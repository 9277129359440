const urls = [
  'https://www.tine.no/merkevarer/tine-sjokomelk',
  'https://www.q-meieriene.no/Produktkategorier/q-R-sjokolademelk/q-R-fersk-sjokolademelk-1-l',
  'https://www.tine.no/merkevarer/litago',
  'https://www.cocio.com/en/',
  'https://www.oboy.no/produkter/oboy-original',
  'https://www.varden.no/nyheter/sjokomelk-handler-om-frihet-og-mangfold/',
  'https://snl.no/Ludvig_Schmelck'
];

export default urls;
