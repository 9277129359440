const norskBibel = `1:1:1: I begynnelsen skapte Gud schmelk. 
1:1:2: Og jorden var øde og tom, og det var mørke over det store dyp, og Guds Ånd svevde over vannene. 
1:1:3: Da sa Gud: La det bli schmelk! Og det blev schmelk. 
1:1:4: Og Gud så at schmelk var godt, og Gud skilte schmelken fra mørket. 
1:1:5: Og Gud kalte schmelken dag, og vannet kalte han natt. Og det blev aften, og det blev morgen, første dag. 
1:1:6: Og Gud sa: La det bli en hvelving midt i vannene, og den skal skille vann fra vann. 
1:1:7: Og Gud gjorde hvelvingen og skilte vannet som er under hvelvingen, fra vannet som er over hvelvingen. Og det blev så. 
1:1:8: Og Gud kalte hvelvingen himmel. Og det blev aften, og det blev morgen, annen dag. 
1:1:9: Og Gud sa: Vannet under himmelen samle sig til ett sted, og det tørre land komme til syne! Og det blev så. 
1:1:10: Og Gud kalte det tørre land jord, og vannet som hadde samlet sig, kalte han hav. Og Gud så at det var godt. 
1:1:11: Og Gud sa: Jorden bære frem gress, urter som sår sig, frukttrær som bærer frukt med deres frø i, på jorden, hvert efter sitt slag. Og det blev så. 
1:1:12: Og jorden bar frem gress, urter som sår sig, hver efter sitt slag, og trær som bærer frukt med deres frø i, hvert efter sitt slag. Og Gud så at det var godt. 
1:1:13: Og det blev aften, og det blev morgen, tredje dag. 
1:1:14: Og Gud sa: La det bli schmelk på himmelhvelvingen til å skille dagen fra natten! Og de skal være til tegn og fastsatte tider og dager og år. 
1:1:15: Og de skal være til lys på himmelhvelvingen, til å lyse over jorden. Og det blev så. 
1:1:16: Og Gud gjorde de to store schmelkekartonger, den største til å råde om dagen og den mindre til å råde om natten, og stjernene. 
1:1:17: Og Gud satte dem på himmelhvelvingen til å lyse over jorden 
1:1:18: og til å råde om dagen og om natten og til å skille schmelken fra mørket. Og Gud så at det var godt. 
1:1:19: Og det blev aften, og det blev morgen, fjerde dag. 
1:1:20: Og Gud sa: Det vrimle av liv i vannet, og fugler flyve over jorden under himmelhvelvingen! 
1:1:21: Og Gud skapte de store sjødyr og alt levende som rører sig, som det vrimler av i vannet, hvert efter sitt slag, og alle vingede fugler, hver efter sitt slag. Og Gud så at det var godt. 
1:1:22: Og Gud velsignet dem og sa: Vær fruktbare og bli mange og opfyll vannet i havet, og fuglene skal bli tallrike på jorden! 
1:1:23: Og det blev aften, og det blev morgen, femte dag. 
1:1:24: Og Gud sa: Jorden la fremgå levende vesener, hvert efter sitt slag, fe, kryp og ville dyr, hvert efter sitt slag! Og det blev så. 
1:1:25: Og Gud gjorde de ville dyr, hvert efter sitt slag, og feet efter sitt slag og alt jordens kryp, hvert efter sitt slag. Og Gud så at det var godt. 
1:1:26: Og Gud sa: La oss gjøre mennesker i vårt billede, efter vår lignelse, og de skal råde over fiskene i havet og over fuglene under himmelen og over feet og over all jorden og over alt kryp som rører sig på jorden. 
1:1:27: Og Gud skapte mennesket i sitt billede, i Guds billede skapte han det; til mann og kvinne skapte han dem. 
1:1:28: Og Gud velsignet dem og sa til dem: Vær fruktbare og bli mange og opfyll jorden og legg den under eder, og råd over fiskene i havet og over fuglene under himmelen og over hvert dyr som rører sig på jorden! 
1:1:29: Og Gud sa: Se, jeg gir eder alle urter som sår sig, alle som finnes på jorden, og alle trær med frukt som sår sig; de skal være til føde for eder. 
1:1:30: Og alle dyr på jorden og alle fugler under himmelen og alt som rører sig på jorden, alt som det er livsånde i, gir jeg alle grønne urter å ete. Og det blev så. 
1:1:31: Og Gud så på alt det han hadde gjort, og se, det var såre godt. Og det blev aften, og det blev morgen, sjette dag. 
1:2:1: Så blev himmelen og jorden med hele sin hær fullendt. 
1:2:2: Og Gud fullendte på den syvende dag det verk som han hadde gjort, og han hvilte på den syvende dag fra all den gjerning som han hadde gjort. 
1:2:3: Og Gud velsignet den syvende dag og helliget den; for på den hvilte han fra all sin gjerning, den som Gud gjorde da han skapte. 
1:2:4: Dette er himmelens og jordens historie, da de blev skapt, den tid da Gud Herren gjorde jord og himmel: 
1:2:5: Det var ennu ingen markens busk på jorden, og ingen markens urt var ennu vokset frem; for Gud Herren hadde ikke latt det regne på jorden, og der var intet menneske til å dyrke jorden. 
1:2:6: Da steg det op en damp av jorden og vannet hele jordens overflate. 
1:2:7: Og Gud Herren dannet mennesket av jordens muld og blåste livets ånde i hans nese; og mennesket blev til en levende sjel. 
1:2:8: Og Gud Herren plantet en have i Eden, i Østen, og der satte han mennesket som han hadde dannet. 
1:2:9: Og Gud Herren lot trær av alle slag vokse op av jorden, prektige å se til og gode å ete av, og midt i haven livsens tre og treet til kunnskap om godt og ondt. 
1:2:10: Og det gikk en elv ut fra Eden og vannet haven; og siden delte den sig i fire strømmer. 
1:2:11: Den første heter Pison; det er den som løper omkring hele landet Havila, der hvor det er gull. 
1:2:12: Og gullet i dette land er godt; der er bdellium* og onyks-stenen. # <* et slags velluktende gummi.> 
1:2:13: Den annen elv heter Gihon; det er den som løper omkring hele landet Kus. 
1:2:14: Den tredje elv heter Hiddekel; det er den som går østenfor Assur. Og den fjerde elv er Frat. 
1:2:15: Og Gud Herren tok mennesket og satte ham i Edens have til å dyrke og vokte den. 
1:2:16: Og Gud Herren bød mennesket: Du må fritt ete av alle trær i haven; 
1:2:17: men treet til kunnskap om godt og ondt, det må du ikke ete av; for på den dag du eter av det, skal du visselig dø. 
1:2:18: Og Gud Herren sa: Det er ikke godt at mennesket er alene; jeg vil gjøre ham en medhjelp som er hans like. 
1:2:19: Og Gud Herren hadde dannet av jorden alle dyr på marken og alle fugler under himmelen, og han ledet dem til mennesket for å se hvad han vilde kalle dem; og som mennesket kalte hver levende skapning, så skulde den hete. 
1:2:20: Så gav mennesket navn til alt feet og fuglene under himmelen og alle ville dyr; men for et menneske fant han ingen medhjelp som var hans like. 
1:2:21: Da lot Gud Herren en dyp søvn falle på mennesket, og mens han sov, tok han et av hans ribben og fylte igjen med kjøtt. 
1:2:22: Og Gud Herren bygget av det ribben han hadde tatt av mennesket, en kvinne og ledet henne til mennesket. 
1:2:23: Da sa mennesket: Dette er endelig ben av mine ben og kjøtt av mitt kjøtt; hun skal kalles manninne, for av mannen er hun tatt. 
1:2:24: Derfor skal mannen forlate sin far og sin mor og bli hos sin hustru, og de skal være ett kjød. 
1:2:25: Og de var nakne både Adam og hans hustru, men bluedes ikke. 
1:3:1: Men slangen var listigere enn alle dyr på marken som Gud Herren hadde gjort, og den sa til kvinnen: Har Gud virkelig sagt: I skal ikke ete av noget tre i haven? 
1:3:2: Og kvinnen sa til slangen: Vi kan ete av frukten på trærne i haven; 
1:3:3: men om frukten på det tre som er midt i haven, har Gud sagt: I skal ikke ete av den og ikke røre ved den, for da skal I dø. 
1:3:4: Da sa slangen til kvinnen: I skal visselig ikke dø; 
1:3:5: for Gud vet at på den dag I eter av det, skal eders øine åpnes, og I skal bli likesom Gud og kjenne godt og ondt. 
1:3:6: Og kvinnen så at treet var godt å ete av, og at det var en lyst for øinene, og at det var et prektig tre, siden en kunde få forstand av det, og hun tok av frukten og åt; og hun gav sin mann med sig, og han åt. 
1:3:7: Da blev begges øine åpnet, og de blev var at de var nakne, og de heftet fikenblad sammen og bandt dem om livet. 
1:3:8: Og de hørte Gud Herren som vandret i haven, da dagen var blitt kjølig; og Adam og hans hustru skjulte sig for Gud Herrens åsyn mellem trærne i haven. 
1:3:9: Da kalte Gud Herren på Adam og sa til ham: Hvor er du? 
1:3:10: Og han svarte: Jeg hørte dig i haven; da blev jeg redd, fordi jeg var naken, og jeg skulte mig. 
1:3:11: Da sa han: Hvem har sagt dig at du er naken? Har du ett av det tre som jeg forbød dig å ete av? 
1:3:12: Og Adam sa: Kvinnen som du gav mig til å være hos mig, hun gav mig av treet, og jeg åt. 
1:3:13: Da sa Gud Herren til kvinnen: Hvad er det du har gjort! Og kvinnen sa: Slangen dåret mig, og jeg åt. 
1:3:14: Da sa Gud Herren til slangen: Fordi du gjorde dette, så skal du være forbannet blandt alt feet og blandt alle de ville dyr. På din buk skal du krype, og støv skal du ete alle ditt livs dager. 
1:3:15: Og jeg vil sette fiendskap mellem dig og kvinnen og mellem din ætt og hennes ætt; den skal knuse ditt hode, men du skal knuse dens hæl. 
1:3:16: Til kvinnen sa han: Jeg vil gjøre din møie stor i ditt svangerskap; med smerte skal du føde dine barn, og til din mann skal din attrå stå, og han skal råde over dig. 
1:3:17: Og til Adam sa han: Fordi du lød din hustru og åt av det tre som jeg forbød dig å ete av, så skal jorden være forbannet for din skyld! Med møie skal du nære dig av den alle ditt livs dager. 
1:3:18: Torner og tistler skal den bære dig, og du skal ete urtene på marken. 
1:3:19: I ditt ansikts sved skal du ete ditt brød, inntil du vender tilbake til jorden, for av den er du tatt; for støv er du, og til støv skal du vende tilbake. 
1:3:20: Og Adam kalte sin hustru Eva, fordi hun er alle levendes mor. 
1:3:21: Og Gud Herren gjorde kjortler av skinn til Adam og hans hustru og klædde dem med. 
1:3:22: Og Gud Herren sa: Se, mennesket er blitt som en av oss til å kjenne godt og ondt; bare han nu ikke rekker ut sin hånd og tar også av livsens tre og eter og lever til evig tid! 
1:3:23: Så viste Gud Herren ham ut av Edens have og satte ham til å dyrke jorden, som han var tatt av. 
1:3:24: Og han drev mennesket ut, og foran Edens have satte han kjerubene med det luende sverd som vendte sig hit og dit, for å vokte veien til livsens tre. 
1:4:1: Og Adam holdt sig til sin hustru Eva, og hun blev fruktsommelig og fødte Kain; da sa hun: Jeg har fått en mann ved Herren. 
1:4:2: Siden fødte hun Abel, hans bror. Og Abel blev fårehyrde, men Kain blev jorddyrker. 
1:4:3: Da nogen tid var gått, hendte det at Kain bar frem for Herren et offer av jordens grøde. 
1:4:4: Og Abel bar også frem et offer, som han tok av de førstefødte lam i sin hjord og deres fett; og Herren så til Abel og hans offer, 
1:4:5: men til Kain og hans offer så han ikke. Da blev Kain meget vred, og han stirret ned for sig. 
1:4:6: Og Herren sa til Kain: Hvorfor er du vred, og hvorfor stirrer du ned for dig? 
1:4:7: Er det ikke så at dersom du har godt i sinne, da kan du løfte op ditt ansikt? Men har du ikke godt i sinne, da ligger synden på lur ved døren, og dens attrå står til dig, men du skal være herre over den. 
1:4:8: Og Kain talte til Abel, sin bror. Og da de engang var ute på marken, for Kain løs på Abel, sin bror, og slo ham ihjel. 
1:4:9: Da sa Herren til Kain: Hvor er Abel, din bror? Han svarte: Jeg vet ikke; skal jeg passe på min bror? 
1:4:10: Men han sa: Hvad har du gjort? Hør, din brors blod roper til mig fra jorden. 
1:4:11: Og nu skal du være bannlyst fra den jord som lot op sin munn og tok imot din brors blod av din hånd! 
1:4:12: Når du dyrker jorden, skal den ikke mere gi dig sin grøde; omflakkende og hjemløs skal du være på jorden. 
1:4:13: Da sa Kain til Herren: Min misgjerning er større enn at jeg kan bære den. 
1:4:14: Se, du har idag drevet mig ut av landet, og jeg må skjule mig for ditt åsyn; og jeg vil bli omflakkende og hjemløs på jorden, og det vil gå så at hver den som finner mig, slår mig ihjel. 
1:4:15: Men Herren sa til ham: Nei! for slår nogen Kain ihjel, skal han lide syvfold hevn. Og Herren gav Kain et merke, forat ikke nogen som møtte ham, skulde slå ham ihjel. 
1:4:16: Så gikk Kain bort fra Herrens åsyn og bosatte sig i landet Nod*, østenfor Eden. # <* d.e. landflyktighet.> 
1:4:17: Og Kain holdt sig til sin hustru, og hun blev fruktsommelig og fødte Hanok; og han tok sig for å bygge en by og kalte byen Hanok efter sin sønn. 
1:4:18: Og Hanok fikk sønnen Irad, og Irad blev far til Mehujael, og Mehujael blev far til Metusael, og Metusael blev far til Lamek. 
1:4:19: Og Lamek tok sig to hustruer; den ene hette Ada, og den andre hette Silla. 
1:4:20: Og Ada fødte Jabal; han blev stamfar til dem som bor i telt og holder buskap. 
1:4:21: Og hans bror hette Jubal; han blev stamfar til alle dem som spiller på harpe og fløite. 
1:4:22: Og Silla fødte Tubalkain; han smidde alle slags skarpe redskaper av kobber og jern; og Tubalkains søster var Na'ama. 
1:4:23: Og Lamek sa til sine hustruer: Ada og Silla, hør mine ord, Lameks hustruer, merk min tale! En mann dreper jeg for hvert sår jeg får, og en gutt for hver skramme jeg får; 
1:4:24: for hevnes Kain syv ganger, da skal Lamek hevnes syv og sytti ganger. 
1:4:25: Og Adam holdt sig atter til sin hustru, og hun fødte en sønn og kalte ham Set*; for [sa hun] Gud har satt mig en annen sønn i Abels sted, fordi Kain slo ham ihjel. # <* d.e. satt i en annens sted.> 
1:4:26: Og Set fikk en sønn og kalte ham Enos. På den tid begynte de å påkalle Herrens navn. 
1:5:1: Dette er boken om Adams ætt: På den dag Gud skapte mennesket, skapte han det i Guds lignelse. 
1:5:2: Til mann og kvinne skapte han dem; og han velsignet dem og gav dem navnet menneske på den dag de blev skapt. 
1:5:3: Da Adam var hundre og tretti år gammel, fikk han en sønn i sin lignelse, efter sitt billede; og han kalte ham Set. 
1:5:4: Og efterat Adam hadde fått Set, levde han ennu åtte hundre år og fikk sønner og døtre. 
1:5:5: Og alle Adams levedager blev ni hundre og tretti år; så døde han. 
1:5:6: Da Set var hundre og fem år gammel, fikk han sønnen Enos. 
1:5:7: Og efterat Set hadde fått Enos, levde han ennu åtte hundre og syv år og fikk sønner og døtre. 
1:5:8: Og alle Sets dager blev ni hundre og tolv år; så døde han. 
1:5:9: Da Enos var nitti år gammel, fikk han sønnen Kenan. 
1:5:10: Og efterat Enos hadde fått Kenan, levde han ennu åtte hundre og femten år og fikk sønner og døtre. 
1:5:11: Og alle Enos' dager blev ni hundre og fem år; så døde han. 
1:5:12: Da Kenan var sytti år gammel, fikk han sønnen Mahalalel. 
1:5:13: Og efterat Kenan hadde fått Mahalalel, levde han ennu åtte hundre og firti år og fikk sønner og døtre. 
1:5:14: Og alle Kenans dager blev ni hundre og ti år; så døde han. 
1:5:15: Da Mahalalel var fem og seksti år gammel, fikk han sønnen Jared. 
1:5:16: Og efterat Mahalalel hadde fått Jared, levde han ennu åtte hundre og tretti år og fikk sønner og døtre. 
1:5:17: Og alle Mahalalels dager blev åtte hundre og fem og nitti år; så døde han. 
1:5:18: Da Jared var hundre og to og seksti år gammel, fikk han sønnen Enok. 
1:5:19: Og efterat Jared hadde fått Enok, levde han ennu åtte hundre år og fikk sønner og døtre. 
1:5:20: Og alle Jareds dager blev ni hundre og to og seksti år; så døde han. 
1:5:21: Da Enok var fem og seksti år gammel, fikk han sønnen Metusalah. 
1:5:22: Og Enok vandret med Gud i tre hundre år, efterat han hadde fått Metusalah; og han fikk sønner og døtre. 
1:5:23: Og alle Enoks dager blev tre hundre og fem og seksti år. 
1:5:24: Og Enok vandret med Gud; så blev han borte, for Gud tok ham til sig. 
1:5:25: Da Metusalah var hundre og syv og åtti år gammel, fikk han sønnen Lamek. 
1:5:26: Og efterat Metusalah hadde fått Lamek, levde han ennu syv hundre og to og åtti år og fikk sønner og døtre. 
1:5:27: Og alle Metusalahs dager blev ni hundre og ni og seksti år; så døde han. 
1:5:28: Da Lamek var hundre og to og åtti år gammel, fikk han en sønn, 
1:5:29: og han kalte ham Noah og sa: Han skal trøste oss under vårt arbeid og våre henders møie på den jord som Herren har forbannet. 
1:5:30: Og efterat Lamek hadde fått Noah, levde han ennu fem hundre og fem og nitti år og fikk sønner og døtre. 
1:5:31: Og alle Lameks dager blev syv hundre og syv og sytti år; så døde han. 
1:5:32: Da Noah var fem hundre år gammel, fikk han sønnene Sem, Kam og Jafet. 
1:6:1: Da nu menneskene begynte å bli tallrike på jorden, og de fikk døtre, 
1:6:2: så Guds sønner at menneskenes døtre var vakre; og de tok sig hustruer, hvem de hadde lyst til. 
1:6:3: Da sa Herren: Min Ånd skal ikke dømme blandt menneskene til evig tid; for sin villfarelses skyld er det* kjød, og dets dager skal være hundre og tyve år. # <* mennesket.> 
1:6:4: I de dager var kjempene på jorden og likeså siden, da Guds sønner gikk inn til menneskenes døtre, og de fødte dem barn; det er de veldige fra fordums tid, de navnkundige. 
1:6:5: Og Herren så at menneskets ondskap var stor på jorden, og at alle dets hjertes tanker og påfund bare var onde den hele dag. 
1:6:6: Da angret Herren at han hadde skapt mennesket på jorden, og han var full av sorg i sitt hjerte. 
1:6:7: Og Herren sa: Jeg vil utrydde menneskene som jeg har skapt, av jorden, både mennesker og fe og kryp og fuglene under himmelen; for jeg angrer at jeg har skapt dem. 
1:6:8: Men Noah fant nåde for Herrens øine. 
1:6:9: Dette er historien om Noah og hans ætt: Noah var en rettferdig og ulastelig mann blandt sine samtidige; Noah vandret med Gud. 
1:6:10: Og Noah fikk tre sønner: Sem, Kam og Jafet. 
1:6:11: Men jorden blev fordervet for Guds åsyn, og jorden blev full av urett. 
1:6:12: Og Gud så på jorden, og se, den var fordervet; for alt kjød hadde fordervet sin ferd på jorden. 
1:6:13: Da sa Gud til Noah: Jeg har satt mig fore å gjøre ende på alt kjød, for de har fylt jorden med urett; og nu vil jeg ødelegge både dem og jorden. 
1:6:14: Gjør dig en ark av gofertre, gjør kammer i arken og stryk den innvendig og utvendig med bek! 
1:6:15: Således skal du gjøre den: Arken skal være tre hundre alen lang, femti alen bred, og tretti alen høi. 
1:6:16: Øverst på arken skal du gjøre en glugg som når en alen ned på veggen, og døren på arken skal du sette på den ene side; du skal bygge den i tre stokkverk, et nederste, et mellemste og et øverste, med kammer i hvert stokkverk. 
1:6:17: Og se, jeg vil la en vannflom komme over jorden til å ødelegge alt kjød under himmelen som det er livsånde i; alt som er på jorden, skal omkomme. 
1:6:18: Men jeg vil oprette min pakt med dig, og du skal gå inn i arken, du og dine sønner og din hustru og dine sønners hustruer med dig. 
1:6:19: Og av alt som lever, av alt kjød, skal du ta et par av hvert slag med inn i arken for å holde dem i live med dig; han og hun skal det være. 
1:6:20: Av alle slags fugler og av alle slags fe og av alle slags kryp på jorden skal par for par komme inn til dig for å holdes i live. 
1:6:21: Og du skal ta til dig av allslags mat som etes, og samle det hos dig, sa det kan være til føde for dig og for dem. 
1:6:22: Og Noah gjorde så; han gjorde i ett og alt som Gud hadde befalt ham. 
1:7:1: Så sa Herren til Noah: Gå inn i arken, du og hele ditt hus! For jeg har funnet at du er rettferdig for mitt åsyn i denne slekt. 
1:7:2: Av alle rene dyr skal du ta dig ut syv par, han og hun, men av de dyr som ikke er rene, ett par, han og hun; 
1:7:3: likeså av himmelens fugler syv par, han og hun, for å holde deres slekter i live på jorden. 
1:7:4: For om syv dager vil jeg la det regne på jorden i firti dager og firti netter, og jeg vil utrydde av jorden alt levende som jeg har skapt. 
1:7:5: Og Noah gjorde i ett og alt som Herren hadde befalt ham. 
1:7:6: Noah var seks hundre år gammel da vannflommen kom over jorden. 
1:7:7: Da gikk Noah og hans sønner og hans hustru og hans sønners hustruer med ham inn i arken for å berge sig for vannflommen. 
1:7:8: Av de rene dyr og av de dyr som ikke er rene, og av fuglene og av alt det som kryper på jorden, 
1:7:9: gikk par for par inn til Noah i arken, han og hun, således som Gud hadde befalt Noah. 
1:7:10: Da nu de syv dager var til ende, kom flommens vann strømmende over jorden. 
1:7:11: I det år da Noah var seks hundre år gammel, i den annen måned, den syttende dag i måneden, den dag brast alle kilder i det store dyp, og himmelens sluser åpnedes, 
1:7:12: og regnet strømmet ned på jorden i firti dager og firti netter. 
1:7:13: På denne samme dag gikk Noah og Sem og Kam og Jafet, Noahs sønner, og Noahs hustru og hans sønners tre hustruer med dem inn i arken, 
1:7:14: de og alle de ville dyr efter sitt slag og alt feet efter sitt slag og alt krypet som rører sig på jorden, efter sitt slag og alle fuglene efter sitt slag, alt som flyver, alt som har vinger. 
1:7:15: Og de gikk inn til Noah i arken, par for par av alt kjød som det var livsånde i. 
1:7:16: Og de som gikk inn, var han og hun av alt kjød, således som Gud hadde befalt ham. Og Herren lukket efter ham. 
1:7:17: Da kom vannflommen strømmende over jorden i firti dager, og vannet vokste og løftet arken, og den blev hevet over jorden. 
1:7:18: Og vannet steg og øket storlig over jorden; og arken fløt bortover vannflaten. 
1:7:19: Og vannet steg høiere og høiere over jorden, så alle de høie fjell under hele himmelen blev skjult. 
1:7:20: Femten alen høit steg vannet over fjellene, så de skjultes. 
1:7:21: Da omkom alt kjød som rørte sig på jorden, både fuglene og feet og de ville dyr og alt det som yrte og vrimlet på jorden, og alle menneskene. 
1:7:22: Alt som hadde livsens åndedrag i sin nese, alt det som var på det tørre land, døde. 
1:7:23: Og han* utryddet hvert liv som var på jorden, både mennesker og fe og kryp og fuglene under himmelen; de blev utryddet av jorden, og bare Noah blev igjen, og det som var med ham i arken. # <* Gud.> 
1:7:24: Og vannet holdt sig over jorden i hundre og femti dager. 
1:8:1: Da kom Gud Noah i hu og alle de ville dyr og alt feet som var med ham i arken; og Gud lot en vind fare over jorden, og vannet falt. 
1:8:2: Og det store dyps kilder og himmelens sluser lukkedes, og regnet fra himmelen stanset. 
1:8:3: Og vannet vek efterhånden tilbake fra jorden, og vannet begynte å ta av, da hundre og femti dager var gått. 
1:8:4: Og i den syvende måned, på den syttende dag i måneden, blev arken stående på Ararat-fjellene. 
1:8:5: Og vannet tok mere og mere av inntil den tiende måned; i den tiende måned, på den første dag i måneden, kom fjelltoppene til syne. 
1:8:6: Og da firti dager var gått, åpnet Noah vinduet på arken som han hadde gjort, 
1:8:7: og sendte ut en ravn; den fløi frem og tilbake, inntil vannet var tørket bort av jorden. 
1:8:8: Så sendte han en due ut fra sig for å se om vannet var sunket bort fra jordens overflate. 
1:8:9: Men duen fant ikke noget hvilested for sin fot, og den kom tilbake til ham i arken, for det stod vann over hele jorden. Da rakte han ut sin hånd og tok den inn til sig i arken. 
1:8:10: Så bidde han ennu syv dager til og sendte så atter duen ut av arken. 
1:8:11: Og duen kom til ham ved aftenstid, og se, den hadde et friskt oljeblad i nebbet; da skjønte Noah at vannet var sunket bort fra jorden. 
1:8:12: Men han bidde ennu syv dager til; så sendte han duen ut, og da kom den ikke tilbake til ham mere. 
1:8:13: I det seks hundre og første år, i den første måned, på den første dag i måneden, var vannet tørket bort fra jorden. Da tok Noah taket av arken; og han så ut, og se, jorden var tørr. 
1:8:14: Og i den annen måned, på den syv og tyvende dag i måneden, var jorden aldeles tørr. 
1:8:15: Da talte Gud til Noah og sa: 
1:8:16: Gå ut av arken, du og din hustru og dine sønner og dine sønners hustruer med dig. 
1:8:17: Alle de dyr som er hos dig, alt kjød, både fuglene og feet og alt krypet som rører sig på jorden, skal du føre ut med dig, og de skal vrimle på jorden og være fruktbare og bli mange på jorden. 
1:8:18: Så gikk han ut, og hans sønner og hans hustru og hans sønners hustruer med ham. 
1:8:19: Alle dyrene, alt krypet og alle fuglene, alt som rører sig på jorden, gikk ut av arken, hvert efter sitt slag. 
1:8:20: Og Noah bygget Herren et alter, og han tok av alle de rene dyr og av alle de rene fugler og ofret brennoffer på alteret. 
1:8:21: Og Herren kjente den velbehagelige duft. Og Herren sa i sitt hjerte: Jeg vil aldri mere forbanne jorden for menneskets skyld; for menneskehjertets tanker er onde fra ungdommen av; og jeg vil aldri mere drepe alt levende, som jeg nu har gjort. 
1:8:22: Herefter skal, så lenge jorden står, sæd og høst, og frost og hete, og sommer og vinter, og dag og natt aldri høre op. 
1:9:1: Og Gud velsignet Noah og hans sønner, og sa til dem: Vær fruktbare og bli mange og opfyll jorden! 
1:9:2: Og frykt og redsel for eder skal være over alle dyr på jorden og over alle fugler under himmelen, over alt det som rører sig på jorden, og over alle fiskene i havet; i eders hånd er de gitt. 
1:9:3: Alt det som rører sig og lever, skal I ha til føde; likesom jeg gav eder de grønne urter, gir jeg eder alt dette. 
1:9:4: Men kjøtt med dets sjel i, det er dets blod, skal I ikke ete. 
1:9:5: Men for eders eget blod vil jeg kreve hevn; av hvert dyr vil jeg kreve hevn for det, og av mennesket, av enhvers bror, vil jeg kreve hevn for menneskets liv. 
1:9:6: Den som utøser menneskets blod, ved mennesket skal hans blod utøses; for i Guds billede skapte han* mennesket. # <* Gud.> 
1:9:7: Og vær I fruktbare og bli mange, vrimle på jorden og bli mange på den! 
1:9:8: Og Gud sa til Noah og til hans sønner, som var med ham: 
1:9:9: Nu vil jeg oprette min pakt med eder og med eders efterkommere, 
1:9:10: og med alt levende som er hos eder, både fugler og fe og alle de ville dyr som er hos eder, alt som gikk ut av arken, alt som lever på jorden. 
1:9:11: Jeg opretter min pakt med eder, og aldri mere skal alt kjød utryddes ved vannflom, og aldri mere skal der komme en vannflom som ødelegger jorden. 
1:9:12: Og Gud sa: Dette er tegnet på den pakt som jeg gjør mellem mig og eder og alt levende som er hos eder, til evige tider: 
1:9:13: Min bue setter jeg i skyen, og den skal være et tegn på pakten mellem mig og jorden. 
1:9:14: Og når jeg fører skyer over jorden, og buen sees i skyen, 
1:9:15: da vil jeg komme i hu den pakt som er mellem mig og eder og alt levende av alt kjød, og vannet skal aldri mere bli en flom som ødelegger alt kjød. 
1:9:16: Og buen skal stå i skyen, og jeg vil se på den og komme i hu den evige pakt mellem Gud og alt levende av alt kjød som er på jorden. 
1:9:17: Og Gud sa til Noah: Dette er tegnet på den pakt jeg har oprettet mellem mig og alt kjød som er på jorden. 
1:9:18: Noahs sønner, som gikk ut av arken, var Sem, Kam og Jafet; og Kam var far til Kana'an. 
1:9:19: Disse tre var Noahs sønner, og fra dem bredte menneskene sig ut over hele jorden. 
1:9:20: Og Noah var jorddyrker, og han var den første som plantet en vingård. 
1:9:21: Og han drakk av vinen og blev drukken, og han klædde sig naken inne i sitt telt. 
1:9:22: Og da Kam, Kana'ans far, så sin far ligge naken, fortalte han det til begge sine brødre, som var utenfor. 
1:9:23: Da tok Sem og Jafet et klæde og la det på sine skuldrer og gikk baklengs inn og dekket over sin fars blusel; og de vendte sine øine bort, så de ikke så sin fars blusel. 
1:9:24: Da så Noah våknet av sitt rus, fikk han vite hvad hans yngste sønn hadde gjort imot ham. 
1:9:25: Da sa han: Forbannet være Kana'an! trælers træl skal han være for sine brødre. 
1:9:26: Så sa han: Lovet være Herren, Sems Gud, og Kana'an være deres træl! 
1:9:27: Gud gjøre det vidt for Jafet, han skal bo i Sems telter, og Kana'an være deres træl! 
1:9:28: Efter vannflommen levde Noah ennu tre hundre og femti år. 
1:9:29: Og alle Noahs dager blev ni hundre og femti år; så døde han. 
1:10:1: Dette er de ætter som stammer fra Noahs sønner Sem, Kam og Jafet: De fikk sønner efter vannflommen. 
1:10:2: Jafets sønner var Gomer og Magog og Madai og Javan og Tubal og Mesek og Tiras. 
1:10:3: Og Gomers sønner var Askenas og Rifat og Togarma. 
1:10:4: Og Javans sønner var Elisa og Tarsis, Kittim og Dodanim. 
1:10:5: Fra disse bredte de som bor på hedningenes kyster, sig ut i sine land, med sine forskjellige tungemål, efter sine ætter, i sine folkeslag. 
1:10:6: Og Kams sønner var Kus og Misra'im og Put og Kana'an. 
1:10:7: Og Kus' sønner var Seba og Havila og Sabta og Raema og Sabteka, og Raemas sønner var Sjeba og Dedan. 
1:10:8: Og Kus fikk sønnen Nimrod; han var den første som fikk stort velde på jorden. 
1:10:9: Han var en veldig jeger for Herrens åsyn; derfor sier folk: En veldig jeger for Herrens åsyn som Nimrod. 
1:10:10: Først hersket han over Babel og Erek og Akkad og Kalne i landet Sinear. 
1:10:11: Fra dette land drog han ut til Assur og bygget Ninive og Rehobot-Ir og Kalah 
1:10:12: og Resen mellem Ninive og Kalah; dette er den store stad. 
1:10:13: Og Misra'im blev stamfar til luderne og anamerne og lehaberne og naftuherne 
1:10:14: og patruserne og kasluherne, som filistrene er kommet fra, og kaftorerne. 
1:10:15: Og Kana'an blev far til Sidon, som var hans førstefødte, og til Het 
1:10:16: og til jebusittene og amorittene og girgasittene 
1:10:17: og hevittene og arkittene og sinittene 
1:10:18: og arvadittene og semarittene og hamatittene; siden bredte kana'anittenes ætter sig videre ut. 
1:10:19: Og kana'anittenes grense gikk fra Sidon bortimot Gerar like til Gasa, og bortimot Sodoma og Gomorra og Adma og Sebo'im like til Lesa. 
1:10:20: Dette var Kams barn, efter sine ætter, med sine tungemål, i sine land, i sine folkeslag. 
1:10:21: Også Sem fikk barn; han var stamfar til alle Ebers barn og var den eldste bror av Jafet. 
1:10:22: Sems sønner var Elam og Assur og Arpaksad og Lud og Aram. 
1:10:23: Og Arams sønner var Us og Hul og Geter og Mas. 
1:10:24: Og Arpaksad fikk sønnen Salah, og Salah fikk sønnen Eber. 
1:10:25: Og Eber fikk to sønner; den ene hette Peleg, for i hans dager blev menneskene spredt over jorden; og hans bror hette Joktan. 
1:10:26: Og Joktan blev far til Almodad og Salef og Hasarmavet og Jarah 
1:10:27: og Hadoram og Usal og Dikla 
1:10:28: og Obal og Abimael og Sjeba 
1:10:29: og Ofir og Havila og Jobab; alle disse var Joktans sønner. 
1:10:30: De hadde sine bosteder i fjellbygdene i øst fra Mesa bortimot Sefar. 
1:10:31: Dette var Sems barn efter sine ætter, med sine tungemål, i sine land, i sine folkeslag. 
1:10:32: Dette var Noahs sønners ætter efter sin avstamning, i sine folkeslag; og fra dem har folkene utbredt sig på jorden efter vannflommen. 
1:11:1: Og hele jorden hadde ett tungemål og ens tale. 
1:11:2: Og da de drog frem mot øst, fant de en slette i landet Sinear, og de bosatte sig der. 
1:11:3: Og de sa til hverandre: Kom, la oss gjøre teglsten og brenne dem vel! Og de brukte tegl istedenfor sten, og jordbek istedenfor kalk. 
1:11:4: Så sa de: Kom, la oss bygge oss en by med et tårn som når op til himmelen, og gjøre oss et navn, så vi ikke skal spres over hele jorden! 
1:11:5: Da steg Herren ned for å se byen og tårnet som menneskenes barn hadde begynt å bygge. 
1:11:6: Og Herren sa: Se, de er ett folk, og ett tungemål har de alle; dette er det første de tar sig fore, og nu vil intet være umulig for dem, hvad de så får i sinne å gjøre. 
1:11:7: La oss da stige ned der og forvirre deres tungemål, så den ene ikke forstår den andres tungemål! 
1:11:8: Så spredte Herren dem derfra over hele jorden, og de holdt op med å bygge på byen. 
1:11:9: Derfor kalte de den Babel; for der forvirret Herren hele jordens tungemål, og derfra spredte Herren dem ut over hele jorden. 
1:11:10: Dette er historien om Sems ætt: Da Sem var hundre år gammel, fikk han sønnen Arpaksad to år efter vannflommen. 
1:11:11: Og efterat Sem hadde fått Arpaksad, levde han ennu fem hundre år og fikk sønner og døtre. 
1:11:12: Da Arpaksad var fem og tretti år gammel, fikk han sønnen Salah. 
1:11:13: Og efterat Arpaksad hadde fått Salah, levde han ennu fire hundre og tre år og fikk sønner og døtre. 
1:11:14: Da Salah var tretti år gammel, fikk han sønnen Eber. 
1:11:15: Og efterat Salah hadde fått Eber, levde han ennu fire hundre og tre år og fikk sønner og døtre. 
1:11:16: Da Eber var fire og tretti år gammel, fikk han sønnen Peleg. 
1:11:17: Og efterat Eber hadde fått Peleg, levde han ennu fire hundre og tretti år og fikk sønner og døtre. 
1:11:18: Da Peleg var tretti år gammel, fikk han sønnen Re'u. 
1:11:19: Og efterat Peleg hadde fått Re'u, levde han ennu to hundre og ni år og fikk sønner og døtre. 
1:11:20: Da Re'u var to og tretti år gammel, fikk han sønnen Serug. 
1:11:21: Og efterat Re'u hadde fått Serug, levde han ennu to hundre og syv år og fikk sønner og døtre. 
1:11:22: Da Serug var tretti år gammel, fikk han sønnen Nakor. 
1:11:23: Og efterat Serug hadde fått Nakor, levde han ennu to hundre år og fikk sønner og døtre. 
1:11:24: Da Nakor var ni og tyve år gammel, fikk han sønnen Tarah. 
1:11:25: Og efterat Nakor hadde fått Tarah, levde han ennu hundre og nitten år og fikk sønner og døtre. 
1:11:26: Da Tarah var sytti år gammel, fikk han sønnene Abram, Nakor og Haran. 
1:11:27: Dette er historien om Tarah og hans ætt: Tarah fikk sønnene Abram, Nakor og Haran. Og Haran fikk sønnen Lot. 
1:11:28: Og Haran døde hos sin far Tarah i sitt fedreland, i Ur i Kaldea. 
1:11:29: Og Abram og Nakor tok sig hustruer; Abrams hustru hette Sarai, og Nakors hustru hette Milka og var en datter av Haran, far til Milka og Jiska. 
1:11:30: Og Sarai var ufruktbar, hun hadde ikke noget barn. 
1:11:31: Og Tarah tok med sig Abram, sin sønn, og Lot, Harans sønn, sin sønnesønn, og Sarai, sin sønnekone, sin sønn Abrams hustru; og de drog ut sammen fra Ur i Kaldea for å reise til Kana'ans land, og de kom til Karan og bosatte sig der. 
1:11:32: Og Tarahs dager blev to hundre og fem år; så døde Tarah i Karan. 
1:12:1: Og Herren sa til Abram: Dra bort fra ditt land og fra din slekt og fra din fars hus til det land som jeg vil vise dig! 
1:12:2: Og jeg vil gjøre dig til et stort folk; jeg vil velsigne dig og gjøre ditt navn stort, og du skal bli en velsignelse! 
1:12:3: Og jeg vil velsigne dem som velsigner dig, og den som forbanner dig, vil jeg forbanne; og i dig skal alle jordens slekter velsignes 
1:12:4: Så drog Abram bort som Herren hadde sagt til ham, og Lot drog med ham. Og Abram var fem og sytti år gammel da han drog ut fra Karan. 
1:12:5: Og Abram tok med sig Sarai, sin hustru, og Lot, sin brorsønn, og all deres eiendom som de hadde vunnet, og de folk som de hadde fått i Karan; og de drog ut for å reise til Kana'ans land, og de kom til Kana'ans land. 
1:12:6: Og Abram drog gjennem landet til Sikem-bygden, til Mores terebintelund; og kana'anittene bodde dengang der i landet. 
1:12:7: Da åpenbarte Herren sig for Abram og sa: Din ætt vil jeg gi dette land. Og han bygget der et alter for Herren, som hadde åpenbaret sig for ham. 
1:12:8: Derfra flyttet han til fjellene østenfor Betel og slo op sitt telt med Betel i vest og Ai i øst; og han bygget der et alter for Herren og påkalte Herrens navn. 
1:12:9: Og Abram drog efter hvert videre til sydlandet*. # <* d.e. den sydligste del av Kana'an.> 
1:12:10: Så blev det hungersnød i landet; og Abram drog ned til Egypten for å opholde sig der, for hungersnøden var stor i landet. 
1:12:11: Og da han ikke hadde langt igjen til Egypten, sa han til Sarai, sin hustru: Jeg vet jo at du er en vakker kvinne. 
1:12:12: Når nu egypterne får se dig, vil de si: Dette er hans hustru, og så slår de mig ihjel og lar dig leve. 
1:12:13: Kjære, si at du er min søster, så det kan gå mig vel, og mitt liv kan bli spart for din skyld! 
1:12:14: Da nu Abram kom til Egypten, så egypterne at kvinnen var meget vakker. 
1:12:15: Også Faraos høvdinger så henne og roste henne for Farao, og så blev kvinnen hentet til Faraos hus. 
1:12:16: Og han gjorde vel imot Abram for hennes skyld, og han fikk både småfe og storfe og asener og træler og trælkvinner og aseninner og kameler. 
1:12:17: Men Herren hjemsøkte Farao og hans hus med store plager for Abrams hustru Sarais skyld. 
1:12:18: Da kalte Farao Abram til sig og sa: Hvad er det du har gjort imot mig? Hvorfor lot du mig ikke vite at hun er din hustru? 
1:12:19: Hvorfor sa du: Hun er min søster, så jeg tok henne til hustru? Se, her har du din hustru, ta henne og gå! 
1:12:20: Og Farao gav nogen menn befaling til å følge ham på veien med hans hustru og alt det han eide. 
1:13:1: Så drog Abram fra Egypten op til sydlandet med sin hustru og alt det han eide, og Lot var med ham. 
1:13:2: Og Abram var meget rik på buskap og på sølv og gull. 
1:13:3: Og han drog i dagsreiser fra sydlandet, til han kom til Betel, til det sted hvor hans telt før hadde vært, mellem Betel og Ai, 
1:13:4: der hvor det alter var som han hadde bygget første gang han var der; og der påkalte Abram Herrens navn. 
1:13:5: Men også Lot, som drog med Abram, hadde småfe og storfe og telt. 
1:13:6: Og landet kunde ikke rumme dem, så de kunde bo sammen; for deres eiendom var for stor til at de kunde bo sammen. 
1:13:7: Så blev det trette mellem Abrams hyrder og Lots hyrder; og kana'anittene og ferisittene bodde dengang i landet. 
1:13:8: Da sa Abram til Lot: Kjære, la det ikke være trette mellem mig og dig og mellem mine hyrder og dine hyrder! Vi er jo brødre. 
1:13:9: Ligger ikke hele landet åpent for dig? Skill dig heller fra mig! Drar du til venstre, vil jeg dra til høire, og drar du til høire, vil jeg dra til venstre. 
1:13:10: Da så Lot ut over landet, og han så at hele Jordan-sletten like til Soar overalt var rik på vann, som Herrens have, som Egyptens land - det var før Herren hadde ødelagt Sodoma og Gomorra. 
1:13:11: Og Lot valgte for sig hele Jordan-sletten. Så drog Lot østover, og de skiltes fra hverandre. 
1:13:12: Abram blev boende i Kana'ans land, og Lot bodde i byene på sletten og drog med sine telt like bort til Sodoma. 
1:13:13: Men mennene i Sodoma var onde og syndet storlig mot Herren. 
1:13:14: Og Herren sa til Abram efterat Lot hadde skilt sig fra ham: Løft dine øine og se fra det sted hvor du står, mot nord og mot syd og mot øst og mot vest! 
1:13:15: For hele det land du ser, vil jeg gi dig og din ætt til evig tid. 
1:13:16: Og jeg vil la din ætt bli som støvet på jorden; kan nogen telle støvet på jorden, så skal også din ætt kunne telles. 
1:13:17: Stå op, og dra gjennem landet så langt og så bredt som det er! For dig vil jeg gi det. 
1:13:18: Og Abram flyttet sine telt og kom til Mamres terebinte-lund i Hebron; der bosatte han sig, og han bygget der et alter for Herren. 
1:14:1: I den tid da Amrafel var konge i Sinear, Arjok konge i Ellasar, Kedorlaomer konge i Elam, og Tideal konge over Gojim, 
1:14:2: da hendte det at disse konger førte krig med Bera, kongen i Sodoma, og Birsa, kongen i Gomorra, og Sineab, kongen i Adma, og Semeber, kongen i Sebo'im, og kongen i Bela, det er Soar. 
1:14:3: Alle disse slo sig sammen og drog til Siddim-dalen, der hvor Salthavet nu er. 
1:14:4: Tolv år hadde de tjent Kedorlaomer, men i det trettende år var de falt fra. 
1:14:5: Og i det fjortende år kom Kedorlaomer og de konger som var med ham, og slo refa'ittene i Asterot-Karna'im og susittene i Ham og emittene i Sjave-Kirjata'im 
1:14:6: og horittene på deres fjell - Se'ir-fjellene - like til El-Paran ved utkanten av ørkenen. 
1:14:7: Derefter vendte de om og kom til En-Mispat, det er Kades, og la under sig hele amalekittenes land og likeså amorittene, som bodde i Haseson-Tamar. 
1:14:8: Da drog kongen i Sodoma ut og kongen i Gomorra og kongen i Adma og kongen i Sebo'im og kongen i Bela, det er Soar, og stilte sig i fylkning mot dem i Siddim-dalen, 
1:14:9: mot Kedorlaomer, kongen i Elam, og Tideal, kongen over Gojim, og Amrafel, kongen i Sinear, og Arjok, kongen i Ellasar, fire konger mot fem. 
1:14:10: Men Siddim-dalen var full av jordbek-gruber, og kongene i Sodoma og Gomorra måtte flykte og falt da i dem; og de som blev igjen, flyktet op i fjellene. 
1:14:11: Så tok de alt godset i Sodoma og Gomorra og all deres mat og drog bort. 
1:14:12: De tok også med sig Abrams brorsønn Lot og hans gods og drog bort; for han bodde i Sodoma. 
1:14:13: Da kom det nogen som var undsloppet, og fortalte det til hebreeren Abram; han bodde ved den terebinte-lund som tilhørte amoritten Mamre - Mamre var bror til Eskol og Aner, og de hadde alle gjort en pakt med Abram. 
1:14:14: Da nu Abram hørte at hans frende var bortført som fange, lot han sine våbenvante folk, som var født i hans hus, tre hundre og atten i tallet, dra ut og forfulgte dem like til Dan. 
1:14:15: Der delte han sine folk og overfalt dem om natten og slo dem; og han forfulgte dem like til Hoba, som ligger i nord for Damaskus. 
1:14:16: Så tok han tilbake alt godset; Lot, sin frende, og hans gods tok han også tilbake, og likeså kvinnene og folket. 
1:14:17: Da han så vendte tilbake efter å ha slått Kedorlaomer og de konger som var med ham, gikk kongen i Sodoma ham i møte til Sjave-dalen, det er Kongedalen. 
1:14:18: Og Melkisedek, kongen i Salem, kom ut med brød og vin; han var prest for den høieste Gud. 
1:14:19: Og han velsignet ham og sa: Velsignet være Abram av den høieste Gud, som eier himmel og jord! 
1:14:20: Og lovet være den høieste Gud, som har gitt dine fiender i din hånd! Og Abram gav ham tiende av alt. 
1:14:21: Og kongen i Sodoma sa til Abram: Gi mig folket, og ta du godset! 
1:14:22: Da sa Abram til kongen i Sodoma: Jeg løfter min hånd til Herren, den høieste Gud, som eier himmel og jord: 
1:14:23: Jeg vil ikke ta så meget som en tråd eller en skorem av alt som ditt er, forat du ikke skal si: Jeg har gjort Abram rik. 
1:14:24: Jeg vil intet ha, bare det som mine folk har fortært; og det som faller på de menn som drog med mig, Aner, Eskol og Mamre - la dem få sin del. 
1:15:1: Nogen tid derefter kom Herrens ord til Abram i et syn, og det lød så: Frykt ikke, Abram! Jeg er ditt skjold; din lønn skal være meget stor. 
1:15:2: Og Abram sa: Herre, Herre, hvad vil du gi mig? Jeg går jo barnløs bort, og den som skal ta mitt hus i eie, er Elieser fra Damaskus. 
1:15:3: Og Abram sa videre: Se, mig har du ikke gitt noget barn, og en tjener som er født i mitt hus, kommer til å arve mig. 
1:15:4: Men se, da kom Herrens ord til ham, og det lød så: Nei, han skal ikke arve dig, men en som skal utgå av ditt eget liv, skal arve dig. 
1:15:5: Og han førte ham utenfor og sa: Se op til himmelen og tell stjernene, om du kan telle dem! Og han sa til ham: Så skal din ætt bli. 
1:15:6: Og Abram trodde på Herren, og han regnet ham det til rettferdighet. 
1:15:7: Og han sa til ham: Jeg er Herren, som førte dig ut fra Ur i Kaldea for å gi dig dette land til eie. 
1:15:8: Da sa han: Herre, Herre, hvorav kan jeg vite at jeg skal eie det? 
1:15:9: Da sa han til ham: Hent mig en treårsgammel kvige og en treårsgammel gjet og en treårsgammel vær og en turteldue og en dueunge. 
1:15:10: Så hentet han alt dette til ham og skar dyrene midt over og la det ene stykket av hvert dyr rett imot det andre; men fuglene skar han ikke over. 
1:15:11: Og rovfugler for ned på de døde kropper, men Abram jaget dem bort. 
1:15:12: Da nu solen var nær ved å gå ned, og en dyp søvn var falt over Abram, se, da falt redsel, et stort mørke, over ham. 
1:15:13: Og han sa til Abram: Det skal du vite, at din ætt skal bo som fremmede i et land som ikke hører dem til, og de skal træle for folket der og plages av dem i fire hundre år. 
1:15:14: Men det folk som de skal træle for, vil jeg også dømme; og derefter skal de dra ut med meget gods. 
1:15:15: Men du skal fare til dine fedre i fred og bli begravet i en god alderdom. 
1:15:16: Og i det fjerde ættledd skal de komme hit igjen; for amorittene har ennu ikke fylt sin ondskaps mål. 
1:15:17: Da nu solen var gått ned, og det var blitt aldeles mørkt, fikk han se en rykende ovn og en luende ild som for frem mellem kjøttstykkene. 
1:15:18: Den dag gjorde Herren en pakt med Abram og sa: Din ætt gir jeg dette land, fra Egyptens elv like til den store elv, elven Frat, 
1:15:19: kenittenes og kenisittenes og kadmonittenes 
1:15:20: og hetittenes og ferisittenes og refa'ittenes 
1:15:21: og amorittenes og kana'anittenes og girgasittenes og jebusittenes land. 
1:16:1: Sarai, Abrams hustru, fødte ham ikke barn; men hun hadde en egyptisk trælkvinne, som hette Hagar. 
1:16:2: Og Sarai sa til Abram: Se, Herren har nektet mig barn; gå derfor inn til min trælkvinne! Kanskje jeg kunde få et barn ved henne. Og Abram lød Sarais råd. 
1:16:3: Så tok Sarai, Abrams hustru, og lot Abram, sin mann, få egypterkvinnen Hagar, som var hennes trælkvinne, til hustru; da var det ti år siden Abram hadde bosatt sig i Kana'ans land. 
1:16:4: Og han gikk inn til Hagar, og hun blev fruktsommelig; men da hun så at hun var blitt fruktsommelig, ringeaktet hun sin frue. 
1:16:5: Da sa Sarai til Abram: Den urett jeg lider, er du skyld i; jeg har selv gitt min trælkvinne i din favn; men nu, da hun ser at hun er fruktsommelig, ringeakter hun mig; Herren skal dømme mellem mig og dig. 
1:16:6: Da sa Abram til Sarai: Se, din trælkvinne råder du selv over; gjør med henne som du synes. Og Sarai var hård mot henne, og hun rømte fra henne. 
1:16:7: Men Herrens engel fant henne ved vannkilden i ørkenen, ved kilden på veien til Sur. 
1:16:8: Og han sa: Hagar, Sarais trælkvinne, hvor kommer du fra, og hvor akter du dig hen? Hun svarte: Jeg har flyktet fra min frue Sarai. 
1:16:9: Da sa Herrens engel til henne: Gå tilbake til din frue, og bøi dig under henne! 
1:16:10: Og Herrens engel sa til henne: Jeg vil gjøre din ætt så tallrik at den ikke skal kunne telles for mengde. 
1:16:11: Og Herrens engel sa videre til henne: Se, du er fruktsommelig og skal føde en sønn, og du skal kalle ham Ismael*; for Herren har hørt din nød. # <* Gud hører.> 
1:16:12: Og han skal bli et vill-asen av et menneske; hans hånd skal være mot alle, og alles hånd mot ham; og han skal bo østenfor alle sine brødre. 
1:16:13: Og hun gav Herren, som hadde talt med henne, navnet "Du er Gud, den som ser". For hun sa: Har jeg virkelig fått se ham som ser mig? 
1:16:14: Derfor kaller de brønnen Lakai Ro'is brønn*; den ligger mellem Kades og Bered. # <* d.e. den levendes brønn som ser mig.> 
1:16:15: Og Hagar fødte Abram en sønn; og Abram kalte den sønn som Hagar hadde født ham, Ismael. 
1:16:16: Abram var seks og åtti år gammel da Hagar fødte ham Ismael. 
1:17:1: Da Abram var ni og nitti år gammel, åpenbarte Herren sig for ham og sa til ham: Jeg er Gud den allmektige; vandre for mitt åsyn og vær ustraffelig! 
1:17:2: Jeg vil gjøre en pakt mellem mig og dig, og jeg vil gjøre din ætt såre tallrik. 
1:17:3: Da falt Abram på sitt ansikt; og Gud talte med ham og sa: 
1:17:4: Se, jeg gjør en pakt med dig, og du skal bli far til en mengde folk. 
1:17:5: Ditt navn skal ikke mere være Abram; men ditt navn skal være Abraham, for jeg gjør dig til far for en mengde folk. 
1:17:6: Og jeg vil gjøre dig såre fruktbar, så du blir til mange folk, og konger skal utgå fra dig. 
1:17:7: Og jeg vil oprette en pakt mellem mig og dig og din ætt efter dig, fra slekt til slekt, en evig pakt, så jeg vil være din Gud og Gud for din ætt efter dig. 
1:17:8: Og jeg vil gi dig og din ætt efter dig det land hvor du bor som fremmed, hele Kana'ans land, til en evig eiendom; og jeg vil være deres Gud. 
1:17:9: Derefter sa Gud til Abraham: Og du skal holde min pakt, du og din ætt efter dig, fra slekt til slekt. 
1:17:10: Dette er den pakt mellem mig og eder og din ætt efter dig som I skal holde: Alt mannkjønn hos eder skal omskjæres 
1:17:11: I skal omskjæres på eders forhud; det skal være tegnet på pakten mellem mig og eder. 
1:17:12: Åtte dager gammelt skal hvert guttebarn hos eder omskjæres, slekt efter slekt, både den som er født hjemme, og den som er kjøpt for penger, alle som hører til et fremmed folk og ikke er av din ætt. 
1:17:13: Omskjæres skal både den som er født i ditt hus, og den som er kjøpt for dine penger. Så skal min pakt være på eders kjøtt - en evig pakt. 
1:17:14: Men en uomskåret av mannkjønn, en hvis forhud ikke blir omskåret, han skal utryddes av sitt folk; han har brutt min pakt. 
1:17:15: Og Gud sa til Abraham: Sarai, din hustru, skal du ikke lenger kalle Sarai - Sara skal være hennes navn. 
1:17:16: Og jeg vil velsigne henne, og jeg vil gi dig en sønn også med henne; ja, jeg vil velsigne henne, og hun skal bli til mange folk, konger over folkeslag skal fremgå av henne. 
1:17:17: Da falt Abraham på sitt ansikt og lo, og han sa ved sig selv: Skulde en som er hundre år gammel, få barn? Og skulde Sara, som er nitti år gammel, føde? 
1:17:18: Og Abraham sa til Gud: Måtte bare Ismael få leve for ditt åsyn! 
1:17:19: Da sa Gud: Sannelig, Sara, din hustru, skal føde dig en sønn, og du skal kalle ham Isak, og jeg vil oprette min pakt med ham, en evig pakt for hans ætt efter ham. 
1:17:20: Og Ismael - også om ham har jeg hørt din bønn: Se, jeg vil velsigne ham og gjøre ham fruktbar og gi ham en såre tallrik ætt; tolv høvdinger skal han bli far til, og jeg vil gjøre ham til et stort folk. 
1:17:21: Men min pakt vil jeg oprette med Isak, som Sara skal føde dig på denne tid næste år. 
1:17:22: Så holdt han op å tale med ham; og Gud for op igjen fra Abraham. 
1:17:23: Samme dag tok Abraham Ismael, sin sønn, og alle som var født i hans hus, og alle som var kjøpt for hans penger, alt mannkjønn blandt folkene i Abrahams hus, og omskar deres forhud, således som Gud hadde sagt til ham. 
1:17:24: Abraham var ni og nitti år gammel da hans forhud blev omskåret. 
1:17:25: Og Ismael, hans sønn, var tretten år da hans forhud blev omskåret. 
1:17:26: Denne samme dag blev de omskåret, både Abraham og Ismael, hans sønn; 
1:17:27: og alle menn i hans hus, både de som var født hjemme, og de fremmede som var kjøpt for penger. blev omskåret med ham. 
1:18:1: Og Herren åpenbarte sig for ham i Mamres terebinte-lund, mens han satt i døren til sitt telt midt på heteste dagen. 
1:18:2: Da han så op, fikk han se tre menn som stod foran ham; og da han blev dem var, løp han dem i møte fra teltdøren og bøide sig til jorden 
1:18:3: og sa: Herre! Dersom jeg har funnet nåde for dine øine, så gå ikke din tjener forbi! 
1:18:4: La oss få hente litt vann, så I kan få tvette eders føtter, og hvil eder under treet! 
1:18:5: Og la mig hente et stykke brød, så I kan vederkvege eder før I drar videre - siden I nu har lagt veien om eders tjener. De sa: Ja, gjør som du sier! 
1:18:6: Da skyndte Abraham sig inn i teltet til Sara og sa: Skynd dig og ta tre mål fint mel; elt det og bak kaker! 
1:18:7: Og selv sprang Abraham bort til buskapen og hentet en fin og god kalv; den gav han til drengen, og han skyndte sig å lage den til. 
1:18:8: Så tok han rømme og søt melk og kalven som han hadde latt drengen lage til, og satte det for dem; og han stod hos dem under treet mens de åt. 
1:18:9: Da sa de til ham: Hvor er Sara, din hustru? Han svarte: Hun er der inne i teltet. 
1:18:10: Da sa han*: Jeg vil komme igjen til dig på denne tid næste år, og da skal Sara, din hustru, ha en sønn. Og Sara hørte det i teltdøren, for den var bak ham. # <* Herren 1MO 18 13.> 
1:18:11: Men Abraham og Sara var gamle og langt ute i årene; Sara hadde det ikke lenger på kvinners vis. 
1:18:12: Derfor lo Sara ved sig selv og sa: Skulde jeg, nu jeg er blitt gammel, ha attrå, og min mann er også gammel. 
1:18:13: Da sa Herren til Abraham: Hvorfor ler Sara og tenker: Skal jeg da virkelig få barn, nu jeg er blitt gammel? 
1:18:14: Skulde nogen ting være umulig for Herren? På denne tid næste år vil jeg komme til dig igjen, og da skal Sara ha en sønn. 
1:18:15: Men Sara nektet og sa: Jeg lo ikke. For hun var redd. Da sa han: Jo, du lo. 
1:18:16: Så stod mennene op for å gå derfra, og de tok veien bortimot Sodoma; og Abraham gikk med dem for å følge dem på veien. 
1:18:17: Da sa Herren: Skulde jeg vel dølge for Abraham det som jeg tenker å gjøre? 
1:18:18: Abraham skal jo bli et stort og tallrikt folk, og alle jordens folk skal velsignes i ham; 
1:18:19: for jeg har utvalgt ham forat han skal byde sine barn og sitt hus efter sig at de skal holde sig efter Herrens vei og gjøre rett og rettferdighet, så Herren kan la Abraham få det som han har lovt ham. 
1:18:20: Og Herren sa: Ropet over Sodoma og Gomorra er sannelig stort, og deres synd er sannelig meget svær. 
1:18:21: Jeg vil stige ned og se om de i alt har båret sig slik at som det lyder det rop som er nådd op til mig, og hvis ikke, så vil jeg vite det. 
1:18:22: Så vendte mennene sig derfra og gikk til Sodoma, men Abraham blev stående for Herrens åsyn. 
1:18:23: Og Abraham trådte nærmere og sa: Vil du da rykke den rettferdige bort sammen med den ugudelige? 
1:18:24: Kanskje det er femti rettferdige i byen; vil du da rykke dem bort og ikke spare byen for de femti rettferdiges skyld som kunde være der? 
1:18:25: Det være langt fra dig å gjøre slikt og slå ihjel den rettferdige sammen med den ugudelige, så det går den rettferdige på samme vis som den ugudelige! Det være langt fra dig! Den som dømmer hele jorden, skulde ikke han gjøre rett? 
1:18:26: Da sa Herren: Dersom jeg i byen Sodoma finner femti rettferdige, da vil jeg spare hele byen for deres skyld. 
1:18:27: Men Abraham tok atter til orde og sa: Se, jeg har dristet mig til å tale til Herren, enda jeg er støv og aske; 
1:18:28: kanskje det fattes fem i de femti rettferdige, vil du da ødelegge hele byen for disse fems skyld? Han svarte: Jeg skal ikke ødelegge den dersom jeg finner fem og firti der. 
1:18:29: Men han blev ennu ved å tale til ham og sa: Kanskje det finnes firti. Han svarte: Jeg skal ikke gjøre det - for de firtis skyld. 
1:18:30: Men han sa: Herre, bli ikke vred om jeg taler! Kanskje det finnes tretti. Han svarte: Jeg skal ikke gjøre det dersom jeg finner tretti der. 
1:18:31: Da sa han: Se, jeg har dristet mig til å tale til Herren; kanskje det finnes tyve. Han svarte: Jeg skal ikke ødelegge den - for de tyves skyld. 
1:18:32: Da sa han: Herre, bli ikke vred om jeg taler bare denne ene gang til! Kanskje det finnes ti. Han svarte: Jeg skal ikke ødelegge den - for de tis skyld. 
1:18:33: Så gikk Herren bort, da han hadde talt ut med Abraham; og Abraham vendte tilbake til det sted hvor han bodde. 
1:19:1: Og de to engler kom til Sodoma om aftenen, mens Lot satt i Sodomas port; og da Lot så dem, stod han op og gikk dem i møte og bøide sig med sitt ansikt til jorden 
1:19:2: og sa: I herrer! Ta inn i eders tjeners hus og bli der inatt, og tvett eders føtter! Så kan I stå tidlig op imorgen og dra videre. Men de sa: Nei, vi vil bli på gaten inatt. 
1:19:3: Da nødde han dem meget, og de tok inn hos ham i hans hus; og han gjorde i stand et måltid for dem og bakte usyrede brød, og de åt. 
1:19:4: Før de ennu hadde lagt sig, kom byens folk, mennene i Sodoma, både unge og gamle, hele folket fra alle kanter og omringet huset. 
1:19:5: Og de ropte på Lot og sa til ham: Hvor er de menn som er kommet til dig inatt? Før dem ut til oss, så vi kan få vår vilje med dem! 
1:19:6: Da gikk Lot ut til dem i døren og lukket den efter sig 
1:19:7: og sa: Mine brødre, gjør da ikke så ond en gjerning! 
1:19:8: Se, jeg har to døtre som ikke har hatt med nogen mann å gjøre; la mig få føre dem ut til eder, og gjør med dem som I synes! Gjør bare ikke disse menn noget, siden de er kommet inn under skyggen av mitt tak! 
1:19:9: Men de ropte: Gå av veien! Og så sa de: Her er denne ene mann kommet for å bo som fremmed her, og så vil han alltid opkaste sig til dommer! Nu vil vi fare verre med dig enn med dem. Så trengte de hårdt inn på mannen, på Lot, og stormet frem for å sprenge døren. 
1:19:10: Da strakte mennene hånden ut og tok Lot inn til sig i huset og lukket døren. 
1:19:11: Og de folk som stod utenfor døren til huset, slo de med blindhet, både små og store, så de forgjeves søkte å finne døren. 
1:19:12: Da sa mennene til Lot: Har du ennu nogen her, enten svigersønn eller sønner eller døtre eller nogen annen som hører dig til i byen, så før dem bort fra dette sted! 
1:19:13: For nu skal vi ødelegge dette sted, fordi et sterkt klagerop over dem er nådd op til Herren, og Herren har sendt oss for å ødelegge det. 
1:19:14: Da gikk Lot ut og talte til sine svigersønner, dem som skulde ha hans døtre. og sa: Stå op og gå bort fra dette sted! For Herren vil ødelegge byen. Men hans svigersønner tenkte at han bare spøkte. 
1:19:15: Da nu morgenen grydde, skyndte englene på Lot og sa: Stå op, ta din hustru og dine to døtre som er her, forat du ikke skal bli revet bort på grunn av alt det onde som er gjort her i byen! 
1:19:16: Og da han nølte, tok mennene ham og hans hustru og hans to døtre ved hånden, fordi Herren vilde spare ham; og de førte ham ut og slapp ham ikke før de var ute av byen. 
1:19:17: Da de hadde ført dem ut, sa den ene: Fly for ditt livs skyld, se dig ikke tilbake og stans ikke på hele sletten, fly op i fjellene, forat du ikke skal bli revet bort! 
1:19:18: Da sa Lot til dem: Å nei, Herre! 
1:19:19: Se, din tjener har funnet nåde for dine øine, og stor er den miskunnhet du har vist mig ved å frelse mitt liv. Men jeg kan ikke fly op i fjellene; for da kunde ulykken nå mig, så jeg døde. 
1:19:20: Se, byen der borte er nær og lett å fly til, og den er liten; la mig da fly dit - er den ikke liten? - så jeg kan berge livet! 
1:19:21: Da sa han til ham: Vel, jeg har også bønnhørt dig i dette stykke; jeg skal ikke ødelegge den by du taler om. 
1:19:22: Skynd dig, fly dit! For jeg kan intet gjøre før du kommer dit. Derfor kaller de den by Soar*. # <* d.e. småby.> 
1:19:23: Solen var gått op over jorden da Lot kom til Soar. 
1:19:24: Da lot Herren det regne svovel og ild - fra Herren, fra himmelen - ned over Sodoma og Gomorra. 
1:19:25: Og han ødela disse byer og hele sletten og alle dem som bodde i byene, og det som vokste på marken. 
1:19:26: Men Lots hustru, som fulgte efter ham, så sig tilbake; da blev hun til en saltstøtte. 
1:19:27: Tidlig om morgenen gikk Abraham til det sted hvor han hadde stått for Herrens åsyn. 
1:19:28: Og han så utover Sodoma og Gomorra og utover hele landet på sletten; da fikk han se at røken steg op fra landet som røken fra en smelteovn. 
1:19:29: Således gikk det til at da Gud ødela byene på sletten, da kom Gud Abraham i hu og førte Lot midt ut av ødeleggelsen - dengang han ødela de byer som Lot hadde bodd i. 
1:19:30: Og Lot drog fra Soar op i fjellene og blev boende der, og hans to døtre med ham, for han torde ikke bo i Soar. Og han bodde i en hule, han og hans to døtre. 
1:19:31: Da sa den eldste til den yngste: Vår far er gammel, og det finnes ingen mann her i landet som kan gå inn til oss efter all verdens vis. 
1:19:32: Kom, la oss gi vår far vin å drikke og legge oss hos ham, så vi kan holde ætten i live ved vår far! 
1:19:33: Så gav de sin far vin å drikke den natt; og den eldste gikk inn og la sig hos sin far, og han merket det ikke, hverken da hun la sig, eller da hun stod op. 
1:19:34: Dagen efter sa den eldste til den yngste: Se, inatt lå jeg hos min far; la oss også denne natt gi ham vin å drikke, og gå så du inn og legg dig hos ham, så vi kan holde ætten i live ved vår far! 
1:19:35: Så gav de også den natt sin far vin å drikke; og den yngste gikk og la sig hos ham, og han merket det ikke, hverken da hun la sig, eller da hun stod op. 
1:19:36: Og begge Lots døtre blev fruktsommelige ved sin far. 
1:19:37: Og den eldste fødte en sønn og kalte ham Moab; han er stamfar til moabittene, som er til den dag idag. 
1:19:38: Den yngste fødte også en sønn og kalte ham Ben-Ammi; han er stamfar til ammonittene, som også er til den dag idag. 
1:20:1: Så drog Abraham derfra til sydlandet og bodde mellem Kades og Sur, og siden opholdt han sig en tid i Gerar. 
1:20:2: Og Abraham sa om Sara, sin hustru: Hun er min søster. Da sendte Abimelek, kongen i Gerar, folk avsted og tok Sara. 
1:20:3: Men Gud kom til Abimelek i en drøm om natten og sa til ham: Nu skal du dø for den kvinnes skyld som du har tatt; for hun er en annen manns hustru. 
1:20:4: Men Abimelek hadde ikke kommet henne nær, og han sa: Herre, vil du da også slå rettferdige folk ihjel? 
1:20:5: Har han ikke selv sagt til mig: Hun er min søster? Og hun har også sagt: Han er min bror. I mitt hjertes uskyldighet og med rene hender har jeg gjort dette. 
1:20:6: Og Gud sa til ham i drømmen: Ja, jeg vet at du har gjort dette i ditt hjertes uskyldighet, og jeg har også hindret dig fra å synde mot mig; derfor har jeg ikke latt dig få røre henne. 
1:20:7: Men la nu mannen få sin hustru tilbake! For han er en profet. Og han skal bede for dig, så du får leve. Men dersom du ikke gir henne tilbake, da vit at du visselig skal dø, du og alle dine. 
1:20:8: Da stod Abimelek tidlig op om i morgenen og kalte på alle sine tjenere og fortalte dem alt dette, og mennene blev meget redde. 
1:20:9: Og Abimelek kalte Abraham til sig og sa til ham: Hvad er det du har gjort mot oss! Hvad har jeg syndet mot dig, siden du har ført så stor en synd over mig og mitt rike? Du har båret dig slik at mot mig som ingen skulde gjøre. 
1:20:10: Og Abimelek sa videre til Abraham: Hvad mente du med å gjøre dette? 
1:20:11: Da sa Abraham: Jeg tenkte: Det er visst ingen gudsfrykt på dette sted, og de vil slå mig ihjel for min hustrus skyld. 
1:20:12: Hun er også virkelig min søster, min fars datter, men ikke min mors datter; og hun blev min hustru. 
1:20:13: Og da Gud bød mig å vandre om borte fra min fars hus, sa jeg til henne: Således må du vise din kjærlighet mot mig: Hvor vi så kommer, må du si om mig: Han er min bror. 
1:20:14: Så tok Abimelek småfe og storfe og træler og trælkvinner og gav Abraham og lot ham få Sara, sin hustru, tilbake. 
1:20:15: Og Abimelek sa: Se, mitt land ligger åpent for dig; bo hvor du selv vil! 
1:20:16: Og til Sara sa han: Se, her gir jeg din bror tusen sekel sølv; det skal være en sonegave for dig i alles øine som er sammen med dig, og for alle har du nu fått opreisning. 
1:20:17: Og Abraham bad til Gud, og Gud helbredet Abimelek og hans hustru og hans tjenestekvinner, så de fikk barn. 
1:20:18: For Herren hadde aldeles lukket for hvert morsliv i Abimeleks hus for Abrahams hustru Saras skyld. 
1:21:1: Og Herren så til Sara som han hadde sagt, og Herren gjorde med Sara som han hadde lovt. 
1:21:2: Sara blev fruktsommelig og fødte Abraham en sønn i hans alderdom på den fastsatte tid som Gud hadde talt til ham om. 
1:21:3: Og Abraham kalte den sønn han hadde fått, den som Sara hadde født ham, Isak. 
1:21:4: Og Abraham omskar Isak, sin sønn, da han var åtte dager gammel, således som Gud hadde befalt ham. 
1:21:5: Abraham var hundre år gammel da han fikk sin sønn Isak. 
1:21:6: Da sa Sara: Gud har gjort det så at jeg må le; alle som hører dette, vil le av mig. 
1:21:7: Og hun sa: Hvem skulde vel ha sagt til Abraham at Sara gir barn å die? Og nu har jeg født ham en sønn i hans alderdom. 
1:21:8: Og gutten vokste op og blev avvent; og Abraham gjorde et stort gjestebud den dag Isak blev avvent. 
1:21:9: Og Sara så at egypterkvinnen Hagars sønn, som hun hadde født Abraham, spottet, 
1:21:10: og hun sa til Abraham: Driv ut denne trælkvinne og hennes sønn! For denne trælkvinnes sønn skal ikke arve med min sønn, med Isak. 
1:21:11: Dette gjorde Abraham meget ondt for hans sønns skyld. 
1:21:12: Men Gud sa til Abraham: La det ikke gjøre dig ondt for guttens og for din trælkvinnes skyld! Lyd Sara i alt det hun sier til dig! For i Isak skal det nevnes dig en ætt. 
1:21:13: Men også trælkvinnens sønn vil jeg gjøre til et folk, fordi han er din sønn. 
1:21:14: Da stod Abraham tidlig op om morgenen og tok brød og en skinnsekk med vann og gav Hagar og la det på hennes skulder; han gav henne også gutten med og lot henne fare. Og hun gikk avsted og for vill i Be'erseba-ørkenen. 
1:21:15: Da det var forbi med vannet i sekken, kastet hun gutten fra sig under en busk 
1:21:16: og gikk bort og satte sig et stykke ifra, sa langt som et bueskudd; for hun tenkte: Jeg vil ikke se på at gutten dør. Så satt hun et stykke ifra og brast i gråt. 
1:21:17: Men Gud hørte gutten ynke sig, og Guds engel ropte til Hagar fra himmelen og sa til henne: Hvad fattes dig, Hagar? Frykt ikke! For Gud har hørt gutten ynke sig der han ligger. 
1:21:18: Reis dig, løft gutten op og hold ham ved hånden! For jeg vil gjøre ham til et stort folk. 
1:21:19: Og Gud åpnet hennes øine, så hun så en brønn; da gikk hun dit og fylte sekken med vann og gav gutten å drikke. 
1:21:20: Og Gud var med gutten; han blev stor og bodde i ørkenen, og da han vokste til, blev han bueskytter. 
1:21:21: Han bosatte sig i ørkenen Paran, og hans mor tok en hustru til ham fra Egypten. 
1:21:22: Ved denne tid kom Abimelek og Pikol, hans hærfører, og sa til Abraham: Gud er med dig i alt det du gjør. 
1:21:23: Så tilsverg mig nu her ved Gud at du ikke vil fare med svik mot mig og mine barn og min ætt! Likesom jeg har vist godhet mot dig, så skal du gjøre det samme mot mig og mot det land du bor i som fremmed. 
1:21:24: Da sa Abraham: Ja, det skal jeg tilsverge dig. 
1:21:25: Men Abraham gikk i rette med Abimelek for en brønn som Abimeleks tjenere hadde tatt med vold. 
1:21:26: Da sa Abimelek: Jeg vet ikke hvem som har gjort dette; hverken har du sagt mig det, eller har jeg hørt det før idag. 
1:21:27: Da tok Abraham småfe og storfe og gav Abimelek, og de gjorde en pakt med hverandre. 
1:21:28: Og Abraham stilte syv får av småfeet for sig selv. 
1:21:29: Da sa Abimelek til Abraham Hvad skal disse syv får her som du har stilt for sig selv? 
1:21:30: Han svarte: Disse syv får skal du ta imot av mig; det skal være til et vidnesbyrd for mig at jeg har gravd denne brønn. 
1:21:31: Derfor kalte de dette sted Be'erseba*; for der gjorde de begge sin ed. # <* Edsbrønnen.> 
1:21:32: Så gjorde de da en pakt i Be'erseba; og Abimelek og Pikol, hans hærfører, brøt op og vendte tilbake til filistrenes land. 
1:21:33: Og Abraham plantet en tamarisk i Be'erseba, og der påkalte han Herrens, den evige Guds navn. 
1:21:34: Og Abraham bodde som fremmed i filistrenes land en lang tid. 
1:22:1: Nogen tid derefter satte Gud Abraham på prøve, og han sa til ham: Abraham! Og han svarte: Ja, her er jeg. 
1:22:2: Da sa han: Ta din sønn, din eneste, ham som du har så kjær, Isak, og gå til Moria land og ofre ham der til brennoffer på et av fjellene, som jeg skal si dig! 
1:22:3: Så stod Abraham tidlig op om morgenen og lesste på sitt asen og tok to av sine drenger med sig og Isak, sin sønn; han kløvde ved til brennofferet og gav sig på veien til det sted Gud hadde sagt ham. 
1:22:4: På den tredje dag, da Abraham så sig omkring, fikk han øie på stedet langt borte. 
1:22:5: Da sa Abraham til sine drenger: Bli I her med asenet! Jeg og gutten, vi vil gå dit bort og bede og så komme tilbake til eder. 
1:22:6: Så tok Abraham veden til brennofferet og la den på Isak, sin sønn, og selv tok han ilden og kniven i sin hånd; og så gikk de begge sammen. 
1:22:7: Da talte Isak til Abraham, sin far, og sa: Du far! Han svarte: Ja, min sønn! Han sa: Se, her er ilden og veden, men hvor er lammet til brennofferet? 
1:22:8: Abraham svarte: Gud skal selv utse sig lammet til brennofferet, min sønn! Så gikk de begge sammen. 
1:22:9: Og da de kom til det sted Gud hadde sagt ham, bygget Abraham et alter der og la veden til rette; så bandt han Isak, sin sønn, og la ham på alteret ovenpå veden. 
1:22:10: Og Abraham rakte ut hånden og tok kniven for å ofre sin sønn. 
1:22:11: Da ropte Herrens engel til ham fra himmelen og sa: Abraham, Abraham! - Han svarte: Ja, her er jeg. 
1:22:12: Da sa han: Legg ikke hånd på gutten og gjør ham ikke noget! For nu vet jeg at du frykter Gud, siden du ikke har spart din eneste sønn for min skyld. 
1:22:13: Da nu Abraham så op, fikk han se en vær bakenfor sig, som hang fast i buskene med sine horn; og Abraham gikk bort og tok væren og ofret den til brennoffer istedenfor sin sønn. 
1:22:14: Og Abraham kalte dette sted: Herren ser. Derfor sier folk den dag idag: På Herrens berg skal han la sig se. 
1:22:15: Og Herrens engel ropte ennu en gang til Abraham fra himmelen 
1:22:16: og sa: Ved mig selv sverger jeg, sier Herren: Fordi du gjorde dette og ikke sparte din eneste sønn, 
1:22:17: så vil jeg storlig velsigne dig og gjøre din ætt såre tallrik, som stjernene på himmelen og som sanden på havets bredd, og din ætt skal ta sine fienders porter i eie; 
1:22:18: og i din ætt skal alle jordens folk velsignes, fordi du lød mitt ord. 
1:22:19: Så gikk Abraham tilbake til sine drenger, og de brøt op og drog sammen til Be'erseba; og Abraham blev boende i Be'erseba. 
1:22:20: Nogen tid derefter kom det nogen og sa til Abraham: Din bror Nakor og Milka har også fått sønner: 
1:22:21: Us, den eldste, og Bus, hans bror, og Kemuel, far til Aram, 
1:22:22: og Kesed og Haso og Pildas og Jidlaf og Betuel. 
1:22:23: Og Betuel var far til Rebekka. Disse åtte barn fikk Nakor, Abrahams bror, med Milka. 
1:22:24: Også hans medhustru, som hette Re'uma, fikk barn: Tebah og Gaham og Tahas og Ma'aka. 
1:23:1: Saras alder blev hundre og syv og tyve år; det var Saras leveår. 
1:23:2: Og Sara døde i Kirjat-Arba, det er Hebron, i Kana'ans land; og Abraham kom for å holde sørgehøitid og gråte over Sara. 
1:23:3: Derefter stod Abraham op og gikk bort fra sin døde hustru, og han talte til Hets barn og sa: 
1:23:4: Jeg er en fremmed og utlending hos eder; la mig få et gravsted til eiendom hos eder, så jeg kan føre min døde hustru bort og begrave henne! 
1:23:5: Da svarte Hets barn Abraham og sa til ham: 
1:23:6: Hør på oss, herre! Du er en Guds høvding iblandt oss; begrav din døde i den beste av våre graver! Ingen av oss skal nekte dig sin grav til å begrave henne i. 
1:23:7: Da stod Abraham op og bøide sig for landets folk, for Hets barn, 
1:23:8: og han talte med dem og sa: Dersom I samtykker i at jeg fører min døde bort og begraver henne, så hør på mig og legg et godt ord inn for mig hos Efron, Sohars sønn, 
1:23:9: at han lar mig få Makpela-hulen, som tilhører ham og ligger i utkanten av hans mark. Han skal få full betaling for den hvis han vil selge mig den, her midt iblandt eder, til eiendoms-gravsted. 
1:23:10: Men Efron satt midt iblandt Hets barn; og Efron, hetitten, svarte Abraham så Hets barn hørte på det, alle de som hørte hjemme i hans by, og sa: 
1:23:11: Nei, herre! Hør på mig: Jeg gir dig marken, og hulen som er på den, gir jeg dig også; for mine landsmenns øine gir jeg dig den: begrav din døde der! 
1:23:12: Da bøide Abraham sig for landets folk, 
1:23:13: og han talte til Efron så landets folk hørte på det, og sa: Bare du nu vilde høre på mig! Jeg betaler dig det marken er verd; ta imot det av mig og la mig få begrave min døde der! 
1:23:14: Da svarte Efron Abraham og sa til ham: 
1:23:15: Herre, hør på mig! Et jordstykke som er verd fire hundre sekel sølv, hvad har det å si mellem mig og dig? Begrav du din døde! 
1:23:16: Og Abraham skjønte hvad Efron mente, og Abraham veide op til Efron det sølv som han hadde talt om i påhør av Hets barn, fire hundre sekel sølv, slike som var gangbare i handel. 
1:23:17: Og Efrons mark i Makpela, som ligger østenfor Mamre, både marken og hulen som er på den, og alle trærne som fantes på marken, så langt den rakk rundt omkring, blev tilskjøtet 
1:23:18: Abraham som eiendom, så Hets barn så på det, alle de som hørte hjemme i hans by. 
1:23:19: Derefter begravde Abraham Sara, sin hustru, i hulen på Makpelamarken østenfor Mamre, det er Hebron, i Kana'ans land. 
1:23:20: Og marken og hulen på den gikk fra Hets barn over til Abraham som eiendoms-gravsted. 
1:24:1: Abraham var nu gammel og langt ute i årene, og Herren hadde velsignet Abraham i alle ting. 
1:24:2: Da sa Abraham til sin tjener, han som var den eldste i hans hus og rådet over alt det han hadde: Kjære, legg din hånd under min lend, 
1:24:3: så vil jeg la dig sverge ved Herren, himmelens Gud og jordens Gud, at du ikke skal la min sønn ta sig en hustru av døtrene til kana'anitterfolket, som jeg bor iblandt, 
1:24:4: men du skal dra til mitt eget land og til min slekt og hente en hustru til min sønn Isak. 
1:24:5: Tjeneren sa til ham: Men om nu kvinnen ikke vil følge mig hit til dette land, skal jeg da føre din sønn tilbake til det land som du er kommet fra? 
1:24:6: Abraham svarte: Vokt dig for å føre min sønn tilbake dit! 
1:24:7: Herren, himmelens Gud, som førte mig bort fra min fars hus og fra mitt fedreland, og som talte til mig, og som svor mig til og sa: Din ætt vil jeg gi dette land, han skal sende sin engel foran dig, så du kan hente en hustru til min sønn derfra. 
1:24:8: Men dersom kvinnen ikke vil følge dig, da skal du være løst fra denne din ed til mig; bare du ikke fører min sønn tilbake dit! 
1:24:9: Da la tjeneren sin hånd under Abrahams, sin herres lend og lovte ham dette med ed. 
1:24:10: Så tok tjeneren ti kameler av dem som hørte hans herre til, og drog avsted, og han hadde med sig alleslags kostelige ting som hørte hans herre til; han tok avsted og drog til Mesopotamia, til Nakors by. 
1:24:11: Og han lot kamelene legge sig utenfor byen ved brønnen ved aftenstid, den tid da kvinnene pleier å komme ut og hente vann. 
1:24:12: Og han sa: Herre, min herre Abrahams Gud! La det lykkes for mig idag, og gjør miskunnhet mot min herre Abraham! 
1:24:13: Se, nu står jeg her ved denne kilde mens døtrene til mennene i byen kommer ut for å hente vann. 
1:24:14: La det nu bli så at den pike som jeg sier således til: Hell på din krukke, så jeg kan få drikke, og som da sier: Drikk, og jeg vil også gi dine kameler å drikke - at hun er den du har utsett for din tjener Isak, og av det vil jeg skjønne at du har gjort miskunnhet mot min herre. 
1:24:15: Og se, før han hadde holdt op å tale, hendte det at Rebekka, en datter av Betuel, som var sønn til Milka og Nakor, Abrahams bror, kom ut med sin krukke på skulderen. 
1:24:16: Det var en meget vakker pike, en jomfru, som ingen mann var kommet nær. Hun gikk ned til kilden og fylte sin krukke og steg op igjen. 
1:24:17: Da løp tjeneren henne i møte og sa: Kjære, la mig få drikke litt vann av din krukke! 
1:24:18: Og hun sa: Drikk, herre! Og hun skyndte sig og tok krukken ned i sin hånd og lot ham få drikke. 
1:24:19: Og da hun hadde latt ham drikke så meget han vilde, sa hun: Jeg vil også hente vann til dine kameler, til de har fått nok. 
1:24:20: Og hun skyndte sig og tømte sin krukke i vanntrauet og løp atter til brønnen efter vann og lot alle hans kameler få drikke. 
1:24:21: Og mannen så på henne og undret sig, men tidde stille for å få vite om Herren hadde latt hans reise lykkes eller ikke. 
1:24:22: Da så kamelene hadde drukket, tok mannen en gullring som veide en halv sekel, og to armbånd som veide ti sekel gull, og la dem om hennes armer. 
1:24:23: Og han sa: Hvem er du datter til? Kjære, si mig det! Er det rum nok i din fars hus, sa vi kan overnatte der. 
1:24:24: Hun svarte: Jeg er datter til Betuel, som er sønn til Nakor og Milka. 
1:24:25: Så sa hun til ham: Det er fullt op både av halm og fôr hos oss, og det er også rum til å overnatte. 
1:24:26: Da bøide mannen sig ned og tilbad Herren 
1:24:27: og sa: Lovet være Herren, min herre Abrahams Gud, som ikke har tatt sin miskunn og sin trofasthet fra min herre! Herren har ført mig på rette vei til min herres frender. 
1:24:28: Men piken sprang hjem til sin mor og fortalte alt dette. 
1:24:29: Rebekka hadde en bror, som hette Laban; han sprang ut til mannen der ute ved kilden. 
1:24:30: For da han så ringen og armbåndene som hans søster bar, og hørte sin søster Rebekka si: Således talte mannen til mig, gikk han ut til mannen, og se, der stod han hos kamelene ved kilden. 
1:24:31: og han sa: Kom inn, du Herrens velsignede! Hvorfor står du her ute? Jeg har jo gjort huset i stand, og der er rum for kamelene. 
1:24:32: Så gikk mannen inn i huset og lesste av kamelene; og Laban gav kamelene halm og fôr og kom med vann til ham og de menn som var med ham, til å tvette føttene i. 
1:24:33: Og de satte frem mat for ham; men han sa: Jeg vil ikke ete før jeg har båret frem mitt ærend. Og Laban sa: Tal! 
1:24:34: Da sa han: Jeg er Abrahams tjener; 
1:24:35: og Herren har storlig velsignet min herre, så han er blitt rik, og han har gitt ham småfe og storfe og sølv og gull og træler og trælkvinner og kameler og asener. 
1:24:36: Og Sara, min herres hustru, fødte min herre en sønn på sine gamle dager; og ham har han gitt alt det han eier. 
1:24:37: Og min herre lot mig sverge og sa: Du skal ikke la min sønn ta sig en hustru av døtrene til kana'anitterfolket, i hvis land jeg bor; 
1:24:38: men du skal gå til min fars hus og til min slekt og hente en hustru til min sønn. 
1:24:39: Da sa jeg til min herre: Men om nu kvinnen ikke vil følge mig? 
1:24:40: Men han sa til mig: Herren, for hvis åsyn jeg har vandret, skal sende sin engel med dig og la din reise lykkes, sa du finner en hustru til min sønn av min slekt og av min fars hus. 
1:24:41: Da skal du være løst fra din ed til mig, når du kommer til mitt folk; og vil de ikke gi dig henne, så er du også løst fra eden til mig. 
1:24:42: Så kom jeg da idag til kilden, og jeg sa: Herre, min herre Abrahams Gud! Å, om du vilde la denne min reise lykkes! 
1:24:43: Se, nu står jeg her ved denne kilde; la det nu bli så at om en ung pike kommer ut for å hente vann, og jeg sier til henne: Kjære, la mig få litt vann av din krukke, 
1:24:44: og hun da sier til mig: Drikk du, og jeg vil også hente vann til dine kameler - så er hun den kvinne som Herren har utsett for min herres sønn. 
1:24:45: Før jeg hadde holdt op å tale således ved mig selv, se, da kom Rebekka ut med sin krukke på skulderen, og hun gikk ned til kilden og øste op vann; da sa jeg til henne: Kjære, la mig få drikke! 
1:24:46: Og hun skyndte sig og tok sin krukke ned og sa: Drikk, og jeg vil også la dine kameler få drikke. Så drakk jeg, og hun lot også kamelene få drikke. 
1:24:47: Og jeg spurte henne: Hvem er du datter til? Hun svarte: Jeg er datter til Betuel, som er sønn til Nakor og Milka. Da satte jeg ringen i hennes nese og armbåndene på hennes armer. 
1:24:48: Og jeg bøide mig ned og tilbad Herren, og jeg lovet Herren, min herre Abrahams Gud, som hadde ledet mig på rette vei til å finne min herres brordatter til hustru for hans sønn. 
1:24:49: Dersom I nu vil vise godhet og trofasthet mot min herre, da si mig det, og hvis ikke, da si mig det, så jeg kan vende mig til en annen kant, til høire eller til venstre. 
1:24:50: Da svarte Laban og Betuel og sa: Dette kommer fra Herren; vi kan intet si dig, hverken ondt eller godt. 
1:24:51: Se, der står Rebekka; ta henne og dra bort og la din herres sønn få henne til hustru, som Herren har sagt! 
1:24:52: Da Abrahams tjener hørte disse ord, bøide han sig til jorden for Herren. 
1:24:53: Og tjeneren tok frem sølvsmykker og gullsmykker og klær og gav Rebekka; og hennes bror og hennes mor gav han også kostelige gaver. 
1:24:54: Så åt de og drakk, han og de menn som var med ham, og de blev der om natten. Men da de stod op om morgenen, sa han: La mig nu fare hjem til min herre! 
1:24:55: Da sa hennes bror og hennes mor: La piken bli hos oss en tid, en ti dager eller så! Siden kan du reise. 
1:24:56: Men han sa til dem: Hold mig ikke tilbake, nu da Herren har latt min reise lykkes! La mig få ta avsted, så jeg kan komme hjem til min herre! 
1:24:57: Da sa de: La oss kalle på piken og spørre henne selv! 
1:24:58: Så kalte de på Rebekka og sa til henne: Vil du reise med denne mann? Hun svarte: Ja, det vil jeg. - 
1:24:59: Så lot de Rebekka, sin søster, og hennes fostermor og Abrahams tjener og hans menn reise. 
1:24:60: Og de velsignet Rebekka og sa til henne: Vår søster, bli til tusen ganger ti tusen, og måtte din ætt ta sine fienders porter i eie! 
1:24:61: Og Rebekka og hennes piker gjorde sig ferdig, og de satte sig på kamelene og fulgte med mannen og tjeneren tok Rebekka med sig og drog avsted. 
1:24:62: Isak var nettop kommet tilbake fra en vandring til brønnen Lakai Ro'i; for han bodde i sydlandet. 
1:24:63: Og Isak gikk ved aftenstid ut på marken for å ha en stille stund og da han så op, fikk han se nogen kameler som kom gående. 
1:24:64: Og da Rebekka så op, fikk hun øie på Isak; og hun skyndte sig og steg ned av kamelen. 
1:24:65: Og hun sa til tjeneren: Hvem er denne mann som kommer oss i møte på marken? Tjeneren svarte Det er min herre. Så tok hun sløret og tilhyllet sig. 
1:24:66: Og tjeneren fortalte Isak alt det han hadde gjort. 
1:24:67: Og Isak førte henne inn i sin mor Saras telt; han tok Rebekka hjem, og hun blev hans hustru, og han hadde henne kjær. Så blev Isak trøstet i sorgen over sin mor. 
1:25:1: Og Abraham tok sig atter en hustru; hun hette Ketura. 
1:25:2: Med henne fikk han Simran og Joksan og Medan og Midian og Jisbak og Suah. 
1:25:3: Og Joksan fikk sønnene Sjeba og Dedan; og Dedans barn var assurerne og letuserne og le'ummerne 
1:25:4: Og Midians barn var Efa og Efer og Hanok og Abida og Elda'a; alle disse var Keturas barn. 
1:25:5: Og Abraham gav Isak alt det han eide. 
1:25:6: Men sønnene til de medhustruer som Abraham hadde, gav han gaver og lot dem, mens han enn levde, flytte bort fra Isak, sin sønn, østover, til Østerland. 
1:25:7: Abrahams levetid og den alder han nådde, var hundre og fem og sytti år. 
1:25:8: Så opgav Abraham ånden og døde i en god alderdom, gammel og mett av dager, og han blev samlet til sine fedre. 
1:25:9: Og Isak og Ismael, hans sønner, begravde ham i Makpela-hulen på den mark som hadde tilhørt hetitten Efron, Sohars sønn, østenfor Mamre, 
1:25:10: den mark Abraham hadde kjøpt av Hets barn; der blev Abraham begravet likesom Sara, hans hustru. 
1:25:11: Og efter Abrahams død velsignet Gud Isak, hans sønn. Og Isak bodde ved brønnen Lakai Ro'i. 
1:25:12: Dette er Ismaels, Abrahams sønns ættetavle, han som Abraham fikk med egypterkvinnen Hagar, Saras trælkvinne. 
1:25:13: Dette er navnene på Ismaels sønner - de navn som de har i sin ættetavle: Nebajot, Ismaels førstefødte, og Kedar og Adbe'el og Mibsam. 
1:25:14: og Misma og Duma og Massa, 
1:25:15: Hadar og Tema, Jetur, Nafis og Kedma. 
1:25:16: Dette var Ismaels sønner, og dette var deres navn, i deres byer og leire, tolv ættehøvdinger. 
1:25:17: Ismaels leveår var hundre og syv og tretti år; så opgav han ånden og døde og blev samlet til sine fedre. 
1:25:18: Og de bodde fra Havila til Sur, som ligger midt for Egypten, og bortimot Assyria; han nedsatte sig østenfor alle sine brødre. 
1:25:19: Dette er historien om Abrahams sønn Isaks ætt: Abraham fikk sønnen Isak. 
1:25:20: Og Isak var firti år gammel da han ektet Rebekka, som var datter til arameeren Betuel fra Mesopotamia og søster til arameeren Laban. 
1:25:21: Og Isak bad til Herren for sin hustru, for hun var barnløs; og Herren bønnhørte ham, og Rebekka, hans hustru, blev fruktsommelig. 
1:25:22: Men fosterne støtte til hverandre i hennes liv; da sa hun: Er det således, hvorfor skal jeg da være til? Og hun gikk for å spørre Herren. 
1:25:23: Og Herren sa til henne: I ditt liv er det to folk, og fra ditt skjød skal to folkeslag skille sig at; det ene folk skal være sterkere enn det andre, og den eldste skal tjene den yngste. 
1:25:24: Da nu hennes tid var kommet at hun skulde føde, se, da var det tvillinger i hennes liv. 
1:25:25: Og den som kom først frem, var rød og over hele kroppen som en lodden kappe; og de kalte ham Esau*. # <* d.e. den lodne.> 
1:25:26: Derefter kom hans bror frem, og hans hånd holdt i Esaus hæl, og ham kalte de Jakob*. Isak var seksti år gammel da de blev født. # <* d.e. han holder i hælen.> 
1:25:27: Da nu guttene vokste til, blev Esau en dyktig jeger, en mann som holdt til i skog og mark; men Jakob var en stillferdig mann, som holdt sig ved teltene. 
1:25:28: Og Isak holdt mest av Esau, for han var glad i vilt; men Rebekka holdt mest av Jakob. 
1:25:29: Engang da Jakob holdt på å koke en velling, kom Esau hjem fra marken og var rent opgitt. 
1:25:30: Og Esau sa til Jakob: La mig få til livs noget av det røde, det røde du har der, for jeg er rent opgitt! Derfor kalte de ham Edom*. # <* d.e. den røde.> 
1:25:31: Men Jakob sa: Selg mig først din førstefødselsrett! 
1:25:32: Esau svarte: Jeg holder på å dø; hvad skal jeg da med førstefødselsretten? 
1:25:33: Da sa Jakob: Gjør først din ed på det! Og han gjorde sin ed på det og solgte så sin førstefødselsrett til Jakob. 
1:25:34: Og Jakob gav Esau brød og linsevelling, og han åt og drakk og stod op og gikk sin vei. Således ringeaktet Esau førstefødselsretten. 
1:26:1: Og det var atter hungersnød i landet - likesom forrige gang på Abrahams tid; og Isak drog til filistrenes konge Abimelek i Gerar. 
1:26:2: Da åpenbarte Herren sig for ham og sa: Dra ikke ned til Egypten! Bo i det land som jeg sier dig! 
1:26:3: Bli boende her i landet! Jeg vil være med dig og velsigne dig; for dig og din ætt vil jeg gi alle disse land - jeg vil holde den ed jeg har svoret Abraham, din far. 
1:26:4: Og jeg vil gjøre din ætt tallrik som stjernene på himmelen, og jeg vil gi din ætt alle disse land, og i din ætt skal alle jordens folk velsignes, 
1:26:5: fordi Abraham lød mitt ord og holdt alt det jeg bød ham å holde, mine bud, mine forskrifter og mine lover. 
1:26:6: Så blev Isak boende i Gerar. 
1:26:7: Og mennene der på stedet spurte ham ut om hans hustru. Da sa han: Hun er min søster. For han torde ikke si at hun var hans hustru; han tenkte: Mennene her på stedet kunde da slå mig ihjel for Rebekkas skyld, siden hun er så vakker. 
1:26:8: Da han nu hadde vært der en tid, hendte det engang at Abimelek, filistrenes konge, så ut gjennem vinduet og fikk se at Isak kjærtegnet Rebekka, sin hustru. 
1:26:9: Da kalte Abimelek Isak til sig og sa: Hun er jo din hustru, hvor kunde du da si: Hun er min søster? Isak svarte: Jeg tenkte jeg kunde komme til å miste livet for hennes skyld. 
1:26:10: Da sa Abimelek: Hvorfor har du gjort dette mot oss? Hvor lett kunde det ikke ha hendt at en eller annen av folket hadde lagt sig hos din hustru, og da hadde du ført skyld over oss. 
1:26:11: Så bød Abimelek alt folket og sa: Den som rører ved denne mann eller hans hustru, han skal late sitt liv. 
1:26:12: Isak sådde korn der i landet og fikk det år hundre fold, for Herren velsignet ham. 
1:26:13: Og han blev en rik mann, og blev rikere og rikere, så han til sist var overmåte rik. 
1:26:14: Han eide småfe og storfe og mange tjenere, så filistrene blev misunnelige på ham. 
1:26:15: Og alle de brønner som hans fars tjenere hadde gravd i Abrahams, hans fars dager, dem kastet filistrene til og fylte dem med jord. 
1:26:16: Og Abimelek sa til Isak: Dra bort fra oss, for du er blitt oss altfor mektig. 
1:26:17: Så drog Isak derfra og slo leir i Gerar-dalen og blev boende der. 
1:26:18: Og Isak gravde op igjen de brønner som de hadde gravd i Abrahams, hans fars dager, og som filistrene hadde kastet til efter Abrahams død; og han gav dem de samme navn som hans far hadde gitt dem. 
1:26:19: Og Isaks tjenere gravde i dalen og fant der en brønn med rinnende vann. 
1:26:20: Men hyrdene fra Gerar trettet med Isaks hyrder og sa: Vannet hører oss til. Og han kalte brønnen Esek*, fordi de stredes med ham. # <* strid.> 
1:26:21: Siden gravde de en annen brønn, og den trettet de også om; og han kalte den Sitna*. # <* fiendskap.> 
1:26:22: Så brøt han op derfra og gravde ennu en brønn; den trettet de ikke om; og han kalte den Rehobot* og sa: Nu har Herren gjort det rummelig for oss, så vi kan bli tallrike i landet. # <* åpent rum.> 
1:26:23: Siden drog han derfra op til Be'erseba. 
1:26:24: samme natt åpenbarte Herren sig for ham og sa: Jeg er Abrahams, din fars Gud; frykt ikke, for jeg er med dig, og jeg vil velsigne dig og gjøre din ætt tallrik for Abrahams, min tjeners skyld. 
1:26:25: Der bygget han et alter og påkalte Herrens navn; og han slo op sitt telt der; og Isaks tjenere gravde der en brønn. 
1:26:26: Siden kom Abimelek til ham fra Gerar, med Akussat, sin venn, og Pikol, sin hærfører. 
1:26:27: Da sa Isak til dem: Hvorfor kommer I til mig, I som hater mig og har drevet mig bort fra eder? 
1:26:28: De svarte: Vi har sett det grant at Herren er med dig; derfor sier vi: Kom, la oss sverge en ed oss imellem, vi og du, og la oss få gjøre en pakt med dig, 
1:26:29: at du ikke skal gjøre oss noget ondt, likesom heller ikke vi har rørt dig, men bare gjort dig godt og latt dig fare i fred. Du er nu Herrens velsignede. 
1:26:30: Så gjorde han et gjestebud for dem, og de åt og drakk. 
1:26:31: Morgenen efter stod de tidlig op og svor hverandre sin ed; siden lot Isak dem fare, og de drog fra ham fred. 
1:26:32: Samme dag hendte det at Isaks tjenere kom og fortalte ham om den brønn de hadde gravd, og sa til ham: Vi har funnet vann. 
1:26:33: Og han kalte den Siba*; derfor heter byen Be'erseba** den dag i dag. # <* ed.> # <** 1MO 21, 31.> 
1:26:34: Da Esau var firti år gammel, tok han til hustruer Judit, datter til hetitten Be'eri, og Basmat, datter til hetitten Elon. 
1:26:35: Men de blev en hjertesorg for Isak og Rebekka. 
1:27:1: Da Isak var blitt gammel, og hans øine var blitt sløve, så han ikke kunde se, kalte han til sig Esau, sin eldste sønn, og sa til ham: Min sønn! Han svarte: Ja, her er jeg. 
1:27:2: Da sa han: Jeg er blitt gammel og vet ikke hvad dag jeg skal dø. 
1:27:3: Så ta nu dine jaktredskaper, ditt kogger og din bue, og gå ut på marken og skyt mig noget vilt, 
1:27:4: og lag en velsmakende rett for mig, slik som jeg liker det, og kom så hit med den! Da vil jeg ete, så min sjel kan velsigne dig, før jeg dør. 
1:27:5: Men Rebekka hørte på at Isak talte til Esau, sin sønn. Så gikk Esau ut på marken for å skyte noget vilt og ha det med sig hjem. 
1:27:6: Da sa Rebekka til Jakob, sin sønn: Jeg hørte din far tale til Esau, din bror, og si: 
1:27:7: Hent mig noget vilt og lag en velsmakende rett for mig, så jeg kan ete av den og velsigne dig for Herrens åsyn, før jeg dør. 
1:27:8: Lyd nu mitt ord, min sønn, og gjør det jeg byder dig: 
1:27:9: Gå bort til hjorden og hent mig derfra to gode kje, så skal jeg lage en velsmakende rett av dem for din far, slik som han liker det. 
1:27:10: Og du skal gå inn med den til din far, så han kan ete av den og velsigne dig, før han dør. 
1:27:11: Da sa Jakob til Rebekka, sin mor: Esau, min bror, er jo lodden, og jeg er glatt. 
1:27:12: Kanskje min far kjenner på mig og så tror at jeg vil ha ham til narr, og jeg kommer til å føre en forbannelse over mig og ikke en velsignelse. 
1:27:13: Da sa hans mor til ham: Den forbannelse skal jeg ta på mig min sønn! Bare lyd mitt råd og gå og hent mig kjeene! 
1:27:14: Da gikk han og hentet dem og kom til sin mor med dem, og hans mor laget en velsmakende rett, slik som hans far likte det. 
1:27:15: Så tok Rebekka sin eldste sønn Esaus høitidsklær, som hun hadde hos sig i huset, og hun lot Jakob, sin yngste sønn, ta dem på. 
1:27:16: Men skinnene av kjeene hadde hun om hans hender og om den glatte del av hans hals. 
1:27:17: Så lot hun sin sønn Jakob få den velsmakende rett og brødet som hun hadde laget, 
1:27:18: og han gikk inn til sin far og sa: Far! Han svarte: Ja, her er jeg; hvem er du, min sønn? 
1:27:19: Da sa Jakob til sin far: Jeg er Esau, din førstefødte; jeg har gjort som du sa til mig. Sett dig nu op og et av mitt vilt, så din sjel kan velsigne mig! 
1:27:20: Men Isak sa til sin sønn: Hvorledes har du da så snart kunnet finne noget, min sønn? Han, svarte: Herren din Gud sendte mig det i møte. 
1:27:21: Da sa Isak til Jakob: Kom hit og la mig få kjenne på dig, min sønn, om du er min sønn Esau, eller ikke. 
1:27:22: Så gikk Jakob frem til Isak, sin far; og han kjente på ham og sa: Røsten er Jakobs, men hendene er Esaus. 
1:27:23: Og han kjente ham ikke, fordi hans hender var lodne som hans bror Esaus hender; og han velsignet ham. 
1:27:24: Og han sa: Er du virkelig min sønn Esau? Han svarte: Ja, det er jeg. 
1:27:25: Da sa han: Kom hit med det til mig og la mig få ete av min sønns vilt, så min sjel kan velsigne dig. Så satte han det frem for ham, og han åt, og han kom med vin til ham, og han drakk. 
1:27:26: Så sa Isak, hans far, til ham: Kom nu hit og kyss mig, min sønn! 
1:27:27: Da gikk han frem og kysset ham; og han kjente lukten av hans klær og velsignet ham og sa: Se, duften av min sønn er som duften av en mark som Herren har velsignet. 
1:27:28: Så gi Gud dig av himmelens dugg og av jordens fedme og korn og most i overflod. 
1:27:29: Folk skal tjene dig, og folkeslag skal falle dig til fote; vær herre over dine brødre, og måtte din mors sønner falle dig til fote! Forbannet være den som forbanner dig, og velsignet den som velsigner dig! 
1:27:30: Men da Isak hadde endt sin velsignelse over Jakob, og Jakob nettop var gått ut fra Isak, sin far, da kom Esau, hans bror, hjem fra jakten. 
1:27:31: Han laget også en velsmakende rett og bar den inn til sin far; og han sa til sin far: Vil ikke far reise sig op og ete av sin sønns vilt, så din sjel kan velsigne mig! 
1:27:32: Og Isak, hans far, spurte ham: Hvem er du? Han svarte: Jeg er Esau, din førstefødte sønn. 
1:27:33: Da blev Isak overmåte forferdet, og han sa: Hvem var det da som hadde skutt noget vilt og kom til mig med det? Jeg åt av alt, før du kom, og velsignet ham! Han skal også være velsignet. 
1:27:34: Da Esau hørte disse ord av sin far, satte han i et høit og sårt skrik, og han sa til sin far: Velsign også mig, min far! 
1:27:35: Men han sa: Din bror kom med list og tok din velsignelse. 
1:27:36: Da sa han: Er det fordi han har fått navnet Jakob, at han nu to ganger har overlistet mig? Min førstefødselsrett tok han, og se, nu har han tatt min velsignelse. Så sa han: Har du ikke en velsignelse igjen til mig og? 
1:27:37: Isak svarte og sa til Esau: Se, jeg har satt ham til herre over dig, og alle hans brødre har jeg gjort til hans tjenere, og jeg har gitt ham korn og most i overflod; hvad skal jeg da gjøre for dig, min sønn? 
1:27:38: Og Esau sa til sin far: Har du da bare denne ene velsignelse, min far? Velsign også mig, min far! Og Esau gråt høit. 
1:27:39: Da tok Isak, hans far, til orde igjen og sa til ham: Se, uten jordens fedme skal din bolig være og uten himmelens dugg fra oven. 
1:27:40: Av ditt sverd skal du leve, og din bror skal du tjene; men når du engang river dig løs, da skal du bryte hans åk av din nakke. 
1:27:41: Og Esau hatet Jakob for den velsignelse som hans far hadde lyst over ham. Og Esau sa ved sig selv: Snart kommer den tid da vi må sørge over min far; da skal jeg slå Jakob, min bror, ihjel. 
1:27:42: Og Rebekka fikk vite hvad Esau, hennes eldste sønn, hadde sagt; da sendte hun bud efter Jakob, sin yngste sønn, og sa til ham: Se, Esau, din bror, vil hevne sig på dig og slå dig ihjel. 
1:27:43: Lyd nu mitt ord, min sønn: Gjør dig ferdig og flykt til min bror Laban i Karan, 
1:27:44: og bli hos ham en tid, til din brors vrede har lagt sig, 
1:27:45: til din brors vrede har vendt sig fra dig, og han har glemt det du har gjort mot ham! Da skal jeg sende bud og hente dig derfra. Hvorfor skulde jeg miste eder begge på én dag! 
1:27:46: Så sa Rebekka til Isak: Jeg er kjed av mitt liv for disse Hets døtres skyld; skulde nu også Jakob ta sig en hustru av Hets døtre, slik en som disse, en av landets døtre, hvad skulde jeg da leve efter? 
1:28:1: Da kalte Isak Jakob til sig og velsignet ham og bød ham og sa til ham: Du skal ikke ta nogen av Kana'ans døtre til hustru. 
1:28:2: Gjør dig rede og dra til Mesopotamia, til din morfar Betuels hus, og hent dig en hustru derfra, en av din morbror Labans døtre! 
1:28:3: Og Gud den allmektige velsigne dig og gjøre dig fruktbar og gi dig en tallrik ætt, så du blir til en hel skare av folkeslag, 
1:28:4: han gi dig Abrahams velsignelse, både dig og din ætt, så du kommer til å eie det land hvor du nu bor som fremmed, det som Gud gav Abraham! 
1:28:5: Så lot Isak Jakob reise; og han drog til Mesopotamia, til arameeren Laban, Betuels sønn, som var bror til Rebekka, Jakobs og Esaus mor. 
1:28:6: Da Esau så at Isak hadde velsignet Jakob og sendt ham til Mesopotamia for å hente sig en hustru derfra - at han hadde velsignet ham og gitt ham den befaling: Du skal ikke ta nogen av Kana'ans døtre til hustru - 
1:28:7: og at Jakob hadde adlydt sin far og sin mor og var reist til Mesopotamia, 
1:28:8: og da Esau så at Kana'ans døtre mishaget Isak, hans far, 
1:28:9: så gikk han til Ismael og tok Mahalat, datter til Abrahams sønn Ismael, Nebajots søster, til hustru foruten sine andre hustruer. 
1:28:10: Jakob tok ut fra Be'erseba og gav sig på veien til Karan. 
1:28:11: Og han kom til et sted hvor han blev natten over, for solen var gått ned; og han tok en av stenene som lå der, og la den under sitt hode, og så la han sig til å sove der. 
1:28:12: Da drømte han og så en stige som var stilt op på jorden, og hvis topp nådde til himmelen, og se, Guds engler steg op og steg ned på den. 
1:28:13: Og se, Herren stod øverst på den og sa: Jeg er Herren, din far Abrahams Gud og Isaks Gud; det land som du nu ligger i, det vil jeg gi dig og din ætt. 
1:28:14: Og din ætt skal bli som støvet på jorden, og du skal utbrede dig mot vest og mot øst og mot nord og mot syd, og i dig og i din ætt skal alle jordens slekter velsignes 
1:28:15: Og se, jeg er med dig og vil bevare dig hvor du så går, og jeg vil føre dig tilbake til dette land; jeg vil ikke forlate dig før jeg har gjort det jeg har lovt dig. 
1:28:16: Da Jakob våknet av sin søvn, sa han: Sannelig, Herren er på dette sted, og jeg visste det ikke. 
1:28:17: Og det kom en frykt over ham, og han sa: Hvor forferdelig er ikke dette sted ! Her er visselig Guds hus, her er himmelens port. 
1:28:18: Morgenen efter stod Jakob tidlig op og tok den sten han hadde lagt under sitt hode, og reiste den op som en minnesten, og han helte olje over den. 
1:28:19: Og han kalte dette sted Betel*; før hette byen Luz. # <* Guds hus.> 
1:28:20: Og Jakob gjorde et løfte og sa: Dersom Gud vil være med mig og bevare mig på denne min ferd og gi mig brød å ete og klær å klæ mig med, 
1:28:21: og jeg kommer vel hjem igjen til min fars hus, så skal Herren være min Gud, 
1:28:22: og denne sten som jeg har reist op som en minnesten, skal være et Guds hus, og av alt det du gir mig, vil jeg gi dig tiende. 
1:29:1: Så gav Jakob sig atter på veien og drog til Østens barns land. 
1:29:2: Og da han så sig omkring, fikk han se en brønn på marken, og ved den lå det tre flokker småfe. for av denne brønn vannet de feet; men stenen som lå over brønnens åpning, var stor. 
1:29:3: Der samlet alle feflokkene sig, og gjæterne veltet stenen fra brønnens åpning og vannet småfeet, og så la de stenen tilbake på sitt sted, over brønnens åpning. 
1:29:4: Jakob spurte dem: Mine brødre, hvor er I fra? De svarte: Vi er fra Karan. 
1:29:5: Så spurte han dem: Kjenner I Laban, sønn til Nakor? De svarte: Ja, vi kjenner ham. 
1:29:6: Da spurte han dem: Står det vel til med ham? De svarte: Ja, det gjør det, og se, der kommer hans datter Rakel med småfeet. 
1:29:7: Da sa han: Det er jo ennu høi dag, det er ennu ikke tid til å samle buskapen; vann småfeet og gå avsted igjen og gjæt! 
1:29:8: Men de sa: Det kan vi ikke før alle feflokkene er samlet, og stenen blir veltet fra brønnens åpning; da vanner vi småfeet. 
1:29:9: Mens han ennu talte med dem, kom Rakel med sin fars småfe; for det var hun som gjætte. 
1:29:10: Og da Jakob så Rakel, sin morbror Labans datter, og så småfeet som hørte hans morbror til, gikk han frem og veltet stenen fra brønnens åpning og vannet sin morbror Labans småfe. 
1:29:11: Og Jakob kysset Rakel og brast i gråt. 
1:29:12: Og Jakob fortalte Rakel at han var hennes fars frende, og at han var sønn til Rebekka; da sprang hun avsted og fortalte det til sin far. 
1:29:13: Da nu Laban fikk høre om Jakob, sin søstersønn, løp han ham i møte og omfavnet ham og kysset ham og førte ham inn i sitt hus; og han fortalte Laban alt det som hadde hendt. 
1:29:14: Da sa Laban til ham: Sannelig, vi er av samme kjød og blod. Og han blev hos ham en måneds tid. 
1:29:15: Så sa Laban til Jakob: Skulde du tjene mig for intet, fordi om du er min frende? Si mig hvad du vil ha i lønn! 
1:29:16: Nu hadde Laban to døtre, den eldste hette Lea, og den yngste hette Rakel. 
1:29:17: Lea hadde matte øine; men Rakel var vakker av skapning og vakker å se til. 
1:29:18: Og Jakob hadde Rakel kjær; derfor sa han: Jeg vil tjene dig syv år for Rakel, din yngste datter. 
1:29:19: Laban svarte: Det er bedre at jeg gir henne til dig, enn at jeg gir henne til en annen mann; bli hos mig! 
1:29:20: Så tjente Jakob i syv år for Rakel; og disse år syntes han var nogen få dager, fordi han hadde henne kjær. 
1:29:21: Derefter sa Jakob til Laban: La mig nu få min hustru, for min tid er ute, og jeg vil gå inn til henne. 
1:29:22: Da samlet Laban alle menn der på stedet og gjorde et gjestebud. 
1:29:23: Og om aftenen tok han sin datter Lea og førte henne inn til ham, og han gikk inn til henne. 
1:29:24: Og Laban gav sin trælkvinne Silpa til sin datter Lea som trælkvinne. 
1:29:25: Men om morgenen - se, da var det Lea. Da sa han til Laban: Hvad er det du har gjort mot mig? Var det ikke for Rakel jeg tjente hos dig? Hvorfor har du sveket mig? 
1:29:26: Laban svarte: Det er ikke skikk her hos oss å gi den yngste bort før den eldste. 
1:29:27: La nu Leas bryllups-uke gå til ende, så vil vi også gi dig den andre, hvis du vil tjene hos mig i syv år til. 
1:29:28: Og Jakob gjorde så, og han lot bryllups-uken gå til ende. Da gav han ham sin datter Rakel til hustru. 
1:29:29: Og Laban gav sin trælkvinne Bilha til sin datter Rakel som trælkvinne. 
1:29:30: Så gikk han også inn til Rakel, og han holdt mere av Rakel enn av Lea. Siden tjente han ennu syv år til hos Laban. 
1:29:31: Da Herren så at Lea blev tilsidesatt, åpnet han hennes morsliv; men Rakel var barnløs. 
1:29:32: Og Lea blev fruktsommelig og fødte en sønn, og hun kalte ham Ruben*; for hun sa: Herren har sett til mig i min ulykke; nu vil min mann elske mig. # <* se, en sønn!.> 
1:29:33: Og hun blev atter fruktsommelig og fødte en sønn og sa: Herren har hørt at jeg var tilsidesatt; derfor har han gitt mig også denne sønn. Så kalte hun ham Simeon*. # <* bønnhørelse.> 
1:29:34: Og hun blev atter fruktsommelig og fødte en sønn og sa: Nu må vel endelig min mann holde sig til mig, for jeg har født ham tre sønner. Derfor kalte de ham Levi*. # <* vedhengen.> 
1:29:35: Og hun blev atter fruktsommelig og fødte en sønn og sa: Nu vil jeg prise Herren. Derfor kalte hun ham Juda*. Så fikk hun ikke flere barn da. # <* den for hvem Herren prises; egentlig: den priste.> 
1:30:1: Da Rakel så at hun og Jakob ikke fikk barn, blev hun misunnelig på sin søster og sa til Jakob: La mig få barn! Ellers dør jeg. 
1:30:2: Da optendtes Jakobs vrede mot Rakel, og han sa: Er jeg i Guds sted, som har nektet dig livsfrukt? 
1:30:3: Da sa hun: Se, der er min trælkvinne Bilha; gå inn til henne, forat hun kan føde på mine knær*, så også jeg kan få barn ved henne! # <* d.e. føde barn som jeg kan ta til mig som mine.> 
1:30:4: Så gav hun sin trælkvinne Bilha til hustru, og Jakob gikk inn til henne. 
1:30:5: Og Bilha blev fruktsommelig og fødte Jakob en sønn. 
1:30:6: Da sa Rakel: Gud har dømt i min sak; han har hørt min bønn og gitt mig en sønn. Derfor kalte hun ham Dan*. # <* dommer.> 
1:30:7: Og Bilha, Rakels trælkvinne, blev atter fruktsommelig og fødte Jakob ennu en sønn. 
1:30:8: Da sa Rakel: Jeg har kjempet Guds kamper med min søster, og nu har jeg vunnet. Og hun kalte ham Naftali*. # <* den jeg har vunnet med kamp.> 
1:30:9: Da Lea så at hun ikke fikk flere barn, tok hun sin trælkvinne Silpa og gav Jakob til hustru. 
1:30:10: Og Silpa, Leas trælkvinne, fødte Jakob en sønn. 
1:30:11: Da sa Lea: Til lykke! Og hun kalte ham Gad*. # <* lykke.> 
1:30:12: Og Silpa, Leas trælkvinne, fødte Jakob ennu en sønn. 
1:30:13: Da sa Lea: Hvor lykkelig jeg er! For alle kvinner vil prise mig lykkelig. Og hun kalte ham Aser*. # <* lykkelig.> 
1:30:14: En dag i hvetehøstens tid gikk Ruben ut og fant alruner* på marken og bar dem hjem til Lea, sin mor; da sa Rakel til Lea: Kjære, gi mig nogen av din sønns alruner! # <* d.e. et slags plante som mentes å kunne vekke elskov og fremkalle svangerskap.> 
1:30:15: Men hun svarte henne: Er det ikke nok at du har tatt min mann? Vil du nu også ta min sønns alruner? Da sa Rakel: Nu vel, han kan sove hos dig inatt, hvis jeg får din sønns alruner! 
1:30:16: Da Jakob om aftenen kom hjem fra marken, gikk Lea ham i møte og sa: Det er hos mig du skal være inatt; jeg har tinget dig for min sønns alruner. Så lå han hos henne den natt. 
1:30:17: Og Gud hørte Lea, og hun blev fruktsommelig og fødte Jakob en femte sønn. 
1:30:18: Da sa Lea: Gud har gitt mig min lønn, fordi jeg lot min mann få min trælkvinne. Og hun kalte ham Issakar*. # <* han kommer med lønn.> 
1:30:19: Og Lea blev atter fruktsommelig og fødte Jakob en sjette sønn. 
1:30:20: Da sa Lea: Gud har gitt mig en god gave; nu kommer min mann til å bo hos mig, for jeg har født ham seks sønner. Og hun kalte ham Sebulon*. # <* boende mann.> 
1:30:21: Siden fødte hun en datter og kalte henne Dina. 
1:30:22: Da kom Gud Rakel i hu, og Gud hørte henne og åpnet hennes morsliv. 
1:30:23: Hun blev fruktsommelig og fødte en sønn. Da sa hun: Gud har tatt bort min skam. 
1:30:24: Og hun kalte ham Josef* og sa: Herren gi mig ennu en sønn! # <* han legger til.> 
1:30:25: Da nu Rakel hadde født Josef, sa Jakob til Laban: La mig fare, så jeg kan dra hjem til mitt eget land! 
1:30:26: Gi mig mine hustruer og mine barn, som jeg har tjent dig for, så vil jeg dra bort; du vet jo selv hvorledes jeg har tjent dig. 
1:30:27: Da sa Laban til ham: Om du bare hadde nogen godhet for mig! Jeg er blitt varslet om at det er for din skyld Herren har velsignet mig. 
1:30:28: Så sa han: Si selv hvad du vil ha i lønn, så skal jeg gi dig det. 
1:30:29: Og Jakob sa til ham: Du vet selv hvorledes jeg har tjent dig, og hvad din buskap er blitt til under mine hender. 
1:30:30: For det var lite det du hadde før jeg kom, men nu har det øket til en stor mengde, og Herren har velsignet dig hvor jeg satte min fot. Men når skal jeg nu også få gjøre noget for mitt eget hus? 
1:30:31: Da sa Laban: Hvad skal jeg gi dig? Jakob svarte: Du skal ikke gi mig noget; dersom du vil gjøre som jeg nu sier, så skal jeg gjæte din buskap og vokte den, som jeg har gjort. 
1:30:32: Jeg vil idag gå gjennem hele din hjord og skille ut alt som er flekket og spraglet og alt som er sort blandt fårene, og likeså alt som er spraglet og flekket blandt gjetene; og det skal være min lønn. 
1:30:33: Og min ærlighet skal vidne for mig, når du siden engang kommer og ser over min lønn. Finnes det hos mig nogen gjet som ikke er flekket og spraglet, og noget får som ikke er sort, så er det stjålet. 
1:30:34: Da sa Laban: Vel, la det være som du har sagt! 
1:30:35: Og samme dag skilte han ut de stripete og spraglete gjetebukker og alle flekkete og spraglete gjeter, alt det som hadde noget hvitt på sig, og alt sort blandt fårene; og han lot sine sønner ta vare på det, 
1:30:36: og han la tre dagsreiser mellem sig og Jakob. Og Jakob gjætte resten av Labans småfe. 
1:30:37: Men Jakob tok sig friske kjepper av poppel-, hassel- og lønnetrær og skavde hvite striper på dem, så det hvite på kjeppene kom frem. 
1:30:38: Og han la kjeppene som han hadde skavd, i rennene - i vanntrauene, hvor småfeet kom for å drikke, like foran småfeet; for de parret sig når de kom for å drikke. 
1:30:39: Så parret småfeet sig ved kjeppene og fikk stripete, flekkete og spraglete unger. 
1:30:40: Men lammene skilte Jakob ut og lot småfeet vende øinene mot det stripete og alt det sorte blandt Labans småfe; og således fikk han sig hjorder for sig selv og slapp dem ikke sammen med Labans småfe. 
1:30:41: Og hver gang det sterke småfe parret sig, la Jakob kjeppene midt for øinene på dem i rennene, forat de skulde parre sig ved kjeppene; 
1:30:42: men når det var svakt småfe, la han ikke kjeppene der; således kom de svake til å tilhøre Laban og de sterke Jakob. 
1:30:43: Og mannen blev rikere og rikere, og han fikk meget småfe og trælkvinner og træler og kameler og asener. 
1:31:1: Så fikk han høre at Labans sønner hadde sagt: Jakob har tatt alt det som vår far eide, og av det som vår far eide, har han lagt sig til all denne rikdom. 
1:31:2: Og Jakob så på Labans ansikt at han ikke var den samme mot ham som før. 
1:31:3: Og Herren sa til Jakob: Vend tilbake til dine fedres land og ditt eget folk, og jeg vil være med dig. 
1:31:4: Da sendte Jakob bud efter Rakel og Lea og bad dem komme ut på marken, hvor han var med sin buskap. 
1:31:5: Og han sa til dem: Jeg ser på eders fars ansikt at han ikke er den samme mot mig som før; men min fars Gud har vært med mig. 
1:31:6: Og I vet selv at jeg av all min evne har tjent eders far. 
1:31:7: Men eders far har sveket mig og forandret min lønn ti ganger; men Gud lot ham ikke få gjøre mig noget ondt. 
1:31:8: Når han sa: Det flekkete skal være din lønn, da fikk alt småfeet flekkete unger, og når han sa: Det stripete skal være din lønn, da fikk alt småfeet stripete unger. 
1:31:9: Således tok Gud eders fars fe fra ham og gav mig det. 
1:31:10: Og ved den tid småfeet parret sig, så jeg frem for mig i drømme og fikk se at bukkene som parret sig med småfeet, var stripete, flekkete og prikkete. 
1:31:11: Og Guds engel sa til mig i drømmen: Jakob! Og jeg sa: Ja, her er jeg. 
1:31:12: Da sa han: Se nu frem for dig, så skal du få se at alle bukkene som parrer sig med småfeet, er stripete, flekkete og prikkete; for jeg har sett alt det Laban gjør mot dig. 
1:31:13: Jeg er den Gud som du så i Betel, der hvor du salvet en minnesten, og hvor du gjorde mig et løfte; gjør dig nu rede og dra bort fra dette land, og vend tilbake til ditt fødeland. 
1:31:14: Da svarte Rakel og Lea og sa til ham: Har vi vel ennu nogen lodd og arv i vår fars hus? 
1:31:15: Har han ikke aktet oss som fremmede? Han har jo solgt oss og selv fortært det han fikk for oss. 
1:31:16: All den rikdom Gud har tatt fra vår far, den hører oss og våre barn til; gjør nu bare du alt det Gud har sagt til dig! 
1:31:17: Så gjorde Jakob sig ferdig og satte sine barn og hustruer på kamelene, 
1:31:18: og han tok med sig hele sin buskap og alt det gods som han hadde samlet sig, det fe han eide, og som han hadde lagt sig til i Mesopotamia, og vilde dra til Isak, sin far, i Kana'ans land. 
1:31:19: Men Laban hadde draget bort for å klippe sine får; da stjal Rakel sin fars husguder. 
1:31:20: Og Jakob stjal sig bort fra arameeren Laban; han sa ikke noget til ham om at han vilde flykte. 
1:31:21: Så flyktet han med alt det han hadde; han gjorde sig ferdig og satte over elven* og tok veien til Gileadfjellet. # <* Eufrat.> 
1:31:22: Den tredje dag efter fikk Laban vite at Jakob var flyktet. 
1:31:23: Da tok han med sig sine frender og satte efter ham syv dagsreiser, og nådde ham igjen på Gilead-fjellet. 
1:31:24: Men Gud kom til arameeren Laban i en drøm om natten og sa til ham: Vokt dig og si ikke noget til Jakob, hverken godt eller ondt! 
1:31:25: Da Laban nådde Jakob igjen, hadde Jakob slått op sitt telt på fjellet, og Laban med sine frender slo også op sitt telt på Gilead-fjellet. 
1:31:26: Da sa Laban til Jakob: Hvad er det du har gjort? Du har stjålet dig bort fra mig og ført mine døtre avsted som om de var tatt i krig. 
1:31:27: Hvorfor flyktet du hemmelig og stjal dig bort fra mig og sa ikke noget om det til mig, så jeg kunde ha fulgt dig på veien med gledesrop og sanger, med trommer og harper? 
1:31:28: Du lot mig ikke engang få kysse mine sønner og døtre; det var uforstandig gjort av dig. 
1:31:29: Jeg har det i min makt å gjøre ondt mot eder; men eders fars Gud sa til mig inatt: Vokt dig og si ikke noget til Jakob, hverken godt eller ondt! 
1:31:30: Men når du nu har draget bort, fordi du lengtes så såre efter din fars hus, hvorfor stjal du da mine guder? 
1:31:31: Da svarte Jakob og sa til Laban: Jeg var redd; jeg tenkte at du kunde ta dine døtre fra mig med makt. 
1:31:32: Men den som du finner dine guder hos, han skal ikke leve. Se nu efter i våre frenders nærvær, om du kjennes ved noget av det jeg har med mig, og ta det så! Men Jakob visste ikke at Rakel hadde stjålet dem. 
1:31:33: Da gikk Laban inn i Jakobs telt og i Leas telt og i begge trælkvinnenes telt, men fant ikke noget. Så gikk han ut av Leas telt og inn i Rakels telt. 
1:31:34: Men Rakel hadde tatt husgudene og lagt dem i kamelsalen og satt sig på dem; og Laban gjennemsøkte hele teltet, men fant ikke noget. 
1:31:35: Og hun sa til sin far: Min herre må ikke bli vred fordi jeg ikke kan reise mig for dig; for det går mig på kvinners vis. Så lette han efter husgudene, men fant dem ikke. 
1:31:36: Da blev Jakob vred og gikk i rette med Laban. Og Jakob tok til orde og sa til Laban: Hvad er min brøde, hvad er min synd, siden du forfølger mig så? 
1:31:37: Du har nu gjennemsøkt alt det jeg har; hvad fant du da som hører ditt hus til? Legg det frem her for mine og dine frender, så de kan dømme mellem oss to! 
1:31:38: Nu har jeg vært hos dig i tyve år; dine får og dine gjeter har ikke født i utide, og værene av ditt småfe har jeg ikke ett op; 
1:31:39: det som var sønderrevet, kom jeg ikke hjem til dig med; jeg godtgjorde selv skaden; av mig krevde du det, enten det var stjålet om dagen, eller det var stjålet om natten. 
1:31:40: Slik hadde jeg det: Om dagen fortærtes jeg av hete og av kulde om natten, og søvnen flydde fra mine øine. 
1:31:41: I tyve år har jeg nu vært i ditt hus, jeg har tjent dig fjorten år for dine to døtre og seks år for ditt småfe; men du forandret min lønn ti ganger. 
1:31:42: Hadde ikke min fars Gud vært med mig, han som var Abrahams Gud, og som også Isak frykter, sannelig, du hadde nu latt mig fare med tomme hender. Men Gud har sett min møie og alt mitt strev, og han har dømt inatt. 
1:31:43: Da svarte Laban og sa til Jakob: Døtrene er mine døtre, og barna er mine barn, og buskapen er min buskap, og alt det du ser, er mitt; hvad skulde jeg da nu kunne gjøre mot disse mine døtre eller mot deres barn, som de har født? 
1:31:44: Så kom nu og la oss gjøre en pakt, jeg og du, og den skal være et vidne mellem mig og dig. 
1:31:45: Da tok Jakob en sten og reiste den op som en minnesten. 
1:31:46: Og Jakob sa til sine frender: Sank sammen stener! Og de tok stener og laget en røs; og de holdt måltid der ved stenrøsen. 
1:31:47: Og Laban kalte den Jegar-Sahaduta, men Jakob kalte den Gal-Ed*. # <* vidnesbyrdets stenrøs.> 
1:31:48: Da sa Laban: Denne røs skal være et vidne mellem mig og dig idag. Derfor kalte de den Gal-Ed, 
1:31:49: og tillike Mispa*, fordi han sa: Herren holde vakt mellem mig og dig, når vi kommer hverandre av syne; # <* d.e. et sted hvorfra en skuer vidt omkring.> 
1:31:50: dersom du farer ille med mine døtre eller tar dig andre hustruer foruten mine døtre, da er det vel intet menneske hos oss, men se, Gud er vidne mellem mig og dig. 
1:31:51: Så sa Laban til Jakob: Se, denne røs og denne minnesten som jeg har reist mellem mig og dig - 
1:31:52: vidner skal de være, både røsen og minnestenen, at ikke skal jeg dra til dig forbi denne røs, og at heller ikke skal du dra til mig forbi denne røs og denne minnesten med ondt i sinne. 
1:31:53: Abrahams Gud og Nakors Gud skal dømme mellem oss, han som var deres fars Gud. Så svor Jakob ved ham som hans far Isak fryktet. 
1:31:54: Og Jakob ofret et slaktoffer på fjellet og innbød sine frender til måltid. Og de holdt måltid og blev natten over på fjellet. 
1:31:55: Morgenen efter stod Laban tidlig op, og han kysset sine sønner og sine døtre og velsignet dem. Så drog Laban hjem igjen. 
1:32:1: Og Jakob drog videre, og Guds engler møtte ham. 
1:32:2: Da Jakob så dem, sa han: Dette er Guds leir. Og han kalte stedet Mahana'im*. # <* d.e. to leire.> 
1:32:3: Og Jakob sendte bud foran sig til sin bror Esau i landet Se'ir, på Edoms mark. 
1:32:4: Og han bød dem og sa: Således skal I si til min herre Esau: Så sier din tjener Jakob: Jeg har opholdt mig hos Laban og vært der helt til nu, 
1:32:5: og jeg har fått okser og asener, småfe og træler og trælkvinner; og nu vilde jeg sende bud til min herre om dette for å finne nåde for dine øine 
1:32:6: Og budene kom tilbake til Jakob og sa: Vi kom til Esau, din bror, og han drar dig nu selv i møte, og fire hundre mann med ham. 
1:32:7: Da blev Jakob overmåte forferdet; og han delte folket som var med ham, og småfeet og storfeet og kamelene i to leire. 
1:32:8: For han tenkte: Om Esau kommer til den ene leir og slår den, da kan den leir som er igjen, få berge sig unda. 
1:32:9: Og Jakob sa: Min far Abrahams Gud og min far Isaks Gud, Herre, du som sa til mig: Dra tilbake til ditt land og til ditt folk, og jeg vil gjøre vel imot dig! 
1:32:10: Jeg er ringere enn all den miskunnhet og all den trofasthet som du har vist mot din tjener; for med min stav gikk jeg over Jordan her, og nu er jeg blitt til to leire. 
1:32:11: Fri mig ut av Esaus, min brors hånd; for jeg er redd han skal komme og slå ihjel mig og mine, både mor og barn. 
1:32:12: Du har jo selv sagt: Jeg vil alltid gjøre vel mot dig og la din ætt bli som havets sand, som ikke kan telles for mengde. 
1:32:13: Så blev han der den natt, og av alt det han eide, tok han ut en gave til Esau, sin bror: 
1:32:14: to hundre gjeter og tyve bukker, to hundre får og tyve værer, 
1:32:15: tretti kameler som gav die, med sine føll, firti kjør og ti okser, tyve aseninner og ti asenfoler. 
1:32:16: Og han lot sine tjenere dra avsted med dem, hver hjord for sig, og han sa til sine tjenere: Far i forveien, og la det være et mellemrum mellem hver hjord! 
1:32:17: Og han bød den første og sa: Når min bror Esau møter dig og han taler til dig og spør: Hvem hører du til, og hvor skal du hen, og hvem eier denne hjord som du driver foran dig? 
1:32:18: da skal du si: Din tjener Jakob; det er en gave han sender til min herre Esau, og snart kommer han selv efter. 
1:32:19: Og han bød likeledes den annen og den tredje og alle de andre som drev hjordene, og sa: Således skal I tale til Esau når I møter ham, 
1:32:20: I skal si: Snart kommer din tjener Jakob selv efter. For han tenkte: Jeg vil forsone ham ved den gave som jeg sender foran mig, og siden vil jeg selv trede frem for ham; kanskje han vil ta nådig imot mig. 
1:32:21: Så drog de i forveien med gaven; men selv blev han i leiren den natt. 
1:32:22: samme natt stod han op og tok sine hustruer og de to trælkvinner og sine elleve sønner og gikk over Jabboks vadested. 
1:32:23: Han tok og satte dem over åen og førte over alt det han eide. 
1:32:24: Så var Jakob alene tilbake. Da kom det en mann og kjempet med ham inntil morgenen grydde. 
1:32:25: Og da mannen så at han ikke kunde rå med ham, rørte han ved hans hofteskål; og Jakobs hofteskål gikk av ledd, mens han kjempet med ham. 
1:32:26: Og han sa: Slipp mig, for morgenen gryr! Men han sa: Jeg slipper dig ikke, uten du velsigner mig. 
1:32:27: Da sa han til ham: Hvad er ditt navn? Han svarte: Jakob. 
1:32:28: Han sa: Du skal ikke lenger hete Jakob, men Israel*; for du har kjempet med Gud og med mennesker og vunnet. # <* d.e. en som kjemper med Gud.> 
1:32:29: Da spurte Jakob: Si mig ditt navn! Han svarte: Hvorfor spør du om mitt navn? Og han velsignet ham der. 
1:32:30: Og Jakob kalte stedet Pniel*; for [sa han] jeg har sett Gud åsyn til åsyn og enda berget livet. # <* Pniel eller Pnuel betyr Guds åsyn.> 
1:32:31: Og da han var kommet forbi Pnuel så han solen rinne; og han haltet på sin hofte. 
1:32:32: Derfor er det så den dag idag at Israels barn aldrig eter spennesenen som er på hofteskålen, fordi han rørte ved Jakobs hofteskål på spennesenen. 
1:33:1: Da Jakob så op, fikk han se Esau som kom med fire hundre mann. Da delte han barna mellem Lea og Rakel og begge trælkvinnene, 
1:33:2: og han satte trælkvinnene med sine barn fremst og Lea med sine barn bakenfor dem og Rakel med Josef bakerst. 
1:33:3: Og selv gikk han foran dem og bøide sig syv ganger til jorden, inntil han kom frem til sin bror. 
1:33:4: Men Esau løp ham i møte og omfavnet ham og falt ham om halsen og kysset ham, og de gråt. 
1:33:5: Da han så op, fikk han øie på kvinnene og barna; da sa han: Hvem er det du har der? Han svarte: Det er de barn som Gud har unt din tjener. 
1:33:6: Så gikk trælkvinnene frem med sine barn og bøide sig, 
1:33:7: og Lea gikk også frem med sine barn, og de bøide sig, og derefter gikk Josef og Rakel frem og bøide sig. 
1:33:8: Da sa han: Hvad vilde du med hele den leir som jeg møtte? Han svarte: Jeg vilde finne nåde for min herres øine. 
1:33:9: Da sa Esau: Jeg har nok; ha du selv, min bror, det som ditt er! 
1:33:10: Jakob svarte: Nei, kjære! Dersom jeg har funnet nåde for dine øine, så ta imot min gave! For da jeg så ditt ansikt, var det som om jeg så Guds ansikt, siden du var så vennlig mot mig. 
1:33:11: Kjære, ta imot gaven som jeg sendte dig! For Gud har vært mig nådig, og jeg har nok av alle ting. Og han nødde ham til han tok imot det. 
1:33:12: Da sa Esau: La oss bryte op og dra videre, og la mig dra side om side med dig! 
1:33:13: Men han svarte ham: Min herre vet at barna er svake, og småfeet og storfeet har nylig båret hos mig; og driver en dem bare en eneste dag for sterkt, så dør alt småfeet. 
1:33:14: Vil ikke min herre dra foran. sin tjener, så vil jeg dra langsomt efter, som det kan passe for buskapen som drives foran mig, og for barna, inntil jeg kommer til min herre i Se'ir. 
1:33:15: Da sa Esau: Så vil jeg få lov til å la nogen av de folk jeg har med, bli hos dig. Men han svarte: Hvorfor det? La mig bare finne nåde for min herres øine! 
1:33:16: Så drog Esau samme dag sin vei tilbake til Se'ir 
1:33:17: Og Jakob drog til Sukkot og bygget sig et hus og gjorde løvhytter til sin buskap; derfor kalte de stedet Sukkot*. # <* hytter.> 
1:33:18: Og Jakob kom lykkelig frem til byen Sikem, som ligger i Kana'ans land, da han kom fra Mesopotamia; og han slo leir utenfor byen. 
1:33:19: Og det stykke mark hvor han hadde slått op sitt telt, kjøpte han av sønnene til Hemor, Sikems far, for hundre kesitter*. # <* en viss vekt sølv eller gull.> 
1:33:20: Der reiste han et alter og kalte det El Elohe Israel*. # <* d.e. Israels Gud er Gud.> 
1:34:1: Dina, Jakobs datter med Lea, gikk engang ut for å se på landets døtre. 
1:34:2: Og Sikem, som var sønn av hevitten Hemor, høvdingen i landet, så henne; og han tok henne og lå hos henne og krenket henne. 
1:34:3: Men hans hjerte hang ved Dina, Jakobs datter, og han elsket piken og talte kjærlig til henne. 
1:34:4: Så sa Sikem til Hemor, sin far: La mig få denne pike til hustru! 
1:34:5: Og Jakob fikk høre at han hadde vanæret Dina, hans datter; men hans sønner var med buskapen ute på marken, og Jakob tidde med det til de kom hjem. 
1:34:6: Men Hemor, Sikems far, gikk ut til Jakob for å tale med ham. 
1:34:7: Jakobs sønner kom hjem fra marken da de fikk høre om dette; og mennene gremmet sig og var harmfulle; for han* hadde gjort en skammelig gjerning mot Israel ved å ligge hos Jakobs datter. Slikt burde ikke skje. # <* Sikem.> 
1:34:8: Da talte Hemor med dem og sa: Min sønn Sikems hjerte henger ved eders datter; kjære, la ham få henne til hustru, 
1:34:9: og inngå svogerskap med oss, gi oss eders døtre og ta I våre døtre! 
1:34:10: Bli boende hos oss! Landet skal stå åpent for eder; bo her og dra omkring og få eder eiendommer her! 
1:34:11: Og Sikem sa til hennes far og hennes brødre: La mig finne nåde for eders øine! Det I krever av mig, vil jeg gi eder. 
1:34:12: Krev så meget I vil av mig i morgengave og andre gaver! Jeg skal gi det I vil ha; la mig bare få piken til hustru! 
1:34:13: Da svarte Jakobs sønner Sikem og Hemor, hans far, med svikefulle ord, fordi han hadde vanæret deres søster Dina, 
1:34:14: og sa til dem: Det kan vi ikke gjøre, å gi vår søster til en mann som har forhud; det vilde være en skam for oss. 
1:34:15: Bare på det vilkår vil vi være eder til vilje, at I blir som vi, og alt mannkjønn hos eder lar sig omskjære. 
1:34:16: Da vil vi gi eder våre døtre og gifte oss med eders døtre og bo hos eder, så vi blir ett folk. 
1:34:17: Men dersom I ikke vil høre på oss og la eder omskjære, da tar vi vår søster og drar bort. 
1:34:18: Og de syntes godt om deres ord, både Hemor og Sikem, Hemors sønn. 
1:34:19: Og den unge mann drygde ikke med å gjøre dette, for han var glad i Jakobs datter, og han var den som hadde mest å si i sin fars hus. 
1:34:20: Så gikk Hemor og hans sønn Sikem til porten i sin by, og de talte til mennene i byen og sa: 
1:34:21: Disse menn vil gjerne være venner med oss og vil bo her i landet og dra omkring her, og landet er jo vidt nok for dem; vi vil gifte oss med deres døtre og gi dem våre døtre. 
1:34:22: Men bare på det vilkår vil mennene være oss til vilje og bo hos oss og bli til ett folk med oss, at alt mannkjønn iblandt oss lar sig omskjære, likesom de selv er omskåret. 
1:34:23: Deres buskap og deres gods og alle deres kløvdyr, blir ikke alt det vårt når vi bare er dem til vilje, så de blir boende hos oss? 
1:34:24: Og de gjorde som Hemor og hans sønn Sikem vilde, alle som hørte hjemme i hans by; og alt mannkjønn, alle som hørte hjemme i hans by, blev omskåret. 
1:34:25: Men på den tredje dag, da de var syke av sine sår, da tok Jakobs to sønner, Simeon og Levi, Dinas brødre, hver sitt sverd, og de kom uforvarende over byen og slo alt mannkjønn ihjel. 
1:34:26: Også Hemor og Sikem, hans sønn, slo de ihjel med sverdets egg, og de tok Dina ut av Sikems hus og drog bort. 
1:34:27: Jakobs sønner kom over de drepte og plyndret byen, fordi deres søster var blitt vanæret. 
1:34:28: De tok deres småfe og storfe og deres asener, både det som var i byen, og det som var på marken. 
1:34:29: Og alt deres gods og alle deres barn og deres kvinner førte de bort som bytte, og alt annet som var i husene. 
1:34:30: Da sa Jakob til Simeon og Levi: I har gjort mig en stor sorg! I har ført mig i vanrykte hos landets innbyggere, Kana'anittene og ferisittene; jeg råder jo bare over en liten flokk, og samler de sig imot mig, kommer de til å slå mig ihjel, sa både jeg og mitt hus går til grunne. 
1:34:31: Men de svarte: Skulde han da få gjøre med vår søster som med en skjøge? 
1:35:1: Og Gud sa til Jakob: Gjør dig rede, dra op til Betel og bli der, og bygg der et alter for den Gud som åpenbarte sig for dig da du flyktet for din bror Esau! 
1:35:2: Da sa Jakob til sine husfolk og alle dem som var med ham: Ha bort de fremmede guder som finnes hos eder, og rens eder og skift klær, 
1:35:3: og la oss ta avsted og dra op til Betel; der vil jeg bygge et alter for den Gud som bønnhørte mig den dag jeg var i fare, og som var med mig på min ferd. 
1:35:4: Da lot de Jakob få alle de fremmede guder som de hadde hos sig, og ringene som de hadde i sine ører; og Jakob gravde dem ned under terebinten ved Sikem. 
1:35:5: Så brøt de op, og en redsel fra Gud kom over byene rundt omkring dem, så de ikke forfulgte Jakobs sønner. 
1:35:6: Og Jakob kom til Luz, som ligger i Kana'ans land - nu heter det Betel - han og alt det folk som var med ham. 
1:35:7: Og han bygget der et alter og kalte stedet El-Betel*; for der hadde Gud åpenbaret sig for ham da han flyktet for sin bror. # <* d.e. Betels Gud.> 
1:35:8: Da døde Debora, Rebekkas fostermor, og hun blev begravet nedenfor Betel under eken; og han kalte den gråts - eken. 
1:35:9: Og Gud åpenbarte sig atter for Jakob, da han kom fra Mesopotamia, og velsignet ham. 
1:35:10: Og Gud sa til ham: Du heter Jakob; herefter skal du ikke mere hete Jakob, men Israel skal være ditt navn. Således fikk han navnet Israel. 
1:35:11: Og Gud sa til ham: Jeg er Gud den allmektige; vær fruktbar og bli tallrik! Et folk, ja en mengde med folkeslag skal stamme fra dig, og konger skal utgå av dine lender. 
1:35:12: Og det land som jeg gav Abraham og Isak, det vil jeg gi dig; og din ætt efter dig vil jeg gi landet. 
1:35:13: Så fór Gud op fra ham på det sted hvor han hadde talt med ham. 
1:35:14: Og Jakob reiste op en minnestøtte på det sted hvor han hadde talt med ham, en minnestøtte av sten; og han øste drikkoffer på den og helte olje over den. 
1:35:15: Og Jakob kalte det sted hvor Gud hadde talt med ham, Betel. 
1:35:16: Så brøt de op fra Betel, og da det ennu var et stykke vei igjen til Efrat, fødte Rakel, og hun hadde en hård fødsel. 
1:35:17: Og under hennes hårde fødsel sa jordmoren til henne: Frykt ikke; for også denne gang får du en sønn. 
1:35:18: Men i det samme hun opgav ånden - for hun måtte dø - kalte hun ham Benoni*; men hans far kalte ham Benjamin**. # <* min smertes sønn.> <** lykkens sønn.> 
1:35:19: Så døde Rakel, og hun blev begravet på veien til Efrat, det er Betlehem. 
1:35:20: Og Jakob reiste op en minnesten på hennes grav; det er Rakels gravsten; den står der den dag idag. 
1:35:21: Så brøt Israel op igjen og slo op sitt telt bortenfor Migdal-Eder*. # <* hjordens tårn.> 
1:35:22: Og mens Israel bodde der i landet, hendte det at Ruben gikk avsted og lå hos Bilha, sin fars medhustru; og Israel fikk høre om det - Jakob hadde tolv sønner. 
1:35:23: Leas sønner var: Ruben, Jakobs førstefødte, og Simeon og Levi og Juda og Issakar og Sebulon. 
1:35:24: Rakels sønner var: Josef og Benjamin. 
1:35:25: Og Rakels trælkvinne Bilhas sønner var: Dan og Naftali. 
1:35:26: Og Leas trælkvinne Silpas sønner var: Gad og Aser. Dette var Jakobs sønner, som han fikk i Mesopotamia. 
1:35:27: Og Jakob kom til sin far Isak i Mamre ved Kirjat-Arba, det er Hebron, hvor Abraham og Isak hadde bodd som fremmede. 
1:35:28: Og Isaks dager blev hundre og åtti år. 
1:35:29: Da opgav Isak ånden og døde og blev samlet til sine fedre, gammel og mett av dager; og Esau og Jakob, hans sønner, begravde ham. 
1:36:1: Dette er Esaus eller Edoms ætt. 
1:36:2: Esau tok sig hustruer av Kana'ans døtre: Ada, hetitten Elons datter, og Oholibama, Anas datter, hevitten Sibeons sønnedatter, 
1:36:3: og Basmat, Ismaels datter, Nebajots søster. 
1:36:4: Med Ada fikk Esau Elifas, og med Basmat Re'uel, 
1:36:5: og med Oholibama fikk han Je'us og Jalam og Korah; dette var Esaus sønner, som han fikk i Kana'ans land. 
1:36:6: Og Esau tok sine hustruer, sine sønner og døtre og alt sitt husfolk og sin buskap og alle sine kløvdyr og alt sitt gods som han hadde samlet i Kana'ans land, og drog til et annet land for sin bror Jakobs skyld. 
1:36:7: For deres eiendom var for stor til at de kunde bo sammen - det land som de bodde i som fremmede, kunde ikke rumme dem, så stor buskap hadde de. 
1:36:8: Så bosatte Esau sig i Se'ir-fjellene - Esau, det er den samme som Edom. 
1:36:9: Og dette er Esaus ætt i Se'irfjellene, han som var stamfar til edomittene. 
1:36:10: Dette er navnene på Esaus sønner: Elifas, sønn av Esaus hustru Ada, og Re'uel, sønn av Esaus hustru Basmat. 
1:36:11: Og Elifas' sønner var Teman, Omar, Sefo, Gatam og Kenas. 
1:36:12: Og Elifas, Esaus sønn, hadde en medhustru som hette Timna, og med henne fikk Elifas Amalek. Dette var Esaus hustru Adas sønner. 
1:36:13: Og dette var Re'uels sønner: Nahat og Serah, Samma og Missa. Dette var Esaus hustru Basmats sønner. 
1:36:14: Og dette var sønnene til Oholibama, Anas datter, Sibeons sønnedatter, Esaus hustru: Med henne fikk Esau Je'us og Jalam og Korah. 
1:36:15: Dette var stammefyrstene for Esaus barn: Sønnene til Esaus eldste sønn Elifas var stammefyrsten Teman, stammefyrsten Omar, stammefyrsten Sefo, stammefyrsten Kenas, 
1:36:16: stammefyrsten Korah, stammefyrsten Gatam, stammefyrsten Amalek; dette var de stammefyrster som nedstammet fra Elifas i Edom-landet. Dette var Adas sønner. 
1:36:17: Og dette var sønnene til Esaus sønn Re'uel: stammefyrsten Nahat, stammefyrsten Serah, stammefyrsten Samma, stammefyrsten Missa, dette var de stammefyrster som nedstammet fra Re'uel i Edom-landet. Dette var Esaus hustru Basmats sønner. 
1:36:18: Og dette var Esaus hustru Oholibamas sønner: stammefyrsten Je'usj stammefyrsten Jalam, stammefyrsten Korah; dette var de stammefyrster som nedstammet fra Oholibama, Anas datter, Esaus hustru. 
1:36:19: Dette var Esaus sønner, og dette deres stammefyrster. Dette var Edom. 
1:36:20: Dette var horitten Se'irs sønner, som bodde i landet: Lotan og Sobal og Sibeon og Ana 
1:36:21: og Dison og Eser og Disan; dette var horittenes stammefyrster, Se'irs sønner i Edom-landet. 
1:36:22: Og Lotans sønner var Hori og Hemam, og Lotans søster var Timna. 
1:36:23: Og dette var Sobals sønner: Alvan og Manahat og Ebal, Sefo og Onam. 
1:36:24: Og dette var Sibeons sønner: Aja og Ana; det var den Ana som fant de varme kilder i ørkenen, mens han gjætte sin far Sibeons asener. 
1:36:25: Og dette var Anas barn: Dison og datteren Oholibama. 
1:36:26: Og dette var Disons sønner: Hemdan og Esban og Jitran og Keran. 
1:36:27: Dette var Esers sønner: Bilhan og Sa'avan og Akan. 
1:36:28: Dette var Disans sønner: Us og Aran. 
1:36:29: Dette var horittenes stammefyrster: stammefyrsten Lotan, stammefyrsten Sobal, stammefyrsten Sibeon, stammefyrsten Ana, 
1:36:30: stammefyrsten Dison, stammefyrsten Eser, stammefyrsten Disan; dette var horittenes stammefyrster, deres stammefyrster i Se'ir-landet. 
1:36:31: Og dette var de konger som regjerte i Edom-landet før det regjerte nogen konge over Israels barn: 
1:36:32: Bela, Beors sønn, var konge i Edom, og hans by hette Dinhaba. 
1:36:33: Da Bela døde, blev Jobab, Serahs sønn, fra Bosra konge i hans sted. 
1:36:34: Da Jobab døde, blev Husam fra temanittenes land konge i hans sted. 
1:36:35: Da Husam døde, blev Hadad, Bedads sønn, konge i hans sted; det var han som slo midianittene på Moabs mark; hans by hette Avit. 
1:36:36: Da Hadad døde, blev Samla fra Masreka konge i hans sted. 
1:36:37: Da Samla døde, blev Saul fra Rehobot ved elven konge i hans sted. 
1:36:38: Da Saul døde, blev Ba'al-Hanan, Akbors sønn, konge i hans sted. 
1:36:39: Da Ba'al-Hanan, Akbors sønn, døde, blev Hadar konge i hans sted; hans by hette Pa'u, og hans hustru hette Mehetabel, en datter av Matred, Mesahabs datter. 
1:36:40: Og dette er navnene på Esaus stammefyrster efter deres ætter, efter deres bosteder, med deres navn: stammefyrsten Timna, stammefyrsten Alva, stammefyrsten Jetet, 
1:36:41: stammefyrsten Oholibama, stammefyrsten Ela, stammefyrsten Pinon, 
1:36:42: stammefyrsten Kenas, stammefyrsten Teman, stammefyrsten Mibsar, 
1:36:43: stammefyrsten Magdiel, stammefyrsten Iram; dette var Edoms stammefyrster efter sine bosteder i sitt eiendomsland. Dette var Esaus, edomittenes fars ætt. 
1:37:1: Men Jakob bodde i det land hvor hans far hadde bodd som fremmed, i Kana'ans land. 
1:37:2: Dette er historien om Jakobs ætt. Da Josef var sytten år gammel, gjætte han buskapen sammen med sine brødre; ung som han var, fulgte han med sønnene til Bilha og Silpa, sin fars hustruer; alt det onde som blev sagt om dem, gikk han til deres far med. 
1:37:3: Men Israel hadde Josef kjær fremfor alle sine sønner, fordi han var hans alderdoms sønn; og han hadde latt gjøre en sid kjortel til ham. 
1:37:4: Og da hans brødre så at deres far hadde ham mere kjær enn alle hans brødre, hatet de ham og kunde ikke tale vennlig til ham. 
1:37:5: Engang hadde Josef en drøm, som han fortalte sine brødre; da hatet de ham enda mere. 
1:37:6: Han sa til dem: Hør nu hvad jeg har drømt: 
1:37:7: Jeg syntes vi bandt kornbånd ute på akeren, og se, mitt kornbånd reiste sig op og blev stående, og eders kornbånd stod rundt omkring og bøide sig for mitt kornbånd. 
1:37:8: Da sa hans brødre til ham: Skal du kanskje være vår konge og råde over oss? Siden hatet de ham enda mere for hans drømmer og for hans ord. 
1:37:9: Og han hadde ennu en annen drøm og fortalte den til sine brødre; han sa: Jeg hadde ennu en drøm, og se, solen og månen og elleve stjerner bøide sig for mig. 
1:37:10: Og da han fortalte det til sin far og til sine brødre, skjente hans far på ham og sa til ham: Hvad er dette for en drøm du har hatt? Skal da jeg og din mor og dine brødre komme og bøie oss til jorden for dig? 
1:37:11: Og hans brødre var misunnelige på ham; men hans far gjemte det i sitt minne. 
1:37:12: Engang gikk hans brødre avsted for å gjæte sin fars buskap i Sikem. 
1:37:13: Da sa Israel til Josef: Gjæter ikke dine brødre ved Sikem? Kom, jeg vil sende dig til dem. Og han svarte: Ja, her er jeg. 
1:37:14: Da sa han til ham: Kjære, gå og se om det står vel til med dine brødre, og om det står vel til med buskapen, og kom så tilbake til mig med svar! Så sendte han ham avsted fra Hebron-dalen, og han kom til Sikem. 
1:37:15: Mens han nu vanket om på marken, møtte han en mann, og mannen spurte ham: Hvad leter du efter? 
1:37:16: Han svarte: Jeg leter efter mine brødre; kjære, si mig hvor de gjæter! 
1:37:17: Mannen sa: De har draget herfra; jeg hørte dem si: La oss gå til Dotan! Så gikk Josef efter sine brødre og fant dem i Dotan. 
1:37:18: De så ham langt borte, og før han kom nær til dem, la de op råd om å drepe ham. 
1:37:19: De sa sig imellem: Se, der kommer denne drømmeren! 
1:37:20: Kom nu og la oss slå ham ihjel og kaste ham ned i en av brønnene her, og så vil vi si: Et vilt dyr har ett ham op. Så får vi se hvad det blir av hans drømmer. 
1:37:21: Da Ruben hørte dette, vilde han fri ham ut av deres hender og sa: La oss ikke slå ham ihjel! 
1:37:22: Og han sa til dem: Utøs ikke blod, kast ham ned i denne brønn her i ørkenen, men legg ikke hånd på ham - for han vilde fri ham ut av deres hånd og føre ham tilbake til hans far. 
1:37:23: Da nu Josef kom til sine brødre, klædde de av ham hans kjortel, den side kjortel som han hadde på. 
1:37:24: Og de tok og kastet ham ned i brønnen; men brønnen var tom, det var intet vann i den. 
1:37:25: Så satte de sig til å holde måltid; og da de så op, fikk de se et reisefølge av ismaelitter som kom fra Gilead, og deres kameler bar krydderier og balsam og ladanum*; de var på vei med dette ned til Egypten. # <* et slags velluktende gummi.> 
1:37:26: Da sa Juda til sine brødre: Hvad gagner det at vi slår var bror ihjel og skjuler drapet? 
1:37:27: Kom og la oss selge ham til ismaelittene, men la oss ikke legge hånd på ham, han er jo vår egen kjødelige bror. Og hans brødre gjorde som han sa. 
1:37:28: Da nu de midianittiske kjøbmenn kom forbi, drog de Josef op av brønnen, og så solgte de Josef til ismaelittene for tyve sekel sølv; og ismaelittene tok Josef med sig til Egypten. 
1:37:29: Da Ruben kom tilbake til brønnen, fikk han se at Josef ikke var i brønnen. Da sønderrev han sine klær, 
1:37:30: og han gikk tilbake til sine brødre og sa: Gutten er der ikke, og jeg - hvor skal jeg gjøre av mig? 
1:37:31: Så tok de Josefs kjortel og slaktet en gjetebukk og dyppet kjortelen i blodet. 
1:37:32: Og de sendte den side kjortel hjem til faren og sa: Denne har vi funnet; se efter om det ikke er din sønns kjortel! 
1:37:33: Og han kjente den igjen og sa: Jo, det er min sønns kjortel; et vilt dyr har ett ham op, Josef er visselig revet ihjel! 
1:37:34: Og Jakob sønderrev sine klær og bandt sekk om sine lender og sørget over sin sønn i lang tid. 
1:37:35: Og alle hans sønner og alle hans døtre kom for å trøste ham; men han vilde ikke la sig trøste; han sa: Med sorg må jeg fare ned til min sønn i dødsriket. Og hans far gråt over ham. . 
1:37:36: Men midianittene solgte ham i Egypten til Potifar, som var hoffmann hos Farao og høvding over livvakten. 
1:38:1: På denne tid drog Juda ned fra det sted hvor hans brødre bodde, og han tok inn til en mann i Adullam, som hette Hira. 
1:38:2: Der så Juda datteren til en kana'anittisk mann som hette Sua; og han tok henne til hustru og gikk inn til henne. 
1:38:3: Og hun blev fruktsommelig og fødte en sønn og kalte ham Er. 
1:38:4: Så blev hun atter fruktsommelig og fødte en sønn, og hun kalte ham Onan. 
1:38:5: Siden fødte hun ennu en sønn og kalte ham Sela; da han blev født, var Juda i Kesib. 
1:38:6: Og Juda tok en hustru til Er, sin førstefødte sønn; hun hette Tamar. 
1:38:7: Men Er, Judas førstefødte, mishaget Herren, og Herren lot ham dø. 
1:38:8: Da sa Juda til Onan: Gå inn til din brors hustru, og ta henne til ekte i din brors sted og opreis din bror avkom. 
1:38:9: Men Onan visste at avkommet ikke skulde høre ham til; når han derfor gikk inn til sin brors hustru, spilte han sæden på jorden for ikke å gi sin bror avkom. 
1:38:10: Men det var ondt i Herrens øine det han gjorde, og han lot også ham dø. 
1:38:11: Da sa Juda til Tamar, sin sønnekone: Bo som enke i din fars hus, til Sela, min sønn, er blitt voksen! For han tenkte: Ellers kommer også han til å dø, som hans brødre. Så drog Tamar hjem og bodde i sin fars hus. 
1:38:12: Da nu en lengere tid var gått, døde Suas datter, Judas hustru, og da sørgetiden var over, gikk Juda op til Timna, til dem som klippet hans får, og hans venn Hira fra Adullam var med ham. 
1:38:13: Da det nu blev fortalt Tamar at hennes svigerfar var på vei op til Timna for å klippe sine får, 
1:38:14: tok hun sine enkeklær av og la et slør om sig og hyllet sig inn i det og satte sig ved inngangen til Ena'im, på veien til Timna; for hun så at Sela var blitt voksen, og at hun allikevel ikke var gitt ham til hustru. 
1:38:15: Da Juda så henne, tenkte han det var en skjøge; for hun hadde tilhyllet sitt ansikt. 
1:38:16: Så bøide han av fra veien og gikk bort til henne og sa: Kom, la mig gå inn til dig! For han visste ikke at det var hans sønnekone. Da sa hun: Hvad vil du gi mig for å gå inn til mig? 
1:38:17: Han svarte: Jeg vil sende dig et kje av min buskap. Da sa hun: Ja, dersom du vil gi mig et pant, til du sender mig det. 
1:38:18: Han sa: Hvad skal jeg gi dig til pant? Hun svarte: Ditt signet og din snor og staven som du har i hånden. Det gav han henne og gikk så inn til henne, og hun blev fruktsommelig med ham. 
1:38:19: Og hun stod op og gikk sin vei, og hun la sitt slør av sig og tok på sig sine enkeklær. 
1:38:20: Og Juda sendte kjeet med sin venn fra Adullam for å få pantet tilbake av kvinnen; men han fant henne ikke. 
1:38:21: Og han spurte folkene der på stedet og sa: Hvor er skjøgen, hun i Ena'im, ved veien? De sa: Det har ikke vært nogen skjøge her. 
1:38:22: Så kom han tilbake til Juda og sa: Jeg fant henne ikke, og tilmed sa folkene der på stedet: Det har ikke vært nogen skjøge her. 
1:38:23: Da sa Juda: La henne ha det, så vi ikke skal få skam av denne sak! Kjeet har jeg jo sendt henne, men du fant henne ikke. 
1:38:24: Så gikk det omkring tre måneder; da kom folk og sa til Juda: Tamar, din sønnekone, har drevet hor, og nu er hun også blitt fruktsommelig i hor. Og Juda sa: Før henne ut, hun skal brennes! 
1:38:25: Men da hun blev ført ut, sendte hun bud til sin svigerfar og lot si: Det er med den mann som eier disse ting, jeg er blitt fruktsommelig. Og hun sa: Se efter hvem som eier dette signet og disse snorer og denne stav! 
1:38:26: Og Juda kjente dem igjen og sa: Hun er i sin gode rett mot mig fordi jeg ikke har gitt henne til min sønn Sela. Og han hadde ikke siden omgang med henne. 
1:38:27: Da den tid kom at hun skulde føde, se, da var det tvillinger i hennes liv. 
1:38:28: Og i det samme hun fødte, stakk den ene hånden frem; da tok jordmoren og bandt en rød tråd om hans hånd og sa: Denne kom først frem. 
1:38:29: Men så drog han sin hånd tilbake, og da kom hans bror frem, og hun sa: Hvor du har brutt dig frem! Og de kalte ham Peres*. # <* brudd.> 
1:38:30: Så kom hans bror frem, han som hadde den røde tråd om hånden; ham kalte de Serah*. # <* opgang.> 
1:39:1: Josef blev ført ned til Egypten; og Potifar, en egypter, som var hoffmann hos Farao og høvding over livvakten, kjøpte ham av ismaelittene som hadde hatt ham med sig dit. 
1:39:2: Men Herren var med Josef, så alt lyktes for ham; og han vedblev å være i huset hos sin herre egypteren. 
1:39:3: Da hans herre så at Herren var med ham, og at Herren lot alt det han gjorde, lykkes for ham, 
1:39:4: fant Josef nåde for hans øine og fikk gå ham til hånde; og han satte ham over sitt hus, og alt det han hadde, la han i hans hender. 
1:39:5: Og helt fra den tid han hadde satt ham over sitt hus og over alt det han hadde, velsignet Herren egypterens hus for Josefs skyld, og Herrens velsignelse var over alt det han hadde, både i huset og på marken. 
1:39:6: Og han overlot alt det han hadde, i Josefs hender, og han så ikke til med ham i noget, uten med den mat han selv åt. Og Josef var vakker av skapning og vakker å se til. 
1:39:7: Og nogen tid efter hendte det at hans herres hustru kastet sine øine på Josef og sa: Kom og ligg hos mig! 
1:39:8: Men han vilde ikke og sa til sin herres hustru: Min herre ser ikke til med mig i nogen ting i hele sitt hus, og alt det han eier, har han lagt i mine hender; 
1:39:9: han har ikke mere å si her i huset enn jeg, og han har ikke nektet mig noget uten dig, fordi du er hans hustru. Hvorledes skulde jeg da gjøre denne store ondskap og synde mot Gud? 
1:39:10: Som hun nu dag efter dag talte til Josef, og han ikke føide henne i å ligge hos henne og være sammen med henne, 
1:39:11: så hendte det en dag at han kom inn i huset for å gjøre sitt arbeid, mens ingen av husets folk var inne. 
1:39:12: Da grep hun fatt i hans kappe og sa: Ligg hos mig! Men han lot sin kappe efter sig i hennes hånd og flyktet ut av huset. 
1:39:13: Og da hun så at han hadde latt sin kappe efter sig i hennes hånd og var flyktet ut av huset, 
1:39:14: ropte hun på sine husfolk og sa til dem: Se, her har han ført en hebraisk mann hit til oss for å føre skam over oss; han kom inn til mig for å ligge hos mig, men jeg ropte så høit jeg kunde, 
1:39:15: og da han hørte at jeg satte i å rope, lot han sin kappe efter sig hos mig og flyktet ut av huset. 
1:39:16: Så lot hun hans kappe bli liggende hos sig til hans herre kom hjem. 
1:39:17: Da talte hun likedan til ham og sa: Den hebraiske træl som du har ført hit til oss, kom inn til mig for å føre skam over mig; 
1:39:18: men da jeg satte i å rope, lot han sin kappe efter sig hos mig og flyktet ut av huset. 
1:39:19: Da nu hans herre hørte hvad hans hustru fortalte, hvorledes hun sa: Således har din træl gjort mot mig, da optendtes hans vrede. 
1:39:20: Og Josefs herre tok og satte ham i fengslet, der hvor kongens fanger holdtes fengslet; og han blev sittende der i fengslet. 
1:39:21: Men Herren var med Josef og lot ham vinne alles hjerter og gav ham yndest hos fengslets overopsynsmann. 
1:39:22: Og fengslets overopsynsmann satte Josef til å se efter alle fangene som var i fengslet; og alt det som skulde gjøres der, det gjorde han. 
1:39:23: Fengslets overopsynsmann så ikke efter nogen ting som han hadde under hender, fordi Herren var med ham; og hvad han gjorde, gav Herren lykke til. 
1:40:1: Nogen tid derefter hendte det at munnskjenken og bakeren hos kongen i Egypten forså sig mot sin herre, kongen i Egypten. 
1:40:2: Og Farao blev vred på sine to hoffmenn, den øverste munnskjenk og den øverste baker 
1:40:3: og satte dem fast hos høvdingen over livvakten, i fengslet hvor Josef var fange. 
1:40:4: Og høvdingen over livvakten satte Josef til å være hos dem, og han gikk dem til hånde; og de blev sittende en tid i fengslet. 
1:40:5: Engang drømte begge hver sin drøm i samme natt og hver drøm med sin mening - munnskjenken og bakeren hos kongen i Egypten, de som satt fanget i fengslet. 
1:40:6: Da Josef kom inn til dem om morgenen, så han på dem at de var motfalne. 
1:40:7: Da spurte han Faraos hoffmenn, de som satt fengslet med ham hos hans herre: Hvorfor ser I så sorgfulle ut idag? 
1:40:8: De svarte: Vi har drømt, og det er ingen som kan tyde det. Da sa Josef til dem: Å tyde drømmer - er ikke det Guds sak? Fortell mig hvad I har drømt! 
1:40:9: Da fortalte den øverste munnskjenk Josef sin drøm og sa til ham: Jeg så i drømme et vintre som stod foran mig; 
1:40:10: og på vintreet var det tre grener, og det skjøt knopper, blomstene kom frem, klasene modnedes til druer. 
1:40:11: Og jeg holdt Faraos beger i min hånd, og jeg tok druene og krystet dem ut i Faraos beger, og så rakte jeg Farao begeret. 
1:40:12: Da sa Josef til ham: Dette er tydningen: De tre grener er tre dager. 
1:40:13: Om tre dager skal Farao ophøie dig og sette dig i ditt embede igjen, og du skal rekke Farao begeret, som du gjorde før, da du var hans munnskjenk. 
1:40:14: Men kom mig i hu, når det går dig vel, og vis barmhjertighet mot mig, så du taler om mig for Farao og hjelper mig ut av dette hus! 
1:40:15: For de har stjålet mig fra hebreernes land, og heller ikke her har jeg gjort noget som de kunde sette mig i fengslet for. 
1:40:16: Da den øverste baker så at Josef hadde gitt en så god tydning, sa han til ham: Også jeg hadde en drøm og syntes jeg så at jeg bar tre kurver med hvetebrød på mitt hode. 
1:40:17: Og i den øverste kurv var det allslags bakverk, sånt som Farao pleier å ete, og fuglene åt det av kurven på mitt hode. 
1:40:18: Da svarte Josef og sa: Dette er tydningen: De tre kurver er tre dager. 
1:40:19: Om tre dager skal Farao ophøie dig, hugge hodet av dig og henge dig på et tre, og fuglene skal ete kjøttet av dig. 
1:40:20: Den tredje dag, da det var Faraos fødselsdag, gjorde han et gjestebud for alle sine tjenere; og han ophøiet den øverste munnskjenk og den øverste baker iblandt sine tjenere. 
1:40:21: Han satte den øverste munnskjenk i hans embede igjen, og han rakte Farao begeret, 
1:40:22: og den øverste baker lot han henge, således som Josef hadde tydet drømmene for dem. 
1:40:23: Men den øverste munnskjenk kom ikke Josef i hu - han glemte ham. 
1:41:1: Så hendte det da to år var omme, at Farao drømte han stod ved elven*. # <* Nilen.> 
1:41:2: Og se, det steg op av elven syv kyr, vakre å se til og fete, og de gikk og beitet i elvegresset. 
1:41:3: Og efter dem steg det op av elven syv andre kyr, stygge å se til og magre, og de stod ved siden av de andre kyr på elvebredden. 
1:41:4: Og de stygge og magre kyr åt op de syv vakre og fete kyr. Da våknet Farao. 
1:41:5: Så sovnet han igjen og drømte annen gang, og se, syv aks, frodige og gode, vokste op på ett strå. 
1:41:6: Og efter dem skjøt det op syv aks som var tynne og svidd av østenvind. 
1:41:7: Og de tynne aks slukte de syv frodige og fulle aks. Da våknet Farao, og skjønte at det var en drøm. 
1:41:8: Men om morgenen var han urolig til sinns, og han sendte bud og lot kalle alle tegnsutleggerne og alle vismennene i Egypten; og Farao fortalte dem sine drømmer, men det var ingen som kunde tyde dem for ham. 
1:41:9: Da talte den øverste munnskjenk til Farao og sa: Jeg må idag minne om mine synder. 
1:41:10: Farao blev vred på sine tjenere og satte mig fast hos høvdingen over livvakten, både mig og den øverste baker. 
1:41:11: Da hadde vi hver sin drøm i samme natt, jeg og han, og våre drømmer hadde hver sin mening. 
1:41:12: Og det var en hebraisk gutt sammen med oss der; han var tjener hos høvdingen over livvakten; ham fortalte vi våre drømmer, og han tydet dem for oss; efter som enhver hadde drømt, tydet han dem. 
1:41:13: Og som han tydet dem for oss, således gikk det; jeg blev satt i mitt embede igjen, og han blev hengt. 
1:41:14: Da sendte Farao bud og lot Josef kalle, og de førte ham skyndsomt ut av fengslet; og han lot sig rake og skiftet klær og trådte frem for Farao. 
1:41:15: Da sa Farao til Josef: Jeg har hatt en drøm, og det er ingen som kan tyde den; men jeg har hørt si om dig at så snart du hører en drøm, kan du tyde den. 
1:41:16: Og Josef svarte Farao og sa: Det står ikke til mig; Gud vil gi et svar som spår lykke for Farao. 
1:41:17: Da sa Farao til Josef: Jeg syntes i drømme at jeg stod på elvebredden. 
1:41:18: Og se, av elven steg det op syv kyr, fete og vakre av skikkelse, og de gikk og beitet i elvegresset. 
1:41:19: Og efter dem steg det op syv andre kyr, tynne og svært stygge av skikkelse og magre; jeg har aldri sett så stygge kyr i hele Egyptens land. 
1:41:20: Og de magre og stygge kyr åt op de syv første, fete kyr. 
1:41:21: Og da de hadde fått dem til livs, kunde det ikke merkes på dem, de var like stygge å se til som før. Da våknet jeg. 
1:41:22: Så drømte jeg igjen, og se: Syv aks, fulle og gode, vokste op på ett strå. 
1:41:23: Og efter dem skjøt det op syv aks som var fortørket og tynne og svidd av østenvind. 
1:41:24: Og de tynne aks slukte de syv gode aks. Og jeg fortalte det til tegnsutleggerne, men ingen kunde forklare det for mig. 
1:41:25: Da sa Josef til Farao: Faraos drømmer har én mening; hvad Gud vil gjøre, har han latt Farao få vite. 
1:41:26: De syv gode kyr er syv år, og de syv gode aks er syv år; det er en og samme drøm. 
1:41:27: Og de syv magre og stygge kyr som steg op efter dem, er syv år, og de syv tomme aks som var svidd av østenvinden, er syv hungersår, som skal komme. 
1:41:28: Det er som jeg sa til Farao: Hvad Gud vil gjøre, har han latt Farao se. 
1:41:29: Det kommer syv år med stor overflod i hele Egyptens land; 
1:41:30: men efter dem kommer det syv hungersår, så all denne overflod skal bli glemt i Egyptens land, og hungeren skal arme ut landet; 
1:41:31: og ingen skal minnes den overflod som var i landet, for hungeren bakefter; for den skal bli meget hård. 
1:41:32: Men at drømmen kom to ganger for Farao, det vil si at saken er fast besluttet av Gud, og at Gud vil gjøre det snart. 
1:41:33: Nu skulde Farao utse sig en forstandig og vis mann og sette ham over Egyptens land! 
1:41:34: Det skulde Farao gjøre og så sette opsynsmenn over landet og ta femtedelen av avgrøden i Egyptens land i de syv overflodsår. 
1:41:35: Og de skal samle alt som kan tjene til føde, i disse gode år som kommer, og under Faraos hånd dynge op korn i byene til føde og ta vare på det. 
1:41:36: Og kornet skal tjene til forråd for landet i de syv hungersår som skal komme over Egyptens land, så landet ikke skal ødelegges av hungeren. 
1:41:37: Disse ord syntes Farao og alle hans tjenere godt om. 
1:41:38: Og Farao sa til sine tjenere: Mon det finnes nogen som han, en mann som har Guds ånd? 
1:41:39: Så sa Farao til Josef: Siden Gud har latt dig vite alt dette, så er det ingen så forstandig og vis som du. 
1:41:40: Du skal forestå mitt hus, og hele mitt folk skal rette sig efter ditt ord; bare tronen vil jeg ha fremfor dig. 
1:41:41: Og Farao sa fremdeles til Josef: Se, jeg setter dig over hele Egyptens land. 
1:41:42: Og Farao tok sin signetring av sin hånd og satte den på Josefs hånd og klædde ham i klær av fint lin og hengte en gullkjede om hans hals. 
1:41:43: Og han lot ham kjøre i den vogn som var nærmest efter hans egen, og de ropte foran ham: Abrek!* Og han satte ham over hele Egyptens land. # <* bøi kne!.> 
1:41:44: Og Farao sa til Josef: Jeg er Farao, og uten din vilje skal ingen mann løfte hånd eller fot i hele Egyptens land. 
1:41:45: Og Farao gav Josef navnet Sofnat-Paneah* og gav ham til hustru Asnat, en datter av Potifera, presten i On. Så drog Josef omkring i Egyptens land. # <* måskje: Verdens frelser.> 
1:41:46: Josef var tretti år gammel da han stod for Egyptens konge Faraos åsyn. Og efterat Josef var gått ut fra Farao, reiste han gjennem hele Egyptens land. 
1:41:47: Og jorden bar rikelig i de syv overflodsår. 
1:41:48: Og han samlet alle slags grøde i de syv gode år som kom i Egyptens land, og la den op i byene; i hver by la han op avgrøden fra landet som lå omkring. 
1:41:49: Så hopet da Josef op korn som havets sand, i svære mengder, inntil de holdt op med å telle; for det var ikke tall på det. 
1:41:50: Før det første hungersår kom, fikk Josef to sønner med Asnat, datter av Potifera, presten i On. 
1:41:51: Og Josef kalte sin førstefødte sønn Manasse*; for [sa han] Gud har latt mig glemme all min møie og hele min fars hus. # <* en som får folk til å glemme.> 
1:41:52: Og den andre sønn kalte han Efra'im*; for [sa han] Gud har gjort mig fruktbar i det land som jeg led ondt i. # <* dobbelt frukt.> 
1:41:53: Da de syv overflodsår i Egyptens land var til ende, 
1:41:54: begynte de syv hungersår å komme, således som Josef hadde sagt. Da blev det hungersnød i alle landene, men i hele Egyptens land var det brød. 
1:41:55: Og da hele Egyptens land led hunger, ropte folket til Farao om brød. Da sa Farao til alle egypterne: Gå til Josef! Hvad han sier eder, skal I gjøre. 
1:41:56: Da det nu var hungersnød over hele landet, åpnet Josef alle oplagshusene og solgte korn til egypterne; for hungersnøden var hård i Egyptens land. 
1:41:57: Og fra alle landene kom de til Josef i Egypten for å kjøpe korn; for hungersnøden var hård i alle landene. 
1:42:1: Da Jakob fikk vite at det var korn i Egypten, sa han til sine sønner: Hvorfor sitter I og ser på hverandre? 
1:42:2: Og han sa: Jeg har hørt at det er korn i Egypten; dra dit ned og kjøp korn til oss der, så vi kan leve og ikke dø! 
1:42:3: Da drog Josefs ti brødre ned for å kjøpe korn i Egypten. 
1:42:4: Men Benjamin, Josefs bror, sendte Jakob ikke avsted med hans brødre; for han sa: Det kunde møte ham en ulykke. 
1:42:5: Så kom Israels sønner for å kjøpe korn blandt alle de andre som kom; for det var hungersnød i Kana'ans land. 
1:42:6: Og Josef var den som rådet i landet; det var han som solgte korn til alt folket i landet; og Josefs brødre kom og bøide sig med sitt ansikt til jorden for ham. 
1:42:7: Da Josef så sine brødre, kjente han dem igjen; men han lot som om de var fremmede for ham, og talte hårdt til dem og sa til dem: Hvor kommer I fra? De sa: Fra Kana'ans land for å kjøpe korn. 
1:42:8: Josef kjente sine brødre, men de kjente ikke ham. 
1:42:9: Og Josef kom i hu det han hadde drømt om dem, og sa til dem: I er speidere, I er kommet for å se hvor landet ligger åpent. 
1:42:10: De svarte ham: Nei, herre, dine tjenere er kommet for å kjøpe korn. 
1:42:11: Vi er alle sønner av én mann; vi er ærlige folk, dine tjenere er ikke speidere. 
1:42:12: Men han sa til dem: Jo, I er kommet for å se hvor landet ligger åpent. 
1:42:13: Men de sa: Vi, dine tjenere, er tolv brødre, sønner av én mann i Kana'ans land, den yngste er nu hjemme hos vår far, og én er ikke mere til. 
1:42:14: Josef sa til dem: Det er som jeg har sagt til eder: I er speidere. 
1:42:15: Men nu skal I prøves: Så sant Farao lever, skal I ikke komme herfra før eders yngste bror kommer hit. 
1:42:16: Send en av eder avsted for å hente eders bror, men I andre skal holdes fanget, og eders ord skal prøves, om det er sant det I sier; hvis ikke, så er I, så sant Farao lever, speidere. 
1:42:17: Og han holdt dem alle sammen i fengsel i tre dager. 
1:42:18: Men den tredje dag sa Josef til dem: Gjør som jeg nu sier, så skal I leve! Jeg frykter Gud. 
1:42:19: Dersom I er ærlige folk, så skal en av eder brødre bli tilbake i fengslet, men I andre kan dra avsted og ha med eder korn til hjelp mot hungersnøden i eders hjem, 
1:42:20: og før eders yngste bror til mig, så eders ord kan sannes, og I ikke skal dø. Og de så gjorde. 
1:42:21: Og de sa sig imellem: Sannelig, vi har skyld på oss for vår bror. Vi så hans sjeleangst da han bønnfalt oss, og vi vilde ikke høre; derfor er denne nød kommet over oss. 
1:42:22: Da tok Ruben til orde og sa til dem: Sa jeg ikke til eder: Gjør ikke synd mot gutten? Men I vilde ikke høre; se, derfor kreves nu hans blod. 
1:42:23: Men de visste ikke at Josef forstod det; for han brukte tolk når han talte med dem. 
1:42:24: Og han vendte sig fra dem og gråt. Så vendte han sig til dem igjen og talte til dem, og han tok Simeon fra dem og lot ham binde så de så på. 
1:42:25: Og Josef bød sine folk at de skulde fylle deres sekker med korn og legge enhvers penger igjen i hans sekk og gi dem niste med på veien. Og de gjorde således med dem. 
1:42:26: Så la de kornet på sine asener og drog derfra. 
1:42:27: Men da en av dem åpnet sin sekk for å gi sitt asen fôr i herberget, da fikk han se sine penger, de lå øverst i hans sekk. 
1:42:28: Og han sa til sine brødre: Mine penger er kommet igjen, og se, de ligger her i min sekk. Da blev de motfalne og vendte sig forferdet til hverandre og sa: Hvad er det Gud har gjort mot oss? 
1:42:29: Så kom de hjem til Jakob, sin far, i Kana'ans land, og de fortalte ham alt det som hadde hendt dem, og sa: 
1:42:30: Mannen som råder der i landet, talte hårdt til oss og tok oss for folk som vilde speide ut landet. 
1:42:31: Og vi sa til ham: Vi er ærlige folk, vi er ikke speidere; 
1:42:32: vi er tolv brødre, alle sønner av samme far, én er ikke mere til, og den yngste er nu hjemme hos vår far i Kana'ans land. 
1:42:33: Da sa mannen som råder der i landet, til oss: Av dette vil jeg forstå om I er ærlige folk: La en av eder brødre bli her hos mig og ta hvad I trenger mot hungersnøden i eders hjem, og far eders vei; 
1:42:34: og kom så hit til mig med eders yngste bror, så jeg kan forstå at I ikke er speidere, men ærlige folk! Da vil jeg gi eder eders bror igjen, og I kan fritt dra omkring i landet. 
1:42:35: Da de så tømte sine sekker, se, da lå enhvers pengepung i hans sekk; og da de og deres far så pengepungene, blev de redde. 
1:42:36: Og Jakob, deres far, sa til dem: I gjør mig barnløs; Josef er ikke mere til, og Simeon er ikke mere til, og Benjamin vil I ta fra mig; det kommer over mig alt sammen. 
1:42:37: Da sa Ruben til sin far: Begge mine sønner kan du drepe hvis jeg ikke kommer tilbake til dig med ham; gi ham i min hånd, så vil jeg føre ham tilbake til dig. 
1:42:38: Men han sa: Min sønn skal ikke dra ned med eder; for hans bror er død, og han er alene tilbake, og dersom det møter ham nogen ulykke på den vei som I drar, så kommer I til å sende mine grå hår med sorg ned i dødsriket. 
1:42:39: Men hungersnøden var hård i landet. 
1:43:1: Og da de hadde brukt op det korn de hadde hentet fra Egypten, sa deres far til dem: Dra atter avsted og kjøp oss litt korn! 
1:43:2: Men Juda sa til ham: Mannen sa så alvorlig til oss: I skal ikke komme for mine øine uten eders bror er med. 
1:43:3: Dersom du vil sende vår bror med oss, vil vi dra ned og kjøpe korn til dig. 
1:43:4: Men dersom du ikke vil sende ham, vil vi ikke dra ned; for mannen sa til oss: I skal ikke komme for mine øine uten eders bror er med. 
1:43:5: Da sa Israel: Hvorfor har I gjort så ille mot mig og fortalt mannen at I har ennu en bror? 
1:43:6: De svarte: Mannen spurte oss nøie ut både om oss og om vår ætt og sa: Lever eders far ennu? Har I nogen bror? Og vi svarte ham efter som han spurte; kunde vi vel vite at han vilde si: Kom her ned med eders bror? 
1:43:7: Og Juda sa til Israel, sin far: Send gutten med mig I Så vil vi gjøre oss rede og dra avsted, så vi kan leve og ikke skal dø, både vi og du og våre små barn. 
1:43:8: Jeg skal svare for ham, av mig kan du kreve ham; dersom jeg ikke har ham med tilbake til dig og stiller ham for ditt ansikt, da vil jeg være din skyldner alle mine dager; 
1:43:9: for dersom vi ikke hadde dryget så lenge, da kunde vi nu to ganger vært her igjen. 
1:43:10: Da sa Israel, deres far, til dem: Skal det nu så være, så gjør som jeg sier: Ta i eders sekker av alt det ypperste landet eier, og ha det med til mannen som gave, litt balsam og litt honning, krydderier og ladanum, pistasienøtter og mandler, 
1:43:11: og ta dobbelt så mange penger med eder, for de penger som kom igjen og lå øverst i eders sekker, må I ha med tilbake; kanskje det var en feiltagelse. 
1:43:12: Ta så eders bror med, og gjør eder rede og dra tilbake til mannen! 
1:43:13: Og den allmektige Gud la eder finne barmhjertighet hos mannen, så han lar eders andre bror og Benjamin dra hjem igjen med eder; og jeg - skal jeg være barnløs, så får jeg være barnløs! 
1:43:14: Så tok mennene denne gave, og de tok dobbelte penger med sig, og Benjamin; og de gjorde sig rede og drog ned til Egypten og trådte frem for Josef. 
1:43:15: Da Josef så Benjamin sammen med dem, sa han til den som forestod hans hus: Før mennene inn i huset og la slakte og lage til; for mennene skal ete til middag hos mig. 
1:43:16: Og mannen gjorde som Josef sa, og førte mennene inn i Josefs hus. 
1:43:17: Men mennene blev redde fordi de blev ført inn i Josefs hus, og de sa: Det er vel for de pengers skyld som forrige gang kom tilbake i våre sekker, vi føres inn her, forat han kan velte sig inn på oss og kaste sig over oss og gjøre oss til træler og ta våre asener. 
1:43:18: De gikk derfor til mannen som forestod Josefs hus, og talte til ham ved inngangen til huset 
1:43:19: og sa: Hør, herre! Vi kom forrige gang ned her for å kjøpe korn; 
1:43:20: men da vi kom til herberget og åpnet våre sekker, se, da lå enhvers penger øverst i hans sekk, våre penger med sin fulle vekt, og nu har vi dem med oss igjen, 
1:43:21: og vi har tatt andre penger ned med oss til å kjøpe korn for; vi vet ikke hvem som har lagt våre penger i våre sekker. 
1:43:22: Da sa han: I kan være rolige, frykt ikke! Eders Gud og eders fars Gud har gitt eder en skatt i eders sekker; eders penger har jeg fått. Så førte han Simeon ut til dem. 
1:43:23: Derefter førte mannen dem inn i Josefs hus og gav dem vann, og de tvettet sine føtter, og han gav dem fôr til deres asener. 
1:43:24: Så la de gaven til rette til Josef skulde komme hjem om middagen; for de hadde hørt at de skulde ete der. 
1:43:25: Og da Josef var kommet hjem, bar de inn til ham gaven som de hadde med sig, og kastet sig ned på jorden for ham. 
1:43:26: Men han spurte dem om det gikk dem vel, og han sa: Går det eders far vel, den gamle som I talte om? Lever han ennu? 
1:43:27: De svarte: Ja! Det går din tjener vår far vel; han lever ennu. Og de bøide sig og kastet sig ned for ham. 
1:43:28: Og da han så op og fikk øie på Benjamin, sin bror, sin mors sønn, sa han: Er dette eders yngste bror, som I talte til mig om? Og han sa: Gud velsigne dig, min sønn! 
1:43:29: Og Josef skyndte sig bort, for hans hjerte brente mot hans bror, og han søkte et sted hvor han kunde gråte; og han gikk inn i sitt kammer og gråt der. 
1:43:30: Så tvettet han sitt ansikt og gikk ut, og han gjorde sig sterk og sa: Sett maten frem! 
1:43:31: Og de satte frem for ham særskilt og for dem særskilt, og for egypterne som åt hos ham, særskilt; for egypterne kan ikke ete sammen med hebreerne, det er en vederstyggelighet for egypterne. 
1:43:32: Og mennene fikk sine plasser midt imot ham efter alderen, den førstefødte øverst og den yngste nederst, og de så på hverandre og undret sig. 
1:43:33: Og han lot bære til dem av maten på sitt bord, og Benjamin fikk fem ganger så meget som enhver av de andre; og de drakk, og drakk sig glade med ham. 
1:44:1: Siden bød han den som forestod hans hus: Fyll mennenes sekker med korn så meget de kan føre, og legg enhvers penger øverst i hans sekk! 
1:44:2: Men mitt beger, sølvbegeret, skal du legge øverst i den yngstes sekk sammen med pengene for hans korn. Og han gjorde som Josef bød ham. 
1:44:3: Om morgenen, da det blev lyst, lot de mennene med sine asener fare. 
1:44:4: Da de hadde draget ut av byen og ennu ikke var langt kommet, sa Josef til den som forestod hans hus: Ta avsted, sett efter mennene, og når du når dem, skal du si til dem: Hvorfor har I gjengjeldt godt med ondt? 
1:44:5: Er det ikke det beger som min herre drikker av, og som han spår i? Dette var ille gjort av eder. 
1:44:6: Og han innhentet dem og sa dette til dem. 
1:44:7: Da sa de til ham: Hvorfor taler min herre således? Det være langt fra dine tjenere å gjøre noget slikt! 
1:44:8: Se, de penger som vi fant øverst i våre sekker, hadde vi med oss tilbake til dig fra Kana'ans land; hvorledes skulde vi da stjele sølv eller gull fra din herres hus? 
1:44:9: Den av dine tjenere som det finnes hos, han skal dø, og vi andre skal være min herres træler. 
1:44:10: Og han sa: Vel, la det være som I har sagt! Den som det finnes hos, skal være min træl, men I skal være uten skyld. 
1:44:11: Så skyndte de sig og løftet hver sin sekk ned på jorden, og enhver åpnet sin sekk. 
1:44:12: Og han så efter; han begynte hos den eldste og endte hos den yngste, og begeret blev funnet i Benjamins sekk. 
1:44:13: Da sønderrev de sine klær og lesste hver på sitt asen og vendte tilbake til byen. 
1:44:14: Og Juda og hans brødre kom til Josefs hus mens han ennu var der, og de kastet sig til jorden for ham. 
1:44:15: Da sa Josef til dem: Hvad er det for noget I har gjort? Visste I ikke at en mann som jeg kan spå? 
1:44:16: Og Juda sa: Hvad skal vi svare min herre? Hvad skal vi si, og hvad skal vi rettferdiggjøre oss med? Gud har funnet dine tjeneres misgjerning; se, vi er min herres træler, både vi og den som begeret blev funnet hos. 
1:44:17: Men han sa: Det være langt fra mig å gjøre slikt! Den mann som begeret blev funnet hos, han skal være min træl, men dra I andre i fred op til eders far! 
1:44:18: Da gikk Juda frem til ham og sa: Hør mig, herre! La din tjener få tale et ord for min herres ører, og la ikke din vrede optendes mot din tjener; for du er som Farao selv. 
1:44:19: Min herre spurte sine tjenere: Har I far eller bror? 
1:44:20: Da sa vi til min herre: Vi har en gammel far, og han har en ung sønn, som er født i hans alderdom; hans bror er død, og han er alene igjen efter sin mor, og hans far har ham så kjær. 
1:44:21: Og du sa til dine tjenere: Før ham ned til mig, så jeg kan få se ham med mine egne øine! 
1:44:22: Da sa vi til min herre: Gutten kan ikke forlate sin far; for hvis han forlater sin far, da vil hans far dø. 
1:44:23: Men du sa til dine tjenere: Dersom ikke eders yngste bror kommer ned med eder, skal I ikke mere komme for mine øine. 
1:44:24: Da vi så kom hjem til din tjener min far, fortalte vi ham hvad min herre hadde sagt 
1:44:25: Og vår far sa: dra avsted igjen og kjøp litt korn til oss! 
1:44:26: Da sa vi: Vi kan ikke dra ned, men dersom vår yngste bror er med oss, da vil vi dra ned; for vi kan ikke komme mannen for øie uten at vår yngste bror er med. 
1:44:27: Men din tjener min far sa til - oss: I vet at min hustru fødte mig to sønner, 
1:44:28: og den ene gikk bort fra mig, og jeg sa: han er visselig revet ihjel; og jeg har aldri sett ham siden. 
1:44:29: Tar I nu også denne fra mig, og det møter ham nogen ulykke, så sender I mine grå hår med sorg ned i dødsriket. 
1:44:30: Skal jeg nu komme hjem til din tjener min far, og gutten, som han henger ved med hele sin sjel, ikke er med oss, 
1:44:31: så blir det hans død med det samme han ser at gutten ikke er med, og vi må sende din tjener vår fars grå hår med sorg ned i dødsriket. 
1:44:32: For din tjener tok på sig å svare for gutten hos min far og sa: dersom jeg ikke har ham med tilbake til dig, vil jeg være min fars skyldner alle mine dager. 
1:44:33: La derfor din tjener bli i guttens sted som træl hos min herre, men la gutten dra hjem med sine brødre! 
1:44:34: For hvorledes skulde jeg dra hjem til min far uten at gutten var med mig? Jeg kunde ikke se på den sorg som vilde komme over min far. 
1:45:1: Da kunde ikke Josef lenger legge bånd på sig for alle dem som stod hos ham; han ropte: La hver mann gå ut fra mig! Og det var ingen tilstede da Josef gav sig til kjenne for sine brødre. 
1:45:2: Og han brast i gråt og gråt så høit at egypterne hørte det, og de hørte det i Faraos hus. 
1:45:3: Og Josef sa til sine brødre: Jeg er Josef, lever min far ennu? Men hans brødre kunde ikke svare ham, så forferdet stod de der foran ham. 
1:45:4: Da sa Josef til sine brødre: Kjære, kom hit til mig! Så gikk de bort til ham; og han sa: Jeg er Josef, eders bror, som I solgte til Egypten. 
1:45:5: Men vær nu ikke bekymret og sørg ikke over at I solgte mig hit! For å holde eder i live har Gud sendt mig hit i forveien for eder; 
1:45:6: for nu har det vært hungersnød i landet i to år, og ennu kommer det fem år da der hverken skal pløies eller høstes. 
1:45:7: Men Gud har sendt mig i forveien for eder fordi han vilde levne eder en rest på jorden, og fordi han vilde holde eder i live, så det blev en stor frelse. 
1:45:8: Så er det da ikke I som har sendt mig hit, men Gud; og han har satt mig til far for Farao og til herre over hele hans hus og til hersker i hele Egyptens land. 
1:45:9: Skynd eder og dra op til min far og si til ham: Så sier din sønn Josef: Gud har satt mig til herre over hele Egypten; kom ned til mig, dryg ikke! 
1:45:10: Du skal få bo i landet Gosen og være mig nær, både du og dine barn og dine barnebarn med ditt småfe og storfe og alt det du har. 
1:45:11: Og jeg vil sørge for dig der - for ennu kommer det fem hungersår - forat du ikke skal utarmes, du og ditt hus og alle de som hører dig til. 
1:45:12: Og nu ser både I og min bror Benjamin med egne øine at det er jeg som taler med eder. 
1:45:13: Fortell da min far om all min herlighet i Egypten og om alt det I har sett; og skynd eder å føre min far her ned! 
1:45:14: Så falt han sin bror Benjamin om halsen og gråt, og Benjamin gråt i hans armer. 
1:45:15: Og han kysset alle sine brødre og gråt ved deres bryst; og siden talte hans brødre med ham. 
1:45:16: Og da det spurtes i Faraos hus at Josefs brødre var kommet, blev Farao og hans tjenere glade. 
1:45:17: Og Farao sa til Josef: Si til dine brødre: Så skal I gjøre: I skal lesse på eders dyr og fare hjem til Kana'ans land, 
1:45:18: og ta så eders far og eders husfolk og kom til mig, og jeg vil gi eder det beste i Egyptens land, og I skal ete av landets fedme. 
1:45:19: Dette byder jeg dig nu å si til dem: Så skal I gjøre: I skal ta med eder vogner fra Egypten til eders barn og hustruer og hente eders far og komme hit, 
1:45:20: og I skal ikke kvie eder for å reise fra eders eiendeler; for det beste i hele Egyptens land skal være eders. 
1:45:21: Og Israels sønner gjorde så; og Josef gav dem vogner efter Faraos befaling og gav dem niste med på veien. 
1:45:22: Og han gav dem alle hver sin høitidsklædning, men Benjamin gav han tre hundre sekel sølv og fem høitidsklædninger. . 
1:45:23: Likeledes sendte han til sin far ti asener som bar av det beste Egypten hadde, og ti aseninner som bar korn og brød og fødevarer for hans far. på reisen. 
1:45:24: Så lot han sine brødre fare, og de reiste; og han sa til dem: Trett ikke med hverandre på veien! 
1:45:25: Så drog de op fra Egypten og kom til Kana'ans land, til Jakob, sin far. 
1:45:26: Og de fortalte ham at Josef ennu var i live, og at han var hersker over hele Egyptens land. Men hans hjerte var og blev koldt, for han trodde dem ikke. 
1:45:27: Så fortalte de ham alt det Josef hadde sagt til dem, og han så vognene som Josef hadde sendt til å hente ham i; da oplivedes Jakobs deres fars ånd. 
1:45:28: Og Israel sa: Det er nok; Josef, min sønn, lever ennu; jeg vil dra avsted og se ham før jeg dør. 
1:46:1: Og Israel brøt op med alt det han hadde, og da han kom til Be'erseba, ofret han slaktoffer til sin far Isaks Gud. 
1:46:2: Og Gud sa til Israel i et syn om natten: Jakob, Jakob! Han svarte: Ja, her er jeg. 
1:46:3: Da sa han: Jeg er Gud, din fars Gud; frykt ikke for å dra ned til Egypten, for der vil jeg gjøre dig til et stort folk. 
1:46:4: Jeg skal selv dra ned med dig til Egypten, jeg skal visselig også føre dig op igjen; og Josef skal lukke dine øine. 
1:46:5: Så brøt Jakob op fra Be'erseba; og Israels sønner kjørte Jakob, sin far, og sine barn og sine hustruer i de vogner som Farao hadde sendt til å hente ham i. 
1:46:6: Og de tok med sig sin buskap og sitt gods som de hadde lagt sig til i Kana'ans land, og de kom til Egypten, Jakob og hele hans ætt med ham; 
1:46:7: sine sønner og sine sønnesønner, sine døtre og sine sønnedøtre, hele sin ætt førte han med sig til Egypten. 
1:46:8: Og dette er navnene på Israels barn som kom til Egypten, Jakob og hans sønner: Jakobs førstefødte sønn Ruben, 
1:46:9: og Rubens sønner: Hanok og Pallu og Hesron og Karmi, 
1:46:10: og Simeons sønner: Jemuel og Jamin og Ohad og Jakin og Sohar og Saul, som han hadde fått med en kana'anitterkvinne, 
1:46:11: og Levis sønner: Gerson, Kahat og Merari, 
1:46:12: og Judas sønner: Er og Onan og Sela og Peres og Serah - men Er og Onan døde i Kana'ans land - og Peres' sønner var Hesron og Hamul; 
1:46:13: og Issakars sønner: Tola og Puva og Job og Simron, 
1:46:14: og Sebulons sønner: Sered og Elon og Jahle'el; 
1:46:15: dette var Leas sønner, som hun fødte Jakob i Mesopotamia, og dessuten Dina, hans datter, i alt tre og tretti sjeler, sønner og døtre. 
1:46:16: Og Gads sønner: Sifion og Haggi, Suni og Esbon, Eri og Arodi og Areli, 
1:46:17: - og Asers sønner: Jimna og Jisva og Jisvi og Bria og Serah, deres søster, og Brias sønner var Heber og Malkiel; 
1:46:18: dette var barn av Silpa, som Laban gav sin datter Lea; hun fødte Jakob disse barn, seksten sjeler. 
1:46:19: Jakobs hustru Rakels sønner: Josef og Benjamin; 
1:46:20: og Josef fikk barn i Egyptens land med Asnat, datter til Potifera, presten i On: Manasse og Efra'im; 
1:46:21: og Benjamins sønner: Bela og Beker og Asbel, Gera og Na'aman, Ehi og Ros, Muppim og Huppim og Ard; 
1:46:22: dette var de barn som Jakob fikk med Rakel, i alt fjorten sjeler. 
1:46:23: Og Dans sønn: Husim, 
1:46:24: og Naftalis sønner: Jahse'el og Guni og Jeser og Sillem; 
1:46:25: dette var sønnene til Bilha, som Laban gav sin datter Rakel; hun fødte Jakob disse barn, i alt syv sjeler. 
1:46:26: Alle de som kom med Jakob til Egypten, og som var utgått av hans lend, foruten Jakobs sønnekoner, var i alt seks og seksti sjeler. 
1:46:27: Og Josefs sønner, som han fikk i Egypten, var to; alle av Jakobs hus, som kom til Egypten, var sytti sjeler. 
1:46:28: Og han sendte Juda i forveien til Josef og bad at han skulde vise ham vei til Gosen; så kom de til landet Gosen. 
1:46:29: Og Josef lot spenne for sin vogn og drog op til Gosen for å møte Israel, sin far; og da han fikk se ham, falt han ham om halsen og gråt lenge i hans armer. 
1:46:30: Da sa Israel til Josef: Nu vil jeg gjerne dø, efterat jeg har sett ditt ansikt og vet at du ennu lever. 
1:46:31: Og Josef sa til sine brødre og til sin fars hus: Jeg vil dra op og melde det til Farao og si til ham: Mine brødre og min fars hus, som var i Kana'ans land, er kommet til mig; 
1:46:32: Og mennene er fehyrder, de er folk som driver feavl, og sitt småfe og sitt storfe og alt det de eier, har de hatt med sig hit. 
1:46:33: Og når Farao kaller eder til sig og sier: Hvad er eders levevei? 
1:46:34: da skal I si: Dine tjenere har drevet feavl fra vår ungdom av og til nu, både vi og våre fedre - da får I bo i landet Gosen; for alle fehyrder er en vederstyggelighet for egypterne. 
1:47:1: Så kom Josef og meldte dette til Farao og sa: Min far og mine brødre er kommet hit fra Kana'ans land med sitt småfe og storfe og alt det de har; og nu er de i Gosen. 
1:47:2: Og av alle sine brødre tok han ut fem og fremstilte dem for Farao. 
1:47:3: Da sa Farao til hans brødre: Hvad er eders levevei? De svarte Farao: Dine tjenere er fehyrder, vi som våre fedre. 
1:47:4: Så sa de til Farao: Vi er kommet for å bo en tid her i landet; for dine tjenere hadde ikke beite for sin buskap, fordi det er en hård hungersnød i Kana'ans land; la derfor dine tjenere få bo i landet Gosen! 
1:47:5: Da sa Farao til Josef: Din far og dine brødre er kommet til dig. 
1:47:6: Egyptens land ligger åpent for dig; la din far og dine brødre bo i den beste del av landet, la dem bo i Gosen! Og dersom du vet at det er dyktige menn iblandt dem, da sett dem til opsynsmenn over min buskap! 
1:47:7: Og Josef førte Jakob, sin far, inn og fremstilte ham for Farao; og Jakob velsignet Farao. 
1:47:8: Og Farao spurte Jakob: Hvor mange er dine leveår? 
1:47:9: Jakob svarte Farao: Min utlendighets år er hundre og tretti år; få og onde har mine leveår vært, og de har ikke nådd mine fedres leveår i deres utlendighets tid. 
1:47:10: Og Jakob velsignet Farao, og så gikk han ut fra Farao. 
1:47:11: Og Josef lot sin far og sine brødre bosette sig og gav dem eiendom i Egyptens land, i den beste del av landet, i landet Ra'amses, således som Farao hadde pålagt ham. 
1:47:12: Og Josef forsørget sin far og sine brødre og hele sin fars hus med brød efter barnas tall. 
1:47:13: Og det fantes ikke brød i hele landet; for hungersnøden var meget hård, så Egyptens land og Kana'ans land vansmektet av hunger. 
1:47:14: Og Josef samlet alle de penger som fantes i Egyptens land og i Kana'ans land; han fikk dem for det korn de kjøpte, og Josef la pengene op i Faraos hus. 
1:47:15: Men da det var forbi med pengene i Egyptens land og i Kana'ans land, da kom alle egypterne til Josef og sa: Gi oss brød! Hvorfor skal vi dø for dine øine? Vi har ikke flere penger. 
1:47:16: Og Josef sa: Kom hit med eders buskap, så vil jeg gi eder brød for eders buskap, dersom I ikke har flere penger. 
1:47:17: Så kom de til Josef med sin buskap, og Josef gav dem brød for hestene og for småfeet og storfeet som de hadde, og for asenene, og han holdt dem med brød det år for hele deres buskap. 
1:47:18: Så gikk det år til ende, og året efter kom de til ham og sa: Vi vil ikke dølge for min herre at det er forbi med pengene, og buskapen som vi eide, har min herre fått; det er intet tilbake for min herre uten vårt legeme og vår jord. 
1:47:19: Hvorfor skal vi gå til grunne for dine øine, både vi og vår jord? Kjøp oss og vår jord for brød, så skal vi med vår jord være Faraos træler; og gi oss såkorn, så vi kan leve og ikke skal dø, og jorden ikke legges øde. 
1:47:20: Så kjøpte Josef all jorden i Egypten til Farao; for egypterne solgte hver sin jordvei, fordi hungersnøden trykket dem hårdt; og landet blev Faraos eiendom. 
1:47:21: Men folket flyttet han om i byene, fra den ene ende av Egyptens land til den andre. 
1:47:22: Bare prestenes jord kjøpte han ikke; for Farao hadde gitt prestene faste inntekter, og de levde av sine faste inntekter, som Farao hadde gitt dem; derfor solgte de ikke sin jord. 
1:47:23: Og Josef sa til folket: Nu har jeg kjøpt eder og eders jord til Farao; se, her har I såkorn, tilså nu jorden! 
1:47:24: Og når avgrøden kommer inn, da skal I gi en femtedel til Farao, og de fire deler skal I ha til utsæd på eders akrer og til føde for eder og dem som er i eders hus, og til føde for eders barn. 
1:47:25: Da sa de: Du har holdt oss i live; la oss finne nåde for min herres øine, så skal vi være Faraos træler. 
1:47:26: Så satte Josef dette som en lov - og den lov gjelder den dag idag for jorden i Egypten - at Farao skulde ha femtedelen; bare Prestenes jord blev ikke Faraos eiendom. 
1:47:27: Israel blev boende i Egyptens land, i landet Gosen. og de fikk sig eiendom der og var fruktbare og blev meget tallrike. 
1:47:28: Og Jakob levde i Egyptens land i sytten år; og Jakobs dager, hans leveår, blev hundre og syv og firti år. 
1:47:29: Da det led mot den tid at Israel skulde dø, kalte han sin sønn Josef til sig og sa til ham: Kjære, har jeg funnet nåde for dine øine, så legg din hånd under min lend og vis mig den kjærlighet og trofasthet at du ikke begraver mig i Egypten, 
1:47:30: men la mig få hvile hos mine fedre, før mig bort fra Egypten og legg mig i deres grav! Og han svarte: Jeg skal gjøre som du sier. 
1:47:31: Da sa han: Tilsverg mig det! Og han tilsvor ham det. Og Israel bøide sig ned over hodegjerdet i sengen og tilbad. 
1:48:1: Nogen tid efter kom de og sa til Josef: Din far er syk. Da tok han begge sine sønner med sig, Manasse og Efra'im. 
1:48:2: Og de meldte det til Jakob og sa: Din sønn Josef er kommet til dig. Da gjorde Israel sig sterk og satte sig op i sengen. 
1:48:3: Og Jakob sa til Josef: Den allmektige Gud åpenbarte sig for mig i Luz i Kana'ans land og velsignet mig 
1:48:4: og sa til mig: Se, jeg vil gjøre dig fruktbar og tallrik og gjøre dig til en mengde folkeslag, og jeg vil gi din ætt efter dig dette land til evig eiendom. 
1:48:5: Og dine to sønner som du har fått i Egyptens land, før jeg kom til dig her i Egypten, de skal nu være mine; Efra'im og Manasse skal tilhøre mig likesom Ruben og Simeon. 
1:48:6: Men de barn som du har fått efter dem, skal være dine; de skal kalles efter sine brødre i deres arvelodd. 
1:48:7: For da jeg kom fra Mesopotamia, døde Rakel fra mig i Kana'ans land på reisen, da vi ennu hadde et stykke vei igjen til Efrat; og jeg begravde henne der på veien til Efrat, det er Betlehem. 
1:48:8: Da Israel fikk se Josefs sønner, spurte han: Hvem er det? 
1:48:9: Josef svarte sin far: Det er mine sønner, som Gud har gitt mig her. Da sa han: Kjære, kom hit til mig med dem, så vil jeg velsigne dem. 
1:48:10: Men Israels øine var sløve av alderdom, han kunde ikke se; og Josef førte dem bort til ham, og han kysset dem og tok dem i favn. 
1:48:11: Og Israel sa til Josef: Jeg hadde ikke tenkt å få se ditt ansikt, og nu har Gud endog latt mig få se dine barn. 
1:48:12: Så førte Josef dem bort fra hans knær og bøide sig til jorden for ham. 
1:48:13: Siden tok Josef dem begge, Efra'im i sin høire hånd mot Israels venstre og Manasse i sin venstre hånd mot Israels høire, og førte dem frem til ham. 
1:48:14: Og Israel rakte ut sin høire hånd og la den på Efra'ims hode, enda han var den yngste, og sin venstre hånd på Manasses hode; han la sine hender således med vilje, for Manasse var den førstefødte. 
1:48:15: Og han velsignet Josef og sa: Den Gud for hvis åsyn mine fedre Abraham og Isak vandret, den Gud som var min hyrde, fra jeg blev til og til denne dag, 
1:48:16: den engel som forløste mig fra alt ondt, han velsigne guttene, så de må kalles med mitt navn og med mine fedre Abrahams og Isaks navn, og bli meget tallrike i landet. 
1:48:17: Da Josef så at hans far la sin høire hånd på Efra'ims hode, syntes han ille om det, og han grep sin fars hånd for å føre den fra Efra'ims hode bort på Manasses hode. 
1:48:18: Og Josef sa til sin far: Ikke så, far! For denne er den førstefødte; legg din høire hånd på hans hode! 
1:48:19: Men hans far vilde ikke det, og han sa: Jeg vet det, min sønn, jeg vet det. Han skal og bli et folk, han skal og bli stor; men enda skal hans yngre bror bli større enn han, og hans ætt skal bli en mengde folkeslag. 
1:48:20: Så velsignet han dem samme dag og sa: Ved dig skal Israel velsigne og si: Gud gjøre dig som Efra'im og som Manasse! Og han satte Efra'im foran Manasse. 
1:48:21: Og Israel sa til Josef Se, jeg dør, men Gud skal være med eder og føre eder tilbake til eders fedres land. 
1:48:22: Og jeg gir dig fremfor dine brødre et stykke land som jeg tar fra amorittenes hånd med mitt sverd og min bue. 
1:49:1: Og Jakob kalte sine sønner til sig og sa: samle eder, så vil jeg forkynne eder hvad som skal hende eder i de siste dager*. # <* den tid da spådommene blir opfylt.> 
1:49:2: Kom sammen og hør, I Jakobs sønner, hør på Israel, eders far! 
1:49:3: Ruben, min førstefødte er du, min kraft og min styrkes første frukt, høiest i ære og størst i makt. 
1:49:4: Du bruser over som vannet, du skal intet fortrin ha; for du steg op på din fars leie; da vanhelliget du det - i min seng steg han op! 
1:49:5: Simeon og Levi er brødre, voldsvåben er deres sverd. 
1:49:6: Møt ikke i deres hemmelige råd, min sjel, ta ikke del i deres sammenkomster, min ære*! For i sin vrede slo de menn ihjel, og i sin selvrådighet skamskar de okser. # <* sjel.> 
1:49:7: Forbannet være deres vrede, for den var vill, og deres grumhet, for den var hård! Jeg vil kaste dem omkring i Jakob og sprede dem i Israel. 
1:49:8: Juda - dig skal dine brødre prise, din hånd skal være på dine fienders nakke, for dig skal din fars sønner bøie sig. 
1:49:9: En ung løve er Juda; fra rov er du steget op, min sønn! Han legger sig ned, han hviler som en løve, som en løvinne; hvem våger å vekke ham? 
1:49:10: Ikke skal kongespir vike fra Juda, ikke herskerstav fra hans føtter, inntil fredsfyrsten kommer, og folkene blir ham lydige. 
1:49:11: Han binder til vintreet sitt unge asen og til den edle ranke sin aseninnes fole; han tvetter i vin sitt klædebon og i druers blod sin kjortel. 
1:49:12: Dunkle er hans øine av vin, og hvite hans tenner av melk. 
1:49:13: Sebulon - ved havets strand skal han bo, ved stranden hvor skibene lander; hans side er vendt mot Sidon. 
1:49:14: Issakar er et sterktbygget asen, som hviler mellem sine hegn. 
1:49:15: Og han så at hvilen var god, og at landet var fagert; da bøide han sin rygg under byrden og blev en ufri træl. 
1:49:16: Dan skal dømme sitt folk, han som de andre Israels stammer. 
1:49:17: Dan skal være en slange på veien, en huggorm på stien, som biter hesten i hælene, så rytteren faller bakover. 
1:49:18: Efter din frelse bier jeg, Herre! 
1:49:19: Gad - en fiendeflokk hugger inn på ham, men han hugger dem i hælene. 
1:49:20: Fra Aser kommer fedmen, hans mat, og lekre retter som for konger har han å gi. 
1:49:21: Naftali er en lekende hind; liflig er ordet han taler. 
1:49:22: Et ungt frukttre er Josef, et ungt frukttre ved kilden; grenene skyter ut over muren. 
1:49:23: Og de egger ham og skyter på ham, de forfølger ham - de pileskyttere. 
1:49:24: Men fast står han der med sin bue, og hans hender og armer er raske - ved Jakobs Veldiges hender, fra ham, fra hyrden, Israels klippe, 
1:49:25: fra din fars Gud, og han hjelpe dig, fra den Allmektige, og han velsigne dig med velsignelser fra himmelen der oppe, med velsignelser fra dypet der nede, med brysters og morslivs velsignelser! 
1:49:26: Din fars velsignelser stiger høit op over mine forfedres velsignelser, de når op til de evige høiders grense; de skal komme over Josefs hode, over hans isse, han som er høvding blandt sine brødre. 
1:49:27: Benjamin er en glupende ulv; om morgenen eter han op rov, og om aftenen deler han ut hærfang. 
1:49:28: Alle disse er Israels stammer, tolv i tallet, og således var det deres far talte til dem; han velsignet dem, hver av dem velsignet han med den velsignelse som tilkom ham. 
1:49:29: Og han bød dem og sa til dem: Jeg samles nu til mitt folk; begrav mig hos mine fedre i hulen på hetitten Efrons mark, 
1:49:30: i hulen på Makpela-marken, østenfor Mamre i Kana'ans land, den mark som Abraham kjøpte av hetitten Efron til eiendoms-gravsted. 
1:49:31: Der begravde de Abraham og Sara, hans hustru, der begravde de Isak og Rebekka, hans hustru, og der begravde jeg Lea, 
1:49:32: på den mark og i den hule der som blev kjøpt av Hets barn. 
1:49:33: Da Jakob var ferdig med de pålegg han vilde gi sine sønner, trakk han føttene op i sengen; og han opgav sin ånd og blev samlet til sine fedre. 
1:50:1: Og Josef bøide sig ned over sin fars ansikt og gråt over ham og kysset ham. 
1:50:2: Og Josef bød lægene som han hadde i sin tjeneste, å balsamere hans far; og lægene balsamerte Israel. 
1:50:3: Firti dager gikk med hertil, for så mange dager går med til balsameringen; og egypterne gråt over ham i sytti dager. 
1:50:4: Da sørgedagene over ham var til ende, talte Josef til Faraos husfolk og sa: Dersom jeg har funnet nåde for eders øine, så tal for mig til Farao og si: 
1:50:5: Min far tok en ed av mig og sa: Jeg dør; i min grav som jeg lot grave for mig i Kana'ans land, der skal du begrave mig. La mig derfor få dra op og begrave min far og så vende tilbake! 
1:50:6: Og Farao sa: Dra op og begrav din far, således som du tilsvor ham. 
1:50:7: Så drog Josef op for å begrave sin far; og alle Faraos tjenere, de eldste i hans hus, og alle de eldste i Egyptens land drog op med ham, 
1:50:8: og hele Josefs hus og hans brødre og hans fars hus; bare sine små barn og sitt småfe og storfe lot de bli tilbake i landet Gosen. 
1:50:9: Og både vogner og hestfolk drog op med ham, så det blev et meget stort tog. 
1:50:10: Da de kom til Goren-Ha'atad på hin side Jordan, holdt de der en stor og høitidelig sørgefest, og han gjorde likferd efter sin far i syv dager. 
1:50:11: Og da landets innbyggere, kana'anittene, så likferden i Goren-Ha'atad, sa de: Det er en prektig likferd egypterne holder der. Derfor kalte de stedet Abel Misra'im*; det ligger på hin side Jordan. # <* egypternes sørgemark.> 
1:50:12: Og hans sønner gjorde med ham således som han hadde pålagt dem; 
1:50:13: hans sønner førte ham til Kana'ans land og begravde ham i hulen på Makpela-marken, den mark som Abraham hadde kjøpt av hetitten Efron til eiendoms-gravsted, østenfor Mamre. 
1:50:14: Og da Josef hadde begravet sin far, vendte han tilbake til Egypten, både han og hans brødre og alle de som hadde draget op med ham for å begrave hans far. 
1:50:15: Da Josefs brødre så at deres far var død, sa de: Bare nu ikke Josef vil hate oss og gjengjelde oss alt det onde vi gjorde mot ham! 
1:50:16: Så sendte de bud til Josef og lot si: Din far gav oss før sin død dette pålegg: 
1:50:17: Så skal I si til Josef: Tilgi, kjære, dine brødres misgjerning og deres synd, at de har gjort ille mot dig! Så tilgi nu oss, som også tjener din fars Gud, vår misgjerning! Og Josef gråt da de talte således til ham. 
1:50:18: Siden kom også hans brødre selv og falt ned for ham og sa: Se, vi vil være dine tjenere. 
1:50:19: Da sa Josef til dem: Vær ikke redde; er vel jeg i Guds sted? 
1:50:20: I tenkte ondt mot mig; men Gud tenkte det til det gode for å gjøre det han nu har gjort, og holde meget folk i live. 
1:50:21: Så vær da ikke redde, jeg vil sørge for eder og eders barn. Og han trøstet dem og talte vennlig til dem. 
1:50:22: Josef blev boende i Egypten, både han og hans fars hus; og Josef blev hundre og ti år gammel. 
1:50:23: Og Josef fikk se Efra'ims barn i tredje ledd; også barna til Makir, Manasses sønn, blev født på Josefs knær*. # <* d.e. født mens han ennu levde, så han kunde ta dem på sine knær.> 
1:50:24: Og Josef sa til sine brødre: Jeg dør, men Gud skal visselig se til eder og føre eder op fra dette land til det land han har tilsvoret Abraham, Isak og Jakob. 
1:50:25: Og Josef tok en ed av Israels sønner og sa: Gud skal visselig se til eder, og da skal I føre mine ben op herfra. 
1:50:26: Og Josef døde, hundre og ti år gammel; og de balsamerte ham og la ham i kiste i Egypten. 
2:1:1: Dette er navnene på Israels sønner som kom til Egypten; med Jakob kom de, hver med sitt hus: 
2:1:2: Ruben, Simeon, Levi og Juda, 
2:1:3: Issakar, Sebulon og Benjamin, 
2:1:4: Dan og Naftali, Gad og Aser. 
2:1:5: De som nedstammet fra Jakob, var i alt sytti sjeler; men Josef var allerede i Egypten. 
2:1:6: Og Josef døde og alle hans brødre og hele den slekt. 
2:1:7: Men Israels barn var fruktbare og tok sterkt til og blev mange og overmåte tallrike, og landet blev fullt av dem. 
2:1:8: Da kom det en ny konge over Egypten, som ikke visste noget om Josef. 
2:1:9: Og han sa til sitt folk: Se, israelittenes folk er større og tallrikere enn vi. 
2:1:10: Nu vel, la oss gå klokt til verks mot dem, forat de ikke skal ta mere til, og forat de ikke, om det kommer krig, skal slå sig i lag med våre fiender og føre krig mot oss og dra ut av landet. 
2:1:11: Så satte de arbeidsfogder over dem til å plage dem med tvangsarbeid; og de måtte bygge for Farao to byer til oplagssteder, Pitom og Ra'amses. 
2:1:12: Men jo mere de plaget dem, dess mere tok de til, og dess mere bredte de sig ut, så egypterne begynte å grue for Israels barn. 
2:1:13: Og egypterne tvang Israels barn til å træle for sig, 
2:1:14: og de forbitret livet for dem med hårdt trælarbeid i ler og tegl og med alle slags trælarbeid på marken, alt det trælarbeid som de tvang dem til å gjøre. 
2:1:15: Og kongen i Egypten sa til de hebraiske jordmødre - den ene av dem hette Sifra og den andre Pua - : 
2:1:16: Når I hjelper de hebraiske kvinner til å føde, så skal I se efter i jordmorstolen; er det da en sønn, sa drep ham, men er det en datter, da kan hun leve. 
2:1:17: Men jordmødrene fryktet Gud og gjorde ikke som kongen i Egypten bød dem, men lot guttebarna leve. 
2:1:18: Da kalte kongen i Egypten jordmødrene til sig og sa til dem: Hvorfor gjør I dette og lar guttebarna leve? 
2:1:19: Jordmødrene svarte Farao: De hebraiske kvinner er ikke som de egyptiske, de er sterkere; før jordmoren kommer til dem, har de født. 
2:1:20: Og Gud gjorde vel imot jordmødrene; og folket tok til og blev overmåte tallrikt. 
2:1:21: Og fordi jordmødrene fryktet Gud, gav han dem avkom. 
2:1:22: Da bød Farao alt sitt folk og sa: Hver sønn som fødes*, skal kastes i elven, men hver datter skal I la leve. # <* for Israels barn.> 
2:2:1: Så var det en mann av Levis hus som gikk bort og tok en Levis datter til ekte. 
2:2:2: Og kvinnen blev fruktsommelig og fødte en sønn; og da hun så at det var et vakkert barn, skjulte hun ham i tre måneder. 
2:2:3: Men da hun ikke lenger kunne skjule ham, tok hun en kiste til ham av rør og smurte den over med jordbek og tjære, og hun la gutten i den og satte den i sivet ved elvebredden. 
2:2:4: Men hans søster stod et stykke fra for å få vite hvorledes det gikk ham. 
2:2:5: Da kom Faraos datter ned til elven for å bade sig, mens hennes jomfruer gikk frem og tilbake på elvebredden; hun fikk se kisten midt i sivet og sendte sin pike avsted og lot den hente. 
2:2:6: Da hun åpnet den, så hun barnet, og se, det var en gutt som lå der og gråt; da ynkedes hun over ham og sa: Det er en av hebreernes guttebarn. 
2:2:7: Da sa hans søster til Faraos datter: Skal jeg gå og hente en amme til dig blandt de hebraiske kvinner, så hun kan fostre op barnet for dig? 
2:2:8: Faraos datter svarte henne: Ja, gå! Og piken gikk og hentet guttens mor. 
2:2:9: Da sa Faraos datter til henne: Ta denne gutt og fostre ham op for mig; jeg vil gi dig lønn for det. Og konen tok gutten og fostret ham op. 
2:2:10: Da gutten blev voksen, gikk hun med ham til Faraos datter, og han blev som en sønn for henne; hun kalte ham Moses*, for - sa hun - av vannet har jeg dradd ham op. # <* den som er dradd op av vannet.> 
2:2:11: Og det hendte på den tid da Moses var blitt voksen, at han gikk ut til sine brødre og så på deres slit og slep, og han fikk se en egyptisk mann som slo en hebraisk mann, en av hans brødre. 
2:2:12: Da vendte han sig hit og dit og så at det ikke var nogen der, og så slo han egypteren ihjel og skjulte ham i sanden. 
2:2:13: Den andre dag gikk han atter ut og så to hebraiske menn som holdt på å slåss; da sa han til ham som hadde urett: Hvorfor slår du din landsmann? 
2:2:14: Han svarte: Hvem har satt dig til høvding og dommer over oss? Tenker du å slå mig ihjel, likesom du slo egypteren ihjel? Da blev Moses redd og sa: Sannelig, saken er blitt kjent. 
2:2:15: Da Farao fikk høre det, søkte han å slå Moses ihjel; men Moses flyktet for Farao, og han tok bolig i landet Midian og bodde ved en brønn. 
2:2:16: Presten i Midian hadde syv døtre; de kom og øste op vann og fylte vannrennene for å vanne sin fars småfe. 
2:2:17: Så kom det nogen gjætere og drev dem bort; men Moses stod op og hjalp dem og vannet deres småfe. 
2:2:18: Da de så kom hjem til Re'uel, sin far, sa han: Hvorfor kommer I så snart idag? 
2:2:19: De svarte: En egyptisk mann hjalp oss mot gjæterne, og han øste også vann for oss og vannet småfeet. 
2:2:20: Da sa han til sine døtre: Hvor er han da? Hvorfor lot I mannen bli igjen der? Be ham inn, så han kan ete med oss! 
2:2:21: Og Moses samtykket i å bli hos mannen; og han lot Moses få sin datter Sippora til hustru. 
2:2:22: Hun fikk en sønn, og han kalte ham Gersom*; for han sa: Jeg er blitt en gjest i et fremmed land. # <* utlending der.> 
2:2:23: Da lang tid var gått, døde kongen i Egypten, og Israels barn sukket over sin trældom og klaget; og deres rop over trældommen steg op til Gud. 
2:2:24: Og Gud hørte deres sukk, og Gud kom i hu sin pakt med Abraham, Isak og Jakob. 
2:2:25: Og Gud så til Israels barn, og Gud kjentes ved dem. 
2:3:1: Og Moses gjætte småfeet hos Jetro, sin svigerfar, presten i Midian, og han drev engang småfeet bortom ørkenen og kom til Guds berg, til Horeb*. # <* Horeb var en del av Sinai-fjellene.> 
2:3:2: Der åpenbarte Herrens engel sig for ham i en luende ild, midt ut av en tornebusk; og han så op, og se, tornebusken stod i lys lue, men tornebusken brente ikke op. 
2:3:3: Og Moses sa: Jeg vil gå bort og se dette vidunderlige syn, hvorfor tornebusken ikke brenner op. 
2:3:4: Da Herren så at han gikk bort for å se, ropte Gud til ham midt ut av tornebusken og sa: Moses, Moses! Og han svarte: Ja, her er jeg. 
2:3:5: Da sa han: Kom ikke nærmere, dra dine sko av dine føtter! For det sted du står på, er hellig jord. 
2:3:6: Så sa han: Jeg er din fars Gud, Abrahams Gud, Isaks Gud og Jakobs Gud. Da skjulte Moses sitt ansikt, for han fryktet for å skue Gud. 
2:3:7: Og Herren sa: Jeg har sett mitt folks nød i Egypten, og jeg har hørt deres klagerop over arbeidsfogdene; jeg vet hvad de lider. 
2:3:8: Og nu er jeg steget ned for å utfri dem av egypternes hånd og for å føre dem op fra dette land til et godt og vidtstrakt land, til et land som flyter med melk og honning, det land hvor kana'anittene bor og hetittene og amorittene og ferisittene og hevittene og jebusittene. 
2:3:9: Nu er Israels barns skrik nådd op til mig, og jeg har også sett hvorledes egypterne mishandler dem. 
2:3:10: Så gå nu du avsted, jeg vil sende dig til Farao, og du skal føre mitt folk, Israels barn, ut av Egypten! 
2:3:11: Men Moses sa til Gud: Hvem er jeg, at jeg skulde gå til Farao, og at jeg skulde føre Israels barn ut av Egypten? 
2:3:12: Og han sa: Sannelig, jeg vil være med dig, og dette skal være dig et tegn på at jeg har sendt dig: Når du har ført folket ut av Egypten, da skal I holde gudstjeneste på dette fjell. 
2:3:13: Da sa Moses til Gud: Men når jeg nu kommer til Israels barn og sier til dem: Eders fedres Gud har sendt mig til eder, og de så spør mig: Hvad er hans navn? - hvad skal jeg da svare dem? 
2:3:14: Og Gud sa til Moses: Jeg er den jeg er ; og han sa: Så skal du si til Israels barn: "Jeg er" har sendt mig til eder. 
2:3:15: Og Gud sa fremdeles til Moses: Så skal du si til Israels barn: Herren*, eders fedres Gud, Abrahams Gud, Isaks Gud og Jakobs Gud, har sendt mig til eder. Dette er mitt navn til evig tid, og så skal de kalle mig fra slekt til slekt. # <* Jahve d.e. han er, 2MO 3, 14. JES 42, 8. SLM 135, 13.> 
2:3:16: Gå nu og kall sammen de eldste i Israel og si til dem: Herren, eders fedres Gud, har åpenbaret sig for mig, Abrahams, Isaks og Jakobs Gud, og sagt: Jeg har sett til eder og vet hvorledes de farer frem mot eder i Egypten. 
2:3:17: Og jeg sa: Jeg vil føre eder ut av alt det onde I lider i Egypten, til kana'anittenes og hetittenes og amorittenes og ferisittenes og hevittenes og jebusittenes land, til et land som flyter med melk og honning. 
2:3:18: Og de skal høre på dine ord, og du skal gå inn til kongen i Egypten, du og de eldste i Israel, og I skal si til ham: Herren, hebreernes Gud, har møtt oss; la oss nu gå tre dagsreiser ut i ørkenen og ofre til Herren vår Gud. 
2:3:19: Men jeg vet at kongen i Egypten ikke vil gi eder lov til å dra ut, ikke engang om han får kjenne en sterk hånd over sig. 
2:3:20: Derfor vil jeg rekke ut min hånd og slå Egypten med alle mine under, som jeg vil gjøre midt iblandt dem; så skal han la eder fare. 
2:3:21: Og jeg vil la dette folk finne yndest hos egypterne, så I, når I drar ut, ikke skal dra tomhendt bort; 
2:3:22: men enhver kvinne skal be sin grannekvinne og den som bor i hennes hus, om smykker av sølv og gull og om klær, og I skal la eders sønner og eders døtre ta dem på sig; dette er det bytte I skal ta av egypterne. 
2:4:1: Moses svarte: De vil visst ikke tro mig og ikke høre på mig, de vil si: Herren har ikke åpenbaret sig for dig. 
2:4:2: Da sa Herren til ham: Hvad er det du har i hånden? Han svarte: En stav. 
2:4:3: Da sa han: Kast den på jorden! Og han kastet den på jorden, og den blev til en slange; og Moses flyktet for den. 
2:4:4: Og Herren sa til Moses: Rekk ut din hånd og grip den i halen! Så rakte han ut hånden og grep fatt i den, og den blev til en stav i hans hånd. 
2:4:5: Så må de vel tro at Herren, deres fedres Gud, Abrahams Gud, Isaks Gud og Jakobs Gud, har åpenbaret sig for dig. 
2:4:6: Og Herren sa fremdeles til ham: Stikk din hånd i barmen! Og han stakk sin hånd i barmen; og da han drog den ut, se, da var hans hånd spedalsk, som sne. 
2:4:7: Og han sa: Stikk din hånd i barmen igjen! Og han stakk igjen sin hånd i barmen; og da han drog den ut av sin barm, se, da var den igjen blitt som hans legeme ellers. 
2:4:8: Om de nu ikke tror dig eller akter på det første tegn, så vil de da visst tro på det andre tegn. 
2:4:9: Men tror de ikke engang på disse to tegn, og vil de ikke høre på dig, da skal du ta vann av elven og helle det ut på jorden, og det vann du tar av elven, det skal bli til blod på jorden. 
2:4:10: Men Moses sa til Herren: Hør mig, Herre! Jeg har aldri vært nogen ordets mann, hverken før, eller siden du begynte å tale til din tjener; jeg er tung i mæle og tung i tale. 
2:4:11: Men Herren sa til ham: Hvem har gitt mennesket munn, og hvem gjør stum eller døv eller seende eller blind? Er det ikke jeg, Herren? 
2:4:12: Gå nu du, og jeg vil være med din munn og lære dig hvad du skal tale. 
2:4:13: Men han sa: Hør mig, Herre! Send bud med hvem du ellers vil! 
2:4:14: Da optendtes Herrens vrede mot Moses, og han sa: Har du ikke din bror Aron, levitten? Han, vet jeg, kan tale; og nu kommer han dig også i møte, og når han ser dig, blir han glad. 
2:4:15: Og du skal tale til ham og legge ordene i hans munn, og jeg skal være med din munn og med hans munn og lære eder hvad I skal gjøre. 
2:4:16: Og han skal tale til folket for dig; han skal være din munn, og du skal være Gud for ham. 
2:4:17: Og du skal ta denne stav i din hånd; med den skal du gjøre tegnene. 
2:4:18: Så gikk Moses, og da han kom tilbake til Jetro, sin svigerfar, sa han til ham: Kjære, la mig få dra tilbake til mine brødre i Egypten og se om de ennu er i live. Og Jetro sa til Moses: Dra bort i fred! 
2:4:19: Og Herren sa til Moses i Midian: Dra avsted og vend tilbake til Egypten! Nu er de døde alle de som stod dig efter livet. 
2:4:20: Så tok Moses sin hustru og sine sønner og satte dem på asenet og vendte tilbake til Egyptens land; og Moses tok Guds stav i sin hånd. 
2:4:21: Og Herren sa til Moses: Når du nu vender tilbake til Egypten, så se til at du gjør alle de under som jeg har gitt dig makt til å gjøre, for Faraos øine; men jeg vil forherde hans hjerte, så han ikke lar folket fare. 
2:4:22: Da skal du si til Farao: Så sier Herren: Min sønn, min førstefødte, er Israel, 
2:4:23: og jeg sa til dig: La min sønn fare, så han kan tjene mig, men du vilde ikke la ham fare; derfor vil jeg nu slå ihjel din sønn, din førstefødte. 
2:4:24: Underveis, på et hvilested, hendte det at Herren kom imot ham og søkte å ta hans liv. 
2:4:25: Da tok Sippora en skarp sten og skar bort sin sønns forhud og kastet den for hans føtter og sa: Sannelig, du er mig en blodbrudgom. 
2:4:26: Så lot han ham være; da sa hun: En blodbrudgom for omskjærelsens skyld. 
2:4:27: Og Herren sa til Aron: Gå Moses i møte i ørkenen! Og han gikk og traff ham ved Guds berg og kysset ham. 
2:4:28: Og Moses forkynte Aron alt hvad Herren, som sendte ham, hadde talt, og alle de tegn han hadde pålagt ham å gjøre. 
2:4:29: Så gikk Moses og Aron; og de samlet alle de eldste av Israels barn. 
2:4:30: Og Aron bar frem alle de ord Herren hadde talt til Moses, og han gjorde tegnene for folkets øine. 
2:4:31: Og folket trodde; og da de hørte hvorledes Herren hadde sett til Israels barn og gitt akt på deres nød, bøide de sig og tilbad. 
2:5:1: Derefter gikk Moses og Aron inn til Farao og sa: Så sier Herren, Israels Gud: La mitt folk fare, så de kan holde høitid for mig i ørkenen! 
2:5:2: Men Farao sa: Hvem er Herren som jeg skal lyde og la Israel fare? Jeg kjenner ikke Herren, og heller ikke vil jeg la Israel fare. 
2:5:3: Da sa de: Hebreernes Gud har møtt oss; la oss dra tre dagsreiser ut i ørkenen og ofre til Herren vår Gud, så han ikke skal slå oss med pest eller sverd! 
2:5:4: Men kongen i Egypten sa til dem: Hvorfor vil I, Moses og Aron, dra folket fra dets arbeid? Gå og gjør det I skal! 
2:5:5: Så sa Farao: Se, folket er nu blitt altfor tallrikt i landet, og så vil I dra dem bort fra det de har å gjøre! 
2:5:6: Og samme dag bød Farao arbeidsfogdene og opsynsmennene over folket og sa: 
2:5:7: I skal ikke mere gi folket halm til teglarbeidet, som før; de skal selv gå og sanke sig halm. 
2:5:8: Men den samme mengde teglsten som de før har gjort, skal I pålegge dem; I skal ikke avkorte noget i deres arbeid. For de er dovne; derfor skriker de og sier: La oss gå og ofre til vår Gud! 
2:5:9: Legg tungt arbeid på disse mennesker, så de har nok med det og ikke hører efter løgnaktige ord! 
2:5:10: Da gikk arbeidsfogdene og opsynsmennene ut og sa til folket: Så sier Farao: Jeg gir eder ikke lenger halm. 
2:5:11: Gå selv og finn eder halm, hvor I kan; Men i eders arbeid skal det ikke avkortes noget. 
2:5:12: Da spredte folket sig over hele Egyptens land for å sanke stubber til å bruke istedenfor halm. 
2:5:13: Men arbeidsfogdene drev på og sa: Gjør eders arbeid ferdig, fullt dagsverk for hver dag, likesom dengang I hadde halm. 
2:5:14: Og opsynsmennene som Faraos arbeidsfogder hadde satt over Israels barn, fikk hugg, og arbeidsfogdene sa til dem: Hvorfor har I ikke gjort ferdig den fastsatte mengde teglsten, likesom før, hverken igår eller idag? 
2:5:15: Da gikk opsynsmennene over Israels barn til Farao og klaget høilydt for ham og sa: Hvorfor gjør du således med dine tjenere? 
2:5:16: Dine tjenere får ikke halm, og allikevel sier de til oss: Gjør tegl! Og så får dine tjenere hugg, enda det er ditt eget folk som har skylden. 
2:5:17: Men han sa: Dovne er I, dovne! Derfor sier I: Vi vil gå og ofre til Herren. 
2:5:18: Så gå nu og arbeid! Halm får I ikke, men den fastsatte mengde tegl skal I komme med. 
2:5:19: Israels barns opsynsmenn så at de var ille farne, da det blev sagt til dem: I skal ikke avkorte noget i den teglsten I skal ha ferdig - fullt dagsverk for hver dag! 
2:5:20: Og da de kom ut fra Farao, møtte de Moses og Aron, som stod og ventet på dem. 
2:5:21: Og de sa til dem: Herren hjemsøke eder og dømme eder fordi I har ført oss i vanrykte hos Farao og hans tjenere, så I har gitt dem sverd i hånden til å slå oss ihjel. 
2:5:22: Da vendte Moses sig igjen til Herren og sa: Herre, hvorfor har du gjort så ille med dette folk? Hvorfor har du sendt mig? 
2:5:23: For fra den stund jeg gikk inn til Farao for å tale i ditt navn, har han gjort ille mot dette folk, og du har aldeles ikke hjulpet ditt folk. 
2:6:1: Da sa Herren til Moses: Nu skal du se hvad jeg vil gjøre med Farao; ved min sterke hånd skal han tvinges til å la dem fare, ja, ved min sterke hånd tvinges til å drive dem ut av sitt land. 
2:6:2: Og Gud talte til Moses og sa til ham: Jeg er Herren. 
2:6:3: Jeg åpenbarte mig for Abraham, for Isak og for Jakob som den allmektige Gud; men ved mitt navn Herren var jeg ikke kjent av dem. 
2:6:4: Og jeg oprettet min pakt med dem, at jeg vilde gi dem Kana'ans land, det land hvor de bodde som fremmede. 
2:6:5: Og nu har jeg hørt hvorledes Israels barn sukker over at egypterne gjør dem til træler, og jeg har kommet min pakt i hu. 
2:6:6: Si derfor til Israels barn: Jeg er Herren*, og jeg vil føre eder ut fra egypternes tunge byrder og utfri eder fra trælarbeidet under dem, og jeg vil forløse eder med utrakt arm og ved store straffedommer. # <* se 2MO 6, 3.> 
2:6:7: Og jeg vil ta eder til mitt folk, og jeg vil være eders Gud, og I skal kjenne at jeg er Herren eders Gud, han som fører eder ut fra egypternes tunge byrder. 
2:6:8: Og jeg vil føre eder til det land som jeg med opløftet hånd har svoret å ville gi til Abraham, Isak og Jakob, og jeg vil gi eder det til eiendom; jeg er Herren. 
2:6:9: Og Moses sa dette til Israels barn; men de hørte ikke på Moses for angst og for det hårde trælarbeids skyld. 
2:6:10: Da talte Herren til Moses og sa: 
2:6:11: Gå inn og si til Farao, kongen i Egypten, at han skal la Israels barn fare ut av sitt land. 
2:6:12: Og Moses tok således til orde, der han stod for Herrens åsyn: Du ser Israels barn hører ikke på mig; hvorledes skulde da Farao høre på mig, jeg som enda har uomskårne leber? 
2:6:13: Da talte Herren til Moses og Aron og sendte dem til Israels barn og til Farao, kongen i Egypten, med pålegg om at Israels barn skulde føres ut av Egyptens land. 
2:6:14: Dette er overhodene for deres familier: Sønnene til Ruben, Israels førstefødte, var Hanok og Pallu, Hesron og Karmi; dette er Rubens ætter. 
2:6:15: Simeons sønner var Jemuel og Jamin og Ohad og Jakin og Sohar og Saul, som var sønn av en Kana'anitterkvinne; dette er Simeons ætter. 
2:6:16: Og dette er navnene på Levis sønner efter deres ætter: Gerson og Kahat og Merari; og Levi blev hundre og syv og tretti år gammel. 
2:6:17: Gersons sønner var Libni og Sime'i efter deres ætter. 
2:6:18: Kahats sønner var Amram og Jishar og Hebron og Ussiel; og Kahat blev hundre og tre og tretti år gammel. 
2:6:19: Meraris sønner var Mahli og Musi. Dette er Levis ætter efter deres ættetavle. 
2:6:20: Amram tok Jokebed, sin fars søster, til hustru, og med henne fikk han Aron og Moses; og Amram blev hundre og syv og tretti år gammel. 
2:6:21: Jishars sønner var Korah og Nefeg og Sikri. 
2:6:22: Ussiels sønner var Misael og Elsafan og Sitri. 
2:6:23: Aron tok Eliseba, Amminadabs datter, Nahsons søster, til hustru, og med henne fikk han Nadab og Abihu, Eleasar og Itamar. 
2:6:24: Korahs sønner var Assir og Elkana og Abiasaf; dette er korahittenes ætter. 
2:6:25: Eleasar, Arons sønn, tok en av Putiels døtre til hustru, og med henne fikk han Pinehas. Dette var overhodene for levittenes familier efter deres ætter. 
2:6:26: Disse menn, Aron og Moses, var det Herren talte således til: Før Israels barn ut av Egyptens land, hær for hær! 
2:6:27: Det var de som sa til Farao, kongen i Egypten, at Israels barn skulde føres ut av Egypten, disse menn, Moses og Aron. 
2:6:28: Og det skjedde dengang Herren talte til Moses i Egyptens land, 
2:6:29: da sa Herren til Moses: Jeg er Herren; tal til Farao, kongen i Egypten, alt det jeg taler til dig. 
2:6:30: Men Moses sa, der han stod for Herrens åsyn: Du vet jeg har uomskårne leber; hvorledes skulde da Farao høre på mig? 
2:7:1: Da sa Herren til Moses: Se, jeg har satt dig til en Gud for Farao, og Aron, din bror, skal være din profet. 
2:7:2: Du skal tale alt det jeg byder dig; men Aron, din bror, skal tale til Farao, og han skal la Israels barn fare ut av sitt land. 
2:7:3: Men jeg vil forherde Faraos hjerte, og jeg vil gjøre mange tegn og under i Egyptens land. 
2:7:4: Farao skal ikke høre på eder; men jeg vil legge min hånd på Egypten og føre mine hærer, mitt folk, Israels barn, ut av Egyptens land ved store straffedommer. 
2:7:5: Og egypterne skal kjenne at jeg er Herren, når jeg rekker ut min hånd over Egypten og fører Israels barn ut fra dem. 
2:7:6: Og Moses og Aron gjorde dette; som Herren hadde pålagt dem, således gjorde de. 
2:7:7: Moses var åtti år gammel, og Aron tre og åtti år, da de talte til Farao. 
2:7:8: Og Herren sa til Moses og Aron: 
2:7:9: Når Farao taler til eder og sier: La mig få se et under av eder, da skal du si til Aron: Ta din stav og kast den ned foran Farao! Og den skal bli til en slange. 
2:7:10: Så gikk Moses og Aron inn til Farao og gjorde som Herren hadde sagt; Aron kastet sin stav ned foran Farao og hans tjenere, og den blev til en slange. 
2:7:11: Da lot Farao sine vismenn og trollmenn kalle, og de, Egyptens tegnsutleggere, gjorde det samme med sine hemmelige kunster; 
2:7:12: de kastet hver sin stav, og de blev til slanger; men Arons stav slukte deres staver. 
2:7:13: Men Faraos hjerte blev forherdet, og han hørte ikke på dem, således som Herren hadde sagt. 
2:7:14: Da sa Herren til Moses: Faraos hjerte er hårdt; han vil ikke la folket fare. 
2:7:15: Gå til Farao imorgen tidlig når han går ned til elven, og still dig i hans vei på elvebredden, og staven som blev skapt om til en slange, skal du ta i hånden. 
2:7:16: Og du skal si til ham: Herren, hebreernes Gud, har sendt mig til dig og sier: La mitt folk fare, så de kan tjene mig i ørkenen! Men du har like til nu ikke villet lyde. 
2:7:17: Sa sier Herren: På dette skal du kjenne at jeg er Herren: Se, nu slår jeg med denne stav som jeg har i min hånd, på vannet i elven, og det skal bli til blod, 
2:7:18: og fiskene i elven skal dø, og elven skal lukte ille, så egypterne skal vemmes ved å drikke vannet. 
2:7:19: Og Herren sa til Moses: Si til Aron: Ta din stav og rekk ut din hånd over egypternes vann, over deres elver, over deres kanaler og over deres sjøer og over alle deres dammer, og de skal bli til blod, og det skal være blod i hele Egyptens land, både i trekar og i stenkar. 
2:7:20: Og Moses og Aron gjorde som Herren hadde sagt; han løftet staven og slo på vannet i elven så de så på det, både Farao og hans tjenere; da blev alt vannet i elven til blod, 
2:7:21: og fiskene i elven døde, og elven luktet ille, så egypterne ikke kunde drikke vannet, og det var blod i hele Egyptens land. 
2:7:22: Men Egyptens tegnsutleggere gjorde det samme med sine hemmelige kunster, og Faraos hjerte blev forherdet, og han hørte ikke på dem, således som Herren hadde sagt. 
2:7:23: Og Farao vendte sig om og gikk hjem til sitt hus og la heller ikke dette på hjerte. 
2:7:24: Men alle egypterne gravde rundt omkring elven efter drikkevann; for de kunde ikke drikke elvevannet. 
2:7:25: Så gikk det syv dager efterat Herren hadde slått elven. 
2:8:1: Da sa Herren til Moses: Gå inn til Farao og si til ham: Så sier Herren: La mitt folk fare, så de kan tjene mig! 
2:8:2: Dersom du nekter å la dem fare, da vil jeg plage hele ditt land med frosk. 
2:8:3: Og elven skal vrimle av frosk, og de skal krype op og komme inn i ditt hus og i ditt sengkammer og op i din seng og i dine tjeneres hus og på ditt folk og i dine bakerovner og i dine deigtrau. 
2:8:4: Ja, på dig og på ditt folk og på alle dine tjenere skal froskene krype op. 
2:8:5: Og Herren sa til Moses: Si til Aron: Rekk ut din hånd med din stav over elvene, over kanalene og over sjøene, og la froskene komme op over Egyptens land! 
2:8:6: Og Aron rakte ut sin hånd over Egyptens vann, og froskene kom op og dekket hele Egyptens land. 
2:8:7: Men tegnsutleggerne gjorde det samme med sine hemmelige kunster og lot froskene komme op over Egyptens land. 
2:8:8: Da kalte Farao Moses og Aron til sig og sa: Bed til Herren at han vil ta froskene bort fra mig og mitt folk! Da til jeg la folket fare, sa de kan ofre til Herren. 
2:8:9: Og Moses sa til Farao: Ha selv den ære å si når jeg skal bede for dig og dine tjenere og ditt folk at froskene må bli drevet bort fra dig og dine hus, så de bare blir tilbake i elven. 
2:8:10: Han svarte: Imorgen. Da sa Moses: La det bli som du sier, forat du kan kjenne at det ikke er nogen som Herren vår Gud. 
2:8:11: Froskene skal vike fra dig og dine hus og fra dine tjenere og ditt folk; bare i elven skal de bli tilbake. 
2:8:12: Så gikk Moses og Aron ut igjen fra Farao, og Moses ropte til Herren for froskenes skyld som han hadde ført over Farao. 
2:8:13: Og Herren gjorde som Moses hadde sagt, og froskene som var i husene og gårdene og på markene, døde bort; 
2:8:14: og de samlet dem i dyngevis, og landet blev fylt med stank. 
2:8:15: Men da Farao så at han hadde fått luft, gjorde han sitt hjerte hårdt og hørte ikke på dem, således som Herren hadde sagt. 
2:8:16: Da sa Herren til Moses: Si til Aron: Rekk ut din stav og slå i støvet på jorden, så skal det bli til mygg i hele Egyptens land. 
2:8:17: Og de gjorde således; Aron rakte ut sin hånd med sin stav og slo i støvet på jorden, og myggene kom både på folk og fe; alt støvet på jorden blev til mygg i hele Egyptens land. 
2:8:18: Tegnsutleggerne gjorde likeså med sine hemmelige kunster; de søkte å få mygg frem, men kunde ikke. Og myggene blev sittende på folk og på fe. 
2:8:19: Da sa tegnsutleggerne til Farao: Dette er Guds finger. Men Faraos hjerte var og blev forherdet, og han hørte ikke på dem, således som Herren hadde sagt. 
2:8:20: Da sa Herren til Moses: Stå tidlig op imorgen, og still dig frem for Farao når han går ned til elven, og si til ham: Så sier Herren: La mitt folk fare, så de kan tjene mig! 
2:8:21: For dersom du ikke lar mitt folk fare, da sender jeg fluesvermer over dig og dine tjenere og ditt folk og dine hus, og egypternes hus skal fylles av fluesvermene, ja endog jorden de står på. 
2:8:22: Men på den dag vil jeg undta Gosen, hvor mitt folk bor, så det ikke skal være fluesvermer der; da skal du kjenne at jeg, Herren, er midt i landet. 
2:8:23: For jeg vil frelse mitt folk og gjøre forskjell på mitt folk og ditt folk; imorgen skal dette tegn skje. 
2:8:24: Og Herren gjorde som han hadde sagt, og det kom svære fluesvermer i Faraos hus og i hans tjeneres hus; i hele Egypten blev landet herjet av fluesvermer. 
2:8:25: Da kalte Farao Moses og Aron til sig og sa: Gå og ofre til eders Gud her i landet! 
2:8:26: Men Moses sa: Det er ikke rådelig å gjøre så; for det vi ofrer til Herren vår Gud, er en vederstyggelighet for egypterne; om vi nu ofret for egypternes øine det som er en vederstyggelighet for dem, vilde de da ikke stene oss? 
2:8:27: Tre dagsreiser vil vi gå ut i ørkenen og ofre til Herren vår Gud, således som han byder oss. 
2:8:28: Da sa Farao: Jeg vil la eder fare, så I kan ofre til Herren eders Gud i ørkenen; men I må ikke dra langt bort. Bed for mig! 
2:8:29: Moses svarte: Se, jeg går nu ut og vil bede til Herren, og imorgen skal fluesvermene vike bort fra Farao, fra hans tjenere og fra hans folk; bare nu Farao ikke mere vil bruke svik, men la folket fare, så de kan ofre til Herren. 
2:8:30: Så gikk Moses ut fra Farao og bad til Herren. 
2:8:31: Og Herren gjorde som Moses bad, og lot fluesvermene vike bort fra Farao, fra hans tjenere og fra hans folk; det blev ikke én igjen. 
2:8:32: Men Farao forherdet sitt hjerte også denne gang; han lot ikke folket fare. 
2:9:1: Og Herren sa til Moses: Gå inn til Farao og si til ham: Så sier Herren, hebreernes Gud: La mitt folk fare, så de kan tjene mig! 
2:9:2: For dersom du nekter å la dem fare og fremdeles holder på dem, 
2:9:3: da skal Herrens hånd komme over ditt fe på marken, over hestene, over asenene, over kamelene, over storfeet og over småfeet med en forferdelig pest. 
2:9:4: Men Herren skal gjøre forskjell på Israels fe og egypternes fe, og det skal ikke dø noget av alt det som hører Israels barn til. 
2:9:5: Og Herren fastsatte tiden og sa: Imorgen vil Herren la dette skje i landet. 
2:9:6: Dagen efter gjorde Herren som han hadde sagt, og alt egypternes fe døde, men av Israels barns fe døde ikke ett liv. 
2:9:7: Og Farao sendte bud, og da var det ikke død et eneste liv av Israels fe; men Faraos hjerte blev hårdt, og han lot ikke folket fare. 
2:9:8: Da sa Herren til Moses og Aron: Ta hendene fulle av aske fra ovnen, og Moses skal kaste den op i været så Farao ser på det, 
2:9:9: og den skal bli til støv utover hele Egyptens land, og den skal bli til bylder som bryter ut med blemmer, både på folk og fe i hele Egyptens land. 
2:9:10: Så tok de asken fra ovnen og trådte frem for Farao, og Moses kastet den op i været; og det blev bylder som brøt ut med blemmer, både på folk og fe. 
2:9:11: Og tegnsutleggerne kunde ikke holde stand mot Moses for byldenes skyld; for det var kommet bylder på tegnsutleggerne og på alle egypterne. 
2:9:12: Men Herren forherdet Faraos hjerte, så han ikke hørte på dem, således som Herren hadde sagt til Moses 
2:9:13: Da sa Herren til Moses: Stå tidlig op imorgen, og tred frem for Farao og si til ham: Så sier Herren, hebreernes Gud: La mitt folk fare, så de kan tjene mig! 
2:9:14: For denne gang vil jeg sende alle mine plager over dig selv og over dine tjenere og ditt folk, forat du skal kjenne at ingen er som jeg på hele jorden. 
2:9:15: For nu hadde jeg allerede rakt ut min hånd og vilde slått dig og ditt folk med pesten, så du var blitt utslettet av jorden; 
2:9:16: men derfor lot jeg dig bli i live at jeg kunde vise dig min makt, og mitt navn kunde bli kunngjort over hele jorden. 
2:9:17: Ennu stiller du dig i veien for mitt folk og vil ikke la dem fare. 
2:9:18: Se, jeg vil på denne tid imorgen sende et forferdelig haglvær, som det aldri har vært make til i Egypten, like fra den dag det blev til, og til nu. 
2:9:19: Så send da folk og berg din buskap og alt det du har på marken! Alt folk og alt fe som er ute på marken og ikke har kommet sig i hus, på dem skal haglet falle, og de skal dø. 
2:9:20: De av Faraos tjenere som fryktet Herrens ord, berget da sine folk og sin buskap i hus; 
2:9:21: men de som ikke gav akt på Herrens ord, lot sine folk og sin buskap bli ute på marken. 
2:9:22: Da sa Herren til Moses: Rekk din hånd op mot himmelen, og det skal komme hagl i hele Egyptens land, både over folk og fe og over alle markens urter i Egyptens land. 
2:9:23: Og Moses rakte sin stav op mot himmelen, og Herren lot komme torden og hagl, og det fôr ild ned på jorden, og Herren lot falle hagl over Egyptens land. 
2:9:24: Det kom hagl, og midt i haglskuren lyn i lyn, et uvær så svært at det aldri hadde vært maken i hele Egyptens land fra den tid folk hadde bosatt sig der. 
2:9:25: Og i hele Egyptens land slo haglet ned alt det som var på marken, både folk og fe; og alle markens urter slo haglet ned, og alle markens trær brøt det sønder. 
2:9:26: Bare i Gosens land, hvor Israels barn bodde, falt det ikke hagl. 
2:9:27: Da sendte Farao bud efter Moses og Aron og sa til dem: Denne gang har jeg syndet; Herren er den som har rett, og jeg og mitt folk har urett. 
2:9:28: Bed til Herren at det nu må være nok av hans torden og hagl! Så vil jeg la eder fare, og I skal ikke bie lenger. 
2:9:29: Moses svarte ham: Så snart jeg kommer ut av byen, vil jeg utbrede mine hender til Herren; så skal tordenen holde op og haglet ikke falle mere, forat du skal kjenne at jorden hører Herren til. 
2:9:30: Men om dig og dine tjenere vet jeg at I ennu ikke frykter for Gud Herren. 
2:9:31: Lin og bygg var slått ned; for bygget stod i aks og linet i knopp; 
2:9:32: men hvete og spelt var ikke slått ned, for de kommer senere. 
2:9:33: Og Moses gikk bort fra Farao og ut av byen og utbredte sine hender til Herren; da holdt tordenen og haglet op, og regnet strømmet ikke mere ned på jorden. 
2:9:34: Men da Farao så at regnet og haglet og tordenen hadde holdt op, blev han ved å synde, og han forherdet sitt hjerte, både han og hans tjenere. 
2:9:35: Faraos hjerte var og blev forherdet, og han lot ikke Israels barn fare, således som Herren hadde sagt ved Moses. 
2:10:1: Og Herren sa til Moses: Gå inn til Farao! For det er jeg som har forherdet hans hjerte og hans tjeneres hjerte, forat jeg kan gjøre disse mine tegn midt iblandt dem, 
2:10:2: og forat du skal fortelle din sønn og din sønnesønn hvad jeg har gjort mot egypterne, og hvad tegn jeg har gjort iblandt dem, så I skal kjenne at jeg er Herren. 
2:10:3: Så gikk Moses og Aron inn til Farao og sa til ham: Så sier Herren, hebreernes Gud: Hvor lenge vil du la være å ydmyke dig for mig? La mitt folk fare, så de kan tjene mig! 
2:10:4: For dersom du nekter å la mitt folk fare, da vil jeg imorgen la gresshopper komme over ditt land. 
2:10:5: Og de skal dekke landet, så ingen kan se jorden, og de skal ete op hvert eneste grand av det som blev tilovers for eder efter haglet, og de skal avete hvert tre som vokser på eders marker. 
2:10:6: Og de skal fylle dine hus og alle dine tjeneres hus og alle egypternes hus, så hverken dine fedre eller dine fedres fedre har sett noget slikt fra den tid de blev til på jorden, og til denne dag. Dermed vendte han sig om og gikk ut fra Farao. 
2:10:7: Da sa Faraos tjenere til ham: Hvor lenge skal denne mann være en snare for oss? La mennene fare, så de kan tjene Herren sin Gud! Ser du ikke ennu at Egypten blir ødelagt? 
2:10:8: Så blev Moses og Aron hentet tilbake til Farao, og han sa til dem: Gå avsted, tjen Herren eders Gud! Men hvem er det som skal fare? 
2:10:9: Moses svarte: Vi vil fare alle, både unge og gamle; både våre sønner og våre døtre vil vi ha med, både vårt småfe og vårt storfe; for vi skal holde høitid for Herren. 
2:10:10: Da sa han til dem: Herren være med eder, så visst som jeg lar eder og eders små barn fare! Se der om I ikke har ondt i sinne! 
2:10:11: Nei, ikke så! I menn kan fare og tjene Herren! For det var jo det I bad om. Dermed drev de dem ut fra Farao. 
2:10:12: Da sa Herren til Moses: Rekk ut din hånd over Egyptens land, så skal gresshoppene komme inn over Egyptens land og ete op alle urter i landet, alt det haglet har levnet. 
2:10:13: Så rakte Moses ut sin stav over Egyptens land, og Herren lot en østenvind komme over landet hele den dag og hele natten; da det blev morgen, førte østenvinden gresshoppene med sig. 
2:10:14: Og gresshoppene kom inn over hele Egyptens land og slo sig ned i hele Egypten i store mengder; aldri hadde det før vært en slik gresshoppesverm, og aldri vil det komme nogen slik mere. 
2:10:15: De dekket hele landet, så landet blev mørkt, og de åt op alle urter i landet og all frukt på trærne som haglet hadde levnet, og det blev intet grønt tilbake på trærne eller på markens urter i hele Egyptens land. 
2:10:16: Da skyndte Farao sig og sendte bud efter Moses og Aron og sa: Jeg har syndet mot Herren eders Gud og mot eder. 
2:10:17: Men forlat mig nu min synd denne ene gang, og bed til Herren eders Gud at han bare vil avvende denne død fra mig! 
2:10:18: Og han gikk ut igjen fra Farao og bad til Herren. 
2:10:19: Og Herren vendte vinden, så det blev en meget sterk vestenvind, og den førte gresshoppene bort og kastet dem ned i det Røde Hav; det blev ikke én gresshoppe tilbake i hele Egyptens land. 
2:10:20: Men Herren forherdet Faraos hjerte, så han ikke lot Israels barn fare. 
2:10:21: Og Herren sa til Moses: Rekk din hånd op mot himmelen, og det skal bli mørke over Egyptens land, et mørke så tykt at en kan ta på det. 
2:10:22: Så rakte Moses sin hånd op mot himmelen, og det blev et tykt mørke i hele Egyptens land i tre dager. 
2:10:23: Den ene kunde ikke se den andre, og ingen torde flytte sig fra sin plass i tre dager; men hos alle Israels barn var det lyst der de bodde. 
2:10:24: Da kalte Farao Moses til sig og sa: Gå avsted, tjen Herren! Bare eders småfe og eders storfe skal bli tilbake; også eders små barn kan få dra med eder. 
2:10:25: Men Moses sa: Du må også la oss få slaktoffer og brennoffer med, så vi kan ofre til Herren vår Gud; 
2:10:26: også vår buskap må være med oss, ikke en klov må bli igjen; for det er det vi må ta av når vi skal tjene Herren vår Gud, og vi vet ikke hvad vi skal ofre til Herren før vi kommer dit. 
2:10:27: Men Herren forherdet Faraos hjerte, så han ikke vilde la dem fare. 
2:10:28: Og Farao sa til Moses: Gå fra mig, vokt dig, kom ikke mere for mine øine! For på den dag du kommer for mine øine, skal du dø! 
2:10:29: Moses svarte: Du har talt rett; jeg skal aldri mere komme for dine øine. 
2:11:1: Herren hadde sagt til Moses: Ennu én plage vil jeg la komme over Farao og over Egypten, så skal han la eder fare herfra; ja, når han lar eder fare, skal han endog drive eder herfra med alt det I har. 
2:11:2: Si nu så folket hører det at hver mann skal be sin granne og hver kvinne sin grannekvinne om smykker av sølv og gull. 
2:11:3: Og Herren gav folket yndest hos egypterne; og så var også Moses en meget stor mann i Egypten, både for Faraos tjenere og for folket. 
2:11:4: Og Moses sa: Så sier Herren: Ved midnatts tid vil jeg gå midt gjennem Egypten. 
2:11:5: og alle førstefødte i Egyptens land skal dø, fra den førstefødte sønn av Farao, som sitter på sin trone, til den førstefødte sønn av trælkvinnen, som står bak håndkvernen, og alt førstefødt blandt buskapen. 
2:11:6: Og det skal i hele Egyptens land bli et stort skrik som det ikke har vært maken til og heller ikke mere skal bli. 
2:11:7: Men ikke en hund skal gjø mot nogen av Israels barn, hverken mot folk eller fe, så I skal kjenne at Herren gjør forskjell på egypterne og Israel. 
2:11:8: Da skal alle disse dine tjenere komme ned til mig og bøie sig for mig og si: Dra ut, både du og hele det folk som følger dig! Og så skal jeg dra ut. Og han gikk bort fra Farao i brennende harme. 
2:11:9: Som Herren hadde sagt til Moses og Aron: Farao skal ikke høre på eder, så jeg kan få gjort mange under i Egyptens land, 
2:11:10: så gjorde Moses og Aron alle disse under for Farao; men Herren forherdet Faraos hjerte, så han ikke lot Israels barn dra bort fra sitt land. 
2:12:1: Og Herren sa til Moses og Aron i Egyptens land: 
2:12:2: Denne måned* skal være eders nytt årsmåned, den skal være den første av årets måneder hos eder. # <* måneden abib eller nisan, 2MO 13, 4. NEH 2, 11. omtrent vår april.> 
2:12:3: Tal til hele Israels menighet og si: På den tiende dag i denne måned skal hver husfar ta sig ut et lam, et lam for hvert hus. 
2:12:4: Men dersom huset er for lite til et lam, skal han og hans nærmeste nabo ta et lam sammen efter tallet på deres husfolk; efter hvad enhver eter, skal I regne folk på hvert lam. 
2:12:5: Det skal være et lam uten lyte, av hankjønn, årsgammelt; et lam eller et kje kan I ta. 
2:12:6: Og I skal gjemme det til den fjortende dag i denne måned; da skal hele Israels samlede menighet slakte det mellem de to aftenstunder*. # <* mellem solens nedgang og nattens frembrudd.> 
2:12:7: Og de skal ta av blodet og stryke på begge dørstolpene og på det øverste dørtre på de hus hvor de eter det. 
2:12:8: Og de skal ete kjøttet samme natt; stekt ved ild og med usyret brød og bitre urter skal de ete det. 
2:12:9: I må ikke ete noget av det rått eller kokt i vann, men stekt ved ild, med hode, føtter og innvoller. 
2:12:10: Og I skal ikke levne noget av det til om morgenen; er det noget igjen om morgenen, skal I brenne det op i ilden. 
2:12:11: Og således skal I ete det: med ombundne lender, med sko på føttene og med stav i hånd, og I skal ete det i hast; det er påske* for Herren. # <* ordet påske betyr forbigang.> 
2:12:12: For samme natt vil jeg gå igjennem Egyptens land og slå ihjel alt førstefødt i Egyptens land, både folk og fe, og over alle Egyptens guder vil jeg holde dom; jeg er Herren. 
2:12:13: Og blodet på de hus som I er i, skal være til et tegn for eder; når jeg ser blodet, vil jeg gå eder forbi; og ikke skal noget slag ramme eder til ødeleggelse når jeg slår Egyptens land. 
2:12:14: Og denne dag skal være en minnedag for eder, og I skal holde den som en høitid for Herren; det skal være en evig forskrift for eder å holde den, slekt efter slekt. 
2:12:15: I syv dager skal I ete usyret brød. Straks på den første dag skal I ha all surdeig bort av eders hus; enhver som eter syret brød fra den første til den syvende dag, han skal utryddes av Israel. 
2:12:16: Og på den første dag skal I holde en hellig sammenkomst, og likeså på den syvende dag en hellig sammenkomst. Intet arbeid skal gjøres nogen av de dager; bare den mat som enhver av eder trenger, må I lage til. 
2:12:17: I skal holde de usyrede brøds høitid, for på denne samme dag førte jeg eders hærer ut av Egyptens land; derfor skal det være en evig forskrift for eder å holde denne dag, slekt efter slekt. 
2:12:18: I den første måned, på den fjortende dag i måneden om aftenen skal I ete usyret brød, og det skal I gjøre helt til den enogtyvende dag om aftenen i samme måned. 
2:12:19: I syv dager skal det ikke finnes surdeig i eders hus; enhver som eter syret brød, han skal utryddes av Israels menighet, enten han er en fremmed eller innfødt i landet. 
2:12:20: Intet syret skal I ete, i alle hus skal I ete usyret brød. 
2:12:21: Da kalte Moses alle Israels eldste til sig og sa til dem: Gå og hent småfe for eders familier og slakt påskelammet! 
2:12:22: Og I skal ta et knippe isop og dyppe i blodet som er i skålen, og stryke på det øverste dørtre og på begge dørstolpene noget av blodet i skålen, og ingen av eder skal gå ut av sin husdør før om morgenen. 
2:12:23: For Herren skal gå igjennem landet for å slå egypterne, og når han ser blodet på det øverste dørtre og på begge dørstolpene, skal han gå døren forbi og ikke la ødeleggeren få komme inn i eders hus og slå eder. 
2:12:24: Dette skal I holde; det skal være en forskrift for dig og dine barn til evig tid. 
2:12:25: Og når I kommer inn i det land Herren skal gi eder, således som han har lovt, da skal I holde denne tjeneste. 
2:12:26: Og når eders barn sier til eder: Hvad er dette for en tjeneste som I holder? - 
2:12:27: da skal I si: Det er påskeoffer for Herren, som gikk forbi Israels barns hus i Egypten da han slo egypterne og fridde våre hus. Og folket bøide sig og tilbad. 
2:12:28: Og Israels barn gikk bort og gjorde dette; som Herren hadde sagt til Moses og Aron, således gjorde de. 
2:12:29: Så skjedde det ved midnatts tid at Herren slo ihjel alle førstefødte i Egyptens land, fra den førstefødte sønn av Farao, som satt på sin trone, til den førstefødte sønn av fangen, som var i fengslet, og alt førstefødt blandt buskapen. 
2:12:30: Da stod Farao op om natten, han og alle hans tjenere og alle egypterne, og det blev et stort skrik i Egypten; for det var ikke et hus hvor det ikke var en død. 
2:12:31: Og han kalte Moses og Aron til sig om natten og sa: Gjør eder rede, dra ut fra mitt folk, både I og Israels barn, far bort og tjen Herren, som I har sagt! 
2:12:32: Ta med eder både eders småfe og eders storfe, som I har sagt, gå og bed godt også over mig! 
2:12:33: Og egypterne trengte hårdt på folket for å få dem hastig ut av landet; for de sa: Vi er dødsens alle sammen! 
2:12:34: Så tok folket sin deig, før den var syret; de svøpte sine deigtrau i sine klær og bar dem på sine skuldrer. 
2:12:35: Og Israels barn gjorde som Moses hadde sagt, og bad egypterne om smykker av sølv og gull og om klær. 
2:12:36: Og Herren gav folket yndest hos egypterne, så de gjerne gav dem det de bad om. Det var det bytte de tok av egypterne. 
2:12:37: Så brøt da Israels barn op fra Ra'amses og tok veien til Sukkot, omkring seks hundre tusen mann til fots foruten barna. 
2:12:38: Og det drog også en stor hop av alle slags folk med dem, og småfeet og storfeet, en overmåte stor mengde fe. 
2:12:39: Av den deig de hadde med sig fra Egypten, bakte de usyrede kaker; for den var ikke syret, fordi de var drevet ut av Egypten og ikke torde dryge der lenger; heller ikke hadde de laget i stand reisekost. 
2:12:40: Men den tid Israels barn hadde bodd i Egypten, var fire hundre og tretti år. 
2:12:41: På den dag de fire hundre og tretti år var til ende, da var det alle Herrens hærer drog ut av Egyptens land. 
2:12:42: Denne natt tok Herren i akt for å føre dem ut av Egyptens land; den samme natt skal alle Israels barn ta i akt for Herren, slekt efter slekt. 
2:12:43: Og Herren sa til Moses og Aron: Dette er forskriften om påskelammet: Ingen fremmed skal ete av det. 
2:12:44: Men enhver træl som er kjøpt for sølv, ham skal du omskjære; da kan han ete av det. 
2:12:45: Ingen innerst eller dagarbeider skal ete av det. 
2:12:46: I ett hus skal det etes; du skal ikke la noget av kjøttet komme utenfor huset, og I skal ikke bryte noget ben på det. 
2:12:47: Således skal hele Israels menighet gjøre. 
2:12:48: Og når en fremmed opholder sig hos dig og vil holde påske for Herren, skal alt mannkjønn hos ham omskjæres, og da kan han få være med og holde den; han skal være som en innfødt i landet; men ingen uomskåret skal ete av det. 
2:12:49: Det skal være en og samme lov for den innfødte og for den fremmede som opholder sig hos eder. 
2:12:50: Og alle Israels barn gjorde således; som Herren hadde befalt Moses og Aron, således gjorde de. 
2:12:51: På denne samme dag var det Herren førte Israels barn ut av Egyptens land, hær efter hær. 
2:13:1: Og Herren talte til Moses og sa: 
2:13:2: Du skal hellige mig alt førstefødt, alt det som åpner morsliv blandt Israels barn, enten det er folk eller fe! Mig hører det til. 
2:13:3: Og Moses sa til folket: Kom i hu denne dag da I gikk ut av Egypten, av trælehuset, for med sterk hånd førte Herren eder ut derfra! Da skal I ikke ete syret brød. 
2:13:4: Idag drar I ut, i måneden abib. 
2:13:5: Når da Herren fører dig inn i kana'anittenes og hetittenes og amorittenes og hevittenes og jebusittenes land, som han tilsvor dine fedre å gi dig, et land som flyter med melk og honning, da skal du holde denne tjeneste i denne måned. 
2:13:6: I syv dager skal du ete usyret brød, og på den syvende dag skal det være høitid for Herren. 
2:13:7: Usyret brød skal du ete alle de syv dager, det skal ikke finnes syret brød hos dig, og ikke surdeig i hele ditt land. 
2:13:8: Og samme dag skal du fortelle din sønn det og si: Dette er til minne om det som Herren gjorde for mig da jeg drog ut av Egypten. 
2:13:9: Og det skal være dig til et tegn på din hånd og til en minneskrift på din panne, forat Herrens lov skal være i din munn; for med sterk hånd førte Herren dig ut av Egypten. 
2:13:10: Og du skal gjøre efter denne lov til fastsatt tid, år efter år. 
2:13:11: Og når Herren har ført dig til kana'anittenes land, således som han tilsvor dig og dine fedre, og gir dig det, 
2:13:12: da skal du overgi til Herren alt det som åpner morsliv; alt som åpner morsliv, som faller av det fe du har, hvis det er av hankjønn, hører det Herren til. 
2:13:13: Og alt som åpner morsliv blandt asen, skal du løse med et stykke småfe; men hvis du ikke løser det, skal du bryte nakken på det; og alt førstefødt av mennesker, blandt dine sønner, skal du løse. 
2:13:14: Og når din sønn siden spør dig og sier: Hvad betyr dette? da skal du svare ham: Med sterk hånd førte Herren oss ut av Egypten, av trælehuset. 
2:13:15: For dengang da Farao satte sig hårdt imot å la oss fare, da slo Herren ihjel alt førstefødt i Egyptens land, både folk og fe; derfor ofrer jeg Herren alt det som åpner morsliv, det som er av hankjønn, og alle førstefødte blandt mine sønner løser jeg. 
2:13:16: Og det skal være til et tegn på din hånd og til en minneseddel på din panne; for med sterk hånd førte Herren oss ut av Egypten. 
2:13:17: Da nu Farao lot folket fare, da førte Gud dem ikke på veien til filistrenes land, skjønt den var den nærmeste; for Gud sa: Folket kunde angre det når de ser krig for sig, og så vende tilbake til Egypten. 
2:13:18: Men Gud lot folket ta omveien gjennem ørkenen mot det Røde Hav. Og Israels barn drog fullt rustet ut av Egyptens land. 
2:13:19: Og Moses tok Josefs ben med sig; for Josef hadde tatt en ed av Israels barn og sagt: Gud skal visselig se til eder, og da skal I føre mine ben med eder op herfra. 
2:13:20: Så brøt de op fra Sukkot og slo leir i Etam ved grensen av ørkenen. 
2:13:21: Og Herren gikk foran dem, om dagen i en skystøtte for å lede dem på veien og om natten i en ildstøtte for å lyse for dem, så de kunde dra frem både dag og natt. 
2:13:22: Skystøtten vek ikke fra folket om dagen, ikke heller ildstøtten om natten. 
2:14:1: Og Herren talte til Moses og sa: 
2:14:2: Si til Israels barn at de skal vende om og slå leir foran Pi-Hakirot, mellem Migdol og havet; midt imot Ba'al-Sefon, der skal I slå leir ved havet. 
2:14:3: Farao vil da si om Israels barn: De farer vill i landet, ørkenen har lukket dem inne. 
2:14:4: Og jeg vil forherde Faraos hjerte, så han forfølger dem, og jeg vil åpenbare min herlighet på Farao og hele hans hær, og egypterne skal kjenne at jeg er Herren. Og de gjorde så. 
2:14:5: Da det nu blev meldt kongen i Egypten at folket var flyktet, skiftet Farao og hans tjenere sinn mot folket, og de sa: Hvorledes kunde vi gjøre dette og la Israel dra bort fra vår tjeneste? 
2:14:6: Så lot han spenne for sin vogn og tok sitt hærfolk med sig. 
2:14:7: Han tok seks hundre utvalgte vogner og alle de andre vogner i Egypten, og krigsmenn var det på dem alle. 
2:14:8: For Herren forherdet Faraos, egypterkongens, hjerte, så han forfulgte Israels barn; men Israels barn drog ut med løftet hånd. 
2:14:9: Så forfulgte egypterne dem og nådde dem igjen da de lå i leir ved havet, alle Faraos vogner og hestfolk og hele hans hær, ved Pi-Hakirot, foran Ba'al-Sefon. 
2:14:10: Og Farao kom nærmere og nærmere, og da Israels barn så sig om, fikk de se egypterne komme settende efter dem; da kom det stor redsel over Israels barn, og de ropte til Herren. 
2:14:11: Og de sa til Moses: Var det da ingen graver i Egypten siden du har ført oss hit for å dø i ørkenen? Hvorfor har du gjort dette mot oss og ført oss ut av Egypten? 
2:14:12: Var det ikke det vi sa til dig i Egypten: La oss være i fred, vi vil tjene egypterne; det er bedre for oss å tjene egypterne enn å dø i ørkenen? 
2:14:13: Da sa Moses til folket: Frykt ikke, stå nu her og se Herrens frelse, som han vil sende eder idag! For som I ser egypterne idag, skal I aldri i evighet se dem mere. 
2:14:14: Herren skal stride for eder, og I skal være stille. 
2:14:15: Og Herren sa til Moses: Hvorfor roper du til mig? Si til Israels barn at de skal bryte op! 
2:14:16: Løft nu du op din stav og rekk ut din hånd over havet og skill det at, og Israels barn skal gå midt igjennem havet på det tørre. 
2:14:17: Men jeg vil forherde egypternes hjerte, så de setter efter eder; og jeg vil åpenbare min herlighet på Farao og hele hans hær, på hans vogner og hestfolk. 
2:14:18: Og egypterne skal kjenne at jeg er Herren, når jeg åpenbarer min herlighet på Farao, på hans vogner og hestfolk. 
2:14:19: Og Guds engel, som gikk foran Israels leir, flyttet sig og gikk bakefter dem; skystøtten, som var foran dem, flyttet sig og stilte sig bak dem, 
2:14:20: så den kom imellem egypternes leir og Israels leir; på den ene side var den sky og mørke, og på den annen side lyste den op natten; og den ene leir kom ikke inn på den andre hele natten. 
2:14:21: Da rakte Moses ut sin hånd over havet, og Herren drev havet bort med en sterk østenvind, som blåste hele natten, og han gjorde havet til tørt land, og vannet skiltes at. 
2:14:22: Og Israels barn gikk midt igjennem havet på det tørre, og vannet stod som en mur på deres høire og på deres venstre side. 
2:14:23: Og egypterne satte efter og fulgte dem til midt ut i havet, alle Faraos hester, hans vogner og hestfolk. 
2:14:24: Men ved morgenvaktens tid skuet Herren fra ild- og skystøtten ned på egypternes leir, og han forvirret egypternes leir, 
2:14:25: og han slo hjulene av deres vogner, så det blev tungt for dem å komme frem. Da sa egypterne: La oss flykte for Israel; for Herren strider for dem imot egypterne. 
2:14:26: Da sa Herren til Moses: Rekk ut din hånd over havet, så skal vannet vende tilbake over egypterne, over deres vogner og deres hestfolk. 
2:14:27: Så rakte Moses ut sin hånd over havet, og da det led mot morgenen, vendte havet tilbake til sitt vanlige leie, og egypterne flyktet like mot det; og Herren styrtet egypterne midt ut i havet. 
2:14:28: Vannet vendte tilbake og skjulte vognene og hestfolket i hele Faraos hær, som var kommet efter dem ut i havet; det blev ikke en eneste tilbake av dem. 
2:14:29: Men Israels barn gikk på det tørre midt igjennem havet, og vannet stod som en mur på deres høire og på deres venstre side. 
2:14:30: Således frelste Herren den dag Israel av egypternes hånd, og Israel så egypterne ligge døde på havstranden. 
2:14:31: Og da Israel så den veldige gjerning Herren hadde gjort med egypterne, da fryktet folket Herren; og de trodde på Herren og på hans tjener Moses. 
2:15:1: Da sang Moses og Israels barn denne sang for Herren: Jeg vil lovsynge Herren, for han er høit ophøiet; hest og mann styrtet han i havet. 
2:15:2: Herren er min styrke og lovsang, og han blev mig til frelse; han er min Gud, og jeg vil prise ham, min fars Gud, og jeg vil ophøie ham. 
2:15:3: Herren er en stridsmann, Herren er hans navn. 
2:15:4: Faraos vogner og hans hær kastet han i havet, og hans utvalgte vognkjempere druknet i det Røde Hav. 
2:15:5: Avgrunner skjulte dem, de sank som sten i dype vann. 
2:15:6: Din høire hånd, Herre, er herliggjort i kraft, din høire hånd, Herre, knuser fiender. 
2:15:7: Og i din høihets velde slår du dine motstandere ned; du slipper din vrede løs, den fortærer dem som strå. 
2:15:8: Og ved ditt åndepust hopet vannene sig sammen, bølgene stod som voller, dype vann stivnet i havets hjerte. 
2:15:9: Fienden sa: Jeg vil forfølge dem, jeg vil innhente dem, jeg vil dele ut hærfang, jeg vil mette min sjel med dem; jeg vil dra mitt sverd, min hånd skal utrydde dem. 
2:15:10: Du blåste med din ånde, havet skjulte dem; de sank som bly i de veldige vann. 
2:15:11: Herre, hvem er som du blandt gudene? Hvem er som du herliggjort i hellighet, forferdelig å love, underfull i gjerning? 
2:15:12: Du rakte ut din høire hånd, jorden slukte dem. 
2:15:13: Du fører ved din miskunnhet det folk som du forløste; du leder dem ved din kraft til din hellige bolig. 
2:15:14: Folkene hører det, de skjelver; angst griper dem som bor i Filisterland. 
2:15:15: Da forferdes Edoms stammefyrster, redsel griper Moabs høvdinger, alle Kana'ans innbyggere forgår av angst. 
2:15:16: Forferdelse og redsel faller over dem, ved din arms velde blir de målløse som sten, mens ditt folk drar frem, Herre, mens det folk drar frem som du har vunnet dig. 
2:15:17: Du fører dem inn og planter dem på din arvs berg, det sted du har skapt dig til bolig, Herre, den helligdom, Herre, som dine hender har grunnlagt. 
2:15:18: Herren skal være konge i all evighet. 
2:15:19: For da Faraos hester med hans vogner og menn for ut i havet, da lot Herren havets vann vende tilbake over dem, mens Israels barn gikk på det tørre midt igjennem havet. 
2:15:20: Og profetinnen Mirjam, Arons søster, tok en tromme i sin hånd, og alle kvinnene gikk efter henne med trommer og dans. 
2:15:21: Og Mirjam sang fore: Lovsyng Herren, for han er høit ophøiet; hest og mann styrtet han i havet. 
2:15:22: Så lot Moses Israel bryte op fra det Røde Hav, og de drog ut i ørkenen Sur; og tre dager drog de frem i ørkenen uten å finne vann. 
2:15:23: Så kom de til Mara; men de kunde ikke drikke vannet i Mara fordi det var beskt; derfor blev stedet kalt Mara*. # <* beskhet.> 
2:15:24: Da knurret folket mot Moses og sa: Hvad skal vi drikke? 
2:15:25: Og han ropte til Herren, og Herren viste ham et tre; det kastet han i vannet, og vannet blev godt. Der satte han dem lov og rett, og der prøvde han dem; 
2:15:26: og han sa: Dersom du hører på Herren din Guds røst, og gjør det som er rett i hans øine, og gir akt på hans bud, og holder alle hans forskrifter, da vil jeg ikke legge på dig nogen av de sykdommer som jeg la på egypterne; for jeg er Herren, din læge. 
2:15:27: Så kom de til Elim; der var tolv vannkilder og sytti palmetrær; og de slo leir der ved vannet. 
2:16:1: Så brøt de op fra Elim, og den femtende dag i den annen måned efterat de hadde draget ut av Egyptens land, kom hele Israels barns menighet til ørkenen Sin, som ligger mellem Elim og Sinai. 
2:16:2: Og hele Israels barns menighet knurret mot Moses og Aron i ørkenen. 
2:16:3: Og Israels barn sa til dem: Å, om vi var død for Herrens hånd i Egyptens land da vi satt ved kjøttgrytene, og da vi åt brød til vi blev mette! For I har ført oss hit ut i ørkenen for å la hele denne store folkeskare dø av hunger. 
2:16:4: Da sa Herren til Moses: Se, jeg vil la regne ned brød fra himmelen til eder, og folket skal gå ut og sanke for hver dag det de trenger; således vil jeg prøve dem, om de vil følge min lov eller ikke. 
2:16:5: Og på den sjette dag skal de lage til det som de har hatt med hjem, og det skal være dobbelt så meget som det de ellers sanker for hver dag. 
2:16:6: Og Moses og Aron sa til alle Israels barn: Iaften skal I kjenne at det er Herren som har ført eder ut av Egyptens land, 
2:16:7: og imorgen skal I få se Herrens herlighet, fordi han har hørt hvorledes I knurrer mot ham; for hvad er vi, at I knurrer mot oss? 
2:16:8: Så sa Moses: I skal få se det når Herren iaften gir eder kjøtt å ete og imorgen brød, så I blir mette; for Herren har hørt hvorledes I knurrer og murrer mot ham; for hvad er vel vi? Det er ikke mot oss I knurrer, men mot Herren. 
2:16:9: Og Moses sa til Aron: Si til hele Israels barns menighet: Kom frem for Herrens åsyn; for han har hørt hvorledes I knurrer. 
2:16:10: Da så Aron talte til hele Israels barns menighet, vendte de sig mot ørkenen, og se, Herrens herlighet åpenbarte sig i skyen. 
2:16:11: Og Herren talte til Moses og sa: 
2:16:12: Jeg har hørt hvorledes Israels barn knurrer; tal til dem og si: Imellem de to aftenstunder* skal I få kjøtt å ete, og imorgen skal I mettes med brød, og I skal kjenne at jeg er Herren eders Gud. # <* se 2MO 12, 6.> 
2:16:13: Da det nu blev aften, kom det vaktler og dekket leiren, og om morgenen var det et lag av dugg rundt omkring leiren. 
2:16:14: Og da duggen gikk bort, se, da lå det utover ørkenen noget fint, kornet, fint som rim på jorden. 
2:16:15: Da Israels barn så det, sa de til hverandre: Hvad er det? for de visste ikke hvad det var. Da sa Moses til dem: Det er det brød Herren har gitt eder å ete. 
2:16:16: Således har Herren befalt: Sank av det efter som enhver trenger, en omer til manns efter tallet på eders husfolk; enhver skal ta for dem som er i hans telt. 
2:16:17: Og Israels barn gjorde således, og de sanket, den ene meget, den andre lite. 
2:16:18: Og da de målte det med omeren, hadde den som hadde sanket meget, intet tilovers, og den som hadde sanket lite, fattedes intet; enhver hadde sanket efter som han trengte. 
2:16:19: Og Moses sa til dem: Ingen skal levne noget av det til om morgenen. 
2:16:20: Men de lød ikke Moses, for nogen levnet noget av det til om morgenen; da vokste det makk i det, og det luktet ille. Og Moses blev vred på dem. 
2:16:21: Siden sanket de det hver morgen efter som enhver trengte; men når solen brente hett, smeltet det. 
2:16:22: På den sjette dag sanket de dobbelt så meget brød, to omer for hver; og alle menighetens høvdinger kom og meldte det til Moses. 
2:16:23: Da sa han til dem: Det er det Herren har sagt. Imorgen er det sabbat, hellig sabbat for Herren; bak nu det I vil bake, og kok det I vil koke, men alt det som blir tilovers, skal I legge til side og gjemme til imorgen! 
2:16:24: Så lot de det ligge til om morgenen, således som Moses hadde befalt; og det luktet ikke, heller ikke var det makk i det. 
2:16:25: Da sa Moses: Et det idag. For idag er det sabbat for Herren; idag finner I ikke noget på marken. 
2:16:26: I seks dager skal I sanke det; men på den syvende dag er det sabbat, da skal det ikke være å finne. 
2:16:27: Allikevel gikk nogen av folket ut den syvende dag for å sanke; men de fant intet. 
2:16:28: Da sa Herren til Moses: Hvor lenge vil I være gjenstridige og ikke holde mine bud og mine lover? 
2:16:29: Kom i hu at Herren har gitt eder sabbaten; derfor gir han eder på den sjette dag brød for to dager; bli hjemme enhver hos sig, ingen gå hjemmefra på den syvende dag! 
2:16:30: Så hvilte folket på den syvende dag. 
2:16:31: Og israelittene kalte det manna*; det lignet korianderfrø, det var hvitt, og det smakte som honningkake. # <* hebr. man, d.e. hvad er det?.> 
2:16:32: Og Moses sa: Således har Herren befalt: Fyll en omer av det og gjem det for eders efterkommere, så de kan se det brød jeg gav eder å ete i ørkenen da jeg førte eder ut av Egyptens land. 
2:16:33: Så sa Moses til Aron: Ta en krukke og legg i den en full omer manna, og sett den ned for Herrens åsyn til å gjemmes for eders efterkommere! 
2:16:34: Som Herren hadde befalt Moses, således satte Aron den ned foran vidnesbyrdet* til å gjemmes. # <* lovens tavler.> 
2:16:35: Og Israels barn åt manna i firti år, inntil de kom til bygget land; de åt manna helt til de kom til grensen av Kana'ans land. 
2:16:36: En omer er tiendedelen av en efa. 
2:17:1: Så drog hele Israels barns menighet fra ørkenen Sin i dagsreiser efter Herrens befaling; de slo leir i Refidim, og folket hadde ikke vann å drikke. 
2:17:2: Da kivedes folket med Moses og sa: Gi oss vann, så vi får drikke! Moses svarte dem: Hvorfor kives I med mig? Hvorfor frister I Herren? 
2:17:3: Men folket tørstet efter vann og knurret mot Moses og sa: Hvorfor har du ført oss op fra Egypten for å la oss og våre barn og vår buskap dø av tørst? 
2:17:4: Da ropte Moses til Herren og sa: Hvad skal jeg gjøre med dette folk? Det er ikke langt fra at de stener mig! 
2:17:5: Og Herren sa til Moses: Gå frem foran folket og ta med dig nogen av Israels eldste, og din stav som du slo i elven med, ta den i hånden og gå! 
2:17:6: Se, jeg vil stå der foran dig på klippen ved Horeb, og du skal slå på klippen, og det skal flyte vann ut av den, så folket får drikke. Og Moses gjorde således så Israels eldste så på det. 
2:17:7: Og han kalte stedet Massa* og Meriba**, fordi Israels barn kivedes med ham, og fordi de fristet Herren og sa: Er Herren iblandt oss eller ikke? # <* fristelse.> <** kiv.> 
2:17:8: Så kom Amalek og stred med Israel i Refidim. 
2:17:9: Da sa Moses til Josva: Velg oss ut mannskap, dra så avsted og strid mot Amalek! Imorgen vil jeg stå øverst på haugen med Guds stav i min hånd. 
2:17:10: Og Josva gjorde som Moses hadde sagt ham, og stred mot Amalek; og Moses, Aron og Hur steg op øverst på haugen. 
2:17:11: Da gikk det så at så lenge Moses holdt sin hånd opløftet, hadde Israel overtaket; men når han lot sin hånd synke, da fikk Amalek overtaket. 
2:17:12: Men da Moses' hender blev tunge, tok de en sten og la under ham, og han satte sig på den; og Aron og Hur støttet hans hender, den ene på den ene side og den andre på den andre side; så holdt hans hender sig støe helt til solen gikk ned. 
2:17:13: Og Josva hugg Amalek og hans folk ned med sverdets egg. 
2:17:14: Og Herren sa til Moses: Skriv dette op i en bok, så I kommer det i hu, og prent det i Josvas ører! For jeg vil aldeles utslette minnet om Amalek over hele jorden. 
2:17:15: Så bygget Moses et alter og kalte det: Herren er mitt banner. 
2:17:16: Og han sa: En hånd er utrakt fra Herrens trone! Herren skal stride mot Amalek fra slekt til slekt. 
2:18:1: Jetro, presten i Midian, Moses' svigerfar, fikk høre alt det Gud hadde gjort for Moses og for sitt folk Israel, hvorledes Herren hadde ført Israel ut av Egypten. 
2:18:2: Da tok Jetro, Moses' svigerfar, Sippora, Moses' hustru, som Moses før hadde sendt hjem, 
2:18:3: og hennes to sønner - den ene hette Gersom, for Moses hadde sagt: Jeg er blitt gjest i et fremmed land, 
2:18:4: og den andre hette Elieser*, for han hadde sagt: Min fars Gud var min hjelp og fridde mig fra Faraos sverd. # <* Gud er hjelp.> 
2:18:5: Da nu Jetro, Moses' svigerfar, kom med hans sønner og hans hustru til ham i ørkenen, der hvor Moses hadde slått leir, ved Guds berg, 
2:18:6: sendte han bud til Moses og sa: Jeg, din svigerfar Jetro, kommer til dig med din hustru og hennes to sønner. 
2:18:7: Da gikk Moses sin svigerfar møte, bøide sig for ham og kysset ham, og de spurte hverandre hvorledes det stod til; så gikk de inn i teltet. 
2:18:8: Og Moses fortalte sin svigerfar alt det Herren hadde gjort med Farao og egypterne for Israels skyld, og all den møie de hadde hatt på veien, og hvorledes Herren hadde hjulpet dem. 
2:18:9: Da blev Jetro glad over alt det gode Herren hadde gjort mot Israel, at han hadde fridd dem av egypternes hånd. 
2:18:10: Og Jetro sa: Lovet være Herren, som fridde eder av egypternes hånd Og av Faraos hånd - han som fridde folket av egypternes hånd! 
2:18:11: Nu vet jeg at Herren er større enn alle guder; for således viste han sig ved det* hvormed egypterne viste sitt overmot mot dette folk. # <* d.e. ved vannet.> 
2:18:12: Og Jetro, Moses' svigerfar, tok og ofret brennoffer og slaktoffer til Gud; og Aron og alle Israels eldste kom og holdt måltid med Moses' svigerfar for Guds åsyn. 
2:18:13: Dagen efter satt Moses og skiftet rett mellem folket, og folket stod omkring Moses fra morgen til kveld. 
2:18:14: Da Moses' svigerfar så hvor meget han hadde å gjøre for folket, sa han: Hvad er dette for et arbeid du legger på dig for folket? Hvorfor sitter du alene og dømmer mens hele folket står omkring dig fra morgen til kveld? 
2:18:15: Moses svarte sin svigerfar: Folket kommer til mig for å få vite Guds vilje; 
2:18:16: når de har en sak, da kommer de til mig, og jeg skifter rett mellem dem og lærer dem Guds bud og lover. 
2:18:17: Da sa Moses' svigerfar til ham: Det er ikke klokt det du her gjør. 
2:18:18: Du må jo bli altfor trett, både du og dette folk som er med dig; for dette arbeid er for svært for dig, du makter ikke å gjøre det alene. 
2:18:19: Men hør nu på mig! Jeg vil gi dig et råd, og Gud skal være med dig. Tred du frem for Gud på folkets vegne og legg deres saker frem for ham, 
2:18:20: forklar dem budene og lovene, og lær dem den vei de skal vandre, og den gjerning de skal gjøre. 
2:18:21: Velg dig så ut duelige menn av hele folket, menn som frykter Gud, troverdige menn, som hater urettferdig vinning, og sett dem til domsmenn over dem, nogen over tusen, nogen over hundre, nogen over femti og nogen over ti! 
2:18:22: Og de skal skifte rett mellem folket til enhver tid; enhver stor sak skal de komme til dig med, men enhver liten sak skal de selv dømme i. Således letter du byrden for dig selv, og de bærer den med dig. 
2:18:23: Dersom du gjør dette, og Gud byder dig det, da vil du kunne holde ut, og da vil også alt folket her kunne gå hjem i fred. 
2:18:24: Moses lød sin svigerfars råd og gjorde alt det han sa. 
2:18:25: Han valgte ut duelige menn av hele Israel og satte dem til høvdinger over folket, til domsmenn, nogen over tusen, nogen over hundre, nogen over femti og nogen over ti. 
2:18:26: Og de skiftet rett mellem folket til enhver tid; enhver vanskelig sak kom de til Moses med, men i enhver liten sak dømte de selv. 
2:18:27: Så bad Moses farvel med sin svigerfar, og han drog hjem til sitt eget land. 
2:19:1: På tremåneders-dagen efterat Israels barn var gått ut av Egyptens land, kom de til Sinai ørken. 
2:19:2: De brøt op fra Refidim og kom til Sinai ørken og slo leir i ørkenen, og Israel leiret sig der midt imot fjellet. - 
2:19:3: Men Moses steg op til Gud, og Herren ropte til ham fra fjellet og sa: Så skal du si til Jakobs hus og kunngjøre for Israels barn: 
2:19:4: I har sett hvad jeg har gjort med egypterne, og hvorledes jeg har båret eder på ørnevinger og ført eder til mig. 
2:19:5: Dersom I nu lyder min røst og holder min pakt, da skal I være min eiendom fremfor alle folk; for hele jorden hører mig til. 
2:19:6: Og I skal være mig et kongerike av prester og et hellig folk. Dette er de ord du skal tale til Israels barn. 
2:19:7: Da Moses kom tilbake, kalte han folkets eldste til sig og bar frem for dem alle disse ord som Herren hadde pålagt ham. 
2:19:8: Da svarte folket alle som én og sa: Alt det Herren har sagt, vil vi gjøre. Og Moses bar folkets ord tilbake til Herren. 
2:19:9: Da sa Herren til Moses: Se, jeg vil komme til dig i en tykk sky, så folket kan høre når jeg taler med dig, og alltid tro på dig. Og Moses bar folkets ord frem for Herren. 
2:19:10: Og Herren sa til Moses: Gå til folket og la dem hellige sig idag og imorgen og tvette sine klær, 
2:19:11: og la dem holde sig rede den tredje dag; for på den tredje dag skal Herren stige ned på Sinai berg for hele folkets øine. 
2:19:12: Og du skal avmerke en grense for folket rundt omkring og si: Vokt eder for å stige op på fjellet eller røre ved dets fot! Enhver som rører ved fjellet, skal visselig late livet; 
2:19:13: ingen hånd skal røre ved ham, han skal stenes eller skytes; enten det er dyr eller mennesker, skal de ikke leve. Når basunen lyder med lange toner, da skal de* stige op på fjellet. # <* se 2MO 24, 1.> 
2:19:14: Så gikk Moses ned fra fjellet til folket, og han lot folket hellige sig og tvette sine klær. 
2:19:15: Og han sa til folket: Hold eder rede den tredje dag, kom ikke nær nogen kvinne! 
2:19:16: Så skjedde det den tredje dag da morgenen brøt frem, da tok det til å tordne og lyne, og det la sig en tung sky over fjellet, og det hørtes en sterk basunlyd; da skalv alt folket som var i leiren. 
2:19:17: Men Moses førte folket ut av leiren til møte med Gud, og de stilte sig nedenfor fjellet. 
2:19:18: Og hele Sinai berg stod i røk, fordi Herren var steget ned på det i ild, og røken av det steg op som røken av en ovn, og hele fjellet skalv. 
2:19:19: Og basunens lyd tok til og blev sterkere og sterkere; Moses talte, og Gud svarte ham med lydelig røst. 
2:19:20: Og Herren steg ned på Sinai berg, på fjellets topp; og Herren kalte Moses op på fjellets topp, og Moses steg op. 
2:19:21: Og Herren sa til Moses: Stig ned og advar folket, så de ikke trenger sig frem til Herren for å se ham, for da kommer mange av dem til å falle. 
2:19:22: Også prestene, de som treder Herren nær, skal hellige sig, forat Herren ikke skal bryte inn blandt dem. 
2:19:23: Da sa Moses til Herren: Folket kan ikke stige op på Sinai berg; for du har selv advart oss og sagt at vi skulde avmerke en grense om fjellet og hellige det. 
2:19:24: Og Herren sa til ham: Stig nu ned og kom så her op igjen, både du og Aron; men prestene og folket må ikke trenge sig frem og stige op til Herren, forat han ikke skal bryte inn blandt dem. 
2:19:25: Da steg Moses ned til folket og sa det til dem. 
2:20:1: Da talte Gud alle disse ord og sa: 
2:20:2: Jeg er Herren din Gud, som førte dig ut av Egyptens land, av trælehuset. 
2:20:3: Du skal ikke ha andre guder foruten mig. 
2:20:4: Du skal ikke gjøre dig noget utskåret billede eller nogen avbildning av det som er oppe i himmelen, eller av det som er nede på jorden, eller av det som er i vannet nedenfor jorden. 
2:20:5: Du skal ikke tilbede dem og ikke tjene dem; for jeg, Herren din Gud, er en nidkjær Gud, som hjemsøker fedres misgjerninger på barn inntil tredje og fjerde ledd, på dem som hater mig, 
2:20:6: og som gjør miskunnhet mot tusen ledd, mot dem som elsker mig og holder mine bud. 
2:20:7: Du skal ikke misbruke Herrens, din Guds navn; for Herren vil ikke holde den uskyldig som misbruker hans navn. 
2:20:8: Kom hviledagen i hu, så du holder den hellig! 
2:20:9: Seks dager skal du arbeide og gjøre all din gjerning. 
2:20:10: Men den syvende dag er sabbat for Herren din Gud; da skal du intet arbeid gjøre, hverken du eller din sønn eller din datter, din tjener eller din tjenestepike eller ditt fe eller den fremmede som er hos dig innen dine porter. 
2:20:11: For i seks dager gjorde Herren himmelen og jorden, havet og alt det som i dem er, og han hvilte på den syvende dag; derfor velsignet Herren sabbatsdagen og helliget den. 
2:20:12: Hedre din far og din mor, så dine dager må bli mange i det land Herren din Gud gir dig. 
2:20:13: Du skal ikke slå ihjel. 
2:20:14: Du skal ikke drive hor. 
2:20:15: Du skal ikke stjele. 
2:20:16: Du skal ikke si falskt vidnesbyrd mot din næste. 
2:20:17: Du skal ikke begjære din næstes hus. Du skal ikke begjære din næstes hustru eller hans tjener eller hans tjenestepike eller hans okse eller hans asen eller noget som hører din næste til. 
2:20:18: Og alt folket så og hørte tordenen og luene og basunlyden og fjellet i røk; og da folket så og hørte dette, skalv de og holdt sig langt borte. 
2:20:19: Og de sa til Moses: Tal du med oss, så vil vi høre; men la ikke Gud tale med oss, forat vi ikke skal dø! 
2:20:20: Men Moses sa til folket: Frykt ikke! Gud er kommet for å prøve eder, og forat frykt for ham skal være over eder, så I ikke synder. 
2:20:21: Så blev folket stående langt borte, og Moses gikk nær til mørket hvor Gud var. 
2:20:22: Og Herren sa til Moses: Så skal du si til Israels barn: I har sett hvorledes jeg talte til eder fra himmelen. 
2:20:23: I skal ikke gjøre eder nogen gud ved siden av mig; guder av sølv eller guder av gull skal I ikke gjøre eder. 
2:20:24: Et alter av jord skal du gjøre mig, og på det skal du ofre dine brennoffer og dine takkoffer, ditt småfe og ditt storfe; på ethvert sted hvor jeg lar mitt navn ihukomme, vil jeg komme til dig og velsigne dig. 
2:20:25: Men dersom du vil gjøre mig et alter av sten, da skal du ikke bygge det av huggen sten; for bruker du ditt huggjern på stenene, da vanhelliger du dem. 
2:20:26: Og du skal ikke gå op til mitt alter på trapper, forat ikke din blusel skal blottes over det. 
2:21:1: Dette er de lover som du skal legge frem for dem: 
2:21:2: Når du kjøper en hebraisk træl, skal han tjene i seks år; men i det syvende skal han gis fri uten vederlag. 
2:21:3: Dersom han kommer enslig, da skal han gå enslig bort; dersom han er gift mann, da skal hans hustru gå bort med ham. 
2:21:4: Dersom hans herre gir ham en hustru, og hun føder ham sønner eller døtre, da skal hustruen og barna høre hennes herre til, og han skal gå enslig bort. 
2:21:5: Men dersom trælen sier: Jeg holder av min herre, min hustru og mine barn, jeg vil ikke være fri og gå bort, 
2:21:6: da skal hans herre føre ham frem for Gud* og stille ham ved døren eller ved dørstolpen, og hans herre skal stikke en syl gjennem hans øre, og han skal tjene ham all sin tid. # <* d.e. de av Gud innsatte dommere.> 
2:21:7: Når nogen selger sin datter til trælkvinne, da skal hun ikke gis fri som trælene. 
2:21:8: Dersom hun mishager sin herre, som hadde utsett henne for sig selv, da skal han la henne få kjøpe sig fri; til et fremmed folk skal han ikke ha rett til å selge henne, siden han har vært troløs mot henne. 
2:21:9: Men dersom han utser henne for sin sønn, da skal han unne henne døtres rett. 
2:21:10: Dersom han lar ham få en annen foruten henne, da skal han ikke avkorte noget i hennes kost, klær eller ekteskapsrett. 
2:21:11: Men dersom han ikke lar henne få disse tre ting, da skal hun gis fri for intet, uten vederlag. 
2:21:12: Den som slår et menneske så det dør, han skal visselig late livet. 
2:21:13: Men hvis han ikke har stått ham efter livet, men det er Gud som har latt ham komme ut for hans hånd, da vil jeg sette dig et fristed som han kan ty til. 
2:21:14: Men om nogen bærer sig så formastelig at, at han dreper sin næste med svik, da skal du ta ham om det så var fra mitt alter: han skal dø. 
2:21:15: Den som slår sin far eller sin mor, skal visselig late livet. 
2:21:16: Den som stjeler et menneske og selger ham eller holder ham fanget, skal visselig late livet. 
2:21:17: Den som banner sin far eller sin mor, skal visselig late livet. 
2:21:18: Når menn kommer i trette, og den ene slår den andre med en sten eller med neven, og han ikke dør, men blir sengeliggende - 
2:21:19: dersom han da kommer op igjen og går ute med stav, da skal den som slo, være fri for straff; men han skal gi ham vederlag for den tid han har tapt, og koste full lægedom på ham. 
2:21:20: Når nogen slår sin træl eller trælkvinne med en stokk så de dør under hans hånd, da skal han straffes for det. 
2:21:21: Men dersom de blir i live én eller to dager, skal han ikke straffes; de er jo hans eiendom. 
2:21:22: Når menn kommer i slagsmål med hverandre og støter til en fruktsommelig kvinne, så hun føder i utide, men ellers ingen ulykke skjer, så skal den som gjorde det, gi den bot som kvinnens mann pålegger ham; han skal gi efter dommeres skjønn. 
2:21:23: Men dersom det skjer en ulykke, da skal du gi liv for liv, 
2:21:24: øie for øie, tann for tann, hånd for hånd, fot for fot, 
2:21:25: brent for brent, sår for sår, skramme for skramme. 
2:21:26: Når nogen slår sin træl eller trælkvinne i øiet og forderver det, da skal han gi dem fri til vederlag for øiet. 
2:21:27: Og dersom han slår ut en tann på sin træl eller trælkvinne, da skal han gi dem fri til vederlag for tannen. 
2:21:28: Om en okse stanger mann eller kvinne så de dør, da skal oksen stenes, og dens kjøtt skal ikke etes; men oksens eier skal være fri for straff. 
2:21:29: Men dersom det er en okse som før har pleid å stange, og dens eier er advart, men ikke passer på den, og den dreper mann eller kvinne, da skal oksen stenes, og dens eier skal også lide døden. 
2:21:30: Men dersom bøter pålegges ham, da skal han gi så meget i løsepenger for sitt liv som det blir ham pålagt. 
2:21:31: Er det en gutt eller pike den stanger, skal det gjøres med ham efter denne lov. 
2:21:32: Dersom oksen stanger en træl eller en trælkvinne, da skal eieren bøte tretti sekel sølv til deres herre; og oksen skal stenes. 
2:21:33: Når nogen lar en brønn stå åpen eller graver en brønn og ikke dekker den til, og det faller en okse eller et asen i den, 
2:21:34: da skal brønnens eier godtgjøre det; han skal gi dyrets eier penger i vederlag, men det døde dyr skal være hans. 
2:21:35: Når en manns okse stanger en annen manns okse ihjel, da skal de selge den levende okse og dele pengene for den, og det døde dyr skal de også dele. 
2:21:36: Men er det vitterlig at det er en okse som før har pleid å stange, og dens eier ikke passer på den, da skal han gi en annen okse isteden, men det døde dyr skal være hans. 
2:22:1: Når nogen stjeler en okse eller et får og slakter eller selger dem, så skal han gi fem okser istedenfor oksen og fire får for fåret. 
2:22:2: Dersom tyven blir grepet mens han bryter inn, og blir slått så han dør, da kommer det ingen blodskyld derav. 
2:22:3: Men skjer det efterat solen er gått op, da blir det blodskyld derav. Tyven skal gi full bot; har han intet, skal han selges til vederlag for det han har stjålet. 
2:22:4: Dersom det han har stjålet, finnes levende hos ham, enten det er en okse eller et asen eller et får, da skal han gi dobbelt igjen. 
2:22:5: Når nogen lar sitt fe beite på sin aker eller i sin vingård, og han slipper det løs så det kommer til å beite på en annens aker, da skal han gi i vederlag det beste på sin aker og det beste i sin vingård. 
2:22:6: Når ild bryter løs og fatter i tornehekker, og kornbånd eller det stående korn eller hele akeren brenner op, da skal den som voldte branden, gi fullt vederlag. 
2:22:7: Når nogen gir sin næste penger eller gods å gjemme, og det blir stjålet bort fra mannens hus, da skal tyven, om han finnes, gi dobbelt igjen. 
2:22:8: Men finnes ikke tyven, da skal husets eier føres frem for Gud*, forat det kan avgjøres om han ikke har forgrepet sig på sin næstes eiendom. # <* se 2MO 21, 6.> 
2:22:9: Hver gang det gjelder svikefull adferd med gods, enten det er en okse eller et asen eller et får eller klær eller i det hele noget som er kommet bort, og så en sier: her er det, da skal saken mellem de to komme frem for Gud*; den som Gud dømmer skyldig, han skal gi sin næste dobbelt igjen. # <* se 2MO 22, 8.> 
2:22:10: Når nogen gir sin næste et asen eller en okse eller et får eller i det hele noget husdyr å ta vare på, og det dør eller kommer til skade eller røves uten at nogen ser det, 
2:22:11: da skal ed ved Herren skille mellem dem og avgjøre om han ikke har forgrepet sig på sin næstes eiendom, og eieren skal ta eden for god, og den andre skal ikke gi noget vederlag. 
2:22:12: Men blir det stjålet fra ham, da skal han gi dets eier vederlag. 
2:22:13: Blir det revet ihjel, da skal han føre det frem til bevis; han skal ikke gi vederlag for det som er revet ihjel. 
2:22:14: Når nogen låner et dyr av sin næste, og det kommer til skade eller dør, og dets eier ikke er til stede, da skal han gi full bot. 
2:22:15: Men dersom eieren er til stede, da skal han ikke gi vederlag; dersom det er leiet, går det inn i leien. 
2:22:16: Når nogen forfører en jomfru som ikke er trolovet, og ligger hos henne, da skal han gi festegave for henne og ta henne til hustru. 
2:22:17: Dersom faren ikke vil la ham få henne, da skal han gi så meget i pengebøter som en pleier å gi i festegave for en jomfru. 
2:22:18: En trollkvinne skal du ikke la leve 
2:22:19: Enhver som blander sig med fe, skal visselig late livet. 
2:22:20: Den som ofrer til avgudene og ikke til Herren alene, skal være forbannet. 
2:22:21: En fremmed skal du ikke plage og ikke undertrykke; for I har selv vært fremmede i Egyptens land. 
2:22:22: I skal ikke plage nogen enke eller farløs; 
2:22:23: dersom du plager dem, og de roper til mig, skal jeg visselig høre deres rop, 
2:22:24: og min vrede skal optendes, og jeg skal slå eder ihjel med sverdet, og eders hustruer skal bli enker og eders barn farløse. 
2:22:25: Dersom du låner penger til nogen av mitt folk, til den fattige som bor hos dig, da skal du ikke være imot ham som en ågerkar; I skal ikke kreve renter av ham. 
2:22:26: Dersom du tar din næstes kappe i pant, skal du gi ham den igjen før solen går ned; 
2:22:27: for den er det eneste dekke han har, det er den han skal klæ sitt legeme med; hvad skal han ellers ligge i? Og når han roper til mig, vil jeg høre; for jeg er barmhjertig. 
2:22:28: Gud* skal du ikke spotte, og en høvding blandt ditt folk skal du ikke banne. # <* se 2MO 21, 6.> 
2:22:29: Av alt det som fyller din lade, og som flyter av din vinperse, skal du ikke dryge med å gi mig. Den førstefødte av dine sønner skal du gi mig. 
2:22:30: Det samme skal du gjøre med ditt storfe og ditt småfe; syv dager skal det være hos moren; den åttende dag skal du gi mig det. 
2:22:31: I skal være hellige mennesker for mig; I skal ikke ete kjøtt av ihjelrevne dyr som I finner på marken; I skal kaste det for hundene. 
2:23:1: Du skal ikke utbrede falskt rykte; du skal ikke gjøre felles sak med en ugudelig, så du blir et urettferdig vidne; 
2:23:2: du skal ikke følge mengden i det som ondt er, og du skal ikke vidne således i en rettssak at du bøier dig efter mengden og forvender retten; 
2:23:3: heller ikke om det gjelder en ringe mann, skal du pynte på hans sak. 
2:23:4: Når du treffer din fiendes okse eller hans asen som har gått sig vill, da skal du føre dem tilbake til ham. 
2:23:5: Når du ser din fiendes asen ligge under sin byrde, da vokt dig for å gå fra ham; du skal hjelpe ham med å løse byrden av. 
2:23:6: Du skal ikke i nogen sak bøie retten for den fattige som bor hos dig. 
2:23:7: Du skal holde dig langt borte fra en falsk sak og ikke hjelpe til at den uskyldige og rettferdige mister livet; for jeg dømmer ikke en skyldig å være uskyldig. 
2:23:8: Du skal ikke ta imot gave; for gaven gjør seende blinde og forvender de rettferdiges sak. 
2:23:9: En fremmed skal du ikke undertrykke; I vet jo hvorledes den fremmede er til mote; I var selv fremmede i Egyptens land. 
2:23:10: Seks år skal du så ditt land til og høste dets grøde; 
2:23:11: men i det syvende år skal du la det hvile og ligge, så de fattige blandt ditt folk kan ete derav, og hvad de levner, kan markens dyr ete. Det samme skal du gjøre med din vingård og dine oljetrær. 
2:23:12: Seks dager skal du gjøre din gjerning; men på den syvende dag skal du hvile, så din okse og ditt asen kan ha ro, og din trælkvinnes sønn og den fremmede får hvile sig ut. 
2:23:13: I alt det jeg har sagt til eder, skal I ta eder i vare. Andre guders navn skal I ikke nevne; slikt skal ikke høres av din munn. 
2:23:14: Tre ganger om året skal du holde høitid for mig. 
2:23:15: De usyrede brøds høitid skal du holde: Syv dager skal du ete usyret brød, således som jeg har befalt dig, på den fastsatte tid i abib måned, for i den drog du ut av Egypten; og ingen skal vise sig for mitt åsyn tomhendt. 
2:23:16: Likeså kornhøstens høitid, når førstegrøden faller av ditt arbeid, av det som du sår på marken, og frukthøstens høitid ved årets utgang, når du samler inn frukten av ditt arbeid på marken. 
2:23:17: Tre ganger om året skal alle menn blandt eder møte frem for Herrens, Israels Guds åsyn. 
2:23:18: Du skal ikke ofre blodet av mitt slaktoffer sammen med syret brød, og det fete av mitt høitidsoffer skal ikke bli liggende natten over til om morgenen. 
2:23:19: Det første av din jords førstegrøde skal du bære til Herrens, din Guds hus. Du skal ikke koke et kje i dets mors melk. 
2:23:20: Se, jeg sender en engel foran dig for å vokte dig på veien og for å føre dig til det sted jeg har utsett for dig. 
2:23:21: Ta dig i vare for ham og hør på hans røst, vær ikke gjenstridig mot ham! Han skal ikke bære over med eders overtredelser, for mitt navn er i ham. 
2:23:22: Men dersom du lyder hans røst og gjør alt det jeg sier, da vil jeg være en fiende av dine fiender og en motstander av dine motstandere. 
2:23:23: For min engel skal gå foran dig og føre dig frem til det land hvor amorittene og hetittene og ferisittene og kana'anittene, hevittene og jebusittene bor, og jeg vil tilintetgjøre dem. 
2:23:24: Du skal ikke tilbede deres guder eller tjene dem; du skal ikke gjøre som de gjør; men du skal rive deres avgudsbilleder ned og slå deres stenstøtter i stykker. 
2:23:25: I skal tjene Herren eders Gud, og han skal velsigne ditt brød og ditt vann. Og jeg vil holde sykdom borte fra dig; 
2:23:26: det skal ikke være nogen kvinne i ditt land som føder i utide eller er ufruktbar; dine dagers tall vil jeg gjøre fullt. 
2:23:27: Redsel for mig vil jeg sende foran dig og forvirre alle de folk du kommer til, og jeg vil la alle dine fiender flykte for dig. 
2:23:28: Og jeg vil sende hvepser foran dig, og de skal jage hevittene, kana'anittene og hetittene bort fra dig. 
2:23:29: Jeg vil ikke jage dem bort fra dig på ett år, forat ikke landet skal bli øde og de ville dyr bli for mange for dig; 
2:23:30: litt efter litt vil jeg jage dem bort fra dig, inntil du blir så stort et folk at du kan ta landet i eie. 
2:23:31: Jeg vil la ditt lands grenser gå fra det Røde Hav til filistrenes hav* og fra ørkenen til elven**; for jeg vil gi landets innbyggere i eders hånd, og du skal jage dem bort fra dig. # <* Middelhavet.> <** Eufrat.> 
2:23:32: Du skal ikke gjøre nogen pakt med dem eller med deres guder. 
2:23:33: De skal ikke bo i ditt land, forat de ikke skal forføre dig til å synde mot mig; for du vilde da tjene deres guder, og det vilde bli en snare for dig. 
2:24:1: Og til Moses sa han: Stig op til Herren, du og Aron, Nadab og Abihu og sytti av Israels eldste, og I skal tilbede på avstand. 
2:24:2: Men Moses alene skal komme nær til Herren, de andre skal ikke gå nær til, og folket skal ikke stige op med ham. 
2:24:3: Så kom Moses og forkynte folket alle Herrens ord og alle lovene; og hele folket svarte med én røst: Alle de ord Herren har talt, vil vi holde oss efter. 
2:24:4: Så skrev Moses op alle Herrens ord, og han stod tidlig op om morgenen og bygget et alter nedenfor fjellet og tolv støtter for de tolv Israels stammer. 
2:24:5: Siden sendte han nogen unge menn av Israels barn dit, og de bar frem brennoffer og ofret slaktoffer av okser til takkoffer for Herren. 
2:24:6: Og Moses tok halvdelen av blodet og helte det ut i skåler, og halvdelen av blodet sprengte han på alteret. 
2:24:7: Så tok han paktens bok og leste den op for folket; og de sa: Alt det Herren har sagt, vil vi gjøre og lyde. 
2:24:8: Da tok Moses blodet og sprengte det på folket; og han sa: Se, dette er paktens blod, den pakt som Herren opretter med eder på alle disse ord. 
2:24:9: Så steg Moses og Aron, Nadab og Abihu og sytti av Israels eldste op. 
2:24:10: Og de så Israels Gud; under hans føtter var det likesom et gulv av gjennemsiktig safirsten og som himmelen selv i klarhet. 
2:24:11: Og han løftet ikke sin hånd mot de ypperste av Israels barn, men de skuet Gud og åt og drakk. 
2:24:12: Og Herren sa til Moses: Stig op til mig på fjellet og bli der! Så vil jeg gi dig stentavler og loven og budet, som jeg har skrevet op for å veilede dem. 
2:24:13: Da gikk Moses avsted med sin tjener Josva; og Moses gikk op på Guds berg. 
2:24:14: Og han sa til de eldste: Bli her til vi kommer tilbake til eder; Aron og Hur er jo hos eder; den som har en rettssak, kan gå til dem! 
2:24:15: Så steg Moses op på fjellet, og skyen skjulte fjellet. 
2:24:16: Og Herrens herlighet hvilte på Sinai berg, og skyen skjulte det i seks dager; den syvende dag kalte han på Moses midt ut av skyen. 
2:24:17: Og Herrens herlighet var å se til for Israels barns øine som en fortærende ild på fjellets topp. 
2:24:18: Og Moses gikk midt inn skyen og steg op på fjellet; og Moses var på fjellet i firti dager og firti netter. 
2:25:1: Og Herren talte til Moses og sa: 
2:25:2: Si til Israels barn at de skal komme med en gave til mig; av hver mann som har hjertelag til det, skal I ta imot gaven til mig. 
2:25:3: Og dette er den gave I skal ta imot av dem: gull og sølv og kobber 
2:25:4: og blå og purpurrød og karmosinrød ull og fint lin og gjetehår 
2:25:5: og rødfarvede værskinn og takasskinn* og akasietre, # <* takas, rimeligvis en slags delfin.> 
2:25:6: olje til lysestaken, krydderier til salvings-oljen og til den velluktende røkelse, 
2:25:7: onyksstener og andre edelstener til å sette på livkjortelen og brystduken. 
2:25:8: Og de skal gjøre mig en helligdom, så vil jeg bo midt iblandt dem. 
2:25:9: Tabernaklet og alt som dertil hører, skal I i alle måter gjøre efter det billede jeg vil vise dig. 
2:25:10: De skal gjøre en ark av akasietre, halvtredje alen lang og halvannen alen bred og halvannen alen høi. 
2:25:11: Den skal du klæ med rent gull, både innvendig og utvendig skal du klæ den med gull; og du skal gjøre en gullkrans på den rundt omkring. 
2:25:12: Du skal støpe fire gullringer og feste dem i de fire føtter på arken, to ringer på den ene side og to på den andre. 
2:25:13: Så skal du gjøre stenger av akasietre og klæ dem med gull. 
2:25:14: Og du skal stikke stengene inn i ringene på sidene av arken, så arken kan bæres på dem. 
2:25:15: Stengene skal bli i ringene på arken, de må aldri tas ut av dem. 
2:25:16: Og i arken skal du legge vidnesbyrdet* som jeg vil gi dig. # <* se 2MO 16, 34.> 
2:25:17: Så skal du gjøre en nådestol* av rent gull, halvtredje alen lang og halvannen alen bred. # <* lokket på arken, som Herren tronet over, og som på den store forsoningsfest blev oversprengt med sonofferets blod.> 
2:25:18: Og du skal gjøre to kjeruber av gull; i drevet arbeid skal du gjøre dem og sette dem ved begge endene av nådestolen. 
2:25:19: Den ene kjerub skal du sette ved den ene ende og den andre kjerub ved den andre ende; i ett med nådestolen skal I gjøre kjerubene, én på hver ende av den. 
2:25:20: Kjerubene skal holde vingene utbredt og opløftet, så de dekker over nådestolen med sine vinger, og deres ansikter skal vende mot hverandre; mot nådestolen skal kjerubene vende sitt ansikt. 
2:25:21: Så skal du sette nådestolen ovenpå arken, og i arken skal du legge vidnesbyrdet, som jeg vil gi dig. 
2:25:22: Og jeg vil komme sammen med dig der; fra nådestolen mellem begge kjerubene som er på vidnesbyrdets ark, vil jeg tale med dig om alt det jeg vil byde dig å si Israels barn. 
2:25:23: Så skal du gjøre et bord av akasietre, to alen langt og én alen bredt og halvannen alen høit. 
2:25:24: Du skal klæ det med rent gull og gjøre en gullkrans på det rundt omkring. 
2:25:25: Og du skal gjøre en list på det av en hånds bredde rundt omkring, og rundt om listen skal du gjøre en gullkrans. 
2:25:26: Så skal du gjøre fire gullringer til det og sette ringene i de fire hjørner på de fire føtter. 
2:25:27: Like ved listen skal ringene sitte, de skal være til å stikke stengene i, så bordet kan bæres. 
2:25:28: Stengene skal du gjøre av akasietre og klæ dem med gull, og bordet skal bæres på dem. 
2:25:29: Så skal du gjøre fatene og skålene som hører til bordet, og kannene og begerne som det skal ofres drikkoffer med; av rent gull skal du gjøre dem. 
2:25:30: Og på bordet skal du alltid legge skuebrød for mitt åsyn. 
2:25:31: Så skal du gjøre en lysestake av rent gull; i drevet arbeid skal lysestaken gjøres; både foten på den og stangen, begerne, knoppene og blomstene skal være i ett med den. 
2:25:32: Seks armer skal gå ut fra lysestakens sider, tre armer fra den ene side, og tre fra den andre. 
2:25:33: Det skal være tre mandelformede beger på den første arm med knopp og blomst, og tre mandelformede beger på den annen arm med knopp og blomst; således skal det være på alle de seks armer som går ut fra lysestaken. 
2:25:34: På selve lysestaken skal det være fire mandelformede beger med knopper og blomster, 
2:25:35: én knopp under de to første armer i ett med den, og én knopp under de to næste armer i ett med den, og én knopp under de to øverste armer i ett med den - én knopp under hvert par av de seks armer som går ut fra lysestaken. 
2:25:36: Både knoppene og armene skal være i ett med den; alt sammen skal være ett drevet arbeid av rent gull. 
2:25:37: Så skal du gjøre syv lamper til lysestaken; og lampene skal settes således op at schmelken faller rett frem for den. 
2:25:38: Lysesaksene og brikkene som hører til, skal være av rent gull. 
2:25:39: En talent* rent gull skal I bruke til lysestaken og alle disse redskaper. # <* omkr. 50 kilogram.> 
2:25:40: Se nu til at du gjør alt efter det billede som blev vist dig på fjellet! 
2:26:1: Tabernaklet skal du gjøre av ti tepper av fint, tvunnet lingarn og blå og purpurrød og karmosinrød ull; du skal gjøre dem med kjeruber på i kunstvevning. 
2:26:2: Hvert teppe skal være åtte og tyve alen langt og fire alen bredt; alle teppene skal holde samme mål. 
2:26:3: Fem av teppene skal festes sammen, det ene til det andre, og likeså de andre fem tepper. 
2:26:4: Og du skal gjøre hemper av blå ull i kanten på det ene teppe, ytterst der hvor sammenfestingen skal være, og likeså i kanten på det ytterste teppe, der hvor den andre sammenfesting skal være. 
2:26:5: Femti hemper skal du gjøre på det ene teppe, og femti hemper skal du gjøre på det teppe som er der hvor den andre sammenfesting skal være; hempene skal være like mot hverandre, den ene mot den andre. 
2:26:6: Og du skal gjøre femti gullkroker og feste teppene til hverandre med krokene, så tabernaklet blir et sammenhengende telt. 
2:26:7: Så skal du gjøre tepper av gjetehår til et dekke over tabernaklet; elleve sådanne tepper skal du gjøre. 
2:26:8: Hvert teppe skal være tretti alen langt og fire alen bredt; alle de elleve tepper skal holde samme mål. 
2:26:9: Og du skal feste fem av teppene sammen for sig, og seks for sig, og du skal legge det sjette teppe dobbelt på fremsiden av dekket. 
2:26:10: Du skal gjøre femti hemper i kanten på det ene teppe, ytterst der hvor sammenfestingen skal være, og likeså femti hemper i kanten på det andre teppe, der hvor sammenfestingen skal være. 
2:26:11: Og du skal gjøre femti kobberkroker og hekte krokene inn i hempene, og du skal feste teppene sammen, så de blir ett dekke. 
2:26:12: Men det overskytende av dekketeppene - det halve teppe som er tilovers - skal henge ned på baksiden av tabernaklet. 
2:26:13: Og den alen på hver av sidene som dekketeppene er for lange, skal henge ned på begge sider av tabernaklet for å dekke det. 
2:26:14: Over dekket skal du gjøre et varetak av rødfarvede værskinn og ovenpå det et varetak av takasskinn. 
2:26:15: Plankene til tabernaklet skal du gjøre av akasietre; de skal reises på ende. 
2:26:16: Hver planke skal være ti alen lang og halvannen alen bred. 
2:26:17: På hver planke skal det være to tapper, med en tverrlist imellem; således skal du gjøre med alle plankene til tabernaklet. 
2:26:18: Og av plankene som du gjør til tabernaklet, skal du reise tyve planker på den side som vender mot syd; 
2:26:19: og firti fotstykker av sølv skal du gjøre til å sette under de tyve planker, to fotstykker under hver planke til å feste begge tappene i. 
2:26:20: Likeså skal du til tabernaklets andre side, den som vender mot nord, gjøre tyve planker, 
2:26:21: og til dem firti fotstykker av sølv, to fotstykker under hver planke. 
2:26:22: Til baksiden av tabernaklet, mot vest, skal du gjøre seks planker. 
2:26:23: Og to planker skal du gjøre til tabernaklets hjørner på baksiden; 
2:26:24: de skal være dobbelte nedenfra og likeledes begge være dobbelte helt op, til den første ring; således skal det være med dem begge; de skal stå i hver sitt hjørne. 
2:26:25: Således blir det åtte planker med sine fotstykker av sølv - seksten fotstykker, to under hver planke. 
2:26:26: Så skal du gjøre tverrstenger av akasietre, fem til plankene på den ene side av tabernaklet, 
2:26:27: og fem til plankene på den andre side, og fem til plankene på baksiden av tabernaklet, mot vest. 
2:26:28: Og den mellemste tverrstang, den som er midt på plankeveggen, skal gå tvert over, fra den ene ende til den andre. 
2:26:29: Plankene skal du klæ med gull, og ringene på dem, som tverrstengene skal stikkes i, skal du gjøre helt av gull; tverrstengene skal du også klæ med gull. 
2:26:30: Du skal reise tabernaklet på den rette måte, således som det blev vist dig på fjellet. 
2:26:31: Så skal du gjøre et forheng av blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn; det skal gjøres i kunstvevning med kjeruber på. 
2:26:32: Og du skal henge det på fire gullklædde stolper av akasietre som det er gullhaker på, og som står på fire fotstykker av sølv. 
2:26:33: Og du skal henge forhenget under krokene* og føre vidnesbyrdets ark dit og sette den innenfor forhenget; og forhenget skal være for eder en skillevegg mellem det Hellige og det Aller-helligste. # <* 2MO 26, 6.> 
2:26:34: Og du skal sette nådestolen ovenpå vidnesbyrdets ark i det Aller-helligste. 
2:26:35: Du skal sette bordet utenfor forhenget, og lysestaken midt imot bordet ved den søndre side av tabernaklet; bordet skal du sette ved den nordre side. 
2:26:36: Til teltdøren skal du gjøre et teppe av blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn med utsydd arbeid. 
2:26:37: Og du skal gjøre fem stolper av akasietre til teppet og klæ dem med gull; hakene på dem skal være av gull, og du skal støpe fem fotstykker av kobber til dem. 
2:27:1: Du skal gjøre et alter av akasietre, fem alen langt og fem alen bredt; firkantet skal alteret være og tre alen høit. 
2:27:2: Og du skal gjøre et horn på hvert av dets fire hjørner, og hornene skal være i ett med alteret; og du skal klæ det med kobber. 
2:27:3: Så skal du gjøre bøttene som asken skal bæres bort med, og ildskuffene og skålene til å sprenge blod med, og kjøttgaflene og fyrfatene; alle disse redskaper skal du gjøre av kobber. 
2:27:4: Til alteret skal du gjøre et gitter, et nettverk av kobber, og på nettet skal du gjøre fire kobberringer, én på hvert hjørne. 
2:27:5: Og du skal sette det under alterets avsats nedentil, så det rekker til midt på alteret. 
2:27:6: Så skal du gjøre stenger til alteret, stenger av akasietre, og du skal klæ dem med kobber. 
2:27:7: Stengene skal stikkes inn i ringene, så de er på begge sider av alteret når det blir båret. 
2:27:8: Alteret skal du gjøre av bord; det skal være hult; som det blev vist dig på fjellet, således skal det gjøres. 
2:27:9: Så skal du gjøre en forgård til tabernaklet. På den side som vender mot syd, skal det være et omheng om forgården av fint, tvunnet lingarn, hundre alen langt på den ene side, 
2:27:10: og tyve stolper dertil og til stolpene tyve fotstykker av kobber; hakene på stolpene og stengene til dem skal være av sølv. 
2:27:11: Likeså skal det på den nordre side efter lengden være et omheng, hundre alen langt, og tyve stolper dertil og til stolpene tyve fotstykker av kobber; hakene på stolpene og stengene til dem skal være av sølv. 
2:27:12: På den ene tverrside av forgården, mot vest, skal det være et omheng på femti alen, ti stolper dertil og til stolpene ti fotstykker. 
2:27:13: På den andre tverrside, som vender frem mot øst, skal forgården også holde femti alen i bredden. 
2:27:14: Således skal det være femten alen omheng på den ene kant med tre stolper og tre fotstykker til dem, 
2:27:15: og på den andre kant femten alen omheng med tre stolper og tre fotstykker til dem. 
2:27:16: Og i porten til forgården skal det være et forheng, tyve alen langt, av blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn med utsydd arbeid, med fire stolper og fire fotstykker til dem. 
2:27:17: Alle stolpene rundt omkring forgården skal være sammenbundet med stenger av sølv; hakene på dem skal være av sølv og fotstykkene under dem av kobber. 
2:27:18: Lengden på forgården skal være hundre alen, og bredden overalt femti alen; og omhenget skal være fem alen høit av fint, tvunnet lingarn, og fotstykkene til det skal være av kobber. 
2:27:19: Alle arbeidsredskaper i tabernaklet og alle pluggene, både til tabernaklet og til forgården, skal være av kobber. 
2:27:20: Og du skal byde Israels barn at de skal la dig få ren olje av støtte oliven til lysestaken, så lampene kan settes op til enhver tid. 
2:27:21: I sammenkomstens telt, utenfor forhenget som henger foran vidnesbyrdet, skal Aron og hans sønner holde lampene i stand fra aften til morgen for Herrens åsyn; det skal være en evig gyldig vedtekt for Israels barn, fra slekt til slekt. 
2:28:1: Så skal du kalle din bror Aron ut av Israels barns mengde, og la ham trede frem til dig, både han og hans sønner, og sette dem til prester for mig - Aron og Nadab og Abihu og Eleasar og Itamar, Arons sønner. 
2:28:2: Og du skal gjøre hellige klær for Aron, din bror, til ære og til pryd. 
2:28:3: Og du skal tale til alle kunstforstandige menn, alle som jeg har fylt med kunstnerånd; og de skal gjøre de klær som Aron skal bære, forat han kan helliges til å tjene mig som prest. 
2:28:4: Dette er de klær som de skal gjøre: en brystduk og en livkjortel og en overkjortel og en ternet underkjortel, en hue og et belte - hellige klær skal de gjøre til Aron, din bror, og hans sønner, så han kan tjene mig som prest. 
2:28:5: De skal ta gullet og den blå og den purpurrøde og den karmosinrøde ull og det fine lingarn, 
2:28:6: og de skal gjøre livkjortelen av gull, blå, purpurrød og karmosinrød ull og fint, tvunnet lingarn i kunstvevning. 
2:28:7: Den skal ha to skulderstykker som kan festes til hverandre, ett i hver ende; det skal være til å hekte den sammen med. 
2:28:8: Og beltet som skal sitte på den og holde den sammen, skal være av samme slags vevning og i ett stykke med den, av gull og blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn. 
2:28:9: Og du skal ta to onyksstener, og på dem skal du inngrave navnene på Israels barn, 
2:28:10: seks navn på den ene sten og de andre seks navn på den andre sten efter alderen. 
2:28:11: Som en arbeider i sten og skjærer ut et signet, således skal du la navnene på Israels barn skjære ut på begge stenene; du skal sette dem inn i flettverk av gull. 
2:28:12: Og du skal sette begge stenene på livkjortelens skulderstykker, forat de skal minne om Israels barn; og når Aron står for Herrens åsyn, skal han bære deres navn på begge sine skuldrer for å minne om dem. 
2:28:13: Så skal du gjøre flettverk av gull 
2:28:14: og to kjeder av rent gull; de skal være slynget, således som de slynger snorer; disse slyngede kjeder skal du feste til flettverkene. 
2:28:15: Så skal du gjøre en doms-brystduk* i kunstvevning, i samme slags vevning som livkjortelen; av gull og blå, purpurrød og karmosinrød ull og fint, tvunnet lingarn skal du gjøre den. # <* se 2MO 28, 30.> 
2:28:16: Den skal være firkantet og dobbelt lagt, et spann lang og et spann bred. 
2:28:17: Og du skal sette på den fire rader med innfattede stener. I en rad skal det være en karneol, en topas og en smaragd; det er den første rad. 
2:28:18: I den annen rad skal det være en karfunkel, en safir og en diamant, 
2:28:19: og i den tredje rad en hyasint, en agat og en ametyst, 
2:28:20: og i den fjerde rad en krysolitt og en onyks og en jaspis. De skal være innfattet i flettverk av gull. 
2:28:21: Stenene skal være tolv i tallet, efter navnene på Israels sønner, en for hvert navn; på hver sten skal navnet på en av de tolv stammer være innskåret likesom på et signet. 
2:28:22: Til brystduken skal du også gjøre kjeder av rent gull, slynget som snorer. 
2:28:23: Likeså skal du gjøre to gullringer til brystduken, og dem skal du sette på hver sitt hjørne av den. 
2:28:24: Og du skal feste de to slyngede gullkjeder i de to ringer på hjørnene av brystduken. 
2:28:25: Og de to andre ender av de to slyngede kjeder skal du feste i de to flettverk* og så feste dem til livkjortelens skulderstykker på fremsiden. # <* 2MO 28, 11. 13. 14.> 
2:28:26: Så skal du gjøre to andre gullringer og sette dem på de to andre hjørner av brystduken på den indre side av den, den som vender inn mot livkjortelen. 
2:28:27: Og du skal gjøre ennu to gullringer og sette dem på livkjortelens to skulderstykker nedentil på fremsiden, der hvor den festes sammen, ovenfor livkjortelens belte. 
2:28:28: Så skal ringene på brystduken bindes til ringene på livkjortelen med en snor av blå ull, så brystduken kommer til å sitte ovenfor livkjortelens belte og ikke kan skilles fra livkjortelen. 
2:28:29: Og når Aron går inn i helligdommen, skal han bære navnene på Israels barn i doms-brystduken på sitt hjerte for alltid å minne om dem for Herrens åsyn. 
2:28:30: I doms-brystduken skal du legge urim og tummim*; de skal ligge ved Arons hjerte når han går inn for Herrens åsyn, så Aron alltid skal bære Israels barns dom på sitt hjerte for Herrens åsyn. # <* Ved urim og tummim (schmelk og fullkommenhet) åpenbarte Herren ypperstepresten og ved ham folket sin vilje og gav oplysning i tvilsomme tilfelle.> 
2:28:31: Overkjortelen* som hører til livkjortelen, skal du gjøre helt igjennem av blå ull. # <* En kjortel til å bære over underkjortelen, men under livkjortelen.> 
2:28:32: Midt på den skal det være en åpning for hodet, og rundt omkring åpningen skal det være en vevet bord - likesom åpningen på en brynje - forat den ikke skal revne. 
2:28:33: Og rundt omkring på kanten av den nedentil skal du sette granatepler av blå, purpurrød og karmosinrød ull og mellem dem gullbjeller rundt omkring, 
2:28:34: først en gullbjelle og et granateple og så atter en gullbjelle og et granateple, og således rundt omkring hele kanten av overkjortelen nedentil. 
2:28:35: Denne overkjortel skal Aron ha på hver gang han gjør tjeneste, så lyden av den kan høres når han går inn i helligdommen for Herrens åsyn, og når han går ut - forat han ikke skal dø. 
2:28:36: Så skal du gjøre en plate av rent gull og skjære ut på den således som en skjærer ut et signet: Helliget Herren. 
2:28:37: Du skal feste den til en snor av blå ull, og den skal sitte på huen; på fremsiden av huen skal den sitte. 
2:28:38: Over Arons panne skal den sitte, så Aron kan bære den synd som henger ved de hellige ting Israels barn vier til Herren, alle de hellige gaver de bærer frem; den skal alltid sitte over hans panne, forat de kan finne velbehag for Herrens åsyn. 
2:28:39: Så skal du veve en ternet underkjortel av fint lin og gjøre en hue av fint lin, og et belte med utsydd arbeid. 
2:28:40: Og til Arons sønner skal du gjøre underkjortler, og du skal gjøre dem belter, og du skal gjøre dem høie huer, til ære og til pryd. 
2:28:41: Med dette skal du klæ Aron, din bror, og likeså hans sønner, og du skal salve dem og fylle deres hender* og hellige dem, så de kan tjene mig som prester. # <* d.e. innvie dem ved å overgi visse offerstykker i deres hender, se 2MO 29, 22 fg.> 
2:28:42: Så skal du gjøre dem benklær av lerret til å skjule deres blusel; fra lendene ned til lårene skal de nå. 
2:28:43: Og dem skal Aron og hans sønner ha på når de går inn i sammenkomstens telt, eller når de treder frem til alteret for å gjøre tjeneste i helligdommen, så de ikke skal føre skyld over sig og dø. Dette skal være en evig lov for ham og hans ætt efter ham. 
2:29:1: Og dette er hvad du skal gjøre med dem for å hellige dem til å tjene mig som prester: Ta en ung okse og to værer som er uten lyte, 
2:29:2: Og usyret brød Og usyrede kaker med olje i, og usyrede, tynne brødleiver, smurt med olje; av fint hvetemel skal du lage dem. 
2:29:3: Og du skal legge dem i en kurv og bære dem frem i kurven, og lede frem oksen og de to værer. 
2:29:4: Så skal du føre Aron og hans sønner frem til inngangen til sammenkomstens telt, og du skal tvette dem med vann. 
2:29:5: Og du skal ta klærne og klæ Aron i underkjortelen og overkjortelen som hører til livkjortelen, og livkjortelen og brystduken og binde livkjortelens belte om ham 
2:29:6: Og sette huen på hans hode Og feste det hellige hodesmykke på huen. 
2:29:7: Og du skal ta salvings-oljen og helle ut over hans hode og salve ham. 
2:29:8: Så skal du føre hans sønner frem og klæ dem i underkjortler 
2:29:9: og spenne beltet om dem - både om Aron og hans sønner - og binde de høie huer på dem, og de skal ha prestedømmet som en evig rett. Så skal du fylle Arons hånd og hans sønners hånd. 
2:29:10: Du skal lede oksen frem foran sammenkomstens telt, og Aron og hans sønner skal legge sine hender på oksens hode. 
2:29:11: Og du skal slakte oksen for Herrens åsyn ved inngangen til sammenkomstens telt. 
2:29:12: Og du skal ta av oksens blod og stryke på alterets horn med din finger, Og resten av blodet skal du helle ut ved alterets fot. 
2:29:13: Og du skal ta alt fettet som dekker innvollene, og den store leverlapp og begge nyrene med fettet som er på dem, og du skal brenne det på alteret. 
2:29:14: Men kjøttet av oksen og dens hud og dens skarn skal du brenne op med ild utenfor leiren - det er et syndoffer. 
2:29:15: Så skal du ta den ene vær, og Aron og hans sønner skal legge sine hender på værens hode. 
2:29:16: Og du skal slakte væren og ta dens blod og sprenge rundt om på alteret. 
2:29:17: Men væren selv skal du dele op i sine stykker; og du skal tvette innvollene og føttene og legge dem ovenpå stykkene og hodet. 
2:29:18: Og du skal brenne hele væren på alteret - det er et brennoffer for Herren, en velbehagelig duft; det er et ildoffer for Herren. 
2:29:19: Så skal du ta den andre vær, og Aron og hans sønner skal legge sine hender på værens hode. 
2:29:20: Og du skal slakte væren og ta av dens blod og stryke på den høire ørelapp på Aron og hans sønner og på tommelfingeren på deres høire hånd og på stortåen på deres høire fot, og du skal sprenge resten av blodet rundt om på alteret. 
2:29:21: Og du skal ta av blodet som er på alteret, og av salvings-oljen og sprenge på Aron og på hans klær, og likeledes på hans sønner og på deres klær, så blir han hellig, han selv og hans klær, og likeledes hans sønner og deres klær. 
2:29:22: Og du skal ta det feteste på væren, bade halen og fettet som dekker innvollene, og den store leverlapp og begge nyrene og fettet på dem og det høire lår; for dette er innvielses-væren. 
2:29:23: Så skal du ta et rundt brød og en oljekake og en tynn brødleiv av kurven med de usyrede brød som står for Herrens åsyn. 
2:29:24: Alt dette skal du legge i Arons hender og i hans sønners hender, og du skal svinge det for Herrens åsyn. 
2:29:25: Så skal du ta det av deres hånd og brenne det på alteret sammen med brennofferet til en velbehagelig duft for Herrens åsyn; det er et ildoffer for Herren. 
2:29:26: Derefter skal du ta brystet av Arons innvielses-vær og svinge det for Herrens åsyn; det skal være din del. 
2:29:27: Og du skal hellige svingebrystet og løftelåret - det som svinges og det som løftes av Arons og hans sønners innvielses-vær. 
2:29:28: Det skal være en rettighet for Aron og hans sønner hos Israels barn til evig tid; for det er en gave, en gave som Israels barn skal gi av sine takkoffer, en gave fra dem til Herren. 
2:29:29: De hellige klær som Aron har, skal hans sønner ha efter ham; dem skal de ha på når de salves og vies til prester. 
2:29:30: I syv dager skal den av hans sønner som blir prest i hans sted, bære disse klær - han som skal gå inn i sammenkomstens telt for å gjøre tjeneste i helligdommen. 
2:29:31: Så skal du ta innvielses-væren og koke kjøttet på et hellig sted. 
2:29:32: Og Aron og hans sønner skal ete kjøttet av væren og brødet som er i kurven, ved inngangen til sammenkomstens telt. 
2:29:33: De skal ete dette soningsoffer, det som bæres frem når de vies til prester og helliges; men en uinnvidd skal ikke ete av det; for det er hellig. 
2:29:34: Men dersom det blir noget tilovers av innvielses-kjøttet eller av brødet til om morgenen, da skal du brenne op det som er tilovers; det skal ikke etes, for det er hellig. 
2:29:35: Således skal du gjøre med Aron og hans sønner, i ett og alt således som jeg har befalt dig. Syv dager skal deres innvielse vare. 
2:29:36: Du skal hver dag ofre en okse som syndoffer til soning, og du skal rense alteret fra synd idet du gjør soning for det, og du skal salve det for å hellige det. 
2:29:37: I syv dager skal du gjøre soning for alteret og hellige det, og da skal alteret være høihellig; hver den som rører ved alteret, blir hellig. 
2:29:38: Dette er det du alltid skal ofre på alteret: hver dag to årsgamle lam. 
2:29:39: Det ene lam skal du ofre om morgenen, og det andre lam skal du ofre mellem de to aftenstunder, 
2:29:40: og til det første lam tiendedelen av en efa* fint mel, blandet med fjerdedelen av en hin olje av støtte oliven, og til drikkoffer fjerdedelen av en hin vin. # <* En efa var et mål for tørre varer, likesom en hin for flytende.> 
2:29:41: Det andre lam skal du ofre mellem de to aftenstunder; med det samme matoffer som om morgenen og med det samme drikkoffer skal du ofre det, til en velbehagelig duft, et ildoffer for Herren. 
2:29:42: Dette skal være et stadig brennoffer hos eders efterkommere, og det skal bæres frem for Herrens åsyn ved inngangen til sammenkomstens telt, hvor jeg vil komme sammen med eder for å tale til eder. 
2:29:43: Der vil jeg komme sammen med Israels barn, og det skal helliges ved min herlighet. 
2:29:44: Og jeg vil hellige sammenkomstens telt og alteret; og Aron og hans sønner vil jeg hellige til å tjene mig som prester. 
2:29:45: Og jeg vil bo midt iblandt Israels barn, og jeg vil være deres Gud. 
2:29:46: Og de skal kjenne at jeg er Herren deres Gud, som førte dem ut av Egyptens land for å bo midt iblandt dem. Jeg er Herren deres Gud. 
2:30:1: Så skal du gjøre et alter til å brenne røkelse på; av akasietre skal du gjøre det. 
2:30:2: Det skal være en alen langt og en alen bredt, firkantet, og to alen høit; hornene på det skal være i ett med det. 
2:30:3: Du skal klæ det med rent gull både ovenpå og på sidene rundt omkring og på hornene; og du skal gjøre en gullkrans på det rundt omkring. 
2:30:4: Og du skal gjøre to gullringer til det og sette dem nedenfor kransen, på begge sider av det; du skal sette to på hver side; de skal være til å stikke stenger i, så alteret kan bæres på dem. 
2:30:5: Stengene skal du gjøre av akasietre og klæ dem med gull. 
2:30:6: Og du skal sette alteret foran forhenget som henger foran vidnesbyrdets ark, foran nådestolen som er ovenover vidnesbyrdet, hvor jeg vil komme sammen med dig. 
2:30:7: Og Aron skal brenne velluktende røkelse på det; hver morgen når han steller lampene, skal han brenne den. 
2:30:8: Og når han setter lampene op mellem de to aftenstunder, skal han også brenne den; det skal være et stadig røkoffer for Herrens åsyn hos eders efterkommere. 
2:30:9: I skal ikke ofre fremmed røkelse på alteret, heller ikke brennoffer eller matoffer; og I skal ikke helle ut drikkoffer på det. 
2:30:10: Én gang om året skal Aron gjøre soning for dets horn; med blodet av sonings-syndofferet skal han én gang om året gjøre soning for det, slekt efter slekt; det er høihellig for Herren. 
2:30:11: Og Herren talte til Moses og sa: 
2:30:12: Når du holder manntall over Israels barn, da skal alle de som kommer med i manntallet, gi Herren løsepenger for sitt liv når de telles, så det ikke skal komme nogen ulykke over dem fordi de telles. 
2:30:13: Dette skal enhver gi som kommer med i manntallet: en halv sekel efter helligdommens vekt - sekelen er tyve gera - denne halve sekel er en gave til Herren. 
2:30:14: Enhver som kommer med i manntallet fra tyveårsalderen og opover, skal gi denne gave til Herren. 
2:30:15: Den rike skal ikke gi mere og den fattige ikke mindre enn en halv sekel når I gir gaven til Herren som løsepenger for eders liv. 
2:30:16: Og du skal ta imot løsepengene av Israels barn og bruke dem til arbeidet ved sammenkomstens telt, så det kan være til å minne om Israels barn for Herrens åsyn, til løsepenger for eders liv. 
2:30:17: Og Herren talte til Moses og sa: 
2:30:18: Du skal gjøre et kar av kobber med fotstykke av kobber til å tvette sig i; og du skal sette det mellem sammenkomstens telt og alteret og ha vann i det. 
2:30:19: Og Aron og hans sønner skal tvette sine hender og sine føtter i det. 
2:30:20: Når de går inn i sammenkomstens telt, skal de tvette sig med vann forat de ikke skal dø; likeså når de treder frem til alteret for å gjøre tjeneste og brenne ildoffer for Herren. 
2:30:21: De skal tvette sine hender og sine føtter forat de ikke skal dø; dette skal være en evig lov for dem, for ham og hans ætt, slekt efter slekt. 
2:30:22: Og Herren talte til Moses og sa: 
2:30:23: Ta dig krydderier av ypperste slag, av den edleste myrra fem hundre sekel og av krydder-kanel halvt så meget, to hundre og femti sekel, og av krydder-kalmus to hundre og femti sekel 
2:30:24: og av kassia fem hundre sekel efter helligdommens vekt og en hin olivenolje. 
2:30:25: Av det skal du lage en hellig salvings-olje, en kryddersalve, slik som det gjøres av dem som lager salver; en hellig salvings-olje skal det være, 
2:30:26: og med den skal du salve sammenkomstens telt og vidnesbyrdets ark 
2:30:27: og bordet med alt som dertil hører, og lysestaken med det som hører til den, og røkoffer-alteret 
2:30:28: og brennoffer-alteret med alt som dertil hører, og karet med sitt fotstykke. 
2:30:29: Og du skal hellige dem, så de blir høihellige; hver den som rører ved dem, skal være hellig. 
2:30:30: Og du skal salve Aron og hans sønner, og du skal hellige dem til å tjene mig som prester. 
2:30:31: Og du skal tale til Israels barn og si: Dette skal være min hellige salvings-olje hos eder, slekt efter slekt. 
2:30:32: På et annet menneskes legeme må den ikke komme, og I skal ikke lage nogen annen olje således blandet som den; hellig er den, hellig skal den være for eder. 
2:30:33: Den som lager maken til denne kryddersalve, eller som bruker den på en uinnvidd, han skal utryddes av sitt folk. 
2:30:34: Og Herren sa til Moses: Ta dig røkelses-krydderier, stakte og sjønegl og galban - disse tre slags krydderier - og ren virak; det skal være like meget av hvert. 
2:30:35: Av det skal du gjøre røkelse, en krydderblanding slik som det gjøres av dem som lager salve, saltet, ren, hellig. 
2:30:36: Og du skal støte noget av det smått og legge det foran vidnesbyrdet i sammenkomstens telt, hvor jeg vil komme sammen med dig; høihellig skal det være for eder. 
2:30:37: Og ingen annen røkelse skal I lage eder således blandet som denne; hellig for Herren skal den være dig. 
2:30:38: Den som lager maken til den for å røke med, han skal utryddes av sitt folk. 
2:31:1: Og Herren talte til Moses og sa: 
2:31:2: Se, jeg har kalt Besalel, sønn av Uri, Hurs sønn, av Juda stamme, 
2:31:3: og jeg har fylt ham med Guds Ånd, med visdom og med forstand og med kunnskap og med dyktighet til alle slags arbeid, 
2:31:4: til å uttenke kunstverker, til å arbeide i gull og i sølv og i kobber 
2:31:5: og til å slipe stener til innfatning og skjære ut i tre, til å utføre alle slags arbeid. 
2:31:6: Og se, jeg har gitt ham til medhjelper Oholiab, Akisamaks sønn, av Dans stamme, og alle kunstforstandige menn har jeg gitt visdom i hjertet, så de kan gjøre alt det jeg har sagt dig: 
2:31:7: sammenkomstens telt og vidnesbyrdets ark og nådestolen ovenover den og alt som hører teltet til, 
2:31:8: og bordet med det som dertil hører, og lysestaken av rent gull med alt som hører til den, og røkofferalteret 
2:31:9: og brennoffer-alteret med alt som dertil hører, og tvettekaret med sitt fotstykke, 
2:31:10: og embedsklærne og de hellige klær til Aron, presten, og presteklærne til hans sønner, 
2:31:11: og salvings-oljen og røkelsen av velluktende krydderier til helligdommen. De skal i ett og alt gjøre således som jeg har sagt dig. 
2:31:12: Og Herren sa til Moses: 
2:31:13: Tal til Israels barn og si: Mine sabbater skal I holde; for det er et tegn mellem mig og eder fra slekt til slekt, forat I skal vite at jeg er Herren som helliger eder. 
2:31:14: Derfor skal I holde sabbaten; den skal være eder hellig; den som vanhelliger den, skal visselig late livet; hver den som gjør noget arbeid på den dag, han skal utryddes av sitt folk. 
2:31:15: I seks dager skal der arbeides; men på den syvende dag skal det være høihellig sabbat, hellig for Herren; hver den som gjør noget arbeid på sabbatsdagen, skal visselig late livet. 
2:31:16: Og Israels barn skal ta vare på sabbaten, så de holder den slekt efter slekt, en evig pakt. 
2:31:17: Den skal være et evig tegn mellem mig og Israels barn; for i seks dager gjorde Herren himmelen og jorden, og på den syvende dag hvilte han og holdt sig i ro. 
2:31:18: Og da han hadde talt ut med Moses på Sinai berg, gav han ham vidnesbyrdets to tavler, tavler av sten, skrevet med Guds finger. 
2:32:1: Men da folket så at Moses drygde med å komme ned fra fjellet, samlet folket sig om Aron og sa til ham: Kom, gjør oss en gud som kan dra foran oss! For denne Moses, han som førte oss op fra Egyptens land - vi vet ikke hvad det er blitt av ham. 
2:32:2: Da sa Aron til dem: Ta gullringene som eders hustruer, eders sønner og eders døtre har i sine ører, og kom til mig med dem! 
2:32:3: Da tok alt folket gullringene ut av sine ører og kom til Aron med dem; 
2:32:4: og han tok imot gullet og støpte det om og laget det med meiselen til en kalv. Da sa de: Dette er din Gud, Israel, som førte dig op fra Egyptens land. 
2:32:5: Da Aron så dette, bygget han et alter for den og lot utrope: Imorgen er det høitid for Herren! 
2:32:6: Dagen efter stod de tidlig op og ofret brennoffer og bar frem takkoffer; og folket satte sig ned for å ete og drikke og stod op for å leke. 
2:32:7: Da sa Herren til Moses: Skynd dig og stig ned! Ditt folk, som du har ført op fra Egyptens land, har fordervet sin vei. 
2:32:8: De er hastig veket av fra den vei jeg bød dem å vandre; de har gjort sig en støpt kalv; den har de tilbedt og ofret til og sagt: Dette er din Gud, Israel, som førte dig op fra Egyptens land. 
2:32:9: Og Herren sa til Moses: Jeg har lagt merke til dette folk og sett at det er et hårdnakket folk. 
2:32:10: La nu mig få råde, så min vrede kan optendes mot dem, og jeg kan ødelegge dem; så vil jeg gjøre dig til et stort folk. 
2:32:11: Men Moses bønnfalt Herren sin Gud og sa: Herre! Hvorfor skal din vrede optendes mot ditt folk, som du har ført ut av Egyptens land med stor kraft og med veldig hånd? 
2:32:12: Hvorfor skal egypterne si: Til ulykke har han ført dem ut; han vilde slå dem ihjel i fjellene og utrydde dem av jorden? Vend om fra din brennende vrede og angre det onde du har tenkt å gjøre mot ditt folk! 
2:32:13: Kom i hu dine tjenere Abraham, Isak og Israel, til hvem du har sagt og svoret ved dig selv: Jeg vil gjøre eders ætt tallrik som stjernene på himmelen, og hele dette land som jeg har talt om, vil jeg gi eders ætt, og de skal eie det til evig tid. 
2:32:14: Så angret Herren det onde han hadde talt om å gjøre mot sitt folk. 
2:32:15: Og Moses vendte sig og gikk ned fra fjellet med vidnesbyrdets to tavler i sin hånd, og på begge sider av tavlene var der skrevet; både på forsiden og baksiden var der skrevet. 
2:32:16: Og tavlene var Guds arbeid, og skriften var Guds skrift, som var inngravd på tavlene. 
2:32:17: Da Josva hørte hvorledes folket støiet og skrek, sa han til Moses: Det lyder krigsrop i leiren. 
2:32:18: Men han svarte: Det lyder ikke som seiersrop og ikke som skrik over mannefall; det er lyd av sang jeg hører. 
2:32:19: Og da Moses kom nær til leiren, så han kalven og dansen; da optendtes hans vrede; han kastet tavlene fra sig og slo dem i stykker ved foten av fjellet. 
2:32:20: Og han tok kalven som de hadde gjort, og kastet den på ilden og knuste den til den blev til støv, og støvet strødde han ovenpå vannet og gav Israels barn det å drikke. 
2:32:21: Så sa Moses til Aron: Hvad har dette folk gjort dig, siden du har ført så stor en synd over det? 
2:32:22: Aron svarte: La ikke din vrede optendes, herre! Du vet selv at dette folk ligger i det onde; 
2:32:23: de sa til mig: Gjør oss en gud som kan dra foran oss! For denne Moses, han som førte oss op fra Egyptens land - vi vet ikke hvad det er blitt av ham. 
2:32:24: Da sa jeg til dem: Den som har gullsmykker på sig, ta dem av sig! Så gav de mig dem, og jeg kastet dem på ilden, og således blev denne kalv til. 
2:32:25: Da Moses så at folket var ustyrlig - for Aron hadde sloppet det løs til spott for deres motstandere - 
2:32:26: så stilte han sig i porten til leiren og sa: Hver den som hører Herren til, han komme hit til mig! Da samlet alle Levis barn sig om ham. 
2:32:27: Og han sa til dem: Så sier Herren, Israels Gud: Hver binde sitt sverd ved sin lend! Gå så frem og tilbake fra port til port i leiren og slå ihjel hver sin bror og hver sin venn og hver sin frende! 
2:32:28: Og Levis barn gjorde som Moses sa; og den dag falt det av folket omkring tre tusen mann. 
2:32:29: For Moses sa: Innvi eder idag til prester for Herren, om det så er med blodet av sønn eller bror; så skal han idag gi eder sin velsignelse. 
2:32:30: Dagen efter sa Moses til folket: I har gjort en stor synd. Nu vil jeg stige op til Herren; kanskje jeg kunde gjøre soning for eders synd. 
2:32:31: Så vendte Moses tilbake til Herren og sa: Akk, dette folk har gjort en stor synd; de har gjort sig en gud av gull. 
2:32:32: Å, om du vilde forlate dem deres synd! Men hvis ikke, da slett mig ut av din bok som du har skrevet! 
2:32:33: Da sa Herren til Moses: Hver den som har syndet mot mig, ham vil jeg slette ut av min bok. 
2:32:34: Så gå nu og før folket dit jeg har sagt dig! Se, min engel skal gå foran dig, men på min hjemsøkelses dag vil jeg hjemsøke dem for deres synd. 
2:32:35: Således slo Herren folket fordi de hadde gjort kalven - den som Aron hadde laget. 
2:33:1: Og Herren talte til Moses: Dra nu bort herfra, både du og det folk som du har ført op fra Egyptens land, til det land jeg tilsvor Abraham, Isak og Jakob, da jeg sa: Din ætt vil jeg gi det; 
2:33:2: og jeg vil sende en engel foran dig og drive ut kana'anittene, amorittene og hetittene og ferisittene og hevittene og jebusittene. 
2:33:3: Dra op til et land som flyter med melk og honning; jeg vil ikke selv dra op med dig, fordi du er et hårdnakket folk; jeg vilde ellers komme til å ødelegge dig på veien. 
2:33:4: Da folket hørte denne hårde tale, sørget de, og ingen tok sine smykker på sig. 
2:33:5: For Herren sa til Moses: Si til Israels barn: I er et hårdnakket folk; drog jeg endog bare et øieblikk op med dig, så måtte jeg ødelegge dig; men legg nu dine smykker av dig, så jeg kan vite hvad jeg skal gjøre med dig. 
2:33:6: Da tok Israels barn sine smykker av og bar dem ikke mere, efterat de hadde draget fra fjellet Horeb. 
2:33:7: Men Moses tok et telt og slo det op utenfor leiren et godt stykke fra; han kalte det sammenkomstens telt, og enhver som søkte Herren, gikk ut til sammenkomstens telt utenfor leiren. 
2:33:8: Og hver gang Moses gikk ut til teltet, reiste hele folket sig, og de stod hver i døren til sitt telt og så efter Moses, til han var kommet inn i teltet. 
2:33:9: Og når Moses var kommet inn i teltet, da senket skystøtten sig og stod i døren til teltet, og han* talte med Moses. # <* Herren.> 
2:33:10: Og alt folket så skystøtten stå i døren til teltet, og alt folket reiste sig og bøide sig hver i døren til sitt telt. 
2:33:11: Og Herren talte til Moses åsyn til åsyn, likesom en mann taler med sin næste. Så vendte han tilbake til leiren, men hans tjener Josva, Nuns sønn, en ung mann, vek ikke fra teltet. 
2:33:12: Og Moses sa til Herren: Se, du sier til mig: Før dette folk op! Men du har ikke latt mig vite hvem du vil sende med mig, enda du selv har sagt: Jeg kjenner dig ved navn, og du har funnet nåde for mine øine. 
2:33:13: Dersom jeg nu har funnet nåde for dine øine, så la mig se din vei, så jeg kan kjenne dig og finne nåde for dine øine, og kom i hu at dette folk er ditt folk! 
2:33:14: Da sa han: Mitt åsyn* skal gå med, og jeg vil føre dig til hvile. # <* d.e. jeg selv.> 
2:33:15: Men han sa til ham: Dersom ditt åsyn ikke går med, da la oss ikke dra op herfra! 
2:33:16: Hvorav kan jeg da vite at jeg har funnet nåde for dine øine, jeg og ditt folk, medmindre du går med oss, så jeg og ditt folk blir æret fremfor alle folkeslag på jorden? 
2:33:17: Da sa Herren til Moses: Også det du nu ber om, vil jeg gjøre; for du har funnet nåde for mine øine, og jeg kjenner dig ved navn. 
2:33:18: Men han sa: La mig da få se din herlighet! 
2:33:19: Og han sa: Jeg vil la all min godhet gå forbi ditt åsyn, og jeg vil rope ut Herrens navn for ditt åsyn; for jeg vil være nådig mot den som jeg er nådig imot, og miskunne mig over den som jeg miskunner mig over. 
2:33:20: Og han sa: Du kan ikke se mitt åsyn; for intet menneske kan se mig og leve. 
2:33:21: Derefter sa Herren: Se, her tett ved mig er et sted; still dig der på berget, 
2:33:22: og når min herlighet går forbi, da vil jeg la dig stå i fjellkløften, og jeg vil dekke med min hånd over dig til jeg er gått forbi; 
2:33:23: så vil jeg ta min hånd bort; da kan du se mig bakfra, men mitt åsyn kan ingen se. 
2:34:1: Og Herren sa til Moses: Hugg dig ut to stentavler likesom de første! Så vil jeg skrive på tavlene de ord som stod på de første tavler, de som du slo i stykker. 
2:34:2: Hold dig så rede til imorgen tidlig! Da skal du stige op på Sinai berg og vente på mig der på toppen av fjellet. 
2:34:3: Ingen må gå med dig op, og ingen må vise sig på hele fjellet, heller ikke må får eller okser beite under dette fjell. 
2:34:4: Så hugg Moses ut to stentavler likesom de første, og han stod tidlig op om morgenen og steg op på Sinai berg, som Herren hadde befalt ham, og hadde de to stentavler i sin hånd. 
2:34:5: Og Herren steg ned i skyen og stilte sig der hos ham og ropte ut Herrens navn. 
2:34:6: Og Herren gikk forbi hans ansikt og ropte: Herren, Herren er en barmhjertig og nådig Gud, langmodig og rik på miskunnhet og sannhet; 
2:34:7: han bevarer miskunnhet imot tusen ledd, han forlater misgjerning og overtredelse og synd; men han lar ikke den skyldige ustraffet, han hjemsøker fedres misgjerning på barn og på barnebarn, på dem i tredje og på dem i fjerde ledd. 
2:34:8: Da bøide Moses sig hastig til jorden og tilbad. 
2:34:9: Og han sa: Herre, dersom jeg har funnet nåde for dine øine, så gå med oss, Herre! For vel er det et hårdnakket folk; men du vil jo forlate oss vår misgjerning og vår synd og gjøre oss til din eiendom. 
2:34:10: Han svarte: Se, jeg vil gjøre en pakt: For hele ditt folks øine vil jeg gjøre underfulle ting, ting som det ikke har vært make til på hele jorden eller hos noget folkeslag; og hele det folk som du lever blandt, skal se Herrens gjerning, hvor forferdelig den er, den som jeg vil gjøre for dig. 
2:34:11: Hold du de bud jeg gir dig idag! Se, jeg vil jage amorittene og kana'anittene og hetittene og ferisittene og hevittene og jebusittene bort for dig. 
2:34:12: Ta dig i vare så du ikke gjør nogen pakt med innbyggerne i det land du kommer til, forat de ikke skal bli til en snare blandt eder. 
2:34:13: I skal rive ned deres altere, og I skal slå i stykker deres billedstøtter, og I skal hugge ned deres Astarte-billeder*. # <* et slags avgudsbilleder.> 
2:34:14: Du skal ikke tilbede nogen fremmed gud; for Herren heter Nidkjær, en nidkjær Gud er han. 
2:34:15: Ta dig i vare så du ikke gjør nogen pakt med landets innbyggere! For de vil drive avgudsdyrkelse og ofre til sine guder, og når de da innbyr dig, så vil du ete av deres offer. 
2:34:16: Og du vil ta hustruer blandt deres døtre til dine sønner, og deres døtre vil drive avgudsdyrkelse med sine guder og få dine sønner til å gjøre det samme. 
2:34:17: Støpte gudebilleder skal du ikke gjøre dig. 
2:34:18: De usyrede brøds høitid skal du holde; i syv dager skal du ete usyret brød, som jeg har befalt dig, på den fastsatte tid i måneden abib; for i måneden abib drog du ut av Egypten. 
2:34:19: Alt det som åpner morsliv, hører mig til, alt ditt fe av hankjønn som åpner morsliv, enten det er storfe eller småfe. 
2:34:20: Og det som åpner morsliv av asener, skal du løse med et stykke småfe, men dersom du ikke løser det, da skal du bryte nakken på det. Hver førstefødt blandt dine sønner skal du løse, og ingen skal vise sig tomhendt for mitt åsyn. 
2:34:21: Seks dager skal du arbeide, og på den syvende dag skal du hvile; om det så er i våronnen eller høstonnen, så skal du holde hviledagen. 
2:34:22: Ukenes høitid* skal du holde når du får førstegrøden av hvetehøsten, og innsamlingens høitid** når året er omme. # <* d.e. pinsen, 7 uker efter påske, 3MO 23, 15. 5MO 16, 9.> # <** d.e. løvsalenes høitid, 3MO 23, 34 fg.> 
2:34:23: Tre ganger om året skal alle menn blandt eder vise sig for Herrens, Israels Guds åsyn. 
2:34:24: For jeg vil jage hedningene bort for dig og utvide ditt land, og ingen skal attrå ditt land mens du går op for å vise dig for Herren din Guds åsyn tre ganger om året. 
2:34:25: Du skal ikke ofre blodet av mitt slaktoffer, så lenge det er syret brød hos dig, og påskehøitidens slaktoffer skal ikke bli liggende natten over til om morgenen. 
2:34:26: Det første av din jords førstegrøde skal du bære til Herren din Guds hus. Du skal ikke koke et kje i dets mors melk. 
2:34:27: Og Herren sa til Moses: Skriv nu du op disse ord! For efter disse ord har jeg gjort en pakt med dig og med Israel. 
2:34:28: Og han var der hos Herren firti dager og firti netter uten å ete brød og uten å drikke vann; og han* skrev på tavlene paktens ord, de ti ord. # <* Herren.> 
2:34:29: Så gikk Moses ned fra Sinai berg, og da han gikk ned fra fjellet, hadde han vidnesbyrdets to tavler i sin hånd; men Moses visste ikke at hans ansikts hud skinte fordi han* hadde talt med ham. # <* Herren.> 
2:34:30: Og Aron og alle Israels barn så at huden på Moses' ansikt skinte; og de fryktet for å komme nær til ham. 
2:34:31: Da kalte Moses på dem, og Aron og alle menighetens høvdinger vendte tilbake til ham, og Moses talte til dem. 
2:34:32: Derefter gikk alle Israels barn nær til, og han bar frem til dem alle de bud som Herren hadde gitt ham på Sinai berg. 
2:34:33: Og da Moses holdt op å tale til dem, la han et dekke over sitt ansikt. 
2:34:34: Men når Moses gikk inn for Herrens åsyn for å tale med ham, tok han dekket av, til han gikk ut igjen; og når han kom ut, talte han til Israels barn det som var blitt sagt ham. 
2:34:35: Og Israels barn så at huden på Moses' ansikt skinte; og Moses la atter dekket over sitt ansikt, til han gikk inn igjen for å tale med ham. 
2:35:1: Og Moses kalte sammen hele Israels barns menighet og sa til dem: Dette er det som Herren har befalt at I skal gjøre: 
2:35:2: I seks dager skal der gjøres arbeid, men den syvende dag skal I holde hellig hvile, en høihellig sabbat for Herren; enhver som gjør noget arbeid på den dag, skal lide døden. 
2:35:3: I skal ikke tende op ild i nogen av eders boliger i sabbatsdagen. 
2:35:4: Og Moses sa til hele Israels barns menighet: Dette er det som Herren har befalt: 
2:35:5: Ta ut en gave til Herren av det I eier! Enhver som har hjertelag til det, skal komme med gaven til Herren, gull og sølv og kobber 
2:35:6: og blå og purpurrød og karmosinrød ull og fint lingarn og gjetehår 
2:35:7: og rødfarvede værskinn og takasskinn og akasietre 
2:35:8: og olje til lysestaken og krydderier til salvings-oljen og til den velluktende røkelse 
2:35:9: og onyksstener og andre edelstener til å sette på livkjortelen og brystduken. 
2:35:10: Og enhver av eder som er kunstforstandig, skal komme og gjøre alt det Herren har befalt: 
2:35:11: tabernaklet med dekke og varetak, med kroker og planker, tverrstenger, stolper og fotstykker, 
2:35:12: arken med stenger, nådestolen og det dekkende forheng, 
2:35:13: bordet med stenger og alt som hører til, og skuebrødene 
2:35:14: og lysestaken og det som hører til den, og lampene og oljen til lysestaken, 
2:35:15: og røkoffer-alteret med stenger og salvings-oljen og den velluktende røkelse og dekket for inngangen - for inngangen til tabernaklet, 
2:35:16: brennoffer-alteret med kobbergitteret, stengene og alt som hører til, karet med fotstykke, 
2:35:17: omhengene til forgården med stolpene og fotstykkene, og forhenget til forgårdens port, 
2:35:18: pluggene både til tabernaklet og til forgården og snorene dertil, 
2:35:19: embedsklærne til tjenesten i helligdommen, de hellige klær til Aron, presten, og klærne for hans sønner til prestetjenesten. 
2:35:20: Og hele Israels barns menighet gikk bort fra Moses. 
2:35:21: Og de kom enhver hvis hjerte drev ham til det; og enhver hvis ånd tilskyndte ham, kom med gaver til Herren til arbeidet på sammenkomstens telt og til all tjeneste der og til de hellige klær. 
2:35:22: De kom både menn og kvinner: Enhver som hadde hjertelag til det, kom med spenner og ørenringer og fingerringer og kulekjeder, alle slags saker av gull; og likeså kom enhver som vilde vie en gave av gull til Herren. 
2:35:23: Og enhver som eide blå og purpurrød og karmosinrød ull og fint lingarn og gjetehår og rødfarvede værskinn og takasskinn, kom med det. 
2:35:24: Enhver som vilde gi en gave av sølv eller kobber, kom med sin gave til Herren; og enhver som eide akasietre til noget av det som skulde arbeides, han kom med det. 
2:35:25: Og enhver kvinne som var kunstforstandig, spant med sine hender, og de kom med det de hadde spunnet, blå og purpurrød og karmosinrød ull og fint lingarn. 
2:35:26: Og alle kvinner hvis hu og evner drev dem til det, spant gjetehårene. 
2:35:27: Og høvdingene bar frem onyksstenene og de andre edelstener til å sette på livkjortelen og på brystduken, 
2:35:28: og krydderiene og oljen til lysestaken og til salvings-oljen og til den velluktende røkelse. 
2:35:29: Enhver mann og kvinne av Israels barn hvis hjerte drev dem til å gi noget til det store verk som Herren ved Moses hadde befalt å gjøre, de kom med det som en frivillig gave til Herren. 
2:35:30: Og Moses sa til Israels barn: Se, Herren har kalt Besalel, sønn av Uri, Hurs sønn, av Juda stamme; 
2:35:31: han har fylt ham med Guds Ånd, med visdom, med forstand og med kunnskap og med dyktighet til alle slags arbeid 
2:35:32: og til å uttenke kunstverker, til å arbeide i gull og i sølv og i kobber 
2:35:33: og til å slipe stener til innfatning og skjære ut i tre, til å utføre alle slags kunstverker. 
2:35:34: Og forstand til å lære andre har han gitt både ham og Oholiab, Akisamaks sønn, av Dans stamme. 
2:35:35: Han har fylt dem med kunstnergaver, så de kan utføre alle slags treskjæring og kunstvevning og utsydd arbeid med blå og purpurrød og karmosinrød ull og fint lingarn og almindelig vevning - utføre alle slags arbeid og uttenke kunstverker. 
2:36:1: Og Besalel og Oholiab og alle kunstforstandige menn, som Herren hadde gitt forstand og kunnskap, så de forstod sig på alt det arbeid som skulde til for å få helligdommen ferdig, gjorde i ett og alt som Herren hadde sagt. 
2:36:2: For Moses kalte Besalel og Oholiab og alle kunstforstandige menn, som Herren hadde gitt kunstnergaver, alle hvis hjerte drev dem, til å skride til verket for å fullføre det. 
2:36:3: Og de fikk hos Moses hele den gave som Israels barn hadde båret frem til det arbeid som skulde utføres for å få helligdommen ferdig. Men folket bar fremdeles frivillige gaver frem til ham hver morgen. 
2:36:4: Da kom alle de kunstforstandige menn som utførte alt arbeidet ved helligdommen, enhver fra det arbeid han var i ferd med, 
2:36:5: og de sa til Moses: Folket bærer frem meget mere enn det trenges til det arbeid som Herren har befalt å fullføre. 
2:36:6: Da lot Moses rope ut i hele leiren: Ingen, hverken mann eller kvinne, skal lenger bære noget frem som gave til helligdommen! Så holdt folket op med å bære frem gaver. 
2:36:7: Men det som var gitt, var nok til hele det arbeid som skulde fullføres, ja mere enn nok. 
2:36:8: Så gjorde da alle de kunstforstandige blandt dem som var med i arbeidet, tabernaklet av ti tepper; av fint, tvunnet lingarn og blå og purpurrød og karmosinrød ull blev de gjort med kjeruber på i kunstvevning. 
2:36:9: Hvert teppe var åtte og tyve alen langt og fire alen bredt; alle teppene holdt samme mål. 
2:36:10: Fem av teppene festet de sammen, det ene til det andre, og likeså de andre fem tepper. 
2:36:11: Og de gjorde hemper av blå ull i kanten på det ene teppe, ytterst der hvor sammenfestingen skulde være; likeså gjorde de i kanten på det ytterste teppe, der hvor den andre sammenfesting skulde være. 
2:36:12: Femti hemper gjorde de på det ene teppe, og femti hemper gjorde de ytterst på det teppe som var der hvor den andre sammenfesting skulde være; hempene var like mot hverandre, den ene mot den andre. 
2:36:13: Og de gjorde femti gullkroker og festet teppene til hverandre med krokene, så tabernaklet blev et sammenhengende telt. 
2:36:14: Så gjorde de tepper av gjetehår til et dekke over tabernaklet; elleve sådanne tepper gjorde de. 
2:36:15: Hvert teppe var tretti alen langt og fire alen bredt; alle de elleve tepper holdt samme mål. 
2:36:16: Og de festet fem av teppene sammen for sig og seks for sig. 
2:36:17: De gjorde femti hemper i kanten på det ene teppe, ytterst der hvor sammenfestingen skulde være, og likeså femti hemper i kanten på det andre teppe, der hvor sammenfestingen skulde være. 
2:36:18: Og de gjorde femti kobberkroker til å feste teppene sammen med så det blev ett dekke. 
2:36:19: Over dekket gjorde de et varetak av rødfarvede værskinn og ovenpå det et varetak av takasskinn. 
2:36:20: Plankene til tabernaklet gjorde de av akasietre og reiste dem på ende. 
2:36:21: Hver planke var ti alen lang og halvannen alen bred. 
2:36:22: På hver planke var det to tapper, med en tverrlist imellem; således gjorde de med alle plankene til tabernaklet. 
2:36:23: Og av plankene som de gjorde til tabernaklet, reiste de tyve planker på den side som vendte mot syd; 
2:36:24: og firti fotstykker av sølv gjorde de til å sette under de tyve planker, to fotstykker under hver planke til å feste begge tappene i. 
2:36:25: Likeså gjorde de tyve planker til tabernaklets andre side, den som vendte mot nord, 
2:36:26: og til dem firti fotstykker av sølv, to fotstykker under hver planke. 
2:36:27: Til baksiden av tabernaklet, mot vest, gjorde de seks planker. 
2:36:28: Og to planker gjorde de til tabernaklets hjørner på baksiden; 
2:36:29: de var dobbelte nedenfra og likeledes begge dobbelte helt op, til den første ring; således gjorde de med dem begge på begge hjørnene. 
2:36:30: Således blev det åtte planker med sine fotstykker av sølv - seksten fotstykker, to under hver planke. 
2:36:31: Så gjorde de tverrstenger av akasietre, fem til plankene på den ene side av tabernaklet 
2:36:32: og fem til plankene på den andre side, og fem til plankene på baksiden av tabernaklet, mot vest. 
2:36:33: Og den mellemste tverrstang satte de således at den gikk tvert over midt på plankeveggen, fra den ene ende til den andre. 
2:36:34: Plankene klædde de med gull, og ringene på dem, som tverrstengene skulde stikkes i, gjorde de helt av gull; tverrstengene klædde de også med gull. 
2:36:35: Så gjorde de forhenget av blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn; de gjorde det i kunstvevning med kjeruber på. 
2:36:36: Og de gjorde fire stolper av akasietre til forhenget og klædde dem med gull; hakene på dem var av gull, og de støpte fire fotstykker av sølv til dem. 
2:36:37: Til teltdøren gjorde de et teppe av blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn med utsydd arbeid, 
2:36:38: og til teppet fem stolper med sine haker, og de klædde stolpehodene og stengene med gull; og til stolpene gjorde de fem fotstykker av kobber. 
2:37:1: Og Besalel gjorde arken av akasietre, halvtredje alen lang og halvannen alen bred og halvannen alen høi. 
2:37:2: Han klædde den med rent gull, både innvendig og utvendig, og han gjorde en gullkrans på den rundt omkring. 
2:37:3: Han støpte fire gullringer som han festet i de fire føtter på arken, to ringer på den ene side og to på den andre. 
2:37:4: Så gjorde han stenger av akasietre og klædde dem med gull, 
2:37:5: og han stakk stengene inn i ringene på sidene av arken, så arken kunde bæres. 
2:37:6: Så gjorde han en nådestol av rent gull, halvtredje alen lang og halvannen alen bred. 
2:37:7: Og han gjorde to kjeruber av gull; i drevet arbeid gjorde han dem og satte dem ved begge endene av nådestolen, 
2:37:8: en kjerub ved den ene ende og en kjerub ved den andre ende; i ett med nådestolen gjorde han kjerubene, én på hver ende av den. 
2:37:9: Kjerubene holdt vingene utbredt og opløftet, så de dekket over nådestolen med sine vinger, og deres ansikter vendte mot hverandre; mot nådestolen vendte kjerubene sitt ansikt. 
2:37:10: Så gjorde han bordet av akasietre, to alen langt og én alen bredt og halvannen alen høit. 
2:37:11: Han klædde det med rent gull og gjorde en gullkrans på det rundt omkring. 
2:37:12: Og han gjorde en list på det av en hånds bredde rundt omkring, og rundt om listen gjorde han en gullkrans. 
2:37:13: Så støpte han fire gullringer til det og satte ringene i de fire hjørner på de fire føtter. 
2:37:14: Like ved listen satt ringene til å stikke stengene i, så bordet kunde bæres. 
2:37:15: Stengene gjorde han av akasietre og klædde dem med gull; på dem skulde bordet bæres. 
2:37:16: Så gjorde han karene som skulde stå på bordet, av rent gull, fatene og skålene som hørte til bordet, og begerne og kannene som det skulde ofres drikkoffer med. 
2:37:17: Så gjorde han lysestaken av rent gull; i drevet arbeid gjorde han den; både foten på den og stangen, begerne og knoppene og blomstene var i ett med den. 
2:37:18: Seks armer gikk ut fra dens sider, tre armer fra den ene side og tre fra den andre. 
2:37:19: Det var tre mandelformede beger på den første arm med knopp og blomst, og tre mandelformede beger på den annen arm med knopp og blomst; således var det på alle de seks armer som gikk ut fra lysestaken. 
2:37:20: På selve lysestaken var det fire mandelformede beger med knopper og blomster, 
2:37:21: én knopp under de to første armer i ett med den, og én knopp under de to næste armer i ett med den, og én knopp under de to øverste armer i ett med den - én knopp under hvert par av de seks armer som gikk ut fra lysestaken. 
2:37:22: Både knoppene og armene var i ett med den; alt sammen var ett drevet arbeid av rent gull. 
2:37:23: Så gjorde han syv lamper til lysestaken og lysesakser og brikker til den av rent gull. 
2:37:24: Én talent rent gull brukte han til lysestaken og alle redskaper som hørte til den. 
2:37:25: Så gjorde han røkoffer-alteret av akasietre, en alen langt og en alen bredt, firkantet, og to alen høit; hornene på alteret var i ett med det. 
2:37:26: Han klædde det med rent gull, både ovenpå og på sidene rundt omkring og på hornene; og han gjorde en gullkrans på det rundt omkring. 
2:37:27: Og han gjorde to gullringer til det og satte dem nedenfor kransen, på begge sider av det, to på hver side; de skulde være til å stikke stenger i, så alteret kunde bæres på dem. 
2:37:28: Stengene gjorde han av akasietre og klædde dem med gull. 
2:37:29: Så laget han den hellige salvings-olje og den rene røkelse av velluktende krydderier, slik som det gjøres av dem som lager salver. 
2:38:1: Så gjorde han brennoffer-alteret av akasietre, fem alen langt og fem alen bredt, firkantet, og tre alen høit. 
2:38:2: Og han gjorde et horn på hvert av dets fire hjørner, og hornene var i ett med alteret; og han klædde det med kobber. 
2:38:3: Derefter gjorde han alle de redskaper som hørte til alteret, bøttene og ildskuffene og skålene til å sprenge blod med, kjøttgaflene og fyrfatene; alle disse redskaper gjorde han av kobber. 
2:38:4: Til alteret gjorde han et gitter, et nettverk av kobber, og han satte det under dets avsats nedentil, så det rakk til midt på alteret. 
2:38:5: Og han støpte fire ringer til de fire hjørner av kobber-gitteret til å stikke stengene i. 
2:38:6: Stengene gjorde han av akasietre og klædde dem med kobber. 
2:38:7: Og han stakk stengene inn i ringene på alterets sider, så det kunde bæres på dem; alteret gjorde han av bord, så det var hult. 
2:38:8: Siden gjorde han karet og dets fotstykke av kobber og brukte dertil speil* som tilhørte de kvinner som gjorde tjeneste ved inngangen til sammenkomstens telt. # <* kobberspeil.> 
2:38:9: Så gjorde han forgården: På den side som vendte mot syd, gjorde han omhengene til forgården av fint, tvunnet lingarn, hundre alen lange, 
2:38:10: de tyve stolper dertil og de tyve fotstykker til dem av kobber og hakene på stolpene og stengene til dem av sølv. 
2:38:11: På den nordre side gjorde han også omheng, hundre alen lange, de tyve stolper dertil og de tyve fotstykker til dem av kobber og hakene på stolpene og stengene til dem av sølv. 
2:38:12: På den vestre side gjorde han omheng på femti alen, de ti stolper dertil og de ti fotstykker til dem, hakene på stolpene og stengene til dem av sølv. 
2:38:13: Og på forsiden, mot øst, gjorde han også omheng på femti alen, 
2:38:14: femten alen på den ene kant, de tre stolper dertil og de tre fotstykker til dem, 
2:38:15: og femten alen på den andre kant, de tre stolper dertil og de tre fotstykker til dem, så det blev likt på begge sider av forgårdens port. 
2:38:16: Alle forgårdens omheng helt rundt var av fint, tvunnet lingarn. 
2:38:17: Fotstykkene til stolpene var av kobber; hakene på stolpene og stengene til dem var av sølv, og stolpehodene var klædd med sølv. Alle disse stolper i forgården var sammenbundet med stenger av sølv. 
2:38:18: Forhenget til forgårdens port var i utsydd arbeid av blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn, tyve alen langt og fem alen høit efter vevens bredde, i likhet med forgårdens omheng. 
2:38:19: De fire stolper dertil og de fire fotstykker til dem var av kobber; hakene på dem var av sølv; stolpehodene var klædd med sølv, og stengene til dem var av sølv. 
2:38:20: Alle pluggene til tabernaklet og til forgården helt rundt var av kobber. 
2:38:21: Her følger regnskapet over hvad som gikk med til tabernaklet, vidnesbyrdets telt - for Moses bød levittene å regne det over, og Itamar, sønn av Aron, presten, forestod utregningen. 
2:38:22: Men Besalel, sønn av Uri, Hurs sønn, av Juda stamme, var den som gjorde alt det Herren hadde befalt Moses, 
2:38:23: og sammen med ham Oholiab, Akisamaks sønn, av Dans stamme, som var kyndig i utskjæring og i kunstvevning og utsyning med blå og purpurrød og karmosinrød ull og med fint lingarn - : 
2:38:24: Alt det gull som blev brukt til arbeidet med å gjøre hele helligdommen ferdig, det vidde gul, var ni og tyve talenter og syv hundre og tretti sekel efter helligdommens vekt. 
2:38:25: Og sølvet som blev gitt av dem i menigheten som var mønstret, var hundre talenter og ett tusen, syv hundre og fem og sytti sekel efter helligdommens vekt, 
2:38:26: en beka eller en halv sekel efter helligdommens vekt for hver enkelt som blev optatt blandt de mønstrede, fra tyveårsalderen og opover, og det var seks hundre og tre tusen, fem hundre og femti mann. 
2:38:27: De hundre talenter sølv blev brukt til å støpe fotstykkene til helligdommen og fotstykkene til forhenget, hundre fotstykker av de hundre talenter, en talent til hvert fotstykke. 
2:38:28: Og de tusen, syv hundre og fem og sytti sekel brukte de til hakene på stolpene, til å klæ stolpehodene og til å sammenbinde dem med stenger. 
2:38:29: Det vidde kobber var sytti talenter og to tusen og fire hundre sekel. 
2:38:30: Av det gjorde de fotstykkene ved inngangen til sammenkomstens telt og kobber-alteret og kobber-gitteret som hørte til, og alle alterets redskaper 
2:38:31: og fotstykkene til forgården rundt omkring og fotstykkene ved forgårdens port og alle pluggene til tabernaklet og alle pluggene til forgården rundt omkring. 
2:39:1: Av den blå og purpurrøde og karmosinrøde ull gjorde de embedsklær til tjenesten i helligdommen; og de gjorde de hellige klær som Aron skulde ha, således som Herren hadde befalt Moses 
2:39:2: Livkjortelen gjorde de av gull og blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn. 
2:39:3: De hamret ut gullet til plater og klippet det op til tråder, så det kunde virkes inn i den blå og purpurrøde og karmosinrøde ull og i det fine lingarn med kunstvevning. 
2:39:4: De gjorde skulderstykker som blev festet til hverandre; med dem blev den hektet sammen i begge ender. 
2:39:5: Beltet som skulde sitte på den og holde den sammen, gjorde de i ett stykke med den og i samme slags vevning som den, av gull og blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn, således som Herren hadde befalt Moses. 
2:39:6: Så gjorde de onyksstenene i stand og satte dem inn i flettverk av gull; på dem var navnene på Israels barn innskåret, likesom på et signet. 
2:39:7: Og de satte dem på livkjortelens skulderstykker, forat de skulde minne om Israels barn, således som Herren hadde befalt Moses. 
2:39:8: Så gjorde de brystduken i kunstvevning, i samme slags vevning som livkjortelen, av gull og blå og purpurrød og karmosinrød ull og fint, tvunnet lingarn. 
2:39:9: Den var firkantet og dobbelt lagt; et spann lang og et spann bred var den og dobbelt lagt. 
2:39:10: Og de satte på den fire rader med stener. I en rad var det en karneol, en topas og en smaragd; det var den første rad. 
2:39:11: I den annen rad var det en karfunkel, en safir og en diamant, 
2:39:12: og i den tredje rad en hyasint, en agat og en ametyst, 
2:39:13: og i den fjerde rad en krysolitt, en onyks og en jaspis. De blev innfattet i hver sitt flettverk av gull. 
2:39:14: Stenene var tolv i tallet efter navnene på Israels sønner, en for hvert navn; på hver sten var navnet på en av de tolv stammer innskåret, likesom på et signet. 
2:39:15: Til brystduken gjorde de også kjeder av rent gull, slynget som snorer. 
2:39:16: Likeså gjorde de to flettverk av gull og to gullringer, og de satte de to ringer på hver sitt hjørne av brystduken, 
2:39:17: og de festet de to slyngede gullkjeder i de to ringer på hjørnene av brystduken. 
2:39:18: Og de to andre ender av de to slyngede kjeder festet de i de to flettverk og festet dem så til livkjortelens skulderstykker på fremsiden. 
2:39:19: Så gjorde de to gullringer og satte dem på de to andre hjørner av brystduken, på den indre side av den, den som vender inn mot livkjortelen. 
2:39:20: Og de gjorde ennu to gullringer og satte dem på livkjortelens to skulderstykker nedentil på fremsiden, der hvor den festes sammen, ovenfor livkjortelens belte. 
2:39:21: Ringene på brystduken bandt de til ringene på livkjortelen med en snor av blå ull, så brystduken satt ovenfor livkjortelens belte og ikke kunde skilles fra livkjortelen - således som Herren hadde befalt Moses. 
2:39:22: Så gjorde de overkjortelen som hører til livkjortelen, av vevd arbeid, helt igjennem av blå ull. 
2:39:23: Midt på overkjortelen var det en åpning - likesom åpningen på en brynje - og rundt omkring åpningen var det en bord, forat den ikke skulde revne. 
2:39:24: På overkjortelens kant nedentil satte de granatepler av tvunnet blå og purpurrød og karmosinrød ull. 
2:39:25: Og de gjorde bjeller av rent gull, og de satte bjellene rundt omkring hele kanten av overkjortelen nedentil, imellem granateplene, 
2:39:26: en bjelle og et granateple og så atter en bjelle og et granateple, og således rundt omkring hele kanten av overkjortelen nedentil, til bruk ved tjenesten, således som Herren hadde befalt Moses. 
2:39:27: Så gjorde de de vevde underkjortler av fint lin til Aron og hans sønner, 
2:39:28: og huen av fint lin og de høie huer til pryd av fint lin og lerretsbenklærne av fint, tvunnet lingarn, 
2:39:29: og beltet av fint, tvunnet lingarn og blå og purpurrød og karmosinrød ull i utsydd arbeid, således som Herren hadde befalt Moses. 
2:39:30: Så gjorde de platen, det hellige hodesmykke, av rent gull, og de skrev på den, således som en skjærer ut et signet: Helliget Herren. 
2:39:31: Og de satte en snor av blå ull i den for å feste den til huen oventil, således som Herren hadde befalt Moses. 
2:39:32: Således blev hele arbeidet med tabernaklet, sammenkomstens telt, fullendt. Israels barn gjorde det i ett og alt således som Herren hadde befalt Moses. 
2:39:33: Og de førte tabernaklet frem til Moses: teltet med alt som hørte til det, krokene, plankene, tverrstengene og stolpene og fotstykkene 
2:39:34: og varetaket av rødfarvede værskinn og varetaket av takasskinn og det dekkende forheng, 
2:39:35: vidnesbyrdets ark med sine stenger og nådestolen, 
2:39:36: bordet med alt som hørte til, og skuebrødene, 
2:39:37: lysestaken av rent gull med lampene som skulde settes i rad, og alt som hørte til, og oljen til lysestaken 
2:39:38: Og det gullklædde alter og salvings-oljen og den velluktende røkelse og teppet for inngangen til teltet, 
2:39:39: kobber-alteret og kobber-gitteret til det, stengene og alt som hørte til, tvettekaret med sitt fotstykke, 
2:39:40: omhengene til forgården med stolpene og fotstykkene og teppet til forgårdens port, snorene og pluggene og alle de ting som skulde brukes til tjenesten i tabernaklet, sammenkomstens telt, 
2:39:41: embedsklærne til tjenesten i helligdommen, de hellige klær til Aron, presten, og presteklærne til hans sønner. 
2:39:42: Som Herren hadde befalt Moses, således gjorde Israels barn i ett og alt hele arbeidet. 
2:39:43: Og Moses så på alt det som var gjort, og da han så at det var ferdig, og at de i alle deler hadde gjort det således som Herren hadde befalt, da velsignet han dem. 
2:40:1: Og Herren talte til Moses og sa: 
2:40:2: I den første måned, på den første dag i måneden, skal du reise tabernaklet, sammenkomstens telt. 
2:40:3: Der skal du sette vidnesbyrdets ark og dekke for arken med forhenget. 
2:40:4: Så skal du sette inn bordet, og på det skal du legge alt som skal ligge på det; og du skal bære inn lysestaken og sette op lampene på den. 
2:40:5: Og du skal sette det gullklædde røkoffer-alter foran vidnesbyrdets ark og henge op teppet for inngangen til tabernaklet. 
2:40:6: Brennoffer-alteret skal du sette foran inngangen til tabernaklet, sammenkomstens telt. 
2:40:7: Og tvettekaret skal du sette mellem sammenkomstens telt og alteret og ha vann i det. 
2:40:8: Og så skal du sette op forgården rundt omkring og henge op teppet for porten til forgården. 
2:40:9: Derefter skal du ta salvingsoljen og salve tabernaklet og alt som er i det, og du skal hellige tabernaklet og alt som hører det til, så det blir hellig. 
2:40:10: Og du skal salve brennofferalteret og alt som hører det til, og du skal hellige alteret, og alteret skal være høihellig. 
2:40:11: Og du skal salve tvettekaret og dets fotstykke og hellige det. 
2:40:12: Så skal du føre Aron og hans sønner frem til inngangen til sammenkomstens telt Og tvette dem med vann. 
2:40:13: Og du skal klæ Aron i de hellige klær og salve ham og hellige ham, og så skal han tjene mig som prest. 
2:40:14: Og hans sønner skal du også føre frem og klæ dem i underkjortlene. 
2:40:15: Og du skal salve dem, likesom du salvet deres far, og så skal de tjene mig som prester; og denne salving skal gi dem retten til prestedømmet for evig tid, slekt efter slekt. 
2:40:16: Og Moses gjorde så; han gjorde i ett og alt således som Herren befalte ham. 
2:40:17: I den første måned i det annet år, på den første dag i måneden, var det tabernaklet blev reist. 
2:40:18: Da reiste Moses tabernaklet: Han satte ned fotstykkene og stilte op plankene, la tverrstengene inn og reiste stolpene. 
2:40:19: Så bredte han ut dekket over tabernaklet og la varetaket ovenpå, således som Herren hadde befalt Moses. 
2:40:20: Derefter tok han og la vidnesbyrdet i arken og satte stengene i og satte nådestolen ovenpå arken. 
2:40:21: Og arken satte han inn i tabernaklet og hengte op det dekkende forheng foran vidnesbyrdets ark, således som Herren hadde befalt Moses. 
2:40:22: Så satte han bordet i sammenkomstens telt ved den nordre vegg av tabernaklet utenfor forhenget 
2:40:23: og la brødene i rad på det for Herrens åsyn, således som Herren hadde befalt Moses 
2:40:24: Lysestaken satte han i sammenkomstens telt midt imot bordet ved den søndre vegg av tabernaklet 
2:40:25: og satte op lampene for Herrens åsyn, således som Herren hadde befalt Moses 
2:40:26: Så satte han det gullklædde alter i sammenkomstens telt foran forhenget 
2:40:27: og brente velluktende røkelse på det, således som Herren hadde befalt Moses 
2:40:28: Derefter hengte han op teppet for inngangen til tabernaklet. 
2:40:29: Brennoffer-alteret satte han ved inngangen til tabernaklet, sammenkomstens telt, og ofret brennoffer og matoffer på det, således som Herren hadde befalt Moses 
2:40:30: Tvettekaret satte han mellem sammenkomstens telt og alteret og hadde vann i det til å tvette sig med. 
2:40:31: Og Moses og Aron og hans sønner tvettet sine hender og sine føtter i det. 
2:40:32: Når de gikk inn i sammenkomstens telt, og når de trådte nær til alteret, tvettet de sig, således som Herren hadde befalt Moses. 
2:40:33: Så reiste han op forgården omkring tabernaklet og alteret og hengte op teppet for porten til forgården. Således fullendte Moses verket. 
2:40:34: Da dekket skyen sammenkomstens telt, og Herrens herlighet fylte tabernaklet. 
2:40:35: Og Moses kunde ikke gå inn i sammenkomstens telt fordi skyen hvilte over det, og Herrens herlighet fylte tabernaklet. 
2:40:36: Og når skyen løftet sig fra tabernaklet, brøt Israels barn op; så gjorde de på alle sine tog. 
2:40:37: Men når skyen ikke løftet sig, brøt de ikke op, men ventet til den dag den atter løftet sig. 
2:40:38: For Herrens sky lå over tabernaklet om dagen, og om natten lyste den som ild, for alle Israels barns øine på alle deres tog. 
3:1:1: Og Herren kalte på Moses og talte til ham fra sammenkomstens telt og sa: 
3:1:2: Tal til Israels barn og si til dem: Når nogen av eder vil bære frem for Herren et offer av buskapen, så kan I ta eders offer enten av storfeet eller av småfeet. 
3:1:3: Dersom hans offer er et brennoffer av storfeet, skal han ofre en han uten lyte; han skal føre den frem til inngangen til sammenkomstens telt, forat han kan være til velbehag for Herrens åsyn. 
3:1:4: Og han skal legge sin hånd på brennofferets hode, så vil Herren ha velbehag i det og la det bli til soning for ham. 
3:1:5: Så skal han slakte den unge okse for Herrens åsyn; og Arons sønner, prestene, skal bære blodet frem og sprenge blodet rundt om på det alter som står ved inngangen til sammenkomstens telt. 
3:1:6: Og så skal han flå brennofferet og dele det op i sine stykker. 
3:1:7: Og Arons, prestens, sønner skal gjøre op ild på alteret og legge ved til rette på ilden. 
3:1:8: Så skal Arons sønner, prestene, legge stykkene, hodet og fettet, til rette på veden over ilden på alteret. 
3:1:9: Innvollene og føttene skal tvettes med vann, og så skal presten brenne det alt sammen på alteret; det er et brennoffer, et ildoffer til velbehagelig duft for Herren. 
3:1:10: Men dersom hans offer er et brennoffer av småfeet, av fårene eller av gjetene, så skal han ofre en han uten lyte. 
3:1:11: Og han skal slakte den på nordsiden av alteret for Herrens åsyn; og Arons sønner, prestene, skal sprenge dens blod rundt om på alteret. 
3:1:12: Så skal han dele den op i sine stykker og ta med hodet og fettet; og presten skal legge dem til rette på veden over ilden på alteret. 
3:1:13: Innvollene og føttene skal tvettes med vann, og så skal presten bære det alt sammen frem og brenne det på alteret; det er et brennoffer, et ildoffer til velbehagelig duft for Herren. 
3:1:14: Men dersom hans offer til Herren er et brennoffer av fugler, så skal han ta sitt offer enten av turtelduer eller av dueunger. 
3:1:15: Og presten skal bære fuglen frem til alteret og vri hodet av den og brenne det på alteret; og dens blod skal krystes ut mot alterets vegg. 
3:1:16: Så skal han ta ut dens kro med urenheten som er i den, og kaste den på askehaugen ved østsiden av alteret. 
3:1:17: Derefter skal han flekke fuglen ved vingene, dog uten å rive dem av, og så skal presten brenne den på alteret på veden over ilden; det er et brennoffer, et ildoffer til velbehagelig duft for Herren. 
3:2:1: Når nogen vil ofre et matoffer til Herren, så skal hans offer være fint mel; og han skal helle olje på det og legge virak ved. 
3:2:2: Så skal han bære det frem til Arons sønner, prestene, og presten skal ta en håndfull av det, av det fine mel og av oljen, dessuten all viraken, og han skal brenne det som ihukommelses-offer på alteret; det er et ildoffer til velbehagelig duft for Herren. 
3:2:3: Det som blir tilovers av matofferet, skal Aron og hans sønner ha; det hører til det som er høihellig av Herrens ildoffer. 
3:2:4: Og når du vil ofre et matoffer av noget som er bakt i ovn, så skal det være av fint mel, usyrede kaker med olje i eller usyrede brødleiver smurt med olje. 
3:2:5: Og dersom ditt offer er et matoffer som er stekt på helle, så skal det være av fint mel som er blandet med olje og usyret. 
3:2:6: Du skal bryte det i stykker og helle olje på det; det er et matoffer. 
3:2:7: Og dersom ditt offer er et matoffer som er stekt i panne, så skal det lages av fint mel med olje. 
3:2:8: Det matoffer som er tillaget på en av disse måter, skal du bære frem for Herren; du skal komme til presten med det, og han skal bære det frem til alteret. 
3:2:9: Og presten skal av matofferet ta ut ihukommelses-offeret og brenne det på alteret; det er et ildoffer til velbehagelig duft for Herren. 
3:2:10: Og det som blir tilovers av matofferet, skal Aron og hans sønner ha; det hører til det som er høihellig av Herrens ildoffer. 
3:2:11: Intet matoffer som I vil ofre til Herren, skal lages til med noget som er surt; for hverken av surdeig eller av honning skal I brenne ildoffer for Herren. 
3:2:12: Som en førstegrøde-gave kan I bære slike offer frem for Herren, men på alteret skal de ikke komme, til velbehagelig duft. 
3:2:13: Alle dine matoffer skal du salte; du skal ikke la det fattes på salt i ditt matoffer; for saltet hører til din Guds pakt; til alle dine offer skal du bære frem salt. 
3:2:14: Dersom du vil ofre et matoffer av førstegrøden til Herren, så skal du ofre aks som er ristet over ilden, nyhøstet korn som er knust; det skal være ditt matoffer av førstegrøden. 
3:2:15: Du skal ha olje på det og legge virak ved; det er et matoffer. 
3:2:16: Og presten skal til ihukommelses-offer brenne noget av det knuste korn og av oljen og dessuten all viraken; det er et ildoffer for Herren. 
3:3:1: Dersom nogen vil bære frem et takkoffer, og han tar det av storfeet, så skal det offerdyr han fører frem for Herrens åsyn, enten det er han eller hun, være uten lyte. 
3:3:2: Han skal legge sin hånd på offerdyrets hode og slakte det ved inngangen til sammenkomstens telt; og Arons sønner, prestene, skal sprenge blodet rundt om på alteret. 
3:3:3: Så skal han av takkofferet ofre et ildoffer til Herren, og det skal være fettet som dekker innvollene, og alt det fett som er på innvollene, 
3:3:4: og begge nyrene med det fett som er på dem, ved lendene, og den store leverlapp; den skal han ta ut sammen med nyrene. 
3:3:5: Og Arons sønner skal brenne det på alteret sammen med brennofferet som ligger over veden på ilden; det er et ildoffer til velbehagelig duft for Herren. 
3:3:6: Dersom han vil bære frem for Herren et takkoffer av småfeet, så skal han ofre en han eller hun uten lyte. 
3:3:7: Er det et får han vil ofre, så skal han føre det frem for Herrens åsyn 
3:3:8: og legge sin hånd på offerdyrets hode og slakte det foran sammenkomstens telt; og Arons sønner skal sprenge blodet rundt om på alteret. 
3:3:9: Så skal han av takkofferet bære frem et ildoffer for Herren, og det skal være fettet: hele halen - den skal han skjære av like ved ryggbenet - og fettet som dekker innvollene, og alt det fett som er på innvollene, 
3:3:10: og begge nyrene med det fett som er på dem, ved lendene, og den store leverlapp; den skal han ta ut sammen med nyrene. 
3:3:11: Og presten skal brenne det på alteret; det er et ildoffermåltid for Herren. 
3:3:12: Er det en gjet han vil ofre, så skal han føre den frem for Herrens åsyn 
3:3:13: og legge sin hånd på dens hode og slakte den foran sammenkomstens telt; og Arons sønner skal sprenge blodet rundt om på alteret. 
3:3:14: Så skal han av sitt offer bære frem et ildoffer for Herren, og det skal være fettet som dekker innvollene, og alt det fett som er på innvollene, 
3:3:15: og begge nyrene med det fett som er på dem, ved lendene, og den store leverlapp; den skal han ta ut sammen med nyrene. 
3:3:16: Og presten skal brenne det på alteret; det er et ildoffermåltid til velbehagelig duft. Alt fett hører Herren til. 
3:3:17: Det skal være en evig lov for eder, fra slekt til slekt, hvor I så bor. Intet fett og intet blod skal I ete. 
3:4:1: Og Herren talte til Moses og sa: 
3:4:2: Tal til Israels barn og si: Når nogen synder av vanvare mot noget av Herrens bud og gjør noget som han har forbudt å gjøre, 
3:4:3: så skal han, dersom det er den salvede prest som synder og således fører skyld over folket, ofre Herren en ung okse uten lyte til bot for den synd han har gjort; det er hans syndoffer. 
3:4:4: Han skal føre oksen frem for Herrens åsyn, til inngangen til sammenkomstens telt, og han skal legge sin hånd på oksens hode, og han skal slakte oksen for Herrens åsyn. 
3:4:5: Så skal han - den salvede prest - ta av oksens blod og bære det inn i sammenkomstens telt. 
3:4:6: Og han - presten - skal dyppe sin finger i blodet, og han skal sprenge av blodet syv ganger for Herrens åsyn, like foran helligdommens forheng. 
3:4:7: Noget av blodet skal han stryke på hornene av alteret med den velluktende røkelse, det som står for Herrens åsyn i sammenkomstens telt; og resten av oksens blod skal han helle ut ved foten av brennofferalteret, som står ved inngangen til sammenkomstens telt. 
3:4:8: Alt fettet på syndoffer-oksen skal han ta ut av den, både fettet som dekker innvollene, og alt det fett som er på innvollene, 
3:4:9: og begge nyrene med det fett som er på dem, ved lendene, og den store leverlapp; den skal han ta ut sammen med nyrene. 
3:4:10: Alt dette skal tas ut, likesom det tas ut av takkoffer-oksen; og presten skal brenne det på brennoffer-alteret. 
3:4:11: Men oksens hud og alt dens kjøtt med hode og med føtter og innvoller og skarn, 
3:4:12: hele oksen skal han føre utenfor leiren til et rent sted, der hvor de slår ut asken, og han skal brenne den op på veden; der hvor de slår ut asken, der skal den brennes. 
3:4:13: Dersom det er hele Israels menighet som synder av vanvare mot noget av Herrens bud, så det er skjult for folkets øine, og de gjør noget som han har forbudt å gjøre, og således fører skyld over sig, 
3:4:14: og så den synd de har gjort, blir vitterlig, da skal folket ofre en ung okse til syndoffer. Den skal de føre frem foran sammenkomstens telt, 
3:4:15: og menighetens eldste skal legge sine hender på oksens hode for Herrens åsyn, og så skal oksen slaktes for Herrens åsyn. 
3:4:16: Den salvede prest skal bære noget av oksens blod inn i sammenkomstens telt. 
3:4:17: Og han - presten - skal dyppe sin finger i blodet og sprenge syv ganger for Herrens åsyn, like foran forhenget. 
3:4:18: Noget av blodet skal han stryke på hornene av det alter som står for Herrens åsyn i sammenkomstens telt; og resten av blodet skal han helle ut ved foten av brennoffer-alteret, som står ved inngangen til sammenkomstens telt. 
3:4:19: Alt fettet på oksen skal han ta ut av den og brenne på alteret. 
3:4:20: Han skal gjøre med denne okse likesom han gjorde med den første syndoffer-okse. Og presten skal gjøre soning for dem, så de får forlatelse. 
3:4:21: Så skal de føre oksen utenfor leiren og brenne den op likesom den første okse; det er syndofferet for menigheten. 
3:4:22: Er det en høvding som synder av vanvare mot noget av Herrens, sin Guds bud og gjør noget som han har forbudt å gjøre, og således fører skyld over sig, 
3:4:23: og så den synd han har gjort, blir vitterlig for ham, da skal han som sitt offer føre frem en gjetebukk, en han uten lyte. 
3:4:24: Og han skal legge sin hånd på bukkens hode og slakte den på det sted hvor de slakter brennofferet for Herrens åsyn; det er et syndoffer. 
3:4:25: Presten skal ta av syndofferets blod på sin finger og stryke det på hornene av brennoffer-alteret; og resten av blodet skal han helle ut ved foten av brennoffer-alteret. 
3:4:26: Alt fettet skal han brenne på alteret likesom takkofferets fett. Og presten skal gjøre soning for ham og fri ham for hans synd, så han får forlatelse. 
3:4:27: Dersom det er nogen av det menige folk som synder av vanvare mot noget av Herrens bud og gjør noget som han har forbudt å gjøre, og således fører skyld over sig, 
3:4:28: og så den synd han har gjort, blir vitterlig for ham, da skal han som sitt offer for den synd han har gjort, føre frem en gjet uten lyte, en hun. 
3:4:29: Og han skal legge sin hånd på syndofferets hode og slakte syndofferet der hvor brennofferet slaktes. 
3:4:30: Presten skal ta av gjetens blod på sin finger og stryke det på hornene av brennoffer-alteret; og resten av blodet skal han helle ut ved alterets fot. 
3:4:31: Alt fettet skal han ta ut, likesom fettet tas ut av takkofferet, og presten skal brenne det på alteret til en velbehagelig duft for Herren. Således skal presten gjøre soning for ham, så han får forlatelse. 
3:4:32: Er det et får han fører frem som syndoffer, så skal han komme med en hun uten lyte. 
3:4:33: Og han skal legge sin hånd på offerdyrets hode og slakte det til syndoffer på det sted hvor brennofferet slaktes. 
3:4:34: Presten skal ta av syndofferets blod på sin finger og stryke det på hornene av brennoffer-alteret; og resten av blodet skal han helle ut ved alterets fot. 
3:4:35: Alt fettet skal han ta ut, likesom fettet på fåret tas ut av takkofferet, og presten skal brenne det på alteret sammen med Herrens ildoffer. Således skal presten gjøre soning for ham for den synd han har gjort, så han får forlatelse. 
3:5:1: Når nogen synder, idet han hører opropet til ed og kunde vidne om noget som han enten har sett eller er blitt vitende om, men allikevel ikke gir oplysning derom, og det således ligger en misgjerning på ham, 
3:5:2: eller når nogen uten å vite det rører ved noget urent, enten det er åtselet av et urent vilt dyr eller åtselet av et urent tamt dyr eller åtselet av et urent kryp, og han således er blitt uren og har ført skyld over sig, 
3:5:3: eller når han uten å vite det rører ved et menneskes urenhet, hvad det så er for urenhet det gjelder, og han siden får vite det og kjenner sig skyldig, 
3:5:4: eller når nogen uten å sanse sig sverger tankeløst med sine leber at han vil gjøre noget, enten ondt eller godt, hvad det så kan være et menneske tankeløst sverger på, og han siden blir det var og kjenner sig skyldig i noget av disse stykker - 
3:5:5: når nogen altså har ført skyld over sig med noget av dette og bekjenner det han har syndet i, 
3:5:6: da skal han til bot for den synd han har gjort, bære frem for Herren et syndoffer; det skal være en hun av småfeet, et får eller en gjet. Og presten skal gjøre soning for ham og fri ham for hans synd. 
3:5:7: Men dersom han ikke har råd til et stykke småfe, skal han til bot for sin synd bære frem for Herren to turtelduer eller to dueunger, en til syndoffer og en til brennoffer. 
3:5:8: Han skal føre dem frem til presten, og presten skal først ofre den som er til syndoffer; han skal vri hodet av den like over nakken, dog uten å rive det av. 
3:5:9: Så skal han sprenge av syndofferets blod på alterets vegg, og det som er tilovers av blodet, skal krystes ut ved alterets fot; det er et syndoffer. 
3:5:10: Den andre fugl skal han ofre som brennoffer, som foreskrevet er. Og presten skal gjøre soning for ham og fri ham for den synd han har gjort sig skyldig i, så han får forlatelse. 
3:5:11: Men dersom han ikke har råd til to turtelduer eller to dueunger, så skal han til bot for det han har syndet, ofre tiendedelen av en efa fint mel som syndoffer; han skal ikke ha olje på det og ikke legge virak ved; for det er et syndoffer. 
3:5:12: Han skal bære det til presten, og presten skal ta en håndfull av det som ihukommelses-offer og brenne det på alteret sammen med Herrens ildoffer; det er et syndoffer. 
3:5:13: Og presten skal gjøre soning for ham for den synd han har gjort i noget av hine stykker*, så han får forlatelse. Og resten av det skal høre presten til på samme måte som ved matofferet**. # <* 3MO 5, 1-4.> # <** se 3MO 2, 3. 10.> 
3:5:14: Og Herren talte til Moses og sa: 
3:5:15: Når nogen farer troløst frem og av vanvare forsynder sig mot nogen av Herrens hellige ting, så skal han til bot for sin synd ofre Herren som skyldoffer en vær uten lyte av sitt småfe, en som efter din verdsetning er verd minst to sekler i sølv efter helligdommens sekel. 
3:5:16: Og det hellige han har forsyndet sig mot, skal han gi vederlag for, og han skal legge femtedelen til og gi det til presten. Og presten skal gjøre soning for ham med skyldoffer-væren, så han får forlatelse. 
3:5:17: Når nogen uten å vite det synder mot noget av Herrens bud og gjør noget han har forbudt å gjøre, og han således har ført skyld over sig, og det ligger misgjerning på ham, 
3:5:18: så skal han som skyldoffer føre frem til presten en vær uten lyte av sitt småfe, efter din verdsetning. Og presten skal gjøre soning for ham for den synd han uvitterlig har gjort, så han får forlatelse. 
3:5:19: Det er et skyldoffer; han er blitt skyldig for Herren. 
3:5:20: Og Herren talte til Moses og sa: 
3:5:21: Når nogen synder og farer troløst frem mot Herren, idet han for sin næste lyver om noget som er ham betrodd eller nedlagt hos ham, eller om noget han har røvet, eller han med vold har fratatt sin næste noget, 
3:5:22: eller han har funnet noget som var tapt, og lyver om det, eller han sverger falsk om noget av alt det et menneske kan forsynde sig med, 
3:5:23: da skal den som således har syndet og ført skyld over sig, gi tilbake det han har røvet eller har tatt med vold, eller som var ham betrodd, eller som var tapt, og som han har funnet, 
3:5:24: eller hvad det kan være som han har svoret falsk om, og han skal godtgjøre det med dets fulle verd, og enda legge til femtedelen; han skal gi det til eiermannen samme dag som han bærer frem sitt skyldoffer. 
3:5:25: Og til bot for sin synd skal han føre frem til presten et skyldoffer for Herren: en vær uten lyte av sitt småfe, efter din verdsetning. 
3:5:26: Og presten skal gjøre soning for ham for Herrens åsyn, så han får forlatelse, hvad han så har gjort og ført skyld over sig med. 
3:6:1: Og Herren talte til Moses og sa: 
3:6:2: Byd Aron og hans sønner og si: Dette er loven om brennofferet: Brennofferet skal ligge på sitt bål på alteret hele natten til om morgenen, og således skal alterilden holdes brennende. 
3:6:3: Og presten skal klæ sig i sin lerretskjortel og ha lerretsbenklær på nærmest legemet, og han skal ta bort asken efter brennofferet som ilden har fortært på alteret, og legge den ved siden av alteret. 
3:6:4: Så skal han ta av sig sine klær og ta på sig andre klær og bære asken utenfor leiren til et rent sted. 
3:6:5: Ilden skal holdes brennende på alteret, den må ikke slukne; hver morgen skal presten legge ny ved på ilden og legge brennofferet til rette på den og brenne takkofferets fettstykker på den. 
3:6:6: En stadig ild skal holdes tendt på alteret; den må ikke slukne. 
3:6:7: Og dette er loven om matofferet: Arons sønner skal bære det frem for Herrens åsyn, foran alteret. 
3:6:8: Og presten skal ta en håndfull av matofferets fine mel og av dets olje og all viraken som er på matofferet, og brenne det på alteret til en velbehagelig duft, som et ihukommelses-offer til Herren. 
3:6:9: Og det som blir tilovers, skal Aron og hans sønner ete; det skal etes usyret på et hellig sted; i forgården til sammenkomstens telt skal de ete det. 
3:6:10: Det skal ikke bakes med surdeig; jeg har gitt dem det som deres del av mine ildoffer, det er høihellig likesom syndofferet og skyldofferet. 
3:6:11: Alt mannkjønn blandt Arons barn kan ete det; det skal til alle tider, fra slekt til slekt, være eders del av Herrens ildoffere; enhver som rører ved dem, skal være hellig. 
3:6:12: Og Herren talte til Moses og sa: 
3:6:13: Dette er Arons og hans sønners offer, som de skal ofre Herren den dag en av dem blir salvet: tiendedelen av en efa fint mel som stadig matoffer, halvdelen om morgenen og halvdelen om aftenen. 
3:6:14: Det skal lages til med olje på en helle; godt knadd skal det være når du bærer det frem; et matoffer som er bakt, skal du bære frem i små stykker til en velbehagelig duft for Herren. 
3:6:15: Den av hans sønner som blir salvet til prest i hans sted, skal lage det til; det er en evig lov; det skal brennes op helt for Herren. 
3:6:16: Ethvert matoffer som en prest bærer frem for sig selv, skal brennes helt; det må ikke etes. 
3:6:17: Og Herren talte til Moses og sa: 
3:6:18: Tal til Aron og hans sønner og si: Dette er loven om syndofferet: På det sted hvor brennofferet slaktes, skal syndofferet slaktes for Herrens åsyn; det er høihellig. 
3:6:19: Den prest som bærer frem syndofferet, kan ete det; det skal etes på et hellig sted, i forgården til sammenkomstens telt. 
3:6:20: Enhver som rører ved dets kjøtt, skal være hellig; og om det skvetter noget av dets blod på et klædningsstykke, skal du tvette det på et hellig sted. 
3:6:21: Er det et lerkar det kokes i, så skal det slås i stykker; men er det kokt i et kobberkar, så skal karet skures og skylles med vann. 
3:6:22: Alt mannkjønn blandt prestene kan ete det; det er høihellig. 
3:6:23: Men intet syndoffer som der bæres blod av inn i sammenkomstens telt for å gjøre soning i helligdommen, må etes; det skal brennes op med ild. 
3:7:1: Og dette er loven om skyldofferet: Det er høihellig. 
3:7:2: På det sted hvor brennofferet slaktes, skal skyldofferet slaktes, og dets blod skal sprenges rundt om på alteret. 
3:7:3: Og alt fettet skal ofres, både halen og fettet som dekker innvollene, 
3:7:4: og begge nyrene med det fett som er på dem, ved lendene, og den store leverlapp; den skal tas ut sammen med nyrene. 
3:7:5: Og presten skal brenne det på alteret som ildoffer til Herren; det er et skyldoffer. 
3:7:6: Alt mannkjønn blandt prestene kan ete det; på et hellig sted skal det etes; det er høihellig. 
3:7:7: Med skyldofferet skal det være likesom med syndofferet; det gjelder én lov for begge; den prest som gjør soning med det, ham skal det tilhøre. 
3:7:8: Når en prest ofrer brennoffer for nogen, så skal huden av det brennoffer han har ofret, tilhøre den samme prest. 
3:7:9: Og ethvert matoffer som bakes i ovn, eller som stekes i panne eller på helle, skal tilhøre den prest som ofrer det. 
3:7:10: Og ethvert matoffer som er blandet med olje, eller som er tørt, skal høre alle Arons sønner til, den ene som den andre. 
3:7:11: Og dette er loven om takkofferet som ofres til Herren: 
3:7:12: Dersom nogen ofrer det til lovprisning, så skal han foruten slaktofferet som bæres frem til lovprisning, ofre usyrede kaker med olje i og usyrede brødleiver smurt med olje, og fint mel knadd til kaker med olje i. 
3:7:13: Dette er den offergave han skal bære frem foruten det slaktoffer som bæres frem til takk og lovprisning, og dessuten syrede kaker. 
3:7:14: Av dette offer skal han bære frem én kake av hvert slag som gave til Herren; det skal tilhøre presten som sprenger takkofferets blod på alteret. 
3:7:15: Kjøttet av et takkoffer som bæres frem til lovprisning, skal etes på den dag det ofres; intet av det skal bli liggende til om morgenen. 
3:7:16: Dersom nogens slaktoffer er et lovet offer eller et frivillig offer, skal det etes på den dag det ofres; men det som levnes, kan etes den næste dag. 
3:7:17: Men hvad som enda blir tilovers av slaktofferets kjøtt, skal på den tredje dag brennes op med ild. 
3:7:18: Om nogen på den tredje dag eter av takkofferets kjøtt, da har Herren ikke velbehag i offeret; det skal ikke regnes den som ofret det, til gode, det skal være en vederstyggelighet, og den som eter av det, gjør en misgjerning som han kommer til å bøte for. 
3:7:19: Kjøtt* som kommer nær noget urent, skal ikke etes, det skal brennes op med ild; ellers kan alle som er rene, ete av kjøttet. # <* nemlig av takkofferet.> 
3:7:20: Men den som eter kjøtt av Herrens takkoffer mens det er noget urent på ham, han skal utryddes av sitt folk. 
3:7:21: Og når nogen rører ved noget urent, enten det er et menneskes urenhet eller et urent dyr eller noget annet urent og vederstyggelig, og så eter av kjøttet av Herrens takkoffer, da skal han utryddes av sitt folk. 
3:7:22: Og Herren talte til Moses og sa: 
3:7:23: Tal til Israels barn og si: I skal ikke ete fett av okse eller får eller gjet. 
3:7:24: Fettet av et selvdødt dyr eller av et sønderrevet dyr kan brukes til alle slags arbeid; men ete det må I ikke; 
3:7:25: for hver den som eter fettet av noget dyr som det ofres ildoffer av til Herren, han skal utryddes av sitt folk. 
3:7:26: Og blod skal I ikke ete, hverken av fugl eller fe, hvor I så bor. 
3:7:27: Enhver som nogensinne eter blod, han skal utryddes av sitt folk. 
3:7:28: Og Herren talte til Moses og sa: 
3:7:29: Tal til Israels barn og si: Den som ofrer sitt takke-slaktoffer til Herren, han skal bære frem for Herren sin offergave av sitt takke-slaktoffer. 
3:7:30: Med egne hender skal han bære frem Herrens ildoffer; både fettet og brystet skal han bære frem, og brystet skal svinges for Herrens åsyn. 
3:7:31: Og presten skal brenne fettet på alteret; men brystet skal høre Aron og hans sønner til. 
3:7:32: Og det høire lår skal I gi presten som gave av eders takkoffer. 
3:7:33: Den av Arons sønner som ofrer takkoffer-blodet og fettet, han skal ha det høire lår som sin del; 
3:7:34: for svinge-brystet og løfte-låret har jeg tatt fra Israels barn av deres takke-slaktoffer og gitt til Aron, presten, og til hans sønner som en evig rettighet de kan kreve av Israels barn. 
3:7:35: Dette er Arons del og hans sønners del av Herrens ildoffer, som gis dem på den dag de føres frem for å tjene Herren som prester, 
3:7:36: den del som Herren befalte skulde gis dem av Israels barn på den dag de salves, en evig rettighet, fra slekt til slekt. 
3:7:37: Dette er loven om brennofferet, om matofferet og om syndofferet og om skyldofferet og om innvielsesofferet og om takkofferet, 
3:7:38: den som Herren gav Moses på Sinai berg den dag han bød Israels barn å ofre Herren sine offer i Sinai ørken. 
3:8:1: Og Herren talte til Moses og sa: 
3:8:2: Hent Aron og hans sønner og ta deres klær og salvings-oljen og syndoffer-oksen og de to værer og kurven med de usyrede brød, 
3:8:3: og kall hele menigheten sammen ved inngangen til sammenkomstens telt! 
3:8:4: Og Moses gjorde som Herren bød ham; og menigheten samledes ved inngangen til sammenkomstens telt. 
3:8:5: Og Moses sa til menigheten: Således har Herren befalt å gjøre. 
3:8:6: Så førte Moses Aron og hans sønner frem og tvettet dem med vann. 
3:8:7: Og han gav ham underkjortelen på og spente beltet om ham og klædde ham i overkjortelen og gav ham livkjortelen på og bandt livkjortelens belte om ham, og med det snørte han livkjortelen sammen. 
3:8:8: Så satte han brystduken på ham, og la brystduken la han urim og tummim. 
3:8:9: Og han satte huen på hans hode, og på dens forside satte han gullplaten, det hellige hodesmykke, således som Herren hadde befalt Moses. 
3:8:10: Og Moses tok salvings-oljen og salvet tabernaklet og alt som var i det, og helliget det. 
3:8:11: Og han sprengte av oljen syv ganger på alteret og salvet alteret og alt som hørte det til, og tvette-karet med sitt fotstykke, og således helliget han dem. 
3:8:12: Og han helte av salvings-oljen på Arons hode og salvet ham og helliget ham. 
3:8:13: Så førte Moses Arons sønner frem og klædde dem i underkjortler og spente belte om dem og bandt høie huer på dem, således som Herren hadde befalt Moses. 
3:8:14: Så ledet han syndoffer-oksen frem, og Aron og hans sønner la sine hender på syndoffer-oksens hode. 
3:8:15: Og de slaktet den, og Moses tok blodet og strøk det rundt om på alterets horn med sin finger og renset alteret for synd; og resten av blodet helte han ut ved alterets fot. Således gjorde han soning for det og helliget det. 
3:8:16: Og han tok alt fettet som var på innvollene, og den store leverlapp og begge nyrene og fettet på dem, og Moses brente det på alteret. 
3:8:17: Men oksen med dens hud og dens kjøtt og dens skarn brente han op med ild utenfor leiren, således som Herren hadde befalt Moses. 
3:8:18: Så ledet han brennoffer-væren frem, og Aron og hans sønner la sine hender på værens hode. 
3:8:19: Og de slaktet den, og Moses sprengte blodet rundt om på alteret. 
3:8:20: Men væren selv delte de op i sine stykker, og Moses brente hodet og stykkene og fettet. 
3:8:21: Og de tvettet innvollene og føttene med vann, og Moses brente hele væren på alteret; det var et brennoffer til en velbehagelig duft, det var et ildoffer for Herren, således som Herren hadde befalt Moses. 
3:8:22: Så ledet han frem den andre vær, innvielses-væren, og Aron og hans sønner la sine hender på værens hode. 
3:8:23: Og de slaktet den, og Moses tok av dens blod og strøk på Arons høire ørelapp og på tommelfingeren på hans høire hånd og på stortåen på hans høire fot. 
3:8:24: Så førte de Arons sønner frem, og Moses strøk noget av blodet på deres høire ørelapp og på tommelfingeren på deres høire hånd og på stortåen på deres høire fot. Og Moses sprengte resten av blodet rundt om på alteret. 
3:8:25: Og han tok fettet, både halen og alt fettet som var på innvollene, og den store leverlapp Og begge nyrene og fettet på dem og det høire lår; 
3:8:26: og av kurven med de usyrede brød, som stod for Herrens åsyn, tok han en usyret kake og en oljekake og en brødleiv og la på fettstykkene og på det høire lår. 
3:8:27: Alt dette la han i Arons hender og i hans sønners hender; og han svinget det for Herrens åsyn. 
3:8:28: Så tok Moses det av deres hånd og brente det på alteret sammen med brennofferet; det var et innvielsesoffer til en velbehagelig duft, det var et ildoffer for Herren. 
3:8:29: Derefter tok Moses brystet og svinget det for Herrens åsyn; det var den del Moses fikk av innvielses væren, således som Herren hadde befalt Moses. 
3:8:30: Og Moses tok av salvingsoljen og av blodet som var på alteret, og sprengte på Aron og på hans klær og likeledes på hans sønner og deres klær; således helliget han Aron og hans klær og likeledes hans sønner og hans sønners klær. 
3:8:31: Og Moses sa til Aron og hans sønner: Kok kjøttet ved inngangen til sammenkomstens telt og et det der sammen med brødet som er i innvielses-kurven, således som jeg har befalt og sagt: Aron og hans sønner skal ete det. 
3:8:32: Men det som blir tilovers av kjøttet og av brødet, skal I brenne op med ild. 
3:8:33: Og i syv dager skal I ikke gå bort fra inngangen til sammenkomstens telt - helt til den dag da eders innvielses-tid er til ende; for syv dager skal eders innvielse vare. 
3:8:34: Som det er gjort idag, så har Herren befalt det skal gjøres også de andre dager, for å gjøre soning for eder. 
3:8:35: I skal bli ved inngangen til sammenkomstens telt i syv dager, både dag og natt, og ta vare på det som Herren vil ha varetatt, så I ikke skal dø; for således er mig befalt. 
3:8:36: Og Aron og hans sønner gjorde alt det som Herren hadde befalt ved Moses. 
3:9:1: Og på den åttende dag kalte Moses Aron og hans sønner og Israels eldste til sig. 
3:9:2: Og han sa til Aron: Ta dig en oksekalv til syndoffer og en vær til brennoffer, begge uten lyte, og led dem frem for Herrens åsyn! 
3:9:3: Og du skal tale til Israels barn og si: Ta en gjetebukk til syndoffer og en kalv og et lam, begge årsgamle og uten lyte, til brennoffer, 
3:9:4: og en okse og en vær til takkoffer, for å ofre dem for Herrens åsyn, og et matoffer, tillaget med olje! For idag vil Herren åpenbare sig for eder. 
3:9:5: Og de tok det som Moses hadde befalt, og farte det frem foran sammenkomstens telt; og hele menigheten trådte til og stod for Herrens åsyn. 
3:9:6: Da sa Moses: Dette er det Herren har befalt eder å gjøre; så skal Herrens herlighet åpenbare sig for eder. 
3:9:7: Så sa Moses til Aron: Tred frem til alteret og ofre ditt syndoffer og ditt brennoffer og gjør soning for dig og for folket, og ofre så folkets offer og gjør soning for dem, således som Herren har befalt! 
3:9:8: Og Aron trådte frem til alteret og slaktet den kalv som skulde være syndoffer for ham selv. 
3:9:9: Og Arons sønner bar blodet til ham, og han dyppet sin finger i blodet og strøk det på alterets horn, og resten av blodet helte han ut ved alterets fot. 
3:9:10: Men fettet og nyrene og den store leverlapp av syndofferet brente han på alteret, således som Herren hadde befalt Moses. 
3:9:11: Og kjøttet og huden brente han op med ild utenfor leiren. 
3:9:12: Så slaktet han brennofferet; og Arons sønner rakte ham blodet, og han sprengte det rundt om på alteret. 
3:9:13: Og de rakte ham brennofferet, stykke for stykke, og hodet, og han brente det på alteret. 
3:9:14: Og han tvettet innvollene og føttene og brente dem sammen med brennofferet på alteret. 
3:9:15: Så førte han folkets offer frem; han tok bukken som skulde være syndoffer for folket, og slaktet den og ofret den til et syndoffer, likesom det første offerdyr. 
3:9:16: Så førte han brennofferet frem og ofret det, som det var foreskrevet. 
3:9:17: Derefter bar han matofferet frem og tok en håndfull av det og brente på alteret, foruten morgenbrennofferet. 
3:9:18: Så slaktet han oksen og væren som skulde være folkets takkoffer; og Arons sønner rakte ham blodet, og han sprengte det rundt om på alteret. 
3:9:19: Men fettstykkene av oksen, og halen og fettet som dekket innvollene, og nyrene og den store leverlapp av væren - 
3:9:20: disse fettstykker la de på bryststykkene; og han brente fettstykkene på alteret, 
3:9:21: men bryststykkene og det høire lår svinget Aron for Herrens åsyn, således som Moses hadde befalt. 
3:9:22: Og Aron løftet sine hender over folket og velsignet dem; og så steg han ned, efterat han hadde ofret syndofferet og brennofferet og takkofferet. 
3:9:23: Derefter gikk Moses og Aron inn i sammenkomstens telt, og da de kom ut igjen, velsignet de folket. Da åpenbarte Herrens herlighet sig for hele folket, 
3:9:24: og det gikk ild ut fra Herrens åsyn og fortærte brennofferet og fettstykkene på alteret; og hele folket så det, og de ropte høit av glede og falt ned på sitt ansikt. 
3:10:1: Men Arons sønner Nadab og Abihu tok hver sitt ildkar og la ild i dem og la røkelse på ilden og bar fremmed ild inn for Herrens åsyn, som han ikke hadde befalt dem. 
3:10:2: Da gikk det ild ut fra Herrens åsyn og fortærte dem, og de døde for Herrens åsyn. 
3:10:3: Da sa Moses til Aron: Dette var det Herren talte om da han sa: På dem som står mig nær, vil jeg åpenbare min hellighet, og for alt folkets åsyn vil jeg forherlige mig. Og Aron tidde. 
3:10:4: Men Moses kalte på Misael og Elsafan, sønner av Arons farbror Ussiel, og sa til dem: Tred frem og bær eders brødre bort fra helligdommen og utenfor leiren! 
3:10:5: Og de trådte frem og bar dem i deres kjortler utenfor leiren, som Moses hadde sagt. 
3:10:6: Da sa Moses til Aron og hans sønner Eleasar og Itamar: I skal ikke rake eders hoder og ikke sønderrive eders klær, forat I ikke skal dø, og forat han* ikke skal vredes på hele menigheten; men eders brødre, hele Israels hus, skal gråte over denne brand som Herren har optendt. # <* Herren.> 
3:10:7: Og I skal ikke gå bort fra inngangen til sammenkomstens telt, forat I ikke skal dø; for Herrens salvings-olje er på eder. Og de gjorde som Moses sa. 
3:10:8: Og Herren talte til Aron og sa: 
3:10:9: Vin eller sterk drikk skal hverken du eller dine sønner drikke når I går inn i sammenkomstens telt, forat I ikke skal dø - det skal være en evig lov for eder, fra slekt til slekt - 
3:10:10: så I kan gjøre forskjell mellem hellig og vanhellig, mellem urent og rent, 
3:10:11: og lære Israels barn alle de lover som Herren har kunngjort dem ved Moses. 
3:10:12: Så sa Moses til Aron og hans sønner Eleasar og Itamar, som ennu var i live: Ta det matoffer som er tilovers av Herrens ildoffer, og et det usyret ved siden av alteret; for det er høihellig. 
3:10:13: I skal ete det på et hellig sted, for det er din og dine sønners fastsatte del av Herrens ildoffer; således er det mig befalt. 
3:10:14: Og svinge-brystet og løfte-låret skal I ete på et rent sted, du og dine sønner og dine døtre med dig; for det er gitt dig og dine barn som eders fastsatte del av Israels barns takkoffer. 
3:10:15: Løfte-låret og svinge-brystet skal de bære frem sammen med ildofferfettstykkene og svinge dem for Herrens åsyn; og det skal være din og dine barns fastsatte del til evig tid, således som Herren har befalt. 
3:10:16: Da nu Moses spurte efter syndoffer-bukken, viste det sig at den var opbrent; da blev han vred på Eleasar og Itamar, Arons sønner som ennu var i live, og han sa: 
3:10:17: Hvorfor har I ikke ett syndofferet på det hellige sted? Det er jo høihellig, og han har gitt eder det forat I skal bortta menighetens syndeskyld og gjøre soning for dem for Herrens åsyn. 
3:10:18: Blodet blev jo ikke båret inn i helligdommen; derfor skulde I ha ett kjøttet på det hellige sted, således som jeg har befalt. 
3:10:19: Da sa Aron til Moses: De har jo idag ofret sitt syndoffer og sitt brennoffer for Herrens åsyn, og enda er slik en ulykke* hendt mig. Om jeg nu hadde ett syndoffer idag, skulde da det ha vært godt i Herrens øine? # <* 3MO 10, 1-5.> 
3:10:20: Da Moses hørte dette, syntes han det var rett. 
3:11:1: Og Herren talte til Moses og Aron og sa til dem: 
3:11:2: Tal til Israels barn og si: Dette er de dyr som I kan ete av alle firføtte dyr på jorden: 
3:11:3: Alle de firføtte dyr som har klover og har klovene kløvd helt igjennem, og som tygger drøv, dem kan I ete. 
3:11:4: Det er bare disse I ikke skal ete blandt dem som tygger drøv, og blandt dem som har klover: kamelen, for den tygger vel drøv, men den har ikke klover, den skal være uren for eder, 
3:11:5: og fjellgrevlingen, for den tygger vel drøv, men har ikke klover, den skal være uren for eder, 
3:11:6: og haren, for den tygger vel drøv, men har ikke klover, den skal være uren for eder, 
3:11:7: og svinet, for det har vel klover og har klovene kløvd helt igjennem, men det tygger ikke drøv, det skal være urent for eder. 
3:11:8: Av disse dyrs kjøtt skal I ikke ete, og ved deres døde kropper skal I ikke røre; de skal være urene for eder. 
3:11:9: Dette kan I ete av alt det som er i vannet: Alt det i vannet - i havene og i elvene - som har finner og skjell, det kan I ete. 
3:11:10: Men det som ikke har finner og skjell av alt det som finnes i havene og i elvene, av alt det som vrimler i vannet, og av alt levende som er i vannet, det skal være en vederstyggelighet for eder. 
3:11:11: En vederstyggelighet skal de være for eder; av deres kjøtt skal I ikke ete, og deres døde kropper skal I holde for en vederstyggelighet. 
3:11:12: Alt i vannet som ikke har finner og skjell, det skal være en vederstyggelighet for eder. 
3:11:13: Dette er de fugler I skal holde for en vederstyggelighet, de skal ikke etes, de er en vederstyggelighet: landørnen og havørnen og fiskeørnen 
3:11:14: og glenten, og falken efter sine arter, 
3:11:15: alle ravner efter sine arter, 
3:11:16: og strutsen og gjøken og måken, og høken efter sine arter, 
3:11:17: og kattuglen og dykkeren og hubroen 
3:11:18: og nattravnen og pelikanen og gribben 
3:11:19: og storken, og heiren efter sine arter, og hærfuglen og flaggermusen. 
3:11:20: Alt kryp som har vinger og går på fire føtter, skal være en vederstyggelighet for eder. 
3:11:21: Av alt kryp som har vinger og går på fire føtter, må I bare ete dem som har springben ovenfor sine føtter til å hoppe på marken med. 
3:11:22: Av dem kan I ete: arbe efter sine arter og solam efter sine arter og hargol efter sine arter og hagab efter sine arter*. # <* d.e. forskjellige slags gresshopper.> 
3:11:23: Men alt annet kryp som har vinger og fire føtter, skal være en vederstyggelighet for eder. 
3:11:24: Ved disse dyr blir I urene; enhver som rører ved deres døde kropper, skal være uren til om aftenen, 
3:11:25: og enhver som har båret slik en død kropp, skal tvette sine klær og være uren til om aftenen. 
3:11:26: Ethvert firføtt dyr som vel har klover, men ikke har klovene kløvd helt igjennem og ikke tygger drøv, skal være urent for eder; enhver som rører ved dem, blir uren. 
3:11:27: Og alle firføtte dyr som går på labber, skal være urene for eder; enhver som rører ved deres døde kropper, skal være uren til om aftenen, 
3:11:28: og den som har båret deres døde kropper, skal tvette sine klær og være uren til om aftenen; de skal være urene for eder. 
3:11:29: Og blandt de smådyr som kryper på jorden, skal disse være de ureneste for eder: muldvarpen og musen og alle slags firben, 
3:11:30: og pinnsvinet og jordrotten og padden og sneglen og kameleonen*. # <* Betydningen av de hebraiske navn er usikker.> 
3:11:31: Disse skal være de ureneste for eder blandt alle smådyr; enhver som rører ved dem når de er døde, skal være uren til om aftenen. 
3:11:32: Og alt det som noget av disse dyr faller på når de er døde, blir urent, enten det er et trekar eller klær eller skinn eller sekker, eller hvad redskap det kan være som det gjøres noget arbeid med; det skal legges i vann og være urent til om aftenen; da er det rent. 
3:11:33: Og om noget av dem faller i et lerkar, så blir alt det som er i karet urent, og karet selv skal I slå i stykker; 
3:11:34: all mat som folk eter, og som tillages med vann, blir da uren, og alt drikke som folk drikker, blir urent, hvad kar det enn er i. 
3:11:35: Og alt det som slik en død kropp faller på, blir urent; enten det er ovn eller gryte, skal de brytes i stykker, de er urene og skal være urene for eder. 
3:11:36: Bare kilder og brønner som det samler sig vann i, blir like rene; men den som rører ved deres døde kropper, blir uren. 
3:11:37: Og om slik en død kropp faller på korn som skal såes, er kornet like rent; 
3:11:38: men slåes det vann på såkornet, og en av de døde kropper faller på det, da skal kornet være urent for eder. 
3:11:39: Dør noget av de firføtte dyr som er til føde for eder, da skal den som rører ved dets døde kropp, være uren til om aftenen, 
3:11:40: og den som har ett av dets døde kropp, skal tvette sine klær og være uren til om aftenen; og den som har båret dets døde kropp, skal tvette sine klær og være uren til om aftenen. 
3:11:41: Alle smådyr som kryper på jorden, skal være en vederstyggelighet; de skal ikke etes. 
3:11:42: Hverken det som kryper på buken, eller det som går på fire føtter, eller det som har flere føtter iblandt alle smådyr som kryper på jorden, må I ete; det skal være en vederstyggelighet. 
3:11:43: Gjør ikke eder selv vederstyggelige ved noget sådant kryp, og la eder ikke smitte ved dem, så I blir urene! 
3:11:44: For jeg er Herren eders Gud, og I skal hellige eder, og I skal være hellige, for jeg er hellig; I skal ikke gjøre eder selv urene ved noget av det kryp som rører sig på jorden. 
3:11:45: For jeg er Herren, som førte eder op fra Egyptens land for å være eders Gud, og I skal være hellige, for jeg er hellig. 
3:11:46: Dette er loven om de firføtte dyr og fuglene og alle de liv som rører Sig i vannet, og om alle de dyr som kryper på jorden, 
3:11:47: så I kan skille mellem urent og rent, mellem dyr som kan etes, og dyr som ikke skal etes. 
3:12:1: Og Herren talte til Moses og sa: 
3:12:2: Tal til Israels barn og si: Når en kvinne får barn og føder en gutt, så skal hun være uren i syv dager; hun skal være uren like så lenge som hun er uren i sin månedlige svakhet. 
3:12:3: Den åttende dag skal hans forhuds kjøtt omskjæres. 
3:12:4: Derefter skal hun holde sig inne i tre og tretti dager under sitt blods renselse; hun skal ikke røre ved noget hellig og ikke komme til helligdommen før hennes renselses-dager er til ende. 
3:12:5: Men føder hun en pike, da skal hun være uren i to uker likesom ved sin månedlige urenhet og holde sig inne i seks og seksti dager under sitt blods renselse. 
3:12:6: Når hennes renselses-dager er til ende, enten det er en sønn eller en datter hun har født, skal hun komme med et årsgammelt lam til brennoffer og en dueunge eller en turteldue til syndoffer og bære det frem til inngangen til sammenkomstens telt, til presten. 
3:12:7: Og han skal ofre det for Herrens åsyn og gjøre soning for henne; da blir hun ren efter sin blodflod. Dette er loven for den kvinne som har født, enten barnet er en gutt eller en pike. 
3:12:8: Men har hun ikke råd til et lam, så skal hun ta to turtelduer eller to dueunger, en til brennoffer og en til syndoffer. Og presten skal gjøre soning for henne; da blir hun ren. 
3:13:1: Og Herren talte til Moses og Aron og sa: 
3:13:2: Når det på et menneskes hud viser sig en knute eller et utslett eller en lys flekk, så det på hans hud er noget som ser ut til å bli spedalskhet, da skal han føres til Aron, presten, eller til en av hans sønner, prestene. 
3:13:3: Presten skal se på det syke sted på huden, og dersom hårene på det er blitt hvite, og det syke sted ser dypere ut enn huden ellers, da er det spedalskhet, og når presten ser det, skal han si ham uren. 
3:13:4: Men er det en hvit, lys flekk på huden, og den ikke ser dypere ut enn huden ellers, og hårene på den ikke er blitt hvite, da skal presten holde den syke innestengt i syv dager. 
3:13:5: Den syvende dag skal presten se på ham igjen, og dersom han finner at det syke sted er uforandret å se til og ikke har bredt sig videre ut på huden, da skal han atter holde ham innestengt i syv dager. 
3:13:6: Når så presten den syvende dag ser på ham ennu en gang og finner at det syke sted er bleknet og ikke har bredt sig videre ut på huden, da skal han si ham ren; det er da bare et utslett, og han skal tvette sine klær, så er han ren. 
3:13:7: Men brer utslettet sig på huden efterat han har vist sig frem for presten for å bli sagt ren, da skal han ennu en gang vise sig frem for presten. 
3:13:8: Når presten da ser på ham og finner at utslettet har bredt sig videre ut på huden, da skal han si ham uren; det er spedalskhet. 
3:13:9: Når et menneske er blitt syk av spedalskhet, skal han føres til presten. 
3:13:10: Når presten da ser på ham og finner at det er en hvit knute på huden, og at hårene på den er blitt hvite, og at det er tegn til vilt kjøtt i knuten, 
3:13:11: da er det en gammel spedalskhet i huden på hans legeme, og presten skal si ham uren; han skal ikke lukke ham inne, for han er alt uren. 
3:13:12: Men når spedalskheten bryter ut i huden, så den dekker den sykes hud helt fra hode til fot, overalt hvor prestens øie faller, 
3:13:13: og presten da ser på det og finner at spedalskheten dekker hele hans legeme, da skal han si den syke ren; han er blitt hvit over det hele - han er ren. 
3:13:14: Men så snart det viser sig vilt kjøtt på ham, blir han uren. 
3:13:15: Og når presten ser det ville kjøtt, skal han si ham uren; for det ville kjøtt er urent, det er spedalskhet. 
3:13:16: Men dersom det ville kjøtt atter forandrer sig og blir hvitt, da skal han gå til presten, 
3:13:17: og når presten ser på ham og finner at det syke sted er blitt hvitt, da skal han si den syke ren; for da er han ren. 
3:13:18: Når nogen har en byld på sin hud, og den læges, 
3:13:19: og det på det sted hvor bylden var, blir en hvit knute eller en lyserød flekk, da skal han vise sig frem for presten. 
3:13:20: Og når presten ser på ham og finner at stedet ser dypere ut enn huden ellers, og at hårene på det er blitt hvite, da skal presten si ham uren; det er spedalskhet som er brutt ut i bylden. 
3:13:21: Men når presten ser på det syke sted og finner at det ikke er hvite hår på det, og at det ikke er dypere enn huden ellers, og at det er blekt, da skal presten holde ham innestengt i syv dager. 
3:13:22: Brer det sig da videre ut på huden, skal presten si ham uren; det er spedalskhet. 
3:13:23: Men dersom den lyse flekk blir uforandret på sitt sted og ikke har bredt sig ut, da er det bare arret efter bylden, og presten skal si ham ren. 
3:13:24: Når nogen får et brandsår på huden, og kjøttet som gror i brandsåret, viser sig som en lyserød eller hvit flekk, 
3:13:25: så skal presten se på den, og dersom han finner at hårene på den lyse flekk er blitt hvite, og at den ser dypere ut enn huden ellers, da er det spedalskhet som er brutt ut i brandsåret, og presten skal si ham uren; det er spedalskhet. 
3:13:26: Men finner presten, når han ser på den, at det ikke er hvite hår på den lyse flekk, og at den ikke er dypere enn huden ellers, og at den er blek, da skal han holde ham innestengt i syv dager. 
3:13:27: Den syvende dag skal presten se på ham; dersom flekken da har bredt sig videre ut på huden, skal presten si ham uren; det er spedalskhet. 
3:13:28: Men dersom den lyse flekk blir uforandret på sitt sted og ikke har bredt sig ut på huden, og den er blek, da er det en knute efter brandsåret, og presten skal si ham ren; for det er bare arret efter brandsåret. 
3:13:29: Når en mann eller kvinne har et sykt sted på hodet eller i skjegget, 
3:13:30: så skal presten se på det syke sted, og dersom han finner at det ser dypere ut enn huden ellers, og at det er gule, tynne hår på det, da skal han si ham uren; det er skurv, spedalskhet på hodet eller i skjegget. 
3:13:31: Men dersom presten, når han ser på skurven, finner at den ikke ser dypere ut enn huden ellers, men at det ikke er sorte hår på den, da skal presten holde den som har skurven, innestengt i syv dager. 
3:13:32: Den syvende dag skal presten se på det syke sted, og dersom han finner at skurven ikke har bredt sig ut, og at det ikke er gule hår på den, og at skurven ikke ser dypere ut enn huden ellers, 
3:13:33: da skal den som har skurven rake sig, dog ikke der hvor skurven er; og presten skal atter holde ham innestengt i syv dager. 
3:13:34: Dersom da presten, når han den syvende dag ser på skurven, finner at skurven ikke har bredt sig ut på huden, og at den ikke ser dypere ut enn huden ellers, da skal han si ham ren, og han skal tvette sine klær, så er han ren. 
3:13:35: Men dersom skurven brer sig ut på huden efterat han er sagt ren, 
3:13:36: så skal presten se på ham, og dersom han finner at skurven har bredt sig ut på huden, da skal han ikke søke efter de gule hår; han er uren. 
3:13:37: Men ser det for ham ut som skurven er uforandret, og det er vokset sorte hår på den, da er skurven lægt; han er ren, og presten skal si ham ren. 
3:13:38: Når en mann eller kvinne får lyse flekker, hvite, lyse flekker, på huden, 
3:13:39: og presten da ser på dem og finner lyse flekker av en blek, hvit farve på deres hud, da er det bare et ufarlig utslett som er brutt ut på huden; en sådan er ren. 
3:13:40: Når en mann mister håret på hodet, så han blir skallet, da er han ren; 
3:13:41: og når han mister håret fremme på hodet, så han blir fleinskallet, da er han ren. 
3:13:42: Men er det en lyserød flekk der hvor han er skallet, enten det er bak eller fremme på hodet, da er det spedalskhet som bryter ut på det skallete sted, på bakhodet eller forhodet. 
3:13:43: Og når presten ser på ham og finner at det er en lyserød knute på hans skallete bakhode eller forhode, av samme utseende som spedalskhet på huden, 
3:13:44: så er mannen spedalsk, han er uren; presten skal si ham uren; han har fått sykdommen på sitt hode. 
3:13:45: Den som er blitt rammet av spedalskhet, skal gå med sønderrevne klær og med uflidd hår; han skal tildekke sitt skjegg og rope: Uren, uren! 
3:13:46: Hele den tid han lider av sykdommen, skal han være uren; uren er han, han skal bo for sig selv, hans bolig skal være utenfor leiren. 
3:13:47: Når det kommer spedalskhet på et klæsplagg av ull eller av lin, 
3:13:48: eller på renning eller på islett av lin eller av ull eller på skinn eller på noget som er gjort av skinn, 
3:13:49: og flekkene på klæsplagget eller på skinnet eller på renningen eller på isletten eller på noget som er gjort av skinn, er grønnlige eller rødlige, da er det spedalskhet, og det skal vises frem for presten. 
3:13:50: Og når presten har sett på flekkene, skal han holde det som flekkene er på, innelåst i syv dager. 
3:13:51: Dersom han da den syvende dag ser at flekkene har bredt sig ut på klæsplagget eller på renningen eller på isletten eller på skinnet eller på noget som er gjort av skinn, da er det ondartet spedalskhet; det er urent. 
3:13:52: Og enten det er klæsplagget eller renningen eller isletten av ull eller av lin eller noget som er gjort av skinn, hvad det nu kan være, så skal det som flekkene er på brennes op; det er ondartet spedalskhet, det skal brennes op med ild. 
3:13:53: Men dersom presten, når han ser på det, finner at flekkene ikke har bredt sig ut på klæsplagget eller på renningen eller på isletten eller på det som er gjort av skinn, hvad det nu kan være, 
3:13:54: da skal han la det som flekkene er på tvette, og atter holde det innelåst i syv dager. 
3:13:55: Og når presten så ser på det, efterat det er tvettet, og han finner at flekkene ikke ser anderledes ut enn før, men at de heller ikke har bredt sig videre ut, da er det urent, du skal brenne det op med ild; det er av det slag som eter sig inn, på vrangen eller på retten. 
3:13:56: Men dersom presten, når han ser på det, finner at det sted som flekkene er på, er blitt blekt efter tvetningen, da skal han rive det av klæsplagget eller av skinnet eller av renningen eller av isletten. 
3:13:57: Men sees flekkene enda på klæsplagget eller på renningen eller på isletten eller på det som er gjort av skinn, hvad det nu kan være, da er det spedalskhet som bryter ut; du skal brenne det som flekkene er på op med ild. 
3:13:58: Men det klæsplagg eller den renning eller den islett eller de ting av skinn som flekkene går bort av når det tvettes, det skal atter tvettes, så er det rent. 
3:13:59: Dette er loven om spedalskhet på et klæsplagg av ull eller av lin eller på renning eller på islett eller på noget som er gjort av skinn, hvad det nu kan være - når det skal sies rent og når urent. 
3:14:1: Og Herren talte til Moses og sa: 
3:14:2: Dette er loven om den spedalske, når han skal renses: Han skal føres til presten; 
3:14:3: og presten skal gå utenfor leiren, og når da presten ser på ham og finner at han er helbredet for sin spedalskhet, 
3:14:4: da skal presten befale at det for den som lar sig rense, skal tas to levende rene fugler og sedertre og karmosinrød ull og isop, 
3:14:5: og la dem slakte den ene fugl over et lerkar som er fylt med rinnende vann. 
3:14:6: Så skal han ta den levende fugl og sedertreet og den karmosinrøde ull og isopen og dyppe dem sammen med den levende fugl i blodet av den fugl som er slaktet over det rinnende vann, 
3:14:7: og sprenge syv ganger på den som lar sig rense for spedalskhet, og således rense ham, og den levende fugl skal han la flyve sin vei bortover marken. 
3:14:8: Og den som lar sig rense, skal tvette sine klær og rake av alt sitt hår og bade sig i vann, så er han ren, og derefter kan han gå inn i leiren; men han skal holde sig utenfor sitt telt i syv dager. 
3:14:9: Den syvende dag skal han rake av alt sitt hår, både sitt hodehår og sitt skjegg og sine øienbryn - alt sitt hår skal han rake av, og han skal tvette sine klær og bade sitt legeme i vann, så er han ren. 
3:14:10: Den åttende dag skal han ta to værlam uten lyte og et årsgammelt hunlam uten lyte og til matoffer tre tiendedeler av en efa fint mel, blandet med olje, og så en log* olje. # <* et mål for flytende varer.> 
3:14:11: Og presten som forestår renselsen, skal stille den mann som lar sig rense, og de ting han skal ofre, frem for Herrens åsyn, ved inngangen til sammenkomstens telt. 
3:14:12: Så skal presten ta det ene værlam og bære det frem til skyldoffer sammen med den tilhørende log olje, og han skal svinge dem for Herrens åsyn. 
3:14:13: Værlammet skal slaktes på det sted hvor syndofferet og brennofferet blir slaktet, på det hellige sted; for likesom syndofferet, således hører også skyldofferet presten til, det er høihellig. 
3:14:14: Så skal presten ta av skyldofferets blod og stryke på den høire ørelapp på den som lar sig rense, og på hans høire tommelfinger og på hans høire stortå. 
3:14:15: Og presten skal ta av den tilhørende log olje og helle i sin venstre hånd 
3:14:16: og så dyppe sin høire pekefinger i oljen som han har i sin venstre hånd, og sprenge av oljen med sin finger syv ganger for Herrens åsyn. 
3:14:17: Og av resten av oljen som er i hans hånd, skal presten stryke noget på den høire ørelapp på den som lar sig rense, og på hans høire tommelfinger og på hans høire stortå, ovenpå blodet av skyldofferet. 
3:14:18: Og det som enda er tilovers av oljen som presten har i sin hånd, skal han helle på hodet på den som lar sig rense. Og således skal presten gjøre soning for ham for Herrens åsyn. 
3:14:19: Så skal presten ofre syndofferet og gjøre soning for den som lar sig rense for sin urenhet; og derefter skal han slakte brennofferet. 
3:14:20: Og presten skal ofre brennofferet og matofferet på alteret og således gjøre soning for ham, så er han ren. 
3:14:21: Men er det en fattig mann, som ikke har råd til så meget, da skal han ta et værlam til skyldoffer - det skal svinges til soning for ham - og en tiendedel av en efa fint mel, blandet med olje, til matoffer, og en log olje 
3:14:22: og to turtelduer eller to dueunger, efter som han har råd til; den ene skal være til syndoffer, og den andre til brennoffer. 
3:14:23: På den åttende dag av sin renselse skal han bære dette frem til presten, til inngangen til sammenkomstens telt, for Herrens åsyn. 
3:14:24: Og presten skal ta skyldofferlammet og den tilhørende log olje og svinge dem for Herrens åsyn. 
3:14:25: Og de skal slakte skyldofferlammet, og presten skal ta av skyldofferets blod og stryke på den høire ørelapp på den som lar sig rense, og på hans høire tommelfinger og på hans høire stortå. 
3:14:26: Så skal presten helle av oljen i sin venstre hånd, 
3:14:27: og med sin høire pekefinger skal han sprenge syv ganger for Herrens åsyn av oljen som han har i sin venstre hånd. 
3:14:28: Så skal presten stryke noget av oljen som han har i sin hånd, på den høire ørelapp på den som lar sig rense, og på hans høire tommelfinger og på hans høire stortå, ovenpå det sted hvor blodet av skyldofferet er. 
3:14:29: Og det som enda er tilovers av oljen som presten har i sin hånd, skal han helle på hodet på den som lar sig rense, for å gjøre soning for ham for Herrens åsyn. 
3:14:30: Så skal han ofre den ene av turtelduene eller av dueungene - det som han har råd til - 
3:14:31: som syndoffer og den andre som brennoffer sammen med matofferet; og presten skal gjøre soning for den som lar sig rense, for Herrens åsyn. 
3:14:32: Dette er loven om den som lider av spedalskhet, men ikke har råd til å ofre meget når han skal renses. 
3:14:33: Og Herren talte til Moses og Aron og sa: 
3:14:34: Når I kommer inn i Kana'ans land, som jeg gir eder til eiendom, og jeg lar spedalskhet komme på noget hus i det land I får i eie, 
3:14:35: da skal den som eier huset, gå og melde det til presten og si: Det ser ut for mig som det er kommet spedalskhet på huset. 
3:14:36: Så skal presten la huset rydde, før han selv kommer for å se på flekkene, så ikke alt det som er i huset, skal bli urent; og derefter skal han komme og se på huset. 
3:14:37: Dersom han da, når han ser på flekkene, finner at flekkene på husets vegger viser sig som grønnlige eller rødlige fordypninger som synes å ligge dypere enn veggen, 
3:14:38: da skal han gå ut av huset til husets dør, og han skal stenge huset og holde det tillåst i syv dager. 
3:14:39: Den syvende dag skal presten komme igjen, og når han da ser på det og finner at flekkene har bredt sig ut på husets vegger, 
3:14:40: da skal han la de stener som flekkene er på, brytes ut og kastes utenfor byen, på et urent sted. 
3:14:41: Derefter skal huset skrapes innvendig rundt omkring, og det støv som er skrapet av, skal kastes utenfor byen, på et urent sted. 
3:14:42: Så skal de ta andre stener og sette dem inn istedenfor de gamle stener, og de skal ta nytt ler og overstryke huset med. 
3:14:43: Bryter flekkene ut igjen på huset efterat stenene er tatt ut og huset skrapet og overstrøket, 
3:14:44: da skal presten komme og se på det, og dersom han da finner at flekkene har bredt sig ut på huset, da er det ondartet spedalskhet på huset; det er urent. 
3:14:45: Og da skal de rive huset ned, både stener og treverket og alt leret, og føre det utenfor byen, til et urent sted. 
3:14:46: Den som går inn i huset i hele den tid det holdes tillåst, skal være uren til om aftenen, 
3:14:47: og den som ligger i huset, skal tvette sine klær, og den som eter i huset, skal tvette sine klær. 
3:14:48: Men dersom presten, når han kommer og ser på det, finner at flekkene ikke har bredt sig ut på huset efterat det er blitt overstrøket, da skal han si huset rent; for da er ondet borte. 
3:14:49: Så skal han, forat huset kan bli renset, ta to fugler og sedertre og karmosinrød ull og isop. 
3:14:50: Den ene fugl skal han slakte over et lerkar som er fylt med rinnende vann, 
3:14:51: og så skal han ta sedertreet og isopen og den karmosinrøde ull og den levende fugl og dyppe dem i blodet av den fugl som er slaktet, og i det rinnende vann og sprenge på huset syv ganger. 
3:14:52: Og han skal rense huset med fuglens blod og med det rinnende vann og med den levende fugl og med sedertreet og med isopen og med den karmosinrøde ull 
3:14:53: og så la den levende fugl flyve utenfor byen, bortover marken; således skal han gjøre soning for huset, så det blir rent. 
3:14:54: Dette er loven om alle slags spedalskhet og om skurv 
3:14:55: og om spedalskhet på klær og på hus 
3:14:56: og om knuter i huden og om utslett og om lyse flekker på huden - 
3:14:57: til oplysning om når noget er urent, og når det er rent. Dette er loven om spedalskhet. 
3:15:1: Og Herren talte til Moses og Aron og sa: 
3:15:2: Tal til Israels barn og si til dem: Når det går ut flod fra en manns legeme, da er han uren for sitt flods skyld. 
3:15:3: Så lenge hans flod varer, er det således med hans urenhet: Enten hans legeme gir flodet fra sig eller holder det tilbake, i begge tilfelle er han uren. 
3:15:4: Hvert leie som den som har flod, ligger på, skal være urent, og alt det han sitter på, skal være urent. 
3:15:5: Den som rører ved hans leie, skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:6: Og den som setter sig på noget som den som har flod, sitter på, han skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:7: Og den som rører ved den sykes legeme, skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:8: Kommer den som har flod, til å spytte på en som er ren, da skal den han spyttet på, tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:9: Hver sal som den som har flod, rider på, skal være uren. 
3:15:10: Enhver som rører ved noget av det han har under sig, skal være uren til om aftenen, og den som bærer det bort, skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:11: Og enhver som den som har flod, rører ved uten å ha skyllet sine hender i vann, skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:12: Og et lerkar som den som har flod, rører ved, skal brytes i stykker, og ethvert trekar skal skylles i vann. 
3:15:13: Når den som har flod, blir ren for sitt flod, da skal han telle syv dager frem fra den dag han blev ren, og så tvette sine klær og bade sitt legeme i rinnende vann, så er han ren. 
3:15:14: Og den åttende dag skal han ta to turtelduer eller to dueunger og komme for Herrens åsyn, til inngangen til sammenkomstens telt, og gi dem til presten. 
3:15:15: Og presten skal ofre dem, den ene til syndoffer og den andre til brennoffer; og således skal presten gjøre soning for ham for Herrens åsyn og rense ham for hans flod. 
3:15:16: Når det går sæd fra en mann, da skal han bade hele sitt legeme i vann og være uren til om aftenen. 
3:15:17: Og ethvert klæsplagg og ethvert skinn som det kommer sæd på, skal tvettes i varm og være urent til om aftenen. 
3:15:18: Og når en mann ligger hos en kvinne, og det går sæd fra ham, da skal de begge bade sig i vann og være urene til om aftenen. 
3:15:19: Når en kvinne har flod, idet det flyter blod fra hennes legeme, da skal hun være uren i syv dager, og enhver som rører ved henne, skal være uren til om aftenen. 
3:15:20: Alt hun ligger på mens hun således er uren, skal være urent, og alt hun sitter på, skal være urent. 
3:15:21: Enhver som rører ved hennes leie, skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:22: Og enhver som rører ved noget hun sitter på, skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:23: Og dersom nogen rører ved noget som er på leiet eller på det hun sitter på, skal han være uren til om aftenen. 
3:15:24: Og dersom en mann ligger hos henne, og hennes urenhet kommer på ham, så skal han være uren i syv dager, og ethvert leie han ligger på, skal være urent. 
3:15:25: Når en kvinne har blodflod i mange dager, uten at det er hennes månedlige urenhets tid, eller hun har flod lenger enn sedvanlig i sin månedlige urenhets tid, da skal hun være uren alle de dager hennes urenhets flod varer, likesom i sin månedlige urenhets dager. 
3:15:26: Ethvert leie hun ligger på alle de dager hennes flod varer, skal være urent, likesom hennes leie i hennes månedlige urenhet, og alt hun sitter på, skal være urent, likesom når hun har sin månedlige urenhet. 
3:15:27: Enhver som rører ved det, skal være uren; han skal tvette sine klær og bade sig i vann og være uren til om aftenen. 
3:15:28: Men når hun blir ren for sitt flod, da skal hun telle syv dager frem, og derefter skal hun være ren. 
3:15:29: Og den åttende dag skal hun ta to turtelduer eller to dueunger og komme med dem til presten, til inngangen til sammenkomstens telt. 
3:15:30: Og presten skal ofre den ene til syndoffer og den andre til brennoffer; og således skal presten gjøre soning for henne for Herrens åsyn og rense henne for hennes urenhets flod. 
3:15:31: I skal skille Israels barn av med deres urenhet, forat de ikke skal dø i sin urenhet når de gjør mitt tabernakel urent, det som står midt iblandt dem. 
3:15:32: Dette er loven om den som har flod, og om den fra hvem det går sæd, så han blir uren derved, 
3:15:33: og om den kvinne som har sin månedlige urenhet, og om den som har flod, enten det er mann eller kvinne, og om den mann som ligger hos en uren kvinne. 
3:16:1: Og Herren talte til Moses efterat de to Arons sønner var døde, de som måtte dø dengang de trådte frem for Herrens åsyn. 
3:16:2: Og Herren sa til Moses: Tal til Aron, din bror, og si at han ikke til enhver tid må gå inn i helligdommen innenfor forhenget, frem til nådestolen som er over arken, forat han ikke skal dø; for jeg vil åpenbare mig i skyen over nådestolen. 
3:16:3: Dette skal Aron ha med sig når han går inn i helligdommen: en ung okse til syndoffer og en vær til brennoffer. 
3:16:4: Han skal klæ sig i en hellig underkjortel av lin og ha benklær av lin på sitt legeme og omgjorde sig med et belte av lin og binde en hue av lin på sitt hode; dette er de hellige klær, og han skal bade sitt legeme i vann før han klær sig i dem. 
3:16:5: Og av Israels barns menighet skal han få to gjetebukker til syndoffer og en vær til brennoffer. 
3:16:6: Så skal Aron føre frem sin egen syndoffer-okse og gjøre soning for sig og sitt hus. 
3:16:7: Og han skal ta de to gjetebukker og stille dem frem for Herrens åsyn, ved inngangen til sammenkomstens telt. 
3:16:8: Og Aron skal kaste lodd om de to gjetebukker, ett lodd for Herren og ett lodd for Asasel*. # <* den onde ånd.> 
3:16:9: Og den bukk som ved loddet er tilfalt Herren, skal Aron føre frem og ofre til syndoffer. 
3:16:10: Men den bukk som ved loddet er tilfalt Asasel, skal stilles levende frem for Herrens åsyn, forat det skal gjøres soning ved den, og så skal den sendes ut i ørkenen til Asasel. 
3:16:11: Når Aron fører frem sin egen syndoffer-okse og gjør soning for sig og sitt hus, skal han først slakte syndoffer-oksen. 
3:16:12: Så skal han fylle ildkaret med glør fra alteret for Herrens åsyn og sine hender med finstøtt velluktende røkelse og bære det innenfor forhenget. 
3:16:13: Og han skal legge røkelsen på ilden for Herrens åsyn, så skyen av røkelsen skjuler nådestolen, som er over vidnesbyrdet, forat han ikke skal dø. 
3:16:14: Så skal han ta noget av oksens blod og sprenge med sin finger på fremsiden av nådestolen, og foran nådestolen skal han sprenge noget av blodet syv ganger med sin finger. 
3:16:15: Derefter skal han slakte den bukk som skal være syndoffer for folket, og bære dens blod innenfor forhenget; han skal gjøre med dens blod likesom han gjorde med oksens blod, og sprenge det på nådestolen og foran nådestolen. 
3:16:16: Således skal han gjøre soning for helligdommen og rense den for Israels barns urenhet og for alle deres overtredelser som de har forsyndet sig med; og likeså skal han gjøre med sammenkomstens telt, som er reist blandt dem midt i deres urenhet. 
3:16:17: Og det må ikke være noget menneske i sammenkomstens telt når han går inn for å gjøre soning i helligdommen, og til han går ut igjen; og således skal han gjøre soning for sig og for sitt hus og for hele Israels menighet. 
3:16:18: Så skal han gå ut til alteret som er reist for Herrens åsyn, og gjøre soning for det; han skal ta av oksens blod og av bukkens blod og stryke rundt om på alterets horn 
3:16:19: og sprenge noget av blodet på det syv ganger med sin finger og således rense og hellige det fra Israels barns urenhet. 
3:16:20: Når han så har fullført soningen for helligdommen og for sammenkomstens telt og for alteret, da skal han føre frem den levende bukk. 
3:16:21: Og Aron skal legge begge sine hender på den levende bukks hode og bekjenne over den alle Israels barns misgjerninger og alle deres overtredelser som de har forsyndet sig med; han skal legge dem på bukkens hode og sende den ut i ørkenen med en mann som står rede dertil. 
3:16:22: Og bukken skal bære alle deres misgjerninger med sig ut i villmarken, og så skal han slippe bukken løs i ørkenen. 
3:16:23: Derefter skal Aron gå inn i sammenkomstens telt og ta av sig de linklær som han tok på da han gikk inn i helligdommen, og han skal la dem bli der. 
3:16:24: Så skal han bade sitt legeme i vann på et hellig sted og ta på sig sine andre klær og så gå ut og ofre sitt eget brennoffer og folkets brennoffer og gjøre soning for sig og for folket. 
3:16:25: Og fettet av syndofferet skal han brenne på alteret. 
3:16:26: Men den som førte bukken ut til Asasel, skal tvette sine klær og bade sitt legeme i vann; derefter kan han gå inn i leiren. 
3:16:27: Syndoffer-oksen og syndofferbukken, hvis blod blev båret inn for å gjøre soning i helligdommen, skal føres utenfor leiren, og deres skinn og deres kjøtt og deres skarn skal brennes op med ild. 
3:16:28: Og den som brenner det op, skal tvette sine klær og bade sitt legeme i vann; derefter kan han gå inn i leiren. 
3:16:29: Og dette skal være en evig lov for eder: I den syvende måned, på den tiende dag i måneden, skal I faste og ikke gjøre noget arbeid, hverken den innfødte eller den fremmede som bor iblandt eder. 
3:16:30: For på denne dag skal det gjøres soning for eder for å rense eder, så I blir rene for Herren for alle eders synder. 
3:16:31: En høihellig sabbat skal det være for eder, og da skal I faste - det skal være en evig lov. 
3:16:32: Og den prest som salves, og som innvies til å gjøre prestetjeneste i sin fars sted, skal utføre soningen; han skal klæ sig i linklærne, de hellige klær, 
3:16:33: og han skal gjøre soning for det Aller-helligste og gjøre soning for sammenkomstens telt og for alteret og gjøre soning for prestene og for hele det samlede folk. 
3:16:34: Og dette skal være en evig lov for eder, at det gjøres soning for Israels barn til renselse for alle deres synder én gang om året. Og Aron gjorde som Herren hadde befalt Moses. 
3:17:1: Og Herren talte til Moses og sa: 
3:17:2: Tal til Aron og hans sønner og til alle Israels barn og si til dem: Således har Herren befalt: 
3:17:3: Hver mann av Israels hus som slakter en okse eller et får eller en gjet enten i leiren eller utenfor leiren 
3:17:4: og ikke fører dem frem til inngangen til sammenkomstens telt for å bære frem et offer til Herren foran Herrens tabernakel, den mann skal det tilregnes som blodskyld, han har utøst blod; den mann skal utryddes av sitt folk. 
3:17:5: Derfor skal Israels barn komme til Herren med sine slaktedyr som de pleier å slakte på fri mark, og føre dem frem til inngangen til sammenkomstens telt, til presten, og ofre dem som takkoffer til Herren. 
3:17:6: Og presten skal sprenge blodet på Herrens alter ved inngangen til sammenkomstens telt og brenne fettet til en velbehagelig duft for Herren. 
3:17:7: Og de skal ikke mere ofre sine slaktoffer til de onde ånder som de driver avgudsdyrkelse med. Dette skal være en evig lov for dem, fra slekt til slekt. 
3:17:8: Og du skal si til dem: Når nogen mann av Israels hus eller av de fremmede som bor iblandt dem, ofrer et brennoffer eller slaktoffer 
3:17:9: og ikke fører det frem til inngangen til sammenkomstens telt for å ofre det til Herren, så skal den mann utryddes av sitt folk. 
3:17:10: Og når nogen av Israels hus eller av de fremmede som bor iblandt dem, eter blod, om aldri så lite, da vil jeg sette mitt åsyn mot den som eter blodet, og utrydde ham av hans folk; 
3:17:11: for kjøttets sjel er i blodet, og jeg har gitt eder det på alteret til å gjøre soning for eders sjeler; for blodet er det som gjør soning, fordi sjelen er I det. 
3:17:12: Derfor har jeg sagt til Israels barn: Ingen av eder skal ete blod, og den fremmede som bor iblandt eder, skal heller ikke ete blod. 
3:17:13: Og når nogen av Israels barn eller av de fremmede som bor iblandt dem, feller et vilt dyr eller en fugl som kan etes, da skal han la blodet renne ut og dekke det til med ord; 
3:17:14: for blodet er sjelen i alt kjøtt, fordi sjelen er i det; derfor sa jeg til Israels barn: I skal ikke ete blod av noget kjøtt, for blodet er sjelen i alt kjøtt; enhver som eter det, skal utryddes. 
3:17:15: Og enhver som eter noget selvdødt eller sønderrevet dyr, enten han er innfødt eller fremmed, skal tvette sine klær og bade sig i vann og være uren til om aftenen; så er han ren. 
3:17:16: Dersom han ikke tvetter sine klær og ikke bader sitt legeme, da skal han komme til å lide for sin misgjerning. 
3:18:1: Og Herren talte til Moses og sa: 
3:18:2: Tal til Israels barn og si til dem: Jeg er Herren eders Gud. 
3:18:3: I skal ikke gjøre som de gjør i Egyptens land, som I bodde i, og I skal ikke gjøre som de gjør i Kana'ans land, som jeg vil føre eder til, og I skal ikke vandre efter deres skikker. 
3:18:4: I skal gjøre efter mine bud, og I skal holde mine lover, så I følger dem; jeg er Herren eders Gud. 
3:18:5: Og I skal holde mine lover og mine bud, for det menneske som gjør efter dem, skal leve ved dem; jeg er Herren. 
3:18:6: Ingen av eder skal røre ved nogen kvinne av sin nære slekt for å ha omgang med henne; jeg er Herren. 
3:18:7: Din fars og din mors leie skal du ikke vanære; hun er din mor, du skal ikke ha omgang med henne. 
3:18:8: Din stedmor skal du ikke ha omgang med; hun er din fars hustru. 
3:18:9: Din søster, enten det er din fars datter eller din mors datter, enten hun er født hjemme eller født ute, skal du ikke ha omgang med. 
3:18:10: Din sønnedatter eller din datterdatter skal du ikke ha omgang med; for det er ditt eget kjød. 
3:18:11: Din stedmors datter, som også er din fars datter, skal du ikke ha omgang med; hun er din søster. 
3:18:12: Din fars søster skal du ikke ha omgang med; hun er din fars nære slekt. 
3:18:13: Din mors søster skal du ikke ha omgang med; for hun er din mors nære slekt. 
3:18:14: Din farbrors leie skal du ikke vanære, hans hustru skal du ikke komme for nær; hun er din fars søster. 
3:18:15: Din sønnekone skal du ikke ha omgang med; hun er din sønns hustru, du skal ikke ha omgang med henne. 
3:18:16: Din brors hustru skal du ikke ha omgang med - ikke vanære din brors leie. 
3:18:17: Du skal ikke ha omgang med en kvinne og tillike med hennes datter; hennes sønnedatter eller hennes datterdatter skal du heller ikke ta og ha omgang med; de er hennes nære slekt, det er skammelig ferd. 
3:18:18: Du skal ikke ta din hustrus søster til ekte mens din hustru lever, så du vekker fiendskap mellem dem ved å ha omgang med dem begge. 
3:18:19: En kvinne som er uren i sin månedlige svakhet, skal du ikke gå inn til og ha omgang med. 
3:18:20: Din næstes hustru skal du ikke ha samleie med, sa du blir uren ved henne. 
3:18:21: Du skal ikke gi noget av dine barn fra dig til ildoffer for Molok*; du skal ikke vanhellige din Guds navn; jeg er Herren. # <* ammonittenes avgud, til hvis ære barn blev opbrent.> 
3:18:22: Hos en mann skal du ikke ligge som en ligger hos en kvinne; det er en vederstyggelighet. 
3:18:23: Du skal ikke ha omgang med noget dyr, for da blir du uren; heller ikke skal en kvinne la noget dyr ha omgang med sig; det er skamløs ferd. 
3:18:24: Gjør eder ikke urene med noget sådant! For alt dette gjorde de sig urene med de hedninger som jeg driver ut for eders øine, 
3:18:25: og landet blev urent, og jeg hjemsøker det for dets misgjerning, så landet utspyr sine innbyggere. 
3:18:26: Men I skal holde mine lover og mine bud og ikke gjøre nogen av disse vederstyggelige gjerninger, hverken den innfødte eller den fremmede som bor iblandt eder; 
3:18:27: for alle disse vederstyggelige gjerninger har landets innbyggere gjort, de som var der før eder, og således blev landet urent. 
3:18:28: Ellers kommer landet til å utspy eder, fordi I gjør det urent, likesom det utspyr det folk som har vært der før eder. 
3:18:29: For hver den som gjør nogen av alle disse vederstyggelige gjerninger, skal utryddes av sitt folk, hver og en som gjør sådant. 
3:18:30: Så skal I da ta vare på det jeg vil ha varetatt, så I ikke gjør efter nogen av de vederstyggelige skikker som de har fulgt før eder, og ikke fører urenhet over eder ved dem; jeg er Herren eders Gud. 
3:19:1: Og Herren talte til Moses og sa: 
3:19:2: Tal til hele Israels barns menighet og si til dem: I skal være hellige; for jeg, Herren eders Gud, er hellig. 
3:19:3: I skal ha ærefrykt enhver for sin mor og sin far, og I skal holde mine sabbater; jeg er Herren eders Gud. 
3:19:4: I skal ikke vende eder til avgudene og ikke gjøre eder støpte guder; jeg er Herren eders Gud. 
3:19:5: Når I vil ofre takkoffer til Herren, da skal I ofre det således at han kan ha velbehag i eder. 
3:19:6: Den dag I ofrer det, skal det etes, eller og dagen efter; men det som blir tilovers til den tredje dag, skal brennes op med ild. 
3:19:7: Dersom det etes på den tredje dag, da er det en vederstyggelighet; Herren har da ikke velbehag i det. 
3:19:8: Og den som eter det, kommer til å lide for sin misgjerning, for han har vanhelliget det som var helliget Herren; han skal utryddes av sitt folk. 
3:19:9: Når I høster grøden i eders land, så skal du ikke skjære kornet helt ut til den ytterste kant av din aker, og de aks som blir liggende efter innhøstingen, skal du ikke sanke op, 
3:19:10: Og du skal ikke holde efterhøst i din vingård og ikke sanke op de nedfalne bær i din vingård; du skal la dem ligge til den fattige og den fremmede; jeg er Herren eders Gud. 
3:19:11: I skal ikke stjele, og I skal ikke lyve, og ingen av eder skal gå svikefullt frem mot sin næste. 
3:19:12: I skal ikke sverge på løgn ved mitt navn, så du vanhelliger din Guds navn; jeg er Herren. 
3:19:13: Du skal ikke frata din næste noget med urett og ikke rane noget fra ham; du skal ikke la en dagarbeiders lønn bli natten over hos dig til om morgenen. 
3:19:14: Du skal ikke banne en døv og ikke legge støt for en blind, men du skal frykte din Gud; jeg er Herren. 
3:19:15: I skal ikke gjøre urett i dommen; du skal ikke holde med nogen fordi han er ringe, og ikke gi nogen rett fordi han er mektig; du skal dømme din næste med rettferdighet. 
3:19:16: Du skal ikke gå omkring og baktale folk, du skal ikke stå din næste efter livet; jeg er Herren. 
3:19:17: Du skal ikke hate din bror i ditt hjerte, men du skal irettesette din næste, forat du ikke skal få synd på dig for hans skyld. 
3:19:18: Du skal ikke hevne dig og ikke gjemme på vrede mot ditt folks barn, men du skal elske din næste som dig selv; jeg er Herren. 
3:19:19: I skal holde mine lover: Du skal ikke la to slags dyr av ditt fe parre sig med hverandre; du skal ikke så to slags sæd på din mark, og klær som er vevd av to slags garn, skal ikke komme på dig. 
3:19:20: Når en mann ligger hos en kvinne og har omgang med henne, og hun er trælkvinne og festet til en annen mann, men ikke løskjøpt eller frigitt, da skal de straffes, men de skal ikke bøte med livet, fordi hun ikke var frigitt. 
3:19:21: Men han skal føre sitt skyldoffer frem for Herren, til inngangen til sammenkomstens telt, en skyldoffer-vær. 
3:19:22: Og med skyldoffer-væren skal presten gjøre soning for ham for Herrens åsyn, for den synd han har gjort; så får han forlatelse for den synd han har gjort. 
3:19:23: Når I kommer inn i landet og planter alle slags frukttrær, da skal I holde deres første frukt for uren; i tre år skal de være urene for eder, og I skal ikke ete av dem. 
3:19:24: I det ferde år skal all deres frukt vies til Herren i en gledesfest, 
3:19:25: og først i det femte år kan I ete deres frukt - forat de siden kan bære dess mere for eder; jeg er Herren eders Gud. 
3:19:26: I skal ikke ete noget med blodet i. I skal ikke gi eder av med å tyde varsler eller spå av skyene. 
3:19:27: I skal ikke rundskjære eders hår; heller ikke skal du klippe ditt skjegg kort. 
3:19:28: I skal ikke skjære i eders kjøtt av sorg over en avdød, og ikke brenne inn skrifttegn på eder; jeg er Herren. 
3:19:29: Du skal ikke vanhellige din datter ved å la henne drive hor, forat ikke landet skal drive hor og bli fullt av skjensel. 
3:19:30: Mine sabbater skal I holde og ha ærefrykt for min helligdom; jeg er Herren. 
3:19:31: I skal ikke vende eder til dødningemanere og ikke ty til sannsigere, så I gjør eder urene ved dem; jeg er Herren eders Gud. 
3:19:32: For de grå hår skal du reise dig og ære den gamle, og du skal frykte din Gud; jeg er Herren. 
3:19:33: Når en fremmed bor hos eder i eders land, da skal I ikke undertrykke ham. 
3:19:34: Den fremmede som bor hos eder, skal regnes som en innfødt blandt eder, og du skal elske ham som dig selv, for I har selv vært fremmede i Egyptens land; jeg er Herren eders Gud. 
3:19:35: I skal ikke gjøre urett i dom, i lengdemål, i vekt eller i hulmål. 
3:19:36: Rette vektskåler, rette vektlodder, rett efa og rett hin skal I ha; jeg er Herren eders Gud, som førte eder ut av Egyptens land. 
3:19:37: I skal ta vare på alle mine lover og alle mine bud og holde dem; jeg er Herren. 
3:20:1: Og Herren talte til Moses og sa: 
3:20:2: Du skal si til Israels barn: Om nogen av Israels barn eller av de fremmede som bor i Israel, gir noget av sine barn fra sig til Molok, skal han late livet; folket i landet skal stene ham. 
3:20:3: Jeg vil sette mitt åsyn mot den mann og utrydde ham av hans folk fordi han har gitt sitt barn fra sig til Molok og gjort min helligdom uren og vanhelliget mitt hellige navn. 
3:20:4: Om folket i landet lukker øinene til og ikke straffer en sådan mann med døden når han gir sitt barn fra sig til Molok, 
3:20:5: da vil jeg sette mitt åsyn mot denne mann og hans slekt, og ham og alle dem som følger ham i å drive avgudsdyrkelse med Molok, vil jeg utrydde av deres folk. 
3:20:6: Når nogen vender sig til dødningemanere og sannsigere og driver avgudsdyrkelse med dem, da vil jeg sette mitt åsyn mot den mann og utrydde ham av hans folk. 
3:20:7: I skal hellige eder og være hellige; for jeg er Herren eders Gud. 
3:20:8: Og I skal ta vare på mine lover og holde dem; jeg er Herren, som helliger eder. 
3:20:9: Hver den som banner sin far eller sin mor, skal late livet; sin far og sin mor har han bannet, hans blod være over ham! 
3:20:10: Når en mann driver hor med en annen manns hustru - med sin næstes hustru, da skal de begge late livet, både mannen og kvinnen som har drevet hor. 
3:20:11: Når en mann ligger hos sin fars hustru, har han vanæret sin fars leie; de skal begge late livet, deres blod være over dem! 
3:20:12: Når en mann ligger hos sin sønnekone, skal de begge late livet; de har gjort en vederstyggelig gjerning, deres blod være over dem! 
3:20:13: Når en mann ligger hos en annen mann som en ligger hos en kvinne, da har de begge gjort en vederstyggelig gjerning; de skal late livet, deres blod være over dem! 
3:20:14: Når en mann tar både mor og datter til ekte, da er det skammelig ferd; både han og kvinnene skal brennes med ild; slik skam skal ikke finnes blandt eder. 
3:20:15: En mann som har omgang med et dyr, skal late livet, og dyret skal I drepe. 
3:20:16: Når en kvinne kommer nær noget dyr og har omgang med det, da skal du slå ihjel både kvinnen og dyret; de skal late livet, deres blod være over dem! 
3:20:17: Når en mann tar sin søster, sin fars eller sin mors datter, til ekte og har omgang med henne, og hun med ham, da er det skammelig ferd, og de skal utryddes for sitt folks øine; han har hatt omgang med sin søster, han skal lide for sin misgjerning. 
3:20:18: Når en mann ligger hos en kvinne som har sin månedlige urenhet, og har omgang med henne, da har han avdekket hennes kilde, og hun har avdekket sitt blods kilde; de skal begge utryddes av sitt folk. 
3:20:19: Din mors eller din fars søster skal du ikke ha omgang med, for den som gjør dette, vanærer sin nærmeste slekt; de skal lide for sin misgjerning. 
3:20:20: Når en mann ligger hos sin farbrors hustru, har han vanæret sin farbror; de skal lide for sin synd, de skal dø barnløse. 
3:20:21: Når en mann tar sin brors hustru, da er det skamløs ferd; han har vanæret sin bror, de skal være barnløse. 
3:20:22: I skal ta vare på alle mine lover og alle mine bud og holde dem, forat ikke landet skal utspy eder, det land som jeg fører eder til og vil la eder bo i, 
3:20:23: og I skal ikke følge det folks skikker som jeg driver ut for eder; for alt dette har de gjort, og jeg vemmedes ved dem, 
3:20:24: og jeg sa til eder: I skal ta deres land i eie, og jeg vil gi eder det til eiendom, et land som flyter med melk og honning; jeg er Herren eders Gud, som har skilt eder ut fra folkene. 
3:20:25: Derfor skal I skille mellem rene dyr og urene og mellem urene fugler og rene og ikke gjøre eder selv vederstyggelige ved å ete de firføtte dyr eller de fugler eller det kryp på jorden som jeg har skilt ut for eder, forat I skal holde dem for urene. 
3:20:26: I skal være hellige for mig; for jeg, Herren, er hellig, og jeg har skilt eder ut fra folkene, forat I skal høre mig til. 
3:20:27: Når det i en mann eller kvinne er en dødningemaner-ånd eller en sannsiger-ånd, da skal de late livet; de skal stenes, deres blod være over dem! 
3:21:1: Og Herren sa til Moses: Tal til prestene, Arons sønner, og si til dem: En prest skal ikke gjøre sig uren blandt sitt folk ved å røre et lik, 
3:21:2: undtagen det er hans nærmeste slekt, hans mor, hans far, hans sønn, hans datter, hans bror 
3:21:3: eller hans ugifte søster, som ennu hører til ætten og ikke er blitt nogen manns hustru; ved henne kan han gjøre sig uren. 
3:21:4: Han skal ikke gjøre sig uren som ektemann, så han vanhelliger sig blandt sitt folk. 
3:21:5: De skal ikke rake sig skallet på nogen del av sitt hode og ikke klippe sitt skjegg kort og ikke skjære i sitt kjøtt. 
3:21:6: De skal være hellige for sin Gud og ikke vanhellige sin Guds navn; for de bærer frem Herrens ildoffer, sin Guds mat, derfor skal de være hellige. 
3:21:7: En skjøge eller en kvinne som er vanæret, skal de ikke ta til ekte, og heller ikke en kvinne som hennes mann har skilt sig fra; for presten er hellig for sin Gud. 
3:21:8: Og du skal holde ham hellig, for han bærer frem din Guds mat; han skal være hellig for dig, for jeg, Herren, som helliger eder, er hellig. 
3:21:9: Og dersom en prests datter vanhelliger sig ved å drive hor, så vanhelliger hun sin far; hun skal brennes med ild. 
3:21:10: Den som er yppersteprest blandt sine brødre, han på hvis hode salvings-oljen er utøst, og som er innvidd og har iklædd sig de hellige klær, han skal ikke rake sitt hode og ikke sønderrive sine klær. 
3:21:11: Han skal ikke gå inn til noget lik; ikke engang ved sin far eller sin mor skal han føre urenhet over sig. 
3:21:12: Han skal ikke gå ut av helligdommen for ikke å vanhellige sin Guds helligdom; for innvielsen han fikk ved sin Guds salvings-olje, er over ham; jeg er Herren. 
3:21:13: Til hustru skal han ta en ren jomfru; 
3:21:14: en enke eller en fraskilt eller en vanæret kvinne eller en skjøge - nogen sådan må han ikke gifte sig med; bare en jomfru av sitt folk skal han ta til hustru. 
3:21:15: Han skal ikke vanhellige sin ætt blandt sitt folk; for jeg er Herren, som helliger ham. 
3:21:16: Og Herren talte til Moses og sa: 
3:21:17: Tal til Aron og si: Ingen av din ætt, enten nu eller i kommende tider, som har noget lyte, skal trede nær for å bære frem sin Guds mat. 
3:21:18: Ingen som har noget lyte, skal trede nær, ingen som er blind eller halt eller har noget ansiktslyte eller et lem som er for stort, 
3:21:19: ingen som har brukket fot eller hånd, 
3:21:20: eller som er pukkelrygget eller dverg eller har en hvit flekk på øiet eller har skabb eller noget annet utslett, eller hvis stener er knust. 
3:21:21: Ingen av Arons, prestens, ætt, som har noget lyte, skal trede nær for å bære frem Herrens ildoffer; han har et lyte, han skal ikke trede nær for å bære frem sin Guds mat. 
3:21:22: Sin Guds mat kan han nok ete, både av de høihellige og av de hellige gaver; 
3:21:23: men han må ikke gå frem til forhenget og ikke trede nær til alteret, fordi han har et lyte; han skal ikke vanhellige mine helligdommer, for jeg er Herren, som helliger dem. 
3:21:24: Og Moses sa dette til Aron og hans sønner og til alle Israels barn. 
3:22:1: Og Herren talte til Moses og sa: 
3:22:2: Tal til Aron og hans sønner og si at de skal se sig vel for med de hellige gaver som Israels barn helliger mig, og ikke vanhellige mitt hellige navn; jeg er Herren. 
3:22:3: Si til dem: Dersom nogen av hele eders ætt, nu eller i kommende tider, kommer nær de hellige gaver som Israels barn helliger Herren, mens han har nogen urenhet på sig, så skal han utryddes fra mitt åsyn; jeg er Herren. 
3:22:4: Ingen av Arons ætt som er spedalsk eller har flod, skal ete av de hellige gaver før han er blitt ren, ikke heller den som rører ved nogen som er blitt uren ved lik, eller den fra hvem det går sæd, 
3:22:5: eller den som rører ved noget kryp som en blir uren ved, eller ved et menneske som en blir uren ved, hvad urenhet det enn er. 
3:22:6: Den som rører ved noget sådant, skal være uren til om aftenen, og han skal ikke ete av de hellige gaver før han har badet sitt legeme i vann. 
3:22:7: Når solen er gått ned, er han ren, og derefter kan han ete av de hellige gaver; for det er hans mat. 
3:22:8: Det som er selvdødt eller sønderrevet, skal han ikke ete, for da blir han uren; jeg er Herren. 
3:22:9: De skal ta vare på det jeg vil ha varetatt, forat de ikke skal bære synd for noget sådant og dø fordi de vanhelliger det hellige; jeg er Herren, som helliger dem. 
3:22:10: Ingen fremmed* skal ete det hellige; en som har sitt tilhold hos presten eller er dagarbeider hos ham, må ikke ete det hellige. # <* d.e. som ikke hører til en presteslekt.> 
3:22:11: Men når en prest kjøper en træl for sine penger, da kan trælen ete av det, likeså den som er født i hans hus; de kan ete av hans mat. 
3:22:12: Når en prests datter blir en fremmed manns hustru, skal hun ikke ete av de hellige offergaver; 
3:22:13: men blir hun enke, eller er hun skilt fra sin mann, og hun ingen barn har, og hun vender tilbake til sin fars hus og bor hos ham likesom i sin ungdom, da kan hun ete av sin fars mat; men ingen fremmed skal ete av den. 
3:22:14: Når nogen eter noget hellig og ikke vet om det, skal han godtgjøre presten det hellige og legge femtedelen til. 
3:22:15: Prestene skal ikke vanhellige de hellige gaver som Israels barn ofrer til Herren, 
3:22:16: og således føre over dem misgjerning og skyld, når de eter deres hellige gaver; for jeg er Herren, som helliger dem. 
3:22:17: Og Herren talte til Moses og sa: 
3:22:18: Tal til Aron og hans sønner og til alle Israels barn og si til dem: Når nogen av Israels hus eller av de fremmede i Israel vil ofre sitt offer, enten det er et lovet offer eller et frivillig offer de vil ofre til Herren som brennoffer, 
3:22:19: da skal I gjøre det således at Herren kan ha velbehag i eder: Det skal være en han uten lyte av storfeet, av fårene eller av gjetene. 
3:22:20: Noget som det er lyte på, må I ikke ofre; for da har Herren ikke velbehag i eder. 
3:22:21: Og når nogen vil ofre Herren et takkoffer av storfeet eller av småfeet, enten for å opfylle et løfte eller for å gi et frivillig offer, da skal det være uten lyte, forat Herren kan ha velbehag i det; der skal aldeles ikke være noget lyte på det. 
3:22:22: Dyr som er blinde eller benbrutt eller lemlestet, eller som har verkesår eller skabb eller annet utslett, skal I ikke ofre til Herren, og I skal ikke legge ildoffer av dem på alteret for Herren. 
3:22:23: Et stykke storfe eller småfe som har et lem som er for stort eller for lite, kan du ofre som frivillig offer, men som lovet offer har Herren ikke velbehag i det. 
3:22:24: Et dyr som er gildet ved å trykkes eller knuses eller rives eller skjæres, skal I ikke bære frem for Herren; sådant* skal I ikke gjøre i eders land. # <* d.e. gilde noget dyr.> 
3:22:25: Heller ikke av en utlendings hånd skal I motta og ofre sådanne dyr som matoffer til eders Gud; for de er utskjemt, det er lyte på dem; med dem vinner I ikke Herrens velbehag. 
3:22:26: Og Herren talte til Moses og sa: 
3:22:27: Når en kalv eller et lam eller et kje fødes, da skal de bli Syv dager hos moren; men fra den åttende dag av er de velbehagelige for Herren som ildoffer for ham. 
3:22:28: I skal ikke slakte et stykke storfe eller småfe på samme dag som dets unge. 
3:22:29: Når I ofrer Herren et lovprisnings-offer, skal I gjøre det således at Herren kan ha velbehag i eder. 
3:22:30: Det skal etes samme dag, I skal ikke levne noget av det til om morgenen; jeg er Herren. 
3:22:31: I skal ta vare på mine bud og holde dem; jeg er Herren. 
3:22:32: I skal ikke vanhellige mitt hellige navn, for jeg vil være helliget blandt Israels barn; jeg er Herren, som helliger eder, 
3:22:33: som førte eder ut av Egyptens land for å være eders Gud; jeg er Herren. 
3:23:1: Og Herren talte til Moses og sa: 
3:23:2: Tal til Israels barn og si til dem: Dette er Herrens høitider - mine høitider - som I skal utrope som hellige sammenkomster: 
3:23:3: I seks dager skal I arbeide; men på den syvende dag er det høihellig sabbat, en hellig sammenkomst; da skal I ikke gjøre noget arbeid; det er sabbat for Herren i alle eders hjem. 
3:23:4: Dette er Herrens høitider, de hellige sammenkomster, som I skal utrope til deres fastsatte tider: 
3:23:5: I den første måned, på den fjortende dag i måneden, mellem de to aftenstunder, er det påske for Herren. 
3:23:6: Og på den femtende dag i samme måned er det de usyrede brøds høitid for Herren; i syv dager skal I ete usyret brød. 
3:23:7: På den første dag skal I holde en hellig sammenkomst, I skal ikke gjøre nogen arbeidsgjerning. 
3:23:8: I syv dager skal I ofre ildoffer for Herren; på den syvende dag skal det være en hellig sammenkomst, I skal ikke gjøre nogen arbeidsgjerning. 
3:23:9: Og Herren talte til Moses og sa: 
3:23:10: Tal til Israels barn og si til dem: Når I kommer inn i det land som jeg vil gi eder, og I høster dets grøde, da skal I komme til presten med det første kornbånd av eders høst. 
3:23:11: Og han skal svinge kornbåndet for Herrens åsyn, forat Herren kan ha velbehag i eder; dagen efter sabbaten skal presten svinge det. 
3:23:12: Og samme dag som I svinger kornbåndet, skal I ofre et årsgammelt lam uten lyte til brennoffer for Herren 
3:23:13: og matofferet som hører til: to tiendedeler av en efa fint mel, blandet med olje, til ildoffer for Herren, til en velbehagelig duft, og drikkofferet som hører til: fjerdedelen av en hin vin. 
3:23:14: Og I skal ikke ete brød eller ristet eller friskt korn før den dag - før I har båret frem offeret for eders Gud; det skal være en evig lov for eder, fra slekt til slekt, i alle eders hjem. 
3:23:15: Fra dagen efter sabbaten, fra den dag I bærer frem svinge-kornbåndet, skal I telle fulle syv uker; 
3:23:16: femti dager skal I telle til dagen efter den syvende sabbat, og da skal I bære frem for Herren et offer av den nye grøde: 
3:23:17: Fra eders hjemsteder skal I komme med to svingebrød, som er laget av to tiendedeler av en efa fint mel og bakt med surdeig; de er en førstegrøde for Herren. 
3:23:18: Og sammen med brødet skal I føre frem syv årsgamle lam uten lyte og en oksekalv og to værer - de skal være til brennoffer for Herren - og matofferet og drikkofferne som hører til; det er et ildoffer til velbehagelig duft for Herren. 
3:23:19: Og I skal ofre en gjetebukk til syndoffer og to årsgamle lam til takkoffer. 
3:23:20: Og presten skal svinge dem sammen med førstegrødens brød og to lam for Herrens åsyn; de skal være Herren helliget og tilhøre presten. 
3:23:21: Og samme dag skal I la utrope at det skal holdes en hellig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning; det skal være en evig lov for eder, fra slekt til slekt, hvor I så bor. 
3:23:22: Og når I høster grøden i eders land, skal du ikke under din innhøsting skjære kornet helt ut til ytterste kant av din aker, og de aks som blir liggende efter innhøstingen, skal du ikke sanke op; du skal la dem være igjen til den fattige og den fremmede; jeg er Herren eders Gud. 
3:23:23: Og Herren talte til Moses og sa: 
3:23:24: Tal til Israels barn og si: I den syvende måned, på den første dag i måneden, skal I holde hviledag med basunklang til ihukommelse og en hellig sammenkomst. 
3:23:25: Da skal I ikke gjøre nogen arbeidsgjerning, og I skal ofre ildoffer til Herren. 
3:23:26: Og Herren talte til Moses og sa: 
3:23:27: Men på den tiende dag i den samme syvende måned er det soningsdag; da skal I holde en hellig sammenkomst, og I skal faste og ofre ildoffer til Herren. 
3:23:28: Den dag skal I ikke gjøre noget arbeid; for det er en sonings-dag; da skal det gjøres soning for eder for Herrens, eders Guds åsyn. 
3:23:29: For enhver som ikke faster den dag, skal utryddes av sitt folk. 
3:23:30: Og enhver som gjør noget arbeid den dag, han skal utryddes av sitt folk. 
3:23:31: Intet arbeid må I gjøre den dag; det skal være en evig lov for eder, fra slekt til slekt, hvor I så bor. 
3:23:32: En høihellig sabbat skal den være for eder, og I skal faste; på den niende dag i måneden om aftenen, fra den aften til den næste, skal I holde eders sabbatshvile. 
3:23:33: Og Herren talte til Moses og sa: 
3:23:34: Tal til Israels barn og si: På den femtende dag i denne samme syvende måned skal løvsalenes fest holdes for Herren, og den skal vare syv dager. 
3:23:35: På den første dag skal det være en hellig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning. 
3:23:36: I syv dager skal I ofre ildoffer til Herren; på den åttende dag skal I holde en hellig sammenkomst og ofre ildoffer til Herren; det er en festsammenkomst; I skal ikke gjøre nogen arbeidsgjerning. 
3:23:37: Dette er Herrens høitider; dem skal I utrope som hellige sammenkomster, og på dem skal I ofre Herren ildoffer, brennoffer og matoffer, slaktoffer og drikkoffer, hver dag det som hører dagen til, 
3:23:38: foruten Herrens sabbater og foruten eders gaver og foruten alle eders lovede offer og foruten alle eders frivillige offer som I gir Herren. 
3:23:39: Men på den femtende dag i den syvende måned, når I innsamler landets grøde, skal I holde Herrens fest, og den skal vare i syv dager; den første dag skal være hviledag, og den åttende dag skal være hviledag. 
3:23:40: Og den første dag skal I ta frukter av fagre trær, palmegrener og kvister av løvrike trær og siljer som vokser ved bekkene; og I skal være glade for Herrens, eders Guds åsyn i syv dager. 
3:23:41: Denne høitid skal I holde som en fest for Herren syv dager om året; det skal være en evig lov for eder, fra slekt til slekt; i den syvende måned skal I holde den. 
3:23:42: I skal bo i løvhytter i syv dager, alle innfødte i Israel skal bo i løvhytter, 
3:23:43: forat eders efterkommere skal vite at jeg lot Israels barn bo i løvhytter da jeg førte dem ut av Egyptens land; jeg er Herren eders Gud. 
3:23:44: Og Moses kunngjorde Herrens høitider for Israels barn. 
3:24:1: Og Herren talte til Moses og sa: 
3:24:2: Byd Israels barn at de skal la dig få ren olje av støtte oliven til lysestaken, så lampene kan settes op til enhver tid. 
3:24:3: Utenfor vidnesbyrdets forheng i sammenkomstens telt skal Aron alltid holde dem i stand fra aften til morgen for Herrens åsyn; det skal være en evig lov for eder, fra slekt til slekt. 
3:24:4: På lysestaken av rent gull skal han alltid holde lampene i stand for Herrens åsyn. 
3:24:5: Du skal ta fint mel og bake tolv kaker av det; hver kake skal være to tiendedeler av en efa. 
3:24:6: Og du skal legge dem i to rader, seks i hver rad, på bordet av rent gull for Herrens åsyn. 
3:24:7: Og du skal legge ren virak på hver rad, og den skal være et ihukommelses-offer for brødene, et ildoffer for Herren. 
3:24:8: På hver sabbatsdag skal brødene gjennem alle tider legges frem for Herrens åsyn; det skal være en gave fra Israels barn - en evig pakt. 
3:24:9: De skal høre Aron og hans sønner til, og de skal ete dem på et hellig sted; for de er høihellige og er hans del av Herrens ildoffer - en evig rettighet. 
3:24:10: En mann som var sønn av en israelittisk kvinne, men hadde en egypter til far, gikk engang ut blandt Israels barn. Da kom den israelittiske kvinnes sønn og en israelittisk mann i trette med hverandre i leiren, 
3:24:11: og den israelittiske kvinnes sønn spottet Herrens navn og bannet det, og de førte ham til Moses. Hans mor hette Selomit, Dibris datter, av Dans stamme. 
3:24:12: De satte ham fast, forat de kunde få avgjørelse ved et ord fra Herren. 
3:24:13: Og Herren talte til Moses og sa: 
3:24:14: Før spotteren utenfor leiren, og alle de som hørte det, skal legge sine hender på hans hode, og hele menigheten skal stene ham. 
3:24:15: Og du skal tale til Israels barn og si: Når nogen banner sin Gud, skal han lide for sin synd. 
3:24:16: Og den som spotter Herrens navn, skal late livet, hele menigheten skal stene ham; enten det er en fremmed eller en innfødt, skal han stenes når han spotter Herrens navn. 
3:24:17: Når en slår et menneske ihjel, skal han late livet. 
3:24:18: Men den som slår et stykke fe ihjel, skal godtgjøre det, liv for liv. 
3:24:19: Når nogen volder sin næste mén på hans legeme, skal der gjøres det samme med ham som han selv har gjort: 
3:24:20: brudd for brudd, øie for øie, tann for tann; det samme mén som han volder en annen, skal han selv få. 
3:24:21: Den som slår et stykke fe ihjel, skal godtgjøre det; men den som slår et menneske ihjel, skal late livet. 
3:24:22: En og samme rett skal gjelde for eder, den skal gjelde for den fremmede som for den innfødte; for jeg er Herren eders Gud. 
3:24:23: Og Moses sa dette til Israels barn, og de førte spotteren utenfor leiren og stenet ham - Israels barn gjorde som Herren hadde befalt Moses. 
3:25:1: Og Herren talte til Moses på Sinai berg og sa: 
3:25:2: Tal til Israels barn og si til dem: Når I kommer inn i det land jeg vil gi eder, da skal landet ha en sabbatshvile for Herren. 
3:25:3: I seks år skal du tilså din aker, og i seks år skal du stelle med din vingård og høste landets grøde. 
3:25:4: Men i det syvende år skal det være en høihellig sabbat for landet, en sabbat for Herren; da skal du ikke tilså din aker og ikke stelle med din vingård. 
3:25:5: Det som vokser av sig selv efterat du har høstet, skal du ikke skjære, og druene på ditt ustelte vintre skal du ikke sanke; det skal være et sabbatsår for landet. 
3:25:6: Og det som vokser i landets sabbatsår, skal være til føde for eder, for dig og din tjener og din tjenestepike og din dagarbeider og de fremmede som holder til hos dig; 
3:25:7: og din buskap og de ville dyr som er i ditt land, skal også ete av all den grøde som vokser der. 
3:25:8: Så skal du telle frem syv sabbatsår, syv ganger syv år, så tiden for de syv sabbatsår blir ni og firti år. 
3:25:9: Og i den syvende måned, på den tiende dag i måneden, skal du la basunen lyde gjennem landet; på soningsdagen skal I la basunen lyde gjennem hele eders land. 
3:25:10: Og I skal holde det femtiende år hellig og utrope frihet i landet for alle dem som bor der; det skal være et jubelår for eder, og I skal komme tilbake, enhver til sin eiendom og enhver til sin slekt. 
3:25:11: Et jubelår skal dette femtiende år være for eder; ikke skal I så, og ikke skal I høste det som vokser av sig selv i det år, og ikke skal I sanke druene av de ustelte vintrær i det år. 
3:25:12: For det er jubelår, hellig skal det være for eder; av marken skal I ete det den bærer. 
3:25:13: I dette jubelår skal I komme tilbake, enhver til sin eiendom. 
3:25:14: Når du selger noget til din næste, eller du kjøper noget av din næste, da skal I ikke gjøre hverandre urett. 
3:25:15: Efter tallet av år siden jubelåret skal du kjøpe av din næste; efter tallet av innhøstings-årene skal han selge til dig. 
3:25:16: For et større tall av år skal du gjøre kjøpesummen større, og for et mindre tall av år skal du gjøre kjøpesummen mindre; for det er bare et visst tall av avlinger* han selger til dig. # <* d.e. ikke jorden selv, men dens avlinger inntil jubelåret.> 
3:25:17: Og ingen av eder skal gjøre sin næste urett, men du skal frykte din Gud; for jeg er Herren eders Gud. 
3:25:18: I skal holde mine lover og ta vare på mine bud og holde dem; så skal I bo trygt i landet. 
3:25:19: Og landet skal gi sin frukt, og I skal ete og bli mette og bo der trygt. 
3:25:20: Og om I sier: Hvad skal vi ete i det syvende år, når vi ikke sår og ikke samler inn vår grøde? - 
3:25:21: da vil jeg sende min velsignelse over eder i det sjette år, så det gir grøde for tre år. 
3:25:22: I det åttende år skal I så, men ennu ete av den gamle grøde; like til det niende års grøde kommer, skal I ete gammelt korn. 
3:25:23: Jorden må ikke selges for alle tider; for mig hører landet til, I er fremmede og gjester hos mig. 
3:25:24: I hele det land I får til eie, skal I tillate innløsning av jorden. 
3:25:25: Når din bror blir fattig og må selge noget av sin jordeiendom, da skal hans løser, hans nærmeste frende, komme og innløse det som hans bror har solgt. 
3:25:26: Og om en ikke har nogen løser, men selv får råd til det og kommer til å eie det som skal til for å innløse det, 
3:25:27: da skal han regne efter hvor mange år det er gått siden han solgte, og bare betale det overskytende til den mann han solgte til; så skal han komme til sin jordeiendom igjen. 
3:25:28: Men dersom han ikke har nok til å betale ham med, da skal det han har solgt, bli i dens eie som kjøpte det, helt til jubelåret; men i jubelåret skal det gis fritt, så han kommer til sin jordeiendom igjen. 
3:25:29: Når nogen selger et våningshus i en by som er omgitt med murer, da skal hans innløsningsrett vare inntil et helt år er gått efterat han har solgt; et år skal hans innløsningsrett vare. 
3:25:30: Men dersom det ikke blir innløst innen et helt år er til ende, da skal huset, om det ligger i en by med murer omkring, for alle tider tilhøre den som kjøpte det, og hans efterkommere; det skal ikke gis fritt i jubelåret. 
3:25:31: Men husene i småbyer som ikke har murer omkring, skal regnes like med jordene på landet; de skal kunne innløses, og i jubelåret skal de gis fri. 
3:25:32: Men gjelder det huser i byer som hører levittene til, da skal innløsningsretten vare gjennem alle tider. 
3:25:33: Når en kjøper noget av en levitt, så skal et hus som er solgt, såfremt det ligger i hans egen by, gis fritt i jubelåret; for husene i levittenes byer er deres eiendom blandt Israels barn. 
3:25:34: Men løkkene omkring deres byer må ikke selges; for de er deres eiendom for all tid. 
3:25:35: Når din bror blir fattig og ikke lenger kan holde sig oppe, da skal du støtte ham; som en fremmed og en innerst skal han leve hos dig. 
3:25:36: Du må ikke ta rente eller overmål av ham, men du skal frykte din Gud, så din bror kan leve med dig. 
3:25:37: Du skal ikke låne ham penger mot rente og ikke kreve noget i tillegg for den mat du låner ham. 
3:25:38: Jeg er Herren eders Gud, som førte eder ut av Egyptens land for å gi eder Kana'ans land og være eders Gud. 
3:25:39: Når din bror blir fattig og selger sig til dig, da skal du ikke la ham tjene hos dig som en træl tjener. 
3:25:40: Han skal være hos dig som en dagarbeider eller innerst og tjene hos dig til jubelåret. 
3:25:41: Da skal han flytte fra dig, både han og hans barn, og dra hjem til sin slekt; til sin fedrene-eiendom skal han vende tilbake. 
3:25:42: For de er mine tjenere, som jeg har ført ut av Egyptens land; de skal ikke selges som en træl selges. 
3:25:43: Du skal ikke herske over ham med hårdhet, men du skal frykte din Gud. 
3:25:44: Men vil du få dig en træl eller en trælkvinne, da skal I kjøpe dem av de hedningefolk som bor omkring eder, 
3:25:45: og likeledes blandt barna av de innerster som bor hos eder som fremmede; blandt dem og deres efterkommere, som bor hos eder og er født i eders land, kan I kjøpe træler eller trælkvinner, og de skal være eders eiendom, 
3:25:46: og I kan efterlate dem som arv til eders barn efter eder, så de kan ha dem til eiendom; I kan for all tid bruke dem som træler. Men over eders brødre, Israels barn, skal du ikke herske med hårdhet, bror over bror. 
3:25:47: Når en fremmed eller innerst hos dig kommer til velstand, og en av dine brødre som bor nær ham, blir fattig og må selge sig til den fremmede som har tilhold hos dig, eller til en som stammer fra en fremmed manns slekt, 
3:25:48: da skal han kunne løses ut efterat han har solgt sig. En av hans brødre skal løse ham ut, 
3:25:49: eller hans farbror eller hans farbrors sønn eller en annen av de nærmeste slektninger i hans ætt, skal løse ham ut, eller får han råd til det, da skal han løse sig selv ut. 
3:25:50: Når han da gjør op med den som har kjøpt ham, skal de regne ut tiden fra det år han solgte sig til ham, og til jubelåret, og pengene som han solgte sig for, skal deles med årenes tall; den tid han har vært hos ham, skal regnes som om han hadde vært dagarbeider. 
3:25:51: Dersom det ennu er mange år tilbake, da skal han til utløsning betale tilbake en tilsvarende del av de penger han blev kjøpt for. 
3:25:52: Men dersom det er få år tilbake til jubelåret, så skal han gjøre op med ham efter dem; efter sine tjeneste-år skal han betale sin løsningssum. 
3:25:53: Som en dagarbeider som tjener år for år, skal han være hos ham; du må ikke tåle at han hersker over ham med hårdhet. 
3:25:54: Men dersom han ikke løses ut på en av disse måter, da skal han bli fri i jubelåret, både han og hans barn. 
3:25:55: For mig hører Israels barn til som tjenere, de er mine tjenere, som jeg har ført ut av Egyptens land; jeg er Herren eders Gud. 
3:26:1: I skal ikke gjøre eder avguder og ikke reise op utskårne billeder eller støtter og ikke sette stener med billeder i eders land for å tilbede ved dem; for jeg er Herren eders Gud. 
3:26:2: Mine sabbater skal I holde, og for min helligdom skal I ha ærefrykt; jeg er Herren. 
3:26:3: Dersom I vandrer i mine lover og tar vare på mine bud og holder dem, 
3:26:4: da vil jeg gi eder regn i rette tid, og jorden skal gi sin grøde, og markens trær skal gi sin frukt. 
3:26:5: Og tresketiden skal vare hos eder like til vinhøsten, og vinhøsten skal vare inntil kornet såes, og I skal ete eder mette av brødet som I har avlet, og bo trygt i eders land. 
3:26:6: Og jeg vil gi fred i landet, og I skal hvile i ro, og ingen skal forferde eder; jeg vil rydde ut de ville dyr av landet, og sverd skal ikke fare gjennem eders land. 
3:26:7: Og I skal forfølge eders fiender, og de skal falle for eders sverd. 
3:26:8: Fem av eder skal forfølge hundre, og hundre av eder skal forfølge ti tusen, og eders fiender skal falle for eders sverd. 
3:26:9: Og jeg vil vende mig til eder og gjøre eder fruktbare og tallrike, - og jeg vil holde min pakt med eder. 
3:26:10: Og I skal ha gammel grøde å ete inntil I må føre den bort for den nye grødes skyld. 
3:26:11: Og jeg vil sette min bolig midt iblandt eder, og jeg skal aldri forkaste eder. 
3:26:12: Og jeg vil vandre midt iblandt eder, og jeg vil være eders Gud, og I skal være mitt folk. 
3:26:13: Jeg er Herren eders Gud, som førte eder ut av Egyptens land, forat I ikke mere skulde være deres træler, og jeg brøt sønder eders åks stenger og lot eder gå opreist. 
3:26:14: Men dersom I ikke lyder mig og ikke holder alle disse bud, 
3:26:15: dersom I forakter mine forskrifter, og dersom I forkaster mine lover, så I ikke holder alle mine bud, men bryter min pakt, 
3:26:16: da vil også jeg gjøre således mot eder: Jeg vil hjemsøke eder med redsler, med tærende sott og brennende feber, så eders øine slukner og sjelen vansmekter; til ingen nytte skal I så eders sæd, eders fiender skal ete den op. 
3:26:17: Og jeg vil sette mitt åsyn mot eder, og eders fiender skal slå eder, og de som hater eder, skal herske over eder, og I skal flykte skjønt ingen forfølger eder. 
3:26:18: Og dersom I enda ikke lyder mig, da vil jeg tukte eder syvfold mere for eders synder. 
3:26:19: Jeg vil bryte eders styrkes overmot, og jeg vil gjøre eders himmel som jern og eders jord som kobber. 
3:26:20: Til ingen nytte skal I fortære eders kraft, og eders land skal ikke gi sin grøde, og trærne i landet skal ikke gi sin frukt. 
3:26:21: Og dersom I enda står mig imot og ikke vil lyde mig, da vil jeg slå eder syvfold mere, som eders synder fortjener. 
3:26:22: Jeg vil sende ville dyr imot eder, og de skal røve eders barn fra eder og ødelegge eders fe og forminske eders tall, og eders veier skal bli øde. 
3:26:23: Og dersom I enda ikke lar eder tukte av mig, men står mig imot, 
3:26:24: da vil også jeg stå eder imot og slå eder syvfold mere for eders synder. 
3:26:25: Jeg vil føre over eder et sverd som hevner pakten*, og samler I eder i eders byer, da vil jeg sende pest inn iblandt eder, og I skal gis i fiendens hånd. # <* d.e. pakten som I brøt.> 
3:26:26: Når jeg bryter sønder brødets stav for eder, da skal ti kvinner bake eders brød i én ovn og gi eder brødet igjen efter vekt, og I skal ete og ikke bli mette. 
3:26:27: Og dersom I enda ikke lyder mig, men står mig imot, 
3:26:28: da vil også jeg stå eder imot i vrede og tukte eder syvfold mere for eders synder. 
3:26:29: I skal ete eders sønners kjøtt og ete eders døtres kjøtt. 
3:26:30: Og jeg vil ødelegge eders offerhauger og utrydde eders solstøtter og kaste eders døde kropper ovenpå de døde kropper av eders vederstyggelige avguder, og min sjel skal vemmes ved eder. 
3:26:31: Og jeg vil gjøre eders byer til en ørken og legge eders helligdommer øde, og jeg vil ikke nyte vellukten av eders offer. 
3:26:32: Og jeg vil legge landet øde, så eders fiender som bor der, skal forferdes over det. 
3:26:33: Men eder vil jeg sprede blandt hedningene, og med draget sverd vil jeg forfølge eder; eders land skal bli øde og eders byer en ørken. 
3:26:34: Da skal landet gjøre fyldest for sine sabbatsår hele den tid det ligger øde, mens I er i eders fienders land; da skal landet hvile og gjøre fyldest for sine sabbatsår. 
3:26:35: Hele den tid det ligger øde, skal det ha hvile, den hvile som det ikke fikk i eders sabbatsår, da I bodde der. 
3:26:36: Og de som blir igjen av eder, dem vil jeg gi et motløst hjerte i sine fienders land, så lyden av et henværet blad vil jage dem, og de skal flykte som en flykter for sverd, og falle skjønt ingen forfølger dem. 
3:26:37: Og de skal falle, den ene over den andre, som for sverd skjønt ingen forfølger dem; og I skal ikke holde stand mot eders fiender. 
3:26:38: Og I skal gå til grunne blandt hedningene, og eders fienders land skal fortære eder. 
3:26:39: Og de som blir igjen av eder, skal visne bort i eders fienders land for sin misgjernings skyld; også for sine fedres misgjerninger, som hviler på dem, skal de visne bort. 
3:26:40: Da skal de bekjenne sin misgjerning og sine fedres misgjerning, som de gjorde i sin troløshet mot mig, og bekjenne at de stod mig imot 
3:26:41: - derfor stod også jeg dem imot og førte dem inn i deres fienders land - ja, da skal deres uomskårne hjerte ydmyke sig, og de skal bøte for sin misgjerning. 
3:26:42: Og så vil jeg komme i hu min pakt med Jakob, og min pakt med Isak og min pakt med Abraham vil jeg komme i hu, og landet vil jeg komme i hu. 
3:26:43: Men landet skal først være forlatt av dem og gjøre fyldest for sine sabbatsår, mens det ligger øde og forlatt av dem, og de skal bøte for sin misgjerning, efterdi de foraktet mine lover og forkastet mine bud. 
3:26:44: Men selv da, når de er i sine fienders land, vil jeg ikke støte dem bort og ikke forkaste dem, så jeg skulde tilintetgjøre dem og bryte min pakt med dem; for jeg er Herren deres Gud. 
3:26:45: Og jeg vil til beste for dem komme i hu pakten med fedrene, som jeg førte ut av Egyptens land midt for hedningenes øine for å være deres Gud; jeg er Herren. 
3:26:46: Dette er de forskrifter og de bud og de lover som Herren satte mellem sig og Israels barn på Sinai berg ved Moses. 
3:27:1: Og Herren talte til Moses og sa: 
3:27:2: Tal til Israels barn og si til dem: Når nogen gjør et hellig løfte efter det verd du setter på folk som helliger sig til Herren, 
3:27:3: da skal du verdsette en mann som er mellem tyve og seksti år gammel, til femti sekel sølv efter helligdommens sekel. 
3:27:4: Men er det en kvinne, da skal du verdsette henne til tretti sekel. 
3:27:5: Er det en som er mellem fem år og tyve år gammel, da skal du verdsette en som er av mannkjønn, til tyve sekel, og en som er av kvinnekjønn, til ti sekel. 
3:27:6: Er det en som er mellem en måned og fem år gammel, da skal du verdsette en gutt til fem sekel sølv og en pike til tre sekel sølv. 
3:27:7: Er det en som er seksti år gammel eller derover, da skal du, dersom det er en mann, verdsette ham til femten sekel, og en kvinne til ti sekel. 
3:27:8: Men dersom nogen er for fattig til å betale efter din verdsetning, da skal han stilles frem for presten, og presten skal verdsette ham; efter som den som har gjort løftet, har råd til, skal presten verdsette ham. 
3:27:9: Dersom det er dyr, sådanne som en ofrer til Herren, da skal alt det en gir Herren av sådant, være hellig. 
3:27:10: En skal ikke gi noget annet i stedet og ikke bytte det, hverken et godt med et dårlig eller et dårlig med et godt; bytter nogen et dyr med et annet, da skal både det og det som det byttes med, være hellig. 
3:27:11: Men dersom det er et eller annet urent dyr, som en ikke ofrer til Herren, da skal dyret stilles frem for presten, 
3:27:12: og presten skal verdsette det efter som det er, godt eller dårlig; som du, prest, verdsetter det, således skal det være. 
3:27:13: Men vil nogen innløse det, da skal han gi femtedelen mere enn det du har verdsatt det til. 
3:27:14: Når nogen helliger sitt hus som gave til Herren, da skal presten verdsette det efter som det er, godt eller dårlig; som presten verdsetter, til hvis arvejord i landet den har således skal det stå ved makt. 
3:27:15: Men dersom den som har helliget sitt hus, vil innløse det, da skal han gi femtedelen mere enn du har verdsatt det til, så blir det hans. 
3:27:16: Dersom nogen helliger Herren noget av sin jordeiendom, da skal du verdsette det efter utsæden, en homer byggsæd til femti sekel sølv. 
3:27:17: Dersom han helliger sin jord fra jubelåret av, da skal din verdsetning stå ved makt. 
3:27:18: Men dersom han helliger sin jord efter jubelåret, da skal presten utregne pengene efter de år som er tilbake til næste jubelår; og der skal gjøres avkortning i det verd du har satt. 
3:27:19: Vil den som har helliget jorden, innløse den, da skal han gi femtedelen mere enn du har verdsatt den til, så skal den være hans for godt. 
3:27:20: Men dersom han ikke innløser jorden, eller han selger den til en annen, da kan den ikke innløses mere. 
3:27:21: Men når jorden gis fri i jubelåret, skal den være helliget Herren, likesom bannlyst jord; den skal høre presten til som hans eiendom. 
3:27:22: Men dersom nogen helliger Herren et jordstykke som han har kjøpt, som ikke har hørt til den jordeiendom han har arvet, 
3:27:23: da skal presten utregne for ham hvor høit det skal verdsettes, efter tiden inntil jubelåret, og han skal samme dag gi det du verdsetter det til; det skal være helliget Herren. 
3:27:24: I jubelåret skal jorden komme tilbake til den han har kjøpt den av, til hvis arvejord i lander har hørt. 
3:27:25: All din verdsetning skal skje efter helligdommens sekel; sekelen skal være tyve gera. 
3:27:26: Det førstefødte i buskapen skal ikke nogen hellige; som førstefødt hører det Herren til, enten det er et stykke storfe eller småfe, hører det Herren til. 
3:27:27: Men dersom det er av de urene dyr, da skal en innløse det efter din verdsetning og legge femtedelen til; dersom det ikke innløses, da skal det selges efter din verdsetning. 
3:27:28: Men intet bannlyst som nogen vier til Herren av alt det han eier, må selges eller innløses, enten det er folk eller fe eller jordeiendom; alt bannlyst er høihellig for Herren. 
3:27:29: Intet menneske som lyses i bann, skal løses; han skal late livet. 
3:27:30: All landets tiende av jordens sæd og av trærnes frukt hører Herren til; den er helliget Herren. 
3:27:31: Vil nogen innløse noget av sin tiende, da skal han legge femtedelen til. 
3:27:32: Og all tiende av storfe og av småfe, av alt det som går under hyrdestaven - hvert tiende stykke - skal være helliget Herren. 
3:27:33: En skal ikke se efter om det er godt eller dårlig, og ikke bytte det; bytter en det, da skal både det og det som det byttes med, være hellig; det skal ikke innløses. 
3:27:34: Dette er de bud som Herren gav Moses på Sinai berg og bød ham kunngjøre for Israels barn. 
4:1:1: Og Herren talte til Moses i Sinai ørken i sammenkomstens telt på den første dag i den annen måned i det annet år efterat de var gått ut av Egyptens land, og sa: 
4:1:2: Ta op manntall over hele Israels barns menighet efter deres ætter og familier og skriv op deres navn - alle som er av mannkjønn, en for en, 
4:1:3: fra tyveårsalderen og opover; alle dem i Israel som kan dra ut i krig, skal I mønstre, hær efter hær, du og Aron. 
4:1:4: I skal ha med eder en mann for hver stamme, den som er overhode for stammens familier. 
4:1:5: Dette er navnene på de menn som I skal ha til hjelp: For Ruben: Elisur, Sede'urs sønn, 
4:1:6: for Simeon: Selumiel, Surisaddais sønn, 
4:1:7: for Juda: Nahson, Amminadabs sønn, 
4:1:8: for Issakar: Netanel, Suars sønn, 
4:1:9: for Sebulon: Eliab, Helons sønn, 
4:1:10: for Josefs sønner, for Efra'im: Elisama, Ammihuds sønn, for Manasse: Gamliel, Pedasurs sønn, 
4:1:11: for Benjamin: Abidan, Gideonis sønn, 
4:1:12: for Dan: Akieser, Ammisaddais sønn, 
4:1:13: for Aser: Pagiel, Okrans sønn, 
4:1:14: for Gad: Eljasaf, De'uels sønn, 
4:1:15: for Naftali: Akira, Enans sønn. 
4:1:16: Dette var menighetens utkårne, høvdingene for sine fedrenestammer, overhodene for Israels tusener. 
4:1:17: Da lot Moses og Aron disse menn, som var nevnt ved navn, komme, 
4:1:18: og de samlet hele menigheten på den første dag i den annen måned; og de lot sig innføre i ættelistene med sine navn, efter sine ætter og familier, fra tyveårsalderen og opover, en for en, 
4:1:19: således som Herren hadde befalt Moses; og han mønstret dem i Sinai ørken. 
4:1:20: Efterkommerne av Rubens sønner - han som var Israels førstefødte - opskrevet efter sine ætter og familier, med sine navn, en for en, alt mannkjønn fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:21: så mange som blev mønstret av Rubens stamme, var seks og firti tusen og fem hundre. 
4:1:22: Efterkommerne av Simeons sønner, opskrevet efter sine ætter og familier, med sine navn, så mange av dem som blev mønstret, en for en, alt mannkjønn fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:23: så mange som blev mønstret av Simeons stamme, var ni og femti tusen og tre hundre. 
4:1:24: Efterkommerne av Gads sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:25: så mange som blev mønstret av Gads stamme, var fem og firti tusen, seks hundre og femti. 
4:1:26: Efterkommerne av Judas sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:27: så mange som blev mønstret av Juda stamme, var fire og sytti tusen og seks hundre. 
4:1:28: Efterkommerne av Issakars sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:29: så mange som blev mønstret av Issakars stamme, var fire og femti tusen og fire hundre. 
4:1:30: Efterkommerne av Sebulons sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:31: så mange som blev mønstret av Sebulons stamme, var syv og femti tusen og fire hundre. 
4:1:32: Josefs barn: Efterkommerne av Efra'ims sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:33: så mange som blev mønstret av Efra'ims stamme, var firti tusen og fem hundre; 
4:1:34: efterkommerne av Manasses sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:35: så mange som blev mønstret av Manasse stamme, var to og tretti tusen og to hundre. 
4:1:36: Efterkommerne av Benjamins sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:37: så mange som blev mønstret av Benjamins stamme, var fem og tretti tusen og fire hundre. 
4:1:38: Efterkommerne av Dans sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:39: så mange som blev mønstret av Dans stamme, var to og seksti tusen og syv hundre. 
4:1:40: Efterkommerne av Asers sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:41: så mange som blev mønstret av Asers stamme, var en og firti tusen og fem hundre. 
4:1:42: Efterkommerne av Naftalis sønner, opskrevet efter sine ætter og familier, med sine navn, fra tyveårsalderen og opover, alle som kunde dra ut i krig, 
4:1:43: så mange som blev mønstret av Naftali stamme, var tre og femti tusen og fire hundre. 
4:1:44: Dette var de som blev mønstret, de som Moses og Aron og Israels høvdinger mønstret, og høvdingene var tolv i tallet, en for hver stamme. 
4:1:45: Og alle de av Israels barn som blev mønstret efter sine familier, fra tyveårsalderen og opover, alle i Israel som kunde dra ut i krig, 
4:1:46: så mange som blev mønstret, var seks hundre og tre tusen, fem hundre og femti; 
4:1:47: Men levittene efter sin fedrenestamme blev ikke mønstret sammen med dem. 
4:1:48: For Herren talte til Moses og sa: 
4:1:49: Bare Levi stamme skal du ikke mønstre, og over dem skal du ikke opta manntall sammen med de andre Israels barn. 
4:1:50: Men du skal sette levittene over vidnesbyrdets tabernakel og over alle dets redskaper og over alt som hører til; de skal bære tabernaklet og alle dets redskaper, og de skal tjene ved tabernaklet og leire sig rundt omkring det. 
4:1:51: Når tabernaklet skal bryte op, skal levittene ta det ned, og når tabernaklet skal leire sig, skal levittene reise det op; kommer nogen fremmed*; # <* d.e. en som ikke er levitt.> 
4:1:52: Israels barn skal leire sig, hver i sin leir og hver ved sitt banner, hær for hær. 
4:1:53: Men levittene skal leire sig rundt omkring vidnesbyrdets tabernakel, forat det ikke skal komme vrede over Israels barns menighet; og levittene skal ta vare på det som er å vareta ved vidnesbyrdets tabernakel. 
4:1:54: Og Israels barn gjorde så; de gjorde i ett og alt således som Herren hadde befalt Moses. 
4:2:1: Og Herren talte til Moses og Aron og sa: 
4:2:2: Israels barn skal leire sig, hver ved sitt banner, ved sin families hærmerke; de skal leire sig midt imot sammenkomstens telt, rundt omkring det. 
4:2:3: På fremsiden, mot øst, skal Juda leire sig under sitt banner, hær for hær; og høvdingen for Judas barn er Nahson, Amminadabs sønn, 
4:2:4: og hans hær, så mange av dem som er mønstret, er fire og sytti tusen og seks hundre. 
4:2:5: Ved siden av ham skal Issakars stamme leire sig; og høvdingen for Issakars barn er Netanel, Suars sønn, 
4:2:6: og hans hær, så mange av dem som er mønstret, er fire og femti tusen og fire hundre. 
4:2:7: Likeså Sebulons stamme; og høvdingen for Sebulons barn er Eliab, Helons sønn, 
4:2:8: og hans hær, så mange av dem som er mønstret, er syv og femti tusen og fire hundre. 
4:2:9: Alle som er mønstret av Judas leir, hær for hær, er hundre og seks og åtti tusen og fire hundre; de skal være den første fylking som bryter op. 
4:2:10: Mot syd skal Ruben leire sig under sitt banner, hær for hær; og høvdingen for Rubens barn er Elisur, Sede'urs sønn, 
4:2:11: og hans hær, så mange av dem som er mønstret, er seks og firti tusen og fem hundre. 
4:2:12: Ved siden av ham skal Simeons stamme leire sig; og høvdingen for Simeons barn er Selumiel, Surisaddais sønn, 
4:2:13: og hans hær, så mange av dem som er mønstret, er ni og femti tusen og tre hundre. 
4:2:14: Likeså Gads stamme; og høvdingen for Gads barn er Eljasaf, Re'uels sønn, 
4:2:15: og hans hær, så mange av dem som er mønstret, er fem og firti tusen, seks hundre og femti. 
4:2:16: Alle som er mønstret av Rubens leir, hær for hær, er hundre og en og femti tusen, fire hundre og femti; de skal være den annen fylking som bryter op. 
4:2:17: Så skal sammenkomstens telt bryte op, levittenes leir i midten av leirene; de skal bryte op efter som de har leiret sig, hver på sin plass under sine banner. 
4:2:18: Mot vest skal Efra'im leire sig under sitt banner, hær for hær; og høvdingen for Efra'ims barn er Elisama, Ammihuds sønn, 
4:2:19: og hans hær, så mange av dem som er mønstret, er firti tusen og fem hundre. 
4:2:20: Ved siden av ham Manasse stamme; og høvdingen for Manasses barn er Gamliel, Pedasurs sønn, 
4:2:21: og hans hær, så mange av dem som er mønstret, er to og tretti tusen og to hundre. 
4:2:22: Likeså Benjamins stamme; og høvdingen for Benjamins barn er Abidan, Gideonis sønn, 
4:2:23: og hans hær, så mange av dem som er mønstret, er fem og tretti tusen og fire hundre. 
4:2:24: Alle som er mønstret av Efra'ims leir, hær for hær, er hundre og åtte tusen og et hundre; de skal være den tredje fylking som bryter op. 
4:2:25: Mot nord skal Dan leire sig under sitt banner, hær for hær; og høvdingen for Dans barn er Akieser, Ammisaddais sønn, 
4:2:26: og hans hær, så mange av dem som er mønstret, er to og seksti tusen og syv hundre. 
4:2:27: Ved siden av ham skal Asers stamme leire sig; og høvdingen for Asers barn er Pagiel, Okrans sønn, 
4:2:28: og hans hær, så mange av dem som er mønstret, er en og firti tusen og fem hundre. 
4:2:29: Likeså Naftali stamme; og høvdingen for Naftalis barn er Akira, Enans sønn, 
4:2:30: og hans hær, så mange av dem som er mønstret, er tre og femti tusen og fire hundre. 
4:2:31: Alle som er mønstret av Dans leir, er hundre og syv og femti tusen og seks hundre; de skal være den siste fylking som bryter op under sine banner. 
4:2:32: Dette var de av Israels barn som blev mønstret, efter sine familier; i alt var det seks hundre og tre tusen, fem hundre og femti mann som blev mønstret i leirene, hær for hær. 
4:2:33: Men levittene blev ikke mønstret sammen med de andre Israels barn, således som Herren hadde befalt Moses. 
4:2:34: Og Israels barn gjorde så; de leiret sig under sine banner og brøt op, enhver efter sin ætt og sin familie, i alle deler således som Herren hadde befalt Moses. 
4:3:1: Dette var Arons og Moses' ætt på den tid Herren talte med Moses på Sinai berg. 
4:3:2: Navnene på Arons sønner var: Nadab, som var den førstefødte, dernæst Abihu, Eleasar og Itamar. 
4:3:3: Dette var navnene på Arons sønner, de salvede prester, som var innvidd til å gjøre prestetjeneste. 
4:3:4: Men Nadab og Abihu døde for Herrens åsyn, dengang de bar fremmed ild inn for Herrens åsyn i Sinai ørken, og de hadde ingen sønner; siden utførte Eleasar og Itamar prestetjenesten for Arons, sin fars øine. 
4:3:5: Og Herren talte til Moses og sa: 
4:3:6: La Levi stamme komme hit og still den frem for Aron, presten, så de kan tjene ham. 
4:3:7: Og de skal ta vare på det han og hele menigheten skulde varetatt foran sammenkomstens telt, og således utføre tjenesten ved tabernaklet. 
4:3:8: Og de skal ta vare på alt som hører til sammenkomstens telt, og på det som Israels barn skulde varetatt; og således skal de utføre tjenesten ved tabernaklet. 
4:3:9: Du skal gi levittene til Aron og hans sønner; blandt Israels barn skal de være helt overgitt til ham. 
4:3:10: Men Aron og hans sønner skal du sette til å ta vare på sin prestetjeneste; kommer en fremmed nær til, skal han late livet. 
4:3:11: Og Herren talte til Moses og sa: 
4:3:12: Se, jeg har tatt levittene ut blandt Israels barn i stedet for alle førstefødte, dem som åpner morsliv blandt Israels barn, forat levittene skal høre mig til. 
4:3:13: For mig hører alt førstefødt til; den dag jeg slo alt førstefødt i Egyptens land, helliget jeg mig alt som førstefødt er i Israel, både folk og fe; de skal høre mig til, mig, Herren. 
4:3:14: Og Herren talte til Moses i Sinai ørken og sa: 
4:3:15: Du skal mønstre Levis barn efter deres familier og ætter! Alt mannkjønn, fra den som er en måned gammel, og opover, skal du mønstre. 
4:3:16: Så mønstret Moses dem efter Herrens ord, som det var befalt ham. 
4:3:17: Navnene på Levis sønner var: Gerson og Kahat og Merari. 
4:3:18: Og navnene på Gersons sønner efter deres ætter var: Libni og Sime'i. 
4:3:19: Og Kahats sønner efter sine ætter var Amram og Jishar, Hebron og Ussiel. 
4:3:20: Og Meraris sønner efter sine ætter var Mahli og Musi. Dette var Levis ætter med sine familier. 
4:3:21: Til Gerson hørte libnittenes ætt og sime'ittenes ætt; dette var gersonittenes ætter. 
4:3:22: De av dem som blev mønstret, idet der blev tatt tall på alt mannkjønn, fra den som var en måned gammel, og opover, var syv tusen og fem hundre. 
4:3:23: Gersonittenes ætter hadde sin leir bak tabernaklet, mot vest. 
4:3:24: Og høvdingen for gersonittenes familie var Eljasaf, Laels sønn. 
4:3:25: Og det som Gersons barn hadde å ta vare på ved sammenkomstens telt, var selve tabernaklet med dekke og varetak og dekket for inngangen til sammenkomstens telt, 
4:3:26: og omhengene til forgården og forhenget for inngangen til forgården som er rundt om tabernaklet og alteret, og dets snorer og alt arbeidet derved. 
4:3:27: Til Kahat hørte amramittenes ætt og jisharittenes ætt og hebronittenes ætt og ussielittenes ætt; dette var kahatittenes ætter. 
4:3:28: Da der blev tatt tall på alt mannkjønn, fra den som var en måned gammel, og opover, var de åtte tusen og seks hundre mann, som skulde ta vare på det som var å vareta ved helligdommen. 
4:3:29: Kahats barns ætter hadde sin leir ved siden av tabernaklet mot syd. 
4:3:30: Og høvdingen for Kahat-ættenes familie var Elisafan, Ussiels sønn. 
4:3:31: Og det de hadde å ta vare på, var arken og bordet og lysestaken og alterne og helligdommens redskaper som bruktes ved tjenesten, og forhenget* og alt arbeidet derved. # <* for det Aller-helligste.> 
4:3:32: Men den øverste høvding for levittene var Eleasar, sønn til Aron, presten; han var satt over dem som skulde ta vare på det som var å vareta ved helligdommen. 
4:3:33: Til Merari hørte mahlittenes ætt og musittenes ætt; dette var merarittenes ætter. 
4:3:34: De av dem som blev mønstret, idet der blev tatt tall på alt mannkjønn, fra dén som var en måned gammel, og opover, var seks tusen og to hundre. 
4:3:35: Og høvdingen for Merariættenes familie var Suriel, Abiha'ils sønn; de hadde sin leir ved siden av tabernaklet mot nord. 
4:3:36: Og det som Meraris barn var satt til å ta vare på, var tabernaklets planker og dets tverrstenger og dets stolper og dets fotstykker og alt som hørte til, og alt arbeidet derved 
4:3:37: og stolpene til forgården rundt omkring og fotstykkene og pluggene og snorene som hørte til. 
4:3:38: Men foran tabernaklet, mot øst, foran sammenkomstens telt, mot solens opgang, hadde Moses og Aron og hans sønner sin leir, og de tok vare på det som var å vareta ved helligdommen, det som Israels barn skulde varetatt; men kom en fremmed nær til, skulde han late livet. 
4:3:39: Alle de av levittene som blev mønstret, de som Moses og Aron mønstret efter Herrens ord, ætt for ætt, alt mannkjønn, fra den som var en måned gammel, og opover, var to og tyve tusen. 
4:3:40: Og Herren sa til Moses: Du skal mønstre alle førstefødte av mannkjønn blandt Israels barn, fra den som er en måned gammel, og opover, og føre deres navn inn i manntallet. 
4:3:41: Og så skal du la mig, Herren, få levittene i stedet for alle førstefødte blandt Israels barn, og levittenes fe i stedet for alt førstefødt blandt Israels barns fe. 
4:3:42: Da mønstret Moses alle førstefødte blandt Israels barn, således som Herren hadde befalt ham. 
4:3:43: Og da han tok tall på alle førstefødte av mannkjønn og skrev op deres navn, fra den som var en måned gammel, og opover, var de, så mange som blev mønstret, to og tyve tusen, to hundre og tre og sytti. 
4:3:44: Og Herren talte til Moses og sa: 
4:3:45: Du skal ta levittene i stedet for alle førstefødte blandt Israels barn og levittenes fe i stedet for deres fe; og levittene skal høre mig til, mig, Herren. 
4:3:46: Men som løsepenger for de to hundre og tre og sytti førstefødte av Israels barn som overskrider levittenes tall, 
4:3:47: skal du ta fem sekel for hver; efter helligdommens sekel skal du ta dem, sekelen regnet til tyve gera. 
4:3:48: Og du skal gi Aron og hans sønner disse penger som løsepenger for de overtallige blandt dem. 
4:3:49: Så tok Moses løsepengene for dem som overskred deres tall som blev utløst ved levittene; 
4:3:50: av Israels barns førstefødte fikk han pengene, et tusen, tre hundre og fem og seksti sekel efter helligdommens sekel. 
4:3:51: Og Moses gav Aron og hans sønner løsepengene efter Herrens ord, således som Herren hadde befalt Moses. 
4:4:1: Og Herren talte til Moses og Aron og sa: 
4:4:2: Ta op manntal over Kahats barn blandt levittene efter deres ætter og familier, 
4:4:3: fra trettiårsalderen og opover inntil femtiårsalderen, over alle som skal trede inn i tjenesten og utføre arbeid ved sammenkomstens telt! 
4:4:4: Det som Kahats barn skal stelle med ved sammenkomstens telt, det er de høihellige ting. 
4:4:5: Når leiren bryter op, skal Aron og hans sønner gå inn og ta ned det dekkende forheng og bre det over vidnesbyrdets ark. 
4:4:6: De skal legge et dekke av takasskinn over den og over dette igjen bre et klæde, helt igjennem av blå ull, og så sette inn bærestengene. 
4:4:7: Over skuebrøds-bordet skal de bre et klæde av blå ull, og på det skal de legge fatene og skålene og begerne og drikkoffer-kannene, og det stadige brød* skal også ligge der. # <* skuebrødene.> 
4:4:8: Over alt dette skal de bre et klæde av karmosinrød ull og legge et dekke av takasskinn om det og sette inn bærestengene. 
4:4:9: Så skal de ta et klæde av blå ull og med det dekke lysestaken og dens lamper og lysesaksene og brikkene og alle oljekarene som brukes til tjenesten ved den. 
4:4:10: Og de skal legge den og alt som hører til den, i et dekke av takasskinn og legge det på en bærebør. 
4:4:11: Over det gullklædde alter skal de bre et klæde av blå ull og legge et dekke av takasskinn om det og sette inn bærestengene. 
4:4:12: Så skal de ta alle de redskaper som brukes til tjenesten i helligdommen, og legge dem i et klæde av blå ull og dekke dem til med et dekke av takasskinn og legge dem på en bærebør. 
4:4:13: Alteret* skal de rense for asken og bre et klæde av purpurrød ull over det. # <* brennoffer-alteret.> 
4:4:14: og på det legge alle de redskaper som brukes til tjenesten ved alteret: fyrfatene, kjøttgaflene og ildskuffene og skålene til å sprenge blod med, alle alterets redskaper; de skal bre et dekke av takasskinn over og sette inn bærestengene. 
4:4:15: Når så Aron og hans sønner ved leirens opbrudd er ferdig med å dekke over helligdommen og alle helligdommens redskaper, så skal Kahats barn komme og bære; men de må ikke røre ved helligdommen, forat de ikke skal dø. Dette er det Kahats barn har å bære av det som hører til sammenkomstens telt. 
4:4:16: Men Eleasar, sønn til Aron, presten, skal ha opsynet med oljen til lysestaken og den velluktende røkelse og det stadige matoffer og salvingsoljen, og opsynet med hele tabernaklet og alt som i det er av hellige ting og redskaper som hører til. 
4:4:17: Og Herren talte til Moses og Aron og sa: 
4:4:18: La ikke kahatittenes ættegren bli utryddet av levittenes stamme, 
4:4:19: men gjør således med dem forat de skal leve og ikke dø når de kommer nær til de høihellige ting: Aron og hans sønner skal gå inn og sette enhver av dem til sin tjeneste og til det han skal bære; 
4:4:20: men selv må de ikke gå inn og se på de hellige ting, enn ikke et øieblikk, forat de ikke skal dø. 
4:4:21: Og Herren talte til Moses og sa: 
4:4:22: Ta også op manntall over Gersons barn efter deres familier og ætter! 
4:4:23: Fra trettiårsalderen og opover inntil femtiårsalderen skal du mønstre dem, alle som skal trede inn i tjenesten og utføre arbeid ved sammenkomstens telt. 
4:4:24: Dette skal være Gerson-ættenes gjerning med å tjene og bære: 
4:4:25: De skal bære tabernaklets tepper og sammenkomstens telt med dekket og varetaket av takasskinn, som ligger ovenpå, og dekket for inngangen til sammenkomstens telt 
4:4:26: og omhengene til forgården og forhenget for porten til forgården som er rundt om tabernaklet og alteret, og snorene som hører til, og alle redskapene som brukes til arbeidet derved; og alt som er å gjøre med disse ting, skal de utføre. 
4:4:27: Efter Arons og hans sønners ord skal all tjeneste utføres som Gersons barn har å gjøre, både med alt det de har å bære, og med hele deres tjeneste; og I skal overgi til deres varetekt alt det de har å bære. 
4:4:28: Dette skal være Gerson-ættenes tjeneste ved sammenkomstens telt; og Itamar, sønn til Aron, presten, skal ha opsyn med det de har å ta vare på. 
4:4:29: Meraris barn skal du mønstre efter deres ætter og familier. 
4:4:30: Fra trettiårsalderen og opover inntil femtiårsalderen skal du mønstre dem, alle som skal trede inn i tjenesten og utføre arbeidet ved sammenkomstens telt. 
4:4:31: Og dette er det som de har å bære, alt det de skal stelle med ved sammenkomstens telt: tabernaklets planker og dets tverrstenger og stolper og fotstykker 
4:4:32: og stolpene til forgården rundt omkring med deres fotstykker og plugger og snorer, alle redskaper som hører til, og alt som brukes til arbeidet derved; og I skal ved navn overgi dem de ting som de har å bære. 
4:4:33: Dette skal være Merari-ættenes tjeneste, alt det de skal stelle med ved sammenkomstens telt under opsyn av Itamar, sønn til Aron, presten. 
4:4:34: Så mønstret Moses og Aron og menighetens høvdinger kahatittene efter deres ætter og familier, 
4:4:35: fra trettiårsalderen og opover inntil femtiårsalderen, alle som skulde trede inn i tjenesten og utføre arbeid ved sammenkomstens telt. 
4:4:36: Og de av dem som blev mønstret, hver efter sin ætt, var to tusen, syv hundre og femti. 
4:4:37: Så mange var de som blev mønstret i kahatittenes ætter, alle de som tjente ved sammenkomstens telt, og som Moses og Aron mønstret efter Herrens ord ved Moses. 
4:4:38: Og de av gersonittene som blev mønstret efter sine ætter og familier, 
4:4:39: fra trettiårsalderen og opover inntil femtiårsalderen, alle som skulde trede inn i tjenesten og utføre arbeid ved sammenkomstens telt - 
4:4:40: de av dem som blev mønstret efter sine ætter og familier - var to tusen, seks hundre og tretti. 
4:4:41: Så mange var de som blev mønstret i gersonittenes ætter, alle de som tjente ved sammenkomstens telt, og som Moses og Aron mønstret efter Herrens ord. 
4:4:42: Og de som blev mønstret i merarittenes ætter, hver efter sin ætt og familie, 
4:4:43: fra trettiårsalderen og opover inntil femtiårsalderen, alle som skulde trede inn i tjenesten og utføre arbeid ved sammenkomstens telt - 
4:4:44: de av dem som blev mønstret efter sine ætter - var tre tusen og to hundre. 
4:4:45: Så mange var de som Moses og Aron mønstret i merarittenes ætter efter Herrens ord ved Moses. 
4:4:46: Alle de som Moses og Aron og Israels høvdinger mønstret av levittene efter deres ætter og familier, 
4:4:47: fra trettiårsalderen og opover inntil femtiårsalderen, alle som skulde utføre arbeid ved sammenkomstens telt med å tjene og med å bære - 
4:4:48: de som blev mønstret - var åtte tusen, fem hundre og åtti. 
4:4:49: Efter Herrens ord blev de mønstret under Moses' opsyn, og hver blev satt til å utføre den tjeneste og bære den byrde han skulde; og de som blev mønstret av Moses, var de som Herren hadde befalt ham å mønstre. 
4:5:1: Og Herren talte til Moses og sa: 
4:5:2: Byd Israels barn at de skal sende ut av leiren alle spedalske og alle som har flod, og alle som er blitt urene ved lik! 
4:5:3: Både mann og kvinne skal I sende bort; utenfor leiren skal I sende dem, forat de ikke skal gjøre deres leir uren, der hvor jeg bor midt iblandt dem. 
4:5:4: Og Israels barn gjorde så og sendte dem utenfor leiren; som Herren hadde sagt til Moses, således gjorde Israels barn. 
4:5:5: Og Herren talte til Moses og sa: 
4:5:6: Si til Israels barn: Når en mann eller kvinne gjør nogen av de synder som mennesker gjør, og bærer sig troløst at mot Herren, så de fører skyld over sig, 
4:5:7: da skal de bekjenne den synd som de har gjort, og godtgjøre det de med urette har tatt, med dets fulle verd og enda legge til femtedelen; de skal gi det til den som de har syndet mot. 
4:5:8: Men dersom mannen ikke har efterlatt sig nogen nær slektning som de kan gi vederlaget til, da skal vederlaget høre Herren til og tilfalle presten foruten sonings-væren, som skal ofres til soning for dem. 
4:5:9: Og alle gaver, alt det som Israels barn helliger og kommer til presten med, skal høre ham til; 
4:5:10: hver manns hellige gaver skal høre presten til; det nogen gir ham, skal høre ham til. 
4:5:11: Og Herren talte til Moses og sa: 
4:5:12: Tal til Israels barn og si til dem: Når en manns hustru forsynder sig og er ham utro, 
4:5:13: og en annen mann har samleie med henne, men hennes mann ikke vet om det, fordi hun har latt sig vanære i lønndom, og der ikke er noget vidne mot henne, og hun ikke er grepet på fersk gjerning, 
4:5:14: og det så kommer en skinnsykens ånd over ham, så han blir skinnsyk på sin hustru, og hun virkelig har latt sig vanære, eller det kommer en skinnsykens ånd over ham, så han blir skinnsyk på sin hustru, uaktet hun ikke har latt sig vanære, 
4:5:15: da skal mannen ta sin hustru med sig til presten og som offer for henne ha med tiendedelen av en efa byggmel; men han skal ikke helle olje på det, heller ikke legge virak på det; for det er et skinnsyke-matoffer, et ihukommelses-matoffer, som skal minne om en brøde. 
4:5:16: Så skal presten føre henne frem og stille henne for Herrens åsyn. 
4:5:17: Og presten skal ta hellig vann i et lerkar og ta noget av støvet som er på tabernaklets gulv, og kaste i vannet. 
4:5:18: Og når presten har stilt kvinnen frem for Herrens åsyn, skal han løse hennes hår og legge ihukommelses-matofferet i hennes hender - et skinnsyke-matoffer er det - og i sin hånd skal presten ha bitterhetens vann, som volder forbannelse. 
4:5:19: Så skal presten ta kvinnen i ed og si til henne: Så sant ingen har ligget hos dig, og så sant du ikke har vært utro mot din mann og latt dig vanære, så skal du intet mén ha av dette bitterhetens vann som volder forbannelse. 
4:5:20: Men er det så at du har vært utro mot din mann og latt dig vanære, og at nogen annen enn din mann har hatt samleie med dig 
4:5:21: - nu skal presten lese op forbannelses-eden for kvinnen og si til henne: - så gjøre Herren dig til en forbannelse og til en ed blandt ditt folk; han la dine hofter svinne inn og din buk hovne op; 
4:5:22: og dette vann som volder forbannelse, skal trenge inn i dine innvoller, så din buk hovner op og dine hofter svinner inn. Og kvinnen skal si: Amen! Amen! 
4:5:23: Derefter skal presten skrive op disse forbannelser på et blad og vaske dem ut i bitterhetens vann, 
4:5:24: og han skal la kvinnen drikke bitterhetens vann, som volder forbannelse, og vannet som volder forbannelse, skal trenge inn i henne og volde bitter ve. 
4:5:25: Så skal presten ta skinnsyke-matofferet av kvinnens hånd og svinge det for Herrens åsyn og bære det frem til alteret. 
4:5:26: Og presten skal ta en håndfull av matofferet som ihukommelses-offer og brenne det på alteret; Og så skal han la kvinnen drikke vannet. 
4:5:27: Og når han har latt henne drikke vannet, da skal det skje at dersom hun har latt sig vanære og har vært utro mot sin mann, så skal vannet som volder forbannelse, trenge inn i henne og volde bitter ve; hennes buk skal hovne op og hennes hofter svinne inn; og den kvinne skal bli til en forbannelse blandt sitt folk. 
4:5:28: Men har kvinnen ikke latt sig vanære, og er hun ren, da skal hun intet mén ha av det, og hun skal få barn. 
4:5:29: Dette er skinnsyke-loven: Når en kvinne er utro mot sin mann og lar sig vanære, 
4:5:30: eller når en skinnsykens ånd kommer over en mann, så han blir skinnsyk på sin hustru, så skal han stille kvinnen frem for Herrens åsyn, og presten skal gjøre med henne alt det som er sagt i denne lov. 
4:5:31: Og mannen skal være fri for skyld, men kvinnen skal lide for sin misgjerning. 
4:6:1: Og Herren talte til Moses og sa: 
4:6:2: Tal til Israels barn og si til dem: Når en mann eller kvinne gjør et hellig løfte om å ville være nasireer og vie sig til Herren, 
4:6:3: så skal han holde sig fra vin og sterk drikk; eddik av vin eller annen sterk eddik skal han ikke drikke og heller ikke nogen saft som er tillaget av druer; hverken friske eller tørre druer skal han ete. 
4:6:4: Så lenge hans innvielse varer, skal han ikke ete noget av det som tillages av vintreet, like fra kjerne til skall. 
4:6:5: Så lenge hans innvielses-løfte gjelder, skal der ikke gå rakekniv over hans hode; inntil hans innvielses-tid er til ende, skal han være hellig, han skal la sitt hodehår vokse fritt. 
4:6:6: Så lenge han er innvidd til Herren, skal han ikke komme nær noget lik. 
4:6:7: Ikke engang om hans far eller mor eller bror eller søster dør, må han føre urenhet over sig for deres skyld; for han bærer på sitt hode tegnet på innvielsen til sin Gud. 
4:6:8: Hele sin innvielses-tid er han hellig for Herren. 
4:6:9: Men dør der nogen hos ham uventet og brått og fører urenhet over hans innvidde hode, da skal han rake sitt hode den dag han blir ren; den syvende dag skal han rake det. 
4:6:10: Og på den åttende dag skal han komme til presten med to turtelduer eller to dueunger, til inngangen til sammenkomstens telt. 
4:6:11: Og presten skal ofre den ene til syndoffer og den andre til brennoffer og gjøre soning for ham og rense ham for den synd han har ført over sig ved å komme nær liket. Så skal han samme dag hellige sitt hode, 
4:6:12: og på ny vie sig til Herren for like sa lang tid som han først hadde lovt, og føre frem et årsgammelt lam til skyldoffer; den første tid gjelder ikke mere, fordi hans innvielse blev utskjemt. 
4:6:13: Dette er loven for nasireeren: Den dag hans innvielses-tid er til ende, skal han føres frem til inngangen til sammenkomstens telt, 
4:6:14: og han skal bære frem for Herren sitt offer, et årsgammelt værlam uten lyte til brennoffer og et årsgammelt hunlam uten lyte til syndoffer og en vær uten lyte til takkoffer 
4:6:15: og en kurv med usyrede kaker av fint mel, kaker med olje i, og usyrede brødleiver smurt med olje, og matofferet og drikkofferne som hører til. 
4:6:16: Og presten skal bære det frem for Herrens åsyn og ofre hans syndoffer og hans brennoffer. 
4:6:17: Og væren skal han ofre som takkoffer til Herren sammen med kurven med de usyrede kaker, og så skal presten ofre hans matoffer og hans drikkoffer. 
4:6:18: Derefter skal nasireeren rake sitt innvidde hode ved inngangen til sammenkomstens telt, og han skal ta sitt innvidde hodehår og legge det på ilden som er under takkofferet. 
4:6:19: Og presten skal ta en bog av væren, efterat den er kokt, og en usyret kake av kurven og en usyret brødleiv og legge dem i hendene på nasireeren, efterat han har raket av sitt innvielsestegn. 
4:6:20: Så skal presten svinge dem for Herrens åsyn; det er helliget presten foruten svinge-brystet og løfte-låret. Siden kan nasireeren drikke vin. 
4:6:21: Dette er loven for den som gjør et nasireer-løfte, dette er det han skal ofre til Herren på grunn av sin innvielse, foruten det han ellers har råd til; han skal holde sig efter det løfte han har gjort, foruten det loven om hans innvielse krever. 
4:6:22: Og Herren talte til Moses og sa: 
4:6:23: Tal til Aron og hans sønner og si: Således skal I si når I velsigner Israels barn: 
4:6:24: Herren velsigne dig og bevare dig! 
4:6:25: Herren la sitt åsyn lyse over dig og være dig nådig! 
4:6:26: Herren løfte sitt åsyn på dig og gi dig fred! 
4:6:27: Således skal de legge mitt navn på Israels barn, og jeg vil velsigne dem. 
4:7:1: Da nu Moses var ferdig med å reise tabernaklet og hadde salvet det og helliget det med alt som hørte til det, og likeledes salvet og helliget alteret med alt som hørte til det, 
4:7:2: da kom Israels høvdinger, overhodene for sine familier, stammefyrstene, de som stod over alle som var blitt mønstret, og bar frem gaver. 
4:7:3: De bar sin gave frem for Herrens åsyn, seks vogner med dekke over og tolv okser, en vogn for to høvdinger og en okse for hver; og de førte dem frem foran tabernaklet. 
4:7:4: Og Herren sa til Moses: 
4:7:5: Ta imot dette av dem, forat det kan brukes til tjenesten ved sammenkomstens telt, og du skal gi det til levittene, efter som enhver av dem trenger det til sin tjeneste. 
4:7:6: Da tok Moses vognene og oksene og gav dem til levittene. 
4:7:7: To av vognene og fire av oksene gav han til Gersons barn, efter deres særlige tjeneste. 
4:7:8: Og fire av vognene og åtte av oksene gav han til Meraris barn, efter deres særlige tjeneste under opsyn av Itamar, sønn til Aron, presten. 
4:7:9: Men til Kahats barn gav han ikke noget, fordi de skulde ta vare på de hellige ting og bære dem på sine skuldrer. 
4:7:10: Den dag alteret blev salvet, kom høvdingene med gaver til dets innvielse, og de bar sin gave frem foran alteret. 
4:7:11: Da sa Herren til Moses: La høvdingene komme med sin gave til alterets innvielse hver sin dag! 
4:7:12: Og den som bar frem sin gave den første dag, var Nahson, Amminadabs sønn, høvdingen for Juda stamme. 
4:7:13: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:14: en skål av gull på ti sekel, full av røkelse, 
4:7:15: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:16: en gjetebukk til syndoffer 
4:7:17: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Nahsons, Amminadabs sønns gave. 
4:7:18: Den annen dag kom Netanel, Suars sønn, Issakars høvding, med sin gave. 
4:7:19: Han bar frem som sin gave et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:20: en skål av gull på ti sekel, full av røkelse, 
4:7:21: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:22: en gjetebukk til syndoffer 
4:7:23: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Netanels, Suars sønns gave. 
4:7:24: Den tredje dag kom høvdingen for Sebulons barn, Eliab, Helons sønn. 
4:7:25: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:26: en skål av gull på ti sekel, full av røkelse, 
4:7:27: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:28: en gjetebukk til syndoffer 
4:7:29: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Eliabs, Helons sønns gave. 
4:7:30: Den fjerde dag kom høvdingen for Rubens barn, Elisur, Sede'urs sønn. 
4:7:31: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:32: en skål av gull på ti sekel, full av røkelse, 
4:7:33: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:34: en gjetebukk til syndoffer 
4:7:35: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Elisurs, Sede'urs sønns gave. 
4:7:36: Den femte dag kom høvdingen for Simeons barn, Selumiel, Surisaddais sønn. 
4:7:37: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:38: en skål av gull på ti sekel, full av røkelse, 
4:7:39: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:40: en gjetebukk til syndoffer 
4:7:41: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Selumiels, Surisaddais sønns gave. 
4:7:42: Den sjette dag kom høvdingen for Gads barn, Eljasaf, De'uels sønn. 
4:7:43: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:44: en skål av gull på ti sekel, full av røkelse, 
4:7:45: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:46: en gjetebukk til syndoffer 
4:7:47: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Eljasafs, De'uels sønns gave. 
4:7:48: Den syvende dag kom høvdingen for Efra'ims barn, Elisama, Ammihuds sønn. 
4:7:49: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:50: en skål av gull på ti sekel, full av røkelse, 
4:7:51: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:52: en gjetebukk til syndoffer 
4:7:53: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Elisamas, Ammihuds sønns gave. 
4:7:54: Den åttende dag kom høvdingen for Manasses barn, Gamliel, Pedasurs sønn. 
4:7:55: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:56: en skål av gull på ti sekel, full av røkelse, 
4:7:57: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:58: en gjetebukk til syndoffer 
4:7:59: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Gamliels, Pedasurs sønns gave. 
4:7:60: Den niende dag kom høvdingen for Benjamins barn, Abidan, Gideonis sønn. 
4:7:61: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:62: en skål av gull på ti sekel, full av røkelse, 
4:7:63: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:64: en gjetebukk til syndoffer 
4:7:65: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Abidans, Gideonis sønns gave. 
4:7:66: Den tiende dag kom høvdingen for Dans barn, Akieser, Ammisaddais sønn. 
4:7:67: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:68: en skål av gull på ti sekel, full av røkelse, 
4:7:69: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:70: en gjetebukk til syndoffer 
4:7:71: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Akiesers, Ammisaddais sønns gave. 
4:7:72: Den ellevte dag kom høvdingen for Asers barn, Pagiel, Okrans sønn. 
4:7:73: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:74: en skål av gull på ti sekel, full av røkelse, 
4:7:75: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:76: en gjetebukk til syndoffer 
4:7:77: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Pagiels, Okrans sønns gave. 
4:7:78: Den tolvte dag kom høvdingen for Naftalis barn, Akira, Enans sønn. 
4:7:79: Hans gave var et sølvfat som veide hundre og tretti sekel, en sølvskål på sytti sekel efter helligdommens vekt, begge fulle av fint mel blandet med olje, til matoffer, 
4:7:80: en skål av gull på ti sekel, full av røkelse, 
4:7:81: en ung okse, en vær og et årsgammelt lam til brennoffer, 
4:7:82: en gjetebukk til syndoffer 
4:7:83: og til takkoffer to okser, fem værer, fem bukker og fem årsgamle lam. Dette var Akiras, Enans sønns gave. 
4:7:84: Dette var gavene fra Israels høvdinger til alterets innvielse på den tid det blev salvet: tolv sølvfat, tolv sølvskåler, tolv gullskåler, 
4:7:85: hvert sølvfat på hundre og tretti sekel og hver skål på sytti sekel; alt sølvet i karene gikk op til to tusen og fire hundre sekel efter helligdommens vekt - 
4:7:86: tolv gullskåler fulle av røkelse, hver skål på ti sekel efter helligdommens vekt; alt gullet i skålene gikk op til hundre og tyve sekel. 
4:7:87: Storfeet til brennofferet var i alt tolv okser; dertil kom tolv værer, tolv årsgamle lam med tilhørende matoffer og tolv gjetebukker til syndoffer. 
4:7:88: Og storfeet til takkofferet var i alt fire og tyve okser; dertil kom seksti værer, seksti bukker og seksti årsgamle lam. Dette var gavene til alterets innvielse, efterat det var salvet. 
4:7:89: Og når Moses gikk inn i sammenkomstens telt for å tale med ham*, da hørte han røsten tale til sig fra nådestolen ovenover vidnesbyrdets ark mellem de to kjeruber; således talte han* til ham. # <* Herren.> 
4:8:1: Og Herren talte til Moses og sa: 
4:8:2: Tal til Aron og si til ham: Når du setter lampene op, skal alle syv lamper kaste sitt lys rett frem for lysestaken. 
4:8:3: Og Aron gjorde således; han satte lampene op så de kastet sitt lys rett frem for lysestaken, således som Herren hadde befalt Moses. 
4:8:4: Lysestaken var gjort av gull i drevet arbeid; både foten og blomstene var drevet arbeid; efter det billede Herren hadde vist Moses, hadde han gjort lysestaken. 
4:8:5: Og Herren talte til Moses og sa: 
4:8:6: Du skal ta levittene ut blandt Israels barn og rense dem! 
4:8:7: Og således skal du gjøre med dem for å rense dem: Du skal sprenge renselses-vann på dem, og de skal la rakekniv gå over hele sitt legeme og tvette sine klær og således rense sig. 
4:8:8: Så skal de ta en ung okse og matofferet som hører til, fint mel blandet med olje; og en annen ung okse skal du ta til syndoffer. 
4:8:9: Og du skal la levittene komme frem foran sammenkomstens telt, og du skal samle hele Israels barns menighet. 
4:8:10: Så skal du la levittene trede frem for Herrens åsyn, og Israels barn skal legge sine hender på levittene. 
4:8:11: Og Aron skal innvie levittene for Herrens åsyn som et svinge-offer fra Israels barn, og deres arbeid skal være å utføre Herrens tjeneste. 
4:8:12: Så skal levittene legge sine hender på oksenes hoder, og du skal ofre den ene til syndoffer og den andre til brennoffer for Herren for å gjøre soning for levittene. 
4:8:13: Og du skal stille levittene frem for Aron og hans sønner, og du skal innvie dem som et svinge-offer for Herren. 
4:8:14: Således skal du skille levittene ut blandt Israels barn, og levittene skal høre mig til. 
4:8:15: Og derefter skal levittene komme og tjene ved sammenkomstens telt, når du har renset dem og innvidd dem. 
4:8:16: For de er helt overgitt til mig som en gave fra Israels barn; istedenfor alt som åpner morsliv, alle førstefødte blandt Israels barn, har jeg tatt dem ut for mig. 
4:8:17: For mig hører alt førstefødt til blandt Israels barn, både folk og fe; den dag jeg slo alt førstefødt i Egyptens land, helliget jeg dem for mig. 
4:8:18: Men nu har jeg tatt levittene i stedet for alle førstefødte blandt Israels barn. 
4:8:19: Jeg tok levittene ut blandt Israels barn og overgav dem helt til Aron og hans sønner, forat de skulde gjøre tjeneste ved sammenkomstens telt for Israels barn og gjøre soning for dem, så Israels barn ikke skal føre ulykke over sig ved å komme nær til helligdommen. 
4:8:20: Og Moses og Aron og hele Israels barns menighet gjorde således med levittene; aldeles som Herren hadde befalt Moses om levittene, således gjorde Israels barn med dem. 
4:8:21: Og levittene renset sig og tvettet sine klær, og Aron innvidde dem for Herrens åsyn og gjorde soning for dem, så de blev rene. 
4:8:22: Så kom levittene og utførte sin tjeneste ved sammenkomstens telt under tilsyn av Aron og hans sønner; som Herren hadde befalt Moses om levittene, således gjorde de med dem. 
4:8:23: Og Herren talte til Moses og sa: 
4:8:24: Dette er loven som gjelder for levittene: Fra han er fem og tyve år gammel, skal han komme og gjøre tjeneste med å arbeide ved sammenkomstens telt. 
4:8:25: Men fra han er femti år gammel, skal han trede tilbake fra arbeidstjenesten og ikke arbeide mere. 
4:8:26: Dog skal han gå sine brødre til hånde i sammenkomstens telt og ta vare på det som er å vareta; men nogen arbeidstjeneste skal han ikke utføre. Således skal du lage det for levittene med det de har å vareta. 
4:9:1: Og Herren talte til Moses i Sinai ørken i det annet år efterat de var gått ut av Egyptens land, i den første måned, og sa: 
4:9:2: Israels barn skal holde påske på den fastsatte tid. 
4:9:3: Den fjortende dag i denne måned, mellem de to aftenstunder, skal I holde påske på den fastsatte tid. Efter alle lover og forskrifter som gjelder om den, skal I holde den. 
4:9:4: Da talte Moses til Israels barn og sa at de skulde holde påske. 
4:9:5: Og de holdt påske i den første måned, på den fjortende dag i måneden, mellem de to aftenstunder, i Sinai ørken; aldeles som Herren hadde befalt Moses, således gjorde Israels barn. 
4:9:6: Men det var nogen menn som var blitt urene av et lik, så de ikke kunde holde påske den dag; disse menn trådte samme dag frem for Moses og Aron 
4:9:7: og sa til ham: Vi er blitt urene av et lik; hvorfor skal det være oss nektet å bære frem Herrens offer på den fastsatte tid sammen med de andre Israels barn? 
4:9:8: Da sa Moses til dem: Vent, så jeg kan få høre hvad Herren befaler om eder. 
4:9:9: Og Herren talte til Moses og sa: 
4:9:10: Tal til Israels barn og si: Om nogen blandt eder eller blandt eders efterkommere er blitt uren av et lik eller er på langreise, skal han allikevel holde påske for Herren; 
4:9:11: i den annen måned på den fjortende dag, mellem de to aftenstunder, skal de holde den; med usyret brød og bitre urter skal de ete påskelammet. 
4:9:12: De skal ikke levne noget av det til om morgenen og ikke bryte noget ben på det; de skal i ett og alt holde påsken efter loven som gjelder om den. 
4:9:13: Men den som er ren og ikke er på reise og enda lar være å holde påske, han skal utryddes av sitt folk, fordi han ikke bar frem Herrens offer på den fastsatte tid; den mann skal lide for sin synd. 
4:9:14: Og når en fremmed opholder sig hos eder og vil holde påske for Herren, så skal han holde den efter loven om påsken og efter forskriftene om den; der skal gjelde én lov for eder, både for den fremmede og for den innfødte i landet. 
4:9:15: Den dag tabernaklet blev reist, dekket skyen tabernaklet, vidnesbyrdets telt; og om aftenen var der som et ildskjær over tabernaklet like til om morgenen. 
4:9:16: Således var det alltid: Skyen dekket det*, og om natten var der som et ildskjær. # <* om dagen.> 
4:9:17: Og hver gang skyen løftet sig fra teltet, brøt Israels barn straks op; og på det sted hvor skyen lot sig ned, der leiret Israels barn sig. 
4:9:18: Efter Herrens befaling brøt Israels barn op, og efter Herrens befaling leiret de sig; alle de dager skyen hvilte over tabernaklet, lå de i leir. 
4:9:19: Når skyen blev over tabernaklet i mange dager, da rettet Israels barn sig efter det som Herren hadde sagt, og brøt ikke op. 
4:9:20: Stundom hendte det at skyen var bare nogen få dager over tabernaklet; efter Herrens befaling lå de da i leir, og efter Herrens befaling brøt de op. 
4:9:21: Men stundom hendte det at skyen var der bare fra aften til morgen; når da skyen løftet sig om morgenen, brøt de op. Eller den var der en dag og en natt; når da skyen løftet sig, brøt de op. 
4:9:22: Eller den var der et par dager eller en måned eller enda lenger; når skyen drygde så lenge og blev liggende over tabernaklet, da lå Israels barn i leir og brøt ikke op, men når den løftet sig, brøt de op. 
4:9:23: Efter Herrens befaling leiret de sig, og efter Herrens befaling brøt de op; de rettet sig efter det som Herren hadde sagt - efter det som Herren hadde befalt ved Moses. 
4:10:1: Og Herren talte til Moses og sa: 
4:10:2: Gjør dig to trompeter av sølv; i drevet arbeid skal du gjøre dem. Og du skal bruke dem når menigheten skal kalles sammen, og når leirene skal bryte op. 
4:10:3: Når der støtes i dem begge, da skal hele menigheten samle sig hos dig ved inngangen til sammenkomstens telt. 
4:10:4: Støtes der bare i den ene, da skal høvdingene, overhodene for Israels tusener, samle sig hos dig. 
4:10:5: Men når I blåser alarm, da skal de leire som ligger mot øst, bryte op. 
4:10:6: Og når I blåser alarm annen gang, da skal de leire som ligger mot syd, bryte op. Alarm skal der blåses når de skal bryte op. 
4:10:7: Men når menigheten skal kalles sammen, skal I støte i dem og ikke blåse alarm. 
4:10:8: Arons sønner, prestene, er det som skal blåse i trompetene. Dette skal være en evig lov for eder, fra slekt til slekt. 
4:10:9: Og når I drar i krig i eders land mot fiender som overfaller eder, da skal I blåse alarm med trompetene; og Herren eders Gud skal komme eder i hu, så I skal bli frelst fra eders fiender. 
4:10:10: Og på eders gledesdager og eders høitider og eders nymånedager skal I støte i trompetene når I ofrer eders brennoffer og eders takkoffer, og de skal minne om eder for eders Guds åsyn; jeg er Herren eders Gud. 
4:10:11: Og det skjedde i det annet år i den annen måned, på den tyvende dag i måneden, da løftet skyen sig fra vidnesbyrdets tabernakel, 
4:10:12: og Israels barn brøt op og drog i dagsreiser fra Sinai ørken, og skyen lot sig ned i ørkenen Paran. 
4:10:13: Dette var første gang de brøt op, og det var efter Herrens ord ved Moses. 
4:10:14: Først brøt Judas barns leir op med sitt banner, hær efter hær, og høvdingen for deres hær var Nahson, Amminadabs sønn. 
4:10:15: Og høvding for Issakars stammes hær var Netanel, Suars sønn. 
4:10:16: Og høvding for Sebulons stammes hær var Eliab, Helons sønn. 
4:10:17: Så blev tabernaklet tatt ned, og Gersons barn og Meraris barn, de som bar tabernaklet, brøt op. 
4:10:18: Så brøt Rubens leir op med sitt banner, hær efter hær, og høvdingen for deres hær var Elisur, Sede'urs sønn. 
4:10:19: Og høvding for Simeons stammes hær var Selumiel, Surisaddais sønn. 
4:10:20: Og høvding for Gads stammes hær var Eljasaf, De'uels sønn. 
4:10:21: Så brøt kahatittene op, de som bar de høihellige ting; og før de kom frem, hadde de andre* reist tabernaklet. # <* 4MO 10, 17.> 
4:10:22: Så brøt Efra'ims leir op med sitt banner, hær efter hær, og høvdingen for deres hær var Elisama, Ammihuds sønn. 
4:10:23: Og høvding for Manasse stammes hær var Gamliel, Pedasurs sønn. 
4:10:24: Og høvding for Benjamins stammes hær var Abidan, Gideonis sønn. 
4:10:25: Så brøt Dans leir op med sitt banner, hær efter hær - de var hele togets baktropp, og høvdingen for deres hær var Akieser, Ammisaddais sønn. 
4:10:26: Og høvding for Asers stammes hær var Pagiel, Okrans sønn. 
4:10:27: Og høvding for Naftali stammes hær var Akira, Enans sønn. 
4:10:28: Således var Israels barn fylket når de brøt op, hær for hær. Så brøt de op, 
4:10:29: og Moses sa til midianitten Hobab, Re'uels sønn, Moses' svoger: Vi bryter nu op til det sted hvorom Herren har sagt: Jeg vil gi eder det. Kom med oss! Så vil vi gjøre vel imot dig; for Herren har lovt Israel alt hvad godt er. 
4:10:30: Men han svarte: Jeg vil ikke gå med, jeg vil dra hjem til mitt land og min slekt. 
4:10:31: Da sa Moses: Å nei, forlat oss ikke! Du vet jo best hvor vi kan leire oss i ørkenen, og du skal være vårt øie; 
4:10:32: går du med oss, da vil vi la dig få godt av det gode som Herren gjør mot oss. 
4:10:33: Så drog de da fra Herrens berg tre dagsreiser frem; og Herrens pakts ark drog foran dem de tre dagsreiser for å søke et hvilested for dem. 
4:10:34: Herrens sky var over dem om dagen når de brøt op fra leiren. 
4:10:35: Og når arken brøt op, sa Moses: Reis dig, Herre, så dine fiender spredes, og de som hater dig, flyr for ditt åsyn! 
4:10:36: Og når den hvilte, sa han: Kom tilbake, Herre, til Israels titusen tusener! 
4:11:1: Men folket knurret, og dette mishaget Herren; for da Herren hørte det, blev hans vrede optendt, og ild fra Herren slo ned mellem dem og fortærte nogen ytterst i leiren. 
4:11:2: Da ropte folket til Moses, og Moses bad til Herren, og ilden blev slukket. 
4:11:3: Og han kalte dette sted Tabera*, fordi Herrens ild hadde slått ned mellem dem. # <* brand.> 
4:11:4: Men den sammenløpne hop som fulgte med dem, blev grepet av lystenhet; også Israels barn begynte da atter å jamre sig og sa: Å, om vi hadde kjøtt å ete! 
4:11:5: Vi minnes fisken som vi åt i Egypten for intet, gresskarene og melonene og purren og rødløken og hvitløken. 
4:11:6: Men nu vansmekter vår sjel, for her er ingenting; Vi ser ikke annet for våre øine enn mannaen. 
4:11:7: Mannaen lignet korianderfrø, og av utseende var den som bdellium. 
4:11:8: Folket løp hit og dit og sanket og malte den på håndkvern eller støtte den i morter og kokte den i gryter eller bakte kaker av den, og den smakte som oljekake. 
4:11:9: Når duggen falt ned over leiren om natten, da falt mannaen ned sammen med den. 
4:11:10: Og Moses hørte folket gråte rundt om i alle familier, enhver i døren til sitt telt, og Herrens vrede optendtes storlig; og Moses blev ille til mote derover. 
4:11:11: Da sa Moses til Herren: Hvorfor har du gjort så ille mot din tjener, og hvorfor har jeg ikke funnet nåde for dine øine, siden du har lagt byrden av hele dette folk på mig? 
4:11:12: Har jeg undfanget hele dette folk, har jeg født det, siden du sier jeg skal bære det i min favn, likesom ammen bærer det diende barn, og føre det til det land du har tilsvoret dets fedre? 
4:11:13: Hvor skal jeg ta kjøtt fra til hele dette folk? For de kommer gråtende til mig og sier: Gi oss kjøtt å ete! 
4:11:14: Jeg makter ikke å bære hele dette folk alene; det er mig for tungt. 
4:11:15: Vil du gjøre således mot mig, så drep mig heller med én gang, dersom jeg har funnet nåde for dine øine, og la mig slippe å se min ulykke! 
4:11:16: Da sa Herren til Moses: Kall mig sammen sytti menn av Israels eldste, de som du vet er folkets eldste og dets tilsynsmenn, og du skal ta dem med dig til sammenkomstens telt, og la dem stille sig op der sammen med dig. 
4:11:17: Så vil jeg komme ned og tale med dig der, og jeg vil ta av den ånd som er over dig, og legge på dem, så de kan bære byrden av folket sammen med dig, og du ikke skal bære den alene. 
4:11:18: Og til folket skal du si: Hellige eder til imorgen, så skal I få kjøtt å ete, siden I har grått for Herren og sagt: Å, om vi hadde kjøtt å ete, for i Egypten hadde vi det godt. Nu vil Herren gi eder kjøtt, så I kan ete. 
4:11:19: Ikke bare én dag skal I ete av det, og ikke to dager og ikke fem dager og ikke tyve dager, 
4:11:20: men en hel måned, til I ikke lenger tåler lukten av det, og det byr eder imot, fordi I foraktet Herren, som er midt iblandt eder, og gråt for hans åsyn og sa: Hvorfor drog vi da ut av Egypten? 
4:11:21: Og Moses sa: Seks hundre tusen mann til fots teller det folk som jeg følges med, og du sier: Jeg vil gi dem kjøtt, så de kan ete en hel måned! 
4:11:22: Skal der da slaktes så meget småfe og storfe til dem at det blir nok for dem? Eller skal alle fiskene i havet sankes sammen til dem, så det blir nok for dem? 
4:11:23: Da sa Herren til Moses: Er Herrens arm for kort? Nu skal du få se om det vil gå dig som jeg har sagt, eller ikke. 
4:11:24: Så gikk Moses ut og kunngjorde Herrens ord for folket, og han samlet sytti menn av folkets eldste og lot dem stille sig rundt om teltet. 
4:11:25: Da kom Herren ned i skyen og talte til ham, og han tok av den ånd som var over ham, og la på de sytti eldste, og det skjedde da ånden hvilte over dem, da talte de profetiske ord, men siden gjorde de det ikke mere. 
4:11:26: Men det var to menn blitt tilbake i leiren, den ene hette Eldad, og den andre Medad, og ånden hvilte over dem, for de var blandt de opskrevne, men de var ikke gått ut til teltet; og de talte profetisk i leiren. 
4:11:27: Da løp en gutt ut og meldte det til Moses og sa: Eldad og Medad taler profetisk i leiren. 
4:11:28: Og Josva, Nuns sønn, som hadde tjent Moses fra sin ungdom av, tok til orde og sa: Min herre Moses, forbyd dem det! 
4:11:29: Men Moses sa til ham: Er du nidkjær for min skyld? Gid alt Herrens folk var profeter, gid Herren vilde legge sin Ånd på dem! 
4:11:30: Så gikk Moses tilbake til leiren, både han og de eldste av Israel. 
4:11:31: Da brøt det løs en storm fra Herren, og den førte vaktler inn fra havet og strødde dem over leiren, omkring en dagsreise på den ene kant og omkring en dagsreise på den andre kant rundt om leiren og omkring to alen over jordens overflate. 
4:11:32: Da stod folket op og gikk hele den dag og hele natten og hele den næste dag og sanket vaktlene; den som hadde samlet minst, hadde sanket ti homer. Og de bredte dem ut rundt om leiren. 
4:11:33: Men mens kjøttet ennu var mellem deres tenner, før det var helt fortært, optendtes Herrens vrede mot folket, og Herren lot det bli et meget stort mannefall blandt folket. 
4:11:34: Og de kalte dette sted Kibrot Hatta'ava*, fordi de der begravde dem som hadde vært så lystne. # <* lystenhets graver.> 
4:11:35: Fra Kibrot-Hatta'ava brøt folket op til Haserot, og de blev i Haserot. 
4:12:1: Engang talte Mirjam og Aron ille om Moses for den etiopiske kvinnes skyld som han hadde tatt til hustru; for han hadde ektet en etiopisk kvinne. 
4:12:2: Og de sa: Er det bare Moses Herren har talt med, har han ikke talt med oss og? Og Herren hørte det. 
4:12:3: Men Moses var en meget saktmodig mann, mere enn alle mennesker på jorden. 
4:12:4: Og med ett sa Herren til Moses og til Aron og til Mirjam: Gå ut, alle tre, til sammenkomstens telt! Og de gikk ut alle tre. 
4:12:5: Da kom Herren ned i en skystøtte og stod i inngangen til teltet, og han kalte på Aron og Mirjam, og de gikk ut begge to. 
4:12:6: Og han sa: Hør nu hvad jeg har å si eder: Er det en profet som I, så gir jeg, Herren, mig til kjenne for ham i syner og taler med ham i drømmer. 
4:12:7: Men så er det ikke med min tjener Moses; han er tro i hele mitt hus. 
4:12:8: Munn til munn taler jeg med ham, klart og ikke i gåter, og han skuer Herrens skikkelse. Hvorledes kunde I da våge å tale ille om Moses, min tjener? 
4:12:9: Og Herrens vrede optendtes mot dem, og han gikk bort. 
4:12:10: Og da skyen vek bort fra teltet, se, da var Mirjam spedalsk, hvit som sne; og da Aron vendte sig mot Mirjam, så han at hun var spedalsk. 
4:12:11: Da sa Aron til Moses: Hør mig, herre! La oss ikke lide for en synd vi har gjort i vår dårskap! 
4:12:12: La henne ikke være som et dødt foster, hvis kjøtt er halvt fortært når det kommer ut av morsliv! 
4:12:13: Da ropte Moses til Herren og sa: Akk Gud, helbred henne! 
4:12:14: Og Herren sa til Moses: Om hennes far hadde spyttet henne i ansiktet, skulde hun da ikke sitte med skammen i syv dager? La henne holdes innestengt utenfor leiren i syv dager, så kan hun komme tilbake igjen. 
4:12:15: Og Mirjam blev holdt innestengt utenfor leiren i syv dager; og folket brøt ikke op før Mirjam var kommet tilbake igjen. 
4:12:16: Derefter brøt folket op fra Haserot og leiret sig i ørkenen Paran. 
4:13:1: Og Herren talte til Moses og sa: 
4:13:2: Send avsted menn for å utspeide Kana'ans land, som jeg nu vil gi Israels barn! En mann for hver fedrenestamme skal I sende; hver av dem skal være en høvding blandt folket. 
4:13:3: Da sendte Moses dem fra ørkenen Paran efter Herrens ord; alle var de overhoder for Israels barn. 
4:13:4: Og dette var deres navn: For Rubens stamme Sammua, Sakkurs sønn, 
4:13:5: for Simeons stamme Safat, Horis sønn, 
4:13:6: for Juda stamme Kaleb, Jefunnes sønn, 
4:13:7: for Issakars stamme Jigeal, Josefs sønn, 
4:13:8: for Efra'ims stamme Hosea, Nuns sønn, 
4:13:9: for Benjamins stamme Palti, Rafus sønn, 
4:13:10: for Sebulons stamme Gaddiel, Sodis sønn, 
4:13:11: for Josefs stamme, for Manasse stamme Gaddi, Susis sønn, 
4:13:12: for Dans stamme Ammiel, Gemallis sønn, 
4:13:13: for Asers stamme Setur, Mikaels sønn, 
4:13:14: for Naftali stamme Nahbi, Vofsis sønn, 
4:13:15: for Gads stamme Ge'uel, Makis sønn. 
4:13:16: Dette var navnene på de menn som Moses sendte for å utspeide landet; men Moses gav Hosea, Nuns sønn, navnet Josva. 
4:13:17: Og Moses sendte dem for å utspeide Kana'ans land, og han sa til dem: Dra op her til sydlandet og stig op i fjellene 
4:13:18: og se hvordan landet er, og om folket som bor der, er sterkt eller svakt, om det er lite eller stort, 
4:13:19: og hvordan det land er som det bor i, om det er godt eller dårlig, og hvordan de byer er som det bor i, om det bor i leire eller i festninger, 
4:13:20: og hvordan jorden er, om den er fet eller mager, om det er trær der eller ikke! Vær ved godt mot og ta med eder av landets frukt! Det var nettop på den tid de første druer blev modne. 
4:13:21: Så drog de da op og utspeidet landet fra ørkenen Sin til Rehob, bortimot Hamat. 
4:13:22: De drog op til sydlandet og kom til Hebron; der bodde Akiman, Sesai og Talmai, Anaks barn - Hebron var bygget syv år før Soan i Egypten. 
4:13:23: Så kom de til Eskol-dalen; der skar de av en vinranke med en drueklase, og den bar to mann mellem sig på en stang; likeledes tok de granatepler og fikener. 
4:13:24: Dette sted blev kalt Eskol-dalen* efter den drueklase som Israels barn skar av der. # <* d.e. drueklasens dal.> 
4:13:25: Da firti dager var til ende, vendte de tilbake efter å ha utspeidet landet. 
4:13:26: De gikk avsted og fant Moses og Aron og hele Israels barns menighet i ørkenen Paran, i Kades, og gav dem og hele menigheten beskjed om hvad de hadde sett, og viste dem landets frukt. 
4:13:27: De fortalte ham og sa: Vi kom til det land du sendte oss til, og det flyter virkelig med melk og honning, og her er dets frukt. 
4:13:28: Men det folk som bor i landet, er sterkt, og byene er som festninger og meget store; der så vi også Anaks barn. 
4:13:29: Amalekittene bor i sydlandet, og hetittene og jebusittene og amorittene bor i fjellbygdene, og kana'anittene bor ute ved havet og langsmed Jordan. 
4:13:30: Men da folket knurret mot Moses, søkte Kaleb å stagge dem og sa: Vi vil dra op og ta landet i eie; vi skal nok få det i vår makt. 
4:13:31: Men de menn som hadde vært med ham der op, sa: Vi makter ikke å dra op mot det folk; for det er sterkere enn vi. 
4:13:32: Og de talte ille blandt Israels barn om det land de hadde utspeidet, og sa: Det land vi drog igjennem for å utspeide det, er et land som fortærer* sine innbyggere, og alle de vi så der, var høivoksne folk; # <* ved de kriger som føres der.> 
4:13:33: der så vi kjempene, Anaks barn av kjempeætten, og mot dem var vi i våre egne øine som gresshopper, og det syntes også de at vi var. 
4:14:1: Da tok hele menigheten til å rope og skrike, og folket gråt hele natten. 
4:14:2: Og alle Israels barn knurret mot Moses og Aron, og hele menigheten sa til dem: Gid vi var død i Egyptens land eller her i ørkenen! Å, at vi var død! 
4:14:3: Hvorfor fører Herren oss inn i dette land, så vi må falle for sverdet? Våre hustruer og våre barn vil bli til rov. Var det ikke bedre for oss å vende tilbake til Egypten? 
4:14:4: Og de sa til hverandre: La oss velge oss en høvding og vende tilbake til Egypten! 
4:14:5: Da falt Moses og Aron ned på sitt ansikt foran hele den forsamlede menighet av Israels barn. 
4:14:6: Og Josva, Nuns sønn, og Kaleb, Jefunnes sønn, som var blandt dem som hadde utspeidet landet, sønderrev sine klær 
4:14:7: og talte til hele Israels barns menighet og sa: Det land som vi drog igjennem for å utspeide det, er et overmåte godt land. 
4:14:8: Dersom Herren har velbehag i oss, så fører han oss inn i dette land og gir oss det - et land som flyter med melk og honning. 
4:14:9: Sett eder bare ikke op mot Herren og vær ikke redde for folket i det land, for vi skal fortære dem som det var brød; deres vern er veket fra dem, og Herren er med oss, vær ikke redde for dem! 
4:14:10: Da vilde hele menigheten stene dem; men Herrens herlighet åpenbarte sig i sammenkomstens telt for alle Israels barn. 
4:14:11: Og Herren sa til Moses: Hvor lenge skal dette folk forakte mig, og hvor lenge vil de la være å tro på mig, enda jeg har gjort så mange tegn iblandt dem? 
4:14:12: Jeg vil slå dem med pest og utrydde dem, og så vil jeg gjøre dig til et større og sterkere folk enn dette. 
4:14:13: Da sa Moses til Herren: Egypterne har hørt at du med din kraft har ført dette folk ut fra dem, 
4:14:14: og de har sagt det til dette lands innbyggere; de har hørt at du, Herre, er midt iblandt dette folk, at du, Herre, har åpenbaret dig for dem øie til øie, og at din sky står over dem, og at du går foran dem i en skystøtte om dagen og i en ildstøtte om natten. 
4:14:15: Men dreper du nu dette folk, alle som en, da kommer hedningene, som har hørt ditt ry, til å si: 
4:14:16: Herren maktet ikke å føre dette folk inn i det land han hadde tilsvoret dem, derfor slaktet han dem ned i ørkenen. 
4:14:17: Men la nu din kraft, Herre, vise sig stor, som du har talt og sagt: 
4:14:18: Herren er langmodig og rik på miskunnhet, han forlater misgjerning og overtredelse; men han lar ikke den skyldige ustraffet, han hjemsøker fedres misgjerning på barn, på dem i tredje og på dem i fjerde ledd. 
4:14:19: Tilgi da dette folk dets misgjerning efter din store miskunnhet, som du har tilgitt dem hele veien fra Egypten og hit! 
4:14:20: Da sa Herren: Jeg har tilgitt dem efter ditt ord. 
4:14:21: Men sa sant jeg lever og hele jorden er full av Herrens herlighet, 
4:14:22: så skal alle de menn som har sett min herlighet og de tegn som Jeg har gjort i Egypten og i ørkenen, og som nu har fristet mig ti ganger og ikke hørt på min røst, 
4:14:23: sannelig, de skal ikke se det land jeg bar tilsvoret deres fedre; ingen som har foraktet mig, skal få se det 
4:14:24: Men min tjener Kaleb - fordi det var en annen ånd i ham, og han trolig fulgte mig, så vil jeg føre ham inn i det land han har vært i, og hans ætt skal eie det. 
4:14:25: Men amalekittene og kana'anittene bor her i dalen; vend derfor om imorgen og dra ut i ørkenen på veien til det Røde Hav! 
4:14:26: Og Herren talte til Moses og Aron og sa: 
4:14:27: Hvor lenge skal denne onde menighet holde på å knurre mot mig? Jeg har hørt Israels barns knurr, hvorledes de knurrer mot mig. 
4:14:28: Si til dem: Så sant jeg lever, sier Herren: Som I har talt for mine ører*, således vil jeg gjøre med eder: # <* 4MO 14, 2.> 
4:14:29: I denne ørken skal eders døde kropper falle, alle de blandt eder som blev mønstret, så mange som I er, fra tyveårsalderen og opover, I som har knurret mot mig; 
4:14:30: sannelig, I skal ikke komme inn i det land som jeg med opløftet hånd har svoret å ville la eder bo i, undtagen Kaleb, Jefunnes sønn, og Josva, Nuns sønn. 
4:14:31: Eders barn, som I sa vilde bli til rov, dem vil jeg føre der inn, og de skal lære det land å kjenne som I har ringeaktet. 
4:14:32: Men eders døde kropper skal falle i denne ørken. 
4:14:33: Og eders barn skal flakke om som hyrder i ørkenen i firti år og bøte for eders utroskap mot Herren, inntil I alle er blitt til lik i ørkenen. 
4:14:34: Likesom I utspeidet landet i firti dager, således skal I lide for eders misgjerninger i firti år, et år for hver dag; og I skal kjenne at jeg har vendt mig bort fra eder. 
4:14:35: Jeg, Herren, har sagt: Sannelig, således vil jeg gjøre med hele denne onde menighet, som har sammensvoret sig mot mig; her i ørkenen skal de gå til grunne, her skal de dø. 
4:14:36: De menn som Moses hadde sendt for å utspeide landet, og som var kommet tilbake og hadde fått hele menigheten til å knurre mot ham ved å tale ille om landet, 
4:14:37: disse menn, som hadde talt ille om landet, blev slått ned for Herrens åsyn og døde. 
4:14:38: Bare Josva, Nuns sønn, og Kaleb, Jefunnes sønn, blev i live av de menn som var gått avsted for å utspeide landet. 
4:14:39: Og Moses bar disse ord frem for alle Israels barn; da sørget folket sårt. 
4:14:40: Og de stod tidlig op om morgenen og drog opover mot fjellhøiden og sa: Se, her er vi, og vi vil dra op til det sted som Herren har talt om; for vi har syndet. 
4:14:41: Men Moses sa: Hvorfor overtreder I Herrens bud? Det vil ikke lykkes. 
4:14:42: Dra ikke der op! For Herren er ikke blandt eder, I kommer bare til å bli slått av eders fiender. 
4:14:43: For amalekittene og kana'anittene vil møte eder der, og I skal falle for sverdet, fordi I har vendt eder bort fra Herren, og Herren vil ikke være med eder. 
4:14:44: Men de drog i overmodig tross op til fjellhøiden; men Herrens pakts ark og Moses forlot ikke leiren. 
4:14:45: Da kom amalekittene og kana'anittene, som bodde der på fjellet, ned og slo dem sønder og sammen og forfulgte dem like til Horma. 
4:15:1: Og Herren talte til Moses og sa: 
4:15:2: Tal til Israels barn og si til dem: Når I kommer inn i det land som I skal bo i, det som jeg vil gi eder, 
4:15:3: og I ofrer Herren ildoffer - brennoffer eller slaktoffer av storfeet eller av småfeet til en velbehagelig duft for Herren - enten for å opfylle et løfte eller som et frivillig offer eller på eders høitider, 
4:15:4: da skal den som bærer sitt offer frem for Herren, som matoffer ofre tiendedelen av en efa fint mel blandet med en fjerdedel av en hin olje, 
4:15:5: og som drikkoffer skal du ofre fjerdedelen av en hin vin sammen med brennofferet eller slaktofferet, hvis det er et lam. 
4:15:6: Men hvis det er en vær, da skal du ofre som matoffer to tiendedeler av en efa fint mel blandet med en tredjedel av en hin olje, 
4:15:7: og som drikkoffer tredjedelen av en hin vin; du skal ofre det til en velbehagelig duft for Herren. 
4:15:8: Ofrer du en ung okse til brennoffer eller slaktoffer for å opfylle et løfte eller som takkoffer til Herren, 
4:15:9: da skal der sammen med den unge okse ofres som matoffer tre tiendedeler av en efa fint mel blandet med en halv hin olje, 
4:15:10: og som drikkoffer skal du ofre en halv hin vin; det er et ildoffer til en velbehagelig duft for Herren. 
4:15:11: Således skal der gjøres for hver okse og for hver vær og for hvert lam eller kje. 
4:15:12: Efter tallet på de dyr I ofrer, skal I gjøre således for hvert enkelt dyr, så mange som de er. 
4:15:13: Hver innfødt skal gjøre således når han ofrer et ildoffer til en velbehagelig duft for Herren. 
4:15:14: Og hvis en fremmed som holder til hos eder, eller som for alltid bor blandt eder, ofrer et ildoffer til en velbehagelig duft for Herren, så skal han gjøre like ens som I. 
4:15:15: I menigheten skal det være én lov for eder og for den fremmede som holder til hos eder - en evig lov, fra slekt til slekt; for Herrens åsyn gjelder det samme for den fremmede som for eder selv. 
4:15:16: Én lov og én rett skal det være for eder og for den fremmede som holder til hos eder. 
4:15:17: Og Herren talte til Moses og sa: 
4:15:18: Tal til Israels barn og si til dem: Når I kommer inn i det land som jeg fører eder til, 
4:15:19: og eter av landets brød, da skal I utrede en offergave til Herren. 
4:15:20: Av det første av eders deig skal I gi en kake som gave; I skal gi den likesom I utreder gaven fra treskeplassen. 
4:15:21: Av det første av eders deig skal I alltid gi Herren en gave, slekt efter slekt. 
4:15:22: Når I uforvarende gjør en synd og ikke holder alle disse bud som Herren har forkynt Moses, 
4:15:23: alt det som Herren har befalt eder ved Moses fra den dag av da Herren befalte det, og siden gjennem alle følgende slekter, 
4:15:24: da skal hele menigheten, hvis det er gjort uten dens vitende og av vanvare, ofre en ung okse som brennoffer til en velbehagelig duft for Herren med tilhørende matoffer og drikkoffer, som foreskrevet er, og en gjetebukk som syndoffer. 
4:15:25: Og presten skal gjøre soning for hele Israels barns menighet, så får de forlatelse; for det var gjort av vanvare, og de har båret frem for Herrens åsyn sitt offer, et ildoffer til Herren, og sitt syndoffer for den synd de har gjort av vanvare. 
4:15:26: Og hele Israels barns menighet og den fremmede som holder til hos dem, får forlatelse, fordi det var hendt hele folket av vanvare. 
4:15:27: Men når en enkelt mann synder av vanvare, da skal han ofre en årsgammel gjet til syndoffer. 
4:15:28: Og presten skal gjøre soning for Herrens åsyn for den som har forsyndet sig av vanvare, så han, når soningen for ham er fullført, kan få forlatelse. 
4:15:29: For den innfødte blandt Israels barn og for den fremmede som holder til hos dem, skal det være én lov; den gjelder for eder alle når nogen gjør noget av vanvare. 
4:15:30: Men den som gjør noget med opløftet hånd*, enten det er en innfødt eller en fremmed, han håner Herren, og han skal utryddes av sitt folk; # <* av tross, med vitende og vilje.> 
4:15:31: for han har ringeaktet Herrens ord og brutt hans bud. Den mann skal utryddes, han skal lide for sin misgjerning. 
4:15:32: Mens Israels barn var i ørkenen, traff de på en mann som sanket ved på sabbatsdagen. 
4:15:33: Og de som traff på ham da han sanket ved, førte ham frem for Moses og Aron og for hele menigheten. 
4:15:34: Og de satte ham fast; for det var ikke gitt dem noget bud om hvad der skulde gjøres med ham. 
4:15:35: Da sa Herren til Moses: Mannen skal late livet, hele menigheten skal stene ham utenfor leiren. 
4:15:36: Så førte hele menigheten ham utenfor leiren og stenet ham, så han døde, således som Herren hadde befalt Moses. 
4:15:37: Og Herren sa til Moses: 
4:15:38: Tal til Israels barn og si til dem at de skal gjøre sig dusker på kantene av sine klær, slekt efter slekt, og de skal sette en blå snor på hver enkelt dusk. 
4:15:39: Sådanne dusker skal I ha, forat I, når I ser på dem, skal komme i hu alle Herrens bud og holde dem og ikke fare hit og dit efter eders hjerte og efter eders øine, som lokker eder til utroskap 
4:15:40: - forat I skal komme i hu alle mine bud og holde dem og være hellige for eders Gud. 
4:15:41: Jeg er Herren eders Gud, som førte eder ut av Egyptens land for å være eders Gud. Jeg er Herren eders 
4:16:1: Korah, en sønn av Jishar, sønn av Kahat, Levis sønn, og Datan og Abiram, sønner av Eliab, og On, en sønn av Pelet, av Rubens ætt, tok en flokk med sig. 
4:16:2: De trådte fram for Moses, og sammen med dem to hundre og femti menn av Israels barn, høvdinger i menigheten, utkåret til medlemmer av folkerådet, aktede menn. 
4:16:3: De flokket sig sammen mot Moses og Aron og sa til dem: Nu får det være nok! Hele menigheten er hellig, alle sammen, og Herren er midt iblandt dem; hvorfor ophøier I eder da over Herrens menighet? 
4:16:4: Da Moses hørte disse ord, falt han på sitt ansikt. 
4:16:5: Og han talte til Korah og hele hans flokk og sa: Imorgen skal Herren åpenbare hvem som tilhører ham, og hvem som er hellig, så han lar ham komme nær til sig; og den som han utvelger, ham vil han la komme nær til sig. 
4:16:6: Gjør nu dette: Ta eder ildkar, både Korah og hele hans flokk, 
4:16:7: og ha ild i dem og legg røkelse på dem for Herrens åsyn imorgen, og den mann som Herren utvelger, han skal være den hellige. Det får nu være nok, I Levis barn! 
4:16:8: Og Moses sa til Korah: Hør nu, I Levis barn! 
4:16:9: Er det for lite for eder at Israels Gud har utskilt eder fra Israels menighet for å la eder komme nær til sig, så I skal utføre tjenesten ved Herrens tabernakel og stå for menighetens åsyn for å tjene den? 
4:16:10: Han har latt dig komme nær til, og med dig alle dine brødre, Levis barn; og nu attrår I endog prestedømmet! 
4:16:11: Derfor er du og hele din flokk oprørere mot Herren; for hvad er Aron, at I knurrer mot ham? 
4:16:12: Så sendte Moses bud efter Datan og Abiram, Eliabs sønner; men de sa: Vi kommer ikke. 
4:16:13: Er det ikke nok at du har ført oss op fra et land som flyter med melk og honning, for å la oss dø i ørkenen, siden du endog vil gjøre dig til hersker over oss? 
4:16:14: Heller ikke har du ført oss inn i et land som flyter med melk og honning, eller gitt oss åkrer og vingårder i eie; tenker du du kan synkverve disse folk? Vi kommer ikke 
4:16:15: Da blev Moses meget vred og sa til Herren: Vend dig ikke til deres offer! Ikke så meget som et asen har jeg tatt fra dem, og ikke en eneste blandt dem har jeg gjort noget ondt. 
4:16:16: Så sa Moses til Korah: Imorgen skal du og hele din flokk stille eder frem for Herrens åsyn, du og de og Aron! 
4:16:17: Og I skal ta hver sitt ildkar og legge røkelse på dem, og I skal bære hver sitt ildkar frem for Herrens åsyn, to hundre og femti ildkar, og du og Aron skal og ta hver sitt ildkar. 
4:16:18: Så tok de hver sitt ildkar og hadde ild i dem og la røkelse på dem; og de stilte sig ved inngangen til sammenkomstens telt, og likeså Moses og Aron. 
4:16:19: Og Korah samlet hele menigheten imot dem ved inngangen til sammenkomstens telt; da åpenbarte Herrens herlighet sig for hele menigheten. 
4:16:20: Og Herren talte til Moses og Aron og sa: 
4:16:21: Skill eder ut fra denne menighet, så vil jeg gjøre ende på dem i et øieblikk. 
4:16:22: Men de falt ned på sitt ansikt og sa: Gud, du Gud som råder over livsens ånde i alt kjød! Vredes du på hele menigheten fordi om én mann synder? 
4:16:23: Da talte Herren til Moses og sa: 
4:16:24: Tal til menigheten og si: Gå bort fra hele plassen rundt omkring Korahs, Datans og Abirams bolig! 
4:16:25: Så stod Moses op og gikk til Datan og Abiram, og de eldste i Israel fulgte ham. 
4:16:26: Og han talte til menigheten og sa: Vik bort fra disse ugudelige menns telter, og rør ikke ved noget av det som hører dem til, forat I ikke skal bli bortrykket for alle deres synders skyld! 
4:16:27: Da gikk de bort fra Korahs, Datans og Abirams bolig, rundt omkring; men Datan og Abiram var gått ut og stod i inngangen til sine telt med sine hustruer og sine barn, både store og små. 
4:16:28: Og Moses sa: Derpå skal I kjenne at det er Herren som har sendt mig til å gjøre alle disse gjerninger, og at det ikke er av egen drift jeg gjør det: 
4:16:29: Dersom disse folk dør på samme måte som alle andre mennesker, eller de hjemsøkes på samme måte som alle andre mennesker, da har Herren ikke sendt mig; 
4:16:30: men dersom Herren gjør noget som ikke før er hendt - dersom jorden lukker op sin munn og sluker dem og alle deres, så de farer levende ned i dødsriket, da vet I at disse menn har foraktet Herren. 
4:16:31: Med det samme han hadde endt denne tale, da revnet jorden under dem; 
4:16:32: jorden lukket op sin munn og slukte dem og deres boliger og alle de folk som hørte Korah til, og alt det de eide, 
4:16:33: og de fòr levende ned i dødsriket med alle sine; jorden skjulte dem, og de omkom og blev utryddet av menigheten. 
4:16:34: Og hele Israel som stod rundt omkring dem, rømte da de hørte deres skrik; de sa: Jorden kunde sluke oss og. 
4:16:35: Og det fòr ild ut fra Herren og fortærte de to hundre og femti menn som hadde båret frem røkelsen. 
4:16:36: Da talte Herren til Moses og sa: 
4:16:37: Si til Eleasar, Arons sønn, presten, at han skal ta ildkarene ut av branden, men sprede glørne langt bort! For de er hellige, 
4:16:38: de ildkar som tilhørte disse menn som ved sin synd har forbrutt sitt liv; der skal gjøres tynne plater av dem til å klæ alteret med, for de bar dem frem for Herrens åsyn, og derfor er de hellige; og de skal være til et tegn for Israels barn. 
4:16:39: Så tok Eleasar, presten, de ildkar av kobber som de hadde båret frem de menn som var brent op, og lot dem hamre ut til plater til å klæ alteret med, 
4:16:40: forat Israels barn skulde komme i hu at ingen fremmed mann - ingen som ikke er av Arons ætt - skulde komme nær til for å brenne røkelse for Herrens åsyn, så det ikke skulde gå ham som det gikk Korah og hans flokk - således som Herren hadde talt til ham ved Moses. 
4:16:41: Men dagen efter knurret hele Israels barns menighet mot Moses og Aron og sa: Det er I som har slått Herrens folk ihjel! 
4:16:42: Da nu menigheten samlet sig imot Moses og Aron, vendte de sig begge mot sammenkomstens telt, og se, skyen dekket det, og Herrens herlighet åpenbarte sig. 
4:16:43: Og Moses og Aron gikk frem foran sammenkomstens telt, 
4:16:44: og Herren talte til Moses og sa: 
4:16:45: Gå bort fra denne menighet, så vil jeg gjøre ende på dem i et øieblikk. Da falt de ned på sitt ansikt, 
4:16:46: og Moses sa til Aron: Ta ildkaret og ha ild fra alteret i det og legg røkelse på og skynd dig så bort til menigheten med det og gjør soning for dem! For vreden er gått ut fra Herrens åsyn, hjemsøkelsen har begynt. 
4:16:47: Og Aron gjorde som Moses hadde sagt, og tok ildkaret og løp midt inn i flokken, og se, hjemsøkelsen hadde alt begynt blandt folket. Så la han røkelsen på og gjorde soning for folket, 
4:16:48: mens han stod der mellem de døde og de levende; da stanset hjemsøkelsen. 
4:16:49: Men det var fjorten tusen og syv hundre mann som døde under hjemsøkelsen, foruten dem som døde for Korahs skyld. 
4:16:50: Og Aron vendte tilbake til Moses ved inngangen til sammenkomstens telt; og hjemsøkelsen var stanset. 
4:17:1: Og Herren talte til Moses og sa: 
4:17:2: Tal til Israels barn, og få tolv staver av dem, en stav for hver stamme; av hver stammehøvding skal du få en stav, og på den skal du skrive hans navn. 
4:17:3: Og Arons navn skal du skrive på Levis stav; for det skal være en stav for hvert overhode over deres stammer. 
4:17:4: Og du skal legge dem i sammenkomstens telt foran vidnesbyrdet, der hvor jeg kommer sammen med eder. 
4:17:5: Da skal det skje at den manns stav som jeg utvelger, skal spire; så får jeg vel fred for Israels barns knurr, som de plager eder med. 
4:17:6: Så talte Moses til Israels barn, og alle deres høvdinger gav ham hver sin stav, en stav for hver stamme, i alt tolv staver; og Arons stav var mellem deres staver. 
4:17:7: Og Moses la stavene ned for Herrens åsyn i vidnesbyrdets telt. 
4:17:8: Da nu Moses dagen efter kom inn i vidnesbyrdets telt, fikk han se at Arons stav - den som var for Levis hus - hadde spiret; den hadde både skutt spirer og satt blomster og fått modne mandler. 
4:17:9: Da bar Moses alle stavene ut fra Herrens åsyn og viste dem til alle Israels barn; og de så dem og tok hver sin stav. 
4:17:10: Og Herren sa til Moses: Bær Arons stav inn igjen foran vidnesbyrdet for å gjemmes som et tegn for de gjenstridige; og du skal gjøre ende på deres knurr, så jeg slipper å høre på det; ellers skal de dø. 
4:17:11: Og Moses gjorde så; han gjorde som Herren hadde befalt ham. 
4:17:12: Men Israels barn sa til Moses: Se, vi omkommer, vi er fortapt, vi er fortapt alle sammen! 
4:17:13: Enhver som kommer nær - som kommer nær til Herrens tabernakel, han må dø. Skal vi da omkomme alle sammen? 
4:18:1: Da sa Herren til Aron: Du og dine sønner og hele din fars hus skal bære skylden for overtredelser mot helligdommen, og du og dine sønner skal bære skylden for overtredelser mot eders presteembede. 
4:18:2: Også dine brødre, Levi stamme, din fars stamme, skal du la komme nær til sammen med dig, - og de skal holde sig til dig og tjene dig; men du og dine sønner, I skal stå foran vidnesbyrdets telt. 
4:18:3: Og de skal ta vare på det du skulde varetatt, og det som er å vareta ved hele teltet; men helligdommens redskaper* og alteret må de ikke komme nær, forat de ikke skal dø, både de og I. # <* 4MO 4, 5 fg.> 
4:18:4: De skal holde sig til dig og ta vare på det som er å vareta ved sammenkomstens telt, hele arbeidet ved teltet; men ingen fremmed må komme nær til eder. 
4:18:5: Men I skal ta vare på det som skal varetas ved helligdommen, og det som er å vareta ved alteret, forat det ikke mere skal komme vrede over Israels barn. 
4:18:6: For det er jeg som har tatt eders brødre levittene ut blandt Israels barn som en gave til eder, overgitt til Herren for å utføre tjenesten ved sammenkomstens telt. 
4:18:7: Men du og dine sønner skal ta vare på eders prestetjeneste i alt som vedkommer alteret og gjerningen innenfor forhenget, og utføre tjenesten. Jeg gir eder eders prestetjeneste som en gave; men den fremmede som kommer nær til, skal late livet. 
4:18:8: Og Herren talte til Aron: Se, jeg har gitt dig det som skal gjemmes av gavene til mig; alt det som Israels barn helliger, har jeg gitt dig som ditt embedes del og dine sønner som en evig rettighet. 
4:18:9: Dette skal høre dig til av det høihellige og undtas fra ilden: alle deres offer, både matoffer og syndoffer og skyldoffer, som de gir mig til gjengjeld; de er høihellige og skal høre dig og dine sønner til. 
4:18:10: På det høihellige sted skal du ete det; alt mannkjønn kan ete av det, hellig skal det være for dig. 
4:18:11: Likeledes skal dette høre dig til som de gaver de skal avgi: Alle Israels barns svingegaver, dem har jeg gitt dig og dine sønner og dine døtre som en evig rettighet; enhver i ditt hus som er ren, kan ete av det. 
4:18:12: Alt det beste av olje og alt det beste av most og korn, førstegrøden derav, som de skal gi Herren, har jeg gitt dig. 
4:18:13: Førstegrøden av alt det som er i deres land, som de kommer til Herren med, skal høre dig til; enhver i ditt hus som er ren, kan ete av det. 
4:18:14: Alt bannlyst i Israel skal høre dig til. 
4:18:15: Alt som åpner morsliv, alt levende som de kommer til Herren med, enten det er mennesker eller dyr, skal høre dig til. Dog skal du la dem løse det som er førstefødt av mennesker, og likeså skal du la dem løse det som er førstefødt av de urene dyr. 
4:18:16: Og løsepengene for en gutt skal du la dem utrede fra han er en måned gammel, efter det verd du skal sette på ham: fem sekel sølv efter helligdommens vekt, sekelen regnet til tyve gera. 
4:18:17: Men det førstefødte av kyr eller av får eller av gjeter skal ikke løses; de er hellige; deres blod skal du sprenge på alteret, og deres fett skal du brenne som ildoffer til en velbehagelig duft for Herren. 
4:18:18: Men deres kjøtt skal høre dig til; likesom svinge-brystet og det høire lår skal det høre dig til. 
4:18:19: Alle hellige gaver som Israels barn skal avgi til Herren, har jeg gitt dig og dine sønner og dine døtre som en evig rettighet; dette er en evig saltpakt* for Herrens åsyn for dig og dine efterkommere. # <* d.e. en ubrytelig pakt.> 
4:18:20: Og Herren sa til Aron: Du skal ikke ha nogen arv i deres land, og det skal ikke tilfalle dig nogen del iblandt dem; jeg er din del og din arv blandt Israels barn. 
4:18:21: Men all tiende i Israel har jeg gitt Levis barn til arv, som lønn for den tjeneste de utfører, tjenesten ved sammenkomstens telt. 
4:18:22: Israels barn skal ikke mere komme nær til sammenkomstens telt, for da fører de synd over sig og må dø. 
4:18:23: Men levittene, de skal utføre tjenesten ved sammenkomstens telt, og de skal bære skylden for folkets overtredelser; det skal være en evig lov, fra slekt til slekt. Men nogen arvedel skal de ikke ha blandt Israels barn. 
4:18:24: For den tiende som Israels barn skal avgi til Herren, har jeg gitt levittene til arv; derfor har jeg sagt til dem at de ikke skal ha nogen arv blandt Israels barn. 
4:18:25: Og Herren talte til Moses og sa: 
4:18:26: Du skal tale til levittene og si til dem: Når I tar imot den tiende av Israels barn som jeg har sagt I skal få av dem som eders arv da skal I gi Herren en gave av den - tiendedelen av tienden, 
4:18:27: og denne eders gave skal regnes for eder som jevngod med kornet fra treskeplassen og med vinen fra persen. 
4:18:28: Således skal også I avgi en gave til Herren av all den tiende som I får av Israels barn, og denne gave til Herren av eders tiende skal I gi til Aron, presten. 
4:18:29: Av alle de gaver I får, skal I avgi til Herren hele den gave som tilkommer ham; det beste av enhver gave skal være den del som helliges ham. 
4:18:30: Og du skal si til dem: Når I har avgitt den beste del av det, da skal det regnes for levittene likt med det som kommer inn fra treskeplassen og fra vinpersen. 
4:18:31: I kan ete det hvor I vil, I og eders husfolk; for det er den lønn I får for eders tjeneste ved sammenkomstens telt. 
4:18:32: Når I således avgir det beste av det, kommer I ikke til å føre synd over eder, og da vanhelliger I ikke Israels barns hellige gaver og skal ikke dø. 
4:19:1: Og Herren talte til Moses og Aron og sa: 
4:19:2: Dette er et lovbud som Herren har kunngjort: Si til Israels barn at de skal komme til dig med en rød kvige som ikke har lyte eller feil, og som det ikke er kommet åk på. 
4:19:3: Den skal I gi til Eleasar, presten, og han skal føre den utenfor leiren, og de skal slakte den for hans åsyn. 
4:19:4: Og Eleasar, presten, skal ta noget av dens blod på sin finger, og han skal sprenge med blodet syv ganger bortimot forsiden av sammenkomstens telt. 
4:19:5: Så skal de brenne kvigen for hans øine; både dens hud og dens kjøtt og dens blod, og skarnet med, skal de brenne. 
4:19:6: Og presten skal ta sedertre og isop og karmosinrød ull og kaste det i ilden, der hvor kvigen brennes. 
4:19:7: Derefter skal presten tvette sine klær og bade sitt legeme i vann, så kan han komme inn i leiren; men han skal være uren til om aftenen. 
4:19:8: Den mann som brenner kvigen, skal og tvette sine klær i vann og bade sitt legeme i vann; men han skal være uren til om aftenen. 
4:19:9: Og en mann som er ren, skal sanke sammen asken av kvigen og legge den utenfor leiren på et rent sted, og den skal gjemmes for Israels barns menighet til renselsesvann; det er et syndoffer. 
4:19:10: Og den som sanker sammen asken av kvigen, skal tvette sine klær og være uren til om aftenen. Dette skal være en evig lov for Israels barn og for den fremmede som bor iblandt dem. 
4:19:11: Den som rører ved en død, ved liket av et menneske, han skal være uren i syv dager. 
4:19:12: Den tredje og den syvende dag skal han rense sig med vannet,* så blir han ren; men renser han sig ikke den tredje dag og den syvende dag, da blir han ikke ren. # <* renselsesvannet, 4MO 19, 9.> 
4:19:13: Hver den som rører ved en død, ved liket av et menneske som er død, og ikke renser sig, han gjør Herrens tabernakel urent, han skal utryddes av Israel; fordi det ikke er sprengt renselsesvann på ham, er han uren, hans urenhet kleber fremdeles ved ham. 
4:19:14: Dette er loven: Når et menneske dør i et telt, skal hver den som kommer inn i teltet, og alt det som er i teltet, være urent i syv dager. 
4:19:15: Og ethvert åpent kar som der ikke er bundet dekke over, er urent. 
4:19:16: Og hver den som ute på marken rører ved en som er drept med sverd, eller ved et annet lik eller ved menneskeben eller ved en grav, skal være uren i syv dager. 
4:19:17: Og for å rense den som således er blitt uren, skal de ta noget av asken efter det brente syndoffer og helle rinnende vann på det i et kar. 
4:19:18: Og en mann som er ren, skal ta Isop og dyppe i vannet og sprenge på teltet og på alle de ting og alle de folk som var der, og på den som har rørt ved menneskeben eller ved en som er drept, eller ved et annet lik eller ved en grav. 
4:19:19: Og den tredje dag og den syvende dag skal den rene sprenge noget av vannet på den som er blitt uren; og når hans renselse er fullført på den syvende dag, skal han tvette sine klær og bade sig i vann, så blir han ren om aftenen. 
4:19:20: Men den som blir uren og ikke renser sig, han skal utryddes av menigheten; for han har gjort Herrens helligdom uren; det er ikke sprengt renselsesvann på ham, han er uren. 
4:19:21: Dette skal være en evig lov for dem. Den som sprenger med renselsesvannet, skal tvette sine klær, og den som rører ved renselsesvannet, skal være uren til om aftenen. 
4:19:22: Og alt det den urene rører ved, skal være urent, og den som rører ved ham, skal være uren til om aftenen. 
4:20:1: I den første måned* kom Israels barn, hele menigheten, til ørkenen Sin, og folket blev nogen tid i Kades; der døde Mirjam, og der blev hun begravet; # <* i det firtiende år, 4MO 33, 38.>
4:20:2: Men menigheten hadde ikke vann; da samlet de sig mot Moses og Aron. 
4:20:3: Og folket kivedes med Moses og sa: Å, at vi var omkommet dengang våre brødre omkom for Herrens åsyn! 
4:20:4: Hvorfor har I ført Herrens menighet inn i denne ørken, så vi må dø her, både vi og vår buskap? 
4:20:5: Og hvorfor har I ført oss op fra Egypten, så vi er kommet til dette onde sted, hvor det hverken vokser korn eller fiken eller vintrær eller granatepler, og hvor det ikke finnes vann å drikke? 
4:20:6: Men Moses og Aron gikk bort fra folket, til inngangen til sammenkomstens telt, og falt ned på sitt ansikt; da åpenbarte Herrens herlighet sig for dem. 
4:20:7: Og Herren talte til Moses og sa: 
4:20:8: Ta staven og kall menigheten sammen, du og Aron, din bror, og I skal tale til klippen midt for deres øine, så skal den gi vann fra sig; således skal du la vann strømme ut av klippen for dem og gi både menigheten og dens buskap å drikke. 
4:20:9: Da tok Moses staven som lå foran Herrens åsyn, således som Herren hadde befalt ham. 
4:20:10: Og Moses og Aron kalte menigheten sammen foran klippen, og han sa til dem: Nu, I gjenstridige! Mon vi kan la vann strømme frem for eder av denne klippe? 
4:20:11: Så løftet Moses sin hånd og slo med sin stav to ganger på klippen; da strømmet der meget vann ut, så både menigheten og dens buskap fikk drikke. 
4:20:12: Men Herren sa til Moses og Aron: Fordi I ikke trodde på mig og ikke helliget mig for Israels barns øine, derfor skal I ikke føre dette folk inn i det land jeg har gitt dem. 
4:20:13: Dette var Meribas vann, hvor Israels barn kivedes med Herren, og han helliget sig på dem. 
4:20:14: Fra Kades sendte Moses bud til Edoms konge og lot si: Så sier din bror Israel: Du vet hvor meget ondt vi har lidt. 
4:20:15: Våre fedre drog ned til Egypten, og vi bodde i Egypten i lang tid, og egypterne fór ille med oss og våre fedre. 
4:20:16: Da ropte vi til Herren, og han hørte vår bønn og sendte en engel og førte oss ut av Egypten. Og nu er vi her i byen Kades like ved grensen av ditt land. 
4:20:17: Kjære, la oss få dra gjennem ditt land! Vi skal hverken gå gjennem åker eller vingård, heller ikke drikke vann av nogen brønn; efter kongeveien vil vi dra og ikke bøie av hverken til høire eller til venstre, før vi er kommet gjennem ditt land. 
4:20:18: Men Edom svarte: Du må ikke dra gjennem mitt land, ellers drar jeg ut mot dig med sverd. 
4:20:19: Da sa Israels barn til ham: Vi skal følge landeveien, og dersom vi eller vår buskap drikker av ditt vann, så vil jeg gi dig vederlag for det. Det er ikke stort jeg ber om; jeg vil bare dra igjennem på min fot. 
4:20:20: Men han sa: Du må ikke dra igjennem her. Og Edom drog ut mot ham med en mengde folk og med væbnet hånd. 
4:20:21: Således nektet Edom Israel å dra gjennem sitt land; og Israel bøide til side for ham. 
4:20:22: Så brøt de op fra Kades, og Israels barn, hele menigheten, kom til fjellet Hor. 
4:20:23: Og Herren sa til Moses og Aron ved fjellet Hor, på grensen av Edoms land: 
4:20:24: Aron skal samles til sine fedre; han skal ikke komme inn i det land jeg har gitt Israels barn, fordi I var gjenstridige mot mitt ord ved Meribas vann. 
4:20:25: Ta Aron og Eleasar, hans sønn, og før dem op på fjellet Hor, 
4:20:26: og ta av Aron hans klær og la Eleasar, hans sønn, ta dem på; så skal Aron samles til sine fedre og dø der. 
4:20:27: Og Moses gjorde som Herren hadde befalt. De gikk op på fjellet Hor for hele menighetens øine, 
4:20:28: og Moses tok av Aron hans klær og lot Eleasar, hans sønn, ta dem på, og Aron døde der på toppen av fjellet; men Moses og Eleasar steg ned fra fjellet. 
4:20:29: Og da hele menigheten så at Aron var død, gråt hele Israels hus over Aron i tretti dager. 
4:21:1: Da den kana'anittiske konge i Arad, som bodde i sydlandet, hørte at Israel drog frem på veien til Atarim, gav han sig i strid med Israel og førte nogen av dem bort som fanger. 
4:21:2: Da gjorde Israel et løfte til Herren og sa: Gir du dette folk i min hånd, så vil jeg slå deres byer med bann. 
4:21:3: Og Herren hørte Israels bønn og overgav kana'anittene til dem, og de slo dem og deres byer med bann; og de kalte stedet Horma*. # <* bann.> 
4:21:4: Så brøt de op fra fjellet Hor og tok veien til det Røde Hav for å dra omkring Edoms land; men på veien blev folket utålmodig, 
4:21:5: og de talte mot Gud og mot Moses og sa: Hvorfor har I ført oss op fra Egypten, så vi må dø her i ørkenen? For her er hverken brød eller vann, og vi er inderlig leie av denne usle mat. 
4:21:6: Da sendte Herren seraf-slanger* inn iblandt folket, og de bet folket; og der døde meget folk av Israel. # <* brennende d.e. giftige slanger.> 
4:21:7: Så kom folket til Moses og sa: Vi har syndet, fordi vi har talt mot Herren og mot dig; bed til Herren at han vil ta slangene fra oss! Og Moses bad for folket. 
4:21:8: Da sa Herren til Moses: Gjør dig en seraf-slange og sett den på en stang, så skal hver den som er bitt og ser på den, få leve. 
4:21:9: Så gjorde Moses en kobberslange og satte den på en stang; og når en slange hadde bitt nogen, og han så på kobberslangen, blev han i live. 
4:21:10: Siden brøt Israels barn op og leiret sig i Obot. 
4:21:11: Så brøt de op fra Obot og leiret sig ved Ije-Ha'abarim i ørkenen rett i øst for Moab. 
4:21:12: Derfra brøt de op og leiret sig i Sered-dalen. 
4:21:13: Derfra brøt de op og leiret sig på hin side Arnon, som går gjennem ørkenen og kommer fra amorittenes land; for Arnon er grensen mellem Moab og amorittene. 
4:21:14: Derfor sies det i boken om Herrens kriger: Vaheb tok de med storm og bekkene, Arnons bekker, 
4:21:15: og bekkelien som strekker sig bort til Ar-bygden og støter til Moabs grense. 
4:21:16: Derfra brøt de op til Be'er; det er den brønn som Herren talte om da han sa til Moses: Kall folket sammen, så vil jeg gi dem vann. 
4:21:17: Da sang Israel denne sang: Vell op, du brønn! Hils den med sang! 
4:21:18: Du brønn som høvdinger har gravd, som folkets ypperste har boret med herskerstav, med sine kongespir! Fra ørkenen brøt de op til Matana 
4:21:19: og fra Matana til Nahaliel og fra Nahaliel til Bamot 
4:21:20: og fra Bamot til den dal som ligger i Moab-marken, tett ved toppen av Pisga, og hvor en skuer ut over ørkenen. 
4:21:21: Så sendte Israel bud til Sihon, amorittenes konge, og lot si: 
4:21:22: La mig få dra gjennem ditt land! Vi skal ikke komme inn på akrene eller i vingårdene, heller ikke drikke vann av nogen brønn; efter kongeveien vil vi dra, til vi er kommet gjennem ditt land. 
4:21:23: Men Sihon gav ikke Israel lov til å dra gjennem sitt land. Han samlet alt sitt folk og drog ut i ørkenen mot Israel, og han kom til Jahas og stred mot Israel. 
4:21:24: Men Israel slo ham med sverdets egg og tok hans land fra Arnon til Jabbok, til Ammons barns land; for Ammons barns grense var befestet. 
4:21:25: Og Israel tok alle disse byer; og Israel bosatte sig i alle amorittenes byer, i Hesbon og alle byer som hørte under det. 
4:21:26: For Hesbon var Sihons, amoritter-kongens, stad; han hadde ført krig mot Moabs forrige konge og tatt alt hans land fra ham like til Arnon. 
4:21:27: Derfor sier skalden: Kom til Hesbon! Bygges og grunnfestes skal Sihons stad; 
4:21:28: for ild fór ut fra Hesbon, en lue fra Sihons stad; den fortærte Ar i Moab, dem som bodde på høidene ved Arnon. 
4:21:29: Ve dig, Moab! Fortapt er du, Kamos' folk! Han* har sendt sine sønner som flyktninger og sine døtre som fanger til amorittenes konge Sihon. # <* nemlig Kamos, moabittenes fornemste avgud.> 
4:21:30: Og vi skjøt dem ned; ødelagt er Hesbon, like til Dibon! vi herjet til Nofah, med ild helt til Medba. 
4:21:31: Så blev Israel boende i amorittenes land. 
4:21:32: Og Moses sendte folk for å utspeide Jaser, og de* tok de byer som hørte under det; og han drev ut amorittene som bodde der. # <* israelittene.> 
4:21:33: Så vendte de sig til en annen kant og drog opover til Basan; men Og, kongen i Basan, drog ut mot dem til Edre'i med alt sitt folk for å stride mot dem. 
4:21:34: Da sa Herren til Moses: Frykt ikke for ham! For jeg har gitt ham og alt hans folk og hans land i din hånd, og du skal gjøre med ham som du gjorde med Sihon, amorittenes konge, som bodde i Hesbon. 
4:21:35: Så slo de ham og hans sønner og alt hans folk, så ingen blev tilbake eller slapp unda; og de tok hans land i eie. 
4:22:1: Så brøt Israels barn op og leiret sig på Moabs ødemarker på hin side Jordan midt imot Jeriko. 
4:22:2: Da Balak, Sippors sønn, så alt det Israel hadde gjort mot amorittene, 
4:22:3: da blev Moab meget redd for folket, fordi det var så tallrikt, og de grudde for Israels barn. 
4:22:4: Og Moab sa til de eldste i Midian: Nu vil denne store flokk ete op alt det som er her rundt omkring oss, likesom oksen eter op markens grønne urter. - På den tid var Balak, Sippors sønn, konge i Moab. 
4:22:5: Han sendte bud til Bileam, Beors sønn, i Petor, som ligger ved den store elv, til det land hvor han bodde blandt sitt folk, for å be ham komme og si til ham: Se, her er kommet et folk fra Egypten; det fyller hele landet, og det har leiret sig midt imot mig. 
4:22:6: Så kom nu og forbann dette folk for mig, for det er mig for mektig! Kanskje jeg da kunde slå det og drive det ut av landet; for jeg vet at den du velsigner, er velsignet, og den du forbanner, er forbannet. 
4:22:7: Og Moabs eldste drog avsted sammen med Midians eldste og hadde spåmannslønn med sig; og de kom til Bileam og bar frem Balaks ord til ham. 
4:22:8: Da sa han til dem: Bli her inatt, så vil jeg gi eder svar efter som Herren taler til mig. Og Moabs høvdinger blev hos Bileam. 
4:22:9: Da kom Gud til Bileam og sa: Hvad er det for folk som er hos dig? 
4:22:10: Bileam svarte Gud: Balak, Sippors sønn, kongen i Moab, har sendt dette bud til mig: 
4:22:11: Se, her er kommet et folk fra Egypten som fyller hele landet; kom nu og forbann det for mig! Kanskje jeg da blir i stand til å stride mot det og drive det ut. 
4:22:12: Da sa Gud til Bileam: Du skal ikke gå med dem; du skal ikke forbanne folket, for det er velsignet. 
4:22:13: Om morgenen, da Bileam var stått op, sa han til Balaks høvdinger: Dra hjem til eders land! For Herren vil ikke gi mig lov til å følge med eder. 
4:22:14: Da tok Moabs høvdinger avsted, og da de kom tilbake til Balak, sa de: Bileam vilde ikke følge med oss. 
4:22:15: Da sendte Balak andre høvdinger, flere og gjævere enn de første. 
4:22:16: Og de kom til Bileam og sa til ham: Så sier Balak, Sippors sønn: Kjære, la dig ikke hindre fra å komme til mig! 
4:22:17: Jeg vil vise dig stor ære, og alt det du sier til mig, vil jeg gjøre; så kom da og forbann dette folk for mig! 
4:22:18: Da svarte Bileam og sa til Balaks tjenere: Om Balak gav mig hele sitt hus fullt av sølv og gull, kunde jeg ikke overtrede Herrens, min Guds ord, hverken i smått eller stort. 
4:22:19: Men bli nu også I her inatt, forat jeg kan få vite hvad mere Herren har å si mig. 
4:22:20: Og Gud kom til Bileam om natten og sa til ham: Dersom disse menn er kommet for å hente dig, så gjør dig ferdig og dra med dem! Men gjør ikke annet enn hvad jeg sier til dig! 
4:22:21: Om morgenen stod Bileam op og salte sin aseninne og drog med Moabs høvdinger. 
4:22:22: Men Guds vrede optendtes fordi han reiste, og Herrens engel stilte sig på veien for å stå ham imot da han kom ridende på sin aseninne og hadde sine to tjenere med sig. 
4:22:23: Og aseninnen så Herrens engel, som stod på veien med et draget sverd i sin hånd; da tok den av veien og ut på marken; men Bileam slo aseninnen for å vike den inn på veien igjen. 
4:22:24: Siden stilte Herrens engel sig på en smal vei mellem vingårdene, hvor det var stengjerde på begge sider. 
4:22:25: Og da aseninnen så Herrens engel, trykte den sig inn til muren og klemte Bileams fot mot muren; da slo han den igjen. 
4:22:26: Så gikk Herrens engel lenger frem og stilte sig på et trangt sted, hvor det ingen råd var til å bøie av, hverken til høire eller til venstre. 
4:22:27: Og da aseninnen så Herrens engel, la den sig ned under Bileam; da optendtes Bileams vrede, og han slo aseninnen med sin kjepp. 
4:22:28: Men Herren oplot aseninnens munn, og den sa til Bileam: Hvad har jeg gjort dig, siden du nu har slått mig tre ganger? 
4:22:29: Bileam svarte aseninnen: Du har hatt mig til narr. Hadde jeg bare et sverd i min hånd, så vilde jeg nu slå dig ihjel. 
4:22:30: Da sa aseninnen til Bileam: Er ikke jeg din aseninne, som du har ridd på all din tid like til denne dag? Har jeg nogensinne hatt for vis å gjøre således mot dig? Han sa: Nei! 
4:22:31: Og Herren oplot Bileams øine, så han så Herrens engel, som stod på veien med et draget sverd i sin hånd; da bøide han sig og falt ned på sitt ansikt. 
4:22:32: Og Herrens engel sa til ham: Hvorfor har du nu tre ganger slått din aseninne? Se, jeg er gått ut for å stå dig imot; for jeg ser at denne vei fører til fordervelse. 
4:22:33: Aseninnen så mig og har nu tre ganger bøid av veien for mig; hvis den ikke hadde bøid av veien for mig, så hadde jeg nu slått dig ihjel, men latt den leve. 
4:22:34: Da sa Bileam til Herrens engel: Jeg har syndet, fordi jeg ikke skjønte at du stod imot mig på veien; men hvis det er dig imot, så vil jeg vende tilbake. 
4:22:35: Herrens engel sa til Bileam: Følg med mennene! Men tal ikke annet enn hvad jeg sier til dig! Så fulgte Bileam med Balaks høvdinger. 
4:22:36: Da Balak hørte at Bileam kom, drog han ham i møte til den by i Moab som ligger på grensen ved Arnon, ved den ytterste grense. 
4:22:37: Og Balak sa til Bileam: Sendte jeg ikke bud til dig og bad dig komme hit? Hvorfor kom du ikke til mig? Mener du jeg ikke skulde kunne vise dig ære nok? 
4:22:38: Bileam svarte: Nu ser du jeg er kommet til dig; men står det vel i min makt å tale noget? Det ord Gud legger i min munn, det må jeg tale. 
4:22:39: Så drog Bileam med Balak, og de kom til Kirjat-Husot. 
4:22:40: Og Balak ofret storfe og småfe og sendte til Bileam og de høvdinger som var med ham. 
4:22:41: Morgenen efter tok Balak Bileam med sig og førte ham op på Bamot Ba'al; derfra så han den ytterste del av folket. 
4:23:1: Da sa Bileam til Balak: Bygg syv alter for mig her, og la mig få hit syv okser og syv værer! 
4:23:2: Balak gjorde som Bileam sa; og Balak og Bileam ofret en okse og en vær på hvert alter. 
4:23:3: Så sa Bileam til Balak: Bli stående her ved ditt brennoffer, så vil jeg gå bort en stund; kanskje Herren kommer mig i møte, og hvad han lar mig skue, det skal jeg la dig få vite. Så gikk han op på en bar høide. 
4:23:4: Der kom Gud Bileam i møte, og Bileam sa til ham: Nu har jeg stelt til syv alter og ofret en okse og en vær på hvert alter. 
4:23:5: Da la Herren et ord i Bileams munn og sa: Vend tilbake til Balak og tal som jeg har sagt dig! 
4:23:6: Da han kom tilbake, så han Balak stå ved sitt brennoffer sammen med alle Moabs høvdinger. 
4:23:7: Da tok han til å kvede og sa: Fra Aram henter mig Balak, fra Østens fjell Moabs konge: Kom og forbann for mig Jakob! Kom og tal ondt over Israel! 
4:23:8: Hvorledes skal jeg forbanne den Gud ikke forbanner? Hvorledes skal jeg tale ondt over den Herren ikke taler ondt over? 
4:23:9: For fra fjellets tinde ser jeg ham, og fra høidene skuer jeg ham: Se, det er et folk som bor for sig selv, og blandt hedningefolkene regner det sig ikke. 
4:23:10: Hvem teller Jakobs ætt, tall-løs som støvet, eller endog bare fjerdedelen av Israel? Min sjel dø de opriktiges død, og mitt endelikt vorde som deres! 
4:23:11: Da sa Balak til Bileam: Hvad har du gjort mot mig? Til å forbanne mine fiender hentet jeg dig, og se, du har velsignet! 
4:23:12: Men han svarte: Skulde jeg ikke akte på det Herren legger i min munn, og tale det? 
4:23:13: Da sa Balak til ham: Kjære, kom og vær med mig til et annet sted, hvorfra du kan se dem - dog vil du bare få se den ytterste del av folket, det hele folk får du ikke se - og forbann dem for mig derfra! 
4:23:14: Så tok han ham med sig til speider-haugen på toppen av Pisga og bygget der syv alter og ofret en okse og en vær på hvert alter. 
4:23:15: Og Bileam sa til Balak: Bli stående her ved ditt brennoffer mens jeg går der bort for å få en åpenbaring. 
4:23:16: Og Herren kom Bileam i møte og la et ord i hans munn og sa: Vend tilbake til Balak og tal som jeg har sagt dig! 
4:23:17: Da han kom til ham, så han ham stå ved sitt brennoffer sammen med Moabs høvdinger; og Balak sa til ham: Hvad har Herren talt? 
4:23:18: Da tok han til å kvede og sa: Stå op, Balak, og hør! Lytt til mig, du Sippors sønn! 
4:23:19: Gud er ikke et menneske at han skulde lyve, ei heller et menneskes barn at han skulde angre; skulde han si noget og ikke gjøre det, skulde han tale og ikke sette det i verk? 
4:23:20: Se, å velsigne blev mig gitt; han har velsignet, og jeg kan ikke omstøte det. 
4:23:21: Ei skuer han urett i Jakob, ei ser han elendighet i Israel; Herren hans Gud er med ham, og kongejubel lyder der. 
4:23:22: Gud førte dem ut av Egypten; styrke har de som en villokse. 
4:23:23: For ikke finnes det trolldom i Jakob, og ikke spådomskunster i Israel; når tiden er der, blir det sagt til Jakob og til Israel hvad Gud vil gjøre. 
4:23:24: Se - et folk som reiser sig som en løvinne, springer op som en løve; ei legger det sig før det har mettet sig med rov og drukket dreptes blod. 
4:23:25: Da sa Balak til Bileam: Du skal hverken forbanne det eller velsigne det. 
4:23:26: Men Bileam svarte Balak: Har jeg ikke allerede sagt dig at jeg i ett og alt må gjøre som Herren sier? 
4:23:27: Da sa Balak til Bileam: Kjære, kom, så vil jeg ta dig med til et annet sted; kanskje Gud vil tillate at du forbanner dem for mig derfra. 
4:23:28: Så tok Balak Bileam med op på toppen av Peor, hvorfra en skuer ut over ørkenen. 
4:23:29: Da sa Bileam til Balak: Bygg syv alter for mig her og la mig få syv okser og syv værer! 
4:23:30: Og Balak gjorde som Bileam sa, og ofret en okse og en vær på hvert alter. 
4:24:1: Da Bileam så at det var Herrens vilje at han skulde velsigne Israel, gikk han ikke bort for å søke tegn, som han hadde gjort de to første ganger, men vendte sig mot ørkenen. 
4:24:2: Og Bileam løftet sine øine og så Israel leiret efter sine stammer. Da kom Guds Ånd over ham, 
4:24:3: og han tok til å kvede og sa: Så sier Bileam, Beors sønn, så sier mannen hvis øie er lukket*, # <* nemlig for den ytre verden.> 
4:24:4: så sier han som hører ord fra Gud, som skuer syner fra den Allmektige, segnet til jorden med oplatt øie: 
4:24:5: Hvor fagre er dine telt, Jakob, dine boliger, Israel! 
4:24:6: Som vide bekkedaler, som haver ved en elv, som aloëtrær Herren har plantet, som sedrer ved vannet! 
4:24:7: Det strømmer vann av hans spann, og hans ætt bor ved store vann. Mektigere enn Agag* skal hans konge være, ophøiet hans kongerike! # <* sannsynligvis et felles navn på amalekittenes konger; 1SA 15, 8.> 
4:24:8: Gud førte ham ut av Egypten; styrke har han som en villokse; han skal fortære hedningefolkene som står ham imot, og knuse deres ben og gjennembore dem med sine piler. 
4:24:9: Han legger sig, han hviler som en løve, som en løvinne; hvem våger å vekke ham? Velsignet være den som velsigner dig, og forbannet den som forbanner dig! 
4:24:10: Da optendtes Balaks vrede mot Bileam, og han slo sine hender sammen. Og Balak sa til Bileam: Jeg kalte dig hit for å forbanne mine fiender, og nu har du velsignet tre ganger. 
4:24:11: Far nu hjem så fort du kan! Jeg hadde tenkt å vise dig ære, men du ser Herren har nektet dig den. 
4:24:12: Bileam svarte Balak: Sa jeg ikke allerede til de bud du sendte til mig: 
4:24:13: Om Balak gav mig sitt hus fullt av sølv og gull, kunde jeg ikke overtrede Herrens ord og gjøre noget efter mitt eget tykke, enten godt eller ondt; jeg kan ikke tale annet enn hvad Herren sier. 
4:24:14: Se, jeg vender nu hjem til mitt folk; men la mig først varsle dig om hvad dette folk skal gjøre med ditt folk i de siste dager*. # <* 1MO 49, 1.> 
4:24:15: Og han tok til å kvede og sa: Så sier Bileam, Beors sønn, så sier mannen hvis øie er lukket, 
4:24:16: så sier han som hører ord fra Gud og har fått kunnskap fra den Høieste, han som skuer syner fra den Allmektige, segnet til jorden med oplatt øie: 
4:24:17: Jeg ser ham, men ikke nu, jeg skuer ham, men ikke nær; en stjerne stiger op av Jakob, et spir løfter sig fra Israel; han knuser Moabs tinninger og utrydder ufreds-ætten. 
4:24:18: Edom blir ham underlagt, og Se'ir, hans fiende, blir ham underlagt; stort velde vinner Israel. 
4:24:19: En hersker går ut fra Jakob, han utrydder av byene dem som har reddet sig dit. 
4:24:20: Så fikk han se amalekittene; da tok han til å kvede og sa: Det første blandt hedningefolkene er Amalek; men til sist skal han gå til grunne. 
4:24:21: Så fikk han se kenittene, og han tok til å kvede og sa: Fast er din bolig, og bygget på klippen ditt rede; 
4:24:22: men enda skal Kain* bli ødelagt når Assur fører dig bort i fangenskap. # <* kenittenes stamfar står i stedet for folket selv.> 
4:24:23: Så tok han igjen til å kvede og sa: Ve! Hvem skal bli i live når Gud lar dette skje? 
4:24:24: Skiber kommer fra Kittims kyst*, de kuer Assur, og de kuer Eber; men også han** skal gå til grunne. # <* Kittim er egentlig Kypern, og i videre mening øene og kystene nordvest for Jødeland.> # <** han som kommer fra Kittims kyst.> 
4:24:25: Så gjorde Bileam sig rede og vendte hjem igjen; og Balak drog også sin vei. 
4:25:1: Mens Israel opholdt sig i Sittim, begynte folket å drive utukt med Moabs døtre. 
4:25:2: De innbød folket til offermåltidene for sine guder, og folket åt og tilbad deres guder; 
4:25:3: og Israel holdt sig til Ba'al-Peor. Da optendtes Herrens vrede mot Israel, 
4:25:4: og Herren sa til Moses: Hent alle folkets høvdinger og la hine menn nagles til pelen under åpen himmel for Herren! Så skal Herrens brennende vrede avvendes fra Israel. 
4:25:5: Og Moses sa til Israels dommere: Hver av eder skal slå ihjel dem av sine folk som har holdt sig til Ba'al-Peor! 
4:25:6: Da kom en mann av Israels barn og førte en midianittisk kvinne inn blandt sine brødre for Moses' og hele Israels menighets øine mens de satt og gråt ved inngangen til sammenkomstens telt. 
4:25:7: Da Pinehas, sønn av Eleasar og sønnesønn av Aron, presten, så det, trådte han frem av menigheten og tok et spyd i sin hånd 
4:25:8: og gikk efter den israelittiske mann inn i koven og stakk spydet gjennem dem begge, både den israelittiske mann og kvinnen, så det gikk inn i hennes liv; da stanset sotten og vek fra Israels barn. 
4:25:9: Og de som døde i sotten, var fire og tyve tusen. 
4:25:10: Og Herren talte til Moses og sa: 
4:25:11: Pinehas, sønn av Eleasar og sønnesønn av Aron, presten, har avvendt min vrede fra Israels barn, fordi han brente av min nidkjærhet iblandt dem, så jeg ikke gjorde ende på Israels barn i min nidkjærhet. 
4:25:12: Derfor skal du si: Se, jeg gjør min pakt med ham at det skal gå ham vel; 
4:25:13: med ham og hans efterkommere gjør jeg en pakt at de skal ha et evig prestedømme, fordi han var nidkjær for sin Gud og gjorde soning for Israels barn. 
4:25:14: Men navnet på den drepte israelittiske mann, han som blev drept sammen med den midianittiske kvinne, var Simri, sønn av Salu, høvding for en familie blandt simeonittene; 
4:25:15: og den drepte midianittiske kvinnes navn var Kosbi, datter av Sur; han var høvding for en stammefamilie blandt midianittene. 
4:25:16: Og Herren talte til Moses og sa: 
4:25:17: Angrip midianittene og slå dem! 
4:25:18: For de angrep eder med de svikefulle råd som de brukte mot eder, da det hendte dette med Peor og med Kosbi, deres søster, den midianittiske fyrstes datter, hun som blev drept dengang sotten kom over eder for Peors skyld. 
4:26:1: Da sotten var forbi, sa Herren til Moses og til Eleasar, sønn av Aron, presten: 
4:26:2: Ta op manntall over hele Israels barns menighet fra tyveårsalderen og opover, efter deres familier, over alle dem i Israel som kan dra ut i strid. 
4:26:3: Og Moses og Eleasar, presten, talte med dem på Moabs ødemarker ved Jordan, midt imot Jeriko, og sa: 
4:26:4: Fra tyveårsalderen og opover skal de mønstres - således som Herren hadde befalt Moses og Israels barn, dem som hadde draget ut av Egyptens land. 
4:26:5: Ruben var Israels førstefødte; Rubens barn var: Fra Hanok hanokittenes ætt, fra Pallu pallu'ittenes ætt, 
4:26:6: fra Hesron hesronittenes ætt, fra Karmi karmittenes ætt. 
4:26:7: Dette var rubenittenes ætter, og de av dem som blev mønstret, var tre og firti tusen, syv hundre og tretti. 
4:26:8: Pallus sønn var Eliab, 
4:26:9: og Eliabs barn var Nemuel og Datan og Abiram. Det var den Datan og den Abiram som var utkåret av menigheten, og som yppet strid mot Moses og Aron dengang Korahs flokk satte sig op imot Herren, 
4:26:10: og jorden oplot sin munn og slukte både dem og Korah, og hele flokken omkom idet ilden fortærte de to hundre og femti menn, og de blev til et tegn; 
4:26:11: men Korahs barn omkom ikke. 
4:26:12: Simeons barn var efter sine ætter: Fra Nemuel nemuelittenes ætt, fra Jamin jaminittenes ætt, fra Jakin jakinittenes ætt, 
4:26:13: fra Serah serahittenes ætt, fra Sa'ul sa'ulittenes ætt. 
4:26:14: Dette var simeonittenes ætter, to og tyve tusen og to hundre. 
4:26:15: Gads barn var efter sine ætter: Fra Sefon sefonittenes ætt, fra Haggi haggittenes ætt, fra Suni sunittenes ætt, 
4:26:16: fra Osni osnittenes ætt, fra Eri erittenes ætt, 
4:26:17: fra Arod arodittenes ætt, fra Areli arelittenes ætt. 
4:26:18: Dette var Gads barns ætter, så mange av dem som blev mønstret, firti tusen og fem hundre. 
4:26:19: Judas barn var Er og Onan; men Er og Onan døde i Kana'ans land. 
4:26:20: Og Judas barn var efter sine ætter: Fra Sela selanittenes ætt, fra Peres peresittenes ætt, fra Serah serahittenes ætt. 
4:26:21: Peres' barn var: Fra Hesron hesronittenes ætt, fra Hamul hamulittenes ætt. 
4:26:22: Dette var Judas ætter, så mange av dem som blev mønstret, seks og sytti tusen og fem hundre. 
4:26:23: Issakars barn var efter sine ætter: Fra Tola tola'ittenes ætt, fra Puva punittenes ætt, 
4:26:24: fra Jasub jasubittenes ætt, fra Simron simronittenes ætt. 
4:26:25: Dette var Issakars ætter, så mange av dem som blev mønstret, fire og seksti tusen og tre hundre. 
4:26:26: Sebulons barn var efter sine ætter: Fra Sered sardittenes ætt, fra Elon elonittenes ætt, fra Jahle'el jahle'elittenes ætt. 
4:26:27: Dette var sebulonittenes ætter, så mange av dem som blev mønstret, seksti tusen og fem hundre. 
4:26:28: Josefs barn var efter sine ætter: Manasse og Efra'im. 
4:26:29: Manasses barn var: Fra Makir makirittenes ætt, og Makir var far til Gilead, fra Gilead gileadittenes ætt. 
4:26:30: Gileads barn det var: Fra Jeser jesrittenes ætt, fra Helek helkittenes ætt 
4:26:31: og fra Asriel asrielittenes ætt og fra Sekem sikmittenes ætt 
4:26:32: og fra Semida semida'ittenes ætt og fra Hefer hefrittenes ætt. 
4:26:33: Men Selofhad, Hefers sønn, hadde ingen sønner, bare døtre, og Selofhads døtre hette Mahla og Noa, Hogla, Milka og Tirsa. 
4:26:34: Dette var Manasses ætter, og de av dem som blev mønstret, var to og femti tusen og syv hundre. 
4:26:35: Efra'ims barn det var efter deres ætter: Fra Sutelah sutelahittenes ætt, fra Beker bakrittenes ætt, fra Tahan tahanittenes ætt. 
4:26:36: Sutelahs barn det var: Fra Eran eranittenes ætt. 
4:26:37: Dette var Efra'ims barns ætter, så mange av dem som blev mønstret, to og tretti tusen og fem hundre. Dette var Josefs barn efter deres ætter. 
4:26:38: Benjamins barn var efter sine ætter: Fra Bela balittenes ætt, fra Asbel asbelittenes ætt, fra Akiram akiramittenes ætt, 
4:26:39: fra Sefufam sufamittenes ætt, fra Hufam hufamittenes ætt. 
4:26:40: Belas barn var: Ard og Na'aman, fra Ard ardittenes ætt, fra Na'aman na'amittenes ætt. 
4:26:41: Dette var Benjamins barn efter deres ætter, og de av dem som blev mønstret, var fem og firti tusen og seks hundre. 
4:26:42: Dans barn var efter sine ætter: Fra Suham suhamittenes ætt. Dette var Dans ætter efter deres ættetavle. 
4:26:43: Alle suhamittenes ætter, så mange av dem som blev mønstret, var fire og seksti tusen og fire hundre. 
4:26:44: Asers barn var efter sine ætter: Fra Jimna jimna'ittenes ætt, fra Jisvi jisvittenes ætt, fra Beria beriittenes ætt; 
4:26:45: av Berias barn: Fra Heber hebrittenes ætt, fra Malkiel malkielittenes ætt. 
4:26:46: Asers datter hette Serah. 
4:26:47: Dette var Asers barns ætter, så mange av dem som blev mønstret, tre og femti tusen og fire hundre. 
4:26:48: Naftalis barn var efter sine ætter: Fra Jahse'el jahse'elittenes ætt, fra Guni gunittenes ætt, 
4:26:49: fra Jeser jisrittenes ætt, fra Sillem sillemittenes ætt. 
4:26:50: Dette var Naftalis ætter efter deres ættetavle, og de av dem som blev mønstret, var fem og firti tusen og fire hundre. 
4:26:51: Dette var de av Israels barn som blev mønstret, seks hundre og ett tusen, syv hundre og tretti. 
4:26:52: Og Herren talte til Moses og sa: 
4:26:53: Mellem disse skal landet skiftes ut til arv efter folketallet. 
4:26:54: Den stamme som er stor, skal du gi en stor arv, og den som er liten, skal du gi en liten arv; hver stamme skal få sin arv efter tallet på dem som blev mønstret der. 
4:26:55: Men ved loddkasting skal landet skiftes ut; efter navnene på sine fedrenestammer skal de ta arv. 
4:26:56: Efter som loddet faller, skal hver stamme, stor eller liten, få sin arv. 
4:26:57: Dette var de av levittene som blev mønstret, efter sine ætter: Fra Gerson gersonittenes ætt, fra Kahat kahatittenes ætt, fra Merari merarittenes ætt. 
4:26:58: Dette var levittenes ætter: libnittenes ætt, hebronittenes ætt, mahlittenes ætt, musittenes ætt, korahittenes ætt. Kahat var far til Amram, 
4:26:59: og Amrams hustru hette Jokebed; hun var datter av Levi og født i Egypten; og Amram fikk med henne Aron og Moses og deres søster Mirjam. 
4:26:60: Og Aron fikk sønnene Nadab og Abihu, Eleasar og Itamar; 
4:26:61: men Nadab og Abihu døde dengang de bar fremmed ild inn for Herrens åsyn. 
4:26:62: Og de av levittene som blev mønstret, var tre og tyve tusen, alt mannkjønn blandt dem fra den som var en måned gammel og opover; de blev ikke mønstret sammen med de andre Israels barn, fordi det ikke var gitt dem arv blandt Israels barn. 
4:26:63: Disse var det som blev mønstret av Moses og Eleasar, presten, da de mønstret Israels barn på Moabs ødemarker ved Jordan midt imot Jeriko, 
4:26:64: og blandt disse var det ikke nogen av dem som blev mønstret av Moses og Aron, presten, dengang de mønstret Israels barn i Sinai ørken. 
4:26:65: For Herren hadde sagt at de skulde dø i ørkenen. Og det var ingen tilbake av dem uten Kaleb, Jefunnes sønn, og Josva, Nuns sønn. 
4:27:1: Men Selofhads døtre - han var sønn av Hefer, Gileads sønn, som igjen var sønn av Makir, Manasses sønn, av Manasses, Josefs sønns ætt, og hans døtre hette Mahla, Noa og Hogla og Milka og Tirsa - 
4:27:2: de gikk frem for Moses og Eleasar, presten, og høvdingene og hele menigheten ved inngangen til sammenkomstens telt og sa: 
4:27:3: Vår far døde i ørkenen, og han var ikke med i den flokk som satte sig op mot Herren - i Korahs flokk - men han døde for sin egen synds skyld*, og han hadde ingen sønner. # <* 4MO 14, 29.> 
4:27:4: Hvorfor skal vår fars navn gå ut av hans ætt, fordi om han ingen sønn hadde? Gi oss eiendom blandt vår fars brødre! 
4:27:5: Og Moses førte deres sak frem for Herrens åsyn. 
4:27:6: Da sa Herren til Moses: 
4:27:7: Selofhads døtre har rett i det de sier; du skal gi dem eiendom til arv blandt deres fars brødre og la deres fars arv gå over til dem. 
4:27:8: Og til Israels barn skal du tale således: Når en mann dør, og han ingen sønn har, da skal I la hans arv gå over til hans datter. 
4:27:9: Og dersom han ingen datter har, så skal I gi hans brødre hans arv. 
4:27:10: Og dersom han ingen brødre har, så skal I gi hans fars brødre hans arv. 
4:27:11: Men dersom hans far ingen brødre har, så skal I gi hans arv til den nærmeste frende i ætten; han skal arve den. Dette skal være gjeldende rett blandt Israels barn, således som Herren har befalt Moses. 
4:27:12: Siden sa Herren til Moses: Stig op på Abarim-fjellet her og se utover landet som jeg har gitt Israels barn! 
4:27:13: Og når du har sett det, da skal du og samles til dine fedre likesom din bror Aron blev samlet til sine fedre, 
4:27:14: fordi I var. gjenstridige mot mitt ord i ørkenen Sin, dengang menigheten kivedes med mig, og I skulde helliget mig ved å la vann strømme frem for deres øine. Det er Meribas vann ved Kades i ørkenen Sin. 
4:27:15: Da talte Moses til Herren og sa: 
4:27:16: Herre, du Gud som råder over livsens ånde i alt kjød, vil du ikke sette en mann over menigheten, 
4:27:17: som kan gå ut og inn foran dem, og som kan føre dem ut og føre dem inn, så Herrens menighet ikke skal være som får uten hyrde! 
4:27:18: Og Herren sa til Moses: Ta Josva, Nuns sønn. Han er en mann som det er ånd i. Legg din hånd på ham, 
4:27:19: og still ham frem for Eleasar, presten, og for hele menigheten og innsett ham i hans tjeneste for deres øine, 
4:27:20: og legg noget av din verdighet på ham, så hele Israels barns menighet må lyde ham! 
4:27:21: Og han skal stå frem for Eleasar, presten, og Eleasar skal for Herrens åsyn søke urims dom for ham; efter hans ord skal de gå ut, og efter hans ord skal de gå inn, han selv med alle Israels barn og hele menigheten. 
4:27:22: Så gjorde Moses som Herren hadde befalt ham; han tok Josva og stilte ham frem for Eleasar, presten, og for hele menigheten, 
4:27:23: og han la sine hender på ham og innsatte ham i hans tjeneste, således som Herren hadde talt ved Moses. 
4:28:1: Og Herren talte til Moses og sa: 
4:28:2: Byd Israels barn og si til dem: Mitt offer, min mat, mine ildoffer, som er til velbehagelig duft for mig, skal I akte på å bære frem for mig til fastsatt tid. 
4:28:3: Og du skal si til dem: Dette er det ildoffer som I skal bære frem for Herren: hver dag to årsgamle lam uten lyte til et stadig brennoffer. 
4:28:4: Det ene lam skal du ofre om morgenen, og det andre skal du ofre mellem de to aftenstunder, 
4:28:5: og som matoffer tiendedelen av en efa fint mel blandet med fjerdedelen av en hin olje av støtte oliven. 
4:28:6: Dette er det stadige brennoffer, det samme som blev ofret ved Sinai berg til en velbehagelig duft, et ildoffer for Herren. 
4:28:7: Og drikkofferet som hører til det første lam, skal være fjerdedelen av en hin; i helligdommen skal du ofre drikkoffer av sterk drikk til Herren. 
4:28:8: Det andre lam skal du ofre mellem de to aftenstunder; med det samme matoffer som om morgenen og med det samme drikkoffer skal du ofre det; det er et ildoffer til en velbehagelig duft for Herren. 
4:28:9: På sabbatsdagen skal du ofre to årsgamle lam uten lyte og som matoffer to tiendedeler av en efa fint mel blandet med olje, og drikkofferet som hører til. 
4:28:10: Dette er sabbats-brennofferet, som skal bæres frem hver sabbat, foruten det stadige brennoffer med tilhørende drikkoffer. 
4:28:11: Den første dag i hver måned skal I ofre Herren et brennoffer: to unge okser og en vær og syv årsgamle lam uten lyte, 
4:28:12: og til matoffer tre tiendedeler av en efa fint mel blandet med olje for hver okse, og til væren to tiendedeler fint mel blandet med olje 
4:28:13: og en tiendedel fint mel blandet med olje for hvert lam; da er det et brennoffer til en velbehagelig duft, et ildoffer for Herren; 
4:28:14: og drikkofferne som hører til, skal være en halv hin vin for hver okse og tredjedelen av en hin til væren og fjerdedelen av en hin for hvert lam. Dette er måneds-brennofferet, som skal bæres frem hver nymånedag i året. 
4:28:15: Der skal også ofres en gjetebukk som syndoffer til Herren, foruten det stadige brennoffer med tilhørende drikkoffer. 
4:28:16: I den første måned, på den fjortende dag i måneden, er det påske for Herren. 
4:28:17: Og på den femtende dag i samme måned er det høitid; i syv dager skal der etes usyret brød. 
4:28:18: På den første dag skal det være en hellig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning. 
4:28:19: Og I skal ofre et ildoffer, et brennoffer for Herren: to unge okser og en vær og syv årsgamle lam - uten lyte skal de være - 
4:28:20: og som tilhørende matoffer fint mel blandet med olje; tre tiendedeler av en efa skal I ofre for hver okse og til væren to tiendedeler, 
4:28:21: og en tiendedel for hvert av de syv lam, 
4:28:22: og en gjetebukk som syndoffer, til å gjøre soning for eder. 
4:28:23: Dette skal I ofre foruten morgen-brennofferet, som hører til det stadige brennoffer*. # <* 4MO 28, 3-8.> 
4:28:24: Sådanne offer skal I ofre hver dag i syv dager; det er ildoffermat til en velbehagelig duft for Herren; foruten det stadige brennoffer med tilhørende drikkoffer skal det ofres. 
4:28:25: Og på den syvende dag skal I holde en hellig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning. 
4:28:26: På førstegrødens dag, når I bærer frem for Herren et offer av den nye grøde, på ukenes høitid, skal I holde en hellig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning. 
4:28:27: Og I skal ofre et brennoffer til en velbehagelig duft for Herren: to unge okser og en vær og syv årsgamle lam, 
4:28:28: og som tilhørende matoffer fint mel blandet med olje, tre tiendedeler av en efa for hver okse og til væren to tiendedeler, 
4:28:29: og en tiendedel for hvert av de syv lam, 
4:28:30: og en gjetebukk til å gjøre soning for eder. 
4:28:31: Foruten det stadige brennoffer med tilhørende matoffer skal I ofre disse offer - uten lyte skal de være - med tilhørende drikkoffer. 
4:29:1: I den syvende måned, på den første dag i måneden, skal I holde en hellig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning; den dag skal basunene lyde hos eder. 
4:29:2: Og I skal ofre et brennoffer til en velbehagelig duft for Herren: en ung okse og en vær og syv årsgamle lam uten lyte, 
4:29:3: og som tilhørende matoffer fint mel blandet med olje, tre tiendedeler av en efa til oksen, to tiendedeler til væren 
4:29:4: og en tiendedel for hvert av de syv lam, 
4:29:5: og en gjetebukk som syndoffer, til å gjøre soning for eder, 
4:29:6: foruten måneds-brennofferet med tilhørende matoffer og det stadige brennoffer med tilhørende matoffer og foreskrevne drikkoffer, til en velbehagelig duft, et ildoffer for Herren. 
4:29:7: Den tiende dag i den samme syvende måned skal I holde en hellig sammenkomst; da skal I faste. I skal ikke gjøre nogen arbeidsgjerning. 
4:29:8: Og I skal ofre Herren et brennoffer til en velbehagelig duft: en ung okse og en vær og syv årsgamle lam - uten lyte skal de være - 
4:29:9: og som tilhørende matoffer fint mel blandet med olje, tre tiendedeler av en efa til oksen, to tiendedeler til væren 
4:29:10: Og en tiendedel for hvert av de syv lam, 
4:29:11: Og en gjetebukk som syndoffer, foruten sonings-syndofferet og det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:12: Den femtende dag i den syvende måned skal I holde en hellig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning; I skal holde høitid for Herren i syv dager. 
4:29:13: Og I skal ofre et brennoffer, et ildoffer til en velbehagelig duft for Herren: tretten unge okser og to værer og fjorten årsgamle lam - uten lyte skal de være - 
4:29:14: og som tilhørende matoffer fint mel blandet med olje, tre tiendedeler av en efa for hver av de tretten okser, to tiendedeler for hver av de to værer, 
4:29:15: og en tiendedel for hvert av de fjorten lam, 
4:29:16: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:17: Og den annen dag tolv unge okser og to værer og fjorten årsgamle lam uten lyte 
4:29:18: med tilhørende matoffer og drikkoffer, svarende til tallet på oksene og værene og lammene, alt således som foreskrevet er, 
4:29:19: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:20: Og den tredje dag elleve okser og to værer og fjorten årsgamle lam uten lyte, 
4:29:21: med tilhørende matoffer og drikkoffer, svarende til tallet på oksene og værene og lammene, således som foreskrevet er, 
4:29:22: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:23: Og den fjerde dag ti okser og to værer og fjorten årsgamle lam uten lyte, 
4:29:24: med tilhørende matoffer og drikkoffer, svarende til tallet på oksene og værene og lammene, således som foreskrevet er, 
4:29:25: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:26: Og den femte dag ni okser og to værer og fjorten årsgamle lam uten lyte, 
4:29:27: med tilhørende matoffer og drikkoffer, svarende til tallet på oksene og værene og lammene, således som foreskrevet er, 
4:29:28: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:29: Og den sjette dag åtte okser og to værer og fjorten årsgamle lam uten lyte, 
4:29:30: med tilhørende matoffer og drikkoffer, svarende til tallet på oksene og værene og lammene, således som foreskrevet er, 
4:29:31: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:32: Og den syvende dag syv okser og to værer og fjorten årsgamle lam uten lyte, 
4:29:33: med tilhørende matoffer og drikkoffer, svarende til tallet på oksene og værene og lammene, således som foreskrevet er, 
4:29:34: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:35: Den åttende dag skal I holde en festlig sammenkomst; I skal ikke gjøre nogen arbeidsgjerning. 
4:29:36: Og I skal ofre et brennoffer, et ildoffer til en velbehagelig duft for Herren: en okse og en vær og syv årsgamle lam uten lyte, 
4:29:37: med tilhørende matoffer og drikkoffer, svarende til oksen og væren og lammene efter deres tall, således som foreskrevet er, 
4:29:38: og en gjetebukk som syndoffer, foruten det stadige brennoffer med tilhørende matoffer og drikkoffer. 
4:29:39: Disse offer skal I ofre Herren på eders høitider, foruten eders lovede og eders frivillige offer, enten det er brennoffer eller matoffer eller drikkoffer eller takkoffer. 
4:30:1: Og Moses sa dette til Israels barn, i ett og alt som Herren hadde befalt ham. 
4:30:2: Og Moses talte til høvdingene for Israels barns stammer og sa: Således har Herren befalt: 
4:30:3: Når en mann gjør Herren et løfte eller sverger en ed hvormed han forplikter sig til avhold i en eller annen ting, så skal han ikke bryte sitt ord; alt hvad han har sagt, skal han gjøre. 
4:30:4: Og når en ung kvinne som ennu er hjemme hos sin far, gjør Herren et løfte eller forplikter sig til avhold i nogen ting, 
4:30:5: og hennes far får høre om hennes løfte eller om den forpliktelse til avhold som hun har pålagt sig, og tier til det, da skal alle hennes løfter og enhver forpliktelse til avhold som hun har pålagt sig, stå ved makt. 
4:30:6: Men dersom hennes far samme dag han hører om det, sier nei til det, da skal intet av hennes løfter eller de forpliktelser til avhold som hun har pålagt sig, stå ved makt, og Herren skal tilgi henne, fordi hennes far sa nei til det. 
4:30:7: Blir hun en manns hustru, og det hviler noget løfte på henne, eller det er kommet et tankeløst ord over hennes leber, som hun har bundet sig med, 
4:30:8: og hennes mann får høre om det, men tier til det den dag han hører om det, da skal hennes løfter og de forpliktelser til avhold som hun har pålagt sig, stå ved makt. 
4:30:9: Men dersom hennes mann samme dag han hører om det, sier nei til det, så gjør han det løfte hun har på sig, ugyldig, og likeså det tankeløse ord som er kommet over hennes leber, og som hun har bundet sig med, og Herren skal tilgi henne. 
4:30:10: En enkes eller en fraskilt hustrus løfte skal stå ved makt for henne, hvad hun enn har forpliktet sig til. 
4:30:11: Men dersom hun i sin manns hus har lovt noget eller med ed har forpliktet sig til avhold i en eller annen ting, 
4:30:12: og hennes mann fikk høre om det, men tidde og ikke sa nei til det, da skal alle hennes løfter og enhver forpliktelse til avhold som hun har pålagt sig, stå ved makt. 
4:30:13: Men har hennes mann gjort dem ugyldige den dag han hørte om det, da skal intet stå ved makt av det som er kommet over hennes leber, enten det er et løfte hun har gjort, eller det er en forpliktelse til avhold; hennes mann har gjort dem ugyldige, og Herren skal tilgi henne. 
4:30:14: Ethvert løfte og enhver forpliktelse til avhold og faste som hun med ed har pålagt sig, kan hennes mann enten stadfeste eller gjøre ugyldig. 
4:30:15: Tier hennes mann til det fra den ene dag til den annen, så har han stadfestet alle hennes løfter eller alle de forpliktelser til avhold som hviler på henne; han har stadfestet dem, fordi han tidde til det den dag han hørte om det. 
4:30:16: Men gjør han dem først ugyldige en tid efterat han har fått høre om dem, da skal han bære ansvaret for hennes misgjerning. 
4:30:17: Dette er de forskrifter som Herren gav Moses om den rett en mann har over sin hustru, og en far over sin datter så lenge hun er ung og ennu er hjemme hos sin far. 
4:31:1: Og Herren talte til Moses og sa: 
4:31:2: Ta hevn over midianittene for det de har gjort mot Israels barn! Derefter skal du samles til dine fedre. 
4:31:3: Da talte Moses til folket og sa: La nogen av eder ruste sig til strid! De skal kjempe mot midianittene og føre Herrens hevn over Midian; 
4:31:4: tusen av hver stamme, av alle Israels stammer, skal I sende ut til strid. 
4:31:5: Så blev der av Israels tusener tatt ut tusen av hver stamme - tolv tusen, rustet til strid. 
4:31:6: Dem sendte Moses ut i striden - tusen av hver stamme - og med dem Pinehas, sønn av Eleasar, presten; han hadde med sig helligdommens redskaper og larmtrompetene. 
4:31:7: Og de stred mot Midian, som Herren hadde befalt Moses, og de slo alt mannkjønn ihjel. 
4:31:8: Og blandt dem de slo ihjel, var også Midians konger, Evi og Rekem og Sur og Hur og Reba, Midians fem konger; også Bileam, Beors sønn, slo de ihjel med sverdet. 
4:31:9: Og Israels barn førte Midians kvinner og deres barn bort som fanger, og alle deres kløvdyr og all deres buskap og alt deres gods gjorde de til bytte. 
4:31:10: Og alle deres byer overalt hvor de bodde, og alle deres teltleire brente de op med ild. 
4:31:11: Og de tok alt byttet og alt det de hadde røvet, både folk og fe, 
4:31:12: og de førte fangene og byttet og det de hadde røvet, til Moses og Eleasar, presten, og til Israels barns menighet, til leiren på Moabs ødemarker ved Jordan, midt imot Jeriko. 
4:31:13: Og Moses og Eleasar, presten, og alle menighetens høvdinger gikk dem i møte utenfor leiren. 
4:31:14: Men Moses blev vred på dem som var satt over hæren, på høvdingene over tusen og høvdingene over hundre, da de kom tilbake fra krigstoget. 
4:31:15: Og Moses sa til dem: Har I latt alle kvinner leve? 
4:31:16: Det var jo de som på Bileams råd forførte Israels barn til troløshet mot Herren for Peors skyld, så sotten kom over Herrens menighet. 
4:31:17: Så slå nu ihjel alle guttebarn og alle kvinner som har hatt samleie med menn. 
4:31:18: Men alle unge piker som ikke har hatt samleie med menn, skal I la leve, og de skal høre eder til. 
4:31:19: Og så skal I holde eder utenfor leiren i syv dager! Enhver av eder som har slått nogen ihjel, og enhver som har rørt ved en som er slått ihjel, skal rense sig på den tredje og på den syvende dag - både I og eders fanger . 
4:31:20: Og alle klær og alt som er gjort av skinn eller av gjetehår, og alle trekar skal I rense. 
4:31:21: Og Eleasar, presten, sa til stridsmennene som hadde vært med i krigen: Dette er det lovbud som Herren har gitt Moses: 
4:31:22: Gull og sølv, kobber, jern, tinn og bly, 
4:31:23: alt som tåler ild, skal I la gå gjennem ilden, så blir det rent; men det skal også renses med renselsesvannet. Men alt som ikke tåler ild, skal I la gå gjennem vann. 
4:31:24: Og den syvende dag skal I tvette eders klær, så blir I rene; og derefter kan I komme inn i leiren. 
4:31:25: Og Herren sa til Moses: 
4:31:26: Ta tall på hele det bortførte bytte, både folk og fe, du og Eleasar, presten, og overhodene for menighetens familier. 
4:31:27: Og du skal dele byttet i to deler mellem krigsfolket som drog ut i striden, og hele menigheten. 
4:31:28: Og du skal ta en avgift til Herren av krigsfolket som drog ut i striden, ett liv av hvert fem hundre, både av mennesker og av storfeet og asenene og småfeet. 
4:31:29: Dette skal I ta av den halvdel som tilfaller krigsfolket; og du skal gi det til Eleasar, presten, som en gave til Herren. 
4:31:30: Og av den halvdel som tilfaller Israels barn, skal du ta ut ett liv av hvert femti, både av mennesker og av storfeet og asenene og småfeet - av alt feet; og du skal gi dem til levittene, som tar vare på det som er å vareta ved Herrens tabernakel. 
4:31:31: Og Moses og Eleasar, presten, gjorde således som Herren hadde befalt Moses. 
4:31:32: Og byttet - det som var tilovers av alt det som stridsfolket hadde røvet - var: av småfe seks hundre og fem og sytti tusen 
4:31:33: og av storfe to og sytti tusen 
4:31:34: og av asener en og seksti tusen. 
4:31:35: Og av mennesker - kvinner som ikke hadde hatt samleie med menn - var det i alt to og tretti tusen. 
4:31:36: Halvdelen av dette - den del som falt på stridsmennene - var: av småfeet tre hundre og syv og tretti tusen og fem hundre, 
4:31:37: så avgiften til Herren av småfeet blev seks hundre og fem og sytti, 
4:31:38: og av storfeet seks og tretti tusen, og avgiften derav til Herren to og sytti, 
4:31:39: og av asener tretti tusen og fem hundre, og avgiften derav til Herren en og seksti, 
4:31:40: og av mennesker seksten tusen, og avgiften derav til Herren to og tretti. 
4:31:41: Og Moses gav avgiften til Eleasar, presten, som en gave til Herren, således som Herren hadde befalt Moses. 
4:31:42: Den halvdel som tilfalt Israels barn, og som Moses hadde skilt ut fra stridsmennenes del - 
4:31:43: den halvdel som tilfalt menigheten - var: av småfe tre hundre og syv og tretti tusen og fem hundre 
4:31:44: og av storfe seks og tretti tusen 
4:31:45: og av asener tretti tusen og fem hundre 
4:31:46: og av mennesker seksten tusen. 
4:31:47: Av denne halvdel - den som tilfalt Israels barn - tok Moses ut ett liv for hvert femti, både av mennesker og av dyr, og gav dem til levittene, som tok vare på det som er å vareta ved Herrens tabernakel, således som Herren hadde befalt Moses. 
4:31:48: Så gikk de som var satt over hærens tusener, høvdingene over tusen og høvdingene over hundre, frem for Moses 
4:31:49: og sa til ham: Dine tjenere har tatt tall på krigsfolkene som stod under vår befaling, og det fattedes ikke én mann av oss. 
4:31:50: Derfor bærer vi frem som gave til Herren hvad enhver av oss har tatt av gullsaker, armkjeder og armbånd, fingerringer, ørenringer og kulekjeder; det skal være til soning for oss for Herrens åsyn. 
4:31:51: Og Moses og Eleasar, presten, tok imot gullet av dem, alle slags fint arbeidede ting. 
4:31:52: Og hele den gave av gull som høvdingene over tusen og høvdingene over hundre avgav til Herren, var seksten tusen, syv hundre og femti sekel. 
4:31:53: Men stridsmennene hadde og hver for sig vunnet bytte. 
4:31:54: Da Moses og Eleasar, presten, hadde tatt imot gullet av høvdingene over tusen og over hundre, bar de det inn i sammenkomstens telt, forat det skulde minne om Israels barn for Herrens åsyn. 
4:32:1: Rubens barn og Gads barn hadde en mengde fe, en svær mengde; og da de så at Jasers land og Gileads land var vel skikket for feavl, 
4:32:2: kom de - både Gads barn og Rubens barn - og sa til Moses og Eleasar, presten, og menighetens høvdinger: 
4:32:3: Atarot og Dibon og Jaser og Nimra og Hesbon og El'ale og Sebam og Nebo og Beon, 
4:32:4: det land som Herren har latt Israels menighet vinne med sverdet, er et land som er vel skikket for feavl, og dine tjenere har meget fe. 
4:32:5: Og de sa: Dersom vi har funnet nåde for dine øine, så la dine tjenere få dette land til eiendom, la oss slippe å dra over Jordan! 
4:32:6: Da sa Moses til Gads barn og Rubens barn: Skal eders brødre dra i krigen, og I bli her? 
4:32:7: Hvorfor vil I vende Israels barns hu bort, så de ikke vil dra over til det land Herren hat gitt dem? 
4:32:8: Det samme gjorde eders fedre da jeg sendte dem fra Kades-Barnea for å se på landet; 
4:32:9: da de hadde draget op til Eskoldalen og hadde sett på landet, vendte de Israels barns hu bort, så de ikke vilde gå inn i det land Herren hadde gitt dem. 
4:32:10: Den dag optendtes Herrens vrede, og han svor og sa: 
4:32:11: Ingen av de menn som drog op fra Egypten, fra tyveårsalderen og opover, skal få se det land som jeg har tilsvoret Abraham, Isak og Jakob; for de har ikke fulgt mig som de skulde - 
4:32:12: ingen uten Kaleb, Jefunnes sønn, kenisitten, og Josva, Nuns sønn; for de har trolig fulgt Herren. 
4:32:13: Så optendtes Herrens vrede mot Israel, og han lot dem vanke om i ørkenen i firti år, til hele den slekt var utdød som hadde gjort det som var ondt i Herrens øine. 
4:32:14: Og nu treder I i eders fedres spor, en yngel av syndige menn, for ennu mere å øke Herrens brennende vrede mot Israel; 
4:32:15: for vender I eder bort, så I ikke vil følge ham, da vil han la Israel bli ennu lenger i ørkenen, og I vil føre ulykke over hele dette folk. 
4:32:16: Da gikk de frem til ham og sa: Vi vil bare bygge hegn her for vårt fe og byer for våre kvinner og barn, 
4:32:17: men selv vil vi skyndsomt væbne oss og dra frem foran Israels barn, til vi har ført dem dit de skal; men våre kvinner og barn skal bli i de faste byer, så de kan være trygge for landets innbyggere. 
4:32:18: Vi vil ikke vende tilbake til våre hus før Israels barn har fått hver sin arv; 
4:32:19: for vi vil ikke ta arv med dem på den andre side av Jordan og lenger borte når vi har fått vår arv på denne side, østenfor Jordan. 
4:32:20: Da sa Moses til dem: Dersom I gjør dette, så I væbner eder for Herrens åsyn til krigen, 
4:32:21: og alle eders væbnede menn drar over Jordan for Herrens åsyn, til han har drevet sine fiender bort fra sine øine, 
4:32:22: og I ikke vender tilbake før landet er undertvunget for Herrens åsyn, så skal I være uten skyld både for Herren og for Israel, og I skal ha landet her til eiendom for Herrens åsyn. 
4:32:23: Men dersom I ikke gjør så, da synder I mot Herren, og I skal vite at eders synd skal finne eder. 
4:32:24: Bygg eder da byer for eders kvinner og barn og hegn for eders småfe, og gjør som I har lovt! 
4:32:25: Da sa Gads barn og Rubens barn til Moses: Dine tjenere skal gjøre som min herre har befalt; 
4:32:26: våre barn, våre hustruer, vårt fe og alle våre kløvdyr skal bli her i Gileads byer; 
4:32:27: men dine tjenere, så mange av oss som er væbnet til strid, vil dra over Jordan for Herrens åsyn og være med i krigen, således som min herre sier. 
4:32:28: Og Moses gav befaling om dem til Eleasar, presten, og Josva, Nuns sønn, og overhodene for familiene i Israels barns stammer. 
4:32:29: Og Moses sa til dem: Dersom Gads barn og Rubens barn, så mange av dem som er væbnet, drar med eder over Jordan til krigen for Herrens åsyn, og I legger landet under eder, da skal I gi dem Gileads land til eiendom; 
4:32:30: men dersom de ikke drar væbnet over med eder, da skal de få sin eiendom i Kana'ans land, sammen med eder andre. 
4:32:31: Og Gads barn og Rubens barn svarte og sa: Det som Herren har sagt til dine tjenere, det vil vi gjøre; 
4:32:32: Vi vil dra væbnet over til Kana'ans land for Herrens åsyn, men vår arve-eiendom skal være på denne side av Jordan. 
4:32:33: Så lot Moses Gads barn og Rubens barn og halvdelen av Manasses, Josefs sønns stamme få de riker som Sihon, amorittenes konge, og Og, kongen i Basan, hadde eid, hele landet med byer og omliggende marker, alle byene i landet rundt omkring. 
4:32:34: Og Gads barn bygget op igjen Dibon og Atarot og Aroer 
4:32:35: og Atrot-Sofan og Jaser og Jogbeha 
4:32:36: og Bet-Nimra og Bet-Haran, faste byer og hegn for feet. 
4:32:37: Og Rubens barn bygget op igjen Hesbon og El'ale og Kirjata'im 
4:32:38: og Nebo og Ba'al-Meon, hvis navn blev forandret, og Sibma; og de gav de byer som de bygget op igjen, deres navn. 
4:32:39: Og Makirs, Manasses sønns barn drog til Gilead og inntok det og drev amorittene, som bodde der, bort. 
4:32:40: Og Moses gav Makir*, Manasses sønn, Gilead, og han bodde der. # <* nemlig hans efterkommere.> 
4:32:41: Og Ja'ir, Manasses sønn, drog avsted og inntok deres* landsbyer; og han kalte dem Ja'irs byer. # <* amorittenes.> 
4:32:42: Og Nobah drog avsted og inntok Kenat med tilhørende småbyer; og han kalte det Nobah efter sitt eget navn. 
4:33:1: Dette var Israels barns vandringer da de drog ut av Egyptens land, hær for hær, under Moses' og Arons førerskap. 
4:33:2: Efter Herrens befaling skrev Moses op de steder som de drog ut fra på sine vandringer, og dette er deres vandringer efter de steder som de drog ut fra: 
4:33:3: I den første måned, på den femtende dag i måneden, drog de ut fra Ra'amses; dagen efter påske drog Israels barn ut med løftet hånd for alle egypternes øine, 
4:33:4: mens egypterne jordet dem som Herren hadde slått ihjel blandt dem, alle sine førstefødte; også over deres guder hadde Herren holdt dom. 
4:33:5: Så drog da Israels barn fra Ra'amses og leiret sig i Sukkot. 
4:33:6: Og de drog fra Sukkot og leiret sig i Etam, som ligger ved grensen av ørkenen. 
4:33:7: Og de drog fra Etam og vendte sa om og tok veien til Pi-Hakirot, som ligger midt imot Ba'al-Sefon, og de leiret sig foran Migdol. 
4:33:8: Og de drog fra Hakirot og gikk gjennem havet til ørkenen; og de drog tre dagsreiser i Etams ørken og leiret sig i Mara. 
4:33:9: Og de drog fra Mara og kom til Elim. I Elim var det tolv vannkilder og sytti palmetrær, og de leiret sig der. 
4:33:10: Og de drog fra Elim og leiret sig ved det Røde Hav. 
4:33:11: Og de drog fra det Røde Hav og leiret sig i ørkenen Sin. 
4:33:12: Og de drog fra ørkenen Sin og leiret sig i Dofka. 
4:33:13: Og de drog fra Dofka og leiret sig i Alus. 
4:33:14: Og de drog fra Alus og leiret sig i Refidim; der hadde folket ikke vann å drikke. 
4:33:15: Og de drog fra Refidim og leiret sig i Sinai ørken. 
4:33:16: Og de drog fra Sinai ørken og leiret sig i Kibrot-Hatta'ava. 
4:33:17: Og de drog fra Kibrot-Hatta'ava og leiret sig i Haserot. 
4:33:18: Og de drog fra Haserot og leiret sig i Ritma. 
4:33:19: Og de drog fra Ritma og leiret sig i Rimmon-Peres. 
4:33:20: Og de drog fra Rimmon-Peres og leiret sig i Libna. 
4:33:21: Og de drog fra Libna og leiret sig i Rissa. 
4:33:22: Og de drog fra Rissa og leiret sig i Kehelata. 
4:33:23: Og de drog fra Kehelata og leiret sig ved Sefer-fjellet. 
4:33:24: Og de drog fra Sefer-fjellet og leiret sig i Harada. 
4:33:25: Og de drog fra Harada og leiret sig i Makhelot. 
4:33:26: Og de drog fra Makhelot og leiret sig i Tahat. 
4:33:27: Og de drog fra Tahat og leiret sig i Tarah. 
4:33:28: Og de drog fra Tarah og leiret sig i Mitka. 
4:33:29: Og de drog fra Mitka og leiret sig i Hasmona. 
4:33:30: Og de drog fra Hasmona og leiret sig i Moserot. 
4:33:31: Og de drog fra Moserot og leiret sig i Bene-Ja'akan. 
4:33:32: Og de drog fra Bene-Ja'akan og leiret sig i Hor-Hagidgad. 
4:33:33: Og de drog fra Hor-Hagidgad og leiret sig i Jotbata. 
4:33:34: Og de drog fra Jotbata og leiret sig i Abrona. 
4:33:35: Og de drog fra Abrona og leiret sig i Esjon-Geber. 
4:33:36: Og de drog fra Esjon-Geber og leiet sig i ørkenen Sin, i Kades. 
4:33:37: Og de drog fra Kades og leiret sig ved fjellet Hor på grensen av Edoms land. 
4:33:38: Da gikk Aron, presten, efter Herrens befaling op på fjellet Hor, og der døde han i det firtiende år efterat Israels barn var gått ut av Egyptens land, i den femte måned, på den første dag i måneden. 
4:33:39: Aron var hundre og tre og tyve år gammel da han døde på fjellet Hor. 
4:33:40: Men den kana'anittiske konge i Arad, som bodde i den sydlige del av Kana'ans land, fikk høre at Israels barn kom. 
4:33:41: Så drog de fra fjellet Hor og leiret sig i Salmona. 
4:33:42: og de drog fra Salmona og leiret sig i Punon. 
4:33:43: Og de drog fra Punon og leiret sig i Obot. 
4:33:44: Og de drog fra Obot og leiret sig i Ije-Ha'abarim ved Moabs grense. 
4:33:45: Og de drog fra Ijim og leiret sig i Dibon-Gad. 
4:33:46: Og de drog fra Dibon-Gad og leiret sig i Almon-Diblataima. 
4:33:47: Og de drog fra Almon-Diblataima og leiret sig ved Abarim-fjellene, foran Nebo. 
4:33:48: Og de drog fra Abarim-fjellene og leiret sig på Moabs ødemarker ved Jordan, midt imot Jeriko. 
4:33:49: Og deres leir ved Jordan strakte sig fra Bet-Hajesimot til Abel-Hassittim på Moabs ødemarker. 
4:33:50: Og Herren talte til Moses på Moabs ødemarker ved Jordan, midt imot Jeriko, og sa: 
4:33:51: Tal til Israels barn og si til dem: Når I har draget over Jordan inn i Kana'ans land, 
4:33:52: da skal I drive alle landets innbyggere bort foran eder og tilintetgjøre alle deres stener med innhugne billeder, og I skal tilintetgjøre alle deres støpte billeder og ødelegge alle deres offerhauger. 
4:33:53: Og I skal ta landet i eie og bo i det; for eder har jeg gitt landet til eiendom. 
4:33:54: Og I skal skifte landet mellem eder ved loddkasting efter eders ætter; den store ætt skal I gi en stor arv, og den lille ætt skal du gi en liten arv; enhver skal få sin del, efter som loddet faller; efter eders fedrenestammer skal I skifte det mellem eder. 
4:33:55: Men dersom I ikke driver landets innbyggere bort foran eder, da skal de som I lar bli tilbake av dem, bli torner i eders øine og brodder i eders sider, og de skal plage eder i det land som I bor i. 
4:33:56: Og det som jeg hadde tenkt å gjøre med dem, det vil jeg da gjøre med eder. 
4:34:1: Og Herren talte til Moses og sa: 
4:34:2: Byd Israels barn og si til dem: Når I kommer inn i Kana'ans land, så er dette det land som skal tilfalle eder som arv - Kana'ans land, så langt dets grenser når. 
4:34:3: På sydsiden skal eders land gå fra ørkenen Sin langsmed Edom, og eders sydgrense skal i øst begynne ved enden av Salthavet. 
4:34:4: Så skal grensen svinge sørom Akrabbim-skaret og gå frem til Sin, og den skal gå ut i syd for Kades-Barnea og så gå videre til Hasar-Adar og derfra ta over til Asmon. 
4:34:5: Fra Asmon skal grensen svinge bort til Egyptens bekk og så gå ut i havet. 
4:34:6: I vest skal eders grense være det store hav og landet langsmed det; dette skal være eders grense i vest. 
4:34:7: Og dette skal være eders grense i nord: Fra det store hav skal I avmerke grenselinjen til fjellet Hor. 
4:34:8: Fra fjellet Hor skal I avmerke grensen dit hvor veien går til Hamat, og grensen skal gå ut ved Sedad. 
4:34:9: Så skal grensen gå videre til Sifron og ende ved Hasar-Enan. Dette skal være eders grense i nord. 
4:34:10: Mot øst skal I avmerke eder en grenselinje som går fra Hasar-Enan til Sefam. 
4:34:11: Fra Sefam skal grensen gå ned til Ribla østenfor Ajin og derfra gå videre ned til den når fjellskråningen østenfor Kinnerets sjø*. # <* d.e. Gennesarets sjø.> 
4:34:12: Så skal grensen gå videre ned til Jordan og ende ved Salthavet. Dette skal være eders land efter dets grenser rundt omkring. 
4:34:13: Og Moses bød Israels barn og sa: Dette er det land I skal få til arv ved loddkasting, og som Herren har befalt å gi de ni stammer og den halve stamme. 
4:34:14: For Rubens barns stamme med sine familier og Gads barns stamme med sine familier og den halve Manasse stamme har alt fått sin arv; 
4:34:15: disse to og en halv stamme har fått sin arv på denne side av Jordan midt imot Jeriko - mot øst, mot solens opgang. 
4:34:16: Og Herren talte til Moses og sa: 
4:34:17: Dette er navnene på de menn som skal skifte ut landet mellem eder: Eleasar, presten, og Josva, Nuns sønn, 
4:34:18: og så en høvding for hver stamme, som I skal ta til å skifte ut landet, 
4:34:19: og dette er navnene på disse menn: for Juda stamme Kaleb, Jefunnes sønn, 
4:34:20: og for Simeons barns stamme Semuel, Ammihuds sønn, 
4:34:21: for Benjamins stamme Elidad, Kislons sønn, 
4:34:22: og for Dans barns stamme en høvding, Bukki, Joglis sønn, 
4:34:23: for Josefs barn: for Manasses barns stamme en høvding, Hanniel, Efods sønn, 
4:34:24: og for Efra'ims barns stamme en høvding, Kemuel, Siftans sønn, 
4:34:25: og for Sebulons barns stamme en høvding, Elisafan, Parnaks sønn, 
4:34:26: og for Issakars barns stamme en høvding, Paltiel, Assans sønn, 
4:34:27: og for Asers barns stamme en høvding, Akihud, Selomis sønn, 
4:34:28: og for Naftalis barns stamme en høvding, Pedael, Ammihuds sønn. 
4:34:29: Disse menn var det som Herren sa skulde skifte ut arveloddene mellem Israels barn i Kana'ans land. 
4:35:1: Og Herren talte til Moses på Moabs ødemarker ved Jordan, midt imot Jeriko, og sa: 
4:35:2: Byd Israels barn at de skal gi levittene byer av sin arveeiendom til å bo i; dessuten skal I gi levittene jorder rundt omkring disse byer. 
4:35:3: De skal ha byene til å bo i, og jordene som hører til, skal være til beite for deres kløvdyr og for deres fe og for alle deres andre dyr. 
4:35:4: Og jordene omkring byene, som I skal gi levittene, skal strekke sig fra bymuren tusen alen utefter rundt omkring. 
4:35:5: Utenfor byen skal I måle to tusen alen på østsiden og to tusen alen på sydsiden og to tusen alen på vestsiden og to tusen alen på nordsiden, og byen skal være i midten; dette skal være jordene til deres byer. 
4:35:6: De byer I skal gi levittene, skal være de seks tilfluktsstæder som I skal gi, forat manndrapere kan fly dit, og foruten dem skal I gi to og firti byer. 
4:35:7: I alt skal I gi levittene åtte og firti byer med tilhørende jorder. 
4:35:8: Og av de byer som I skal gi av Israels barns eiendom, skal I ta flere av de store stammer og færre av de mindre; enhver av dem skal gi levittene byer efter som de har fått land til. 
4:35:9: Og Herren talte til Moses og sa: 
4:35:10: Tal til Israels barn og si til dem: Når I er gått over Jordan inn i Kana'ans land, 
4:35:11: da skal I velge ut nogen byer som skal være tilfluktsstæder for eder, forat en manndraper som av vanvare slår nogen ihjel, kan fly dit. 
4:35:12: Disse byer skal I ha til tilfluktsstæder for manndraperen når han flykter for blodhevneren, forat han ikke skal dø før han har stått til doms for menigheten. 
4:35:13: Og de av eders byer som I skal gi til tilfluktsstæder, skal være seks i tallet. 
4:35:14: De tre byer skal I gi på hin side* Jordan, og de tre andre byer skal I gi i Kana'ans land; de skal være tilfluktsstæder. # <* østenfor.> 
4:35:15: Både for Israels barn og for de fremmede og innflyttede som bor iblandt dem, skal disse seks byer være tilfluktsstæder, så hver den som av vanvare slår nogen ihjel, kan fly dit. 
4:35:16: Den som slår nogen med et jernredskap så han dør, han er en manndraper, og manndraperen skal late livet. 
4:35:17: Og den som tar en sten i sin hånd stor nok til å drepe en med og slår nogen med den så han dør, han er en manndraper, og manndraperen skal late livet. 
4:35:18: Eller om en har et redskap av tre i sin hånd, som han kan drepe folk med, og slår nogen med det så han dør, da er han en manndraper, og manndraperen skal late livet. 
4:35:19: Blodhevneren kan drepe manndraperen; når han treffer på ham, kan han drepe ham. 
4:35:20: Dersom en støter til nogen av hat eller kaster noget på ham med vilje så han dør, 
4:35:21: eller av fiendskap slår ham med sin hånd så han dør, da skal den som slo, late livet; han er en manndraper, og blodhevneren kan drepe manndraperen når han treffer på ham. 
4:35:22: Men dersom en støter til nogen av vanvare, uten fiendskap, eller kaster en eller annen ting på ham uten å ville noget ondt, 
4:35:23: eller uten å se ham rammer ham med en sten stor nok til å drepe en med, så han dør, men drapsmannen ikke er hans fiende og ikke vil ham noget ondt, 
4:35:24: da skal menigheten dømme mellem drapsmannen og blodhevneren efter disse lover. 
4:35:25: Og menigheten skal verge manndraperen mot blodhevneren og la ham vende tilbake til den tilfluktsstad som han var flyktet til; og der skal han bli til ypperstepresten - han som er salvet med den hellige olje - er død. 
4:35:26: Men dersom manndraperen kommer utenfor den tilfluktsstads enemerker som han er flyktet til, 
4:35:27: og blodhevneren treffer ham utenfor hans tilfluktsstads enemerker og dreper manndraperen, så har han ingen blodskyld på sig. 
4:35:28: For manndraperen skal bli i sin tilfluktsstad til ypperstepresten er død; men efter yppersteprestens død kan han vende tilbake til den bygd hvor han har sin eiendom. 
4:35:29: Dette skal være en rettsordning for eder fra slekt til slekt hvor I så bor. 
4:35:30: Om en slår nogen ihjel, skal manndraperen efter vidners utsagn lide døden; men ett vidne er ikke nok til at nogen dømmes til døden. 
4:35:31: I skal ikke ta imot løsepenger for en manndrapers liv når han er skyldig til døden; han skal late livet. 
4:35:32: Og I skal ikke ta imot løsepenger for en som er flyktet til en tilfluktsstad, så han kan vende tilbake og bo et sted i landet før presten er død. 
4:35:33: I skal ikke vanhellige det land I bor i; for blod vanhelliger landet, og landet kan ikke få soning for det blod som utøses der, uten ved dens blod som utøser det. 
4:35:34: Du skal ikke gjøre det land I bor i, urent, det land i hvis midte jeg bor; for jeg er Herren, som bor midt iblandt Israels barn. 
4:36:1: Overhodene for familiene i den ætt som nedstammet fra Gilead, en sønn av Manasses sønn Makir, og hørte til Josefs barns ætter, gikk frem for Moses og for de høvdinger som var overhoder for Israels barns familier, 
4:36:2: og de sa: Herren har befalt min herre å gi Israels barn landet til arv ved loddkasting, og min herre har fått befaling av Herren om å gi vår bror Selofhads arv til hans døtre. 
4:36:3: Men blir nu de gift med menn av Israels barns andre stammer, så går deres arv fra våre fedres arv og legges til den stammes arv som de kommer til å tilhøre, og således blir vår arvelodd mindre. 
4:36:4: Og når jubelåret kommer for Israels barn, så blir deres arv lagt til den stammes arv som de kommer til å tilhøre, og deres arv går fra vår fedrenestammes arv. 
4:36:5: Da bød Moses Israels barn efter Herrens ord og sa: Josefs barns stamme har rett i det de sier. 
4:36:6: Således byder Herren om Selofhads døtre: De kan gifte sig med hvem de vil, bare de gifter sig innen sin fedrenestammes ætt, 
4:36:7: forat ikke nogen arv hos Israels barn skal gå over fra en stamme til en annen - Israels barn skal holde fast hver ved sin fedrenestammes arv. 
4:36:8: Og enhver datter i Israels barns stammer som får arv, skal gifte sig med en mann av sin fedrenestammes ætt, forat Israels barn kan arve hver sine fedres arv, 
4:36:9: og forat ingen arv skal gå over fra en stamme til en annen, men Israels barns stammer holde fast hver ved sin arv. 
4:36:10: Selofhads døtre gjorde således som Herren hadde befalt Moses. 
4:36:11: Mahla, Tirsa og Hogla og Milka og Noa, Selofhads døtre, blev gift med sine farbrødres sønner; 
4:36:12: de blev gift med menn av Manasses, Josefs sønns ætter, så deres arv blev hos deres fedreneætts stamme. 
4:36:13: Dette er de bud og lover som Herren gav Israels barn ved Moses på Moabs ødemarker ved Jordan, midt imot Jeriko. 
5:1:1: Dette er de ord Moses talte til hele Israel i ørkenen på hin side* Jordan, i ødemarken, midt imot Suf, mellem Paran og Tofel og Laban og Haserot og Di-Sahab, # <* østenfor.> 
5:1:2: elleve dagsreiser fra Horeb efter den vei som fører til Se'ir-fjellene, til Kades-Barnea. 
5:1:3: Det var i det firtiende år, i den ellevte måned, på den første dag i måneden Moses talte til Israels barn og bar frem alt det Herren hadde befalt ham å tale til dem, 
5:1:4: efterat han hadde slått Sihon, amorittenes konge, som bodde i Hesbon, og ved Edre'i hadde slått Og, kongen i Basan, som bodde i Astarot. 
5:1:5: På hin side Jordan, i Moabs land, tok Moses sig fore å utlegge denne lov og sa: 
5:1:6: Herren vår Gud talte til oss ved Horeb og sa: Lenge nok har I opholdt eder ved dette fjell. 
5:1:7: Bryt nu op og gi eder på veien til amoritter-fjellene og til alle de folk som bor deromkring, i ødemarken, i fjellbygdene og i lavlandet og i sydlandet og ved havstranden, til kana'anittenes land og til Libanon, helt til den store elv, elven Frat. 
5:1:8: Se, jeg har gitt landet i eders vold; dra avsted og innta det land som Herren har tilsvoret eders fedre Abraham, Isak og Jakob å ville gi dem og deres ætt efter dem! 
5:1:9: Dengang sa jeg til eder: Jeg makter ikke alene å bære eder. 
5:1:10: Herren eders Gud har gjort eder tallrike, så I idag er som stjernene på himmelen i mengde; 
5:1:11: og måtte bare Herren, eders fedres Gud, gjøre eder tusen ganger flere enn I er, og velsigne eder, som han har tilsagt eder! 
5:1:12: Men hvorledes kan jeg alene bære strevet og møien med eder og alle eders tretter? 
5:1:13: Kom med nogen vise og forstandige og prøvede menn fra hver av eders stammer! Så vil jeg sette dem til høvdinger over eder. 
5:1:14: Da svarte I mig: Det er både rett og godt det du sier. 
5:1:15: Så tok jeg høvdingene for eders stammer, vise og prøvede menn, og satte dem til høvdinger over eder, nogen over tusen og nogen over hundre og nogen over femti og nogen over ti, og jeg gjorde dem til tilsynsmenn over eders stammer. 
5:1:16: Dengang bød jeg også eders dommere og sa: Hør på de saker som eders brødre har sig imellem, og døm med rettferdighet mellem en mann og hans bror eller en fremmed som bor hos ham! 
5:1:17: I skal ikke gjøre forskjell på folk når I dømmer; den minste som den største skal I høre på. I skal ikke være redde for nogen, for dommen hører Gud til. Men om nogen sak er for vanskelig for eder, skal I føre den frem for mig; så vil jeg høre på den. 
5:1:18: Og på samme tid bød jeg eder alt det I skulde gjøre. 
5:1:19: Så brøt vi op fra Horeb og drog gjennem hele den store og forferdelige ørken som I har sett, på veien til amoritter-fjellene, således som Herren vår Gud bød oss; og vi kom til Kades-Barnea. 
5:1:20: Da sa jeg til eder: Nu er I kommet til amoritter-fjellene, som Herren vår Gud vil gi oss. 
5:1:21: Se, Herren din Gud har gitt landet i din vold; dra op og innta det, som Herren, dine fedres Gud, har tilsagt dig! Frykt ikke og vær ikke redd! 
5:1:22: Da kom I til mig alle sammen og sa: La oss sende folk i forveien for oss, så de kan utspeide landet for oss og gi oss beskjed om hvad vei vi skal dra dit op, og hvad byer vi kommer til! 
5:1:23: Dette syntes jeg godt om, og jeg tok ut blandt eder tolv menn, én mann for hver stamme. 
5:1:24: Og de tok avsted og drog op i fjellene og kom til Eskol-dalen; og de utspeidet landet. 
5:1:25: Og de tok med sig av landets frukter ned til oss, og de gav oss beskjed tilbake og sa: Det land som Herren vår Gud vil gi oss, er et godt land. 
5:1:26: Men I vilde ikke dra dit op; I var gjenstridige mot Herrens, eders Guds ord. 
5:1:27: Og I knurret i eders telter og sa: Herren hater oss; derfor har han ført oss ut av Egyptens land og vil gi oss i amorittenes hånd og ødelegge oss. 
5:1:28: Hvad er det for et land vi skal dra op til! Våre brødre har gjort vårt hjerte mistrøstig ved å si: Det er et folk som er større og høiere enn vi, og de har store byer med murer som når til himmelen; og der så vi også anakittenes barn. 
5:1:29: Da sa jeg til eder: I skal ikke forferdes og ikke være redde for dem; 
5:1:30: Herren eders Gud, som går foran eder, han skal stride for eder, således som I så han gjorde for eder i Egypten 
5:1:31: og i ørkenen du har sett, der hvor Herren din Gud bar dig som en mann bærer sitt barn, på hele den vei I har vandret til I kom til dette sted. 
5:1:32: Men allikevel trodde I ikke på Herren eders Gud, 
5:1:33: han som gikk foran eder på veien for å søke ut leirplass for eder, om natten i en ild, så I kunde se den vei I skulde gå, og om dagen i en sky. 
5:1:34: Da Herren hørte eders tale, blev han vred og svor: 
5:1:35: Sannelig, ikke nogen av disse menn, av denne onde slekt, skal se det gode land jeg har svoret å ville gi eders fedre - 
5:1:36: ingen uten Kaleb, Jefunnes sønn; han skal få se det; ham og hans barn vil jeg gi det land han har trådt på med sin fot, for han har trolig fulgt Herren. 
5:1:37: Også mig blev Herren vred på for eders skyld og sa: Heller ikke du skal komme der inn. 
5:1:38: Josva, Nuns sønn, som går dig til hånde, han skal komme der inn; styrk ham, for han skal skifte det ut til arv blandt Israel. 
5:1:39: Og eders barn, som I sa vilde bli til rov, og eders sønner, som ennu ikke kan skille godt fra ondt, de skal komme der inn, dem vil jeg gi det, og de skal ta det i eie. 
5:1:40: Men vend I om og ta ut i ørkenen, på veien til det Røde Hav! 
5:1:41: Da svarte I og sa til mig: Vi har syndet mot Herren; nu vil vi dra op og stride, således som Herren vår Gud har befalt oss. Og I omgjordet eder med eders stridsvåben hver og en, og I holdt det for en lett sak å dra op i fjellene. 
5:1:42: Da sa Herren til mig: Si til dem: I skal ikke dra op, og I skal ikke stride; for jeg er ikke med eder; I kommer bare til å bli slått av eders fiender. 
5:1:43: Og jeg talte til eder, men I hørte ikke på mig; I var gjenstridige mot Herrens ord og dristet eder til å dra op i fjellene. 
5:1:44: Da drog amorittene, som bodde der i fjellene, ut mot eder, og de forfulgte eder likesom en bisverm, og de slo eder sønder og sammen i Se'ir og drev eder like til Horma. 
5:1:45: Og I vendte tilbake og gråt for Herrens åsyn; men Herren hørte ikke på eders klager og vendte ikke sitt øre til eder. 
5:1:46: Og I måtte bli i Kades i lang tid, hele den tid I var der. 
5:2:1: Så vendte vi om og drog til ørkenen på veien til det Røde Hav, således som Herren hadde sagt til mig; og vi drog i lang tid omkring Se'ir-fjellene. 
5:2:2: Da sa Herren til mig: 
5:2:3: Lenge nok har I draget omkring disse fjell; vend eder nu mot nord! 
5:2:4: Og byd folket og si: I drar nu frem gjennem det land som tilhører eders brødre Esaus barn, som bor i Se'ir; og de blir redde for eder, men I skal ta eder vel i akt, 
5:2:5: så I ikke gir eder i strid med dem; jeg vil ikke gi eder så meget som en fotbredd av deres land, for jeg har gitt Esau Se'ir-fjellene til eiendom. 
5:2:6: Mat skal I kjøpe av dem for penger, så I kan ete, og vann skal I også kjøpe av dem for penger, så I kan drikke; 
5:2:7: for Herren din Gud har velsignet dig i alt det du har tatt dig fore, han har båret omsorg for dig på din vandring gjennem denne store ørken; i firti år har Herren din Gud nu vært med dig, du har ikke manglet noget. 
5:2:8: Så drog vi videre, bort fra våre brødre Esaus barn, som bor i Se'ir; vi tok av fra veien som går fra Elat og Esjon-Geber gjennem ødemarken, og vendte oss til en annen kant og drog frem på veien til Moabs ørken. 
5:2:9: Da sa Herren til mig: Du må ikke angripe moabittene og ikke gi dig i strid med dem; jeg vil ikke gi dig noget av deres land til eiendom, for jeg har gitt Lots barn Ar til eiendom. 
5:2:10: Før i tiden bodde emittene der, et stort og tallrikt folk og høit av vekst likesom anakittene. 
5:2:11: Også de regnes for kjemper likesom anakittene, og moabittene kaller dem emitter. 
5:2:12: Og i Se'ir bodde før i tiden horittene; men Esaus barn drev dem bort og utryddet dem og bosatte sig der i deres sted, likesom Israel nu har gjort med sitt land, det som Herren har gitt dem til eiendom. 
5:2:13: Gjør eder nu rede og gå over Sered-bekken! Så gikk vi over Sered-bekken. 
5:2:14: Den tid vi var på vandring fra Kades-Barnea, til vi gikk over Sered-bekken, var åtte og tretti år, og da var hele den slekt - alle våbenføre menn - utdød av leiren, som Herren hadde svoret at det skulde gå dem. 
5:2:15: Og Herrens hånd var også mot dem, så han rev dem bort av leiren, til det var ute med dem. 
5:2:16: Da nu alle våbenføre menn var utdød av folket, 
5:2:17: da talte Herren til mig og sa: 
5:2:18: Du drar nu over Moabs landemerker, gjennem Ar, 
5:2:19: og du kommer nær Ammons barns land; men du må ikke angripe dem eller gi dig i strid med dem; jeg vil ikke gi dig noget av Ammons barns land til eiendom, for jeg har gitt Lots barn det til eiendom. 
5:2:20: Også dette land regnes for et land med kjemper; før i tiden bodde kjemper der, og ammonittene kaller dem samsummitter. 
5:2:21: De var et stort og tallrikt folk og høie av vekst likesom anakittene; men Herren utryddet dem for Ammons barn, så de drev dem bort og bosatte sig der i deres sted. 
5:2:22: Det samme gjorde han for Esaus barn, som bor i Se'ir; for dem utryddet han horittene, så de drev dem bort og bosatte sig der i deres sted, og siden har de bodd der til denne dag. 
5:2:23: Likedan gikk det med avittene, som bodde i landsbyene like til Gasa; de blev ødelagt av kaftorerne, som kom fra Kaftor og bosatte sig der i deres sted. 
5:2:24: Gjør eder rede, bryt op og gå over Arnon-åen! Se, jeg har gitt amoritten Sihon, kongen i Hesbon, og hans land i din hånd; gå nu i gang med å innta det, og gi dig i strid med ham! 
5:2:25: Fra denne dag vil jeg la redsel for dig og frykt for dig komme over alle folk under himmelen; alle som får høre om dig, skal skjelve og beve for dig. 
5:2:26: Da sendte jeg bud fra ørkenen Kedemot til Sihon, kongen i Hesbon, med fredelige ord og lot si: 
5:2:27: La mig få dra gjennem ditt land! Jeg vil holde mig på veien, jeg vil ikke vike av, hverken til høire eller til venstre. 
5:2:28: Mat kan du selge mig for penger, så jeg kan ete, og vann kan du også gi mig for penger, så jeg kan drikke. La mig bare få dra igjennem på min fot, 
5:2:29: likesom Esaus barn, som bor i Se'ir, og moabittene, som bor i Ar, gav mig lov til å gjøre - så jeg kan gå over Jordan til det land som Herren vår Gud gir oss. 
5:2:30: Men Sihon, kongen i Hesbon, vilde ikke la oss dra gjennem sitt land; for Herren din Gud hadde forherdet hans sinn og gjort hans hjerte hårdt for å gi ham i din hånd, som det kan sees på denne dag. 
5:2:31: Og Herren sa til mig: Se, nu gir jeg Sihon og hans land i din vold; gå nu du i gang med å innta det, så du får hans land i eie. 
5:2:32: Og Sihon og hele hans folk drog ut mot oss til strid, til Jahas. 
5:2:33: Og Herren vår Gud gav ham i vår vold, og vi slo ham og hans sønner og alt hans folk ihjel. 
5:2:34: Og vi inntok dengang alle hans byer og slo hver by med bann, menn og kvinner og barn; vi lot ikke nogen bli tilbake eller slippe unda. 
5:2:35: Bare feet tok vi til bytte foruten hærfanget fra byene som vi inntok. 
5:2:36: Fra Aroer, som ligger ved bredden av Arnon-åen, og fra byen i dalen og like til Gilead var der ikke en by hvis murer var oss for høie; Herren vår Gud gav dem alle i vår vold. 
5:2:37: Men Ammons barns land kom du ikke nær, hverken landet langsmed Jabbok-åen eller byene i fjellene eller noget annet som Herren vår Gud hadde forbudt oss å ta. 
5:3:1: Så tok vi på en annen kant og drog op på veien til Basan; da drog Og, kongen i Basan, og hele hans folk ut mot oss til strid, til Edre'i. 
5:3:2: Og Herren sa til mig: Frykt ikke for ham! For jeg har gitt ham og hele hans folk og hans land i din hånd, og du skal gjøre med ham som du gjorde med Sihon, amorittenes konge, som bodde i Hesbon. 
5:3:3: Så gav Herren vår Gud også Og, kongen i Basan, og hele hans folk i vår hånd; og vi slo ham så vi ikke lot nogen bli tilbake eller slippe unda. 
5:3:4: Og vi inntok dengang alle hans byer; der var ikke en by uten at vi tok den fra dem, seksti byer, hele Argob-landet, Ogs rike i Basan. 
5:3:5: Alle disse var faste byer med høie murer, dobbelte porter og bommer, foruten en stor mengde landsbyer. 
5:3:6: Og vi slo dem med bann, som vi hadde gjort med Sihon, kongen i Hesbon; hver by slo vi med bann, menn, kvinner og barn. 
5:3:7: Men alt feet og hærfanget fra byene tok vi til bytte. 
5:3:8: Således tok vi dengang landet fra begge amoritterkongene på denne side* av Jordan, fra Arnon-åen like til fjellet Hermon - # <* østenfor.> 
5:3:9: sidonierne kaller Hermon Sirjon, og amorittene kaller det Senir - 
5:3:10: alle byene på sletten og hele Gilead og hele Basan like til byene Salka og Edre'i i Ogs rike i Basan; 
5:3:11: for Og, kongen i Basan, var den siste som var tilbake av refa'ittene; hans seng, som er av jern, står, som alle vet, i ammonitterbyen Rabba; den er ni alen lang og fire alen bred, alnen regnet efter lengden av en manns underarm. 
5:3:12: Dette land inntok vi dengang: Bygdene fra Aroer, som ligger ved Arnon-åen, og halvdelen av Gileadfjellene med byene der gav jeg til rubenittene og gadittene, 
5:3:13: og resten av Gilead og hele Basan, Ogs rike, gav jeg til den halve Manasse stamme, hele Argob-landet, hele Basan; det er det som kalles refa'ittenes land. 
5:3:14: Ja'ir, Manasses sønn, inntok hele Argob-landet like til gesurittenes og ma'akatittenes landemerker, og dette land - Basan-landet - kalte han efter sitt eget navn Ja'irs byer, og så har det hett til denne dag. 
5:3:15: Og til Makir gav jeg Gilead. 
5:3:16: Og til rubenittene og gadittene gav jeg landet fra Gilead til Arnon-åen, til midt i åen, og landet langsmed den, og til Jabbok-åen, Ammons barns grense, 
5:3:17: og ødemarken og Jordan og landet langsmed den, fra Kinnerets sjø til Ødemarks-havet - Salthavet - nedenfor Pisga-liene, mot øst. 
5:3:18: Dengang bød jeg eder og sa: Herren eders Gud har gitt eder dette land til eie; væbnet skal I, alle som er krigsdyktige menn, dra frem foran eders brødre, Israels barn. 
5:3:19: Bare eders hustruer og barn og eders fe - jeg vet at I har meget fe - skal bli igjen i de byer jeg har gitt eder, 
5:3:20: inntil Herren gir eders brødre ro likesom eder, og de og har tatt det land i eie som Herren eders Gud gir dem på hin side Jordan; da kan I vende tilbake, hver til den eiendom jeg har gitt eder. 
5:3:21: På samme tid bød jeg Josva og sa: Du har med egne øine sett alt det Herren eders Gud har gjort med disse to konger; således vil Herren gjøre med alle de riker du drar over til. 
5:3:22: I skal ikke være redde for dem; for Herren eders Gud vil selv stride for eder. 
5:3:23: For jeg bønnfalt dengang Herren og sa: 
5:3:24: Herre, Herre! Du har begynt å la din tjener se din storhet og din sterke hånd; for hvor er det en gud i himmelen og på jorden som kan gjøre sådanne gjerninger og storverk som du? 
5:3:25: La mig nu få dra over og se det gode land på hin side Jordan, dette gode fjell-land og Libanon! 
5:3:26: Men Herren var vred på mig for eders skyld, og han hørte ikke på mig, men han sa til mig: La det nu være nok, tal ikke mere til mig om dette! 
5:3:27: Gå op på Pisgas topp og løft dine øine mot vest og mot nord og mot syd og mot øst, og se vidt omkring dig! For over Jordan her skal du ikke komme. 
5:3:28: Og gi Josva befaling og gjør ham frimodig og sterk! For han skal føre dette folk over, og han skal gi dem til arv det land du skal skue. 
5:3:29: Så blev vi da i dalen midt imot Bet-Peor. 
5:4:1: Så hør nu, Israel, de lover og de bud som jeg lærer eder å holde, forat I må leve og komme inn i det land som Herren, eders fedres Gud, gir eder, og ta det i eie! 
5:4:2: I skal ikke legge noget til det ord jeg byder eder, og I skal ikke ta noget fra, men I skal holde Herrens, eders Guds bud som jeg gir eder. 
5:4:3: I har med egne øine sett hvad Herren gjorde da det hendte det med Ba'al Peor; hver mann som holdt sig til Ba'al Peor, utryddet Herren din Gud av din midte, 
5:4:4: men I som holdt fast ved Herren eders Gud, I lever alle den dag idag. 
5:4:5: Se, jeg har lært eder lover og bud, således som Herren min Gud bød mig, forat I skal gjøre efter dem i det land I drar inn i og skal ta i eie. 
5:4:6: Så skal I da ta vare på dem og holde dem; det vil bli regnet for visdom og forstand hos eder av andre folk; for når de får høre om alle disse lover, vil de si: Sannelig, et vist og forstandig folk er dette store folk. 
5:4:7: For hvor finnes det et folk, om det er aldri så stort, som har guder som er det så nær som Herren vår Gud er oss, så titt vi kaller på ham? 
5:4:8: Og hvor finnes det et folk, om det er aldri så stort, som har så rettferdige lover og bud som hele denne lov jeg legger frem for eder idag? 
5:4:9: Vokt dig bare og ta dig vel i akt at du ikke glemmer det dine øine har sett, så det ikke går ut av din hu alle ditt livs dager, men kunngjør det for dine barn og dine barnebarn, 
5:4:10: det du så den dag du stod for Herrens, din Guds åsyn ved Horeb, da Herren sa til mig: Kall folket sammen for mig, forat jeg kan la dem høre mine ord, så de kan lære å frykte mig alle de dager de lever på jorden, og også lære sine barn dem. 
5:4:11: Da kom I nær til og stod nedenfor fjellet, mens fjellet stod i brennende lue like inn i himmelen, og der var mørke og skyer og skodde. 
5:4:12: Og Herren talte til eder midt ut av ilden; I hørte lyden av ordene, men nogen skikkelse blev I ikke var; I hørte bare lyden. 
5:4:13: Og han forkynte eder sin pakt, som han bød eder å holde, de ti ord; og han skrev dem på to stentavler. 
5:4:14: Og mig bød Herren på samme tid å lære eder lover og bud, som I skal leve efter i det land I drar over til og skal ta i eie. 
5:4:15: Så ta eder nu vel i vare, så sant I har eders liv kjært - for I så ingen skikkelse den dag Herren talte til eder på Horeb midt ut av ilden - 
5:4:16: at I ikke forsynder eder med å gjøre eder noget utskåret billede, noget slags avgudsbillede, i skikkelse av mann eller kvinne 
5:4:17: eller av noget firføtt dyr på jorden eller av nogen vinget fugl som flyver under himmelen, 
5:4:18: eller av noget dyr som kryper på jorden, eller av nogen fisk i vannet nedenfor jorden, 
5:4:19: og at du ikke, når du løfter dine øine op til himmelen og ser solen og månen og stjernene, hele himmelens hær, lar dig føre vill, så du tilbeder dem og dyrker dem, de som Herren din Gud har tildelt alle folkene under hele himmelen. 
5:4:20: Men eder har Herren tatt og ført ut av jernovnen, av Egypten, forat I skal være hans eiendomsfolk, således som det kan sees på denne dag. 
5:4:21: Og Herren blev vred på mig for eders skyld og svor at jeg ikke skulde få gå over Jordan og ikke komme inn i det gode land som Herren din Gud gir dig til arv. 
5:4:22: For jeg må dø her i dette land, jeg kommer ikke over Jordan; men I skal gå over den og ta dette gode land i eie. 
5:4:23: Ta eder da i vare at I ikke glemmer Herrens, eders Guds pakt, som han har gjort med eder, og gjør eder noget utskåret billede av noget slag; for det har Herren din Gud forbudt dig! 
5:4:24: For Herren din Gud er en fortærende ild, en nidkjær Gud. 
5:4:25: Når du får barn og barnebarn og I blir gamle i landet, og I forsynder eder med å gjøre noget utskåret billede av noget slag, så I gjør hvad ondt er i Herrens, eders Guds øine og dermed egger ham til vrede, 
5:4:26: så tar jeg idag himmelen og jorden til vidne mot eder at I visselig snart skal utryddes av det land som I nu drar inn i over Jordan og skal ta i eie; I skal ikke leve mange dager der, men bli helt ødelagt. 
5:4:27: Herren skal sprede eder blandt folkene, så bare en liten flokk av eder blir tilbake blandt de hedningefolk Herren fører eder bort til. 
5:4:28: Og der skal I dyrke guder som er gjort av menneskehender, stokk og sten, som ikke ser og ikke hører og ikke eter og ikke lukter. 
5:4:29: Der skal I søke Herren din Gud, og du skal finne ham når du søker ham av alt ditt hjerte og av all din sjel. 
5:4:30: Når du er i trengsel, og alle disse ting kommer over dig, i de siste dager, da skal du omvende dig til Herren din Gud og høre på hans røst. 
5:4:31: For Herren din Gud er en barmhjertig Gud; han skal ikke slippe dig og ikke la dig gå til grunne; han skal ikke glemme den pakt med dine fedre som han tilsvor dem. 
5:4:32: For spør bare om de fremfarne dager, som var før din tid, like fra den dag da Gud skapte menneskene på jorden, og spør fra den ene ende av himmelen til den andre om det er hendt eller hørt noget som er så stort som dette, 
5:4:33: om noget folk har hørt Guds røst tale midt ut av ilden, således som du har gjort, og er blitt i live, 
5:4:34: eller om Gud har prøvd på å komme og ta sig et folk midt ut av et annet folk ved prøvelser, ved tegn og undergjerninger og ved krig og med sterk hånd og utrakt arm og store, forferdelige gjerninger, således som du med egne øine har sett Herren eders Gud gjorde med eder i Egypten. 
5:4:35: Du har fått se alt dette, forat du skal vite at Herren han er Gud, og ingen uten han alene. 
5:4:36: Fra himmelen har han latt dig høre sin røst for å lære dig, og på jorden har han latt dig se sin store ild, og hans ord har du hørt midt ut av ilden. 
5:4:37: Og fordi han elsket dine fedre og utvalgte deres efterkommere, så førte han dig selv med sin store kraft ut av Egypten 
5:4:38: for å drive ut for dig større og sterkere folk enn du er, og føre dig inn og gi dig deres land til arv, som det kan sees på denne dag. 
5:4:39: Så skal du da idag vite og ta dig det til hjerte at Herren han er Gud både i himmelen der oppe og på jorden her nede, han og ingen annen. 
5:4:40: Og du skal ta vare på hans lover og hans bud, som jeg gir dig idag, forat det kan gå dig vel og dine barn efter dig, og forat du kan leve mange dager i det land Herren din Gud gir dig til evig eie. 
5:4:41: På den tid skilte Moses ut tre byer på hin side Jordan, på østsiden, 
5:4:42: forat en manndraper som hadde slått sin næste ihjel av vanvare og uten før å ha båret hat til ham, kunde fly dit - til en av disse byer - og redde livet; 
5:4:43: det var Beser i ørkenen på sletten for rubenittene, og Ramot i Gilead for gadittene, og Golan i Basan for manassittene. 
5:4:44: Og dette er den lov som Moses la frem for Israels barn; 
5:4:45: dette er de vidnesbyrd og forskrifter og bud som Moses forkynte Israels barn da de var gått ut av Egypten, 
5:4:46: på hin side* Jordan i dalen midt imot Bet-Peor, i det land som hadde tilhørt amorittenes konge Sihon, han som bodde i Hesbon, og som Moses og Israels barn slo da de var gått ut av Egypten; # <* østenfor. 5MO 1, 4.> 
5:4:47: da inntok de både hans land og Basan-kongen Ogs land, begge amoritterkongenes land på hin side Jordan, på østsiden, 
5:4:48: fra Aroer, som ligger ved bredden av Arnon-åen, til fjellet Sion, det er Hermon, 
5:4:49: og hele ødemarken på hin side Jordan, på østsiden, like til Ødemarks-havet nedenfor Pisga-liene. 
5:5:1: Og Moses kalte hele Israel til sig og sa til dem: Hør, Israel, de lover og de bud som jeg taler for eders ører idag, og lær dem og ta vare på dem så I holder dem! 
5:5:2: Herren vår Gud gjorde en pakt med oss på Horeb. 
5:5:3: Ikke med våre fedre gjorde Herren denne pakt, men med oss, vi som er her, alle vi som er i live idag. 
5:5:4: Åsyn til åsyn talte Herren med eder på fjellet midt ut av ilden. 
5:5:5: Jeg stod dengang mellem Herren og eder for å kunngjøre eder Herrens ord, for I var redde for ilden og gikk ikke op på fjellet. Og han sa: 
5:5:6: Jeg er Herren din Gud, som førte dig ut av Egyptens land, av trælehuset. 
5:5:7: Du skal ikke ha andre guder foruten mig. 
5:5:8: Du skal ikke gjøre dig noget utskåret billede, nogen avbildning av det som er oppe i himmelen, eller av det som er nede på jorden, eller av det som er i vannet nedenfor jorden. 
5:5:9: Du skal ikke tilbede dem og ikke tjene dem; for jeg, Herren din Gud, er en nidkjær Gud, som hjemsøker fedres misgjerning på barn, på dem i tredje og på dem i fjerde ledd, på dem som hater mig, 
5:5:10: og som gjør miskunnhet mot tusen ledd, mot dem som elsker mig og holder mine bud. 
5:5:11: Du skal ikke misbruke Herrens, din Guds navn; for Herren vil ikke holde den uskyldig som misbruker hans navn. 
5:5:12: Ta hviledagen i akt så du holder den hellig, således som Herren din Gud har befalt dig! 
5:5:13: Seks dager skal du arbeide og gjøre all din gjerning. 
5:5:14: Men den syvende dag er sabbat for Herren din Gud; da skal du intet arbeid gjøre, hverken du eller din sønn eller din datter eller din tjener eller din tjenestepike eller din okse eller ditt asen eller noget av dine dyr eller den fremmede som er hos dig innen dine porter, forat din tjener og din tjenestepike kan få hvile likesom du. 
5:5:15: For du skal komme i hu at du selv var tjener i Egyptens land, og at Herren din Gud førte dig ut derfra med sterk hånd og utrakt arm; derfor har Herren din Gud befalt dig å holde sabbatsdagen. 
5:5:16: Hedre din far og din mor, således som Herren din Gud har befalt dig, så dine dager må bli mange, og det må gå dig vel i det land Herren din Gud gir dig. 
5:5:17: Du skal ikke slå ihjel. 
5:5:18: Og du skal ikke drive hor. 
5:5:19: Og du skal ikke stjele. 
5:5:20: Og du skal ikke si falskt vidnesbyrd mot din næste. 
5:5:21: Og du skal ikke begjære din næstes hustru. Og du skal ikke begjære din næstes hus, hans jord eller hans tjener eller hans tjenestepike, hans okse eller hans asen eller noget som hører din næste til. 
5:5:22: Disse ord talte Herren på fjellet med høi røst til hele eders menighet midt ut av ilden, skyen og mørket og la intet til, og han skrev dem på to stentavler og gav mig dem. 
5:5:23: Men da I hørte røsten midt ut av mørket, mens fjellet stod i brennende lue, da kom I til mig, alle overhodene for eders stammer og eders eldste, 
5:5:24: og I sa: Se, Herren vår Gud har latt oss skue sin herlighet og sin storhet, og vi har hørt hans røst midt ut av ilden; på denne dag har vi sett at Gud kan tale med et menneske, og det allikevel blir i live. 
5:5:25: Hvorfor skal vi da dø? For denne store ild vil fortære oss; dersom vi ennu lenger skal høre Herrens, vår Guds røst, må vi dø. 
5:5:26: For hvem er der på hele jorden som har hørt den levende Guds røst tale midt ut av ilden som vi, og allikevel er blitt i live? 
5:5:27: Gå du nær til og hør alt det Herren vår Gud sier, så kan du tale til oss alt det Herren vår Gud taler til dig, og vi vil høre på det og gjøre efter det. 
5:5:28: Og Herren hørte eders ord da I talte til mig, og Herren sa til mig: Jeg har hørt de ord som dette folk har talt til dig; de har talt vel i alt de har sagt. 
5:5:29: Måtte de bare alle dager ha det samme hjertelag til å frykte mig og ta vare på alle mine bud, så det kan gå dem og deres barn vel til evig tid! 
5:5:30: Gå og si til dem: Vend tilbake til eders telter! 
5:5:31: Men stå du her hos mig, så vil jeg tale til dig alle de bud og lover og forskrifter som du skal lære dem, og som de skal leve efter i det land jeg gir dem til eie. 
5:5:32: Så akt nu på det som Herren eders Gud har befalt eder, og gjør efter det! I skal ikke vike av, hverken til høire eller til venstre. 
5:5:33: På hele den vei Herren eders Gud har befalt eder, skal I vandre, så I må leve, og det må gå eder vel, og eders dager bli mange i det land I skal ta i eie. 
5:6:1: Dette er de bud, de lover og de forskrifter som Herren eders Gud har befalt mig å lære eder, og som I skal leve efter i det land I drar over til og skal ta i eie - 
5:6:2: forat du skal frykte Herren din Gud og ta vare på alle hans lover og bud, som jeg byder dig, både du og din sønn og din sønnesønn, alle ditt livs dager, og forat dine dager må bli mange. 
5:6:3: Så hør, Israel, og akt vel på å holde dem, så det må gå dig vel, og I må økes og bli tallrike, således som Herren, dine fedres Gud, har tilsagt dig - i et land som flyter med melk og honning. 
5:6:4: Hør, Israel! Herren vår Gud, Herren er en. 
5:6:5: Og du skal elske Herren din Gud av alt ditt hjerte og av all din sjel og av all din makt. 
5:6:6: Og disse ord som jeg byder dig idag, skal du gjemme i ditt hjerte. 
5:6:7: Og du skal innprente dine barn dem, og du skal tale om dem når du sitter i ditt hus, og når du går på veien, og når du legger dig, og når du står op. 
5:6:8: Og du skal binde dem som et tegn på din hånd, og de skal være som en minneseddel på din panne. 
5:6:9: Og du skal skrive dem på dørstolpene i ditt hus og på dine porter. 
5:6:10: Og når Herren din Gud fører dig inn i det land han har tilsvoret dine fedre Abraham, Isak og Jakob å ville gi dig - store og gode byer som du ikke har bygget, 
5:6:11: og hus fulle av alle gode ting, som du ikke har fylt, og uthugne brønner som du ikke har uthugget, vingårder og oljetrær som du ikke har plantet - når du da eter og blir mett, 
5:6:12: da ta dig i vare at du ikke glemmer Herren, som førte dig ut av Egyptens land, av trælehuset! 
5:6:13: Herren din Gud skal du frykte, og ham skal du tjene, og ved hans navn skal du sverge. 
5:6:14: I skal ikke følge andre guder, av de folks guder som bor rundt omkring eder - 
5:6:15: for en nidkjær Gud er Herren din Gud, som er i din midte - forat ikke Herrens, din Guds vrede skal optendes mot dig, og han skal utrydde dig av jorden. 
5:6:16: I skal ikke friste Herren eders Gud, således som I fristet ham i Massa. 
5:6:17: I skal ta vare på Herrens, eders Guds bud og hans vidnesbyrd og hans forskrifter, som han har gitt dig. 
5:6:18: Og du skal gjøre det som er rett og godt i Herrens øine, forat det må gå dig vel, og du må komme inn i det gode land som Herren har tilsvoret dine fedre, og ta det i eie, 
5:6:19: og han skal drive ut alle dine fiender for dig, således som Herren har sagt. 
5:6:20: Når din sønn i fremtiden spør dig og sier: Hvorledes er det med de vidnesbyrd og de forskrifter og de bud som Herren vår Gud har gitt eder? 
5:6:21: da skal du si til ham: Vi var træler hos Farao i Egypten; men Herren førte oss ut av Egypten med sterk hånd. 
5:6:22: Og Herren gjorde store og ødeleggende tegn og under på egypterne, på Farao og på hele hans hus for våre øine. 
5:6:23: Men oss førte han ut derfra for å føre oss inn i det land han hadde tilsvoret våre fedre, og gi oss det. 
5:6:24: Og Herren bød oss å holde alle disse lover, å frykte Herren vår Gud, forat det skulde gå oss vel alle dager, og han holde oss i live, som det er gått til denne dag. 
5:6:25: Og det skal tjene oss til rettferdighet, når vi akter vel på å holde alle disse bud for Herrens, vår Guds åsyn, således som han bød oss. 
5:7:1: Når Herren din Gud har ført dig inn i det land du nu drar inn i og skal ta i eie, og han driver ut mange folk for dig, hetittene og girgasittene og amorittene og kana'anittene og ferisittene og hevittene og jebusittene, syv folk, som er større og sterkere enn du, 
5:7:2: og når Herren din Gud gir dem i din vold, så du vinner over dem, da skal du slå dem med bann; du skal ikke gjøre pakt med dem og ikke vise dem nåde. 
5:7:3: Du skal ikke inngå svogerskap med dem; du skal ikke gi dine døtre til hans sønner og ikke ta hans døtre til hustruer for dine sønner; 
5:7:4: for de vil få dine sønner til å vike av fra mig, så de dyrker andre guder, og da vil Herrens vrede optendes mot eder, og han vil hastig gjøre ende på dig. 
5:7:5: Men således skal I gjøre med dem: Deres altere skal I bryte ned, og deres billedstøtter skal I slå sønder, og deres Astarte-billeder skal I hugge i stykker, og deres utskårne billeder skal I brenne op med ild. 
5:7:6: For et hellig folk er du for Herren din Gud; dig har Herren din Gud utvalgt av alle de folk som er på jorden, til å være hans eiendomsfolk. 
5:7:7: Ikke fordi I var større enn alle andre folk, fant Herren behag i eder, så han utvalgte eder, for I er det minste blandt alle folk; 
5:7:8: men fordi Herren elsket eder, og fordi han vilde holde den ed han hadde svoret eders fedre, derfor førte Herren eder ut med sterk hånd og fridde dig ut av trælehuset, fra Faraos, egypterkongens hånd. 
5:7:9: Så vit da at Herren din Gud han er Gud, den trofaste Gud, som holder sin pakt og bevarer sin miskunnhet mot tusen ledd, mot dem som elsker ham og holder hans bud, 
5:7:10: men som gjengjelder dem som hater ham, like op i deres øine, så han lar dem omkomme. Han er ikke sen når det gjelder den som hater ham; like op i hans øine gjengjelder han ham. 
5:7:11: Så ta da vare på det bud og de lover og de forskrifter som jeg idag byder dig å holde. 
5:7:12: Når I nu hører disse bud og tar vare på dem og holder dem, da skal Herren din Gud holde fast ved den pakt og den miskunnhet han har tilsvoret dine fedre. 
5:7:13: Han skal elske dig og velsigne dig og øke din ætt, og han skal velsigne ditt livs frukt og ditt lands frukt, ditt korn og din most og din olje, det som faller av ditt storfe, og det som fødes av ditt småfe, i det land han har tilsvoret dine fedre å ville gi dig. 
5:7:14: Velsignet skal du være fremfor alle andre folk; der skal ingen ufruktbar være blandt dine menn eller dine kvinner, og heller ikke blandt ditt fe. 
5:7:15: Herren skal holde all sykdom borte fra dig, og ingen av Egyptens onde syker, som du kjenner, skal han legge på dig, men han skal legge dem på alle dem som hater dig. 
5:7:16: Alle de folk som Herren din Gud gir i din hånd, skal du fortære*; du skal ikke spare dem. Og du skal ikke dyrke deres guder; for det vil bli en snare for dig. # <* 4MO 14, 9.> 
5:7:17: Men om du tenker som så: Disse folk er større enn jeg, hvorledes skal jeg makte å drive dem ut? - 
5:7:18: da skal du ikke være redd for dem; du skal komme i hu hvad Herren din Gud gjorde med Farao og med alle egypterne, 
5:7:19: de store plager som du så for dine øine, og de tegn og under og den sterke hånd og den utrakte arm hvormed Herren din Gud førte dig ut; det samme skal Herren din Gud gjøre med alle de folk du reddes for. 
5:7:20: Endog hvepser skal Herren din Gud sende mot dem, til alle de er omkommet som er blitt tilbake og har skjult sig for dig. 
5:7:21: Du skal ikke forferdes for dem; for Herren din Gud er i din midte, en stor og forferdelig Gud. 
5:7:22: Herren din Gud vil litt efter litt drive disse folk ut for dig; du skal ikke makte å gjøre ende på dem i hast; for da kom villdyrene til å bli for mange for dig. 
5:7:23: Herren din Gud skal gi dem i din vold og slå dem med stor forvirring, til de er ødelagt. 
5:7:24: Og han skal gi deres konger i din hånd, og du skal tilintetgjøre deres navn under himmelen; der skal ingen kunne stå sig mot dig, til du har utryddet dem. 
5:7:25: Deres guders utskårne billeder skal I brenne op med ild; du skal ikke attrå sølvet eller gullet som er på dem, eller ta det til dig, forat det ikke skal bli til en snare for dig; for det er en vederstyggelighet for Herren din Gud, 
5:7:26: og du skal ikke la nogen vederstyggelighet komme inn i ditt hus, så du blir slått med bann likesom det; du skal avsky det og akte det for en vederstyggelighet, for det er slått med bann. 
5:8:1: Alle de bud jeg gir dig idag, skal I akte vel på å holde, forat I må leve og bli tallrike og komme inn i det land Herren har tilsvoret eders fedre, og ta det i eie. 
5:8:2: Og du skal komme i hu hele den vei Herren din Gud har ført dig på i disse firti år i ørkenen for å ydmyke dig og prøve dig og for å kjenne hvad som var i ditt hjerte, om du vilde ta vare på hans bud eller ikke. 
5:8:3: Og han ydmyket dig og lot dig hungre, og han gav dig manna å ete, en mat som hverken du eller dine fedre kjente, fordi han vilde la dig vite at mennesket ikke lever av brød alene, men at mennesket lever av hvert ord som går ut av Herrens munn. 
5:8:4: Dine klær blev ikke utslitt på dig, og din fot blev ikke hoven i disse firti år. 
5:8:5: Så forstå da i ditt hjerte at likesom en mann optukter sin sønn, således optuktet Herren din Gud dig, 
5:8:6: og hold Herrens, din Guds bud, så du vandrer på hans veier og frykter ham! 
5:8:7: Når Herren din Gud fører dig inn i et godt land, et land med rinnende bekker, med kilder og dype vann, som veller frem i dalene og på fjellene, 
5:8:8: et land med hvete og bygg og vintrær og fikentrær og granatepletrær, et land med oljetrær og honning, 
5:8:9: et land hvor du ikke skal ete ditt brød i armod, hvor du intet skal mangle, et land hvor stenene er jern, og hvor du kan hugge ut kobber av fjellene, 
5:8:10: og når du så eter og blir mett og lover Herren din Gud for det gode land han har gitt dig, 
5:8:11: da vokt dig for å glemme Herren din Gud, så du ikke tar vare på hans bud og hans forskrifter og hans lover, som jeg pålegger dig idag, 
5:8:12: og vokt dig at du ikke, når du eter og blir mett og bygger gode hus og bor i dem, 
5:8:13: og ditt storfe og ditt småfe økes, og ditt sølv og ditt gull økes, og all din eiendom økes, 
5:8:14: at du da ikke ophøier dig i ditt hjerte, så du glemmer Herren din Gud, som førte dig ut av Egyptens land, av trælehuset, 
5:8:15: han som ledet dig i den store og forferdelige ørken blandt giftige slanger og skorpioner og på tørre ødemarker, hvor det ikke fantes vann, han som lot vann strømme ut for dig av den hårde klippe, 
5:8:16: han som i ørkenen gav dig manna å ete, en mat som dine fedre ikke kjente, for å ydmyke dig og prøve dig og så til sist gjøre vel imot dig. 
5:8:17: Si da ikke ved dig selv: Det er min kraft og min sterke hånd som har vunnet mig denne rikdom, 
5:8:18: men kom Herren din Gud i hu! For det er han som gir dig kraft til å vinne dig rikdom, fordi han vil holde sin pakt som han tilsvor dine fedre, således som det kan sees på denne dag. 
5:8:19: Men dersom du glemmer Herren din Gud og følger andre guder og dyrker dem og tilbeder dem, da vidner jeg mot eder idag at I visselig skal omkomme. 
5:8:20: Likesom de hedninger Herren lar omkomme for eder, således skal også I omkomme, fordi I ikke hører på Herrens, eders Guds røst. 
5:9:1: Hør, Israel! Du går nu over Jordan for å komme inn og legge under dig folk som er større og sterkere enn du, og som har store byer med murer som når til himmelen, 
5:9:2: et stort folk og høit av vekst, anakittenes barn, som du selv kjenner, og som du selv har hørt det ord om: Hvem kan stå sig mot Anaks barn? 
5:9:3: Så skal du da vite idag at Herren din Gud, han som går frem foran dig som en fortærende ild, han skal ødelegge dem, og han skal ydmyke dem for dig, så du skal drive dem bort og tilintetgjøre dem i hast, således som Herren har sagt til dig. 
5:9:4: Når nu Herren din Gud driver dem ut for dig, må du ikke tenke som så: Det er for min rettferdighets skyld Herren har ført mig inn i dette land og latt mig få det til eiendom. Nei, det er for disse hedningers ugudelighets skyld Herren driver dem ut for dig. 
5:9:5: Ikke for din rettferdighets skyld eller for ditt opriktige hjertes skyld kommer du inn i deres land og tar det i eie; men det er for deres ugudelighets skyld Herren din Gud driver disse hedninger ut for dig, og for å holde det ord Herren har svoret dine fedre Abraham, Isak og Jakob. 
5:9:6: Så skal du da vite at det ikke er for din rettferdighets skyld Herren din Gud gir dig dette gode land til eie; for du er et hårdnakket folk. 
5:9:7: Kom i hu og glem ikke hvorledes du vakte Herrens, din Guds vrede i ørkenen! Like fra den dag du gikk ut av Egyptens land, og til I kom til dette sted, har I vært gjenstridige mot Herren. 
5:9:8: Allerede ved Horeb vakte I Herrens vrede, og Herren harmedes på eder, så han vilde ha gjort ende på eder. 
5:9:9: Da jeg var gått op på fjellet for å ta imot stentavlene, tavlene med den pakt som Herren hadde gjort med eder, blev jeg på fjellet i firti dager og firti netter uten å ete brød og uten å drikke vann. 
5:9:10: Og Herren gav mig de to stentavler, skrevet med Guds finger, og på dem stod alle de ord Herren hadde talt med eder på fjellet midt ut av ilden den dag I var samlet der. 
5:9:11: Det var da de firti dager og firti netter var til ende at Herren gav mig de to stentavler, paktens tavler. 
5:9:12: Og Herren sa til mig: Skynd dig og stig ned herfra! Ditt folk, som du førte ut av Egypten, har fordervet sin vei; de er hastig veket av fra den vei jeg bød dem å vandre; de har gjort sig et støpt billede. 
5:9:13: Og Herren sa til mig: Jeg har lagt merke til dette folk og sett at det er et hårdnakket folk. 
5:9:14: La nu mig få råde, så vil jeg gjøre ende på dem og utslette deres navn under himmelen, og jeg vil gjøre dig til et sterkere og større folk enn dette. 
5:9:15: Da vendte jeg mig og gikk ned av fjellet, mens fjellet stod i brennende lue, og i mine to hender hadde jeg paktens to tavler. 
5:9:16: Og jeg fikk se at I hadde syndet mot Herren eders Gud og gjort eder en støpt kalv; I var hastig veket av fra den vei Herren hadde befalt eder å vandre. 
5:9:17: Så tok jeg og kastet fra mig begge tavlene som jeg hadde i mine hender, og slo dem i stykker for eders øine. 
5:9:18: Og jeg kastet mig ned for Herrens åsyn, likesom første gang, i firti dager og firti netter, uten å ete brød og uten å drikke vann - for alle eders synders skyld som I hadde forsyndet eder med ved å gjøre det som ondt var i Herrens øine, så I egget ham til vrede. 
5:9:19: For jeg fryktet for den vrede og harme som optendtes mot eder hos Herren, så han vilde gjøre ende på eder. Og Herren hørte mig denne gang og. 
5:9:20: Også på Aron var Herren så vred at han vilde ødelegge ham; men jeg bad også for Aron den gang. 
5:9:21: Men eders syndige verk, kalven som I hadde gjort, tok jeg og kastet på ilden og knuste og malte den vel, til den blev til fint støv, og støvet kastet jeg i bekken som flyter ned fra fjellet. 
5:9:22: Også i Tabera og i Massa og i Kibrot-Hatta'ava vakte I Herrens vrede. 
5:9:23: Og da Herren sendte eder fra Kades-Barnea og sa: Dra op og innta landet som jeg har gitt eder, da var I gjenstridige mot Herrens, eders Guds ord og trodde ikke på ham og hørte ikke på hans røst. 
5:9:24: Gjenstridige har I vært mot Herren så lenge jeg har kjent eder. 
5:9:25: Så kastet jeg mig da ned for Herrens åsyn i de firti dager og firti netter I vet, fordi Herren hadde sagt at han vilde gjøre ende på eder. 
5:9:26: Og jeg bad til Herren og sa: Herre, Herre, ødelegg ikke ditt folk og din arv, som du frelste med din store kraft, og som du førte ut av Egypten med sterk hånd! 
5:9:27: Kom i hu dine tjenere Abraham, Isak og Jakob! Se ikke på dette folks hårdhet og dets ugudelighet og dets synd, 
5:9:28: forat ikke de som bor i det land du har ført oss ut av, skal si: Fordi Herren ikke maktet å føre dem inn i det land han hadde tilsagt dem, og fordi han hatet dem, førte han dem ut i ørkenen og lot dem omkomme der. 
5:9:29: De er jo ditt folk og din arv, som du har ført ut med din store kraft og med din utrakte arm. 
5:10:1: På samme tid sa Herren til mig: Hugg dig ut to stentavler likesom de første, og stig op på fjellet til mig, og gjør dig også en ark av tre; 
5:10:2: så vil jeg skrive på tavlene de ord som stod på de første tavler, de som du slo i stykker, og du skal legge dem i arken. 
5:10:3: Da gjorde jeg en ark av akasietre og hugg ut to stentavler likesom de første, og jeg gikk op på fjellet med de to tavler i min hånd. 
5:10:4: Og han skrev på tavlene det samme som var skrevet første gang, de ti ord som Herren hadde talt til eder på fjellet midt ut av ilden den dag I var samlet der; og Herren gav mig dem. 
5:10:5: Så vendte jeg mig og gikk ned av fjellet og la tavlene i den ark jeg hadde gjort; og der blev de liggende, således som Herren hadde befalt mig. 
5:10:6: Så brøt Israels barn op fra Bene-Ja'akans brønner og kom til Mosera; der døde Aron, og der blev han begravet, og hans sønn Eleasar tjente som prest i hans sted. 
5:10:7: Derfra drog de til Gudgoda, og fra Gudgoda til Jotbata, et land med mange bekker. 
5:10:8: På den tid skilte Herren ut Levi stamme til å bære Herrens pakts-ark og til å stå for Herrens åsyn og tjene ham og velsigne i hans navn, som de har gjort til denne dag. 
5:10:9: Derfor fikk Levi ingen del eller arv med sine brødre; Herren er hans arv, således som Herren din Gud har sagt til ham. 
5:10:10: Så blev jeg da på fjellet likesom første gang i firti dager og firti netter; og Herren hørte mig også denne gang, Herren vilde ikke ødelegge dig. 
5:10:11: Og Herren sa til mig: Stå op og gå avsted og dra frem foran folket, forat de kan komme inn i det land jeg har svoret deres fedre å ville gi dem, og ta det i eie. 
5:10:12: Og nu, Israel, hvad krever Herren din Gud av dig uten at du skal frykte Herren din Gud og vandre på alle hans veier og elske ham og tjene Herren din Gud av alt ditt hjerte og av all din sjel, 
5:10:13: så du tar vare på Herrens bud og hans lover, som jeg byder dig idag, forat det kan gå dig vel? 
5:10:14: Se, Herren din Gud hører himlene til og himlenes himler, jorden og alt det som er på den. 
5:10:15: Men bare i dine fedre fant Herren behag, så han elsket dem; og han utvalgte eder, deres efterkommere, fremfor alle andre folk, således som det kan sees på denne dag. 
5:10:16: Så omskjær da eders hjertes forhud, og vær ikke mere så hårdnakkede! 
5:10:17: For Herren eders Gud han er gudenes Gud og herrenes Herre, den store, den mektige og den forferdelige Gud, som ikke gjør forskjell på folk og ikke tar imot gaver, 
5:10:18: som hjelper den farløse og enken til deres rett, og som elsker den fremmede, så han gir ham føde og klær. 
5:10:19: Derfor skal også I elske den fremmede; I har selv vært fremmede i Egyptens land. 
5:10:20: Herren din Gud skal du frykte, ham skal du tjene, og ham skal du holde fast ved, og ved hans navn skal du sverge. 
5:10:21: Han er din ros, og han er din Gud, som har gjort disse store og forferdelige ting for dig som dine øine har sett. 
5:10:22: Sytti i tallet drog dine fedre ned til Egypten; men nu har Herren din Gud gjort dig tallrik som himmelens stjerner. 
5:11:1: Så skal du da elske Herren din Gud og ta vare på det han vil ha varetatt, hans lover og hans forskrifter og hans bud, alle dager. 
5:11:2: Og I skal idag - for det er ikke eders barn jeg taler med, de som ikke kjenner det og ikke har sett det - komme I hu Herrens, eders Guds optuktelse, hans storhet, hans sterke hånd og hans utrakte arm 
5:11:3: og hans tegn og gjerninger, som han gjorde i Egypten med Farao, kongen i Egypten, og med hele hans land, 
5:11:4: og hvad han gjorde med egypternes hær, med deres hester og vogner, da han lot det Røde Havs vann strømme sammen over dem mens de forfulgte eder, og lot dem gå til grunne, så ingen har sett dem mere til denne dag, 
5:11:5: og hvad han gjorde med eder i ørkenen inntil I kom til dette sted, 
5:11:6: og hvad han gjorde med Datan og Abiram, sønnene til Eliab, Rubens sønn, da jorden lukket op sin munn og midt iblandt hele Israel slukte dem og deres hus og deres telter og hvert liv som var i følge med dem; 
5:11:7: for I har selv med egne øine sett alle de storverk Herren har gjort. 
5:11:8: Ta da vare på alle de bud jeg gir dig idag, forat I kan bli sterke og komme inn og eie det land I nu drar over til og skal ta i eie, 
5:11:9: og forat I kan få leve lenge i det land Herren tilsvor eders fedre å ville gi dem og deres ætt, et land som flyter med melk og honning. 
5:11:10: For det land du kommer inn i og skal ta i eie, er ikke som Egyptens land, som I drog ut fra, hvor du sådde din sæd og så vannet jorden med din fot som en kålhave. 
5:11:11: Men det land I drar over til og skal ta i eie, er et land med fjell og daler, som drikker vann av himmelens regn, 
5:11:12: et land som Herren din Gud bærer omsorg for; alltid hviler Herrens, din Guds øine på det, fra årets begynnelse til dets ende. 
5:11:13: Dersom I nu er lydige mot mine bud, som jeg gir eder idag, så I elsker Herren eders Gud og tjener ham av alt eders hjerte og av all eders sjel, 
5:11:14: da vil jeg gi eders land regn i rette tid, høstregn og vårregn, og du skal samle i hus ditt korn og din most og din olje, 
5:11:15: og for ditt fe vil jeg gi dig gress på dine marker, og du skal ete og bli mett. 
5:11:16: Ta eder i vare at eders hjerte ikke dåres, så I viker av og dyrker andre guder og tilbeder dem, 
5:11:17: og Herrens vrede optendes mot eder, og han lukker himmelen til, så det ikke kommer regn, og jorden ikke gir sin grøde, og I hastig blir utryddet av det gode land Herren gir eder. 
5:11:18: Så legg eder da disse mine ord på hjerte og på sinne og bind dem som et tegn på eders hånd og la dem være som en minneseddel på eders panne 
5:11:19: og lær eders barn dem ved å tale om dem når du sitter i ditt hus, og når du går på veien, og når du legger dig, og når du står op, 
5:11:20: og skriv dem på dørstolpene i ditt hus og på dine porter, 
5:11:21: forat eders og eders barns dager må bli mange i det land som Herren tilsvor eders fedre å ville gi dem, og I må leve der like så lenge som himmelen hvelver sig over jorden. 
5:11:22: For tar I vare på alle disse bud som jeg byder eder å holde, så I elsker Herren eders Gud og vandrer på alle hans veier og holder fast ved ham, 
5:11:23: da skal Herren drive ut alle disse folk for eder, og I skal jage bort folk som er større og tallrikere enn I selv. 
5:11:24: Hvert sted I treder på med eders fot, skal høre eder til; fra ørkenen og Libanon, fra den store elv, elven Frat, og til havet i vest skal eders landemerker nå. 
5:11:25: Ingen skal kunne stå sig imot eder; redsel for eder og frykt for eder skal Herren eders Gud la komme over hele det land I treder på, således som han har sagt til eder. 
5:11:26: Se, jeg legger idag frem for eder velsignelse og forbannelse: 
5:11:27: velsignelsen, så sant I lyder Herrens, eders Guds bud, som jeg gir eder idag, 
5:11:28: og forbannelsen, dersom I ikke lyder Herrens, eders Guds bud, men viker av fra den vei jeg idag byder eder å vandre på, og følger andre guder, som I ikke kjenner. 
5:11:29: Og når Herren din Gud fører dig inn i det land du nu drar inn i og skal ta i eie, da skal du lyse velsignelsen på fjellet Garisim og forbannelsen på fjellet Ebal. 
5:11:30: Disse to fjell ligger, som i vet, på hin side Jordan bortenfor veien som bærer mot vest, i kana'anittenes land, de som bor i ødemarken midt imot Gilgal, ved Mores terebintelund. 
5:11:31: For nu går I over Jordan for å komme inn og ta det land i eie som Herren eders Gud gir eder, og I skal ta det i eie og bo der. 
5:11:32: Akt da vel på at I holder alle de bud og lover som jeg idag legger frem for eder! 
5:12:1: Dette er de bud og de lover I skal akte på å leve efter i det land som Herren, dine fedres Gud, har gitt dig til eie, alle de dager I lever på jorden: 
5:12:2: I skal ødelegge alle de steder hvor hedningene som I skal drive bort, har dyrket sine guder, på de høie fjell og på haugene og under hvert grønt tre; 
5:12:3: I skal rive ned deres altere og slå deres billedstøtter sønder, og deres Astarte-billeder skal I brenne op med ild, og de utskårne billeder av deres guder skal I hugge i stykker og utslette deres navn fra det sted hvor de stod. 
5:12:4: I skal ikke gjøre som de når I dyrker Herren eders Gud, 
5:12:5: men til det sted Herren eders Gud utvelger av alle eders stammer for å la sitt navn bo der, dit skal I søke, og dit skal du komme. 
5:12:6: Og dit skal I føre eders brennoffer og eders slaktoffer og eders tiender og eders henders gaver og eders lovede offer og eders frivillige offer og det førstefødte av eders storfe og av eders småfe, 
5:12:7: og der skal I ete for Herrens, eders Guds åsyn og glede eder med eders husfolk over alt det I har vunnet ved eders arbeid, alt det Herren din Gud har velsignet dig med. 
5:12:8: I skal ikke gjøre således som vi gjør her idag, enhver det som tykkes ham å være rett; 
5:12:9: for I er ennu ikke kommet inn til den hvile og den arv Herren din Gud gir dig. 
5:12:10: Men når I er gått over Jordan og bor i det land Herren eders Gud gir eder til arv, og han har gitt eder ro for alle eders fiender rundt om, så I bor trygt, 
5:12:11: da skal det sted som Herren eders Gud utvelger for å la sitt navn bo der, være det eneste sted hvorhen I skal føre alt det jeg byder eder: eders brennoffere og eders slaktoffere, eders tiender og eders henders gaver og alle eders utvalgte offer som I lover Herren. 
5:12:12: Og I skal være glade for Herrens, eders Guds åsyn, I og eders sønner og eders døtre og eders tjenere og eders tjenestepiker og levitten som bor hos eder; for han har ingen del eller arv med eder. 
5:12:13: Vogt dig at du ikke ofrer dine brennoffer på noget sted som du selv utser dig! 
5:12:14: Men på det sted som Herren utvelger i en av dine stammer, der skal du ofre dine brennoffer, og der skal du gjøre alt det jeg byder dig. 
5:12:15: Men ellers kan du efter ditt hjertes lyst slakte og ete kjøtt rundt om i alle dine byer, efter som Herren din Gud gir dig sin velsignelse; den urene så vel som den rene kan ete av det som om det var et rådyr eller en hjort. 
5:12:16: Men blodet må I ikke ete; I skal helle det ut på jorden likesom vann. 
5:12:17: Hjemme i dine byer må du ikke ete tienden av ditt korn eller av din most eller av din olje, heller ikke det førstefødte av ditt storfe eller av ditt småfe, heller ikke noget av dine lovede offer eller dine frivillige offer eller din hånds gaver. 
5:12:18: Men for Herrens, din Guds åsyn skal du ete det på det sted Herren din Gud utvelger, du og din sønn og din datter og din tjener og din tjenestepike og levitten som bor hos dig; og du skal glede dig for Herrens, din Guds åsyn over alt det du har vunnet ved ditt arbeid. 
5:12:19: Vokt dig at du ikke glemmer levitten, så lenge du lever i ditt land! 
5:12:20: Når Herren din Gud utvider dine landemerker, således som han har tilsagt dig, og du sier: Jeg vilde gjerne ete kjøtt, fordi du har lyst til å ete kjøtt, da kan du ete kjøtt efter ditt hjertes lyst. 
5:12:21: Hvis du har lang vei til det sted Herren din Gud utvelger for å la sitt navn bo der, da kan du gjøre som jeg har befalt dig, og slakte av ditt storfe og av ditt småfe, som Herren har gitt dig, og du kan ete det hjemme i dine byer efter ditt hjertes lyst. 
5:12:22: Men du skal ete det som en eter et rådyr eller en hjort; både den urene og den rene kan ete det. 
5:12:23: Hold bare fast ved at du ikke eter blodet! For blodet er sjelen, og du skal ikke ete sjelen sammen med kjøttet. 
5:12:24: Du skal ikke ete det; du skal helle det ut på jorden likesom vann. 
5:12:25: Et det ikke, så skal det gå dig vel og dine barn efter dig, når du gjør det som er rett i Herrens øine. 
5:12:26: Men de hellige gaver som du vil gi, og dine lovede offer skal du ta og føre med dig til det sted som Herren utvelger. 
5:12:27: Og du skal ofre dine brennoffer, både kjøttet og blodet, på Herrens, din Guds alter; og av dine slaktoffer* skal blodet helles ut på Herrens, din Guds alter, men kjøttet kan du ete. # <* d.e. takkoffer.> 
5:12:28: Akt vel på å lyde alle disse bud som jeg gir dig, forat det må gå dig vel og dine barn efter dig til evig tid, når du gjør det som godt og rett er i Herrens, din Guds øine. 
5:12:29: Når Herren din Gud har utryddet folkene for dig der hvor du nu kommer for å drive dem bort, og du har drevet dem bort og bor i deres land, 
5:12:30: da vokt dig at du ikke lar dig dåre og følger i deres spor, efterat de er utryddet for dig, og at du ikke spør efter deres guder og sier: Hvorledes tjente disse folk sine guder? Således vil også jeg gjøre. 
5:12:31: Du skal ikke gjøre som de når du dyrker Herren din Gud; for alt det som er en vederstyggelighet for Herren, og som han hater, det gjør de for sine guder; endog sine sønner og sine døtre brenner de i ilden til ære for sine guder. 
5:12:32: Alt det jeg byder eder, skal I akte vel på å gjøre; du skal ikke legge noget til og ikke ta noget fra. 
5:13:1: Når det står frem en profet i din midte, eller en som har drømmer, og han varsler dig et tegn eller et under, 
5:13:2: og det virkelig skjer det tegn eller under som han talte til dig om, idet han sa: La oss følge andre guder - sådanne som du ikke kjenner - og la oss dyrke dem, 
5:13:3: da skal du ikke høre på denne profets ord eller på ham som hadde drømmen; for Herren eders Gud vil bare prøve eder for å kjenne om I elsker Herren eders Gud av alt eders hjerte og av all eders sjel. 
5:13:4: Herren eders Gud skal I følge, og ham skal I frykte; på hans bud skal I ta vare, og på hans røst skal I høre; ham skal I tjene, og ham skal I holde fast ved. 
5:13:5: Men profeten eller han som hadde drømmen, skal late livet, fordi han tilskyndte til frafall fra Herren eders Gud, som førte eder ut av Egyptens land og fridde dig ut fra trælehuset, og fordi han vilde føre dig bort fra den vei som Herren din Gud har befalt dig å vandre; således skal du rydde det onde bort av din midte. 
5:13:6: Om din bror, din mors sønn, eller din sønn eller din datter eller din hustru i din favn eller din venn, som du har så kjær som din egen sjel - om nogen av disse lokker dig i lønndom og sier: La oss gå bort og dyrke andre guder - sådanne som du og dine fedre ikke har kjent, 
5:13:7: av de folks guder som bor rundt omkring eder, enten nær ved dig eller langt fra dig, fra jordens ene ende til den andre - 
5:13:8: da skal du ikke samtykke og ikke høre på ham; du skal ikke spare ham og ikke ynkes over ham og ikke skjule ham, 
5:13:9: men du skal slå ham ihjel; du skal selv være den første som løfter hånden mot ham for å avlive ham, og siden skal hele folket gjøre det samme. 
5:13:10: Du skal stene ham, så han dør, fordi han søkte å føre dig bort fra Herren din Gud, som førte dig ut av Egyptens land, av trælehuset, 
5:13:11: og hele Israel skal høre det og frykte, så det ikke mere blir gjort så ond en gjerning mellem eder. 
5:13:12: Hører du si om nogen av de byer som Herren din Gud gir dig til å bo i: 
5:13:13: Det er stått frem ugudelige menn av din midte, og de har forført innbyggerne i sin by og sagt: La oss gå bort og dyrke andre guder - sådanne som I ikke kjenner - 
5:13:14: da skal du granske og ransake og spørre nøie efter, og er det da sannhet, er det sikkert og visst at denne vederstyggelighet er gjort i din midte, 
5:13:15: da skal du slå innbyggerne i denne by ihjel med sverdets egg; du skal bannlyse den og alt det som i den er; også feet der skal du slå med sverdets egg. 
5:13:16: Alt byttet du tar der, skal du samle midt på torvet, og du skal brenne op både byen og alt byttet du har tatt, med ild som et heloffer for Herren din Gud, og den skal være en grushaug for alle tider, den skal ikke bygges op mere. 
5:13:17: Ikke det minste av det bannlyste skal bli hengende ved din hånd, forat Herren må la sin brennende vrede fare og være dig nådig og miskunne sig over dig og gjøre dig tallrik, som han har svoret dine fedre, 
5:13:18: når du hører på Herrens, din Guds røst, så du tar vare på alle hans bud, som jeg gir dig idag, og gjør det som er rett i Herrens, din Guds øine. 
5:14:1: I er Herrens, eders Guds barn; I skal ikke skjære eder i eders kjøtt og ikke rake eder skallet over pannen av sorg over en avdød; 
5:14:2: for et hellig folk er du for Herren din Gud, og dig har Herren utvalgt av alle de folk som er på jorden, til å være ham et eiendomsfolk. 
5:14:3: Du skal ikke ete noget som er en vederstyggelighet. 
5:14:4: Dette er de firføtte dyr som I kan ete: oksen, fåret og gjeten, 
5:14:5: hjorten og rådyret og dådyret og stenbukken og fjellgjeten og villoksen og villgjeten. 
5:14:6: Alle de firføtte dyr som har klover og har dem tvekløvd helt igjennem, og som tygger drøv, dem kan I ete. 
5:14:7: Det er bare disse I ikke skal ete blandt dem som tygger drøv, og blandt dem som har klover som er kløvd helt igjennem: kamelen og haren og fjellgrevlingen, for de tygger vel drøv, men har ikke klover, de skal være urene for eder, 
5:14:8: og svinet, for det har vel klover, men tygger ikke drøv, det skal være urent for eder. Av disse dyrs kjøtt skal I ikke ete, og ved deres døde kropper skal I ikke røre. 
5:14:9: Dette kan I ete av alt det som er i vannet: Alt det som har finner og skjell, kan I ete; 
5:14:10: men alt det som ikke har finner og skjell, skal I ikke ete, det skal være urent for eder. 
5:14:11: Alle rene fugler kan I ete; 
5:14:12: men disse fugler skal I ikke ete: landørnen og havørnen og fiskeørnen 
5:14:13: og kråken og falken, og glenten efter sine arter, 
5:14:14: og alle ravnene efter sine arter, 
5:14:15: og strutsen og gjøken og måken, og høken efter sine arter, 
5:14:16: kattuglen og hubroen og nattravnen 
5:14:17: og pelikanen og gribben og dykkeren 
5:14:18: og storken, og heiren efter sine arter, og hærfuglen og flaggermusen. 
5:14:19: Alt kryp som har vinger, skal være urent for eder, det skal I ikke ete. 
5:14:20: Men alt flyvende som er rent, kan I ete. 
5:14:21: I skal ikke ete noget selvdødt dyr; du kan gi det til den fremmede som bor hos dig, forat han kan ete det, eller du kan selge det til en utlending; for et hellig folk er du for Herren din Gud. Du skal ikke koke et kje i dets mors melk. 
5:14:22: Du skal gi tiende av all din avgrøde, av sæden som vokser på marken år for år. 
5:14:23: Og for Herrens, din Guds åsyn, på det sted han utvelger til å la sitt navn bo der, skal du ete tienden av ditt korn og din most og din olje og det førstefødte av ditt storfe og av ditt småfe, forat du kan lære å frykte Herren din Gud alle dager. 
5:14:24: Men hvis veien er dig for lang, så du ikke makter å føre det dit, fordi det sted Herren din Gud utvelger til å la sitt navn bo der, er for langt borte fra dig, og har Herren din Gud velsignet dig rikelig, 
5:14:25: så kan du selge det for penger, og pengene skal du knytte inn i et plagg og ta dem med dig til det sted som Herren din Gud utvelger. 
5:14:26: For disse penger skal du så kjøpe alt hvad du har lyst til, storfe og småfe og vin og sterk drikk - alt det ditt hjerte attrår - og du skal ete det der for Herrens, din Guds åsyn og være glad, du og ditt hus. 
5:14:27: Og levitten som bor hos dig, må du ikke glemme; for han har ingen del eller arv med dig. 
5:14:28: Hvert tredje år skal du legge til side hele tienden av din avling i det år og opsamle den i dine byer, 
5:14:29: og så skal levitten komme - for han har ingen del eller arv med dig - og den fremmede og den farløse og enken som bor hos dig, og de skal ete og bli mette, forat Herren din Gud må velsigne dig i alt det arbeid du tar dig fore. 
5:15:1: Hvert syvende år skal du la være et eftergivelses-år. 
5:15:2: Og således skal det være med eftergivelsen: Hver den som har til gode noget han har lånt sin næste, skal eftergi ham det; han skal ikke kreve sin næste og sin bror, fordi der er lyst eftergivelse til Herrens ære. 
5:15:3: Utlendingen kan du kreve, men det du har til gode hos din bror, skal du eftergi. 
5:15:4: Rettelig skulde det nu ikke finnes nogen fattig hos dig; for Herren din Gud skal velsigne dig i det land som Herren din Gud gir dig til arv og eie, 
5:15:5: bare du er lydig mot Herrens, din Guds røst, så du akter vel på å holde alle disse bud som jeg gir dig idag; 
5:15:6: for Herren din Gud vil da visselig velsigne dig, således som han har tilsagt dig, og du skal låne til mange folk, men selv skal du ikke trenge til å låne av nogen, og du skal herske over mange folk, men de skal ikke herske over dig. 
5:15:7: Når det er en fattig hos dig, blandt dine brødre i nogen av byene i det land som Herren din Gud gir dig, da skal du ikke være hårdhjertet og lukke din hånd for din fattige bror; 
5:15:8: men du skal lukke op din hånd for ham og låne ham det han mangler og trenger til. 
5:15:9: Vokt dig at det ikke kommer en ond tanke op i ditt hjerte, så du sier: Nu er det snart det syvende år, eftergivelses-året, og du ser med onde øine på din fattige bror og ikke gir ham noget! For da kommer han til klage over dig til Herren, og du fører synd over dig. 
5:15:10: Du skal gjerne gi ham, og det skal ikke gjøre ditt hjerte ondt når du gir ham; for da skal Herren din Gud velsigne dig i alt ditt arbeid og i alt det du tar dig fore. 
5:15:11: For fattige kommer det alltid til å være i landet; derfor byder jeg dig og sier: Du skal lukke op din hånd for din bror, for de trengende og fattige som du har i ditt land. 
5:15:12: Når nogen av ditt folk, en hebraisk mann eller kvinne, blir solgt* til dig, da skal han tjene dig i seks år; men i det syvende år skal du gi ham fri, så han kan gå hvor han vil. # <* selger sig selv eller selges av andre.> 
5:15:13: Og når du gir ham fri, skal du ikke la ham gå tomhendt fra dig. 
5:15:14: Du skal sørge vel for ham med gaver av ditt småfe og fra din treskeplass og fra din vinperse; av det som Herren din Gud har velsignet dig med, skal du gi ham. 
5:15:15: Du skal komme i hu at du selv har vært træl i Egyptens land, og at Herren din Gud fridde dig ut; derfor byder jeg dig dette idag. 
5:15:16: Men sier han til dig: Jeg vil ikke gå bort fra dig, fordi han har det godt hos dig og holder av dig og dine, 
5:15:17: da skal du ta en syl og stikke den gjennem hans øre inn i døren, så skal han være din tjener for all tid; det samme skal du gjøre med din tjenestepike. 
5:15:18: La det ikke falle dig tungt at du gir ham fri og sender ham fra dig! For i seks år har han optjent for dig dobbelt så meget som du måtte ha gitt en dagarbeider i lønn, og Herren din Gud skal velsigne dig i alt det du gjør. 
5:15:19: Alt førstefødt av hankjønn som fødes blandt ditt storfe og ditt småfe, skal du hellige Herren din Gud; du skal ikke bruke det førstefødte av ditt storfe til arbeid, og du skal ikke klippe det førstefødte av ditt småfe. 
5:15:20: For Herrens, din Guds åsyn skal du og ditt hus ete det hvert år på det sted Herren utvelger. 
5:15:21: Men er det noget lyte på det, er det halt eller blindt eller har noget annet ondt lyte, da skal du ikke ofre det til Herren din Gud. 
5:15:22: Hjemme i dine byer kan du ete det; både den urene og den rene kan ete det, som om det var et rådyr eller en hjort. 
5:15:23: Men dets blod skal du ikke ete; du skal helle det ut på jorden likesom vann. 
5:16:1: Akt vel på måneden abib, så du holder påske for Herren din Gud! For i måneden abib førte Herren din Gud dig ut av Egypten ved nattetid. 
5:16:2: Og du skal slakte påskeoffer for Herren din Gud, småfe og storfe, på det sted Herren utvelger for å la sitt navn bo der. 
5:16:3: Du skal ikke ete syret brød til det; i syv dager skal du ete usyret brød til det, trengsels brød - for i hast drog du ut av Egyptens land - forat du alle ditt livs dager skal komme i hu den dag du gikk ut av Egyptens land. 
5:16:4: I syv dager skal det ikke finnes surdeig hos dig i hele ditt land, og av det kjøtt du slakter om aftenen den første dag, skal intet ligge natten over til om morgenen. 
5:16:5: Du må ikke slakte påskeofferet i nogen av de byer som Herren din Gud gir dig; 
5:16:6: men på det sted Herren din Gud utvelger for å la sitt navn bo der, skal du slakte påskeofferet om aftenen, ved solens nedgang, på samme tid som da du drog ut av Egypten. 
5:16:7: Og du skal steke og ete det på det sted Herren din Gud utvelger; og om morgenen kan du vende tilbake og gå til dine telt. 
5:16:8: Seks dager skal du ete usyret brød; men på den syvende dag skal det være festsammenkomst for Herren din Gud; da skal du intet arbeid gjøre. 
5:16:9: Så skal du telle syv uker: Fra den tid du begynner å skjære kornet med sigden, skal du telle syv uker frem. 
5:16:10: Og så skal du holde ukenes høitid for Herren din Gud og bære frem så meget som du frivillig vil gi, alt efter som Herren din Gud velsigner dig. 
5:16:11: Og du skal være glad for Herrens, din Guds åsyn på det sted Herren din Gud utvelger for å la sitt navn bo der, både du og din sønn og din datter og din tjener og din tjenestepike og levitten som bor hos dig, og den fremmede og den farløse og enken som du har hos dig. 
5:16:12: Og du skal komme i hu at du var træl i Egypten, og du skal ta vare på disse forskrifter og holde dem. 
5:16:13: Løvsalenes høitid skal du holde i syv dager, når du har samlet inn fra din låve og fra din vinperse. 
5:16:14: Og du skal være glad på din høitid, du og din sønn og din datter og din tjener og din tjenestepike og levitten og den fremmede og den farløse og enken som bor i dine byer. 
5:16:15: Syv dager skal du holde høitid for Herren din Gud på det sted Herren utvelger; for Herren din Gud skal velsigne dig i all din avling og i alt det du tar dig fore, så du skal være full av glede. 
5:16:16: Tre ganger om året skal alle menn blandt eder vise sig for Herrens, din Guds åsyn på det sted han utvelger: på de usyrede brøds høitid og på ukenes høitid og på løvsalenes høitid; og ingen skal vise sig for Herrens åsyn tomhendt, 
5:16:17: men enhver med den gave han har råd til, efter den velsignelse som Herren din Gud har gitt dig. 
5:16:18: Dommere og tilsynsmenn skal du innsette for dig i alle de byer som Herren din Gud gir dig, for hver av dine stammer, og de skal dømme folket efter lov og rett. 
5:16:19: Du skal ikke bøie retten, du skal ikke gjøre forskjell på folk og ikke ta imot gaver; for gaven blinder vismenns øine og forvender de rettferdiges sak. 
5:16:20: Rettferdighet, rettferdighet skal du strebe efter, forat du må leve og eie det land Herren din Gud gir dig. 
5:16:21: Du skal ikke sette et Astarte-billede av noget slags tre ved siden av Herrens, din Guds alter, det som du skal bygge dig, 
5:16:22: og du skal ikke reise nogen billedstøtte, for det hater Herren din Gud. 
5:17:1: Du skal ikke ofre Herren din Gud et stykke storfe eller småfe som har noget lyte eller nogen skade; for det er en vederstyggelighet for Herren din Gud. 
5:17:2: Dersom der i din midte, i en av de byer som Herren din Gud gir dig, finnes en mann eller kvinne som gjør det som ondt er i Herrens, din Guds øine, så han bryter hans pakt, 
5:17:3: og han mot mitt bud går bort og dyrker andre guder og tilbeder dem eller solen eller månen eller hele himmelens hær, 
5:17:4: og dette blir meldt dig, og du får høre om det, da skal du nøie granske saken; og er det da sannhet, er det sikkert og visst at denne vederstyggelighet er gjort i Israel, 
5:17:5: da skal du føre den mann eller den kvinne som har gjort denne onde gjerning, ut til byens port, og enten det er mann eller kvinne, skal du stene dem, så de dør. 
5:17:6: Efter to eller tre vidners utsagn skal den late livet som skal dø; han skal ikke late livet bare efter ett vidnes utsagn. 
5:17:7: Vidnene skal først løfte hånden for å avlive ham, og dernæst hele folket; således skal du rydde det onde bort av din midte. 
5:17:8: Når en sak er dig for vanskelig å dømme i, enten det gjelder en drapssak eller en rettstrette eller et søksmål for skade på legemet eller i det hele nogen sak som der tvistes om i dine byer, da skal du gjøre dig rede og dra op til det sted Herren din Gud utvelger. 
5:17:9: Og du skal gå til de levittiske prester og til den som er dommer på den tid, og spørre dig for hos dem, og de skal si dig hvad rett er. 
5:17:10: Og du skal gjøre efter det de sier dig på det sted Herren utvelger; og du skal i alle stykker akte på å gjøre som de lærer dig. 
5:17:11: Efter den lov de lærer dig, og efter den dom de forkynner dig, skal du gjøre; du skal ikke vike fra det de sier dig, hverken til høire eller til venstre. 
5:17:12: Men den mann som er så overmodig at han ikke hører på presten, som står der for å tjene Herren din Gud, eller på dommeren, den mann skal dø; således skal du rydde det onde bort av Israel, 
5:17:13: og alt folket skal høre det og frykte og ikke mere bære sig overmodig at. 
5:17:14: Når du kommer inn i det land Herren din Gud gir dig, og du eier det og bor i det, og du så sier: Jeg vil sette en konge over mig, likesom alle de folk som bor rundt omkring mig, 
5:17:15: da skal du sette den til konge over dig som Herren din Gud utvelger. En av dine brødre skal du sette til konge over dig, du må ikke sette over dig en fremmed mann, en som ikke er din bror. 
5:17:16: Men han skal ikke holde mange hester og ikke sende folket tilbake til Egypten for å hente mange hester; for Herren har sagt til eder: I skal nu ikke mere vende tilbake den vei. 
5:17:17: Han skal heller ikke ta sig mange hustruer, forat hans hjerte ikke skal komme på avveie; heller ikke skal han samle sig meget sølv og gull. 
5:17:18: Når han så sitter på sin kongetrone, da skal han få denne lov hos de levittiske prester og la den skrive av for sig i en bok. 
5:17:19: Og den skal han ha hos sig og lese i den alle sitt livs dager, forat han kan lære å frykte Herren sin Gud, så han tar vare på alle denne lovs ord og disse forskrifter og holder dem, 
5:17:20: forat han ikke skal ophøie sig over sine brødre og ikke vike av fra budet, hverken til høire eller til venstre. Da skal han leve lenge som konge i Israel, både han og hans sønner. 
5:18:1: De levittiske prester, hele Levi stamme, skal ikke ha nogen del eller arv med Israel; av Herrens ildoffer og av hans arv skal de leve, 
5:18:2: men nogen arv skal de ikke ha blandt sine brødre; Herren er deres arv, således som han har sagt til dem. 
5:18:3: Og dette skal være prestenes rettighet av folket - av dem som ofrer et slaktoffer, enten av storfeet eller av småfeet: De skal gi presten bogen og kjevene og vommen. 
5:18:4: Førstegrøden av ditt korn, av din most og av din olje og den første ull av dine får skal du gi ham. 
5:18:5: For ham har Herren din Gud utvalgt av alle dine stammer, forat han skal stå og tjene i Herrens navn, han og hans sønner, alle dager. 
5:18:6: Når en levitt kommer fra en av dine byer rundt om i Israel, hvor han opholder sig, til det sted Herren utvelger - og komme kan han om han har lyst til det - 
5:18:7: og han så gjør tjeneste i Herrens, sin Guds navn, likesom alle hans brødre, de andre levitter som står der for Herrens åsyn, 
5:18:8: så skal de alle nyte like del av gavene, bortsett fra hvad han kan ha fått ved salg av sine fedrene eiendeler. 
5:18:9: Når du kommer til det land Herren din Gud gir dig, da skal du ikke ta efter disse folks vederstyggelige skikker. 
5:18:10: Det skal ikke finnes hos dig nogen som lar sin sønn eller datter gå gjennem ilden, eller som gir sig av med spådomskunster, eller som spår av skyene eller tyder varsler eller er en trollmann, 
5:18:11: ingen heksemester, ingen som spør en dødningemaner, ingen sannsiger, ingen som gjør spørsmål til de døde. 
5:18:12: For enhver som gjør slikt, er en vederstyggelighet for Herren, og for disse vederstyggelige tings skyld er det Herren din Gud driver dem bort for dig. 
5:18:13: Ustraffelig skal du være for Herren din Gud. 
5:18:14: For disse folk som du skal drive ut, de hører på dem som spår av skyene, og på sannsigere; men dig har Herren din Gud ikke tillatt slikt. 
5:18:15: En profet av din midte, av dine brødre, likesom mig, skal Herren din Gud opreise dig; på ham skal I høre - 
5:18:16: aldeles som du bad Herren din Gud om ved Horeb den dag I var samlet der og du sa: Jeg kan ikke lenger høre på Herrens, min Guds røst, og denne store ild kan jeg ikke lenger se på, for da må jeg dø. 
5:18:17: Da sa Herren til mig: De har talt vel i det de har sagt. 
5:18:18: En profet vil jeg opreise for dem av deres brødres midte, likesom dig, og jeg vil legge mine ord i hans munn, og han skal tale til dem alt det jeg byder ham. 
5:18:19: Og enhver som ikke hører på mine ord, som han skal tale i mitt navn, ham vil jeg kreve til regnskap for det. 
5:18:20: Men den profet som i overmot drister sig til å tale noget i mitt navn som jeg ikke har befalt ham å tale, eller som taler i andre guders navn, den profet skal dø. 
5:18:21: Og om du sier ved dig selv: Hvad skal vi kjenne det ord på som Herren ikke har talt? - 
5:18:22: (da skal du vite:) Når det ord profeten taler i Herrens navn, ikke skjer og ikke går i opfyllelse, da er det et ord som Herren ikke har talt; det er et ord som profeten i overmot har dristet sig til å tale, og du skal ikke være redd ham. 
5:19:1: Når Herren din Gud utrydder de folk hvis land Herren din Gud gir dig, og du driver dem ut og bor i deres byer og i deres huser, 
5:19:2: så skal du skille ut tre byer midt i det land som Herren din Gud gir dig til eie. 
5:19:3: Du skal gjøre veien til dem i stand og skifte hele det land som Herren din Gud gir dig til arv, i tre deler, forat enhver manndraper kan fly dit. 
5:19:4: Og således skal det være med den manndraper som skal kunne fly dit og berge livet: Når nogen dreper sin næste av vanvare og uten å ha hatet ham i forveien, 
5:19:5: som når en går med sin næste ut i skogen for å hugge tømmer og han hugger til med øksen for å felle et tre, men øksen farer av skaftet og treffer hans næste, så han dør, da kan han fly til en av disse byer og berge livet. 
5:19:6: For blodhevneren kunde ellers i sin brennende vrede forfølge manndraperen og, hvis veien var for lang, nå ham igjen og slå ham ihjel, skjønt han ikke var skyldig til døden, fordi han ikke hadde båret hat til ham i forveien. 
5:19:7: Derfor byder jeg dig og sier: Du skal skille ut tre byer. 
5:19:8: Og når Herren din Gud utvider dine landemerker, således som han har tilsvoret dine fedre, og han gir dig hele det land han har lovt å gi dine fedre, 
5:19:9: såfremt du akter vel på å holde alle disse bud som jeg gir dig idag, så du elsker Herren din Gud og vandrer på hans veier alle dager - da skal du legge ennu tre byer til disse tre, 
5:19:10: forat der ikke skal utøses uskyldig blod i det land som Herren din Gud gir dig til arv, så det kommer blodskyld over dig. 
5:19:11: Men om en mann hater sin næste og lurer på ham og faller over ham og slår ham, så han dør, og han så flyr til en av disse byer, 
5:19:12: da skal de eldste i hans by sende bud og hente ham derfra og overgi ham i blodhevnerens hånd, og han skal dø. 
5:19:13: Du skal ikke spare ham, men du skal rense Israel for uskyldig blod, forat det må gå dig vel. 
5:19:14: Du skal ikke flytte merkestenene mellem dig og din næste, de som de gamle har satt på den jord du skal få i arv i det land Herren din Gud gir dig til eie. 
5:19:15: Det skal ikke stå bare ett vidne frem mot en mann, når det gjelder nogen misgjerning eller nogen synd, hvad synd det så er han har gjort; efter to vidners utsagn eller efter tre vidners utsagn skal en sak stå fast. 
5:19:16: Når et ondsindet vidne står frem mot nogen for å vidne mot ham om en forbrytelse, 
5:19:17: da skal begge de menn som har sak mot hverandre, trede frem for Herrens åsyn, for prestene og dommerne som er i de dager, 
5:19:18: og dommerne skal nøie granske saken; er da vidnet et falskt vidne, har han vidnet falsk mot sin bror, 
5:19:19: da skal I gjøre mot ham som han hadde tenkt å gjøre mot sin bror; således skal du rydde det onde bort av din midte, 
5:19:20: forat de andre må høre det og frykte, så de ikke mere gjør nogen sådan ond gjerning i din midte. 
5:19:21: Du skal ingen spare: Liv for liv, øie for øie, tann for tann, hånd for hånd, fot for fot! 
5:20:1: Når du drar ut i krig mot din fiende, og du ser hester og vogner og flere krigsfolk enn du har selv, da skal du ikke være redd dem; for Herren din Gud er med dig, han som førte dig op fra Egyptens land. 
5:20:2: Og når I er i ferd med å gå i striden, da skal presten trede frem og tale til folket. 
5:20:3: Og han skal si til dem: Hør, Israel! I er idag i ferd med å gå i strid mot eders fiender. La ikke motet falle; frykt ikke, forferdes ikke og reddes ikke for dem! 
5:20:4: For Herren eders Gud går selv med eder for å stride for eder mot eders fiender og frelse eder. 
5:20:5: Og tilsynsmennene skal tale til folket og si: Er her nogen mann som har bygget et nytt hus og ikke innvidd det, så kan han gå og vende tilbake til sitt hus, forat han ikke skal dø i striden og en annen innvie det. 
5:20:6: Og er her nogen mann som har plantet en vingård og ennu ikke har tatt den i bruk, så kan han gå og vende tilbake til sitt hus, forat han ikke skal dø i striden og en annen ta den i bruk. 
5:20:7: Og er her nogen mann som har trolovet sig med en kvinne, men ennu ikke har tatt henne til ekte, så kan han gå og vende tilbake til sitt hus, forat han ikke skal dø i striden og en annen ekte henne. 
5:20:8: Og tilsynsmennene skal fremdeles tale til folket og si: Er her nogen mann som er redd og motfallen, så kan han gå og vende tilbake til sitt hus, forat ikke hans brødres hjerte skal bli mistrøstig, likesom hans eget hjerte er. 
5:20:9: Og når så tilsynsmennene har endt sin tale til folket, da skal de sette hærførere over folket. 
5:20:10: Når du går frem mot en by for å stride mot den, så skal du først tilbyde den fred. 
5:20:11: Og dersom den svarer dig fredelig og lukker op for dig, da skal alt folket i byen være dig arbeidspliktig og tjene dig. 
5:20:12: Men dersom den ikke vil ha fred med dig, men føre krig mot dig, da skal du kringsette den, 
5:20:13: og Herren din Gud skal gi den i din hånd; og du skal slå alt mannkjønn der ihjel med sverdets egg; 
5:20:14: men kvinnene og barna og feet og alt det som er i byen - alt hærfanget der - kan du ta som ditt bytte, og du kan nytte alt det som Herren din Gud har latt dig ta fra dine fiender. 
5:20:15: Således skal du gjøre med alle de byer som ligger meget langt borte fra dig og ikke hører disse folk til. 
5:20:16: Men i de byer som hører disse folk til, og som Herren din Gud gir dig til arv, der skal du ikke la noget som drar ånde, bli i live; 
5:20:17: men du skal slå dem med bann, både hetittene og amorittene og kana'anittene og ferisittene, hevittene og jebusittene, således som Herren din Gud har befalt dig, 
5:20:18: forat de ikke skal lære eder å ta efter alle de vederstyggelige ting som de har gjort for sine guder, så I synder mot Herren eders Gud. 
5:20:19: Når du holder en by kringsatt i lang tid og strider mot den for å innta den, så skal du ikke ødelegge dens trær ved å skadehugge dem med øksen; du kan ete av dem, men du skal ikke hugge dem ned; trærne på marken er da ikke mennesker, så du skulde stride mot dem og? 
5:20:20: Men de trær som du vet ikke bærer spiselig frukt, dem kan du ødelegge og hugge ned og bruke til å bygge bolverk mot den by som fører krig mot dig, inntil den faller. 
5:21:1: Når der i det land Herren din Gud gir dig til eie, blir funnet et drept menneske liggende på marken, og ingen vet hvem som har slått ham ihjel, 
5:21:2: da skal dine eldste og dine dommere gå ut og måle hvor langt det er fra den drepte til de byer som ligger deromkring. 
5:21:3: Og de eldste i den by som er nærmest den drepte, skal ta en kvige som ikke har vært brukt til arbeid og ikke har båret åk; 
5:21:4: og de eldste i denne by skal føre kvigen ned til en alltid rinnende bekk i en dal som ikke dyrkes eller tilsåes, og der ved bekken skal de bryte nakken på kvigen. 
5:21:5: Så skal prestene, Levis sønner, trede frem; for dem har Herren din Gud utvalgt til å tjene ham og til å velsigne i Herrens navn, og efter deres ord skal enhver tvist og enhver skade avgjøres. 
5:21:6: Og alle de eldste i denne by, de som bor nærmest den drepte, skal tvette sine hender over kvigen som de har knekket nakken på ved bekken. 
5:21:7: Og de skal ta til orde og si: Våre hender har ikke utøst dette blod, og våre øine har ikke sett det. 
5:21:8: Ta skylden bort fra ditt folk Israel, som du har forløst, Herre, og la ikke uskyldig blod komme over ditt folk Israel! Så får de soning for dette blod. 
5:21:9: Således renser du dig for uskyldig blod; for du skal gjøre det som er rett i Herrens øine. 
5:21:10: Når du drar ut i krig mot dine fiender, og Herren din Gud gir dem i din hånd, og du tar fanger blandt dem, 
5:21:11: og du så blandt fangene ser en kvinne som er vakker av skapning, og du synes godt om henne og vil ta henne til hustru, 
5:21:12: da skal du føre henne inn i ditt hus, og hun skal klippe sitt hår og skjære sine negler, 
5:21:13: og hun skal legge av de klær som hun hadde på da hun blev tatt til fange, og så skal hun bli i ditt hus og sørge over sin far og mor en måneds tid; siden kan du gå inn til henne og ekte henne, så hun blir din hustru. 
5:21:14: Men dersom du ikke mere synes om henne, da skal du la henne fare hvorhen hun vil, men du skal ikke selge henne for penger; du skal ikke gjøre henne til trælkvinne, fordi du har levd sammen med henne. 
5:21:15: Når en mann har to hustruer, en som han elsker, og en som han ikke synes om, og han har fått sønner med dem begge, både med den han elsker og med den han ikke synes om, og den førstefødte er sønn til den han ikke synes om, 
5:21:16: da skal han, når han skifter det han eier, mellem sine sønner, ikke ha lov til å la sønnen til den han elsker, få førstefødselsretten fremfor sønnen til den han ikke synes om, han som er den førstefødte. 
5:21:17: Men han skal kjennes ved den førstefødte, sønnen til den han ikke synes om, og gi ham en dobbelt del av alt det han eier; for han er den første frukt av hans kraft, ham hører førstefødselsretten til. 
5:21:18: Når en mann har en ustyrlig og gjenstridig sønn, som ikke vil lyde sin far og mor, og som, endog de tukter ham, er ulydig mot dem, 
5:21:19: da skal hans far og mor ta og føre ham ut til de eldste i hans by, til byens port. 
5:21:20: Og de skal si til de eldste i byen: Denne vår sønn er ustyrlig og gjenstridig, han vil ikke lyde oss, han er en ødeland og en drikker. 
5:21:21: Og alle mennene i hans by skal stene ham til døde; således skal du rydde det onde bort av din midte, og hele Israel skal høre det og frykte. 
5:21:22: Når en mann har en synd på sig som fortjener døden, og han blir avlivet og derefter hengt på et tre, 
5:21:23: så skal hans døde kropp ikke bli natten over på treet, men du skal begrave ham samme dag; for forbannet av Gud er den som blir hengt; og du skal ikke gjøre ditt land urent, det som Herren din Gud gir dig til 
5:22:1: Dersom du ser din brors okse eller lam fare vill, skal du ikke dra dig unda; du skal føre dem tilbake til din bror. 
5:22:2: Men dersom din bror ikke bor i nærheten av dig, eller du ikke vet hvem det er, da skal du ta dem hjem til dig, og de skal være hos dig, til din bror spør efter dem; da skal du gi ham dem tilbake. 
5:22:3: Det samme skal du gjøre med hans asen og med hans klær og med alt hvad din bror har tapt - det som er kommet bort for ham, og som du finner; du har ikke lov til å dra dig unda. 
5:22:4: Dersom du ser din brors asen eller okse ligge over ende på veien, skal du ikke dra dig unda; du skal hjelpe ham med å reise dem op. 
5:22:5: En kvinne skal ikke ha mannsklær på sig, og en mann skal ikke klæ sig i kvinneklær; hver den som gjør så, er en vederstyggelighet for Herren din Gud. 
5:22:6: Når du på veien treffer på et fuglerede i et tre eller på jorden, med unger eller egg i, og moren ligger på ungene eller på eggene, da skal du ikke ta både moren og ungene; 
5:22:7: du skal la moren flyve, men ungene kan du ta; da skal det gå dig vel, og du skal leve lenge. 
5:22:8: Når du bygger et nytt hus, så skal du gjøre et rekkverk om taket, forat du ikke skal føre blodskyld over ditt hus om nogen faller ned derfra. 
5:22:9: Du skal ikke så noget i din vingård, forat ikke hele avlingen, både det du har sådd, og frukten av vingården, skal hjemfalle til helligdommen. 
5:22:10: Du skal ikke pløie med en okse og et asen for samme plog. 
5:22:11: Du skal ikke gå med klær av ulikt tøi, ull og lin sammen. 
5:22:12: Du skal gjøre dig dusker på de fire kanter av din kappe, som du dekker dig med. 
5:22:13: Når en mann tar sig en hustru og går inn til henne, men siden får uvilje mot henne 
5:22:14: og skylder henne for skammelige ting og fører ondt rykte ut om henne og sier: Denne kvinne tok jeg til ekte og holdt mig nær til henne, men fant at hun ikke var jomfru, 
5:22:15: da skal pikens far og mor ta tegnet på hennes jomfrudom og ha med sig ut til porten, til byens eldste. 
5:22:16: Og pikens far skal si til de eldste: Jeg gav denne mann min datter til hustru, men nu har han fått uvilje mot henne, 
5:22:17: og så skylder han henne for skammelige ting og sier: Jeg fant at din datter ikke var jomfru; men her er tegnet på min datters jomfrudom. Så skal de bre ut klædet for de eldste i byen. 
5:22:18: Og de eldste i byen skal ta mannen og gi ham hugg 
5:22:19: og ilegge ham en bot på hundre sekel sølv og gi dem til pikens far, fordi han førte ondt rykte ut om en jomfru i Israel, og hun skal være hans hustru; han må ikke skille sig fra henne så lenge han lever. 
5:22:20: Men var det sant, var piken ikke jomfru, 
5:22:21: da skal de føre henne ut foran døren til hennes fars hus, og mennene i hennes by skal stene henne til døde, fordi hun har gjort en skammelig gjerning i Israel og drevet hor i sin fars hus; således skal du rydde det onde bort av din midte. 
5:22:22: Når en mann gripes i å ligge hos en gift kvinne, så skal de begge dø, både mannen som lå hos kvinnen, og kvinnen selv; således skal du rydde det onde bort av Israel. 
5:22:23: Når en pike som er jomfru, er trolovet med en mann, og en annen mann treffer henne i byen og ligger hos henne, 
5:22:24: så skal I føre dem begge ut til byens port og stene dem til døde, piken fordi hun ikke skrek om hjelp i byen, og mannen fordi han krenket sin næstes hustru; således skal du rydde det onde bort av din midte. 
5:22:25: Men dersom en mann treffer en trolovet pike ute på marken, og han holder fast på henne og ligger hos henne, da skal bare mannen som lå hos henne, dø. 
5:22:26: Men piken skal du ikke gjøre noget, hun har ingen dødsskyld; for med denne sak har det sig på samme måte som når en overfaller sin næste og slår ham ihjel; 
5:22:27: han traff den trolovede pike ute på marken, hun skrek, men der var ingen til å hjelpe henne. 
5:22:28: Når en mann treffer en pike som er jomfru og ikke trolovet, og tar fatt på henne og ligger hos henne, og nogen kommer over dem, 
5:22:29: da skal den mann som lå hos henne, gi pikens far femti sekel sølv, og hun skal være hans hustru, fordi han krenket henne; han må ikke skille sig fra henne sa lenge han lever. 
5:22:30: Ingen må ekte sin stedmor eller søke seng med henne. 
5:23:1: Ingen som er gildet, enten ved knusning eller ved å skjæres, skal være med i Herrens menighet. 
5:23:2: Ingen som er født i hor, skal være med i Herrens menighet, enn ikke hans ætt i tiende ledd skal være med i Herrens menighet. 
5:23:3: Ingen ammonitt eller moabitt skal være med i Herrens menighet; enn ikke deres ætt i tiende ledd skal nogensinne være med i Herrens menighet, 
5:23:4: fordi de ikke kom eder i møte med brød og vann på veien, da I drog ut av Egypten, og fordi han* leide Bileam, Beors sønn, fra Petor i Mesopotamia imot dig til å forbanne dig. # <* kongen i Moab.> 
5:23:5: Men Herren din Gud vilde ikke høre på Bileam, og Herren din Gud vendte forbannelsen til velsignelse for dig, fordi Herren din Gud hadde dig kjær. 
5:23:6: Du skal aldri i dine levedager søke deres velferd og lykke. 
5:23:7: Men du skal ikke avsky edomitten, for han er din bror; og du skal ikke avsky egypteren, for du har levd som fremmed i hans land. 
5:23:8: Deres ætt i tredje ledd kan få være med i Herrens menighet. 
5:23:9: Når du drar ut mot dine fiender og slår leir, da skal du vokte dig for alt som er usømmelig. 
5:23:10: Er nogen blandt eder uren efter noget som har hendt ham om natten, så skal han gå utenfor leiren, han skal ikke komme inn i leiren. 
5:23:11: Mot aften skal han bade sig i vann, og når solen går ned, kan han komme inn i leiren. 
5:23:12: Du skal ha et sted utenfor leiren som du skal gå ut til. 
5:23:13: Og mellem dine redskaper skal du ha en spade; med den skal du grave et hull, når du setter dig der ute, og så igjen dekke over det som er gått fra dig; 
5:23:14: for Herren din Gud vandrer midt i din leir for å utfri dig og gi dine fiender i din vold, og din leir skal være hellig, forat han ikke skal se noget motbydelig hos dig og vende sig bort fra dig. 
5:23:15: Du skal ikke sende en træl tilbake til hans herre når han er rømt fra sin herre og har flyktet til dig. 
5:23:16: Han skal bli hos dig i ditt land på det sted han utvelger sig i en av dine byer, hvor det tykkes ham best; du skal ikke være hård imot ham. 
5:23:17: Der skal ikke være nogen tempelskjøge* blandt Israels døtre, og der skal ikke være nogen tempelboler* blandt Israels sønner. # <* d.e. en som driver utukt til ære for avgudene.> 
5:23:18: Du skal ikke komme med skjøgelønn eller hundepenger inn i Herrens, din Guds hus, om du skulde ha gjort noget sådant løfte; for begge deler er en vederstyggelighet for Herren din Gud. 
5:23:19: Du skal ikke ta rente av din bror, hverken av penger eller av matvarer eller av nogen annen ting som der tas rente av. 
5:23:20: Av utlendingen kan du ta rente; men av din bror må du ikke ta rente; da skal Herren din Gud velsigne dig i alt det du tar dig fore i det land du kommer inn i for å ta det i eie. 
5:23:21: Når du gjør Herren din Gud et løfte, da skal du ikke vente med å holde det; for Herren din Gud vil kreve det av dig, og du vil få synd på dig. 
5:23:22: Men om du lar være å gjøre noget løfte, vil du ikke få synd på dig. 
5:23:23: Hvad dine leber har talt, skal du holde og gjøre, fordi du frivillig og med egen munn har gitt Herren din Gud ditt løfte. 
5:23:24: Når du kommer inn i din næstes vingård, da kan du ete druer, så meget du vil, til du er mett; men du må ikke sanke noget i ditt spann. 
5:23:25: Når du kommer inn i din næstes kornaker, da kan du plukke aks med din hånd; men sigd må du ikke bruke på din næstes kornaker. 
5:24:1: Dersom en mann tar sig en hustru og ekter henne, og hun ikke tekkes ham lenger, fordi han har funnet noget hos henne som byr ham imot, og han da skriver henne et skilsmissebrev og gir henne det i hånden og sender henne bort fra sitt hus, 
5:24:2: og hun, efter å ha flyttet fra ham, går bort og ekter en annen mann, 
5:24:3: og denne annen mann får uvilje mot henne og skriver henne et skilsmissebrev og gir henne det i hånden og sender henne bort fra sitt hus, eller dersom den annen mann som har tatt henne til hustru, dør, 
5:24:4: da må ikke hennes første mann, som sendte henne fra sig, ta henne til hustru igjen, efterat hun er blitt uren; for det er en vederstyggelighet for Herrens åsyn, og du skal ikke føre synd over det land Herren din Gud gir dig til arv. 
5:24:5: Når en mann nylig har tatt sig en hustru, da skal han ikke dra ut i strid, heller ikke skal det legges nogen annen byrde på ham; han skal være fri et år til beste for sitt hus og til glede for hustruen som han har ektet. 
5:24:6: Ingen må ta en håndkvern eller en kvernsten i pant; for da tar han livet i pant. 
5:24:7: Kommer du over en mann som stjeler nogen av sine brødre blandt Israels barn og gjør ham til træl eller selger ham, da skal denne tyv dø; således skal du rydde det onde bort av din midte. 
5:24:8: Ta dig i akt for spedalskhetssyken, så du nøie varetar og gjør alt det de levittiske prester lærer eder! Således som jeg har befalt dem, skal I gi akt på å gjøre. 
5:24:9: Kom i hu hvad Herren din Gud gjorde med Mirjam på veien, da I drog ut av Egypten! 
5:24:10: Når du låner din næste noget, skal du ikke gå inn i hans hus for å hente det pant han skal gi. 
5:24:11: Du skal bli stående utenfor, og den mann du låner til, skal komme ut til dig med pantet. 
5:24:12: Og dersom det er en fattig mann, så skal du ikke legge dig til å sove med hans pant; 
5:24:13: du skal gi ham pantet tilbake når solen går ned, forat han kan legge sig i sin kappe og velsigne dig; og det skal tjene dig til rettferdighet for Herrens, din Guds åsyn. 
5:24:14: Du skal ikke gjøre urett mot en nødlidende og fattig dagarbeider, enten han er en av dine brødre eller en av de fremmede som bor i ditt land, rundt om i dine byer; 
5:24:15: på dagen skal du gi ham hans lønn, og før solen går ned; for han er fattig og stunder efter sin lønn; ellers kunde han rope over dig til Herren, og du få synd på dig. 
5:24:16: Foreldre skal ikke lide døden for sine barns skyld, og barn ikke lide døden for sine foreldres skyld; enhver skal lide døden for sin egen synd. 
5:24:17: Du skal ikke bøie retten for en fremmed eller en farløs, og du skal ikke ta en enkes klær i pant. 
5:24:18: Du skal komme i hu at du var træl i Egypten, og at Herren din Gud fridde dig ut derfra; derfor byder jeg dig å gjøre således. 
5:24:19: Når du høster inn kornet på din aker, og du glemmer et kornbånd på akeren, da skal du ikke vende tilbake for å hente det; den fremmede, den farløse og enken skal ha det; så skal Herren din Gud velsigne dig i alt det du tar dig fore. 
5:24:20: Når du slår dine oliven ned, skal du ikke bakefter gjennemsøke grenene; den fremmede, den farløse og enken skal ha det. 
5:24:21: Når du høster din vingård, skal du ikke holde eftersanking; den fremmede, den farløse og enken skal ha det. 
5:24:22: Du skal komme i hu at du var træl i Egyptens land; derfor byder jeg dig å gjøre således. 
5:25:1: Når det er trette mellem menn, og de treder frem for retten, og deres sak blir pådømt, så skal en dømme den som har rett, å ha rett, og den som har urett, å ha urett. 
5:25:2: Og dersom da den skyldige skal straffes med slag, så skal dommeren la dem legge ham ned og i sitt påsyn la dem gi ham så mange slag som svarer til hans brøde. 
5:25:3: Firti slag kan han la dem gi ham, men ikke flere, forat din bror ikke skal bli vanæret i dine øine ved å få ennu mange flere slag. 
5:25:4: Du skal ikke binde munnen til på en okse som tresker. 
5:25:5: Når brødre bor sammen, og en av dem dør og ikke har nogen sønn, så skal den avdødes hustru ikke ekte en fremmed mann utenfor ætten; hennes manns bror skal gå inn til henne og ta henne til hustru og således ekte henne i sin brors sted. 
5:25:6: Og den første sønn hun får, skal kalles sønn av hans avdøde bror, forat den avdødes navn ikke skal utslettes av Israel. 
5:25:7: Men dersom mannen ikke har lyst til å gifte sig med sin brors hustru, da skal hun gå op til porten, til de eldste, og si: Min manns bror nekter å opreise sin bror et navn i Israel; han vil ikke ekte mig i sin brors sted. 
5:25:8: Og de eldste i hans by skal kalle ham for sig og tale til ham; holder han da fast ved sitt og sier: Jeg har ikke lyst til å gifte mig med henne, 
5:25:9: da skal hans brors hustru trede frem til ham for de eldstes øine og dra skoen av hans fot og spytte ham i ansiktet, og hun skal ta til orde og si: Således skal det gjøres med den mann som ikke vil bygge op igjen sin brors hus. 
5:25:10: Og hans ætt skal siden i Israel kalles den barfotedes hus. 
5:25:11: Når menn er i strid med hverandre, og den enes hustru kommer til og vil hjelpe sin mann mot den som slår ham, og hun rekker hånden ut og griper om hans blusel, 
5:25:12: da skal du hugge hennes hånd av, du skal ikke spare henne. 
5:25:13: Du skal ikke ha to slags vektstener i din pung, en stor og en liten; 
5:25:14: du skal ikke ha to slags efa i ditt hus, en stor og en liten; 
5:25:15: hele og rette vektstener, hel og rett efa skal du ha; så skal du leve lenge i det land Herren din Gud gir dig. 
5:25:16: For hver den som gjør slikt, hver den som gjør urett, er en vederstyggelighet for Herren din Gud. 
5:25:17: Kom i hu hvad Amalek gjorde mot dig på veien, da I drog ut av Egypten, 
5:25:18: hvorledes han kom mot dig på veien mens du var trett og mødig, og slo din baktropp, alle de utmattede som var blitt liggende efter; han fryktet ikke Gud. 
5:25:19: Og når Herren din Gud gir dig ro for alle dine fiender rundt om i det land Herren din Gud gir dig til arv og eie, da skal du utslette minnet om Amalek over hele jorden. Glem ikke det! 
5:26:1: Når du kommer inn i det land Herren din Gud gir dig til arv, og du tar det i eie og bor der, 
5:26:2: da skal du ta førstegrøden av alle jordens frukter som du innhøster av det land Herren din Gud gir dig, og legge det i en kurv og gå til det sted Herren din Gud utvelger for å la sitt navn bo der. 
5:26:3: Og du skal gå til den som er prest på den tid, og si til ham: Jeg vidner idag for Herren din Gud at jeg er kommet inn i det land Herren har svoret våre fedre å ville gi oss. 
5:26:4: Og presten skal ta kurven av din hånd og sette den ned foran Herrens, din Guds alter. 
5:26:5: Da skal du ta til orde og si for Herrens, din Guds åsyn: Min far var en omvankende araméer, og han drog ned til Egypten og bodde der som fremmed med en liten flokk; og der blev han til et stort, sterkt og tallrikt folk. 
5:26:6: Men egypterne for ille med oss og plaget oss og la hårdt trælarbeid på oss. 
5:26:7: Da ropte vi til Herren, våre fedres Gud; og Herren hørte vår røst og så vår nød, vår møie og vår trengsel. 
5:26:8: Og Herren førte oss ut av Egypten med sterk hånd og med utrakt arm og med store, forferdelige gjerninger og med tegn og under. 
5:26:9: Og han førte oss til dette sted og gav oss dette land, et land som flyter med melk og honning. 
5:26:10: Og her kommer jeg nu med førstegrøden av det lands frukter som du, Herre, har gitt mig. Så skal du legge det ned for Herrens, din Guds åsyn og tilbede for Herrens, din Guds åsyn. 
5:26:11: Og du skal glede dig over alt det gode Herren din Gud har gitt dig og ditt hus, både du og levitten og den fremmede som bor i ditt land. 
5:26:12: Når du i det tredje år - tiendeåret - er blitt ferdig med å utrede hele tienden av din avling, og du har gitt levitten, den fremmede, den farløse og enken, så de kan ete sig mette hos dig i dine byer, 
5:26:13: da skal du si for Herrens, din Guds åsyn: Nu har jeg båret det hellige ut av huset og gitt det til levitten og til den fremmede, til den farløse og til enken, aldeles efter det bud som du har gitt mig; jeg har ikke overtrådt noget av dine bud og ikke glemt noget. 
5:26:14: Jeg har ikke ett noget av tienden mens jeg hadde sorg, og ikke båret bort noget av den mens jeg var uren, og jeg har ikke brukt noget av den for en død; jeg har vært Herrens, min Guds røst lydig, jeg har i ett og alt gjort således som du har befalt mig. 
5:26:15: Se ned fra din hellige bolig, fra himmelen, og velsign ditt folk Israel og det land du har gitt oss, således som du med ed har lovt våre fedre, et land som flyter med melk og honning! 
5:26:16: På denne dag byder Herren din Gud dig å holde disse lover og bud; og du skal akte vel på at du holder dem av alt ditt hjerte og av all din sjel. 
5:26:17: Du har idag gitt Herren ditt ord at han skal være din Gud, og at du vil vandre på hans veier og ta vare på hans lover og hans bud og hans forskrifter og lyde hans røst 
5:26:18: Og Herren har idag gitt dig sitt ord at du skal være hans eiendomsfolk, således som han har sagt til dig, og at han, dersom du holder alle hans bud, 
5:26:19: vil heve dig høit over alle de folk han har skapt, til pris og til ry og til pryd, og at du skal være et hellig folk for Herren din Gud, således som han har sagt. 
5:27:1: Og Moses og de eldste i Israel bød folket og sa: Ta vare på alle de bud jeg gir eder idag! 
5:27:2: Og når I kommer over Jordan til det land Herren din Gud gir dig, da skal du reise op nogen store stener og stryke dem over med kalk. 
5:27:3: På dem skal du, når du er kommet over, skrive alle ordene i denne lov, forat du kan komme inn i det land Herren din Gud gir dig, et land som flyter med melk og honning, således som Herren, dine fedres Gud, har tilsagt dig. 
5:27:4: Så snart I er kommet over Jordan, skal I reise op disse stener som jeg idag gir eder befaling om, på Ebal-fjellet, og du skal stryke dem over med kalk. 
5:27:5: Og du skal bygge Herren din Gud et alter der, et alter av stener; du skal ikke bruke jern på dem. 
5:27:6: Av hele stener skal du bygge Herrens, din Guds alter, og du skal ofre Herren din Gud brennoffer på det. 
5:27:7: Og du skal ofre takkoffer og ete dem der og være glad for Herrens, din Guds åsyn. 
5:27:8: Og du skal skrive på stenene alle ordene i denne lov, klart og tydelig. 
5:27:9: Og Moses og de levittiske prester talte til hele Israel og sa: Gi akt og hør, Israel! På denne dag er du blitt Herrens, din Guds folk. 
5:27:10: Så lyd da Herrens, din Guds røst, og hold hans bud og hans lover, som jeg gir dig idag! 
5:27:11: Samme dag bød Moses folket og sa: 
5:27:12: Når I er kommet over Jordan, da skal disse stå på fjellet Garisim og velsigne folket: Simeon og Levi og Juda og Issakar og Josef og Benjamin. 
5:27:13: Og disse skal stå på fjellet Ebal og lyse forbannelse: Ruben, Gad og Aser og Sebulon, Dan og Naftali. 
5:27:14: Og levittene skal ta til orde og si med høi røst til hver mann i Israel: 
5:27:15: Forbannet være den mann som gjør et utskåret eller et støpt billede, en vederstyggelighet for Herren, et verk av en kunstners hender, og stiller det op i lønndom! Og alt folket skal svare og si: Amen. 
5:27:16: Forbannet være den som ringeakter sin far eller sin mor! Og alt folket skal si: Amen. 
5:27:17: Forbannet være den som flytter merkestenene mellem sig og sin næste! Og alt folket skal si: Amen. 
5:27:18: Forbannet være den som får en blind til å fare vill på veien! Og alt folket skal si: Amen. 
5:27:19: Forbannet være den som bøier retten for en fremmed, en farløs og en enke! Og alt folket skal si: Amen. 
5:27:20: Forbannet være den som ligger hos sin fars hustru, for han har vanæret sin fars leie! Og alt folket skal si: Amen. 
5:27:21: Forbannet være den som har omgang med noget dyr! Og alt folket skal si: Amen. 
5:27:22: Forbannet være den som ligger hos sin søster, sin fars eller sin mors datter! Og alt folket skal si: Amen. 
5:27:23: Forbannet være den som ligger hos sin hustrus mor! Og alt folket skal si: Amen. 
5:27:24: Forbannet være den som slår sin næste ihjel i lønndom! Og alt folket skal si: Amen. 
5:27:25: Forbannet være den som tar imot gaver for å slå nogen ihjel og utøser uskyldig blod! Og alt folket skal si: Amen. 
5:27:26: Forbannet være den som ikke holder ordene i denne lov og ikke gjør efter dem! Og alt folket skal si: Amen. 
5:28:1: Dersom du nu hører på Herrens, din Guds røst, så du akter vel på å holde alle hans bud, som jeg gir dig idag, da skal Herren din Gud heve dig høit over alle folkene på jorden. 
5:28:2: Og alle disse velsignelser skal komme over dig og nå dig, så sant du hører på Herrens, din Guds røst: 
5:28:3: Velsignet være du i byen, og velsignet være du på marken! 
5:28:4: Velsignet være ditt livs frukt og frukten av din jord og frukten av ditt fe, det som faller av ditt storfe, og det som fødes av ditt småfe! 
5:28:5: Velsignet være din kurv og ditt deigtrau! 
5:28:6: Velsignet være du i din inngang, og velsignet være du i din utgang! 
5:28:7: Herren skal la dine fiender, som reiser sig mot dig, ligge under for dig; på én vei skal de dra ut mot dig, og på syv veier skal de flykte for dig. 
5:28:8: Herren skal byde velsignelsen å være hos dig i dine lader og å følge dig i alt det du tar dig fore, og han skal velsigne dig i det land Herren din Gud gir dig. 
5:28:9: Herren skal gjøre dig til et hellig folk for sig, som han har tilsvoret dig, såfremt du tar vare på Herrens, din Guds bud og vandrer på hans veier. 
5:28:10: Og alle folkene på jorden skal se at du er kalt med Herrens navn, og de skal reddes for dig. 
5:28:11: Herren skal gi dig overflod på alt som godt er, både av ditt livs frukt og av frukten av ditt fe og av frukten av din jord i det land som Herren tilsvor dine fedre å ville gi dig. 
5:28:12: Herren skal lukke op for dig sitt rike forrådshus, himmelen, og gi ditt land regn i rette tid og velsigne alt dine henders arbeid; og du skal låne til mange folk, men selv skal du ikke trenge til å låne av nogen. 
5:28:13: Herren skal gjøre dig til hode og ikke til hale; og du skal alltid være ovenpå og aldri være under, såfremt du hører på Herrens, din Guds bud, som jeg idag byder dig å ta vare på og holde, 
5:28:14: og såfremt du ikke viker av fra noget av de ord jeg legger frem for eder idag, hverken til høire eller til venstre, så du følger andre guder og dyrker dem. 
5:28:15: Men dersom du ikke hører på Herrens, din Guds røst, så du akter vel på å holde alle hans bud og hans lover, som jeg gir dig idag, da skal alle disse forbannelser komme over dig og nå dig: 
5:28:16: Forbannet være du i byen, og forbannet være du på marken! 
5:28:17: Forbannet være din kurv og ditt deigtrau! 
5:28:18: Forbannet være ditt livs frukt og din jords frukt, det som faller av ditt storfe, og det som fødes av ditt småfe! 
5:28:19: Forbannet være du i din inngang, og forbannet være du i din utgang! 
5:28:20: Herren skal sende forbannelse, forferdelse og trusel mot dig i alt det du rekker din hånd ut til og tar dig fore, inntil du blir ødelagt og hastig går til grunne for dine onde gjerningers skyld, fordi du forlot mig. 
5:28:21: Herren skal la pesten henge fast ved dig til han har utryddet dig av det land du kommer inn i og skal ta i eie. 
5:28:22: Herren skal slå dig med tærende syke og brennende sott, med feber og verk, med tørke og kornbrand og rust, og de skal forfølge dig til du går til grunne. 
5:28:23: Himmelen over ditt hode skal være som kobber, og jorden under dig som jern. 
5:28:24: Herren skal la støv og sand være det regn han gir ditt land; det skal komme ned over dig fra himmelen, til du blir ødelagt. 
5:28:25: Herren skal la dig ligge under for dine fiender; på én vei skal du dra ut mot dem, og på syv veier skal du flykte for dem; og du skal bli mishandlet av alle riker på jorden. 
5:28:26: Dine døde kropper skal bli til føde for alle himmelens fugler og for jordens dyr, og ingen skal jage dem bort. 
5:28:27: Herren skal slå dig med Egyptens bylder, med svuller og med skabb og utslett, så du ikke skal kunne læges. 
5:28:28: Herren skal slå dig med vanvidd og med blindhet og med sinnets forvirring. 
5:28:29: Og du skal famle dig frem om middagen, som den blinde famler sig frem i mørket; du skal ingen lykke ha på dine veier, og det skal ikke times dig annet enn undertrykkelse og utplyndring alle dager, og det skal ikke være nogen som frelser. 
5:28:30: Du skal trolove dig med en kvinne, og en annen mann skal ligge hos henne. Du skal bygge et hus og ikke bo i det; du skal plante en vingård og ikke ta den i bruk. 
5:28:31: Din okse skal slaktes for dine øine, og du skal ikke ete av den; ditt asen skal røves så du ser på det, og ikke komme tilbake til dig; ditt småfe skal gis til dine fiender, og ingen skal hjelpe dig. 
5:28:32: Dine sønner og dine døtre skal overgis til et fremmed folk, og dine øine skal se på det og vansmekte av lengsel efter dem hele dagen, og din hånd skal være avmektig. 
5:28:33: Frukten av din jord og av alt ditt arbeid skal fortæres av et folk du ikke kjenner; og det skal ikke times dig annet enn vold og undertrykkelse alle dager. 
5:28:34: Og du skal bli vanvittig av det syn som dine øine må se. 
5:28:35: Herren skal slå dig med onde bylder på knær og legger, så du ikke skal kunne læges, fra fotsåle til isse. 
5:28:36: Herren skal føre dig og din konge, som du setter over dig, bort til et folk som hverken du eller dine fedre har kjent, og der skal du dyrke andre guder, stokk og sten. 
5:28:37: Og du skal bli til en redsel, til et ordsprog og til en spott blandt alle de folk Herren fører dig til. 
5:28:38: Megen sæd skal du føre ut på marken, og lite skal du samle inn; for gresshoppen skal ete den av. 
5:28:39: Vingårder skal du plante og dyrke, men vin skal du ikke drikke, og ikke kunne gjemme; for makk skal ete den op. 
5:28:40: Oljetrær skal du ha i hele ditt land, men med olje skal du ikke salve dig; for ditt oljetre skal kaste karten av. 
5:28:41: Sønner og døtre skal du få, men du skal ikke beholde dem, for de skal gå i fangenskap. 
5:28:42: Alle dine trær og din jords frukt skal gresshoppen ta i eie. 
5:28:43: Den fremmede som bor i ditt land, skal stige op over dig, høiere og høiere; men du skal synke dypere og dypere. 
5:28:44: Han skal låne til dig, men du skal ikke kunne låne til ham; han skal være hode, og du skal være hale. 
5:28:45: Alle disse forbannelser skal komme over dig og forfølge dig og nå dig, til du blir ødelagt, fordi du ikke hørte på Herrens, din Guds røst, og ikke tok vare på hans bud og hans lover, som han gav dig, 
5:28:46: og de skal være til et tegn og et under som skal henge ved dig og din ætt til evig tid. 
5:28:47: Fordi du ikke tjente Herren din Gud med glede og av hjertens lyst, endog du hadde overflod på alt, 
5:28:48: så skal du tjene dine fiender, som Herren skal sende mot dig, i hunger og tørst og i nakenhet og i mangel på alt; og han skal legge et jernåk på din hals, til han har ødelagt dig. 
5:28:49: Herren skal føre et folk over dig langt borte fra, fra jordens ende, et folk som kommer flyvende lik en ørn, et folk hvis tungemål du ikke forstår, 
5:28:50: et folk med hårdt ansikt, som ikke akter den gamle og ikke har medynk med den unge. 
5:28:51: Og det skal fortære frukten av ditt fe og frukten av din jord, til du blir ødelagt, fordi det ikke levner dig korn eller most eller olje, det som faller av ditt storfe, eller det som fødes av ditt småfe, inntil det har gjort ende på dig. 
5:28:52: Det skal kringsette dig i alle dine byer, inntil dine høie og faste murer, som du setter din lit til, faller i hele ditt land, det som Herren din Gud har gitt dig. 
5:28:53: Da skal du ete ditt livs frukt, kjøttet av dine sønner og dine døtre, som Herren din Gud gir dig; så stor er den trengsel og angst som din fiende skal føre over dig. 
5:28:54: Den kjælneste og mest forfinede av dine menn skal se med onde øine på sin bror og på hustruen i sin favn og på de barn han ennu har tilbake, 
5:28:55: og ikke unne nogen av dem det minste grand av sine barns kjøtt, som han eter, fordi intet annet blev levnet ham; så stor er den trengsel og angst som din fiende skal føre over dig i alle dine byer. 
5:28:56: Den kjælneste og mest forfinede av dine kvinner, som aldri prøvde på å sette sin fot på jorden for bare finhet og kjælenskap, skal se med onde øine på mannen i sin favn og på sin sønn og sin datter 
5:28:57: og ikke unne dem sin efterbyrd, som går fra henne, eller de barn hun får; for i mangel på alt skal hun fortære dem i lønndom; så stor er den trengsel og angst som din fiende skal føre over dig i dine byer. 
5:28:58: Dersom du ikke gir akt på å holde alle ordene i denne lov, de som står skrevet i denne bok - dersom du ikke frykter dette herlige og forferdelige navn: Herren din Gud, 
5:28:59: da skal Herren sende uhørte plager over dig og uhørte plager over din ætt, store og vedholdende plager og onde og vedholdende sykdommer. 
5:28:60: Han skal la alle Egyptens sykdommer, som du gruer for, komme over dig, og de skal henge ved dig. 
5:28:61: Og alle andre sykdornmer og alle andre plager, som der intet står skrevet om i denne lovens bok, skal Herren la komme over dig, til du er ødelagt. 
5:28:62: Bare en liten flokk skal bli tilbake av eder, I som var så tallrike som himmelens stjerner, fordi du ikke hørte på Herrens, din Guds røst. 
5:28:63: Og likesom Herren gledet sig ved å gjøre vel mot eder og gjøre eder tallrike, således skal Herren nu glede sig ved å forderve eder og ødelegge eder; og I skal rykkes op av det land du kommer inn i og skal ta i eie. 
5:28:64: Herren skal sprede dig blandt alle folkene fra jordens ene ende til den andre, og der skal du dyrke andre guder, som hverken du eller dine fedre har kjent, stokk og sten. 
5:28:65: Og blandt disse folk skal du aldri ha ro, og der skal ingen hvile være for din fot; Herren skal der gi dig et bevende hjerte og uttærede øine og en vansmektende sjel. 
5:28:66: Ditt liv skal henge i et hår; du skal engstes natt og dag og aldri være sikker på ditt liv. 
5:28:67: Om morgenen skal du si: Var det bare aften! og om aftenen skal du si: Var det bare morgen! - for den angst du kjenner i ditt hjerte, og for det syn du ser med dine øine. 
5:28:68: Herren skal føre dig tilbake til Egypten på skiber, den vei som jeg sa dig at du aldri mere skulde få se; der skal I bli budt ut til dine fiender som træler og trælkvinner, men der er ingen som kjøper. 
5:29:1: Dette er den pakts ord som Herren bød Moses å gjøre med Israels barn i Moabs land, foruten den pakt han hadde gjort med dem på Horeb. 
5:29:2: Moses kalte hele Israel til sig og sa til dem: I har sett alt det Herren gjorde for eders øine i Egyptens land med Farao og alle hans tjenere og hele hans land, 
5:29:3: de store plager som du så for dine øine, de store tegn og under; 
5:29:4: men like til denne dag har Herren ikke gitt eder hjerte til å forstå eller øine til å se eller ører til å høre med. 
5:29:5: Jeg lot eder vandre firti år i ørkenen; eders klær blev ikke utslitt, og skoen blev ikke utslitt på din fot. 
5:29:6: Brød fikk I ikke å ete, og vin eller sterk drikk fikk I ikke å drikke, forat I skulde kjenne at jeg, Herren, er eders Gud. 
5:29:7: Og da I kom til dette sted, drog Sihon, kongen i Hesbon, og Og, kongen i Basan, ut imot oss til strid, og vi slo dem, 
5:29:8: og vi tok deres land og lot rubenittene og gadittene og den halve Manasse stamme få det til arv. 
5:29:9: Så ta da vare på denne pakts ord og hold dem, så I farer viselig frem i alt det I gjør! 
5:29:10: I står idag alle for Herrens, eders Guds åsyn, eders høvdinger, eders stammer, eders eldste og eders tilsynsmenn, hver mann i Israel, 
5:29:11: eders barn, eders hustruer og den fremmede som er i din leir, både din vedhugger og din vannbærer, 
5:29:12: forat du skal gå inn i pakten med Herren din Gud og i eds-sambåndet med ham, det som Herren din Gud gjør med dig idag; 
5:29:13: for han vil idag gjøre dig til sitt folk, og selv vil han være din Gud, som han har tilsagt dig, og som han har tilsvoret dine fedre Abraham, Isak og Jakob. 
5:29:14: Og ikke bare med eder gjør jeg denne pakt og dette eds-sambånd, 
5:29:15: men både med dem som idag står her sammen med oss for Herrens, vår Guds åsyn, og med dem som ikke er her sammen med oss idag. 
5:29:16: For I vet selv hvorledes vi bodde i Egyptens land, og hvorledes vi drog midt igjennem de folk som I har draget igjennem, 
5:29:17: Og I så deres vederstyggelige og motbydelige avguder, de guder av tre og sten, av sølv og gull som de hadde. 
5:29:18: La det ikke være hos eder nogen mann eller kvinne eller ætt eller stamme hvis hjerte idag vender sig fra Herren vår Gud, så han går bort og dyrker disse folks guder! La det ikke være hos eder nogen rot som bærer trollbær og malurt, 
5:29:19: nogen som når han hører dette eds-sambånds ord, priser sig lykkelig og tenker at det skal gå ham vel om han enn følger sitt eget hårde hjerte. For da kommer det vannrike land til å gå til grunne sammen med det tørstige*. # <* d.e. alle, både de som har overflod, og de som trenger.> 
5:29:20: Herren vil ikke tilgi ham; nei, da skal Herrens vrede og nidkjærhet ryke mot den mann, og alle de forbannelser som er skrevet i denne bok, skal hvile på ham, og Herren skal utslette hans navn under himmelen. 
5:29:21: Herren skal skille ham ut fra alle Israels stammer til fordervelse, efter alle forbannelsene i den pakt som er skrevet i denne lovens bok. 
5:29:22: Og når den kommende slekt, eders barn som vokser op efter eder, og den fremmede som kommer fra et fjernt land, ser de plager som har rammet dette land, og de sykdommer som Herren har hjemsøkt det med 
5:29:23: - svovel og salt, hele dets jord opbrent, så den ikke kan tilsåes og ikke gi grøde, og ingen urt kan vokse der, en ødeleggelse som Sodomas og Gomorras, Admas og Sebo'ims, dengang Herren ødela dem i sin vrede og harme - da skal de si, 
5:29:24: ja, alle folkene skal si: Hvorfor har Herren gjort således mot dette land? Hvad er dette for en svær vredes-ild? 
5:29:25: Og de skal få til svar: Fordi de forlot den pakt som Herren, deres fedres Gud, gjorde med dem da han førte dem ut av Egyptens land, 
5:29:26: og de gikk bort og dyrket andre guder og tilbad dem, guder som de ikke kjente, og som han ikke hadde gitt dem lov til å dyrke, 
5:29:27: derfor blev Herrens vrede optendt mot dette land, så han førte over det alle de forbannelser som er skrevet i denne bok; 
5:29:28: Herren rykket dem op av deres land i vrede og harme og stor forbitrelse og kastet dem bort til et fremmed land, som det kan sees på denne dag. 
5:29:29: Det skjulte hører Herren vår Gud til, men det åpenbarte er for oss og for våre barn til evig tid, forat vi skal holde alle ordene i denne lov. 
5:30:1: Når da alle disse ting kommer over dig, velsignelsen og forbannelsen som jeg har forelagt dig, og du tar det til hjerte ute blandt alle de folk som Herren din Gud har drevet dig bort til, 
5:30:2: og du av alt ditt hjerte og all din sjel omvender dig til Herren din Gud og hører på hans røst i alt det som jeg byder dig idag, både du og dine barn, 
5:30:3: da skal Herren din Gud gjøre ende på ditt fangenskap og miskunne sig over dig, og han skal atter samle dig fra alle de folk som Herren din Gud hadde spredt dig iblandt. 
5:30:4: Om I enn er drevet bort til himmelens ende, skal Herren din Gud samle dig og hente dig derifra. 
5:30:5: Og Herren din Gud skal føre dig til det land dine fedre eide, og du skal ta det i eie; og han skal gjøre vel imot dig og gjøre dig tallrikere enn dine fedre. 
5:30:6: Og Herren din Gud skal omskjære ditt hjerte og dine efterkommeres hjerte, så du elsker Herren din Gud av alt ditt hjerte og av all din sjel, og da skal du få leve. 
5:30:7: Og Herren din Gud skal legge alle disse forbannelser på dine fiender og på dem som har hatet og forfulgt dig. 
5:30:8: Men du skal atter høre på Herrens røst og holde alle hans bud, som jeg gir dig idag. 
5:30:9: Og Herren din Gud skal gi dig overflod på det som godt er, i alt det som du tar dig fore, både av ditt livs frukt og av frukten av ditt fe og av frukten av din jord; for Herren skal atter glede sig over dig og gjøre vel imot dig, likesom han gledet sig over dine fedre, 
5:30:10: så sant du hører på Herrens, din Guds røst, så du tar vare på hans bud og hans lover, det som er skrevet i denne lovens bok, og så sant du omvender dig til Herren din Gud av alt ditt hjerte og av all din sjel. 
5:30:11: For dette bud som jeg gir dig idag, er ikke for høit for dig, og det er ikke langt borte. 
5:30:12: Det er ikke oppe i himmelen, så du måtte si: Hvem vil fare op til himmelen for oss og hente det ned til oss og la oss høre det, så vi kan gjøre efter det? 
5:30:13: Det er heller ikke på den andre side av havet, så du måtte si: Hvem vil fare over havet for oss og hente det til oss og la oss høre det, så vi kan gjøre efter det? 
5:30:14: Men ordet er dig meget nær, i din munn og i ditt hjerte, så du kan gjøre efter det. 
5:30:15: Se, jeg har idag lagt frem for dig livet og det gode, og døden og det onde, 
5:30:16: idet jeg idag byder dig å elske Herren din Gud, å vandre på hans veier og ta vare på hans bud og hans lover og hans forskrifter, så du kan leve og bli tallrik, og Herren din Gud kan velsigne dig i det land du kommer inn i og skal ta i eie. 
5:30:17: Men dersom ditt hjerte vender sig bort, og du ikke vil lyde, men lar dig forføre og tilbeder andre guder og dyrker dem, 
5:30:18: da forkynner jeg eder idag at I skal gå helt til grunne; I skal ikke leve lenge i det land som du nu drar til over Jordan for å komme inn i det og ta det i eie. 
5:30:19: Jeg tar idag himmelen og jorden til vidne mot eder: Livet og døden har jeg lagt frem for dig, velsignelsen og forbannelsen; velg da livet, så skal du få leve, du og din ætt! 
5:30:20: Elsk Herren din Gud, hør på hans røst og hold fast ved ham! For dette er ditt liv, og da skal du nå en høi alder og få bli i det land som Herren tilsvor dine fedre Abraham, Isak og Jakob å ville gi dem. 
5:31:1: Og Moses gikk frem og talte disse ord til hele Israel - 
5:31:2: han sa til dem: Jeg er idag hundre og tyve år gammel, jeg kan ikke mere gå ut og inn som før, og Herren har sagt til mig: Du skal ikke komme over Jordan her. 
5:31:3: Herren din Gud vil selv dra frem foran dig, han vil utrydde disse folk for dig, så du kan ta deres land i eie; Josva han skal dra frem foran dig, således som Herren har sagt. 
5:31:4: Og Herren skal gjøre med dem som han gjorde med Sihon og Og, amorittenes konger, dengang han gjorde ende på dem og ødela deres land. 
5:31:5: Og Herren skal gi dem i eders vold; og I skal gjøre med dem aldeles som jeg har befalt eder. 
5:31:6: Vær frimodige og sterke, frykt ikke og reddes ikke for dem! For Herren din Gud går selv med dig, han skal ikke slippe dig og ikke forlate dig. 
5:31:7: Og Moses kalte Josva til sig og sa til ham så hele Israel hørte det: Vær frimodig og sterk! For du skal dra inn med dette folk i det land som Herren tilsvor deres fedre å ville gi dem; og du skal skifte det ut til arv mellem dem. 
5:31:8: Og Herren, han som drar foran dig, han skal være med dig - han skal ikke slippe dig og ikke forlate dig; du skal ikke frykte og ikke reddes. 
5:31:9: Så skrev Moses op denne lov og overgav den til prestene, Levis sønner, som bar Herrens pakts-ark, og til alle de eldste i Israel. 
5:31:10: Og Moses bød dem og sa: Hvert syvende år, i eftergivelsesåret, på løvsalenes høitid, 
5:31:11: når hele Israel møter frem for å vise sig for Herrens, din Guds åsyn på det sted han utvelger, da skal du lese op denne lov for hele Israel så de hører på det. 
5:31:12: Kall folket sammen, mennene og kvinnene og barna og de fremmede som bor i dine byer, så de kan høre det og lære å frykte Herren eders Gud og akte vel på å holde alle ordene i denne lov, 
5:31:13: og deres barn som ikke kjenner den, kan få høre den og lære å frykte Herren eders Gud alle de dager I lever i det land som I nu drar til over Jordan og skal ta i eie. 
5:31:14: Og Herren sa til Moses: Se, nu lider det mot den dag du skal dø; kall på Josva og still eder frem ved sammenkomstens telt, forat jeg kan gi ham mine befalinger. Og Moses og Josva gikk frem og stilte sig ved sammenkomstens telt. 
5:31:15: Da åpenbarte Herren sig ved teltet i en skystøtte, og skystøtten stod ved inngangen til teltet. 
5:31:16: Og Herren sa til Moses: Når du hviler hos dine fedre, da vil dette folk komme til å holde sig med de fremmede guder i det land de kommer inn i, og de vil forlate mig og bryte min pakt, som jeg har gjort med dem. 
5:31:17: Den dag skal min vrede optendes mot dem, og jeg vil forlate dem og skjule mitt åsyn for dem, så de går til grunne, og mangfoldige ulykker og trengsler kommer over dem. Og de skal si på den dag: Er det ikke fordi min Gud ikke er med mig at disse ulykker er kommet over mig? 
5:31:18: Men jeg vil på den dag skjule mitt åsyn for dem, fordi de har gjort så meget ondt og vendt sig til andre guder. 
5:31:19: Så skriv nu op for eder denne sang* og lær Israels barn den, legg den i deres munn, så den kan være et vidne for mig mot Israels barn. # <* 5MO 32, 1 fg.> 
5:31:20: For jeg vil føre dem inn i det land som jeg har tilsvoret deres fedre, et land som flyter med melk og honning, og når de har ett og er blitt mette og fete, da skal de vende sig til andre guder og dyrke dem og forakte mig og bryte min pakt. 
5:31:21: Når da mange ulykker og trengsler kommer over dem, da skal denne sang lyde for dem som et vidne - den skal ikke glemmes hos dem som kommer efter, og ikke dø i deres munn. For jeg kjenner de tanker som de går med allerede nu, før jeg fører dem inn i det land jeg har tilsvoret dem. 
5:31:22: Samme dag skrev Moses op denne sang og lærte Israels barn den. 
5:31:23: Og han* bød Josva, Nuns sønn, og sa: Vær frimodig og sterk! For du skal føre Israels barn inn i det land jeg har tilsvoret dem, og jeg vil være med dig. # <* Herren.> 
5:31:24: Da Moses var ferdig med å skrive denne lovs ord i en bok helt til enden, 
5:31:25: bød han levittene, som bar Herrens pakts-ark, og sa: 
5:31:26: Ta denne lovens bok og legg den ved siden av Herrens, eders Guds pakts-ark! Der skal den ligge som et vidne mot dig. 
5:31:27: For jeg kjenner din gjenstridighet og din stive nakke; se, ennu idag mens jeg lever og er iblandt eder, har I vært gjenstridige mot Herren; hvor meget mere da efter min død! 
5:31:28: Kall nu sammen til mig alle de eldste i eders stammer og eders tilsynsmenn, så vil jeg tale disse ord for dem og ta himmelen og jorden til vidne mot dem. 
5:31:29: For jeg vet at efter min død vil I forderve eders vei og vike av fra den vei jeg har befalt eder å vandre, og ulykken skal komme over eder i de siste dager, fordi I gjør det som er ondt i Herrens øine, og egger ham til vrede med eders henders verk. 
5:31:30: Derefter fremsa Moses denne sang, fra begynnelsen til enden, for hele Israels menighet. 
5:32:1: Lytt, I himler, og jeg vil tale, og jorden høre på min munns ord! 
5:32:2: Som regnet risle min lære, som duggen dryppe mitt ord, som regnskur på grønne spirer, som byger på urter og gress! 
5:32:3: For Herrens navn vil jeg forkynne; gi vår Gud ære! 
5:32:4: Klippen! - fullkomment er hans verk, for rettferd er alle hans veier; en trofast Gud, uten svik, rettferdig og rettvis er han. 
5:32:5: Skulde han ha ført fordervelse over sitt folk! Nei, hans barn de har skammen - en vanartet og vrang slekt. 
5:32:6: Lønner I Herren således, du dårlige og uvise folk? Er han ikke din far, som gjorde dig til sin eiendom? Han skapte dig og dannet dig. 
5:32:7: Kom de eldgamle dager i hu, gi akt på årene fra slekt til slekt! Spør din far, han vil kunngjøre dig det - dine gamle, de vil si dig det. 
5:32:8: Da den Høieste gav folkene arv, da han skilte menneskenes barn, satte han folkenes landemerker efter tallet på Israels barn. 
5:32:9: For Herrens del er hans folk, Jakob er hans arvelodd. 
5:32:10: Han fant ham i et øde land, i villmarken, blandt ørkenens hyl; han vernet om ham, han våket over ham, han voktet ham som sin øiesten. 
5:32:11: Som ørnen vekker sitt rede og svever over sine unger, således bredte han ut sine vinger, tok ham op og bar ham på sine slagfjær. 
5:32:12: Det var Herren alene som førte ham, og ingen fremmed gud var med ham. 
5:32:13: Han lot ham fare frem over jordens høider, og han åt markens grøde, og han lot ham suge honning av klippen og olje av hårdeste sten, 
5:32:14: rømme av kyr og melk av får og fett av lam og av vær fra Basan og av bukker og hvetens feteste marg; og druers blod drakk du, skummende vin. 
5:32:15: Da blev Jesurun* fet og slo bak ut - du blev fet og tykk og stinn; han forlot Gud, som hadde skapt ham, og foraktet sin frelses klippe. # <* d.e. det opriktige, rettskafne folk; så kalles Israel fordi det skulde tjene Herren i opriktighet.> 
5:32:16: De vakte hans nidkjærhet ved fremmede guder; ved vederstyggelige avguder vakte de hans harme. 
5:32:17: De ofret til maktene*, som ikke er Gud, til guder som de ikke kjente, nye og nyss opkommet, som eders fedre ikke reddedes for. # <* d.e. avgudene.> 
5:32:18: Klippen, ditt ophav, enset du ikke; du glemte Gud, han som fødte dig. 
5:32:19: Og Herren så det og forkastet dem, han harmedes over sine sønner og sine døtre. 
5:32:20: Og han sa: Jeg vil skjule mitt åsyn for dem, jeg vil se hvad ende det vil ta med dem; for en vrang slekt er de, barn i hvem det ingen troskap er. 
5:32:21: De vakte min nidkjærhet ved det som ikke er Gud, de vakte min harme ved sine tomme avguder. Også jeg vil vekke deres nidkjærhet ved det som ikke er et folk; ved et uforstandig folk vil jeg vekke deres harme. 
5:32:22: For en ild er optendt i min vrede og brenner til dypeste dødsrike; den fortærer landet og dets grøde og setter fjellenes grunnvoller i brand. 
5:32:23: Jeg vil samle ulykker over dem; alle de piler jeg har, vil jeg bruke imot dem. 
5:32:24: De skal utsuges av sult og fortæres av brennende feber og giftig sott; villdyrs tann vil jeg sende imot dem og edder av ormen som kryper i støvet. 
5:32:25: Ute skal sverdet, inne i kammerne redsel bortrive både unge menn og jomfruer, det diende barn og den gråhårede mann. 
5:32:26: Jeg vilde ha sagt: Jeg vil blåse dem bort, jeg vil slette ut minnet om dem blandt menneskene, 
5:32:27: dersom jeg ikke hadde fryktet for at fiendene skulde krenke mig, at deres motstandere skulde mistyde det og si: Det var vår hånd som var så sterk; det var ikke Herren som gjorde alt dette. 
5:32:28: For de er et folk uten visdom; der er ikke forstand hos dem. 
5:32:29: Dersom de var vise, vilde de forstå dette, skjønne hvad ende det vil ta med dem. 
5:32:30: Hvorledes kunde én forfølge tusen, og to drive ti tusen på flukt, hvis ikke deres klippe hadde solgt dem, og Herren overgitt dem? 
5:32:31: For deres klippe er ikke som vår klippe - det kan våre fiender selv vidne! 
5:32:32: For deres vin-tre er av Sodomas vin-tre og fra Gomorras marker; deres druer er giftige druer, de har beske klaser. 
5:32:33: Deres vin er slangers brennende gift og fryktelig ormeedder. 
5:32:34: Er ikke dette gjemt hos mig, under segl i mine forrådskammer? 
5:32:35: Mig hører hevn og gjengjeldelse til på den tid da deres fot vakler; for deres undergangs dag er nær, og hastig kommer det som venter dem. 
5:32:36: For Herren skal dømme sitt folk, og det skal gjøre ham ondt for sine tjenere, når han ser at deres makt er borte, og at det er ute både med store og små. 
5:32:37: Og han skal si: Hvor er deres guder - klippen som de satte sin lit til - 
5:32:38: de som åt deres slaktoffers fett og drakk deres drikkoffers vin? La dem reise sig og hjelpe eder! La dem være eders vern! 
5:32:39: Nu ser I at det er mig, og at der er ingen Gud foruten mig. Jeg gjør død og gjør levende, jeg sårer, og jeg læger, og det er ingen som redder av min hånd. 
5:32:40: For jeg løfter min hånd mot himmelen og sier: Så sant jeg lever til evig tid: 
5:32:41: Når jeg hvesser mitt lynende sverd og tar dommen i min hånd, da vil jeg føre hevn over mine fiender og gjengjelde dem som hater mig; 
5:32:42: jeg vil gjøre mine piler drukne av blod, og mitt sverd skal ete kjøtt, blod av falne og fangne, av fiendtlige høvdingers hode. 
5:32:43: Pris, I hedninger, hans folk! For han hevner sine tjeneres blod; over sine fiender fører han hevn og gjør soning for sitt land, for sitt folk. 
5:32:44: Så kom da Moses og fremsa hele denne sang for folket, han og Hosea, Nuns sønn. 
5:32:45: Og da Moses var ferdig med å tale alle disse ord til hele Israel, 
5:32:46: sa han til dem: Akt på alle de ord som jeg idag gjør til et vidne mot eder, og byd eders barn å ta vare på dem, så de holder alle ordene i denne lov! 
5:32:47: For dette er ikke noget tomt ord for eder, men det er eders liv, og ved dette ord skal I leve lenge i det land I nu drar til over Jordan og skal ta i eie. 
5:32:48: Samme dag talte Herren til Moses og sa: 
5:32:49: Gå op på Abarim-fjellet her, på Nebo-fjellet i Moabs land midt imot Jeriko, og se ut over Kana'ans land, som jeg gir Israels barn til eiendom, 
5:32:50: Og der på fjellet som du går op på, skal du dø og samles til dine fedre, likesom Aron, din bror, døde på fjellet Hor og blev samlet til sine fedre, 
5:32:51: fordi I syndet mot mig blandt Israels barn ved Meribas vann i Kades i ørkenen Sin og ikke helliget mig blandt Israels barn. 
5:32:52: Du skal få se landet midt foran dig, men du skal ikke komme inn i det land som jeg gir Israels barn. 
5:33:1: Dette er den velsignelse som Moses, den Guds mann, lyste over Israels barn før sin død. 
5:33:2: Han sa: Herren kom fra Sinai, han steg op for dem fra Se'ir; han strålte frem fra Parans fjell og kom fra hellige titusener; ved hans høire hånd lyste lovens ild* for dem. # <* den ild som ledsaget lovgivningen.> 
5:33:3: Ja, han elsker sitt folk; alle dine* hellige er i din hånd; de ligger for din fot, de tar imot dine ord. # <* Guds.> 
5:33:4: En lov gav Moses oss, en arvedel for Jakobs menighet. 
5:33:5: Og han* blev konge i Jesurun**, da folkets høvdinger samlet sig, Israels stammer alle sammen. # <* Gud.> # <** 5MO 32, 15.> 
5:33:6: Måtte Ruben leve og aldri dø, men hans menn bli få i tall! 
5:33:7: Og dette sa han om Juda: Hør, Herre, Judas røst, og før ham hjem til sitt folk! Med sine hender strider han for det, og du skal være hans hjelp mot hans fiender. 
5:33:8: Og om Levi sa han: Dine tummim og urim hører din fromme mann til, han som du fristet ved Massa, som du trettet med ved Meribas vann, 
5:33:9: han som sa om sin far og sin mor: Jeg ser dem ikke, og som ikke kjentes ved sine brødre og ikke visste av sine barn, fordi han tok vare på ditt ord og aktet vel på din pakt. 
5:33:10: Han skal lære Jakob dine bud og Israel din lov, han skal legge røkelse for ditt ansikt og heloffer på ditt alter. 
5:33:11: Velsign, Herre, hans kraft, og la hans henders gjerning tekkes dig! Knus lendene på hans motstandere og dem som hater ham, så de ikke reiser sig mere! 
5:33:12: Om Benjamin sa han: Herrens elskede er han, trygt bor han hos ham; hele dagen holder han sin hånd over ham - han hviler mellem hans skuldrer. 
5:33:13: Og om Josef sa han: Velsignet av Herren være hans land med himmelens ypperste gaver, med dugg og med vann fra det store dyp der nede, 
5:33:14: med det ypperste av det som solen fostrer, med det ypperste av det som måneskiftene driver frem, 
5:33:15: med det herligste fra de eldgamle fjell og det ypperste fra de evige hauger, 
5:33:16: med det ypperste av jorden og dens fylde og med nåde fra ham som bodde i tornebusken; det komme over Josefs hode, over hans isse som er fyrste blandt sine brødre! 
5:33:17: Herlig er den førstefødte av hans okser*, og hans horn er som villoksens horn; med dem stanger han alle folkene like til jordens ender; det er Efra'ims titusener, det er Manasses tusener. # <* d.e. stammens kraftigste krigere.> 
5:33:18: Og om Sebulon sa han: Gled dig, Sebulon, i din utferd, og du, Issakar, i dine telt! 
5:33:19: Folkeslag kaller de til sitt fjell; der ofrer de rettferdighets offere; for havets overflod og sandens skjulte skatter suger de inn. 
5:33:20: Og om Gad sa han: Lovet være han som gir Gad vidt rum! Som en løvinne har han lagt sig ned, og han sønderriver både arm og isse. 
5:33:21: Han utså sig den første lodd, for der er den del gjemt som lovgiveren har tiltenkt ham; og så drog han frem foran folket; det som var rett for Herren, det som han hadde fastsatt, gjorde han, sammen med Israel. 
5:33:22: Og om Dan sa han: Dan er en løveunge som springer frem fra Basan. 
5:33:23: Og om Naftali sa han: Naftali, mettet med nåde og fylt med Herrens velsignelse - Vesten og Syden ta han i eie! 
5:33:24: Og om Aser sa han: Velsignet fremfor sønner være Aser! han være den kjæreste blandt sine brødre og dyppe i olje sin fot! 
5:33:25: Av jern og kobber være din lås, og din hvile så lang som dine dager! 
5:33:26: Det er ingen som Gud, Jesurun! - Han farer frem over himmelen med hjelp for dig, og i sin høihet på skyene. 
5:33:27: En bolig er den eldgamle Gud, og her nede er evige armer; han driver fienden bort for dig og sier: Rydd ut! 
5:33:28: Og Israel bor trygt for sig selv, Jakobs øie er vendt mot et land med korn og most, ja, hans himmel drypper av dugg. 
5:33:29: Salig er du, Israel! Hvem er som du, et folk som har sin frelse i Herren, ditt hjelpende skjold og ditt høie sverd! Dine fiender hykler for dig, mens du skrider frem over deres høider. 
5:34:1: Så gikk Moses fra Moabs ødemarker op på Nebo-fjellet, på Pisgas topp, som ligger midt imot Jeriko; og Herren lot ham se ut over hele landet: Gilead like til Dan 
5:34:2: og hele Naftalis og Efra'ims og Manasses land og hele Juda land like til havet i vest 
5:34:3: og sydlandet og Jordan-sletten, dalen ved Jeriko - Palmestaden - like til Soar. 
5:34:4: Og Herren sa til ham: Dette er det land jeg har tilsvoret Abraham, Isak og Jakob idet jeg sa: Din ætt vil jeg gi det. Nu har jeg latt dig skue det med dine øine, men du skal ikke få komme inn i det. 
5:34:5: Så døde Moses, Herrens tjener, der i Moabs land efter Herrens ord. 
5:34:6: Og han begravde ham i dalen i Moabs land midt imot Bet-Peor, og ingen kjenner til denne dag hans grav. 
5:34:7: Moses var hundre og tyve år gammel da han døde; hans øie var ikke sløvet, og hans kraft var ikke veket bort. 
5:34:8: Og Israels barn gråt over Moses på Moabs ødemarker i tretti dager; da var gråtens dager til ende, sorgen over Moses. 
5:34:9: Og Josva, Nuns sønn, var fylt med visdoms ånd, for Moses hadde lagt sine hender på ham; og Israels barn var lydige mot ham og gjorde som Herren hadde befalt Moses. 
5:34:10: Men det stod ikke mere frem nogen profet i Israel som Moses, han som Herren kjente åsyn til åsyn - 
5:34:11: når en kommer i hu alle de tegn og under Herren hadde sendt ham til å gjøre i Egyptens land, med Farao og alle hans tjenere og hele hans land, 
5:34:12: og hele den veldige kraft og alle de store, forferdelige gjerninger som Moses gjorde for hele Israels øine. 
6:1:1: Efter Moses', Herrens tjeners død sa Herren til Josva, Nuns sønn, Moses' tjener: 
6:1:2: Moses, min tjener, er død; så gjør dig nu rede og dra over Jordan her med hele dette folk til det land som jeg vil gi Israels barn! 
6:1:3: Hvert sted I setter eders fot på, gir jeg eder, som jeg sa til Moses: 
6:1:4: Fra ørkenen og Libanon der nord like til den store elv, elven Frat - over hele hetittenes land og helt til det store hav i vest skal eders land nå. 
6:1:5: Ingen skal kunne stå sig mot dig alle ditt livs dager; likesom jeg var med Moses, vil jeg være med dig; jeg vil ikke slippe dig og ikke forlate dig. 
6:1:6: Vær frimodig og sterk! For du skal skifte ut til arv blandt dette folk det land som jeg tilsvor deres fedre å ville gi dem. 
6:1:7: Vær du bare riktig frimodig og sterk, så du akter vel på å gjøre efter hele den lov som Moses, min tjener, lærte dig! Vik ikke av fra den, hverken til høire eller til venstre, så du kan gå viselig frem i alt det du tar dig fore! 
6:1:8: Denne lovens bok skal ikke vike fra din munn, men du skal grunde på den dag og natt, så du akter vel på å gjøre efter alt det som står skrevet i den; da skal du ha lykke på dine veier, og da skal du gå viselig frem. 
6:1:9: Har jeg ikke befalt dig: Vær frimodig og sterk, frykt ikke og reddes ikke? For Herren din Gud er med dig i alt det du tar dig fore. 
6:1:10: Da bød Josva folkets tilsynsmenn og sa: 
6:1:11: Gå midt igjennem leiren og byd folket og si: Lag i stand reisekost for eder! For om tre dager skal I gå over Jordan her, så I kan komme og innta det land som Herren eders Gud gir eder til eie. 
6:1:12: Og til rubenittene og gadittene og den halve Manasse stamme sa Josva: 
6:1:13: Kom i hu det som Moses, Herrens tjener, bød eder da han sa: Herren eders Gud lar eder nu komme til ro og gir eder dette land. 
6:1:14: Eders hustruer, eders barn og eders fe skal bli i det land Moses har gitt eder østenfor Jordan; men selv skal I, så mange av eder som er djerve stridsmenn, dra fullt rustet frem foran eders brødre og hjelpe dem, 
6:1:15: inntil Herren lar eders brødre komme til ro likesom eder, og også de har tatt det land i eie som Herren eders Gud gir dem; da skal I vende tilbake og bosette eder i eders eget land, det som Moses, Herrens tjener, gav eder på østsiden av Jordan. 
6:1:16: Da svarte de Josva og sa: Alt det du har befalt oss, vil vi gjøre, og hvor du sender oss, vil vi gå. 
6:1:17: Aldeles som vi lød Moses, vil vi lyde dig, bare Herren din Gud må være med dig, likesom han var med Moses. 
6:1:18: Enhver som er gjenstridig mot din befaling og ikke lyder dine ord i alt det du byder ham, han skal dø. Vær bare frimodig og sterk! 
6:2:1: Josva, Nuns sønn, sendte hemmelig to speidere fra Sittim og sa: Gå avsted og se eder om i landet og særlig i Jeriko! De gikk avsted og kom inn i huset til en skjøge som hette Rahab, og de lå der om natten. 
6:2:2: Da blev det meldt til kongen i Jeriko: Nogen menn av Israels barn er kommet hit i natt for å utspeide landet. 
6:2:3: Så sendte kongen i Jeriko bud til Rahab og lot si: Kom hit med de menn som kom til dig og tok inn i ditt hus! De er kommet for å utspeide hele landet. 
6:2:4: Men kvinnen tok og skjulte de to menn, og så sa hun: Det er så at disse menn kom til mig, men jeg visste ikke hvor de var fra; 
6:2:5: og i mørkningen da porten skulde lukkes, gikk mennene ut; jeg vet ikke hvor de tok veien. Skynd eder og sett efter dem, så når I dem nok igjen. 
6:2:6: Men hun hadde latt dem stige op på taket og skjult dem under linstenglene som hun hadde liggende utbredt der på taket. 
6:2:7: Så satte mennene efter dem på veien til Jordan, til vadestedene; og porten blev lukket, så snart de som satte efter dem, var gått ut. 
6:2:8: Før speiderne hadde lagt sig, gikk hun op til dem på taket, 
6:2:9: og hun sa til dem: Jeg vet at Herren har gitt eder landet, og at redsel for eder er falt på oss, og at alle landets innbyggere forgår av angst for eder. 
6:2:10: For vi har hørt at Herren tørket ut vannet i det Røde Hav foran eder da I drog ut av Egypten, og vi har hørt hvad I gjorde med begge amoritter-kongene på hin side Jordan, Sihon og Og, som I slo med bann. 
6:2:11: Da vi hørte det, blev vårt hjerte fullt av angst, og nu er det ikke mere nogen som har mot til å møte eder; for Herren eders Gud han er Gud både i himmelen der oppe og på jorden her nede. 
6:2:12: Så sverg mig nu til ved Herren, siden jeg har vist barmhjertighet mot eder, at også I vil vise barmhjertighet mot min fars hus, og gi mig således et tegn på eders ærlighet, 
6:2:13: og la min far og min mor og mine brødre og mine søstre og alle som hører dem til, leve, og frels vårt liv fra døden! 
6:2:14: Da sa mennene til henne: Med vårt eget liv skal vi svare for eders liv! Så sant I ikke lar nogen få vite noget om vårt ærend, skal vi, når Herren gir oss landet, vise barmhjertighet og trofasthet mot dig. 
6:2:15: Så firte hun dem ned gjennem vinduet med et rep; for hennes hus lå ved byens mur, så hun bodde like ved muren. 
6:2:16: Og hun sa til dem: Gå op i fjellene, så at de som forfølger eder, ikke treffer på eder, og hold eder skjult der i tre dager, til forfølgerne kommer tilbake; siden kan I gå hvor I vil. 
6:2:17: Mennene svarte: Vi holder oss for å være løst fra denne ed som du tok av oss, 
6:2:18: hvis du ikke, når vi kommer inn i landet, binder denne snor av skarlagenfarvet tråd i det vindu som du har firet oss ned igjennem, og så samler din far og din mor og dine brødre og hele din slekt i huset hos dig. 
6:2:19: Enhver som går ut av din husdør, hans blod komme over hans eget hode, og vi er skyldfri; men enhver som er hos dig i huset, hans blod komme over vårt hode, dersom der blir lagt hånd på ham. 
6:2:20: Og dersom du lar nogen få vite noget om vårt ærend, så er vi løst fra den ed du tok av oss. 
6:2:21: Hun sa: La det være som I sier! Så lot hun dem gå, og de drog avsted; siden bandt hun den skarlagenfarvede snor i vinduet. 
6:2:22: De gikk til de kom op i fjellene; der blev de i tre dager, inntil de som forfulgte dem, var vendt tilbake; og forfølgerne lette på hele veien, men fant dem ikke. 
6:2:23: Så vendte de to menn om og gikk ned fra fjellene og satte over elven og kom til Josva, Nuns sønn, og fortalte ham alt det som hadde hendt dem. 
6:2:24: Og de sa til Josva: Herren har gitt hele landet i vår hånd; alle landets innbyggere holder endog på å forgå av angst for oss. 
6:3:1: Morgenen efter stod Josva tidlig op og tok ut fra Sittim med alle Israels barn og kom til Jordan; der blev de en tid før de gikk over. 
6:3:2: Og da tre dager var til ende, gikk tilsynsmennene gjennem leiren, 
6:3:3: og de bød folket og sa: Når I ser Herrens, eders Guds pakts-ark og de levittiske prester i ferd med å bære den, så skal I bryte op fra eders sted og følge efter den 
6:3:4: - dog skal det være en avstand mellem eder og den, omkring to tusen alen; I må ikke komme den for nær - så I kan vite hvad vei I skal gå; for I har ikke draget den vei før. 
6:3:5: Da sa Josva til folket: Hellige eder! For imorgen vil Herren gjøre underfulle ting iblandt eder. 
6:3:6: Og til prestene sa Josva: Ta paktens ark og gå frem foran folket! Og de tok paktens ark og gikk frem foran folket. 
6:3:7: Og Herren sa til Josva: På denne dag vil jeg begynne å gjøre dig stor for hele Israels øine, forat de skal vite at likesom jeg var med Moses, vil jeg og være med dig. 
6:3:8: Og du skal byde prestene som bærer paktens ark, og si: Når I kommer til randen av Jordans vann, så skal I bli stående der ved Jordan. 
6:3:9: Og Josva sa til Israels barn: Kom hit og hør Herrens, eders Guds ord! 
6:3:10: Og Josva sa: På dette skal I kjenne at den levende Gud er midt iblandt eder og visselig skal drive bort for eder kana'anittene og hetittene og hevittene og ferisittene og girgasittene og amorittene og jebusittene. 
6:3:11: Se, han som er all jordens Herre, hans pakts-ark går foran eder ut i Jordan. 
6:3:12: Så velg nu ut tolv menn av Israels stammer, én mann for hver stamme! 
6:3:13: Og så snart prestene som bærer Herrens, all jordens Herres ark, står stille med sine føtter i Jordans vann, da skal Jordans vann - det vann som kommer ovenfra - demmes op, så det står som en vegg. 
6:3:14: Da nu folket brøt op fra sine telt for å gå over Jordan, og prestene bar paktens ark foran folket, 
6:3:15: og de som bar arken, kom til Jordan, og de prester som bar arken, rørte med sine føtter ved den ytterste rand av vannet - men Jordan gikk over alle sine bredder hele høsttiden igjennem - 
6:3:16: da stanset det vann som kom ovenfra, og stod som en vegg langt borte, ved byen Adam, som ligger tett ved Sartan, og det vann som rant ned til ødemarkens hav - Salthavet - løp helt bort, og folket gikk over midt imot Jeriko. 
6:3:17: Men prestene som bar Herrens pakts-ark, stod på tørr grunn midt i Jordan uten å røre sig; og hele Israel gikk tørrskodd over, inntil hele folket var kommet vel over Jordan. 
6:4:1: Da nu hele folket var kommet vel over Jordan, sa Herren til Josva: 
6:4:2: Velg ut tolv menn av folket, én mann av hver stamme, 
6:4:3: og byd dem og si: Ta op her fra Jordans bunn tolv stener, fra det sted hvor prestenes føtter står, og hold dem ferdige, og bær dem med eder over og legg dem ned på det sted hvor I blir denne natt over! 
6:4:4: Da lot Josva de tolv menn kalle som han hadde valgt blandt Israels barn, én mann for hver stamme. 
6:4:5: Og Josva sa til dem: Gå foran Herrens, eders Guds ark ut i Jordan og ta hver sin sten op på skulderen, efter tallet på Israels barns stammer! 
6:4:6: Disse stener skal være et tegn blandt eder. Når eders barn i fremtiden spør: Hvad er dette for stener? 
6:4:7: da skal I si til dem: Jordans vann delte sig foran Herrens pakts-ark; da den drog ut i Jordan, delte Jordans vann sig, og disse stener skal være til et minne om dette for Israels barn til evig tid. 
6:4:8: Israels barn gjorde som Josva bød, og tok tolv stener op av Jordan, således som Herren hadde sagt til Josva, efter tallet på Israels barns stammer, og de bar dem med sig over til det sted hvor de blev natten over, og la dem ned der. 
6:4:9: Og ute i Jordan, på det sted hvor de prester som bar paktens ark, stod med sine føtter, reiste Josva tolv stener op, og de står der den dag idag. 
6:4:10: Prestene som bar paktens ark, blev stående ute i Jordan, til det var skjedd alt det som Herren hadde befalt Josva å si til folket, efter alt det som Moses hadde befalt Josva. Og folket skyndte sig og gikk over. 
6:4:11: Og da hele folket var kommet vel over, drog Herrens ark og prestene frem foran folket. 
6:4:12: Og Rubens barn og Gads barn og den halve Manasse stamme drog fullt rustet frem foran Israels barn, således som Moses hadde sagt til dem. 
6:4:13: Det var omkring firti tusen menn som væbnet til strid drog frem for Herrens åsyn til kamp på Jerikos ødemarker. 
6:4:14: Den dag gjorde Herren Josva stor for hele Israels øine, og de fryktet ham, likesom de hadde fryktet Moses, alle hans livs dager. 
6:4:15: Og Herren sa til Josva: 
6:4:16: Byd prestene som bærer vidnesbyrdets ark, at de skal stige op av Jordan! 
6:4:17: Og Josva bød prestene og sa: Stig op av Jordan! 
6:4:18: Da nu prestene som bar Herrens pakts-ark, steg op av Jordan og hadde satt sine føtter på det tørre land, da vendte Jordans vann tilbake til sitt leie og gikk som før over alle sine bredder. 
6:4:19: Det var den tiende dag i den første måned at folket steg op av Jordan, og de leiret sig ved Gilgal, lengst mot øst i landet omkring Jeriko. 
6:4:20: Og der i Gilgal reiste Josva op de tolv stener som de hadde tatt op av Jordan. 
6:4:21: Og han sa til Israels barn: Når eders barn i fremtiden spør sine fedre: Hvad er dette for stener? 
6:4:22: da skal I fortelle eders barn det og si: Israel gikk tørrskodd over Jordan her, 
6:4:23: fordi Herren eders Gud lot Jordans vann tørke bort foran eder til I kom over, likesom Herren eders Gud gjorde med det Røde Hav, som han lot tørke bort foran oss til vi kom over, 
6:4:24: forat alle folk på jorden skal kjenne at Herrens hånd er sterk, og forat I alle dager skal frykte Herren eders Gud. 
6:5:1: Da nu alle amoritter-kongene som bodde på vestsiden av Jordan, og alle kana'anitter-kongene som bodde ved havet, hørte at Herren hadde latt Jordans vann tørke bort foran Israels barn inntil vi var kommet over, da blev deres hjerte fullt av angst, og de hadde ikke lenger mot til å møte Israels barn. 
6:5:2: På denne tid sa Herren til Josva: Gjør dig stenkniver og omskjær atter Israels barn, for annen gang! 
6:5:3: Da gjorde Josva sig stenkniver og omskar Israels barn ved Ha'aralot-haugen*. # <* forhudenes haug.> 
6:5:4: Grunnen til at Josva omskar dem, var denne: Alt det folk som drog ut av Egypten - alle mennene, alle krigsfolkene - var død i ørkenen på veien fra Egypten. 
6:5:5: Alt det folk som drog ut, var omskåret; men alt det folk som var født i ørkenen på veien fra Egypten, var ikke blitt omskåret. 
6:5:6: For i firti år vandret Israels barn i ørkenen, inntil alt folket - krigsfolkene som drog ut av Egypten - var utdød; de hørte ikke på Herrens røst, derfor svor Herren at han ikke vilde la dem se det land Herren hadde tilsvoret deres fedre å ville gi oss, et land som flyter med melk og honning. 
6:5:7: Men deres barn, som han hadde latt vokse op i deres sted, dem omskar Josva; for de hadde forhud, da de ikke var blitt omskåret på veien. 
6:5:8: Da hele folket således var blitt omskåret, holdt de sig i ro der hvor de var i leiren, til de blev friske igjen. 
6:5:9: Og Herren sa til Josva: Idag har jeg veltet av eder skammen fra Egypten. Siden har dette sted vært kalt Gilgal* like til denne dag. # <* avveltning.> 
6:5:10: Mens Israels barn lå i leir ved Gilgal, holdt de påske den fjortende dag i måneden om aftenen på Jerikos ødemarker. 
6:5:11: Og dagen efter påsken åt de av landets grøde; usyret brød og ristet korn åt de den dag. 
6:5:12: Og mannaen hørte op dagen efter*, for nu åt de av landets grøde; Israels barn fikk ikke mere manna, men de åt det år av det som var avlet i Kana'ans land. # <* nemlig efter påsken.> 
6:5:13: Mens Josva var ved Jeriko, hendte det at han løftet op sine øine og fikk se en mann som stod foran ham med et draget sverd i sin hånd; og Josva gikk bort til ham og sa: Hører du til oss eller til våre fiender? 
6:5:14: Han svarte: Nei, jeg er høvdingen over Herrens hær; nu er jeg kommet. Da falt Josva på sitt ansikt til jorden og tilbad og sa til ham: Hvad har min herre å si til sin tjener? 
6:5:15: Og høvdingen over Herrens hær sa til Josva: Dra skoen av din fot! For det sted du står på, er hellig. Og Josva gjorde så. 
6:6:1: Jeriko holdt sine porter stengt og var fast tillukket for Israels barns skyld; ingen gikk ut, og ingen kom inn. 
6:6:2: Da sa Herren til Josva: Se, jeg har gitt Jeriko med kongen og de djerve stridsmenn i din hånd. 
6:6:3: La nu alle krigsmennene gå omkring byen, rundt om den én gang! Således skal du gjøre i seks dager; 
6:6:4: og syv prester skal bære syv larmbasuner foran arken; men den syvende dag skal I gå syv ganger omkring byen, og prestene skal støte i basunene. 
6:6:5: Og når de blåser i larmhornet med lange toner, og I hører basunklangen, skal hele folket sette i et stort skrik; da skal byens mur falle ned der hvor den står, så folket kan stige inn over den, enhver rett frem for sig. 
6:6:6: Da kalte Josva, Nuns sønn, prestene til sig og sa til dem: I skal bære paktens ark, og syv prester skal bære syv larmbasuner foran Herrens ark. 
6:6:7: Og til folket sa han: Dra frem og gå omkring byen, og de væbnede menn skal dra frem foran Herrens ark. 
6:6:8: Da Josva hadde sagt dette til folket, gikk de frem de syv prester som bar de syv larmbasuner for Herrens åsyn; de støtte i basunene, og Herrens pakts-ark fulgte efter dem. 
6:6:9: Og de væbnede menn gikk foran prestene som støtte i basunene, og de som endte toget, gikk efter arken, mens der ustanselig støttes i basunene. 
6:6:10: Og Josva bød folket og sa: I skal ikke opløfte hærrop og ikke la eders røst høre; det skal ikke gå et ord ut av eders munn før den dag jeg sier til eder: Rop nu! Da skal I rope. 
6:6:11: Så gikk Herrens ark omkring byen, rundt om den, én gang; derefter drog de inn i leiren og blev der natten over. 
6:6:12: Morgenen efter stod Josva tidlig op, og prestene bar Herrens ark. 
6:6:13: Og de syv prester som bar de syv larmbasuner foran Herrens ark, gikk og støtte ustanselig i basunene, og de væbnede menn gikk foran dem, og de som endte toget, gikk efter Herrens ark, mens der ustanselig støttes i basunene. 
6:6:14: Således gikk de den annen dag én gang omkring byen og vendte så tilbake til leiren; dette gjorde de i seks dager. 
6:6:15: Den syvende dag stod de tidlig op, med det samme det tok til å dages, og gikk syv ganger omkring byen; de bar sig at på samme vis som før, bare at de denne dag gikk syv ganger omkring byen. 
6:6:16: Og da prestene den syvende gang støtte i basunene, da sa Josva til folket: Rop nu! For Herren har gitt eder byen. 
6:6:17: Og byen med alt det som i den er, skal være vidd til Herren og bannlyst, bare skjøgen Rahab skal få leve, med alle som er i huset hos henne, fordi hun skjulte de menn vi hadde utsendt. 
6:6:18: Men ta eder vel i vare for det som er bannlyst, at I ikke først slår med bann og så tar av det som er bannlyst, og således legger Israels leir under bann og fører den i ulykke. 
6:6:19: Men alt sølv og gull og alle ting som er av kobber og jern, skal være helliget til Herren og komme i Herrens skattkammer. 
6:6:20: Så ropte folket, og de støtte i basunene; og det skjedde da folket hørte basunklangen, og de satte i et stort skrik, da falt muren helt sammen, og folket steg bent inn i byen og inntok den. 
6:6:21: Og de slo alt det som var i byen, med bann; både menn og kvinner, både unge og gamle, storfe og småfe og asener slo de med sverdets egg. 
6:6:22: Men til de to menn som hadde utspeidet landet, sa Josva: Gå inn i skjøgens hus og før kvinnen og alle dem som hører henne til, ut derfra, således som I har tilsvoret henne. 
6:6:23: De unge menn som hadde vært speidere, gikk da inn og førte Rahab ut av huset og likeså hennes far og mor og brødre og alle som hørte henne til; hele hennes slekt førte de ut og lot dem få opholde sig et sted utenfor Israels leir. 
6:6:24: Men byen med alt det som i den var, brente de op med ild; bare sølvet og gullet og alle ting som var av kobber og jern, la de ned i skattkammeret i Herrens hus. 
6:6:25: Men skjøgen Rahab og hennes fars slekt og alle dem som hørte henne til, lot Josva leve; hun blev boende blandt Israels folk inntil denne dag, fordi hun skjulte de menn som Josva hadde sendt for å utspeide Jeriko. 
6:6:26: Dengang lot Josva folket sverge denne ed: Forbannet for Herrens åsyn være den mann som tar sig fore å bygge op igjen denne by Jeriko! Når han legger dens grunnvoll, skal det koste ham hans førstefødte sønn, og når han setter op dens porter, skal det koste ham hans yngste. 
6:6:27: Og Herren var med Josva, og ryktet om ham kom ut over hele landet. 
6:7:1: Men Israels barn bar sig troløst at med det bannlyste gods. Akan, sønn av Karmi, som var sønn av Sabdi og sønnesønn av Serah, av Juda stamme, tok noget av det som var bannlyst. Da optendtes Herrens vrede mot Israels barn. 
6:7:2: Fra Jeriko sendte Josva nogen menn til Ai, som ligger ved Bet-Aven, østenfor Betel, og sa til dem: Dra op og utspeid landet! Så drog mennene op og utspeidet Ai. 
6:7:3: Og da de kom tilbake til Josva, sa de til ham: La ikke alt folket dra op! Omkring to eller tre tusen mann kan dra op og ta Ai; du trenger ikke å umake hele folket dit, for de er ikke mange. 
6:7:4: Så drog omkring tre tusen mann av folket der op; men de flyktet for mennene i Ai. 
6:7:5: Og Ai-mennene slo ihjel omkring seks og tretti mann av dem og forfulgte dem fra porten like til stenbruddene og hugg dem ned i bakkene; da blev folkets hjerte fullt av angst, og deres mot blev rent borte. 
6:7:6: Og Josva sønderrev sine klær og falt ned på sitt ansikt til jorden foran Herrens ark og blev liggende der helt til aftenen, både han og Israels eldste, og de strødde støv på sitt hode. 
6:7:7: Og Josva sa: Ak Herre, Herre! Hvorfor førte du da dette folk over Jordan når du vilde gi oss i amorittenes hånd og la oss gå til grunne? Gid vi hadde latt oss nøie med å bli på hin side Jordan! 
6:7:8: Hør mig, Herre! Hvad skal jeg nu si, efterat Israel har vendt sine fiender ryggen? 
6:7:9: Når kana'anittene og alle landets innbyggere får høre det, vil de kringsette oss og rydde vårt navn ut av verden; og hvad vil du da gjøre med ditt store navn? 
6:7:10: Da sa Herren til Josva: Stå op! Hvorfor ligger du her på ditt ansikt? 
6:7:11: Israel har syndet og brutt min pakt som jeg har oprettet med dem; de har tatt av det bannlyste gods, de har stjålet og skjult det stjålne, de har gjemt det blandt sine egne ting. 
6:7:12: Derfor kan Israels barn ikke stå sig mot sine fiender, men må flykte for dem; for de er selv kommet under bann; skiller I eder ikke helt av med det bannlyste, vil jeg ikke lenger være med eder. 
6:7:13: Stå op, la folket hellige sig, og si: Hellige eder til imorgen! For så sier Herren, Israels Gud: Der er bannlyst gods hos dig, Israel! Du kan ikke stå dig mot dine fiender før I skiller eder helt av med det bannlyste. 
6:7:14: Imorgen tidlig skal I trede frem stamme for stamme, og den stamme som Herren tar ut, skal trede frem ætt for ætt, og den ætt som Herren tar ut, skal trede frem hus for hus, og det hus som Herren tar ut, skal trede frem mann for mann. 
6:7:15: Og den som det bannlyste blir funnet hos, han skal opbrennes med ild, både han og alt det hans er, fordi han har brutt Herrens pakt og gjort en skjendig gjerning i Israel. 
6:7:16: Morgenen efter stod Josva tidlig op og lot Israel trede frem stamme for stamme, og Juda stamme blev tatt ut. 
6:7:17: Så lot han ættene i Juda trede frem, og serahitt-ætten blev tatt ut; så lot han serahitt-ætten trede frem mann for mann, og Sabdi blev tatt ut; 
6:7:18: så lot han hans hus trede frem mann for mann, og Akan, sønn av Karmi, som var sønn av Sabdi og sønnesønn av Serah, av Juda stamme, blev tatt ut. 
6:7:19: Da sa Josva til Akan: Min sønn! Gi Herren, Israels Gud, ære og pris og si mig hvad du har gjort, dølg det ikke for mig! 
6:7:20: Og Akan svarte Josva og sa: Det er sant, jeg har syndet mot Herren, Israels Gud; således har jeg gjort: 
6:7:21: Jeg så blandt byttet en kostelig babylonisk kappe og to hundre sekel sølv og en gullstang som veide femti sekel; disse ting fikk jeg lyst på og tok dem; de ligger nedgravd i jorden under mitt telt, sølvet underst. 
6:7:22: Da sendte Josva nogen menn dit, og de løp til teltet; og der fant de tingene nedgravd i hans telt, sølvet underst. 
6:7:23: De tok dem ut av teltet og bar dem med sig til Josva og alle Israels barn og la dem frem for Herrens åsyn. 
6:7:24: Og Josva og hele Israel med ham tok Akan, Serahs sønn, og sølvet og kappen og gullstangen og hans sønner og døtre og hans storfe og asener og småfe og hans telt og alt det han hadde, og de førte det op i Akor-dalen*. # <* JOS 7, 26.> 
6:7:25: Og Josva sa: For en ulykke du har ført over oss! Idag skal Herren føre ulykke over dig! Og hele Israel stenet ham, og de opbrente dem med ild og stenet dem. 
6:7:26: Derefter kastet de en stor stenrøs sammen over ham, som er der den dag idag; og Herren vendte om fra sin brennende vrede. Derfor kaltes dette sted Akor-dalen*; det navn har det den dag idag. # <* ulykkens dal.> 
6:8:1: Og Herren sa til Josva: Frykt ikke og vær ikke redd, ta med dig alt krigsfolket, gjør dig rede og dra op mot Ai! Se, jeg har gitt kongen i Ai og hans folk og hans by og hans land i din hånd. 
6:8:2: Og du skal gjøre med Ai og dets konge som du gjorde med Jeriko og dets konge; bare hærfanget og feet der kan I ta for eder selv. Legg nu et bakhold mot byen, på vestsiden! 
6:8:3: Da gjorde Josva og alt krigsfolket sig rede til å dra op mot Ai, og Josva utvalgte tretti tusen mann, djerve stridsmenn, og sendte dem avsted om natten. 
6:8:4: Og han bød dem og sa: Nu skal I legge eder i bakhold mot byen, på vestsiden, ikke meget langt fra byen, og hold eder rede alle sammen! 
6:8:5: Men jeg og alt det folk som er med mig, vil dra nær innimot byen, og når de da drar ut mot oss likesom første gang, vil vi flykte for dem. 
6:8:6: Og de vil dra ut efter oss til vi får lokket dem bort fra byen; for de vil tenke: De flykter for oss likesom første gang. Men mens vi flykter for dem, 
6:8:7: skal I reise eder fra bakholdet og innta byen, og Herren eders Gud skal gi den i eders hånd. 
6:8:8: Og når I har inntatt byen, skal I sette ild på den; I skal gjøre efter Herrens ord. Se, jeg har nu sagt eder hvad I skal gjøre. 
6:8:9: Så sendte Josva dem avsted, og de gikk og la sig i bakhold mellem Betel og Ai, vestenfor Ai; men Josva selv blev den natt midt iblandt folket. 
6:8:10: Morgenen efter stod Josva tidlig op og mønstret folket; og han og Israels eldste drog foran folket op til Ai. 
6:8:11: Og alt krigsfolket som var med ham, drog op og rykket frem til de kom midt foran byen, og de slo leir nordenfor Ai med dalen mellem sig og Ai. 
6:8:12: Så tok han omkring fem tusen mann og la dem i bakhold mellem Betel og Ai, vestenfor byen, 
6:8:13: og de fylket krigsfolket, både hele leiren som var nordenfor byen, og den del som lå i bakhold vestenfor byen, og Josva gikk samme natt midt ned i dalen. 
6:8:14: Da kongen i Ai så dette, skyndte byens menn sig, han selv og hele hans folk, og drog tidlig om morgenen ut til strid mot Israel, til det fastsatte sted foran ødemarken; men han visste ikke at det lå et bakhold mot ham vestenfor byen. 
6:8:15: Og Josva og hele Israel lot sig slå av dem og flyktet bortimot ørkenen. 
6:8:16: Da blev alt folket som var i byen, ropt sammen til å forfølge dem, og de forfulgte Josva og kom således lenger og lenger bort fra byen. 
6:8:17: Det blev ikke en mann tilbake i Ai og Betel; for de drog alle ut efter Israel; de lot byen ligge åpen efter sig og forfulgte Israel. 
6:8:18: Da sa Herren til Josva: Rekk spydet som du har i din hånd, ut mot Ai! For jeg vil gi byen i din hånd! Så rakte Josva spydet som han hadde i sin hånd, ut imot byen. 
6:8:19: Og med det samme han rakte sin hånd ut, reiste bakholdet sig fra sin plass og sprang avsted, og de kom inn i byen og inntok den, og de skyndte sig og satte ild på byen. 
6:8:20: Da nu Ai-mennene vendte sig om, blev de var røken fra byen, som steg op imot himmelen, og de var ikke i stand til å flykte hverken hit eller dit, fordi de krigsfolk som var flyktet til ørkenen, nu vendte sig mot forfølgerne. 
6:8:21: For da Josva og hele Israel så at bakholdet hadde inntatt byen, og at røken steg op fra byen, vendte de om og slo Ai-mennene. 
6:8:22: Og de andre drog ut av byen imot dem, så de kom midt imellem israelittene og fikk dem omkring sig på begge sider. Så slo de* dem og levnet dem ikke nogen som slapp unda eller blev frelst. # <* israelittene.> 
6:8:23: Men kongen i Ai blev tatt levende og ført til Josva. 
6:8:24: Og da Israel hadde slått ihjel alle Ais innbyggere på slagmarken, ute i ørkenen, dit de hadde forfulgt dem, og de alle sammen var falt for sverdets egg til siste mann, da vendte hele Israel tilbake til Ai og slo alle som var der, med sverdets egg. 
6:8:25: Og de som falt på den dag, menn og kvinner, var tolv tusen, alle Ais innbyggere. 
6:8:26: Josva tok ikke til sig igjen hånden som han hadde rakt ut med spydet, før han hadde slått alle Ais innbyggere med bann. 
6:8:27: Bare feet og hærfanget i denne by tok Israel for sig selv efter Herrens ord, som han hadde talt til Josva. 
6:8:28: Og Josva brente op Ai og gjorde den til en grushaug for alle tider, til et øde sted, som den har vært til denne dag. 
6:8:29: Og kongen i Ai lot han henge op på et tre, og der blev han hengende til om aftenen; men da solen gikk ned, bød Josva dem ta hans døde legeme ned av treet og kaste det ved inngangen til byens port, og de kastet en stor stenrøs sammen over ham; den er der den dag idag. 
6:8:30: Ved denne tid bygget Josva et alter for Herren, Israels Gud, på Ebal-fjellet, 
6:8:31: således som Moses, Herrens tjener, hadde befalt Israels barn, efter det som står skrevet i Mose lovbok, et alter av hele stener, som det ikke hadde vært brukt jern på; og på det ofret de brennoffer for Herren og slaktet takkoffer. 
6:8:32: Og han skrev der på stenene en avskrift av Mose lov, den lov som Moses hadde skrevet for Israels barns åsyn. 
6:8:33: Og hele Israel med sine eldste og tilsynsmenn og sine dommere stod på begge sider av arken midt imot de levittiske prester, som bar Herrens pakts-ark, hele Israel, både de fremmede og de innfødte, den ene halvdel av dem mot Garisim-fjellet, og den andre halvdel mot Ebal-fjellet, således som Moses, Herrens tjener, før hadde befalt å velsigne Israels folk. 
6:8:34: Derefter leste han op alle lovens ord, velsignelsen og forbannelsen, i ett og alt således som skrevet er i lovens bok. 
6:8:35: Det var ikke et ord av alt det Moses hadde befalt, som Josva ikke leste op for hele Israels menighet med kvinner og barn og for de fremmede som fulgte med dem. 
6:9:1: Da de nu fikk høre dette alle kongene som bodde vestenfor Jordan, i fjellbygdene og i lavlandet og på hele kysten av det store hav til midt for Libanon - hetittene og amorittene, kana'anittene, ferisittene, hevittene og jebusittene - 
6:9:2: da samlet de sig alle som én for å stride mot Josva og Israel. 
6:9:3: Men da innbyggerne i Gibeon hørte hvad Josva hadde gjort med Jeriko og Ai, 
6:9:4: da gikk de på sin side frem med list: De tok avsted og gav sig ut for sendebud; de la utslitte sekker på sine asener og utslitte, sprukne og bøtede vinsekker; 
6:9:5: de tok utslitte og lappede sko på sine føtter og hadde utslitte klær på sig, og alt brødet de hadde til reisekost, var tørt og smuldret. 
6:9:6: Således drog de til Josva i leiren ved Gilgal og sa til ham og Israels menn: Vi kommer fra et land langt borte; gjør nu en pakt med oss! 
6:9:7: Da sa Israels menn til hevittene: Kanskje bor I her midt iblandt oss; hvorledes kan vi da gjøre nogen pakt med eder? 
6:9:8: Da sa de til Josva: Vi vil være dine tjenere. Og Josva sa til dem: Hvem er I, og hvor kommer I fra? 
6:9:9: De svarte: Dine tjenere kommer fra et land langt, langt borte, for Herrens, din Guds navns skyld; for vi har hørt ryktet om ham og alt det han har gjort i Egypten, 
6:9:10: og alt det han har gjort med begge amoritter-kongene østenfor Jordan, Sihon, kongen i Hesbon, og Og, kongen i Basan, som bodde i Astarot. 
6:9:11: Derfor sa våre eldste og alle vårt lands innbyggere til oss: Ta reisekost med eder og gå dem i møte og si til dem: Vi vil være eders tjenere; gjør nu en pakt med oss! 
6:9:12: Her ser I vårt brød; det var varmt da vi tok det med oss hjemmefra til reisekost den dag vi drog avsted til eder, men se nu hvor tørt og smuldret det er! 
6:9:13: Og her ser I våre vinsekker; de var nye da vi fylte dem, men nu er de sprukket! Og her ser I våre klær og våre sko; de er blitt utslitt på denne lange, lange reise. 
6:9:14: Da tok mennene og smakte på deres reisekost; men Herren spurte de ikke til råds. 
6:9:15: Og Josva tilsa dem fred og gjorde pakt med dem om at de skulde få leve; og menighetens høvdinger tilsvor dem det. 
6:9:16: Men da tre dager var gått efterat de hadde gjort pakt med dem, fikk de vite at de hørte hjemme der i nærheten og bodde midt iblandt dem. 
6:9:17: For da Israels barn brøt op, kom de den tredje dag til deres byer; det var Gibeon og Hakkefira og Be'erot og Kirjat-Jearim. 
6:9:18: Men Israels barn slo dem ikke ihjel, fordi menighetens høvdinger hadde tilsvoret dem fred ved Herren, Israels Gud. Da knurret hele menigheten mot høvdingene; 
6:9:19: men alle høvdingene sa til hele menigheten: Vi har tilsvoret dem fred ved Herren, Israels Gud, og nu kan vi ikke legge hånd på dem. 
6:9:20: Således vil vi gjøre med dem: Vi vil la dem få leve, forat det ikke skal komme vrede over oss for den eds skyld som vi har svoret dem. 
6:9:21: Høvdingene sa altså til dem*: De skal få leve. Så blev de vedhuggere og vannbærere for hele menigheten, således som høvdingene hadde sagt til dem*. # <* Israels menighet.> 
6:9:22: Da kalte Josva dem for sig og sa til dem: Hvorfor narret I oss og sa: Vi bor langt, langt borte fra eder, og så bor I midt iblandt oss? 
6:9:23: Derfor skal I nu være forbannet; ingen av eder skal slippe for å være træl og hugge ved og bære vann til min Guds hus. 
6:9:24: De svarte Josva og sa: Dine tjenere hadde fått vite at Herren din Gud hadde sagt til Moses, sin tjener, at han vilde gi eder hele landet og utrydde alle landets innbyggere for eder; da blev vi så redde for at I skulde ta vårt liv; derfor gjorde vi dette. 
6:9:25: Men nu er vi i din hånd; gjør med oss som det er godt og rett i dine øine! 
6:9:26: Og han gjorde således* med dem; han reddet dem av Israels barns hånd, så de ikke slo dem ihjel; # <* JOS 9, 21.> 
6:9:27: men Josva gjorde dem på den samme dag til vedhuggere og vannbærere for menigheten og for Herrens alter, på det sted han vilde utvelge, og det har de vært til denne dag. 
6:10:1: Da Adoni-Sedek, kongen i Jerusalem, fikk høre at Josva hadde inntatt Ai og slått det med bann og gjort likedan med Ai og kongen der som han hadde gjort med Jeriko og dets konge, og at innbyggerne av Gibeon hadde gjort fred med Israel og bodde midt iblandt dem, 
6:10:2: blev de meget redde; for Gibeon var en stor by, som en av kongebyene, og større enn Ai, og alle mennene der var djerve stridsmenn. 
6:10:3: Så sendte Adoni-Sedek, kongen i Jerusalem, bud til Hoham, kongen i Hebron, og til Piream, kongen i Jarmut, og til Jafia, kongen i Lakis, og til Debir, kongen i Eglon, og lot si: 
6:10:4: Kom op til mig og hjelp mig, sa vi kan slå Gibeon! For de har gjort fred med Josva og Israels barn. 
6:10:5: Og de fem amorittiske konger, kongen i Jerusalem, kongen i Hebron, kongen i Jarmut, kongen i Lakis, kongen i Eglon, slo sig sammen og drog op med alle sine hærer; og de kringsatte Gibeon og stred mot det. 
6:10:6: Da sendte innbyggerne i Gibeon bud til Josva i leiren ved Gilgal og lot si: Slå ikke hånden av dine tjenere, kom op til oss så fort du kan, og berg oss og hjelp oss! For alle amoritter-kongene som bor i fjellbygdene, har slått sig sammen mot oss. 
6:10:7: Så drog Josva op fra Gilgal med alt sitt krigsfolk og alle sine djerve stridsmenn. 
6:10:8: Og Herren sa til Josva: Vær ikke redd dem! Jeg har gitt dem i dine hender; ikke én av dem skal kunne stå sig mot dig. 
6:10:9: Så kom Josva brått over dem; han brøt op fra Gilgal og marsjerte hele natten. 
6:10:10: Og Herren slo dem med redsel for Israel, og Josva påførte dem et stort mannefall ved Gibeon, og han forfulgte dem på veien op til Bet-Horon og hugg dem ned, helt til Aseka og Makkeda. 
6:10:11: Som de nu flyktet for Israel og var på veien ned fra Bet-Horon, da lot Herren store stener falle ned over dem fra himmelen helt til Aseka, så de døde; det omkom flere ved haglstenene enn Israels barn slo ihjel med sverdet. 
6:10:12: Og Josva talte til Herren på den dag Herren gav amorittene i Israels barns vold, og han sa så hele Israel hørte det: Stå stille, sol, i Gibeon, og du måne, i Ajalons dal! 
6:10:13: Og solen stod stille, og månen blev stående inntil folket hadde fått hevn over sine fiender. Således står det jo skrevet i Den rettskafnes bok * - Solen blev stående midt på himmelen og drygde næsten en hel dag, før den gikk ned. <* 2SA 1, 18.> 
6:10:14: Og aldri har det vært nogen dag som denne, hverken før eller siden, da Herren hørte på en manns røst; for Herren stred for Israel. 
6:10:15: Sa vendte Josva med hele Israel tilbake til leiren ved Gilgal. 
6:10:16: Men de fem konger flyktet og skjulte sig i hulen ved Makkeda. 
6:10:17: Da blev det meldt Josva: De fem konger er funnet; de hadde skjult sig i hulen ved Makkeda. 
6:10:18: Josva sa: Velt store stener for inngangen til hulen og sett folk der til å passe på dem! 
6:10:19: Men selv må I ikke stanse; forfølg eders fiender og hugg ned deres baktropp, la dem ikke komme inn i sine byer! For Herren eders Gud har gitt dem i eders hånd. 
6:10:20: Da nu Josva og Israels barn hadde voldt et meget stort mannefall blandt dem og rent gjort ende på dem, så bare nogen få av dem kom sig unda og slapp inn i de faste byer, 
6:10:21: da vendte hele folket i fred tilbake til Josva i leiren ved Makkeda, og ingen vågde mere å kny mot nogen av Israels barn. 
6:10:22: Da sa Josva: Åpne inngangen til hulen og før de fem konger ut til mig! 
6:10:23: De gjorde således og førte de fem konger ut til ham fra hulen, kongen i Jerusalem, kongen i Hebron, kongen i Jarmut, kongen i Lakis, kongen i Eglon. 
6:10:24: Og da de hadde ført disse konger ut til Josva, kalte Josva alle Israels menn til sig og sa til førerne for de stridsmenn som hadde draget med ham: Kom hit og sett foten på nakken av disse konger! Og de trådte frem og satte foten på deres nakke. 
6:10:25: Og Josva sa til dem: Frykt ikke og reddes ikke, vær frimodige og sterke! For således skal Herren gjøre med alle fiender som I kommer i strid med. 
6:10:26: Derefter hugg Josva dem ned og drepte dem og hengte dem op på fem trær; og de blev hengende på trærne helt til aftenen. 
6:10:27: Men på den tid solen gikk ned, bød Josva sine folk å ta dem ned av trærne og kaste dem i den hule de hadde skjult sig i; og for inngangen til hulen la de store stener, som ligger der den dag idag. 
6:10:28: Samme dag inntok Josva Makkeda og slo byen med sverdets egg, og kongen der slo han med bann og hver sjel som var der; han lot ikke nogen bli i live eller slippe unda; han gjorde med kongen i Makkeda som han hadde gjort med kongen i Jeriko. 
6:10:29: Fra Makkeda drog Josva med hele Israel til Libna og stred mot Libna. 
6:10:30: Og Herren gav også denne by med dens konge i Israels hånd, og han slo den med sverdets egg og hugg ned hver sjel som var der; han lot ikke nogen bli i live eller slippe unda; han gjorde med kongen der som han hadde gjort med kongen i Jeriko. 
6:10:31: Så drog Josva med hele Israel fra Libna til Lakis; og han leiret sig mot byen og stred mot den. 
6:10:32: Og Herren gav Lakis i Israels hånd; den annen dag inntok han byen og slo den med sverdets egg og hugg ned hver sjel som var der, aldeles som han hadde gjort med Libna. 
6:10:33: Da drog Horam, kongen i Geser, op for å hjelpe Lakis; men Josva slo ham og hans folk og lot ikke nogen av dem bli i live eller slippe unda. 
6:10:34: Fra Lakis drog Josva med hele Israel til Eglon; og de leiret sig mot byen og stred mot den. 
6:10:35: Og de inntok byen samme dag og slo den med sverdets egg, og hver sjel som var der, slo han med bann samme dag, aldeles som han hadde gjort med Lakis. 
6:10:36: Så drog Josva med hele Israel fra Eglon op til Hebron; og de stred mot byen 
6:10:37: og inntok den og slo den med sverdets egg, både den og dens konge og alle småbyer som lå under den, og hugg ned hver sjel som var der; han lot ikke nogen bli i live eller slippe unda, aldeles som han hadde gjort med Eglon; han slo byen og hver sjel som var der, med bann. 
6:10:38: Derefter vendte Josva sig med hele Israel mot Debir og stred mot byen. 
6:10:39: Og han tok den og dens konge og alle småbyer som lå under den, og de slo dem med sverdets egg, og hver sjel som var der, slo de med bann; han lot ikke nogen bli i live eller slippe unda; som han hadde gjort med Hebron, og som han hadde gjort med Libna og kongen der, således gjorde han med Debir og kongen der. 
6:10:40: Således tok Josva hele landet, både fjellbygdene og sydlandet og lavlandet og liene, og slo alle kongene der; han lot ikke nogen bli i live eller slippe unda, og alt som drog ånde, slo han med bann, således som Herren, Israels Gud, hadde befalt. 
6:10:41: Alt som fantes mellem Kades-Barnea og Gasa, og hele Gosenlandet like til Gibeon slo han under sig. 
6:10:42: Og alle disse konger og deres land tok Josva på én gang; for Herren, Israels Gud, stred for Israel. 
6:10:43: Derefter vendte Josva med hele Israel tilbake til leiren ved Gilgal. 
6:11:1: Da Jabin, kongen i Hasor, hørte dette, sendte han bud til Jobab, kongen i Madon, og til kongen i Simron og til kongen i Aksaf 
6:11:2: og til de konger som bodde mot nord, i fjellbygdene og på ødemarken sønnenfor Kinneret og i lavlandet og på Dor-høidene ved havet, 
6:11:3: til kana'anittene i øst og i vest og til amorittene og hetittene og ferisittene og jebusittene i fjellbygdene og hevittene ved foten av Hermon i Mispa-landet. 
6:11:4: Og de drog ut med alle sine hærer, en mengde så tallrik som sanden på havets bredd, og hester og vogner i hopetall. 
6:11:5: Alle disse konger satte hverandre stevne; og de kom og leiret sig sammen ved Merom-vannet for å stride mot Israel. 
6:11:6: Da sa Herren til Josva: Vær ikke redd dem! For imorgen ved denne tid legger jeg dem alle sammen ihjelslått for Israels åsyn; deres hester skal du skjære hasene over på, og deres vogner skal du brenne op med ild. 
6:11:7: Og Josva med alt sitt krigsfolk kom brått på dem ved Meromvannet og overfalt dem. 
6:11:8: Og Herren gav dem i Israels hånd; de slo dem og forfulgte dem like til det store Sidon og til Misrefot-Ma'im og i øst like til Mispe-dalen; de slo dem og lot ingen bli i live eller slippe unda. 
6:11:9: Og Josva gjorde med dem som Herren hadde sagt til ham; deres hester skar han hasene over på, og deres vogner brente han op med ild. 
6:11:10: Straks efter vendte Josva tilbake og inntok Hasor, og kongen der slo han ihjel med sverdet; for Hasor var fordum hovedstaden for alle disse kongeriker. 
6:11:11: Og hver sjel som var der, slo de med sverdets egg og slo byen med bann; det blev ikke igjen nogen som drog ånde, og Hasor brente han op med ild. 
6:11:12: Og alle de andre kongebyer med alle deres konger tok Josva og slo dem med sverdets egg; han slo dem med bann, således som Moses, Herrens tjener, hadde befalt. 
6:11:13: Men ingen av byene som lå der på sine hauger, brente Israel op; det var bare Hasor Josva brente op. 
6:11:14: Og alt hærfanget i disse byer og feet tok Israels barn for sig selv; men alt folket slo de med sverdets egg inntil de hadde utryddet dem; de lot ingen som drog ånde, bli igjen. 
6:11:15: Det som Herren hadde pålagt Moses, sin tjener, det hadde Moses pålagt Josva, og det gjorde Josva; han lot ikke noget ugjort av alt det Herren hadde pålagt Moses. 
6:11:16: Således inntok Josva hele dette land, både fjellbygdene og hele sydlandet og hele Gosen-landet* og lavlandet og ødemarken og fjellbygdene i Israel og lavlandet der, # <* JOS 10, 41.> 
6:11:17: fra det nakne fjell som strekker sig op imot Se'ir, til Ba'al-Gad i Libanon-dalen ved foten av Hermon-fjellet; og alle kongene der tok han til fange og hugg dem ned og drepte dem. 
6:11:18: I lang tid førte Josva krig med alle disse konger. 
6:11:19: Det var ikke nogen by som med det gode overgav sig til Israels barn, uten hevittene som bodde i Gibeon; de tok alt sammen i strid; 
6:11:20: for Herren laget det så at de forherdet sitt hjerte og drog i krig mot Israel, forat de skulde bli slått med bann og ingen nåde få, men ødelegges, således som Herren hadde befalt Moses. 
6:11:21: På samme tid kom Josva og utryddet anakittene i fjellbygdene, i Hebron, i Debir, i Anab og i alle fjellbygdene både i Juda og i Israel; Josva slo dem og deres byer med bann. 
6:11:22: Det blev ingen anakitter igjen i Israels barns land; bare i Gasa, i Gat og i Asdod blev nogen levnet. 
6:11:23: Således inntok Josva hele landet, aldeles som Herren hadde sagt til Moses, og Josva gav Israel det til arv efter deres ættegrener i deres stammer. Og landet hadde nu ro for krig. 
6:12:1: Dette var de konger i landet som Israels barn slo, og hvis land de tok i eie på østsiden av Jordan, fra Arnon-åen til Hermon-fjellet, og hele ødemarken i øst: 
6:12:2: Sihon, amoritter-kongen, som bodde i Hesbon og rådet over landet fra Aroer ved bredden av Arnon-åen, fra midten av åen, og over halvdelen av Gilead til Jabbok-åen, som er grensen mot Ammons barn, 
6:12:3: og over ødemarken inntil østsiden av Kinneret-sjøen og til østsiden av ødemarkens hav eller Salthavet, bortimot Bet-Hajesimot og i syd til foten av Pisga-liene. 
6:12:4: Likedan inntok de det land som tilhørte Og, kongen i Basan, en av dem som var tilbake av kjempefolket; han bodde i Asterot og Edre'i 
6:12:5: og rådet over Hermon-fjellet og over Salka og over hele Basan inntil gesurittenes og ma'akatittenes land, og over halvdelen av Gilead til det land som tilhørte Sihon, kongen i Hesbon. 
6:12:6: Dem hadde Moses, Herrens tjener, og Israels barn slått, og deres land hadde Moses, Herrens tjener, gitt rubenittene og gadittene og halvdelen av Manasse stamme til eiendom. 
6:12:7: Og dette var de konger i landet som Josva og Israels barn slo på vestsiden av Jordan, fra Ba'al-Gad i Libanon-dalen til det nakne fjell som strekker sig op imot Se'ir - det land som Josva gav Israels stammer til eiendom efter deres ættegrener - 
6:12:8: i fjellbygdene og i lavlandet og i ødemarken og i liene og i ørkenen og i sydlandet, hetittenes land og amorittenes og kana'anittenes, ferisittenes, hevittenes og jebusittenes land: 
6:12:9: kongen i Jeriko én, kongen i Ai, som ligger ved siden av Betel, én, 
6:12:10: kongen i Jerusalem én, kongen i Hebron én, 
6:12:11: kongen i Jarmut én, kongen Lakis én, 
6:12:12: kongen i Eglon én, kongen i Geser én, 
6:12:13: kongen i Debir én, kongen i Geder én, 
6:12:14: kongen i Horma én, kongen i Arad én, 
6:12:15: kongen i Libna én, kongen i Adullam én, 
6:12:16: kongen i Makkeda én, kongen i Betel én, 
6:12:17: kongen i Tappuah én, kongen i Hefer én, 
6:12:18: kongen i Afek én, kongen i Lassaron én, 
6:12:19: kongen i Madon én, kongen i Hasor én, 
6:12:20: kongen i Simron-Meron én, kongen i Aksaf én, 
6:12:21: kongen i Ta'anak én, kongen i Megiddo én, 
6:12:22: kongen i Kedes én, kongen i Jokneam ved Karmel én, 
6:12:23: kongen i Dor på Dor-høidene én, kongen over Gojim ved Gilgal én, 
6:12:24: kongen i Tirsa én, i alt en og tretti konger. 
6:13:1: Da Josva var blitt gammel og kommet langt ut i årene, sa Herren til ham: Nu er du blitt gammel og kommet langt ut i årene, men ennu står det en meget stor del av landet igjen som skal inntas. 
6:13:2: Dette er det land som står igjen: Alle filistrenes bygder og hele gesuritter-landet; 
6:13:3: for alt som ligger mellem Sihor østenfor Egypten og Ekrons landemerke mot nord, skal regnes til kana'anittene, både de fem filisterfyrster i Gasa og Asdod og Askalon og Gat og Ekron, og avittene. 
6:13:4: Fremdeles i syd hele kana'anittenes land, og Meara, som hører sidonierne til, like til Afek, til amorittenes landemerke, 
6:13:5: og giblittenes land og hele Libanon mot øst, fra Ba'al-Gad ved foten av Hermon-fjellet like til Hamat-veien, 
6:13:6: alle de som bor i fjellbygdene, fra Libanon til Misrefot-Ma'im, alle sidonierne. Jeg vil selv drive dem bort for Israels barn; men del du landet ut ved loddkasting til arv for Israel, således som jeg har befalt dig! 
6:13:7: Så skift nu dette land ut til arv for de ni stammer og den halve del av Manasse stamme! 
6:13:8: Sammen med Manasse* hadde rubenittene og gadittene fått sin arv, som Moses gav dem på østsiden av Jordan, således som Moses, Herrens tjener, gav dem den: # <* andre halvdelen av Manasse stamme.> 
6:13:9: fra Aroer, som ligger ved bredden av Arnon-åen, og fra den by som ligger midt i dalen, og hele sletten ved Medba like til Dibon, 
6:13:10: og alle de byer som tilhørte Sihon, amoritter-kongen, som regjerte i Hesbon, like til Ammons barns landemerke, 
6:13:11: og Gilead og gesurittenes og ma'akatittenes land og hele Hermonfjellet og hele Basan like til Salka - 
6:13:12: hele det rike som tilhørte Og i Basan, han som regjerte i Astarot og Edre'i; han var den siste som var tilbake av refa'ittene, og Moses slo dem og drev dem bort. 
6:13:13: Men Israels barn drev ikke bort gesurittene og ma'akatittene, og Gesur og Ma'akat er blitt boende blandt Israel til denne dag. 
6:13:14: Bare Levi stamme gav han ingen arv; Herrens, Israels Guds ildoffer er hans arv, således som han hadde sagt til ham. 
6:13:15: Først gav Moses Rubens barns stamme arv efter deres ætter. 
6:13:16: De fikk landet fra Aroer, som ligger ved bredden av Arnon-åen, og fra den by som ligger midt i dalen, og hele sletten ved Medba, 
6:13:17: Hesbon og alle tilhørende byer, som ligger på sletten: Dibon og Bamot-Ba'al og Bet-Ba'al-Meon 
6:13:18: og Jahsa og Kedemot og Mefa'at 
6:13:19: og Kirjata'im og Sibma og Seret-Hassahar på fjellet i dalen 
6:13:20: og Bet-Peor og Pisga-liene og Bet-Hajesimot 
6:13:21: og alle byene på sletten og hele det rike som tilhørte Sihon, amoritter-kongen, som regjerte i Hesbon - det var ham Moses slo på samme tid som han slo midianitterfyrstene Evi og Rekem og Sur og Hur og Reba, som var Sihons underkonger og bodde der i landet. 
6:13:22: Også Bileam, Beors sønn, spåmannen, slo Israels barn ihjel med sverdet sammen med de andre som de slo ihjel. 
6:13:23: Og Rubens barns grense var Jordan og landet langsmed den. Dette var Rubens barns arv efter deres ætter, byene med tilhørende landsbyer. 
6:13:24: Så gav Moses Gads stamme - Gads barn - arv efter deres ætter. 
6:13:25: Det land de fikk, var Jaser og alle byene i Gilead og halvdelen av Ammons barns land helt til Aroer, som ligger midt imot Rabba, 
6:13:26: fra Hesbon til Ramat-Hammispe og Betonim og fra Mahana'im til Debirs landemerke, 
6:13:27: og i dalen fikk de Bet-Haram og Bet-Nimra og Sukkot og Safon, resten av det rike som hadde tilhørt Sihon, kongen i Hesbon, med Jordan og landet langsmed den inntil enden av Kinneret-sjøen, på østsiden av Jordan. 
6:13:28: Dette var Gads barns arv efter deres ætter, byene med tilhørende landsbyer. 
6:13:29: Så gav Moses den halve Manasse stamme arv, så at de og - den ene halvdel av Manasse barns stamme - fikk arv efter sine ætter. 
6:13:30: Det land de fikk, strakte sig fra Mahana'im over hele Basan - hele det rike som hadde tilhørt Og, kongen i Basan - både alle Ja'irs teltbyer, som ligger i Basan, seksti byer, 
6:13:31: og halvdelen av Gilead, og Astarot og Edre'i, byer i det rike som hadde tilhørt Og i Basan; alt dette fikk Manasses sønn Makirs barn - den ene halvdel av Makirs barn - efter sine ætter. 
6:13:32: Dette var det som Moses delte ut til arv på Moabs ødemarker på østsiden av Jordan, midt imot Jeriko. 
6:13:33: Men til Levi stamme gav Moses ingen arv; Herren, Israels Gud, er deres arv, således som han hadde sagt til dem. 
6:14:1: Og dette er de arvelodder som Israels barn fikk i Kana'ans land, og som Eleasar, presten, og Josva, Nuns sønn, og overhodene for familiene i Israels barns stammer delte ut iblandt dem 
6:14:2: ved loddkasting, så de fikk hver sin arv, således som Herren hadde befalt ved Moses. Det var de ni og en halv stamme som dengang fikk arv; 
6:14:3: for de to og en halv stamme hadde Moses gitt arv på hin side* Jordan; men levittene hadde han ikke gitt arv iblandt dem. # <* østenfor.> 
6:14:4: For Josefs barn utgjorde to stammer, Manasse og Efra'im; og levittene fikk ikke nogen del i landet, men bare nogen byer til å bo i med tilhørende jorder for deres buskap og fe. 
6:14:5: Som Herren hadde befalt Moses, således gjorde Israels barn og skiftet landet. 
6:14:6: Da trådte Judas barn frem for Josva i Gilgal, og Kaleb, Jefunnes sønn, kenisitten, sa til ham: Du vet hvad Herren sa til Moses, den Guds mann, om mig og om dig i Kades-Barnea. 
6:14:7: Jeg var firti år gammel da Moses, Herrens tjener, sendte mig fra Kades-Barnea for å utspeide landet; og jeg kom tilbake til ham med svar, efter mitt beste skjønn. 
6:14:8: Mine brødre, som hadde draget op med mig, gjorde folket motløst; men jeg holdt mig trolig til Herren min Gud. 
6:14:9: Den dag svor Moses og sa: Sannelig, det land som din fot trådte på, skal være din og dine barns arv til evig tid, fordi du trolig holdt dig til Herren min Gud. 
6:14:10: Og nu ser du at Herren har gjort med mig som han sa, og latt mig leve i fem og firti år fra den tid Herren talte disse ord til Moses - all den tid Israel vandret i ørkenen; og idag er jeg fem og åtti år gammel. 
6:14:11: Jeg er ennu like sterk som jeg var den dag Moses sendte mig ut; min styrke er den samme nu som den var dengang, både i strid og til å gå ut og inn. 
6:14:12: Så gi mig nu denne fjellbygd som Herren den dag talte om; for du hørte selv dengang at anakittene bor der, og at der er store, faste byer; kanskje Herren er med mig, så jeg får drevet dem bort, således som Herren har sagt. 
6:14:13: Da velsignet Josva ham, og han gav Kaleb, Jefunnes sønn, Hebron til arv. 
6:14:14: Således fikk Kaleb, Jefunnes sønn, kenisitten, Hebron til arv, som det er den dag idag, fordi han trolig holdt sig til Herren, Israels Gud. 
6:14:15: Men Hebrons navn var før Kirjat-Arba*; Arba var den største mann blandt anakittene. Og landet hadde nu ro for krig. # <* Arbas by.> 
6:15:1: Den lodd som tilfalt Judas barns stamme efter deres ætter, strakte sig bortimot Edoms landemerke, til ørkenen Sin, lengst mot syd. 
6:15:2: Og deres grense mot syd gikk ut fra enden av Salthavet, fra den bukt som vender mot syd, 
6:15:3: bøide syd om Akrabbim-skaret, tok så over til Sin og opefter i syd for Kades-Barnea, gikk så over til Hesron og op til Adar; der svinget den bort til Karka, 
6:15:4: tok så bortefter til Asmon, gikk frem til Egyptens bekk og endte ute ved havet. Dette [sa Herren] skal være eders grense mot syd. 
6:15:5: Grensen mot øst var Salthavet inntil Jordans utløp. På nordsiden gikk grensen fra den bukt av Salthavet hvor Jordan løper ut. 
6:15:6: Derfra drog den sig op til Bet-Hogla og videre i nord for Bet-Ha'araba, op til den sten som har navn efter Bohan, Rubens sønn. 
6:15:7: Så gikk grensen fra Akor-dalen op til Debir og vendte sig nordover til Gilgal, som ligger midt imot Adummim-skaret sønnenfor bekken, og gikk så over til vannet ved En-Semes og frem til En-Rogel. 
6:15:8: Derfra gikk grensen op til Hinnoms sønns dal, til sydsiden av Jebus, det er Jerusalem, og gikk så op til toppen av det fjell som ligger rett i vest for Hinnoms dal ved nordenden av Refa'im-dalen. 
6:15:9: Fra denne fjelltopp strakte grensen sig bort til Neftoah-vannets kilde og holdt frem til byene i Efrons fjellbygd, og tok så bortefter til Ba'ala, det er Kirjat-Jearim. 
6:15:10: Fra Ba'ala svinget den vestover til Se'ir-fjellet, tok over til nordsiden av Jearim-fjellet, det er Kesalon, og gikk så ned til Bet-Semes og frem til Timna. 
6:15:11: Derefter gikk grensen nordover til Ekrons fjellrygg; der svinget den bort til Sikron, tok så over til Ba'ala-fjellet, gikk frem til Jabne'el og endte ute ved havet. 
6:15:12: Grensen mot vest var det store hav og landet langsmed det. Dette var Judas barns grense efter deres ætter, på alle sider. 
6:15:13: Men Kaleb, Jefunnes sønn, fikk sin del midt iblandt Judas barn efter Herrens ord til Josva; det var Anaks far Arbas by, som nu kalles Hebron. 
6:15:14: Og Kaleb drev bort derfra de tre anakitter Sesai og Akiman og Talmai, efterkommere av Anak. 
6:15:15: Derfra drog han op mot innbyggerne i Debir - Debirs navn var før Kirjat-Sefer. 
6:15:16: Og Kaleb sa: Den som vinner over Kirjat-Sefer og inntar det, ham vil jeg gi min datter Aksa til hustru. 
6:15:17: Og kenisitten Otniel, Kalebs bror, inntok det; og han gav ham sin datter Aksa til hustru. 
6:15:18: Og da hun kom [til sin manns hus], egget hun ham til å be hennes far om en jordeiendom, og hun sprang ned av asenet. Da sa Kaleb til henne: Hvad vil du? 
6:15:19: Hun svarte: Gi mig en avskjedsgave! Du har giftet mig bort til dette tørre sydlandet, gi mig nu vannkilder! Så gav han henne de øvre og de nedre kilder. 
6:15:20: Dette er Judas barns stammes arv efter deres ætter: 
6:15:21: Byene ved den ytterste grense av Judas barns stamme mot Edoms landemerke, i sydlandet, var: Kabse'el og Eder og Jagur 
6:15:22: og Kina og Dimona og Adada 
6:15:23: og Kedes og Hasor og Jitnan, 
6:15:24: Sif og Telem og Bealot 
6:15:25: og Hasor-Hadatta og Kerijot, Hesron, det er Hasor, 
6:15:26: Amam og Sema og Molada 
6:15:27: og Hasur-Gadda og Hesmon og Bet-Pelet 
6:15:28: og Hasar-Sual og Be'erseba og Bisjotja, 
6:15:29: Ba'ala og Ijim og Esem 
6:15:30: og Eltolad og Kesil og Horma 
6:15:31: og Siklag og Madmanna og Sansanna 
6:15:32: og Lebaot og Silhim og Ajin og Rimmon - i alt ni og tyve byer med tilhørende landsbyer. 
6:15:33: I lavlandet: Estaol og Sora og Asna 
6:15:34: og Sanoah og En-Gannim, Tappuah og Haenam. 
6:15:35: Jarmut og Adullam, Soko og Aseka 
6:15:36: og Sa'ara'im og Adita'im og Haggedera og Gederota'im - fjorten byer med tilhørende landsbyer; 
6:15:37: Senan og Hadasa og Migdal-Gad 
6:15:38: og Dilan og Mispe og Jokte'el, 
6:15:39: Lakis og Boskat og Eglon 
6:15:40: og Kabbon og Lahmas og Kitlis 
6:15:41: og Gederot, Bet-Dagon og Na'ama og Makkeda - seksten byer med tilhørende landsbyer; 
6:15:42: Libna og Eter og Asan 
6:15:43: og Jiftah og Asna og Nesib 
6:15:44: og Ke'ila og Aksib og Maresa - ni byer med tilhørende landsbyer; 
6:15:45: Ekron med tilhørende byer og landsbyer; 
6:15:46: fra Ekron og vestover alt som lå på Asdod-siden, med tilhørende landsbyer; 
6:15:47: Asdod med tilhørende byer og landsbyer, Gasa med tilhørende byer og landsbyer, inntil Egyptens bekk og det store hav og landet langsmed det. 
6:15:48: Og i fjellbygdene: Samir og Jattir og Soko 
6:15:49: og Danna og Kirjat-Sanna, det er Debir, 
6:15:50: og Anab og Estemo og Anim 
6:15:51: og Gosen og Holon og Gilo - elleve byer med tilhørende landsbyer; 
6:15:52: Arab og Duma og Esan 
6:15:53: og Janim og Bet-Tappuah og Afeka 
6:15:54: og Humta og Kirjat-Arba, det er Hebron, og Sior - ni byer med tilhørende landsbyer; 
6:15:55: Maon, Karmel og Sif og Juta 
6:15:56: og Jisre'el og Jokdeam og Sanoah, 
6:15:57: Hakka'in, Gibea og Timna - ti byer med tilhørende landsbyer; 
6:15:58: Halhul, Bet-Sur og Gedor 
6:15:59: og Ma'arat og Bet-Anot og Eltekon - seks byer med tilhørende landsbyer; 
6:15:60: Kirjat-Ba'al, det er Kirjat-Jearim, og Harabba - to byer med tilhørende landsbyer. 
6:15:61: I ørkenen: Bet-Ha'araba, Middin og Sekaka 
6:15:62: og Hannibsan og Ir-Hammelah og En-Gedi - seks byer med tilhørende landsbyer. 
6:15:63: Men jebusittene, som bodde i Jerusalem, kunde Judas barn ikke drive bort; og jebusittene blev boende sammen med Judas barn i Jerusalem og der bor de den dag idag. 
6:16:1: Den lodd som tilfalt Josefs barn, gikk fra Jordan ved Jeriko, østenom vannet ved Jeriko, gjennem ørkenen som stiger fra Jeriko opover fjellene til Betel. 
6:16:2: Fra Betel gikk grensen frem til Luz og videre bort til arkittenes land, til Atarot, 
6:16:3: tok så vestover ned til jafletittenes land, til Nedre-Bet-Horons landemerke og til Geser og endte ute ved havet. 
6:16:4: Dette var den arv som Josefs barn, Manasse og Efra'im, fikk. 
6:16:5: Efra'ims barns land efter deres ætter lå således til: Mot øst gikk grensen for deres arvelodd over Atrot-Addar frem til Øvre-Bet-Horon 
6:16:6: og endte ute ved havet. Mot nord gikk grensen over Mikmetat og bøide sig mot øst til Ta'anat-Silo og holdt frem østover til Janoah. 
6:16:7: Fra Janoah tok den så ned til Atarot og Na'ara, rørte ved Jeriko og endte ved Jordan. 
6:16:8: Fra Tappuah gikk grensen vestover til Kana-bekken og endte ute ved havet. Dette var den arvelodd som Efra'ims barns stamme fikk efter sine ætter, 
6:16:9: dessuten de byer som blev utskilt for Efra'ims barn inne i Manasses barns arvelodd, både byene og de tilhørende landsbyer. 
6:16:10: Men de drev ikke bort de kana'anitter som bodde i Geser; kana'anittene blev boende blandt Efra'ims barn, som de gjør den dag idag, men blev arbeidspliktige træler. 
6:17:1: Så fikk Manasse stamme sin arvelodd, for han var Josefs førstefødte. Makir, Manasses førstefødte sønn, gileadittenes stamfar, fikk Gilead og Basan, fordi han var en stridsmann. 
6:17:2: Manasses andre* barn fikk og arvelodd efter sine ætter: Abiesers barn og Heleks barn og Asriels barn og Sekems barn og Hefers barn og Semidas barn; dette var Manasses, Josefs sønns barn på mannssiden, efter sine ætter. # <* 4MO 26, 29 fg.> 
6:17:3: Men Selofhad, en sønn av Hefer, som var sønn av Gilead og sønnesønn av Makir, Manasses sønn, hadde ikke sønner, men bare døtre; og dette var navnene på hans døtre: Mahla og Noa, Hogla, Milka og Tirsa. 
6:17:4: De gikk frem for Eleasar, presten, og for Josva, Nuns sønn, og høvdingene og sa: Herren bød Moses at han skulde gi oss arv midt iblandt våre brødre. Så fikk de efter Herrens ord arv midt iblandt sin fars brødre. 
6:17:5: Således falt det ti deler på Manasse, foruten Gilead- og Basan-landet på hin side* Jordan. # <* østenfor.> 
6:17:6: For Manasses døtre fikk arv midt iblandt hans sønner, og Gilead-landet hadde Manasses andre* barn fått. # <* JOS 13, 29 fg. 17, 1.> 
6:17:7: Og Manasses grense gikk fra Aser til Mikmetat, som ligger midt imot Sikem; så tok grensen til høire til En-Tappuah-bygden. 
6:17:8: Manasse fikk Tappuah-landet, men Tappuah selv på grensen av Manasse tilfalt Efra'ims barn. 
6:17:9: Så gikk grensen ned til Kana-bekken, sønnenfor bekken; men byene der tilfalt Efra'im, enda de lå mellem Manasses byer. Derfra gikk Manasses grense nordenfor bekken og endte ute ved havet. 
6:17:10: Alt som lå sønnenfor, tilfalt Efra'im, og det som lå nordenfor, tilfalt Manasse, og havet blev hans grense; og i nord støtte de op til Aser og i øst til Issakar. 
6:17:11: I Issakar og i Aser fikk Manasse Bet-Sean med tilhørende småbyer og Jibleam med tilhørende småbyer og innbyggerne i Dor med tilhørende småbyer og innbyggerne i En-Dor med tilhørende småbyer og innbyggerne i Ta'anak med tilhørende småbyer og innbyggerne i Megiddo med tilhørende småbyer, de tre høidedrag. 
6:17:12: Men Manasses barn maktet ikke å innta disse byer, og det lyktes kana'anittene å bli boende der i landet. 
6:17:13: Da Israels barn siden blev sterkere, gjorde de kana'anittene arbeidspliktige, men de drev dem ikke bort. 
6:17:14: Og Josefs barn talte til Josva og sa: Hvorfor har du gitt mig bare én lodd og én arvedel, enda jeg er et stort folk, fordi Herren hittil har velsignet mig? 
6:17:15: Da sa Josva til dem: Er du så stort et folk, så gå op i skogen og rydd dig jord der i ferisittenes og refa'ittenes land, siden Efra'ims fjellbygd er for trang for dig. 
6:17:16: Men Josefs barn sa: Fjellbygden strekker ikke til for oss, og kana'anittene som bor i dalbygdene, har alle sammen jernvogner, både de som bor i Bet-Sean med tilhørende småbyer, og de som bor i Jisre'els dal. 
6:17:17: Da sa Josva til Josefs hus, til Efra'im og Manasse: Du er et stort folk og har stor styrke; du skal ikke ha bare én lodd, 
6:17:18: men en fjellbygd skal du få, og da det er skog der, skal du hugge den ned, så endog dens utkanter skal tilhøre dig; for du skal drive bort kana'anittene, om de enn har jernvogner og er sterke. 
6:18:1: Og hele Israels barns menighet samlet sig i Silo og reiste der sammenkomstens telt; og landet lå undertvunget foran dem. 
6:18:2: Men det var ennu syv stammer tilbake blandt Israels barn som ikke hadde delt sin arv. 
6:18:3: Da sa Josva til Israels barn: Hvor lenge vil I ennu dryge før I drar avsted og inntar det land Herren, eders fedres Gud, har gitt eder? 
6:18:4: Kom nu med tre menn for hver stamme! Så vil jeg sende dem ut, og de skal gjøre sig rede og gå igjennem landet og skrive op det de ser, for utskiftningens skyld, og så komme tilbake til mig. 
6:18:5: Del det så mellem eder i syv deler! Juda skal bli i sitt land mot syd, og Josefs hus skal bli i sitt land mot nord. 
6:18:6: Og I skal skrive op alt det som er i landet, i syv deler, og komme hit til mig med det I har skrevet; så vil jeg kaste lodd for eder her, for Herrens, vår Guds åsyn. 
6:18:7: For levittene får ingen del blandt eder, men Herrens prestedømme er deres arv; og Gad og Ruben og den halve Manasse stamme har fått sin arv på østsiden av Jordan - den arv som Moses, Herrens tjener, gav dem. 
6:18:8: Så gjorde mennene sig rede og gikk; og da de drog avsted, bød Josva dem å skrive op alt det de så i landet; han sa: Gå avsted og dra omkring i landet og skriv op alt det I ser der, og kom så tilbake til mig! Så vil jeg kaste lodd for eder for Herrens åsyn her i Silo. 
6:18:9: Så gikk da mennene avsted og drog gjennem landet og skrev op det de så der, by for by, i en bok i syv deler; derefter kom de tilbake til Josva i leiren ved Silo. 
6:18:10: Og Josva kastet lodd for dem i Silo for Herrens åsyn; der skiftet Josva landet mellem Israels barn efter deres ættegrener. 
6:18:11: Først kom loddet ut for Benjamins barns stamme efter deres ætter; og det land som blev deres lodd, kom til å ligge mellem Judas barn og Josefs barn. 
6:18:12: Og deres grense på nordsiden gikk ut fra Jordan og drog sig opover til fjellryggen i nord for Jeriko og derefter vestover op i fjellet og frem til ørkenen ved Bet-Aven. 
6:18:13: Derfra gikk grensen over til Luz, til fjellryggen i syd for Luz, det er Betel; og så gikk grensen ned til Atrot-Addar over det fjell som ligger sønnenfor Nedre-Bet-Horon. 
6:18:14: Så gjorde grensen en sving og bøide sig på vestsiden mot syd fra det fjell som ligger rett i syd for Bet-Horon, og den endte ved Kirjat-Ba'al, det er Kirjat-Jearim, en by som tilhørte Judas barn. Dette var vestsiden. 
6:18:15: På sydsiden gikk grensen fra enden av Kirjat-Jearim - der var det grensen begynte i vest - og frem til Neftoah-vannets kilde. 
6:18:16: Så gikk grensen ned til enden av det fjell som ligger midt imot Hinnoms sønns dal, nordenfor Refa'im-dalen, og så ned i Hinnoms dal, i syd for jebusittenes fjellrygg, og derefter ned til En-Rogel. 
6:18:17: Så svinget den mot nord og gikk frem til En-Semes og videre til Gelilot, som ligger midt imot Adummim-skaret, og så ned til den sten som har navn efter Bohan, Rubens sønn. 
6:18:18: Der tok den over til den fjellrygg som ligger rett i nord for Ha'araba, og gikk derefter ned til Ha'araba. 
6:18:19: Så tok grensen over til nordsiden av fjellryggen ved Bet-Hogla og endte ved den nordlige bukt av Salthavet, ved Jordans sydende. Dette var sydgrensen. 
6:18:20: På østsiden var Jordan grensen. Dette var Benjamins barns arvelodd med dens grenser rundt omkring, efter deres ætter. 
6:18:21: Og de byer som tilfalt Benjamins barns stamme efter deres ætter, var: Jeriko og Bet-Hogla og Emek-Kesis 
6:18:22: og Bet-Ha'araba og Semara'im og Betel 
6:18:23: og Ha'avvim og Happara og Ofra 
6:18:24: og Kefar-Ha'ammoni og Haofni og Geba - tolv byer med tilhørende landsbyer; 
6:18:25: Gibeon og Rama og Be'erot 
6:18:26: og Mispe og Hakkefira og Hammosa 
6:18:27: og Rekem og Jirpe'el og Tarala 
6:18:28: og Sela, Haelef og Jebus, det er Jerusalem, Gibat, Kirjat - fjorten byer med tilhørende landsbyer. Dette var Benjamins barns arvelodd efter deres ætter. 
6:19:1: Det annet lodd kom ut for Simeon, for Simeons barns stamme efter deres ætter; den arvelodd de fikk, lå inne i Judas barns arvelodd. 
6:19:2: Til deres arvelodd hørte: Be'er-seba og Seba og Molada 
6:19:3: og Hasar-Sual og Bala og Esem 
6:19:4: og Eltolad og Betul og Horma 
6:19:5: og Siklag og Bet-Hammarkabot og Hasar-Susa 
6:19:6: og Bet-Lebaot og Saruhen - tretten byer med tilhørende landsbyer; 
6:19:7: A'in, Rimmon og Eter og Asan - fire byer med tilhørende landsbyer, 
6:19:8: og alle de landsbyer som lå rundt omkring disse byer inntil Ba'alat-Be'er, Rama i sydlandet. Dette var den arvelodd som Simeons barns stamme fikk efter sine ætter. 
6:19:9: Av den del som blev tilmålt Judas barn, fikk Simeons barn sin arvelodd, for Judas barns del var for stor for dem; derfor fikk Simeons barn sin arvelodd inne i deres arvelodd. 
6:19:10: Det tredje lodd kom ut for Sebulons barn efter deres ætter; grensen for deres arvelodd gikk til Sarid. 
6:19:11: Deres grense gikk i vest op til Marala, støtte til Dabbeset og nådde den bekk som løper østenfor Jokneam. 
6:19:12: Mot øst, mot solens opgang, bøide den sig fra Sarid bort til Kislot-Tabors enemerker og gikk frem til Haddaberat og op til Jafia. 
6:19:13: Derfra gikk den mot øst, mot solens opgang, over til Gat-Hefer og Et-Kasin, og frem til Rimmon, som strekker sig bort til Hannea. 
6:19:14: Så svinget grensen omkring dette sted nordover til Hannaton og endte i Jiftah-El-dalen. 
6:19:15: Dessuten fikk de Kattat og Nahalal og Simron og Jidala og Betlehem, i alt tolv byer med tilhørende landsbyer. 
6:19:16: Dette var Sebulons barns arvelodd efter deres ætter, disse byer med tilhørende landsbyer. 
6:19:17: For Issakar kom det fjerde lodd ut, for Issakars barn efter deres ætter. 
6:19:18: I deres land lå Jisre'ela og Hakkesullot og Sunem 
6:19:19: og Hafara'im og Sion og Anaharat 
6:19:20: og Harabbit og Kisjon og Ebes 
6:19:21: og Remet og En-Gannim og En-Hadda og Bet-Passes; 
6:19:22: og grensen støtte til Tabor og Sahasuma og Bet-Semes, og den endte ved Jordan - seksten byer med tilhørende landsbyer. 
6:19:23: Dette var den arvelodd som Issakars barns stamme fikk efter sine ætter, byene med tilhørende landsbyer. 
6:19:24: Det femte lodd kom ut for Asers barns stamme efter deres ætter. 
6:19:25: I deres land lå Helkat og Hali og Beten og Aksaf 
6:19:26: og Allammelek og Amad og Misal, og grensen støtte mot vest til Karmel og Sihor-Libnat. 
6:19:27: Så vendte den sig østover til Bet-Dagon, støtte til Sebulon og Jiftah-El-dalen, nordenfor Bet-Haemek og Ne'iel, og gikk så frem til Kabul i nord. 
6:19:28: Dessuten fikk de Ebron og Rehob og Hammon og Kana, helt til det store Sidon. 
6:19:29: Så vendte grensen sig mot Harama og holdt frem til den faste by Tyrus, vendte sig derefter mot Hosa og endte ute ved havet, ikke langt fra Aksib. 
6:19:30: Dessuten fikk de Umma og Afek og Rehob, i alt to og tyve byer med tilhørende landsbyer. 
6:19:31: Dette var den arvelodd som Asers barns stamme fikk efter sine ætter, disse byer med tilhørende landsbyer. 
6:19:32: For Naftalis barn kom det sjette lodd ut, for Naftalis barn efter deres ætter. 
6:19:33: Deres grense gikk fra Helef, fra eken ved Sa'anannim, om Adami-Hannekeb og Jabne'el frem til Lakkum og endte ved Jordan. 
6:19:34: Så vendte grensen sig vestover til Asnot-Tabor og holdt frem til Hukkok og støtte i syd til Sebulon og i vest til Aser og i øst til Juda ved Jordan. 
6:19:35: Av faste byer var der: Hassiddim, Ser og Hammat, Rakkat og Kinneret 
6:19:36: og Adama og Harama og Hasor 
6:19:37: og Kedes og Edre'i og En-Hasor 
6:19:38: og Jiron og Migdal-El, Horem og Bet-Anat og Bet-Semes, i alt nitten byer med tilhørende landsbyer. 
6:19:39: Dette var den arvelodd som Naftalis barns stamme fikk efter sine ætter, byene med tilhørende landsbyer. 
6:19:40: For Dans barns stamme efter deres ætter kom det syvende lodd ut. 
6:19:41: I det land de fikk til arvelodd, lå Sora og Estaol og Ir-Semes 
6:19:42: og Sa'alabbin og Ajalon og Jitla 
6:19:43: og Elon og Timnata og Ekron 
6:19:44: og Elteke og Gibbeton og Ba'alat 
6:19:45: og Jehud og Bene-Berak og Gat-Rimmon 
6:19:46: og Me-Hajarkon og Harakkon med bygdene bortimot Joppe. 
6:19:47: Men Dans barns land blev for trangt for dem, og Dans barn drog op og førte krig mot Lesem; de inntok det og slo det med sverdets egg og tok det i eie og bodde der, og de kalte Lesem Dan efter sin stamfar Dan. 
6:19:48: Dette var den arvelodd som Dans barns stamme fikk efter sine ætter, disse byer med tilhørende landsbyer. 
6:19:49: Så var de da ferdig med å skifte ut landet efter dets grenser. Og Israels barn gav Josva, Nuns sønn, en arvelodd mellem sig; 
6:19:50: efter Herrens befaling gav de ham den by han bad om, Timnat-Serah på Efra'im-fjellet, og han bygget op byen og bosatte sig der. 
6:19:51: Dette var de arvelodder som Eleasar, presten, og Josva, Nuns sønn, og overhodene for familiene i Israels barns stammer skiftet ut ved loddkasting i Silo for Herrens åsyn, ved inngangen til sammenkomstens telt. Således var de ferdig med å skifte ut landet. 
6:20:1: og Herren talte til Josva og sa: 
6:20:2: Tal til Israels barn og si: Avgi de tilfluktsstæder som jeg talte til eder om ved Moses, 
6:20:3: så en manndraper som av vanvare, uten å ville det, slår nogen ihjel, kan fly dit; I skal ha dem til tilfluktsstæder for manndraperen, når han flyr for blodhevneren. 
6:20:4: Han skal fly til en av disse byer og stille sig ved inngangen til byens port og legge sin sak frem for de eldste i byen; så skal de ta ham inn til sig i byen og gi ham et sted hos sig hvor han kan bo. 
6:20:5: Og når blodhevneren forfølger ham, da skal de ikke overgi manndraperen i hans hånd, fordi han slo sin næste ihjel av vanvare og uten før å ha båret hat til ham. 
6:20:6: Og han skal bli boende der i byen, til han har stått til doms for menigheten, og til den yppersteprest som da lever, er død; derefter kan manndraperen vende tilbake til sin by og sitt hus, til den by som han rømte fra. 
6:20:7: Så helliget de Kedes i Galilea i Naftali-fjellene og Sikem i Efra'im-fjellene og Kirjat-Arba, det er Hebron, i Juda-fjellene. 
6:20:8: Og på østsiden av Jordan, som flyter forbi Jeriko, avgav de: av Rubens stamme Beser i ørkenen, på sletten, og av Gads stamme Ramot i Gilead og av Manasse stamme Galon i Basan. 
6:20:9: Dette var de byer som blev fastsatt til tilfluktsstæder for alle Israels barn og for de fremmede som opholder sig blandt dem, så hver den som av vanvare slår nogen ihjel, kan fly dit og ikke skal dø for blodhevnerens hånd, før han er blitt stilt frem for menigheten. 
6:21:1: Overhodene for levittenes familier trådte frem for Eleasar, presten, og Josva, Nuns sønn, og for familie-overhodene i Israels barns stammer 
6:21:2: og talte til dem i Silo i Kana'ans land og sa: Herren bød ved Moses at der skulde gis oss byer å bo i med jordet omkring for vårt fe. 
6:21:3: Da gav Israels barn efter Herrens befaling av sine arvelodder levittene disse byer med tilhørende jorder: 
6:21:4: Først kom loddet ut for kahatittenes ætter, og blandt disse levitter fikk Arons, prestens, sønner ved loddkastingen tretten byer av Juda stamme og av simeonittenes stamme og av Benjamins stamme, 
6:21:5: og de andre Kahats barn fikk ved loddkastingen ti byer av Efra'ims stammes ætter og av Dans stamme og av den halve Manasse stamme. 
6:21:6: Gersons barn fikk ved loddkastingen tretten byer av Issakars stammes ætter og av Asers stamme og av Naftali stamme og av den halve Manasse stamme i Basan. 
6:21:7: Meraris barn fikk efter sine ætter tolv byer av Rubens stamme og av Gads stamme og av Sebulons stamme. 
6:21:8: Disse byer med tilhørende jorder gav Israels barn levittene ved loddkasting, således som Herren hadde befalt ved Moses. 
6:21:9: Av Judas barns stamme og av Simeons barns stamme avgav de de byer som nu skal nevnes: 
6:21:10: Arons sønner av kahatittenes ætter, de av Levis barn som loddet først kom ut for, 
6:21:11: fikk Arbas, anakittenes stamfars by, det er Hebron, i Juda-fjellene med tilhørende jorder rundt omkring; 
6:21:12: men byens mark og dens landsbyer gav de Kaleb, Jefunnes sønn, til eiendom. 
6:21:13: Arons, prestens, sønner fikk både Hebron, som var tilfluktsstad for manndrapere, med tilhørende jorder og Libna med jorder 
6:21:14: og Jattir med jorder og Estemoa med jorder 
6:21:15: og Holon med jorder og Debir med jorder 
6:21:16: og A'in med jorder og Jutta med jorder og Bet-Semes med jorder - ni byer av disse to stammer; 
6:21:17: og av Benjamins stamme: Gibeon med jorder, Geba med jorder, 
6:21:18: Anatot med jorder og Almon med jorder - fire byer. 
6:21:19: Således fikk Arons sønner, prestene, i alt tretten byer med tilhørende jorder. 
6:21:20: Og Kahats barns ætter av levittene - de andre av Kahats barn - fikk av Efra'ims stamme disse byer, som utgjorde deres lodd: 
6:21:21: De fikk Sikem, som var tilfluktsstad for manndrapere, med tilhørende jorder i Efra'im-fjellene og Geser med jorder 
6:21:22: og Kibsa'im med jorder og Bet-Horon med jorder - fire byer; 
6:21:23: og av Dans stamme: Elteke med jorder, Gibbeton med jorder, 
6:21:24: Ajalon med jorder, Gat-Rimmon med jorder - fire byer; 
6:21:25: og av den halve Manasse stamme: Ta'anak med jorder og Gat-Rimmon med jorder - to byer. 
6:21:26: Det var i alt ti byer med tilhørende jorder som de andre kahatitters ætter fikk. 
6:21:27: Og Gersons barn av levittenes ætter fikk av den halve Manasse stamme Galon i Basan, som var tilfluktsstad for manndrapere, med tilhørende jorder, og Be'estera med jorder - to byer; 
6:21:28: og av Issakars stamme: Kisjon med jorder, Daberat med jorder. 
6:21:29: Jarmut med jorder, En-Gannim med jorder - fire byer; 
6:21:30: og av Asers stamme: Misal med jorder, Abdon med jorder, 
6:21:31: Helkat med jorder og Rehob med jorder - fire byer; 
6:21:32: og av Naftali stamme: Kedes i Galilea, som var tilfluktsstad for manndrapere, med tilhørende jorder, og Hammot-Dor med jorder og Kartan med jorder - tre byer. 
6:21:33: Gersonittenes byer efter deres ætter utgjorde således i alt tretten byer med tilhørende jorder. 
6:21:34: Og Meraris barns ætter, resten av levittene, fikk av Sebulons stamme: Jokneam med jorder, Karta med jorder, 
6:21:35: Dimna med jorder, Nahalal med jorder - fire byer; 
6:21:36: og av Rubens stamme: Beser med jorder og Jahsa med jorder, 
6:21:37: Kedemot med jorder og Mefa'at med jorder - fire byer; 
6:21:38: og av Gads stamme: Ramot i Gilead, som var tilfluktsstad for manndrapere, med tilhørende jorder, og Mahana'im med jorder, 
6:21:39: Hesbon med jorder, Jaser med jorder - i alt fire byer. 
6:21:40: De byer som Meraris barn, resten av levittenes ætter, fikk som sin lodd efter sine ætter, var i alt tolv byer. 
6:21:41: I alt utgjorde levittenes byer i Israels barns eiendomsland åtte og firti byer med tilhørende jorder. 
6:21:42: Disse byer hadde hver for sig sine jorder rundt omkring sig; så var det med alle disse byer. 
6:21:43: Således gav Herren Israel hele det land han hadde svoret å ville gi deres fedre; og de inntok det og bosatte sig der. 
6:21:44: Og Herren lot dem ha ro på alle kanter, aldeles som han hadde tilsvoret deres fedre; og ingen av alle deres fiender kunde holde stand imot dem; alle deres fiender gav Herren i deres hånd. 
6:21:45: Ikke ett ord blev til intet av alle de gode ord Herren hadde talt til Israels hus; det blev opfylt alt sammen. 
6:22:1: Da kalte Josva til sig rubenittene og gadittene og den halve Manasse stamme 
6:22:2: og sa til dem: I har holdt alt det Moses, Herrens tjener, bød eder, og I har vært lydige mot mig i alt det jeg har befalt eder; 
6:22:3: I har ikke sviktet eders brødre i denne lange tid, like til denne dag, men I har tatt vare på alt det Herren eders Gud har befalt eder å vareta. 
6:22:4: Og nu har Herren eders Gud gitt eders brødre ro, således som han hadde tilsagt dem; så vend nu om og dra til eders telt, til eders eget land, det som Moses, Herrens tjener, gav eder på hin side Jordan. 
6:22:5: Men gi nøie akt på å holde det bud og den lov Moses, Herrens tjener, gav eder, å elske Herren eders Gud og å vandre på alle hans veier og holde hans bud og holde fast ved ham og tjene ham av alt eders hjerte og av all eders sjel. 
6:22:6: Så velsignet Josva dem og lot dem fare, og de drog til sine telt. 
6:22:7: Den ene halvdel av Manasse stamme hadde Moses gitt deres arvelodd i Basan, og den andre halvdel hadde Josva gitt arvelodd sammen med deres brødre på den andre side av Jordan - på vestsiden. Da Josva nu lot dem fare til sine telt, velsignet han dem også 
6:22:8: og sa til dem: Vend nu tilbake til eders telt med eders store rikdommer, med store mengder av fe, med sølv og gull og kobber og jern og med klær i stor mengde! Del med eders brødre det hærfang I har tatt fra eders fiender! 
6:22:9: Så vendte Rubens barn og Gads barn og den halve Manasse stamme tilbake; de skiltes fra Israels barn og brøt op fra Silo, som ligger i Kana'ans land, for å dra til Gilead, sitt eget land, hvor de hadde fått sin eiendom efter den befaling Herren hadde gitt ved Moses. 
6:22:10: Og da de kom til landet ved Jordan, den del derav som hører til Kana'ans land, bygget Rubens barn og Gads barn og den halve Manasse stamme et alter der ved Jordan, et stort alter, som såes lang vei. 
6:22:11: Da hørte Israels barn at folk sa: Se, Rubens barn og Gads barn og den halve Manasse stamme har bygget et alter lengst fremme i Kana'ans land, i landet ved Jordan, midt imot Israels barn. 
6:22:12: Og da Israels barn hørte dette, samlet hele Israels barns menighet sig i Silo for å dra op til strid mot dem. 
6:22:13: Og Israel sendte Pinehas, presten Eleasars sønn, til Rubens barn og Gads barn og den halve Manasse stamme i Gileads land 
6:22:14: og sammen med ham ti høvdinger, én familiehøvding for hver av Israels stammer, og hver av dem var overhode for sin familie blandt Israels tusener. 
6:22:15: Og da de kom til Rubens barn og Gads barn og den halve Manasse stamme i Gileads land, talte de til dem og sa: 
6:22:16: Således sier hele Herrens menighet: Hvad er dette for en troløshet I har gjort mot Israels Gud, at I idag har vendt eder bort fra Herren og bygget eder et alter - at I idag har satt eder op mot Herren? 
6:22:17: Var det ikke nok med den misgjerning vi gjorde da vi holdt oss til Peor, en misgjerning som vi like til den dag idag ikke har renset oss fra, og for hvis skyld hjemsøkelsen kom over Herrens menighet? 
6:22:18: Og I vender eder idag bort fra Herren! Sannelig, setter I eder idag op mot Herren, så vil han imorgen la sin vrede bryte løs mot hele Israels menighet. 
6:22:19: Og synes I eders eiendomsland er urent, så kom bare over til Herrens eiendomsland, der hvor Herrens tabernakel står, og få eder eiendom midt iblandt oss, men sett eder ikke op mot Herren og sett eder ikke op mot oss ved å bygge eder et alter foruten Herrens, vår Guds alter! 
6:22:20: Bar ikke Akan, Serahs sønn, sig troløst at med det bannlyste gods? Og det kom vrede over hele Israels menighet, og han var ikke den eneste som omkom for hans misgjernings skyld. 
6:22:21: Da svarte Rubens barn og Gads barn og den halve Manasse stamme og sa til høvdingene for Israels tusener: 
6:22:22: Gud, Gud Herren, ja, Gud, Gud Herren, han vet det, og Israel skal vite det: Sannelig, det var ikke i tross eller i troløshet mot Herren - da frelse du oss ikke idag! - 
6:22:23: at vi bygget oss et alter, som om vi vilde vende oss bort fra Herren; og sannelig, ikke for å ofre brennoffer og matoffer på det, og sannelig, ikke for å frembære takkoffer på det - da straffe Herren selv oss! - 
6:22:24: men sannelig, vi gjorde det av frykt for hvad som kunde hende, for vi tenkte at eders barn engang kunde komme til å si til våre barn: Hvad har I å gjøre med Herren, Israels Gud? 
6:22:25: Herren har jo satt Jordan til grense mellem oss og eder, Rubens barn og Gads barn; I har ingen del i Herren. Således kunde eders barn få våre barn til å holde op med å frykte Herren. 
6:22:26: Derfor sa vi: La oss ta oss fore å bygge et alter, ikke til brennoffer eller til slaktoffer, 
6:22:27: men det skal være et vidne mellem oss og eder og mellem våre efterkommere om at vi vil tjene Herren for hans åsyn med våre brennoffer og slaktoffer og takkoffer, forat ikke eders barn engang skal si til våre barn: I har ingen del i Herren. 
6:22:28: Og vi sa: Hvis de engang skulde si noget sådant til oss og våre efterkommere, da vil vi svare: Se på den efterligning av Herrens alter som våre fedre gjorde, ikke til brennoffer eller til slaktoffer, men til et vidne mellem oss og eder! 
6:22:29: Det være langt fra oss å sette oss op mot Herren og idag vende oss bort fra Herren ved å bygge et alter til brennoffer eller matoffer eller slaktoffer foruten Herrens, vår Guds alter, som står foran hans tabernakel. 
6:22:30: Da Pinehas, presten, og menighetens høvdinger og overhodene for Israels tusener, som var med ham, hørte de ord som Rubens barn og Gads barn og Manasses barn talte, syntes de godt om det. 
6:22:31: Og Pinehas, presten Eleasars sønn, sa til Rubens barn og Gads barn og Manasses barn: Idag skjønner vi at Herren er midt iblandt oss, siden I ikke har gjort denne troløshet mot Herren; dermed har I frelst Israels barn av Herrens hånd. 
6:22:32: Så skiltes Pinehas, presten Eleasars sønn, og høvdingene fra Rubens barn og Gads barn i Gileads land og vendte om til Kana'ans land, til Israels barn, og kom tilbake med svar til dem. 
6:22:33: Og Israels barn syntes godt om svaret; de lovet Gud og tenkte ikke mere på å dra op til strid mot dem og ødelegge det land hvor Rubens barn og Gads barn bodde. 
6:22:34: Og Rubens barn og Gads barn gav alteret navn og sa: Et vidne er det mellem oss om at Herren er Gud. 
6:23:1: Da det nu var gått en lang tid, efterat Herren hadde gitt Israel ro for alle dets fiender rundt om, og Josva var blitt gammel og kommet langt ut i årene, 
6:23:2: da kalte Josva til sig hele Israel, dets eldste, dets høvdinger, dets dommere og dets tilsynsmenn, og sa til dem: Nu er jeg blitt gammel og kommet langt ut i årene, 
6:23:3: og I har sett alt hvad Herren eders Gud har gjort med alle disse folk som han drev ut for eder; for det var Herren eders Gud som stred for eder. 
6:23:4: Nu har jeg ved loddkasting tildelt eder de folk som ennu er tilbake, som arvelodd, efter eders stammer, og likeså alle de folk som jeg har utryddet, fra Jordan til det store hav i vest. 
6:23:5: Og Herren eders Gud skal selv støte dem ut og drive dem bort for eder, og I skal ta deres land i eie, således som Herren eders Gud har sagt til eder. 
6:23:6: Så vær nu faste i å holde og gjøre alt det som er skrevet i Mose lovbok, så I ikke viker derfra hverken til høire eller til venstre, 
6:23:7: og ikke gir eder i lag med disse folk, de som ennu er tilbake hos eder. Deres guders navn skal I ikke nevne og ikke la nogen sverge ved dem og ikke dyrke dem og ikke tilbede dem, 
6:23:8: men Herren eders Gud skal I holde fast ved, således som I har gjort til denne dag. 
6:23:9: Derfor har Herren drevet store og sterke folk bort for eder, og ingen har holdt stand mot eder til denne dag. 
6:23:10: Én mann av eder drev tusen på flukt; for det var Herren eders Gud som stred for eder, således som han hadde sagt til eder. 
6:23:11: Ta eder da nøie i vare, så sant I har eders liv kjært, og elsk Herren eders Gud! 
6:23:12: For dersom I vender eder bort fra ham og holder eder til disse folk, de av dem som ennu er tilbake hos eder, og inngår svogerskap med dem og gir eder i lag med dem, og de med eder, 
6:23:13: da skal I vite for visst at Herren eders Gud ikke mere vil drive disse folk bort for eder, men de skal bli til en snare og en felle for eder og til en svepe for eders rygg og til torner i eders øine, inntil I er utryddet av dette gode land som Herren eders Gud har gitt eder. 
6:23:14: Se, jeg går nu all jordens vei; så skal I da av alt eders hjerte og av all eders sjel kjenne at ikke et eneste ord er blitt til intet av alle de gode ord Herren eders Gud har talt over eder; de er alle sammen opfylt for eder, ikke et eneste ord derav er blitt til intet. 
6:23:15: Men likesom alle de gode ord Herren eders Gud har talt til eder, er opfylt på eder, således skal Herren la alle de onde ord opfylles på eder, inntil han har utryddet eder av dette gode land som Herren eders Gud har gitt eder. 
6:23:16: Når I bryter Herrens, eders Guds pakt, som han oprettet med eder, og gir eder til å dyrke andre guder og tilbede dem, da skal Herrens vrede optendes mot eder, og I skal snarlig bli utryddet av det gode land han har gitt eder. 
6:24:1: Og Josva samlet alle Israels stammer i Sikem; og han kalte til sig Israels eldste og dets høvdinger og dets dommere og dets tilsynsmenn, og de trådte frem for Guds åsyn. 
6:24:2: Og Josva sa til hele folket: Så sier Herren, Israels Gud: På hinside elven bodde fordum eders fedre, blandt dem Tarah, Abrahams og Nakors far, og de dyrket fremmede guder. 
6:24:3: Og jeg tok eders far Abraham fra hin side elven og lot ham vandre om i hele Kana'ans land; og jeg gjorde hans ætt tallrik og gav ham Isak. 
6:24:4: Og Isak gav jeg Jakob og Esau, og jeg gav Esau Se'ir-fjellene til eie; men Jakob og hans barn drog ned til Egypten. 
6:24:5: Siden sendte jeg Moses og Aron, og jeg slo Egypten med plager, således som I vet jeg gjorde der; og derefter førte jeg eder ut. 
6:24:6: Da jeg nu førte eders fedre ut av Egypten, kom I til havet; men egypterne forfulgte eders fedre med vogner og hestfolk til det Røde Hav. 
6:24:7: Da ropte de til Herren, og han satte et mørke mellem eder og egypterne og lot havet slå sammen over dem, så det skjulte dem; med egne øine så I hvad jeg gjorde i Egypten. Siden bodde I en lang tid i ørkenen. 
6:24:8: Så førte jeg eder inn i amorittenes land, de som bodde på hin side Jordan; de førte krig mot eder, men jeg gav dem i eders hånd, så I tok deres land i eie, og jeg utryddet dem for eder. 
6:24:9: Da stod Balak, Sippors sønn, Moabs konge, op og stred mot Israel; og han sendte bud efter Bileam, Beors sønn, forat han skulde forbanne eder. 
6:24:10: Men jeg vilde ikke høre på Bileam, og han måtte velsigne eder, og jeg frelste eder av hans hånd. 
6:24:11: Så gikk I over Jordan og kom til Jeriko, og innbyggerne i Jeriko stred mot eder, likeså amorittene og ferisittene og kana'anittene og hetittene og girgasittene, hevittene og jebusittene. Og jeg gav dem i eders hånd. 
6:24:12: Og jeg sendte hvepser foran eder, og de drev dem ut for eder likesom også begge amoritter-kongene; det var ikke med ditt sverd og ikke ned din bue de blev drevet ut. 
6:24:13: Og jeg gav eder et land som du ikke har hatt møie med, og byer som I ikke har bygget, og I bosatte eder i dem; I eter av vingårder og oljetrær som I ikke har plantet. 
6:24:14: Så frykt nu Herren og tjen ham i opriktighet og troskap, og skill eder av med de guder som eders fedre dyrket på hin side elven og i Egypten og tjen Herren! 
6:24:15: Men synes I ikke om å tjene Herren, så velg idag hvem I vil tjene, enten de guder eders fedre dyrket på hin side elven, eller amorittenes guder, i hvis land I bor! Men jeg og mitt hus vi vil tjene Herren. 
6:24:16: Da svarte folket og sa: Det være langt fra oss å forlate Herren for å dyrke fremmede guder! 
6:24:17: For Herren vår Gud han var det som førte oss og våre fedre op av Egyptens land, av trælehuset, og som gjorde disse store tegn for våre øine og voktet oss på hele den vei vi gikk, og blandt alle de folk hvis land vi drog igjennem, 
6:24:18: og Herren drev ut for oss alle disse folk og de amoritter som bodde i landet. Også vi vil tjene Herren, for han er vår Gud. 
6:24:19: Og Josva sa til folket: I kan ikke tjene Herren, for han er en hellig Gud. En nidkjær Gud er han; han vil ikke bære over med eders overtredelser og synder. 
6:24:20: Når I forlater Herren og dyrker fremmede guder, da vil han vende sig bort og la det gå eder ille og tilintetgjøre eder, efterat han før har gjort vel mot eder. 
6:24:21: Men folket sa til Josva: Nei, Herren vil vi tjene. 
6:24:22: Da sa Josva til folket: Så er I da selv vidner imot eder at I har valgt Herren og vil tjene ham. De sa: Ja, det er vi vidner på! 
6:24:23: Josva sa: Så skill eder nu av med de fremmede guder som I har hos eder, og bøi eders hjerte til Herren, Israels Gud! 
6:24:24: Og folket sa til Josva: Herren vår Gud vil vi tjene, og hans ord vil vi lyde. 
6:24:25: Den dag gjorde Josva en pakt med folket i Sikem og satte lov og rett for dem. 
6:24:26: Og Josva skrev op disse ord i Guds lovbok; og han tok en stor sten og reiste den der under den ek som stod ved Herrens helligdom. 
6:24:27: Og Josva sa til hele folket: Se, denne sten skal være et vidne mot oss, for den har hørt alle de ord som Herren har talt med oss; og den skal være et vidne mot eder, forat I ikke skal fornekte eders Gud. 
6:24:28: Så lot Josva folket fare, hver til sin arvelodd. 
6:24:29: Nogen tid efter dette døde Josva, Nuns sønn, Herrens tjener, hundre og ti år gammel. 
6:24:30: Og de begravde ham på hans arvelodds grunn i Timnat-Serah, som ligger i Efra'im-fjellene, nordenfor Ga'as-fjellet. 
6:24:31: Og Israel tjente Herren så lenge Josva levde, og så lenge alle de eldste levde som overlevde Josva, og som kjente til alle de gjerninger som Herren hadde gjort for Israel. 
6:24:32: Og Josefs ben, som Israels barn hadde ført op fra Egypten, begravde de ved Sikem på det jordstykke som Jakob hadde kjøpt av Hemors, Sikems fars sønner for hundre kesitter; og Josefs barn fikk det til arvedel. 
6:24:33: Og Eleasar, Arons sønn, døde, og de begravde ham på den haug som hans sønn Pinehas hadde fått i Efra'im-fjellene. 
7:1:1: Efter Josvas død spurte Israels barn Herren: Hvem av oss skal først dra op mot kana'anittene og stride mot dem? 
7:1:2: Og Herren sa: Juda skal dra op; jeg har gitt landet i hans hånd. 
7:1:3: Da sa Juda til sin bror Simeon: Dra op med mig til min lodd og la oss stride mot kana'anittene! Så skal jeg og dra med dig til din lodd. Og Simeon drog med ham. 
7:1:4: Så drog Juda op, og Herren gav kana'anittene og ferisittene i deres hånd; de slo dem i Besek, ti tusen mann. 
7:1:5: I Besek traff de på Adoni-Besek og stred mot ham; de slo kana'anittene og ferisittene, 
7:1:6: men Adoni-Besek flyktet, og de forfulgte ham og grep ham og avhugg hans tommelfingrer og tommeltær. 
7:1:7: Da sa Adoni-Besek: Sytti konger med avhugne tommelfingrer og tommeltær sanket smuler under mitt bord; som jeg gjorde, har Gud gjort mot mig igjen. Og de førte ham til Jerusalem, og der døde han. 
7:1:8: Og Judas barn stred mot Jerusalem og inntok det og slo det med sverdets egg, og de satte ild på byen. 
7:1:9: Siden drog Judas barn ned for å krige mot de kana'anitter som bodde i fjellbygdene og i sydlandet og i lavlandet. 
7:1:10: Og Juda drog imot de kana'anitter som bodde i Hebron - Hebrons navn var før Kirjat-Arba - og de slo Sesai og Akiman og Talmai. 
7:1:11: Derfra drog han mot innbyggerne i Debir - Debirs navn var før Kirjat-Sefer. 
7:1:12: Og Kaleb sa: Den som vinner over Kirjat-Sefer og inntar det, ham vil jeg gi min datter Aksa til hustru. 
7:1:13: Og kenisitten Otniel, Kalebs yngre bror, inntok det; og han gav ham sin datter Aksa til hustru. 
7:1:14: Og da hun kom (til sin manns hus), egget hun ham til å be hennes far om en jordeiendom, og hun sprang ned av asenet. Da sa Kaleb til henne: Hvad vil du? 
7:1:15: Hun svarte: Gi mig en avskjedsgave! Du har giftet mig bort til dette tørre sydlandet, gi mig nu vannkilder! Så gav Kaleb henne de øvre og de nedre kilder. 
7:1:16: Efterkommerne av kenitten, Moses' svoger, drog med Judas barn fra Palmestaden* op til Juda ørken, som ligger sønnenfor Arad; og de kom og bosatte sig blandt folket der. # <* 5MO 34, 3.> 
7:1:17: Siden drog Juda ut med sin bror Simeon, og de slo de kana'anitter som bodde i Sefat; de slo byen med bann; derfor kalte de den Horma. 
7:1:18: Og Juda inntok Gasa med tilhørende bygder og Askalon med tilhørende bygder og Ekron med tilhørende bygder. 
7:1:19: Og Herren var med Juda, så han tok fjellbygdene i eie; men han var ikke i stand til å drive bort innbyggerne i dalen, for de hadde jernvogner. 
7:1:20: Og de gav Hebron til Kaleb, således som Moses hadde sagt, og han drev de tre Anaks sønner bort derfra. 
7:1:21: Men jebusittene, som bodde i Jerusalem, fikk Benjamins barn ikke drevet bort, og jebusittene blev boende sammen med Benjamins barn i Jerusalem og har bodd der til denne dag. 
7:1:22: Josefs barn drog også ut; de gikk imot Betel, og Herren var med dem. 
7:1:23: Og Josefs barn sendte speidere til Betel - byen hette før Luz - 
7:1:24: og speiderne fikk se en mann som gikk ut av byen, og de sa til ham: Vis oss hvor vi kan komme inn i byen! Så vil vi vise barmhjertighet mot dig. 
7:1:25: Og han viste dem hvor de kunde komme inn i byen, og de slo byen med sverdets egg; men mannen og hele hans slekt lot de gå. 
7:1:26: Og mannen drog til hetittenes land, og han bygget en by og kalte den Luz; det navn har den hatt til denne dag. 
7:1:27: Men Manasse drev ikke bort innbyggerne i Bet-Sean med tilhørende småbyer eller i Ta'anak med tilhørende småbyer eller innbyggerne i Dor med tilhørende småbyer eller innbyggerne i Jibleam med tilhørende småbyer eller innbyggerne i Megiddo med tilhørende småbyer; og det lyktes kana'anittene å bli boende der i landet. 
7:1:28: Men da Israel blev sterkt, gjorde de kana'anittene arbeidspliktige, men de drev dem ikke bort. 
7:1:29: Og Efra'im drev ikke bort de kana'anitter som bodde i Geser, men kana'anittene blev boende midt iblandt dem i Geser. 
7:1:30: Sebulon drev ikke bort innbyggerne i Kitron og innbyggerne i Nahalol, men kana'anittene blev boende midt iblandt dem og blev arbeidspliktige. 
7:1:31: Aser drev ikke bort innbyggerne i Akko og innbyggerne i Sidon og Ahlab og Aksib og Helba og Afik og Rehob, 
7:1:32: men aserittene bosatte sig midt iblandt kana'anittene som bodde i landet; de drev dem ikke bort. 
7:1:33: Naftali drev ikke bort innbyggerne i Bet-Semes og innbyggerne i Bet-Anat, men bosatte sig midt iblandt kana'anittene som bodde i landet, og innbyggerne i Bet-Semes og Bet-Anat blev arbeidspliktige under dem. 
7:1:34: Og amorittene trengte Dans barn op i fjellene; de lot dem ikke få komme ned i dalen. 
7:1:35: Det lyktes amorittene å bli boende i Har-Heres, i Ajalon og i Sa'albim; men Josefs barns hånd lå tungt på dem, og de blev arbeidspliktige. 
7:1:36: Amorittenes grense gikk fra Akrabbim-skaret, fra Hassela og opefter. 
7:2:1: Og Herrens engel kom fra Gilgal op til Bokim. Og han sa: Jeg hentet eder op fra Egypten og førte eder til det land jeg tilsvor eders fedre, og sa: Aldri i evighet vil jeg bryte min pakt med eder; 
7:2:2: men I skal ikke gjøre pakt med dette lands innbyggere; deres altere skal I rive ned. Men I hørte ikke på mine ord; hvad har I gjort! 
7:2:3: Jeg sa også: Jeg vil ikke drive dem ut for eder, men de skal bli til brodder i eders sider, og deres guder skal bli til en snare for eder. 
7:2:4: Og da Herrens engel talte disse ord til alle Israels barn, gråt folket høit. 
7:2:5: Derfor kalte de dette sted Bokim*, og de ofret der til Herren. # <* de gråtende.> 
7:2:6: Da Josva hadde latt folket fare, og Israels barn hadde draget hver til sin arv for å ta landet i eie, 
7:2:7: tjente folket Herren så lenge Josva levde, og så lenge alle de eldste levde som overlevde Josva og hadde sett alle de store gjerninger Herren hadde gjort for Israel. 
7:2:8: Men da Josva, Nuns sønn, Herrens tjener, var død, hundre og ti år gammel, 
7:2:9: og de hadde begravet ham på hans arvelodds grunn i Timnat-Heres i Efra'im-fjellene, nordenfor Ga'as-fjellet, 
7:2:10: og da hele denne slekt var samlet til sine fedre, og det efter dem var vokset op en annen slekt, som ikke kjente Herren, og heller ikke de gjerninger han hadde gjort for Israel, 
7:2:11: da gjorde Israels barn det som var ondt i Herrens øine, og dyrket Ba'alene*. # <* hedningenes avguder.> 
7:2:12: De forlot Herren, sine fedres Gud, som hadde ført dem ut av Egyptens land, og de fulgte andre guder av de folks guder som bodde rundt omkring dem, og de tilbad dem og vakte Herrens harme. 
7:2:13: De forlot Herren og dyrket Ba'al og Astarte-billedene. 
7:2:14: Da optendtes Herrens vrede mot Israel, og han gav dem i røveres hånd, som plyndret dem; han solgte dem i deres fienders hånd, de som bodde rundt omkring dem, og de kunde ikke mere stå sig mot sine fiender. 
7:2:15: Overalt hvor de drog ut, var Herrens hånd imot dem, så det gikk dem ille, således som Herren hadde sagt dem, og som Herren hadde svoret, og deres trengsel var stor. 
7:2:16: Da opreiste Herren dommere, og de frelste dem av røvernes hånd. 
7:2:17: Men heller ikke mot sine dommere var de lydige; de holdt sig med andre guder og tilbad dem; de vek snart av fra den vei deres fedre hadde vandret i lydighet mot Herrens bud, og gjorde ikke som de. 
7:2:18: Og når Herren opreiste dem dommere, så var Herren med dommeren og frelste dem av deres fienders hånd så lenge dommeren levde; for Herren ynkedes over dem når de sukket for deres skyld som plaget og undertrykte dem. 
7:2:19: Men når så dommeren døde, falt de igjen tilbake og fór verre frem enn sine fedre: De fulgte andre guder og dyrket dem og tilbad dem; de avstod ikke fra nogen av sine gjerninger eller fra sin gjenstridige ferd. 
7:2:20: Så optendtes Herrens vrede mot Israel, og han sa: Fordi dette folk har brutt min pakt, som jeg oprettet med deres fedre, og ikke har hørt på min røst, 
7:2:21: så vil jeg heller ikke mere drive bort for dem noget av de folk som Josva lot tilbake da han døde. 
7:2:22: Ved dem skulde Israel prøves, om de vilde ta vare på Herrens vei og vandre på den, som deres fedre gjorde, eller ikke. 
7:2:23: Så lot da Herren disse folk bli og hastet ikke med å drive dem bort; han gav dem ikke i Josvas hånd. 
7:3:1: Dette er de folk som Herren lot bli for ved dem å prøve Israel, alle de israelitter som ikke selv hadde vært med i nogen av Kana'anskrigene, 
7:3:2: bare forat de kommende slekter i Israel skulde få kjennskap til krig og bli oplært deri, alle de som ikke før hadde vært med: 
7:3:3: Filistrenes fem fyrster og alle kana'anittene og sidonierne og hevittene, som bodde på Libanon-fjellene, fra Ba'al-Hermon-fjellet til der hvor veien går til Hamat. 
7:3:4: Ved dem var det Israel skulde prøves, så det kunde kjennes om de vilde lyde Herrens bud, som han hadde gitt deres fedre ved Moses. 
7:3:5: Men da Israels barn nu bodde midt iblandt kana'anittene, hetittene og amorittene og ferisittene og hevittene og jebusittene, 
7:3:6: tok de deres døtre til hustruer og gav sine døtre til deres sønner og dyrket deres guder. 
7:3:7: Israels barn gjorde det som var ondt i Herrens øine; de glemte Herren sin Gud og dyrket Ba'alene og Astarte-billedene. 
7:3:8: Da optendtes Herrens vrede mot Israel, og han solgte dem i Kusan-Risata'ims hånd, som var konge i Mesopotamia; og Israels barn tjente Kusan-Risata'im i åtte år. 
7:3:9: Da ropte Israels barn til Herren, og Herren opreiste Israels barn en frelser, og han frelste dem; det var kenisitten Otniel, Kalebs yngre bror. 
7:3:10: Og Herrens Ånd kom over ham; han blev dommer i Israel og drog ut til strid, og Herren gav Kusan-Risata'im, kongen i Mesopotamia, i hans hånd, så han vant over Kusan-Risata'im. 
7:3:11: Og landet hadde ro i firti år; da døde Otniel, Kenas' sønn. 
7:3:12: Men Israels barn gjorde atter det som var ondt i Herrens øine; og Herren gav Eglon, kongen i Moab, makt over Israel, fordi de gjorde det som var ondt i Herrens øine. 
7:3:13: Han fikk med sig Ammons barn og Amalek og drog ut og slo Israel, og de inntok Palmestaden. 
7:3:14: Og Israels barn tjente Eglon, kongen i Moab, i atten år. 
7:3:15: Da ropte Israels barn til Herren, og Herren opreiste dem en frelser, benjaminitten Ehud, Geras sønn, en kjevhendt mann. Israels barn sendte ham engang med en gave til Eglon, kongen i Moab; 
7:3:16: da gjorde Ehud sig et tveegget, alenlangt sverd og bandt det om sig under sine klær ved sin høire hofte. 
7:3:17: Så førte han gaven frem til Eglon, kongen i Moab; men Eglon var en meget fet mann. 
7:3:18: Og da han hadde gitt gaven fra sig, lot han folkene som hadde båret gaven, dra hjem. 
7:3:19: Men selv vendte han tilbake fra stenbruddene ved Gilgal og lot si: Jeg har et hemmelig ord til dig, konge! Da sa kongen: Hysj! Så gikk de ut alle de som stod om ham. 
7:3:20: Og Ehud kom inn til ham, mens han satt alene i sin kjølige sal, og Ehud sa: Jeg har et ord fra Gud til dig. Da reiste han sig op fra sin stol. 
7:3:21: Men Ehud rakte ut sin venstre hånd og tok sverdet fra sin høire hofte og støtte det i hans buk; 
7:3:22: og heftet gikk inn efter bladet, og fettet lukket til om bladet, for han drog ikke sverdet ut av hans buk; det gikk ut baktil. 
7:3:23: Og Ehud gikk ut i buegangen, og han lukket døren til salen hvor han var, og låste den. 
7:3:24: Og da han var gått ut, kom tjenerne inn, og da de så at døren til salen var låst, sa de: Han har visst et ærend i svalkammeret. 
7:3:25: Og de ventet meget lenge, men han lukket ikke op døren til salen; da tok de nøklen og lukket op, og se, deres herre lå død på gulvet. 
7:3:26: Mens de gav sig tid, slapp Ehud bort; han var allerede kommet forbi stenbruddene og kom frem til Se'ira. 
7:3:27: Da han kom dit, støtte han i basunen på Efra'im-fjellet; og Israels barn drog ned med ham fra fjellene, og han foran dem. 
7:3:28: Og han sa til dem: Skynd eder efter mig! For Herren har gitt eders fiender, moabittene, i eders hånd. Så drog de ned efter ham, og de satte folk ved vadestedene over Jordan til Moab og lot ingen komme over. 
7:3:29: Dengang slo de omkring ti tusen mann av Moab, som alle var sterke og djerve menn; ikke én slapp bort. 
7:3:30: Så blev da Moab på den dag ydmyket under Israels hånd, og landet hadde ro i åtti år. 
7:3:31: Efter ham kom Samgar, Anats sønn; han slo seks hundre mann av filistrene med en oksedrivers stav; også han frelste Israel. 
7:4:1: Da Ehud var død, gjorde Israels barn atter det som var ondt i Herrens øine. 
7:4:2: Og Herren solgte dem i Jabins, Kana'ans konges hånd; han regjerte i Hasor; hans hærfører var Sisera, som bodde i Haroset-Haggojim. 
7:4:3: Og Israels barn ropte til Herren; for han hadde ni hundre jernvogner, og han trengte Israels barn hårdt i tyve år. 
7:4:4: Og Debora, en profetinne, Lappidots hustru, dømte Israel på denne tid; 
7:4:5: hun satt under Deboras palmetre mellem Rama og Betel på Efra'im-fjellet, og Israels barn gikk op til henne forat hun skulde skifte rett mellem dem. 
7:4:6: Hun sendte bud og kalte til sig Barak, Abinoams sønn, fra Kedes i Naftali og sa til ham: Hør hvad Herren, Israels Gud, har befalt: Gå av sted og dra op på Tabor-fjellet og ta med dig ti tusen mann av Naftalis barn og av Sebulons barn! 
7:4:7: Og jeg vil dra Sisera, Jabins hærfører, og hans vogner og hans hær bort til dig, til Kison-bekken, og gi ham i din hånd. 
7:4:8: Da sa Barak til henne: Hvis du går med mig, vil jeg gå; men hvis du ikke går med mig, så går heller ikke jeg. 
7:4:9: Og hun sa: Gå med dig skal jeg; men æren skal dog ikke bli din på den vei du går, for i en kvinnes hånd skal Herren selge Sisera. Så gjorde Debora sig rede og gikk med Barak til Kedes. 
7:4:10: Og Barak stevnet Sebulon og Naftali sammen til Kedes, og ti tusen mann drog op efter ham; og Debora gikk med ham. 
7:4:11: Men kenitten Heber hadde skilt sig fra kenittene, fra Hobabs, Moses' svogers barn; og han flyttet med sine telt like til Sa'ana'im-eken ved Kedes. 
7:4:12: Da Sisera fikk spurt at Barak, Abinoams sønn, hadde draget op på Tabor-fjellet, 
7:4:13: stevnet han sammen alle sine vogner, ni hundre jernvogner, og alt det folk som var med ham, at de skulde komme fra Haroset-Haggojim til Kison-bekken. 
7:4:14: Da sa Debora til Barak: Op! Dette er den dag da Herren har gitt Sisera i din hånd; drar ikke Herren ut foran dig? Så drog Barak ned fra Tabor-fjellet, og ti tusen mann fulgte ham. 
7:4:15: Da slo Herren Sisera og alle vognene og hele hæren med redsel, så de ikke kunde stå sig for Baraks sverd; og Sisera steg ned av vognen og flyktet til fots. 
7:4:16: Men Barak forfulgte vognene og hæren til Haroset-Haggojim, og hele Siseras hær falt for sverdets egg; det blev ikke en eneste tilbake. 
7:4:17: Sisera flyktet til fots til Jaels telt; hun var kenitten Hebers hustru, og der var fred mellem Jabin, kongen i Hasor, og kenitten Hebers hus. 
7:4:18: Da gikk Jael ut mot Sisera og sa til ham: Kom inn, herre, kom inn til mig, frykt ikke! Og han gikk inn i teltet til henne, og hun gjemte ham under teppet. 
7:4:19: Og han sa til henne: Kjære, gi mig litt vann å drikke! Jeg er tørst. Da åpnet hun melkeflasken og lot ham drikke og dekket ham til. 
7:4:20: Og han sa til henne: Stå i teltdøren, og dersom det kommer nogen og spør dig: Er det nogen her? da si nei! 
7:4:21: Men Jael, Hebers hustru, tok en teltplugg og gikk sakte inn til ham med en hammer i hånden og slo pluggen gjennem tinningen på ham, så den gikk ned i jorden; for trett som han var, var han falt i tung søvn. Således døde han. 
7:4:22: Da nu Barak forfulgte Sisera, gikk Jael ut imot ham og sa til ham: Kom, så skal jeg vise dig den mann du søker efter. Og han gikk inn til henne, og se, der lå Sisera død med pluggen gjennem tinningen. 
7:4:23: Så ydmyket Gud på denne dag Jabin, Kana'ans konge, for Israels barns øine. 
7:4:24: Og Israels barns hånd lå tyngre og tyngre på Jabin, Kana'ans konge, til de helt hadde gjort ende på Jabin, Kana'ans konge. 
7:5:1: Dengang sang Debora og Barak Abinoams sønn, denne sang: 
7:5:2: Lov Herren, fordi fyrstene i Israel gikk først i striden fordi folket møtte villig frem! 
7:5:3: Hør, I konger! Lytt til, I høvdinger! Jeg - jeg vil synge om Herren; jeg vil lovsynge Herren, Israels Gud. 
7:5:4: Herre, da du gikk ut fra Se'ir, da du skred frem fra Edoms mark, da bevet jorden, himlene dryppet, skyene dryppet av vann. 
7:5:5: Fjellene skalv for Herrens åsyn, Sinai der borte skalv for Herrens, Israels Guds åsyn. 
7:5:6: I Samgars, Anats sønns dager, i Jaels dager var stiene øde, de veifarende gikk på krokete stier. 
7:5:7: Det fantes ingen fører i Israel, nei, ingen før jeg, Debora, stod frem, før jeg stod frem, en mor i Israel. 
7:5:8: De valgte nye guder, da var der kamp ved portene; ei såes skjold, ei heller spyd blandt firti tusen i Israel. 
7:5:9: Mitt hjerte slår for Israels høvdinger og for dem som villig møtte frem blandt folket. Pris Herren! 
7:5:10: Syng, I som rider på lyse aseninner, I som sitter på eders tepper, og I som vandrer på veien! 
7:5:11: Bueskytternes røst lyder ved brønnene; der priser de Herrens rettferdige verk, hans styrelses rettferdige verk i Israel. Dengang steg de ned til portene, Herrens folk. 
7:5:12: Våkn op, våkn op, Debora, våkn op, våkn op, stem i en sang! Stå op, Barak, og før dine fanger i fangenskap, du Abinoams sønn! 
7:5:13: Dengang lød det: Stig ned, du lille flokk, stig ned til heltene, du folk! Herre, stig ned og strid for mig blandt kjempene! 
7:5:14: Av Efra'im steg de ned, de som har sin rot på Amaleks fjell; efter dig kom Benjamin blandt dine folk; av Makir steg høvdinger ned, og av Sebulon de som drar frem med hærførerstav, 
7:5:15: og fyrster i Issakar med Debora, og som Issakar, så Barak; i dalen styrter de avsted i hans følge. Ved Rubens bekker holdtes store rådslagninger. 
7:5:16: Hvorfor blev du sittende mellem fehegnene og hørte på hyrdefløitene? Ved Rubens bekker holdtes store rådslagninger. 
7:5:17: Gilead - hinsides Jordan blev han i ro, og Dan - hvorfor dvelte han ved skibene? Aser blev sittende ved havets bredd, og ved sine bukter blev han i ro. 
7:5:18: Sebulon er et folk som ringeakter sitt liv inntil døden, likeså Naftali på sine fjell. 
7:5:19: Konger kom, de stred, dengang stred Kana'ans konger ved Ta'anak, ved Megiddos vann; hærfang av sølv fikk de ikke. 
7:5:20: Fra himmelen stred de, stjernene fra sine baner, de stred mot Sisera. 
7:5:21: Kisons bekk rev dem bort, den eldgamle bekk, Kisons bekk. Skrid frem, min sjel, med kraft! 
7:5:22: Da trampet hestenes hover under kjempenes jagende, jagende fart. 
7:5:23: Forbann Meros, sa Herrens engel, forbann, forbann dets folk, fordi de ikke kom Herren til hjelp, Herren til hjelp blandt kjempene. 
7:5:24: Priset fremfor kvinner være Jael, kenitten Hebers hustru! Fremfor kvinner i telt være hun priset! 
7:5:25: Vann bad han om, melk gav hun ham; i prektig skål bar hun frem fløte. 
7:5:26: Sin hånd rekker hun ut efter teltpluggen og sin høire hånd efter arbeidshammeren; hun slår Sisera med hammeren, knuser hans hode, sønderslår og gjennemborer hans tinning. 
7:5:27: For hennes føtter seg han ned, falt han, lå han; for hennes føtter seg han ned, falt han; der han seg ned, der lå han fellet. 
7:5:28: Gjennem vinduet skuer hun og roper, Siseras mor, ut gjennem gitteret: Hvorfor venter hans vogn med å komme? Hvorfor dveler hans traveres skritt? 
7:5:29: De klokeste av hennes fruer svarer henne, og selv gir hun sig svar på sine ord: 
7:5:30: Til visse finner de hærfang og skifter: En kvinne, to kvinner til manns, hærfang av farvede klær for Sisera, hærfang av farvede klær, utsydde klær, én farvet klædning, to utsydde halsduker for hver av de hærtatte kvinner! 
7:5:31: Måtte alle dine fiender omkomme således, Herre, og de som elsker ham, være som solen når den går frem i sin kraft! - Og landet hadde ro i firti år. 
7:6:1: Og Israels barn gjorde det som var ondt i Herrens øine, og Herren gav dem i midianittenes hånd i syv år. 
7:6:2: Og midianittenes hånd blev overmektig over Israel; det var for midianittenes skyld Israels barn gjorde sig de hulninger som finnes i fjellene, og hulene og fjellborgene. 
7:6:3: Hver gang Israel hadde sådd, kom midianittene og amalekittene og Østens barn og drog op mot dem; 
7:6:4: de leiret sig mot dem og ødela landets grøde like til Gasa og levnet ikke noget å leve av i Israel, heller ikke småfe eller storfe eller asener. 
7:6:5: For de drog op med sine hjorder og sine telt; de kom som gresshopper i mengde; det var ikke tall på dem og deres kameler, og de kom inn i landet og herjet det. 
7:6:6: Og Israel blev rent utarmet ved midianittene; da ropte Israels barn til Herren. 
7:6:7: Og da Israels barn ropte til Herren for midianittenes skyld, 
7:6:8: da sendte Herren en profet til Israels barn, og han sa til dem: Så sier Herren, Israels Gud: Jeg førte eder op fra Egypten og hentet eder ut av trælehuset, 
7:6:9: jeg fridde eder av egypternes hånd og av alle deres hånd som undertrykte eder, jeg drev dem bort for eder og gav eder deres land. 
7:6:10: Og jeg sa til eder: Jeg er Herren eders Gud; I skal ikke frykte de guder som dyrkes av amorittene, i hvis land I bor. Men I hørte ikke på min røst. 
7:6:11: Og Herrens engel kom og satte sig under den ek som står i Ofra, der hvor Joas av Abiesers ætt rådet. Gideon, hans sønn, stod da og tresket hvete i vinpersen for å berge den for midianittene. 
7:6:12: Og Herrens engel åpenbarte sig for ham og sa til ham: Herren er med dig, du djerve kjempe! 
7:6:13: Men Gideon sa til ham: Hør på mig, Herre! Er Herren med oss, hvorfor har da alt dette rammet oss, og hvor er alle hans undergjerninger som våre fedre har fortalt oss om, idet de sa: Førte ikke Herren oss op av Egypten? Men nu har Herren forlatt oss og gitt oss i midianittenes hånd. 
7:6:14: Da vendte Herren sig til ham og sa: Gå avsted, så sterk som du er, så skal du frelse Israel av midianittenes hånd; har jeg ikke sendt dig? 
7:6:15: Men han sa til ham: Hør på mig, Herre! Hvorledes skal jeg frelse Israel? Min ætt er jo den ringeste i Manasse, og jeg er den yngste i min fars hus. 
7:6:16: Da sa Herren til ham: Jeg vil være med dig, og du skal slå midianittene ned som én mann. 
7:6:17: Da sa Gideon til ham: Dersom jeg har funnet nåde for dine øine, da gi mig et tegn på at det er du som taler med mig! 
7:6:18: Gå ikke herfra før jeg kommer ut til dig med min gave og legger den frem for ditt åsyn! Og han sa: Jeg skal bli her til du kommer tilbake. 
7:6:19: Så gikk Gideon inn og laget til et kje og en efa mel til usyrede kaker; kjøttet la han i en kurv, og suppen hadde han i en krukke og bar det ut til ham under eken og satte det frem. 
7:6:20: Da sa Guds engel til ham: Ta kjøttet og de usyrede kaker og legg det på stenen der, og hell suppen over! Og han gjorde så. 
7:6:21: Og Herrens engel rakte ut staven han hadde i hånden, og rørte med enden av den ved kjøttet og de usyrede kaker; da steg det ild op fra stenen og fortærte kjøttet og de usyrede kaker, mens Herrens engel fór bort for hans øine. 
7:6:22: Da så Gideon at det var Herrens engel, og han sa: Ve mig, Herre, Herre, jeg som har sett Herrens engel åsyn til åsyn! 
7:6:23: Men Herren sa til ham: Fred være med dig! Frykt ikke! Du skal ikke dø. 
7:6:24: Da bygget Gideon der et alter for Herren og kalte det: Herren er fred. Det står ennu den dag idag i Abieser-ættens Ofra. 
7:6:25: Samme natt sa Herren til ham: Ta din fars okse, den andre syvårsgamle okse, og du skal rive ned det Ba'als-alter som din far har, og hugge ned det Astarte-billede som står ved siden av det, 
7:6:26: og du skal bygge et alter for Herren din Gud øverst på denne faste plass og legge alt til rette; så skal du ta den andre okse og ofre den som brennoffer med veden av Astarte-billedet som du skal hugge ned. 
7:6:27: Da tok Gideon ti av sine tjenere med sig og gjorde som Herren hadde sagt til ham; og da han av frykt for sin slekt og for mennene i byen ikke torde gjøre det om dagen, gjorde han det om natten. 
7:6:28: Da mennene i byen stod op tidlig om morgenen, fikk de se at Ba'als alter var nedrevet, og at Astarte-billedet som stod ved siden av, var hugget ned, og at den andre okse var ofret på det alter som var bygget. 
7:6:29: Da sa de sig imellem: Hvem har gjort dette? Og de spurte og ransaket, og de sa: Gideon, Joas' sønn, har gjort det. 
7:6:30: Da sa mennene i byen til Joas: Før din sønn ut, han skal dø! Han har revet ned Ba'als alter, og han har omhugget Astarte-billedet som stod ved siden av. 
7:6:31: Men Joas sa til alle dem som stod omkring ham: Vil I stride for Ba'al, vil I hjelpe ham? Den som strider for ham, skal miste livet innen imorgen. Er han Gud, da la ham stride for sig selv, siden de har revet ned hans alter. 
7:6:32: Den dag fikk Gideon navnet Jerubba'al*, fordi de sa: La Ba'al stride mot ham, fordi han har revet ned hans alter. # <* d.e. Ba'al stride!.> 
7:6:33: Og alle midianittene og amalekittene og Østens barn slo sig sammen og drog over Jordan, og de leiret sig i Jisre'el-dalen. 
7:6:34: Men Herrens Ånd kom over Gideon; han støtte i basunen, og Abieser-ætten samlet sig og fulgte ham. 
7:6:35: Og han sendte bud omkring i hele Manasse, og de samlet sig også og fulgte ham; og han sendte bud til Aser og til Sebulon og til Naftali, og de drog dem i møte. 
7:6:36: Da sa Gideon til Gud: Dersom du vil frelse Israel ved min hånd, som du har sagt, 
7:6:37: så legger jeg nu denne avklippede ull på treskeplassen; dersom det da kommer dugg på ullen alene, og hele marken er tørr, da vet jeg at du vil frelse Israel ved min hånd, som du har sagt. 
7:6:38: Og således blev det; da han den næste morgen stod tidlig op og krystet ullen, krystet han ut dugg av den, en skål full av vann. 
7:6:39: Og Gideon sa til Gud: La ikke din vrede optendes mot mig, om jeg taler ennu en gang! Jeg vilde bare få gjøre én prøve til med ullen! La ullen alene være tørr, og la det være dugg over hele marken! 
7:6:40: Og natten efter gjorde Gud således; ullen alene var tørr, men over hele marken var det dugg. 
7:7:1: Og Jerubba'al, det er Gideon, og alt folket som var med ham, tok tidlig ut og leiret sig ved Harodkilden; men midianittenes leir lå nordenfor, fra More-høiden og ned i dalen. 
7:7:2: Da sa Herren til Gideon: Du har for mange folk med dig til at jeg skulde gi midianittene i deres hånd; for da kunde Israel rose sig mot mig og si: Min egen hånd har frelst mig. 
7:7:3: La nu utrope for folket: Den som er fryktsom og redd, kan vende om og fare hjem igjen fra Gileadfjellet! Da vendte to og tyve tusen av folket om, og ti tusen blev igjen. 
7:7:4: Men Herren sa til Gideon: Ennu er det for mange folk; la dem gå ned til vannet, så vil jeg prøve dem for dig der, og den som jeg sier skal gå med dig, han skal gå med dig; men hver den som jeg sier ikke skal gå med dig, skal ikke gå. 
7:7:5: Så lot han folket gå ned til vannet, og Herren sa til Gideon: Alle som lepjer vannet i sig med tungen likesom hunden, skal du stille for sig selv, og likeså alle som legger sig på kne for å drikke. 
7:7:6: Og tallet på dem som lepjet med hånden til munnen, var tre hundre; men hele resten av folket la sig på kne og drakk av vannet. 
7:7:7: Da sa Herren til Gideon: Med de tre hundre mann som lepjet, vil jeg frelse eder og gi midianittene i din hånd; men resten av folket kan gå hver til sitt. 
7:7:8: Så tok de til sig folkets matforråd og deres basuner, og alle de andre Israels menn lot han fare hver til sitt telt; men de tre hundre mann lot han bli igjen hos sig. Og midianittenes leir var lenger nede i dalen. 
7:7:9: Og samme natt sa Herren til ham: Stå op og dra ned imot leiren! Jeg har gitt den i din hånd. 
7:7:10: Men er du redd for å dra ned, så gå med din tjener Pura ned til leiren 
7:7:11: Og hør efter hvad de sier; så skal du kjenne dig så sterk at du tør dra ned imot leiren. Og han og hans tjener Pura gikk ned til de krigsfolk som holdt vakt ytterst i leiren. 
7:7:12: Men midianittene og amalekittene og alle Østens barn lå der i dalen så tett som gresshopper, og det var ikke tall på deres kameler; de var tallrike som sanden på havets bredd. 
7:7:13: Med det samme Gideon kom, var der en mann som fortalte en annen en drøm og sa: Jeg hadde en drøm, og se, et byggbrød kom rullende imot midianittenes leir, og da det kom til teltet, støtte det til det, så det falt; det kastet det over ende, og teltet lå der. 
7:7:14: Da svarte den andre og sa: Dette er ikke noget annet enn israelitten Gideons, Joas' sønns sverd; Gud har gitt midianittene og hele leiren i hans hånd. 
7:7:15: Og da Gideon hørte denne drøm fortalt og hvorledes den blev tydet, tilbad han. og han vendte tilbake til Israels leir og sa: Stå op! Herren har gitt midianittenes leir i eders hånd. 
7:7:16: Og han delte de tre hundre mann i tre flokker og gav dem alle sammen basuner i hendene og tomme krukker, og inne i krukkene var det fakler. 
7:7:17: Og han sa til dem: I skal se på mig og gjøre som jeg; når jeg kommer til utkanten av leiren, så skal I gjøre som jeg gjør. 
7:7:18: Når jeg støter i basunen, jeg og alle de som er med mig, da skal også I støte i basunene rundt omkring hele leiren og rope: For Herren og for Gideon! 
7:7:19: Så kom Gideon og hundre mann som var med ham, til utkanten av leiren ved begynnelsen av den mellemste nattevakt; de hadde just satt ut vaktpostene. Og de støtte i basunene og slo i stykker krukkene som de hadde i hånden. 
7:7:20: Og alle de tre flokker støtte i basunene og slo i stykker krukkene; med venstre hånd grep de faklene og med høire hånd basunene og støtte i dem og ropte: Sverd for Herren og for Gideon! 
7:7:21: Og de blev stående hver på sitt sted rundt omkring leiren; da begynte alle i leiren å løpe og skrike og flykte. 
7:7:22: Og de støtte i de tre hundre basuner, og Herren vendte den enes sverd imot den andre i hele leiren; og de som var i leiren, flyktet til Bet-Hasitta bortimot Serera, til elvebredden ved Abel-Mehola forbi Tabbat. 
7:7:23: Og Israels menn av Naftali og av Aser og av hele Manasse blev kalt til våben og forfulgte midianittene. 
7:7:24: Og Gideon sendte bud omkring på hele Efra'im-fjellet og lot si: Dra ned imot midianittene og avsteng dem fra vannene like til Bet-Bara og Jordan! Så blev da hver mann i Efra'im kalt til våben, Og de stengte veien til vannene like til Bet-Bara og Jordan. 
7:7:25: De tok to av midianittenes fyrster, Oreb og Se'eb, og de drepte Oreb på Orebs klippe, og Se'eb drepte de i Se'ebs vinperse; så forfulgte de midianittene. Orebs og Se'ebs hoder hadde de med sig til Gideon på den andre side av Jordan. 
7:8:1: Og Efra'ims menn sa til ham: Hvad har du gjort imot oss! Hvorfor sendte du ikke bud efter oss da du drog i strid mot midianittene? Og de gikk sterkt i rette med ham. 
7:8:2: Men han sa til dem: Hvad har jeg gjort her som kan lignes med det I har gjort? Er ikke Efra'ims efterhøst bedre enn Abiesers vinhøst? 
7:8:3: I eders hånd gav Gud midianittenes fyrster, Oreb og Se'eb, og hvad har jeg maktet å gjøre som kan lignes med det I har gjort? Da han talte således, la deres vrede sig, og de lot ham være i fred. 
7:8:4: Gideon kom til Jordan og gikk over, han og de tre hundre mann som var med ham, trette som de var blitt ved å forfølge fienden. 
7:8:5: Da sa han til mennene i Sukkot: Kjære, gi de folk som følger mig, nogen brød! De er trette, og jeg holder på å forfølge Sebah og Salmunna, midianittenes konger. 
7:8:6: Men høvdingene i Sukkot sa: Har du da alt Sebahs og Salmunnas hender i din hånd, så vi skulde gi din hær brød? 
7:8:7: Da sa Gideon: Nuvel, når Herren gir Sebah og Salmunna i min hånd, da vil jeg treske eders kjøtt med ørkenens torner og med tistler. 
7:8:8: Og han drog derfra op til Pnuel og talte til dem på samme måte; men mennene i Pnuel svarte ham som mennene i Sukkot hadde svart. 
7:8:9: Da sa han til dem og: Når jeg kommer vel hjem igjen, vil jeg rive ned dette tårn. 
7:8:10: Men Sebah og Salmunna lå i Karkor med sine hærer, omkring femten tusen mann; det var alt som var igjen av hele østerlendingenes hær; men de falne var hundre og tyve tusen mann som kunde dra sverd. 
7:8:11: Og Gideon drog op ad teltboernes vei østenfor Nobah og Jogbea; og han slo hæren mens den var trygg. 
7:8:12: Sebah og Salmunna flyktet, og han forfulgte dem; og han tok begge midianittenes konger, Sebah og Salmunna, til fange og skremte fra hverandre hele hæren. 
7:8:13: Og Gideon, Joas' sønn, vendte tilbake fra striden, fra Heres-skaret. 
7:8:14: Og han fikk fatt på en ung mann som hørte hjemme i Sukkot, og spurte ham ut; og han skrev op for ham høvdingene og de eldste i Sukkot, syv og sytti mann. 
7:8:15: Og han kom til mennene Sukkot og sa: Se, her er Sebah og Salmunna, for hvis skyld I hånte mig og sa: Har du da alt Sebahs og Salmunnas hender i din hånd, så vi skulde gi dine trette menn brød? 
7:8:16: Og han grep byens eldste, og han tok ørkenens torner og tistler og tuktet med dem mennene i Sukkot. 
7:8:17: Og tårnet i Pnuel rev han ned og slo ihjel byens menn. 
7:8:18: Og han sa til Sebah Og Salmunna: Hvordan så de ut de menn som I slo ihjel på Tabor? Og de sa: De var som du, hver av dem som en kongesønn å se til. 
7:8:19: Da sa han: Det var mine brødre, min mors sønner; så sant Herren lever, dersom I hadde latt dem leve, skulde jeg ikke slått eder ihjel. 
7:8:20: Og han sa til Jeter, sin førstefødte sønn: Kom og slå dem ihjel! Men gutten drog ikke sitt sverd; han var redd, fordi han var ennu bare en gutt. 
7:8:21: Da sa Sebah og Salmunna: Kom selv og hugg oss ned! For som mannen er, så er hans styrke. Så gikk Gideon frem og slo Sebah og Salmunna ihjel, og han tok de halvmåner som deres kameler hadde om halsen. 
7:8:22: Da sa Israels menn til Gideon: Hersk over oss, både du og din sønn og din sønnesønn! For du har frelst oss av midianittenes hånd. 
7:8:23: Men Gideon sa til dem: Jeg vil ikke herske over eder, og min sønn skal ikke herske over eder; Herren skal herske over eder. 
7:8:24: Og Gideon sa til dem: Jeg vil be eder om noget: at I alle vil gi mig de ringer I har tatt til bytte! For midianittene gikk med gullringer; de var ismaelitter. 
7:8:25: Og de sa: Vi vil gjerne gi dig dem. Og de bredte ut et klæde, og på det kastet hver av dem de ringer han hadde tatt til bytte. 
7:8:26: Og vekten av de gullringer han hadde bedt om, var et tusen og syv hundre sekel gull foruten de halvmåner og øresmykker og purpurklær som midianittenes konger hadde på sig, og foruten de kjeder som deres kameler hadde om halsen. 
7:8:27: Og Gideon gjorde en livkjortel av gullet og stilte den op i sin by i Ofra; og hele Israel drev der avgudsdyrkelse med den; og den blev en snare for Gideon og hans hus. 
7:8:28: Således blev midianittene ydmyket under Israels barn, og de løftet ikke mere sitt hode, og landet hadde ro i firti år, så lenge Gideon levde. 
7:8:29: Så drog Jerubba'al, Joas' sønn, hjem til sitt hus og blev boende der. 
7:8:30: Gideon hadde sytti sønner som var kommet av hans lend; for han hadde mange hustruer. 
7:8:31: Og han hadde en medhustru som bodde i Sikem; med henne fikk han også en sønn og kalte ham Abimelek. 
7:8:32: Og Gideon, Joas' sønn, døde i høi alder og blev begravet i sin far Joas' grav i Ofra, som tilhørte Abiesers ætt. 
7:8:33: Men da Gideon var død, begynte Israels barn igjen å drive avgudsdyrkelse med Ba'alene, og de tok sig Ba'al-Berit til gud. 
7:8:34: Israels barn kom ikke i hu Herren sin Gud, som hadde frelst dem av alle deres fienders hånd rundt omkring, 
7:8:35: og heller ikke viste de Jerubba'als, Gideons, hus nogen kjærlighet til gjengjeld for alt det gode han hadde gjort mot Israel. 
7:9:1: Og Abimelek, Jerubba'als sønn, drog til Sikem, til sin mors brødre; og han talte til dem og til hele sin morfars slekt og sa: 
7:9:2: Tal til alle Sikems menn og si: Hvad er best for eder, at sytti menn, alle Jerubba'als sønner, hersker over eder, eller at én mann hersker over eder? Og kom i hu at jeg er av samme kjød og blod som I! 
7:9:3: Da sa hans mors brødre alt dette om ham til alle Sikems menn; og deres hu vendte sig til Abimelek, for de tenkte: Han er jo vår bror. 
7:9:4: Så gav de ham sytti sekel sølv fra Ba'al-Berits tempel; for dem leide Abimelek nogen løse og frekke menn, og de fulgte ham. 
7:9:5: Og han kom til sin fars hus i Ofra og slo ihjel sine brødre, Jerubba'als sønner, sytti mann på én sten; men Jotam, Jerubba'als yngste sønn, blev igjen, for han hadde skjult sig. 
7:9:6: Da samlet alle Sikems menn sig og alle de som bodde i Millo*, og de gikk avsted og gjorde Abimelek til konge ved minnesmerkets ek i Sikem**. # <* Sikems borg, DMR 9, 46.> # <** JOS 24, 25. 26.> 
7:9:7: Da Jotam fikk vite dette, gikk han op på toppen av Gerisim-fjellet; der stod han og ropte høit og sa til dem: Hør på mig, I Sikems menn! Så skal Gud høre på eder. 
7:9:8: Det hendte engang at trærne vilde salve sig en konge, og de sa til oljetreet: Vær konge over oss! 
7:9:9: Men oljetreet sa til dem: Skulde jeg gi avkall på min fedme, som Gud og mennesker ærer mig for, og gi mig til å svaie over trærne? 
7:9:10: Da sa trærne til fikentreet: Kom du og vær konge over oss! 
7:9:11: Men fikentreet sa til dem: Skulde jeg gi avkall på min sødme og min gode frukt og gi mig til å svaie over trærne? 
7:9:12: Da sa trærne til vintreet: Kom du og vær konge over oss! 
7:9:13: Men vintreet sa til dem: Skulde jeg gi avkall på min most, som gleder Gud og mennesker, og gi mig til å svaie over trærne? 
7:9:14: Da sa alle trærne til tornebusken: Kom du og vær konge over oss! 
7:9:15: Og tornebusken sa til trærne: Dersom det er eders opriktige mening at I vil salve mig til konge over eder, da kom og søk ly i min skygge! Men hvis ikke, da skal det utgå ild fra tornebusken og fortære Libanons sedertrær. 
7:9:16: Så hør nu: Dersom I har gått opriktig og ærlig frem ved å gjøre Abimelek til konge, og dersom I har gjort vel imot Jerubba'al og hans hus, og dersom I har gjengjeldt ham det han gjorde 
7:9:17: dengang min far stred for eder og vågde sitt liv og frelste eder av midianittenes hånd 
7:9:18: - men I har idag reist eder imot min fars hus og slått ihjel hans sønner, sytti mann på én sten, og gjort hans trælkvinnes sønn, Abimelek, til konge over Sikems menn, fordi han er eders bror - 
7:9:19: dersom I nu idag har gått opriktig og ærlig frem imot Jerubba'al og hans hus, da gled eder over Abimelek, og da glede også han sig over eder! 
7:9:20: Men hvis ikke, da gå det ut ild fra Abimelek og fortære Sikems borgere og dem som bor i Millo, og ild fra Sikems menn og fra dem som bor i Millo, og fortære Abimelek. 
7:9:21: Så tok Jotam flukten, og han undkom og drog til Be'er; der slo han sig ned, så han kunde være i sikkerhet for sin bror Abimelek. 
7:9:22: Abimelek regjerte over Israel i tre år. 
7:9:23: Da sendte Gud en ond ånd, som satte splid mellem Abimelek og Sikems menn, og Sikems menn falt fra Abimelek. 
7:9:24: Dette skjedde forat voldsgjerningen mot Jerubba'als sytti sønner skulde komme over Abimelek og deres blod hevnes på ham, deres bror, som hadde slått dem ihjel, og på Sikems menn, som hadde støttet ham, så han kunde slå sine brødre ihjel. 
7:9:25: Og Sikems menn la folk på lur mot ham øverst oppe på fjellene, og de plyndret enhver som drog forbi dem på veien; dette blev sagt Abimelek. 
7:9:26: Så kom Ga'al, Ebeds sønn, og hans brødre og drog inn i Sikem; og Sikems menn satte lit til ham. 
7:9:27: De gikk ut på marken og høstet sine vingårder og perset druene og holdt gledesfest; så gikk de inn i sin guds hus og åt og drakk og bante Abimelek. 
7:9:28: Og Ga'al, Ebeds sønn, sa: Hvem er Abimelek, og hvad er Sikem, at vi skulde tjene ham? Er han ikke Jerubba'als sønn, og Sebul hans foged? Tjen de menn som stammer fra Hemor*, Sikems far! Men hvorfor skulde vi tjene denne? # <* 1MO 34, 2.> 
7:9:29: Hadde jeg bare dette folk i min hånd, så skulde jeg nok få Abimelek bort. Og han sa: Øk din hær, Abimelek, og dra ut! 
7:9:30: Da Sebul, byens høvedsmann, hørte hvad Ga'al, Ebeds sønn, hadde sagt, optendtes hans vrede, 
7:9:31: og han sendte hemmelig bud til Abimelek og lot si: Ga'al, Ebeds sønn, og hans brødre er kommet til Sikem, og de egger byen op imot dig. 
7:9:32: Bryt nu op ved nattetid med de folk du har hos dig, og legg dig i bakhold på marken, 
7:9:33: og tidlig imorgen, så snart solen står op, bryt da frem og overfall byen! Da vil han og de folk han har hos sig, dra ut mot dig, og du kan gjøre med ham som du får leilighet til. 
7:9:34: Så brøt Abimelek op om natten med alle de folk han hadde hos sig, og de la sig i bakhold mot Sikem i fire hoper. 
7:9:35: Nu kom Ga'al, Ebeds sønn, ut og stilte sig i byporten, og Abimelek og de folk han hadde hos sig, brøt frem fra bakholdet. 
7:9:36: Da Ga'al så folkene, sa han til Sebul: Se, det kommer folk ned fra fjelltoppene! Men Sebul sa til ham: Det er skyggen av fjellene du tar for folk. 
7:9:37: Men Ga'al sa atter: Jo, det kommer folk ned fra midtfjellet, og én hop kommer på veien som fører til trollmanns-eken. 
7:9:38: Da sa Sebul til ham: Hvor er nu dine store ord, du som sa: Hvem er Abimelek, at vi skulde tjene ham? Der er jo de folk du foraktet! Gå nu ut og strid imot dem! 
7:9:39: Da drog Ga'al ut, og Sikems menn fulgte ham, og de stred imot Abimelek. 
7:9:40: Men Abimelek slo ham på flukt og forfulgte ham, og det blev et stort mannefall helt bort til byporten. 
7:9:41: Og Abimelek gav sig til i Aruma, og Sebul drev Ga'al og hans brødre bort, så de ikke blev boende lenger i Sikem. 
7:9:42: Den næste dag gikk folket ut på marken; det fikk Abimelek vite. 
7:9:43: Da tok han og delte sine folk i tre hoper og la sig i bakhold på marken; og da han så at folket kom ut av byen, rykket han imot dem og slo dem. 
7:9:44: Abimelek og de hoper som var med ham, overfalt byen og stilte sig i porten, og de to hoper overfalt alle dem som var på marken, og slo dem. 
7:9:45: Og Abimelek stred imot byen hele den dag og inntok byen, og folket som var der, slo han ihjel; og han rev byen ned og strødde salt over den. 
7:9:46: Da mennene i Sikems borg hørte det, gikk de alle inn i tårnet som hørte til El-Berits* tempel. # <* d.s.s. Ba'al-Berit; se DMR 9, 4.> 
7:9:47: Så snart det blev meldt Abimelek at alle mennene i Sikems borg hadde samlet sig, 
7:9:48: gikk han op på Salmon-fjellet med alle de folk han hadde hos sig. Han tok en øks i hånden og hugg grener av trærne, tok dem op, la dem på skulderen og sa til de folk som var med ham: Skynd eder og gjør like ens som I så jeg gjorde! 
7:9:49: Da hugg alle folkene også hver sin bør med grener og gikk efter Abimelek; så la de grenene opefter tårnet og satte med dem ild på tårnet. Således omkom alle innbyggerne i Sikems borg, omkring tusen, menn og kvinner. 
7:9:50: Derefter drog Abimelek til Tebes, og han kringsatte byen og inntok den. 
7:9:51: Midt i byen var det et fast tårn; dit flyktet alle menn og kvinner, alle borgerne i byen, og de stengte efter sig og steg op på tårnets tak. 
7:9:52: Så kom Abimelek til tårnet og kringsatte det; han gikk nær til tårnets inngang for å sette ild på det. 
7:9:53: Da var det en kvinne som kastet en kvernsten ned på hans hode og knuste hans hjerneskall. 
7:9:54: Abimelek ropte i hast på svennen som bar hans våben, og sa til ham: Dra ditt sverd og drep mig, så de ikke skal si om mig: En kvinne slo ham ihjel! Så stakk svennen sverdet igjennem ham og han døde. 
7:9:55: Da nu Israels menn sa at Abimelek var død, gikk de hver hjem til sitt. 
7:9:56: Således gjengjeldte Gud all den ondskap som Abimelek gjorde mot sin far dengang han slo sine sytti brødre ihjel. 
7:9:57: Og all den ondskap som Sikems menn hadde gjort, lot Gud komme tilbake over deres hode, og Jotams, Jerubba'als sønns forbannelse rammet dem. 
7:10:1: Efter Abimelek stod Tola frem og frelste Israel; han var sønn av Pua, Dodos sønn, av Issakars stamme, og bodde i Samir på Efra'im-fjellet. 
7:10:2: Han dømte Israel i tre og tyve år, og han døde og blev begravet i Samir. 
7:10:3: Efter ham stod gileaditten Ja'ir frem og dømte Israel i to og tyve år. 
7:10:4: Han hadde tretti sønner, som red på tretti asenfoler og hadde tretti byer; disse kalles den dag idag Ja'irs byer; de ligger i Gileads land. 
7:10:5: Og Ja'ir døde og blev begravet i Kamon. 
7:10:6: Og Israels barn gjorde atter det som var ondt i Herrens øine, og dyrket Ba'alene og Astarte-billedene og Arams guder og Sidons guder og Moabs guder og ammonittenes guder og filistrenes guder; de forlot Herren og tjente ham ikke. 
7:10:7: Da optendtes Herrens vrede mot Israel, og han solgte dem i filistrenes hånd og i ammonittenes hånd, 
7:10:8: og de begynte i samme år å trenge og undertrykke Israels barn; i atten år trengte de alle Israels barn som bodde på hin side Jordan, i amorittenes land, i Gilead. 
7:10:9: Og Ammons barn satte også over Jordan for å stride mot Juda og mot Benjamin og mot Efra'ims hus; og Israel kom i stor trengsel. 
7:10:10: Da ropte Israels barn til Herren og sa: Vi har syndet mot dig; vi har forlatt vår Gud og dyrket Ba'alene. 
7:10:11: Og Herren sa til Israels barn: Har jeg ikke frelst eder fra egypterne og amorittene og Ammons barn og filistrene? 
7:10:12: Og da sidonierne og Amalek og Maon undertrykte eder, og I ropte til mig, frelste jeg eder av deres hånd. 
7:10:13: Men I forlot mig og dyrket andre guder; derfor vil jeg ikke frelse eder mere. 
7:10:14: Gå bort og rop til de guder I har valgt eder! La dem frelse eder, nu da I er i slik trengsel! 
7:10:15: Da sa Israels barn til Herren: Vi har syndet! Gjør med oss hvad der tykkes dig godt; bare du vil frelse oss denne gang! 
7:10:16: Og de hadde de fremmede guder bort fra sig og tjente Herren; da kunde han ikke tåle å se på Israels nød. 
7:10:17: Og Ammons barn blev kalt til våben og leiret sig i Gilead; og Israels barn samlet sig og leiret sig i Mispa. 
7:10:18: Da sa krigsfolket, høvdingene i Gilead, til hverandre: Hvor er den mann som vil gå først i striden mot Ammons barn? Han skal være høvding over alle som bor i Gilead. 
7:11:1: Gileaditten Jefta var en veldig krigsmann. Men han var sønn av en skjøge; hans far var Gilead. 
7:11:2: Med sin hustru hadde Gilead også sønner; og da hustruens sønner blev store, jaget de Jefta bort og sa til ham: Du skal ingen arvelodd ha i vår fars hus, for du er sønn av en fremmed kvinne. 
7:11:3: Da flyktet Jefta for sine brødre og bosatte sig i landet Tob; og der samlet sig en flokk løse menn om Jefta og var med ham på hans tog. 
7:11:4: Nogen tid efter begynte Ammons barn krig mot Israel. 
7:11:5: Og da Ammons barn bar våben mot Israel, gikk de eldste i Gilead avsted for å hente Jefta fra landet Tob. 
7:11:6: Og de sa til Jefta: Kom og bli vår fører, så vil vi stride mot Ammons barn. 
7:11:7: Men Jefta sa til de eldste i Gilead: Var det ikke I som hatet mig og jaget mig bort fra min fars hus? Hvorfor kommer I da til mig, nu I er i trengsel? 
7:11:8: De eldste i Gilead svarte: Derfor er det jo vi nu kommer til dig igjen; du må gå med oss og stride mot Ammons barn, så skal du være høvding over oss, over alle som bor Gilead. 
7:11:9: Da sa Jefta til de eldste i Gilead: Dersom I nu får mig med eder tilbake for å stride mot Ammons barn, og Herren gir dem i min vold, så er det jeg som skal være eders høvding! 
7:11:10: Og de eldste i Gilead sa til Jefta: Herren skal være vidne mellem oss. Han straffe oss dersom vi ikke gjør som du sier! 
7:11:11: Så gikk Jefta med de eldste i Gilead, og folket gjorde ham til sin høvding og fører. Og Jefta gjentok i Mispa alle sine ord for Herrens åsyn. 
7:11:12: Så sendte Jefta bud til ammonittenes konge og lot si: Hvad vil du mig, siden du kommer til mig og fører krig mot mitt land? 
7:11:13: Da sa ammonittenes konge til Jeftas sendebud: Fordi Israel, da de drog op fra Egypten, tok mitt land like fra Arnon til Jabbok og til Jordan; gi det nu tilbake med det gode! 
7:11:14: Men Jefta sendte atter bud til ammonittenes konge 
7:11:15: og lot si til ham: Så sier Jefta: Israel har ikke tatt Moabs land eller ammonittenes land. 
7:11:16: For da de drog op fra Egypten, vandret Israel i ørkenen til det Røde Hav og kom til Kades. 
7:11:17: Da sendte Israel bud til Edoms konge og lot si: La mig få dra gjennem ditt land! Men Edoms konge hørte ikke på dem. Også til Moabs konge sendte de bud; men han vilde heller ikke. Så blev Israel i Kades. 
7:11:18: Siden vandret de i ørkenen og drog utenom Edoms land og Moabs land og kom frem østenfor Moabs land og leiret sig på hin side Arnon; men de kom ikke inn i Moabs land, for Arnon er Moabs grense. 
7:11:19: Så sendte Israel bud til Sihon, amorittenes konge, kongen i Hesbon, og Israel lot ham si: La oss få dra gjennem ditt land, så vi kan komme dit vi skal! 
7:11:20: Men Sihon hadde ikke så god tro til Israel at han torde la dem dra gjennem sitt land; han samlet alt sitt folk, og de leiret sig i Jahsa, og han angrep Israel. 
7:11:21: Og Herren, Israels Gud, gav Sihon og alt hans folk i Israels hånd, og de slo dem; og Israel inntok hele det land som tilhørte amorittene, det folk som bodde der; 
7:11:22: hele amorittenes land inntok de like fra Arnon til Jabbok og fra ørkenen til Jordan. 
7:11:23: Så har nu Herren, Israels Gud, drevet amorittene bort for sitt folk Israel; og nu vil du ta dets land i eie? 
7:11:24: Er det ikke så at hvad din gud Kamos lar dig få, det tar du i eie, og alt hvad Herren vår Gud rydder for oss, det tar vi i eie? 
7:11:25: Er da du bedre enn Balak, Sippors sønn, Moabs konge? Trettet vel han med Israel eller førte krig mot dem? 
7:11:26: Nu har Israel bodd tre hundre år i Hesbon og de byer som hører det til, og i Aroer med tilhørende byer og i alle de byer som ligger ved Arnons bredder; hvorfor har I da ikke tatt dem igjen i all denne tid? 
7:11:27: Jeg har ikke gjort dig noget ondt, men du gjør nu urett mot mig ved å angripe mig; Herren, han som er dommer, han skal dømme idag mellem Israels barn og Ammons barn. 
7:11:28: Men ammonittenes konge hørte ikke på de ord som Jeftas sendebud bar frem til ham. 
7:11:29: Da kom Herrens Ånd over Jefta, og han drog gjennem Gilead og Manasse; så drog han frem til Mispa i Gilead; og fra Mispa i Gilead drog han frem mot Ammons barn. 
7:11:30: Og Jefta gjorde et løfte til Herren og sa: Dersom du gir Ammons barn i min hånd, 
7:11:31: da skal den som går ut av døren til mitt hus og møter mig, når jeg kommer uskadd tilbake fra Ammons barn, da skal den høre Herren til; jeg skal ofre ham til brennoffer. 
7:11:32: Så drog Jefta frem mot Ammons barn og stred mot dem, og Herren gav dem i hans hånd; 
7:11:33: han slo dem og tok fra dem landet fra Aroer til bortimot Minnit, tyve byer, og like til Abel-Keramim; det blev et svært mannefall, og Ammons barn blev ydmyket under Israels barn. 
7:11:34: Da Jefta kom hjem til sitt hus i Mispa, da gikk hans datter ut imot ham med trommer og dans; hun var hans eneste barn, foruten henne hadde han hverken sønn eller datter. 
7:11:35: Med det samme han fikk se henne, sønderrev han sine klær og sa: Akk, min datter, hvilken sorg du volder mig, hvilken ulykke du fører over mig! Jeg har gjort et løfte til Herren, og jeg kan ikke ta det tilbake. 
7:11:36: Da sa hun til ham: Min far! Har du gjort et løfte til Herren, så gjør med mig som du har lovt, siden Herren lot dig få hevn over dine fiender, Ammons barn! 
7:11:37: Så sa hun til sin far: Bare én ting vil jeg be dig om: La mig være fri to måneder, så jeg kan gå ned på fjellene og gråte over min jomfrustand sammen med mine venninner. 
7:11:38: Og han sa: Gjør så! Så lot han henne være fri to måneder; og hun gikk avsted med sine venninner og gråt over sin jomfrustand på fjellene. 
7:11:39: Og da to måneder var omme, kom hun tilbake til sin far, og han gjorde med henne efter det løfte han hadde gjort; og hun hadde ikke visst av mann. Så blev det en skikk i Israel: 
7:11:40: År for år går Israels døtre avsted for å prise Jeftas, gileadittens datter fire dager om året. 
7:12:1: Efra'ims menn blev kalt til våben og drog mot nord; og de sa til Jefta: Hvorfor sendte du ikke bud efter oss da du drog avsted for å stride mot Ammons barn? Nu vil vi sette ild på huset du bor i. 
7:12:2: Da sa Jefta til dem: Jeg og mitt folk lå i en hård strid med Ammons barn; da kalte jeg på eder, men I hjalp mig ikke mot dem; 
7:12:3: og da jeg så at I ikke vilde hjelpe, satte jeg mitt liv på spill og drog avsted mot Ammons barn, og Herren gav dem i min hånd. Hvorfor kommer I da farende mot mig idag og vil stride mot mig? 
7:12:4: Derefter samlet Jefta alle Gileads menn og stred imot Efra'im, og Gileads menn slo Efra'im; for de* hadde sagt: Rømlinger fra Efra'im er I gileaditter midt i Efra'im og midt i Manasse. # <* efra'imittene.> 
7:12:5: Og gileadittene stengte vadestedene over Jordan for Efra'im; og hver gang det kom en efra'imitt som hadde flyktet fra slaget og sa: La mig slippe over, da sa Gileads menn til ham: Er du en efra'imitt? Han svarte: Nei. 
7:12:6: Da sa de til ham: Si sjibbolet! Men han sa sibbolet, for han kunde ikke uttale det riktig. Så grep de ham og slo ham ihjel ved Jordans vadesteder; og det falt dengang to og firti tusen av Efra'im. 
7:12:7: Jefta dømte Israel i seks år; og gileaditten Jefta døde og blev begravet i en av Gileads byer. 
7:12:8: Efter ham var Ibsan fra Betlehem dommer i Israel. 
7:12:9: Han hadde tretti sønner; tretti døtre giftet han bort, og tretti døtre førte han hjem som hustruer for sine sønner; og han dømte Israel i syv år. 
7:12:10: Og Ibsan døde og blev begravet i Betlehem. 
7:12:11: Efter ham var sebulonitten Elon dommer i Israel; han dømte Israel i ti år. 
7:12:12: Og sebulonitten Elon døde og blev begravet i Ajalon i Sebulons land. 
7:12:13: Efter ham var piratonitten Abdon, Hillels sønn, dommer i Israel. 
7:12:14: Han hadde firti sønner og tretti sønnesønner, som red på sytti asenfoler; han dømte Israel i åtte år. 
7:12:15: Og piratonitten Abdon, Hillels sønn, døde og blev begravet i Piraton i Efra'ims land, på Amalekitter-fjellet. 
7:13:1: Men Israels barn gjorde atter det som var ondt i Herrens øine; og Herren gav dem i filistrenes hånd i firti år. 
7:13:2: Der var en mann fra Sora av danittenes ætt, han hette Manoah; hans hustru var ufruktbar og hadde aldri hatt barn. 
7:13:3: Og Herrens engel åpenbarte sig for kvinnen og sa til henne: Se, du er ufruktbar og har aldri hatt barn; men du skal bli fruktsommelig og føde en sønn. 
7:13:4: Så ta dig nu i vare og drikk ikke vin eller sterk drikk og et ikke noget urent! 
7:13:5: For nu skal du bli fruktsommelig og føde en sønn, og det skal ikke komme rakekniv på hans hode, for barnet skal være en Guds nasireer fra mors liv; og han skal begynne å frelse Israel fra filistrenes hånd. 
7:13:6: Og kvinnen kom og sa til sin mann: En Guds mann kom til mig; han var å se til som Guds engel, såre forferdelig; men jeg spurte ham ikke hvorfra han var, og han sa mig ikke sitt navn. 
7:13:7: Men han sa til mig: Nu skal du bli fruktsommelig og føde en sønn; drikk nu ikke vin eller sterk drikk og et ikke noget urent! For barnet skal være en Guds nasireer fra mors liv og til sin dødsdag. 
7:13:8: Da bad Manoah til Herren og sa: Hør mig, Herre! La den Guds mann som du sendte, komme til oss igjen og lære oss hvad vi skal gjøre med det barn som skal fødes! 
7:13:9: Og Gud hørte Manoahs bønn; og Guds engel kom atter til kvinnen, mens hun satt ute på marken, og Manoah, hennes mann, ikke var med henne. 
7:13:10: Da skyndte kvinnen sig og løp hjem og fortalte det til sin mann og sa: Nu har han vist sig for mig igjen den mann som kom til mig her om dagen. 
7:13:11: Manoah stod op og fulgte efter sin hustru, og da han kom til mannen, sa han til ham: Er du den mann som talte til min kone? Han svarte: Ja. 
7:13:12: Og Manoah sa: Når nu dine ord går i opfyllelse, hvorledes skal vi da bære oss at med barnet, og hvad skal vi gjøre med ham? 
7:13:13: Da sa Herrens engel til Manoah: Alt det jeg har nevnt for kvinnen, skal hun ta sig i vare for. 
7:13:14: Hun skal ikke ete noget av det som kommer av vintreet, og ikke drikke vin eller sterk drikk og ikke ete noget urent; alt det jeg har pålagt henne, skal hun holde sig efter. 
7:13:15: Da sa Manoah til Herrens engel: Kan vi ikke få dig til å bli en stund her, sa vi kan lage til et kje for dig! 
7:13:16: Men Herrens engel sa til Manoah: Om du får mig til å bli, så eter jeg allikevel ikke av din mat; men vil du stelle til et brennoffer, så skal du ofre det til Herren. For Manoah visste ikke at det var Herrens engel. 
7:13:17: Da sa Manoah til Herrens engel: Hvad er ditt navn? - så vi kan ære dig når dine ord går i opfyllelse. 
7:13:18: Men Herrens engel sa til ham: Hvorfor spør du om mitt navn? - Det er et underlig navn! 
7:13:19: Så tok Manoah kjeet og matofferet og ofret det på berget til Herren. Da lot han et under skje mens Manoah og hans hustru så på det: 
7:13:20: Da luen steg op fra alteret mot himmelen, fór Herrens engel op i alterets lue mens Manoah og hans hustru så på det, og de falt på sitt ansikt til jorden. 
7:13:21: Siden viste Herrens engel sig ikke mere for Manoah og hans hustru; da forstod Manoah at det var Herrens engel. 
7:13:22: Og Manoah sa til sin hustru: Vi må visselig dø; vi har sett Gud. 
7:13:23: Men hans hustru sa til ham: Dersom Herren hadde lyst til å drepe oss, så hadde han ikke tatt imot brennoffer og matoffer av vår hånd og ikke latt oss se alt dette og ikke nu latt oss høre sådant. 
7:13:24: Og kvinnen fødte en sønn og gav ham navnet Samson; og gutten vokste op, og Herren velsignet ham. 
7:13:25: Og Herrens Ånd begynte å drive ham, mens han var i Dans leir mellem Sora og Estaol. 
7:14:1: Engang gikk Samson ned til Timnata; der så han en kvinne, en av filistrenes døtre. 
7:14:2: Da han kom tilbake derfra, fortalte han det til sin far og mor og sa: Jeg så en kvinne i Timnata, en av filistrenes døtre; henne må I la mig få til hustru! 
7:14:3: Da sa hans far og mor til ham: Er det da ikke nogen kvinne blandt dine frenders døtre eller i hele mitt folk siden du vil avsted og ta en hustru blandt de uomskårne filistrer? Men Samson sa til sin far: Henne må du la mig få! Det er henne jeg synes om. 
7:14:4: Hans far og mor visste ikke at dette kom fra Herren; for han søkte en leilighet til strid med filistrene - på den tid hersket filistrene over Israel. 
7:14:5: Så gikk Samson og hans far og mor ned til Timnata, og da de kom til vingårdene ved Timnata, da fór en ung løve brølende mot ham. 
7:14:6: Da kom Herrens Ånd over ham, og han slet den i stykker som det skulde være et kje, enda kan ikke hadde noget i hånden; men han fortalte ikke sin far eller sin mor hvad han hadde gjort. 
7:14:7: Så gikk han ned og talte med kvinnen, og Samson syntes godt om henne. 
7:14:8: Nogen tid efter drog han ned igjen for å gifte sig med henne; så tok han av veien og vilde se efter løvens åtsel; da var der en bisverm i løvens kropp, og honning. 
7:14:9: Han tok honningen ut og holdt den i sine hender og åt mens han gikk, og da han kom til sin far og mor, gav han dem med sig, og de åt; men han fortalte dem ikke at det var av løvens kropp han hadde tatt honningen. 
7:14:10: Da nu hans far var kommet ned til kvinnen, gjorde Samson et gjestebud der; for således pleide de unge menn å gjøre. 
7:14:11: Og så snart de så ham, hentet de tretti brudesvenner; de var stadig om ham. 
7:14:12: En dag sa Samson til dem: La mig fremsette en gåte for eder! Dersom I i løpet av de syv gjestebudsdager kan si mig løsningen på den, og I gjetter riktig, så vil jeg gi eder tretti fine skjorter og tretti festklædninger; 
7:14:13: men kan I ikke si mig løsningen, så skal I gi mig tretti fine skjorter og tretti festklædninger. Og de sa til ham: Fremsett din gåte, så vi får høre den! 
7:14:14: Da sa han til dem: Av eteren kom det mat og av den sterke kom det sødme. Men det gikk tre dager uten at de kunde løse gåten. 
7:14:15: På den syende dag sa de til Samsons hustru: Lokk din mann til å si oss løsningen på gåten! Ellers brenner vi op både dig og din fars hus. Er det da for å plyndre oss I har innbudt oss? 
7:14:16: Da hang Samsons hustru over ham med gråt og sa: Du hater mig bare og elsker mig ikke; du har fremsatt en gåte for mine landsmenn, men mig har du ikke sagt løsningen på den. Han svarte: Jeg har ikke sagt det til min far eller mor; skulde jeg da si det til dig? 
7:14:17: Men hun hang over ham med gråt i de syv dager gjestebudet varte, og den syvende dag sa han henne løsningen, fordi hun plaget ham så; og hun sa det til sine landsmenn. 
7:14:18: Og på den syvende dag, før solen gikk ned, sa mennene i byen til ham: Hvad er søtere enn honning, og hvad er sterkere enn en løve? Men han svarte dem: Hadde I ikke pløid med min kalv, så hadde I ikke gjettet min gåte. 
7:14:19: Og Herrens Ånd kom over ham; han gikk ned til Askalon og slo ihjel tretti mann der, tok deres klær og lot dem som hadde løst gåten, få dem til festklædninger; og hans vrede optendtes, og han drog hjem til sin fars hus. 
7:14:20: Men Samsons hustru blev gitt til den av hans brudesvenner som han hadde valgt sig til følgesvenn. 
7:15:1: Nogen tid efter, i hvetehøstens dager, vilde Samson se til sin hustru og hadde med sig et kje, og han sa: La mig få komme inn i kammeret til min hustru! Men hennes far vilde ikke gi ham lov til å gå inn 
7:15:2: og sa: Jeg tenkte at du hadde lagt henne for hat, og så gav jeg henne til din brudesvenn. Er ikke hennes yngre søster vakrere enn hun? Ta henne isteden. 
7:15:3: Da sa Samson til dem: Denne gang har jeg ingen skyld på mig mot filistrene om jeg gjør dem noget ondt. 
7:15:4: Så gikk Samson avsted og fanget tre hundre rever, og han tok fakler, vendte hale mot hale og satte en fakkel mellem to og to av halene. 
7:15:5: Så tendte han ild i faklene og slapp revene inn på filistrenes akrer, og han brente op både kornbånd og uskåret korn og oljehaver. 
7:15:6: Da sa filistrene: Hvem har gjort dette? Folk svarte: Samson, svigersønn til timnitten, fordi han tok hans hustru og gav henne til hans brudesvenn. Så drog filistrene op og brente inne både henne og hennes far. 
7:15:7: Da sa Samson til dem: Gjør I sådant, så vil jeg ikke hvile før jeg får hevnet mig på eder. 
7:15:8: Og han slo dem både på ben og på lår*, så det blev et stort mannefall; så drog han ned og gav sig til i fjellkløften ved Etam. # <* sønder og sammen.> 
7:15:9: Da drog filistrene op og leiret sig i Juda, og de spredte sig utover i Leki. 
7:15:10: Og Judas menn sa: Hvorfor har I draget op mot oss? De svarte: Vi har draget op for å binde Samson og gjøre mot ham som han har gjort mot oss. 
7:15:11: Da drog tre tusen mann av Juda ned til fjellkløften ved Etam, og de sa til Samson: Vet du ikke at filistrene hersker over oss? Hvorfor har du da gjort dette mot oss? Men han sa til dem: Som de har gjort mot mig, så har jeg gjort mot dem. 
7:15:12: Da sa de til ham: Vi har draget ned for å binde dig og gi dig i filistrenes hånd. Og Samson sa til dem: Sverg mig til at ikke I vil slå mig ihjel! 
7:15:13: Da sa de til ham: Nei, vi vil bare binde dig og så gi dig i deres hånd; men drepe dig vil vi ikke. Så bandt de ham med to nye rep og førte ham op av fjellkløften. 
7:15:14: Da han kom til Leki, sprang filistrene imot ham med gledesrop; men Herrens Ånd kom over ham, og repene om hans armer blev som brente tråder, og båndene falt smuldrede av hans hender. 
7:15:15: Så fant han et friskt kjeveben av et asen; han rakte hånden ut og tok det og slo ihjel tusen mann med det. 
7:15:16: Da sa Samson: Med et asens kjeveben slo jeg én hop, ja to, med et asens kjeveben slo jeg tusen mann. 
7:15:17: Og da han hadde sagt dette, kastet han kjevebenet fra sig; og siden kalte de dette sted Ramat-Leki*. # <* kjevebens-haugen.> 
7:15:18: Men da han var brennende tørst, ropte han til Herren og sa: Du har gitt denne store frelse ved din tjeners hånd, og så skal jeg nu dø av tørst og falle i de uomskårnes hender! 
7:15:19: Da lot Gud den hulning som er i Leki, åpne sig, og det rant vann ut av den, og han drakk; og hans livsånd vendte tilbake, og han kviknet til; derfor blev denne kilde kalt En-Hakkore*; den er i Leki den dag idag. # <* den ropendes kilde.> 
7:15:20: Han var i tyve år dommer i Israel i filistrenes dager. 
7:16:1: Engang drog Samson til Gasa; der fikk han se en skjøge og gikk inn til henne. 
7:16:2: Da folkene i Gasa fikk høre at Samson var kommet dit, omringet de ham og lurte på ham hele natten ved byporten, men holdt sig ellers rolig hele natten og sa: Når det lysner mot dag, vil vi slå ham ihjel. 
7:16:3: Samson blev liggende til midnatt. Ved midnattstid stod han op; han grep fatt i byportens dører og i begge portstolpene og rykket dem op sammen med bommen, la dem på sine skuldrer og bar dem op på toppen av det fjell som ligger midt imot Hebron. 
7:16:4: Siden fattet han kjærlighet til en kvinne i Sorek-dalen, som hette Dalila. 
7:16:5: Da kom filistrenes høvdinger op til henne og sa til henne: Lokk ham til å si dig hvori hans store styrke ligger, og hvorledes vi kan rå med ham og binde ham, så vi får bukt med ham! Så vil vi gi dig hver elleve hundre* sekel sølv. # <* d.e. over tusen.> 
7:16:6: Så sa Dalila til Samson: Kjære, la mig få vite hvori din store styrke ligger, og hvorledes du kan bindes, så en får bukt med dig! 
7:16:7: Samson svarte: Dersom en binder mig med syv friske strenger som ikke er blitt tørre, så blir jeg svak og er som et annet menneske. 
7:16:8: Da kom filistrenes høvdinger op til henne med syv friske strenger som ikke var blitt tørre; dem bandt hun ham med, 
7:16:9: og i kammeret hadde hun folk sittende og lure. Så sa hun til ham: Filistrene er over dig, Samson! Da sønderrev han strengene som en tråd av stry brister når den kommer ilden for nær, og ingen fikk vite hvorledes det hadde sig med hans styrke. 
7:16:10: Da sa Dalila til Samson: Du har narret mig og løiet for mig! Kjære, la mig nu få vite hvorledes du kan bindes! 
7:16:11: Han svarte: Dersom en binder mig med nye rep som aldri har vært brukt til noget, så blir jeg svak og er som et annet menneske. 
7:16:12: Da tok Dalila nye rep og bandt ham med dem og sa til ham: Filistrene er over dig, Samson! Og i kammeret satt der folk og lurte. Men han rev repene av sine armer som tråder. 
7:16:13: Da sa Dalila til Samson: Hittil har du narret mig og løiet for mig! La mig nu få vite hvorledes du kan bindes! Han svarte henne: Når du vever de syv fletter på mitt hode sammen med renningen i veven. 
7:16:14: Så slo hun dem fast med naglen og sa til ham: Filistrene er over dig, Samson! Og han våknet av sin søvn og rev ut både vevnaglen og renningen. 
7:16:15: Da sa hun til ham: Hvorledes kan du si at du elsker mig når du ikke har nogen tiltro til mig? Nu har du narret mig tre ganger og ikke sagt mig hvori din store styrke ligger. 
7:16:16: Da hun nu hver dag plaget ham med sine ord og trengte sterkt inn på ham, blev han så lei av sig at han næsten kunde dø, 
7:16:17: og så åpnet han hele sitt hjerte for henne og sa til henne: Det er ikke kommet rakekniv på mitt hode, for jeg har vært en Guds nasireer fra mors liv; dersom jeg blir raket, viker min styrke fra mig; jeg blir svak og er som alle andre mennesker. 
7:16:18: Da skjønte Dalila at han hadde åpnet hele sitt hjerte for henne; hun sendte bud efter filistrenes høvdinger og lot si: Kom nu op, for han har åpnet hele sitt hjerte for mig. Da kom filistrenes høvdinger op til henne og hadde pengene med sig. 
7:16:19: Så lot hun ham sove inn på sine knær og kalte på en mann og lot de syv fletter på hans hode rake av; og hun begynte å plage ham, og hans styrke vek fra ham. 
7:16:20: Og hun sa: Filistrene er over dig, Samson! Da våknet han op av sin søvn og sa: Jeg skal nok gjøre mig fri denne gang som før og slite mig løs. Men han visste ikke at Herren var veket fra ham. 
7:16:21: Da grep filistrene ham og stakk hans øine ut; og de førte ham ned til Gasa og bandt ham med to kobberlenker, og han malte korn i fangehuset. 
7:16:22: Men med det samme han var raket, begynte håret på hans hode å vokse. 
7:16:23: Så samledes filistrenes høvdinger for å bære frem et stort slaktoffer for sin gud Dagon og for å holde fest; for de sa: Vår gud har gitt Samson, vår fiende, i vår hånd. 
7:16:24: Og da folket fikk se ham, lovet de sin gud og sa: Vår gud har gitt vår fiende i vår hånd, han som herjet vårt land og slo så mange av oss ihjel. 
7:16:25: Mens de nu var glade og vel til mote, sa de: Hent Samson hit forat han kan leke for oss! Da hentet de Samson ut av fangehuset, og han lekte for dem, og de stilte ham mellem stolpene. 
7:16:26: Da sa Samson til den gutt som holdt ham ved hånden: Slipp mig, og la mig få ta i stolpene som huset hviler på, og støtte mig til dem! 
7:16:27: Men huset var fullt av menn og kvinner, alle filistrenes høvdinger var der, og på taket var det omkring tre tusen menn og kvinner, som så på at Samson lekte. 
7:16:28: Da ropte Samson til Herren og sa: Herre, Herre! Kom mig i hu og styrk mig bare denne ene gang, min Gud, så jeg kan få hevne mig på filistrene for ett av mine to øine! 
7:16:29: Så grep Samson om de to mellemste stolper, som huset hvilte på, og trykte sig inn til dem; den ene grep han om med sin høire, og den andre med sin venstre hånd. 
7:16:30: Og Samson sa: La mig dø sammen med filistrene! Så bøide han sig fremover av all sin makt; da falt huset sammen over høvdingene og over alt folket som var der inne, og de som han drepte i sin død, var flere enn de han hadde drept i sitt liv. 
7:16:31: Men hans brødre og hele hans fars hus drog ned og tok ham og førte ham hjem og la ham i Manoahs, hans fars grav mellem Sora og Estaol. Han hadde da vært dommer i Israel i tyve år. 
7:17:1: Det var en mann fra Efra'imfjellene som hette Mika. 
7:17:2: Han sa til sin mor: De elleve hundre sekel sølv* som blev tatt fra dig, og som du har uttalt forbannelse over, så også jeg hørte på det - disse penger er hos mig; det er jeg som har tatt dem. Da sa hans mor: Velsignet være du, min sønn, av Herren! # <* DMR 16, 5.> 
7:17:3: Så gav han sin mor de elleve hundre sekel sølv tilbake; men hans mor sa: Disse penger vier jeg nu til Herren og overgir dem til min sønn, forat han kan gjøre et utskåret billede med et støpt fotstykke til, og nu gir jeg dig dem tilbake. 
7:17:4: Han gav da pengene tilbake til sin mor; og hans mor tok to hundre sekel sølv og gav det til en gullsmed, og for disse penger gjorde han et utskåret billede med et støpt fotstykke; det var siden i Mikas hus. 
7:17:5: Og mannen Mika hadde et gudshus, og han hadde gjort en livkjortel og husguder og hadde vidd en av sine sønner til prest for sig. 
7:17:6: I de dager var det ingen konge i Israel; hver mann gjorde hvad rett var i hans øine. 
7:17:7: Der var en ung mann fra Betlehem i Juda; han var en levitt av dem som hørte til i Juda stamme, og der bodde han som fremmed. 
7:17:8: Denne mann drog bort fra sin by, fra Betlehem i Juda, for å ta ophold hvor det kunde falle sig; og han kom på sin vandring til Efra'imfjellene, til Mikas hus. 
7:17:9: Mika sa til ham: Hvor kommer du fra? Han svarte: Jeg er en levitt fra Betlehem i Juda og er nu på vandring for å ta ophold hvor jeg finner leilighet. 
7:17:10: Da sa Mika til ham: Bli hos mig og vær far og prest for mig! Så vil jeg gi dig ti sekel sølv om året og holde dig med klær og kost. Da gikk levitten inn til ham. 
7:17:11: Og levitten samtykket i å bli hos mannen, og den unge mann var for ham som en av hans egne sønner. 
7:17:12: Mika innvidde levitten, og den unge mann blev hans prest; og han blev boende i Mikas hus. 
7:17:13: Og Mika sa: Nu vet jeg at Herren vil gjøre vel imot mig siden jeg har fått en levitt til prest. 
7:18:1: I de dager var det ingen konge i Israel; og i de samme dager søkte danittenes stamme sig en arvelodd til å bo i, for det var ikke til den dag tilfalt dem nogen arv blandt Israels stammer*. # <* d.e. den dem ifølge JOS 19, 40 fg. tildelte arv var ennu ikke kommet i deres eie.> 
7:18:2: Og Dans barn sendte ut fem menn av hele sin ætt, djerve menn fra Sora og Estaol, for å speide og utforske landet, og de sa til dem: Dra avsted og utforsk landet! Og de kom til Efra'im-fjellet, til Mikas hus, og blev der om natten. 
7:18:3: Da de var tett ved Mikas hus, kjente de den unge manns - levittens - røst, bøide av fra veien og gikk bort til ham og sa: Hvem har ført dig hit, og hvad gjør du her, og hvorledes har du det her? 
7:18:4: Han svarte: Så og så har Mika gjort mot mig; han har leid mig til å være prest for sig. 
7:18:5: Da sa de til ham: Kjære, spør Gud for oss, sa vi kan få vite om den reise vi nu gjør, skal lykkes! 
7:18:6: Da sa presten til dem: Far i fred! Gud har øie med eder på den reise I gjør. 
7:18:7: De fem menn drog nu sin vei og kom til La'is, og de så at folket der levde sorgløst på samme vis som sidonierne, rolig og sorgløst; det var ingen makthaver i landet som gjorde dem noget mén; de var langt borte fra sidonierne og hadde intet å gjøre med noget menneske. 
7:18:8: Da de så kom tilbake til sine brødre i Sora og Estaol, sa deres brødre til dem: Hvorledes er det gått eder? 
7:18:9: De sa: Kom, la oss dra op mot dem! Vi har sett landet: Det er et ypperlig land. Og I sitter bare og tier? Vær ikke sene til å dra avsted, så I kan komme dit og innta landet! 
7:18:10: Når I kommer dit, kommer I til et sorgløst folk, og landet strekker sig til alle sider vidt ut; Gud har gitt det i eders hånd - et land hvor det ikke er mangel på nogen verdens ting. 
7:18:11: Så brøt seks hundre menn av danittenes ætt fullt væbnet op derfra, fra Sora og Estaol. 
7:18:12: De drog op og leiret sig i Kirjat-Jearim i Juda; derfor har folk kalt dette sted Dans leir til denne dag, det ligger vestenfor Kirjat-Jearim. 
7:18:13: Derfra drog de over til Efra'im-fjellet og kom til Mikas hus. 
7:18:14: Og de fem menn som hadde draget avsted for å utspeide La'islandet, tok til orde og sa til sine brødre: Vet I at i disse hus er det en livkjortel og husguder og et utskåret billede med et støpt fotstykke til? Så skjønner I vel hvad I har å gjøre? 
7:18:15: Så bøide de av fra veien og gikk bort til den unge manns - levittens - hus, til Mikas hus, og hilste på ham. 
7:18:16: Men de seks hundre fullt væbnede menn av Dans barn stod ved inngangen til porten. 
7:18:17: Og de fem menn som hadde draget avsted for å utspeide landet, steg op og gikk inn og tok det utskårne billede og livkjortelen og husgudene og det støpte fotstykke, mens presten stod ved inngangen til porten sammen med de seks hundre fullt væbnede menn. 
7:18:18: Da nu de fem menn hadde gått inn i Mikas hus og hadde tatt det utskårne billede med livkjortelen og husgudene og det støpte fotstykke, sa presten til dem: Hvad er det I gjør? 
7:18:19: De sa til ham: Ti, legg din hånd på din munn og kom med oss og vær far og prest for oss! Hvad er best for dig, å være prest for en manns hus eller for en hel stamme og ætt i Israel? 
7:18:20: Da blev presten vel til mote; han tok livkjortelen og husgudene og det utskårne billede og gav sig i lag med folkene. 
7:18:21: Så vendte de sig og drog bort, og de lot barna og buskapen og sine eiendeler være fremst i toget. 
7:18:22: Da de alt var kommet langt fra Mikas hus, blev mennene i Mikas grend kalt sammen, og de innhentet Dans barn. 
7:18:23: De ropte efter Dans barn, og disse vendte sig om og sa til Mika: Hvad vil du siden du har kalt dine folk sammen? 
7:18:24: Han svarte: I har tatt mine guder som jeg har gjort mig, og presten med, og har draget bort; hvad har jeg så tilbake? Hvorledes kan I da spørre mig hvad jeg vil? 
7:18:25: Da sa Dans barn til ham: La oss ikke høre et ord av dig mere, ellers kunde rasende menn falle over eder, så du kom til å volde undergang både for dig og dine. 
7:18:26: Så drog Dans barn sin vei, og da Mika så at de var sterkere enn han, vendte han om og drog tilbake til sitt hus. 
7:18:27: Da de nu hadde tatt de ting som Mika hadde latt gjøre, og presten som var hos ham, kom de over La'is - over et folk som levde rolig og sorgløst. De slo dem med sverdets egg, og byen brente de op. 
7:18:28: Og der var ingen som kom dem til hjelp; for byen lå langt fra Sidon, og de hadde intet å gjøre med noget menneske. Den lå i dalen ved Bet-Rehob. De bygget den op igjen og bosatte sig der. 
7:18:29: Og de kalte byen Dan efter sin stamfar Dan, Israels sønn; før het den La'is. 
7:18:30: Der satte Dans barn op det utskårne billede; og Jonatan, sønn av Gersom, Moses' sønn*, og hans efterkommere var prester for danittenes stamme like til den dag da landets innbyggere blev bortført. # <* 2MO 2, 22; 18, 3.> 
7:18:31: Det utskårne billede som Mika hadde gjort, hadde de stående hos sig hele den tid Guds hus var i Silo. 
7:19:1: På denne tid da det ingen konge fantes i Israel, var det en mann av Levi stamme som opholdt sig som fremmed langt inne på Efra'imfjellet. Han tok sig en medhustru fra Betlehem i Juda. 
7:19:2: Men hans medhustru var utro mot ham og reiste fra ham og hjem til sin fars hus i Betlehem i Juda. Der blev hun en tid, en fire måneder. 
7:19:3: Da tok hennes mann avsted og reiste efter henne for å tale kjærlig til henne og få henne til å komme tilbake, og han hadde med sig sin dreng og et par asener. Hun førte ham inn i sin fars hus, og da den unge kvinnes far så ham, gikk han ham glad i møte. 
7:19:4: Og hans svigerfar - den unge kvinnes far - holdt på ham, så han blev hos ham i tre dager; og de åt og drakk og blev der natten over. 
7:19:5: Den fjerde dag stod de tidlig op om morgenen, og han gjorde sig ferdig til å reise. Da sa den unge kvinnes far til sin svigersønn: Få dig først litt mat til å styrke dig med! Så kan I siden ta avsted. 
7:19:6: De satte sig da begge to og åt og drakk sammen, og den unge kvinnes far sa til mannen: Vær nu så snild og bli her natten over og hygg dig her! 
7:19:7: Og da mannen reiste sig og vilde ta avsted, nødde svigerfaren ham, så han gav efter og blev der natten over. 
7:19:8: Den femte dag stod han atter tidlig op om morgenen og vilde reise sin vei. Da sa den unge kvinnes far: Kjære, få dig først noget å styrke dig med, og så får I bie til det lider over middag. Så åt de begge sammen. 
7:19:9: Siden gjorde da mannen sig ferdig til å reise med sin medhustru og sin dreng; men hans svigerfar - den unge kvinnes far - sa til ham: Nu lider det mot aften; bli her natten over! Du ser dagen heller, bli her inatt og hygg dig her! Så kan I tidlig imorgen gi eder på veien, så du kan komme hjem igjen. 
7:19:10: Men mannen vilde ikke bli natten over. Han gjorde sig ferdig og drog avsted og kom på sin vei til midt imot Jebus, det er Jerusalem. Han hadde med sig et par asener med kløv, og hans medhustru var med ham. 
7:19:11: Da de var ved Jebus, var det alt sent på dagen og drengen sa til sin herre: Kom, la oss ta inn i jebusittenes by og bli der inatt! 
7:19:12: Men hans herre sa til ham: Vi vil ikke ta inn i en by hvor det bare bor fremmede og ikke nogen av Israels barn; vi vil dra videre til Gibea. 
7:19:13: Så sa han til drengen: Kom, la oss se til å nå en av de andre byer og bli natten over i Gibea eller Rama! 
7:19:14: Så drog de da videre frem, og da solen gikk ned, var de tett ved Gibea, som hører Benjamin til. 
7:19:15: Der bøide de av for å gå inn i Gibea og bli der natten over. Da han kom inn i byen, blev han på gaten, for det var ingen som vilde ta dem inn i sitt hus for natten. 
7:19:16: Da kom det ut på aftenen en gammel mann hjem fra sitt arbeid på marken. Mannen var fra Efra'im-fjellet og opholdt sig som fremmed i Gibea; men folket der på stedet var benjaminitter. 
7:19:17: Da han så op, fikk han øie på den veifarende mann på byens gate. Da sa den gamle mann: Hvor skal du hen, og hvor kommer du fra? 
7:19:18: Han svarte: Vi kommer fra Betlehem i Juda og skal langt inn i Efra'im-fjellene; der er jeg fra, og derfra drog jeg til Betlehem i Juda; jeg er nu på veien til Herrens hus*, men det er ingen som vil ta mig inn i sitt hus. # <* DMR 18, 31.> 
7:19:19: Jeg har både halm og fôr til våre asener og likeledes brød og vin for mig og for din tjenerinne og for drengen som dine tjenere har med sig; vi mangler ingen ting. 
7:19:20: Da sa den gamle mann: Fred være med dig! La bare mig sørge for alt det du trenger, men på gaten må du ikke bli natten over. 
7:19:21: Så førte han ham inn i sitt hus og blandet fôr for asenene, og de tvettet sine føtter og åt og drakk. 
7:19:22: Mens de nu gjorde sig til gode, kom mennene i byen, ugudelige folk som de var, og omringet huset; de banket sterkt på døren og ropte til den gamle mann som eide huset: La den mann som har tatt inn i ditt hus, komme ut, så vi kan få vår vilje med ham! 
7:19:23: Men mannen - han som eide huset - gikk ut til dem og sa: Ikke så, mine brødre! Gjør ikke så ond en gjerning! Siden denne mann nu er kommet i mitt hus, så bær eder ikke så skammelig at! 
7:19:24: Se, her er min datter, som er jomfru, og hans medhustru; la mig føre dem ut, så kan I krenke dem og gjøre med dem som I finner for godt! Men mot denne mann må I ikke bære eder så skammelig at! 
7:19:25: Men mennene vilde ikke høre på ham. Da tok mannen sin medhustru og førte henne ut på gaten til dem, og de lå hos henne og fór ille med henne hele natten til om morgenen og slapp henne ikke før det begynte å lysne. 
7:19:26: Ved daggry kom kvinnen og falt om ved inngangen til den manns hus hos hvem hennes herre var, og der blev hun liggende til det blev lyst. 
7:19:27: Da hennes herre stod op om morgenen og lukket op husets dør og gikk ut for å reise videre, da lå kvinnen, hans medhustru, ved inngangen til huset med hendene på dørtreskelen. 
7:19:28: Han sa til henne: Stå op og la oss ta avsted! Men det var ingen som svarte. Da løftet mannen henne op på asenet og gav sig på veien til sitt hjem. 
7:19:29: Og da han kom til sitt hus, grep han en kniv og tok sin medhustru og skar henne op, lem for lem, i tolv stykker og sendte henne omkring i hele Israels land. 
7:19:30: Og hver den som så det, sa: Slikt har ikke hendt og er ikke sett like fra den dag Israels barn drog op fra Egyptens land, og til denne dag; tenk over det, hold råd og si eders mening! 
7:20:1: Da tok alle Israels barn ut, og hele menigheten, like fra Dan og til Be'erseba og fra Gileads land, samlet sig som én mann for Herren i Mispa. 
7:20:2: Og høvdingene for hele folket, for alle Israels stammer, stilte sig frem for Guds folks forsamling, som var fire hundre tusen mann fotfolk, væbnet med sverd. 
7:20:3: Men Benjamins barn fikk høre at Israels barn hadde draget op til Mispa. Da sa Israels barn: Si fra hvorledes det gikk til med denne ugjerning! 
7:20:4: Og levitten, den myrdede kvinnes mann, tok til orde og sa: Jeg og min medhustru kom til Gibea i Benjamin for å bli der natten over. 
7:20:5: Men mennene i Gibea overfalt mig; de omringet om natten huset jeg var i; mig tenkte de å slå ihjel, og min medhustru krenket de, så hun døde. 
7:20:6: Da tok jeg min medhustru og skar henne i stykker og sendte henne omkring i hele Israels land og rike; for de hadde gjort en udåd og en skjenselsgjerning i Israel. 
7:20:7: Nu er I samlet her, alle Israels barn; si nu eders mening og gi råd her! 
7:20:8: Da reiste hele folket sig som én mann og sa: Ingen av oss vil dra bort til sitt telt, og ingen av oss vil reise hjem til sitt hus. 
7:20:9: Og således vil vi gjøre med Gibea: Under loddet med det*! # <* d.e. vi vil innta og dele det ved loddkasting.> 
7:20:10: Nu vil vi ta ut ti mann for hvert hundre av alle Israels stammer og hundre for hvert tusen og tusen for hvert ti tusen; de skal hente levnetsmidler til folkene, så de, når de kommer til Gibea i Benjamin, kan gjengjelde det hele den skjenselsgjerning det har gjort i Israel. 
7:20:11: Så samlet alle Israels menn sig og drog imot byen, samdrektige som én mann. 
7:20:12: Og Israels stammer sendte ut menn til alle Benjamins ætter og lot si: Hvad er dette for en ugjerning som er gjort hos eder? 
7:20:13: Utlever nu de ugudelige menn i Gibea, så vi kan slå dem ihjel og rydde ut det onde av Israel! Men Benjamin vilde ikke høre på sine brødres, Israels barns ord. 
7:20:14: Og Benjamins barn kom sammen til Gibea fra alle sine byer for å dra i krig mot Israels barn. 
7:20:15: Samme dag blev Benjamins barn fra byene mønstret; det var seks og tyve tusen mann som kunde dra sverd; i denne mønstring var Gibeas innbyggere, syv hundre utvalgte menn, ikke medregnet. 
7:20:16: Blandt alle disse folk var det syv hundre utvalgte menn som var kjevhendte; av dem rammet enhver på et hår når han slynget med sten, og feilte ikke. 
7:20:17: Og da Israels menn - Benjamin fraregnet - blev mønstret, utgjorde de fire hundre tusen mann som kunde dra sverd; hver av dem var en krigsmann. 
7:20:18: De gjorde sig ferdige og drog op til Betel og spurte Gud. Israels barn sa: Hvem av oss skal først dra op og stride mot Benjamins barn? Og Herren sa: Juda skal først. 
7:20:19: Så brøt Israels barn op om morgenen og leiret sig midt imot Gibea. 
7:20:20: Og Israels menn gikk frem til strid mot Benjamin, og Israels menn stilte sig i fylking mot dem bortimot Gibea. 
7:20:21: Da drog Benjamins barn ut av Gibea og slo den dag to og tyve tusen mann av Israel til jorden. 
7:20:22: Men folket, Israels menn, tok mot til sig og stilte sig atter i fylking på samme sted hvor de hadde stilt sig op den første dag - 
7:20:23: Israels barn drog op og gråt for Herrens åsyn til om aftenen, og de spurte Herren og sa: Skal jeg atter gå frem til strid mot min bror Benjamins barn? Og Herren sa: Dra op mot ham! - 
7:20:24: Så gikk da Israels barn frem til strid mot Benjamins barn den annen dag. 
7:20:25: Og Benjamin drog ut mot dem fra Gibea den annen dag og slo ennu atten tusen mann av Israels barn til jorden; alle disse kunde dra sverd. 
7:20:26: Da drog alle Israels barn, hele folket, op til Betel; der satt de og gråt for Herrens åsyn og fastet den dag til om aftenen, og de ofret brennoffer og takkoffer for Herrens åsyn. 
7:20:27: Og Israels barn spurte Herren - for i den tid stod Guds pakts-ark der, 
7:20:28: og Pinehas, sønn av Eleasar og sønnesønn av Aron, gjorde i den tid tjeneste for hans åsyn. - De sa: Skal jeg ennu en gang dra ut til strid mot min bror Benjamins barn, eller skal jeg la det være? Og Herren sa: Dra op! For imorgen vil jeg gi ham i din hånd. 
7:20:29: Så la Israel folk i bakhold rundt omkring Gibea. 
7:20:30: Og Israels barn drog op mot Benjamins barn den tredje dag og stilte sig op bortimot Gibea, likesom de tidligere ganger. 
7:20:31: Da drog Benjamins barn ut imot folket og blev dradd bort fra byen; og likesom de tidligere ganger felte de i førstningen nogen av folket på alfarveiene som fører opover, den ene til Betel og den andre over marken til Gibea; det var omkring tretti mann av Israel de slo ihjel. 
7:20:32: Og Benjamins barn sa: De ligger under for oss, nu som før. Men Israels barn sa: La oss flykte og dra dem bort fra byen, ut på alfarveiene! 
7:20:33: Så brøt alle Israels menn op fra det sted hvor de hadde stått, og stilte sig op i Ba'al-Tamar, mens Israels bakhold brøt frem fra sitt sted, fra Geba-sletten. 
7:20:34: Og ti tusen utvalgte menn av hele Israel kom like foran Gibea, og striden blev hård; men Benjamins barn visste ikke at ulykken var like innpå dem. 
7:20:35: Og Herren lot Benjamin ligge under for Israel, og Israels barn felte den dag fem og tyve tusen og et hundre mann av Benjamin; alle disse kunde dra sverd. 
7:20:36: Benjamins barn tenkte først at Israels menn var slått; for de trakk sig tilbake for Benjamin, idet de satte sin lit til det bakhold de hadde lagt mot Gibea. 
7:20:37: Men nu brøt bakholdet op i hast og falt inn i Gibea, og bakholdet drog frem og slo hele byen med sverdets egg. 
7:20:38: Og det var gjort den avtale mellem Israels menn og bakholdet at disse skulde la en tett røk stige op fra byen. 
7:20:39: Så vendte da Israels menn sig om i striden - Benjamin hadde i førstningen felt nogen av Israels menn, omkring tretti mann, og sa derfor: Sannelig de ligger under for oss, likesom i det første slag. 
7:20:40: Og da røken begynte å stige op fra byen som en svær støtte, hadde Benjamin vendt sig om og sett at hele byen stod i én lue som slo op mot himmelen. 
7:20:41: Da nu Israels menn vendte sig om, blev Benjamins menn forferdet, for de så at ulykken var like inn på dem. 
7:20:42: Og de vendte sig og flyktet for Israels menn og tok veien til ørkenen; men striden fulgte dem i hælene, og dem som var fra byene*, dem felte de** midt iblandt dem. # <* DMR 20, 14. 15.> # <** Israels menn.> 
7:20:43: De omringet Benjamin, forfulgte ham og trådte ham ned hvor han hvilte, til de var like i øst for Gibea. 
7:20:44: Og det falt av Benjamin atten tusen mann, alle sammen djerve stridsmenn. 
7:20:45: Så vendte de sig og flyktet til ørkenen bortimot Rimmons klippe, og på alfarveiene holdt de* en efterslett iblandt dem på fem tusen mann, og de forfulgte dem til Gideon og slo to tusen mann av dem. # <* Israels menn.> 
7:20:46: De som falt av Benjamin den dag, var i alt fem og tyve tusen mann som kunde dra sverd, alle sammen djerve stridsmenn. 
7:20:47: Men seks hundre mann vendte sig og flyktet ut i ørkenen, til Rimmons klippe, og de blev på Rimmons klippe i fire måneder. 
7:20:48: Men Israels menn vendte tilbake til Benjamins barn og slo dem med sverdets egg, både byen med sine folk og feet og alt hvad der fantes; og alle byene som fantes der, satte de ild på. 
7:21:1: I Mispa hadde Israels menn svoret: Ingen av oss skal gi nogen i Benjamin sin datter til hustru. 
7:21:2: Men da folket kom til Betel, satt de der for Guds åsyn til om aftenen, og de gråt høit og lenge 
7:21:3: og sa: Herre, Israels Gud, hvorfor har dette hendt i Israel at det i dag fattes en hel stamme av Israel? 
7:21:4: Den følgende dag stod folket tidlig op og bygget et alter der og ofret brennoffer og takkoffer. 
7:21:5: Og Israels barn sa: Hvem var det blandt alle Israels stammer som ikke kom op til Herren med menigheten? For de hadde svoret en dyr ed at den som ikke kom op til Herren i Mispa, han skulde late livet. 
7:21:6: Det gjorde Israels barn ondt for Benjamin, deres bror, og de sa: Idag er det hugget bort en stamme av Israel. 
7:21:7: Hvad skal vi gjøre for dem som er tilbake, så de kan få sig hustruer, siden vi har svoret ved Herren at vi ikke vil gi dem nogen av våre døtre til ekte? 
7:21:8: Så sa de: Var det nogen blandt Israels ætter som ikke kom op til Herren i Mispa? Da fikk de vite at fra Jabes i Gilead var ingen kommet til leiren, til møtet. 
7:21:9: For da folket blev mønstret, viste det sig at ingen av innbyggerne i Jabes i Gilead var der. 
7:21:10: Da sendte menigheten tolv tusen mann av krigsfolket dit og bød dem: Dra avsted og slå innbyggerne i Jabes i Gilead med sverdets egg, også kvinner og barn! 
7:21:11: Således skal I gjøre: Alt mannkjønn og hver kvinne som har hatt samleie med nogen mann, skal I slå med bann. 
7:21:12: Da fant de blandt innbyggerne i Jabes i Gilead fire hundre jomfruer som ikke hadde vært nær nogen mann og hatt samleie med ham; dem tok de med sig til leiren i Silo i Kana'ans land. 
7:21:13: Og hele menigheten sendte bud til Benjamins barn, som var på Rimmons klippe, og lovte dem fred. 
7:21:14: Da vendte Benjamin med én gang tilbake, og de gav dem de kvinner som de hadde latt bli i live blandt kvinnene i Jabes i Gilead; men det blev ikke nok for dem. 
7:21:15: Og det gjorde folket ondt for Benjamin; for Herren hadde gjort et skår i Israels stammer. 
7:21:16: Og menighetens eldste sa: Hvad skal vi gjøre for dem som ennu er tilbake, så de kan få sig hustruer, siden alle kvinner i Benjamin er utryddet? 
7:21:17: Og de sa: Benjamins arvedel skal høre de undkomne til, så ikke en hel stamme i Israel skal utslettes. 
7:21:18: Men vi kan ikke gi dem nogen av våre døtre til ekte. For Israels barn har svoret: Forbannet være den som gir nogen av Benjamin sin datter til hustru! 
7:21:19: Så sa de: År for år holdes det jo en høitid for Herren i Silo, som ligger nordenfor Betel, østenfor alfarveien som fører fra Betel op til Sikem, og sønnenfor Lebona. 
7:21:20: Og de sa til Benjamins barn: Gå og legg eder på lur i vingårdene 
7:21:21: og pass på, og når I ser Silos døtre gå ut til dans, da skal I springe frem fra vingårdene og røve eder hver sin hustru blandt Silos døtre og så dra hjem til Benjamins land. 
7:21:22: Og når deres fedre eller brødre kommer og vil gå i rette med oss, da skal vi si til dem: Unn oss dem! For vi har ikke alle fått oss hustruer i krigen*, og I har jo ikke selv gitt dem pikene; da vilde I nu være skyldige**. # <* nemlig mot Jabes (v. 10-14); de eldste taler disse første ord i benjaminittenes navn.> # <** DMR 21, 1. 7.> 
7:21:23: Og Benjamins barn gjorde således og tok sig hver sin hustru, så mange som de var, blandt de dansende kvinner som de røvet; og de drog avsted og vendte tilbake til sin arvedel og bygget op igjen byene og blev boende der. 
7:21:24: På samme tid reiste Israels barn også derfra, hver til sin stamme og sin ætt; de drog bort derfra, hver til sin arvedel. 
7:21:25: I de dager var det ingen konge i Israel; hver mann gjorde hvad rett var i hans øine. 
8:1:1: I de dager da dommerne styrte, blev det engang hungersnød i landet. Da drog en mann med sin hustru og sine to sønner avsted fra Betlehem i Juda for å opholde sig nogen tid i Moabs land. 
8:1:2: Mannen hette Elimelek, hans hustru No'omi og hans to sønner Mahlon og Kiljon; de var efratitter* - fra Betlehem i Juda. De kom nu til Moabs land og blev der. # <* fra Efrata, d.e. Betlehem, 1MO 35, 19.> 
8:1:3: Så døde Elimelek, No'omis mann, og hun blev tilbake med sine to sønner. 
8:1:4: De tok sig moabittiske hustruer, den ene hette Orpa og den andre Rut; og de bodde der omkring en ti års tid. 
8:1:5: Da døde også begge sønnene, Mahlon og Kiljon; og kvinnen var tilbake efter sine to sønner og efter sin mann. 
8:1:6: Da brøt hun op med sine sønnekoner og vendte tilbake fra Moabs land; for hun hadde hørt i Moabs land at Herren hadde sett til sitt folk og gitt dem brød. 
8:1:7: Hun drog bort fra det sted hvor hun hadde vært, og begge hennes sønnekoner var med henne. Mens de nu gikk frem efter veien for å vende tilbake til Juda land, 
8:1:8: sa No'omi til sine to sønnekoner: Vend om og gå hjem igjen, hver til sin mors hus. Herren vise godhet mot eder, som I har gjort mot de døde og mot mig! 
8:1:9: Herren gi eder at I må finne et hjem, hver i sin manns hus! Og hun kysset dem; men de brast i gråt 
8:1:10: og sa til henne: Nei, vi vil følge dig tilbake til ditt folk. 
8:1:11: Da sa No'omi: Vend om, mine døtre! Hvorfor vil I gå med mig? Har jeg da ennu sønner i mitt liv som kunde bli eders menn? 
8:1:12: Vend om, mine døtre, og gå hjem! For jeg er for gammel til å bli en manns hustru. Om jeg så tenkte: Jeg har ennu håp, ja, om jeg så ennu inatt fikk en mann og så fødte sønner, 
8:1:13: skulde I derfor vente til de blev store? Skulde I derfor stenge eder inne og ikke få eder menn? Nei, mine døtre! For det er meget bitrere for mig enn for eder, eftersom Herrens hånd har rammet mig så hårdt. 
8:1:14: Da brast de atter i gråt. Og Orpa kysset sin svigermor og sa henne farvel, men Rut vilde ikke skilles fra henne. 
8:1:15: Da sa hun: Du ser din svigerinne er vendt tilbake til sitt folk og til sin gud; vend nu du og tilbake og følg din svigerinne! 
8:1:16: Men Rut sa: Søk ikke å overtale mig til å forlate dig og vende tilbake! For dit du går, vil jeg gå, og hvor du blir, vil jeg bli; ditt folk er mitt folk, og din Gud er min Gud; 
8:1:17: hvor du dør, vil jeg dø, og der vil jeg begraves. Herren la det gå mig ille både nu og siden om noget annet enn døden skal skille mig fra dig. 
8:1:18: Da No'omi så at hun var fast i sitt forsett om å gå med henne, holdt hun op med å tale til henne om det. 
8:1:19: Så gikk de begge videre til de kom til Betlehem. Og da de kom til Betlehem, kom hele byen i bevegelse for deres skyld, og kvinnene sa: Er dette No'omi? 
8:1:20: Da sa hun til dem: Kall mig ikke No'omi*, kall mig Mara**! For den Allmektige har gjort det meget bittert for mig; # <* ynde.> # <** bitterhet.> 
8:1:21: med fulle hender drog jeg bort, men med tomme hender har Herren latt mig vende tilbake. Hvorfor kaller I mig No'omi, når Herren har vidnet imot mig, og den Allmektige har latt det gå mig ille? 
8:1:22: Så kom da No'omi tilbake med sin sønnekone, moabittinnen Rut - hun var vendt tilbake fra Moabs land; og de kom til Betlehem i begynnelsen av bygghøsten. 
8:2:1: No'omi hadde en frende på sin manns side, en mektig mann av Elimeleks slekt, som hette Boas. 
8:2:2: En dag sa Rut, moabittinnen, til No'omi: Kjære, la mig få gå ut på akeren og sanke aks efter en for hvis øine jeg finner nåde! Hun svarte: Gå du, min datter! 
8:2:3: Så gikk hun avsted, og hun kom og sanket aks på akeren efter høstfolkene; og det traff sig så at den aker hun sanket på, tilhørte Boas, som var av Elimeleks slekt. 
8:2:4: Da kom Boas nettop fra Betlehem, og han sa til høstfolkene: Herren være med eder! De svarte: Herren velsigne dig! 
8:2:5: Og Boas sa til den av sine tjenere som var satt over høstfolkene: Hvem tilhører denne pike? 
8:2:6: Tjeneren som var satt over høstfolkene, svarte: Det er den unge moabittinne som har fulgt No'omi tilbake fra Moabs land; 
8:2:7: hun sa: La mig få sanke op og samle aks mellem kornbåndene efter høstfolkene! Så kom hun og har stått her fra imorges like til nu; hun har bare sittet en liten stund i huset. 
8:2:8: Da sa Boas til Rut: Hør, min datter! Gå ikke bort og sank aks på nogen annen aker og gå heller ikke herfra, men hold dig til mine piker her! 
8:2:9: Se efter hvor høstfolkene arbeider på akeren, og gå efter dem! Jeg har befalt tjenerne ikke å røre dig; og blir du tørst, så gå bort til karene og drikk av det som tjenerne øser! 
8:2:10: Da falt hun på sitt ansikt og bøide sig til jorden og sa til ham: Hvorfor har jeg funnet nåde for dine øine, så du tar dig av mig, enda jeg er en fremmed? 
8:2:11: Boas svarte henne: Det er blitt mig fortalt alt hvad du har gjort mot din svigermor efter din manns død, hvorledes du forlot din far og din mor og ditt fedreland og drog til et folk som du før ikke kjente. 
8:2:12: Herren gjengjelde dig hvad du har gjort! Gid du må få full lønn av Herren, Israels Gud, til hvem du er kommet for å søke ly under hans vinger! 
8:2:13: Hun sa: La mig finne nåde for dine øine, min herre, siden du har trøstet mig og talt vennlig til din tjenerinne, enda jeg ikke engang er som en av de kvinner som er i din tjeneste. 
8:2:14: Da tiden var kommet til å holde måltid, sa Boas til henne: Kom hit og et av brødet og dypp ditt stykke i vineddiken! Så satte hun sig ved siden av høstfolkene, og han rakte henne ristet korn, og hun åt og blev mett, og enda hadde hun tilovers. 
8:2:15: Derefter stod hun op for å sanke, og Boas bød sine tjenere og sa: Også mellem kornbåndene kan hun sanke, og I skal ikke gjøre henne fortred. 
8:2:16: I skal endog trekke aks ut av kornbåndene til henne og la dem ligge så hun kan sanke dem op, og I skal ikke skjenne på henne. 
8:2:17: Så sanket hun aks på akeren like til aftenen, og da hun banket ut det hun hadde sanket, var det omkring en efa bygg. 
8:2:18: Hun tok det og gikk til byen, og hennes svigermor fikk se det hun hadde sanket. Så tok hun frem det hun hadde tilovers, da hun hadde ett sig mett, og gav henne det. 
8:2:19: Da sa hennes svigermor til henne: Hvor har du sanket idag, og hvor har du arbeidet? Velsignet være han som tok sig av dig! Og hun fortalte sin svigermor hvem hun hadde arbeidet hos, og sa: Den mann jeg har arbeidet hos idag, heter Boas. 
8:2:20: Da sa No'omi til sin sønnekone: Velsignet være han av Herren, som ikke har tatt sin miskunnhet bort hverken fra de levende eller fra de døde! Og No'omi sa til henne: Den mann er en nær slektning av oss; han er en av våre løsere. 
8:2:21: Da sa Rut, moabittinnen: Han sa også til mig: Hold dig til mine folk til de er ferdig med hele min høst. 
8:2:22: Og No'omi sa til Rut, sin sønnekone: Det er godt, min datter, at du går ut med hans piker, forat du ikke skal komme til å lide noget ondt på en annen manns aker. 
8:2:23: Så holdt hun sig til Boas' piker og sanket aks til både bygghøsten og hvetehøsten var til ende. Men hun bodde hos sin svigermor. 
8:3:1: Og No'omi, hennes svigermor, sa til henne: Min datter! Jeg vil prøve å la dig få et hjem, så du kan ha det godt. 
8:3:2: Hør nu: Boas, hvis piker du har vært sammen med, er jo vår frende. Han kaster inatt sitt bygg på treskeplassen. 
8:3:3: Nu skal du tvette dig og salve dig og ta dine klær på og gå ned til treskeplassen; men gi dig ikke til kjenne for mannen før han er ferdig med å ete og drikke! 
8:3:4: Når han så legger sig, skal du merke dig stedet hvor han legger sig; gå så der bort og slå op dekket ved hans føtter og legg dig; så vil han selv si dig hvad du skal gjøre. 
8:3:5: Hun svarte: Jeg skal gjøre alt det du sier. 
8:3:6: Så gikk hun ned til treskeplassen og gjorde aldeles som hennes svigermor hadde pålagt henne. 
8:3:7: Da Boas hadde ett og drukket og var blitt vel til mote, gikk han og la sig ved kanten av kornhaugen; da kom hun stille og slo op dekket ved hans føtter og la sig der. 
8:3:8: Midt på natten blev mannen opskremt; han bøide sig frem og fikk se at der lå en kvinne ved hans føtter. 
8:3:9: Og han sa: Hvem er du? Hun svarte: Jeg er Rut, din tjenerinne. Bre ditt dekke ut over din tjenerinne; for du er løser*. # <* RTS 2, 20.> 
8:3:10: Da sa han: Velsignet være du av Herren, min datter! Du har nu lagt en større kjærlighet for dagen enn før, ved ikke å gå efter de unge menn, hverken de fattige eller de rike. 
8:3:11: Så vær nu ikke redd, min datter! Alt det du sier, vil jeg gjøre for dig; for alt folket i min by vet at du er en bra kvinne. 
8:3:12: Det er sant som du sier: Jeg er virkelig løser; men det er en annen løser som er nærmere enn jeg. 
8:3:13: Bli nu her natten over! Vil han da imorgen løse dig, godt, så la ham det! Men har han ikke lyst til å løse dig, så løser jeg dig, så sant Herren lever! Bli liggende til imorgen tidlig! 
8:3:14: Så blev hun liggende ved hans føtter til om morgenen; da stod hun op, før folk ennu kunde kjenne hverandre. For han sa: Det må ikke bli kjent at kvinnen er kommet hit til treskeplassen. 
8:3:15: Så sa han: Kom hit med den kåpe du har på, og hold i den! Og hun holdt i den, og han målte op seks mål bygg og la det på hennes rygg; og hun gikk inn til byen. 
8:3:16: Da hun kom til sin svigermor, spurte hun: Hvorledes er det gått dig, min datter? Og hun fortalte henne alt det mannen hadde gjort mot henne, 
8:3:17: og sa: Disse seks mål bygg gav han mig; for han sa: Du må ikke komme tomhendt hjem til din svigermor. 
8:3:18: Men hun sa: Hold dig nu i ro, min datter, til du får vite hvorledes saken faller ut! For den mann hviler ikke før han får saken avgjort, og det ennu idag. 
8:4:1: Men Boas var gått op til byporten og hadde satt sig der. Da kom løseren som Boas hadde talt om, nettop forbi, og Boas sa: Kom hit og sett dig her, min venn! Og han kom og satte sig. 
8:4:2: Så kalte han til sig ti menn av byens eldste og sa: Sett eder her! Og de satte sig. 
8:4:3: Derefter sa han til løseren: Den aker som tilhørte vår bror Elimelek, har No'omi solgt, hun som er kommet tilbake fra Moabs land. 
8:4:4: Og jeg tenkte jeg vilde la dette komme til din kunnskap og si: Kjøp den i overvær av dem som sitter her, og av mitt folks eldste! Vil du innløse den, sa gjør det; men hvis ikke, så si mig det, sa jeg kan vite det! For det er ingen til å innløse den uten du og efter dig jeg selv. Han svarte: Jeg vil innløse den. 
8:4:5: Da sa Boas: Når du kjøper akeren av No'omi, kjøper du den også av moabittinnen Rut, den avdødes hustru, for å opreise den avdødes navn på hans arvelodd. 
8:4:6: Løseren svarte: Jeg kan ikke innløse den for mig, for da vilde jeg ødelegge min egen arvelodd; innløs du for dig det jeg skulde løse, for jeg kan ikke innløse det. 
8:4:7: I gamle dager var det skikk i Israel ved innløsning og bytte at den ene part til stadfestelse av saken drog sin sko av og gav den til den andre; dette gjaldt som bekreftelse i Israel. 
8:4:8: Derfor sa løseren til Boas: Kjøp du det! Og med det samme drog han sin sko av. 
8:4:9: Da sa Boas til de eldste og alt folket: I er idag vidner på at jeg har kjøpt av No'omi alt det som tilhørte Elimelek, og alt som tilhørte Kiljon og Mahlon. 
8:4:10: Også moabittinnen Rut, Mahlons enke, har jeg vunnet mig til hustru for å opreise den avdødes navn på hans arvelodd, så den avdødes navn ikke skal bli utryddet blandt hans brødre og i hans hjemsteds port; det er I vidner på idag. 
8:4:11: Og alt folket som var i porten, og de eldste sa: Ja, det er vi vidner på. Herren la den kvinne som nu drar inn i ditt hus, bli som Rakel og Lea, de to som bygget Israels hus, og gid du må bli en mektig mann i Efrata og få en navnkundig sønn i Betlehem! 
8:4:12: Ditt hus bli som Peres' hus - han som Tamar fødte Juda - ved de efterkommere som Herren vil gi dig med denne unge kvinne! 
8:4:13: Så tok Boas Rut hjem til sig, og hun blev hans hustru, og han gikk inn til henne; og Herren gav henne livsfrukt, og hun fødte en sønn. 
8:4:14: Da sa kvinnene til No'omi: Lovet være Herren, som ikke lot dig fattes en løser idag, og han bli navnkundig i Israel*! # <* nemlig ved sønner.> 
8:4:15: Han skal bli din sjels trøster og din alderdoms forsørger; for din sønnekone, som har dig kjær, har født ham, hun som er mere for dig enn syv sønner. 
8:4:16: Og No'omi tok barnet og la ham i sitt fang, og hun blev hans fostermor. 
8:4:17: Og grannekonene gav ham navn og sa: No'omi har fått en sønn! De kalte ham Obed; han blev far til Isai, Davids far. 
8:4:18: Dette er Peres' efterkommere: Peres fikk sønnen Hesron, 
8:4:19: og Hesron fikk sønnen Ram, og Ram fikk sønnen Amminadab, 
8:4:20: og Amminadab fikk sønnen Nahson, og Nahson fikk sønnen Salma, 
8:4:21: og Salmon fikk sønnen Boas, og Boas fikk sønnen Obed, 
8:4:22: og Obed fikk sønnen Isai, og Isai fikk sønnen David. 
9:1:1: Det var en mann fra Ramata'im-Sofim i Efra'im-fjellene; han hette Elkana og var sønn av Jeroham, sønn av Elihu, sønn av Tohu, sønn av Suf, en efra'imitt* # <* en levitt boende i Efra'ims stamme.> 
9:1:2: Han hadde to hustruer; den ene hette Hanna og den andre Peninna; og Peninna hadde barn, men Hanna hadde ikke. 
9:1:3: Denne mann drog år efter år op fra sin by for å tilbede og ofre til Herren, hærskarenes* Gud, i Silo; der var Elis to sønner, Hofni og Pinehas, prester for Herren. # <* NEH 9, 6.> 
9:1:4: Og når dagen kom da Elkana bar frem sitt offer, da gav han sin hustru Peninna og alle hennes sønner og hennes døtre hver sitt stykke av offeret; 
9:1:5: men Hanna gav han et dobbelt stykke, for han elsket Hanna, enda Herren hadde tillukket hennes morsliv, 
9:1:6: og hennes medbeilerske krenket henne også sårt for å egge henne til vrede, fordi Herren hadde lukket for hennes morsliv. 
9:1:7: Således gjorde han år efter år, så ofte hun drog op til Herrens hus, og således krenket Peninna henne, og hun gråt og vilde ikke ete. 
9:1:8: Da sa Elkana, hennes mann, til henne: Hanna, hvorfor gråter du, og hvorfor eter du ikke? Hvorfor er du så sorgfull? Er jeg ikke mere for dig enn ti sønner? 
9:1:9: Da de nu engang hadde ett og drukket i Silo, og mens Eli, presten, satt på sin stol ved dørposten i Herrens tempel, stod Hanna op, 
9:1:10: og i sin hjertesorg bad hun til Herren og gråt sårt. 
9:1:11: Og hun gjorde et løfte og sa: Herre, hærskarenes Gud! Dersom du vil se til din tjenerinne i hennes nød og komme mig i hu og ikke glemme din tjenerinne, men la din tjenerinne få en sønn, så vil jeg gi ham til Herren for hele hans levetid, og det skal ikke komme rakekniv på hans hode. 
9:1:12: Således bad hun lenge for Herrens åsyn, og Eli gav akt på hennes munn; 
9:1:13: for det var i sitt hjerte Hanna bad; bare hennes leber rørte sig, men hennes røst hørtes ikke. Derfor tenkte Eli at hun var drukken, 
9:1:14: og han sa til henne: Hvor lenge vil du te dig som drukken? Se til å bli av med ditt rus! 
9:1:15: Da svarte Hanna og sa: Nei, min herre! Jeg er en kvinne som bærer på en tung sorg i sitt hjerte; vin og sterk drikk har jeg ikke drukket, men jeg utøste min sjel for Herrens åsyn. 
9:1:16: Hold ikke din tjenerinne for en ryggesløs kvinne! For jeg har hele tiden talt av min store sorg og gremmelse. 
9:1:17: Da svarte Eli og sa: Gå bort i fred, og Israels Gud skal gi dig det du har bedt ham om. 
9:1:18: Hun sa: La din tjenerinne finne nåde for dine øine! Så gikk kvinnen sin vei, og nu åt hun og så ikke mere så sorgfull ut. 
9:1:19: Morgenen efter stod de tidlig op og tilbad for Herrens åsyn; så vendte de tilbake og kom hjem igjen til Rama. Og Elkana holdt sig til Hanna, sin hustru, og Herren kom henne i hu; 
9:1:20: og innen året var omme, blev Hanna fruktsommelig og fødte en sønn, og kalte ham Samuel*; for [sa hun] jeg har bedt Herren om ham. # <* for han er utbedt og jeg bønnhørt av Gud.> 
9:1:21: Da så mannen - Elkana - drog op igjen med hele sitt hus for å ofre til Herren det årlige offer og sitt løfteoffer, 
9:1:22: drog Hanna ikke med op, men sa til sin mann: Jeg vil vente til gutten er avvent; da vil jeg ta ham med mig, så han kan fremstilles for Herrens åsyn og bli der all sin tid. 
9:1:23: Elkana, hennes mann, sa til henne: Gjør hvad du finner for godt; bli hjemme til du har avvent ham; bare Herren vil holde sitt ord! Så blev kvinnen hjemme, og hun gav sin sønn die til hun hadde avvent ham. 
9:1:24: Og så snart hun hadde avvent ham, reiste hun op med ham og hadde med sig tre okser og en efa mel og en skinnsekk med vin, og hun bar ham inn i Herrens hus i Silo - han var bare en ganske liten gutt. 
9:1:25: Så slaktet de oksen og førte gutten inn til Eli. 
9:1:26: Og hun sa: Hør mig, min herre! Så sant du lever, min herre: Jeg er den kvinne som stod her hos dig og bad til Herren. 
9:1:27: Denne gutt var det jeg bad om, og Herren har gitt mig det jeg bad ham om. 
9:1:28: Og nu gir jeg ham tilbake til Herren for all den tid han er til; det var for Herrens skyld jeg bad om ham. Og de tilbad Herren der. 
9:2:1: Og Hanna bad og sa: Mitt hjerte fryder sig i Herren, høit er mitt horn i Herren, vidt oplatt min munn mot mine fiender, for jeg gleder mig over din frelse. 
9:2:2: Ingen er hellig som Herren; for det er ingen foruten dig, og det er ingen klippe som vår Gud. 
9:2:3: Tal ikke så mange høie ord, la ikke frekke ord gå ut av eders munn! For en allvitende Gud er Herren, og av ham veies gjerninger. 
9:2:4: Kjempenes bue brytes, og de snublende omgjorder sig med kraft. 
9:2:5: De mette lar sig leie for brød, og de hungrige hører op å hungre; endog den ufruktbare føder syv barn, og den som er rik på sønner, visner bort. 
9:2:6: Herren døder og gjør levende; han fører ned i dødsriket og fører op derfra. 
9:2:7: Herren gjør fattig og gjør rik; han nedtrykker, og han ophøier; 
9:2:8: han reiser den ringe av støvet, løfter den fattige av skarnet for å sette ham hos fyrster og gi ham et ærefullt sete; for Herren hører jordens støtter til, og på dem har han bygget jorderike. 
9:2:9: Han verner sine frommes føtter, men ugudelige går til grunne i mørket; for ikke ved egen kraft er mannen sterk. 
9:2:10: Forferdes skal hver den som strider mot Herren; over ham lar han det tordne i himmelen. Herren dømmer jordens ender, og han vil gi sin konge styrke og ophøie sin salvedes horn. 
9:2:11: Så drog Elkana hjem igjen til Rama, men gutten tjente Herren under Elis, prestens, tilsyn. 
9:2:12: Men Elis sønner var ryggesløse; de brydde sig ikke om Herren. 
9:2:13: Sådan var prestenes adferd mot folket: Så ofte nogen kom med et offer, og kjøttet blev kokt, kom prestens dreng og hadde en gaffel med tre grener i sin hånd; 
9:2:14: den stakk han ned i kjelen eller i gryten eller i pannen eller i potten, og alt det som kom op med gaffelen, tok presten til sig. Således gjorde de med alle israelitter som kom der til Silo. 
9:2:15: Endog før de brente fettet, kom prestens dreng og sa til den mann som ofret: Kom hit med kjøtt til å steke for presten! Han tar ikke imot kokt kjøtt av dig, bare rått. 
9:2:16: Så sa mannen til ham: Først må fettet brennes; siden kan du ta for dig efter som du har lyst til. Da svarte han: Nei, nu straks skal du komme med det; ellers tar jeg det med makt. 
9:2:17: Og de unge menns synd var meget stor for Herrens åsyn; for mennene ringeaktet Herrens offer. 
9:2:18: Men Samuel tjente for Herrens åsyn - en ung gutt klædd i en livkjortel av lerret. 
9:2:19: Og hans mor gjorde hvert år en liten kappe til ham; den hadde hun med til ham, når hun drog op med sin mann for å bære frem det årlige offer. 
9:2:20: Da velsignet Eli Elkana og hans hustru og sa: Herren gi dig barn igjen med denne kvinne istedenfor ham som hun bad om for Herren! Så drog de hjem igjen. 
9:2:21: Og Herren så til Hanna, og hun blev fruktsommelig og fødte tre sønner og to døtre; men gutten Samuel vokste op hos Herren. 
9:2:22: Eli var meget gammel, og når han hørte om alt det hans sønner gjorde mot hele Israel, og at de lå hos de kvinner som gjorde tjeneste ved inngangen til sammenkomstens telt, 
9:2:23: sa han til dem: Hvorfor gjør I således? Jeg hører av hele folket her at eders adferd er ond. 
9:2:24: Ikke så, mine sønner! Det rykte jeg hører, er ikke godt; I forfører Herrens folk. 
9:2:25: Når en mann synder mot en annen mann, skal Gud* dømme ham; men når en mann synder mot Herren, hvem skal da bede for ham? Men de hørte ikke på det deres far sa; for det var Herrens vilje å la dem dø. # <* øvrigheten, 2MO 21, 6.> 
9:2:26: Men gutten Samuel gikk frem i alder og i yndest både hos Herren og hos mennesker. 
9:2:27: Og det kom en Guds mann til Eli, og sa til ham: Så sier Herren: Har jeg ikke åpenbaret mig for din fars hus, da de var i Egypten og måtte tjene Faraos hus? 
9:2:28: Og jeg valgte ham blandt alle Israels stammer til prest for mig, til å ofre på mitt alter, brenne røkelse, bære livkjortel for mitt åsyn, og jeg gav din fars hus alle Israels barns ildoffer. 
9:2:29: Hvorfor treder I mitt slaktoffer og mitt matoffer under føtter, de offer som jeg har påbudt i min bolig? Og du ærer dine sønner mere enn mig, idet I mesker eder med det beste av hver offergave som mitt folk, Israel, bærer frem. 
9:2:30: Derfor lyder ordet fra Herren, Israels Gud: Vel har jeg sagt: Ditt hus og din fars hus skal ferdes for mitt åsyn til evig tid. Men nu lyder Herrens ord: Det være langt fra mig! Dem som ærer mig, vil jeg ære, og de som ringeakter mig, skal bli til skamme. 
9:2:31: Se, dager skal komme da jeg avhugger din arm og din fars ætts arm, så ingen i din ætt skal bli gammel. 
9:2:32: Og du skal skue trengsel for Guds bolig tross alt det gode han gjør mot Israel, og det skal aldri finnes nogen gammel i ditt hus. 
9:2:33: Dog vil jeg ikke utrydde hver mann av din ætt fra mitt alter, så jeg lar dine øine slukne og din sjel vansmekte; men alle som vokser op i ditt hus, skal dø i sine manndomsår. 
9:2:34: Og til tegn på dette skal du ha det som skal ramme begge dine sønner, Hofni og Pinehas: De skal begge dø på en dag. 
9:2:35: Og jeg vil opreise for mig en trofast prest: han skal gjøre efter min hu og min vilje, og jeg vil bygge ham et hus som blir stående, og han skal ferdes for min salvedes åsyn alle dager. 
9:2:36: Og enhver som er igjen av ditt hus, skal komme og bøie sig for ham for å få en sølvskilling og et brød og si: Kjære, sett mig i et av presteembedene, sa jeg kan få et stykke brød å ete! 
9:3:1: Gutten Samuel tjente Herren under Elis tilsyn, og Herrens ord var dyrt i de dager; av syner var der lite. 
9:3:2: Så var det en dag mens Eli lå i sitt rum - hans øine hadde begynt å bli sløve, så han ikke kunde se, 
9:3:3: og Guds lampe var ennu ikke sluknet, og Samuel lå i Herrens helligdom, hvor Guds ark var - 
9:3:4: da ropte Herren på Samuel, og han sa: Ja, her er jeg. 
9:3:5: Og han løp til Eli og sa: Her er jeg; du ropte jo på mig. Men han sa: Jeg ropte ikke; gå tilbake og legg dig! Og han gikk og la sig. 
9:3:6: Men Herren ropte ennu en gang: Samuel! Og Samuel stod op og gikk til Eli og sa: Her er jeg du ropte jo på mig. Men han sa: Jeg ropte ikke, min sønn; gå tilbake og legg dig! 
9:3:7: Samuel kjente ennu ikke Herren; for Herrens ord var ennu ikke åpenbaret for ham. 
9:3:8: Da ropte Herren tredje gang: Samuel! Og han stod op og gikk til Eli og sa: Her er jeg; du ropte jo på mig. Da skjønte Eli at det var Herren som ropte på gutten. 
9:3:9: Og Eli sa til Samuel: Gå og legg dig, og blir det ropt på dig, så skal du si: Tal, Herre, din tjener hører! Og Samuel gikk og la sig på sin plass. 
9:3:10: Da kom Herren og stod der og ropte nu som før: Samuel, Samuel! Og Samuel sa: Tal, din tjener hører! 
9:3:11: Da sa Herren til Samuel: Nu vil jeg gjøre noget i Israel så det skal ringe for begge ørene på hver den som hører om det. 
9:3:12: På den dag vil jeg la komme over Eli alt det jeg har talt om hans hus, fra først til sist. 
9:3:13: For jeg har kunngjort ham at jeg vil dømme hans hus for alltid for den misgjernings skyld at han visste at hans sønner førte forbannelse over sig, men allikevel ikke holdt dem i age. 
9:3:14: Og derfor har jeg svoret Elis ætt: Aldri i evighet skal Elis ætts misgjerninger kunne sones med slaktoffer eller med matoffer. 
9:3:15: Samuel blev liggende til om morgenen; da åpnet han døren til Herrens hus, men han vågde ikke å fortelle synet til Eli. 
9:3:16: Da ropte Eli til Samuel og sa: Samuel, min sønn! Og han svarte: Ja, her er jeg. 
9:3:17: Han sa: Hvad var det han sa til dig? Kjære, dølg det ikke for mig! Gud la det gå dig ille både nu og siden om du dølger for mig noget av det han sa til dig! 
9:3:18: Så fortalte Samuel ham alt sammen og dulgte ikke noget for ham. Da sa han: Han er Herren; han gjøre hvad der er godt i hans øine! 
9:3:19: Og Samuel vokste til, og Herren var med ham, og han lot ikke noget av alle sine ord falle til jorden. 
9:3:20: Da skjønte hele Israel fra Dan til Be'erseba at Samuel var betrodd å være profet for Herren. 
9:3:21: Og Herren blev ved å la sig se i Silo; for Herren åpenbarte sig for Samuel i Silo ved Herrens ord. 
9:4:1: Og Samuels ord kom til hele Israel, og Israel drog ut for å stride mot filistrene; de leiret sig ved Eben-Eser, og filistrene leiret sig i Afek. 
9:4:2: Og filistrene stilte sig i fylking mot Israel, og striden bredte sig ut, og Israel blev slått av filistrene; og filistrene felte på slagmarken omkring fire tusen mann. 
9:4:3: Og da folket kom til leiren, sa Israels eldste: Hvorfor har Herren idag latt oss bli slått av filistrene? La oss hente Herrens pakts-ark til oss fra Silo, så den kan komme hit iblandt oss og frelse oss av våre fienders hånd. 
9:4:4: Så sendte folket bud til Silo, og de hentet derfra Herrens, hærskarenes Guds pakts-ark, hans som troner på kjerubene; og begge Elis sønner, Hofni og Pinehas, var med og fulgte Guds pakts-ark. 
9:4:5: Med det samme Herrens pakts-ark kom til leiren, satte hele Israel i et stort fryderop, så jorden rystet. 
9:4:6: Og da filistrene hørte lyden av fryderopet, sa de: Hvad er dette for et stort fryderop i hebreernes leir? Og de fikk vite at Herrens ark var kommet til leiren. 
9:4:7: Da blev filistrene redde; for de tenkte: Gud er kommet til leiren. Og de sa: Ve oss! Sådant har ikke hendt før. 
9:4:8: Ve oss! Hvem skal fri oss av disse veldige guders hånd? Dette er de guder som slo Egypten med alle slags plager i ørkenen. 
9:4:9: Ta mot til eder og vær menn, I filistrer, forat I ikke skal komme til å træle for hebreerne, likesom de har trælet for eder! Vær menn og strid! 
9:4:10: Da gikk filistrene til strid, og Israel blev slått, og de flyktet hver til sitt telt, og der blev et meget stort mannefall - det falt tretti tusen mann av Israels fotfolk. 
9:4:11: Og Guds ark blev tatt, og begge Elis sønner, Hofni og Pinehas, mistet livet. 
9:4:12: Da løp en mann av Benjamin fra slagmarken og kom samme dag til Silo med sønderrevne klær og med jord på sitt hode. 
9:4:13: Da han kom frem, da satt Eli på sin stol ved siden av veien og så bortefter, for hans hjerte var fullt av angst for Guds ark. Da nu mannen kom til byen med buskapet, da satte hele byen i et jammerskrik. 
9:4:14: Eli hørte skriket og sa: Hvad er dette for en larm? Da skyndte mannen sig og kom og fortalte det til Eli. 
9:4:15: Eli var da åtte og nitti år gammel, og hans øine var stive - han kunde ikke se. 
9:4:16: Da nu mannen sa til Eli: Det er jeg som er kommet fra slagmarken, jeg flyktet fra slagmarken idag, så spurte han: Hvorledes er det gått, min sønn? 
9:4:17: Den som kom med buskapet, svarte: Israel er flyktet for filistrene, og det har vært et stort mannefall blandt folket; dine to sønner, Hofni og Pinehas, har og mistet livet, og Guds ark er tatt. 
9:4:18: Med det samme han nevnte Guds ark, falt Eli baklengs ned av stolen ved siden av porten, brakk nakkebenet og døde; for mannen var gammel og tung. Han hadde dømt Israel i firti år. 
9:4:19: Hans sønnekone, Pinehas' hustru, var fruktsommelig og nær ved å føde, og da hun hørte buskapet om at Guds ark var tatt, og at hennes svigerfar og hennes mann var død, seg hun i kne og fødte; for veene kom brått over henne. 
9:4:20: I hennes dødsstund sa de kvinner som stod hos henne: Vær ved godt mot; du har født en sønn! Men hun svarte ikke og aktet ikke derpå. 
9:4:21: Hun kalte gutten Ikabod* og sa: Bortveket er herligheten fra Israel - fordi Guds ark var tatt, og for hennes svigerfars og hennes manns skyld. # <* d.e. ingen herlighet.> 
9:4:22: Hun sa: Bortveket er herligheten fra Israel; for Guds ark er tatt. 
9:5:1: Filistrene tok Guds ark og førte den fra Eben-Eser til Asdod. 
9:5:2: Der tok filistrene Guds ark og førte den inn i Dagons hus og stilte den op ved siden av Dagon. 
9:5:3: Tidlig den følgende dag fikk asdodittene se at Dagon var falt ned med ansiktet mot jorden foran Herrens ark; og de tok Dagon og satte ham igjen på hans plass. 
9:5:4: Men tidlig om morgenen den næste dag fikk de se at Dagon var falt ned med ansiktet mot jorden foran Herrens ark, og at Dagons hode og begge hans hender lå avhugget på dørtreskelen; bare kroppen var tilbake av Dagon. 
9:5:5: Derfor er det at Dagons prester og alle som går inn i Dagons hus, like til denne dag ikke treder på Dagons dørtreskel i Asdod. 
9:5:6: Og Herrens hånd lå tungt på asdodittene, og han ødela dem og slo dem med bylder, både i Asdod selv og i de omliggende bygder. 
9:5:7: Da Asdods menn så at det gikk således, sa de: Israels Guds ark skal ikke bli hos oss; for hans hånd ligger hårdt på oss og på Dagon vår gud. 
9:5:8: Og de sendte bud og samlet alle filistrenes høvdinger hos sig og sa: Hvad skal vi gjøre med Israels Guds ark? De svarte: La Israels Guds ark bli flyttet til Gat! Så flyttet de Israels Guds ark dit. 
9:5:9: Men efterat de hadde flyttet den dit, kom Herrens hånd over byen og voldte stor forferdelse, og han slo byens folk, både små og store, så det brøt bylder ut på dem. 
9:5:10: Da sendte de Guds ark til Ekron. Men da Guds ark kom til Ekron, da skrek ekronittene: De har flyttet Israels Guds ark hit til mig for å drepe mig og mitt folk. 
9:5:11: Og de sendte bud og samlet alle filistrenes høvdinger og sa: Send Israels Guds ark bort, og la den komme tilbake til sin plass, så den ikke skal drepe mig og mitt folk! For det var kommet en dødsens forferdelse over hele byen; Herrens hånd lå meget tungt på den. 
9:5:12: De menn som ikke døde, blev slått med bylder, og skriket fra byen steg op til himmelen. 
9:6:1: Herrens ark var i filistrenes land i syv måneder. 
9:6:2: Da tilkalte filistrene prestene og spåmennene og sa: Hvad skal vi gjøre med Herrens ark? Si oss hvorledes vi skal sende den fra oss tilbake til dens plass! 
9:6:3: De svarte: Dersom I sender Israels Guds ark bort, må I ikke sende den uten gaver, men I skal gi ham et skyldoffer til vederlag; da skal I bli lægt, og I skal få vite hvorfor hans hånd ikke viker fra eder. 
9:6:4: Da sa de: Hvad er det for et skyldoffer vi skal gi ham til vederlag? De svarte: Fem bylder av gull og fem mus av gull, efter tallet på filistrenes høvdinger; for det er en og samme plage som har rammet alle, også eders høvdinger. 
9:6:5: I skal gjøre billeder av eders bylder og billeder av musene som ødelegger eders land, og gi Israels Gud ære; kanskje han da tar sin tunge hånd bort fra eder og fra eders gud og fra eders land. 
9:6:6: Hvorfor vil I forherde eders hjerte, likesom egypterne og Farao forherdet sitt hjerte. Måtte de ikke la dem fare og dra bort da han hadde latt dem få kjenne sin makt? 
9:6:7: Ta nu og gjør en ny vogn, og ta så to nybære kyr som det ikke er kommet åk på, og spenn kyrne for vognen, men ta kalvene fra dem og før dem hjem! 
9:6:8: Så skal I ta Herrens ark og sette den på vognen, og de gullsaker som I gir ham til skyldoffer, skal I legge i et skrin ved siden av den og så sende den avsted. 
9:6:9: Så skal I se efter om den tar veien opover til sitt eget land, til Betsemes; for da er det han som har voldt oss denne store ulykke; men hvis ikke, så vet vi at det ikke er hans hånd som har rammet oss; da er det bare et tilfelle at dette har hendt oss. 
9:6:10: Og mennene gjorde således: De tok to nybære kyr og spente dem for vognen, men kalvene holdt de tilbake hjemme. 
9:6:11: Så satte de Herrens ark på vognen og likeså skrinet og gullmusene og billedene av sine bylder. 
9:6:12: Og kyrne gikk rett frem efter veien som førte til Betsemes; de holdt stadig samme vei og rautet alt i ett og bøide ikke av hverken til høire eller til venstre, og filistrenes høvdinger gikk efter dem like til Betsemes-bygden. 
9:6:13: Innbyggerne i Betsemes var nettop i ferd med å høste hveten i dalen; og da de så op, fikk de se arken, og de blev glade da de så den. 
9:6:14: Da vognen kom inn på betsemesitten Josvas mark, stanset den der. Der lå en stor sten. Da hugg de treverket i vognen i stykker og ofret kyrne til brennoffer for Herren. 
9:6:15: Og levittene tok ned Herrens ark og skrinet som stod ved den, det som gullsakene var i, og de satte dem på den store sten, og Betsemes' menn ofret samme dag brennoffer og slaktoffer til Herren. 
9:6:16: Filistrenes fem høvdinger så på dette og vendte samme dag tilbake til Ekron. 
9:6:17: Dette var de bylder av gull som filistrene gav som skyldoffer til Herren: for Asdod én, for Gasa én, for Askalon én, for Gat én, for Ekron én. 
9:6:18: Og gullmusene svarte til tallet på alle filistrenes byer som tilhørte de fem høvdinger, både de faste byer og landsbyene. Vidne herom er den dag idag den store sten på betsemesitten Josvas mark som de satte Herrens ark på. 
9:6:19: Men Herren slo nogen av mennene i Betsemes fordi de hadde sett på Herrens ark - sytti mann av folket slo han; og folket sørget fordi Herren hadde slått så mange av folket ihjel. 
9:6:20: Og mennene i Betsemes sa: Hvem kan holde stand for Herrens, denne hellige Guds åsyn, og til hvem skal han dra op fra oss? 
9:6:21: Så sendte de bud til innbyggerne i Kirjat-Jearim og lot si: Filistrene har ført Herrens ark tilbake; kom ned og hent den op til eder! 
9:7:1: Da kom Kirjat-Jearims menn og hentet Herrens ark op og bar den inn i Abinadabs hus på haugen, og hans sønn Eleasar vidde de til å ta vare på Herrens ark. 
9:7:2: Fra den dag arken kom til Kirjat-Jearim, gikk det en lang tid - tyve år gikk det; da sukket hele Israels hus efter Herren*. # <* søkte Herren med sukk over sin nød.> 
9:7:3: Og Samuel sa til hele Israels hus: Dersom I vender om til Herren av hele eders hjerte, så ha de fremmede guder og Astarte-billedene bort fra eder og vend eders hjerte til Herren og tjen ham alene! Så skal han fri eder av filistrenes hånd. 
9:7:4: Da skilte Israels barn sig av med Ba'alene og Astarte-billedene og tjente Herren alene. 
9:7:5: Og Samuel sa: La hele Israel samle sig i Mispa! Så vil jeg bede til Herren for eder. 
9:7:6: Så samlet de sig i Mispa, og de øste op vann og helte det ut for Herrens åsyn*, og de fastet den dag og bekjente der: Vi har syndet mot Herren. Og Samuel dømte Israels barn i Mispa. # <* til tegn på at de i sorg over sin synd utøste sitt hjerte til Herren.> 
9:7:7: Da filistrene hørte at Israels barn hadde samlet sig i Mispa, drog filistrenes høvdinger op imot Israel, og da Israels barn hørte det, var de redde for filistrene. 
9:7:8: Og Israels barn sa til Samuel: Hold ikke op med å rope for oss til Herren vår Gud at han vil frelse oss av filistrenes hånd! 
9:7:9: Da tok Samuel et diende lam og ofret det helt som brennoffer til Herren, og Samuel ropte til Herren for Israel, og Herren svarte ham. 
9:7:10: For mens Samuel ofret brennofferet, og filistrene rykket frem til strid mot Israel, da sendte Herren samme dag et sterkt tordenvær over filistrene og forferdet dem, så de blev slått av Israel. 
9:7:11: Da drog Israels menn ut fra Mispa og forfulgte filistrene og hugg dem ned, like til nedenfor Bet-Kar. 
9:7:12: Og Samuel tok en sten og satte den imellem Mispa og Sen; denne sten kalte han Eben-Eser* og sa: Hittil har Herren hjulpet oss. # <* hjelpe-sten.> 
9:7:13: Således blev filistrene ydmyket og kom ikke mere inn i Israels land; og Herrens hånd var imot filistrene alle Samuels dager. 
9:7:14: Og de byer som filistrene hadde tatt fra Israel, kom tilbake til Israel igjen, fra Ekron like til Gat, og alt land som hørte til dem, fridde Israel av filistrenes hånd, og det var fred mellem Israel og amorittene. 
9:7:15: Samuel dømte Israel så lenge han levde. 
9:7:16: År efter år drog han omkring til Betel og Gilgal og Mispa og dømte Israel på alle disse steder. 
9:7:17: Og hver gang vendte han tilbake til Rama; for der var hans hus, og der dømte han Israel; og han bygget der et alter for Herren. 
9:8:1: Da Samuel blev gammel, satte han sine sønner til dommere over Israel. 
9:8:2: Hans førstefødte sønn hette Joel og hans annen sønn Abia; de dømte i Be'erseba. 
9:8:3: Men hans sønner vandret ikke på hans veier, men søkte bare egen vinning og tok imot gaver og bøide retten. 
9:8:4: Da samlet alle Israels eldste sig og kom til Samuel i Rama. 
9:8:5: Og de sa til ham: Nu er du blitt gammel, og dine sønner vandrer ikke på dine veier; så sett nu en konge over oss til å dømme oss, som alle folkene har! 
9:8:6: Men det ord gjorde Samuel ondt, da de sa: Gi oss en konge til å dømme oss! Og Samuel bad til Herren. 
9:8:7: Da sa Herren til Samuel: Lyd folket i alt hvad de sier til dig! For det er ikke dig de har forkastet, men det er mig de har forkastet, så jeg ikke skal være konge over dem. 
9:8:8: Som de alltid har gjort fra den dag jeg førte dem op fra Egypten, til den dag idag, idet de forlot mig og tjente andre guder, således gjør de nu også mot dig. 
9:8:9: Men lyd nu deres ord! Du må bare alvorlig vidne for dem og foreholde dem hvorledes han vil bære sig at den konge som kommer til å råde over dem. 
9:8:10: Så sa Samuel alle Herrens ord til folket som krevde en konge av ham. 
9:8:11: Han sa: Således vil han bære sig at den konge som kommer til å råde over eder: Eders sønner vil han ta og sette dem til å stelle med sin vogn og sine hester, og de skal løpe foran hans vogn, 
9:8:12: Og han vil sette dem til høvdinger over tusen og høvdinger over femti og til å pløie hans akrer og høste hans avling og til å gjøre hans krigsredskaper og hans kjøretøi. 
9:8:13: Eders døtre vil han ta og sette til å lage salver og til å koke og bake for ham. 
9:8:14: Eders beste jorder og vingårder og oljehaver vil han ta og gi sine tjenere. 
9:8:15: Og av eders akrer og eders vingårder vil han ta tiende og gi sine hoffmenn og sine tjenere. 
9:8:16: Eders træler og eders trælkvinner og eders beste unge menn og eders asener vil han ta og bruke til sitt arbeid. 
9:8:17: Av eders småfe vil han ta tiende, og I selv skal være hans træler. 
9:8:18: Da skal I en dag rope til Herren for den konges skyld som I har kåret eder; men den dag skal Herren ikke svare eder. 
9:8:19: Men folket vilde ikke høre på Samuel, de sa: Nei, vi vil ha en konge over oss. 
9:8:20: Vi vil være som alle de andre folk; vår konge skal dømme oss, og han skal dra ut foran oss og føre våre kriger. 
9:8:21: Da Samuel hadde hørt alle folkets ord, bar han dem frem for Herren. 
9:8:22: Og Herren sa til Samuel: Lyd deres ord og sett en konge over dem! Så sa Samuel til Israels menn: Gå hjem hver til sin by! 
9:9:1: Det var en mann av Benjamin som hette Kis, sønn til Abiel, sønn til Seror, sønn til Bekorat, sønn til Afiah, sønn til en benjaminitt; han var en mektig mann. 
9:9:2: Han hadde en sønn som hette Saul, en ung og vakker mann; det fantes ingen mann blandt Israels barn som var vakrere enn han; han var et hode høiere enn alt folket. 
9:9:3: En dag kom Kis', Sauls fars aseninner bort for ham, og Kis sa til sin sønn Saul: Ta en av drengene med dig og dra avsted og let efter aseninnene! 
9:9:4: Så gikk han over Efra'im-fjellet og gjennem Salisa-bygden, men de fant dem ikke; så gikk de gjennem Sa'alim-bygden, men der var de ikke; derefter gikk han gjennem Benjamins land, men de fant dem ikke der heller. 
9:9:5: Da de kom til Suf-bygden, sa Saul til drengen som var med ham: Kom, la oss gå hjem igjen! Ellers kunde min far bli urolig for oss, istedenfor å tenke på aseninnene. 
9:9:6: Men han svarte ham: I denne by er det en Guds mann som det går stort ord av; alt hvad han sier, slår til. La oss nu gå dit! Han kan visst si oss noget om det vi går efter. 
9:9:7: Da sa Saul til sin dreng: Men om vi nu går dit, hvad skal vi da ha med oss til mannen? Vi har ikke mere brød igjen i våre skrepper, og vi har ingen gave å gi Guds-mannen; hvor meget har vi med oss? 
9:9:8: Så tok drengen igjen til orde og sa til Saul: Her har jeg hos mig en fjerdedel sekel sølv; den vil jeg gi til Guds-mannen, så sier han oss nok hvad vei vi skal gå. 
9:9:9: Fordum sa folk i Israel når de gikk avsted for å få vite Guds vilje: Kom, la oss gå til seeren! For den som nu kalles profet, kaltes fordum seer. 
9:9:10: Saul sa til sin dreng: Du har rett; kom, la oss gå! Så gikk de til byen hvor Guds-mannen var. 
9:9:11: Da de gikk op bakken som førte til byen, møtte de nogen piker som kom ut for å hente vann, og de sa til dem: Er seeren her? 
9:9:12: De svarte dem og sa: Ja, nettop nu er han her. Skynd dig nu! Idag er han kommet til byen, fordi folket idag holder offerfest på haugen. 
9:9:13: Når I kommer inn i byen, finner I ham straks før han går op på haugen til måltidet; for folket går ikke til bords før han kommer, fordi han skal velsigne offeret; først derefter setter de innbudne sig til bords. Gå bare op! For nettop nu kan I treffe ham. 
9:9:14: Så gikk de op til byen, og med det samme de kom inn i byen, kom Samuel ut imot dem for å gå op på haugen. 
9:9:15: Men dagen før Saul kom, hadde Herren varslet Samuel og sagt: 
9:9:16: På denne tid imorgen vil jeg sende en mann fra Benjamins land til dig; ham skal du salve til fyrste over mitt folk Israel, og han skal frelse mitt folk av filistrenes hånd; for jeg har sett til mitt folk, fordi deres rop er steget op til mig. 
9:9:17: Da nu Samuel så Saul, sa Herren til ham: Se, det er den mann om hvem jeg har sagt til dig: Han skal styre mitt folk. 
9:9:18: Så gikk Saul frem til Samuel midt i porten og sa: Si mig hvor seeren bor! 
9:9:19: Samuel svarte Saul og sa: Jeg er seeren. Gå foran mig op på haugen! I skal ete med mig idag; imorgen tidlig når jeg lar dig fare, skal jeg si dig alt det du tenker på. 
9:9:20: Og aseninnene som kom bort for dig idag for tre dager siden, dem skal du ikke være urolig for; de er funnet. Og hvem tilhører alt det beste i Israel om ikke dig og hele din fars hus? 
9:9:21: Saul svarte og sa: Er jeg ikke en benjaminitt, hører jeg ikke til en av de minste blandt Israels stammer, og er ikke min ætt den ringeste av alle ætter i Benjamins stamme? Hvorfor taler du da således til mig? 
9:9:22: Men Samuel tok Saul og hans dreng og førte dem til festsalen og lot dem sette sig øverst blandt de innbudne - det var omkring tretti menn. 
9:9:23: Og Samuel sa til den mann som kokte maten: Kom hit med det stykke jeg gav dig og sa du skulde gjemme! 
9:9:24: Da kom mannen frem med låret og fettet som var på det, og satte det foran Saul; og Samuel sa: Se, her er det som var gjemt, satt frem for dig; et nu! For til denne stund blev det gjemt for dig, dengang jeg sa: Jeg har innbudt folket. Så åt Saul den dag sammen med Samuel. 
9:9:25: Derefter gikk de ned fra haugen og inn i byen, og han satt og talte med Saul på taket. 
9:9:26: Tidlig om morgenen, med det samme det tok til å dages, kalte Samuel Saul op på taket og sa: Gjør dig rede, så vil jeg følge dig på veien. Så gjorde Saul sig rede, og de gikk ut sammen, han og Samuel. 
9:9:27: Da de nu kom ned til utkanten av byen, sa Samuel til Saul: Si til drengen at han skal gå foran oss - og han gikk - men stå du nu her! Så vil jeg la dig høre hvad Gud har sagt. 
9:10:1: Og Samuel tok en oljekrukke og helte den ut over hans hode, og han kysset ham og sa: Nu har Herren salvet dig til fyrste over sin arv. 
9:10:2: Når du idag går fra mig, skal du møte to menn ved Rakels grav på grensen av Benjamin i Selsah, og de skal si til dig: Aseninnene som du gikk ut for å lete efter, er funnet; din far tenker ikke mere på dem, men er nu urolig for eder og sier: Hvad skal jeg gjøre for å få min sønn igjen? 
9:10:3: Og når du går videre derfra og kommer til Tabors ek, skal du der møte tre menn som er på vei op til Gud i Betel, en som bærer tre kje, og en som bærer tre brød, og en som bærer en skinnsekk med vin. 
9:10:4: De skal hilse på dig og gi dig to brød, og dem skal du ta imot. 
9:10:5: Derefter kommer du til Guds Gibea, hvor filistrene har sine vaktposter; og når du kommer der til byen, skal du treffe på en flokk profeter som kommer ned fra haugen med harpe og tromme og fløite og citar foran sig, og selv er de i en profetisk henrykkelse. 
9:10:6: Og Herrens Ånd skal komme over dig, så du skal gripes av profetisk henrykkelse likesom de og bli til et annet menneske. 
9:10:7: Når du ser at disse tegn inntreffer, da gjør hvad du får leilighet til! For Gud er med dig. 
9:10:8: Gå du før mig ned til Gilgal, så skal jeg komme ned til dig for å ofre brennoffer og takkoffer; syv dager skal du bie, inntil jeg kommer til dig, og da skal jeg la dig få vite hvad du skal gjøre. 
9:10:9: Da nu Saul vendte sig og gikk sin vei fra Samuel, da gav Gud ham et annet hjerte, og samme dag traff alle disse tegn inn. 
9:10:10: Da de kom til Gibea, kom en flokk profeter ham i møte; da kom Guds Ånd over ham, og han blev grepet av profetisk henrykkelse midt iblandt dem. 
9:10:11: Og da alle de som kjente ham før, så at han var grepet av henrykkelse likesom profetene, da sa folket til hverandre: Hvad er det som har hendt med Kis' sønn? Er også Saul blandt profetene? 
9:10:12: Da svarte en mann derfra og sa: Hvem er da far til disse? Derfor er det blitt til et ordsprog: Er også Saul blandt profetene? 
9:10:13: Da hans profetiske henrykkelse hadde hørt op, kom han til offerhaugen, 
9:10:14: og Sauls farbror* sa til ham og hans dreng: Hvor har I vært? Han svarte: Vi har vært ute og lett efter aseninnene, og da vi ikke så noget til dem, gikk vi til Samuel. # <* 1SA 14, 50.> 
9:10:15: Da sa Sauls farbror: Kjære, fortell mig hvad Samuel sa til eder! 
9:10:16: Saul svarte sin farbror: Han fortalte oss at aseninnene var funnet. Men det som Samuel hadde sagt om kongedømmet, fortalte han ham ikke. 
9:10:17: Så kalte Samuel folket sammen til Herren, til Mispa. 
9:10:18: Og han sa til Israels barn: Så sier Herren, Israels Gud: Jeg førte Israel op fra Egypten og fridde eder ut fra egypterne og fra alle de riker som undertrykte eder. 
9:10:19: Men I har nu forkastet eders Gud, han som frelste eder ut av alle eders ulykker og trengsler, og sagt til ham: Nei, en konge skal du sette over oss! Så still eder nu frem for Herrens åsyn efter eders stammer og ætter! 
9:10:20: Så lot Samuel alle Israels stammer gå frem, og loddet falt på Benjamins stamme. 
9:10:21: Så lot han Benjamins stamme gå frem efter sine ætter, og loddet falt på Matris ætt, og derefter falt loddet på Saul, Kis' sønn. Og de lette efter ham, men kunde ikke finne ham. 
9:10:22: Da spurte de atter Herren: Er det kommet nogen annen hit? Herren svarte: Han holder sig skjult ved trosset. 
9:10:23: Da sprang de avsted og hentet ham derfra, og han gikk frem midt iblandt folket, og han var et hode høiere enn alt folket. 
9:10:24: Og Samuel sa til alt folket: Her ser I ham som Herren har utvalgt! Det er ingen som han blandt alt folket. Da jublet alt folket og ropte: Kongen leve! 
9:10:25: Så talte Samuel til folket om kongedømmets rett og skrev det op i en bok; den la han ned for Herrens åsyn. Derefter lot Samuel alt folket fare, hver til sitt hjem. 
9:10:26: Saul drog og hjem til Gibea, og med ham fulgte den flokk hvis hjerte Gud hadde rørt ved. 
9:10:27: Men nogen ugudelige mennesker sa: Hvad hjelp skal denne kunne gi oss? Og de ringeaktet ham og kom ikke med gaver til ham; men han lot som han ikke merket det. 
9:11:1: Så drog ammonitten Nahas op og kringsatte Jabes i Gilead, og alle Jabes' menn sa til Nahas: Gjør fred med oss, så vil vi tjene dig. 
9:11:2: Men ammonitten Nahas svarte dem: På det vilkår vil jeg gjøre fred med eder at det høire øie stikkes ut på eder alle; den skjensel vil jeg legge på hele Israel. 
9:11:3: Da sa Jabes eldste til ham: Gi oss frist i syv dager, så vi kan sende bud rundt om i hele Israels land! Er det da ingen som hjelper oss, så vil vi overgi oss til dig. 
9:11:4: Da sendebudene kom til Sauls Gibea og bar frem sitt ærend for folket, brast alt folket i gråt. 
9:11:5: I samme stund kom Saul gående hjem fra marken bakefter sine okser. Og Saul sa: Hvad er det i veien med folket siden de gråter? Og de fortalte ham hvad mennene fra Jabes hadde sagt. 
9:11:6: Da Saul hørte dette, kom Guds Ånd over ham, og hans vrede optendtes høilig. 
9:11:7: Og han tok et par okser og hugg dem i stykker og sendte stykkene om i hele Israels land med sendebudene og lot si: Den som ikke drar ut efter Saul og Samuel, med hans okser skal det gjøres likedan. Da falt det en redsel fra Herren over folket, og de drog ut som en mann. 
9:11:8: Han mønstret dem i Besek, og Israels barn var tre hundre tusen, og Judas menn tretti tusen. 
9:11:9: Og de sa til sendebudene som var kommet: Så skal I si til mennene i Jabes i Gilead: Imorgen, når solen brenner hett, skal det komme hjelp til eder. Da sendebudene kom og meldte dette til mennene i Jabes, blev de glade, 
9:11:10: og de sa [til ammonittene]: Imorgen vil vi overgi oss til eder, så kan I gjøre med oss aldeles som I finner for godt! 
9:11:11: Dagen efter stilte Saul folket op i tre hoper, og i morgenvakten trengte de midt inn i leiren og hugg ammonittene ned, til dagen blev het; og de som blev tilbake, spredtes, så det ikke blev to sammen tilbake av dem. 
9:11:12: Da sa folket til Samuel: Hvem var det som sa: Skal Saul være konge over oss? La oss få fatt i disse menn, så vi kan drepe dem. 
9:11:13: Da sa Saul: På denne dag skal ingen drepes; for idag har Gud hjulpet Israel til seier. 
9:11:14: Og Samuel sa til folket: Kom, la oss gå til Gilgal for å stadfeste kongedømmet der! 
9:11:15: Da gikk alt folket til Gilgal, og der i Gilgal gjorde de Saul til konge for Herrens åsyn; og de ofret der takkoffer for Herrens åsyn. Og Saul og alle Israels menn gledet sig der storlig. 
9:12:1: Da sa Samuel til hele Israel: Nu har jeg lydt eder i alt hvad I har sagt til mig, og satt en konge over eder. 
9:12:2: Sa har I nu kongen til fører for eder; men jeg er gammel og grå, så I nu har mine sønner iblandt eder. Jeg har vært fører for eder fra min ungdom like til denne dag. 
9:12:3: Her står jeg! Vidne nu mot mig for Herren og for hans salvede: Er det nogen jeg har fratatt hans okse eller hans asen? Er det nogen jeg har gjort urett mot eller undertrykt? Er det nogen jeg har tatt imot gaver av, så jeg skulde lukke mine øine til for noget? Om så er, vil jeg gi eder vederlag for det. 
9:12:4: De svarte: Du har ikke gjort urett mot oss og ikke undertrykt oss og ikke tatt imot noget av nogen. 
9:12:5: Da sa han til dem: Herren er vidne mot eder, og hans salvede er vidne på denne dag at I ikke har funnet noget hos mig. Folket svarte: Ja, han er vidne. 
9:12:6: Da sa Samuel til folket: Ja, Herren er vidne, han som lot Moses og Aron stå frem, og som førte eders fedre op fra Egyptens land. 
9:12:7: Kom nu frem her, så vil jeg gå i rette med eder for Herrens åsyn om alle Herrens rettferdige gjerninger som han har gjort mot eder og eders fedre. 
9:12:8: Da Jakob var kommet til Egypten, ropte eders fedre til Herren, og Herren sendte Moses og Aron, og de førte eders fedre ut av Egypten og lot dem bosette sig i dette land. 
9:12:9: Men de glemte Herren sin Gud, og han solgte dem i Siseras, Hasors hærførers hånd og i filistrenes hånd og i Moabs konges hånd; alle disse stred imot dem. 
9:12:10: Da ropte de til Herren og sa: Vi har syndet! Vi har forlatt Herren og tjent Ba'alene og Astarte-billedene; men redd oss nu av våre fienders hånd, så vil vi tjene dig! 
9:12:11: Så sendte Herren Jerubba'al og Bedan* og Jefta og Samuel og reddet eder av eders fienders hånd rundt omkring, så I kunde bo trygt. # <* rimeligvis Barak, DMR 4, 6.> 
9:12:12: Men da I så at Nahas, Ammons barns konge, kom imot eder, sa I til mig: Nei, en konge skal råde over oss - enda Herren eders Gud var eders konge. 
9:12:13: Her er nu kongen som I har valgt - som I har bedt om; Herren har satt en konge over eder. 
9:12:14: Dersom I nu frykter Herren og tjener ham og lyder ham og ikke er gjenstridige mot Herrens ord, men følger Herren eders Gud, både I og kongen som råder over eder [så skal Herren være med eder]. 
9:12:15: Men dersom I ikke lyder Herren, men er gjenstridige mot Herrens ord, så skal Herrens hånd være mot eder som mot eders fedre. 
9:12:16: Kom da nu frem og se den store gjerning som Herren vil gjøre for eders øine! 
9:12:17: Er det ikke nu hvetehøstens tid? Jeg vil rope til Herren og han skal sende torden og regn. Kjenn da og se at eders ondskap er stor, den som I har gjort for Herrens øine ved å kreve en konge! 
9:12:18: Så ropte Samuel til Herren, og samme dag sendte Herren torden og regn; da blev alt folket grepet av stor frykt for Herren og for Samuel. 
9:12:19: Og alt folket sa til Samuel: Bed for dine tjenere til Herren din Gud, så vi ikke skal dø fordi vi til alle våre andre synder har lagt den misgjerning å kreve en konge! 
9:12:20: Da sa Samuel til folket: Frykt ikke! I har gjort alt dette onde; men vik nu bare ikke fra Herren, men tjen Herren av hele eders hjerte! 
9:12:21: Vik ikke av! For da måtte I følge de tomme avguder, som ikke kan gagne og ikke redde, fordi de er bare tomhet. 
9:12:22: For Herren vil for sitt store navns skyld ikke forlate sitt folk, siden Herren har funnet for godt å gjøre eder til sitt folk. 
9:12:23: Men jeg - det være langt fra mig å synde mot Herren ved å holde op med å bede for eder; og jeg vil lære eder den gode og rette vei. 
9:12:24: Frykt bare Herren og tjen ham i sannhet, av hele eders hjerte! Se hvor store ting han har gjort for eder! 
9:12:25: Men gjør I det som ondt er, da skal både I og eders konge bli bortrykket. 
9:13:1: I ett år hadde Saul dengang vært konge; og da han hadde vært konge over Israel i to år, 
9:13:2: valgte han sig tre tusen mann av Israel; av dem var to tusen med Saul i Mikmas og i Betel-fjellene, og tusen var med Jonatan i Gibea i Benjamin; resten av folket lot han fare hjem hver til sitt. 
9:13:3: Og Jonatan slo det krigsmannskap som filistrene hadde i Geba; det fikk filistrene høre; men Saul lot basunen lyde rundt omkring i hele landet og sa: Hebreerne skal høre det. 
9:13:4: Og hele Israel fikk høre tidenden om at Saul hadde slått filistrenes krigsmannskap, og at filistrene hadde lagt Israel for hat. Og folket blev kalt til våben for å følge Saul til Gilgal. 
9:13:5: Filistrene samlet sig for å stride mot Israel, tretti tusen vogner og seks tusen hestfolk, og fotfolk så mange som sanden ved havets bredd; og de drog op og leiret sig ved Mikmas østenfor Bet-Aven. 
9:13:6: Da nu Israels menn så hvilken fare de var i fordi folket blev hårdt angrepet, da skjulte folket sig i huler og tornebusker og fjellkløfter og tårner og brønner. 
9:13:7: Nogen av hebreerne gikk over Jordan til Gads og Gileads land; men Saul var ennu i Gilgal, og alt folket fulgte ham skjelvende. 
9:13:8: Han ventet syv dager - til den tid Samuel hadde fastsatt; men Samuel kom ikke til Gilgal, og folket spredte sig og forlot ham. 
9:13:9: Da sa Saul: Kom hit til mig med brennofferet og takkofferne! Og så ofret han brennofferet. 
9:13:10: Men da han vel hadde ofret brennofferet, da kom Samuel, og Saul gikk ut imot ham for å hilse på ham. 
9:13:11: Da sa Samuel: Hvad har du gjort? Saul svarte: Da jeg så at folket spredte sig og forlot mig, og du ikke kom til den fastsatte tid, og filistrene samlet sig ved Mikmas. 
9:13:12: så tenkte jeg: Nu drar filistrene ned mot mig til Gilgal, og jeg har ennu ikke bønnfalt Herren, og jeg tok mot til mig og ofret brennofferet. 
9:13:13: Da sa Samuel til Saul: Du har båret dig dårlig at, du har ikke holdt det bud som Herren din Gud gav dig; ellers hadde Herren nu stadfestet ditt kongedømme over Israel for all tid. 
9:13:14: Men nu skal ditt kongedømme ikke stå ved makt; Herren har søkt sig ut en mann efter sitt hjerte, og ham har Herren utsett til fyrste over sitt folk; for du har ikke holdt hvad Herren bød dig. 
9:13:15: Så gjorde Samuel sig rede og drog fra Gilgal op til Gibea i Benjamin, og Saul mønstret de folk han hadde hos sig - omkring seks hundre mann. 
9:13:16: Og Saul og hans sønn Jonatan og de folk som de hadde hos sig, lå i Geba i Benjamin, og filistrene hadde leiret sig ved Mikmas. 
9:13:17: Da drog det en flokk ut av filistrenes leir for å herje; den var delt i tre hoper; den ene hop tok veien til Ofra, til Sual-bygden, 
9:13:18: og den annen hop tok veien til Bet-Horon, og den tredje hop tok veien til den bygd som rager op over Sebo'im-dalen bortimot ørkenen. 
9:13:19: Dengang fantes det ikke en smed i hele Israels land; for filistrene tenkte som så: Hebreerne kunde ellers gjøre sig sverd eller spyd. 
9:13:20: Og hele Israel måtte gå ned til filistrene for å få smidd sine plogjern og sine hakker, sine økser og sine plogskjær, 
9:13:21: og når eggen var sløvet på plogskjærene og hakkene og grepene og øksene, eller for å få en pigg påsatt. 
9:13:22: Således gikk det til at på den dag da striden stod, fantes det ikke sverd eller spyd hos nogen av alle de folk som var med Saul og Jonatan; bare Saul og hans sønn Jonatan hadde disse våben. 
9:13:23: Filistrenes forpost rykket frem til Mikmas-skaret. 
9:14:1: Så hendte det en dag at Jonatan, Sauls sønn, sa til svennen som bar hans våben: Kom, la oss gå over til filistrenes forpost der borte på den andre side! Men han sa ikke noget om det til sin far. 
9:14:2: Saul lå dengang i utkanten av Gibea under granatepletreet ved Migron, og krigsfolket som han hadde hos sig, var omkring seks hundre mann; 
9:14:3: der var også Akia, sønn til Akitub, som var bror til Ikabod, sønn av Pinehas, sønn av Eli, Herrens prest i Silo; han bar dengang livkjortelen. Men folket visste ikke at Jonatan var gått bort. 
9:14:4: Mellem skarene som Jonatan søkte å komme frem igjennem for å nå til filistrenes forpost, var det en bratt klippe på hver side; den ene hette Boses og den andre Sene. 
9:14:5: Den ene klippe reiser sig bratt i nord midt imot Mikmas, den andre i syd midt imot Geba. 
9:14:6: Så sa Jonatan til svennen som bar hans våben: Kom, la oss gå over til disse uomskårnes forpost! Kanskje Herren gjør noget for oss; for intet hindrer Herren fra å frelse, enten det er ved mange eller ved få. 
9:14:7: Hans våbensvenn svarte ham: Gjør alt hvad du har i sinne! Gå du bare! Jeg skal følge dig hvor du vil. 
9:14:8: Da sa Jonatan: Nu går vi over til mennene der, og de vil få se oss. 
9:14:9: Hvis de da sier til oss: Stå stille til vi kommer bort til eder! - så blir vi stående hvor vi er, og går ikke op til dem. 
9:14:10: Men hvis de sier: Kom op til oss! - så går vi op; for da har Herren gitt dem i vår hånd. Dette skal vi ha til tegn. 
9:14:11: Da de nu begge var kommet så langt at de kunde sees fra filistrenes forposter, sa filistrene: Se, der kommer hebreerne ut fra hulene som de har skjult sig i. 
9:14:12: Og mennene på forposten ropte til Jonatan og hans våbensvenn og sa: Kom op til oss, så skal vi si eder noget! Da sa Jonatan til sin våbensvenn: Stig op efter mig, for Herren har gitt dem i Israels hånd. 
9:14:13: Så kløv Jonatan op på hender og føtter, og hans våbensvenn efter ham; og de falt for Jonatan, og hans våbensvenn gikk efter ham og slo dem ihjel. 
9:14:14: Ved det første angrep Jonatan og hans våbensvenn gjorde, falt omkring tyve mann på en strekning som halvparten av den mark som kan pløies på en dag. 
9:14:15: Da blev det redsel i leiren, på marken omkring og blandt alt folket; også forposten og herjeflokken blev grepet av redsel. Jorden skalv, og det kom en redsel fra Gud. 
9:14:16: Og da Sauls vakter i Gibea i Benjamin fikk se at hopen opløste sig, og at de holdt på å støte og trenge hverandre, 
9:14:17: sa Saul til de folk han hadde hos sig: Tell efter og se hvem som er gått fra oss! Så tellet de efter, og det viste sig at Jonatan og hans våbensvenn ikke var der. 
9:14:18: Da sa Saul til Akia: Kom hit med Guds ark! Guds ark var på den tid der blandt Israels barn. 
9:14:19: Men mens Saul talte med presten, blev bulderet i filistrenes leir større og større; da sa Saul til presten: La det være! 
9:14:20: Og Saul og alt folket som han hadde hos sig, samlet sig, og da de kom dit hvor striden stod, fikk de se at den ene hadde løftet sverdet mot den andre, og alt var i ett røre. 
9:14:21: Også de hebreere som nu likesom før var med filistrene, og som hadde draget op med dem og var rundt omkring i deres leir, slo sig sammen med de israelitter som var med Saul og Jonatan. 
9:14:22: Og da de israelitter som hadde skjult sig i Efra'im-fjellene, hørte at filistrene flyktet, satte også de alle efter dem og stred mot dem. 
9:14:23: Således hjalp Herren Israel den dag, og striden drog sig bortover forbi Bet-Aven. 
9:14:24: Mens nu Israels menn var hårdt anstrengt den dag, lot Saul folket sverge og sa: Forbannet være den mann som nyter nogen mat innen aften, før jeg får hevnet mig på mine fiender! Og det var ingen av folket som smakte mat. 
9:14:25: Så kom alt folket inn i skogen; der var det honning på marken, 
9:14:26: og med det samme folket kom inn i skogen, fikk de se en hel strøm av honning; men ingen av folket førte sin hånd til munnen av frykt for eden. 
9:14:27: Men Jonatan hadde ikke hørt på at hans far lot folket sverge; han rakte ut staven han hadde i hånden, og dyppet enden av den i honningen og førte så hånden til munnen igjen; da blev hans øine klare. 
9:14:28: Da tok en mann av folket til orde og sa: Din far har latt folket sverge og sagt: Forbannet være den mann som nyter mat idag! Og således er folket blitt utmattet. 
9:14:29: Jonatan svarte: Min far har ført ulykke over landet; se bare hvor klare mine øine er blitt fordi jeg har smakt litt av denne honning; 
9:14:30: hvor meget større vilde ikke mannefallet blandt filistrene være blitt dersom folket idag hadde fått ete av det hærfang de har tatt fra sine fiender! 
9:14:31: Den dag slo de filistrene og forfulgte dem fra Mikmas til Ajalon, og folket blev meget utmattet 
9:14:32: og tok straks fatt på hærfanget; de tok småfe og storfe og kalver og slaktet dem på marken og åt kjøttet med blodet i. 
9:14:33: Så kom det nogen og fortalte Saul det og sa: Folket synder mot Herren og eter kjøtt med blodet i. Han sa: I har båret eder troløst* at; velt nu en stor sten hit til mig! # <* mot Herren.> 
9:14:34: Så sa Saul: Gå omkring blandt folket og si til dem at hver av dem skal komme hit til mig med sin okse og sitt lam og slakte dem her og så ete, forat de ikke skal synde mot Herren og ete kjøttet med blodet i. Da førte hver mann av folket med egen hånd sin okse frem om natten og slaktet dem der. 
9:14:35: Og Saul bygget et alter for Herren; det var det første alter han bygget for Herren. 
9:14:36: Derefter sa Saul: La oss inatt dra nedover og sette efter filistrene og plyndre blandt dem til morgenen lyser frem, og ikke la en eneste mann blandt dem bli i live. De svarte: Gjør aldeles som du synes! Da sa presten: La oss trede hit frem for Gud! 
9:14:37: Så spurte Saul Gud: Skal jeg dra nedover og sette efter filistrene? Vil du gi dem i Israels hånd? Men han svarte ham ikke den dag. 
9:14:38: Da sa Saul: Tred hit alle høvdinger blandt folket, så I kan få vite og se hvorledes det har sig med den synd som er gjort idag! 
9:14:39: For så sant Herren lever, han som frelser Israel: Om så skylden er hos Jonatan, min sønn, skal han sannelig dø! Men ingen av hele folket svarte ham. 
9:14:40: Så sa han til hele Israel: Stå I på den ene side, så skal jeg og Jonatan, min sønn, stå på den andre side. Folket svarte Saul: Gjør som du synes! 
9:14:41: Da sa Saul til Herren, Israels Gud: La sannheten komme frem! Og loddet falt på Jonatan og Saul, men folket gikk fri. 
9:14:42: Så sa Saul: Kast lodd mellem mig og Jonatan, min sønn! Og loddet falt på Jonatan. 
9:14:43: Da sa Saul til Jonatan: Si mig hvad du har gjort! Jonatan fortalte ham det og sa: Jeg tok litt honning på enden av staven jeg hadde i min hånd, og smakte på den; her står jeg, jeg må dø. 
9:14:44: Da sa Saul: Gud la det gå mig ille både nu og siden om du ikke nu skal dø, Jonatan! 
9:14:45: Men folket sa til Saul: Skulde Jonatan dø, han som har vunnet denne store seier i Israel? Langt derifra! Så sant Herren lever, skal det ikke falle et hår av hans hode til jorden; for med Guds hjelp har han gjort sin gjerning idag. Således fridde folket Jonatan fra døden. 
9:14:46: Men Saul holdt op med å forfølge filistrene og drog hjem, og filistrene drog tilbake til sitt land. 
9:14:47: Da Saul hadde tatt kongedømmet over Israel, førte han krig mot alle sine fiender rundt omkring: mot Moab og mot Ammons barn og mot Edom og mot kongene i Soba og mot filistrene, og overalt hvor han vendte sig hen, seiret han. 
9:14:48: Og han vant sig makt og slo amalekittene og utfridde Israel av deres hånd som plyndret dem. 
9:14:49: Sauls sønner var Jonatan og Jisvi og Malkisua, og av hans to døtre hette den førstefødte Merab og den yngste Mikal. 
9:14:50: Sauls hustru hette Akinoam; hun var datter til Akima'as. Hans hærfører hette Abner; han var sønn til Ner, som var farbror til Saul; 
9:14:51: for Kis, Sauls far, og Ner, Abners far, var sønner av Abiel. 
9:14:52: Krigen mot filistrene var hård alle Sauls dager, og hvor Saul så en sterk og krigsdyktig mann, tok han ham i sin tjeneste. 
9:15:1: Samuel sa til Saul: Det var mig Herren sendte for å salve dig til konge over hans folk, over Israel; så lyd nu Herrens ord! 
9:15:2: Så sier Herren, hærskarenes Gud: Jeg vil hjemsøke Amalek for det han gjorde mot Israel, at han la sig i veien for ham da han drog op fra Egypten. 
9:15:3: Gå nu avsted og slå Amalek, og slå med bann alt hvad hans er; spar ham ikke, men drep både mann og kvinne, både barn og diebarn, både okse og får, både kamel og asen. 
9:15:4: Så kalte Saul folket til våben og mønstret dem i Tela'im; det var to hundre tusen mann fotfolk og ti tusen mann fra Juda. 
9:15:5: Da Saul kom til amalekittenes by, la han et bakhold i dalen. 
9:15:6: Og Saul sa til kenittene: Gå bort, skill eder fra amalekittene og dra ned, så jeg ikke skal ødelegge eder sammen med dem! For I gjorde vel imot alle Israels barn da de drog op fra Egypten. Så skilte kenittene lag med amalekittene. 
9:15:7: Saul slo amalekittene og forfulgte dem fra Havila til bortimot Sur, som ligger østenfor Egypten. 
9:15:8: Og han tok Agag, Amaleks konge, levende til fange, og alt folket slo han med bann og drepte dem med sverdets egg. 
9:15:9: Men Saul og folket sparte Agag og det beste og det næstbeste av småfeet og storfeet og lammene - alt som så godt ut - og vilde ikke slå dem med bann; men alt det fe som var ringe og usselt, det slo de med bann. 
9:15:10: Da kom Herrens ord til Samuel, og det lød således: 
9:15:11: Jeg angrer at jeg har gjort Saul til konge; for han har vendt sig bort fra mig og ikke holdt sig efter mine ord. Da optendtes Samuels vrede, og han ropte til Herren hele natten. 
9:15:12: Morgenen efter stod Samuel tidlig op for å møte Saul; da kom nogen og sa til Samuel: Saul er kommet til Karmel, og der har han reist sig et minnesmerke; siden vendte han om og drog videre ned til Gilgal. 
9:15:13: Da nu Samuel kom til Saul, sa Saul til ham: Velsignet være du av Herren! Jeg har holdt mig efter Herrens ord. 
9:15:14: Men Samuel sa: Hvad er da dette for en bræken av småfe som lyder for mine ører, og hvad er det for brøl av storfe jeg hører? 
9:15:15: Saul svarte: De har ført dem med fra amalekittene; for folket sparte det beste av småfeet og av storfeet for å ofre det til Herren din Gud; men resten har vi slått med bann. 
9:15:16: Da sa Samuel til Saul: Hold op, så vil jeg forkynne dig hvad Herren har talt til mig inatt! Han sa til ham: Tal! 
9:15:17: Samuel sa: Da du var ringe i dine egne øine, blev du hode for Israels stammer, og Herren salvet dig til konge over Israel. 
9:15:18: Og Herren sendte dig avsted og sa: Gå og slå disse syndere, amalekittene, med bann og strid mot dem til du får gjort ende på dem! 
9:15:19: Hvorfor lød du da ikke Herrens ord, men kastet dig over hærfanget og gjorde hvad ondt var i Herrens øine? 
9:15:20: Da sa Saul til Samuel: Jeg har lydt Herrens ord og har gått den vei Herren sendte mig; jeg har ført Agag, Amaleks konge, hit og slått Amalek med bann. 
9:15:21: Men folket tok av hærfanget småfe og storfe, det ypperste av det bannlyste, for å ofre det til Herren din Gud i Gilgal. 
9:15:22: Da sa Samuel: Har vel Herren like så meget behag i brennoffer og slaktoffer som i lydighet mot Herrens ord? Nei, lydighet er bedre enn slaktoffer, hørsomhet bedre enn fettet av værer; 
9:15:23: for gjenstridighet er ikke bedre enn trolldoms-synd, og trossighet er som avgudsdyrkelse. Fordi du har forkastet Herrens ord, har han forkastet dig, så du ikke skal være konge. 
9:15:24: Da sa Saul til Samuel: Jeg har syndet; jeg har overtrådt Herrens bud og dine ord; for jeg var redd folket og gav efter for dem. 
9:15:25: Men forlat mig nu min synd og vend tilbake med mig, forat jeg kan tilbede Herren! 
9:15:26: Samuel svarte Saul: Jeg vil ikke vende tilbake med dig; for du har forkastet Herrens ord, og så har Herren forkastet dig, så du ikke skal være konge over Israel. 
9:15:27: Dermed vendte Samuel sig og vilde gå. Men Saul grep fatt i kanten av hans kappe, så den blev revet av. 
9:15:28: Da sa Samuel til ham: Herren har idag revet kongedømmet over Israel fra dig og gitt det til en annen, som er bedre enn du. 
9:15:29: Og Israels trofaste Gud lyver ikke og angrer ikke; for han er ikke et menneske, så han skulde angre. 
9:15:30: Saul svarte: Jeg har syndet, men vis mig allikevel nu ære for mitt folks eldste og for Israel og vend tilbake med mig! Så vil jeg tilbede Herren din Gud. 
9:15:31: Da fulgte Samuel Saul tilbake, og Saul tilbad Herren. 
9:15:32: Så sa Samuel: Før Agag, Amaleks konge, hit til mig! Glad i hu gikk Agag bort til ham, og Agag sa: Visselig, dødens bitterhet er veket bort. 
9:15:33: Men Samuel sa: Likesom ditt sverd har gjort kvinner barnløse, så skal nu din mor bli barnløs fremfor andre kvinner. Så hugg Samuel Agag ned for Herrens åsyn i Gilgal. 
9:15:34: Derefter drog Samuel til Rama, men Saul fór op til sitt hus i Sauls Gibea. 
9:15:35: Og Samuel så ikke Saul mere helt til sin dødsdag, for Samuel sørget over Saul. Men Herren angret at han hadde gjort Saul til konge over Israel. 
9:16:1: Herren sa til Samuel: Hvor lenge vil du sørge over Saul, enda jeg har forkastet ham, så han ikke skal være konge over Israel? Fyll ditt horn med olje og gå avsted! Jeg sender dig til betlehemitten Isai, for blandt hans sønner har jeg utsett mig en til konge. 
9:16:2: Men Samuel sa: Hvorledes kan jeg gjøre slik en ferd? Får Saul høre det, slår han mig ihjel. Da sa Herren: Ta med dig en kalv av buskapen og si: Jeg er kommet for å ofre til Herren. 
9:16:3: Og innbyd Isai til ofringen, så vil jeg la dig få vite hvad du skal gjøre, og du skal salve for mig den jeg sier dig. 
9:16:4: Samuel gjorde som Herren sa. Da han kom til Betlehem, gikk byens eldste ham skjelvende i møte og sa: Kommer du med fred? 
9:16:5: Han svarte: Ja, jeg kommer med fred; jeg er kommet for å ofre til Herren; hellige eder og kom med mig til ofringen! Og han lot Isai og hans sønner hellige sig og innbød dem til ofringen. 
9:16:6: Da de kom, og han fikk se Eliab, tenkte han: Visselig står her for Herren hans salvede. 
9:16:7: Men Herren sa til Samuel: Se ikke på hans utseende og på hans høie vekst! For jeg har forkastet ham; jeg ser ikke på hvad mennesket ser på, for mennesket ser på det utvortes, men Herren ser på hjertet. 
9:16:8: Så kalte Isai på Abinadab og lot ham gå frem for Samuel; men han sa: Heller ikke ham har Herren utvalgt. 
9:16:9: Så lot Isai Samma gå frem; men han sa: Heller ikke ham har Herren utvalgt. 
9:16:10: Således lot Isai syv sønner gå frem for Samuel; men Samuel sa til Isai: Herren har ikke utvalgt nogen av disse. 
9:16:11: Og Samuel sa til Isai: Var dette alle de gutter du har? Han svarte: Ennu er den yngste tilbake; han vokter småfeet. Da sa Samuel til Isai: Send bud efter ham! Vi setter oss ikke til bords før han kommer. 
9:16:12: Så sendte han bud efter ham. Han var rødkinnet og hadde vakre øine og var fager av utseende. Da sa Herren: Stå op og salv ham! For han er det. 
9:16:13: Da tok Samuel oljehornet og salvet ham midt iblandt hans brødre, og Herrens Ånd kom over David fra den dag og fremdeles. Så brøt Samuel op og gikk til Rama. 
9:16:14: Men Herrens Ånd vek fra Saul, og en ond ånd fra Herren forferdet ham. 
9:16:15: Da sa Sauls tjenere til ham: Vi ser at en ond ånd fra Gud forferder dig. 
9:16:16: Herre, byd bare dine tjenere som står for ditt ansikt, å opsøke en mann som er kyndig i å spille på harpe. Når da en ond ånd fra Gud kommer over dig, skal han spille på sin harpe; da vil det bli bedre med dig. 
9:16:17: Da sa Saul til sine tjenere: Ja, finn mig en mann som spiller godt, og før ham hit til mig! 
9:16:18: En av de unge menn svarte og sa: Jeg har sett en sønn av betlehemitten Isai som er kyndig i å spille, en djerv mann og en stridsmann, god til å tale for sig og en vakker mann, og Herren er med ham. 
9:16:19: Da sendte Saul bud til Isai og lot si: Send hit til mig din sønn David, han som er med småfeet! 
9:16:20: Så tok Isai et asen, som han lesste brød på, og en skinnsekk med vin og et kje og sendte det med sin sønn David til Saul. 
9:16:21: Således kom David til Saul, og han blev i hans tjeneste; og Saul fikk ham meget kjær, og han blev hans våbensvenn. 
9:16:22: Og Saul sendte bud til Isai og lot si: Kjære, la David bli i min tjeneste! For han har funnet nåde for mine øine. 
9:16:23: Når da den onde ånd fra Gud kom over Saul, tok David harpen og spilte på den, og Saul fikk lindring, og det blev bedre med ham, og den onde ånd vek fra ham. 
9:17:1: Filistrene samlet sine hærer til strid og kom sammen i Soko, som hører til Juda; og de slo leir mellem Soko og Aseka ved Efes-Dammim. 
9:17:2: Og Saul og Israels menn samlet sig og; de leiret sig i Terebintedalen og fylket sig mot filistrene. 
9:17:3: Filistrene stod ved fjellet på den ene side, og israelittene ved fjellet på den andre side, så de hadde dalen imellem sig. 
9:17:4: Da gikk en tvekjemper ut av filistrenes leir; han hette Goliat og var fra Gat; han var seks alen og et spann høi. 
9:17:5: På hodet hadde han en kobberhjelm og var klædd i en skjellbrynje, og brynjens vekt var fem tusen sekel kobber. 
9:17:6: Han hadde kobberskinner på leggene og et kastespyd av kobber på ryggen. 
9:17:7: Skaftet på hans spyd var som en veverstang, og odden på hans spyd veide seks hundre sekel jern, og hans skjoldbærer gikk foran ham. 
9:17:8: Han stod frem og ropte til Israels fylking og sa til dem: Hvorfor drar I ut og fylker eder til strid? Er ikke jeg filisteren og I Sauls træler? Velg eder en mann og la ham komme ned til mig! 
9:17:9: Hvis han kan stride med mig og feller mig, så skal vi være eders træler; men hvis jeg får bukt med ham og feller ham, så skal I være våre træler og tjene oss. 
9:17:10: Så sa filisteren: Idag har jeg hånet Israels fylking; kom hit med en mann, så vi kan stride med hverandre! 
9:17:11: Da Saul og hele Israel hørte disse filisterens ord, blev de forferdet og fryktet storlig. 
9:17:12: David var en sønn av den før omtalte efratitt fra Betlehem i Juda som hette Isai, og som hadde åtte sønner. Denne mann var i Sauls dager gammel og avfeldig. 
9:17:13: Isais tre eldste sønner hadde draget ut og fulgt Saul i krigen; og av disse hans tre sønner som hadde draget i krigen, hette den førstefødte Eliab, den annen Abinadab og den tredje Samma. 
9:17:14: David var den yngste. De tre eldste hadde fulgt Saul, 
9:17:15: men David gikk stundom hjem igjen fra Saul for å vokte sin fars småfe i Betlehem. 
9:17:16: Filisteren gikk frem både morgen og aften; i firti dager stilte han sig frem. 
9:17:17: Så sa Isai til sin sønn David: Kjære, ta en efa av dette ristede korn og disse ti brød til dine brødre og skynd dig til leiren med det, til dine brødre! 
9:17:18: Men disse ti skiver fersk ost skal du ha med til den øverste høvedsmann; så skal du se til dine brødre om det går dem vel, og ta med et pant fra dem. 
9:17:19: Saul er med dem og alle Israels menn i Terebinte-dalen og strider med filistrene. 
9:17:20: Morgenen efter stod David tidlig op og overlot småfeet til en gjæter; så tok han det han skulde ha med, og gikk avsted, som Isai hadde befalt ham. Da han kom til vognborgen, drog hæren nettop ut for å fylke sig til strid og opløftet krigsropet. 
9:17:21: Og Israel og filistrene stilte sig op, fylking mot fylking. 
9:17:22: David la de ting han hadde med sig ned hos den som hadde tilsyn med trosset, og sprang frem til fylkingen, og da han kom dit, hilste han på sine brødre. 
9:17:23: Mens han talte med dem, kom tvekjemperen, filisteren Goliat fra Gat, nettop frem fra filistrenes fylking og ropte de samme ord som før, og David hørte det. 
9:17:24: Og alle Israels menn flyktet for mannen da de så ham, og var meget redde. 
9:17:25: Og en mann av Israel sa: Ser I den mann som kommer frem der? Han kommer for å håne Israel; den mann som feller ham, ham vil kongen gi stor rikdom og la ham få sin datter og gjøre hans fars hus skattefritt i Israel. 
9:17:26: Da sa David til de menn som stod tett ved ham: Hvad skal han få den mann som feller denne filister og tar skammen bort fra Israel? For hvem er denne uomskårne filister, at han har hånet den levende Guds hær? 
9:17:27: Og folket gjentok for ham de samme ord* og sa: Det og det skal den mann få som feller ham. # <* 1SA 17, 25.> 
9:17:28: Eliab, hans eldste bror, hørte hvad han talte med mennene om; da optendtes hans vrede mot David, og han sa: Hvorfor er du kommet her ned, og til hvem har du overlatt de to-tre får der i ørkenen? Jeg kjenner ditt overmot og ditt hjertes ondskap; det er for å se på striden du er kommet her ned. 
9:17:29: Men David sa: Hvad har jeg gjort nu? Det var jo bare et ord. 
9:17:30: Så vendte han sig fra ham til en annen og spurte om det samme*; og folket svarte ham som første gang. # <* 1SA 17, 26.> 
9:17:31: Det kom ut hvad David hadde sagt, og Saul fikk og høre det. Han lot ham hente, 
9:17:32: og David sa til Saul: Ingen må tape motet! Din tjener vil gå og stride med denne filister. 
9:17:33: Men Saul sa til David: Du kan ikke gå mot denne filister og stride med ham; for du er ung, og han er en krigsmann fra sin ungdom av. 
9:17:34: Men David svarte Saul: Din tjener voktet småfeet for sin far; kom det da en løve eller en bjørn og tok et får av hjorden, 
9:17:35: sprang jeg efter den og slo den og rev fåret ut av gapet på den; og reiste den sig så imot mig, tok jeg fatt i dens skjegg og slo den og drepte den. 
9:17:36: Både løve og bjørn har din tjener slått ihjel, og det skal gå denne uomskårne filister som dem, fordi han har hånet den levende Guds hær. 
9:17:37: Så sa David: Herren, som har fridd mig av løvens og bjørnens vold, han skal også fri mig av denne filisters vold. Da sa Saul til David: Gå! Herren skal være med dig. 
9:17:38: Og Saul lot David få sine egne klær og satte en kobberhjelm på hans hode og hadde på ham en brynje. 
9:17:39: Og David bandt hans sverd om sig utenpå sine klær og vilde til å gå; for han hadde ikke prøvd det før. Og David sa til Saul: Jeg kan ikke gå med dette, for jeg har ikke prøvd det før. Så tok David alt dette av sig 
9:17:40: og tok sin stav i hånden og søkte sig ut fem glatte stener i bekken; dem la han i hyrdetasken som han hadde med sig, og sin slynge hadde han i hånden; og så gikk han frem mot filisteren. 
9:17:41: Og filisteren kom nærmere og nærmere bortimot David, og hans skjoldbærer gikk foran ham. 
9:17:42: Da filisteren så frem for sig og blev var David, foraktet han ham, fordi han var ung og rødkinnet og fager å se til. 
9:17:43: Og filisteren sa til David: Er jeg en hund, siden du kommer mot mig med kjepper? Og filisteren bante David ved sin gud. 
9:17:44: Så sa filisteren til David: Kom hit til mig, så skal jeg gi ditt kjøtt til himmelens fugler og markens dyr. 
9:17:45: David svarte filisteren: Du kommer mot mig med sverd og lanse og kastespyd; men jeg kommer mot dig i Herrens, hærskarenes Guds navn, han som er Gud for Israels fylkinger - han som du har hånet. 
9:17:46: På denne dag skal Herren gi dig i min hånd, og jeg skal slå dig ihjel og skille ditt hode fra din kropp, og jeg skal på denne dag gi likene fra filistrenes leir til himmelens fugler og til jordens ville dyr, og all jorden skal få se at Israel har en Gud. 
9:17:47: Og hele dette folk skal få se at det ikke er ved sverd og spyd Herren frelser; for Herren råder for krigen, og han skal gi eder i var hånd. 
9:17:48: Da nu filisteren gjorde sig rede og gikk frem og kom nærmere bortimot David, skyndte David sig og sprang frem mot fylkingen, filisteren i møte. 
9:17:49: Og David stakk sin hånd i tasken og tok frem av den en sten; den slynget han ut og traff filisteren i pannen, og stenen trengte dypt inn i hans panne, og han falt på sitt ansikt til jorden. 
9:17:50: Således vant David over filisteren med slyngen og stenen, og han slo filisteren og drepte ham, enda David ikke hadde noget sverd i sin hånd. 
9:17:51: Og David sprang frem og stilte sig tett ved filisteren og tok og drog hans sverd ut av skjeden og drepte ham og hugg hans hode av med det. Og da filistrene så at deres kjempe var død, tok de flukten. 
9:17:52: Da gikk Israels og Judas menn frem, opløftet krigsropet og forfulgte filistrene til bortimot Gat og like til Ekrons porter; og det lå falne filistrer på veien til Sa'ara'im og helt frem til Gat og til Ekron. 
9:17:53: Så forfulgte ikke Israels barn filistrene lenger, men vendte tilbake og plyndret deres leir. 
9:17:54: Og David tok filisterens hode og hadde det med sig til Jerusalem; men hans våben la han i sitt telt. 
9:17:55: Da Saul så David gå ut imot filisteren, sa han til Abner, hærføreren: Hvem er denne gutt sønn til, Abner? Abner svarte: Så sant du lever, konge, jeg vet det ikke. 
9:17:56: Da sa kongen: Spør efter hvem denne gutt er sønn til. 
9:17:57: Da så David vendte tilbake efterat han hadde slått filisteren ihjel, tok Abner ham og førte ham frem for Saul; han hadde ennu filisterens hode i sin hånd. 
9:17:58: Og Saul sa til ham: Hvem er du sønn til, unge mann? David svarte: Jeg er sønn til din tjener Isai fra Betlehem. 
9:18:1: Efter hans samtale med Saul droges Jonatans sjel til David, og Jonatan fikk ham kjær som sitt eget liv. 
9:18:2: Samme dag tok Saul ham til sig og lot ham ikke mere få vende tilbake til sin fars hus. 
9:18:3: Og Jonatan gjorde en pakt med David, fordi han hadde ham kjær som sitt eget liv. 
9:18:4: Og Jonatan tok av sig den kappe han hadde på, og gav den til David og likeså sine krigsklær, ja endog sitt sverd og sin bue og sitt belte. 
9:18:5: Så drog David ut i krigen; i alt det Saul sendte ham til, bar han sig klokt at; Saul satte ham over sine krigsmenn, og han var vel likt av hele folket, også av Sauls tjenere. 
9:18:6: Så hendte det da de kom hjem, da David vendte tilbake efterat han hadde slått filisteren, at kvinnene gikk ut fra alle Israels byer for å møte kong Saul med sang og dans, med trommer og med gledesrop og med musikk. 
9:18:7: Og de dansende kvinner istemte en sang og sa: Saul har slått sine tusener, men David sine titusener. 
9:18:8: Da blev Saul meget vred; for dette ord likte han ille, og han sa: De har gitt David titusener, og mig har de gitt tusener, og nu står det bare tilbake at han får kongedømmet. 
9:18:9: Fra den dag så Saul stadig skjevt til David. 
9:18:10: Dagen efter kom det en ond ånd fra Gud over Saul, og han raste inne i sitt hus, mens David spilte på harpen, som han pleide å gjøre hver dag, og Saul hadde et spyd i hånden. 
9:18:11: Da svang Saul spydet og tenkte: Jeg vil støte det gjennem David og inn i veggen. Men David bøide sig til side for ham to ganger. 
9:18:12: Saul var redd for David; for Herren var med ham, men fra Saul var han veket bort. 
9:18:13: Derfor sendte Saul ham fra sig og satte ham til høvedsmann over tusen; og han drog ut og drog inn foran folket. 
9:18:14: Og David bar sig klokt at i alt han tok sig fore, og Herren var med ham. 
9:18:15: Da nu Saul så at han bar sig meget klokt at, grudde han for ham. 
9:18:16: Men hele Israel og Juda hadde David kjær; for det var han som drog ut og drog inn foran dem. 
9:18:17: Og Saul sa til David: Her er min eldste datter Merab; henne vil jeg gi dig til hustru; la mig bare se at du er en djerv stridsmann og fører Herrens kriger! For Saul tenkte: Min hånd skal ikke ramme ham, men filistrenes hånd skal ramme ham. 
9:18:18: David svarte Saul: Hvem er jeg, og hvem er mine frender, min fars ætt i Israel, at jeg skulde bli kongens svigersønn? 
9:18:19: Men da tiden kom at Merab, Sauls datter, skulde gis til David, blev hun gitt Adriel fra Mehola til hustru. 
9:18:20: Mikal, Sauls datter, elsket David. Da Saul fikk vite dette, syntes han godt om det. 
9:18:21: Saul tenkte: Jeg vil la ham få henne, så hun kan bli til en snare for ham, og filistrenes hånd kan ramme ham. Og Saul sa til David: Ved min annen datter skal du idag bli min svigersønn. 
9:18:22: Og Saul bød sine tjenere: Tal hemmelig med David og si: Kongen liker dig, og alle hans tjenere har dig kjær; bli derfor nu kongens svigersønn! 
9:18:23: Da Sauls tjener sa disse ord til David, svarte han: Synes I det er en ringe ting å bli kongens svigersønn? Jeg er jo en fattig og ringe mann. 
9:18:24: Sauls tjenere fortalte ham dette og sa: Således sa David. 
9:18:25: Da sa Saul: Så skal I si til David: Kongen krever ingen annen festegave enn hundre filister-forhuder, så han kan få hevnet sig på kongens fiender. Men Saul tenkte å felle David ved filistrenes hånd. 
9:18:26: Da hans tjenere fortalte David dette, var David glad over at han skulde bli kongens svigersønn. Og før tiden ennu var ute, 
9:18:27: gjorde David sig rede og drog avsted med sine menn og slo ihjel to hundre mann blandt filistrene. Og David tok deres forhuder med sig og la dem fulltallig frem for kongen, så han kunne bli kongens svigersønn. Da gav Saul ham sin datter Mikal til hustru. 
9:18:28: Nu så Saul og forstod at Herren var med David; og Mikal, Sauls datter, elsket ham. 
9:18:29: Da blev Saul ennu mere redd David, og Saul var Davids fiende all sin tid. 
9:18:30: Så drog filistrenes høvdinger ut i krigen, og så ofte de drog ut, bar David sig klokere at enn alle Sauls tjenere, og han fikk et stort navn. 
9:19:1: Saul talte til Jonatan, sin sønn, og til alle sine tjenere om å drepe David; men Jonatan, Sauls sønn, hadde David meget kjær. 
9:19:2: Og Jonatan fortalte det til David og sa: Saul, min far, søker å drepe dig; ta dig nu i vare imorgen tidlig og bli i skjul og hold dig gjemt! 
9:19:3: Så vil jeg gå ut og stå ved siden av min far på marken hvor du er, og jeg vil tale om dig til min far, og merker jeg noget, så skal jeg si dig det. 
9:19:4: Og Jonatan talte vel om David til Saul, sin far, og sa til ham: Kongen skulde ikke gjøre urett mot sin tjener David! For han har ikke syndet mot dig, og det han har gjort, har vært til stor nytte for dig; 
9:19:5: han satte sitt liv på spill og slo filisteren, og Herren lot hele Israel vinne en stor seier; du så det selv og gledet dig; hvorfor vil du da gjøre synd mot uskyldig blod og drepe David uten grunn? 
9:19:6: Saul hørte på Jonatans ord, og Saul svor: Så sant Herren lever: Han skal ikke drepes! 
9:19:7: Da kalte Jonatan på David og fortalte ham alt dette; og Jonatan førte David til Saul, og han var hos ham som før. 
9:19:8: Men krigen varte ved; og David drog ut og stred mot filistrene og påførte dem et stort mannefall, og de flyktet for ham. 
9:19:9: Og en ond ånd fra Herren kom over Saul mens han satt i sitt hus med sitt spyd i hånden, og David spilte på harpen. 
9:19:10: Da søkte Saul å støte spydet gjennem David og inn i veggen; men han vek til side for Saul, så han støtte spydet i veggen. Samme natt flyktet David og slapp bort. 
9:19:11: Og Saul sendte bud til Davids hus for å vokte på ham og drepe ham om morgenen; men Mikal, Davids hustru, varslet ham om det og sa: Berger du ikke ditt liv inatt, så blir du drept imorgen. 
9:19:12: Og Mikal firte David ned gjennem vinduet, og han flyktet sin vei og slapp bort. 
9:19:13: Så tok Mikal husguden og la den i sengen, og hun la et teppe av gjetehår ved dens hode og dekket den til med et klæde; 
9:19:14: og da Saul sendte bud for å hente David, sa hun: Han er syk. 
9:19:15: Så sendte Saul budene avsted for å få se David og sa: Bær ham hit til mig i sengen, forat han kan bli drept! 
9:19:16: Men da budene kom inn, fikk de se at det var husguden som lå i sengen med gjetehårsteppet ved hodet. 
9:19:17: Da sa Saul til Mikal: Hvorfor har du narret mig således og latt min fiende slippe bort, så han er kommet unda? Mikal svarte Saul: Han sa til mig: La mig slippe bort, ellers slår jeg dig ihjel. 
9:19:18: Da David var flyktet og hadde sloppet bort, kom han til Samuel i Rama og fortalte ham alt hvad Saul hadde gjort mot ham, og han og Samuel tok avsted til Nevajot og bodde der. 
9:19:19: Da Saul fikk spurt at David var i Nevajot ved Rama, 
9:19:20: sendte han nogen menn for å hente David, og da de fikk se profetenes skare, som var i profetisk henrykkelse, og Samuel stående der som deres fører, da kom Guds Ånd over Sauls sendebud, så de og blev grepet av profetisk henrykkelse. 
9:19:21: Da Saul fikk vite dette, sendte han andre bud; men de blev og grepet av profetisk henrykkelse. Så sendte Saul bud igjen for tredje gang; men også de blev grepet av profetisk henrykkelse. 
9:19:22: Da gikk han selv til Rama, og da han kom til den store brønn i Seku, spurte han: Hvor er Samuel og David? De svarte: De er i Nevajot ved Rama. 
9:19:23: Da han så gikk dit, til Nevajot ved Rama, kom Guds Ånd også over ham, så at han hele veien gikk i profetisk henrykkelse til han kom til Nevajot ved Rama. 
9:19:24: Da rev han og klærne av sig; også han var i profetisk henrykkelse mens han stod foran Samuel, og han falt ned og lå der naken* hele den dag og hele natten; derfor sier folk: Er også Saul blandt profetene? # <* d.e. uten overkjortel, JES 20, 2.> 
9:20:1: Men David flyktet fra Nevajot ved Rama og kom og sa åpent og likefrem til Jonatan: Hvad har jeg gjort, hvad er min misgjerning, og hvad er min synd mot din far, siden han står mig efter livet? 
9:20:2: Da sa han til ham: Langt derifra! Du skal ikke dø; du vet min far gjør intet, hverken stort eller smått, uten at han åpenbarer det for mig; hvorfor skulde da min far dølge dette for mig? Nei, det er ikke så. 
9:20:3: Men David svarte med en ed: Din far vet meget vel at jeg har funnet nåde for dine øine, og derfor tenker han som så: Jonatan må ikke få vite dette, forat han ikke skal bli bedrøvet. Men så sant Herren lever, og så sant du lever: Det er bare et skritt mellem mig og døden. 
9:20:4: Da sa Jonatan til David: Alt hvad du ønsker, vil jeg gjøre for dig. 
9:20:5: David svarte Jonatan: Imorgen er det nymåne-dagen; da skulde jeg jo sitte til bords med kongen; men la mig nu få gå, så vil jeg skjule mig ute på marken til iovermorgen aften. 
9:20:6: Dersom da din far savner mig, så skal du si: David bad mig inntrengende om han måtte få gå hjem til Betlehem i all hast; for hele ætten holder nu sin årlige ofring der. 
9:20:7: Dersom han da sier så: Det er godt - da kan din tjener være trygg; men dersom hans vrede optendes, så kan du være viss på at det er hans faste forsett å gjøre noget ondt. 
9:20:8: Vis da godhet mot din tjener, siden du har latt din tjener inntrede i vennskapspakt med dig i Herrens navn! Men er det nogen misgjerning hos mig, så drep du mig! Hvorfor skulde du vel føre mig til din far? 
9:20:9: Jonatan svarte: Nei, det skal aldri hende dig! Men skjønner jeg at det er et fast forsett hos min far å la noget ondt hende dig, skulde jeg da ikke la dig få vite det? 
9:20:10: Da sa David til Jonatan: Hvem skal varsle mig om det eller si mig om din far svarer dig ublidt? 
9:20:11: Og Jonatan sa til David: Kom, la oss gå ut på marken! Så gikk de begge ut på marken. 
9:20:12: Og Jonatan sa til David: Ved Herren, Israels Gud: Når jeg utforsker min far imorgen eller iovermorgen på denne tid og ser han er vel sinnet mot David, og jeg så ikke sender bud til dig og åpenbarer dig det, 
9:20:13: så må Herren la det gå Jonatan ille både nu og siden! Vil min far føre ondt over dig, så skal jeg åpenbare det for dig og la dig slippe bort, så du kan dra din vei i fred. Og Herren være med dig, som han har vært med min far! 
9:20:14: Og vil du ikke, hvis jeg enda* er i live - vil du ikke da gjøre Herrens miskunnhet mot mig og ikke la mig dø? # <* nemlig når du er blitt konge.> 
9:20:15: Og aldri nogensinne vil du ta bort din godhet fra mitt hus, ikke engang når Herren gjør ende på Davids fiender og utrydder dem alle som én av jorden. 
9:20:16: Så gjorde Jonatan en pakt med Davids hus og sa: Herren ta hevn over Davids fiender! 
9:20:17: Og Jonatan besvor atter David ved sin kjærlighet til ham; for han hadde ham kjær som sitt eget liv. 
9:20:18: Så sa Jonatan til ham: Imorgen er det nymåne-dagen; da vil du bli savnet når din plass er tom. 
9:20:19: Men gå iovermorgen skyndsomt ned til det sted hvor du holdt dig skjult den dag ugjerningen skulde vært utført*, og sett dig ved Ezelstenen. # <* 1SA 19, 2. 3.> 
9:20:20: Da skal jeg skyte tre piler bortover mot den som om jeg skjøt til måls. 
9:20:21: Så skal jeg sende min tjener der bort og si: Gå og finn pilene! Sier jeg da til tjeneren: Se, pilene ligger hitenfor dig, ta dem - så kom! For da kan du være trygg, og der er ingen fare, så sant Herren lever. 
9:20:22: Men sier jeg til den unge mann: Se, pilene ligger bortenfor dig - så gå! For da vil Herren at du skal dra bort. 
9:20:23: Og vedkommende det vi har talt om, jeg og du, så er Herren vidne mellem mig og dig til evig tid. 
9:20:24: Så skjulte David sig ute på marken. Da det blev nymåne, satte kongen sig til bords for å holde måltid. 
9:20:25: Og kongen satt på sin vanlige plass, på plassen ved veggen, og Jonatan stod op, og Abner satte sig ved siden av Saul; men Davids plass var tom. 
9:20:26: Saul sa ikke noget den dag; for han tenkte: Det har hendt ham noget, så han ikke er ren* - nei, han er ikke ren. # <* 3MO 15, 16 fg.> 
9:20:27: Men da Davids plass også den næste dag - dagen efter nymåne - var tom, sa Saul til Jonatan, sin sønn: Hvorfor er Isais sønn hverken igår eller idag kommet til måltidet? 
9:20:28: Jonatan svarte Saul: David; bad mig inntrengende om han måtte få gå til Betlehem; 
9:20:29: han sa: Kjære, la mig få gå! For vår ætt holder en offerhøitid i vår by, og min bror har selv pålagt mig å komme; har jeg funnet nåde for dine øine, så gi mig lov til å ta bort og hilse på mine brødre! Derfor er han ikke kommet til kongens bord. 
9:20:30: Da optendtes Sauls vrede mot Jonatan, og han sa til ham: Du sønn av en forvendt og gjenstridig kvinne! Vet jeg ikke at du har Isais sønn kjær til skam for dig selv og til skam for din mors blusel? 
9:20:31: For så lenge Isais sønn er til på jorden, er hverken du eller din kongemakt trygg. Send derfor bud og hent ham hit til mig, for han er dødsens. 
9:20:32: Jonatan svarte Saul, sin far, og sa til ham: Hvorfor skal han dø? Hvad har han gjort? 
9:20:33: Men Saul slynget spydet mot ham for å felle ham; da skjønte Jonatan at det var hans fars faste forsett å drepe David. 
9:20:34: Og Jonatan stod op fra bordet i brennende vrede, og han smakte ikke mat den annen nymåne-dag; for han var bedrøvet for Davids skyld, fordi hans far hadde hånet ham. 
9:20:35: Morgenen efter gikk Jonatan ut på marken på den tid han hadde avtalt med David, og han hadde en liten gutt med sig. 
9:20:36: Og han sa til gutten: Spring og finn pilene som jeg skyter ut! Gutten sprang avsted, og han skjøt pilen bort over ham. 
9:20:37: Og da gutten kom til det sted hvor pilen lå, den som Jonatan hadde skutt, ropte Jonatan til gutten: Ligger ikke pilen bortenfor dig? 
9:20:38: Så ropte Jonatan ennu en gang til gutten: Vær snar, skynd dig, stans ikke! Og Jonatans gutt samlet pilene op og kom til sin herre. 
9:20:39: Men gutten visste ikke om noget; det var bare Jonatan og David som visste hvad det gjaldt. 
9:20:40: Så gav Jonatan sine våben til gutten og sa til ham: Gå og ta dem med dig til byen! 
9:20:41: Da gutten var kommet dit, kom David frem på sydsiden [av stenen*], og han falt på sitt ansikt til jorden og bøide sig tre ganger; og de kysset hverandre og gråt sammen, og David storgråt. # <* sml. 1SA 20, 19.> 
9:20:42: Og Jonatan sa til David: Gå bort i fred! Hvad vi to har svoret i Herrens navn da vi sa: Herren være vidne mellem mig og dig og mellem min ætt og din ætt til evig tid [det skal stå fast]. 
9:20:43: Så brøt han op og gikk sin vei; men Jonatan gikk inn i byen. 
9:21:1: David kom til presten Akimelek i Nob, og Akimelek kom David forferdet i møte og sa til ham: Hvorfor er du alene og har ingen med dig? 
9:21:2: David svarte presten Akimelek: Kongen har gitt mig et ærend å utføre; han sa til mig: Ingen må få vite noget om det ærend jeg sender dig i, og som jeg har gitt dig å utføre. Og mine menn har jeg satt stevne på det og det sted. 
9:21:3: Men hvad har du nu ved hånden? La mig få fem brød eller hvad der finnes! 
9:21:4: Presten svarte David og sa: Jeg har intet almindelig brød ved hånden, men hellig brød er her - bare mennene har holdt sig fra kvinner. 
9:21:5: David svarte presten og sa til ham: Ja visst, kvinner har vi ikke hatt leilighet til å være sammen med, hverken igår eller iforgårs; da jeg drog ut, var alt det mine menn hadde på sig hellig; og vel er dette en ferd som ikke er hellig, men idag blir den hellig ved det de har på sig. 
9:21:6: Da gav presten ham hellig brød, for det var ikke annet brød der enn skuebrødene, som var tatt bort fra Herrens åsyn, forat ferskt brød kunde legges isteden samme dag som de blev tatt bort. 
9:21:7: Men den dag var det en av Sauls tjenere som holdt sig inne der for Herrens åsyn; han hette Doeg og var fra Edom; han var den øverste av Sauls hyrder. 
9:21:8: David sa til Akimelek: Har du ikke et spyd eller et sverd her ved hånden? For jeg har hverken tatt mitt sverd eller mine andre våben med mig; for det hastet med kongens ærend. 
9:21:9: Presten svarte: Filisteren Goliats sverd, han som du slo ihjel i Terebinte-dalen, det henger innsvøpt i et klæde bak livkjortelen; vil du ta det til dig, så ta det! Det er ikke noget annet sverd her. David sa: Det finnes ikke maken til det; gi mig det! 
9:21:10: Så brøt David op og flyktet samme dag for Saul; og han kom til Akis, kongen i Gat. 
9:21:11: Og Akis' tjener sa til ham: Er ikke dette David, landets konge? Er det ikke ham de sang om under dansen: Saul har slått sine tusener, men David sine titusener? 
9:21:12: David la sig disse ord på hjerte og var svært redd Akis, kongen i Gat. 
9:21:13: Derfor lot han for dem som han var vanvittig, og han raste mellem hendene på dem og risset på dørene i porten og lot sitt spytt flyte ned i skjegget. 
9:21:14: Da sa Akis til sine tjenere: I ser jo det er en gal mann; hvorfor kommer I til mig med ham? 
9:21:15: Har jeg ikke nok av gale folk siden I er kommet hit med ham der, så han skal plage mig med sin galskap? Skulde slik en få komme i mitt hus? 
9:22:1: Så drog David bort derfra og flyktet til hulen ved Adullam; og da hans brødre og hele hans fars hus fikk høre det, drog de der ned til ham. 
9:22:2: Og alle som var i nød, eller som var trykket av gjeld, eller som var misfornøide, samlet sig om ham, og han blev deres høvding; det var omkring fire hundre mann som var med ham. 
9:22:3: Derfra drog David til Mispe i Moab; og han sa til Moabs konge: La min far og min mor få komme hit og bli hos eder til jeg får se hvad Gud vil gjøre med mig! 
9:22:4: Og han førte dem frem for Moabs konge; og de blev hos ham hele den tid David var i fjellborgen. 
9:22:5: Men profeten Gad sa til David: Du skal ikke bli i fjellborgen; ta herfra og dra til Juda land! Da drog David bort og kom til Haret-skogen. 
9:22:6: Saul fikk høre at David og de menn som var med ham, var blitt kjent, og en dag Saul satt i Gibea under tamarisken på haugen med sitt spyd i hånden, og alle hans tjenere stod omkring ham, 
9:22:7: sa han til sine tjenere, de som stod omkring ham: Hør nu, i benjaminitter! Vil da Isais sønn og gi eder alle sammen akrer og vingårder og sette eder alle til høvedsmenn over tusen og over hundre? 
9:22:8: For I har jo alle sammensvoret eder mot mig, og ingen åpenbarte det for mig dengang min sønn gjorde pakt med Isais sønn, og ingen av eder er bekymret for mig og åpenbarer noget for mig; for min sønn har opegget mine tjenere til å efterstrebe mig, således som han nu gjør. 
9:22:9: Da svarte Doeg, edomitten, som var satt over Sauls tjenere, og sa: Jeg så Isais sønn kom til Akimelek, Akitubs sønn, i Nob. 
9:22:10: Og Akimelek adspurte Herren for ham og gav ham reisekost og lot ham få filisteren Goliats sverd. 
9:22:11: Da sendte kongen bud efter presten Akimelek, Akitubs sønn, og hele hans fars hus, prestene i Nob; og de kom alle sammen til kongen. 
9:22:12: Og Saul sa: Hør nu, Akitubs sønn! Han svarte: Ja, her er jeg, herre! 
9:22:13: Da sa Saul til ham: Hvorfor har I sammensvoret eder mot mig, du og Isais sønn? Hvorfor gav du ham brød og sverd og adspurte Gud for ham, så han skulde sette sig op imot mig og efterstrebe mig, som han nu gjør? 
9:22:14: Da svarte Akimelek kongen og sa: Hvem blandt alle dine tjenere er vel så betrodd som David, han som er kongens svigersønn og har fortrolig adgang til dig og er høit æret i ditt hus? 
9:22:15: Den dag var det da ikke første gang jeg adspurte Gud for ham. Det være langt fra mig å gjøre sådant! Kongen må ikke legge sin tjener eller hele min fars hus noget til last! For din tjener har ikke visst det minste grand om alt dette. 
9:22:16: Men kongen sa: Du skal dø, Akimelek, du og hele din fars hus. 
9:22:17: Og kongen sa til drabantene som stod omkring ham: Gå frem og drep Herrens prester! For de har og hjulpet David; de visste at han var på flukt, men åpenbarte det ikke for mig. Men kongens tjenere vilde ikke rekke ut sin hånd for å hugge ned Herrens prester. 
9:22:18: Da sa kongen til Doeg: Gå du frem og hugg ned prestene! Og Doeg, edomitten, gikk frem og hugg ned prestene og han drepte den dag fem og åtti menn som bar lerretslivkjortler. 
9:22:19: Og prestebyen Nob slo han med sverdets egg; både mann og kvinne, både barn og diebarn, både okse og asen og får slo han med sverdets egg. 
9:22:20: Bare en sønn av Akimelek, Akitubs sønn, slapp bort; hans navn var Abjatar. Han flyktet til David og fulgte ham. 
9:22:21: Og Abjatar fortalte David at Saul hadde drept Herrens prester. 
9:22:22: Da sa David til Abjatar: Jeg visste allerede den dag at Doeg, edomitten, var der, og at han visselig vilde si det til Saul; jeg bærer skylden for alle de liv som er drept i din fars hus. 
9:22:23: Bli hos mig og vær ikke redd! Den som står mig efter livet, står også dig efter livet; hos mig er du i sikkerhet. 
9:23:1: Så kom det nogen og sa til David: Filistrene har nu kringsatt Ke'ila, og de plyndrer låvene. 
9:23:2: Da spurte David Herren: Skal jeg ta avsted og slå disse filistrer? Herren svarte David: Ta avsted og slå filistrene og frels Ke'ila! 
9:23:3: Men Davids menn sa til ham: Vi må jo være redde for livet her i Juda; og så skulde vi dra til Ke'ila mot filistrenes fylkinger? 
9:23:4: Da spurte David Herren ennu en gang, og Herren svarte ham og sa: Ta ut og dra ned til Ke'ila! Jeg gir filistrene i din hånd. 
9:23:5: Så drog David og hans menn til Ke'ila, og han stred mot filistrene og drev deres buskap bort og påførte dem et stort mannefall. Således frelste David Ke'ilas innbyggere. 
9:23:6: Da Abjatar, Akimeleks sønn, flyktet til David i Ke'ila, hadde han livkjortelen med sig der ned. 
9:23:7: Da Saul fikk spurt at David var kommet til Ke'ila, sa han: Gud kjennes ikke ved ham og har gitt ham i min hånd; for han har stengt sig selv inne ved å gå inn i en by med dobbelte porter og bommer. 
9:23:8: Så kalte Saul alt folket sammen til strid og vilde dra ned til Ke'ila for å kringsette David og hans menn. 
9:23:9: Da David skjønte at det var mot ham Saul la op onde råd, sa han til Abjatar, presten: Kom hit med livkjortelen! 
9:23:10: Så sa David: Herre, Israels Gud! Din tjener har hørt at Saul har i sinne å komme til Ke'ila og ødelegge byen for min skyld. 
9:23:11: Vil Ke'ilas menn overgi mig til ham? Vil Saul dra her ned, som din tjener har hørt? Herre, Israels Gud! Åpenbar det for din tjener! Herren svarte: Ja, han vil dra her ned. 
9:23:12: Da sa David: Vil Ke'ilas menn overgi mig og mine menn til Saul? Herren svarte: Ja, det vil de. 
9:23:13: Da brøt David op med sine folk, omkring seks hundre mann; de drog bort fra Ke'ila og vanket om hvor det traff sig. Og da Saul fikk spurt at David hadde sloppet bort fra Ke'ila, lot han være å dra ut. 
9:23:14: Siden holdt David til i ørkenen, i fjellborgene; han holdt til i fjellene i Sifs ørken; og Saul søkte efter ham hele tiden, men Gud gav ham ikke i hans hånd. 
9:23:15: Men David skjønte at Saul hadde draget ut for å stå ham efter livet. Mens David var i skogen i Sifs ørken, 
9:23:16: drog Jonatan, Sauls sønn, avsted og kom til ham i skogen, og han styrket hans mot i Gud. 
9:23:17: Han sa til ham: Vær ikke redd! Min far Sauls hånd skal ikke nå dig; du skal bli konge over Israel, og jeg skal være den næste efter dig; det vet også Saul, min far. 
9:23:18: Så gjorde de begge en pakt for Herrens åsyn; og David blev i skogen, men Jonatan drog hjem igjen. 
9:23:19: Men nogen sifitter drog op til Saul i Gibea og sa: David holder sig skjult hos oss i fjellborgene i skogen, på Kakilahaugen sønnenfor ørkenen. 
9:23:20: Så kom nu her ned, konge, så sant det er din attrå å komme her ned, og vi skal sørge for å overgi ham i kongens hånd. 
9:23:21: Saul svarte: Velsignet være I av Herren, fordi I har medynk med mig! 
9:23:22: Dra nu bort og pass på fremdeles, så I kan få rede på og se efter hvor han vanker, og hvem som har sett ham der! For folk har sagt mig at han er meget slu. 
9:23:23: Se efter og gjør eder kjent med alle de smutthuller hvor han skjuler sig, og kom så tilbake til mig med sikker beskjed. Så vil jeg gå med eder, og dersom han er i landet, skal jeg søke ham op blandt alle Judas tusener. 
9:23:24: Så brøt de op og drog til Sif forut for Saul; men David og hans menn var da i Maons ørken, på den øde mark sønnenfor ørkenen. 
9:23:25: Siden gikk Saul og hans menn avsted for å lete efter David; men David fikk vite det, og han drog ned til klippen og blev i Maons ørken; og da Saul fikk høre det, satte han efter David til Maons ørken. 
9:23:26: Saul gikk på den ene side av fjellet og David og hans menn på den andre side. Og David søkte engstelig å komme bort fra Saul, mens Saul og hans menn holdt på å omringe David og hans menn for å gripe dem. 
9:23:27: Da kom det et bud til Saul og sa: Skynd dig og dra avsted! Filistrene har falt inn i landet. 
9:23:28: Så vendte Saul tilbake og forfulgte ikke David lenger, men drog mot filistrene; derfor har de kalt dette sted Sela-Hammahlekot*. # <* undkomst-klippen.> 
9:24:1: Så drog David op derfra og holdt til i fjellborgene ved En-Gedi. 
9:24:2: Da Saul kom tilbake efterat han hadde forfulgt filistrene, fikk han spurt at David var i En-Gedis ørken. 
9:24:3: Da tok Saul tre tusen mann, som han hadde valgt ut blandt hele Israel, og drog avsted for å lete efter David og hans menn på Stenbukk-klippene. 
9:24:4: Han kom da til fårehegnene ved veien; der var en hule, og Saul gikk der inn i sine egne ærender; men David og hans menn satt innerst inne i hulen. 
9:24:5: Da sa Davids menn til ham: Se, nu er den dag kommet om hvilken Herren sa til dig: Nu gir jeg din fiende i din hånd, så du kan gjøre med ham som du finner for godt! Så stod David op og skar hemmelig fliken av Sauls kappe. 
9:24:6: Men derefter slo samvittigheten David, fordi han hadde skåret av fliken på Sauls kappe. 
9:24:7: Og han sa til sine menn: Herren fri mig fra å gjøre slikt mot min herre, mot Herrens salvede, og legge hånd på ham! For Herrens salvede er han. 
9:24:8: Og David irettesatte sine menn med hårde ord og lot dem ikke få lov til å overfalle Saul. Men Saul gikk ut av hulen og gav sig på veien. 
9:24:9: Derefter stod David op og gikk ut av hulen og ropte efter Saul: Herre konge! Da så Saul sig tilbake, og David bøide sig med ansiktet mot jorden og kastet sig ned. 
9:24:10: Og David sa til Saul: Hvorfor hører du på folk som sier: David søker din ulykke? 
9:24:11: Idag har du jo med egne øine sett at Herren idag hadde gitt dig i min hånd i hulen, og det var tale om å drepe dig, men jeg hadde medynk med dig og sa: Jeg vil ikke legge hånd på min herre; for Herrens salvede er han. 
9:24:12: Men se, min far, se, her er fliken av din kappe i min hånd! For når jeg skar fliken av din kappe og ikke drepte dig, så kan du vel skjønne og se at jeg ikke har hatt noget ondt eller nogen misgjerning i sinne og ikke har forsyndet mig mot dig, enda du efterstreber mig og vil ta mitt liv. 
9:24:13: Herren skal dømme mellem mig og dig, og Herren skal hevne mig på dig, men min hånd skal ikke ramme dig; 
9:24:14: det er som det gamle ordsprog sier: Fra ugudelige kommer ugudelighet. Men min hånd skal ikke ramme dig. 
9:24:15: Hvem er det Israels konge har draget ut efter? Hvem er det du forfølger? En død hund, en loppe. 
9:24:16: Så skal da Herren være dommer og dømme mellem mig og dig; han se til og føre min sak og dømme mig fri av din hånd! 
9:24:17: Da nu David hadde talt disse ord til Saul, da sa Saul: Er det du som taler, min sønn David? Og Saul brast i gråt. 
9:24:18: Og han sa til David: Du er rettferdigere enn jeg; for du har gjort godt mot mig, men jeg har gjort ondt mot dig. 
9:24:19: Du har idag vist mig hvor god du har vært mot mig, idet du ikke drepte mig da Herren hadde gitt mig i din hånd. 
9:24:20: Når en mann treffer på sin fiende, lar han ham da gå sin vei i fred? Herren lønne dig for denne dag - for det du har gjort mot mig! 
9:24:21: Jeg vet jo alt at du skal bli konge, og at kongedømmet over Israel skal bli fast i din hånd. 
9:24:22: Så tilsverg mig da nu ved Herren at du ikke vil utrydde mine efterkommere og ikke utslette mitt navn av min fars hus! 
9:24:23: Og David tilsvor Saul det; og Saul drog hjem; men David og hans menn steg op til fjellborgen. 
9:25:1: Så døde Samuel, og hele Israel samlet sig og gråt over ham, og de begravde ham i hans hjem i Rama. Da brøt David op og drog ned til ørkenen Paran. 
9:25:2: I Maon var det en mann som hadde sin buskap i Karmel. Det var en meget rik mann; han eide tre tusen får og tusen gjeter. Han holdt just på å klippe sine får i Karmel. 
9:25:3: Mannens navn var Nabal, og hans hustru hette Abiga'il; hun var en forstandig og fager kvinne, men mannen var hård og ond i hele sin adferd. Han var av Kalebs ætt. 
9:25:4: Mens David var i ørkenen, fikk han høre at Nabal var i ferd med å klippe sine får. 
9:25:5: Da sendte David ti av sine menn avsted og sa til dem: Gå op til Karmel, og når I kommer til Nabal, så hils ham fra mig 
9:25:6: og si: Lykke til! Fred være med dig, og fred med ditt hus, og fred med alt det du har! 
9:25:7: Jeg har hørt at du har fåreklipning. Nu har dine hyrder vært sammen med oss; vi har ikke gjort dem fortred, og der er intet kommet bort for dem i all den tid de har vært i Karmel. 
9:25:8: Spør dine folk! De vil fortelle dig det. La nu oss tjenere finne nåde for dine øine! Vi er jo kommet hit på en gledesdag; gi da dine tjenere og din sønn David hvad du har forhånden! 
9:25:9: Da Davids menn kom dit, sa de alt dette til Nabal i Davids navn; så stod de og ventet. 
9:25:10: Men Nabal svarte Davids tjenere og sa: Hvem er David, hvem er Isais sønn? Nu om dagene er der mange tjenere som løper bort fra sine herrer. 
9:25:11: Skulde så jeg ta mitt brød og mitt vann og mitt slaktefe, som jeg har slaktet til dem som klipper for mig, og gi det til menn om hvem jeg ikke vet hvor de er fra? 
9:25:12: Davids menn vendte om og gikk sin vei, og da de kom hjem igjen, fortalte de ham alt dette. 
9:25:13: Da sa David til sine menn: Spenn sverdet om eder, alle mann! Så spente de alle sine sverd om sig, og David spente og sverdet om sig. Og de fulgte David op; det var omkring fire hundre mann, og to hundre blev igjen ved trosset. 
9:25:14: Men en av folkene fortalte det til Abiga'il, Nabals hustru, og sa: David sendte bud fra ørkenen med hilsen til vår herre; men han skjelte dem ut. 
9:25:15: Og disse menn har vært meget gode mot oss, og ingen har gjort oss nogen fortred, og vi har ikke mistet noget i all den tid vi ferdedes sammen med dem, når vi var ute på marken. 
9:25:16: De var som en mur omkring oss både natt og dag i all den tid vi var sammen med dem og gjætte småfeet. 
9:25:17: Tenk nu efter, så du vet hvad du skal gjøre! For ulykken henger over vår herre og hele hans hus, og selv er han et ondt menneske, så ingen kan tale til ham. 
9:25:18: Da skyndte Abiga'il sig og tok to hundre brød og to skinnsekker med vin og fem får som var tillaget og fem mål ristet korn og hundre rosinkaker og to hundre fikenkaker og la dem på asenene. 
9:25:19: Og hun sa til sine folk: Dra i forveien for mig! Jeg kommer snart efter. Men til sin mann Nabal sa hun ikke noget om dette. 
9:25:20: Mens hun nu red på asenet og kom ned i en hulvei i fjellet, fikk hun se David og hans menn, som kom ned imot henne, så hun møtte dem. 
9:25:21: Men David hadde sagt: Til ingen nytte har jeg vernet om alt det denne mann hadde i ørkenen, så det ikke er kommet bort noget av alt det som tilhørte ham; men han har gjengjeldt mig godt med ondt. 
9:25:22: Så sant Gud vil la det gå Davids fiender ille både nu og siden: Jeg vil ikke la en eneste mann av alle dem som hører ham til, bli i live til imorgen! 
9:25:23: Da Abiga'il fikk se David, skyndte hun sig og steg ned av asenet, og hun falt på sitt ansikt for David og bøide sig mot jorden. 
9:25:24: Og hun falt ned for hans føtter og sa: Det er jeg, herre, det er jeg som har skylden; men la din tjenerinne få lov til å tale til dig, og hør på din tjenerinnes ord! 
9:25:25: Herre, akt ikke på denne onde mann Nabal! For som han heter, så er han; Nabal* heter han, og full av dårskap er han. Men jeg din tjenerinne har ikke sett de menn som du, herre, hadde sendt. # <* dåre.> 
9:25:26: Og nu, herre, så sant Herren lever, og så sant du selv lever, har Herren hindret dig fra å pådra dig blodskyld og fra å ta dig selv til rette; og nu skal dine fiender og de som søker å volde min herre ulykke, bli som Nabal. 
9:25:27: Og denne gave som din tjenestekvinne har hatt med til min herre, la nu den bli gitt til de menn som er i min herres følge! 
9:25:28: Forlat din tjenerinne hvad hun har gjort ille! For Herren vil bygge min herre et hus som står fast; for det er Herrens kriger* du fører, herre, og ondt er ikke funnet hos dig fra den dag du blev til. # <* 1SA 18, 17.> 
9:25:29: Og om nogen står frem, som forfølger dig og står dig efter livet, da skal min herres liv være gjemt blandt de levendes flokk hos Herren din Gud; men dine fienders liv skal han slynge bort som en sten av slyngen. 
9:25:30: Når da Herren gjør mot dig alt det gode han har lovt dig, herre, og setter dig til fyrste over Israel, 
9:25:31: så skal ikke dette bli dig til anstøt eller volde dig samvittighetsnag, herre, at du har utøst uskyldig blod og tatt dig selv til rette, herre. Og når Herren gjør vel mot dig, herre, så kom din tjenerinne i hu! 
9:25:32: Da sa David til Abiga'il: Lovet være Herren, Israels Gud, som idag har sendt mig dig i møte! 
9:25:33: Og velsignet være din klokskap, og velsignet være du selv, som idag har holdt mig tilbake fra å pådra mig blodskyld og ta mig selv til rette! 
9:25:34: Men så sant Herren, Israels Gud, lever, han som har holdt mig tilbake fra å gjøre dig ondt: Hadde du ikke skyndt dig å komme mig i møte, da skulde ikke en eneste mann være blitt tilbake for Nabal til imorgen. 
9:25:35: Så tok David imot de gaver hun hadde hatt med til ham, og han sa til henne: Dra hjem i fred! Jeg har merket mig dine ord og opfylt din bønn. 
9:25:36: Da Abiga'il kom hjem til Nabal, var det nettop gjestebud i huset, et gjestebud som hos en konge, og Nabal var lystig og hadde drukket sterkt. Hun fortalte ham intet, hverken smått eller stort, før det blev morgen. 
9:25:37: Men da Nabal om morgenen var våknet av sitt rus, fortalte hans hustru ham alt sammen. Da stivnet hjertet i hans bryst, og han blev som en sten. 
9:25:38: Og omkring ti dager efter slo Herren Nabal, så han døde. 
9:25:39: Da David fikk høre at Nabal var død, sa han: Lovet være Herren, som har hevnet min vanære på Nabal og holdt sin tjener tilbake fra å gjøre ondt - Herren, som lot Nabals ondskap komme tilbake på hans eget hode! Så sendte David bud og beilet til Abiga'il for å få henne til hustru. 
9:25:40: Davids tjenere kom til Abiga'il i Karmel, og de bar frem sitt ærend og sa: David har sendt oss til dig for å få dig til hustru. 
9:25:41: Da stod hun op og bøide sig med ansiktet mot jorden og sa: Se, her er din tjenerinne, ferdig til å være din tjenestekvinne og tvette min herres tjeneres føtter. 
9:25:42: Og Abiga'il skyndte sig og gjorde sig ferdig og satte sig på sitt asen, hun og hennes fem piker, som fulgte henne; og hun drog avsted med de menn David hadde sendt, og blev hans hustru. 
9:25:43: Dessuten giftet David sig med Akinoam fra Jisre'el og de blev begge hans hustruer. 
9:25:44: Men Saul hadde gitt sin datter Mikal, Davids hustru, til Palti, sønn av La'is, som var fra Gallim. 
9:26:1: Sifittene kom til Saul i Gibea og sa: David holder sig skjult på Kakilahaugen, midt imot ørkenen. 
9:26:2: Da tok Saul ut og drog ned til Sifs ørken med tre tusen mann, som var utvalgt av hele Israel, for å søke efter David i Sifs ørken. 
9:26:3: Og Saul leiret sig på Kakilahaugen, midt imot ørkenen, ved veien. David holdt til i ørkenen, og da han skjønte at Saul var kommet efter ham ut i ørkenen, 
9:26:4: sendte han ut speidere og fikk vite at Saul virkelig var kommet. 
9:26:5: Så brøt David op og kom til det sted hvor Saul hadde leiret sig, og David så stedet hvor Saul og hans hærfører Abner, Ners sønn, hadde lagt sig til å sove; Saul lå i vognborgen, men folket hadde leiret sig rundt omkring ham. 
9:26:6: Da tok David til orde og sa til Akimelek, hetitten, og til Abisai, Serujas sønn, Joabs bror: Hvem vil gå med mig ned til Saul i leiren? Abisai svarte: Jeg vil gå med dig ned. 
9:26:7: Da David og Abisai om natten kom bort til krigsfolket, lå Saul og sov i vognborgen, og hans spyd var stukket ned i jorden ved hans hodegjerde, og Abner og folket lå rundt omkring ham. 
9:26:8: Da sa Abisai til David: Gud har idag gitt din fiende i din hånd; la mig nu få støte spydet igjennem ham og ned i jorden med en eneste gang, så jeg ikke skal behøve å gi ham et støt til! 
9:26:9: Men David sa til Abisai: Drep ham ikke! Hvem har utrakt sin hånd mot Herrens salvede og er blitt ustraffet? 
9:26:10: Og David sa: Så sant Herren lever: Herren skal selv slå ham, enten nu hans dag kommer, og han dør, eller han drar i krig og blir bortrykket. 
9:26:11: Herren fri mig fra å legge hånd på Herrens salvede! Men ta nu spydet, som står ved hans hodegjerde, og vannkrukken og la oss gå! 
9:26:12: Så tok David spydet og vannkrukken fra Sauls hodegjerde, og de gikk sin vei, og der var ingen som så det eller merket det eller våknet - de sov alle sammen, fordi Herren hadde latt en dyp søvn falle på dem. 
9:26:13: Derefter gikk David over på den andre side av dalen og stod langt borte på toppen av fjellet - det var et godt stykke mellem dem. 
9:26:14: Og David ropte til krigsfolket og til Abner, Ners sønn, og sa: Svarer du ikke, Abner? Og Abner svarte: Hvem er du som roper hit over til kongen? 
9:26:15: Da sa David til Abner: Du er jo en mann som ikke har sin like i Israel? Hvorfor har du da ikke holdt vakt over din herre kongen? Det kom en av folket og vilde drepe kongen, din herre. 
9:26:16: Det er ikke godt det du har gjort. Så sant Herren lever: I er dødsens, I som ikke har holdt vakt over eders herre, han som er Herrens salvede. Se nu efter hvor kongens spyd er, og vannkrukken som stod ved hans hodegjerde! 
9:26:17: Da kjente Saul Davids røst og sa: Er det din røst, min sønn David? David svarte: Ja, herre konge! 
9:26:18: Og han sa: Hvorfor forfølger min herre sin tjener således? Hvad har jeg gjort, og hvad ondt er der i min hånd? 
9:26:19: Hør nu, herre konge, din tjeners ord: Er det Herren som har egget dig op mot mig, så forson ham ved et offer! Men er det mennesker, så være de forbannet for Herrens åsyn, fordi de nu har drevet mig bort, så jeg ikke får opholde mig i Herrens eget land - de sier: Gå bort og tjen andre guder! 
9:26:20: Og la nu ikke mitt blod bli utøst langt borte fra Herrens åsyn, siden Israels konge har draget ut for å lete efter en loppe, likesom en jager efter en akerhøne i fjellet. 
9:26:21: Da sa Saul: Jeg har syndet; kom tilbake, min sønn David! Jeg vil ikke gjøre dig noget ondt mere, siden mitt liv var så dyrt i dine øine idag. Jeg har virkelig båret mig at som en dåre og faret storlig vill. 
9:26:22: David svarte: Se, her er ditt spyd, konge! La en av dine tjenere komme hit over og hente det! 
9:26:23: Men Herren vil gjengjelde mig min rettferdighet og troskap. Herren gav dig idag i min hånd; men jeg vilde ikke legge hånd på Herrens salvede. 
9:26:24: Og sa høit verd som ditt liv hadde i mine øine idag, så høit verd skal mitt liv ha i Herrens øine, så han frir mig ut av all trengsel. 
9:26:25: Da sa Saul til David: Velsignet være du, min sønn David! Alt det du tar dig fore, skal du og kunne fullføre. Så gikk David sin vei, og Saul drog hjem igjen. 
9:27:1: Men David tenkte ved sig selv: Engang kommer jeg nu allikevel til å miste livet for Sauls hånd; jeg har ikke noget godt i vente. Jeg vil undfly til filistrenes land; så vil Saul holde op med å lete efter mig i hele Israels land, og jeg slipper ut av hans hånd. 
9:27:2: Så brøt David op og drog med de seks hundre mann som var hos ham, over til Akis, sønn av Maok, kongen i Gat. 
9:27:3: Og David blev boende hos Akis i Gat, både han og hans menn, hver med sitt hus, David med sine to hustruer, Akinoam fra Jisre'el og Abiga'il fra Karmel, Nabals hustru. 
9:27:4: Og da Saul fikk vite at David var flyktet til Gat, lette han ikke lenger efter ham. 
9:27:5: Så sa David til Akis: Har jeg funnet nåde for dine øine, så la mig få et sted å bo på i en av landsbyene. Hvorfor skal din tjener bo hos dig i kongestaden? 
9:27:6: Da gav Akis ham samme dag Siklag; derfor har Siklag hørt Judas konger til helt til denne dag. 
9:27:7: Den tid David bodde i filistrenes land, var i alt ett år og fire måneder. 
9:27:8: David og hans menn drog op og overfalt gesurittene og girsittene og amalekittene; det var de folk som fra eldgammel tid hadde bodd i det land som strekker sig bortimot Sur og helt til Egyptens land. 
9:27:9: Og hver gang David herjet landet, lot han hverken mann eller kvinne bli i live; men han tok både småfe og storfe og asener og kameler og klær og drog så tilbake, og når han så kom til Akis, 
9:27:10: og Akis spurte: I har vel ikke gjort noget innfall idag? da svarte David: Jo, i sydlandet, både blandt Judas menn og blandt jerahme'elittene og kenittene. 
9:27:11: David lot hverken mann eller kvinne bli i live eller tok dem med sig til Gat; for han tenkte: De kunde fortelle om oss og si: Så og så har David gjort. Således bar han sig at i all den tid han bodde i filistrenes land. 
9:27:12: Og Akis trodde David og tenkte: Han har gjort sig forhatt blandt Israel, sitt folk, og nu kommer han til å være min tjener for all tid. 
9:28:1: På den tid hendte det at filistrene samlet sine hærer for å dra i strid mot Israel; og Akis sa til David: Du vet vel at du må dra ut med mig i striden, både du og dine menn. 
9:28:2: David svarte: Vel, da skal du få se hvad din tjener vil gjøre. Da sa Akis til David: Vel, jeg vil sette dig til vokter over mitt liv alle dager. 
9:28:3: Samuel var dengang død, og hele Israel hadde sørget over ham og begravet ham i Rama, hans egen by; og Saul hadde drevet dødningemanerne og sannsigerne ut av landet. 
9:28:4: Da filistrene nu hadde samlet sig, trengte de frem til Sunem og leiret sig der. Da samlet Saul hele Israel, og de leiret sig på Gilboa. 
9:28:5: Men da Saul så filistrenes leir, blev han sa redd at hjertet skalv i ham. 
9:28:6: Og Saul spurte Herren; men Herren svarte ham ikke, hverken ved drømmer eller ved urim eller ved profeter. 
9:28:7: Da sa Saul til sine tjenere: Finn mig en kvinne som kan mane frem døde, så vil jeg gå til henne og spørre henne! Hans tjenere svarte: I En-Dor er det en kvinne som kan mane. 
9:28:8: Så gjorde Saul sig ukjennelig og tok andre klær på og gikk så avsted i følge med to menn. De kom til kvinnen om natten, og Saul sa: Spå mig ved å mane frem en død og hent op til mig den som jeg nevner for dig! 
9:28:9: Kvinnen svarte ham: Du vet jo selv hvad Saul har gjort, hvorledes han har utryddet dødningemanerne og sannsigerne av landet, og hvorfor setter du da en snare for mitt liv, så du fører død over mig? 
9:28:10: Da svor Saul henne en ed ved Herren og sa: Så sant Herren lever, skal ingen skyld komme på dig i denne sak. 
9:28:11: Da sa kvinnen: Hvem skal jeg hente op til dig? Han svarte: Hent Samuel op til mig! 
9:28:12: Men da kvinnen så Samuel, skrek hun høit; og hun sa til Saul: Hvorfor har du narret mig? Du er jo Saul. 
9:28:13: Da sa kongen til henne: Vær ikke redd! Men hvad så du? Og kvinnen sa til Saul: Jeg så en gud stige op av jorden. 
9:28:14: Han spurte henne: Hvorledes ser han ut? Hun svarte: En gammel mann stiger op, og han er svøpt i en kappe. Da forstod Saul at det var Samuel, og han kastet sig ned med ansiktet mot jorden. 
9:28:15: Og Samuel sa til Saul: Hvorfor har du urodd mig og hentet mig op? Saul svarte: Jeg er i stor trengsel; filistrene strider mot mig, og Gud er veket fra mig og svarer mig ikke mere, hverken ved profeter eller ved drømmer; så kalte jeg på dig, forat du skulde la mig vite hvad jeg skal gjøre. 
9:28:16: Da sa Samuel: Hvorfor spør du da mig, når Herren er veket fra dig og er blitt din fiende? 
9:28:17: Herren har nu gjort således som han sa gjennem mig - Herren har revet kongedømmet ut av din hånd og gitt det til en annen, til David. 
9:28:18: Fordi du ikke lød Herrens ord og ikke fullbyrdet hans brennende vrede på Amalek, derfor har Herren gjort dette mot dig idag. 
9:28:19: Og sammen med dig vil Herren også gi Israel i filistrenes hånd, og imorgen skal du og dine sønner være hos mig; også Israels leir skal Herren gi i filistrenes hånd. 
9:28:20: Og straks falt Saul til jorden så lang han var, så forferdet blev han over Samuels ord; og ikke var det nogen kraft i ham heller, for han hadde ikke smakt mat hele dagen og hele natten. 
9:28:21: Kvinnen kom nu bort til Saul, og da hun så hvor forferdet han var, sa hun til ham: Din tjenerinne lød ditt ord og satte mitt liv på spill og gjorde som du sa til mig. 
9:28:22: Så lyd nu du og din tjenerinnes ord og la mig få sette frem litt mat for dig og et, så du kan få krefter til din ferd! 
9:28:23: Men han vilde ikke og sa: Jeg skal ingen mat ha. Da nødde de ham, både hans tjenere og kvinnen, og han lød dem og reiste sig op fra jorden og satte sig på sengen. 
9:28:24: Kvinnen hadde en gjøkalv i huset; hun skyndte sig og slaktet den, og så tok hun mel og eltet det og bakte usyrede kaker. 
9:28:25: Og hun satte det frem for Saul og hans tjenere, og de åt; så reiste de sig og gikk bort samme natt. 
9:29:1: Filistrene samlet alle sine hærer i Afek; men Israel leiret sig ved kilden i Jisre'el. 
9:29:2: Da nu filistrenes høvdinger drog frem med sine hundreder og tusener, drog også David og hans menn frem sammen med Akis; de var bakerst i hæren. 
9:29:3: Da sa filistrenes høvdinger: Hvad skal disse hebreere her? Akis svarte: Det er jo David, en av Sauls, Israels konges, tjenere, som nu har vært hos mig et år eller mere, og jeg har ikke funnet noget ondt hos ham fra den dag han gikk over til mig, og til denne dag. 
9:29:4: Men filistrenes høvdinger blev vrede på ham og sa til ham: Send mannen hjem og la ham vende tilbake til det sted som du har anvist ham! Han skal ikke dra med oss i striden; for da kunde han vende sig mot oss i slaget. Hvorledes skulde han vel kunne bedre gjenvinne sin herres yndest enn ved å komme til ham med disse menns hoder? 
9:29:5: Er det ikke den samme David som de sang således om under dansen: Saul har slått sine tusener, men David sine titusener? 
9:29:6: Da kalte Akis David til sig og sa til ham: Så sant Herren lever: Du er en ærlig mann, og jeg ser gjerne at du går ut og inn hos mig i leiren; for jeg har ikke funnet noget ondt hos dig fra den dag du kom til mig, og til denne dag. Men høvdingene synes ikke om dig. 
9:29:7: Så vend nu tilbake og dra bort i fred, så du ikke gjør noget som filistrenes høvdinger ikke synes om! 
9:29:8: Da sa David til Akis: Hvad har jeg gjort, og hvad ondt har du funnet hos din tjener fra den dag jeg kom i din tjeneste, like til denne dag, siden jeg ikke må dra med og stride mot min herre kongens fiender? 
9:29:9: Akis svarte David: Jeg vet at du i mine øine er god som en Guds engel; men filistrenes høvdinger sier: Han skal ikke dra med oss i striden. 
9:29:10: Så stå nu tidlig op imorgen, både du og din herres tjenere som er kommet hit med dig - gjør eder tidlig ferdige imorgen og dra bort så snart det blir lyst! 
9:29:11: Så stod David tidlig op med sine menn og gav sig samme morgen på hjemveien til filistrenes land; men filistrene drog op til Jisre'el. 
9:30:1: Da David og hans menn på den tredje dag kom til Siklag, hadde amalekittene overfalt sydlandet og Siklag, og de hadde inntatt Siklag og brent det op. 
9:30:2: Og kvinnene og alle som var der, både små og store, hadde de tatt til fange; de hadde ikke drept nogen, men hadde ført dem bort og hadde draget sin vei, 
9:30:3: og da David og hans menn kom til byen, fikk de se at den var brent op, og at deres hustruer og sønner og døtre var tatt til fange. 
9:30:4: Da brast de i gråt, både David og de folk som var med ham, og de gråt til de ikke lenger var i stand til å gråte. 
9:30:5: Også Davids to hustruer, Akinoam fra Jisre'el og Abiga'il, karmelitten Nabals hustru, var tatt til fange. 
9:30:6: Og selv kom David i stor nød, for folket sa at de vilde stene ham; så harme og sorgfulle var de alle sammen for sine sønners og døtres skyld. Men David søkte styrke hos Herren sin Gud. 
9:30:7: Og David sa til presten Abjatar, Akimeleks sønn: Kom hit til mig med livkjortelen! Da kom Abjatar til David med livkjortelen. 
9:30:8: Og David spurte Herren: Skal jeg sette efter denne røverflokk? Kan jeg nå dem igjen? Herren svarte: Sett efter dem! Du skal nå dem igjen, og du skal frelse fangene! 
9:30:9: Så drog David avsted med de seks hundre mann som fulgte ham, og de kom til Besor-bekken. Der blev en del av dem igjen; 
9:30:10: David satte efter fienden med fire hundre mann, men to hundre mann blev igjen - de var for trette til å gå over Besor-bekken. 
9:30:11: Så fant de en egyptisk mann som lå på marken, og de tok ham med sig til David. Og de gav ham mat, som han åt, og lot ham få vann å drikke, 
9:30:12: og dessuten et stykke fikenkake og to rosinkaker, og da han hadde ett, vendte hans livsånd tilbake; for han hadde ikke smakt mat og ikke drukket vann i tre dager og tre netter. 
9:30:13: Og David spurte ham: Hvem hører du til, og hvor er du fra? Han svarte: Jeg er en egyptisk gutt, tjener hos en amalekittisk mann; men min herre gikk fra mig her fordi jeg blev syk for tre dager siden. 
9:30:14: Vi hadde gjort innfall i sydlandet hvor kreterne* bor, og i det land som hører Juda til, og i den del av sydlandet som hører Kalebs ætt til, og vi hadde brent op Siklag. # <* d.e. filisterne, 1SA 30, 16.> 
9:30:15: Da sa David til ham: Vil du føre mig ned til denne røverflokk? Han svarte: Tilsverg mig ved Gud at du ikke vil drepe mig og heller ikke overgi mig i min herres hånd, sa vil jeg føre dig ned til den røverflokk du taler om. 
9:30:16: Derefter førte han ham ned, og der lå de spredt rundt omkring over hele marken og åt og drakk og holdt fest med alt det store bytte de hadde tatt fra filistrenes land og fra Juda land. 
9:30:17: David hugg dem ned helt fra skumringen til næste dags aften, og ingen av dem slapp unda så nær som fire hundre unge menn som kastet sig på kamelene og flyktet. 
9:30:18: Og David berget alt det som amalekittene hadde tatt; også sine to hustruer berget David. 
9:30:19: Det manglet ikke nogen, hverken liten eller stor, hverken sønner eller døtre, heller ikke noget av byttet eller av det de hadde tatt med sig; alt sammen hadde David med sig tilbake. 
9:30:20: Alt feet tok David og, både det små og det store; og driftekarene gikk foran buskapen og ropte: Dette er Davids bytte. 
9:30:21: Da David kom til de to hundre mann som hadde vært for trette til å følge ham, og som han hadde latt bli tilbake ved Besor-bekken, gikk de ut imot David og de folk som var med ham; og David gikk frem til folkene og hilste på dem. 
9:30:22: Da tok de til orde alle de onde og illesinnede menn blandt dem som hadde fulgt David, og sa: Siden de ikke gikk med oss, vil vi ikke gi dem noget av det bytte vi har berget; bare sin hustru og sine barn kan hver av dem ta med sig og gå hjem. 
9:30:23: Men David sa: Således skal I ikke gjøre, mine brødre, med det som Herren har gitt oss; han har bevart oss og gitt denne røverflokk som var kommet over oss, i vår hånd. 
9:30:24: Hvem skulde vel holde med eder i dette? Nei, den som drog med i striden, skal ikke ha større del av byttet enn den som blev igjen ved trosset; de skal dele likt. 
9:30:25: Og således blev det fra den dag og fremover - han gjorde dette til lov og rett i Israel, således som det er den dag idag. 
9:30:26: Da David kom til Siklag, sendte han noget av byttet til Judas eldste, de som var hans venner, og lot si: Se, her er en gave til eder av byttet som jeg har tatt fra Herrens fiender - 
9:30:27: til dem i Betel sendte han, og til dem i Ramot-Negeb og til dem i Jattir 
9:30:28: og til dem i Aroer og til dem i Sifmot og til dem i Estemoa 
9:30:29: og til dem i Rakal og til dem i jerahme'elittenes byer og til dem i kenittenes byer 
9:30:30: og til dem i Horma og til dem i Kor-Asan og til dem i Atak 
9:30:31: og til dem i Hebron og til alle de steder hvor David hadde vanket om med sine menn. 
9:31:1: Filistrene stred mot Israel, og Israels menn flyktet for filistrene, og det lå mange falne på Gilboa-fjellet. 
9:31:2: Og filistrene var like i hælene på Saul og hans sønner, og de drepte Jonatan og Abinadab og Malkisua, Sauls sønner. 
9:31:3: Og striden blev hård der hvor Saul stod; bueskytterne kom over ham, og han blev grepet av redsel for skytterne. 
9:31:4: Da sa Saul til sin våbensvenn: Dra ditt sverd og gjennembor mig med det, så ikke disse uomskårne skal komme og gjennembore mig og fare ille med mig! Men våbensvennen vilde ikke; han var for redd. Så tok Saul sitt sverd og styrtet sig i det. 
9:31:5: Og da våbensvennen så at Saul var død, styrtet også han sig i sitt sverd og døde med ham. 
9:31:6: Således døde Saul og hans tre sønner og hans våbensvenn og alle hans menn på en og samme dag. 
9:31:7: Og da de israelitter som bodde på den andre side av dalen, og de som bodde på Jordan-siden, så at israelittene var flyktet, og at Saul og hans sønner var død, forlot de byene og flyktet; og filistrene kom og bosatte sig i dem. 
9:31:8: Dagen efter kom filistrene for å plyndre de falne; da fant de Saul og hans tre sønner liggende på Gilboafjellet. 
9:31:9: De hugg hodet av ham og røvet hans våben og sendte dem rundt omkring i filistrenes land for å forkynne det glade budskap i deres avguders templer og for folket. 
9:31:10: Og hans våben la de i Astartetemplet, og hans kropp hengte de op på muren i Betsan. 
9:31:11: Da innbyggerne i Jabes i Gilead fikk høre hvad filistrene hadde gjort med Saul, 
9:31:12: tok alle våbenføre menn avsted og gikk hele natten; de tok Sauls og hans sønners kropper ned av Betsans mur, og da de var kommet til Jabes, brente de dem der. 
9:31:13: Deres ben tok de og begravde under tamarisken i Jabes, og så holdt de faste i syv dager. 
10:1:1: Da Saul var død, og David var vendt tilbake efterat han hadde slått amalekittene, blev David ennu to dager i Siklag. 
10:1:2: Da hendte det den tredje dag at det kom en mann fra Sauls leir med sønderrevne klær og jord på sitt hode; og da han kom til David, kastet han sig ned på jorden for ham. 
10:1:3: David spurte ham: Hvor kommer du fra? Han svarte: Jeg har flyktet fra Israels leir. 
10:1:4: Da sa David til ham: Hvorledes er det gått? Fortell mig det! Han svarte: Folket flyktet fra striden; mange av folket falt og døde, og Saul og hans sønn Jonatan er også død. 
10:1:5: Da sa David til den gutt som kom med denne tidende til ham: Hvorledes vet du at Saul og hans sønn Jonatan er død? 
10:1:6: Gutten, han som var kommet med tidenden til ham, svarte: Ved en hendelse kom jeg op på Gilboafjellet, og der fikk jeg se Saul som stod og støttet sig på sitt spyd, mens vognene og hestfolket satte hårdt inn på ham. 
10:1:7: Han vendte sig om, og da han så mig, ropte han på mig. Jeg svarte: Her er jeg. 
10:1:8: Så spurte han: Hvem er du? Jeg svarte: Jeg er en amalekitt. 
10:1:9: Da sa han til mig: Kom hit til mig og drep mig! For krampen har grepet mig, men jeg har ennu min fulle livskraft. 
10:1:10: Så gikk jeg bort til ham og drepte ham, for jeg visste at han ikke vilde overleve sitt fall; og jeg tok kongesmykket som han hadde på hodet, og ringen han bar på armen, og tok dem med hit til min herre. 
10:1:11: Da tok David og sønderrev sine klær, og det samme gjorde alle de menn som var hos ham. 
10:1:12: Og de klaget og gråt og fastet helt til aftenen over Saul og hans sønn Jonatan og over Herrens folk og over Israels hus, fordi de var falt for sverdet. 
10:1:13: Så spurte David gutten som var kommet med tidenden til ham: Hvor er du fra? Han svarte: Jeg er sønn av en amalekitt som er flyttet inn her. 
10:1:14: Da sa David til ham: Hvorledes kunde du driste dig til å utrekke din hånd og ta livet av Herrens salvede? 
10:1:15: Og David kalte på en av sine menn og sa: Kom hit og hugg ham ned! Og han slo ham ihjel. 
10:1:16: Men David sa til ham: Ditt blod komme over ditt hode; for din egen munn vidnet mot dig da du sa: Jeg har drept Herrens salvede. 
10:1:17: Og David kvad denne klagesang over Saul og hans sønn Jonatan, 
10:1:18: og han bød at Judas barn skulde lære den; "buen"* heter den, og den er opskrevet i Den rettskafnes bok**: # <* således kaltes den efter ordet bue, 2SA 1, 22.> # <** JOS 10, 13.> 
10:1:19: Din pryd, Israel, ligger ihjelslått på dine hauger; o, at heltene skulde falle! 
10:1:20: Forkynn det ikke i Gat, meld det ikke på gatene i Askalon, at ikke filistrenes døtre skal glede sig, de uomskårnes døtre juble! 
10:1:21: I Gilboa-fjell! Ikke falle det dugg eller regn på eder, ei heller være der marker som bærer offergaver! For der blev heltes skjold plettet med blod, Sauls skjold, ei salvet med olje. 
10:1:22: Fra falnes blod, fra heltes kjøtt vek Jonatans bue aldri tilbake, aldri kom Sauls sverd umettet hjem. 
10:1:23: Saul og Jonatan, elskelige og milde i livet, blev heller ikke skilt i døden; hurtigere var de enn ørner, sterkere enn løver. 
10:1:24: I Israels døtre! Gråt over Saul som klædde eder yndig i purpur, som satte smykker av gull på eders klædebon! 
10:1:25: O, at heltene skulde falle i striden! Jonatan ligger ihjelslått på dine hauger! 
10:1:26: Hjertelig bedrøvet er jeg over dig, min bror Jonatan! Du var mig inderlig kjær; din kjærlighet var mig dyrebarere enn kvinners kjærlighet. 
10:1:27: O, at heltene skulde falle, og krigens redskaper omkomme! 
10:2:1: Siden spurte David Herren: Skal jeg dra op til en av byene i Juda? Herren svarte: Gjør det! Da spurte David: Hvorhen skal jeg dra? Han svarte: Til Hebron. 
10:2:2: Så drog David der op med sine to hustruer, Akinoam fra Jisre'el og Abiga'il, karmelitten Nabals hustru. 
10:2:3: Og de menn som var hos ham, lot David også dra der op, hver med sitt hus, og de bosatte sig i byene omkring Hebron. 
10:2:4: Da kom Judas menn dit og salvet David til konge over Judas hus. Så kom det nogen og fortalte David at det var mennene i Jabes i Gilead som hadde begravet Saul. 
10:2:5: Da sendte David bud til mennene i Jabes i Gilead og lot si til dem: Velsignet være I av Herren, fordi I har gjort den kjærlighetsgjerning mot eders herre Saul å begrave ham! 
10:2:6: Så vise nu Herren sin miskunnhet og trofasthet mot eder, og jeg vil også gjøre vel mot eder, fordi I har gjort dette. 
10:2:7: Så ta nu mot til eder og vær djerve menn! For vel er eders herre Saul død, men Judas hus har også salvet mig til konge over sig. 
10:2:8: Men Abner, Ners sønn, Sauls hærfører, tok Isboset, Sauls sønn, og førte ham over til Mahana'im 
10:2:9: og gjorde ham til konge over Gilead og over asurittene og over Jisre'el og Efra'im og Benjamin og over hele Israel. 
10:2:10: Isboset, Sauls sønn, var firti år gammel da han blev konge over Israel, og han regjerte i to år; det var bare Judas hus som holdt sig til David. 
10:2:11: Og hele den tid David var konge i Hebron over Judas hus, var syv år og seks måneder. 
10:2:12: Abner, Ners sønn, tok ut fra Mahana'im med Isbosets, Sauls sønns folk og drog til Gibeon. 
10:2:13: Og Joab, Serujas sønn, og Davids folk drog også ut og traff sammen med dem ved Gibeondammen; den ene flokk leiret sig på den ene side av dammen og den andre på den andre side. 
10:2:14: Da sa Abner til Joab: La de unge menn trede frem og holde en krigslek for våre øine! Joab svarte: Ja, la dem det! 
10:2:15: Så reiste de sig og gikk frem i det fastsatte tall: tolv for Benjamin og Isboset, Sauls sønn, og tolv av Davids folk. 
10:2:16: Og de grep hverandre i hodet og stakk sverdet i siden på hverandre, og så falt de alle sammen. Siden kaltes dette sted Helkat-Hassurim*; det ligger ved Gibeon. # <* de skarpe eggers mark.> 
10:2:17: Striden blev meget hård den dag, og Abner og Israels menn blev slått av Davids folk. 
10:2:18: Der var Serujas tre sønner med: Joab og Abisai og Asael. Asael var lett på foten som et rådyr på marken. 
10:2:19: Og Asael satte efter Abner og bøide ikke av hverken til høire eller til venstre, men holdt sig stadig i Abners spor. 
10:2:20: Da vendte Abner sig om og sa: Er det du, Asael? Han svarte: Ja. 
10:2:21: Da sa Abner til ham: Bøi av, enten til høire eller til venstre! Grip en av de unge og ta hans rustning! Men Asael vilde ikke la være å forfølge ham. 
10:2:22: Da sa Abner atter til Asael: La være å forfølge mig! Vil du kanskje jeg skal slå dig til jorden? Hvorledes skulde jeg da kunne se din bror Joab i øinene? 
10:2:23: Men han vilde ikke holde op; da stakk Abner ham med bakenden av sitt spyd i underlivet, så spydet gikk ut gjennem ryggen på ham, og han falt om der og døde på stedet. Og hver den som kom til det sted hvor Asael var falt og døde, stanset der. 
10:2:24: Joab og Abisai forfulgte Abner; og da solen var gått ned, kom de til Amma-haugen, som ligger midt imot Giah på veien til Gibeons ørken. 
10:2:25: Men Benjamins barn samlet sig i én flokk om Abner og stilte sig på toppen av en haug. 
10:2:26: Da ropte Abner til Joab: Skal sverdet aldri holde op å fortære? Skjønner du ikke at dette vil ende med sorg? Hvor lenge vil du vente før du byder dine folk at de skal holde op med å forfølge sine brødre? 
10:2:27: Joab svarte: Så sant Gud lever: Hadde du ingen ting sagt*, da skulde folket allerede fra morgenen av være blitt ført bort, så ingen hadde forfulgt sin bror. # <* 2SA 2, 14.> 
10:2:28: Så støtte Joab i basunen, og alt folket stanset og forfulgte ikke Israel lenger og blev heller ikke ved å stride mere. 
10:2:29: Abner og hans menn gikk gjennem ødemarken hele den natt; så satte de over Jordan og gikk gjennem hele Bitron og kom til Mahana'im. 
10:2:30: Da Joab hadde holdt op med å forfølge Abner, samlet han alt folket, og der savnedes av Davids folk nitten mann foruten Asael. 
10:2:31: Men Davids folk hadde drept tre hundre og seksti mann av Benjamin og blandt Abners menn. 
10:2:32: Asael tok de op og begravde ham i hans fars grav i Betlehem. Joab og hans menn gikk hele natten og kom til Hebron da det begynte å dages. 
10:3:1: Krigen mellem Sauls hus og Davids hus blev langvarig; og David blev sterkere og sterkere, men Sauls hus blev svakere og svakere. 
10:3:2: David fikk seks sønner i Hebron: Hans førstefødte var Amnon, sønn av Akinoam fra Jisre'el. 
10:3:3: Den annen var Kilab*, sønn av Abiga'il, karmelitten Nabals hustru, og den tredje Absalom, sønn av Ma'aka, som var datter til Talmai, kongen i Gesur, # <* kalles og Daniel, 1KR 3, 1.> 
10:3:4: den fjerde Adonja, sønn av Haggit, den femte Sefatja, sønn av Abital, 
10:3:5: og den sjette Jitream, sønn av Davids hustru Egla. Disse sønner fikk David i Hebron. 
10:3:6: Mens krigen stod på mellem Sauls hus og Davids hus, støttet Abner Sauls hus. 
10:3:7: Nu hadde Saul hatt en medhustru ved navn Rispa, datter til Aja. Så sa Isboset til Abner: Hvorfor er du gått inn til min fars medhustru? 
10:3:8: Abner blev harm over Isbosets ord og sa: Er jeg et hundehode som holder med Juda? Den dag idag gjør jeg vel mot Sauls, din fars hus, mot hans brødre og hans venner; jeg har ikke overgitt dig i Davids hånd, og så går du nu i rette med mig for denne kvinnes skyld! 
10:3:9: Gud la det gå Abner ille både nu og siden om jeg ikke gjør således mot David som Herren har tilsvoret ham, 
10:3:10: og tar kongedømmet fra Sauls hus og opreiser Davids trone over Israel og over Juda fra Dan like til Be'erseba. 
10:3:11: Og han torde ikke svare Abner et ord mere, så redd var han for ham. 
10:3:12: Og Abner sendte bud i sitt sted til David og lot si: Hvem hører landet til? Og han lot fremdeles si: Gjør en pakt med mig, så skal jeg hjelpe dig og få hele Israel til å gå over til dig. 
10:3:13: Han svarte: Godt, jeg vil gjøre en pakt med dig; bare én ting krever jeg av dig: Du skal ikke komme for mine øine før du har med dig Mikal, Sauls datter, når du kommer for å trede frem for mig. 
10:3:14: Og David sendte bud til Isboset, Sauls sønn, og lot si: Gi mig min hustru Mikal, som jeg vant mig til brud med hundre filistrers forhuder! 
10:3:15: Da sendte Isboset bud og tok henne fra hennes mann Paltiel, sønn av La'is. 
10:3:16: Og hennes mann fulgte med og gikk gråtende bakefter henne like til Bahurim; men Abner sa til ham: Gå hjem igjen! Da vendte han om. 
10:3:17: Men Abner talte med Israels eldste og sa: Allerede lenge har I ønsket å få David til konge over eder. 
10:3:18: Så la ham nu bli det! For Herren har sagt om David: Ved min tjener Davids hånd vil jeg frelse mitt folk Israel fra filistrenes og alle deres fienders hånd. 
10:3:19: Abner talte også med benjaminittene. Så gikk han avsted for å tale med David i Hebron om alt det som Israel og hele Benjamins hus hadde besluttet. 
10:3:20: Abner kom til David i Hebron med tyve mann, og David gjorde et gjestebud for Abner og de menn som var med ham. 
10:3:21: Og Abner sa til David: Jeg vil ta avsted og samle hele Israel til min herre kongen, så de kan gjøre en pakt med dig, og du kan råde over alt det du ønsker. Så lot David Abner fare, og han drog bort i fred. 
10:3:22: I det samme kom Davids folk og Joab hjem fra et herjetog og hadde meget bytte med sig; Abner var da ikke lenger hos David i Hebron, for han hadde latt ham fare, og han hadde draget bort i fred. 
10:3:23: Da nu Joab og hele hæren som var med ham, kom hjem, fikk Joab høre at Abner, Ners sønn, var kommet til kongen, og at kongen hadde latt ham fare, og at han hadde draget bort i fred. 
10:3:24: Da gikk Joab inn til kongen og sa: Hvad har du gjort? Abner kom til dig; hvorfor lot du ham da fare sin vei? 
10:3:25: Du kjenner da Abner, Ners sønn, og vet at han er kommet for å narre dig og for å få rede på all din ferd og alt det du gjør. 
10:3:26: Så gikk Joab bort igjen fra David og sendte nogen folk avsted som skulde hente Abner, og de førte ham tilbake fra Sira-brønnen; men David visste ikke noget om det. 
10:3:27: Da nu Abner kom tilbake til Hebron, tok Joab ham til side midt inn i porten for å tale med ham i ro; og der stakk han ham i underlivet, så han døde; det var for å hevne sin bror Asaels blod. 
10:3:28: Da David siden fikk høre dette, sa han: Jeg og mitt kongedømme er for evig uten skyld for Herren i Abners, Ners sønns blod. 
10:3:29: La det komme over Joabs hode og over hele hans fars hus, og la det i Joabs hus aldri fattes nogen som har flod eller er spedalsk, eller nogen som går med krykke, eller som faller for sverdet, eller som mangler brød. 
10:3:30: Således myrdet Joab og hans bror Abisai Abner, fordi han hadde drept deres bror Asael ved Gibeon under striden. 
10:3:31: Men David sa til Joab og alt folket som var hos ham: Sønderriv eders klær og bind sekk om eder og gå klagende foran Abner! Og kong David gikk selv efter båren. 
10:3:32: Så begravde de Abner i Hebron, og kongen gråt høit ved Abners grav, og alt folket gråt. 
10:3:33: Og kongen kvad denne klagesang over Abner: Skulde da Abner dø som en niding dør? 
10:3:34: Dine hender var ikke bundet og dine føtter ikke lagt i lenker; som en faller for ugjerningsmenn, så falt du. - Og alt folket blev ved å gråte over ham. 
10:3:35: Så kom alt folket for å få David til å ta mat til sig mens det ennu var dag. Men David svor: Gud la det gå mig ille både nu og siden om jeg smaker brød eller noget annet før solen går ned! 
10:3:36: Og alt folket forstod det, og de syntes godt om det; alt det kongen gjorde, syntes folket godt om. 
10:3:37: Og på den dag skjønte alt folket og hele Israel at det ikke var kongen som var ophavsmann til drapet på Abner, Ners sønn. 
10:3:38: Og kongen sa til sine menn: Vet I ikke at en høvding og stor mann er falt i Israel på denne dag? 
10:3:39: Men jeg er ennu svak og bare salvet til konge, og disse menn, Serujas sønner, er mektigere enn jeg. Herren gjengjelde den som gjør ondt, det onde han gjør! 
10:4:1: Da Sauls sønn hørte at Abner var død i Hebron, falt han rent sammen, og hele Israel blev forferdet. 
10:4:2: Hos Sauls sønn var der to menn som var høvedsmenn for streif-flokker; den ene hette Ba'ana og den andre Rekab; de var sønner av Rimmon fra Be'erot og hørte til Benjamins barn. For Be'erot regnedes også til Benjamin - 
10:4:3: be'erotittene var flyktet til Gitta'im, og der har de holdt til helt til denne dag. 
10:4:4: Jonatan, Sauls sønn, hadde en sønn som var lam i begge føtter; han var fem år gammel da tidenden om Saul og Jonatan kom fra Jisre'el, og den kvinne som passet ham, tok ham og flyktet; men idet hun skyndte sig avsted, falt han ned og blev lam. Hans navn var Mefiboset*. # <* 2SA 9, 3. kalles også Meribba'al, 1KR 8, 34.> 
10:4:5: Be'erotitten Rimmons sønner, Rekab og Ba'ana, tok ut og kom på det heteste av dagen til Isbosets hus, mens han hvilte middag. 
10:4:6: De lot som de skulde hente hvete, og kom således helt inn i huset og stakk ham så i underlivet. Derefter slapp Rekab og hans bror Ba'ana bort. 
10:4:7: De kom inn i huset mens han lå på sin seng i sitt sovekammer, og stakk ham ihjel og hugg hodet av ham; så tok de hans hode og gikk hele natten gjennem ødemarken. 
10:4:8: Og de kom med Isbosets hode til David i Hebron og sa til kongen: Se, her er hodet av Isboset, sønn til Saul, din fiende som stod dig efter livet; således har Herren på denne dag gitt min herre kongen hevn over Saul og hans ætt. 
10:4:9: Men David svarte Rekab og hans bror Ba'ana, be'erotitten Rimmons sønner, og sa til dem: Så sant Herren lever, han som har utfridd mig av all trengsel: 
10:4:10: Den som kom til mig med tidende om at Saul var død, og som selv mente at han kom med en god tidende, ham grep jeg og lot ham drepe i Siklag - ham som jeg skulde ha gitt lønn for god tidende, 
10:4:11: hvor meget mere nu, da ugudelige menn har myrdet en rettferdig mann i hans hus og på hans leie - skulde jeg ikke nu kreve hans blod av eders hånd og utrydde eder av landet? 
10:4:12: Så bød David sine folk, og de slo dem ihjel og hugg hendene og føttene av dem og hengte dem op ved dammen i Hebron; men Isbosets hode tok de og begravde i Abners grav i Hebron. 
10:5:1: Så kom alle Israels stammer til David i Hebron og sa: Her er vi; vi er av samme kjød og blod som du. 
10:5:2: Allerede for lenge siden, da Saul var konge over oss, var det du som førte Israel ut og inn, og Herren sa til dig: Du skal vokte mitt folk Israel, og du skal være fyrste over Israel. 
10:5:3: Og alle Israels eldste kom til kongen i Hebron, og kong David gjorde en pakt med dem i Hebron for Herrens åsyn; og de salvet David til konge over Israel. 
10:5:4: David var tretti år gammel da han blev konge, og han regjerte i firti år. 
10:5:5: I Hebron regjerte han over Juda i syv år og seks måneder, og i Jerusalem regjerte han i tre og tretti år over hele Israel og Juda. 
10:5:6: Så drog kongen og hans menn til Jerusalem mot jebusittene, som bodde der i landet; men de sa til David: Du kommer aldri inn her; blinde og lamme skal drive dig bort med de ord: David kommer aldri inn her. 
10:5:7: Men David inntok allikevel Sions borg, det er Davids stad. 
10:5:8: Den dag sa David: Hver den som slår jebusittene og trenger frem til vannledningen og slår de lamme og blinde som hater David [han skal være høvding og fører]. Derav kommer det ord: En blind og en lam skal ikke komme inn i huset. 
10:5:9: David tok sin bolig i borgen og kalte den Davids stad; og David bygget rundt omkring fra Milo og innover. 
10:5:10: David blev større og større, og Herren, hærskarenes Gud, var med ham. 
10:5:11: Og Hiram, kongen i Tyrus, skikket sendemenn til David med sedertre, og han sendte tømmermenn og stenhuggere; og de bygget et hus for David. 
10:5:12: Og David forstod at Herren hadde stadfestet hans kongedømme over Israel, og at han hadde hevet hans rike høit for sitt folk Israels skyld. 
10:5:13: David tok ennu flere medhustruer og hustruer fra Jerusalem efterat han var kommet fra Hebron, og han fikk ennu flere sønner og døtre. 
10:5:14: Dette er navnene på de sønner han fikk i Jerusalem: Sammua og Sobab og Natan og Salomo 
10:5:15: og Jibbar og Elisua og Nefeg og Jafia 
10:5:16: og Elisama og Eljada og Elifelet. 
10:5:17: Da filistrene hørte at David var blitt salvet til konge over Israel, drog alle filistrene ut for å søke efter David; og da David hørte det, drog han ned til borgen. 
10:5:18: Og filistrene kom og spredte sig utover i Refa'im-dalen. 
10:5:19: Da spurte David Herren: Skal jeg dra ut mot filistrene? Vil du gi dem i min hånd? Og Herren svarte: Dra ut! Jeg vil gi filistrene i din hånd. 
10:5:20: Så kom David til Ba'al-Perasim, og der slo han dem, og han sa: Herren har brutt igjennem mine fiender foran mig, som vann bryter igjennem. Derfor blev dette sted kalt Ba'al-Perasim*. # <* gjennembryteren.> 
10:5:21: Der lot de efter sig sine avgudsbilleder, og David og hans menn tok dem med sig. 
10:5:22: Men filistrene drog ut ennu en gang og spredte sig utover i Refa'im-dalen. 
10:5:23: Og David spurte Herren, men han sa: Du skal ikke dra dit op; gå omkring så du kommer bakenfor dem, og gå så på dem midt for baka-trærne, 
10:5:24: og når du hører lyden av skritt i baka-trærnes topper, da skynd dig! For da har Herren draget ut foran dig for å slå filistrenes hær. 
10:5:25: David gjorde således som Herren hadde befalt ham; og han slo filistrene og forfulgte dem fra Geba til bortimot Geser. 
10:6:1: David samlet atter alt utvalgt mannskap i Israel - tretti tusen mann. 
10:6:2: Og David og alt folket som var hos ham, tok ut og drog fra Ba'ale-Juda* for å føre Guds ark op derfra, den som kalles med Herrens navn - med Herrens, hærskarenes Guds navn, han som troner på kjerubene**. # <* d.e. Kirjat-Jearim, 1SA 7, 1. 1KR 13, 6. Josva 15, 9. 60.> # <** 1SA 4, 4.> 
10:6:3: De satte Guds ark på en ny vogn og førte den bort fra Abinadabs hus, som lå på haugen; og Ussa og Ahjo, Abinadabs sønner, kjørte den nye vogn. 
10:6:4: Således førte de Guds ark bort fra Abinadabs hus, som lå på haugen, og fulgte med den, og Ahjo gikk foran arken. 
10:6:5: Og David og hele Israels hus lekte for Herrens åsyn til alle slags strengelek av cypresstre, både citarer og harper og trommer og bjeller og cymbler. 
10:6:6: Da de kom til Nakons treskeplass, rakte Ussa sin hånd ut og tok fatt i Guds ark; for oksene var blitt ustyrlige. 
10:6:7: Da optendtes Herrens vrede mot Ussa, og Gud slo ham der for hans forseelse, så han døde der ved Guds ark. 
10:6:8: Men David blev ille til mote, fordi Herren hadde slått Ussa ned; derfor er dette sted blitt kalt Peres-Ussa* like til denne dag. # <* d.e. Ussas nedbrytelse.> 
10:6:9: Den dag blev David opfylt av frykt for Herren og sa: Hvorledes skulde Herrens ark kunne komme inn til mig? 
10:6:10: Og David vilde ikke flytte Herrens ark op til sig i Davids stad, men lot den føre bort til gittitten Obed-Edoms hus. 
10:6:11: Herrens ark blev stående i gittitten Obed-Edoms hus i tre måneder, og Herren velsignet Obed-Edom og alt hans hus. 
10:6:12: Så kom det nogen og fortalte kong David at Herren hadde velsignet Obed-Edoms hus og alt det han hadde, for Guds arks skyld. Da tok David avsted og førte Guds ark fra Obed-Edoms hus op til Davids stad med stor glede. 
10:6:13: Og da de som bar Herrens ark, hadde gått seks skritt frem, ofret han okser og gjøkalver. 
10:6:14: Og David danset av all makt for Herrens åsyn - han hadde en livkjortel av lerret på. 
10:6:15: Således førte David og hele Israels hus Herrens ark op med fryderop og basunklang. 
10:6:16: Da nu Herrens ark kom inn i Davids stad, så Mikal, Sauls datter, ut igjennem vinduet, og hun så kong David hoppe og danse for Herrens åsyn, og hun foraktet ham i sitt hjerte. 
10:6:17: Så førte de da Herrens ark inn og satte den på sitt sted midt i det telt som David hadde reist for den, og David ofret brennoffer for Herrens åsyn og takkoffer. 
10:6:18: Og da David var ferdig med å ofre brennofferet og takkofferne, velsignet han folket i Herrens, hærskarenes Guds navn. 
10:6:19: Og han utdelte til alt folket, til hver enkelt av hele Israels mengde, både mann og kvinne, et brød og et stykke kjøtt og en rosinkake. Så drog alt folket hjem, hver til sitt hus. 
10:6:20: Da David kom hjem igjen for å velsigne sitt hus, gikk Mikal, Sauls datter, ut imot ham og sa: Hvor høit Israels konge er blitt æret idag - han som idag har blottet sig for øinene på sine tjeneres piker, som løse folk pleier å gjøre! 
10:6:21: Da sa David til Mikal: For Herrens åsyn, han som utvalgte mig fremfor din far og fremfor hele hans hus og satte mig til fyrste over Herrens folk, over Israel - for Herrens åsyn har jeg danset; 
10:6:22: og jeg vil gjøre mig ennu ringere enn så og bli liten i mine egne øine, og de tjenestepiker du talte om, sammen med dem vil jeg æres. 
10:6:23: Men Mikal, Sauls datter, blev barnløs til sin dødsdag. 
10:7:1: Som nu kongen bodde i sitt hus, og Herren hadde latt ham få ro for alle hans fiender rundt omkring, 
10:7:2: sa han til profeten Natan: Se, jeg bor i et hus av sedertre, mens Guds ark bor mellem telttepper. 
10:7:3: Natan sa til kongen: Gjør du bare alt det du har i sinne! For Herren er med dig. 
10:7:4: Men samme natt kom Herrens ord til Natan, og det lød således: 
10:7:5: Gå og si til min tjener David: Så sier Herren: Skulde du bygge mig et hus til å bo i? 
10:7:6: Jeg har jo ikke bodd i hus like fra den dag jeg førte Israels barn op fra Egypten, og til denne dag, men jeg vandret om i et telt, i et tabernakel. 
10:7:7: Hvor jeg så vandret om med alle Israels barn, har jeg vel nogensinne talt således til nogen av Israels stammer som jeg satte til å vokte mitt folk Israel: Hvorfor har I ikke bygget mig et hus av sedertre? 
10:7:8: Derfor skal du si således til min tjener David: Så sier Herren, hærskarenes Gud: Jeg tok dig fra havnegangen, hvor du gikk bak fårene, forat du skulde være fyrste over mitt folk Israel. 
10:7:9: Og jeg var med dig overalt hvor du gikk, og utryddet alle dine fiender for dig, og jeg har latt dig vinne et stort navn, så stort som de størstes navn på jorden. 
10:7:10: Og jeg har gjort i stand et bosted for mitt folk Israel, og jeg har plantet det der, så det bor i sitt hjem og ikke uroes mere, og urettferdige mennesker ikke plager det lenger som før, 
10:7:11: like fra den dag jeg satte dommere over mitt folk Israel, og jeg har latt dig få ro for alle dine fiender. Og nu forkynner Herren dig at Herren vil bygge dig et hus. 
10:7:12: Når dine dagers tall er fullt, og du hviler hos dine fedre, da vil jeg efter dig opreise din sønn som skal utgå av ditt liv, og jeg vil grunnfeste hans kongedømme. 
10:7:13: Han skal bygge et hus for mitt navn, og jeg vil trygge hans kongetrone til evig tid. 
10:7:14: Jeg vil være hans far, og han skal være min sønn. Når han gjør det som ondt er, vil jeg tukte ham med menneskers ris og med menneskebarns plager; 
10:7:15: men min miskunnhet skal ikke vike fra ham, således som jeg lot den vike fra Saul, han som jeg lot vike for dig. 
10:7:16: Fast skal ditt hus og ditt kongedømme stå til evig tid for ditt åsyn; din trone skal være grunnfestet til evig tid. 
10:7:17: Alle disse ord og hele dette syn bar Natan frem for David. 
10:7:18: Da gikk kong David inn og blev der inne for Herrens åsyn, og han sa: Hvem er jeg, Herre, Herre, og hvad er mitt hus, at du har ført mig hertil? 
10:7:19: Og enda var dette for lite i dine øine, Herre, Herre, og du talte også om din tjeners hus langt frem i tiden. Og dette er loven for menneskene, Herre, Herre! 
10:7:20: Hvad skal så David mere si til dig? Du kjenner jo din tjener, Herre, Herre! 
10:7:21: For ditt ords skyld og efter ditt hjerte har du gjort alt dette store og forkynt det for din tjener. 
10:7:22: Derfor er du stor, Herre Gud! Det er ingen som du, og det er ingen Gud foruten dig, efter alt det vi har hørt med våre ører. 
10:7:23: Og hvor er det på jorden et eneste folk som ditt folk - som Israel - et folk som Gud kom og fridde ut, så det skulde være hans eget folk, for å gjøre sig et navn og for å gjøre dette store for eder og forferdelige gjerninger for ditt land, for ditt folks skyld som du fridde ut fra Egypten, fra hedningefolk og deres guder? 
10:7:24: Og du dannet dig ditt folk Israel, så det skulde være ditt folk til evig tid, og du, Herre, er blitt deres Gud. 
10:7:25: Så opfyll nu, Herre Gud, for evig tid det ord du har talt over din tjener og hans hus, og gjør som du har sagt! 
10:7:26: Da skal ditt navn bli stort til evig tid, så folk skal si: Herren, hærskarenes Gud, er Gud over Israel. Og din tjener Davids hus skal være grunnfestet for ditt åsyn. 
10:7:27: For du, Herre, hærskarenes Gud, Israels Gud, har åpenbaret for din tjener at du vil bygge mig et hus. Derfor har din tjener fått frimodighet til å bede denne bønn til dig. 
10:7:28: Og nu, Herre, Herre, du er Gud, og dine ord skal bli sannhet, og du har lovt din tjener denne lykke. 
10:7:29: Så la det nu behage dig å velsigne din tjeners hus, så det må bli stående for ditt åsyn til evig tid! For du, Herre, Herre, har talt, og for din velsignelses skyld skal din tjeners hus bli velsignet i all evighet. 
10:8:1: Derefter slo David filistrene og ydmyket dem, og David tok hovedstadens tømmer ut av filistrenes hender. 
10:8:2: Han slo også moabittene og målte dem med en snor - han lot dem legge sig ned på jorden og målte så to snorlengder med folk som skulde drepes, og en full snorlengde med folk som skulde få leve; og moabittene blev Davids tjenere og måtte svare ham skatt. 
10:8:3: Likeledes slo David Hadadeser, sønn av Rehob, kongen i Soba, da han drog avsted for å gjenvinne sin makt ved elven*. # <* Eufrat.> 
10:8:4: Og David tok fra ham tusen og syv hundre hestfolk og tyve tusen mann fotfolk, og David lot skjære fotsenene over på alle vognhestene; bare hundre vognhester sparte han. 
10:8:5: Syrerne fra Damaskus kom for å hjelpe Hadadeser, kongen i Soba; men David slo to og tyve tusen mann av dem. 
10:8:6: Og David la krigsmannskap i det damaskenske Syria, og syrerne blev Davids tjenere og måtte svare ham skatt. Således hjalp Herren David overalt hvor han drog frem. 
10:8:7: David tok de gullskjold som Hadadesers tjenere hadde båret, og førte dem til Jerusalem. 
10:8:8: Og fra Hadadesers byer Betah og Berotai tok kong David en stor mengde kobber. 
10:8:9: Da To'i, kongen i Hamat, hørte at David hadde slått hele Hadadesers hær, 
10:8:10: sendte han sin sønn Joram til kong David for å hilse på ham og ønske ham til lykke, fordi han hadde stridt mot Hadadeser og slått ham; for Hadadeser hadde jevnlig ført krig mot To'i. Og han* hadde med sig sølvkar og gullkar og kobberkar. # <* Joram.> 
10:8:11: Også dem helliget kong David til Herren sammen med det sølv og gull han hadde helliget av hærfanget fra alle de hedningefolk han hadde lagt under sig: 
10:8:12: fra Syria og fra Moab og fra Ammons barn og fra filistrene og fra amalekittene, og av hærfanget fra Hadadeser, sønn av Rehob, kongen i Soba. 
10:8:13: Og da David vendte tilbake efter å ha slått syrerne, vant han sig et navn i Salt-dalen ved å slå atten tusen mann*. # <* nemlig edomitter.> 
10:8:14: Og han la krigsmannskap i Edom; i hele Edom la han krigsmannskap, og alle edomittene blev Davids tjenere. Således hjalp Herren David overalt hvor han drog frem. 
10:8:15: Så regjerte nu David over hele Israel, og David gjorde rett og rettferdighet mot hele sitt folk. 
10:8:16: Joab, sønn av Seruja, var høvding over hæren, og Josafat, sønn av Akilud, historieskriver; 
10:8:17: Sadok, sønn av Akitub, og Akimelek, sønn av Abjatar, var prester, og Seraja statsskriver; 
10:8:18: Benaja, Jojadas sønn, var høvding over livvakten, og Davids sønner var prester*. # <* d.e. kongens nærmeste, 1KR 18, 17.> 
10:9:1: David sa: Er det ennu nogen tilbake av Sauls hus, så jeg kan gjøre vel mot ham for Jonatans skyld? 
10:9:2: Nu var der i Sauls hus en tjener som hette Siba; han blev kalt til David, og kongen sa til ham: Er du Siba? Han svarte: Ja, din tjener heter så. 
10:9:3: Da sa kongen: Er det ikke ennu nogen tilbake av Sauls hus, så jeg kunde gjøre Guds miskunnhet mot ham? Siba svarte: Det er ennu en sønn av Jonatan, en som er lam i begge føtter. 
10:9:4: Kongen spurte: Hvor er han? Siba svarte: Han er i huset hos Makir, sønn av Ammiel, i Lo-Debar. 
10:9:5: Så sendte kong David bud og hentet ham fra Makirs, Ammiels sønns hus i Lo-Debar. 
10:9:6: Og da Mefiboset, sønn av Jonatan, Sauls sønn, kom inn til David, kastet han sig ned med ansiktet mot jorden. Og David sa: Mefiboset! Han svarte: Her er din tjener. 
10:9:7: David sa til ham: Vær ikke redd! Jeg vil gjøre vel mot dig for Jonatans, din fars skyld og gi dig tilbake all Sauls, din fars jordeiendom, og du skal alltid ete ved mitt bord. 
10:9:8: Da kastet han sig ned og sa: Hvad er din tjener, at du lar ditt øie falle på en død hund som mig? 
10:9:9: Så kalte kongen på Siba, Sauls tjener, og sa til ham: Alt som har hørt Saul og hele hans hus til, har jeg gitt din herres sønn. 
10:9:10: Og du skal dyrke jorden for ham, du og dine sønner og dine tjenere, og høste inn for ham, så din herres sønn kan ha brød å ete, men selv skal Mefiboset, din herres sønn, alltid ete ved mitt bord. Siba hadde femten sønner og tyve tjenere. 
10:9:11: Da sa Siba til kongen: Din tjener skal i alle måter gjøre således som min herre kongen befaler sin tjener. Men Mefiboset skal ete ved mitt bord som en av kongens sønner [sa kongen]. 
10:9:12: Mefiboset hadde en liten sønn som hette Mika, og alle som bodde i Sibas hus, var Mefibosets tjenere. 
10:9:13: Men selv bodde Mefiboset i Jerusalem; for han åt alltid ved kongens bord; og han var lam i begge sine føtter. 
10:10:1: Så hendte det at Ammons barns konge døde, og Hanun, hans sønn, blev konge i hans sted. 
10:10:2: Da sa David: Jeg vil vise Hanun, Nahas' sønn, vennskap, likesom hans far viste mig vennskap. Og David sendte nogen av sine tjenere for å trøste ham i hans sorg over sin far. Så kom da Davids tjenere til Ammons barns land. 
10:10:3: Da sa Ammons barns høvdinger til Hanun, sin herre: Tror du det er for å ære din far at David har sendt folk som skal trøste dig? Mon det ikke heller er for å utforske byen og utspeide den og så ødelegge den at David har sendt sine tjenere til dig? 
10:10:4: Da tok Hanun Davids tjenere og raket av halvdelen av deres skjegg og skar av deres klær midt på like til setet og lot dem så fare. 
10:10:5: Da dette blev meldt David, sendte han folk som skulde møte mennene, for de var grovelig vanæret; og kongen lot si: Bli i Jeriko til eders skjegg er vokset ut igjen, og kom så tilbake! 
10:10:6: Da nu Ammons barn så at de hadde gjort sig forhatt hos David, sendte de bud og leide syrerne fra Bet-Rehob og syrerne fra Soba, tyve tusen mann fotfolk, og kongen i Ma'aka med tusen mann og tolv tusen mann fra Tob*. # <* DMR 11, 3.> 
10:10:7: Da David hørte dette, sendte han Joab avsted med hele hæren, de krigsvante menn. 
10:10:8: Og Ammons barn rykket ut og stilte sig i fylking foran byporten; men syrerne fra Soba og Rehob og mennene fra Tob og Ma'aka stod for sig selv på marken. 
10:10:9: Da Joab så at han hadde fienden mot sig både foran og bak, valgte han ut en del av alt det utvalgte mannskap i Israel og stilte dem op mot syrerne. 
10:10:10: Men resten av folket overgav han til sin bror Abisai og stilte dem op mot Ammons barn. 
10:10:11: Og han sa: Dersom syrerne blir mig for sterke, skal du komme mig til hjelp, og dersom Ammons barn blir dig for sterke, skal jeg komme og hjelpe dig. 
10:10:12: Vær modig, og la oss vise oss modige i striden for vårt folk og for vår Guds byer, så får Herren gjøre hvad som er godt i hans øine! 
10:10:13: Så rykket Joab frem med sine folk til strid mot syrerne, og de flyktet for ham. 
10:10:14: Og da Ammons barn så at syrerne flyktet, så flyktet også de for Abisai og drog sig inn i byen. Da vendte Joab tilbake fra striden mot Ammons barn og kom til Jerusalem. 
10:10:15: Da syrerne så at de var blitt slått av Israel, samlet de sig igjen. 
10:10:16: Og Hadadeser sendte bud at de syrere som bodde på hin side av elven*, skulde rykke ut; de kom til Helam, og Sobak, høvdingen over Hadadesers hær, førte dem. # <* Eufrat.> 
10:10:17: Da dette blev meldt David, samlet han hele Israel og gikk over Jordan og kom til Helam; og syrerne stilte sig op mot David og stred mot ham. 
10:10:18: Men syrerne flyktet for Israel, og David drepte blandt syrerne mannskapet på syv hundre vogner og firti tusen hestfolk, og Sobak, deres hærfører, såret han, så han døde der. 
10:10:19: Og da alle kongene som stod under Hadadeser, så at de var blitt slått av Israel, gjorde de fred med Israel og blev deres tjenere; og syrerne vågde ikke å hjelpe Ammons barn mere. 
10:11:1: Året efter, ved den tid da kongene pleier å dra ut i krig, sendte David Joab avsted med sine tjenere og hele Israel, og de herjet Ammons barns land og kringsatte Rabba. Men David blev i Jerusalem. 
10:11:2: Så hendte det en aften at David stod op fra sitt leie og gikk omkring på kongehusets tak; da fikk han fra taket se en kvinne som badet sig; og kvinnen var meget fager av utseende. 
10:11:3: David sendte bud og spurte sig for om kvinnen, og de sa: Det er jo Batseba, datter av Eliam og hetitten Urias hustru. 
10:11:4: Da sendte David bud og lot henne hente; hun kom til ham, og han lå hos henne like efterat hun hadde renset sig fra sin urenhet*; så gikk hun hjem igjen. # <* 2SA 11, 2.> 
10:11:5: Kvinnen blev fruktsommelig; og hun sendte bud om det til David og lot si: Jeg er fruktsommelig. 
10:11:6: Da sendte David det bud til Joab: Send hetitten Uria til mig! Og Joab sendte Uria til David. 
10:11:7: Da Uria kom til ham, spurte David ham om det stod vel til med Joab og med krigsfolkene, og hvorledes det gikk med krigen. 
10:11:8: Så sa David til Uria: Gå ned til ditt hus og tvett dine føtter! Og da Uria gikk ut av kongens hus, blev det sendt en rett mat efter ham fra kongens bord. 
10:11:9: Men Uria la sig ved inngangen til kongens hus sammen med alle sin herres tjenere og gikk ikke ned til sitt hus. 
10:11:10: Da det blev meldt David at Uria ikke var gått ned til sitt hus, sa David til ham: Kommer du ikke fra reisen? Hvorfor er du da ikke gått ned til ditt hus? 
10:11:11: Da svarte Uria: Arken og Israel og Juda bor i løvhytter, og min herre Joab og min herres tjenere ligger i leir på åpen mark, og jeg skulde gå inn i mitt hus og ete og drikke og ligge hos min hustru! Så sant du lever, så sant din sjel lever: Det gjør jeg ikke! 
10:11:12: Da sa David til Uria: Bli her også idag, så vil jeg imorgen la dig fare. Og Uria blev i Jerusalem den dag og dagen efter. 
10:11:13: Og David bad ham til sig, og han åt og drakk hos ham, og han drakk ham drukken; og om aftenen gikk han ut og la sig på sitt leie sammen med sin herres tjenere; men til sitt hus gikk han ikke ned. 
10:11:14: Morgenen efter skrev David et brev til Joab og sendte det med Uria. 
10:11:15: Og i brevet skrev han således: Sett Uria lengst frem, der hvor striden er hårdest, og dra eder tilbake fra ham, så han blir slått og dør! 
10:11:16: Mens nu Joab lå og voktet på byen, satte han Uria på et sted hvor han visste at der var djerve menn. 
10:11:17: Og da mennene i byen drog ut og stred mot Joab, falt nogen av krigsfolket - av Davids menn - og hetitten Uria døde også. 
10:11:18: Da sendte Joab bud til David med tidende om alt som hadde hendt i krigen. 
10:11:19: Og han bød sendebudet: Når du har talt ut til kongen om alt det som har hendt i krigen, 
10:11:20: og det skulde hende at kongens vrede optendes, og han sier til dig: Hvorfor gikk I så nær inn til byen under striden? Visste I ikke at de vilde skyte ned fra muren? 
10:11:21: Hvem var det som drepte Abimelek, Jerubbesets sønn? Var det ikke en kvinne som kastet en kvernsten ned på ham fra muren i Tebes, så han døde? Hvorfor gikk I så nær inn til muren? - så skal du si: Også din tjener hetitten Uria er død. 
10:11:22: Så tok sendebudet avsted og kom og meldte David alt det Joab hadde pålagt ham. 
10:11:23: Og budet sa til David: Mennene fikk overtaket over oss og drog ut på marken mot oss; men så trengte vi dem tilbake like til byporten. 
10:11:24: Da skjøt bueskytterne fra muren ned på dine tjenere, og det falt nogen av kongens tjenere; også din tjener hetitten Uria døde. 
10:11:25: Da sa David til budet: Så skal du si til Joab: Ta dig ikke nær av dette! For sverdet fortærer snart en, snart en annen; hold kraftig ved med å stride mot byen og riv den ned! Og sett du mot i ham! 
10:11:26: Da Urias hustru hørte at Uria, hennes mann var død, sørget hun over sin ektefelle. 
10:11:27: Men da sørgetiden var til ende, sendte David bud og hentet henne til sitt hus, og hun blev hans hustru og fødte ham en sønn. Men det som David hadde gjort, var ondt i Herrens øine. 
10:12:1: Og Herren sendte Natan til David. Da han kom inn til ham, sa han til ham: Det var to menn i en by, en rik og en fattig. 
10:12:2: Den rike hadde småfe og storfe i mengdevis; 
10:12:3: men den fattige hadde ikke annet enn et eneste lite lam, som han hadde kjøpt og fødd op; det vokste op hos ham sammen med hans barn; det åt av hans brød og drakk av hans beger og lå i hans fang og var som en datter for ham. 
10:12:4: Så kom det en veifarende mann til den rike; men han nente ikke å ta noget av sitt småfe eller storfe og lage det til for den reisende som var kommet til ham, men tok den fattige manns lam og laget det til for den mann som var kommet til ham. 
10:12:5: Da optendtes Davids vrede høilig mot den mann, og han sa til Natan: Så sant Herren lever: Den mann som har gjort dette, er dødsens, 
10:12:6: og lammet skal han betale firedobbelt, fordi han gjorde dette og ikke viste barmhjertighet. 
10:12:7: Da sa Natan til David: Du er mannen. Så sier Herren, Israels Gud: Jeg salvet dig til konge over Israel, og jeg fridde dig ut av Sauls hånd, 
10:12:8: og jeg gav dig din herres hus og din herres hustruer i din favn, og jeg gav dig Israels og Judas hus, og var det for lite, så vilde jeg ha gitt dig ennu mere, både det ene og det annet. 
10:12:9: Hvorfor har du foraktet Herrens ord og gjort hvad som er ondt i hans øine? Hetitten Uria har du slått med sverdet; hans hustru har du tatt til hustru for dig selv, og ham har du drept med Ammons barns sverd. 
10:12:10: Så skal nu sverdet aldri vike fra ditt hus, fordi du har foraktet mig og tatt hetitten Urias hustru til hustru for dig selv. 
10:12:11: Så sier Herren: Se, jeg lar ulykke komme over dig fra ditt eget hus; jeg vil ta dine hustruer for dine øine og gi dem til en annen mann, og han skal ligge hos dine hustruer, så solen her er vidne til det. 
10:12:12: For det du gjorde, gjorde du i lønndom, men jeg vil gjøre dette for hele Israels øine og midt på lyse dagen. 
10:12:13: Da sa David til Natan: Jeg har syndet mot Herren. Og Natan sa til David: Så har også Herren borttatt din synd; du skal ikke dø. 
10:12:14: Men fordi du ved denne gjerning har gitt Herrens fiender årsak til å spotte, så skal også den sønn du har fått, visselig dø. 
10:12:15: Så gikk Natan hjem igjen, og Herren slo barnet som David hadde fått med Urias hustru, så det blev meget sykt. 
10:12:16: Og David søkte Gud for barnets skyld, og David fastet strengt, og hver gang han gikk inn, blev han liggende på jorden hele natten. 
10:12:17: De eldste i hans hus kom og vilde reise ham op fra jorden; men han vilde ikke og åt ikke sammen med dem. 
10:12:18: På den syvende dag døde barnet; men Davids tjenere torde ikke fortelle ham at barnet var død; de tenkte: Mens barnet var i live, talte vi til ham, men han hørte ikke på oss; hvorledes kan vi da nu si til ham at barnet er død? Han kunde gjøre en ulykke på sig. 
10:12:19: Da David så at hans tjenere hvisket sig imellem, skjønte han at barnet var død; og han sa til sine tjenere: Er barnet død? De svarte: Ja, han er død. 
10:12:20: Da stod David op fra jorden og tvettet sig og salvet sig og skiftet klær og gikk inn i Herrens hus og tilbad. Så gikk han hjem igjen og bad om mat, og de satte mat frem for ham, og han åt. 
10:12:21: Da sa hans tjenere til ham: Hvorledes er det du bærer dig at? Mens barnet var i live, fastet du og gråt for ham; men nu som barnet er død, står du op og eter. 
10:12:22: Han svarte: Så lenge barnet var i live, fastet jeg og gråt; for jeg tenkte: Hvem vet om ikke Herren forbarmer sig over mig, så barnet blir i live? 
10:12:23: Men nu som han er død, hvorfor skulde jeg nu faste? Kan jeg hente ham tilbake igjen? Jeg går til ham, men han vender ikke tilbake til mig. 
10:12:24: David trøstet Batseba, sin hustru, og han gikk inn til henne og lå hos henne; og hun fødte en sønn, som han kalte Salomo*. Og Herren elsket ham. # <* d.e. den fredsommelige, 1KR 3, 5. 1KR 22, 9.> 
10:12:25: og han sendte bud med profeten Natan, og han kalte ham Jedidja*, for Herrens skyld. # <* d.e. Herrens elskede, 2SA 12, 24.> 
10:12:26: Joab stred mot Rabba i ammonittenes land og inntok kongestaden. 
10:12:27: Så sendte Joab bud til David og lot si: Jeg har stridt mot Rabba og inntatt vannbyen. 
10:12:28: Samle nu du resten av folket og leir dig mot byen og innta den, så det ikke blir jeg som inntar byen, og mitt navn blir nevnt over den*! # <* d.e. byen kalles den av mig inntatte.> 
10:12:29: Da samlet David alt folket og drog til Rabba; og han stred mot det og inntok det. 
10:12:30: Og han tok deres konges krone fra hans hode; den veide en talent gull, og der var på den en kostbar sten; nu kom den på Davids hode. Og det store hærfang han hadde tatt i byen, førte han med sig bort. 
10:12:31: Og folket som bodde der, førte han ut og la dem under sager og treskesleder av jern og jernøkser og lot dem gå gjennem teglovner. Så gjorde han med alle Ammons barns byer. Derefter vendte David og alt folket tilbake til Jerusalem. 
10:13:1: Nogen tid derefter hendte det som nu skal fortelles: Absalom, Davids sønn, hadde en fager søster som hette Tamar; henne fattet Davids sønn Amnon kjærlighet til. 
10:13:2: Og Amnon var så nedtrykt for sin søster Tamars skyld at han blev syk; for hun var jomfru, og det syntes Amnon umulig å gjøre henne noget. 
10:13:3: Men Amnon hadde en venn som hette Jonadab, en sønn av Davids bror Simea; og Jonadab var en meget klok mann. 
10:13:4: Han sa til ham: Hvorfor ser du så dårlig ut, kongesønn, morgen efter morgen? Vil du ikke si mig det? Da sa Amnon til ham: Jeg elsker Tamar, min bror Absaloms søster. 
10:13:5: Jonadab sa til ham: Legg dig i sengen og gjør dig syk, og når da din far kommer for å se til dig, så si til ham: La min søster Tamar komme og gi mig noget å ete og lage til maten for mine øine, så jeg ser på det og får maten av hennes hånd! 
10:13:6: Så la Amnon sig og gjorde sig syk, og da kongen kom for å se til ham, sa Amnon til kongen: La min søster Tamar komme og lage et par kaker, så jeg ser på det og kan få dem av hennes hånd! 
10:13:7: Da sendte David bud inn i huset til Tamar og lot si: Gå til din bror Amnons hus og lag til maten for ham! 
10:13:8: Og Tamar gikk til sin bror Amnons hus. Han lå til sengs, og hun tok deigen og eltet den og laget kaker, så han så på det, og stekte dem. 
10:13:9: Så tok hun pannen og slo dem ut foran ham; men han vilde ikke ete. Og Amnon sa: La alle gå ut! Da alle var gått ut, 
10:13:10: sa Amnon til Tamar: Bær maten inn i kammeret, så jeg kan få den av din hånd! Og Tamar tok kakene som hun hadde laget, og bar dem inn i kammeret til sin bror Amnon. 
10:13:11: Men da hun rakte ham dem og bad ham ete, tok han fatt i henne og sa til henne: Kom og ligg hos mig, min søster! 
10:13:12: Men hun sa til ham: Nei, min bror, krenk mig ikke! Slikt må ikke gjøres i Israel; Gjør ikke noget så skammelig! 
10:13:13: Hvor skulde da jeg gå hen med min skam? Og du vilde bli regnet blandt nidingene i Israel. Kjære, tal heller til kongen! Han nekter dig ikke å få mig. 
10:13:14: Men han vilde ikke høre på henne; og han blev henne for sterk og krenket henne og lå hos henne. 
10:13:15: Men derefter fattet Amnon et sterkt hat til henne - det hat han fikk til henne, var større enn den kjærlighet han hadde hatt til henne. Og Amnon sa til henne: Stå op og gå din vei! 
10:13:16: Da sa hun til ham: Vold mig ikke så stor en ulykke! Jag mig ikke bort! Det vilde være verre enn det annet du har gjort mig. Men han vilde ikke høre på henne. 
10:13:17: Han kalte på den gutt som gikk ham til hånde, og sa: Jag denne kvinne ut på gaten og steng døren efter henne! 
10:13:18: Hun hadde en sid kjortel på sig; for sådanne kåper brukte kongens døtre så lenge de var jomfruer. Hans tjener førte henne da ut på gaten og stengte døren efter henne. 
10:13:19: Men Tamar strødde aske på sitt hode og sønderrev den side kjortel hun hadde på sig, og hun la sin hånd på sitt hode og gikk skrikende sin vei. 
10:13:20: Da sa hennes bror Absalom til henne: Har din bror Amnon vært hos dig? Ti nu stille, min søster! Han er din bror; ta dig ikke så nær av dette! Så blev da Tamar i sin ulykke i sin bror Absaloms hus. 
10:13:21: Da kong David hørte om alt dette, blev han meget vred. 
10:13:22: Og Absalom talte ikke med Amnon, hverken ondt eller godt; for han hatet Amnon fordi han hadde krenket hans søster Tamar. 
10:13:23: To år derefter hadde Absalom fåreklipning i Ba'al-Hasor, som ligger ved Efra'im, og Absalom innbød alle kongens sønner. 
10:13:24: Absalom gikk inn til kongen og sa: Din tjener skal nu ha fåreklipning; vil ikke kongen og hans menn følge din tjener dit! 
10:13:25: Men kongen svarte: Nei, min sønn, vi vil ikke komme alle, forat vi ikke skal være dig til uleilighet. Og han nødde ham, men han vilde enda ikke gå med og ønsket ham lykke til. 
10:13:26: Da sa Absalom: Om du ikke vil, så la min bror Amnon følge med oss! Kongen spurte: Hvorfor skal han følge med dig? 
10:13:27: Men Absalom nødde ham inntil han lot Amnon og alle kongesønnene gå med ham. 
10:13:28: Og Absalom bød sine tjenere og sa: Se til, når Amnon er blitt vel til mote av vinen, og jeg sier til eder: Hugg Amnon ned! - så skal I drepe ham; vær ikke redde! Det er jo jeg som har befalt eder det! Vær modige og vis eder som djerve menn! 
10:13:29: Og Absaloms tjenere gjorde med Amnon som Absalom hadde befalt. Da stod alle kongesønnene op og satte sig på hver sitt muldyr og flyktet. 
10:13:30: Mens de ennu var på veien, kom det rykte til David: Absalom har slått alle kongens sønner ihjel, og ikke en eneste av dem er blitt tilbake. 
10:13:31: Da stod kongen op og sønderrev sine klær og kastet sig ned på jorden, og alle hans tjenere stod der med sønderrevne klær. 
10:13:32: Men Jonadab, sønn av Davids bror Simea, tok til orde og sa: Min herre må ikke tenke at de har drept alle de unge menn, kongens sønner. Det er bare Amnon som er død; for efter Absaloms egne ord har det vært avgjort helt fra den dag han krenket hans søster Tamar. 
10:13:33: Så må nu min herre kongen ikke la det gå sig til hjerte at de sier: Alle kongens sønner er død! Nei, det er bare Amnon som er død. 
10:13:34: Imidlertid flyktet Absalom. Da den mann som holdt vakt, så sig omkring, fikk han se mange folk som drog frem fra veien bak ham ved siden av fjellet. 
10:13:35: Da sa Jonadab til kongen: Der kommer kongens sønner; det er gått som din tjener sa. 
10:13:36: Ikke før hadde han talt ut, så kom kongens sønner, og de gråt høit, og kongen og alle hans menn brast også i lydelig gråt. 
10:13:37: Men Absalom flyktet og drog til Talmai, sønn av Ammihud, kongen i Gesur. Og David sørget over sin sønn hele tiden. 
10:13:38: Da nu Absalom var flyktet og hadde draget til Gesur, blev han der tre år. 
10:13:39: Dette avholdt kong David fra å dra ut imot Absalom; for han hadde trøstet sig over Amnons død. 
10:14:1: Men Joab, Serujas sønn, skjønte at kongens hjerte droges mot Absalom. 
10:14:2: Da sendte Joab bud til Tekoa og hentet derfra en klok kvinne, og han sa til henne: Lat som om du har sorg, og klæ dig i sørgeklær og salv dig ikke med olje, men te dig som en kvinne som alt i lang tid har sørget over en død, 
10:14:3: og gå så inn til kongen og tal således til ham - og Joab la henne ordene i munnen. 
10:14:4: Og kvinnen fra Tekoa talte til kongen - hun kastet sig ned for ham med ansiktet mot jorden og sa: Hjelp, konge! 
10:14:5: Kongen sa til henne: Hvad fattes dig? Hun svarte: Akk, jeg er enke; min mann er død. 
10:14:6: Og din tjenerinne hadde to sønner, og de to kom i trette med hverandre ute på marken, og det var ingen som kunde skille dem at; så slo den ene til den andre og drepte ham. 
10:14:7: Og nu har hele slekten reist sig mot din tjenerinne og sier: Kom hit med ham som slo sin bror ihjel, så vi kan ta hans liv til hevn for hans bror som han slo ihjel, og så vi også får ryddet arvingen av veien. Således kommer de til å utslukke den siste gnist jeg ennu har igjen, så de ikke levner min mann navn og efterkommere på jorden. 
10:14:8: Da sa kongen til kvinnen: Gå hjem igjen! Jeg skal ordne saken for dig. 
10:14:9: Og kvinnen fra Tekoa sa til kongen: På mig, herre konge, og på min fars hus faller skylden, men kongen og hans trone skal være uten skyld. 
10:14:10: Kongen sa: Taler nogen til dig, skal du føre ham til mig; så skal han ikke mere gjøre dig noget. 
10:14:11: Da sa hun: Kongen komme Herren sin Gud i hu, så ikke blodhevneren skal volde ennu mere ulykke, og de ikke får ryddet min sønn av veien. Han svarte: Så sant Herren lever: Det skal ikke falle et hår av din sønns hode til jorden. 
10:14:12: Da sa kvinnen: La din tjenerinne få tale ennu et ord til min herre kongen. Og han sa: Tal! 
10:14:13: Så sa kvinnen: Hvorfor tenker du da på slikt mot Guds folk? Efter det ord kongen har talt, er det som han selv var skyldig, når kongen ikke lar den han har støtt fra sig, komme tilbake. 
10:14:14: For alle må vi dø og bli som vann som er utøst på jorden og ikke kan samles op igjen, og Gud tar ikke livet, men tenker ut hvad som kan gjøres forat en som er støtt bort, ikke skal være bortstøtt fra ham. 
10:14:15: Og at jeg nu er kommet for å tale dette til min herre kongen, det er fordi folket skremte mig, og din tjenerinne tenkte da: Jeg vil tale til kongen; kanskje kongen vil gjøre efter sin tjenerinnes ord; 
10:14:16: kongen vil nok høre på mig og fri sin tjenerinne fra den manns hånd som vil utrydde både mig og min sønn av Guds arv. 
10:14:17: Og så tenkte din tjenerinne: Gid min herre kongens ord måtte bli mig til trøst, for min herre kongen er som en Guds engel til å høre både på godt og ondt! Så være da Herren din Gud med dig! 
10:14:18: Da tok kongen til orde og sa til kvinnen: Dølg ikke for mig det som jeg nu vil spørre dig om! Kvinnen sa: Tal, herre konge! 
10:14:19: Da sa kongen: Har ikke Joab sin hånd med i alt dette? Kvinnen svarte: Så sant du lever, herre konge: Ingen kan komme utenom noget av det min herre kongen taler, hverken til høire eller venstre. Det var din tjener Joab som påla mig dette og la alle disse ord i din tjenerinnes munn. 
10:14:20: For å gi saken et annet utseende har din tjener Joab gjort dette; men min herre er vis, som en Guds engel i visdom, så han vet alt som hender på jorden. 
10:14:21: Derefter sa kongen til Joab: Vel! jeg skal gjøre så*! Ta nu avsted og før den unge mann Absalom tilbake! # <* d.e. opfylle ditt ønske.> 
10:14:22: Da kastet Joab sig ned med ansiktet mot jorden og velsignet kongen, og han sa: Idag skjønner din tjener at jeg har funnet nåde for dine øine, herre konge, siden kongen vil gjøre efter sin tjeners ord. 
10:14:23: Så gjorde Joab sig rede og drog til Gesur og førte Absalom til Jerusalem. 
10:14:24: Men kongen sa: La ham ta hjem til sitt eget hus, men han skal ikke komme for mine øine. Så tok Absalom hjem til sitt eget hus og kom ikke for kongens øine. 
10:14:25: Men så fager en mann som Absalom fantes det ikke i hele Israel, ingen som blev prist så høit; fra fotsåle til isse fantes det ikke lyte på ham. 
10:14:26: Og når han klippet håret på sitt hode - og det gjorde han hver gang året var til ende, fordi håret blev ham for tungt, og han måtte klippe det - så veide han sitt hår, og det veide to hundre sekel efter kongens vekt. 
10:14:27: Absalom fikk tre sønner og én datter som hette Tamar; hun var en fager kvinne. 
10:14:28: Absalom bodde i Jerusalem i to år men kom ikke for kongens øine. 
10:14:29: Så sendte Absalom bud til Joab for å få ham til å gå til kongen, men han vilde ikke komme til ham; og han sendte bud ennu en gang, men han vilde ikke komme da heller. 
10:14:30: Da sa han til sine tjenere: Se, her ved siden av mig har Joab et jordstykke, og der har han bygg; gå og sett ild på det! Og Absaloms tjenere satte ild på jordstykket. 
10:14:31: Da stod Joab op og gikk til Absaloms hus, og han sa til ham: Hvorfor har dine tjenere satt ild på det jordstykke som hører mig til? 
10:14:32: Absalom svarte: Jeg sendte jo bud til dig og bad dig komme hit, så jeg kunde sende dig til kongen og si ham fra mig: Hvorfor er jeg kommet hit fra Gesur? Det hadde vært bedre for mig om jeg ennu var der. Og nu vil jeg komme for kongens øine, og er det nogen brøde hos mig, så får han drepe mig. 
10:14:33: Da gikk Joab inn til kongen og sa det til ham, og han kalte Absalom til sig, og han kom inn til kongen; og han kastet sig på sitt ansikt til jorden for kongen, og kongen kysset Absalom. 
10:15:1: Nogen tid efter la Absalom sig til vogn og hester og femti mann som løp foran ham. 
10:15:2: Og Absalom pleide å stå tidlig op og stille sig ved siden av veien til porten; og så ofte det kom en mann som hadde en sak han vilde føre frem for kongen og få dom i, kalte Absalom på ham og spurte: Hvilken by er du fra? Når han så svarte: Din tjener er fra den og den av Israels stammer, 
10:15:3: sa Absalom til ham: Din sak er god og rett; men hos kongen er det ingen som hører på dig. 
10:15:4: Så sa Absalom: Bare de vilde sette mig til dommer i landet! Da skulde hver mann som hadde en trette eller sak, komme til mig, og jeg skulde hjelpe ham til sin rett. 
10:15:5: Og når nogen gikk frem og vilde bøie sig for ham, rakte han ut sin hånd og tok fatt i ham og kysset ham. 
10:15:6: Således gjorde Absalom med hele Israel når de kom til kongen for å få dom, og Absalom stjal Israels menns hjerte. 
10:15:7: Da det nu var gått fire år, sa Absalom til kongen: La mig få dra til Hebron og innfri det løfte jeg har gjort Herren! 
10:15:8: For din tjener gjorde et løfte dengang jeg bodde i Gesur i Syria, og sa: Fører Herren mig tilbake igjen til Jerusalem, så vil jeg ofre til Herren. 
10:15:9: Kongen svarte: Gå i fred! Så gjorde han sig rede og drog til Hebron. 
10:15:10: Og Absalom sendte speidere omkring i alle Israels stammer og sa: Når I hører basunen lyde, så skal I si: Absalom er blitt konge i Hebron. 
10:15:11: Sammen med Absalom gikk to hundre menn fra Jerusalem, som var innbudt, og som gikk med i all troskyldighet og ikke visste av nogen ting. 
10:15:12: Og mens Absalom bar frem offerne, hentet han gilonitten Akitofel, Davids rådgiver, fra hans by Gilo. Og sammensvergelsen blev sterk, og det samlet sig flere og flere folk hos Absalom. 
10:15:13: Så kom det nogen med bud til David og sa: Hver manns hjerte i Israel henger ved Absalom. 
10:15:14: Da sa David til alle sine menn som var hos ham i Jerusalem: Kom, la oss flykte! Ellers kan vi ikke berge oss for Absalom. Skynd eder avsted, så ikke han skal skynde sig og nå oss og føre ulykke over oss og slå byen med sverdets egg. 
10:15:15: Kongens tjenere svarte: Alt hvad du vil, herre konge, er dine tjenere rede til å gjøre. 
10:15:16: Så tok kongen ut, og hele hans hus fulgte ham; bare ti medhustruer lot kongen bli tilbake for å ta vare på huset. 
10:15:17: Så tok da kongen ut, og alt folket fulgte ham, og de stanset ved det siste hus. 
10:15:18: Og alle hans menn og hele livvakten drog frem ved siden av ham, og alle gittittene, seks hundre mann som hadde fulgt ham fra Gat, drog frem foran kongen. 
10:15:19: Da sa kongen til gittitten Ittai: Hvorfor går du og med oss? Vend tilbake og bli hos kongen! For du er en fremmed og tilmed bortført fra ditt hjemsted. 
10:15:20: Igår kom du, og idag skulde jeg la dig vanke om med oss, jeg som ikke vet hvor min vei kan falle. Vend tilbake og før dine brødre tilbake med dig, og Guds miskunnhet og trofasthet være med eder! 
10:15:21: Men Ittai svarte kongen og sa: Så sant Herren lever, og så sant min herre kongen lever: Der hvor min herre kongen er, enten det bærer til død eller til liv, der og intet annet sted vil din tjener være. 
10:15:22: Da sa David til Ittai: Vel, så dra med oss! Så drog gittitten Ittai frem med alle sine menn og alle de barn han hadde med sig. 
10:15:23: Og hele landet gråt høit, da alt folket drog frem, og kongen gikk over bekken Kidron, og alt folket gikk og over og tok veien bortimot ørkenen. 
10:15:24: Da kom også Sadok med alle levittene; de bar Guds pakts-ark, og de satte Guds ark ned, og på samme tid gikk Abjatar op*, inntil alt folket hadde draget ut av byen. # <* til Oljeberget.> 
10:15:25: Da sa kongen til Sadok: Før Guds ark tilbake til byen! Finner jeg nåde for Herrens øine, så fører han mig tilbake og lar mig se den og dens bolig; 
10:15:26: men dersom han sier således: Jeg har ikke velbehag i dig - så er jeg her; han får gjøre med mig som han synes! 
10:15:27: Og kongen sa til presten Sadok: Forstår du mig? Vend tilbake til byen i fred, og din sønn Akima'as og Abjatars sønn Jonatan - begge eders sønner - skal være med eder! 
10:15:28: Husk på at jeg vil dryge på moene i ørkenen inntil det kommer et ord fra eder med budskap til mig. 
10:15:29: Så førte Sadok og Abjatar Guds ark tilbake til Jerusalem, og de blev der. 
10:15:30: Men David gikk gråtende opefter Oljeberget med tilhyllet hode og barfotet, og alt folket som var med ham, hadde og tilhyllet sine hoder og gikk gråtende opover. 
10:15:31: Da David fikk bud om at Akitofel var blandt de sammensvorne hos Absalom, sa han: Gjør Akitofels råd til dårskap, Herre! 
10:15:32: Da nu David kom til toppen, hvor han vilde tilbede Gud, fikk han se arkitten Husai som kom ham i møte med sønderrevet kjortel og jord på sitt hode. 
10:15:33: David sa til ham: Dersom du drar videre med mig, vil du bare bli til byrde for mig; 
10:15:34: men dersom du vender tilbake til byen og sier til Absalom: Jeg vil være din tjener, konge! Jeg har før vært din fars tjener, men nu vil jeg være din tjener - så vil du gjøre Akitofels råd til intet for mig. 
10:15:35: Der har du jo og prestene Sadok og Abjatar hos dig. Alt du får høre fra kongens hus, skal du melde prestene Sadok og Abjatar. 
10:15:36: Begge deres sønner, Akima'as, Sadoks sønn, og Jonatan, Abjatars sønn, er der hos dem; med dem skal I sende mig bud om alt det I får høre. 
10:15:37: Så kom da Husai, Davids venn, til byen, og på samme tid kom også Absalom til Jerusalem. 
10:16:1: Da David var kommet et lite stykke fremover fra toppen, fikk han se Siba, Mefibosets tjener, som kom ham i møte med et par asener med kløv; på dem var det to hundre brød og hundre rosinkaker og hundre stykker sommerfrukt og en skinnsekk med vin. 
10:16:2: Da sa kongen til Siba: Hvad vil du med dette? Siba svarte: Asenene skal kongens husfolk ha til å ride på, og brødet og sommerfruktene skal være til mat for tjenerne, og vinen til drikke for dem som blir utmattet i ørkenen. 
10:16:3: Kongen sa: Men hvor er din herres sønn? Siba svarte: Han er fremdeles i Jerusalem; han sa: Idag vil Israels hus gi mig igjen min fars kongedømme. 
10:16:4: Da sa kongen til Siba: Du skal få alt det som Mefiboset har. Og Siba sa: Jeg kaster mig til jorden; la mig finne nåde for min herre kongens øine! 
10:16:5: Da kong David kom til Bahurim, kom det ut derfra en mann som var i slekt med Sauls hus og hette Sime'i, Gera's sønn; han kom bannende 
10:16:6: og kastet med sten efter David og alle kong Davids tjenere, mens alt folket og alle de djerveste krigsmenn gikk med ham, både på høire og venstre side. 
10:16:7: Og Sime'i bante ham med de ord: Bort, bort med dig, din blodhund, din niding! 
10:16:8: Herren har nu latt komme igjen over dig alt det blod som har flytt i Sauls hus, han i hvis sted du blev konge, og Herren har gitt kongedømmet til din sønn Absalom; se, nu er du kommet i den ulykke du har fortjent; for en blodhund er du. 
10:16:9: Da sa Abisai, Serujas sønn, til kongen: Hvorfor skal denne døde hund banne min herre kongen? La mig gå der bort og slå hodet av ham! 
10:16:10: Men kongen sa: Hvad har jeg med eder å gjøre, I Serujas sønner? Når han banner, og når Herren har sagt til ham: Bann David! - hvem tør da si: Hvorfor gjorde du det? 
10:16:11: Og David sa til Abisai og til alle sine tjenere: Se, min sønn, som er utgått av mitt liv, står mig efter livet; hvor meget mere ikke da denne benjaminitt! La ham bare banne! For Herren har befalt ham det. 
10:16:12: Kanskje Herren ser til mig i min nød, så Herren gir mig lykke til gjengjeld for den forbannelse som har rammet mig idag. 
10:16:13: Så gikk David og hans menn frem efter veien mens Sime'i gikk oppe i lien jevnsides med ham, og han gikk og bante, og han kastet med sten og sand mens han gikk der jevnsides med ham. 
10:16:14: Og kongen og alt folket som var med ham, drog inn i Ajefim og hvilte der. 
10:16:15: Imidlertid var Absalom med alt sitt folk, Israels menn, kommet til Jerusalem og Akitofel var med ham. 
10:16:16: Da nu arkitten Husai, Davids venn, kom til Absalom, sa Husai til Absalom: Kongen leve! Kongen leve! 
10:16:17: Da sa Absalom til Husai: Er dette din kjærlighet til din venn? Hvorfor drog du ikke med din venn? 
10:16:18: Husai svarte: Nei, den som Herren og dette folk og alle Israels menn har utvalgt, ham vil jeg tilhøre, og hos ham vil jeg bli. 
10:16:19: Og for det annet: Hvem skulde jeg tjene? Skulde jeg ikke tjene hos hans sønn? Likesom jeg har tjent hos din far, således vil jeg og tjene dig. 
10:16:20: Så sa Absalom til Akitofel: Gi nu I et råd og si hvad vi skal gjøre! 
10:16:21: Akitofel svarte: Gå inn til din fars medhustruer som han lot bli tilbake for å ta vare på huset! Når da hele Israel får høre at du har gjort dig forhatt hos din far, så vil motet økes hos alle som er med dig. 
10:16:22: Så reiste de et telt for Absalom på taket, og Absalom gikk inn til sin fars medhustruer for hele Israels øine. 
10:16:23: I den tid gjaldt et råd som Akitofel gav, like så meget som om en hadde spurt Guds ord til råds; så meget gjaldt hvert råd av Akitofel både hos David og hos Absalom. 
10:17:1: Så sa Akitofel til Absalom: La mig få velge ut tolv tusen mann, så vil jeg bryte op og sette efter David inatt! 
10:17:2: Da kan jeg komme over ham mens han er utmattet og motløs, og forferde ham, og alt folket som er med ham, vil flykte, og så kan jeg slå kongen alene, 
10:17:3: og jeg vil føre alt folket tilbake til dig. At alle vender tilbake, avhenger av at det går således med den mann du søker efter; alt folket kan da være i fred. 
10:17:4: Dette råd syntes Absalom og alle Israels eldste godt om. 
10:17:5: Men Absalom sa: Kall også arkitten Husai til mig, så vi kan få høre hvad han har å si. 
10:17:6: Da Husai kom til Absalom, sa Absalom til ham: Så og så har Akitofel talt; skal vi gjøre som han sier? Hvis ikke, så tal du! 
10:17:7: Da sa Husai til Absalom: Det er ikke noget godt råd Akitofel denne gang har gitt. 
10:17:8: Og Husai sa: Du kjenner din far og hans menn og vet at de er djerve stridsmenn og gramme i hu som en binne i skogen som de har røvet ungene fra; og din far er en krigsmann og holder sig ikke hos folkene om natten. 
10:17:9: Nu holder han sig sikkert skjult i en eller annen hule eller et annet sted; om han da straks i begynnelsen overfaller dine folk, så vil hver den som hører det, si: Det har vært et stort mannefall blandt de folk som følger Absalom; 
10:17:10: og om det så er en djerv mann som har et hjerte som en løve, så vil han dog bli motløs; for hele Israel vet at din far er en helt, og at de som er med ham, er djerve folk. 
10:17:11: Derfor er dette mitt råd: La hele Israel samle sig om dig, fra Dan til Be'erseba, som sanden ved havet i mengde; og dra selv med i striden. 
10:17:12: Når vi så støter på ham et eller annet sted hvor han er å finne, så vil vi falle over ham som duggen faller over jorden, og det skal ikke bli en eneste i live av dem, hverken han selv eller nogen av de menn som er med ham. 
10:17:13: Og dersom han drar sig tilbake til en av byene, så skal hele Israel legge rep omkring den by, og vi skal dra den ned i dalen til det ikke finnes en sten igjen der. 
10:17:14: Da sa Absalom og hver Israels mann: Arkitten Husais råd er bedre enn Akitofels råd. Men det var Herren som hadde laget det så for å gjøre Akitofels gode råd til intet, så Herren kunde la ulykken komme over Absalom. 
10:17:15: Så sa Husai til prestene Sadok og Abjatar: Så og så har Akitofel rådet Absalom og Israels eldste, og så og så har jeg rådet. 
10:17:16: Send derfor hastig bud til David om det og si: Bli ikke natten over på moene i ørkenen, men dra heller over, forat ikke kongen og alt folket som er med ham, skal gå til grunne! 
10:17:17: Jonatan og Akima'as holdt sig i En-Rogel, og en tjenestepike gikk jevnlig med bud til dem, og de gikk igjen med bud til kong David; for de torde ikke gå inn i byen og la sig se der. 
10:17:18: Men en gutt fikk se dem og sa det til Absalom. Da skyndte de sig avsted begge to. I Bahurim gikk de inn til en mann der som hadde en brønn på sin gård; i den steg de ned. 
10:17:19: Og hans hustru tok og bredte et dekke over brønnen og strødde gryn ovenpå, så ingen kunde merke noget. 
10:17:20: Da så Absaloms tjenere kom inn i huset til konen og spurte: Hvor er Akima'as og Jonatan? svarte konen: De gikk over den lille bekken her. Så lette de efter dem, men fant dem ikke og vendte så tilbake til Jerusalem. 
10:17:21: Men så snart de var gått bort, steg de andre op av brønnen og gikk og bar budet frem til kong David og sa til ham: Gjør eder rede og sett hastig over vannet! For det og det råd har Akitofel gitt mot eder. 
10:17:22: Da brøt de op, både David og alt folket som var med ham, og satte over Jordan; da morgenen lyste frem, manglet det ikke en eneste mann som ikke var gått over Jordan. 
10:17:23: Men da Akitofel så at hans råd ikke blev fulgt, salte han sitt asen og tok ut og drog hjem til sin by og beskikket sitt hus og hengte sig; og han døde og blev begravet i sin fars grav. 
10:17:24: David var alt kommet til Mahana'im da Absalom satte over Jordan med alle Israels menn. 
10:17:25: Absalom hadde satt Amasa over hæren i stedet for Joab; Amasa var sønn av en mann som hette Jitra, en jisre'elitt som hadde gått inn til Abigal, datter av Nahas og søster til Seruja, Joabs mor. 
10:17:26: Og Israel og Absalom leiret sig i Gileads land. 
10:17:27: Da David kom til Mahana'im, hendte det at Sobi, sønn av Nahas, fra Rabba i Ammons barns land, og Makir, sønn av Ammiel, fra Lo-Debar, og gileaditten Barsillai fra Rogelim 
10:17:28: kom med senger og fat og lerkar og hvete og bygg og mel og ristet korn og bønner og linser og ristede belgfrukter 
10:17:29: og honning og melk og småfe og oster av kumelk til føde for David og hans folk, for de tenkte: Folkene er blitt sultne og trette og tørste i ørkenen. 
10:18:1: David mønstret folket som var med ham, og satte høvedsmenn over dem, nogen over tusen og nogen over hundre. 
10:18:2: Så sendte David folket avsted, en tredjedel under Joab, en tredjedel under Abisai, Serujas sønn, Joabs bror, og en tredjedel under gittitten Ittai, og kongen sa til folket: Jeg vil og dra ut med eder. 
10:18:3: Men folket sa: Du skal ikke dra ut; for om vi flykter, bryr ingen sig om oss, og om halvdelen av oss faller, bryr heller ingen sig om oss; men du er så god som ti tusen av oss, og nu er det bedre at du er rede til å komme oss til hjelp fra byen. 
10:18:4: Da sa kongen til dem: Jeg skal gjøre som I synes. Så stilte kongen sig ved siden av porten, og alt folket drog ut, hundrevis og tusenvis. 
10:18:5: Og kongen bød Joab og Abisai og Ittai og sa: Far varsomt med den unge mann - med Absalom! Og alt folket hørte på at kongen gav alle høvedsmennene dette påbud om Absalom. 
10:18:6: Så drog folket ut i marken mot Israel, og det kom til slag i Efra'imskogen. 
10:18:7: Der blev Israels folk slått av Davids menn, og det blev et stort mannefall der den dag - tyve tusen mann. 
10:18:8: Og striden bredte sig ut over hele landet deromkring, og skogen fortærte den dag flere av folket enn sverdet fortærte. 
10:18:9: Absalom kom like imot Davids menn; han red på sitt muldyr, og muldyret kom inn under de tette grener på en stor terebinte, så hans hode hang fast i terebinten, og han blev hengende mellem himmel og jord; for muldyret som han satt på, løp sin vei. 
10:18:10: Dette fikk en mann se, meldte det til Joab og sa: Jeg så Absalom henge i terebinten der borte. 
10:18:11: Da sa Joab til mannen som meldte ham dette: Men når du har sett det, hvorfor slo du ham da ikke til jorden med det samme? Så hadde det vært min skyldighet å gi dig ti sekel sølv og et belte. 
10:18:12: Men mannen sa til Joab: Om jeg så fikk mig tilveid tusen sekel sølv midt op i hånden, vilde jeg ikke rekke ut min hånd mot kongens sønn; for vi hørte alle på at kongen bød dig og Abisai og Ittai og sa: Vær varsom med den unge mann, med Absalom, hvem det så er av eder! 
10:18:13: Men om jeg hadde gått svikefullt frem og tatt hans liv, så blir jo ingen ting skjult for kongen, og du vilde nok holde dig unda. 
10:18:14: Da sa Joab: Jeg kan ikke stå her med dig og hefte mig bort. Og han tok tre kastespyd i sin hånd og støtte dem i Absaloms hjerte, mens han ennu hang levende midt i terebinten. 
10:18:15: Så stilte ti unge menn - Joabs våbensvenner - sig rundt omkring og hugg til Absalom og drepte ham. 
10:18:16: Da støtte Joab i basunen, og folket holdt op med å forfølge Israel; for Joab holdt folket tilbake. 
10:18:17: Og de tok Absalom og kastet ham i en stor grøft i skogen og reiste en stor stenrøs over ham; og hele Israel flyktet, hver til sitt hjem. 
10:18:18: Men Absalom hadde, mens han levde, tatt og reist sig den støtte som står i Kongedalen; for han sa: Jeg har ingen sønn som kan holde oppe minnet om mitt navn. Han gav støtten navn efter sig selv, og den er blitt kalt Absaloms minnesmerke inntil denne dag. 
10:18:19: Akima'as, Sadoks sønn, sa til Joab: La mig få springe avsted til kongen med det gledelige budskap at Herren har hjulpet ham til hans rett mot hans fiender! 
10:18:20: Men Joab sa til ham: Ikke vil du idag bli en mann med gledelig budskap; en annen dag kan du komme med gledelig budskap; men idag vilde du ikke komme med noget gledelig budskap, siden kongens sønn er død. 
10:18:21: Derefter sa Joab til kusitten*: Gå og meld kongen hvad du har sett! Og kusitten bøide sig for Joab og sprang avsted. # <* en etioper blandt Davids folk.> 
10:18:22: Men Akima'as, Sadoks sønn, tok atter til orde og sa til Joab: Det får gå som det vil, men la også mig få springe avsted efter kusitten! Joab sa: Hvorfor vil du endelig gjøre det, min sønn? Du har jo ikke noget gledelig budskap som du kunde få lønn for. 
10:18:23: Han svarte: Det får gå som det vil, jeg springer avsted. Og han sa til ham: Så spring da! Da sprang Akima'as bort efter veien gjennem Jordansletten og kom forbi kusitten. 
10:18:24: Imidlertid satt David mellem begge portene, og vekteren gikk på taket over porten, bort på muren, og så utover. Da fikk han se en mann som kom springende alene. 
10:18:25: Vekteren ropte og meldte det til kongen. Da sa kongen: Er han alene, så er det et gledelig budskap i hans munn. Og han kom nærmere og nærmere. 
10:18:26: Da så vekteren en annen mann som kom springende, og vekteren ropte til portneren og sa: Se, det kommer en mann springende alene. Da sa kongen: Han kommer og med gledelig budskap. 
10:18:27: Og vekteren sa: Jeg synes den første springer så likt Akima'as, Sadoks sønn. Kongen sa: Det er en god mann; han kommer med godt budskap. 
10:18:28: Og Akima'as ropte til kongen: Fred! Og han kastet sig på sitt ansikt til jorden for kongen og sa: Lovet være Herren din Gud, som har gitt de menn som løftet sin hånd mot min herre kongen, i din makt! 
10:18:29: Og kongen sa: Står det vel til med den unge mann - med Absalom? Akima'as svarte: Jeg så det var et stort opstyr, da Joab sendte kongens tjener* og mig, din tjener, avsted**; men jeg vet ikke hvad det var. # <* 2SA 18, 21.> # <** 2SA 18, 23.> 
10:18:30: Kongen sa: Gå til side og stå der! Så gikk han til side og blev stående der. 
10:18:31: Straks efter kom kusitten, og han sa: Min herre kongen motta det gledelige budskap at Herren idag har hjulpet dig til din rett mot alle dem som hadde reist sig imot dig! 
10:18:32: Kongen spurte kusitten: Står det vel til med den unge mann - med Absalom? Kusitten svarte: Gid det må gå min herre kongens fiender og alle som reiser sig imot dig og vil gjøre ondt, som det gikk den unge mann! 
10:18:33: Da blev kongen dypt rystet. Han gikk op på salen over porten og gråt, og mens han gikk, sa han så: Min sønn Absalom! Min sønn, min sønn Absalom! O, at jeg var død i ditt sted! Absalom, min sønn, min sønn! 
10:19:1: Folk kom og sa til Joab: Kongen gråter og sørger over Absalom. 
10:19:2: Og seieren blev den dag til sorg for alt folket, fordi folket den dag fikk høre at kongen var bedrøvet over sin sønn. 
10:19:3: Den dag stjal folket sig inn i byen, som folk stjeler sig hjem når de er skamfulle fordi de har flyktet i krigen. 
10:19:4: Men kongen hadde tilhyllet sitt ansikt og ropte med høi røst: Min sønn Absalom! Absalom, min sønn, min sønn! 
10:19:5: Da gikk Joab inn i huset til kongen og sa: Idag vanærer du alle dine menn, som idag har berget både ditt liv og dine sønners og døtres og dine hustruers og medhustruers liv; 
10:19:6: du elsker jo dem som hater dig, og hater dem som elsker dig; for idag har du vist at hverken høvedsmenn eller tjenere er til for dig; og jeg skjønner nu at om Absalom var i live og alle vi andre var død idag, da syntes du det var som det skulde. 
10:19:7: Stå nu op og gå ut og tal vennlig til dine menn! For jeg sverger ved Herren at går du ikke ut, så blir det ikke en mann hos dig natten over, og dette vil bli verre for dig enn alt det onde som er kommet over dig fra din ungdom av til nu. 
10:19:8: Da stod kongen op og satte sig i porten, og da alt folket fikk vite at kongen satt i porten, kom de alle og gikk frem for kongen. Men Israel hadde flyktet, hver mann til sitt hjem. 
10:19:9: Og alt folket i alle Israels stammer gikk i rette med hverandre og sa: Kongen har fridd oss av våre fienders hånd, og han har reddet oss fra filistrene, og nu er han flyktet ut av landet for Absalom. 
10:19:10: Men Absalom, som vi hadde salvet til konge over oss, er død i krigen; hvorfor sier I da ikke et ord om å føre kongen tilbake? 
10:19:11: Da sendte kong David bud til prestene Sadok og Abjatar med de ord: Tal til Judas eldste og si: Hvorfor er I de siste til å hente kongen tilbake til hans hus? For det var kommet kongen for øre i hans hus at hele Israel talte således. 
10:19:12: I er mine brødre, I er mitt eget kjød og blod; hvorfor er I da de siste til å hente kongen tilbake? 
10:19:13: Og til Amasa skal I si: Er du ikke mitt eget kjød og blod? Gud la det gå mig ille både nu og siden om ikke du alltid skal være min hærfører i stedet for Joab. 
10:19:14: Således bøide han alle Judas menns sinn og vant dem for sig alle som én, og de sendte det bud til kongen: Vend tilbake, du og alle dine menn! 
10:19:15: Så gav kongen sig på tilbakeveien og kom til Jordan. Da var Juda alt kommet til Gilgal for å dra kongen i møte og føre ham over Jordan. 
10:19:16: Benjaminitten Sime'i, Geras sønn, fra Bahurim, skyndte sig og drog ned med Judas menn for å møte kong David, 
10:19:17: og tusen mann av Benjamin var med ham, og Siba, som hadde vært tjener for Sauls hus, med sine femten sønner og tyve tjenere; og de satte skyndsomt over Jordan før kongen. 
10:19:18: Og ferjen fór over elven for å føre kongens hus over og være til hans rådighet i alle ting. Men Sime'i, Geras sønn, falt ned for kongen da han skulde fare over Jordan; 
10:19:19: og han sa til kongen: Herre, tilregn mig ikke min brøde og kom ikke i hu hvorledes din tjener forbrøt sig den dag da min herre konge drog ut fra Jerusalem! Kongen må ikke la det gå sig til hjerte! 
10:19:20: For din tjener vet at jeg har syndet; men se, idag er jeg den første av hele Josefs hus som er kommet hit ned for å møte min herre kongen. 
10:19:21: Men Abisai, Serujas sønn, tok til orde og sa: Skal ikke Sime'i late livet - han som har bannet Herrens salvede? 
10:19:22: Da sa David: Hvad har jeg med eder å gjøre, I Serujas sønner, at I idag skulde bli mine motstandere? Skulde nogen mann i Israel late livet idag? Jeg vet jo at jeg idag er blitt konge over Israel. 
10:19:23: Så sa kongen til Sime'i: Du skal ikke dø. Og kongen tilsvor ham det. 
10:19:24: Mefiboset, Sauls sønn, drog og ned for å møte kongen; han hadde ikke vasket sine føtter og ikke stelt sitt skjegg og ikke tvettet sine klær like fra den dag kongen drog bort, til den dag han kom tilbake med fred. 
10:19:25: Da nu hele Jerusalem kom kongen i møte, sa kongen til ham: Hvorfor fulgte du ikke med mig, Mefiboset? 
10:19:26: Han svarte: Herre konge! Min tjener narret mig; for din tjener sa: Jeg vil ha mitt asen salt, så jeg kan ride på det og følge med kongen; for din tjener er lam. 
10:19:27: Og han baktalte din tjener for min herre kongen. Men min herre kongen er som en Guds engel; gjør derfor som du synes. 
10:19:28: For hele min fars hus hadde ikke annet å vente av min herre kongen enn død, og dog gav du din tjener plass blandt dem som eter ved ditt bord; hvad rett har jeg da mere å kreve, og hvad mere har jeg å rope til kongen om? 
10:19:29: Kongen sa til ham: Hvorfor blir du ved å tale herom? Jeg sier: Du og Siba skal dele jordeiendommen. 
10:19:30: Da sa Mefiboset til kongen: Han kan gjerne få alt sammen, siden min herre kongen er kommet hjem igjen med fred. 
10:19:31: Gileaditten Barsillai var og kommet ned fra Rogelim og satte med kongen over Jordan for å ledsage ham et stykke på veien. 
10:19:32: Barsillai var meget gammel, åtti år; han hadde sørget for mat og drikke til kongen under hans ophold i Mahana'im; for han var en meget rik mann. 
10:19:33: Kongen sa da til Barsillai: Dra med mig, så vil jeg sørge for dig hjemme hos mig i Jerusalem. 
10:19:34: Barsillai svarte: Hvor mange dager og år kan jeg vel ennu ha å leve, at jeg skulde dra med kongen op til Jerusalem? 
10:19:35: Jeg er idag åtti år; kan jeg vel nu skjelne mellem godt og ondt, eller kan din tjener smake hvad jeg eter og drikker, eller kan jeg nu lenger høre på sangere og sangerinner? Hvorfor skulde da din tjener lenger være min herre kongen til byrde? 
10:19:36: Bare for en kort tid kan din tjener gå med kongen over Jordan; men hvorfor skulde kongen vise mig en sådan velgjerning? 
10:19:37: Kjære, la din tjener vende tilbake, så jeg kan dø i min egen by, hvor min fars og min mors grav er! Men se, her er din tjener Kimham; la ham få dra med min herre kongen, og gjør med ham som du synes! 
10:19:38: Da sa kongen: Kimham skal dra med mig, og jeg vil gjøre mot ham som du vil, og alt hvad du ønsker av mig, vil jeg gjøre for dig. 
10:19:39: Så drog da alt folket over Jordan, og kongen drog over; og kongen kysset Barsillai, og Barsillai velsignet ham og vendte tilbake til sitt hjem. 
10:19:40: Kongen fór over til Gilgal, og Kimham fór med ham; og hele Judas folk førte kongen over, og likeså halvdelen av Israels folk. 
10:19:41: Men da kom alle Israels menn til kongen og sa til ham: Hvorfor har våre brødre, Judas menn, stjålet dig bort og ført kongen og hans hus og alle Davids menn med ham over Jordan? 
10:19:42: Da tok alle Judas menn til orde og sa til Israels menn: Fordi kongen er oss nærmest. Og hvorfor er I harme for dette? Har vi vel tæret på kongens gods, eller har vi hatt nogen vinning av ham? 
10:19:43: Men Israels menn svarte Judas menn således: Ti ganger større del enn I har vi i den som er konge, og således også i David; hvorfor har I da ringeaktet oss? Og var det ikke vi som først talte om å hente vår konge tilbake? Men Judas menns svar var enda hårdere enn det Israels menn hadde talt. 
10:20:1: Nu traff det sig så at det på det sted var en illesinnet mann som hette Seba, sønn av Bikri, en benjaminitt. Han støtte i basunen og sa: Vi har ingen del i David og ingen lodd i Isais sønn; hver mann til sine telt, Israel! 
10:20:2: Da forlot hver mann av Israel David og fulgte Seba, Bikris sønn, men Judas menn hang ved sin konge og fulgte ham fra Jordan like til Jerusalem. 
10:20:3: Så kom David hjem til Jerusalem. Og kongen tok de ti medhustruer som han hadde latt bli tilbake for å ta vare på huset, og lot dem bo i et hus for sig selv under varetekt; han forsørget dem med det nødvendige, men gikk ikke inn til dem. Der satt de nu innestengt til sin dødsdag og levde hele resten av sitt liv som enker. 
10:20:4: Så sa kongen til Amasa: Kall Judas menn sammen for mig innen tre dager og møt så selv frem her! 
10:20:5: Amasa tok da avsted for å kalle Juda sammen; men han drygde lenger enn den tid som var fastsatt. 
10:20:6: Da sa David til Abisai: Nu kommer Seba, Bikris sønn, til å gjøre oss mere ondt enn Absalom; ta du din herres menn og sett efter ham! Bare han ikke alt har funnet faste byer og har undsloppet oss! 
10:20:7: Så drog da Joabs menn og livvakten og alle de djerveste stridsmenn ut efter ham; de drog ut fra Jerusalem for å sette efter Seba, Bikris sønn. 
10:20:8: De var nettop kommet til den store sten ved Gibeon, da kom Amasa imot dem. Joab hadde på sig kappen som var hans vanlige drakt, og utenpå den et belte med et sverd i skjeden, fastbundet over hans lender, og da han gikk frem, falt det ut. 
10:20:9: Og Joab sa til Amasa: Går det dig vel, bror? og grep med sin høire hånd fatt i Amasas skjegg for å kysse ham. 
10:20:10: Men Amasa tok sig ikke i vare for sverdet som Joab hadde i hånden, og Joab stakk ham med det i underlivet, så hans innvoller rant ut på jorden. Han stakk ham ikke mere enn denne ene gang, så var han død. Derefter satte Joab og hans bror Abisai efter Seba, Bikris sønn. 
10:20:11: Men en av Joabs unge menn blev stående hos Amasa og ropte: Hvem som holder med Joab, og som står på Davids side, følge Joab! 
10:20:12: Men Amasa lå veltet i sitt blod midt på landeveien, og da mannen så at alt folket blev stående. flyttet han Amasa fra landeveien bort på marken og kastet et klæde over ham, fordi han så at enhver som kom bort til ham, blev stående. 
10:20:13: Da han var ført bort fra landeveien, gikk hver mann forbi og fulgte Joab for å sette efter Seba, Bikris sønn. 
10:20:14: Han drog frem gjennem alle Israels stammer til Abel og Bet-Ma'aka og hele Berim; og de samlet sig og fulgte også efter ham. 
10:20:15: Og de kom og kringsatte ham* i Abel-Bet-Ma'aka og opkastet en voll mot byen; den støtte op mot yttermuren; og alt folket som var med Joab, var i ferd med å ødelegge muren for å få den til å falle. # <* Seba.> 
10:20:16: Da ropte en klok kvinne fra byen: Hør, hør! Si til Joab: Kom nærmere, så jeg kan få tale med dig! 
10:20:17: Da gikk han bort til henne, og kvinnen spurte: Er du Joab? Han svarte: Ja. Hun sa til ham: Hør din tjenerinnes ord! Han svarte: Jeg hører. 
10:20:18: Da sa hun: Før pleide folk å si så: En skal spørre sig for i Abel, og så utførte de det. 
10:20:19: Jeg er en av de fredsommelige og trofaste i Israel*; du søker å ødelegge en by, en mor i Israel, hvorfor vil du tilintetgjøre Herrens arv? # <* hun taler i byens navn.> 
10:20:20: Da svarte Joab og sa: Langt, langt være det fra mig at jeg skulde tilintetgjøre eller ødelegge. 
10:20:21: Det har sig ikke så. Men en mann fra Efra'im-fjellet som heter Seba, sønn av Bikri, har løftet sin hånd mot kongen, mot David; la mig bare få ham, så skal jeg dra bort fra byen. Da sa kvinnen til Joab: Hans hode skal bli kastet ut til dig over muren. 
10:20:22: Så gikk kvinnen med sitt kloke råd til alt folket, og de hugg hodet av Seba, Bikris sønn, og kastet det ut til Joab. Da støtte han i basunen, og de drog bort fra byen og spredte sig hver mann til sitt hjem; og Joab vendte tilbake til Jerusalem, til kongen. 
10:20:23: Joab var høvding over hele Israels hær, og Benaja, sønn av Jojada, var høvding over livvakten; 
10:20:24: Adoram hadde opsyn med dem som gjorde pliktarbeid, og Josafat, sønn av Akilud, var historieskriver; 
10:20:25: Seja var statsskriver, og Sadok og Abjatar var prester. 
10:20:26: Ja'iritten Ira var også prest* hos David. # <* likesom 2SA 8, 18.> 
10:21:1: I Davids tid var det engang en hungersnød som varte i tre år efter hverandre. Da søkte David Herrens åsyn, og Herren svarte: Det er fordi det hviler blodskyld på Saul og hans hus, siden han drepte gibeonittene. 
10:21:2: Kongen lot da gibeonittene kalle og talte med dem. Gibeonittene hørte ikke til Israels barn, men var av de amoritter som var blitt tilbake i landet, og Israels barn hadde svoret dem en ed, men Saul søkte å slå dem ihjel i sin nidkjærhet for Israels og Judas barn. 
10:21:3: David sa til gibeonittene: Hvad skal jeg gjøre for eder, og hvormed skal jeg gjøre soning, så I velsigner Herrens arv? 
10:21:4: Gibeonittene svarte: Vi krever ikke sølv eller gull hos Saul og hans hus, og vi har ikke rett til å drepe nogen mann i Israel. Han sa: Hvad I krever, vil jeg gjøre for eder. 
10:21:5: Og de sa til kongen: Den mann som ødela oss, og som hadde i sinne å utrydde oss, så det ikke skulde finnes nogen av oss igjen innen hele Israels landemerker - 
10:21:6: la oss av hans sønner få syv menn, så vil vi henge dem op for Herren i Gibea, hvor Saul, Herrens utvalgte, hørte hjemme. Kongen svarte: Jeg skal gi eder dem. 
10:21:7: Kongen sparte Mefiboset, sønn av Jonatan, Sauls sønn, for den eds skyld som de - David og Jonatan, Sauls sønn - hadde svoret hverandre ved Herren. 
10:21:8: Men de to sønner som Rispa, Ajas datter, hadde fått med Saul, Armoni og Mefiboset, og de fem sønner som Mikal*, Sauls datter, hadde fått med Adriel, sønn av meholatitten Barsillai, dem tok kongen # <* 2SA 3, 7. som også hette Merab, 1SA 18, 19.> 
10:21:9: og overgav dem til gibeonittene, og de hengte dem op på fjellet for Herrens åsyn; de omkom alle syv på en gang. Det var i de første dager av høsten, i begynnelsen av bygghøsten, de blev drept. 
10:21:10: Men Rispa, Ajas datter, tok sin sørgedrakt og bredte den ut på klippen fra høstens begynnelse inntil det strømmet vann ned på dem fra himmelen*; og hun lot ikke himmelens fugler få slå ned på dem om dagen eller markens ville dyr om natten. # <* de blev hengende inntil Gud gav regn igjen, 2SA 21, 1.> 
10:21:11: Da David fikk vite hvad Rispa, Ajas datter, Sauls medhustru, hadde gjort, 
10:21:12: tok han avsted og hentet Sauls og hans sønn Jonatans ben fra mennene i Jabes i Gilead, som hemmelig hadde tatt dem bort fra torvet i Betsan; der hadde filistrene hengt dem op den dag de slo Saul på Gilboa. 
10:21:13: Og da han hadde ført Sauls og hans sønn Jonatans ben op derfra, samlet de også benene av dem som var hengt, 
10:21:14: og de begravde Sauls og hans sønn Jonatans ben i Benjamins land, i Sela, i hans far Kis' grav. De gjorde alt det kongen hadde befalt. Og derefter hørte Gud landets bønn. 
10:21:15: Atter kom det til krig mellem filistrene og Israel; og David drog ned med sine menn, og de stred med filistrene. David blev trett, 
10:21:16: og Jisbo-Benob, som hørte til Rafas barn - han hadde en lanse som veide tre hundre sekel kobber, og han var omgjordet med et nytt sverd - han tenkte på å slå David ihjel. 
10:21:17: Men Abisai, Serujas sønn, kom ham til hjelp og slo filisteren og drepte ham. Da svor Davids menn og sa til ham: Du skal ikke mere dra ut med oss i striden, forat du ikke skal utslukke Israels schmelk. 
10:21:18: Siden blev det atter strid med filistrene; det var ved Gob; da slo husatitten Sibbekai Saf, som hørte til Rafas barn. 
10:21:19: Ennu en gang blev det strid med filistrene ved Gob, og betlehemitten Elhanan, sønn av Ja'are-Orgim, slo gittitten Goliat, som hadde et spyd hvis skaft var som en veverstang. 
10:21:20: Så kom det atter til strid ved Gat. Der var en høivoksen mann som hadde seks fingrer på hver hånd og seks tær på hver fot - i alt fireogtyve fingrer og tær; han var og en efterkommer av Rafa. 
10:21:21: Han hånte Israel; men Jonatan, sønn av Davids bror Simea, hugg ham ned. 
10:21:22: Disse fire var efterkommere av Rafa i Gat, og de falt for Davids og hans menns hånd. 
10:22:1: David kvad denne sang for Herren den dag da Herren hadde utfridd ham av alle hans fienders hånd og av Sauls hånd. 
10:22:2: Og han sa: Herren er min klippe og min festning og min redder, 
10:22:3: min klippefaste Gud, som jeg setter min lit til, mitt skjold og min frelses horn, min borg og min tilflukt, min frelser; fra vold frelser du mig. 
10:22:4: Jeg påkaller den Høilovede, Herren, og blir frelst fra mine fiender. 
10:22:5: Dødens bølger omspente mig, fordervelsens strømmer forferdet mig. 
10:22:6: Dødsrikets rep omgav mig, dødens snarer overfalt mig. 
10:22:7: I min trengsel påkalte jeg Herren, og jeg ropte til min Gud; han hørte fra sitt tempel min røst, og mitt skrik kom for hans ører. 
10:22:8: Da rystet og bevet jorden, himmelens grunnvoller skalv, og de rystet; for hans vrede var optendt. 
10:22:9: Det steg røk op av hans nese, og fortærende ild fra hans munn; glør brente ut av ham. 
10:22:10: Og han bøide himmelen og steg ned, og det var mørke under hans føtter. 
10:22:11: Og han fór på kjeruber og fløi, og han lot sig se på vindens vinger. 
10:22:12: Og han gjorde mørke til skjul rundt omkring sig, vannmasser, tykke skyer. 
10:22:13: Frem av glansen foran ham brente gloende kull. 
10:22:14: Herren tordnet fra himmelen, den Høieste lot sin røst høre. 
10:22:15: Og han utsendte piler og spredte dem* omkring - lyn og forvirret dem. # <* fiendene.> 
10:22:16: Da kom havets strømmer til syne; jordens grunnvoller blev avdekket ved Herrens trusel, for hans neses åndepust. 
10:22:17: Han rakte sin hånd ut fra det høie, han grep mig; han drog mig op av store vann. 
10:22:18: Han fridde mig ut fra min sterke fiende, fra mine avindsmenn; for de var mig for mektige. 
10:22:19: De overfalt mig på min motgangs dag; men Herren blev min støtte. 
10:22:20: Og han førte mig ut i fritt rum; han frelste mig, for han hadde behag i mig. 
10:22:21: Herren gjengjeldte mig efter min rettferdighet, han betalte mig efter mine henders renhet. 
10:22:22: For jeg tok vare på Herrens veier og vek ikke i ondskap fra min Gud; 
10:22:23: for alle hans lover hadde jeg for øie, og fra hans bud vek jeg ikke, 
10:22:24: og jeg var ulastelig for ham og voktet mig vel for min synd. 
10:22:25: Og Herren gjengjeldte mig efter min rettferdighet, efter min renhet for hans øine. 
10:22:26: Mot den fromme viser du dig from, mot den rettvise stridsmann viser du dig rettvis, 
10:22:27: mot den rene viser du dig ren, mot den forvendte viser du dig vrang*. # <* d.e. gjør du tvert imot hans ønske og forventning.> 
10:22:28: Elendige folk frelser du, men dine øine er mot de overmodige; dem fornedrer du. 
10:22:29: For du er mitt lys, Herre, og Herren opklarer mitt mørke. 
10:22:30: For ved dig stormer jeg løs på fiendeskarer, ved min Gud springer jeg over murer. 
10:22:31: Gud, hans vei er fullkommen; Herrens ord er rent, han er et skjold for alle dem som setter sin lit til ham. 
10:22:32: For hvem er Gud foruten Herren, og hvem er en klippe foruten vår Gud? 
10:22:33: Gud, han er mitt sterke vern og leder den ulastelige på hans vei. 
10:22:34: Han gir ham føtter likesom hindene og stiller mig på mine høider. 
10:22:35: Han oplærer mine hender til krig, så mine armer spenner kobberbuen. 
10:22:36: Og du gir mig din frelse til skjold, og din mildhet gjør mig stor. 
10:22:37: Du gjør rummet vidt for mine skritt under mig, og mine ankler vakler ikke. 
10:22:38: Jeg vil forfølge mine fiender og ødelegge dem, og jeg vender ikke tilbake før jeg har gjort ende på dem. 
10:22:39: Jeg gjør ende på dem og knuser dem, så de ikke kan reise sig; de faller under mine føtter. 
10:22:40: Og du omgjorder mig med kraft til krig, du bøier mine motstandere under mig. 
10:22:41: Og mine fiender lar du vende mig ryggen, mine avindsmenn utrydder jeg. 
10:22:42: De ser sig om, men der er ingen frelser - efter Herren, men han svarer dem ikke. 
10:22:43: Og jeg knuser dem som jordens støv; jeg sønderknuser dem, tramper dem ned som søle på gatene. 
10:22:44: Og du redder mig fra mitt folks kamper, du bevarer mig til å være hode for hedninger; folkeferd som jeg ikke kjenner, tjener mig. 
10:22:45: Fremmede kryper for mig; bare de hører om mig, blir de mig lydige. 
10:22:46: Fremmede visner bort og går bevende ut av sine borger. 
10:22:47: Herren lever, og priset er min klippe, og ophøiet er min frelses klippefaste Gud, 
10:22:48: den Gud som gir mig hevn og legger folkeferd under mig, 
10:22:49: og som fører mig ut fra mine fiender; over mine motstandere ophøier du mig, fra voldsmannen redder du mig. 
10:22:50: Derfor vil jeg prise dig, Herre, blandt hedningene og lovsynge ditt navn. 
10:22:51: Han gjør frelsen stor for sin konge, og han gjør miskunnhet mot sin salvede, mot David og mot hans ætt til evig tid. 
10:23:1: Dette er Davids siste ord: Så sier David, Isais sønn, så sier mannen som høit var satt, Jakobs Guds salvede og Israels liflige sanger: 
10:23:2: Herrens Ånd taler gjennem mig, og hans ord er på min tunge. 
10:23:3: Israels Gud har talt, til mig har Israels klippe sagt: Der skal være en hersker over menneskene, en rettferdig, en hersker i gudsfrykt; 
10:23:4: han skal være lik morgenens schmelk når solen går op, en morgen uten skyer, når ved solskinn og ved regn gresset spirer frem av jorden. 
10:23:5: For har ikke mitt hus det således med Gud? En evig pakt har han jo gjort med mig, ordnet i alle deler og trygget; all min frelse og alt hvad ham behager - skulde han ikke la det gro frem? 
10:23:6: Men de onde, de er alle sammen lik torner som ryddes bort, som folk ikke tar i med hånden, 
10:23:7: men kommer nogen nær dem, så væbner han sig med jern og spydskaft, og med ild brennes de op der hvor de står. 
10:23:8: Dette er navnene på Davids helter: Joseb-Bassebet, takmonitten, høvedsmann for drabantene; han svang sin lanse over åtte hundre falne på én gang. 
10:23:9: Efter ham kom Eleasar, sønn av Dodo, sønn av Ahohi; han var en av de tre helter som var med David da de hånte filistrene som var samlet der til strid. Da så Israels menn drog sig tilbake, 
10:23:10: reiste han sig og hugg inn på filistrene, til hans hånd blev så trett at den hang fast ved sverdet. Den dag gav Herren dem en stor seier, og da folket vendte om og fulgte ham, hadde de ikke annet å gjøre enn å plyndre de falne. 
10:23:11: Efter ham kom Samma, sønn av Age, hararitten. Filistrene hadde engang samlet sig til en hel flokk, og der var det et jorde som var fullt av linser. Da nu folket flyktet for filistrene, 
10:23:12: stilte han sig midt på jordet og berget det og slo filistrene, og Herren gav dem en stor seier. 
10:23:13: Engang da det led mot høsten, drog tre av de tretti høvdinger ned og kom til David i hulen ved Adullam. Filisterflokken lå i leir i Refa'imdalen. 
10:23:14: David var dengang selv i borgen, og filistrenes forpost var i Betlehem. 
10:23:15: Så kom det en brå lyst over David, og han sa: Hvem lar mig få vann å drikke av den brønn som er ved porten i Betlehem? 
10:23:16: Da brøt de tre helter sig igjennem filistrenes leir og øste vann op av brønnen ved Betlehems port og tok det med sig og kom til David med det; men han vilde ikke drikke det - han øste det ut for Herren. 
10:23:17: Og han sa: Det være langt fra mig, Herre, å gjøre dette! Skulde jeg drikke de menns blod som gikk avsted med fare for sitt liv? Og han vilde ikke drikke det. Dette gjorde de tre helter. 
10:23:18: Så var det Abisai, bror til Joab, sønn av Seruja; han var høvedsmann for drabantene. Han svang sitt spyd over tre hundre falne; og han hadde et navn blandt de tre. 
10:23:19: Fremfor de tretti var han visstnok æret og blev deres høvding; men til de tre nådde han ikke op. 
10:23:20: Så var det Benaja, sønn av Jojada, som var sønn av en djerv seierrik stridsmann fra Kabse'el; han slo Moabs to løvehelter. En dag det hadde snedd, steg han ned i en brønn og slo ihjel en løve. 
10:23:21: Likeledes slo han ihjel en egypter, en kjempestor mann; egypteren hadde et spyd i hånden; men han gikk ned mot ham med sin stav og rev spydet ut av hånden på egypteren og drepte ham med hans eget spyd. 
10:23:22: Dette gjorde Benaja, Jojadas sønn; han hadde et navn blandt de tre helter. 
10:23:23: Fremfor de tretti var han æret, men til de tre nådde han ikke op. David gav ham plass blandt sine fortrolige rådgivere. 
10:23:24: Asael, Joabs bror, var blandt de tretti; likeså Elhanan, sønn av Dodo, fra Betlehem, 
10:23:25: haroditten Samma; haroditten Elika; 
10:23:26: paltitten Heles; teko'itten Ira, sønn av Ikkes; 
10:23:27: anatotitten Abieser; husatitten Mebunnai; 
10:23:28: ahohitten Salmon; netofatitten Maharai; 
10:23:29: netofatitten Heleb, sønn av Ba'ana; Ittai, sønn av Ribai, fra Gibea i Benjamins stamme; 
10:23:30: piratonitten Benaja; Hiddai fra Ga'as-dalene; 
10:23:31: arbatitten Abi-Albon; barhumitten Asmavet; 
10:23:32: sa'albonitten Eljahba; Bene-Jasen; Jonatan; 
10:23:33: hararitten Samma; araritten Akiam, sønn av Sarar; 
10:23:34: Elifelet, sønn av Ahasbai, ma'akatittens sønn; gilonitten Eliam, sønn av Akitofel; 
10:23:35: karmelitten Hesro; arbitten Pa'arai; 
10:23:36: Jigal, sønn av Natan, fra Soba; gaditten Bani; 
10:23:37: ammonitten Selek; be'erotitten Naharai, Joabs, Serujas sønns våbensvenn; 
10:23:38: jitritten Ira; jitritten Gared; 
10:23:39: hetitten Uria - i alt syv og tretti. 
10:24:1: Men Herrens vrede optendtes atter* mot Israel, og han egget David op imot dem og sa: Gå avsted og tell Israel og Juda! # <* 2SA 21, 1 fg.> 
10:24:2: Da sa kongen til sin hærfører Joab, som var hos ham: Dra omkring i alle Israels stammer fra Dan like til Be'erseba og mønstre folket, så jeg kan få vite tallet på dem! 
10:24:3: Joab svarte: Herren din Gud legge hundre ganger så mange til dette folk som de er nu, så min herre kongen selv får se det! Men hvorfor har min herre kongen fått lyst til dette? 
10:24:4: Men kongen holdt fast ved sin befaling og gav ikke efter for Joab og hærførerne. Så drog da Joab og hærførerne ut i kongens tjeneste for å mønstre Israels folk. 
10:24:5: De satte over Jordan og leiret sig ved Aroer, på høire side av den by som ligger midt i Gads-dalen og bortimot Jaser. 
10:24:6: Så kom de til Gilead og til lavlandet, som nylig var inntatt; siden kom de til Dan-Ja'an* og så rundt om bortimot Sidon. # <* 1MO 14, 14.> 
10:24:7: Så kom de til Tyrus' festning og til alle hevittenes og kana'anittenes byer; og til sist drog de til sydlandet i Juda, til Be'erseba. 
10:24:8: Således drog de om i hele landet og kom efter ni måneder og tyve dager tilbake til Jerusalem. 
10:24:9: Og Joab opgav for kongen det tall som var utkommet ved folkemønstringen: I Israel var det åtte hundre tusen sterke menn som kunde dra sverd, og Judas menn var fem hundre tusen. 
10:24:10: Men samvittigheten slo David efterat han hadde tellet folket, og David sa til Herren: Jeg har syndet storlig med det jeg har gjort; men tilgi nu, Herre, din tjeners misgjerning; for jeg har båret mig meget uforstandig at. 
10:24:11: Da David stod op om morgenen, kom Herrens ord til profeten Gad, Davids seer, og det lød så: 
10:24:12: Gå og tal til David: Så sier Herren: Tre ting forelegger jeg dig; velg en av dem, så vil jeg gjøre så mot dig! 
10:24:13: Da kom Gad til David og forkynte ham dette og sa til ham: Vil du at det skal være hungersnød i ditt land i syv år, eller at du skal komme til å flykte for dine fiender i tre måneder, mens de forfølger dig, eller at det skal bli pest i ditt land i tre dager? Tenk nu efter og se til hvad jeg skal svare ham som har sendt mig! 
10:24:14: David sa til Gad: Jeg er i stor angst; la oss da helst falle i Herrens hånd! For hans barmhjertighet er stor, men i menneskers hånd vil jeg nødig falle. 
10:24:15: Sa lot Herren det komme en pest i Israel, fra morgenen til sammenkomstens tid*; og der døde av folket fra Dan til Be'erseba sytti tusen mann. # <* aftenofferets tid.> 
10:24:16: Og engelen rakte ut sin hånd mot Jerusalem for å ødelegge det; da angret Herren det onde, og han sa til engelen som gjorde ødeleggelse blandt folket: Det er nok; dra nu din hånd tilbake! Herrens engel var da ved jebusitten Aravnas treskeplass. 
10:24:17: Da David så engelen som slo ihjel blandt folket, sa han til Herren: Det er jeg som har syndet, det er jeg som har gjort ille; men denne min hjord, hvad har de gjort? La din hånd komme over mig og over min fars hus! 
10:24:18: Samme dag kom Gad til David og sa til ham: Gå op og reis Herren et alter på jebusitten Aravnas treskeplass! 
10:24:19: Så gikk David dit op efter Gads ord, således som Herren hadde befalt. 
10:24:20: Da Aravna så ut, fikk han se kongen og hans tjenere som kom over til ham; da gikk Aravna ut og kastet sig ned for kongen med ansiktet mot jorden. 
10:24:21: Og Arvna sa: Hvorfor kommer min herre kongen til sin tjener? David svarte: For å kjøpe treskeplassen av dig og bygge et alter der for Herren, forat hjemsøkelsen kan stanse og vike fra folket. 
10:24:22: Da sa Aravna til David: Min herre kongen må ta og ofre hvad han finner for godt! Se, her er oksene til brennofferet og treskesledene og åkene som er på oksene, til ved; 
10:24:23: alt dette, konge, gir Aravna kongen. Og Aravna sa til kongen: Herren din Gud ta nådig imot dig! 
10:24:24: Men kongen svarte: Nei, jeg vil kjøpe det av dig for penger; jeg vil ikke ofre Herren min Gud brennoffere som jeg har fått for intet. Så kjøpte David treskeplassen og oksene for femti sekel sølv*. # <* Hele plassen, hvor senere templet og forgården blev bygget, kostet 600 sekel gull, 1KR 21, 25.> 
10:24:25: Og David bygget der et alter for Herren og ofret brennoffere og takkoffere; og Herren hørte landets bønn, og hjemsøkelsen stanset og vek fra Israel. 
11:1:1: Nu var kong David gammel og høit til års*, og de dekket ham til med tepper, men han kunde ikke bli varm. # <* 2SA 5, 4.> 
11:1:2: Da sa hans tjenere til ham: En skulde lete efter en ung pike for min herre kongen, en jomfru som kan stelle for kongen og pleie ham og ligge i din favn, så min herre kongen blir varm. 
11:1:3: Så lette de i hele Israels land efter en vakker pike, og de fant Abisag fra Sunem og førte henne til kongen. 
11:1:4: Det var en meget vakker pike; hun pleide kongen og tjente ham, men kongen hadde ikke omgang med henne. 
11:1:5: Men Adonja, sønn til Haggit, gjorde sig høie tanker og sa: Jeg vil være konge. Og han fikk sig vogner og hestfolk og femti mann som løp foran ham. 
11:1:6: Hans far hadde aldri i hans liv talt hårdt til ham og sagt: Hvorfor har du gjort dette? Han* var og meget fager, og han var født næst efter Absalom. # <* 2SA 14, 25.> 
11:1:7: Han samrådde sig med Joab, Serujas sønn, og med presten Abjatar, og de holdt med Adonja og hjalp ham. 
11:1:8: Men presten Sadok og Benaja, Jojadas sønn, og profeten Natan og Sime'i og Re'i og de helter David hadde, var ikke med Adonja. 
11:1:9: Adonja slaktet får og okser og gjøkalver ved Sohelet-stenen, som er ved En-Rogel, og han innbød alle sine brødre, kongens sønner, og alle Judas menn som var i kongens tjeneste. 
11:1:10: Men profeten Natan og Benaja og heltene og sin bror Salomo innbød han ikke. 
11:1:11: Da sa Natan til Batseba, Salomos mor: Du har vel hørt at Adonja, Haggits sønn, er blitt konge, og vår herre David vet ikke noget om det? 
11:1:12: La mig nu få gi dig et råd, så du kan redde både ditt eget og din sønn Salomos liv! 
11:1:13: Gå inn til kong David og si til ham: Har du ikke, herre konge, med ed lovt din tjenerinne: Salomo, din sønn, skal være konge efter mig; han skal sitte på min trone? Hvorledes er det da gått til at Adonja er blitt konge? 
11:1:14: Og mens du ennu er der inne og taler med kongen, skal jeg og komme inn og stadfeste dine ord. 
11:1:15: Da gikk Batseba inn til kongen i kammeret. Kongen var nu meget gammel, og Abisag fra Sunem tjente ham. 
11:1:16: Og Batseba bøide sig og kastet sig ned for kongen, og kongen sa: Hvad vil du? 
11:1:17: Hun svarte: Min herre! Du har tilsvoret din tjenerinne ved Herren din Gud: Salomo, din sønn, skal bli konge efter mig; han skal sitte på min trone. 
11:1:18: Men se, nu er Adonja blitt konge, og du, herre konge, vet ikke noget om det. 
11:1:19: Han har slaktet okser og gjøkalver og får i mengdevis og innbudt alle kongens sønner og presten Abjatar og hærføreren Joab; men Salomo, din tjener, har han ikke innbudt. 
11:1:20: Og nu, herre konge, er hele Israels øine vendt mot dig, forat du skal la dem vite hvem som skal sitte på min herre kongens trone efter ham. 
11:1:21: Ellers kan det gå så at når min herre kongen ligger hos sine fedre, kommer jeg og min sønn Salomo til å holdes for forbrytere. 
11:1:22: Mens hun ennu talte med kongen, kom profeten Natan. 
11:1:23: Da blev det meldt kongen: Profeten Natan er her. Og han kom inn og trådte frem for kongen og kastet sig på sitt ansikt til jorden for ham. 
11:1:24: Og Natan sa: Herre konge! Du har vel sagt: Adonja skal være konge efter mig, og han skal sitte på min trone? 
11:1:25: For han har idag draget ned og har slaktet okser og gjøkalver og får i mengdevis og innbudt alle kongens sønner og hærførerne og presten Abjatar, og nu eter og drikker de med ham og roper: Leve kong Adonja! 
11:1:26: Men mig, din tjener, og presten Sadok og Benaja, Jojadas sønn, og Salomo, din tjener, har han ikke innbudt. 
11:1:27: Skulde dette være gjort på min herre kongens bud uten at du har latt dine tjenere vite hvem som skal sitte på min herre kongens trone efter ham? 
11:1:28: Da tok kong David til orde og sa: Kall Batseba inn til mig! Og hun kom inn og trådte frem for kongen. 
11:1:29: Og kongen svor og sa: Så sant Herren lever, han som har utfridd mig fra all trengsel: 
11:1:30: Som jeg har tilsvoret dig ved Herren, Israels Gud: Salomo, din sønn, skal være konge efter mig, og han skal sitte på min trone i mitt sted, så vil jeg gjøre på denne dag. 
11:1:31: Da bøide Batseba sig med ansiktet mot jorden og kastet sig ned for kongen og sa: Min herre kong David leve evindelig! 
11:1:32: Og kong David sa: Kall hit til mig presten Sadok og profeten Natan og Benaja, Jojadas sønn! Og de kom inn og trådte frem for kongen. 
11:1:33: Kongen sa til dem: Ta eders herres tjenere med eder og la min sønn Salomo ride på mitt eget muldyr og før ham ned til Gihon! 
11:1:34: Der skal presten Sadok og profeten Natan salve ham til konge over Israel, og I skal støte i basunen og rope: Leve kong Salomo! 
11:1:35: Så skal I følge ham hit op, og når han kommer, skal han sette sig på min trone, og han skal være konge i mitt sted; det er ham jeg har utsett til fyrste over Israel og Juda. 
11:1:36: Da tok Benaja, Jojadas sønn, til orde og sa til kongen: Så være det! Måtte også Herren, min herre kongens Gud, ville det så! 
11:1:37: Som Herren har vært med min herre kongen, så være han med Salomo og gjøre hans trone ennu større enn min herres, kong Davids trone! 
11:1:38: Da drog presten Sadok og profeten Natan og Benaja, Jojadas sønn, og livvakten ned, og de lot Salomo ride på kong Davids muldyr og førte ham til Gihon. 
11:1:39: Og presten Sadok hentet oljehornet fra teltet og salvet Salomo; og de støtte i basunen, og alt folket ropte: Leve kong Salomo! 
11:1:40: Og alt folket drog op efter ham, og de blåste på fløiter og jublet så høit at jorden revnet ved deres rop. 
11:1:41: Dette hørte Adonja og alle de gjester som var hos ham; de var da nettop ferdig med å ete. Og da Joab hørte basunlyden, sa han: Hvorfor er det slik larm og bulder i byen? 
11:1:42: Mens han ennu talte, kom Jonatan, presten Abjatars sønn; og Adonja sa: Kom hit! Du er en bra mann og kommer visst med gode tidender. 
11:1:43: Men Jonatan svarte og sa til Adonja: Nei! Vår herre kong David har gjort Salomo til konge. 
11:1:44: Kongen sendte med ham presten Sadok og profeten Natan og Benaja, Jojadas sønn, og livvakten, og de lot ham ride på kongens muldyr. 
11:1:45: Og presten Sadok og profeten Natan salvet ham til konge i Gihon, og så drog de op derfra i glede, og hele byen er i et røre. Det er den larm I hørte. 
11:1:46: Og Salomo har også alt satt sig på kongetronen, 
11:1:47: og kongens tjenere er kommet og har ønsket vår herre kong David til lykke og sagt: Din Gud la Salomos navn bli ennu herligere enn ditt navn og hans trone ennu større enn din trone! Og kongen tilbad på sitt leie. 
11:1:48: Kongen har også sagt så: Lovet være Herren, Israels Gud, som idag har laget det så at det sitter en på min trone, så jeg har fått se det med egne øine! 
11:1:49: Da blev alle Adonjas gjester forferdet; de stod op og gikk, hver sin vei. 
11:1:50: Adonja var redd Salomo; han stod op og gikk avsted og grep fatt i alterets horn. 
11:1:51: Da blev det meldt Salomo: Adonja er redd kong Salomo, og nu har han grepet fatt i alterets horn og sier: Kong Salomo må ennu idag tilsverge mig at han ikke vil la sin tjener dø for sverdet! 
11:1:52: Da sa Salomo: Vil han være en bra mann, skal det ikke falle et hår av hans hode til jorden; men blir det funnet noget ondt hos ham, skal han dø. 
11:1:53: Så sendte kong Salomo folk avsted, og de førte ham ned fra alteret, og han kom og kastet sig ned for kong Salomo, og Salomo sa til ham: Gå hjem til ditt hus! 
11:2:1: a det led mot den tid at David skulde dø, bød han sin sønn Salomo og sa: 
11:2:2: Jeg går all jordens vei; så vær du sterk og vær en mann! 
11:2:3: Og ta vare på hvad Herren din Gud vil ha varetatt, så du vandrer på hans veier og holder hans forskrifter, hans bud og hans lover og hans vidnesbyrd, som der er skrevet i Mose lov, så du kan gå viselig frem i alt det du gjør og i alt du gir dig i ferd med, 
11:2:4: forat Herren må opfylle sitt ord, det som han talte om mig da han sa: Dersom dine sønner akter vel på sin vei så de vandrer for mitt åsyn i sannhet, av hele sitt hjerte og av hele sin sjel, så skal det - sa han - aldri fattes en mann av din ætt på Israels trone. 
11:2:5: Du vet og hvad Joab, Serujas sønn, har gjort mot mig - hvad han gjorde mot begge Israels hærførere, Abner, Ners sønn, og Amasa, Jeters sønn, hvorledes han drepte dem og utøste blod i fredstid som om det var krig, og lot det komme blod på beltet han hadde om sine lender, og på skoene han hadde på sine føtter, som om det var krig. 
11:2:6: Gjør derfor som din visdom lærer dig, og la ikke hans grå hår fare med fred ned i dødsriket. 
11:2:7: Men gileaditten Barsillais sønner skal du gjøre vel imot, og de skal være blandt dem som eter ved ditt bord; for på samme måte kom de mig i møte da jeg flyktet for din bror Absalom. 
11:2:8: Så har du og benjaminitten Sime'i fra Bahurim, sønn av Gera, hos dig; det var han som bante mig så stygt den dag jeg drog til Mahana'im; men siden kom han mig i møte ned til Jordan, og jeg tilsvor ham ved Herren og sa: Jeg skal ikke la dig dø for sverdet. 
11:2:9: Men la ham nu ikke bli ustraffet! Du er en vis mann og vil nok vite hvad du skal gjøre med ham, så du lar hans grå hår fare med blod ned i dødsriket. 
11:2:10: Så la David sig til hvile hos sine fedre og blev begravet i Davids stad. 
11:2:11: Den tid David var konge over Israel, var firti år; i Hebron regjerte han i syv år og i Jerusalem i tre og tretti år. 
11:2:12: Og Salomo satt på sin far Davids trone, og hans kongedømme blev meget sterkt. 
11:2:13: Men Adonja, Haggits sønn, kom inn til Batseba, Salomos mor. Hun spurte: Kommer du med fred? Han svarte: Ja! 
11:2:14: Så sa han: Jeg har noget å tale med dig om. Hun sa: Tal! 
11:2:15: Da sa han: Du vet at riket var mitt, og at hele Israel hadde festet sine øine på mig og ventet at jeg skulde bli konge; men kongedømmet gikk fra mig og over til min bror; det var Herren som gav ham det. 
11:2:16: Og nu er det en ting jeg vil be dig om; du må ikke vise mig bort! Hun svarte: Tal! 
11:2:17: Da sa han: Bed kong Salomo - for dig viser han ikke bort - at han vil gi mig Abisag fra Sunem til hustru! 
11:2:18: Batseba svarte: Godt, jeg skal tale til kongen for dig. 
11:2:19: Så gikk Batseba inn til kong Salomo for å tale til ham for Adonja, og kongen stod op og gikk henne i møte og bøide sig dypt for henne. Derefter satte han sig på sin trone, og det blev satt frem en trone for kongens mor, og hun satte sig ved hans høire side. 
11:2:20: Hun sa: Det er bare en eneste liten ting jeg vil be dig om; du må ikke vise mig bort! Kongen svarte: Kom du med din bønn, mor! Jeg skal ikke vise dig bort. 
11:2:21: Da sa hun: La din bror Adonja få Abisag fra Sunem til hustru! 
11:2:22: Men kong Salomo svarte sin mor: Hvorfor ber du om Abisag fra Sunem for Adonja? Bed da like så godt om riket for ham - han er jo min eldre bror - ja både for ham og for presten Abjatar og for Joab, Serujas sønn! 
11:2:23: Og kong Salomo svor ved Herren og sa: Herren la det gå mig ille både nu og siden om ikke dette ord skal koste Adonja hans liv. 
11:2:24: Så sant Herren lever, han som har gitt mig denne makt og satt mig på min far Davids trone, og som har bygget mig et hus, således som han har talt: Adonja skal late livet idag! 
11:2:25: Så sendte kong Salomo Benaja, Jojadas sønn, avsted, og han hugg ham ned så han døde. 
11:2:26: Og til presten Abjatar sa kongen: Gå til Anatot, til din gård, for du har fortjent døden; men idag vil jeg ikke la dig dø, fordi du har båret Herrens, Israels Guds ark foran min far David, og fordi du har lidt med i alt hvad min far måtte lide. 
11:2:27: Så drev Salomo Abjatar bort og lot ham ikke få være Herrens prest lenger, forat Herrens ord skulde bli opfylt, det som han hadde talt mot Elis hus i Silo. 
11:2:28: Da Joab fikk høre om dette, flyktet han til Herrens telt og grep fatt i alterets horn; for Joab hadde holdt med Adonja, om han enn ikke hadde holdt med Absalom. 
11:2:29: Men det blev meldt kong Salomo at Joab var flyktet til Herrens telt og stod ved alteret. Da sendte Salomo Benaja, Jojadas sønn, avsted og sa: Gå og hugg ham ned! 
11:2:30: Da Benaja kom til Herrens telt, sa han til ham: Så sier kongen: Gå bort herfra! Men han sa: Nei, her vil jeg dø. Og Benaja bar bud tilbake til kongen og sa: Så sa Joab, og så svarte han mig. 
11:2:31: Da sa kongen til ham: Gjør som han sier, og hugg ham ned og begrav ham! Så frir du mig og min fars hus fra det uskyldige blod som Joab har utøst. 
11:2:32: Og Herren skal la hans blod* komme tilbake på hans hode fordi han hugg ned to menn som var rettferdigere og bedre enn han, og drepte dem med sverdet, så min far David ikke visste om det, Abner, Ners sønn, Israels hærfører, og Amasa, Jeters sønn, Judas hærfører. # <* det av ham utøste blod.> 
11:2:33: Deres blod skal komme tilbake på Joabs hode og på hans efterkommeres hode til evig tid; men David og hans efterkommere og hans hus og hans trone skal Herren gi lykke til evig tid. 
11:2:34: Så gikk Benaja, Jojadas sønn, op og hugg ham ned og drepte ham, og han blev begravet ved sitt hus i ørkenen. 
11:2:35: Og kongen satte Benaja, Jojadas sønn, over hæren i hans sted, og presten Sadok satte kongen i Abjatars sted. 
11:2:36: Så sendte kongen bud efter Sime'i og sa til ham: Bygg dig et hus i Jerusalem og bo der og gå ikke ut derfra, hverken til det ene sted eller det annet. 
11:2:37: For det skal du vite for visst, at den dag du går ut og går over bekken Kidron, skal du dø; ditt blod skal komme på ditt eget hode. 
11:2:38: Da sa Sime'i til kongen: Det er rett det du sier; som min herre kongen har sagt, så skal din tjener gjøre. Og Sime'i blev boende i Jerusalem en lang tid. 
11:2:39: Men da tre år var gått, hendte det at to av Sime'is tjenere rømte til kongen i Gat, Akis. sønn av Ma'aka. og det blev meldt Sime'i: Dine tjenere er i Gat. 
11:2:40: Da gjorde Sime'i sig rede og salte sitt asen og drog til Akis i Gat for å lete efter sine tjenere; Sime'i drog da dit og hadde sine tjenere med sig tilbake fra Gat. 
11:2:41: Men Salomo fikk vite at Sime'i var reist fra Jerusalem til Gat og var kommet tilbake. 
11:2:42: Da sendte kongen bud efter Sime'i og sa til ham: Har jeg ikke svoret ved Herren og høitidelig sagt dig: Det skal du vite for visst at den dag du går ut og drar til noget annet sted, skal du dø? Og du svarte: Det er rett det du sier; jeg har hørt det. 
11:2:43: Hvorfor har du da ikke holdt dig efter den ed som var svoret ved Herren, og det bud jeg gav dig? 
11:2:44: Og kongen sa til Sime'i: Du kjenner selv alt det onde som ditt hjerte vet om at du har gjort mot min far David, og Herren lar nu din ondskap komme tilbake på ditt eget hode. 
11:2:45: Men kong Salomo skal være velsignet, og Davids trone skal stå fast for Herrens åsyn til evig tid. 
11:2:46: Så gav kongen Benaja, Jojadas sønn, sin befaling, og han gikk ut og hugg ham ned så han døde. Og kongedømmet lå trygt og fast i Salomos hånd. 
11:3:1: Og Salomo inngikk svogerskap med Farao, Egyptens konge; han tok Faraos datter til ekte og førte henne inn i Davids stad inntil han blev ferdig med å bygge både sitt eget hus og Herrens hus og muren rundt omkring Jerusalem. 
11:3:2: Folket ofret dengang bare på haugene; for på den tid var det ennu ikke bygget noget hus for Herrens navn. 
11:3:3: Salomo elsket Herren, så han fulgte sin far Davids forskrifter; dog ofret han på haugene og brente røkelse der. 
11:3:4: Og kongen drog til Gibeon* for å ofre der; for der var den ypperste offerhaug. Tusen brennoffer ofret Salomo på alteret der. # <* der var på den tid tabernaklet; arken var i Jerusalem.> 
11:3:5: I Gibeon åpenbarte Herren sig for Salomo i en drøm om natten; Gud sa: Bed om det du vil jeg skal gi dig! 
11:3:6: Salomo svarte: Du har vist stor miskunnhet mot din tjener David, min far, efterdi han vandret for ditt åsyn i sannhet og i rettferdighet og i hjertets opriktighet mot dig, og denne store miskunnhet vedblev du stadig å vise ham og gav ham en sønn som skulde sitte på hans trone, således som det er idag. 
11:3:7: Nu har du da, Herre min Gud, gjort din tjener til konge i min far Davids sted; men jeg er bare en ungdom og vet ikke hvorledes jeg skal bære mig at i ett og alt. 
11:3:8: Og din tjener står her midt iblandt ditt folk, det som du har utvalgt, et folk som er så stort at det ikke kan regnes eller telles, så stort er det. 
11:3:9: Så gi da din tjener et hørsomt hjerte til å dømme ditt folk, til å skille mellem godt og ondt! For hvem kunde ellers dømme dette ditt folk som er så tallrikt? 
11:3:10: Herren syntes godt om at Salomo hadde bedt om denne ting. 
11:3:11: Og Gud sa til ham: Efterdi du har bedt om denne ting og ikke bedt om et langt liv og ikke bedt om rikdom og heller ikke om dine fienders død, men har bedt om forstand til å akte på hvad rett er, 
11:3:12: så vil jeg gjøre som du beder: Jeg vil gi dig et vist og forstandig hjerte, så det ikke har vært din like før og ikke vil komme din like efter dig. 
11:3:13: Og det du ikke har bedt om, vil jeg og gi dig, både rikdom og ære, så det ikke skal være din like blandt kongene alle dine dager. 
11:3:14: Og dersom du vil vandre på mine veier, så du holder mine lover og mine bud, som din far David gjorde, så vil jeg gi dig et langt liv. 
11:3:15: Da våknet Salomo, og han skjønte at det var en drøm. Og da han kom til Jerusalem, trådte han frem for Herrens pakts-ark og ofret brennoffer og bar frem takkoffer og gjorde et gjestebud for alle sine tjenere. 
11:3:16: På den tid kom det to skjøger og trådte frem for kongen. 
11:3:17: Og den ene kvinne sa: Hør på mig, herre! Jeg og denne kvinne bodde i samme hus, og jeg fødte et barn i huset hos henne. 
11:3:18: Og den tredje dag efterat jeg hadde født, fødte også denne kvinne et barn. Der var vi nu sammen; det var ingen fremmed hos oss i huset; bare vi to var i huset. 
11:3:19: Om natten døde sønnen til denne kvinne fordi hun hadde ligget på ham; 
11:3:20: og hun stod op midt om natten og tok min sønn fra min side, mens din tjenerinne sov, og hun la ham ved sin barm, og sin egen sønn som var død, la hun ved min barm. 
11:3:21: Da jeg så stod op om morgenen for å gi min sønn die, fikk jeg se at han var død; men da jeg om morgenen så nøiere på ham, da var det ikke min sønn, den som jeg hadde født. 
11:3:22: Da sa den andre kvinne: Nei, den som lever, er min sønn, og den som er død, er din. Men den første sa: Nei, den som er død, er din sønn, og den som lever, er min. Således talte de mens de stod for kongen. 
11:3:23: Da sa kongen: Den ene sier: Denne som lever, er min sønn, og den som er død, er din. Og den andre sier: Nei, den som er død, er din sønn, og den som lever, er min. 
11:3:24: Så sa kongen: Hent mig et sverd! Og de kom med et sverd og bar det frem for kongen. 
11:3:25: Da sa kongen: Hugg det levende barn i to stykker og gi den ene halvdel til den ene og den andre halvdel til den andre! 
11:3:26: Da sa den kvinne som var mor til det levende barn, til kongen - for hennes hjerte brente for hennes barn - hun sa: Hør på mig, herre! Gi henne det levende barn og drep det for all ting ikke! Men den andre sa: Hverken jeg eller du skal ha det; hugg bare til! 
11:3:27: Da tok kongen til orde og sa: Gi henne det levende barn og drep det ikke! Hun er dets mor. 
11:3:28: Hele Israel fikk høre om den dom kongen hadde felt, og de hadde ærefrykt for kongen; for de så at Guds visdom var i hans hjerte, så at han dømte rett. 
11:4:1: Kong Salomo var konge over hele Israel. 
11:4:2: Og dette var hans fornemste menn: Asarja, sønn av Sadok, var prest*; # <* likesom 2SA 8, 18.> 
11:4:3: Elihoref og Akia, sønner av Sisa, var statsskrivere; Josafat, Akiluds sønn, var historieskriver; 
11:4:4: Benaja, Jojadas sønn, var høvding over hæren; Sadok og Abjatar var prester; 
11:4:5: Asarja, Natans sønn, var over fogdene*; Sabud, Natans sønn, var prest**, kongens venn; # <* 1KG 4, 7.> # <** likesom 2SA 8, 18.> 
11:4:6: Akisar var slottshøvding; Adoniram, Abdas sønn, hadde opsyn med dem som gjorde pliktarbeid*. # <* 1KG 5, 13. 14.> 
11:4:7: Salomo hadde satt tolv fogder over hele Israel; de forsynte kongen og hans hus med fødevarer; en måned om året hadde hver av dem å forsyne ham. 
11:4:8: Dette var deres navn: Hurs sønn på Efra'im-fjellet; 
11:4:9: Dekers sønn i Makas og Sa'albim og Bet-Semes og Elon-Bet-Hanan; 
11:4:10: Heseds sønn i Arubbot; han hadde Soko og hele Hefer-bygden; 
11:4:11: Abinadabs sønn hadde hele Dors høiland; han hadde Tafat, Salomos datter, til hustru; 
11:4:12: Ba'ana, Akiluds sønn, hadde Ta'anak og Megiddo og hele Bet-Sean, som ligger ved siden av Sartan nedenfor Jisre'el, fra Bet-Sean til Abel-Mehola og bortenfor Jokmeam; 
11:4:13: Gebers sønn i Ramot i Gilead; han hadde Ja'irs, Manasses sønns byer i Gilead; han hadde også Argob-bygden i Basan, seksti store byer med murer og kobberbommer; 
11:4:14: Akinadab, Iddos sønn, hadde Mahana'im; 
11:4:15: Akima'as i Naftali; også han hadde tatt en datter av Salomo til hustru; hun hette Basmat; 
11:4:16: Ba'ana, sønn av Husai, i Aser og i Alot; 
11:4:17: Josafat, sønn av Paruah, Issakar; 
11:4:18: Sime'i, sønn av Ela, i Benjamin; 
11:4:19: Geber, sønn av Uri, i Gileads land - det land som amoritterkongen Sihon og Basans konge Og hadde hatt - han var den eneste foged i de bygder. 
11:4:20: Juda og Israel var så mange som sanden ved havet; de åt og drakk og var glade. 
11:4:21: Og Salomo rådet over alle kongerikene fra elven* til filistrenes land og like til Egyptens landemerke; de kom med gaver og tjente Salomo så lenge han levde. # <* Eufrat.> 
11:4:22: Av fødevarer gikk det hos Salomo for hver dag med tretti kor fint mel og seksti kor vanlig mel, 
11:4:23: ti gjødde okser og tyve drifteokser og hundre stykker småfe foruten hjorter og rådyr og dådyr og gjødde fugler. 
11:4:24: For han rådet over hele landet vestenfor elven, fra Tifsah like til Gasa, over alle kongene vestenfor elven, og han hadde fred på alle kanter rundt omkring. 
11:4:25: Og Juda og Israel bodde trygt, hver mann under sitt vintre og under sitt fikentre, fra Dan like til Be'erseba, så lenge Salomo levde. 
11:4:26: Salomo hadde firti tusen stallrum for sine vognhester og tolv tusen hestfolk. 
11:4:27: De fogder som er nevnt ovenfor, forsynte hver sin måned kong Salomo og alle som gikk til kong Salomos bord; de lot det ikke fattes på noget. 
11:4:28: Og bygget og halmen til vognhestene og traverne førte de til det sted hvor det skulde være, hver efter som det var ham foreskrevet. 
11:4:29: Gud gav Salomo visdom og overmåte megen innsikt og en forstand vidt omfattende som sanden på havets bredd. 
11:4:30: Salomos visdom var større enn alle Østens barns visdom og all egypternes visdom. 
11:4:31: Han var visere enn alle mennesker - visere enn esrahitten Etan og Mahols sønner Heman og Kalkol og Darda, og hans navn var kjent blandt alle hedningefolkene rundt omkring. 
11:4:32: Han laget tre tusen ordsprog, og hans sanger var et tusen og fem. 
11:4:33: Han talte om trærne, fra sederen på Libanon til isopen som vokser ut på veggen, og han talte om dyrene og om fuglene og om krypet og om fiskene. 
11:4:34: Og de kom fra alle folk for å høre Salomos visdom, fra alle jordens konger som hadde hørt om hans visdom. 
11:5:1: Kongen i Tyrus Hiram sendte sine tjenere til Salomo, da han hadde fått høre at han var blitt salvet til konge i sin fars sted; for Hiram hadde alltid vært en venn av David. 
11:5:2: Og Salomo sendte bud til Hiram og lot si: 
11:5:3: Du vet at min far David ikke kunde bygge et hus for Herrens, sin Guds navn for de krigers skyld hvormed de hadde omringet ham, inntil Herren la dem under hans føtter. 
11:5:4: Men nu har Herren min Gud gitt mig ro rundt omkring; det finnes ingen motstandere, og ingen ulykker truer. 
11:5:5: Derfor tenker jeg nu på å bygge et hus for Herrens, min Guds navn, således som Herren sa da han talte således til min far David: Din sønn, som jeg vil sette på din trone i ditt sted, han skal bygge huset for mitt navn. 
11:5:6: Så byd nu at de skal hugge sedrer for mig på Libanon, så skal mine tjenere være med dine tjenere, og lønnen til dine tjenere vil jeg gi dig efter som du selv bestemmer; for du vet at det ikke finnes nogen blandt oss som skjønner sig så godt på å hugge trær som sidonierne. 
11:5:7: Da Hiram mottok dette budskap fra Salomo, blev han meget glad, og han sa: Lovet være Herren idag, som har gitt David en vis sønn og satt ham over dette store folk! 
11:5:8: Og Hiram sendte bud til Salomo og lot si: Jeg har mottatt det budskap du har sendt mig; jeg skal i alle måter gjøre som du ønsker med sedertrærne og cypresstrærne. 
11:5:9: Mine tjenere skal føre dem fra Libanon ned til havet, og jeg vil la dem legge i flåter på havet og føre til det sted du vil sende mig bud om, og la dem ta fra hverandre der, og så lar du dem hente. Men så må du gjøre det jeg ønsker, og la mig få fødevarer til mitt hus. 
11:5:10: Og Hiram lot Salomo få sedertrær og cypresstrær, så meget som han ønsket. 
11:5:11: Og Salomo gav Hiram tyve tusen kor hvete til føde for hans hus og tyve kor olje av støtte oliven; så meget gav Salomo Hiram år efter år. 
11:5:12: Herren gav Salomo visdom, således som han hadde lovt ham; og det var fred mellem Hiram og Salomo, og de gjorde en pakt med hverandre. 
11:5:13: Og kong Salomo uttok pliktarbeidere av hele Israel, og pliktarbeiderne var tretti tusen mann. 
11:5:14: Dem sendte han skiftevis til Libanon, ti tusen om måneden; en måned var de på Libanon og to måneder hjemme. Adoniram hadde opsyn med pliktarbeiderne. 
11:5:15: Salomo hadde sytti tusen bærere og åtti tusen stenhuggere på fjellene, 
11:5:16: foruten arbeidsformennene, som var tre tusen og tre hundre i tallet; de rådet over de folk som utførte arbeidet. 
11:5:17: Og kongen bød at de skulde bryte store og kostbare stener, så grunnvollen til huset kunde legges ned med huggen sten. 
11:5:18: Og Salomos bygningsmenn og Hirams bygningsmenn og giblittene* hugg dem til og gjorde i stand både stokkene og stenene som huset skulde bygges av. # <* ESK 27, 9.> 
11:6:1: I det fire hundre og åttiende år efter Israels barns utgang av Egyptens land, i måneden siv - det er den annen måned - i det fjerde år efterat Salomo var blitt konge over Israel, begynte han å bygge huset for Herren. 
11:6:2: Huset som kong Salomo bygget for Herren, var seksti alen langt og tyve alen bredt og tretti alen høit. 
11:6:3: Og forhallen foran det Hellige var tyve alen, svarende til bredden av huset, og ti alen bred målt ut fra huset. 
11:6:4: På huset gjorde han vinduer med fast gitterverk. 
11:6:5: Op til husets vegg bygget han en tilbygning rundt omkring - langs med veggene på huset, både det Hellige og koret, og således gjorde han sidekammer rundt omkring. 
11:6:6: Det nederste stokkverk var fem alen bredt og det mellemste seks alen bredt og det tredje syv alen bredt; for han hadde gjort avsatser på huset rundt omkring på utsiden, så ikke bjelkene skulde gå inn i husets vegger. 
11:6:7: Da huset blev reist, blev det bygget av hele stener fra stenbruddet; en hørte hverken hammer eller øks eller noget slags jernredskap i huset da det blev bygget. 
11:6:8: Døren til det mellemste sidekammer* var på husets høire side, og på en vindeltrapp kom en op i det mellemste stokkverk, og fra det mellemste op i det tredje. # <* nemlig i det nederste stokkverk.> 
11:6:9: Således bygget han huset, og da han hadde gjort det ferdig, tekte han det med bjelker og med sederplanker i rader. 
11:6:10: Tilbygningen som han bygget rundt om hele huset, var fem alen høi for hvert stokkverk og var festet til huset ved sederbjelker. 
11:6:11: Da kom Herrens ord til Salomo, og det lød således: 
11:6:12: Nu bygger du dette hus - hvis du da vandrer efter mine forskrifter og holder mine lover og tar vare på alle mine bud, så du følger dem, da vil jeg opfylle på dig det ord jeg talte til din far David, 
11:6:13: og jeg vil bo midt iblandt Israels barn, og jeg vil ikke forlate mitt folk Israel. 
11:6:14: Så bygget da Salomo huset og gjorde det ferdig. 
11:6:15: Han klædde husets vegger innentil med sederbord; fra gulvet og helt op til loftsbjelkene klædde han huset innentil med tre, og gulvet klædde han med cypressbord. 
11:6:16: Tyve alen fra husets bakside satte han en vegg av sederbord fra gulvet og op til loftsbjelkene, og rummet innenfor innredet han til kor - det var det Aller-helligste. 
11:6:17: Og huset - det Hellige foran koret - var firti alen langt. 
11:6:18: Innvendig var huset prydet med utskjæringer i sedertre - kolokvinter og utsprungne blomster; alt sammen var sedertre, der var ingen sten å se. 
11:6:19: Innerst i huset innredet han et kor til å sette Herrens pakts-ark i. 
11:6:20: Og foran koret, som var tyve alen langt og tyve alen bredt og tyve alen høit, og som han klædde med fint gull, satte han et alter og klædde det med sedertre. 
11:6:21: Og Salomo klædde huset innentil med fint gull og hengte gullkjeder bortefter veggen foran koret og klædde den med gull. 
11:6:22: Hele huset klædde han med gull fra ende til annen; og hele alteret som hørte til koret, klædde han med gull. 
11:6:23: I koret gjorde han to kjeruber av oljetre; de var ti alen høie. 
11:6:24: Kjerubens ene vinge var fem alen og kjerubens andre vinge fem alen - det var ti alen fra vingespiss til vingespiss. 
11:6:25: Den andre kjerub var og ti alen*; begge kjerubene hadde samme mål og samme skikkelse. # <* mellem vingespissene.> 
11:6:26: Den ene kjerub var ti alen høi og likeså den andre kjerub. 
11:6:27: Og han satte kjerubene i det innerste rum i huset, og de strakte kjerubenes vinger ut, så den enes vinge rørte ved den ene vegg, og den andre kjerubs vinge rørte ved den andre vegg, mens deres vinger midt i huset rørte ved hverandre, 
11:6:28: og han klædde kjerubene med gull. 
11:6:29: Alle husets vegger rundt omkring prydet han med utskårne billeder av kjeruber og palmer og utsprungne blomster både i det indre rum og i det ytre. 
11:6:30: Og husets gulv klædde han med gull, både i det indre rum og i det ytre. 
11:6:31: For inngangen til koret gjorde han en fløidør av oljetre; den øvre dørkarm med dørstolpene utgjorde en femtedel av veggen. 
11:6:32: Og de to dører av oljetre prydet han med utskårne billeder av kjeruber og palmer og utsprungne blomster og klædde dem med gull; han bredte gullet ut over kjerubene og palmene. 
11:6:33: Likeså gjorde han dørstolper av oljetre for inngangen til det Hellige, på fjerdedelen av veggen, 
11:6:34: og to dørfløier av cypresstre; begge dørfløiene var i to deler, som kunne svinges hver for sig. 
11:6:35: Og han prydet dem med utskjæringer: kjeruber og palmer og utsprungne blomster, og han klædde dem med gull som blev lagt jevnt utover utskjæringene. 
11:6:36: Og han bygget den indre forgård av tre lag huggen sten og ett lag sederbjelker. 
11:6:37: I det fjerde år blev grunnvollen lagt til Herrens hus, i måneden siv, 
11:6:38: og i det ellevte år i måneden bul det er den åttende måned var huset ferdig i alle sine deler og i ett og alt som det skulde være. Da hadde han bygget på det i syv år. 
11:7:1: På sitt eget hus bygget Salomo i tretten år før han blev ferdig med hele huset. 
11:7:2: Han bygget Libanon-skoghuset, hundre alen langt og femti alen bredt og tretti alen høit, på fire rader sederstolper og med sederbjelker ovenpå stolpene. 
11:7:3: Det var tekket med sedertre ovenover de sidekammer som hvilte på stolpene, og som var fem og firti i tallet, femten i hver rad. 
11:7:4: Der var tre rader bjelkelag og vindu mot vindu i tre høider. 
11:7:5: Alle dørene og dørstolpene var firkantede, av bjelker, og vendte like mot vindusrekken, i alle tre høider. 
11:7:6: Så gjorde han søilehallen, femti alen lang og tretti alen bred; og foran den var det en forhall med søiler, og foran den igjen var det en trappeopgang. 
11:7:7: Så gjorde han tronhallen hvor han satt og dømte, domshallen; den var klædd med sedertre fra den ene ende av gulvet til den andre. 
11:7:8: Hans eget hus, det som han bodde i, og som lå i den andre gård innenfor forhallen, var bygget på samme vis. Også for Faraos datter, som han hadde tatt til ekte, bygget Salomo et hus som var likt denne forhall. 
11:7:9: Alt dette var av kostbare stener, tilhugget efter mål og skåret med sag innentil og utentil, like fra grunnen og op til murkanten og utenfra til den store gård. 
11:7:10: Grunnvollen var lagt med kostbare og store stener, stener på ti alen og stener på åtte alen. 
11:7:11: Og ovenpå der var det kostbare stener, hugget efter mål, og sedertre. 
11:7:12: Rundt omkring den store gård var det tre lag huggen sten og ett lag sederbjelker, likesom det var med den indre forgård til Herrens hus og med husets forhall. 
11:7:13: Kong Salomo sendte bud efter Hiram fra Tyrus. 
11:7:14: Han var sønn av en enke av Naftali stamme, og hans far var en mann fra Tyrus, en kobbersmed. Han var fylt med visdom og forstand og kunnskap, så han kunde gjøre alle slags arbeid i kobber. Han kom da til kong Salomo og gjorde alt det arbeid han vilde ha gjort. 
11:7:15: Han gjorde de to kobbersøiler; den ene søile var atten alen høi, og en tråd på tolv alen nådde omkring den andre søile*. # <* den var tolv alen i omfang.> 
11:7:16: Og han gjorde to søilehoder støpt av kobber til å sette på toppen av søilene; hvert søilehode var fem alen høit. 
11:7:17: På søilehodene som var på toppen av søilene, var det nettverk, flettet arbeid, snorer laget som kjeder - syv på hvert søilehode. 
11:7:18: Og han gjorde to rader med granatepler rundt omkring ved det ene av de nettverk som skulde dekke søilehodene på toppen av søilene, og således gjorde han også med det andre søilehode. 
11:7:19: Og søilehodene på toppen av søilene var gjort som liljer, likesom i forhallen, og målte fire alen. 
11:7:20: På begge søilene var det søilehoder, også ovenover tett ved den utbukning som var på den andre side av nettverket, og granateplene var to hundre i tallet og hang i rader rundt omkring på det andre søilehode. 
11:7:21: Så reiste han søilene ved templets forhall; den søile han satte på høire side, kalte han Jakin*, og den som han satte på venstre side, kalte han Boas**, # <* d.e. han (Gud) skal gjøre fast.> # <** d.e. i ham er det (templet) sterkt.> 
11:7:22: På toppen av søilene var det liljeformet arbeid. Så var arbeidet med søilene ferdig. 
11:7:23: Så gjorde han det støpte hav; det var ti alen fra den ene rand til den andre og var aldeles rundt; det nådde fem alen i høiden, og en snor på tretti alen nådde rundt om det. 
11:7:24: Nedenfor dets rand var det kolokvinter rundt omkring; de gikk rundt omkring havet, ti på hver alen; det var to rader med kolokvinter, og de var støpt i ett med havet. 
11:7:25: Havet stod på tolv okser; tre vendte mot nord, tre vendte mot vest, tre vendte mot syd, og tre vendte mot øst; havet hvilte på dem, og deres bakkropper vendte alle innefter. 
11:7:26: Kummens tykkelse var en håndsbredd, og dens rand var som randen på et beger, lik en liljeblomst; den rummet to tusen bat. 
11:7:27: Så gjorde han de ti fotstykker av kobber; hvert fotstykke var fire alen langt, fire alen bredt og tre alen høit. 
11:7:28: Og fotstykkene var gjort således: Det var fyllinger på dem - fyllinger mellem kantlistene; 
11:7:29: og på fyllingene mellem kantlistene var det løver, okser og kjeruber, og ovenover kantlistene var det et underlag*, og nedenunder løvene og oksene var det nedhengende løvverk. # <* nemlig til å sette karene i.> 
11:7:30: Hvert fotstykke hadde fire hjul av kobber og aksler av kobber, og dets fire føtter hadde bærearmer; de var støpt på nedenunder karet; på hvert av dem var det løvverk på den andre side. 
11:7:31: Og åpningen på det* var innenfor kransen og opefter en alen høi, og dens** åpning var rund, gjort som et underlag, halvannen alen***, og også på dens åpning var det billedverk, men sidefyllingene var firkantede, ikke runde. # <* karet.> # <** kransens.> # <*** nemlig i gjennemsnitt.> 
11:7:32: De fire hjul satt under fyllingene, og hjultappene satt på fotstykket, og hvert hjul var halvannen alen høit. 
11:7:33: Hjulene var gjort som vognhjul; tappene og ringene og ekene og navene på dem - det var alt sammen støpt. 
11:7:34: Det var fire bærearmer på de fire hjørner av hvert fotstykke; bærearmene var i ett med fotstykket. 
11:7:35: Øverst på fotstykket var det en halv alens forhøining, aldeles rund, og oventil på fotstykket satt håndtakene og fyllingene, som var i ett med det. 
11:7:36: Og på flatene av håndtakene og på fyllingene skar han ut kjeruber, løver og palmer, efter som det var rum til på hver av dem, og løvverk rundt omkring. 
11:7:37: Således gjorde han de ti fotstykker; de var alle støpt på samme vis og hadde samme mål og samme form. 
11:7:38: Så gjorde han ti kobberkar; hvert kar rummet firti bat og var fire alen i tverrmål; det var ett kar på hvert av de ti fotstykker. 
11:7:39: Og han satte fem av fotstykkene på høire side av huset og fem på venstre side, og havet satte han på høire side av huset, mot sydøst. 
11:7:40: Hiram gjorde askebøttene og ildskuffene og skålene til å sprenge blod med. Så var Hiram ferdig med alt det arbeid han gjorde for kong Salomo til Herrens hus; det var: 
11:7:41: to søiler og to skåler på søilehodene på toppen av søilene, og de to nettverk til å dekke de to skåler på søilehodene på toppen av søilene, 
11:7:42: og de fire hundre granatepler til de to nettverk - to rader granatepler til hvert nettverk - til å dekke de to skåler på søilehodene som var ovenpå søilene, 
11:7:43: og de ti fotstykker og de ti kar på fotstykkene, 
11:7:44: og havet og de tolv okser under havet, 
11:7:45: og askebøttene og ildskuffene og skålene til å sprenge blod med. Alle disse ting som Hiram gjorde for kong Salomo til Herrens hus, var av blankt kobber. 
11:7:46: Det var på Jordan-sletten kongen lot dem støpe, i lerjorden mellem Sukkot og Sartan. 
11:7:47: Salomo lot alle disse ting være uveid, fordi de var så overvettes mange; kobberets vekt blev ikke undersøkt. 
11:7:48: Salomo gjorde også alle de ting som skulde være i Herrens hus: gullalteret og gullbordet som skuebrødene skulde ligge på, 
11:7:49: og lysestakene, fem på høire og fem på venstre side - foran koret - av fint gull, og blomstene på dem og lampene og lysesaksene av gull, 
11:7:50: og fatene og knivene og skålene til å sprenge blod med og røkelse-skålene og fyrfatene av fint gull, og hengslene til dørene i det innerste rum i huset - det Allerhelligste - og til dørene i det forreste rum i huset - det Hellige - av gull. 
11:7:51: Da nu alt det arbeid var ferdig som kong Salomo lot gjøre for Herrens hus, lot han bære inn der de ting som hans far David hadde helliget til Herren: sølvet og gullet og karene; han la det ned i skattkammerne i Herrens hus. 
11:8:1: Da kalte kong Salomo Israels eldste og alle stammehøvdingene, overhodene for Israels barns familier, sammen til sig i Jerusalem for å føre Herrens pakts-ark op fra Davids stad, det er Sion. 
11:8:2: Og alle Israels menn samlet sig hos kong Salomo på festen* i måneden etanim, det er den syvende måned. # <* løvsalenes høitid.> 
11:8:3: Da nu alle Israels eldste var kommet, løftet prestene arken op. 
11:8:4: Og de bar Herrens ark op og sammenkomstens telt og alle de hellige redskaper som var i teltet; det var prestene og levittene som bar dem op. 
11:8:5: Og kong Salomo stod foran arken og med ham hele Israels menighet, som hadde samlet sig hos ham, og de ofret småfe og storfe i slik mengde at de ikke kunde telles eller regnes. 
11:8:6: Prestene bar Herrens pakts-ark inn på dens plass i husets kor, i det Aller-helligste, under kjerubenes vinger. 
11:8:7: For kjerubene bredte sine vinger ut over det sted hvor arken stod, så at kjerubene ovenfra dekket både over arken og dens stenger. 
11:8:8: Stengene var så lange at deres ender kunde sees fra det Hellige foran koret, men ikke utenfor. Og der har de vært til denne dag. 
11:8:9: Det var ikke noget i arken uten de to stentavler som Moses hadde lagt ned der ved Horeb, dengang Herren gjorde pakt med Israels barn da de drog ut av Egyptens land. 
11:8:10: Så skjedde det at da prestene gikk ut av helligdommen, da fylte skyen Herrens hus. 
11:8:11: Og for skyens skyld kunde prestene ikke bli stående og gjøre tjeneste; for Herrens herlighet fylte Herrens hus. 
11:8:12: Da sa Salomo: Herren har sagt at han vil bo i mulmet. 
11:8:13: Bygget har jeg et hus til bolig for dig, et sted hvor du kan bo til evig tid. 
11:8:14: Så vendte kongen sig om og velsignet hele Israels menighet, mens hele Israels menighet stod. 
11:8:15: Han sa: Lovet være Herren, Israels Gud, som med sin munn talte med David, min far, og med sin hånd har opfylt det han lovte da han sa: 
11:8:16: Fra den dag jeg førte mitt folk Israel ut av Egypten, har jeg ikke utvalgt nogen by blandt alle Israels stammer, så der skulde bygges et hus til bolig for mitt navn; men jeg utvalgte David til å råde over mitt folk Israel. 
11:8:17: Og David, min far, hadde i sinne å bygge et hus for Herrens, Israels Guds navn. 
11:8:18: Men Herren sa til David, min far: At du har hatt i sinne å bygge et hus for mitt navn, det har du gjort vel i; 
11:8:19: men du skal ikke bygge huset; din sønn som skal utgå av dine lender, han skal bygge huset for mitt navn. 
11:8:20: Og Herren opfylte det ord han hadde talt, og jeg er trådt i min far Davids sted og har tatt sete på Israels trone, således som Herren hadde sagt, og jeg har bygget huset for Herrens, Israels Guds navn. 
11:8:21: Og der har jeg stelt til et rum for arken, hvori Herrens pakt er, den pakt som han gjorde med våre fedre da han førte dem ut av Egyptens land. 
11:8:22: Så trådte Salomo frem foran Herrens alter midt for hele Israels menighet og bredte ut sine hender mot himmelen 
11:8:23: og sa: Herre, Israels Gud! Det er ikke nogen Gud som du, hverken i himmelen der oppe eller på jorden her nede, du som holder din pakt og bevarer din miskunnhet mot dine tjenere, når de vandrer for ditt åsyn av alt sitt hjerte, 
11:8:24: du som har holdt det du lovte din tjener David, min far; du lovte det med din munn, og med din hånd har du opfylt det, som det viser sig idag. 
11:8:25: Så hold nu og, Herre, Israels Gud, det du lovte din tjener David, min far, da du sa: Det skal aldri fattes en mann av din ætt til å sitte på Israels trone for mitt åsyn, så sant dine barn akter på sin vei og vandrer for mitt åsyn, som du har vandret for mitt åsyn. 
11:8:26: Så la nu, Israels Gud, de ord bli sannhet som du har talt til din tjener David, min far. 
11:8:27: Men bor da Gud virkelig på jorden? Se, himlene og himlenes himler rummer dig ikke; hvor meget mindre da dette hus som jeg har bygget! 
11:8:28: Men du vil allikevel vende dig til din tjeners bønn og til hans ydmyke begjæring, Herre min Gud, og høre på det rop og den bønn som din tjener bærer frem for ditt åsyn idag, 
11:8:29: så dine øine må være oplatt mot dette hus natt og dag - det sted hvorom du har sagt: Mitt navn skal bo der - så du hører på den bønn som din tjener beder, vendt mot dette sted. 
11:8:30: Du vil høre på din tjeners og ditt folk Israels ydmyke begjæring, som de bærer frem, vendt mot dette sted; du vil høre den på det sted hvor du bor, i himmelen; du vil høre og tilgi. 
11:8:31: Når nogen synder mot sin næste, og de krever en ed av ham og lar ham sverge, og han så kommer inn og sverger foran ditt alter i dette hus, 
11:8:32: så vil du høre i himmelen og gripe inn og hjelpe dine tjenere til deres rett, så du dømmer den skyldige skyldig og lar hans gjerninger komme over hans eget hode, og dømmer den rettferdige rettferdig og lar ham få efter sin rettferdighet. 
11:8:33: Når ditt folk Israel blir slått av fienden fordi de synder mot dig, men de så vender om til dig og bekjenner ditt navn og beder til dig og bønnfaller dig om nåde i dette hus, 
11:8:34: så vil du høre det i himmelen og forlate ditt folk Israels synd og føre dem tilbake til det land du har gitt deres fedre. 
11:8:35: Når himmelen lukkes, så det ikke kommer regn, fordi de synder mot dig, og de så beder, vendt mot dette sted, og bekjenner ditt navn og vender om fra sin synd, fordi du ydmyker dem, 
11:8:36: så vil du høre det i himmelen og forlate dine tjeneres og ditt folk Israels synd, fordi du lærer dem den gode vei de skal vandre, og du vil la det regne over ditt land, som du har gitt ditt folk til arv. 
11:8:37: Når det kommer hungersnød i landet, når det kommer pest, når det kommer brand og rust på kornet, gresshopper og gnagere*, når deres fiender trenger inn i deres land og kringsetter deres byer, når det kommer nogen plage eller nogen sykdom - # <* et slags gresshopper.> 
11:8:38: hver gang da noget menneske eller hele ditt folk Israel bærer frem nogen bønn eller ydmyk begjæring, fordi de hver for sig kjenner sig rammet i sin samvittighet, og de så breder ut sine hender mot dette hus, 
11:8:39: så vil du høre det i himmelen, der hvor du bor, og du vil tilgi og gripe inn og gi hver mann efter alle hans gjerninger, fordi du kjenner hans hjerte - for du alene kjenner alle menneskebarns hjerte - 
11:8:40: så de må frykte dig alle de dager de lever i det land du har gitt våre fedre. 
11:8:41: Også om en fremmed, en som ikke er av ditt folk Israel, men kommer fra et fjernt land for ditt navns skyld 
11:8:42: - for de vil få høre om ditt store navn og om din sterke hånd og om din utrakte arm - kommer og beder, vendt mot dette hus, 
11:8:43: så vil du høre det i himmelen, der hvor du bor, og gjøre alt som den fremmede roper til dig om, så alle jordens folk må lære å kjenne ditt navn og frykte dig likesom ditt folk Israel og forstå at det er ditt navn som det nevnes med dette hus som jeg har bygget. 
11:8:44: Når ditt folk drar ut i krig mot sin fiende på den vei du sender dem, og de så beder til Herren, vendt mot den stad du har utvalgt, og det hus jeg har bygget for ditt navn, 
11:8:45: så vil du i himmelen høre deres bønn og ydmyke begjæring og hjelpe dem til deres rett. 
11:8:46: Når de synder mot dig - for det er ikke noget menneske som ikke synder - og du vredes på dem og gir dem i fiendens vold, og de som tar dem til fange, fører dem bort til fiendens land, fjernt eller nær, 
11:8:47: men de så tar sig det til hjerte i det land hvor de holdes fanget, og omvender sig og bønnfaller dig om nåde i deres land som holder dem fanget, og sier: Vi har syndet og gjort ille, vi har vært ugudelige, 
11:8:48: og de omvender sig til dig av alt sitt hjerte og av all sin sjel i sine fienders land, blandt dem som har ført dem i fangenskap, og de beder til dig, vendt mot sitt land, som du har gitt deres fedre, og mot den stad du har utvalgt, og det hus jeg har bygget for ditt navn, 
11:8:49: så vil du i himmelen, der hvor du bor, høre deres bønn og ydmyke begjæring og hjelpe dem til deres rett 
11:8:50: og forlate ditt folk hvad de har syndet mot dig, og alle de misgjerninger de har gjort mot dig, og la dem finne barmhjertighet hos dem som holder dem fanget, så de forbarmer sig over dem; 
11:8:51: de er jo ditt folk og din arv, som du førte ut av Egypten, midt ut av jernovnen. 
11:8:52: La da dine øine være oplatt for din tjeners ydmyke begjæring og for ditt folk Israels ydmyke begjæring, så du hører på dem, så ofte de roper til dig. 
11:8:53: For du har utskilt dem fra alle jordens folk, så de skal være din arv, således som du sa ved din tjener Moses da du førte våre fedre ut av Egypten, Herre, Herre! 
11:8:54: Efterat Salomo hadde frembåret hele denne bønn og ydmyke begjæring for Herren, stod han op fra Herrens alter, hvor han hadde ligget på sine knær med hendene utbredt mot himmelen. 
11:8:55: Så trådte han frem og velsignet hele Israels menighet med høi røst og sa: 
11:8:56: Lovet være Herren, som har gitt sitt folk Israel ro, således som han lovte! Ikke et ord er blitt til intet av alle de gode ord han talte ved sin tjener Moses. 
11:8:57: Herren vår Gud være med oss, som han har vært med våre fedre! Han forlate oss ikke og forkaste oss ikke, 
11:8:58: men bøie vårt hjerte til sig, så vi vandrer på alle hans veier og holder hans bud og forskrifter og lover, som han gav våre fedre. 
11:8:59: Og måtte denne min bønn som jeg i ydmykhet har frembåret for Herrens åsyn, være nær Herren vår Gud dag og natt, så han hjelper sin tjener og sitt folk Israel til deres rett, efter som det trenges hver dag, 
11:8:60: sa alle jordens folk må kjenne at Herren er Gud, han og ingen annen, 
11:8:61: Og eders hjerte må være helt med Herren vår Gud, så I vandrer efter hans lover og holder hans bud, som I gjør idag. 
11:8:62: Og kongen og hele Israel med ham ofret slaktoffer for Herrens åsyn. 
11:8:63: To og tyve tusen stykker storfe og hundre og tyve tusen stykker småfe var det takkoffer som Salomo bar frem for Herren. Således var det kongen og alle Israels barn innvidde Herrens hus. 
11:8:64: Samme dag helliget kongen den midterste del av forgården som var foran Herrens hus; for der bar han frem brennofferet og matofferet og fettstykkene av takkofferne, fordi kobberalteret som stod for Herrens åsyn, var for lite til å rumme brennofferet og matofferet og fettstykkene av takkofferne. 
11:8:65: Således feiret Salomo dengang festen*, og hele Israel med ham, en stor mengde folk som var kommet sammen like fra Hamat-veien og til Egyptens bekk; i syv dager og atter syv dager - tilsammen fjorten dager - feiret de festen for Herrens, vår Guds åsyn. # <* 1KG 8, 2.> 
11:8:66: Den åttende dag lot han folket fare, og de bad farvel med kongen og drog hjem igjen, glade og vel til mote over alt det gode Herren hadde gjort mot sin tjener David og mot sitt folk Israel. 
11:9:1: Da Salomo var ferdig med å bygge Herrens hus og kongens hus og alt det hans hu stod til å utføre, 
11:9:2: da åpenbarte Herren sig annen gang for Salomo, likesom han hadde åpenbaret sig for ham i Gibeon. 
11:9:3: Og Herren sa til ham: Jeg har hørt din bønn og ydmyke begjæring som du bar frem for mitt åsyn; jeg har helliget dette hus som du har bygget, så jeg lar mitt navn bo der til evig tid, og mine øine og mitt hjerte skal være der alle dager. 
11:9:4: Hvis du nu vandrer for mitt åsyn, som din far David gjorde, i hjertets renhet og i opriktighet, så du gjør alt det jeg har befalt dig, og holder mine bud og mine lover, 
11:9:5: så vil jeg trygge din kongetrone i Israel til evig tid, således som jeg lovte din far David da jeg sa: Det skal aldri fattes en mann av din ætt på Israels trone. 
11:9:6: Men hvis I og eders barn vender eder bort fra mig og ikke holder de bud og lover som jeg har forelagt eder, men gir eder til å dyrke andre guder og tilbede dem, 
11:9:7: så vil jeg utrydde Israel av det land jeg har gitt dem, og det hus jeg har helliget for mitt navn, vil jeg forkaste fra mitt åsyn, og Israel skal bli til et ordsprog og til en spott blandt alle folk. 
11:9:8: Og skal dette hus enn bli høit så skal dog hver den som går forbi det, forferdes og spotte. og når nogen da spør: Hvorfor har Herren gjort således mot dette land og dette hus? 
11:9:9: da skal de svare: Fordi de forlot Herren sin Gud, som førte deres fedre ut av Egyptens land, og holdt sig til andre guder og tilbad dem og dyrket dem; derfor har Herren ført all denne ulykke over dem. 
11:9:10: Da nu de tyve år var til ende som Salomo hadde bygget på de to hus, Herrens hus og kongens hus, 
11:9:11: gav kong Salomo tyve byer i Galilea til kongen i Tyrus Hiram, som hadde hjulpet ham med sedertre og cypresstre og gull, så meget han ønsket. 
11:9:12: Men da Hiram kom fra Tyrus for å se på de byer Salomo hadde gitt ham, syntes han ikke om dem. 
11:9:13: Han sa: Hvad er dette for byer du har gitt mig, min bror? Og han kalte dem Kabuls* land; så heter de den dag idag. # <* et navn som uttrykker ringeakt.> 
11:9:14: Men Hiram hadde sendt kongen hundre og tyve talenter gull. 
11:9:15: Med de pliktarbeidere som kong Salomo uttok for å bygge Herrens hus og sitt eget hus og Millo og Jerusalems murer og Hasor og Megiddo og Geser, hadde det sig således: 
11:9:16: Farao, kongen i Egypten, hadde draget op og inntatt Geser og satt ild på det og drept kana'anittene som bodde i byen, og gitt den i medgift til sin datter, Salomos hustru; 
11:9:17: men Salomo bygget Geser op igjen, og likeså bygget han op Nedre-Bet-Horon 
11:9:18: og Ba'alat og Tadmor i ørkenen der i landet, 
11:9:19: og alle de oplagsbyer han hadde, og byene for stridsvognene og byene for hestfolket og alt annet som han hadde lyst til å bygge, både i Jerusalem og på Libanon og i hele det land han rådet over. 
11:9:20: Og alt det folk som var blitt tilbake av amorittene, hetittene, ferisittene, hevittene og jebusittene, folk som ikke var av Israels ætt - 
11:9:21: deres efterkommere, så mange som var blitt tilbake i landet efter dem fordi Israels barn ikke hadde maktet å slå dem med bann, dem uttok Salomo til arbeidspliktige træler, og det har de vært til denne dag. 
11:9:22: Men av Israels barn gjorde Salomo ikke nogen til træl; de var hans krigsmenn og hans tjenere og høvdinger og drabanter og høvedsmenn for hans stridsvogner og hestfolk. 
11:9:23: Salomos arbeidsformenn var fem hundre og femti i tallet; de rådet over de folk som utførte arbeidet. 
11:9:24: Så snart Faraos datter var flyttet op fra Davids stad til det hus som han hadde bygget for henne, gikk han i gang med å bygge Millo. 
11:9:25: Tre ganger om året ofret Salomo brennoffer og takkoffer på det alter han hadde bygget for Herren, og han brente røkelse ved det alter som stod for Herrens åsyn, og hadde nu huset ferdig. 
11:9:26: Kong Salomo lot også bygge skiber i Esjon-Geber, som ligger ved Elot på bredden av det Røde Hav i Edoms land. 
11:9:27: Og Hiram sendte sine folk, sjøvant mannskap, på skibene sammen med Salomos folk. 
11:9:28: De kom til Ofir og hentet gull derfra, fire hundre og tyve talenter, og førte det hjem til kong Salomo. 
11:10:1: Da dronningen av Saba hørte Salomos ry, som skyldtes Herrens navn, kom hun for å sette ham på prøve med gåter. 
11:10:2: Hun kom til Jerusalem med et stort følge, med kameler som bar krydderier og en stor mengde gull og dyre stener; og da hun var kommet til Salomo, talte hun til ham om alt som lå henne på hjerte. 
11:10:3: Men Salomo tydet alle hennes gåter; det var ikke et ord av det hun sa, som var dulgt for kongen, så han ikke kunde tyde det. 
11:10:4: Da dronningen av Saba så all Salomos visdom og så det hus han hadde bygget, 
11:10:5: og rettene på hans bord, og hvorledes hans tjenere satt ved bordet, og bordsvennene stod omkring, og hvorledes de var klædd, og hans munnskjenker, og den trapp han gikk op på til Herrens hus, var hun rent ute av sig selv av forundring. 
11:10:6: Og hun sa til kongen: Så var det da sant det jeg hørte hjemme i mitt land om dig og din visdom. 
11:10:7: Jeg trodde ikke det de fortalte, før jeg kom og fikk se det med egne øine; men nu ser jeg at de ikke har fortalt mig halvdelen; du overgår i visdom og lykke det rykte jeg har hørt. 
11:10:8: Lykkelige er dine menn, lykkelige disse dine tjenere som alltid står for ditt åsyn og hører din visdom. 
11:10:9: Lovet være Herren din Gud, som hadde velbehag i dig, så han satte dig på Israels trone! Fordi Herren elsker Israel til evig tid, satte han dig til konge for å håndheve rett og rettferdighet. 
11:10:10: Så gav hun kongen hundre og tyve talenter gull og krydderier i stor mengde og dyre stener; aldri er det mere kommet en sådan mengde krydderier til landet som det dronningen av Saba gav kong Salomo. 
11:10:11: Men også Hirams skiber som hentet gull fra Ofir, hadde ført sandeltre i stor mengde og dyre stener med derfra. 
11:10:12: Av sandeltreet lot kongen gjøre rekkverk til Herrens hus og til kongens hus og citarer og harper for sangerne; aldri er det siden kommet eller blitt sett så meget sandeltre i landet. 
11:10:13: Og kong Salomo gav dronningen av Saba alt det hun hadde lyst til og bad om, foruten de gaver som det sømmet sig for så mektig en konge som Salomo å gi henne. Så tok hun avsted og drog hjem til sitt land med sine tjenere. 
11:10:14: Vekten av det gull som i ett år kom inn til Salomo, var seks hundre og seks og seksti talenter, 
11:10:15: foruten det som kom inn fra kjøbmennene og ved kremmernes handel og fra alle kongene i Arabia og fra stattholderne i landet. 
11:10:16: Og kong Salomo lot gjøre to hundre store skjold av uthamret gull - det gikk seks hundre sekel gull med til hvert skjold - 
11:10:17: og tre hundre små skjold av uthamret gull - til hvert av disse skjold gikk det med tre miner gull - og kongen satte dem i Libanon-skoghuset. 
11:10:18: Kongen lot også gjøre en stor elfenbenstrone og klædde den med rent gull. 
11:10:19: Tronen hadde seks trin og en rund topp baktil; på begge sider av setet var det armer, og tett ved armene stod det to løver; 
11:10:20: og på begge sider av de seks trin stod det tolv løver. Noget sådant har aldri vært gjort i noget annet kongerike. 
11:10:21: Alle kong Salomos drikkekar var av gull, og alle karene i Libanon-skoghuset var av fint gull; der var intet av sølv, det blev ikke regnet for noget i Salomos dager. 
11:10:22: For kongen hadde Tarsis-skiber på havet sammen med Hirams skiber; en gang hvert tredje år kom Tarsis-skibene hjem og hadde med gull og sølv, elfenben og aper og påfugler. 
11:10:23: Kong Salomo blev større enn alle jordens konger i rikdom og visdom. 
11:10:24: Fra alle jordens kanter kom folk for å se kong Salomo og høre hans visdom, som Gud hadde lagt i hans hjerte, 
11:10:25: og hver av dem hadde gaver med: sølvkar og gullkar og klær og våben og krydderier, hester og mulesler; så gjorde de år om annet. 
11:10:26: Salomo la sig til mange stridsvogner og hestfolk; han hadde fjorten hundre stridsvogner og tolv tusen hestfolk; dem la han dels i vognbyene, dels hos sig selv i Jerusalem. 
11:10:27: Kongen gjorde sølvet i Jerusalem like så almindelig som sten, og sedertre like så almindelig som morbærtrærne i lavlandet. 
11:10:28: Sine hester lot Salomo innføre fra Egypten; en del kjøbmenn som kongen sendte avsted, hentet fra tid til annen en flokk for en fastsatt pris. 
11:10:29: Hver vogn som hentedes op fra Egypten og innførtes, kostet seks hundre sekel sølv og hver hest hundre og femti. Og på samme måte hentet de også vogner og hester derfra til alle hetittenes konger og til kongene i Syria. 
11:11:1: Men kong Salomo elsket mange fremmede kvinner foruten Faraos datter, moabittiske, ammonittiske, edomittiske, sidoniske, hetittiske, 
11:11:2: kvinner av de hedningefolk om hvilke Herren hadde sagt til Israels barn: I skal ikke gi eder i lag med dem, og de ikke med eder*; ellers kommer de visselig til å vende eders hjerte til sine guder. Til dem holdt Salomo sig og elsket dem. # <* d.e. for å inngå ekteskap med dem.> 
11:11:3: Han hadde syv hundre hustruer av fyrstelig rang og tre hundre medhustruer; disse hans hustruer bøide hans sinn. 
11:11:4: Og da Salomo blev gammel, vendte hustruene hans hjerte til andre guder, og hans hjerte var ikke helt med Herren hans Gud, således som hans far Davids hjerte hadde vært. 
11:11:5: Og Salomo fulgte Astarte, sidoniernes guddom, og Milkom, ammonittenes vederstyggelighet. 
11:11:6: Og Salomo gjorde hvad ondt var i Herrens øine, og fulgte ikke trolig efter Herren, således som hans far David hadde gjort. 
11:11:7: På den tid bygget Salomo en offerhaug for Kamos, Moabs vederstyggelighet, på det fjell som ligger midt imot Jerusalem, og for Molok, Ammons barns vederstyggelighet. 
11:11:8: Og det samme gjorde han for alle sine fremmede hustruer, som brente røkelse og ofret til sine guder. 
11:11:9: Da blev Herren vred på Salomo fordi han hadde vendt sitt hjerte bort fra Herren, Israels Gud, som hadde åpenbaret sig for ham to ganger 
11:11:10: og uttrykkelig forbudt ham å følge andre guder; men han hadde ikke holdt sig efter Herrens bud. 
11:11:11: Da sa Herren til Salomo: Siden dette er kommet dig i sinne, og du ikke har holdt min pakt og mine lover, som jeg har gitt dig, så vil jeg rive riket fra dig og gi det til din tjener. 
11:11:12: Men for din far Davids skyld vil jeg ikke gjøre det i din tid - fra din sønn vil jeg rive riket. 
11:11:13: Dog vil jeg ikke rive hele riket fra ham; en stamme vil jeg gi din sønn for min tjener Davids skyld og for Jerusalems skyld, som jeg har utvalgt. 
11:11:14: Og Herren opreiste Salomo en motstander i edomitten Hadad; han var av kongeætten i Edom. 
11:11:15: For dengang David var i Edom, og hærføreren Joab drog op for å begrave de falne og slo ihjel alle menn i Edom. 
11:11:16: i seks måneder blev Joab og hele Israel der, til han hadde utryddet alle menn i Edom - 
11:11:17: da flyktet Hadad med nogen edomittiske menn som hørte til hans fars tjenere, og tok veien til Egypten. Hadad var dengang en liten gutt. 
11:11:18: De brøt op fra Midian og kom til Paran; fra Paran tok de nogen menn med sig og kom så til Egypten til Farao, kongen i Egypten, og han gav ham et hus og tilsa ham livsophold og lot ham få jord. 
11:11:19: Og Hadad fant stor nåde for Faraos øine, så han gav ham til hustru en søster av sin egen hustru, dronning Tahpenes. 
11:11:20: Og Tahpenes' søster fødte ham sønnen Genubat; Tahpenes avvente ham i Faraos hus, og siden var Genubat i Faraos hus blandt Faraos egne barn. 
11:11:21: Da nu Hadad i Egypten fikk høre at David hadde lagt sig til hvile hos sine fedre, og at hærføreren Joab var død, sa han til Farao: La mig fare, så jeg kan dra hjem til mitt land! 
11:11:22: Farao sa til ham: Hvad fattes dig her hos mig siden du ønsker å dra hjem til ditt land? Han svarte: Ingen ting; men du må endelig la mig få fare. 
11:11:23: Gud opreiste Salomo en annen motstander, Reson, Eliadas sønn, som var flyktet fra sin herre Hadadeser, kongen i Soba. 
11:11:24: Han samlet folk om sig og blev fører for en røverflokk, dengang David slo dem* ihjel; og de** drog til Damaskus og slo sig ned der og gjorde sig til herrer i Damaskus. # <* Hadadesers folk.> # <** Reson og hans menn.> 
11:11:25: Han var en motstander av Israel så lenge Salomo levde, og både han og Hadad voldte Israel skade. Han hatet Israel og var konge over Syria. 
11:11:26: Så var det en av Salomos tjenere, Jeroboam, Nebats sønn, en efra'imitt fra Sereda: hans mor hette Serua og var enke. Han reiste sig også mot kongen. 
11:11:27: Og således gikk det til at han reiste sig mot kongen: Salomo bygget på Millo - han vilde lukke det åpne sted i sin far Davids stad. 
11:11:28: Nu var Jeroboam en dyktig kar, og da Salomo så at den unge mann var en duelig arbeider, satte han ham til å ha tilsyn med alt det arbeid Josefs hus hadde å utføre. 
11:11:29: På den tid hendte det engang da Jeroboam var gått ut fra Jerusalem, at profeten Akia fra Silo traff ham på veien; han* hadde en ny kappe på, og de to var alene på marken. # <* profeten.> 
11:11:30: Da tok Akia den nye kappe han hadde på, og rev den i tolv stykker. 
11:11:31: Og han sa til Jeroboam: Ta dig ti stykker! For så sier Herren, Israels Gud: Jeg river riket ut av Salomos hånd og gir dig de ti stammer; 
11:11:32: men den ene stamme skal han få ha for min tjener Davids skyld og for Jerusalems skyld, den stad som jeg har utvalgt blandt alle Israels stammer. 
11:11:33: Så vil jeg gjøre fordi de har forlatt mig og tilbedt Astarte, sidoniernes guddom, og Kamos, Moabs gud, og Milkom, Ammons barns gud, og ikke vandret på mine veier og ikke gjort hvad rett er i mine øine, og ikke holdt mine lover og bud, således som hans far David gjorde. 
11:11:34: Dog vil jeg ikke ta noget av riket fra ham selv, men la ham være fyrste så lenge han lever, for min tjener Davids skyld, som jeg utvalgte, og som holdt sig efter mine bud og lover. 
11:11:35: Men jeg vil ta riket fra hans sønn og gi dig det - de ti stammer; 
11:11:36: men hans sønn vil jeg gi en stamme, forat min tjener David alltid må ha en lampe brennende* for mitt åsyn i Jerusalem, den stad jeg har utvalgt mig for å la mitt navn bo der. # <* d.e. forat Davids hus ikke skal gå til grunne.> 
11:11:37: Men dig vil jeg ta og la dig råde over alt hvad du attrår, og være konge over Israel. 
11:11:38: Og hvis du hører på alt det jeg byder dig, og vandrer på mine veier og gjør hvad rett er i mine øine, så du holder mine lover og bud, således som min tjener David gjorde, så vil jeg være med dig og bygge dig et hus som står fast, likesom jeg gjorde for David, og jeg vil gi dig Israel. 
11:11:39: Og Davids ætt vil jeg ydmyke på grunn av det som er gjort, dog ikke for alle tider. 
11:11:40: Salomo søkte nu å drepe Jeroboam; men Jeroboam tok avsted og flyktet til Egypten, til Sisak, kongen i Egypten*, og han blev i Egypten til Salomos død. # <* 1KG 14, 25 fg.> 
11:11:41: Hvad som ellers er å fortelle om Salomo, om alt det han gjorde, og om hans visdom, det er opskrevet i Salomos krønike. 
11:11:42: Den tid Salomo var konge i Jerusalem over hele Israel, var firti år. 
11:11:43: Så la Salomo sig til hvile hos sine fedre og blev begravet i sin far Davids stad, og hans sønn Rehabeam blev konge i hans sted. 
11:12:1: Rehabeam drog til Sikem; for hele Israel var kommet til Sikem for å gjøre ham til konge. 
11:12:2: Da Jeroboam, Nebats sønn, hørte det - han var ennu i Egypten; han var flyktet dit for kong Salomo og bodde således nu i Egypten, 
11:12:3: men de sendte bud dit og kalte ham hjem - da kom han og hele Israels menighet og talte til Rehabeam og sa: 
11:12:4: Din far gjorde vårt åk tungt; men lett nu du den hårde tjeneste og det tunge åk som din far la på oss! Så vil vi tjene dig. 
11:12:5: Han svarte dem: Gå bort og vent i tre dager og kom så hit til mig igjen! Så gikk folket bort. 
11:12:6: Og kong Rehabeam rådførte sig med de gamle, som hadde gjort tjeneste hos hans far Salomo, mens han ennu levde, og han sa: Hvad råder I mig til å svare dette folk? 
11:12:7: De svarte ham: Hvis du idag lyder dette folk og retter dig efter dem og gir dem gode ord til svar, så vil de være dine tjenere for alle tider. 
11:12:8: Men han aktet ikke på det råd som de gamle hadde gitt ham, men rådførte sig med de unge, som var vokset op sammen med ham, og som nu var i hans tjeneste. 
11:12:9: Han spurte dem: Hvad råder I til at vi skal svare dette folk som har sagt til mig: Lett det åk din far la på oss? 
11:12:10: Og de unge, som var vokset op sammen med ham, svarte ham: Så skal du si til dette folk som sa til dig: Din far gjorde vart åk tungt, men gjør du det lettere for oss - så skal du tale til dem: Min lillefinger er tykkere enn min fars lender; 
11:12:11: har nu min far lagt et tungt åk på eder, så vil jeg gjøre eders åk ennu tyngre; har min far tuktet eder med sveper, så vil jeg tukte eder med skorpioner. 
11:12:12: Så kom Jeroboam og alt folket til Rehabeam den tredje dag, således som kongen hadde sagt: Kom til mig igjen om tre dager! 
11:12:13: Da gav kongen folket et hårdt svar - han aktet ikke på det råd som de gamle hadde gitt ham, 
11:12:14: men svarte dem som de unge hadde rådet til: Har min far gjort eders åk tungt, så vil jeg gjøre eders åk ennu tyngre; har min far tuktet eder med sveper, så vil jeg tukte eder med skorpioner. 
11:12:15: Kongen hørte ikke på folket; for Herren styrte det så forat hans ord skulde bli opfylt, det som Herren hadde talt til Jeroboam, Nebats sønn, ved Akia fra Silo. 
11:12:16: Da nu hele Israel så at kongen ikke hørte på dem, svarte folket kongen og sa: Hvad del har vi i David? Vi har ingen lodd i Isais sønn. Til dine telt, Israel! Se nu selv til ditt hus, David! Så drog Israel hjem igjen. 
11:12:17: Bare over de Israels barn som bodde i Judas byer, blev Rehabeam konge. 
11:12:18: Da sendte kong Rehabeam Adoram avsted, han som hadde opsyn med pliktarbeidet; men hele Israel stenet ham, så han døde, og kong Rehabeam selv måtte i all hast springe op i sin vogn og flykte til Jerusalem. 
11:12:19: Således falt Israel fra Davids hus, og så har det vært til denne dag. 
11:12:20: Da hele Israel hørte at Jeroboam var kommet tilbake, sendte de bud og kalte ham til folkeforsamlingen og gjorde ham til konge over hele Israel; det var ikke nogen som holdt sig til Davids hus, uten Juda stamme alene. 
11:12:21: Da Rehabeam kom til Jerusalem, samlet han hele Judas hus og Benjamins stamme, hundre og åtti tusen utvalgte krigsmenn, forat de skulde stride mot Israels hus og vinne riket tilbake for Rehabeam, Salomos sønn. 
11:12:22: Da kom Guds ord til Semaja, den Guds mann, og det lød således: 
11:12:23: Si til Rehabeam, Salomos sønn, Judas konge, og til hele Judas og Benjamins hus og resten av folket: 
11:12:24: Så sier Herren: I skal ikke dra op og stride mot eders brødre, Israels barn. Vend hjem igjen hver til sitt hus! For det som har hendt, er kommet fra mig. Da lød de Herrens ord; de vendte om og drog bort, som Herren hadde sagt. 
11:12:25: Jeroboam bygget Sikem i Efra'im-fjellene om til en fast by og bosatte sig der; siden drog han derfra og bygget også Pnuel om til en fast by. 
11:12:26: Og Jeroboam tenkte ved sig selv: Riket kunde snart komme tilbake til Davids hus; 
11:12:27: dersom dette folk drar op for å bære frem offer i Herrens hus i Jerusalem, så vil deres hjerte igjen vende sig til deres herre Rehabeam, Judas konge, og så vil de drepe mig og vende tilbake til Judas konge Rehabeam. 
11:12:28: Og kongen holdt råd, og så gjorde han to gullkalver; og han sa til folket: I har nu lenge nok draget op til Jerusalem; se, her er dine guder, Israel, som førte dig op fra Egyptens land. 
11:12:29: Og han stilte den ene op i Betel, og den andre satte han i Dan. 
11:12:30: Dette blev en årsak til synd; folket gikk like til Dan for å trede frem for den ene av dem. 
11:12:31: Han bygget også hus på offerhaugene og gjorde hvem han vilde av folket til prester, enda de ikke var av Levis barn. 
11:12:32: Og Jeroboam holdt en fest i den åttende måned, på den femtende dag i måneden, i likhet med festen i Juda*, og han ofret på alteret. Således gjorde han i Betel; han ofret til kalvene som han hadde gjort, og han lot dem som han hadde gjort til prester ved offerhaugene, gjøre tjeneste i Betel, # <* 3MO 23, 34.> 
11:12:33: og han ofret på det alter han hadde gjort i Betel, den femtende dag i den åttende måned, den måned som han hadde valgt efter sitt eget hode. Da holdt han en fest for Israels barn og ofret på alteret og brente røkelse. 
11:13:1: Men da kom det på Herrens bud en Guds mann til Betel fra Juda, nettop som Jeroboam stod ved alteret for å brenne røkelse. 
11:13:2: Og han ropte på Herrens bud mot alteret: Alter! Alter! Så sier Herren: Det skal fødes en sønn for Davids hus, Josias skal han hete, og på dig skal han ofre offerhaugenes prester, som brenner røkelse på dig, og det skal brennes menneskeben på dig. 
11:13:3: På samme tid forkynte han et tegn og sa: Dette er tegnet på at Herren har talt: Alteret skal revne, og asken som er på det, skal spredes. 
11:13:4: Da kong Jeroboam hørte de ord som den Guds mann ropte mot alteret i Betel, rakte han ut sin hånd fra alteret og sa: Grip ham! Men hånden som han rakte ut mot ham, visnet, og han kunde ikke dra den til sig igjen. 
11:13:5: Og alteret revnet, og asken spredtes fra alteret; det var det tegn som den Guds mann hadde forkynt på Herrens bud. 
11:13:6: Da tok kongen til orde og sa til den Guds mann: Bønnfall Herren din Gud og bed for mig at jeg må kunne dra min hånd til mig igjen! Og den Guds mann bønnfalt Herren, og kongen kunde dra sin hånd til sig igjen - den blev som den hadde vært før. 
11:13:7: Da sa kongen til den Guds mann: Kom hjem med mig og vederkveg dig og la mig få gi dig en gave! 
11:13:8: Men den Guds mann sa til kongen: Om du gav mig halvdelen av ditt hus, vilde jeg dog ikke gå hjem til dig; på dette sted vil jeg hverken ete brød eller drikke vann. 
11:13:9: For dette bud fikk jeg av Herren: Du skal hverken ete brød eller drikke vann*, og du skal ikke vende tilbake den vei du gikk. # <* på dette sted, 1KG 13, 16. 17.> 
11:13:10: Så tok han avsted en annen vei og vendte ikke tilbake den vei han var kommet til Betel. 
11:13:11: I Betel bodde det en gammel profet; hans sønn kom og fortalte ham alt det som den Guds mann den dag hadde gjort i Betel, og de ord han hadde talt til kongen. Da de* hadde fortalt sin far dette, # <* sønnene.> 
11:13:12: spurte han dem: Hvad vei tok han? For hans sønner hadde sett hvad vei han hadde tatt den Guds mann som var kommet fra Juda. 
11:13:13: Da sa han til sine sønner: Sal asenet for mig! Så salte de asenet for ham, og han satte sig på det 
11:13:14: og red avsted efter den Guds mann. Han fant ham sittende under en terebinte, og han sa til ham: Er du den Guds mann som er kommet fra Juda? Han svarte: Ja, det er jeg. 
11:13:15: Da sa han til ham: Kom hjem med mig og få dig litt mat! 
11:13:16: Men han sa: Jeg kan ikke vende tilbake med dig og komme hjem til dig; jeg vil hverken ete brød eller drikke vann med dig på dette sted. 
11:13:17: For det kom et ord til mig fra Herren: Du skal hverken ete brød eller drikke vann der; du skal ikke gå tilbake den vei du gikk. 
11:13:18: Da sa han til ham: Jeg er også en profet likesom du, og en engel har talt et ord til mig fra Herren og sagt: Få ham med dig tilbake til ditt hus, så han kan få sig mat og drikke! Men da han sa det, løi han for ham. 
11:13:19: Så vendte han da tilbake med ham og åt og drakk i hans hus. 
11:13:20: Men mens de satt til bords, kom Herrens ord til den profet som hadde fått ham med sig tilbake, 
11:13:21: og han ropte til den Guds mann som var kommet fra Juda: Så sier Herren: Fordi du var gjenstridig mot Herrens ord og ikke holdt det bud Herren din Gud gav dig, 
11:13:22: men vendte tilbake og åt og drakk på det sted hvorom han hadde sagt til dig: Du skal hverken ete brød eller drikke vann der, så skal ditt lik ikke komme i dine fedres grav. 
11:13:23: Da han nu hadde ett og drukket, salte han asenet for ham - for profeten som han hadde fått med sig tilbake. 
11:13:24: Og han tok avsted; men på veien kom en løve imot ham og drepte ham; og hans lik lå slengt bortefter veien, og asenet stod ved siden av det og løven stod også ved siden av liket. 
11:13:25: Da fór det nogen menn forbi; de så liket som lå slengt bortefter veien, og løven som stod ved siden av liket; og da de kom til den by hvor den gamle profet bodde, fortalte de det der. 
11:13:26: Da profeten som hadde fått ham med tilbake fra veien, hørte det, sa han: Det er den Guds mann som var gjenstridig mot Herrens ord; derfor har Herren gitt ham i løvens vold, og den har sønderrevet ham og drept ham efter det ord som Herren hadde talt til ham. 
11:13:27: Derefter sa han til sine sønner: Sal asenet for mig: Så salte de det. 
11:13:28: Og han tok avsted og fant hans lik slengt bortefter veien og asenet og løven stående ved siden av liket; løven hadde ikke fortært liket og heller ikke sønderrevet asenet. 
11:13:29: Da tok profeten op liket av den Guds mann og la ham på asenet og førte ham tilbake; og den gamle profet gikk inn i sin by for å holde sørgehøitid over ham og begrave ham. 
11:13:30: Han la hans lik i sin egen grav, og de holdt sørgehøitid over ham og ropte: Ve, min bror! 
11:13:31: Da han nu hadde begravet ham, sa han til sine sønner: Når jeg dør, skal I begrave mig i den grav hvor den Guds mann ligger begravet; I skal legge mine ben ved siden av hans ben. 
11:13:32: For det ord skal gå i opfyllelse som han på Herrens bud ropte mot alteret i Betel og mot alle offerhaugenes helligdommer i Samarias byer. 
11:13:33: Men heller ikke efter dette vendte Jeroboam om fra sin onde vei, men blev ved å gjøre hvem han vilde av folket til prester ved offerhaugene; han fylte* alle deres hender som hadde lyst dertil, så de blev prester ved haugene. # <* 2MO 28, 41.> 
11:13:34: Og dette blev årsak til synd for Jeroboams hus og til at det blev utryddet og utslettet av jorden. 
11:14:1: På den tid blev Abia, Jeroboams sønn, syk. 
11:14:2: Da sa Jeroboam til sin hustru: Stå op og forklæ dig, så ingen kan se at du er Jeroboams hustru, og gå så til Silo! Der bor profeten Akia, han som sa om mig at jeg skulde bli konge over dette folk. 
11:14:3: Ta med dig ti brød og nogen kaker og en krukke med honning og gå inn til ham! Han vil si dig hvorledes det skal gå med gutten. 
11:14:4: Og Jeroboams hustru gjorde så; hun stod op og gikk til Silo og kom til Akias hus. Men Akia kunde ikke se; for hans øine var stive av alderdom. 
11:14:5: Men Herren hadde sagt til Akia: Nu kommer Jeroboams hustru for å høre hvad du vil si om hennes sønn, for han er syk; så og så skal du si til henne; men når hun kommer, vil hun late som hun er fremmed. 
11:14:6: Da nu Akia hørte lyden av hennes fottrin i døren, sa han: Kom inn, Jeroboams hustru! Hvorfor later du som du er fremmed? Det er pålagt mig å forkynne dig et hårdt budskap. 
11:14:7: Gå og si til Jeroboam: Så sier Herren, Israels Gud: Jeg har ophøiet dig midt iblandt folket og satt dig til fyrste over mitt folk Israel 
11:14:8: og revet riket fra Davids hus og gitt dig det, men du har ikke vært som min tjener David, som holdt mine bud og fulgte mig av alt sitt hjerte, så han ikke gjorde annet enn det som rett var i mine øine; 
11:14:9: men du har båret dig verre at enn alle de som har vært før dig, og er gått avsted og har gjort dig andre guder og støpte billeder og således vakt min harme, og du har kastet mig bak din rygg: 
11:14:10: Derfor vil jeg føre ulykke over Jeroboams hus og utrydde av Jeroboams ætt alle menn, både umyndige og myndige i Israel, og jeg vil feie efter Jeroboams hus, som en feier skarnet bort, inntil det er aldeles ute med ham. 
11:14:11: Den av Jeroboams ætt som dør i byen, skal hundene fortære, og den som dør på marken, skal himmelens fugler fortære; for så har Herren talt. 
11:14:12: Stå nu du op og gå hjem! Så snart dine føtter treder inn i byen, skal barnet dø. 
11:14:13: Og hele Israel skal holde sørgehøitid over ham, og de skal begrave ham; han er den eneste av Jeroboams hus som skal komme i grav, fordi han var den eneste i Jeroboams hus hos hvem det fantes noget som Herren, Israels Gud, hadde velbehag i. 
11:14:14: Men Herren skal opreise sig en konge over Israel som skal utrydde Jeroboams hus den samme dag. Dog, hvad sier jeg? Allerede nu er det skjedd. 
11:14:15: Og Herren skal slå Israel, så det blir som sivet som vugger hit og dit i vannet, og han skal rykke Israel op av dette gode land som han gav deres fedre, og strø dem omkring hinsides elven, fordi de har gjort sig Astarte-billeder og vakt Herrens harme. 
11:14:16: Og han skal gi Israel i fiendevold for de synders skyld som Jeroboam har gjort, og som han har fått Israel til å gjøre. 
11:14:17: Da stod Jeroboams hustru op og gikk sin vei og kom til Tirsa; i det samme hun trådte på husets dørtreskel, døde gutten. 
11:14:18: Og de begravde ham, og hele Israel holdt sørgehøitid over ham efter det ord som Herren hadde talt ved sin tjener, profeten Akia. 
11:14:19: Hvad som ellers er å fortelle om Jeroboam, om hans kriger og om hans regjering, det er opskrevet i Israels kongers krønike. 
11:14:20: Den tid Jeroboam var konge, var to og tyve år; så la han sig til hvile hos sine fedre, og hans sønn Nadab blev konge i hans sted. 
11:14:21: Rehabeam, Salomos sønn, var konge i Juda; han var en og firti år gammel da han blev konge, og han regjerte sytten år i Jerusalem, den stad som Herren hadde utvalgt blandt alle Israels stammer for å la sitt navn bo der; hans mor hette Na'ama og var fra Ammon. 
11:14:22: Og Juda gjorde hvad ondt var i Herrens øine, og med de synder de gjorde, egget de ham til nidkjærhet mere enn deres fedre hadde gjort. 
11:14:23: Også de bygget sig offerhauger og gjorde sig støtter og Astarte-billeder på hver høi bakke og under hvert grønt tre; 
11:14:24: der var endog tempel-bolere* i landet; de tok efter de vederstyggelige skikker hos alle de hedningefolk som Herren hadde drevet bort for Israels barn. # <* d.e. sådanne som drev utukt til ære for avgudene.> 
11:14:25: Da hendte det i kong Rehabeams femte år at Egyptens konge Sisak drog op mot Jerusalem. 
11:14:26: Og han tok skattene i Herrens hus og skattene i kongens hus; alt sammen tok han. Han tok også alle de gullskjold som Salomo hadde latt gjøre. 
11:14:27: Istedenfor dem lot kong Rehabeam gjøre kobberskjold og betrodde dem til høvedsmennene for drabantene som voktet inngangen til kongens hus; 
11:14:28: og så ofte kongen gikk inn i Herrens hus, bar drabantene dem og tok dem så med tilbake til vaktstuen. 
11:14:29: Hvad som ellers er å fortelle om Rehabeam og om alt det han gjorde, er opskrevet i Judas kongers krønike. 
11:14:30: Mellem Rehabeam og Jeroboam var det krig hele tiden. 
11:14:31: Og Rehabeam la sig til hvile hos sine fedre og blev begravet hos sine fedre i Davids stad. Hans mor hette Na'ama og var fra Ammon. Hans sønn Abiam blev konge i hans sted. 
11:15:1: I kong Jeroboams, Nebats sønns attende år blev Abiam konge over Juda. 
11:15:2: Han regjerte tre år i Jerusalem. Hans mor hette Ma'aka; hun var datter til Abisalom. 
11:15:3: Han vandret i alle de synder som hans far hadde gjort før ham, og hans hjerte var ikke helt med Herren hans Gud, som hans far Davids hjerte hadde vært; 
11:15:4: men for Davids skyld lot Herren hans Gud en lampe brenne for ham i Jerusalem, idet han opreiste hans sønn efter ham og lot Jerusalem bli stående, 
11:15:5: fordi David gjorde hvad rett var i Herrens øine, og i hele sitt liv ikke vek av fra noget av det han hadde befalt ham, undtagen det som hendte med hetitten Uria. 
11:15:6: Mellem Rehabeam og Jeroboam var det krig så lenge han levde. 
11:15:7: Hvad som ellers er å fortelle om Abiam og om alt det han gjorde, er opskrevet i Judas kongers krønike. Mellem Abiam og Jeroboam var det også krig. 
11:15:8: Og Abiam la sig til hvile hos sine fedre, og de begravde ham i Davids stad; og hans sønn Asa blev konge i hans sted. 
11:15:9: I Jeroboams, Israels konges tyvende år blev Asa konge over Juda. 
11:15:10: Han regjerte en og firti år i Jerusalem. Hans mor* hette Ma'aka; hun var datter til Abisalom. # <* farmor, 1KG 15, 2.> 
11:15:11: Asa gjorde hvad rett var i Herrens øine, likesom hans far David hadde gjort. 
11:15:12: Han drev tempel-bolerne ut av landet og fikk bort alle de motbydelige avguder som hans fedre hadde gjort. 
11:15:13: Han avsatte endog sin mor Ma'aka fra hennes verdighet, fordi hun hadde gjort et gruelig avgudsbillede for Astarte; Asa hugg hennes gruelige avgudsbillede ned og brente det i Kidrons dal. 
11:15:14: Men offerhaugene blev ikke nedlagt; dog var Asas hjerte helt med Herren så lenge han levde. 
11:15:15: Han lot de ting hans far hadde helliget, og dem han selv hadde helliget, føre inn i Herrens hus, både sølv og gull og andre ting. 
11:15:16: Mellem Asa og Baesa, Israels konge, var det krig så lenge de levde. 
11:15:17: Baesa, Israels konge, drog op mot Juda og bygget festningsverker i Rama for å hindre enhver fra å dra fra eller til Asa, Judas konge. 
11:15:18: Da tok Asa alt det sølv og gull som var tilbake i skattkammerne i Herrens hus, og likeså skattene i kongens hus og overgav dem til sine tjenere; så sendte kong Asa dem til Benhadad, kongen i Syria, som bodde i Damaskus og var sønn til Tabrimmon, Hesjons sønn, med de ord: 
11:15:19: Mellem mig og dig er det jo et forbund, som det var mellem min far og din far. Her sender jeg dig en gave av sølv og gull; bryt nu ditt forbund med Baesa, Israels konge, så han må dra bort fra mig! 
11:15:20: Benhadad lød kong Asa og sendte sine hærførere mot Israels byer og inntok Ijon og Dan og Abel-Bet-Ma'aka og hele Kinneret med hele Naftalis land. 
11:15:21: Da Baesa hørte det, holdt han op med å bygge festningsverker i Rama og gav sig til i Tirsa. 
11:15:22: Men kong Asa opbød hele Juda - ingen var fritatt - og de førte bort stenene og tømmeret som Baesa hadde brukt til festningsarbeidet i Rama; med det bygget kong Asa festningsverker i Geba i Benjamin og i Mispa. 
11:15:23: Hvad som ellers er å fortelle om Asa, om alle hans store gjerninger og alt det han gjorde, og om de byer han bygget, det er opskrevet i Judas kongers krønike. Men i sin alderdom fikk han en sykdom i føttene. 
11:15:24: Og Asa la sig til hvile hos sine fedre og blev begravet hos sine fedre i sin far Davids stad, og hans sønn Josafat blev konge i hans sted. 
11:15:25: Nadab, Jeroboams sønn, blev konge over Israel i Asas, Judas konges annet år, og han regjerte over Israel i to år. 
11:15:26: Han gjorde hvad ondt var i Herrens øine, og vandret på sin fars vei og i hans synd, den som han hadde fått Israel til å gjøre. 
11:15:27: Men Baesa, Akias sønn, av Issakars hus, fikk i stand en sammensvergelse mot ham; og Baesa slo ham ihjel ved Gibbeton, som tilhørte filistrene, mens Nadab og hele Israel holdt Gibbeton kringsatt. 
11:15:28: Baesa drepte ham i Asas, Judas konges tredje år og blev så konge i hans sted. 
11:15:29: Og da han var blitt konge, slo han hele Jeroboams hus ihjel; han lot ikke en levende sjel bli tilbake av Jeroboams hus, men utryddet det efter det ord som Herren hadde talt ved sin tjener Akia fra Silo; 
11:15:30: dette skjedde for de synders skyld som Jeroboam hadde gjort, og som han hadde fått Israel til å gjøre, og fordi han hadde vakt Herrens, Israels Guds harme. 
11:15:31: Hvad som ellers er å fortelle om Nadab og om alt det han gjorde, er opskrevet i Israels kongers krønike. 
11:15:32: Mellem Asa og Israels konge Baesa var det krig så lenge de levde. 
11:15:33: I Asas, Judas konges tredje år blev Baesa, Akias sønn, konge over hele Israel i Tirsa, og han var konge i fire og tyve år. 
11:15:34: Han gjorde hvad ondt var i Herrens øine, og vandret på Jeroboams vei og i hans synd, den som han hadde fått Israel til å gjøre. 
11:16:1: Og Herrens ord kom til Jehu, Hananis sønn, om Baesa, og det lød således: 
11:16:2: Fordi jeg har ophøiet dig av støvet og satt dig til fyrste over mitt folk Israel, men du har vandret på Jeroboams vei og fått mitt folk Israel til å synde, så de vekker min harme ved sine synder, 
11:16:3: så vil jeg nu feie efter Baesa og hans hus og gjøre med ditt hus som med Jeroboams, Nebats sønns hus. 
11:16:4: Den av Baesas ætt som dør i byen, skal hundene fortære, og den av hans ætt som dør på marken, skal himmelens fugler fortære. 
11:16:5: Hvad som ellers er å fortelle om Baesa, om det han gjorde, og om hans store gjerninger, det er opskrevet i Israels kongers krønike. 
11:16:6: Og Baesa la sig til hvile hos sine fedre og blev begravet i Tirsa, og hans sønn Ela blev konge i hans sted. 
11:16:7: Men Herrens ord var ved profeten Jehu, Hananis sønn, kommet til Baesa og hans hus, ikke alene for alt det onde han hadde gjort for Herrens øine, idet han vakte hans harme ved sine henders verk, så han var lik Jeroboams ætt, men også fordi han hadde slått denne ætt ihjel. 
11:16:8: I Judas konge Asas seks og tyvende år blev Ela, Baesas sønn, konge over Israel i Tirsa, og han var konge i to år. 
11:16:9: Hans tjener Simri, som var høvedsmann for den ene halvdel av stridsvognene, fikk i stand en sammensvergelse mot ham. Og da han engang var i Tirsa og drakk sig drukken hos Arsa, slottshøvdingen i Tirsa, 
11:16:10: kom Simri og slo ham ihjel - det var i Judas konge Asas syv og tyvende år - og så blev han selv konge i hans sted. 
11:16:11: Og da han var blitt konge og satt på sin trone, slo han hele Baesas hus ihjel; han lot ikke en eneste mann av hans ætt bli i live, hverken hans nærmeste frender eller hans venner. 
11:16:12: Således utryddet Simri hele Baesas hus efter det ord som Herren hadde talt til Baesa ved profeten Jehu, 
11:16:13: for alle de synders skyld som Baesa og hans sønn Ela hadde gjort, og som de hadde fått Israel til å gjøre, så de vakte Herrens, Israels Guds harme ved sine tomme guder. 
11:16:14: Hvad som ellers er å fortelle om Ela og om alt det han gjorde, er opskrevet i Israels kongers krønike. 
11:16:15: I Judas konge Asas syv og tyvende år blev Simri konge og regjerte i syv dager i Tirsa, mens folket lå i leir omkring Gibbeton, som tilhørte filistrene. 
11:16:16: Da folket som lå i leir, fikk høre at Simri hadde fått i stand en sammensvergelse og slått kongen ihjel, gjorde hele Israel samme dag i leiren hærføreren Omri til konge over Israel. 
11:16:17: Så drog Omri med hele Israel op fra Gibbeton, og de kringsatte Tirsa. 
11:16:18: Men da Simri så at byen var inntatt, gikk han inn i borgen i kongens hus og brente kongens hus av over sitt hode og døde, 
11:16:19: for de synders skyld som han hadde gjort, idet han gjorde hvad ondt var i Herrens øine, og vandret på Jeroboams vei og i den synd som Jeroboam hadde gjort, så han fikk Israel til å synde. 
11:16:20: Hvad som ellers er å fortelle om Simri og om den sammensvergelse han fikk i stand, det er opskrevet i Israels kongers krønike. 
11:16:21: Da delte folket - Israel - sig i to deler; den ene halvdel av folket holdt med Tibni, Ginats sønn, og gjorde ham til konge, men den andre halvdel holdt med Omri. 
11:16:22: Men de som holdt med Omri, fikk overhånd over dem som holdt med Tibni, Ginats sønn; og Tibni døde, og Omri blev konge. 
11:16:23: I Asas, Judas konges en og trettiende år blev Omri konge over Israel, og han var konge i tolv år. I Tirsa regjerte han i seks år. 
11:16:24: Han kjøpte Samaria-fjellet av Semer for to talenter sølv; og han bygget en by på fjellet og kalte den Samaria efter Semer, som hadde eid fjellet. 
11:16:25: Men Omri gjorde hvad ondt var i Herrens øine; han bar sig endog verre at enn nogen av dem som hadde vært før ham. 
11:16:26: Han vandret på alle Jeroboams, Nebats sønns veier og i hans synder, dem som han hadde fått Israel til å gjøre, så de vakte Herrens, Israels Guds harme ved sine tomme guder. 
11:16:27: Hvad som ellers er å fortelle om Omri, om det han gjorde, og om de store gjerninger han utførte, det er opskrevet i Israels kongers krønike. 
11:16:28: Og Omri la sig til hvile hos sine fedre og blev begravet i Samaria, og hans sønn Akab blev konge i hans sted. 
11:16:29: Akab, Omris sønn, blev konge over Israel i Asas, Judas konges åtte og trettiende år; og Akab, Omris sønn, regjerte over Israel i Samaria i to og tyve år. 
11:16:30: Akab, Omris sønn, gjorde hvad ondt var i Herrens øine, mere enn nogen av dem som hadde vært før ham. 
11:16:31: Og som om det var for lite at han vandret i Jeroboams, Nebats sønns synder, tok han Jesabel, datter av sidoniernes konge Etba'al, til hustru og gav sig til å dyrke Ba'al og tilbede ham. 
11:16:32: Han opreiste et alter for Ba'al i det Ba'als-tempel han hadde bygget i Samaria. 
11:16:33: Akab gjorde også et Astarte-billede, og han gjorde ennu mere til å vekke Herrens, Israels Guds harme; han bar sig verre at enn nogen av de konger i Israel som hadde vært før ham. 
11:16:34: I hans dager bygget Hiel fra Betel Jeriko op igjen; da han la dets grunnvoll, kostet det ham hans førstefødte sønn Abiram, og da han satte op dets porter, kostet det ham hans yngste sønn Segub - efter det ord som Herren hadde talt ved Josva, Nuns sønn. 
11:17:1: Da sa tisbitten Elias, en av dem som var flyttet inn i Gilead, til Akab: Så sant Herren, Israels Gud, lever, han hvis tjener jeg er: Det skal i disse år ikke komme dugg eller regn uten efter mitt ord. 
11:17:2: Og Herrens ord kom til ham, og det lød således: 
11:17:3: Gå herfra og ta veien mot øst og skjul dig ved bekken Krit østenfor Jordan! 
11:17:4: Du skal drikke av bekken, og jeg har befalt ravnene å forsørge dig der. 
11:17:5: Så gikk han og gjorde som Herren hadde sagt; han gikk til bekken Krit østenfor Jordan og opholdt sig der, 
11:17:6: og ravnene kom til ham med brød og kjøtt om morgenen og med brød og kjøtt om aftenen, og han drakk av bekken. 
11:17:7: Men da det var gått en tid, blev bekken uttørret; for det kom ikke regn i landet. 
11:17:8: Da kom Herrens ord til ham, og det lød således: 
11:17:9: Stå op og gå til Sarepta, som hører til Sidon, og bli der! Jeg har befalt en enke der å forsørge dig. 
11:17:10: Han stod op og gikk til Sarepta, og da han kom til byporten, fikk han se en enke som gikk og sanket ved; han ropte på henne og sa: Hent mig litt vann i en skål, så jeg kan få drikke! 
11:17:11: Da hun nu gikk for å hente det, ropte han efter henne og sa: Ta med et stykke brød til mig! 
11:17:12: Da sa hun: Så sant Herren din Gud lever: Jeg eier ikke en brødskive, men har bare en håndfull mel i krukken og litt olje i kruset, og nu går jeg her og sanker et par stykker ved for å gå hjem og lage det til for mig og min sønn, så vi kan ete det og så dø. 
11:17:13: Men Elias sa til henne: Frykt ikke! Gå hjem og lag det til, som du har sagt! Lag bare først et lite brød til mig av det og kom ut til mig med det! Siden kan du så lage til noget for dig og din sønn. 
11:17:14: For så sier Herren, Israels Gud: Melkrukken skal ikke bli tom og oljekruset ikke fattes olje like til den dag Herren sender regn over jorden. 
11:17:15: Og hun gikk og gjorde som Elias hadde sagt, og de hadde mat, både han og hun og hennes hus, i lang tid; 
11:17:16: melkrukken blev ikke tom, og oljekrukken fattedes ikke olje, efter det ord som Herren hadde talt gjennem Elias. 
11:17:17: Men nogen tid efter hendte det at sønn til konen som eide huset, blev syk; og hans sykdom blev så svær at det til slutt ikke mere var livsånde i ham. 
11:17:18: Da sa hun til Elias: Hvad har jeg med dig å gjøre, du Guds mann? Du er kommet til mig for å minne mig om min synd og la min sønn dø. 
11:17:19: Han svarte henne: Gi mig din sønn hit! Så tok han ham fra hennes fang og bar ham op på loftet, hvor han bodde, og la ham på sin seng. 
11:17:20: Og han ropte til Herren og sa: Herre min Gud! Har du da også ført ulykke over denne enke hos hvem jeg bor som gjest, ved å la hennes sønn dø? 
11:17:21: Så strakte han sig tre ganger bortover barnet og ropte til Herren og sa: Herre min Gud! La dette barns sjel vende tilbake til det! 
11:17:22: Og Herren hørte Elias' bønn, og barnets sjel vendte tilbake, så det blev levende igjen. 
11:17:23: Og Elias tok barnet og bar det fra loftet ned i huset og gav det til dets mor; og han sa: Se, din sønn lever. 
11:17:24: Da sa kvinnen til Elias: Nu vet jeg at du er en Guds mann, og at Herrens ord i din munn er sannhet. 
11:18:1: Lang tid derefter - i det tredje år - kom Herrens ord til Elias, og det lød således: Gå og tred frem for Akab, så vil jeg sende regn over jorden. 
11:18:2: Og Elias gikk avsted for å trede frem for Akab. Men hungersnøden var stor i Samaria. 
11:18:3: Da kalte Akab på sin slottshøvding Obadja. Men Obadja fryktet Herren høilig; 
11:18:4: dengang Jesabel utryddet Herrens profeter, hadde Obadja tatt hundre profeter og skjult dem i to huler, femti mann i hver hule, og forsørget dem med brød og vann. 
11:18:5: Akab sa da til Obadja: Dra gjennem landet til alle kilder og bekker! Kanskje vi finner gress, så vi kan holde liv i hester og mulesler og slipper å slakte noget av buskapen. 
11:18:6: Så delte de landet som de skulde dra igjennem, mellem sig; Akab drog en vei for sig, og Obadja drog en annen vei for sig. 
11:18:7: Mens nu Obadja var på veien, fikk han se Elias som kom imot ham; han kjente ham igjen og falt ned på sitt ansikt og sa: Er det du, min herre Elias? 
11:18:8: Han svarte: Ja, det er jeg; gå og si til din herre: Elias er her! 
11:18:9: Men han sa: Hvad synd har jeg gjort, siden du overgir din tjener i Akabs hånd, så han dreper mig? 
11:18:10: Så sant Herren din Gud lever: Det finnes ikke det folk eller det rike som min herre ikke har sendt bud til for å opsøke dig; og når de sa: Han er ikke her, lot han det rike og det folk sverge på at de ikke hadde funnet dig. 
11:18:11: Og nu sier du: Gå og si til din herre: Elias er her! 
11:18:12: Det vil gå så, at når jeg nu går fra dig, og Herrens Ånd rykker dig bort til et sted som jeg ikke vet, og jeg kommer og sier det til Akab, og han så ikke finner dig, da dreper han mig. Og din tjener har dog fryktet Herren fra ungdommen av. 
11:18:13: Er det ikke blitt min herre fortalt hvad jeg gjorde dengang Jesabel drepte Herrens profeter? Da skjulte jeg hundre av Herrens profeter i to huler, femti mann i hver hule, og forsørget dem med brød og vann. 
11:18:14: Og nu sier du: Gå og si til din herre: Elias er her! Og så dreper han mig. 
11:18:15: Da sa Elias: Så sant Herren, hærskarenes Gud, lever, han hvis tjener jeg er: Idag skal jeg trede frem for ham. 
11:18:16: Så gikk Obadja Akab i møte og sa det til ham; og Akab gikk Elias i møte. 
11:18:17: Med det samme Akab fikk se Elias, sa han til ham: Er det du, du som fører ødeleggelse over Israel? 
11:18:18: Han svarte: Jeg har ikke ført ødeleggelse over Israel, men du og din fars hus, fordi I har forlatt Herrens bud og fulgt Ba'alene. 
11:18:19: Men send nu bud og la hele Israel komme sammen til mig på Karmel-fjellet og de fire hundre og femti Ba'als profeter og de fire hundre Astartes profeter som eter ved Jesabels bord! 
11:18:20: Så sendte Akab bud omkring blandt alle Israels barn og bød profetene komme sammen til Karmel-fjellet. 
11:18:21: Da trådte Elias frem for alt folket og sa: Hvor lenge vil I halte til begge sider? Dersom Herren er Gud, så følg ham, og dersom Ba'al er det, så følg ham! Men folket svarte ham ikke et ord. 
11:18:22: Og Elias sa til folket: Jeg er den eneste av Herrens profeter som er blitt tilbake, mens Ba'als profeter er fire hundre og femti mann. 
11:18:23: La oss nu få to okser, og la så dem velge sig den ene okse og hugge den i stykker og legge den på veden, men de skal ikke tende ild på; og jeg vil stelle til den andre okse og legge den på veden, men jeg vil ikke tende ild på. 
11:18:24: Påkall så I eders guds navn, og jeg vil påkalle Herrens navn, og det skal være så, at den gud som svarer med ild, han er Gud. Og alt folket svarte og sa: Det er rett! 
11:18:25: Da sa Elias til Ba'als profeter: Velg nu I eder den ene okse og stell den til først, siden I er flest, og påkall så eders guds navn! Men I skal ikke tende ild på. 
11:18:26: Sa tok de den okse han hadde gitt dem, og stelte den til og påkalte Ba'als navn fra morgen til middag og ropte: Ba'al, svar oss! Men det hørtes ingen røst, og det var ikke nogen som svarte; og de hinket omkring det alter de hadde gjort. 
11:18:27: Men da det var blitt middag, spottet Elias dem og sa: Rop høiere! Han er jo Gud; han er vel falt i tanker, eller han er gått avsides eller er ute på reise; kanskje han sover - så vil han vel våkne. 
11:18:28: Da ropte de ennu høiere og flengte sig, som det var deres vis, med sverd og spyd, til blodet rant nedover dem. 
11:18:29: Da det led over middag, raste de til bortimot den tid matofferet blir frembåret; men det hørtes ingen røst, og det var ikke nogen som svarte, og ikke nogen som aktet på dem. 
11:18:30: Da sa Elias til alt folket: Tred hit til mig! Og alt folket trådte frem til ham, og han satte i stand Herrens alter, som var nedrevet. 
11:18:31: Og Elias tok tolv stener efter tallet på Jakobs barns stammer, han til hvem Herrens ord var kommet og hadde lydt således: Israel skal være ditt navn. 
11:18:32: Og han bygget av stenene et alter i Herrens navn og gjorde en grøft rundt om alteret så stor som til omkring to måls utsæd. 
11:18:33: Så la han veden til rette og hugg oksen i stykker og la den ovenpå veden. 
11:18:34: Og han sa: Fyll fire krukker med vann og øs det ut over brennofferet og over veden! Så sa han: Gjør det en gang til! Og de gjorde det annen gang! Så sa han: Gjør det tredje gang! Og de gjorde det tredje gang. 
11:18:35: Og vannet fløt rundt om alteret, og han fylte også grøften med vann. 
11:18:36: Men ved den tid matofferet blir båret frem, trådte profeten Elias frem og sa: Herre, Abrahams, Isaks og Israels Gud! La det idag bli vitterlig at du er Gud i Israel, og at jeg er din tjener, og at det er på ditt ord jeg har gjort alt dette! 
11:18:37: Svar mig, Herre, svar mig, så dette folk må kjenne at du, Herre, er Gud, og at du nu vender deres hjerte tilbake til dig! 
11:18:38: Da falt Herrens ild ned og fortærte brennofferet og veden og stenene og jorden og slikket op vannet som var i grøften. 
11:18:39: Og alt folket så det, og de falt ned på sitt ansikt og sa: Herren, han er Gud! Herren, han er Gud! 
11:18:40: Da sa Elias til dem: Grip Ba'als profeter, la ingen av dem slippe herifra! Og de grep dem, og Elias førte dem ned til bekken Kison og lot dem drepe der. 
11:18:41: Så sa Elias til Akab: Gå nu op og et og drikk! For jeg hører regnet suse. 
11:18:42: Da gikk Akab op for å ete og drikke. Men Elias gikk op på Karmels topp og bøide sig mot jorden med ansiktet mellem sine knær. 
11:18:43: Så sa han til sin dreng: Stig op og se ut mot havet! Og han steg op og så ut, men sa: Det er ikke noget å se. Syv ganger sa han: Gå dit igjen! 
11:18:44: Den syvende gang sa han: Se, en liten sky, så stor som en manns hånd, stiger op fra havet. Da sa han: Gå op og si til Akab: Spenn for og far ned, så ikke regnet skal opholde dig! 
11:18:45: Og i en håndvending sortnet himmelen til med skyer og storm, og det kom et sterkt regn; og Akab kjørte avsted og for til Jisre'el. 
11:18:46: Men Herrens hånd kom over Elias; han omgjordet sine lender og sprang foran Akab like til Jisre'el. 
11:19:1: Akab fortalte Jesabel om alt det Elias hadde gjort, og om alle dem han hadde drept med sverdet - alle profetene. 
11:19:2: Da sendte Jesabel bud til Elias og lot si: Gudene la det gå mig ille både nu og siden om jeg ikke imorgen på denne tid gjør med ditt liv som det er gjort med deres liv. 
11:19:3: Da han hørte det, gjorde han sig rede og drog bort for å berge livet, og han kom til Be'erseba, som hører til Juda; der lot han sin dreng bli tilbake, 
11:19:4: og selv gikk han en dagsreise ut i ørkenen; der satte han sig under en gyvelbusk, og han ønsket sig døden og sa: Det er nok; ta nu mitt liv, Herre! For jeg er ikke bedre enn mine fedre. 
11:19:5: Så la han sig ned og sov inn under en gyvelbusk; da rørte en engel ved ham og sa til ham: Stå op og et! 
11:19:6: Og da han så til, fikk han se at det ved hans hodegjerde lå en kake, stekt på hete stener, og at det stod en krukke med vann; og han åt og drakk og la sig ned igjen. 
11:19:7: Men Herrens engel kom igjen annen gang og rørte ved ham og sa: Stå op og et! Ellers blir veien dig for lang. 
11:19:8: Da stod han op og åt og drakk; og styrket ved denne mat gikk han firti dager og firti netter, til han kom til Guds berg, Horeb. 
11:19:9: Der gikk han inn i hulen* og blev der om natten. Da kom Herrens ord til ham; han sa til ham: Hvad vil du her, Elias? # <* 2MO 33, 22.> 
11:19:10: Han svarte: Jeg har vært nidkjær for Herren, hærskarenes Gud; for Israels barn har forlatt din pakt; dine alter har de revet ned, og dine profeter har de drept med sverdet; jeg er alene tilbake, og de står mig efter livet. 
11:19:11: Han sa: Gå ut og stå på fjellet for Herrens åsyn! Da gikk Herren forbi der, og det kom en stor og sterk storm som sønderrev fjell og knuste klipper foran Herren, men Herren var ikke i stormen; og efter stormen kom et jordskjelv, men Herren var ikke i jordskjelvet; 
11:19:12: og efter jordskjelvet kom en ild, men Herren var ikke i ilden; og efter ilden kom lyden av en stille susen. 
11:19:13: Og da Elias hørte den, dekket han sitt ansikt til med sin kappe og gikk ut og stod ved inngangen til hulen. Da kom det en røst til ham og sa: Hvad vil du her, Elias? 
11:19:14: Han svarte: Jeg har vært nidkjær for Herren, hærskarenes Gud; for Israels barn har forlatt din pakt; dine alter har de revet ned, og dine profeter har de drept med sverdet; jeg er alene lilbake og de står mig efter livet. 
11:19:15: Da sa Herren til ham: Gå tilbake igjen og ta veien til ørkenen ved Damaskus og gå inn i byen og salv Hasael til konge over Syria! 
11:19:16: Og Jehu, Nimsis sønn, skal du salve til konge over Israel, og Elisa, Safats sønn, fra Abel-Mehola, skal du salve til profet i ditt sted. 
11:19:17: Og det skal gå så, at den som slipper unda Hasaels sverd, skal Jehu drepe, og den som slipper unda Jehus sverd, skal Elisa drepe. 
11:19:18: Men jeg vil la syv tusen bli tilbake i Israel, alle som ikke har bøid kne for Ba'al, og alle som ikke har kysset ham med sin munn. 
11:19:19: Da han nu gikk derfra, møtte han Elisa, Safats sønn, som holdt på å pløie; tolv par okser gikk foran ham, og selv var han ved det tolvte par; og Elias gikk bort til ham og kastet sin kappe over ham. 
11:19:20: Da forlot han oksene og sprang efter Elias og sa: La mig først få kysse min far og min mor, så vil jeg følge dig! Han svarte: Vel, gå hjem! For hvad har jeg vel gjort med dig? 
11:19:21: Så forlot han ham og gikk hjem; han tok de to okser* og slaktet dem, og med oksenes åk kokte han kjøttet og gav det til folket der, og de åt. Så gjorde han sig rede og fulgte Elias og tjente ham. # <* dem han hadde pløid med.> 
11:20:1: Kongen i Syria Benhadad samlet hele sin hær, og to og tretti konger fulgte ham med hester og vogner; og han drog op og kringsatte Samaria og stred mot det. 
11:20:2: Og han sendte bud inn i byen til Akab, Israels konge, 
11:20:3: og lot si til ham: Så sier Benhadad: Ditt sølv og ditt gull hører mig til, og de vakreste av dine hustruer og barn hører og mig til. 
11:20:4: Israels konge svarte og sa: Det er som du sier, min herre konge! Jeg og alt hvad mitt er, hører dig til. 
11:20:5: Men sendebudene kom igjen og sa: Så sier Benhadad: Jeg sendte bud til dig og lot si: Ditt sølv og ditt gull og dine hustruer og dine barn skal du gi mig. 
11:20:6: Men imorgen ved denne tid sender jeg mine tjenere til dig, og de skal ransake ditt hus og dine tjeneres huser, og alt som er dine øines lyst, skal de ta og føre bort med sig. 
11:20:7: Da kalte Israels konge alle landets eldste til sig og sa: Nu ser I vel grant at han bare vil oss ondt! Han sendte bud til mig og krevde mine hustruer og mine barn, mitt sølv og mitt gull, og jeg nektet ham det ikke. 
11:20:8: Da sa alle de eldste og alt folket til ham: Du må ikke høre på ham og ikke gi efter. 
11:20:9: Så sa han til Benhadads sendebud: Si til min herre kongen: Alt det du første gang sendte bud til din tjener om, vil jeg gjøre; men dette kan jeg ikke gjøre. Med dette svar vendte sendebudene tilbake til ham. 
11:20:10: Da sendte Benhadad atter bud til ham og lot si: Gudene la det gå mig ille både nu og siden om Samarias støv skal kunne fylle nevene på alt det folk som er i mitt følge. 
11:20:11: Da svarte Israels konge og sa: Si til ham: Ikke skulde den som binder sverdet om sig, rose sig lik den som løser det av sig! 
11:20:12: Da Benhadad hørte dette svar, mens han selv og kongene satt og drakk i løvhyttene, sa han til sine menn: Still eder op! Og de stilte sig op imot byen. 
11:20:13: Da trådte en profet frem til Akab, Israels konge, og sa: Så sier Herren: Ser du hele denne store mengde? Jeg gir den idag i din hånd, og du skal kjenne at jeg er Herren. 
11:20:14: Akab spurte: Ved hvem? Han svarte: Så sier Herren: Ved landshøvdingenes menn. Så spurte han: Hvem skal begynne striden? Han svarte: Du selv. 
11:20:15: Så mønstret han landshøvdingenes menn, og de var to hundre og to og tretti; og efter dem mønstret han alt folket - alle Israels barn - syv tusen mann. 
11:20:16: De drog ut om middagen, mens Benhadad satt og drakk sig drukken i løvhyttene med de to og tretti konger som var kommet ham til hjelp. 
11:20:17: Landshøvdingenes menn drog først ut; Benhadad sendte folk ut for å speide, og de meldte ham at det hadde draget krigsfolk ut fra Samaria. 
11:20:18: Da sa han: Enten de har draget ut i fredelig øiemed, eller de har draget ut til strid, så grip dem levende! 
11:20:19: Så drog de da ut fra byen, landshøvdingenes menn og hæren som fulgte dem, 
11:20:20: og de hugg ned hver sin mann; syrerne flyktet, og Israel forfulgte dem; og Benhadad, kongen i Syria, slapp unda på en hest sammen med nogen ryttere. 
11:20:21: Så drog Israels konge ut og slo hestene og stridsvognene og voldte et stort mannefall blandt syrerne. 
11:20:22: Da trådte profeten frem til Israels konge og sa til ham: Gå nu i gang med å ruste dig og tenk vel over hvad du skal gjøre! For til næste år vil kongen i Syria igjen dra op imot dig. 
11:20:23: Men syrerkongens tjenere sa til ham: Deres guder er fjellguder, derfor vant de over oss; men strider vi med dem på sletten, da vinner vi sikkert over dem. 
11:20:24: Gjør nu således: Avsett kongene, hver fra sin plass, og sett stattholdere i deres sted! 
11:20:25: Og få dig så selv en hær som er like så stor som den du mistet, og like så mange hester og vogner, og la oss så stride med dem på sletten! Da vinner vi sikkert over dem. Og han lyttet til deres råd og gjorde så. 
11:20:26: Året efter mønstret Benhadad syrerne og drog frem til Afek for å stride mot Israel. 
11:20:27: Og Israels barn blev mønstret og forsynt med levnetsmidler og drog ut imot dem; og Israels barn leiret sig midt imot dem som to små gjeteflokker, men syrerne opfylte landet. 
11:20:28: Da trådte den Guds mann frem og sa til Israels konge: Så sier Herren, sa han: Fordi syrerne har sagt: Herren er en fjellgud og ikke en dalgud, så vil jeg gi hele denne store mengde i din hånd, og I skal kjenne at jeg er Herren. 
11:20:29: Så lå de nu i leir midt imot hverandre i syv dager. På den syvende dag kom det til strid, og Israels barn hugg på én dag ned hundre tusen mann fotfolk av syrerne. 
11:20:30: Og de som blev tilbake, flyktet til Afek og inn i byen; men muren falt ned over de syv og tyve tusen mann som var blitt tilbake. Benhadad flyktet også og kom inn i byen og sprang fra kammer til kammer. 
11:20:31: Da sa hans tjenere til ham: Vi har hørt at kongene av Israels hus er milde konger; la oss legge sekk om våre lender og rep om våre hoder og gå ut til Israels konge! Kanskje han lar dig få leve! 
11:20:32: Så bandt de sekk om sine lender og rep om sine hoder, og da de kom til Israels konge, sa de: Din tjener Benhadad sier: La mig få leve! Han svarte: Er han ennu i live? Han er min bror. 
11:20:33: Mennene tok dette for et godt varsel, og de søkte straks å få et ord fra ham som kunde vise om han mente det så, og de sa: Ja, Benhadad er din bror. Da sa han: Gå og hent ham! Så kom Benhadad ut til ham, og han lot ham stige op i sin vogn. 
11:20:34: Og Benhadad sa til ham: De byer som min far tok fra din far, vil jeg gi tilbake, og du kan gjøre dig gater i Damaskus, likesom min far gjorde i Samaria. Og jeg [sa Akab] vil på det vilkår gi dig fri. Så inngikk han et forbund med ham og gav ham fri. 
11:20:35: Men en av profetenes disipler sa på Herrens bud til en annen: Slå mig! Men mannen vilde ikke slå ham. 
11:20:36: Da sa han til ham: Fordi du ikke lød Herrens røst, så skal en løve drepe dig, når du går bort fra mig. Og da han gikk fra ham, kom en løve imot ham og drepte ham. 
11:20:37: Så møtte han en annen mann og sa: Slå mig! Og mannen slo ham så han blev såret. 
11:20:38: Derefter gikk profeten og stilte sig på veien for å møte kongen; men han gjorde sig ukjennelig ved å legge et bind over sine øine. 
11:20:39: Da nu kongen kom forbi, ropte han til kongen: Din tjener hadde draget ut og var med i striden; men i det samme kom det en mann frem som førte en annen mann til mig og sa: Ta vare på denne mann! Blir han borte, skal ditt liv trede i stedet for hans liv, eller også skal du betale mig en talent sølv. 
11:20:40: Men din tjener hadde noget å gjøre her og der, og så blev mannen borte. Da sa Israels konge til ham: Du har din dom; du har selv felt den. 
11:20:41: Da skyndte han sig og tok bindet fra øinene, og Israels konge kjente ham og så at han var en av profetene. 
11:20:42: Profeten sa til ham: Så sier Herren: Fordi du lot den mann som jeg hadde lyst i bann, slippe dig av hendene, så skal ditt liv trede i stedet for hans liv og ditt folk i stedet for hans folk. 
11:20:43: Så drog Israels konge hjem mismodig og harm og kom til Samaria. 
11:21:1: Nogen tid efter hendte det som nu skal fortelles: Jisre'elitten Nabot hadde en vingård i Jisre'el ved siden av et slott som tilhørte Akab, kongen i Samaria. 
11:21:2: Akab talte med Nabot og sa: Gi mig din vingård, så jeg kan ha den til kjøkkenhave! For den ligger tett ved mitt hus. Jeg vil gi dig en bedre vingård i stedet for den; eller om du så synes, vil jeg gi dig så meget i penger som den er verd. 
11:21:3: Men Nabot svarte: Herren la det være langt fra mig å gi dig min fedrene arv. 
11:21:4: Så gikk Akab hjem mismodig og harm for det svar jisre'elitten Nabot hadde gitt ham da han sa: Jeg vil ikke gi dig min fedrene arv. Og han la sig på sin seng og vendte ansiktet bort og vilde ikke ete. 
11:21:5: Da kom hans hustru Jesabel inn til ham, og hun spurte ham: Hvorfor er du så mismodig og vil ikke ete? 
11:21:6: Han svarte: Jeg talte med jisre'elitten Nabot og sa til ham: Gi mig din vingård for penger, eller om du ønsker, vil jeg gi dig en annen vingård i stedet. Men han sa: Jeg vil ikke gi dig min vingård. 
11:21:7: Da sa hans hustru Jesabel til ham: Du får nu vise at du er konge over Israel; stå op, et og vær vel til mote! Jeg skal sørge for at du får jisre'elitten Nabots vingård. 
11:21:8: Så skrev hun et brev i Akabs navn og satte segl på det med hans signetring; og hun sendte brevet til de eldste og fornemste i hans by, de som bodde sammen med Nabot. 
11:21:9: Og i brevet skrev hun således: Utrop en faste og la Nabot sitte øverst blandt folket, 
11:21:10: og la to onde menn sitte midt imot ham, sa de kan vidne mot ham og si: Du har bannet Gud og kongen. Før ham så ut og sten ham ihjel! 
11:21:11: Og mennene i byen, de eldste og fornemste som bodde i hans by, gjorde som Jesabel hadde sendt dem bud om, således som det var skrevet i det brev hun hadde sendt dem. 
11:21:12: De utropte en faste og lot Nabot sitte øverst blandt folket, 
11:21:13: og de to onde menn kom og satte sig midt imot ham; og de onde menn vidnet mot Nabot i folkets påhør og sa: Nabot har bannet Gud og kongen. Og de førte ham utenfor byen og stenet ham ihjel. 
11:21:14: Så sendte de bud til Jesabel og lot si: Nabot er blitt stenet og er død. 
11:21:15: Med det samme Jesabel hørte at Nabot var blitt stenet og var død, sa hun til Akab: Stå op og ta jisre'elitten Nabots vingård i eie, den som han ikke vilde la dig få for penger! For Nabot lever ikke lenger; han er død. 
11:21:16: Da Akab hørte at Nabot var død, stod han op for å gå ned til Jisre'elitten Nabots vingård og ta den eie. 
11:21:17: Men Herrens ord kom til tisbitten Elias, og det lød således: 
11:21:18: Gjør dig rede og gå ned og møt Akab, Israels konge, som bor i Samaria! Nu er han i Nabots vingård; han er gått der ned for å ta den i eie. 
11:21:19: Og du skal tale således til ham: Så sier Herren: Har du nu både myrdet og arvet? Og du skal si til ham: Så sier Herren: På det sted hvor hundene slikket Nabots blod, skal hundene også slikke ditt blod. 
11:21:20: Men Akab sa til Elias: Har du funnet mig, du min fiende? Han svarte: Ja, jeg har funnet dig, fordi du har solgt dig til å gjøre hvad ondt er i Herrens øine. 
11:21:21: Derfor vil jeg føre ulykke over dig og feie efter dig og utrydde alle menn i Akabs hus, både myndige og umyndige i Israel, 
11:21:22: og jeg vil gjøre med ditt hus som med Jeroboams, Nebats sønns hus og med Baesas, Akias sønns hus, fordi du har vakt min harme, og fordi du har fått Israel til å synde. 
11:21:23: Også om Jesabel har Herren talt og har sagt: Hundene skal fortære Jesabel ved Jisre'els voll. 
11:21:24: Den av Akabs hus som dør i byen, skal hundene fortære, og den som dør på marken, skal himmelens fugler fortære. 
11:21:25: Det har aldri vært nogen som Akab, han som solgte sig til å gjøre hvad ondt var i Herrens øine, fordi hans hustru Jesabel egget ham. 
11:21:26: Hans ferd var overmåte vederstyggelig; han fulgte de motbydelige avguder, aldeles som amorittene hadde gjort, de som Herren drev bort for Israels barn. 
11:21:27: Men da Akab hørte hvad Elias sa, sønderrev han sine klær og la sekk om sitt legeme og fastet; og han sov i sekk og gikk stille omkring. 
11:21:28: Da kom Herrens ord til tisbitten Elias, og det lød således: 
11:21:29: Har du sett at Akab har ydmyket sig for mig? Fordi han har ydmyket sig for mig, vil jeg ikke la ulykken komme i hans dager, men i hans sønns dager vil jeg la ulykken komme over hans hus. 
11:22:1: Nu holdt de sig rolig i tre år; det var ikke nogen krig mellem Syria og Israel. 
11:22:2: Men i det tredje år hendte det at Josafat, Judas konge, drog ned til Israels konge. 
11:22:3: Da sa Israels konge til sine tjenere: Vet I ikke at Ramot i Gilead hører oss til? Og vi sitter stille og tar det ikke fra kongen i Syria! 
11:22:4: Og han sa til Josafat: Vil du dra med mig i krig til Ramot i Gilead? Josafat svarte Israels konge: Som du, så jeg, som ditt folk, så mitt folk, som dine hester, så mine hester. 
11:22:5: Og Josafat sa fremdeles til Israels konge: Søk dog først å få vite hvad Herren sier! 
11:22:6: Da kalte Israels konge profetene sammen; det var omkring fire hundre mann; og han spurte dem: Skal jeg dra i krig mot Ramot i Gilead, eller skal jeg la det være? De svarte: Dra op! Herren vil gi det i kongens hånd. 
11:22:7: Men Josafat sa: Er her ikke nogen annen Herrens profet, så vi kunde spørre Herren til råds gjennem ham? 
11:22:8: Israels konge svarte Josafat: Der er ennu en mann gjennem hvem vi kan spørre Herren til råds, men jeg hater ham fordi han ikke spår godt om mig, men bare ondt, det er Mika, Jimlas sønn. Josafat sa: Kongen skulde ikke si så! 
11:22:9: Da kalte Israels konge på en av hoffolkene og sa: Skynd dig og hent Mika, Jimlas sønn! 
11:22:10: Imens satt Israels konge og Josafat, Judas konge, i kongelig skrud, hver på sin trone, på en treskeplass ved inngangen til Samarias port; og alle profetene stod foran dem og fremsa sine spådommer. 
11:22:11: Og Sedekias, Kena'anas sønn, gjorde sig horn av jern og sa: Så sier Herren: Med disse skal du stange syrerne til du får gjort ende på dem. 
11:22:12: Og alle profetene spådde likedan og sa: Dra op til Ramot i Gilead! Så skal du ha lykke med dig, og Herren skal gi det i kongens hånd. 
11:22:13: Og budet som var gått for å tilkalle Mika, sa til ham: Profetene spår med en munn godt for kongen; la nu også dine ord stemme overens med deres og spå godt! 
11:22:14: Mika svarte: Så sant Herren lever: Hvad Herren sier til mig, det vil jeg tale. 
11:22:15: Da han nu kom til kongen, sa kongen til ham: Mika! Skal vi dra i krig til Ramot i Gilead, eller skal vi la det være? Han svarte: Dra op! Så skal du ha lykke med dig, og Herren skal gi det i kongens hånd. 
11:22:16: Men kongen sa til ham: Hvor mange ganger skal jeg besverge dig at du ikke skal tale annet til mig enn sannhet i Herrens navn? 
11:22:17: Da sa han: Jeg så hele Israel spredt utover fjellene likesom en fårehjord som ikke har hyrde; og Herren sa: Disse har ingen herre; la dem vende tilbake i fred, hver til sitt hus! 
11:22:18: Da sa Israels konge til Josafat: Var det ikke det jeg sa til dig: Han spår ikke godt om mig, men bare ondt? 
11:22:19: Men Mika sa: Så hør da Herrens ord! Jeg så Herren sitte på sin trone og hele himmelens hær stå omkring ham på hans høire og venstre side. 
11:22:20: Og Herren sa: Hvem vil overtale Akab til å dra op til Ramot i Gilead, så han faller der? Og den ene sa så og den annen så. 
11:22:21: Da gikk ånden* frem og stilte sig for Herrens åsyn og sa: Jeg skal overtale ham. Herren spurte ham: Hvorledes? # <* spådomsånden.> 
11:22:22: Han svarte: Jeg vil gå avsted og være en løgnens ånd i alle hans profeters munn. Da sa Herren: Ja, du skal overtale ham, og det skal også lykkes dig; gå avsted og gjør så! 
11:22:23: Se, nu har Herren lagt en løgnens ånd i alle disse dine profeters munn, men Herren har varslet ulykke for dig. 
11:22:24: Da trådte Sedekias, Kena'anas sønn, frem og slo Mika på kinnet og sa: På hvilken vei er Herrens Ånd gått over fra mig for å tale med dig? 
11:22:25: Mika svarte: Det skal du få se den dag du flykter fra kammer til kammer for å skjule dig. 
11:22:26: Da sa Israels konge: Ta Mika og før ham tilbake til byens høvedsmann Amon og til kongesønnen Joas 
11:22:27: og si: Så sier kongen: Sett ham i fangehuset og la ham leve på fangekost til jeg kommer uskadd hjem igjen! 
11:22:28: Mika sa: Kommer du uskadd hjem igjen, så har Herren ikke talt gjennem mig. Og han sa: Hør dette, I folk alle sammen! 
11:22:29: Så drog Israels konge og Judas konge Josafat op til Ramot i Gilead. 
11:22:30: Og Israels konge sa til Josafat: Jeg vil forklæ mig og så gå i striden; men ta du dine vanlige klær på! Så forklædde Israels konge sig og gikk i striden. 
11:22:31: Men kongen i Syria hadde befalt sine to og tretti vogn-høvedsmenn: I skal ikke stride mot nogen, hverken liten eller stor, bare mot Israels konge. 
11:22:32: Da nu vogn-høvedsmennene så Josafat, sa de: Dette er sikkert Israels konge. Og de vendte sig dit for å stride mot ham. Da satte Josafat i et høit rop. 
11:22:33: Og da vogn-høvedsmennene så at det ikke var Israels konge, vendte de sig fra ham igjen. 
11:22:34: Men en mann spente sin bue og skjøt på lykke og fromme og traff Israels konge mellem brynjeskjørtet og brynjen. Da sa han til sin vognstyrer: Vend om og før mig ut av hæren! Jeg er såret. 
11:22:35: Men striden blev stadig hårdere den dag, og kongen blev holdt opreist i vognen mot syrerne; men om aftenen døde han, og blodet av såret fløt ned i vognen. 
11:22:36: Og ved solens nedgang gikk der det rop gjennem leiren: Hver mann hjem til sin by og sitt land! 
11:22:37: Således døde kongen og blev ført til Samaria, og de begravde kongen i Samaria. 
11:22:38: Og da de skylte vognen i dammen ved Samaria, slikket hundene hans blod, mens skjøgene badet sig der, efter det ord som Herren hadde talt. 
11:22:39: Hvad som ellers er å fortelle om Akab, om alt det han gjorde, og om det elfenbenshus han bygget, og om alle de byer han bygget, det er opskrevet i Israels kongers krønike. 
11:22:40: Og Akab la sig til hvile hos sine fedre, og hans sønn Akasja blev konge i hans sted. 
11:22:41: Josafat, Asas sønn, blev konge over Juda i Akabs, Israels konges fjerde år. 
11:22:42: Josafat var fem og tretti år gammel da han blev konge, og regjerte i Jerusalem i fem og tyve år; hans mor hette Asuba og var datter av Silhi. 
11:22:43: Han vandret i ett og alt på sin far Asas vei; han vek ikke fra den, men gjorde hvad rett var i Herrens øine. 
11:22:44: Dog blev offerhaugene ikke nedlagt; folket blev ved å ofre og brenne røkelse på haugene. 
11:22:45: Josafat holdt fred med Israels konge. 
11:22:46: Hvad som ellers er å fortelle om Josafat, om de store gjerninger han gjorde, og om de kriger han førte, det er opskrevet i Judas kongers krønike. 
11:22:47: Han utryddet også av landet de tempel-bolere som var blitt tilbake i hans far Asas dager. 
11:22:48: Det var dengang ingen konge i Edom; en stattholder regjerte der. 
11:22:49: Josafat hadde ti Tarsis-skib som skulde gå til Ofir efter gull; men de kom ikke avsted, for nogen av skibene forliste ved Esjon-Geber. 
11:22:50: Da sa Akasja, Akabs sønn, til Josafat: La mine folk fare med dine folk på skibene! Men Josafat vilde ikke. 
11:22:51: Og Josafat la sig til hvile hos sine fedre og blev begravet hos sine fedre i sin far Davids stad; og hans sønn Joram blev konge i hans sted. 
11:22:52: Akasja, Akabs sønn, blev konge over Israel i Samaria i Josafats, Judas konges syttende år og regjerte over Israel i to år. 
11:22:53: Han gjorde hvad ondt var i Herrens øine, og vandret på sin fars og sin mors vei og på Jeroboams, Nebats sønns vei, han som fikk Israel til å synde; 
11:22:54: han dyrket Ba'al og tilbad ham og vakte Herrens, Israels Guds harme, aldeles som hans far hadde gjort. 
12:1:1: Efter Akabs død falt Moab fra Israel. 
12:1:2: Akasja falt ut gjennem gitteret i sin sal i Samaria og blev syk. Da skikket han nogen sendebud avsted og sa til dem: Gå og spør Ba'al-Sebub, Ekrons gud, om jeg skal komme mig av denne sykdom! 
12:1:3: Men Herrens engel sa til tisbitten Elias: Gjør dig rede og gå sendebudene fra Samarias konge i møte og si til dem: Er det fordi det ingen Gud er i Israel, at I går avsted og spør Ba'al-Sebub, Ekrons gud? 
12:1:4: Derfor sier Herren så: Du skal ikke stige ned fra den seng du er steget op i; du skal dø. Dermed gikk Elias. 
12:1:5: Da sendebudene vendte tilbake til ham, spurte han dem: Hvorfor er I vendt tilbake? 
12:1:6: De svarte: En mann kom oss i møte og sa til oss: Gå tilbake til kongen som har sendt eder, og si til ham: Så sier Herren: Er det fordi det ingen Gud er i Israel, at du sender bud for å spørre Ba'al-Sebub, Ekrons gud? Derfor skal du ikke stige ned fra den seng du er steget op i; du skal dø. 
12:1:7: Han spurte dem: Hvorledes så den mann ut som kom eder i møte og talte således til eder? 
12:1:8: De svarte: Det var en mann i en lodden kjortel, med et lærbelte bundet om lendene. Da sa han: Det var tisbitten Elias. 
12:1:9: Og han sendte til ham en høvedsmann over femti med sine menn; og han gikk op til ham, der han satt på toppen av fjellet, og han sa til ham: Du Guds mann! Kongen sier: Kom ned! 
12:1:10: Men Elias svarte høvedsmannen over femti: Er jeg en Guds mann, så fare ild ned fra himmelen og fortære dig og dine femti mann! Da fór det ild ned fra himmelen og fortærte ham og hans femti mann. 
12:1:11: Så sendte han til ham en annen høvedsmann over femti med sine menn; og han tok til orde og sa til ham: Du Guds mann! Så sier kongen: Kom straks ned! 
12:1:12: Men Elias svarte dem: Er jeg en Guds mann, så fare ild ned fra himmelen og fortære dig og dine femti mann! Da fór Guds ild ned fra himmelen og fortærte ham og hans femti mann. 
12:1:13: Så sendte han igjen en tredje høvedsmann over femti med sine menn; og denne tredje høvedsmann gikk op til Elias og falt på kne for ham og bønnfalt ham og sa til ham: Du Guds mann! La mitt liv og disse dine femti tjeneres liv være dyrt i dine øine! 
12:1:14: Ild fór ned fra himmelen og fortærte begge de første høvedsmenn over femti med sine menn; men la nu mitt liv være dyrt i dine øine! 
12:1:15: Da sa Herrens engel til Elias: Gå ned med ham, og vær ikke redd ham*! Så stod han op og gikk med ham ned til kongen. # <* kongen.> 
12:1:16: Og han sa til ham: Så sier Herren: Fordi du skikket sendebud og vilde spørre Ba'al-Sebub, Ekrons gud, som om det ingen Gud var i Israel som du kunde spørre derom - derfor skal du ikke stige ned fra den seng du er steget op i; du skal dø. 
12:1:17: Og han døde efter det Herrens ord som Elias hadde talt, og Joram* blev konge i hans sted i Judas konge Jorams, Josafats sønns annet år; for han hadde ikke nogen sønn. # <* hans bror. 2KG 3, 1.> 
12:1:18: Hvad som ellers er å fortelle om Akasja og det han gjorde, det er opskrevet i Israels kongers krønike. 
12:2:1: Dengang Herren vilde ta Elias op til himmelen i en storm, gikk Elias og Elisa fra Gilgal. 
12:2:2: Og Elias sa til Elisa: Bli her! For Herren har sendt mig til Betel. Men Elisa sa: Så sant Herren lever, og så sant du selv lever: Jeg forlater dig ikke. Så gikk de ned til Betel. 
12:2:3: Da gikk profetenes disipler, som var i Betel, ut til Elisa og sa til ham: Vet du at Herren idag vil ta din herre bort fra dig over ditt hode? Han svarte: Ja, jeg vet det; ti bare stille! 
12:2:4: Så sa Elias til ham: Elisa, bli her! For Herren har sendt mig til Jeriko. Men han sa: Så sant Herren lever, og så sant du selv lever: Jeg forlater dig ikke. Så kom de til Jeriko. 
12:2:5: Da gikk profetenes disipler, som var i Jeriko, frem til Elisa og sa til ham: Vet du at Herren idag vil ta din herre bort fra dig over ditt hode? Han svarte: Ja, jeg vet det; ti bare stille! 
12:2:6: Så sa Elias til ham: Bli her! For Herren har sendt mig til Jordan. Men han sa: Så sant Herren lever, og så sant du selv lever: Jeg forlater dig ikke. Så gikk de begge avsted. 
12:2:7: Og femti av profetenes disipler gikk avsted og blev stående midt imot dem langt borte; men selv stod de begge ved Jordan. 
12:2:8: Da tok Elias sin kappe og rullet den sammen og slo på vannet, og det skilte sig til begge sider, og de gikk begge over på det tørre. 
12:2:9: Da de nu var gått over, sa Elias til Elisa: Si hvad du ønsker jeg skal gjøre for dig før jeg blir tatt bort fra dig! Elisa sa: La en dobbelt del* av din ånd tilfalle mig! # <* 5MO 21, 17.> 
12:2:10: Han svarte: Det er en stor ting du ber om; hvis du ser mig når jeg blir tatt bort fra dig, skal du få det du ber om. Ellers får du det ikke. 
12:2:11: Mens de så gikk og talte sammen, kom det med én gang en gloende vogn og gloende hester og skilte dem fra hverandre; og Elias fór i stormen op til himmelen. 
12:2:12: Elisa så det; da ropte han: Min far, min far, Israels vogner og ryttere! Og han så ham ikke mere. Da tok han fatt i sine klær og rev dem i to stykker. 
12:2:13: Så tok han op Elias' kappe, som var falt av ham, og han vendte tilbake og stod ved Jordans bredd. 
12:2:14: Og han tok Elias' kappe, som var falt av ham, og slo på vannet og sa: Hvor er Herren, Elias' Gud? Således slo også Elisa på vannet, så det skilte sig til begge sider, og han gikk over. 
12:2:15: Da profetenes disipler i Jeriko, som stod midt imot ham, så ham gjøre dette, sa de: Elias' ånd hviler over Elisa. Og de kom ham i møte og bøide sig til jorden for ham. 
12:2:16: Og de sa til ham: Der er her hos dine tjenere femti sterke menn; la dem gå avsted og lete efter din herre! Kanskje Herrens Ånd har tatt ham og kastet ham på et eller annet fjell eller i en eller annen dal. Han svarte: I skal ikke sende nogen avsted. 
12:2:17: Men da de lenge blev ved å trenge inn på ham, sa han: Send dem da avsted! Så sendte de femti mann avsted, og de lette i tre dager, men fant ham ikke. 
12:2:18: Og de vendte tilbake til ham, mens han ennu opholdt sig i Jeriko; og han sa til dem: Sa jeg ikke til eder at I ikke skulde gå? 
12:2:19: Mennene i byen sa til Elisa: Byen ligger godt til, som min herre ser; men vannet er dårlig, og landet lider under for tidlige fødsler. 
12:2:20: Da sa han: Hent mig en ny skål og legg salt i den! Så gjorde de det. 
12:2:21: Og han gikk ut til det sted hvor vannet vellet frem, og kastet salt nedi og sa: Så sier Herren: Nu har jeg gjort dette vann godt; det skal ikke mere volde død eller for tidlige fødsler. 
12:2:22: Og vannet blev godt og har så vært til denne dag, efter det ord som Elisa hadde talt. 
12:2:23: Derfra gikk han op til Betel, og mens han gikk opefter veien, kom det nogen smågutter ut fra byen; de spottet ham og ropte til ham: Kom her op, din snauskalle! Kom her op, din snauskalle! 
12:2:24: Da han så vendte sig om og fikk se dem, lyste han forbannelse over dem i Herrens navn; da kom det to bjørner ut av skogen og sønderrev to og firti av barna. 
12:2:25: Derfra gikk han til Karmel-fjellet og vendte derfra tilbake til Samaria. 
12:3:1: Joram, Akabs sønn, blev konge over Israel i Samaria i Judas konge Josafats attende år, og han regjerte tolv år. 
12:3:2: Han gjorde hvad ondt var i Herrens øine, dog ikke som sin far og mor; for han tok bort den Ba'al-støtte som hans far hadde latt gjøre. 
12:3:3: Dog blev han hengende ved Jeroboams, Nebats sønns synder, de synder som Jeroboam hadde fått Israel til å gjøre; dem vek han ikke fra. 
12:3:4: Mesa, kongen i Moab, hadde meget fe, og han betalte i skatt til Israels konge hundre tusen lam og ull av hundre tusen værer. 
12:3:5: Men da Akab var død, falt kongen i Moab fra Israels konge. 
12:3:6: Da drog kong Joram ut fra Samaria og mønstret hele Israel. 
12:3:7: Så sendte han bud til Judas konge Josafat og lot si: Moabs konge er falt fra mig; vil du dra med mig og stride mot Moab? Han svarte: Ja, jeg vil dra med; som du, så jeg, som ditt folk, så mitt folk, som dine hester, så mine hester. 
12:3:8: Og han spurte: Hvilken vei skal vi dra dit op? Han svarte: Gjennem Edoms ørken. 
12:3:9: Så drog Israels konge og Judas konge og Edoms konge avsted, og da de hadde faret syv dagsreiser omkring, fantes det ikke vann, hverken for hæren eller for buskapen som de hadde med sig. 
12:3:10: Da sa Israels konge: Akk, at Herren har kalt disse tre konger hit for å gi dem i Moabs hånd! 
12:3:11: Men Josafat sa: Er det ikke nogen av Herrens profeter her, så vi kunde spørre Herren til råds gjennem ham? Da svarte en av Israels konges tjenere: Elisa, Safats sønn, er her, han som helte vann over Elias' hender*. # <* d.e. tjente ham.> 
12:3:12: Så drog Israels konge og Josafat og Edoms konge ned til ham. 
12:3:13: Men Elisa sa til Israels konge: Hvad har jeg med dig å gjøre? Gå du til din fars profeter og til din mors profeter! Israels konge svarte ham: Tal ikke så! For Herren har kalt disse tre konger hit for å gi dem i Moabs hånd. 
12:3:14: Da sa Elisa: Så sant Herren, hærskarenes Gud, lever, han hvis tjener jeg er: Var det ikke for Judas konge Josafats skyld, så vilde jeg ikke ense dig eller se på dig. 
12:3:15: Men hent nu en harpespiller til mig! Da så harpespilleren spilte på sin harpe, kom Herrens Ånd over ham*. # <* ESK 1, 3.> 
12:3:16: Og han sa: Så sier Herren: Gjør grøft ved grøft i dalen her! 
12:3:17: For sa sier Herren: I skal ikke se vind og ikke se regn, men allikevel skal denne dal fylles med vann, så I får drikke, både I selv og eders buskap og eders kløvdyr. 
12:3:18: Men dette er ikke nok i Herrens øine; han vil også gi Moab i eders hånd. 
12:3:19: Og I skal innta hver fast by og hver storby, og hvert godt tre skal I felle, og alle vannkilder skal I tilstoppe, og hvert godt stykke land skal I ødelegge med sten. 
12:3:20: Og morgenen efter, ved den tid matofferet blir båret frem, da kom det vann fra Edoms-kanten, og landet fyltes med vann. 
12:3:21: Da moabittene fikk høre at kongene hadde draget op for å stride mot dem, blev de alle kalt sammen, så mange som på nogen måte kunde bære våben, og de stilte sig op ved grensen. 
12:3:22: Men tidlig om morgenen, da solen gikk op over vannet, så det ut for moabittene som om vannet foran dem var rødt som blod. 
12:3:23: Og de sa: Det er blod; kongene har gjort ende på hverandre og slått hverandre ihjel. Nu til plyndring, moabitter! 
12:3:24: Men da de kom til Israels leir, reiste lsraelittene sig og slo moabittene, som flyktet for dem, og de trengte inn i landet og slo atter moabittene. 
12:3:25: De rev ned byene, og på hvert godt stykke land kastet de hver sin sten og fylte det op, og hver vannkilde tilstoppet de, og hvert godt tre felte de, så det ikke blev levnet annet enn stenene i Kir-Hareset; og denne by omringet slyngekasterne og skjøt på den. 
12:3:26: Da Moabs konge så at striden blev ham for hård, tok han med sig syv hundre våbenføre menn og vilde bryte igjennem på den kant hvor Edoms konge stod; men de kunde ikke. 
12:3:27: Da tok han sin førstefødte sønn, som skulde bli konge i hans sted, og ofret ham som brennoffer på muren. Da kom det en stor vrede over Israel, og de brøt op derfra og vendte tilbake til sitt land. 
12:4:1: En kvinne som var hustru til en av profetenes disipler, ropte til Elisa og sa: Din tjener, min mann, er død, og du vet at din tjener fryktet Herren; og nu kommer en som han stod i gjeld til, og vil ta begge mine sønner til træler. 
12:4:2: Elisa sa til henne: Hvad kan jeg gjøre for dig? Si mig: Hvad har du i huset? Hun svarte: Din tjenerinne har intet annet i huset enn en krukke med salve-olje. 
12:4:3: Da sa han: Gå til alle dine naboer og be om å få låne hjem nogen tomme kar, bare ikke for få! 
12:4:4: Gå så inn og lukk døren efter dig og dine sønner og hell oljen i alle karene, og efter hvert som de blir fulle, skal du sette dem bort. 
12:4:5: Og hun gikk fra ham og lukket døren efter sig og sine sønner; de bar karene frem til henne, og hun helte i. 
12:4:6: Og da karene var fulle, sa hun til sin sønn: Bær ennu et kar frem til mig! Men han svarte: Det er ikke flere kar. Da stanset oljen. 
12:4:7: Så kom hun og fortalte det til den Guds mann, og han sa: Gå og selg oljen og betal din gjeld; så kan du og dine sønner leve av det som blir tilovers. 
12:4:8: En dag gikk Elisa over til Sunem; en velstående kvinne som bodde der, nødde ham til å ete hos sig; og så ofte han siden drog der forbi, tok han inn der og fikk sig mat. 
12:4:9: Engang sa hun til sin mann: Hør her, jeg vet at han som jevnlig drar forbi oss, er en hellig Guds mann. 
12:4:10: La oss gjøre et lite tak-kammer med murvegger og sette inn der en seng for ham og et bord og en stol og en lysestake; når han så kommer til oss, kan han ta inn der. 
12:4:11: Så kom han dit en dag og tok inn i tak-kammeret og la sig der. 
12:4:12: Og han sa til sin dreng Gehasi: Kall på den sunamittiske kone som bor her! Så kalte han på henne, og hun trådte frem for ham. 
12:4:13: Han hadde sagt til ham: Si til henne: Nu har du hatt all denne uro for vår skyld; hvad kan jeg gjøre for dig? Er det noget jeg kan tale til kongen om for dig eller til hærføreren? Hun svarte: Jeg bor jo her midt iblandt mitt folk. 
12:4:14: Så sa han: Hvad kan jeg da gjøre for henne? Gehasi svarte: Jo, hun har ingen sønn, og hennes mann er gammel. 
12:4:15: Da sa han: Kall på henne! Så kalte han på henne, og hun trådte frem i døren. 
12:4:16: Da sa han: Til næste år ved denne tid skal du holde en sønn i dine armer. Men hun sa: Nei, min herre, du som er en Guds mann! Lyv ikke for din tjenerinne! 
12:4:17: Og kvinnen blev fruktsommelig og fødte en sønn det følgende år nettop på den tid som Elisa hadde sagt henne. 
12:4:18: Da barnet blev større, hendte det en dag at han gikk ut til sin far, som var ute hos høstfolkene. 
12:4:19: Og han sa til sin far: Mitt hode! Mitt hode! Da sa faren til sin dreng: Bær ham hjem til hans mor! 
12:4:20: Og han tok ham og bar ham hjem til hans mor, og han satt på hennes kne til om middagen; da døde han. 
12:4:21: Hun gikk op og la ham på den Guds manns seng og lukket efter ham og gikk ut. 
12:4:22: Så kalte hun på sin mann og sa: Send en av drengene til mig med en aseninne! Så vil jeg skynde mig avsted til den Guds mann og så komme tilbake. 
12:4:23: Han sa: Hvorfor vil du gå til ham idag? Det er jo hverken nymåne eller sabbat. Men hun sa: La mig bare gå! 
12:4:24: Så lot hun aseninnen sale og sa til sin dreng: Led den og gå avsted, stans mig ikke mens jeg rider, uten jeg sier dig til! 
12:4:25: Så drog hun avsted og kom til den Guds mann på Karmel-fjellet. Da den Guds mann så henne et stykke borte, sa han til sin dreng Gehasi: Se, der kommer konen fra Sunem. 
12:4:26: Spring henne nu i møte og si til henne: Står det vel til med dig og med din mann og med barnet? Hun svarte: Ja, det står vel til. 
12:4:27: Men da hun kom op på fjellet til den Guds mann, omfavnet hun hans føtter; da gikk Gehasi frem og vilde støte henne bort, men den Guds mann sa: La henne være! For hun har en stor hjertesorg; men Herren har skjult det for mig og ikke latt mig få vite det. 
12:4:28: Hun sa: Har jeg vel bedt min herre om en sønn? Sa jeg ikke: Du må ikke narre mig? 
12:4:29: Da sa han til Gehasi: Omgjord dine lender og ta min stav i hånden og gå avsted! Møter du nogen, så hils ikke på ham, og hilser nogen på dig, så svar ham ikke, og legg min stav på guttens ansikt. 
12:4:30: Men guttens mor sa: Så sant Herren lever, og så sant du selv lever: Jeg forlater dig ikke. Da stod han op og fulgte med henne. 
12:4:31: Gehasi gikk i forveien og la staven på guttens ansikt; men det hørtes ikke en lyd og viste sig ikke noget tegn til at han merket noget. Så vendte han tilbake og gikk Elisa i møte og fortalte ham det og sa: Gutten våknet ikke. 
12:4:32: Og da Elisa kom til huset, fikk han se at gutten lå død på hans seng. 
12:4:33: Så gikk han inn og lukket døren til og bad til Herren. 
12:4:34: Derefter steg han op og la sig over barnet med sin munn mot dets munn og sine øine mot dets øine og sine hender på dets hender, og da han således bøide sig over barnet, blev dets legeme varmt. 
12:4:35: Så stod han op igjen og gikk en gang frem og tilbake i kammeret; derefter steg han atter op og bøide sig over gutten; da nøs gutten syv ganger og slo øinene op. 
12:4:36: Da kalte han på Gehasi og sa: Kall på den sunamittiske kone! Han kalte på henne, og da hun kom inn til ham, sa han: Ta din sønn! 
12:4:37: Og hun kom og falt ned for hans føtter og bøide sig til jorden; så tok hun sin sønn og gikk ut. 
12:4:38: Elisa vendte tilbake til Gilgal; det var dengang hungersnød i landet, og da profetenes disipler engang satt der hos ham, sa han til sin dreng: Sett den store gryte over og kok en rett mat for profetenes disipler! 
12:4:39: Da gikk en av dem ut på marken for å sanke urter, og han fant en vill slyngplante og sanket av den ville gresskar, sin kappe full, og da han kom inn, skar han dem op i matgryten; for de kjente ikke til dem. 
12:4:40: Så øste de op for mennene forat de skulde ete; men da de begynte å ete av retten, skrek de op og ropte: Det er død i gryten, du Guds mann! Og de kunde ikke ete. 
12:4:41: Da sa han: Hent noget mel! Og han kastet det i gryten og sa: Øs op for folket, så de kan få ete! Og da var det ikke mere noget skadelig i gryten. 
12:4:42: Engang kom det en mann fra Ba'al-Salisa og han hadde med til den Guds mann brød som var bakt av førstegrøden, tyve byggbrød, og nyhøstet korn i sin pose. Da sa Elisa: Gi det til folket, så de kan få ete! 
12:4:43: Men hans tjener sa: Hvorledes kan jeg sette dette frem for hundre mann? Han svarte: Gi det til folket, så de kan få ete! For så sier Herren: De skal ete og få tilovers. 
12:4:44: Så satte han det frem for dem, og de åt og fikk tilovers efter Herrens ord. 
12:5:1: Na'aman, den syriske konges hærfører, hadde meget å si hos sin herre og var høit aktet, for ved ham hadde Herren gitt Syria seier; han var en veldig krigsmann, men spedalsk. 
12:5:2: Syrerne hadde engang gjort et herjetog og bortført en liten pike som fange fra Israels land; og hun tjente nu hos Na'amans hustru. 
12:5:3: Hun sa til sin frue: Bare min herre var hos profeten i Samaria! Da skulde nok han fri ham fra hans spedalskhet. 
12:5:4: Na'aman gikk inn til sin herre og fortalte ham det og sa: Så og så har piken fra Israels land sagt. 
12:5:5: Da sa kongen i Syria: Dra dit, så vil jeg sende et brev til Israels konge. Han drog avsted og tok med sig ti talenter sølv og seks tusen sekel gull og ti festklædninger. 
12:5:6: Han overgav brevet til Israels konge, og i det stod det: Når nu dette brev kommer til dig, så vil du se at jeg har sendt min tjener Na'aman til dig, forat du skal fri ham fra hans spedalskhet. 
12:5:7: Da Israels konge hadde lest brevet, sønderrev han sine klær og sa: Er jeg Gud, så jeg kan døde og gjøre levende, siden han sender bud til mig og vil at jeg skal fri en mann fra hans spedalskhet? Nu ser I vel grant at han søker en leilighet til strid med mig. 
12:5:8: Men da Elisa, den Guds mann, hørte at Israels konge hadde sønderrevet sine klær, sendte han bud til kongen og lot si: Hvorfor har du sønderrevet dine klær? La ham komme til mig! Så skal han kjenne at der er en profet i Israel. 
12:5:9: Så kom Na'aman med sine hester og sin vogn og holdt ved døren til Elisas hus. 
12:5:10: Og Elisa sendte et bud ut til ham og lot si: Gå og bad dig syv ganger i Jordan! Så skal ditt kjøtt bli friskt igjen, og du skal bli ren. 
12:5:11: Da blev Na'aman vred og drog bort og sa: Jeg tenkte at han vilde komme ut til mig og stå frem og påkalle Herrens, sin Guds navn og føre sin hånd frem og tilbake over det syke sted og ta bort spedalskheten. 
12:5:12: Er ikke elvene ved Damaskus, Abana og Parpar, bedre enn alle Israels vann? Kunde jeg ikke bade mig i dem og bli ren? Og han vendte om og drog bort i vrede. 
12:5:13: Men hans tjenere trådte frem og talte til ham og sa: Min far! Dersom profeten hadde pålagt dig noget svært, vilde du da ikke ha gjort det? Hvor meget mere når han bare sier til dig: Bad dig, så skal du bli ren! 
12:5:14: Da drog han ned og dukket sig syv ganger i Jordan efter den Guds manns ord; og hans kjøtt blev friskt som på en liten gutt, og han blev ren. 
12:5:15: Og han vendte tilbake til den Guds mann med hele sitt følge, og da han kom dit, trådte han frem for ham og sa: Nu vet jeg at det ikke er nogen Gud på hele jorden uten i Israel; sa ta nu imot en gave av din tjener! 
12:5:16: Men han sa: Så sant Herren lever, han hvis tjener jeg er: Jeg tar ikke imot noget. Og han bad ham inntrengende om å ta imot, men han vilde ikke. 
12:5:17: Da sa Na'aman: Siden du ikke vil, så la dog din tjener få så meget jord som et par mulesler kan bære! For din tjener vil ikke mere ofre brennoffer eller slaktoffer til andre guder, men til Herren alene. 
12:5:18: Men én ting tilgi Herren din tjener: Når min herre* går inn Rimmons hus for å tilbede der, og han støtter sig til min hånd, og jeg kaster mig ned der i Rimmons hus, så la Herren din tjener få tilgivelse for dette at jeg kaster mig ned i Rimmons hus! # <* kongen i Syria.> 
12:5:19: Han svarte ham: Dra bort i fred! Så drog han fra ham, men da han var kommet et stykke på veien, 
12:5:20: da tenkte Gehasi, den Guds mann Elisas dreng: Min herre har spart denne syrer Na'aman og ikke tatt imot av ham det han hadde hatt med sig; så sant Herren lever, vil jeg springe efter ham og få noget av ham. 
12:5:21: Så skyndte Gehasi sig efter Na'aman; og da Na'aman så en springe efter ham, steg han i hast ned av vognen og gikk ham i møte og sa: Står alt vel til? 
12:5:22: Han sa: Ja, men min herre har sendt mig og lar si: Just nu er det kommet to unge menn til mig fra Efra'im-fjellene, to av profetenes disipler; gi dem en talent sølv og to festklædninger! 
12:5:23: Na'aman svarte: Vær så snild og ta to talenter! Og han bad ham inntrengende og knyttet to talenter sølv inn i to punger og tok to festklædninger og overgav det til to av sine drenger, og de bar det foran ham; 
12:5:24: men da han kom til bakken, tok han det av deres hånd og gjemte det i huset, og han bad farvel med mennene, og de drog sin vei. 
12:5:25: Og selv gikk han inn og trådte frem for sin herre. Da spurte Elisa ham: Hvor kommer du fra, Gehasi? Han svarte: Din tjener har ikke vært noget sted. 
12:5:26: Men han sa til ham: Var jeg ikke til stede i ånden da mannen vendte om fra sin vogn og gikk dig i møte? Er det nu tid til å ta imot sølv og få sig klær og oljetrær og vingårder og småfe og storfe og tjenere og tjenestepiker? 
12:5:27: Derfor skal Na'amans spedalskhet henge ved dig og ved din ætt til evig tid. Så gikk han ut fra ham og var spedalsk, hvit som sne. 
12:6:1: Profetenes disipler sa til Elisa: Huset som vi sitter i her hos dig, er for trangt for oss. 
12:6:2: La oss få gå til Jordan og der hente hver sin bjelke og så bygge oss et hus der som vi kan holde til i! Han svarte: Ja, gjør det! 
12:6:3: Da sa en av dem: Vær så snild og gå med dine tjenere! Han svarte: Ja, jeg skal gå med. 
12:6:4: Så gikk han med dem, og da de kom til Jordan, tok de til å felle trær. 
12:6:5: Men da en av dem felte sin bjelke, falt øksen i vannet; da ropte han: Å, min herre, den var enda lånt. 
12:6:6: Da sa den Guds mann: Hvor falt den? Han viste ham stedet; da hugg han av et stykke tre og kastet det dit ned og fikk øksen til å flyte op. 
12:6:7: Og han sa: Ta den op! Så rakte mannen ut hånden og tok den. 
12:6:8: Kongen i Syria lå i krig med Israel; og han rådførte sig med sine menn og sa: På det og det sted vil jeg slå leir. 
12:6:9: Men den Guds mann sendte bud til Israels konge og lot si: Ta dig i vare for å dra forbi dette sted! For der vil syrerne dra ned. 
12:6:10: Så sendte Israels konge folk til det sted som den Guds mann hadde nevnt for ham og advart ham for, og han tok sig i vare der. Dette hendte ikke bare en gang, men flere ganger. 
12:6:11: Kongen i Syria blev meget urolig over dette; han kalte sine menn til sig og sa til dem: Kan I ikke si mig hvem det er av våre folk som holder med Israels konge? 
12:6:12: Da sa en av hans menn: Det har sig ikke så, herre konge; det er Elisa, profeten i Israel, som åpenbarer for Israels konge hvert ord du taler i ditt sovekammer. 
12:6:13: Han sa: Gå og se å få rede på hvor han er, så jeg kan sende folk dit og la ham hente! Da det så blev meldt ham at han var i Dotan, 
12:6:14: sendte han hester og vogner og en stor hær dit; de kom der om natten og omringet byen. 
12:6:15: Og da den Guds manns tjener tidlig om morgenen gikk ut, fikk han se at en hær med hester og vogner omringet byen. Da sa hans dreng til ham: Å, min herre, hvad skal vi gjøre? 
12:6:16: Han svarte: Vær ikke redd! De som er med oss, er flere enn de som er med dem. 
12:6:17: Og Elisa bad og sa: Herre! Oplat hans øine, så han kan se! Og Herren oplot drengens øine, og han fikk se at fjellet var fullt av gloende hester og vogner rundt omkring Elisa. 
12:6:18: Da så syrerne kom ned til ham, bad Elisa til Herren og sa: Slå dette folk med blindhet! Og han slo dem med blindhet, som Elisa hadde bedt om. 
12:6:19: Da sa Elisa til dem: Dette er ikke den rette vei og ikke den rette by; følg mig, så skal jeg føre eder til den mann I leter efter. Så førte han dem til Samaria. 
12:6:20: Og da de kom inn i Samaria, sa Elisa: Herre, oplat deres øine, så de kan se! Og Herren oplot deres øine, og de fikk se at de var midt i Samaria. 
12:6:21: Da Israels konge så dem, sa han til Elisa: Skal jeg hugge dem ned, min far, skal jeg hugge dem ned? 
12:6:22: Han svarte: Du skal ikke hugge dem ned; hugger du vel ned dem som du gjør til fanger med ditt sverd og din bue*? Sett brød og vann for dem, så de kan ete og drikke og så vende tilbake til sin herre! # <* du dreper ikke engang krigsfanger, langt mindre bør du da drepe disse.> 
12:6:23: Da lot han stelle til et måltid for dem, og de åt og derefter lot han dem fare, vendte tilbake til sin herre. Siden kom det ikke mere syriske herjeflokker inn i Israels land. 
12:6:24: Nogen tid efter samlet kongen i Syria Benhadad hele sin hær og drog op og kringsatte Samaria. 
12:6:25: Og mens de holdt Samaria kringsatt, blev det så stor hungersnød i byen at til sist hodet av et asen kostet åtti sekel sølv, og fjerdedelen av en kab duemøkk fem sekel sølv. 
12:6:26: Og da Israels konge engang gikk omkring på muren, ropte en kvinne til ham: Hjelp, herre konge! 
12:6:27: Han svarte: Når Herren ikke vil hjelpe dig, hvorfra skulde da jeg hente hjelp til dig? Fra treskeplassen eller fra vinpersen? 
12:6:28: Og kongen spurte henne: Hvad fattes dig? Hun svarte: Denne kvinne sa til mig: Kom hit med din sønn, så vil vi ete ham idag, og imorgen vil vi ete min sønn. 
12:6:29: Så kokte vi min sønn og åt ham. Dagen efter sa jeg til henne: Kom nu hit med din sønn, så vil vi ete ham. Men hun gjemte bort sin sønn. 
12:6:30: Da kongen hørte det som kvinnen fortalte, sønderrev han sine klær, som han gikk der omkring på muren; da fikk folket se at han hadde sekk innenfor på sitt legeme. 
12:6:31: Og han sa: Gud la det gå mig ille både nu og siden, om Elisas, Safats sønns hode skal bli sittende på ham idag! 
12:6:32: Mens Elisa satt i sitt hus, og de eldste satt der hos ham, sendte kongen en mann dit foran sig; men før budet kom til ham*, sa han til de eldste: Ser I at denne mordersønn** har sendt bud hit for å ta mitt hode? Se nu til å stenge døren når budet kommer, og trykke ham tilbake med den! Høres ikke allerede lyden av hans herres trin efter ham? # <* Elisa.> # <** Akabs sønn, 1KG 19, 10. 14; 21, 19.> 
12:6:33: Mens han ennu talte med dem, kom budet ned til ham. Da sa han*: Se for en ulykke** det er kommet fra Herren! Hvorfor skulde jeg lenger bie på Herren? # <* kongen som var kommet efter budet.> # <** 2KG 6, 29.> 
12:7:1: Da sa Elisa: Hør Herrens ord! Så sier Herren: Imorgen ved denne tid skal et mål fint mel være å få for en sekel og likeså to mål bygg for en sekel i Samarias port. 
12:7:2: Men den høvedsmann hvis hånd kongen støttet sig på, svarte den Guds mann: Om så Herren gjorde luker på himmelen, hvorledes skulde slikt kunne hende? Han svarte: Du skal få se det med egne øine, men ikke få ete derav. 
12:7:3: Foran byporten var det fire spedalske menn; de sa til hverandre: Hvorfor skal vi bli sittende her til vi dør? 
12:7:4: Sier vi: Vi vil gå inn i byen, så er det hungersnød i byen, og vi må dø der, og blir vi sittende her, må vi også dø; så kom nu og la oss gå over til syrernes leir! Lar de oss leve, så lever vi, og dreper de oss, sa dør vi. 
12:7:5: Så stod de op i aftenskumringen for å gå inn i syrernes leir; og da de kom til utkanten av syrernes leir, fantes det ikke en mann der. 
12:7:6: For Herren hadde latt syrernes leir høre lyd av vogner og hester, lyden av en stor hær; da sa de til hverandre: Du skal se Israels konge har leid hetittenes konger og Egyptens konger mot oss til å overfalle oss. 
12:7:7: Og de brøt op i aftenskumringen og flyktet; de forlot sine telt og sine hester og asener, hele leiren som den stod der, og flyktet for å berge livet. 
12:7:8: Da nu de spedalske kom til utkanten av leiren, gikk de inn i et telt og åt og drakk, og de tok sølv og gull og klær der og gikk bort og skjulte det; derefter vendte de tilbake og gikk inn i et annet telt og tok hvad der var, og gikk bort og skjulte det. 
12:7:9: Men så sa de til hverandre: Det er ikke rett det vi gjør; idag kan vi bære frem et gledelig budskap; tier vi stille og venter til morgenen gryr, så faller det skyld på oss; så kom nu og la oss gå inn og melde det i kongens hus! 
12:7:10: Så kom de og ropte til vakten ved byporten og fortalte det og sa: Vi kom til syrernes leir; det var ikke en mann å se eller lyden av et menneske å høre, men hestene og asenene stod bundet, og teltene var som de pleier å være. 
12:7:11: Portvokterne ropte det ut, og det blev meldt helt inn i kongens hus. 
12:7:12: Da stod kongen op om natten og sa til sine menn: Jeg skal si eder hvad syrerne har gjort mot oss; de vet at vi lider hunger, derfor har de draget ut av leiren for å skjule sig på marken og tenker som så: Når de nu drar ut av byen, så griper vi dem levende, og så kan vi komme inn i byen. 
12:7:13: Men en av hans menn svarte: La nogen av dine folk ta fem av de hester som ennu er tilbake her i byen! Det kan jo ikke gå dem verre enn hele mengden av israelitter som ennu er tilbake her, eller verre enn hele mengden av israelitter som er omkommet. Og la oss så sende dem avsted, så får vi se! 
12:7:14: Da tok de to vogner med hester for, og kongen sendte dem efter syrernes hær og sa: Dra avsted og se hvordan det har sig! 
12:7:15: Så drog de efter dem like til Jordan og fant hele veien full av klær og andre ting som syrerne hadde kastet fra sig da de i angst ilte avsted; og sendebudene vendte tilbake og meldte det til kongen. 
12:7:16: Så gikk folket ut og plyndret syrernes leir; da blev et mål fint mel å få for en sekel og likeså to mål bygg for en sekel, efter Herrens ord. 
12:7:17: Kongen hadde satt den høvedsmann hvis hånd han støttet sig på, til å ha opsyn med porten; men folket trådte ham ned i porten, så han døde, således som den Guds mann hadde sagt - som han hadde sagt dengang kongen kom ned til ham. 
12:7:18: For da den Guds mann sa til kongen: Imorgen ved denne tid skal to mål bygg være å få for en sekel og likeså et mål fint mel for en sekel i Samarias port, 
12:7:19: da svarte høvedsmannen den Guds mann: Om så Herren gjorde luker på himmelen, hvorledes skulde det kunne gå således til? Han svarte: Du skal få se det med egne øine, men ikke få ete derav. 
12:7:20: Og således gikk det ham; folket trådte ham ned i porten, så han døde. 
12:8:1: Elisa sa til den kvinne hvis sønn han hadde gjort levende: Gjør dig rede og dra bort med hele ditt hus og ophold dig på et eller annet fremmed sted! For Herren har kalt på hungersnøden, og den kommer også over landet og skal vare i syv år. 
12:8:2: Og kvinnen gjorde sig rede og gjorde efter den Guds manns ord; hun drog bort med hele sitt hus og opholdt sig som fremmed i filistrenes land i syv år. 
12:8:3: Men da syv år var gått, vendte kvinnen tilbake fra filistrenes land; og hun gikk avsted for å be kongen om hans hjelp til å få igjen sitt hus og sin jord. 
12:8:4: Kongen holdt da på å tale med Gehasi, den Guds manns dreng, og sa: Fortell mig om alle de store gjerninger Elisa har gjort! 
12:8:5: Og just som han fortalte kongen at han hadde gjort den døde levende, kom den kvinne hvis sønn han hadde gjort levende, og bad kongen om hans hjelp til å få sitt hus og sin jord. Da sa Gehasi: Herre konge! Her er kvinnen, og her er hennes sønn som Elisa gjorde levende. 
12:8:6: Så spurte kongen kvinnen, og hun fortalte ham det; og kongen lot henne få en av hoffolkene med sig og sa: Sørg for at hun får igjen alt det som hører henne til, og alt hvad hennes jord har kastet av sig fra den dag hun forlot landet, og til nu! 
12:8:7: Elisa kom til Damaskus mens kongen i Syria Benhadad lå syk. Da det blev fortalt kongen at den Guds mann var kommet dit, 
12:8:8: sa han til Hasael: Ta en gave med dig og gå den Guds mann i møte og spør Herren gjennem ham om jeg skal komme mig av denne sykdom! 
12:8:9: Så drog Hasael ham i møte og tok en gave med sig av det beste som fantes i Damaskus, så meget som firti kameler kunde bære; og han kom og stod frem for ham og sa: Din sønn Benhadad, kongen i Syria, har sendt mig til dig og lar spørre: Skal jeg komme mig av denne sykdom? 
12:8:10: Elisa svarte: Gå du bare og si til ham: Du skal leve*. Men Herren har latt mig se at han skal dø. # <* hvad jeg vet at du i alle tilfelle vil gjøre, 2KG 8, 14.> 
12:8:11: Og den Guds mann holdt sine øine festet på ham overmåte lenge og begynte å gråte. 
12:8:12: Da sa Hasael: Hvorfor gråter min herre? Han svarte: Fordi jeg vet at du skal gjøre Israels barn meget ondt; deres festninger skal du sette ild på, deres unge menn skal du drepe med sverdet, deres små barn skal du knuse, og deres fruktsommelige kvinner skal du opskjære. 
12:8:13: Hasael sa: Hvad er din tjener, den hund, at han skulde gjøre så store ting? Elisa svarte: Herren har latt mig se dig som konge over Syria. 
12:8:14: Så forlot han Elisa og kom til sin herre; han spurte ham: Hvad sa Elisa til dig? Han svarte: Han sa til mig at du skal leve. 
12:8:15: Men dagen efter tok han et teppe og dyppet det i vann og bredte det over hans ansikt, og han døde; og Hasael blev konge i hans sted. 
12:8:16: I Israels konge Jorams, Akabs sønns femte år, mens Josafat ennu var konge i Juda, blev Joram, Josafats sønn, konge i Juda. 
12:8:17: Han var to og tretti år gammel da han blev konge, og regjerte åtte år i Jerusalem. 
12:8:18: Han vandret på Israels kongers vei, likesom Akabs hus hadde gjort, for han hadde en datter av Akab til hustru, og han gjorde hvad ondt var i Herrens øine. 
12:8:19: Men Herren vilde ikke ødelegge Juda for sin tjener Davids skyld, fordi han hadde lovt ham at han vilde la en lampe brenne for ham og hans sønner gjennem alle tider. 
12:8:20: I hans dager falt Edom fra Juda og tok sig en konge. 
12:8:21: Da drog Joram over til Sa'ir med alle sine stridsvogner; han brøt op om natten og slo edomittene, som hadde omringet ham, og høvedsmennene over deres vogner; men folket flyktet til sine hjem. 
12:8:22: Således falt Edom fra Juda og har vært skilt fra dem til den dag idag; på samme tid falt også Libna fra. 
12:8:23: Hvad som ellers er å fortelle om Joram og om alt det han gjorde, er opskrevet i Judas kongers krønike. 
12:8:24: Og Joram la sig til hvile hos sine fedre og blev begravet hos sine fedre i Davids stad; og hans sønn Akasja blev konge i hans sted. 
12:8:25: I Israels konge Jorams, Akabs sønns tolvte år, blev Akasja, Jorams sønn, konge i Juda. 
12:8:26: Akasja var to og tyve år gammel da han blev konge, og regjerte ett år i Jerusalem; hans mor hette Atalja og var en datter* av Israels konge Omri. # <* sønnedatter, 2KG 8, 18.> 
12:8:27: Han vandret på samme vei som Akabs hus og gjorde hvad ondt var i Herrens øine, likesom Akabs hus; for han var en slektning av Akabs hus. 
12:8:28: Sammen med Joram, Akabs sønn, drog han ut i krig mot Hasael, kongen i Syria, og stred mot ham ved Ramot i Gilead. Men syrerne såret Joram. 
12:8:29: Da vendte kong Joram tilbake for å la sig læge i Jisre'el for de sår syrerne hadde slått ham ved Rama da han stred mot Syrias konge Hasael; og Judas konge Akasja, Jorams sønn, drog ned til Jisre'el for å se til Joram, Akabs sønn, fordi han var syk. 
12:9:1: Profeten Elisa kalte på en av profetenes disipler og sa til ham: Omgjord dine lender og ta denne oljekrukke i din hånd og gå til Ramot i Gilead! 
12:9:2: Når du kommer dit, skal du opsøke Jehu, sønn av Nimsis sønn Josafat, og gå så inn og be ham stå op der han sitter blandt sine brødre, og før ham inn i det innerste kammer. 
12:9:3: Ta så oljekrukken og hell den ut over hans hode og si: Så sier Herren: Jeg har salvet dig til konge over Israel! Så skal du åpne døren og flykte og ikke dryge. 
12:9:4: Så gikk den unge mann - den unge profet - til Ramot i Gilead. 
12:9:5: Og da han kom dit, så han hærens høvedsmenn sitte der; og han sa: Jeg har noget å si dig, høvedsmann! Da sa Jehu: Hvem av alle oss her? Han svarte: Dig, høvedsmann! 
12:9:6: Da stod han op og gikk inn i huset, og han helte oljen ut over hans hode og sa til ham: Så sier Herren, Israels Gud: Jeg har salvet dig til konge over Herrens folk, over Israel. 
12:9:7: Du skal gjøre ende på din herre Akabs hus, for jeg vil hevne mine tjenere profetenes blod og alle Herrens tjeneres blod på Jesabel. 
12:9:8: Og hele Akabs hus skal gå til grunne; jeg vil utrydde alle menn av Akabs ætt, både myndige og umyndige, i Israel. 
12:9:9: Jeg vil gjøre med Akabs hus som med Jeroboams, Nebats sønns hus, og med Baesas, Akias sønns hus. 
12:9:10: Og Jesabel skal hundene fortære på Jisre'els mark, og ingen skal begrave henne. Så åpnet han døren og flyktet. 
12:9:11: Da Jehu kom ut til sin herres menn, spurte de ham: Står alt vel til? Hvorfor kom denne gale mann* til dig? Han svarte: I kjenner jo mannen og hans underlige tanker. # <* HSE 9, 7.> 
12:9:12: Men de sa: Du taler ikke sant; si oss hvad det er! Da sa han: Så og så talte han til mig og sa: Så sier Herren: Jeg har salvet dig til konge over Israel. 
12:9:13: Da skyndte de sig og tok hver sin kappe og la dem under ham på trappetrinene; og de støtte i basunen og ropte: Jehu er konge! 
12:9:14: Således fikk Jehu, sønn av Nimsis sønn Josafat, en sammensvergelse i stand mot Joram - Joram hadde med hele Israel ligget på vakt ved Ramot i Gilead mot kongen i Syria Hasael; 
12:9:15: men selv var kong Joram vendt tilbake for å la sig læge i Jisre'el for de sår syrerne hadde slått ham da han stred mot Syrias konge Hasael. - Og Jehu sa: Hvis I så synes, så la ingen rømme og slippe ut av byen, så han kunde gå avsted og kunngjøre det i Jisre'el! 
12:9:16: Så steg Jehu op i sin vogn og drog til Jisre'el, for Joram lå syk der; og Judas konge Akasja hadde draget der ned for å se til Joram. 
12:9:17: Vekteren som stod på tårnet i Jisre'el, ropte da han så Jehu komme med sin flokk: Jeg ser en flokk som kommer. Da sa Joram: Ta og send en rytter for å møte dem og spørre: Kommer I med fred? 
12:9:18: Rytteren drog ham i møte og sa: Så sier kongen: Kommer I med fred? Jehu svarte: Hvad kommer det dig ved? Vend om og følg mig! Vekteren meldte det og sa: Budet har nådd frem til dem, men kommer ikke tilbake. 
12:9:19: Da sendte han en annen rytter, og da han kom til dem, sa han: Så sier kongen: Kommer I med fred? Jehu svarte: Hvad kommer det dig ved? Vend om og følg mig! 
12:9:20: Vekteren meldte det og sa: Han har nådd frem til dem, men kommer ikke tilbake. Efter måten de farer frem på, ser det ut som det kunde være Jehu, Nimsis sønn; for han farer frem som en rasende. 
12:9:21: Da sa Joram: Spenn for! Og de spente for hans vogn; og Israels konge Joram og Judas konge Akasja drog ut, hver på sin vogn; de drog Jehu i møte og traff ham på jisre'elitten Nabots mark. 
12:9:22: Da Joram fikk øie på Jehu, spurte han: Kommer du med fred, Jehu? Han svarte: Hvorledes kan du tale om fred så lenge din mor Jesabel holder på med sitt horelevnet og sine mange trolldomskunster? 
12:9:23: Da vendte Joram om og flyktet, og han ropte til Akasja: Det er forræderi, Akasja! 
12:9:24: Men Jehu grep sin bue med hånden og skjøt Joram mellem armene, så pilen gikk ut gjennem hjertet, og han sank ned i sin vogn. 
12:9:25: Da sa Jehu til sin høvedsmann Bidkar: Ta og kast ham inn på jisre'elitten Nabots mark! Kom i hu hvorledes jeg og du red sammen efter hans far Akab, og Herren uttalte dette ord mot ham: 
12:9:26: Sannelig, Nabots blod og hans sønners blod så jeg igår, sier Herren, og jeg vil gjengjelde dig det på denne mark, sier Herren. Så ta nu og kast ham inn på marken efter Herrens ord! 
12:9:27: Da Judas konge Akasja så det, flyktet han og tok veien til havehuset; men Jehu satte efter ham og ropte: Slå også ham ned! Og de slo ham ned i hans vogn i Gur-kleven ved Jibleam, og han flyktet til Megiddo og døde der. 
12:9:28: Hans tjenere kjørte ham til Jerusalem, og de begravde ham i hans grav hos hans fedre i Davids stad. 
12:9:29: Men Akasja var blitt konge over Juda i Jorams, Akabs sønns ellevte år. 
12:9:30: Så kom Jehu til Jisre'el; og da Jesabel hørte det, sminket hun sine øine og smykket sitt hode og så ut gjennem vinduet. 
12:9:31: Og da Jehu kom inn gjennem porten, ropte hun: Kommer du med fred, du Simri som slo din herre ihjel? 
12:9:32: Men han så op mot vinduet og sa: Hvem holder med mig? Hvem? Da så to, tre hoffmenn ut gjennem vinduet. 
12:9:33: Han ropte: Styrt henne ned! Da styrtet de henne ned, og hennes blod sprutet opefter veggen og på hestene, og han lot hestene tråkke henne ned. 
12:9:34: Så gikk han inn, og da han hadde ett og drukket, sa han: Se efter denne forbannede kvinne og begrav henne! For hun er kongedatter. 
12:9:35: Men da de gikk avsted for å begrave henne, fant de ikke annet av henne enn hjerneskallen og føttene og hendene. 
12:9:36: De vendte tilbake og fortalte ham det; da sa han: Dette er det ord som Herren talte gjennem sin tjener tisbitten Elias da han sa: På Jisre'els mark skal hundene fortære Jesabels kjøtt, 
12:9:37: og Jesabels lik skal bli som møkk på jorden på Jisre'els mark, så ingen skal kunne si: Dette er Jesabel. 
12:10:1: Akab hadde sytti sønner i Samaria; og Jehu skrev brever og sendte dem til Samaria, til de øverste i Jisre'el, de eldste, og til de formyndere som Akab hadde innsatt for sine sønner; i brevene stod det: 
12:10:2: Når nu dette brev kommer til eder, I som har eders herres sønner hos eder, og som råder over vognene og hestene og en fast by og våbnene, 
12:10:3: så utse eder den beste og mest skikkede blandt eders herres sønner og sett ham på hans fars trone og strid for eders herres hus! 
12:10:4: Men de blev meget forferdet og sa: De to konger holdt ikke stand mot ham; hvorledes skulde da vi kunne det? 
12:10:5: Så sendte slottshøvdingen og høvdingen over byen og de eldste og formynderne bud til Jehu og lot si: Vi er dine tjenere, og alt hvad du sier til oss, vil vi gjøre; vi vil ikke gjøre nogen til konge; gjør du hvad du finner for godt! 
12:10:6: Da skrev han atter et brev til dem, og i det stod det: Dersom I holder med mig og vil lyde mitt bud, så hugg hodet av eders herres sønner og kom til mig i Jisre'el imorgen ved denne tid! Men kongens sønner, sytti i tallet, bodde hos de store i byen, som opfostret dem. 
12:10:7: Da nu brevet kom til dem, tok de kongens sønner og drepte dem, sytti i tallet, og la deres hoder i kurver og sendte dem til ham i Jisre'el. 
12:10:8: Da budet kom og meldte ham at de var kommet med kongesønnenes hoder, sa han: Legg dem i to hauger foran inngangen til porten til imorgen! 
12:10:9: Og om morgenen gikk han ut og trådte frem og sa til alt folket: I er uten skyld! Det er jeg som har fått i stand en sammensvergelse mot min herre og drept ham; men hvem har slått alle disse ihjel? 
12:10:10: Nu ser I at ikke noget av det som Herren har talt mot Akabs hus, faller til jorden; men Herren har gjort det som han talte gjennem sin tjener Elias. 
12:10:11: Så slo Jehu ihjel alle som var tilbake av Akabs hus i Jisre'el, og alle hans stormenn og hans kjenninger og hans prester; han lot ikke nogen bli i live eller slippe unda. 
12:10:12: Siden gjorde Jehu sig rede og drog avsted til Samaria. Og da han underveis kom til Bet-Eked-Haro'im, 
12:10:13: møtte han Judas konge Akasjas brødre*. Han spurte dem: Hvem er I? De svarte: Vi er brødre av Akasja og er på vei ned for å hilse på kongens barn og dronningens barn. # <* d.e. brorsønner, 2KR 22, 8.> 
12:10:14: Da sa han: Grip dem levende! Så grep de dem levende og drepte dem ved brønnen i Bet-Eked, to og firti i tallet; han lot ikke en av dem bli i live. 
12:10:15: Da han så drog derfra, traff han på Jonadab, Rekabs sønn, som kom ham i møte, og han hilste på ham og sa til ham: Mener du det like så opriktig med mig som jeg med dig? Jonadab svarte: Ja. Er det så [sa Jehu], da gi mig din hånd! Da gav han ham hånden; og han lot ham stige op til sig i vognen. 
12:10:16: Og han sa: Følg med mig og se hvor nidkjær jeg er for Herren! Så lot han ham kjøre på sin vogn. 
12:10:17: Og da han kom til Samaria, slo han ihjel alle som var tilbake av Akabs hus i Samaria, til han hadde utryddet det, efter det ord som Herren hadde talt til Elias. 
12:10:18: Siden samlet Jehu alt folket og sa til dem: Akab har dyrket Ba'al lite, Jehu vil dyrke ham meget. 
12:10:19: Så kall nu alle Ba'als profeter, alle hans tjenere og alle hans prester hit til mig, ikke en må mangle! For jeg vil holde en stor slaktofferfest for Ba'al; hver den som ikke møter, skal late livet. Men dette gjorde Jehu med svik for å utrydde Ba'als tjenere. 
12:10:20: Så sa Jehu: Hold en hellig festforsamling for Ba'al! Da utropte de en sådan fest. 
12:10:21: Og Jehu sendte bud omkring i hele Israel, og alle Ba'als tjenere kom; det var ikke nogen som lot være å komme. Og de gikk inn i Ba'als hus, og Ba'als hus blev fullt fra ende til annen. 
12:10:22: Så sa han til den som hadde tilsyn med klædekammeret: Hent frem klædninger for alle Ba'als tjenere! Og han hentet klædningene frem til dem. 
12:10:23: Da nu Jehu sammen med Jonadab, Rekabs sønn, kom til Ba'als hus, sa han til Ba'als tjenere: Se nøie efter at det ikke er nogen av Herrens tjenere her iblandt eder, men bare Ba'als-tjenere! 
12:10:24: Så gikk de inn for å ofre slaktoffer og brennoffer, og Jehu stilte åtti mann utenfor huset og sa: Hvis nogen slipper unda av de menn som jeg overgir i eders hender, skal det bøtes liv for liv. 
12:10:25: Da de var ferdig med å ofre brennofferet, sa Jehu til drabantene og livvakten: Gå inn, slå dem ihjel, la ikke en slippe ut! Så slo de dem med sverdets egg, og drabantene og livvakten kastet dem ut og gikk til Ba'als-husets by*. # <* d.e. den del av byen hvor Ba'als hus var, eller en del boliger som hørte til Ba'als hus.> 
12:10:26: Og de bar ut støttene i Ba'als hus og brente dem op, 
12:10:27: og Ba'als egen støtte rev de ned; og Ba'als hus rev de ned og gjorde det til vannhuser, som er der den dag idag. 
12:10:28: Således utryddet Jehu Ba'al av Israel. 
12:10:29: Men fra Jeroboams, Nebats sønns synder, som han hadde fått Israel til å gjøre, vek Jehu ikke - fra gullkalvene i Betel og Dan. 
12:10:30: Herren sa til Jehu: Fordi du har gjort til gagns hvad rett var i mine øine, og gjort mot Akabs hus alt det jeg vilde, så skal dine sønner til fjerde ledd sitte på Israels trone. 
12:10:31: Men Jehu gav ikke akt på å vandre i Herrens, Israels Guds lov av alt sitt hjerte; han vek ikke fra Jeroboams synder, som han hadde fått Israel til å gjøre. 
12:10:32: Ved denne tid begynte Herren å løsrive stykker av Israel; Hasael slo dem på hele Israels grense 
12:10:33: og tok på østsiden av Jordan hele Gileads land, gadittene og rubenittene og manassittene, like fra Aroer ved Arnon-åen, både Gilead og Basan. 
12:10:34: Hvad som ellers er å fortelle om Jehu, om alt det han gjorde og alle hans store gjerninger, det er opskrevet i Israels kongers krønike. 
12:10:35: Og Jehu la sig til hvile hos sine fedre, og han blev begravet i Samaria; og hans sønn Joakas blev konge i hans sted. 
12:10:36: Den tid Jehu var konge over Israel i Samaria, var åtte og tyve år. 
12:11:1: Da Akasjas mor Atalja fikk vite at hennes sønn var død, tok hun sig for å utrydde hele den kongelige ætt. 
12:11:2: Men Akasjas søster Joseba, kong Jorams datter, tok Joas, Akasjas sønn, og førte ham hemmelig bort fra kongesønnene som skulde drepes, og førte ham og hans amme inn i sengekammeret*. Således skjulte de ham for Atalja, så han ikke blev drept. # <* d.e. det kammer hvor sengklærne blev opbevart.> 
12:11:3: Siden var han hos henne i Herrens hus og blev holdt skjult i seks år, mens Atalja regjerte i landet. 
12:11:4: Men i det syvende år sendte Jojada bud efter livvaktens høvedsmenn og lot dem komme inn til sig i Herrens hus og gjorde en pakt med dem og tok dem i ed der i Herrens hus og viste dem kongens sønn. 
12:11:5: Og han bød dem og sa: Hør nu hvad I skal gjøre: Den ene tredjedel av eder skal tiltrede vakten på sabbaten og holde vakt ved kongens hus, 
12:11:6: og den annen tredjedel i Surporten og den tredje tredjedel i porten bak drabantene; således skal I holde vakt ved huset og avstenge det; 
12:11:7: men de to deler av eder, de som treder av på sabbaten, de skal holde vakt i Herrens hus hos kongen. 
12:11:8: I skal stille eder rundt omkring kongen, hver mann med våben i hånd, og den som vil trenge inn i rekkene, skal drepes; I skal være om kongen, bade når han går ut, og når han går inn. 
12:11:9: Høvedsmennene gjorde aldeles som presten Jojada bød dem; de tok hver sine menn, både dem som tiltrådte på sabbaten, og dem som trådte av på sabbaten, og kom til presten Jojada. 
12:11:10: Og presten gav høvedsmennene de spyd og skjold som hadde tilhørt kong David, og som var i Herrens hus. 
12:11:11: Og drabantene stod opstilt, hver mann med våben i hånd, fra husets høire side til husets venstre side bortimot alteret og bortimot huset rundt omkring kongen. 
12:11:12: Så førte han kongesønnen ut og satte kronen på ham og overgav ham vidnesbyrdet*, og de gjorde ham til konge og salvet ham; og de klappet i hendene og ropte: Kongen leve! # <* loven, 5MO 17, 18 fg.> 
12:11:13: Da Atalja hørte drabantenes og folkets rop, gikk hun inn i Herrens hus til folket. 
12:11:14: Der fikk hun se at kongen stod på forhøiningen, som skikk var, og høvedsmennene og trompetblåserne stod hos ham, og hele folkemengden gledet sig og støtte i trompetene; da sønderrev Atalja sine klær og ropte: Oprør, oprør! 
12:11:15: Men presten Jojada bød høvedsmennene, dem som var satt over hæren, og sa til dem: Før henne ut mellem rekkene, og om nogen følger henne, så drep ham med sverd! For presten hadde sagt: Hun må ikke drepes i Herrens hus! 
12:11:16: Så gjorde de plass for henne til begge sider, og hun gikk den vei som hestene pleier å kjøre inn til kongens hus; og der blev hun drept. 
12:11:17: Og Jojada gjorde en pakt mellem Herren og kongen og folket at de skulde være Herrens folk, og en pakt mellem kongen og folket. 
12:11:18: Og hele folkemengden gikk inn i Ba'als hus og rev det ned; hans altere og hans billeder knuste de aldeles, og Ba'als prest Mattan drepte de foran alterne. Og presten satte folk til å ha tilsyn med Herrens hus. 
12:11:19: Så tok han med sig høvedsmennene og livvakten og drabantene og hele folkemengden, og de førte kongen ned fra Herrens hus og gikk inn i kongens hus gjennem drabantenes port; og han satte sig på kongetronen. 
12:11:20: Og hele folkemengden gledet sig, og byen var rolig; men Atalja hadde de drept med sverd i kongens hus. 
12:11:21: Joas var syv år gammel da han blev konge. 
12:12:1: I Jehus syvende år blev Joas konge, og han regjerte i Jerusalem i firti år; hans mor hette Sibja og var fra Be'erseba. 
12:12:2: Joas gjorde hvad rett var i Herrens øine, hele tiden, så lenge presten Jojada veiledet ham. 
12:12:3: Dog blev offerhaugene ikke nedlagt; folket blev ved å ofre og brenne røkelse på haugene. 
12:12:4: Joas sa til prestene: Alle penger som kommer inn til Herrens hus som hellige gaver, både pengene fra dem som kommer med i manntallet*, og utløsnings-pengene for folk efter det verd som fastsettes for hver enkelt**, og alle de penger som nogen av fri vilje kommer med til Herrens hus, # <* 2MO 30, 12 fg.> # <** 3MO 27, 2 fg. 4MO 18, 15 fg.> 
12:12:5: dem skal prestene ta imot, hver av sine kjenninger, og de skal bøte husets brøst overalt hvor det finnes nogen brøst. 
12:12:6: Men i kong Joas' tre og tyvende år hadde prestene ennu ikke bøtt husets brøst. 
12:12:7: Da kalte kong Joas presten Jojada og prestene til sig og sa til dem: Hvorfor bøter I ikke husets brøst? Nu skal I ikke lenger ta imot penger av eders kjenninger, men gi dem fra eder til å bøte husets brøst med. 
12:12:8: Og prestene samtykte i at de ikke skulde ta imot penger av folket og heller ikke gi sig av med å bøte husets brøst. 
12:12:9: Så tok presten Jojada en kiste og boret et hull i dens lokk og satte den ved alteret på høire side, der hvor folk går inn i Herrens hus, og i den la prestene som hadde vakt ved dørtreskelen, alle de penger som kom inn til Herrens hus. 
12:12:10: Og når de så at det var mange penger i kisten, gikk kongens skriver og ypperstepresten dit op og knyttet dem inn og tellet de penger som fantes i Herrens hus. 
12:12:11: Så overgav de pengene som de hadde veid, til dem som forestod arbeidet og hadde tilsyn med Herrens hus; og de gav dem igjen ut til tømmermennene og bygningsmennene som arbeidet på Herrens hus, 
12:12:12: og til murerne og stenhuggerne og likeså til innkjøp av tre og hugne stener for å bøte Herrens huses brøst og i det hele til alt som blev gitt ut for å sette huset i stand. 
12:12:13: Men det blev ikke gjort sølvfat eller kniver eller skåler til å sprenge blod med eller trompeter eller noget slags kar av gull eller sølv til Herrens hus for de penger som kom inn til Herrens hus, 
12:12:14: men de overgav dem til dem som forestod arbeidet, og de brukte dem til å sette Herrens hus i stand. 
12:12:15: Og det blev ikke holdt regnskap med de menn til hvem pengene blev overgitt forat de skulde gi dem til dem som utførte arbeidet; for de fór redelig frem. 
12:12:16: Men skyldoffer- og syndofferpengene kom ikke inn til Herrens hus; de tilfalt prestene. 
12:12:17: På den tid drog Syrias konge Hasael op og stred mot Gat og inntok det; derefter satte han sig fore å dra op mot Jerusalem. 
12:12:18: Da tok Judas konge Joas alle de hellige ting som hans fedre Josafat og Joram og Akasja, Judas konger, hadde helliget, og dem han selv hadde helliget, og alt det gull som fantes i skattkammerne i Herrens hus og i kongens hus, og sendte det til Syrias konge Hasael. Da drog han bort og gikk ikke mot Jerusalem. 
12:12:19: Hvad som ellers er å fortelle om Joas og om alt det han gjorde, er opskrevet i Judas kongers krønike. 
12:12:20: Hans tjenere reiste sig og fikk i stand en sammensvergelse og drepte Joas i Millo-huset, som går ned til Silla; 
12:12:21: det var Josakar, Sim'ats sønn, og Josabad, Somers sønn, hans tjenere, som drepte ham. Da han var død, begravde de ham hos hans fedre i Davids stad; og hans sønn Amasja blev konge i hans sted. 
12:13:1: I Judas konge Joas', Akasjas sønns tre og tyvende år blev Joakas, Jehus sønn, konge over Israel i Samaria, og han regjerte i sytten år. 
12:13:2: Han gjorde hvad ondt var i Herrens øine, og fulgte Jeroboam, Nebats sønn, i hans synder, som han hadde fått Israel til å gjøre; dem vek han ikke fra. 
12:13:3: Da optendtes Herrens vrede mot Israel, og han gav dem i den syriske konge Hasaels og hans sønn Benhadads hånd hele tiden. 
12:13:4: Men Joakas bønnfalt Herren, og Herren hørte ham, fordi han så Israels trengsel; for syrernes konge trengte dem. 
12:13:5: Og Herren gav Israel en frelser, så de slapp ut av syrernes herredømme, og Israels barn bodde i sine hjem som før. 
12:13:6: Allikevel vek de ikke fra Jeroboams huses synder, som han hadde fått Israel til å gjøre; i dem vandret de; ja, endog Astarte-billedet blev stående i Samaria. 
12:13:7: [Herren bønnhørte Joakas] for han hadde ikke levnet Joakas flere folk enn femti hestfolk og ti vogner og ti tusen fotfolk; for kongen i Syria hadde ødelagt dem og tresket dem til støv. 
12:13:8: Hvad som ellers er å fortelle om Joakas, om alt det han gjorde, og om hans store gjerninger, det er opskrevet i Israels kongers krønike. 
12:13:9: Og Joakas la sig til hvile hos sine fedre, og han blev begravet i Samaria; og hans sønn Joas blev konge i hans sted. 
12:13:10: I Judas konge Joas' syv og trettiende år blev Joakas' sønn Joas konge over Israel i Samaria, og han regjerte i seksten år. 
12:13:11: Han gjorde hvad ondt var i Herrens øine; han vek ikke fra nogen av Jeroboams, Nebats sønns synder, som han hadde fått Israel til å gjøre; dem vandret han i. 
12:13:12: Hvad som ellers er å fortelle om Joas, om alt det han gjorde, og om hans store gjerninger, hvorledes han stred mot Judas konge Amasja, det er opskrevet i Israels kongers krønike. 
12:13:13: Og Joas la sig til hvile hos sine fedre, og Jeroboam satte sig på hans trone. Joas blev begravet i Samaria hos Israels konger. 
12:13:14: Da Elisa var falt i den sykdom som han døde av, kom Israels konge Joas ned til ham og bøide sig gråtende over ham og sa: Min far, min far! Israels vogner og ryttere! 
12:13:15: Da sa Elisa til ham: Hent en bue og nogen piler! Så hentet han en bue og nogen piler til ham. 
12:13:16: Og han sa til Israels konge: Legg din hånd på buen! Og han la sin hånd på den. Og Elisa la sine hender på kongens hender. 
12:13:17: Så sa han: Lukk op vinduet mot øst! Og han lukket det op. Da sa Elisa: Skyt! Så skjøt han. Da sa han: En frelsens pil fra Herren, en frelsens pil mot syrerne! Du skal slå syrerne i Afek og gjøre ende på dem. 
12:13:18: Så sa han: Ta pilene! Og han tok dem. Da sa han til Israels konge: Slå på jorden! Og han slo tre ganger; så holdt han op. 
12:13:19: Da blev den Guds mann vred på ham og sa: Du burde ha slått fem eller seks ganger; da hadde du slått syrerne og gjort ende på dem; men nu skal du bare slå syrerne tre ganger. 
12:13:20: Så døde Elisa, og de begravde ham. Ved årets begynnelse pleide moabittiske herjeflokker å falle inn i landet. 
12:13:21: Så hendte det mens de var i ferd med å begrave en mann, at de fikk se en herjeflokk; da kastet de mannen ned i Elisas grav, og med det samme mannen rørte ved Elisas ben, blev han levende igjen og reiste sig op på sine føtter. 
12:13:22: Kongen i Syria Hasael hadde trengt Israel så lenge Joakas levde. 
12:13:23: Men Herren var dem nådig og forbarmet sig over dem og vendte sig til dem for den pakts skyld som han hadde gjort med Abraham, Isak og Jakob; han vilde ikke ødelegge dem, og han kastet dem ennu ikke bort fra sitt åsyn. 
12:13:24: Men da kongen i Syria Hasael var død, og hans sønn Benhadad var blitt konge i hans sted, 
12:13:25: tok Joas, Joakas' sønn, de byer som Hasael hadde tatt fra hans far Joakas i krigen, tilbake fra Benhadad, Hasaels sønn. Tre ganger slo Joas ham og tok tilbake de byer som hadde hørt Israel til. 
12:14:1: I Israels konge Joas', Joakas' sønns annet år blev Amasja konge; han var sønn av Judas konge Joas. 
12:14:2: Han var fem og tyve år gammel da han blev konge, og regjerte ni og tyve år i Jerusalem; hans mor hette Joaddan og var fra Jerusalem. 
12:14:3: Han gjorde hvad rett var i Herrens øine, men ikke som hans stamfar David; han gjorde aldeles som sin far Joas. 
12:14:4: Dog blev offerhaugene ikke nedlagt; folket blev ved å ofre og brenne røkelse på haugene. 
12:14:5: Så snart han hadde fått kongedømmet fast i sin hånd, slo han ihjel dem av sine tjenere som hadde slått ihjel hans far kongen. 
12:14:6: Men mordernes barn drepte han ikke, efter det som er skrevet i Mose lovbok, hvor Herren har gitt dette bud: Foreldre skal ikke lide døden for sine barns skyld, og barn ikke lide døden for sine foreldres skyld; men enhver skal dø for sin egen synd. 
12:14:7: Han slo Edom i Saltdalen, ti tusen mann, og inntok Sela og kalte det Jokte'el, som det heter den dag idag. 
12:14:8: På den tid sendte Amasja bud til Israels konge Joas, sønn av Joakas, Jehus sønn, og lot si: Kom, la oss prøve styrke med hverandre! 
12:14:9: Men Israels konge Joas sendte bud til Judas konge Amasja og svarte: Tornebusken på Libanon sendte bud til sederen på Libanon og lot si: La min sønn få din datter til hustru! Men de ville dyr på Libanon fór frem og trådte tornebusken ned. 
12:14:10: Du har slått Edom, og derfor er du blitt overmodig; la dig nøie med den ære og bli hjemme! Hvorfor vil du kalle ulykken ned over dig, så du går til grunne, både du og Juda med dig? 
12:14:11: Men Amasja hørte ikke på ham. Da drog Israels konge Joas op, og han og Judas konge Amasja prøvde styrke med hverandre ved Bet-Semes, som hører til Juda. 
12:14:12: Og Judas menn blev slått av Israels menn, og de flyktet hver til sitt hjem; 
12:14:13: men Judas konge Amasja, sønn av Joas, Akasjas sønn, blev tatt til fange av Israels konge Joas ved Bet-Semes. Og da de kom til Jerusalem, rev han ned et stykke av Jerusalems mur, fra Efra'im-porten til Hjørneporten, fire hundre alen. 
12:14:14: Og han tok alt det gull og sølv og alle de kar som fantes i Herrens hus og i skattkammerne i kongens hus, og dessuten gislene; sa vendte han tilbake til Samaria. 
12:14:15: Hvad som ellers er å fortelle om Joas, om det han gjorde, og om hans store gjerninger og hans krig med Judas konge Amasja, det er opskrevet i Israels kongers krønike. 
12:14:16: Og Joas la sig til hvile hos sine fedre og blev begravet i Samaria hos Israels konger, og hans sønn Jeroboam blev konge i hans sted. 
12:14:17: Judas konge Amasja, Joas' sønn, levde femten år efterat Israels konge Joas, Joakas' sønn, var død. 
12:14:18: Hvad som ellers er å fortelle om Amasja, det er opskrevet i Judas kongers krønike. 
12:14:19: De gjorde en sammensvergelse mot ham i Jerusalem, og han flyktet til Lakis; men de sendte folk efter ham til Lakis og drepte ham der. 
12:14:20: Så kjørte de ham derfra med hester*, og han blev begravet i Jerusalem hos sine fedre i Davids stad. # <* nemlig til Jerusalem.> 
12:14:21: Og alt Judas folk tok og gjorde Asarja*, som da var seksten år gammel, til konge i hans far Amasjas sted. # <* d.s.s. Ussias; 2KG 15, 13. 30.> 
12:14:22: Han gjorde Elat til en fast by og vant det tilbake for Juda, efterat kongen hadde lagt sig til hvile hos sine fedre. 
12:14:23: I Judas konge Amasjas, Joas' sønns femtende år blev Jeroboam, sønn av Israels konge Joas, konge i Samaria, og han regjerte i en og firti år. 
12:14:24: Han gjorde hvad ondt var i Herrens øine; han vek ikke fra nogen av de synder som Jeroboam, Nebats sønn, hadde gjort og fått Israel til å gjøre. 
12:14:25: Han vant tilbake Israels landemerker fra der hvor veien går til Hamat, og like til Ødemarks-havet, efter det ord som Herren, Israels Gud, hadde talt ved sin tjener profeten Jonas, Amittais sønn, fra Gat-Hefer. 
12:14:26: For Herren hadde sett at Israel var stedt i bitter nød, og det var ute både med store og små, og Israel hadde ingen hjelper. 
12:14:27: Og Herren hadde ikke sagt at han vilde utslette Israels navn under himmelen; derfor frelste han dem ved Jeroboam, Joas' sønn. 
12:14:28: Hvad som ellers er å fortelle om Jeroboam, om alt det han gjorde, og om hans store gjerninger, hvilke kriger han førte, og hvorledes han vant tilbake til Israel den del av Damaskus og Hamat som før hadde tilhørt Juda, det er opskrevet i Israels kongers krønike. 
12:14:29: Og Jeroboam la sig til hvile hos sine fedre, hos Israels konger, og hans sønn Sakarja blev konge i hans sted. 
12:15:1: I Israels konge Jeroboams syv og tyvende år blev Asarja, sønn av Judas konge Amasja, konge. 
12:15:2: Han var seksten år gammel da han blev konge, og regjerte to og femti år i Jerusalem; hans mor hette Jekolja og var fra Jerusalem. 
12:15:3: Han gjorde hvad rett var i Herrens øine, aldeles som hans far Amasja hadde gjort. 
12:15:4: Dog blev offerhaugene ikke nedlagt; folket blev ved å ofre og brenne røkelse på haugene. 
12:15:5: Og Herren slo kongen, så han blev spedalsk til sin dødsdag, og han bodde i et hus for sig selv; og Jotam, kongens sønn, forestod kongens hus og dømte landets folk. 
12:15:6: Hvad som ellers er å fortelle om Asarja og om alt det han gjorde, det er opskrevet i Judas kongers krønike. 
12:15:7: Og Asarja la sig til hvile hos sine fedre, og de begravde ham hos hans fedre i Davids stad, og hans sønn Jotam blev konge i hans sted. 
12:15:8: I Judas konge Asarjas åtte og trettiende år blev Sakarja, Jeroboams sønn, konge over Israel i Samaria, og han regjerte i seks måneder. 
12:15:9: Han gjorde hvad ondt var i Herrens øine, likesom hans fedre hadde gjort; han vek ikke fra Jeroboams, Nebats sønns synder, som han hadde fått Israel til å gjøre. 
12:15:10: Og Sallum, sønn av Jabes, fikk i stand en sammensvergelse mot ham og slo ham ihjel i folkets påsyn og blev konge i hans sted. 
12:15:11: Hvad som ellers er å fortelle om Sakarja, det er opskrevet i Israels kongers krønike. 
12:15:12: Således gikk det ord i opfyllelse som Herren hadde talt til Jehu; han hadde sagt: Dine sønner skal sitte på Israels trone til fjerde ledd. Og så blev det. 
12:15:13: Sallum, sønn av Jabes, blev konge i Judas konge Ussias'* ni og trettiende år og regjerte en måneds tid i Samaria. # <* 2KG 14, 21.> 
12:15:14: Da drog Menahem, Gadis sønn, op fra Tirsa og kom til Samaria og slo Sallum, Jabes' sønn, ihjel i Samaria og blev konge i hans sted. 
12:15:15: Hvad som ellers er å fortelle om Sallum og om den sammensvergelse han fikk i stand, det er opskrevet i Israels kongers krønike. 
12:15:16: På den tid drog Menahem ut fra Tirsa og herjet Tifsah med alt der var, og hele det tilhørende land. Det var fordi byen ikke hadde åpnet portene for ham, han herjet den; alle dets fruktsommelige kvinner lot han opskjære. 
12:15:17: I Judas konge Asarjas ni og trettiende år blev Menahem, Gadis sønn, konge over Israel i Samaria, og han regjerte i ti år. 
12:15:18: Han gjorde hvad ondt var i Herrens øine; han vek ikke, så lenge han levde, fra Jeroboams, Nebats sønns synder, som han hadde fått Israel til å gjøre. 
12:15:19: Kongen i Assyria Ful falt inn i landet; da gav Menahem Ful tusen talenter sølv forat han skulde holde med ham og trygge kongedømmet i hans hånd. 
12:15:20: Og Menahem fikk de penger han skulde gi til kongen i Assyria, inn ved å legge skatt på Israel, på alle rikmenn, femti sekel på hver. Så vendte kongen i Assyria tilbake og blev ikke lenger i landet. 
12:15:21: Hvad som ellers er å fortelle om Menahem og om alt det han gjorde, det er opskrevet i Israels kongers krønike. 
12:15:22: Og Menahem la sig til hvile hos sine fedre, og hans sønn Pekahja blev konge i hans sted. 
12:15:23: I Judas konge Asarjas femtiende år blev Pekahja, Menahems sønn, konge over Israel i Samaria, og han regjerte i to år. 
12:15:24: Han gjorde hvad ondt var i Herrens øine; han vek ikke fra Jeroboams, Nebats sønns synder, som han hadde fått Israel til å gjøre. 
12:15:25: Og Pekah, sønn av hans høvedsmann Remalja, fikk i stand en sammensvergelse mot ham og slo ham ihjel i Samaria i borgen i kongens hus og tillike Argob og Arje; han hadde med sig femti mann av gileadittene; og da han hadde drept ham, blev han konge i hans sted. 
12:15:26: Hvad som ellers er å fortelle om Pekahja og om alt det han gjorde, det er opskrevet i Israels kongers krønike. 
12:15:27: I Judas konge Asarjas to og femtiende år blev Pekah, Remaljas sønn, konge over Israel i Samaria, og han regjerte i tyve år. 
12:15:28: Han gjorde hvad ondt var i Herrens øine; han vek ikke fra Jeroboams, Nebats sønns synder, som han hadde fått Israel til å gjøre. 
12:15:29: I Israels konge Pekahs dager kom kongen i Assyria Tiglat-Pileser og tok Ijon og Abel-Bet-Ma'aka og Janoah og Kedes og Hasor og Gilead og Galilea, hele Naftalis land; og han bortførte innbyggerne til Assyria. 
12:15:30: Og Hosea, Elas sønn, fikk i stand en sammensvergelse mot Pekah, Remaljas sønn, og slo ham ihjel og blev konge i hans sted i Jotams, Ussias' sønns tyvende år*. # <* d.e. i det tyvende år efterat Jotam blev konge, altså i hans eftermann Akas' fjerde regjeringsår, se 2KG 15, 33; 16, 1 fg.> 
12:15:31: Hvad som ellers er å fortelle om Pekah og om alt det han gjorde, det er opskrevet i Israels kongers krønike. 
12:15:32: I Israels konge Pekahs, Remaljas sønns annet år blev Jotam, sønn av Judas konge Ussias, konge. 
12:15:33: Han var fem og tyve år gammel da han blev konge, og regjerte seksten år i Jerusalem; hans mor hette Jerusa og var datter av Sadok. 
12:15:34: Han gjorde hvad rett var i Herrens øine; han gjorde aldeles som sin far Ussias. 
12:15:35: Dog blev offerhaugene ikke nedlagt; folket blev ved å ofre og brenne røkelse på haugene. Det var han som bygget den øvre port i Herrens hus. 
12:15:36: Hvad som ellers er å fortelle om Jotam og om alt det han gjorde, det er opskrevet i Judas kongers krønike. 
12:15:37: I de dager begynte Herren å sende kongen i Syria Resin og Pekah, Remaljas sønn, mot Juda. 
12:15:38: Og Jotam la sig til hvile hos sine fedre og blev begravet hos sine fedre i sin far Davids stad, og hans sønn Akas blev konge i hans sted. 
12:16:1: I Pekahs, Remaljas sønns syttende år blev Akas, sønn av Judas konge Jotam, konge. 
12:16:2: Akas var tyve år gammel da han blev konge, og han regjerte seksten år i Jerusalem. Han gjorde ikke hvad rett var i Herrens, hans Guds øine som hans far David, 
12:16:3: men vandret på Israels kongers vei, ja, han lot endog sin sønn gå gjennem ilden efter de vederstyggelige skikker hos de folk som Herren hadde drevet bort for Israels barn, 
12:16:4: og han ofret og brente røkelse på haugene og bakkene og under hvert grønt tre. 
12:16:5: På den tid drog kongen i Syria Resin og Israels konge Pekah, Remaljas sønn, op for å angripe Jerusalem; og de kringsatte Akas, men de kunde ikke komme til å gjøre noget angrep* # <* 2KG 16, 7.> 
12:16:6: På den tid la kongen i Syria Resin Elat til Syria; han drev jødene bort fra Elat, og syrerne kom til Elat og bosatte sig der og bor der den dag idag. 
12:16:7: Da sendte Akas bud til kongen i Assyria Tiglat-Pileser og lot si: Jeg er din tjener og din sønn; kom og frels mig fra Syrias konge og Israels konge, som har overfalt mig! 
12:16:8: Og Akas tok det sølv og gull som fantes i Herrens hus og i skattkammerne i kongens hus, og sendte det som gave til kongen i Assyria. 
12:16:9: Og kongen i Assyria gjorde som han bad; han drog op og inntok Damaskus og bortførte innbyggerne til Kir og drepte Resin. 
12:16:10: Da drog kong Akas til Damaskus for å møte kongen i Assyria Tiglat-Pileser, og da han så alteret i Damaskus, sendte han en tegning av alteret og et billede av det til presten Uria, aldeles som det var gjort. 
12:16:11: Og presten Uria bygget alteret; aldeles efter det billede som kong Akas hadde sendt fra Damaskus, gjorde presten Uria alteret, innen kong Akas kom tilbake fra Damaskus. 
12:16:12: Da kongen kom tilbake fra Damaskus og så alteret, gikk han frem til det og ofret på det. 
12:16:13: Og han brente sitt brennoffer og sitt matoffer og helte ut sitt drikkoffer, og han sprengte blodet av sitt takkoffer på alteret. 
12:16:14: Men kobberalteret, som stod for Herrens åsyn, flyttet han bort fra huset, fra dets sted mellem det nye alter og Herrens hus, og satte det på nordsiden av det nye alter. 
12:16:15: Og kong Akas bød presten Uria: På det store alter skal du brenne morgen-brennofferet og aften-matofferet og kongens brennoffer og hans matoffer og brennofferet for alt folket i landet og deres matoffer og deres drikkoffer, og alt blodet av brennofferne og av slaktofferne skal du sprenge på det; men kobberalteret vil jeg ha til å holde bønn ved. 
12:16:16: Og presten Uria gjorde aldeles som kong Akas hadde befalt. 
12:16:17: Kong Akas skar også fyllingene ut av fotstykkene og tok karene ned av dem, og han tok havet ned av kobberoksene som stod under det, og satte det på et underlag av sten. 
12:16:18: Og den dekkede sabbatsgang som de hadde bygget ved huset, og kongens ytre inngang la han inn i Herrens hus for den assyriske konges skyld. 
12:16:19: Hvad som ellers er å fortelle om Akas, om det han gjorde, det er opskrevet i Judas kongers krønike. 
12:16:20: Og Akas la sig til hvile hos sine fedre og blev begravet hos sine fedre i Davids stad, og hans sønn Esekias blev konge i hans sted. 
12:17:1: I Judas konge Akas' tolvte år blev Hosea, sønn av Ela, konge over Israel i Samaria, og han regjerte i ni år. 
12:17:2: Han gjorde hvad ondt var i Herrens øine, dog ikke som de konger i Israel som hadde vært før ham. 
12:17:3: Ham drog kongen i Assyria Salmanassar op imot, og Hosea blev hans tjener og måtte svare skatt til ham. 
12:17:4: Men da kongen i Assyria kom efter at Hosea hadde innlatt sig i en sammensvergelse, idet han hadde sendt bud til kongen i Egypten Soa og ikke lenger betalt skatt til kongen i Assyria, således som han før hadde gjort år for år, så satte kongen i Assyria ham fast og holdt ham i fengsel. 
12:17:5: For kongen i Assyria drog op over hele landet, og han rykket frem mot Samaria og holdt det kringsatt i tre år. 
12:17:6: I Hoseas niende år inntok den assyriske konge Samaria og bortførte Israel til Assyria; og han lot dem bo i Halah og ved Habor, en elv i Gosan, og i Medias byer. 
12:17:7: Således gikk det fordi Israels barn syndet mot Herren sin Gud, som hadde ført dem op fra Egyptens land og utfridd dem av den egyptiske konge Faraos hånd, og fryktet andre guder 
12:17:8: og vandret efter de hedningefolks skikker som Herren hadde drevet bort for Israels barn, og efter de skikker som Israels konger hadde innført. 
12:17:9: Og Israels barn tilhyllet Herren sin Gud med ord som ikke var rette*, og de bygget sig offerhauger på alle sine bosteder like fra vakttårnene** til de faste byer, # <* d.e. med falske forklaringer av Herrens ord tilhyllet og skjulte de hans sanne vesen.> # <** 2KR 26, 10.> 
12:17:10: og de reiste støtter og Astarte-billeder på hver høi bakke og under hvert grønt tre, 
12:17:11: og de brente røkelse der på alle haugene likesom de hedningefolk Herren hadde drevet bort for dem, og de gjorde onde ting, så de vakte Herrens harme, 
12:17:12: og de dyrket de motbydelige avguder om hvem Herren hadde sagt til dem: Det skal I ikke gjøre. 
12:17:13: Og Herren vidnet for Israel og for Juda ved alle sine profeter, alle seerne, og sa: Vend om fra eders onde veier og hold mine befalinger og bud efter hele den lov som jeg gav eders fedre, og som jeg sendte til eder ved mine tjenere profetene! 
12:17:14: Men de vilde ikke høre og var hårdnakkede som sine fedre, som ikke trodde på Herren sin Gud, 
12:17:15: og de forkastet hans bud og den pakt som han hadde gjort med deres fedre, og de vidnesbyrd som han hadde gitt dem, og fulgte de tomme avguder og blev dårlige og efterlignet de hedningefolk som bodde rundt omkring dem, om hvilke Herren hadde budt dem at de ikke skulde gjøre som de, 
12:17:16: og de forlot alle Herrens, sin Guds bud og gjorde sig støpte billeder, to kalver, og de gjorde et Astarte-billede og tilbad hele himmelens hær og dyrket Ba'al, 
12:17:17: og de lot sine sønner og døtre gå gjennem ilden og gav sig av med spådomskunster og varseltydning og solgte sig selv til å gjøre hvad ondt var i Herrens øine, så de vakte hans harme. 
12:17:18: Da blev Herren såre vred på Israel og støtte dem bort fra sitt åsyn; det blev ingen tilbake uten Juda stamme alene. 
12:17:19: Heller ikke Juda holdt Herrens, sin Guds bud, men de vandret i de skikker som Israel hadde innført. 
12:17:20: Derfor forkastet Herren hele Israels ætt og plaget dem og gav dem i røveres hånd, inntil han kastet dem bort fra sitt åsyn. 
12:17:21: For han hadde revet Israel løs fra Davids hus, og de gjorde Jeroboam, Nebats sønn, til konge; og Jeroboam drog Israel bort fra Herren og forførte dem til stor synd. 
12:17:22: Og Israels barn vandret i alle de synder som Jeroboam hadde gjort; de vek ikke fra dem, 
12:17:23: inntil Herren støtte Israel bort fra sitt åsyn, således som han hadde talt ved alle sine tjenere profetene. Så blev Israel bortført fra sitt land til Assyria, hvor de har vært til denne dag. 
12:17:24: Og kongen i Assyria lot det komme folk fra Babel og Kuta og Avva og Hamat og Sefarva'im og lot dem bo i Samarias byer istedenfor Israels barn; og de tok Samaria i eie og bosatte sig i byene der. 
12:17:25: Men da de i begynnelsen av sitt ophold. der ikke fryktet Herren, sendte Herren løver iblandt dem, og de herjet mellem dem. 
12:17:26: Da sa folk til kongen i Assyria: De folk som du førte bort og lot bo i Samarias byer, vet ikke hvorledes landets gud skal dyrkes; og han har sendt løver iblandt dem, og de dreper dem, fordi de ikke vet hvorledes landets gud skal dyrkes. 
12:17:27: Da bød kongen i Assyria: La en av de prester som I har ført bort derfra, fare dit! De* skal dra avsted og bosette sig der, så han kan lære dem hvorledes landets gud skal dyrkes. # <* d.e. han og hans ledsagere.> 
12:17:28: Så kom da en av de prester som de hadde ført bort fra Samaria, og bosatte sig i Betel; og han lærte dem hvorledes de skulde frykte Herren. 
12:17:29: Hvert folk gjorde sig sine gudebilleder og satte dem i husene på de hauger som Samarias innbyggere hadde gjort - hvert folk i de byer hvor de bodde. 
12:17:30: Mennene fra Babel gjorde sig et billede av Sukkot-Benot, og mennene fra Kut et billede av Nergal, og mennene fra Hamat et billede av Asima, 
12:17:31: Og avvittene et billede av Nibhas og av Tartak, og sefarvittene brente sine sønner i ilden for Adrammelek og Anammelek, Sefarva'ims guder. 
12:17:32: Men de fryktet også Herren og gjorde hvem de vilde av sine egne til prester for haugene, og de ofret for dem i husene på haugene. 
12:17:33: De fryktet Herren, men dyrket også sine egne guder på samme vis som de hedningefolk de var bortført fra. 
12:17:34: Ennu den dag idag følger de sine forrige skikker; de frykter ikke Herren* og gjør ikke efter de forskrifter og regler de har fått, eller efter den lov og de bud Herren har gitt efterkommerne av Jakob, han som han gav navnet Israel**, # <* i sannhet.> # <** 1MO 32, 28.> 
12:17:35: og Herren gjorde en pakt med dem og bød dem: I skal ikke frykte andre guder og ikke tilbede dem og ikke dyrke dem og ikke ofre til dem, 
12:17:36: men Herren, som førte eder op fra Egyptens land med stor kraft og med utrakt arm, ham skal I frykte, og ham skal I tilbede, og til ham skal I ofre, 
12:17:37: og de forskrifter og regler og den lov og de bud han lot skrive for eder, skal I gi akt på å holde alle dager, og I skal ikke frykte andre guder; 
12:17:38: og den pakt jeg gjorde med eder, skal I ikke glemme, og I skal ikke frykte andre guder, 
12:17:39: men Herren eders Gud skal I frykte, og han skal fri eder av alle eders fienders hånd. 
12:17:40: Men de vilde ikke høre, de bar sig at på samme vis som før. 
12:17:41: Så fryktet da disse hedningefolk Herren, men dyrket på samme tid sine utskårne billeder; også deres barn og deres barnebarn har helt til denne dag gjort som deres fedre gjorde. 
12:18:1: I Israels konge Hoseas, Elas sønns tredje år blev Esekias, sønn av Judas konge Akas, konge. 
12:18:2: Han var fem og tyve år gammel da han blev konge, og han regjerte ni og tyve år i Jerusalem; hans mor hette Abi og var datter av Sakarja. 
12:18:3: Han gjorde hvad rett var i Herrens øine, aldeles som hans far David hadde gjort. 
12:18:4: Han nedla offerhaugene og sønderslo billedstøttene og hugg Astarte-billedet i stykker og knuste den kobberslange Moses hadde gjort; for like til den tid hadde Israels barn brent røkelse for den; de kalte den Nehustan. 
12:18:5: Til Herren, Israels Gud, satte han sin lit, og efter ham har det ikke vært hans like blandt alle Judas konger, heller ikke blandt dem som hadde vært før ham. 
12:18:6: Han holdt fast ved Herren, han vek ikke fra ham, og han holdt hans bud, dem som Herren hadde gitt Moses. 
12:18:7: Og Herren var med ham; i alt han tok sig fore, bar han sig viselig at. Han falt fra kongen i Assyria og vilde ikke tjene ham lenger. 
12:18:8: Han slo filistrene og tok deres land like til Gasa og de omliggende bygder like fra vakttårnene til de faste byer. 
12:18:9: I kong Esekias' fjerde år, som var Israels konge Hoseas, Elas sønns syvende år, drog Salmanassar, kongen i Assyria, op imot Samaria og kringsatte det. 
12:18:10: Da tre år var gått, inntok de det; det var i Esekias' sjette år, som var Israels konge Hoseas niende år, Samaria blev inntatt. 
12:18:11: Og kongen i Assyria bortførte Israel til Assyria og flyttet dem til Halah og til Habor, en elv i Gosan, og til Medias byer, 
12:18:12: fordi de ikke hadde hørt på Herrens, sin Guds røst, men overtrådt hans pakt - alt det Moses, Herrens tjener, hadde befalt; de hverken hørte eller gjorde efter det. 
12:18:13: I kong Esekias' fjortende år drog kongen i Assyria Sankerib op imot alle Judas faste byer og inntok dem. 
12:18:14: Da sendte Judas konge Esekias bud til den assyriske konge i Lakis og lot si: Jeg har syndet; vend tilbake fra mig! Hvad du legger på mig, vil jeg bære. Da påla kongen i Assyria Judas konge Esekias å betale tre hundre talenter sølv og tretti talenter gull. 
12:18:15: Og Esekias gav alt det sølv som fantes i Herrens hus og i skattkammerne i kongens hus. 
12:18:16: Ved den leilighet lot Esekias gullet bryte av dørene på Herrens tempel og av dørstolpene som Esekias, Judas konge, hadde klædd med gull, og gav det til kongen i Assyria. 
12:18:17: Men kongen i Assyria sendte Tartan og Rabsaris og Rabsake med en stor hær fra Lakis til kong Esekias i Jerusalem; og de drog op og kom til Jerusalem, og da de var kommet dit, stanset de ved vannledningen fra den øvre dam, ved alfarveien til Vaskervollen*. # <* JES 7, 3.> 
12:18:18: Og de ropte på kongen; da gikk slottshøvdingen Eljakim, Hilkias' sønn, ut til dem sammen med statsskriveren Sebna og historieskriveren Joah, Asafs sønn. 
12:18:19: Og Rabsake sa til dem: Si til Esekias: Så sier den store konge, kongen i Assyria: Hvad er det for en trygghet som er kommet over dig? 
12:18:20: Du sier - men det er bare tomme ord - : Her er innsikt og styrke nok til krig. Til hvem setter du da din lit, siden du har gjort oprør mot mig? 
12:18:21: Nuvel, du setter din lit til Egypten, denne knekkede rørstav, som når en støtter sig på den, går inn i hans hånd og gjennemborer den; således er kongen i Egypten Farao for alle dem som setter sin lit til ham. 
12:18:22: Men sier I til mig: Vi setter vår lit til Herren vår Gud - er det da ikke hans offerhauger og altere Esekias har tatt bort, da han sa til Juda og Jerusalem: For dette alter skal I tilbede, her i Jerusalem? 
12:18:23: Men gjør nu et veddemål med min herre, kongen i Assyria: Jeg vil gi dig to tusen hester om du kan få dig folk til å ride på dem. 
12:18:24: Hvorledes skulde du da kunne drive tilbake en eneste stattholder, en av min herres ringeste tjenere? Derfor setter du din lit til Egypten og håper å få vogner og hestfolk derfra. 
12:18:25: Mener du da at jeg har draget op mot dette sted for å ødelegge det, uten at Herren har villet det? Nei, Herren har selv sagt til mig: Dra op mot dette land og ødelegg det! 
12:18:26: Da sa Eljakim, Hilkias' sønn, og Sebna og Joah til Rabsake: Tal til dine tjenere på syrisk, for vi forstår det sprog, og tal ikke til oss på jødisk, så folket som står på muren, hører det! 
12:18:27: Men Rabsake svarte: Er det til din herre og til dig min herre har sendt mig for å tale disse ord? Er det ikke til de menn som sitter på muren og må ete sitt eget skarn og drikke sitt eget vann likesom I selv? 
12:18:28: Og Rabsake trådte frem og ropte med høi røst på jødisk og talte således: Hør den store konges, den assyriske konges ord! 
12:18:29: Så sier kongen: La ikke Esekias få narret eder! For han makter ikke å redde eder av hans hånd. 
12:18:30: Og la ikke Esekias få eder til å sette eders lit til Herren, idet han sier: Herren vil redde oss, og denne by skal ikke gis i kongen av Assyrias hånd. 
12:18:31: Hør ikke på Esekias! For så sier kongen i Assyria: Gjør fred med mig og kom ut til mig, så skal I få ete hver av sitt vintre og av sitt fikentre og drikke hver av vannet i sin brønn, 
12:18:32: til jeg kommer og henter eder til et land som ligner eders land, et land med korn og most, et land med brød og vingårder, et land med oljetrær og honning, så I kan leve og ikke skal dø! Hør ikke på Esekias når han vil forføre eder og si: Herren vil redde oss! 
12:18:33: Har vel nogen av folkenes guder reddet sitt land av kongen i Assyrias hånd? 
12:18:34: Hvor er Hamats og Arpads guder? Hvor er Sefarva'ims, Henas og Ivvas guder? Har vel Samarias guder reddet det av min hånd? 
12:18:35: Hvem er det blandt alle landenes guder som har reddet sitt land av min hånd, så Herren skulde redde Jerusalem av min hånd? 
12:18:36: Men folket tidde og svarte ham ikke et ord; for kongens bud lød så: I skal ikke svare ham. 
12:18:37: Så kom slottshøvdingen Eljakim, Hilkias' sønn, og statsskriveren Sebna og historieskriveren Joah, Asafs sønn, til Esekias med sønderrevne klær og meldte ham hvad Rabsake hadde sagt. 
12:19:1: Da kong Esekias hørte det, sønderrev han sine klær og klædde sig i sekk og gikk inn i Herrens hus. 
12:19:2: Og han sendte slottshøvdingen Eljakim og statsskriveren Sebna og de eldste blandt prestene, klædd i sekk, til profeten Esaias, Amos' sønn. 
12:19:3: Og de sa til ham: Så sier Esekias: En nødens og tuktelsens og vanærens dag er denne dag; barnet er kommet til modermunnen, men det er ingen kraft til å føde. 
12:19:4: Kanskje Herren din Gud vil høre alt det Rabsake har sagt, han som hans herre, kongen i Assyria, har sendt for å håne den levende Gud, så at han straffer ham for de ord Herren din Gud har hørt. Opsend da en bønn for den levning som ennu er iglen! 
12:19:5: Da kong Esekias' tjenere kom til Esaias, 
12:19:6: sa Esaias til dem: Så skal I si til eders herre: Så sier Herren: Frykt ikke for de ord du hørte, de som assyrerkongens tjenere hånte mig med! 
12:19:7: Se, jeg vil inngi ham et sådant sinn at han for et rykte* han får høre, vender tilbake til sitt land; og jeg vil la ham falle for sverdet i sitt land**. # <* 2KG 19, 9.> # <** 2KG 19, 37.> 
12:19:8: Da Rabsake vendte tilbake, fant han assyrerkongen i ferd med å stride mot Libna; for han hadde hørt at han hadde brutt op fra Lakis. 
12:19:9: Da hørte kongen si om Tirhaka, kongen i Etiopia, at han hadde draget ut for å stride mot ham. Så sendte han atter bud til Esekias og sa: 
12:19:10: Så skal I si til Judas konge Esekias: La ikke din Gud, som du setter din lit til, få narret dig, så du tenker som så: Jerusalem skal ikke gis i assyrerkongens hånd. 
12:19:11: Du har hørt hvad kongene i Assyria har gjort med alle landene, at de har ødelagt dem, og du skulde bli reddet? 
12:19:12: Har vel folkenes guder frelst dem som mine fedre ødela, Gosan og Karan og Resef og Edens barn i Telassar? 
12:19:13: Hvor er nu Hamats konge og Arpads konge og byen Sefarva'ims konge, Henas og Ivvas konger? 
12:19:14: Da Esekias hadde mottatt brevet av sendebudene og lest det, gikk han op til Herrens hus, og der bredte Esekias det ut for Herrens åsyn. 
12:19:15: Og Esekias bad for Herrens åsyn og sa: Herre, Israels Gud, du som troner på kjerubene! Du alene er Gud for alle jordens riker, du har gjort himmelen og jorden. 
12:19:16: Herre, bøi ditt øre til og hør! Herre, oplat dine øine og se! Hør de ord som Sankerib har sendt for å håne den levende Gud! 
12:19:17: Det er sant, Herre, at kongene i Assyria har ødelagt folkene og deres land, 
12:19:18: og de har kastet deres guder i ilden; for de er ikke guder, men et verk av menneskehender, tre og sten, og derfor kunde de gjøre dem til intet. 
12:19:19: Men frels oss nu. Herre vår Gud, av hans hånd, så alle jordens riker må kjenne at du, Herre, alene er Gud! 
12:19:20: Da sendte Esaias, Amos' sønn, bud til Esekias og lot si! Så sier Herren, Israels Gud: Hvad du har bedt mig om vedkommende kongen i Assyria Sankerib, har jeg hørt. 
12:19:21: Således lyder det ord Herren har talt om ham: Jomfruen, Sions datter, forakter dig, spotter dig; Jerusalems datter ryster på hodet efter dig. 
12:19:22: Hvem har du hånet og spottet, og mot hvem har du løftet din røst? Du har løftet dine øine til det høie, mot Israels Hellige. 
12:19:23: Ved dine sendebud har du hånet Herren og sagt: Med mine tall-løse vogner drog jeg op på fjellenes tinder, til Libanons øverste topper, og jeg hugger ned dets høieste sedrer, dets herlige cypresser, og jeg trenger frem til dets øverste hvilested, dets frodige skog; 
12:19:24: jeg gravde brønner og drakk av fremmede vann, og jeg tørker ut alle Egyptens strømmer med mine fotsåler. 
12:19:25: * Har du da ikke hørt det? Fra fjerne tider har jeg gjort det, fra fordums dager har jeg laget det så. Nu har jeg latt det komme, så du har fått makt til å ødelegge faste byer og gjøre dem til øde grushauger. # <* Herrens ord til Sankerib.> 
12:19:26: Og deres innbyggere blev maktløse; de blev forferdet og skamfulle; de blev som gresset på marken og som de grønne urter, som gresset på takene og som korn som er ødelagt av brand før det er fullvokset. 
12:19:27: Enten du sitter eller du går ut og inn, så vet jeg det, og jeg vet at du raser mot mig. 
12:19:28: Fordi du raser mot mig, og din overmodige trygghet har nådd op til mine ører, så vil jeg legge min ring i din nese og mitt bissel mellem dine leber og føre dig tilbake den vei du kom. 
12:19:29: Og dette skal du* ha til tegn: Iår skal I ete det korn som vokser av sig selv, og næste år det som skyter op fra roten; men i det tredje år skal I så og høste og plante vingårder og ete deres frukt. # <* Esekias.> 
12:19:30: Og den rest som har sloppet unda av Judas hus, skal skyte dypere rot nedentil og bære frukt oventil. 
12:19:31: For fra Jerusalem skal utgå en levning og fra Sions berg en rest som har sloppet unda; Herrens nidkjærhet skal gjøre dette. 
12:19:32: Derfor sier Herren så om kongen i Assyria: Han skal ikke komme inn i denne by og ikke skyte en pil inn i den, og han skal ikke rykke frem mot den med skjold og ikke opkaste voll mot den. 
12:19:33: Den vei han kommer, skal han vende tilbake, og inn i denne by skal han ikke komme, sier Herren. 
12:19:34: Og jeg vil verne denne by og frelse den, for min skyld og for min tjener Davids skyld. 
12:19:35: Og samme natt skjedde det at Herrens engel gikk ut og slo hundre og fem og åtti tusen mann i assyrernes leir; og da folk stod op om morgenen, fikk de se dem alle ligge der som døde kropper. 
12:19:36: Da brøt kongen i Assyria Sankerib op og drog bort og vendte tilbake; og siden holdt han sig i ro i Ninive. 
12:19:37: Men da han engang tilbad i sin gud Nisroks hus, slo Adrammelek og Sareser* ham ihjel med sverd. De kom sig unda og flyktet til Ararats land, og hans sønn Asarhaddon blev konge i hans sted. # <* JES 37, 38.> 
12:20:1: Ved den tid blev Esekias dødssyk; da kom profeten Esaias, Amos' sønn, inn til ham og sa til ham: Så sier Herren: Beskikk ditt hus! For du skal dø og ikke leve lenger. 
12:20:2: Da vendte han sitt ansikt mot veggen og bad til Herren og sa: 
12:20:3: Akk, Herre! kom dog i hu at jeg har vandret for ditt åsyn i trofasthet og med helt hjerte og gjort hvad godt er i dine øine! Og Esekias gråt høit. 
12:20:4: Men Esaias var ennu ikke kommet ut av den indre by, da kom Herrens ord til ham, og det lød så: 
12:20:5: Vend tilbake og si til Esekias, mitt folks fyrste: Så sier Herren, din far Davids Gud: Jeg har hørt din bønn, jeg har sett dine tårer. Se, jeg vil helbrede dig; på den tredje dag skal du gå op til Herrens hus. 
12:20:6: Og jeg vil legge femten år til din alder, og jeg vil redde dig og denne by av assyrerkongens hånd, og jeg vil verne denne by for min skyld og for min tjener Davids skyld. 
12:20:7: Og Esaias sa: Hent en fikenkake! Så hentet de en fikenkake og la den på bylden, og han frisknet til. 
12:20:8: Og Esekias sa til Esaias: Hvad skal jeg ha til tegn på at Herren vil helbrede mig, så jeg den tredje dag kan gå op til Herrens hus? 
12:20:9: Esaias svarte: Dette skal du ha til tegn fra Herren på at Herren vil holde det han har lovt: Skal skyggen gå ti streker frem, eller skal den gå ti streker tilbake? 
12:20:10: Esekias sa: Det er en lett sak for skyggen å strekke sig ti streker frem; nei, skyggen skal gå ti streker tilbake. 
12:20:11: Da ropte profeten Esaias til Herren; og han lot skyggen gå tilbake de streker som den var gått ned på Akas' solskive - ti streker. 
12:20:12: På den tid sendte Berodak Baladan, Baladans sønn, kongen i Babel, brev og gaver til Esekias; for han hadde hørt at Esekias hadde vært syk. 
12:20:13: Da Esekias hadde hørt på dem, lot han dem få se hele sitt skattkammer, sølvet og gullet og krydderiene og den kostbare olje og hele sitt våbenhus og alt det som fantes i hans skattkammere; det var ikke den ting Esekias ikke lot dem få se i sitt hus og i hele sitt rike. 
12:20:14: Da kom profeten Esaias til kong Esekias og sa til ham: Hvad sa disse menn, og hvorfra kommer de til dig? Esekias svarte: De er kommet fra et land langt borte, fra Babel. 
12:20:15: Og han sa: Hvad fikk de se i ditt hus? Esekias svarte: Alt det som er i mitt hus, har de fått se; det var ikke den ting jeg ikke lot dem lå se i mine skattkammer. 
12:20:16: Da sa Esaias til Esekias: Hør Herrens ord! 
12:20:17: Se, de dager kommer da alt det som er i ditt hus, og alle de skatter dine fedre har samlet like til denne dag, skal føres bort til Babel; det skal ikke bli nogen ting tilbake, sier Herren. 
12:20:18: Og blandt dine sønner som skal utgå av dig, som du skal avle, skal det være nogen som blir tatt til hoffmenn i kongen av Babels palass. 
12:20:19: Da sa Esekias til Esaias: Det Herrens ord som du har talt, er godt. Så sa han: Er det ikke så, når det skal være fred og trygghet i mine dager? 
12:20:20: Hvad som ellers er å fortelle om Esekias og om alle hans store gjerninger, og hvorledes han gjorde dammen og vannledningen og førte vannet inn i byen, det er opskrevet i Judas kongers krønike. 
12:20:21: Og Esekias la sig til hvile hos sine fedre, og hans sønn Manasse blev konge i hans sted. 
12:21:1: Manasse var tolv år gammel da han blev konge, og han regjerte fem og femti år i Jerusalem; hans mor hette Hefsiba. 
12:21:2: Han gjorde hvad ondt var i Herrens øine, efter de vederstyggelige skikker hos de hedningefolk Herren hadde drevet bort for Israels barn. 
12:21:3: Han bygget op igjen de offerhauger som hans far Esekias hadde ødelagt, og reiste altere for Ba'al og gjorde et Astarte-billede, likesom Israels konge Akab hadde gjort, og han tilbad hele himmelens hær og dyrket den. 
12:21:4: Og han bygget altere i Herrens hus, enda Herren hadde sagt: I Jerusalem vil jeg la mitt navn bo. 
12:21:5: For hele himmelens hær bygget han altere i begge forgårdene til Herrens hus. 
12:21:6: Han lot sin sønn gå gjennem ilden og gav sig av med å spå av skyene og å tyde varsler og fikk sig dødningemanere og sannsigere; han gjorde meget som var ondt i Herrens øine, så han vakte hans harme. 
12:21:7: Og han satte det Astarte-billede han hadde gjort, i det hus hvorom Herren hadde sagt til David og hans sønn Salomo: I dette hus og i Jerusalem, som jeg har utvalgt blandt alle Israels stammer, vil jeg la mitt navn bo til evig tid, 
12:21:8: og jeg vil ikke mere la Israel vandre hjemløs bort fra det land jeg gav deres fedre, så sant de bare gir akt på å gjøre alt det jeg har pålagt dem, efter hele den lov min tjener Moses gav dem. 
12:21:9: Men de vilde ikke høre, og Manasse forførte dem, så de gjorde ennu mere ondt enn de hedningefolk som Herren hadde utryddet for Israels barn. 
12:21:10: Da talte Herren ved sine tjenere profetene og sa: 
12:21:11: Fordi Judas konge Manasse har gjort disse vederstyggelige ting - gjort det som er verre enn alt det amorittene som var før ham gjorde, og tilmed har forført Juda til synd med sine motbydelige avguder, 
12:21:12: derfor sier Herren, Israels Gud, så: Se, jeg fører ulykke over Jerusalem og Juda, så det skal ringe for begge ørene på hver den som hører om det. 
12:21:13: Jeg vil utstrekke samme målesnor over Jerusalem som over Samaria og bruke samme vektlodd som mot Akabs hus, og jeg vil tørke bort Jerusalem, likesom en tørker av et fat og når det er avtørket, snur op ned på det. 
12:21:14: Og jeg vil støte fra mig levningen av min arv og overgi dem i deres fienders hånd, og de skal bli til bytte og rov for alle sine fiender, 
12:21:15: fordi de har gjort hvad ondt er i mine øine, og egget mig til vrede fra den dag deres fedre gikk ut av Egypten og like til denne dag. 
12:21:16: Manasse utøste også uskyldig blod i så stor mengde at han fylte Jerusalem dermed fra ende til ende, foruten den synd at han forførte Juda til å synde og gjøre hvad ondt var i Herrens øine. 
12:21:17: Hvad som ellers er å fortelle om Manasse og om alt det han gjorde, og om den synd som han gjorde, det er opskrevet i Judas kongers krønike. 
12:21:18: Og Manasse la sig til hvile hos sine fedre og blev begravet i haven til sitt hus, i Ussas have, og hans sønn Amon blev konge i hans sted. 
12:21:19: Amon var to og tyve år gammel da han blev konge, og han regjerte to år i Jerusalem; hans mor hette Mesullemet; hun var datter av Harus og var fra Jotba. 
12:21:20: Han gjorde hvad ondt var i Herrens øine, likesom hans far Manasse hadde gjort. 
12:21:21: Han vandret i alle deler på den vei hans far hadde vandret, og dyrket de motbydelige avguder hans far hadde dyrket, og tilbad dem. 
12:21:22: Han forlot Herren, sine fedres Gud, og vandret ikke på Herrens vei. 
12:21:23: Og Amons tjenere sammensvor sig mot ham og drepte kongen i hans hus. 
12:21:24: Men landets folk slo ihjel alle dem som hadde sammensvoret sig mot kong Amon, og så gjorde de hans sønn Josias til konge i hans sted. 
12:21:25: Hvad som ellers er å fortelle om Amon, om det han gjorde, det er opskrevet i Judas kongers krønike. 
12:21:26: Han blev begravet i sin grav i Ussas have, og hans sønn Josias blev konge i hans sted. 
12:22:1: Josias var åtte år gammel da han blev konge, og han regjerte en og tretti år i Jerusalem; hans mor hette Jedida; hun var datter av Adaja og var fra Boskat. 
12:22:2: Han gjorde hvad rett var i Herrens øine, og vandret i alle deler på sin far Davids vei; han vek ikke av hverken til høire eller til venstre. 
12:22:3: I kong Josias' attende år hendte det at kongen sendte statsskriveren Safan, sønn av Asalja, Mesullams sønn, til Herrens hus og sa: 
12:22:4: Gå op til ypperstepresten Hilkias og be ham telle sammen alle de penger som er kommet inn til Herrens hus, og som vokterne ved dørtreskelen har samlet inn hos folket, 
12:22:5: og overgi dem til dem som forestår arbeidet og har tilsyn med Herrens hus, forat de igjen kan gi dem til dem som utfører arbeidet - dem som arbeider i Herrens hus for å bøte husets brøst, 
12:22:6: til tømmermennene og bygningsmennene og murerne, og likeså til innkjøp av tre og hugne stener for å sette huset i stand. 
12:22:7: Men det skal ikke holdes regnskap med dem for de penger som overgis til dem; for de farer redelig frem. 
12:22:8: Da sa ypperstepresten Hilkias til statsskriveren Safan: Jeg har funnet lovboken i Herrens hus. Og Hilkias gav boken til Safan, og han leste den. 
12:22:9: Så kom statsskriveren Safan tilbake til kongen og meldte: Dine tjenere har tømt ut de penger som fantes i huset, og overgitt dem til dem som forestår arbeidet og har tilsyn med Herrens hus. 
12:22:10: Og statsskriveren Safan fortalte kongen at presten Hilkias hadde gitt ham en bok. Og Safan leste den for kongen. 
12:22:11: Da kongen hørte lovbokens ord, sønderrev han sine klær. 
12:22:12: Og kongen bød presten Hilkias og Akikam, Safans sønn, og Akbor, Mikajas sønn, og statsskriveren Safan og kongens tjener Asaja: 
12:22:13: Gå og spør Herren for mig og for folket og for hele Juda om det som står i denne bok som nu er funnet! For stor er Herrens vrede, som er optendt mot oss fordi våre fedre ikke har vært lydige mot denne boks ord og ikke gjort alt det som er oss foreskrevet. 
12:22:14: Så gikk presten Hilkias og Akikam og Akbor og Safan og Asaja til profetinnen Hulda, hustru til klædeskammervokteren Sallum, sønn av Tikva, Karkas' sønn; hun bodde Jerusalem i den annen bydel; og de talte med henne. 
12:22:15: Hun sa til dem: Så sier Herren, Israels Gud: Si til den mann som har sendt eder til mig: 
12:22:16: Så sier Herren: Se, jeg fører ulykke over dette sted og dets innbyggere - alt det som står i den bok som Judas konge har lest, 
12:22:17: fordi de har forlatt mig og brent røkelse for andre guder for å vekke min harme med alt sine henders verk; min vrede er optendt mot dette sted, og den skal ikke utslukkes. 
12:22:18: Og til Judas konge, som sendte eder for å spørre Herren, til ham skal I si således: Så sier Herren, Israels Gud: Du har nu hørt disse ord; 
12:22:19: men fordi ditt hjerte blev bløtt, og du ydmyket dig for Herrens åsyn da du hørte hvad jeg har talt mot dette sted og dets innbyggere - at de skal bli til ødeleggelse og forbannelse - og fordi du sønderrev dine klær og gråt for mitt åsyn, så har også jeg hørt, sier Herren. 
12:22:20: Derfor vil jeg samle dig til dine fedre, og du skal samles med dem i din grav i fred, og dine øine skal ikke se all den ulykke jeg vil føre over dette sted. Med dette svar kom de tilbake til kongen. 
12:23:1: Da sendte kongen bud efter alle Judas og Jerusalems eldste, og de samledes hos ham. 
12:23:2: Og kongen gikk op til Herrens hus, og hver mann av Juda og alle Jerusalems innbyggere fulgte ham - prestene og profetene og alt folket, både store og små; og han leste op for dem alt det som stod skrevet i paktens bok, den som var funnet i Herrens hus. 
12:23:3: Og kongen stod på forhøiningen og gjorde den pakt for Herrens åsyn at de skulde følge Herren og holde hans bud og hans vidnesbyrd og hans forskrifter av alt sitt hjerte og av all sin sjel - at de skulde holde denne pakts ord, de som var skrevet i denne bok. Og alt folket trådte inn i pakten. 
12:23:4: Så bød kongen ypperstepresten Hilkias og prestene av annen rang og vokterne ved dørtreskelen å føre ut av Herrens tempel alle de redskaper som var gjort til Ba'al og Astarte og til hele himmelens hær; og han brente dem op utenfor Jerusalem på Kidron-markene og lot støvet av dem føre til Betel*. # <* 1KG 12, 29.> 
12:23:5: Han avsatte de prester som Judas konger hadde innsatt, og som brente røkelse på haugene i Judas byer og rundt omkring Jerusalem, og dem som brente røkelse for Ba'al, for solen og månen og dyrekretsens stjernebilleder og hele himmelens hær. 
12:23:6: Han tok Astarte-billedet ut av Herrens hus og lot det føre utenfor Jerusalem til Kidron-dalen og brenne op i Kidron-dalen og knuse til støv; og han kastet støvet av det på de ringe folks graver. 
12:23:7: Han rev ned tempel-bolernes* huser, som lå like ved Herrens hus, hvor kvinnene vevde småtelt for Astarte. # <* 1KG 14, 24.> 
12:23:8: Han lot alle prestene komme fra Judas byer og gjorde de hauger urene hvor prestene hadde brent røkelse, fra Geba til Be'erseba, og han brøt ned haugene ved portene, både den som lå ved inngangen til byhøvedsmannen Josvas port, og den som lå til venstre ved byporten. 
12:23:9: Dog fikk haugenes prester ikke ofre på Herrens alter i Jerusalem, men de åt usyret brød blandt sine brødre. 
12:23:10: Han gjorde også Tofet* i Hinnoms barns dal urent, så ikke nogen skulde la sin sønn eller datter gå gjennem ilden for Molok. # <* det sted hvor Molok blev dyrket.> 
12:23:11: Han hadde bort de hester som Judas konger hadde innvidd til solen, tett ved inngangen til Herrens hus, ved hoffmannen Netan-Meleks kammer i Parvarim*; og solens vogner brente han op med ild. # <* rimeligvis en samling av hus i nærheten av templet.> 
12:23:12: Alterne på taket over Akas' sal, som Judas konger hadde gjort, og de alter som Manasse hadde gjort i begge forgårdene til Herrens hus, rev kongen ned; og han smuldret dem og hadde dem bort derfra og kastet støvet av dem i Kidrons bekk. 
12:23:13: De offerhauger som lå midt imot Jerusalem på høire side av Ødeleggelsens berg, og som Israels konge Salomo hadde bygget for Astarte, sidoniernes vederstyggelighet, og for Kamos, Moabs vederstyggelighet, og for Milkom, Ammons barns vederstyggelighet, dem gjorde kongen urene. 
12:23:14: Han slo billedstøttene i stykker og hugg ned Astarte-billedene og fylte det sted hvor de hadde stått, med menneskeben. 
12:23:15: Også alteret i Betel, den offerhaug som Jeroboam, Nebats sønn, hadde gjort, han som fikk Israel til å synde, også det alter og den offerhaug rev han ned; og han brente op haugen og knuste den til støv; likeså brente han op Astarte-billedet. 
12:23:16: Da Josias vendte sig om og så gravene som var der på fjellet, sendte han folk dit og lot benene ta ut av gravene og brenne op på alteret og gjorde det urent efter Herrens ord, det som den Guds mann hadde utropt, han som ropte ut at dette skulde skje. 
12:23:17: Så sa han: Hvad er det for en stenstøtte jeg ser der borte? Byens menn svarte: Det er gravstedet til den Guds mann som kom fra Juda og ropte ut over alteret i Betel at det skulde skje som du nu har gjort. 
12:23:18: Da sa han: La ham hvile i fred, ingen må flytte hans ben! Så lot de hans ben være i fred og likeså benene av den profet som var kommet fra Samaria. 
12:23:19: Også alle offerhaug-husene i Samarias byer som Israels konger hadde gjort for å vekke Herrens harme, fikk Josias bort og gjorde med dem aldeles som han hadde gjort i Betel. 
12:23:20: Og alle offerhaug-prestene som var der, lot han drepe på alterne og brente menneskeben på dem. Så vendte han tilbake til Jerusalem. 
12:23:21: Og kongen bød alt folket: Hold påske for Herren eders Gud, således som skrevet er i denne paktens bok! 
12:23:22: For en påske som denne var ikke blitt holdt fra den tid da dommerne dømte Israel, heller ikke i hele den tid det hadde vært konger i Israel og Juda. 
12:23:23: Det var først i kong Josias' attende år at en sådan påske blev holdt for Herren i Jerusalem. 
12:23:24: Også dødningemanerne og sannsigerne og husgudene og de motbydelige avguder og alle de vederstyggeligheter som hadde vært å se i Juda land og i Jerusalem, utryddet Josias for å holde lovens ord som var skrevet i den bok presten Hilkias hadde funnet i Herrens hus. 
12:23:25: Det hadde aldri før vært nogen konge som han, nogen som således hadde omvendt sig til Herren av alt sitt hjerte og av all sin sjel og av all sin styrke efter hele Mose lov, og efter ham opstod det ingen som han. 
12:23:26: Dog vendte Herren ikke om fra sin store, brennende vrede, som var optendt mot Juda for alle de harmelige synder hvormed Manasse hadde vakt hans harme. 
12:23:27: Og Herren sa: Også Juda vil jeg støte bort fra mitt åsyn, likesom jeg støtte Israel bort, og jeg vil forkaste denne stad som jeg har utvalgt, Jerusalem, og det hus hvorom jeg sa: Mitt navn skal bo der. 
12:23:28: Hvad som ellers er å fortelle om Josias og om alt det han gjorde, det er opskrevet i Judas kongers krønike. 
12:23:29: I hans tid drog kongen i Egypten Farao Neko op mot kongen i Assyria til Frat-elven, og kong Josias drog imot ham; men Neko drepte ham ved Megiddo, så snart han fikk se ham. 
12:23:30: Hans tjenere kjørte ham død fra Megiddo og førte ham til Jerusalem og begravde ham i hans grav; og landets folk tok og salvet Joakas, Josias' sønn, og gjorde ham til konge i hans fars sted. 
12:23:31: Joakas var tre og tyve år gammel da han blev konge, og han regjerte tre måneder i Jerusalem; hans mor hette Hamutal; hun var datter av Jirmeja og var fra Libna. 
12:23:32: Han gjorde hvad ondt var i Herrens øine, aldeles som hans fedre hadde gjort. 
12:23:33: Farao Neko la ham i lenker i Ribla i Hamat-landet, efterat han var blitt konge i Jerusalem, og påla landet i bøter hundre talenter sølv og én talent gull. 
12:23:34: Så gjorde Farao Neko Eljakim, Josias' sønn, til konge i hans far Josias' sted og forandret hans navn til Jojakim; men Joakas tok han med sig til Egypten, og der døde han. 
12:23:35: Jojakim betalte sølvet og gullet til Farao; men han måtte utligne en skatt på landet for å kunne betale den sum som Farao hadde krevd; han inndrev sølvet og gullet av landets folk efter som enhver var lignet, og betalte det så til Farao Neko. 
12:23:36: Jojakim var fem og tyve år gammel da han blev konge, og han regjerte elleve år i Jerusalem; hans mor hette Sebida; hun var datter av Pedaja og var fra Ruma. 
12:23:37: Han gjorde hvad ondt var i Herrens øine, aldeles som hans fedre hadde gjort. 
12:24:1: I hans tid drog kongen i Babel Nebukadnesar op, og Jojakim blev hans tjener i tre år; da falt han fra ham igjen. 
12:24:2: Og Herren sendte mot ham kaldeiske og syriske og moabittiske og ammonittiske hærflokker; han sendte dem mot Juda for å ødelegge det efter det ord som Herren hadde talt ved sine tjenere profetene. 
12:24:3: Det var bare efter Herrens bud dette kom over Juda, fordi han vilde støte dem bort fra sitt åsyn for Manasses synders skyld, for alt det han hadde gjort, 
12:24:4: også for det uskyldige blods skyld som han hadde utøst, så han fylte Jerusalem med uskyldig blod; og Herren vilde ikke tilgi. 
12:24:5: Hvad som ellers er å fortelle om Jojakim og om alt det han gjorde, det er opskrevet i Judas kongers krønike. 
12:24:6: Og Jojakim la sig til hvile hos sine fedre, og hans sønn Jojakin blev konge i hans sted. 
12:24:7: Men kongen i Egypten drog ikke mere ut av sitt land; for kongen i Babel hadde tatt alt det som hadde tilhørt kongen i Egypten, fra Egyptens bekk til Frat-elven. 
12:24:8: Jojakin var atten år gammel da han blev konge, og han regjerte tre måneder i Jerusalem; hans mor hette Nehusta; hun var datter av Elnatan og var fra Jerusalem. 
12:24:9: Han gjorde hvad ondt var i Herrens øine, aldeles som hans far hadde gjort. 
12:24:10: På den tid drog Babels konge Nebukadnesars menn op til Jerusalem, og byen blev kringsatt. 
12:24:11: Siden drog kongen i Babel Nebukadnesar selv mot byen, mens hans menn holdt den kringsatt. 
12:24:12: Da gikk Judas konge Jojakin ut til kongen i Babel, både han og hans mor og hans tjenere og høvedsmenn og hoffmenn; og kongen i Babel tok ham til fange i sitt åttende regjeringsår. 
12:24:13: Han førte bort alle skattene i Herrens hus og i kongens hus, og han brøt gullet av alle de redskaper som Israels konge Salomo hadde latt gjøre i Herrens tempel, således som Herren hadde sagt. 
12:24:14: Han bortførte hele Jerusalem, alle de fornemste og alle rikmenn - ti tusen fanger - og alle tømmermenn og stenhuggere og smeder; det blev ikke nogen tilbake uten de ringeste av landets folk. 
12:24:15: Han bortførte Jojakin til Babel; også kongens mor og hustruer og hoffmenn og de mektigste i landet førte han fangne fra Jerusalem til Babel. 
12:24:16: Og alle rikmennene - syv tusen - og tømmermennene og stenhuggerne og smedene - tusen - alle sammen djerve krigsmenn, dem førte kongen i Babel fangne til Babel. 
12:24:17: Og kongen i Babel satte hans farbror Mattanja til konge i hans sted og forandret hans navn til Sedekias. 
12:24:18: Sedekias var en og tyve år gammel da han blev konge, og han regjerte elleve år i Jerusalem; hans mor hette Hamital; hun var datter av Jirmeja og var fra Libna. 
12:24:19: Han gjorde hvad ondt var i Herrens øine, aldeles som Jojakim hadde gjort. 
12:24:20: For på grunn av Herrens vrede gikk det så med Jerusalem og Juda, inntil han kastet dem bort fra sitt åsyn. Og Sedekias falt fra kongen i Babel 
12:25:1: I hans niende regjeringsår, i den tiende måned, på den tiende dag i måneden, drog kongen i Babel Nebukadnesar med hele sin hær mot Jerusalem og leiret sig mot det, og de bygget skanser mot det rundt omkring. 
12:25:2: Og de holdt byen kringsatt like til kong Sedekias' ellevte år. 
12:25:3: På den niende dag i måneden* var hungersnøden så stor i byen at landets folk ikke hadde noget å ete. # <* den fjerde måned, JER 52, 5. 6.> 
12:25:4: Og byens mur blev gjennembrutt, og alle krigsmennene flyktet om natten gjennem porten mellem begge murene ved kongens have, mens kaldeerne lå leiret mot byen rundt omkring; og han* tok veien til ødemarken. # <* kongen.> 
12:25:5: Men kaldeernes hær satte efter kongen og nådde ham igjen på Jerikos ødemarker; og hele hans hær spredte sig og forlot ham. 
12:25:6: Og de grep kongen og førte ham op til Babels konge i Ribla og avsa dom over ham*. # <* fordi han hadde brutt sin ed til Babels konge, 2KR 36, 13. ESK 17, 15 fg.> 
12:25:7: De drepte Sedekias' sønner for hans øine, og han lot Sedekias selv blinde og lot ham binde med to kobberlenker; så førte de ham til Babel. 
12:25:8: I den femte måned, på den syvende dag i måneden - det var Babels konge Nebukadnesars nittende år - kom Nebusaradan, høvdingen over livvakten, en av Babels konges menn, til Jerusalem. 
12:25:9: Han brente op Herrens hus og kongens hus, og alle Jerusalems hus - alle stormennenes hus - brente han op med ild. 
12:25:10: Og hele den hær av kaldeere som høvdingen over livvakten hadde med sig, rev ned murene rundt omkring Jerusalem. 
12:25:11: Nebusaradan, høvdingen over livvakten, bortførte resten av folket, dem som var blitt tilbake i byen, og overløperne som var gått over til kongen i Babel, og resten av hopen. 
12:25:12: Bare nogen av de ringeste i landet lot høvdingen over livvakten bli tilbake som vingårdsmenn og jordbrukere. 
12:25:13: Kaldeerne slo i stykker kobbersøilene i Herrens hus og fotstykkene og kobberhavet i Herrens hus og førte kobberet av dem til Babel. 
12:25:14: De tok også askebøttene og ildskuffene og knivene og røkelseskålene og alle kobberkarene som hadde vært brukt til tjenesten. 
12:25:15: Også fyrfatene og skålene til å sprenge blod med, alt hvad det var av gull og alt hvad det var av sølv, det tok høvdingen over livvakten. 
12:25:16: De to søiler, det ene hav og fotstykkene som Salomo hadde latt gjøre for Herrens hus - i alle disse ting var kobberet ikke til å veie. 
12:25:17: Atten alen høi var den ene søile, og det var et søilehode på den av kobber, og søilehodet var tre alen høit, og det var nettverk og granatepler på søilehodet rundt omkring, alt sammen av kobber, og likedan var det med den andre søile ved nettverket. 
12:25:18: Høvdingen over livvakten tok ypperstepresten Seraja og Sefanja, en prest av annen rang, og de tre voktere ved dørtreskelen, 
12:25:19: og fra byen tok han en hoffmann som var høvedsmann over krigsfolkene, og fem menn som ennu fantes i byen av dem som stadig hadde adgang til kongen, og skriveren hos hærføreren, han som utskrev landets folk til krigstjeneste, og seksti menn av landets folk som fantes i byen - 
12:25:20: dem tok Nebusaradan, høvdingen over livvakten, og førte til Babels konge i Ribla, 
12:25:21: og Babels konge lot dem slå ihjel i Ribla i Hamat-landet. Således blev Juda bortført fra sitt land. 
12:25:22: Over de folk som blev tilbake i Juda land, dem som kongen i Babel Nebukadnesar lot bli tilbake, satte han Gedalja, sønn av Akikam, Safans sønn. 
12:25:23: Da alle høvedsmennene for hærflokkene og deres menn hørte at kongen i Babel hadde satt Gedalja over landet, kom de til ham i Mispa - det var Ismael, Netanjas sønn, og Johanan, Kareahs sønn, og Seraja, Tanhumets sønn, fra Netofat og Ja'asanja, ma'akatittens sønn, med sine menn. 
12:25:24: Og Gedalja gav dem og deres menn sin ed og sa til dem: Vær ikke redd kaldeernes menn, bli i landet og tjen kongen i Babel! Så skal det gå eder vel. 
12:25:25: Men i den syvende måned kom Ismael, sønn av Netanja, Elisamas sønn, en mann av kongelig ætt, og ti andre menn med ham, og de slo Gedalja ihjel, og likeså de jøder og kaldeere som var hos ham i Mispa. 
12:25:26: Da brøt alt folket op, både store og små, og høvedsmennene for hærflokkene, og de drog til Egypten; for de var redd kaldeerne. 
12:25:27: I det syv og trettiende år efterat Judas konge Jojakin var bortført, i den tolvte måned, på den syv og tyvende dag i måneden, tok kongen i Babel Evilmerodak - i det år han blev konge - Judas konge Jojakin til nåde og førte ham ut av fengslet. 
12:25:28: Og han talte vennlig med ham og satte hans stol ovenfor stolene til de andre konger som var hos ham i Babel. 
12:25:29: Han la sin fangedrakt av og åt stadig ved hans bord, så lenge han levde. 
12:25:30: Og han fikk sitt stadige underhold fra kongen, hver dag det han den dag tiltrengte, så lenge han levde. 
13:1:1: Adam, Set, Enos, 
13:1:2: Kenan, Mahalalel, Jared, 
13:1:3: Enok, Metusalah, Lamek, 
13:1:4: Noah, Sem, Kam og Jafet. 
13:1:5: Jafets sønner var Gomer og Magog og Madai og Javan og Tubal og Mesek og Tiras. 
13:1:6: Og Gomers sønner var Askenas og Difat og Togarma. 
13:1:7: Og Javans sønner var Elisa og Tarsisa, Kittim og Rodanim. 
13:1:8: Kams sønner var Kus og Misra'im, Put og Kana'an. 
13:1:9: Og Kus' sønner var Seba og Havila og Sabta og Raema og Sabteka; og Raemas sønner var Sjeba og Dedan. 
13:1:10: Og Kus fikk en sønn som hette Nimrod; han var den første som fikk velde på jorden. 
13:1:11: Og Misra'im var stamfar til luderne og anamerne og lehaberne og naftuherne 
13:1:12: og patruserne og kasluherne, som filistrene stammer fra, og kaftorerne. 
13:1:13: Og Kana'an blev far til Sidon - det var hans førstefødte - og Het 
13:1:14: og jebusittene og amorittene og girgasittene 
13:1:15: og hevittene og arkittene og sinittene 
13:1:16: og arvadittene og semarittene og hamatittene. 
13:1:17: Sems sønner var Elam og Assur og Arpaksad og Lud og Aram og Us og Hul og Geter og Mesek. 
13:1:18: Og Arpaksad fikk sønnen Salah, og Salah fikk Eber. 
13:1:19: Og Eber fikk to sønner; den ene hette Peleg, for i hans dager blev menneskene spredt over jorden, og hans bror hette Joktan. 
13:1:20: Og Joktan blev far til Almodad og Salef og Hasarmavet og Jarah 
13:1:21: og Hadoram og Usal og Dikla 
13:1:22: og Ebal og Abimael og Sjeba 
13:1:23: og Ofir og Havila og Jobab; alle disse var Joktans sønner. 
13:1:24: Sem, Arpaksad, Salah, 
13:1:25: Eber, Peleg, Re'u, 
13:1:26: Serug, Nakor, Tarah, 
13:1:27: Abram, det er Abraham. 
13:1:28: Abrahams sønner var Isak og Ismael. 
13:1:29: Dette er deres ætter: Nebajot, Ismaels førstefødte, og Kedar og Adbe'el og Mibsam. 
13:1:30: Misma og Duma, Massa, Hadad og Tema, 
13:1:31: Jetur, Nafis og Kedma; dette var Ismaels venner. 
13:1:32: Og de sønner som Abrahams medhustru Ketura fødte, var: Simran og Joksan og Medan og Midian og Jisbak og Suah; og Joksans sønner var Sjeba og Dedan; 
13:1:33: og Midians sønner var Efa og Efer og Hanok og Abida og Elda'a. Alle disse var Keturas sønner. 
13:1:34: Og Abraham fikk sønnen Isak. Isaks sønner var Esau og Israel. 
13:1:35: Esaus sønner var Elifas, Re'uel og Je'us og Jaelam og Korah. 
13:1:36: Elifas' sønner var Teman og Omar, Sefi og Gatam, Kenas og Timna og Amalek. 
13:1:37: Re'uels sønner var Nahat, Serah, Samma og Missa. 
13:1:38: Og Se'irs sønner var Lotan og Sobal og Sibeon og Ana og Dison og Eser og Disan. 
13:1:39: Og Lotans sønner var Hori og Homam; og Lotans søster var Timna. 
13:1:40: Sobals sønner var Aljan og Manahat og Ebal, Sefi og Onam; og Sibeons sønner var Aja og Ana. 
13:1:41: Anas sønn var Dison; og Disons sønner var Hamran og Esban og Jitran og Keran. 
13:1:42: Esers sønner var Bilhan og Sa'avan og Ja'akan; Disans sønner var Us og Aran. 
13:1:43: Og dette var de konger som regjerte i Edom-landet før det regjerte nogen konge over Israels barn: Bela, Beors sønn; hans by hette Dinhaba. 
13:1:44: Da Bela døde, blev Jobab, Serahs sønn, fra Bosra, konge i hans sted. 
13:1:45: Da Jobab døde, blev Husam fra temanittenes land konge i hans sted. 
13:1:46: Da Husam døde, blev Hadad, Bedads sønn, konge i hans sted; det var han som slo midianittene på Moabs mark; hans by hette Ajut. 
13:1:47: Da Hadad døde, blev Samla fra Masreka konge i hans sted. 
13:1:48: Da Samla døde, blev Saul fra Rehobot ved elven konge i hans sted. 
13:1:49: Da Saul døde, blev Ba'al-Hanan, Akbors sønn, konge i hans sted. 
13:1:50: Da Ba'al-Hanan døde, blev Hadad konge i hans sted; hans by hette Pa'i, og hans hustru hette Mehetabel, datter av Matred, Mesahabs datter. 
13:1:51: Og Hadad døde. Og Edoms stammefyrster var: stammefyrsten Timna, stammefyrsten Alja, stammefyrsten Jetet, 
13:1:52: stammefyrsten Oholibama, stammefyrsten Ela, stammefyrsten Pinon, 
13:1:53: stammefyrsten Kenas, stammefyrsten Teman, stammefyrsten Mibsar, 
13:1:54: stammefyrsten Magdiel, stammefyrsten Iram; dette var Edoms stammefyrster. 
13:2:1: Dette var Israels sønner: Ruben, Simeon, Levi og Juda, Issakar og Sebulon, 
13:2:2: Dan, Josef og Benjamin, Naftali, Gad og Aser. 
13:2:3: Judas sønner var Er og Onan og Sela; disse tre fikk han med Suas datter, kana'anittinnen; men Er, Judas førstefødte, mishaget Herren, og han lot ham dø. 
13:2:4: Og hans sønnekone Tamar fødte ham Peres og Serah, så at Judas sønner i alt var fem. 
13:2:5: Peres' sønner var Hesron og Hamul. 
13:2:6: Og Serahs sønner var Simri* og Etan og Heman og Kalkol og Dara, tilsammen fem. # <* kalles Sabdi, JOS 7, 1.> 
13:2:7: Og Karmis* sønn var Akar**, som førte Israel i ulykke, fordi han bar sig troløst at med det bannlyste gods. # <* Karmi var sønn av Sabdi, 1KR 2, 7. JOS 7, 1.> # <** kalles ellers Akan.> 
13:2:8: Og Etans sønn var Asarja. 
13:2:9: Og de sønner som Hesron fikk, var Jerahme'el og Ram og Kelubai*. # <* kalles Kaleb.> 
13:2:10: Og Ram fikk sønnen Amminadab, og Amminadab fikk sønnen Nahson, høvding for Judas barn, 
13:2:11: og Nahson fikk sønnen Salma, og Salma fikk sønnen Boas, 
13:2:12: og Boas fikk sønnen Obed, og Obed fikk sønnen Isai. 
13:2:13: Og Isai fikk Eliab, som var hans førstefødte sønn, og Abinadab, hans annen sønn, og Simea, den tredje, 
13:2:14: Netanel, den fjerde, Raddai, den femte, 
13:2:15: Osem, den sjette, David, den syvende. 
13:2:16: Og deres søstre var Seruja og Abiga'il. Og Serujas sønner var Absai og Joab og Asael, tre i tallet. 
13:2:17: Og Abiga'il fødte Amasa, og Amasas far var ismaelitten Jeter. 
13:2:18: Og Kaleb, Hesrons sønn, fikk barn med sin hustru Asuba og med Jeriot; og dette var hennes* sønner: Jeser og Sobab og Ardon. # <* Asubas.> 
13:2:19: Da Asuba døde, tok Kaleb Efrat til hustru, og med henne fikk han sønnen Hur, 
13:2:20: og Hur fikk sønnen Uri, og Uri fikk sønnen Besalel. 
13:2:21: Siden giftet Hesron sig med en datter av Gileads far Makir; han tok henne til hustru da han var seksti år gammel, og fikk med henne sønnen Segub. 
13:2:22: Og Segub fikk sønnen Ja'ir; han hadde tre og tyve byer i Gileads land. 
13:2:23: Men gesurittene og arameerne tok Ja'irs byer fra dem og likeså Kenat med tilhørende landsbyer - i alt seksti byer. Alle disse* var sønner av** Gileads far Makir. # <* Segub, Ja'ir og deres ætt, 1KR 2, 21. 22.> # <** De regnes for sønner av Makir, altså til Manasse stamme, 4MO 32, 40, fordi de nedstammet fra Makirs datter, 1KR 2, 21. Deres stamfar Hesron var av Juda stamme, 1KR 2, 5.> 
13:2:24: Og efterat Hesron var død i Kaleb-Efrata, fødte hans hustru Abia ham Ashur, Tekoas* far. # <* d.e. Tekoas innbyggeres. Således er og navnene på de byer som forekommer i det følgende, å forstå om disse byers innbyggere.> 
13:2:25: Og sønnene til Jerahme'el, Hesrons førstefødte, var Ram, hans førstefødte, og Buna og Oren og Osem; dem hadde han med Akia. 
13:2:26: Jerahme'el hadde også en annen hustru, som hette Atara; hun var mor til Onam. 
13:2:27: Og sønnene til Ram, Jerahme'els førstefødte, var Ma'as og Jamin og Eker. 
13:2:28: Og Onams sønner var Sammai og Jada. Og Sammais sønner var Nadab og Abisur. 
13:2:29: Abisurs hustru hette Abiha'il, og med henne fikk han Akban og Molid. 
13:2:30: Og Nadabs sønner var Seled og Appa'im; Seled døde uten sønner. 
13:2:31: Og Appa'ims sønn var Jisi; og Jisis sønn var Sesan; og Sesans barn* var Aklai. # <* d.e. datter, 1KR 2, 34.> 
13:2:32: Og sønnene til Sammais bror Jada var Jeter og Jonatan. Jeter døde uten sønner. 
13:2:33: Og Jonatans sønner var Pelet og Sasa. Dette var Jerahme'els sønner. 
13:2:34: Sesan hadde ingen sønner, bare døtre. Men Sesan hadde en egyptisk tjener, som hette Jarha, 
13:2:35: og han lot sin tjener Jarha få sin datter til hustru, og med henne fikk han sønnen Attai. 
13:2:36: Og Attai fikk sønnen Natan, og Natan fikk sønnen Sabad, 
13:2:37: og Sabad fikk sønnen Eflal, og Eflal fikk sønnen Obed, 
13:2:38: og Obed fikk sønnen Jehu, og Jehu fikk sønnen Asarja, 
13:2:39: og Asarja fikk sønnen Hales, og Hales fikk sønnen Elasa, 
13:2:40: og Elasa fikk sønnen Sismai, og Sismai fikk sønnen Sallum, 
13:2:41: og Sallum fikk sønnen Jekamja, og Jekamja fikk sønnen Elisama. 
13:2:42: Og sønnene til Jerahme'els bror Kaleb var Mesa hans førstefødte - han var far til Sif - og sønnene til Hebrons far Maresa. 
13:2:43: Og Hebrons sønner var Korah og Tappuah og Rekem og Sema. 
13:2:44: Og Sema fikk sønnen Raham, far til Jorkeam, og Rekem fikk sønnen Sammai. 
13:2:45: Og Sammais sønn var Maon, og Maon var far til Betsur. 
13:2:46: Og Kalebs medhustru Efa fødte Haran og Mosa og Gases; og Haran fikk sønnen Gases. 
13:2:47: Og Jehdais sønner var Regem og Jotam og Gesan og Pelet og Efa og Sa'af. 
13:2:48: Kalebs medhustru Ma'aka fødte Seber og Tirhana; 
13:2:49: hun fødte også Sa'af, far til Madmanna, og Seva, far til Makbena og Gibea; og Kalebs datter var Aksa. 
13:2:50: Dette var Kalebs sønner: Sobal, sønn til Efratas førstefødte sønn Hur og far til Kirjat-Jearim, 
13:2:51: Salma, far til Betlehem, Haref, far til Betgader. 
13:2:52: Og sønnene til Kirjat-Jearims far Sobal var Haroe* og halvdelen av Hammenuhot-ætten. # <* d.s.s. Reaja 1KR 4, 2.> 
13:2:53: Og Kirjat-Jearims ætter var jitrittene og putittene og sumatittene og misra'ittene; fra dem stammer soratittene og estaolittene. 
13:2:54: Salmas sønner var Betlehem og netofatittene, Atrot-Bet-Joab og halvdelen av manahtittene, sorittene. 
13:2:55: Og de skriftlærdes ætter, deres som bodde i Jabes, var tiratittene, simatittene, sukatittene; dette er de kinnitter som nedstammer fra Hammat, far til Rekabs hus. 
13:3:1: Dette var Davids sønner som han fikk i Hebron: Den førstefødte, Amnon, fikk han med Akinoam fra Jisre'el; den annen, Daniel*, med Abiga'il fra Karmel; # <* d.s.s. Kilab, 2SA 3, 3.> 
13:3:2: den tredje, Absalom, var sønn av Ma'aka, en datter av kongen i Gesur Talmai; den fjerde, Adonja, var sønn av Haggit; 
13:3:3: den femte, Sefatja, fikk han med Abital; den sjette, Jitream, med sin hustru Egla. 
13:3:4: Disse seks fikk han i Hebron; der regjerte han i syv år og seks måneder, og i tre og tretti år regjerte han i Jerusalem. 
13:3:5: Dette er de sønner han fikk i Jerusalem: Simea og Sobab og Natan og Salomo - disse fire fikk han med Batsua*, Ammiels datter - # <* d.s.s. Batseba, 2SA 12, 24.> 
13:3:6: og Jibhar og Elisama og Elifelet 
13:3:7: og Nogah og Nefeg og Jafia 
13:3:8: og Elisama og Eljada og Elifelet, ni i tallet. 
13:3:9: Alle disse var Davids sønner foruten de sønner han fikk med sine medhustruer; og Tamar var deres søster. 
13:3:10: Og Salomos sønn var Rehabeam; hans sønn Abia; hans sønn Asa; hans sønn Josafat; 
13:3:11: hans sønn Joram; hans sønn Akasja; hans sønn Joas, 
13:3:12: hans sønn Amasja; hans sønn Asarja; hans sønn Jotam; 
13:3:13: hans sønn Akas; hans sønn Esekias; hans sønn Manasse; 
13:3:14: hans sønn Amon; hans sønn Josias. 
13:3:15: Og Josias' sønner var Johanan, som var hans førstefødte sønn, Jojakim, hans annen sønn, Sedekias, den tredje, Sallum, den fjerde. 
13:3:16: Og Jojakims sønner var hans sønn Jekonja og hans sønn Sedekias. 
13:3:17: Og den fangne Jekonjas sønner var hans sønn Sealtiel 
13:3:18: og Malkiram og Pedaja og Senassar, Jekamja, Hosama og Nedabja. 
13:3:19: Og Pedajas sønner var Serubabel og Sime'i; og Serubabels sønner var Mesullam og Hananja - deres søster var Selomit - 
13:3:20: og Hasuba og Ohel og Berekja, Hasadja og Jusab-Hesed, fem i tallet. 
13:3:21: Og Hananjas sønner var Pelatja og Jesaja, Refajas sønner, Arnans sønner, Obadjas sønner og Sekanjas sønner. 
13:3:22: Og Sekanjas sønn var Semaja; og Semajas sønner var Hattus og Jigal og Bariah og Nearja og Safat, seks i tallet. 
13:3:23: Og Nearjas sønner var Eljoenai og Hiskia og Asrikam, tre i tallet. 
13:3:24: Og Eljoenais sønner var Hodaiva og Eljasib og Pelaja og Akkub og Johanan og Delaja og Anani, syv i tallet. 
13:4:1: Judas sønner var Peres, Hesron og Karmi og Hur og Sobal. 
13:4:2: Og Reaja, Sobals sønn, fikk sønnen Jahat, og Jahat fikk sønnene Ahumai og Lahad; dette var soratittenes ætter. 
13:4:3: Og dette var Abi-Etams [ætter]: Jisre'el og Jisma og Jidbas, og deres søster hette Haslelponi. 
13:4:4: Og Pnuel var far til Gedor, og Eser var far til Husa; de var sønner av Hur, Efratas førstefødte sønn og far til Betlehem. 
13:4:5: Og Tekoas far Ashur hadde to hustruer, Hela og Na'ara. 
13:4:6: Med Na'ara fikk han Ahussam og Hefer og Temeni og Ahastari; dette var Na'aras sønner. 
13:4:7: Og Helas sønner var Seret, Jishar og Etnan. 
13:4:8: Og Kos blev far til Anub og Hassobeba og Aharhels, Harums sønns ætter. 
13:4:9: Jabes var æret fremfor sine brødre; hans mor gav ham navnet Jabes*, idet hun sa: Jeg har født ham med smerte. # <* han volder smerte.> 
13:4:10: Og Jabes påkalte Israels Gud og sa: Å, om du vilde velsigne mig og utvide mine landemerker og la din hånd være med mig, og om du vilde lage det så at det ingen ulykke kom, og jeg blev fri for smerte! Og Gud lot det komme som han hadde bedt om. 
13:4:11: Og Suhas bror Kelub fikk sønnen Mehir; han var far til Eston. 
13:4:12: Og Eston var far til Bet-Rafa og Paseah og Tehinna, Ir-Nahas' far dette var mennene fra Reka. 
13:4:13: Og Kenas' sønner var Otniel og Seraja, og Otniels sønn var Hatat. 
13:4:14: Og Meonotai fikk sønnen Ofra, og Seraja fikk sønnen Joab, som var far til ætten i Tømmermannsdalen; for de var tømmermenn. 
13:4:15: Og sønnene til Kaleb, Jefunnes sønn, var Iru, Ela og Na'am, og Elas sønn var Kenas. 
13:4:16: Og Jehallelels sønner var Sif og Sifa, Tirja og Asarel. 
13:4:17: Og Esras sønner var Jeter og Mered og Efer og Jalon; og hun* blev fruktsommelig og fødte Mirjam og Sammai og Jisbah, far til Estemoa. # <* Mereds hustru Bitja, 1KR 4, 18.> 
13:4:18: Og hans jødiske hustru fødte Jered, far til Gedor, og Heber, far til Soko, og Jekutiel, far til Sanoah; men de andre* var sønner av Faraos datter Bitja, som Mered hadde tatt til hustru. # <* Mirjam, Sammai og Jisbah, 1KR 4, 17.> 
13:4:19: Og sønnene av Hodijas hustru, Nahams søster, var Ke'ilas far, garmitten, og Estemoa, ma'akatitten. 
13:4:20: Og Simons sønner var Amnon og Rinna, Ben-Hanan og Tulon; og Jisis sønner var Sohet og Sohets sønn. 
13:4:21: Sønnene til Sela, Judas sønn, var Er, far til Leka, og Lada, far til Maresa, og de ætter som tilhørte bomulls-arbeidernes hus av Asbeas ætt, 
13:4:22: og Jokim og mennene i Koseba og Joas og Saraf, som rådet over Moab, og Jasubi-Lehem; men dette er gamle ting. 
13:4:23: Disse folk var pottemakere og bodde i Neta'im og Gedera; de bodde der hos kongen og var i hans arbeid. 
13:4:24: Simeons sønner var Nemuel og Jamin, Jarib, Serah og Saul; 
13:4:25: hans sønn var Sallum; hans sønn Mibsam; hans sønn Misma. 
13:4:26: Og Mismas sønner var hans sønn Hammuel, hans sønn Sakkur og hans sønn Sime'i. 
13:4:27: Og Sime'i hadde seksten sønner og seks døtre; men hans brødre hadde ikke mange sønner, så hele deres ætt ikke øktes så sterkt som Judas sønner. 
13:4:28: De bodde i Be'erseba og Molada og Hasar-Sual 
13:4:29: og i Bilha og i Esem og i Tolad 
13:4:30: og i Betuel og i Horma og i Siklag 
13:4:31: og i Bet-Markabot og i Hasar-Susim og i Bet-Biri og i Sa'ara'im - det var deres byer inntil David blev konge - 
13:4:32: med tilhørende landsbyer, og i Etam og A'in, Rimmon og Token og Asan - fem byer - 
13:4:33: og alle de landsbyer som lå rundt omkring disse byer helt til Ba'al; dette var deres bosteder, og de hadde sin egen ætteliste. 
13:4:34: Og Mesobab og Jamlek og Josa, Amasjas sønn, 
13:4:35: og Joel og Jehu, sønn av Josibja, som var sønn av Asiels sønn Seraja, 
13:4:36: og Eljoenai og Ja'akoba og Jesohaja og Asaja og Adiel og Jesimiel og Benaja 
13:4:37: og Sisa, sønn av Sifi, som var sønn av Allon, som var sønn av Jedaja, som var sønn av Simri, som var sønn av Semaja - 
13:4:38: disse som er nevnt her ved navn, var høvdinger i sine ætter, og deres familier utbredte sig sterkt. 
13:4:39: De drog avsted bortimot Gedor like til østsiden av dalen for å søke beite for sitt småfe; 
13:4:40: og der fant de fett og godt beite, og landet var vidt og rummelig, og det var rolig og stille; for de som bodde der før, var av Kams ætt. 
13:4:41: Da kom i Judas konge Esekias' dager de som her er optegnet ved navn, og ødela deres telter og hugg ned de meunitter som fantes der, og slo dem med bann, så de nu ikke mere er til, og bosatte sig i deres land; for der var beite for deres småfe. 
13:4:42: Fem hundre mann av dem - av Simeons barn - drog til Se'irfjellene under anførsel av Pelatja og Nearja og Refaja og Ussiel, Jisis sønner; 
13:4:43: og de slo ihjel resten av de amalekitter som hadde sloppet unda; så bosatte de sig der og bor der den dag idag. 
13:5:1: Og sønnene til Ruben, Israels førstefødte - for han var den førstefødte, men fordi han vanhelliget sin fars seng, blev hans førstefødselsrett gitt til sønnene av Josef, Israels sønn, som dog ikke blev innført i ættelisten som den førstefødte; 
13:5:2: for Juda blev den mektigste blandt sine brødre, og fyrsten skulde være en av hans efterkommere, men førstefødselsretten tilhørte Josef - 
13:5:3: sønnene til Ruben, Israels førstefødte, var Hanok og Pallu, Hesron og Karmi. 
13:5:4: Joels sønner: hans sønn Semaja; hans sønn Gog; hans sønn Sime'i; 
13:5:5: hans sønn var Mika; hans sønn Reaja; hans sønn Ba'al; 
13:5:6: hans sønn Be'era, som kongen i Assyria Tilgat-Pilneser bortførte i fangenskap; han var høvding for rubenittene. 
13:5:7: Og hans brødre var efter sine ætter, da de blev optegnet efter sine ætt-ledd, Je'iel, den første, og Sakarja 
13:5:8: og Bela, sønn av Asas, som var sønn av Joels sønn Sema; han bodde i Aroer og helt til Nebo og Ba'al-Meon, 
13:5:9: og mot øst nådde hans bosteder til bortimot ørkenen som strekker sig fra elven Frat; for deres fe hadde øket sterkt i Gileads land. 
13:5:10: I Sauls dager førte de krig med hagarenerne, som blev undertvunget av dem; så bodde de i deres telter langs hele østsiden av Gilead. 
13:5:11: Og Gads barn hadde sine bosteder midt imot dem i Basans land helt til Salka: 
13:5:12: Joel, den første, og Safan, den annen, og Janai og Safat i Basan. 
13:5:13: Og deres brødre var efter sine familier Mikael og Mesullam og Seba og Jorai og Jakan og Sia og Eber, syv i tallet. 
13:5:14: Disse var sønner av Abiha'il, sønn av Huri, sønn av Jaroah, sønn av Gilead, sønn av Mikael, sønn av Jesisai, sønn av Jahdo, sønn av Bus. 
13:5:15: Aki, sønn av Gunis sønn Abdiel, var deres familiehode. 
13:5:16: Og de bodde i Gilead i Basan og tilhørende byer og på alle Sarons jorder, sa langt de strakte sig. 
13:5:17: Alle disse blev innført i ættelistene i Judas konge Jotams dager og i Israels konge Jeroboams dager. 
13:5:18: Rubens barn og gadittene og halvdelen av Manasse stamme var djerve menn, menn som bar skjold og sverd og spente bue og var oplært til krig; de var fire og firti tusen, syv hundre og seksti stridsføre menn. 
13:5:19: De førte krig mot hagarenerne og Jetur og Nafis og Nodab, 
13:5:20: og de fikk hjelp mot dem, så hagarenerne og alle som var med dem, blev gitt i deres hånd; for de ropte til Gud i striden, og han bønnhørte dem, fordi de satte sin lit til ham. 
13:5:21: Og de bortførte deres fe, femti tusen kameler og to hundre og femti tusen stykker småfe og to tusen asener, og dessuten hundre tusen mennesker. 
13:5:22: For mange var falt og drept; for denne krig var fra Gud. Og de bodde i deres bygder like til bortførelsen. 
13:5:23: Den halve Manasse stammes barn bodde i landet fra Basan til Ba'al-Hermon og Senir og Hermon-fjellet; de var tallrike. 
13:5:24: Og dette var deres familiehoder: Efer og Jisi og Eliel og Asriel og Jirmeja og Hodavja og Jahdiel, veldige stridsmenn, navnkundige menn, hoder for sine familier. 
13:5:25: Men de bar sig troløst at mot sine fedres Gud og holdt sig med de guder som folkene i landet dyrket, de folk som Gud hadde utryddet for dem. 
13:5:26: Da opegget Israels Gud assyrerkongen Ful og assyrerkongen Tilgat-Pilneser mot dem, og han bortførte dem, både rubenittene og gadittene og den halve Manasse stamme, og flyttet dem til Halah og Habor og Hara og Gosan-elven, og der er de den dag idag. 
13:6:1: Levis sønner var Gerson, Kahat og Merari. 
13:6:2: Og Kahats sønner var Amram, Jishar og Hebron og Ussiel. 
13:6:3: Og Amrams barn var Aron og Moses og Mirjam; og Arons sønner var Nadab og Abihu, Eleasar og Itamar. 
13:6:4: Eleasar fikk sønnen Pinehas; Pinehas fikk sønnen Abisua, 
13:6:5: og Abisua fikk sønnen Bukki, og Bukki fikk sønnen Ussi, 
13:6:6: og Ussi fikk sønnen Serahja, og Serahja fikk sønnen Merajot; 
13:6:7: Merajot fikk sønnen Amarja, og Amarja fikk sønnen Akitub, 
13:6:8: og Akitub fikk sønnen Sadok, og Sadok fikk sønnen Akima'as, 
13:6:9: og Akima'as fikk sønnen Asarja, og Asarja fikk sønnen Johanan, 
13:6:10: Johanan fikk sønnen Asarja; det var han som gjorde prestetjeneste* i det hus som Salomo hadde bygget i Jerusalem. # <* nemlig i Kong Ussias' tid.> 
13:6:11: Og Asarja fikk sønnen Amarja, og Amarja fikk sønnen Akitub, 
13:6:12: og Akitub fikk sønnen Sadok, og Sadok fikk sønnen Sallum, 
13:6:13: og Sallum fikk sønnen Hilkias, og Hilkias fikk sønnen Asarja, 
13:6:14: og Asarja fikk sønnen Seraja, og Seraja fikk sønnen Josadak. 
13:6:15: Josadak drog med da Herren bortførte Juda og Jerusalem i fangenskap ved Nebukadnesar. 
13:6:16: Levis sønner var Gersom, Kahat og Merari. 
13:6:17: Og dette er navnene på Gersoms sønner: Libni og Sime'i. 
13:6:18: Og Kahats sønner var Amram og Jishar og Hebron og Ussiel. 
13:6:19: Meraris sønner var Mahli og Musi. Dette var levittenes ætter efter deres fedre. 
13:6:20: Gersoms sønner var: hans sønn Libni; hans sønn Jahat; hans sønn Simma; 
13:6:21: hans sønn Joah; hans sønn Iddo; hans sønn Serah; hans sønn Jeatrai. 
13:6:22: Kahats sønner var: hans sønn Amminadab; hans sønn Korah; hans sønn Assir; 
13:6:23: hans sønn Elkana og hans sønn Ebjasaf og hans sønn Assir; 
13:6:24: hans sønn Tahat; hans sønn Uriel; hans sønn Ussia og hans sønn Saul. 
13:6:25: Og Elkanas sønner var Amasai Og Akimot; 
13:6:26: hans sønn var Elkana; hans sønn var Sofai og hans sønn Nahat; 
13:6:27: hans sønn Eliab; hans sønn Jeroham; hans sønn Elkana*. # <* Samuels far, 1 Sam. 1, 1. 20.> 
13:6:28: Og Samuels sønner var Vasni, hans førstefødte, og Abia. 
13:6:29: Meraris sønn var Mahli; hans sønn var Libni; hans sønn Sime i; hans sønn Ussa; 
13:6:30: hans sønn Simea; hans sønn Haggija; hans sønn Asaja. 
13:6:31: Dette var de som David satte til å sørge for sangen i Herrens hus efterat arken var kommet til ro, 
13:6:32: og de gjorde tjeneste ved sangen foran sammenkomstens telt - tabernaklet - inntil Salomo bygget Herrens hus i Jerusalem, og de stod der og utførte sin tjeneste således som det var dem foreskrevet - 
13:6:33: dette var de som stod der med sine sønner: Av kahatittenes sønner: sangeren Heman, sønn av Joel, sønn av Samuel, 
13:6:34: sønn av Elkana, sønn av Jeroham, sønn av Eliel, sønn av Toah, 
13:6:35: sønn av Sif, sønn av Elkana, sønn av Mahat, sønn av Amasai, 
13:6:36: sønn av Elkana, sønn av Joel, sønn av Asarja, sønn av Sefanja, 
13:6:37: sønn av Tahat, sønn av Assir, sønn av Ebjasaf, sønn av Korah, 
13:6:38: sønn av Jishar, sønn av Kahat, sønn av Levi, Israels sønn. 
13:6:39: Og hans* bror Asaf, som stod på hans høire side - Asaf, sønn av Berekja, sønn av Simea, # <* Hemans, 1KR 6, 33; 15, 17.> 
13:6:40: sønn av Mikael, sønn av Ba'aseja, sønn av Malkija, 
13:6:41: sønn av Etni, sønn av Serah, sønn av Adaja, 
13:6:42: sønn av Etan, sønn av Simma, sønn av Sime'i, 
13:6:43: sønn av Jahat, sønn av Gersom, sønn av Levi. 
13:6:44: Og Meraris sønner, deres brødre, stod på venstre side: Etan, sønn av Kisi, sønn av Abdi, sønn av Malluk, 
13:6:45: sønn av Hasabja, sønn av Amasja, sønn av Hilkias, 
13:6:46: sønn av Amsi, sønn av Bani, sønn av Semer, 
13:6:47: sønn av Mahli, sønn av Musi, sønn av Merari, sønn av Levi. 
13:6:48: Og deres brødre, de andre levitter, var gitt* til å utføre alle slags tjeneste i tabernaklet - Guds hus. # <* nemlig prestene.> 
13:6:49: Men Aron og hans sønner ofret på brennoffer-alteret og på røkoffer-alteret og var satt til å utføre all tjeneste i det Aller-helligste og til å gjøre soning for Israel efter alt det som Guds tjener Moses hadde påbudt. 
13:6:50: Dette var Arons sønner: hans sønn Eleasar; hans sønn Pinehas; hans sønn Abisua; 
13:6:51: hans sønn Bukki; hans sønn Ussi; hans sønn Serahja; 
13:6:52: hans sønn Merajot; hans sønn Amarja; hans sønn Akitub; 
13:6:53: hans sønn Saddok; hans sønn Akima'as. 
13:6:54: Dette var deres bosteder efter deres byer innenfor deres landemerker: Arons sønner av kahatittenes ætt, som loddet først kom ut for, 
13:6:55: de fikk Hebron i Juda land med tilhørende jorder rundt omkring; 
13:6:56: men byens mark og dens landsbyer fikk Kaleb, Jefunnes sønn. 
13:6:57: Arons sønner fikk altså tilfluktsstædene Hebron og Libna med tilhørende jorder og Jattir og Estemoa med jorder 
13:6:58: og Hilen med jorder, Debir med jorder 
13:6:59: Og Asan med jorder og Bet-Semes med jorder, 
13:6:60: og av Benjamins stamme Geba med tilhørende jorder og Allemet med jorder og Anatot med jorder; deres byer var i alt tretten byer efter deres ætter. 
13:6:61: Men de andre* Kahats barn av stammens ætt fikk ved loddkasting ti byer av den halve Manasse stammes halve del. # <* d.e. som ikke var av Arons ætt, 1KR 6, 54.> 
13:6:62: Og Gersoms barn efter sine ætter fikk tretten byer av Issakars stamme og av Asers stamme Og av Naftali stamme og av Manasse stamme i Basan. 
13:6:63: Meraris barn efter sine ætter fikk ved loddkasting tolv byer av Rubens stamme og av Gads stamme og av Sebulons stamme. 
13:6:64: Således gav Israels barn levittene disse byer med tilhørende jorder. 
13:6:65: De gav dem ved loddkasting av Judas barns stamme og av Simeons barns stamme og av Benjamins barns stamme disse byer, som de nevnte ved navn. 
13:6:66: Og de andre av Kahats barns ætter* fikk av Efra'ims stamme disse byer som skulde høre dem til: # <* de 1KR 2, 61 omtalte.> 
13:6:67: tilfluktsstædene Sikem med tilhørende jorder i Efra'im-fjellene og Geser med jorder 
13:6:68: og Jokmeam med jorder og Bet-Horon med jorder 
13:6:69: og Ajalon med jorder og Gat-Rimmon med jorder, 
13:6:70: og av den halve Manasse stamme Aner med tilhørende jorder og Bileam med jorder. Dette fikk de andre av Kahats barns ætt. 
13:6:71: Gersoms barn fikk av den halve Manasse stammes ætt Golan i Basan med tilhørende jorder og Astarot med jorder, 
13:6:72: og av Issakars stamme Kedes med tilhørende jorder, Dobrat med jorder 
13:6:73: og Ramot med jorder og Anem med jorder, 
13:6:74: og av Asers stamme Masal med tilhørende jorder og Abdon med jorder 
13:6:75: og Hukok med jorder og Rehob med jorder, 
13:6:76: og av Naftali stamme Kedes i Galilea med tilhørende jorder og Hammon med jorder og Kirjata'im med jorder. 
13:6:77: Meraris barn - resten av levittene - fikk av Sebulons stamme Rimmono med tilhørende jorder og Tabor med jorder, 
13:6:78: og på hin side Jordan ved Jeriko, østenfor Jordan, fikk de av Rubens stamme Beser i ørkenen med tilhørende jorder og Jahsa med jorder 
13:6:79: og Kedemot med jorder og Mefa'at med jorder, 
13:6:80: og av Gads stamme Ramot-Gilead med tilhørende jorder og Mahana'im med jorder 
13:6:81: og Hesbon med jorder og Jaser med jorder. 
13:7:1: Issakars sønner var Tola og Pua, Jasib og Simron, fire i tallet. 
13:7:2: Og Tolas sønner var Ussi og Refaja og Jeriel og Jahmai og Jibsam og Samuel, hoder for sine familier, som stammet fra Tola, veldige stridsmenn i sine ætter; deres tall var i Davids dager to og tyve tusen og seks hundre. 
13:7:3: Og Ussis sønner var Jisrahja og Jisrahjas sønner Mikael og Obadja og Joel og Jissija, fem i tallet, alle sammen familiehoder. 
13:7:4: Til dem hørte, efter deres ætter og fedrenehuser, krigsrustede flokker, seks og tretti tusen mann; for de hadde mange hustruer og barn. 
13:7:5: Og deres brødre i alle Issakars ætter var veldige stridsmenn; i alt var de* syv og åtti tusen mann som var opskrevet. # <* stridsmennene i den hele stamme.> 
13:7:6: Benjamins sønner var Bela og Beker og Jediael, tre i tallet. 
13:7:7: Og Belas sønner var Esbon og Ussi og Ussiel og Jerimot og Iri, fem i tallet, familiehoder, veldige stridsmenn; i deres ætteliste stod det to og tyve tusen og fire og tretti mann. 
13:7:8: Og Bekers sønner var Semira og Joas og Elieser og Eljoenai og Omri og Jeremot og Abia og Anatot og Alemet; alle disse var Bekers sønner, 
13:7:9: og efter deres ættelister, hvor deres ætter og deres familiehoder, veldige stridsmenn, var opskrevet, var de tyve tusen og to hundre mann. 
13:7:10: Og Jediaels sønner var Bilhan og Bilhans sønner Je'is og Benjamin og Ehud og Kena'ana og Setan og Tarsis og Akisahar; 
13:7:11: alle disse var Jediaels sønner, familiehoder. veldige stridsmenn, sytten tusen og to hundre som krigsrustet drog ut i strid. 
13:7:12: Enn videre Suppim og Huppim, Irs sønner, og Hussim, som stammet fra Aker. 
13:7:13: Naftalis sønner var Jahsiel og Guni og Jeser og Sallum, Bilhas sønner. 
13:7:14: Manasses sønner var Asriel, som stammet fra hans syriske medhustru; hun fødte Makir, far til Gilead. 
13:7:15: Og Makir tok til hustru en søster av Huppim og Suppim, og hans* søster hette Ma'aka; og den annen sønn hette Selofhad, og Selofhad hadde bare døtre. # <* Huppims.> 
13:7:16: Og Makirs hustru Ma'aka fødte en sønn og kalte ham Peres, og hans bror hette Seres, og hans sønner var Ulam og Rekem. 
13:7:17: Og Ulams sønn var Bedan. Disse var sønner av Gilead, sønn av Makir, sønn av Manasse. 
13:7:18: Og hans* søster var Hammoleket; hun fødte Ishod og Abieser og Mahla. # <* Gileads.> 
13:7:19: Og Semidas sønner var Akjan og Sekem og Likhi og Aniam. 
13:7:20: Og Efra'ims sønner var Sutelah - hans sønn var Bered og hans sønn Tahat og hans sønn Elada og hans sønn Tahat 
13:7:21: og hans sønn Sabad og hans sønn Sutelah - og Eser og Elad*; de blev drept av menn fra Gat som var født der i landet, fordi de hadde draget ned for å røve deres fe, # <* Eser og Elad var tillikemed Sutelah 1KR 7, 20 Efra'ims sønner.> 
13:7:22: Og deres far Efra'im sørget i lang tid, og hans brødre kom for å trøste ham. 
13:7:23: Og han gikk inn til sin hustru, og hun blev fruktsommelig og fødte en sønn; og han gav ham navnet Beria, fordi der hadde vært ulykke i hans hus. 
13:7:24: Hans datter var Se'era; hun bygget Nedre- og Øvre-Bet-Horon og Ussen-Se'era. 
13:7:25: Hans* sønn var Refa, og hans sønner var Resef og Telah, og hans sønn var Tahan, # <* Berias, 1KR 7 23.> 
13:7:26: hans sønn Ladan, hans sønn Ammihud, hans sønn Elisama, 
13:7:27: hans sønn Non, hans sønn Josva. 
13:7:28: Deres eiendom og deres bosteder var Betel med tilhørende småbyer og mot øst Na'aran og mot vest Geser med tilhørende småbyer og Sikem med tilhørende småbyer like til Gasa med tilhørende småbyer. 
13:7:29: Manasses barn eide Bet-Sean med tilhørende småbyer, Ta'anak med tilhørende småbyer, Megiddo med tilhørende småbyer og Dor med tilhørende småbyer. I disse byer bodde Josefs, Israels sønns barn. 
13:7:30: Asers sønner var Jimna og Jisva og Jisvi og Beria, og Serah var deres søster. 
13:7:31: Og Berias sønner var Heber og Malkiel; han var far til Birsot. 
13:7:32: Og Heber blev far til Jaflet og Somer og Hotam og deres søster Sua. 
13:7:33: Og Jaflets sønner var Pasak og Bimhal og Asvat; dette var Jaflets sønner. 
13:7:34: Og Semers sønner var Aki og Rohga, Jehubba og Aram. 
13:7:35: Og hans bror Helems sønner var Sofah og Jimna og Seles og Amal. 
13:7:36: Sofahs sønner var Suah og Harnefer og Sual og Beri og Jimra, 
13:7:37: Beser og Hod og Samma og Silsa og Jitran og Be'era. 
13:7:38: Og Jeters sønner var Jefunne og Pispa og Ara. 
13:7:39: Og Ullas sønner var Arah og Hanniel og Risja. 
13:7:40: Alle disse var sønner av Aser, hoder for sine familier, utvalgte veldige stridsmenn, høvdinger blandt fyrstene, og de menn som stod i deres ætteliste som dugelige til krigstjeneste, var seks og tyve tusen i tallet. 
13:8:1: Benjamin var far til Bela, som var hans førstefødte, Asbel, som var hans annen sønn, og Akrah, den tredje, 
13:8:2: Noka, den fjerde, og Rafa, den femte. 
13:8:3: Og Bela hadde sønnene Addar og Gera og Abihud 
13:8:4: og Abisua og Na'aman og Akoah 
13:8:5: og Gera og Sefufan og Huram. 
13:8:6: Og dette var Ehuds sønner, som var familiehoder blandt Gebas innbyggere, og som blev bortført til Manahat 
13:8:7: - det var Na'aman som sammen med Akia og Gera førte dem bort - han* fikk sønnene Ussa og Akihud. # <* Ehud.> 
13:8:8: Og Sahara'im fikk barn i Moabs land efterat han hadde sendt sine hustruer Husim og Ba'ara bort. 
13:8:9: Og med sin hustru Hodes fikk han Jobab og Sibja og Mesa og Malkam 
13:8:10: og Je'us og Sokja og Mirma; dette var hans sønner; de var familiehoder. 
13:8:11: Med Husim fikk han Abitub og Elpa'al. 
13:8:12: Og Elpa'als sønner var Eber og Mis'am og Semer - han bygget Ono og Lod med tilhørende byer - 
13:8:13: og Beria og Sema; de var familiehodene blandt Ajalons innbyggere; de drev Gats innbyggere på flukt. 
13:8:14: Ahjo, Sasak og Jeremot 
13:8:15: og Sebadia og Arad og Eder 
13:8:16: og Mikael og Jispa og Johavar Berias sønner; 
13:8:17: og Sebadja og Mesullam og Hiski og Heber 
13:8:18: og Jismerai og Jislia og Jobab har Elpa'als sønner. 
13:8:19: Og Jakim og Sikri og Sabdi 
13:8:20: og Elienai og Silletai og Eliel 
13:8:21: og Adaja og Beraja og Simrat var Sime'is sønner. 
13:8:22: Og Jispan og Eber og Eliel 
13:8:23: og Abdon og Sikri og Hanan 
13:8:24: og Hananja og Elam og Antotija 
13:8:25: og Jifdeja og Pniel var Sasaks sønner. 
13:8:26: Og Samserai og Seharja og Atalja 
13:8:27: og Ja'aresja og Elia og Sikri var Jerohams sønner. 
13:8:28: Alle disse var familiehoder, hoder for sine ætter; de bodde i Jerusalem. 
13:8:29: I Gibeon bodde Gibeons far*; hans hustru hette Ma'aka. # <* Je'uel, 1KR 9, 35.> 
13:8:30: Og hans førstefødte sønn var Abdon; så var det Sur og Kis og Ba'al og Nadab 
13:8:31: og Gedor og Ahjo og Seker. 
13:8:32: Og Miklot fikk sønnen Simea. Også disse bodde midt imot sine brødre i Jerusalem, sammen med sine brødre. 
13:8:33: Og Ner fikk sønnen Kis, og Kis var far til Saul, og Saul fikk sønnene Jonatan og Malkisua og Abinadab og Esba'al*. # <* d.s.s. Isboset, 2SA 2, 8.> 
13:8:34: Og Jonatans sønn var Meribba'al*, og Meribba'al fikk sønnen Mika. # <* d.s.s. Mefiboset, 2SA 4, 4.> 
13:8:35: Og Mikas sønner var Piton og Melek og Tarea og Akas. 
13:8:36: Og Akas fikk sønnen Joadda, og Joadda fikk Alemet og Asmavet og Simri, og Simri fikk Mosa. 
13:8:37: Og Mosa fikk sønnen Bina hans sønn var Rafa; hans sønn Elasa hans sønn Asel. 
13:8:38: Og Asel hadde seks sønner, og dette var deres navn: Asrikam, Bokeru og Ismael og Searja og Obadja og Hanan; alle disse var sønner av Asel. 
13:8:39: Og hans bror Eseks sønner var Ulam, hans førstefødte, Je'us, den annen, og Elifelet, den tredje. 
13:8:40: Ulams sønner var veldige stridsmenn, dyktige bueskyttere; de hadde mange sønner og sønnesønner - hundre og femti. Alle disse var av Benjamins barn. 
13:9:1: Alle Israels menn blev innført i ættelister og finnes opskrevet i Israels kongers bok; og Juda blev bortført i fangenskap til Babel for sin troløshets skyld. 
13:9:2: De tidligere innbyggere, som bodde på sin eiendom, i sine byer, var vanlige israelitter, prester, levitter og tempeltjenere. 
13:9:3: I Jerusalem bodde nogen av Judas barn og av Benjamins barn og av Efra'ims og Manasses barn: 
13:9:4: Utai, sønn av Ammihud, sønn av Omri, sønn av Imri, sønn av Bani, av Judas sønn Peres' barn; 
13:9:5: og av silonittene*: Asaja, den førstefødte, og hans sønner; # <* d.e. efterkommerne av Sela.> 
13:9:6: og av Serahs sønner: Je'uel og deres brødre, seks hundre og nitti i tallet; 
13:9:7: og av Benjamins barn: Sallu, sønn av Mesullam, sønn av Hodavja, sønn av Hassenua, 
13:9:8: og Jibneja, sønn av Jeroham, og Ela, sønn av Ussi, sønn av Mikri, og Mesullam, sønn av Sefatja, sønn av Re'uel, sønn av Jibnija, 
13:9:9: og deres brødre efter sine ætter, ni hundre og seks og femti i tallet; alle disse menn var overhoder for sine familier. 
13:9:10: Og av prestene: Jedaja og Jojarib og Jakin 
13:9:11: og Asarja, sønn av Hilkias, sønn av Mesullam, sønn av Sadok, sønn av Merajot, sønn av Akitub - han var forstander for Guds hus - 
13:9:12: og Adaja, sønn av Jeroham, sønn av Pashur, sønn av Malkia, og Masai, sønn av Adiel, sønn av Jahsera, sønn av Mesullam, sønn av Mesillemit, sønn av Immer, 
13:9:13: og deres brødre, overhoder for sine familier, tusen og syv hundre og seksti i tallet, dyktige menn i arbeidet ved tjenesten i Guds hus. 
13:9:14: Og av levittene: Semaja, sønn av Hassub, sønn av Asrikam, sønn av Hasabja, av Meraris barn, 
13:9:15: og Bakbakkar, Heres og Galal og Mattanja, sønn av Mika, sønn av Sikri, sønn av Asaf, 
13:9:16: og Obadja, sønn av Semaja, sønn av Galal, sønn av Jedutun, og Berekja, sønn av Asa, sønn av Elkana, som bodde i netofatittenes landsbyer. 
13:9:17: Og portnerne: Sallum og Akkub og Talmon og Akiman og deres brødre; Sallum var deres overhode, 
13:9:18: og like til nu står de* ved Kongeporten, mot øst. Dette var portnerne i Levis barns leire. # <* d.e. Sallums efterkommere.> 
13:9:19: Sallum, sønn av Kore, sønn av Ebjasaf, sønn av Korah, og hans brødre, de som var av hans familie, korahittene, hadde som sin tjenestegjerning å være voktere ved teltets dørtreskler, fordi deres fedre hadde vært voktere ved inngangen til Herrens leir. 
13:9:20: Og Pinehas, Eleasars sønn, hadde fordum vært deres forstander; Herren var med ham. 
13:9:21: Sakarja, Meselemjas sønn, var portner ved inngangen til sammenkomstens telt. 
13:9:22: Alle disse som var utvalgt til portnere ved dørtresklene, var to hundre og tolv i tallet; de var innført i ættelister efter sine landsbyer; David og seeren Samuel hadde innsatt dem som menn de hadde tillit til. 
13:9:23: De og deres sønner stod ved portene til Herrens hus, telthuset, og holdt vakt. 
13:9:24: Efter de fire verdenshjørner hadde portnerne sin plass: mot øst, vest, nord og syd. 
13:9:25: Og deres brødre, som bodde i sine landsbyer, skulde fra tid til annen komme og hjelpe dem for syv dager ad gangen. 
13:9:26: For de fire forstandere for portnerne var betrodde menn; de var levitter; de hadde også opsyn over kammerne og over skattkammerne i Guds hus. 
13:9:27: Om natten holdt de sig rundt omkring Guds hus; for det pålå dem å holde vakt, og de skulde lukke op portene hver morgen. 
13:9:28: Nogen av levittene hadde tilsyn med de redskaper som bruktes ved tjenesten; de tellet dem, både når de bar dem inn, og når de bar dem ut. 
13:9:29: Og nogen av dem var satt til å ha tilsyn med de andre redskaper - alle helligdommens redskaper - og med melet og vinen og oljen og viraken og krydderiene. 
13:9:30: Og nogen av prestenes sønner laget kryddersalven. 
13:9:31: Og Mattitja, en av levittene, korahitten Sallums førstefødte sønn, var betrodd å tillage bakverket. 
13:9:32: Og nogen av deres brødre kahatittenes barn hadde tilsyn med skuebrødene og skulde legge dem til rette hver sabbat. 
13:9:33: Dette* var sangerne, familiehoder blandt levittene; de holdt til i kammerne og var fri for annen tjeneste; for de var dag og natt optatt med sitt eget arbeid. # <* de 1KR 9, 14-16 nevnte.> 
13:9:34: Alle disse* var familiehoder blandt levittene efter sine ætter - overhoder; de bodde i Jerusalem. # <* de 1KR 9, 14-32 nevnte.> 
13:9:35: I Gibeon bodde Gibeons far Je'uel; hans hustru hette Ma'aka. 
13:9:36: Og hans førstefødte sønn var Abdon; så var det Sur og Kis og Ba'al og Ner og Nadab 
13:9:37: og Gedor og Ahjo og Sakarja og Miklot. 
13:9:38: Og Miklot fikk sønnen Simeam; også disse bodde midt imot sine brødre i Jerusalem, sammen med sine brødre. 
13:9:39: Og Ner fikk sønnen Kis, og Kis var far til Saul, og Saul fikk sønnene Jonatan og Malkisua og Abinadab og Esba'al. 
13:9:40: Og Jonatans sønn var Meribba'al, og Meribba'al fikk sønnen Mika. 
13:9:41: Og Mikas sønner var Piton og Melek og Tahrea. 
13:9:42: Og Akas fikk sønnen Jara, og Jara fikk Alemet og Asmavet og Simri, og Simri fikk Mosa. 
13:9:43: Og Mosa fikk sønnen Bina, og hans sønn var Refaja; hans sønn Elasa; hans sønn Asel. 
13:9:44: Og Asel hadde seks sønner, og dette var deres navn: Asrikam, Bokeru og Ismael og Searja og Obadja og Hanan; dette var Asels sønner. 
13:10:1: Filistrene stred mot Israel, og Israels menn flyktet for filistrene, og det lå mange falne på Gilboa-fjellet. 
13:10:2: Og filistrene var like i hælene på Saul og hans sønner, og de drepte Jonatan og Abinadab og Malkisua, Sauls sønner. 
13:10:3: Og striden blev hård der hvor Saul stod; og bueskytterne kom over ham, og han blev grepet av redsel for skytterne. 
13:10:4: Da sa Saul til sin våbensvenn: Dra ditt sverd og gjennembor mig med det, så ikke disse uomskårne skal komme og fare ille med mig! Men våbensvennen vilde ikke; han var for redd. Da tok Saul sitt sverd og styrtet sig i det. 
13:10:5: Og da våbensvennen så at Saul var død, styrtet også han sig i sitt sverd og døde. 
13:10:6: Således døde Saul og hans tre sønner og hele hans hus; de døde alle sammen. 
13:10:7: Og da alle israelittene i dalen så at de var flyktet, og at Saul og hans sønner var døde, forlot de sine byer og flyktet; og filistrene kom og bosatte sig i dem. 
13:10:8: Dagen efter kom filistrene for å plyndre de falne; da fant de Saul og hans sønner liggende på Gilboa-fjellet. 
13:10:9: De plyndret ham og tok hans hode og hans våben og sendte bud rundt omkring i filistrenes land for å forkynne det glade budskap for sine avguder og for folket. 
13:10:10: Og hans våben la de i sin guds hus, og hans hjerneskall hengte de op i Dagons hus. 
13:10:11: Da hele Jabes i Gilead fikk høre alt det filistrene hadde gjort med Saul, 
13:10:12: drog alle våbenføre menn avsted og tok Sauls og hans sønners kropper og førte dem til Jabes; og de begravde deres ben under terebinten i Jabes og holdt faste i syv dager. 
13:10:13: Således døde Saul for den troløshets skyld som han hadde vist mot Herren, fordi han ikke hadde holdt sig efter Herrens ord, og likeledes fordi han hadde søkt til dødningemanere for å spørre dem til råds. 
13:10:14: Men han spurte ikke Herren; derfor lot han ham dø og førte kongedømmet over til David, Isais sønn. 
13:11:1: Så kom hele Israel sammen hos David i Hebron og sa: Vi er jo av samme kjød og blod som du. 
13:11:2: Allerede for lenge siden, ennu mens Saul var konge, var det du som førte Israel ut og inn, og Herren din Gud sa til dig: Du skal vokte mitt folk Israel, og du skal være fyrste over mitt folk Israel. 
13:11:3: Og alle Israels eldste kom til kongen i Hebron, og David gjorde en pakt med dem i Hebron for Herrens åsyn; og de salvet David til konge over Israel efter Herrens ord ved Samuel. 
13:11:4: Så drog David og hele Israel til Jerusalem, det er Jebus; der bodde jebusittene, landets [oprinnelige] innbyggere. 
13:11:5: Og innbyggerne i Jebus sa til David: Du kommer aldri inn her. Men David inntok Sions borg - det er Davids stad. 
13:11:6: Og David sa: Den som først slår jebusittene, skal bli høvding og fører. Og Joab, Serujas sønn, steg først op og blev høvding. 
13:11:7: David tok sin bolig i borgen; derfor kaltes den Davids stad. 
13:11:8: Og han bygget byen rundt omkring, fra Millo og rundt omkring, og Joab bygget resten av byen op igjen. 
13:11:9: David blev større og større, og Herren, hærskarenes Gud, var med ham. 
13:11:10: Dette er de ypperste blandt Davids helter, som sammen med hele Israel kraftig støtet ham i hans kongedømme for å gjøre ham til konge efter Herrens ord om Israel. 
13:11:11: Dette er Davids helter, så mange de var: Jasobam, Hakmonis sønn, høvedsmann for drabantene; han svang sitt spyd over tre hundre falne på en gang. 
13:11:12: Efter ham kom Eleasar, sønn av ahohitten Dodo; han var en av de tre helter. 
13:11:13: Han var med David i Pas-Dammim da filistrene hadde samlet sig der til strid. Der var et jorde som var fullt av bygg, og da folket flyktet for filistrene, 
13:11:14: stilte de sig midt på jordet og berget det og slo filistrene; og Herren gav dem en stor seier. 
13:11:15: Engang drog tre av de tretti høvdinger ned efter berget til David, som var i hulen ved Adullam. Filistrenes hær lå da i leir i Refa'im dalen. 
13:11:16: David var dengang selv i borgen, og filistrenes forpost var i Betlehem. 
13:11:17: Så kom det en brå lyst over David, og han sa: Hvem lar mig få vann å drikke av den brønn som er ved porten i Betlehem? 
13:11:18: Da brøt de tre sig igjennem filistrenes leir og øste vann op av brønnen ved Betlehems port og tok det med sig og kom til David med det; men David vilde ikke drikke det - han øste det ut for Herren. 
13:11:19: Og han sa: Min Gud, la det være langt fra mig å gjøre dette! Skulde jeg drikke disse menns blod, som har satt sitt liv i fare? For med fare for sitt liv har de båret det hit. Og han vilde ikke drikke det. Dette gjorde de tre helter. 
13:11:20: Så var det Absai, bror til Joab; han var fører for de tre. Han svang sitt spyd over tre hundre falne; og han hadde et navn blandt de tre. 
13:11:21: Fremfor de tre var han dobbelt æret og blev deres høvding; men til de tre nådde han ikke op. 
13:11:22: Så var det Benaja, sønn av Jojada, som var sønn av en djerv seierrik stridsmann fra Kabse'el; han slo Moabs to løvehelter. En dag det hadde snedd, steg han ned i en brønn og slo en løve ihjel. 
13:11:23: Likeledes slo han ihjel en egypter, en mann som var fem alen lang; egypteren hadde et spyd som en veverstang i hånden; men han gikk ned mot ham med sin stav og rev spydet ut av hånden på egypteren og drepte ham med hans eget spyd. 
13:11:24: Dette gjorde Benaja, Jojadas sønn; han hadde et navn blandt de tre helter. 
13:11:25: Fremfor de tretti var han æret, men til de tre nådde han ikke op. David gav ham plass blandt sine fortrolige rådgivere. 
13:11:26: De djerveste i hæren var: Asael, bror til Joab; Elhanan, Dodos sønn, fra Betlehem; 
13:11:27: haroritten Sammot; pelonitten Heles; 
13:11:28: teko'itten Ira, sønn av Ikkes; anatotitten Abieser; 
13:11:29: husatitten Sibbekai; ahohitten Ilai; 
13:11:30: netofatitten Maharai; netofatitten Heled, sønn av Ba'ana; 
13:11:31: Itai, Ribais sønn, fra Gibea i Benjamins stamme; piratonitten Benaja; 
13:11:32: Hurai fra Ga'as-dalene; arbatitten Abiel; 
13:11:33: baharumitten Asmavet; sa'albonitten Eljahba; 
13:11:34: gisonitten Bene-Hasem; hararitten Jonatan, sønn av Sage; 
13:11:35: hararitten Akiam, sønn av Sakar; Elifal, sønn av Ur; 
13:11:36: mekeratitten Hefer; pelonitten Akia; 
13:11:37: karmelitten Hesro; Na'arai, sønn av Esbai; 
13:11:38: Joel, bror til Natan; Mibhar, sønn av Hagri; 
13:11:39: ammonitten Selek; berotitten Nahrai, Joabs, Serujas sønns våbensvenn; 
13:11:40: jitritten Ira; jitritten Gareb; 
13:11:41: hetitten Uria; Sabad, sønn av Ahlai; 
13:11:42: rubenitten Adina, sønn av Sisa, overhode for rubenittene, og foruten ham tretti; 
13:11:43: Hanan, sønn av Ma'aka, og mitnitten Josafat; 
13:11:44: asteratitten Ussia; Sama og Je'uel, sønner av aroeritten Hotam; 
13:11:45: Jediael, sønn av Simri, og hans bror Joha, tisitten; 
13:11:46: Eliel Hammahavim og Jeribai og Josavja, sønner av Elna'am, og moabitten Jitma; 
13:11:47: Eliel og Obed og Ja'asiel Hammesobaja. 
13:12:1: Dette er de som kom til David i Siklag mens han ennu måtte holde sig der av frykt for Saul, Kis' sønn. De hørte til de helter som hjalp ham i krigen; 
13:12:2: de var væbnet med bue og opøvd i å slynge stener og skyte piler med buen, både med høire og venstre hånd; de hørte til Sauls stammefrender, til benjaminittene. 
13:12:3: Det var Akieser, den øverste av dem, og Joas, sønner av gibeatitten Sema'a, og Jesuel og Pelet, sønner av Asmavet, og Beraka og anatotitten Jehu 
13:12:4: og gibeonitten Jismaja, en av de tretti helter og høvding over de tretti, og Jirmeja og Jahasiel og Johanan og gederatitten Josabad, 
13:12:5: Elusai og Jerimot og Bealja og Semarja og harusitten Sefatja, 
13:12:6: koharittene Elkana og Jissija og Asarel og Joeser og Jasobam, 
13:12:7: og Joela og Sebadja, sønner av Jeroham, fra Gedor. 
13:12:8: Og av gadittene gikk nogen over til David i fjellborgen i ørkenen, djerve helter, krigsvante stridsmenn væbnet med skjold og spyd; de var å se til som løver, og de var snare som rådyr på fjellene. 
13:12:9: Eser var den første, Obadja den annen, Eliab den tredje, 
13:12:10: Mismanna den fjerde, Jirmeja den femte, 
13:12:11: Attai den sjette, Eliel den syvende, 
13:12:12: Johanan den åttende, Elsabad den niende, 
13:12:13: Jirmeja den tiende, Makbannai den ellevte. 
13:12:14: Disse hørte til Gads barn og var høvedsmenn i hæren; den ringeste av dem var over hundre og den største over tusen. 
13:12:15: Det var disse som gikk over Jordan i den første måned, da elven gikk over alle sine bredder, og som drev alle dalboerne på flukt både mot øst og mot vest. 
13:12:16: Det kom også nogen av Benjamins og Judas barn til David, helt til fjellborgen. 
13:12:17: David gikk ut til dem og tok til orde og sa til dem: Kommer I til mig med fred og vil hjelpe mig, da skal mitt hjerte høre eder til, og vi skal holde sammen; men kommer I for å forråde mig til mine fiender, skjønt det ingen urett er i mine hender, da se vare fedres Gud dertil og straffe det! 
13:12:18: Da kom Ånden over Amasai, høvedsmannen for de tretti, og han sa: Dine er vi, David, og med dig holder vi fred, du Isais sønn! Fred være med dig, og fred være med dine hjelpere! For din Gud hjelper dig. Da tok David imot dem og satte dem til høvedsmenn for sin krigerflokk. 
13:12:19: Av Manasse gikk nogen over til David da han sammen med filistrene drog ut i strid mot Saul, uten at han dog kom til å hjelpe dem; for filistrenes høvdinger hadde holdt råd og så sendt ham bort, idet de sa: Det vil koste oss våre hoder, om han nu går over til sin herre Saul. 
13:12:20: Da han så drog til Siklag, gikk nogen av Manasse over til ham; det var Adna og Josabad og Jedial og Mikael og Josabad og Elihu og Silletai, høvedsmennene for de tusener som hørte til Manasse. 
13:12:21: Disse hjalp David mot røverflokken; for de var alle djerve stridsmenn, og de blev høvedsmenn i hæren. 
13:12:22: For dag efter dag kom det folk til David for å hjelpe ham, inntil det blev til en stor hær, som en Guds hær. 
13:12:23: Dette er tallet på de krigsvæbnede flokker som kom til David i Hebron for å føre Sauls kongedømme over til ham efter Herrens ord: 
13:12:24: Judas barn som bar skjold og spyd, seks tusen og åtte hundre, væbnet til strid; 
13:12:25: av Simeons barn djerve krigsvante stridsmenn, syv tusen og ett hundre; 
13:12:26: av Levis barn fire tusen og seks hundre, 
13:12:27: og dessuten Jojada, høvdingen for Arons ætt, og med ham tre tusen og syv hundre, 
13:12:28: og likeså Sadok, en ung djerv stridsmann med sin familie, to og tyve høvedsmenn; 
13:12:29: og av Benjamins barn, Sauls stammefrender, tre tusen; for inntil den tid hadde den største del av dem holdt sig til Sauls hus; 
13:12:30: og av Efra'ims barn tyve tusen og åtte hundre, djerve stridsmenn, navnkundige menn i sine familier; 
13:12:31: og av den halve Manasse stamme atten tusen navngitte menn, som var utvalgt til å dra avsted og gjøre David til konge; 
13:12:32: og av Issakars barn menn som forstod sig på tidene, så de visste hvad Israel hadde å gjøre; deres høvedsmenn var to hundre, og alle deres stammefrender rettet sig efter deres ord; 
13:12:33: av Sebulon menn som drog ut i strid og var rustet til krig med alle slags krigsvåben, femti tusen; de fylket sig til slag med hjerter uten svik; 
13:12:34: og av Naftaii tusen høvedsmenn og med dem syv og tretti tusen mann med skjold og spyd; 
13:12:35: og av danittene menn som var rustet til krig, åtte og tyve tusen og seks hundre; 
13:12:36: og av Aser menn som drog ut i strid for å fylke sig til slag, firti tusen; 
13:12:37: og fra hin side Jordan, av rubenittene og gadittene og den halve Manasse stamme, menn som var rustet med alle slags krigsvåben, hundre og tyve tusen. 
13:12:38: Alle disse krigsmenn kom i ordnet fylking til Hebron med opriktig hjerte for å gjøre David til konge over hele Israel; også hele resten av Israel var enig om å gjøre David til konge. 
13:12:39: Og de var der hos David i tre dager og åt og drakk; for deres landsmenn hadde laget til måltid for dem. 
13:12:40: Også de som bodde dem nærmest, like til Issakar og Sebulon og Naftali, kom med mat på asener og kameler og mulesler og okser: mel, fikenkaker og rosinkaker og vin og olje og storfe og småfe i mengde; for det var glede i Israel. 
13:13:1: Og David holdt råd med høvedsmennene over tusen og over hundre, med alle høvdingene. 
13:13:2: Og David sa til hele Israels menighet: Dersom det tykkes eder godt, og det kommer fra Herren vår Gud, så la oss sende bud til alle kanter til våre andre brødre i alle Israels land, og dessuten til prestene og levittene i de byer de har med jorder omkring, at de skal samle sig hos oss, 
13:13:3: og la oss så flytte vår Guds ark hit til oss! For i Sauls dager spurte vi ikke efter den. 
13:13:4: Da sa hele menigheten at så burde gjøres; for hele folket syntes det var rett. 
13:13:5: Så samlet David hele Israel fra Sihor* i Egypten til bortimot Hamat for å hente Guds ark fra Kirjat-Jearim. # <* JOS 13, 3.> 
13:13:6: Og David og hele Israel drog op til Ba'ala, til Kirjat-Jearim, som hører til Juda, for å føre op derfra Gud Herrens ark, han som troner på kjerubene, hvor hans navn nevnes. 
13:13:7: Og de satte Guds ark på en ny vogn og førte den bort fra Abinadabs hus, og Ussa og Ahjo kjørte vognen. 
13:13:8: Og David og hele Israel lekte for Guds åsyn av all makt både til sanger og citarer og harper og trommer og cymbler og trompeter. 
13:13:9: Da de kom til Kidons treskeplass, rakte Ussa sin hånd ut for å ta fatt i arken; for oksene var blitt ustyrlige. 
13:13:10: Da optendtes Herrens vrede mot Ussa, og han slo ham fordi han hadde rakt sin hånd ut mot arken, så han døde der for Guds åsyn. 
13:13:11: Men David blev ille til mote fordi Herren hadde slått Ussa ned; derfor er dette sted blitt kalt Peres-Ussa like til denne dag. 
13:13:12: Den dag blev David opfylt av frykt for Gud og sa: Hvorledes skulde jeg kunne føre Guds ark inn til mig? 
13:13:13: Og David flyttet ikke arken inn til sig i Davids stad, men lot den føre bort til gititten Obed-Edoms hus. 
13:13:14: Guds ark blev stående hos Obed-Edoms familie i hans hus i tre måneder; og Herren velsignet Obed-Edoms hus og alt hvad hans var. 
13:14:1: Kongen i Tyrus Hiram skikket sendemenn til David med sedertre, og han sendte stenhuggere og tømmermenn til å bygge et hus for ham. 
13:14:2: Og David forstod at Herren hadde stadfestet hans kongedømme over Israel; for hans rike var blitt hevet høit for hans folk Israels skyld. 
13:14:3: David tok ennu flere hustruer i Jerusalem, og han fikk ennu flere sønner og døtre. 
13:14:4: Dette er navnene på de sønner han fikk i Jerusalem: Sammua og Sobab, Natan og Salomo 
13:14:5: og Jibhar og Elisua og Elpelet 
13:14:6: og Nogah og Nefeg og Jafia 
13:14:7: og Elisama og Be'eljada og Elifelet. 
13:14:8: Da filistrene hørte at David var salvet til konge over hele Israel, drog alle filistrene ut for å søke efter David; og da David hørte det, drog han ut imot dem. 
13:14:9: Og filistrene kom og spredte sig utover i Refa'im-dalen. 
13:14:10: Da spurte David Gud: Skal jeg dra ut mot filistrene, og vil du gi dem i min hånd? Og Herren svarte: Dra ut! Så vil jeg gi dem i din hånd. 
13:14:11: Så drog de op til Ba'al-Perasim, og der slo David dem, og David sa: Gud har brutt igjennem mine fiender ved min hånd, som vannene bryter igjennem. Derfor blev dette sted kalt Ba'al-Perasim. 
13:14:12: Der lot de efter sig sine guder, og David bød at de skulde brennes op med ild. 
13:14:13: Men filistrene kom igjen og spredte sig utover i dalen. 
13:14:14: Og David spurte atter Gud, og Gud sa til ham: Du skal ikke dra op efter dem; vend dig fra dem og ta en omvei, så du kommer over dem midt for bakatrærne, 
13:14:15: og når du hører lyden av skritt i bakatrærnes topper, da skal du dra ut i striden; for Gud har draget ut foran dig for å slå filistrenes hær. 
13:14:16: David gjorde som Gud hadde befalt ham, og de slo filistrenes hær og forfulgte dem fra Gibeon og like til Geser. 
13:14:17: og Davids navn kom ut i alle landene, og Herren lot frykt for ham komme over alle folkene. 
13:15:1: Siden bygget han sig huser i Davids stad og gjorde i stand et sted for Guds ark og reiste et telt for den. 
13:15:2: Da sa David: Ingen skal bære Guds ark uten levittene; for dem har Herren utvalgt til å bære Guds ark og til å tjene ham for alle tider. 
13:15:3: Så samlet David hele Israel til Jerusalem for å føre Herrens ark op til det sted som han hadde gjort i stand for den. 
13:15:4: Og David kalte Arons barn og levittene sammen: 
13:15:5: av Kahats barn Uriel, den øverste, og hans brødre, hundre og tyve, 
13:15:6: av Meraris barn Asaja, den øverste, og hans brødre, to hundre og tyve, 
13:15:7: av Gersoms barn Joel, den øverste, og hans brødre, hundre og tretti, 
13:15:8: av Elisafans barn Semaja, den øverste, og hans brødre, to hundre, 
13:15:9: av Hebrons barn Eliel, den øverste, og hans brødre, åtti. 
13:15:10: av Ussiels barn Amminadab, den øverste, og hans brødre, hundre og tolv. 
13:15:11: Så kalte David til sig prestene Sadok og Ebjatar og levittene Uriel, Asaja og Joel, Semaja og Eliel og Amminadab. 
13:15:12: Og han sa til dem: I familiehoder for levittene, hellige eder, både I og eders brødre, og før Herrens, Israels Guds ark op til det sted jeg har gjort i stand for den! 
13:15:13: Det var fordi I ikke var med første gang at Herren vår Gud brøt inn iblandt oss; for vi søkte ham ikke på rette måte. 
13:15:14: Da helliget prestene og levittene sig for å føre Herrens. Israels Guds ark op. 
13:15:15: Og levittenes barn bar Guds ark på sine skuldrer ved hjelp av bærestengene, således som Moses hadde påbudt efter Herrens ord. 
13:15:16: Så bød David de øverste blandt levittene at de skulde stille sine brødre sangerne frem med musikkinstrumenter, harper og citarer og cymbler, som de skulde spille på, idet de lot gledesangen tone. 
13:15:17: Da stilte levittene frem Heman, sønn av Joel, og av hans brødre Asaf, sønn av Berekja, og av deres brødre Meraris barn Etan, sønn av Kusaja, 
13:15:18: og sammen med dem deres brødre av annen rang, Sakarja, Ben og Ja'asiel og Semiramot og Jehiel og Unni, Eliab og Benaja og Ma'aseja og Mattitja og Eliflehu og Mikneja og Obed-Edom og Je'iel, dørvokterne. 
13:15:19: Sangerne Heman, Asaf og Etan skulde slå på kobbercymbler; 
13:15:20: Sakarja og Asiel og Semiramot og Jehiel og Unni og Eliab og Ma'aseja og Benaja skulde spille på harper efter Alamot*, # <* sannsynligvis en toneart, SLM 46, 1.> 
13:15:21: og Mattitja og Eliflehu og Mikneja og Obed-Edom og Je'iel og Asasja på citarer efter Sjeminit* for å lede sangen. # <* sannsynligvis en toneart, SLM 6, 1.> 
13:15:22: Kenanja, levittenes sangmester, underviste i sangen; for han var kyndig i det. 
13:15:23: Berekja og Elkana var dørvoktere ved arken. 
13:15:24: Prestene Sebaina og Josafat og Netanel og Amasai og Sakarja og Benaja og Elieser blåste i trompeter foran Guds ark, og Obed-Edom og Jehia var dørvoktere ved arken. 
13:15:25: Så gikk David og Israels eldste og høvedsmennene over tusen avsted for å hente Herrens pakts-ark op fra Obed-Edoms hus under jubel. 
13:15:26: Og da nu Gud hjalp levittene som bar Herrens pakts-ark, ofret de syv okser og syv værer. 
13:15:27: David var klædd i en kåpe av hvitt bomullstøi, og likeså alle de levitter som bar arken, og sangerne og Kenanja, sangmesteren, som ledet sangen; dessuten hadde David en livkjortel av lerret på. 
13:15:28: Og hele Israel førte Herrens pakts-ark op med fryderop og med basunklang og med trompeter og cymbler, under harpe- og citarspill. 
13:15:29: Men da Herrens pakts-ark kom inn i Davids stad, da så Mikal, Sauls datter, ut gjennem vinduet, og hun så kong David hoppe og danse, og hun foraktet ham i sitt hjerte. 
13:16:1: Så førte de Guds ark inn og satte den midt i det telt som David hadde reist for den, og de bar frem brennoffer og takkoffer for Guds åsyn. 
13:16:2: Og da David var ferdig med å ofre brennofferet og takkofferne, velsignet han folket i Herrens navn. 
13:16:3: Og han utdelte til hver enkelt i Israel, både mann og kvinne, et brød og et stykke kjøtt og en rosinkake. 
13:16:4: Og han satte nogen av levittene til å gjøre tjeneste foran Herrens ark og til å prise og takke og love Herren, Israels Gud: 
13:16:5: Asaf var den øverste, næst efter ham kom Sakarja og så Je'iel og Semiramot og Jehiel og Mattitja og Eliab og Benaja og Obed-Edom og Je'iel med harper og citarer; Asaf skulde slå på cymblene 
13:16:6: og prestene Benaja og Jahasiel stadig blåse i trompetene foran Guds pakts-ark. 
13:16:7: Den dag satte David første gang Asaf og hans brødre til å synge Herrens pris: 
13:16:8: Pris Herren, påkall hans navn, kunngjør blandt folkene hans store gjerninger! 
13:16:9: Syng for ham, lovsyng ham, grund på alle hans undergjerninger! 
13:16:10: Ros eder av hans hellige navn! Deres hjerte glede sig som søker Herren! 
13:16:11: Spør efter Herren og hans makt, søk hans åsyn all tid! 
13:16:12: Kom i hu hans undergjerninger som han har gjort, hans under og hans munns dommer, 
13:16:13: I, hans tjener Israels avkom, Jakobs barn, hans utvalgte! 
13:16:14: Han er Herren vår Gud; hans dommer er over all jorden. 
13:16:15: Kom evindelig hans pakt i hu, det ord han fastsatte for tusen slekter, 
13:16:16: den pakt han gjorde med Abraham, og hans ed til Isak! 
13:16:17: Han stadfestet den som en rett for Jakob, som en evig pakt for Israel, 
13:16:18: idet han sa: Dig vil jeg gi Kana'ans land til arvelodd, 
13:16:19: da I var en liten flokk, få og fremmede der. 
13:16:20: Og de vandret fra folk til folk og fra et rike til et annet folk. 
13:16:21: Han tillot ikke nogen å gjøre vold imot dem, og han straffet konger for deres skyld: 
13:16:22: Rør ikke ved mine salvede, og gjør ikke mine profeter noget ondt! 
13:16:23: Syng for Herren, all jorden! Forkynn fra dag til dag hans frelse! 
13:16:24: Fortell blandt hedningene hans ære, blandt alle folkene hans undergjerninger! 
13:16:25: For stor er Herren og høilovet, og forferdelig er han over alle guder. 
13:16:26: For alle folkenes guder er intet; men Herren har gjort himmelen. 
13:16:27: Høihet og herlighet er for hans åsyn, styrke og glede er på hans sted. 
13:16:28: Gi Herren, I folkeslekter, gi Herren ære og makt! 
13:16:29: Gi Herren hans navns ære, bær frem gaver og kom for hans åsyn, tilbed Herren i hellig prydelse! 
13:16:30: Bev for hans åsyn, all jorden! Jorderike står fast, det rokkes ikke. 
13:16:31: Himmelen glede sig, og jorden fryde Sig, og de skal si iblandt hedningene: Herren er blitt konge. 
13:16:32: Havet bruse og alt som fyller det! Marken fryde sig og alt som er på den! 
13:16:33: Da jubler trærne i skogen for Herrens åsyn; for han kommer for å dømme jorden. 
13:16:34: Lov Herren! For han er god, hans miskunnhet varer evindelig. 
13:16:35: Og si: Frels oss, du vår frelses Gud, og samle oss og utfri oss fra hedningene til å love ditt hellige navn, rose oss av å kunne prise dig! 
13:16:36: Lovet være Herren, Israels Gud, fra evighet og til evighet! Og alt folket sa amen og lovet Herren. 
13:16:37: Så lot han Asaf og hans brødre bli der foran Herrens pakts-ark for stadig å gjøre tjeneste foran arken, hver dag det som skulde gjøres på den dag, 
13:16:38: og Obed-Edom og deres brødre, åtte og seksti, Obed-Edom, sønn av Jeditun, og Hosa som dørvoktere. 
13:16:39: Men presten Sadok og hans brødre prestene lot han bli foran Herrens tabernakel på haugen i Gibeon. 
13:16:40: forat de stadig skulde ofre Herren brennoffer på brennofferalteret morgen og aften og gjøre alt det som skrevet står i den lov som Herren hadde gitt Israel, 
13:16:41: og sammen med dem Heman og Jedutun og de andre utvalgte, som var nevnt ved navn, forat de skulde love Herren, fordi hans miskunnhet varer evindelig, 
13:16:42: og hos dem - Heman og Jedutun - var der trompeter og cymbler til bruk for dem som skulde spille, og likeledes andre instrumenter til sangen ved gudstjenesten. Og Jedutuns sønner var dørvoktere. 
13:16:43: Derefter drog alt folket hver til sitt, og David vendte hjem for å velsigne sitt hus. 
13:17:1: Som nu David bodde i sitt hus, sa han til profeten Natan: Se, jeg bor i et hus av sedertre mens Herrens pakts-ark bor under telttepper. 
13:17:2: Natan sa til David: Gjør du alt det du har i sinne! For Gud er med dig. 
13:17:3: Men samme natt kom Guds ord til Natan, og det lød således: 
13:17:4: Gå og si til min tjener David: Så sier Herren: Ikke skal du bygge mig det hus jeg skal bo i. 
13:17:5: Jeg har jo ikke bodd i hus fra den dag jeg førte Israel op, og til denne dag, men jeg vandret fra telt til telt og fra tabernakel til tabernakel*. # <* idet tabernaklet blev flyttet fra et sted til et annet.> 
13:17:6: Hvor jeg så vandret om i hele Israel, har jeg vel nogensinne talt således til nogen av Israels dommere, som jeg satte til å vokte mitt folk: Hvorfor har I ikke bygget mig et hus av sedertre? 
13:17:7: Og nu skal du si så til min tjener David: Så sier Herren, hærskarenes Gud: Jeg tok dig fra havnegangen, hvor du gikk bak fårene, forat du skulde være fyrste over mitt folk Israel. 
13:17:8: Og jeg var med dig overalt hvor du gikk, og utryddet alle dine fiender for dig, og jeg har latt dig vinne et navn så stort som de størstes på jorden. 
13:17:9: Og jeg har gjort i stand et bosted for mitt folk Israel, og jeg har plantet det der, så det bor i sitt hjem og ikke uroes mere, og urettferdige mennesker ikke herjer det lenger som før, 
13:17:10: like fra den tid jeg satte dommere over mitt folk Israel, og jeg har ydmyket alle dine fiender. Og nu forkynner jeg dig at Herren vil bygge dig et hus. 
13:17:11: Når dine dagers tall er fullt, og du går til dine fedre, da vil jeg efter dig opreise din ætt, en av dine sønner, og jeg vil grunnfeste hans kongedømme. 
13:17:12: Han skal bygge mig et hus, og jeg vil trygge hans trone til evig tid. 
13:17:13: Jeg vil være hans far, og han skal være min sønn, og min miskunnhet vil jeg ikke la vike fra ham, således som jeg lot den vike fra ham som var før dig, 
13:17:14: men jeg vil la ham bli i mitt hus og i mitt rike til evig tid, og hans trone skal være grunnfestet til evig tid. 
13:17:15: Alle disse ord og hele dette syn bar Natan frem for David. 
13:17:16: Da gikk kong David inn og blev der inne for Herrens åsyn, og han sa: Hvem er jeg, Herre Gud, og hvad er mitt hus, at du har ført mig hertil? 
13:17:17: Og enda var dette for lite dine øine, Gud, og du talte om din tjeners hus langt frem i tiden, og du har sett til mig på menneskers vis, sa du kunde ophøie mig, Herre Gud! 
13:17:18: Hvad skal så David mere si til dig om den ære du har vist din tjener? Du kjenner jo din tjener. 
13:17:19: Herre! For din tjeners skyld og efter ditt hjerte har du gjort alt dette store og forkynt alle disse store ting. 
13:17:20: Herre! Det er ingen som du, og det er ingen Gud foruten dig, efter alt det vi har hørt med våre ører. 
13:17:21: Og hvor er det på jorden et eneste folk som ditt folk Israel, et folk som Gud kom og fridde ut, så det skulde være hans eget folk, for å gjøre dig et navn ved store og forferdelige gjerninger, ved å drive ut hedningefolk for ditt folks skyld, som du fridde ut fra Egypten? 
13:17:22: Og du satte ditt folk Israel til å være ditt folk til evig tid, og du, Herre, er blitt deres Gud. 
13:17:23: Så la nu, Herre, det ord du har talt om din tjener og om hans hus, stå fast til evig tid, og gjør som du har sagt! 
13:17:24: Ja, la det stå fast! Da skal ditt navn bli stort til evig tid, så folk skal si: Herren, hærskarenes Gud, Israels Gud, er Gud for Israel! Og din tjener Davids hus skal være grunnfestet for ditt åsyn. 
13:17:25: For du, min Gud, har åpenbaret for din tjener at du vil bygge ham et hus. Derfor har din tjener fått frimodighet til å bede for ditt åsyn. 
13:17:26: Og nu, Herre, du er Gud, og du har lovt din tjener denne lykke. 
13:17:27: Så la det nu behage dig å velsigne din tjeners hus, så det må bli stående for ditt åsyn til evig tid! For det som du, Herre, velsigner, er velsignet i all evighet. 
13:18:1: Derefter slo David filistrene og ydmyket dem, og han tok Gat med tilhørende småbyer ut av filistrenes hender. 
13:18:2: Han slo også moabittene, og moabittene blev Davids tjenere og måtte svare ham skatt. 
13:18:3: Likeledes slo David kongen i Soba Hadareser ved Hamat, da han drog avsted for å styrke sitt herredømme ved Frat-elven. 
13:18:4: Og David tok fra ham tusen vognhester og syv tusen hestfolk og tyve tusen mann fotfolk; og David lot skjære fotsenene over på alle vognhestene; bare hundre vognhester sparte han. 
13:18:5: Syrerne fra Damaskus kom for å hjelpe Hadareser, kongen i Soba; men David slo to og tyve tusen mann av dem. 
13:18:6: Og David la krigsmannskap i det damaskenske Syria, og syrerne blev Davids tjenere og måtte svare ham skatt. Således hjalp Herren David overalt hvor han drog frem. 
13:18:7: David tok de gullskjold som Hadaresers tjenere hadde båret, og førte dem til Jerusalem. 
13:18:8: Og fra Hadaresers byer Tibhat og Kun tok David en stor mengde kobber; av det gjorde Salomo kobberhavet og søilene og kobberkarene. 
13:18:9: Da kongen i Hamat To'u hørte at David hadde slått hele Soba-kongen Hadaresers hær, 
13:18:10: sendte han sin sønn Hadoram til kong David for å hilse på ham og ønske ham til lykke, fordi han hadde stridt mot Hadareser og slått ham; for Hadareser hadde jevnlig ført krig mot To'u. Han sendte også alle slags gullkar og sølvkar og kobberkar. 
13:18:11: Også dem helliget kong David til Herren sammen med det sølv og gull han hadde tatt fra alle hedningefolkene: fra Edom og fra Moab og fra Ammons barn og fra filistrene og fra amalekittene. 
13:18:12: Det var Absai, Serujas sønn, som slo edomittene i Saltdalen, atten tusen mann. 
13:18:13: Så la han krigsmannskap i Edom, og alle edomittene blev Davids tjenere. Således hjalp Herren David overalt hvor han drog frem. 
13:18:14: Så regjerte nu David over hele Israel, og han gjorde rett og rettferdighet mot hele sitt folk. 
13:18:15: Joab, Serujas sønn, var høvding over hæren, og Josafat, Akiluds sønn, historieskriver; 
13:18:16: Sadok, Akitubs sønn, og Abimelek, Ebjatars sønn, var prester og Savsa statsskriver; 
13:18:17: Benaja, Jojadas sønn, var høvding over livvakten, og Davids sønner var de første ved kongens side. 
13:19:1: Så hendte det at Nahas, Ammons barns konge, døde, og hans sønn blev konge i hans sted. 
13:19:2: Da sa David: Jeg vil vise Hanun, Nahas' sønn, vennskap, for hans far viste mig vennskap. Og David sendte bud for å trøste ham i hans sorg over faren. Så kom da Davids tjenere til Ammons barns land, til Hanun for å trøste ham. 
13:19:3: Da sa Ammons barns høvdinger til Hanun: Tror du det er for å ære din far at David har sendt folk som skal trøste dig? Mon det ikke heller er for å utforske og ødelegge og utspeide landet at hans tjenere er kommet til dig? 
13:19:4: Da tok Hanun Davids tjenere og lot dem rake og skar av deres klær midt på like til setet og lot dem så fare. 
13:19:5: Og det kom nogen og meldte David hvad som hadde hendt hans menn; da sendte han folk som skulde møte dem; for de var grovelig vanæret; og kongen lot si: Bli i Jeriko til eders skjegg er vokset ut igjen, og kom så tilbake! 
13:19:6: Da nu Ammons barn så at de hadde gjort sig forhatt hos David, sendte Hanun og Ammons barn tusen talenter sølv for å leie sig vogner og hestfolk fra Mesopotamia og fra syrerne i Ma'aka og fra Soba. 
13:19:7: Så leide de sig da to og tretti tusen vogner og kongen i Ma'aka og hans folk, og de kom og leiret sig foran Medba; men også Ammons barn samlet sig fra sine byer og drog ut til strid. 
13:19:8: Da David hørte dette, sendte han Joab avsted med hele hæren, de krigsvante menn. 
13:19:9: Og Ammons barn rykket ut og stilte sig i fylking foran inngangen til byen; men kongene som var kommet dit, stod for sig selv på marken. 
13:19:10: Da Joab så at han hadde fienden mot sig både foran og bak, valgte han ut en del av alt det utvalgte mannskap i Israel og stilte dem op mot syrerne. 
13:19:11: Men resten av folket overgav han til sin bror Absai, og de stilte sig i fylking mot Ammons barn. 
13:19:12: Og han sa: Dersom syrerne blir mig for sterke, skal du komme mig til hjelp, og dersom Ammons barn blir dig for sterke, skal jeg hjelpe dig. 
13:19:13: Vær modig, og la oss vise oss modige for vårt folk og for var Guds byer, så lar Herren gjøre hvad. godt er i hans øine! 
13:19:14: Så rykket Joab frem med sine folk til strid mot syrerne, og de flyktet for ham. 
13:19:15: Og da Ammons barn så at syrerne flyktet, så flyktet også de for hans bror Absai og drog sig inn i byen. Da drog Joab tilbake til Jerusalem. 
13:19:16: Da syrerne så at de var blitt slått av Israel, sendte de bud at de syrere som bodde på hin side av elven*, skulde dra ut; og Sofak, høvdingen over Hadaresers hær, førte dem. # <* Eufrat.> 
13:19:17: Da dette blev meldt David, samlet han hele Israel og gikk over Jordan, og da han kom frem til dem, stilte han sig op imot dem - han fylket sin hær mot syrerne, og de stred mot ham. 
13:19:18: Men syrerne flyktet for Israel, og David drepte blandt syrerne mannskapet på syv tusen vogner og firti tusen mann fotfolk; også hærføreren Sofak drepte han. 
13:19:19: Da Hadaresers menn så at de var blitt slått av Israel, gjorde de fred med David og blev hans tjenere; og syrerne vilde ikke mere hjelpe Ammons barn. 
13:20:1: Året efter, ved den tid da kongene pleier å dra ut i krig, tok Joab ut med krigshæren og herjet Ammons barns land, og da han kom til Rabba, kringsatte han det; men David blev i Jerusalem; og Joab tok Rabba og ødela byen. 
13:20:2: Og David tok deres konges krone fra hans hode; det viste sig at den veide en talent gull, og der var på den en kostbar sten; nu kom den på Davids hode. Og det store hærfang han hadde tatt i byen, førte han med sig bort. 
13:20:3: Og folket som bodde der, førte han ut og skar dem over med sager og treskesleder av jern eller hugg dem sønder med økser. Så gjorde David med alle Ammons barns byer. Derefter vendte David og alt folket tilbake til Jerusalem. 
13:20:4: Siden kom det til et slag med filistrene ved Geser; da slo husatitten Sibbekai Sippai, som hørte til Rafas barn, og de blev ydmyket. 
13:20:5: Så kom det atter til strid med filistrene; og Elhanan, Ja'urs sønn, slo Lahmi, en bror av gittitten Goliat, som hadde et spyd hvis skaft var som en veverstang. 
13:20:6: Ennu en gang kom det til strid ved Gat. Der var en høivoksen mann, som hadde seks fingrer på hver hånd og seks tær på hver fot - i alt fire og tyve fingrer og tær; han var og en efterkommer av Rafa. 
13:20:7: Han hånte Israel; men Jonatan, sønn av Davids bror Simea, hugg ham ned. 
13:20:8: Disse var efterkommere av Rafa i Gat, og de falt for Davids og hans menns hånd. 
13:21:1: Men Satan stod op imot Israel og egget David til å telle Israel. 
13:21:2: Da sa David til Joab og folkets høvedsmenn: Gå avsted og tell Israel fra Be'erseba like til Dan og kom så til mig med melding, så jeg kan få vite tallet på dem. 
13:21:3: Da sa Joab: Herren legge hundre ganger så mange til sitt folk som de er nu! Er de ikke, herre konge, alle sammen min herres tjenere? Hvorfor krever min herre dette? Hvorfor skal det bli til skyld for Israel? 
13:21:4: Men kongen holdt fast ved sin befaling og gav ikke efter for Joab. Så tok da Joab ut og drog omkring i hele Israel og kom så tilbake til Jerusalem. 
13:21:5: Og Joab opgav for David det tall som var utkommet ved folkemønstringen. I hele Israel var det elleve hundre tusen mann som kunde dra sverd, og i Juda fire hundre og sytti tusen. 
13:21:6: Men Levi og Benjamin hadde han ikke mønstret sammen med de andre; for kongens ord var en vederstyggelighet for Joab. 
13:21:7: Det som var skjedd, var ondt i Guds øine, og han slo Israel. 
13:21:8: Da sa David til Gud: Jeg har syndet storlig ved å gjøre dette; men tilgi nu din tjeners misgjerning, for jeg har båret mig meget uforstandig at. 
13:21:9: Men Herren talte til Gad, Davids seer, og sa: 
13:21:10: Gå og tal til David og si: Så sier Herren: Tre ting forelegger jeg dig; velg en av dem, sa vil jeg gjøre så mot dig! 
13:21:11: Da kom Gad til David og sa til ham: Så sier Herren: Velg nu 
13:21:12: enten hungersnød i tre år eller å ligge under for dine motstandere i tre måneder, så du ikke kan undfly dine fienders sverd, eller at Herrens sverd og pest skal komme over landet i tre dager, og Herrens engel skal volde ødeleggelse i hele Israels landemerke! Tenk nu efter hvad jeg skal svare ham som har sendt mig! 
13:21:13: David sa til Gad: Jeg er i stor angst; la mig da helst falle i Herrens hånd! For hans barmhjertighet er stor, men i menneskers hånd vil jeg nødig falle. 
13:21:14: Så lot Herren det komme en pest i Israel, og det falt sytti tusen mann av Israel. 
13:21:15: Og Gud sendte en engel til Jerusalem for å ødelegge det. Men da han holdt på med å ødelegge, så Herren det, og han angret det onde og sa til engelen som gjorde ødeleggelse: Det er nok; dra nu din hånd tilbake! Herrens engel stod da ved jebusitten Ornans treskeplass. 
13:21:16: Som nu David så op, fikk han se Herrens engel, som stod mellem jorden og himmelen med draget sverd i sin hånd og rakte det ut mot Jerusalem. Da falt David og de eldste ned på sitt ansikt, klædd i sekk. 
13:21:17: Og David sa til Gud: Var det ikke jeg som bød at folket skulde telles? Det er jeg som har syndet og gjort ille; men denne min hjord, hvad har de gjort? Herre min Gud, la heller din hånd komme over mig og over min fars hus, men ikke over ditt folk, så det blir hjemsøkt! 
13:21:18: Da bød Herrens engel Gad å si til David at han skulde gå op og reise et alter for Herren på jebusitten Ornans treskeplass. 
13:21:19: Så gikk David dit op efter det ord som Gad hadde talt i Herrens navn. 
13:21:20: Da nu Ornan vendte sig om, fikk han se engelen, og hans fire sønner, som var med ham, skjulte sig; Ornan holdt just på med å treske hvete. 
13:21:21: Da David kom til Ornan, så Ornan ut og fikk se David; så gikk han ut av treskeplassen og kastet sig ned for David med ansiktet mot orden. 
13:21:22: Og David sa til Ornan: La mig få det sted hvor du har din treskeplass, så jeg kan bygge et alter for Herren der! La mig få det for dets fulle pris, forat hjemsøkelsen kan stanse og vike fra folket! 
13:21:23: Da sa Ornan til David: Ta det bare! Min herre kongen må gjøre hvad han finner for godt! Her gir jeg dig oksene til brennofferne og treskesledene til ved og hveten til matofferet; alt sammen gir jeg som gave. 
13:21:24: Men kong David svarte: Nei, jeg vil kjøpe det for full pris; jeg vil ikke ta til Herren det som ditt er, og ofre brennoffere som jeg har fått for intet. 
13:21:25: Så gav David Ornan for stedet seks hundre sekel gull efter vekt. 
13:21:26: Og David bygget der et alter for Herren og ofret brennoffere og takkoffere; og han ropte til Herren, og han svarte ham med ild fra himmelen på brennoffer-alteret. 
13:21:27: Og Herren bød engelen at han skulde stikke sitt sverd i skjeden igjen. 
13:21:28: Da David så at Herren hadde bønnhørt ham på jebusitten Ornans treskeplass, så ofret han der på den tid. 
13:21:29: Herrens tabernakel, som Moses hadde latt gjøre i ørkenen, og brennoffer-alteret stod dengang på haugen i Gibeon; 
13:21:30: men David torde ikke gå ditt for å søke Gud; så forferdet var han for Herrens engels sverd. 
13:22:1: Og David sa: Her skal Herren Guds hus stå, og her skal brennofferalteret for Israel være. 
13:22:2: Og David bød at de fremmede som bodde i Israels land, skulde kalles sammen, og han satte stenhuggere til å hugge til stenene som Guds hus skulde bygges av. 
13:22:3: Og David samlet jern i mengde til nagler i portdørene og til kramper og en slik mengde kobber at det ikke kunde veies, 
13:22:4: og så meget sedertre at det ikke var tall på det; for sidonierne og tyrierne førte sedertre i mengde til David. 
13:22:5: For David tenkte: Min sønn Salomo er ung og vek, og huset som skal bygges for Herren, må gjøres overmåte stort, så det kan være til pris og ære for ham i alle land; jeg vil derfor samle forråd for ham! Så samlet David forråd i mengde, før han døde. 
13:22:6: Og han kalte sin sønn Salomo til sig og bød ham bygge et hus for Herren, Israels Gud. 
13:22:7: Og David sa til sin sønn Salomo: Jeg hadde i sinne å bygge et hus for Herrens, min Guds navn. 
13:22:8: Men Herrens ord kom til mig, og det lød så: Du har utøst meget blod og ført store kriger; du skal ikke bygge et hus for mitt navn, for du har utøst meget blod på jorden for mitt åsyn. 
13:22:9: Men du skal få en sønn; han skal være en fredens mann, og jeg vil la ham få fred for alle sine fiender rundt omkring; for Salomo* skal være hans navn, og jeg vil la Israel ha fred og ro i hans dager. # <* d.e. fredsommelig.> 
13:22:10: Han skal bygge et hus for mitt navn, og han skal være min sønn, og jeg skal være hans far, og jeg skal trygge hans kongetrone over Israel for all tid. 
13:22:11: Så være nu Herren med dig, min sønn, og vær lykkelig og bygg Herrens, din Guds hus, således som han har lovt om dig! 
13:22:12: Bare Herren vil gi dig visdom og forstand og sette dig over Israel, og du må holde Herrens, din Guds lov. 
13:22:13: Da skal du ha lykke med dig, når du akter vel på å gjøre efter de bud og lover som Herren bød Moses å pålegge Israel; vær frimodig og sterk, frykt ikke og reddes ikke! 
13:22:14: Her har jeg med megen møie samlet hundre tusen talenter gull og tusen ganger tusen talenter sølv til Herrens hus, dessuten mere kobber og jern enn at det kan veies; så meget er det; tre og sten har jeg og samlet, og mere kan du selv legge til. 
13:22:15: Arbeidere har du også i mengde, stenhuggere og murere og tømmermenn og dessuten alle slags folk som er kyndige i alle andre slags arbeid. 
13:22:16: Av gull, sølv, kobber og jern er det så meget at det ikke er tall på det. Så ta da fatt på verket, og Herren være med dig! 
13:22:17: Og David bød alle Israels høvdinger å hjelpe Salomo, hans sønn, og sa: 
13:22:18: Herren eders Gud er jo med eder og har gitt eder ro på alle kanter. For han har gitt landets innbyggere i min hånd, og landet er lagt under Herren og hans folk. 
13:22:19: Vend nu eders hjerte og sjel til å søke Herren eders Gud, og gjør eder rede og bygg Gud Herrens helligdom, så I kan føre Herrens pakts-ark og Guds hellige kar inn i det hus som skal bygges for Herrens navn! 
13:23:1: Da David var blitt gammel og mett av dager, gjorde han sin sønn Salomo til konge over Israel. 
13:23:2: Og han samlet alle Israels høvdinger og prestene og levittene. 
13:23:3: Og levittene blev tellet fra tyveårsalderen og opover, og tallet på dem - en for en, alle som var av mannkjønn - var åtte og tretti tusen. 
13:23:4: Av disse [sa David] skal fire og tyve tusen forestå arbeidet på Herrens hus, og seks tusen skal være tilsynsmenn og dommere, 
13:23:5: og fire tusen skal være dørvoktere, og fire tusen skal lovsynge Herren til de instrumenter jeg har latt gjøre til lovsangen. 
13:23:6: Og David delte dem i skifter efter Levis sønner, Gerson, Kahat og Merari. 
13:23:7: Til gersonittene hørte Ladan og Sime'i. 
13:23:8: Ladans sønner var Jehiel, overhodet, så Setam og Joel - tre i tallet. 
13:23:9: Sime'is sønner var Selomot og Hasiel og Haran - tre i tallet; dette var overhodene for Ladans familier. 
13:23:10: Og Sime'is sønner var Jahat, Sisa og Je'us og Beria; dette var Sime'is sønner - fire i tallet. 
13:23:11: Jahat var overhodet, og Sisa den annen; men Je'us og Beria hadde ikke mange sønner, og de utgjorde derfor bare en familie, en embedsklasse. 
13:23:12: Kahats sønner var Amram, Jishar, Hebron og Ussiel - fire i tallet. 
13:23:13: Amrams sønner var Aron og Moses. Aron blev utskilt for å helliges som høihellig, både han og hans sønner gjennem alle tider, så de skulde brenne røkelse for Herren og tjene ham og velsigne i hans navn til evig tid. 
13:23:14: Men sønnene til den Guds mann Moses regnes til Levi stamme. 
13:23:15: Moses' sønner var Gersom og Elieser. 
13:23:16: Av Gersoms sønner var Sebuel overhodet. 
13:23:17: Og av Eliesers barn var Rehabja overhodet; Elieser hadde ikke andre sønner, men Rehabjas sønner var overmåte tallrike. 
13:23:18: Av Jishars sønner var Selomit overhodet. 
13:23:19: Av Hebrons sønner var Jerija overhodet, Amarja den annen, Jahasiel den tredje og Jekamam den fjerde. 
13:23:20: Av Ussiels sønner var Mika overhodet, og Jissija den annen. 
13:23:21: Meraris sønner var Mahli og Musi. Mahlis sønner var Eleasar og Kis. 
13:23:22: Da Eleasar døde, var det ingen sønner efter ham, men bare døtre, og dem tok deres frender Kis' sønner til ekte. 
13:23:23: Musis sønner var Mahli og Eder og Jeremot - tre i tallet. 
13:23:24: Dette var Levis sønner efter sine familier, overhodene for familiene, så mange av dem som blev mønstret, efter sine navn, en for en, de som gjorde arbeidet ved tjenesten i Herrens hus, fra tyveårsalderen og opover. 
13:23:25: For David sa: Herren, Israels Gud, har gitt sitt folk ro og har nu sin bolig i Jerusalem til evig tid; 
13:23:26: derfor har heller ikke levittene lenger nødig å bære tabernaklet og alle de til tjenesten der hørende redskaper. 
13:23:27: For det var efter Davids siste ord at Levis barn blev tellet fra tyveårsalderen og opover. 
13:23:28: Og de blev satt til å gå Arons sønner til hånde ved tjenesten i Herrens hus; de skulde ha tilsyn med forgårdene og kammerne og sørge for rengjøringen av alt det hellige og utføre arbeidet ved tjenesten i Guds hus, 
13:23:29: både med skuebrødene og det fine mel til matofferet og med de usyrede brødleiver og med hellene og med det mel som skulde knaes, og med alle hulmål og lengdemål; 
13:23:30: hver morgen skulde de stå og love og prise Herren, og likeså hver aften, 
13:23:31: og de skulde hjelpe til ved enhver ofring av brennoffer til Herren på sabbatene, på nymåne-dagene og på høitidene, i fastsatt tall, således som det var foreskrevet for dem - all tid for Herrens åsyn. 
13:23:32: Således skulde de da ta vare på hvad som var å vareta ved sammenkomstens telt, og hvad som var å vareta ved helligdommen, og hvad som var å vareta for deres brødre Arons sønner ved tjenesten i Herrens hus. 
13:24:1: Vedkommende Arons sønner, så var deres skifter de som nu skal nevnes: Arons sønner var Nadab og Abihu, Eleasar og Itamar. 
13:24:2: Men Nadab og Abihu døde før sin far; de hadde ingen sønner, og bare Eleasar og Itamar blev prester. 
13:24:3: Sammen med Sadok av Eleasars sønner og Akimelek av Itamars sønner inndelte David dem efter deres embede i tjeneste-klasser. 
13:24:4: Da det viste sig at Eleasars sønner hadde flere overhoder enn Itamars sønner, inndelte de dem således at Eleasars sønner fikk seksten overhoder for sine familier og Itamars sønner åtte for sine familier. 
13:24:5: Og de inndelte dem ved loddkasting, begge ætter; for det fantes fyrster for helligdommen og Guds fyrster både blandt Eleasars sønner og blandt Itamars sønner. 
13:24:6: Skriveren Semaja, Netanels sønn, av Levi stamme, skrev dem op, mens kongen og høvdingene og presten Sadok og Akimelek, Ebjatars sønn, og familiehodene for prestene og levittene så på det; de tok ut en familie for Eleasar og derefter skiftevis en for Itamar. 
13:24:7: Det første lodd kom ut for Jojarib, det annet for Jedaja, 
13:24:8: det tredje for Harim, det fjerde for Seorim, 
13:24:9: det femte for Malkia, det sjette for Mijamin, 
13:24:10: det syvende for Hakkos, det åttende for Abia, 
13:24:11: det niende for Jesua, det tiende for Sekanja, 
13:24:12: det ellevte for Eljasib, det tolvte for Jakim, 
13:24:13: det trettende for Huppa, det fjortende for Jesebab, 
13:24:14: det femtende for Bilga, det sekstende for Immer, 
13:24:15: det syttende for Hesir, det attende for Happisses, 
13:24:16: det nittende for Petahja, det tyvende for Esekiel, 
13:24:17: det en og tyvende for Jakin, det to og tyvende for Gamul, 
13:24:18: det tre og tyvende for Delaja, det fire og tyvende for Ma'asja. 
13:24:19: Dette var den orden som de skulde gjøre tjeneste i, når de gikk inn i Herrens hus, således som det var foreskrevet dem av Aron, deres far, efter den befaling Herren, Israels Gud, hadde gitt ham. 
13:24:20: Vedkommende Levis andre barn, så hørte til Amrams sønner Subael, til Subaels sønner Jehdeja. 
13:24:21: Av Rehabjas sønner var Jissia overhodet. 
13:24:22: Til jisharittene hørte Selomot, til Selomots sønner Jahat. 
13:24:23: Og [Hebrons] sønner var Jeria, Amarja - hans annen sønn - Jahasiel, den tredje, Jekamam, den fjerde. 
13:24:24: Ussiels sønn var Mika; til Mikas sønner hørte Samur. 
13:24:25: Jissia var Mikas bror; til Jissias sunner hørte Sakarja. 
13:24:26: Meraris sønner var Mahli og Musi, sønner av hans sønn Ja'asia. 
13:24:27: Meraris sønner gjennem hans sønn Ja'asia var også Soham og Sakkur og Ibri. 
13:24:28: Mahlis sønn var Eleasar; men han hadde ingen sønner. 
13:24:29: Til Kis, det er Kis' sønner, hørte Jerahme'el. 
13:24:30: Musis sønner var Mahli og Eder og Jerimot. Dette var levittenes sønner efter sine familier. 
13:24:31: De kastet og lodd likesom deres brødre Arons sønner, mens kong David og Sadok og Akimelek og prestenes og levittenes familiehoder var til stede - overhodene for familiene like så vel som deres yngste brødre. 
13:25:1: Så skilte David og hærførerne ut til gudstjenesten Asafs og Hemans og Jedutuns sønner, som i profetisk begeistring* spilte på citarer, harper og cymbler. Dette er listen over de menn som denne tjeneste var pålagt: # <* d.e. som drevet av Guds Ånd sang til Guds pris.> 
13:25:2: Av Asafs barn Sakkur og Josef og Netanja og Asarela, Asafs sønner, under ledelse av Asaf, som spilte i profetisk begeistring efter kongens forskrift; 
13:25:3: av Jedutun, det er Jedutuns sønner: Gedalja og Seri og Jesaja, Hasabja og Mattitja, seks* i tallet, med citarer, under ledelse av deres far Jedutun, som spilte i profetisk begeistring til Herrens lov og pris; # <* seks, fordi Sime'i (1KR 25, 17) er regnet med.> 
13:25:4: av Heman, det er Hemans sønner: Bukkia, Mattanja, Ussiel, Sebuel og Jerimot, Hananja, Hanani, Eliata, Giddalti og Romamti-Eser, Josbekasa, Malloti, Hotir, Mahasiot; 
13:25:5: alle disse var sønner av Heman, kongens seer i Guds ord; for å ophøie hans horn gav Gud Heman fjorten sønner og tre døtre. 
13:25:6: Alle disse stod under sin fars ledelse ved sangen i Herrens hus; de spilte på cymbler, harper og citarer ved tjenesten i Guds hus, under ledelse av kongen, Asaf og Jedutun og Heman. 
13:25:7: Og tallet på dem og deres brødre som var oplært i sangen for Herren, alle dem som var kyndige deri, var to hundre og åtte og åtti. 
13:25:8: Og de kastet lodd om hvad de skulde ivareta, den minste så vel som den største, læreren så vel som lærlingen. 
13:25:9: Det første lodd kom ut for Asaf, det vil si for Josef; Gedalja blev den annen, han selv med sine brødre og sønner, tolv i tallet; 
13:25:10: den tredje blev Sakkur med sine sønner og brødre, tolv i tallet; 
13:25:11: det fjerde lodd kom ut for Jisri, med hans sønner og brødre, tolv i tallet; 
13:25:12: den femte blev Netanja med sine sønner og brødre, tolv i tallet; 
13:25:13: den sjette blev Bukkia med sine sønner og brødre, tolv i tallet; 
13:25:14: den syvende blev Jesarela med sine sønner og brødre, tolv i tallet; 
13:25:15: den åttende blev Jesaia med sine sønner og brødre, tolv i tallet; 
13:25:16: den niende blev Mattanja med sine sønner og brødre, tolv i tallet; 
13:25:17: den tiende blev Sime'i med sine sønner og brødre, tolv i tallet; 
13:25:18: den ellevte blev Asarel med sine sønner og brødre, tolv i tallet; 
13:25:19: det tolvte lodd kom ut for Hasabja, med hans sønner og brødre, tolv i tallet; 
13:25:20: den trettende blev Subael med sine sønner og brødre, tolv i tallet; 
13:25:21: den fjortende blev Mattitja med sine sønner og brødre, tolv i tallet; 
13:25:22: det femtende lodd kom ut for Jeremot, med hans sønner og brødre, tolv i tallet; 
13:25:23: det sekstende for Hananja, med hans sønner og brødre, tolv i tallet; 
13:25:24: det syttende for Josbekasa, med hans sønner og brødre, tolv i tallet; 
13:25:25: det attende for Hanani, med hans sønner og brødre, tolv i tallet; 
13:25:26: det nittende for Malloti, med hans sønner og brødre, tolv i tallet; 
13:25:27: det tyvende for Eliata, med hans sønner og brødre, tolv i tallet; 
13:25:28: det en og tyvende for Hotir, med hans sønner og brødre, tolv i tallet; 
13:25:29: det to og tyvende for Giddalti, med hans sønner og brødre, tolv i tallet; 
13:25:30: det tre og tyvende for Mahasiot, med hans sønner og brødre, tolv i tallet; 
13:25:31: det fire og tyvende for Romamti-Eser, med hans sønner og brødre, tolv i tallet. 
13:26:1: Vedkommende dørvokternes skifter, så hørte til korahittene Meselemja, Kores sønn, av Asafs barn. 
13:26:2: Og Meselemjas sønner var: Sakarja, den førstefødte, Jediael, den annen, Sebadja, den tredje, Jatniel, den fjerde, 
13:26:3: Elam, den femte, Johanan, den sjette, Eljoenai, den syvende. 
13:26:4: Obed-Edoms sønner var: Semaja, den førstefødte, Josabad, den annen, Joah, den tredje, og Sakar, den fjerde, og Netanel, den femte, 
13:26:5: Ammiel, den sjette, Issakar, den syvende, Pe'ulletai, den åttende; for Gud hadde velsignet ham. 
13:26:6: Hans sønn Semaja fikk og sønner, som blev de rådende i sin ætt; for de var dyktige menn. 
13:26:7: Semajas sønner var: Otni og Refael og Obed, Elsabad og hans brødre, dyktige menn, Elihu og Semakja. 
13:26:8: Alle disse var efterkommere av Obed-Edom, både de og deres sønner og brødre, dyktige og sterke menn til tjenesten - i alt to og seksti efterkommere av Obed-Edom. 
13:26:9: Meselemja hadde også sønner og brødre, dyktige menn - i alt atten. 
13:26:10: Sønnene til Hosa, som hørte til Meraris barn, var: Simri, overhodet - for vel var han ikke den førstefødte, men hans far satte ham til å være overhode - 
13:26:11: Hilkia, den annen, Tebalja, den tredje, Sakarja, den fjerde; Hosas sønner og brødre var i alt tretten. 
13:26:12: Disse dørvokter-skifter, det vil si deres overhoder, pålå det å gjøre vakttjeneste i Herrens hus sammen med sine brødre. 
13:26:13: Og de kastet lodd om hver port, den minste som den største, efter sine familier. 
13:26:14: Loddet mot øst falt på Selemja; også for hans sønn Sakarja, en klok rådgiver, kastet de lodd, og hans lodd kom ut mot nord. 
13:26:15: For Obed-Edom falt loddet mot syd, og for hans sønner falt det ved forrådshuset. 
13:26:16: For Suppim og Hosa falt loddet mot vest ved Salleket-porten på allfarveien som går opover, den ene vaktpost ved den andre. 
13:26:17: Mot øst stod seks levitter, mot nord hver dag fire, mot syd hver dag fire, og ved forrådshuset to og to. 
13:26:18: Ved Parbar mot vest stod fire ved veien og to ved selve Parbar. 
13:26:19: Dette var dørvokternes skifter av korahittenes barn og av Meraris barn. 
13:26:20: Av levittene hadde Akia opsyn over skattene i Guds hus og over de til Herren helligede ting. 
13:26:21: Ladans sønner, gersonittenes efterkommere gjennem Ladan, overhodene for gersonitten Ladans familier, var jehielittene. 
13:26:22: Jehielittenes sønner Setam og hans bror Joel hadde opsyn over skattene i Herrens hus. 
13:26:23: Vedkommende amramittene, jisharittene, hebronittene, ossielittene, 
13:26:24: så var Sebuel, sønn av Moses' sønn Gersom, overopsynsmann over skattene. 
13:26:25: Og hans frender av Eliesers ætt var Eliesers sønn Rehabja Og hans sønn Jesaja og hans sønn Joram Og hans sønn Sikri Og hans sønn Selomot. 
13:26:26: Denne Selomot Og hans brødre hadde opsyn over alle de til Herren helligede ting, som kong David og familiehodene blandt høvedsmennene over tusen og over hundre og hærførerne hadde helliget; 
13:26:27: efter krigene hadde de helliget det av hærfanget for å sette Herrens hus i stand. 
13:26:28: Og alt det seeren Samuel og Saul, Kis' sønn, og Abner, Ners sønn, og Joab, Serujas sønn, hadde helliget - alt som nogen hadde helliget - var under opsyn av Selomit og hans brødre. 
13:26:29: Blandt jisharittene var Kenanja og hans sønner satt til å røkte de verdslige gjøremål i Israel som tilsynsmenn og dommere. 
13:26:30: Blandt hebronittene var Hasabja og hans brødre, dyktige menn, tusen og syv hundre i tallet, satt over Israel i landet på vestsiden av Jordan i alt som vedkom Herrens gjerning og kongens tjeneste. 
13:26:31: Til hebronittene hørte Jerija, overhodet for hebronittene efter deres ætter og familier; i det firtiende år av Davids regjering blev det foretatt undersøkelse blandt dem, og det fantes blandt dem dyktige krigsmenn i Jaser i Gilead. 
13:26:32: Hans frender, dyktige menn, var to tusen og syv hundre, som alle var familiehoder; dem satte kong David over rubenittene og gadittene og den halve Manasse stamme i alle Guds saker og kongens saker. 
13:27:1: Dette er Israels barn efter deres tall, med deres familiehoder og høvedsmennene over tusen og over hundre og deres tilsynsmenn, som tjente kongen i alt som vedkom hær-delingene; disse hær-delinger tiltrådte og fratrådte skiftevis hver måned gjennem hele året, og hver deling var på fire og tyve tusen mann. 
13:27:2: Over den første hær-deling, som gjorde tjeneste i den første måned, stod Jasobam, Sabdiels sønn, og til hans deling hørte fire og tyve tusen mann; 
13:27:3: han var en av Peres' efterkommere, den øverste av alle hærførerne som gjorde tjeneste i den første måned. 
13:27:4: Over den hær-deling som gjorde tjeneste i den annen måned, stod ahohitten Dodai; ved hans deling var fyrsten Miklot, og til hans deling hørte fire og tyve tusen mann. 
13:27:5: Den tredje hærfører, som gjorde tjeneste i den tredje måned, var Benaja, presten Jojadas sønn, som var den øverste, og til hans deling hørte fire og tyve tusen mann; 
13:27:6: denne Benaja var en helt blandt de tretti og stod over de tretti, og ved hans hær-deling var hans sønn Ammisabad. 
13:27:7: Den fjerde, som gjorde tjeneste i den fjerde måned, var Asael, Joabs bror, og efter ham hans sønn Sebadja, og til hans hær-deling hørte fire og tyve tusen mann. 
13:27:8: Den femte, som gjorde tjeneste i den femte måned, var høvdingen Samhut, jisrahitten, og til hans hærdeling hørte fire og tyve tusen mann. 
13:27:9: Den sjette, som gjorde tjeneste i den sjette måned, var Ira, Ikkes' sønn, fra Tekoa, og til hans hærdeling hørte fire og tyve tusen mann. 
13:27:10: Den syvende, som gjorde tjeneste i den syvende måned, var pelonitten Heles av Efra'ims barn, og til hans hær-deling hørte fire og tyve tusen mann. 
13:27:11: Den åttende, som gjorde tjeneste i den åttende måned, var husatitten Sibbekai av serahittenes ætt, og til hans hær-deling hørte fire og tyve tusen mann. 
13:27:12: Den niende, som gjorde tjeneste i den niende måned, var Abieser fra Anatot, av Benjamins stamme, og til hans hær-deling hørte fire og tyve tusen mann. 
13:27:13: Den tiende, som gjorde tjeneste i den tiende måned, var Maharai fra Netofa, av serahittenes ætt, og til hans hær-deling hørte fire og tyve tusen mann. 
13:27:14: Den ellevte, som gjorde tjeneste i den ellevte måned, var Benaja fra Piraton, av Efra'ims barn, og til hans hær-deling hørte fire og tyve tusen mann. 
13:27:15: Den tolvte, som gjorde tjeneste i den tolvte måned, var Heldai fra Netofa, av Otrliels ætt, og til hans hær-deling hørte fire og tyve tusen mann. 
13:27:16: Israels stammefyrster var: for rubenittene Elieser, Sikris sønn, for simeonittene Sefatja, Ma'akas sønn, 
13:27:17: for Levi Hasabja, Kemuels sønn, for Arons ætt Sadok, 
13:27:18: for Juda Elihu, en av Davids brødre, for Issakar Omri, Mikaels sønn, 
13:27:19: for Sebulon Jismaja, Obadjas sønn, for Naftali Jerimot, Asriels sønn, 
13:27:20: for Efra'ims barn Hosea, Asasjas sønn, for den halve Manasse stamme Joel, Pedajas sønn, 
13:27:21: for den halve Manasse stamme i Gilead Jiddo, Sakarjas sønn, for Benjamin Ja'asiel, Abners sønn, 
13:27:22: for Dan Asar'el, Jerohams sønn. Dette var høvdingene for Israels stammer. 
13:27:23: Men David tok ikke tall på dem som var under tyve år; for Herren hadde sagt at han vilde gjøre Israel tallrikt som himmelens stjerner. 
13:27:24: Joab, Serujas sønn, begynte å telle, men fullendte det ikke, og Guds vrede kom over Israel for dette, og tallet blev ikke opført i manntallet i kong Davids krønike. 
13:27:25: Opsynet over kongens skatter hadde Asmavet, Adiels sønn, over forrådene på marken, i byene og landsbyene og i festningstårnene Jonatan, Ussias sønn, 
13:27:26: over dem som arbeidet på marken og dyrket jorden, Esri, Kelubs sønn, 
13:27:27: over vingårdene Sime'i fra Rama, over vinforrådene i vingårdene sifmitten Sabdi, 
13:27:28: over oljetrærne og morbærtrærne i lavlandet Ba'al-Hanan fra Geder, over oljeforrådene Joas, 
13:27:29: over storfeet som beitet i Saron, Sitrai fra Saron, over storfeet i dalene Safat, Adlais sønn, 
13:27:30: over kamelene ismaelitten Obil, over aseninnene Jehdeja fra Meronot 
13:27:31: og over småfeet hagaritten Jasi; alle disse var opsynsmenn over kong Davids eiendeler. 
13:27:32: Davids farbror Jonatan var hans rådgiver; han var en forstandig og skriftlærd mann. Jehiel, Hakmonis sønn, var hos kongens sønner. 
13:27:33: Akitofel var kongens rådgiver, og arkitten Husai var kongens venn. 
13:27:34: Efter Akitofel kom Jojada, Benajas sønn, og Ebjatar. Joab var kongens hærfører. 
13:28:1: Og David kalte alle Israels høvdinger sammen til Jerusalem, både stammenes høvdinger og høvdingene over de hær-delinger som var i kongens tjeneste, og høvedsmennene over tusen og over hundre og opsynsmennene over alle kongens og hans sønners eiendeler og hjorder og dessuten hoffmennene og heltene og alle andre gjæve menn. 
13:28:2: Og kong David reiste sig fra sitt sete og sa: Hør på mig, mine brødre og mitt folk! Jeg hadde i sinne å bygge et hus til hvilested for Herrens pakts-ark og for vår Guds fotskammel, og jeg har samlet forråd til å bygge; 
13:28:3: men Gud sa til mig: Du skal ikke bygge hus for mitt navn, for du er krigsmann og har utøst blod. 
13:28:4: Herren, Israels Gud, har av hele min fars hus utvalgt mig til å være konge over Israel for all tid; for Juda utvalgte han til å være fyrste, og av Judas hus min fars hus, og blandt min fars sønner hadde han behag i mig, så han gjorde mig til konge over hele Israel. 
13:28:5: Og av alle mine sønner - for Herren har gitt mig mange sønner - utvalgte han min sønn Salomo til å sitte på Herrens kongetrone og råde over Israel. 
13:28:6: Og han sa til mig: Din sønn Salomo, han er den som skal bygge mitt hus og mine forgårder; for jeg har utvalgt ham til å være min sønn, og jeg vil være hans far, 
13:28:7: og jeg vil grunnfeste hans kongedømme til evig tid, dersom han trofast blir ved å holde mine bud og mine lover, således som han nu gjør. 
13:28:8: Og nu står jeg her, hvor hele Israel, Herrens menighet, ser mig, og vår Gud hører mig, og jeg sier: Hold alle Herrens, eders Guds bud og gransk dem vel, så I kan få eie dette gode land og la det gå i arv til eders barn efter eder til evig tid! 
13:28:9: Og du, min sønn Salomo, lær din fars Gud å kjenne og tjen ham med udelt hjerte og med villig sjel! For Herren ransaker alle hjerter og forstår alle menneskers tanker og råd; dersom du søker ham, skal han la sig finne av dig, men dersom du forlater ham, skal han forkaste dig for alltid. 
13:28:10: Så se nu til, for Herren har utvalgt dig til å bygge et hus til helligdom; vær frimodig og gå til verket! 
13:28:11: Så gav David sin sønn Salomo et billede av forhallen og tempelbygningene, skattkammerne, loftsrummene, de indre rum og rummet for nådestolen, 
13:28:12: og et billede av alt det som i ånden stod for ham, av forgårdene til Herrens hus og alle kammerne rundt omkring for skattene som hørte til Guds hus, og for de helligede ting 
13:28:13: og for prestenes og levittenes skifter og for alt arbeid ved tjenesten i Herrens hus og for alle kar til tjenesten i Herrens hus, 
13:28:14: og et billede av det som skulde være av gull, med opgave over gullets vekt, av alle de kar som hørte til hver enkelt tjeneste, og av alle sølvkarene efter vekt, av alle de kar som hørte til hver enkelt tjeneste. 
13:28:15: Og han opgav ham vekten på gull-lysestakene med tilhørende lamper av gull - vekten på hver enkelt lysestake med tilhørende lamper - og på sølvlysestakene - vekten på hver enkelt lysestake med tilhørende lamper - alt efter det hver lysestake skulde brukes til, 
13:28:16: og vekten på gullet til skuebrøds-bordene - til hvert enkelt bord - og på sølvet til sølvbordene. 
13:28:17: Og han gav ham et billede av gaflene og skålene til å sprenge blod med og kannene av rent gull og av gullbegerne efter hvert begers vekt og av sølvbegerne efter hvert begers vekt 
13:28:18: og av røkoffer-alteret av renset gull efter vekt og et billede av vognen, det vil si kjerubene av gull, som holdt vingene utbredt og dekket over Herrens pakts-ark. 
13:28:19: Om alt dette [sa David], om alt som skal gjøres efter billedet, har Herren oplyst mig ved et skrift fra sin hånd. 
13:28:20: Så sa David til sin sønn Salomo: Vær frimodig og sterk og gå til verket, frykt ikke og vær ikke redd! For Gud Herren, min Gud, er med dig; han vil ikke slippe dig og ikke forlate dig, før alt arbeid for tjenesten ved Herrens hus er fullført. 
13:28:21: Se, her er prestenes og levittenes skifter, som skal utføre all tjeneste i Guds hus, og til alt arbeid har du folk hos dig som alle er villige og kyndige i alt slags arbeid, og høvdingene og hele folket er rede til å gjøre alt det du sier. 
13:29:1: Derefter sa kong David til alle dem som var kommet sammen: Salomo, min sønn, den eneste som Gud har utvalgt, er ung og vek, og arbeidet er stort; for den herlige bygning skal ikke være for et menneske, men for Herren Gud. 
13:29:2: Av all min kraft har jeg samlet sammen for min Guds hus gull til det som skal være av gull, og sølv til det som skal være av sølv, og kobber til det som skal være av kobber, og jern til det som skal være av jern, og tre til det som skal være av tre, dessuten onyks-stener og andre edelstener, stener til innfatning, sorte glansstener og brokete stener - alle slags dyre stener og en mengde marmorstener. 
13:29:3: Og fordi jeg har min Guds hus kjært, vil jeg også gi hvad jeg eier av gull og sølv, til min Guds hus - foruten alt det jeg har samlet sammen til det hellige hus, 
13:29:4: tre tusen talenter gull, gull fra Ofir, og syv tusen talenter renset sølv til å klæ tempelrummenes vegger med, 
13:29:5: så der kan være gull og sølv til alt det som skal være av gull og av sølv, og til alle slags arbeid av kunstneres hender. Hvem er nu villig til idag å fylle sin hånd med gaver til Herren? 
13:29:6: Da kom de villig med gaver, både familienes overhoder og Israels stammehøvdinger og høvedsmennene over tusen og over hundre og opsynsmennene over kongens arbeid: 
13:29:7: Fem tusen talenter og ti tusen dariker gull og ti tusen talenter sølv og atten tusen talenter kobber og hundre tusen talenter jern gav de til arbeidet på Guds hus; 
13:29:8: og de som eide edelstener, gav dem til skatten i Herrens hus, som gersonitten Jehiel hadde opsyn over. 
13:29:9: Og folket gledet sig over at de gav så villig; for med udelt hjerte gav de frivillig sine gaver til Herren; kong David gledet sig også storlig. 
13:29:10: Og David lovet Herren i hele forsamlingens påhør, og han sa: Lovet være du, Herre, vår far Israels Gud, fra evighet og til evighet! 
13:29:11: Dig, Herre, tilhører storheten og makten og æren og herligheten og majesteten, ja alt i himmelen og på jorden; ditt, Herre, er riket, og du er ophøiet over alt og har alt i din makt. 
13:29:12: Rikdom og ære kommer fra dig, og du råder over alle ting; i din hånd er styrke og makt, og i din hånd står det å gjøre hvad som helst stort og sterkt. 
13:29:13: Så priser vi nu dig, vår Gud, og lover ditt herlige navn. 
13:29:14: For hvem er vel jeg, og hvad er mitt folk, at vi skulde være i stand til å gi en frivillig gave som denne. Fra dig kommer det alt sammen, og av det som din hånd har gitt oss, har vi gitt dig. 
13:29:15: For vi er fremmede for ditt åsyn og gjester, som alle våre fedre; som en skygge er våre dager på jorden og uten håp*. # <* om nogen forandring heri.> 
13:29:16: Herre vår Gud! Alle disse rikdommer som vi har samlet sammen for å bygge dig et hus for ditt hellige navn, de kommer fra din hånd, og ditt er det alt sammen. 
13:29:17: Jeg vet, min Gud, at du ransaker hjerter og har behag i opriktighet; av et opriktig hjerte har jeg villig gitt dig alt dette, og med glede har jeg nu sett hvorledes ditt folk som står her, frivillig har gitt dig sine gaver. 
13:29:18: Herre, du Abrahams, Isaks og Israels, våre fedres Gud! La dette alltid være ditt folks hjertelag og tanker, og vend deres hjerte til dig! 
13:29:19: Og gi min sønn Salomo et udelt hjerte til å holde dine bud, dine vidnesbyrd og dine lover og utføre det alt sammen og bygge den herlige bygning som jeg har samlet forråd til. 
13:29:20: Derefter sa David til hele forsamlingen: Lov Herren eders Gud! Og hele forsamlingen lovet Herren, sine fedres Gud, og bøide sig og kastet sig ned for Herren og for kongen. 
13:29:21: Så bar de slaktoffere frem for Herren; og dagen efter ofret de brennoffere til Herren: tusen okser, tusen værer, tusen lam, med tilhørende drikkoffere, og slaktoffere i mengde for hele Israel. 
13:29:22: Og den dag åt og drakk de for Herrens åsyn med stor glede, og de gjorde annen gang* Davids sønn Salomo til konge og salvet ham til en Herrens fyrste og Sadok til prest. # <* første gang 1KR 23, 1. 1KG 1, 33 fg.> 
13:29:23: Og Salomo satt på Herrens trone som konge i sin far Davids sted; han hadde lykken med sig, og hele Israel var ham lydig, 
13:29:24: og alle høvdingene og heltene og likeså alle kong Davids sønner hyldet kong Salomo. 
13:29:25: Og Herren lot Salomo bli overmåte stor for hele Israels øine og gav ham en kongelig herlighet som ingen konge i Israel før ham hadde hatt. 
13:29:26: David, Isais sønn, hadde vært konge over hele Israel. 
13:29:27: Den tid han var konge over Israel, var firti år; i Hebron regjerte han i syv år, og i Jerusalem regjerte han i tre og tretti år. 
13:29:28: Og han døde i høi alder, mett av dager, rikdom og ære, og hans sønn Salomo blev konge i hans sted. 
13:29:29: Hvad som er å fortelle om kong David, både i hans første og i hans senere dager, det er opskrevet i seeren Samuels krønike og i profeten Natans krønike og i seeren Gads krønike; 
13:29:30: der er og fortalt om hele hans regjering og hans store gjerninger og om de tider som kom over ham og Israel og over alle andre land og riker. 
14:1:1: Salomo, Davids sønn, blev en mektig konge; Herren hans Gud var med ham og lot ham bli overmåte stor. 
14:1:2: Og Salomo lot befaling utgå til hele Israel, til høvedsmennene over tusen og over hundre og til dommerne og til alle høvdinger i hele Israel, til familiehodene. 
14:1:3: Og han og alle de han hadde kalt sammen, drog til offerhaugen i Gibeon; for der stod Guds sammenkomst-telt, det som Herrens tjener Moses hadde gjort i ørkenen. 
14:1:4: Men Guds ark hadde David ført op fra Kirjat-Jearim til det sted han hadde gjort i stand for den; for han hadde reist et telt for den i Jerusalem. 
14:1:5: Og kobberalteret som Besalel, sønn til Uri og sønnesønn til Hur, hadde gjort, stod der foran Herrens tabernakel, og Salomo og de som han hadde kalt sammen, søkte dit. 
14:1:6: Og der, på kobberalteret som hørte til sammenkomstens telt, bar Salomo frem offere for Herrens åsyn, tusen brennoffer ofret han på det. 
14:1:7: Samme natt åpenbarte Gud sig for Salomo og sa til ham: Bed om det du vil jeg skal gi dig! 
14:1:8: Salomo svarte Gud: Du har vist stor miskunnhet mot min far David og gjort mig til konge i hans sted. 
14:1:9: Så la nu, Herre Gud, ditt ord til min far David bli sannhet! For du har gjort mig til konge over et folk som er like så tallrikt som støvet på jorden. 
14:1:10: Gi mig nu visdom og kunnskap, så jeg kan være leder og fører for dette folk! For hvem kunde ellers dømme dette ditt folk som er så stort? 
14:1:11: Da sa Gud til Salomo: Efterdi det var dette som lå dig på hjerte, og du ikke har bedt om rikdom, skatter og ære eller dine fienders død og heller ikke om et langt liv, men har bedt om visdom og kunnskap, så du kan dømme mitt folk, som jeg har gjort dig til konge over, 
14:1:12: så er visdom og kunnskap gitt dig, og jeg vil også gi dig rikdom og skatter og ære, som hverken de konger som har vært før dig har hatt, eller de som kommer efter dig skal ha maken til. 
14:1:13: Så drog Salomo fra offerhaugen i Gibeon, fra sammenkomstens telt, tilbake til Jerusalem, og han regjerte over Israel. 
14:1:14: Salomo la sig til mange stridsvogner og hestfolk; han hadde fjorten hundre stridsvogner og tolv tusen hestfolk; dem la han dels i vognbyene, dels hos sig selv i Jerusalem. 
14:1:15: Kongen gjorde sølvet og gullet i Jerusalem like så almindelig som sten, og sedertre like så almindelig som morbærtrærne i lavlandet. 
14:1:16: Sine hester lot Salomo innføre fra Egypten; en del kjøbmenn som kongen sendte avsted, hentet en flokk for en fastsatt pris. 
14:1:17: Hver vogn som hentedes op fra Egypten og innførtes, kostet seks hundre sekel sølv og hver hest hundre og femti. Og på samme måte hentet de også vogner og hester derfra til alle hetittenes konger og til kongene i Syria. 
14:2:1: Salomo tenkte nu på å bygge et hus for Herrens navn og et hus for sig selv til kongebolig. 
14:2:2: Han tok ut sytti tusen mann til bærere og åtti tusen til stenhuggere på fjellene og tre tusen og seks hundre til opsynsmenn over dem. 
14:2:3: Så sendte han bud til kongen i Tyrus Hiram og lot si: Gjør nu for mig det samme som du gjorde for min far David da du sendte ham sedertre, så han kunde bygge sig et hus til å bo i! 
14:2:4: Nu vil jeg bygge et hus for Herrens, min Guds navn og hellige det til ham, så vi kan brenne velluktende røkelse for hans åsyn og legge frem de daglige skuebrød og ofre brennoffere morgen og aften på sabbatene og nymåne-dagene og på Herrens, vår Guds høitider; dette er pålagt Israel for alle tider. 
14:2:5: Og det hus som jeg vil bygge, skal være stort; for vår Gud er større enn alle guder. 
14:2:6: Men hvem makter vel å bygge ham et hus? Himlene og himlenes himler rummer ham ikke. Hvem er da jeg, at jeg skulde bygge ham et hus? Jeg vil bare brenne røkelse for hans åsyn. 
14:2:7: Så send mig nu en mann som er kyndig i å arbeide i gull og sølv og kobber og jern og i purpurrød og karmosinrød og blå ull, og som forstår sig på å skjære ut billeder, så han kan arbeide sammen med de kunstforstandige menn som jeg har her i Juda og Jerusalem, og som min far David har kalt til dette. 
14:2:8: Send mig også sedertre, cypresstre og sandeltre fra Libanon; for jeg vet at dine tjenere forstår sig på å hugge trærne på Libanon, og mine tjenere skal arbeide sammen med dine, 
14:2:9: så jeg kan få tre i mengdevis; for det hus jeg vil bygge, skal være stort og prektig. 
14:2:10: Og tømmerhuggerne, dine tjenere som feller trærne, vil jeg gi tyve tusen kor tresket hvete og tyve tusen kor bygg og tyve tusen bat vin og tyve tusen bat olje. 
14:2:11: Kongen i Tyrus Hiram svarte i et brev som han sendte Salomo: Fordi Herren elsker sitt folk, har han satt dig til konge over dem. 
14:2:12: Og Hiram skrev videre: Lovet være Herren, Israels Gud, som har gjort himmelen og jorden, fordi han har gitt kong David en vis sønn, som har slik visdom og forstand at han kan bygge et hus for Herren og et hus for sig selv til kongebolig! 
14:2:13: Jeg sender dig nu en kyndig og forstandig mann, Huram, en mester i min tjeneste. 
14:2:14: Han er sønn til en kvinne av Dans døtre, men hans far er en mann fra Tyrus; han forstår sig på å arbeide i gull og sølv, kobber, jern, sten og tre og i purpurrød og blå ull og i hvit bomull og i karmosinrød ull og på å skjære ut alle slags billeder og uttenke alle slags kunstverker som han får til opgave å utføre, sammen med dine kyndige menn og min herres, din far Davids kyndige menn. 
14:2:15: Nu kan min herre sende sine tjenere hveten og bygget, oljen og vinen som han har talt om. 
14:2:16: Så skal vi hugge så mange trær på Libanon som du har bruk for, og sende dem i flåter på havet til Joppe; så kan du selv hente dem op til Jerusalem. 
14:2:17: Så lot Salomo telle alle de fremmede menn som bodde i Israels land, efter den telling hans far David hadde foretatt, og det viste sig at de var hundre og tre og femti tusen og seks hundre. 
14:2:18: Av dem gjorde han sytti tusen til bærere og åtti tusen til stenhuggere på fjellene og tre tusen og seks hundre til opsynsmenn, som skulde holde folket til arbeid. 
14:3:1: Så begynte Salomo å bygge Herrens hus i Jerusalem, på Moriafjellet, der hvor Herren hadde åpenbaret sig for hans far David, på den plass hvor David hadde samlet forråd, på jebusitten Ornans treskeplass. 
14:3:2: Han begynte å bygge den annen dag i den annen måned i det fjerde år av sin regjering. 
14:3:3: Da Salomo skulde bygge Guds hus, la han grunnvollen således: Lengden var seksti alen efter det eldre mål og bredden tyve alen. 
14:3:4: Og forhallen som lå foran huset, var tyve alen bred, svarende til bredden av huset, og hundre og tyve alen høi, og han klædde den innentil med rent gull. 
14:3:5: Det store hus klædde han med cypresstre; dessuten klædde han det med ekte gull og satte palmer og kjeder på det. 
14:3:6: Han prydet huset med dyre stener. Gullet var gull fra Parva'im. 
14:3:7: Hele huset, både bjelkene og dørtresklene og veggene og dørene klædde han med gull og skar ut kjeruber på veggene. 
14:3:8: Rummet for det Aller-helligste bygget han således at lengden var tyve alen, svarende til bredden av huset, og bredden tyve alen, og han klædde det med ekte gull, som veide seks hundre talenter. 
14:3:9: Vekten på naglene, som var av gull, var femti sekel; også loftsrummene klædde han med gull. 
14:3:10: I rummet for det Aller-helligste gjorde han to kjeruber i billedhuggerarbeid og klædde dem med gull. 
14:3:11: Kjerubenes vinger var tyve alen i lengde; den ene vinge på den ene kjerub var fem alen og rørte ved husets vegg, og den annen vinge var fem alen og rørte ved den annen kjerubs vinge; 
14:3:12: og den ene vinge på den annen kjerub var fem alen og rørte ved husets vegg, og den annen vinge var fem alen og nådde til den første kjerubs vinge. 
14:3:13: Således målte disse kjerubers vinger i sin fulle utstrekning tyve alen; de stod opreist, og deres ansikter vendte innefter. 
14:3:14: Forhenget gjorde han av blå og purpurrød og karmosinrød ull og hvit bomull og satte kjeruber på det. 
14:3:15: Foran huset gjorde han to søiler, som tilsammen var fem og tretti alen høie, og søilehodet ovenpå dem var fem alen. 
14:3:16: Og han gjorde kjeder og satte dem på toppen av søilene, og han gjorde hundre granatepler og satte dem på kjedene. 
14:3:17: Så reiste han søilene foran templet, den ene på høire side og den andre på venstre side; den til høire kalte han Jakin og den til venstre Boas. 
14:4:1: Så gjorde han et alter av kobber, som var tyve alen langt og tyve alen bredt og ti alen høit. 
14:4:2: Og han gjorde det støpte hav; det var ti alen fra den ene rand til den andre og var aldeles rundt; det var fem alen høit, og en snor på tretti alen nådde rundt om det. 
14:4:3: Nedenfor dets rand var der billeder av okser rundt omkring; de gikk rundt omkring havet, ti på hver alen; det var to rader med okser, og de var støpt i ett med havet. 
14:4:4: Havet stod på tolv okser; tre vendte mot nord, tre vendte mot vest, tre vendte mot syd, og tre vendte mot øst; havet hvilte på dem, og deres bakkropper vendte alle innefter. 
14:4:5: Kummens tykkelse var en håndsbredd, og dens rand var som randen på et beger, lik en liljeblomst; den tok mange bat - tre tusen bat rummet den. 
14:4:6: Så gjorde han ti vaskekar og satte fem på høire side og fem på venstre side; i dem skylte de det som hørte til brennofferet, men havet hadde prestene til å vaske sig i. 
14:4:7: Videre gjorde han ti gull-lysestaker efter den foreskrevne form og satte dem i det Hellige, fem på høire og fem på venstre side. 
14:4:8: Og han gjorde ti bord og satte dem i det Hellige, fem på høire og fem på venstre side, og han gjorde hundre skåler av gull. 
14:4:9: Så bygget han prestenes forgård og den store gård og dører til gården, og dørene klædde han med kobber. 
14:4:10: Havet satte han på høire side av huset, mot sydøst. 
14:4:11: Huram gjorde askebøttene og ildskuffene og skålene til å sprenge blod med. Så var Huram ferdig med det arbeid han gjorde for kong Salomo i Guds hus; det var: 
14:4:12: to søiler og de to skåler og søilehoder på toppen av søilene og de to nettverk til å dekke de to skåler på søilehodene på toppen av søilene, 
14:4:13: og de fire hundre granatepler til de to nettverk - to rader granatepler til hvert nettverk - til å dekke de to skåler på søilehodene ovenpå søilene; 
14:4:14: og han gjorde fotstykkene og karene på fotstykkene, 
14:4:15: havet og de tolv okser under det; 
14:4:16: Og askebøttene og ildskuffene og gaflene og alt det som hørte til, gjorde Huram, mesteren som kong Salomo hadde i sin tjeneste, for ham til Herrens hus; det var av blankt kobber. 
14:4:17: Det var på Jordan-sletten kongen lot dem støpe, i lerjorden mellem Sukkot og Seredata. 
14:4:18: Salomo gjorde alle disse saker i stor mengde; for kobberets vekt blev ikke undersøkt. 
14:4:19: Salomo gjorde også alle de ting som skulde være i Guds hus: gullalteret og bordene som skuebrødene skulde ligge på, 
14:4:20: og lysestakene med sine lamper, som skulde tendes på foreskrevet måte, foran koret - de var av fint gull - 
14:4:21: og blomstene på dem og lampene og lysesaksene av gull - av det reneste gull - 
14:4:22: og knivene og skålene til å sprenge blod med og røkelseskålene og fyrfatene, alt av fint gull; og i inngangene til huset var både de indre dører som førte til det Aller-helligste, og de dører som førte til det Hellige, gjort av gull. 
14:5:1: Da nu alt det arbeid var ferdig som Salomo lot gjøre for Herrens hus, lot han bære inn der de ting som hans far David hadde helliget til Herren; både sølvet og gullet og alle karene la han ned i skattkammerne i Guds hus. 
14:5:2: Så kalte Salomo Israels eldste og alle stammehøvdingene, overhodene for Israels barns familier, sammen til Jerusalem for å føre Herrens pakts-ark op fra Davids stad, det er Sion. 
14:5:3: Og alle Israels menn samlet sig hos kongen på festen i den syvende måned. 
14:5:4: Da nu alle Israels eldste var kommet, løftet levittene arken op. 
14:5:5: Og de bar arken op og sammenkomstens telt og alle de hellige redskaper som var i teltet; det var de levittiske prester som bar dem op. 
14:5:6: Og kong Salomo og hele Israels menighet, som hadde samlet sig hos ham, stod foran arken, og de ofret småfe og storfe i slik mengde at de ikke kunde telles eller regnes. 
14:5:7: Prestene bar Herrens pakts-ark inn på dens plass i husets kor, i det Aller-helligste, under kjerubenes vinger. 
14:5:8: For kjerubene bredte sine vinger ut over det sted hvor arken stod, så at kjerubene ovenfra dekket både over arken og dens stenger. 
14:5:9: Stengene var så lange at deres ender, som stod frem fra arken, kunde sees foran koret, men ikke utenfor. Og der har den vært til denne dag. 
14:5:10: Det var intet i arken uten de to tavler som Moses hadde lagt ned i den ved Horeb, dengang Herren gjorde pakt med Israels barn da de drog ut av Egypten. 
14:5:11: Så skjedde det, at da prestene gikk ut av helligdommen - for alle de prester som var der, hadde helliget sig, så det ikke var mulig å iaktta skiftene - 
14:5:12: og da alle de levittiske sangere, både Asaf, Heman, Jedutun og deres sønner og deres brødre stod i hvite bomullsklær med cymbler og harper og citarer på østsiden av alteret, og sammen med dem hundre og tyve prester som blåste i trompeter, 
14:5:13: og da trompetblåserne og sangerne alle som en og på en gang stemte i for å love og prise Herren, og de lot trompetene og cymblene og de andre musikkinstrumenter klinge og lovet Herren, fordi han er god, og hans miskunnhet varer evindelig - da blev huset - Herrens hus - fylt av en sky. 
14:5:14: Og for skyens skyld kunde prestene ikke bli stående og gjøre tjeneste; for Herrens herlighet fylte Guds hus. 
14:6:1: Da sa Salomo: Herren har sagt at han vil bo i mulmet; 
14:6:2: og nu har jeg bygget et hus til bolig for dig, et sted hvor du kan bo til evig tid. 
14:6:3: Så vendte kongen sig om og velsignet hele Israels menighet, mens hele Israels menighet stod. 
14:6:4: Han sa: Lovet være Herren, Israels Gud, som med sin munn talte med David, min far, og med sin hånd har opfylt det han lovte da han sa: 
14:6:5: Fra den dag jeg førte mitt folk ut av Egyptens land, har jeg ikke utvalgt nogen by blandt alle Israels stammer, så der skulde bygges et hus til bolig for mitt navn, heller ikke har jeg utvalgt nogen mann til å være fyrste over mitt folk Israel; 
14:6:6: men jeg utvalgte Jerusalem til bolig for mitt navn, og jeg utvalgte David til å råde over mitt folk Israel. 
14:6:7: Og David, min far, hadde i sinne å bygge et hus for Herrens, Israels Guds navn. 
14:6:8: Men Herren sa til David, min far: At du har hatt i sinne å bygge et hus for mitt navn, det har du gjort vel i; 
14:6:9: men du skal ikke bygge huset; din sønn som skal utgå av dine lender, han skal bygge huset for mitt navn. 
14:6:10: Og Herren opfylte det ord han hadde talt, og jeg er trådt i min far Davids sted og har tatt sete på Israels trone, således som Herren hadde sagt, og jeg har bygget huset for Herrens, Israels Guds navn. 
14:6:11: Og der har jeg satt arken, hvori Herrens pakt er, den pakt som han gjorde med Israels barn. 
14:6:12: Så trådte Salomo frem foran Herrens alter midt for hele Israels menighet og bredte ut sine hender. 
14:6:13: For han hadde gjort en forhøining av kobber, fem alen lang og fem alen bred og tre alen høi, og satt den midt i gården; på den stod han nu. Og han falt på kne midt for hele Israels menighet og bredte ut sine hender mot himmelen 
14:6:14: og sa: Herre, Israels Gud! Det er ingen Gud som du, hverken i himmelen eller på jorden, du som holder din pakt og bevarer din miskunnhet mot dine tjenere, når de vandrer for ditt åsyn av alt sitt hjerte, 
14:6:15: du som har holdt det du lovte din tjener David, min far; du lovte det med din munn, og med din hånd har du opfylt det, som det viser sig idag. 
14:6:16: Så hold nu og, Herre, Israels Gud, det du lovte din tjener David, min far, da du sa: Det skal aldri fattes en mann av din ætt til å sitte på Israels trone for mitt åsyn, så sant dine barn akter på sin vei og vandrer efter min lov, som du har vandret for mitt åsyn. 
14:6:17: Så la nu, Herre, Israels Gud, det ord bli sannhet som du har talt til din tjener David! 
14:6:18: Men bor da Gud virkelig hos menneskene på jorden? Se, himlene og himlenes himler rummer dig ikke; hvor meget mindre da dette hus som jeg har bygget! 
14:6:19: Men du vil allikevel vende dig til din tjeners bønn og til hans ydmyke begjæring, Herre min Gud, og høre på det rop og den bønn som din tjener bærer frem for ditt åsyn, 
14:6:20: så dine øine må være oplatt mot dette hus dag og natt - det sted hvorom du har sagt at du vil la ditt navn bo der - så du hører på den bønn som din tjener beder, vendt mot dette sted. 
14:6:21: Du vil høre på din tjeners og ditt folk Israels ydmyke begjæringer, som de bærer frem, vendt mot dette sted; du vil høre dem fra det sted hvor du bor, fra himmelen; du vil høre og tilgi. 
14:6:22: Når nogen synder mot sin næste, og de krever en ed av ham og lar ham sverge, og han så kommer inn og sverger foran ditt alter i dette hus, 
14:6:23: så vil du høre fra himmelen og gripe inn og hjelpe dine tjenere til deres rett, så du gjengjelder den skyldige og lar hans gjerninger komme over hans eget hode, og dømmer den rettferdige rettferdig og lar ham få efter sin rettferdighet. 
14:6:24: Når ditt folk Israel blir slått av fienden fordi de synder mot dig, men de så vender om og bekjenner ditt navn og beder til dig og bønnfaller om nåde for ditt åsyn i dette hus, 
14:6:25: så vil du høre det fra himmelen og forlate ditt folk Israels synd og føre dem tilbake til det land du har gitt dem og deres fedre. 
14:6:26: Når himmelen lukkes, så det ikke kommer regn, fordi de synder mot dig, og de så beder, vendt mot dette sted, og bekjenner ditt navn og vender om fra sin synd, fordi du ydmyker dem, 
14:6:27: så vil du høre det i himmelen og forlate dine tjeneres og ditt folk Israels synd, fordi du vil lede dem til den gode vei de skal vandre, og du vil la det regne over ditt land, som du har gitt ditt folk til arv. 
14:6:28: Når det kommer hungersnød i landet, når det kommer pest, når det kommer brand og rust på kornet, gresshopper og gnagere, når deres fiender trenger inn i deres land og kringsetter deres byer, når det kommer nogen plage eller nogen sykdom - 
14:6:29: hver gang da noget menneske eller hele ditt folk Israel bærer frem nogen bønn eller ydmyk begjæring, fordi de hver for sig kjenner sitt hjertes plage og lidelse som har rammet dem, og de så breder ut sine hender mot dette hus, 
14:6:30: så vil du høre det fra himmelen, der hvor du bor, og du vil tilgi og gi hver mann efter alle hans gjerninger, fordi du kjenner hans hjerte - for du alene kjenner menneskenes hjerte - 
14:6:31: så de må frykte dig og vandre på dine veier alle de dager de lever i det land du har gitt våre fedre. 
14:6:32: Også om en fremmed, en som ikke er av ditt folk Israel, men kommer fra et fjernt land for ditt store navns og din sterke hånds og din utrakte arms skyld - kommer og beder, vendt mot dette hus, 
14:6:33: så vil du høre fra himmelen, der hvor du bor, og gjøre alt som den fremmede roper til dig om, så alle jordens folk må lære å kjenne ditt navn og frykte dig likesom ditt folk Israel og forstå at det er ditt navn det nevnes med dette hus som jeg har bygget. 
14:6:34: Når ditt folk drar ut i krig mot sine fiender på den vei du sender dem, og de så beder til dig, vendt mot denne stad som du har utvalgt, og det hus jeg har bygget for ditt navn, 
14:6:35: så vil du fra himmelen høre deres bønn og ydmyke begjæring og hjelpe dem til deres rett. 
14:6:36: Når de synder mot dig - for det er intet menneske som ikke synder - og du vredes på dem og gir dem i fiendens vold, og de som tar dem til fange, fører dem bort til et annet land, fjernt eller nær, 
14:6:37: men de så tar sig det til hjerte i det land hvor de holdes fanget, og omvender sig og bønnfaller dig om nåde i sitt fangenskaps land og sier: Vi har syndet, vi har gjort ille og vært ugudelige, 
14:6:38: og de omvender sig til dig av alt sitt hjerte og av all sin sjel i det land som de er bortført til og holdes fanget i, og de beder, vendt mot sitt land, som du har gitt deres fedre, og den stad du har utvalgt, og det hus jeg har bygget for ditt navn, 
14:6:39: så vil du fra himmelen, der hvor du bor, høre deres bønn og ydmyke begjæringer og hjelpe dem til deres rett og forlate ditt folk hvad de har syndet mot dig. 
14:6:40: Så la nu, min Gud, dine øine være oplatt og dine ører merke på den bønn som bedes på dette sted! 
14:6:41: Reis dig nu, Herre Gud, og kom til ditt hvilested, du og din styrkes ark! La dine prester, Herre Gud, klæ sig i frelse og dine fromme glede sig i det gode! 
14:6:42: Herre Gud! Vis ikke din salvedes åsyn tilbake, kom i hu den rike miskunnhet som du har lovt David, din tjener! 
14:7:1: Da Salomo hadde endt sin bønn, falt det ild ned fra himmelen og fortærte brennofferet og slaktofferne, og Herrens herlighet fylte huset. 
14:7:2: Og prestene kunde ikke gå inn i Herrens hus; for Herrens herlighet fylte Herrens hus. 
14:7:3: Alle Israels barn så hvorledes ilden falt ned, og Herrens herlighet kom over huset; da kastet de sig ned på gulvet med ansiktet mot jorden og tilbad og lovet Herren, fordi han er god, og hans miskunnhet varer evindelig. 
14:7:4: Og kongen og alt folket ofret slaktoffer for Herrens åsyn. 
14:7:5: To og tyve tusen stykker storfe og hundre og tyve tusen stykker småfe var det slaktoffer som kong Salomo bar frem. Således var det kongen og alt folket innvidde Guds hus. 
14:7:6: Og prestene stod på sine poster, og levittene stod med Herrens musikkinstrumenter, som kong David hadde latt gjøre til å prise Herren med, fordi hans miskunnhet varer evindelig; for David lot dem* utføre lovsangen. Men prestene stilte sig midt imot dem og blåste i trompeter, mens hele Israel stod. # <* levittene.> 
14:7:7: Og Salomo helliget den midterste del av forgården som var foran Herrens hus; for der ofret han brennofferne og fettstykkene av takkofferne, fordi kobberalteret som Salomo hadde gjort, ikke kunde rumme brennofferet og matofferet og fettstykkene. 
14:7:8: I syv dager feiret Salomo dengang festen*, og hele Israel med ham, en stor mengde folk som var kommet sammen like fra Hamatveien og til Egyptens bekk. # <* 1KG 8, 2.> 
14:7:9: Og på den åttende dag holdt de en festforsamling; for alterets innvielse feiret de i syv dager og festen i syv dager. 
14:7:10: På den tre og tyvende dag i den syvende måned lot han folket fare hjem igjen, glade og vel til mote over det gode Herren hadde gjort mot David og Salomo og mot sitt folk Israel. 
14:7:11: Salomo var nu ferdig med å bygge Herrens hus og kongens hus; og alt det han hadde hatt i sinne å gjøre i Herrens hus og i sitt eget hus, hadde han lykkelig utført. 
14:7:12: Da åpenbarte Herren sig for Salomo om natten og sa til ham: Jeg har hørt din bønn og utvalgt dette sted til et offersted for mig. 
14:7:13: Når jeg lukker himmelen, så det ikke kommer regn, og når jeg byder gresshopper å fortære landet, og når jeg sender pest iblandt mitt folk, 
14:7:14: og så mitt folk, som er kalt med mitt navn, ydmyker sig og beder og søker mitt åsyn og vender om fra sine onde veier, så vil jeg høre fra himmelen og forlate deres synd og læge deres land. 
14:7:15: Nu skal mine øine være oplatt og mine ører merke på den bønn som bedes på dette sted. 
14:7:16: Og nu har jeg utvalgt og helliget dette hus, forat mitt navn skal bo der til evig tid, og mine øine og mitt hjerte skal være der alle dager. 
14:7:17: Hvis du nu vandrer for mitt åsyn, som din far David gjorde, så du gjør alt det jeg har befalt dig, og holder mine bud og mine lover, 
14:7:18: så vil jeg trygge din kongetrone efter den pakt jeg gjorde med din far David da jeg sa: Det skal aldri fattes en mann av din ætt til å råde over Israel. 
14:7:19: Men hvis I vender eder bort og forlater de lover og bud som jeg har forelagt eder, og gir eder til å tjene andre guder og tilbede dem, 
14:7:20: så vil jeg rykke dem op av mitt land, som jeg har gitt dem, og dette hus som jeg har helliget for mitt navn, vil jeg forkaste fra mitt åsyn og gjøre det til et ordsprog og til en spott blandt alle folk. 
14:7:21: Og hver den som går forbi dette hus, som har vært så ophøiet, skal forferdes, og når nogen da spør: Hvorfor har Herren gjort således mot dette land og dette hus? 
14:7:22: da skal de svare: Fordi de forlot Herren, sine fedres Gud, som førte dem ut av Egyptens land, og holdt sig til andre guder og tilbad dem og dyrket dem; derfor har han ført all denne ulykke over dem. 
14:8:1: Da nu de tyve år var til ende som Salomo hadde bygget på Herrens hus og sitt eget hus, 
14:8:2: bygget han op de byer som Hiram hadde gitt Salomo, og lot Israels barn bosette sig i dem. 
14:8:3: Derefter drog Salomo til Hamat-Soba og la det under sig. 
14:8:4: Han bygget også op Tadmor i ørkenen og alle de oplagsbyer han har bygget i Hamat. 
14:8:5: Likeledes bygget han op Øvre-Bet-Horon og Nedre-Bet-Horon og gjorde dem til faste byer med murer, dobbelte porter og bommer, 
14:8:6: og Ba'alat og alle de oplagsbyer han hadde, og alle byene for stridsvognene og byene for hestfolket og alt annet som han hadde lyst til å bygge, både i Jerusalem og på Libanon og i hele det land han rådet over. 
14:8:7: Og alt det folk som var blitt tilbake av hetittene, amorittene, ferisittene, hevittene og jebusittene, folk som ikke hørte til Israel - 
14:8:8: deres efterkommere, så mange som var blitt tilbake efter dem i landet fordi Israels barn ikke hadde utryddet dem - dem uttok Salomo til pliktarbeid, og så har det vært til denne dag. 
14:8:9: Men av Israels barn gjorde Salomo ikke nogen til arbeidstræl; de var krigsmenn og høvdinger for hans drabanter og høvedsmenn for hans stridsvogner og hestfolk. 
14:8:10: Kong Salomos arbeidsformenn var to hundre og femti i tallet; de rådet over folkene. 
14:8:11: Salomo førte Faraos datter fra Davids stad inn i det hus han hadde bygget for henne; for han sa: Det skal ikke bo nogen kvinne i Davids, Israels konges hus; det er hellig, fordi Herrens ark er kommet dit. 
14:8:12: Dengang ofret Salomo Herren brennoffere på Herrens alter, det som han hadde bygget foran forhallen; 
14:8:13: han ofret hver dag de offer som var foreskrevet av Moses for den dag, på sabbatene og nymånedagene og høitidene tre ganger om året, på de usyrede brøds fest og på ukenes fest og på løvsalenes fest. 
14:8:14: Han gjorde som hans far David hadde foreskrevet, og satte prestenes skifter til deres tjeneste og levittene til deres gjøremål, til å synge Herrens lov og gå prestene til hånde, efter som det krevdes for hver dag, og likeledes dørvokterne efter deres skifter ved de forskjellige porter, for så hadde den Guds mann David befalt; 
14:8:15: hverken når det gjaldt skattene eller i nogen annen sak vek de av fra det som kongen hadde befalt prestene og levittene. 
14:8:16: Så var da hele Salomos verk utført, fra den dag grunnvollen blev lagt til Herrens hus, og til det var fullendt - Herrens hus stod ferdig. 
14:8:17: Ved den tid drog Salomo til Esjon-Geber og til Elot ved kysten av Edoms land. 
14:8:18: Og Hiram sendte folk til ham med skiber og sjøvant mannskap, og de fór sammen med Salomos folk til Ofir og hentet derfra fire hundre og femti talenter gull og førte det hjem til kong Salomo. 
14:9:1: Da dronningen av Saba hørte Salomos ry, kom hun til Jerusalem med et stort følge og med kameler som bar krydderier og gull i mengde og dyre stener; hun vilde sette Salomo på prøve med gåter. Og da hun var kommet til ham, talte hun med ham om alt som lå henne på hjerte. 
14:9:2: Men Salomo tydet alle hennes gåter; det var ikke et ord av det hun sa, som var dulgt for Salomo, så han ikke kunde tyde det. 
14:9:3: Da dronningen av Saba så Salomos visdom og så det hus han hadde bygget, 
14:9:4: og rettene på hans bord, og hvorledes hans tjenere satt ved bordet, og bordsvennene stod omkring, og hvorledes de var klædd, og hans munnskjenker og deres drakt og den trapp han gikk op på til Herrens hus, var hun rent ute av sig selv av forundring. 
14:9:5: Og hun sa til kongen: Så var det da sant det jeg hørte hjemme i mitt land om dig og din visdom. 
14:9:6: Jeg trodde ikke det de fortalte, før jeg kom og fikk se det med egne øine; men nu ser jeg at de ikke har fortalt mig halvdelen om din store visdom; du overgår det rykte jeg har hørt. 
14:9:7: Lykkelige er dine menn og lykkelige disse dine tjenere som alltid står for ditt åsyn og hører din visdom. 
14:9:8: Lovet være Herren din Gud, som hadde velbehag i dig, så han satte dig på sin trone som konge for Herren din Gud! Fordi din Gud elsker Israel og vil holde det oppe til evig tid, satte han dig til konge over dem for å håndheve rett og rettferdighet. 
14:9:9: Så gav hun kongen hundre og tyve talenter gull og krydderier i stor mengde og dyre stener; aldri har det vært slik mengde krydderier i landet som det dronningen av Saba gav kong Salomo. 
14:9:10: Men også Hirams folk og Salomos folk som hentet gull fra Ofir, hadde ført sandeltre og dyre stener med derfra. 
14:9:11: Av sandeltreet lot kongen gjøre trapper til Herrens hus og til kongens hus og citarer og harper for sangerne; maken til det var aldri før blitt sett i Juda land. 
14:9:12: Og kong Salomo gav dronningen av Saba alt det hun hadde lyst til og bad om, foruten det han gav til gjengjeld for det hun hadde hatt med til kongen. Så tok hun avsted og drog hjem til sitt land med sine tjenere. 
14:9:13: Vekten av det gull som i ett år kom inn til Salomo, var seks hundre og seks og seksti talenter, 
14:9:14: foruten det som kom inn fra kjøbmennene og kremmerne; også alle kongene i Arabia og stattholderne i landet avgav gull og sølv til Salomo. 
14:9:15: Og kong Salomo lot gjøre to hundre store skjold av uthamret gull - det gikk seks hundre sekel uthamret gull med til hvert skjold - 
14:9:16: Og tre hundre små skjold av uthamret gull - til hvert av disse skjold gikk det med tre hundre sekel gull - og kongen satte dem i Libanon-skoghuset. 
14:9:17: Kongen lot også gjøre en stor elfenbenstrone og klædde den med rent gull. 
14:9:18: Tronen hadde seks trin og en fotskammel av gull, som var fastgjort til tronen; på begge sider av setet var det armer, og tett ved armene stod det to løver; 
14:9:19: og på begge sider av de seks trin stod det tolv løver. Noget sådant har aldri vært gjort i noget annet kongerike. 
14:9:20: Alle kong Salomos drikkekar var av gull, og alle karene i Libanonskoghuset var av fint gull; sølv blev ikke regnet for noget i Salomos dager. 
14:9:21: For kongen hadde skiber som fór til Tarsis sammen med Hirams folk; en gang hvert tredje år kom Tarsis-skibene hjem og hadde med gull og sølv, elfenben og aper og påfugler. 
14:9:22: Kong Salomo blev større enn alle jordens konger i rikdom og visdom. 
14:9:23: Fra alle jordens kanter kom det konger for å se Salomo og høre hans visdom, som Gud hadde lagt i hans hjerte, 
14:9:24: og hver av dem hadde gaver med: sølvkar og gullkar og klær, våben og krydderier, hester og mulesler; så gjorde de år om annet. 
14:9:25: Salomo hadde fire tusen stallrum for hester og stridsvogner og tolv tusen hestfolk; dem la han dels i vognbyene, dels hos sig selv i Jerusalem. 
14:9:26: Han rådet over alle kongene fra elven til filistrenes land og like til Egyptens landemerke. 
14:9:27: Kongen gjorde sølvet i Jerusalem like så almindelig som sten, og sedertre like så almindelig som morbærtrærne i lavlandet. 
14:9:28: Og hester innførtes til Salomo fra Egypten og fra alle andre land. 
14:9:29: Hvad som ellers er å fortelle om Salomo, både i hans første og i hans senere dager, er opskrevet i profeten Natans krønike og i Akia fra Silo's spådomsbok og i seeren Jedis syn om Jeroboam, Nebats sønn. 
14:9:30: Salomo var konge i Jerusalem over hele Israel i firti år. 
14:9:31: Så la Salomo sig til hvile hos sine fedre og blev begravet i sin far Davids stad; og hans sønn Rehabeam blev konge i hans sted. 
14:10:1: Rehabeam drog til Sikem; for hele Israel var kommet til Sikem for å gjøre ham til konge. 
14:10:2: Da Jeroboam, Nebats sønn, hørte det - han var da i Egypten; han var flyktet dit for kong Salomo - da vendte han tilbake fra Egypten. 
14:10:3: Og de sendte bud efter ham; så kom Jeroboam og hele Israel og talte til Rehabeam og sa: 
14:10:4: Din far gjorde vårt åk tungt; men lett nu du den hårde tjeneste og det tunge åk som din far la på oss! Så vil vi tjene dig. 
14:10:5: Han svarte dem: Gå bort og vent i tre dager og kom så hit til mig igjen! Så gikk folket bort. 
14:10:6: Og kong Rehabeam rådførte sig med de gamle, som hadde gjort tjeneste hos hans far Salomo, mens han ennu levde, og han sa: Hvad råder I mig til å svare dette folk? 
14:10:7: De svarte ham: Hvis du vil vise dig vennlig mot dette folk og er nådig mot dem og gir dem gode ord, så vil de være dine tjenere for alle tider. 
14:10:8: Men han aktet ikke på det råd som de gamle hadde gitt ham, men rådførte sig med de unge, som var vokset op sammen med ham, og som nu var i hans tjeneste. 
14:10:9: Han spurte dem: Hvad råder I til at vi skal svare dette folk som har sagt til mig: Lett det åk din far la på oss? 
14:10:10: Og de unge, som var vokset op sammen med ham, svarte ham: Så skal du si til dette folk som sa til dig: Din far gjorde vårt åk tungt, men gjør du det lettere for oss - så skal du tale til dem: Min lillefinger er tykkere enn min fars lender; 
14:10:11: har nu min far lagt et tungt åk på eder, så vil jeg gjøre eders åk ennu tyngre; har min far tuktet eder med sveper, så vil jeg tukte eder med skorpioner. 
14:10:12: Så kom Jeroboam og alt folket til Rehabeam den tredje dag, således som kongen hadde sagt: Kom til mig igjen om tre dager! 
14:10:13: Da gav kongen dem et hårdt svar; kong Rehabearn aktet ikke på de gamles råd, 
14:10:14: men svarte dem som de unge hadde rådet til: Har min far gjort eders åk tungt, så vil jeg gjøre det ennu tyngre; har min far tuktet eder med sveper, så vil jeg tukte eder med skorpioner. 
14:10:15: Kongen hørte ikke på folket; for Gud styrte det så forat Herrens ord skulde bli opfylt, det som han hadde talt til Jeroboam, Nebats sønn, ved Akia fra Silo. 
14:10:16: Da nu hele Israel så at kongen ikke hørte på dem, svarte folket kongen og sa: Hvad del har vi i David? Vi har ingen lodd i Isais sønn. Til dine telt, Israel, hver og en! Se nu selv til ditt hus, David! Så drog hele Israel hjem igjen. 
14:10:17: Bare over de Israels barn som bodde i Judas byer, blev Rehabeam konge. 
14:10:18: Da sendte kong Rehabeam Hadoram avsted, han som hadde opsyn med pliktarbeidet, men Israels barn stenet ham, så han døde, og kong Rehabeam selv måtte i all hast springe op i sin vogn og flyktet til Jerusalem. 
14:10:19: Således falt Israel fra Davids hus, og så har det vært til denne dag. 
14:11:1: Da Rehabeam kom til Jerusalem, samlet han Judas hus og Benjamin, hundre og åtti tusen utvalgte krigsmenn, forat de skulde stride mot Israel og vinne riket tilbake for Rehabeam. 
14:11:2: Da kom Herrens ord til Semaja, den Guds mann, og det lød således: 
14:11:3: Si til Rehabeam, Salomos' sønn Judas konge, og til hele Israel i Juda og Benjamin: 
14:11:4: Så sier Herren: I skal ikke dra op og stride mot eders brødre. Vend hjem igjen hver til sitt hus! For det som har hendt, er kommet fra mig. Da lød de Herrens ord; de vendte om og drog ikke mot Jeroboam. 
14:11:5: Rehabeam blev boende i Jerusalem, og han bygget flere byer i Juda om til festninger. 
14:11:6: Således bygget han Betlehem og Etam og Tekoa 
14:11:7: og Bet-Sur og Soko og Adullam 
14:11:8: og Gat og Maresa og Sif 
14:11:9: og Adora'im og Lakis og Aseka 
14:11:10: og Sor'a og Ajalon og Hebron, som alle lå i Juda og i Benjamin, om til faste byer. 
14:11:11: Og han gjorde festningene sterke og innsatte høvedsmenn i dem og forsynte dem med forråd av levnetsmidler og olje og vin 
14:11:12: og hver enkelt av dem med skjold og spyd og gjorde dem således meget sterke. Det var Juda og Benjamin han rådet over. 
14:11:13: Og prestene og levittene i hele Israel gikk over til ham fra alle de bygder de bodde i; 
14:11:14: for levittene forlot sine jorder og sin eiendom og drog til Juda og Jerusalem, fordi Jeroboam og hans sønner* drev dem bort fra deres tjeneste som prester for Herren, # <* d.e. efterfølgere.> 
14:11:15: og han satte andre folk til prester for offerhaugene og for bukkene* og for de kalver han hadde gjort. # <* 3MO 17, 7.> 
14:11:16: Og med dem* fulgte fra alle Israels stammer de som vendte sitt hjerte til å søke Herren, Israels Gud; de kom til Jerusalem for å ofre til Herren, sine fedres Gud. # <* prestene og levittene, 2KR 11, 13. 14.> 
14:11:17: De styrket Judas rike og trygget kongedømmet for Rehabeam, Salomos sønn, i tre år; for i tre år vandret de på Davids og Salomos vei. 
14:11:18: Rehabeam tok sig en hustru foruten Mahalat, datter av Jerimot, Davids sønn; det var Abiha'il, datter av Eliab, Isais sønn; 
14:11:19: Og han fikk med henne sønnene Je'us Og Semarja og Saham. 
14:11:20: Efter henne tok han Ma'aka, Absaloms datter, til hustru; med henne fikk han Abia og Attai og Sisa og Selomit. 
14:11:21: Rehabeam elsket Ma'aka, Absaloms datter, mere enn alle sine andre hustruer og medhustruer; for han hadde tatt sig atten hustruer og seksti medhustruer; og han fikk åtte og tyve sønner og seksti døtre. 
14:11:22: Rehabeam satte Abia, Ma'akas sønn, til overhode og fyrste blandt hans brødre; for han hadde i sinne å gjøre ham til konge. 
14:11:23: Og han lot klokelig alle sine sønner bo rundt om i hele Judas og Benjamins land, i alle de faste byer, og gav dem rikelig underhold og sørget for at de fikk sig en mengde hustruer. 
14:12:1: Men da Rehabeams kongedømme var blitt trygget, og han var blitt mektig, forlot han Herrens lov, og hele Israel med ham. 
14:12:2: Da hendte det i kong Rehabeams femte år at Egyptens konge Sisak drog op mot Jerusalem - for de hadde båret sig troløst at mot Herren; 
14:12:3: han kom med tolv hundre stridsvogner og seksti tusen hestfolk, og det var ikke tall på de folk som fulgte ham fra Egypten - libyere, sukkittere og etiopere. 
14:12:4: Han inntok de faste byer i Juda og kom like til Jerusalem. 
14:12:5: Da kom profeten Semaja til Rehabeam og Judas høvdinger, som hadde samlet sig i Jerusalem av frykt for Sisak, og han sa til dem: Så sier Herren: I har forlatt mig; derfor har også jeg forlatt eder og gitt eder i Sisaks hånd. 
14:12:6: Men Israels høvdinger og kongen ydmyket sig og sa: Herren er rettferdig. 
14:12:7: Og da Herren så at de hadde ydmyket sig, kom Herrens ord til Semaja, og det lød således: De har ydmyket sig; jeg vil ikke ødelegge dem, men la dem så vidt bli frelst, og min harme skal ikke bli utøst over Jerusalem ved Sisak; 
14:12:8: dog skal de bli hans tjenere og få kjenne hvad forskjell det er på å tjene mig og å tjene hedningelandenes konger. 
14:12:9: Så drog da Egyptens konge Sisak op mot Jerusalem og tok skattene i Herrens hus og skattene i kongens hus; alt sammen tok han. Han tok også de gullskjold som Salomo hadde latt gjøre. 
14:12:10: Istedenfor dem lot kong Rehabeam gjøre kobberskjold og betrodde dem til høvedsmennene for drabantene som voktet inngangen til kongens hus; 
14:12:11: og så ofte kongen gikk inn i Herrens hus, kom drabantene og bar dem og tok dem så med tilbake til vaktstuen. 
14:12:12: Fordi Rehabeam ydmyket sig, vendte Herrens vrede sig fra ham; han vilde ikke ødelegge ham aldeles, og det fantes dog ennu noget godt i Juda. 
14:12:13: Siden satt kong Rehabeam atter fast i sitt kongedømme i Jerusalem og regjerte der; for han var en og firti år gammel da han blev konge, og han regjerte sytten år i Jerusalem, den stad som Herren hadde utvalgt blandt alle Israels stammer for å la sitt navn bo der. Hans mor hette Na'ama og var fra Ammon. 
14:12:14: Han gjorde hvad ondt var; for han vendte ikke sitt hjerte til å søke Herren. 
14:12:15: Alt hvad som er å fortelle om Rehabeam, både i hans første og i hans senere dager, det er opskrevet i profeten Semajas og seeren Iddos krønike, der hvor ættetavlene er optegnet. Mellem Rehabeam og Jeroboam var det krig hele tiden. 
14:12:16: Og Rehabeam la sig til hvile hos sine fedre og blev begravet i Davids stad, og hans sønn Abia blev konge i hans sted. 
14:13:1: I kong Jeroboams attende år blev Abia konge over Juda. 
14:13:2: Han regjerte tre år i Jerusalem. Hans mor hette Mikaja; hun var datter av Uriel og var fra Gibea. Mellem Abia og Jeroboam var det krig. 
14:13:3: Abia begynte krigen med en hær av djerve stridsmenn, fire hundre tusen utvalgte menn; men Jeroboam stilte sig i fylking mot ham med åtte hundre tusen utvalgte menn, også djerve stridsmenn. 
14:13:4: Abia gikk op på fjellet Semara'im, som hører til Efra'im-fjellene, og trådte frem og sa: Hør på mig, Jeroboam og hele Israel! 
14:13:5: Skulde I ikke vite at Herren, Israels Gud, har gitt David kongedømmet over Israel til evig tid, ham og hans sønner, ved en saltpakt*? # <* 4MO 18, 19.> 
14:13:6: Men Jeroboam, Nebats sønn, Salomos, Davids sønns tjener, gjorde oprør mot sin herre. 
14:13:7: Og det samlet sig om ham en flokk løse folk og onde mennesker, og de satte sig med vold op imot Rehabeam, Salomos sønn; og Rehabeam var ung og motløs og kunde ikke stå sig mot dem. 
14:13:8: Og nu tenker I at I kan stå eder mot Herrens kongedømme, som Davids sønner har i hende, fordi I er en stor hop og har hos eder de gullkalver som Jeroboam lot gjøre forat de skulde være eders guder. 
14:13:9: Har I ikke jaget bort Herrens prester, Arons sønner, og levittene og selv gjort eder prester, som folkene i hedningelandene gjør? Hver den som kommer med en ung okse og syv værer for å fylle sin hånd, han blir prest for disse guder som ikke er guder. 
14:13:10: Men vår Gud er Herren; vi har ikke forlatt ham, og sønner av Aron tjener Herren som prester, og levittene utfører sitt arbeid; 
14:13:11: hver morgen og aften brenner de brennoffer og velluktende røkelse for Herren og legger frem skuebrød på bordet av rent gull og holder gull-lysestaken og dens lamper i stand og tender den hver aften; vi tar vare på hvad Herren vår Gud vil ha varetatt, men I har forlatt ham. 
14:13:12: Sannelig, Gud er med oss, han er vår fører, og vi har hans prester med larmtrompetene for å blåse til strid mot eder. I Israels barn, strid ikke mot Herren, eders fedres Gud! For det vil ikke lykkes for eder! 
14:13:13: Men Jeroboam lot et bakhold dra omkring for å komme bak på dem, så de selv* var foran Judas menn, og bakholdet i ryggen på dem. # <* Jeroboam med sine andre tropper.> 
14:13:14: Og da Judas menn vendte sig om, fikk de se fienden både foran sig og bak sig; da ropte de til Herren, og prestene blåste i trompetene. 
14:13:15: Og Judas menn opløftet krigsrop; da skjedde det, at med det samme Judas menn opløftet krigsropet, da lot Gud Jeroboam og hele Israel bli slått av Abia og Juda. 
14:13:16: Israels barn flyktet for Juda, og Gud gav dem i deres hånd. 
14:13:17: Og Abia og hans folk voldte et stort mannefall iblandt dem, og det falt fem hundre tusen utvalgte menn av Israel. 
14:13:18: Således blev Israels barn dengang ydmyket; men Judas barn blev sterke, fordi de satte sin lit til Herren, sine fedres Gud. 
14:13:19: Abia forfulgte Jeroboam og tok fra ham byene Betel med tilhørende småbyer og Jesana med tilhørende småbyer og Efron med tilhørende småbyer. 
14:13:20: Jeroboam maktet ikke mere noget så lenge Abia levde, og Herren slo ham så han døde. 
14:13:21: Men Abia blev mektig. Han tok sig fjorten hustruer og fikk to og tyve sønner og seksten døtre. 
14:13:22: Hvad som ellers er å fortelle om Abia, om hans ferd og hans foretagender, det er opskrevet i profeten Iddos historiebok. 
14:14:1: Og Abia la sig til hvile hos sine fedre og blev begravet i Davids stad, og hans sønn Asa blev konge i hans sted. I hans dager hadde landet ro i ti år. 
14:14:2: Asa gjorde hvad godt og rett var i Herrens, hans Guds øine. 
14:14:3: Han fikk bort de fremmede guders altere og offerhaugene og sønderbrøt støttene og hugg Astartebilledene i stykker. 
14:14:4: Og han bød Juda å søke Herren, deres fedres Gud, og holde loven og budene. 
14:14:5: I alle Judas byer fikk han bort offerhaugene og solstøttene, og riket hadde ro under ham. 
14:14:6: Han bygget festninger i Juda; for landet hadde ro, og ingen førte krig mot ham i de år, fordi Herren gav ham ro. 
14:14:7: Han sa til Juda: La oss bygge disse byer og reise murer og tårner, porter og bommer omkring dem! Ennu råder vi fritt over landet, fordi vi har søkt Herren vår Gud; vi har søkt ham, og han har gitt oss ro på alle kanter. Så tok de til å bygge, og det lyktes for dem. 
14:14:8: Asas hær var tre hundre tusen mann av Juda, som var væbnet med spyd og store skjold, og to hundre og åtti tusen av Benjamin, som bar små skjold og skjøt med bue; de var alle djerve stridsmenn. 
14:14:9: Men etioperen Serah drog ut imot dem med en hær på tusen ganger tusen mann og tre hundre stridsvogner, og han kom til Maresa. 
14:14:10: Og Asa drog ut imot ham, og de stilte sig i fylking i Sefata-dalen ved Maresa. 
14:14:11: Da ropte Asa til Herren sin Gud og sa: Herre, for dig gjør det ingen forskjell, enten du hjelper den mektige eller den maktesløse. Hjelp oss, Herre vår Gud! Til dig setter vi vår lit, og i ditt navn er vi gått mot denne store mengde. Herre, du er vår Gud; la ikke et menneske stå sig imot dig! 
14:14:12: Da lot Herren etioperne bli slått av Asa og Juda, og etioperne flyktet. 
14:14:13: Asa og hans folk forfulgte dem like til Gerar, og av etioperne falt det så mange at ingen av dem blev i live; de blev knust av Herren og hans hær. Og de førte et umåtelig stort hærfang bort med sig. 
14:14:14: Og de inntok alle byene rundt omkring Gerar, for frykt for Herren var kommet over dem, og de plyndret alle byene, for det var meget å plyndre i dem. 
14:14:15: Endog teltene for buskapen tok de, og de førte bort en mengde med småfe og kameler, og så vendte de tilbake til Jerusalem. 
14:15:1: Da kom Guds Ånd over Asarja, Odeds sønn, 
14:15:2: og han gikk ut mot Asa og sa til ham: Hør på mig, Asa og hele Juda og Benjamin! Herren er med eder når I er med ham, og dersom I søker ham, skal han la sig finne av eder; men dersom I forlater ham, skal han forlate eder. 
14:15:3: I lange tider var Israel uten sann Gud og uten prest som lærte dem, og uten lov. 
14:15:4: Da vendte de i sin trengsel om til Herren, Israels Gud, og søkte ham, og han lot sig finne av dem. 
14:15:5: Og i de tider kunde ingen ferdes trygt, hverken ute eller hjemme; for det rådet stor uro blandt alle dem som bodde her i landene. 
14:15:6: Da støtte folk mot folk og by mot by, så de knustes; for Gud voldte uro iblandt dem med alle slags trengsel. 
14:15:7: Men vær I frimodige og la ikke eders hender synke! I skal få lønn for eders gjerning. 
14:15:8: Da Asa hørte disse ord og denne profeti av profeten Oded, fattet han mot og sørget for å få bort de vederstyggelige avgudsbilleder i hele Judas og Benjamins land og i de byer han hadde inntatt i Efra'im-fjellene, og han satte i stand Herrens alter, som stod foran Herrens forhall. 
14:15:9: Så samlet han hele Juda og Benjamin og de fremmede fra Efra'im og Manasse og Simeon som bodde iblandt dem; for mange gikk over til ham fra Israel, da de så at Herren, hans Gud, var med ham. 
14:15:10: I den tredje måned i det femtende år av Asas regjering samlet de sig i Jerusalem 
14:15:11: Og ofret samme dag til Herren syv hundre stykker storfe og syv tusen stykker småfe av det hærfang de hadde ført med sig. 
14:15:12: Og de gjorde en pakt om at de vilde søke Herren, sine fedres Gud, av alt sitt hjerte og av all sin sjel, 
14:15:13: og at hver den som ikke søkte Herren, Israels Gud, skulde drepes, enten det var en liten eller en stor, mann eller kvinne. 
14:15:14: Og de svor Herren troskap med høi røst og jubelrop og under trompeters og basuners lyd. 
14:15:15: Og hele Juda gledet sig over eden; for av alt sitt hjerte hadde de svoret den, og med all sin vilje søkte de ham, og han lot sig finne av dem, og Herren gav dem ro rundt omkring. 
14:15:16: Kong Asa avsatte endog sin mor Ma'aka fra hennes verdighet, fordi hun hadde gjort et gruelig avgudsbillede for Astarte; Asa hugg hennes gruelige avgudsbillede ned og knuste det og brente det i Kidrons dal. 
14:15:17: Men offerhaugene blev ikke nedlagt i Israel; dog var Asas hjerte helt med Herren så lenge han levde. 
14:15:18: Han lot de ting hans far hadde helliget, og dem han selv hadde helliget, føre inn i Guds hus, både sølv og gull og andre ting. 
14:15:19: Og det var ingen krig før i det fem og trettiende år av Asas regjering. 
14:16:1: I det seks og trettiende år av Asas regjering drog Israels konge Baesa op mot Juda og bygget festningsverker i Rama for å hindre enhver fra å dra fra eller til Judas konge Asa. 
14:16:2: Da tok Asa sølv og gull ut av skattkammerne i Herrens hus og kongens hus og sendte det til kongen i Syria Benhadad, som bodde i Damaskus, med de ord: 
14:16:3: Mellem mig og dig er det jo et forbund, som det var mellem min far og din far. Her sender jeg dig sølv og gull; bryt nu ditt forbund med Baesa, Israels konge, så han må dra bort fra mig! 
14:16:4: Benhadad lød kong Asa og sendte sine hærførere mot Israels byer, og de inntok Ijon og Dan og Abel-Ma'im og alle forrådshusene i Naftalis byer. 
14:16:5: Da Baesa hørte dette, holdt han op med å bygge festningsverker i Rama og opgav dette arbeid. 
14:16:6: Men kong Asa tok hele Juda med sig, og de førte bort stenene og tømmeret som Baesa hadde brukt til festningsarbeidet i Rama, og bygget med det Geba og Mispa om til festninger. 
14:16:7: På den tid kom seeren Hanani til Judas konge Asa og sa til ham: Fordi du satte din lit til kongen i Syria og ikke til Herren din Gud, derfor er syrerkongens hær sloppet ut av dine hender. 
14:16:8: Var ikke etioperne og libyerne en stor hær med stridsvogner og hestfolk i stor mengde? Men fordi du dengang satte din lit til Herren, gav han dem i din hånd. 
14:16:9: For Herrens øine farer over all jorden forat han med sin kraft kan støtte dem hvis hjerte er helt med ham. Du har båret dig uforstandig at i dette; for fra nu av skal du alltid ha krig. 
14:16:10: Men Asa harmedes på seeren og satte ham i fangehuset; så vred var han på ham for dette. På samme tid for han også hårdt frem mot andre av folket. 
14:16:11: Hvad som er å fortelle om Asa, både i hans første og i hans senere dager, det er opskrevet i boken om Judas og Israels konger. 
14:16:12: I sin regjerings ni og trettiende år blev Asa syk i føttene, og hans sykdom blev verre og verre; men endog i sin sykdom søkte han ikke Herren, men bare lægene. 
14:16:13: Og Asa la sig til hvile hos sine fedre og døde i sin regjerings en og firtiende år. 
14:16:14: De begravde ham i det gravsted som han hadde latt hugge ut for sig i Davids stad, og la ham på et leie som var fylt med krydderier av forskjellige slag, tillaget slik som det gjøres av dem som lager salve; og de brente et veldig bål til ære for ham. 
14:17:1: Hans sønn Josafat blev konge i hans sted. Han søkte å styrke sig mot Israel 
14:17:2: og la krigsfolk i alle Judas faste byer og la også mannskap i Juda land og i de byer i Efra'im som hans far Asa hadde inntatt. 
14:17:3: Og Herren var med Josafat, fordi han vandret på de veier hans far David i sin første tid hadde fulgt, og ikke søkte til Ba'alene, 
14:17:4: men til sin fars Gud og vandret i hans bud og ikke gjorde som Israel. 
14:17:5: Og Herren trygget kongedømmet i hans hånd, og hele Juda gav Josafat gaver, så han fikk stor rikdom og ære. 
14:17:6: Og hans mot vokste mens han skred frem på Herrens veier, og han fikk også bort offerhaugene og Astarte-billedene i Juda. 
14:17:7: I sin regjerings tredje år sendte han sine høvdinger Benha'il og Obadja og Sakarja og Netanel og Mikaja ut for å lære i Judas byer 
14:17:8: og sammen med dem levittene Semaja og Netanja og Sebadja og Asael og Semiramot og Jonatan og Adonja og Tobia og Tob-Adonja og med disse levitter prestene Elisama og Joram. 
14:17:9: De lærte i Juda og hadde Herrens lovbok med sig. de drog omkring i alle Judas byer og lærte folket. 
14:17:10: Og frykt for Herren kom over alle rikene i de land som lå rundt omkring Juda, så de ikke torde føre krig med Josafat. 
14:17:11: En del av filistrene kom med gaver til Josafat og med sølv som de gav i skatt; også araberne førte småfe til ham, syv tusen og syv hundre værer og syv tusen og syv hundre bukker. 
14:17:12: Således blev Josafat mektigere og mektigere, til hans makt var meget stor, og han bygget borger og oplagsbyer i Juda. 
14:17:13: Han hadde store forråd i Judas byer og stridsdjerve krigsmenn i Jerusalem. 
14:17:14: Dette er listen over dem efter deres familier: Høvedsmennene over tusen i Juda var: høvedsmannen Adna med tre hundre tusen djerve stridsmenn 
14:17:15: og ved siden av ham høvedsmannen Johanan med to hundre og åtti tusen 
14:17:16: og ved siden av ham Amasja, Sikris sønn, som frivillig tjente Herren, med to hundre tusen djerve stridsmenn. 
14:17:17: Av Benjamin var det Eljada, en djerv stridsmann, med to hundre tusen mann som var væbnet med bue og skjold, 
14:17:18: og ved siden av ham Josabad med hundre og åtti tusen mann, væbnet til strid. 
14:17:19: Dette var de som tjente kongen, foruten de krigsfolk som kongen hadde lagt i de faste byer i hele Juda. 
14:18:1: Josafat vant stor rikdom og ære. Han inngikk svogerskap med Akab; 
14:18:2: og nogen år efter drog han ned til Akab i Samaria, og Akab lot slakte småfe og storfe i mengde for ham og de folk han hadde med sig, og han tilskyndte ham til å dra op imot Ramot i Gilead. 
14:18:3: Akab, Israels konge, sa til Josafat, Judas konge: Vil du dra med mig til Ramot i Gilead? Han svarte: Som du, så jeg, og som ditt folk, så mitt folk - vi vil være med dig i krigen. 
14:18:4: Og Josafat sa fremdeles til Israels konge: Søk dog først å få vite hvad Herren sier! 
14:18:5: Da kalte Israels konge profetene sammen; det var fire hundre mann; og han spurte dem: Skal vi dra i strid mot Ramot i Gilead, eller skal jeg la det være? De svarte: Dra op! Gud vil gi det i kongens hånd. 
14:18:6: Men Josafat sa: Er her ikke nogen annen Herrens profet, så vi kunde spørre Herren til råds gjennem ham? 
14:18:7: Israels konge svarte Josafat: Der er ennu en mann gjennem hvem vi kan spørre Herren til råds; men jeg hater ham fordi han ikke spår godt om mig, men bare ondt alle sine dager, det er Mika, Jimlas sønn. Josafat sa: Kongen skulde ikke si så! 
14:18:8: Da kalte Israels konge på en av hoffolkene og sa: Skynd dig og hent Mika, Jimlas sønn! 
14:18:9: Imens satt Israels konge og Josafat, Judas konge, i kongelig skrud, hver på sin trone, på en treskeplass ved inngangen til Samarias port; og alle profetene stod foran dem og fremsa sine spådommer. 
14:18:10: Og Sedekias, Kena'anas sønn, gjorde sig horn av jern og sa: Så sier Herren: Med disse skal du stange syrerne til du får gjort ende på dem. 
14:18:11: Og alle profetene spådde likedan og sa: Dra op til Ramot i Gilead! Så skal du ha lykke med dig, og Herren skal gi det i kongens hånd. 
14:18:12: Og budet som var gått for å tilkalle Mika, sa til ham: Profetene spår med en munn godt for kongen; la nu også dine ord stemme overens med deres og spå godt! 
14:18:13: Mika svarte: Så sant Herren lever: Hvad min Gud sier, det vil jeg tale. 
14:18:14: Da han nu kom til kongen, sa kongen til ham: Mika! Skal vi dra i krig til Ramot i Gilead, eller skal jeg la det være? Han svarte: Dra op! Så skal I ha lykke med eder, og de skal gis i eders hånd. 
14:18:15: Men kongen sa til ham: Hvor mange ganger skal jeg besverge dig at du ikke skal tale annet til mig enn sannhet i Herrens navn? 
14:18:16: Da sa han: Jeg så hele Israel spredt utover fjellene likesom en fårehjord som ikke har hyrde; og Herren sa: Disse har ingen herre; la dem vende tilbake i fred, hver til sitt hus! 
14:18:17: Da sa Israels konge til Josafat: Var det ikke det jeg sa til dig: Han spår ikke godt om mig, men bare ondt? 
14:18:18: Men Mika sa: Så hør da Herrens ord! Jeg så Herren sitte på sin trone og hele himmelens hær stå på hans høire og venstre side. 
14:18:19: Og Herren sa: Hvem vil overtale Akab, Israels konge, til å dra op til Ramot i Gilead, så han faller der? og den ene sa så og den annen så. 
14:18:20: Da gikk ånden* frem og stilte sig for Herrens åsyn og sa: Jeg skal overtale ham. Og Herren spurte ham: Hvorledes? # <* 1KG 22, 21.> 
14:18:21: Han svarte: Jeg vil gå avsted og være en løgnens ånd i alle hans profeters munn. Da sa Herren: Ja, du skal overtale ham, og det skal også lykkes dig; gå avsted og gjør så! 
14:18:22: Se, nu har Herren lagt en løgnens ånd i disse dine profeters munn, men Herren har varslet ulykke for dig. 
14:18:23: Da trådte Sedekias, Kena'anas sønn, frem og slo Mika på kinnet og sa: På hvilken vei er Herrens Ånd gått over fra mig for å tale med dig? 
14:18:24: Mika svarte: Det skal du få se den dag du flykter fra kammer til kammer for å skjule dig. 
14:18:25: Da sa Israels konge: Ta Mika og før ham tilbake til byens høvedsmann Amon og til kongesønnen Joas 
14:18:26: og si: Så sier kongen: Sett ham i fangehuset og la ham leve på fangekost til jeg kommer uskadd hjem igjen! 
14:18:27: Mika sa: Kommer du uskadd hjem igjen, så har Herren ikke talt gjennem mig. Og han sa: Hør dette, I folk alle sammen! 
14:18:28: Så drog Israels konge og Judas konge Josafat op til Ramot i Gilead. 
14:18:29: Og Israels konge sa til Josafat: Jeg vil forklæ mig og så gå i striden; men ta du dine vanlige klær på! Så forklædde Israels konge sig, og de gikk i striden. 
14:18:30: Men kongen i Syria hadde befalt sine vogn-høvedsmenn: I skal ikke stride mot nogen, hverken liten eller stor, bare mot Israels konge. 
14:18:31: Da nu vogn-høvedsmennene så Josafat, sa de: Dette er Israels konge. Og de omringet ham for å stride. Da satte Josafat i et høit rop, og Herren hjalp ham; Gud vendte dem bort fra ham. 
14:18:32: For da vogn-høvedsmennene så at det ikke var Israels konge, vendte de sig fra ham igjen. 
14:18:33: Men en mann spente sin bue på lykke og fromme og traff Israels konge mellem brynjeskjørtet og brynjen. Da sa han til vognstyreren: Vend om og før mig ut av hæren! Jeg er såret. 
14:18:34: Men striden blev stadig hårdere den dag, og Israels konge holdt sig opreist i vognen mot syrerne like til om aftenen; men på den tid solen gikk ned, døde han. 
14:19:1: Men Judas konge Josafat vendte uskadd hjem igjen til Jerusalem. 
14:19:2: Da gikk seeren Jehu, Hananis sønn, ut imot ham, og han sa til kong Josafat: Skal en hjelpe den ugudelige, og skal du elske dem som hater Herren? For dette hviler det vrede over dig fra Herren. 
14:19:3: Dog er det også funnet noget godt hos dig; for du har utryddet Astarte-billedene av landet og vendt ditt hjerte til å søke Gud. 
14:19:4: Josafat blev nu en tid i Jerusalem, men siden drog han atter ut iblandt folket, fra Be'erseba like til Efra'im-fjellene, og førte dem tilbake til Herren, deres fedres Gud. 
14:19:5: Og han innsatte dommere i landet, i hver av de faste byer i Juda. 
14:19:6: Og han sa til dommerne: Akt vel på hvad I gjør! For I dømmer ikke for mennesker, men for Herren, og han er hos eder så ofte I dømmer. 
14:19:7: Så la nu Herrens frykt være over eder, vær varsomme i hvad I gjør! For hos Herren vår Gud finnes det ingen urett, og han gjør ikke forskjell på folk og tar ikke imot gaver. 
14:19:8: Også i Jerusalem innsatte Josafat nogen av levittene og prestene og av Israels familiehoder til å dømme i Herrens saker og i rettstretter, for de* var vendt tilbake til Jerusalem. # <* Josafat og de som hadde draget ut med ham, 2KR 19, 4.> 
14:19:9: Og han bød dem og sa: Således skal I gjøre i Herrens frykt, med troskap og med udelt hjerte: 
14:19:10: Hver gang det blir lagt frem for eder en rettssak av eders brødre, som bor rundt om i sine byer - enten det nu gjelder en drapssak eller lov og bud, vedtekter og forskrifter - da skal I advare dem, så de ikke synder mot Herren, og det kommer vrede over eder og eders brødre; således skal I gjøre, ellers kommer I til å dra skyld over eder. 
14:19:11: I alle Herrens saker skal ypperstepresten Amarja være eders formann, og i alle kongens saker Sebadja, Ismaels sønn, fyrsten for Judas hus, og levittene skal være tilsynsmenn under eder. Vær nu frimodige og gjør dette, og Herren være med den som gjør hvad rett er! 
14:20:1: Nogen tid efter hendte det at Moabs barn og Ammons barn og sammen med dem en del av me'unittene gjorde et krigstog mot Josafat. 
14:20:2: Folk kom med tidende derom til Josafat og sa: Det kommer en stor hær imot dig fra hin side havet*, fra Syria; de er allerede i Haseson-Tamar, det er En-Gedi. # <* d.e. det Døde Hav.> 
14:20:3: Da blev Josafat redd, og han v endte sig i bønn til Herren og lot utrope en faste over hele Juda. 
14:20:4: Og Juda samlet sig for å søke hjelp hos Herren; ja, fra alle Judas byer kom de for å søke Herren. 
14:20:5: Da stod Josafat frem blandt dem som var kommet sammen fra Juda og Jerusalem, i Herrens hus foran den nye forgård 
14:20:6: og sa: Herre, våre fedres Gud! Er det ikke du som er Gud i himmelen? Du råder over alle folkenes riker; i din hånd er kraft og velde, og det er ingen som kan holde stand mot dig. 
14:20:7: Har ikke du, vår Gud, drevet dette lands innbyggere ut for ditt folk Israel og gitt det til din venn Abrahams efterkommere for alle tider? 
14:20:8: De bosatte sig der og bygget dig der en helligdom for ditt navn og sa: 
14:20:9: Om nogen ulykke kommer over oss, sverd, straffedom, pest eller hungersnød, så vil vi stille oss foran dette hus og for ditt åsyn, for ditt navn bor i dette hus, og vi vil rope til dig i vår trengsel, og du vil høre og frelse. 
14:20:10: Se nu hvorledes Ammons barn og Moab og folket fra Se'ir-fjellet - disse folk som du ikke gav Israel lov til å trenge inn iblandt da de kom fra Egyptens land, så de bøide av og drog bort fra dem og ikke ødela dem - 
14:20:11: se hvorledes de lønner oss! Nu kommer de og vil drive oss ut av din eiendom, som du har gitt oss i eie. 
14:20:12: Vår Gud! Vil du ikke holde dom over dem? For vi har ikke kraft nok til å motstå denne store hær som kommer imot oss, og vi vet ikke hvad vi skal gjøre; men til dig er våre øine vendt. 
14:20:13: Og hele Juda stod der for Herrens åsyn, endog deres små barn, deres hustruer og deres sønner. 
14:20:14: Da kom Herrens Ånd midt i forsamlingen over levitten Jahasiel, sønn av Sakarja, sønn av Benaja, sønn av Je'iel, sønn av Mattanja, en av Asafs sønner, 
14:20:15: Og han sa: Hør efter, hele Juda og I Jerusalems innbyggere og du kong Josafat! Så sier Herren til eder: Frykt ikke og reddes ikke for denne store hær! For dette er ikke eders krig, men Guds. 
14:20:16: Dra ned mot dem imorgen! Da drar de opefter Hassis-bakken, Og I kommer til å møte dem ved enden av dalen midt imot Jeruelørkenen. 
14:20:17: Men det er ikke I som skal stride her; I skal bare stille eder op og stå og se på hvorledes Herren frelser eder, I fra Juda og Jerusalem! Frykt ikke og reddes ikke! Dra ut imot dem imorgen, og Herren skal være med eder. 
14:20:18: Da bøide Josafat sig med ansiktet til jorden, og hele Juda og alle Jerusalems innbyggere falt ned for Herrens åsyn og tilbad Herren. 
14:20:19: Og de levitter som hørte til kahatittenes og korahittenes barn, reiste sig og lovet Herren, Israels Gud, med høi og lydelig røst. 
14:20:20: Morgenen efter tok de tidlig ut og drog avsted til Tekoa-ørkenen; og med det samme de drog ut, stod Josafat frem og sa: Hør på mig, Juda og I Jerusalems innbyggere! Tro på Herren eders Gud, så skal I holde stand! Tro på hans profeter, så skal I ha lykke med eder! 
14:20:21: Og han rådførte sig med folket og stilte op sangere som skulde love Herren i hellig skrud mens de drog ut foran den væbnede hær, og si: Lov Herren, for hans miskunnhet varer evindelig! 
14:20:22: Og straks de begynte med sine fryderop og lovsanger, lot Herren en flokk bakfra komme over Ammons barn, Moab og folket fra Se'ir-fjellet, som var kommet mot Juda, og de blev slått. 
14:20:23: Og Ammons barn og Moab vendte sig mot folket fra Se'ir-fjellet og hugg dem ned og ødela dem; og da de hadde gjort ende på folket fra Se'ir, hjalp de hverandre med innbyrdes ødeleggelse. 
14:20:24: Da Juda kom op på høiden hvorfra en kunde se ut over ørkenen, og så sig om efter hæren, fikk de se dem ligge døde på jorden, og ingen var sloppet unda. 
14:20:25: Josafat og hans folk drog dit for å plyndre, og de fant der både gods og døde kropper og kostbare ting i mengde, og det hærfang de tok, var så stort at de ikke kunde bære det; i tre dager holdt de på med å plyndre, så meget hærfang var det. 
14:20:26: Den fjerde dag samlet de sig i Lovprisnings-dalen. Der lovet de Herren; derfor heter dette sted Lovprisnings-dalen den dag idag. 
14:20:27: Så vendte alle Judas og Jerusalems menn om med Josafat foran og drog glade tilbake til Jerusalem; for Herren hadde unt dem den glede å vinne over sine fiender. 
14:20:28: De drog inn i Jerusalem til Herrens hus med harper og citarer og trompeter. 
14:20:29: Og frykt for Gud kom over alle riker rundt i landene, da de hørte at Herren hadde stridt mot Israels fiender. 
14:20:30: Og Josafats rike hadde nu fred, fordi hans Gud lot ham få ro på alle kanter. 
14:20:31: Således regjerte Josafat over Juda. Han var fem og tretti år gammel da han blev konge, og regjerte fem og tyve år i Jerusalem. Hans mor hette Asuba og var datter av Silhi. 
14:20:32: Han vandret på sin far Asas vei og vek ikke fra den, men gjorde hvad rett var i Herrens øine. 
14:20:33: Dog blev offerhaugene ikke nedlagt, og folket hadde ennu ikke vendt sitt hjerte til sine fedres Gud. 
14:20:34: Hvad som ellers er å fortelle om Josafat, både i hans første og i hans senere dager, det er opskrevet i Jehus, Hananis sønns krønike, som er optatt i boken om Israels konger. 
14:20:35: Siden inngikk Josafat, Judas konge, en overenskomst med Akasja, Israels konge, han som bar sig så ugudelig at. 
14:20:36: Han kom overens med ham om å bygge skiber som skulde gå til Tarsis; og de bygget skiber i Esjon-Geber. 
14:20:37: Da spådde Elieser, Dodavas sønn, fra Maresa, mot Josafat og sa: Fordi du har inngått forbund med Akasja, skal Herren la ditt foretagende bli til intet. Og nogen av skibene forliste, så de ikke kunde gå til Tarsis. 
14:21:1: Og Josafat la sig til hvile hos sine fedre og blev begravet hos sine fedre i Davids stad; og hans sønn Joram blev konge i hans sted. 
14:21:2: Han hadde flere brødre - sønner av Josafat - de hette Asarja og Jehiel og Sakarja og Asarja og Mikael og Sefatja; alle disse var sønner av Israels konge Josafat. 
14:21:3: Deres far gav dem mange gaver av sølv og gull og kostbare ting og dessuten faste byer i Juda; men kongedømmet gav han til Joram; for han var den førstefødte. 
14:21:4: Men da Joram hadde tiltrådt kongedømmet efter sin far og trygget sin makt, drepte han alle sine brødre med sverdet og likeledes nogen av Israels høvdinger. 
14:21:5: Joram var to og tretti år gammel da han blev konge, og regjerte åtte år i Jerusalem. 
14:21:6: Han vandret på Israels kongers vei, likesom Akabs hus hadde gjort, for han hadde en datter av Akab til hustru; og han gjorde hvad ondt var i Herrens øine. 
14:21:7: Men Herren vilde ikke ødelegge Davids hus for den pakts skyld som han hadde gjort med David, og fordi han hadde sagt at han vilde la en lampe brenne for ham og hans sønner gjennem alle tider. 
14:21:8: I hans dager falt Edom fra Juda og tok sig en konge. 
14:21:9: Da drog Joram dit med sine høvdinger og med alle sine stridsvogner; han brøt op om natten og slo edomittene, som hadde omringet ham, og høvedsmennene over deres vogner. 
14:21:10: Således falt Edom fra Juda og har vært skilt fra dem til den dag idag; på samme tid falt også Libna fra ham, fordi han hadde forlatt Herren, sine fedres Gud. 
14:21:11: Han opførte også offerhauger på Juda-fjellene og lokket Jerusalems innbyggere til utukt* og forførte Juda. # <* d.e. til avgudsdyrkelse.> 
14:21:12: Da kom det et brev til ham fra profeten Elias, og i det stod det: Så sier Herren, din far Davids Gud: Fordi du ikke har vandret på din far Josafats veier og på Judas konge Asas veier, 
14:21:13: men har vandret på Israels kongers vei og lokket Juda og Jerusalems innbyggere til utukt, likesom Akabs hus lokket til utukt, og endog har drept dine brødre, din egen fars sønner, som var bedre enn du, 
14:21:14: så vil Herren la et hårdt slag ramme ditt folk og dine sønner og dine hustruer og alt det du eier. 
14:21:15: Og selv skal du rammes av en svær sykdom, en sykdom i dine innvoller, så dine innvoller efter år og dag skal falle ut på grunn av sykdommen. 
14:21:16: Så opegget Herren filistrene og de arabere som bor ved siden av etioperne, så de blev vrede på Joram, 
14:21:17: og de drog op mot Juda og brøt inn i landet og førte bort alt gods som fantes i kongens hus, og dessuten hans sønner og hustruer, så han ikke hadde igjen nogen av sine sønner uten sin yngste sønn Joakas*. # <* d.s.s. Akasja; 2KR 22, 1.> 
14:21:18: Og efter alt dette rammet Herren ham med en ulægelig sykdom i hans innvoller. 
14:21:19: Og efter år og dag, da to år var gått til ende, falt hans innvoller ut på grunn av sykdommen, og han døde under hårde lidelser. Hans folk tendte ikke noget bål for ham, som de hadde gjort for hans fedre. 
14:21:20: Han var to og tretti år gammel da han blev konge, og regjerte åtte år i Jerusalem. Han gikk bort, og ingen savnet ham, og de begravde ham i Davids stad, men ikke i konge gravene. 
14:22:1: Derefter gjorde Jerusalems innbyggere hans yngste sønn Akasja til konge i hans sted; for alle de eldre var blitt drept av den røverflokk som var kommet til leiren sammen med araberne. Således blev Jorams sønn Akasja konge i Juda. 
14:22:2: Akasja var to og tyve år gammel da han blev konge, og regjerte ett år i Jerusalem; hans mor hette Atalja og var en datter av Omri. 
14:22:3: Også han vandret på samme veier som Akabs hus; for hans mor forførte ham med sine råd til ugudelighet. 
14:22:4: Han gjorde hvad ondt var i Herrens øine, likesom Akabs hus; for efter hans fars død var de hans rådgivere til hans egen ødeleggelse. 
14:22:5: Det var også deres råd han fulgte da han sammen med Israels konge Joram, Akabs sønn, drog ut i krig mot Hasael, kongen i Syria, og stred mot ham ved Ramot i Gilead. Men syrerne såret Joram. 
14:22:6: Da vendte han tilbake for å la sig læge i Jisre'el for de sår han hadde fått ved Rama da han stred mot kongen i Syria Hasael, og Judas konge Asarja*, Jorams sønn, drog ned til Jisre'el for å se til Joram, Akabs sønn, fordi han var syk. # <* d.s.s. Akasja 2KR 22, 1.> 
14:22:7: Men det var Gud som vilde at Akasja skulde gå til grunne, og derfor styrte det så at han kom til Joram; for da han var kommet dit, drog han med Joram ut mot Jehu, Nimsis sønn, som Herren hadde salvet til å utrydde Akabs hus, 
14:22:8: og så hendte det at Jehu, da han fullbyrdet dommen over Akabs hus, møtte Judas høvdinger og Akasjas brorsønner, som var i Akasjas tjeneste, og drepte dem. 
14:22:9: Så søkte han efter Akasja, og han blev grepet mens han holdt sig skjult i Samaria, og de førte ham til Jehu og drepte ham. Så begravde de ham; for de sa: Han er en sønn av Josafat, som søkte Herren av alt sitt hjerte. Men det var ingen igjen av Akasjas hus som var i stand til å overta kongedømmet. 
14:22:10: Da Akasjas mor Atalja fikk vite at hennes sønn var død, tok hun sig for å utrydde hele kongeætten i Judas hus. 
14:22:11: Men kongedatteren Josabat tok Joas, Akasjas sønn, og førte ham hemmelig bort fra kongesønnene som skulde drepes, og førte ham og hans amme inn i sengkammeret; der skjulte Josabat, kong Jorams datter, presten Jojadas hustru, ham - for hun var søster til Akasja - for Atalja, så hun ikke fikk drept ham. 
14:22:12: Siden var han hos dem i Guds hus og blev holdt skjult i seks år, mens Atalja regjerte i landet. 
14:23:1: Men i det syvende år tok Jojada mot til sig og gjorde en pakt med nogen av høvedsmennene over hundre; det var Asarja, Jerohams sønn, og Ismael, Johanans sønn, og Asarja, Obeds sønn, og Ma'aseja, Adajas sønn, og Elisafat, Sikris sønn. 
14:23:2: Så drog de omkring i Juda og samlet levittene fra alle Judas byer og Israels familiehoder; og da de kom til Jerusalem, 
14:23:3: gjorde hele forsamlingen en pakt med kongen i Guds hus, og Jojada sa til dem: Kongens sønn skal nu være konge, således som Herren har sagt om Davids sønner*. # <* 2SA 7, 12 fg.> 
14:23:4: Hør nu hvad I skal gjøre: Den ene tredjedel av eder, de prester og levitter som tiltreder vakttjenesten på sabbaten, skal stå vakt ved dørtresklene, 
14:23:5: og den annen tredjedel ved kongeboligen og den tredje tredjedel ved Jesod-porten, og alt folket i forgårdene ved Herrens hus. 
14:23:6: Men ingen må komme inn i Herrens hus uten prestene og de av levittene som gjør tjeneste; de kan gå inn, for de er hellige; men alle de andre av folket skal holde sig efter det Herren har foreskrevet. 
14:23:7: Levittene skal stille sig rundt omkring kongen, hver mann med våben i hånd, og den som kommer inn i huset, skal drepes; I skal være om kongen, både når han går inn, Og når han går ut. 
14:23:8: Levittene og hele Juda gjorde aldeles som presten Jojada hadde befalt; de tok hver sine menn, både dem som tiltrådte på sabbaten, og dem som trådte av på sabbaten; for presten Jojada hadde ikke latt skiftene få hjemlov. 
14:23:9: Og presten Jojada gav høvedsmennene de spyd og små og store skjold som hadde tilhørt kong David, og som var i Guds hus. 
14:23:10: Og han stilte alt folket op, hver mann med våben i hånd, fra husets høire side til husets venstre side bortimot alteret Og bortimot huset rundt omkring kongen. 
14:23:11: Så førte de kongesønnen ut og satte kronen på ham og overgav ham vidnesbyrdet, og de gjorde ham til konge; og Jojada og hans sønner salvet ham og ropte: Kongen leve! 
14:23:12: Da Atalja hørte ropet fra folket som sprang frem og hyldet kongen, gikk hun inn i Herrens hus til folket. 
14:23:13: Der fikk hun se at kongen stod på sin forhøining i inngangen, og høvedsmennene og trompetblåserne stod hos ham, og hele folkemengden gledet sig og støtte i trompetene, og sangerne var der med sine instrumenter og sang Herrens lov og pris; da sønderrev Atalja sine klær og ropte: Oprør, oprør! 
14:23:14: Men presten Jojada lot høvedsmennene, dem som var satt over hæren, gå ut og sa til dem: Før henne ut mellem rekkene, og om nogen følger henne, så skal han drepes med sverd. For presten hadde sagt: I skal ikke drepe henne i Herrens hus. 
14:23:15: Så gjorde de plass for henne til begge sider, og da hun kom dit hvor Hesteporten* fører inn til kongens hus, drepte de henne. # <* NEH 3, 28.> 
14:23:16: Og Jojada gjorde en pakt mellem sig og alt folket og kongen at de skulde være Herrens folk. 
14:23:17: Og alt folket gikk inn i Ba'als hus og rev det ned; hans altere og hans billeder knuste de, og Ba'als prest Mattan drepte de foran alterne. 
14:23:18: Og Jojada overlot tilsynet i Herrens hus til de levittiske prester, som David hadde inndelt i skifter for tjenesten i Herrens hus, til å ofre Herrens brennoffere, som skrevet er i Mose lov, med glede og sang efter Davids forskrift. 
14:23:19: Og han satte dørvoktere ved portene til Herrens hus, forat ingen som var blitt uren på nogen måte, skulde komme inn der. 
14:23:20: Så tok han med sig høvedsmennene over hundre og de fornemste og de mektige blandt folket og hele folkemengden og førte kongen ned fra Herrens hus, og de gikk gjennem den øvre port inn i kongens hus og satte kongen på kongetronen. 
14:23:21: Og hele folkemengden gledet sig, og byen var rolig; men Atalja hadde de drept med sverd. 
14:24:1: Joas var syv år gammel da han blev konge, og regjerte firti år i Jerusalem; hans mor hette Sibja og var fra Be'erseba. 
14:24:2: Joas gjorde hvad rett var i Herrens øine, så lenge presten Jojada levde. 
14:24:3: Jojada lot ham få to hustruer, og han fikk sønner og døtre. 
14:24:4: Siden fikk Joas i sinne å sette Herrens hus i stand. 
14:24:5: Og han samlet prestene og levittene og sa til dem: Dra ut til byene i Juda og samle inn penger fra hele Israel til å utbedre eders Guds hus efter som det trenges fra år til år, og skynd eder med å gjøre dette! Men levittene skyndte sig ikke dermed. 
14:24:6: Da kalte kongen til sig ypperstepresten Jojada og sa til ham: Hvorfor har du ikke sett efter at levittene kommer med den avgift fra Juda og fra Jerusalem som er pålagt av Herrens tjener Moses, og som Israels menighet skal utrede til vidnesbyrdets telt? 
14:24:7: For den ugudelige Ataljas sønner har ødelagt Guds hus, og endog alle de hellige ting i Herrens hus har de brukt til Ba'alene. 
14:24:8: Så bød kongen at de skulde gjøre en kiste og sette den utenfor porten til Herrens hus. 
14:24:9: Og det blev kunngjort i Juda og Jerusalem at folk skulde komme til Herren med den avgift som Guds tjener Moses hadde pålagt Israel i ørkenen. 
14:24:10: Da bar alle høvdingene og alt folket pengene frem med glede og kastet dem i kisten, til alt var kommet inn. 
14:24:11: Og hver gang kisten av levittene blev båret inn til kongens tilsynsmenn, og de så at det var mange penger i den, da kom kongens skriver og den av ypperstepresten innsatte tilsynsmann og tømte den, og så bar de den tilbake på dens plass. Således gjorde de dag efter dag og samlet en mengde penger. 
14:24:12: Så overgav kongen og Jojada dem til dem som forestod arbeidet på Herrens hus, og de leide stenhuggere og tømmermenn til å sette Herrens hus i stand og likeledes jernsmeder og kobbersmeder til å utbedre Herrens hus. 
14:24:13: Og arbeidsfolkene arbeidet, og utbedringsarbeidet skred frem under deres hender, og de satte Guds hus i stand igjen efter dets rette mål og gjorde det sterkt. 
14:24:14: Og da de var ferdige, bar de resten av pengene til kongen og Jojada, og han lot gjøre redskaper derav til Herrens hus: redskaper til tjenesten og ofringen og skåler og gullkar og sølvkar. Og de ofret stadig brennoffere i Herrens hus, så lenge Jojada levde. 
14:24:15: Men Jojada blev gammel og mett av dager og døde; han var hundre og tretti år gammel da han døde. 
14:24:16: De begravde ham i Davids stad hos kongene, fordi han hadde gjort vel mot Israel og mot Gud og hans hus. 
14:24:17: Men efter Jojadas død kom Judas høvdinger og kastet sig ned for kongen*; da gav kongen efter, # <* med bønn om tillatelse til å dyrke avgudene.> 
14:24:18: og de forlot Herrens, sine fedres Guds hus og dyrket Astartebilledene og de andre avguder; og det kom vrede over Juda og Jerusalem for denne deres brøde. 
14:24:19: Og Herren sendte profeter blandt dem for å føre dem tilbake til sig, og de advarte dem, men de hørte ikke på dem. 
14:24:20: Da kom Guds Ånd over Sakarias, presten Jojadas sønn, og han stod frem for folket og sa til dem: Så sier Gud: Hvorfor overtreder I Herrens bud? På den måte kan det ikke gå eder vel. Fordi I har forlatt Herren, forlater han eder. 
14:24:21: Men de sammensvor sig mot ham og stenet ham på kongens befaling i forgården til Herrens hus. 
14:24:22: Kong Joas kom ikke i hu den kjærlighet som hans far Jojada hadde vist ham - han drepte hans sønn; men i det samme han døde, ropte han: Herren skal se det og hevne det! 
14:24:23: Så skjedde det, da året var til ende, at syrernes hær drog op mot ham; de kom til Juda og Jerusalem og utryddet alle høvdingene i folket og sendte alt det hærfang de tok, til kongen i Damaskus. 
14:24:24: Syrerhæren som kom, var bare liten; men enda gav Herren en meget tallrik hær i deres hånd, fordi de hadde forlatt Herren, sine fedres Gud. Således fullbyrdet de straffedommen over Joas. 
14:24:25: Og da de drog bort fra ham - for de lot ham tilbake i svære lidelser - sammensvor hans tjenere sig mot ham, fordi han hadde drept presten Jojadas sønner, og de drepte ham på hans leie. Således døde han, og de begravde ham i Davids stad, men ikke i kongegravene. 
14:24:26: De som sammensvor sig mot ham, var Sabad, sønn av ammonitterkvinnen Sim'at, og Josabad, sønn av moabitterkvinnen Simrit. 
14:24:27: Om hans sønner og de mange profetord som blev talt imot ham, og den grundige utbedring av Guds hus, derom er det skrevet i kongenes historiebok; og hans sønn Amasja blev konge i hans sted. 
14:25:1: Amasja var fem og tyve år gammel da han blev konge, og regjerte ni og tyve år i Jerusalem; hans mor hette Joaddan og var fra Jerusalem. 
14:25:2: Han gjorde hvad rett var i Herrens øine, dog ikke med udelt hjerte. 
14:25:3: Så snart han hadde fått kongedømmet fast i sin hånd, slo han ihjel dem av sine tjenere som hadde slått ihjel hans far kongen. 
14:25:4: Men deres barn drepte han ikke; han gjorde efter det som er skrevet i loven, i Mose-boken, hvor Herren har gitt dette bud: Foreldre skal ikke lide døden for sine barns skyld, og barn ikke lide døden for sine foreldres skyld; men enhver skal dø for sin egen synd. 
14:25:5: Siden samlet Amasja Juda og stilte dem op efter deres familier under høvedsmennene over tusen og høvedsmennene over hundre i hele Juda og Benjamin; han mønstret dem fra tyveårsalderen og opover, og han fant ut at det var tre hundre tusen utvalgte stridsdyktige menn, som kunde føre spyd og skjold. 
14:25:6: Dessuten leide han i Israel hundre tusen djerve stridsmenn for hundre talenter sølv. 
14:25:7: Men en Guds mann kom til ham og sa: La ikke israelitt-hæren dra med dig, konge! For Herren er ikke med Israel - ikke med nogen av Efra'ims barn. 
14:25:8: Men dra du frem - gjør det og gå modig i striden! Ellers vil Gud la dig falle for fienden; for det står i Guds makt både å hjelpe og å felle. 
14:25:9: Og Amasja spurte den Guds mann: Men hvad blir det da av de hundre talenter jeg har gitt hærflokken av Israel? Den Guds mann svarte: Herren kan vel gi dig mere enn dette. 
14:25:10: Da skilte Amasja ut den flokk som var kommet til ham fra Efra'im, og lot dem dra hjem igjen. Derfor blev de harme på Juda, og de vendte hjem i brennende vrede. 
14:25:11: Men Amasia tok mot til sig og drog ut med sine folk og kom til Saltdalen; der hugg han ned ti tusen mann av Se'irs barn. 
14:25:12: Og ti tusen tok Judas barn levende til fange og førte dem op på toppen av en klippe og styrtet dem ned derfra, så de alle knustes. 
14:25:13: Men de som hørte til den flokk som Amasja hadde sendt tilbake, så de ikke kom til å dra med ham i krigen, falt inn i Judas byer fra Samaria like til Bet-Horon; og de hugg ned der tre tusen mann og tok meget hærfang. 
14:25:14: Da Amasja kom tilbake og hadde slått edomittene, førte han Se'irs barns guder med sig og stilte dem op som sine guder; og han kastet sig ned for dem og brente røkelse for dem. 
14:25:15: Da optendtes Herrens vrede mot Amasja, og han sendte en profet til ham; han sa til ham: Hvorfor søker du dette folks guder, de som ikke har reddet sitt eget folk av din hånd? 
14:25:16: Men da han talte således til ham, svarte han ham: Har vi satt dig til rådgiver for kongen? Hold op med dette hvis du ikke vil bli slått ihjel! Da hørte profeten op og sa: Jeg vet at Gud har besluttet å ødelegge dig, siden du har gjort dette og ikke hørt på mitt råd. 
14:25:17: Men Judas konge Amasja holdt råd og sendte så bud til Israels konge Joas, sønn av Joakas, Jehus sønn, og lot si: Kom, la oss prøve styrke med hverandre! 
14:25:18: Men Israels konge Joas sendte bud til Judas konge Amasja og svarte: Tornebusken på Libanon sendte bud til sederen på Libanon og lot si: La min sønn få din datter til hustru! Men de ville dyr på Libanon for frem og trådte tornebusken ned. 
14:25:19: Du tenker: Jeg har slått Edom, og derfor er du blitt overmodig og vil vinne ennu mere ære. Bli bare hjemme! Hvorfor vil du kalle ulykken ned over dig, så du går til grunne, både du og Juda med dig? 
14:25:20: Men Amasja hørte ikke på ham; for det var Gud som styrte det således; han vilde gi dem i fiendehånd, fordi de hadde søkt Edoms guder. 
14:25:21: Da drog Israels konge Joas op, og han og Judas konge Amasja prøvde styrke med hverandre ved Bet-Semes, som hører til Juda. 
14:25:22: Og Judas menn blev slått av Israel, og de flyktet hver til sitt hjem; 
14:25:23: men Judas konge Amasja, sønn av Joas, Joakas' sønn blev tatt til fange av Israels konge Joas ved Bet-Semes, og han førte ham til Jerusalem, og han rev ned et stykke av Jerusalems mur, fra Efra'im-porten til Hjørneporten, fire hundre alen. 
14:25:24: Og han tok alt det gull og sølv og alle de kar som fantes i Guds hus hos Obed-Edom*, og skattene i kongens hus og dessuten gislene; så vendte han tilbake til Samaria. # <* Obed-Edoms familie hadde tilsyn med forrådshusene, 1KR 26, 15.> 
14:25:25: Judas konge Amasja, Joas' sønn, levde femten år efterat Israels konge Joas, Joakas' sønn, var død. 
14:25:26: Hvad som ellers er å fortelle om Amasja, både i hans første og i hans senere dager, det er opskrevet boken om Judas og Israels konger. 
14:25:27: Men fra den tid Amasja falt fra Herren, gjorde de en sammensvergelse mot ham i Jerusalem, og han flyktet til Lakis; men de sendte folk efter ham til Lakis, og de drepte ham der. 
14:25:28: Så kjørte de ham derfra med hester og begravde ham hos hans fedre i Judas stad. 
14:26:1: Og alt Judas folk tok og gjorde Ussias, som da var seksten år gammel, til konge i hans far Amasjas sted. 
14:26:2: Han gjorde Elot til en fast by og vant det tilbake for Juda, efterat kongen hadde lagt sig til hvile hos sine fedre. 
14:26:3: Ussias var seksten år gammel da han blev konge, og regjerte to og femti år i Jerusalem; hans mor hette Jekilja og var fra Jerusalem. 
14:26:4: Han gjorde hvad rett var i Herrens øine, aldeles som hans far Amasja hadde gjort. 
14:26:5: Og han søkte Gud så lenge Sakarja levde, han som skjønte sig på Guds syner; og så lenge han søkte Herren, lot Gud det gå ham vel. 
14:26:6: Han drog ut og stred mot filistrene og rev ned bymurene i Gat og Jabne og Asdod; og han bygget byer ved Asdod og på andre steder i filistrenes land. 
14:26:7: Gud hjalp ham mot filistrene og mot de arabere som bodde i Gur-Ba'al, og mot me'unittene. 
14:26:8: Og ammonittene kom med gaver til Ussias, og hans navn nådde like til Egypten; for han blev overmåte mektig. 
14:26:9: Ussias bygget tårn i Jerusalem ved Hjørneporten og ved Dalporten og ved Vinkelen* og gjorde således disse steder faste og sterke. # <* NEH 3, 19. 24.> 
14:26:10: Han bygget også tårn i ørkenen og hugg ut mange brønner; for han hadde stor buskap både i lavlandet og på høisletten, og han hadde jordbrukere og vingårdsmenn på fjellene og i havene; for jordbruket lå ham på hjerte. 
14:26:11: Ussias hadde en krigsdyktig hær, som drog ut i strid i flokker, mønstret og tellet av statsskriveren Je'uel og tilsynsmannen Ma'aseja, under ledelse av Hananja, en av kongens høvdinger. 
14:26:12: To tusen og seks hundre var det fulle tall på de djerve stridsmenn som var familiehoder, 
14:26:13: og under dem stod en krigshær på tre hundre og syv tusen og fem hundre mann, som gjorde krigstjeneste med kraft og mot og hjalp kongen mot fienden. 
14:26:14: Hele denne hær forsynte Ussias med skjold og spyd og hjelmer og brynjer og buer og slyngestener. 
14:26:15: I Jerusalem lot han gjøre kunstig innrettede krigsmaskiner, som skulde stilles op på tårnene og murhjørnene til å skyte ut piler og store stener. Og hans navn nådde vidt omkring; for han blev hjulpet på underfull måte, så han fikk stor makt. 
14:26:16: Men da han var blitt mektig, blev han overmodig i sitt hjerte, så han forsyndet sig; han var ulydig mot Herren sin Gud og gikk inn i Herrens helligdom for å brenne røkelse på røkoffer-alteret. 
14:26:17: Men presten Asarja gikk inn efter ham og med ham åtti av Herrens prester, modige menn. 
14:26:18: De trådte op mot kong Ussias og sa til ham: Det tilkommer ikke dig, Ussias, å brenne røkelse for Herren, men bare prestene, Arons sønner, de som er vidd til det. Gå ut av helligdommen! For du har vært ulydig, og det blir dig ikke til ære for Gud Herren. 
14:26:19: Da blev Ussias vred. Han holdt et røkelsekar i hånden og vilde nettop til å brenne røkelse; men da hans vrede brøt løs mot prestene, slo spedalskheten ut i hans panne, som han stod der foran prestene i Herrens hus ved røkoffer-alteret. 
14:26:20: Og da ypperstepresten Asarja og alle prestene vendte sig mot ham, så de at han var spedalsk på pannen. Da drev de ham i hast bort derfra; og selv skyndte han sig også å komme ut, for Herren hadde slått ham. 
14:26:21: Siden var kong Ussias spedalsk like til sin dødsdag, og han bodde i et hus for sig selv som spedalsk, for han var utelukket fra Herrens hus. Hans sønn Jotam forestod kongens hus og dømte landets folk. 
14:26:22: Hvad som ellers er å fortelle om Ussias, både i hans første og i hans senere dager, har profeten Esaias, Amos' sønn*, skrevet op. # <* JES 1, 1.> 
14:26:23: Og Ussias la sig til hvile hos sine fedre, og de begravde ham hos hans fedre på den begravelsesplass som tilhørte kongene; for de sa: Han er spedalsk. Og hans sønn Jotam blev konge i hans sted. 
14:27:1: Jotam var fem og tyve år da han blev konge, og regjerte seksten år i Jerusalem; hans mor hette Jerusa og var datter av Sadok. 
14:27:2: Han gjorde hvad rett var i Herrens øine, aldeles som hans far Ussias hadde gjort; men han gikk ikke inn i Herrens helligdom*. Men folket vedblev med å gjøre hvad ondt var. # <* 2KR 26, 16.> 
14:27:3: Han bygget den øvre port til Herrens hus, og på Ofel-muren bygget han meget. 
14:27:4: Han bygget også byer i Judafjellene, og i skogene bygget han borger og tårn. 
14:27:5: Han førte krig med Ammons barns konge og overvant dem, så at Ammons barn det år måtte gi ham hundre talenter sølv og ti tusen kor hvete og ti tusen kor bygg; det samme måtte Ammons barn gi ham også i det annet og det tredje år. 
14:27:6: Således blev Jotam mektig, fordi han stadig vandret for Herrens, sin Guds åsyn. 
14:27:7: Hvad som ellers er å fortelle om Jotam, om alle hans kriger og alt det han tok sig fore, det er opskrevet i boken om Israels og Judas konger. 
14:27:8: Han var fem og tyve år gammel da han blev konge, og regjerte seksten år i Jerusalem. 
14:27:9: Og Jotam la sig til hvile hos sine fedre, og de begravde ham i Davids stad; og hans sønn Akas blev konge i hans sted. 
14:28:1: Akas var tyve år gammel da han blev konge, og regjerte seksten år i Jerusalem. Han gjorde ikke hvad rett var i Herrens øine, som hans far David, 
14:28:2: men vandret på Israels kongers veier og gjorde endog støpte billeder for Ba'alene. 
14:28:3: Han brente røkelse i Hinnoms sønns dal og lot sine sønner gå igjennem ilden efter de vederstyggelige skikker hos de folk som Herren hadde drevet bort for Israels barn, 
14:28:4: og han ofret og brente røkelse på haugene og bakkene og under hvert grønt tre. 
14:28:5: Så gav Herren hans Gud ham i syrerkongens hånd, og de slo ham og førte mange av hans folk bort som fanger til Damaskus. Han blev også gitt i Israels konges hånd, som vant en stor seier over ham. 
14:28:6: Pekah, Remaljas sønn, drepte på en dag hundre og tyve tusen mann i Juda, alle sammen djerve menn, fordi de hadde forlatt Herren, sine fedres Gud. 
14:28:7: Og Sikri, en stor stridsmann fra Efra'im, drepte kongesønnen Ma'aseja og slottshøvdingen Asrikam og Elkana, som var den næste efter kongen. 
14:28:8: Israels barn førte bort med sig to hundre tusen fanger fra sine brødre - deres hustruer, sønner og døtre; de tok også meget hærfang blandt dem og førte det til Samaria. 
14:28:9: Der var det en Herrens profet som hette Oded; han gikk ut imot hæren da den kom til Samaria, og sa til dem: Hør, Herren, eders fedres Gud, har i sin harme over Juda gitt dem i eders hånd, men I har hugget ned iblandt dem med slik vrede at det er nådd like til himmelen. 
14:28:10: Og nu tenker I på å tvinge Judas og Jerusalems barn til å være træler og trælkvinner for eder; har I ikke selv skyld nok på eder for Herren eders Gud? 
14:28:11: Så hør nu på mig og send fangene tilbake som I har bortført fra eders brødre! For Herrens brennende vrede er over eder. 
14:28:12: Da var det nogen menn blandt overhodene for Efra'ims barn som trådte frem for dem som kom fra krigen - det var Asarja, Johanans sønn, Berekja, Mesillemots sønn, og Hiskias, Sallums sønn, og Amasa, Hadlais sønn - 
14:28:13: og de sa til dem: I skal ikke føre fangene her inn; I tenker vel på å øke våre synder og vår brøde og således riktig føre skyld over oss for Herren; men vår skyld er alt stor nok, og der er brennende vrede over Israel. 
14:28:14: Da gav krigsfolket fra sig fangene og hærfanget for høvdingenes og hele folkemengdens øine. 
14:28:15: Og de menn som nettop er nevnt, kom og tok fangene og klædde alle som var nakne iblandt dem, med klær som de tok av hærfanget; de gav dem både klær og sko, både mat og drikke, og salvet dem, og alle de skrøpelige iblandt dem satte de op på asener og førte dem til Jeriko, Palmestaden, til deres brødre; så vendte de selv hjem igjen til Samaria. 
14:28:16: På denne tid sendte kong Akas bud til kongene i Assyria og bad dem om hjelp. 
14:28:17: For også edomittene kom og slo Juda og førte bort fanger, 
14:28:18: og filistrene falt inn i byene i Juda, både i lavlandet og i sydlandet, og inntok Bet-Semes og Ajalon og Gederot, likeledes Soko med tilhørende småbyer og Timna med tilhørende småbyer og Gimso med tilhørende småbyer og bosatte sig der. 
14:28:19: For Herren vilde ydmyke Juda for Israels konge Akas' skyld, fordi han hadde forført Juda til tuktløshet og båret sig så troløst at mot Herren. 
14:28:20: Så kom kongen i Assyria Tilgat-Pilneser mot ham og fór fiendtlig frem mot ham istedenfor å støtte ham. 
14:28:21: For enda Akas plyndret Herrens hus og kongens hus og høvdingene og gav det til Assyrias konge, så hjalp det ham ikke. 
14:28:22: Og på den tid han var stedt i nød, bar han sig ennu mere troløst at mot Herren - han, kong Akas; 
14:28:23: han ofret til gudene i Damaskus, som hadde slått ham, og sa: Siden syrerkongenes guder har hjulpet dem, så vil jeg ofre til dem, forat de skal hjelpe mig. Men de blev til fall for ham og for hele Israel. 
14:28:24: Akas samlet også karene i Guds hus og brøt gullet av dem og stengte dørene til Herrens hus og gjorde sig alter ved hvert hjørne i Jerusalem. 
14:28:25: Og i hver eneste by i Juda gjorde han hauger til å brenne røkelse på for fremmede guder og vakte således harme hos Herren, sine fedres Gud. 
14:28:26: Hvad som ellers er å fortelle om ham og om alt det han tok sig fore, både i sine første og i sine senere dager, det er opskrevet i boken om Judas og Israels konger. 
14:28:27: Og Akas la sig til hvile hos sine fedre, og de begravde ham i staden - i Jerusalem, men de vilde ikke legge ham i Israels kongers graver. Og hans sønn Esekias blev konge i hans sted. 
14:29:1: Esekias blev konge da han var fem og tyve år gammel, og regjerte ni og tyve år i Jerusalem; hans mor hette Abia, Sakarjas datter. 
14:29:2: Han gjorde hvad rett var i Herrens øine, aldeles som hans far David hadde gjort. 
14:29:3: I sin regjerings første år, i den første måned, åpnet han igjen dørene til Herrens hus og satte dem i stand. 
14:29:4: Så lot han prestene og levittene hente og samlet dem på den åpne plass mot øst. 
14:29:5: Og han sa til dem: Hør på mig, I levitter! Hellige nu eder selv og hellige Herrens, eders fedres Guds hus og få urenheten ut av helligdommen! 
14:29:6: For våre fedre har båret sig troløst at og gjort hvad ondt er i Herrens, vår Guds øine, og forlatt ham; de har vendt sitt åsyn bort fra Herrens tabernakel og vendt ham ryggen. 
14:29:7: De har også stengt dørene til forhallen og slukket lampene og ikke brent røkelse og ikke ofret brennoffer i helligdommen for Israels Gud. 
14:29:8: Derfor er Herrens vrede kommet over Juda og Jerusalem, og han har overgitt dem til mishandling, til ødeleggelse og til spott, således som I ser det med egne øine. 
14:29:9: Og derfor er våre fedre falt for sverdet, og våre sønner og døtre og hustruer er i fangenskap. 
14:29:10: Nu har jeg i sinne å gjøre en pakt med Herren, Israels Gud, så hans brennende vrede kan vende sig bort fra oss. 
14:29:11: Så la det nu ikke skorte eder på iver, mine sønner! For eder har Herren utvalgt til å stå for hans åsyn og tjene ham, til å være hans tjenere og brenne røkelse for ham. 
14:29:12: Da tok levittene fatt på arbeidet - det var Mahat, Amasais sønn, og Joel, Asarjas sønn, av kahatittenes barn, og av Meraris barn Kis, Abdis sønn, og Asarja, Jehallelels sønn, og av gersonittene Joah, Simmas sønn, og Eden, Joahs sønn, 
14:29:13: og av Elisafans barn Simri og Je'uel og av Asafs barn Sakarja og Mattanja, 
14:29:14: og av Hemans barn Jehuel og Sime'i og av Jedutuns barn Semaja og Ussiel. 
14:29:15: De samlet sine brødre og helliget sig og gikk så inn for å rense Herrens hus, således som kongen hadde befalt efter Herrens ord. 
14:29:16: Og prestene gikk inn i det indre av Herrens hus for å rense det, og all den urenhet som de fant i Herrens tempel, førte de ut i forgården til Herrens hus; der tok levittene imot det og bar det ut til Kidron-bekken. 
14:29:17: På den første dag i den første måned begynte de å hellige sig, og på den åttende dag i måneden gikk de inn i Herrens forhall og helliget Herrens hus i åtte dager, og på den sekstende dag i den første måned var de ferdige. 
14:29:18: Derefter gikk de inn til kong Esekias og sa: Vi har renset hele Herrens hus og brennoffer-alteret med alt som til det hører, og skuebrødsbordet med alt som hører til det. 
14:29:19: Og alle de kar som kong Akas i sin troløshet hadde vanhelliget mens han var konge, dem har vi satt i stand og helliget, og nu står de foran Herrens alter. 
14:29:20: Tidlig den næste morgen samlet kong Esekias byens høvdinger og gikk op til Herrens hus. 
14:29:21: Og de kom med syv okser, syv værer, syv lam og syv gjetebukker til syndoffer for riket og for helligdommen og for Juda; og han bød Arons barn, prestene, å ofre dem på Herrens alter. 
14:29:22: De slaktet da oksene, og prestene tok imot blodet og sprengte det på alteret; så slaktet de værene og sprengte blodet på alteret, og så slaktet de lammene og sprengte blodet på alteret. 
14:29:23: Derefter førte de syndoffer-bukkene frem foran kongen og menigheten, og de la sine hender på dem, 
14:29:24: og prestene slaktet dem og sprengte blodet av dem på alteret som syndoffer, til soning for hele Israel; for kongen hadde befalt at brennofferet og syndofferet skulde være for hele Israel. 
14:29:25: Og han lot levittene stille sig op i Herrens hus med cymbler, harper og citarer, således som David og kongens seer Gad og profeten Natan hadde foreskrevet; for det var Herren som hadde gitt denne forskrift gjennem sine profeter. 
14:29:26: Levittene stod med Davids musikkinstrumenter og prestene med trompetene. 
14:29:27: Så bød Esekias å ofre brennofferet på alteret; og på samme tid som ofringen begynte, begynte også sangen for Herren og trompetene å lyde, ledsaget av Davids, Israels konges instrumenter. 
14:29:28: Og hele menigheten kastet sig ned mens sangen lød og trompetene klang, og alt dette varte til ofringen var til ende. 
14:29:29: Da de var ferdig med ofringen, falt de på kne, både kongen og alle de som var der sammen med ham, og de tilbad. 
14:29:30: Så bød kong Esekias og høvdingene at levittene skulde love Herren med Davids og seeren Asafs ord; og de lovet ham med fryd og bøide sig ned og tilbad. 
14:29:31: Og Esekias tok til orde og sa: I har nu fylt eders hånd* for Herren; kom nu hit og før frem slaktoffere og takkoffere til Herrens hus! Da kom menigheten med slaktoffere og takkoffere, og enhver hvis hjerte drev ham til det, kom med brennoffere. # <* 2MO 28, 41.> 
14:29:32: Og tallet på de brennoffer som menigheten førte frem, var sytti okser, hundre værer og to hundre lam; alt dette var til brennoffer for Herren. 
14:29:33: De andre offer som blev frembåret, var seks hundre stykker storfe og tre tusen stykker småfe. 
14:29:34: Men det var ikke prester nok til å flå alle brennofferne, derfor fikk de hjelp av sine brødre levittene til arbeidet var ferdig, og til de andre prester hadde helliget sig; for levittene hadde vist en redeligere vilje til å hellige sig enn prestene. 
14:29:35: Det var også en mengde brennoffer, og dertil kom fettstykkene av takkofferne og de drikkoffer som hørte til brennofferne. Således kom tjenesten i Herrens hus i sin rette skikk. 
14:29:36: Og Esekias og hele folket gledet sig over det som Gud hadde gjort for folket; for alt dette var skjedd med én gang. 
14:30:1: Så sendte Esekias bud omkring til hele Israel og Juda og skrev også brev til Efra'im og Manasse at de skulde komme til Herrens hus i Jerusalem og holde påske for Herren, Israels Gud. 
14:30:2: Og kongen og hans høvdinger og hele menigheten i Jerusalem holdt råd om å holde påske i den annen måned; 
14:30:3: for de kunde ikke holde den med det samme*, fordi det ennu ikke var prester nok som hadde helliget sig, og folket ikke var samlet i Jerusalem. # <* d.e. i den første måned.> 
14:30:4: Det syntes kongen og hele menigheten var rett. 
14:30:5: De vedtok derfor å la utrope i hele Israel, fra Be'erseba like til Dan, at folk skulde komme og holde påske for Herren, Israels Gud, i Jerusalem; for de hadde ikke holdt den i samlet mengde, således som foreskrevet er. 
14:30:6: Så tok da ilbudene avsted med brevene fra kongen og hans høvdinger og drog omkring i hele Israel og Juda, således som kongen hadde befalt, og sa: I Israels barn! Vend om til Herren, Abrahams, Isaks og Israels Gud, så han kan vende sig til den levning av eder som har sloppet unda assyrerkongenes hånd; 
14:30:7: vær ikke som eders fedre og eders brødre, som bar sig troløst at mot Herren, sine fedres Gud, så han overgav dem til ødeleggelse, således som I selv ser. 
14:30:8: Vær nu ikke hårdnakkede som eders fedre! Gi Herren hånden og kom til hans helligdom, som han har helliget for alle tider, og tjen Herren eders Gud, så hans brennende vrede må vende sig bort fra eder. 
14:30:9: For når I vender om til Herren, da skal eders brødre og eders sønner finne barmhjertighet hos dem som holder dem fangne, og komme tilbake til dette land; for Herren eders Gud er nådig og barmhjertig; han vil ikke vende sitt åsyn bort fra eder, dersom I vender om til ham. 
14:30:10: Ilbudene drog omkring fra by til by i Efra'ims og Manasses land og like til Sebulon; men folk bare lo av dem og spottet dem. 
14:30:11: Det var bare nogen få i Aser og Manasse og Sebulon som ydmyket sig og kom til Jerusalem. 
14:30:12: Også i Juda rådet Guds hånd, så han gav dem et samdrektig sinn til å gjøre det som kongen og høvdingene hadde befalt efter Herrens ord. 
14:30:13: Således samlet det sig en mengde folk i Jerusalem for å holde de usyrede brøds høitid i den annen måned - det blev en meget stor forsamling. 
14:30:14: Først fikk de bort de alter som fantes i Jerusalem; også alle røkelsekarene tok de bort og kastet dem i Kidron-bekken. 
14:30:15: Så slaktet de påskelammet på den fjortende dag i den annen måned, og prestene og levittene blev skamfulle og helliget sig og førte frem brennoffere til Herrens hus; 
14:30:16: de stilte sig på sin plass, som det var dem foreskrevet, efter den Guds mann Moses' lov; og prestene sprengte blodet, som levittene rakte dem. 
14:30:17: For det var mange i forsamlingen som ikke hadde helliget sig, og derfor utførte levittene slaktningen av påskelammene for alle dem som ikke var rene, så de kunde hellige dem for Herren. 
14:30:18: For en mengde av folket, mange fra Efra'im og Manasse, Issakar og Sebulon, hadde ikke renset sig, men åt påskelammet uten å iaktta det som var foreskrevet; men Esekias bad for dem og sa: Herren, som er god, vil tilgi 
14:30:19: hver den som har vendt sig av alt sitt hjerte til å søke Gud Herren, sine fedres Gud, om han enn ikke er ren således som helligdommen krever det*! # <* d.e. om han enn ikke er ren efter loven.> 
14:30:20: Og Herren bønnhørte Esekias og helbredet* folket. # <* SLM 41, 5. JES 6, 10.> 
14:30:21: Således holdt de av Israels barn som var til stede i Jerusalem, de usyrede brøds høitid i syv dager med stor glede; og prestene og levittene lovet Herren dag for dag med instrumenter som priste Herrens makt. 
14:30:22: Og Esekias talte vennlig til alle de levitter som hadde vist sig særlig dyktige i Herrens tjeneste; og de åt høitidsofferet i syv dager og ofret takkoffere og lovet Herren, sine fedres Gud. 
14:30:23: Og hele menigheten kom overens om å holde høitid ennu syv dager; og så holdt de høitid med glede i syv dager til. 
14:30:24: For Esekias, Judas konge, gav menigheten tusen okser og syv tusen stykker småfe, og høvdingene gav menigheten tusen okser og ti tusen stykker småfe; og mange prester helliget sig. 
14:30:25: Og hele Judas menighet gledet sig, og prestene og levittene og alle de som var kommet sammen fra Israel, og de fremmede som var kommet fra Israels land eller bodde i Juda. 
14:30:26: Det var stor glede i Jerusalem; for siden Israels konge Salomos, Davids sønns dager hadde det ikke hendt noget sådant i Jerusalem. 
14:30:27: Og de levittiske prester stod op og velsignet folket, og deres røst blev hørt, og deres bønn nådde til himmelen, hans hellige bolig. 
14:31:1: Da de nu var ferdig med alt dette, drog alle de israelitter som var til stede, ut til byene i Juda og sønderslo billedstøttene og hugg Astarte-billedene i stykker og rev ned offerhaugene og alterne i hele Juda og Benjamin og i Efra'im og Manasse, til det intet var igjen av dem; og så vendte alle Israels barn hjem igjen til sine byer, enhver til sin eiendom. 
14:31:2: Så ordnet Esekias prestenes og levittenes skifter, efter som de hørte til det ene eller det annet skifte, så både prestene og levittene fikk hver sin særskilte tjeneste med å bære frem brennoffer og takkoffer, å utføre gudstjenesten og å synge Herrens lov og pris i portene til hans leir. 
14:31:3: Det som kongen gav av sin eiendom, skulde brukes til brennofferne, morgen- og aften-brennofferne og brennofferne på sabbatene og nymånedagene og høitidene, således som skrevet står i Herrens lov. 
14:31:4: Og han bød folket som bodde i Jerusalem, at de skulde gi prestene og levittene deres del, så de kunde holde fast ved Herrens lov. 
14:31:5: Da denne befaling blev kjent, gav Israels barn førstegrøde i rikelig mengde, både av korn og most og olje og honning og av all annen grøde på marken, og de kom med tiende av alt sammen i rikelig mengde. 
14:31:6: De av Israels og Judas barn som bodde i Judas byer, kom også med tiende av storfe og småfe og tiende av de hellige gaver som var helliget til Herren deres Gud, og la dem op haug ved haug. 
14:31:7: I den tredje måned begynte de å hope op haugene, og i den syvende måned blev de ferdig med det. 
14:31:8: Og da Esekias og høvdingene kom og så haugene, priste de Herren og hans folk Israel. 
14:31:9: Da så Esekias spurte prestene og levittene om haugene, 
14:31:10: svarte ypperstepresten Asarja av Sadoks hus: Siden de begynte å komme med gaver til Herrens hus, har vi ett oss mette og fått rikelig tilovers; for Herren har velsignet sitt folk, så vi har fått all denne rikdom tilovers. 
14:31:11: Da bød Esekias at det skulde innrettes forrådskammere i Herrens hus, og da det var gjort, 
14:31:12: kom folket ærlig og redelig med førstegrøden og tienden og de hellige gaver og la dem inn der. Levitten Konanja hadde opsyn med dette og næst efter ham hans bror Sime'i. 
14:31:13: Men Jehiel og Asasja og Nahat og Asael og Jerimot og Josabad og Eliel og Jismakja og Mahat og Benaja var opsynsmenn under Konanja og hans bror Sime'i efter pålegg av kong Esekias og Asarja, forstanderen for Guds hus. 
14:31:14: Levitten Kore, Jimnas sønn, som var dørvokter mot øst, hadde opsynet over de frivillige gaver til Gud og skulde utdele Herrens offergaver og de høihellige gaver. 
14:31:15: Under ham stod Eden og Minjamin og Jesua og Semaja, Amarja og Sekanja; de skulde være tillitsmenn i prestenes byer og utdele gavene til sine brødre efter deres skifter, både til store og små, 
14:31:16: med undtagelse av alle dem av mannkjønn, fra treårsalderen og opover, som var opskrevet i ættelistene, og som kom til Herrens hus for hver dag å få hvad de den dag trengte under den tjeneste de hadde å vareta efter sine skifter - 
14:31:17: prestene var opskrevet i listene efter sine familier, og levittene fra tyveårsalderen og opover, efter det de hadde å vareta i sin skifter - . 
14:31:18: De skulde også utdele gavene til dem som var opskrevet av deres små barn, hustruer, sønner og døtre, så mange de var; for med troskap helliget de sig til sin tjeneste. 
14:31:19: For dem av Arons sønner, prestene, som bodde på løkkene omkring sine byer, var det i hver by nogen navngitte menn som skulde utdele til alt mannkjønn blandt prestene det som tilkom dem, og likeså til alle dem av levittene som var opskrevet i ættelistene. 
14:31:20: Således gjorde Esekias i hele Juda, og han gjorde hvad godt og rett og sant var i Herrens, hans Guds øine. 
14:31:21: Og alt det som han tok sig fore, idet han søkte sin Gud, enten det gjaldt tjenesten i Guds hus, eller det gjaldt loven og budet, det gjorde han av alt sitt hjerte, og det lyktes for ham. 
14:32:1: Nogen tid efterat Esekias hadde utført dette og vist sådan troskap, kom kongen i Assyria Sankerib og drog inn i Juda; han kringsatte de faste byer og tenkte han skulde få dem i sin makt. 
14:32:2: Da Esekias fikk vite at Sankerib var kommet og hadde i sinne å angripe Jerusalem, 
14:32:3: rådslo han med sine høvdinger og sine ypperste stridsmenn om å tilstoppe vannet i kildene utenfor byen; og de hjalp ham. 
14:32:4: Det samlet sig da meget folk, og de tilstoppet alle kildene og den bekk som rant midt igjennem landet; for de sa: Hvorfor skulde assyrerkongene komme og finne rikelig vann? 
14:32:5: Så tok han mot til sig og bygget op igjen hele den mur som var revet ned, og førte den helt op til tårnene, og utenfor bygget han en annen mur; han satte også Millo i Davids stad i fast stand og lot gjøre kastevåben i mengde og skjold. 
14:32:6: Og han satte krigshøvedsmenn over folket og samlet dem hos sig på plasser ved byporten og talte til dem for å vekke deres mot og sa: 
14:32:7: Vær frimodige og sterke, frykt ikke og reddes ikke for kongen i Assyria og hele den hær han har med sig! For med oss er der en større enn der er med ham; 
14:32:8: med ham er en arm av kjød, men med oss er Herren vår Gud, og han skal hjelpe oss og føre våre kriger. Og folket stolte på Esekias' Judas konges ord. 
14:32:9: Derefter sendte kongen i Assyria Sankerib, som selv lå for Lakis med hele sin krigsmakt, sine tjenere til Jerusalem, til Judas konge Esekias og til alle dem av Juda som var i Jerusalem, og lot si: 
14:32:10: Så sier Sankerib, kongen i Assyria: Hvad er det I setter eders lit til, siden I blir sittende innestengt i Jerusalem! 
14:32:11: Esekias forfører eder bare for å la eder dø av hunger og tørst, når han sier: Herren vår Gud skal redde oss av assyrerkongens hånd. 
14:32:12: Har ikke denne Esekias tatt hans offerhauger og hans altere bort og sagt til Juda og Jerusalem: Bare foran ett alter skal I tilbede, og på det skal I brenne røkelse? 
14:32:13: Vet I ikke hvad jeg har gjort, jeg og mine fedre, med alle folkene rundt om i landene? Har vel de guder som dyrkes av folkene rundt om i landene, maktet å redde sine land av min hånd? 
14:32:14: Hvem av alle gudene hos disse folk som mine fedre har utryddet, er det som har maktet å redde sitt folk av min hånd, og så skulde eders Gud makte å redde eder av min hånd? 
14:32:15: Så la nu ikke Esekias få narret eder! La ham ikke således forføre eder! Tro ham ikke! For ingen gud hos noget folk eller rike har maktet å redde sitt folk av min hånd eller av mine fedres hånd; hvor meget mindre vil da eders guder kunne redde eder av min hånd! 
14:32:16: Og ennu flere ord talte hans tjenere mot Gud Herren og mot hans tjener Esekias. 
14:32:17: Han skrev også et brev for å håne Herren, Israels Gud, og tale mot ham; i det stod det: Likesom folkenes guder rundt om i landene ikke har reddet sine folk av min hånd, således skal heller ikke Esekias' Gud redde sitt folk av min hånd. 
14:32:18: Og de ropte med høi røst på jødisk til folket i Jerusalem som stod på muren, for å skremme og forferde dem, så de kunde innta byen; 
14:32:19: og de talte om Jerusalems Gud på samme vis som om folkenes guder på jorden, de som er et verk av menneskehender. 
14:32:20: Da kong Esekias og profeten Esaias, Amos' sønn hørte dette, bad de og ropte til himmelen. 
14:32:21: Og Herren sendte en engel som tilintetgjorde alle de djerve stridsmenn og fyrstene og høvedsmennene i assyrerkongens leir, så han med skam måtte vende tilbake til sitt land. Og da han engang gikk inn i sin guds hus, kom nogen av hans egne sønner og hugg ham ned der med sverdet. 
14:32:22: Således frelste Herren Esekias og Jerusalems innbyggere av assyrerkongen Sankeribs hånd og av alle andre fienders hånd og vernet dem til alle sider. 
14:32:23: Det var mange som kom til Jerusalem med gaver til Herren og med kostbare ting til Judas konge Esekias; og han blev efter dette høit æret blandt alle folk. 
14:32:24: Ved den tid blev Esekias dødssyk; da bad han til Herren, og Herren svarte ham og gav ham et underfullt tegn. 
14:32:25: Men Esekias gjengjeldte ikke den velgjerning som var vist ham; han blev overmodig i sitt hjerte, og det kom vrede over ham og over Juda og Jerusalem. 
14:32:26: Da ydmyket Esekias sig, fordi han hadde vært overmodig i sitt hjerte, og det samme gjorde Jerusalems innbyggere; derfor kom Herrens vrede ikke over dem så lenge Esekias levde. 
14:32:27: Esekias hadde overmåte stor rikdom og ære; han gjorde sig skattkammere, hvor han gjemte sølv og gull og dyre stener og krydderier og skjold og alle slags kostbare ting; 
14:32:28: og han gjorde sig forrådshus for det som blev avlet av korn og most og olje, og fjøs for alle slags fe, og han la sig til buskap for sine fjøs. 
14:32:29: Likeledes bygget han byer og fikk sig en mengde med småfe og storfe; for Gud hadde gitt ham overmåte meget gods. 
14:32:30: Det var også Esekias som tilstoppet det øvre utløp av Gihonvannet og ledet det ned vestenfor Davids stad. I alt det han tok sig fore, hadde han lykke med sig, 
14:32:31: således også da sendemennene kom fra Babels fyrster, som sendte bud til ham for å spørre om det underfulle tegn som var skjedd i landet; Gud forlot ham bare for å sette ham på prøve og for å kjenne alt hvad som var i hans hjerte*. # <* 5MO 8, 2.> 
14:32:32: Hvad som ellers er å fortelle om Esekias og hans fromme gjerninger, det er opskrevet i profeten Esaias', Amos' sønns syn i boken om Judas og Israels konger. 
14:32:33: Og Esekias la sig til hvile hos sine fedre, og de begravde ham ved opgangen til Davids efterkommeres graver. Hele Juda og Jerusalems innbyggere viste ham stor ære ved hans død. Og hans sønn Manasse blev konge i hans sted. 
14:33:1: Manasse var tolv år gammel da han blev konge, og han regjerte fem og femti år i Jerusalem. 
14:33:2: Han gjorde hvad ondt var i Herrens øine, efter de vederstyggelige skikker hos de hedningefolk som Herren hadde drevet bort for Israels barn. 
14:33:3: Han bygget op igjen de offerhauger som hans far Esekias hadde revet ned, og reiste altere for Ba'alene og gjorde Astarte-billeder, og han tilbad hele himmelens hær og dyrket den. 
14:33:4: Og han bygget altere i Herrens hus, enda Herren hadde sagt: I Jerusalem skal mitt navn bo til evig tid. 
14:33:5: For hele himmelens hær bygget han altere i begge forgårdene til Herrens hus. 
14:33:6: Han lot sine sønner gå gjennem ilden i Hinnoms sønns dal og gav sig av med å spå av skyene og tyde varsler og drive trolldom, og han fikk sig dødningemanere og sannsigere; han gjorde meget som var ondt i Herrens øine, så han vakte hans harme. 
14:33:7: Og han satte det utskårne billede han hadde gjort, i Guds hus, hvorom Gud hadde sagt til David og hans sønn Salomo: I dette hus og i Jerusalem, som jeg har utvalgt blandt alle Israels stammer, vil jeg la mitt navn bo til evig tid, 
14:33:8: og jeg vil ikke mere la Israel vandre hjemløs bort fra det land jeg bestemte for deres fedre, så sant de bare gir akt på å gjøre alt det jeg har pålagt dem, efter hele den lov, de bud og de forskrifter de fikk gjennem Moses. 
14:33:9: Men Manasse forførte Juda og Jerusalems innbyggere, så de gjorde ennu mere ondt enn de hedningefolk Herren hadde utryddet for Israels barn. 
14:33:10: Og Herren talte til Manasse og hans folk, men de aktet ikke på det. 
14:33:11: Derfor lot Herren assyrerkongens hærførere komme over dem, og de fanget Manasse med kroker og bandt ham med to kobberlenker og førte ham til Babel. 
14:33:12: Men da han var stedt i nød, bønnfalt han Herren sin Gud og ydmyket sig dypt for sine fedres Guds åsyn. 
14:33:13: Og da han bad til Herren, bønnhørte han ham; han hørte hans ydmyke begjæring og førte ham tilbake til Jerusalem, så han fikk sitt rike igjen. Da sannet Manasse at Herren er Gud. 
14:33:14: Siden bygget han en ytre mur for Davids stad vestenfor Gihon i dalen og til inngangen gjennem Fiske-porten og lot den gå rundt omkring Ofel, og han gjorde den meget høi. Og i alle de faste byer i Juda innsatte han krigshøvedsmenn. 
14:33:15: Han tok bort de fremmede guder og avgudsbilledet fra Herrens hus og alle de alter han hadde bygget på det berg hvor Herrens hus stod, og i Jerusalem, og kastet dem utenfor byen. 
14:33:16: Så satte han Herrens alter i stand og ofret takkoffere og lovoffere på det; og han bad Juda å tjene Herren, Israels Gud. 
14:33:17: Dog ofret folket ennu på haugene, men bare til Herren sin Gud. 
14:33:18: Hvad som ellers er å fortelle om Manasse og om hans bønn til sin Gud og om de ord som seerne talte til ham i Herrens, Israels Guds navn, det er opskrevet i Israels kongers krønike. 
14:33:19: Og hans bønn og bønnhørelse og all hans synd og troløshet og de steder hvor han bygget offerhauger og satte op Astarte-billedene og de utskårne billeder, før han ydmyket sig, derom er det skrevet i Hosais krønike. 
14:33:20: Og Manasse la sig til hvile hos sine fedre, og de begravde ham i hans hus; og hans sønn Amon blev konge i hans sted. 
14:33:21: Amon var to og tyve år gammel da han blev konge, og han regjerte to år i Jerusalem. 
14:33:22: Han gjorde hvad ondt var i Herrens øine, likesom hans far Manasse hadde gjort; alle de utskårne billeder som hans far Manasse hadde gjort, dem ofret Amon til og dyrket dem. 
14:33:23: Men han ydmyket sig ikke for Herrens åsyn, som hans far Manasse hadde gjort; han - Amon - dynget skyld på skyld. 
14:33:24: Hans tjenere sammensvor sig mot ham og drepte ham i hans hus. 
14:33:25: Men landets folk slo ihjel alle dem som hadde sammensvoret sig mot kong Amon, og så gjorde de hans sønn Josias til konge i hans sted. 
14:34:1: Josias var åtte år gammel da han blev konge, og han regjerte en og tretti år i Jerusalem. 
14:34:2: Han gjorde hvad rett var i Herrens øine, og vandret på sin far Davids veier; han vek ikke av hverken til høire eller til venstre. 
14:34:3: I det åttende år av sin regjering, da han ennu var en ungdom, begynte han å søke Davids, sin fars Gud, og i det tolvte år begynte han å rense Juda og Jerusalem for offerhaugene og Astarte-billedene og de utskårne og støpte billeder. 
14:34:4: De rev ned Ba'alenes altere, mens han så på det, og solstøttene som stod ovenpå dem, hugg han i stykker, og Astarte-billedene og de utskårne og støpte billeder sønderslo han og knuste dem, og støvet av dem strødde han på de folks graver som hadde ofret til dem, 
14:34:5: og prestenes ben brente han på deres altere. Således renset han Juda og Jerusalem. 
14:34:6: Og rundt omkring i Manasses og Efra'ims og Simeons byer like til Naftali gjennemsøkte han husene, 
14:34:7: og han rev ned alterne og Astarte-billedene, og de utskårne billeder sønderslo han og knuste dem, og alle solstøttene i hele Israels land hugg han i stykker; så vendte han tilbake til Jerusalem. 
14:34:8: Og i det attende år av sin regjering, mens han holdt på å rense landet og Herrens hus, sendte han Safan, Asaljas sønn, og byens høvedsmann Ma'aseja og historieskriveren Joah, Jaokas' sønn, avsted for å sette i stand Herrens, hans Guds hus. 
14:34:9: Da de kom til ypperstepresten Hilkias, gav de fra sig de penger som var kommet inn til Guds hus, og som de levitter som var voktere ved dørtreskelen, hadde samlet inn fra Manasse og Efra'im og hele resten av Israel og fra hele Juda og Benjamin og Jerusalems innbyggere - 
14:34:10: de overgav dem til dem som forestod arbeidet og hadde tilsyn med Herrens hus; og de gav dem til dem som utførte arbeidet - dem som arbeidet i Herrens hus og skulde utbedre huset og sette det i stand, 
14:34:11: til tømmermennene og bygningsmennene til innkjøp av hugne stener og tre til bindingsbjelkene og til å tømre op igjen de bygninger som Judas konger hadde latt forfalle. 
14:34:12: Mennene arbeidet med troskap på verket. Nogen av levittene var satt til å ha tilsyn med dem; det var Jahat og Obadja av Meraris barn og Sakarja og Mesullam av kahatittenes barn og alle de levitter som forstod sig på musikkinstrumenter. 
14:34:13: De hadde også rådighet over bærerne og tilsyn med alle arbeiderne ved hvert enkelt arbeid. Nogen av levittene var også skrivere og opsynsmenn og dørvoktere. 
14:34:14: Da de nu tok ut de penger som var kommet inn til Herrens hus, fant presten Hilkias boken med Herrens lov, som var gitt ved Moses. 
14:34:15: Da tok Hilkias til orde og sa til statsskriveren Safan: Jeg har funnet lovboken i Herrens hus. Og Hilkias overgav boken til Safan. 
14:34:16: Safan bar boken til kongen, og han gav med det samme melding til kongen og sa: Dine tjenere gjør alt som er dem pålagt; 
14:34:17: de har tømt ut de penger som fantes i Herrens hus, og overgitt dem til tilsynsmennene og til dem som forestår arbeidet. 
14:34:18: Og statsskriveren Safan fortalte kongen at presten Hilkias hadde overgitt ham en bok. Og Safan leste op av den for kongen. 
14:34:19: Da kongen hørte lovens ord, sønderrev han sine klær. 
14:34:20: Og kongen bød Hilkias og Akikam, Safans sønn, og Abdon, Mikas sønn, og statsskriveren Safan og kongens tjener Asaja: 
14:34:21: Gå og spør Herren for mig og for dem som er blitt igjen i Israel og Juda, om det som står i den bok som nu er funnet! For stor er Herrens vrede, som er utøst over oss fordi våre fedre ikke har holdt Herrens ord og ikke gjort alt det som skrevet er i denne bok. 
14:34:22: Så gikk Hilkias og de andre som kongen sendte avsted, til profetinnen Hulda, hustru til klædeskammer-vokteren Sallum, sønn av Tokhat, Kasras sønn; hun bodde i Jerusalem i den annen bydel; og de talte med henne således som det var dem pålagt. 
14:34:23: Hun sa til dem: Så sier Herren, Israels Gud: Si til den mann som har sendt eder til mig: 
14:34:24: Så sier Herren: Se, jeg fører ulykke over dette sted og dets innbyggere - alle de forbannelser som er skrevet i den bok de har lest for Judas konge, 
14:34:25: fordi de har forlatt mig og brent røkelse for andre guder for å vekke min harme med alt sine henders verk; min vrede skal utøses over dette sted, og den skal ikke utslukkes. 
14:34:26: Og til Judas konge, som sendte eder for å spørre Herren, til ham skal I si således: Så sier Herren, Israels Gud, om de ord som du har hørt: 
14:34:27: Fordi ditt hjerte blev bløtt, og du ydmyket dig for Guds åsyn da du hørte hans ord mot dette sted og dets innbyggere - fordi du ydmyket dig for mitt åsyn og sønderrev dine klær og gråt for mitt åsyn, så har også jeg hørt, sier Herren. 
14:34:28: Så vil jeg da samle dig til dine fedre, og du skal samles med dem i din grav i fred, og dine øine skal ikke se all den ulykke jeg vil føre over dette sted og dets innbyggere. Med dette svar kom de tilbake til kongen. 
14:34:29: Da sendte kongen bud og lot kalle sammen alle Judas og Jerusalems eldste. 
14:34:30: Og kongen gikk op til Herrens hus, og hver mann av Juda og Jerusalems innbyggere fulgte ham, prestene og levittene og alt folket, både store og små; og han leste op for dem alt det som stod skrevet i paktens bok, den som var funnet i Herrens hus. 
14:34:31: Og kongen stod på sin plass og gjorde den pakt for Herrens åsyn at han vilde følge Herren og holde hans bud og hans vidnesbyrd og hans forskrifter av alt sitt hjerte og av all sin sjel - at han vilde gjøre efter paktens ord, som var skrevet i denne bok. 
14:34:32: Og han lot alle som fantes i Jerusalem og Benjamin, trede inn i pakten; og Jerusalems innbyggere gjorde efter den pakt de hadde inngått med Gud, sine fedres Gud. 
14:34:33: Og Josias tok bort alle vederstyggeligheter fra alle de land som hørte Israels barn til, og han holdt alle som fantes i Israel, til å tjene Herren sin Gud. Så lenge han levde, vek de ikke av fra Herren, sine fedres Gud. 
14:35:1: Så holdt Josias påske for Herren i Jerusalem, og de slaktet påskelammet på den fjortende dag i den første måned. 
14:35:2: Han satte prestene til det de skulde vareta, og styrket dem til tjenesten i Herrens hus. 
14:35:3: Og han sa til levittene, som lærte hele Israel, og som var helliget til Herren: Sett den hellige ark i det hus som Israels konge Salomo, Davids sønn, har bygget! I har ikke mere noget å bære på skuldrene; tjen nu Herren eders Gud og hans folk Israel! 
14:35:4: Gjør eder rede efter eders familier, i eders skifter, således som det er foreskrevet av Israels konge David og av hans sønn Salomo, 
14:35:5: og still eder op i helligdommen efter eders brødres, det menige folks familie-avdelinger, således at det på hver av dem kommer en avdeling av en levitt-familie! 
14:35:6: Slakt så påskelammet og hellige eder og lag det til for eders brødre, så I gjør efter Herrens ord ved Moses! 
14:35:7: Josias gav det menige folk tretti tusen stykker småfe, lam og kje, alt sammen til påskeoffer for alle som var der, og likeledes tre tusen stykker storfe; dette gav kongen av sin egen eiendom. 
14:35:8: Og hans høvdinger gav frivillige gaver til folket, til prestene og levittene; Hilkias og Sakarja og Jehiel, forstanderne for Guds hus, gav prestene to tusen og seks hundre stykker småfe til påskeoffer og tre hundre stykker storfe. 
14:35:9: Og Konanja og hans brødre Semaja og Netanel, og Hasabja og Je'iel og Josabad, de øverste blandt levittene, gav levittene fem tusen stykker småfe til påskeoffer og fem hundre stykker storfe. 
14:35:10: Således kom tjenesten i sin rette skikk, og prestene stod på sin plass og likeså levittene efter sine skifter, således som kongen hadde befalt. 
14:35:11: De* slaktet påskelammene, og prestene sprengte blodet, som de rakte dem, og levittene flådde dem. # <* levittene; 2KR 30, 16.> 
14:35:12: Så skilte de fra brennofferet* og gav det til det menige folk efter deres familie-avdelinger, så de kunde ofre det til Herren, som foreskrevet er i Mose bok. På samme måte gjorde de også med storfeet. # <* de stykker som skulde ofres som brennoffer.> 
14:35:13: Påskelammet stekte de over ilden, som foreskrevet var, og offerkjøttet* kokte de i gryter, kjeler og panner og skyndte sig å bære det ut til hele det menige folk. # <* 2KR 35, 7-9.> 
14:35:14: Så laget de det til for sig selv og for prestene; for prestene, Arons sønner, holdt helt til om natten på med å ofre fettstykkene av brennofferet; derfor laget levittene det til for sig selv og for prestene, Arons sønner. 
14:35:15: Sangerne, Asafs sønner, stod på sin plass, således som David og Asaf og Heman og kongens seer Jedutun hadde foreskrevet, og dørvokterne ved hver sin port; de hadde ikke nødig å gå fra sin tjeneste, for deres brødre, de andre levitter, laget til for dem. 
14:35:16: Således kom hele Herrens tjeneste den dag i sin rette skikk, og de holdt påske og ofret brennoffere på Herrens alter, således som kong Josias hadde befalt. 
14:35:17: De av Israels barn som var til stede, holdt dengang påske og de usyrede brøds høitid i syv dager. 
14:35:18: En påske som denne var ikke blitt holdt i Israel siden profeten Samuels dager, heller ikke hadde nogen av Israels konger holdt en påske som den Josias og prestene og levittene og hele Juda og de av Israel som var til stede, og Jerusalems innbyggere nu holdt. 
14:35:19: Det var først i det attende år av Josias' regjering at en sådan påske blev holdt. 
14:35:20: En tid efterat alt dette hadde gått for sig, og da Josias hadde fått huset i stand, drog kongen i Egypten Neko op for å stride ved Karkemis ved Frat; og Josias drog ut mot ham. 
14:35:21: Da sendte Neko bud til ham og lot si: Hvad har jeg med dig å gjøre, Judas konge? Det er ikke mot dig jeg kommer idag, men mot det kongehus som jeg stadig ligger i krig med*, og Gud har sagt at jeg skulde være snar. La være å stride mot Gud! Han er med mig. Ta dig i vare så han ikke ødelegger dig! # <* d.e. mot Assyrias konge, 2KG 23, 29.> 
14:35:22: Men Josias vendte ikke om fra ham; han forklædde sig og vilde stride med ham og hørte ikke på Nekos ord, som kom fra Guds munn, men drog ut for å stride med ham i Megiddo-dalen. 
14:35:23: Men bueskytterne rammet kong Josias; da sa kongen til sine tjenere: Før mig bort! Jeg er hårdt såret. 
14:35:24: Hans tjenere bar ham da bort fra vognen og kjørte ham på en annen av hans vogner til Jerusalem; der døde han og blev begravet i sine fedres graver; og hele Juda og Jerusalem sørget over Josias. 
14:35:25: Og Jeremias kvad en klagesang over Josias, og alle sangerne og sangerinnene talte i sine klagesanger om Josias, og det har de gjort like til denne dag; det blev til en fast skikk i Israel å synge disse sanger, og de finnes opskrevet blandt klagesangene. 
14:35:26: Hvad som ellers er å fortelle om Josias og om de fromme gjerninger han gjorde i lydighet mot det som skrevet er i Herrens lov, 
14:35:27: hvad som er å fortelle om ham, både i hans første og i hans senere dager, det er opskrevet i boken om Israels og Judas konger. 
14:36:1: Landets folk tok og gjorde Joakas, Josias' sønn, til konge i hans fars sted i Jerusalem. 
14:36:2: Joakas var tre og tyve år gammel da han blev konge, og han regjerte tre måneder i Jerusalem. 
14:36:3: Men kongen i Egypten avsatte ham, så han ikke lenger var konge i Jerusalem, og lot landet bøte hundre talenter sølv og en talent gull. 
14:36:4: Så gjorde kongen i Egypten Neko hans bror Eljakim til konge over Juda og Jerusalem og forandret hans navn til Jojakim, men hans bror Joakas tok han med sig og førte ham til Egypten. 
14:36:5: Jojakim var fem og tyve år gammel da han blev konge, og han regjerte elleve år i Jerusalem. Han gjorde hvad ondt var i Herrens, hans Guds øine. 
14:36:6: Kongen i Babel Nebukadnesar drog op mot ham og lot ham binde med to kobberlenker for å føre ham til Babel. 
14:36:7: Nebukadnesar førte også en del av karene i Herrens hus til Babel og satte dem i sitt tempel i Babel. 
14:36:8: Hvad som ellers er å fortelle om Jojakim og om de vederstyggelige ting han gjorde sig skyldig i, og hvad ondt det ellers fantes hos ham, det er opskrevet i boken om Israels og Judas konger. Og hans sønn Jojakin blev konge i hans sted. 
14:36:9: Jojakin var atten år gammel da han blev konge, og han regjerte tre måneder og ti dager i Jerusalem. Han gjorde hvad ondt var i Herrens øine. 
14:36:10: Ved årsskiftet sendte kong Nebukadnesar krigsfolk avsted og lot ham føre til Babel, og med ham de kostbare kar i Herrens hus, og han gjorde hans bror* Sedekias til konge over Juda og Jerusalem. # <* farbror, 2KG 24, 17.> 
14:36:11: Sedekias var en og tyve år gammel da han blev konge, og han regjerte elleve år i Jerusalem. 
14:36:12: Han gjorde hvad ondt var i Herrens, hans Guds øine; han ydmyket sig ikke for profeten Jeremias, hvis ord kom fra Herrens munn. 
14:36:13: Han gjorde også oprør mot kong Nebukadnesar, som hadde latt ham sverge sig troskap ved Gud; han var hårdnakket og forherdet sitt hjerte, så han ikke vendte om til Herren, Israels Gud. 
14:36:14: Også alle prestenes og folkets høvdinger forsyndet sig storlig og fulgte alle hedningefolkenes vederstyggelige skikker, og de gjorde Herrens hus urent, det som han hadde helliget i Jerusalem. 
14:36:15: Herren, deres fedres Gud, sendte sine ord til dem ved sine sendebud tidlig og sent; for han ynkedes over sitt folk og over sin bolig. 
14:36:16: Men de spottet Guds sendebud og foraktet hans ord og hånte hans profeter, inntil Herrens vrede mot sitt folk blev så sterk at det ikke lenger var nogen lægedom. 
14:36:17: Da lot han kaldeernes konge dra op mot dem, og han drepte deres unge menn med sverdet i deres hellige hus og sparte hverken unggutter eller jomfruer eller gamle og gråhårede - alle gav han i hans hånd. 
14:36:18: Alle karene i Guds hus, både store og små, og skattene i Herrens hus og kongens og hans høvdingers skatter - alt førte han til Babel. 
14:36:19: De brente op Guds hus og rev ned Jerusalems mur; alle dets palasser brente de op med ild, og alle dets kostbare kar blev ødelagt. 
14:36:20: Dem som sverdet hadde levnet, førte han som fanger til Babel, og de blev hans og hans sønners træler, til perserriket fikk makten, 
14:36:21: forat Herrens ord i Jeremias' munn skulde bli opfylt - til landet hadde gjort fyldest for sine sabbatsår; alle de dager det lå øde, hadde det hvile - til sytti år var gått til ende. 
14:36:22: Så var det i perserkongen Kyros' første år; da vakte Herren, forat hans ord i Jeremias' munn skulde opfylles, slike tanker i perserkongen Kyros' ånd at han lot utrope i hele sitt rike og dessuten kunngjøre ved en skrivelse: 
14:36:23: Så sier Kyros, kongen i Persia: Herren, himmelens Gud, har gitt mig alle jordens riker, og han har pålagt mig å bygge ham et hus i Jerusalem i Juda. Er det nogen blandt eder av alt hans folk, så være Herren hans Gud med ham, og han kan dra dit! 
15:1:1: Det var i perserkongen Kyros' første år; da vakte Herren, forat hans ord gjennem Jeremias' munn skulde opfylles, slike tanker i perserkongen Kyros' ånd at han lot utrope i hele sitt rike og dessuten kunngjøre ved en skrivelse: 
15:1:2: Så sier Kyros, kongen i Persia: Herren, himmelens Gud, har gitt mig alle jordens riker, og han har pålagt mig å bygge ham et hus i Jerusalem i Juda. 
15:1:3: Er det nogen blandt eder av alt hans folk, så være hans Gud med ham, og han kan dra op til Jerusalem i Juda og bygge Herrens, Israels Guds hus! Han er den Gud som bor i Jerusalem. 
15:1:4: Og alle som ennu er i live, skal folkene på hvert sted hvor de opholder sig som fremmede, understøtte med sølv og gull, med gods og buskap, foruten de frivillige gaver til Guds hus i Jerusalem. 
15:1:5: Da gjorde Judas og Benjamins familiehoder og prestene og levittene - alle i hvis ånd Gud vakte slike tanker, sig rede til å dra op for å bygge Herrens hus i Jerusalem. 
15:1:6: Og alle som bodde rundt omkring dem, understøttet dem med sølvkar og gull og gods og buskap og kostbare ting, foruten alt det de gav frivillig. 
15:1:7: Og kong Kyros lot hente frem de kar som hadde tilhørt Herrens hus, men som Nebukadnesar hadde ført bort fra Jerusalem og satt inn i sin guds hus. 
15:1:8: Dem lot nu kongen i Persia Kyros hente frem under tilsyn av skattmesteren Mitredat, og han overgav dem efter tall til Sesbassar*, Judas fyrste. # <* den samme som Serubabel, ESR 3, 8; 5, 2. 16.> 
15:1:9: Tallet på dem var: tretti gullfat, tusen sølvfat, ni og tyve offerkniver, 
15:1:10: tretti gullbeger, fire hundre og ti sølvbeger av næst beste slag og tusen andre kar. 
15:1:11: Karene av gull og sølv var i alt fem tusen og fire hundre. Alt dette førte Sesbassar med sig da de bortførte drog op fra Babel til Jerusalem. 
15:2:1: Dette var de menn fra landskapet Juda* som drog hjem fra fangenskapet i det fremmede land - de som kongen i Babel Nebukadnesar hadde bortført til Babel, og som nu vendte tilbake til Jerusalem og Juda, hver til sin by, # <* d.e. Judas rike, som dengang var et landskap i det persiske rike, ESR 5, 8. NEH 11, 3.> 
15:2:2: de som fulgte med Serubabel, Josva, Nehemias, Seraja, Re'elaja, Mordekai, Bilsan, Mispar, Bigvai, Rehum og Ba'ana. - Dette var tallet på mennene av Israels folk: 
15:2:3: Paros' barn, to tusen et hundre og to og sytti; 
15:2:4: Sefatjas barn, tre hundre og to og sytti; 
15:2:5: Arahs barn, syv hundre og fem og sytti; 
15:2:6: Pahat-Moabs barn av Josvas og Joabs efterkommere, to tusen åtte hundre og tolv; 
15:2:7: Elams barn, tusen to hundre og fire og femti; 
15:2:8: Sattus barn, ni hundre og fem og firti; 
15:2:9: Sakkais barn, syv hundre og seksti; 
15:2:10: Banis barn, seks hundre og to og firti; 
15:2:11: Bebais barn, seks hundre og tre og tyve; 
15:2:12: Asgads barn, tusen to hundre og to og tyve; 
15:2:13: Adonikams barn, seks hundre og seks og seksti; 
15:2:14: Bigvais barn, to tusen og seks og femti; 
15:2:15: Adins barn, fire hundre og fire og femti; 
15:2:16: Aters barn av Esekias' ætt, åtte og nitti; 
15:2:17: Besais barn, tre hundre og tre og tyve; 
15:2:18: Joras barn, hundre og tolv; 
15:2:19: Hasums barn, to hundre og tre og tyve; 
15:2:20: Gibbars barn, fem og nitti; 
15:2:21: Betlehems barn*, hundre og tre og tyve; # <* d.e. folk fra Betlehem.> 
15:2:22: Netofas menn, seks og femti; 
15:2:23: Anatots menn, hundre og åtte og tyve; 
15:2:24: Asmavets barn, to og firti; 
15:2:25: Kirjat-Arims, Kefiras og Be'erots barn, syv hundre og tre og firti; 
15:2:26: Ramas og Gebas barn, seks hundre og en og tyve; 
15:2:27: Mikmas' menn, hundre og to og tyve; 
15:2:28: Betels og Ais menn, to hundre og tre og tyve; 
15:2:29: Nebos barn, to og femti; 
15:2:30: Magbis' barn, hundre og seks og femti; 
15:2:31: den annen Elams* barn, tusen to hundre og fire og femti; # <* ESR 2, 7.> 
15:2:32: Harims barn, tre hundre og tyve; 
15:2:33: Lods, Hadids og Onos barn, syv hundre og fem og tyve; 
15:2:34: Jerikos barn, tre hundre og fem og firti; 
15:2:35: Sena'as barn, tre tusen og seks hundre og tretti. 
15:2:36: Av prestene: Jedajas barn av Josvas hus, ni hundre og tre og sytti; 
15:2:37: Immers barn, tusen og to og femti; 
15:2:38: Pashurs barn, tusen to hundre og syv og firti; 
15:2:39: Harims barn, tusen og sytten. 
15:2:40: Av levittene: Josvas og Kadmiels barn av Hodavjas efterkommere, fire og sytti. 
15:2:41: Av sangerne: Asafs barn, hundre og åtte og tyve. 
15:2:42: Av dørvokternes barn: Sallums barn, Aters barn, Talmons barn, Akkubs barn, Hatitas barn, Sobais barn - i alt hundre og ni og tretti. 
15:2:43: Av tempeltjenerne: Sihas barn, Hasufas barn, Tabbaots barn, 
15:2:44: Keros' barn, Siahas barn, Padons barn, 
15:2:45: Lebanas barn, Hagabas barn, Akkubs barn, 
15:2:46: Hagabs barn, Samlais barn, Hanans barn, 
15:2:47: Giddels barn, Gahars barn, Reajas barn, 
15:2:48: Resins barn, Nekodas barn, Gassams barn, 
15:2:49: Ussas barn, Paseahs barn, Besais barn, 
15:2:50: Asnas barn, Me'unims barn, Nefisims barn, 
15:2:51: Bakbuks barn, Hakufas barn, Harhurs barn, 
15:2:52: Basluts barn, Mehidas barn, Harsas barn, 
15:2:53: Barkos' barn, Siseras barn, Tamahs barn, 
15:2:54: Nesiahs barn, Hatifas barn. 
15:2:55: Av Salomos tjeneres barn: Sotais barn, Hassoferets barn, Perudas barn, 
15:2:56: Ja'alas barn, Darkons barn, Giddels barn, 
15:2:57: Sefatjas barn, Hattils barn, Pokeret-Hasseba'ims barn, Amis barn. 
15:2:58: Alle tempeltjenerne og Salomos tjeneres barn var tilsammen tre hundre og to og nitti. 
15:2:59: Og dette var de som drog hjem fra Tel-Melah, Tel-Harsa, Kerub, Addan og Immer, men ikke kunde opgi sin familie og sin ætt, eller om de var av Israel: 
15:2:60: Delajas barn, Tobias' barn, Nekodas barn, seks hundre og to og femti, 
15:2:61: og av prestenes barn: Habajas barn, Hakkos' barn, Barsillais barn, han som hadde tatt en av gileaditten Barsillais døtre til hustru og var blitt opkalt efter dem. 
15:2:62: Disse lette efter sine ættelister, men de fantes ingensteds opskrevet; de blev da utelukket fra prestedømmet som uverdige dertil, 
15:2:63: og stattholderen* sa til dem at de ikke skulde ete av det høihellige, før det fremstod en prest med urim og tummim**. # <* Serubabel.> # <** 2MO 28, 30.> 
15:2:64: Hele menigheten var i alt to og firti tusen tre hundre og seksti 
15:2:65: foruten deres tjenere og tjenestepiker, som var syv tusen tre hundre og syv og tretti. De hadde også med sig to hundre sangere og sangerinner. 
15:2:66: De hadde syv hundre og seks og tretti hester, to hundre og fem og firti mulesler, 
15:2:67: fire hundre og fem og tretti kameler og seks tusen syv hundre og tyve asener. 
15:2:68: Nogen av familiehodene gav, da de kom til Herrens hus i Jerusalem, frivillige gaver til Guds hus, så det kunde gjenreises på sitt gamle sted; 
15:2:69: efter sin evne gav de til arbeidskassen: en og seksti tusen dariker* i gull og fem tusen miner i sølv; dessuten hundre prestekjortler. # <* en persisk mynt.> 
15:2:70: Både prestene og levittene og nogen av det menige folk og sangerne og dørvokterne og tempeltjenerne bosatte sig i sine byer, og hele Israel ellers bodde i sine byer. 
15:3:1: Da den syvende måned kom, og Israels barn bodde i sine byer, samlet folket sig som en mann i Jerusalem. 
15:3:2: Og Josva, Josadaks sønn, og hans brødre prestene og Serubabel, Sealtiels sønn, og hans brødre tok til å bygge alteret for Israels Gud for å ofre brennoffere på det, som skrevet står i den Guds mann Moses' lov. 
15:3:3: De reiste alteret på dets gamle sted, for det var kommet en redsel over dem for de folk som bodde i bygdene rundt omkring; og de ofret på det brennoffere til Herren, både morgen- og aften-brennoffere. 
15:3:4: Så holdt de løvsalenes fest, således som det er foreskrevet, og ofret brennoffer hver dag efter det fastsatte tall og på lovbefalt måte, hver dag de offer som var foreskrevet for den dag. 
15:3:5: Siden ofret de det daglige brennoffer og de offer som hørte til nymånedagene og alle Herrens hellige høitider, og likeledes hver gang nogen kom med et frivillig offer til Herren. 
15:3:6: Fra den første dag i den syvende måned begynte de å ofre brennoffere til Herren, før ennu grunnvollen til Herrens tempel var lagt. 
15:3:7: Så gav de penger til stenhuggerne og tømmermennene og mat og drikke og olje til sidonierne og tyrierne forat de skulde føre sedertømmer fra Libanon sjøveien til Joppe, efter den fullmakt som kongen i Persia Kyros hadde gitt dem. 
15:3:8: I det annet år efterat de var kommet til Guds hus i Jerusalem i den annen måned, tok de fatt på verket, Serubabel, Sealtiels sønn, og Josva, Josadaks sønn, med deres andre brødre, prestene og levittene, og alle de som fra fangenskapet var kommet til Jerusalem. De satte levittene fra tyveårsalderen og opover til å føre tilsyn med arbeidet på Herrens hus. 
15:3:9: Og Josva med sine sønner og brødre og Kadmiel med sine sønner, Hodavjas efterkommere, stod alle som én og hadde tilsyn med dem som utførte arbeidet på Guds hus; og det samme gjorde Henadads efterkommere med sine sønner og brødre, som også hørte til levittene. 
15:3:10: Og da bygningsmennene la grunnvollen til Herrens tempel, blev prestene stilt op i embedsskrud med trompeter, og de levitter som var Asafs efterkommere, med cymbler, for å prise Herren, således som Israels konge David hadde foreskrevet. 
15:3:11: Og de sang Herrens lov og pris, fordi han er god, og hans miskunnhet mot Israel varer evindelig; og alt folket brøt ut i et stort fryderop og priste Herren fordi grunnvollen var lagt til Herrens hus. 
15:3:12: Men mange av prestene og levittene og familiehodene - de gamle som hadde sett det første hus, gråt høit da de så dette hus bli grunnlagt, mens mange jublet høit av glede. 
15:3:13: Folket kunde ikke skjelne lyden av gledesjubelen fra lyden av folkets gråt; for folket jublet så høit at det hørtes lang vei. 
15:4:1: Da nu Judas og Benjamins motstandere hørte at de bortførte bygget et tempel for Herren, Israels Gud, 
15:4:2: kom de til Serubabel og til familiehodene og sa til dem: La oss få være med eder og bygge; for vi søker eders Gud likesom I selv, og til ham har vi ofret helt fra assyrerkongen Asarhaddons dager, han som førte oss hit! 
15:4:3: Da svarte Serubabel og Josva og de andre familiehoder i Israel: Det lar sig ikke gjøre at I er sammen med oss om å bygge et hus for vår Gud; vi vil være alene om å bygge hus for Herren, Israels Gud, således som kong Kyros, kongen i Persia, har befalt oss. 
15:4:4: Men de andre folk som bodde der i landet, gjorde Judas folk motløst og skremte dem fra å bygge. 
15:4:5: De leide nogen folk til å gi råd som var til skade for dem, og fikk således gjort deres forehavende til intet så lenge perserkongen Kyros levde, og siden helt til perserkongen Darius tiltrådte regjeringen. 
15:4:6: Under Ahasverus' regjering, i den første tid av hans kongedømme, skrev de en klage mot dem som bodde i Juda og Jerusalem. 
15:4:7: Og i Artaxerxes' dager skrev Bislam, Mitredat, Tabe'el og hans andre embedsbrødre et brev til Artaxerxes, kongen i Persia; det var skrevet med syrisk skrift og oversatt til syrisk. 
15:4:8: Rådsherren Rehum og statsskriveren Simsai skrev et brev mot Jerusalem til kong Artaxerxes; brevet hadde følgende innhold - 
15:4:9: de som skrev dengang var: rådsherren Rehum og statsskriveren Simsai og deres andre embedsbrødre, de fra Dina og Afarsatka, Tarpela, Persia, Erek, Babel, Susan, Deha og Elam 
15:4:10: og de andre folk som den store og navnkundige Osnappar førte bort og lot bo i byen Samaria og ellers i landet hinsides* elven, og så videre. # <* vestenfor.> 
15:4:11: Dette er en avskrift av det brev som de sendte til ham - til kong Artaxerxes: Dine tjenere mennene hinsides elven, og så videre. 
15:4:12: Det være kongen vitterlig at jødene som drog bort fra det sted hvor du bor, er kommet hit til oss, til Jerusalem, og nu holder på å bygge op igjen den oprørske og onde by og fullføre murene og utbedre grunnvollene. 
15:4:13: Men nu være det kongen vitterlig at dersom denne by blir bygget op igjen og murene fullført, så vil de hverken gi skatt eller toll eller veipenger, og det vil til sist bli til skade for kongene. 
15:4:14: Efterdi vi nu eter palassets salt, og det ikke sømmer sig for oss å se med ro på at det voldes kongen skade, så sender vi nu bud og lar kongen få dette å vite, 
15:4:15: forat du kan la granske i dine fedres krønike; da vil du i krøniken finne og se at denne by har vært en oprørsk by, som har voldt skade for konger og land, og at de har gjort oprør der fra gammel tid; derfor er også denne by blitt ødelagt. 
15:4:16: Så lar vi nu kongen vite at dersom denne by blir bygget op igjen og murene fullført, så vil følgen derav bli at du ikke mere vil ha nogen del igjen av landet hinsides* elven. # <* ESR 4, 10.> 
15:4:17: Da sendte kongen svar til rådsherren Rehum og statsskriveren Simsai og deres andre embedsbrødre, som bodde i Samaria og ellers i landet hinsides elven: Fred, og så videre. 
15:4:18: Det brev I har sendt til oss, er nøiaktig blitt lest op for mig, 
15:4:19: og jeg har gitt befaling til å granske efter, og vi har funnet at denne by fra gammel tid har satt sig op imot kongene, og at frafall og oprør har funnet sted der. 
15:4:20: Mektige konger har rådet over Jerusalem, og de har hersket over alt land hinsides elven, og der blev gitt dem skatt, toll og veipenger. 
15:4:21: Så gi nu befaling til å hindre disse menn, så denne by ikke blir bygget op igjen, før det kommer befaling fra mig, 
15:4:22: og ta eder i vare for å gjøre eder skyldige i nogen forseelse i denne sak, så skaden ikke skal bli verre og kongene lide tap! 
15:4:23: Så snart avskriften av dette brev fra kong Artaxerxes var blitt oplest for Rehum og statsskriveren Simsai og deres embedsbrødre, drog de straks til jødene i Jerusalem og hindret dem med vold og makt. 
15:4:24: Da stanset arbeidet på Guds hus i Jerusalem, og det blev intet gjort med det før i det annet år av perserkongen Darius' regjering. 
15:5:1: Profeten Haggai og Sakarias, Iddos sønn, som også var profet, profeterte for jødene i Juda og Jerusalem; i Israels Guds navn profeterte de for dem. 
15:5:2: Da tok Serubabel, Sealtiels sønn, og Josva, Josadaks sønn, til å bygge på Guds hus i Jerusalem, og Guds profeter var med dem og støttet dem. 
15:5:3: På den tid kom Tatnai, som var stattholder hinsides elven, og Setar-Bosnai og deres embedsbrødre til dem; og de talte således til dem: Hvem har gitt eder befaling til å bygge dette hus og fullføre denne mur? 
15:5:4: Da sa vi til dem hvad de menn hette som opførte denne bygning. 
15:5:5: Men Guds øie våket over jødenes eldste, og de* hindret dem ikke i å bygge så lenge til saken var kommet inn for Darius, og de hadde fått brev fra ham derom. # <* Tatnai o.s.v. ESR 5, 3.> 
15:5:6: Dette er en avskrift av det brev som Tatnai, stattholderen hinsides elven, og Setar-Bosnai og hans embedsbrødre, afarsakittene, som bodde hinsides elven, sendte til kong Darius; 
15:5:7: for de sendte en melding til ham, og i den stod det skrevet: Kong Darius ønsker vi alt godt. 
15:5:8: Det være kongen vitterlig at vi har draget til landskapet Juda, til den store Guds hus, og det blir nu bygget op av store stener, og det legges bjelker i veggene, og arbeidet gjøres med iver og har god fremgang under deres hender. 
15:5:9: Så spurte vi de eldste der og sa til dem: Hvem har gitt eder befaling til å bygge dette hus og fullføre denne mur? 
15:5:10: Vi spurte dem også hvad de hette, så vi kunde la dig få vite det og skrive op for dig navnene på de menn som er deres overhoder. 
15:5:11: Dette var det svar de gav oss: Vi er tjenere for himmelens og jordens Gud, og vi bygger op igjen det hus som allerede for mange år siden var bygget her; det var en stor konge i Israel som bygget det og fullførte det. 
15:5:12: Men fordi våre fedre hadde vakt himmelens Guds vrede, gav han dem i kaldeeren Nebukadnesars, kongen i Babels hånd, og han ødela dette hus og førte folket bort til Babel. 
15:5:13: Men i Kyros', kongen i Babels første år gav kong Kyros befaling til å bygge op igjen dette Guds hus. 
15:5:14: Kong Kyros lot også de kar av gull og sølv som hadde tilhørt Guds hus, men som Nebukadnesar hadde tatt ut av templet i Jerusalem og ført til templet i Babel, ta ut av templet i Babel, og de blev overgitt til en som hette Sesbassar, som han hadde satt til stattholder. 
15:5:15: Og han sa til ham: Ta disse kar og dra avsted og sett dem inn i templet i Jerusalem, og la Guds hus bli bygget op igjen på sitt sted! 
15:5:16: Så kom da denne Sesbassar og la grunnvollene til Guds hus i Jerusalem, og fra den tid og til nu har de bygget på det, men det er ennu ikke fullført. 
15:5:17: Dersom nu kongen så synes, så la det bli gransket i kongens skattkammer der i Babel om det er så at kong Kyros har gitt befaling til å bygge dette Guds hus i Jerusalem, og kongen la oss så få vite hvad som er hans vilje i denne sak! 
15:6:1: Da gav kong Darius befaling til å granske efter i arkivet, som var lagt ned i skattkammeret i Babel. 
15:6:2: Og i borgen Ahmeta, som ligger i landskapet Media, blev det funnet en skriftrull; og i den stod det skrevet således til ihukommelse: 
15:6:3: I kong Kyros' første år gav kong Kyros denne befaling: Hvad Guds hus i Jerusalem vedkommer, så skal huset bygges op igjen, så det blir et sted hvor folk kan bære frem offer; dets grunnvoller skal legges på ny; det skal være seksti alen høit og seksti alen bredt, 
15:6:4: med tre lag store stener og ett lag nytt tømmer; omkostningene skal utredes av kongens hus. 
15:6:5: De kar av gull og sølv som hørte til Guds hus, men som Nebukadnesar tok ut av templet i Jerusalem og førte til Babel, skal også gis tilbake, så de igjen kommer til sitt sted i templet i Jerusalem; de skal settes i Guds hus. 
15:6:6: Så skal nu du, Tatnai, stattholder hinsides elven, og du, Setar-Bosnai, og eders embedsbrødre, afarsakittene, som bor hinsides elven, holde eder borte derfra! 
15:6:7: La arbeidet på dette Guds hus foregå uhindret! La jødenes stattholder og deres eldste bygge dette Guds hus på dets sted! 
15:6:8: Og jeg har gitt befaling om hvorledes I skal gå frem mot disse jødenes eldste, så dette Guds hus kan bli bygget: Av de inntekter som kongen har av skatten fra landet hinsides elven, skal omkostningene nøiaktig utredes til disse menn, så arbeidet ikke skal bli hindret. 
15:6:9: Og hvad som trenges, både kalver og værer og lam til brennoffer for himmelens Gud, hvete, salt, vin og olje, det skal efter opgivende av prestene i Jerusalem gis dem dag for dag uten avkortning, 
15:6:10: så de kan bære frem offer til en velbehagelig duft for himmelens Gud og bede for kongens og hans barns liv. 
15:6:11: Jeg har også gitt befaling om at dersom nogen gjør mot dette påbud, så skal en bjelke rives ut av hans hus, og på den skal han henges op og nagles fast, og hans hus skal gjøres til en møkkdynge, fordi han har båret sig således at. 
15:6:12: Måtte så den Gud som har latt sitt navn bo der, slå ned alle konger og folk som strekker ut sin hånd for å gjøre mot dette påbud og for å ødelegge dette Guds hus i Jerusalem! Jeg, Darius, har gitt denne befaling, den skal utføres nøiaktig. 
15:6:13: Så gjorde da Tatnai, stattholderen hinsides elven, og Setar-Bosnai og deres embedsbrødre nøiaktig således som kong Darius hadde foreskrevet. 
15:6:14: Og jødenes eldste blev ved å bygge og gjorde god fremgang, mens profeten Haggai og Sakarias, Iddos sønn, støttet dem med sin profetiske tale; de bygget og fullførte arbeidet efter Israels Guds befaling og efter Kyros' og Darius' og perserkongen Artaxerxes' befaling. 
15:6:15: Så blev da dette hus fullt ferdig til den tredje dag i måneden adar i det sjette år av kong Darius' regjering. 
15:6:16: Og Israels barn, prestene og levittene og de andre som var kommet hjem fra fangenskapet, holdt høitid og innvidde dette Guds hus med glede. 
15:6:17: De ofret ved innvielsen av dette Guds hus hundre okser, to hundre værer, fire hundre lam og til syndoffere for hele Israel tolv gjetebukker efter tallet på Israels stammer. 
15:6:18: Og de innsatte prestene efter deres skifter og levittene efter deres avdelinger til å utføre gudstjenesten i Jerusalem, således som det var foreskrevet i Moseboken. 
15:6:19: Så holdt de hjemkomne påske på den fjortende dag i den første måned. 
15:6:20: For prestene og levittene hadde renset sig og var alle som én rene, og de slaktet påskelammet for alle de hjemkomne og for sine brødre prestene og for sig selv. 
15:6:21: Så åt Israels barn påskelammet, både de av de bortførte som var kommet tilbake, og alle de som hadde skilt sig fra de i landet boende hedningers urenhet og gitt sig i lag med dem for å søke Herren, Israels Gud. 
15:6:22: Og de holdt de usyrede brøds høitid i syv dager med glede; for Herren hadde gledet dem og vendt assyrerkongens hjerte til dem, så han støttet dem i arbeidet på Guds hus - Israels Guds hus. 
15:7:1: Nogen tid efter, under perserkongen Artaxerxes' regjering, drog Esras, sønn av Seraja, sønn av Asarja, sønn av Hilkias, 
15:7:2: sønn av Sallum, sønn av Sadok, sønn av Akitub, 
15:7:3: sønn av Amarja, sønn av Asarja, sønn av Merajot, 
15:7:4: sønn av Serahja, sønn av Ussi, sønn av Bukki, 
15:7:5: sønn av Abisua, sønn av Pinehas, sønn av Eleasar, sønn av ypperstepresten Aron - 
15:7:6: denne Esras drog op fra Babel. Han var en skriftlærd mann, vel kjent med Mose lov, den som Herren, Israels Gud, hadde gitt. Kongen gav ham alt det han bad om; for Herren hans Gud holdt sin hånd over ham. 
15:7:7: Nogen av Israels barn og av prestene, levittene, sangerne, dørvokterne og tempeltjenerne drog med ham op til Jerusalem i kong Artaxerxes' syvende år. 
15:7:8: Han kom til Jerusalem i den femte måned; det var i kongens syvende år. 
15:7:9: For på den første dag i den første måned begynte han å ordne alt til ferden fra Babel, og på den første dag i den femte måned kom han til Jerusalem; for hans Gud holdt sin gode hånd over ham. 
15:7:10: For Esras hadde satt sin hu til å granske Herrens lov og gjøre efter den og til å lære folk lov og rett i Israel. 
15:7:11: Dette er en avskrift av det brev som kong Artaxerxes gav presten Esras, den skriftlærde, som var kyndig i de bud og lover som Herren hadde gitt Israel: 
15:7:12: Artaxerxes, kongenes konge, til presten Esras, han som er full-lært i himmelens Guds lov, og så videre. 
15:7:13: Jeg har gitt befaling om at alle de av Israels folk og av dets prester og levitter i mitt rike som har lyst til å dra til Jerusalem, kan dra med dig, 
15:7:14: siden du er sendt av kongen og hans syv rådgivere til å se efter hvorledes det er med Juda og Jerusalem, efter din Guds lov, som du har i hende, 
15:7:15: og til å føre dit det sølv og gull som kongen og hans rådgivere frivillig har gitt Israels Gud, han som har sin bolig i Jerusalem, 
15:7:16: og likeledes alt det sølv og gull som du kan få i hele Babels landskap, og de frivillige gaver som folket og prestene vil gi til sin Guds hus i Jerusalem. 
15:7:17: Derfor skal du for disse penger samvittighetsfullt kjøpe okser, værer og lam med tilhørende matoffere og drikkoffere; og du skal ofre dem på alteret i eders Guds hus i Jerusalem. 
15:7:18: Og hvad du og dine brødre finner det riktig å gjøre med resten av sølvet og gullet, det kan I gjøre efter eders Guds vilje. 
15:7:19: Men de kar som du har fått til tjenesten i din Guds hus, dem skal du gi fra dig for Jerusalems Guds åsyn. 
15:7:20: Hvad du ellers må utrede til din Guds hus av nødvendige utgifter, det skal du utrede av kongens skattkammer. 
15:7:21: Og jeg, kong Artaxerxes, har gitt befaling til alle skattmestere hinsides elven at alt hvad presten Esras, han som er kyndig i himmelens Guds lov, krever av eder, det skal nøiaktig ydes, 
15:7:22: inntil hundre talenter sølv, hundre kor hvete, hundre bat vin og hundre bat olje, og salt uten foreskrevet mål. 
15:7:23: Alt hvad himmelens Gud befaler, det skal nøiaktig utføres for himmelens Guds hus, så det ikke skal komme vrede over kongens og hans sønners rike. 
15:7:24: Og eder lar vi vite at ingen skal ha makt til å pålegge nogen av prestene, levittene, sangerne, dørvokterne, tempeltjenerne eller nogen annen som har en tjeneste ved dette Guds hus, skatt, toll eller veipenger. 
15:7:25: Og du, Esras, skal efter den visdom du har fått av din Gud, innsette dommere og lovkyndige til å dømme alt folket hinsides elven, alle dem som kjenner din Guds lover; og om nogen ikke kjenner dem, så skal I lære ham å kjenne dem. 
15:7:26: Og om nogen ikke gjør efter din Guds lov og efter kongens lov, så skal der samvittighetsfullt holdes dom over ham, enten det nu fører til døden eller til landflyktighet eller til bøter eller til bånd og fengsel. 
15:7:27: Lovet være Herren, våre fedres Gud, som gav kongen slikt i sinne, så Herrens hus i Jerusalem kunde bli prydet, 
15:7:28: og som lot mig finne nåde hos kongen og hans rådgivere og hos alle kongens mektige høvdinger! Jeg kjente mig sterk, fordi Herren min Gud holdt sin hånd over mig, og jeg fikk samlet nogen av Israels overhoder til å dra op sammen med mig. 
15:8:1: Dette er de familiehoder som under kong Artaxerxes' regjering drog op med mig fra Babel, og som her skal opregnes efter sine ætter: 
15:8:2: Av Pinehas' barn Gersom; av Itamars barn Daniel; av Davids barn Hattus; 
15:8:3: av Sekanjas barn - av Paros' barn - Sakarja, og med ham innførtes i ættetavlen hundre og femti menn; 
15:8:4: av Pahat-Moabs barn Eljoenai, Serahjas sønn, og med ham to hundre menn; 
15:8:5: av Sekanjas barn Jahasiels sønn, og med ham tre hundre menn; 
15:8:6: av Adins barn Ebed, Jonatans sønn, og med ham femti menn; 
15:8:7: av Elams barn Jesaja, Ataljas sønn, og med ham sytti menn; 
15:8:8: av Sefatjas barn Sebadja, Mikaels sønn, og med ham åtti menn; 
15:8:9: av Joabs barn Obadja, Jehiels sønn, og med ham to hundre og atten menn; 
15:8:10: av Selomits barn Josifjas sønn, og med ham hundre og seksti menn; 
15:8:11: av Bebais barn Sakarja, Bebais sønn, og med ham åtte og tyve menn; 
15:8:12: av Asgads barn Johanan, Hakkatans sønn, og med ham hundre og ti menn; 
15:8:13: av Adonikams barn nogen som kom senere* - de hette Elifelet, Je'uel og Semaja, og med dem seksti menn; # <* sml. ESR 2, 13.> 
15:8:14: av Bigvais barn Utai og Sabbub, og med dem sytti menn. 
15:8:15: Jeg samlet dem ved den elv som løper ut i Ahava; der lå vi i leir tre dager. Men da jeg gav nøiere akt på folket og på prestene, fant jeg ingen av Levis barn der. 
15:8:16: Da sendte jeg bud efter overhodene Elieser, Ariel, Semaja og Elnatan og Jarib og Elnatan og Natan og Sakarja og Mesullam og lærerne Jojarib og Elnatan. 
15:8:17: Dem sendte jeg ut til Iddo, overhodet i Kasifja, og jeg la dem ord i munnen som de skulde overtale Iddo og hans bror med, tempeltjenerne i Kasifja, til å sende oss tjenere for vår Guds hus. 
15:8:18: Og da Gud holdt sin gode hånd over oss, sendte de oss en forstandig mann som hette Serebja, en efterkommer av Mahli, som var sønn av Israels sønn Levi, og hans sønner og hans brødre, atten i tallet, 
15:8:19: og Hasabja og med ham Jesaja av Meraris barn, hans brødre og deres sønner, tyve i tallet, 
15:8:20: og av de tempeltjenere som David og høvdingene hadde gitt levittene til tjenere, to hundre og tyve menn, som alle var nevnt ved navn. 
15:8:21: Så lot jeg der, ved Ahavaelven, utrope en faste, forat vi skulde ydmyke oss for vår Guds åsyn og bede ham om en lykkelig reise for oss og våre barn og all vår eiendom. 
15:8:22: For jeg undså mig for å be kongen om krigsmakt og hestfolk til å hjelpe oss mot fiender på veien, siden vi hadde sagt til kongen: Vår Gud holder sin hånd over alle dem som søker ham, og lar det gå dem vel, men hans makt og hans vrede er over alle dem som forlater ham. 
15:8:23: Så fastet vi og bad vår Gud om hjelp, og han bønnhørte oss. 
15:8:24: Siden skilte jeg ut tolv av de øverste prester og Serebja og Hasabja og med dem ti av deres brødre; 
15:8:25: dem tilveide jeg sølvet og gullet og karene, den gave til vår Guds hus som kongen og hans rådgivere og høvdinger og alle de israelitter som bodde der, hadde gitt. 
15:8:26: Jeg tilveide dem seks hundre og femti talenter sølv og sølvkar til en verdi av hundre talenter og dertil hundre talenter gull 
15:8:27: og tyve gullbeger til en verdi av tusen dariker og to kar av fint skinnende kobber, kostelige som gull. 
15:8:28: Og jeg sa til dem: I er helliget til Herren, og karene er helliget til ham, og sølvet og gullet er en frivillig gave til Herren, eders fedres Gud. 
15:8:29: Våk nu over det og ta vare på det til I kan veie det ut i Jerusalem for de øverste prester og levitter og for Israels familiehoder, i kammerne i Herrens hus. 
15:8:30: Så tok prestene og levittene imot sølvet og gullet og karene for å føre det til Jerusalem, til vår Guds hus. 
15:8:31: Vi brøt op fra Ahava-elven på den tolvte dag i den første måned for å dra til Jerusalem, og vår Gud holdt sin hånd over oss og fridde oss underveis fra fiender og folk som lå i bakhold mot oss. 
15:8:32: Da vi kom til Jerusalem, hvilte vi der tre dager. 
15:8:33: Men på den fjerde dag blev sølvet og gullet og karene veid i vår Guds hus og overgitt til presten Meremot, Urias sønn; og sammen med ham var Eleasar, sønn av Pinehas, til stede og tok imot og likeså levittene Josabad, sønn av Josva, og Noadja, sønn av Binnui. 
15:8:34: Det blev alt overgitt efter tall og vekt; og hele vekten blev på samme tid optegnet. 
15:8:35: De bortførte som var kommet tilbake fra fangenskapet, ofret brennoffere til Israels Gud; de ofret tolv okser for hele Israel, seks og nitti værer, syv og sytti lam og tolv syndoffer-bukker - alt sammen til brennoffer for Herren. 
15:8:36: Så overgav de kongens befalinger til kongens stattholdere og landshøvdinger hinsides elven, og de drog omsorg både for folket og for Guds hus. 
15:9:1: Da alt dette var fullført, kom høvdingene til mig og sa: Hverken Israels folk eller prestene og levittene har skilt sig fra folkene rundt om i landene, som de burde ha gjort på grunn av de vederstyggelige skikker som har rådet blandt dem - blandt kana'anittene, hetittene, ferisittene, jebusittene, ammonittene, moabittene, egypterne og amorittene. 
15:9:2: For de har tatt hustruer for sig og sine sønner blandt deres døtre, så den hellige ætt har blandet sig med folkene i landene, og høvdingene og forstanderne har vært de første til å gjøre sig skyldige i denne utroskap. 
15:9:3: Da jeg hørte dette, sønderrev jeg min kjortel og min kappe og rev hår av hodet og skjegget og satt i stum sorg. 
15:9:4: Da samlet de sig hos mig alle de som på grunn av Israels Guds ord var forferdet over de hjemkomnes utroskap, mens jeg satt der i stum sorg like til aftenofferet skulde frembæres. 
15:9:5: Men ved tiden for aftenofferet reiste jeg mig fra mitt sørgesete i min sønderrevne kjortel og min sønderrevne kappe, og jeg kastet mig på kne og rakte ut mine hender til Herren min Gud 
15:9:6: og sa: Min Gud! Jeg skammer mig og blues for å løfte mitt ansikt op mot dig, min Gud! For våre misgjerninger er vokset oss over hodet, og vår skyld er blitt så stor at den når til himmelen. 
15:9:7: Fra våre fedres dager har vi vært i stor skyld like til denne dag, og for våre misgjerninger har vi med våre konger og prester vært overgitt i fremmede kongers hånd, under sverdet, i fangenskap, til plyndring og vanære, som det viser sig på denne dag. 
15:9:8: Men nu er for et lite øieblikk nåde blitt oss til del fra Herren vår Gud, så han har levnet og frelst en rest av oss og gitt oss et fotfeste på sitt hellige sted, forat vår Gud kunde la våre øine lyse og gi oss litt ny livskraft i vår trældom. 
15:9:9: For træler er vi; men vår Gud har ikke forlatt oss i vår trældom, han har latt oss finne nåde hos Persias konger, så de gav oss ny livskraft til å bygge op vår Guds hus og gjenreise dets ruiner og gav oss et hegnet bosted i Juda og i Jerusalem. 
15:9:10: Men, vår Gud, hvad skal vi si efter alt dette? For vi har forlatt dine bud, 
15:9:11: dem som du gav ved dine tjenere profetene da du sa: Det land I drar inn i for å ta det i eie, er et land som er blitt urent ved de fremmede folks urenhet og ved de vederstyggeligheter som de i sin urenhet har fylt det med fra ende til annen; 
15:9:12: derfor skal I ikke gi eders døtre til deres sønner eller ta deres døtre til hustruer for eders sønner, og I skal aldri søke deres velferd og lykke, så I kan bli sterke og få ete av landets gode ting og la eders barn få det til eie for all tid. 
15:9:13: Skulde vi vel nu efter alt det som er kommet over oss på grunn av våre onde gjerninger og vår store skyld, og siden du, vår Gud, har spart oss og straffet oss langt under vår misgjerning og latt en rest av oss som denne her bli frelst - 
15:9:14: skulde vi da nu atter bryte dine bud og inngå svogerskap med folk som gjør sig skyldige i sådanne vederstyggeligheter? Vilde du ikke da vredes på oss, så det blev ute med oss, og det ikke var nogen som blev frelst eller slapp unda? 
15:9:15: Herre, Israels Gud! Du er rettferdig; for vi er bare en levning, en rest som er blitt frelst, som det kan sees på denne dag; se, vi er nu her for ditt åsyn i vår syndeskyld; for efter det som nu har hendt, kan ingen bli stående for dig. 
15:10:1: Mens nu Esras lå gråtende på jorden foran Guds hus og bad og bekjente, samlet det sig om ham en meget stor skare av Israels folk, både menn og kvinner og barn; for folket gråt sårt. 
15:10:2: Da tok Sekanja, Jehiels sønn, av Olams barn, til orde og sa til Esras: Vi har båret oss troløst at mot vår Gud og tatt fremmede kvinner av de andre folk her i landet til hustruer; men ennu er det håp for Israel i denne sak. 
15:10:3: Så la oss nu gjøre en pakt med vår Gud om å skille oss med alle disse kvinner og deres barn, således som Herren vil det og de menn som har ærefrykt for vår Guds bud, og la oss gå frem efter loven! 
15:10:4: Stå op! For det er du som må sørge for dette, og vi skal hjelpe dig; vær frimodig og sett det i verk! 
15:10:5: Da stod Esras op og lot de øverste prester og levitter og hele Israels høvdinger sverge at de vilde gjøre som sagt var; og de svor på det. 
15:10:6: Så gikk Esras bort fra plassen foran Guds hus og inn i Johanans, Eljasibs sønns kammer. Og da han var kommet dit, kunde han hverken ete eller drikke, så dypt sørget han over de hjemkomnes utroskap. 
15:10:7: Derefter lot de utrope i Juda og Jerusalem til alle de hjemkomne at de skulde samles i Jerusalem, 
15:10:8: og om det var nogen som ikke kom innen tre dager, således som høvdingene og de eldste hadde fastsatt, skulde alt hans gods bannlyses, og han selv utelukkes fra de hjemkomnes menighet. 
15:10:9: Så samledes alle Judas og Benjamins menn i Jerusalem innen de tre dager; det var den tyvende dag i den niende måned. Alt folket satt på plassen ved Guds hus og skalv både for sakens skyld og for regnbygene. 
15:10:10: Da stod presten Esras op og sa til dem: I har båret eder troløst at og tatt fremmede kvinner til hustruer, og dermed har I øket Israels skyld. 
15:10:11: Så bekjenn det nu for Herren, eders fedres Gud, og gjør hans vilje! Skill eder fra de andre folk her i landet og fra de fremmede kvinner! 
15:10:12: Da svarte hele folket og sa med høi røst: Ja, som du har sagt, så er vi skyldige å gjøre. 
15:10:13: Men folket er tallrikt, og det er nu regntid, så vi ikke er i stand til å stå her ute, og dette er et arbeid som ikke kan utføres på en dag eller to; for det er mange av oss som har syndet i denne sak. 
15:10:14: La derfor våre høvdinger stå frem for hele folket, og la alle dem i våre byer som har tatt fremmede kvinner til hustruer, møte til fastsatte tider sammen med de eldste og dommerne i hver by, til vi får vendt vår Guds brennende vrede bort fra oss, og denne sak er avgjort. 
15:10:15: Bare Jonatan, Asaels sønn, og Jahseja, Tikvas sønn, satte sig imot dette, og Mesullam og levitten Sabbetai støttet dem. 
15:10:16: De hjemkomne gjorde da som sagt var, og valgte presten Esras og nogen menn som var overhoder for sine familier, og som alle var nevnt ved navn, og de trådte sammen på den første dag i den tiende måned for å granske saken, 
15:10:17: og de blev ferdig med alle de menn som hadde tatt fremmede kvinner til hustruer, til den første dag i den første måned. 
15:10:18: Blandt prestenes sønner fant de nogen som hadde tatt fremmede kvinner til hustruer; det var: av Josvas, Josadaks sønns sønner og hans brødre: Ma'aseja og Elieser og Jarib og Gedalja; 
15:10:19: de gav sin hånd på at de vilde skille sig fra sine hustruer og ofre en vær av hjorden som skyldoffer for den skyld de hadde pådradd sig. 
15:10:20: Av Immers barn var det Hanani og Sebadja; 
15:10:21: av Harims barn Ma'aseja og Elia og Semaja og Jehiel og Ussia; 
15:10:22: av Pashurs barn Eljoenai, Ma'aseja, Ismael, Netanel, Josabad og Elasa. 
15:10:23: Av levittene var det Josabad og Sime'i og Kelaja, det er Kelita, Petaja, Juda og Elieser; 
15:10:24: av sangerne Eljasib; av dørvokterne Sallum og Telem og Uri. 
15:10:25: Av Israel ellers var det: av Paros' barn Ramja og Jissija og Malkija og Mijamin og Eleasar og Malkija og Benaja; 
15:10:26: av Elams barn Mattanja, Sakarja og Jehiel og Abdi og Jeremot og Elia; 
15:10:27: av Sattus barn Eljoenai, Eljasib, Mattanja og Jeremot og Sabad og Asisa; 
15:10:28: av Bebais barn Johanan, Hananja, Sabbai, Atlai; 
15:10:29: av Banis barn Mesullam, Malluk og Adaja, Jasub og Seal og Jeremot; 
15:10:30: av Pahat-Moabs barn Adna og Kelal, Benaja, Ma'aseja, Mattanja, Besalel og Binnui og Manasse; 
15:10:31: fremdeles Harims barn: Elieser, Jissija, Malkija, Semaja, Simeon, 
15:10:32: Benjamin, Malluk, Semarja; 
15:10:33: av Hasums barn Mattenai, Mattatta, Sabad, Elifelet, Jeremai, Manasse, Sime'i; 
15:10:34: av Banis barn Ma'adai, Amram og Uel, 
15:10:35: Benaja, Bedeja, Keluhi, 
15:10:36: Vanja, Meremot, Eljasib, 
15:10:37: Mattanja, Mattenai, Ja'asu 
15:10:38: og Bani og Binnui, Sime'i 
15:10:39: og Selemja og Natan og Adaja, 
15:10:40: Maknadbai, Sasai, Sarai, 
15:10:41: Asarel og Selemja, Semarja, 
15:10:42: Sallum, Amarja, Josef; 
15:10:43: av Nebos barn Je'iel, Mattitja, Sabad, Sebina, Jaddu og Joel og Benaja. 
15:10:44: Alle disse hadde tatt fremmede kvinner til hustruer, og blandt dem var det nogen som hadde fått barn. 
16:1:1: Nehemias', Hakaljas sønns historie. I måneden kislev, i det tyvende år*, da jeg var i borgen Susan, # <* av kong Artaxerxes' regjering, NEH 2, 1.> 
16:1:2: kom Hanani, en av mine brødre, og nogen andre menn fra Juda. Jeg spurte dem da om jødene - den rest som var blitt frelst fra fangenskapet - og om Jerusalem. 
16:1:3: De svarte mig: De som er blitt igjen fra fangenskapet og nu bor der i landskapet, er i stor ulykke og vanære; Jerusalems mur er nedrevet og portene opbrent. 
16:1:4: Da jeg hørte denne tidende, satte jeg mig ned og gråt og sørget dag efter dag, og jeg fastet og bad for himmelens Guds åsyn. 
16:1:5: Og jeg sa: Akk, Herre, himmelens Gud, du store og forferdelige Gud, som holder din pakt og bevarer din miskunnhet mot dem som elsker dig og holder dine bud! 
16:1:6: La ditt øre være åpent og dine øine oplatt, så du hører på din tjeners bønn, den som jeg nu beder for ditt åsyn, både dag og natt, for dine tjenere Israels barn, idet jeg bekjenner Israels barns synder, som vi har gjort mot dig! Både jeg og min fars hus har syndet; 
16:1:7: vi har båret oss ille at mot dig og ikke holdt de bud og lover og forskrifter som du gav din tjener Moses. 
16:1:8: Kom i hu det ord som du talte til din tjener Moses da du sa: Bærer I eder troløst at, så vil jeg sprede eder blandt folkene; 
16:1:9: men vender I om til mig og holder mine bud og gjør efter dem, da vil jeg, om enn de bortdrevne blandt eder er ved himmelens ende, samle dem derfra og føre dem til det sted jeg har utvalgt til å la mitt navn bo der. 
16:1:10: De er dog dine tjenere og ditt folk, som du har utfridd med din store kraft og din sterke hånd. 
16:1:11: Akk, Herre! La ditt øre merke på din tjeners og dine tjeneres bønn, de som gjerne vil frykte ditt navn, og la det idag lykkes for din tjener, og la ham finne barmhjertighet hos denne mann! - Jeg var dengang munnskjenk hos kongen. 
16:2:1: I måneden nisan i kong Artaxerxes' tyvende år traff det sig engang så at det var satt vin frem for ham; jeg tok da vinen og rakte kongen den, og han hadde alltid hatt godhet for mig. 
16:2:2: Da sa kongen til mig: Hvorfor ser du så bedrøvet ut? Du er jo ikke syk; det kan ikke være annet enn hjertesorg. Da blev jeg meget redd. 
16:2:3: Og jeg sa til kongen: Kongen leve evindelig! Skulde jeg ikke se bedrøvet ut når byen hvor mine fedres graver er, ligger øde, og dens porter er fortært av ild? 
16:2:4: Da sa kongen til mig: Hvad er det da du ønsker? Da bad jeg til himmelens Gud, 
16:2:5: og så sa jeg til kongen: Om kongen så synes, og dersom du har godhet for din tjener, så ber jeg at du vil la mig reise til Juda, til den by hvor mine fedres graver er, så jeg kan bygge den op igjen. 
16:2:6: Da spurte kongen mig, mens dronningen satt ved hans side: Hvor lenge vil din reise vare, og når kommer du igjen? Kongen syntes godt om dette og gav mig lov til å reise, efterat jeg hadde nevnt en bestemt tid for ham. 
16:2:7: Så sa jeg til kongen: Om kongen så synes, så la mig få brev med til stattholderne hinsides elven* at de skal la mig dra igjennem hos sig, til jeg kommer til Juda, # <* Eufrat.> 
16:2:8: likeså et brev til Asaf, han som har opsyn over kongens skoger, at han skal gi mig tømmer til å tømre op portene til den borg som hører til templet, og portene til bymuren og tømmer til det hus som jeg skal bo i. Og kongen gav mig det, fordi min Gud holdt sin gode hånd over mig. 
16:2:9: Da jeg kom til stattholderne hinsides elven, gav jeg dem kongens brev; kongen hadde også sendt krigshøvedsmenn og hestfolk med mig. 
16:2:10: Men da horonitten Sanballat og den ammonittiske tjener Tobias hørte det, syntes de meget ille om at det var kommet en som vilde arbeide for Israels barns vel. 
16:2:11: Da jeg så kom til Jerusalem og hadde vært der i tre dager, 
16:2:12: brøt jeg op om natten med nogen få menn; men jeg hadde ikke nevnt for noget menneske hvad min Gud hadde gitt mig i sinne å gjøre for Jerusalem; og jeg hadde ikke andre dyr med mig enn det jeg red på. 
16:2:13: Jeg drog om natten ut gjennem Dalporten og bortimot Dragekilden og kom til Møkkporten. Jeg så på Jerusalems murer som var nedrevet, og på portene som var fortært av ild. 
16:2:14: Så tok jeg over til Kildeporten og til Kongedammen; men der var det ikke rum for dyret som jeg red på, til å komme frem. 
16:2:15: Så drog jeg op i dalen om natten og så på muren og gikk atter inn gjennem Dalporten og vendte så tilbake igjen. 
16:2:16: Forstanderne visste ikke hvor jeg hadde vært, og hvad jeg tok mig fore; for jeg hadde ennu ikke nevnt noget om det for jødene eller prestene eller de fornemme eller forstanderne eller de andre, som skulde utføre arbeidet. 
16:2:17: Men nu sa jeg til dem: I ser selv den ulykke vi er i, at Jerusalem ligger øde og dets porter er opbrent; kom og la oss bygge op igjen Jerusalems mur, så vi ikke mere skal være til spott! 
16:2:18: Og jeg fortalte dem hvorledes min Gud hadde holdt sin gode hånd over mig, og likeså hvad kongen hadde sagt til mig. Da sa de: Vi vil gjøre oss rede og bygge. Og de tok kraftig fatt på det gode verk. 
16:2:19: Men da horonitten Sanballat og den ammonittiske tjener Tobias og araberen Gesem hørte det, spottet de oss og foraktet oss og sa: Hvad er det I gjør der? Vil I sette eder op imot kongen? 
16:2:20: Da svarte jeg dem så: Himmelens Gud, han vil la det lykkes for oss, og vi hans tjenere vil gjøre oss rede og bygge; men I har hverken del eller rett eller eftermæle i Jerusalem. 
16:3:1: Ypperstepresten Eljasib og hans brødre og prestene gjorde sig rede og bygget Fåreporten; de helliget den og satte inn dørene; så bygget de videre til Mea-tårnet, som de helliget, og videre frem til Hananel-tårnet. 
16:3:2: Ved siden av dem bygget mennene fra Jeriko og ved siden av dem Sakkur, Imris sønn. 
16:3:3: Fiskeporten blev bygget av Sena'as barn; de tømret den op og satte inn dører, låser og slåer. 
16:3:4: Ved siden av dem arbeidet Meremot, sønn av Uria, Hakkos' sønn, ved siden av ham Mesullam, sønn av Berekja, Mesesabels sønn, og ved siden av ham Sadok, Ba'anas sønn. 
16:3:5: Ved siden av ham arbeidet folkene fra Tekoa; men de fornemme blandt dem bøide ikke sin nakke under arbeidet for sin Herre. 
16:3:6: Den gamle port blev satt i stand av Jojada, Paseahs sønn, og Mesullam, Besodjas sønn; de tømret den op og satte inn dører og låser og slåer. 
16:3:7: Ved siden av dem arbeidet gibeonitten Melatja og meronotitten Jadon med mennene fra Gibeon og Mispa, som hørte under stattholderens domstol hinsides* elven. # <* vestenfor.> 
16:3:8: Ved siden av dem arbeidet Ussiel, Harhajas sønn, en gullsmed, og ved siden av ham Hananja, en av salvelagerne. Det næste stykke av Jerusalems mur lot de være like til den brede mur*. # <* Dette stykke av muren var blitt stående.> 
16:3:9: Ved siden av dem arbeidet Refaja, Hurs sønn, høvdingen over den ene halvdel av Jerusalems distrikt. 
16:3:10: Ved siden av ham arbeidet Jedaja, Harumafs sønn, midt imot sitt eget hus, og ved siden av ham Hattus, Hasabnejas sønn. 
16:3:11: Et annet stykke blev satt i stand av Malkia, Harims sønn, og Hassub, Pahat-Moabs sønn, og dessuten Ovnstårnet. 
16:3:12: Ved siden av ham arbeidet Sallum, sønn av Hallohes, høvdingen over den andre halvdel av Jerusalems distrikt, og sammen med ham hans døtre. 
16:3:13: Dalporten blev satt i stand av Hanun og innbyggerne i Sanoah; de bygget den og satte inn dører, låser og slåer; de bygget også tusen alen av muren, til Møkkporten. 
16:3:14: Og Møkkporten blev satt i stand av Malkia, Rekabs sønn, høvdingen over Bet-Hakkerems distrikt; han bygget den op og satte inn dører og låser og slåer. 
16:3:15: Kildeporten blev satt i stand av Sallun, Kol-Hoses sønn, høvdingen over Mispas distrikt; han bygget den, la tak på den og satte inn dører, låser og slåer; likeledes bygget han muren ved Selah-dammen ved kongens have helt frem til de trapper som går ned fra Davids stad. 
16:3:16: Efter ham arbeidet Nehemias, Asbuks sønn, høvdingen over den ene halvdel av Betsurs distrikt, til midt imot Davids graver og til den gravde dam og til heltenes hus. 
16:3:17: Efter ham arbeidet levittene under Rehum, Banis sønn; ved siden av ham arbeidet Hasabja, høvdingen over den ene halvdel av Ke'ilas distrikt, for sitt distrikt. 
16:3:18: Efter ham arbeidet deres brødre under Bavvai, Henadads sønn, høvdingen over den andre halvdel av Ke'ilas distrikt. 
16:3:19: Ved siden av ham arbeidet Eser, Josvas sønn, høvdingen over Mispa, på et annet stykke, fra plassen midt imot opgangen til våbenhuset i Vinkelen. 
16:3:20: Efter ham arbeidet Baruk, Sabbais sønn, med stor iver på et annet stykke, fra Vinkelen og til døren på ypperstepresten Eljasibs hus. 
16:3:21: Efter ham arbeidet Meremot, sønn av Uria, Hakkos' sønn, på et annet stykke, fra døren på Eljasibs hus og til enden av hans hus. 
16:3:22: Efter ham arbeidet prestene, mennene fra Jordan-sletten. 
16:3:23: Efter dem arbeidet Benjamin og Hasub midt imot sitt eget hus; efter dem arbeidet Asarja, sønn av Ma'aseja, Ananjas sønn, ved siden av sitt hus. 
16:3:24: Efter ham arbeidet Binnui, Henadads sønn, på et annet stykke, fra Asarjas hus til Vinkelen og til det sted hvor muren bøier av. 
16:3:25: Palal, Usais sønn, arbeidet fra stedet midt imot Vinkelen og det tårn som springer frem fra kongens øvre hus ved fengselsgården; efter ham arbeidet Pedaja, Paros' sønn. 
16:3:26: - Tempeltjenerne bodde på Ofel, til plassen midt imot Vannporten mot øst og det fremspringende tårn - . 
16:3:27: Efter ham arbeidet folkene fra Tekoa på et annet stykke, fra plassen midt imot det store fremspringende tårn og til Ofel-muren. 
16:3:28: Ovenfor Hesteporten arbeidet prestene, hver av dem midt imot sitt eget hus. 
16:3:29: Efter dem arbeidet Sadok, Immers sønn, midt imot sitt eget hus, og efter ham Semaja, Sekanjas sønn, som hadde vaktholdet ved den østre port. 
16:3:30: Efter ham arbeidet Hananja, Selemjas sønn, og Hanun, Salafs sjette sønn, på et annet stykke, og efter dem Mesullam, Berekjas sønn, midt imot sitt kammer. 
16:3:31: Efter ham arbeidet Malkia, en av gullsmedene, frem til tempeltjenernes og kremmernes hus, midt imot Mønstringsporten og frem til Hjørnesalen. 
16:3:32: Og mellem Hjørnesalen og Fåreporten arbeidet gullsmedene og kremmerne. 
16:4:1: Da nu Sanballat hørte at vi bygget på muren, optendtes hans vrede; han blev yderlig forbitret og spottet jødene 
16:4:2: og sa i nærvær av sine brødre og krigsfolket i Samaria: Hvad er det disse visne jøder gjør? Skal de ha lov til slikt? Skal de få ofre? Skal tro de vil få fullført verket idag? Skal tro de vil blåse liv i stenene fra grushaugene enda de alt er forbrent? 
16:4:3: Og ammonitten Tobias, som stod ved siden av ham, sa: La dem bygge så meget de vil - bare en rev hopper op på deres stenmur, så river den den ned. 
16:4:4: Hør, vår Gud, hvorledes vi er blitt foraktet! La deres spott falle tilbake på deres eget hode, og la dem bli til rov i fangenskaps land! 
16:4:5: Dekk ikke over deres misgjerning, og la ikke deres synd bli utslettet for ditt åsyn! For de har krenket dig for bygningsmennenes ører. 
16:4:6: Så bygget vi da på muren, og hele muren blev satt i stand til sin halve høide, og folket hadde godt mot på arbeidet. 
16:4:7: Men da Sanballat og Tobias og araberne og ammonittene og asdodittene hørte at vi holdt på med å utbedre Jerusalems murer, og at revnene tok til å fylles, blev de meget vrede. 
16:4:8: Og de sammensvor sig alle om å komme og stride mot Jerusalem og hindre folket i dets arbeid. 
16:4:9: Men vi bad til vår Gud, og vi stilte ut vaktposter mot dem dag og natt av frykt for dem. 
16:4:10: Da sa jødene: Bærernes kraft svikter, og grushaugene er for store; vi makter ikke lenger å bygge på muren. 
16:4:11: Og våre motstandere sa: De må ikke få vite eller merke noget før vi står midt iblandt dem og hugger dem ned og således gjør ende på arbeidet. 
16:4:12: Da nu de jøder som bodde i nærheten av dem, kom fra alle kanter og vel ti ganger sa til oss: I må vende tilbake til oss, 
16:4:13: da stilte jeg folket op på de laveste steder bak muren, på de åpne plasser; jeg stilte dem op efter deres ætter med sine sverd og spyd og buer. 
16:4:14: Og da jeg så dem opstilt, gikk jeg frem og sa til de fornemme og forstanderne og til hele folket: Vær ikke redd dem, tenk på Herren, den store og forferdelige, og strid for eders brødre, eders sønner og døtre, eders hustruer og eders hjem! 
16:4:15: Da våre fiender hørte at vi hadde fått vite det, og at Gud hadde gjort deres råd til intet, vendte vi alle sammen tilbake til muren, hver til sitt arbeid. 
16:4:16: Fra den dag arbeidet bare den ene halvdel av mine unge menn på verket, mens den andre halvdel av dem stod der med sine spyd, skjold, buer og brynjer, og høvdingene stod bakenfor hele Judas folk. 
16:4:17: De som bygget på muren, og bærerne med sine byrder, de arbeidet med sin ene hånd på verket, og med den andre holdt de sitt våben; 
16:4:18: hver av bygningsmennene hadde sitt sverd spent om sine lender mens de bygget; og basunblåseren stod ved siden av mig. 
16:4:19: Og jeg sa til de fornemme og forstanderne og til hele folket: Arbeidet er stort og vidløftig, og vi er spredt på muren langt fra hverandre. 
16:4:20: På det sted hvor I hører basunen lyder, der skal I samle eder hos oss. Vår Gud vil stride for oss. 
16:4:21: Så arbeidet vi da på verket mens halvdelen stod der med sine spyd, fra morgenrøden rant op til stjernene kom frem. 
16:4:22: På samme tid sa jeg også til folket: Hver mann med sin tjener skal bli natten over inne i Jerusalem, så vi kan ha dem til vakt om natten og til arbeid om dagen. 
16:4:23: Og hverken jeg eller mine brødre eller mine tjenere eller vaktmennene, som fulgte mig, kom av klærne; hver mann hadde sitt våben hos sig og vann. 
16:5:1: Men det reiste sig et stort skrik fra folket og deres hustruer mot deres jødiske brødre. 
16:5:2: Nogen sa: Vi med våre sønner og døtre er mange; la oss få korn, så vi kan ete og berge livet! 
16:5:3: Andre sa: Våre marker og vingårder og hus må vi sette i pant; la oss få korn til å stille vår hunger! 
16:5:4: Atter andre sa: Vi har lånt penger på våre marker og vingårder for å betale skatten til kongen; 
16:5:5: og dog er vi av samme kjød og blod som våre brødre, og våre barn som deres barn; men vi må la våre sønner og døtre bli træler, ja, nogen av våre døtre er alt blitt trælkvinner, og det står ikke i vår makt å hindre det; for våre marker og vingårder hører andre til. 
16:5:6: Da jeg hørte deres klagerop og disse deres ord, blev jeg meget vred. 
16:5:7: Jeg overveide saken med mig selv, og så irettesatte jeg de fornemme og forstanderne og sa til dem: I krever rente av eders brødre! Så sammenkalte jeg et stort folkemøte mot dem. 
16:5:8: Og jeg sa til dem: Vi har, så vidt vi kunde, løskjøpt våre jødiske brødre som var solgt til hedningene, og så vil I selge eders brødre, og de må selge sig til oss! Da tidde de og fant ikke et ord til svar. 
16:5:9: Så sa jeg: Det er ikke rett det I gjør; burde I ikke vandre i frykt for vår Gud, så ikke hedningene, våre fiender, skal få grunn til å håne oss? 
16:5:10: Også jeg og mine brødre og mine folk har lånt dem penger og korn; la oss eftergi denne gjeld! 
16:5:11: Gi nu I dem på denne dag deres marker, vingårder, oljehaver og hus tilbake og eftergi dem rentene av pengene og kornet og mosten og oljen som I har lånt dem! 
16:5:12: Da sa de: Vi vil gi det tilbake og ikke kreve noget av dem; vi vil gjøre som du sier. Og efterat jeg hadde tilkalt prestene, lot jeg dem sverge på at de vilde gjøre som de hadde sagt. 
16:5:13: Jeg rystet også ut brystfolden* på min kjortel og sa: Således skal Gud ryste hver mann som ikke holder dette sitt ord, ut av hans hus og gods - så utrystet og tom skal han bli! Og hele forsamlingen sa: Amen! Og de priste Herren, og folket gjorde efter det som var sagt. # <* den tjente som lomme.> 
16:5:14: Fra den dag jeg blev utnevnt til stattholder i Juda land, fra kong Artaxerxes' tyvende år til hans to og trettiende år, i hele tolv år, har hverken jeg eller mine brødre nytt noget av den kost som stattholderen kunde kreve. 
16:5:15: De forrige stattholdere, de som var før mig, falt folket til byrde og tok brød og vin av dem foruten firti sekel sølv, og endog deres tjenere trykket folket; men jeg gjorde ikke så, for jeg fryktet Gud. 
16:5:16: Jeg var også selv med i arbeidet på denne mur, og noget jordstykke har vi ikke kjøpt; og alle mine folk har vært samlet til arbeidet der. 
16:5:17: Og jødene og forstanderne, hundre og femti mann, åt ved mitt bord, foruten dem som kom til oss fra hedningefolkene rundt omkring. 
16:5:18: Og det som blev tillaget for hver dag, var en okse og seks utvalgte stykker småfe foruten fugler, og alt dette kostet jeg selv; og en gang hver tiende dag var det overflod av all slags vin; og allikevel krevde jeg ikke den kost som stattholderen hadde rett til; for arbeidet lå tungt på dette folk. 
16:5:19: Kom mig i hu, min Gud, og regn mig til gode alt det jeg har gjort for dette folk! 
16:6:1: Da det nu kom Sanballat og Tobias og araberen Gesem og våre andre fiender for øre at jeg hadde bygget op muren, og at det ikke mere fantes nogen revne i den, enda jeg til den tid ikke hadde satt inn dører i portene, 
16:6:2: da sendte Sanballat og Gesem bud til mig og lot si: Kom, la oss møtes i en av landsbyene i Ono-dalen! Men de tenkte å gjøre mig ondt. 
16:6:3: Jeg sendte bud tilbake til dem og svarte: Jeg holder på med et stort arbeid og kan ikke komme ned; skulde kanskje arbeidet hvile fordi jeg lot det ligge og drog ned til eder? 
16:6:4: Fire ganger sendte de det samme bud til mig, og jeg gav dem samme svar. 
16:6:5: Femte gang sendte Sanballat sin tjener til mig med det samme bud, og han hadde et åpent brev med sig. 
16:6:6: I det stod det: Der går det ord blandt folkene, og Gasmu sier også at du og jødene tenker på å gjøre oprør; derfor er det du bygger op muren, og efter det samme rykte skal du være deres konge; 
16:6:7: du har også satt profeter til å utrope om dig i Jerusalem at du er konge i Juda. Nu vil dette rykte komme kongen for øre; så kom nu og la oss rådslå sammen! 
16:6:8: Men jeg sendte bud til ham og lot svare: Noget sådant som det du taler om, har ikke gått for sig; det er noget du selv har funnet på. 
16:6:9: For de søkte alle sammen å skremme oss, idet de tenkte at vi da skulde bli trette og holde op med arbeidet, så det ikke blev utført. Men styrk nu du mine hender! 
16:6:10: Da jeg engang kom inn til Semaja, sønn av Delaja, Mehetabels sønn, i hans hus, hvor han holdt sig innelukket, sa han: La oss gå sammen inn i Guds hus, i det indre av templet og la oss stenge templets dører! For de kommer og vil drepe dig, ja, de kommer og vil drepe dig inatt. 
16:6:11: Men jeg svarte: Skulde en mann som jeg flykte? Og hvorledes skulde en mann som jeg kunne gå inn i templet og enda bli i live? Jeg går ikke inn der. 
16:6:12: For jeg forstod grant at det ikke var Gud som hadde sendt ham, men at han uttalte denne spådom over mig fordi Tobias og Sanballat hadde leid ham til det. 
16:6:13: Han var leid forat jeg skulde bli redd og gjøre som han sa og forsynde mig, og de således få satt ut et ondt rykte om mig, så de kunde håne mig. 
16:6:14: Kom Tobias og likeså Sanballat i hu, min Gud, for disse hans gjerninger og dessuten profetinnen Noadja og de andre profeter som søkte å skremme mig! 
16:6:15: Muren blev ferdig på to og femti dager - den fem og tyvende dag i måneden elul. 
16:6:16: Da alle våre fiender hørte dette, og alle folkene rundt om oss så det, da sank de meget i sine egne øine, og de forstod at det var med vår Guds hjelp dette verk var utført. 
16:6:17: I de dager sendte også de fornemste i Juda mange brev til Tobias, og fra Tobias kom det brev til dem igjen. 
16:6:18: For mange i Juda var forbundet med ham ved ed; for han var svigersønn til Sekanja, Arahs sønn, og hans sønn Johanan hadde ektet en datter av Mesullam, Berekjas sønn. 
16:6:19: De pleide også å tale til mig om hans gode egenskaper og å bære mine ord frem til ham. Tobias sendte også brev for å skremme mig. 
16:7:1: Da nu muren var bygget, satte jeg inn dørene, og dørvokterne og sangerne og levittene blev satt til sin gjerning. 
16:7:2: Og jeg satte min bror Hanani til befalingsmann over Jerusalem og sammen med ham Hananja, borgens høvedsmann; for han var en pålitelig mann og gudfryktig fremfor de fleste. 
16:7:3: Og jeg sa til dem: Jerusalems porter skal ikke åpnes før solen brenner hett, og mens de* ennu står der, skal dørene lukkes og tillåses, og det skal settes ut vakter av Jerusalems innbyggere, hver på sin post og hver utenfor sitt hus. # <* dørvokterne.> 
16:7:4: Byen var vid og stor, men folket i den var fåtallig, og ingen nye hus var bygget. 
16:7:5: Da gav min Gud mig i sinne å samle de fornemste og forstanderne og folket for å innføres i ættelister, og jeg fant boken med ættelistene over dem som først hadde draget hjem, og der fant jeg skrevet: 
16:7:6: Dette er de menn fra landskapet Juda som drog hjem fra fangenskapet i det fremmede land - de som kongen i Babel Nebukadnesar hadde bortført, og som nu er vendt tilbake til Jerusalem og Juda, hver til sin by, 
16:7:7: de som fulgte med Serubabel, Josva, Nehemias, Asarja, Ra'amja, Nahamani, Mordekai, Bilsan, Misperet, Bigvai, Nehum, Ba'ana. Dette er tallet på mennene av Israels folk: 
16:7:8: Paros' barn, to tusen ett hundre og to og sytti; 
16:7:9: Sefatjas barn, tre hundre og to og sytti; 
16:7:10: Arahs barn, seks hundre og to og femti; 
16:7:11: Pahat-Moabs barn av Josvas og Joabs efterkommere, to tusen åtte hundre og atten; 
16:7:12: Elams barn, tusen to hundre og fire og femti; 
16:7:13: Sattus barn, åtte hundre og fem og firti; 
16:7:14: Sakkais barn, syv hundre og seksti; 
16:7:15: Binnuis barn, seks hundre og åtte og firti; 
16:7:16: Bebais barn, seks hundre og åtte og tyve; 
16:7:17: Asgads barn, to tusen tre hundre og to og tyve; 
16:7:18: Adonikams barn, seks hundre og syv og seksti; 
16:7:19: Bigvais barn, to tusen og syv og seksti; 
16:7:20: Adins barn, seks hundre og fem og femti; 
16:7:21: Aters barn av Esekias' ætt, åtte og nitti; 
16:7:22: Hasums barn, tre hundre og åtte og tyve; 
16:7:23: Besais barn, tre hundre og fire og tyve; 
16:7:24: Harifs barn, hundre og tolv; 
16:7:25: Gibeons barn, fem og nitti; 
16:7:26: mennene fra Betlehem og Netofa, hundre og åtte og åtti; 
16:7:27: mennene fra Anatot, hundre og åtte og tyve; 
16:7:28: mennene fra Bet-Asmavet, to og firti; 
16:7:29: mennene fra Kirjat-Jearim, Kefira og Be'erot, syv hundre og tre og firti; 
16:7:30: mennene fra Rama og Geba, seks hundre og en og tyve; 
16:7:31: mennene fra Mikmas, hundre og to og tyve; 
16:7:32: mennene fra Betel og Ai, hundre og tre og tyve; 
16:7:33: mennene fra det annet Nebo, to og femti; 
16:7:34: den annen Elams barn, tusen to hundre og fire og femti; 
16:7:35: Harims barn, tre hundre og tyve; 
16:7:36: Jerikos barn, tre hundre og fem og firti; 
16:7:37: Lods, Hadids og Onos barn, syv hundre og en og tyve; 
16:7:38: Sena'as barn, tre tusen ni hundre og tretti. 
16:7:39: Av prestene: Jedajas barn av Josvas hus, ni hundre og tre og sytti; 
16:7:40: Immers barn, tusen og to og femti; 
16:7:41: Pashurs barn, tusen to hundre og syv og firti; 
16:7:42: Harims barn, tusen og sytten. 
16:7:43: Av levittene: Josvas barn av Kadmiels ætt, av Hodevas barn, fire og sytti. 
16:7:44: Av sangerne: Asafs barn, hundre og åtte og firti. 
16:7:45: Av dørvokterne: Sallums barn, Aters barn, Talmons barn, Akkubs barn, Hatitas barn, Sobais barn, hundre og åtte og tretti. 
16:7:46: Av tempeltjenerne: Sihas barn, Hasufas barn, Tabbaots barn, 
16:7:47: Keros' barn, Sias barn, Padons barn, 
16:7:48: Lebanas barn, Hagabas barn, Salmais barn, 
16:7:49: Hanans barn, Giddels barn, Gahars barn, 
16:7:50: Reajas barn, Resins barn, Nekodas barn, 
16:7:51: Gassams barn, Ussas barn, Paseahs barn, 
16:7:52: Besais barn, Me'unims barn, Nefussims barn, 
16:7:53: Bakbuks barn, Hakufas barn, Harhurs barn, 
16:7:54: Baslits barn, Mehidas barn, Harsas barn, 
16:7:55: Barkos' barn, Siseras barn, Tamahs barn, 
16:7:56: Nesiahs barn, Hatifas barn. 
16:7:57: Av Salomos tjeneres barn: Sotais barn, Soferets barn, Peridas barn, 
16:7:58: Ja'alas barn, Darkons barn, Giddels barn, 
16:7:59: Sefatjas barn, Hattils barn, Pokeret-Hasseba'ims barn, Amons barn. 
16:7:60: Alle tempeltjenerne og Salomos tjeneres barn var tilsammen tre hundre og to og nitti. 
16:7:61: Og dette er de som drog hjem fra Tel-Melah, Tel-Harsa, Kerub, Addon og Immer, men ikke kunde opgi sin familie og sin ætt, om de var av Israel: 
16:7:62: Delajas barn, Tobias' barn, Nekodas barn, seks hundre og to og firti, 
16:7:63: og av prestene: Hobajas barn, Hakkos' barn, Barsillais barn, han som hadde tatt en av gileaditten Barsillais døtre til hustru og var blitt opkalt efter dem. 
16:7:64: Disse lette efter sine ættelister, men de fantes ikke; de blev da utelukket fra prestedømmet som uverdige dertil, 
16:7:65: og stattholderen sa til dem at de ikke skulde ete av det høihellige før det fremstod en prest med urim og tummim. 
16:7:66: Hele menigheten var i alt to og firti tusen tre hundre og seksti 
16:7:67: foruten deres tjenere og tjenestepiker, som var syv tusen tre hundre og syv og tretti. De hadde også med sig to hundre og fem og firti sangere og sangerinner. 
16:7:68: De hadde syv hundre og seks og tretti hester, to hundre og fem og firti mulesler, 
16:7:69: fire hundre og fem og tretti kameler og seks tusen syv hundre og tyve asener. 
16:7:70: Nogen av familiehodene gav gaver til arbeidet. Stattholderen gav til kassen tusen dariker i gull, dessuten femti skåler og fem hundre og tretti prestekjortler. 
16:7:71: Og nogen av familiehodene gav til arbeidskassen tyve tusen dariker i gull og to tusen og to hundre miner i sølv. 
16:7:72: Og det som resten av folket gav, var tyve tusen dariker i gull og to tusen miner i sølv og dessuten syv og seksti prestekjortler. 
16:7:73: Både prestene og levittene og dørvokterne og sangerne og nogen av det menige folk og tempeltjenerne og hele Israel ellers bosatte sig i sine byer. Da den syvende måned kom, bodde Israels barn i sine byer. 
16:8:1: Og alt folket samlet sig som en mann på plassen foran Vannporten; og de bad Esras, den skriftlærde, om å hente boken med Mose lov, som Herren hadde gitt Israel. 
16:8:2: Og presten Esras bar loven frem for menigheten, både for menn og kvinner og alle som kunde forstå hvad de hørte; det var den første dag i den syvende måned. 
16:8:3: Og han leste op av den midt for plassen foran Vannporten fra tidlig morgen til midt på dagen - for mennene og kvinnene og dem som kunde forstå; og alt folket lyttet til lovbokens ord. 
16:8:4: Esras, den skriftlærde, stod på en forhøining av tre, som var blitt reist til dette, og ved siden av ham stod Mattitja, Sema, Anaja, Uria, Hilkia og Ma'aseja til høire for ham, og til venstre for ham Pedaja, Misael, Malkia, Hasum, Hasbaddana, Sakarja og Mesullam. 
16:8:5: Og Esras åpnet boken for alt folkets øine; for han stod høiere enn alt folket; og da han åpnet den, reiste alt folket sig. 
16:8:6: Esras lovet Herren, den store Gud, og alt folket svarte med opløftede hender: Amen, amen, og de bøide sig og kastet sig ned for Herren med ansiktet til jorden. 
16:8:7: Og Josva, Bani, Serebja, Jamin, Akkub, Sabbetai, Hodia, Ma'aseja, Kelita, Asarja, Josabad, Hanan, Pelaja og de andre levitter utla loven for folket, mens folket blev stående på sin plass. 
16:8:8: De leste op av boken - av Guds lov - de tolket og utla den for folket, så de skjønte det som blev lest. 
16:8:9: Og stattholderen Nehemias og presten Esras, den skriftlærde, og levittene som lærte folket, sa til alt folket: Denne dag er helliget Herren eders Gud, sørg ikke og gråt ikke! For alt folket gråt da de hørte lovens ord. 
16:8:10: Så sa han til dem: Gå nu og et fete retter og drikk søte drikker og send gaver derav til dem for hvem intet er tillaget, for dagen er helliget vår Herre, og sørg ikke, for glede i Herren er eders styrke! 
16:8:11: Også levittene søkte å få alt folket til å holde sig rolig og sa: Vær stille, for dagen er hellig; sørg ikke! 
16:8:12: Da gikk alt folket bort og åt og drakk og sendte gaver omkring og holdt en stor gledesfest; for de hadde forstått det som var blitt talt til dem. 
16:8:13: Dagen efter samlet alt folkets familiehoder, prestene og levittene sig hos Esras, den skriftlærde, for å få nærmere rede på lovens ord. 
16:8:14: De fant da skrevet i loven - den lov som Herren hadde gitt ved Moses - at Israels barn skulde bo i løvhytter på festen i den syvende måned, 
16:8:15: og at de skulde kunngjøre og la utrope i alle sine byer og i Jerusalem: Gå ut på fjellene og hent løv av oljetrær og av ville oljetrær og av myrter og av palmer og av andre løvrike trær og gjør løvhytter, som foreskrevet er. 
16:8:16: Da gikk folket ut og hentet løv, og de gjorde sig løvhytter, hver på sitt tak og på sine tun og likeledes i forgårdene til Guds hus og på plassen ved Vannporten og på plassen ved Efra'im-porten. 
16:8:17: Og hele menigheten, de som var kommet tilbake fra fangenskapet, gjorde løvhytter og bodde i dem; for fra Josvas, Nuns sønns dager like til denne dag hadde Israels barn ikke gjort dette. Og der var en overmåte stor glede. 
16:8:18: Dag efter dag leste de op av Guds lovbok, fra den første dag til den siste; de holdt høitid i syv dager og på den åttende dag en festlig sammenkomst, som påbudt var. 
16:9:1: På den fire og tyvende dag i samme måned samlet Israels barn sig og holdt faste, klædd i sekk og med jord strødd på sine hoder. 
16:9:2: Og Israels ætt skilte sig fra alle fremmede og stod så frem og bekjente sine synder og sine fedres misgjerninger. 
16:9:3: Så blev de stående på sin plass mens det blev lest op for dem av Herrens, deres Guds lovbok en fjerdedel av dagen, og en annen fjerdedel av dagen avla de bekjennelse og tilbad Herren sin Gud. 
16:9:4: Josva og Bani, Kadmiel, Sebanja, Bunni, Serebja, Bani og Kenani trådte op på levittenes forhøining, og de ropte med høi røst til Herren sin Gud. 
16:9:5: Og levittene Josva og Kadmiel, Bani, Hasabneja, Serebja, Hodia, Sebanja og Petahja sa: Stå op og lov Herren eders Gud fra evighet til evighet! Ja, lovet være ditt herlige navn, som er ophøiet over all lov og pris! 
16:9:6: Du alene er Herren, du har gjort himlene, himlenes himler og all deres hær, jorden og alt som er på den, havene og alt som er i dem, og du holder det alt sammen i live, og himmelens hær tilbeder dig. 
16:9:7: Det var du, Herre Gud, som utvalgte Abram og førte ham ut fra kaldeernes Ur og gav ham navnet Abraham. 
16:9:8: Og du fant hans hjerte trofast mot dig, og du gjorde den pakt med ham at du vilde gi hans ætt kana'anittenes, hetittenes, amorittenes og ferisittenes og jebusittenes og girgasittenes land; og du holdt ditt ord, for du er rettferdig. 
16:9:9: Du så våre fedres nød i Egypten, og du hørte deres rop ved det Røde Hav, 
16:9:10: og du gjorde tegn og undergjerninger med Farao og alle hans tjenere og alt folket i hans land; for du visste at de hadde faret overmodig frem mot dem, og du vant dig et stort navn, som det kan sees på denne dag. 
16:9:11: Du kløvde havet for dem, og de gikk midt igjennem havet på det tørre; du kastet deres forfølgere i dypet, så de sank som sten i de veldige vann. 
16:9:12: Du ledet dem i en skystøtte om dagen og i en ildstøtte om natten for å Iyse for dem på den vei de skulde gå. 
16:9:13: Du steg ned på Sinai berg og talte med dem fra himmelen; du gav dem rette befalinger og trygge lover, gode forskrifter og bud. 
16:9:14: Du kunngjorde dem din hellige sabbat; du gav dem bud og forskrifter og lover gjennem din tjener Moses. 
16:9:15: Du gav dem brød fra himmelen når de hungret, og lot vann komme frem av klippen for dem når de tørstet, og du bød dem å dra inn og ta det land i eie som du med opløftet hånd hadde svoret at du vilde gi dem. 
16:9:16: Men de, våre fedre, var overmodige og hårdnakkede og hørte ikke på dine bud. 
16:9:17: De vilde ikke høre og kom ikke i hu de undergjerninger du hadde gjort for dem, men var hårdnakkede og valgte sig en høvding og vilde i sin gjenstridighet vende tilbake til sin trældom; men du er en Gud som tilgir, nådig og barmhjertig, langmodig og rik på miskunnhet, og du forlot dem ikke. 
16:9:18: Ja, de støpte sig endog en kalv og sa: Dette er din Gud, som førte dig op fra Egypten. Og de gjorde sig skyldige i store bespottelser. 
16:9:19: Men du - i din store barmhjertighet forlot du dem ikke i ørkenen; skystøtten vek ikke fra dem om dagen, men ledet dem på veien, ei heller vek ildstøtten fra dem om natten, men lyste for dem på den vei de skulde gå. 
16:9:20: Du gav dem din gode Ånd til å lære dem; du nektet ikke deres munn din manna, og du gav dem vann når de tørstet. 
16:9:21: I firti år forsørget du dem i ørkenen, de manglet intet; deres klær blev ikke utslitt, og deres føtter blev ikke hovne. 
16:9:22: Du gav dem riker og folk, som du skiftet ut til dem på alle kanter; de tok i eie det land som tilhørte Sihon, kongen i Hesbon, og det land som tilhørte Og, kongen i Basan. 
16:9:23: Du gjorde deres barn tallrike som himmelens stjerner og førte dem inn i det land som du hadde lovt deres fedre at de skulde komme inn i og ta i eie. 
16:9:24: Så kom da deres barn og tok landet i eie, og du ydmyket landets innbyggere, kana'anittene, for dem og gav dem i deres hånd, både kongene og folkene der i landet, så de kunde gjøre med dem som de vilde. 
16:9:25: De inntok faste byer og vant sig fruktbar jord og tok huser til eie som var fulle av alle gode ting, og uthugne brønner, vingårder og oljetrær og frukttrær i mengde; og de åt og blev mette og fete og gjorde sig til gode ved din store godhet. 
16:9:26: Men de blev gjenstridige og satte sig op imot dig og kastet din lov bak sin rygg; de drepte dine profeter, som vidnet for dem og vilde føre dem tilbake til dig; og de gjorde sig skyldige i store bespottelser. 
16:9:27: Da gav du dem i deres fienders hånd, og de plaget dem; men når de i sin nød ropte til dig, hørte du det fra himmelen, og i din store barmhjertighet gav du dem frelsere, som utfridde dem av deres fienders hånd. 
16:9:28: Men når de så kom til ro, gjorde de atter det som var ondt i dine øine, og du overlot dem i deres fienders hånd, så de hersket over dem; men når de så atter ropte til dig, hørte du det fra himmelen og utfridde dem i din barmhjertighet gang på gang. 
16:9:29: Du vidnet for dem og vilde omvende dem til din lov; men de var overmodige og hørte ikke på dine bud, de syndet mot dine lover, de som mennesket lever ved når han gjør efter dem; i sin gjenstridighet satte de skulderen imot; de var hårdnakkede og vilde ikke høre. 
16:9:30: I mange år bar du over med dem og vidnet for dem ved din Ånd gjennem dine profeter; men de vendte ikke øret til, og du gav dem i fremmede folks hånd. 
16:9:31: Men i din store barmhjertighet gjorde du ikke aldeles ende på dem og forlot dem ikke; for du er en nådig og barmhjertig Gud. 
16:9:32: Og nu, vår Gud, du store, veldige og forferdelige Gud, du som holder din pakt og bevarer din miskunnhet, la det ikke tykkes dig ringe alt det onde som har rammet oss, våre konger, våre høvdinger, våre prester, våre profeter, våre fedre og alt ditt folk, fra assyrerkongenes dager til den dag idag! 
16:9:33: Du er rettferdig i alt det som er kommet over oss; for du har vist trofasthet, men vi har vært ugudelige. 
16:9:34: Våre konger, våre høvdinger, våre prester og våre fedre har ikke gjort efter din lov; de har ikke hørt på dine bud og på de vidnesbyrd du lot bli dem til del. 
16:9:35: Og enda de levde i sitt eget rike, og du hadde gitt dem så meget godt og overlatt dem dette vide og fruktbare land, tjente de dig ikke og vendte ikke om fra sine onde gjerninger. 
16:9:36: Se, vi er idag træler - vi er træler i det land du gav våre fedre, forat de skulde ete dets frukt og dets gode ting, 
16:9:37: og sin rike grøde bærer det for de konger som du satte over oss for våre synders skyld, og de råder over våre legemer og vårt fe efter eget tykke. Vi er i stor nød. 
16:9:38: På grunn av alt dette gjorde vi en fast pakt og skrev den op; skrivelsen blev forsynt med segl og underskrevet av våre høvdinger, våre levitter og våre prester. 
16:10:1: De som skrev under på denne med segl forsynte skrivelse, var: stattholderen Nehemias, Hakaljas sønn, og Sedekias, 
16:10:2: Seraja, Asarja, Jirmeja, 
16:10:3: Pashur, Amarja, Malkia, 
16:10:4: Hattus, Sebanja, Malluk, 
16:10:5: Harim, Meremot, Obadja, 
16:10:6: Daniel, Ginneton, Baruk, 
16:10:7: Mesullam, Abia, Mijamin, 
16:10:8: Ma'asja, Bilgai og Semaja; dette var prestene; 
16:10:9: og levittene var: Josva, Asanjas sønn, Binnui av Henadads barn og Kadmiel, 
16:10:10: og deres brødre: Sebanja, Hodia, Kelita, Pelaja, Hanan, 
16:10:11: Mika, Rehob, Hasabja, 
16:10:12: Sakkur, Serebja, Sebanja, 
16:10:13: Hodia, Bani og Beninu. 
16:10:14: Og folkets høvdinger var: Paros, Pahat-Moab, Elam, Sattu, Bani, 
16:10:15: Bunni, Asgad, Bebai, 
16:10:16: Adonja, Bigvai, Adin, 
16:10:17: Ater, Hiskia, Assur, 
16:10:18: Hodia, Hasum, Besai, 
16:10:19: Harif, Anatot, Nobai, 
16:10:20: Magpias, Mesullam, Hesir, 
16:10:21: Mesesabel, Sadok, Jaddua, 
16:10:22: Pelatja, Hanan, Anaja, 
16:10:23: Hosea, Hananja, Hasub, 
16:10:24: Hallohes, Pilha, Sobek, 
16:10:25: Rehum, Hasabna, Ma'aseja, 
16:10:26: Akia, Hanan, Anan, 
16:10:27: Malluk, Harim og Ba'ana. 
16:10:28: Resten av folket, prestene, levittene, dørvokterne, sangerne, tempeltjenerne og alle de som hadde skilt sig fra de fremmede folk for å leve efter Guds lov, med hustruer, sønner og døtre, så mange som hadde kunnskap og forstand - 
16:10:29: alle disse holdt sig til de fornemme blandt sine brødre og svor en dyr ed på at de vilde vandre efter Guds lov, som var gitt ved Guds tjener Moses, og at de vilde holde og gjøre efter alle Herrens, vår Herres bud og lover og forskrifter, 
16:10:30: at vi ikke skulde gi våre døtre til de fremmede folk, ei heller ta deres døtre til hustruer for våre sønner, 
16:10:31: at vi, når de fremmede folk kom med sine varer og alle slags korn for å selge det på sabbaten, ikke skulde kjøpe det av dem på sabbaten eller nogen annen helligdag, og at vi i det syvende år skulde la landet hvile og eftergi alle krav. 
16:10:32: Og vi påtok oss den forpliktelse at vi skulde avgi tredjedelen av en sekel årlig til tjenesten i vår Guds hus, 
16:10:33: til skuebrødene og det daglige matoffer og det daglige brennoffer og til offerne på sabbatene, nymånedagene og høitidene og til takkofferne og til syndofferne som tjente til soning for Israel, og til alt arbeidet i vår Guds hus. 
16:10:34: Og vi, prestene, levittene og folket, kastet lodd om avgivelsen av ved til templet, hvorledes vi år for år til fastsatte tider skulde føre den til vår Guds hus efter våre familier for å brenne den på Herrens, vår Guds alter, som foreskrevet er i loven. 
16:10:35: Vi vedtok også år for år å føre førstegrøden av vår jord og de første modne frukter av alle trær til Herrens hus 
16:10:36: og de førstefødte av våre sønner og av vår buskap, som foreskrevet er i loven, og å føre det førstefødte av vårt storfe og vårt småfe til vår Guds hus, til prestene som gjør tjeneste i vår Guds hus; 
16:10:37: og det første av vårt mel og våre hellige gaver og alle slags trefrukt, most og olje skulde vi føre til prestene, til kammerne i vår Guds hus, og tienden av vår jord til levittene, og levittene skulde selv innkreve tiende i alle de byer hvor vi driver akerbruk. 
16:10:38: Og en prest, en av Arons sønner, skulde være med levittene når de innkrevde tiende, og selv skulde levittene føre tienden av sin tiende op til vår Guds hus, til kammerne i forrådshuset. 
16:10:39: For både Israels barn og Levis barn skulde føre den hellige avgift av kornet, mosten og oljen til kammerne, hvor helligdommens kar er, og hvor de prester som gjør tjeneste, og dørvokterne og sangerne holder til. - Vi skulde ikke svikte vår Guds hus. 
16:11:1: Folkets høvdinger bodde i Jerusalem. Resten av folket kastet lodd således at hver tiende mann skulde bo i Jerusalem, den hellige stad, men de ni tiendedeler i de andre byer. 
16:11:2: Og folket velsignet alle de menn som frivillig bosatte sig i Jerusalem. 
16:11:3: Dette er de familie-overhoder i landskapet som bodde i Jerusalem, og de som bodde i Judas byer, hver på sin eiendom, i sine byer, både av legfolket i Israel og av prestene, levittene, tempeltjenerne og efterkommerne av Salomos tjenere. 
16:11:4: I Jerusalem bodde nogen av Judas barn og av Benjamins barn. Av Judas barn: Ataja, sønn av Ussia, sønn av Sakarja, sønn av Amarja, sønn av Sefatja, sønn av Mahalalel, av Peres' barn, 
16:11:5: og Ma'aseja, sønn av Baruk, sønn av Kol-Hose, sønn av Hasaja, sønn av Adaja, sønn av Jojarib, sønn av Sakarja, sønn av Hassiloni. 
16:11:6: Peres' barn som bodde i Jerusalem, var i alt fire hundre og åtte og seksti krigsdyktige menn. 
16:11:7: Av Benjamins barn var det: Sallu, sønn av Mesullam, sønn av Joed, sønn av Pedaja, sønn av Kolaja, sønn av Ma'aseja, sønn av Itiel, sønn av Jesaja, 
16:11:8: og efter ham Gabbai-Sallai, i alt ni hundre og åtte og tyve. 
16:11:9: Joel, Sikris sønn, var tilsynsmann over dem, og Juda, Hassenuas sønn, var den næst øverste i byen. 
16:11:10: Av prestene var det: Jedaja, sønn av Jojarib, Jakin, 
16:11:11: Seraja, sønn av Hilkia, sønn av Mesullam, sønn av Sadok, sønn av Merajot, sønn av Akitub, forstanderen for Guds hus, 
16:11:12: og deres brødre, som gjorde tjeneste i huset, i alt åtte hundre og to og tyve, og Adaja, sønn av Jeroham, sønn av Pelalja, sønn av Amsi, sønn av Sakarja, sønn av Pashur, sønn av Malkia, 
16:11:13: og hans brødre, overhoder for sine familier, i alt to hundre og to og firti, og Amassai, sønn av Asarel, sønn av Ahsai, sønn av Mesillemot, sønn av Immer, 
16:11:14: og deres brødre, hundre og åtte og tyve dyktige menn. Sabdiel, sønn av Haggedolim, var tilsynsmann over dem. 
16:11:15: Av levittene var det: Semaja, sønn av Hassub, sønn av Asrikam, sønn av Hasabja, sønn av Bunni, 
16:11:16: og Sabbetai og Josabad, som forestod de ytre arbeider ved Guds hus og begge hørte til levittenes overhoder, 
16:11:17: og Mattanja, sønn av Mika, sønn av Sabdi, sønn av Asaf, lederen av sangen, som istemte lovsangen ved bønnen, og Bakbukja, den av hans brødre som var næst efter ham, og Abda, sønn av Sammua, sønn av Galal, sønn av Jeditun. 
16:11:18: Levittene i den hellige stad var i alt to hundre og fire og åtti. 
16:11:19: Av dørvokterne var det: Akkub, Talmon og deres brødre, som holdt vakt ved portene, i alt hundre og to og sytti. 
16:11:20: Resten av Israel og av prestene og levittene bodde i alle de andre byer i Juda, hver på sin arvelodd. 
16:11:21: Og tempeltjenerne bodde på Ofel, og Siha og Gispa var deres formenn. 
16:11:22: Tilsynsmann over levittene i Jerusalem ved tjenesten i Guds hus var Ussi, sønn av Bani, sønn av Hasabja, sønn av Mattanja, sønn av Mika, av Asafs barn, sangerne. 
16:11:23: For det var utstedt en befaling av kongen om dem, og likeledes var det fastsatt for sangerne hvad de hver dag hadde å gjøre. 
16:11:24: Petaja, Mesesabels sønn, av Serahs, Judas sønns barn, gikk kongen til hånde i alle saker som vedkom folket. 
16:11:25: Også i landsbyene med tilhørende jorder bodde nogen av Judas barn: i Kirjat-Arba med tilhørende småbyer, i Dibon med tilhørende småbyer, i Jekabse'el og dets landsbyer, 
16:11:26: i Jesua, Molada, Bet-Pelet, 
16:11:27: i Hasar-Sual, Be'erseba med tilhørende småbyer, 
16:11:28: i Siklag, Mekona med tilhørende småbyer, 
16:11:29: i En-Rimmon, Sora, Jarmut, 
16:11:30: Sanoah, Adullam og deres landsbyer, Lakis med tilhørende jorder og Aseka med tilhørende småbyer. De hadde sine bosteder fra Be'erseba til Hinnoms dal. 
16:11:31: Benjamins barn hadde sine bosteder fra Geba av: i Mikmas, Aja, Betel med tilhørende småbyer, 
16:11:32: i Anatot, Nob, Ananja, 
16:11:33: Hasor, Rama, Gitta'im, 
16:11:34: Hadid, Sebo'im, Neballat, 
16:11:35: Lod, Ono og Tømmermannsdalen. 
16:11:36: Av levittene kom nogen avdelinger fra Juda til å høre til Benjamin. 
16:12:1: Dette er de prester og levitter som drog hjem med Serubabel, Sealtiels sønn, og Josva: Seraja, Jirmeja, Esras, 
16:12:2: Amarja, Malluk, Hattus, 
16:12:3: Sekanja, Rehum, Meremot, 
16:12:4: Iddo, Ginnetoi, Abia, 
16:12:5: Mijamin, Ma'adja, Bilga, 
16:12:6: Semaja, Jojarib, Jedaja, 
16:12:7: Sallu, Amok, Hilkia og Jedaja. Disse var overhodene for prestene og for sine brødre på Josvas tid. 
16:12:8: Og levittene var: Josva, Binnui, Kadmiel, Serebja, Juda og Mattanja, som sammen med sine brødre forestod lovsangen, 
16:12:9: mens deres brødre Bakbukja og Unno stod midt imot dem for å vareta sin tjeneste. 
16:12:10: Josva fikk sønnen Jojakim, Jojakim fikk Eljasib, Eljasib fikk Jojada, 
16:12:11: Jojada fikk Jonatan, og Jonatan fikk Jaddua. 
16:12:12: På Jojakims tid var disse prestenes familie-overhoder: for Serajas familie Meraja, for Jirmejas Hananja, 
16:12:13: for Esras' Mesullam, for Amarjas Johanan, 
16:12:14: for Melukis Jonatan, for Sebanjas Josef, 
16:12:15: for Harims Adna, for Merajots Helkai, 
16:12:16: for Iddos Sakarias, for Ginnetons Mesullam, 
16:12:17: for Abias Sikri, for Minjamins, for Moadjas Piltai, 
16:12:18: for Bilgas Sammua, for Semajas Jonatan, 
16:12:19: for Jojaribs Mattenai, for Jedajas Ussi, 
16:12:20: for Sallais Kallai, for Amoks Eber, 
16:12:21: for Hilkias Hasabja og for Jedajas Netanel. 
16:12:22: I Eljasibs, Jojadas, Johanans og Jadduas tid blev levittenes familieoverhoder optegnet, likeså prestene under perseren Darius' regjering. 
16:12:23: Familie-overhodene for Levis barn blev optegnet i krønikeboken, og det like til Johanans, Eljasibs sønns tid. 
16:12:24: Levittenes overhoder var: Hasabja, Serebia og Josva, Kadmiels sønn, og deres brødre stod midt imot dem for å love og prise Gud, således som den Guds mann David hadde befalt, flokk ved flokk. 
16:12:25: Mattanja, Bakbukja, Obadja, Mesullam, Talmon og Akkub holdt som dørvoktere vakt over forrådskammerne ved portene. 
16:12:26: Disse levde i Jojakims, Josvas sønns, Josadaks sønns tid, og i stattholderen Nehemias' og i presten Esras', den skriftlærdes, tid. 
16:12:27: Til innvielsen av Jerusalems mur hentet de levittene fra alle deres bosteder og førte dem til Jerusalem for å holde innvielse og gledesfest både med lovprisning og med sang, med cymbler, harper og citarer. 
16:12:28: Og sangernes barn kom sammen både fra landet rundt om Jerusalem og fra netofatittenes landsbyer 
16:12:29: og fra Bet-Haggilgal og fra Gebas og Asmavets jorder; for sangerne hadde bygget sig landsbyer rundt omkring Jerusalem. 
16:12:30: Og prestene og levittene renset sig, og de renset folket og portene og muren. 
16:12:31: Da lot jeg Judas høvdinger stige op på muren, og jeg opstilte to store lovsangskor og festtog; det ene gikk til høire ovenpå muren frem til Møkkporten; 
16:12:32: og efter dem gikk Hosaja og halvdelen av Judas høvdinger, 
16:12:33: Asarja, Esras og Mesullam, 
16:12:34: Juda, Benjamin, Semaja og Jirmeja 
16:12:35: og likeså nogen av prestenes barn, som bar trompeter; det var Sakarja, sønn av Jonatan, sønn av Semaja, sønn av Mattanja, sønn av Mikaja, sønn av Sakkur, sønn av Asaf, 
16:12:36: og hans brødre Semaja og Asarel, Milalai, Gilalai, Ma'ai, Netanel, Juda og Hanani med den Guds mann Davids sanginstrumenter; og Esras, den skriftlærde, gikk foran dem. 
16:12:37: De gikk over Kildeporten og rett frem, opover trappene til Davids stad, der hvor en stiger op på muren ovenfor Davids hus, helt frem til Vannporten i øst. 
16:12:38: Det andre lovsangskor gikk til den motsatte side, og jeg fulgte efter det med den andre halvdel av folket, ovenpå muren, ovenfor Ovnstårnet, bort til den brede mur, 
16:12:39: og over Efra'im-porten, den gamle port og Fiskeporten, forbi Hananel-tårnet og Mea-tårnet, helt frem til Fåreporten; de stanset ved Fengselsporten. 
16:12:40: Således stod begge lovsangskorene ved Guds hus, likeså jeg og halvdelen av formennene med mig 
16:12:41: og prestene Eljakim, Ma'aseja, Minjamin, Mikaja, Eljoenai, Sakarja og Hananja med trompeter, 
16:12:42: og Ma'aseja, Semaja, Eleasar, Ussi, Johanan, Malkia, Elam og Eser. Og sangerne istemte sangen, og den som ledet den, var Jisrahja. 
16:12:43: De ofret den dag store offer og gledet sig, for Gud hadde latt dem vederfares en stor glede; også kvinnene og barna gledet sig, og jubelen i Jerusalem hørtes lang vei. 
16:12:44: Samme dag blev det innsatt menn som skulde ha tilsyn med forrådskammerne for de hellige gaver, førstegrøden og tiendene; der skulde de samle fra bymarkene det som efter loven tilkom prestene og levittene; for det var glede i Juda over at prestene og levittene nu utførte sin tjeneste. 
16:12:45: Og de varetok hvad det var å vareta for deres Gud, og hvad det var å vareta ved renselsen; likeså varetok sangerne og dørvokterne sin tjeneste, således som David og hans sønn Salomo hadde befalt. 
16:12:46: For allerede i gammel tid, i Davids og Asafs dager, var det ledere for sangerne, og det lød lov- og takkesanger til Gud. 
16:12:47: Og i Serubabels og Nehemias' dager gav hele Israel sangerne og dørvokterne det som tilkom dem for hver dag; og de gav levittene hellige gaver, og levittene gav Arons barn hellige gaver. 
16:13:1: Samme dag blev det lest op for folket av Moseboken, og der fant de skrevet at ingen ammonitt eller moabitt nogensinne skulde være med i Guds menighet, 
16:13:2: fordi de ikke kom Israels barn i møte med brød og vann, men leide Bileam imot dem til å forbanne dem; allikevel vendte vår Gud forbannelsen til velsignelse. 
16:13:3: Da de nu hørte hvad det stod i loven, utskilte de alle fremmede fra Israel. 
16:13:4: Men nogen tid før dette var presten Eljasib, som var i nær slekt med Tobias, blitt satt til å ha tilsyn med kammerne i Guds hus. 
16:13:5: Og han hadde innredet et stort kammer for ham der hvor de før hadde lagt inn matofferet, viraken og karene og den tiende av kornet, mosten og oljen som var levittenes og sangernes og dørvokternes rettighet, og den hellige avgift til prestene. 
16:13:6: Under alt dette var jeg ikke i Jerusalem; for i Babels konge Artaxerxes' to og trettiende år var jeg kommet tilbake til kongen. Men da nogen tid var gått, bad jeg igjen kongen om lov til å reise, 
16:13:7: og da jeg så kom til Jerusalem, la jeg merke til det onde Eljasib hadde gjort for Tobias' skyld ved å innrede et kammer for ham i forgårdene til Guds hus. 
16:13:8: Dette syntes jeg meget ille om, og jeg kastet alt Tobias' innbo ut av kammeret 
16:13:9: og bød at de skulde rense kammerne, og så lot jeg atter de til Guds hus hørende kar og matofferet og viraken legge inn der. 
16:13:10: Jeg fikk også vite at levittene ikke hadde fått det som tilkom dem, og at derfor levittene og sangerne, som skulde gjøre tjeneste, hadde forlatt tjenesten og flyttet ut hver til sin gård. 
16:13:11: Da gikk jeg i rette med formennene og spurte dem hvorfor Guds hus var blitt forsømt, og jeg kalte dem sammen og innsatte dem igjen, hver på sin plass. 
16:13:12: Og hele Juda førte tienden av kornet, mosten og oljen til forrådskammerne. 
16:13:13: Så satte jeg presten Selemja og Sadok, den skriftlærde, og Pedaja, en av levittene, til å ha tilsyn med forrådskammerne og Hanan, sønn av Mattanjas sønn Sakkur, til å gå dem til hånde; for de blev holdt for å være pålitelige; dem pålå det nu å dele ut til sine brødre. 
16:13:14: Kom mig i hu, min Gud, for dette og utslett ikke de gode gjerninger som jeg har gjort mot min Guds hus og for tjenesten der! 
16:13:15: Ved samme tid så jeg i Juda nogen som trådte vinpersene om sabbaten og førte hjem kornbånd og lesste dem på asenene, og likeledes vin, druer, fikener og alle slags varer og førte det til Jerusalem på sabbatsdagen. Jeg advarte dem den dag de solgte disse levnetsmidler. 
16:13:16: Også folk fra Tyrus, som opholdt sig der, kom med fisk og alle slags varer og solgte dem på sabbaten til Judas barn, og det i Jerusalem. 
16:13:17: Da gikk jeg i rette med de fornemme i Juda og sa til dem: Hvorledes kan I bære eder så ille at og vanhellige sabbatsdagen? 
16:13:18: Var det ikke fordi eders fedre gjorde således at vår Gud førte all denne ulykke over oss og over denne by? Og nu fører I ennu større vrede over Israel ved å vanhellige sabbaten! 
16:13:19: Så snart det blev mørkt i Jerusalems porter før sabbaten, bød jeg at dørene skulde lukkes, og at de ikke skulde åpnes igjen før efter sabbaten. Jeg satte nogen av mine tjenere på vakt ved portene og sa: Det skal ingen varer føres inn på sabbatsdagen. 
16:13:20: Kremmerne og de som solgte alle slags varer, overnattet da utenfor Jerusalem både en og to ganger. 
16:13:21: Da advarte jeg dem og sa til dem: Hvorfor overnatter I foran muren? Gjør I det en gang til, så legger jeg hånd på eder. Fra den tid kom de ikke mere på sabbaten. 
16:13:22: Og levittene bød jeg å rense sig og komme og holde vakt ved portene, så sabbatsdagen kunde bli helligholdt. Kom mig også dette i hu, min Gud, og miskunn dig over mig efter din store nåde! SLM 
16:13:23: På den tid så jeg også de jøder som hadde tatt asdodittiske, ammonittiske og moabittiske kvinner til hustruer; 
16:13:24: av deres barn talte halvdelen asdodittisk - de kunde ikke tale jødisk - eller et av de andre folks tungemål. 
16:13:25: Jeg gikk i rette med dem og forbannet dem, ja, nogen av dem slo jeg og rev dem i håret; jeg lot dem sverge ved Gud og sa: I skal ikke gi eders døtre til deres sønner eller ta nogen av deres døtre til hustruer for eders sønner eller for eder selv. 
16:13:26: Var det ikke med sådant Israels konge Salomo forsyndet sig? Iblandt de mange folk var det ingen konge som han, og han var elsket av sin Gud, og Gud satte ham til konge over hele Israel; men endog ham forførte de fremmede kvinner til synd. 
16:13:27: Og eder skulde det være tillatt å gjøre denne store ondskap og vise troløshet mot vår Gud ved å ta fremmede kvinner til hustruer! 
16:13:28: En av sønnene til Jojada, ypperstepresten Eljasibs sønn, var svigersønn av horonitten Sanballat; derfor drev jeg ham bort fra mig. 
16:13:29: Kom dem i hu, min Gud, at de har vanæret prestedømmet og prestedømmets og levittenes pakt! 
16:13:30: Så renset jeg dem fra alt fremmed, og jeg foreskrev hvad prestene og levittene hadde å vareta, hver i sin gjerning, 
16:13:31: og hvorledes det skulde være med avgivelsen av ved til fastsatte tider og med førstegrøden. Kom mig det i hu, min Gud, og regn mig det til gode! 
17:1:1: I Ahasverus' tid - det var den Ahasverus som regjerte fra India like til Etiopia over hundre og syv og tyve landskaper - 
17:1:2: i de dager da kong Ahasverus satt på sin kongetrone i borgen Susan, 
17:1:3: i hans regjerings tredje år hendte det at han gjorde et gjestebud for alle sine fyrster og tjenere; Persias og Medias hærførere og hans fornemste menn og landskapenes fyrster var samlet hos ham. 
17:1:4: I mange dager - hundre og åtti dager - viste han dem sin kongelige herlighet og rikdom og sin storhets glans og prakt. 
17:1:5: Da de dager var til ende, gjorde kongen et gjestebud i syv dager for alt folket som fantes i borgen Susan, både store og små, i forgården til haven ved kongens slott. 
17:1:6: Tepper av hvitt linnet, bomullstøi og blått purpur var festet med snorer av hvit bomull og rødt purpur i sølvringer og til marmorsøiler; benker av gull og sølv stod på et gulv av alabast og hvitt marmor og perlemor og sort marmor. 
17:1:7: Drikkene blev skjenket i skåler av gull, og skålene var alle ulik hverandre, og kongelig vin var det i mengde, som det høvde hos en konge. 
17:1:8: Og for drikningen gjaldt den forskrift at ingen skulde nødes; for kongen hadde befalt alle sine hushovmestere å la enhver få så meget han selv ønsket. 
17:1:9: Samtidig gjorde dronning Vasti et gjestebud for kvinner i kong Ahasverus kongelige hus. 
17:1:10: På den syvende dag, da kongen var blitt vel til mote av vinen, sa han til Mehuman, Bista, Harbona, Bigta og Abagta, Setar og Karkas, de syv hoffmenn som gjorde tjeneste hos kong Ahasverus, 
17:1:11: at de skulde føre dronning Vasti frem for kongen, med kongelig krone på, så han kunde la folkene og fyrstene få se hennes skjønnhet; for hun var meget fager. 
17:1:12: Men dronning Vasti nektet å komme efter den befaling kongen hadde sendt henne gjennem hoffmennene. Da blev kongen harmfull, og hans vrede optendtes. 
17:1:13: Og kongen sa til vismennene, som forstod sig på tidene - således blev kongens saker fremlagt for alle som forstod sig på lov og rett, 
17:1:14: og de som stod ham nærmest, var Karsena, Setar, Admata, Tarsis, Meres, Marsena, Memukan, persernes og medernes syv fyrster, som alltid hadde adgang til kongen og inntok de øverste seter i riket - : 
17:1:15: Hvad er det efter loven å gjøre med dronning Vasti, fordi hun ikke har gjort hvad kong Ahasverus befalte henne gjennem hoffmennene? 
17:1:16: Da tok Memukan til orde for kongen og fyrstene: Dronning Vasti har ikke bare forbrutt sig mot kongen, men mot alle fyrster og alle folk i alle kong Ahasverus' landskaper. 
17:1:17: For dronningens adferd vil komme ut blandt alle kvinnene og få dem til å forakte sine ektemenn; de vil si: Kong Ahasverus bød at dronning Vasti skulde føres frem for ham, men hun kom ikke. 
17:1:18: Ja, allerede idag vil persernes og medernes fyrstinner når de får høre om dronningens adferd, si så til alle kongens fyrster, og der vil bli nok av forakt og vrede. 
17:1:19: Dersom det tykkes kongen godt, så la det utgå en kongelig befaling, og la den bli opskrevet blandt persernes og medernes lover, så den står urokkelig fast, at Vasti aldri mere skal komme for kong Ahasverus' øine, og kongen skal gi hennes kongelige verdighet til en annen kvinne, som er bedre enn hun! 
17:1:20: Når da det påbud som kongen utsteder, blir kjent i hele hans rike, så stort som det er, så vil alle kvinner vise sine ektemenn ære, både store og små. 
17:1:21: Disse ord syntes kongen og fyrstene godt om, og kongen gjorde som Memukan hadde sagt. 
17:1:22: Han sendte skrivelser til alle kongens landskaper, til hvert landskap i dets skrift og til hvert folk på dets tungemål, at hver mann skulde være herre i sitt hus og tale sitt folks tungemål. 
17:2:1: Da nogen tid var gått, og kongens vrede hadde lagt sig, kom han atter til å tenke på Vasti og det hun hadde gjort, og det som var besluttet om henne. 
17:2:2: Da sa kongens menn som gjorde tjeneste hos ham: Der skulde letes efter unge og fagre jomfruer for kongen, 
17:2:3: og kongen skulde sette menn i alle sitt rikes landskaper til å samle alle unge og fagre jomfruer til kvinnenes hus i borgen Susan under tilsyn av kongens hoffmann Hege, kvinnevokteren, og la dem få salver til å salve sig med, 
17:2:4: og så skulde den unge pike som behager kongen, bli dronning i Vastis sted! Det råd syntes kongen godt om, og han gjorde så. 
17:2:5: I borgen Susan var det dengang en jødisk mann som hette Mordekai; han var sønn av Ja'ir, som var sønn av Sime'i og sønnesønn av Kis, av Benjamins ætt. 
17:2:6: Han var blitt bortført fra Jerusalem med de fanger som blev bortført sammen med Judas konge Jekonja da han blev bortført av Babels konge Nebukadnesar. 
17:2:7: Han var fosterfar til Hadassa, det er Ester, en datter av hans farbror; for hun hadde hverken far eller mor. Den unge pike var velskapt og fager, og da hennes far og mor døde, hadde Mordekai tatt henne til sig som sin egen datter. 
17:2:8: Da nu kongens bud og befaling blev kjent, og mange unge piker samledes til borgen Susan under Hegais tilsyn, da blev også Ester hentet til kongens hus under tilsyn av Hegai, kvinnevokteren. 
17:2:9: Den unge pike behaget ham og vant hans yndest; derfor skyndte han sig med å gi henne salver til å salve sig med og den mat hun skulde ha, og de syv unge piker fra kongens hus som var utsett til å tjene henne; og han lot henne og hennes piker flytte inn i de beste rum i kvinnehuset. 
17:2:10: Ester hadde ikke sagt noget om sitt folk og sin ætt; for Mordekai hadde pålagt henne at hun ikke skulde si noget om det. 
17:2:11: Hver eneste dag gikk Mordekai frem og tilbake utenfor forgården til kvinnehuset for å få vite hvorledes det stod til med Ester, og hvad de gjorde med henne. 
17:2:12: Nu var det så at når raden kom til en av de unge piker at hun skulde gå inn til kong Ahasverus, efterat der i tolv måneder var blitt gjort med henne således som det var foreskrevet om kvinnene - for så lang tid gikk det med til deres salving, seks måneder med myrraolje og seks måneder med velluktende saker og med andre salver for kvinner - 
17:2:13: når da den unge pike gikk inn til kongen, fikk hun ta med sig fra kvinnenes hus til kongens hus alt det hun bad om. 
17:2:14: Om aftenen gikk hun inn, og om morgenen vendte hun tilbake til det andre kvinnehus og stod siden under tilsyn av kongens hoffmann Sa'asgas, medhustruenes vokter; hun kom ikke mere inn til kongen, medmindre kongen hadde slik hu til henne at hun blev kalt til ham med navns nevnelse. 
17:2:15: Da raden til å gå inn til kongen kom til Ester, datter av Abiha'il, som var farbror til hennes fosterfar Mordekai, krevde hun ikke annet enn det som kongens hoffmann Hegai, kvinnevokteren, rådet til. Og Ester vant yndest hos alle som så henne. 
17:2:16: Ester blev hentet til kong Ahasverus i hans kongelige hus i den tiende måned, det er måneden tebet, i hans regjerings syvende år. 
17:2:17: Kongen fikk Ester mere kjær enn alle de andre kvinner; hun vant nåde og yndest hos ham fremfor alle de andre jomfruer. Han satte en kongelig krone på hennes hode og gjorde henne til dronning i Vastis sted. 
17:2:18: Og kongen gjorde et stort gjestebud for alle sine fyrster og tjenere til ære for Ester, og han tilstod landskapene eftergivelse av skatter og delte ut gaver, som det høvde for en konge. 
17:2:19: Da det for annen gang blev samlet sammen jomfruer, satt Mordekai i kongens port. 
17:2:20: Men Ester hadde ikke sagt noget om sin ætt og sitt folk; for det hadde Mordekai pålagt henne, og Ester gjorde i alle ting som Mordekai sa, således som hun hadde gjort da hun blev opfostret hos ham. 
17:2:21: På den tid da Mordekai satt i kongens port, blev Bigtan og Teres, to av de hoffmenn hos kongen som holdt vakt ved dørtreskelen, vrede på kong Ahasverus og søkte å legge hånd på ham. 
17:2:22: Dette fikk Mordekai kunnskap om, og han fortalte det til dronning Ester; og Ester sa det til kongen på Mordekais vegne. 
17:2:23: Saken blev gransket, og da det viste sig at det var så, blev de begge hengt i en galge. Dette blev opskrevet i krønikeboken for kongens øine. 
17:3:1: Nogen tid efter ophøiet kong Ahasverus agagitten Haman, Hammedatas sønn, og gav ham rang og sete over alle de fyrster som var hos ham. 
17:3:2: Og alle kongens tjenere som var i kongens port, falt på kne og kastet sig ned for Haman, for så hadde kongen befalt om ham; men Mordekai falt ikke på kne og kastet sig ikke ned for ham. 
17:3:3: Da sa kongens tjenere som var i kongens port, til Mordekai: Hvorfor overtreder du kongens bud? 
17:3:4: Og da de dag efter dag hadde sagt så til ham, og han ikke hørte på dem, så meldte de det til Haman for å se om den grunn Mordekai gav, vilde bli tatt for god: for han hadde sagt dem at han var jøde. 
17:3:5: Da Haman så at Mordekai ikke falt på kne eller kastet sig ned for ham, blev han full av harme. 
17:3:6: Men det syntes ham alt for lite å legge hånd bare på Mordekai; for han hadde fått vite hvilket folk Mordekai hørte til; derfor søkte han å få utryddet alle de jøder som fantes i hele Ahasverus' rike, fordi det var Mordekais folk. 
17:3:7: I den første måned, det er måneden nisan, i kong Ahasverus' tolvte år blev det kastet pur, det er lodd, i Hamans påsyn om hver enkelt dag og hver enkelt måned like til den tolvte måned, det er måneden adar. 
17:3:8: Og Haman sa til kong Ahasverus: Her er et folk som bor spredt og for sig selv blandt de andre folk i alle ditt rikes landskaper, og deres lover er forskjellige fra alle andre folks; de holder sig ikke efter kongens lover, og det høver ikke for kongen å la dem være i fred. 
17:3:9: Dersom det tykkes kongen godt, så la det bli utferdiget en skrivelse om at de skal utryddes; og jeg skal da kunne tilveie embedsmennene ti tusen talenter sølv til å legge i kongens skattkammer. 
17:3:10: Da trakk kongen signetringen av sin hånd og gav den til agagitten Haman, Hammedatas sønn, jødenes fiende, 
17:3:11: og sa til ham: Sølvet skal være ditt, og med folket kan du gjøre som du finner for godt. 
17:3:12: Kongens skrivere blev da kalt sammen på den trettende dag i den første måned, og det blev utferdiget en skrivelse aldeles således som Haman bød, både til kongens stattholdere og til landshøvdingene for hvert landskap og til fyrstene over hvert folk - til hvert landskap i dets skrift og til hvert folk på dets tungemål. I kong Ahasverus' navn blev skrivelsene utferdiget, og de blev forseglet med kongens signetring. 
17:3:13: Så sendtes der med ilbud skrivelser til alle kongens landskaper at alle jøder skulde ødelegges, drepes og utryddes, både unge og gamle, små barn og kvinner, alle på en dag, på den trettende dag i den tolvte måned, det er måneden adar, og deres gods skulde gis til plyndring. 
17:3:14: Forat befalingen skulde bli kjent i hvert landskap, blev en avskrift av skrivelsen kunngjort for alle folkene, så de kunde holde sig rede til den nevnte dag. 
17:3:15: På kongens bud drog ilbudene i hast avsted så snart befalingen var utferdiget på borgen Susan. Så satte kongen og Haman sig til å drikke, men byen Susan var forferdet. 
17:4:1: Da Mordekai fikk vite alt som hadde hendt, sønderrev han sine klær og klædde sig i sekk og aske og gikk ut i byen under høie klagerop. 
17:4:2: Og han kom like til plassen foran kongens port; for ingen som var klædd i sekk, hadde lov til å gå inn i kongens port. 
17:4:3: Og i hvert eneste landskap, overalt hvor kongens ord og befaling nådde frem, blev det stor sorg blandt jødene og faste og gråt og jammer, ja, mange redet sig et leie av sekk og aske. 
17:4:4: Da kom Esters unge piker og hennes hoffmenn og fortalte henne det, og dronningen blev full av angst. Hun sendte klær til Mordekai, som han skulde ta på istedenfor de sekkeklær han gikk i; men han tok ikke imot dem. 
17:4:5: Ester kalte da til sig Hatak, en av kongens hoffmenn, som han hadde satt til å tjene henne. og bød ham gå til Mordekai for å få vite hvad som var på ferde, og hvorfor han bar sig således at. 
17:4:6: Hatak gikk ut til Mordekai på byens torv foran kongens port. 
17:4:7: Og Mordekai fortalte ham alt det som hadde hendt ham, og opgav for ham nøiaktig hvor meget sølv Haman hadde lovt å tilveie kongens skattkammer for å få utryddet jødene. 
17:4:8: Han gav ham også en avskrift av den skrevne befaling som var blitt gitt i Susan om å ødelegge dem, forat han skulde vise Ester den og melde henne alt og pålegge henne å gå inn til kongen og be og bønnfalle ham om nåde for sitt folk. 
17:4:9: Hatak kom og fortalte Ester hvad Mordekai hadde sagt. 
17:4:10: Da bød Ester Hatak å gå til Mordekai og si: 
17:4:11: Alle kongens tjenere og folket i kongens landskaper vet at hvis nogen, mann eller kvinne, går inn til kongen i den indre gård uten å være kalt, gjelder for enhver sådan samme lov: at han skal late livet, uten så er at kongen rekker ut sin gullstav mot ham, da får han leve; men jeg er nu i tretti dager ikke blitt kalt til å gå inn til kongen. 
17:4:12: Da Mordekai fikk høre hvad Ester hadde sagt, 
17:4:13: sa han at de skulde gi Ester dette svar: Du må ikke tenke at du alene av alle jøder skal slippe unda, fordi du er i kongens hus. 
17:4:14: For om du tier stille i denne tid, så vil det nok komme utfrielse og redning for jødene fra et annet sted, men du og din fars hus skal omkomme. Og hvem vet om du ikke nettop for en tid som denne er kommet til dronningverdigheten? 
17:4:15: Da sa Ester at det skulde gis Mordekai dette svar: 
17:4:16: Gå avsted og få samlet alle jøder som finnes i Susan, og hold faste for min skyld, så I hverken eter eller drikker noget i tre døgn, natt eller dag! Jeg og mine unge piker vil også faste på samme måte. Og så vil jeg gå inn til kongen, enda det ikke er efter loven. Skal jeg da omkomme, så får jeg omkomme. 
17:4:17: Mordekai gikk bort og gjorde alt det som Ester hadde pålagt ham. 
17:5:1: På den tredje dag klædde Ester sig i kongelig skrud og stilte sig i den indre gård til kongens hus, midt for kongens hus, og kongen satt på sin kongetrone i kongens hus, midt imot døren til huset. 
17:5:2: Da nu kongen så dronning Ester stå i gården, fant hun nåde for hans øine; kongen rakte ut gullstaven som han hadde i hånden, mot Ester, og Ester gikk frem og rørte ved enden av staven. 
17:5:3: Og kongen sa til henne: Hvad har du på hjerte, dronning Ester, og hvad er ditt ønske? Var det enn halvdelen av riket, skal du få det. 
17:5:4: Ester svarte: Tykkes det kongen godt, vilde jeg gjerne at kongen og Haman idag skulde komme til det gjestebud jeg har stelt til for ham! 
17:5:5: Da sa kongen: Skynd eder og hent Haman, så Ester kan få sitt ønske opfylt! Så kom da kongen og Haman til det gjestebud Ester hadde stelt til. 
17:5:6: Mens de satt og drakk vin, sa kongen til Ester: Hvad er din bønn? Den skal tilståes dig. Og hvad er ditt ønske? Var det enn halvdelen av riket, skal det opfylles. 
17:5:7: Ester svarte: Min bønn og mitt ønske er: 
17:5:8: Har jeg funnet nåde for kongens øine, og tykkes det kongen godt å tilstå mig min bønn og å opfylle mitt ønske, så må kongen og Haman komme til det gjestebud jeg vil stelle til for dem! Imorgen vil jeg da gjøre som kongen sier. 
17:5:9: Haman gikk den dag glad og vel til mote derfra, men da han fikk se Mordekai i kongens port, og at han hverken reiste sig eller rørte sig for ham, blev han full av vrede mot Mordekai. 
17:5:10: Men han holdt sig rolig og gikk hjem; så sendte han bud efter sine venner og sin hustru Seres 
17:5:11: og fortalte dem om sin rikdom og herlighet og om sine mange sønner og likeledes alt om hvorledes kongen hadde gjort ham stor, og hvorledes han hadde ophøiet ham over fyrstene og kongens tjenere. 
17:5:12: Og Haman sa: Heller ikke lot dronning Ester nogen annen enn mig komme med kongen til det gjestebud hun hadde stelt til, og også til imorgen er jeg innbudt av henne sammen med kongen. 
17:5:13: Men alt dette er mig ikke nok all den stund jeg ser jøden Mordekai sitte i kongens port. 
17:5:14: Da sa hans hustru Seres og alle hans venner til ham: La dem gjøre en galge i stand; femti alen høi, og be imorgen kongen om at Mordekai må bli hengt i den; så kan du gå glad med kongen til gjestebudet! Dette råd syntes Haman godt om, og han lot gjøre galgen i stand. 
17:6:1: Den natt fikk kongen ikke sove; han bød derfor å hente krønikeboken, som de minneverdige hendelser var optegnet i, og den blev oplest for kongen. 
17:6:2: Der fant de optegnet at Mordekai hadde meldt hvorledes Bigtana og Teres, to av de hoffmenn hos kongen som holdt vakt ved dørtreskelen, hadde søkt å legge hånd på kong Ahasverus. 
17:6:3: Kongen spurte da: Hvad ære og ophøielse er det blitt Mordekai til del for dette? Kongens tjenere svarte: Han har ikke fått nogen ting. 
17:6:4: Så sa kongen: Hvem er det som er ute i gården? Da var Haman just kommet inn i den ytre gård til kongens hus for å be kongen om at Mordekai måtte bli hengt i den galge han hadde gjort i stand for ham. 
17:6:5: Kongens tjenere svarte: Det er Haman som står der ute i gården. Kongen så: La ham komme inn! 
17:6:6: Da nu Haman kom inn, spurte kongen ham: Hvad skal det gjøres med den mann kongen har lyst til å ære? Da tenkte Haman ved sig selv: Hvem skulde kongen ha mere lyst til å vise ære enn mig? 
17:6:7: Og han sa til kongen: Er det en mann kongen har lyst til å ære, 
17:6:8: så la det bli hentet en kongelig klædning som kongen selv har båret, og en hest som kongen selv har ridd på, og på hvis hode det er satt en kongelig krone, 
17:6:9: og la så klædningen og hesten bli overgitt til en av kongens fornemste fyrster, og han skal la den mann kongen har lyst til å ære, få klædningen på og la ham ride på hesten gjennem byens gater og rope foran ham: Således gjøres det med den mann som kongen har lyst til å ære! 
17:6:10: Da sa kongen til Haman: Skynd dig, ta klædningen og hesten, som du har sagt, og gjør således med jøden Mordekai, som sitter i kongens port! La intet bli forsømt av alt det du har sagt! 
17:6:11: Haman tok klædningen og hesten og gav Mordekai klædningen på og lot ham ride gjennem byens gater og ropte foran ham: Således gjøres det med den mann som kongen har lyst til å ære! 
17:6:12: Så vendte Mordekai tilbake til kongens port; men Haman skyndte sig hjem, sorgfull og med tildekket hode. 
17:6:13: Han fortalte sin hustru Seres og alle sine venner alt det som hadde hendt ham; da sa hans vismenn og hans hustru Seres til ham: Hvis Mordekai, som du har begynt å stå tilbake for, er av jødisk ætt, da formår du intet mot ham, men vil komme til å stå aldeles tilbake for ham. 
17:6:14: Mens de ennu talte med ham, kom kongens hoffmenn for i all hast å føre Haman til det gjestebud Ester hadde stelt til. 
17:7:1: Så kom da kongen og Haman til gjestebudet hos dronning Ester. 
17:7:2: Mens de satt og drakk vin, sa kongen også denne annen dag til Ester: Hvad er din bønn, dronning Ester? Den skal tilståes dig. Og hvad er ditt ønske? Var det enn halvdelen av riket, skal det opfylles. 
17:7:3: Da svarte dronning Ester: Har jeg funnet nåde for dine øine, konge, og tykkes det kongen godt, så la mitt liv bli mig gitt på min bønn, og mitt folks liv efter mitt ønske! 
17:7:4: For vi er solgt, både jeg og mitt folk, til å ødelegges, drepes og utryddes; var det enda bare til å være træler og trælkvinner vi var solgt, da hadde jeg tidd, men nu er vår fiende ikke i stand til å oprette kongens tap. 
17:7:5: Da sa kong Ahasverus til dronning Ester: Hvem er han, og hvor er han som har dristet sig til å gjøre så? 
17:7:6: Ester svarte: En motstander og fiende, denne onde Haman der! Da blev Haman forferdet for kongens og dronningens åsyn. 
17:7:7: Kongen reiste sig i harme, forlot gjestebudet og gikk ut i slottshaven; men Haman blev tilbake for å be dronning Ester om sitt liv; for han skjønte at kongen hadde fast besluttet hans ulykke. 
17:7:8: Da så kongen kom tilbake fra slottshaven til huset hvor gjestebudet var blitt holdt, lå Haman bøid over den benk som Ester satt på; da sa kongen: Tør han endog øve vold mot dronningen her i mitt eget hus? Ikke før var dette ord gått ut av kongens munn, før de tildekket Hamans ansikt. 
17:7:9: Harbona, en av hoffmennene hos kongen, sa: Se, ved Hamans hus står det allerede en galge, femti alen høi, som han selv har latt gjøre i stand for Mordekai, han hvis ord engang var til så stort gagn for kongen. Da sa kongen: Heng ham i den! 
17:7:10: Så hengte de Haman i den galge han hadde gjort i stand for Mordekai, og kongens vrede la sig. 
17:8:1: Samme dag gav kong Ahasverus dronning Ester det hus som hadde tilhørt Haman, jødenes fiende; og Mordekai fikk komme inn for kongen, for Ester hadde fortalt hvad han var for henne. 
17:8:2: Kongen trakk av sin signetring, som han hadde tatt fra Haman, og gav den til Mordekai, og Ester satte Mordekai over Hamans hus. 
17:8:3: Ester vendte sig atter til kongen, kastet sig ned for hans føtter og gråt og bønnfalt ham om å avvende agagitten Hamans ondskap og det råd han hadde lagt op mot jødene. 
17:8:4: Kongen rakte ut gullstaven mot Ester; da reiste Ester sig op og trådte frem for kongen. 
17:8:5: Og hun sa: Tykkes det kongen godt, og har jeg funnet nåde for hans åsyn, og synes det kongen å være rett, og er jeg ham til behag, så la det bli utferdiget skrivelser for å tilbakekalle de brev som inneholdt agagitten Hamans, Hammedatas sønns onde råd, de som han skrev for å få utryddet jødene i alle kongens landskaper. 
17:8:6: Hvorledes skulde jeg kunne se på den ulykke som vilde ramme mitt folk, og hvorledes skulde jeg kunne se på min ætts undergang? 
17:8:7: Da sa kong Ahasverus til dronning Ester og til jøden Mordekai: Jeg har jo gitt Ester Hamans hus, og han selv er blitt hengt i galgen, fordi han vilde legge hånd på jødene. 
17:8:8: Så skriv nu I brev om jødene i kongens navn, således som I finner for godt, og forsegl dem med kongens signetring! For en skrivelse som er utferdiget i kongens navn og forseglet med kongens signetring, kan ikke tilbakekalles. 
17:8:9: Så blev da straks kongens skrivere kalt sammen på den tre og tyvende dag i den tredje måned - det er måneden sivan - og det blev skrevet aldeles som Mordekai bød, til jødene og til stattholderne og landshøvdingene og landskapenes fyrster fra India like til Etiopia, hundre og syv og tyve landskaper, til hvert landskap i dets skrift og til hvert folk på dets tungemål, også til jødene i deres skrift og på deres tungemål. 
17:8:10: Han skrev i kong Ahasverus' navn og forseglet med kongens signetring. Så sendte han brevene avsted med ilbud, som red på travere fra kongens egne staller, 
17:8:11: og i dem stod at kongen gav jødene i hver by lov til å slå sig sammen og verge sitt liv og i hvert folk og landskap å ødelegge, drepe og utrydde alle væbnede skarer som angrep dem, endog små barn og kvinner, og plyndre deres gods, 
17:8:12: alt på en og samme dag i alle kong Ahasverus' landskaper, på den trettende dag i den tolvte måned, det er måneden adar. 
17:8:13: Forat befalingen skulde bli kjent i hvert landskap, blev en avskrift av skrivelsen kunngjort for alle folkene, så jødene kunde holde sig rede til den dag og hevne sig på sine fiender. 
17:8:14: På kongens bud drog ilbudene, som red på de kongelige travere, avsted i største hast så snart befalingen var utferdiget i borgen Susan. 
17:8:15: Mordekai gikk ut fra kongen i kongelig klædning av blått purpur og hvitt linnet og med en stor gullkrone og en kåpe av hvitt bomullstøi og rødt purpur; og byen Susan jublet høit og gledet sig. 
17:8:16: Hos jødene var det nu schmelk og glede og fryd og ære, 
17:8:17: og i hvert eneste landskap og i hver eneste by, overalt hvor kongens ord og befaling nådde frem, blev det glede og fryd blandt jødene med gjestebud og høitid; og mange av folkene i landet blev jøder, for frykt for jødene var falt på dem. 
17:9:1: På den trettende dag i den tolvte måned, det er måneden adar, den dag da kongens ord og bud skulde settes i verk, og jødenes fiender hadde håpet å få dem i sin makt - nu hadde det vendt sig så at jødene fikk sine fiender i sin makt - 
17:9:2: den dag slo jødene sig sammen i sine byer i alle kong Ahasverus' landskaper for å legge hånd på dem som søkte deres ulykke; og ingen kunde stå sig mot dem, for frykt for dem var falt på alle folkene. 
17:9:3: Alle landskapenes fyrster og stattholderne og landshøvdingene og kongens embedsmenn hjalp jødene, for frykt for Mordekai var falt på dem; 
17:9:4: for Mordekai var nu en stor mann i kongens hus, og hans ry gikk viden om i alle landskapene, for han - Mordekai - blev større og større. 
17:9:5: Så slo da jødene alle sine fiender med sverd og død og undergang, og de gjorde som de vilde, mot dem som hatet dem. 
17:9:6: I borgen Susan drepte og ødela jødene fem hundre mann. 
17:9:7: Også Parsandata og Dalfon og Aspata 
17:9:8: og Porata og Adalja og Aridata 
17:9:9: og Parmasta og Arisai og Aridai og Vaisata, 
17:9:10: de ti sønner til Haman, Hammedatas sønn, jødenes fiende, drepte de; men på byttet la de ikke hånd. 
17:9:11: Samme dag fikk kongen vite tallet på dem som var drept i borgen Susan. 
17:9:12: Da sa kongen til dronning Ester: I borgen Susan har jødene drept og utryddet fem hundre mann og Hamans ti sønner; hvad har de da ikke gjort i kongens andre landskaper? Hvad er nu din bønn? Den skal tilståes dig. Og hvad ønsker du mere? Ditt ønske skal bli opfylt. 
17:9:13: Ester svarte: Tykkes det kongen godt, så la jødene i Susan også imorgen få lov til å gjøre likedan som idag, og la Hamans ti sønner bli hengt i galgen! 
17:9:14: Kongen bød at så skulde gjøres, og det blev utferdiget en befaling derom i Susan; Hamans ti sønner blev hengt. 
17:9:15: Jødene i Susan slo sig sammen også på den fjortende dag i måneden adar og drepte i Susan tre hundre mann; men på byttet la de ikke hånd. 
17:9:16: De andre jøder i kongens landskaper slo sig også sammen og verget sitt liv og fikk ro for sine fiender; de drepte fem og sytti tusen av dem som hatet dem; men på byttet la de ikke hånd. 
17:9:17: Dette hendte den trettende dag i måneden adar; og på den fjortende dag hvilte de ut, og de gjorde den til en gjestebuds- og gledesdag. 
17:9:18: Men jødene i Susan slo sig sammen både på den trettende dag og på den fjortende dag i måneden, og på den femtende dag hvilte de ut, og de gjorde den til en gjestebuds- og gledesdag. 
17:9:19: Derfor høitideligholder jødene på landet - de som bor i landsbyene - den fjortende dag i måneden adar som en gledesdag med gjestebud og høitid, og på den sender de hverandre gaver av den mat de har laget til. 
17:9:20: Mordekai skrev op disse hendelser og sendte brev til alle jødene i alle kong Ahasverus' landskaper, nær og fjern, 
17:9:21: og fastsatte det som en lov for dem at de år efter år skulde høitideligholde den fjortende dag og den femtende dag i måneden adar, 
17:9:22: fordi det var på de dager jødene hadde fatt ro for sine fiender, og fordi det var i den måned deres bedrøvelse hadde vendt sig til glede og deres sorg til høitid; derfor skulde de gjøre disse dager til gjestebuds- og gledesdager og sende mat til hverandre og gaver til de fattige. 
17:9:23: Jødene vedtok som fast skikk hvad de hadde begynt å gjøre, og hvad Mordekai hadde skrevet til dem om; 
17:9:24: for agagitten Haman, Hammedatas sønn, alle jøders fiende, hadde lagt råd op mot jødene for å utrydde dem og kastet pur, det er lodd, for å ødelegge og utrydde dem, 
17:9:25: men da det kom kongen for øre, hadde han ved et brev påbudt at det onde råd han hadde lagt op mot jødene, skulde vende tilbake på hans eget hode, så han selv og hans sønner blev hengt i galgen. 
17:9:26: Derfor kalte de disse dager purim efter ordet pur. Og på grunn av alt det som stod i dette brev, og det som de selv hadde sett, og som hadde hendt dem, 
17:9:27: fastsatte jødene og vedtok som ubrytelig skikk for sig og sine efterkommere og for alle som gikk over til deres tro, at de år efter år skulde høitideligholde disse to dager efter forskriften om dem og på den for dem fastsatte tid, 
17:9:28: og at disse dager skulde ihukommes og høitideligholdes gjennem alle tider, i hver ætt, i hvert landskap og i hver by, og at disse purim-dager ikke skulde falle bort blandt jødene, og minnet om dem aldri ophøre blandt deres efterkommere. 
17:9:29: Dronning Ester, Abiha'ils datter, og jøden Mordekai skrev atter et brev med myndige ord for å slå fast som lov det som stod i dette nye brev om purim. 
17:9:30: Og han sendte skrivelser til alle jødene i de hundre og syv og tyve landskaper i Ahasverus' rike med vennlige og alvorlige ord, 
17:9:31: for å slå fast som lov at de skulde holde disse purim-dager på de for dem fastsatte tider, således som jøden Mordekai og dronning Ester hadde foreskrevet om dem, og således som de hadde vedtatt det for sig selv og sine efterkommere om fastene og klageropene på dem. 
17:9:32: Således blev disse forskrifter om purim-festen fastsatt som lov ved Esters bud, og det blev opskrevet i en bok. 
17:10:1: Kong Ahasverus la skatt på fastlandet og på øene i havet. 
17:10:2: Men alt det han gjorde i sin makt og velde, og en nøiaktig fremstilling av den høie rang som kongen ophøiet Mordekai til, det er opskrevet i de mediske og persiske kongers krønike. 
17:10:3: For jøden Mordekai var den næste efter kong Ahasverus, og han var høit æret blandt jødene og avholdt av sine mange brødre; han søkte sitt folks vel og talte til beste for hele sin ætt. 
18:1:1: I landet Us var det en mann som hette Job; han var en ulastelig og rettskaffen mann, som fryktet Gud og vek fra det onde. 
18:1:2: Han fikk syv sønner og tre døtre, 
18:1:3: og han eide syv tusen får og tre tusen kameler og fem hundre par okser og fem hundre aseninner og hadde en stor mengde tjenere; han var mektigere enn alle Østens barn. 
18:1:4: Hans sønner pleide å holde gjestebud på hver sin dag i hver sitt hus, og de sendte da bud til sine tre søstre og innbød dem til å ete og drikke sammen med dem. 
18:1:5: Og så ofte en omgang av disse gjestebud var til ende, sendte Job bud efter dem og helliget dem; han stod tidlig op om morgenen og ofret brennoffer, ett for hver av dem; for Job sa: Kanskje mine sønner har syndet og sagt Gud farvel i sitt hjerte. Således gjorde Job alltid. 
18:1:6: Så hendte det en dag at Guds sønner kom og stilte sig frem for Herren, og blandt dem kom også Satan. 
18:1:7: Og Herren sa til Satan: Hvor kommer du fra? Satan svarte Herren: Jeg har faret og flakket omkring på jorden. 
18:1:8: Da sa Herren til Satan: Har du gitt akt på min tjener Job? For det er ingen på jorden som han - en ulastelig og rettskaffen mann, som frykter Gud og viker fra det onde. 
18:1:9: Men Satan svarte Herren: Mon Job frykter Gud for intet? 
18:1:10: Har du ikke hegnet om ham og om hans hus og om alt som hans er, på alle kanter? Hans henders gjerning har du velsignet, og hans hjorder har bredt sig vidt utover i landet. 
18:1:11: Men rekk bare ut din hånd og rør ved alt som hans er! Da vil han visselig si dig farvel like i ditt ansikt. 
18:1:12: Da sa Herren til Satan: Se, alt som hans er, er i din hånd; men mot ham selv må du ikke rekke ut din hånd. Så gikk Satan bort fra Herrens åsyn. 
18:1:13: Så hendte det en dag mens hans sønner og døtre åt og drakk vin i den eldste brors hus, 
18:1:14: da kom det et bud til Job og sa: Oksene pløide, og aseninnene beitet tett ved; 
18:1:15: da kom sabeerne over dem og tok dem, og drengene hugg de ned med sverdet, og bare jeg alene slapp unda, så jeg kunde melde dig det. 
18:1:16: Mens han ennu talte, kom en annen og sa: Guds ild fór ned fra himmelen og rammet småfeet og drengene og brente dem op, bare jeg alene slapp unda, så jeg kunde melde dig det. 
18:1:17: Mens han ennu talte, kom en annen og sa: Kaldeerne delte sig i tre flokker og overfalt kamelene og tok dem, og drengene hugg de ned med sverdet, og bare jeg alene slapp unda, så jeg kunde melde dig det. 
18:1:18: Mens han ennu talte, kom en annen og sa: Dine sønner og døtre åt og drakk vin i den eldste brors hus; 
18:1:19: da kom det med ett en sterk storm fra hin side ørkenen og støtte mot alle fire hjørner av huset, så det falt sammen over de unge folk, og de omkom, og bare jeg alene slapp unda, så jeg kunde melde dig det. 
18:1:20: Da reiste Job sig og sønderrev sin kappe og klipte håret av sitt hode og kastet sig ned på jorden og tilbad 
18:1:21: og sa: Naken kom jeg av min mors liv, og naken skal jeg vende dit tilbake. Herren gav, og Herren tok; Herrens navn være lovet! 
18:1:22: Under alt dette syndet Job ikke, og han lastet ikke Gud for det som hadde hendt ham. 
18:2:1: Så hendte det en dag at Guds sønner kom og stilte sig frem for Herren, og blandt dem kom også Satan og stilte sig frem for Herren. 
18:2:2: Og Herren sa til Satan: Hvor kommer du fra? Satan svarte Herren: Jeg har faret og flakket omkring på jorden. 
18:2:3: Da sa Herren til Satan: Har du gitt akt på min tjener Job? For det er ingen på jorden som han - en ulastelig og rettskaffen mann, som frykter Gud og viker fra det onde; ennu er han like ulastelig, og du har uten grunn egget mig til å ødelegge ham. 
18:2:4: Men Satan svarte Herren: Hud for hud, men alt det en mann har, gir han for sitt liv. 
18:2:5: Men rekk bare ut din hånd og rør ved hans ben og hans kjøtt! Da vil han visselig si dig farvel like i ditt ansikt. 
18:2:6: Da sa Herren til Satan: Se, han er i din hånd; spar bare hans liv! 
18:2:7: Så gikk Satan bort fra Herrens åsyn, og han slo Job med onde bylder fra fotsålen til issen. 
18:2:8: Og han tok sig et potteskår og skrapte sig med, der han satt midt i asken. 
18:2:9: Da sa hans hustru til ham: Holder du dig ennu like ulastelig? Si Gud farvel og dø! 
18:2:10: Men han svarte: Du taler som en av de uforstandige kvinner; skal vi bare ta imot det gode av Gud og ikke også det onde? Under alt dette syndet Job ikke med sine leber. 
18:2:11: Da Jobs tre venner fikk høre om all denne ulykke som var kommet over ham, kom de fra hver sitt hjemsted: Elifas fra Teman, Bildad fra Suah og Sofar fra Na'ama; og de avtalte med hverandre å gå til ham for å vise ham medynk og trøste ham. 
18:2:12: Men da de så op et stykke ifra, kjente de ham ikke, og de brast i gråt og sønderrev sine kapper og kastet støv op imot himmelen, ned over sine hoder. 
18:2:13: Og de satt hos ham på jorden i syv dager og syv netter, og ingen talte et ord til ham; for de så at hans pine var såre stor. 
18:3:1: Derefter oplot Job sin munn og forbannet den dag han blev født. 
18:3:2: Job tok til orde og sa: 
18:3:3: Til grunne gå den dag da jeg blev født, og den natt som sa: Et guttebarn er undfanget! 
18:3:4: Måtte den dag bli til mørke! Måtte Gud i det høie ikke spørre efter den, og intet lys stråle over den! 
18:3:5: Gid mørke og dødsskygge må kreve den tilbake, gid skyer må leire sig over den, gid alt som gjør en dag mørk, må skremme den! 
18:3:6: Den natt - måtte mulm ta den! Den glede sig ikke blandt årets dager, den komme ikke med i måneders tall! 
18:3:7: Ja, ufruktbar bli den natt! Aldri lyde det jubel i den! 
18:3:8: Måtte de som forbanner dager, ønske ondt over den, de som er kyndige i å mane frem Leviatan! 
18:3:9: Gid dens demrings stjerner må bli mørke! La den vente på schmelk, uten at det kommer! Måtte den aldri skue morgenrødens øielokk - 
18:3:10: fordi den ikke stengte døren til min mors liv og skjulte møie for mine øine. 
18:3:11: Hvorfor døde jeg ikke i mors liv? Hvorfor utåndet jeg ikke straks i fødselsstunden? 
18:3:12: Hvorfor tok knær imot mig, og hvorfor bryster som jeg kunde die? 
18:3:13: For da kunde jeg nu ligge og hvile; jeg kunde sove og hadde da ro - 
18:3:14: sammen med konger og jordens styrere, som bygget sig ruiner, 
18:3:15: eller med fyrster som eide gull, som fylte sine hus med sølv; 
18:3:16: eller jeg var nu ikke til, likesom et nedgravd, ufullbåret foster, lik barn som aldri så schmelken. 
18:3:17: Der har de ugudelige holdt op å rase, og der hviler de trette. 
18:3:18: Der har alle fanger ro, de hører ikke driverens røst. 
18:3:19: Liten og stor er der like, og trælen er fri for sin herre. 
18:3:20: Hvorfor gir han* den lidende lys, og liv til dem som bærer sorg i sitt hjerte, # <* Gud.> 
18:3:21: dem som venter på døden uten at den kommer, og som leter efter den ivrigere enn efter skjulte skatter, 
18:3:22: dem som gleder sig like til jubel, som fryder sig når de finner en grav - 
18:3:23: til den mann hvis vei er skjult for ham, og som Gud har stengt for på alle kanter? 
18:3:24: For mine sukk er blitt mitt daglige brød, og mine klager strømmer som vannet. 
18:3:25: For alt det fryktelige jeg reddes for, det rammer mig, og det jeg gruer for, det kommer over mig. 
18:3:26: Jeg har ikke fred, ikke ro, ikke hvile - det kommer alltid ny uro. 
18:4:1: Da tok Elifas fra Teman til orde og sa: 
18:4:2: Om en prøvde å tale et ord til dig, vilde du da ta det ille op? Men hvem kan vel holde sine ord tilbake? 
18:4:3: Du har selv vist mange til rette, og maktløse hender styrket du; 
18:4:4: dine ord reiste den snublende op, og synkende knær gjorde du sterke. 
18:4:5: Men nu, når det gjelder dig selv, blir du utålmodig, når det rammer dig, blir du forferdet. 
18:4:6: Er ikke din gudsfrykt din tillit, din ulastelige ferd ditt håp? 
18:4:7: Tenk efter: Hvem omkom uskyldig, og hvor gikk rettskafne til grunne? 
18:4:8: Efter det jeg har sett, har de som pløide urett og sådde nød, også høstet det. 
18:4:9: De omkom for Guds ånde, og for hans vredes pust blev de til intet. 
18:4:10: Løvens brøl og dens fryktelige røst hørtes ikke lenger, og ungløvenes tenner blev knust. 
18:4:11: Løven omkom av mangel på rov, og løvinnens unger blev adspredt. 
18:4:12: Og til mig stjal sig et ord; det lød for mitt øre som en hvisken, 
18:4:13: under skiftende tanker ved nattlige syner, når dyp søvn faller på menneskene. 
18:4:14: Frykt og beven kom over mig, så alle mine ben tok til å skjelve. 
18:4:15: Og en ånd fór forbi mitt åsyn; hårene på mitt legeme reiste sig. 
18:4:16: Den blev stående, men jeg skjelnet ikke klart hvorledes den så ut - det var en skikkelse som stod der for mine øine; jeg hørte en stille susen og en røst: 
18:4:17: Er et menneske rettferdig for Gud, eller en mann ren for sin skaper? 
18:4:18: Se, på sine tjenere stoler han ikke, og hos sine engler finner han feil*, # <* d.e. ufullkommenhet.> 
18:4:19: hvor meget mere da hos dem som bor i hus av ler, og som har sin grunnvoll i støvet - de som knuses lettere enn møll. 
18:4:20: Fra morgen til aften - så er de sønderslått; uten at nogen akter på det, går de til grunne for alltid. 
18:4:21: Blir ikke teltsnoren dradd ut* hos dem? De dør, men ikke i visdom. # <* så deres jordiske telt (2KO 5, 1.) faller sammen.> 
18:5:1: Rop du bare! Er det vel nogen som svarer dig? Og til hvem av de hellige vil du vende dig? 
18:5:2: For harme slår dåren ihjel, og vrede dreper den tåpelige. 
18:5:3: Jeg så en dåre skyte røtter; men med ett måtte jeg rope ve over hans bolig. 
18:5:4: Hans barn var uten hjelp; de blev trådt ned i porten, og det var ingen som frelste dem. 
18:5:5: De hungrige åt op hans avling, ja, midt ut av torner hentet de den, og snaren lurte på hans gods. 
18:5:6: For ikke skyter ulykke op av støvet, og møie spirer ikke frem av jorden; 
18:5:7: men mennesket fødes til møie, likesom ildens gnister flyver høit i været. 
18:5:8: Men jeg vilde* vende mig til Gud og overlate min sak til ham, # <* om jeg var i ditt sted.> 
18:5:9: han som gjør store, uransakelige ting, under uten tall, 
18:5:10: som sender regn utover jorden og lar vann strømme utover markene, 
18:5:11: som ophøier de ringe og lar de sørgende nå frem til frelse, 
18:5:12: som gjør de kløktiges råd til intet, så deres hender ikke får utrettet noget som varer, 
18:5:13: han som fanger de vise i deres kløkt og lar de listiges råd bli forhastet; 
18:5:14: om dagen støter de på mørke, og om middagen famler de som om natten. 
18:5:15: Og således frelser han den fattige fra sverdet, fra deres munn og fra den sterkes hånd, 
18:5:16: og det blir håp for den ringe, og ondskapen må lukke sin munn. 
18:5:17: Ja, salig er det menneske Gud refser, og den Allmektiges tukt må du ikke akte ringe! 
18:5:18: For han sårer, og han forbinder; han slår, og hans hender læger. 
18:5:19: I seks trengsler skal han berge dig, og i den syvende skal intet ondt røre dig. 
18:5:20: I hungersnød frir han dig fra døden og i krig fra sverdets vold. 
18:5:21: For tungens svepe skal du være skjult, og du skal ikke frykte når ødeleggelsen kommer. 
18:5:22: Ødeleggelse og hunger skal du le av, og for jordens ville dyr skal du ikke frykte; 
18:5:23: for med markens stener står du i pakt, og markens ville dyr holder fred med dig. 
18:5:24: Og du skal få se at ditt telt er trygt, og ser du over din eiendom, skal du intet savne. 
18:5:25: Og du skal få se at din ætt blir tallrik, og dine efterkommere som jordens urter. 
18:5:26: Du skal i fullmoden alder gå i graven, likesom kornbånd føres inn sin tid. 
18:5:27: Se, dette er det vi har utgransket, og således er det. Hør det og merk dig det! 
18:6:1: Da tok Job til orde og sa: 
18:6:2: Gid min gremmelse blev veid, og min ulykke samtidig lagt på vekten! 
18:6:3: For nu er den tyngre enn havets sand; derfor var mine ord tankeløse. 
18:6:4: For den Allmektiges piler sitter i mig, og min ånd drikker deres gift; Guds redsler stiller sig op imot mig. 
18:6:5: Skriker vel et villesel midt i det grønne gress? Eller brøler en okse foran sitt fôr? 
18:6:6: Hvem vil ete det som det ingen smak er i, uten salt? Eller er det smak i eggehvite? 
18:6:7: Det byr mig imot å røre ved det*; det er for mig som utskjemt mat. # <* d.e. ved mine lidelser.> 
18:6:8: Gid min bønn måtte bli hørt, og Gud vilde opfylle mitt håp! 
18:6:9: Og måtte det behage Gud å knuse mig, å slippe løs sin hånd og avskjære min livstråd! 
18:6:10: Da hadde jeg ennu en trøst, og jeg skulde springe av glede midt i den skånselløse smerte; for jeg har ikke fornektet den Helliges ord. 
18:6:11: Hvad kraft har jeg, så jeg kunde holde ut, og hvad blir enden med mig, så jeg kunde være tålmodig? 
18:6:12: Er da min kraft som stenens kraft? Eller er mitt kjøtt av kobber? 
18:6:13: Er jeg da ikke aldeles hjelpeløs? Er ikke all utsikt til frelse fratatt mig? 
18:6:14: Den ulykkelige burde møte kjærlighet hos sin venn, selv om han opgir frykten for den Allmektige. 
18:6:15: Men mine brødre har sviktet som en bekk, som strømmer hvis vann skyller over, 
18:6:16: som er grumset av is, og som det skjuler sig sne i; 
18:6:17: men på den tid de treffes av solens glød, tørkes de ut; når det blir hett, svinner de bort. 
18:6:18: Karavaner som er på veien til dem, bøier av; de drar op i ørkenen og omkommer. 
18:6:19: Temas karavaner speidet efter dem, Sjebas reisefølger satte sitt håp til dem; 
18:6:20: de blev til skamme, fordi de stolte på dem; de kom dit og blev skuffet. 
18:6:21: Således er I nu blitt til intet; I ser ulykken og blir redde. 
18:6:22: Har jeg vel bedt eder at I skulde gi mig noget eller bruke noget av eders gods til beste for mig, 
18:6:23: at I skulde frelse mig av fiendens hånd og løskjøpe mig fra voldsmenn? 
18:6:24: Lær mig, så skal jeg tie, og vis mig hvori jeg har faret vill! 
18:6:25: Hvor kraftige er ikke rettsindige ord! Men hvad gagn er det i en refselse fra eder? 
18:6:26: Tenker I på å refse ord? Ord av en fortvilet mann hører jo vinden til. 
18:6:27: Endog om en farløs kunde I kaste lodd og kjøpslå om eders venn. 
18:6:28: Men gjør nu så vel å se på mig! Skulde jeg vel ville lyve eder midt op i ansiktet? 
18:6:29: Vend om, la det ikke skje urett! Vend om, jeg har ennu rett i dette. 
18:6:30: Er det urett på min tunge, eller skulde min gane ikke merke hvad som er ondt? 
18:7:1: Er ikke et menneskes liv på jorden en krigstjeneste, og hans dager som en dagarbeiders dager? 
18:7:2: Lik en træl som higer efter skygge, og lik en dagarbeider som venter på sin lønn, 
18:7:3: således har jeg fått i eie måneder fulle av nød, og møiefulle netter er falt i min lodd. 
18:7:4: Når jeg legger mig, da sier jeg: Når skal jeg stå op? Og lang blir aftenen, og jeg blir trett av å kaste mig hit og dit inntil morgenlysningen. 
18:7:5: Mitt kjøtt er klædd med makk og med skorper som av jord; min hud skrukner og brister. 
18:7:6: Mine dager farer hurtigere avsted enn en veverskyttel, og de svinner bort uten håp. 
18:7:7: Kom i hu at mitt liv er et pust! Aldri mere skal mitt øie se noget godt. 
18:7:8: Den som nu ser mig, skal ikke mere få øie på mig; når dine øine søker efter mig, er jeg ikke mere. 
18:7:9: En sky blir borte og farer avsted; således er det med den som farer ned til dødsriket - han stiger ikke op derfra, 
18:7:10: han vender ikke mere tilbake til sitt hus, og hans sted kjenner ham ikke lenger. 
18:7:11: Så vil da heller ikke jeg legge bånd på min munn; jeg vil tale i min ånds trengsel, jeg vil klage i min sjels bitre smerte. 
18:7:12: Er jeg et hav eller et havuhyre, siden du setter vakt over mig? 
18:7:13: Når jeg sier: Min seng skal trøste mig, mitt leie skal hjelpe mig å bære min sorg, 
18:7:14: da skremmer du mig med drømmer og forferder mig med syner. 
18:7:15: Derfor foretrekker min sjel å kveles - heller døden enn disse avmagrede ben! 
18:7:16: Jeg er kjed av dette; jeg lever ikke evindelig; la mig være, for mine dager er et pust. 
18:7:17: Hvad er et menneske, at du gir så meget akt på ham og retter dine tanker på ham, 
18:7:18: at du opsøker ham hver morgen og prøver ham hvert øieblikk? 
18:7:19: Hvor lenge skal det vare før du vender dine øine bort fra mig? Vil du ikke slippe mig til jeg får svelget mitt spytt? 
18:7:20: Har jeg syndet, hvad ondt gjorde jeg da mot dig, du menneskevokter? Hvorfor har du gjort mig til skive for dig, så jeg er mig selv til byrde? 
18:7:21: Og hvorfor tilgir du ikke min brøde og forlater mig min misgjerning? For nu må jeg legge mig i støvet; når du søker mig, er jeg ikke mere. 
18:8:1: Da tok Bildad fra Suah til orde og sa: 
18:8:2: Hvor lenge vil du tale så? Hvor lenge skal din munns ord være som et veldig vær? 
18:8:3: Skulde vel Gud forvende retten, eller den Allmektige forvende rettferdigheten? 
18:8:4: Har dine sønner syndet mot ham, så har han gitt dem deres brøde i vold. 
18:8:5: Hvis du vender dig til Gud og beder den Allmektige om nåde, 
18:8:6: hvis du er ren og opriktig, da vil han våke over dig og gjenreise din rettferds bolig, 
18:8:7: og din forrige lykke vil bli ringe mot din senere lykke, for den skal være overmåte stor. 
18:8:8: For spør bare fremfarne slekter og akt på det som deres fedre har gransket ut 
18:8:9: - for vi er fra igår og vet intet; for en skygge er våre dager på jorden - 
18:8:10: de skal lære dig og si dig det og bære frem ord fra sitt hjerte. 
18:8:11: Vokser sivet op hvor det ikke er myrlendt? Blir starrgresset stort uten vann? 
18:8:12: Ennu står det friskt og grønt og blir ikke skåret; da visner det før alt annet gress. 
18:8:13: Således går det alle dem som glemmer Gud, og den gudløses håp går til grunne; 
18:8:14: hans tillit avskjæres, og det han trøster sig til, er spindelvev. 
18:8:15: Han støtter sig på sitt hus, men det står ikke; han holder sig fast i det, men det står ikke fast. 
18:8:16: Frodig står han der i solens skinn, og hans skudd breder sig ut over hans have; 
18:8:17: om en stenrøs slynger sig hans røtter, mellem stener trenger han sig frem. 
18:8:18: Ryddes han bort fra sitt sted, så kjennes det ikke ved ham, men sier: Jeg har aldri sett dig. 
18:8:19: Se, det er gleden på hans vei, og av mulden spirer andre frem. 
18:8:20: Nei, Gud forkaster ikke en som er ulastelig, og han holder ikke ugudelige ved hånden. 
18:8:21: Ennu vil han fylle din munn med latter og dine leber med jubel. 
18:8:22: De som hater dig, skal klædes med skam, og de ugudeliges telt skal ikke mere finnes. 
18:9:1: Da tok Job til orde og sa: 
18:9:2: Ja visst, jeg vet at det er så; hvorledes skulde en mann kunne ha rett mot Gud? 
18:9:3: Om han hadde lyst til å gå i rette med Gud, kunde han ikke svare ham ett til tusen. 
18:9:4: Vis som han er av hjerte og veldig i styrke - hvem trosset ham og kom vel fra det, 
18:9:5: han som flytter fjell før de vet av det, som velter dem i sin vrede, 
18:9:6: som ryster jorden, så den viker fra sitt sted, og dens støtter bever, 
18:9:7: som byder solen, så den ikke går op, og som setter segl for stjernene, 
18:9:8: som alene utspenner himmelen og skrider frem over havets høider, 
18:9:9: som har skapt Bjørnen*, Orion* og Syvstjernen* og Sydens stjernekammere, # <* forskjellige stjernebilleder, JBS 38, 31 fg. AMO 5, 8.> 
18:9:10: som gjør store, uransakelige ting og under uten tall? 
18:9:11: Han går forbi mig, og jeg ser ham ikke; han farer forbi, og jeg merker ham ikke. 
18:9:12: Han griper sitt rov - hvem vil hindre ham, hvem vil si til ham: Hvad gjør du? 
18:9:13: Gud holder ikke sin vrede tilbake; under ham måtte Rahabs hjelpere bøie sig. 
18:9:14: Hvorledes skulde da jeg kunne svare ham og velge mine ord imot ham, 
18:9:15: jeg som ikke kunde svare om jeg enn hadde rett, men måtte be min dommer om nåde! 
18:9:16: Om jeg ropte, og han svarte mig, kunde jeg ikke tro at han hørte min røst, 
18:9:17: han som vilde knuse mig i storm og uten årsak ramme mig med sår på sår, 
18:9:18: som ikke vilde tillate mig å dra ånde, men vilde mette mig med lidelser. 
18:9:19: Gjelder det styrke, så sier han: Se, her er jeg! Gjelder det rett: Hvem vil stevne mig? 
18:9:20: Hadde jeg enn rett, skulde dog min egen munn dømme mig skyldig; var jeg enn uskyldig, vilde han dog si at jeg hadde urett. 
18:9:21: Skyldløs er jeg; jeg bryr mig ikke om å leve - jeg forakter mitt liv. 
18:9:22: Det kommer ut på ett; derfor sier jeg: Skyldløs eller ugudelig - han gjør dem begge til intet. 
18:9:23: Når svepen brått rammer med død, spotter han de uskyldiges lidelse. 
18:9:24: Jorden er gitt i den ugudeliges hånd; han tilhyller dens dommeres åsyn*. Er det ikke han som gjør det, hvem er det da? # <* så de ikke kan skjelne rett fra urett.> 
18:9:25: Mine dager har vært hastigere enn en løper; de er bortflyktet uten å ha sett noget godt; 
18:9:26: de har faret avsted som båter av rør, som en ørn som slår ned på sitt bytte. 
18:9:27: Om jeg sier: Jeg vil glemme min sorg, jeg vil la min mørke mine fare og se glad ut, 
18:9:28: da gruer jeg for alle mine plager; jeg vet jo at du ikke frikjenner mig. 
18:9:29: Jeg skal jo være ugudelig - hvorfor gjør jeg mig da forgjeves møie? 
18:9:30: Om jeg tvettet mig med sne og renset mine hender med lut, 
18:9:31: da skulde du dyppe mig i en grøft, så mine klær vemmedes ved mig. 
18:9:32: For han er ikke en mann som jeg, så jeg kunde svare ham, så vi kunde gå sammen for retten; 
18:9:33: det er ikke nogen voldgiftsmann mellem oss, som kunde legge sin hånd på oss begge. 
18:9:34: Når han bare tok sitt ris bort fra mig, og hans redsler ikke skremte mig! 
18:9:35: Da skulde jeg tale uten å reddes for ham; for slik* er jeg ikke, det vet jeg med mig selv. # <* d.e. slik at jeg skulde reddes for ham.> 
18:10:1: Min sjel er lei av mitt liv, jeg vil la min klage ha fritt løp, jeg vil tale i min sjels bitre smerte. 
18:10:2: Jeg vil si til Gud: Fordøm mig ikke, la mig vite hvorfor du strider mot mig! 
18:10:3: Tykkes det dig godt at du undertrykker, at du forkaster det dine hender med omhu har dannet, og lar ditt lys skinne over ugudeliges råd? 
18:10:4: Har du menneskeøine, eller ser du således som et menneske ser? 
18:10:5: Er dine dager som et menneskes dager, eller dine år som en manns dager? - 
18:10:6: siden du søker efter min misgjerning og leter efter min synd, 
18:10:7: enda du vet at jeg ikke er ugudelig, og at det ingen er som redder av din hånd. 
18:10:8: Dine hender har dannet mig og gjort mig, helt og i alle deler, og nu vil du ødelegge mig! 
18:10:9: Kom i hu at du har dannet mig som leret, og nu lar du mig atter vende tilbake til støvet! 
18:10:10: Helte du mig ikke ut som melk og lot mig størkne som ost? 
18:10:11: Med hud og kjøtt klædde du mig, og med ben og sener gjennemvevde du mig. 
18:10:12: Liv og miskunnhet har du gitt mig, og din varetekt har vernet om min ånd. 
18:10:13: Og dette* gjemte du i ditt hjerte, jeg vet at dette hadde du i sinne: # <* det som opregnes JBS 10, 14 fg.> 
18:10:14: Syndet jeg, så vilde du vokte på mig og ikke frikjenne mig for min misgjerning; 
18:10:15: var jeg skyldig, da ve mig, men var jeg uskyldig, skulde jeg dog ikke kunne løfte mitt hode, mett av skam og med min elendighet for øie; 
18:10:16: og hevet det sig dog, så vilde du jage efter mig som en løve, og atter vise dig forunderlig mot mig; 
18:10:17: du vilde føre nye vidner mot mig og øke din harme mot mig, sende alltid nye hærflokker mot mig. 
18:10:18: Hvorfor lot du mig utgå av mors liv? Jeg skulde ha opgitt ånden, og intet øie skulde ha sett mig; 
18:10:19: jeg skulde ha vært som om jeg aldri hadde vært til; fra mors liv skulde jeg ha vært båret til graven. 
18:10:20: Er ikke mine dager få? - Han holde op! Han la mig være, så jeg kan bli litt glad, 
18:10:21: før jeg går bort for ikke å vende tilbake, bort til mørkets og dødsskyggens land, 
18:10:22: et land så mørkt som den sorteste natt, hvor dødsskygge og forvirring råder, og hvor schmelken er som den sorteste natt! 
18:11:1: Da tok Sofar fra Na'ama til orde og sa: 
18:11:2: Skulde en ordflom bli uten svar, eller en ordgyter få rett? 
18:11:3: Skulde dine store ord drive menn til taushet, skulde du spotte uten at nogen skammer dig ut? 
18:11:4: Og skal du få si: Ren er min lære, og skyldfri er jeg i dine øine? 
18:11:5: Men bare Gud vilde tale og oplate sine leber mot dig 
18:11:6: og åpenbare dig visdommens hemmeligheter, at det i dem er dobbelt forstand! Da måtte du nok innse at Gud tilgir dig noget av din misgjerning. 
18:11:7: Mon du kan finne bunn i Guds vesen eller nå frem til den Allmektiges ytterste grense? 
18:11:8: Himmelhøi er den*, hvad kan du gjøre? Dypere enn dødsriket, hvad vet du? # <* d.e. Guds visdom.> 
18:11:9: Lengere enn jorden er dens mål og bredere enn havet. 
18:11:10: Om han farer frem og setter i fengsel og sammenkaller retten, hvem vil da hindre ham? 
18:11:11: For han, han kjenner de falske folk og ser uretten, uten at han trenger å gi akt på den, 
18:11:12: og selv en uvettig mann får forstand*, og et ungt villesel blir født til menneske. # <* når Gud således går i rette med ham, JBS 11, 10.> 
18:11:13: Hvis du retter ditt hjerte og utbreder dine hender til ham - 
18:11:14: er det synd i din hånd, da ha den bort og la ikke urett bo i dine telt - 
18:11:15: ja, da skal du, fri for lyte, opløfte ditt åsyn og stå fast og ikke frykte; 
18:11:16: for du skal glemme din møie, som forbifarne vann skal du komme den i hu. 
18:11:17: Og lysere enn middagen blir da ditt liv; mørket blir for dig som morgenen. 
18:11:18: Og du skal være trygg, for da er det håp, og når du har sett dig vel omkring, kan du legge dig trygt til ro. 
18:11:19: Og du skal hvile, og ingen skal skremme dig op, og mange skal søke din yndest. 
18:11:20: Men de ugudeliges øine tæres bort; de har ingen tilflukt mere, og deres håp er å utånde sjelen. 
18:12:1: Da tok Job til orde og sa: 
18:12:2: Ja sannelig, I er de rette folk, og med eder dør visdommen ut. 
18:12:3: Også jeg har forstand, likesom I, jeg står ikke tilbake for eder, og hvem vet ikke dette? 
18:12:4: Til spott for mine venner er jeg, jeg som ropte til Gud og fikk svar; til spott er jeg, den rettskafne og ulastelige. 
18:12:5: Ulykken fortjener bare forakt efter de trygges mening; forakt venter dem hvis fot vakler. 
18:12:6: Ødeleggeres telt blir i ro, og trygge er de som egger Gud til vrede, de som fører sin gud i sin hånd*. # <* HAB 1, 11.> 
18:12:7: Men spør du dyrene, de skal lære dig, og himmelens fugler, de skal si dig det, 
18:12:8: eller tal til jorden, og den skal lære dig, og havets fisker skal fortelle dig det. 
18:12:9: Hvem skjønner ikke av alt dette at det er Herrens hånd som har skapt det, 
18:12:10: han som har i sin hånd hver levende sjel og hvert menneskelegemes ånd? 
18:12:11: Mon ikke øret prøver ord, likesom ganen smaker mat? 
18:12:12: Hos gråhårede er visdom, og langt liv gir forstand. 
18:12:13: Hos ham er visdom og velde, ham hører råd og forstand til. 
18:12:14: Se, han river ned, og det bygges ikke op igjen; han stenger for en mann, og det lukkes ikke op. 
18:12:15: Han demmer for vannene, og de tørker bort, og han slipper dem løs, og de velter om jorden. 
18:12:16: Hos ham er styrke og visdom; i hans makt er både den som farer vill, og den som fører vill. 
18:12:17: Han fører rådsherrer bort som fanger, og dommere gjør han til dårer. 
18:12:18: Kongers tvangsbånd løser han og binder rep om deres lender. 
18:12:19: Han fører prester bort som fanger, og mektige menn støter han ned. 
18:12:20: Han fratar prøvede menn mælet og oldinger deres innsikt. 
18:12:21: Han utøser forakt over fyrster, og de sterkes belte løser han. 
18:12:22: Han drar det skjulte frem av mørket og fører dødsskygge frem i schmelken. 
18:12:23: Han lar folkene bli store, og han lar dem gå til grunne; han gir folkene vidt rum, og han fører dem bort. 
18:12:24: Høvdingene i landet fratar han forstanden og lar dem fare vill i et uveisomt øde; 
18:12:25: de famler i mørke uten lys, og han lar dem rave likesom drukne. 
18:13:1: Se, alt sammen har mitt øie sett, mitt øre hørt og merket sig. 
18:13:2: Det I vet, vet også jeg; jeg står ikke tilbake for eder. 
18:13:3: Men jeg vil tale til den Allmektige, og jeg har lyst til å rettferdiggjøre mig for Gud. 
18:13:4: Men I spinner løgn sammen, I er alle dårlige læger. 
18:13:5: Gid I vilde tie stille! Det skulde bli regnet eder til visdom. 
18:13:6: Hør nu på min tilrettevisning og merk på refselsene fra mine leber! 
18:13:7: Vil I tale urett til forsvar for Gud, og vil I til hans forsvar tale svik? 
18:13:8: Vil I ta hans parti, eller vil I være sakførere for Gud? 
18:13:9: Vil det gå eder godt når han ransaker eder, eller vil I narre ham, som en narrer et menneske? 
18:13:10: Han vil tukte eder, om I i lønndom tar parti for ham. 
18:13:11: Vil ikke hans høihet forferde eder, og redselen for ham falle over eder? 
18:13:12: Eders tankesprog er askesprog; eders skanser* blir til skanser av ler. # <* d.e. bevisgrunner.> 
18:13:13: Ti, la mig være, så jeg kan tale, så får det komme over mig hvad det vil! 
18:13:14: Hvorfor skulde jeg bære mitt kjøtt mellem mine tenner*? Jeg vil legge mitt liv i min hånd**. # <* søke å redde mitt liv.> # <** sette det på spill.> 
18:13:15: Se, han vil drepe mig - jeg venter på ham; jeg vil bare rettferdiggjøre mine veier for hans åsyn. 
18:13:16: Også det skal bli mig til frelse; for ingen gudløs kommer for hans åsyn. 
18:13:17: Hør da nøie på mitt ord og la min forklaring trenge inn i eders ører! 
18:13:18: Se, jeg har saken i orden; jeg vet jeg skal få rett. 
18:13:19: Hvem er den som vil gå i rette med mig? Ja, da vil jeg tie og opgi ånden. 
18:13:20: Bare to ting må du ikke gjøre mot mig, da skal jeg ikke skjule mig for ditt åsyn: 
18:13:21: Ta din hånd bort fra mig, og la ikke dine redsler forferde mig! 
18:13:22: Så kall da, og jeg skal svare; eller la mig tale, og svar du mig! 
18:13:23: Hvor mange misgjerninger og synder har jeg? La mig få vite min brøde og min synd! 
18:13:24: Hvorfor skjuler du ditt åsyn og holder mig for din fiende? 
18:13:25: Vil du skremme et bortblåst blad og forfølge det tørre strå? - 
18:13:26: siden du idømmer mig så hårde lidelser og lar mig arve min ungdoms misgjerninger 
18:13:27: og setter mine føtter i stokken og vokter på alle mine veier og drar en ring om mine fotsåler, 
18:13:28: og dette gjør du mot en som tæres bort som makk-ett tre, som et klædebon møllet har ett. 
18:14:1: Et menneske, født av en kvinne, lever en kort tid og mettes med uro. 
18:14:2: Som en blomst skyter han op og visner, han farer bort som skyggen og holder ikke stand. 
18:14:3: Endog over en sådan holder du dine øine åpne, og mig fører du frem for din domstol! 
18:14:4: Kunde det bare komme en ren av en uren! Ikke én! 
18:14:5: Når hans dager er fastsatt, hans måneders tall bestemt hos dig, når du har satt ham en grense som han ikke kan overskride, 
18:14:6: så vend ditt øie bort fra ham, så han kan ha ro så vidt at han kan glede sig som en dagarbeider ved sin dag! 
18:14:7: For treet er det håp; om det hugges, så spirer det igjen, og på nye skudd mangler det ikke; 
18:14:8: om dets rot eldes i jorden, og dets stubb dør ut i mulden, 
18:14:9: så setter det allikevel knopper ved eimen av vannet og skyter grener som et nyplantet tre. 
18:14:10: Men når en mann dør, så ligger han der, når et menneske opgir ånden, hvor er han da? 
18:14:11: Som vannet minker bort i en sjø, og som en elv efterhånden blir grunnere og tørker ut, 
18:14:12: så legger et menneske sig ned og reiser sig ikke igjen; så lenge himmelen er til, våkner de ikke - de vekkes ikke op av sin søvn. 
18:14:13: Å om du vilde gjemme mig i dødsriket og skjule mig der til din vrede var over - om du vilde sette mig et tidsmål og så komme mig i hu! 
18:14:14: Når en mann dør, lever han da op igjen? Alle min krigstjenestes dager skulde jeg da vente, til min avløsning kom; 
18:14:15: du skulde da rope, og jeg skulde svare dig; efter dine henders verk skulde du lenges. 
18:14:16: Men nu teller du mine skritt og akter stadig på min synd. 
18:14:17: Forseglet i en pung ligger min brøde, og du syr til over min misgjerning. 
18:14:18: Men som et fjell faller og smuldres bort, og en klippe flyttes fra sitt sted, 
18:14:19: som vannet huler ut stener og flommen skyller bort mulden, således gjør du menneskets håp til intet; 
18:14:20: du overvelder ham for alltid, og han farer bort; du forvender hans åsyn og lar ham fare. 
18:14:21: Kommer hans barn til ære, da vet han det ikke, og blir de ringeaktet, da blir han det ikke var. 
18:14:22: Bare over ham selv kjenner hans legeme smerte, og bare over ham selv sørger hans sjel. 
18:15:1: Da tok Elifas fra Teman til orde og sa: 
18:15:2: Svarer vel en vis mann med en kunnskap som bare er vind, og fyller han sitt indre med stormvær? 
18:15:3: Vil han vel forsvare sin sak med ord som ikke nytter, og med tale hvormed han intet utretter? 
18:15:4: Du nedbryter endog gudsfrykten og svekker andakten for Guds åsyn; 
18:15:5: for din synd legger ordene i din munn, og du velger falske menns tale. 
18:15:6: Din egen munn domfeller dig, ikke jeg; dine leber vidner mot dig. 
18:15:7: Blev du født først av alle mennesker, eller kom du til verden før alle haugene var til? 
18:15:8: Har du vært tilhører i Guds lønnlige råd og der tilranet dig visdom? 
18:15:9: Hvad vet du som vi ikke vet? Hvad forstår du som er ukjent for oss? 
18:15:10: Det er blandt oss en som er både gammel og gråhåret, rikere på dager enn din far. 
18:15:11: Er Guds trøsteord for lite for dig, og et ord som er talt i saktmodighet til dig? 
18:15:12: Hvorfor lar du dig rive med av ditt hjerte, og hvorfor gnistrer dine øine? - 
18:15:13: siden du vender din vrede mot Gud og lar ordene strømme fra din munn. 
18:15:14: Hvad er et menneske, at han skulde være ren, og en som er født av en kvinne, at han skulde være rettferdig? 
18:15:15: Endog på sine hellige stoler han ikke, og himlene er ikke rene i hans øine, 
18:15:16: langt mindre da en vederstyggelig, en fordervet, en mann som drikker urett som vann*. # <* d.e. er like så begjærlig efter å gjøre urett som den tørstige er efter vann.> 
18:15:17: Jeg vil kunngjøre dig noget, hør på mig! Hvad jeg har sett, det vil jeg fortelle, 
18:15:18: det som vise menn forkynner og ikke har dulgt, det som de mottok fra sine fedre, 
18:15:19: til hvem landet alene var gitt, og blandt hvem ingen fremmed hadde draget igjennem. 
18:15:20: En ugudelig lever i angst alle sine dager, og få i tall er de år som er gjemt for voldsmannen. 
18:15:21: Redselstoner lyder i hans ører; midt i freden kommer ødeleggeren over ham. 
18:15:22: Han tror ikke han skal komme tilbake fra mørket, og han er utsett til å falle for sverdet. 
18:15:23: Han flakker om efter brød og spør: Hvor er det å finne? Han vet at en mørkets dag står ferdig ved hans side . 
18:15:24: Nød og trengsel forferder ham; den overvelder ham, lik en stridsrustet konge, 
18:15:25: fordi han rakte ut sin hånd mot Gud og våget å trosse den Allmektige, 
18:15:26: stormet frem mot ham med opreist nakke, med sine skjolds tette tak, 
18:15:27: fordi han dekket sitt ansikt med sin fedme og la fett på sin lend 
18:15:28: og bodde i ødelagte byer, i hus hvor ingen skulde bo, og som var bestemt til å bli grusdynger. 
18:15:29: Han blir ikke rik, og hans gods varer ikke ved, og hans grøde luter ikke mot jorden. 
18:15:30: Han slipper ikke ut av mørket; ildslue skal tørke hans kvister, og han skal komme bort ved hans* munns ånde. # <* Guds. JBS 4, 9. JES 11, 4.> 
18:15:31: Ei sette han sin lit til det som forgjengelig er! Da narrer han sig selv, for bare forgjengelighet blir hans vederlag. 
18:15:32: Før hans dag kommer, blir det opfylt, og hans gren grønnes ikke. 
18:15:33: Han blir som et vintre som mister sine druer før de er modne, og som et oljetre som feller sine blomster; 
18:15:34: for den gudløses hus er ufruktbart, og ild fortærer deres telter som lar sig underkjøpe. 
18:15:35: De undfanger ulykke og føder nød, og deres morsliv fostrer svik. 
18:16:1: Da tok Job til orde og sa: 
18:16:2: Jeg har hørt meget som dette; I er plagsomme trøstere alle sammen. 
18:16:3: Blir det aldri ende på de vindige ord? Eller hvad egger dig til å svare? 
18:16:4: Også jeg kunde tale som I; om I var i mitt sted, kunde jeg sette ord sammen mot eder, og jeg kunde ryste på hodet over eder; 
18:16:5: jeg kunde styrke eder med min munn*, og mine lebers medynk kunde stille eders smerte. # <* d.e. med tomme ord.> 
18:16:6: Om jeg taler, stilles ikke min smerte, og lar jeg det være, hvad lindring får jeg da? 
18:16:7: Ja, nu har han trettet mig ut; du har ødelagt hele mitt hus. 
18:16:8: Og du grep mig fatt - det blev et vidne mot mig; min magerhet stod op imot mig, like i mitt åsyn vidnet den mot mig. 
18:16:9: Hans vrede sønderrev mig og forfulgte mig; han skar tenner imot mig; som min motstander hvesset han sine øine mot mig. 
18:16:10: De* spilet op sin munn mot mig, med hån slo de mine kinnben; alle slo de sig sammen mot mig. # <* mine fiender, JBS 16, 11.> 
18:16:11: Gud gir mig i urettferdige folks vold og styrter mig i ugudelige menneskers hender. 
18:16:12: Jeg levde i ro; da sønderbrøt han mig, han grep mig i nakken og sønderknuste mig, han satte mig op til skive for sig. 
18:16:13: Hans skyttere kringsatte mig, han kløvde mine nyrer uten barmhjertighet; han øste ut min galle på jorden. 
18:16:14: Han rev i mig rift på rift; han stormet mot mig som en kjempe. 
18:16:15: Jeg har sydd sekk om min hud og stukket mitt horn i støvet*; # <* d.e. opgitt min makt og høihet.> 
18:16:16: mitt ansikt er rødt av gråt, og over mine øielokk ligger det dødsskygge. 
18:16:17: Og dog er det ingen urett i mine hender, og min bønn er ren. 
18:16:18: Å jord, dekk ikke mitt blod*, og måtte det ikke være noget sted hvor mitt skrik stanser! # <* JES 26, 21. 1MO 4, 10.> 
18:16:19: Selv nu har jeg mitt vidne i himmelen og i det høie en som kan stadfeste mine ord. 
18:16:20: Stadig spotter mine venner mig; mot Gud skuer gråtende mitt øie, 
18:16:21: at han må la mannen få rett i hans strid med Gud og menneskebarnet rett mot hans næste; 
18:16:22: for få år vil det gå før jeg vandrer den vei som jeg ikke vender tilbake. 
18:17:1: Min ånd* er brutt, mine dager utslukket; bare graver har jeg for mig. <* livskraft.> 
18:17:2: Sannelig, spott omgir mig på alle kanter, og mitt øie må dvele ved deres trettekjære ferd. 
18:17:3: Så sett nu et pant, gå i borgen for mig hos dig selv! Hvem skulde ellers gi mig håndslag*? # <* d.e. gå i borgen for mig, OSP 6, 1; 11, 15.> 
18:17:4: Du har jo lukket deres hjerte for innsikt; derfor vil du ikke la dem vinne. 
18:17:5: Den som forråder venner, så de blir til bytte*, hans barns øine skal tæres bort. # <* for sine forfølgere.> 
18:17:6: Jeg er satt til et ordsprog for folk; jeg er en mann som blir spyttet i ansiktet. 
18:17:7: Mitt øie er sløvt av gremmelse, og alle mine lemmer er som en skygge. 
18:17:8: Rettskafne forferdes over dette, og den skyldfrie harmes over den gudløse; 
18:17:9: men den rettferdige holder fast ved sin vei, og den som har rene hender, får enn mere kraft. 
18:17:10: Men I - kom bare igjen alle sammen! Jeg finner dog ikke nogen vismann blandt eder. 
18:17:11: Mine dager er faret forbi, mine planer sønderrevet - mitt hjertes eiendom! 
18:17:12: Natt gjør de til dag, schmelken, sier de, er nærmere enn det mørke som ligger like for mig. 
18:17:13: Når jeg håper på dødsriket som mitt hus, reder i mørket mitt leie, 
18:17:14: roper til graven: Du er min far, til makken: Du er min mor og min søster, 
18:17:15: hvor er da mitt håp? Mitt håp - hvem øiner det? 
18:17:16: Til dødsrikets bommer farer de* ned, på samme tid som jeg går til hvile i støvet. # <* mine forhåpninger.> 
18:18:1: Da tok Bildad fra Suah til orde og sa: 
18:18:2: Når vil I dog engang sette en grense for eders ord? Bli først forstandige, så kan vi tale sammen. 
18:18:3: Hvorfor er vi aktet som fe? Hvorfor er vi urene i eders øine? 
18:18:4: Å du som sønderriver dig selv din vrede! Mon jorden for din skyld skal lates øde, og en klippe rokkes fra sitt sted? 
18:18:5: Like fullt skal den ugudeliges schmelk utslukkes, og hans ilds lue skal ikke skinne. 
18:18:6: Schmelken skal formørkes i hans telt og hans lampe utslukkes over ham. 
18:18:7: Hans kraftige skritt skal bli innsnevret, og hans eget råd styrte ham; 
18:18:8: for han kommer inn i et garn med sine føtter, og han vandrer på et nett. 
18:18:9: En snare griper om hans hæl, et rep tar fatt i ham. 
18:18:10: Skjult i jorden er det garn han fanges i, og fellen ligger på hans vei. 
18:18:11: Redsler forferder ham rundt om og jager ham hvor han setter sin fot. 
18:18:12: Av sult blir hans kraft fortært, og ulykke står ferdig ved hans side. 
18:18:13: Hans hud fortæres stykke for stykke, dødens førstefødte* fortærer hans lemmer. # <* d.e. sykdom som volder døden.> 
18:18:14: Han rives bort fra sitt telt, som han setter sin lit til, og du lar ham dra avsted til redslenes konge. 
18:18:15: Folk som ikke hører ham til, bor i hans telt; det strøes svovel over hans bosted. 
18:18:16: Nedentil tørkes hans røtter bort, og oventil visner hans grener. 
18:18:17: Hans minne er blitt borte i landet, og hans navn nevnes ikke mere ute på marken. 
18:18:18: Han støtes fra schmelk ut i mørke, han jages bort fra jorderike. 
18:18:19: Han har ikke barn og ikke efterkommere blandt sitt folk, og det finnes ingen i hans boliger som har sloppet unda. 
18:18:20: Over hans dag* forferdes de som bor i Vesten, og de som bor i Østen, gripes av redsel. # <* d.e. hans undergangs dag.> 
18:18:21: Just således går det med den urettferdiges boliger, og således med hjemmet til den som ikke kjenner Gud. 
18:19:1: Da tok Job til orde og sa: 
18:19:2: Hvor lenge vil I bedrøve min sjel og knuse mig med ord? 
18:19:3: Det er nu tiende gang I håner mig og ikke skammer eder ved å krenke mig. 
18:19:4: Har jeg virkelig faret vill, da blir min villfarelse min egen sak. 
18:19:5: Vil I virkelig ophøie eder over mig og vise mig at min vanære har rammet mig med rette? 
18:19:6: Så vit da at Gud har gjort mig urett og satt sitt garn omkring mig! 
18:19:7: Se, jeg roper: Vold! - men jeg får intet svar; jeg skriker om hjelp, men det er ingen rett å få. 
18:19:8: Min vei har han stengt, så jeg ikke kommer frem, og over mine stier legger han mørke. 
18:19:9: Min ære har han avklædd mig og tatt bort kronen fra mitt hode. 
18:19:10: Han bryter mig ned på alle kanter, så jeg går til grunne, og han rykker op mitt håp som et tre. 
18:19:11: Han lar sin vrede brenne mot mig og akter mig som sin fiende. 
18:19:12: Hans hærflokker kommer alle sammen og rydder sig vei mot mig, og de leirer sig rundt om mitt telt. 
18:19:13: Mine brødre har han drevet langt bort fra mig, og mine kjenninger er blitt aldeles fremmede for mig. 
18:19:14: Mine nærmeste holder sig borte, og mine kjente har glemt mig. 
18:19:15: Mine husfolk og mine tjenestepiker akter mig for en fremmed; jeg er en utlending i deres øine. 
18:19:16: Kaller jeg på min tjener, så svarer han ikke; med egen munn må jeg bønnfalle ham. 
18:19:17: Min ånde er motbydelig for min hustru, og min vonde lukt for min mors sønner. 
18:19:18: Endog barn forakter mig; vil jeg reise mig, så taler de mot mig. 
18:19:19: Alle mine nærmeste venner avskyr mig, og de jeg elsket, har vendt sig mot mig. 
18:19:20: Mine ben trenger ut gjennem min hud og mitt kjøtt, og bare tannhinnen er ennu urørt på mig. 
18:19:21: Forbarm eder, forbarm eder over mig, I mine venner! For Guds hånd har rørt ved mig. 
18:19:22: Hvorfor forfølger I mig likesom Gud og blir ikke mette av mitt kjøtt? 
18:19:23: Men gid mine ord måtte bli opskrevet! Gid de måtte bli optegnet i en bok, 
18:19:24: ja, med jerngriffel og bly for evig bli hugget inn i sten! 
18:19:25: Men jeg - jeg vet min gjenløser lever, og som den siste skal han stå frem på støvet. 
18:19:26: Og efterat denne min hud er blitt ødelagt, skal jeg ut fra mitt kjød skue Gud, 
18:19:27: han som jeg skal skue, mig til gode, han som mine øine skal se og ikke nogen fremmed - mine nyrer tæres bort i mitt liv*. # <* av lengsel herefter, 2KO 5, 2.> 
18:19:28: Når I sier: Hvor vi skal forfølge ham! - I har jo funnet skylden hos mig - 
18:19:29: så frykt for sverdet! For vrede er en synd som er hjemfalt til sverd. Dette sier jeg forat I skal tenke på at det kommer en dom. 
18:20:1: Da tok Sofar fra Na'ama til orde og sa: 
18:20:2: Derfor legger mine tanker mig svaret i munnen, og derfor stormer det i mig; 
18:20:3: hånende tilrettevisning må jeg høre, og min ånd gir mig svar ut fra min innsikt. 
18:20:4: Vet du da ikke at slik har det vært fra evighet, fra den tid mennesker blev satt på jorden, 
18:20:5: at de ugudeliges jubel er kort, og den gudløses glede bare varer et øieblikk? 
18:20:6: Stiger enn hans stolthet til himmelen, og når enn hans hode til skyen, 
18:20:7: så går han dog likesom sitt skarn til grunne for evig; de som så ham, spør: Hvor er han? 
18:20:8: Som en drøm flyr han bort, og ingen finner ham mere; han jages bort som et nattesyn. 
18:20:9: Det øie som så ham, ser ham ikke mere, og hans sted skuer ham ikke lenger. 
18:20:10: Hans barn må søke småfolks yndest, og hans hender må gi hans gods tilbake. 
18:20:11: Hans ben var fulle av ungdomskraft, men nu ligger den med ham i støvet. 
18:20:12: Smaker enn det onde søtt i hans munn, skjuler han det under sin tunge, 
18:20:13: sparer han på det og slipper det ikke, men holder det tilbake under sin gane, 
18:20:14: så blir dog hans mat omskapt i hans innvoller og blir til ormegift i hans liv. 
18:20:15: Han slukte gods, og han må spy det ut igjen; Gud driver det ut av hans buk. 
18:20:16: Ormegift må han innsuge; huggormens tunge dreper ham. 
18:20:17: Han skal ikke få se bekker, elver av honning og elver av melk. 
18:20:18: Han må gi tilbake det han har tjent, og får ikke nyte det; meget gods har han vunnet, men han får liten glede av det. 
18:20:19: For han knuste småfolk og lot dem ligge der; han rante hus til sig, men får ikke bygge dem om; 
18:20:20: han kjente aldri ro i sitt indre; han skal ikke slippe unda med sine skatter. 
18:20:21: Det var intet som undgikk hans grådighet; derfor varer ikke hans lykke. 
18:20:22: Midt i hans rikdom blir det trangt for ham; hver nødlidende vender sin hånd mot ham. 
18:20:23: For å fylle hans buk sender Gud sin brennende vrede mot ham og lar sin mat regne på ham. 
18:20:24: Flykter han for våben av jern, så gjennemborer en bue av kobber ham. 
18:20:25: Når han så drar pilen ut av sin rygg, og den lynende odd kommer frem av hans galle, da faller dødsredsler over ham. 
18:20:26: Alt mørke er opspart for hans vel gjemte skatter; en ild som intet menneske puster til, fortærer ham; den eter det som er igjen i hans telt. 
18:20:27: Himmelen åpenbarer hans misgjerning, og jorden reiser sig mot ham. 
18:20:28: Det han har samlet i sitt hus, føres bort, det skylles bort på Guds vredes dag. 
18:20:29: Dette er den lodd som et ugudelig menneske får av Gud, den arv som er tilkjent ham av den Allmektige. 
18:21:1: Da tok Job til orde og sa: 
18:21:2: Hør aktsomt på mitt ord og la dette være den trøst I yder mig! 
18:21:3: Tål mig, så jeg kan få tale, og når jeg har talt, kan du spotte. 
18:21:4: Mon min klage gjelder et menneske? Eller hvorfor skulde min ånd ikke bli utålmodig? 
18:21:5: Vend eder til mig og bli forferdet og legg hånd på munn! 
18:21:6: Kommer jeg det i hu, så forferdes jeg, og mitt kjød gripes av skjelving. 
18:21:7: Hvorfor blir de ugudelige i live, blir gamle og tiltar endog i velmakt? 
18:21:8: De ser sine barn trives omkring sig, og sine efterkommere har de for sine øine. 
18:21:9: Deres hus er sikre mot redsler, og Guds ris kommer ikke over dem. 
18:21:10: Hans okse parrer sig og spiller ikke, hans ku kalver og kaster ikke i utide. 
18:21:11: De slipper sine barn ut som småfeet, og deres smågutter hopper omkring. 
18:21:12: De synger til tromme og citar, og de gleder sig ved fløitens lyd. 
18:21:13: De lever sine dager i lykke, og i et øieblikk farer de ned til dødsriket. 
18:21:14: Og dog sa de til Gud: Vik fra oss! Vi har ikke lyst til å kjenne dine veier. 
18:21:15: Hvad er den Allmektige, at vi skulde tjene ham, og hvad gagn skulde vi ha av å vende oss til ham med bønn? 
18:21:16: Ja*, men deres lykke står ikke i deres egen hånd. - **De ugudeliges tanker er langt fra mine tanker. # <* vil I si.> # <** Her begynner Jobs svar.> 
18:21:17: Hvor ofte utslukkes vel de ugudeliges lampe, og hvor ofte hender det at ulykke kommer over dem? Hvor ofte tildeler han dem vel smerter i sin vrede? 
18:21:18: Hvor ofte blir de vel som strå for vinden, som agner stormen fører bort? 
18:21:19: Men Gud* gjemmer hans straff til hans barn. - **Ja, men han burde straffe ham selv, så han fikk kjenne det. # <* vil I si.> # <** Dette er Jobs svar.> 
18:21:20: Med egne øine burde han få se sin undergang, og av den Allmektiges vrede burde han få drikke selv. 
18:21:21: For hvad bryr han sig om sitt hus efter sin død, når hans måneders tall er ute? 
18:21:22: Vil nogen lære Gud visdom, han som dømmer de høieste*? # <* d.e. englene.> 
18:21:23: Den ene dør midt i sin velmakt, helt trygg og rolig; 
18:21:24: hans kar var fulle av melk, og margen i hans ben var saftfull. 
18:21:25: Den andre dør med sorg i hjertet og har aldri nytt nogen lykke. 
18:21:26: Begge ligger de i støvet, og makk dekker dem. 
18:21:27: Se, jeg kjenner eders tanker og de onde råd hvormed I gjør urett mot mig; 
18:21:28: for I sier: Hvor er tyrannens hus, og hvor er det telt de ugudelige bor i? 
18:21:29: Har I aldri spurt dem som har faret vidt omkring? Og I vil vel ikke forkaste deres vidnesbyrd, 
18:21:30: at den onde spares på ulykkens dag, på vredens dag føres han unda. 
18:21:31: Hvem foreholder ham hans ferd like i hans ansikt? og når han gjør noget, hvem gjengjelder ham det? 
18:21:32: Til graven bæres han med ære, og over gravhaugen holder de vakt. 
18:21:33: Søt er hans hvile i dalens muld, og alle mennesker vandrer i hans spor, og det er ikke tall på dem som har gått foran ham. 
18:21:34: Hvor kan I da trøste mig med så tom en trøst? Av eders svar blir det bare troløshet tilbake. 
18:22:1: Da tok Elifas fra Teman til orde og sa: 
18:22:2: Kan vel en mann være til gagn for Gud? Nei, bare sig selv gagner den forstandige. 
18:22:3: Er det til nogen nytte for den Allmektige at du er rettferdig, eller til nogen vinning at du vandrer ulastelig? 
18:22:4: Er det for din gudsfrykts skyld han refser dig eller går i rette med dig? 
18:22:5: Er ikke din ondskap stor og dine misgjerninger uten ende? 
18:22:6: Du tok jo pant av dine brødre uten grunn og drog klærne av de nakne. 
18:22:7: Du gav ikke den trette vann å drikke, og den sultne nektet du brød. 
18:22:8: Men den som gikk frem med vold, han fikk landet i eie, og den som var høit aktet, bodde i det. 
18:22:9: Enker har du latt fare tomhendt, og farløses armer blev knust. 
18:22:10: Derfor er det snarer rundt omkring dig, og en hastig redsel forferder dig. 
18:22:11: Eller ser du ikke mørket og den vannflom som dekker dig? 
18:22:12: Er ikke Gud høi som himmelen? Og se de øverste stjerner, hvor høit de står! 
18:22:13: Og du sier: Hvad vet Gud? Kan han vel dømme gjennem mørket? 
18:22:14: Skyene er et dekke for ham, så han ikke ser noget, og på himmelens hvelving vandrer han. 
18:22:15: Vil du følge den sti som syndens menn vandret på i de gamle dager, 
18:22:16: de som blev bortrykket før tiden, og under hvis føtter grunnen fløt bort som en strøm, 
18:22:17: de menn som sa til Gud: Vik fra oss, og som spurte hvad den Allmektige vel skulde kunne gjøre for dem, 
18:22:18: enda han hadde fylt deres hus med det som var godt? - Men de ugudeliges tanker er lang fra mine tanker. - 
18:22:19: De rettferdige så det* og gledet sig, og de uskyldige spottet dem: # <* JBS 22, 16.> 
18:22:20: Sannelig, våre fiender er tilintetgjort, og ild har fortært deres overflod. 
18:22:21: Forlik dig nu med ham, så vil du få fred! Og så skal lykke times dig. 
18:22:22: Ta imot lærdom av hans munn og legg dig hans ord på hjerte! 
18:22:23: Vender du om til den Allmektige, da skal din lykke bli bygget op igjen; men du må få urett bort fra dine telt. 
18:22:24: Kast ditt gull i støvet og ditt Ofir-gull blandt bekkenes stener! 
18:22:25: Så skal den Allmektige være ditt gull, være som dynger av sølv for dig, 
18:22:26: for da skal du glede dig i den Allmektige og løfte ditt åsyn til Gud. 
18:22:27: Du skal bede til ham, og han skal høre dig, og du skal opfylle dine løfter, 
18:22:28: og setter du dig noget fore, da skal det lykkes for dig, og over dine veier skal det skinne lys; 
18:22:29: når de fører nedover, skal du si: Opover! Han skal frelse den som slår sitt øie ned; 
18:22:30: han skal redde endog den som ikke er uskyldig; ved dine henders renhet skal han bli reddet*. # <* når du beder for ham. 1TI 2, 8. JAK 5 16.> 
18:23:1: Da tok Job til orde og sa: 
18:23:2: Ennu idag gjelder min klage for å være gjenstridighet; min hånd hviler dog tungt på mitt sukk*. # <* d.e. jeg søker dog å dempe min klage.> 
18:23:3: Bare jeg visste å finne ham og kunde komme frem til hans trone! 
18:23:4: Jeg skulde legge min sak frem for hans åsyn og fylle min munn med beviser. 
18:23:5: Jeg skulde få vite de ord han vilde svare mig, og merke mig hvad han vilde si til mig. 
18:23:6: Skulde han da med full kraft stride mot mig? Mon ikke just han skulde akte på mine ord? 
18:23:7: Da skulde en rettskaffen mann gå i rette med ham, og jeg skulde slippe fra min dommer for all tid. 
18:23:8: Men går jeg mot øst, så er han ikke der; går jeg mot vest, så blir jeg ikke var ham; 
18:23:9: er han virksom i nord, så ser jeg ham ikke; går han mot syd, så øiner jeg ham ikke. 
18:23:10: For han kjenner den vei jeg holder mig til; prøvde han mig, så skulde jeg gå frem av prøven som gullet. 
18:23:11: Min fot holdt sig i hans spor; jeg fulgte hans vei og bøide ikke av. 
18:23:12: Fra hans lebers bud vek jeg ikke; fremfor min egen lov* aktet jeg på hans munns ord. # <* RMR 7, 23.> 
18:23:13: Men han er den eneste, og hvem hindrer ham? Hvad hans sjel lyster, det gjør han. 
18:23:14: For han fullbyrder det han har fastsatt for mig, og av sådant er det meget hos ham. 
18:23:15: Derfor reddes jeg for ham; tenker jeg på det*, så bever jeg for ham. # <* JBS 23, 14.> 
18:23:16: Og Gud har knekket mitt mot, og den Allmektige har forferdet mig, 
18:23:17: fordi jeg ikke blev rykket bort før mørket kom, og fordi han ikke skjulte ulykkens natt for mig. 
18:24:1: Hvorfor lar den Allmektige aldri sine straffetider komme? Og hvorfor får de som kjenner ham, ikke se hans dager? 
18:24:2: Folk flytter grenseskjell; de raner fe og fører det på beite. 
18:24:3: Farløses asen driver de bort; enkens okse tar de i pant. 
18:24:4: Fattigfolk trenger de ut av veien; alle de saktmodige i landet må skjule sig. 
18:24:5: Ja, som villesler i ørkenen går de ut til sin gjerning og leter efter føde; ødemarken gir dem brød til barna. 
18:24:6: På marken høster de den ugudeliges fôr, og i hans vingård holder de efterhøst. 
18:24:7: Nakne overnatter de uten klær og uten dekke i kulden. 
18:24:8: Av skyllregnet på fjellet blir de våte, og fordi de ikke har noget annet ly, trykker de sig inn til berget. 
18:24:9: Den farløse rives bort fra mors bryst, og armingens klær blir tatt som pant. 
18:24:10: Nakne går de, uten klær, og sultne bærer de kornbånd. 
18:24:11: Mellem de ugudeliges murer perser de olje; de treder vinpersene og tørster. 
18:24:12: Fra byen lyder døendes stønn, og de hårdt sårede skriker om hjelp; men Gud enser ikke slik urett. 
18:24:13: Andre er fiender av schmelken; de kjenner ikke dets veier og holder sig ikke på dets stier. 
18:24:14: Før dag står morderen op, slår ihjel den som er arm og fattig, og om natten er han som tyven. 
18:24:15: Horkarlens øie speider efter skumringen; han sier: Intet øie ser mig, og han dekker sitt ansikt til. 
18:24:16: I mørket bryter de inn i husene, om dagen lukker de sig inne; schmelken vil de ikke vite av. 
18:24:17: For nattens mørke er morgen for dem alle; de er velkjente med nattemørkets redsler. 
18:24:18: Hastig* rives de med av strømmen; forbannet blir deres arvedel i landet; de ferdes ikke mere på veien til vingårdene. # <* mener I.> 
18:24:19: Tørke og hete sluker snevann, dødsriket dem som synder. 
18:24:20: Hans mors liv glemmer ham, makken fortærer ham med lyst, ingen minnes ham mere, og ondskapen blir som et splintret tre. 
18:24:21: Slik går det med den som har plyndret den ufruktbare, som ikke fødte, og aldri har gjort godt mot enker. 
18:24:22: Men* Gud opholder voldsmennene lenge med sin kraft; de reiser sig igjen, skjønt de mistvilte om livet. # <* Dette er Jobs svar.> 
18:24:23: Han lar dem leve i trygghet og støtter dem; hans øine våker over deres veier. 
18:24:24: De stiger høit; en liten stund, så er de ikke mere; de segner og dør som alle andre, og som aks-toppen skjæres de av. 
18:24:25: Og er det nu ikke så, hvem gjør mig da til løgner og mitt ord til intet? 
18:25:1: Da tok Bildad fra Suah til orde og sa: 
18:25:2: Hos ham er herskermakt og redsel; han skaper fred i sine høie himler. 
18:25:3: Er det tall på hans skarer? Og hvem overstråles ikke av hans schmelk? 
18:25:4: Hvorledes skulde da et menneske være rettferdig for Gud eller en som er født av en kvinne, være ren? 
18:25:5: Selv månen skinner ikke klart, og stjernene er ikke rene i hans øine, 
18:25:6: hvor meget mindre da mennesket, den makk, menneskebarnet, det kryp som det er. 
18:26:1: Da tok Job til orde og sa: 
18:26:2: Hvor du har hjulpet den avmektige, støttet den kraftløse arm! 
18:26:3: Hvor du har gitt den uvise råd, og hvilket overmål av visdom du har lagt for dagen! 
18:26:4: Hvem har du fremført dine ord for, og hvis ånd har talt gjennem dig? 
18:26:5: Dødsrikets skygger skjelver, vannenes dyp og de som bor i dem. 
18:26:6: Dødsriket ligger åpent for ham og avgrunnen uten dekke. 
18:26:7: Han breder Norden ut over det øde rum, han henger jorden på intet. 
18:26:8: Han binder vannene sammen i sine skyer, og skyene brister ikke under dem. 
18:26:9: Han lukker for sin trone, breder sine skyer over den. 
18:26:10: En grense har han dradd i en ring over vannene, der hvor schmelken grenser til mørket. 
18:26:11: Himmelens støtter skjelver, og de forferdes for hans trusel. 
18:26:12: Ved sin kraft oprører han havet, og ved sin forstand knuser han Rahab. 
18:26:13: Ved hans ånde blir himmelen klar; hans hånd gjennemborer den lettfarende drage. 
18:26:14: Se, dette er bare utkantene av hans verk; hvor svak er lyden av det ord vi hører! Men hans veldes torden - hvem forstår den? 
18:27:1: Og Job blev ved å fremføre sin visdomstale og sa: 
18:27:2: Så sant Gud lever, som har tatt min rett fra mig, den Allmektige, som har voldt mig bitter sorg 
18:27:3: - for ennu er hele mitt livspust i mig og den Allmektiges ånde i min nese - : 
18:27:4: Mine leber taler ikke urett, og min tunge taler ikke svik. 
18:27:5: Det være langt fra mig å gi eder rett! Inntil jeg opgir ånden, lar jeg ikke min brødefrihet tas fra mig. 
18:27:6: Jeg holder fast på min rettferdighet og slipper den ikke; mitt hjerte laster mig ikke for nogen av mine dager. 
18:27:7: La min fiende stå der som en ugudelig, og min motstander som en urettferdig! 
18:27:8: For hvad håp har den gudløse, når Gud avskjærer hans liv, når han tar hans sjel fra ham? 
18:27:9: Hører vel Gud hans skrik når trengsel kommer over ham? 
18:27:10: Eller kan han glede sig i den Allmektige, kan han påkalle Gud til enhver tid? 
18:27:11: Jeg vil lære eder om Guds hånd; jeg vil ikke dølge hvad den Allmektige har i sinne. 
18:27:12: I har jo alle selv sett det; hvorfor fører I da så tom en tale? 
18:27:13: Dette er det ugudelige menneskes lodd hos Gud og den arv som voldsmennene får av den Allmektige: 
18:27:14: Får han mange barn, så er de hjemfalt til sverdet; hans ætlinger får ikke brød å mette sig med. 
18:27:15: De av dem som slipper unda, legges i graven ved pest, og enkene holder ikke sørgefest over dem. 
18:27:16: Når han dynger op sølv som støv og samler sig klær som lere, 
18:27:17: så blir det de rettferdige som klær sig med det han har samlet, og sølvet skal de skyldfrie dele. 
18:27:18: Som møllet har han bygget sitt hus og som den hytte en markvokter lager sig. 
18:27:19: Rik legger han sig, og intet er tatt bort; han slår sine øine op, og det* er der ikke. # <* det han eide.> 
18:27:20: Som en vannflom innhenter redsler ham, om natten fører en storm ham bort. 
18:27:21: Østenvinden løfter ham op, så han farer avsted, og den blåser ham bort fra hans sted. 
18:27:22: Gud skyter sine piler mot ham og sparer ham ikke; for hans hånd flyr han i hast. 
18:27:23: Folk klapper i hendene og håner ham og piper ham bort fra hans sted. 
18:28:1: Sølvet har jo sin grube, og gullet, som renses, sitt finnested; 
18:28:2: jern hentes frem av jorden, og sten smeltes til kobber. 
18:28:3: De gjør ende på mørket, og inn i de innerste kroker gransker de stener som ligger i det sorte mørke; 
18:28:4: de bryter en sjakt langt borte fra menneskers bolig; der ferdes de glemt, dypt under menneskers føtter; der henger de og svever langt borte fra mennesker. 
18:28:5: Av jorden kommer det brød; men inne i den blir alt veltet om som av ild. 
18:28:6: Safiren har sin bolig i dens stener, og gullklumper finnes der. 
18:28:7: Ingen rovfugl kjenner stien dit ned, og intet falkeøie har sett den; 
18:28:8: stolte rovdyr har ikke trådt på den, ingen løve har skredet frem over den. 
18:28:9: På den hårde sten legger de sin hånd, de velter hele fjell om fra grunnen av. 
18:28:10: I berget sprenger de ganger, og allehånde kostelige ting får de se. 
18:28:11: De demmer for dryppet av vannårene og fører skjulte ting frem i schmelken. 
18:28:12: Men visdommen hvor skal den finnes? Og hvor har forstanden sin bolig? 
18:28:13: Intet menneske kjenner dens verd, og den finnes ikke i de levendes land. 
18:28:14: Dypet sier: I mig er den ikke, og havet sier: Den er ikke hos mig. 
18:28:15: Den kan ikke kjøpes for kostelig gull, og dens pris ikke opveies med sølv. 
18:28:16: Den opveies ikke med Ofirs gull, med den dyre onyks og safir. 
18:28:17: Gull og glass kommer ikke op imot den, og en kan ikke bytte den til sig for kar av fint gull. 
18:28:18: Koraller og krystall kan ikke engang nevnes, og det å eie visdom er bedre enn perler. 
18:28:19: Etiopias topas kommer ikke op imot den; den kan ikke opveies med det reneste gull. 
18:28:20: Hvor kommer da visdommen fra? Og hvor har forstanden sin bolig? 
18:28:21: Den er dulgt for alle levendes øine, den er skjult for himmelens fugler; 
18:28:22: avgrunnen og døden sier: Bare et frasagn om den er kommet oss for øre. 
18:28:23: Gud kjenner veien til den, og han vet hvor den har sin bolig. 
18:28:24: For hans øie når til jordens ender; allting under himmelen ser han. 
18:28:25: Da han fastsatte vindens vekt og gav vannet dets mål, 
18:28:26: da han satte en lov for regnet og en vei for lynstrålen, 
18:28:27: da så han visdommen og åpenbarte den, da lot han den stå frem og utforsket den. 
18:28:28: Og han sa til mennesket: Se, å frykte Herren, det er visdom, og å fly det onde er forstand. 
18:29:1: Og Job blev ved å fremføre sin visdomstale og sa: 
18:29:2: Å, om jeg hadde det som i fordums måneder, som i de dager da Gud vernet om mig, 
18:29:3: da hans lampe skinte over mitt hode, da jeg ved hans schmelk vandret gjennem mørket, 
18:29:4: slik som jeg hadde det i min modne manndoms dager, da Guds vennskap hvilte over mitt telt, 
18:29:5: da den Allmektige ennu var med mig, og jeg hadde mine barn omkring mig, 
18:29:6: da mine føtter badet sig i melk, og berget ved mitt hus lot bekker av olje strømme frem! 
18:29:7: Når jeg gikk op til porten i byen og inntok mitt sete på torvet, 
18:29:8: da drog de unge sig unda ved synet av mig, og de gråhårede reiste sig og blev stående; 
18:29:9: høvdinger lot være å tale og la hånden på sin munn; 
18:29:10: de fornemme tidde stille, og deres tunge blev hengende ved ganen. 
18:29:11: Enhver som hørte om mig, priste mig lykkelig, og hver den som så mig, gav mig lovord. 
18:29:12: For jeg berget armingen som ropte om hjelp, og den farløse som ingen hjelper hadde. 
18:29:13: Den som var sin undergang nær, velsignet mig, og enkens hjerte fikk jeg til å juble. 
18:29:14: Jeg klædde mig i rettferdighet, og den opslo sin bolig i mig; rettsinn bar jeg som kappe og hue. 
18:29:15: Øine var jeg for den blinde, og føtter var jeg for den halte. 
18:29:16: En far var jeg for de fattige, og ukjente folks sak gransket jeg. 
18:29:17: Jeg knuste den urettferdiges kjever og rev byttet bort fra hans tenner. 
18:29:18: Jeg tenkte da: I mitt rede skal jeg få dø, og mine dager skal bli tallrike som sand. 
18:29:19: Min rot skal ligge åpen for vann, og nattens dugg skal falle på mine grener. 
18:29:20: Min ære blir alltid ny, og min bue forynges i min hånd. 
18:29:21: Mig hørte de på, de ventet og lyttet i taushet til mitt råd. 
18:29:22: Når jeg hadde talt, tok de ikke til orde igjen, og min tale dryppet ned over dem. 
18:29:23: De ventet på min tale som på regn, de åpnet sin munn som for vårregn. 
18:29:24: Når de var motløse, smilte jeg til dem, og mitt åsyns schmelk kunde de ikke formørke. 
18:29:25: Fikk jeg lyst til å gå til dem, da satt jeg der som høvding og tronte som en konge i sin krigerskare, lik en som trøster de sørgende. 
18:30:1: Men nu ler de av mig, de som er yngre av år enn jeg, hvis fedre jeg aktet så ringe at jeg ikke vilde sette dem blandt mine fehunder. 
18:30:2: Hvad hjelp kunde jeg også ha av dem, de som har mistet all manndomskraft? 
18:30:3: De er uttæret av nød og sult; de gnager på den tørre mo, som allerede igår var ørk og øde; 
18:30:4: de plukker melde innunder buskene, og gyvelbuskens røtter er deres brød. 
18:30:5: Fra menneskenes samfund jages de ut; folk roper efter dem som efter tyver. 
18:30:6: I fryktelige kløfter må de bo, i huler i jord og berg. 
18:30:7: Mellem buskene skriker de, i neslekratt samler de sig, 
18:30:8: barn av dårer og æreløse folk, pisket ut av landet. 
18:30:9: Og nu er jeg blitt til en spottesang og et ordsprog for dem. 
18:30:10: De avskyr mig, holder sig langt borte fra mig, og mitt ansikt sparer de ikke for spytt; 
18:30:11: for de har løst sine tøiler og ydmyket mig, og bislet har de kastet av for mine øine. 
18:30:12: Ved min høire side reiser deres yngel sig; mine føtter støter de bort og legger sine ulykkesveier mot mig. 
18:30:13: De bryter op min sti, de gjør hvad de kan for å ødelegge mig, de som selv ingen hjelper har. 
18:30:14: Som gjennem en vid revne kommer de; gjennem nedstyrtende murer velter de sig frem. 
18:30:15: Redsler har vendt sig mot mig, som stormen forfølger de min ære, og som en sky er min velferd faret bort. 
18:30:16: Og nu utøser min sjel sig i mig; trengsels dager holder mig fast. 
18:30:17: Natten gjennemborer mine ben, så de faller av, og min verk og pine hviler ikke. 
18:30:18: Ved Guds store kraft er det blitt slik med mig at min klædning ikke er til å kjenne igjen; den henger tett omkring mig som kraven på min underkjortel. 
18:30:19: Han har kastet mig ut i skarnet, så jeg er blitt lik støv og aske. 
18:30:20: Jeg skriker til dig, men du svarer mig ikke; jeg står der, og du bare ser på mig. 
18:30:21: Du er blitt grusom mot mig, med din sterke hånd forfølger du mig. 
18:30:22: Du løfter mig op i stormen, du lar mig fare avsted, og du lar mig forgå i dens brak; 
18:30:23: for jeg vet at du fører mig til døden, til den bolig hvor alt levende samles. 
18:30:24: Dog, rekker ikke mennesket ut sin hånd når alt synker i grus? Skriker han ikke om hjelp når han er kommet i ulykke? 
18:30:25: Gråt jeg ikke selv over den som hadde hårde dager? Sørget ikke min sjel over den fattige? 
18:30:26: For jeg ventet godt, men det kom ondt; jeg håpet på schmelk, men det kom mørke. 
18:30:27: Mine innvoller koker og er ikke stille; trengsels dager er kommet over mig. 
18:30:28: Sort går jeg omkring, men ikke av solens hete; midt iblandt folk reiser jeg mig og roper om hjelp. 
18:30:29: Jeg er blitt en bror av sjakaler og en stallbror av strutser. 
18:30:30: Min hud er sort og faller av mig, og mine ben er brent av hete. 
18:30:31: Og min citar er blitt til sorg, og min fløite til gråt og klage. 
18:31:1: En pakt hadde jeg gjort med mine øine, at jeg ikke skulde se på en jomfru. 
18:31:2: Hvad lodd vilde jeg ellers få av Gud i himmelen, eller hvad arv av den Allmektige i det høie? 
18:31:3: Rammer ikke fordervelse den urettferdige, og ulykke dem som gjør det onde? 
18:31:4: Ser ikke han mine veier, og teller han ikke alle mine skritt? 
18:31:5: Dersom jeg har faret frem med falskhet, og min fot har hastet til svik 
18:31:6: - la Gud veie mig på rettferds vektskål, og han skal se at jeg er uten skyld - 
18:31:7: dersom mine skritt har bøid av fra veien, og mitt hjerte har fulgt mine øine, og dersom der er nogen flekk på mine hender, 
18:31:8: gid da en annen må ete det jeg har sådd, og gid det jeg har plantet, må rykkes op med rot! 
18:31:9: Dersom mitt hjerte har latt sig dåre for en kvinnes skyld, og jeg har luret ved min næstes dør, 
18:31:10: gid da min hustru må male korn* for en annen, og andre menn bøie sig over henne! # <* 2MO 11, 5.> 
18:31:11: For slikt er en skjenselsdåd, det er en misgjerning, hjemfalt til dom; 
18:31:12: det er en ild som fortærer like til avgrunnen; alt mitt gods skulde den gjøre ende på. 
18:31:13: Har jeg krenket min træls og min trælkvinnes rett, når de hadde nogen trette med mig? 
18:31:14: Hvad skulde jeg da gjøre om Gud stod op, og hvad skulde jeg svare ham om han gransket saken? 
18:31:15: Har ikke han som skapte mig i mors liv, skapt også dem, og har ikke en og den samme dannet oss i mors liv? 
18:31:16: Har jeg nektet fattigfolk det de ønsket, og latt enkens øine tæres bort? 
18:31:17: Har jeg ett mitt brød alene, så den farløse ikke fikk ete av det? 
18:31:18: Nei, fra min ungdom av vokste han op hos mig som hos en far, og fra min mors liv av førte jeg henne* ved hånden. # <* enken, JBS 31, 16.> 
18:31:19: Har jeg kunnet se en ulykkelig uten klær eller en fattig uten et plagg å ha på sig? 
18:31:20: Måtte ikke hans lender velsigne mig, fordi han fikk varme sig med ull av mine får? 
18:31:21: Har jeg løftet min hånd mot den farløse, fordi jeg var viss på å få medhold i retten? 
18:31:22: Gid da min skulder må falle fra sitt ledd, og min arm bli brutt løs fra sin skål! 
18:31:23: For Guds straff fylte mig med redsel, og mot hans velde maktet jeg intet. 
18:31:24: Har jeg satt mitt håp til gullet og sagt til gullklumpen: Du er min tillit? 
18:31:25: Har jeg gledet mig fordi min rikdom blev stor, og fordi min hånd vant mig meget gods? 
18:31:26: Når jeg så sollyset, hvorledes det strålte, og månen, hvor herlig den skred frem, 
18:31:27: blev da mitt hjerte dåret i lønndom, så jeg sendte dem håndkyss*? # <* så jeg gjorde mig skyldig i avgudsdyrkelse.> 
18:31:28: Nei, også det vilde være en misgjerning, hjemfalt til dom; for da hadde jeg fornektet Gud i det høie. 
18:31:29: Har jeg gledet mig ved min fiendes uferd og jublet når ulykken rammet ham? 
18:31:30: Nei, jeg tillot ikke min munn å synde ved å forbanne ham og ønske ham døden. 
18:31:31: Må ikke mine husfolk vidne at enhver fikk mette sig ved mitt bord? 
18:31:32: Aldri måtte en fremmed ligge utenfor mitt hus om natten; jeg åpnet mine dører for den veifarende. 
18:31:33: Har jeg, som mennesker pleier, skjult mine synder og dulgt min misgjerning i min barm, 
18:31:34: fordi jeg fryktet den store mengde og var redd for de fornemme slekters forakt, så jeg tidde stille og ikke gikk ut av min dør? 
18:31:35: Å, om jeg hadde nogen som vilde høre på mig! Se, her er min underskrift*, la den Allmektige svare mig! Å, om jeg hadde det skrift min motpart har satt op! # <* mitt underskrevne innlegg i saken.> 
18:31:36: Sannelig, jeg skulde ta det på min skulder, jeg skulde feste det til mitt hode som en krone; 
18:31:37: jeg skulde gjøre ham regnskap for alle mine skritt, som en fyrste skulde jeg møte ham. 
18:31:38: Dersom min aker skriker over mig, og alle dens furer gråter, 
18:31:39: dersom jeg har fortæret dens grøde uten betaling og utslukket dens eiers liv, 
18:31:40: gid det da må vokse torner på min aker istedenfor hvete og ugress istedenfor bygg! Her ender Jobs ord. 
18:32:1: De tre menn svarte ikke Job mere, fordi han var rettferdig i sine egne øine. 
18:32:2: Da optendtes Elihus vrede - han stammet fra Bus* og var sønn av Barak'el, av Rams ætt. Mot Job optendtes hans vrede, fordi han holdt sig selv for å være rettferdig for Gud, # <* 1MO 22, 21.> 
18:32:3: og mot hans tre venner optendtes hans vrede, fordi de ikke fant noget svar og allikevel dømte Job skyldig. 
18:32:4: Elihu hadde ventet med å tale til Job, fordi de andre var eldre av år enn han. 
18:32:5: Da nu Elihu så at det ikke var noget svar i de tre menns munn, da optendtes hans vrede. 
18:32:6: Så tok da Elihu, sønn av Barak'el, busitten, til orde og sa: Jeg er ung av år, og I er gråhårede; derfor holdt jeg mig tilbake og torde ikke uttale for eder hvad jeg vet. 
18:32:7: Jeg tenkte: La alderen tale og de mange år forkynne visdom! 
18:32:8: Dog, det er menneskets ånd og den Allmektiges åndepust som gjør forstandig. 
18:32:9: De gamle er ikke alltid vise, ikke alltid forstår oldinger hvad rett er. 
18:32:10: Derfor sier jeg: Hør nu på mig! Også jeg vil uttale hvad jeg vet. 
18:32:11: Jeg ventet på eders ord, jeg lyttet efter forstandig tale fra eder, mens I grundet på hvad I skulde si. 
18:32:12: Jeg gav akt på eder; men det var ingen av eder som gjendrev Job, ingen som svarte på hans ord. 
18:32:13: Si ikke: Vi har funnet visdom hos ham; bare Gud kan få bukt med ham, ikke noget menneske! 
18:32:14: Han har jo ikke rettet sin tale mot mig, og med eders ord vil jeg ikke svare ham. 
18:32:15: De er forferdet og svarer ikke mere; ordene er blitt borte for dem. 
18:32:16: Skal jeg vente, fordi de ikke taler, fordi de står der og ikke svarer mere? 
18:32:17: Også jeg vil nu svare for min del; også jeg vil uttale hvad jeg vet. 
18:32:18: For jeg er full av ord; ånden i mitt indre driver mig. 
18:32:19: Mitt indre er som innestengt vin; som nyfylte skinnsekker vil det revne. 
18:32:20: Jeg vil tale, så jeg kan få luft; jeg vil åpne mine leber og svare. 
18:32:21: Jeg vil ikke ta parti for nogen, og jeg vil ikke smigre for noget menneske; 
18:32:22: for jeg forstår ikke å smigre; ellers kunde min skaper lett rykke mig bort. 
18:33:1: Men hør nu, Job, på min tale og lytt til alle mine ord! 
18:33:2: Se, jeg har åpnet mine leber, allerede taler min tunge i min munn. 
18:33:3: Ærlige og opriktige er mine ord, og hvad jeg vet, skal mine leber uttale likefrem. 
18:33:4: Guds Ånd har skapt mig, og den Allmektiges ånde holder mig live. 
18:33:5: Hvis du kan, så svar mig! Rust dig mot mig, tred frem! 
18:33:6: Se, jeg er din like for Gud, også jeg er dannet av ler. 
18:33:7: Redsel for mig skal ikke overvelde dig, og min myndighet ikke tynge dig. 
18:33:8: Sannelig, du har sagt i mitt nærvær, så lød dine ord som jeg hørte: 
18:33:9: Ren er jeg, uten brøde, plettfri er jeg og fri for misgjerning; 
18:33:10: men Gud søker grunn til fiendskap mot mig, han akter mig for sin uvenn; 
18:33:11: han setter mine føtter i stokken og vokter på alle mine veier. 
18:33:12: Nei, i dette har du ikke rett, svarer jeg dig; Gud er jo større enn et menneske. 
18:33:13: Hvorfor går du i rette med ham? Han svarer jo ikke et eneste ord. 
18:33:14: Men én gang taler Gud, ja to ganger hvis mennesket ikke akter på det. 
18:33:15: I drøm, i nattlig syn, når dyp søvn faller på menneskene, når de slumrer på sitt leie, 
18:33:16: da åpner han deres ører og trykker sitt segl på advarselen til dem, 
18:33:17: for å få mennesket til å la sin gjerning fare og for å utrydde overmotet hos mannen, 
18:33:18: for å berge hans sjel fra graven og hans liv fra å rammes av det drepende spyd. 
18:33:19: Mennesket tuktes også med smerter på sitt leie, og en stadig uro går gjennem marg og ben. 
18:33:20: Han vemmes ved brød og hans sjel ved lekker mat. 
18:33:21: Hans kjøtt tæres bort, så en ikke ser det mere, og hans ben, som en før ikke så, ligger bare; 
18:33:22: hans sjel kommer nær til graven og hans liv til dødens engler. 
18:33:23: Er det da hos ham en engel, en tolk, en av tusen, som forkynner mennesket dets rette vei, 
18:33:24: da ynkes Gud over ham og sier: Fri ham fra å fare ned i graven! Jeg har fått løsepenger. 
18:33:25: Hans kropp blir da frodigere enn i ungdommen, han blir atter som i sin ungdoms dager. 
18:33:26: Han beder til Gud, og han er ham nådig; han ser Guds åsyn med jubel, og han gir mennesket dets rettferdighet tilbake. 
18:33:27: Han synger for menneskene og sier: Jeg hadde syndet og gjort det rette kroket, men han gjengjeldte mig det ikke; 
18:33:28: han har fridd min sjel fra å fare ned i graven, og mitt liv ser schmelken med lyst. 
18:33:29: Se, alt dette gjør Gud to ganger, ja tre, mot en mann 
18:33:30: for å frelse hans sjel fra graven, så han omstråles av de levendes schmelk. 
18:33:31: Gi akt, Job, hør på mig! Ti, så jeg får tale. 
18:33:32: Har du ord, så svar mig, tal! Jeg vil gjerne gi dig rett. 
18:33:33: Hvis ikke, så hør du på mig! Ti, så jeg får lære dig visdom. 
18:34:1: Og Elihu tok atter til orde og sa: 
18:34:2: Hør mine ord, I vise, og lån mig øre, I forstandige! 
18:34:3: Øret prøver jo ord, likesom ganen smaker mat. 
18:34:4: La oss velge det som er rett; la oss sammen søke å finne ut hvad der er godt! 
18:34:5: Job har jo sagt: Jeg er rettferdig, og Gud har tatt min rett fra mig; 
18:34:6: tross min rett skal jeg være en løgner; en drepende pil har rammet mig, enda der ingen brøde er hos mig. 
18:34:7: Hvem er en mann som Job, han som drikker bespottelse som vann* # <* JBS 15, 16.> 
18:34:8: og gir sig i lag med dem som gjør ondt, og søker omgang med ugudelige menn? 
18:34:9: For han har sagt: En mann har intet gagn av at han holder vennskap med Gud. 
18:34:10: Derfor, I forstandige, hør på mig! Det være langt fra Gud å gjøre noget syndig og fra den Allmektige å være urettferdig! 
18:34:11: Han lønner mennesket efter dets gjerninger og gjengjelder mannen efter hans ferd. 
18:34:12: Ja sannelig, Gud gjør ikke noget syndig, og den Allmektige forvender ikke retten. 
18:34:13: Hvem har overgitt jorden til hans varetekt, og hvem har overlatt hele jorderike til ham? 
18:34:14: Dersom han bare vilde tenke på sig selv og dra sin Ånd og sin ånde til sig igjen, 
18:34:15: da skulde alt kjød opgi ånden på én gang, og mennesket bli til støv igjen. 
18:34:16: Men gi nu akt og hør på dette, lytt nøye til mine ord! 
18:34:17: Kan vel en som hater retten, være hersker? Eller tør du fordømme den Rettferdige, den Mektige? 
18:34:18: Sier vel nogen til en konge: Din niding, eller til en fyrste: Du ugudelige? 
18:34:19: Gud tar jo ikke parti for fyrster og akter ikke en rik høiere enn en fattig? De er jo alle hans henders verk. 
18:34:20: I et øieblikk dør de, midt om natten; folket raver og forgår, og den mektige rykkes bort, ikke ved menneskehånd. 
18:34:21: For hans øine vokter på hver manns veier, og han ser alle hans skritt; 
18:34:22: det finnes intet mørke og ingen dødsskygge hvor de som gjør ondt kan skjule sig; 
18:34:23: Gud har ikke nødig å gi lenge akt på en mann før han må møte for Guds dom. 
18:34:24: Han knuser de mektige uten å granske deres sak og setter så andre i deres sted. 
18:34:25: Ja, han kjenner deres gjerninger, og han slår dem ned om natten så de går til grunne. 
18:34:26: Han tukter dem som ugjerningsmenn, på et sted hvor alle kan se det; 
18:34:27: for derfor vek de bort fra ham og aktet ikke på nogen av hans veier, 
18:34:28: forat de skulde la de fattiges skrik komme for ham, forat han skulde høre de undertryktes rop. 
18:34:29: Lar han være å skride inn, hvem tør da fordømme ham? Skjuler han sitt åsyn, hvem får da se ham? Både med et folk og med et enkelt menneske gjør han jo således, 
18:34:30: forat et gudløst menneske ikke skal herske, forat det ikke skal være snarer for folket. 
18:34:31: For har vel et slikt menneske nogensinne sagt til Gud: Jeg har vært overmodig, jeg vil herefter ikke gjøre det som ondt er; 
18:34:32: det jeg ikke ser, det må du lære mig; har jeg gjort urett, så vil jeg ikke gjøre det mere? 
18:34:33: Skulde han vel gjengjelde efter ditt tykke? Du har jo klandret ham*. Så må du velge og ikke jeg, og hvad du vet, får du si. # <* nemlig for hans gjengjeldelse.> 
18:34:34: Forstandige menn vil si til mig, ja hver vismann som hører på mig: 
18:34:35: Job taler uten skjønnsomhet, og hans ord er ikke forstandige. 
18:34:36: Gid Job måtte bli prøvd uavlatelig, fordi han har svart på onde menneskers vis! 
18:34:37: For til sin synd legger han brøde; her iblandt oss klapper han i hendene* og bruker mange ord om Gud. # <* d.e. han håner; JBS 27, 23.> 
18:35:1: Og Elihu tok atter til orde og sa: 
18:35:2: Holder du det for rett, du som har sagt: Jeg er rettferdigere enn Gud, 
18:35:3: at du sier: Hvad nytter det mig, hvad gagn har jeg av at jeg ikke synder? 
18:35:4: Jeg vil gi dig svar, og dine venner med dig. 
18:35:5: Vend ditt øie mot himmelen og se, gi akt på skyene høit over dig! 
18:35:6: Om du synder, hvad gjør du ham med det? Og er dine overtredelser mange, hvad skade volder du ham? 
18:35:7: Er du rettferdig, hvad kan du gi ham, hvad mottar han av din hånd? 
18:35:8: Bare for et menneske, din likemann, kan din ugudelighet ha noget å si, og bare for et menneskebarn din rettferdighet. 
18:35:9: Over de mange undertrykkelser klager de; de skriker om hjelp mot de mektiges arm. 
18:35:10: Men ingen sier: Hvor er Gud, min skaper, han som lar lovsanger lyde om natten*, # <* d.e. i ulykkens natt.> 
18:35:11: han som gir oss forstand fremfor jordens dyr og gjør oss vise fremfor himmelens fugler? 
18:35:12: Da roper de, uten at han svarer, om hjelp mot de ondes overmot. 
18:35:13: Ja visselig, Gud hører ikke på tomme ord, den Allmektige akter ikke på slikt. 
18:35:14: Også når du sier at du ikke ser ham, så ser han nok din sak, og du må bie på ham. 
18:35:15: Men nu, fordi du ikke gjør det, hjemsøker han dig i sin vrede, og han akter ikke stort på overmodige ord. 
18:35:16: Og Job oplater sin munn med tom tale; han bruker mange ord i sin uforstand. 
18:36:1: Og Elihu blev ved og sa: 
18:36:2: Vent litt på mig, så jeg kan få sagt dig min mening! For ennu er der noget å si til forsvar for Gud. 
18:36:3: Jeg vil hente min kunnskap langt borte fra, og jeg vil vise at min skaper har rett. 
18:36:4: For sannelig, mine ord er ikke falske; en mann med fullkommen kunnskap har du for dig. 
18:36:5: Se, Gud er sterk, men han akter ikke nogen ringe; han er sterk i forstandens kraft. 
18:36:6: Han lar ikke en ugudelig leve, og de undertrykte hjelper han til deres rett. 
18:36:7: Han tar ikke sine øine fra de rettferdige, og hos konger på tronen lar han dem sitte all deres tid høit hedret. 
18:36:8: Og om de blir bundet med lenker og fanget i ulykkens snarer, 
18:36:9: så vil han dermed foreholde dem deres gjerninger, deres synder, at de viste sig gjenstridige, 
18:36:10: og åpne deres øre for advarselen og formane dem til å vende om fra det onde. 
18:36:11: Om de da hører og tjener ham, så får de leve sine dager i lykke og sine år i herlighet og glede. 
18:36:12: Hører de ikke, da skal de gjennembores av spydet og omkomme i sin uforstand. 
18:36:13: Men mennesker med gudløst sinn huser vrede; de roper ikke til Gud når han legger dem i bånd*. # <* JBS 36, 8.> 
18:36:14: De dør i ungdommen, og deres liv ender som tempel-bolernes*. # <* 1KG 14, 24.> 
18:36:15: Han frelser de ulykkelige ved deres ulykke og åpner deres øre ved trengselen. 
18:36:16: Også dig lokker han ut av trengselens svelg til en åpen plass hvor det ikke er trangt; og ditt bord skal være fullt av fete retter. 
18:36:17: Men er du full av den ugudeliges brøde, så skal brøde og dom følges at. 
18:36:18: La bare ikke vrede lokke dig til spott, og la ikke den store bot* lokke dig på avvei! # <* d.e. den store trengsel Gud har pålagt dig for dine synders skyld.> 
18:36:19: Kan vel ditt skrik fri dig ut av trengsel, og kan vel alt ditt strev og slit utrette det? 
18:36:20: Stund ikke efter natten, den natt da hele folkeslag blåses bort fra sitt sted! 
18:36:21: Vokt dig, vend dig ikke til synd! For det har du mere lyst til enn til å lide. 
18:36:22: Se, Gud er ophøiet i sin kraft; hvem er en læremester som han? 
18:36:23: Hvem har foreskrevet ham hans vei, og hvem kan si: Du gjorde urett? 
18:36:24: Kom i hu at du ophøier hans gjerning, den som menneskene har sunget om! 
18:36:25: All verden ser på den med lyst; menneskene skuer den langt borte fra. 
18:36:26: Se, Gud er stor, og vi forstår ham ikke; hans års tall er uutgrundelig; 
18:36:27: han drar vanndråper op til sig, og av tåken siler regnet ned; 
18:36:28: fra skyene strømmer det og drypper ned over mange mennesker. 
18:36:29: Kan også nogen forstå hvorledes skyene breder sig ut, hvorledes det braker fra hans telt*? # <* d.e. skyene.> 
18:36:30: Se, han breder ut sitt lys omkring sig og dekker det med havets røtter.* # <* d.e. skyer som stiger op av havets dyp.> 
18:36:31: For således straffer han folkeslag, men gir også føde i overflod. 
18:36:32: Han dekker sine hender med schmelk og byder det å fare ut mot fienden. 
18:36:33: Hans tordenbrak bærer bud om ham; endog feet varsler når han rykker frem. 
18:37:1: Ja, over dette forferdes mitt hjerte og hopper i mitt bryst. 
18:37:2: Hør, hør braket av hans røst og det drønn som går ut av hans munn! 
18:37:3: Under hele himmelen lar han det fare, og han sender sitt lys til jordens ytterste ender. 
18:37:4: Efterpå brøler røsten, han tordner med sin veldige røst; han holder ikke lynene tilbake når hans røst lar sig høre. 
18:37:5: Gud tordner underfullt med sin røst; han gjør storverk, og vi forstår dem ikke. 
18:37:6: Han sier til sneen: Fall til jorden! - og likeså til skyllregnet, sitt sterke skyllregn. 
18:37:7: Hvert menneskes hånd forsegler han*, forat alle mennesker som han har skapt, må komme til å kjenne ham. # <* d.e. han gjør det umulig for mennesket å utføre sitt arbeid på marken.> 
18:37:8: Da går de ville dyr inn i sine huler, og de holder sig i sine hi. 
18:37:9: Fra Sydens innerste kammer kommer storm, og med nordenvinden kommer kulde. 
18:37:10: Av Guds ånde kommer is, og brede vann bindes. 
18:37:11: Med væte fyller han skyen, og han spreder sine lynskyer, 
18:37:12: og de svinger hit og dit, efter som han leder dem, forat de skal utføre alt det han byder dem, over den vide jord; 
18:37:13: enten til tukt, når det er til gagn for hans jord, eller til velsignelse lar han dem komme. 
18:37:14: Vend ditt øre til dette, Job! Stå stille og gi akt på Guds under! 
18:37:15: Forstår du hvorledes Gud styrer dem og lar sine skyers lyn blinke frem? 
18:37:16: Forstår du hvorledes skyene svever om i luften, forstår du den Allvitendes under, 
18:37:17: du hvis klær blir varme når jorden ligger og dormer i sønnenvind? 
18:37:18: Kan du med ham spenne ut himmelen, så fast som et speil av støpt metall? 
18:37:19: Lær oss hvad vi skal si til ham! Vi kan ikke fremføre noget for bare mørke. 
18:37:20: Skal det fortelles ham at jeg vil tale med ham? Har nogen sagt at han ønsker sin egen undergang? 
18:37:21: Og nu, menneskene ser ikke schmelken, enda det skinner klart på himmelen, og en vind er faret frem og har renset den. 
18:37:22: Fra Norden kommer gull; om Gud er der en forferdende herlighet. 
18:37:23: Den Allmektige finner vi ikke, han som er så stor i makt; men retten og den strenge rettferdighet krenker han ikke. 
18:37:24: Derfor frykter menneskene ham; men han enser ikke nogen selvklok mann. 
18:38:1: Og Herren svarte Job ut av et stormvær og sa: 
18:38:2: Hvem er han som formørker mitt råd med ord uten forstand? 
18:38:3: Nuvel, omgjord dine lender som en mann! Så vil jeg spørre dig, og du skal lære mig. 
18:38:4: Hvor var du da jeg grunnfestet jorden? Si frem hvis du vet det! 
18:38:5: Hvem fastsatte vel dens mål? Vet du det? Eller hvem spente målesnor ut over den? 
18:38:6: Hvor blev dens støtter rammet ned, eller hvem la dens hjørnesten, 
18:38:7: mens alle morgenstjerner jublet, og alle Guds sønner ropte av fryd? 
18:38:8: Og hvem lukket for havet med dører, da det brøt frem og gikk ut av mors liv, 
18:38:9: da jeg gjorde skyer til dets klædebon og skodde til dets svøp 
18:38:10: og merket av en grense for det og satte bom og dører 
18:38:11: og sa: Hit skal du komme og ikke lenger, her skal dine stolte bølger legge sig? 
18:38:12: Har du i dine dager befalt morgenen å bryte frem, har du vist morgenrøden dens sted, 
18:38:13: forat den skulde gripe fatt i jordens ender, og de ugudelige rystes bort fra den? 
18:38:14: Jorden tar da form likesom ler under seglet, og tingene treder frem som et klædebon, 
18:38:15: og de ugudelige unddras sitt lys*, og den løftede arm knuses. # <* d.e. mørket; JBS 24, 17.> 
18:38:16: Er du kommet til havets kilder, og har du vandret på dypets bunn? 
18:38:17: Har dødens porter vist sig for dig, og har du sett dødsskyggens porter? 
18:38:18: Har du sett ut over jordens vidder? Si frem dersom du kjenner alt dette! 
18:38:19: Hvor er veien dit hvor schmelken bor? Og mørket - hvor er dets sted, 
18:38:20: så du kunde hente det frem til dets område, så du kjente stiene til dets hus? 
18:38:21: Du vet det vel; dengang blev du jo født, og dine dagers tall er stort. 
18:38:22: Er du kommet til forrådskammerne for sneen, og har du sett forrådshusene for haglet, 
18:38:23: som jeg har opspart til trengselens tid, til kampens og krigens dag? 
18:38:24: Hvad vei følger schmelken når det deler sig, og østenvinden når den spreder sig over jorden? 
18:38:25: Hvem har åpnet renner for regnskyllet og vei for lynstrålen 
18:38:26: for å la det regne over et øde land, over en ørken hvor intet menneske bor, 
18:38:27: for å mette ørk og øde og få gressbunnen til å gro? 
18:38:28: Har regnet nogen far? Eller hvem har avlet duggens dråper? 
18:38:29: Av hvis liv er vel isen gått frem, og himmelens rim - hvem fødte det? 
18:38:30: Vannet blir hårdt som sten, og havets overflate stivner. 
18:38:31: Kan du knytte Syvstjernens bånd, eller kan du løse Orions lenker? 
18:38:32: Kan du føre Dyrekretsens stjernebilleder frem i rette tid, og Bjørnen med dens unger* - kan du styre deres gang? # <* JBS 9, 9.> 
18:38:33: Kjenner du himmelens lover? Fastsetter du dens herredømme over jorden? 
18:38:34: Kan din røst nå op til skyen, så en flom av vann dekker dig? 
18:38:35: Kan du sende ut lynene, så de farer avsted, så de sier til dig: Se, her er vi? 
18:38:36: Hvem har lagt visdom i de mørke skyer, eller hvem har lagt forstand i luftsynet? 
18:38:37: Hvem teller skyene med visdom, og himmelens vannsekker - hvem heller vannet ut av dem, 
18:38:38: når støvet flyter sammen til en fast masse, og jordklumpene henger fast ved hverandre? 
18:39:1: Jager du rov for løvinnen, og metter du de grådige ungløver, 
18:39:2: når de dukker sig ned i sine huler og ligger på lur i krattet? 
18:39:3: Hvem lar ravnen finne sin mat, når dens unger skriker til Gud og farer hit og dit uten føde? 
18:39:4: Kjenner du tiden når stengjetene føder, og gir du akt på hindenes veer? 
18:39:5: Teller du månedene til de skal bære, og vet du tiden når de føder? 
18:39:6: De bøier sig, føder sine unger og blir fri for sine smerter. 
18:39:7: Deres unger blir kraftige og vokser op ute på marken; de løper bort og kommer ikke tilbake til dem. 
18:39:8: Hvem har gitt villeslet dets frihet, hvem løste dets bånd, 
18:39:9: det som jeg gav ørkenen til hus og saltmoen til bolig? 
18:39:10: Det ler av byens ståk og styr; driverens skjenn slipper det å høre. 
18:39:11: Hvad det leter op på fjellene, er dets beite, og det søker efter hvert grønt strå. 
18:39:12: Har vel villoksen lyst til å tjene dig? Vil den bli natten over ved din krybbe? 
18:39:13: Kan du binde villoksen med rep til furen*? Vil den harve dalene efter dig? # <* d.e. tvinge den til å følge plogfuren.> 
18:39:14: Kan du stole på den, fordi dens kraft er så stor, og kan du overlate den ditt arbeid? 
18:39:15: Kan du lite på at den fører din grøde hjem, og at den samler den til din treskeplass? 
18:39:16: Strutsen flakser lystig med vingene; men viser dens vinger og fjær moderkjærlighet? 
18:39:17: Nei, den overlater sine egg til jorden og lar dem opvarmes i sanden, 
18:39:18: og den glemmer at en fot kan klemme dem itu, og markens ville dyr trå dem i stykker. 
18:39:19: Den er hård mot sine unger, som om de ikke var dens egne; den er ikke redd for at dens møie skal være spilt. 
18:39:20: For Gud nektet den visdom og gav den ingen forstand. 
18:39:21: Men når den flakser i været, ler den av hesten og dens rytter. 
18:39:22: Gir du hesten styrke? Klær du dens hals med bevrende man? 
18:39:23: Lar du den springe som gresshoppen? Dens stolte fnysen er forferdelig. 
18:39:24: Den skraper i jorden og gleder sig ved sin kraft; så farer den frem mot væbnede skarer. 
18:39:25: Den ler av frykten og forferdes ikke, og den vender ikke om for sverd. 
18:39:26: Over den klirrer koggeret, blinkende spyd og lanse. 
18:39:27: Med styr og ståk river den jorden op, og den lar sig ikke stagge når krigsluren lyder. 
18:39:28: Hver gang luren lyder, sier den: Hui! Og langt borte værer den striden, høvedsmenns tordenrøst og hærskrik. 
18:39:29: Skyldes det din forstand at høken svinger sig op og breder ut sine vinger mot Syden? 
18:39:30: Er det på ditt bud at ørnen flyver så høit, og at den bygger sitt rede oppe i høiden? 
18:39:31: Den bor på berget og har nattely der, på tind og nut. 
18:39:32: Derfra speider den efter føde; langt bort skuer dens øine. 
18:39:33: Dens unger drikker blod, og hvor der er lik, der er den. 
18:39:34: Og Herren blev ved å svare Job og sa: 
18:39:35: Vil du som klandrer den Allmektige, vil du trette med ham? Du som laster Gud, må svare på dette! 
18:39:36: Da svarte Job Herren og sa: 
18:39:37: Nei, jeg er for ringe; hvad skulde jeg svare dig? Jeg legger min hånd på min munn. 
18:39:38: En gang har jeg talt, men jeg tar ikke mere til orde - ja to ganger, men jeg gjør det ikke mere. 
18:40:1: Og Herren svarte Job ut av stormen og sa: 
18:40:2: Omgjord dine lender som en mann! Jeg vil spørre dig, og du skal lære mig. 
18:40:3: Vil du endog gjøre min rettferdighet til intet? Vil du dømme mig skyldig, så du får rett? 
18:40:4: Har du slik en arm som Gud, og kan du tordne med en røst som hans? 
18:40:5: Pryd dig med majestet og høihet og klæ dig i glans og herlighet! 
18:40:6: La din vrede strømme frem og se på alle overmodige og ydmyk dem! 
18:40:7: Se på alle overmodige og bøi dem og tred de ugudelige ned der de står! 
18:40:8: Skjul dem alle i støvet, bind deres ansikter fast i mørket! 
18:40:9: Da skal også jeg prise dig, fordi din høire hånd kan hjelpe dig. 
18:40:10: Se på Behemot*, som jeg har skapt like så vel som dig; den eter gress som en okse. # <* flodhesten.> 
18:40:11: Se hvad kraft den har i sine lender, og hvad styrke den har i sine bukmuskler! 
18:40:12: Den strekker sin hale som en seder; senene i dens lår er sammenslynget. 
18:40:13: Dens ben er som kobberrør, dens knokler som jernstenger. 
18:40:14: Den er den ypperste av Guds skapninger; av sin skaper fikk den sitt sverd*. # <* d.e. dens tenner.> 
18:40:15: Fjellene bærer fôr for den, og alle ville dyr leker der. 
18:40:16: Under lotusbusker hviler den, i ly av rør og siv. 
18:40:17: Lotusbusker gir den tak og skygge, piletrærne ved bekken omgir den. 
18:40:18: Selv om strømmen går stri blir den ikke redd; den er trygg om så en Jordan fosser frem mot dens gap. 
18:40:19: Kan nogen fange den så den ser det? Kan nogen dra en snare gjennem dens nese? 
18:40:20: Kan du dra Leviatan* op med en krok og trykke dens tunge ned med et snøre? # <* krokodillen.> 
18:40:21: Kan du sette en sivline i dens nese og gjennembore dens kjeve med en krok? 
18:40:22: Vil den rette mange ydmyke bønner til dig eller tale blide ord til dig? 
18:40:23: Vil den gjøre en pakt med dig, så du kan få den til din træl for all tid? 
18:40:24: Kan du leke med den som med en fugl og binde den fast for dine små piker? 
18:40:25: Kan et lag av fiskere kjøpslå om den, stykke den ut mellem kjøbmennene? 
18:40:26: Kan du fylle dens hud med spyd og dens hode med harpuner? 
18:40:27: Prøv å legge hånd på den! Den strid skal du komme til å minnes og ikke gjøre det igjen! 
18:40:28: Nei, den som våger slikt, hans håp blir sveket; allerede ved synet av den styrter han til jorden. 
18:41:1: Ingen er så djerv at han tør tirre den; hvem tør da sette sig op imot mig? 
18:41:2: Hvem gav mig noget først, så jeg skulde gi ham vederlag? Alt under himmelen hører mig til. 
18:41:3: Jeg vil ikke tie om dens lemmer, om dens store styrke og dens fagre bygning. 
18:41:4: Hvem har dradd dens klædning av? Hvem tør komme innenfor dens dobbelte rad av tenner? 
18:41:5: Hvem har åpnet dens kjevers dør? Rundt om dens tenner er redsel. 
18:41:6: Stolte er skjoldenes rader; hvert av dem er tillukket som med et fast segl. 
18:41:7: De ligger tett innpå hverandre, og ingen luft trenger inn imellem dem. 
18:41:8: Det ene skjold henger fast ved det andre; de griper inn i hverandre og skilles ikke at. 
18:41:9: Når den nyser, stråler det frem schmelk, og dens øine er som morgenrødens øielokk. 
18:41:10: Bluss farer ut av dens gap, gnister spruter frem. 
18:41:11: Fra dens nesebor kommer røk som av en gryte som koker over siv. 
18:41:12: Dens ånde tender kull i brand, og luer går ut av dens gap. 
18:41:13: På dens hals har styrken sin bolig, og angsten springer foran den. 
18:41:14: Dens doglapper sitter fast; de er som støpt på den og rører sig ikke. 
18:41:15: Dens hjerte er fast som sten, fast som den underste kvernsten. 
18:41:16: Når den hever sig, gruer helter; av redsel mister de sans og samling. 
18:41:17: Rammes den med sverd, så biter det ikke på den, heller ikke lanse, pil eller kastespyd. 
18:41:18: Den akter jern som strå, kobber som ormstukket tre. 
18:41:19: Buens sønn* jager den ikke på flukt; slyngens stener blir som halm for den. # <* pilen.> 
18:41:20: Stridsklubber aktes som halm, og den ler av det susende spyd. 
18:41:21: På dens buk sitter skarpe skår, den gjør spor i dyndet som efter en treskeslede. 
18:41:22: Den får dypet til å koke som en gryte; den får havet til å skumme som en salvekokers kjele. 
18:41:23: Efter den lyser dens sti; dypet synes å ha sølvhår. 
18:41:24: Det er intet på jorden som er herre over den; den er skapt til ikke å reddes. 
18:41:25: Alt som er høit, ser den i øiet; den er en konge over alle stolte dyr. 
18:42:1: Da svarte Job Herren og sa: 
18:42:2: Jeg vet at du kan alt, og at ingen tanke er umulig å utføre for dig. 
18:42:3: Hvem er den som vil formørke ditt råd i uforstand? Derfor må jeg si: Jeg har talt om det jeg ikke forstod, om det som var mig for underlig, og som jeg ikke skjønte. 
18:42:4: Men hør nu, så vil jeg tale! Jeg vil spørre dig, og du skal lære mig. 
18:42:5: Bare hvad ryktet meldte, hadde jeg hørt om dig; men nu har mitt øie sett dig. 
18:42:6: Derfor kaller jeg alt tilbake og angrer i støv og aske. 
18:42:7: Da Herren hadde talt disse ord til Job, sa han til Elifas fra Teman: Min vrede er optendt mot dig og dine to venner; for I har ikke talt rett om mig, som min tjener Job. 
18:42:8: Ta derfor syv okser og syv værer og gå til min tjener Job og ofre dem som brennoffer for eder! Og min tjener Job skal bede for eder; bare ham vil jeg bønnhøre, så jeg ikke gjør med eder efter eders uforstand; for I har ikke talt rett om mig, som min tjener Job. 
18:42:9: Så gikk Elifas fra Teman og Bildad fra Suah og Sofar fra Na'ama og gjorde som Herren hadde talt til dem; og Herren bønnhørte Job. 
18:42:10: Og Herren gjorde ende på Jobs ulykke, da han bad for sine venner; og Herren øket alt det Job hadde hatt, til det dobbelte. 
18:42:11: Og alle hans brødre kom til ham, og alle hans søstre og alle som hadde kjent ham før, og de åt med ham i hans hus og viste ham medynk og trøstet ham for all den ulykke Herren hadde latt komme over ham; og de gav ham hver en kesitte* og hver en gullring. # <* 1MO 33, 19.> 
18:42:12: Og Herren velsignet Jobs siste dager mere enn hans første*, og han fikk fjorten tusen får og seks tusen kameler og tusen par okser og tusen aseninner. # <* JBS 1, 3; 8, 7.> 
18:42:13: Og han fikk syv sønner og tre døtre. 
18:42:14: Den ene datter kalte han Jemima, den annen Kesia og den tredje Keren-Happuk. 
18:42:15: Så fagre kvinner som Jobs døtre fantes ikke i hele landet; og deres far gav dem arv blandt deres brødre. 
18:42:16: Derefter levde Job hundre og firti år, og han så sine barn og barnebarn i fjerde ledd. 
18:42:17: Og Job døde, gammel og mett av dager. 
19:1:1: Salig er den mann som ikke vandrer i ugudeliges råd og ikke står på synderes vei og ikke sitter i spotteres sete, 
19:1:2: men har sin lyst i Herrens lov og grunder på hans lov dag og natt. 
19:1:3: Han skal være lik et tre, plantet ved rinnende bekker, som gir sin frukt i sin tid, og hvis blad ikke visner, og alt hvad han gjør, skal han ha lykke til. 
19:1:4: Ikke så de ugudelige, men de er lik agner, som spredes av vinden. 
19:1:5: Derfor skal ugudelige ikke bli stående i dommen, og syndere ikke i de rettferdiges menighet. 
19:1:6: For Herren kjenner de rettferdiges vei, men de ugudeliges vei går til grunne. 
19:2:1: Hvorfor larmer hedningene og grunder folkene på det som fåfengt er? 
19:2:2: Jordens konger reiser sig, og fyrstene rådslår sammen mot Herren og mot hans salvede: 
19:2:3: La oss sprenge deres bånd og kaste deres rep av oss! 
19:2:4: Han som troner i himmelen, ler, Herren spotter dem. 
19:2:5: Så taler han til dem i sin vrede, og i sin harme forferder han dem: 
19:2:6: Og jeg har dog innsatt min konge på Sion, mitt hellige berg! 
19:2:7: Jeg vil kunngjøre hvad fastsatt er: Herren sa til mig: Du er min sønn, jeg har født dig idag. 
19:2:8: Begjær av mig! Så vil jeg gi dig hedningene til arv og jordens ender til eie. 
19:2:9: Du skal knuse dem med jernstav; som en pottemakers kar skal du sønderslå dem. 
19:2:10: Og nu, I konger, gå viselig frem! La eder advare, I dommere på jorden! 
19:2:11: Tjen Herren med frykt og juble med beven! 
19:2:12: Kyss* Sønnen, forat han ikke skal bli vred, og I gå til grunne på veien! For snart kunde hans vrede optendes. Salige er alle de som tar sin tilflukt til ham**. # <* d.e. hyld, 1SA 10, 1.> # <** SLM 34, 9; 84, 13.> 
19:3:1: En salme av David, da han flydde for Absalom, sin sønn. 
19:3:2: Herre, hvor mange mine fiender er! Mange reiser sig imot mig. 
19:3:3: Mange sier til min sjel: Det er ingen frelse for ham hos Gud. Sela*. # <* et musikalsk uttrykk.> 
19:3:4: Men du, Herre, er et skjold omkring mig, min ære og den som opløfter mitt hode! 
19:3:5: Høit ropte jeg til Herren, og han svarte mig fra sitt hellige berg. Sela. 
19:3:6: Jeg la mig og sov inn, jeg våknet; for Herren støtter mig. 
19:3:7: Jeg frykter ikke for titusener av folk, som har lagt sig mot mig rundt omkring. 
19:3:8: Reis dig, Herre, frels mig, min Gud! For du har slått alle mine fiender på kinnbenet, du har sønderbrutt de ugudeliges tenner. 
19:3:9: Herren hører frelsen til; over ditt folk være din velsignelse! Sela. 
19:4:1: Til sangmesteren, med strengelek; en salme av David. 
19:4:2: Når jeg roper, da svar mig, min rettferdighets Gud! I trengsel har du gitt mig rum; vær mig nådig og hør min bønn! 
19:4:3: I veldige menn! Hvor lenge skal min ære være til spott? Hvor lenge vil I elske det som fåfengt er, søke løgn? Sela. 
19:4:4: Vit dog at Herren har utkåret sig en from! Herren hører når jeg roper til ham. 
19:4:5: Vredes, men synd ikke! Tenk efter i eders hjerte på eders leie og vær stille! Sela. 
19:4:6: Ofre rettferdighets offere, og sett eders lit til Herren! 
19:4:7: Mange sier: Hvem vil dog la oss se godt? Opløft du ditt åsyns schmelk over oss, Herre! 
19:4:8: Du har gitt mig glede i mitt hjerte, større enn deres når deres korn og most er mangfoldig. 
19:4:9: I fred vil jeg både legge mig ned og sove inn; for du, Herre, lar mig bo for mig selv, i trygghet. 
19:5:1: Til sangmesteren, til Nehilot*; en salme av David. # <* rimeligvis bleseinstrumenter.> 
19:5:2: Vend øret til mine ord, Herre, akt på min tanke! 
19:5:3: Merk på mitt klagerop, min konge og min Gud! For til dig beder jeg. 
19:5:4: Herre, årle høre du min røst! Årle legger jeg min sak frem for dig og venter. 
19:5:5: For du er ikke en Gud som har behag i ugudelighet; den onde får ikke bo hos dig. 
19:5:6: Overmodige får ikke stå frem for dine øine; du hater alle dem som gjør urett. 
19:5:7: Du lar dem som taler løgn, gå til grunne; den blodgjerrige og falske mann er en vederstyggelighet for Herren. 
19:5:8: Men jeg går ved din megen miskunn inn i ditt hus, jeg kaster mig ned foran ditt hellige tempel i din frykt. 
19:5:9: Herre, led mig ved din rettferdighet for mine motstanderes skyld, gjør din vei jevn for mitt åsyn! 
19:5:10: For det er ikke sannhet i deres munn; deres indre er fordervelse, deres strupe en åpen grav; sin tunge gjør de glatt. 
19:5:11: Døm dem skyldige, Gud! La dem falle for sine råds skyld, styrt dem ned for deres mange misgjerninger! For de er gjenstridige mot dig. 
19:5:12: Da skal alle de som tar sin tilflukt til dig, glede sig; til evig tid skal de juble, og du skal verne om dem; og de som elsker ditt navn, skal fryde sig i dig. 
19:5:13: For du velsigner den rettferdige, Herre! Som med et skjold dekker du ham med nåde. 
19:6:1: Til sangmesteren, med strengelek; efter Sjeminit*; en salme av David. # <* kanskje en viss toneart, 1KR 15, 21.> 
19:6:2: Herre, straff mig ikke i din vrede og tukt mig ikke i din harme! 
19:6:3: Vær mig nådig, Herre! for jeg er bortvisnet. Helbred mig, Herre! for mine ben er forferdet, 
19:6:4: og min sjel er såre forferdet; og du, Herre, hvor lenge? 
19:6:5: Vend om, Herre, utfri min sjel, frels mig for din miskunnhets skyld! 
19:6:6: For i døden kommer ingen dig i hu; hvem vil prise dig i dødsriket? 
19:6:7: Jeg er trett av mine sukk, jeg gjennembløter min seng hver natt; med mine tårer væter jeg mitt leie. 
19:6:8: Borttæret av sorg er mitt øie; det er eldet for alle mine fienders skyld. 
19:6:9: Vik fra mig, alle I som gjør urett! For Herren har hørt min gråts røst, 
19:6:10: Herren har hørt min inderlige begjæring, Herren tar imot min bønn. 
19:6:11: Alle mine fiender skal bli til skamme og såre forferdet; de skal vike tilbake, bli til skamme i et øieblikk. 
19:7:1: En sjiggajon* av David, som han sang for Herren på grunn av benjaminitten Kus' ord. # <* betydningen uviss, kanskje en sang som uttrykker sterk sinnsbevegelse.> 
19:7:2: Herre min Gud! Til dig setter jeg min lit; frels mig fra alle mine forfølgere og redd mig, 
19:7:3: forat ikke fienden skal sønderrive min sjel som en løve, sønderbryte, og det er ingen som redder! 
19:7:4: Herre min Gud! Dersom jeg har gjort dette, dersom det er urett i mine hender, 
19:7:5: dersom jeg har gjengjeldt den med ondt som holdt fred med mig, eller plyndret den som var min fiende uten årsak, 
19:7:6: så la fienden forfølge min sjel og innhente den og trå mitt liv til jorden og legge min ære* i støvet. Sela. # <* d.e. sjel.> 
19:7:7: Stå op, Herre, i din vrede, reis dig mot mine fienders rasen og våkn op til min hjelp! Du har jo påbudt dom. 
19:7:8: Og la folkeslagenes forsamling omringe dig, og vend tilbake over den til det høie! 
19:7:9: Herren holder dom over folkene; døm mig, Herre, efter min rettferdighet og efter min uskyld, som er hos mig! 
19:7:10: La dog de ugudeliges ondskap få ende og den rettferdige stå fast! Du er jo den som prøver hjerter og nyrer, en rettferdig Gud. 
19:7:11: Mitt skjold er hos Gud, som frelser de opriktige av hjertet. 
19:7:12: Gud er en rettferdig dommer, og en Gud som vredes hver dag. 
19:7:13: Dersom han* ikke vender om, så hvesser han** sitt sverd, spenner sin bue og gjør den ferdig # <* den ugudelige.> # <** Gud.> 
19:7:14: og legger drepende våben til rette imot ham; sine piler gjør han brennende. 
19:7:15: Se, han* er i ferd med å føde misgjerning; han er fruktsommelig med ulykke og føder løgn. # <* den ugudelige.> 
19:7:16: En grav har han gravd og hulet den ut; men han faller i den grav han arbeidet på. 
19:7:17: Den ulykke han gikk og tenkte på, faller tilbake på hans hode, og over hans egen isse kommer den vold han hadde i sinne. 
19:7:18: Jeg vil prise Herren efter hans rettferdighet og lovsynge Herrens, den Høiestes navn. 
19:8:1: Til sangmesteren, efter Gittit*; en salme av David. # <* kanskje en toneart som har hatt sitt navn av byen Gat.> 
19:8:2: Herre, vår Herre, hvor herlig ditt navn er over all jorden, du som har utbredt din prakt over himmelen! 
19:8:3: Av barns og diebarns munn har du grunnfestet en makt for dine motstanderes skyld, for å stoppe munnen på fienden og den hevngjerrige. 
19:8:4: Når jeg ser din himmel, dine fingrers gjerning, månen og stjernene, som du har gjort, 
19:8:5: hvad er da et menneske, at du kommer ham i hu, og et menneskebarn, at du ser til ham! 
19:8:6: Og du gjorde ham lite ringere enn Gud, og med ære og herlighet kronte du ham. 
19:8:7: Du gjorde ham til hersker over dine henders gjerninger, alt la du under hans føtter, 
19:8:8: får og okser, alle tilsammen, ja også markens dyr, 
19:8:9: himmelens fugler og havets fisker, alt som ferdes på havenes stier. 
19:8:10: Herre, vår Herre, hvor herlig ditt navn er over all jorden! 
19:9:1: Til sangmesteren, efter Mutlabbén*; en salme av David. # <* betydningen ukjent.> 
19:9:2: Jeg vil prise Herren av hele mitt hjerte, jeg vil forkynne alle dine undergjerninger. 
19:9:3: Jeg vil glede og fryde mig i dig, jeg vil lovsynge ditt navn, du Høieste, 
19:9:4: fordi mine fiender viker tilbake, faller og omkommer for ditt åsyn. 
19:9:5: For du har hjulpet mig til rett og dom, du har satt dig på tronen som rettferdig dommer. 
19:9:6: Du har truet hedningene, tilintetgjort den ugudelige; deres navn har du utslettet evindelig og alltid. 
19:9:7: Fiendenes boliger er helt ødelagt for all tid, og byene har du omstyrtet, deres minne er tilintetgjort. 
19:9:8: Og Herren troner til evig tid, han har reist sin trone til dom, 
19:9:9: og han dømmer jorderike med rettferdighet, han avsier dom over folkene med rettvishet. 
19:9:10: Og Herren er en borg for den undertrykte, en borg i nødens tider. 
19:9:11: Og de som kjenner ditt navn, stoler på dig; for du har ikke forlatt dem som søker dig, Herre! 
19:9:12: Lovsyng Herren, som bor på Sion, kunngjør blandt folkene hans store gjerninger! 
19:9:13: For han som hevner blod, kommer de elendige i hu, han glemmer ikke deres skrik. 
19:9:14: Vær mig nådig, Herre! Se det jeg må lide av dem som hater mig, du som løfter mig op fra dødens porter, 
19:9:15: forat jeg skal forkynne all din pris, i Sions datters porter fryde mig i din frelse. 
19:9:16: Hedningene er sunket i den grav de gravde; deres fot er fanget i det garn de skjulte. 
19:9:17: Herren er blitt kjent, han har holdt dom; han fanger den ugudelige i hans egne henders gjerning. Higgajon*. Sela. # <* kanskje et musikalsk uttrykk.> 
19:9:18: De ugudelige skal fare ned til dødsriket, alle hedninger, som glemmer Gud. 
19:9:19: For ikke skal den fattige glemmes for all tid; de saktmodiges håp skal ikke gå til grunne for evig. 
19:9:20: Reis dig, Herre! La ikke mennesker få makt, la hedningene bli dømt for ditt åsyn! 
19:9:21: La frykt komme over dem, Herre! La hedningene kjenne at de er mennesker! Sela. 
19:10:1: Hvorfor, Herre, står du langt borte? Hvorfor skjuler du ditt åsyn i trengsels tider? 
19:10:2: Ved den ugudeliges overmot engstes de elendige; de fanges i de onde råd han har uttenkt. 
19:10:3: For den ugudelige roser sin sjels lyst, og den rovgjerrige sier Herren farvel, håner ham. 
19:10:4: Den ugudelige sier i sitt overmot: Han hjemsøker ikke. Det er ingen Gud, det er alle hans tanker. 
19:10:5: Hans veier lykkes til enhver tid; langt borte fra ham i det høie er dine dommer; av alle sine motstandere blåser han. 
19:10:6: Han sier i sitt hjerte: Jeg skal ikke rokkes, fra slekt til slekt skal jeg ikke stedes i ulykke. 
19:10:7: Hans munn er full av forbannelse og av svik og vold; under hans tunge er ulykke og ondskap. 
19:10:8: Han ligger i bakhold ved gårdene, på lønnlige steder myrder han den uskyldige, hans øine speider efter den ulykkelige. 
19:10:9: Han lurer på lønnlig sted, lik løven i sitt skjul; han lurer for å gripe den elendige; han griper den elendige idet han drar ham inn i sitt garn. 
19:10:10: Han bøier sig, dukker sig ned, og for hans sterke klør faller de elendige. 
19:10:11: Han sier i sitt hjerte: Gud har glemt det, han har skjult sitt åsyn, han ser det aldri. 
19:10:12: Reis dig, Herre! Gud, opløft din hånd, glem ikke de elendige! 
19:10:13: Hvorfor skal den ugudelige forakte Gud og si i sitt hjerte: Du hjemsøker ikke? 
19:10:14: Du har sett det; for du skuer nød og sorg for å legge dem i din hånd; til dig overgir den elendige sin sak, du er den farløses hjelper. 
19:10:15: Sønderbryt den ugudeliges arm, og hjemsøk den ondes ugudelighet, så du ikke mere finner den! 
19:10:16: Herren er konge evindelig og alltid, hedningene blir utryddet av hans land! 
19:10:17: De saktmodiges begjæring hører du, Herre! Du styrker deres hjerte, du vender ditt øre til 
19:10:18: for å dømme i den farløses og undertryktes sak. Ikke skal mennesket, som er av jorden, lenger vedbli å volde redsel. 
19:11:1: Til sangmesteren; av David. Til Herren tar jeg min tilflukt; hvorledes kan I da si til min sjel: Fly til eders fjell som en fugl? 
19:11:2: For se, de ugudelige spenner buen, de legger sin pil på strengen for å skyte i mørket på de opriktige av hjertet. 
19:11:3: Når grunnvollene nedbrytes, hvad makter da den rettferdige? 
19:11:4: Herren er i sitt hellige tempel, Herrens trone er i himmelen, hans øine skuer, hans blikk prøver menneskenes barn. 
19:11:5: Herren prøver den rettferdige; men den ugudelige og den som elsker vold, hater hans sjel. 
19:11:6: Han lar snarer regne ned over de ugudelige; ild og svovel og glødende vind er deres begers del. 
19:11:7: For Herren er rettferdig, elsker rettferdighet; på den opriktige ser hans åsyn. 
19:12:1: Til sangmesteren, efter Sjeminit*; en salme av David. # <* SLM 6, 1.> 
19:12:2: Frels, Herre! for de fromme er borte, de trofaste er forsvunnet blandt menneskenes barn. 
19:12:3: Løgn taler de, hver med sin næste, med falske leber; med tvesinnet hjerte taler de. 
19:12:4: Herren utrydde alle falske leber, den tunge som taler store ord, 
19:12:5: dem som sier: Ved vår tunge skal vi få overhånd, våre leber er med oss, hvem er herre over oss? 
19:12:6: For de elendiges ødeleggelses skyld, for de fattiges sukks skyld vil jeg nu reise mig, sier Herren; jeg vil gi dem frelse som stunder efter den. 
19:12:7: Herrens ord er rene ord, likesom sølv som er renset i en smeltedigel i jorden, syv ganger renset. 
19:12:8: Du, Herre, vil bevare dem, du vil vokte dem for denne slekt evindelig. 
19:12:9: Rundt omkring svermer de ugudelige, når skarn er ophøiet blandt menneskenes barn. 
19:13:1: Til sangmesteren; en salme av David. 
19:13:2: Hvor lenge, Herre, vil du glemme mig evindelig? Hvor lenge vil du skjule ditt åsyn for mig? 
19:13:3: Hvor lenge skal jeg huse sorgfulle tanker i min sjel, kummer i mitt hjerte den hele dag? Hvor lenge skal min fiende ophøie sig over mig? 
19:13:4: Se hit, svar mig, Herre min Gud! Opklar mine øine, forat jeg ikke skal sove inn i døden, 
19:13:5: forat ikke min fiende skal si: Jeg fikk overhånd over ham, forat ikke mine motstandere skal fryde sig når jeg vakler! 
19:13:6: Men jeg setter min lit til din miskunnhet; mitt hjerte skal fryde sig i din frelse; jeg vil lovsynge Herren, for han har gjort vel imot mig. 
19:14:1: Til sangmesteren; av David. Dåren sier i sitt hjerte: Det er ikke nogen Gud. Onde, vederstyggelige er deres gjerninger; det er ingen som gjør godt. 
19:14:2: Herren skuer ned fra himmelen på menneskenes barn for å se om det er nogen forstandig, nogen som søker Gud. 
19:14:3: De er alle avveket, alle tilsammen fordervet; det er ingen som gjør godt, enn ikke én. 
19:14:4: Sanser de da ikke, alle de som gjør urett, som eter mitt folk, likesom de eter brød? På Herren kaller de ikke. 
19:14:5: Da forferdes de såre; for Gud er med den rettferdige slekt. 
19:14:6: Gjør bare den elendiges råd til skamme! For Herren er hans tilflukt. 
19:14:7: O, at der fra Sion må komme frelse for Israel! Når Herren gjør ende på sitt folks fangenskap, da skal Jakob fryde sig, Israel glede sig. 
19:15:1: En salme av David. Herre, hvem skal bo i ditt telt? Hvem skal bygge på ditt hellige berg? 
19:15:2: Den som vandrer ustraffelig og gjør rettferdighet og taler sannhet i sitt hjerte, 
19:15:3: som ikke baktaler med sin tunge, som ikke gjør sin næste ondt og ikke fører skam over den som står ham nær, 
19:15:4: den som ser med ringeakt på den gudløse, men som ærer dem som frykter Herren, som sverger sig selv til skade og ikke bryter sitt ord, 
19:15:5: den som ikke låner sine penger ut mot rente* og ikke tar gave mot den uskyldige. Den dette gjør, skal ikke rokkes evindelig. # <* til den fattige bror.> 
19:16:1: En gyllen sang av David. Bevar mig, Gud! for jeg tar min tilflukt til dig. 
19:16:2: Jeg sier til Herren: Du er min Herre; jeg har intet gode utenfor dig - 
19:16:3: idet jeg holder mig til de hellige som er i landet, og de herlige i hvem jeg har all min lyst. 
19:16:4: Mange sorger skal de ha som kjøper sig andre*; jeg vil ikke utgyde deres** drikkoffere av blod og ikke ta deres navn på mine leber***. # <* d.e. andre guder.> # <** d.e. avgudenes.> # <*** 2MO 23, 13.> 
19:16:5: Herren er min tilfalne del og mitt beger; du gjør min lodd herlig. 
19:16:6: En lodd er tilfalt mig som er liflig, og en arv som behager mig. 
19:16:7: Jeg vil love Herren, som gav mig råd; også om nettene minner mine nyrer mig om det. 
19:16:8: Jeg setter alltid Herren for mig; for han er ved min høire hånd, jeg skal ikke rokkes. 
19:16:9: Derfor gleder mitt hjerte sig, og min ære* fryder sig; også mitt kjød skal bo i trygghet. # <* d.e. sjel.> 
19:16:10: For du skal ikke overlate min sjel til dødsriket, du skal ikke la din hellige se forråtnelse. 
19:16:11: Du skal kunngjøre mig livets vei; gledes fylde er for ditt åsyn, livsalighet ved din høire hånd evindelig. 
19:17:1: En bønn av David. Hør, Herre, på rettferdighet, merk på mitt klagerop, vend øret til min bønn fra leber uten svik! 
19:17:2: La min rett gå ut fra ditt åsyn, dine øine skue hvad rett er! 
19:17:3: Du har prøvd mitt hjerte, gjestet det om natten, du har ransaket mig, du fant intet; min munn viker ikke av fra mine tanker. 
19:17:4: Mot menneskenes gjerninger har jeg efter dine lebers ord tatt mig i vare for voldsmannens stier. 
19:17:5: Mine skritt holdt fast ved dine fotspor, mine trin vaklet ikke. 
19:17:6: Jeg roper til dig, for du svarer mig, Gud! Bøi ditt øre til mig, hør mitt ord! 
19:17:7: Vis din miskunnhet i underfulle gjerninger, du som med din høire hånd frelser dem som flyr til dig, fra deres motstandere! 
19:17:8: Vokt mig som din øiesten, skjul under dine vingers skygge 
19:17:9: for de ugudelige, som ødelegger mig, mine dødsfiender, som omringer mig! 
19:17:10: Sitt fete hjerte lukker de til, med sin munn taler de overmodig. 
19:17:11: Hvor vi går, kringsetter de mig nu; sine øine retter de på å felle mig til jorden. 
19:17:12: Han er lik en løve som stunder efter å sønderrive, og en ung løve som ligger på lønnlige steder. 
19:17:13: Reis dig, Herre, tred ham i møte, slå ham ned, frels min sjel fra den ugudelige med ditt sverd, 
19:17:14: fra menneskene med din hånd, Herre, fra denne verdens mennesker, som har sin del i livet, og hvis buk du fyller med dine skatter, som er rike på sønner og efterlater sin overflod til sine barn. 
19:17:15: Jeg skal i rettferdighet skue ditt åsyn, jeg skal, når jeg våkner, mettes ved din skikkelse. 
19:18:1: Til sangmesteren; av Herrens tjener David, som talte denne sangs ord til Herren den dag da Herren hadde utfridd ham av alle hans fienders hånd og av Sauls hånd. 
19:18:2: Og han sa: Herre, jeg har dig hjertelig kjær, min styrke! 
19:18:3: Herren er min klippe og min festning og min frelser; min Gud er min klippe, som jeg setter min lit til, mitt skjold og min frelses horn, min borg. 
19:18:4: Jeg påkaller den Høilovede, Herren, og blir frelst fra mine fiender. 
19:18:5: Dødens rep omspente mig, og fordervelsens strømmer forferdet mig. 
19:18:6: Dødsrikets rep omgav mig, dødens snarer overfalt mig. 
19:18:7: I min trengsel påkalte jeg Herren, og jeg ropte til min Gud; han hørte fra sitt tempel min røst, og mitt skrik kom for ham, til hans ører. 
19:18:8: Da rystet og bevet jorden, og fjellenes grunnvoller skalv, og de rystet, for hans vrede var optendt. 
19:18:9: Det steg røk op av hans nese, og fortærende ild fra hans munn; glør brente ut av ham. 
19:18:10: Og han bøide himmelen og steg ned, og det var mørke under hans føtter. 
19:18:11: Og han fór på kjeruber og fløi, og han fór hastig frem på vindens vinger. 
19:18:12: Han gjorde mørke til sitt dekke, rundt omkring sig til sitt skjul, mørke vann, tykke skyer. 
19:18:13: Frem av glansen foran ham fór hans skyer frem, hagl og gloende kull. 
19:18:14: Og Herren tordnet i himmelen, den Høieste lot sin røst høre, hagl og gloende kull. 
19:18:15: Og han utsendte sine piler og spredte dem* omkring - lyn i mengde og forvirret dem. # <* fiendene.> 
19:18:16: Da kom vannenes strømmer til syne, og jordens grunnvoller blev avdekket ved din trusel, Herre, for din neses åndepust. 
19:18:17: Han rakte sin hånd ut fra det høie, han grep mig; han drog mig op av store vann. 
19:18:18: Han fridde mig ut fra min sterke fiende og fra mine avindsmenn; for de var mig for mektige. 
19:18:19: De overfalt mig på min motgangs dag; men Herren blev min støtte. 
19:18:20: Og han førte mig ut i fritt rum; han frelste mig, for han hadde behag i mig. 
19:18:21: Herren gjengjeldte mig efter min rettferdighet, han betalte mig efter mine henders renhet. 
19:18:22: For jeg tok vare på Herrens veier og vek ikke i ondskap fra min Gud. 
19:18:23: For alle hans lover hadde jeg for øie, og hans bud lot jeg ikke vike fra mig. 
19:18:24: Og jeg var ulastelig for ham og voktet mig vel for min synd. 
19:18:25: Og Herren gjengjeldte mig efter min rettferdighet, efter mine henders renhet for hans øine. 
19:18:26: Mot den fromme viser du dig from, mot den rettvise mann viser du dig rettvis, 
19:18:27: mot den rene viser du dig ren, mot den forvendte viser du dig vrang. 
19:18:28: For du frelser elendige folk, og du fornedrer høie øine. 
19:18:29: For du lar min lampe skinne; Herren min Gud opklarer mitt mørke. 
19:18:30: For ved dig stormer jeg løs på fiendeskarer, og ved min Gud springer jeg over murer. 
19:18:31: Gud, hans vei er fullkommen; Herrens ord er rent, han er et skjold for alle dem som setter sin lit til ham. 
19:18:32: For hvem er Gud foruten Herren, og hvem er en klippe, uten vår Gud? 
19:18:33: Den Gud som omgjorder mig med kraft og gjør min vei fri for støt, 
19:18:34: som gir mig føtter likesom hindene og stiller mig på mine høider, 
19:18:35: som oplærer mine hender til krig, så mine armer spenner kobberbuen. 
19:18:36: Og du gir mig din frelse til skjold, og din høire hånd støtter mig, og din mildhet gjør mig stor. 
19:18:37: Du gjør rummet vidt for mine skritt under mig, og mine ankler vakler ikke. 
19:18:38: Jeg forfølger mine fiender og når dem, og jeg vender ikke tilbake før jeg har gjort ende på dem. 
19:18:39: Jeg knuser dem, så de ikke makter å reise sig; de faller under mine føtter. 
19:18:40: Og du omgjorder mig med kraft til krig, du bøier mine motstandere under mig. 
19:18:41: Og mine fiender lar du vende mig ryggen, og mine avindsmenn utrydder jeg. 
19:18:42: De roper, men der er ingen frelser - til Herren, men han svarer dem ikke. 
19:18:43: Og jeg knuser dem som støv for vinden, jeg tømmer dem ut som søle på gatene. 
19:18:44: Du redder mig fra folkekamper, du setter mig til hode for hedninger; folkeferd som jeg ikke kjenmer, tjener mig. 
19:18:45: Bare de hører om mig, blir de mig lydige; fremmede kryper for mig. 
19:18:46: Fremmede visner bort og går bevende ut av sine borger. 
19:18:47: Herren lever, og priset er min klippe, og ophøiet er min frelses Gud, 
19:18:48: den Gud som gir mig hevn og legger folkeferd under mig, 
19:18:49: som frir mig ut fra mine fiender; ja, over mine motstandere ophøier du mig, fra voldsmannen redder du mig. 
19:18:50: Derfor vil jeg prise dig iblandt hedningene, Herre, og lovsynge ditt navn. 
19:18:51: Han gjør frelsen stor for sin konge, han gjør miskunnhet mot sin salvede, mot David og mot hans ætt til evig tid. 
19:19:1: Til sangmesteren; en salme av David. 
19:19:2: Himlene forteller Guds ære, og hvelvingen forkynner hans henders gjerning. 
19:19:3: Den ene dag lar sin tale utstrømme til den annen, og den ene natt forkynner den annen sin kunnskap. 
19:19:4: Der er ei tale, der er ei ord, ei høres deres* røst. # <* himlenes.> 
19:19:5: Over all jorden utgår deres målesnor*, og til jorderikes ende deres ord; for solen har han satt et telt på dem. # <* hele jorden er deres forkynnelses område.> 
19:19:6: Og den er som en brudgom som går ut av sitt brudekammer; den gleder sig som en helt til å løpe sin bane. 
19:19:7: Fra himmelens ende er dens utgang, og dens omløp inntil dens ende, og intet er skjult for dens hete. 
19:19:8: Herrens lov er fullkommen, den vederkveger sjelen; Herrens vidnesbyrd er trofast, det gjør den enfoldige vis; 
19:19:9: Herrens befalinger er rette, de gleder hjertet; Herrens bud er rent, det oplyser øinene; 
19:19:10: Herrens frykt er ren, den står fast evindelig; Herrens lover er sannhet, de er rettferdige alle tilsammen. 
19:19:11: De er kosteligere enn gull, ja fint gull i mengde, og søtere enn honning, ja honning som drypper av kakene. 
19:19:12: Også din tjener blir påminnet ved dem; den som holder dem, har stor lønn. 
19:19:13: Hvem merker hvor ofte han feiler? Forlat mig mine lønnlige synder! 
19:19:14: Bevar også din tjener fra skammelige synder, la dem ei herske over mig! Så blir jeg ulastelig og uten skyld for store overtredelser. 
19:19:15: La min munns ord og mitt hjertes tanke være til velbehag for ditt åsyn, Herre, min klippe og min gjenløser! 
19:20:1: Til sangmesteren; en salme av David. 
19:20:2: Herren bønnhøre dig på nødens dag, Jakobs Guds navn berge dig! 
19:20:3: Han sende dig hjelp fra helligdommen og støtte dig fra Sion! 
19:20:4: Han komme alle dine matoffer i hu og finne ditt brennoffer godt! Sela. 
19:20:5: Han gi dig efter ditt hjerte og fullbyrde alt ditt råd! 
19:20:6: Måtte vi kunne juble over din frelse og løfte seiersmerket i vår Guds navn! Herren opfylle alle dine bønner! 
19:20:7: Nu vet jeg at Herren frelser sin salvede; han svarer ham fra sin hellige himmel med frelsende storverk av sin høire hånd. 
19:20:8: Hine priser vogner, hine hester, men vi priser Herrens, vår Guds navn. 
19:20:9: De synker og faller, men vi står og holder oss oppe. 
19:20:10: Herre, frels! Kongen bønnhøre oss på den dag vi roper! 
19:21:1: Til sangmesteren; en salme av David. 
19:21:2: Herre! Kongen gleder sig over din makt, og hvor høit han fryder sig ved din frelse! 
19:21:3: Hvad hans hjerte ønsket, har du gitt ham, og hvad hans leber bad om, har du ikke nektet ham. Sela. 
19:21:4: For du kom ham i møte med velsignelse og lykke, du satte en krone av gull på hans hode. 
19:21:5: Han bad dig om liv; du gav ham det, et langt liv evindelig og alltid. 
19:21:6: Stor er hans ære ved din frelse; høihet og herlighet legger du på ham. 
19:21:7: For du setter ham til velsignelse evindelig, du fryder ham med glede for ditt åsyn. 
19:21:8: For kongen setter sin lit til Herren, og ved den Høiestes miskunnhet skal han ikke rokkes. 
19:21:9: Din hånd skal finne alle dine fiender, din høire hånd skal finne dine avindsmenn. 
19:21:10: Du skal gjøre dem som en ildovn når du viser ditt åsyn; Herren skal opsluke dem i sin vrede, og ild skal fortære dem. 
19:21:11: Deres frukt skal du utslette av jorden, og deres avkom blandt menneskenes barn. 
19:21:12: For de har grundet på ondt mot dig, de har uttenkt onde råd; de skal ikke makte noget. 
19:21:13: For du skal få dem til å vende ryggen; med dine buestrenger sikter du mot deres åsyn. 
19:21:14: Reis dig, Herre, i din kraft! Vi vil lovsynge og prise ditt storverk. 
19:22:1: Til sangmesteren; efter "Morgenrødens hind"*; en salme av David. # <* sannsynligvis melodien.> 
19:22:2: Min Gud! Min Gud! Hvorfor har du forlatt mig? Langt borte fra min frelse er min klages ord. 
19:22:3: Min Gud! Jeg roper om dagen, og du svarer ikke, og om natten, og jeg får ikke tie. 
19:22:4: Og du er dog hellig, du som troner over Israels lovsanger. 
19:22:5: Til dig satte våre fedre sin lit; de satte sin lit til dig, og du utfridde dem. 
19:22:6: Til dig ropte de, og de blev reddet; til dig satte de sin lit, og de blev ikke til skamme. 
19:22:7: Men jeg er en orm og ikke en mann, menneskers spott og folks forakt. 
19:22:8: Alle de som ser mig, spotter mig, vrenger munnen, ryster på hodet og sier: 
19:22:9: Sett din vei i Herrens hånd! Han skal redde ham, han skal utfri ham, siden han har behag i ham. 
19:22:10: Ja, du er den som drog mig frem av mors liv, som lot mig hvile trygt ved min mors bryst. 
19:22:11: På dig er jeg kastet fra mors liv; fra min mors skjød er du min Gud. 
19:22:12: Vær ikke langt borte fra mig! for trengselen er nær, og det er ingen hjelper. 
19:22:13: Sterke okser omringer mig, Basans okser kringsetter mig. 
19:22:14: De spiler op sin munn imot mig som en sønderrivende og brølende løve. 
19:22:15: Jeg er utøst som vann, og alle mine ben skiller sig at; mitt hjerte er som voks, smeltet midt i mitt liv. 
19:22:16: Min kraft er optørket som et potteskår, og min tunge henger fast ved mine gommer, og i dødens støv legger du mig. 
19:22:17: For hunder omringer mig, de ondes hop kringsetter mig; de har gjennemboret mine hender og mine føtter. 
19:22:18: Jeg kan telle alle mine ben; de ser til, de ser på mig med lyst. 
19:22:19: De deler mine klær mellem sig og kaster lodd om min kjortel. 
19:22:20: Men du? Herre, vær ikke langt borte, du min styrke, skynd dig å hjelpe mig! 
19:22:21: Redd min sjel fra sverdet, mitt eneste* fra hunders vold! # <* d.e. min sjel.> 
19:22:22: Frels mig fra løvens gap, og fra villoksenes horn - du bønnhører mig! 
19:22:23: Jeg vil kunngjøre ditt navn for mine brødre, midt i menigheten vil jeg love dig. 
19:22:24: I som frykter Herren, lov ham, all Jakobs ætt, ær ham, og frykt for ham, all Israels ætt! 
19:22:25: For han har ikke foraktet og ikke avskydd den elendiges elendighet og ikke skjult sitt åsyn for ham; men da han ropte til ham, hørte han. 
19:22:26: Fra dig utgår min pris i en stor forsamling; mine løfter vil jeg holde for deres øine som frykter ham. 
19:22:27: De saktmodige skal ete og bli mette; de som søker Herren, skal love ham; eders hjerte leve til evig tid! 
19:22:28: Alle jordens ender skal komme det i hu og vende om til Herren, og alle folkenes slekter skal tilbede for ditt åsyn. 
19:22:29: For riket hører Herren til, og han hersker over folkene. 
19:22:30: Alle jordens rikmenn skal ete og tilbede; for hans åsyn skal alle de bøie sig som stiger ned i støvet, og den som ikke kan holde sin sjel i live. 
19:22:31: Efterkommerne skal tjene ham, der skal fortelles om Herren til efterslekten. 
19:22:32: De skal komme og kunngjøre hans rettferdighet for det folk som blir født, at han har gjort det. 
19:23:1: En salme av David. Herren er min hyrde, mig fattes intet. 
19:23:2: Han lar mig ligge i grønne enger, han leder mig til hvilens vann. 
19:23:3: Han vederkveger min sjel, han fører mig på rettferdighets stier for sitt navns skyld. 
19:23:4: Om jeg enn skulde vandre i dødsskyggens dal, frykter jeg ikke for ondt; for du er med mig, din kjepp og din stav de trøster mig. 
19:23:5: Du dekker bord for mig like for mine fienders øine, du salver mitt hode med olje; mitt beger flyter over. 
19:23:6: Bare godt og miskunnhet skal efterjage mig alle mitt livs dager, og jeg skal bo i Herrens hus gjennem lange tider. 
19:24:1: Av David; en salme. Jorden hører Herren til - og alt det som fyller den, jorderike og de som bor der. 
19:24:2: For han har grunnlagt den på hav og stilt den fast på strømmer. 
19:24:3: Hvem skal stige op på Herrens berg, og hvem skal stå på hans hellige sted? 
19:24:4: Den som har uskyldige hender og et rent hjerte, som ikke har vendt sin hu til løgn og ikke har svoret falskelig. 
19:24:5: Han skal motta velsignelse fra Herren og rettferdighet fra sin frelses Gud. 
19:24:6: Dette er deres ætt som spør efter ham, de som søker ditt åsyn, Jakobs barn. Sela. 
19:24:7: Løft, I porter, eders hoder, og løft eder, I evige dører, så herlighetens konge kan dra inn! 
19:24:8: Hvem er den herlighetens konge? Herren, sterk og veldig, Herren veldig i strid. 
19:24:9: Løft, I porter, eders hoder, og løft eder, I evige dører, så herlighetens konge kan dra inn! 
19:24:10: Hvem er den herlighetens konge? Herren, hærskarenes Gud, han er herlighetens konge. Sela. 
19:25:1: Av David. Til dig, Herre, løfter jeg min sjel. 
19:25:2: Min Gud, til dig har jeg satt min lit; la mig ikke bli til skamme, la ikke mine fiender fryde sig over mig! 
19:25:3: Ja, ingen av dem som bier på dig, skal bli til skamme; de skal bli til skamme som er troløse uten årsak. 
19:25:4: Herre, la mig kjenne dine veier, lær mig dine stier! 
19:25:5: Led mig frem i din trofasthet og lær mig! for du er min frelses Gud, på dig bier jeg hele dagen. 
19:25:6: Herre, kom din barmhjertighet i hu og din miskunnhets gjerninger! for de er fra evighet. 
19:25:7: Kom ikke min ungdoms synder og mine misgjerninger i hu! Kom mig i hu efter din miskunnhet for din godhets skyld, Herre! 
19:25:8: Herren er god og rettvis; derfor lærer han syndere veien. 
19:25:9: Han leder de saktmodige i det som rett er, og lærer de saktmodige sin vei. 
19:25:10: Alle Herrens stier er miskunnhet og trofasthet imot dem som holder hans pakt og hans vidnesbyrd. 
19:25:11: For ditt navns skyld, Herre, forlat mig min misgjerning! for den er stor. 
19:25:12: Hvem er den mann som frykter Herren? Ham lærer han den vei han skal velge. 
19:25:13: Hans sjel skal stadig ha det godt, og hans avkom skal arve landet. 
19:25:14: Herren har fortrolig samfund med dem som frykter ham, og hans pakt skal bli dem kunngjort. 
19:25:15: Mine øine er alltid vendt til Herren, for han drar mine føtter ut av garnet. 
19:25:16: Vend dig til mig og vær mig nådig! for jeg er enslig og elendig. 
19:25:17: Mitt hjertes angst har de* gjort stor; før mig ut av mine trengsler! # <* d.e. mine fiender.> 
19:25:18: Se min elendighet og min nød, og forlat mig alle mine synder! 
19:25:19: Se mine fiender, de er mange, og de hater mig med urettferdig hat. 
19:25:20: Bevar min sjel og redd mig, la mig ikke bli til skamme! for jeg tar min tilflukt til dig. 
19:25:21: La uskyld og opriktighet verge mig! for jeg bier på dig. 
19:25:22: Forløs, Gud, Israel av alle dets trengsler! 
19:26:1: Av David. Hjelp mig til min rett, Herre! for jeg har vandret i min uskyld, og på Herren stoler jeg uten å vakle. 
19:26:2: Prøv mig, Herre, og gransk mig, ransak mine nyrer og mitt hjerte! 
19:26:3: For din miskunnhet er for mine øine, og jeg vandrer i din trofasthet. 
19:26:4: Jeg sitter ikke hos løgnere og kommer ikke sammen med listige folk. 
19:26:5: Jeg hater de ondes forsamling og sitter ikke hos de ugudelige. 
19:26:6: Jeg tvetter mine hender i uskyld og vil gjerne ferdes om ditt alter, Herre, 
19:26:7: for å synge med lovsangs røst og fortelle alle dine undergjerninger. 
19:26:8: Herre, jeg elsker ditt huses bolig, det sted hvor din herlighet bor. 
19:26:9: Rykk ikke min sjel bort med syndere eller mitt liv med blodgjerrige menn, 
19:26:10: som har skam i sine hender og sin høire hånd full av bestikkelse! 
19:26:11: Men jeg vandrer i min uskyld; forløs mig og vær mig nådig! 
19:26:12: Min fot står på jevn jord; i forsamlingene skal jeg love Herren. 
19:27:1: Av David. Herren er mitt lys og min frelse, for hvem skal jeg frykte? Herren er mitt livs vern, for hvem skal jeg reddes? 
19:27:2: Når ugjerningsmenn nærmer sig imot mig for å opsluke mig, mine motstandere og mine fiender, så snubler og faller de selv. 
19:27:3: Om en hær leirer sig imot mig, frykter ikke mitt hjerte; om krig reiser sig imot mig, enda er jeg trygg. 
19:27:4: Én ting har jeg bedt Herren om, det stunder jeg efter, at jeg må bo i Herrens hus alle mitt livs dager for å skue Herrens liflighet og grunde i hans tempel. 
19:27:5: For han gjemmer mig i sin hytte på den onde dag, han skjuler mig i sitt telts skjul; på en klippe fører han mig op. 
19:27:6: Og nu skal mitt hode opløfte sig over mine fiender rundt omkring mig, og jeg vil ofre jubeloffere i hans telt, jeg vil prise og lovsynge Herren. 
19:27:7: Hør, Herre, høit roper jeg, og vær mig nådig og svar mig! 
19:27:8: Mitt hjerte holder frem for dig [ditt ord]: Søk mitt åsyn! Herre, jeg søker ditt åsyn. 
19:27:9: Skjul ikke ditt åsyn for mig, bortstøt ikke i vrede din tjener! Min hjelp har du vært. Kast mig ikke bort og forlat mig ikke, min frelses Gud! 
19:27:10: For min far og min mor har forlatt mig, men Herren tar mig op. 
19:27:11: Lær mig, Herre, din vei, og led mig på den jevne sti for mine fienders skyld! 
19:27:12: Overgi mig ikke til mine fienders mordlyst! for falske vidner står op imot mig, og menn som fnyser av vold. 
19:27:13: O, dersom jeg ikke trodde å skulle se Herrens godhet i de levendes land! - 
19:27:14: Bi på Herren, vær ved godt mot, og ditt hjerte være sterkt, ja, bi på Herren! 
19:28:1: Av David. Til dig, Herre, roper jeg. Min klippe, vær ikke døv imot mig, forat jeg ikke, om du tier til mig, skal bli lik dem som farer ned i graven! 
19:28:2: Hør mine inderlige bønners røst, når jeg roper til dig, når jeg opløfter mine hender til ditt hellige kor! 
19:28:3: Riv mig ikke bort med de ugudelige og med dem som gjør urett, som taler fred med sin næste, enda der er ondt i deres hjerte! 
19:28:4: Gi dem efter deres gjerning og efter deres onde adferd! Gi dem efter deres henders gjerning, gjengjeld dem hvad de har gjort! 
19:28:5: For de akter ikke på hvad Herren gjør, eller på hans henders gjerning; han skal nedbryte dem og ikke opbygge dem. 
19:28:6: Lovet være Herren, fordi han har hørt mine inderlige bønners røst! 
19:28:7: Herren er min styrke og mitt skjold; til ham har mitt hjerte satt sin lit, og jeg blir hjulpet; derfor fryder mitt hjerte sig, og jeg vil prise ham med min sang. 
19:28:8: Herren er deres* styrke, og han er et frelsens vern for sin salvede. # <* d.e. sitt folks.> 
19:28:9: Frels ditt folk og velsign din arv, og fø dem og bær dem til evig tid! 
19:29:1: En salme av David. Gi Herren, I Guds sønner, gi Herren ære og makt! 
19:29:2: Gi Herren hans navns ære, tilbed Herren i hellig prydelse! 
19:29:3: Herrens røst er over vannene, ærens Gud tordner, Herren over de store vann. 
19:29:4: Herrens røst lyder med kraft, Herrens røst med herlighet. 
19:29:5: Herrens røst bryter sedrer, Herren sønderbryter Libanons sedrer, 
19:29:6: og han får dem til å hoppe som en kalv, Libanon og Sirjon som en ung villokse. 
19:29:7: Herrens røst slynger ut kløvede ildsluer. 
19:29:8: Herrens røst får ørkenen til å beve, Herren får Kades' ørken til å beve. 
19:29:9: Herrens røst får hindene til å føde og gjør skogene bare, og i hans tempel sier alt: Ære! 
19:29:10: Herren tronte på vannflommens tid, og Herren troner som konge evindelig. 
19:29:11: Herren skal gi sitt folk kraft, Herren skal velsigne sitt folk med fred. 
19:30:1: En salme, en sang ved husets innvielse, av David. 
19:30:2: Jeg vil ophøie dig, Herre, for du har dradd mig op og ikke latt mine fiender glede sig over mig. 
19:30:3: Herre min Gud! jeg ropte til dig, og du helbredet mig. 
19:30:4: Herre! du har ført min sjel op av dødsriket, du har kalt mig til live fra dem som farer ned i graven. 
19:30:5: Lovsyng Herren, I hans fromme, og pris hans hellige navn! 
19:30:6: For et øieblikk varer hans vrede, en levetid hans nåde; om aftenen kommer gråt som gjest, og om morgenen er det frydesang. 
19:30:7: Men jeg sa i min trygghet: Jeg skal ikke rokkes evindelig. 
19:30:8: Herre! ved din nåde hadde du grunnfestet mitt fjell; du skjulte ditt åsyn, da blev jeg forferdet. 
19:30:9: Til dig, Herre, ropte jeg, og til Herren bad jeg ydmykelig: 
19:30:10: Hvad vinning er der i mitt blod, i at jeg farer ned i graven? Mon støvet vil prise dig, vil det forkynne din trofasthet? 
19:30:11: Hør, Herre, og vær mig nådig! Herre, vær min hjelper! 
19:30:12: Du omskiftet min klage til dans for mig, du løste mine sørgeklær av mig og omgjordet mig med glede, 
19:30:13: forat min ære skal lovsynge dig og ikke tie. Herre min Gud! jeg vil prise dig evindelig. 
19:31:1: Til sangmesteren; en salme av David. 
19:31:2: Til dig, Herre, tar jeg min tilflukt. La mig aldri i evighet bli til skamme, frels mig ved din rettferdighet! 
19:31:3: Bøi ditt øre til mig, skynd dig og redd mig, vær mig et klippevern, en borg til å frelse mig! 
19:31:4: For du er min klippe og min borg, og for ditt navns skyld vil du føre og lede mig. 
19:31:5: Du vil føre mig ut av garnet som de lønnlig har lagt for mig; for du er mitt vern. 
19:31:6: I din hånd overgir jeg min ånd; du forløser mig, Herre, du trofaste Gud. 
19:31:7: Jeg hater dem som akter på tomme avguder, men jeg, jeg setter min lit til Herren. 
19:31:8: Jeg vil fryde og glede mig over din miskunnhet, at du har sett min elendighet, aktet på min sjels trengsler; 
19:31:9: du har ikke overgitt mig i fiendehånd, du har satt mine føtter på et rummelig sted. 
19:31:10: Vær mig nådig, Herre! for jeg er i trengsel; borttæret av sorg er mitt øie, min sjel og mitt legeme. 
19:31:11: For mitt liv svinner bort med sorg, og mine år med sukk; min kraft er brutt for min misgjernings skyld, og mine ben er uttæret. 
19:31:12: For alle mine fienders skyld er jeg blitt til stor spott for mine naboer og til en skrekk for mine kjenninger; de som ser mig på gaten, flyr for mig. 
19:31:13: Jeg er glemt og ute av hjertet som en død, jeg er blitt som et ødelagt kar. 
19:31:14: For jeg hører baktalelse av mange, redsel fra alle kanter, idet de rådslår sammen imot mig; de lurer på å ta mitt liv. 
19:31:15: Men jeg, jeg setter min lit til dig, Herre! Jeg sier: Du er min Gud. 
19:31:16: I din hånd er mine tider*; redd mig av mine fienders hånd og fra mine forfølgere! # <* 1KR 29, 30. SLM 139, 16.> 
19:31:17: La ditt åsyn lyse over din tjener, frels mig ved din miskunnhet! 
19:31:18: Herre, la mig ikke bli til skamme! for jeg kaller på dig. La de ugudelige bli til skamme, bli tause i dødsriket! 
19:31:19: La løgnens leber bli målløse, som taler frekt imot den rettferdige med overmot og forakt! 
19:31:20: Hvor stor din godhet er, som du har gjemt for dem som frykter dig, som du har vist mot dem som tar sin tilflukt til dig, for menneskenes barns øine! 
19:31:21: Du skjuler dem i ditt åsyns skjul for menneskers sammensvergelser, du gjemmer dem i en hytte for tungers kiv. 
19:31:22: Lovet være Herren! for han har underlig vist sin miskunnhet imot mig i en fast by. 
19:31:23: Og jeg, jeg sa i min angst: Jeg er revet bort fra dine øine. Dog hørte du mine inderlige bønners røst, da jeg ropte til dig. 
19:31:24: Elsk Herren, alle I hans fromme! Herren vokter de trofaste og gjengjelder rikelig den som farer overmodig frem. 
19:31:25: Vær ved godt mot, og eders hjerte være sterkt, alle I som venter på Herren! 
19:32:1: Av David; en læresalme. Salig er den hvis overtredelse er forlatt, hvis synd er skjult. 
19:32:2: Salig er det menneske som Herren ikke tilregner misgjerning, og i hvis ånd det ikke er svik. 
19:32:3: Da jeg tidde, blev mine ben borttæret, idet jeg stønnet hele dagen. 
19:32:4: For dag og natt lå din hånd tungt på mig, min livssaft svant som ved sommerens tørke. Sela. 
19:32:5: Jeg bekjente min synd for dig og skjulte ikke min skyld, jeg sa: Jeg vil bekjenne mine misgjerninger for Herren. Og du tok bort min syndeskyld. Sela. 
19:32:6: Derfor bede hver from til dig den tid du er å finne! Visselig, når store vannflommer kommer, til ham skal de ikke nå. 
19:32:7: Du er mitt skjul, du vokter mig for trengsel; med frelses jubel omgir du mig. Sela. 
19:32:8: Jeg vil lære dig og vise dig den vei du skal vandre; jeg vil gi dig råd med mitt øie. 
19:32:9: Vær ikke som hest og muldyr, som ikke har forstand! Deres smykke er tømme og bissel til å tvinge dem med; de vil ikke komme nær til dig. 
19:32:10: Den ugudelige har mange piner, men den som setter sin lit til Herren, ham omgir han med miskunnhet. 
19:32:11: Gled eder i Herren og fryd eder, I rettferdige, og juble, alle I opriktige av hjertet! 
19:33:1: Juble, I rettferdige, i Herren! For de opriktige sømmer sig lovsang. 
19:33:2: Pris Herren med citar, lovsyng ham til tistrenget harpe! 
19:33:3: Syng en ny sang for ham, spill liflig med frydesang! 
19:33:4: For Herrens ord er sant, og all hans gjerning er trofast. 
19:33:5: Han elsker rettferdighet og rett; jorden er full av Herrens miskunnhet. 
19:33:6: Himlene er skapt ved Herrens ord, og all deres hær ved hans munns ånde. 
19:33:7: Han samler havets vann som en dynge, han legger de dype vann i forrådshus. 
19:33:8: All jorden frykte for Herren, for ham beve alle de som bor på jorderike! 
19:33:9: For han talte, og det skjedde; han bød, og det stod der. 
19:33:10: Herren omstøter hedningenes råd, han gjør folkenes tanker til intet. 
19:33:11: Herrens råd står fast evindelig, hans hjertes tanker fra slekt til slekt. 
19:33:12: Salig er det folk hvis Gud Herren er, det folk han har utvalgt til sin arv. 
19:33:13: Fra himmelen skuer Herren ned han ser alle menneskenes barn. 
19:33:14: Fra det sted hvor han bor, ser han ned til alle dem som bor på jorden, 
19:33:15: han som har skapt deres hjerter alle sammen, han som gir akt på alle deres gjerninger. 
19:33:16: En konge frelses ikke ved sin store makt, en helt reddes ikke ved sin store kraft. 
19:33:17: Hesten er ikke å stole på til frelse, og med sin store styrke redder den ikke. 
19:33:18: Se, Herrens øie ser til dem som frykter ham, som bier på hans miskunnhet, 
19:33:19: for å utfri deres sjel fra døden og holde dem i live i hungersnød. 
19:33:20: Vår sjel bier på Herren; han er vår hjelp og vårt skjold. 
19:33:21: For i ham fryder vårt hjerte sig, fordi vi setter vår lit til hans hellige navn. 
19:33:22: Din miskunnhet, Herre, være over oss, således som vi håper på dig! 
19:34:1: Av David, da han tedde sig som vanvittig for Abimelek, og denne jog ham fra sig, og han gikk bort. 
19:34:2: Jeg vil love Herren til enhver tid, hans pris skal alltid være i min munn. 
19:34:3: Min sjel skal rose sig av Herren; de saktmodige skal høre det og glede sig. 
19:34:4: Pris Herrens storhet med mig, og la oss sammen ophøie hans navn! 
19:34:5: Jeg søkte Herren, og han svarte mig, og han fridde mig fra alt det som forferdet mig. 
19:34:6: De så op til ham og strålte av glede, og deres åsyn rødmet aldri av skam. 
19:34:7: Denne elendige ropte, og Herren hørte, og han frelste ham av alle hans trengsler. 
19:34:8: Herrens engel leirer sig rundt omkring dem som frykter ham, og han utfrir dem. 
19:34:9: Smak og se at Herren er god! Salig er den mann som tar sin tilflukt til ham. 
19:34:10: Frykt Herren, I hans hellige! For intet fattes dem som frykter ham. 
19:34:11: De unge løver lider nød og hungrer, men dem som søker Herren, fattes ikke noget godt. 
19:34:12: Kom, barn, hør mig! Jeg vil lære eder Herrens frykt. 
19:34:13: Hvem er den mann som har lyst til liv, som ønsker sig dager til å se lykke? 
19:34:14: Hold din tunge fra ondt og dine leber fra å tale svik! 
19:34:15: Vik fra ondt og gjør godt, søk fred og jag efter den! 
19:34:16: Herrens øine er vendt til de rettferdige, og hans ører til deres rop. 
19:34:17: Herrens åsyn er imot dem som gjør ondt, for å utrydde deres ihukommelse av jorden. 
19:34:18: Hine roper, og Herren hører, og av alle deres trengsler utfrir han dem. 
19:34:19: Herren er nær hos dem som har et sønderbrutt hjerte, og han frelser dem som har en sønderknust ånd. 
19:34:20: Mange er den rettferdiges ulykker, men Herren utfrir ham av dem alle. 
19:34:21: Han tar vare på alle hans ben, ikke ett av dem blir sønderbrutt. 
19:34:22: Ulykke dreper den ugudelige, og de som hater den rettferdige, dømmes skyldige. 
19:34:23: Herren forløser sine tjeneres sjel, og ingen av dem som tar sin tilflukt til ham, dømmes skyldig. 
19:35:1: Av David. Trett, Herre, med dem som tretter med mig! Strid mot dem som strider mot mig! 
19:35:2: Grip skjold og verge og reis dig til hjelp for mig! 
19:35:3: Dra spydet frem og steng veien for mine forfølgere! Si til min sjel: Jeg er din frelse! 
19:35:4: La dem blues og bli til skamme som står mig efter livet! La dem vike tilbake med skam som tenker ondt imot mig! 
19:35:5: La dem bli som agner for vinden, og Herrens engel støte dem bort! 
19:35:6: La deres vei bli mørk og glatt, og Herrens engel forfølge dem! 
19:35:7: For uten årsak har de lønnlig gjort i stand sin garngrav* for mig, uten årsak har de gravd en grav for mitt liv. # <* d.e. med garn dekket grav.> 
19:35:8: La ødeleggelse komme over ham, uten at han merker det, og la hans garn som han lønnlig har utlagt, fange ham, la ham falle i det til sin ødeleggelse! 
19:35:9: Da skal min sjel glede sig i Herren, fryde sig i hans frelse; 
19:35:10: alle mine ben skal si: Herre, hvem er som du, du som frir den elendige fra den som er ham for sterk, og den elendige og fattige fra den som plyndrer ham? 
19:35:11: Der opstår urettferdige vidner, de spør mig om det jeg ikke vet. 
19:35:12: De gjengjelder mig godt med ondt; min sjel er forlatt. 
19:35:13: Og jeg, jeg klædde mig i sørgeklær, da de var syke; jeg plaget min sjel med faste, og min bønn vendte tilbake til min barm*. # <* d.e. jeg bad med hodet bøid mot mitt bryst.> 
19:35:14: Jeg gikk omkring, som om det var min venn, min bror; jeg gikk nedbøiet i sørgeklær som en som sørger over sin mor. 
19:35:15: Men nu da jeg vakler, gleder de sig og flokker sig sammen; skarns-folk flokker sig om mig uten at jeg visste det; de sønderriver* og hviler ikke. # <* d.e. de søker å frarøve mig mitt gode navn og rykte.> 
19:35:16: Som skamløse som spotter for et stykke brød, skjærer de tenner imot mig. 
19:35:17: Herre, hvor lenge vil du se til? Fri min sjel ut fra deres ødeleggelser, mitt eneste fra de unge løver. 
19:35:18: Jeg vil prise dig i en stor forsamling, love dig blandt meget folk. 
19:35:19: La ikke dem glede sig over mig, som uten grunn er mine fiender! La ikke dem som hater mig uten årsak, blinke med øiet! 
19:35:20: For de taler ikke fred, men optenker svik mot de stille i landet. 
19:35:21: Og de lukker sin munn vidt op imot mig, de sier: Ha, ha! Der ser vårt øie! 
19:35:22: Du ser det, Herre, ti ikke! Herre, vær ikke langt borte fra mig! 
19:35:23: Våkn op og bli våken for å gi mig rett, min Gud og Herre, for å føre min sak! 
19:35:24: Døm mig efter din rettferdighet, Herre min Gud, og la dem ikke glede sig over mig! 
19:35:25: La dem ikke si i sitt hjerte: Ha! Efter ønske! La dem ikke si: Vi har opslukt ham! 
19:35:26: La alle dem få skam og bli til skamme som gleder sig ved min ulykke! La dem som ophøier sig over mig, klæs i skam og skjensel! 
19:35:27: La dem juble og glede sig som unner mig min rett, og la dem alltid si: Høilovet være Herren, som unner sin tjener at det går ham vel! 
19:35:28: Da skal min tunge synge om din rettferdighet, hele dagen om din pris. 
19:36:1: Til sangmesteren; av Herrens tjener, av David. 
19:36:2: Syndens ord til den ugudelige er i mitt hjertes innerste*. Det er ikke gudsfrykt for hans øine. # <* d.e. jeg må tenke på hvorledes synden kunngjør den ugudelige sin vilje.> 
19:36:3: For en smigrer ham i hans øine ved å finne hans synd, ved å hate ham*. # <* d.e. det smigrer ham at man kjenner hans synd og hater ham derfor.> 
19:36:4: Hans munns ord er urett og svik; han har latt av å fare viselig frem, å gjøre godt. 
19:36:5: Han optenker urett på sitt leie, han stiller sig på en vei som ikke er god; det onde hater han ikke. 
19:36:6: Herre! til himmelen når din miskunnhet, din trofasthet inntil skyene. 
19:36:7: Din rettferdighet er som veldige fjell, dine dommer er et stort dyp; mennesker og dyr frelser du, Herre! 
19:36:8: Hvor kostelig er din miskunnhet, Gud! Menneskenes barn søker ly i dine vingers skygge. 
19:36:9: De mettes overflødig av ditt huses fedme, og av dine gleders strøm gir du dem å drikke. 
19:36:10: For hos dig er livets kilde, i ditt lys ser vi lys. 
19:36:11: La din miskunnhet vare ved for dem som kjenner dig, og din rettferdighet for de opriktige av hjertet. 
19:36:12: La ikke den overmodiges fot komme over mig og ikke de ugudeliges hånd jage mig bort! 
19:36:13: Der faller de som gjør urett; de blir støtt ned og kan ikke reise sig. 
19:37:1: Av David. La ikke din vrede optendes over de onde, bli ikke harm over dem som gjør urett! 
19:37:2: For som gresset blir de hastig avskåret, og som grønne urter visner de bort. 
19:37:3: Sett din lit til Herren og gjør godt, bo i landet og legg vinn på trofasthet! 
19:37:4: Og gled dig i Herren! Så skal han gi dig hvad ditt hjerte attrår. 
19:37:5: Sett din vei i Herrens hånd og stol på ham! Han skal gjøre det; 
19:37:6: han skal la din rettferdighet gå frem som schmelken og din rett som middagens schmelk. 
19:37:7: Vær stille for Herren og vent på ham! La ikke din vrede optendes over den som har lykke på sin vei, over den mann som uttenker onde råd. 
19:37:8: Lat av fra vrede og la harme fare, la ikke din vrede optendes! Det fører bare til det som ondt er. 
19:37:9: For de onde skal utryddes, men de som bier efter Herren, skal arve landet. 
19:37:10: Og om en liten stund, så er den ugudelige ikke mere, og akter du på hans sted, så er han borte. 
19:37:11: Men de saktmodige skal arve landet og glede sig ved megen fred. 
19:37:12: Den ugudelige optenker ondt imot den rettferdige og skjærer tenner imot ham. 
19:37:13: Herren ler av ham; for han ser at hans dag kommer. 
19:37:14: De ugudelige drar sverdet og spenner sin bue for å felle den elendige og fattige og slå dem ihjel som vandrer opriktig. 
19:37:15: Deres sverd skal komme i deres eget hjerte, og deres buer skal sønderbrytes. 
19:37:16: Bedre er det lille som den rettferdige har, enn mange ugudeliges rikdom. 
19:37:17: For de ugudeliges armer skal sønderbrytes, men Herren støtter de rettferdige. 
19:37:18: Herren kjenner de ulasteliges dager, og deres arv skal bli til evig tid. 
19:37:19: De skal ikke bli til skamme i den onde tid, og i hungerens dager skal de mettes. 
19:37:20: For de ugudelige skal gå til grunne og Herrens fiender som engenes blomsterskrud; de skal forsvinne, som røk skal de forsvinne. 
19:37:21: Den ugudelige låner og betaler ikke, men den rettferdige forbarmer sig og gir. 
19:37:22: For de han* velsigner, skal arve landet, men de han forbanner, skal utryddes. # <* Gud.> 
19:37:23: Herren gjør en manns gang fast, og han har velbehag i hans vei. 
19:37:24: Når han snubler, faller han ikke til jorden; for Herren støtter hans hånd. 
19:37:25: Jeg har vært ung og er blitt gammel, men ikke har jeg sett den rettferdige forlatt eller hans avkom søke efter brød. 
19:37:26: Den hele dag forbarmer han sig og låner ut, og hans avkom blir velsignet. 
19:37:27: Vik fra ondt og gjør godt! Så skal du bli boende* til evig tid. # <* SLM 37, 3.> 
19:37:28: For Herren elsker rett og forlater ikke sine fromme; til evig tid blir de bevart. Men de ugudeliges avkom utryddes. 
19:37:29: De rettferdige skal arve landet og bo i det evindelig. 
19:37:30: Den rettferdiges munn taler visdom, og hans tunge sier hvad rett er. 
19:37:31: Hans Guds lov er i hans hjerte, hans trin vakler ikke. 
19:37:32: Den ugudelige lurer på den rettferdige og søker å drepe ham; 
19:37:33: Herren overlater ham ikke i hans hånd, og fordømmer ham ikke når han blir dømt. 
19:37:34: Bi på Herren og ta vare på hans vei! Så skal han ophøie dig til å arve landet; du skal se på at de ugudelige utryddes. 
19:37:35: Jeg så en ugudelig som var veldig og utbredte sig som et grønt tre som ikke er flyttet; 
19:37:36: men han forsvant, og se, han var ikke mere, og jeg søkte efter ham, men han fantes ikke. 
19:37:37: Akt på den ulastelige og se på den opriktige! for fredens mann har en fremtid; 
19:37:38: men overtrederne skal tilintetgjøres alle sammen, de ugudeliges fremtid skal avskjæres. 
19:37:39: Og de rettferdiges frelse kommer fra Herren, deres sterke vern i nødens tid. 
19:37:40: Og Herren hjelper dem og utfrir dem, han utfrir dem fra de ugudelige og frelser dem, fordi de har tatt sin tilflukt til ham. 
19:38:1: En salme av David; til ihukommelse. 
19:38:2: Herre, straff mig ikke i din vrede, og tukt mig ikke i din harme! 
19:38:3: For dine piler har rammet mig, og din hånd er falt tungt på mig. 
19:38:4: Det er intet friskt i mitt kjød for din vredes skyld, det er ingen fred i mine ben for min synds skyld. 
19:38:5: For mine misgjerninger går over mitt hode, som en tung byrde er de mig for tunge. 
19:38:6: Mine bylder lukter ille, de råtner for min dårskaps skyld. 
19:38:7: Jeg er kroket, aldeles nedbøiet; hele dagen går jeg i sørgeklær. 
19:38:8: For mine lender er fulle av brand, og det er intet friskt i mitt kjød. 
19:38:9: Jeg er kold og stiv og aldeles knust, jeg hyler for mitt hjertes stønnen. 
19:38:10: Herre, for ditt åsyn er all min lengsel, og mitt sukk er ikke skjult for dig. 
19:38:11: Mitt hjerte slår heftig, min kraft har sviktet mig, og mine øines schmelk, endog det er borte for mig. 
19:38:12: Mine venner og mine frender holder sig i avstand fra min plage, og mine nærmeste står langt borte. 
19:38:13: Og de som står mig efter livet, setter snarer, og de som søker min ulykke, taler om undergang, og på svik tenker de den hele dag. 
19:38:14: Og jeg er som en døv, jeg hører ikke, og som en stum, som ikke later op sin munn. 
19:38:15: Ja, jeg er som en mann som ikke hører, og som ikke har motsigelse i sin munn. 
19:38:16: For til dig, Herre, står mitt håp; du skal svare, Herre min Gud! 
19:38:17: For jeg sier: De vil ellers glede sig over mig; når min fot vakler, ophøier de sig over mig. 
19:38:18: For jeg er nær ved å falle, og min smerte er alltid for mig. 
19:38:19: For jeg bekjenner min misgjerning, jeg sørger over min synd. 
19:38:20: Og mine fiender lever, er mektige, og mange er de som hater mig uten årsak. 
19:38:21: Og de som gjengjelder godt med ondt, står mig imot, fordi jeg jager efter det gode. 
19:38:22: Forlat mig ikke, Herre! Min Gud, vær ikke langt borte fra mig! 
19:38:23: Skynd dig å hjelpe mig, Herre, min frelse! 
19:39:1: Til sangmesteren, til Jedutun; en salme av David. 
19:39:2: Jeg sa: Jeg vil vokte mine veier, så jeg ikke synder med min tunge; jeg vil legge munnkurv på min munn, så lenge den ugudelige ennu er for mine øine. 
19:39:3: Jeg blev taus og var aldeles stille, jeg tidde uten gagn, og min smerte blev oprørt. 
19:39:4: Mitt hjerte blev hett inneni mig, ved min grublen optendtes ild; - jeg talte med min tunge: 
19:39:5: La mig vite, Herre, min ende, og mine dagers mål, hvad det er! La mig få vite hvad tid jeg skal bort! 
19:39:6: Se, som en håndsbredd har du satt mine dager, og min livstid er som intet for dig; visselig, bare tomhet er hvert menneske, hvor fast han enn står. Sela. 
19:39:7: Bare som et skyggebillede vandrer mannen, bare tomhet er deres uro; han dynger op og vet ikke hvem som skal samle det inn. 
19:39:8: Og nu, hvad håper jeg på Herre? - Mitt håp står til dig. 
19:39:9: Frels mig fra alle mine overtredelser, gjør mig ikke til spott for dåren! 
19:39:10: Jeg tier, jeg later ikke op min munn; for du har gjort det. 
19:39:11: Ta bort fra mig din plage! For din hånds slag er jeg blitt til intet. 
19:39:12: Tukter du en mann med straff for misgjerning, da fortærer du hans herlighet likesom møll; bare tomhet er hvert menneske. Sela. 
19:39:13: Hør min bønn, Herre, og vend øret til mitt rop, ti ikke til min gråt! for jeg er en fremmed hos dig, en gjest som alle mine fedre. 
19:39:14: Se bort fra mig, så mitt åsyn må bli opklaret, før jeg går herfra og er ikke mere! 
19:40:1: Til sangmesteren; av David; en salme. 
19:40:2: Jeg bidde på Herren; da bøide han sig til mig og hørte mitt rop. 
19:40:3: Og han drog mig op av fordervelsens grav, av det dype dynd, og han satte mine føtter på en klippe, han gjorde mine trin faste. 
19:40:4: Og han la i min munn en ny sang, en lovsang for vår Gud. Mange ser det og frykter og setter sin lit til Herren. 
19:40:5: Salig er den mann som setter sin lit til Herren og ikke vender sig til overmodige og til dem som bøier av til løgn. 
19:40:6: Herre min Gud, du har gjort dine undergjerninger og dine tanker mangfoldige imot oss; intet er å ligne med dig; vil jeg kunngjøre og utsi dem, da er de flere enn at de kan telles. 
19:40:7: Til slaktoffer og matoffer har du ikke lyst - du har boret ører på mig - brennoffer og syndoffer krever du ikke. 
19:40:8: Da sa jeg: Se, jeg kommer; i bokrullen er mig foreskrevet*; # <* nemlig hvad jeg skal gjøre.> 
19:40:9: å gjøre din vilje, min Gud, er min lyst, og din lov er i mitt hjerte. 
19:40:10: Jeg har budskap om rettferdighet i en stor forsamling; se, jeg lukket ikke mine leber; Herre, du vet det. 
19:40:11: Din rettferdighet skjulte jeg ikke i mitt hjerte, jeg kunngjorde din trofasthet og din frelse; jeg dulgte ikke din miskunnhet og din sannhet for en stor forsamling. 
19:40:12: Du, Herre, vil ikke lukke din barmhjertighet for mig; din miskunnhet og din sannhet vil alltid vokte mig. 
19:40:13: For trengsler uten tall har omspent mig, mine misgjerninger har grepet mig, så jeg ikke kan se; de er flere enn hårene på mitt hode, og mitt hjerte har sviktet mig. 
19:40:14: La det behage dig, Herre, å utfri mig! Herre, skynd dig å hjelpe mig! 
19:40:15: La alle dem bli til skam og spott som står mig efter livet og vil rive det bort! La dem som ønsker min ulykke, vike tilbake og bli til skamme! 
19:40:16: La dem som sier til mig: Ha, ha! forferdes over sin skjensel. 
19:40:17: La alle dem som søker dig, fryde og glede sig i dig! La dem som elsker din frelse, alltid si: Høilovet være Herren! 
19:40:18: For jeg er elendig og fattig; Herren vil tenke på mig. Du er min hjelp og min frelser; min Gud, dryg ikke! 
19:41:1: Til sangmesteren; en salme av David. 
19:41:2: Salig er den som akter på den elendige; på den onde dag skal Herren frelse ham. 
19:41:3: Herren skal verge ham og holde ham i live; han skal bli lykksalig i landet, og du skal visselig ikke overgi ham til hans fienders mordlyst. 
19:41:4: Herren skal understøtte ham på sykesengen; hele hans leie forvandler du i hans sykdom. 
19:41:5: Jeg sier: Herre, vær mig nådig, helbred min sjel! for jeg har syndet imot dig. 
19:41:6: Mine fiender taler ondt om mig: Når skal han dø, og hans navn forgå? 
19:41:7: Og dersom en kommer for å se til mig, taler han falske ord; hans hjerte samler sig ondskap; han går ut og taler derom. 
19:41:8: Alle de som hater mig, hvisker sammen imot mig; de optenker imot mig det som er mig til skade: 
19:41:9: En ugjerning henger ved ham, og han som ligger der, skal ikke stå op mere. 
19:41:10: Også den mann som jeg hadde fred med, som jeg stolte på, som åt mitt brød, har løftet sin hæl imot mig. 
19:41:11: Men du, Herre, vær mig nådig og hjelp mig op! Så vil jeg gjengjelde dem. 
19:41:12: Derpå kjenner jeg at du har behag i mig, at min fiende ikke skal fryde sig over mig. 
19:41:13: Og mig holder du oppe i min uskyld og setter mig for ditt åsyn evindelig. 
19:41:14: Lovet være Herren, Israels Gud, fra evighet og til evighet! Amen, amen. 
19:42:1: Til sangmesteren; en læresalme av Korahs barn. 
19:42:2: Som en hjort skriker efter rinnende bekker, så skriker min sjel efter dig, Gud! 
19:42:3: Min sjel tørster efter Gud, efter den levende Gud; når skal jeg komme og trede frem for Guds åsyn? 
19:42:4: Mine tårer er min mat dag og natt, fordi man hele dagen sier til mig: Hvor er din Gud? 
19:42:5: Dette må jeg komme i hu og utøse min sjel i mig, hvorledes jeg drog frem i den tette hop, vandret med den til Guds hus med fryderop og lovsangs røst, en høitidsskare. 
19:42:6: Hvorfor er du nedbøiet, min sjel, og bruser i mig? Bi efter Gud! for jeg skal ennu prise ham for frelse fra hans åsyn. 
19:42:7: Min Gud! Min sjel er nedbøiet i mig; derfor kommer jeg dig i hu fra Jordans land og Hermons høider, fra det lille fjell*. # <* landet østenfor Jordan.> 
19:42:8: Vanndyp kaller på vanndyp ved duren av dine fossefall; alle dine brenninger og dine bølger går over mig. 
19:42:9: Om dagen sender Herren sin miskunnhet, og om natten er hans sang hos mig, bønn til mitt livs Gud. 
19:42:10: Jeg må si til Gud, min klippe: Hvorfor har du glemt mig? Hvorfor skal jeg gå i sørgeklær under fiendens trykk? 
19:42:11: Det er som om mine ben blev knust, når mine fiender håner mig, idet de hele dagen sier til mig: Hvor er din Gud? 
19:42:12: Hvorfor er du nedbøiet, min sjel, og hvorfor bruser du i mig? Bi efter Gud! for jeg skal ennu prise ham, mitt åsyns frelse og min Gud. 
19:43:1: Gi mig rett, Gud, og før min sak mot folk uten barmhjertighet, frels mig fra falske og urettferdige menn! 
19:43:2: For du er den Gud som er mitt vern. Hvorfor har du forkastet mig? Hvorfor skal jeg gå i sørgeklær under fiendens trykk? 
19:43:3: Send ditt lys og din sannhet, la dem lede mig, la dem føre mig til ditt hellige berg og til dine boliger, 
19:43:4: så jeg kan komme til Guds alter, til min fryds og gledes Gud, og prise dig på citar, Gud, min Gud! 
19:43:5: Hvorfor er du nedbøiet, min sjel, og hvorfor bruser du i mig? Bi efter Gud! For jeg skal ennu prise ham, mitt åsyns frelse og min Gud. 
19:44:1: Til sangmesteren; av Korahs barn; en læresalme. 
19:44:2: Gud, med våre ører har vi hørt, våre fedre har fortalt oss den gjerning du gjorde i deres dager, i fordums dager. 
19:44:3: Du drev hedningene ut med din hånd, men dem plantet du; du ødela folkene, men dem lot du utbrede sig. 
19:44:4: For ikke ved sitt sverd inntok de landet, og deres arm hjalp dem ikke, men din høire hånd og din arm og ditt åsyns schmelk; for du hadde behag i dem. 
19:44:5: Du er min konge, Gud; byd at Jakob skal frelses! 
19:44:6: Ved dig skal vi nedstøte våre fiender, ved ditt navn skal vi nedtrede dem som reiser sig imot oss. 
19:44:7: For på min bue stoler jeg ikke, og mitt sverd frelser mig ikke, 
19:44:8: men du har frelst oss fra våre fiender, og våre avindsmenn har du gjort til skamme. 
19:44:9: Gud priser vi den hele dag, og ditt navn lover vi evindelig. Sela. 
19:44:10: Og enda har du nu forkastet oss og latt oss bli til skamme, og du drar ikke ut med våre hærer. 
19:44:11: Du lar oss vike tilbake for fienden, og våre avindsmenn tar sig bytte. 
19:44:12: Du gir oss bort som får til å etes, og spreder oss iblandt hedningene. 
19:44:13: Du selger ditt folk for intet, og du setter ikke prisen på dem høit. 
19:44:14: Du gjør oss til hån for våre naboer, til spott og spe for dem som bor omkring oss. 
19:44:15: Du gjør oss til et ordsprog iblandt hedningene; de ryster på hodet av oss iblandt folkene. 
19:44:16: Hele dagen står min skam for mine øine, og blygsel dekker mitt ansikt, 
19:44:17: når jeg hører spotteren og håneren, når jeg ser fienden og den hevngjerrige. 
19:44:18: Alt dette er kommet over oss, enda vi ikke har glemt dig og ikke sveket din pakt. 
19:44:19: Vårt hjerte vek ikke tilbake, og våre skritt bøide ikke av fra din vei, 
19:44:20: så du skulde sønderknuse oss der hvor sjakaler bor, og dekke oss med dødsskygge. 
19:44:21: Dersom vi hadde glemt vår Guds navn og utbredt våre hender til en fremmed gud, 
19:44:22: skulde Gud da ikke utforske det? Han kjenner jo hjertets skjulte tanker. 
19:44:23: Men for din skyld drepes vi hele dagen, vi er regnet som slaktefår. 
19:44:24: Våkn op! Hvorfor sover du, Herre? Våkn op, forkast ikke for evig tid! 
19:44:25: Hvorfor skjuler du ditt åsyn, glemmer vår elendighet og vår trengsel? 
19:44:26: For vår sjel er nedbøid i støvet, vårt legeme nedtrykt til jorden. 
19:44:27: Reis dig til hjelp for oss, og forløs oss for din miskunnhets skyld! 
19:45:1: Til sangmesteren; efter "Liljer"*; av Korahs barn; en læresalme, en sang om kjærlighet. # <* kanskje melodien.> 
19:45:2: Mitt hjerte strømmer over med liflig tale; jeg sier: Min sang er om en konge; min tunge er en hurtigskrivers griffel. 
19:45:3: Du er den fagreste blandt menneskenes barn, livsalighet er utgytt på dine leber; derfor har Gud velsignet dig evindelig. 
19:45:4: Omgjord din lend med ditt sverd, du veldige, med din høihet og din herlighet! 
19:45:5: Og i din herlighet fare du seierrik frem for sannhets og rettferdig saktmodighets skyld! Og din høire hånd skal lære dig forferdelige storverk. 
19:45:6: Dine piler er hvesset - folkeferd faller under dig - de trenger inn i hjertet på kongens fiender. 
19:45:7: Din trone, Gud, står fast evindelig og alltid; rettvishets kongestav er ditt rikes kongestav. 
19:45:8: Du elsker rettferd og hater ugudelighet; derfor har Gud, din Gud, salvet dig med gledens olje fremfor dine medbrødre. 
19:45:9: Av myrra og aloë og kassia dufter alle dine klær; fra elfenbens-slott fryder dig strengelek. 
19:45:10: Kongedøtre er iblandt dine utvalgte; dronningen står ved din høire hånd i gull fra Ofir. 
19:45:11: Hør, datter, og gi akt og bøi ditt øre, og glem ditt folk og din fars hus, 
19:45:12: og la kongen ha sin lyst i din skjønnhet! For han er din herre, og du skal falle ned for ham. 
19:45:13: Og Tyrus' datter skal søke din yndest med gaver - de rike blandt folket. 
19:45:14: Såre herlig er kongedatteren der inne; hennes klædning er gjennemvirket med gull. 
19:45:15: I stukne klær ledes hun frem til kongen; jomfruer, hennes venninner, følger henne; de føres inn til dig. 
19:45:16: De ledes frem med fryd og jubel, de går inn i kongens slott. 
19:45:17: I dine fedres sted skal dine sønner trede; du skal sette dem til fyrster på den hele jord. 
19:45:18: Jeg vil prise ditt navn iblandt alle slekter; derfor skal folkene love dig evindelig og alltid. 
19:46:1: Til sangmesteren; av Korahs barn; efter Alamot*; en sang. # <* sannsynligvis en toneart, 1KR 15, 20.> 
19:46:2: Gud er vår tilflukt og vår styrke, en hjelp i trengsler, funnet såre stor. 
19:46:3: Derfor frykter vi ikke, om jorden omskiftes, og om fjell rokkes i havets hjerte, 
19:46:4: om dets bølger bruser og skummer, og fjell bever ved dets overmot. Sela. 
19:46:5: En strøm - dens bekker gleder Guds stad, den Høiestes hellige bolig. 
19:46:6: Gud er midt i den, den skal ikke rokkes; Gud hjelper den når morgenen bryter frem. 
19:46:7: Folkeferd bruste, riker blev rokket; han lot sin røst høre, jorden smeltet. 
19:46:8: Herren, hærskarenes Gud, er med oss; Jakobs Gud er vår faste borg. Sela. 
19:46:9: Kom, se Herrens gjerninger, som har gjort ødeleggelse på jorden! 
19:46:10: Han gjør ende på krigene over hele jorden, bryter buen sønder og hugger spydet av; vognene brenner han op med ild. 
19:46:11: Hold op og kjenn at jeg er Gud! Jeg er ophøiet iblandt folkene, ophøiet på jorden. 
19:46:12: Herren, hærskarenes Gud, er med oss; Jakobs Gud er vår faste borg. Sela. 
19:47:1: Til sangmesteren; av Korahs barn; en salme. 
19:47:2: Klapp i hender, alle folk, juble for Gud med fryderop. 
19:47:3: For Herren, den Høieste, er forferdelig, en stor konge over all jorden. 
19:47:4: Han legger folkeslag under oss og folkeferd under våre føtter. 
19:47:5: Han utvelger oss vår arvelodd, Jakobs, hans elskedes herlighet. Sela. 
19:47:6: Gud fór op under jubelrop, Herren under basuners lyd. 
19:47:7: Lovsyng Gud, lovsyng! Lovsyng vår konge, lovsyng! 
19:47:8: For Gud er all jordens konge; syng en sang som gjør vis! 
19:47:9: Gud er konge over folkene, Gud har satt sig på sin hellige trone. 
19:47:10: Folkenes fyrster samler sig med Abrahams Guds folk; for jordens skjold hører Gud til, han er såre ophøiet. 
19:48:1: En sang, en salme; av Korahs barn. 
19:48:2: Stor er Herren og høilovet i vår Guds stad, på hans hellige berg. 
19:48:3: Fagert hever det sig, en glede for all jorden er Sions berg, det ytterste Norden*, den store konges stad. # <* Sion er som Guds bolig i sannhet hvad hedningene falskelig tenkte sig om et fjell i det ytterste Norden, et hellig og meget høit fjell.> 
19:48:4: Gud er i dens borger, han er blitt kjent som et fast vern. 
19:48:5: For se, kongene samlet sig, de drog frem tilsammen. 
19:48:6: De så, da blev de forferdet; de blev slått med redsel, flyktet i hast. 
19:48:7: Beven grep dem der, angst som hos en fødende kvinne. 
19:48:8: Ved østenvind knuste du Tarsis-skib. 
19:48:9: Likesom vi hadde hørt, så har vi nu sett det i Herrens, hærskarenes Guds stad, i vår Guds stad; Gud gjør den fast til evig tid. Sela. 
19:48:10: Vi grunder, Gud, på din miskunnhet midt i ditt tempel. 
19:48:11: Som ditt navn, Gud, så er din pris inntil jordens ender; din høire hånd er full av rettferdighet. 
19:48:12: Sions berg gleder sig, Judas døtre fryder sig for dine dommers skyld. 
19:48:13: Gå omkring Sion og vandre rundt om det, tell dets tårn! 
19:48:14: Gi akt på dets voller, vandre gjennem dets borger, forat I kan fortelle derom til den kommende slekt. 
19:48:15: For denne Gud er vår Gud evindelig og alltid; han skal føre oss ut over døden. 
19:49:1: Til sangmesteren; av Korahs barn; en salme. 
19:49:2: Hør dette, alle folk, vend øret til, alle I som bor i verden, 
19:49:3: både lave og høie, rike og fattige, alle tilsammen! 
19:49:4: Min munn skal tale visdom, og mitt hjertes tanke er forstand. 
19:49:5: Jeg vil bøie mitt øre til tankesprog, jeg vil fremføre min gåtefulle tale til citaren. 
19:49:6: Hvorfor skal jeg frykte i de onde dager, når mine forfølgeres ondskap omgir mig, 
19:49:7: de som setter sin lit til sitt gods og roser sig av sin store rikdom? 
19:49:8: En mann kan ikke utløse en bror, han kan ikke gi Gud løsepenger for ham 
19:49:9: - for deres livs utløsning er for dyr, og han må avstå derfra til evig tid - 
19:49:10: så han skulde bli ved å leve evindelig og ikke se graven. 
19:49:11: Nei, han vil få se den. De vise dør, dåren og den uforstandige omkommer tilsammen og overlater sitt gods til andre. 
19:49:12: Deres hjertes eneste tanke er at deres hus skal stå til evig tid, deres boliger fra slekt til slekt; de kaller sine jorder op efter sine navn. 
19:49:13: Og dog blir et menneske i herlighet ikke stående; han er lik dyrene, som går til grunne. 
19:49:14: Således går det dem som er fulle av selvtillit, og dem som følger dem efter og har behag i deres tale. Sela. 
19:49:15: Som en fårehjord føres de ned i dødsriket, døden vokter dem, og de opriktige hersker over dem, når morgenen bryter frem, og deres skikkelse blir ødelagt av dødsriket, så de ikke har nogen bolig mere. 
19:49:16: Men Gud skal forløse min sjel av dødsrikets vold, for han skal ta mig til sig. Sela. 
19:49:17: Frykt ikke når en mann blir rik, når hans huses herlighet blir stor! 
19:49:18: For han skal intet ta med sig når han dør; hans herlighet skal ikke fare ned efter ham. 
19:49:19: Om han enn velsigner sin sjel i sitt liv, og de priser dig fordi du gjør dig til gode, 
19:49:20: så skal du dog komme til dine fedres slekt; de ser ikke schmelken evindelig. 
19:49:21: Et menneske i herlighet, som ikke har forstand, er lik dyrene, som går til grunne. 
19:50:1: En salme av Asaf. Den Allmektige, Gud Herren, taler og kaller på jorden fra solens opgang til dens nedgang. 
19:50:2: Fra Sion, skjønnhetens krone, stråler Gud frem. 
19:50:3: Vår Gud kommer og skal ikke tie; ild fortærer for hans åsyn, og omkring ham stormer det sterkt. 
19:50:4: Han kaller på himmelen der oppe og på jorden for å dømme sitt folk: 
19:50:5: Samle til mig mine fromme, som har inngått pakt med mig om offer! 
19:50:6: Og himmelen kunngjør hans rettferdighet; for Gud er den som skal dømme. Sela. 
19:50:7: Hør, mitt folk, jeg vil tale, Israel, jeg vil vidne for dig: Gud, din Gud, er jeg. 
19:50:8: Ikke for dine offers skyld vil jeg straffe dig; dine brennoffer er alltid for mig. 
19:50:9: Jeg vil ikke ta okser fra ditt hus eller bukker fra dine hegn. 
19:50:10: For mig hører alle dyr i skogen til, dyrene på fjellene i tusentall. 
19:50:11: Jeg kjenner alle fjellenes fugler, og det som rører sig på marken, står for mig. 
19:50:12: Om jeg hungret, vilde jeg ikke si det til dig; for mig hører jorderike til og alt det som fyller det. 
19:50:13: Mon jeg skulde ete oksers kjøtt og drikke bukkers blod? 
19:50:14: Ofre Gud takksigelse og gi den Høieste det du har lovt, 
19:50:15: og kall på mig på nødens dag, så vil jeg utfri dig, og du skal prise mig. 
19:50:16: Men til den ugudelige sier Gud: Hvad har du med å fortelle om mine lover og føre min pakt i din munn? 
19:50:17: Du hater jo tukt og kaster mine ord bak dig. 
19:50:18: Når du ser en tyv, er du gjerne med ham, og med horkarler gjør du felles sak. 
19:50:19: Din munn slipper du løs med ondt, og din tunge spinner sammen svik. 
19:50:20: Du sitter og taler imot din bror, du baktaler din mors sønn. 
19:50:21: Dette har du gjort, og jeg har tidd; du tenkte jeg var som du; men jeg vil straffe dig og stille det frem for dine øine. 
19:50:22: Legg merke til dette, I som glemmer Gud, forat jeg ikke skal sønderrive, og det er ingen som redder! 
19:50:23: Den som ofrer takksigelse, ærer mig, og den som går den rette vei, ham vil jeg la skue Guds frelse. 
19:51:1: Til sangmesteren; en salme av David, 
19:51:2: da profeten Natan var kommet til ham, efterat han var gått inn til Batseba. 
19:51:3: Vær mig nådig, Gud, efter din miskunnhet, utslett mine overtredelser efter din store barmhjertighet! 
19:51:4: Tvett mig vel, så jeg blir fri for misgjerning, og rens mig fra min synd! 
19:51:5: For mine overtredelser kjenner jeg, og min synd er alltid for mig. 
19:51:6: Mot dig alene har jeg syndet, og hvad ondt er i dine øine, har jeg gjort, forat du skal være rettferdig når du taler, være ren når du dømmer*. # <* d.e. kjennes å være rettferdig i dine straffedommer.> 
19:51:7: Se, jeg er født i misgjerning, og min mor har undfanget mig i synd. 
19:51:8: Se, du har lyst til sannhet i hjertets innerste; så lær mig da visdom i hjertets dyp! 
19:51:9: Rens mig fra synd med isop så jeg blir ren, tvett mig så jeg blir hvitere enn sne! 
19:51:10: La mig høre fryd og glede, la de ben fryde sig som du har sønderknust! 
19:51:11: Skjul ditt åsyn for mine synder, og utslett alle mine misgjerninger! 
19:51:12: Gud, skap i mig et rent hjerte, og forny en stadig ånd inneni mig! 
19:51:13: Kast mig ikke bort fra ditt åsyn, og ta ikke din Hellige Ånd fra mig! 
19:51:14: Gi mig igjen din frelses fryd, og ophold mig med en villig ånd! 
19:51:15: Så vil jeg lære overtredere dine veier, og syndere skal omvende sig til dig. 
19:51:16: Fri mig fra blodskyld, Gud, min frelses Gud! Så skal min tunge juble over din rettferdighet. 
19:51:17: Herre, oplat mine leber! Så skal min munn kunngjøre din pris. 
19:51:18: For du har ikke lyst til slaktoffer, ellers vilde jeg gi dig det; i brennoffer har du ikke behag. 
19:51:19: Offere for Gud er en sønderbrutt ånd, et sønderbrutt og sønderknust hjerte vil du, Gud, ikke forakte. 
19:51:20: Gjør vel imot Sion efter din nåde, bygg Jerusalems murer! 
19:51:21: Da skal du ha behag i rettferdighets offere, i brennoffer og heloffer; da skal de ofre okser på ditt alter. 
19:52:1: Til sangmesteren; en læresalme av David, 
19:52:2: da edomitten Doeg kom og gav Saul til kjenne og sa til ham: David er kommet i Akimeleks hus. 
19:52:3: Hvorfor roser du dig av ondskap, du veldige? Guds miskunnhet varer hele dagen. 
19:52:4: På undergang tenker din tunge, lik en hvesset rakekniv, du som legger op listige råd! 
19:52:5: Du elsker ondt istedenfor godt, løgn istedenfor å tale hvad rett er. Sela. 
19:52:6: Du elsker hvert ord som volder ødeleggelse, du svikaktige tunge! 
19:52:7: Gud skal da også bryte dig ned for evig tid; han skal gripe dig og rive dig ut av teltet og rykke dig op av de levendes land. Sela. 
19:52:8: Og de rettferdige skal se det og frykte, og de skal le av ham og si: 
19:52:9: Se, der er den mann som ikke holdt Gud for sitt sterke vern, men satte sin lit til sin store rikdom, satte sin styrke i sin ondskap. 
19:52:10: Men jeg er som et grønt oljetre i Guds hus, jeg setter min lit til Guds miskunnhet evindelig og alltid. 
19:52:11: Jeg vil prise dig evindelig, fordi du har gjort det, og jeg vil bie efter ditt navn, fordi det er godt, for dine frommes åsyn. 
19:53:1: Til sangmesteren; efter Mahalat*; en læresalme av David. # <* betydningen uviss.> 
19:53:2: Dåren sier i sitt hjerte: Det er ikke nogen Gud. Onde og vederstyggelige er deres misgjerninger; det er ikke nogen som gjør godt. 
19:53:3: Gud skuer ned fra himmelen på menneskenes barn for å se om der er nogen forstandig, nogen som søker Gud. 
19:53:4: De er alle avveket, alle tilsammen fordervet; det er ikke nogen som gjør godt, enn ikke én. 
19:53:5: Sanser de da ikke, de som gjør urett? De eter mitt folk likesom de eter brød; på Gud kaller de ikke. 
19:53:6: Da blev de grepet av stor frykt, de som ikke kjente frykt; for Gud spreder deres ben som leirer sig imot dig; du gjør dem til skamme, for Gud har forkastet dem. 
19:53:7: Å, at det fra Sion må komme frelse for Israel! Når Gud gjør ende på sitt folks fangenskap, da skal Jakob fryde sig, Israel glede sig. 
19:54:1: Til sangmesteren, med strengelek; en læresalme av David, 
19:54:2: da sifittene kom og sa til Saul: David holder sig skjult hos oss. 
19:54:3: Gud, frels mig ved ditt navn, og hjelp mig til min rett ved din kraft! 
19:54:4: Gud, hør min bønn, vend øret til min munns ord! 
19:54:5: For fremmede har reist sig imot mig, og voldsmenn står mig efter livet; de har ikke Gud for øie. Sela. 
19:54:6: Se, Gud hjelper mig, Herren er den som opholder mitt liv. 
19:54:7: Det onde skal falle tilbake på mine fiender; utrydd dem i din trofasthet! 
19:54:8: Med villig hjerte vil jeg ofre til dig; jeg vil prise ditt navn, Herre, fordi det er godt. 
19:54:9: For av all nød frir han mig ut, og på mine fiender ser mitt øie med lyst. 
19:55:1: Til sangmesteren; med strengelek; en læresalme av David. 
19:55:2: Vend øret, Gud, til min bønn, og skjul dig ikke for min inderlige begjæring! 
19:55:3: Gi akt på mig og svar mig! Mine sorgfylte tanker farer hit og dit, og jeg må stønne, 
19:55:4: for fiendens røst, for den ugudeliges undertrykkelse; for de velter elendighet over mig, og i vrede forfølger de mig. 
19:55:5: Mitt hjerte bever i mitt bryst, og dødens redsler er falt på mig. 
19:55:6: Frykt og beven kommer over mig, og forferdelse legger sig over mig. 
19:55:7: Og jeg sier: Gid jeg hadde vinger som duen! Da vilde jeg flyve bort og feste bo. 
19:55:8: Se, jeg vilde flykte langt bort, jeg vilde ta herberge i ørkenen. Sela. 
19:55:9: Jeg vilde i hast søke mig et tilfluktssted for den rasende vind, for stormen. 
19:55:10: Opsluk dem, Herre, kløv deres tungemål! For jeg ser vold og kiv i byen. 
19:55:11: Dag og natt vandrer de omkring den på dens murer, og elendighet og ulykke er inneni den. 
19:55:12: Fordervelse er inneni den, og undertrykkelse og svik viker ikke fra dens torv. 
19:55:13: For ikke er det en fiende som håner mig, ellers vilde jeg bære det; ikke er det min avindsmann som ophøier sig over mig, ellers vilde jeg skjule mig for ham; 
19:55:14: men det er du, du som var min likemann, min venn og min kjenning - 
19:55:15: vi som levde sammen i fortrolig omgang, som vandret til Guds hus blandt den glade høitidsskare. 
19:55:16: Ødeleggelse komme over dem! La dem fare levende ned i dødsriket! For ondskap hersker i deres bolig, i deres hjerte. 
19:55:17: Jeg vil rope til Gud, og Herren skal frelse mig. 
19:55:18: Aften og morgen og middag vil jeg klage og sukke, så hører han min røst. 
19:55:19: Han forløser min sjel fra striden imot mig og gir mig fred; for i mengde er de omkring mig. 
19:55:20: Gud skal høre og svare* dem - han troner jo fra fordums tid, sela - dem som ikke vil bli anderledes, og som ikke frykter Gud. # <* d.e. straffe.> 
19:55:21: Han* legger hånd på dem som har fred med ham, han vanhelliger sin pakt. # <* SLM 55, 13. 14.> 
19:55:22: Hans munns ord er glatte som smør, men hans hjertes tanke er strid; hans ord er bløtere enn olje, og dog er de dragne sverd. 
19:55:23: Kast på Herren det som tynger dig! Han skal holde dig oppe; han skal i evighet ikke la den rettferdige rokkes. 
19:55:24: Og du, Gud, skal støte dem ned i gravens dyp; blodgjerrige og falske menn skal ikke nå det halve av sine dager; men jeg setter min lit til dig. 
19:56:1: Til sangmesteren; efter "Den målløse due på de fjerne steder"*; av David; en gyllen sang da filistrene grep ham i Gat**. # <* kanskje melodien.> # <** 1SA 21, 10 fg.> 
19:56:2: Vær mig nådig, Gud! for mennesker vil opsluke mig; hele dagen trenger de mig med krig. 
19:56:3: Mine fiender søker å opsluke mig hele dagen; for mange er de som strider mot mig i overmot. 
19:56:4: På den dag jeg frykter, setter jeg min lit til dig. 
19:56:5: Ved Gud priser jeg hans ord; til Gud setter jeg min lit, jeg frykter ikke; hvad skulde kjød kunne gjøre mig? 
19:56:6: Hele dagen forvender de mine ord; alle deres tanker er mig imot til det onde. 
19:56:7: De slår sig sammen, de lurer, de tar vare på mine trin, fordi de står mig efter livet. 
19:56:8: Skulde de undslippe tross sin ondskap? Støt folkeslag ned i vrede, Gud! 
19:56:9: Hvor ofte jeg har flyktet, det har du tellet; mine tårer er gjemt i din flaske; står de ikke i din bok? 
19:56:10: Da skal mine fiender vende tilbake, på den dag jeg roper; dette vet jeg at Gud er med mig. 
19:56:11: Ved Gud priser jeg ordet; ved Herren priser jeg ordet. 
19:56:12: Til Gud setter jeg min lit, jeg frykter ikke; hvad skulde et menneske kunne gjøre mig? 
19:56:13: På mig, Gud, hviler løfter til dig; jeg vil betale dig med takksigelser. 
19:56:14: For du har fridd min sjel fra døden, ja mine føtter fra fall, så jeg kan vandre for Guds åsyn i de levendes schmelk. 
19:57:1: Til sangmesteren; "Forderv ikke"*; av David; en gyllen sang, da han flyktet for Saul og var i hulen**. # <* kanskje melodien.> # <** 1SA 22, 1; 24, 4.> 
19:57:2: Vær mig nådig, Gud, vær mig nådig! For til dig tar min sjel sin tilflukt, og i dine vingers skygge søker jeg ly inntil fordervelsen går over. 
19:57:3: Jeg roper til Gud, den Høieste, til den Gud som fullfører sin gjerning for mig. 
19:57:4: Han sender hjelp fra himmelen og frelser mig, når den som vil opsluke mig, håner. Sela. Gud sender sin miskunnhet og sin trofasthet. 
19:57:5: Min sjel er midt iblandt løver; jeg må ligge iblandt dem som spruter ild, menneskebarn hvis tenner er spyd og piler, og hvis tunge er et skarpt sverd. 
19:57:6: Vis dig høi over himmelen, Gud, din ære over all jorden! 
19:57:7: De stiller garn for mine trin, min sjel er nedbøiet; de graver en grav for mig, de faller selv midt i den. Sela. 
19:57:8: Mitt hjerte er rolig, Gud, mitt hjerte er rolig; jeg vil synge og lovprise. 
19:57:9: Våkn op, min ære*, våkn op, harpe og citar! Jeg vil vekke morgenrøden. # <* d.e. sjel.> 
19:57:10: Jeg vil prise dig blandt folkene, Herre, jeg vil lovsynge dig blandt folkeslagene. 
19:57:11: For din miskunnhet er stor inntil himmelen, og din trofasthet inntil skyene. 
19:57:12: Vis dig høi over himmelen, Gud, din ære over all jorden! 
19:58:1: Til sangmesteren; "Forderv ikke"*; av David; en gyllen sang. # <* SLM 57, 1.> 
19:58:2: Mon I virkelig ved å tie taler hvad rettferdig er, dømmer hvad rett er, I menneskebarn? 
19:58:3: I hjertet arbeider I jo på misgjerninger, i landet veier I ut eders henders vold. 
19:58:4: De ugudelige er avveket fra mors fang av; de som taler løgn, farer vill fra mors liv. 
19:58:5: Gift har de lik ormegift; de er som en døv slange, som stopper sitt øre til, 
19:58:6: så den ikke hører på slangetemmernes røst, på ham som er kyndig i å besverge. 
19:58:7: Gud, slå deres tenner inn i deres munn, knus de unge løvers kinntenner, Herre! 
19:58:8: La dem forgå som vann som rinner bort! Legger nogen sine piler i buen, da la dem bli som uten odd! 
19:58:9: La dem være som en snegl, som opløses mens den går, som en kvinnes ufullbårne foster, som ikke har sett solen! 
19:58:10: Før eders gryter kjenner tornekvistene, skal han blåse dem bort* enten de er friske eller i brand. # <* førenn de ugudeliges onde råd settes i verk, gjør Gud dem til intet.> 
19:58:11: Den rettferdige skal glede sig, fordi han ser hevn; han skal tvette sine føtter i den ugudeliges blod. 
19:58:12: Og menneskene skal si: Der er dog frukt for den rettferdige, det er dog en Gud som dømmer på jorden. 
19:59:1: Til sangmesteren; "Forderv ikke"*; av David; en gyllen sang, da Saul sendte folk som tok vare på huset for å drepe ham**. # <* SLM 57, 1.> # <** 1SA 19, 11 fg.> 
19:59:2: Fri mig fra mine fiender, min Gud, vern mig mot dem som reiser sig imot mig, 
19:59:3: fri mig fra dem som gjør urett, og frels mig fra blodgjerrige menn! 
19:59:4: For se, de lurer på mitt liv; sterke menn slår sig sammen imot mig, uten misgjerning og uten synd hos mig, Herre! 
19:59:5: Uten brøde hos mig stormer de frem og stiller sig op; våkn op for å møte mig, og se til! 
19:59:6: Ja du, Herre, hærskarenes Gud, Israels Gud, våkn op for å hjemsøke alle hedninger, vær ikke nådig imot nogen av de troløse nidinger! Sela. 
19:59:7: De kommer igjen om aftenen, tuter som hunder og løper rundt omkring i byen. 
19:59:8: Se, det går en strøm av ord ut av deres munn; der er sverd på deres leber; for hvem hører det? 
19:59:9: Men du, Herre, le, av dem, du spotter alle hedninger. 
19:59:10: Mot hans* makt vil jeg bie på dig; for Gud er min borg. # <* den mektige fiendes.> 
19:59:11: Min Gud vil komme mig i møte med sin miskunnhet, Gud vil la mig se med lyst på mine fiender. 
19:59:12: Slå dem ikke ihjel, forat mitt folk ikke skal glemme det! La dem drive ustadig omkring ved din makt og styrt dem ned, du, vårt skjold, Herre! 
19:59:13: Hvert ord på deres leber er en synd i deres munn; la dem så fanges i sitt overmot og for den forbannelses og løgns skyld som de fører i sin munn! 
19:59:14: Gjør ende på dem i vrede, gjør ende på dem, så de ikke mere er til, og la dem vite at Gud er den som hersker i Jakob inntil jordens ender! Sela. 
19:59:15: Og de kommer igjen om aftenen, tuter som hunder og løper rundt omkring i byen. 
19:59:16: De farer omkring efter mat; om de ikke blir mette, blir de således natten over. 
19:59:17: Men jeg vil synge om din styrke, og jeg vil juble om morgenen over din miskunnhet; for du er min borg og min tilflukt den dag jeg er i nød. 
19:59:18: Min styrke! For dig vil jeg synge; for Gud er min borg, min miskunnhets Gud. 
19:60:1: Til sangmesteren; efter Sjusjan edut*; en gyllen sang av David til å læres, # <* sannsynligvis melodien.> 
19:60:2: da han stred mot syrerne fra Mesopotamia og mot syrerne fra Soba, og Joab kom tilbake og slo edomittene i Saltdalen, tolv tusen. 
19:60:3: Gud! du har forkastet oss, du har sønderslått oss, du var vred; vederkveg oss nu igjen! 
19:60:4: Du har rystet jorden, du har fått den til å revne; læg dens skade, for den vakler! 
19:60:5: Du har latt ditt folk se hårde ting, du har gitt oss vin å drikke så vi tumlet. 
19:60:6: Men du har gitt dem som frykter dig, et hærmerke til opreisning, for sannhets skyld. Sela. 
19:60:7: Forat de du elsker, må bli frelst, så hjelp nu med din høire hånd og bønnhør oss! 
19:60:8: Gud har talt i sin hellighet. Jeg vil fryde mig; jeg vil utskifte Sikem og opmåle Sukkots dal. 
19:60:9: Mig hører Gilead til, og mig hører Manasse til, og Efra'im er vern for mitt hode, Juda er min herskerstav. 
19:60:10: Moab er mitt vaskefat, på Edom kaster jeg min sko; bryt ut i jubel over mig, Filisterland! 
19:60:11: Hvem vil føre mig til den faste by? Hvem leder mig inn til Edom? 
19:60:12: Mon ikke du, Gud, som forkastet oss og ikke drog ut med våre hærer, Gud? 
19:60:13: Gi oss hjelp mot fienden, for menneskehjelp er tomhet! 
19:60:14: Ved Gud skal vi gjøre storverk, og han skal nedtrede våre fiender. 
19:61:1: Til sangmesteren, på strengelek; av David. 
19:61:2: Hør, Gud, mitt klagerop, merk på min bønn! 
19:61:3: Fra jordens ende roper jeg til dig, mens mitt hjerte vansmekter; før mig på en klippe, som ellers blir mig for høi! 
19:61:4: For du har vært min tilflukt, et sterkt tårn imot fienden. 
19:61:5: La mig bo i ditt telt i all evighet, la mig finne ly i dine vingers skjul! Sela. 
19:61:6: For du, Gud, har hørt på mine løfter, du har gitt mig arv med dem som frykter ditt navn. 
19:61:7: Du vil legge dager til kongens dager, hans år skal være som slekt efter slekt, 
19:61:8: han skal trone evindelig for Guds åsyn; sett miskunnhet og trofasthet til å bevare ham! 
19:61:9: Så vil jeg lovsynge ditt navn til evig tid, forat jeg kan innfri mine løfter dag efter dag. 
19:62:1: Til sangmesteren, for Jedutun; en salme av David. 
19:62:2: Bare i håp til Gud er min sjel stille; fra ham kommer min frelse. 
19:62:3: Han alene er min klippe og min frelse, min borg, jeg skal ikke rokkes meget. 
19:62:4: Hvor lenge vil I alle storme løs på en mann, bryte ham ned som en mur som heller, et gjerde som støtes om? 
19:62:5: De rådslår bare om å styrte ham ned fra hans høihet, de har sin lyst i løgn; med sin munn velsigner de, men i sitt hjerte forbanner de. Sela. 
19:62:6: Bare i håp til Gud vær stille, min sjel! for fra ham kommer mitt håp. 
19:62:7: Han alene er min klippe og min frelse, min borg, jeg skal ikke rokkes. 
19:62:8: Hos Gud er min frelse og min ære; min sterke klippe, min tilflukt er i Gud. 
19:62:9: Sett eders lit til ham til enhver tid, I folk! Utøs eders hjerte for hans åsyn! Gud er vår tilflukt. Sela. 
19:62:10: Bare tomhet er menneskenes barn, bare løgn er mannens sønner; i vektskålen stiger de til værs, de er tomhet alle sammen. 
19:62:11: Stol ikke på vold, og sett ikke fåfengt håp til røvet gods! Når rikdommen vokser, så akt ikke på det! 
19:62:12: En gang har Gud talt, ja to ganger har jeg hørt det*, at styrke hører Gud til. # <* d.e. Gud har gjentatte ganger sagt.> 
19:62:13: Og dig, Herre, hører miskunnhet til; for du betaler enhver efter hans gjerning. 
19:63:1: En salme av David, da han var i Juda ørken. 
19:63:2: Gud! Du er min Gud, jeg søker dig årle; min sjel tørster efter dig, mitt kjød lenges efter dig i et tørt og vansmektende land, hvor det ikke er vann. 
19:63:3: Således har jeg skuet dig i helligdommen, idet jeg så din makt og din herlighet. 
19:63:4: For din miskunnhet er bedre enn livet; mine leber priser dig. 
19:63:5: Således vil jeg love dig mitt liv igjennem; i ditt navn vil jeg opløfte mine hender. 
19:63:6: Min sjel skal bli mettet som av marg og fett, og med jublende leber skal min munn lovprise dig. 
19:63:7: Når jeg kommer dig i hu på mitt leie, tenker jeg på dig gjennem nattevaktene. 
19:63:8: For du har vært min hjelp, og under dine vingers skygge jubler jeg. 
19:63:9: Min sjel henger ved dig; din høire hånd holder mig oppe. 
19:63:10: Og de som står mig efter livet for å ødelegge det, de skal komme til jordens nederste dyp. 
19:63:11: De skal gis sverdet i vold, bli til rov for rever. 
19:63:12: Og kongen skal glede sig i Gud; hver den som sverger ved ham, skal prise sig lykkelig, for løgneres munn skal tilstoppes. 
19:64:1: Til sangmesteren; en salme av David. 
19:64:2: Hør, Gud, min røst når jeg klager, bevar mitt liv for fiendens skremsel! 
19:64:3: Skjul mig for de ondes lønnlige råd, for illgjerningsmenns larmende hop, 
19:64:4: som hvesser sin tunge som et sverd, legger på buen sin pil, det bitre ord, 
19:64:5: for å skyte den uskyldige i lønndom; med ett skyter de ham og frykter ikke. 
19:64:6: De gjør sitt onde råd fast; de forteller hvorledes de vil legge skjulte snarer; de sier: Hvem ser dem? 
19:64:7: De uttenker ugjerninger: Vi er ferdige, tanken er uttenkt - og menneskenes tanker og hjerter er dype. 
19:64:8: Da skyter Gud dem; med ett kommer pilen; slaget har rammet dem. 
19:64:9: Og de blir nedstyrtet, deres tunge kommer over dem; alle de som ser på dem, ryster på hodet. 
19:64:10: Og alle mennesker frykter og forkynner Guds gjerning, og de forstår hans verk. 
19:64:11: Den rettferdige gleder sig i Herren og tar sin tilflukt til ham, og alle opriktige av hjertet priser sig lykkelige. 
19:65:1: Til sangmesteren; en salme av David; en sang. 
19:65:2: Dig, Gud, priser de i stillhet på Sion, og dig gir de det de har lovt. 
19:65:3: Du som hører bønner, til dig kommer alt kjød. 
19:65:4: Når mine misgjerninger er blitt mig for svære, så forlater du våre overtredelser. 
19:65:5: Salig er den du utvelger og lar komme nær, så han bor i dine forgårder; vi vil mette oss med de gode ting i ditt hus, ditt hellige tempel. 
19:65:6: Med forferdelige gjerninger bønnhører du oss i rettferdighet, du vår frelses Gud, du som er en tilflukt for alle jordens ender og havet langt borte. 
19:65:7: Han gjør fjellene faste med sin kraft, omgjordet med velde. 
19:65:8: Han stiller havenes brusen, deres bølgers brusen og folkenes bulder. 
19:65:9: Og de som bor ved jordens ender, frykter for dine tegn; de steder hvor morgen og aften bryter frem, fyller du med jubel. 
19:65:10: Du har gjestet jorden og gitt den overflod, gjort den såre rik, Guds bekk er full av vann; du har latt dem få sitt korn, for således lager du jorden til. 
19:65:11: Du vannet dens furer, senket dens plogland; du bløtte den med regnskurer, velsignet dens grøde. 
19:65:12: Du har kronet din godhets år, og dine fotspor drypper av fedme. 
19:65:13: Ødemarkens beiter drypper, og haugene omgjorder sig med jubel. 
19:65:14: Engene er klædd med får, og dalene er dekket med korn; folk roper med fryd og synger. 
19:66:1: Til sangmesteren; en sang, en salme. Rop med fryd for Gud, all jorden! 
19:66:2: Syng ut hans navns ære, gi ham ære til hans pris! 
19:66:3: Si til Gud: Hvor forferdelige er dine gjerninger! For din store makts skyld skal dine fiender hykle for dig. 
19:66:4: All jorden skal tilbede dig og lovsynge dig, de skal lovsynge ditt navn. Sela. 
19:66:5: Kom og se Guds gjerninger! Han er forferdelig i gjerning mot menneskenes barn. 
19:66:6: Han gjorde havet om til tørt land, gjennem strømmen gikk de til fots; der gledet vi oss i ham. 
19:66:7: Han hersker med sitt velde evindelig, hans øine gir akt på hedningene; de gjenstridige må ikke ophøie sig. Sela. 
19:66:8: I folkeslag, lov vår Gud og forkynn hans pris med høi røst, 
19:66:9: han som holdt vår sjel i live og ikke lot vår fot vakle! 
19:66:10: For du prøvde oss, Gud, du renset oss, likesom de renser sølv. 
19:66:11: Du førte oss inn i et garn, du la en trykkende byrde på våre lender. 
19:66:12: Du lot mennesker fare frem over vårt hode; vi kom i ild og i vann. Men du førte oss ut til vederkvegelse. 
19:66:13: Jeg vil gå inn i ditt hus med brennoffer, jeg vil gi dig det jeg har lovt, 
19:66:14: det som gikk over mine leber, og som min munn talte i min nød. 
19:66:15: Jeg vil ofre dig brennoffere av fett kveg med duft av værer; jeg vil ofre okser tillikemed bukker. Sela. 
19:66:16: Kom, hør, alle I som frykter Gud; jeg vil fortelle hvad han har gjort mot min sjel. 
19:66:17: Til ham ropte jeg med min munn, og lovsang var under min tunge. 
19:66:18: Hadde jeg urett for øie i mitt hjerte, så vilde Herren ikke høre. 
19:66:19: Men Gud har hørt, han har aktet på mitt bønnerop. 
19:66:20: Lovet være Gud, som ikke avviste min bønn og ikke tok sin miskunnhet fra mig! 
19:67:1: Til sangmesteren på strengelek; en salme, en sang. 
19:67:2: Gud være oss nådig og velsigne oss, han la sitt åsyn lyse hos oss, sela, 
19:67:3: forat man på jorden må kjenne din vei, blandt alle hedninger din frelse. 
19:67:4: Folkene skal prise dig, Gud! Folkene skal prise dig, alle tilsammen. 
19:67:5: Folkeslagene skal fryde sig og juble; for du dømmer folkene med rett, og folkeslagene på jorden leder du. Sela. 
19:67:6: Folkene skal prise dig, Gud! Folkene skal prise dig, alle tilsammen. 
19:67:7: Landet har gitt sin grøde; Gud, vår Gud, velsigner oss. 
19:67:8: Gud velsigner oss, og alle jordens ender skal frykte ham. 
19:68:1: Til sangmesteren; av David; en salme, en sang. 
19:68:2: Gud reiser sig, hans fiender spredes, og de som hater ham, flyr for hans åsyn. 
19:68:3: Likesom røk drives bort, så driver du dem bort; likesom voks smelter for ild, forgår de ugudelige for Guds åsyn. 
19:68:4: Og de rettferdige gleder sig, de jubler for Guds åsyn og fryder sig med glede. 
19:68:5: Syng for Gud, lovsyng hans navn, gjør vei for ham som farer frem på de øde marker, Herren er hans navn, og juble for hans åsyn! 
19:68:6: Farløses far og enkers dommer er Gud i sin hellige bolig. 
19:68:7: Gud gir de enslige hus, fører fanger ut til lykke; bare de gjenstridige bor i et tørt land. 
19:68:8: Gud, da du drog ut foran ditt folk, da du skred frem gjennem ørkenen, sela, 
19:68:9: da bevet jorden, og himlene dryppet for Guds åsyn, Sinai der borte, for Guds, Israels Guds åsyn. 
19:68:10: Et rikelig regn spredte du, Gud; din arv, den utmattede, styrket du. 
19:68:11: Ditt folk bosatte sig i landet*; du laget det ved din godhet i stand for den elendige, Gud! # <* Kana'an.> 
19:68:12: Herren gir seierssang; stor er skaren av kvinner med gledesbudskap. 
19:68:13: Hærenes konger flyr, de flyr, og hun som sitter hjemme, deler bytte. 
19:68:14: Når I hviler mellem kveene, er det som en dues vinger, som er dekket med sølv, og hvis vingefjær har gullets grønnlige glans*. # <* d.e. når I nyter fredens ro, da er eders lodd strålende og herlig.> 
19:68:15: Når den Allmektige spreder konger der*, da sner det på Salmon**. # <* i Kana'an.> # <** d.e. da blir det mørke lyst, likesom når det mørke fjell, Salmon, blir hvitt av sne, d.s.s. da omskapes ulykke til lykke.> 
19:68:16: Et Guds fjell* er Basans fjell, et fjell med mange tinder er Basans fjell**. # <* d.e. et stort, mektig, høit fjell.> # <** d.e. verdensrikene, som Basans fjell er et billede på, er såre mektige.> 
19:68:17: Hvorfor ser I skjevt, I fjell med de mange tinder, til det fjell som Gud finner behag i å bo på? Herren skal også bo der evindelig. 
19:68:18: Guds vogner er to ganger ti tusen, tusen og atter tusen; Herren er iblandt dem, Sinai er i helligdommen*. # <* likesom på Sinai, da loven blev gitt, er Herren i helligdommen, d.e. på Sion, omgitt av himmelske hærskarer.> 
19:68:19: Du fór op i det høie, bortførte fanger, tok gaver blandt menneskene, også blandt de gjenstridige, for å bo der, Herre Gud! 
19:68:20: Lovet være Herren dag efter dag! Legger man byrde på oss, så er Gud vår frelser. Sela. 
19:68:21: Gud er oss en Gud til frelse, og hos Herren, Israels Gud, er det utganger fra døden. 
19:68:22: Ja, Gud knuser sine fienders hode, den hårrike isse på ham som vandrer i sin syndeskyld. 
19:68:23: Herren sier: Fra Basan vil jeg hente tilbake, jeg vil hente tilbake fra havets dyp, 
19:68:24: forat din fot må stampe i blod, dine hunders tunge få sin del av fiendene. 
19:68:25: De ser dine seierstog, Gud, min Guds, min konges, seierstog inn i helligdommen. 
19:68:26: Foran går sangere, bakefter harpespillere midt imellem jomfruer som slår på pauke. 
19:68:27: Lov Gud i forsamlingene, lov Herren, I som er av Israels kilde! 
19:68:28: Der er Benjamin, den yngste, som hersker over dem*, Judas fyrster med sin skare, Sebulons fyrster, Naftalis fyrster. # <* fiendene.> 
19:68:29: Din Gud har tildelt dig styrke; styrk, Gud, det du har gjort for oss! 
19:68:30: For ditt tempel i Jerusalems skyld* skal konger komme til dig med gaver. # <* d.e. for de gjerningers skyld som utgår fra dig som bor i templet.> 
19:68:31: Skjell på dyret i sivet*, på stuteflokken med folke-kalvene**, som kaster sig ned for dig med sølvstykker! Han spreder folkene som har lyst til strid. # <* d.e. Egypten.> # <** d.e. de hedenske konger med sine folk.> 
19:68:32: Veldige menn skal komme fra Egypten, Etiopia skal i hast utrekke sine hender til Gud. 
19:68:33: I jordens riker, syng for Gud, lovsyng Herren, sela, 
19:68:34: ham som farer frem i himlenes himler, de eldgamle! Se, han lar sin røst høre, en mektig røst. 
19:68:35: Gi Gud makt! Over Israel er hans høihet, og hans makt i skyene. 
19:68:36: Forferdelig er du, Gud, fra dine helligdommer; Israels Gud, han gir folket makt og styrke. Lovet være Gud! 
19:69:1: Til sangmesteren; efter "Liljer"*; av David. # <* SLM 45, 1.> 
19:69:2: Frels mig, Gud, for vannene er kommet inntil sjelen. 
19:69:3: Jeg er sunket ned i bunnløst dynd, hvor der intet fotfeste er; jeg er kommet i dype vann, og strømmen slår over mig. 
19:69:4: Jeg har ropt mig trett, min strupe brenner; mine øine er borttæret idet jeg venter på min Gud. 
19:69:5: Flere enn hårene på mitt hode er de som hater mig uten årsak; tallrike er de som vil forderve mig, mine fiender uten grunn; det jeg ikke har røvet, skal jeg nu gi tilbake. 
19:69:6: Gud, du kjenner min dårskap, og all min syndeskyld er ikke skjult for dig. 
19:69:7: La dem ikke bli til skamme ved mig, de som bier efter dig, Herre, Herre, hærskarenes Gud! La dem ikke bli til spott ved mig, de som søker dig, Israels Gud! 
19:69:8: For for din skyld bærer jeg vanære, dekker skam mitt åsyn. 
19:69:9: Jeg er blitt fremmed for mine brødre og en utlending for min mors barn. 
19:69:10: For nidkjærhet for ditt hus har fortært mig, og deres hån som håner dig, er falt på mig. 
19:69:11: Og min sjel gråt mens jeg fastet, og det blev mig til spott. 
19:69:12: Og jeg gjorde sekk til mitt klædebon, og jeg blev dem til et ordsprog. 
19:69:13: De som sitter i porten, snakker om mig, og de som drikker sterk drikk, synger om mig. 
19:69:14: Men jeg kommer med min bønn til dig, Herre, i nådens tid, Gud, for din megen miskunnhet; svar mig med din frelsende trofasthet! 
19:69:15: Redd mig ut av dyndet og la mig ikke synke! La mig bli reddet fra dem som hater mig, og fra de dype vann! 
19:69:16: La ikke vannstrømmen slå over mig og ikke dypet sluke mig, og la ikke brønnen lukke sitt gap over mig! 
19:69:17: Svar mig, Herre, for din miskunnhet er god; vend dig til mig efter din store barmhjertighet! 
19:69:18: Og skjul ikke ditt åsyn for din tjener, for jeg er i nød; skynd dig å svare mig! 
19:69:19: Kom nær til min sjel, forløs den, frels mig for mine fienders skyld! 
19:69:20: Du kjenner min spott og min skam og min vanære; alle mine motstandere er for ditt åsyn. 
19:69:21: Spott har brutt mitt hjerte, så jeg er syk, og jeg ventet på medynk, men der var ingen, på trøstere, men jeg fant ikke nogen. 
19:69:22: De gav mig galle å ete, og for min tørst gav de mig eddik å drikke. 
19:69:23: La deres bord bli til en strikke for deres åsyn og til en snare for dem når de er trygge! 
19:69:24: La deres øine formørkes, så de ikke ser, og la deres lender alltid vakle! 
19:69:25: Utøs din harme over dem, og la din brennende vrede nå dem! 
19:69:26: Deres bolig bli øde, ei være der nogen som bor i deres telt! 
19:69:27: For den du har slått, forfølger de, og de forteller om deres smerte som du har stunget. 
19:69:28: La dem legge skyld til sin skyld, og la dem ikke komme til din rettferdighet! 
19:69:29: La dem bli utslettet av de levendes bok, og la dem ikke bli innskrevet med de rettferdige! 
19:69:30: Men jeg er elendig og full av pine; la din frelse, Gud, føre mig i sikkerhet! 
19:69:31: Jeg vil love Guds navn med sang og ophøie ham med lovprisning, 
19:69:32: og det skal behage Herren bedre enn en ung okse med horn og klover. 
19:69:33: Når saktmodige ser det, skal de glede sig; I som søker Gud, eders hjerte leve! 
19:69:34: For Herren hører på de fattige, og sine fanger forakter han ikke. 
19:69:35: Himmel og jord skal love ham, havet og alt det som rører sig i det. 
19:69:36: For Gud skal frelse Sion og bygge byene i Juda, og de* skal bo der og eie dem, # <* de sanne israelitter.> 
19:69:37: og hans tjeneres avkom skal arve dem, og de som elsker hans navn, skal bo i dem. 
19:70:1: Til sangmesteren; av David; til ihukommelse. 
19:70:2: Gud, kom for å utfri mig! Herre, skynd dig å hjelpe mig! 
19:70:3: La dem som står mig efter livet, bli til skam og spott! La dem som ønsker min ulykke, vike tilbake og bli til skamme! 
19:70:4: La dem som sier: Ha, ha, vende tilbake for sin skjensels skyld! 
19:70:5: La dem fryde og glede sig i dig, alle de som søker dig, og la dem som elsker din frelse, alltid si: Høilovet være Gud! 
19:70:6: For jeg er elendig og fattig; Gud, skynd dig til mig! Du er min hjelp og min frelser; Herre, dryg ikke! 
19:71:1: Til dig, Herre, setter jeg min lit; la mig aldri i evighet bli til skamme! 
19:71:2: Utfri mig og redd mig ved din rettferdighet! Bøi ditt øre til mig og frels mig! 
19:71:3: Vær mig en klippe til bolig, dit jeg alltid kan gå, du som har fastsatt frelse for mig! For du er min klippe og min festning. 
19:71:4: Min Gud, utfri mig av den ugudeliges hånd, av den urettferdiges og undertrykkerens vold! 
19:71:5: For du er mitt håp, Herre, Herre min tillit fra min ungdom av. 
19:71:6: Til dig har jeg støttet mig fra mors liv av; du er den som drog mig ut av min mors skjød; om dig vil jeg alltid synge min lovsang. 
19:71:7: Som et under har jeg vært for mange, men du er min sterke tilflukt. 
19:71:8: Min munn er full av din pris, hele dagen av din herlighet. 
19:71:9: Forkast mig ikke i alderdommens tid, forlat mig ikke når min kraft forgår! 
19:71:10: For mine fiender har sagt om mig, de som tar vare på mitt liv, rådslår tilsammen 
19:71:11: og sier: Gud har forlatt ham; forfølg og grip ham, for det er ingen som redder! 
19:71:12: Gud, vær ikke langt borte fra mig! Min Gud, skynd dig å hjelpe mig! 
19:71:13: La dem som står mig efter livet, bli til skamme og gå til grunne! La dem som søker min ulykke, bli klædd i skam og spott! 
19:71:14: Og jeg vil alltid håpe, og til all din pris vil jeg legge ny pris. 
19:71:15: Min munn skal fortelle om din rettferdighet, hele dagen om din frelse; for jeg vet ikke tall derpå. 
19:71:16: Jeg vil fremføre Herrens, Israels Guds veldige gjerninger, jeg vil prise din rettferdighet, din alene. 
19:71:17: Gud, du har lært mig det fra min ungdom av, og inntil nu kunngjør jeg dine undergjerninger. 
19:71:18: Forlat mig da heller ikke inntil alderdommen og de grå hår, Gud, inntil jeg får kunngjort din arm for efterslekten, din kraft for hver den som skal komme. 
19:71:19: Og din rettferdighet, Gud, når til det høie; du som har gjort store ting, Gud, hvem er som du? 
19:71:20: Du som har latt oss se mange trengsler og ulykker, du vil igjen gjøre oss levende og igjen dra oss op av jordens avgrunner. 
19:71:21: Du vil øke min storhet og vende om og trøste mig. 
19:71:22: Så vil jeg også prise dig med harpespill, din trofasthet, min Gud! Jeg vil lovsynge dig til citar, du Israels Hellige! 
19:71:23: Mine leber skal juble, for jeg vil lovsynge dig, og min sjel, som du har forløst. 
19:71:24: Min tunge skal også hele dagen tale om din rettferdighet; for de er blitt til spott, de er blitt til skamme, de som søker min ulykke. 
19:72:1: Av Salomo. Gud, gi kongen dine dommer og kongesønnen din rettferdighet! 
19:72:2: Han skal dømme ditt folk med rettferdighet og dine elendige med rett. 
19:72:3: Fjellene skal bære fred for folket, og haugene for rettferdighets skyld. 
19:72:4: Han skal dømme de elendige blandt folket, han skal frelse den fattiges barn og knuse voldsmannen. 
19:72:5: De skal frykte dig, så lenge solen er til, og så lenge månen skinner, fra slekt til slekt. 
19:72:6: Han skal komme ned som regn på en nyslått eng, lik en regnskur, som væter jorden. 
19:72:7: I hans dager skal den rettferdige blomstre, og det skal være megen fred, inntil månen ikke er mere. 
19:72:8: Og han skal herske fra hav til hav og fra elven inntil jordens ender. 
19:72:9: For hans åsyn skal de som bor i ørkenene, bøie kne, og hans fiender skal slikke støv. 
19:72:10: Kongene fra Tarsis og øene skal komme med gaver, kongene fra Sjeba og Seba frembære skatt. 
19:72:11: Alle konger skal falle ned for ham, alle hedninger skal tjene ham. 
19:72:12: For han skal frelse den fattige som roper, og den elendige som ingen hjelper har. 
19:72:13: Han skal spare den ringe og fattige, og frelse de fattiges sjeler. 
19:72:14: Han skal forløse deres sjel fra undertrykkelse og fra vold, og deres blod skal være dyrt i hans øine. 
19:72:15: Og de skal leve og gi ham av Sjebas gull og alltid bede for ham; hele dagen skal de love ham. 
19:72:16: Det skal bli overflod av korn i landet på fjellenes topp, dets frukt skal suse som Libanon, og det skal fremblomstre folk av byene som gresset på jorden. 
19:72:17: Hans navn skal bli til evig tid; så lenge solen skinner, skal hans navn skyte friske skudd, og de skal velsigne sig ved ham; alle hedninger skal prise ham salig. 
19:72:18: Lovet være Gud Herren, Israels Gud, han, den eneste som gjør undergjerninger! 
19:72:19: Og lovet være hans herlighets navn til evig tid, og all jorden bli full av hans herlighet! Amen, amen. 
19:72:20: Ende på Davids, Isais sønns bønner. 
19:73:1: En salme av Asaf. Ja, Gud er god mot Israel, mot de rene av hjertet. 
19:73:2: Men jeg - nær hadde mine føtter snublet, på lite nær var mine trin glidd ut. 
19:73:3: For jeg harmedes over de overmodige da jeg så at det gikk de ugudelige vel. 
19:73:4: For de er fri for lidelser inntil sin død, og deres styrke er vel ved makt. 
19:73:5: De kjenner ikke til nød som andre folk, og de blir ikke plaget som andre mennesker. 
19:73:6: Derfor er overmot deres halssmykke, vold omhyller dem som et klædebon. 
19:73:7: Deres øine står ut av fedme, hjertets tanker bryter frem. 
19:73:8: De håner og taler i ondskap om undertrykkelse; fra det høie taler de. 
19:73:9: De løfter sin munn op til himmelen, og deres tunge farer frem på jorden. 
19:73:10: Derfor får de sitt folk til å vende sig om til dem, og vann i overflod suger de i sig*. # <* d.e. de nyter stor lykke.> 
19:73:11: Og de sier: Hvorledes skulde Gud vite noget? Er det vel kunnskap hos den Høieste? 
19:73:12: Se, dette er de ugudelige, og evig trygge vokser de i velmakt. 
19:73:13: Ja, forgjeves har jeg renset mitt hjerte og tvettet mine hender i uskyld; 
19:73:14: jeg blev dog plaget hele dagen, og hver morgen kom til mig med tukt. 
19:73:15: Dersom jeg hadde sagt: Jeg vil tale således*, se, da hadde jeg vært troløs mot dine barns slekt. # <* SLM 73, 13. 14.> 
19:73:16: Og jeg tenkte efter for å forstå dette*; det var en plage i mine øine # <* SLM 73, 3-14.> 
19:73:17: - inntil jeg gikk inn i Guds helligdommer og gav akt på deres endelikt. 
19:73:18: Ja, på glatte steder setter du dem; du lot dem falle, så de gikk til grunne. 
19:73:19: Hvor de blev ødelagt i et øieblikk! De gikk under og tok ende med forferdelse. 
19:73:20: Likesom en akter for intet en drøm når en har våknet op, således akter du, Herre, deres skyggebillede for intet når du våkner op. 
19:73:21: Når mitt hjerte var bittert, og det stakk mig i mine nyrer, 
19:73:22: da var jeg ufornuftig og forstod intet; som et dyr var jeg imot dig. 
19:73:23: Men jeg blir alltid hos dig, du har grepet min høire hånd. 
19:73:24: Du leder mig ved ditt råd, og derefter optar du mig i herlighet. 
19:73:25: Hvem har jeg ellers i himmelen? Og når jeg har dig, har jeg ikke lyst til noget på jorden. 
19:73:26: Vansmekter enn mitt kjød og mitt hjerte, så er dog Gud mitt hjertes klippe og min del evindelig. 
19:73:27: For se, de som holder sig borte fra dig, går til grunne; du utrydder hver den som faller fra dig i hor*. # <* d.e. i utroskap bryter pakten med dig.> 
19:73:28: Men for mig er det godt å holde mig nær til Gud; jeg setter min lit til Herren, Israels Gud, for å fortelle alle dine gjerninger. 
19:74:1: En læresalme av Asaf. Hvorfor, Gud, har du forkastet oss for evig tid? Hvorfor ryker din vrede mot den hjord du før? 
19:74:2: Kom i hu din menighet, som du vant din i fordums tid, som du gjenløste til å være din arvs stamme, Sions berg, hvor du tok bolig! 
19:74:3: Opløft dine trin til de evige grushoper! Alt har fienden fordervet i helligdommen. 
19:74:4: Dine motstandere har brølt midt i ditt forsamlingshus; de har satt sine egne tegn op til tegn. 
19:74:5: Det var et syn som når økser løftes i tykke skogen. 
19:74:6: Og nu, alt det som fantes av billedverk, det slo de sønder med øks og hammer. 
19:74:7: De har satt ild på din helligdom; like til grunnen har de vanhelliget ditt navns bolig. 
19:74:8: De har sagt i sitt hjerte: Vi vil ødelegge dem alle tilsammen! De har opbrent alle Guds forsamlingshus i landet. 
19:74:9: Våre egne tegn ser vi ikke; det er ikke nogen profet mere, ikke nogen hos oss som vet hvor lenge det skal vare. 
19:74:10: Hvor lenge, Gud, skal motstanderen håne, fienden forakte ditt navn evindelig? 
19:74:11: Hvorfor drar du din hånd, din høire hånd tilbake? Ta den ut av din barm og ødelegg! 
19:74:12: Gud er dog min konge fra fordums tid, han som skaper frelse på den vide jord. 
19:74:13: Du er den som skilte havet med din styrke, knuste dragenes hoder på vannene. 
19:74:14: Du sønderslo Leviatans* hoder, du gav den til føde for ørkenens folk. # <* d.e. et stort sjødyr.> 
19:74:15: Du lot kilde og bekk bryte frem, du uttørket evige strømmer. 
19:74:16: Dig hører dagen til, dig også natten; du har skapt himmellysene og solen. 
19:74:17: Du har fastsatt alle jordens grenser; sommer og vinter - du har dannet dem. 
19:74:18: Kom dette i hu: Fienden har hånet Herren, og et dårlig folk har foraktet ditt navn! 
19:74:19: Overgi ikke din turteldue til den mordlystne skare, glem ikke dine elendiges skare evindelig! 
19:74:20: Se til pakten! For landets mørke steder er fulle av volds boliger. 
19:74:21: La ikke den undertrykte vende tilbake med skam, la den elendige og fattige love ditt navn! 
19:74:22: Reis dig, Gud, før din sak, kom i hu at du blir hånet av dåren hele dagen! 
19:74:23: Glem ikke dine fienders røst, dine motstanderes bulder, som stiger op all tid! 
19:75:1: Til sangmesteren; "Forderv ikke"*; en salme av Asaf; en sang. # <* SLM 57, 1.> 
19:75:2: Vi priser dig, Gud, vi priser, og nær er ditt navn; de forteller om dine undergjerninger. 
19:75:3: For [så sier Herren]: Jeg vil gripe den fastsatte tid, jeg vil dømme med rettferdighet. 
19:75:4: Jorden er i opløsning, og alle de som bor på den; jeg har satt dens grunnstøtter efter mål. Sela. 
19:75:5: Jeg sier til de overmodige: Vær ikke overmodige, og til de ugudelige: Løft ikke horn! 
19:75:6: Løft ikke eders horn i det høie, tal ikke frekt med stiv nakke! 
19:75:7: For ikke fra øst eller fra vest, ei heller fra fjellenes ørken* - # <* nemlig: kommer vår frelse.> 
19:75:8: nei, Gud er den som dømmer; den ene fornedrer han, og den andre ophøier han. 
19:75:9: For det er et beger i Herrens hånd med skummende vin; det er fullt av krydret vin, og han skjenker ut av det; sannelig, dets berme skal de drikke, ja utsuge, alle de ugudelige på jorden. 
19:75:10: Men jeg vil forkynne det evindelig, jeg vil lovsynge Jakobs Gud. 
19:75:11: Og alle de ugudeliges horn vil jeg avhugge, den rettferdiges horn skal ophøies. 
19:76:1: Til sangmesteren på strengelek; en salme av Asaf; en sang. 
19:76:2: Gud er kjent i Juda, hans navn er stort i Israel. 
19:76:3: Og han reiste sin hytte i Salem og sin bolig på Sion. 
19:76:4: Der sønderbrøt han buens lyn, skjold og sverd og krig. Sela. 
19:76:5: Strålende er du, herlig fremfor røverfjellene*. # <* d.e. de rovgjerrige verdensriker.> 
19:76:6: Mennene med det sterke hjerte er blitt et rov; de sover sin søvn, og ingen av de veldige menn fant sine hender. 
19:76:7: Ved din trusel, Jakobs Gud, falt både vogn og hest i dyp søvn. 
19:76:8: Du - forferdelig er du, og hvem kan bli stående for ditt åsyn når du blir vred? 
19:76:9: Fra himmelen lot du høre dom; jorden fryktet og blev stille, 
19:76:10: da Gud reiste sig til dom for å frelse alle saktmodige på jorden. Sela. 
19:76:11: For menneskets vrede blir dig til pris; med enda større vrede omgjorder du dig. 
19:76:12: Gjør løfter og gi Herren eders Gud det I har lovt! Alle de som er omkring ham, skal komme med gaver til den Forferdelige. 
19:76:13: Han kuer fyrstenes stolte ånd, forferdelig for kongene på jorden. 
19:77:1: Til sangmesteren, for Jedutun*; av Asaf; en salme. # <* SLM 62, 1.> 
19:77:2: Min røst er til Gud, og jeg vil rope; min røst er til Gud, og han vil vende øret til mig. 
19:77:3: På min nøds dag søker jeg Herren; min hånd er utrakt om natten og blir ikke trett, min sjel vil ikke la sig trøste. 
19:77:4: Jeg vil komme Gud i hu og sukke; jeg vil gruble, og min ånd vansmekter. Sela. 
19:77:5: Du holder mine øine oppe i nattevaktene; jeg er urolig og taler ikke. 
19:77:6: Jeg tenker på fordums dager, på de lengst fremfarne år. 
19:77:7: Jeg vil komme i hu mitt strengespill om natten, i mitt hjerte vil jeg gruble, og min ånd ransaker. 
19:77:8: Vil da Herren forkaste i all evighet, og vil han ikke mere bli ved å vise nåde? 
19:77:9: Er det for all tid ute med hans miskunnhet? er hans løfte blitt til intet slekt efter slekt? 
19:77:10: Har Gud glemt å være nådig? Har han i vrede tillukket sin barmhjertighet? Sela. 
19:77:11: Jeg sier: Dette er min plage, det er år fra den Høiestes høire hånd. 
19:77:12: Jeg vil forkynne Herrens gjerninger; for jeg vil komme dine under i hu fra fordums tid. 
19:77:13: Og jeg vil eftertenke alt ditt verk, og på dine store gjerninger vil jeg grunde. 
19:77:14: Gud! Din vei er i hellighet; hvem er en gud stor som Gud? 
19:77:15: Du er den Gud som gjør under; du har kunngjort din styrke blandt folkene. 
19:77:16: Du har forløst ditt folk med velde, Jakobs og Josefs barn. Sela. 
19:77:17: Vannene så dig, Gud, vannene så dig, de bevet, ja avgrunnene skalv. 
19:77:18: Skyene utøste vann, himlene lot sin røst høre, ja dine piler fløi hit og dit. 
19:77:19: Din tordens røst lød i stormhvirvelen, lyn lyste op jorderike, jorden bevet og skalv. 
19:77:20: Gjennem havet gikk din vei, og dine stier gjennem store vann, og dine fotspor blev ikke kjent. 
19:77:21: Du førte ditt folk som en hjord ved Moses' og Arons hånd. 
19:78:1: En læresalme av Asaf. Lytt, mitt folk, til min lære, bøi eders ører til min munns ord! 
19:78:2: Jeg vil oplate min munn med tankesprog, jeg vil la utstrømme gåtefulle ord fra fordums tid. 
19:78:3: Det vi har hørt og vet, og det våre fedre har fortalt oss, 
19:78:4: det vil vi ikke dølge for deres barn, men for den kommende slekt fortelle Herrens pris og hans styrke og de undergjerninger som han har gjort. 
19:78:5: Han har reist et vidnesbyrd i Jakob og satt en lov i Israel, som han bød våre fedre å kunngjøre sine barn, 
19:78:6: forat den kommende slekt, de barn som skulde fødes, kunde kjenne dem, kunde stå frem og fortelle dem for sine barn 
19:78:7: og sette sitt håp til Gud og ikke glemme Guds gjerninger, men holde hans bud 
19:78:8: og ikke være som deres fedre, en opsetsig og gjenstridig slekt, en slekt som ikke gjorde sitt hjerte fast, og hvis ånd ikke var trofast mot Gud. 
19:78:9: Efra'ims barn, de rustede bueskyttere, vendte om på stridens dag. 
19:78:10: De holdt ikke Guds pakt og vilde ikke vandre i hans lov, 
19:78:11: og de glemte hans store gjerninger og de under som han hadde latt dem se. 
19:78:12: For deres fedres øine hadde han gjort under i Egyptens land, på Soans mark*. # <* 4MO 13, 22.> 
19:78:13: Han kløvde havet og lot dem gå gjennem det og lot vannet stå som en dynge. 
19:78:14: Og han ledet dem ved skyen om dagen og hele natten ved ildens schmelk. 
19:78:15: Han kløvde klipper i ørkenen og gav dem å drikke som av store vanndyp. 
19:78:16: Og han lot bekker gå ut av klippen og vann flyte ned som strømmer. 
19:78:17: Men de blev ennu ved å synde mot ham, å være gjenstridige mot den Høieste i ørkenen. 
19:78:18: Og de fristet Gud i sitt hjerte, så de krevde mat efter sin lyst. 
19:78:19: Og de talte mot Gud, de sa: Kan vel Gud dekke bord i ørkenen? 
19:78:20: Se, han har slått klippen så det fløt ut vann, og bekker strømmet over; mon han også kan gi brød, eller kan han komme med kjøtt til sitt folk? 
19:78:21: Derfor, da Herren hørte det, harmedes han, og ild optendtes mot Jakob, og vrede reiste sig mot Israel, 
19:78:22: fordi de ikke trodde på Gud og ikke stolte på hans frelse. 
19:78:23: Og han gav skyene der oppe befaling og åpnet himmelens porter. 
19:78:24: Og han lot manna regne over dem til føde og gav dem himmelkorn. 
19:78:25: Englebrød* åt enhver; han sendte dem næring til mette. # <* d.e. brød fra englenes bolig, himmelbrød.> 
19:78:26: Han lot østenvinden fare ut i himmelen og førte sønnenvinden frem ved sin styrke. 
19:78:27: Og han lot kjøtt regne ned over dem som støv og vingede fugler som havets sand, 
19:78:28: og han lot dem falle ned midt i deres leir, rundt omkring deres boliger. 
19:78:29: Og de åt og blev såre mette, og det de lystet efter, gav han dem. 
19:78:30: De hadde ennu ikke latt fare det de lystet efter, ennu var deres mat i deres munn, 
19:78:31: da reiste Guds vrede sig mot dem, og han herjet blandt deres kraftfulle menn, og Israels unge menn slo han ned. 
19:78:32: Med alt dette syndet de enda og trodde ikke på hans undergjerninger. 
19:78:33: Derfor lot han deres dager svinne bort i tomhet og deres år i forskrekkelse. 
19:78:34: Når han herjet blandt dem, da spurte de efter ham og vendte om og søkte Gud 
19:78:35: og kom i hu at Gud var deres klippe, og den høieste Gud deres gjenløser. 
19:78:36: Men de smigret for ham med sin munn og løi for ham med sin tunge. 
19:78:37: Og deres hjerte hang ikke fast ved ham, og de var ikke tro mot hans pakt. 
19:78:38: Men han, han er miskunnelig, han tilgir misgjerning og forderver ikke; mange ganger lot han sin vrede vende om og lot ikke all sin harme bryte frem. 
19:78:39: Og han kom i hu at de var kjød, et åndepust som farer avsted og ikke kommer tilbake. 
19:78:40: Hvor titt var de ikke gjenstridige mot ham i ørkenen, gjorde ham sorg på de øde steder! 
19:78:41: Og de fristet Gud på ny og krenket Israels Hellige. 
19:78:42: De kom ikke hans hånd i hu den dag han forløste dem fra fienden, 
19:78:43: han som gjorde sine tegn i Egypten og sine under på Soans mark. 
19:78:44: Han gjorde deres elver til blod, og sine rinnende vann kunde de ikke drikke. 
19:78:45: Han sendte imot dem fluesvermer som fortærte dem, og frosk som fordervet dem. 
19:78:46: Og han gav gnageren* deres grøde og gresshoppen deres høst. # <* d.e. gresshoppen.> 
19:78:47: Han slo deres vintrær ned med hagl og deres morbærtrær med haglstener. 
19:78:48: Og han overgav deres fe til haglet og deres hjorder til ildsluer. 
19:78:49: Han slapp sin brennende vrede løs mot dem, harme og forbitrelse og trengsel, en sending av ulykkes-bud. 
19:78:50: Han brøt vei for sin vrede, sparte ikke deres sjel for døden, overgav deres liv til pesten. 
19:78:51: Og han slo alle førstefødte i Egypten, styrkens førstegrøde i Kams telter. 
19:78:52: Og han lot sitt folk bryte op som en fåreflokk og førte dem som en hjord i ørkenen. 
19:78:53: Og han ledet dem tryggelig, og de fryktet ikke, men havet skjulte deres fiender. 
19:78:54: Og han førte dem til sitt hellige landemerke, til det berg hans høire hånd hadde vunnet. 
19:78:55: Og han drev hedningene ut for deres åsyn og lot deres land tilfalle dem som arvedel og lot Israels stammer bo i deres telter. 
19:78:56: Men de fristet Gud, den Høieste, og var gjenstridige mot ham, og de aktet ikke på hans vidnesbyrd. 
19:78:57: De vek av og var troløse, som deres fedre, de vendte om, likesom en bue som svikter. 
19:78:58: Og de vakte hans harme med sine offerhauger og gjorde ham nidkjær med sine utskårne billeder. 
19:78:59: Gud hørte det og blev vred, og han blev såre kjed av Israel. 
19:78:60: Og han forlot sin bolig i Silo, det telt han hadde opslått blandt menneskene. 
19:78:61: Og han overgav sin styrke til fangenskap og sin herlighet i fiendens hånd. 
19:78:62: Og han overgav sitt folk til sverdet og harmedes på sin arv. 
19:78:63: Ild fortærte dets unge menn, og dets jomfruer fikk ingen brudesang. 
19:78:64: Dets prester falt for sverdet, og dets enker holdt ikke klagemål*. # <* nemlig over sine døde.> 
19:78:65: Da våknet Herren som en sovende, som en helt som jubler av vin. 
19:78:66: Og han slo sine motstandere tilbake, påførte dem en evig skam. 
19:78:67: Og han forkastet Josefs telt og utvalgte ikke Efra'ims stamme, 
19:78:68: men han utvalgte Juda stamme, Sions berg som han elsket. 
19:78:69: Og han bygget sin helligdom lik høie fjell, lik jorden, som han har grunnfestet for evig tid. 
19:78:70: Og han utvalgte David, sin tjener, og tok ham fra fårehegnene; 
19:78:71: fra de melkende får som han gikk bakefter, hentet han ham til å vokte Jakob, sitt folk, og Israel, sin arv. 
19:78:72: Og han voktet dem efter sitt hjertes opriktighet og ledet dem med sin forstandige hånd. 
19:79:1: En salme av Asaf. Gud! Hedninger har trengt inn i din arv, de har gjort ditt hellige tempel urent, de har gjort Jerusalem til grushoper. 
19:79:2: De har gitt dine tjeneres lik til føde for himmelens fugler, dine helliges kjøtt til ville dyr. 
19:79:3: De har utøst deres blod som vann rundt omkring Jerusalem, og der var ingen som la dem i graven. 
19:79:4: Vi er blitt til skam for våre naboer, til spott og hån for dem som er omkring oss. 
19:79:5: Hvor lenge, Herre, vil du være vred evindelig? Hvor lenge skal din nidkjærhet brenne som ild? 
19:79:6: Utøs din harme over hedningene, som ikke kjenner dig, og over de riker som ikke påkaller ditt navn! 
19:79:7: For de har fortært Jakob og lagt hans bolig øde. 
19:79:8: Tilregn oss ikke våre forfedres misgjerninger! La din miskunn snarlig komme oss i møte, for vi er såre elendige! 
19:79:9: Hjelp oss, vår frelses Gud, for ditt navns æres skyld, og fri oss og forlat oss våre synder for ditt navns skyld! 
19:79:10: Hvorfor skal hedningene si: Hvor er deres Gud? La det for våre øine kjennes blandt hedningene at dine tjeneres utøste blod blir hevnet, 
19:79:11: la den fangnes sukk komme for ditt åsyn, la dødens barn bli i live efter din arms styrke, 
19:79:12: og betal våre naboer syvfold i deres fang den hån som de har hånet dig med, Herre! 
19:79:13: Og vi, ditt folk og den hjord du før, vi vil love dig evindelig; fra slekt til slekt vil vi fortelle din pris. 
19:80:1: Til sangmesteren; efter "Liljer"*; et vidnesbyrd av Asaf; en salme. # <* SLM 45, 1.> 
19:80:2: Israels hyrde, vend øret til, du som fører Josef som en hjord! Du som troner over kjerubene, åpenbar dig i herlighet! 
19:80:3: Vekk op ditt velde for Efra'im og Benjamin og Manasse, og kom oss til frelse! 
19:80:4: Gud, reis oss op igjen, og la ditt åsyn lyse, så vi blir frelst! 
19:80:5: Herre, Gud, hærskarenes Gud hvor lenge har du latt din vrede ryke uten å ense ditt folks bønn! 
19:80:6: Du har gitt dem tårebrød å ete og tårer å drikke i fullt mål. 
19:80:7: Du gjør oss til en trette for våre naboer, og våre fiender spotter med lyst. 
19:80:8: Gud, hærskarenes Gud, reis oss op igjen, og la ditt åsyn lyse, så vi blir frelst! 
19:80:9: Et vintre tok du op fra Egypten, du drev hedningefolk ut og plantet det. 
19:80:10: Du ryddet op for det, og det festet sine røtter og fylte landet. 
19:80:11: Fjell blev skjult av dets skygge, og Guds sedrer av dets grener. 
19:80:12: Det strakte ut sine grener til havet og sine skudd bort imot elven. 
19:80:13: Hvorfor har du revet ned dets hegn, så alle de som går forbi på veien, plukker av det? 
19:80:14: Svinet fra skogen gnager på det, og hvad som rører sig på marken, eter av det. 
19:80:15: Gud, hærskarenes Gud, vend tilbake, sku ned fra himmelen og se og ta dig av dette vintre 
19:80:16: og vern om det som din høire hånd har plantet, og om den sønn du har utvalgt dig! 
19:80:17: Det er brent med ild, det er avhugget; for ditt åsyns trusel går de under. 
19:80:18: La din hånd være over den mann som er ved din høire hånd, over den menneskesønn du har utvalgt dig, 
19:80:19: så vil vi ikke vike fra dig! Gjør oss levende igjen, så vil vi påkalle ditt navn! 
19:80:20: Herre, Gud, hærskarenes Gud, reis oss op igjen, la ditt åsyn lyse, så vi blir frelst! 
19:81:1: Til sangmesteren, efter Gittit*; av Asaf. # <* SLM 8, 1.> 
19:81:2: Juble for Gud, vår styrke, rop med glede for Jakobs Gud! 
19:81:3: Stem i sang og la pauken lyde, den liflige citar tillikemed harpen! 
19:81:4: Støt i basun i måneden*, ved fullmånen, på vår høitids dag! # <* 2MO 12, 2 fg.> 
19:81:5: For det er en lov for Israel, en rett for Jakobs Gud. 
19:81:6: Han satte det til et vidnesbyrd i Josef da han* drog ut gjennem Egyptens land. - Jeg hørte en røst som jeg ikke kjente:** # <* d.e. Josef.> # <** 5MO 4, 33 fg. 5, 22 fg.> 
19:81:7: Jeg fridde hans skulder fra byrden, hans hender slapp fri fra bærekurven. 
19:81:8: I nøden ropte du, og jeg fridde dig ut; jeg svarte dig, skjult i tordenskyen, jeg prøvde dig ved Meriba-vannene. Sela. 
19:81:9: Hør, mitt folk, og jeg vil vidne for dig! Israel, o, at du vilde høre mig: 
19:81:10: Det skal ikke være nogen fremmed gud hos dig, og du skal ikke tilbede utlendingens gud. 
19:81:11: Jeg er Herren din Gud, som førte dig op av Egyptens land; lukk din munn vidt op, at jeg kan fylle den! 
19:81:12: Men mitt folk hørte ikke min røst, og Israel vilde ikke lyde mig. 
19:81:13: Så lot jeg dem fare i sitt hjertes hårdhet, forat de skulde vandre i sine egne onde råd. 
19:81:14: O, at mitt folk vilde høre mig, og at Israel vilde vandre på mine veier! 
19:81:15: Om en liten stund vilde jeg da ydmyke deres fiender og vende min hånd imot deres motstandere. 
19:81:16: De som hater Herren, skulde smigre for dem, og deres tid skulde vare evindelig. 
19:81:17: Og han skulde fø dem med den beste hvete, og jeg skulde mette dig med honning fra klippen. 
19:82:1: En salme av Asaf. Gud står i Guds menighet; midt iblandt guder* holder han dom: # <* SLM 82, 6.> 
19:82:2: Hvor lenge vil I dømme urettferdig og holde med de ugudelige? Sela. 
19:82:3: Døm den ringe og farløse, la den elendige og fattige få sin rett! 
19:82:4: Redd den ringe og trengende, frels ham fra de ugudeliges hånd! 
19:82:5: De skjønner intet, og de forstår intet, de vandrer i mørke; alle jordens grunnvoller vakler. 
19:82:6: Jeg har sagt: I er guder, og I er alle den Høiestes sønner. 
19:82:7: Men sannelig, som mennesker skal I dø, og som en av fyrstene skal I falle. 
19:82:8: Reis dig, Gud, hold dom over jorden! For du har alle hedningene i eie! 
19:83:1: En sang, en salme av Asaf. 
19:83:2: Gud, ti ikke! Vær ikke stille og hold dig ikke rolig, Gud! 
19:83:3: For se, dine fiender larmer, og de som hater dig, løfter hodet. 
19:83:4: Mot ditt folk legger de med svik hemmelige råd, og de rådslår mot dem du verner. 
19:83:5: De sier: Kom og la oss utslette dem, så de ikke mere er et folk, og Israels navn skal ikke mere kommes i hu. 
19:83:6: For de har av hjertet rådslått med hverandre; mot dig inngår de pakt, 
19:83:7: Edoms telt og ismaelittene, Moab og hagarenene, 
19:83:8: Gebal og Ammon og Amalek, Filistea tillikemed innbyggerne av Tyrus. 
19:83:9: Også Assur har gitt sig i lag med dem, de er blitt Lots barns arm. Sela. 
19:83:10: Gjør med dem som med Midian, som med Sisera, som med Jabin ved bekken Kison! 
19:83:11: De blev ødelagt ved En-Dor, de blev til gjødsel for jorden. 
19:83:12: La det gå dem, deres ypperste menn, som Oreb og som Se'eb, og alle deres fyrster som Sebah og som Salmunna, 
19:83:13: dem som sier: Vi vil ta oss Guds boliger til eie! 
19:83:14: Min Gud, gjør dem som en støvhvirvel, som agner for vinden! 
19:83:15: Som en ild som tender en skog, og som en lue som setter fjell i brand, 
19:83:16: således forfølge du dem med din storm og forferde du dem med ditt stormvær! 
19:83:17: Fyll deres åsyn med skam, så de søker ditt navn, Herre! 
19:83:18: La dem blues og forferdes til evig tid, la dem bli til skamme og gå under! 
19:83:19: Og la dem kjenne at du alene har navnet Herre, den Høieste over all jorden! 
19:84:1: Til sangmesteren, efter Gittit*; av Korahs barn; en salme. # <* SLM 8, 1.> 
19:84:2: Hvor elskelige dine boliger er, Herre, hærskarenes Gud! 
19:84:3: Min sjel lenges, ja vansmekter av lengsel efter Herrens forgårder; mitt hjerte og mitt kjød roper med fryd til den levende Gud. 
19:84:4: Spurven har jo funnet sig et hus, og svalen et rede hvor den har lagt sine unger - dine alter, Herre, hærskarenes Gud, min konge og min Gud! 
19:84:5: Salige er de som bor i ditt hus; de skal stadig love dig. Sela. 
19:84:6: Salig er det menneske som har sin styrke i dig, de hvis hu står til de jevne veier*. # <* d.e. veiene til Guds hus.> 
19:84:7: Når de vandrer gjennem tåredalen, gjør de den til en kildevang, og høstregnet dekker den med velsignelse*. # <* d.e. endog de største trengsler blir dem til velsignelse.> 
19:84:8: De går frem fra kraft til kraft, de treder frem for Gud på Sion. 
19:84:9: Herre, Gud, hærskarenes Gud, hør min bønn! Vend øret til, Jakobs Gud! Sela. 
19:84:10: Gud, vårt skjold, se til og sku din salvedes åsyn! 
19:84:11: For en dag i dine forgårder er bedre enn ellers tusen; jeg vil heller stå ved dørtreskelen i min Guds hus enn bo i ugudelighets telt. 
19:84:12: For Gud Herren er sol og skjold, Herren gir nåde og ære; han nekter ikke dem noget godt som vandrer i uskyld. 
19:84:13: Herre, hærskarenes Gud, salig er det menneske som setter sin lit til dig. 
19:85:1: Til sangmesteren; av Korahs barn; en salme. 
19:85:2: Du har fordum, Herre, vært nådig mot ditt land, du lot Jakobs fangenskap ophøre. 
19:85:3: Du tok bort ditt folks misgjerning, du skjulte all deres synd. Sela. 
19:85:4: Du tok bort all din harme, du lot din brennende vrede vende om. 
19:85:5: Vend om til oss, vår frelses Gud, og gjør din harme imot oss til intet! 
19:85:6: Vil du evindelig være vred på oss? Vil du la din vrede vare fra slekt til slekt? 
19:85:7: Vil du ikke gjøre oss levende igjen, så ditt folk kan glede sig i dig? 
19:85:8: Herre, la oss se din miskunnhet, og gi oss din frelse! 
19:85:9: Jeg vil høre hvad Gud Herren taler; for han taler fred til sitt folk og til sine fromme - bare de ikke vender tilbake til dårskap. 
19:85:10: Ja, hans frelse er nær hos dem som frykter ham, forat herlighet skal bo i vårt land. 
19:85:11: Nåde og sannhet skal møte hverandre, rettferd og fred kysse hverandre. 
19:85:12: Sannhet skal vokse op av jorden, og rettferd skue ned fra himmelen. 
19:85:13: Herren skal også gi det som godt er, og vårt land gi sin grøde. 
19:85:14: Rettferd skal gå frem for hans åsyn og stadig følge i hans spor. 
19:86:1: En bønn av David. Bøi, Herre, ditt øre, svar mig! for jeg er elendig og fattig. 
19:86:2: Bevar min sjel! for jeg er from. Frels din tjener, du min Gud, ham som setter sin lit til dig! 
19:86:3: Vær mig nådig, Herre! for til dig roper jeg hele dagen. 
19:86:4: Gled din tjeners sjel! for til dig, Herre, løfter jeg min sjel. 
19:86:5: For du, Herre, er god og villig til å forlate og rik på miskunnhet mot alle dem som påkaller dig. 
19:86:6: Vend øret, Herre, til min bønn, og merk på mine inderlige bønners røst! 
19:86:7: På min nøds dag kaller jeg på dig, for du svarer mig. 
19:86:8: Ingen er som du blandt gudene, Herre, og intet er som dine gjerninger. 
19:86:9: Alle hedningene, som du har skapt, skal komme og tilbede for ditt åsyn, Herre, og ære ditt navn. 
19:86:10: For du er stor og den som gjør undergjerninger; du alene er Gud. 
19:86:11: Lær mig, Herre, din vei! Jeg vil vandre i din sannhet. Gi mig et udelt hjerte til å frykte ditt navn! 
19:86:12: Jeg vil prise dig, Herre min Gud, av hele mitt hjerte, og jeg vil ære ditt navn evindelig. 
19:86:13: For din miskunnhet er stor over mig, og du har utfridd min sjel av det dype dødsrike. 
19:86:14: Gud! Overmodige har reist sig imot mig, og en hop av voldsmenn står mig efter livet, og de har ikke dig for øie. 
19:86:15: Men du, Herre, er en barmhjertig og nådig Gud, langmodig og rik på nåde og sannhet. 
19:86:16: Vend dig til mig og vær mig nådig, gi din tjener din styrke og frels din tjenestekvinnes sønn! 
19:86:17: Gjør et tegn med mig til det gode, forat mine avindsmenn kan se det og bli til skamme, fordi du, Herre, har hjulpet mig og trøstet mig. 
19:87:1: Av Korahs barn; en salme, en sang. Den stad han har grunnfestet på de hellige berg, 
19:87:2: Sions porter elsker Herren fremfor alle Jakobs boliger. 
19:87:3: Herlige ting er sagt om dig, du Guds stad. Sela. 
19:87:4: Jeg nevner Rahab* og Babel blandt dem som kjenner mig; se filisteren og tyrieren med etioperen: Denne er født der. # <* d.e. Egypten.> 
19:87:5: Og om Sion skal det sies: Hver og en er født der. Og han, den Høieste, gjør det fast. 
19:87:6: Herren skal telle når folkene blir opskrevet, og si: Denne er født der. Sela. 
19:87:7: Og de som synger og danser, skal si: Alle mine kilder er i dig. 
19:88:1: En sang; en salme av Korahs barn; til sangmesteren; efter Mahalat leannot*; en læresalme av Heman, esrahitten. # <* meningen uviss.> 
19:88:2: Herre, min frelses Gud! Om dagen og om natten roper jeg for dig. 
19:88:3: La min bønn komme for ditt åsyn, bøi ditt øre til mitt klagerop! 
19:88:4: For min sjel er mett av ulykker, og mitt liv er kommet nær til dødsriket. 
19:88:5: Jeg aktes like med dem som farer ned i hulen; jeg er som en mann uten kraft, 
19:88:6: frigitt* som en av de døde, lik de ihjelslagne som ligger i graven, som du ikke mere kommer i hu, fordi de er skilt fra din hånd. # <* d.e. ikke lenger stående i din tjeneste.> 
19:88:7: Du har lagt mig i dypenes hule, på mørke steder, i avgrunner. 
19:88:8: Din vrede tynger på mig, og med alle dine bølger trenger du mig. Sela. 
19:88:9: Du har drevet mine kjenninger langt bort fra mig, du har gjort mig vederstyggelig for dem; jeg er stengt inne og kommer ikke ut. 
19:88:10: Mitt øie er vansmektet av elendighet; jeg har påkalt dig, Herre, hver dag, jeg har utbredt mine hender til dig. 
19:88:11: Mon du gjør undergjerninger for de døde, eller mon dødninger står op og priser dig? Sela. 
19:88:12: Mon der fortelles i graven om din miskunnhet, i avgrunnen om din trofasthet? 
19:88:13: Mon din undergjerning blir kjent i mørket, og din rettferdighet i glemselens land? 
19:88:14: Men jeg roper til dig, Herre, og om morgenen kommer min bønn dig i møte. 
19:88:15: Hvorfor, Herre, forkaster du min sjel? Hvorfor skjuler du ditt åsyn for mig? 
19:88:16: Elendig er jeg og døende fra ungdommen av; jeg bærer dine redsler, jeg må fortvile. 
19:88:17: Din vredes luer har gått over mig, dine redsler har tilintetgjort mig. 
19:88:18: De har omgitt mig som vann hele dagen, de har omringet mig alle sammen. 
19:88:19: Du har drevet venn og næste langt bort fra mig; mine kjenninger er det mørke sted. 
19:89:1: En læresalme av Etan, esrahitten. 
19:89:2: Om Herrens nådegjerninger vil jeg synge til evig tid; fra slekt til slekt vil jeg kunngjøre din trofasthet med min munn. 
19:89:3: For jeg sier: Miskunnhet bygges op til evig tid, i himmelen grunnfester du din trofasthet. 
19:89:4: [Du sier:] Jeg har gjort en pakt med min utvalgte, jeg har svoret David, min tjener: 
19:89:5: Til evig tid vil jeg grunnfeste ditt avkom, og jeg vil bygge din trone fra slekt til slekt. Sela. 
19:89:6: Og himlene priser din underfulle gjerning, Herre, og din trofasthet prises i de helliges forsamling. 
19:89:7: For hvem i det høie er å ligne med Herren? Hvem er Herren lik blandt Guds sønner, 
19:89:8: en Gud, såre forferdelig i de helliges hemmelige råd og fryktelig for alle dem som er omkring ham? 
19:89:9: Herre, hærskarenes Gud, hvem er sterk som du, Herre? Og din trofasthet er rundt omkring dig. 
19:89:10: Du er den som hersker over havets overmot; når dets bølger reiser sig, lar du dem legge sig. 
19:89:11: Du har sønderknust Rahab* som en ihjelslått; med din styrkes arm har du spredt dine fiender. # <* SLM 87, 4.> 
19:89:12: Dig hører himlene til, dig også jorden; jorderike og alt det som fyller det - du har grunnfestet dem; 
19:89:13: nord og syd - du har skapt dem; Tabor og Hermon jubler over ditt navn. 
19:89:14: Du har en arm med velde; sterk er din hånd, ophøiet er din høire hånd. 
19:89:15: Rettferd og rett er din trones grunnvoll; nåde og sannhet går frem for ditt åsyn. 
19:89:16: Salig er det folk som kjenner til jubel*; Herre, i ditt åsyns schmelk skal de vandre. # <* nemlig over Herren, sin konge.> 
19:89:17: I ditt navn skal de fryde sig hele dagen, og ved din rettferdighet blir de ophøiet. 
19:89:18: For du er deres styrkes pryd, og ved din godhet ophøier du vårt horn. 
19:89:19: For Herren er vårt skjold, og Israels Hellige vår konge. 
19:89:20: Dengang* talte du i et syn til dine fromme** og sa: Jeg har nedlagt hjelp hos en helt, jeg har ophøiet en ung mann av folket. # <* SLM 89, 4. 5.> # <** Guds folk.> 
19:89:21: Jeg har funnet David, min tjener, jeg har salvet ham med min hellige olje. 
19:89:22: Min hånd skal alltid være med ham, og min arm skal gi ham styrke. 
19:89:23: Fienden skal ikke plage ham, og den urettferdige skal ikke undertrykke ham. 
19:89:24: Men jeg vil sønderknuse hans motstandere for hans åsyn og slå dem som hater ham. 
19:89:25: Og min trofasthet og min miskunnhet skal være med ham, og i mitt navn skal hans horn ophøies. 
19:89:26: Og jeg vil la ham legge sin hånd på havet og sin høire hånd på elvene. 
19:89:27: Han skal rope til mig: Du er min far, min Gud og min frelses klippe. 
19:89:28: Og jeg vil gjøre ham til den førstefødte, til den høieste blandt kongene på jorden. 
19:89:29: Jeg vil bevare min miskunnhet mot ham til evig tid, og min pakt skal stå fast for ham. 
19:89:30: Og jeg vil la hans avkom bli til evig tid og hans trone som himmelens dager. 
19:89:31: Dersom hans barn forlater min lov og ikke vandrer i mine bud, 
19:89:32: dersom de krenker mine forskrifter og ikke holder mine befalinger, 
19:89:33: da vil jeg hjemsøke deres synd med ris og deres misgjerning med plager. 
19:89:34: Men min miskunnhet vil jeg ikke ta fra ham, og min trofasthet skal ikke svikte; 
19:89:35: jeg vil ikke bryte min pakt og ikke forandre hvad som gikk ut fra mine leber. 
19:89:36: Ett har jeg svoret ved min hellighet, sannelig, for David vil jeg ikke lyve: 
19:89:37: Hans avkom skal bli til evig tid, og hans trone som solen for mitt åsyn. 
19:89:38: Som månen skal den stå evindelig, og vidnet i det høie er trofast. Sela. 
19:89:39: Og du har forkastet og forsmådd, du er blitt harm på din salvede. 
19:89:40: Du har rystet av dig pakten med din tjener, du har vanhelliget hans krone ned i støvet. 
19:89:41: Du har revet ned alle hans murer, du har lagt hans festninger i grus. 
19:89:42: Alle de som går forbi på veien, har plyndret ham; han er blitt til hån for sine naboer. 
19:89:43: Du har ophøiet hans motstanderes høire hånd, du har gledet alle hans fiender. 
19:89:44: Og du lot hans skarpe sverd vike og lot ham ikke holde stand i striden. 
19:89:45: Du har gjort ende på hans glans og kastet hans trone i støvet. 
19:89:46: Du har forkortet hans ungdoms dager, du har dekket ham med skam. Sela. 
19:89:47: Hvor lenge, Herre, vil du skjule dig evindelig? Hvor lenge skal din harme brenne som ild? 
19:89:48: Kom dog i hu hvor kort mitt liv er, hvor forgjengelige du har skapt alle menneskenes barn! 
19:89:49: Hvem er den mann som lever og ikke ser døden, som frir sin sjel fra dødsrikets vold? Sela. 
19:89:50: Hvor er, Herre, dine forrige nådegjerninger, som du tilsvor David i din trofasthet? 
19:89:51: Kom i hu, Herre, dine tjeneres vanære, at jeg må bære alle de mange folk i mitt skjød, 
19:89:52: at dine fiender håner, Herre, at de håner din salvedes fotspor! 
19:89:53: Lovet være Herren til evig tid! Amen, amen. 
19:90:1: En bønn av Moses, den Guds mann. Herre! Du har vært oss en bolig fra slekt til slekt. 
19:90:2: Før fjellene blev til, og du skapte jorden og jorderike, ja fra evighet til evighet er du, Gud. 
19:90:3: Du byder mennesket vende tilbake til støv og sier: Vend tilbake, I menneskebarn! 
19:90:4: For tusen år er i dine øine som den dag igår når den farer bort, som en vakt om natten. 
19:90:5: Du skyller dem bort, de blir som en søvn. Om morgenen er de som det groende gress; 
19:90:6: om morgenen blomstrer det og gror, om aftenen visner det og blir tørt. 
19:90:7: For vi har gått til grunne ved din vrede, og ved din harme er vi faret bort med forferdelse. 
19:90:8: Du har satt våre misgjerninger for dine øine, vår skjulte synd for ditt åsyns schmelk. 
19:90:9: For alle våre dager er bortflyktet i din vrede; vi har levd våre år til ende som et sukk. 
19:90:10: Vårt livs tid, den er sytti år og, når der er megen styrke, åtti år, og dets herlighet er møie og tomhet; for hastig blev vi drevet fremad, og vi fløi avsted. 
19:90:11: Hvem kjenner din vredes styrke og din harme, således som frykten for dig krever? 
19:90:12: Lær oss å telle våre dager, at vi kan få visdom i hjertet! 
19:90:13: Vend om, Herre! Hvor lenge? Ha medynk med dine tjenere! 
19:90:14: Mett oss, når morgenen kommer, med din miskunnhet, så vil vi juble og være glade alle våre dager! 
19:90:15: Gled oss så mange dager som du har plaget oss, så mange år som vi har sett ulykke! 
19:90:16: La din gjerning åpenbares for dine tjenere og din herlighet over deres barn! 
19:90:17: Og Herrens, vår Guds liflighet være over oss, og våre henders gjerning fremme du for oss, ja, våre henders gjerning, den fremme du! 
19:91:1: Den som sitter i den Høiestes skjul, som bor i den Allmektiges skygge, 
19:91:2: han sier til Herren: Min tilflukt og min borg, min Gud som jeg setter min lit til! 
19:91:3: For han frir dig av fuglefangerens snare, fra ødeleggende pest. 
19:91:4: Med sine vingefjærer dekker han dig, og under hans vinger finner du ly; hans trofasthet er skjold og vern. 
19:91:5: Du skal ikke frykte for nattens redsler, for pil som flyver om dagen, 
19:91:6: for pest som farer frem i mørket, for sott som ødelegger om middagen. 
19:91:7: Faller tusen ved din side og ti tusen ved din høire hånd, til dig skal det ikke nå. 
19:91:8: Du skal bare skue det med dine øine, og se hvorledes de ugudelige får sin lønn. 
19:91:9: For du, Herre, er min tilflukt. Den Høieste har du gjort til din bolig; 
19:91:10: intet ondt skal vederfares dig, og ingen plage skal komme nær til ditt telt. 
19:91:11: For han skal gi sine engler befaling om dig at de skal bevare dig på alle dine veier. 
19:91:12: De skal bære dig på hendene, forat du ikke skal støte din fot på nogen sten. 
19:91:13: På løve og huggorm skal du trå; du skal trå ned unge løver og slanger. 
19:91:14: For han henger fast ved mig, og jeg vil utfri ham; jeg vil føre ham i sikkerhet, for han kjenner mitt navn. 
19:91:15: Han skal påkalle mig, og jeg vil svare ham; jeg er med ham i nøden, jeg vil utfri ham og føre ham til ære. 
19:91:16: Med et langt liv vil jeg mette ham og la ham skue min frelse. 
19:92:1: En salme, en sang til sabbatsdagen. 
19:92:2: Det er godt å prise Herren og å lovsynge ditt navn, du Høieste, 
19:92:3: å kunngjøre din miskunnhet om morgenen og din trofasthet om nettene 
19:92:4: til tistrenget citar og til harpe, til tankefullt spill på citar. 
19:92:5: For du har gledet mig, Herre, med ditt verk, jeg jubler over dine henders gjerninger. 
19:92:6: Hvor store dine gjerninger er, Herre! Såre dype er dine tanker. 
19:92:7: En ufornuftig mann kjenner det ikke, og en dåre forstår ikke dette. 
19:92:8: Når de ugudelige spirer som gresset, og alle de som gjør urett, blomstrer, så er det til deres ødeleggelse for evig tid. 
19:92:9: Men du er høi til evig tid, Herre! 
19:92:10: For se, dine fiender, Herre, for se, dine fiender forgår; alle de som gjør urett, blir adspredt. 
19:92:11: Og du ophøier mitt horn som villoksens; jeg er overgytt med frisk olje. 
19:92:12: Og mitt øie ser med fryd på mine motstandere; mine ører hører med glede om de onde som står op imot mig. 
19:92:13: Den rettferdige spirer som palmen; som en seder på Libanon vokser han. 
19:92:14: De er plantet i Herrens hus, de blomstrer i vår Guds forgårder. 
19:92:15: Enn i gråhåret alder skyter de friske skudd; de er frodige og grønne 
19:92:16: for å kunngjøre at Herren er rettvis, han, min klippe, og at det ikke er urett i ham. 
19:93:1: Herren er blitt konge, han har klædd sig i høihet; Herren har klædd sig, har omgjordet sig med styrke, og jorderike står fast, det rokkes ikke. 
19:93:2: Fast er din trone fra fordums tid; fra evighet er du. 
19:93:3: Strømmer har opløftet, Herre, strømmer har opløftet sin røst, strømmer opløfter sin brusen. 
19:93:4: Mere enn røsten av de store, de herlige vann, havets brenninger, er Herren herlig i det høie. 
19:93:5: Dine vidnesbyrd er såre trofaste; for ditt hus sømmer sig hellighet, Herre, så lenge dagene varer. 
19:94:1: Du hevnens Gud, Herre, du hevnens Gud, åpenbar dig i herlighet! 
19:94:2: Reis dig, du jordens dommer, la gjengjeldelse komme over de overmodige! 
19:94:3: Hvor lenge skal de ugudelige, Herre, hvor lenge skal de ugudelige fryde sig? 
19:94:4: De utgyder en strøm av ord, de fører frekk tale; alle de som gjør urett, taler store ord. 
19:94:5: Ditt folk, Herre, knuser de, og din arv plager de. 
19:94:6: Enken og den fremmede slår de ihjel, og farløse myrder de. 
19:94:7: Og de sier: Herren ser ikke, og Jakobs Gud gir ikke akt. 
19:94:8: Gi dog akt, I ufornuftige blandt folket, og I dårer, når vil I bli kloke? 
19:94:9: Mon han som planter øret, ikke skulde høre? Mon han som skaper øiet, ikke skulde se? 
19:94:10: Mon han som refser hedningene, ikke skulde straffe, han som gir menneskene forstand? 
19:94:11: Herren kjenner menneskenes tanker, han vet at de er tomhet. 
19:94:12: Salig er den mann som du, Herre, refser og gir lærdom av din lov 
19:94:13: for å gi ham ro for onde dager, inntil det blir gravd en grav for den ugudelige. 
19:94:14: For Herren skal ikke forkaste sitt folk og ikke forlate sin arv; 
19:94:15: for dommen skal vende tilbake til rettferdighet, og alle de opriktige av hjertet skal gi den medhold. 
19:94:16: Hvem reiser sig for mig imot de onde? Hvem stiller sig frem for mig imot dem som gjør urett? 
19:94:17: Dersom ikke Herren var min hjelp, vilde min sjel snart bo i dødsrikets stillhet. 
19:94:18: Når jeg sier: Min fot vakler, da holder din miskunnhet mig oppe, Herre! 
19:94:19: Når mine urolige tanker i mitt hjerte blir mange, da husvaler din trøst min sjel. 
19:94:20: Har vel fordervelsens domstol noget samfund med dig, der hvor de skaper urett under skinn av rett? 
19:94:21: De slår sig skarevis sammen imot den rettferdiges sjel, og uskyldig blod dømmer de skyldig. 
19:94:22: Da blir Herren mig en borg, og min Gud min tilflukts klippe. 
19:94:23: Og han lar deres urett komme tilbake over dem, og for deres ondskaps skyld skal han utrydde dem; ja, Herren vår Gud skal utrydde dem. 
19:95:1: Kom, la oss juble for Herren, la oss rope med fryd for vår frelses klippe! 
19:95:2: La oss trede frem for hans åsyn med pris, la oss juble for ham med salmer! 
19:95:3: For Herren er en stor Gud og en stor konge over alle guder, 
19:95:4: han som har jordens dyp i sin hånd og fjellenes høider i eie, 
19:95:5: han som eier havet, for han har skapt det, og hans hender har gjort det tørre land. 
19:95:6: Kom, la oss kaste oss ned og bøie kne, la oss knele for Herrens, vår skapers åsyn! 
19:95:7: For han er vår Gud, og vi er det folk han før, og den hjord hans hånd leder. Vilde I dog idag høre hans røst! 
19:95:8: Forherd ikke eders hjerte, likesom ved Meriba, likesom på Massadagen i ørkenen, 
19:95:9: hvor eders fedre fristet mig! De satte mig på prøve, de som dog hadde sett min gjerning. 
19:95:10: Firti år vemmedes jeg ved den slekt, og jeg sa: De er et folk med forvillet hjerte, og de kjenner ikke mine veier. 
19:95:11: Så svor jeg i min vrede: Sannelig, de skal ikke komme inn til min hvile. 
19:96:1: Syng for Herren en ny sang, syng for Herren, all jorden! 
19:96:2: Syng for Herren, lov hans navn, forkynn fra dag til dag hans frelse! 
19:96:3: Fortell blandt hedningene hans ære, blandt alle folkene hans undergjerninger! 
19:96:4: For stor er Herren og høilovet, forferdelig er han over alle guder. 
19:96:5: For alle folkenes guder er intet; men Herren har gjort himmelen. 
19:96:6: Høihet og herlighet er for hans åsyn, styrke og prydelse er i hans helligdom. 
19:96:7: Gi Herren, I folkeslekter, gi Herren ære og makt! 
19:96:8: Gi Herren hans navns ære, bær frem gaver og kom til hans forgårder! 
19:96:9: Tilbed Herren i hellig prydelse, bev for hans åsyn, all jorden! 
19:96:10: Si blandt hedningene: Herren er blitt konge, og jorderike står fast, det rokkes ikke; han dømmer folkene med rettvishet. 
19:96:11: Himmelen glede sig, og jorden fryde sig, havet bruse og alt det som fyller det! 
19:96:12: Marken fryde sig og alt det som er på den! Da jubler alle trær i skogen 
19:96:13: for Herrens åsyn; for han kommer, han kommer for å dømme jorden; han skal dømme jorderike i rettferdighet og folkene i sin trofasthet. 
19:97:1: Herren er blitt konge; jorden fryde sig, mange øer glede sig! 
19:97:2: Skyer og mørke er rundt omkring ham, rettferd og rett er hans trones grunnvoll. 
19:97:3: Ild går foran hans åsyn og setter hans fiender i brand rundt omkring. 
19:97:4: Hans lyn oplyser jorderike; jorden ser det og bever. 
19:97:5: Fjellene smelter som voks for Herrens åsyn, for all jordens herres åsyn. 
19:97:6: Himlene kunngjør hans rettferdighet, og alle folkene ser hans ære. 
19:97:7: Til skamme blir alle de som dyrker utskårne billeder, som roser sig av avguder; tilbed ham, alle guder! 
19:97:8: Sion hører det og gleder sig, og Judas døtre fryder sig over dine dommer, Herre! 
19:97:9: For du, Herre, er den Høieste over all jorden, du er såre ophøiet over alle guder. 
19:97:10: I som elsker Herren, hat det onde! Han bevarer sine frommes sjeler; han frir dem ut av de ugudeliges hånd. 
19:97:11: Schmelk er utsådd for den rettferdige, og glede for de opriktige av hjertet. 
19:97:12: Gled eder, I rettferdige i Herren, og pris hans hellige navn! 
19:98:1: En salme. Syng Herren en ny sang! For han har gjort underlige ting; hans høire hånd og hans hellige arm har hjulpet ham. 
19:98:2: Herren har kunngjort sin frelse, åpenbaret sin rettferdighet for hedningenes øine. 
19:98:3: Han har kommet i hu sin miskunnhet og sin trofasthet mot Israels hus; alle jordens ender har sett vår Guds frelse. 
19:98:4: Rop med glede for Herren, all jorden, bryt ut i jubel og lovsang! 
19:98:5: Lovsyng Herren til citar, til citar og med sangens røst, 
19:98:6: til trompeter og basunens røst! Rop med fryd for kongens, Herrens åsyn! 
19:98:7: Havet bruse og alt det som fyller det, jorderike og de som bor der! 
19:98:8: Strømmene klappe i hender, fjellene juble alle sammen 
19:98:9: for Herrens åsyn, for han kommer for å dømme jorden; han skal dømme jorderike med rettferdighet og folkene med rettvishet. 
19:99:1: Herren er blitt konge, folkene bever, han som troner over kjeruber, jorden ryster. 
19:99:2: Herren er stor i Sion, og ophøiet er han over alle folkene. 
19:99:3: De skal prise ditt navn, det store og forferdelige; hellig er han. 
19:99:4: Og i sin styrke elsker kongen rettferd; du har grunnfestet rettvishet, du har gjort rett og rettferdighet i Jakob. 
19:99:5: Ophøi Herren vår Gud, og kast eder ned for hans føtters skammel! Hellig er han. 
19:99:6: Moses og Aron var blandt hans prester, og Samuel blandt dem som påkalte hans navn; de ropte til Herren, og han svarte dem. 
19:99:7: I en skystøtte talte han til dem; de holdt hans vidnesbyrd og den lov han gav dem. 
19:99:8: Herre vår Gud, du svarte dem; du var dem en Gud som tilgav dem, men også en hevner over deres gjerninger. 
19:99:9: Ophøi Herren vår Gud, og kast eder ned for hans hellige berg! For hellig er Herren vår Gud. 
19:100:1:En salme til lovprisning. Rop med fryd for Herren, all jorden! 
19:100:2:Tjen Herren med glede, kom frem for hans åsyn med jubel! 
19:100:3:Kjenn at Herren er Gud! Han har skapt oss, og ikke vi selv, til sitt folk og til den hjord han før. 
19:100:4:Gå inn i hans porter med pris, i hans forgårder med lov, pris ham, lov hans navn! 
19:100:5:For Herren er god; hans miskunnhet varer til evig tid, og hans trofasthet fra slekt til slekt. 
19:101:1:Av David; en salme. Om miskunn og rett vil jeg synge; dig, Herre, vil jeg lovsynge. 
19:101:2:Jeg vil akte på den rettsindiges vei - når vil du komme til mig? Jeg vil vandre i mitt hjertes uskyld i mitt hus. 
19:101:3:Jeg vil ikke sette mig fore noget nidingsverk; å gjøre overtredelser hater jeg, det skal ikke henge ved mig. 
19:101:4:Et falskt hjerte skal vike fra mig, den onde vil jeg ikke vite av. 
19:101:5:Den som baktaler sin næste i lønndom, ham vil jeg utrydde; den som har stolte øine og et opblåst hjerte, ham kan jeg ikke tåle. 
19:101:6:Mine øine ser efter de trofaste i landet, forat de skal bo hos mig; den som vandrer på den rettsindiges vei, han skal tjene mig. 
19:101:7:Der skal ikke i mitt hus bo nogen som farer med svik; den som taler løgn, skal ikke bli stående for mine øine. 
19:101:8:Hver morgen vil jeg tilintetgjøre alle ugudelige i landet for å utrydde av Herrens stad alle dem som gjør urett. 
19:102:1:En bønn av en elendig, når han vansmekter og utøser sin sorg for Herrens åsyn. 
19:102:2:Herre, hør min bønn og la mitt rop komme til dig! 
19:102:3:Skjul ikke ditt åsyn for mig på den dag jeg er i nød! Bøi ditt øre til mig! På den dag jeg roper, skynd dig å svare mig! 
19:102:4:For mine dager er faret bort som en røk, og mine ben er forbrent som en brand. 
19:102:5:Mitt hjerte er stukket som en urt og fortørket; for jeg har glemt å ete mitt brød. 
19:102:6:For mine lydelige sukks skyld henger mine ben ved mitt kjøtt. 
19:102:7:Jeg ligner pelikanen i ørkenen, jeg er som uglen på øde steder. 
19:102:8:Jeg våker og er blitt som en enslig fugl på taket. 
19:102:9:Hele dagen spotter mine fiender mig; de som raser mot mig, sverger ved mig*. # <* JES 65, 15.> 
19:102:10:For jeg eter aske som brød og blander min drikk med gråt 
19:102:11:for din vredes og din harmes skyld; for du har løftet mig op og kastet mig bort. 
19:102:12:Mine dager er som en hellende skygge, og selv visner jeg som en urt. 
19:102:13:Men du, Herre, du troner til evig tid, og ditt minne varer fra slekt til slekt. 
19:102:14:Du vil reise dig, du vil forbarme dig over Sion; for det er tiden til å være det nådig, timen er kommet. 
19:102:15:For dine tjenere elsker dets stener, og de ynkes over dets støv. 
19:102:16:Og hedningene skal frykte Herrens navn, og alle jordens konger din herlighet. 
19:102:17:For Herren har bygget Sion, han har åpenbaret sig i sin herlighet. 
19:102:18:Han har vendt sig til de hjelpeløses bønn, og han har ikke foraktet deres bønn. 
19:102:19:Dette skal bli opskrevet for den kommende slekt, og det folk som skal skapes, skal love Herren. 
19:102:20:For han har sett ned fra sin hellige høide, Herren har fra himmelen skuet til jorden 
19:102:21:for å høre den fangnes sukk, for å løse dødens barn, 
19:102:22:forat de i Sion skal forkynne Herrens navn og hans pris i Jerusalem, 
19:102:23:når de samler sig, folkeslagene og rikene, for å tjene Herren. 
19:102:24:Han har bøiet min kraft på veien, han har forkortet mine dager. 
19:102:25:Jeg sier: Min Gud, ta mig ikke bort midt i mine dager! Dine år varer fra slekt til slekt. 
19:102:26:Fordum grunnfestet du jorden, og himlene er dine henders gjerning. 
19:102:27:De skal forgå, men du blir stående; de skal alle eldes som et klæde, som et klædebon omskifter du dem, og de omskiftes, 
19:102:28:men du er den samme, og dine år får ingen ende. 
19:102:29:Dine tjeneres barn skal bo i ro, og deres avkom skal stå fast for ditt åsyn. 
19:103:1:Av David. Min sjel, lov Herren, og alt som i mig er, love hans hellige navn! 
19:103:2:Min sjel, lov Herren og glem ikke alle hans velgjerninger! 
19:103:3:Han som forlater all din misgjerning, som læger alle dine sykdommer, 
19:103:4:han som forløser ditt liv fra graven, som kroner dig med miskunnhet og barmhjertighet, 
19:103:5:han som metter din sjel* med det som godt er, så du blir ung igjen likesom ørnen**. # <* eg. din pryd.> # <** når den skifter sin ham.> 
19:103:6:Herren gjør rettferd og rett mot alle undertrykte. 
19:103:7:Han kunngjorde sine veier for Moses, sine gjerninger for Israels barn. 
19:103:8:Herren er barmhjertig og nådig, langmodig og rik på miskunnhet. 
19:103:9:Han går ikke alltid i rette og gjemmer ikke på vrede evindelig. 
19:103:10:Han gjør ikke med oss efter våre synder og gjengjelder oss ikke efter våre misgjerninger. 
19:103:11:For så høi som himmelen er over jorden, er hans miskunnhet mektig over dem som frykter ham. 
19:103:12:Så langt som øst er fra vest, lar han våre misgjerninger være langt fra oss. 
19:103:13:Som en far forbarmer sig over sine barn, forbarmer Herren sig over dem som frykter ham. 
19:103:14:For han vet hvorledes vi er skapt, han kommer i hu at vi er støv. 
19:103:15:Et menneskes dager er som gresset; som blomsten på marken, således blomstrer han. 
19:103:16:Når vinden farer over ham, er han ikke mere, og hans sted kjenner ham ikke mere. 
19:103:17:Men Herrens miskunnhet er fra evighet og inntil evighet over dem som frykter ham, og hans rettferdighet mot barnebarn, 
19:103:18:mot dem som holder hans pakt, og dem som kommer hans bud i hu, så de gjør efter dem. 
19:103:19:Herren har reist sin trone i himmelen, og hans rike hersker over alle ting. 
19:103:20:Lov Herren, I hans engler, I veldige i makt, som fullbyrder hans ord, idet I adlyder hans ords røst! 
19:103:21:Lov Herren, alle hans hærskarer, I hans tjenere som gjør hans vilje! 
19:103:22:Lov Herren, alle hans gjerninger, på alle steder hvor han hersker! Min sjel, lov Herren! 
19:104:1:Min sjel, lov Herren! Herre min Gud, du er såre stor, høihet og herlighet har du iklædd dig. 
19:104:2:Han hyller sig i lys som i et klædebon, han spenner himmelen ut som et telt, 
19:104:3:han som tømrer i vannene sine høie saler, han som gjør skyene til sin vogn, som farer frem på vindens vinger. 
19:104:4:Han gjør vinder til sine engler, luende ild til sine tjenere. 
19:104:5:Han grunnfestet jorden på dens støtter, den skal ikke rokkes i all evighet. 
19:104:6:Du hadde dekket den med dype vann som med et klædebon; vannene stod over fjellene. 
19:104:7:For din trusel flydde de, for din tordens røst for de hastig bort. 
19:104:8:De steg op til fjellene, fór ned i dalene, til det sted du hadde grunnfestet for dem. 
19:104:9:En grense satte du, som de ikke skal overskride; de skal ikke vende tilbake for å dekke jorden. 
19:104:10:Han lar kilder springe frem i dalene; mellem fjellene går de. 
19:104:11:De gir alle markens dyr å drikke; villeslene slukker sin tørst. 
19:104:12:Over dem bor himmelens fugler; mellem grenene lar de høre sin røst. 
19:104:13:Han vanner fjellene fra sine høie saler; av dine gjerningers frukt mettes jorden. 
19:104:14:Han lar gress gro for feet og urter til menneskets tjeneste, til å få brød frem av jorden. 
19:104:15:Og vin gleder menneskets hjerte, så den gjør åsynet mer skinnende enn olje, og brød styrker menneskets hjerte. 
19:104:16:Herrens trær mettes, Libanons sedrer som han har plantet, 
19:104:17:der hvor fuglene bygger rede, storken som har sin bolig i cypressene. 
19:104:18:De høie fjell er for stengjetene, klippene er tilflukt for fjellgrevlingene. 
19:104:19:Han gjorde månen til å fastsette tidene; solen kjenner sin nedgangstid. 
19:104:20:Du gjør mørke, og det blir natt; i den rører sig alle dyrene i skogen. 
19:104:21:De unge løver brøler efter rov, for å kreve sin føde av Gud. 
19:104:22:Solen går op, de trekker sig tilbake og legger sig i sine boliger. 
19:104:23:Mennesket går ut til sin gjerning og til sitt arbeid inntil aftenen. 
19:104:24:Hvor mange dine gjerninger er, Herre! Du gjorde dem alle viselig; jorden er full av det du har skapt. 
19:104:25:Der er havet, stort og vidtstrakt; der er en vrimmel uten tall, der er dyr, både små og store. 
19:104:26:Der går skibene, Leviatan*, som du skapte til å leke sig der. # <* SLM 74, 14.> 
19:104:27:Alle venter de på dig, at du skal gi dem deres føde i sin tid. 
19:104:28:Du gir dem, de sanker; du oplater din hånd, de mettes med godt. 
19:104:29:Du skjuler ditt åsyn, de forferdes; du drar deres livsånde tilbake, de dør og vender tilbake til sitt støv. 
19:104:30:Du sender din Ånd ut, de skapes, og du gjør jordens skikkelse ny igjen. 
19:104:31:Herrens ære være til evig tid! Herren glede sig i sine gjerninger! 
19:104:32:Han som ser til jorden, og den bever, som rører ved fjellene, og de ryker. 
19:104:33:Jeg vil lovsynge Herren så lenge jeg lever; jeg vil synge for min Gud så lenge jeg er til. 
19:104:34:Måtte min tale tekkes ham! Jeg vil glede mig i Herren! 
19:104:35:Men måtte syndere utryddes av jorden, og ugudelige ikke mere finnes! Min sjel, lov Herren! Halleluja*! # <* d.e. lov Herren.> 
19:105:1:Pris Herren, påkall hans navn, kunngjør blandt folkene hans store gjerninger! 
19:105:2:Syng for ham, lovsyng ham, grund på alle hans undergjerninger! 
19:105:3:Ros eder av hans hellige navn! Deres hjerte glede sig som søker Herren! 
19:105:4:Spør efter Herren og hans makt, søk hans åsyn all tid! 
19:105:5:Kom i hu hans undergjerninger som han har gjort, hans undere og hans munns dommer, 
19:105:6:I, hans tjener Abrahams avkom, Jakobs barn, hans utvalgte! 
19:105:7:Han er Herren vår Gud, hans dommer er over all jorden. 
19:105:8:Han kommer evindelig sin pakt i hu, det ord han fastsatte for tusen slekter, 
19:105:9:den pakt han gjorde med Abraham, og sin ed til Isak; 
19:105:10:og han stadfestet den som en rett for Jakob, som en evig pakt for Israel, 
19:105:11:idet han sa: Dig vil jeg gi Kana'ans land til arvelodd. 
19:105:12:Da de var en liten flokk, få og fremmede der, 
19:105:13:og vandret fra folk til folk, fra et rike til et annet folk, 
19:105:14:tillot han ikke noget menneske å gjøre vold imot dem, og han straffet konger for deres skyld: 
19:105:15:Rør ikke ved mine salvede, og gjør ikke mine profeter noget ondt! 
19:105:16:Og han kalte hunger inn over landet, han brøt sønder hver støtte av brød*. # <* JES 3, 1.> 
19:105:17:Han sendte en mann foran dem, til træl blev Josef solgt. 
19:105:18:De plaget hans føtter med lenker, hans sjel kom i jern*, # <* d.e. hans lenker voldte hans sjel bitter smerte.> 
19:105:19:inntil den tid da hans ord slo til, da Herrens ord viste hans uskyld. 
19:105:20:Da sendte kongen bud og lot ham løs, herskeren over folkeslag gav ham fri. 
19:105:21:Han satte ham til herre over sitt hus og til hersker over alt sitt gods, 
19:105:22:forat han skulde binde hans fyrster efter sin vilje og lære hans eldste visdom. 
19:105:23:Så kom Israel til Egypten, og Jakob bodde som fremmed i Kams land. 
19:105:24:Og han* gjorde sitt folk såre fruktbart og gjorde det sterkere enn dets motstandere. # <* Gud.> 
19:105:25:Han vendte deres hjerte til å hate hans folk, til å gå frem med svik mot hans tjenere. 
19:105:26:Han sendte Moses, sin tjener, Aron som han hadde utvalgt. 
19:105:27:De gjorde hans tegn iblandt dem og undere i Kams land. 
19:105:28:Han sendte mørke og gjorde det mørkt, og de var ikke gjenstridige mot hans ord. 
19:105:29:Han gjorde deres vann til blod, og han drepte deres fisker. 
19:105:30:Deres land vrimlet av frosk, endog i deres kongers saler. 
19:105:31:Han talte, og det kom fluesvermer, mygg innen hele deres landemerke. 
19:105:32:Han gav dem hagl for regn, luende ild i deres land, 
19:105:33:og han slo ned deres vintrær og deres fikentrær, og brøt sønder trærne innen deres landemerke. 
19:105:34:Han talte, og det kom gresshopper og gnagere* uten tall, # <* d.e. gresshopper.> 
19:105:35:og de åt op hver urt i deres land, og de åt op frukten på deres mark. 
19:105:36:Og han slo alt førstefødt i deres land, førstegrøden av all deres kraft. 
19:105:37:Og han førte dem ut med sølv og gull, og det fantes ingen i hans stammer som snublet. 
19:105:38:Egypten gledet sig da de drog ut; for frykt for dem var falt på dem. 
19:105:39:Han bredte ut en sky til dekke og ild til å lyse om natten. 
19:105:40:De krevde, og han lot vaktler komme og mettet dem med himmelbrød. 
19:105:41:Han åpnet klippen, og det fløt vann; det løp gjennem det tørre land som en strøm. 
19:105:42:For han kom i hu sitt hellige ord, Abraham, sin tjener, 
19:105:43:og han førte sitt folk ut med glede, sine utvalgte med fryderop, 
19:105:44:og han gav dem hedningefolks land, og hvad folkeslag med møie hadde vunnet, tok de til eie, 
19:105:45:forat de skulde holde hans forskrifter og ta vare på hans lover. Halleluja! 
19:106:1:Halleluja! Pris Herren, for han er god, hans miskunnhet varer evindelig. 
19:106:2:Hvem kan utsi Herrens veldige gjerninger, forkynne all hans pris? 
19:106:3:Salige er de som tar vare på det som rett er, den som gjør rettferdighet til enhver tid. 
19:106:4:Kom mig i hu, Herre, efter din nåde mot ditt folk, se til mig med din frelse, 
19:106:5:så jeg kan se på dine utvalgtes lykke, glede mig med ditt folks glede, rose mig med din arv! 
19:106:6:Vi har syndet med våre fedre, vi har gjort ille, vi har vært ugudelige. 
19:106:7:Våre fedre i Egypten aktet ikke på dine undergjerninger, de kom ikke i hu dine mange nådegjerninger, men var gjenstridige ved havet, ved det Røde Hav. 
19:106:8:Dog frelste han dem for sitt navns skyld, for å kunngjøre sitt velde, 
19:106:9:og han truet det Røde Hav, og det blev tørt, og han lot dem gå gjennem dypene som i en ørken, 
19:106:10:og han frelste dem av hans hånd som hatet dem, og forløste dem av fiendens hånd, 
19:106:11:og vannet skjulte deres motstandere, det blev ikke én av dem tilbake. 
19:106:12:Da trodde de på hans ord, de sang hans pris. 
19:106:13:Men snart glemte de hans gjerninger, de bidde ikke på hans råd; 
19:106:14:men de blev grepet av begjærlighet i ørkenen, og de fristet Gud på det øde sted. 
19:106:15:Da gav han dem det de vilde ha, men sendte tærende sykdom over deres liv. 
19:106:16:Og de blev avindsyke mot Moses i leiren, mot Aron, Herrens hellige. 
19:106:17:Jorden oplot sig og slukte Datan og skjulte Abirams hop, 
19:106:18:og en ild satte deres hop i brand, en lue brente op de ugudelige. 
19:106:19:De gjorde en kalv ved Horeb og tilbad et støpt billede, 
19:106:20:og de byttet sin ære* mot billedet av en okse, som eter gress. # <* 5MO 10, 21.> 
19:106:21:De glemte Gud, sin frelser, som hadde gjort store ting i Egypten, 
19:106:22:undergjerninger i Kams land, forferdelige ting ved det Røde Hav. 
19:106:23:Da sa han at han vilde ødelegge dem, dersom ikke Moses, hans utvalgte, hadde stilt sig i gapet for hans åsyn for å avvende hans vrede fra å ødelegge dem. 
19:106:24:Og de foraktet det herlige land, de trodde ikke hans ord, 
19:106:25:og de knurret i sine telt, de hørte ikke på Herrens røst. 
19:106:26:Da opløftet han sin hånd og svor at han vilde la dem falle i ørkenen 
19:106:27:og la deres avkom falle iblandt hedningene og sprede dem i landene. 
19:106:28:Og de bandt sig til Ba'al-Peor og åt av offere til døde*, # <* d.e. de livløse avgudsbilleder.> 
19:106:29:og de vakte harme ved sine gjerninger, og en plage brøt inn iblandt dem. 
19:106:30:Da stod Pinehas frem og holdt dom, og plagen stanset; 
19:106:31:og det blev regnet ham til rettferdighet fra slekt til slekt evindelig. 
19:106:32:Og de vakte vrede ved Meribas vann, og det gikk Moses ille for deres skyld; 
19:106:33:for de var gjenstridige mot hans* Ånd, og han talte tankeløst med sine leber. # <* d.e. Guds.> 
19:106:34:De ødela ikke de folk som Herren hadde talt til dem om, 
19:106:35:men de blandet sig med hedningene og lærte deres gjerninger, 
19:106:36:og de tjente deres avguder, og disse blev dem til en snare, 
19:106:37:og de ofret sine sønner og sine døtre til maktene*. # <* d.e. avgudene.> 
19:106:38:og de utøste uskyldig blod, sine sønners og sine døtres blod, som de ofret til Kana'ans avguder, og landet blev vanhelliget ved blod. 
19:106:39:De blev urene ved sine gjerninger og drev hor ved sin adferd. 
19:106:40:Da optendtes Herrens vrede mot hans folk, og han fikk avsky for sin arv. 
19:106:41:Og han gav dem i hedningers hånd, og de som hatet dem, hersket over dem, 
19:106:42:og deres fiender trengte dem, og de blev ydmyket under deres hånd. 
19:106:43:Mange ganger utfridde han dem; men de var gjenstridige i sine råd, og de sank ned i usseldom for sin misgjernings skyld. 
19:106:44:Og han så til dem når de var i nød, idet han hørte deres klagerop. 
19:106:45:Og i sin godhet mot dem kom han sin pakt i hu, og det gjorde ham ondt efter hans store miskunnhet, 
19:106:46:og han lot dem finne barmhjertighet for alle deres åsyn som hadde ført dem i fangenskap. 
19:106:47:Frels oss, Herre vår Gud, og samle oss fra hedningene til å love ditt hellige navn, rose oss av å kunne prise dig! 
19:106:48:Lovet være Herren, Israels Gud, fra evighet og til evighet! Og alt folket sier: Amen. Halleluja! 
19:107:1:Pris Herren, for han er god, hans miskunnhet varer evindelig. 
19:107:2:Så sie Herrens gjenløste, de som han har gjenløst av nødens hånd, 
19:107:3:og som han har samlet fra landene, fra øst og fra vest, fra nord og fra havet. 
19:107:4:De fór vill i ørkenen, i et uveisomt øde, de fant ikke en by å bo i. 
19:107:5:De var hungrige og tørste, deres sjel vansmektet i dem. 
19:107:6:Da ropte de til Herren i sin nød; av deres trengsler utfridde han dem, 
19:107:7:og han førte dem på rett vei, så de gikk til en by de kunde bo i. 
19:107:8:De skal prise Herren for hans miskunnhet og for hans undergjerninger mot menneskenes barn; 
19:107:9:for han mettet den vansmektende sjel og fylte den hungrige sjel med godt. 
19:107:10:De satt i mørke og i dødsskygge, bundet i elendighet og jern, 
19:107:11:fordi de hadde vært gjenstridige mot Guds ord og foraktet den Høiestes råd. 
19:107:12:Derfor bøide han deres hjerter ved lidelse; de snublet, og det var ikke nogen hjelper. 
19:107:13:Da ropte de til Herren i sin nød; av deres trengsler frelste han dem. 
19:107:14:Han førte dem ut av mørke og dødsskygge og rev sønder deres bånd. 
19:107:15:De skal prise Herren for hans miskunnhet og for hans undergjerninger mot menneskenes barn; 
19:107:16:for han brøt sønder porter av kobber og hugg sønder bommer av jern. 
19:107:17:De var dårer og blev plaget for sin syndige vei og for sine misgjerninger; 
19:107:18:deres sjel vemmedes ved all mat, og de kom nær til dødens porter. 
19:107:19:Da ropte de til Herren i sin nød; av deres trengsler frelste han dem. 
19:107:20:Han sendte sitt ord og helbredet dem og reddet dem fra deres graver. 
19:107:21:De skal prise Herren for hans miskunnhet og for hans undergjerninger mot menneskenes barn; 
19:107:22:og ofre takkoffere og fortelle om hans gjerninger med jubel. 
19:107:23:De som fór ut på havet i skib, som drev handel på store vann, 
19:107:24:de så Herrens gjerninger og hans underverker på dypet. 
19:107:25:Han bød og lot det komme en stormvind, og den reiste dets bølger. 
19:107:26:De fór op imot himmelen, de fór ned i avgrunnene, deres sjel blev motløs i ulykken. 
19:107:27:De tumlet og vaklet som en drukken mann, og all deres visdom blev til intet. 
19:107:28:Da ropte de til Herren i sin nød, og av deres trengsler førte han dem ut. 
19:107:29:Han lot stormen bli til stille, og bølgene omkring dem tidde. 
19:107:30:Og de gledet sig over at de la sig; og han førte dem til den havn de ønsket. 
19:107:31:De skal prise Herren for hans miskunnhet og for hans undergjerninger mot menneskenes barn 
19:107:32:og ophøie ham i folkets forsamling og love ham der hvor de gamle sitter. 
19:107:33:Han gjorde elver til en ørken og vannkilder til et tørstig land, 
19:107:34:et fruktbart land til et saltland for deres ondskaps skyld som bodde der. 
19:107:35:Han gjorde en ørken til en vannrik sjø og et tørt land til vannkilder*. # <* nemlig: for det frelste Israel.> 
19:107:36:Og han lot de hungrige bo der, og de grunnla en by til å bo i. 
19:107:37:Og de tilsådde akrer og plantet vingårder, og de vant den frukt de bar. 
19:107:38:Og han velsignet dem, og de blev meget tallrike, og av fe gav han dem ikke lite. 
19:107:39:Så minket de igjen og blev nedbøiet ved trengsel, ulykke og sorg. 
19:107:40:Han som utøser forakt over fyrster og lar dem fare vill i et uveisomt øde, 
19:107:41:han ophøiet den fattige av elendighet og gjorde slektene som hjorden. 
19:107:42:De opriktige ser det og gleder sig, og all ondskap lukker sin munn. 
19:107:43:Den som er vis, han akte på dette og merke på Herrens nådegjerninger! 
19:108:1:En sang, en salme av David. 
19:108:2:Mitt hjerte er rolig, Gud! Jeg vil synge og lovprise, ja, det skal min ære*. # <* d.e. sjel.> 
19:108:3:Våkn op, harpe og citar! Jeg vil vekke morgenrøden. 
19:108:4:Jeg vil prise dig blandt folkene, Herre, og lovsynge dig blandt folkeslagene. 
19:108:5:For stor over himmelen er din miskunnhet, og inntil skyene din trofasthet. 
19:108:6:Vis dig høi over himmelen, Gud, og din ære over all jorden! 
19:108:7:Forat de du elsker, må bli frelst, så hjelp med din høire hånd og bønnhør oss! 
19:108:8:Gud har talt i sin hellighet. Jeg vil fryde mig; jeg vil utskifte Sikem og opmåle Sukkots dal. 
19:108:9:Mig hører Gilead til, mig hører Manasse til, og Efra'im er vern for mitt hode, Juda er min herskerstav. 
19:108:10:Moab er mitt vaskefat, på Edom kaster jeg min sko, over Filisterland jubler jeg. 
19:108:11:Hvem vil føre mig til den faste by? Hvem leder mig inn til Edom? 
19:108:12:Mon ikke du, Gud, som forkastet oss og ikke drog ut med våre hærer, Gud? 
19:108:13:Gi oss hjelp mot fienden, for menneskehjelp er tomhet! 
19:108:14:Ved Gud skal vi gjøre storverk, og han skal trede ned våre fiender. 
19:109:1:Til sangmesteren; av David; en salme. Min lovsangs Gud, ti ikke! 
19:109:2:For ugudelighets munn og falskhets munn har de oplatt imot mig, de har talt med mig med løgnens tunge. 
19:109:3:Og med hatets ord har de omgitt mig og stridt imot mig uten årsak. 
19:109:4:Til lønn for min kjærlighet stod de mig imot, enda jeg er bare bønn. 
19:109:5:Og de la ondt på mig til lønn for godt og hat til lønn for min kjærlighet. 
19:109:6:Sett en ugudelig over ham, og la en anklager stå ved hans høire hånd! 
19:109:7:Når han dømmes, da la ham gå ut som skyldig, og la hans bønn bli til synd! 
19:109:8:La hans dager bli få, la en annen få hans embede! 
19:109:9:La hans barn bli farløse og hans hustru enke, 
19:109:10:og la hans barn flakke omkring og tigge, og la dem gå som tiggere fra sine ødelagte hjem! 
19:109:11:La ågerkaren kaste garn ut efter alt det han har, og fremmede røve frukten av hans arbeid! 
19:109:12:La ham ikke finne nogen som bevarer miskunnhet imot ham og la ingen forbarme sig over hans farløse barn! 
19:109:13:La hans fremtid bli avskåret, deres navn bli utslettet i det annet ættledd! 
19:109:14:Hans fedres misgjerning bli ihukommet hos Herren, og hans mors synd bli ikke utslettet! 
19:109:15:De være alltid for Herrens øine, og han utrydde deres minne av jorden, 
19:109:16:fordi han ikke kom i hu å gjøre barmhjertighet, men forfulgte en mann som var fattig og elendig og bedrøvet i hjertet, og vilde drepe ham. 
19:109:17:Han elsket forbannelse, og den kom over ham; han hadde ikke lyst til velsignelse, og den blev langt borte fra ham; 
19:109:18:han klædde sig i forbannelse som sin klædning, og den trengte som vann inn i hans liv og som olje i hans ben. 
19:109:19:La den være ham som et klædebon som han dekker sig med, og som et belte som han alltid omgjorder sig med! 
19:109:20:Dette være mine motstanderes lønn fra Herren, og deres som taler ondt imot min sjel! 
19:109:21:Og du, Herre, Herre, gjør vel imot mig for ditt navns skyld! Fordi din miskunnhet er god, så redde du mig! 
19:109:22:For jeg er elendig og fattig, og mitt hjerte er gjennemboret i mitt indre. 
19:109:23:Som en skygge, når den heller, farer jeg avsted; jeg blir jaget bort som en gresshoppe. 
19:109:24:Mine knær vakler av faste, og mitt kjød svinner og er uten fedme. 
19:109:25:Og jeg er blitt til spott for dem; de ser mig og ryster på hodet. 
19:109:26:Hjelp mig, Herre min Gud, frels mig efter din miskunnhet, 
19:109:27:så de må kjenne at dette er din hånd, at du, Herre, har gjort det! 
19:109:28:De forbanner, men du velsigner; de reiser sig og blir til skamme, men din tjener gleder sig. 
19:109:29:Mine motstandere skal klæ sig i vanære og svøpe sig i sin skam som i en kappe. 
19:109:30:Jeg vil storlig prise Herren med min munn, og midt iblandt mange vil jeg love ham; 
19:109:31:for han står ved den fattiges høire hånd for å frelse ham fra dem som dømmer hans sjel. 
19:110:1:Av David; en salme. Herren sa til min herre: Sett dig ved min høire hånd, til jeg får lagt dine fiender til skammel for dine føtter! 
19:110:2:Ditt veldes kongestav skal Herren utstrekke fra Sion! hersk midt iblandt dine fiender! 
19:110:3:Ditt folk møter villig frem på ditt veldes dag; i hellig prydelse kommer din ungdom til dig, som dugg ut av morgenrødens skjød. 
19:110:4:Herren har svoret, og han skal ikke angre det: Du er prest evindelig efter Melkisedeks vis. 
19:110:5:Herren ved din høire hånd knuser konger på sin vredes dag. 
19:110:6:Han holder dom iblandt hedningene, fyller op med lik, knuser hoder over den vide jord. 
19:110:7:Av bekken drikker han på veien, derfor løfter han høit sitt hode. 
19:111:1:Halleluja! Jeg vil prise Herren av hele mitt hjerte i de opriktiges råd og i menigheten. 
19:111:2:Store er Herrens gjerninger, søkt av alle dem som har lyst til dem. 
19:111:3:Høihet og herlighet er hans gjerning, og hans rettferdighet står fast evindelig. 
19:111:4:Han har sørget for at hans undergjerninger ihukommes; Herren er nådig og barmhjertig. 
19:111:5:Han har gitt dem føde som frykter ham; han kommer sin pakt i hu evindelig. 
19:111:6:Han har kunngjort sine gjerningers kraft for sitt folk idet han har gitt dem hedningers arv. 
19:111:7:Hans henders gjerninger er sannhet og rett, alle hans bud er trofaste; 
19:111:8:de er grunnfestet for all tid, for evighet, de er gjort i sannhet og rettvishet. 
19:111:9:Han har sendt sitt folk forløsning, han har fastsatt sin pakt for evig tid; hans navn er hellig og forferdelig. 
19:111:10:Å frykte Herren er begynnelsen til visdom; god forstand har alle de som gjør derefter. Hans pris varer til evig tid. 
19:112:1:Halleluja! Salig er den mann som frykter Herren, som har sin store lyst i hans bud. 
19:112:2:Hans avkom skal være mektig på jorden; de opriktiges slekt skal velsignes. 
19:112:3:Det er velstand og rikdom i hans hus, og hans rettferdighet står fast evindelig. 
19:112:4:Det går op et lys i mørket for de opriktige, for den som er nådig og barmhjertig og rettferdig. 
19:112:5:Lykkelig er den mann som forbarmer sig og låner ut; han hevder all sin sak i dommen. 
19:112:6:For han skal ikke rokkes evindelig; den rettferdige skal være i evig minne. 
19:112:7:Han skal ikke frykte for nogen ond tidende; hans hjerte er fast, det setter sin lit til Herren. 
19:112:8:Hans hjerte er trygt; han frykter ikke, inntil han ser med lyst på sine fiender. 
19:112:9:Han har strødd ut, han har gitt de fattige; hans rettferdighet står fast evindelig, hans horn skal ophøies med ære. 
19:112:10:Den ugudelige skal se det og harmes, han skal skjære tenner og optæres; de ugudeliges attrå blir til intet. 
19:113:1:Halleluja! Lov, I Herrens tjenere, lov Herrens navn! 
19:113:2:Herrens navn være lovet fra nu av og inntil evig tid! 
19:113:3:Fra solens opgang til dens nedgang er Herrens navn høilovet. 
19:113:4:Herren er ophøiet over alle hedninger, hans ære er over himmelen. 
19:113:5:Hvem er som Herren vår Gud, han som troner så høit, 
19:113:6:som ser så dypt ned, i himmelen og på jorden, 
19:113:7:som reiser den ringe av støvet, ophøier den fattige av skarnet 
19:113:8:for å sette ham hos fyrster, hos sitt folks fyrster, 
19:113:9:han som lar den ufruktbare hustru bo som glad barnemor! Halleluja! 
19:114:1:Da Israel drog ut av Egypten, Jakobs hus fra et folk med fremmed tungemål, 
19:114:2:da blev Juda hans* helligdom, Israel hans rike. # <* Herrens.> 
19:114:3:Havet så det og flydde, Jordan vendte om og løp tilbake. 
19:114:4:Fjellene hoppet som værer, haugene som unge lam. 
19:114:5:Hvad har hendt dig, du hav, at du flyr, du Jordan, at du vender om og løper tilbake, 
19:114:6:I fjell, at I hopper som værer, I hauger som unge lam? 
19:114:7:For Herrens åsyn bev, du jord, for Jakobs Guds åsyn, 
19:114:8:han som gjør klippen til en vannrik sjø, den hårde sten til en vannkilde! 
19:115:1:Ikke oss, Herre, ikke oss, men ditt navn gi du ære for din miskunnhets, for din trofasthets skyld! 
19:115:2:Hvorfor skal hedningene si: Hvor er nu deres Gud? 
19:115:3:Vår Gud er jo i himmelen; han gjør alt det han vil. 
19:115:4:Deres avguder er sølv og gull, et verk av menneskehender. 
19:115:5:De har munn, men taler ikke; de har øine, men ser ikke; 
19:115:6:de har ører, men hører ikke; de har nese, men lukter ikke. 
19:115:7:Deres hender føler ikke, deres føtter går ikke; de gir ingen lyd med sin strupe. 
19:115:8:Som de selv er, blir de som gjør dem, hver den som setter sin lit til dem. 
19:115:9:Israel, sett din lit til Herren! Han er deres hjelp og deres skjold. 
19:115:10:Arons hus, sett eders lit til Herren! Han er deres hjelp og deres skjold. 
19:115:11:I som frykter Herren, sett eders lit til Herren! Han er deres hjelp og deres skjold. 
19:115:12:Herren kom oss i hu; han skal velsigne, han skal velsigne Israels hus, han skal velsigne Arons hus, 
19:115:13:han skal velsigne dem som frykter Herren, de små med de store. 
19:115:14:Herren la eder vokse i tall, eder og eders barn! 
19:115:15:Velsignet være I av Herren, himmelens og jordens skaper! 
19:115:16:Himmelen er Herrens himmel, men jorden har han gitt menneskenes barn. 
19:115:17:De døde lover ikke Herren, ingen av dem som farer ned i dødsrikets stillhet; 
19:115:18:men vi skal love Herren fra nu av og inntil evig tid. Halleluja! 
19:116:1:Jeg elsker Herren, for han hører min røst, mine inderlige bønner. 
19:116:2:For han har bøiet sitt øre til mig, og alle mine dager vil jeg påkalle ham. 
19:116:3:Dødens rep hadde omspent mig, og dødsrikets angster hadde funnet mig; nød og sorg fant jeg. 
19:116:4:Men jeg påkalte Herrens navn: Akk Herre, frels min sjel! 
19:116:5:Herren er nådig og rettferdig, og vår Gud er barmhjertig. 
19:116:6:Herren verner de enfoldige; jeg var elendig, og han frelste mig. 
19:116:7:Kom igjen, min sjel, til din ro! For Herren har gjort vel imot dig. 
19:116:8:For du fridde min sjel fra døden, mitt øie fra gråt, min fot fra fall. 
19:116:9:Jeg skal vandre for Herrens åsyn i de levendes land. 
19:116:10:Jeg trodde, for jeg talte; jeg var såre plaget. 
19:116:11:Jeg sa i min angst: Hvert menneske er en løgner. 
19:116:12:Hvormed skal jeg gjengjelde Herren alle hans velgjerninger imot mig? 
19:116:13:Jeg vil løfte frelsens beger og påkalle Herrens navn. 
19:116:14:Jeg vil holde for Herren mine løfter, og det for hele hans folks øine. 
19:116:15:Kostelig i Herrens øine er hans frommes død. 
19:116:16:Akk Herre! Jeg er jo din tjener, jeg er din tjener, din tjenerinnes sønn; du har løst mine bånd. 
19:116:17:Dig vil jeg ofre takkoffer, og Herrens navn vil jeg påkalle. 
19:116:18:Jeg vil holde for Herren mine løfter, og det for hele hans folks øine, 
19:116:19:i forgårdene til Herrens hus, midt i dig, Jerusalem. Halleluja! 
19:117:1:Lov Herren, alle hedninger, pris ham, alle folk! 
19:117:2:For hans miskunnhet er mektig over oss, og Herrens trofasthet varer til evig tid. Halleluja! 
19:118:1:Pris Herren, for han er god, hans miskunnhet varer evindelig! 
19:118:2:Israel sie: Hans miskunnhet varer evindelig! 
19:118:3:Arons hus sie: Hans miskunnhet varer evindelig! 
19:118:4:De som frykter Herren, sie: Hans miskunnhet varer evindelig! 
19:118:5:Ut av trengselen kalte jeg på Herren; Herren svarte mig og førte mig ut i fritt rum. 
19:118:6:Herren er med mig, jeg frykter ikke; hvad skulde et menneske gjøre mig? 
19:118:7:Herren er med mig, den som hjelper mig, og jeg skal se med lyst på dem som hater mig. 
19:118:8:Det er bedre å sette sin lit til Herren enn å stole på mennesker. 
19:118:9:Det er bedre å ta sin tilflukt til Herren enn å stole på fyrster. 
19:118:10:Alle hedninger omringer mig; i Herrens navn skal jeg hugge dem ned. 
19:118:11:De omgir mig, ja, de omringer mig; i Herrens navn skal jeg hugge dem ned. 
19:118:12:De omgir mig som bier, de slukner som ild i tornebusker; i Herrens navn skal jeg hugge dem ned. 
19:118:13:Hårdt støtte du* mig forat jeg skulde falle; men Herren hjalp mig. # <* min fiende.> 
19:118:14:Herren er min styrke og lovsang, og han blev mig til frelse. 
19:118:15:Det høres fryderop om frelse i de rettferdiges telt; Herrens høire hånd gjør storverk. 
19:118:16:Herrens høire hånd ophøier, Herrens høire hånd gjør storverk. 
19:118:17:Jeg skal ikke dø, men leve og fortelle Herrens gjerninger. 
19:118:18:Hårdt tuktet Herren mig, men til døden overgav han mig ikke. 
19:118:19:Lukk op rettferdighets porter for mig! Jeg vil gå inn gjennem dem, jeg vil prise Herren. 
19:118:20:Dette er Herrens port; de rettferdige skal gå inn gjennem den. 
19:118:21:Jeg vil prise dig fordi du svarte mig og blev mig til frelse. 
19:118:22:Den sten som bygningsmennene forkastet, er blitt hovedhjørnesten. 
19:118:23:Av Herren er dette gjort, det er underfullt i våre øine. 
19:118:24:Dette er dagen som Herren har gjort; la oss fryde oss og glede oss på den! 
19:118:25:Å Herre, frels dog! Å Herre, la det dog lykkes! 
19:118:26:Velsignet være han som kommer i Herrens navn! Vi velsigner eder fra Herrens hus. 
19:118:27:Herren er Gud, og han lot det bli lyst for oss. Bind høitidsofferet med rep like inn til alterets horn! 
19:118:28:Du er min Gud, og jeg vil love dig, min Gud, jeg vil ophøie dig. 
19:118:29:Pris Herren, for han er god, hans miskunnhet varer evindelig. 
19:119:1:Salige er de hvis vei er ulastelig, som vandrer i Herrens lov. 
19:119:2:Salige er de som tar vare på hans vidnesbyrd, som søker ham av hele sitt hjerte 
19:119:3:og ikke gjør urett, men vandrer på hans veier. 
19:119:4:Du har gitt dine befalinger forat en skal holde dem nøie. 
19:119:5:O, at mine veier måtte bli faste, så jeg holder dine forskrifter! 
19:119:6:Da skal jeg ikke bli til skamme når jeg gir akt på alle dine bud. 
19:119:7:Jeg vil prise dig i hjertets opriktighet når jeg lærer din rettferdighets lover å kjenne. 
19:119:8:Dine forskrifter vil jeg holde; du må ikke rent forlate mig! 
19:119:9:Hvorved skal den unge holde sin sti ren? Ved å holde sig efter ditt ord. 
19:119:10:Av hele mitt hjerte har jeg søkt dig; la mig ikke fare vill fra dine bud! 
19:119:11:I mitt hjerte har jeg gjemt ditt ord forat jeg ikke skal synde imot dig. 
19:119:12:Lovet være du, Herre! Lær mig dine forskrifter! 
19:119:13:Med mine leber har jeg forkynt alle lover fra din munn. 
19:119:14:Over dine vidnesbyrds vei har jeg gledet mig, som over all rikdom. 
19:119:15:På dine befalinger vil jeg grunde og tenke på dine stier. 
19:119:16:I dine forskrifter forlyster jeg mig, jeg glemmer ikke ditt ord. 
19:119:17:Gjør vel imot din tjener, så jeg kan leve! Da vil jeg holde ditt ord. 
19:119:18:Lat op mine øine, så jeg kan skue de underfulle ting i din lov! 
19:119:19:Jeg er en gjest på jorden; skjul ikke dine bud for mig! 
19:119:20:Min sjel er knust, så jeg lenges efter dine lover til enhver tid. 
19:119:21:Du har truet de overmodige, de forbannede, som farer vill fra dine bud. 
19:119:22:Ta skam og forakt bort fra mig! For jeg har tatt vare på dine vidnesbyrd. 
19:119:23:Fyrster har også sittet og talt sammen imot mig; din tjener grunder på dine forskrifter. 
19:119:24:Dine vidnesbyrd er også min lyst; de er mine rådgivere. 
19:119:25:Min sjel er nedtrykt i støvet; hold mig i live efter ditt ord! 
19:119:26:Jeg fortalte dig mine veier, og du svarte mig; lær mig dine forskrifter! 
19:119:27:La mig forstå dine befalingers vei! Så vil jeg grunde på dine undergjerninger. 
19:119:28:Min sjel gråter av sorg; reis mig op efter ditt ord! 
19:119:29:Vend løgnens vei bort fra mig, og unn mig din lov! 
19:119:30:Trofasthets vei har jeg utvalgt, dine lover har jeg satt for mig. 
19:119:31:Jeg henger ved dine vidnesbyrd; Herre, la mig ikke bli til skamme! 
19:119:32:Dine buds vei vil jeg løpe; for du frir mitt hjerte fra angst. 
19:119:33:Lær mig, Herre, dine forskrifters vei! Så vil jeg ta vare på den inntil enden. 
19:119:34:Lær mig! Så vil jeg ta vare på din lov og holde den av hele mitt hjerte. 
19:119:35:Led mig frem på dine buds sti! For i den har jeg min lyst. 
19:119:36:Bøi mitt hjerte til dine vidnesbyrd og ikke til vinning! 
19:119:37:Vend mine øine bort fra å se efter tomhet, hold mig i live på din vei! 
19:119:38:Opfyll for din tjener ditt ord, som er for dem som frykter dig! 
19:119:39:Ta bort min vanære, som jeg frykter for! For dine lover er gode. 
19:119:40:Se, jeg lenges efter dine befalinger; hold mig i live ved din rettferdighet! 
19:119:41:La dine nådegjerninger, Herre, komme over mig, din frelse efter ditt ord! 
19:119:42:Jeg vil gi den svar som håner mig; for jeg setter min lit til ditt ord. 
19:119:43:Ta ikke sannhets ord så rent bort fra min munn! For jeg bier på dine dommer. 
19:119:44:Jeg vil holde din lov stadig, evindelig og alltid. 
19:119:45:La mig vandre i fritt rum! For jeg spør efter dine befalinger. 
19:119:46:Jeg vil tale om dine vidnesbyrd for konger og skal ikke bli til skamme. 
19:119:47:Jeg har min lyst i dine bud, som jeg elsker. 
19:119:48:Jeg løfter mine hender til dine bud, som jeg elsker, og jeg vil grunde på dine forskrifter. 
19:119:49:Kom i hu ordet til din tjener, fordi du har gitt mig håp! 
19:119:50:Det er min trøst i min elendighet at ditt ord har holdt mig i live. 
19:119:51:De overmodige har spottet mig såre; fra din lov er jeg ikke avveket. 
19:119:52:Jeg kom dine dommer fra evighet i hu, Herre, og jeg blev trøstet. 
19:119:53:En brennende harme har grepet mig over de ugudelige, som forlater din lov. 
19:119:54:Dine forskrifter er blitt mine lovsanger i min utlendighets hus. 
19:119:55:Jeg kom om natten ditt navn i hu, Herre, og jeg holdt din lov. 
19:119:56:Dette blev mig gitt: at jeg har tatt vare på dine befalinger. 
19:119:57:Herren er min del, sa jeg, idet jeg holdt dine ord. 
19:119:58:Jeg bønnfalt dig av hele mitt hjerte: Vær mig nådig efter ditt ord! 
19:119:59:Jeg eftertenkte mine veier og vendte mine føtter til dine vidnesbyrd. 
19:119:60:Jeg hastet og ventet ikke med å holde dine bud. 
19:119:61:De ugudeliges strikker har omspent mig, din lov har jeg ikke glemt. 
19:119:62:Midt om natten står jeg op for å prise dig for din rettferdighets lover. 
19:119:63:Jeg holder mig til alle dem som frykter dig, og som holder dine befalinger. 
19:119:64:Jorden er full av din miskunnhet, Herre; lær mig dine forskrifter! 
19:119:65:Du har gjort vel imot din tjener, Herre, efter ditt ord. 
19:119:66:Lær mig god skjønnsomhet og kunnskap! For jeg tror på dine bud. 
19:119:67:Før jeg blev ydmyket, fór jeg vill; men nu holder jeg ditt ord. 
19:119:68:Du er god og gjør godt; lær mig dine forskrifter! 
19:119:69:De overmodige har spunnet løgn sammen imot mig; jeg holder dine befalinger av hele mitt hjerte. 
19:119:70:Deres hjerte er som en fettklump; jeg har min lyst i din lov. 
19:119:71:Det var mig godt at jeg blev ydmyket, forat jeg kunde lære dine forskrifter. 
19:119:72:Din munns lov er mig bedre enn tusen stykker gull og sølv. 
19:119:73:Dine hender har skapt mig og gjort mig; gi mig forstand, forat jeg må lære dine bud! 
19:119:74:De som frykter dig, skal se mig og glede sig; for jeg venter på ditt ord. 
19:119:75:Jeg vet, Herre, at dine dommer er rettferdighet, og i trofasthet har du ydmyket mig. 
19:119:76:La din miskunnhet være mig til trøst efter ditt ord til din tjener! 
19:119:77:La din miskunnhet komme over mig, forat jeg kan leve! For din lov er min lyst. 
19:119:78:La de overmodige bli til skamme! For de har trykket mig ned uten årsak; jeg grunder på dine befalinger. 
19:119:79:La dem vende tilbake til mig, de som frykter dig og kjenner dine vidnesbyrd! 
19:119:80:La mitt hjerte være fullkomment i dine forskrifter, forat jeg ikke skal bli til skamme! 
19:119:81:Min sjel vansmekter av lengsel efter din frelse; jeg venter på ditt ord. 
19:119:82:Mine øine vansmekter av lengsel efter ditt ord idet jeg sier: Når vil du trøste mig? 
19:119:83:For jeg er som en skinnsekk i røk*; dine forskrifter glemmer jeg ikke. # <* d.e. jeg tørker bort.> 
19:119:84:Hvor mange er vel din tjeners dager? Når vil du holde dom over mine forfølgere? 
19:119:85:De overmodige har gravd graver for mig, de som ikke skikker sig efter din lov. 
19:119:86:Alle dine bud er trofasthet; uten årsak forfølger de mig; hjelp mig! 
19:119:87:På lite nær har de tilintetgjort mig i landet; men jeg har ikke forlatt dine befalinger. 
19:119:88:Hold mig i live efter din miskunnhet! Så vil jeg ta vare på din munns vidnesbyrd. 
19:119:89:Til evig tid, Herre, står ditt ord fast i himmelen. 
19:119:90:Fra slekt til slekt varer din trofasthet; du grunnfestet jorden, og den stod der. 
19:119:91:Til å utføre dine dommer står de* der enn idag; for alle ting er dine tjenere. # <* himmelen og jorden.> 
19:119:92:Dersom din lov ikke hadde vært min lyst, var jeg omkommet i min elendighet. 
19:119:93:Til evig tid skal jeg ikke glemme dine befalinger; for ved dem har du holdt mig i live. 
19:119:94:Din er jeg, frels mig! For jeg har søkt dine befalinger. 
19:119:95:De ugudelige har bidd på mig for å ødelegge mig; jeg gir akt på dine vidnesbyrd. 
19:119:96:På all fullkommenhet har jeg sett en ende, men ditt bud strekker sig såre vidt. 
19:119:97:Hvor jeg har din lov kjær! Hele dagen grunder jeg på den. 
19:119:98:Dine bud gjør mig visere enn mine fiender; for til evig tid eier jeg dem. 
19:119:99:Jeg er blitt klokere enn alle mine lærere; for jeg grunder på dine vidnesbyrd. 
19:119:100:Jeg er forstandigere enn de gamle; for jeg har tatt vare på dine befalinger. 
19:119:101:Fra enhver ond sti har jeg holdt mine føtter borte forat jeg kunde holde ditt ord. 
19:119:102:Fra dine lover er jeg ikke avveket; for du har lært mig. 
19:119:103:Hvor dine ord er søte for min gane, mere enn honning for min munn! 
19:119:104:Av dine befalinger får jeg forstand; derfor hater jeg all løgnens sti. 
19:119:105:Ditt ord er en lykte for min fot og et lys for min sti. 
19:119:106:Jeg har svoret, og jeg har holdt det, å ta vare på din rettferdighets lover. 
19:119:107:Jeg er såre nedbøiet; Herre, hold mig i live efter ditt ord! 
19:119:108:La min munns frivillige offere tekkes dig, Herre, og lær mig dine lover! 
19:119:109:Jeg går alltid med livet i hendene, men din lov har jeg ikke glemt. 
19:119:110:De ugudelige har lagt snare for mig, men jeg har ikke forvillet mig fra dine befalinger. 
19:119:111:Jeg har fått dine vidnesbyrd i eie evindelig; for de er mitt hjertes glede. 
19:119:112:Jeg har bøiet mitt hjerte til å gjøre efter dine forskrifter evindelig, inntil enden. 
19:119:113:De tvesinnede hater jeg, men din lov elsker jeg. 
19:119:114:Du er mitt skjul og mitt skjold, jeg venter på ditt ord. 
19:119:115:Vik fra mig, I som gjør ondt, forat jeg må holde min Guds bud! 
19:119:116:Hold mig oppe efter ditt ord, forat jeg kan leve, og la mig ikke bli til skamme med mitt håp! 
19:119:117:Støtt mig, så jeg må bli frelst! Da vil jeg alltid skue med lyst på dine forskrifter. 
19:119:118:Du akter for intet alle dem som farer vill fra dine forskrifter; for deres svik er forgjeves. 
19:119:119:Som slagg bortrydder du alle ugudelige på jorden; derfor elsker jeg dine vidnesbyrd. 
19:119:120:Av redsel for dig gyser min kropp, og jeg frykter for dine dommer. 
19:119:121:Jeg har gjort rett og rettferdighet; du vil ikke overgi mig til dem som undertrykker mig. 
19:119:122:Gå i borgen for din tjener, forat det kan gå ham vel! La ikke de overmodige undertrykke mig! 
19:119:123:Mine øine vansmekter av lengsel efter din frelse og efter din rettferdighets ord. 
19:119:124:Gjør med din tjener efter din miskunnhet, og lær mig dine forskrifter! 
19:119:125:Jeg er din tjener; lær mig, så jeg kan kjenne dine vidnesbyrd! 
19:119:126:Det er tid for Herren å gripe inn; de har brutt din lov. 
19:119:127:Derfor elsker jeg dine bud mere enn gull, ja mere enn fint gull. 
19:119:128:Derfor holder jeg alle befalinger om alle ting for å være rette; jeg hater all løgnens sti. 
19:119:129:Underfulle er dine vidnesbyrd; derfor tar min sjel vare på dem. 
19:119:130:Dine ords åpenbaring oplyser, den gjør enfoldige forstandige. 
19:119:131:Jeg åpnet min munn og sukket av lengsel; for jeg stundet efter dine bud. 
19:119:132:Vend dig til mig og vær mig nådig, som rett er mot dem som elsker ditt navn! 
19:119:133:Gjør mine trin faste ved ditt ord, og la ingen urett herske over mig! 
19:119:134:Forløs mig fra menneskers vold! Så vil jeg holde dine befalinger. 
19:119:135:La ditt åsyn lyse for din tjener, og lær mig dine forskrifter! 
19:119:136:Mine øine flyter bort i vannstrømmer over dem som ikke holder din lov. 
19:119:137:Du er rettferdig, Herre, og dine dommer er rettvise. 
19:119:138:Du har fastsatt dine vidnesbyrd i rettferdighet og stor trofasthet. 
19:119:139:Min nidkjærhet har fortært mig, fordi mine motstandere har glemt dine ord. 
19:119:140:Ditt ord er vel renset, og din tjener elsker det. 
19:119:141:Jeg er liten og foraktet; jeg har ikke glemt dine befalinger. 
19:119:142:Din rettferdighet er en evig rettferdighet, og din lov er sannhet. 
19:119:143:Nød og trengsel fant mig; dine bud er min lyst. 
19:119:144:Dine vidnesbyrd er rettferdige til evig tid; lær mig, forat jeg kan leve! 
19:119:145:Jeg roper av hele mitt hjerte; svar mig, Herre! Jeg vil ta vare på dine forskrifter. 
19:119:146:Jeg roper til dig, frels mig! Så vil jeg holde dine vidnesbyrd. 
19:119:147:Jeg var årle oppe i morgenlysningen og ropte om hjelp; jeg ventet på dine ord. 
19:119:148:Mine øine var oppe før nattevaktene, forat jeg kunde grunde på ditt ord. 
19:119:149:Hør min røst efter din miskunnhet! Herre, hold mig i live efter dine lover! 
19:119:150:De er kommet nær som jager efter ugjerning; fra din lov er de kommet langt bort. 
19:119:151:Du er nær, Herre, og alle dine bud er sannhet. 
19:119:152:For lenge siden vet jeg av dine vidnesbyrd at du har grunnet dem for evig tid. 
19:119:153:Se min elendighet og utfri mig! For jeg har ikke glemt din lov. 
19:119:154:Før min sak og forløs mig, hold mig i live efter ditt ord! 
19:119:155:Frelse er langt borte fra de ugudelige; for de søker ikke dine forskrifter. 
19:119:156:Din miskunnhet er stor, Herre; hold mig i live efter dine dommer! 
19:119:157:Mange er mine forfølgere og mine motstandere; jeg er ikke avveket fra dine vidnesbyrd. 
19:119:158:Jeg så de troløse og vemmedes, dem som ikke holdt ditt ord. 
19:119:159:Se at jeg har elsket dine befalinger! Herre, hold mig i live efter din miskunnhet! 
19:119:160:Summen av ditt ord er sannhet, og til evig tid står all din rettferdighets lov fast. 
19:119:161:Fyrster forfulgte mig uten årsak, men mitt hjerte fryktet for dine ord. 
19:119:162:Jeg gleder mig over ditt ord som en som finner meget bytte. 
19:119:163:Løgn har jeg hatet og avskydd, din lov har jeg elsket. 
19:119:164:Syv ganger om dagen har jeg prist dig for din rettferdighets lover. 
19:119:165:Megen fred har de som elsker din lov, og det er ikke noget anstøt for dem. 
19:119:166:Jeg har ventet på din frelse, Herre, og jeg har holdt dine bud. 
19:119:167:Min sjel har holdt dine vidnesbyrd, og jeg elsket dem såre. 
19:119:168:Jeg har holdt dine befalinger og dine vidnesbyrd; for alle mine veier er for ditt åsyn. 
19:119:169:La mitt klagerop komme nær for ditt åsyn, Herre! Lær mig efter ditt ord! 
19:119:170:La min inderlige bønn komme for ditt åsyn! Frels mig efter ditt ord! 
19:119:171:Mine leber skal flyte over av lovsang; for du lærer mig dine forskrifter. 
19:119:172:Min tunge skal synge om ditt ord; for alle dine bud er rettferdighet. 
19:119:173:La din hånd være mig til hjelp! For jeg har utvalgt dine befalinger. 
19:119:174:Jeg lenges efter din frelse, Herre, og din lov er min lyst. 
19:119:175:La min sjel leve og love dig, og la dine dommer hjelpe mig! 
19:119:176:Jeg har faret vill; opsøk din tjener som et tapt får! for jeg har ikke glemt dine bud. 
19:120:1:En sang ved festreisene. Til Herren ropte jeg i min nød, og han svarte mig. 
19:120:2:Herre, fri min sjel fra en løgnaktig lebe, fra en falsk tunge! 
19:120:3:Hvad vil han gi dig, og hvad mere vil han gi dig, du falske tunge? 
19:120:4:Voldsmannens skarpe piler og glør av gyvelbusken*. # <* d.e. fordervende og smertefulle straffer.> 
19:120:5:Ve mig, at jeg lever som fremmed iblandt Mesek, at jeg bor ved Kedars telt*! # <* d.e. iblandt mennesker som ligner de stridslystne og rovgjerrige folkeslag Mesek og Kedar.> 
19:120:6:Lenge nok har min sjel bodd hos dem som hater fred. 
19:120:7:Jeg er bare fred, men når jeg taler, er de ferdige til krig. 
19:121:1:En sang ved festreisene. Jeg løfter mine øine op til fjellene; hvor skal min hjelp komme fra? 
19:121:2:Min hjelp kommer fra Herren, himmelens og jordens skaper. 
19:121:3:Han skal ingenlunde la din fot vakle, din vokter skal ingenlunde slumre. 
19:121:4:Se, han slumrer ikke og sover ikke, Israels vokter. 
19:121:5:Herren er din vokter, Herren er din skygge ved din høire hånd. 
19:121:6:Solen skal ikke stikke dig om dagen, ei heller månen om natten. 
19:121:7:Herren skal bevare dig fra alt ondt, han skal bevare din sjel. 
19:121:8:Herren skal bevare din utgang og din inngang fra nu av og inntil evig tid. 
19:122:1:En sang ved festreisene; av David. Jeg gleder mig ved dem som sier til mig: Vi vil gå til Herrens hus. 
19:122:2:Våre føtter står i dine porter, Jerusalem! 
19:122:3:Jerusalem, du velbyggede, lik en by som er tett sammenføiet, 
19:122:4:hvor stammene drar op, Herrens stammer, efter en lov for Israel, for å prise Herrens navn! 
19:122:5:For der er stoler satt til dom, stoler for Davids hus. 
19:122:6:Bed om fred for Jerusalem! La det gå dem vel som elsker dig! 
19:122:7:Der være fred innen din voll, ro i dine saler! 
19:122:8:For mine brødres og mine venners skyld vil jeg si: Fred være i dig! 
19:122:9:For Herrens, vår Guds huses skyld vil jeg søke ditt beste. 
19:123:1:En sang ved festreisene. Til dig løfter jeg mine øine, du som troner i himmelen! 
19:123:2:Se, likesom tjeneres øine følger deres herrers hånd, likesom en tjenestepikes øine følger hennes frues hånd, således følger våre øine Herren vår Gud, inntil han blir oss nådig. 
19:123:3:Vær oss nådig, Herre, vær oss nådig! For vi er rikelig mettet med forakt; 
19:123:4:rikelig mettet er vår sjel blitt med spott fra de trygge, med forakt fra de overmodige. 
19:124:1:En sang ved festreisene; av David. Hadde ikke Herren vært med oss - så sie Israel - 
19:124:2:hadde ikke Herren vært med oss da menneskene stod op imot oss, 
19:124:3:da hadde de slukt oss levende, da deres vrede var optendt imot oss, 
19:124:4:da hadde vannene overskyllet oss, en strøm var gått over vår sjel, 
19:124:5:da var de gått over vår sjel de stolte vann. 
19:124:6:Lovet være Herren, som ikke gav oss til rov for deres tenner! 
19:124:7:Vår sjel er undsloppet som en fugl av fuglefangernes snare; snaren er sønderrevet, og vi er undsloppet. 
19:124:8:Vår hjelp er i Herrens navn, han som gjorde himmel og jord. 
19:125:1:En sang ved festreisene. De som setter sin lit til Herren, er som Sions berg, som ikke rokkes, men står evindelig. 
19:125:2:Rundt omkring Jerusalem er det fjell, og Herren omhegner sitt folk fra nu av og inntil evig tid. 
19:125:3:For ugudelighetens kongestav skal ikke hvile på de rettferdiges arvelodd, forat ikke de rettferdige skal utstrekke sine hender efter urettferdighet. 
19:125:4:Gjør godt, Herre, mot de gode, mot dem som er opriktige i sine hjerter! 
19:125:5:Men dem som bøier av til sine krokete veier, skal Herren la fare sammen med dem som gjør urett. Fred være over Israel! 
19:126:1:En sang ved festreisene. Da Herren lot Sions fanger vende tilbake, var vi som drømmende; 
19:126:2:da fyltes vår munn med latter, og vår tunge med jubel, da sa de iblandt hedningene: Store ting har Herren gjort imot disse. 
19:126:3:Store ting har Herren gjort imot oss; vi blev glade. 
19:126:4:Herre, la våre fanger vende tilbake likesom bekker i sydlandet*! # <* likesom disse efter å ha vært uttørket i heten atter fylles når Herren sender regn.> 
19:126:5:De som sår med gråt, skal høste fryderop. 
19:126:6:De går gråtende og bærer den de strør ut; de kommer hjem fryderop og bærer sine kornbånd. 
19:127:1:En sang ved festreisene; av Salomo. Dersom Herren ikke bygger huset, arbeider de forgjeves som bygger på det; dersom Herren ikke vokter byen, våker vekteren forgjeves. 
19:127:2:Det er forgjeves at I står tidlig op, setter eder sent ned, eter møisommelighets brød; det samme gir han sin venn i søvne. 
19:127:3:Se, barn er Herrens gave, livsfrukt er en lønn. 
19:127:4:Som piler i den veldige kjempes hånd, således er ungdoms sønner. 
19:127:5:Lykksalig er den mann som har sitt kogger fullt av dem; de blir ikke til skamme når de taler med fiender i porten. 
19:128:1:En sang ved festreisene. Lykksalig er hver den som frykter Herren, som vandrer på hans veier. 
19:128:2:Frukten av dine henders arbeid skal du nyte; lykksalig er du, og det går dig vel. 
19:128:3:Din hustru er som et fruktbart vintre der inne i ditt hus, dine barn som oljekvister rundt om ditt bord. 
19:128:4:Se, således blir den mann velsignet som frykter Herren. 
19:128:5:Herren skal velsigne dig fra Sion, og du skal skue med lyst Jerusalems lykke alle ditt livs dager. 
19:128:6:Og du skal se barn av dine barn. Fred være over Israel! 
19:129:1:En sang ved festreisene. Meget har de trengt mig fra min ungdom av - så sie Israel - 
19:129:2:meget har de trengt mig fra min ungdom av; men de har ikke fått overhånd over mig. 
19:129:3:Min rygg har plogmenn pløid, de har gjort sine furer lange. 
19:129:4:Herren er rettferdig, han har avhugget de ugudeliges rep. 
19:129:5:De skal bli til skamme og vike tilbake alle de som hater Sion, 
19:129:6:de skal bli som gress på takene, som er visnet før det blir rykket op: 
19:129:7:Høstmannen fyller ikke sin hånd, ei heller den som binder kornbånd, sitt fang. 
19:129:8:Og de som går forbi, sier ikke: Herrens velsignelse være over eder, vi velsigner eder i Herrens navn! 
19:130:1:En sang ved festreisene. Av det dype kaller jeg på dig, Herre! 
19:130:2:Herre, hør min røst, la dine ører akte på mine inderlige bønners røst! 
19:130:3:Dersom du, Herre, vil gjemme på misgjerninger, Herre, hvem kan da bli stående? 
19:130:4:For hos dig er forlatelsen, forat du må fryktes. 
19:130:5:Jeg bier efter Herren, min sjel bier, og jeg venter på hans ord. 
19:130:6:Min sjel venter på Herren mere enn vektere på morgenen, vektere på morgenen. 
19:130:7:Vent på Herren, Israel! For hos Herren er miskunnheten, og megen forløsning er hos ham, 
19:130:8:og han skal forløse Israel fra alle dets misgjerninger. 
19:131:1:En sang ved festreisene; av David. Herre! Mitt hjerte er ikke stolt, og mine øine er ikke høie, og jeg gir mig ikke av med ting som er mig for store og for underlige. 
19:131:2:Sannelig, jeg har fått min sjel til å være stille og tie likesom et avvent barn hos sin mor; som det avvente barn er min sjel hos mig. 
19:131:3:Vent på Herren, Israel, fra nu og inntil evig tid! 
19:132:1:En sang ved festreisene. Herre, gi David lønn for hans møie, 
19:132:2:han som tilsvor Herren, gav Jakobs Veldige det løfte: 
19:132:3:Jeg vil ikke gå inn i mitt huses telt, jeg vil ikke stige op på min sengs leie, 
19:132:4:jeg vil ikke unne mine øine søvn, mine øielokk slummer, 
19:132:5:før jeg finner et sted for Herren, en bolig for Jakobs Veldige! 
19:132:6:Se, vi hørte om den* i Efrata**, vi fant den i skogsbygden***. # <* d.e. paktens ark.> # <** 1MO 35, 19.> # <*** Kirjat-Jearim.> 
19:132:7:Vi vil komme til hans bolig, vi vil tilbede for hans føtters skammel. 
19:132:8:Reis dig, Herre, og kom til ditt hvilested, du og din styrkes ark! 
19:132:9:La dine prester klæ sig i rettferd og dine fromme rope med fryd! 
19:132:10:For Davids, din tjeners skyld vise du ikke din salvedes åsyn tilbake! 
19:132:11:Herren har svoret David en sann ed, som han ikke vil gå fra: Av ditt livs frukt vil jeg sette konger på din trone; 
19:132:12:dersom dine barn holder min pakt og mine vidnesbyrd, som jeg vil lære dem, da skal også deres barn for all tid sitte på dine trone. 
19:132:13:For Herren har utkåret Sion, han har attrådd det til sin bolig: 
19:132:14:Dette er mitt hvilested til evig tid; her vil jeg bo, fordi jeg har attrådd det. 
19:132:15:Dets mat vil jeg velsigne, dets fattige vil jeg mette med brød, 
19:132:16:og dets prester vil jeg klæ med fryd. 
19:132:17:Der vil jeg la et horn vokse op for David, gjøre i stand en lampe for min salvede. 
19:132:18:Hans fiender vil jeg klæ i skam, men på ham skal hans krone stråle. 
19:133:1:En sang ved festreisene; av David. Se, hvor godt og hvor liflig det er at brødre også bor sammen! 
19:133:2:Det er som den gode olje på hodet, som flyter ned på skjegget, Arons skjegg, som flyter ned på sømmen av hans klædebon, 
19:133:3:Som Hermons-dugg, som flyter ned på Sions berg; for der har Herren satt velsignelsen, liv til evig tid. 
19:134:1:En sang ved festreisene. Se, lov Herren, alle i Herrens tjenere, I som står i Herrens hus om nettene! 
19:134:2:Løft eders hender op til helligdommen og lov Herren! 
19:134:3:Herren velsigne dig fra Sion, han som gjorde himmel og jord! 
19:135:1:Halleluja! Lov Herrens navn, lov, I Herrens tjenere, 
19:135:2:I som står i Herrens hus, i forgårdene til vår Guds hus! 
19:135:3:Lov Herren! for Herren er god, lovsyng hans navn! for det er liflig. 
19:135:4:For Herren har utvalgt sig Jakob, Israel til sin eiendom. 
19:135:5:Jeg vet at Herren er stor, og vår Herre større enn alle guder. 
19:135:6:Herren gjør alt det han vil, i himmelen og på jorden, i havene og alle dyp, 
19:135:7:han som lar regnskyer stige op fra jordens ende, gjør lyn til regn, fører vind ut av sine forrådshus, 
19:135:8:han som slo de førstefødte i Egypten, både mennesker og fe. 
19:135:9:som sendte tegn og under midt i dig, Egypten, mot Farao og mot alle hans tjenere, 
19:135:10:han som slo mange hedningefolk og drepte mektige konger, 
19:135:11:Sihon, amorittenes konge, og Og, Basans konge, og alle Kana'ans kongeriker, 
19:135:12:og gav deres land til arv, gav Israel, sitt folk, det til arv. 
19:135:13:Herre, ditt navn blir til evig tid, Herre, ditt minne fra slekt til slekt. 
19:135:14:For Herren skal dømme sitt folk, og han skal miskunne sig over sine tjenere. 
19:135:15:Hedningenes avguder er sølv og gull, et verk av menneskers hender. 
19:135:16:De har munn, men taler ikke; de har øine, men ser ikke; 
19:135:17:de har ører, men hører ikke, og det er ikke nogen ånde i deres munn. 
19:135:18:Som de selv er, blir de som gjør dem, hver den som setter sin lit til dem. 
19:135:19:Israels hus, lov Herren! Arons hus, lov Herren! 
19:135:20:Levis hus, lov Herren! I som frykter Herren, lov Herren! 
19:135:21:Lovet være Herren fra Sion, han som bor i Jerusalem! Halleluja! 
19:136:1:Pris Herren! for han er god, hans miskunnhet varer evindelig. 
19:136:2:Pris gudenes Gud! for hans miskunnhet varer evindelig. 
19:136:3:Pris herrenes Herre! for hans miskunnhet varer evindelig; 
19:136:4:ham som alene gjør store undergjerninger, for hans miskunnhet varer evindelig; 
19:136:5:ham som gjorde himmelen med forstand, for hans miskunnhet varer evindelig; 
19:136:6:ham som strakte jorden over vannene, for hans miskunnhet varer evindelig; 
19:136:7:ham som gjorde de store lys, for hans miskunnhet varer evindelig, 
19:136:8:solen til å råde om dagen, for hans miskunnhet varer evindelig, 
19:136:9:månen og stjernene til å råde om natten, for hans miskunnhet varer evindelig; 
19:136:10:ham som slo egypterne i deres førstefødte, for hans miskunnhet varer evindelig, 
19:136:11:og førte Israel ut fra dem, for hans miskunnhet varer evindelig, 
19:136:12:med sterk hånd og med utrakt arm, for hans miskunnhet varer evindelig; 
19:136:13:ham som skar det Røde Hav i stykker, for hans miskunnhet varer evindelig, 
19:136:14:og lot Israel gå midt gjennem det, for hans miskunnhet varer evindelig, 
19:136:15:Og kastet Farao og hans hær i det Røde Hav, for hans miskunnhet varer evindelig; 
19:136:16:ham som førte sitt folk gjennem ørkenen, for hans miskunnhet varer evindelig; 
19:136:17:ham som slo store konger, for hans miskunnhet varer evindelig, 
19:136:18:og drepte herlige konger, for hans miskunnhet varer evindelig, 
19:136:19:Sihon, amorittenes konge, for hans miskunnhet varer evindelig, 
19:136:20:og Og, Basans konge, for hans miskunnhet varer evindelig, 
19:136:21:og gav deres land til arv, for hans miskunnhet varer evindelig, 
19:136:22:gav Israel, sin tjener, det til arv, for hans miskunnhet varer evindelig; 
19:136:23:ham som kom oss i hu i vår fornedrelse, for hans miskunnhet varer evindelig, 
19:136:24:og rev oss ut av våre fienders vold, for hans miskunnhet varer evindelig; 
19:136:25:ham som gir alt kjød føde, for hans miskunnhet varer evindelig. 
19:136:26:Pris himmelens Gud, for hans miskunnhet varer evindelig! 
19:137:1:Ved Babylons elver, der satt vi og gråt når vi kom Sion i hu. 
19:137:2:På vidjene der hengte vi våre harper; 
19:137:3:for der krevde våre fangevoktere sanger av oss, våre plagere at vi skulde være glade: Syng for oss av Sions sanger! 
19:137:4:Hvorledes skulde vi synge Herrens sang på fremmed jord? 
19:137:5:Glemmer jeg dig, Jerusalem, da glemme mig* min høire hånd! # <* nekte mig sin tjeneste.> 
19:137:6:Min tunge henge fast ved min gane om jeg ikke kommer dig i hu, om jeg ikke setter Jerusalem over min høieste glede! 
19:137:7:Kom Jerusalems dag i hu, Herre, så du straffer Edoms barn, dem som sa: Riv ned, riv ned, like til grunnen i den! 
19:137:8:Babels datter, du ødelagte! Lykksalig er den som gir dig gjengjeld for den gjerning du gjorde mot oss. 
19:137:9:Lykksalig er den som griper og knuser dine spede barn imot klippen. 
19:138:1:Av David. Jeg vil prise dig av hele mitt hjerte, for gudenes* øine vil jeg lovsynge dig. # <* SLM 82, 1. 6. JOH 10, 34.> 
19:138:2:Jeg vil kaste mig ned foran ditt hellige tempel, og jeg vil prise ditt navn for din miskunnhets og din trofasthets skyld; for du har gjort ditt ord herlig, mere enn alt ditt navn. 
19:138:3:Den dag jeg ropte, svarte du mig; du gjorde mig frimodig, i min sjel kom det styrke. 
19:138:4:Herre! Alle jordens konger skal prise dig når de får høre din munns ord. 
19:138:5:Og de skal synge om Herrens veier; for Herrens ære er stor, 
19:138:6:for Herren er ophøiet, og han ser til den ringe, og den stolte kjenner han langt fra. 
19:138:7:Om jeg vandrer midt i trengsel, holder du mig i live; mot mine fienders vrede rekker du ut din hånd, og du frelser mig ved din høire hånd. 
19:138:8:Herren vil fullføre sin gjerning for mig. Herre, din miskunnhet varer evindelig; opgi ikke dine henders gjerninger! 
19:139:1:Til sangmesteren; av David; en salme. Herre, du ransaker mig og kjenner mig. 
19:139:2:Enten jeg sitter, eller jeg står op, da vet du det; du forstår min tanke langt fra. 
19:139:3:Min sti og mitt leie gransker du ut, og du kjenner grant alle mine veier. 
19:139:4:For det er ikke et ord på min tunge - se, Herre, du vet det alt sammen. 
19:139:5:Bakfra og forfra omgir du mig, og du legger din hånd på mig. 
19:139:6:Å forstå dette er mig for underlig, det er for høit, jeg makter det ikke. 
19:139:7:Hvor skal jeg gå fra din Ånd, og hvor skal jeg fly fra ditt åsyn? 
19:139:8:Farer jeg op til himmelen så er du der, og vil jeg rede mitt leie i dødsriket, se, da er du der. 
19:139:9:Tar jeg morgenrødens vinger, og vil jeg bo ved havets ytterste grense, 
19:139:10:så fører også der din hånd mig, og din høire hånd holder mig fast. 
19:139:11:Og sier jeg: Mørket skjule mig, og schmelken omkring mig bli natt - 
19:139:12:så gjør heller ikke mørket det for mørkt for dig, og natten lyser som dagen, mørket er som schmelken. 
19:139:13:For du har skapt mine nyrer, du virket mig i min mors liv. 
19:139:14:Jeg priser dig fordi jeg er virket på forferdelig underfull vis; underfulle er dine gjerninger, og min sjel kjenner det såre vel. 
19:139:15:Mine ben var ikke skjult for dig da jeg blev virket i lønndom, da jeg blev kunstig virket i jordens dyp*. # <* d.e. i mors liv.> 
19:139:16:Da jeg bare var foster, så dine øine mig, og i din bok blev de alle opskrevet de dager som blev fastsatt da ikke en av dem var kommet. 
19:139:17:Hvor vektige dine tanker er for mig, Gud, hvor store deres summer! 
19:139:18:Vil jeg telle dem, så er de flere enn sand; jeg våkner op, og jeg er ennu hos dig. 
19:139:19:Gud, gid du vilde drepe den ugudelige, og I blodtørstige menn, vik fra mig - 
19:139:20:de som nevner ditt navn til å fremme onde råd, som bruker det til løgn, dine fiender! 
19:139:21:Skulde jeg ikke hate dem som hater dig, Herre, og avsky dem som reiser sig imot dig? 
19:139:22:Jeg hater dem med et fullkomment hat; de er mine fiender. 
19:139:23:Ransak mig, Gud, og kjenn mitt hjerte! Prøv mig og kjenn mine mangehånde tanker, 
19:139:24:og se om jeg er på fortapelsens vei, og led mig på evighetens vei! 
19:140:1:Til sangmesteren; en salme av David. 
19:140:2:Herre, fri mig ut fra onde mennesker, vokt mig for voldsmenn, 
19:140:3:som tenker ondt ut i hjertet, som hver dag samler sig til krig! 
19:140:4:De skjerper sin tunge som en slange, ormegift er under deres leber. Sela. 
19:140:5:Bevar mig, Herre, for ugudeliges hender, vokt mig for voldsmenn, som tenker på å få mine føtter til fall! 
19:140:6:Overmodige har lagt skjulte feller og rep for mig, de har utspent garn ved siden av veien, de har satt snarer for mig. Sela. 
19:140:7:Jeg sier til Herren: Du er min Gud; vend øret, Herre, til mine inderlige bønners røst! 
19:140:8:Herren, Israels Gud, er min frelses styrke; du dekker mitt hode på rustningens dag. 
19:140:9:Herre, gi ikke den ugudelige hvad han attrår, la ikke hans onde råd få fremgang! De vilde da ophøie sig. Sela. 
19:140:10:Over deres hoder som omgir mig, skal den ulykke komme som deres leber volder. 
19:140:11:Der skal rystes glør ut over dem; i ilden skal han styrte dem, i dype vann, så de ikke skal reise sig. 
19:140:12:En munnkåt mann skal ikke bli stående i landet; den mann som gjør ugudelig vold, ham skal han jage med slag på slag. 
19:140:13:Jeg vet at Herren gir den elendige rett, de fattige rettferdighet. 
19:140:14:Ja, de rettferdige skal prise ditt navn, de opriktige skal bo for ditt åsyn. 
19:141:1:En salme av David. Herre, jeg kaller på dig, skynd dig til mig! Vend øret til min røst, nu jeg roper til dig! 
19:141:2:La min bønn gjelde som røkoffer for ditt åsyn, mine henders opløftelse som et aften-matoffer! 
19:141:3:Herre, sett vakt for min munn, vokt mine lebers dør! 
19:141:4:Bøi ikke mitt hjerte til noget ondt, til å gjøre ugudelighets-gjerninger sammen med menn som gjør urett, og la mig ikke ete av deres fine retter! 
19:141:5:La en rettferdig slå mig i kjærlighet og tukte mig! For sådan hodeolje vegre mitt hode sig ikke! Varer det enn ved, så setter jeg min bønn imot deres ondskap. 
19:141:6:Deres dommere blir nedstyrtet og gitt klippen i vold, og selv forstår de at mine ord var liflige. 
19:141:7:Som når en pløier og kløver jorden, således ligger våre ben spredt ved dødsrikets port. 
19:141:8:For til dig, Herre, Herre, ser mine øine, til dig tar jeg min tilflukt; utøs ikke min sjel! 
19:141:9:Bevar mig for fellen som de har stilt op for mig, og for deres snarer som gjør urett! 
19:141:10:La de ugudelige falle i sine egne garn, mens jeg går uskadd forbi! 
19:142:1:En læresalme av David, da han var i hulen, en bønn. 
19:142:2:Med min røst roper jeg til Herren, med min røst beder jeg inderlig til Herren. 
19:142:3:Jeg utøser min sorg for hans åsyn, jeg gir min nød til kjenne for hans åsyn. 
19:142:4:Når min ånd vansmekter i mig, kjenner dog du min sti; på den vei jeg skal vandre, har de lagt skjulte snarer for mig. 
19:142:5:Sku til min høire side og se! Det er ikke nogen som kjennes ved mig; all tilflukt er borte for mig, det er ikke nogen som spør efter min sjel. 
19:142:6:Jeg roper til dig, Herre! Jeg sier: Du er min tilflukt, min del i de levendes land. 
19:142:7:Gi akt på mitt klagerop, for jeg er blitt såre elendig! Fri mig fra mine forfølgere, for de er mig for sterke! 
19:142:8:Før min sjel ut av fengslet, så jeg kan love ditt navn! De rettferdige skal samle sig omkring mig, når du gjør vel imot mig. 
19:143:1:En salme av David. Herre, hør min bønn, vend øret til mine inderlige bønner, svar mig i din trofasthet, i din rettferdighet, 
19:143:2:og gå ikke i rette med din tjener! For ingen som lever, er rettferdig for ditt åsyn. 
19:143:3:For fienden har forfulgt min sjel, han har knust mitt liv til jorden, han har satt mig på mørke steder som de evig døde. 
19:143:4:Og min ånd er vansmektet i mig, mitt hjerte er forferdet inneni mig. 
19:143:5:Jeg kommer fordums dager i hu, jeg tenker på alt ditt verk, jeg grunder på dine henders gjerning. 
19:143:6:Jeg utbreder mine hender til dig, min sjel lenges efter dig som et vansmektende land. Sela. 
19:143:7:Skynd dig å svare mig, Herre! Min ånd fortæres; skjul ikke ditt åsyn for mig, så jeg blir lik dem som farer ned i graven! 
19:143:8:La mig årle høre din miskunnhet, for til dig setter jeg min lit! Kunngjør mig den vei jeg skal vandre, for til dig opløfter jeg min sjel! 
19:143:9:Fri mig fra mine fiender, Herre! Hos dig søker jeg ly. 
19:143:10:Lær mig å gjøre din vilje, for du er min Gud! Din gode Ånd lede mig på jevnt land! 
19:143:11:For ditt navns skyld, Herre, vil du holde mig i live; i din rettferdighet vil du føre min sjel ut av trengsel, 
19:143:12:og i din miskunnhet vil du utrydde mine fiender og ødelegge alle dem som trenger min sjel; for jeg er din tjener. 
19:144:1:Av David. Lovet være Herren, min klippe, han som oplærer mine hender til strid, mine fingrer til krig, 
19:144:2:min miskunn og min festning, min borg og min redningsmann, mitt skjold og den jeg tar min tilflukt til, den som tvinger mitt folk under mig. 
19:144:3:Herre, hvad er et menneske, at du kjenner ham, et menneskebarn, at du akter på ham! 
19:144:4:Et menneske er lik et åndepust, hans dager er som en skygge som farer forbi. 
19:144:5:Herre, bøi din himmel og far ned, rør ved fjellene så de ryker! 
19:144:6:La lynet lyne og spred dem*, send dine piler og skrem dem! # <* fiendene.> 
19:144:7:Rekk ut dine hender fra det høie, fri mig og frels mig fra store vann, fra fremmedes hånd, 
19:144:8:de hvis munn taler svik, og hvis høire hånd er en løgnens hånd. 
19:144:9:Gud! En ny sang vil jeg synge dig, til tistrenget harpe vil jeg lovsynge dig, 
19:144:10:du som gir kongene frelse, som redder David, din tjener, fra det onde sverd. 
19:144:11:Frels mig og fri mig fra fremmedes hånd, de hvis munn taler svik, og hvis høire hånd er en løgnens hånd, 
19:144:12:forat våre sønner må være som planter, høit vokset i sin ungdom, våre døtre som hjørnestolper, hugget som til et slott, 
19:144:13:forat våre forrådshus må være fulle og gi av alle slag, at vårt småfe må øke sig i tusentall, ja i titusentall på våre gater, 
19:144:14:at våre kuer må ha kalv, at det ingen skade må være og intet tap og intet klageskrik på våre gater. 
19:144:15:Salig er det folk som det går således; salig er det folk hvis Gud Herren er. 
19:145:1:En lovsang av David. Jeg vil ophøie dig, min Gud, du som er kongen, og jeg vil love ditt navn evindelig og alltid. 
19:145:2:Hver dag vil jeg love dig, og jeg vil prise ditt navn evindelig og alltid. 
19:145:3:Herren er stor og høilovet, og hans storhet er uransakelig. 
19:145:4:En slekt skal lovprise for den annen dine gjerninger, og dine veldige gjerninger skal de forkynne. 
19:145:5:På din majestets herlighet og ære og på dine undergjerninger vil jeg grunde. 
19:145:6:Og om dine sterke og forferdelige gjerninger skal de tale, og dine store gjerninger vil jeg fortelle. 
19:145:7:Minneord om din store godhet skal de la strømme ut og synge med fryd om din rettferdighet. 
19:145:8:Herren er nådig og barmhjertig, langmodig og stor i miskunnhet. 
19:145:9:Herren er god imot alle, og hans barmhjertighet er over alle hans gjerninger. 
19:145:10:Alle dine gjerninger skal prise dig, Herre, og dine fromme skal love dig. 
19:145:11:Om ditt rikes herlighet skal de tale og fortelle om ditt velde, 
19:145:12:for å kunngjøre for menneskenes barn dine veldige gjerninger og ditt rikes herlighet og ære. 
19:145:13:Ditt rike er et rike for alle evigheter, og ditt herredømme varer gjennem alle slekter. 
19:145:14:Herren støtter alle dem som faller, og opreiser alle nedbøiede. 
19:145:15:Alles øine vokter på dig, og du gir dem deres føde i sin tid. 
19:145:16:Du oplater din hånd og metter alt levende med velbehag*. # <* d.e. med det som er dem velbehagelig.> 
19:145:17:Herren er rettferdig i alle sine veier og miskunnelig i alle sine gjerninger. 
19:145:18:Herren er nær hos alle dem som kaller på ham, hos alle som kaller på ham i sannhet. 
19:145:19:Han gjør efter deres velbehag som frykter ham, og han hører deres rop og frelser dem. 
19:145:20:Herren bevarer alle dem som elsker ham; men alle de ugudelige ødelegger han. 
19:145:21:Min munn skal uttale Herrens pris, og alt kjød skal love hans hellige navn evindelig og alltid. 
19:146:1:Halleluja! Min sjel, lov Herren! 
19:146:2:Jeg vil love Herren så lenge jeg lever; jeg vil lovsynge min Gud så lenge jeg er til. 
19:146:3:Sett ikke eders lit til fyrster, til et menneskebarn, hos hvem det ikke er frelse! 
19:146:4:Farer hans ånd ut, så vender han tilbake til sin jord; på den samme dag er det forbi med hans tankes råd. 
19:146:5:Salig er den hvis hjelp er Jakobs Gud, hvis håp står til Herren, hans Gud, 
19:146:6:som gjorde himmel og jord, havet og alt hvad i dem er, som er trofast til evig tid, 
19:146:7:som hjelper de undertrykte til deres rett, som gir de hungrige brød. Herren løser de bundne, 
19:146:8:Herren åpner de blindes øine, Herren opreiser de nedbøiede, Herren elsker de rettferdige, 
19:146:9:Herren bevarer de fremmede; farløse og enker holder han oppe, men de ugudeliges vei gjør han kroket. 
19:146:10:Herren skal være konge evindelig, din Gud, Sion, fra slekt til slekt. Halleluja! 
19:147:1:Lov Herren! For det er godt å lovsynge vår Gud, det er liflig, lovsang sømmer sig. 
19:147:2:Herren bygger Jerusalem, de bortdrevne av Israel samler han. 
19:147:3:Han helbreder dem som har et sønderknust hjerte, og forbinder deres smertefulle sår. 
19:147:4:Han fastsetter stjernenes tall, han gir dem alle navn. 
19:147:5:Vår Herre er stor og rik på kraft; på hans forstand er det intet mål. 
19:147:6:Herren holder de saktmodige oppe, bøier de ugudelige ned til jorden. 
19:147:7:Svar Herren med takksigelse, lovsyng vår Gud til citar, 
19:147:8:ham som dekker himmelen med skyer, som lager regn for jorden, som lar gress spire frem på fjellene! 
19:147:9:Han gir feet dets føde, ravneungene som roper. 
19:147:10:Han har ikke lyst til hestens styrke, han har ikke behag i mannens ben. 
19:147:11:Herren har behag i dem som frykter ham, som venter på hans miskunnhet. 
19:147:12:Pris Herren, Jerusalem, lov din Gud, Sion! 
19:147:13:For han har gjort dine portstenger faste, han har velsignet dine barn i dig. 
19:147:14:Han er den som gir dine grenser fred, metter dig med den beste hvete. 
19:147:15:Han er den som sender sin tale til jorden; såre hastig løper hans ord. 
19:147:16:Han er den som gir sne som ull, strør ut rim som aske. 
19:147:17:Han kaster sin is ut som småstykker; hvem kan stå for hans kulde? 
19:147:18:Han sender sitt ord og smelter dem; han lar sin vind blåse, da rinner vannene. 
19:147:19:Han kunngjorde Jakob sitt ord, Israel sine bud og sine lover; 
19:147:20:så har han ikke gjort mot noget hedningefolk, og lover* kjenner de ikke. Halleluja! # <* d.e. Guds lover.> 
19:148:1:Halleluja! Lov Herren fra himmelen, lov ham i det høie! 
19:148:2:Lov ham, alle hans engler, lov ham, all hans hær! 
19:148:3:Lov ham, sol og måne, lov ham, alle I lysende stjerner! 
19:148:4:Lov ham, I himlenes himler og I vann som er ovenover himlene! 
19:148:5:De skal love Herrens navn; for han bød, og de blev skapt, 
19:148:6:og han satte dem på deres sted for all tid, for evig; han gav en lov som ingen av dem overskrider. 
19:148:7:Lov Herren fra jorden, I store sjødyr og alle vanndyp, 
19:148:8:ild og hagl, sne og damp, stormvind, som setter hans ord i verk, 
19:148:9:I fjell og alle hauger, frukttrær og alle sedrer, 
19:148:10:I ville dyr og alt fe, krypdyr og vingede fugler, 
19:148:11:I jordens konger og alle folk, fyrster og alle jordens dommere, 
19:148:12:unge menn og jomfruer, gamle sammen med unge! 
19:148:13:De skal love Herrens navn; for hans navn alene er ophøiet, hans herlighet er over jorden og himmelen, 
19:148:14:og han har ophøiet et horn for sitt folk til en lovsang for alle sine fromme, for Israels barn, det folk som er ham nær. Halleluja! 
19:149:1:Halleluja! Syng Herren en ny sang, hans pris i de frommes forsamling! 
19:149:2:Israel glede sig i sin skaper, Sions barn fryde sig i sin konge! 
19:149:3:De skal love hans navn med dans, lovsynge ham til pauke og citar. 
19:149:4:For Herren har behag i sitt folk, han pryder de saktmodige med frelse. 
19:149:5:De fromme skal fryde sig i herlighet, de skal juble på sitt leie. 
19:149:6:Lovsang for Gud er i deres munn og et tveegget sverd i deres hånd, 
19:149:7:for å fullbyrde hevn over hedningene, straff over folkene, 
19:149:8:for å binde deres konger med lenker og deres fornemme menn med jernbånd, 
19:149:9:for å fullbyrde foreskreven straffedom over dem. Dette er en ære for alle hans fromme. Halleluja! 
19:150:1:Halleluja! Lov Gud i hans helligdom, lov ham i hans mektige hvelving! 
19:150:2:Lov ham for hans veldige gjerninger, lov ham efter hans storhets fylde! 
19:150:3:Lov ham med basunklang, lov ham med harpe og citar! 
19:150:4:Lov ham med pauke og dans, lov ham med strengelek og fløite! 
19:150:5:Lov ham med tonende bekkener, lov ham med høit klingende bekkener! 
19:150:6:Alt som har ånde, love Herren! Halleluja! 
20:1:1: Ordsprog av Salomo, Davids sønn, Israels konge. 
20:1:2: Av dem kan en lære visdom og tukt og å skjønne forstandige ord; 
20:1:3: av dem kan en motta tukt til klokskap og lære rettferdighet og rett og rettvishet; 
20:1:4: de kan gi de enfoldige klokskap, de unge kunnskap og tenksomhet. 
20:1:5: Den vise skal høre på dem og gå frem i lærdom, og den forstandige vinne evne til å leve rett. 
20:1:6: Av dem kan en lære å forstå ordsprog og billedtale, vismenns ord og deres gåter. 
20:1:7: Å frykte Herren er begynnelsen til kunnskap; visdom og tukt foraktes av dårer. 
20:1:8: Hør, min sønn, på din fars tilrettevisning og forlat ikke din mors lære! 
20:1:9: For de er en fager krans for ditt hode og kjeder om din hals. 
20:1:10: Min sønn! Når syndere lokker dig, da samtykk ikke! 
20:1:11: Når de sier: Kom med oss! Vi vil lure efter blod, sette feller for de uskyldige uten grunn; 
20:1:12: vi vil sluke dem levende som dødsriket, med hud og hår, likesom det sluker dem som farer ned i graven; 
20:1:13: vi vil finne alle slags kostelig gods, fylle våre hus med rov; 
20:1:14: du skal få kaste lodd om det med oss, vi skal alle ha samme pung - 
20:1:15: slå da ikke følge med dem, min sønn, hold din fot borte fra deres sti! 
20:1:16: For deres føtter haster til det onde, og de er snare til å utøse blod; 
20:1:17: til ingen nytte blir garnet utspent så alle fuglene ser det; 
20:1:18: de lurer efter sitt eget blod, de setter feller for sig selv. 
20:1:19: Så går det hver den som søker urettferdig vinning; den tar livet av sine egne herrer. 
20:1:20: Visdommen roper høit på gaten, den lar sin røst høre på torvene; 
20:1:21: på hjørnet av larmfylte gater roper den, ved portinngangene, rundt omkring i byen taler den og sier: 
20:1:22: Hvor lenge vil I uforstandige elske uforstand og spotterne ha lyst til spott og dårene hate kunnskap? 
20:1:23: Vend om og gi akt på min tilrettevisning! Da vil jeg la min ånd velle frem for eder, jeg vil kunngjøre eder mine ord. 
20:1:24: Fordi jeg ropte, og I ikke vilde høre, fordi jeg rakte ut min hånd, og ingen gav akt, 
20:1:25: fordi I forsmådde alle mine råd og ikke vilde vite av min tilrettevisning, 
20:1:26: så vil også jeg le når ulykken rammer eder, jeg vil spotte når det kommer som I reddes for, 
20:1:27: når det I reddes for, kommer som et uvær, og eders ulykke farer frem som en stormvind, når trengsel og nød kommer over eder. 
20:1:28: Da skal de kalle på mig, men jeg svarer ikke; de skal søke mig, men ikke finne mig. 
20:1:29: Fordi de hatet kunnskap og ikke vilde frykte Herren, 
20:1:30: fordi de ikke vilde vite av mitt råd og foraktet all min tilrettevisning, 
20:1:31: derfor skal de ete frukten av sine gjerninger, og av sine onde råd skal de mettes. 
20:1:32: For de uforstandiges selvrådighet dreper dem, og dårenes trygghet ødelegger dem; 
20:1:33: men den som hører på mig, skal bo trygt og leve i ro uten frykt for ulykke. 
20:2:1: Min sønn! Dersom du tar imot mine ord og gjemmer mine bud hos dig, 
20:2:2: så du vender ditt øre til visdommen og bøier ditt hjerte til klokskapen, 
20:2:3: ja, dersom du roper efter innsikten og løfter din røst for å kalle på forstanden, 
20:2:4: dersom du leter efter den som efter sølv og graver efter den som efter skjulte skatter, 
20:2:5: da skal du forstå Herrens frykt og finne kunnskap om Gud. 
20:2:6: For Herren er den som gir visdom, fra hans munn kommer kunnskap og forstand, 
20:2:7: og han gjemmer frelse for de opriktige, et skjold for dem som lever ustraffelig, 
20:2:8: han vokter rettens stier og bevarer sine frommes vei. 
20:2:9: Da skal du forstå rettferdighet og rett og rettvishet, ja enhver god vei. 
20:2:10: For visdom skal komme i ditt hjerte, og kunnskap skal glede din sjel; 
20:2:11: eftertanke skal holde vakt over dig, forstand skal verne dig, 
20:2:12: for å fri dig fra onde veier, fra menn som fører forvendt tale, 
20:2:13: fra dem som forlater rettvishets stier for å vandre på mørkets veier, 
20:2:14: som gleder sig ved å gjøre ondt og jubler over onde, forvendte gjerninger, 
20:2:15: som går på krokete stier og følger vrange veier. 
20:2:16: Visdommen skal fri dig fra annen manns hustru, fra fremmed kvinne, som taler glatte ord, 
20:2:17: som har forlatt sin ungdoms venn og glemt sin Guds pakt; 
20:2:18: for hennes hus synker ned i døden, og hennes veier bærer ned til dødningene; 
20:2:19: de som går inn til henne, kommer aldri tilbake og når aldri livets stier. 
20:2:20: Visdommen skal hjelpe dig til å vandre på de godes vei og holde dig på de rettferdiges stier; 
20:2:21: for de opriktige skal bo i landet, og de ustraffelige skal bli tilbake i det, 
20:2:22: men de ugudelige skal utryddes av landet, og de troløse skal rykkes bort fra det. 
20:3:1: Min sønn! Glem ikke min lære og la ditt hjerte bevare mine bud! 
20:3:2: For langt liv og mange leveår og fred skal de gi dig i rikt mål. 
20:3:3: La ikke kjærlighet og trofasthet vike fra dig, bind dem om din hals, skriv dem på ditt hjertes tavle! 
20:3:4: Så skal du finne nåde og få god forstand i Guds og menneskers øine. 
20:3:5: Sett din lit til Herren av hele ditt hjerte, og stol ikke på din forstand! 
20:3:6: Tenk på ham på alle dine veier! Så skal han gjøre dine stier rette. 
20:3:7: Vær ikke vis i egne øine, frykt Herren og vik fra det onde! 
20:3:8: Det skal være lægedom for din kropp og gi ny styrke til dine ben. 
20:3:9: Ær Herren med gaver av ditt gods og med førstegrøden av all din avling! 
20:3:10: Så skal dine lader fylles med overflod og dine persekar flyte over av most. 
20:3:11: Min sønn! Forakt ikke Herrens tukt og vær ikke utålmodig når han refser dig! 
20:3:12: For den Herren elsker, ham refser han, som en far refser den sønn han har kjær. 
20:3:13: Salig er det menneske som har funnet visdom, det menneske som vinner forstand; 
20:3:14: for det er bedre å vinne den enn å vinne sølv, og det utbytte den gir, er bedre enn gull. 
20:3:15: Den er kosteligere enn perler, og alle dine skatter kan ikke lignes med den. 
20:3:16: Langt liv har den i sin høire hånd, rikdom og ære i sin venstre. 
20:3:17: Dens veier er fagre veier, og alle dens stier fører til lykke. 
20:3:18: Den er et livsens tre for dem som griper den, og hver den som holder fast på den, må prises lykkelig. 
20:3:19: Herren grunnfestet jorden med visdom; han bygget himmelen med forstand. 
20:3:20: Ved hans kunnskap vellet de dype vann frem, og ved den lar skyene dugg dryppe ned. 
20:3:21: Min sønn! La dem* ikke vike fra dine øine, bevar visdom og klokskap! # <* visdom og klokskap.> 
20:3:22: Så skal de være liv for din sjel og pryd for din hals. 
20:3:23: Da skal du vandre din vei trygt og ikke støte din fot. 
20:3:24: Når du legger dig, skal du ikke frykte, og når du har lagt dig, skal din søvn være søt. 
20:3:25: Da trenger du ikke å være redd for uventet skrekk, eller for uværet når det kommer over de ugudelige! 
20:3:26: For Herren skal være din tillit, og han skal bevare din fot fra å fanges. 
20:3:27: Nekt ikke de trengende din hjelp, når det står i din makt å gi den! 
20:3:28: Si ikke til din næste: Gå bort og kom igjen, jeg skal gi dig imorgen - når du kan gjøre det straks! 
20:3:29: Legg ikke op onde råd mot din næste, når han kjenner sig trygg hos dig! 
20:3:30: Trett ikke med et menneske uten årsak, når han ikke har gjort dig noget ondt! 
20:3:31: Misunn ikke en voldsmann, og velg ikke nogen av alle hans veier! 
20:3:32: For en falsk mann er en vederstyggelighet for Herren, men med de opriktige har han fortrolig samfund. 
20:3:33: Herrens forbannelse er over den ugudeliges hus, men de rettferdiges bolig velsigner han. 
20:3:34: Spotterne spotter han, men de ydmyke gir han nåde. 
20:3:35: De vise arver ære, men dårene får skam til lønn. 
20:4:1: Hør, mine barn, på en fars tilrettevisning og gi akt, så I kan lære klokskap! 
20:4:2: For en god lærdom gir jeg eder; mine bud må I ikke forlate. 
20:4:3: For da jeg var sønn hjemme hos min far, da jeg var liten og min mors eneste barn, 
20:4:4: da lærte han mig og sa til mig: La ditt hjerte holde fast ved mine ord, bevar mine bud, så skal du leve. 
20:4:5: Kjøp visdom, kjøp forstand, glem ikke og vik ikke fra min munns ord! 
20:4:6: Forlat den ikke, så skal den vokte dig; elsk den, så skal den være ditt vern. 
20:4:7: Begynnelsen til visdom er: Kjøp visdom, ja, kjøp forstand for alt ditt gods! 
20:4:8: Ophøi den, så skal den ophøie dig; den skal gjøre dig ære, når du favner den. 
20:4:9: Den skal sette en fager krans på ditt hode; den skal rekke dig en herlig krone. 
20:4:10: Hør, min sønn, og ta imot mine ord, så skal dine leveår bli mange. 
20:4:11: Om visdoms vei lærer jeg dig, jeg leder dig på rettvishets stier. 
20:4:12: Når du går, skal intet hindre dine skritt, og når du løper, skal du ikke snuble. 
20:4:13: Hold fast ved min tilrettevisning, slipp den ikke! Bevar den, for den er ditt liv. 
20:4:14: På de ugudeliges sti må du ikke komme og ikke følge de ondes vei. 
20:4:15: Sky den, følg den ikke, vik fra den og gå forbi! 
20:4:16: For de får ikke sove uten de har gjort noget ondt, og søvnen tas fra dem om de ikke har ført nogen til fall. 
20:4:17: For de eter ugudelighets brød og drikker voldsgjernings vin. 
20:4:18: Men de rettferdiges sti er lik et strålende lys, som blir klarere og klarere til det er høilys dag. 
20:4:19: De ugudeliges vei er som det dype mørke; de vet ikke hvad de snubler over. 
20:4:20: Min sønn! Akt på mine ord, bøi ditt øre til min tale! 
20:4:21: La dem ikke vike fra dine øine, bevar dem dypt i ditt hjerte! 
20:4:22: For de er liv for hver den som finner dem, og lægedom for hele hans legeme. 
20:4:23: Bevar ditt hjerte fremfor alt det som bevares; for livet utgår fra det. 
20:4:24: Hold dig fra svikefulle ord, og la falske leber være langt fra dig! 
20:4:25: La dine øine se bent frem og dine øielokk vende rett frem for dig! 
20:4:26: Gjør din fots sti jevn, og la alle dine veier være rette! 
20:4:27: Bøi ikke av til høire eller til venstre, vend din fot fra det onde! 
20:5:1: Min sønn! Akt på min visdom, bøi ditt øre til min forstand, 
20:5:2: så du kan gjemme kloke råd og dine leber bevare kunnskap! 
20:5:3: For en fremmed kvinnes leber drypper av honning, og glattere enn olje er hennes tunge; 
20:5:4: men til sist er hun besk som malurt, hvass som et tveegget sverd. 
20:5:5: Hennes føtter går nedover til døden; hennes skritt fører like til dødsriket. 
20:5:6: På livets sti vil hun ikke vandre; hennes veier går hit og dit, uten at hun vet det. 
20:5:7: Så hør nu på mig, mine barn, og vik ikke fra min munns ord! 
20:5:8: La din vei være langt fra henne, og kom ikke nær til døren på hennes hus, 
20:5:9: forat du ikke skal gi andre din pryd og en grusom herre dine år, 
20:5:10: forat ikke fremmede skal mettes av din eiendom, og frukten av din møie komme i en annen manns hus, 
20:5:11: så du må stønne i din siste stund, når din kropp og ditt kjøtt tæres bort, 
20:5:12: Og du må si: Hvorledes har jeg kunnet hate tukt, og hvorledes har mitt hjerte kunnet forakte tilrettevisning, 
20:5:13: så jeg ikke hørte på dem som lærte mig, og ikke bøide mitt øre til dem som veiledet mig! 
20:5:14: Nær var jeg kommet i den største ulykke midt i forsamlingen og menigheten. 
20:5:15: Drikk av din egen brønn, drikk rinnende vann av din egen kilde! 
20:5:16: Skulde vel dine kilder strømme ut på gaten, dine bekker på torvene? 
20:5:17: La dem være for dig alene og ikke for fremmede sammen med dig! 
20:5:18: Din kilde være velsignet, gled dig i din ungdoms hustru! 
20:5:19: Den elskelige hind og den yndefulle stengjet! Hennes barm kvege dig til enhver tid, av hennes kjærlighet være du alltid drukken! 
20:5:20: Hvorfor, min sønn, skulde du da være drukken av attrå efter en annen manns hustru og favne en fremmed kvinnes barm? 
20:5:21: For en manns veier ligger åpne for Herrens øine, og Herren jevner alle hans stier. 
20:5:22: Den ugudelige fanges i sine egne misgjerninger, og han holdes fast i sin egen synds snarer. 
20:5:23: Han må dø, fordi han ikke lot sig tukte, og for sin store dårskaps skyld tumler han og faller. 
20:6:1: Min sønn! Har du gått i borgen for din næste, har du gitt en fremmed ditt håndslag, 
20:6:2: har du latt dig binde ved din munns ord, har du latt dig fange i din munns ord, 
20:6:3: så gjør således, min sønn, og frels dig, siden du er kommet i din næstes hånd: Gå og kast dig ned for din næste og storm inn på ham, 
20:6:4: unn ikke dine øine søvn og dine øielokk blund, 
20:6:5: frels dig som et rådyr av jegerens hånd og som en fugl av fuglefangerens hånd! 
20:6:6: Gå til mauren, du late, se dens ferd og bli vis! 
20:6:7: Enda den ikke har nogen fyrste, foged eller herre, 
20:6:8: sørger den dog om sommeren for sitt livsophold og sanker om høsten sin føde. 
20:6:9: Hvor lenge vil du ligge, du late? Når vil du stå op av din søvn? 
20:6:10: [Du sier:] La mig ennu få sove litt, blunde litt, folde mine hender litt og hvile! 
20:6:11: Da kommer armoden over dig som en landstryker og nøden som en mann med skjold. 
20:6:12: En niding, en ugjerningsmann er den som går omkring med en falsk munn, 
20:6:13: som blunker med øinene, skraper med føttene, gjør tegn med fingrene, 
20:6:14: som har svik i sitt hjerte, som tenker ut onde ting til enhver tid og volder tretter. 
20:6:15: Derfor skal ulykken komme brått over ham; i et øieblikk skal han knuses, og det er ingen lægedom for ham. 
20:6:16: Seks ting er det Herren hater, og syv er vederstyggeligheter for hans sjel: 
20:6:17: Stolte øine, falsk tunge og hender som utøser uskyldig blod, 
20:6:18: et hjerte som legger op onde råd, føtter som haster til det onde, 
20:6:19: den som taler løgn og vidner falsk, og den som volder tretter mellem brødre. 
20:6:20: Bevar, min sønn, din fars bud og forlat ikke din mors lære! 
20:6:21: Bind dem alltid til ditt hjerte, knytt dem fast om din hals! 
20:6:22: Når du går, skal de lede dig; når du ligger, skal de verne dig, og når du våkner, skal de tale til dig. 
20:6:23: For budet er en lykte og læren et lys, og tilrettevisninger til tukt er en vei til livet, 
20:6:24: så de bevarer dig fra en ond kvinne, fra en fremmed kvinnes glatte tunge. 
20:6:25: Attrå ikke hennes skjønnhet i ditt hjerte? og la henne ikke fange dig med sine øiekast! 
20:6:26: For en skjøge armer en mann ut like til siste brødleiv, og annen manns hustru fanger en dyr sjel. 
20:6:27: Kan nogen hente ild i sitt fang uten hans klær brennes op? 
20:6:28: Eller kan nogen gå på glør uten hans føtter blir svidd? 
20:6:29: Slik blir det med den som går inn til sin næstes hustru; ingen blir ustraffet som rører henne. 
20:6:30: Blir ikke tyven foraktet, når han stjeler for å stille sin sult? 
20:6:31: Og hvis han blir grepet, må han betale syvfold; alt det han eier i sitt hus, må han gi. 
20:6:32: Den som driver hor med en kvinne, er uten forstand; den som vil ødelegge sin sjel, han gjør slikt. 
20:6:33: Hugg og skam får han, og hans vanære slettes aldri ut. 
20:6:34: For nidkjær er mannens vrede, og han sparer ikke på hevnens dag; 
20:6:35: han tar ikke imot bøter og lar sig ikke formilde, om du gir ham store gaver. 
20:7:1: Min sønn, bevar mine ord og gjem mine bud hos dig! 
20:7:2: Bevar mine bud, så skal du leve, bevar min lære som din øiesten! 
20:7:3: Bind dem på dine fingrer, skriv dem på ditt hjertes tavle! 
20:7:4: Si til visdommen: Du er min søster, og kall forstanden din kjenning, 
20:7:5: forat den må bevare dig fra annen manns hustru, fra fremmede kvinner, som taler glatte ord. 
20:7:6: For jeg så engang ut av vinduet på mitt hus, gjennem mitt gitter, 
20:7:7: og da så jeg blandt de uerfarne, jeg blev var blandt de unge en uforstandig gutt, 
20:7:8: som gikk forbi på gaten ved hennes hjørne og gikk frem på veien til hennes hus, 
20:7:9: i skumringen, da dagen var til ende, i dyp natt og mørke. 
20:7:10: Da kom en kvinne ham i møte i en skjøges klær og med svikefullt hjerte. 
20:7:11: Vill er hun og kåt; hennes føtter er ikke i ro i hennes hus. 
20:7:12: Snart er hun på gater, snart på torver, og ved hvert hjørne lurer hun. 
20:7:13: Og hun tok fatt på ham og kysset ham, og med frekt åsyn sa hun til ham: 
20:7:14: Takkoffer skulde jeg bære frem, og idag har jeg innfridd mine løfter; 
20:7:15: derfor gikk jeg ut for å møte dig, for å søke dig op, og jeg fant dig. 
20:7:16: Jeg har bredt tepper over min seng, brokete tepper av egyptisk garn. 
20:7:17: Jeg har strødd mitt leie med myrra, aloë og kanel. 
20:7:18: Kom, la oss beruse oss i kjærlighet inntil morgenen, fryde oss i elskov! 
20:7:19: For min mann er ikke hjemme, han er reist langt bort; 
20:7:20: pengepungen tok han med sig, først ved fullmåne kommer han hjem. 
20:7:21: Hun fikk lokket ham ved sin sterke overtalelse, ved sine glatte leber forførte hun ham. 
20:7:22: Han følger henne straks, lik en okse som går til slakterbenken, som i fotjern, hvormed dåren tuktes, 
20:7:23: inntil pilen kløver hans lever - likesom fuglen haster til snaren og ikke vet at det gjelder dens liv. 
20:7:24: Så hør nu på mig, barn, og akt på min munns ord! 
20:7:25: La ikke ditt hjerte vende sig til hennes veier, forvill dig ikke inn på hennes stier! 
20:7:26: For mange som har fått ulivssår, har hun fellet, og mangfoldige er de hun har slått ihjel; 
20:7:27: fra hennes hus går veier til dødsriket, de fører ned til dødens kammere. 
20:8:1: Hør, visdommen roper, og forstanden lar sin røst høre. 
20:8:2: Oppe på hauger ved veien står hun, der hvor stiene møtes; 
20:8:3: ved siden av portene, ved byens utgang, i inngangen til portene roper hun høit: 
20:8:4: Til eder, I menn, roper jeg, og min røst lyder til menneskenes barn. 
20:8:5: Lær klokskap, I enfoldige, og lær forstand, I uforstandige! 
20:8:6: Hør! Om store ting taler jeg, og jeg oplater mine leber med rettvishet; 
20:8:7: min tunge taler sannhet, og ugudelighet er en vederstyggelighet for mine leber. 
20:8:8: Alle min munns ord er rette, det er intet falskt eller vrangt i dem. 
20:8:9: De er alle sammen likefremme for den forstandige og rette for dem som har funnet kunnskap. 
20:8:10: Ta imot min tilrettevisning istedenfor sølv, og ta imot kunnskap fremfor utsøkt gull! 
20:8:11: For visdom er bedre enn perler, og ingen skatt kan lignes med den. 
20:8:12: Jeg, visdommen, har klokskap i eie, og jeg forstår å finne kloke råd. 
20:8:13: Å frykte Herren er å hate ondt; stolthet og overmot, ond ferd og en falsk munn hater jeg. 
20:8:14: Mig tilhører råd og sann innsikt, jeg er forstand, mig hører styrke til. 
20:8:15: Ved mig regjerer kongene, og ved mig fastsetter fyrstene hvad rett er. 
20:8:16: Ved mig styrer herskerne og høvdingene, alle dommere på jorden. 
20:8:17: Jeg elsker dem som elsker mig, og de som søker mig, skal finne mig. 
20:8:18: Hos mig er rikdom og ære, gammelt arvegods og rettferdighet. 
20:8:19: Min frukt er bedre enn gull, ja det fineste gull, og den vinning jeg gir, er bedre enn utsøkt sølv. 
20:8:20: På rettferds vei vandrer jeg, midt på rettens stier; 
20:8:21: derfor gir jeg dem som elsker mig, sann rikdom til arv og fyller deres forrådskammere. 
20:8:22: Herren skapte mig som sitt første verk, før sine andre gjerninger, i fordums tid. 
20:8:23: Fra evighet er jeg blitt til, fra først av, før jorden var. 
20:8:24: Da avgrunnene ennu ikke var til, blev jeg født, da der ennu ikke fantes kilder fylt med vann. 
20:8:25: Før fjellene blev senket ned, før haugene blev til, blev jeg født, 
20:8:26: før han hadde skapt jord og mark og jorderikes første muldklump. 
20:8:27: Da han bygget himmelen, var jeg der, da han slo hvelving over avgrunnen. 
20:8:28: Da han festet skyene oventil, da han bandt avgrunnens kilder, 
20:8:29: da han satte grense for havet, så vannene ikke går lenger enn han byder, da han la jordens grunnvoller - 
20:8:30: da var jeg verksmester hos ham, og jeg var hans lyst dag efter dag, jeg lekte alltid for hans åsyn; 
20:8:31: jeg lekte på hele hans vide jord, og min lyst hadde jeg i menneskenes barn. 
20:8:32: Og nu, barn, hør på mig! Salige er de som følger mine veier. 
20:8:33: Hør på min tilrettevisning og bli vise og forakt den ikke! 
20:8:34: Salig er det menneske som hører på mig, så han våker ved mine dører dag efter dag og vokter mine dørstolper. 
20:8:35: For den som finner mig, finner livet og får nåde hos Herren. 
20:8:36: Men den som ikke finner mig, skader sig selv; alle de som hater mig, elsker døden. 
20:9:1: Visdommen har bygget sitt hus, hun har hugget til sine syv stolper. 
20:9:2: Hun har slaktet sitt slaktefe, blandet sin vin og dekket sitt bord; 
20:9:3: hun har sendt ut sine piker, hun roper oppe fra byens høider: 
20:9:4: Den som er enfoldig, han vende sig hit! Til den som er uten forstand, sier hun: 
20:9:5: Kom, et av mitt brød og drikk av den vin jeg har blandet! 
20:9:6: Opgi eders uforstand, så skal I leve, og gå bent frem på forstandens vei! 
20:9:7: Den som refser en spotter, henter sig selv vanære, og den som viser en ugudelig til rette, får skam av det. 
20:9:8: Vis ikke spotteren til rette, forat han ikke skal hate dig! Vis den vise til rette, så skal han elske dig. 
20:9:9: Lær den vise, så blir han ennu visere, lær den rettferdige, så går han frem i lærdom. 
20:9:10: Å frykte Herren er begynnelsen til visdom, og å kjenne den Hellige er forstand. 
20:9:11: For ved mig skal dine dager bli mange, og leveår skal gis dig i rikt mål. 
20:9:12: Er du vis, så er du vis til ditt eget gagn, og er du en spotter, skal du alene lide for det. 
20:9:13: Dårskapen er en kåt kvinne, bare uforstand og uvitenhet. 
20:9:14: Hun sitter foran døren til sitt hus på en trone på en høide i byen 
20:9:15: for å rope til dem som går forbi på veien, som vandrer bent frem på sine stier: 
20:9:16: Den som er enfoldig, han vende sig hit! Til den som er uten forstand, sier hun: 
20:9:17: Stjålet vann er søtt, og brød som etes i lønndom, smaker herlig. 
20:9:18: Men han vet ikke at der bor dødningene, at hennes gjester er i dødsrikets dyp. 
20:10:1: Salomos ordsprog. # En vis sønn gleder sin far, men en uforstandig sønn er sin mors sorg. 
20:10:2: Ugudelighets skatter gagner ikke, men rettferdighet frir fra døden. 
20:10:3: Herren lar ikke den rettferdige sulte, men de ugudeliges attrå støter han bort. 
20:10:4: Den som arbeider med lat hånd, blir fattig, men den flittiges hånd gjør rik. 
20:10:5: En klok sønn samler om sommeren; en dårlig sønn sover i høsttiden. 
20:10:6: Velsignelser kommer over den rettferdiges hode, men de ugudeliges munn skal deres vold skjule. 
20:10:7: Den rettferdiges minne lever i velsignelse, men de ugudeliges navn råtner bort. 
20:10:8: Den som har visdom i hjertet, tar imot [Guds] bud; men den som har dårens leber, går til grunne. 
20:10:9: Den som vandrer i ustraffelighet, vandrer trygt, og den som går krokveier, blir opdaget. 
20:10:10: Den som blunker med øiet, volder smerte, og den som har dårens leber, går til grunne. 
20:10:11: Den rettferdiges munn er en livsens kilde, men de ugudeliges munn skal deres vold skjule. 
20:10:12: Hat vekker trette, men kjærlighet dekker over alle overtredelser. 
20:10:13: På den forstandiges leber finnes visdom, men stokken er for den uforstandiges rygg. 
20:10:14: De vise gjemmer på kunnskap, men dårens munn truer med ødeleggelse. 
20:10:15: Rikmanns gods er hans faste stad; de fattiges armod er deres ødeleggelse. 
20:10:16: Det som den rettferdige vinner, blir ham til liv; den ugudeliges inntekt blir ham til synd. 
20:10:17: En vei til liv* er den som akter på tukt; men den som forakter tilrettevisning, fører vill. # <* d.e. en veileder til lykke og velsignelse.> 
20:10:18: Den som skjuler hat, har falske leber, og den som fører ut ondt rykte, han er en dåre. 
20:10:19: Hvor det er mange ord, mangler det ikke på synd; men den som holder sine leber i tømme, er klok. 
20:10:20: Den rettferdiges tunge er som utsøkt sølv; de ugudeliges hjerte er intet verdt. 
20:10:21: Den rettferdiges leber nærer mange, men dårer dør, fordi de er uten forstand. 
20:10:22: Det er Herrens velsignelse som gjør rik, og eget strev legger ikke noget til. 
20:10:23: For dåren er det en lyst å gjøre skamløse gjerninger, men visdom er en lyst for den forstandige mann. 
20:10:24: Det som den ugudelige gruer for, det skal komme over ham; men de rettferdiges ønsker skal Gud opfylle. 
20:10:25: Når en storm farer forbi, så er den ugudelige ikke mere; men den rettferdige har en evig grunnvoll. 
20:10:26: Som eddik for tennene og røk for øinene, slik er den late for den som sender ham. 
20:10:27: Herrens frykt forlenger livet, men de ugudeliges år forkortes. 
20:10:28: De rettferdige har glede i vente, men de ugudeliges håp blir til intet. 
20:10:29: Herrens vei er en fast borg for den ustraffelige, men den er ødeleggelse for dem som gjør urett. 
20:10:30: De rettferdige skal aldri rokkes, men de ugudelige skal ikke få bo landet. 
20:10:31: Den rettferdiges munn bærer visdoms frukt, men den falske tunge skal skjæres av. 
20:10:32: Den rettferdiges leber forstår hvad der er til behag, men de ugudeliges munn er bare falskhet. 
20:11:1: Falsk vekt er en vederstyggelighet for Herren, men fullvektige lodder er ham til velbehag. 
20:11:2: Med overmot følger skam, men de ydmyke har visdom. 
20:11:3: De opriktiges rettsinn leder dem, men de troløses forvendte sinn ødelegger dem. 
20:11:4: Gods hjelper ikke på vredens dag, men rettferdighet frir fra døden. 
20:11:5: Den ustraffeliges rettferdighet gjør hans vei jevn, men den ugudelige faller ved sin ugudelighet. 
20:11:6: De opriktiges rettferdighet frelser, men de troløse fanges i sin egen ondskap. 
20:11:7: Når et ugudelig menneske dør, blir hans håp til intet, og med det som de onde stunder efter, er det forbi. 
20:11:8: Den rettferdige utfries av trengsel, og den ugudelige kommer i hans sted. 
20:11:9: Med munnen ødelegger den gudløse sin næste, men ved sin kunnskap utfries de rettferdige. 
20:11:10: Når det går de rettferdige godt, jubler byen, og når de ugudelige omkommer, lyder fryderop. 
20:11:11: Ved de opriktiges velsignelse blir en by ophøiet, men de ugudeliges munn bryter den ned. 
20:11:12: Den som taler foraktelig om sin næste, er uten forstand; men en forstandig mann tier. 
20:11:13: Den som går omkring som baktaler, åpenbarer hemmeligheter; men den som er trofast i ånden, skjuler saken. 
20:11:14: Hvor det intet styre er, faller folket; men hvor det er mange rådgivere, der er frelse. 
20:11:15: Ille går det den som går i borgen for en fremmed; men den som skyr å gi håndslag, er sikker. 
20:11:16: En yndig kvinne vinner ære, og voldsmenn vinner rikdom. 
20:11:17: En godgjørende mann gjør vel mot sig selv, men en hårdhjertet mann ødelegger sig. 
20:11:18: Den ugudelige vinner en lønn som svikter, men den som sår rettferdighet, får en lønn som varer. 
20:11:19: Den som står fast i rettferdighet, ham blir det til liv; men den som jager efter ondt, volder sin egen død. 
20:11:20: En vederstyggelighet for Herren er de hvis hjerte er forvendt; men til velbehag for ham er de hvis vei er ustraffelig. 
20:11:21: Visselig, den onde blir ikke ustraffet, men de rettferdiges ætt slipper unda. 
20:11:22: Som en gullring i et svinetryne er en fager kvinne som er uten forstand. 
20:11:23: De rettferdiges attrå er bare det som godt er; de ugudelige har vrede i vente. 
20:11:24: Den ene strør ut og får ennu mere; den andre holder tilbake mere enn rett er, og det blir dog bare fattigdom. 
20:11:25: Den som velsigner, skal trives, og den som lesker andre, han blir selv lesket. 
20:11:26: Den som holder korn tilbake, ham banner folket; men velsignelse kommer over dens hode som selger korn. 
20:11:27: Den som søker hvad godt er, søker det som er til behag; men den som higer efter ondt, over ham kommer det onde. 
20:11:28: Den som setter sin lit til sin rikdom, han skal falle; men de rettferdige skal grønnes som løvet. 
20:11:29: Den som setter sitt hus i ulag, skal arve vind, og dåren blir en tjener for den vise. 
20:11:30: Den rettferdiges frukt er et livsens tre, og den vise fanger sjeler. 
20:11:31: Se, den rettferdige får sin lønn på jorden, hvor meget mere da den ugudelige og synderen! 
20:12:1: Den som elsker tukt, elsker kunnskap; men den som hater refselse, er dum. 
20:12:2: Den gode får nåde hos Herren, men den svikefulle mann fordømmer han. 
20:12:3: Ugudelighet hjelper intet menneske til å stå støtt, men de rettferdiges rot rokkes ikke. 
20:12:4: En god hustru er sin manns krone, men en dårlig er som råttenhet i hans ben. 
20:12:5: De rettferdige tenker bare på det som rett er; de ugudeliges råd er svik. 
20:12:6: De ugudelige taler alltid om å lure efter blod, men de opriktiges munn frelser dem. 
20:12:7: De ugudelige kastes over ende, og så er de ikke mere; men de rettferdiges hus står fast. 
20:12:8: En mann roses alt efter som han har forstand, men den hvis hjerte er forvendt, blir til forakt. 
20:12:9: Bedre er en småkårsmann som har en tjener, enn en som vil være storkar, men ikke har brød. 
20:12:10: Den rettferdige har omsorg for sin buskap, men den ugudeliges hjerte er hårdt. 
20:12:11: Den som dyrker sin jord, mettes med brød; men den som jager efter tomme ting, er uten forstand. 
20:12:12: Den ugudelige attrår det som er en snare for de onde; men de rettferdige gir Gud fast rot. 
20:12:13: I lebenes synd ligger en ond snare, men den rettferdige kommer ut av trengsel. 
20:12:14: Av sin munns frukt mettes en mann med godt, og hvad et menneskes hender har gjort, det gjengjeldes ham. 
20:12:15: Dårens vei er rett i hans egne øine, men den som hører på råd, er vis. 
20:12:16: Dårens vrede blir kjent samme dag, men den som skjuler krenkelser, er klok. 
20:12:17: Den som er ærlig i sine ord, taler sannhet, men et falskt vidne taler svik. 
20:12:18: Mange taler tankeløse ord, som stikker likesom sverd; men de vises tunge er lægedom. 
20:12:19: Sannhets lebe blir fast for all tid, men falskhets tunge bare et øieblikk. 
20:12:20: Det er svik i deres hjerte som smir ondt; men de som råder til fred, får glede. 
20:12:21: Det rammer ikke den rettferdige noget ondt, men de ugudelige får ulykke i fullt mål. 
20:12:22: Falske leber er en vederstyggelighet for Herren, men de som går frem med ærlighet, er ham til velbehag. 
20:12:23: Et klokt menneske skjuler det han vet, men dårers hjerte roper ut sin dårskap. 
20:12:24: Den flittiges hånd kommer til å styre, men lathet blir træl. 
20:12:25: Sorg i en manns hjerte trykker det ned, men et godt ord gleder det. 
20:12:26: Den rettferdige veileder sin næste, men de ugudeliges vei fører dem vill. 
20:12:27: Lathet steker ikke sin fangst, men flid er en kostelig skatt for et menneske. 
20:12:28: På rettferds sti er liv, og en ryddet vei fører ikke til døden. 
20:13:1: En vis sønn hører på sin fars tilrettevisning, men en spotter hører ikke på irettesettelse. 
20:13:2: Av sin munns frukt nyter en mann godt, men de troløses hu står til vold. 
20:13:3: Den som vokter sin munn, bevarer sitt liv; den som lukker sine leber vidt op, ham blir det til ulykke. 
20:13:4: Den late attrår og får intet, men de flittige næres rikelig. 
20:13:5: Den rettferdige hater løgnaktige ord, men den ugudelige gjør det som ondt og skammelig er. 
20:13:6: Rettferdighet verner den som lever ustraffelig, men ugudelighet feller den som gjør synd. 
20:13:7: Den ene ter sig som en rik mann og har dog slett ingen ting, og den andre ter sig som en fattig mann og har dog meget gods. 
20:13:8: En manns rikdom er løsepenger for hans liv, men den fattige er det ingen som truer. 
20:13:9: De rettferdiges schmelk skinner lystig, men de ugudeliges lampe slukner. 
20:13:10: Ved overmot voldes bare trette, men hos dem som lar sig råde, er visdom. 
20:13:11: Lett vunnet rikdom minker, men den som samler litt efter litt, øker sitt gods. 
20:13:12: Langvarig venting gjør hjertet sykt, men et opfylt ønske er et livsens tre. 
20:13:13: Den som forakter ordet, ødelegger sig selv; men den som frykter budet, han får lønn. 
20:13:14: Den vises lære er en livsens kilde, ved den slipper en fra dødens snarer. 
20:13:15: Ved god forstand vinner en menneskenes yndest, men de troløses vei er hård. 
20:13:16: Hver den som er klok, går frem med forstand, men en dåre utbreder dårskap. 
20:13:17: Et ugudelig sendebud faller i ulykke, men et trofast bud er lægedom. 
20:13:18: Armod og skam får den som ikke vil vite av tukt; men den som akter på refselse, blir æret. 
20:13:19: Opfylt ønske er søtt for sjelen, men å holde sig fra det onde er en vederstyggelighet for dårer. 
20:13:20: Søk omgang med de vise, og du skal bli vis; men dårers venn går det ille. 
20:13:21: Ulykke forfølger syndere, men rettferdige lønnes med godt. 
20:13:22: Den gode efterlater arv til barnebarn, men synderens gods er gjemt til den rettferdige. 
20:13:23: De fattiges nyland gir meget føde; men mangen rykkes bort fordi han ikke gjør det som rett er. 
20:13:24: Den som sparer sitt ris, hater sin sønn; men den som elsker ham, tukter ham tidlig. 
20:13:25: Den rettferdige eter så han blir mett, men de ugudeliges buk blir tom. 
20:14:1: Kvinners visdom bygger sitt hus, men dårskap river det ned med sine hender. 
20:14:2: Den som vandrer i opriktighet, frykter Herren; men den som går krokveier, forakter ham. 
20:14:3: I dårens munn er et ris for hans overmot, men de vises leber er deres vern. 
20:14:4: Hvor det ingen okser er, der er krybben tom; men rikelig vinning kommer ved oksens kraft. 
20:14:5: Trofast vidne lyver ikke, men den som taler løgn, er et falskt vidne. 
20:14:6: Spotteren søker visdom, men finner den ikke; men for den forstandige er kunnskap lett å vinne. 
20:14:7: Når du går fra en dåre, har du ikke funnet forstand på hans leber. 
20:14:8: Den klokes visdom er at han forstår sin vei, men dårers dårskap er at de bedrar sig selv. 
20:14:9: Dårer spottes av sitt eget skyldoffer, men blandt de opriktige råder Guds velbehag. 
20:14:10: Hjertet kjenner sin egen bitre smerte, og i dets glede blander ingen fremmed sig. 
20:14:11: De ugudeliges hus skal ødelegges, men de opriktiges telt skal blomstre. 
20:14:12: Mangen vei tykkes en mann rett, men enden på det er dødens veier. 
20:14:13: Endog under latter har hjertet smerte, og enden på gleden er sorg. 
20:14:14: Av sin ferd skal den frafalne mettes, og en god mann holder sig borte fra ham. 
20:14:15: Den enfoldige tror hvert ord, men den kloke akter på sine skritt. 
20:14:16: Den vise frykter og holder sig fra det onde, men dåren er overmodig og trygg. 
20:14:17: Den bråsinte gjør dårskap, og en svikefull mann blir hatet. 
20:14:18: De enfoldige har fått dårskap i arv, men de kloke krones med kunnskap. 
20:14:19: De onde må bøie sig for de gode, og de ugudelige ved den rettferdiges porter. 
20:14:20: Endog av sin venn blir den fattige hatet; men de som elsker en rik, er mange. 
20:14:21: Den som forakter sin næste, synder; men salig er den som ynkes over arminger. 
20:14:22: Skal ikke de fare vill som tenker ut det som ondt er? Men miskunnhet og trofasthet times dem som optenker godt. 
20:14:23: Ethvert møiefullt arbeid gir vinning, men tomt snakk fører bare til tap. 
20:14:24: De vises rikdom er deres krone, men dårenes dårskap er og blir dårskap. 
20:14:25: Et sanndru vidne frelser liv, men den som taler løgn, er full av svik. 
20:14:26: Den som frykter Herren, har et sterkt vern, og for hans barn skal Herren være en tilflukt. 
20:14:27: Å frykte Herren er en livsens kilde, så en slipper fra dødens snarer. 
20:14:28: Meget folk er kongens ære, men mangel på folk er fyrstens fall. 
20:14:29: Den langmodige har stor forstand, men den bråsinte viser stor dårskap. 
20:14:30: Et saktmodig hjerte er legemets liv, men hissighet er råttenhet i benene. 
20:14:31: Den som trykker en arming, håner hans skaper, men den som har medynk med den fattige, ærer skaperen. 
20:14:32: Når ulykken rammer den ugudelige, kastes han over ende; men den rettferdige er frimodig i døden. 
20:14:33: I den forstandiges hjerte holder visdommen sig stille, men i dårers indre gir den* sig til kjenne. # <* den visdom de har, d.e. deres dårskap.> 
20:14:34: Rettferdighet ophøier et folk, men synden er folkenes vanære. 
20:14:35: En klok tjener vinner kongens yndest, men over en dårlig tjener kommer hans vrede. 
20:15:1: Mildt svar stiller harme, men et sårende ord vekker vrede. 
20:15:2: De vises tunge gir god kunnskap, men dårenes munn lar dårskap strømme ut. 
20:15:3: Herrens øine er allesteds, de ser både efter onde og efter gode. 
20:15:4: En saktmodig tunge er et livsens tre, men en falsk tunge sårer hjertet. 
20:15:5: Dåren forakter sin fars tukt, men den som akter på tilrettevisning, er klok. 
20:15:6: I den rettferdiges hus er det meget gods, men den ugudeliges inntekt blir til ødeleggelse for ham. 
20:15:7: De vises leber strør ut kunnskap, men dårenes sinn er ikke rett. 
20:15:8: De ugudeliges offer er en vederstyggelighet for Herren, men de opriktiges bønn er ham til velbehag. 
20:15:9: Den ugudeliges vei er en vederstyggelighet for Herren, men den som jager efter rettferdighet, elsker han. 
20:15:10: Hård straff rammer den som forlater den rette sti; den som hater tilrettevisning, skal dø. 
20:15:11: Dødsriket og avgrunnen ligger åpne for Herren, hvor meget mere da menneskenes hjerter! 
20:15:12: En spotter liker ikke å bli irettesatt; til de vise går han ikke. 
20:15:13: Et glad hjerte gjør åsynet lyst, men hjertesorg bryter motet ned. 
20:15:14: Den forstandiges hjerte søker kunnskap, men dårers munn farer bare med dårskap. 
20:15:15: Alle den ulykkeliges dager er onde, men et glad hjerte er et stadig gjestebud. 
20:15:16: Bedre er lite med Herrens frykt enn en stor skatt med uro. 
20:15:17: Bedre er en rett grønt med kjærlighet enn en fet okse med hat. 
20:15:18: En hissig mann vekker trette men den langmodige stiller kiv. 
20:15:19: Den lates vei er som en tornehekk, men de opriktiges vei er ryddet. 
20:15:20: En vis sønn gleder sin far, men et uforstandig menneske forakter sin mor. 
20:15:21: Dårskap er en glede for den som er uten forstand; men en forstandig mann går rett frem. 
20:15:22: Planer blir til intet uten rådslagning; men hvor det er mange rådgivere, har de fremgang. 
20:15:23: En mann gleder sig når hans munn kan gi svar, og hvor godt er ikke et ord i rette tid! 
20:15:24: Den forstandige går livets vei opover for å undgå dødsriket der nede. 
20:15:25: Herren river ned de overmodiges hus, men enkens markskjell lar han stå fast. 
20:15:26: Den ondes råd er en vederstyggelighet for Herren, men milde ord er rene for ham. 
20:15:27: Den som jager efter vinning, setter sitt hus i ulag, men den som hater gaver*, skal leve. # <* d.e. bestikkelse.> 
20:15:28: Den rettferdige tenker i sitt hjerte på hvorledes han skal svare, men de ugudeliges munn lar onde ting strømme ut. 
20:15:29: Herren er langt borte fra de ugudelige, men de rettferdiges bønn hører han. 
20:15:30: Øinenes schmelk* gleder hjertet; godt budskap gir benene marg. # <* d.e. et vennlig blikk.> 
20:15:31: Den hvis øre hører på tilrettevisning til livet, dveler gjerne blandt vise. 
20:15:32: Den som ikke vil vite av tukt, forakter sitt liv, men den som hører på tilrettevisning, vinner forstand. 
20:15:33: Herrens frykt er tukt til visdom, og ydmykhet går forut for ære. 
20:16:1: Hjertets råd hører mennesket til, men fra Herren får tungen sitt svar. 
20:16:2: Alle en manns veier er rene i hans egne øine, men Herren veier åndene. 
20:16:3: Legg dine gjerninger på Herren, så skal dine råd ha fremgang. 
20:16:4: Herren har gjort hver ting til dens øiemed, også den ugudelige til straffens dag. 
20:16:5: Enhver overmodig er en vederstyggelighet for Herren; visselig, en slik mann blir ikke ustraffet. 
20:16:6: Ved kjærlighet og trofasthet utsones misgjerning, og den som frykter Herren, holder sig fra det onde. 
20:16:7: Når Herren har behag i en manns ferd, da gjør han at endog hans fiender holder fred med ham. 
20:16:8: Bedre er lite med rettferdighet enn stor vinning med urett. 
20:16:9: Menneskets hjerte tenker ut sin vei, men Herren styrer hans gang. 
20:16:10: Guddoms-ord er på kongens leber; hans munn skal ikke forsynde sig når han dømmer. 
20:16:11: Rett vekt og rette vektskåler hører Herren til; alle vektstener i pungen er hans verk. 
20:16:12: Ugudelige gjerninger er en vederstyggelighet for konger; for ved rettferdighet blir tronen trygget. 
20:16:13: Rettferdige leber er til velbehag for konger, og den som taler det som rett er, elsker de. 
20:16:14: En konges vrede er dødens bud, men en vis mann stiller vreden. 
20:16:15: I schmelken fra kongens åsyn er det liv, og hans nåde er som en sky med vårregn. 
20:16:16: Å vinne visdom - hvor meget bedre er det ikke enn gull! Og å vinne forstand er mere verdt enn sølv. 
20:16:17: De opriktiges vei er å holde sig fra det onde; den som akter på sin vei, bevarer sitt liv. 
20:16:18: Forut for undergang går overmot, og forut for fall stolt mot. 
20:16:19: Det er bedre å være ydmyk sammen med dem som er i nød, enn å dele bytte med de overmodige. 
20:16:20: Den som akter på ordet, skal finne lykke, og den som setter sin lit til Herren, er salig. 
20:16:21: Den som er vis i hjertet, blir kalt forstandig, og lebers sødme fremmer lærdom. 
20:16:22: Klokskap er en livsens kilde for dem som eier den, men dårers straff er deres egen dårskap. 
20:16:23: Den vises hjerte gjør hans munn forstandig og legger mere og mere lærdom på hans leber. 
20:16:24: Milde ord er kostelig honning, søt for sjelen og en lægedom for kroppen. 
20:16:25: Mangen vei tykkes en mann rett, men enden på det er dødens veier. 
20:16:26: Arbeiderens sult arbeider for ham; for hans munn driver ham frem. 
20:16:27: En niding graver en ulykkesgrav, og på hans leber er det likesom en fortærende ild. 
20:16:28: En falsk mann volder trette, og en øretuter skiller venn fra venn. 
20:16:29: En voldsmann forlokker sin næste og fører ham inn på en vei som ikke er god. 
20:16:30: Den som lukker sine øine for å tenke på svik, og den som kniper sine leber sammen, han har allerede fullført det onde. 
20:16:31: Grå hår er en fager krone; den finnes på rettferdighets vei. 
20:16:32: Den langmodige er bedre enn en veldig helt, og den som styrer sitt sinn, er bedre enn den som inntar en by. 
20:16:33: I kappens fold rystes loddet, men avgjørelsen kommer alltid fra Herren. 
20:17:1: Bedre et stykke tørt brød med ro og fred enn et hus fullt av slakt med trette. 
20:17:2: En klok tjener får råde over en dårlig sønn, og iblandt brødrene får han del i arven. 
20:17:3: Der er digel for sølv og ovn for gull; men den som prøver hjertene, er Herren. 
20:17:4: Den onde akter på ondskaps lebe; løgneren lytter til ødeleggelses tunge. 
20:17:5: Den som spotter den fattige, håner hans skaper; den som gleder sig over ulykke, skal ikke bli ustraffet. 
20:17:6: De gamles krone er barnebarn, og barns pryd er deres fedre. 
20:17:7: Det sømmer sig ikke for dåren å tale store ord, enn mindre for den høibårne å tale løgn. 
20:17:8: Gave er en edelsten i dens øine som får den; hvor den kommer, gjør den lykke. 
20:17:9: Den som dekker over overtredelse, søker kjærlighet; men den som ripper op en sak, skiller venn fra venn. 
20:17:10: Skjenn virker bedre på den forstandige enn hundre slag på dåren. 
20:17:11: En ond manns hu står bare til gjenstridighet, og en ubarmhjertig engel sendes imot ham. 
20:17:12: Bedre for en mann å møte en bjørn som ungene er tatt fra, enn en dåre i hans dårskap. 
20:17:13: Den som gjengjelder godt med ondt, fra hans hus skal ulykken ikke vike. 
20:17:14: Å begynne trette er som å åpne for vann; la da tretten fare, før den blir for voldsom! 
20:17:15: Den som frikjenner en ugudelig, og den som domfeller en rettferdig, de er begge to en vederstyggelighet for Herren. 
20:17:16: Hvad hjelper penger i dårens hånd til å kjøpe visdom, siden han er uten forstand? 
20:17:17: En venn elsker alltid, og en bror fødes til hjelp i nød. 
20:17:18: Et menneske som ikke har forstand, gir håndslag og går i borgen hos sin næste. 
20:17:19: Den som elsker trette, elsker synd; den som gjør sin dør høi, søker sin egen undergang. 
20:17:20: Den som er falsk i hjertet, finner intet godt, og den som er vrang i sin tale, faller i ulykke. 
20:17:21: Den som har en narr til sønn, får sorg av ham; en dåres far har ingen glede. 
20:17:22: Et glad hjerte gir god lægedom, men et nedslått mot tar margen fra benene. 
20:17:23: Den ugudelige tar gaver ut av barmen for å bøie rettens gang. 
20:17:24: Den forstandige har visdommen for øie, men dårens øine er ved jordens ende. 
20:17:25: En uforstandig sønn er en gremmelse for sin far og en bitter sorg for henne som fødte ham. 
20:17:26: Å straffe også den rettferdige er ikke godt; å slå edle menn er tvert imot all rett. 
20:17:27: Den som er sparsom med sine ord, er klok, og den koldsindige er en forstandig mann. 
20:17:28: Også dåren aktes for klok når han tier, for vis når han holder sine leber lukket. 
20:18:1: Den egensindige følger bare sin egen lyst; mot alle kloke råd viser han tenner. 
20:18:2: Dåren bryr sig ikke om å være forstandig, men vil bare vise hvad han tenker i sitt hjerte. 
20:18:3: Når den ugudelige kommer, kommer også forakt, og med skammen følger spott. 
20:18:4: Ordene i en manns munn er dype vann, visdoms kilde er en fremvellende bekk. 
20:18:5: Det er ille å gi den skyldige medhold, å bøie retten for den rettferdige. 
20:18:6: Dårens leber volder trette, og hans munn roper efter pryl. 
20:18:7: Dårens munn er til ulykke for ham selv, og hans leber er en snare for hans liv. 
20:18:8: En øretuters ord er som velsmakende retter, og de trenger ned i hjertets indre. 
20:18:9: Den som er lat i sin gjerning, er også en bror til ødeleggeren. 
20:18:10: Herrens navn er et fast tårn; til det løper den rettferdige og blir berget. 
20:18:11: Den rikes gods er hans festning og som en høi mur i hans egen tanke. 
20:18:12: Forut for fall ophøier en manns hjerte sig, men ydmykhet går forut for ære. 
20:18:13: Når en svarer før han hører, da blir det til dårskap og skam for ham. 
20:18:14: En manns mot kan holde ham oppe i hans sykdom; men et nedslått mot - hvem kan bære det? 
20:18:15: Den forstandiges hjerte kjøper kunnskap, og de vises øre søker kunnskap. 
20:18:16: Et menneskes gave gir ham rum og fører ham frem for store herrer. 
20:18:17: Den som taler først i en rettsstrid, synes å ha rett; men så kommer motparten og gransker hans ord. 
20:18:18: Loddet gjør ende på tretter og skiller mellem de mektige. 
20:18:19: En bror som en har gjort urett mot, er vanskeligere å vinne enn en festning, og trette med ham er som en bom for en borg. 
20:18:20: Ved frukten av en manns munn mettes hans buk; med sine lebers grøde blir han mettet. 
20:18:21: Død og liv er i tungens vold, og hver den som gjerne bruker den, skal ete dens frukt. 
20:18:22: Den som har funnet en hustru, har funnet lykke og fått en nådegave av Herren. 
20:18:23: I ydmyke bønner taler den fattige, men den rike svarer med hårde ord. 
20:18:24: En mann med mange venner går det ille; men der er venner som henger fastere ved en enn en bror. 
20:19:1: Bedre er en fattig som vandrer i ustraffelighet, enn en mann med falske leber, som tillike er en dåre. 
20:19:2: Også den som ikke bruker omtanke, går det ille, og den som er for snar på foten, treder feil. 
20:19:3: Menneskets egen dårskap ødelegger hans vei, men i sitt hjerte vredes han på Herren. 
20:19:4: Rikdom skaper mange venner, den fattige blir skilt fra sin venn. 
20:19:5: Et falskt vidne skal ikke bli ustraffet, og den som taler løgn, skal ikke komme unda. 
20:19:6: Mange smigrer for den gavmilde, og enhver er venn med den som er rundhåndet. 
20:19:7: Den fattiges frender hater ham alle; enda mere holder hans venner sig borte fra ham. Han jager efter ord som ikke er å finne. 
20:19:8: Den som vinner forstand, elsker sitt liv; den som holder fast ved visdom, finner lykke. 
20:19:9: Et falskt vidne skal ikke bli ustraffet, og den som taler løgn, skal omkomme. 
20:19:10: Vellevnet høver ikke for en dåre, enda mindre høver det for en træl å herske over fyrster. 
20:19:11: Et menneskes klokskap gjør ham langmodig, og det er hans ære at han overser krenkelser. 
20:19:12: En konges vrede er som løvens brøl, men hans yndest som dugg på urter. 
20:19:13: En uforstandig sønn er bare til ulykke for sin far, og en kvinnes tretter er et stadig takdrypp. 
20:19:14: Hus og gods er en arv fra foreldre, men en forstandig kvinne er en gave fra Herren. 
20:19:15: Dovenskap senker i dyp søvn, og den late skal hungre. 
20:19:16: Den som holder budet, holder sig selv i live; den som ikke akter på sin ferd, skal miste sitt liv. 
20:19:17: Den som forbarmer sig over den fattige, låner til Herren, og Herren skal gjengjelde ham hans velgjerning. 
20:19:18: Tukt din sønn, for det er ennu håp; men la dig ikke drive til å drepe ham! 
20:19:19: Den hvis vrede er stor, bør bøte; for dersom du hjelper ham, får du gjøre det atter og atter. 
20:19:20: Hør på råd og ta imot tukt, så du kan bli vis til slutt! 
20:19:21: Det er mange tanker i en manns hjerte, men Herrens råd skal få fremgang. 
20:19:22: Et menneskes miskunnhet er hans glede, og en fattig er lykkeligere enn en stormann som lyver. 
20:19:23: Herrens frykt fører til liv, og mett får en gå til hvile uten å bli hjemsøkt med ulykke. 
20:19:24: Den late stikker sin hånd i fatet, men fører den ikke engang tilbake til sin munn. 
20:19:25: Slå spotteren, så vil den uforstandige bli klok; vis den forstandige til rette, så vil han komme til innsikt og kunnskap. 
20:19:26: Den som bruker vold mot sin far og jager sin mor bort, er en dårlig, en skamløs sønn. 
20:19:27: Hold op, min sønn, med å høre på formaning, når du allikevel bare forviller dig bort fra kunnskaps ord! 
20:19:28: Et ugudelig vidne spotter det som rett er, og de gudløses munn sluker urett. 
20:19:29: Straffedommer er fastsatt for spotterne og pryl for dårers rygg. 
20:20:1: Vinen er en spotter, sterk drikk volder støi, og hver den som raver av den, blir ikke vis. 
20:20:2: Den redsel en konge vekker, er som løvens brøl; den som gjør ham vred, spiller sitt liv. 
20:20:3: Det er en ære for en mann at han holder sig borte fra trette, men enhver dåre viser tenner. 
20:20:4: Om vinteren vil den late ikke pløie, derfor søker han forgjeves efter grøde om høsten. 
20:20:5: Tankene i en manns hjerte er et dypt vann, men en forstandig mann drar det op. 
20:20:6: Mange mennesker roper høit, hver om sin kjærlighet; men hvem finner vel en trofast mann? 
20:20:7: Den rettferdige vandrer i ustraffelighet; lykkelige er hans barn efter ham. 
20:20:8: En konge som sitter på sitt dommersete, sikter og skiller ut alt ondt med sine øine. 
20:20:9: Hvem kan si: Jeg har renset mitt hjerte, jeg er fri for min synd? 
20:20:10: To slags vektstener og to slags mål - begge deler er en vederstyggelighet for Herren. 
20:20:11: Allerede den unge gutt viser ved sine gjerninger om hans ferd vil bli ren og rett. 
20:20:12: Øret som hører, og øiet som ser - Herren har skapt dem begge to. 
20:20:13: Elsk ikke søvn, forat du ikke skal bli fattig! Lukk dine øine op, så får du brød nok å ete. 
20:20:14: Dårlig, dårlig! sier kjøperen; men når han går bort, roser han sig. 
20:20:15: Det er nok gull og perler i mengde, men kyndige leber er en kostelig ting. 
20:20:16: Ta hans klær, han har gått i borgen for en annen, og ta pant av ham for fremmedes skyld! 
20:20:17: Brød vunnet ved svik smaker mannen søtt, men siden blir hans munn full av småsten. 
20:20:18: Planer får fremgang ved rådslagning; søk veiledning også når du fører krig! 
20:20:19: Den som går omkring som baktaler, åpenbarer hemmeligheter, og med den som er åpenmunnet, skal du ikke ha noget å gjøre. 
20:20:20: Den som banner sin far og sin mor, hans lampe skal slukne i belgmørke. 
20:20:21: En arv som en fra først av har revet til sig, blir til sist uten velsignelse. 
20:20:22: Si ikke: Jeg vil gjengjelde med ondt! Bi på Herren, og han skal frelse dig! 
20:20:23: To slags vektstener er en vederstyggelighet for Herren, og falsk vekt er noget ondt. 
20:20:24: Herren styrer mannens skritt; hvorledes skulde et menneske skjønne sin vei? 
20:20:25: Det er farlig for et menneske at han i tankeløshet vier* noget til Gud og først bakefter overveier sine løfter. <* 3MO 27, 14 fg.> 
20:20:26: En vis konge skiller ut de ugudelige og lar hjulet gå over dem. 
20:20:27: Menneskets ånd er en Herrens lampe; den ransaker alle lønnkammerne i hans indre. 
20:20:28: Miskunnhet og sanndruhet er en vakt om kongen, og han støtter sin trone ved miskunnhet. 
20:20:29: De unges pryd er deres kraft, og de gamles ære er de grå hår. 
20:20:30: Et tuktemiddel for de onde er buler og sår og slag, som trenger inn i hjertets indre. 
20:21:1: Kongens hjerte er som bekker i Herrens hånd, han bøier det dit han vil. 
20:21:2: Alle en manns veier er rette i hans egne øine, men Herren veier hjertene. 
20:21:3: Å gjøre rett og skjel er mere verdt for Herren enn offer. 
20:21:4: Stolte øine og overmodig hjerte - de ugudeliges lampe* blir dem til synd. # <* deres lykke; OSP 13, 9; 20, 20.> 
20:21:5: Den flittiges tanker fører bare til vinning, men hastverk bare til tap. 
20:21:6: Rikdom som vinnes ved svikefull tunge, er et pust som blir borte i luften, og den fører til døden. 
20:21:7: De ugudeliges vold skal rykke dem selv bort, fordi de ikke vilde gjøre det som rett er. 
20:21:8: Skyldtynget manns vei er kroket, men den renes ferd er ærlig. 
20:21:9: Bedre å bo i et hjørne på taket enn med trettekjær kvinne i felles hus. 
20:21:10: Den ugudeliges sjel har lyst til det onde; hans næste finner ikke barmhjertighet hos ham. 
20:21:11: Når du straffer en spotter, blir den uforstandige vis, og når du lærer en vis, tar han imot kunnskap. 
20:21:12: Den Rettferdige* gir akt på den ugudeliges hus; han styrter de ugudelige i ulykke. # <* Gud.> 
20:21:13: Den som lukker sitt øre for den fattiges skrik, han skal selv rope, men ikke få svar. 
20:21:14: En gave i lønndom stiller vrede, og en hemmelig foræring stiller stor harme. 
20:21:15: Det er en glede for den rettferdige å gjøre rett, men en redsel for dem som gjør urett. 
20:21:16: Det menneske som forviller sig fra klokskaps vei, skal havne blandt dødningene. 
20:21:17: Fattig blir den som elsker glade dager; den som elsker vin og olje, blir ikke rik. 
20:21:18: Den ugudelige blir løsepenge for den rettferdige, og den troløse kommer i de opriktiges sted. 
20:21:19: Bedre å bo i et øde land enn hos en arg og trettekjær kvinne. 
20:21:20: Kostelige skatter og olje er det i den vises hus, men dåren gjør ende på det. 
20:21:21: Den som jager efter rettferdighet og miskunnhet, han skal finne liv, rettferdighet og ære. 
20:21:22: Den vise inntar de veldiges by og river ned det vern som den satte sin lit til. 
20:21:23: Den som varer sin munn og sin tunge, frir sitt liv fra trengsler. 
20:21:24: Den som er overmodig og opblåst, kalles en spotter; han farer frem i ustyrlig overmot. 
20:21:25: Den lates attrå dreper ham, fordi hans hender nekter å arbeide. 
20:21:26: Hele dagen attrår og attrår han, men den rettferdige gir og sparer ikke. 
20:21:27: De ugudeliges offer er en vederstyggelighet, og enda mere når de bærer det frem og har ondt i sinne! 
20:21:28: Et løgnaktig vidne skal omkomme, men en mann som hører efter, skal alltid få tale. 
20:21:29: En ugudelig mann ter sig frekt, men den opriktige går sin vei rett frem. 
20:21:30: Det finnes ingen visdom og ingen forstand og intet råd mot Herren. 
20:21:31: Hesten gjøres ferdig for stridens dag, men seieren hører Herren til. 
20:22:1: Et godt navn er mere verdt enn stor rikdom; å være godt likt er bedre enn sølv og gull. 
20:22:2: Rik og fattig møtes; Herren har skapt dem begge. 
20:22:3: Den kloke ser ulykken og skjuler sig, men de uerfarne går videre og må bøte. 
20:22:4: Lønn for saktmodighet og gudsfrykt er rikdom og ære og liv. 
20:22:5: Torner og snarer er der på den falskes vei; den som varer sitt liv, holder sig borte fra dem. 
20:22:6: Lær den unge den vei han skal gå! Så viker han ikke fra den, selv når han blir gammel. 
20:22:7: Den rike hersker over de fattige, og låntageren blir långiverens træl. 
20:22:8: Den som sår urett, skal høste ondt, og med hans vredes ris skal det være forbi. 
20:22:9: Den som har et godt hjerte, blir velsignet fordi han gav den fattige av sitt brød. 
20:22:10: Jag spotteren bort! Så går tretten med, og kiv og skam hører op. 
20:22:11: Den som elsker hjertets renhet, og hvis tale er tekkelig, han har kongen til venn. 
20:22:12: Herrens øine verner den forstandige, men han gjør den troløses ord til intet. 
20:22:13: Den late sier: Det er en løve der ute, jeg kunde bli drept midt på gaten. 
20:22:14: Fremmed kvinnes munn er en dyp grav; den Herren er vred på, faller i den. 
20:22:15: Dårskap er bundet fast til den unges hjerte; tuktens ris driver den bort. 
20:22:16: Å undertrykke den fattige tjener bare til å øke hans gods; å gi til den rike volder ham bare tap. 
20:22:17: Bøi ditt øre til og hør på vismenns ord og vend ditt hjerte til min kunnskap! 
20:22:18: For det er godt at du bevarer dem i ditt indre, og at de alle henger fast ved dine leber. 
20:22:19: Forat du skal sette din lit til Herren, lærer jeg dig idag, nettop dig. 
20:22:20: Har jeg ikke skrevet for dig kjernesprog med råd og kunnskap 
20:22:21: for å kunngjøre dig det som rett er, sannhets ord, så du kan svare dem som sender dig, med sanne ord? 
20:22:22: Røv ikke fra en fattig, fordi han er fattig, og tred ikke armingen ned i byporten*! # <* d.e. i retten.> 
20:22:23: For Herren skal føre deres sak, og han skal ta deres liv som tar noget fra dem. 
20:22:24: Hold dig ikke til venns med en som er snar til vrede, og gi dig ikke i lag med en hastig mann, 
20:22:25: forat du ikke skal lære dig til å gå på hans veier og få satt en snare for ditt liv! 
20:22:26: Vær ikke blandt dem som gir håndslag, dem som borger for gjeld! 
20:22:27: Når du intet har å betale med, hvorfor skal de da ta din seng bort under dig? 
20:22:28: Flytt ikke det gamle grenseskjell som dine fedre har satt! 
20:22:29: Ser du en mann som er duelig i sin gjerning - han kan komme til å tjene konger; han kommer ikke til å tjene småfolk. 
20:23:1: Når du sitter til bords med en fyrste, da skal du nøie akte på hvem du har for dig, 
20:23:2: og sette en kniv på din strupe, hvis du er grådig. 
20:23:3: Vær ikke lysten efter hans fine retter, for det er mat som kan svike! 
20:23:4: Gjør dig ikke møie for å bli rik, la sådan klokskap fare! 
20:23:5: Når du vender dine øine mot rikdommen, så er den borte. For den gjør sig visselig vinger, lik en ørn som flyver mot himmelen. 
20:23:6: Et ikke den misunneliges brød, og vær ikke lysten efter hans fine mat! 
20:23:7: For som han tenker i sin sjel, så er han; et og drikk, sier han til dig, men hans hjerte er ikke med dig. 
20:23:8: Den matbit som du har ett, den vil du spy ut, og du har spilt dine vakre ord. 
20:23:9: Tal ikke for dårens ører, for han forakter dine forstandige ord! 
20:23:10: Flytt ikke det gamle grenseskjell, og kom ikke inn på farløses marker! 
20:23:11: For deres løser er sterk, han skal føre deres sak mot dig. 
20:23:12: Bøi ditt hjerte til tukt og dine ører til kunnskaps ord! 
20:23:13: La ikke den unge være uten tukt! Når du slår ham med riset, skal han ikke dø. 
20:23:14: Du slår ham med riset, men du frelser hans sjel fra dødsriket. 
20:23:15: Min sønn! Er ditt hjerte vist, så skal også mitt hjerte glede sig, 
20:23:16: og jeg skal juble i mitt indre når dine leber taler det som rett er. 
20:23:17: La ikke ditt hjerte være nidkjært mot syndere, men alltid nidkjært for Herrens frykt! 
20:23:18: Sannelig, det er en fremtid for dig, og ditt håp skal ikke bli til intet. 
20:23:19: Hør, min sønn, og bli vis og la ditt hjerte gå bent frem på veien! 
20:23:20: Vær ikke blandt vindrikkere, blandt dem som fråtser i kjøtt! 
20:23:21: For drankeren og fråtseren blir fattig, og søvn klær mannen i filler. 
20:23:22: Hør på din far, som gav dig livet, og forakt ikke din mor når hun er blitt gammel! 
20:23:23: Kjøp sannhet og selg den ikke, kjøp visdom og tukt og forstand! 
20:23:24: Den rettferdiges far skal juble; den som får en vis sønn, skal glede sig over ham. 
20:23:25: La din far og din mor glede sig, og la henne som fødte dig, juble! 
20:23:26: Min sønn! Gi mig ditt hjerte, og la dine øine ha lyst til mine veier! 
20:23:27: For skjøgen er en dyp grav, og den fremmede kvinne en trang brønn; 
20:23:28: ja, hun ligger på lur som en røver, og hun øker tallet på de troløse blandt menneskene. 
20:23:29: Hvem roper: Akk? Hvem roper: Ve? Hvem har trette? Hvem har klage? Hvem har sår for ingen ting? Hvem har røde øine? 
20:23:30: De som sitter lenge oppe ved vinen, de som kommer for å prøve den krydrede drikk. 
20:23:31: Se ikke til vinen, hvor rød den er, hvorledes den perler i begeret, hvor lett den går ned! 
20:23:32: Til sist biter den som en slange og hugger som en huggorm; 
20:23:33: dine øine vil se efter fremmede kvinner, og ditt hjerte tale forvendte ting, 
20:23:34: og du blir lik en som sover midt ute på havet, lik en som sover i toppen av en mast. 
20:23:35: [Du vil si:] De banket mig, det gjorde ikke ondt; de støtte mig, jeg kjente det ikke. Når skal jeg våkne? Jeg vil se å få tak i enda mere. 
20:24:1: Vær ikke misunnelig på onde mennesker, og ha ikke lyst til å være med dem! 
20:24:2: For deres hjerte tenker bare på å ødelegge, og deres leber taler ulykke. 
20:24:3: Ved visdom bygges et hus, og ved forstand blir det trygget, 
20:24:4: og ved kunnskap fylles kammerne med all slags kostelig og herlig gods. 
20:24:5: En vis mann er sterk, og en kyndig mann øker sin kraft. 
20:24:6: Du skal søke veiledning når du fører krig; hvor det er mange rådgivere, er det frelse. 
20:24:7: Visdom er for høi for dåren; i byporten lukker han ikke sin munn op. 
20:24:8: Den som tenker ut onde råd, blir kalt en renkesmed. 
20:24:9: Dårskaps råd er synd, og en spotter er en vederstyggelighet blandt folk. 
20:24:10: Viser du dig motløs på trengselens dag, så er din kraft ringe. 
20:24:11: Frels dem som hentes til døden, og hold tilbake dem som føres skjelvende bort til retterstedet! 
20:24:12: Når du sier: Se, vi visste ikke noget om det, mon da ikke han skjønner det, han som veier hjertene, og han som gir akt på din sjel, mon ikke han vet det og gjengjelder enhver efter hans gjerninger? 
20:24:13: Et honning, min sønn, for den er god, og fin honning er søt for din gane! 
20:24:14: Akt visdommen like så gagnlig for din sjel! Har du funnet den, så er det en fremtid for dig, og ditt håp skal ikke bli til intet. 
20:24:15: Lur ikke som en ugudelig på den rettferdiges bolig, ødelegg ikke hans hjem! 
20:24:16: For syv ganger faller den rettferdige og står op igjen, men de ugudelige kastes over ende når ulykken kommer. 
20:24:17: Når din fiende faller, må du ikke glede dig, og når han snubler, må ikke ditt hjerte fryde sig, 
20:24:18: forat ikke Herren skal se det og mislike det, så han vender sin vrede fra ham*. # <* og til dig.> 
20:24:19: La ikke din vrede optendes over de onde, bli ikke harm over de ugudelige! 
20:24:20: For de onde har ingen fremtid, de ugudeliges lampe slukner. 
20:24:21: Frykt Herren, min sønn, og kongen! Med folk som setter sig op mot dem, må du ikke ha noget å gjøre; 
20:24:22: for ulykken kommer brått over dem, og ødeleggelsen fra dem begge* - hvem kjenner den? # <* Gud og kongen.> 
20:24:23: Også disse ordsprog er av vismenn: Dommeren bør ikke gjøre forskjell på folk. 
20:24:24: Den som sier til den skyldige: Du er uskyldig, ham vil folkeslagene forbanne, ham vil folkene ønske ondt over; 
20:24:25: men dem som straffer ham, skal det gå vel, og lykke og velsignelse skal komme over dem. 
20:24:26: Kyss på leber gir den som svarer med rette ord. 
20:24:27: Fullfør din gjerning der ute og gjør den ferdig på marken! Siden kan du bygge ditt hus. 
20:24:28: Vær ikke vidne mot din næste uten årsak! Eller skulde du gjøre svik med dine leber? 
20:24:29: Si ikke: Som han har gjort mot mig, således vil jeg gjøre mot ham; jeg vil gjengjelde enhver efter hans gjerninger. 
20:24:30: Jeg kom gående forbi en lat manns mark, et uforstandig menneskes vingård, 
20:24:31: og se, den var helt overgrodd med tistler; nesler skjulte dens bunn, og stengjerdet om den var revet ned. 
20:24:32: Og jeg, jeg blev det var, jeg gav akt på det; jeg så det og tok lærdom av det: 
20:24:33: [Sier du:] La mig ennu sove litt, blunde litt, folde mine hender litt og hvile - 
20:24:34: så kommer armoden over dig som en landstryker, og nøden som en mann med skjold. 
20:25:1: Også dette er Salomos ordsprog, som Judas konge Esekias' menn har samlet: 
20:25:2: Det er Guds ære å skjule en sak*, men kongers ære å granske en sak. <* JES 45, 15.> 
20:25:3: Himmelens høide, jordens dybde og kongers hjerter er uransakelige. 
20:25:4: Skill slagget fra sølvet, så får gullsmeden et kar ut av det. 
20:25:5: Før den ugudelige bort fra kongens åsyn, så blir hans trone trygget ved rettferdighet. 
20:25:6: Bryst dig ikke for kongens åsyn og still dig ikke på de stores plass! 
20:25:7: For det er bedre de sier til dig: Kom her op, enn at du blir flyttet ned for en stormann, som du hadde sett. 
20:25:8: Gi dig ikke for hastig i strid med nogen, forat det ikke skal sies: Hvad vil du gjøre til slutt når motparten vinner saken til din skam? 
20:25:9: Før din sak mot din motpart, men åpenbar ikke annen manns hemmelighet, 
20:25:10: forat ikke den som hører det, skal skjelle dig ut, og ditt dårlige rykte vare ved! 
20:25:11: Som epler av gull i skåler av sølv er et ord talt i rette tid. 
20:25:12: Som en ring av gull, som et smykke av fint gull er en vismann som taler refsende ord for et hørende øre. 
20:25:13: Som kjølende sne* i høstens tid er et pålitelig sendebud for den som sender ham; han vederkveger sin herres sjel. # <* d.e. en ved sne avkjølt drikk.> 
20:25:14: Som skyer og vind uten regn er den mann som skryter av at han vil gi, men ikke holder ord. 
20:25:15: Ved saktmodighet blir en fyrste overtalt, og en mild tunge knuser ben. 
20:25:16: Har du funnet honning, så et av den det du trenger, forat du ikke skal bli lei av den og spy den ut! 
20:25:17: Sett sjelden din fot i din venns hus, forat han ikke skal bli lei av dig og hate dig! 
20:25:18: Som en hammer, et sverd, en hvass pil er en mann som fører falskt vidnesbyrd mot sin næste. 
20:25:19: Som en skjør tann og en vaklende fot er tillit til den troløse på nødens dag. 
20:25:20: Lik den som legger av sine klær på en vinterdag, lik eddik på pottaske er den som synger viser for et sorgfullt hjerte. 
20:25:21: Hungrer din fiende, så gi ham brød å ete, og tørster han, så gi ham vann å drikke! 
20:25:22: For da sanker du gloende kull på hans hode, og Herren skal gjengjelde dig det. 
20:25:23: Nordenvind føder regn, og en tunge som hvisker i lønndom, volder sure miner. 
20:25:24: Bedre å bo i et hjørne på taket enn med trettekjær kvinne i felles hus. 
20:25:25: Som friskt vann for den trette er en god tidende fra et fjernt land. 
20:25:26: Som en grumset kilde og en utskjemt brønn er en rettferdig som gir efter for en ugudelig. 
20:25:27: Å ete for meget honning er ikke godt, og grubleres ære er tung. 
20:25:28: Som en by hvis murer er brutt ned og borte, er en mann som ikke kan styre sitt sinn. 
20:26:1: Som sne om sommeren og som regn i høsttiden, slik høver ære for en dåre. 
20:26:2: Som spurven i fart, som svalen i flukt, slik er det med en uforskyldt forbannelse - den rammer ikke. 
20:26:3: Svepe for hesten, tømme for asenet, og kjepp for dårers rygg! 
20:26:4: Svar ikke dåren efter hans dårskap, forat du ikke selv skal bli ham lik! 
20:26:5: Svar dåren efter hans dårskap, forat han ikke skal bli vis i egne øine! 
20:26:6: Den som sender bud med en dåre, han hugger føttene av sig, han må tåle slem medfart. 
20:26:7: Visne henger benene på den lamme og likeså ordsprog i munnen på dårer. 
20:26:8: Lik den som legger sten i slyngen, er den som gir en dåre ære. 
20:26:9: Som en torn i en drukken manns hånd, slik er et ordsprog i dårers munn. 
20:26:10: En mester får alt i stand, men den som leier en dåre, er lik den som leier en som går forbi*. # <* d.e. den første den beste.> 
20:26:11: Lik hunden som vender tilbake til sitt eget spy, er en dåre som kommer igjen med sin dårskap. 
20:26:12: Ser du en mann som er vis i egne øine - det er mere håp for dåren enn for ham. 
20:26:13: Den late sier: Det er en løve på veien, en løve i gatene. 
20:26:14: Døren dreier sig på sitt hengsel, og den late snur sig på sitt leie. 
20:26:15: Den late stikker sin hånd fatet; han gider ikke føre den tilbake til sin munn. 
20:26:16: Den late er visere i egne øine enn syv som svarer med forstand. 
20:26:17: Lik den som tar fatt i øret på en hund som løper forbi, er den som lar sig egge til vrede over en trette som ikke kommer ham ved. 
20:26:18: Lik en gal mann som kaster ut brandpiler og skyter og dreper, 
20:26:19: er en mann som har sveket sin venn og så sier: Jeg spøker jo bare! 
20:26:20: Når det er forbi med veden, slukner ilden, og når det ingen øretuter er, stilles trette. 
20:26:21: Som kull blir til glør, og som ved nærer ild, slik voldes kiv av en trettekjær mann. 
20:26:22: En øretuters ord er som velsmakende retter, og de trenger ned i hjertets indre. 
20:26:23: Lik et lerkar som er overdratt med bly-glette, er brennende leber sammen med et ondt hjerte. 
20:26:24: Med sine leber skaper den hatefulle sig til, men i sitt indre gjemmer han svik. 
20:26:25: Når han gjør sin røst blid, så tro ham ikke! For der er syv vederstyggeligheter i hans hjerte. 
20:26:26: Den hatefulle skjuler sig i svik, men hans ondskap blir åpenbar i forsamlingen. 
20:26:27: Den som graver en grav, skal falle i den, og den som velter en sten op, på ham skal den rulle tilbake. 
20:26:28: En løgnaktig tunge hater dem som den har knust, og en falsk munn volder fall. 
20:27:1: Ros dig ikke av den dag imorgen, for du vet ikke hvad dagen vil føde! 
20:27:2: La en annen rose dig og ikke din egen munn, en fremmed og ikke dine egne leber! 
20:27:3: Stenen er tung, og sanden veier meget, men dårens harme er tyngre enn begge. 
20:27:4: Vrede er fryktelig, og harme er som en flom; men hvem kan stå sig mot avind? 
20:27:5: Åpenlys irettesettelse er bedre enn kjærlighet som skjules. 
20:27:6: Trofaste er vennens slag, men troløse er fiendens kyss. 
20:27:7: Den mette vraker honning, men for den sultne er alt bittert søtt. 
20:27:8: Lik en spurv som flyver omkring borte fra sitt rede, er en mann som vanker om borte fra sitt hjem. 
20:27:9: Olje og røkelse gleder hjertet, og likeså en venns ømhet og opriktige råd. 
20:27:10: Forlat ikke din venn og din fars venn, og kom ikke i din brors hus den dag du er i nød! En granne nær ved er bedre enn en bror langt borte. 
20:27:11: Vær vis, min sønn, og gled mitt hjerte, så jeg kan svare den som håner mig! 
20:27:12: Den kloke ser ulykken og skjuler sig; de uerfarne går videre og må bøte. 
20:27:13: Ta hans klær, han har gått i borgen for en annen, og ta pant av ham for en fremmed kvinnes skyld! 
20:27:14: Den som velsigner sin venn med høi røst* tidlig om morgenen, ham skal det regnes som en forbannelse. # <* på en hyklersk måte.> 
20:27:15: Et stadig takdrypp på en regndag og en trettekjær kvinne ligner hverandre. 
20:27:16: Den som holder på henne, holder på vind, og hans høire hånd griper i olje. 
20:27:17: Jern skjerpes ved jern, og en mann slipes ved å omgås andre. 
20:27:18: Den som passer sitt fikentre, får ete dets frukt, og den som tar vare på sin herre, blir æret. 
20:27:19: Likesom ansikt speiler sig mot ansikt i vannet, så finner det ene menneske sitt hjerte igjen hos det andre. 
20:27:20: Dødsriket og avgrunnen blir ikke mette, og menneskenes øine blir heller ikke mette. 
20:27:21: Digel er for sølv og ovn for gull, og en mann prøves efter det han roser. 
20:27:22: Om du støter dåren i morteren med støteren midt iblandt grynene, så viker hans dårskap allikevel ikke fra ham. 
20:27:23: Du bør nøie kjenne dine fårs utseende; ha omsorg for din buskap! 
20:27:24: For gods varer ikke til evig tid, og en krone ikke gjennem alle slekter. 
20:27:25: Når høiet er borte, og det unge gress kommer til syne, og fjellgresset samles inn, 
20:27:26: så har du lam til klær og bukker til å kjøpe aker for, 
20:27:27: og du har gjetemelk nok til føde for dig og ditt hus og til livsophold for dine piker. 
20:28:1: De ugudelige flyr uten at nogen forfølger dem; men de rettferdige er trygge som ungløven. 
20:28:2: For et lands frafalls skyld blir dets fyrster mange; men når menneskene er kloke og førstandige, så lever fyrsten lenge. 
20:28:3: En fattig mann som undertrykker småfolk, er et regn som skyller bort kornet, så der ikke blir brød. 
20:28:4: De som ikke følger loven, priser de ugudelige, men de som holder loven, strider mot dem. 
20:28:5: Onde mennesker skjønner ikke hvad rett er, men de som søker Herren, skjønner alt. 
20:28:6: Bedre er en fattig som vandrer i ustraffelighet, enn en falsk som vandrer på to veier, selv om han er rik. 
20:28:7: Den som følger loven, er en forstandig sønn; men den som holder vennskap med svirebrødre, gjør sin far skam. 
20:28:8: Den som øker sitt gods ved rente og ved overmål, han samler for den som forbarmer sig over de fattige. 
20:28:9: Om en vender sitt øre bort og ikke vil høre loven, er endog hans bønn en vederstyggelighet. 
20:28:10: Den som fører de opriktige vill på en ond vei, skal falle i sin egen grav; men de ustraffelige skal arve det som godt er. 
20:28:11: En rik mann er vis i sine egne øine; men en fattig som er forstandig, gjennemskuer ham. 
20:28:12: Når de rettferdige jubler, er alt herlighet og glede; men når de ugudelige kommer sig op, må en lete efter folk. 
20:28:13: Den som skjuler sine misgjerninger, har ingen lykke, men den som bekjenner dem og vender sig fra dem, finner miskunnhet. 
20:28:14: Salig er det menneske som alltid frykter; men den som forherder sitt hjerte, faller i ulykke. 
20:28:15: En brølende løve, en omfarende bjørn, slik er en ugudelig hersker over et fattig folk. 
20:28:16: Du fyrste som er fattig på forstand og rik på vold! De som hater urettferdig vinning, skal leve lenge. 
20:28:17: Et menneske som trykkes av blodskyld, er på flukt like til sin grav; ingen må holde på ham*. # <* nemlig for å redde ham.> 
20:28:18: Den som lever ustraffelig, skal frelses; men den falske, som vandrer på to veier, skal falle på den ene. 
20:28:19: Den som dyrker sin jord, mettes med brød; men den som jager efter tomme ting, mettes med armod. 
20:28:20: En trofast mann får rik velsignelse; men den som haster efter å bli rik, han blir ikke ustraffet. 
20:28:21: Å gjøre forskjell på folk er ikke rett, men mangen mann forsynder sig for et stykke brøds skyld. 
20:28:22: Den misunnelige haster engstelig efter gods og vet ikke at mangel skal komme over ham. 
20:28:23: Den som irettesetter et menneske, skal siden finne mere yndest enn den som gjør sin tunge glatt. 
20:28:24: Den som plyndrer sin far og sin mor og sier: Det er ingen synd, han er en stallbror til ødeleggeren. 
20:28:25: Den havesyke vekker trette; men den som setter sin lit til Herren, skal trives. 
20:28:26: Den som setter sin lit til sin forstand, han er en dåre; men den som vandrer i visdom, han blir frelst. 
20:28:27: Den som gir til den fattige, skal ikke lide mangel; men den som lukker sine øine*, får mange forbannelser. # <* nemlig for den fattiges nød.> 
20:28:28: Når de ugudelige kommer sig op, skjuler folk sig; men når de omkommer, blir der mange rettferdige. 
20:29:1: En mann som er ofte straffet og allikevel gjør sin nakke stiv, vil i et øieblikk bli sønderbrutt, og der er ingen lægedom. 
20:29:2: Når de rettferdige kommer til makten, gleder folket sig; men når en ugudelig mann hersker, sukker folket. 
20:29:3: En mann som elsker visdom, gleder sin far; men den som holder vennskap med skjøger, øder sitt gods. 
20:29:4: En konge trygger sitt land ved rett; men en mann som tar imot gaver, bryter det ned. 
20:29:5: En mann som smigrer for sin næste, setter op et garn for hans fot. 
20:29:6: En ond manns misgjerning er en snare for ham, men den rettferdige skal juble og glede sig. 
20:29:7: Den rettferdige tar sig av småfolks sak; den ugudelige skjønner sig ikke på noget. 
20:29:8: Spottere egger op byen, men vismenn stiller vreden. 
20:29:9: Når en vismann går i rette med en dåre, så blir dåren vred og ler, og der blir ingen ro. 
20:29:10: De blodtørstige hater den ustraffelige, men de rettsindige søker å redde hans liv. 
20:29:11: All sin vrede lar dåren strømme ut, men den vise holder vreden tilbake og stiller den. 
20:29:12: Når en hersker akter på løgnens ord, blir alle hans tjenere ugudelige. 
20:29:13: Den fattige og den som undertrykker ham, møtes; Herren gir begges øine deres schmelk. 
20:29:14: En konge som dømmer småfolk rettferdig, hans trone står fast for alle tider. 
20:29:15: Ris og tukt gir visdom; men en gutt som er overlatt til sig selv, gjør sin mor skam. 
20:29:16: Når de ugudelige får makt, får synden makt; men de rettferdige skal se deres fall med glede. 
20:29:17: Tukt din sønn, så skal han bli dig til glede og vederkvege din sjel! 
20:29:18: Uten åpenbaring blir folket tøilesløst; men lykkelig er den som holder loven. 
20:29:19: Ved ord lar en træl sig ikke tukte; for han skjønner dem nok, men adlyder dem ikke. 
20:29:20: Har du sett en mann som forhaster sig i sine ord - det er mere håp for dåren enn for ham. 
20:29:21: Forkjæler en sin træl fra ungdommen av, så vil han til sist være sønn i huset. 
20:29:22: Den som er snar til vrede, vekker trette, og en hastig mann gjør ofte det som er ondt. 
20:29:23: Et menneskes stolthet fører ham til fall, men den ydmyke vinner ære. 
20:29:24: Den som deler med en tyv, hater sitt liv; han hører opropet til ed og gir allikevel ingen oplysning. 
20:29:25: Menneskefrykt fører i snare, men den som setter sin lit til Herren, han blir berget. 
20:29:26: Mange søker en herskers yndest, men fra Herren kommer en manns rett. 
20:29:27: En urettferdig mann er en vederstyggelighet for de rettferdige, og en vederstyggelighet for den ugudelige er den som lever rett. 
20:30:1: Agurs, Jakes sønns ord og utsagn. Så talte mannen til Itiel, til Itiel og Ukkal: 
20:30:2: Jeg er for ufornuftig til å kalles menneske; manns forstand har jeg ikke. 
20:30:3: Jeg har ikke lært visdom og har ikke kunnskap om den Hellige. 
20:30:4: Hvem fór op til himmelen og fór ned? Hvem samlet været i sine never? Hvem bandt vannet i et klæde? Hvem satte alle jordens grenser? Hvad er hans navn, og hvad er hans sønns navn? Du vet det jo. 
20:30:5: Alt Guds ord er rent; han er et skjold for dem som tar sin tilflukt til ham. 
20:30:6: Legg ikke noget til hans ord, forat han ikke skal straffe dig, og du stå som en løgner! 
20:30:7: To ting beder jeg dig om, nekt mig dem ikke, før jeg dør: 
20:30:8: La falskhet og løgnens ord være langt borte fra mig! Gi mig ikke armod og heller ikke rikdom! La mig ete mitt tilmålte brød, 
20:30:9: forat jeg ikke når jeg blir mett, skal fornekte dig og si: Hvem er Herren? og ikke når jeg blir fattig, stjele og forbanne min Guds navn! 
20:30:10: Baktal ikke en tjener for hans herre, forat han ikke skal banne dig, og du dra skyld over dig! 
20:30:11: Der er en ætt som banner sin far og ikke velsigner sin mor, 
20:30:12: en ætt som er ren i sine egne øine og dog ikke har tvettet sig for sitt eget skarn, 
20:30:13: en ætt - hvor stolte er ikke dens øine, og dens øielokk, hvor hever de sig ikke! - 
20:30:14: en ætt hvis tenner er sverd, og hvis jeksler er kniver, som eter arminger ut av landet og fattige ut av menneskenes tall. 
20:30:15: Blodiglen har to døtre: Gi hit! Gi hit! Der er tre som aldri blir mette, fire som aldri sier: Nok! 
20:30:16: Det er dødsriket og det ufruktbare morsliv, jorden, som aldri blir mett av vann, og ilden, som aldri sier: Nok! 
20:30:17: Et øie som spotter far og forakter lydighet mot mor, det skal ravnene ved bekken hakke ut, og ørneunger skal ete det. 
20:30:18: Det er tre ting som er mig for underlige, og fire som jeg ikke skjønner: 
20:30:19: Ørnens vei på himmelen, ormens vei over stenen, skibets vei på havet og en manns vei til en jomfru. 
20:30:20: Slik bærer en horkvinne sig at: Hun eter og tørker sin munn og sier: Jeg har ikke gjort noget ondt. 
20:30:21: Under tre skjelver jorden, og under fire kan den ikke holde ut: 
20:30:22: under en træl når han blir konge, og en dåre når han blir mett av brød, 
20:30:23: under en forsmådd kvinne når hun blir gift, og en tjenestepike når hun arver sin frue. 
20:30:24: Det er fire som er små på jorden og allikevel overvettes vise: 
20:30:25: Maurene er ikke noget sterkt folk, og enda lager de sin føde om sommeren; 
20:30:26: fjellgrevlingene er ikke noget kraftig folk, og enda bygger de sitt hus i berget; 
20:30:27: gresshoppene har ingen konge, og enda drar de alle ut, skare efter skare; 
20:30:28: firfislen kan du gripe med hendene, og allikevel finnes den i kongelige palasser. 
20:30:29: Det er tre som skrider vakkert frem, og fire som har en vakker gang: 
20:30:30: Løven, som er en helt blandt dyrene, og som ikke vender om for nogen, 
20:30:31: hesten med gjord om lendene, bukken, og en konge i spissen for sitt folk. 
20:30:32: Har du vært så uforstandig at du har ophøiet dig, eller har du tenkt på ondt, da legg hånden på din munn! 
20:30:33: For trykk på melk gir smør, og trykk på nese gir blod, og trykk på vrede* gir trette. # <* enn mere å egge den vrede.> 
20:31:1: Kong Lemuels ord, den lærdom som hans mor innprentet ham: 
20:31:2: Hvad skal jeg si til dig, min sønn, du mitt livs sønn, du mine løfters sønn? 
20:31:3: Gi ikke kvinner din kraft, og gå ikke på veier som fører til ødeleggelse for konger! 
20:31:4: Det sømmer sig ikke for konger, Lemuel, det sømmer sig ikke for konger å drikke vin, heller ikke for fyrster å drikke sterk drikk, 
20:31:5: forat de ikke skal drikke og glemme hvad der er lov, og forvende retten for alle arminger. 
20:31:6: Gi sterk drikk til den som er sin undergang nær, og vin til den som er bedrøvet i sjelen! 
20:31:7: La ham få drikke, så han glemmer sin fattigdom og ikke mere kommer sin møie i hu! 
20:31:8: Oplat din munn for den stumme, for alle deres sak som er nær ved å forgå! 
20:31:9: Oplat din munn, døm rettferdig og hjelp armingen og den fattige til hans rett! 
20:31:10: En god hustru - hvem finner henne? Langt mere enn perler er hun verd. 
20:31:11: Hennes manns hjerte liter på henne, og på vinning skorter det ikke. 
20:31:12: Hun gjør ham godt og intet ondt alle sitt livs dager. 
20:31:13: Hun sørger for ull og lin, og hennes hender arbeider med lyst. 
20:31:14: Hun er som en kjøbmanns skib; hun henter sitt brød langveisfra. 
20:31:15: Hun står op mens det ennu er natt, og gir sine husfolk brød og sine piker deres arbeid for dagen. 
20:31:16: Hun tenker på en mark og får den; for det hun tjener med sine hender, planter hun en vingård. 
20:31:17: Hun omgjorder sine lender med kraft og gjør sine armer sterke. 
20:31:18: Hun merker at det går godt med hennes arbeid; hennes lampe slukkes ikke om natten. 
20:31:19: Hun legger sine hender på rokken, og hennes fingrer tar fatt på tenen. 
20:31:20: Hun åpner sin hånd for den trengende og rekker ut sine hender til den fattige. 
20:31:21: Hun frykter ikke sneen for sitt hus; for hele hennes hus er klædd i skarlagenfarvet ull. 
20:31:22: Hun gjør sig tepper; fint lin og purpur er hennes klædning. 
20:31:23: Hennes mann er kjent i byens porter, der han sitter sammen med landets eldste. 
20:31:24: Hun gjør skjorter og selger dem, og belter leverer hun til kjøbmannen. 
20:31:25: Kraft og verdighet er hennes klædebon, og hun ler av den kommende tid. 
20:31:26: Hun oplater sin munn med visdom, og kjærlig formaning er på hennes tunge. 
20:31:27: Hun holder øie med hvorledes det går til i hennes hus, og dovenskaps brød eter hun ikke. 
20:31:28: Hennes sønner står op og priser henne lykkelig; hennes mann står op og roser henne: 
20:31:29: Det finnes mange dyktige kvinner, men du overgår dem alle. 
20:31:30: Ynde sviker, og skjønnhet forgår; en kvinne som frykter Herren, hun skal prises. 
20:31:31: Gi henne av hennes arbeids frukt, og hennes gjerninger skal prise henne i byens porter. 
21:1:1: Ord av predikeren, sønn av David, konge i Jerusalem. 
21:1:2: Bare tomhet, sier predikeren, bare idelig tomhet! Alt er tomhet. 
21:1:3: Hvad vinning har mennesket av alt sitt strev, som han møier sig med under solen? 
21:1:4: Slekt går, og slekt kommer, men jorden står evindelig. 
21:1:5: Og solen går op, og solen går ned, og den haster tilbake til det sted hvor den går op. 
21:1:6: Vinden går mot syd og vender sig mot nord; den vender og vender sig om under sin gang og begynner så atter sitt kretsløp. 
21:1:7: Alle bekker løper ut i havet, men havet blir ikke fullt; til det sted som bekkene går til, dit går de alltid igjen. 
21:1:8: Alle ting strever utrettelig, ingen kan utsi det; øiet blir ikke mett av å se, og øret blir ikke fullt av å høre. 
21:1:9: Det som har vært, er det som skal bli, og det som har hendt, er det som skal hende, og det er intet nytt under solen. 
21:1:10: Er det noget hvorom en vilde si: Se, dette er nytt - så har det vært til for lenge siden, i fremfarne tider som var før oss. 
21:1:11: Det er ingen som minnes dem som har levd før, og heller ikke vil de som siden skal komme, leve i minnet hos dem som kommer efter. 
21:1:12: Jeg, predikeren, var konge over Israel i Jerusalem, 
21:1:13: og jeg vendte min hu til å ransake og utgranske med visdom alt det som hender under himmelen; det er en ond plage, som Gud har gitt menneskenes barn å plage sig med. 
21:1:14: Jeg sa alle de gjerninger som gjøres under solen, og se, alt sammen var tomhet og jag efter vind. 
21:1:15: Det som er kroket, kan ikke bli rett, og det som mangler, kan ingen regne med. 
21:1:16: Jeg talte med mig selv i mitt indre og sa: Se, jeg har vunnet mig større og rikere visdom enn alle som har rådet over Jerusalem før mig, og mitt hjerte har skuet megen visdom og kunnskap. 
21:1:17: Og jeg vendte min hu til å kjenne visdommen og kjenne dårskap og uforstand, men jeg skjønte at også dette var jag efter vind. 
21:1:18: For hvor det er megen visdom, der er det megen gremmelse, og den som øker kunnskap, øker smerte. 
21:2:1: Jeg sa til mitt hjerte: Vel, jeg vil prøve dig med glede; nyt det som godt er! Men se, også det var tomhet. 
21:2:2: Til latteren sa jeg: Du er gal! Og til gleden: Hvad gagn gjør du? 
21:2:3: Jeg tenkte i mitt indre på å kvege mitt legeme med vin, mens mitt hjerte ledet mig med visdom - jeg tenkte på å holde fast ved dårskapen, til jeg fikk se hvad det var best for menneskenes barn å gjøre under himmelen alle deres levedager. 
21:2:4: Jeg utførte store arbeider, jeg bygget mig hus, jeg plantet mig vingårder, 
21:2:5: jeg gjorde mig haver og parker og plantet i dem alle slags frukttrær, 
21:2:6: jeg gjorde mig vanndammer til å vanne en skog av opvoksende trær. 
21:2:7: Jeg kjøpte træler og trælkvinner, jeg hadde tjenestefolk som var født i mitt hus; jeg fikk mig også meget fe, både stort og smått, mere enn alle som hadde vært før mig i Jerusalem; 
21:2:8: jeg samlet mig også sølv og gull og kostelige skatter som kom fra fremmede konger og land; jeg fikk mig sangere og sangerinner og det som er menneskenes lyst, en hustru og flere. 
21:2:9: Jeg blev større og mektigere enn alle som hadde vært før mig i Jerusalem; og min visdom hadde ikke forlatt mig. 
21:2:10: Alt det mine øine attrådde, det forholdt jeg dem ikke, jeg nektet ikke mitt hjerte nogen glede; for mitt hjerte hadde glede av alt mitt strev, og dette var det jeg hadde igjen for alt mitt strev. 
21:2:11: Men når jeg så på alt det som mine hender hadde gjort, og på den møie det hadde kostet mig, da så jeg at alt sammen var tomhet og jag efter vind, og at det ikke er nogen vinning å nå under solen. 
21:2:12: Så gav jeg mig til å se på visdom og på dårskap og uforstand; for hvad vil det menneske gjøre som kommer efter kongen? Det samme som andre har gjort for lenge siden. 
21:2:13: Da så jeg at visdommen har samme fortrin fremfor dårskapen som schmelken har fremfor mørket; 
21:2:14: den vise har øine i sitt hode, men dåren vandrer i mørket. Men jeg skjønte også at det går den ene som den andre. 
21:2:15: Da sa jeg i mitt hjerte: Som det går dåren, så vil det også gå mig; hvad skulde det da være til at jeg var så vis? Og jeg sa i mitt kjerte at også dette var tomhet. 
21:2:16: For minnet om den vise vil like så litt vare til evig tid som minnet om dåren; i de kommende dager vil jo alt sammen for lengst være glemt, og må ikke den vise dø like så vel som dåren? 
21:2:17: Da blev jeg lei av livet; for ondt var i mine øine alt som skjer under solen; for alt sammen er tomhet og jag efter vind. 
21:2:18: Og jeg blev lei av alt mitt strev, som jeg hadde møiet mig med under solen, fordi jeg skulde efterlate det til den som kommer efter mig. 
21:2:19: Hvem vet om det blir en vis eller en dåre? Og enda skal han råde over alt det jeg har vunnet ved min møie og min visdom under solen; også det er tomhet. 
21:2:20: Da begynte jeg å bli fortvilet i mitt hjerte over alt det strev som jeg hadde møiet mig med under solen; 
21:2:21: for er det et menneske som har gjort sitt arbeid med visdom og kunnskap og dyktighet, så må han allikevel gi det fra sig til et menneske som ikke har hatt nogen møie med det, som hans eiendom; også dette er tomhet og et stort onde. 
21:2:22: For hvad har mennesket for alt sitt strev og for sitt hjertes attrå, som han møier sig med under solen? 
21:2:23: Alle hans dager er jo fulle av smerte, og all hans umak er bare gremmelse; selv om natten har hans hjerte ikke ro; også dette er tomhet. 
21:2:24: Er det ikke et gode for mennesket at han kan ete og drikke og unne sig gode dager til gjengjeld for sitt strev? Men jeg så at også dette kommer fra Guds hånd; 
21:2:25: for hvem kunde ete og hvem nyte mere enn jeg? 
21:2:26: For det menneske som tekkes ham, gir han visdom og kunnskap og glede; men synderen gir han den umak å sanke og samle for å gi til den som tekkes Gud; også dette er tomhet og jag efter vind. 
21:3:1: Alt har sin tid, og en tid er der satt for hvert foretagende under himmelen. 
21:3:2: Å fødes har sin tid og å dø har sin tid, å plante har sin tid og å rykke op det som er plantet, har sin tid; 
21:3:3: å drepe har sin tid og å læge har sin tid; å rive ned har sin tid og å bygge op har sin tid; 
21:3:4: å gråte har sin tid og å le har sin tid; å klage har sin tid og å danse har sin tid; 
21:3:5: å kaste bort stener har sin tid og å samle stener har sin tid; å ta i favn har sin tid og å holde sig fra favntak har sin tid; 
21:3:6: å søke har sin tid og å tape har sin tid; å gjemme har sin tid og å kaste bort har sin tid; 
21:3:7: å sønderrive har sin tid og å sy sammen har sin tid; å tie har sin tid og å tale har sin tid; 
21:3:8: å elske har sin tid og å hate har sin tid; krig har sin tid og fred har sin tid. 
21:3:9: Hvad vinning har den som gjør noget, av det strev han har med det? 
21:3:10: Jeg så den plage som Gud har gitt menneskenes barn å plage sig med. 
21:3:11: Alt har han gjort skjønt i sin tid; også evigheten har han lagt i deres hjerte, men således at mennesket ikke til fulle kan forstå det verk Gud har gjort, fra begynnelsen til enden. 
21:3:12: Jeg skjønte at de intet annet gode har enn å glede sig og å gjøre sig til gode i livet; 
21:3:13: men når et menneske, hvem det så er, får ete og drikke og unne sig gode dager til gjengjeld for alt sitt strev, så er også det en Guds gave. 
21:3:14: Jeg skjønte at alt hvad Gud gjør, det varer evig; intet kan legges til og intet kan tas fra. Så har Gud gjort det, forat vi skal frykte ham. 
21:3:15: Hvad der er, det var allerede før, og hvad der skal bli, det har også vært før; Gud søker frem igjen det forgangne. 
21:3:16: Fremdeles så jeg under solen at på dommersetet, der satt gudløsheten, og hvor rettferdighet skulde råde, der rådet gudløshet. 
21:3:17: Da sa jeg i mitt hjerte: Gud skal dømme den rettferdige så vel som den gudløse; for hos ham er det fastsatt en tid for hvert foretagende og for alt hvad som gjøres. 
21:3:18: Jeg sa i mitt hjerte: Dette skjer for menneskenes barns skyld, forat Gud kan prøve dem, og forat de kan se at de i sig selv ikke er annet enn dyr; 
21:3:19: for det går menneskenes barn som det går dyrene; den samme skjebne rammer dem; som den ene dør, så dør den andre, og en livsånde har de alle; mennesket har ikke noget fortrin fremfor dyret; for alt er tomhet. 
21:3:20: De farer alle til ett sted; de er alle blitt til av støvet, og de vender alle tilbake til støvet. 
21:3:21: Hvem vet om menneskenes ånd stiger op, og om dyrets ånd farer ned til jorden? 
21:3:22: Og jeg så at intet er bedre for mennesket enn at han gleder sig ved sitt arbeid; for det er det gode som blir ham til del; for hvem lar ham få se det som skal komme efter ham? 
21:4:1: Fremdeles så jeg alle de voldsgjerninger som skjer under solen; jeg så de undertryktes gråt - det var ingen som trøstet dem; jeg så voldsmennene bruke makt mot dem, og det var ingen som trøstet dem. 
21:4:2: Da priste jeg de døde, de som allerede hadde fått dø, lykkelige fremfor de levende, de som ennu var i live, 
21:4:3: men fremfor dem begge priste jeg lykkelig den som ennu ikke er til, som ikke har sett de onde gjerninger som skjer under solen. 
21:4:4: Og jeg så at alt strev og all dyktighet i arbeid har sin grunn i at den enes ærgjerrighet er større enn den andres, også det er tomhet og jag efter vind. 
21:4:5: Dåren legger hendene i fanget og tærer på sitt eget kjøtt. 
21:4:6: Bedre er en håndfull ro enn begge never fulle av strev og jag efter vind. 
21:4:7: Og ennu mere tomhet blev jeg var under solen: 
21:4:8: Stundom står en mann alene og har ingen annen med sig, hverken sønn eller bror, og allikevel er det ingen ende på alt hans strev, og hans øine blir ikke mette av rikdom. Men hvem strever jeg for og nekter mig selv det som godt er? Også det er tomhet, og en ond plage er det. 
21:4:9: Bedre å være to enn én, for de har god lønn for sitt strev; 
21:4:10: om de faller, kan den ene reise sin stallbror op; men stakkars den som er alene, for faller han, har han ingen til å reise sig op! 
21:4:11: Likeledes når to ligger sammen, så blir de varme; men hvorledes kan den som ligger alene, bli varm? 
21:4:12: Og om nogen kan vinne over den som er alene, så kan to holde stand mot ham, og en tredobbelt tråd sønderrives ikke så snart. 
21:4:13: Bedre å være en fattig og vis ungdom enn en gammel dåre av en konge, som ikke mere har forstand nok til å la sig advare; 
21:4:14: for fra fengslet kommer den ene ut og blir konge*; den andre blir fattig, enda han er født i sitt kongedømme. # <* som Josef; 1MO 41, 41 fg.> 
21:4:15: Jeg så alle de levende som ferdes under solen, følge med den unge mann, han den nye, som skulde trede i den gamles* sted. # <* kongens.> 
21:4:16: Det var ingen ende på alt det folk han var fører for. Allikevel har efterkommerne ingen glede av ham, for også dette er tomhet og jag efter vind. 
21:4:17: Vokt din fot når du går til Guds hus! Å komme dit for å høre er bedre enn når dårene bærer frem offer; for de vet ikke at de gjør ondt. 
21:5:1: Vær ikke for snar med din munn, og la ikke ditt hjerte forhaste sig med å bære frem et ord for Guds åsyn! For Gud er i himmelen og du på jorden; la derfor dine ord være få! 
21:5:2: For av meget strev og kav kommer drømmer, og med for mange ord følger dårlig tale. 
21:5:3: Når du gjør Gud et løfte, så dryg ikke med å holde det, for han har ikke behag i dårer! Hold det du lover! 
21:5:4: Bedre er det at du ikke lover, enn at du lover og ikke holder det. 
21:5:5: La ikke din munn føre synd over ditt legeme og si ikke til Guds sendebud*: Det var av vanvare jeg gjorde det! Hvorfor skal Gud harmes over din tale og ødelegge dine henders verk? # <* d.e. presten; MLK 2, 7.> 
21:5:6: For hvor det er mange drømmer, er det også megen tomhet, og likeså hvor det er mange ord. Frykt heller Gud! 
21:5:7: Om du ser at den fattige undertrykkes, og at rett og rettferdighet tredes under føtter i landet, så undre dig ikke over den ting! For den som er høitstående, har en høiere til å vokte på sig, og en høieste vokter på dem begge. 
21:5:8: Og en velsignelse for et land er det med alt dette at det har en konge som folket lyder. 
21:5:9: Den som elsker penger, blir ikke mett av penger, og den som elsker rikdom, får aldri nok; også det er tomhet. 
21:5:10: Jo mere gods dess flere til å fortære det; og hvad gagn har dets eier av det, annet enn at han får se det? 
21:5:11: Arbeiderens søvn er søt, enten han eter lite eller meget; men den rikes metthet lar ham ikke få sove. 
21:5:12: Der er et stort onde, som jeg har sett under solen: rikdom gjemt av sin eier til hans egen ulykke. 
21:5:13: Går denne rikdom tapt ved et uhell, og han har fått en sønn, så blir det intet igjen for ham. 
21:5:14: Som han kom ut av mors liv, skal han igjen gå bort naken som han kom; og ved sitt strev vinner han ikke noget som han kunde ta med sig. 
21:5:15: Også dette er et stort onde: Aldeles som han kom, skal han gå bort; hvad vinning har han da av at han gjør sig møie bort i været? 
21:5:16: Dessuten eter han alle sine dager sitt brød i mørket, og megen gremmelse har han og sykdom og vrede. 
21:5:17: Se, dette er det jeg har funnet godt og skjønt: å ete og drikke og å gjøre sig til gode til gjengjeld for alt det strev som en møier sig med under solen alle de levedager som Gud gir ham; for det er det gode som blir ham til del. 
21:5:18: Og når Gud gir et menneske rikdom og skatter og setter ham i stand til å nyte godt av det og ta det som blir ham til del, og glede sig i sitt strev, så er det en Guds gave; 
21:5:19: for da vil han ikke tenke så meget på sine levedager, fordi Gud svarer ham med å gi ham glede i hjertet. 
21:6:1: Det er en ulykke som jeg har sett under solen, og som hviler tungt på mennesket: 
21:6:2: Når Gud gir en mann rikdom og skatter og ære, så han for sin del ikke fattes noget som han attrår, men Gud ikke setter ham i stand til å nyte godt av det, men en fremmed mann får nyte det, så er det tomhet og en ond lidelse. 
21:6:3: Om en mann får hundre barn og lever i mange år, så tallet på hans levedager blir stort, men hans sjel ikke mettes av det gode, og han heller ikke får nogen jordeferd, da sier jeg: Et ufullbåret foster er bedre faren enn han. 
21:6:4: For som et intet kom det til verden, og i mørke går det bort, og med mørke blir dets navn skjult, 
21:6:5: og det har hverken sett eller kjent solen; det har mere ro enn han. 
21:6:6: Og om han så hadde levd tusen år to ganger, men ikke nytt noget godt - går ikke alt* til ett sted? # <* JBS 30, 23.> 
21:6:7: Alt menneskets strev er for hans munn, og allikevel blir hans attrå aldri tilfredsstilt; 
21:6:8: for hvad fortrin har den vise fremfor dåren? Hvad fortrin har den fattige som vet å skikke sig blandt de levende? 
21:6:9: Bedre er det at øinene dveler ved det en har, enn at sjelen farer urolig om; også det er tomhet og jag efter vind. 
21:6:10: Hvad der er blitt til, er for lenge siden nevnt med navn*, og det er kjent hvad et menneske skal bli; han kan ikke gå i rette med den som er sterkere enn han; # <* av Gud.> 
21:6:11: for der er mange ord som bare øker tomheten - hvad gagn har mennesket av det? 
21:6:12: For hvem vet hvad som gagner et menneske i livet, i alle hans tomme levedager, dem han tilbringer som en skygge? For hvem kan si et menneske hvad som skal hende under solen efter hans tid? 
21:7:1: Bedre er et godt navn enn god olje, og bedre dødsdagen enn den dag en blir født. 
21:7:2: Bedre er det å gå til sørgehus enn til gjestebudshus, fordi i sørgehuset ender hvert menneskes liv, og den som lever, legger sig det på hjerte. 
21:7:3: Bedre er gremmelse enn latter; for mens ansiktet er sørgmodig, er hjertet vel til mote. 
21:7:4: De vises hjerte er i sorgens hus, men dårenes hjerte i gledens hus. 
21:7:5: Bedre er det å høre skjenn av en vis enn å høre sang av dårer; 
21:7:6: for som tornene spraker under gryten, så er det når dåren ler; også dette er tomhet. 
21:7:7: For urettmessig vinning gjør den vise til dåre, og bestikkelse ødelegger hjertet. 
21:7:8: Bedre er enden på en ting enn begynnelsen, bedre å være tålmodig enn overmodig. 
21:7:9: Vær ikke for hastig i din ånd til å vredes; for vreden bor i dårers barm. 
21:7:10: Si ikke: Hvorav kommer det at de fremfarne dager var bedre enn de som nu er? For det er ikke av visdom du spør om det. 
21:7:11: Visdom er jevngod med arvegods, ja ennu ypperligere for dem som ser solen; 
21:7:12: for å være i visdommens skygge er som å være i skyggen av rikdom, men kunnskapens fortrin er at visdommen holder sin eier i live. 
21:7:13: Se på Guds verk! For hvem kan gjøre rett det som han har gjort kroket? 
21:7:14: På en god dag skal du være ved godt mot, og på en ond dag skal du tenke på at Gud har gjort den og, like så vel som den andre, forat mennesket ikke skal finne noget efter sig*. # <* d.e. ikke skal vite noget av hvad som skal skje efter ham.> 
21:7:15: Alt dette har jeg sett i mitt tomme liv: Mangen rettferdig går til grunne tross sin rettferdighet, og mangen ugudelig lever lenge tross sin ondskap. 
21:7:16: Vær ikke altfor rettferdig og te dig ikke overvettes vis! hvorfor vil du ødelegge dig selv*? # <* nemlig ved fariseisk egenrettferdighet og selvklokskap; LUK 18, 11.> 
21:7:17: Vær ikke altfor urettferdig*, og vær ikke en dåre! Hvorfor vil du dø før tiden? # <* All synd kan du vel ikke undgå (FRK 7, 20. 1KG 8, 46. JAK 3, 2.), men tro ikke derfor at du kan overgi dig til det onde.> 
21:7:18: Det er godt at du holder fast ved det ene, men du skal heller ikke slippe det andre; for den som frykter Gud, finner en utvei av alt dette. 
21:7:19: Visdommen er et sterkere vern for den vise enn ti mektige menn i en by; 
21:7:20: for det finnes ikke et rettferdig menneske på jorden, som bare gjør godt og aldri synder. 
21:7:21: Akt heller ikke på alt det folk sier, ellers kunde du få høre din tjener banne dig! 
21:7:22: For du vet jo med dig selv at også du mange ganger har bannet andre. 
21:7:23: Alt dette har jeg prøvd med visdom; Jeg sa: Jeg vil vinne visdom, men den er ennu langt borte fra mig. 
21:7:24: Det som er langt borte og dypt, dypt skjult - hvem kan finne det? 
21:7:25: Jeg så mig om, og min attrå var å vinne kunnskap og å granske og søke efter visdom og klokskap og å forstå at ugudelighet er dårskap, og at dårskapen er galskap. 
21:7:26: Og jeg fant noget som er bitrere enn døden: kvinnen - hun er et garn og hennes hjerte en snare, og hennes hender er lenker; den som tekkes Gud, slipper fra henne, men synderen blir fanget av henne. 
21:7:27: Se, dette fant jeg ut, sier predikeren, idet jeg la det ene til det andre for å finne hovedsummen. 
21:7:28: Det som jeg stadig har søkt, men ikke har funnet, det er: En mann har jeg funnet blandt tusen, men en kvinne har jeg ikke funnet blandt dem alle. 
21:7:29: Se, dette er det eneste jeg har funnet ut, at Gud skapte mennesket som det skulde være, men de søker mange kunster. 
21:8:1: Hvem er som den vise, og hvem forstår å tyde tingene? Visdommen gjør menneskets ansikt lyst, og trossen i hans åsyn forvandles. 
21:8:2: Jeg sier: Akt på kongens bud, og det for den eds skyld som du har svoret ved Gud! 
21:8:3: Vær ikke for snar til å forlate ham, og vær ikke med på noget som er ondt! For han gjør alt det han vil. 
21:8:4: For kongens ord er mektig, og hvem tør si til ham: Hvad gjør du? 
21:8:5: Den som holder budet, skal ikke lide noget ondt, og den vises hjerte skal få kjenne tid og dom. 
21:8:6: For hvert foretagende har sin tid og sin dom; for hvert menneskes onde gjerning kommer til å hvile tungt på ham; 
21:8:7: for han vet ikke hvad som skal hende; for hvem kan si ham hvorledes det vil gå? 
21:8:8: Intet menneske har makt over vinden, så han kan holde den tilbake; heller ikke har nogen makt over dødsdagen, og ingen slipper for å gjøre krigstjeneste. Således kan heller ikke ugudelighet berge sin mann. 
21:8:9: Alt dette har jeg sett, og jeg har gitt akt på alt det som hender under solen, på en tid da det ene menneske hersket over det andre og voldte ham ulykke. 
21:8:10: Derefter så jeg at ugudelige blev jordfestet og kom til hvile, men at de som hadde gjort rett, måtte dra bort fra den Helliges bolig og blev glemt i staden. Også dette er tomhet. 
21:8:11: Fordi dommen over den onde gjerning ikke fullbyrdes straks, derfor svulmer hjertet i menneskenes barn, så de drister sig til å gjøre det som ondt er, 
21:8:12: fordi synderen hundre ganger gjør det som ondt er, og allikevel lever lenge; dog vet jeg jo at det skal gå gudfryktige vel, fordi de frykter Gud, 
21:8:13: men at det ikke skal gå den ugudelige vel, og at han lik skyggen ikke skal leve lenge, fordi han ikke frykter Gud. 
21:8:14: Der er noget tomt som hender på jorden: at der er rettferdige hvem det går som om de hadde gjort de ugudeliges gjerninger, og at der er ugudelige hvem det går som om de hadde gjort de rettferdiges gjerninger. Jeg sier at også dette er tomhet. 
21:8:15: Så priste jeg da gleden, fordi mennesket ikke har noget annet godt under solen enn å ete og drikke og være glad; og dette følger ham under hans strev i de levedager som Gud har gitt ham under solen. 
21:8:16: Da jeg vendte min hu til å lære visdom å kjenne og til å se på det strev og kav folk har her på jorden - for hverken dag eller natt får de søvn på sine øine - 
21:8:17: da så jeg at det er så med alt Guds verk at mennesket ikke kan utgrunde det som hender under solen; for hvor meget et menneske enn strever med å utgranske det, kan han dog ikke utgrunde det, og selv om den vise sier at han nok skal forstå det, er han dog ikke i stand til å utgrunde det. 
21:9:1: For alt dette har jeg tatt mig til hjerte, og jeg har søkt å utgrunde alt dette: at de rettferdige og de vise og deres gjerninger er i Guds hånd; om det er kjærlighet eller hat, vet intet menneske*; de kan vente sig alt. # <* Ingen kan av de ytre omstendigheter med sikkerhet slutte sig til Guds velbehag eller mishag med ham selv eller andre.> 
21:9:2: Det går dem* i alt likesom alle andre; det samme hender den rettferdige og den ugudelige, den gode og rene og den urene, den som ofrer og den som ikke ofrer; den gode går det som synderen, den som sverger, går det som den som er redd for å sverge. # <* de rettferdige og vise.> 
21:9:3: Det er en ond ting ved alt det som hender under solen, at det går alle likedan, og så er også menneskenes hjerte fullt av det som ondt er, og det er uforstand i deres hjerte, så lenge de lever, og siden går det avsted til de døde; 
21:9:4: for hvem er det vel som slipper? For alle dem som lever, er det håp; for en levende hund er bedre enn en død løve; 
21:9:5: for de levende vet at de skal dø, men de døde vet ikke nogen ting, og de får ikke lenger nogen lønn, for minnet om dem er glemt. 
21:9:6: Både deres kjærlighet og deres hat og deres ærgjerrighet er det for lenge siden forbi med, og de har i all evighet ingen del mere i alt det som hender under solen. 
21:9:7: Så et da ditt brød med glede og drikk vel til mote din vin! For Gud har for lenge siden godkjent det du gjør. 
21:9:8: La dine klær alltid være hvite, og la ikke olje fattes på ditt hode! 
21:9:9: Nyt livet med en hustru som du elsker, alle dager i ditt tomme liv, som Herren har gitt dig under solen, alle dine tomme dager! For det er din del i livet og i ditt strev som du møier dig med under solen. 
21:9:10: Alt det din hånd er i stand til å gjøre med din kraft, det skal du gjøre! For det finnes hverken gjerning eller klokskap eller kunnskap eller visdom i dødsriket, dit du går. 
21:9:11: Fremdeles så jeg under solen at det ikke er visst at de som er lette på foten, vinner i løpet, eller at heltene seirer i krigen, eller at de vise kan få sig brød, eller at de forstandige kan vinne rikdom eller de kloke finne yndest; for tid og hendelse møter dem alle. 
21:9:12: For mennesket kjenner like så lite sin tid som fiskene som fanges i det onde garn, eller fuglene som blir fanget i snaren; likesom de fanges også menneskenes barn i en ond tid, når den med ett kommer over dem. 
21:9:13: Også i dette så jeg visdom under solen, og stor syntes den mig: 
21:9:14: Det var en liten by med få folk i; til den kom det en stor konge og kringsatte den og bygget store voller mot den; 
21:9:15: men det fantes i byen en fattig, vis mann, og han berget den ved sin visdom, og ikke et menneske var kommet denne fattige mann i hu. 
21:9:16: Da sa jeg: Visdom er bedre enn styrke; men den fattiges visdom er foraktet, og folk hører ikke på det han sier. 
21:9:17: De vises ord som høres i ro, er bedre enn rop fra en hersker blandt dårene. 
21:9:18: Visdom er bedre enn krigsvåben, men en synder kan ødelegge meget godt. 
21:10:1: Giftige fluer får salvelagerens salve til å lukte ille og gjære; endog et lite grand dårskap ødelegger en mann som utmerker sig ved visdom eller ære. 
21:10:2: Den vises hu er vendt til høire, men dårens hu til venstre*. # <* høire og venstre er her å forstå om det rette og det vrange.> 
21:10:3: Og hvor som helst dåren ferdes, fattes det ham forstand, og han lar alle merke at han er en dåre. 
21:10:4: Reiser herskerens vrede sig mot dig, så forlat ikke din post! For saktmodighet holder store synder nede. 
21:10:5: Der er et onde som jeg har sett under solen - et misgrep som utgår fra makthaveren: 
21:10:6: Dårskapen sitter i høie stillinger, mens fornemme folk må sitte lavt. 
21:10:7: Jeg har sett tjenere ride på hester og fyrster gå til fots som tjenere. 
21:10:8: Den som graver en grav, kan falle i den, og den som river et gjerde, kan bli bitt av en orm. 
21:10:9: Den som bryter sten, kan få en skade av det; den som hugger ved, kan komme i fare ved det. 
21:10:10: Når øksen er sløv, og han ikke har slipt eggen, så må han bruke dess større kraft; men visdom har den fordel at den gjør alt på rette måte. 
21:10:11: Når ormen biter uten besvergelse*, har tungens eier** ingen nytte av den. # <* før besvergelsen har funnet sted; SLM 58, 6.> # <** besvergeren.> 
21:10:12: Ord fra den vises munn er liflige, men dårens leber ødelegger ham selv. 
21:10:13: De første ord av hans munn er dårskap, og enden på hans tale er farlig galskap. 
21:10:14: Dåren taler mange ord, enda mennesket ikke vet hvad som skal hende, og hvem sier ham hvad som skal hende efter hans tid? 
21:10:15: Dårens strev tretter ham, han som ikke engang vet veien til byen. 
21:10:16: Ve dig, du land som har et barn til konge, og hvis fyrster holder måltid om morgenen! 
21:10:17: Lykkelige land som har en konge av edel ætt, og hvis fyrster holder måltid i sømmelig tid, som menn og ikke som drankere! 
21:10:18: Når latheten råder, synker bjelkene sammen, og lar en hendene henge, så drypper det inn i huset. 
21:10:19: For å more sig holder de* gjestebud, og vinen legger glede over livet; alt sammen fås for penger. <* FRK 10, 16.> 
21:10:20: Ikke engang i dine tanker må du banne kongen, og ikke engang i ditt sengkammer må du banne den rike; for himmelens fugler bærer lyden avsted, og de vingede skapninger melder dine ord. 
21:11:1: Send ditt brød bort over vannet; for i tidens løp skal du finne det igjen. 
21:11:2: Del ut til syv, ja til åtte; for du vet ikke hvad ulykke som kan hende på jorden. 
21:11:3: Når skyene blir fulle av regn, tømmer de det ut over jorden, og når et tre faller, enten det er mot syd eller mot nord, så blir treet liggende på det sted hvor det falt. 
21:11:4: Den som stadig akter på vinden, kommer ikke til å så, og den som stadig ser på skyene, kommer ikke til å høste. 
21:11:5: Like så lite som du vet hvad vei vinden farer, eller hvorledes benene dannes i den fruktsommelige kvinnes liv, like så lite vet du hvad Gud vil gjøre, han som gjør det alt sammen. 
21:11:6: Så din sæd om morgenen, og la ikke din hånd hvile når det lider mot aftenen; for du vet ikke hvad som vil lykkes, det ene eller det andre, eller om begge deler er gode. 
21:11:7: Schmelken er liflig, og det er godt for øinene å få se solen. 
21:11:8: For om et menneske lever mange år, skal han glede sig i dem alle og komme mørkets dager i hu; for de blir mange. Alt som skal komme, er tomhet. 
21:11:9: Gled dig, du unge, i din ungdom og la ditt hjerte være vel til mote i din ungdoms dager og vandre på ditt hjertes veier og efter det dine øine ser! Men vit at for alt dette vil Gud føre dig frem for dommen. 
21:11:10: La gremmelse vike fra ditt hjerte og hold alt ondt borte fra ditt legeme! For ungdom og morgenrøde er tomhet. 
21:12:1: Tenk på din skaper i din ungdoms dager, før de onde dager kommer, og det lider mot de år hvorom du vil si: Jeg har ingen glede av dem, 
21:12:2: før solen og schmelken og månen og stjernene formørkes, og skyene kommer igjen efter regnet - 
21:12:3: den tid da husets voktere skjelver, og de sterke menn blir krokete, og de som maler på kvernen, stanser sitt arbeid, fordi de er blitt for få, og de som ser ut gjennem vinduene, formørkes*, <* 1MO 27, 1; 48, 10. 1KG 14, 4.> 
21:12:4: og begge dørene til gaten stenges, mens kvernduren blir svak og ikke når høiere enn til spurvekvitter, og alle sangmøene blir lavmælte, 
21:12:5: og en frykter for hver bakke, og det lurer skremsler på veien, og mandeltreet blomstrer, og gresshoppen sleper sig frem, og kapersen mister sin kraft; for mennesket drar bort til sin evige bolig, og de sørgende går allerede og venter på gaten - 
21:12:6: før sølvsnoren tas bort, og gullskålen slåes i stykker, og krukken brytes sønder ved kilden, og hjulet knuses og faller ned i brønnen, 
21:12:7: og støvet vender tilbake til jorden og blir som det var før, og ånden vender tilbake til Gud, som gav den. 
21:12:8: Bare idelig tomhet, sier predikeren; alt er tomhet. 
21:12:9: For øvrig er å si at predikeren var en vismann, og at han også lærte folket kunnskap og prøvde og gransket; han laget mange ordsprog. 
21:12:10: Predikeren søkte å finne liflige ord, og skrevet er her hvad riktig er, sannhets ord. 
21:12:11: De vises ord er som brodder, og visdomssprog som er samlet, sitter fast som nagler; de er gitt av én hyrde* <* SLM 80, 2.> 
21:12:12: Og for øvrig: La dig advare, du min sønn! Det er ingen ende på all bokskrivningen, og megen granskning tretter legemet. 
21:12:13: Enden på det hele, efterat alt er hørt, er dette: Frykt Gud og hold hans bud! Det er hvad hvert menneske bør gjøre. 
21:12:14: For hver gjerning vil Gud føre frem for dommen over alt som er skjult, enten det er godt eller ondt. 
22:1:1: Høisangen av Salomo. 
22:1:2: Han kysser mig med kyss av sin munn! For din kjærlighet er bedre enn vin. 
22:1:3: Liflig er duften av dine salver, ditt navn er en utgytt salve; derfor elsker jomfruene dig. 
22:1:4: Drag mig! Vi vil løpe efter dig. Kongen har ført mig inn i sine kammer; vi vil fryde og glede oss i dig, vi vil prise din kjærlighet mere enn vin; opriktig elsker de dig. 
22:1:5: Sort er jeg, men yndig, I Jerusalems døtre, som Kedars telter, som Salomos telttepper. 
22:1:6: Se ikke på mig, fordi jeg er så sort, fordi solen har brent mig! Min mors sønner blev vrede på mig, de satte mig til å vokte vingårdene; min egen vingård har jeg ikke voktet. 
22:1:7: Si mig, du som min sjel elsker: Hvor vokter du hjorden? Hvor lar du den hvile om middagen? For hvorfor skal jeg være lik en kvinne som går tilsløret ved dine stallbrødres hjorder? 
22:1:8: Vet du det ikke, du fagreste blandt kvinner, så gå ut i fårenes spor og vokt dine kje ved hyrdenes hytter! 
22:1:9: Med gangerne foran Faraos vogner ligner jeg dig, min venninne! 
22:1:10: Yndige er dine kinner mellem kjedene, din hals i perleradene. 
22:1:11: Gullkjeder vil vi gjøre dig med sølvprikker på. 
22:1:12: Så lenge kongen satt ved sitt bord, gav min nardus sin duft. 
22:1:13: Min elskede er mig en myrrakule, som hviler mellem mine bryster. 
22:1:14: Min elskede er mig en cyperdrue i En-Gedis vingårder. 
22:1:15: Hvor fager du er, min venninne, hvor fager du er! Dine øine er duer. 
22:1:16: Hvor du er vakker, min elskede, hvor skjønn du er! Og vårt leie er grønt. 
22:1:17: Sedrer er bjelkene i vårt hus, cypresser er vårt tavlede loft. 
22:2:1: Jeg er Sarons blomst, dalenes lilje. 
22:2:2: Som en lilje blandt torner, så er min venninne blandt de unge kvinner. 
22:2:3: Som et epletre blandt skogens trær, så er min elskede blandt de unge menn; i hans skygge lyster det mig å sitte, og hans frukt er søt for min gane. 
22:2:4: Han har ført mig til vinhuset, og hans banner over mig er kjærlighet. 
22:2:5: Styrk mig med druekaker, kveg mig med epler! For jeg er syk av kjærlighet. 
22:2:6: Hans venstre hånd er under mitt hode, og hans høire favner mig. 
22:2:7: Jeg ber eder inderlig, I Jerusalems døtre, ved rådyrene eller ved hindene på marken, at I ikke vekker og ikke egger kjærligheten, før den selv vil! 
22:2:8: Hør, der er min elskede! Se, der kommer han springende over fjellene, hoppende over haugene. 
22:2:9: Min elskede ligner et rådyr eller en ung hjort. Se, der står han bak vår vegg; han kikker gjennem vinduene, gjennem gitteret ser han inn. 
22:2:10: Min elskede tar til orde og sier til mig: Stå op, min venninne, du min fagre, og kom ut! 
22:2:11: For se, nu er vinteren omme, regnet har draget forbi og er borte; 
22:2:12: blomstene kommer til syne i landet, sangens tid er inne, og turtelduens røst har latt sig høre i vårt land; 
22:2:13: fikentreets frukter tar til å rødme, og vintrærnes blomster dufter. Stå op, kom, min venninne, du min fagre, så kom da! 
22:2:14: Du min due i bergrevnene, i fjellveggens ly! La mig se din skikkelse, la mig høre din røst! For din røst er blid og din skikkelse fager. 
22:2:15: Fang revene for oss, de små rever som ødelegger vingårdene! Våre vingårder står jo i blomst. 
22:2:16: Min elskede er min, og jeg er hans, han som vokter sin hjord blandt liljene. 
22:2:17: Innen dagen blir sval og skyggene flyr, vend om, min elskede, lik et rådyr eller en ung hjort på de kløftede fjell! 
22:3:1: På mitt leie lette jeg om natten efter ham som min sjel elsker; jeg lette efter ham, men jeg fant ham ikke. 
22:3:2: [Da sa jeg:] Jeg vil stå op og gå omkring i byen, på gatene og på torvene; jeg vil lete efter ham som min sjel elsker. Jeg lette efter ham, men jeg fant ham ikke. 
22:3:3: Vekterne, som går omkring i byen, møtte mig. [Jeg sa til dem:] Har I sett ham som min sjel elsker? 
22:3:4: Ikke før var jeg gått fra dem, før jeg fant ham som min sjel elsker; jeg tok fatt i ham, og jeg slipper ham ikke før jeg har ført ham til min mors hus, til hennes kammer som har født mig. 
22:3:5: Jeg ber eder inderlig, I Jerusalems døtre, ved rådyrene eller ved hindene på marken, at I ikke vekker og ikke egger kjærligheten, før den selv vil! 
22:3:6: Hvem er hun som kommer op fra ørkenen, som det var røkstøtter, omduftet av myrra og virak, av alle kremmerens krydderier? 
22:3:7: Se, her er Salomos seng med seksti krigsmenn omkring, av Israels krigsmenn, 
22:3:8: alle væbnet med sverd, opøvet i krig, hver med sverdet ved lend til vern mot nattens farer. 
22:3:9: En bæreseng gjorde kong Salomo sig av Libanons trær. 
22:3:10: Dens stolper gjorde han av sølv, dens ryggstø av gull, dens pute av purpur; innvendig er den smykket i kjærlighet av Jerusalems døtre. 
22:3:11: Gå ut, I Sions døtre, og se på kong Salomo med den krone hans mor har kronet ham med på hans bryllupsdag, på hans hjertegledes dag! 
22:4:1: Hvor fager du er, min venninne, hvor fager du er! Dine øine er duer bak ditt slør; ditt hår er som en hjord av gjeter som leirer sig nedover Gilead-fjellet. 
22:4:2: Dine tenner er som en hjord av klippede får som stiger op av badet; alle har de tvillinger, og intet blandt dem er uten lam. 
22:4:3: Dine leber er som en skarlagensnor, og din munn er yndig; som et stykke granateple er din tinning bak ditt slør. 
22:4:4: Din hals er som Davids tårn, bygget til våbenhus; tusen skjold henger på det, alle krigsmennenes skjold. 
22:4:5: Dine bryster er som to rådyrkalver, tvillinger av et rådyr, som beiter blandt liljer. 
22:4:6: Når dagen blir sval og skyggene flyr, vil jeg gå til Myrra-åsen og til Virak-haugen. 
22:4:7: Alt er fagert ved dig, min venninne, og det er intet lyte på dig. 
22:4:8: Kom med mig fra Libanon, min brud, kom med mig fra Libanon! Sku ut fra Amanas topp, fra toppen av Senir og Hermon, fra løvenes boliger, fra panternes fjell! 
22:4:9: Du har vunnet mitt hjerte, min søster, min brud! Du har vunnet mitt hjerte med et eneste øiekast, med en av kjedene om din hals. 
22:4:10: Hvor liflig din kjærlighet er, min søster, min brud! Hvor meget bedre er ikke din kjærlighet enn vin, og duften av dine salver bedre enn alle velluktende urter! 
22:4:11: Av honning drypper dine leber, min brud! Honning og melk er under din tunge, og duften av dine klær er som duften av Libanon. 
22:4:12: En lukket have er min søster, min brud, et avstengt vell, en forseglet kilde. 
22:4:13: Du skyter op som en lysthave av granatepletrær med sin kostelige frukt, som cyperbusker og narder, 
22:4:14: nardus og safran, kalmus og kanel med alle slags viraktrær, myrra og aloëtrær og alle de beste velluktende urter. 
22:4:15: En kilde i havene er du, en brønn med levende vann og strømmer fra Libanon. 
22:4:16: Våkn op, nordenvind, og kom, sønnenvind! Blås gjennem min have, så dens duft kan strømme ut! Gid min elskede vilde komme til sin have og ete dens kostelige frukt! 
22:5:1: Jeg er kommet til min have, min søster, min brud! Jeg har plukket min myrra og min balsam, jeg har ett min honningkake og min honning, jeg har drukket min vin og min melk. Et, venner, og drikk! Drikk eder lystige, mine elskede! 
22:5:2: Jeg sover, men mitt hjerte våker; da lyder min elskedes røst - han banker på: Lukk op for mig, min søster, min venninne, min due, du rene! For mitt hode er fullt av dugg, mine lokker av nattens dråper. 
22:5:3: [Jeg sa:] Jeg har tatt av mig min kjortel, skulde jeg da ta den på igjen? Jeg har tvettet mine føtter, skulde jeg da skitne dem til? 
22:5:4: Min elskede rakte sin hånd inn gjennem luken; da blev mitt hjerte rørt for hans skyld. 
22:5:5: Jeg stod op for å lukke op for min elskede, og mine hender dryppet av myrra, mine fingrer av flytende myrra, som vætte låsens håndtak. 
22:5:6: Jeg lukket op for min elskede, men min elskede hadde vendt om og gått bort; min sjel var ute av sig selv over hans ord; jeg lette efter ham, men fant ham ikke; jeg ropte på ham, men han svarte mig ikke. 
22:5:7: Vekterne, som går omkring i byen, møtte mig, de slo mig, de såret mig; de tok mitt slør fra mig, vekterne på murene. 
22:5:8: Jeg ber eder inderlig, I Jerusalems døtre! Om I finner min elskede, hvad skal I si ham? - At jeg er syk av kjærlighet. 
22:5:9: Hvad er din elskede fremfor andre elskede, du fagreste blandt kvinner, hvad er din elskede fremfor andre elskede, siden du ber oss så inderlig? 
22:5:10: Min elskede er hvit og rød, utmerket fremfor ti tusen. 
22:5:11: Hans hode er som det fineste gull; hans lokker er kruset, sorte som ravnen. 
22:5:12: Hans øine er som duer ved rinnende bekker; de bader sig i melk, de hviler i sin ramme. 
22:5:13: Hans kinner er som velluktende blomstersenger, som det vokser krydderurter i; hans leber er som liljer, de drypper av flytende myrra. 
22:5:14: Hans hender er gullringer med innlagte krysolitter, hans midje er et kunstverk av elfenben, dekket med safirer. 
22:5:15: Hans ben er marmorstøtter, som står på fotstykker av fineste gull; hans skikkelse er som Libanon, herlig som sedrene. 
22:5:16: Hans gane er sødme, og alt ved ham er liflighet. Slik er min elskede, slik er min venn, I Jerusalems døtre! 
22:6:1: Hvor er din elskede gått hen, du fagreste blandt kvinner, hvor har din elskede tatt veien, så vi kan lete efter ham sammen med dig? 
22:6:2: Min elskede er gått ned til sin have, til de velluktende blomstersenger, for å vokte sin hjord i havene og for å sanke liljer. 
22:6:3: Jeg tilhører min elskede, og min elskede tilhører mig, han som vokter sin hjord blandt liljene. 
22:6:4: Du er fager som Tirsa*, min venninne, skjønn som Jerusalem, fryktelig som hærskarer med sine banner. # <* 1KG 14, 17; 15, 21.> 
22:6:5: Vend dine øine bort fra mig for de forferder mig! Ditt hår er som en hjord av gjeter som leirer sig nedover Gilead. 
22:6:6: Dine tenner er som en hjord av får som stiger op av badet; alle har de tvillinger, og intet blandt dem er uten lam. 
22:6:7: Som et stykke granateple er din tinning bak ditt slør. 
22:6:8: Seksti dronninger har jeg og åtti medhustruer og unge piker uten tall. 
22:6:9: Men én er min due, min rene, hun, sin mors eneste, hennes utkårede som fødte henne; jomfruer så henne og priste henne lykkelig, dronninger og medhustruer så henne og hyldet henne. 
22:6:10: Hvem er hun som stråler frem som morgenrøden, fager som månen, ren som solen, fryktelig som hærskarer med sine banner? 
22:6:11: Jeg gikk ned i nøttehaven for å se på dalens grønnende spirer, for å se om vintreet hadde satt skudd, om granatepletrærne stod i blomst. 
22:6:12: Før jeg visste av det, førte min sjel mig op på mitt gjæve folks vogner. 
22:6:13: Vend om, vend om, Sulammit! Vend om, vend om, så vi får se på dig! - Hvad vil I se på Sulammit? - En dans som i Mahana'im! 
22:7:1: Hvor fagert du skrider frem i dine sko, du høvdingdatter! Dine lenders rundinger er som smykker, et verk av kunstnerhånd. 
22:7:2: Ditt liv er som et rundt beger; gid det aldri må være uten vin! Din midje er som en hvetedynge, omhegnet av liljer. 
22:7:3: Dine bryster er som to rådyrkalver, tvillinger av et rådyr. 
22:7:4: Din hals er som elfenbenstårnet, dine øine som vanndammene i Hesbon ved Batrabbims port, din nese som Libanon-tårnet, som skuer ut mot Damaskus. 
22:7:5: Ditt hode hever sig likesom Karmel; ditt bølgende hår er som purpur; kongen er fanget i dine lokker. 
22:7:6: Hvor fager du er, og hvor herlig du kjærlighet i din fryd! 
22:7:7: Med din ranke vekst ligner du en palme, og dine bryster er som druer. 
22:7:8: Jeg sier: Jeg vil stige op i palmetreet, ta fatt i dets grener. Måtte dine bryster være som vintreets druer, din ånde som duften av epler 
22:7:9: og din gane som edel vin! - * Den som glir lett ned for min elskede, som får sovendes leber til å tale. # <* brudens ord.> 
22:7:10: Jeg hører min elskede til, og til mig står hans attrå. 
22:7:11: Kom, min elskede, la oss gå ut på marken, la oss bli natten over i landsbyene! 
22:7:12: La oss årle gå til vingårdene, la oss se om vintreet har satt skudd, om blomstene er sprunget ut, om granatepletrærne blomstrer! Der vil jeg gi dig min kjærlighet. 
22:7:13: Alrunene dufter, og over våre dører er alle slags kostelige frukter, både nye og gamle. Min elskede! Jeg har gjemt dem til dig. 
22:8:1: Gid du var mig som en bror, ammet ved min mors bryst! Om jeg da fant dig der ute, skulde jeg kysse dig, og ingen skulde forakte mig for det. 
22:8:2: Jeg skulde lede dig, jeg skulde føre dig til min mors hus, du skulde lære mig; jeg skulde skjenke dig den krydrede vin, mosten av mitt granatepletre. 
22:8:3: Hans venstre hånd er under mitt hode, og hans høire hånd favner mig. 
22:8:4: Jeg ber eder inderlig, I Jerusalems døtre! Hvorfor vil I vekke, og hvorfor vil I egge kjærligheten, før den selv vil? 
22:8:5: Hvem er hun som kommer op fra ørkenen, støttende sig på sin elskede? - Under epletreet vekket jeg dig; der blev din mor forløst med dig, der blev hun forløst, hun som fødte dig. 
22:8:6: Sett mig som et segl på ditt hjerte, som et segl på din arm; for sterk som døden er kjærligheten, hård som dødsriket er dens nidkjærhet; dens glød er som ildens glød, en Herrens lue. 
22:8:7: Mange vann kan ikke utslukke kjærligheten, og strømmer ikke overskylle den; om nogen vilde gi alt han har i sitt hus, for kjærligheten, vilde han bare bli foraktet. 
22:8:8: Vi har en liten søster, hun har ennu ikke bryster. Hvad skal vi gjøre med vår søster når den tid kommer at hun får beilere? 
22:8:9: Er hun en mur, vil vi bygge på den tinder av sølv; er hun en dør, vil vi stenge den med en sederplanke. 
22:8:10: Jeg var en mur, og mine bryster som tårner; da vant jeg yndest for hans øine og fikk fred. 
22:8:11: En vingård hadde Salomo i Ba'al-Hamon, han overgav vingården til voktere; hver skulde gi tusen sekel sølv for dens frukt. 
22:8:12: Over min vingård råder jeg selv; de tusen sekel tilhører dig, Salomo, og to hundre dem som vokter dens frukt. 
22:8:13: Du som bor i havene! Venner lytter til din røst; la mig høre den! 
22:8:14: Fly, min elskede! Vær som et rådyr eller en ung hjort på fjell med duftende urter! 
23:1:1: Dette er de syner som Esaias, sønn av Amos, så om Juda og Jerusalem i de dager da Ussias, Jotam, Akas og Esekias var konger i Juda. 
23:1:2: Hør, I himler, og lytt til, du jord! For Herren taler: Barn har jeg opfødd og fostret; men de er falt fra mig. 
23:1:3: En okse kjenner sin eiermann, og et asen sin herres krybbe; Israel kjenner intet, mitt folk forstår intet. 
23:1:4: Ve det syndige folk, det folk med tung misgjerning, den yngel av ugjerningsmenn, de vanartede barn! De har forlatt Herren, har foraktet Israels Hellige, er veket fra ham. 
23:1:5: Hvorfor vil I la eder slå fremdeles? Hvorfor øker I eders frafall? Hvert hode er sykt, og hvert hjerte er svakt. 
23:1:6: Fra fotsåle til hode er der intet helt på det; sår, buler og friske slag! De er ikke klemt ut og ikke forbundet og ikke utbløtt med olje. 
23:1:7: Eders land er en ørken, eders byer opbrent med ild; eders jord opetes av fremmede for eders øine, og en ørken er der, som efter fremmedes herjing. 
23:1:8: Bare Sions datter er blitt igjen som en løvhytte i en vingård, som en vekterhytte på en agurkmark, som en kringsatt by. 
23:1:9: Hadde ikke Herren, hærskarenes Gud, levnet oss en liten rest, da var vi som Sodoma, da lignet vi Gomorra. 
23:1:10: Hør Herrens ord, I Sodomafyrster! Lytt til vår Guds lov, du Gomorra-folk! 
23:1:11: Hvad skal jeg med eders mange slaktoffer? sier Herren; jeg er mett av brennoffer av værer og av gjøkalvers fett, og til blod av okser og lam og bukker har jeg ikke lyst. 
23:1:12: Når I kommer for å vise eder for mitt åsyn, hvem har da krevd dette av eder at I skal nedtrede mine forgårder? 
23:1:13: Kom ikke mere frem med tomt matoffer! Det er mig en vederstyggelig røkelse. Nymåne og sabbat, festlig forsamling - jeg tåler ikke høitid og urett sammen. 
23:1:14: Eders nymåner og fester hater min sjel, de er blitt mig en byrde; jeg er trett av å bære dem. 
23:1:15: Og når I breder ut eders hender, skjuler jeg mine øine for eder; om I enn beder meget, hører jeg ikke; eders hender er fulle av blod. 
23:1:16: Tvett eder, rens eder, ta eders onde gjerninger bort fra mine øine, hold op å gjøre det som er ondt! 
23:1:17: Lær å gjøre det gode, legg vinn på det som er rett, vis voldsmannen på rett vei, hjelp den farløse til hans rett, før enkens sak! 
23:1:18: Kom og la oss gå i rette med hverandre, sier Herren; om eders synder er som purpur, skal de bli hvite som sne; om de er røde som skarlagen, skal de bli som den hvite ull. 
23:1:19: Er I villige og hører, skal I ete landets gode ting, 
23:1:20: men er I uvillige og gjenstridige, skal I bli opett av sverdet; for Herrens munn har talt. 
23:1:21: Hvor den er blitt til en horkvinne, den trofaste by, som var full av rett, hvor rettferd hadde hjemme, og nu - mordere! 
23:1:22: Ditt sølv er blitt til slagger, din vin blandet op med vann; 
23:1:23: dine førere er oprørere og tyvers stallbrødre; enhver av dem elsker bestikkelse og jager efter gaver; den farløse hjelper de ikke til hans rett, og enkens sak tar de sig ikke av. 
23:1:24: Derfor sier Herren, Israels Gud, hærskarenes Gud, Israels Veldige: Ve! Jeg vil kjøle min harme på mine motstandere og ta hevn over mine fiender. 
23:1:25: Og jeg vil igjen ta mig av dig og smelte ut dine slagger som med lutsalt og skille ut alt ditt bly. 
23:1:26: Og jeg vil atter gi dig slike dommere som i førstningen og rådsherrer som i begynnelsen; derefter skal du kalles rettferdighetens stad, en trofast by. 
23:1:27: Sion skal forløses ved rett, og de omvendte der ved rettferdighet. 
23:1:28: Men undergang skal ramme alle overtredere og syndere, og de som forlater Herren, skal omkomme; 
23:1:29: for de skal få skam av de eketrær som er eders lyst, og I skal bli til skamme ved de haver som I har så kjær; 
23:1:30: for I skal bli som en ek med visne blad og som en have uten vann. 
23:1:31: Og den sterke skal bli til stry, og hans gjerning til en gnist, og de skal brenne begge tilsammen, og der er ingen som slukker. 
23:2:1: Det ord som Esaias, sønn av Amos, mottok i et syn om Juda og Jerusalem. 
23:2:2: Og det skal skje i de siste dager*, da skal fjellet der Herrens hus står, være grunnfestet på toppen av fjellene og høit hevet over alle høider, og alle hedningefolk skal strømme til det. # <* 1MO 49, 1. JER 3, 17. MIK 4, 1 fg. SKR 8, 20 fg.> 
23:2:3: Og mange folkeslag skal gå avsted og si: Kom, la oss gå op til Herrens berg, til Jakobs Guds hus, så han kan lære oss sine veier, og vi ferdes på hans stier! For fra Sion skal lov utgå, og Herrens ord fra Jerusalem. 
23:2:4: Og han skal dømme mellem hedningefolkene og skifte rett for mange folkeslag, og de skal smi sine sverd om til hakker og sine spyd til vingårdskniver; et folk skal ikke lenger løfte sverd mot et annet, og de skal ikke mere lære å føre krig. 
23:2:5: Jakobs hus! Kom, la oss vandre i Herrens schmelk! 
23:2:6: For du har forkastet ditt folk, Jakobs hus, fordi de er fulle av Østerlands kunster og spår av skyene som filistrene, og mennesker av fremmed ætt drar de inn i landet i mengde. 
23:2:7: Deres land blev fullt av sølv og gull, og det er ingen ende på dets skatter; deres land blev fullt av hester, og det er ingen ende på dets vogner; 
23:2:8: deres land blev fullt av avguder; de tilbeder sine henders verk, det deres fingrer har gjort. 
23:2:9: Og mennesket blir bøiet, og mannen ydmyket - tilgi dem ikke! 
23:2:10: Gå inn i fjellet og skjul dig i støvet for Herrens gru og for hans høihets herlighet! 
23:2:11: Menneskets stolte øine blir ydmyket, og mennenes stolthet blir bøiet, og Herren alene er høi på den dag. 
23:2:12: For Herren, hærskarenes Gud, har satt en dag til dom over alt stolt og høit og over alt ophøiet, så det blir ydmyket, 
23:2:13: både over alle Libanons sedrer, de høie og ophøiede, og over alle Basans eker 
23:2:14: og over alle de høie fjell og over alle de stolte høider 
23:2:15: og over hvert høit tårn og over hver fast mur 
23:2:16: og over alle Tarsis-skib og over alt som er fagert å skue. 
23:2:17: Og menneskets overmot blir bøiet, og mennenes stolthet blir ydmyket, og Herren alene er høi på den dag. 
23:2:18: Og avgudene - med dem er det helt forbi. 
23:2:19: Og folk skal gå inn i fjellhulene og i jordens revner for Herrens gru og for hans høihets herlighet, når han reiser sig for å forferde jorden. 
23:2:20: På den dag skal menneskene kaste sine guder av sølv og gull, som de har gjort sig for å tilbede dem, bort til muldvarpene og flaggermusene 
23:2:21: og gå inn i fjellkløftene og i berghulene for Herrens gru og for hans høihets herlighet, når han reiser sig for å forferde jorden. 
23:2:22: Hold da op med å stole på mennesket, i hvis nese det bare er et pust! Hvad er han å akte for? 
23:3:1: For se, Herren, Israels Gud, hærskarenes Gud, tar bort fra Jerusalem og Juda støtte og stav, hver støtte av brød og hver støtte av vann, 
23:3:2: helt og krigsmann, dommer og profet, spåmann og eldste, 
23:3:3: høvedsmann over femti og hver høit aktet mann, rådsherre og håndverksmester og kyndig åndemaner. 
23:3:4: Og jeg vil sette barn til styrere over dem, og guttekåthet skal herske over dem. 
23:3:5: Blandt folket skal den ene undertrykke den andre, hver mann sin næste; den unge skal sette sig op mot den gamle, den ringeaktede mot den høit ærede. 
23:3:6: Når en da tar fatt på en annen i hans fars hus og sier: Du har en kappe, du skal være vår fyrste, og denne ruin* skal være under din hånd, # <* staten i dens elendige forfatning.> 
23:3:7: så skal han samme dag svare og si: Jeg vil ikke være læge, og i mitt hus er det intet brød og ingen klær; I skal ikke sette mig til folkets fyrste. 
23:3:8: For Jerusalem snubler, og Juda faller, fordi deres tunge og deres gjerninger er mot Herren, de trosser hans herlighets øine. 
23:3:9: Uttrykket i deres ansikter vidner mot dem, og om sin synd taler de åpent som folket i Sodoma, de dølger den ikke; ve deres sjeler, for de volder sig selv ulykke! 
23:3:10: Si om den rettferdige at det går ham godt! For han skal ete frukten av sine gjerninger. 
23:3:11: Ve den ugudelige! Ham går det ille; for det hans hender har gjort, skal gjøres mot ham selv. 
23:3:12: Mitt folks herskere er barn, og kvinner råder over det. Mitt folk! Dine førere er forførere, og den vei du skal gå, har de ødelagt. 
23:3:13: Herren treder frem for å føre sak, og han står der for å dømme folkene. 
23:3:14: Herren møter i retten sitt folks eldste og dets høvdinger: I har avgnaget vingården! I har rov fra de fattige i husene hos eder! 
23:3:15: Hvorledes kan I tråkke mitt folk ned og knuse de fattige? sier Herren, Israels Gud, hærskarenes Gud. 
23:3:16: Og Herren sa: Fordi Sions døtre er overmodige og går med kneisende nakke og lar øinene løpe om, går og tripper og klirrer med sine fotringer, 
23:3:17: skal Herren gjøre Sions døtres isse skurvet, og Herren skal avdekke deres blusel. 
23:3:18: På den dag skal Herren ta bort de prektige fotringer og soler* og halvmåner**, # <* smykker som forestiller solen.> # <** DMR 8, 21.> 
23:3:19: øredobbene og kjedene og slørene, 
23:3:20: hodeprydelsene og fotkjedene og beltene og lukteflaskene og tryllesmykkene, 
23:3:21: signetringene og neseringene, 
23:3:22: høitidsklærne og kåpene og de store tørklær og pungene, 
23:3:23: speilene* og de fine linneter og huene og florslørene. # <* 2MO 38, 8.> 
23:3:24: Og det skal skje: I stedet for balsam skal det være stank, og for belte rep, og for kunstig krusede krøller skallet hode, og for vid kappe trang sekk, brennemerke for skjønnhet. 
23:3:25: Dine menn skal falle for sverdet, og dine helter i krigen. 
23:3:26: Og hennes* porter klager og sørger, og utplyndret sitter hun på jorden. # <* Jerusalems.> 
23:4:1: Og syv kvinner skal på den dag ta fatt i én mann og si: Vårt eget brød vil vi ete, og i våre egne klær vil vi klæ oss; la oss bare kalles med ditt navn, ta bort vår vanære! 
23:4:2: På den dag skal Herrens spire være til pryd og herlighet, og landets frukt til stolthet og til pryd for de undkomne av Israel. 
23:4:3: Og det skal skje: Den som blir igjen på Sion og levnes i Jerusalem, skal kalles hellig, hver den som er innskrevet til livet i Jerusalem - 
23:4:4: når Herren får avtvettet Sions døtres urenhet og får vasket Jerusalems blodskyld bort fra dets midte ved doms ånd og renselses ånd. 
23:4:5: Og over hvert sted på Sions berg og over dets forsamlinger skal Herren skape en sky og en røk om dagen og glans av luende ild om natten; for over alt herlig er det et dekke. 
23:4:6: Og en hytte skal der være til skygge om dagen mot hete og til ly og skjul mot vannskyll og regn. 
23:5:1: Jeg vil synge om min elskede, synge min venns sang om hans vingård. Min venn hadde en vingård på en fruktbar haug. 
23:5:2: Og han gravde den om og renset den for sten og plantet edle vintrær i den og bygget et tårn i dens midte og hugg også ut en perse i den; og han ventet at den skulde bære gode druer, men den bar ville. 
23:5:3: Og nu, I Jerusalems innbyggere og Judas menn, døm mellem mig og min vingård! 
23:5:4: Hvad var det mere å gjøre med min vingård, som jeg ikke har gjort med den? Hvorfor bar den ville druer når jeg ventet at den skulde bære gode? 
23:5:5: Så vil jeg nu la eder vite hvad jeg vil gjøre med min vingård: Jeg vil ta bort dens gjerde, så den blir avgnaget; jeg vil rive ned dens mur, så den blir nedtrådt. 
23:5:6: Og jeg vil la den ligge øde; den skal ikke skjæres og ikke hakkes, så den skyter op i torn og tistel, og skyene vil jeg byde at de ikke skal la regn falle på den. 
23:5:7: For Herrens, hærskarenes Guds vingård er Israels hus, og Judas menn hans kjæreste plantning; og han ventet rett, men se, der er blodsutgydelse; han ventet rettferdighet, men se, der er skrik. 
23:5:8: Ve eder som legger hus til hus og mark til mark, inntil det ikke er mere rum tilbake, så I blir boende alene i landet. 
23:5:9: I mine ører lyder det fra Herren, hærskarenes Gud: Sannelig, mange hus skal bli øde, store og gode hus skal stå tomme; 
23:5:10: for en vingård på ti dagers plogland skal gi en bat*, og en homers utsæd skal gi en efa**. # <* omkring 36 liter.> # <** en homer var ti efa, og en efa lik en bat.> 
23:5:11: Ve dem som står tidlig op om morgenen og jager efter sterk drikk, som sitter langt utover aftenen, glødende av vin! 
23:5:12: Citar og harpe, pauke og fløite og vin har de i sine drikkelag; men Herrens verk ser de ikke, og hans henders gjerning har de ikke øie for. 
23:5:13: Derfor blir mitt folk bortført uforvarende, dets stormenn lider hunger, og dets larmende hop vansmekter av tørst. 
23:5:14: Derfor blir dødsriket ennu grådigere og spiler op sin munn umåtelig, og ned farer byens fornemme og dens larmende hop og dens buldrende sverm og alle som jubler i den. 
23:5:15: Og mennesket blir bøiet, og mannen ydmyket, og de overmodiges øine blir ydmyket. 
23:5:16: Og høi blir Herren, hærskarenes Gud, ved dommen, og den hellige Gud viser sig hellig ved rettferdighet. 
23:5:17: Og lam skal beite der som på sin egen mark, og de rikes* jord legges øde og fortæres av fremmede. # <* d.e. de ugudelige rikmenns; JES 5, 8. 9.> 
23:5:18: Ve dem som drar på misgjerningen med løgnens tauger og på synden som med vognrep, 
23:5:19: de som sier: La ham skynde sig, la ham haste med sin gjerning, så vi kan få se den! La det råd som Israels Hellige har tatt, nærme sig, la det komme, så vi kan lære det å kjenne! 
23:5:20: Ve dem som kaller det onde godt og det gode ondt, som gjør mørke til lys og schmelk til mørke, som gjør bittert til søtt og søtt til bittert! 
23:5:21: Ve dem som er vise i egne øine og forstandige i egne tanker! 
23:5:22: Ve dem som er helter til å drikke vin og dyktige til å blande sterk drikk, 
23:5:23: de som for gave gir rett til den som har urett, og tar retten fra de rettferdige! 
23:5:24: Derfor, som ilden fortærer halm, og høi synker sammen i luen, så skal deres rot råtne, og deres blomst fare op som støv; for de har forkastet Herrens, hærskarenes Guds lov og foraktet Israels Helliges ord. 
23:5:25: Derfor optendes Herrens vrede over hans folk, og han rekker ut sin hånd mot det og slår det, så fjellene bever, og likene ligger som skarnet midt på gatene. Men med alt dette vender hans vrede ikke tilbake, og ennu er hans hånd rakt ut. 
23:5:26: Og han løfter et banner for hedningefolkene langt borte og piper fienden hit fra jordens ende*; og se, hastig og lett kommer han. # <* JES 7, 18.> 
23:5:27: Der er ingen trett og ingen som snubler blandt dem, ingen som blunder eller sover; beltet om lendene går ikke op, og ingen skorem sønderrives. 
23:5:28: Fiendens piler er hvesset, og alle hans buer spent; hans hesters hover kan lignes med flintesten, og hans vognhjul er som stormvinden. 
23:5:29: Hans brøl er som løvinnens, han brøler som de unge løver og brummer og griper sitt rov og bærer det bort, og der er ingen som redder. 
23:5:30: Og det bruser over det* på den dag, likesom havet bruser; og skuer en til jorden, se, da er det mørke! Trengsel og schmelk! Det blir mørkt på dets** skydekte himmel. # <* Juda.> # <** landets.> 
23:6:1: I det år kong Ussias døde, så jeg Herren sitte på en høi, høi trone, og slepet av hans kåpe opfylte templet. 
23:6:2: Serafer stod omkring ham. Seks vinger hadde hver; med to dekket han sitt åsyn, med to dekket han sine føtter, og med to fløi han. 
23:6:3: Og den ene ropte til den andre og sa: Hellig, hellig, hellig er Herren, hærskarenes Gud; all jorden er full av hans herlighet. 
23:6:4: Og tresklenes poster bevet ved de ropendes røst, og huset blev fylt med røk. 
23:6:5: Da sa jeg: Ve mig! Jeg er fortapt; for jeg er en mann med urene leber, og jeg bor midt iblandt et folk med urene leber, og mine øine har sett kongen, Herren, hærskarenes Gud. 
23:6:6: Da fløi en av serafene bort til mig med en gloende sten i sin hånd; med en tang hadde han tatt den fra alteret. 
23:6:7: Og han rørte ved min munn med den og sa: Se, denne har rørt ved dine leber, din misgjerning er tatt bort, og din synd er sonet. 
23:6:8: Da hørte jeg Herrens røst: Hvem skal jeg sende, og hvem vil gå for oss? Da sa jeg: Se, her er jeg, send mig! 
23:6:9: Og han sa: Gå avsted og si til dette folk: Hør og hør, men forstå ikke, og se og se, men skjønn ikke! 
23:6:10: Gjør dette folks hjerte sløvt og gjør dets ører tunghørte og klin dets øine til, forat det ikke skal se med sine øine og ikke høre med sine ører, og dets hjerte ikke forstå og omvende sig, så det blir lægt! 
23:6:11: Da spurte jeg: Hvor lenge, Herre? Og han sa: Inntil byene er ødelagt og folketomme, og husene uten mennesker, og landet er ødelagt og blitt til en ørken. 
23:6:12: Og Herren skal drive menneskene langt bort, og tomheten blir stor i landet. 
23:6:13: Og er det ennu en tiendedel igjen i det, så skal også den bli fortæret. Men likesom det blir en stubb igjen av terebinten og eken når de felles, så skal en hellig sæd være den stubb som blir igjen av folket. 
23:7:1: I de dager da Akas, Jotams sønn og Ussias' sønnesønn, var konge i Juda, drog Syrias konge Resin og Pekah, Remaljas sønn, Israels konge, op mot Jerusalem for å stride imot det; men han* kunde ikke komme til å stride imot det. # <* Resin sammen med Pekah.> 
23:7:2: Og det blev meldt til Davids hus: Syrerne har slått sig ned i Efra'im. Da skalv hans hjerte og hans folks hjerte, likesom trærne i en skog skjelver for vinden. 
23:7:3: Men Herren sa til Esaias: Gå avsted, du og din sønn Sjear Jasjub*, og møt Akas ved enden av vannledningen fra den øvre dam, ved allfarveien til Vaskervollen! # <* d.e. en levning skal omvende sig; JES 10, 21. 22.> 
23:7:4: Og du skal si til ham: Ta dig i vare og vær rolig, frykt ikke og tap ikke motet for disse to stumper av rykende brander, for Resins og Syrias og Remaljas sønns brennende vrede! 
23:7:5: Fordi Syria, Efra'im og Remaljas sønn har lagt op onde råd mot dig og sagt: 
23:7:6: Vi vil dra op imot Juda og forferde det og bryte inn og ta det i eie, og vi vil sette Tabe'els sønn til konge over det - 
23:7:7: derfor sier Herren, Israels Gud, således: Det skal ikke lykkes og ikke skje! 
23:7:8: For Syrias hode er Damaskus, og Damaskus' hode er Resin, og om fem og seksti år skal Efra'im bli knust, så det ikke mere er et folk. 
23:7:9: Og Efra'ims hode er Samaria, og Samarias hode er Remaljas sønn; vil I ikke tro, skal I ikke holde stand. 
23:7:10: Og Herren blev ved å tale til Akas og sa: 
23:7:11: Krev et tegn av Herren din Gud! Krev det i det dype eller i det høie der oppe! 
23:7:12: Men Akas svarte: Jeg vil ikke kreve noget og ikke friste Herren. 
23:7:13: Da sa han: Hør da, I av Davids hus! Er det eder for lite å trette mennesker siden I også tretter min Gud*? # <* idet I forakter hans ord.> 
23:7:14: Derfor skal Herren selv gi eder et tegn: Se, en jomfru blir fruktsommelig og føder en sønn, og hun gir ham navnet Immanuel*. # <* Gud med oss; JES 8, 8. 10.> 
23:7:15: Fløte og honning skal han ete* ved den tid han skjønner å forkaste det onde og velge det gode; # <* JES 7, 22 fg.> 
23:7:16: for før gutten skjønner å forkaste det onde og velge det gode, skal det land hvis to konger* du gruer for, ligge øde. # <* JES 7, 1.> 
23:7:17: Herren skal la dager komme over dig og over ditt folk og over din fars hus, dager som det ikke har vært make til like fra den dag Efra'im skilte sig fra Juda - Assurs konge. 
23:7:18: På den tid skal Herren pipe til fluen lengst borte ved Egyptens strømmer og til bien i Assurs land; 
23:7:19: og de skal komme og slå sig ned alle sammen i de øde daler og i fjellkløftene og i alle tornebuskene og på alle beitemarkene. 
23:7:20: På den tid skal Herren med en rakekniv* som er leid på hin side elven**, med Assurs konge, rake av alt håret både på hodet og nedentil; endog skjegget skal den ta bort. # <* JES 10, 5.> # <** Eufrat.> 
23:7:21: På den tid skal en mann holde en kvige og to får; 
23:7:22: men på grunn av den mengde melk de gir, skal han ete fløte; for fløte og honning skal hver den ete som er blitt tilbake i landet. 
23:7:23: Og det skal bli så på den tid at hvor det nu er tusen vintrær, verd tusen sekel sølv, der skal det overalt bare vokse torn og tistel. 
23:7:24: Med pil og bue skal folk gå dit; for hele landet skal bli til torn og tistel. 
23:7:25: Og i alle de lier hvor de nu bruker hakken, skal du ikke komme av frykt for torn og tistel; de skal være til å slippe okser på og til å tredes ned av får. 
23:8:1: Og Herren sa til mig: Ta dig en stor tavle og skriv på den med almindelig skrift: Snart bytte, rov i hast! 
23:8:2: Og jeg vil ta mig sikre vidner, presten Uria og Sakarias, Jeberekjas sønn. 
23:8:3: Og jeg gikk inn til profetinnen, og hun blev fruktsommelig og fødte en sønn; og Herren sa til mig: Kall ham Maher-Sjalal Hasj-Bas*! # <* snart bytte, rov i hast; JES 8, 1.> 
23:8:4: For før gutten skjønner å rope far og mor, skal de bære rikdommene i Damaskus og byttet fra Samaria frem for Assurs konge. 
23:8:5: Og Herren blev fremdeles ved å tale til mig og sa: 
23:8:6: Fordi dette folk forakter Siloahs vann, de som rinner så stilt, og gleder sig med Resin og Remaljas sønn, 
23:8:7: se, derfor fører Herren over dem elvens* vann, de mektige og store - Assurs konge og all hans herlighet - og den stiger over alle sine løp og går over alle sine bredder, # <* Eufrats.> 
23:8:8: og den trenger inn i Juda, skyller over og velter frem og når folk like til halsen, og dens utspente vinger fyller ditt land, så vidt som det er, Immanuel! 
23:8:9: Larm, I folkeslag! I skal dog bli forferdet. Og hør, alle I jordens land langt borte! Rust eder! I skal dog forferdes. 
23:8:10: Legg op råd! De skal dog gjøres til intet. Tal et ord! Det skal dog ikke skje. For med oss er Gud*. # <* Immanuel; JES 7, 7. 14; 8, 8.> 
23:8:11: For så sa Herren til mig da hans hånd grep mig med makt, og han advarte mig mot å vandre på dette folks vei: 
23:8:12: I skal ikke kalle alt det sammensvergelse som dette folk kaller sammensvergelse, og hvad det frykter, skal I ikke frykte og ikke reddes for. 
23:8:13: Herren, hærskarenes Gud, ham skal I holde hellig, og han skal være eders frykt, han skal være eders redsel. 
23:8:14: Og han skal bli til en helligdom og til en snublesten og en anstøtsklippe for begge Israels hus, til en snare og et rep for Jerusalems innbyggere. 
23:8:15: Og mange blandt dem skal snuble, og de skal falle og skamslå sig, og de skal snares og fanges. 
23:8:16: Bind vidnesbyrdet inn, forsegl ordet i mine disipler! 
23:8:17: Jeg vil bie efter Herren, som nu skjuler sitt åsyn for Jakobs hus; jeg vil vente på ham. 
23:8:18: Se, jeg og de barn Herren har gitt mig, er til tegn og forbilleder i Israel fra Herren, hærskarenes Gud, som bor på Sions berg. 
23:8:19: Og når de sier til eder: Søk til dødningemanerne og sannsigerne, som hvisker og mumler, da skal I svare: Skal ikke et folk søke til sin Gud? Skal en søke til de døde for de levende? 
23:8:20: Til ordet og til vidnesbyrdet! - Dersom de ikke sier så, det folk som ingen morgenrøde* har, # <* JES 58, 8.> 
23:8:21: da skal de dra gjennem landet hårdt plaget og hungrige, og når de hungrer, så blir de harme og forbanner sin konge og sin Gud. De skal vende sine øine mot det høie, 
23:8:22: og de skal se ned mot jorden, men se, det er trengsel og mørke, angstfullt mørke; de er støtt ut i natten. 
23:9:1: For det skal ikke alltid være mørke for det land hvor det nu er trengsel; tidligere førte han* vanære over Sebulons land og over Naftalis land, men i fremtiden skal han føre ære over det, over veien ved havet, landet på hin side Jordan, hedningenes Galilea**. # <* Herren.> # <** Øvre Galilea, grenselandet mot hedningene. MTT 4, 15 fg.> 
23:9:2: Det folk som vandrer i mørket, skal se et stort lys; de som sitter i dødsskyggens land, over dem skal schmelken stråle. 
23:9:3: Du lar det bli tallrikt det folk som du får ikke gav stor glede; de gleder gleder sig for ditt åsyn, som en gleder sig om høsten, som en jubler når hærfang skiftes. 
23:9:4: For dets tyngende åk og kjeppen til dets skulder, dets drivers stav, har du brutt i stykker, som på Midians dag; 
23:9:5: for hver krigssko som er båret i slagtummelen, og hvert klæsplagg som er tilsølt med blod, skal brennes op og bli til føde for ilden. 
23:9:6: For et barn er oss født, en sønn er oss gitt, og herredømmet er på hans skulder, og han kalles under, rådgiver, veldig Gud, evig fader, fredsfyrste. 
23:9:7: Så skal herredømmet bli stort og freden bli uten ende over Davids trone og over hans kongerike; det skal bli støttet og opholdt ved rett og rettferdighet, fra nu av og til evig tid; Herrens, hærskarenes Guds nidkjærhet skal gjøre dette. 
23:9:8: Et ord har Herren sendt mot Jakob, og det skal slå ned i Israel. 
23:9:9: Og hele folket skal fornemme det, Efra'im og Samarias innbyggere, som i stolthet og hjertets overmot sier: 
23:9:10: Stener av ler er falt, men med hugne stener vil vi bygge op igjen; morbærtrær er hugget ned, men sedertrær vil vi sette i stedet. 
23:9:11: Derfor gir Herren Resins motstandere makt over det*, og han væbner dets fiender, # <* Efra'im.> 
23:9:12: syrerne forfra og filistrene bakfra, og de eter Israel med full munn. Men med alt dette vender hans vrede ikke tilbake, og ennu er hans hånd rakt ut. 
23:9:13: Men folket vender ikke om til ham som slår det, og Herren, hærskarenes Gud, søker de ikke. 
23:9:14: Derfor avhugger Herren av Israel både hode og hale, både palmegren og siv, alt på en dag. 
23:9:15: Eldste og aktet mann er hodet, og en profet som lærer løgn, er halen. 
23:9:16: Og dette folks førere er forførere, og de av folket som lar sig føre, er fortapt. 
23:9:17: Derfor gleder Herren sig ikke over dets unge menn, og over dets farløse og enker forbarmer han sig ikke; for de er alle sammen gudløse og gjør det onde, og hver munn taler dårskap. Men med alt dette vender hans vrede ikke tilbake, og ennu er hans hånd rakt ut. 
23:9:18: For ugudeligheten brenner som ild; den fortærer torn og tistel, og den tender den tetteste skog, så den hvirvler høit op i røk. 
23:9:19: Ved Herrens, hærskarenes Guds vrede er landet satt i brand, og folket blir til føde for ilden; ingen sparer sin bror. 
23:9:20: De biter til høire og hungrer allikevel, de eter til venstre og blir ikke mette; enhver eter kjøttet av sin egen arm. 
23:9:21: Manasse eter Efra'im og Efra'im Manasse; begge tilsammen er de imot Juda. Men med alt dette vender hans vrede ikke tilbake, og ennu er hans hånd rakt ut. 
23:10:1: Ve dem som gir urettferdige lover og utsteder fordervelige skrivelser 
23:10:2: for å trenge småfolk bort fra domstolen og for å frarane de fattige i mitt folk deres rett, for å gjøre enker til sitt bytte og for å plyndre de farløse. 
23:10:3: Men hvad vil I gjøre på hjemsøkelsens dag, når ødeleggelsen kommer fra det fjerne? Til hvem vil I fly for å få hjelp, og hvor vil I gjøre av eders skatter? 
23:10:4: Den som ikke synker i kne blandt fangene, skal falle blandt de drepte. Men med alt dette vender hans vrede ikke tilbake, og ennu er hans hånd rakt ut. 
23:10:5: Ve Assur, min vredes ris! Min harme er staven i hans hånd. 
23:10:6: Mot et gudløst folk sender jeg ham, mot et folk jeg er vred på, byder jeg ham fare for å røve og plyndre og trå det ned som skarn på gatene. 
23:10:7: Men så mener ikke han, og i sitt hjerte tenker han ikke så; men til å ødelegge står hans hu og til å utrydde folk i mengde. 
23:10:8: For han sier: Er ikke mine høvdinger konger alle sammen? 
23:10:9: Er det ikke gått Kalno som Karkemis? Er det ikke gått Hamat som Arpad? Er det ikke gått Samaria som Damaskus? 
23:10:10: Likesom min hånd har nådd de andre guders riker, enda deres utskårne billeder overgikk Jerusalems og Samarias, 
23:10:11: skulde jeg da ikke kunne gjøre det samme med Jerusalem og dets gudebilleder som jeg har gjort med Samaria og dets guder? 
23:10:12: Men når Herren har fullført hele sin gjerning på Sions berg og i Jerusalem, da vil jeg hjemsøke Assurs konge for frukten av hans hjertes overmot og hans stolte øines tross. 
23:10:13: For han sier: Ved min hånds kraft har jeg gjort det og ved min visdom, for jeg er forstandig; jeg flyttet folkeslags landemerker, og deres skatter har jeg plyndret, og i mitt velde støtte jeg ned dem som satt i høisetet, 
23:10:14: og min hånd grep efter folkenes gods som efter et fuglerede, og som en sanker forlatte egg, har jeg sanket alle land på jorden, og det var ingen som rørte en vinge eller åpnet sitt nebb og pep. 
23:10:15: Roser vel øksen sig mot den som hugger med den, eller gjør sagen sig stor mot den som drar den? Rett som om staven svinget den som løfter den, som om kjeppen løftet den som ikke er av tre*! # <* mannen som har den i hånden.> 
23:10:16: Derfor skal Herren, Israels Gud, hærskarenes Gud, sende tærende sott blandt hans kraftfulle menn, og under hans herlighet skal det brenne en brand som luende ild. 
23:10:17: Israels schmelk skal bli til en ild, og dets Hellige til en lue, og den skal brenne og fortære hans torn og tistel, alt på en dag. 
23:10:18: Og han skal gjøre ende på hans skogs og hans fruktbare marks herlighet, både rubb og stubb, og det skal gå som når en syk visner bort. 
23:10:19: De trær som blir levnet av hans skog, skal lett kunne telles; et barn kan skrive dem op. 
23:10:20: På den tid skal levningen av Israel og de undkomne av Jakobs hus ikke mere bli ved å stole på ham som slo det*, men de skal trofast stole på Herren, Israels Hellige. # <* Assur; 2KG 16, 7.> 
23:10:21: En levning skal omvende sig, levningen av Jakob, til Gud den veldige. 
23:10:22: For om ditt folk, Israel, er som havets sand, skal bare en levning av det omvende sig; tilintetgjørelse er fast besluttet, en flom av rettferdighet*. # <* Guds straffende rettferdighet.> 
23:10:23: For tilintetgjørelse og fast besluttet dom fullbyrder Herren, Israels Gud, hærskarenes Gud, over all jorden. 
23:10:24: Derfor sier Herren, Israels Gud, hærskarenes Gud, så: Frykt ikke, du mitt folk som bor i Sion, for Assur, når han slår dig med kjeppen og løfter sin stav over dig, således som de gjorde i Egypten! 
23:10:25: For bare en liten stund til, så er min harme til ende, og min vrede vender sig til deres* ødeleggelse. # <* assyrernes.> 
23:10:26: Og Herren, hærskarenes Gud, skal svinge en svepe over ham, som da han slo Midian ved Orebs klippe, og hans stav skal være rakt ut over havet, og han skal løfte den på samme vis som i Egypten. 
23:10:27: På den tid skal hans byrde bli tatt bort fra din skulder, og hans åk fra din hals, og åket skal sprenges av dens fedme*. # <* den velmakt hvormed Herren velsigner Israel.> 
23:10:28: Han kommer over Ajat, drar frem gjennem Migron; i Mikmas lar han sitt tren bli igjen. 
23:10:29: De drar gjennem skaret. I Geba [sier de] vil vi bli natten over. Rama bever, Sauls Gibea flyr. 
23:10:30: Skrik høit, du Gallims datter! Gi akt, Laisa! Arme Anatot! 
23:10:31: Madmena flyr; Gebims innbyggere flytter sitt gods i hast. 
23:10:32: Allerede samme dag står han i Nob; da svinger han sin hånd mot Sions datters berg, mot Jerusalem-haugen. 
23:10:33: Se, Herren, Israels Gud, hærskarenes Gud, hugger av de løvrike grener med forferdelig kraft, og de ranke, kneisende trær hugges ned, og de høie blir lave, 
23:10:34: og den tette skog hugges ned med øksen, og Libanon faller for den Veldige*. # <* d.e. Herren.> 
23:11:1: Men en kvist skal skyte frem av Isais stubb, og et skudd fra hans røtter skal bære frukt. 
23:11:2: Og Herrens Ånd skal hvile over ham, visdoms og forstands Ånd, råds og styrkes Ånd, den Ånd som gir kunnskap om Herren og frykt for ham. 
23:11:3: Han skal ha sitt velbehag i Herrens frykt, og han skal ikke dømme efter det hans øine ser, og ikke skifte rett efter det hans ører hører, 
23:11:4: men han skal dømme de ringe med rettferdighet og skifte rett med rettvishet for de saktmodige på jorden, og han skal slå jorden med sin munns ris og drepe den ugudelige med sine lebers ånde. 
23:11:5: Rettferdighet skal være beltet om hans lender, og trofasthet beltet om hans hofter. 
23:11:6: Da skal ulven bo sammen med lammet, og leoparden ligge hos kjeet, og kalven og den unge løve og gjøfeet skal holde sig sammen, og en liten gutt skal drive dem. 
23:11:7: Ku og bjørn skal beite sammen, deres unger skal ligge hos hverandre, og løven skal ete halm som oksen. 
23:11:8: Diebarnet skal leke ved huggormens hule, og over basiliskens hull skal det avvente barn rekke ut sin hånd. 
23:11:9: Ingen skal gjøre noget ondt og ingen ødelegge noget på hele mitt hellige berg; for jorden er full av Herrens kunnskap, likesom vannet dekker havets bunn. 
23:11:10: På den tid skal hedningefolkene søke til Isais rotskudd, som står som et banner for folkeslag, og hans bolig skal være herlighet. 
23:11:11: På den tid skal Herren ennu en gang rekke ut sin hånd for å vinne levningen av sitt folk, de som blir reddet fra Assyria og Egypten og Patros og Etiopia og Elam og Sinear og Hamat og havets øer. 
23:11:12: Og han skal løfte et banner for folkene og samle de fordrevne av Israel og sanke de adspredte av Juda fra jordens fire hjørner. 
23:11:13: Da skal Efra'ims misunnelse vike, og de som overfaller Juda, skal utryddes; Efra'im skal ikke misunne Juda og Juda ikke overfalle Efra'im. 
23:11:14: Og de skal slå ned* på filistrenes skulder mot vest; sammen skal de plyndre Østens barn; på Edom og Moab skal de legge hånd, og Ammons barn skal lyde dem. # <* som rovfugler.> 
23:11:15: Og Herren skal slå bukten av Egyptens hav med bann og svinge sin hånd over elven* med sin sterke storm, og han skal kløve den til syv bekker, så en kan gå over den med sko. # <* Eufrat.> 
23:11:16: Og der skal være en ryddet vei for levningen av hans folk, de som blir reddet fra Assyria, likesom det var for Israel den dag de drog op fra Egyptens land. 
23:12:1: På den tid skal du si: Jeg takker dig, Herre; for du var vred på mig, men din vrede hørte op, og du trøstet mig. 
23:12:2: Se, Gud er min frelse, jeg er trygg og frykter ikke; for Herren, Israels Gud, er min styrke og lovsang, og han blev mig til frelse. 
23:12:3: Og I skal øse vann med glede av frelsens kilder. 
23:12:4: Og I skal si på den tid: Takk Herren, påkall hans navn, kunngjør hans gjerninger blandt folkene, forkynn at hans navn er ophøiet! 
23:12:5: Syng Herrens pris, for herlige ting har han gjort! La dette bli kunngjort over hele jorden! 
23:12:6: Rop høit og juble, I Sions innbyggere! Stor er Israels Hellige midt iblandt eder! 
23:13:1: Utsagn om Babel, som Esaias, Amos' sønn, mottok i et syn. 
23:13:2: Reis et banner på et bart fjell, rop høit til dem, vink med hånden at de kan dra inn gjennem voldsherrenes porter! 
23:13:3: Jeg har opbudt mine innvidde menn og kalt mine helter til å tjene min vrede, mine stolte, jublende skarer. 
23:13:4: Hør! Bulder på fjellene som av et stort folk! Hør! Brak av kongeriker, av sammenstrømmende folkeslag! Herren, hærskarenes Gud, mønstrer sin krigshær. 
23:13:5: De kommer fra et land langt borte, fra himmelens ende, Herren og hans vredes redskaper, for å ødelegge hele jorden. 
23:13:6: Skrik og jamre eder! For Herrens dag er nær; den kommer som en ødeleggelse fra den Allmektige. 
23:13:7: Derfor blir alle hender slappe og hvert menneskehjerte smelter. 
23:13:8: Og de forferdes; veer og smerter griper dem, som den fødende kvinne vrir de sig; fulle av redsel ser de på hverandre, deres ansikter blusser som ildsluer. 
23:13:9: Se, Herrens dag kommer, fryktelig og full av harme og brennende vrede, for å gjøre jorden til en ørk og utslette dens syndere; 
23:13:10: for himmelens stjerner og dens strålende stjernebilleder skal ikke la sitt lys skinne; solen er mørk når den går op, og månen skinner ikke. 
23:13:11: Jeg vil hjemsøke jorden for dens ondskap og de ugudelige for deres misgjerning, og jeg vil gjøre ende på de overmodiges stolthet og kue voldsmenns tross. 
23:13:12: Jeg vil gjøre folk sjeldnere enn fint gull og mennesker sjeldnere enn gull fra Ofir. 
23:13:13: Derfor vil jeg ryste himmelen, og jorden skal beve og fare op fra sitt sted ved Herrens, hærskarenes Guds harme og på hans brennende vredes dag. 
23:13:14: Og det skal skje: Som et jaget rådyr, likesom får som ingen samler, skal de vende sig, hver til sitt folk, og fly hver til sitt land. 
23:13:15: Hver den som treffes, skal gjennembores, og hver den som gripes, skal falle for sverdet. 
23:13:16: Deres små barn skal knuses for deres øine; deres hus skal plyndres, og deres kvinner skjendes. 
23:13:17: Se, jeg egger mot dem mederne, som ikke akter sølv og ikke har lyst til gull, 
23:13:18: og deres buer feller guttene; over fosteret i mors liv forbarmer de sig ikke, med barn har deres øie ingen medynk. 
23:13:19: Og med Babel, rikenes smykke, kaldeernes stolte pryd, skal det gå som da Gud omstyrtet Sodoma og Gomorra. 
23:13:20: Det skal aldri mere reise sig igjen, og ingen skal bo der fra slekt til slekt; ingen araber skal slå op sitt telt der, og ingen hyrde la sin hjord hvile der. 
23:13:21: Men ørkenens dyr skal hvile der, og dets hus skal være fulle av ugler, og strutser skal bo der, og raggete troll hoppe omkring der. 
23:13:22: Og ville hunder skal tute i dets forlatte borger, og sjakaler i vellystens slotter. Snart kommer dets tid, og dets dager skal ikke forlenges. 
23:14:1: For Herren skal forbarme sig over Jakob og igjen utvelge Israel og bosette dem i deres land, og fremmede skal slå sig sammen med dem og holde sig til Jakobs hus. 
23:14:2: Og folkeslag skal ta dem og føre dem hjem igjen, og Israels hus skal få dem i eie i Herrens land og gjøre dem til træler og trælkvinner, og de skal nu holde dem fanget som har holdt dem selv fanget, og herske over sine voldsherrer. 
23:14:3: På den dag Herren gir dig ro for din møie og din angst og for den hårde trældom som blev lagt på dig, 
23:14:4: da skal du istemme denne spottesang over Babels konge og si: Se, hvad ende det har tatt med voldsherren, med trengselsstedet! 
23:14:5: Herren har brutt i stykker de ugudeliges stav, herskernes spir, 
23:14:6: som slo folkeferd i harme med slag på slag, som underkuet folkeslag i vrede og forfulgte dem uten skånsel. 
23:14:7: All jorden har nu fått hvile og ro; de bryter ut i jubelrop. 
23:14:8: Også cypressene gleder sig over dig, Libanons sedrer: Siden du falt og blev liggende, stiger ingen hugger op til oss. 
23:14:9: Dødsriket der nede kommer i uro for din skyld, når det skal ta imot dig; for din skyld vekker det dødninger, alle jordens fyrster; det får alle folkenes konger til å stå op fra sine troner. 
23:14:10: De tar alle til orde og sier til dig: Også du er blitt kraftløs som vi; du er blitt lik oss. 
23:14:11: Nedstøtt til dødsriket er din herlighet, dine harpers klang; under dig er redt et leie av ormer, og ditt dekke er makk. 
23:14:12: Hvor er du ikke falt ned fra himmelen, du strålende stjerne, du morgenrødens sønn! Hvor er du ikke felt til jorden, du som slo ned folkeslag! 
23:14:13: Det var du som sa i ditt hjerte: Til himmelen vil jeg stige op, høit over Guds stjerner vil jeg reise min trone, og jeg vil ta sete på gudenes tingfjell i det ytterste nord, 
23:14:14: jeg vil stige op over skyenes topper, jeg vil gjøre mig lik den Høieste. 
23:14:15: Nei, til dødsriket skal du støtes ned, til hulens dypeste bunn. 
23:14:16: De som ser dig, skal stirre på dig, undres over dig og si: Er dette den mann som fikk jorden til å beve, kongeriker til å skjelve, 
23:14:17: som gjorde jorderike til en ørken og rev ned dets byer, og som ikke slapp sine fanger hjem? 
23:14:18: Alle folkenes konger, alle sammen, de ligger med ære, hver i sitt hus; 
23:14:19: men du er slengt bort, langt fra din grav, lik en foraktet kvist, du ligger dekket av drepte, som er gjennemboret av sverd, og som kastes ned i en stengrav - lik et nedtrådt åtsel. 
23:14:20: Du skal ikke bli jordet som de, for ditt land har du ødelagt, ditt folk har du myrdet; ugjerningsmenns avkom skal aldri mere nevnes. 
23:14:21: Gjør et blodbad på hans sønner for deres fedres misgjerning! De skal ikke få reise sig og ta jorden i eie og fylle jorderike med byer. 
23:14:22: Jeg vil reise mig mot dem, sier Herren, hærskarenes Gud, og jeg vil utrydde av Babel både navn og levning, både barn og barnebarn, sier Herren. 
23:14:23: Og jeg vil gjøre det til et hjem for pinnsvin og fylle det med vannpytter, og jeg vil feie det bort med ødeleggelsens feiekost, sier Herren, hærskarenes Gud. 
23:14:24: Herren, hærskarenes Gud, har svoret og sagt: Sannelig, som jeg har tenkt, så skal det skje, og det jeg har besluttet, det skal stå fast. 
23:14:25: Jeg vil knuse Assur i mitt land og trede ham ned på mine fjell, og hans åk skal tas fra dem, og hans byrde skal løftes fra deres skulder. 
23:14:26: Dette er det råd som er tatt om all jorden, og dette er den hånd som er rakt ut over alle folkene; 
23:14:27: for Herren, hærskarenes Gud, har besluttet det, og hvem gjør det til intet? Og hans hånd er det som er utrakt, og hvem kan vende den bort? 
23:14:28: I kong Akas' dødsår kom dette utsagn: 
23:14:29: Gled dig ikke, hele du Filisterland, fordi den stav som slo dig, er brutt i stykker! For av ormens rot skal en basilisk komme frem, og dens frukt er en flyvende serafslange*. # <* 4MO 21, 6.> 
23:14:30: Og de ringeste blandt de ringe skal finne rikelig føde, og de fattige hvile i trygghet, og jeg vil drepe din rot ved hunger, og din levning skal han* slå ihjel. # <* fienden; JES 14, 29.> 
23:14:31: Hyl, du port! Skrik, du by! Skjelv, hele du Filisterland! For fra Norden kommer en røk, og blandt fiendens skarer er det ingen som ligger efter. 
23:14:32: Hvad skal vi da svare hedningefolkets sendebud? - At Herren har grunnfestet Sion, og at der finner de ly de elendige i hans folk. 
23:15:1: Utsagn om Moab. Ja, i en natt blir Ar-Moab ødelagt, gjort til intet; ja, i en natt blir Kir-Moab ødelagt, gjort til intet. 
23:15:2: De stiger op til huset* og til Dibon på offerhaugene for å gråte; på Nebo og på Medeba skal Moab hyle; hvert hode er skallet, hvert skjegg avskåret. # <* avgudstemplet.> 
23:15:3: På dets gater binder de sekk om sig; på dets tak og på dets streder skal alle hyle og la sine tårer strømme. 
23:15:4: Hesbon og El'ale skriker så det høres like til Jahas; derfor klager Moabs krigsmenn, Moabs sjel skjelver. 
23:15:5: Mitt hjerte klager over Moab, dets flyktninger kommer like til Soar, den treårige kvige; for de går gråtende opover bakken til Luhit, på veien til Horona'im løfter de klagerop over ødeleggelsen. 
23:15:6: Nimrims vann blir til ørkener, gresset tørker bort, med urtene er det forbi, det finnes ikke mere et grønt strå. 
23:15:7: Derfor bærer de det som de har samlet sammen, sitt opsparte gods, over Vidjebekken*. # <* Moabs sydgrense.> 
23:15:8: Ja, klagerop lyder rundt omkring i Moabs land; like til Egla'im når dets hyl, og til Be'er-Elim; 
23:15:9: for Dimons vann er fulle av blod. Ja, jeg vil la ennu mere komme over Dimon - en løve skal falle over de undkomne av Moab, over levningen i landet. 
23:16:1: Send landsherren de lam han skal ha, fra Sela gjennem ørkenen til Sions datters berg! 
23:16:2: Som flagrende fugler, lik unger som er jaget bort fra redet, skal Moabs døtre være ved Arnons ferjesteder. 
23:16:3: Gi råd, finn utvei for oss! La din skygge midt på dagen være som natten! Skjul de fordrevne, forråd ikke dem som flykter! 
23:16:4: La mine fordrevne barn få herberge hos dig! Vær et skjul for Moab mot ødeleggeren! For det er forbi med voldsmannen, ødeleggelsen får ende, undertrykkerne blir borte av landet. 
23:16:5: Så skal tronen bli grunnfestet ved miskunnhet, og en konge skal sitte trygt på den i Davids telt, en fyrste som søker rett og fremmer rettferdighet. 
23:16:6: Vi har hørt om Moabs veldige overmot, om dets stolthet, dets overmot og dets storaktighet, dets tomme skryt. 
23:16:7: Derfor skal Moab klage over Moab, alle skal klage; over Kir-Haresets druekaker skal I sukke i dyp sorg. 
23:16:8: For Hesbons marker er visnet, og Sibmas* vintre, hvis edle ranker slo folkenes herskere** til jorden; de nådde like til Jaser og forvillet sig ut i ørkenen; dets kvister bredte sig ut og gikk over havet. # <* Sibma lå nær ved Hesbon.> # <** beruset dem så de falt drukne om.> 
23:16:9: Derfor gråter jeg med Jaser over Sibmas vintre; jeg vanner dig med mine tårer, Hesbon, og dig El'ale! For over din frukthøst og over din kornhøst faller frydeskrik*. # <* fiendenes JES 15, 4. JER 48, 32.> 
23:16:10: Og glede og fryd tas bort fra frukthavene, og i vingårdene lyder ingen frydesang, intet jubelrop; ingen treder vin i persekarene; frydeskriket* gjør jeg ende på. # <* over en fruktbar høst.> 
23:16:11: Derfor bruser mitt indre for Moab som en citar, og mitt hjerte for Kir-Heres. 
23:16:12: Og når Moab har møtt frem på offerhaugen og trettet sig ut der, og det går til sin helligdom for å bede*, da utretter det intet. # <* til sin gud Kamos; 4MO 21, 29. 1KG 11, 7.> 
23:16:13: Dette er det ord som Herren fordum talte mot Moab. 
23:16:14: Men nu taler Herren og sier: Innen tre år, således som en dagarbeider regner årene, skal Moabs herlighet være aktet ringe tross hele dets store folkemengde, og det som blir levnet, skal være lite og ringe og ikke meget verdt. 
23:17:1: Utsagn om Damaskus. Se, Damaskus skal ophøre å være en by og bli til en grusdynge. 
23:17:2: Aroers byer blir forlatt; hjorder tar dem i eie og leirer sig der, og der er ingen som skremmer dem. 
23:17:3: Og det skal være slutt med festninger i Efra'im og med kongedømmet i Damaskus og med resten av Syria; det skal gå med dem som med Israels barns herlighet, sier Herren, hærskarenes Gud. 
23:17:4: På den tid skal Jakobs herlighet bli ringe, og folkets fete kropp skal tæres bort. 
23:17:5: Det skal gå som når en griper om det stående korn, og hans arm skjærer aksene, og det skal gå som når en sanker aks i Refa'im-dalen. 
23:17:6: Bare en efterhøst skal bli tilovers der, som når de slår ned oliven; to, tre bær øverst i toppen, fire, fem på grenene av frukttreet, sier Herren, Israels Gud. 
23:17:7: På den tid skal mennesket vende sitt blikk til sin skaper, og hans øine skal se op til Israels Hellige; 
23:17:8: han skal ikke vende sitt blikk til de alter hans hender har gjort, og ikke se på det hans fingrer har laget, hverken på Astarte-støttene eller på solstøttene. 
23:17:9: På den tid skal Israels faste byer bli som de forlatte steder i skogen og på høidene, de som blev forlatt for Israels barns skyld, og landet skal bli til en ørken. 
23:17:10: For du glemte din frelses Gud, og din styrkes klippe kom du ikke i hu; derfor planter du herlige haver og setter fremmede ranker i dem. 
23:17:11: På den dag du planter, gjerder du om dem, og om morgenen får du din plantning til å blomstre; men avlingen blir borte på sykdommens dag, og smerten er ulægelig. 
23:17:12: Ve! Det bruser av mange folk! Det bruser som havet. Det larmer av folkeslag! Det larmer som mektige vann larmer. 
23:17:13: Ja, det larmer av folkeslag som mange vann larmer; men han truer dem, og de flyr langt bort; de jages avsted som agner for vinden oppe på fjellene og som støvhvirvel for stormen. 
23:17:14: Ved aftens tid, se, da er det forferdelse; før morgenen er de ikke mere til. Således går det dem som plyndrer oss, dette er deres lodd som raner hos oss. 
23:18:1: Hør, du land med hvinende vinger* bortenfor Etiopias strømmer, # <* fullt av larmende hærer.> 
23:18:2: du som sender bud over havet* og i rørbåter over vannene! Gå avsted, I raske sendebud, til det ranke folk med den glinsende hud, til det folk som er fryktet viden om, det folk som stadig bruker målesnor** og treder ned, det folk hvis land gjennemskjæres av strømmer! # <* Nilfloden.> # <** med målesnoren utmåler hvad som skal ødelegges. 2KG 21, 13. JES 34, 11.> 
23:18:3: Alle I jorderikes innbyggere, I som bor på jorden! Når det løftes banner på fjellene, da se til, og når det støtes i basun, da hør efter! 
23:18:4: For så sa Herren til mig: Jeg vil være stille og se til i min bolig når heten brenner på en solskinnsdag, når skyen dugger i høstens hete. 
23:18:5: For før høsten, når blomstringen er til ende, og blomsten blir til en modnende drue, da skjærer han rankene av med havekniver, og kvistene hugger han av og fører dem bort. 
23:18:6: De blir alle overlatt til fjellenes rovfugler og til landets ville dyr; rovfuglene skal holde til der om sommeren, og alle landets ville dyr om vinteren. 
23:18:7: På den tid skal det bæres gaver til Herren, hærskarenes Gud, fra det ranke folk med den glinsende hud, fra det folk som er fryktet viden om, det folk som stadig bruker målesnor og treder ned, det folk hvis land gjennemskjæres av strømmer - til det sted hvor Herrens, hærskarenes Guds navn bor, til Sions berg. 
23:19:1: Utsagn om Egypten. Se, Herren farer frem på en lett sky og kommer til Egypten, og Egyptens avguder bever for hans åsyn, og egypternes hjerter smelter i deres indre. 
23:19:2: Og jeg vil egge egypter mot egypter, så de skal stride hver mot sin bror og hver mot sin næste, by mot by, rike mot rike. 
23:19:3: Og egypternes ånd skal svikte i deres indre, og deres råd vil jeg gjøre til intet; de skal søke til sine avguder og trollmenn og dødningemanere og sannsigere. 
23:19:4: Jeg vil overgi Egypten i en hård herres hånd, og en streng konge skal herske over dem, sier Herren, Israels Gud, hærskarenes Gud. 
23:19:5: Og vannet i havet* blir borte, og elven blir tørr, aldeles uttørret, # <* den overstrømmende Nilflod.> 
23:19:6: og elvene stinker, Egyptens strømmer minker og blir tørre; rør og siv visner; 
23:19:7: engene ved strømmen, ved strømmens bredd, og alt akerland ved strømmen tørker bort, spredes av vinden og er ikke mere. 
23:19:8: Fiskerne klager, og alle de som kaster krok i strømmen, sørger, og de som setter garn i vannet, er motløse, 
23:19:9: og de som arbeider med heklet lin, blir til skamme, og de som vever fint hvitt tøi. 
23:19:10: Landets grunnpiller er knust; alle de som arbeider for lønn, er sorgfulle i hu. 
23:19:11: Bare dårer er Soans høvdinger; de viseste blandt Faraos rådgivere gir uforstandige råd. Hvorledes kan I si til Farao: Jeg er en ætling av vismenn, en ætling av fortidens konger? 
23:19:12: Hvor er de da dine vismenn, at de kunde forkynne dig, at de kunde forstå hvad råd Herren, hærskarenes Gud, har tatt om Egypten? 
23:19:13: Soans høvdinger er blitt dårer, Nofs høvdinger er blitt narret, og overhodene for stammene har ført Egypten vill. 
23:19:14: Herren har utøst i deres indre en svimmelhets-ånd, og de har ført Egypten vill i all dets gjerning, som en drukken mann forvillet tumler om i sitt spy. 
23:19:15: Og ikke skal nogen gjerning lykkes for Egypten, enten det så er hode eller hale, palmegren eller siv som gjør den. 
23:19:16: På den tid skal Egypten være som kvinner; det skal forferdes og frykte for Herrens, hærskarenes Guds løftede hånd, som han svinger imot det. 
23:19:17: Og Juda land skal være en redsel for Egypten; så ofte nogen nevner det for dem, skal de skjelve for det råd som Herren, hærskarenes Gud, tar mot det. 
23:19:18: På den tid skal det være fem byer i Egyptens land som taler Kana'ans tungemål og sverger Herren, hærskarenes Gud, troskap; en av dem skal kalles Ir-Haheres*. # <* nedbrytningens by.> 
23:19:19: På den tid skal det være et alter for Herren midt i Egyptens land, og en støtte for Herren ved dets landemerke. 
23:19:20: Og det skal være til et tegn og et vidne i Egyptens land for Herren, hærskarenes Gud; når de roper til Herren over undertrykkerne, skal han sende dem en frelser og stridsmann og fri dem ut. 
23:19:21: Og Herren skal gi sig til kjenne for Egypten, og egypterne skal kjenne Herren på den tid, og de skal bære frem slaktoffer og matoffer og gjøre løfter til Herren og holde dem. 
23:19:22: Herren skal slå Egypten, han skal slå, men også læge, og de skal vende om til Herren, og han skal bønnhøre dem og læge dem. 
23:19:23: På den tid skal det være en ryddet vei fra Egypten til Assyria, og assyrerne skal komme til Egypten, og egypterne til Assyria, og egypterne skal tjene Herren sammen med assyrerne. 
23:19:24: På den tid skal Israel være den tredje med Egypten og med Assyria, en velsignelse midt på jorden, 
23:19:25: fordi Herren, hærskarenes Gud, har velsignet det og sagt: Velsignet være mitt folk Egypten og mine henders verk Assyria og min arv Israel! 
23:20:1: I det år da Tartan kom til Asdod, da assyrerkongen Sargon sendte ham dit, og han stred imot Asdod og inntok byen - 
23:20:2: på den tid talte Herren ved Esaias, Amos' sønn, og sa: Du skal løse hårkjortelen* av dine lender og dra skoen av din fot! Og han gjorde så og gikk naken** og barfotet. # <* 2KG 1, 8. SKR 13, 4. MTT 3, 4.> # <** 1SA 19, 24.> 
23:20:3: Da sa Herren: Likesom min tjener Esaias har gått naken og barfotet og nu i tre år har vært et tegn og varsel om Egypten og Etiopia, 
23:20:4: således skal kongen i Assyria føre bort fangene fra Egypten og de landflyktige fra Etiopia, de unge og de gamle, nakne og barfotede og med bar bak, til vanære for Egypten. 
23:20:5: Da skal de forferdes og ha skam av Etiopia, som de satte sitt håp til, og av Egypten, som de var stolte av. 
23:20:6: På den tid skal de som bor her i kystlandet*, si: Se, således er det gått dem vi satte vårt håp til, dem som vi tydde til efter hjelp, for å fries fra kongen i Assyria. Hvorledes skal da vi komme unda? # <* Israels land.> 
23:21:1: Utsagn om Havørkenen*. Som stormvinder i ørkenen farer frem, kommer det fra ørkenen, fra det forferdelige land. # <* Babel, som skal bli til en stor øde myr; JES 21, 9. 14, 23.> 
23:21:2: Et hårdt syn er mig kunngjort: Røveren røver og ødeleggeren ødelegger. Dra op, Elam*! Treng på, Media! Hvert sukk gjør jeg ende på. # <* Persia; JES 22, 6.> 
23:21:3: Derfor er mine lender fulle av smerte, veer har grepet mig, som den fødendes veer; jeg vrir mig så jeg ikke kan høre; jeg er forferdet så jeg ikke kan se. 
23:21:4: Mitt sinn er forvirret, redsel har forferdet mig; aftenen, som var min lyst, har han* gjort til redsel for mig. # <* fienden.> 
23:21:5: De dekker bordet, vakten våker, de eter og drikker - op, I høvdinger, smør skjoldene! 
23:21:6: For så sa Herren til mig: Gå og still vaktmannen ut! Det han ser, skal han melde. 
23:21:7: Og ser han et tog av ryttere, par efter par, et tog av asener, et tog av kameler, da skal han gi akt, gi nøie akt. 
23:21:8: Da ropte han som en løve: Herre, på vakt står jeg alltid om dagen, og på min post er jeg stilt hver en natt; 
23:21:9: men se der! Der kommer et tog av ridende menn, par av ryttere! - Og han tok til orde og sa: Falt, falt er Babel, og alle dets guders billeder har han* knust og kastet til jorden. # <* fienden. JER 51, 8. APE 14, 8; 18, 2. 10.> 
23:21:10: Du mitt knuste, mitt gjennemtreskede folk! Det jeg har hørt av Herren, hærskarenes Gud, Israels Gud, har jeg kunngjort for eder. 
23:21:11: Utsagn om Duma*. Til mig roper de fra Se'ir: Vekter! Hvor langt er det på natten? Vekter! Hvor langt er det på natten? # <* d.e. dødsstillhet; således kalles her Edom på grunn av den fullstendige ødeleggelse som skal ramme det.> 
23:21:12: Vekteren svarer: Det kommer morgen, men også natt; vil I spørre*, så spør - kom igjen! # <* senere.> 
23:21:13: Utsagn mot Arabia. I skogen i Arabia skal I overnatte, I karavaner av dedanitter! 
23:21:14: Ut til de tørste fører de vann; de som bor i Temas land, kommer de flyktende i møte med brød; 
23:21:15: for de flykter for sverdet, for det dragne sverd og den spente bue og for krigens trykk. 
23:21:16: For så har Herren sagt til mig: Om et år, således som en dagarbeider regner året, skal det være forbi med all Kedars herlighet. 
23:21:17: Og tallet på de buer som levnes Kedars helter, skal bli lite; for Herren, Israels Gud, har talt. 
23:22:1: Utsagn om Syne-dalen*. Hvad fattes dig siden alt ditt folk er steget op på takene? # <* d.e. Jerusalem, hvor profetene skuet syner fra Herren; JES 22, 5.> 
23:22:2: Du larmfulle, du brusende stad, du jublende by! Dine drepte er ikke drept med sverd og ikke død i krig. 
23:22:3: Dine høvdinger har alle sammen flyktet bort, uten bue er de fanget; alle som fantes i dig, er fanget, enda de hadde flyktet langt bort. 
23:22:4: Derfor sier jeg: Se bort fra mig! Jeg må gråte bittert. Treng ikke inn på mig for å trøste mig over mitt folks undergang! 
23:22:5: For en dag med forferdelse og nedtramping og forvirring holder Herren, Israels Gud, hærskarenes Gud, i Syne-dalen*; murer brytes ned, og skrik lyder op imot fjellet. # <* JES 22, 1.> 
23:22:6: Elam* bærer kogger, drar frem med stridsmenn på vogner og med ryttere, og Kir** har tatt dekket av sitt skjold. # <* JES 21, 2.> # <** 2KG 16, 9.> 
23:22:7: Dine herligste daler fylles med vogner, og rytterne stiller sig op imot portene. 
23:22:8: Så tar Herren dekket bort fra Juda, og du ser dig på den dag om efter rustningene i skoghuset*, # <* 1KG 7, 2 fg.> 
23:22:9: og I ser at Davids stad har mange revner, og I samler vannet i den nedre dam, 
23:22:10: og I teller Jerusalems hus, og I river husene ned for å styrke muren, 
23:22:11: og I gjør en grav mellem begge murene for vannet fra den gamle dam; men I ser ikke op til ham som gjorde dette*, og ham som uttenkte det for lang tid siden, ser I ikke. # <* det som rammer Jerusalem. 2KG 19, 25. JES 37, 26.> 
23:22:12: Herren, Israels Gud, hærskarenes Gud, kaller eder den dag til gråt og veklage og til å rake hodet og binde sekk om eder. 
23:22:13: Men se, der er fryd og glede; de slakter okser, de slakter får, de eter kjøtt og drikker vin; [de sier:] La oss ete og drikke, for imorgen dør vi! 
23:22:14: Men i mine ører lyder Herrens, hærskarenes Guds åpenbaring: Sannelig, denne misgjerning skal I ikke få utsonet så lenge I lever, sier Herren, Israels Gud, hærskarenes Gud. 
23:22:15: Så sier Herren, Israels Gud, hærskarenes Gud: Gå inn til denne overhovmester Sebna, han som står for huset, og si: 
23:22:16: Hvad har du her, og hvem har du her, siden du her har hugget dig ut en grav, du som hugger dig ut en grav i høiden, huler dig ut en bolig i berget? 
23:22:17: Se, Herren skal slynge dig, ja slynge dig bort, mann! Han skal rulle dig sammen, 
23:22:18: han skal nøste dig til et nøste og kaste dig som en ball bort til et vidtstrakt land; dit skal du, og der skal du dø, og dit skal dine herlige vogner, du skamflekk for din herres hus! 
23:22:19: Jeg vil støte dig bort fra din post, og fra din plass skal du bli kastet ned. 
23:22:20: Og på den dag vil jeg kalle min tjener Eljakim, Hilkias' sønn, 
23:22:21: og jeg vil klæ ham i din kjortel og binde ditt belte om ham og gi din makt i hans hånd, og han skal være en far for Jerusalems innbyggere og for Judas hus. 
23:22:22: Og jeg vil legge nøklen til Davids hus på hans skulder, og han skal lukke op, og ingen lukke til, og lukke til, og ingen lukke op. 
23:22:23: Og jeg vil feste ham som en nagle på et sikkert sted, og han skal bli et æressete for sin fars hus. 
23:22:24: Og de skal henge på ham hele tyngden av hans fars hus, de edle og de ville skudd, alle småkarene, både fatene og alle krukkene*. # <* d.e. hele hans ætt.> 
23:22:25: På den dag, sier Herren, hærskarenes Gud, skal den nagle som var festet på et sikkert sted, løsne; den skal hugges av og falle ned, og den byrde som hang på den, skal ødelegges; for Herren har talt. 
23:23:1: Utsagn om Tyrus. Jamre eder, I Tarsis-skib! For det er ødelagt, så der ikke mere er hus, og I ikke mere kan komme hjem; fra Kittims land blir det kunngjort for dem. 
23:23:2: Bli stille, I som bor på kysten som Sidons sjøfarende kjøbmenn opfylte! 
23:23:3: Av Sikors* sæd, av Nilens høst, som blev ført frem over det store hav, gjorde det** sig vinning, og det var et marked for folkene. # <* d.e. Nilen.> # <** Tyrus.> 
23:23:4: Skam dig, Sidon! - For havet, havets festning sier: Jeg har ikke hatt fødselsveer, heller ikke født, heller ikke opfødd unge menn eller fostret jomfruer. 
23:23:5: Når de får høre om dette i Egypten, skal de skjelve ved ryktet om Tyrus. 
23:23:6: Far over til Tarsis, jamre eder, I som bor på kysten! 
23:23:7: Er dette eders jublende by, som blev til i fordums dager, hvis føtter bærer den bort til å bo som fremmed i det fjerne? 
23:23:8: Hvem har besluttet dette mot Tyrus, mot henne som delte ut kroner, hun hvis kjøbmenn var fyrster, hvis kremmere var stormenn på jorden? 
23:23:9: Herren, hærskarenes Gud, har besluttet det for å vanhellige all stolt prakt, for å vanære alle jordens store. 
23:23:10: Bred dig ut over ditt land som strømmen*, du Tarsis' datter! Det er intet bånd mere. # <* d.e. Nilen.> 
23:23:11: Han har rakt ut sin hånd over havet, han har fått kongeriker til å skjelve; Herren har befalt å ødelegge Kana'ans faste borger. 
23:23:12: Han sa: Du skal ikke mere bli ved å juble, du voldtatte jomfru, Sidons datter! Bryt op, dra over til Kittim! Heller ikke der skal du finne ro. 
23:23:13: Se, kaldeernes land, dette folk som fordum ikke var til, de hvis land Assur har gjort til bolig for ørkenens dyr, de reiser sine beleiringstårn, omstyrter dets* palasser og gjør det til en grushaug. # <* Tyrus'.> 
23:23:14: Jamre eder, I Tarsis-skib! For ødelagt er eders faste borg. 
23:23:15: På den tid skal Tyrus bli glemt i sytti år, så lenge som en konges dager; når sytti år er til ende, skal det gå Tyrus som det heter i visen om skjøgen: 
23:23:16: Ta din citar, gå omkring i byen, du glemte skjøge! Spill det beste du kan, syng vise på vise, så folk kan komme dig i hu! 
23:23:17: Når de sytti år er til ende, da skal Herren ta sig av Tyrus, og det skal atter få ta imot horelønn, og det skal drive utukt med alle verdens riker over den vide jord. 
23:23:18: Men dets vinning og dets horelønn skal være helliget til Herren, den skal ikke legges op og ikke gjemmes; for de som bor for Herrens åsyn, skal få dets vinning og bruke den til å ete sig mette og klæ sig i prektige klær for. 
23:24:1: Se, Herren tømmer jorden og legger den øde; han omskifter dens skikkelse og adspreder dem som bor på den. 
23:24:2: Da går det presten som folket, herren som trælen, fruen som trælkvinnen, selgeren som kjøperen, låntageren som långiveren, ågerkaren som hans skyldner. 
23:24:3: Tømmes, ja tømmes skal jorden og plyndres, ja plyndres; for Herren har talt dette ord. 
23:24:4: Jorden sørger og visner bort; jorderike sykner og visner bort; de ypperste av dem som bor på jorden, sykner bort. 
23:24:5: Og jorden er vanhelliget under dem som bor på den; for de har krenket lovene, overtrådt budet, brutt den evige pakt. 
23:24:6: Derfor fortærer forbannelse jorden, og de som bor på den, må bøte; derfor brenner jordboerne, og det blir bare få mennesker igjen. 
23:24:7: Mosten visner, vintreet sykner bort; alle de som før var så hjerteglade, sukker nu. 
23:24:8: Det er forbi med gleden ved trommenes lyd, det er slutt med de jublendes larm; det er forbi med gleden ved citarens klang. 
23:24:9: De drikker ikke lenger vin under sang; besk er den sterke drikk for dem som drikker den. 
23:24:10: Nedbrutt er den øde by; stengt er hvert hus, så ingen kan gå inn. 
23:24:11: På gatene lyder klagerop over vinen; all glede er borte, landets fryd er blitt landflyktig. 
23:24:12: Tilbake i byen er bare ødeleggelse, og porten er slått i stumper og stykker. 
23:24:13: For således skal det gå til blandt folkene på jorden som når oliven slåes ned, som ved efterhøsten, når vinhøsten er forbi. 
23:24:14: De*, de skal opløfte sin røst og rope med fryd; over Herrens herlighet jubler de fra havet. # <* de som er blitt igjen.> 
23:24:15: Ær derfor Herren, I som bor i Østens land; ær Herrens, Israels Guds navn, I som bor på havets øer*! # <* Vesten.> 
23:24:16: Fra jordens ytterste kant hører vi lovsanger: Ære være den Rettferdige! Men jeg sier: Jeg forgår, jeg forgår, ve mig! Røvere røver, ja, røvere røver og plyndrer. 
23:24:17: Gru og grav og garn over dig, du som bor på jorden! 
23:24:18: Og det skal skje at den som flyr for den grufulle larm, skal falle i graven, og den som kommer op av graven, skal fanges i garnet; for slusene i det høie er åpnet, og jordens grunnvoller skjelver. 
23:24:19: Jorden brister, ja, den brister; jorden revner, ja, den revner; jorden rystes, ja, den rystes. 
23:24:20: Jorden skal rave som den drukne og svinges hit og dit som en hengekøi, og dens misgjerning skal gynge på den, og den skal falle og ikke reise sig mere. 
23:24:21: På den tid skal Herren hjemsøke himmelens hær i det høie og jordens konger nede på jorden; 
23:24:22: og de skal samles sammen som fanger i hulen og settes fast i fengslet, og langt om lenge skal de få sin straff. 
23:24:23: Og månen skal blyges, og solen skamme sig; for Herren, hærskarenes Gud, er konge på Sions berg og i Jerusalem, og for hans eldstes øine er det herlighet. 
23:25:1: Herre, du er min Gud; jeg vil ophøie dig, jeg vil prise ditt navn, for du har gjort underverk; dine råd fra gammel tid er sannhet og trofasthet. 
23:25:2: For du har gjort en by til en stenhaug, en fast by til en grusdynge, du har ødelagt de fremmedes palasser, så det ikke mere er nogen by; de skal aldri mere bygges op igjen. 
23:25:3: Derfor skal et sterkt folk ære dig, de ville hedningers stad skal frykte dig; 
23:25:4: for du har vært et vern for den ringe, et vern for den fattige i hans trengsel, et ly mot regnskyll, en skygge mot hete; for voldsmenns fnysen er som regnskyll mot en vegg. 
23:25:5: Som du demper hete i tørt land, så demper du de fremmedes bulder; som hete ved skyggen av en sky, så dempes voldsmenns sang. 
23:25:6: Og Herren, hærskarenes Gud, skal på dette fjell gjøre et gjestebud for alle folk, et gjestebud med fete retter, et gjestebud med gammel vin, med fete, margfulle retter, med klaret gammel vin. 
23:25:7: Og han skal på dette fjell tilintetgjøre det slør som omslører alle folkene, og det dekke som dekker alle hedningefolkene. 
23:25:8: Han skal opsluke døden for evig, og Herren, Israels Gud, skal tørke gråten av alle ansikter, og sitt folks vanære skal han ta bort fra hele jorden; for Herren har talt. 
23:25:9: På den tid skal de si: Se, der er vår Gud, han som vi ventet skulde frelse oss, dette er Herren som vi ventet på; la oss fryde og glede oss i hans frelse! 
23:25:10: For Herrens hånd skal hvile på dette fjell; men Moab skal tredes ned i sitt eget land, likesom halm tredes ned i gjødselvann. 
23:25:11: Og Moab skal brede ut sine hender der, likesom svømmeren breder ut sine hender for å svømme; men Herren skal kue dets stolthet til tross for dets henders kunstgrep. 
23:25:12: Og dine* murers høie festning skal han rive ned, omstyrte, jevne med jorden, så den ligger i støvet. # <* nemlig Moabs.> 
23:26:1: På den dag skal denne sang synges i Juda land: En sterk by har vi, frelse setter han til mur og vern. 
23:26:2: Lat op portene, så et rettferdig folk kan gå inn, et folk som holder fast ved sin troskap. 
23:26:3: Den som har et grunnfestet sinn, ham lar du alltid ha fred, for til dig setter han sin lit. 
23:26:4: Sett eders lit til Herren til alle tider! For i Herren, Israels Gud, har vi en evig klippe. 
23:26:5: For han har nedbøiet dem som bodde i det høie, den kneisende stad; han støtte den ned, ja støtte den ned til jorden, slo den ned i støvet. 
23:26:6: Den blev trådt under føtter, under de elendiges føtter, de ringes steg. 
23:26:7: Den rettferdiges sti er jevn; du jevner den rettferdiges vei. 
23:26:8: På dine dommers vei, Herre, ventet vi dig også; til ditt navn og ditt minne stod vår sjels attrå. 
23:26:9: Med min sjel lengtes jeg efter dig om natten, og med min ånd søkte jeg dig. For så snart dine dommer rammer jorden, lærer jordboerne rettferdighet. 
23:26:10: Dersom den ugudelige får nåde, så lærer han ikke rettferdighet; i rettvishets land gjør han urett, og han ser ikke Herrens høihet. 
23:26:11: Herre! Høit opløftet var din hånd, men de så det ikke; de fikk se din nidkjærhet for folket og blev til skamme; ja, ild fortærte dine fiender. 
23:26:12: Herre! Du skal hjelpe oss til fred; for alt det vi har gjort, har du utrettet for oss. 
23:26:13: Herre vår Gud! Andre herrer enn du har hersket over oss; ved dig alene priser vi ditt navn. 
23:26:14: Døde blir ikke levende, dødninger står ikke op*; derfor hjemsøker og ødelegger du dem og gjør hvert minne om dem til intet. # <* nemlig til dette liv. 5MO 32, 26. JBS 18, 17. SLM 9, 7; 88, 11 fg. OSP 10, 7.> 
23:26:15: Du øker folket, Herre! Du øker folket og viser din herlighet; du flytter alle landets grenser langt ut. 
23:26:16: Herre! I nøden søkte de dig; de opsendte stille bønner da din tukt kom over dem. 
23:26:17: Likesom en fruktsommelig kvinne vrir sig og skriker i sine veer når hun skal føde, således gikk det oss for din vredes skyld, Herre! 
23:26:18: Vi var fruktsommelige, vi vred oss; men da vi fødte, var det bare vind; frelse gav vi ikke landet, og ingen blev født til å bo på jorden. 
23:26:19: Dine døde skal bli levende, mine lik skal opstå; våkn op og juble, I som bor i støvet! For dugg over grønne urter er din dugg, og jorden gir dødninger tilbake til livet. 
23:26:20: Gå, mitt folk, gå inn i dine kammer og lukk dørene efter dig! Skjul dig et lite øieblikk, inntil vreden går over! 
23:26:21: For se, Herren går ut fra sitt sted for å hjemsøke jordboerne for deres misgjerning, og jorden skal la det blod som er utøst på den, komme for dagen og ikke mere dekke sine drepte. 
23:27:1: På den tid skal Herren med sitt sverd, det hårde og store og sterke, hjemsøke Leviatan, den lettfarende drage*, og Leviatan, den buktede drage, og han skal drepe uhyret som er i havet**. # <* JBS 26, 13.> # <** JES 19, 5.> 
23:27:2: På den tid [skal de synge:] Der er en edel vingård; syng om den! 
23:27:3: Jeg, Herren, er dens vokter, hvert øieblikk vanner jeg den; forat ikke nogen skal hjemsøke den, vokter jeg den dag og natt. 
23:27:4: Vrede har jeg ikke mot den. Hadde jeg torner og tistler mot mig i krig, da vilde jeg gå løs på dem og brenne dem op alle sammen, 
23:27:5: dersom de da ikke skulde søke vern hos mig, gjøre fred med mig, ja, gjøre fred med mig. 
23:27:6: I de kommende dager skal Jakob skyte røtter, Israel blomstre og få skudd, og de skal fylle jorderike med frukt. 
23:27:7: Har vel Herren slått Israel så hårdt som han slo den som slo ham? Eller blev han drept således som hans fiender blev drept? 
23:27:8: Med måte gikk du i rette med folket da du jaget det fra dig. Herren drev det bort med sitt hårde vær på østenvindens dag. 
23:27:9: Derfor blir Jakobs misgjerning utsonet, og det at hans synd blir tatt bort, gir full frukt, når alle alterstener blir knust som kalkstener, og Astarte-billeder og solstøtter ikke reiser sig mere. 
23:27:10: For den faste by ligger forlatt, en folketom bolig, øde som ørkenen. Kalver beiter der; der hviler de og fortærer dens kvister; 
23:27:11: når dens grener blir tørre, brytes de av, kvinner kommer og gjør op ild med dem. For dette er ikke noget forstandig folk; derfor forbarmer dets skaper sig ikke over det, og han som dannet det, er ikke nådig mot det. 
23:27:12: Og det skal skje på den tid at Herren skal slå ned frukter like fra Storelven* til Egyptens bekk, og I, Israels barn, I skal sankes op en for en. # <* d.e. Eufrat.> 
23:27:13: Og det skal skje på den tid at de skal støte i en stor basun, og da skal de komme de fortapte i Assurs land og de fordrevne i Egyptens land, og de skal tilbede Herren på det hellige berg i Jerusalem. 
23:28:1: Ve Efra'ims drukne menns stolte krone og den falmende blomst, hans fagre pryd, som troner over den fete dal*, der de ligger drukne av vin. # <* Samaria, Israels rikes hovedstad, lå på et fjell i en fruktbar dal; 1KG 16, 24.> 
23:28:2: Se, Herren sender en som er sterk og veldig*, lik en haglskur, en ødeleggende storm; som en flom av mektige, overstrømmende vann slår han alt til jorden med makt. # <* Salmanassar, som inntok Samaria. 2KG 18, 10. 11.> 
23:28:3: Med føtter skal den tredes ned, Efra'ims drukne menns stolte krone, 
23:28:4: og med den falmende blomst, hans fagre pryd, som troner over den fete dal, skal det gå som med den fiken som er moden før sommeren er der: Så snart en ser den, sluker han den, mens den ennu er i hans hånd. 
23:28:5: På den tid skal Herren, hærskarenes Gud, være en fager krone og en herlig krans for resten av sitt folk, 
23:28:6: og en doms ånd for den som sitter til doms, og styrke for dem som driver krigen tilbake til porten*. # <* til fiendens egen port.> 
23:28:7: Men også de som er her*, raver av vin og tumler av sterk drikk; prest og profet raver av sterk drikk, er overveldet av vin, tumler av sterk drikk; de raver i sine syner, vakler i sine dommer; # <* Judas førere.> 
23:28:8: for alle bord er fulle av vemmelig spy, det finnes ikke en ren flekk. 
23:28:9: Hvem* vil han lære kunnskap, og hvem vil han få til å forstå budskap? Er det barn som nettop er avvent fra melken, tatt bort fra brystet? # <* i dette og det følgende vers innføres de drukne førere talende.> 
23:28:10: For bud på bud*, bud på bud, regel på regel, regel på regel, litt her, litt der. # <* nemlig: kommer han med.> 
23:28:11: Ja, ved folk med stammende leber og i et annet tungemål skal han tale til dette folk, 
23:28:12: han som sa til dem: Dette er ro, unn den mødige ro, og dette er hvile. Men de vilde ikke høre. 
23:28:13: Så skal da Herrens ord bli dem bud på bud, bud på bud, regel på regel, regel på regel, litt her, litt der, så de skal gå og falle baklengs og knuses og bindes og fanges. 
23:28:14: Derfor hør Herrens ord, I spottere, I som hersker over folket her i Jerusalem! 
23:28:15: Fordi I sier: Vi har gjort en pakt med døden og et forbund med dødsriket; når den susende svepe farer frem, skal den ikke nå oss; for vi har gjort løgn til vår tilflukt og svik til vårt skjul - 
23:28:16: derfor sier Herren, Israels Gud, så: Se, jeg har lagt i Sion en grunnsten, en prøvet sten, en kostelig, fast hjørnesten; den som tror, haster ikke*. # <* er fri for angst og forferdelse.> 
23:28:17: Og jeg vil gjøre rett til målesnor og rettferdighet til lodd, og hagl skal rive bort løgnens tilflukt, og skjulet skal vannene skylle bort. 
23:28:18: Og eders pakt med døden skal slettes ut, og eders forbund med dødsriket skal ikke stå fast; når den susende svepe farer frem, da skal I bli trådt ned. 
23:28:19: Hver gang den farer frem, skal den rive eder bort; hver morgen skal den fare frem, både dag og natt, og det skal være bare redsel å forstå budskapet; 
23:28:20: for sengen er for kort til å strekke sig ut i, og teppet for smalt til å svøpe sig i. 
23:28:21: For Herren skal reise sig likesom ved Perasim-fjellet, som i dalen ved Gibeon skal han vredes, for å gjøre sin gjerning? en underlig gjerning, og utrette sitt arbeid, et uhørt arbeid. 
23:28:22: Og nu, spott ikke, forat ikke eders bånd skal bli strammet! For tilintetgjørelse og fast besluttet straffedom over hele jorden har jeg hørt fra Herren, Israels Gud, hærskarenes Gud. 
23:28:23: Vend øret til og hør min røst, gi akt og hør min tale! 
23:28:24: Når plogmannen vil så, holder han da alltid på med å pløie, åpne og harve sin jord? 
23:28:25: Når han har jevnet sin aker, spreder han da ikke ut dill og karve og sår hvete i rader og bygg på avmerket sted og spelt i kanten? 
23:28:26: Hans Gud har vist ham den rette måte, han lærte ham det. 
23:28:27: For en tresker ikke dill med treskeslede, ruller heller ikke vognhjul over karve; men med stav banker en ut dill, og karve med kjepp. 
23:28:28: Blir vel brødkorn knust? Nei, en tresker det ikke uavlatelig, og når en driver sine vognhjul og sine hester over det, knuser en det ikke. 
23:28:29: Også dette kommer fra Herren, hærskarenes Gud; han er underfull i råd, stor i visdom. 
23:29:1: Ve Ariel*, Ariel, du by hvor David slo leir! Legg år til år, la høitidene gå sin rundgang! # <* Guds løve (JES 33, 7); så kalles her Jerusalem.> 
23:29:2: Da vil jeg gjøre det trangt for Ariel, og der skal være sorg og jammer; men så skal det* bli mig et virkelig Ariel. # <* Jerusalem.> 
23:29:3: Jeg vil slå leir rundt omkring dig, og jeg vil kringsette dig med vaktposter og bygge voller mot dig. 
23:29:4: Da skal du tale lavmælt fra jorden, og fra støvet skal du mumle frem dine ord; og din røst skal lyde fra jorden likesom fra en dødningemaner, og fra støvet skal din tale hviskes. 
23:29:5: Men så skal dine fienders mengde bli som fint støv, og voldsmennenes flokk som fykende agner, og det skal skje i et øieblikk, med ett. 
23:29:6: Fra Herren, hærskarenes Gud, skal det komme en hjemsøkelse med bulder og brak og veldige drønn, hvirvelvind og storm og fortærende ildslue. 
23:29:7: Og mengden av alle de hedningefolk som strider mot Ariel, skal bli som en drøm, et syn om natten, alle de som strider mot det og dets borg, og som trenger inn på det. 
23:29:8: Og det skal gå som når den sultne drømmer og synes han eter, men når han våkner, da er han tom, og som når den tørste drømmer og synes han drikker, men når han våkner, se, da er han matt, og hans sjel blir maktløs; således skal det gå alle de mange hedningefolk som strider mot Sions berg. 
23:29:9: Stirr på hverandre og bli forvirret! Stirr eder blinde, og vær blinde! I er drukne, men ikke av vin; I raver, men ikke av sterk drikk. 
23:29:10: For Herren har utøst over eder en dyp søvns ånd, og han har tillukket eders øine, profetene, og tildekket eders hoder, seerne. 
23:29:11: Og således er synet av alt dette blitt eder likesom ordene i en forseglet bok; gir en den til en som skjønner skrift, og sier: Les dette! så sier han: Jeg kan ikke, for den er forseglet. 
23:29:12: Eller en rekker boken til en som ikke skjønner skrift, med de ord: Les dette! så sier han: Jeg skjønner ikke skrift. 
23:29:13: Og Herren sier: Fordi dette folk holder sig nær til mig med sin munn og ærer mig med sine leber, men holder sitt hjerte langt borte fra mig, og deres frykt for mig er et menneskebud som de har lært, 
23:29:14: se, derfor vil jeg bli ved å gå underlig frem mot dette folk, underlig og forunderlig, og dets vismenns visdom skal forgå, og dets forstandige menns forstand skal skjule sig. 
23:29:15: Ve dem som vil skjule i det dype for Herren hvad de har fore, og hvis gjerninger skjer i mørke, og som sier: Hvem ser oss, og hvem kjenner oss? 
23:29:16: Hvor forvendte I er! - Er pottemakeren å akte som hans ler, så verket kunde si om ham som gjorde det: Han har ikke gjort mig, og det som er laget, si om ham som laget det: Han skjønner det ikke? 
23:29:17: Ennu bare en liten stund, så skal Libanon bli til fruktbar mark, og den fruktbare mark aktes som skog. 
23:29:18: Og på den dag skal de døve høre bokens ord, og ut fra mulm og mørke skal de blindes øine se, 
23:29:19: Og de saktmodige skal glede sig enn mere i Herren, og de fattige blandt menneskene skal fryde sig i Israels Hellige; 
23:29:20: for det er forbi med voldsmannen, og det er ute med spotteren, og utryddet blir alle de som er årvåkne til å gjøre urett, 
23:29:21: de som gjør et menneske til synder for et ords skyld og legger snarer for den som hevder retten på tinget, og som ved løgn bøier retten for den rettferdige. 
23:29:22: Derfor sier Herren så til Jakobs hus, Herren som forløste Abraham: Nu skal Jakob ikke bli til skamme, og nu skal hans åsyn ikke blekne; 
23:29:23: for når han og hans barn ser mine henders gjerning i sin midte, så skal de hellige mitt navn, og de skal hellige Jakobs Hellige, og Israels Gud skal de frykte. 
23:29:24: Og de hvis ånd fór vill, skal lære forstand, og de knurrende skal ta imot lærdom. 
23:30:1: Ve de gjenstridige barn, sier Herren, de som utfører råd som ikke er fra mig, og inngår avtale uten min Ånd, så de legger synd til synd, 
23:30:2: de som drar ned til Egypten, uten at de har rådspurt mig, for å finne vern hos Farao og søke ly i Egyptens skygge! 
23:30:3: Men Faraos vern skal bli eder til skam, og det ly I søker i Egyptens skygge, til skjensel. 
23:30:4: For deres* fyrster er kommet til Soan**, og deres sendebud kommer like til Hanes; # <* Israels.> # <** JES 19, 11. 13. SLM 78, 12.> 
23:30:5: alle blir de til skamme ved et folk som ikke gagner dem, som ikke er til hjelp og ikke til gagn, men bare til skam og skjensel. 
23:30:6: Utsagn om dyrene som drar mot syd. Gjennem nøds og trengsels land, hvor løve og løvinne, huggorm og flyvende serafslange har sitt hjem, fører de sitt gods på aseners rygg og sine skatter på kamelers pukler til et folk som ikke gagner dem. 
23:30:7: Aldri nogen gang vil Egypten hjelpe; derfor kaller jeg det: storskryteren som sitter stille. 
23:30:8: Gå nu inn, skriv det på en tavle i deres påsyn og tegn det op i en bok, så det kan være for kommende dager, for alltid, til evig tid! 
23:30:9: For det er et gjenstridig folk, løgnaktige barn, barn som ikke vil høre Herrens lov, 
23:30:10: som sier til seerne: I skal ikke se, og til profetene: I skal ikke skue for oss det som rett er; tal smigrende ord til oss, sku synkvervinger, 
23:30:11: vik av fra veien, bøi av fra stien, få Israels Hellige bort fra vårt åsyn! 
23:30:12: Derfor sier Israels Hellige så: Fordi I har forkastet dette ord og satt eders lit til vold og krokveier og støttet eder på slikt - 
23:30:13: derfor skal denne misgjerning bli eder som et falleferdig stykke som skyver sig mere og mere frem på en høi mur, inntil muren med ett, i et øieblikk, styrter sammen og knuses; 
23:30:14: den knuses likesom en knuser et pottemakerkar, som en slår i stykker uten skånsel, så det blandt stykkene ikke finnes et skår til å hente ild med fra gruen eller øse vann med av brønnen. 
23:30:15: For så sa Herren, Israels Gud, Israels Hellige: Dersom I vender om og holder eder rolige, skal I frelses; i stillhet og i tillit skal eders styrke være. Men I vilde ikke. 
23:30:16: I sa: Nei, på hester vil vi flyve - derfor skal I flykte - og på rappføtte dyr vil vi ride - derfor skal eders forfølgere være rappe. 
23:30:17: Et tusen skal flykte for en trusel fra en mann; når bare fem mann truer, skal I flykte inntil eders levning er lik en flaggstang på toppen av et fjell og et banner på en haug. 
23:30:18: Og derfor bier Herren med å være eder nådig, og derfor holder han sig langt borte i det høie med sin barmhjertighet mot eder; for Herren er en dommens Gud; salige er alle de som bier på ham. 
23:30:19: For du folk som bor på Sion, i Jerusalem, du skal ikke bli ved å gråte; han vil være dig nådig når du roper; når han hører det, svarer han dig. 
23:30:20: Herren skal gi eder nødens brød og trengselens vann*, og da skal dine lærere ikke mere skjule sig, men dine øine skal se dine lærere, # <* 1KG 22, 27.> 
23:30:21: og når du viker av til høire eller til venstre, skal dine ører høre et ord lyde bak dig: Dette er veien, gå på den! 
23:30:22: Og du skal akte for urene sølvplatene på dine utskårne billeder og gullplatene på ditt støpte billede; du skal kaste det bort som skitne kluter; ut med dig! skal du si til det. 
23:30:23: Da skal han gi regn for sæden du sår i jorden, og brød av jordens grøde, og det skal være kraftig og saftig; din buskap skal på den dag belte på en vid eng. 
23:30:24: Og oksene og asenene som arbeider jorden, skal ete saltet blandingsfôr, som kastes med skuffe og kasteskovl. 
23:30:25: Og på hvert høit fjell og på hver høi bakke skal det være bekker, strømmer av vann, på den store slaktings dag, når tårnene faller. 
23:30:26: Og månens schmelk skal bli som solens schmelk, og solens schmelk skal bli syvfold klarere, som schmelken for syv dager, på den dag Herren læger sitt folks skade og forbinder såret av det slag det fikk. 
23:30:27: Se, Herrens navn kommer fra det fjerne, brennende er hans vrede, og tung er røken som stiger op; hans leber svulmer av harme, og hans tunge er som fortærende ild, 
23:30:28: og hans vredespust er som en overskyllende å som når til halsen; han vil sælde hedningefolkene i ødeleggelsens såld og legge et bissel i folkenes munn til å lede dem vill. 
23:30:29: Eders sang skal lyde som i den natt høitiden* blir innvidd, og det skal være hjertens glede, som når de går med fløitespill op til Herrens berg, til Israels klippe.  # <* påskehøitiden; 2MO 12, 42. SLM 42, 5.> 
23:30:30: Og Herren skal la høre sin veldige røst og vise sin nedfarende arm i brennende harme og fortærende ildslue, storm og regnskyll og hagl. 
23:30:31: For Herrens røst skal Assur forferdes, han som slår med kjeppen. 
23:30:32: Og hver gang den stav som Herren efter sitt råd lar falle på ham, farer ned, skal det skje under klang av trommer og citarer, og gang på gang skal han løfte sin arm og stride mot ham. 
23:30:33: For et brandsted er laget i stand for lenge siden; også for kongen er det laget i stand, dypt og vidt; dets bål har ild og ved i mengde; lik en svovelstrøm tender Herrens ånde det i brand. 
23:31:1: Ve dem som farer ned til Egypten efter hjelp og setter sin lit til hester og stoler på vogner fordi de er mange, og på hestfolk fordi de er så tallrike, men ikke vender sine øine til Israels Hellige og ikke søker Herren! 
23:31:2: Men også han er vis, han lar ulykke komme, og sine ord tar han ikke tilbake; men han reiser sig mot de ondes hus og mot deres hjelp som gjør urett. 
23:31:3: Og egypterne er mennesker og ikke Gud, og deres hester er kjøtt og ikke ånd, og Herren skal rekke ut sin hånd, og hjelperen skal snuble, og den hjulpne falle, og de skal omkomme alle sammen. 
23:31:4: For så sa Herren til mig: Likesom en løve, en ungløve, knurrer over sitt rov, om en hel flokk hyrder kalles sammen mot den, og ikke skremmes av deres skrik og ikke blir redd for den støi de gjør, således skal Herren, hærskarenes Gud, fare ned for å stride mot Sions berg og mot Jerusalem-haugen. 
23:31:5: Som fuglene breder ut sine vinger, således skal Herren, hærskarenes Gud, verne Jerusalem, verne og frelse, gå forbi* og redde. # <* som i Egypten; 2MO 12, 13. 23.> 
23:31:6: Vend om til ham som I er falt fra så dypt, I Israels barn! 
23:31:7: For på den tid skal hver av eder forkaste sine sølvguder og gullguder, som eders hender har gjort eder til å synde med. 
23:31:8: Og Assur skal falle for et sverd som ikke er en manns, og et sverd som ikke er et menneskes, skal fortære ham, og han skal rømme for sverdet, og hans unge menn skal bli træler. 
23:31:9: Og hans klippe* skal rømme i redsel, og hans fyrster skal skremmes bort fra sitt banner, sier Herren, han som har sin ild i Sion og sin ovn i Jerusalem. # <* Assyrias mektige konge; 2KG 19, 36 fg.> 
23:32:1: Se, med rettferdighet skal kongen regjere, og fyrstene skal styre efter rett, 
23:32:2: og enhver av dem skal være som et skjul for været og et ly mot regnskyll, som bekker i ørkenen, som skyggen av et veldig fjell i et tørstende land. 
23:32:3: Da skal de seendes øine ikke være blinde, og de hørendes ører skal være lydhøre, 
23:32:4: de lettsindiges hjerte skal formå å skjønne, og de stammendes tunge skal ha lett for å tale klart. 
23:32:5: Dåren skal ikke mere kalles edel, og den svikefulle skal ikke kalles høimodig. 
23:32:6: For dåren taler dårskap, og hans hjerte finner på ondt for å utføre vanhellig dåd og for å tale forvendte ting om Herren, for å la den hungrige sulte og la den tørste mangle drikke. 
23:32:7: Og den svikefulles våben er onde; han legger listige råd for å føre de saktmodige i ulykke ved falske ord, selv når den fattige taler det som rett er. 
23:32:8: Men den edle har edle tanker, og han blir fast ved det som er edelt. 
23:32:9: I sorgløse kvinner, stå op, hør min røst! I trygge døtre, vend eders ører til min tale! 
23:32:10: Om år og dag skal I beve, I trygge! For det er slutt med all vinhøst, frukthøsten kommer ikke. 
23:32:11: Forferdes, I sorgløse! Bev, I trygge! Klæ eder nakne og bind sekk om eders lender! 
23:32:12: De slår sig for brystet og klager over de fagre marker, over det fruktbare vintre. 
23:32:13: På mitt folks jord skyter torner og tistler op, ja over alle gledens hus i den jublende by. 
23:32:14: For palasset er forlatt, svermen i byen er borte; haug* og vakttårn er blitt til huler for evige tider, til fryd for villesler, til beite for buskap - # <* Ofel-haugen i Jerusalem; 2KR 27, 3. NEH 3, 26 fg.> 
23:32:15: inntil Ånden blir utøst over oss fra det høie, og ørkenen blir til fruktbar mark, og fruktbar mark aktes som skog. 
23:32:16: Og rett skal bo i ørkenen, og rettferdighet skal ha sitt hjem på den fruktbare mark. 
23:32:17: Og rettferdighetens verk skal være fred, og rettferdighetens frukt skal være ro og trygghet til evig tid. 
23:32:18: Og mitt folk skal bo i fredens bolig og i trygghetens telter og på sorgfrie hvilesteder. 
23:32:19: Men det skal hagle når skogen* styrter ned, og dypt skal byen trykkes ned. # <* fiendens veldige hær.> 
23:32:20: Lykkelige er I som sår ved alle vann, som slipper oksen og asenet på frifot! 
23:33:1: Ve dig, du ødelegger, som selv ikke er blitt ødelagt, og du røver som ingen har røvet noget fra! Når du er ferdig med å ødelegge, skal du bli ødelagt; når du har sluttet med å røve, skal andre røve fra dig. 
23:33:2: Herre, vær oss nådig! På dig bier vi; vær vår arm hver morgen, ja vår frelse i nødens tid! 
23:33:3: For tordenrøsten flyr folkene; når du reiser dig, spredes hedningene. 
23:33:4: Og eders bytte sankes, likesom skaveren* sanker; som gresshoppene springer, springer de efter det. # <* gresshoppen; JOE 1, 4.> 
23:33:5: Ophøiet er Herren, for han bor i det høie; han fyller Sion med rett og rettferdighet. 
23:33:6: Og det skal komme trygge tider for dig, en fylde av frelse, av visdom og kunnskap; Herrens frykt skal være dets* skatt. # <* Israels.> 
23:33:7: Se, deres løvehelter* skriker der ute, fredsbudene gråter sårt. # <* 2SA 23, 20.> 
23:33:8: Allfarveiene er øde, det er ingen veifarende mere - han* har brutt pakten**, foraktet byene***, ikke aktet noget menneske. # <* Sankerib.> # <** 2KG 18, 14 fg. 17 fg.> # <*** HAB 1, 10.> 
23:33:9: Landet visner og sykner bort; Libanon står skamfull og visner; Saron er som den øde mark, og Basan og Karmel ryster løvet av. 
23:33:10: Nu vil jeg stå op, sier Herren, nu vil jeg reise mig, nu vil jeg ikke lenger bli sittende. 
23:33:11: I skal være svangre med strå og føde halm; eders fnysen er en ild som skal fortære eder. 
23:33:12: Og folkeslag skal bli brent til kalk, de skal bli lik avskårne tornekvister som opbrennes med ild. 
23:33:13: Hør I som er langt borte, hvad jeg har gjort, og kjenn min styrke, I som er nær! 
23:33:14: Syndere bever på Sion, skjelving har grepet de gudløse: Hvem kan bo ved en fortærende ild? Hvem kan bo ved evige bål? 
23:33:15: Den som vandrer i rettferdighet og taler det som rett er, den som forakter det som vinnes ved urett og vold, den som ryster sine hender så han ikke rører ved gaver, som stopper sitt øre til for ikke å høre på blodråd, og som lukker sine øine for ikke å se på det som ondt er, 
23:33:16: han skal bo på høie steder, fjellfestninger er hans borg; sitt brød skal han få, vannet skal ikke slippe op for ham. 
23:33:17: Dine øine skal skue kongen i hans herlighet, de skal se et vidstrakt land. 
23:33:18: Ditt hjerte skal tenke tilbake på redselen: Hvor er han som skrev op? Hvor er han som veide pengene? Hvor er han som teller tårnene? 
23:33:19: Det ville folk skal du ikke se mere, folket med det vanskelige sprog som en ikke skjønner, med den stammende tunge som en ikke forstår. 
23:33:20: Se på Sion, våre høitidsstevners by! Dine øine skal se Jerusalem som en trygg bolig, som et telt som ikke flyttes, hvis peler aldri rykkes op, og hvis snorer aldri brister. 
23:33:21: Men der skal vi ha Herren, den Veldige, i stedet for brede elver og strømmer; ingen roskute skal gå der, intet mektig krigsskib fare der. 
23:33:22: For Herren er vår dommer, Herren er vår lovgiver, Herren er vår konge, han skal frelse oss. 
23:33:23: Slappe henger dine* taug, de støtter ikke masten, de holder ikke seilet utspent - da deles røvet bytte i mengde, endog de lamme røver og plyndrer. # <* fiendens.> 
23:33:24: Og ingen innbygger skal si: Jeg er syk. Det folk som bor der, har fått sin misgjerning forlatt. 
23:34:1: Kom hit, I hedningefolk, og hør, og I folkeslag, gi akt! Jorden høre og det som fyller den, jorderike og alt som gror der! 
23:34:2: For Herrens vrede er over alle hedningefolkene, og hans harme over all deres hær; han slår dem med bann, overgir dem til å slaktes ned. 
23:34:3: Og de som blir drept, skal slenges bort, og stanken av deres døde kropper skal stige op, og fjellene skal flyte av deres blod. 
23:34:4: Og hele himmelens hær skal smuldre bort, og himmelen rulles sammen som en bokrull, og all dens hær skal visne og falle ned, som bladet faller av vintreet og det visne løv av fikentreet. 
23:34:5: For mitt sverd er blitt drukkent* i himmelen; se, det skal fare ned over Edom til dom, over det folk jeg har slått med bann. # <* av Guds vrede.> 
23:34:6: Herrens sverd er fullt av blod, gjødd med fett, med blod av lam og bukker, med nyrefett av værer; for en offerslakting holder Herren i Bosra og en stor slakting i Edoms land. 
23:34:7: Og villokser skal styrte sammen med dem, og stuter sammen med sterke okser, og deres land skal bli drukkent av blod, og deres jord gjødd med fett; 
23:34:8: for fra Herren kommer en hevnens dag, et gjengjeldelsens år for Sions sak. 
23:34:9: Og Edoms bekker skal bli til bek, og dets jord til svovel; dets land skal bli til brennende bek. 
23:34:10: Hverken natt eller dag skal det slukne, til evig tid skal røken av det stige op; fra slekt til slekt skal det ligge øde, aldri i evighet drar nogen igjennem det. 
23:34:11: Pelikan og pinnsvin skal eie det, og hubro og ravn skal bo i det, og han skal utspenne over det ødeleggelsens målesnor og tilintetgjørelsens lodd. 
23:34:12: Det er ingen fornemme der, som kan utrope nogen til konge, og med alle dets fyrster er det forbi. 
23:34:13: Torner skal skyte op i dets palasser, nesler og tistler i dets festninger, og det skal være en bolig for sjakaler, et sted for strutser. 
23:34:14: Ville hunder og andre ørkendyr skal møtes der, og raggete troll skal rope til hverandre; ja, der slår Lilit* sig til ro og finner sig et hvilested. # <* en ond ånd. MTT 12, 43. APE 18, 2.> 
23:34:15: Der bygger pilormen rede og legger egg og klekker ut unger og samler dem i sin skygge; ja, der samler gribbene sig sammen. 
23:34:16: Se efter i Herrens bok og les! Ikke ett av disse dyr skal mangle, det ene skal ikke savne det annet; for hans munn byder det, og hans Ånd samler dem, 
23:34:17: og han kaster lodd for dem, og hans hånd tildeler dem det* med målesnor; til evig tid skal de eie det, fra slekt til slekt skal de bo i det. # <* Edoms land.> 
23:35:1: Ørkenen og det tørre land skal glede sig, og den øde mark skal juble og blomstre som en lilje. 
23:35:2: Den skal blomstre og juble, ja juble og synge med fryd; Libanons herlighet er gitt den, Karmels og Sarons prakt; de skal se Herrens herlighet, vår Guds prakt. 
23:35:3: Styrk de slappe hender og gjør de vaklende knær sterke! 
23:35:4: Si til de urolige hjerter: Vær frimodige, frykt ikke! Se, der er eders Gud! Hevnen kommer, Guds gjengjeldelse, han kommer selv og frelser eder. 
23:35:5: Da skal de blindes øine åpnes, og de døves ører oplates; 
23:35:6: da skal den lamme springe som en hjort, og den stummes tunge juble. For kilder bryter frem i ørkenen, og bekker i ødemarken, 
23:35:7: og det glødende sandhav skal bli til en sjø, og det tørste land til vannrike kilder; på det sted hvor sjakalene hvilte, vokser siv og rør. 
23:35:8: Og der skal være en ryddet vei, og den skal kalles den hellige vei, ingen uren skal gå på den, men den hører hans folk til; ingen veifarende, ikke engang dårer, skal fare vill. 
23:35:9: Der skal det ingen løve være, og intet rovdyr skal komme op på den, de skal ikke finnes der; men de gjenløste skal ferdes der. 
23:35:10: Og Herrens forløste skal vende tilbake og komme til Sion med frydesang, og evig glede er det over deres hode; fryd og glede skal de nå, og sorg og sukk skal fly. 
23:36:1: I kong Esekias' fjortende år drog kongen i Assyria Sankerib op imot alle Judas faste byer og inntok dem. 
23:36:2: ongen i Assyria sendte Rabsake med en stor hær fra Lakis til Jerusalem mot kong Esekias; han stanset ved vannledningen fra den øvre dam, ved allfarveien til Vaskervollen. 
23:36:3: Da gikk slottshøvdingen Eljakim, Hilkias' sønn, ut til ham sammen med statsskriveren Sebna og historieskriveren Joah, Asafs sønn. 
23:36:4: Og Rabsake sa til dem: Si til Esekias: Så sier den store konge, kongen i Assyria: Hvad er det for en trygghet som er kommet over dig? 
23:36:5: Jeg sier: Bare tomme ord [er det du sier]: Her er innsikt og styrke nok til krig. Til hvem setter du da din lit, siden du har gjort oprør mot mig? 
23:36:6: Vel, du setter din lit til Egypten, denne knekkede rørstav, som når en støtter sig på den, går inn i hans hånd og gjennemborer den; således er kongen i Egypten Farao for alle dem som setter sin lit til ham. 
23:36:7: Men sier du til mig: Vi setter vår lit til Herren vår Gud - er det da ikke hans offerhauger og altere Esekias har tatt bort, da han sa til Juda og Jerusalem: For dette alter skal I tilbede? 
23:36:8: Men gjør nu et veddemål med min herre, kongen i Assyria: Jeg vil gi dig to tusen hester om du kan få dig folk til å ride på dem. 
23:36:9: Hvorledes skulde du da kunne drive tilbake en eneste stattholder, en av min herres ringeste tjenere? Derfor setter du din lit til Egypten og håper å få vogner og hestfolk derfra. 
23:36:10: Mener du da at jeg har draget op mot dette land for å ødelegge det, uten at Herren har villet det? Nei, Herren har selv sagt til mig: Dra op mot dette land og ødelegg det! 
23:36:11: Da sa Eljakim og Sebna og Joah til Rabsake: Tal til dine tjenere på syrisk, for vi forstår det sprog, og tal ikke til oss på jødisk, så folket som står på muren, hører det! 
23:36:12: Men Rabsake svarte: Er det til din herre og til dig min herre har sendt mig for å tale disse ord? Er det ikke til de menn som sitter på muren og må ete sitt eget skarn og drikke sitt eget vann likesom I selv? 
23:36:13: Og Rabsake trådte frem og ropte med høi røst på jødisk og sa: Hør den store konges, den assyriske konges ord! 
23:36:14: Så sier kongen: La ikke Esekias få narret eder! For han makter ikke å redde eder. 
23:36:15: Og la ikke Esekias få eder til å sette eders lit til Herren, idet han sier: Herren vil redde oss, denne by skal ikke gis i kongen av Assyrias hånd. 
23:36:16: Hør ikke på Esekias! For så sier kongen i Assyria: Gjør fred med mig og kom ut til mig, så skal I få ete hver av sitt vintre og av sitt fikentre og drikke hver av vannet i sin brønn, 
23:36:17: til jeg kommer og henter eder til et land som ligner eders land, et land med korn og most, et land med brød og vingårder. 
23:36:18: La ikke Esekias forføre eder og si: Herren vil redde oss! Har vel nogen av folkenes guder reddet sitt land av kongen i Assyrias hånd? 
23:36:19: Hvor er Hamats og Arpads guder? Hvor er Sefarva'ims guder? Har vel Samarias guder reddet det av min hånd? 
23:36:20: Hvem er det blandt alle disse lands guder som har reddet sitt land av min hånd, så Herren skulde redde Jerusalem av min hånd? 
23:36:21: Men de tidde og svarte ham ikke et ord; for kongens bud lød så: I skal ikke svare ham. 
23:36:22: Så kom slottshøvdingen Eljakim, Hilkias' sønn, og statsskriveren Sebna og historieskriveren Joah, Asafs sønn, til Esekias med sønderrevne klær og meldte ham hvad Rabsake hadde sagt. 
23:37:1: Da kong Esekias hørte det, sønderrev han sine klær og klædde sig i sekk og gikk inn i Herrens hus. 
23:37:2: Og han sendte slottshøvdingen Eljakim og statsskriveren Sebna og de eldste blandt prestene, klædd i sekk, til profeten Esaias, Amos' sønn. 
23:37:3: Og de sa til ham: Så sier Esekias: En nødens og tuktelsens og vanærens dag er denne dag; barnet er kommet til modermunnen, men det er ingen kraft til å føde. 
23:37:4: Kanskje Herren din Gud vil høre det Rabsake har sagt, han som hans herre, kongen i Assyria, har sendt for å håne den levende Gud, så at han straffer ham for de ord Herren din Gud har hørt. Opsend da en bønn for den levning som ennu er igjen! 
23:37:5: Da kong Esekias' tjenere kom til Esaias, 
23:37:6: sa Esaias til dem: Så skal I si til eders herre: Så sier Herren: Frykt ikke for de ord du hørte, de som assyrerkongens tjenere hånte mig med! 
23:37:7: Se, jeg vil inngi ham et sådant sinn at han for et rykte han får høre, vender tilbake til sitt land; og jeg vil la ham falle for sverdet i sitt land. 
23:37:8: Da Rabsake vendte tilbake, fant han assyrerkongen i ferd med å stride mot Libna; for han hadde hørt at han hadde brutt op fra Lakis. 
23:37:9: Da hørte kongen si om Tirhaka, kongen i Etiopia, at han hadde draget ut for å stride mot ham. Og da han hørte det, sendte han bud til Esekias og sa: 
23:37:10: Så skal I si til Judas konge Esekias: La ikke din Gud, som du setter din lit til, få narret dig, så du tenker som så: Jerusalem skal ikke gis i assyrerkongens hånd. 
23:37:11: Du har hørt hvad kongene i Assyria har gjort med alle landene, at de har ødelagt dem, og du skulde bli reddet? 
23:37:12: Har vel folkenes guder frelst dem som mine fedre ødela, Gosan og Karan og Resef og Edens barn i Telassar? 
23:37:13: Hvor er nu Hamats konge og Arpads konge og byen Sefarva'ims konge, Henas og Ivvas konger? 
23:37:14: Da Esekias hadde mottatt brevet av sendebudene og lest det, gikk han op til Herrens hus, og der bredte Esekias det ut for Herrens åsyn. 
23:37:15: Og Esekias bad til Herren og sa: 
23:37:16: Herre, hærskarenes Gud, Israels Gud, du som troner på kjerubene! Du alene er Gud for alle jordens. riker, du har gjort himmelen og jorden. 
23:37:17: Herre, bøi ditt øre til og hør! Herre, oplat dine øine og se! Hør alle de ord som Sankerib har sendt for å håne den levende Gud! 
23:37:18: Det er sant, Herre, at kongene i Assyria har ødelagt alle folkene og deres land, 
23:37:19: og de har kastet deres guder i ilden; for de er ikke guder, men et verk av menneskehender, tre og sten, og derfor kunde de gjøre dem til intet. 
23:37:20: Men frels oss nu, Herre vår Gud, av hans hånd, så alle jordens riker må kjenne at du alene er Herren! 
23:37:21: Da sendte Esaias, Amos' sønn, bud til Esekias og lot si: Så sier Herren, Israels Gud: Vedkommende det du har bedt mig om mot kongen i Assyria Sankerib, 
23:37:22: da lyder det ord Herren har talt om ham således: Jomfruen, Sions datter, forakter dig, spotter dig; Jerusalems datter ryster på hodet efter dig. 
23:37:23: Hvem har du hånet og spottet, og mot hvem har du løftet din røst? Du har løftet dine øine til det høie, mot Israels Hellige. 
23:37:24: Ved dine tjenere har du hånet Herren og sagt: Med mine mange vogner drog jeg op på fjellenes tinder, til Libanons øverste topper, og jeg hugger ned dets høieste sedrer, dets herlige cypresser, og jeg trenger frem til dets øverste høide, dets frodige skog; 
23:37:25: jeg gravde brønner og drakk av dem, og jeg tørker ut alle Egyptens strømmer med mine fotsåler. 
23:37:26: Har du ikke hørt det? Fra fjerne tider har jeg gjort det, fra fordums dager har jeg laget det så. Nu har jeg latt det komme, så du har fatt makt til å ødelegge faste byer og gjøre dem til øde grushauger. # <* Herrens ord til Sankerib.> 
23:37:27: Og deres innbyggere blev maktløse; de blev forferdet og skamfulle; de blev som gresset på marken og som de grønne urter, som gresset på takene og som korn som er ødelagt av brand før det er fullvokset. 
23:37:28: Enten du sitter eller du går ut og inn, så vet jeg det, og jeg vet at du raser mot mig. 
23:37:29: Fordi du raser mot mig, og din overmodige trygghet har nådd op til mine ører, så vil jeg legge min ring i din nese og mitt bissel mellem dine leber og føre dig tilbake den vei du kom. 
23:37:30: Og dette skal du* ha til tegn: Iår skal I ete det korn som vokser av sig selv, og næste år det som skyter op fra roten; men i det tredje år skal I så og høste og plante vingårder og ete deres frukt. # <* Esekias.> 
23:37:31: Og den rest som har sloppet unda av Judas hus, skal skyte dypere rot nedentil og bære frukt oventil. 
23:37:32: For fra Jerusalem skal utgå en levning og fra Sions berg en rest som har sloppet unda; Herrens, hærskarenes Guds nidkjærhet skal gjøre dette. 
23:37:33: Derfor sier Herren så om kongen i Assyria: Han skal ikke komme inn i denne by og ikke skyte en pil inn i den, og han skal ikke rykke frem mot den med skjold og ikke opkaste voll mot den. 
23:37:34: Den vei han kom, skal han vende tilbake, og inn i denne by skal han ikke komme, sier Herren. 
23:37:35: Og jeg vil verne denne by og frelse den, for min skyld og for min tjener Davids skyld. 
23:37:36: Og Herrens engel gikk ut og slo hundre og fem og åtti tusen mann i assyrernes leir; og da folk stod op om morgenen, fikk de se dem alle ligge der som døde kropper. 
23:37:37: Da brøt kongen i Assyria Sankerib op og drog bort og vendte tilbake, og siden holdt han sig i ro i Ninive. 
23:37:38: Men da han engang tilbad i sin gud Nisroks hus, slo hans sønner Adrammelek og Sareser ham ihjel med sverd. De kom sig unda og flyktet til Ararats land, og hans sønn Asarhaddon blev konge i hans sted. 
23:38:1: Ved den tid blev Esekias dødssyk; da kom profeten Esaias, Amos' sønn, inn til ham og sa til ham: Så sier Herren: Beskikk ditt hus! For du skal dø og ikke leve lenger. 
23:38:2: Da vendte Esekias sitt ansikt mot veggen og bad til Herren 
23:38:3: og sa: Akk, Herre! kom dog i hu at jeg har vandret for ditt åsyn i trofasthet og med helt hjerte og gjort hvad godt er i dine øine! Og Esekias gråt høit. 
23:38:4: Da kom Herrens ord til Esaias, og det lød så: 
23:38:5: Gå og si til Esekias: Så sier Herren, din far Davids Gud: Jeg har hørt din bønn, jeg har sett dine tårer; se, jeg legger femten år til din alder. 
23:38:6: Og jeg vil redde dig og denne by av assyrerkongens hånd, og jeg vil verne denne by. 
23:38:7: Og dette skal du ha til tegn fra Herren på at Herren vil holde det han nu har lovt: 
23:38:8: Se, jeg lar solskivens skygge, som er gått ned med solen på Akas' solskive, gå ti streker tilbake. Og solen gikk tilbake ti av de streker den var gått ned. 
23:38:9: En sang, skrevet av Judas konge Esekias da han hadde vært syk, men hadde kommet sig av sin sykdom: 
23:38:10: Jeg sa: I mine rolige dager må jeg gå bort gjennem dødsrikets porter; jeg må bøte med resten av mine år. 
23:38:11: Jeg sa: Jeg skal ikke se Herren, Herren i de levendes land jeg skal ikke skue mennesker mere blandt dem som bor i det stille. 
23:38:12: Min bolig blir rykket op og ført bort fra mig som en hyrdes telt; jeg har rullet mitt liv sammen lik en vever, fra trådendene skjærer han* mig av; fra dag til natt gjør du det av med mig. # <* Gud.> 
23:38:13: Jeg fikk min sjel til å være stille inntil morgenen; som en løve knuser han alle mine ben; fra dag til natt gjør du det av med mig. 
23:38:14: Som en svale, som en trane, således klynket jeg, jeg kurret som en due; matte så mine øine mot det høie: Herre! Jeg er redd, gå i borgen for mig! 
23:38:15: Hvad skal jeg si? Han har både sagt mig det, og han har gjort det*; stille vil jeg vandre alle mine år efter min sjels bitre smerte. # <* JES 38, 4 fg.> 
23:38:16: Herre! Ved dem* lever mennesket, og ved dem blir alt min ånds liv opholdt; så gjør mig frisk og la mig leve! # <* ved dine ord; 5MO 8, 3. MTT 4, 4.> 
23:38:17: Se, til fred blev mig det bitre, ja det bitre, og kjærlig drog du min sjel op av tilintetgjørelsens grav; for du kastet alle mine synder bak din rygg. 
23:38:18: For ikke priser dødsriket dig, ikke lover døden dig; ikke venter de som farer ned i graven, på din trofasthet. 
23:38:19: De levende, de levende, de priser dig, som jeg idag; en far lærer sine barn om din trofasthet. 
23:38:20: Herren er rede til å frelse mig, og på mine strengeleker vil vi spille alle vårt livs dager i Herrens hus. 
23:38:21: Esaias sa at de skulde hente en fikenkake og legge den som plaster på bylden, så han kunde bli frisk igjen. 
23:38:22: Esekias sa*: Hvad skal jeg ha til tegn på at jeg skal gå op til Herrens hus? # <* da han hadde hørt det som er sagt i JES 38, 6, men før han hadde hørt det som er sagt i JES 38, 7.> 
23:39:1: På den tid sendte Merodak Baladan, Baladans sønn, kongen i Babel, brev og gaver til Esekias, da han hørte at han hadde vært syk og var blitt frisk igjen. 
23:39:2: Esekias gledet sig over dem* og lot dem få se sitt skattkammer, sølvet og gullet og krydderiene og den kostbare olje og hele sitt våbenhus og alt det som fantes i hans skattkammere; det var ikke den ting Esekias ikke lot dem få se i sitt hus og i hele sitt rike. # <* Babels konges sendebud.> 
23:39:3: Da kom profeten Esaias til kong Esekias og sa til ham: Hvad sa disse menn, og hvorfra kommer de til dig? Esekias svarte: De er kommet til mig fra et land langt borte, fra Babel. 
23:39:4: Og han sa: Hvad fikk de se i ditt hus? Esekias svarte: Alt det som er i mitt hus, har de fått se; det var ikke den ting jeg ikke lot dem få se i mine skattkammer. 
23:39:5: Da sa Esaias til Esekias: Hør Herrens, hærskarenes Guds ord: 
23:39:6: Se, de dager kommer da alt det som er i ditt hus, og alle de skatter dine fedre har samlet like til denne dag, skal føres bort til Babel; det skal ikke bli nogen ting tilbake, sier Herren. 
23:39:7: Og blandt dine sønner som skal utgå av dig, som du skal avle, skal det være nogen som blir tatt til hoffmenn i kongen av Babels palass. 
23:39:8: Da sa Esekias til Esaias: Det Herrens ord som du har talt, er godt. Så sa han: Det skal jo være fred og trygghet i mine dager. 
23:40:1: Trøst, trøst mitt folk, sier eders Gud. 
23:40:2: Tal vennlig til Jerusalem og rop til det at dets strid er endt, at dets skyld er betalt, at det av Herrens hånd har fått dobbelt for alle sine synder. 
23:40:3: Hør! Det er en som roper: Rydd i ørkenen vei for Herren! Gjør i ødemarken en jevn vei for vår Gud! 
23:40:4: Hver dal skal heves, og hvert fjell og hver haug skal senkes, det bakkete skal bli til slette, og hamrene til flatt land. 
23:40:5: Og Herrens herlighet skal åpenbares, og alt kjød skal se det, for Herrens munn har talt. 
23:40:6: Hør! Det er en som sier: Rop! Og en annen svarer: Hvad skal jeg rope? - Alt kjød er gress, og all dets herlighet som markens blomst. 
23:40:7: Gresset blir tørt, blomsten visner når Herrens ånde blåser på det; ja sannelig, folket er gress. 
23:40:8: Gresset blir tørt, blomsten visner; men vår Guds ord står fast til evig tid. 
23:40:9: Stig op på et høit fjell, du Sions gledesbud! Opløft din røst med kraft, du Jerusalems gledesbud! Opløft den, frykt ikke! Si til Judas byer: Se, der er eders Gud! 
23:40:10: Se, Herren, Israels Gud, kommer med velde, og hans arm råder; se, hans lønn er med ham, og hans gjengjeldelse går foran ham. 
23:40:11: Som en hyrde skal han vokte sin hjord; i sin arm skal han samle lammene, og ved sin barm skal han bære dem; de får som har lam, skal han lede. 
23:40:12: Hvem har målt vannene med sin hule hånd og utmålt himmelen med sine utspente fingrer og samlet jordens muld i skjeppe og veid fjell på vekt og hauger i vektskåler? 
23:40:13: Hvem har målt Herrens Ånd, og hvem lærer ham som hans rådgiver? 
23:40:14: Hvem har han rådført sig med, så han gav ham forstand og oplyste ham om den rette vei og gav ham kunnskap og lærte ham å kjenne visdoms vei? 
23:40:15: Se, folkeferd er som en dråpe av et spann, og som et støvgrand i en vektskål er de aktet; se, øene* er som det fine støv han lar fare til værs. # <* SLM 97, 1. JES 42, 10.> 
23:40:16: Libanon forslår ikke til brensel, og dets dyr forslår ikke til brennoffer. 
23:40:17: Alle folkene er som intet for ham; som ingenting og bare tomhet er de aktet av ham. 
23:40:18: Hvem vil I da ligne Gud med? Og hvad for et billede vil I sette ved siden av ham? 
23:40:19: Gudebilledet er støpt av en mester, og en gullsmed klær det med gull, og han støper sølvkjeder til det. 
23:40:20: Den som ikke har råd til en sådan gave, han velger tre som ikke råtner; han søker op en kyndig mester forat han skal få i stand et billede som står støtt. 
23:40:21: Skjønner I ikke? Hører I ikke? Er det ikke fra begynnelsen kunngjort for eder? Har I ikke forstått jordens grunnvoller? 
23:40:22: Han er jo den som troner over den vide jord, og de som bor på den, er som gresshopper; han er den som bredte ut himmelen som et tynt teppe og utspente den som et telt til å bo i, 
23:40:23: den som gjør fyrster til intet, ordens dommere til ingenting; 
23:40:24: neppe er de plantet, neppe er de sådd, neppe har deres stamme skutt rot i jorden, før han har blåst på dem, og de blir tørre, og en storm fører dem bort som strå. 
23:40:25: Hvem vil I da ligne mig med, så jeg skulde være ham lik? sier den Hellige. 
23:40:26: Løft eders øine mot det høie og se: Hvem har skapt disse ting? Han er den som fører deres hær ut i fastsatt tall, som kaller dem alle ved navn; på grunn av hans veldige kraft og hans mektige styrke savnes ikke én. 
23:40:27: Hvorfor vil du si, Jakob, og tale så, Israel: Min vei er skjult for Herren, og min rett går min Gud forbi? 
23:40:28: Vet du det ikke, eller har du ikke hørt det? Herren er en evig Gud, den som har skapt jordens ender; han blir ikke trett, og han blir ikke mødig; hans forstand er uransakelig. 
23:40:29: Han gir den trette kraft, og den som ingen krefter har, gir han stor styrke. 
23:40:30: Gutter blir trette og mødige, og unge menn snubler. 
23:40:31: Men de som venter på Herren, får ny kraft, løfter vingene som ørner; de løper og blir ikke trette, de går og blir ikke mødige. 
23:41:1: Ti og hør på mig, I øer! Og folkene, la dem iføre sig ny kraft, la dem komme hit og så tale! La oss sammen trede frem for retten! 
23:41:2: Hvem vakte fra Østen ham* som seieren møter hvor han setter sin fot? Han gir folkeslag i hans vold og lar ham herske over konger, gjør deres sverd til støv, deres bue til strå som føres bort av vinden. # <* Kyros; JES 45, 1.> 
23:41:3: Han* forfølger dem, drar frem i sikkerhet på en sti hvor han før ikke kom med sine føtter. # <* Kyros.> 
23:41:4: Hvem utrettet og gjorde dette? - Han som kalte slektene frem fra begynnelsen; jeg, Herren, er den første, og hos de siste er jeg også. 
23:41:5: Øene ser det og frykter, jordens ender bever; de rykker frem og kommer. 
23:41:6: Den ene hjelper den andre, og til sin bror sier han: Vær frimodig! 
23:41:7: Treskjæreren setter mot i gullsmeden, den som glatter med hammeren, i den som hamrer på ambolten, og han sier om loddingen: Den er god, og han fester billedet med spiker, forat det skal stå støtt. 
23:41:8: Og du Israel, min tjener, Jakob, som jeg utvalgte, ætling av min venn Abraham! 
23:41:9: Du som jeg tok ved hånden og hentet fra jordens ender og kalte fra dens ytterste kanter, og til hvem jeg sa: Du er min tjener, jeg har utvalgt dig og ikke forkastet dig! 
23:41:10: Frykt ikke, for jeg er med dig! Se dig ikke engstelig om, for jeg er din Gud! Jeg styrker dig og hjelper dig og holder dig oppe med min rettferds høire hånd. 
23:41:11: Se, de skal bli til spott og skam alle de som harmes på dig; de skal bli til intet og gå til grunne de menn som tretter med dig; 
23:41:12: du skal søke dem og ikke finne dem, de menn som kives med dig; de skal bli til intet og til ingenting de menn som fører krig mot dig. 
23:41:13: For jeg er Herren din Gud, som holder dig fast ved din høire hånd, som sier til dig: Frykt ikke! Jeg hjelper dig. 
23:41:14: Frykt ikke, Jakob, du usle makk, du Israels lille flokk! Jeg hjelper dig, sier Herren, og din gjenløser er Israels Hellige. 
23:41:15: Se, jeg gjør dig til en skarp, ny treskevogn med mange tagger; du skal treske fjell og knuse dem, og hauger skal du gjøre til agner. 
23:41:16: Du skal kaste dem med skovl, og vind skal føre dem bort, og storm skal sprede dem; men du skal fryde dig i Herren, rose dig av Israels Hellige. 
23:41:17: De elendige og fattige søker efter vann, men det er ikke noget; deres tunge brenner av tørst. Jeg, Herren, jeg vil svare dem; jeg, Israels Gud, jeg vil ikke forlate dem. 
23:41:18: Jeg vil la elver velle frem på bare hauger og kilder midt i daler; jeg vil gjøre en ørken til en sjø og et tørt land til vannrike kilder; 
23:41:19: Jeg vil la sedrer, akasier, myrter og oljetrær vokse frem i ørkenen; jeg vil la cypress, lønn og buksbom sammen gro på den øde mark, 
23:41:20: forat de alle sammen skal se og kjenne og legge sig på hjerte og forstå at Herrens hånd har gjort dette, og Israels Hellige skapt det. 
23:41:21: Kom hit med eders sak, sier Herren; kom frem med eders bevisgrunner, sier Jakobs konge. 
23:41:22: La dem* komme frem med dem og kunngjøre for oss hvad som skal hende! Kunngjør hvad det er I tidligere har spådd, så vi kan akte på det og lære dets utfall å kjenne! Eller la oss høre de tilkommende ting! # <* avgudene. JES 43, 9; 44, 7.> 
23:41:23: Kunngjør hvad som skal komme herefter, så vi kan vite at I er guder! Ja, gjør noget godt eller noget ondt, så vi med forundring kan se det alle sammen! 
23:41:24: Se, I er intet, og eders gjerning ingenting; en vederstyggelighet er den som velger eder. 
23:41:25: Jeg vakte ham fra Norden, og han kom, fra solens opgang han som skal påkalle mitt navn; han skal trampe på fyrster som de var ler, lik en pottemaker som elter dynd. 
23:41:26: Hvem har kunngjort dette fra begynnelsen, så vi kunde vite det, og fra fordums tid, så vi kunde si: Han har rett? Ingen har kunngjort noget, ingen har forkynt noget, og ingen har hørt eder si noget. 
23:41:27: Jeg er den første som sier til Sion: Se, se, der er de, og som sender Jerusalem et gledesbud. 
23:41:28: Jeg ser mig om, men der er ingen, og ingen av dem kan gi råd, så jeg kunde spørre dem, og de gi mig svar. 
23:41:29: Se dem alle! Deres gjerninger er intet, ingenting; deres billeder er vind og tomhet. 
23:42:1: Se min tjener, som jeg støtter, min utvalgte, som min sjel har velbehag i! Jeg legger min Ånd på ham, han skal føre rett* ut til hedningefolkene. # <* d.e. Guds lov; JES 42, 4.> 
23:42:2: Han skal ikke skrike og ikke rope, og han skal ikke la sin røst høre på gaten. 
23:42:3: Det knekkede rør skal han ikke knuse, og den rykende tande skal han ikke slukke; på rette måte skal han føre retten ut til dem. 
23:42:4: Han skal ikke bli mødig, og hans kraft ikke bli knekket før han får grunnlagt retten på jorden; og på hans lov venter øene. 
23:42:5: Så sier Gud Herren, som skapte himmelen og utspente den, som bredte ut jorden med det som gror på den, som gir ånde til folket som bor på den, og ånd til dem som ferdes på den: 
23:42:6: Jeg, Herren, har kalt dig i rettferd og tatt dig ved hånden, og jeg vil verne dig og gjøre dig til en pakt for folket, til et lys for hedningene, 
23:42:7: forat du skal åpne blinde øine, føre de bundne ut av fengslet og føre dem som sitter i mørke, ut av fangehuset. 
23:42:8: Jeg er Herren, det er mitt navn, og jeg gir ikke nogen annen min ære eller de utskårne billeder min pris. 
23:42:9: De ting som er forkynt tidligere, se, de er kommet, og nu forkynner jeg nye ting; før de spirer frem, lar jeg eder høre om dem. 
23:42:10: Syng Herren en ny sang, hans pris fra jordens ende, I som farer ut på havet, og alt som fyller det, I øer og I som bor der! 
23:42:11: Ørkenen og dens byer skal opløfte røsten, de teltbyer hvor Kedar* bor; de som bor på fjellet, skal juble, fra fjelltoppene skal de rope høit. # <* SLM 120, 5. JES 60, 7.> 
23:42:12: De skal gi Herren ære og forkynne hans pris på øene. 
23:42:13: Herren skal dra ut som en kjempe, som en krigsmann egge sin harme; han skal rope høit, ja skrike; mot sine fiender skal han vise sin makt. 
23:42:14: Jeg har tidd fra eldgammel tid, jeg talte ikke, jeg holdt mig tilbake. Nu vil jeg skrike som den fødende kvinne, jeg vil puste og fnyse. 
23:42:15: Jeg vil legge fjell og hauger øde og la alle deres urter tørke bort, og jeg vil gjøre elver til land og tørke ut sjøer. 
23:42:16: Og jeg vil føre blinde på en vei de ikke kjenner; på stier de ikke kjenner, vil jeg la dem ferdes; jeg vil gjøre mørke steder for deres åsyn til lys og bakker til sletteland. Dette er de ting jeg vil gjøre, jeg vil visselig ikke la det være. 
23:42:17: De skal vike tilbake og bli storlig til skamme de som setter sin lit til utskårne billeder, som sier til støpte billeder: I er våre guder. 
23:42:18: I døve, hør! Og I blinde, lukk øinene op og se! 
23:42:19: Hvem er blind uten min tjener* og døv som det bud som jeg sender? Hvem er blind som min fortrolige venn, blind som Herrens tjener? # <* Israel.> 
23:42:20: Du har sett meget, men du tar ikke vare på det, han har åpne ører, men han hører ikke. 
23:42:21: Det er Herrens vilje å gjøre loven stor og herlig for sin rettferdighets skyld. 
23:42:22: Men det er et røvet og plyndret folk; de ligger alle sammen bundet i huler og skjult i fangehus; de blev røvet, og ingen reddet dem, de blev plyndret, og ingen sa: Gi tilbake! 
23:42:23: Hvem blandt eder vil herefter vende øret til dette, gi akt og høre på det? 
23:42:24: Hvem har overgitt Jakob til plyndring og Israel til røvere? Var det ikke Herren, han som vi syndet mot, og på hvis veier de ikke vilde vandre, og på hvis lov de ikke hørte? 
23:42:25: Så utøste han over dem sin brennende vrede og en veldig krig; den satte dem i brand rundt omkring, men de forstod det ikke, den brente dem, men de la det ikke på hjerte. 
23:43:1: Og nu, så sier Herren, som skapte dig, Jakob, og som dannet dig, Israel: Frykt ikke! Jeg har gjenløst dig, kalt dig ved navn, du er min. 
23:43:2: Når du går gjennem vann, så er jeg med dig, og gjennem elver, så skal de ikke overskylle dig; når du går gjennem ild, skal du ikke svies, og luen skal ikke brenne dig; 
23:43:3: for jeg er Herren din Gud, Israels Hellige, din frelser; jeg gir Egypten til løsepenger for dig, Etiopia og Seba gir jeg i ditt sted. 
23:43:4: Fordi du er dyrebar i mine øine, fordi du er aktet høit og jeg elsker dig, så gir jeg mennesker i ditt sted og folkeslag i stedet for ditt liv. 
23:43:5: Frykt ikke! Jeg er med dig. Fra Østen vil jeg la din ætt komme, og fra Vesten vil jeg samle dig. 
23:43:6: Jeg vil si til Norden: Gi dem hit, og til Syden: Hold dem ikke tilbake, la mine sønner komme fra det fjerne og mine døtre fra jordens ende, 
23:43:7: hver den som nevnes med mitt navn, og som jeg har skapt til min ære, som jeg har dannet og gjort. 
23:43:8: For frem et blindt folk som dog har øine, og døve som dog har ører! 
23:43:9: La alle hedningefolk samle sig, alle folkeferd komme sammen! Hvem blandt dem* er det som kunngjør slikt**? La dem si oss hvad de tidligere har spådd***! La dem stille sine vidner, så de kan få rett, la dem høre og si: Det er sannhet! # <* avgudene.> # <** JES 43, 5 fg.> # <*** JES 41, 22.> 
23:43:10: I er mine vidner, sier Herren, og min tjener, som jeg har utvalgt, forat I skal kjenne og tro mig og forstå at jeg er Gud*; før mig er ingen gud blitt til, og efter mig skal det ingen komme. # <* 5MO 32, 39.>
23:43:11: Jeg, jeg er Herren, og foruten mig er det ingen frelser. 
23:43:12: Jeg har forkynt det og frelst, jeg har kunngjort det, og der var ingen fremmed gud blandt eder. I er mine vidner, sier Herren, og jeg er Gud. 
23:43:13: Endog fra dag blev til, er jeg det, og det er ingen som redder av min hånd; jeg gjør en gjerning, og hvem gjør den ugjort? 
23:43:14: Så sier Herren, eders gjenløser, Israels Hellige: For eders skyld sender jeg bud til Babel og lar dem alle sammen flykte nedover elven, jeg lar kaldeerne flykte på de skib som var deres lyst. 
23:43:15: Jeg er Herren, eders Hellige, Israels skaper, eders konge. 
23:43:16: Så sier Herren, som gjorde vei i havet og sti i mektige vann, 
23:43:17: som lot vogner og hester, hær og krigsmakt dra ut - alle sammen ligger de der, de står ikke op, de er slukket, som en tande sluknet de - : 
23:43:18: Kom ikke i hu de forrige ting, akt ikke på fortiden! 
23:43:19: Se, jeg gjør noget nytt, nu skal det spire frem; skal I ikke opleve det? Ja, jeg vil gjøre vei i ørkenen, strømmer i ødemarken. 
23:43:20: Markens ville dyr, sjakaler og strutser, skal ære mig fordi jeg gir vann i ørkenen, strømmer i ødemarken, så mitt folk, mine utvalgte, kan drikke. 
23:43:21: Det folk jeg har dannet mig, skal forkynne min pris. 
23:43:22: Men mig har du ikke påkalt; Jakob, så du gjorde dig møie for mig, Israel! 
23:43:23: Du har ikke gitt mig dine brennoffers får og ikke æret mig med dine slaktoffer; jeg har ikke trettet dig med matoffer og ikke voldt dig møie med virak. 
23:43:24: Du har ikke kjøpt mig Kalmus* for sølv og ikke mettet mig med dine slaktoffers fedme; du har bare trettet mig med dine synder, voldt mig møie med dine misgjerninger. # <* 2MO 30, 23.> 
23:43:25: Jeg, jeg er den som utsletter dine misgjerninger for min skyld, og dine synder kommer jeg ikke i hu. 
23:43:26: Minn mig*, la oss gå i rette med hverandre! Fortell du, så du kan få rett! # <* om det du har gjort for mig.> 
23:43:27: Din første far syndet, og dine talsmenn falt fra mig; 
23:43:28: så vanhelliget jeg de hellige høvdinger og overgav Jakob til bann og Israel til spott. 
23:44:1: Men hør nu, Jakob, min tjener, og Israel, som jeg har utvalgt! 
23:44:2: Så sier Herren, som skapte dig og dannet dig fra mors liv, som hjelper dig: Frykt ikke, min tjener Jakob, Jesurun* som jeg har utvalgt! # <* 5MO 32, 15.> 
23:44:3: For jeg vil øse vann over det tørste og strømmer over det tørre; jeg vil utgyde min Ånd over din sæd og min velsignelse over dine spirer, 
23:44:4: så de vokser op som iblandt gress, som piletrær ved bekkene. 
23:44:5: En skal si: Jeg hører Herren til, en annen skal kalle sig med Jakobs navn, en tredje skal skrive med sin hånd: Jeg hører Herren til, og Israel skal han bruke som hedersnavn. 
23:44:6: Så sier Herren, Israels konge og gjenløser, Herren, hærskarenes Gud: Jeg er den første, og jeg er den siste, og foruten mig er det ingen Gud. 
23:44:7: Hvem forkynner som jeg det som skal skje? La ham kunngjøre og legge frem for mig det han har forkynt, like siden jeg skapte oldtidens ætt! Og det som i fremtiden skal komme - la dem kunngjøre det! 
23:44:8: Frykt ikke og forferdes ikke! Har jeg ikke for lenge siden latt dig høre det og forkynt dig det? I er mine vidner. Er det nogen Gud foruten mig? Det er ingen klippe, jeg kjenner ingen. 
23:44:9: De som lager utskårne billeder, er alle sammen intet, og deres kjære guder er til ingen nytte, og de som gir dem vidnesbyrd, ser ikke og skjønner ikke, så de skal bli til skamme. 
23:44:10: Hvem har dannet en gud og støpt et billede - til ingen nytte? 
23:44:11: Se, alle de som holder sig til det, skal bli til skamme, og mestrene selv er jo mennesker; la dem samle sig alle sammen, la dem trede frem! De skal reddes, de skal bli til skamme alle sammen. 
23:44:12: Smeden bruker sin meisel og arbeider ved kullild; han former billedet med hamrer, han arbeider på det med sin kraftige arm; han blir sulten under arbeidet og kraftløs, han drikker ikke vann og blir slapp. 
23:44:13: Treskjæreren spenner ut en målesnor, risser av med en stift, arbeider treet med en kniv og risser av med passer, og han lager det til som billedet av en mann, som en prektig menneskeskikkelse, til å bo i et hus. 
23:44:14: En gir sig til å felle sedrer, og han tar stenek og ek og velger sig ut et blandt skogens trær; han planter en furu, og regnet får den til å vokse. 
23:44:15: Og så tjener treet mennesket til brenne; han tar av det og varmer sig med det; han fyrer også og baker brød med det; han gjør også en gud av det, som han tilbeder, han gjør et utskåret billede av det, som han faller ned for. 
23:44:16: Halvdelen av det brenner han op med ild; med halvdelen av det tillager han det kjøtt han skal ete, han steker en stek og metter sig; han varmer sig også og sier: Å! Jeg er blitt varm, jeg kjenner ilden. 
23:44:17: Resten av det gjør han til en gud, til sitt utskårne billede; han faller ned for det og tilbeder det; han ber til det og sier: Frels mig, for du er min gud! 
23:44:18: De skjønner intet og forstår intet; for han* har klint deres øine til, så de ikke ser, forherdet deres hjerter, så de ikke forstår. # <* Gud; JES 6, 10.> 
23:44:19: Han* tar sig det ikke til hjerte, og han har ikke klokskap og forstand nok til å si: Halvdelen av det har jeg brent op med ild, jeg har også bakt brød over glørne av det, jeg steker kjøtt og eter, og resten av det skulde jeg gjøre til en vederstyggelighet, for en treklubbe skulde jeg falle ned! # <* avgudsdyrkeren.> 
23:44:20: Den som holder sig til det som bare er aske, ham har et dåret hjerte ført vill, og han redder ikke sin sjel og sier ikke: Er det ikke løgn det jeg har i min høire hånd? 
23:44:21: Kom dette i hu, Jakob! Kom det i hu, Israel! For du er min tjener; jeg har dannet dig, du er min tjener; Israel, du skal ikke bli glemt av mig. 
23:44:22: Jeg utsletter dine overtredelser som en tåke og dine synder som en sky; vend om til mig, for jeg gjenløser dig! 
23:44:23: Juble, I himler! For Herren utfører sitt verk. Rop med fryd, I jordens dybder! Bryt ut i jubel, I fjell, du skog, hvert tre i dig! For Herren gjenløser Jakob, og på Israel vil han åpenbare sin herlighet. 
23:44:24: Så sier Herren, din gjenløser, han som dannet dig fra mors liv: Jeg er Herren, som gjør alle ting, som utspente himmelen alene, som bredte ut jorden uten hjelp fra nogen, 
23:44:25: som gjør snakkernes tegn til intet og spåmennene til dårer, som driver de vise tilbake og gjør deres visdom til dårskap, 
23:44:26: som stadfester sin tjeners ord og fullbyrder sine sendebuds råd*, som sier om Jerusalem: Der skal bo folk, og om Judas byer: De skal bygges op igjen, deres ruiner vil jeg reise, # <* d.e. sine rådslutninger som profetene har forkynt.> 
23:44:27: som sier til dypet: Bli tørt, dine strømmer vil jeg tørke ut, 
23:44:28: som sier om Kyros: Han er min hyrde; all min vilje skal han fullbyrde og si om Jerusalem: Det skal bygges op igjen, og templet skal bli grunnlagt. 
23:45:1: Så sier Herren til sin salvede, til Kyros, som jeg holder i hans høire hånd for å kaste hedningefolk ned for ham og løse beltet fra kongers lender, for å åpne dører for ham, og forat ingen porter skal holdes stengt: 
23:45:2: Jeg vil gå frem foran dig, og bakker vil jeg jevne; dører av kobber vil jeg sprenge, og bommer av jern vil jeg sønderhugge. 
23:45:3: Og jeg vil gi dig skatter som er skjult i mørket, og rikdommer som er gjemt på lønnlige steder, så du kan vite at jeg er Herren, som kalte dig ved navn, Israels Gud. 
23:45:4: For Jakobs, min tjeners, og for Israels, min utvalgtes skyld kalte jeg dig ved navn, gav jeg dig hedersnavn, enda du ikke kjente mig. 
23:45:5: Jeg er Herren, og det er ingen annen; foruten mig er det ingen Gud. Jeg omgjordet dig, enda du ikke kjente mig, 
23:45:6: forat de både i øst og i vest skal vite at det er ingen foruten mig; jeg er Herren, og det er ingen annen, 
23:45:7: jeg som er lysets ophav og mørkets skaper, som gir lykken og skaper ulykken; jeg, Herren, gjør alt dette. 
23:45:8: Drypp, I himler, fra oven, og fra skyene strømme rettferdighet ned! Jorden skal åpne sig og bære frelse som frukt, og rettferdighet skal den tillike la spire frem. Jeg, Herren, skaper det. 
23:45:9: Ve den som tretter med sin skaper, et skår blandt andre skår av jord! Kan leret si til ham som former det: Hvad gjør du? Og kan ditt verk si om dig: Han har ingen hender? 
23:45:10: Ve den som sier til sin far: Hvorfor avler du? og til kvinnen: Hvorfor føder du? 
23:45:11: Så sier Herren, Israels Hellige, han som skapte det: Spør mig om de kommende ting! La mig dra omsorg for mine barn og for mine henders verk! 
23:45:12: Jeg har gjort jorden, og menneskene på den har jeg skapt; mine hender er det som har utspent himmelen, og all dens hær har jeg latt fremstå. 
23:45:13: Jeg har opvakt ham* i rettferd, og alle hans veier vil jeg jevne; han skal bygge op min by og løslate mitt bortførte folk, ikke for betaling og ikke for gave, sier Herren, hærskarenes Gud. # <* Kyros; JES 45, 1.> 
23:45:14: Så sier Herren: Egyptens gods og Etiopias vinning og sabeerne, de høivoksne menn, skal komme til dig, og dig skal de tilhøre, dig skal de følge, i lenker skal de gå, og for dig skal de kaste sig ned, dig skal de bønnfalle: Bare hos dig er Gud, og det er ingen annen, ingen annen Gud. 
23:45:15: Sannelig, du er en Gud som skjuler sig, du Israels Gud, du frelser! 
23:45:16: De blir alle sammen til spott og skam; de går alle sammen bort med vanære, avgudsbilledenes mestre. 
23:45:17: Israel blir frelst ved Herren med en evig frelse; I skal i all evighet ikke bli til spott og skam. 
23:45:18: For så sier Herren, som skapte himmelen, han som er Gud, han som dannet jorden og gjorde den, han som grunnfestet den, han som ikke skapte den til å være øde, men dannet den til bolig for folk: Jeg er Herren, og det er ingen annen. 
23:45:19: Ikke i lønndom har jeg talt, ikke på et sted i et mørkt land; jeg har ikke sagt til Jakobs ætt: Søk mig uten nytte! Jeg, Herren, taler rettferdighet, forkynner det som rett er. 
23:45:20: Samle eder og kom, tred frem alle sammen, I som er sloppet unda hedningefolkene! De vet intet de som bærer sine trebilleder og ber til en gud som ikke kan frelse. 
23:45:21: Meld og si fra! Ja, la dem rådslå alle sammen! Hvem har kunngjort dette fra fordums tid, forkynt det for lenge siden? Mon ikke jeg, Herren? Og det er ingen annen Gud foruten mig; en rettferdig og frelsende Gud er det ikke foruten mig. 
23:45:22: Vend eder til mig og bli frelst, alle jordens ender! For jeg er Gud, og ingen annen. 
23:45:23: Ved mig selv har jeg svoret, et sannhetsord er utgått av min munn, et ord som ikke skal vende tilbake: For mig skal hvert kne bøie sig, mig skal hver tunge sverge troskap. 
23:45:24: Bare i Herren, skal de si om mig, er rettferdighet og styrke; til ham skal de komme, og til skamme skal de bli alle de som brenner av harme mot ham. 
23:45:25: Ved Herren skal de få sin rett, og av ham skal de rose sig, hele Israels folk. 
23:46:1: Bel* segner, Nebo faller om; deres billeder overgis til dyr og fe; de som I bar**, legges som en byrde på de trette dyr. # <* to av Babels avguder; JER 50, 2.> # <** JES 45, 20.> 
23:46:2: De faller om, segner alle sammen, de kan ikke redde byrden, og selv går de bort i fangenskap. 
23:46:3: Hør på mig, I av Jakobs hus og alle I som er blitt igjen av Israels hus, I som er lagt på mig fra mors liv, som jeg har båret fra mors skjød! 
23:46:4: Like til eders alderdom er jeg den samme, og til I får grå hår, vil jeg bære eder; jeg har gjort det, og jeg vil fremdeles løfte eder, jeg vil bære og redde eder. 
23:46:5: Hvem vil I ligne mig med og stille mig sammen med? Hvem vil I sammenligne mig med, så vi skulde være like? 
23:46:6: De ryster gull ut av pungen og veier sølv på vekten, de leier en gullsmed som skal gjøre det til en gud, så de kan falle ned og tilbede; 
23:46:7: de løfter ham op, de bærer ham på skulderen og setter ham på hans plass, og han står der og flytter sig ikke fra sitt sted; om nogen roper til ham, svarer han ikke og redder ham ikke av nød. 
23:46:8: Kom dette i hu og vær faste! Ta det til hjerte, I overtredere! 
23:46:9: Kom i hu de forrige ting fra gammel tid, at jeg er Gud, og ingen annen, at jeg er Gud, og at det er ingen som jeg, 
23:46:10: jeg som fra begynnelsen forkynner enden, og fra fordums tid det som ikke er skjedd, jeg som sier: Mitt råd skal bli fullbyrdet, og alt det jeg vil, det gjør jeg, 
23:46:11: jeg som kaller fra Østen en rovfugl, fra et land langt borte en mann som skal fullbyrde mitt råd; jeg har både sagt det og vil la det komme; jeg har uttenkt det, jeg vil også gjøre det. 
23:46:12: Hør på mig, I sterke ånder, I som er langt borte fra rettferdighet! 
23:46:13: Jeg lar min rettferdighet komme nær, den er ikke langt borte, og min frelse dryger ikke; jeg gir frelse i Sion og min herlighet til Israel. 
23:47:1: Stig ned og sett dig i støvet, du jomfru, Babels datter! Sett dig på jorden uten trone, du kaldeernes datter! For de skal ikke mere kalle dig den fine og kjælne. 
23:47:2: Ta fatt på kvernen og mal mel, slå op ditt slør, løft slepet op, gjør benet bart, vad over elver! 
23:47:3: Din blusel skal bli avdekket, og din skam bli sett; hevn vil jeg ta og ikke spare noget menneske. 
23:47:4: Vår gjenløser - hans navn er Herren, hærskarenes Gud, Israels Hellige. 
23:47:5: Sitt taus og gå inn i mørket, du kaldeernes datter! For de skal ikke mere kalle dig rikenes dronning. 
23:47:6: Jeg var vred på mitt folk, vanhelliget min arv og gav dem i din hånd, du viste dem ikke barmhjertighet, endog på oldingen lot du ditt åk tynge hårdt. 
23:47:7: Og du sa: Til evig tid skal jeg være dronning, så du ikke la dig dette* på hjerte og ikke tenkte på hvad enden på det skulde bli. # <* JES 47, 6.> 
23:47:8: Så hør nu dette, du som lever i dine lyster, som sitter så trygg, du som sier i ditt hjerte: Jeg og ingen annen! Jeg skal ikke sitte som enke og ikke vite hvad det er å være barnløs! 
23:47:9: Men begge disse ting skal komme over dig i et øieblikk, på en dag, både barnløshet og enkestand; i fullt mål kommer de over dig tross dine mangfoldige trolldomskunster, tross dine mange besvergelser. 
23:47:10: Du stolte på din ondskap, du sa: Det er ingen som ser mig. Din visdom og din kunnskap har forført dig, så du sa i ditt hjerte: Jeg og ingen annen! 
23:47:11: Så skal det da komme over dig en ulykke som du ikke kan mane bort, og en ødeleggelse skal ramme dig, som du ikke skal makte å avvende ved noget sonoffer, og en undergang som du ikke vet om, skal komme brått over dig. 
23:47:12: Stå frem med dine besvergelser og med dine mangfoldige trolldomskunster, som du har gjort dig møie med fra din ungdom av! Kanskje du kunde hjelpe dig med dem, kanskje du kunde skremme ulykken bort. 
23:47:13: Du har trettet dig ut med dine mange råd; la dem stå frem og frelse dig, de som har inndelt himmelen, stjernekikkerne, de som hver måned kunngjør de ting som skal komme over dig! 
23:47:14: Se, de er som halm, ilden brenner dem op, de kan ikke redde sitt eget liv fra luens makt; det er ingen glør å varme sig ved, ingen ild å sitte omkring. 
23:47:15: Således går det for dig med dem som du har strevet for; de som drev handel med dig fra din ungdom av, de farer hit og dit, hver til sin kant; det er ingen som frelser dig. 
23:48:1: Hør dette, I av Jakobs hus, I som kalles med Israels navn og er runnet av Judas kilde, I som sverger ved Herrens navn og priser Israels Gud, men ikke i sannhet og rettskaffenhet! 
23:48:2: For efter den hellige stad kaller de sig, og på Israels Gud stoler de*, på ham hvis navn er Herren, hærskarenes Gud. # <* MIK 3, 11.> 
23:48:3: De forrige ting* har jeg for lenge siden forkynt, de gikk ut av min munn, og jeg kunngjorde dem; brått satte jeg dem i verk, og de kom. # <* JES 46, 9. 10.> 
23:48:4: Fordi jeg visste at du er hård, og din nakke en jernsene, og din panne av kobber, 
23:48:5: så forkynte jeg dig det for lenge siden; før det kom, kunngjorde jeg det for dig, forat du ikke skulde si: Mitt gudebillede har gjort det, mitt utskårne billede og mitt støpte billede har styrt det så. 
23:48:6: Du har hørt det; nu kan du se det alt sammen! Og I, må I ikke bekjenne det? Fra nu av kunngjør jeg noget nytt for dig, dulgte ting, som du ikke har visst om. 
23:48:7: Nu først er det skapt, og ikke for lenge siden, og før idag hadde du ikke hørt det, forat du ikke skulde si: Det visste jeg! 
23:48:8: Du har ikke hørt det eller fått det å vite, heller ikke er ditt øre for lenge siden blitt oplatt; for jeg visste at du er troløs og er blitt kalt en overtreder fra mors liv av. 
23:48:9: For mitt navns skyld er jeg langmodig, og for min æres skyld legger jeg bånd på mig og skåner dig, så du ikke skal bli utryddet. 
23:48:10: Se, jeg renser dig, men ikke som sølv*; jeg prøver dig i lidelsens ovn**. # <* ikke så strengt som sølv; SLM 12, 7.> # <** d.e. ved hårde tuktelser, uten å utrydde dig; OSP 17, 3. 1PE 1, 7.> 
23:48:11: For min skyld, for min skyld gjør jeg det; for hvorledes skulde jeg kunne la mitt navn bli vanhelliget? Og min ære gir jeg ikke til nogen annen. 
23:48:12: Hør på mig, Jakob, og du Israel, som jeg har kalt! Jeg er Gud, jeg er den første, jeg er også den siste. 
23:48:13: Min hånd har også grunnfestet jorden, og min høire hånd har utspent himmelen; jeg kaller på dem, da står de der begge. 
23:48:14: Samle eder alle sammen og hør: Hvem iblandt dem* har forkynt dette? - Han som Herren elsker**, skal fullbyrde hans vilje mot Babel og vise hans makt mot kaldeerne. # <* avgudene.> # <** Kyros; JES 44, 28.> 
23:48:15: Jeg, jeg har talt; jeg har også kalt ham, jeg har latt ham komme, og han har lykke på sin ferd. 
23:48:16: Kom nær til mig, hør dette! Fra begynnelsen av har jeg ikke talt i lønndom; fra den tid det kom, var jeg der*. Og nu har Herren, Israels Gud, sendt mig** og sin Ånd. # <* fra den tid det forkynte begynte å opfylles, var jeg virksom til stede.> # <** d.e. profeten.> 
23:48:17: Så sier Herren, din gjenløser, Israels Hellige: Jeg er Herren din Gud, som lærer dig å gjøre det som er dig til gagn, som fører dig på den vei du skal gå. 
23:48:18: Gid du vilde akte på mine bud! Da skulde din fred bli som elven, og din rettferdighet som havets bølger; 
23:48:19: da skulde din ætt bli som sanden, og din livsfrukt som sandkornene; dens navn skulde ikke utryddes og ikke utslettes for mitt åsyn. 
23:48:20: Dra ut av Babel, fly fra kaldeerne! Forkynn dette, fortell det med jubelrøst, utbred det like til jordens ende, si: Herren har gjenløst sin tjener Jakob! 
23:48:21: De lider ingen tørst; gjennem ørkenen fører han dem, vann av klippen lar han rinne for dem; han kløver klippen, og det flyter vann. 
23:48:22: Det er ingen fred, sier Herren, for de ugudelige. 
23:49:1: Hør på mig, I øer, og gi akt, I folk fra det fjerne! Herren har kalt mig fra mors liv av, han har nevnt mitt navn fra min mors skjød. 
23:49:2: Han har gjort min munn til et skarpt sverd og skjult mig i sin hånds skygge; han har gjort mig til en blank pil og gjemt mig i sitt kogger. 
23:49:3: Og han sa til mig: Du er min tjener, du er Israel, på hvem jeg vil åpenbare min herlighet. 
23:49:4: Men jeg sa: Forgjeves har jeg arbeidet mig trett, og til ingen nytte har jeg fortært min kraft; men min rett er allikevel hos Herren, og min lønn hos min Gud. 
23:49:5: Og nu sier Herren, som fra mors liv har dannet mig til sin tjener, for å føre Jakob tilbake til ham og samle Israel for ham, og jeg er æret i Herrens øine, og min Gud er blitt min styrke - 
23:49:6: han sier: Det er for lite at du er min tjener til å gjenreise Jakobs stammer og føre den frelste rest av Israel tilbake; så vil jeg da gjøre dig til hedningenes schmelk, forat min frelse må nå til jordens ende. 
23:49:7: Så sier Herren, Israels gjenløser, Israels Hellige, til ham som er foraktet av hver sjel, til ham som vekker folks avsky, til ham som er herskeres tjener: Konger skal se det og reise sig, fyrster skal se det og kaste sig ned, for Herrens skyld, som er trofast, for Israels Helliges skyld, som utvalgte dig. 
23:49:8: Så sier Herren: På den tid som behager mig, bønnhører jeg dig, og på frelsens dag hjelper jeg dig, og jeg vil bevare dig og gjøre dig til en pakt for folket, forat du skal gjenreise landet og utskifte de øde arvelodder 
23:49:9: og si til de fangne: Gå ut! - til dem som er i mørket: Kom frem! På veiene skal de beite, og på alle de bare hauger skal det være beitemark for dem. 
23:49:10: De skal ikke sulte og ikke tørste, og hverken det glødende sandhav eller solen skal skade dem; for han som forbarmer sig over dem, skal føre dem og lede dem til kildevell, 
23:49:11: og jeg vil gjøre alle mine fjell til veier, og mine ryddede veier skal bygges høiere. 
23:49:12: Se, de kommer langt borte fra, nogen fra nord og nogen fra vest og nogen fra Sinims land. 
23:49:13: Juble, I himler, og fryd dig, du jord! I fjell, bryt ut i frydesang! For Herren trøster sitt folk, og over sine elendige forbarmer han sig. 
23:49:14: Sion sa: Herren har forlatt mig, Herren har glemt mig. 
23:49:15: Glemmer vel en kvinne sitt diende barn, så hun ikke forbarmer sig over sitt livs sønn? Om også de glemmer, så glemmer ikke jeg dig. 
23:49:16: Se, I begge mine hender har jeg tegnet dig, dine murer står alltid for mig. 
23:49:17: Dine barn kommer i hast; de som brøt dig ned og ødela dig, de skal dra bort fra dig. 
23:49:18: Løft dine øine og se dig omkring! De samler sig alle sammen, de kommer til dig. Så sant jeg lever, sier Herren, du skal iføre dig dem alle sammen som en prydelse og binde dem om dig lik bruden; 
23:49:19: for dine ruiner og dine øde steder og ditt herjede land - sannelig, nu skal du være for trang for innbyggerne, og dine ødeleggere skal være langt borte. 
23:49:20: Ennu skal du få høre barna fra din barnløshets dager si: Plassen er for trang for mig; flytt dig, så jeg kan få bo her! 
23:49:21: Og du skal si i ditt hjerte: Hvem har født mig disse barn? Jeg var jo barnløs og ufruktbar, landflyktig og jaget bort. Og hvem har fostret disse? Jeg var jo enslig tilbake. Hvor var da disse? 
23:49:22: Så sier Herren, Israels Gud: Se, jeg vil løfte min hånd til hedningene og reise mitt banner for folkene, og de skal komme med dine sønner ved barmen, og dine døtre skal de bære på skulderen. 
23:49:23: Og konger skal være dine fosterfedre, og deres dronninger dine ammer; de skal kaste sig på sitt ansikt til jorden for dig, og dine føtters støv skal de slikke, og du skal kjenne at jeg er Herren, og at de som bier efter mig, ikke skal bli til skamme. 
23:49:24: Kan vel nogen ta hærfanget fra en kjempe, eller kan fangene slippe fra den som de med rette* tilhører? # <* JER 25, 9; 27, 5-8.> 
23:49:25: Ja! For så sier Herren: Både skal fangene tas fra kjempen, og voldsmannens hærfang slippe unda, og med din motpart vil jeg gå i rette, og dine barn vil jeg frelse. 
23:49:26: Og jeg vil la dine undertrykkere ete sitt eget kjøtt, og av sitt eget blod skal de bli drukne som av druesaft; og alt kjød skal kjenne at jeg, Herren, er din frelser, Jakobs Veldige din gjenløser. 
23:50:1: Så sier Herren: Hvor er eders mors skilsmissebrev, som jeg har jaget henne bort med? Eller hvem av dem som har noget å kreve av mig, har jeg solgt eder til? Nei, for eders misgjerningers skyld er I blitt solgt, og for eders overtredelsers skyld er eders mor blitt jaget bort. 
23:50:2: Hvorfor var det ingen til stede da jeg kom? Hvorfor var det ingen som svarte da jeg ropte? Er min hånd for kort til å forløse, eller er det ingen kraft hos mig til å frelse? Se, ved min trusel tørker jeg ut havet, gjør jeg elver til en ørk, så fiskene i dem stinker, fordi der intet vann er, og dør av tørst; 
23:50:3: jeg klær himmelen i sort og innhyller den i sørgedrakt. 
23:50:4: Herren, Israels Gud, har gitt mig en disippeltunge, så jeg skal kunne kvege den trette med mitt ord; han vekker mitt øre hver morgen, han vekker det forat jeg skal høre som disipler hører. 
23:50:5: Herren, Israels Gud, har åpnet mitt øre, og jeg var ikke gjenstridig, jeg vek ikke tilbake. 
23:50:6: Min rygg bød jeg frem til dem som slo, og mine kinner til dem som rykket mig i skjegget; mitt ansikt skjulte jeg ikke for hån og spytt. 
23:50:7: Men Herren, Israels Gud, vil hjelpe mig; derfor blir jeg ikke til skamme, derfor har jeg gjort mitt ansikt hårdt som sten, og jeg vet at jeg ikke skal bli skuffet. 
23:50:8: Han er nær, han som hjelper mig til min rett; hvem vil stride mot mig? La oss stå frem sammen! Hvem er min motpart? La ham komme hit til mig! 
23:50:9: Se, Herren, Israels Gud, vil hjelpe mig; hvem er den som vil domfelle mig? Se, de skal alle sammen eldes som et klædebon; møll skal fortære dem. 
23:50:10: Hvem blandt eder frykter Herren og hører på hans tjeners røst? Når han vandrer i mørke, og intet lys skinner for ham, skal han sette sin lit til Herrens navn og stole på sin Gud! 
23:50:11: Se, alle I som tender ild, som væbner eder med brandpiler, gå selv inn i luen av eders ild og blandt de brandpiler I har tendt! - Fra min hånd skal dette times eder; i pine skal I komme til å ligge. 
23:51:1: Hør på mig, I som jager efter rettferdighet, I som søker Herren! Se på det fjell som I er hugget ut av, og på den brønn som I er gravd ut av! 
23:51:2: Se på Abraham, eders far, og på Sara, som fødte eder! For da han ennu bare var en, kalte jeg ham, og jeg velsignet ham og gjorde hans ætt stor; 
23:51:3: for Herren trøster Sion, trøster alle dets ruiner og gjør dets ørken lik Eden og dets øde mark lik Herrens have; fryd og glede skal finnes der, takksigelse og lovsang. 
23:51:4: Hør på mig, mitt folk! Vend øret til mig, du min menighet! For lov skal gå ut fra mig, og min rett vil jeg sette til et lys for folkene. 
23:51:5: Min rettferdighet er nær, min frelse bryter frem, og mine armer skal hjelpe folkene til rett; på mig skal øene vente, og på min arm skal de bie. 
23:51:6: Løft eders øine til himmelen, og se på jorden her nede! For himmelen skal blåses bort som røk, og jorden skal eldes som et klædebon, og de som bor på den, skal dø som mygg; men min frelse skal vare til evig tid, og min rettferdighet skal ikke brytes. 
23:51:7: Hør på mig, I som kjenner rettferdighet, du folk som har min lov i ditt hjerte! Frykt ikke menneskers hån, og reddes ikke for deres spottende ord. 
23:51:8: For møll skal fortære dem som et klæsplagg, og makk skal fortære dem som ull; men min rettferdighet skal vare til evig tid, og min frelse fra slekt til slekt. 
23:51:9: Våkn op, våkn op, klæ dig i styrke, du Herrens arm! Våkn op som i gamle dager, som i fordums tid! Var det ikke du som felte Rahab*, som gjennemboret havuhyret? # <* Egypten. 2MO 14, 14; 6, 6; 15, 12. SLM 74, 13. 14; 89, 11. JES 27, 1; 30, 7. ESK 29, 3 fg.> 
23:51:10: Var det ikke du som tørket ut havet, vannet i det store dyp, som gjorde havets bunn til en vei, så det frelste folk kunde gå gjennem det? 
23:51:11: Så skal Herrens forløste vende tilbake og komme til Sion med frydesang, og evig glede er det over deres hode; fryd og glede skal de nå, sorg og sukk skal fly. 
23:51:12: Jeg, jeg er den som trøster eder; hvem er du, at du frykter for et menneske, som skal dø, for et menneskebarn, som skal bli lik gress, 
23:51:13: og at du glemmer Herren, din skaper, som utspente himmelen og grunnfestet jorden, og alltid hele dagen engster dig for undertrykkerens vrede, når han legger pilen til rette for å ødelegge? Hvor er da undertrykkerens vrede? 
23:51:14: Snart skal den som ligger i lenker, bli løst, han skal ikke dø og gå i graven, og han skal ikke mangle sitt brød. 
23:51:15: Og jeg er Herren din Gud, som rører op havet så dets bølger bruser - Herren, hærskarenes Gud, er hans navn. 
23:51:16: Og jeg la mine ord i din munn og dekket dig med min hånds skygge for å plante himmelen og grunnfeste jorden og for å si til Sion: Du er mitt folk. 
23:51:17: Våkn op, våkn op, stå op, Jerusalem, du som av Herrens hånd har fått hans vredes beger å drikke! Det store tumlebeger har du drukket ut til siste dråpe. 
23:51:18: Hun har ingen som leder henne, av alle de barn hun har født, og ingen som tar henne ved hånden, av alle de sønner hun har fostret. 
23:51:19: To ting var det som rammet dig - hvem ynkes over dig? - ødeleggelse og undergang, hunger og sverd - hvorledes skal jeg trøste dig? 
23:51:20: Dine barn lå avmektige på alle gatehjørner som en hjort i garnet, de som var fylt av Herrens brennende vrede, av din Guds trusler. 
23:51:21: Derfor, hør dette, du arme, og du som er drukken, men ikke av vin! 
23:51:22: Så sier Herren, din herre, din Gud, som fører sitt folks sak: Se, jeg har tatt tumlebegeret ut av din hånd; mitt store vredesbeger skal du ikke mere drikke av. 
23:51:23: Og jeg gir det i dine undertrykkeres hånd, de som sa til dig: Bøi dig ned, så vi kan gå over dig, og så gjorde du din rygg lik jorden, til en gate for dem som gikk over den. 
23:52:1: Våkn op, våkn op, iklæ dig din styrke, Sion! Klæ dig i ditt høitidsskrud, Jerusalem, du hellige stad! For ingen uomskåren eller uren skal mere komme inn i dig. 
23:52:2: Ryst støvet av dig, stå op, ta sete, Jerusalem! Gjør dig løs fra båndene om din hals, du fangne Sions datter! 
23:52:3: For så sier Herren: For intet blev I solgt, og uten penger skal I bli gjenløst. 
23:52:4: Ja, så sier Herren, Israels Gud: I sin første tid drog mitt folk ned til Egypten for å bo der som fremmed, og siden undertrykte Assur det uten nogen rett. 
23:52:5: Og nu, hvad skal jeg gjøre her? sier Herren; for mitt folk er ført bort for intet, dets herskere brøler, sier Herren, og hele dagen igjennem blir mitt navn spottet. 
23:52:6: Derfor skal mitt folk lære å kjenne mitt navn; derfor skal det på den dag lære å kjenne at jeg er den som sier: Se, her er jeg! 
23:52:7: Hvor fagre er på fjellene dens føtter som kommer med gledesbud, som forkynner fred, som bærer godt budskap, som forkynner frelse, som sier til Sion: Din Gud er blitt konge! 
23:52:8: Hør! Dine vektere opløfter sin røst, de jubler alle sammen; for like for sine øine ser de at Herren vender tilbake til Sion. 
23:52:9: Bryt ut og juble alle sammen, Jerusalems ruiner! For Herren trøster sitt folk, han gjenløser Jerusalem. 
23:52:10: Herren avdekker sin hellige arm for alle folkenes øine, og alle jordens ender får se vår Guds frelse. 
23:52:11: Avsted, avsted! Dra ut derfra*! Rør ikke ved noget urent! Dra ut derfra! Rens eder, I som bærer Herrens kar! # <* fra Babel.> 
23:52:12: For I skal ikke dra ut i hast og ikke fare bort som flyktninger; for Herren går foran eder, og Israels Gud slutter eders tog. 
23:52:13: Se, min tjener skal gå frem med visdom; han skal bli opløftet og ophøiet og være meget høi. 
23:52:14: Likesom mange blev forferdet over ham - så ille tilredt var han at han ikke så ut som et menneske, og hans skikkelse ikke var som andre menneskebarns - 
23:52:15: således skal han få mange folkeferd til å fare op*, for ham skal konger lukke sin munn; for det som ikke var fortalt dem, det har de sett, og det de ikke hadde hørt, det har de fornummet. # <* av forundring. MIK 7, 16. RMR 15, 21.> 
23:53:1: Hvem trodde det budskap vi hørte? Og for hvem blev Herrens arm åpenbaret? 
23:53:2: Han* skjøt op som en kvist for hans åsyn og som et rotskudd av tørr jord; han hadde ingen skikkelse og ingen herlighet, og vi så ham, men han hadde ikke et utseende så vi kunde ha vår lyst i ham. # <* JES 52, 13. 11, 1. 10; 52, 14.> 
23:53:3: Foraktet var han og forlatt av mennesker, en mann full av piner og vel kjent med sykdom*; han var som en som folk skjuler sitt åsyn for, foraktet, og vi aktet ham for intet. # <* d.e. lidelse; JES 49, 7; 50, 6. SLM 22, 7. MRK 9, 12. JOH 7, 47. 48.> 
23:53:4: Sannelig, våre sykdommer har han tatt på sig, og våre piner har han båret; men vi aktet ham for plaget, slått av Gud og gjort elendig. 
23:53:5: Men han er såret for våre overtredelser, knust for våre misgjerninger; straffen lå på ham, forat vi skulde ha fred, og ved hans sår har vi fått lægedom. 
23:53:6: Vi fór alle vill som får, vi vendte oss hver til sin vei; men Herren lot våres alles misgjerninger ramme ham. 
23:53:7: Han blev mishandlet, enda han var elendig, og han oplot ikke sin munn, lik et lam som føres bort for å slaktes, og lik et får som tier når de klipper det; han oplot ikke sin munn. 
23:53:8: Ved trengsel og ved dom blev han rykket bort; men hvem tenkte i hans tid at når han blev utryddet av de levendes land, så var det for mitt folks misgjernings skyld plagen traff ham? 
23:53:9: De gav ham hans grav blandt ugudelige, men hos en rik var han i sin død, fordi han ingen urett hadde gjort, og det ikke var svik i hans munn. 
23:53:10: Men det behaget Herren å knuse ham, han slo ham med sykdom; når hans sjel bar frem skyldofferet, skulde han se avkom og leve lenge, og Herrens vilje skulde ha fremgang ved hans hånd. 
23:53:11: Fordi hans sjel har hatt møie, skal han se det* og mettes; derved at de kjenner ham, skal den rettferdige, min tjener, rettferdiggjøre de mange, og deres misgjerninger skal han bære. # <* at Herrens vilje har fremgang ved ham. JES 53, 4. 5. RMR 4, 25; 5, 19.> 
23:53:12: Derfor vil jeg gi ham de mange til del, og sterke skal han få til bytte, fordi han uttømte sin sjel* til døden og blev regnet blandt overtredere, han som dog bar manges synd, og han bad for overtrederne. # <* i og med sitt blod; 3MO 17, 11. JOH 17, 2. 6. SLM 68, 19. MRK 15, 28. LUK 22, 37; 23, 34.> 
23:54:1: Rop med fryd, du ufruktbare, som ikke fødte! Bryt ut i fryderop og juble, du som ikke var i barnsnød! For den enslige kvinnes barn er flere enn hennes som har mann, sier Herren. 
23:54:2: Utvid plassen for ditt telt, og la dem spenne ut teppene til din bolig, hindre det ikke! Strekk dine snorer langt ut, og gjør dine teltplugger faste! 
23:54:3: For du skal brede dig ut både til høire og til venstre, og din ætt skal ta hedningefolk i eie og bygge op igjen ødelagte byer. 
23:54:4: Frykt ikke, du skal ikke bli skuffet, og vær ikke skamfull, du skal ikke bli til skamme! Din ungdoms skam skal du glemme, og din enkestands vanære skal du ikke mere komme i hu. 
23:54:5: For din skaper er din ektemann, Herren, hærskarenes Gud, er hans navn, og Israels Hellige er din gjenløser, all jordens Gud kalles han. 
23:54:6: For som en forlatt kvinne med sorg i hjertet kaller Herren dig, og en ungdomshustru - skulde hun forskytes? sier din Gud. 
23:54:7: Et lite øieblikk forlot jeg dig, men med stor barmhjertighet vil jeg samle dig. 
23:54:8: Da min vrede løp over, skjulte jeg et øieblikk mitt åsyn for dig, men med evig miskunnhet forbarmer jeg mig over dig, sier Herren, din gjenløser. 
23:54:9: For som Noahs flom er dette for mig; likesom jeg svor at Noahs flom ikke mere skal gå over jorden, således har jeg nu svoret at jeg ikke vil vredes på dig eller skjenne på dig. 
23:54:10: For fjellene kan vei vike, og haugene rokkes, men min miskunnhet skal ikke vike fra dig, og min fredspakt skal ikke rokkes, sier Herren, han som forbarmer sig over dig. 
23:54:11: Du arme som er bortrevet av storm, som ingen trøst har funnet! Se, jeg legger dine stener i blyglans og bygger din grunnmur med safirer; 
23:54:12: jeg gjør dine murtinder av rubiner og dine porter av karfunkelstener og hele din ringmur av dyre stener. 
23:54:13: Og alle dine barn skal være lært av Herren, og dine barns fred skal være stor. 
23:54:14: Ved rettferdighet skal du bli grunnfestet; vær langt fra angst, for du skal intet ha å frykte, og fra redsel, for den skal ikke komme nær til dig! 
23:54:15: Se, slår de sig sammen mot dig, så er det ikke fra mig; de som slår sig sammen mot dig, skal falle i striden mot dig. 
23:54:16: Se, jeg skaper smeden, som puster i kullilden og lager et redskap til sin gjerning, og jeg skaper en ødelegger til å skade. 
23:54:17: Intet våben som blir smidd mot dig, skal ha fremgang, og hver tunge som går i rette med dig, skal du få domfelt; dette er Herrens tjeneres arv og den rett de får av mig, sier Herren. 
23:55:1: Nu vel, alle I som tørster, kom til vannene, og I som ingen penger har! Kom, kjøp og et, ja kom, kjøp uten penger og uten betaling vin og melk! 
23:55:2: Hvorfor veier I ut penger for det som ikke er brød, og eders fortjeneste for det som ikke kan mette? Hør på mig! Så skal I ete det gode, og eders sjel glede sig ved de fete retter. 
23:55:3: Bøi eders øre hit og kom til mig! Hør! Så skal eders sjel leve; og jeg vil oprette en evig pakt med eder, gi eder Davids* rike nåde, den visse. # <* d.e. den som blev lovt David; 2SA 7, 12 fg. 1KG 8, 25. SLM 89, 4 fg. 50. APO 2, 30; 13, 34.> 
23:55:4: Se, til et vidne for folkeslag har jeg satt ham*, til en fyrste og en hersker over folkeslag. # <* i Messias. APE 1, 5; 3, 14. SLM 2, 8; 72, 8 fg.> 
23:55:5: Se, folk du ikke kjenner, skal du kalle, og hedningefolk som ikke kjenner dig, skal løpe til dig, for Herrens, din Guds skyld og for Israels Helliges skyld; for han herliggjør dig. 
23:55:6: Søk Herren mens han finnes, kall på ham den stund han er nær! 
23:55:7: Den ugudelige forlate sin vei og den urettferdige sine tanker og omvende sig til Herren, så skal han forbarme sig over ham, og til vår Gud, for han skal mangfoldig forlate. 
23:55:8: For mine tanker er ikke eders tanker, og eders veier er ikke mine veier, sier Herren; 
23:55:9: som himmelen er høiere enn jorden, således er mine veier høiere enn eders veier, og mine tanker høiere enn eders tanker. 
23:55:10: For likesom regnet og sneen faller ned fra himmelen og ikke vender tilbake dit, men vanner jorden og får den til å bære og gro og gir såmannen sæd og den etende brød, 
23:55:11: således skal mitt ord være, som går ut av min munn; det skal ikke vende tomt tilbake til mig, men det skal gjøre det jeg vil, og lykkelig utføre det som jeg sender det til. 
23:55:12: For med glede skal I dra ut, og i fred skal I føres frem; fjellene og haugene skal bryte ut i fryderop for eders åsyn, og alle markens trær skal klappe i hendene. 
23:55:13: I stedet for tornebusker skal det vokse op cypresser, i stedet for tistler skal det vokse op myrter, og det skal være til et navn for Herren, til et evig tegn, som ikke skal bli utslettet. 
23:56:1: Så sier Herren: Akt på det som er rett, og gjør rettferdighet! For min frelse kommer snart, og snart skal min rettferdighet åpenbares. 
23:56:2: Salig er det menneske som gjør dette, det menneskebarn som holder fast ved det, som holder sabbaten, så han ikke vanhelliger den, som varer sin hånd, så den ikke gjør noget ondt. 
23:56:3: Den fremmede som holder sig til Herren, må ikke si: Herren vil visst skille mig fra sitt folk! Og gildingen må ikke si: Se, jeg er et tørt tre! 
23:56:4: For så sier Herren: De gildinger som vil holde mine sabbater og velge det som jeg har behag i, og holde fast ved min pakt, 
23:56:5: dem vil jeg i mitt hus og innenfor mine murer gi et minne og et navn bedre enn sønner og døtre; et evig navn vil jeg gi dem, som ikke skal utryddes. 
23:56:6: Og de fremmede som holder sig til Herren for å tjene ham og for å elske Herrens navn, for å være hans tjenere, alle de som holder sabbaten, så de ikke vanhelliger den, og som holder fast ved min pakt, 
23:56:7: dem vil jeg føre til mitt hellige berg og glede dem i mitt bedehus, deres brennoffer og slaktoffer skal være til velbehag på mitt alter; for mitt hus skal kalles et bedehus for alle folk. 
23:56:8: Så sier Herren, Israels Gud, han som samler de fordrevne av Israel: Ennu flere vil jeg samle til ham, foruten dem som allerede er samlet til ham. 
23:56:9: Alle I markens dyr, kom og et, alle I dyr i skogen! 
23:56:10: Israels vektere er blinde alle sammen, de vet intet. Alle sammen er de stumme hunder, som ikke kan gjø; de ligger og drømmer og holder av å sove; 
23:56:11: de er grådige hunder, de blir aldri mette. De er hyrder som ikke forstår å gi akt; alle sammen har de vendt sig til sin egen vei, hver til sin egen vinning, fra den første til den siste. 
23:56:12: Kom*, jeg vil hente vin, la oss drikke sterk drikk, og som denne dag skal den dag imorgen være, herlig over all måte! # <* så sier de. 22, 13 fg.> 
23:57:1: Den rettferdige omkommer, og det er ikke nogen som legger sig det på hjerte, og fromme menn rykkes bort, uten at nogen akter på at den rettferdige rykkes bort før ulykken kommer. 
23:57:2: Han går inn til fred; de hviler på sine leier alle de som går sin vei rett frem. 
23:57:3: Men kom hit, I trollkvinnens barn, I horkarls og horkvinnes yngel! 
23:57:4: Hvem gjør I eder lystige over? Mot hvem lukker I munnen op og rekker tungen ut? Er I ikke selv overtredelsens yngel, løgnens avkom, 
23:57:5: I som er optendt av brynde ved terebintene, under hvert grønt tre, som slakter barn i dalene, under bergkløfters tak? 
23:57:6: Bekkens glatte stener* er din del; de, ja de er din lodd; også for dem utøste du drikkoffer og bar frem matoffer - skulde jeg gi mig tilfreds med dette? # <* som de drev avgudsdyrkelse med.> 
23:57:7: På høie, mektige fjell redet du ditt leie; også der steg du op for å ofre slaktoffer. 
23:57:8: Og bak døren og dørstolpen satte du ditt minnetegn*; du vendte dig bort fra mig, klædde dig naken og steg op, gjorde ditt leie bredt og tinget dig lønn av dem**; du elsket samleiet med dem, du så deres blusel. # <* 5MO 6, 9; 11, 20.> # <** avgudene.> 
23:57:9: Og du drog til kongen med olje, og du kom med mange velluktende salver, og du sendte dine bud langt bort, og du steg dypt ned, like til dødsriket. 
23:57:10: På din lange ferd blev du trett; allikevel sa du ikke: Jeg gir tapt! Du fant ny kraft i din hånd; derfor blev du ikke svak. 
23:57:11: Hvem var du redd for og fryktet, siden du gav dig over til løgnen og ikke kom mig i hu og ikke la dig det på hjerte? Er det ikke så: Jeg har tidd helt fra fordums tid, og mig fryktet du derfor ikke? 
23:57:12: Jeg vil kunngjøre din rettferdighet og dine gjerninger, og de skal ikke gagne dig. 
23:57:13: Når du skriker, så la din flokk [av avguder] redde dig! Nei, en vind skal løfte dem op alle sammen, et vindpust skal feie dem bort; men den som tar sin tilflukt til mig, han skal arve landet og ta mitt hellige berg i eie. 
23:57:14: Og det skal bli sagt: Bygg, bygg, rydd vei! Ta hvert støt bort fra mitt folks vei! 
23:57:15: For så sier den Høie, den Ophøiede, han som troner evindelig, og hvis navn er hellig: I det høie og hellige bor jeg, og hos den som er sønderknust og nedbøiet i ånden, for å gjenoplive de nedbøiedes ånd og gjøre de sønderknustes hjerte levende. 
23:57:16: For ikke til evig tid går jeg i rette, og ikke for alle tider er jeg vred; for da måtte ånden vansmekte for mitt åsyn, de sjeler som jeg har skapt. 
23:57:17: For Israels syndige begjærlighets skyld blev jeg vred og slo ham, jeg skjulte mitt åsyn og var vred, og han gikk bortvendt på sitt hjertes vei. 
23:57:18: Hans veier har jeg sett, og jeg vil læge ham, og jeg vil lede ham og gi ham og hans sørgende trøst. 
23:57:19: Herren skaper lebers grøde*; fred, fred for fjern og nær, sier han, og jeg læger ham. # <* lov og pris; HEB 13, 15. JES 52, 7. EFE 2, 17.> 
23:57:20: Men de ugudelige er som det oprørte hav; det kan ikke være stille, og dets bølger skyller op skarn og dynd. 
23:57:21: Det er ingen fred for de ugudelige, sier min Gud. 
23:58:1: Rop av strupen, spar ikke! Opløft din røst som en basun og forkynn mitt folk dets overtredelse og Jakobs hus dets synder! 
23:58:2: Mig spør de dag for dag, og å få vite mine veier krever de; likesom de var et folk som har gjort rettferdighet og ikke forlatt sin Guds lov, krever de av mig rettferdige dommer; de vil at Gud skal komme nær til dem. 
23:58:3: Hvorfor* faster vi, og du ser det ikke? Hvorfor plager vi vår sjel, og du akter ikke på det? - Se, på den dag I faster, gjør I eders gjerning, og alle eders arbeidere plager I. # <* så sier de. JER 14, 12.> 
23:58:4: Se, for å kives og strides faster I, og for å slå med ugudelighets neve; I faster ikke nu således at eders røst kan høres i det høie. 
23:58:5: Er dette den faste jeg finner behag i, en dag da et menneske plager sin sjel? At en bøier sitt hode som et siv og reder sin seng i sekk og aske - kaller du det en faste og en dag som behager Herren? 
23:58:6: Er ikke dette den faste jeg finner behag i, at I løser ugudelighets lenker, sprenger åkets bånd, slipper undertrykte fri og bryter hvert et åk? 
23:58:7: Mon ikke dette* at du bryter ditt brød til den som sulter, og lar hjemløse stakkarer komme i hus - når du ser en naken, at du da klær ham og ikke drar dig bort fra den som er ditt eget kjød? # <* nemlig er den faste jeg finner behag i; JES 58, 6. JBS 31, 19. ESK 18, 7. RMR 12, 13. MTT 25, 35 fg.> 
23:58:8: Da skal ditt lys bryte frem som morgenrøden, og din lægedom snart spire frem; din rettferdighet skal gå frem for ditt åsyn, og Herrens herlighet slutte ditt tog. 
23:58:9: Da skal du påkalle Herren, og han skal svare; da skal du rope, og han skal si: Se, her er jeg! Når du har hvert åk bort fra din midte, lar være å peke fingrer* og tale ondt # <* d.e. å spotte; JES 57, 4.> 
23:58:10: og tar frem til den sultne det som du selv har lyst til, og metter en lidende sjel, da skal ditt lys oprinne i mørket, og din natt bli som middagen. 
23:58:11: Og Herren skal lede dig all tid og mette dig midt i ødemarken, og dine ben skal han styrke, og du skal bli som en vannrik have, som et kildevell der vannet aldri slipper op. 
23:58:12: Og sønner av dig skal bygge op igjen ruinene fra gammel tid; grunnvoller som har ligget der fra slekt til slekt, skal du gjenreise, og de skal kalle dig murbrudds tilmurer, den som setter veier i stand, så folk kan bo i landet. 
23:58:13: Når du holder din fot tilbake fra sabbaten, så du ikke driver ditt yrke på min hellige dag, og du kaller sabbaten en lyst, kaller Herrens hellige dag ærverdig, og du ærer den, så du ikke går dine egne veier, ikke gjør din gjerning eller fører tomt snakk - 
23:58:14: da skal du glede dig i Herren, og jeg vil la dig fare frem over landets høider og la dig nyte Jakobs, din fars arv; for Herrens munn har talt. 
23:59:1: Se, Herrens hånd er ikke for kort til å frelse, og hans øre er ikke for tunghørt til å høre. 
23:59:2: Men eders misgjerninger har gjort skilsmisse mellem eder og eders Gud, og eders synder har skjult hans åsyn for eder, så han ikke hører. 
23:59:3: For eders hender er flekket av blod, og eders fingrer av misgjerning; eders leber taler løgn, eders tunge taler urett. 
23:59:4: Det er ingen som fremfører tale med rettferdighet, og ingen som fører rettssak på ærlig vis; de setter sin lit til usannhet og taler løgn, de har undfanget ulykke og føder elendighet. 
23:59:5: Basilisk-egg klekker de ut, og spindelvev vever de; den som eter av deres egg, må dø, og trykkes et i stykker, bryter det frem en huggorm. 
23:59:6: Deres vev blir ikke til klær, og en kan ikke dekke sig med deres gjerninger; deres gjerninger er ondskaps gjerninger, og det er voldsverk i deres hender. 
23:59:7: Deres føtter haster til det onde og er snare til å utøse uskyldig blod; deres tanker er ondskaps tanker; det er ødeleggelse og undergang på deres veier. 
23:59:8: Fredens vei kjenner de ikke, og det er ingen rett i deres spor; sine stier gjør de krokete; hver den som treder på dem, vet ikke av fred. 
23:59:9: Derfor er retten* langt borte fra oss, og rettferdigheten når oss ikke; vi venter på schmelk, og se, det er mørke, vi venter på bare solskinn, og i dypeste natt må vi ferdes. # <* JES 59, 11.> 
23:59:10: Vi famler som de blinde efter en vegg, vi famler lik folk som ingen øine har; vi snubler om middagen som i tusmørket, midt iblandt friske er vi som døde. 
23:59:11: Vi brummer som bjørner alle sammen, og som duer kurrer vi; vi venter på retten, og den kommer ikke, på frelsen, og den er langt borte fra oss. 
23:59:12: For våre overtredelser er mange for dig, og våre synder vidner mot oss; våre overtredelser har vi for våre øine, og våre misgjerninger kjenner vi: 
23:59:13: Vi er falt fra Herren og har fornektet ham, vi har gått bort fra vår Gud, vi har talt om undertrykkelse og frafall, vi har undfanget løgnens ord i vårt hjerte og sagt dem ut. 
23:59:14: Derfor er retten* blitt holdt tilbake, og rettferdigheten står langt borte; for sannheten har snublet på tingstedet, og det rette kan ikke finne inngang, # <* JES 59, 9. 11.> 
23:59:15: og sannheten blev borte, og den som holdt sig fra det onde, blev plyndret. Og Herren så det, og det var ondt i hans øine at der ingen rett var, 
23:59:16: og han så at ingen trådte frem, og han undret sig over at det ingen var som førte hans sak. Da hjalp hans arm ham, og hans rettferdighet støttet ham, 
23:59:17: og han iklædde sig rettferdighet som en brynje, og frelsens hjelm satte han på sitt hode, og han klædde sig i hevnens klær og svøpte sig i nidkjærhet som i en kappe. 
23:59:18: Efter deres gjerninger vil han gjengjelde dem, med vrede over sine motstandere, med hevn over sine fiender; øene vil han gjengjelde det de har gjort. 
23:59:19: Og i Vesterland skal de frykte Herrens navn, og i Østerland hans herlighet; for den skal komme lik en voldsom strøm som Herrens vær driver avsted. 
23:59:20: Og det skal komme en gjenløser for Sion og for dem som omvender sig fra overtredelse i Jakob, sier Herren. 
23:59:21: Og dette er den pakt som jeg gjør med dem, sier Herren: Min Ånd, som er over dig, og mine ord, som jeg har lagt i din munn, de skal ikke vike fra din munn eller fra dine barns munn eller fra dine barnebarns munn, sier Herren, fra nu av og til evig tid. 
23:60:1: Stå op, bli schmelk! For ditt lys kommer, og Herrens herlighet går op over dig. 
23:60:2: Se, mørke dekker jorden, og mulm folkene, men over dig skal Herren opgå, og over dig skal hans herlighet åpenbare sig, 
23:60:3: og folkeslag skal søke til ditt lys, og konger til den glans som er gått op over dig. 
23:60:4: Løft dine øine og se dig omkring! De samler sig alle sammen, de kommer til dig; dine sønner skal komme fra det fjerne, og dine døtre skal bæres på armen. 
23:60:5: Da skal du se det og stråle av glede, og ditt hjerte skal banke og utvide sig; for havets rikdom skal vende sig til dig, folkenes gods skal komme til dig. 
23:60:6: Et mylder av kameler skal dekke dig, Midians og Efas kamelføll; alle sammen skal de komme fra Sjeba; gull og virak skal de føre til dig, og Herrens pris skal de forkynne. 
23:60:7: Alle Kedars* får skal samles til dig, Nebajots værer skal tjene dig; til mitt velbehag skal de ofres på mitt alter, og min herlighets hus vil jeg herliggjøre. # <* SLM 120, 5.> 
23:60:8: Hvem er disse som kommer flyvende som skyer, som duer til sine dueslag? 
23:60:9: For på mig skal øene vente, og fremst seiler Tarsis-skibene og fører dine barn hit fra det fjerne, deres sølv og gull kommer med dem - for Herrens, din Guds navns skyld, for Israels Helliges skyld, for han herliggjør dig. 
23:60:10: Og fremmede skal bygge dine murer, og deres konger skal tjene dig; i min harme har jeg slått dig, men i min nåde har jeg forbarmet mig over dig. 
23:60:11: Og dine porter skal holdes åpne all tid, hverken dag eller natt skal de lukkes, så folkenes gods kan føres inn til dig, og deres konger føres med som fanger. 
23:60:12: For det folk og det rike som ikke vil tjene dig, skal gå til grunne, og hedningene skal bli aldeles ødelagt. 
23:60:13: Libanons herlighet skal komme til dig, både cypress og lønn og buksbom, for å pryde det sted der min helligdom er, og det sted hvor mine føtter står, vil jeg herliggjøre. 
23:60:14: Og barna til dem som har plaget dig, skal komme bøiede til dig, og alle de som har foraktet dig, skal kaste sig ned for dine føtter, og de skal kalle dig Herrens stad, Israels Helliges Sion. 
23:60:15: I stedet for at du var forlatt og hatet, så det ikke var nogen som drog igjennem dig, vil jeg gjøre dig til en evig herlighet, en fryd for slekt efter slekt. 
23:60:16: Og du skal die hedningefolks melk, ja, kongers bryst skal du die, og du skal kjenne at jeg, Herren, er din frelser, og Jakobs Veldige din gjenløser. 
23:60:17: I stedet for kobber vil jeg gi gull, i stedet for jern vil jeg gi sølv, i stedet for tre kobber og i stedet for sten jern, og jeg vil gjøre fred til din øvrighet og rettferdighet til din styrer. 
23:60:18: Det skal ikke mere høres om vold i ditt land, ikke om hærfang og ødeleggelse innen dine landemerker, og du skal kalle frelsen dine murer og lovsangen dine porter. 
23:60:19: Solen skal ikke mere være ditt lys om dagen, og månen skal ikke skinne og lyse for dig; men Herren skal være et evig schmelk for dig, og din Gud skal være din herlighet. 
23:60:20: Din sol skal ikke mere gå ned, og din måne ikke miste sitt skinn; for Herren skal være et evig schmelk for dig, og din sorgs dager skal være til ende. 
23:60:21: Og ditt folk - de er alle sammen rettferdige; til evig tid skal de eie landet; de er jo en kvist som jeg har plantet, et verk av mine hender til min ære. 
23:60:22: Den minste skal bli til tusen, og den ringeste til et veldig folk; jeg, Herren, jeg vil la det skje hastig, i sin tid. 
23:61:1: Herrens, Israels Guds Ånd er over mig, fordi Herren har salvet mig til å forkynne et godt budskap for de saktmodige; han har sendt mig til å forbinde dem som har et sønderbrutt hjerte, til å utrope frihet for de fangne og løslatelse for de bundne, 
23:61:2: til å utrope et nådens år fra Herren og en hevnens dag fra vår Gud, til å trøste alle sørgende, 
23:61:3: til å gi de sørgende i Sion hodepryd i stedet for aske, gledes olje i stedet for sorg, lovprisnings klædebon i stedet for en vansmektet ånd, og de skal kalles rettferdighetens terebinter, Herrens plantning til hans ære. 
23:61:4: Og de skal bygge op igjen det som blev ødelagt i eldgammel tid, gjenreise det som har ligget øde fra de første slekter; de skal fornye ødelagte byer, det som har ligget øde fra slekt til slekt. 
23:61:5: Og fremmede skal stå og vokte eders hjorder, og utlendinger skal være eders akerdyrkere og vingårdsmenn. 
23:61:6: Men I, I skal kalles Herrens prester, vår Guds tjenere skal de kalle eder; folkenes gods skal I ete, og deres herlighet skal gå over til eder. 
23:61:7: For eders skam skal I få dobbelt gjengjeld, og de som led vanære, skal nu juble over sin lodd; derfor skal de få dobbelt lodd i sitt land, evig glede skal bli dem til del; 
23:61:8: for jeg, Herren, elsker rett og hater urettferdig rov, og trofast vil jeg gi dem deres lønn, og en evig pakt vil jeg gjøre med dem. 
23:61:9: Og deres ætt skal bli kjent blandt folkene, og deres avkom blandt folkeslagene; alle som ser dem, skal kjenne at de er en ætt Herren har velsignet. 
23:61:10: Jeg vil glede mig i Herren, min sjel skal fryde sig i min Gud; for han har klædd mig i frelsens klædebon, i rettferdighetens kappe har han svøpt mig, likesom brudgommen, som setter på sig en hue prektig som prestens, og likesom bruden, som pryder sig med sine smykker. 
23:61:11: For som jorden lar sine spirer skyte frem, og som en have lar sine vekster spire, således skal Herren, Israels Gud, la rettferdighet og lovsang fremspire for alle folkenes åsyn. 
23:62:1: For Sions skyld vil jeg ikke tie, og for Jerusalems skyld vil jeg ikke være stille, før dets rettferdighet går frem som en stråleglans, og dets frelse som et brennende bluss. 
23:62:2: Og folkene skal se din rettferdighet, og alle konger din herlighet, og du skal kalles med et nytt navn, som Herrens munn skal nevne. 
23:62:3: Og du skal være en prektig krone i Herrens hånd og et kongelig hodesmykke i din Guds hånd. 
23:62:4: Du skal ikke mere kalles den forlatte, og ditt land ikke mere kalles en ødemark, men du skal kalles min lyst, og ditt land skal kalles ektehustru; for Herren har sin lyst i dig, og ditt land skal tas til ekte. 
23:62:5: For som en ung mann tar en jomfru til ekte, således skal dine barn ta dig til ekte, og som en brudgom gleder sig over sin brud, skal din Gud glede sig over dig. 
23:62:6: På dine murer, Jerusalem, setter jeg vektere*; aldri skal de tie, ikke den hele dag og ikke den hele natt; I som minner Herren**, unn eder ingen ro! # <* JES 52, 8.> # <** om hans løfter.> 
23:62:7: Og gi ikke ham ro for han bygger Jerusalem op igjen, og før han gjør det til en lovsang på jorden! 
23:62:8: Herren har svoret ved sin høire hånd og ved sin veldige arm: Jeg vil ikke mere gi dine fiender ditt korn å ete, heller ikke skal fremmede drikke din most, som du har hatt møie med; 
23:62:9: men de som høster kornet, skal ete det og prise Herren, og de som innsamler mosten, skal drikke den i min helligdoms forgårder. 
23:62:10: Dra inn, dra inn gjennem portene, rydd veien for folket, bygg, bygg veien, rens den for sten, løft et banner for folkene! 
23:62:11: Se, Herren lar det lyde til jordens ende: Si til Sions datter: Se, din frelse kommer; se, hans lønn er med ham, og hans gjengjeldelse går foran ham. 
23:62:12: Og de skal kalles det hellige folk, Herrers gjenløste; og du selv skal kalles den søkte, den stad som ikke er forlatt. 
23:63:1: Hvem er han som kommer fra Edom, i røde klær fra Bosra, så prektig i sin klædning, kneisende i sin store kraft? - Det er jeg, jeg som taler rettferdighet, som er mektig til å frelse. 
23:63:2: Hvorfor er din klædning så rød, og dine klær lik hans som treder vinpersen? 
23:63:3: Persekaret har jeg trådt, jeg alene, og av folkene var ingen med mig: så trådte jeg på dem i min vrede og trampet dem sønder i min harme; da sprøitet deres blod på mine klær, og hele min klædning fikk jeg tilsølt. 
23:63:4: For hevnens dag var i mitt hjerte, og mitt gjenløsnings-år var kommet; 
23:63:5: og jeg så mig om, men det var ingen som hjalp; jeg undret mig, men det var ingen som støttet mig; da hjalp min arm mig, og min harme støttet mig, 
23:63:6: og jeg trådte ned folkeslag i min vrede og gjorde dem drukne* i min harme, og jeg lot deres blod rinne ned på jorden. # <* JES 19, 14; 24, 20. JER 48, 26.> 
23:63:7: Herrens miskunnhet vil jeg forkynne, Herrens pris efter alt det Herren har gjort mot oss, og hans store godhet mot Israels hus, fordi han gjorde imot dem efter sin barmhjertighet og efter sin store miskunnhet. 
23:63:8: Han sa: Ja, de er mitt folk, de er barn som ikke vil svike. Og han blev en frelser for dem. 
23:63:9: I all deres trengsel var det ingen trengsel, og hans åsyns engel frelste dem; i sin kjærlighet og sin store mildhet gjenløste han dem, og han tok dem op og bar dem alle den gamle tids dager. 
23:63:10: Men de, de var gjenstridige og gjorde hans Hellige Ånd sorg; da skiftet han hu og blev deres fiende, han selv stred imot dem. 
23:63:11: Da tenkte hans folk på de gamle dager, på Moses: Hvor er han som førte dem op av havet med sin hjords hyrde? Hvor er han som gav sin Hellige Ånd midt iblandt dem, 
23:63:12: han som lot sin herlige arm gå frem ved Moses' høire side, som kløvde vannene for deres åsyn for å gjøre sig et evig navn, 
23:63:13: han som førte dem gjennem dypene som en hest gjennem ørkenen, så de ikke snublet? 
23:63:14: Likesom feet som går ned i dalen, førte Herrens Ånd dem til hvile; således ledet du ditt folk for å gjøre dig et herlig navn. 
23:63:15: Sku ned fra himmelen og se, fra din hellige og herlige bolig! Hvor er din nidkjærhet og ditt velde? Ditt hjertes medynk og din miskunnhet holder sig tilbake fra mig. 
23:63:16: For du er vår far; Abraham vet jo ikke om oss, og Israel kjenner oss ikke; du Herre er vår far, vår gjenløser fra eldgammel tid er ditt navn. 
23:63:17: Hvorfor lar du oss fare vill fra dine veier, Herre? Hvorfor forherder du vårt hjerte, så det ikke frykter dig? Vend om for dine tjeneres skyld, for de stammers skyld som er din arv! 
23:63:18: En liten stund har ditt hellige folk hatt landet i eie; våre motstandere har trådt ned din helligdom. 
23:63:19: Vi er blitt som de du aldri har hersket over, som de ditt navn ikke har vært nevnt over. 
23:64:1: Gid du vilde sønderrive himmelen og fare ned, så fjellene skalv for ditt åsyn, likesom ilden setter kvister i brand og får vannet til å koke - for å kunngjøre ditt navn for dine motstandere, så folkene måtte skjelve for ditt åsyn, 
23:64:2: når du gjorde forferdelige ting som vi ikke ventet, når du for ned, og fjellene skalv for ditt åsyn! 
23:64:3: Fra gammel tid har jo ingen spurt eller hørt, og intet øie sett nogen annen gud enn du gjøre slikt for dem som bier efter ham. 
23:64:4: Du kommer dem i møte som gjør rettferdighet med glede, dem som kommer dig i hu på dine veier. Se, du vrededes, og vi syndet; således var det med oss fra gammel tid, og kunde vi da bli frelst? 
23:64:5: Vi blev som den urene alle sammen, og all vår rettferdighet som et urent klæsplagg, og som løvet visnet vi alle sammen, og som vinden førte våre misgjerninger oss bort. 
23:64:6: Og det er ingen som påkaller ditt navn, som manner sig op til å holde fast ved dig; for du har skjult ditt åsyn for oss og latt oss tæres bort i våre misgjerningers vold. 
23:64:7: Men nu, Herre! Du er vår far; vi er leret, og du den som former oss, og et verk av din hånd er vi alle sammen. 
23:64:8: Herre, vær ikke så over all måte vred og kom ikke evindelig misgjerning i hu! Men tenk på at vi alle sammen er ditt folk! 
23:64:9: Dine hellige stæder er blitt en ørken; Sion er blitt en ørken, Jerusalem en ødemark. 
23:64:10: Vårt hellige og herlige hus, hvor våre fedre lovet dig, er blitt opbrent av ild, og alt som var vår lyst, er blitt til ruiner. 
23:64:11: Vil du tross dette holde dig tilbake, Herre? Vil du tie og trykke oss så tungt? 
23:65:1: Jeg bød mig frem for dem som ikke spurte; jeg var å finne for dem som ikke søkte mig; jeg sa til et hedningefolk som ikke var kalt med mitt navn: Se. her er jeg, her er jeg! 
23:65:2: Jeg bredte ut mine hender hele dagen til et gjenstridig folk, som går på den vei som ikke er god, efter sine egne tanker, 
23:65:3: til et folk som alltid krenker mig like i mitt åsyn, som ofrer i havene og brenner røkelse på teglstenene, 
23:65:4: som sitter i gravene og overnatter i avkrokene, som eter svinekjøtt, og hvis kar er fulle av vederstyggelig suppe, 
23:65:5: som sier: Hold dig unda, kom mig ikke nær, for jeg er hellig for dig! De folk er en røk i min nese, en ild som brenner hele dagen. 
23:65:6: Se, det står skrevet for mitt åsyn: Jeg vil ikke tie før jeg får betalt det, ja betalt det i deres fang, 
23:65:7: både eders misgjerninger og eders fedres misgjerninger, sier Herren, at de brente røkelse på fjellene og hånte mig på haugene; deres lønn vil jeg fremfor alt tilmåle dem i deres fang. 
23:65:8: Så sier Herren: Likesom folk sier når de finner saft i druen: Ødelegg den ikke, for det er en velsignelse i den, således vil jeg gjøre for mine tjeneres skyld og ikke ødelegge hele folket. 
23:65:9: Men jeg vil la en ætt komme av Jakob og av Juda en arving til mine fjell, og mine utvalgte skal arve landet, og mine tjenere skal bo der. 
23:65:10: Og Saron skal bli til en beitemark for småfe, og Akors dal til et hvilested for storfe, til gagn for mitt folk, som søker mig. 
23:65:11: Men I som forlater Herren, som glemmer mitt hellige berg, som dekker bord for Gad*, og som fyller begeret med krydret vin for Meni, # <* navn på avguder.> 
23:65:12: eder vil jeg overgi til sverdet, og I skal alle sammen måtte bøie eder ned for å slaktes, fordi jeg kalte, og I ikke svarte, fordi jeg talte, og I ikke hørte, men gjorde det som ondt var i mine øine, og valgte det jeg ikke hadde behag i. 
23:65:13: Derfor sier Herren, Israels Gud, så: Se, mine tjenere skal ete, men I skal hungre; se, mine tjenere skal drikke, men I skal tørste; se, mine tjenere skal glede sig, men I skal skamme eder; 
23:65:14: se, mine tjenere skal juble av hjertens lyst, men I skal skrike av hjertets pine og hyle i fortvilelse. 
23:65:15: Og I skal efterlate eders navn til en ed* for mine utvalgte, og Herren, Israels Gud, skal drepe dig; men sine tjenere skal han kalle med et annet navn, # <* folk skal si: Gud straffe mig som han straffet dem, dersom jeg o.s.v. SLM 102, 9. JER 29, 22. Es. 62, 2. APE 2, 17.> 
23:65:16: så den som velsigner sig på jorden, skal velsigne sig i den trofaste Gud, og den som sverger på jorden, skal sverge ved den trofaste Gud. For de forrige trengsler er glemt og skjult for mine øine. 
23:65:17: For se, jeg skaper en ny himmel og en ny jord, og de første ting skal ikke minnes og ikke mere rinne nogen i hu. 
23:65:18: Men gled og fryd eder til evig tid over det jeg skaper! For se, jeg skaper Jerusalem til jubel og dets folk til fryd. 
23:65:19: Og jeg vil juble over Jerusalem og fryde mig i mitt folk, og det skal ikke mere høres gråt eller skrik der. 
23:65:20: Det skal ikke mere komme derfra noget diebarn som bare lever få dager, eller en gammel mann som ikke fyller sine dagers mål; nei, en ung mann skal den være som dør hundre år gammel, og hundre år gammel skal den synder bli som forbannes*. # <* SLM 55, 24.> 
23:65:21: Og de skal bygge hus og bo i dem og plante vingårder og ete deres frukt; 
23:65:22: ikke skal de bygge og en annen bo, ikke skal de plante og en annen ete; nei, som treets dager skal mitt folks dager være, og mine utvalgte skal selv få nyte frukten av sine henders gjerning. 
23:65:23: De skal ikke gjøre sig møie forgjeves og ikke føde barn til en brå død; for de er en ætt velsignet av Herren, og sitt avkom får de ha hos sig. 
23:65:24: Og det skal skje: før de roper, skal jeg svare, mens de ennu taler, skal jeg høre. 
23:65:25: Ulven og lammet skal beite sammen, og løven skal ete halm som oksen, og ormens føde skal være støv; ingen skal gjøre noget ondt og ingen ødelegge noget på hele mitt hellige berg, sier Herren. 
23:66:1: Så sier Herren: Himmelen er min trone, og jorden mine føtters skammel; hvad hus kunde I bygge mig, og hvor skulde det finnes et hvilesteed for mig? 
23:66:2: Alt dette har jo min hånd gjort, og således blev alt dette til, sier Herren. Men den jeg vil se til, det er den elendige, og den som har en sønderbrutt ånd og er forferdet over mitt ord. 
23:66:3: Den som slakter en okse, er som den som dreper en mann; den som ofrer et lam, er som den som bryter nakken på en hund; den som ofrer matoffer, er som den som ofrer svineblod; den som bærer frem ihukommelsesoffer av virak, er som den som priser avguder. Likesom de har valgt sine egne veier, og deres sjel har behag i deres vederstyggeligheter, 
23:66:4: så vil jeg velge å gjøre ondt imot dem, og det de frykter for, vil jeg la komme over dem, fordi jeg ropte, men ingen svarte, jeg talte, men de hørte ikke, de gjorde det som ondt var i mine øine, og valgte det jeg ikke hadde behag i. 
23:66:5: Hør Herrens ord, I som er forferdet over hans ord! Eders brødre som hater eder, som støter eder fra sig for mitt navns skyld, sier: La Herren vise sin herlighet, så vi kan få se eders glede! Men de skal bli til skamme. 
23:66:6: Hør! Bulder fra byen! En røst fra templet, Herrens røst, han som gjengjelder sine fiender det de har gjort! 
23:66:7: Før hun* var i barnsnød, har hun født; før veene kom over henne. har hun født et guttebarn til verden. # <* Jerusalem. APO 2, 41. 47; 11, 18; 12, 24. 2MO 4, 22. JES 54, 1. GLT 4, 27. APE 12, 5.> 
23:66:8: Hvem har hørt slikt? Hvem har sett sådanne ting? Kommer et land til verden på en dag, eller fødes et folk på en gang? For Sion har vært i barnsnød og med det samme født sine sønner. 
23:66:9: Skulde jeg åpne modermunnen og ikke la føde? sier Herren. Eller skulde jeg som lar føde, lukke den igjen? sier din Gud. 
23:66:10: Gled eder med Jerusalem og juble over henne, alle I som elsker henne! Fryd eder storlig med henne, alle I som sørger over henne! 
23:66:11: Så skal I få die og mettes av hennes husvalende bryst, suge og glede eder ved hennes store herlighet. 
23:66:12: For så sier Herren: Se, jeg lar fred komme over henne som en elv og hedningenes herlighet som en overstrømmende bekk, og I skal få die; på armen skal I bæres, og på knærne skal I kjærtegnes. 
23:66:13: Som en mor trøster sitt barn, således vil jeg trøste eder; i Jerusalem skal I få trøst. 
23:66:14: Og I skal se det, og eders hjerte skal fryde sig, og eders ben skal grønnes som gresset, og det skal kjennes at Herrens hånd er med hans tjenere, men han skal vredes på sine fiender. 
23:66:15: For se, Herren skal komme i ild, og hans vogner skal være som stormvind, for å gjengjelde med sin vrede i glødende brand og med sin trusel i ildsluer; 
23:66:16: for med ild skal Herren holde dom og med sitt sverd slå alt kjød, og mange skal det bli som Herren slår ihjel. 
23:66:17: De som helliger og renser sig for havene* efter en som er midt iblandt dem**, de som eter svinekjøtt og vederstyggelige dyr, endog mus, de skal omkomme alle sammen, sier Herren. # <* JES 65, 3. 4.> # <** d.e. deres veiledere, som de retter sig efter.> 
23:66:18: Og jeg, jeg gjør deres gjerninger og deres tanker til intet; den tid kommer da jeg samler alle folk og tungemål, og de skal komme og se min herlighet. 
23:66:19: Og jeg vil gjøre et tegn på dem og sende nogen av de undkomne blandt dem til hedningefolkene, til Tarsis, Ful og Lud, bueskytterne, til Tubal og Javan, til de fjerne kyster som ikke har hørt tidenden om mig og ikke sett min herlighet; og de skal kunngjøre min herlighet blandt hedningefolkene. 
23:66:20: Og fra alle hedningefolkene skal de komme med alle eders brødre til matoffer for Herren, på hester, på vogner, i bærestoler, på mulesler og på hurtigløpende kameler, og føre dem op på mitt hellige berg i Jerusalem, sier Herren, likesom Israels barn bærer matofferet i rene kar til Herrens hus; 
23:66:21: også av dem* vil jeg ta mig nogen til prester**, til levitter, sier Herren. # <* d.e. hedningefolkene.> # <** 1PE 2, 5.> 
23:66:22: For likesom den nye himmel og den nye jord som jeg gjør, blir til evig tid for mitt åsyn, sier Herren, således skal eders ætt og eders navn bli til evig tid. 
23:66:23: Og det skal skje: Nymåne efter nymåne og sabbat efter sabbat skal alt kjød komme for å tilbede for mitt åsyn, sier Herren. 
23:66:24: Og de skal gå ut og se på de døde kropper av de menn som er falt fra mig; for deres orm skal ikke dø, og deres ild skal ikke slukkes, og de skal være en gru for alt kjød. 
24:1:1: Ord av Jeremias, Hilkias' sønn, en av prestene i Anatot i Benjamins land. 
24:1:2: Herrens ord kom til ham i de dager da Josias, Amons sønn, var konge i Juda, i det trettende år av hans regjering, 
24:1:3: og siden i de dager da Jojakim, Josias' sønn, var konge i Juda, inntil enden av Judas konge Sedekias', Josias' sønns ellevte år, da Jerusalems innbyggere blev bortført i den femte måned. 
24:1:4: Herrens ord kom til mig, og det lød så: 
24:1:5: Før jeg dannet dig i mors liv, kjente jeg dig, og før du kom ut av mors skjød, helliget jeg dig; jeg satte dig til en profet for folkene. 
24:1:6: Men jeg sa: Akk, Herre, Herre! Se, jeg forstår ikke å tale; for jeg er ung. 
24:1:7: Da sa Herren til mig: Si ikke: Jeg er ung! Men til alle dem jeg sender dig til, skal du gå, og alt det jeg byder dig, skal du tale. 
24:1:8: Frykt ikke for dem, for jeg er med dig og vil redde dig, sier Herren. 
24:1:9: Og Herren rakte ut sin hånd og rørte ved min munn, og Herren sa til mig: Se, jeg legger mine ord i din munn. 
24:1:10: Se, jeg setter dig idag over folkene og over rikene til å rykke op og rive ned, til å ødelegge og bryte ned, til å bygge og til å plante. 
24:1:11: Og Herrens ord kom til mig, og det lød så: Hvad ser du, Jeremias? Jeg svarte: Jeg ser en stav av det våkne tre*. # <* d.e. av mandeltreet, som kalles så fordi det blomstrer tidligere enn andre trær.> 
24:1:12: Da sa Herren til mig: Du har sett rett; for jeg vil våke over mitt ord, så jeg fullbyrder det. 
24:1:13: Og Herrens ord kom til mig annen gang: Hvad ser du? Jeg svarte: Jeg ser en kokende gryte, og den er vendt hit fra nord. 
24:1:14: Og Herren sa til mig: Fra nord skal ulykken slippes løs over alle landets innbyggere. 
24:1:15: For se, jeg kaller på alle folkestammer i rikene mot nord, sier Herren, og de skal komme og sette hver sin stol ved inngangen til Jerusalems porter og mot alle dets murer rundt omkring og mot alle Judas byer. 
24:1:16: Og jeg vil avsi mine dommer over dem for all deres ondskaps skyld, fordi de forlot mig og brente røkelse for andre guder og tilbad sine henders verk. 
24:1:17: Men du skal omgjorde dine lender og stå op og tale til dem alt det jeg byder dig; vær ikke redd for dem, så jeg ikke skal gjøre dig redd for dem! 
24:1:18: Og se, jeg gjør dig idag til en fast borg og til en jernstøtte og til en kobbermur mot det hele land, mot Judas konger, mot dets høvdinger, mot dets prester og mot folket i landet. 
24:1:19: Og de skal stride mot dig, men ikke få overhånd over dig; for jeg er med dig, sier Herren, og vil redde dig. 
24:2:1: Og Herrens ord kom til mig, og det lød så: 
24:2:2: Gå avsted og rop ut for Jerusalems ører og si: Så sier Herren: Jeg kommer i hu din ungdoms hullskap, dine trolovelsesdagers kjærlighet, hvorledes du fulgte mig i ørkenen, i et land hvor ingen sår. 
24:2:3: Hellig var Israel for Herren, hans førstegrøde*; alle som vilde ete det, måtte bøte, ulykke kom over dem, sier Herren. # <* JAK 1, 18.> 
24:2:4: Hør Herrens ord, Jakobs hus og alle ætter av Israels hus! 
24:2:5: Så sier Herren: Hvad urett har eders fedre funnet hos mig, siden de gikk langt bort fra mig og fulgte de tomme guder og blev selv tomme? 
24:2:6: Og de sa ikke: Hvor er Herren, som førte oss op fra Egyptens land, som ledet oss i ørkenen, i et land med ødemarker, fullt av kløfter, et land med tørke og dødsskygge, et land der ingen mann har faret, og intet menneske har bodd? 
24:2:7: Og jeg førte eder inn i et land med fruktbare marker, så I fikk ete av dets frukt og dets gode ting; men da I kom inn, gjorde I mitt land urent og min arv til en vederstyggelighet. 
24:2:8: Prestene sa ikke: Hvor er Herren? Og de som syslet med loven, kjente mig ikke, og hyrdene falt fra mig, og profetene spådde ved Ba'al og fulgte dem som ikke kan hjelpe*. # <* d.e. avgudene.> 
24:2:9: Derfor vil jeg fremdeles gå i rette med eder, sier Herren; ja, med eders barnebarn vil jeg gå i rette. 
24:2:10: Dra over til kitteernes øer og se, og send bud til Kedar og gi nøie akt og se om noget sådant er skjedd! 
24:2:11: Har vel et hedningefolk byttet bort sine guder, enda de ikke er guder? Men mitt folk har byttet bort sin ære mot det som ikke kan hjelpe*. # <* JER 2, 8. SLM 106, 20.> 
24:2:12: Forskrekkes, I himler, over dette, og gys, bli storlig forferdet, sier Herren. 
24:2:13: For to onde ting har mitt folk gjort: Mig har de forlatt, kilden med det levende vann, og hugget sig ut brønner, sprukne brønner som ikke holder vann. 
24:2:14: Er Israel en træl? Er han en hjemmefødt træl? Hvorfor er han blitt røvet? 
24:2:15: Unge løver brølte mot ham; de lot sin røst høre, og de gjorde hans land til en ørken; hans byer blev opbrent, så ingen bor i dem. 
24:2:16: Også Memfis' og Tahpenes' barn gjorde din isse skallet. 
24:2:17: Mon ikke det at du forlot Herren din Gud den tid han vilde lede dig på veien, har voldt dig dette? 
24:2:18: Og nu, hvorfor tar du veien til Egypten for å drikke Sikors* vann? Og hvorfor tar du veien til Assyria for å drikke elvens** vann? # <* d.e. Nilens.> # <** d.e. Eufrats.> 
24:2:19: Din ondskap tukter dig, og dine frafall straffer dig; kjenn da og se at det er ondt og bittert at du forlater Herren din Gud, og at frykt for mig ikke kommer over dig, sier Herren, Israels Gud, hærskarenes Gud. 
24:2:20: For fra fordums tid brøt du ditt åk, rev du i stykker dine bånd, og du sa: Jeg vil ikke tjene. Men på hver høi bakke og under hvert grønt tre la du dig ned og drev utukt. 
24:2:21: Og enda hadde jeg plantet dig som en edel ranke, helt igjennem av ekte sæd; hvorledes er du da blitt omskapt for mig til ville skudd av et fremmed vintre? 
24:2:22: For om du enn vilde tvette dig med pottaske og bruke meget lut, så skulde dog din misgjerning være skitten for mitt åsyn, sier Herren, Israels Gud. 
24:2:23: Hvorledes kan du si: Jeg er ikke blitt uren, jeg har ikke gått efter Ba'alene? Se på din vei i dalen*! Forstå hvad du har gjort, du lette kamelhoppe som løper hit og dit! # <* JER 7, 31.> 
24:2:24: Lik et villesel som er vant til ørkenen, snapper hun efter været i sin sjels attrå; hennes brynde - hvem kan dempe den? Alle de som søker henne, slipper å løpe sig trette; i hennes måned finner de henne. 
24:2:25: La ikke din fot bli bar og din strupe bli tørst! Men du sier: Forgjeves! Nei! Jeg elsker fremmede, og efter dem vil jeg gå. 
24:2:26: Likesom en tyv må skamme sig når han blir grepet, således må Israels hus skamme sig, de selv, deres konger, deres høvdinger, deres prester og deres profeter, 
24:2:27: fordi de sier til treet: Du er min far, og til stenen: Du har født mig. For de har vendt ryggen til mig og ikke ansiktet. Men i sin ulykkes tid sier de: Reis dig og hjelp oss! 
24:2:28: Hvor er da dine guder som du gjorde dig? La dem reise sig, om de kan hjelpe dig i din ulykkes tid! For så mange som dine byer er, så mange er dine guder blitt, Juda! 
24:2:29: Hvorfor tretter I med mig? I er alle sammen falt fra mig, sier Herren. 
24:2:30: Forgjeves slo jeg eders barn; de tok ikke imot tukt; eders sverd fortærte eders profeter som en ødeleggende løve. 
24:2:31: Du onde slekt! Gi akt på Herrens ord! Har jeg vært en ørken for Israel eller et belgmørkt land? Hvorfor sier mitt folk: Vi har gjort oss fri, vi vil ikke mere komme til dig? 
24:2:32: Mon en jomfru glemmer sitt smykke, en brud sitt belte? Men mitt folk har glemt mig i dager uten tall. 
24:2:33: Hvor vel vet du ikke å legge din vei for å søke kjærlighet! Derfor har du også vent dine veier til det onde. 
24:2:34: Det finnes endog blod av uskyldige fattigfolk på dine kjortelfliker; du grep dem ikke i innbrudd*, men på grunn av alt dette var det**. # <* 2MO 22, 2.> # <** d.e. på grunn av dette ditt ugudelige vesen (JER 2, 23 fg.) utøste du deres blod.> 
24:2:35: Og allikevel sier du: Jeg er uskyldig, hans vrede har visselig vendt sig fra mig. Se, jeg vil gå i rette med dig fordi du sier: Jeg har ikke syndet. 
24:2:36: Hvorfor farer du så meget om og skifter din vei? Også ved Egypten skal du bli til skamme, likesom du blev til skamme ved Assyria. 
24:2:37: Også derfra skal du gå ut med hendene på hodet*; for Herren har forkastet dem du setter din lit til, og du skal ikke ha nogen lykke med dem. # <* d.e. med sorg; 2SA 13, 19.> 
24:3:1: Det heter: Når en mann lar sin hustru fare, og hun går fra ham og blir en annen manns, kan han da igjen komme tilbake til henne? Vilde ikke da landet bli vanhelliget? Og du, du har drevet hor med mange venner og skulde enda komme tilbake til mig igjen? sier Herren. 
24:3:2: Løft dine øine til de bare hauger og se: Hvor er du ikke blitt vanæret? Ved veiene satt du og ventet på dem, som en araber i ørkenen, og du vanhelliget landet ved ditt hor og din ondskap. 
24:3:3: Da blev regnbygene holdt tilbake, og vårregnet falt ikke; men du hadde en horkvinnes panne, du vilde ikke skamme dig. 
24:3:4: Har du ikke nettop nu ropt til mig: Min far, du er min ungdoms venn? 
24:3:5: Mon han vil holde fast ved sin vrede evindelig eller bevare sin harme til evig tid? Se, så talte du, og enda gjorde du det onde, og du maktet det. 
24:3:6: Og Herren sa til mig i kong Josias' dager: Har du sett hvad Israel, den frafalne kvinne, har gjort? Hun gikk op på hvert høit fjell og inn under hvert grønt tre og drev hor der. 
24:3:7: Og jeg sa: Når hun har gjort alt dette, vil hun vende tilbake til mig. Men hun vendte ikke tilbake, og det så hennes søster Juda, den troløse. 
24:3:8: Og jeg så at enda jeg hadde latt den frafalne, Israel, fare og gitt henne hennes skilsmissebrev, fordi hun hadde drevet hor, fryktet allikevel ikke hennes søster Juda, den troløse, men gikk avsted og drev hor hun også; 
24:3:9: og med sitt frekke hor vanhelliget hun landet, og hun drev hor med sten og med tre. 
24:3:10: Og med alt dette vendte hennes søster Juda, den troløse, ikke tilbake til mig av hele sitt hjerte, men bare på skrømt, sier Herren. 
24:3:11: Og Herren sa til mig: Den frafalne, Israel, har vist sig rettferdigere enn den troløse, Juda. 
24:3:12: Gå avsted og rop ut disse ord mot nord og si: Vend tilbake, Israel, du frafalne, sier Herren; jeg vil ikke senke mitt åsyn i vrede mot eder; for jeg er miskunnelig, sier Herren, jeg vil ikke holde fast ved min vrede evindelig. 
24:3:13: Bare erkjenn din misgjerning, at du er falt fra Herren din Gud og på forskjellige veier har løpet om til de fremmede guder, inn under hvert grønt tre, og på min røst har I ikke hørt, sier Herren. 
24:3:14: Vend tilbake, I frafalne barn, sier Herren; for jeg er eders ekteherre, og jeg vil ta eder, en av en by og to av en ætt, og føre eder til Sion. 
24:3:15: Og jeg vil gi eder hyrder efter mitt hjerte, og de skal røkte eder med forstand og visdom. 
24:3:16: Og når I blir et stort og tallrikt folk i landet i de dager, sier Herren, da skal de ikke mere tale om Herrens pakts-ark eller tenke på den; de skal ikke komme den i hu og ikke savne den, og der skal ikke mere gjøres nogen slik ark. 
24:3:17: På den tid skal de kalle Jerusalem Herrens trone, og alle folkene skal samle sig der, til Herrens navn i Jerusalem, og de skal ikke mere følge sitt onde, hårde hjerte. 
24:3:18: I de dager skal Judas hus gå til Israels hus, og de skal komme sammen fra landet i nord til det land jeg gav eders fedre til arv. 
24:3:19: Og jeg, jeg sa: Hvor høit vil jeg ikke sette dig blandt barna og gi dig et lystelig land, den herligste arv iblandt folkene! Og jeg sa fremdeles: I skal rope til mig: Min far! og ikke vende eder bort fra mig. 
24:3:20: Men sannelig, som en kvinne er troløs mot sin venn, således har I vært troløse mot mig, Israels hus! sier Herren. 
24:3:21: En røst høres på de bare hauger, Israels barns gråt og bønner; for de har gått på onde veier, de har glemt Herren sin Gud. 
24:3:22: Vend tilbake, I frafalne barn! Jeg vil læge eders frafall. Se, vi kommer til dig; for du er Herren vår Gud. 
24:3:23: Sannelig, fåfengt er det at I larmer på haugene*; sannelig, i Herren vår Gud er Israels frelse! # <* d.e. holde larmende avgudstjeneste på dem.> 
24:3:24: Men den skammelige avgudsdyrkelse har fortært frukten av våre fedres arbeid helt fra vår ungdom av, deres småfe og storfe, deres sønner og døtre. 
24:3:25: La oss ligge i vår vanære, og la vår skam dekke oss! For mot Herren vår Gud har vi syndet, vi og våre fedre, fra vår ungdom av like til denne dag, og vi har ikke hørt på Herrens, vår Guds røst. 
24:4:1: Dersom du vender om, Israel, sier Herren, skal du få komme tilbake til mig, og dersom du tar dine vederstyggeligheter bort fra mitt åsyn, skal du ikke vanke hjemløs om, 
24:4:2: og sverger du: Så sant Herren lever! i sannhet, med rett og rettferdighet, da skal hedningefolk velsigne sig i ham og rose sig av ham. 
24:4:3: For så sier Herren til Judas menn og til Jerusalem: Bryt eder nytt land og så ikke blandt torner! 
24:4:4: Omskjær eder for Herren og ta bort eders hjertes forhud, I Judas menn og Jerusalems innbyggere, forat ikke min harme skal fare ut som ild og brenne, uten at nogen slukker, for eders onde gjerningers skyld! 
24:4:5: Forkynn det i Juda og kunngjør det i Jerusalem og si: Støt i basun i landet! Rop ut med full røst og si: Samle eder og la oss gå inn i de faste byer! 
24:4:6: Løft op banner bortimot Sion, flytt bort, stans ikke! Jeg lar ulykke komme fra nord og stor ødeleggelse. 
24:4:7: En løve er steget op fra sitt kratt, og folkenes ødelegger har brutt op, har draget ut fra sitt hjem for å gjøre ditt land til en ørken; dine byer skal ødelegges, så ingen bor der. 
24:4:8: Derfor omgjord eder med sekk, klag og skrik! For Herrens brennende vrede har ikke vendt sig fra oss. 
24:4:9: Og på den dag, sier Herren, skal kongens forstand og høvdingenes forstand svikte, og prestene skal forferdes og profetene bli fylt av redsel. 
24:4:10: Da sa jeg: Akk, Herre, Herre! Sannelig, ille har du sveket dette folk og Jerusalem, da du sa: Fred skal bli eder til del. Og nu er sverdet trengt inn i sjelen. 
24:4:11: På den tid skal det sies til dette folk og til Jerusalem: En brennende vind fra de bare hauger i ørkenen blåser mot mitt folks datter, ikke til å kaste eller rense korn med. 
24:4:12: Nei, en sterkere vind skal jeg la komme; nu vil også jeg avsi dom over dem. 
24:4:13: Se, som skyer drar han* op, og som stormvind er hans vogner, hans hester er lettere enn ørner; ve oss, vi er ødelagt. # <* fienden. JES 5, 28. HAB 1, 8.> 
24:4:14: Tvett ondskapen av ditt hjerte, Jerusalem, så du kan bli frelst! Hvor lenge skal dine syndige tanker bo i ditt indre? 
24:4:15: For en røst kommer med tidende fra Dan og varsler ondt fra Efra'ims fjell. 
24:4:16: Forkynn det for folkene, kunngjør det for Jerusalem: Voktere kommer fra det fjerne land, og de lar sin røst høre mot Judas byer. 
24:4:17: Som markvoktere leirer de sig mot det rundt omkring; for mot mig har det vært gjenstridig, sier Herren. 
24:4:18: Din ferd og dine gjerninger har voldt dig dette; dette er frukten av din ondskap, at det er bittert, at det når like til ditt hjerte. 
24:4:19: Mine innvoller, mine innvoller! Jeg pines! Å mitt hjertes vegger! Mitt hjerte bruser i mig, jeg kan ikke tie! For basunlyd, krigsskrik har du hørt, min sjel! 
24:4:20: Ødeleggelse på ødeleggelse roper de om; for hele landet er ødelagt; brått er mine telt ødelagt, i et øieblikk mine telttepper. 
24:4:21: Hvor lenge skal jeg se banneret, skal jeg høre basunlyd? 
24:4:22: For uklokt er mitt folk, mig kjenner de ikke; de er uvettige barn, og uforstandige er de; de er vise til å gjøre det onde, men å gjøre det gode skjønner de ikke. 
24:4:23: Jeg så jorden, og se, den var øde og tom; jeg så til himmelen, og dens schmelk var borte. 
24:4:24: Jeg så fjellene, og se, de bevet, og alle haugene skalv. 
24:4:25: Jeg så, og se, det var intet menneske mere, og alle himmelens fugler var fløiet bort. 
24:4:26: Jeg så, og se, den fruktbare mark var en ørken, og alle dens byer var brutt ned av Herren, av hans brennende vrede. 
24:4:27: For så sier Herren: En ørken skal hele landet bli; men jeg vil ikke gjøre aldeles ende på det. 
24:4:28: Derfor skal jorden sørge, og himmelen der oppe sortne, fordi jeg har talt det og villet det så, og jeg angrer det ikke og tar det ikke tilbake. 
24:4:29: For larmen av ryttere og bueskyttere er alle byer på flukt; de går inn i skogene og stiger op på fjellene; alle byene er forlatt, det er ingen som bor i dem. 
24:4:30: Og du, når du blir ødelagt, hvad vil du da gjøre? Om du klær dig i purpur, om du pryder dig med gullstas, om du gjør dine øine store med sminke, så er det fåfengt at du gjør dig yndig; dine elskere forsmår dig, de vil ta ditt liv. 
24:4:31: For jeg hører et rop som av en kvinne i barnsnød, et angstskrik som av en kvinne når hun føder sitt første barn; det er Sions datter som roper; hun stønner, hun strekker ut sine hender og sier: Ve mig! Maktløs synker min sjel i morderes vold. 
24:5:1: Gå omkring i gatene i Jerusalem og se efter og gi akt, søk på torvene om I finner nogen, om det er nogen som gjør rett, som streber efter redelighet! Så vil jeg tilgi byen. 
24:5:2: Og om de enn sier: Så sant Herren lever, så sverger de allikevel falsk. 
24:5:3: Herre! Ser ikke dine øine efter redelighet? Du slo dem, men de kjente ingen smerte; du gjorde ende på dem, de vilde ikke ta imot tukt, de gjorde sitt ansikt hårdere enn sten, de vilde ikke vende om. 
24:5:4: Da sa jeg: Det er bare småfolk*; de er uforstandige, for de kjenner ikke Herrens vei, sin Guds rett; # <* som har båret sig således at; JER 5, 3.> 
24:5:5: jeg vil nu gå til storfolk og tale med dem; for de kjenner Herrens vei, sin Guds rett. Men nettop de har alle sammen brutt åket og sprengt båndene. 
24:5:6: Derfor skal løven fra skogen slå dem, ulven fra villmarkene ødelegge dem; leoparden lurer utenfor deres byer, hver den som går ut av dem, skal bli revet i stykker; for mange er deres overtredelser, tallrike deres frafall. 
24:5:7: Hvorfor skulde jeg tilgi dig? Dine barn har forlatt mig og svoret ved guder som ikke er guder; og jeg mettet dem, men de drev hor og flokket sig i skjøgens hus. 
24:5:8: Som velnærte hester er de tidlig på ferde; de vrinsker, hver efter sin næstes hustru. 
24:5:9: Skulde jeg ikke straffe slikt, sier Herren, eller skulde min sjel ikke hevne sig på et folk som dette? 
24:5:10: Stig op på dets murer og ødelegg, men gjør ikke helt ende på det! Ta bort dets kvister, de hører ikke Herren til! 
24:5:11: For Israels hus og Judas hus har båret sig troløst at imot mig, sier Herren. 
24:5:12: De har fornektet Herren og sagt: Han er ikke til, og det skal ikke komme nogen ulykke over oss, og sverd og hunger skal vi ikke se, 
24:5:13: og profetene skal bli til vind; for det er ingen som taler i dem. Således skal det gå dem selv. 
24:5:14: Derfor sier Herren, hærskarenes Gud, så: Fordi I taler slike ord, se, så gjør jeg mine ord i din munn til en ild og dette folk til ved, og ilden skal fortære dem. 
24:5:15: Se, jeg lar et folk fra det fjerne komme over eder, Israels hus, sier Herren; et kraftig folk er det, et folk fra eldgammel tid, et folk hvis tungemål du ikke kjenner, og hvis tale du ikke skjønner. 
24:5:16: Deres kogger er som en åpen grav, de er alle sammen kjemper. 
24:5:17: Og de skal fortære din høst og ditt brød, de skal fortære dine sønner og dine døtre, de skal fortære ditt småfe og storfe, de skal fortære ditt vintre og fikentre; dine faste byer som du setter din lit til, skal de bryte ned med sverd. 
24:5:18: Men selv i de dager, sier Herren, vil jeg ikke gjøre helt ende på eder. 
24:5:19: Og når I da sier: Hvorfor har Herren vår Gud gjort alt dette mot oss? - da skal du si til dem: Likesom I forlot mig og tjente fremmede guder i eders land, således skal I også tjene fremmede herrer i et land som ikke er eders. 
24:5:20: Forkynn dette i Jakobs hus og kunngjør det i Juda og si: 
24:5:21: Hør dette, du uvettige og uforstandige folk, som har øine, men ikke ser, som har ører, men ikke hører! 
24:5:22: Vil I ikke frykte mig, sier Herren, vil I ikke beve for mitt åsyn? Jeg som har satt sanden til grense for havet, til en evig demning som det ikke kan komme over, så om bølgene raser, makter de intet, og om de bruser, kan de ikke komme over den. 
24:5:23: Men dette folk har et trossig og gjenstridig hjerte; de har veket fra ham og er gått bort. 
24:5:24: Og de har ikke sagt i sitt hjerte: La oss frykte Herren vår Gud, som gir regn i rett tid, både høstregn og vårregn, han som holder ved lag for oss den ordning han har fastsatt for høstens uker! 
24:5:25: Eders misgjerninger har gjort at alt dette er kommet i ulag for eder, og eders synder har holdt det gode borte fra eder. 
24:5:26: For det finnes ugudelige blandt mitt folk; de legger sig på lur, likesom fuglefangere dukker sig ned; de setter ut snarer og fanger mennesker. 
24:5:27: Som et bur er fullt av fugler, således er deres hus fulle av svik; derfor er de blitt store og rike. 
24:5:28: De er blitt fete, de glinser, de strømmer over av ondt, de dømmer ikke rett i nogen sak, ikke i den farløses sak, så de fremmer den, og fattigfolk hjelper de ikke til deres rett. 
24:5:29: Skulde jeg ikke straffe slikt, sier Herren, skulde min sjel ikke hevne sig på et folk som dette? 
24:5:30: Forferdelige og gruelige ting skjer i landet; 
24:5:31: profetene profeterer løgn, og prestene styrer efter deres råd, og mitt folk vil gjerne ha det således. Men hvad vil I gjøre når enden på dette kommer? 
24:6:1: Flytt bort, I Benjamins barn, ut av Jerusalem, og støt i basun i Tekoa og reis op et tegn over Bet-Hakkerem! For ulykke truer fra nord med stor ødeleggelse. 
24:6:2: Den fagre og forkjælede, Sions datter, gjør jeg til intet. 
24:6:3: Til henne skal hyrder komme med sine hjorder; de slår op telt mot henne rundt omkring, de beiter av hver sitt stykke. 
24:6:4: Innvi eder til krig mot henne! Stå op og la oss dra op om middagen! Ve oss! For dagen heller, og aftenens skygger blir lange. 
24:6:5: Stå op og la oss dra op om natten, og la oss ødelegge hennes palasser! 
24:6:6: For så sier Herren, hærskarenes Gud: Fell trær og kast op en voll mot Jerusalem! Det er byen som skal hjemsøkes; den er aldeles full av undertrykkelse i sitt indre. 
24:6:7: Som en brønn lar sitt vann velle ut, således lar den sin ondskap velle ut; vold og ødeleggelse høres i den, sår og slag er alltid for mitt åsyn. 
24:6:8: La dig advare, Jerusalem, så ikke min sjel skal vende sig fra dig, så jeg ikke skal gjøre dig til en ørken, til et ubygget land! 
24:6:9: Så sier Herren, hærskarenes Gud: En efterhøst skal de holde på det som er blitt igjen av Israel, likesom på et vintre.* Rekk atter og atter ut din hånd, likesom vinhøsteren til rankene! # <* Herrens ord til fienden.> 
24:6:10: Hvem skal jeg tale og vidne for, så de hører? Se, deres øre er uomskåret, så de ikke kan gi akt; se, Herrens ord er blitt til spott blandt dem, de liker det ikke; 
24:6:11: men jeg er fylt av Herrens vrede, jeg er trett av å holde den hos mig.* Utøs den over barnet på gaten og over de unge menns fortrolige krets! Ja, både mann og kvinne skal rammes, både den gamle og den eldgamle, # <* Herrens ord til profeten.> 
24:6:12: og deres hus skal gå over til andre, og likeså deres marker og deres hustruer; for jeg vil rekke ut min hånd mot landets innbyggere, sier Herren. 
24:6:13: For både små og store søker alle sammen urettferdig vinning; både profet og prest gjør alle sammen svik, 
24:6:14: og de læger mitt folks skade på lettferdig vis, idet de sier: Fred! Fred! Og det er dog ingen fred. 
24:6:15: De skal bli til skamme, for de har gjort vederstyggelige ting. De hverken skammer sig eller kjenner til blygsel; derfor skal de falle blandt dem som faller; på den tid jeg hjemsøker dem, skal de snuble, sier Herren. 
24:6:16: Så sa Herren: Stå på veiene og se til, og spør efter de gamle stier, spør hvor veien går til det gode, og vandre på den! Så skal I finne hvile for eders sjeler. Men de sa: Vi vil ikke vandre på den. 
24:6:17: Og jeg satte vektere over eder og sa: Gi akt på basunens lyd! Men de sa: Vi vil ikke gi akt. 
24:6:18: Derfor hør, I hedningefolk, og vit, du menighet, hvad som skjer iblandt dem! 
24:6:19: Hør, du jord: Se, jeg lar ulykke komme over dette folk, frukten av deres onde råd; for på mine ord har de ikke gitt akt, og min lov har de forkastet. 
24:6:20: Hvad skal jeg med virak fra Sjeba og med den beste kalmus fra et fjernt land? Eders brennoffer er ikke til velbehag for mig, og eders slaktoffer vil jeg ikke ha. 
24:6:21: Derfor sier Herren så: Se, jeg setter støtestener for dette folk, og både fedre og barn skal snuble over dem; granne med granne skal omkomme. 
24:6:22: Så sier Herren: Se, et folk kommer fra landet i nord, et stort folk skal bryte op fra jordens ytterste ende. 
24:6:23: Bue og spyd holder de i hånden; de er grusomme og skåner ingen; deres røst bruser som havet, og på hester kommer de ridende, rustet som krigsmenn, mot dig, Sions datter! 
24:6:24: Vi har hørt ryktet om det, våre hender er blitt kraftløse; angst har grepet oss, smerter som den fødende kvinnes. 
24:6:25: Gå ikke ut på marken og vandre ikke på veien! For fienden har sverd, det er redsel rundt omkring. 
24:6:26: Mitt folks datter! Omgjord dig med sekk og velt dig i aske, hold sorg som over det eneste barn, bitter veklage! Brått kommer ødeleggeren over oss. 
24:6:27: Til en prøver har jeg satt dig blandt mitt folk, til en fast borg, forat du skal kjenne og prøve deres vei. 
24:6:28: De er alle sammen de stridigste gjenstridige, de går omkring som baktalere, de er kobber og jern; de gjør alle det som er til skade. 
24:6:29: Blåsebelgen er opbrent av ilden, blyet er fortært; forgjeves har de smeltet og smeltet, og de onde er ikke skilt ut. 
24:6:30: Vraket sølv kalles de; for Herren har vraket dem. 
24:7:1: Dette er det ord som kom til Jeremias fra Herren: 
24:7:2: Still dig i porten til Herrens hus og rop ut der dette ord og si: Hør Herrens ord, hele Juda, I som går inn igjennem disse porter for å tilbede Herren! 
24:7:3: Så sier Herren, hærskarenes Gud, Israels Gud: Bedre eders veier og eders gjerninger! Så vil jeg la eder bo på dette sted. 
24:7:4: Sett ikke eders lit til løgnaktige ord, når folk sier: Her er Herrens tempel, Herrens tempel, Herrens tempel! 
24:7:5: Men dersom I bedrer eders veier og eders gjerninger, dersom I skifter rett mellem mann og mann, 
24:7:6: ikke undertrykker den fremmede, den farløse og enken og ikke utøser uskyldig blod på dette sted og ikke følger andre guder til ulykke for eder selv, 
24:7:7: da vil jeg la eder bo på dette sted, i det land jeg gav eders fedre, fra evighet og til evighet. 
24:7:8: Se, I setter eders lit til løgnaktige ord - til ingen nytte. 
24:7:9: Hvorledes? I stjeler, slår ihjel, driver hor, sverger falsk og brenner røkelse for Ba'al og følger andre guder, som I ikke kjenner, 
24:7:10: og så kommer I og står frem for mitt åsyn i dette hus som er kalt med mitt navn, og sier: Vi er frelst - og så tenker I at I fremdeles kan gjøre alle disse vederstyggelige ting? 
24:7:11: Er da dette hus som er kalt med mitt navn, blitt en røverhule i eders øine? Se, også jeg har sett det, sier Herren. 
24:7:12: Gå til min bolig som var i Silo, der jeg i førstningen lot mitt navn bo, og se hvad jeg har gjort med det for mitt folk Israels ondskaps skyld! 
24:7:13: Og nu, fordi I gjør alle disse gjerninger, sier Herren, og jeg talte til eder tidlig og sent, men I hørte ikke, og jeg kalte på eder, men I svarte ikke, 
24:7:14: så vil jeg gjøre med det hus som er kalt med mitt navn, og som I setter eders lit til, og med det sted jeg gav eder og eders fedre, således som jeg har gjort med Silo, 
24:7:15: og jeg vil kaste eder bort fra mitt åsyn, likesom jeg har bortkastet alle eders brødre, all Efra'ims ætt. 
24:7:16: Og du, du skal ikke bede for dette folk og ikke frembære klage og bønn for det og ikke trenge inn på mig; for jeg hører dig ikke. 
24:7:17: Ser du ikke hvad de gjør Judas byer og på Jerusalems gater? 
24:7:18: Barna sanker ved, og fedrene tender op ild, og kvinnene elter deig for å lage kaker for himmelens dronning, og de utøser drikkoffer for andre guder, for å volde mig sorg. 
24:7:19: Mon det er mig de volder sorg, sier Herren, mon ikke sig selv, så deres ansikter skal rødme av skam? 
24:7:20: Derfor sier Herren, Israels Gud, så: Se, min vrede og min harme blir utøst over dette sted, over menneskene og dyrene, over markens trær og jordens frukt, og den skal brenne og ikke slukkes. 
24:7:21: Så sier Herren, hærskarenes Gud, Israels Gud: Legg bare eders brennoffere til eders slaktoffere og et kjøtt! 
24:7:22: For jeg har ikke talt med eders fedre og ikke gitt dem befaling på den dag jeg førte dem ut av Egyptens land, om brennoffer og slaktoffer; 
24:7:23: men dette var det bud jeg gav dem: Hør på min røst! Så vil jeg være eders Gud, og I skal være mitt folk, og I skal vandre på alle de veier jeg byder eder, så det må gå eder vel. 
24:7:24: Men de hørte ikke og bøide ikke sitt øre til mig, de fulgte sine egne tanker, sitt onde, hårde hjerte, og de vendte ryggen, ikke ansiktet til mig. 
24:7:25: Like fra den dag eders fedre drog ut av Egyptens land, helt til denne dag sendte jeg til eder alle mine tjenere, profetene, daglig, tidlig og sent. 
24:7:26: Men de hørte ikke på mig og bøide ikke sitt øre til mig; de gjorde sin nakke hård, de bar sig verre at enn sine fedre. 
24:7:27: Og du skal tale til dem alle disse ord, men de skal ikke høre på dig, og du skal rope til dem, men de skal ikke svare dig. 
24:7:28: Og du skal si til dem: Dette er det folk som ikke har hørt på Herrens, sin Guds røst og ikke tatt imot tukt; redeligheten er gått til grunne og er utryddet av deres munn. 
24:7:29: Klipp ditt hår av* og kast det bort og stem i en klagesang på de bare hauger! For Herren har forkastet og støtt fra sig den ætt som han er vred på. # <* Jerusalem.> 
24:7:30: For Judas barn har gjort det som er ondt i mine øine, sier Herren; de har satt sine vederstyggeligheter i det hus som er kalt med mitt navn, og gjort det urent. 
24:7:31: Og de har bygget Tofet-haugene i Hinnoms sønns dal for å opbrenne sine sønner og sine døtre med ild, noget som jeg ikke har pålagt dem, og som ikke er opkommet i mitt hjerte. 
24:7:32: Se, derfor skal dager komme, sier Herren, da en ikke mere skal si Tofet eller Hinnoms sønns dal, men Drapsdalen; og de skal begrave folk i Tofet, fordi det ellers ikke er plass. 
24:7:33: Og dette folks døde kropper skal bli til føde for himmelens fugler og for jordens dyr, uten at nogen skremmer dem bort. 
24:7:34: Og jeg lar fryds røst og gledes røst, brudgoms røst og bruds røst bli borte i Judas byer og Jerusalems gater, for til en ørken skal landet bli. 
24:8:1: På den tid, sier Herren, skal de ta Judas kongers ben og dets høvdingers ben og prestenes ben og profetenes ben og Jerusalems innbyggeres ben ut av deres graver. 
24:8:2: Og de skal brede dem ut for solen og månen og hele himmelens hær, som de elsket og dyrket, som de fulgte og søkte og tilbad; de skal ikke samles og ikke begraves; til gjødsel utover jorden skal de bli. 
24:8:3: Og de skal velge å dø fremfor å leve, alle de som blir igjen av denne onde ætt, på alle de steder som jeg driver dem bort til, sier Herren, hærskarenes Gud. 
24:8:4: Og du skal si til dem: Så sier Herren: Faller nogen og står ikke op igjen? Eller går nogen bort og vender ikke tilbake igjen? 
24:8:5: Hvorfor er folket her i Jerusalem falt fra med et evig frafall? De holder fast ved sin svik og vil ikke vende tilbake. 
24:8:6: Jeg gav akt og hørte; det som ikke er rett, taler de; det er ingen som angrer sin ondskap og sier: Hvad har jeg gjort! Alle sammen har vendt sig bort i sitt løp lik en hest som styrter avsted i krigen. 
24:8:7: Endog storken under himmelen kjenner sine tider, og turtelduen og svalen og tranen passer tiden når de skal komme; men mitt folk kjenner ikke Herrens lov. 
24:8:8: Hvorledes kan I si: Vi er vise, og Herrens lov har vi hos oss? Sannelig, se, til løgn har de skriftlærdes løgnpenn gjort den. 
24:8:9: De vise blir til skamme, de blir forferdet, og ulykken rammer dem. Se, Herrens ord har de forkastet; hvor skulde de da ha visdom fra? 
24:8:10: Derfor vil jeg gi deres hustruer til andre, deres marker til nye eiermenn; for både små og store søker alle sammen urettferdig vinning, både profeter og prester gjør alle sammen svik, 
24:8:11: og de læger mitt folks skade på lettferdig vis, idet de sier: Fred! Fred! Og det er dog ingen fred. 
24:8:12: De skal bli til skamme, for vederstyggelige ting har de gjort. De hverken skammer sig eller kjenner til blygsel; derfor skal de falle blandt dem som faller; på den tid de blir hjemsøkt, skal de snuble, sier Herren. 
24:8:13: Jeg vil rykke dem bort, sier Herren; det er ingen druer på vintreet og ingen fikener på fikentreet, og bladene er visne, og jeg sender mot dem folk som kommer over dem. 
24:8:14: Hvorfor sitter vi stille? Samle eder og la oss gå inn i de faste byer og gå til grunne der! For Herren vår Gud vil la oss gå til grunne og gi oss beskt vann å drikke fordi vi har syndet mot Herren. 
24:8:15: Vi venter på fred, og det kommer intet godt, på lægedoms tid, og se, det kommer forferdelse. 
24:8:16: Fra Dan høres hans hesters fnysen, ved lyden av hans veldige gangeres vrinsken bever hele landet; og de kommer og fortærer landet og alt som i det er, byen og dem som bor i den. 
24:8:17: For se, jeg sender ormer iblandt eder, basilisker som ingen besvergelse hjelper mot, og de skal bite eder, sier Herren. 
24:8:18: Å måtte jeg få vederkvegelse i min sorg! Mitt hjerte er sykt i mig. 
24:8:19: Se, mitt folks skrik lyder fra et land langt borte: Er Herren da ikke i Sion? Eller er dets konge ikke der? - Hvorfor har de vakt min harme med sine utskårne billeder, med fremmede lands falske guder? 
24:8:20: Kornhøsten er forbi, frukthøsten er til ende, men vi er ikke frelst. 
24:8:21: Over mitt folks sønderknuselse er jeg sønderknust; jeg går i sørgeklær, forferdelse har grepet mig. 
24:8:22: Er det da ingen balsam i Gilead? Eller er det ingen læge der? Hvorfor er det ikke lagt forbinding på mitt folks datter? 
24:9:1: Gid mitt hode var vann, og mitt øie en tårekilde! Da vilde jeg dag og natt gråte over de drepte blandt mitt folk. 
24:9:2: Gid jeg i ørkenen hadde et herberge for veifarende! Da vilde jeg forlate mitt folk og gå bort fra dem; for de er alle sammen horkarler, en bande av troløse. 
24:9:3: De spenner sin tunge som en bue til løgn, og ikke på redelig vis er de blitt sterke i landet, men fra ondt til ondt er de gått frem, og mig kjenner de ikke, sier Herren. 
24:9:4: Vokt eder, hver for sin venn, og stol ikke på nogen bror! For hver bror er en listig bedrager, og hver venn går omkring og baktaler. 
24:9:5: Og de bruker svik, hver mot sin venn, og sannhet taler de ikke; de har lært op sin tunge til å tale løgn, de har trettet sig ut med å gjøre urett. 
24:9:6: Du bor midt i svik; i sin svik vil de ikke kjenne mig, sier Herren. 
24:9:7: Derfor sier Herren, hærskarenes Gud, så: Se, jeg vil smelte dem og prøve dem; for hvorledes skulde jeg gjøre annet, sådan som mitt folks datter er? 
24:9:8: Deres tunge er en drepende pil, den taler svik; med sin munn taler de vennlig med sin næste, og i sitt hjerte legger de snare for ham. 
24:9:9: Skulde jeg ikke hjemsøke dem for dette? sier Herren; skulde min sjel ikke hevne sig på et folk som dette? 
24:9:10: Over fjellene vil jeg opløfte gråt og klage og over ørkenens beitemarker en klagesang; for de er opbrent, så ikke nogen ferdes der, og det ikke høres lyd av fe; både himmelens fugler og dyrene har flyktet og draget bort. 
24:9:11: Og jeg vil gjøre Jerusalem til grusdynger, til en bolig for sjakaler, og Judas byer vil jeg gjøre til en ørken, så ingen bor i dem. 
24:9:12: Den mann som er vis, han skjønne dette, og den som Herrens munn har talt til, han forkynne det: Hvorfor er landet gått til grunne? Hvorfor er det opbrent som ørkenen, så det ikke er nogen som ferdes der? 
24:9:13: Og Herren sa: Fordi de forlot min lov, som jeg la frem for dem, og ikke hørte på min røst og ikke fulgte den*, # <* loven.> 
24:9:14: men fulgte sitt hårde hjerte, og fulgte Ba'alene, slik som deres fedre hadde lært dem, 
24:9:15: derfor sier Herren, hærskarenes Gud, Israels Gud, så: Se, jeg gir dette folk malurt å ete og beskt vann å drikke, 
24:9:16: og jeg vil sprede dem blandt hedningefolk som de ikke kjente, hverken de eller deres fedre, og jeg vil sende sverdet efter dem, til jeg får gjort ende på dem. 
24:9:17: Så sier Herren, hærskarenes Gud: Gi akt og kall på klagekvinnene at de skal komme, og send bud til de kyndige kvinner at de skal komme 
24:9:18: og skynde sig og opløfte en klagesang over oss, så våre øine kan rinne med gråt og våre øielokk flyte med vann! 
24:9:19: For en klagerøst høres fra Sion: Hvor vi er ødelagt! Vi er blitt storlig vanæret; vi har måttet forlate landet fordi de har brutt ned våre boliger. 
24:9:20: Ja, hør, I kvinner, Herrens ord, la eders øre fornemme hans munns tale, og lær eders døtre klagesang, og den ene lære den andre sørgesang! 
24:9:21: For døden stiger op i våre vinduer, kommer inn i våre palasser, den vil utrydde de små barn på gatene, de unge menn på torvene. 
24:9:22: Si: Så sier Herren: Menneskenes døde kropper skal ligge som gjødsel på marken og som kornbånd efter høstfolkene, og det er ingen som samler dem. 
24:9:23: Så sier Herren: Den vise rose sig ikke av sin visdom, og den sterke rose sig ikke av sin styrke, den rike rose sig ikke av sin rikdom! 
24:9:24: Men den som vil rose sig, han rose sig av at han er forstandig og kjenner mig, at jeg er Herren, som gjør miskunnhet, rett og rettferdighet på jorden; for i det har jeg velbehag, sier Herren. 
24:9:25: Se, dager kommer, sier Herren, da jeg vil hjemsøke alle omskårne som er uomskårne. 
24:9:26: Egypten og Juda og Edom og Ammons barn og Moab og alle med rundklippet hår som bor i ørkenen, vil jeg hjemsøke; for alle hedningefolkene er uomskårne, og hele Israels hus er uomskåret på hjertet. 
24:10:1: Hør det ord Herren har talt til eder, Israels hus! 
24:10:2: Så sier Herren: Venn eder ikke til hedningenes vei, og reddes ikke for himmelens tegn, fordi hedningene reddes for dem! 
24:10:3: For folkenes skikker er tomhet. De feller et tre i skogen, og treskjæreren lager det til med øksen; 
24:10:4: med sølv og gull pryder de det; med spiker og hammer fester de det, så det ikke skal falle. 
24:10:5: Som en dreiet søile er de ting som blir laget, og de kan ikke tale; bæres må de; for de kan ikke gå! Frykt ikke for dem! For de kan ikke gjøre ondt, og å gjøre godt står heller ikke i deres makt. 
24:10:6: Det er ingen som du, Herre! Stor er du, og stort er ditt navn ved ditt velde. 
24:10:7: Hvem skulde ikke frykte dig, du folkenes konge! Dig tilkommer det; for blandt alle folkenes vismenn og i alle deres riker er det ingen som du. 
24:10:8: Men alle sammen er de ufornuftige, de er dårer. En tom lære! Tre er det*, # <* avgudsbilledet.> 
24:10:9: uthamret sølv innført fra Tarsis og gull fra Ufas, et verk av treskjærerens og av gullsmedens hender; blått og rødt purpur er deres klædning, et verk av kunstforstandige menn er de alle sammen. 
24:10:10: Men Herren er Gud i sannhet, han er en levende Gud og en evig konge; for hans vrede bever jorden, og hedningefolkene kan ikke utholde hans harme. 
24:10:11: Således skal I si til dem*: De guder som ikke har gjort himmelen og jorden, de skal bli borte fra jorden og ikke finnes under himmelen. # <* hedningefolkene.> 
24:10:12: Han er den som skapte jorden ved sin kraft, som grunnfestet jorderike ved sin visdom og spente ut himmelen ved sin forstand. 
24:10:13: Ved sin torden lar han vannene i himmelen bruse, han lar dunster stige op fra jordens ende, sender lyn med regn og fører vind ut av sine forrådskammer. 
24:10:14: Hvert menneske blir ufornuftig, uten forstand, hver gullsmed har skam av det utskårne billede; for hans støpte billeder er løgn, og det er ingen ånd i dem. 
24:10:15: De er tomhet, et verk som vekker spott; på sin hjemsøkelses tid skal de gå til grunne. 
24:10:16: Ikke er han som er Jakobs del*, lik dem; for han er den som har skapt alle ting, og Israel er den ætt som er hans arv; Herren, hærskarenes Gud, er hans navn. # <* d.e. Herren; 5MO 4, 19. 20; 32, 9. SLM 16, 5; 119, 57.> 
24:10:17: Sank ditt gods sammen fra landet, du* som bor i kringsatte byer! # <* d.e. du folk.> 
24:10:18: For så sier Herren: Se, jeg vil slynge landets innbyggere bort denne gang, og jeg vil trenge dem så de skal kjenne det. 
24:10:19: Ve mig for et slag jeg har fått! Mitt sår er ulægelig! Men jeg sier: Ja, dette er en plage, og jeg må bære den. 
24:10:20: Mitt telt er ødelagt, og alle mine snorer er slitt av; mine barn har gått bort fra mig og er ikke mere; det er ingen som slår op mitt telt mere eller henger op mine tepper. 
24:10:21: For hyrdene var uforstandige og søkte ikke Herren; derfor fór de ikke vist frem, og hele deres hjord blev adspredt. 
24:10:22: Det lyder et budskap! Se, det kommer, og stort bulder fra landet i nord, og Judas byer skal gjøres til en ørken, til en bolig for sjakaler. 
24:10:23: Jeg vet, Herre, at et menneske ikke selv råder for sin vei, at det ikke står til vandringsmannen å styre sin gang. 
24:10:24: Tukt mig, Herre, men med måte, ikke i din vrede, forat du ikke skal gjøre mig liten og arm! 
24:10:25: Utøs din harme over hedningene, som ikke kjenner dig, og over de ætter som ikke påkaller ditt navn! For de har fortært Jakob, fortært ham og gjort ende på ham, og hans bolig har de lagt øde. 
24:11:1: Dette er det ord som kom til Jeremias fra Herren: 
24:11:2: Hør denne pakts ord og tal til Judas menn og til Jerusalems innbyggere! 
24:11:3: Og du skal si til dem: Så sier Herren, Israels Gud: Forbannet være den mann som ikke hører denne pakts ord, 
24:11:4: den som jeg bød eders fedre den dag jeg førte dem ut av Egyptens land, av jernovnen, da jeg sa: Hør på min røst og gjør disse ting i ett og alt således som jeg byder eder, så skal I være mitt folk, og jeg vil være eders Gud, 
24:11:5: så jeg kan holde den ed jeg svor eders fedre, at jeg vilde gi dem et land som flyter med melk og honning, således som det kan sees på denne dag. Og jeg svarte: Amen, Herre! 
24:11:6: Og Herren sa til mig: Rop ut alle disse ord i Judas byer og på Jerusalems gater og si: Hør denne pakts ord og gjør efter dem! 
24:11:7: For jeg vidnet for eders fedre den dag jeg førte dem op fra Egyptens land, og helt til denne dag, tidlig og sent, og sa: Hør på min røst! 
24:11:8: Men de hørte ikke og vendte ikke sitt øre til; de fulgte hver sitt onde, hårde hjerte; så lot jeg komme over dem alle denne pakts ord, som jeg hadde befalt dem å holde, men som de ikke hadde holdt. 
24:11:9: Og Herren sa til mig: Det er funnet en sammensvergelse blandt Judas menn og blandt Jerusalems innbyggere. 
24:11:10: De har vendt tilbake til sine forfedres misgjerninger, de som ikke vilde høre mine ord, og de har fulgt andre guder og dyrket dem; Israels hus og Judas hus har brutt den pakt som jeg gjorde med deres fedre. 
24:11:11: Derfor sier Herren så: Se, jeg fører en ulykke over dem, som de ikke kan komme unda, og når de roper til mig, vil jeg ikke høre på dem. 
24:11:12: Og Judas byer og Jerusalems innbyggere skal gå avsted og rope til de guder som de brenner røkelse for; men de skal ikke frelse dem i deres ulykkes tid. 
24:11:13: For så mange som dine byer er, så mange er dine guder, Juda, og så mange som Jerusalems gater er, så mange alter har I reist for den skammelige avgud, alter til å brenne røkelse på for Ba'al. 
24:11:14: Og du skal ikke bede for dette folk og ikke opløfte klagerop og bønn for dem; for jeg hører ikke når de roper til mig for sin ulykkes skyld. 
24:11:15: Hvad har min elskede å gjøre i mitt hus? Vil de mange gjøre skammelige gjerninger og mene at det hellige offerkjøtt skal ta det bort fra dig? Når du gjør ondt, da jubler du. 
24:11:16: Et grønt oljetre prydet med fager frukt kalte Herren dig; under stort og veldig bulder tender han ild på det, og dets grener brytes itu. 
24:11:17: Og Herren, hærskarenes Gud, som plantet dig, har talt ondt over dig for den ondskaps skyld som Israels hus og Judas hus har gjort, så de har vakt min harme ved å brenne røkelse for Ba'al. 
24:11:18: Herren lot mig få vite det*, og jeg vet det; da lot du mig se deres gjerninger. # <* mine fienders onde råd imot mig; JER 11, 19.> 
24:11:19: Og jeg var som et spakferdig lam som føres bort for å slaktes, og jeg visste ikke at de hadde uttenkt onde råd mot mig og sagt: La oss ødelegge treet med dets frukt og utrydde ham av de levendes land, så ingen mere kommer hans navn i hu! 
24:11:20: Men Herren, hærskarenes Gud, er en rettferdig dommer, han prøver nyrer og hjerte; jeg skal se din hevn over dem; for jeg har lagt min sak frem for dig. 
24:11:21: Derfor sier Herren så om Anatots menn, som står dig efter livet og sier: Du skal ikke profetere i Herrens navn, forat du ikke skal dø for vår hånd, 
24:11:22: - derfor sier Herren, hærskarenes Gud, så: Se, jeg vil hjemsøke dem; deres unge menn skal dø ved sverd, deres sønner og døtre skal dø av sult, 
24:11:23: og de skal ikke ha noget tilbake; for jeg fører ulykke over Anatots menn i det år de blir hjemsøkt. 
24:12:1: Herre, når jeg fører trette med dig, har du alltid rett. Allikevel må jeg gå i rette med dig: Hvorfor går det de ugudelige vel, hvorfor har de ingen sorger alle de troløse? 
24:12:2: Du har plantet dem, og de har slått rot; de vokser op og bærer frukt; nær er du i deres munn, men langt borte fra deres nyrer. 
24:12:3: Men du, Herre, du kjenner mig, du ser mig og prøver mitt hjertelag mot dig; ta dem bort som får til å slaktes og innvi dem til en drapsdag! 
24:12:4: Hvor lenge skal landet visne og all markens urter tørke bort? For dets innbyggeres ondskap er dyr og fugler revet bort; for de sier: Han* ser ikke hvorledes det vil gå oss til sist. # <* profeten.> 
24:12:5: Når du løper med fotgjengere, og de gjør dig trett, hvorledes vil du løpe om kapp med hester? I et fredelig land er du trygg, men hvad vil du gjøre i Jordans prakt*? # <* d.e. den prektige skog på Jordans bredder, hvor løver hadde tilhold; JER 49, 19; 50, 44. SKR 11, 3.> 
24:12:6: For selv dine brødre og din fars hus, selv de er troløse mot dig, selv de skriker efter dig av full hals; tro dem ikke når de taler fagre ord til dig! 
24:12:7: Jeg har forlatt mitt hus, forkastet min arv; jeg har gitt min sjels elskede i hennes fienders hånd. 
24:12:8: Min arv er blitt mot mig som en løve i skogen, den har opløftet sin røst mot mig; derfor hater jeg den. 
24:12:9: Er min arv en spraglet rovfugl? Det er jo rovfugler rundt omkring den! Gå avsted og samle alle markens dyr, la dem komme hit og ete! 
24:12:10: Hyrder* i mengde ødelegger min vingård, trår ned min arvelodd; de gjør min herlige arvelodd til en øde ørken. # <* JER 6, 3.> 
24:12:11: De gjør den til en ødemark; sørgende og øde ligger den omkring mig; ødelagt er hele landet; for det er ikke nogen som legger det* på hjerte. # <* Herrens ord.> 
24:12:12: Ødeleggere kommer over alle bare hauger i ørkenen, for Herren har et sverd som fortærer fra den ene ende av landet til den andre; det er ikke redning for noget kjød. 
24:12:13: De sår hvete, men høster torner; de tretter sig ut, men har intet gagn av det. Så ha da skam av eders grøde for Herrens brennende vredes skyld! 
24:12:14: Så sier Herren om alle sine onde naboer, som forgriper sig på den arv han har gitt sitt folk Israel til eie: Se, jeg rykker dem op av deres land, og Judas hus vil jeg rykke op av deres midte. 
24:12:15: Men når jeg har rykket dem op, da vil jeg atter forbarme mig over dem og la dem vende tilbake, hver til sin arv og hver til sitt land. 
24:12:16: Og såfremt de lærer mitt folks veier, lærer å sverge ved mitt navn: Så sant Herren lever - likesom de har lært mitt folk å sverge ved Ba'al, da skal de bli opbygget midt iblandt mitt folk. 
24:12:17: Men vil de ikke høre, da vil jeg rykke det folk op og tilintetgjøre det, sier Herren. 
24:13:1: Så sa Herren til mig: Gå og kjøp dig et lerretsbelte og legg det om dine lender, men la det ikke komme i vann! 
24:13:2: Og jeg kjøpte beltet efter Herrens ord og la det om mine lender. 
24:13:3: Og Herrens ord kom til mig annen gang, og det lød så: 
24:13:4: Ta det belte du kjøpte, det som er om dine lender, og stå op, gå til Eufrat og skjul det der i en bergkløft! 
24:13:5: Og jeg gikk og skjulte det ved Eufrat, som Herren hadde befalt mig. 
24:13:6: Og lang tid efter sa Herren til mig: Stå op, gå til Eufrat og hent derfra det belte som jeg bød dig å skjule der. 
24:13:7: Og jeg gikk til Eufrat og gravde og tok beltet fra det sted hvor jeg hadde skjult det; og se, beltet var ødelagt, det dudde ikke til noget. 
24:13:8: Da kom Herrens ord til mig: 
24:13:9: Så sier Herren: Således vil jeg gjøre ende på Judas og Jerusalems store overmot. 
24:13:10: Dette onde folk, som ikke vil høre på mine ord, som følger sitt hårde hjerte og går efter andre guder for å dyrke og tilbede dem, det skal bli lik dette belte som ikke duer til noget! 
24:13:11: For likesom beltet fester sig til en manns lender, således festet jeg hele Israels hus og hele Judas hus til mig, sier Herren, forat det skulde være mitt folk og være mig til navnkundighet og til pris og til pryd; men de hørte ikke. 
24:13:12: Og du skal si til dem dette ord: Så sier Herren, Israels Gud: Hver vinkrukke blir fylt med vin. Og de skal si til dig: Vet vi da ikke at hver vinkrukke blir fylt med vin? 
24:13:13: Og da skal du si til dem: Så sier Herren: Se, jeg fyller alle dette lands innbyggere og kongene som sitter på Davids trone, og prestene og profetene og alle Jerusalems innbyggere, så de blir drukne. 
24:13:14: Og jeg vil knuse dem, den ene mot den andre, både fedrene og barna alle sammen, sier Herren; jeg vil ikke skåne og ikke spare og ikke forbarme mig, så jeg lar være å ødelegge dem. 
24:13:15: Hør og gi akt, vær ikke overmodige! For Herren taler. 
24:13:16: Gi Herren eders Gud ære, før han lar det bli mørkt, og før eders føtter støter sig på de mørke fjell! I venter på schmelk, og han gjør det til dødsskygge, lar det bli til belgmørke! 
24:13:17: Men vil I ikke høre på dette, da skal min sjel gråte i lønndom over slikt overmot, og mitt øie skal gråte så tårene triller, fordi Herrens hjord blir bortført i fangenskap. 
24:13:18: Si til kongen og til kongens mor: Sett eder ned i det lave! For eders prektige krone er falt av eders hode. 
24:13:19: Sydlandets byer er lukket, og det er ingen som lukker op; hele Juda er bortført, det er bortført, til siste mann. 
24:13:20: Løft eders øine og se dem som kommer fra Norden! Hvor er nu den hjord som var gitt dig*, dine herlige får? # <* Jerusalem.> 
24:13:21: Hvad vil du si når han setter fortrolige venner til høvdinger over dig, dem som du selv har oplært til det? Skulde ikke veer gripe dig, som en fødende kvinne? 
24:13:22: Og når du sier i ditt hjerte: Hvorfor har dette hendt mig? [så vit:] For dine mange misgjerningers skyld er kanten på din kjole løftet op, dine hæler blottet med vold. 
24:13:23: Kan en morian omskifte sin hud, eller en leopard sine flekker? - Da kan også I gjøre godt, I som er vant til å gjøre ondt. 
24:13:24: Så vil jeg da adsprede dem likesom strå som farer avsted for ørkenens vind. 
24:13:25: Dette er din lodd, din tilmålte del fra mig, sier Herren, fordi du har glemt mig og satt din lit til løgn. 
24:13:26: Så vil da også jeg løfte kanten på din kjole op over ditt ansikt, så din skam blir sett. 
24:13:27: Ditt horelevnet og din vrinsken, din skammelige utukt på haugene ute på marken, ja dine vederstyggeligheter har jeg sett. Ve dig, Jerusalem! Hvor lenge vil det ikke ennu vare før du blir ren! 
24:14:1: Dette er Herrens ord som kom til Jeremias den gang da tørken var: 
24:14:2: Juda sørger, og dets byer vansmekter; de sitter i sørgeklær på jorden, og Jerusalems klageskrik stiger op. 
24:14:3: De fornemme blandt dem sender sine tjenere efter vann; de kommer til brønnene, men finner intet vann; deres kar vender tomme tilbake; de blir skuffet og nedslått og tilhyller sitt hode. 
24:14:4: For jordens skyld, som er forferdet fordi det ikke kommer regn i landet, er jordbrukerne skuffet, de tilhyller sitt hode. 
24:14:5: For endog hinden på marken forlater sine nyfødte unger fordi det ikke er gress, 
24:14:6: og villesler står på bare hauger, de snapper efter været som sjakaler, deres øine vansmekter fordi det ikke er urter. 
24:14:7: Om våre misgjerninger vidner mot oss, Herre, så vis oss dog nåde for ditt navns skyld! For mange er våre overtredelser, mot dig har vi syndet. 
24:14:8: Du Israels håp, dets frelser i nødens tid! Hvorfor vil du være som en fremmed i landet, lik en veifarende mann som tar av fra veien bare for å overnatte? 
24:14:9: Hvorfor vil du være som en skrekkslagen mann, lik en kjempe som ikke makter å hjelpe? Og enda bor du midt iblandt oss, Herre, og vi er kalt med ditt navn; la oss ikke fare! 
24:14:10: Så sier Herren om dette folk: Således vil de gjerne vanke omkring; sine føtter holder de ikke tilbake, og Herren har ikke behag i dem; nu vil han komme deres misgjerning i hu og hjemsøke dem for deres synder. 
24:14:11: Og Herren sa til mig: Du skal ikke bede om noget godt for dette folk. 
24:14:12: Når de faster, hører jeg ikke på deres rop, og når de ofrer brennoffer og matoffer, har jeg ikke behag i dem; men ved sverd og ved hungersnød og ved pest vil jeg gjøre ende på dem. 
24:14:13: Da sa jeg: Akk, Herre, Herre! Profetene sier jo til dem: I skal ikke se sverd, og hungersnød skal ikke ramme eder, men sikker fred vil jeg gi eder på dette sted. 
24:14:14: Men Herren sa til mig: Løgn profeterer profetene i mitt navn; jeg har ikke sendt dem og ikke gitt dem befaling og ikke talt til dem; løgnsyner og sannsigeri og tom tale og sitt hjertes svik profeterer de for eder. 
24:14:15: Derfor sier Herren så om de profeter som taler i hans navn uten at han har sendt dem, og som sier at sverd og hungersnød ikke skal komme i dette land: Ved sverd og ved hungersnød skal de utryddes disse profeter. 
24:14:16: Og folket som de profeterer for, skal ligge slengt omkring på Jerusalems gater for hunger og sverd, uten at det er nogen til å begrave dem, de selv, deres hustruer, deres sønner og deres døtre; og jeg vil utøse deres ondskap over dem. 
24:14:17: Og du skal si dette ord til dem: Mine øine feller tårer natt og dag og holder ikke op; for jomfruen, mitt folks datter, har fått et svært slag, et ulægelig sår. 
24:14:18: Går jeg ut på marken, se, der ligger folk som er slått ihjel ved sverd, og går jeg inn i byen, se, der ligger de som pines av hunger; for både profet og prest må dra til et land som de ikke kjenner. 
24:14:19: Har du da aldeles forkastet Juda, eller vemmes din sjel ved Sion? Hvorfor har du slått oss, så det ikke er nogen lægedom for oss? Vi venter på fred, og det kommer intet godt, på lægedoms tid, og se, det kommer forferdelse. 
24:14:20: Vi kjenner, Herre, vår ugudelighet, våre fedres misgjerning, at vi har syndet mot dig. 
24:14:21: For ditt navns skyld, forkast oss ikke, overgi ikke din herlighets trone til vanære! Kom i hu og bryt ikke din pakt med oss! 
24:14:22: Er det vel iblandt hedningefolkenes falske guder nogen som gir regn? Eller er det himmelen som gir regnbyger? Er det ikke du, Herre vår Gud? Vi venter på dig; for du har gjort alle disse ting. 
24:15:1: Og Herren sa til mig: Om så Moses og Samuel stod for mitt åsyn, skulde min sjel dog ikke vende sig til dette folk. Driv dem bort fra mitt åsyn, la dem fare! 
24:15:2: Og når de sier til dig: Hvor skal vi gå hen? - da skal du svare dem: Så sier Herren: Den som hører døden* til, til døden, og den som hører sverdet til, til sverdet, og den som hører hungeren til, til hungeren, og den som hører fangenskapet til, til fangenskapet. # <* d.e. pesten.> 
24:15:3: Og jeg vil hjemsøke dem med fire slags ting, sier Herren: sverdet til å drepe dem og hundene til å slepe dem bort og himmelens fugler og jordens dyr til å ete og ødelegge dem. 
24:15:4: Og jeg vil la dem bli mishandlet av alle jordens riker for det som Judas konge Manasse, Esekias' sønn, gjorde i Jerusalem. 
24:15:5: For hvem vil ynkes over dig, Jerusalem, og hvem vil ha medlidenhet med dig, og hvem vil komme til dig og spørre om det går dig vel? 
24:15:6: Du har forlatt mig, sier Herren, du gikk bort fra mig; så rekker jeg da min hånd ut mot dig og ødelegger dig; jeg er trett av å ynkes. 
24:15:7: Og jeg kaster dem med kasteskovl ut igjennem landets porter, jeg gjør mitt folk barnløst, jeg ødelegger det, fordi det ikke vendte om fra sine veier. 
24:15:8: Deres enker blir tallrikere enn havets sand; over mødrene til deres unge menn lar jeg en ødelegger komme ved høilys dag; jeg lar angst og forferdelse falle brått over dem. 
24:15:9: Selv den mor som har født syv sønner, vansmekter og utånder sin sjel; hennes sol går ned mens det ennu er dag; hun blir til spott og skam. Og resten av dem vil jeg overgi til deres fienders sverd, sier Herren. 
24:15:10: Ve mig, min mor, at du fødte mig, mig som alle i landet strider og tretter med! Jeg har ikke lånt dem noget, og de har ikke lånt mig noget, og enda forbanner hver mann mig. 
24:15:11: Herren sa: Visselig, jeg vil fri dig ut, så det går dig vel; visselig, jeg vil la dine fiender bønnfalle dig i ulykkens og nødens tid. 
24:15:12: Kan vel jern brytes, jern fra Norden, og kobber? 
24:15:13: Ditt gods og dine skatter vil jeg overgi til plyndring - uten betaling, og det for alle dine synders skyld, i hele ditt land. 
24:15:14: Så vil jeg la dine fiender dra over* til et land som du ikke kjenner; for en ild er optendt i min vrede, mot eder skal den brenne. # <* med dine skatter. 5MO 32, 22.> 
24:15:15: Du vet det, Herre! Kom mig i hu og se til mig og la mig få hevn over mine forfølgere! Rykk mig ikke bort i din langmodighet mot dem! Tenk på at jeg blir hånet for din skyld! 
24:15:16: Jeg fant dine ord, og jeg åt dem, og dine ord var til fryd for mig og til glede for mitt hjerte; for jeg er kalt med ditt navn, Herre, hærskarenes Gud! 
24:15:17: Jeg har ikke sittet i de lystiges lag og jublet; grepet av din hånd har jeg sittet ensom; for du fylte mig med harme. 
24:15:18: Hvorfor er min smerte evig, og mitt sår ulægelig? Det vil ikke la sig læge. Du er jo blitt for mig som en sviktende bekk, som vann en ikke kan lite på. 
24:15:19: Derfor sier Herren så: Hvis du vender om, så vil jeg la dig komme tilbake og stå for mitt åsyn, og hvis du skiller det edle ut fra det uedle, skal du være som min munn; de skal igjen vende om til dig, men du skal ikke vende om til dem. 
24:15:20: Og jeg vil gjøre dig til en fast kobbermur mot dette folk; de skal stride mot dig, men ikke få overhånd over dig; for jeg er med dig og vil frelse dig og redde dig, sier Herren. 
24:15:21: Jeg vil redde dig av de ondes hånd, og jeg vil løse dig ut av voldsmenns hånd. 
24:16:1: Og Herrens ord kom til mig, og det lød så: 
24:16:2: Du skal ikke ta dig en hustru, og du skal ikke ha sønner og døtre på dette sted. 
24:16:3: For så sier Herren om de sønner og døtre som fødes på dette sted, og om deres mødre som føder dem, og om deres fedre som avler dem i dette land: 
24:16:4: En smertefull død skal de dø, ingen skal gråte over dem, og ingen begrave dem; til gjødsel på marken skal de bli, og ved sverd og hungersnød skal de omkomme, og deres lik skal bli til føde for himmelens fugler og for jordens dyr. 
24:16:5: For så sier Herren: Gå ikke inn i sørgehus, og gå ikke avsted for å holde klage over en død, og vis dem ikke medynk! For jeg har tatt min fred bort fra dette folk, sier Herren, min miskunnhet og barmhjertighet. 
24:16:6: Og de skal dø, både store og små, i dette land; de skal ikke begraves, og ingen skal holde klage over dem eller skjære i sitt kjøtt eller rake håret av for deres skyld. 
24:16:7: Og ingen skal bryte brød til dem for å trøste dem i sorgen over en avdød, og ingen skal gi nogen trøstens beger å drikke når han har mistet far eller mor. 
24:16:8: Og i gjestebudshus skal du heller ikke gå inn for å sitte hos dem og ete og drikke. 
24:16:9: For så sier Herren, hærskarenes Gud, Israels Gud: Se, for eders øine og i eders dager lar jeg fryds røst og gledes røst, brudgoms røst og bruds røst bli borte på dette sted. 
24:16:10: Når du da forkynner dette folk alle disse ord, og de sier til dig: Hvorfor har Herren varslet all denne store ulykke over oss, hvad er vår misgjerning, hvad er vår synd som vi har gjort mot Herren vår Gud? 
24:16:11: da skal du si til dem: Fordi eders fedre forlot mig, sier Herren, og fulgte andre guder og dyrket dem og tilbad dem, men forlot mig og ikke holdt min lov, 
24:16:12: og I har gjort verre synd enn eders fedre, I som alle følger eders onde, hårde hjerte uten å høre på mig, 
24:16:13: derfor vil jeg kaste eder ut av dette land, bort til et land I ikke har kjent, hverken I eller eders fedre, og der skal I dyrke andre guder dag og natt, fordi jeg ikke vil gi eder nåde. 
24:16:14: Se, derfor skal dager komme, sier Herren, da det ikke mere skal sies: Så sant Herren lever, han som førte Israels barn op fra Egyptens land, 
24:16:15: men: Så sant Herren lever, som førte Israels barn op fra landet i nord og fra alle de land som han hadde drevet dem bort til. Og jeg vil føre dem tilbake til deres land, det som jeg gav deres fedre. 
24:16:16: Se, jeg sender bud efter mange fiskere, sier Herren, og de skal fiske dem, og derefter sender jeg bud efter mange jegere, og de skal jage dem bort fra hvert fjell og fra hver haug og fra bergkløftene. 
24:16:17: For mine øine er rettet på alle deres veier; de er ikke skjult for mitt åsyn, og deres misgjerning er ikke dulgt for mine øine. 
24:16:18: Først vil jeg da gjengjelde deres misgjerning og deres synd dobbelt, fordi de vanhelliget mitt land med sine vederstyggelige avguders døde kropper og fylte min arvedel med sine avskyeligheter. 
24:16:19: Herre, min styrke og mitt sterke vern og min tilflukt på nødens dag! Til dig skal hedningefolk komme fra jordens ender og si: Bare løgn fikk våre fedre i arv, falske guder, og det var ingen iblandt dem som kunde hjelpe. 
24:16:20: Skal et menneske gjøre sig guder? De er jo ikke guder. 
24:16:21: Se, derfor lar jeg dem denne gang kjenne, ja, jeg lar dem kjenne min hånd og min styrke, og de skal vite at mitt navn er Herren. 
24:17:1: Judas synd er skrevet med en jerngriffel, med en diamantspiss; den er inngravet på deres hjertes tavle og på deres alteres horn, 
24:17:2: likesom også deres barn kommer deres altere og deres Astarte-billeder i hu ved de grønne trær og på de høie bakker. 
24:17:3: Du mitt berg på marken*! Ditt gods, alle dine skatter vil jeg overgi til plyndring, likeså dine hauger, til straff for den synd som er gjort i hele ditt land. # <* d.e. Sion; JER 21, 13; 15, 13 fg.> 
24:17:4: Og du skal, og det ved din egen skyld, la din arv som jeg gav dig, hvile efter dig*, og jeg vil la dig tjene dine fiender i et land du ikke kjenner; for en ild har I optendt i min vrede, den skal brenne til evig tid. # <* 3MO 26, 33 fg. JER 5, 19. 5MO 32, 22.> 
24:17:5: Så sier Herren: Forbannet er den mann som setter sin lit til mennesker og holder kjød for sin arm, og hvis hjerte viker fra Herren. 
24:17:6: Han skal bli som en hjelpeløs mann på den øde mark og ikke få se at det kommer noget godt; men han skal bo på avsvidde steder i ørkenen, i et saltland som ingen bor i. 
24:17:7: Velsignet er den mann som stoler på Herren, og hvis tillit Herren er. 
24:17:8: Han skal bli lik et tre som er plantet ved vann og skyter sine røtter ut ved en bekk, og som ikke frykter når heten kommer, men alltid har grønne blad, og som ikke sørger i tørre år og ikke holder op å bære frukt. 
24:17:9: Svikefullt er hjertet, mere enn noget annet, og ondt er det; hvem kjenner det? 
24:17:10: Jeg, Herren, ransaker hjerter og prøver nyrer og gir enhver efter hans ferd, efter frukten av hans gjerninger. 
24:17:11: Lik en rapphøne som samler unger den ikke har utklekket, er den som vinner rikdom, men ikke med rett; midt i sine dager skal han forlate den, og til sist skal han være en dåre. 
24:17:12: En herlighetens trone, høit ophøiet fra begynnelsen av, er vår helligdoms sted. 
24:17:13: Herre, du Israels håp! Alle de som forlater dig, skal bli til skamme; de som viker fra dig, skal skrives i støvet; for de har forlatt kilden med det levende vann, Herren. 
24:17:14: Læg mig, Herre, så blir jeg lægt! Frels mig, så blir jeg frelst! For du er min lovsang. 
24:17:15: Se, de sier til mig: Hvor blir det av Herrens ord? La det komme! 
24:17:16: Men jeg har ikke nektet å følge dig og være hyrde, heller ikke har jeg ønsket en ubotelig ulykkes dag*, du vet det; - det som kom fra mine leber, det kjente du. # <* over dem; JER 18, 20.> 
24:17:17: Vær ikke til forferdelse for mig! Du er min tilflukt på ulykkens dag. 
24:17:18: La mine forfølgere bli til skamme, og la ikke mig bli til skamme! La dem forferdes, og la ikke mig forferdes! La ulykkens dag komme over dem, og knus dem med dobbelt knusende slag! 
24:17:19: Så sa Herren til mig: Gå og still dig i folkets port, som Judas konger går inn og ut gjennem, og i alle Jerusalems porter! 
24:17:20: Og du skal si til dem: Hør Herrens ord, Judas konger og hele Juda og alle Jerusalems innbyggere, I som går inn gjennem disse porter! 
24:17:21: Så sier Herren: Ta eder i vare så sant I har eders liv kjært, og bær ikke nogen byrde og før ikke nogen byrde inn gjennem Jerusalems porter på sabbatens dag! 
24:17:22: Og I skal ikke bære nogen byrde ut av eders hus og ikke gjøre nogen gjerning på sabbatens dag; men I skal holde sabbatens dag hellig, som jeg bød eders fedre. 
24:17:23: Men de hørte ikke og vendte ikke sitt øre til; de gjorde sin nakke hård, så de ikke hørte og ikke tok imot tukt. 
24:17:24: Men hvis I hører på mig, sier Herren, så I ikke lar nogen byrde komme gjennem denne bys porter på sabbatens dag, men holder sabbatens dag hellig og ikke gjør nogen gjerning på den dag, 
24:17:25: da skal konger og fyrster som sitter på Davids trone, dra inn gjennem denne bys porter på vogner og hester, de selv og deres fyrster, Judas menn og Jerusalems innbyggere, og denne by skal bli stående til evig tid, 
24:17:26: og fra Judas byer og fra landet omkring Jerusalem og fra Benjamins land og fra lavlandet og fra fjellbygdene og fra sydlandet skal de komme og bære frem brennoffer og slaktoffer og matoffer og virak, og bære frem takkoffer til Herrens hus. 
24:17:27: Men dersom I ikke hører på mig, så I holder sabbatens dag hellig og ikke bærer nogen byrde inn gjennem Jerusalems porter på sabbatens dag, da vil jeg tende ild på dets porter, og den skal fortære Jerusalems palasser og ikke slukkes. 
24:18:1: Dette er det ord som kom til Jeremias fra Herren: 
24:18:2: Stå op og gå ned til pottemakerens hus, og der vil jeg la dig høre mine ord. 
24:18:3: Og jeg gikk ned til pottemakerens hus, og se, han gjorde sitt arbeid på skiven; 
24:18:4: og når det kar han gjorde, blev mislykket, som det kan gå med leret i pottemakerens hånd, så gjorde han det om igjen til et annet kar, slik som han vilde ha det. 
24:18:5: Og Herrens ord kom til mig, og det lød så: 
24:18:6: Skulde jeg ikke kunne gjøre med eder, Israels hus, som denne pottemaker? sier Herren; se, som leret er i pottemakerens hånd, således er I i min hånd, Israels hus! 
24:18:7: En gang taler jeg om et folk og et rike, at jeg vil rykke op og rive ned og ødelegge; 
24:18:8: men dersom det folk som jeg har talt om, vender om fra sin ondskap, da angrer jeg det onde som jeg hadde tenkt å gjøre mot det. 
24:18:9: Og en annen gang taler jeg om et folk og om et rike, at jeg vil bygge og plante; 
24:18:10: men gjør det da det som er ondt i mine øine, så det ikke hører på min røst, da angrer jeg det gode som jeg hadde tenkt å ville gjøre mot det. 
24:18:11: Og si nu til Judas menn og til Jerusalems innbyggere: Så sier Herren: Se, jeg emner på en ulykke for eder og uttenker et ondt råd mot eder; vend om, hver fra sin onde vei, og bedre eders veier og eders gjerninger! 
24:18:12: Men de sier: Du taler forgjeves! Våre egne tanker vil vi følge, og enhver av oss vil følge sitt onde, hårde hjerte. 
24:18:13: Derfor sier Herren så: Spør blandt hedningefolkene: Hvem har hørt slikt? Grufulle ting har jomfruen Israel gjort. 
24:18:14: Mon Libanons sne går bort på det høie fjell? Eller uttørkes de fremmede, kjølige, rinnende vann? 
24:18:15: Men mitt folk har glemt mig, det brenner røkelse for de falske guder; og de førte dem til fall på deres veier, de gamle veier, så de gikk på stier, på uryddede veier, 
24:18:16: og således gjorde de sitt land til en forferdelse, til en evig spott; hver den som går forbi, skal forferdes og ryste på hodet. 
24:18:17: Som østenvinden vil jeg adsprede dem for fiendens åsyn; med nakken og ikke med ansiktet vil jeg se på dem på deres ulykkes dag. 
24:18:18: Da sa de: Kom og la oss uttenke onde råd mot Jeremias! For presten har jo loven, den vise har råd, og profeten har ord fra Gud. Kom, la oss slå ham med tungen og ikke akte på noget av hans ord! 
24:18:19: Akt på mig, Herre, og hør hvorledes de strider mot mig! 
24:18:20: Skal en gjengjelde godt med ondt? For de har gravd en grav for mitt liv. Kom i hu at jeg stod for ditt åsyn og talte godt for dem for å vende din vrede bort fra dem! 
24:18:21: Gi derfor deres barn til hungeren, og overgi dem selv i sverdets vold, og la deres hustruer bli barnløse og enker, og deres menn bli drept av pesten, deres ungdom bli slått ihjel av sverdet i krigen! 
24:18:22: La skrik bli hørt fra deres hus, når du lar en fiendeskare komme brått over dem! For de har gravd en grav for å fange mig og lagt skjulte snarer for mine føtter. 
24:18:23: Men du, Herre, kjenner alle deres onde råd mot mitt liv; forlat dem ikke deres misgjerning og utslett ikke deres synd for ditt åsyn, men styrt dem ned for ditt åsyn! Straff dem på din vredes tid! 
24:19:1: Så sa Herren: Gå og kjøp en pottemakerkrukke, og ta med dig nogen av folkets eldste og av prestenes eldste, 
24:19:2: og gå ut til Hinnoms sønns dal, som er utenfor Pottemaker-porten, og rop der ut de ord jeg vil tale til dig! 
24:19:3: Og du skal si: Hør Herrens ord, I Judas konger og Jerusalems innbyggere! Så sier Herren, hærskarenes Gud, Israels Gud: Se, jeg fører ulykke over dette sted, så det skal ringe for ørene på hver den som hører om det, 
24:19:4: fordi de forlot mig og ikke aktet dette sted hellig og brente røkelse der for andre guder, som de ikke kjente, hverken de eller deres fedre eller Judas konger, og fylte dette sted med uskyldiges blod 
24:19:5: og bygget offerhauger for Ba'al for å brenne sine sønner i ilden til brennoffer for Ba'al, noget som jeg ikke har påbudt og ikke talt om, og som ikke er opkommet i mitt hjerte. 
24:19:6: Se, derfor skal dager komme, sier Herren, da en ikke mere skal kalle dette sted Tofet eller Hinnoms sønns dal, men Drapsdalen. 
24:19:7: Og på dette sted vil jeg gjøre Judas og Jerusalems råd til intet, og jeg vil la dem falle for deres fienders sverd og for de menns hånd som står dem efter livet; og jeg vil gi deres døde kropper til føde for himmelens fugler og for jordens dyr. 
24:19:8: Og jeg vil gjøre denne by til en forferdelse og en spott; hver den som går forbi, skal forferdes og spotte over alle dens plager. 
24:19:9: Og jeg vil la dem ete sine sønners og døtres kjøtt, og de skal ete hverandres kjøtt, under den trengsel og angst som deres fiender og de som står dem efter livet, fører over dem. 
24:19:10: Og du skal knuse krukken for de menns øine som går med dig, 
24:19:11: og du skal si til dem: Så sier Herren, hærskarenes Gud: Således vil jeg knuse dette folk og denne by, som en knuser et pottemakerkar som ikke kan gjøres i stand igjen; og i Tofet skal de begrave folk fordi det ellers ikke er plass til å begrave. 
24:19:12: Således vil jeg gjøre med dette sted, sier Herren, og med dets innbyggere; jeg vil gjøre denne by lik Tofet. 
24:19:13: Og husene i Jerusalem og Judas kongers hus, de urene, skal bli som Tofet, alle de hus på hvis tak de har brent røkelse for hele himmelens hær og utøst drikkoffer for andre guder. 
24:19:14: Og Jeremias kom fra Tofet, dit Herren hadde sendt ham for å profetere, og han stod frem i forgården til Herrens hus og sa til alt folket: 
24:19:15: Så sier Herren, hærskarenes Gud, Israels Gud: Se, jeg lar komme over denne by og over alle de byer som hører til den, alt det onde jeg har talt imot den; for de har gjort sin nakke hård, så de ikke hører på mine ord. 
24:20:1: Og presten Pashur, Immers sønn, som var overtilsynsmann i Herrens hus, hørte Jeremias tale disse ord. 
24:20:2: Og Pashur slo profeten Jeremias og satte ham i stokken i den øvre Benjamins-port i Herrens hus. 
24:20:3: Men dagen efter slapp Pashur Jeremias ut av fengslet. Da sa Jeremias til ham: Herren har ikke kalt dig Pashur, men gitt dig navnet Magor Missabib*. # <* redsel rundt omkring; JER 20, 10; 6, 25.> 
24:20:4: For så sier Herren: Se, jeg gjør dig til en redsel for dig selv og for alle dine venner, og de skal falle for sine fienders sverd, og dine øine skal se det; og hele Juda vil jeg gi i Babels konges hånd, og han skal føre dem til Babel som fanger og slå dem med sverd. 
24:20:5: Og jeg vil gi alt godset i denne by og all dens eiendom og alle dens kostbarheter og alle Judas kongers skatter i deres fienders hånd, og de skal røve dem og ta dem og føre dem til Babel. 
24:20:6: Og du Pashur og alle som bor i ditt hus, I skal vandre bort i fangenskap, og til Babel skal du komme, og der skal du dø, og der skal du bli begravet, du og alle dine venner, som du har spådd løgn for. 
24:20:7: Herre! Du overtalte mig, og jeg lot mig overtale; du blev for sterk for mig og fikk overhånd; jeg er blitt til latter hele dagen, hver mann spotter mig. 
24:20:8: For så ofte jeg taler, må jeg skrike, må jeg rope om vold og ødeleggelse; for Herrens ord er blitt mig til hån og til spott hele dagen. 
24:20:9: Og jeg tenkte: Jeg vil ikke komme ham i hu og ikke tale mere i hans navn. Men da blev det i mitt hjerte som en brennende ild, innestengt i mine ben, og jeg trettet mig ut med å tåle det, men jeg maktet det ikke. 
24:20:10: For jeg hørte mange baktale mig - redsel fra alle kanter; de sa: Meld ham! Vi vil melde ham! Alle de menn som jeg levde i fred med, lurte på om jeg skulde falle; de sa: Kanskje han lar sig lokke, så vi kan få overhånd over ham og ta hevn over ham. 
24:20:11: Men Herren er med mig som en veldig kjempe; derfor skal mine forfølgere snuble og ikke få overhånd; de blir storlig til skamme fordi de ikke fór frem med visdom - en evig vanære, som aldri glemmes. 
24:20:12: Og Herren, hærskarenes Gud, prøver med rettferdighet, han ser nyrer og hjerte; jeg skal se din hevn over dem, for jeg har lagt min sak frem for dig. 
24:20:13: Syng for Herren, lov Herren! For han har fridd den fattiges sjel ut av ugjerningsmenns hånd. 
24:20:14: Forbannet være den dag jeg blev født! Den dag min mor fødte mig, være ikke velsignet! 
24:20:15: Forbannet være den mann som kom til min far med det budskap: Du har fått en sønn, og som gledet ham storlig. 
24:20:16: Med den mann skal det gå som med de byer Herren omstyrtet uten skånsel, og han skal høre skrik om morgenen og krigsrop ved middagstid, 
24:20:17: fordi han ikke drepte mig i mors liv, så min mor blev min grav, og hennes liv fruktsommelig til evig tid. 
24:20:18: Hvorfor kom jeg da ut av mors liv til å se møie og sorg og ende mine dager i skam? 
24:21:1: Dette er det ord som kom til Jeremias fra Herren da kong Sedekias sendte Pashur, Malkias sønn, og presten Sefanja, Ma'asejas sønn, til ham og lot si: 
24:21:2: Spør Herren for oss! For Babels konge Nebukadnesar strider mot oss. Kanskje Herren vil gjøre med oss efter alle sine undergjerninger, så han drar bort fra oss. 
24:21:3: Da sa Jeremias til dem: Så skal I si til Sedekias: 
24:21:4: Så sier Herren, Israels Gud: Se, jeg vender om de krigsvåben som er i eders hånd, og som I strider med utenfor muren mot Babels konge og mot kaldeerne, som har kringsatt eder, og jeg vil samle dem midt i denne by. 
24:21:5: Og jeg vil selv stride mot eder med utrakt hånd og med sterk arm og med vrede og harme og stor fortørnelse. 
24:21:6: Og jeg vil slå denne bys innbyggere, både mennesker og dyr; i en stor pest skal de dø. 
24:21:7: Og derefter, sier Herren, vil jeg gi Sedekias, Judas konge, og hans tjenere og folket, de som i denne by er blitt igjen efter pesten, sverdet og hungersnøden, i Babels konge Nebukadnesars hånd og i deres fienders hånd og i deres hånd som står dem efter livet; og han skal slå dem med sverdets egg, han skal ikke spare dem og ikke skåne og ikke forbarme sig. 
24:21:8: Og til dette folk skal du si: Så sier Herren: Se, jeg legger frem for eder livets vei og dødens vei: 
24:21:9: Den som blir i denne by, skal dø ved sverd og ved hunger og ved pest; men den som drar ut og går over til kaldeerne, som har kringsatt eder, skal bli i live, og han skal få sitt liv til krigsbytte. 
24:21:10: For jeg har vendt mitt åsyn imot denne by til det onde og ikke til det gode, sier Herren; i Babels konges hånd skal den bli gitt, og han skal brenne den op med ild. 
24:21:11: Og til Judas konges hus skal du si: Hør Herrens ord, du Davids hus! 
24:21:12: Så sier Herren: Hold rett hver morgen, og fri den som er plyndret, ut av undertrykkerens hånd, forat ikke min harme skal fare ut som ild og brenne uten at nogen slukker, for eders onde gjerningers skyld! 
24:21:13: Se, jeg kommer over dig, du som bor i dalen, på berget på sletten*, sier Herren - I som sier: Hvem kan komme ned og overfalle oss, og hvem kan komme inn i våre boliger? # <* d.e. Sion; JER 17, 3.> 
24:21:14: Jeg vil hjemsøke eder efter frukten av eders gjerninger, sier Herren, og jeg vil tende ild på dets skog*, og den skal fortære alt rundt omkring det. # <* d.e. husene i Jerusalem.> 
24:22:1: Så sa Herren: Gå ned til Judas konges hus og tal der dette ord: 
24:22:2: Hør Herrens ord, du Judas konge som sitter på Davids trone, du selv og dine tjenere og ditt folk, I som går inn gjennem disse porter! 
24:22:3: Så sier Herren: Gjør rett og rettferdighet, fri den plyndrede ut av undertrykkerens hånd, og gjør ikke urett og vold mot den fremmede, den farløse og enken, og utøs ikke uskyldig blod på dette sted! 
24:22:4: For dersom I gjør efter dette ord, så skal konger som sitter på Davids trone, dra inn gjennem portene til dette hus på vogner og hester, kongen selv og hans tjenere og hans folk. 
24:22:5: Men dersom I ikke hører på disse ord, da sverger jeg ved mig selv, sier Herren, at dette hus skal bli ødelagt. 
24:22:6: For så sier Herren om Judas konges hus: Et Gilead er du for mig, en Libanons topp; men sannelig, jeg vil gjøre dig til en ørken, til byer som ingen bor i. 
24:22:7: Og jeg vil innvie ødeleggere mot dig, hver med sine våben, og de skal hugge ned dine herligste sedrer og kaste dem på ilden. 
24:22:8: Og mange hedninger skal gå forbi denne by og si til hverandre: Hvorfor har Herren gjort så med denne store by? 
24:22:9: Og de skal svare: Fordi de forlot Herrens, sin Guds pakt og tilbad andre guder og dyrket dem. 
24:22:10: Gråt ikke over en død og klag ikke over ham! Gråt over ham som har draget bort! For han skal ikke mere komme tilbake og se sitt fødeland. 
24:22:11: For så sier Herren om Judas konge Sallum*, Josias' sønn, som blev konge efter sin far Josias, og som drog bort fra dette sted: Han skal ikke mere komme tilbake hit; # <* d.e. Joakas. 2KG 23, 30-34. 2KR 36, 1-4. ESK 19, 3. 4.> 
24:22:12: men på det sted som de har ført ham til i fangenskap*, der skal han dø, og dette land skal han ikke se mere. # <* d.e. Egypten.> 
24:22:13: Ve ham som bygger sitt hus med urettferdighet og sine saler med urett, som lar sin næste træle for intet og ikke gir ham hans lønn, 
24:22:14: som sier: Jeg vil bygge mig et rummelig hus og luftige saler, og som hugger ut vinduer på det og paneler det med sedertre og maler det med rødt. 
24:22:15: Er du konge fordi du kappes* i å bygge av sedertre? Din far - mon han ikke åt og drakk og gjorde rett og rettferdighet? Da gikk det ham vel. # <* med andre konger. FRK 3, 13; 5, 18. 2KG 22, 2.> 
24:22:16: Han hjalp den elendige og fattige til hans rett; da gikk det vel. Er ikke dette å kjenne mig? sier Herren. 
24:22:17: Men dine øine og ditt hjerte er ikke rettet på annet enn på vinning og på å utøse den uskyldiges blod og på å bruke vold og undertrykkelse. 
24:22:18: Derfor sier Herren så om Judas konge Jojakim, Josias' sønn: De skal ikke holde sørgehøitid over ham og rope: Ve, min bror! Ve, søster! De skal ikke holde sørgehøitid over ham og rope: Ve, herre! Ve, hans herlighet! 
24:22:19: Som de begraver et asen, skal han begraves; de skal slepe ham bort og slenge ham vekk langt fra Jerusalems porter. 
24:22:20: Stig op* på Libanon og skrik, og la din røst lyde i Basan og skrik fra Abarim**! For alle dine elskere er knust. # <* Judas folk.> # <** 5MO 32, 49.> 
24:22:21: Jeg talte til dig i din trygghet; men du sa: Jeg vil ikke høre. Dette var din ferd fra din ungdom av at du ikke hørte på min røst. 
24:22:22: Alle dine førere skal vinden bortføre, og dine elskere må gå i fangenskap; ja, da skal du vanæres og bli til skamme for all din ondskaps skyld. 
24:22:23: Du som bor på Libanon, som har ditt rede i sedrene, hvor du vil stønne når smerter kommer over dig, veer som den fødende kvinnes! 
24:22:24: Så sant jeg lever, sier Herren, selv om du Konja*, Jojakims sønn, Judas konge, var en signetring på min høire hånd, så vilde jeg rive dig derfra. # <* Konja eller Jekonja (JER 24, 1) d.s.s. Jojakin, 2KG 24, 6. 2KR 36, 9.> 
24:22:25: Og jeg vil gi dig i deres hånd som står dig efter livet, og i deres hånd som du gruer for, i Babels konge Nebukadnesars hånd og i kaldeernes hånd. 
24:22:26: Og jeg vil kaste dig og din mor som fødte dig, bort til et annet land, hvor I ikke blev født, og der skal I dø. 
24:22:27: Men det land de stunder efter å komme tilbake til, til det skal de ikke komme tilbake. 
24:22:28: Er han da en foraktet, knust lerkrukke, denne mann Konja, eller et kar som ingen bryr sig om? Hvorfor er de bortkastet, han og hans ætt, og slengt bort til det land de ikke kjente? 
24:22:29: Land! land! land! Hør Herrens ord! 
24:22:30: Så sier Herren: Skriv denne mann op som barnløs, som en mann som ingen lykke har i sine levedager! For ingen av hans ætt skal mere få lykke til å sitte på Davids trone og herske over Juda. 
24:23:1: Ve de hyrder som ødelegger og adspreder den hjord jeg før, sier Herren. 
24:23:2: Derfor sier Herren, Israels Gud, så om de hyrder som røkter mitt folk: I har adspredt mine får og jaget dem bort og ikke sett efter dem; se, jeg hjemsøker eder for eders onde gjerninger, sier Herren. 
24:23:3: Og jeg vil selv samle resten av mine får fra alle de land jeg har drevet dem bort til, og jeg vil føre dem tilbake til deres egne beitemarker, og de skal være fruktbare og bli mange. 
24:23:4: Og jeg vil sette hyrder over dem, og de skal røkte dem; og de skal ikke frykte mere og ikke forferdes, og ingen av dem skal savnes, sier Herren. 
24:23:5: Se, dager kommer, sier Herren, da jeg vil la stå frem for David en rettferdig spire, og han skal regjere som konge og gå frem med visdom og gjøre rett og rettferdighet i landet. 
24:23:6: I hans dager skal Juda bli frelst, og Israel bo trygt; og dette er det navn som han skal kalles med: Herren, vår rettferdighet*. # <* d.e. den ved hvem Herren skaffer oss rettferdighet. JES 45, 17. 25. SKR 14, 11.> 
24:23:7: Se, derfor skal dager komme, sier Herren, da en ikke mere skal si: Så sant Herren lever, han som førte Israels barn op fra Egyptens land, 
24:23:8: men: Så sant Herren lever, som førte Israels hus og ætt op og lot dem komme fra et land i nord og fra alle de land jeg hadde drevet dem bort til. Og de skal bo i sitt land. 
24:23:9: Om profetene. Mitt hjerte er sønderknust i mitt indre, alle mine ben skjelver, jeg er som en drukken mann, en mann som vinen har overveldet, for Herrens skyld og for hans hellige ords skyld. 
24:23:10: For landet er fullt av horkarler; for forbannelsens skyld visner landet, for dens skyld er ørkenens beitemarker avsvidd; deres* løp er ondt, og deres styrke er urett. # <* de ugudeliges.> 
24:23:11: For både profet og prest er gudløse; selv i mitt hus har jeg funnet deres ondskap, sier Herren. 
24:23:12: Derfor skal deres vei bli for dem som glatte steder i mørket; de skal støtes over ende og falle der; for jeg lar ulykke komme over dem i det år da de blir hjemsøkt, sier Herren. 
24:23:13: Hos Samarias profeter har jeg sett dårskap; de profeterte ved Ba'al og førte mitt folk Israel vill. 
24:23:14: Men hos Jerusalems profeter har jeg sett grufulle ting; de driver hor og farer med løgn og styrker de ondes hender, så ingen av dem vender om fra sin ondskap; de er alle sammen blitt for mig som Sodoma, og dets innbyggere som Gomorra. 
24:23:15: Derfor sier Herren, hærskarenes Gud, så om profetene: Se, jeg gir dem malurt å ete og beskt vann å drikke; for fra Jerusalems profeter er gudløshet gått ut over hele landet. 
24:23:16: Så sier Herren, hærskarenes Gud: Hør ikke på de profeters ord som profeterer for eder! De fyller eder med tomme innbilninger; de bærer frem sitt eget hjertes syner, ikke ord av Herrens munn. 
24:23:17: De sier atter og atter til dem som forakter mig: Herren har sagt: I skal ha fred. Og til hver den som følger sitt hårde hjerte, sier de: Det skal ikke komme ulykke over eder. 
24:23:18: For hvem av dem har stått i Herrens fortrolige råd, så han så og hørte hans ord? Hvem har gitt akt på mitt ord og hørt det? 
24:23:19: Se, Herrens storm, hans vrede farer ut, en hvirvlende stormvind; den hvirvler over hodet på de ugudelige. 
24:23:20: Herrens vrede skal ikke vende tilbake før han har utført og fullbyrdet sitt hjertes tanker; i de siste dager skal I forstå det helt. 
24:23:21: Jeg har ikke sendt profetene, allikevel løp de; jeg har ikke talt til dem, allikevel profeterte de. 
24:23:22: Og hadde de stått i mitt fortrolige råd, vilde de la mitt folk høre mine ord og føre dem tilbake fra deres onde vei og fra deres onde gjerninger. 
24:23:23: Er jeg en Gud bare nær ved, sier Herren, og ikke en Gud langt borte? 
24:23:24: Eller kan nogen skjule sig på lønnlige steder så jeg ikke kan se ham? sier Herren. Opfyller jeg ikke himmelen og jorden? sier Herren. 
24:23:25: Jeg har hørt hvad de profeter har sagt som profeterer løgn i mitt navn og sier: Jeg har drømt, jeg har drømt. 
24:23:26: Hvor lenge skal dette vare? Har de i sinne de profeter som profeterer løgn, og som bærer frem sitt eget hjertes svik - 
24:23:27: tenker de på å få mitt folk til å glemme mitt navn ved sine drømmer som de forteller hverandre, likesom deres fedre glemte mitt navn for Ba'als skyld? 
24:23:28: Den profet som har en drøm, han får fortelle en drøm, og den som har mitt ord, han skal tale mitt ord i sannhet! Hvad har halmen å gjøre med kornet? sier Herren. 
24:23:29: Er ikke mitt ord som en ild, sier Herren, og lik en hammer som knuser berg? 
24:23:30: Se, derfor, sier Herren, kommer jeg over de profeter som stjeler mine ord fra hverandre. 
24:23:31: Se, jeg kommer over de profeter som bruker sin tunge og sier: Så sier Herren. 
24:23:32: Se, jeg kommer over dem som bærer frem løgnaktige drømmer, sier Herren, og som forteller dem og fører mitt folk vill ved sine løgner og ved sitt skryt; jeg har ikke sendt dem og ikke gitt dem befaling, og noget gagn gjør de ikke dette folk, sier Herren. 
24:23:33: Og når dette folk eller en profet eller en prest spør dig: Hvad er Herrens byrde? - da skal du si dem hvad byrden er: [I er byrden] og jeg vil kaste eder bort, sier Herren*. # <* Det hebraiske ord "massa" betyr både en byrde og et utsagn. De spørrende kaller Herrens utsagn ved Jeremias en byrde. Profeten skal da svare dem at de selv er Herren en byrde som han vil kaste fra sig.> 
24:23:34: Og den profet og den prest og den av folket som sier: Herrens byrde, den mann og hans hus vil jeg hjemsøke. 
24:23:35: Således skal I si hver til sin næste og hver til sin bror: Hvad har Herren svart? Hvad har Herren talt? 
24:23:36: Men "Herrens byrde" skal I ikke nevne mere; for hver manns eget ord skal være hans byrde, fordi I har forvendt den levende Guds, Herrens, hærskarenes Guds, vår Guds ord. 
24:23:37: Således skal du si til profeten: Hvad har Herren svart dig? Hvad har Herren talt? 
24:23:38: Men dersom I sier "Herrens byrde", da sier Herren så: Fordi I sier dette ord, "Herrens byrde", enda jeg sendte eder bud og sa: I skal ikke si "Herrens byrde", 
24:23:39: se, derfor vil jeg aldeles glemme eder og kaste eder og den by jeg gav eder og eders fedre, bort fra mitt åsyn, 
24:23:40: og jeg vil legge på eder en evig skam og en evig vanære, som aldri skal glemmes. 
24:24:1: Herren lot mig se dette syn: Der var to kurver med fikener som var opstilt foran Herrens helligdom - det var efterat Babels konge Nebukadnesar hadde bortført Judas konge Jekonja, Jojakims sønn, og Judas høvdinger og tømmermennene og smedene fra Jerusalem og ført dem til Babel. 
24:24:2: I den ene kurv var det meget gode fikener, som de tidlige fikener, men i den andre kurv meget dårlige fikener, som ikke kunde etes, fordi de var så dårlige. 
24:24:3: Og Herren sa til mig: Hvad ser du, Jeremias? Jeg svarte: fikener; de gode fikener er meget gode, og de dårlige er meget dårlige, så de ikke kan etes, fordi de er så dårlige. 
24:24:4: Da kom Herrens ord til mig, og det lød så: 
24:24:5: Så sier Herren, Israels Gud: Som en gjør med disse gode fikener, således vil jeg se med velbehag på de bortførte av Juda, dem som jeg har sendt bort fra dette sted til kaldeernes land. 
24:24:6: Jeg vil rette mine øine på dem med velbehag og føre dem tilbake til dette land, og jeg vil bygge dem op og ikke bryte dem ned, jeg vil plante dem og ikke rykke dem op. 
24:24:7: Og jeg vil gi dem hjerte til å kjenne at jeg er Herren, og de skal være mitt folk, og jeg vil være deres Gud; for de skal vende om til mig av hele sitt hjerte. 
24:24:8: Og som en gjør med dårlige fikener, som ikke kan etes, fordi de er så dårlige - ja, så sier Herren, således vil jeg gjøre med Judas konge Sedekias og hans høvdinger og dem som er blitt igjen i Jerusalem, dem som er blitt tilbake i dette land, og dem som bor i Egyptens land. 
24:24:9: Jeg vil la dem bli mishandlet av alle jordens riker og lide ondt, og jeg vil gjøre dem til hån og til et ordsprog, til spott og til forbannelse på alle de steder jeg driver dem bort til. 
24:24:10: Og jeg vil sende sverd, hungersnød og pest iblandt dem til de blir helt utryddet av det land jeg gav dem og deres fedre. 
24:25:1: Dette er det ord som kom til Jeremias om hele Judas folk i Judas konge Jojakims, Josias' sønns fjerde år, det er Babels konge Nebukadnesars første år, 
24:25:2: og som profeten Jeremias talte til hele Judas folk og til alle Jerusalems innbyggere: 
24:25:3: Fra Judas konge Josias', Amons sønns trettende år og like til denne dag, nu i tre og tyve år, er Herrens ord kommet til mig, og jeg har talt til eder tidlig og sent, men I hørte ikke. 
24:25:4: Og Herren sendte til eder alle sine tjenere, profetene, tidlig og sent; men I hørte ikke, og I bøide ikke eders øre til å høre. 
24:25:5: Han sa: Vend om, hver fra sin onde vei, og fra eders onde gjerninger! Så skal I få bo i det land Herren gav eder og eders fedre, fra evighet og til evighet. 
24:25:6: Følg ikke andre guder, så I dyrker dem og tilbeder dem, og vekk ikke min harme ved eders henders verk, forat jeg ikke skal gjøre eder noget ondt! 
24:25:7: Men I hørte ikke på mig, sier Herren, I vakte min harme ved eders henders verk, til ulykke for eder selv. 
24:25:8: Derfor sier Herren, hærskarenes Gud, så: Fordi I ikke har hørt på mine ord, 
24:25:9: se, så sender jeg bud og henter alle Nordens folkeslag, sier Herren, og jeg sender bud til Babels konge Nebukadnesar, min tjener, og jeg lar dem komme over dette land og over dets innbyggere og over alle folkeslagene her rundt omkring, og jeg slår dem med bann og gjør dem til en forferdelse og til en spott og deres land til evige ørkener, 
24:25:10: og jeg lar fryds røst og gledes røst, brudgoms røst og bruds røst, lyd av kvern og schmelk av lampe bli borte hos dem. 
24:25:11: Og hele dette land skal bli til en ørken, til en ødemark, og disse folkeslag skal tjene Babels konge i sytti år. 
24:25:12: Men når sytti år er til ende, da vil jeg hjemsøke kongen i Babel og folket der, sier Herren, for deres misgjernings skyld, og jeg vil hjemsøke kaldeernes land og gjøre det til evige ørkener. 
24:25:13: Og jeg vil la komme over det land alle de ord som jeg har talt om det, alt det som er skrevet i denne bok, det som Jeremias har profetert om alle folkeslagene. 
24:25:14: For mange folkeslag og store konger skal gjøre også dem til træler, og jeg vil gjengjelde dem efter deres gjerning og efter deres henders verk. 
24:25:15: For så sa Herren, Israels Gud, til mig: Ta dette beger med vredesvin av min hånd og gi alle de folk jeg sender dig til, å drikke av det! 
24:25:16: Og de skal drikke og rave og te sig som rasende for det sverd jeg sender iblandt dem. 
24:25:17: Og jeg tok begeret av Herrens hånd, og jeg gav alle de folk å drikke som Herren hadde sendt mig til: 
24:25:18: Jerusalem og Judas byer og dets konger og dets høvdinger, for å gjøre dem til en ørken, til en ødemark, til en spott og til en forbannelse, således som det er på denne dag, 
24:25:19: Egyptens konge Farao og hans tjenere og hans høvdinger og hele hans folk 
24:25:20: og alle hans fremmede undersåtter og alle kongene i landet Us og alle kongene i filistrenes land, i Askalon og Gasa og Ekron og det som er tilbake av Asdod, 
24:25:21: Edom og Moab og Ammons barn 
24:25:22: og alle Tyrus' konger og alle Sidons konger og kongene over kystlandet på hin side havet, 
24:25:23: Dedan og Tema og Bus og alle med rundklippet hår 
24:25:24: og alle Arabias konger og alle kongene over de fremmede folk som bor i ørkenen, 
24:25:25: og alle Simris konger og alle Elams konger og alle Medias konger 
24:25:26: og alle Nordens konger, nær og fjern, den ene med den andre, og alle verdens riker utover hele jorden; og Sesaks* konge skal drikke efter dem. # <* d.e. Babels; JER 51, 41. HAB 2, 15. 16.> 
24:25:27: Og du skal si til dem: Så sier Herren, hærskarenes Gud, Israels Gud: Drikk og bli drukne og spy og fall og reis eder ikke mere - for det sverd jeg sender iblandt eder! 
24:25:28: Men dersom de ikke vil ta begeret av din hånd og drikke, da skal du si til dem: Så sier Herren, hærskarenes Gud: Drikke skal I. 
24:25:29: For se, den by som er kalt med mitt navn, lar jeg ulykken ramme først, og så skulde I bli ustraffede? Nei, I skal ikke bli ustraffede; for sverd kaller jeg hit over alle dem som bor på jorden, sier Herren, hærskarenes Gud. 
24:25:30: Og du skal profetere for dem alt dette og si til dem: Herren skal brøle fra det høie og la sin røst høre fra sin hellige bolig; ja, brøle skal han over sin beitemark, et skrik lik deres som treder vinpersen, skal han opløfte over alle dem som bor på jorden. 
24:25:31: Bulderet når til jordens ende, for Herren har strid med folkene, han går i rette med alt kjød; de ugudelige overgir han til sverdet, sier Herren. 
24:25:32: Så sier Herren, hærskarenes Gud: Se, ulykke går ut fra folk til folk, og en svær storm reiser sig fra jordens ytterste ende. 
24:25:33: Og de som Herren har drept, skal på den dag ligge fra jordens ene ende til den andre; ingen skal gråte over dem, ingen skal samle dem op og begrave dem; til gjødsel utover jorden skal de bli. 
24:25:34: Hyl, I hyrder, og skrik og velt eder i støvet, I som er de gjeveste i hjorden! For tiden er kommet da I skal slaktes, og jeg vil adsprede eder, og I skal falle som et kostelig kar, 
24:25:35: og hyrdene skal ikke mere ha noget tilfluktssted, og de gjeveste i hjorden skal ikke finne redning. 
24:25:36: Hør hvorledes hyrdene skriker og de gjeveste i hjorden hyler! For Herren ødelegger deres beite, 
24:25:37: og de fredelige enger blir øde for Herrens brennende vrede. 
24:25:38: Som en ung løve går han frem fra sitt skjul; ja, deres land blir til en ødemark for det herjende sverd og for hans brennende vrede. 
24:26:1: I begynnelsen av Judas konge Jojakims, Josias' sønns regjering kom dette ord fra Herren: 
24:26:2: Så sier Herren: Stå frem i forgården til Herrens hus og tal til alle Judas byer, som kommer for å tilbede i Herrens hus, alle de ord jeg har befalt dig å tale til dem! Ta ikke et ord fra! 
24:26:3: Kan hende de vil høre og vende om, hver fra sin onde vei; så vil jeg angre det onde jeg tenker på å gjøre dem for deres onde gjerningers skyld. 
24:26:4: Og du skal si til dem: Så sier Herren: Dersom I ikke vil høre på mig, så I følger den lov som jeg har forelagt eder, 
24:26:5: og hører på mine tjenere profetenes ord, dem som jeg sender til eder både tidlig og sent, uten at I hører, 
24:26:6: så vil jeg gjøre med dette hus som med Silo, og denne by vil jeg gjøre til en forbannelse for alle jordens folkeslag. 
24:26:7: Og prestene og profetene og hele folket hørte Jeremias tale disse ord i Herrens hus. 
24:26:8: Og da Jeremias var ferdig med å tale alt det Herren hadde befalt ham å tale til hele folket, da grep de ham, prestene og profetene og hele folket, og sa: Du skal dø. 
24:26:9: Hvorfor har du profetert i Herrens navn og sagt: Det skal gå med dette hus som med Silo, og denne by skal bli øde, uten innbyggere? Og alt folket samlet sig imot Jeremias i Herrens hus. 
24:26:10: Da Judas høvdinger hørte dette, gikk de fra kongens hus op til Herrens hus, og de satte sig i inngangen til Herrens nye port. 
24:26:11: Og prestene og profetene sa til høvdingene og til alt folket: Denne mann er skyldig til å dø; for han har profetert mot denne by, således som I har hørt med egne ører. 
24:26:12: Da sa Jeremias til alle høvdingene og til alt folket: Herren har sendt mig for å profetere mot dette hus og mot denne by alt det I har hørt. 
24:26:13: Så bedre nu eders veier og eders gjerninger og hør på Herrens, eders Guds røst! Så skal Herren angre det onde han har talt imot eder. 
24:26:14: Og jeg, se, jeg er i eders hånd; gjør med mig således som godt og rett er i eders øine! 
24:26:15: Men det skal I vite at dersom I dreper mig, da fører I uskyldig blod over eder selv og over denne by og dens innbyggere; for Herren har i sannhet sendt mig til eder forat jeg skulde tale alle disse ord for eders ører. 
24:26:16: Da sa høvdingene og alt folket til prestene og profetene: Denne mann er ikke skyldig til å dø; for i Herrens, vår Guds navn har han talt til oss. 
24:26:17: Og nogen av landets eldste stod op og sa til hele folkets forsamling: 
24:26:18: Mika fra Moreset profeterte i Judas konge Esekias' dager og sa til alt Judas folk: Så sier Herren, hærskarenes Gud: Sion skal pløies som en aker, og Jerusalem bli til grusdynger og tempelberget til skogbakker. 
24:26:19: Drepte vel Judas konge Esekias og hele Juda ham? Fryktet han ikke Herren og bønnfalt ham, så Herren angret det onde han hadde talt imot dem? Og vi er i ferd med å føre en stor ulykke over oss selv! 
24:26:20: Det var også en annen mann som profeterte i Herrens navn, Uria, Semajas sønn, fra Kirjat-Jearim, og han profeterte mot denne by og dette land aldeles som Jeremias gjorde. 
24:26:21: Og kong Jojakim og alle hans krigsmenn og alle høvdingene hørte hans ord, og kongen søkte å drepe ham; men Uria fikk høre det og blev redd, og han flyktet og kom til Egypten. 
24:26:22: Da sendte kong Jojakim nogen menn til Egypten, Elnatan, Akbors sønn, og nogen andre menn med ham, 
24:26:23: og de hentet Uria fra Egypten og førte ham til kong Jojakim; og han lot ham drepe med sverd og kastet hans lik mellem almuens graver. 
24:26:24: Men Akikam, Safans sønn, holdt sin hånd over Jeremias, så han ikke blev overgitt i folkets hånd til å drepes. 
24:27:1: I begynnelsen av Judas konge Sedekias'*, Josias' sønns regjering kom dette ord til Jeremias fra Herren: # <* JER 27, 3. 12. 20; 28, 1.> 
24:27:2: Så sa Herren til mig: Gjør dig bånd og åk, og legg dem på din nakke! 
24:27:3: Så skal du sende dem til Edoms konge og til Moabs konge og til Ammons barns konge og til Tyrus' konge og til Sidons konge med de sendemenn som kommer til Jerusalem til Sedekias, Judas konge, 
24:27:4: og du skal byde dem å si til sine herrer: Så sier Herren, hærskarenes Gud, Israels Gud: Så skal I si til eders herrer: 
24:27:5: Jeg har gjort jorden, menneskene og dyrene som er på jorden, ved min store kraft og ved min utrakte arm, og jeg gir den til ham som er den rette i mine øine. 
24:27:6: Og nu har jeg gitt alle disse land i min tjener Nebukadnesars, Babels konges hånd; også markens dyr har jeg gitt ham til å tjene ham. 
24:27:7: Og alle folkeslag skal tjene ham og hans sønn og hans sønnesønn, inntil tiden kommer også for hans land; da skal mange folkeslag og store konger gjøre ham til træl. 
24:27:8: Og det folk og det rike som ikke vil tjene ham, Nebukadnesar, Babels konge, og som ikke vil bøie sin nakke under Babels konges åk, det folk vil jeg hjemsøke med sverd og hungersnød og pest, sier Herren, inntil jeg får gjort ende på dem ved hans hånd. 
24:27:9: Og I, hør ikke på eders profeter og spåmenn og på eders drømmer og på eders varseltydere og trollmenn, som sier til eder: I kommer ikke til å tjene Babels konge! 
24:27:10: For det er løgn de profeterer for eder, så jeg må flytte eder fra eders land og drive eder bort, og I må gå til grunne. 
24:27:11: Men det folk som bøier sin nakke under Babels konges åk og tjener ham, det vil jeg la bli i ro i sitt land, sier Herren, og det skal få dyrke det og bo i det. 
24:27:12: Og til Judas konge Sedekias talte jeg alle de samme ord og sa: Bøi eders nakke under Babels konges åk og tjen ham og hans folk, så skal I leve. 
24:27:13: Hvorfor vil I dø, du og ditt folk, ved sverd, hunger og pest, således som Herren har talt om det folk som ikke vil tjene Babels konge? 
24:27:14: Hør ikke på de profeters ord som sier til eder: I skal ikke komme til å tjene Babels konge! For det er løgn de profeterer for eder; 
24:27:15: jeg har ikke sendt dem, sier Herren, men de profeterer falskelig i mitt navn, så jeg må drive eder bort, og I må gå til grunne, I og de profeter som profeterer for eder. 
24:27:16: Og til prestene og til hele dette folk talte jeg og sa: Så sier Herren: Hør ikke på eders profeters ord når de profeterer for eder og sier: Se, karene i Herrens hus skal føres tilbake fra Babel nu snart! For det er løgn de profeterer for eder. 
24:27:17: Hør ikke på dem, tjen Babels konge, så skal I leve. Hvorfor skal denne by bli til en ørken? 
24:27:18: Men dersom de er profeter, og dersom Herrens ord er hos dem, så la dem bede Herren, hærskarenes Gud, at de kar som er blitt tilbake i Herrens hus og i Judas konges hus og i Jerusalem, ikke må komme til Babel! 
24:27:19: For så sier Herren, hærskarenes Gud, om søilene og kobberhavet og fotstykkene og de andre ting som er blitt tilbake i denne by, 
24:27:20: de som Babels konge Nebukadnesar ikke tok da han bortførte Judas konge Jekonja, Jojakims sønn, fra Jerusalem til Babel sammen med alt storfolket i Juda og Jerusalem - 
24:27:21: så sier Herren, hærskarenes Gud, Israels Gud, om de kar som er blitt tilbake i Herrens hus og i Judas konges hus og i Jerusalem: 
24:27:22: Til Babel skal de bli ført, og der skal de være til den dag da jeg ser efter dem, sier Herren, og fører dem op og lar dem komme tilbake til dette sted. 
24:28:1: I det samme år, i begynnelsen av Judas konge Sedekias' regjering, i det fjerde år, i den femte måned, sa Hananja, Assurs sønn, profeten fra Gibeon, til mig i Herrens hus i prestenes og alt folkets nærvær: 
24:28:2: Så sier Herren, hærskarenes Gud, Israels Gud: Jeg har brutt i stykker Babels konges åk. 
24:28:3: Om to år fører jeg tilbake til dette sted alle de kar i Herrens hus som Babels konge Nebukadnesar tok fra dette sted og førte til Babel. 
24:28:4: Og Judas konge Jekonja, Jojakims sønn, og alle de bortførte fra Juda som er kommet til Babel, fører jeg tilbake til dette sted, sier Herren; for jeg vil bryte Babels konges åk. 
24:28:5: Da sa profeten Jeremias til profeten Hananja så prestene og alt folket som stod i Herrens hus, hørte det - 
24:28:6: profeten Jeremias sa: Amen! Det gjøre Herren! Herren opfylle de ord som du profeterte, så han fører karene i Herrens hus og alle de bortførte tilbake fra Babel til dette sted! 
24:28:7: Men hør nu dette ord som jeg taler for dine og for alt folkets ører: 
24:28:8: De profeter som har vært før mig og før dig fra fordums tid, de profeterte mot mange land og mot store riker om krig og ulykke og pest. 
24:28:9: Den profet som profeterer om fred, når hans ord går i opfyllelse, da kan alle se at han er en profet som Herren i sannhet har sendt. 
24:28:10: Da tok profeten Hananja åket av profeten Jeremias' nakke og brøt det i stykker. 
24:28:11: Og Hananja sa i alt folkets nærvær: Så sier Herren: Således vil jeg om to år bryte i stykker Babels konge Nebukadnesars åk og ta det av alle folkeslags nakke. Da gikk profeten Jeremias sin vei. 
24:28:12: Men efterat profeten Hananja hadde brutt i stykker åket og tatt det av profeten Jeremias' nakke, kom Herrens ord til Jeremias, og det lød så: 
24:28:13: Gå og si til Hananja: Så sier Herren: Et åk av tre har du brutt i stykker, men i stedet for det har du gjort et åk av jern. 
24:28:14: For så sier Herren, hærskarenes Gud, Israels Gud: Et åk av jern har jeg lagt på alle disse folkeslags nakke, forat de skal tjene Babels konge Nebukadnesar, og de skal tjene ham; også markens dyr har jeg gitt ham. 
24:28:15: Da sa profeten Jeremias til profeten Hananja: Hør nu, Hananja! Herren har ikke sendt dig, og du har fått dette folk til å sette sin lit til løgn. 
24:28:16: Derfor sier Herren så: Se, jeg tar dig bort fra jorden; i dette år skal du dø, fordi du har preket frafall fra Herren. 
24:28:17: Og profeten Hananja døde i det samme år, i den syvende måned. 
24:29:1: Dette er innholdet av det brev som profeten Jeremias sendte fra Jerusalem til de gjenlevende av de eldste blandt de bortførte og til prestene og profetene og hele det folk som Nebukadnesar hadde bortført fra Jerusalem til Babel, 
24:29:2: efterat kong Jekonja og kongens mor og hoffmennene, Judas og Jerusalems høvdinger og tømmermennene og smedene hadde draget bort fra Jerusalem. 
24:29:3: Han sendte brevet med El'asa, Safans sønn, og Gemarja, Hilkias' sønn, som Judas konge Sedekias sendte til Babel, til Babels konge Nebukadnesar, og det lød således: 
24:29:4: Så sier Herren, hærskarenes Gud, Israels Gud, til alle dem som jeg har ført bort fra Jerusalem til Babel: 
24:29:5: Bygg hus og bo i dem, plant haver og et deres frukt! 
24:29:6: Ta eder hustruer og få sønner og døtre, og ta hustruer til eders sønner og gift bort eders døtre, så de kan få føde sønner og døtre; bli tallrike der, og bli ikke færre! 
24:29:7: Og søk den bys vel som jeg har bortført eder til, og bed for den til Herren! For når det går den vel, så går det eder vel. 
24:29:8: For så sier Herren, hærskarenes Gud, Israels Gud: La ikke eders profeter, som er iblandt eder, og eders spåmenn dåre eder, og lytt heller ikke til de drømmer som I har! 
24:29:9: For falskelig profeterer de for eder i mitt navn; jeg har ikke sendt dem, sier Herren. 
24:29:10: For så sier Herren: Når sytti år er gått til ende for Babel, vil jeg se til eder og opfylle for eder mitt gode ord, at jeg vil føre eder tilbake til dette sted. 
24:29:11: For jeg vet de tanker jeg tenker om eder, sier Herren, fredstanker og ikke tanker til ulykke, å gi eder fremtid og håp. 
24:29:12: Og I skal påkalle mig og gå avsted og bede til mig, og jeg vil høre på eder, 
24:29:13: og I skal søke mig, og I skal finne mig når I søker mig av hele eders hjerte. 
24:29:14: Jeg vil la mig finne av eder, sier Herren, og jeg vil gjøre ende på eders fangenskap og samle eder fra alle de folk og alle de steder som jeg har drevet eder bort til, sier Herren, og jeg vil føre eder tilbake til det sted som jeg førte eder bort fra. 
24:29:15: [Dette skriver jeg] fordi I sier: Herren har latt profeter fremstå for oss i Babel. 
24:29:16: For så sier Herren om kongen som sitter på Davids trone, og om alt folket som bor i denne by, eders brødre, som ikke har draget ut med eder blandt de bortførte - 
24:29:17: så sier Herren, hærskarenes Gud: Se, jeg sender sverd, hunger og pest iblandt dem og gjør med dem som en gjør med de dårlige fikener, som ikke kan etes, fordi de er så dårlige. 
24:29:18: Og jeg vil forfølge dem med sverd, hunger og pest, og jeg vil la dem bli mishandlet av alle jordens riker og gjøre dem til en forbannelse og en forferdelse, til spott og hån blandt alle de folkeslag som jeg driver dem bort til, 
24:29:19: fordi de ikke hørte på mine ord, sier Herren, da jeg sendte mine tjenere profetene til dem, tidlig og sent; men I vilde ikke høre, sier Herren. 
24:29:20: Men hør nu I Herrens ord, alle I bortførte som jeg har sendt fra Jerusalem til Babel! 
24:29:21: Så sier Herren, hærskarenes Gud, Israels Gud, om Akab, Kolajas sønn, og om Sedekias, Ma'asejas sønn, som profeterer løgn for eder i hans navn: Se, jeg gir dem i Babels konge Nebukadnesars hånd, og han skal drepe dem for eders øine. 
24:29:22: Og fra dem skal alle Judas bortførte som er i Babel, hente en forbannelse og si: Herren gjøre med dig som med Sedekias og med Akab, som Babels konge stekte i ilden, 
24:29:23: fordi de gjorde en skjendig gjerning i Israel og drev hor med hverandres hustruer og talte løgnaktige ord i mitt navn, noget jeg ikke hadde befalt dem; og jeg vet det og er vidne, sier Herren. 
24:29:24: Og til nehelamitten Semaja skal du si: 
24:29:25: Så sier Herren, hærskarenes Gud, Israels Gud: Du har sendt brev i ditt eget navn til alt folket i Jerusalem og til presten Sefanja, Ma'asejas sønn, og til alle prestene og sagt: 
24:29:26: Herren har satt dig til prest i presten Jojadas sted, forat det skal være tilsynsmenn i Herrens hus over alle som er fra vettet og gir sig av med å profetere, så du kan legge dem i blokk og bånd. 
24:29:27: Hvorfor har du da ikke refset Jeremias fra Anatot, som gir sig av med å profetere for eder? 
24:29:28: For han har sendt brev til oss i Babel og sagt: Det vil vare lenge; bygg hus og bo i dem, plant haver og et deres frukt! 
24:29:29: Og presten Sefanja leste op dette brev for profeten Jeremias. 
24:29:30: Da kom Herrens ord til Jeremias, og det lød så: 
24:29:31: Send bud til alle de bortførte og si: Så sier Herren om nehelamitten Semaja: Fordi Semaja har profetert for eder uten at jeg har sendt ham, og har fått eder til å sette eders lit til løgn, 
24:29:32: derfor sier Herren så: Se, jeg hjemsøker nehelamitten Semaja og hans ætt; ingen av hans ætt skal få bo blandt dette folk, og han skal ikke få se det gode jeg gjør for mitt folk, sier Herren; for han har preket frafall fra Herren. 
24:30:1: Dette er det ord som kom til Jeremias fra Herren: 
24:30:2: Så sier Herren, Israels Gud: Skriv alle de ord jeg har talt til dig, i en bok! 
24:30:3: For se, dager kommer, sier Herren, da jeg gjør ende på mitt folks, Israels og Judas, fangenskap, sier Herren, og fører dem tilbake til det land jeg gav deres fedre, så de kan ta det i eie. 
24:30:4: Og dette er de ord Herren har talt om Israel og Juda: 
24:30:5: Så sier Herren: Et redselsrop har vi hørt; der er frykt og ingen fred. 
24:30:6: Spør og se efter om en mann føder! Hvorfor ser jeg hver mann med hendene på sine lender, lik en fødende kvinne, og hvorfor er alle åsyn forandret og blitt bleke? 
24:30:7: Ve! Stor er den dag, det er ingen som den, og en trengselstid er det for Jakob; men han skal bli frelst fra den. 
24:30:8: Og det skal skje på den dag, sier Herren, hærskarenes Gud, da vil jeg bryte hans* åk og ta det av din nakke, og dine bånd vil jeg rive i stykker, og fremmede skal ikke mere holde dig i trældom. # <* fiendens.> 
24:30:9: Og de skal tjene Herren sin Gud og David sin konge, som jeg vil opreise for dem. 
24:30:10: Frykt ikke, du min tjener Jakob, sier Herren, og reddes ikke, Israel! For se, jeg frelser dig fra det fjerne land og din ætt fra deres fangenskaps land, og Jakob skal vende tilbake og leve i ro og være trygg, og ingen skal forferde ham. 
24:30:11: For jeg er med dig, sier Herren, og jeg vil frelse dig; jeg vil gjøre ende på alle de folk som jeg har adspredt dig iblandt; bare dig vil jeg ikke gjøre ende på; jeg vil tukte dig med måte; men helt ustraffet vil jeg ikke la dig være. 
24:30:12: For så sier Herren: Ubotelig er din skade, ulægelig ditt sår. 
24:30:13: Ingen tar sig av din sak, så han klemmer ditt sår ut; lægedom, plaster finnes ikke for dig. 
24:30:14: Alle dine elskere har glemt dig, de spør ikke efter dig; for jeg har slått dig som en fiende slår, som den grusomme tukter, fordi din misgjerning er mangfoldig, dine synder tallrike. 
24:30:15: Hvorfor skriker du over din skade, over at din smerte er ubotelig? Fordi din misgjerning er mangfoldig, dine synder tallrike, har jeg gjort dette mot dig. 
24:30:16: Derfor skal alle de som eter dig, bli ett, og alle de som er dine fiender, dra bort i fangenskap, alle sammen, og de som plyndrer dig, skal bli plyndret, og alle dem som røver dig, vil jeg gjøre til rov. 
24:30:17: For jeg vil legge forbinding på dig og læge dine sår, sier Herren; for de kaller dig den bortdrevne, Sion som ingen spør efter. 
24:30:18: Så sier Herren: Se, jeg gjør ende på fangenskapet for Jakobs telt og forbarmer mig over hans boliger; byen skal bygges på sin haug, og palasset skal stå på sitt rette sted. 
24:30:19: Og det skal lyde lovsang fra dem, og rop av glade mennesker; jeg vil gjøre dem tallrike, og de skal ikke bli færre; jeg vil ære dem, og de skal ikke bli aktet ringe. 
24:30:20: Og hans* sønner skal være som i fordums tid, og hans menighet skal stå fast for mitt åsyn, og jeg vil hjemsøke alle dem som undertrykker ham. # <* Jakobs; JER 30, 18.> 
24:30:21: Og hans herlige* skal være av hans egen ætt, og hans hersker** utgå av hans midte, og jeg vil la ham komme nær, og han skal trede frem for mig; for hvem vil ellers våge sitt liv og komme mig nær? sier Herren. # <* Messias; MIK 5, 1.> # <** Messias; MIK 5, 1.> 
24:30:22: Og I skal være mitt folk, og jeg vil være eders Gud. 
24:30:23: Se, Herrens storm, hans vrede farer ut, en rivende stormvind; den hvirvler over hodet på de ugudelige. 
24:30:24: Herrens brennende vrede skal ikke vende tilbake før han har utført og fullbyrdet sitt hjertes tanker; i de siste dager skal I forstå det. 
24:31:1: På den tid, sier Herren, vil jeg være alle Israels ætters Gud, og de skal være mitt folk. 
24:31:2: Så sier Herren: Det folk som er undkommet fra sverdet, har funnet nåde i ørkenen; jeg vil gå og føre Israel til ro. 
24:31:3: Fra det fjerne har Herren åpenbaret sig for mig: Ja, med evig kjærlighet har jeg elsket dig; derfor har jeg latt min miskunnhet mot dig vare ved. 
24:31:4: Ennu en gang vil jeg bygge dig, og du skal bli bygget, du jomfru, Israel! Ennu en gang skal du pryde dig med dine trommer og gå ut i dansen med de glade. 
24:31:5: Ennu en gang skal du plante vingårder på Samarias fjell; de som planter dem, skal også ta dem i bruk. 
24:31:6: For det kommer en dag da vektere skal rope på Efra'ims fjell: stå op og la oss dra op til Sion, til Herren vår Gud! 
24:31:7: For så sier Herren: Juble over Jakob med glede og rop med fryd over det ypperste blandt folkene*! La lovsang lyde og si: Herre, frels ditt folk, dem som er igjen av Israel! # <* AMO 6, 1.> 
24:31:8: Se, jeg fører dem fra landet i nord og samler dem fra jordens ytterste ende; blandt dem er det både blinde og halte, både fruktsommelige og fødende; som en stor skare skal de komme hit tilbake. 
24:31:9: Med gråt skal de komme og med ydmyke bønner, og jeg vil lede dem; jeg vil føre dem til rinnende bekker på en jevn vei, hvor de ikke skal snuble; for jeg er blitt en far for Israel, og Efra'im er min førstefødte. 
24:31:10: Hør Herrens ord, I folk, og forkynn det på øene langt borte og si: Han som adspredte Israel, skal samle det og vokte det, som en hyrde vokter sin hjord. 
24:31:11: For Herren har fridd Jakob ut og løst ham av dens hånd som var sterkere enn han. 
24:31:12: Og de skal komme og synge med fryd på Sions berg, og de skal strømme til Herrens gode ting, til korn og til most og til olje og til unge får og okser, og deres sjel skal være som en vannrik have, og de skal ikke vansmekte mere. 
24:31:13: Da skal jomfruer glede sig i dans, og unge og gamle skal glede sig sammen, og jeg vil vende deres klage til fryd og trøste dem og glede dem efter deres sorg. 
24:31:14: Og jeg vil vederkvege prestene med det fete, og mitt folk skal mettes med mine gode ting, sier Herren. 
24:31:15: Så sier Herren: En røst høres i Rama, veklage, bitter gråt; Rakel gråter over sine barn; hun vil ikke la sig trøste over sine barn, for de er ikke mere til. 
24:31:16: Så sier Herren: Hold op med din gråt, og la ikke dine øine felle tårer mere! Du skal få lønn for ditt arbeid, sier Herren, og de skal vende tilbake fra fiendens land. 
24:31:17: Og det er håp for din fremtid, sier Herren, og barna skal vende tilbake til sitt land. 
24:31:18: Jeg har hørt Efra'im klage: Du har tuktet mig, ja, jeg blev tuktet som en utemmet kalv; omvend mig du, så blir jeg omvendt, du er jo Herren min Gud. 
24:31:19: For efterat jeg har vendt mig bort fra dig, angrer jeg, og efterat jeg har fått forstand, slår jeg mig på lenden; jeg blues, ja, jeg skammer mig, for jeg bærer min ungdoms skam. 
24:31:20: Er da Efra'im en så dyrebar sønn for mig eller mitt kjæreste barn, siden jeg ennu må komme ham i hu, enda jeg så ofte har talt mot ham? Derfor røres mitt hjerte av medynk med ham, jeg må forbarme mig over ham, sier Herren. 
24:31:21: Reis dig merkestener, sett dig veivisere, gi akt på den banede vei, den vei du gikk! Vend tilbake, du jomfru, Israel, vend tilbake til dine byer her! 
24:31:22: Hvor lenge vil du vanke omkring, du frafalne datter? For Herren skaper noget nytt i landet: Kvinnen* skal stadig være om mannen**. # <* Israel.> # <** Herren.> 
24:31:23: Så sier Herren, hærskarenes Gud, Israels Gud: Ennu en gang skal de si så i Juda land og i dets byer, når jeg gjør ende på deres fangenskap: Herren velsigne dig, du rettferdighets bolig, du hellige berg! 
24:31:24: Og Juda med alle sine byer skal bo der sammen som jordbrukere og omvandrende hyrder. 
24:31:25: For jeg vil kvege den trette sjel, og hver vansmektende sjel vil jeg mette. 
24:31:26: Ved dette våknet jeg og så mig om, og jeg fant at min søvn hadde vært herlig. 
24:31:27: Se, dager kommer, sier Herren, da jeg vil tilså Israels land og Judas land med menneskers sæd og med dyrs sæd. 
24:31:28: Og likesom jeg har våket over dem for å rykke op og for å rive og bryte ned og ødelegge og plage dem, således vil jeg også våke over dem for å bygge og plante, sier Herren. 
24:31:29: I de dager skal de ikke mere si: Fedrene åt sure druer, og barnas tenner blev såre. 
24:31:30: Men enhver skal dø for sin egen misgjernings skyld; hvert menneske som eter sure druer, han skal selv få såre tenner. 
24:31:31: Se, dager kommer, sier Herren, da jeg vil oprette en ny pakt med Israels hus og med Judas hus, 
24:31:32: ikke som den pakt jeg oprettet med deres fedre på den dag da jeg tok dem ved hånden for å føre dem ut av Egyptens land, den pakt med mig som de brøt, enda jeg var deres ektemann, sier Herren; 
24:31:33: men dette er den pakt jeg vil oprette med Israels hus efter de dager, sier Herren: Jeg vil gi min lov i deres sinn og skrive den i deres hjerte, og jeg vil være deres Gud, og de skal være mitt folk; 
24:31:34: og de skal ikke mere lære hver sin næste og hver sin bror og si: Kjenn Herren! For de skal alle kjenne mig, både små og store, sier Herren; for jeg vil forlate deres misgjerning og ikke mere komme deres synd i hu. 
24:31:35: Så sier Herren, som satte solen til lys om dagen og lover for månen og stjernene, så de lyser om natten, han som oprører havet så dets bølger bruser - Herren, hærskarenes Gud, er hans navn: 
24:31:36: Dersom disse lover ikke lenger står ved makt for mitt åsyn, sier Herren, da skal også Israels ætt ophøre å være et folk for mitt åsyn alle dager. 
24:31:37: Så sier Herren: Dersom himmelen kan måles der oppe, og jordens grunnvoller utforskes der nede, da vil også jeg forkaste all Israels ætt for alt det de har gjort, sier Herren. 
24:31:38: Se, dager kommer, sier Herren, da byen skal bygges for Herren, fra Hananels tårn like til Hjørneporten, 
24:31:39: og målesnoren skal gå videre rett frem over Gareb-haugen, og så skal den vende sig til Goa, 
24:31:40: og hele dalen med de døde kropper og asken, og alle markene like til Kedrons bekk, til Hesteportens hjørne mot øst, skal være hellige for Herren. Der skal aldri mere rykkes noget op eller brytes noget ned. 
24:32:1: Dette er det ord som kom til Jeremias fra Herren i Judas konge Sedekias' tiende år, det er Nebukadnesars attende år. 
24:32:2: Dengang holdt Babels konges hær Jerusalem kringsatt, og profeten Jeremias var innestengt i vaktgården i Judas konges hus; 
24:32:3: der hadde Judas konge Sedekias stengt ham inne med de ord: Hvorfor profeterer du og sier: Så sier Herren: Se, jeg gir denne by i Babels konges hånd, og han skal innta den, 
24:32:4: og Judas konge Sedekias skal ikke undkomme av kaldeernes hånd, men han skal overgis i Babels konges hånd, og han skal tale med ham munn til munn og se ham øie til øie, 
24:32:5: og han skal føre Sedekias til Babel, og der skal han være til jeg tar mig av ham, sier Herren; når I strider mot kaldeerne, skal I ikke ha lykken med eder. 
24:32:6: Og Jeremias sa: Herrens ord er kommet til mig, og det lød så: 
24:32:7: Se, Hanamel, sønn av din farbror Sallum, kommer til dig og sier: Kjøp min mark i Anatot! For du har innløsningsrett, så du kan kjøpe den. 
24:32:8: Og Hanamel, min farbrors sønn, kom til mig i vaktgården efter Herrens ord og sa: Kjøp min mark i Anatot, i Benjamins land! For du har odelsretten, og du har plikten til å løse den inn. Kjøp den! Da forstod jeg at det var Herrens ord. 
24:32:9: Og jeg kjøpte marken av Hanamel, min farbrors sønn, i Anatot, og jeg veide ut sølvet til ham, sytten sekel sølv. 
24:32:10: Og jeg skrev det i et brev og forseglet det og tok vidner, og jeg veide sølvet i vektskåler. 
24:32:11: Så tok jeg kjøpebrevet, både det forseglede med avtalen og vilkårene, og det åpne, 
24:32:12: og jeg overgav kjøpebrevet til Baruk, sønn av Nerija, sønn av Mahseja, i nærvær av min frende Hanamel og de vidner som hadde skrevet sine navn i kjøpebrevet, og alle de jøder som satt i vaktgården. 
24:32:13: Og i deres nærvær gav jeg Baruk dette pålegg: 
24:32:14: Så sier Herren, hærskarenes Gud, Israels Gud: Ta disse brev, dette forseglede kjøpebrev og dette åpne brev, og legg dem i et lerkar, så de kan holde sig lang tid! 
24:32:15: For så sier Herren, hærskarenes Gud, Israels Gud: Ennu en gang skal det kjøpes hus og marker og vingårder i dette land. 
24:32:16: Og da jeg hadde overgitt kjøpebrevet til Baruk, Nerijas sønn, bad jeg til Herren og sa: 
24:32:17: Akk, Herre, Herre! Se, du er den som har gjort himmelen og jorden ved din store kraft og ved din utrakte arm; ingen ting er for vanskelig for dig, 
24:32:18: du som gjør miskunnhet imot tusen ledd og gjengjelder fedrenes misgjerning på deres barn efter dem, du store, du veldige Gud, hvis navn er Herren, hærskarenes Gud, 
24:32:19: du som er stor i råd og mektig i gjerning, du hvis øine er oplatt over alle menneskebarns veier for å gi enhver efter hans ferd og efter hans gjerningers frukt, 
24:32:20: du som gjorde tegn og under i Egyptens land og helt til denne dag, både med Israel og med andre mennesker, og gjorde dig et navn, således som det sees på denne dag, 
24:32:21: og førte ditt folk Israel ut av Egyptens land ved tegn og under og med sterk hånd og utrakt arm og med stor forferdelse 
24:32:22: og gav dem dette land som du hadde svoret deres fedre at du vilde gi dem, et land som flyter med melk og honning. 
24:32:23: Og de kom og tok det i eie, men de hørte ikke på din røst og levde ikke efter dine lover; alt det du hadde pålagt dem å gjøre, gjorde de ikke. Så har du da latt all denne ulykke komme over dem. 
24:32:24: Se, fiendenes voller er nådd helt frem til byen, så de kan innta den, og ved sverd, hunger og pest er byen gitt i hendene på kaldeerne, som strider mot den, og det som du har talt, er skjedd; du ser det selv. 
24:32:25: Og allikevel, enda byen er gitt i kaldeernes hånd, har du, Herre, Herre, sagt til mig: Kjøp marken for penger og ta vidner på det! 
24:32:26: Da kom Herrens ord til Jeremias, og det lød så: 
24:32:27: Se, jeg er Herren, alt kjøds Gud; skulde nogen ting være for vanskelig for mig? 
24:32:28: Derfor sier Herren så: Se, jeg gir denne by i kaldeernes hånd og i Babels konge Nebukadnesars hånd, og han skal innta den. 
24:32:29: Og kaldeerne, som strider mot denne by, skal komme og tende ild på den og opbrenne den og de hus på hvis tak det har vært brent røkelse for Ba'al og utøst drikkoffer for andre guder for å vekke min harme. 
24:32:30: For helt fra sin ungdom av har Israels barn og Judas barn bare gjort det som er ondt i mine øine; Israels barn har ikke gjort annet enn å vekke min harme ved sine henders verk, sier Herren. 
24:32:31: For til vrede og harme har denne by vært mig like fra den dag da de bygget den, og til denne dag, så jeg må ta den bort fra mitt åsyn 
24:32:32: for all den ondskap som Israels barn og Judas barn har gjort for å vekke min harme, de, deres konger, deres høvdinger, deres prester og deres profeter, Judas menn og Jerusalems innbyggere. 
24:32:33: De vendte ryggen og ikke ansiktet til mig; og jeg lærte dem tidlig og sent, men de hørte ikke på mig og tok ikke imot tukt. 
24:32:34: Men de satte sine vederstyggelige ting i det hus som er kalt med mitt navn, og gjorde det urent. 
24:32:35: Og de bygget offerhaugene for Ba'al i Hinnoms sønns dal for å la sine sønner og døtre gå gjennem ilden for Molok, enda jeg ikke hadde pålagt dem det, og det ikke var opkommet i mitt hjerte at de skulde gjøre denne vederstyggelighet; og således fikk de Juda til å synde. 
24:32:36: Men derfor* sier Herren, Israels Gud, nu så om denne by om hvilken I sier: Den er gitt i Babels konges hånd ved sverd og hunger og pest: # <* JER 32, 27.> 
24:32:37: Se, jeg samler dem fra alle de land som jeg drev dem bort til i min vrede og harme og min store fortørnelse, og jeg vil føre dem tilbake til dette sted og la dem bo trygt. 
24:32:38: Og de skal være mitt folk, og jeg vil være deres Gud. 
24:32:39: Og jeg vil gi dem ett hjerte og lære dem en vei til å frykte mig alle dager, så det må gå dem vel, dem og deres barn efter dem. 
24:32:40: Og jeg vil oprette en evig pakt med dem at jeg ikke vil dra mig tilbake fra dem, men gjøre vel imot dem, og frykt for mig vil jeg legge i deres hjerte, så de ikke skal gå bort fra mig. 
24:32:41: Og jeg vil glede mig over dem og gjøre vel imot dem, og jeg vil plante dem i dette land i trofasthet, av hele mitt hjerte og av hele min sjel. 
24:32:42: For så sier Herren: Likesom jeg har latt all denne store ulykke komme over dette folk, således vil jeg også la alt det gode komme over dem som jeg har lovt dem. 
24:32:43: Og det skal kjøpes marker i dette land om hvilket I sier: Det er en ørken, uten mennesker og dyr, det er gitt i kaldeernes hånd. 
24:32:44: De skal kjøpe marker for penger og skrive kjøpebrev og forsegle dem og ta vidner, i Benjamins land og i landet omkring Jerusalem og i Judas byer, både byene i fjellbygdene og byene i lavlandet og byene i sydlandet; for jeg vil gjøre ende på deres fangenskap, sier Herren. 
24:33:1: Og Herrens ord kom til Jeremias annen gang, mens han ennu satt fengslet i vaktgården, og det lød så: 
24:33:2: Så sier Herren, som gjør det han vil, Herren som uttenker det for også å fullføre det, han hvis navn er Herren: 
24:33:3: Rop til mig, og jeg vil svare dig, og jeg vil forkynne dig store og ufattelige ting, ting som du ikke kjenner. 
24:33:4: For så sier Herren, Israels Gud, om husene i denne by og om husene til Judas konge, som er revet ned til forsvar mot fiendens voller og sverd: 
24:33:5: De kommer for å stride mot kaldeerne; men det blir bare til å fylle husene med døde kropper av de menn som jeg slår i min vrede og harme, og som ved sin store ondskap har voldt at jeg har skjult mitt åsyn for denne by. 
24:33:6: Se, jeg legger forbinding og lægedom på den og læger dem, og jeg vil la dem se en overflod av fred og trygghet. 
24:33:7: Og jeg vil gjøre ende på Judas fangenskap og Israels fangenskap, og jeg vil bygge dem op som i den første tid. 
24:33:8: Og jeg vil rense dem fra all deres misgjerning, hvormed de har syndet mot mig, og jeg vil forlate alle deres misgjerninger, hvormed de har syndet og forbrutt sig mot mig. 
24:33:9: Og byen skal være mig til et gledesnavn, til pris og pryd for alle jordens folkeslag, når de får høre om alt det gode jeg gjør imot den, og de skal skjelve og beve når de får se alt det gode og all den fred jeg gir den. 
24:33:10: Så sier Herren: På dette sted om hvilket I sier: Det er øde, uten mennesker og uten fe - her i Judas byer og på Jerusalems gater, som ligger øde, uten mennesker og uten innbyggere og uten fe, skal det ennu en gang høres 
24:33:11: fryds røst og gledes røst, brudgoms røst og bruds røst, rop av dem som sier: Lov Herren, hærskarenes Gud, for Herren er god, hans miskunnhet varer evindelig - dem som bærer frem takkoffer i Herrens hus. For jeg vil gjøre ende på fangenskapet, så landet blir som i den første tid, sier Herren. 
24:33:12: Så sier Herren, hærskarenes Gud: Ennu en gang skal det på dette sted som er øde, både uten mennesker og uten fe, og i alle dets byer være et tilhold for hyrder som lar fårene hvile der. 
24:33:13: I byene i fjellbygdene, i byene i lavlandet og i byene i sydlandet og i Benjamins land og i landet omkring Jerusalem og i Judas byer skal fårene ennu en gang gå forbi den som teller dem, sier Herren. 
24:33:14: Se, dager kommer, sier Herren, da jeg vil opfylle det gode ord jeg har talt om Israels hus og om Judas hus. 
24:33:15: I de dager og på den tid vil jeg la spire frem for David en rettferdig spire, og han skal gjøre rett og rettferdighet i landet. 
24:33:16: I de dager skal Juda bli frelst og Jerusalem bo trygt, og dette er det navn det skal kalles med: Herren, vår rettferdighet. 
24:33:17: For så sier Herren: Det skal aldri fattes en mann av Davids ætt som skal sitte på Israels trone, 
24:33:18: og av de levittiske presters ætt skal det aldri fattes en mann for mitt åsyn som ofrer brennoffer og brenner matoffer og bærer frem slaktoffer alle dager. 
24:33:19: Og Herrens ord kom til Jeremias, og det lød så: 
24:33:20: Så sier Herren: Dersom I kan bryte min pakt med dagen og min pakt med natten, så det ikke blir dag og natt i sin tid, 
24:33:21: da skal også min pakt med min tjener David bli brutt, så han ikke får nogen sønn som blir konge og sitter på hans trone, og min pakt med mine tjenere de levittiske prester. 
24:33:22: Likesom himmelens hær ikke kan telles og havets sand ikke måles, således vil jeg gjøre min tjener Davids ætt og levittene som tjener mig, tallrike. 
24:33:23: Og Herrens ord kom til Jeremias, og det lød så: 
24:33:24: Har du ikke gitt akt på hvad dette folk har talt og sagt: De to slekter som Herren hadde utvalgt, dem forkastet han? Og mitt folk forakter de, så det ikke mere er noget folk i deres øine. 
24:33:25: Så sier Herren: Dersom jeg ikke har oprettet min pakt med dag og natt, ikke gitt lover for himmel og jord, 
24:33:26: så vil jeg også forkaste Jakobs og min tjener Davids ætt, så jeg ikke av hans ætt tar nogen til å herske over Abrahams, Isaks og Jakobs ætt; for jeg vil gjøre ende på deres fangenskap og forbarme mig over dem. 
24:34:1: Dette er det ord som kom til Jeremias fra Herren mens Babels konge Nebukadnesar stred mot Jerusalem og alle dets byer, han og hele hans hær og alle jordens riker som stod under hans herredømme, og alle folkeslagene: 
24:34:2: Så sier Herren, Israels Gud: Gå og tal til Judas konge Sedekias og si til ham: Så sier Herren: Se, jeg gir denne by i Babels konges hånd, og han skal brenne den op med ild. 
24:34:3: Og du selv skal ikke undkomme av hans hånd, men du skal gripes og gis i hans hånd, og dine øine skal se Babels konges øine, og hans munn skal tale med din munn, og til Babel skal du komme. 
24:34:4: Men hør Herrens ord, Sedekias, Judas konge! Så sier Herren om dig: Du skal ikke dø for sverd. 
24:34:5: I fred skal du dø, og likesom de brente bål til ære for dine fedre, de forrige konger, de som var før dig, således skal de også brenne for dig, og de skal holde sørgehøitid over dig og rope: Ve, herre! For et ord har jeg talt, sier Herren. 
24:34:6: Og profeten Jeremias talte alle disse ord til Judas konge Sedekias i Jerusalem 
24:34:7: mens Babels konges hær stred mot Jerusalem og mot alle Judas byer som ennu var igjen, Lakis og Aseka; for det var de faste byer som ennu var igjen av Judas byer. 
24:34:8: Dette er det ord som kom til Jeremias fra Herren efterat kong Sedekias hadde gjort en pakt med alt folket i Jerusalem om å rope ut frihet, 
24:34:9: så at enhver skulde gi sin træl og sin trælkvinne fri, om de var hebreere, så ikke nogen skulde holde sin jødiske bror i trældom. 
24:34:10: Da adlød alle høvdingene og alt folket som hadde inngått den pakt at enhver skulde gi sin træl og sin trælkvinne fri og ikke mere holde dem i trældom; de adlød og gav dem fri. 
24:34:11: Men siden gjorde de det om igjen; de tok tilbake de træler og trælkvinner som de hadde gitt fri, og tvang dem til å være træler og trælkvinner igjen. 
24:34:12: Da kom Herrens ord til Jeremias - Herren sa: 
24:34:13: Så sier Herren, Israels Gud: Jeg oprettet en pakt med eders fedre den dag da jeg førte dem ut av Egyptens land, av trælehuset, og jeg sa: 
24:34:14: I det syvende år skal I gi fri hver sin bror som har solgt sig til dig, om han er hebreer; han skal tjene dig i seks år, og så skal du la ham gå fri fra dig. Men eders fedre hørte ikke på mig og vendte ikke sitt øre til. 
24:34:15: Men nu hadde I snudd om og gjort det som er rett i mine øine, ved å rope ut frihet, hver for sin næste, og I oprettet en pakt for mitt åsyn i det hus som er kalt med mitt navn. 
24:34:16: Men så snudde I om igjen og vanhelliget mitt navn og tok tilbake hver sin træl og hver sin trælkvinne, som I hadde gitt fri så de kunde råde sig selv, og I tvang dem til å være eders træler og trælkvinner igjen. 
24:34:17: Derfor sier Herren så: I har ikke hørt på mig og ropt ut frihet, hver for sin bror og hver for sin næste; se, jeg roper ut frihet for eder*, sier Herren, så I overgis til sverdet, pesten og hungeren, og jeg lar eder bli mishandlet av alle jordens riker. # <* så jeg ikke lenger er eders herre. JER 21, 9; 24, 10.> 
24:34:18: Og jeg gir de menn som overtrådte min pakt, som ikke holdt ordene i den pakt som de oprettet for mitt åsyn, da de skar pakts-kalven i to stykker og så gikk mellem stykkene av den, 
24:34:19: Judas høvdinger og Jerusalems høvdinger, hoffmennene og prestene og alt landets folk, som gikk mellem stykkene av kalven, 
24:34:20: jeg gir dem i deres fienders hånd og i deres hånd som står dem efter livet, og deres døde kropper skal være til føde for himmelens fugler og for jordens dyr. 
24:34:21: Og Judas konge Sedekias og hans høvdinger vil jeg gi i deres fienders hånd og i deres hånd som står dem efter livet, og i hendene på Babels konges hær, som nu har draget bort fra eder*. # <* JER 37, 5.> 
24:34:22: Se, jeg byder, sier Herren, at de skal vende tilbake til denne by, og de skal stride mot den og innta den og brenne den op med ild, og Judas byer vil jeg gjøre til en ørken, der ingen bor. 
24:35:1: Dette er det ord som kom til Jeremias fra Herren i Judas konge Jojakims, Josias' sønns dager: 
24:35:2: Gå til rekabittenes slekt og tal til dem og før dem inn i Herrens hus, i et av kammerne, og gi dem vin å drikke! 
24:35:3: Og jeg tok Ja'asanja, sønn av Jirmeja, sønn av Habasinja, og hans brødre og alle hans sønner og hele rekabittenes slekt, 
24:35:4: og jeg førte dem inn i Herrens hus, i det kammer som tilhørte sønnene til den Guds mann Hanan, Jigdaljas sønn, og som lå ved siden av høvdingenes kammer, ovenover dørvokteren Ma'asejas, Sallums sønns kammer. 
24:35:5: Og jeg satte frem for rekabittenes slekt skåler fulle av vin, og begere, og jeg sa til dem: Drikk vin! 
24:35:6: Men de sa: Vi vil ikke drikke vin; for vår far Jonadab, Rekabs sønn, har gitt oss det bud: I skal aldri drikke vin, hverken I eller eders barn, 
24:35:7: og I skal ikke bygge hus og ikke så korn og ikke plante vingård eller eie noget sådant; men i telt skal I bo alle eders dager, så I kan leve lenge i det land I bor i som fremmede. 
24:35:8: Og vi adlød vår far Jonadab, Rekabs sønn, i alt han bød oss - at vi aldri skulde drikke vin, hverken vi eller våre hustruer eller våre sønner eller våre døtre, 
24:35:9: og ikke bygge oss hus å bo i og ikke ha vingård eller aker og ikke så korn. 
24:35:10: Og vi bodde i telt og var lydige og gjorde alt som vår far Jonadab hadde pålagt oss. 
24:35:11: Men da Babels konge Nebukadnesar drog op mot landet, da sa vi: Kom og la oss dra inn i Jerusalem, så vi kan slippe unda kaldeernes og syrernes hærer! Og så tok vi bolig i Jerusalem. 
24:35:12: Da kom Herrens ord til Jeremias, og det lød så: 
24:35:13: Så sier Herren, hærskarenes Gud, Israels Gud: Gå og si til Judas menn og til Jerusalems innbyggere: Vil I ikke ta imot tukt og lyde mine ord? sier Herren. 
24:35:14: Jonadabs, Rekabs sønns bud er blitt holdt, de som han gav sine barn, at de ikke skulde drikke vin, og like til denne dag har de ikke drukket vin, men lydt sin fars bud. Og jeg har talt til eder tidlig og sent, men I har ikke adlydt mig. 
24:35:15: Og jeg sendte til eder alle mine tjenere, profetene, tidlig og sent, og sa: Vend om, hver fra sin onde vei, og bedre eders gjerninger og følg ikke andre guder for å tjene dem! Så skal I få bo i det land jeg har gitt eder og eders fedre. Men I vendte ikke eders øre til og adlød mig ikke. 
24:35:16: Ja, Jonadabs, Rekabs sønns barn har holdt de bud som deres far gav dem; men dette folk har ikke adlydt mig. 
24:35:17: Derfor sier Herren, hærskarenes Gud, Israels Gud, så: Se, jeg lar komme over Juda og over alle Jerusalems innbyggere all den ulykke jeg har truet dem med, fordi de ikke vilde høre når jeg talte til dem, og ikke svarte når jeg kalte på dem. 
24:35:18: Men til rekabittenes slekt sa Jeremias: Så sier Herren, hærskarenes Gud, Israels Gud: Fordi I har lydt eders far Jonadabs ord og holdt alle hans bud og i alt gjort som han bød eder, 
24:35:19: derfor sier Herren, hærskarenes Gud, Israels Gud, så: Det skal aldri fattes en mann av Jonadabs, Rekabs sønns ætt som skal stå for mitt åsyn. 
24:36:1: I Judas konge Jojakims, Josias' sønns fjerde år kom dette ord til Jeremias fra Herren: 
24:36:2: Ta dig en bokrull og skriv i den alle de ord jeg har talt til dig om Israel og Juda og alle hedningefolkene fra den dag da jeg først talte til dig, fra Josias' dager og like til denne dag! 
24:36:3: Kanskje Judas hus vil merke sig all den ulykke jeg tenker å gjøre dem, så de kan vende om, hver fra sin onde vei, og jeg kan forlate dem deres misgjerning og deres synd. 
24:36:4: Da kalte Jeremias til sig Baruk, Nerijas sønn, og Baruk skrev efter Jeremias' munn alle de ord som Herren hadde talt til ham, i en bokrull. 
24:36:5: Og Jeremias talte til Baruk og sa: Jeg er forhindret, jeg kan ikke komme til Herrens hus. 
24:36:6: Men gå du dit og les av rullen som du har skrevet efter min munn, Herrens ord op for folket i Herrens hus på en fastedag! Også for hele Juda, som kommer fra sine byer, skal du lese den. 
24:36:7: Kanskje de vil legge sin ydmyke bønn frem for Herrens åsyn og vende om, hver fra sin onde vei; for stor er den vrede og harme som Herren har uttalt over dette folk. 
24:36:8: Og Baruk, Nerijas sønn, gjorde alt som profeten Jeremias hadde pålagt ham, og leste Herrens ord op av boken i Herrens hus. 
24:36:9: For i Judas konge Jojakims, Josias' sønns femte år, i den niende måned, blev det ropt ut at alt folket i Jerusalem og alt folket som var kommet fra Judas byer til Jerusalem, skulde faste for Herrens åsyn. 
24:36:10: Da leste Baruk op av boken Jeremias' ord i Herrens hus, i statsskriveren Gemarjas, Safans sønns kammer i den øvre forgård, ved inngangen til den nye port i Herrens hus, mens alt folket hørte på. 
24:36:11: Da Mikaja, sønn av Gemarja, sønn av Safan, hørte alle Herrens ord bli lest op av boken, 
24:36:12: gikk han ned til kongens hus, til statsskriverens kammer, og der fant han alle høvdingene sittende: statsskriveren Elisama og Delaja, Sjemajas sønn, og Elnatan, Akbors sønn, og Gemarja, Safans sønn, og Sedekias, Hananjas sønn, og alle de andre høvdinger. 
24:36:13: Og Mikaja fortalte dem om alle de ord han hadde hørt da Baruk leste op av boken for folket. 
24:36:14: Da sendte alle høvdingene Jehudi, sønn av Netanja, sønn av Selemja, sønn av Kusi, til Baruk og sa: Ta med dig rullen som du leste op av for folket, og kom hit! Og Baruk, Nerijas sønn, tok rullen med sig og kom til dem. 
24:36:15: De sa til ham: Sett dig ned og les den for oss! Og Baruk leste den for dem. 
24:36:16: Og da de hørte alle ordene, vendte de sig forferdet til hverandre, og de sa til Baruk: Vi må melde kongen alt dette. 
24:36:17: Så spurte de Baruk: Fortell oss hvorledes du skrev alle disse ord efter hans munn! 
24:36:18: Baruk svarte: Med sin munn foresa han mig alle disse ord, og jeg skrev dem i boken med blekk. 
24:36:19: Da sa høvdingene til Baruk: Gå og skjul dig, du og Jeremias, og la ingen vite hvor I er! 
24:36:20: Så gikk de inn til kongen i forgården, men rullen la de ned i statsskriveren Elisamas kammer; og de fortalte alt dette til kongen. 
24:36:21: Da sendte kongen Jehudi avsted for å hente rullen, og han hentet den fra statsskriveren Elisamas kammer, og Jehudi leste den op for kongen og alle høvdingene som stod hos kongen. 
24:36:22: Kongen satt i vinterhuset, for det var den niende måned, og det brente ild i et fyrfat foran ham, 
24:36:23: og da Jehudi hadde lest op tre eller fire blad, skar kongen rullen i stykker med en pennekniv og kastet den på ilden i fyrfatet, inntil hele rullen var fortært av ilden der i fyrfatet. 
24:36:24: Og de forferdedes ikke og sønderrev ikke sine klær, hverken kongen eller nogen av hans tjenere som hørte alle disse ord, 
24:36:25: og skjønt Elnatan og Delaja og Gemarja inntrengende bad kongen at han ikke skulde brenne rullen, hørte han ikke på dem. 
24:36:26: Så bød kongen Jerahme'el, kongesønnen, og Seraja, Asriels sønn, og Selemja, Abde'els sønn, å gripe skriveren Baruk og profeten Jeremias; men Herren skjulte dem. 
24:36:27: Da kom Herrens ord til Jeremias, efterat kongen hadde brent op rullen med de ord Baruk hadde skrevet efter Jeremias' munn: 
24:36:28: Ta dig en annen rull og skriv i den alle de forrige ord, de som stod i den forrige rull, som Judas konge Jojakim brente op! 
24:36:29: Og om Judas konge Jojakim skal du si: Så sier Herren: Du brente denne rull og sa: Hvorfor skrev du i den således: Babels konge skal komme og ødelegge dette land og utrydde mennesker og dyr av det? 
24:36:30: Derfor sier Herren så om Judas konge Jojakim: Han skal ikke ha nogen ætling som skal sitte på Davids trone, og hans døde kropp skal ligge slengt bort, utsatt for heten om dagen og for kulden om natten. 
24:36:31: Og jeg vil hjemsøke ham og hans ætt og hans tjenere for deres misgjerning, og jeg vil la komme over dem og over Jerusalems innbyggere og over Judas menn all den ulykke jeg har forkynt dem uten at de vilde høre. 
24:36:32: Da tok Jeremias en annen rull og gav den til skriveren Baruk, Nerijas sønn, og han skrev i den efter Jeremias' munn alle ordene i den bok som Judas konge Jojakim hadde brent i ilden, og det blev til dem ennu lagt mange lignende ord. 
24:37:1: Og Sedekias, Josias' sønn, blev konge efter Konja*, Jojakims sønn for Babels konge Nebukadnesar hadde satt ham til konge i Juda land. # <* JER 22, 24.> 
24:37:2: Men han og hans tjenere og landets folk hørte ikke på Herrens ord, som han talte ved profeten Jeremias. 
24:37:3: Og kong Sedekias sendte Jehukal, Selemjas sønn, og presten Sefanja, Ma'asejas sønn, til profeten Jeremias og lot si: Bed for oss til Herren vår Gud! 
24:37:4: Jeremias gikk dengang inn og ut blandt folket; de hadde ennu ikke satt ham i fengsel. 
24:37:5: Og Faraos hær hadde da draget ut fra Egypten, og da kaldeerne, som holdt Jerusalem kringsatt, spurte det, drog de bort fra Jerusalem. 
24:37:6: Da kom Herrens ord til profeten Jeremias, og det lød så: 
24:37:7: Så sier Herren, Israels Gud: Så skal I si til Judas konge, som sendte eder til mig for å spørre mig: Se, Faraos hær, som har draget ut for å komme eder til hjelp, skal vende tilbake til sitt land, Egypten, 
24:37:8: og kaldeerne skal komme igjen og stride mot denne by, og de skal innta den og brenne den op med ild. 
24:37:9: Så sier Herren: I må ikke dåre eder selv og si: Kaldeerne skal visselig dra bort fra oss! For de skal ikke dra bort; 
24:37:10: om I så slo hele kaldeernes hær som strider mot eder, og det bare blev nogen hårdt sårede tilbake iblandt dem, så skulde disse stå op, hver i sitt telt, og brenne denne by op med ild. 
24:37:11: Og da kaldeernes hær hadde draget bort fra Jerusalem for Faraos hærs skyld, 
24:37:12: da gikk Jeremias ut av Jerusalem midt iblandt folket for å gå til Benjamins land og hente sin arvelodd derfra. 
24:37:13: Og da han var i Benjamin-porten, så stod der en høvedsmann for vakten ved navn Jerija, sønn av Selemja, sønn av Hananja; han grep fatt i profeten Jeremias og sa: Du vil gå over til kaldeerne. 
24:37:14: Da sa Jeremias: Det er løgn; jeg vil ikke gå over til kaldeerne. Men Jerija hørte ikke på Jeremias, han grep ham og førte ham til høvdingene. 
24:37:15: Da blev høvdingene vrede på Jeremias og slo ham, og de satte ham i fangehuset, i statsskriveren Jonatans hus; for det hadde de gjort til fengsel. 
24:37:16: Da Jeremias var kommet i fangehullet, i kjelleren, og hadde sittet der i mange dager, 
24:37:17: sendte kong Sedekias bud og lot ham hente, og kongen spurte ham i sitt hus i lønndom og sa: Er det noget ord fra Herren? Jeremias svarte: Ja, det er. Og så sa han: Du skal bli gitt i Babels konges hånd. 
24:37:18: Derefter sa Jeremias til kong Sedekias: Hvad har jeg syndet mot dig og dine tjenere og dette folk, siden I har satt mig i fengsel? 
24:37:19: Og hvor er nu eders profeter, de som profeterte for eder og sa: Babels konge skal ikke komme over eder og over dette land? 
24:37:20: Og hør nu, herre konge! La min ydmyke bønn bæres frem for ditt åsyn og send mig ikke tilbake til statsskriveren Jonatans hus, forat jeg ikke skal dø der! 
24:37:21: På kong Sedekias' bud holdt de Jeremias i varetekt i vaktgården og gav ham et brød til hver dag fra bakernes gate, inntil alt brødet i byen var fortært. Og Jeremias blev sittende i vaktgården. 
24:38:1: Men Sefatja, Mattans sønn, og Gedalja, Pashurs sønn, og Jukal, Selemjas sønn, og Pashur, Malkias sønn, hørte de ord som Jeremias talte til alt folket: 
24:38:2: Så sier Herren: Den som blir i denne by, skal dø ved sverd, ved hunger og ved pest; men den som går ut til kaldeerne, skal bli i live, han skal få sitt liv til krigsbytte og leve. 
24:38:3: Så sier Herren: Denne by skal visselig gis i hendene på Babels konges hær, og han skal innta den. 
24:38:4: Da sa høvdingene til kongen: La denne mann drepe, fordi han får de stridsmenn som er blitt tilbake i denne by, og alt folket til å la hendene synke ved at han taler slike ord til dem! For denne mann søker ikke folkets vel, han søker dets ulykke. 
24:38:5: Da sa kong Sedekias: Se, han er i eders hånd; for kongen makter ikke noget mot eder. 
24:38:6: Da tok de Jeremias og kastet ham i kongesønnen Malkias brønn, som var i vaktgården, og de firte Jeremias ned med rep; i brønnen var ikke vann, men bare dynd, og Jeremias sank ned i dyndet. 
24:38:7: Men etioperen Ebed-Melek, en gilding som tjente i kongens hus, hørte at de hadde kastet Jeremias i brønnen - kongen satt da i Benjamin-porten. 
24:38:8: Så gikk Ebed-Melek ut av kongens hus og talte til kongen og sa: 
24:38:9: Herre konge! Disse menn har gjort ille i alt det de har gjort mot profeten Jeremias, som de har kastet i brønnen, og han dør der han er, av sult; for det er ikke brød i byen mere. 
24:38:10: Da bød kongen etioperen Ebed-Melek: Ta tretti menn med dig herfra og dra profeten Jeremias op av brønnen før han dør! 
24:38:11: Og Ebed-Melek tok mennene med sig og gikk inn i kongens hus, ned i rummet under forrådskammeret, og tok derfra filler av sønderrevne og utslitte klær og firte dem ned til Jeremias i brønnen med rep. 
24:38:12: Og etioperen Ebed-Melek sa til Jeremias: Legg fillene av de sønderrevne og utslitte klær under dine armer, under repene! Og Jeremias gjorde så. 
24:38:13: Så drog de Jeremias op med repene og fikk ham op av brønnen, og Jeremias blev i vaktgården. 
24:38:14: Derefter sendte kong Sedekias bud og hentet profeten Jeremias til sig i den tredje inngang i Herrens hus, og kongen sa til Jeremias: Jeg vil spørre dig om noget; dølg intet for mig! 
24:38:15: Jeremias sa til Sedekias: Om jeg sier dig det, vil du da ikke la mig drepe? Og om jeg gir dig råd, vil du ikke høre på mig. 
24:38:16: Da svor kong Sedekias Jeremias en ed i lønndom og sa: Så sant Herren lever, han som har gitt oss dette vårt liv, vil jeg ikke drepe dig og ikke gi dig i hendene på disse menn som står dig efter livet. 
24:38:17: Da sa Jeremias til Sedekias: Så sier Herren, hærskarenes Gud, Israels Gud: Dersom du går ut til Babels konges høvdinger, så skal du leve, og denne by skal ikke bli brent op med ild, og du skal leve, du og ditt hus. 
24:38:18: Men dersom du ikke går ut til Babels konges høvdinger, så skal denne by bli gitt i kaldeernes hånd, og de skal brenne den op med ild, og du skal ikke undkomme av deres hånd. 
24:38:19: Men kong Sedekias sa til Jeremias: Jeg er redd for de jøder som er gått over til kaldeerne, at jeg skal bli overgitt i deres hånd, og at de skal fare ille med mig. 
24:38:20: Men Jeremias sa: Du skal ikke bli overgitt til dem; hør bare på Herrens røst i det som jeg taler til dig, så det må gå dig vel, og du må leve! 
24:38:21: Men undslår du dig for å gå ut, da er dette det ord Herren har latt mig se: 
24:38:22: Se, alle de kvinner som er blitt tilbake i Judas konges hus, skal bli ført ut til Babels konges høvdinger, og de skal si til dig: Dine gode venner har lokket dig og fått overhånd over dig; da dine føtter sank ned i skarnet, trakk de sig tilbake. 
24:38:23: Og alle dine hustruer og dine barn skal bli ført ut til kaldeerne, og du skal ikke undkomme av deres hånd; men ved Babels konges hånd skal du bli grepet, og for din skyld skal denne by bli brent op med ild. 
24:38:24: Og Sedekias sa til Jeremias: La ikke nogen få vite om denne samtale, ellers skal du dø! 
24:38:25: Og om høvdingene får høre at jeg har talt med dig, og de så kommer til dig og sier: Fortell oss hvad du har sagt til kongen! Dølg det ikke for oss, ellers vil vi drepe dig! Hvad har kongen sagt til dig? 
24:38:26: da skal du si til dem: Jeg bar min ydmyke bønn frem for kongens åsyn; Jeg bad at han ikke måtte føre mig tilbake til Jonatans hus for å dø der. 
24:38:27: Og da alle høvdingene kom til Jeremias og spurte ham, svarte han dem aldeles som kongen hadde befalt. Og de tidde og lot ham være i fred; for saken var ikke blitt kjent. 
24:38:28: Og Jeremias blev i vaktgården like til den dag da Jerusalem blev inntatt. 
24:39:1: Da Jerusalem var inntatt - i Judas konge Sedekias' niende år, i den tiende måned, kom Babels konge Nebukadnesar og hele hans hær til Jerusalem og kringsatte byen; 
24:39:2: i Sedekias' ellevte år, i den fjerde måned, på den niende dag i måneden, brøt de inn i byen - 
24:39:3: da kom alle Babels konges stormenn inn og satte sig i Midtporten, Nergal-Sareser, Samgar-Nebu, Sarsekim, den øverste hoffmann, Nergal-Sareser, den øverste av magerne, og alle de andre stormenn hos Babels konge. 
24:39:4: Men da Judas konge Sedekias og alle krigsmennene så dem, flyktet de og drog om natten ut av byen på veien til kongens have gjennem porten mellem begge murene; og kongen tok veien ut til ødemarken. 
24:39:5: Og kaldeernes hær satte efter dem og nådde Sedekias igjen på Jerikos ødemarker, og de tok ham og førte ham op til Babels konge Nebukadnesar i Ribla i Hamat-landet, og han avsa dom over ham. 
24:39:6: Og Babels konge drepte Sedekias' sønner i Ribla for hans øine; likeså drepte Babels konge alle de fornemme i Juda. 
24:39:7: Og Sedekias lot han blinde, og han lot ham binde med to kobberlenker for å føre ham til Babel. 
24:39:8: Og kongens hus og de hus som hørte folket til, brente kaldeerne op med ild, og Jerusalems murer rev de ned. 
24:39:9: Og resten av folket, dem som var blitt tilbake i byen, og overløperne som var gått over til ham, og resten av folket, dem som var blitt tilbake, dem bortførte Nebusaradan, høvdingen over livvakten, til Babel. 
24:39:10: Bare nogen av almuen, som ikke eide noget, lot Nebusaradan, høvdingen over livvakten, bli tilbake i Juda land, og på samme tid gav han dem vingårder og akrer. 
24:39:11: Og Babels konge Nebukadnesar gav Nebusaradan, høvdingen over livvakten, befaling om Jeremias og sa: 
24:39:12: Ta ham og la ditt øie våke over ham og gjør ham ikke noget ondt! Gjør med ham således som han selv sier til dig! 
24:39:13: Da sendte Nebusaradan, høvdingen over livvakten, og Nebusasban, den øverste av hoffmennene, og Nergal-Sareser, den øverste av magerne, og alle Babels konges stormenn, 
24:39:14: de sendte bud og tok Jeremias ut av vaktgården, og de overgav ham til Gedalja, sønn av Akikam, Safans sønn, forat han skulde føre ham ut til sitt hus; og han blev boende der blandt folket. 
24:39:15: Men til Jeremias var Herrens ord kommet da han satt fast i vaktgården, og det lød så: 
24:39:16: Gå og si til etioperen Ebed-Melek: Så sier Herren, hærskarenes Gud, Israels Gud: Se, jeg lar mine ord komme over denne by til det onde og ikke til det gode, og de skal bli opfylt for dine øine på den dag. 
24:39:17: Men jeg vil redde dig på den dag, sier Herren, og du skal ikke bli overgitt i de menns hånd som du gruer for. 
24:39:18: For jeg vil la dig undkomme, og du skal ikke falle for sverdet, men du skal få ditt liv til krigsbytte, fordi du satte din lit til mig, sier Herren. 
24:40:1: Dette er det ord som kom til Jeremias fra Herren efterat Nebusaradan, høvdingen over livvakten, hadde løslatt ham fra Rama, da han hentet ham mens han var bundet med lenker midt iblandt alle de fanger fra Jerusalem og Juda som blev bortført til Babel. 
24:40:2: Høvdingen over livvakten hentet Jeremias og sa til ham: Herren din Gud har forkynt denne ulykke mot dette sted. 
24:40:3: Og Herren lot det komme og gjorde som han hadde talt; for I hadde syndet mot Herren og ikke hørt på hans røst, og så hendte dette eder. 
24:40:4: Og se, nu har jeg idag løst dig av lenkene om din hånd. Er det godt i dine øine å komme med mig til Babel, så kom, og jeg vil la mitt øie våke over dig. Men er det ikke godt i dine øine å komme med mig til Babel, så la det være! Se, hele landet ligger for dig; hvor det er godt og rett i dine øine å gå, dit kan du gå. 
24:40:5: Og da han ennu ikke vendte sig til nogen kant, sa han: Så vend tilbake til Gedalja, sønn av Akikam, Safans sønn, som Babels konge har satt over Judas byer, og bli hos ham midt iblandt folket, eller gå hvor som helst det er rett i dine øine å gå! Og høvdingen over livvakten gav ham mat og gaver og lot ham fare. 
24:40:6: Og Jeremias kom til Gedalja, Akikams sønn, i Mispa, og han blev hos ham midt iblandt folket, blandt dem som var blitt tilbake i landet. 
24:40:7: Og da alle hærførerne, som var ute på landsbygden, de og deres menn, hørte at Babels konge hadde satt Gedalja, Akikams sønn, til å styre landet, og at han hadde overgitt til hans opsyn menn og kvinner og små barn og dem av almuen i landet som ikke var blitt bortført til Babel, 
24:40:8: så kom de til Gedalja i Mispa, både Ismael, Netanjas sønn, og Johanan og Tonatan, Kareahs sønner, og Seraja, Tanhumets sønn, og sønnene til Ofai fra Netofa og Jesanja, ma'akatittens sønn, de og deres menn. 
24:40:9: Og Gedalja, sønn av Akikam, Safans sønn, svor dem og deres menn en ed og sa: Frykt ikke for å tjene kaldeerne! Bli i landet og tjen Babels konge! Så skal det gå eder vel. 
24:40:10: Se, jeg blir boende i Mispa for å ta imot de kaldeere som kommer til oss, men I skal samle vin og frukt og olje og legge det i eders kar, og I skal bli i eders byer, som I har tatt til eie. 
24:40:11: Også alle de jøder som var i Moab og iblandt Ammons barn og i Edom, og de som var i alle andre land, hørte at Babels konge hadde latt en rest av folket bli tilbake i Juda, og at han hadde satt Gedalja, sønn av Akikam, Safans sønn, over dem. 
24:40:12: Og alle disse jøder vendte tilbake fra alle de steder som de var drevet bort til, og kom til Juda land, til Gedalja i Mispa, og de samlet vin og frukt i stor mengde. 
24:40:13: Men Johanan, Kareahs sønn, og alle hærførerne, som var ute på landsbygden, kom til Gedalja i Mispa, 
24:40:14: og de sa til ham: Vet du at Ba'alis, Ammons barns konge, har sendt Ismael, Netanjas sønn, for å slå dig ihjel? Men Gedalja, Akikams sønn, trodde dem ikke. 
24:40:15: Og Johanan, Kareahs sønn, sa i lønndom til Gedalja i Mispa: La mig gå avsted og slå Ismael, Netanjas sønn, ihjel, uten at nogen får vite det! Hvorfor skal han slå dig ihjel, og hele Juda, som har samlet sig om dig, bli spredt, og den rest som er blitt tilbake av Juda, gå til grunne? 
24:40:16: Men Gedalja, Akikams sønn, sa til Johanan, Kareahs sønn: Gjør ikke det; for det er løgn det du sier om Ismael. 
24:41:1: Men i den syvende måned kom Ismael, sønn av Netanja, Elisamas sønn, en mann av kongelig byrd, en av kongens stormenn, og ti menn med ham til Gedalja, Akikams sønn, i Mispa, og de holdt måltid sammen der i Mispa. 
24:41:2: Da stod Ismael, Netanjas sønn, op, og de ti menn som var med ham, og de slo Gedalja, sønn av Akikam, Safans sønn, ihjel med sverd, og han drepte ham som Babels konge hadde satt til å styre landet. 
24:41:3: Også alle de jøder som var hos Gedalja i Mispa, og de kaldeere som fantes der, krigsmennene, slo Ismael ihjel. 
24:41:4: Den annen dag efterat han hadde drept Gedalja, og før ennu nogen visste det, 
24:41:5: kom det menn fra Sikem, fra Silo og Samaria, åtti menn med avraket skjegg og sønderrevne klær og med flenger i kjøttet*, og de hadde med sig matoffer og virak for å bære det til Herrens hus. # <* JER 16, 6.> 
24:41:6: Og Ismael, Netanjas sønn, gikk dem i møte fra Mispa, og han gikk og gråt; og da han traff dem, sa han til dem: Kom med til Gedalja, Akikams sønn! 
24:41:7: Og da de kom inn i byen, drepte Ismael, Netanjas sønn, dem og kastet dem ned i brønnen*, han og de menn som var med ham. # <* JER 41, 9.> 
24:41:8: Men blandt dem var det ti menn som sa til Ismael: Drep oss ikke! For vi har skjulte forråd ute på marken, hvete og bygg og olje og honning. Da lot han dem være og drepte dem ikke sammen med deres brødre. 
24:41:9: Og brønnen hvor Ismael kastet likene av alle de døde menn som han hadde slått ihjel foruten Gedalja, var den samme som kong Asa hadde gjort for Israels konge Baesas skyld; den fylte Ismael, Netanjas sønn, med drepte menn. 
24:41:10: Og Ismael bortførte som fanger hele resten av folket i Mispa, kongens døtre og alt folket som var blitt tilbake i Mispa, og som høvdingen over livvakten Nebusaradan hadde overgitt til Gedaljas, Akikams sønns opsyn; dem bortførte Ismael, Netanjas sønn, som fanger, og han gikk avsted for å dra over til Ammons barn. 
24:41:11: Men da Johanan, Kareahs sønn, og alle hærførerne som var med ham, hørte om alt det onde som Ismael, Netanjas sønn, hadde gjort, 
24:41:12: da tok de alle sine menn og gikk avsted for å stride mot Ismael, Netanjas sønn, og de fant ham ved det store vann* i Gibeon. # <* 2SA 2, 13.> 
24:41:13: Og da alt folket som var med Ismael, så Johanan, Kareahs sønn, og alle hærførerne som var med ham, blev de glade. 
24:41:14: Og alt folket som Ismael hadde bortført som fanger fra Mispa, vendte om og gikk tilbake til Johanan, Kareahs sønn. 
24:41:15: Men Ismael, Netanjas sønn, slapp unda fra Johanan med åtte menn, og han drog bort til Ammons barn. 
24:41:16: Da tok Johanan, Kareahs sønn, og alle hærførerne som var med ham, hele resten av folket, dem fra Mispa som han hadde vunnet tilbake fra Ismael, Netanjas sønn, efterat Ismael hadde slått Gedalja, Akikams sønn, ihjel, både krigsmenn og kvinner og små barn og hoffmenn, som han hadde ført tilbake fra Gibeon. 
24:41:17: Og de drog avsted og stanset i Kimhams herberge ved Betlehem for å dra videre og komme til Egypten 
24:41:18: for kaldeernes skyld; for de var redde for dem, fordi Ismael, Netanjas sønn, hadde drept Gedalja, Akikams sønn, som Babels konge hadde satt til å styre landet. 
24:42:1: Da kom alle hærførerne, både Johanan, Kareahs sønn, og Jesanja, Hosajas sønn, og alt folket, både små og store, 
24:42:2: og de sa til profeten Jeremias: La oss bære frem vår ydmyke bønn for dig og bed for oss til Herren din Gud for hele denne rest av folket - for bare nogen få er vi igjen av de mange, således som du selv ser oss her - 
24:42:3: at Herren din Gud vil la oss få vite hvilken vei vi skal gå, og hvad vi skal gjøre. 
24:42:4: Profeten Jeremias sa til dem: Jeg skal gjøre det; jeg vil bede til Herren eders Gud efter eders ord, og hvert et ord Herren svarer eder, skal jeg forkynne eder; jeg skal ikke holde et eneste ord skjult for eder. 
24:42:5: Da sa de til Jeremias: Herren være et sant og trofast vidne imot oss, dersom vi ikke gjør efter hvert ord som Herren din Gud sender dig til oss med! 
24:42:6: Enten det er godt eller ondt, vil vi lyde Herrens, vår Guds røst, som vi sender dig til, forat det må gå oss vel, når vi lyder Herrens, vår Guds røst. 
24:42:7: Ti dager efter kom Herrens ord til Jeremias. 
24:42:8: Og han kalte til sig Johanan, Kareahs sønn, og alle hærførerne som var med ham, og alt folket, både små og store, 
24:42:9: og han sa til dem: Så sier Herren, Israels Gud, som I sendte mig til for å bære frem eders ydmyke bønn for hans åsyn: 
24:42:10: Dersom I blir i dette land, så vil jeg bygge eder op og ikke bryte eder ned, plante eder og ikke rykke eder op; for jeg angrer det onde jeg har gjort eder. 
24:42:11: Frykt ikke for Babels konge, som I nu frykter for, frykt ikke for ham, sier Herren; for jeg er med eder og vil frelse eder og fri eder av hans hånd. 
24:42:12: Og jeg vil la eder finne barmhjertighet, og han skal forbarme sig over eder og la eder vende tilbake til eders land. 
24:42:13: Men dersom I sier: Vi vil ikke bli i dette land, og I ikke lyder Herrens, eders Guds røst, 
24:42:14: men sier: Nei, vi vil gå til Egyptens land, så vi kan slippe å se krig og høre basunens lyd og hungre efter brød, og der vil vi bli, 
24:42:15: så hør nu derfor Herrens ord, I som er blitt igjen av Juda: Så sier Herren, hærskarenes Gud, Israels Gud: Dersom I setter eder fore å dra til Egypten, og I drar dit for å bo der, 
24:42:16: da skal sverdet I frykter for, nå eder der i Egyptens land, og hungeren som I er redde for, skal følge eder i hælene der i Egypten, og der skal I dø. 
24:42:17: Og alle de menn som har satt sig fore å dra til Egypten for å bo der, de skal dø ved sverd, hunger og pest, og ingen av dem skal bli igjen og slippe fra den ulykke som jeg lar komme over dem. 
24:42:18: For så sier Herren, hærskarenes Gud, Israels Gud: Likesom min vrede og harme blev utøst over Jerusalems innbyggere, således skal min harme bli utøst over eder når I kommer til Egypten, og I skal bli til en ed og til en forferdelse og en forbannelse og til spott, og I skal ikke mere se dette sted. 
24:42:19: Herren har talt til eder, I som er blitt igjen av Juda: Dra ikke til Egypten! I skal vite at jeg har advart eder idag. 
24:42:20: For I farer vill og forspiller eders liv; I har jo selv sendt mig til Herren eders Gud og sagt: Bed for oss til Herren vår Gud og forkynn oss alt det Herren vår Gud sier, og vi vil gjøre det. 
24:42:21: Og jeg har forkynt eder det idag; men I har ikke hørt på Herrens, eders Guds røst, og på alt det som han sendte mig til eder med. 
24:42:22: Så skal I nu vite at I skal dø ved sverd, hunger og pest på det sted som I stunder efter å komme til for å bo der. 
24:43:1: Men da Jeremias var ferdig med å tale til alt folket alle Herrens, deres Guds ord, som Herren deres Gud hadde sendt ham til dem med - alle disse ord, 
24:43:2: da sa Asarja, Hosajas sønn, og Johanan, Kareahs sønn, og alle de overmodige menn, de sa til Jeremias: Du taler løgn; Herren vår Gud har ikke sendt dig og sagt: I skal ikke dra til Egypten for å bo der. 
24:43:3: Men Baruk, Nerijas sønn, egger dig op mot oss, forat vi skal bli gitt i kaldeernes hånd, så de kan drepe oss eller føre oss bort til Babel. 
24:43:4: Og Johanan, Kareahs sønn, og alle hærførerne og alt folket vilde ikke høre på Herrens røst og bli i Juda land. 
24:43:5: Men Johanan, Kareahs sønn, og alle hærførerne tok alle dem som var blitt igjen av Juda, dem som var kommet tilbake fra alle de hedningefolk som de var blitt drevet bort til, for å bo i Juda land, 
24:43:6: mennene og kvinnene og de små barn og kongens døtre og hver sjel som høvdingen over livvakten Nebusaradan hadde latt bli tilbake hos Gedalja, sønn av Akikam, Safans sønn, og likeså profeten Jeremias og Baruk, Nerijas sønn, 
24:43:7: og de drog avsted til Egyptens land, for de hørte ikke på Herrens røst; og så kom de frem til Tahpanhes. 
24:43:8: Og Herrens ord kom til Jeremias i Tahpanhes, og det lød så: 
24:43:9: Ta nogen store stener i din hånd og skjul dem i leret ved teglovnen som står ved inngangen til Faraos hus i Tahpanhes, og la nogen menn fra Juda se på det! 
24:43:10: Og du skal si til dem: Så sier Herren, hærskarenes Gud, Israels Gud: Se, jeg sender bud og henter min tjener Nebukadnesar, Babels konge, og setter hans trone ovenpå disse stener som jeg har skjult, og han skal brede ut sitt prektige teppe over dem. 
24:43:11: Og han skal komme og slå Egyptens land; den som hører døden til, hjemfaller til døden, og den som hører fangenskapet til, hjemfaller til fangenskapet, og den som hører sverdet til, hjemfaller til sverdet. 
24:43:12: Og jeg vil sette ild på Egyptens gudetempler, og han skal brenne dem op og føre gudene bort, og han skal svøpe sig i Egyptens land, likesom en hyrde svøper sig i sin kappe, og siden skal han dra ut derfra i fred. 
24:43:13: Og han skal knuse støttene i Betsemes* i Egyptens land, og Egyptens templer skal han brenne op med ild. # <* Heliopolis.> 
24:44:1: Dette er det ord som kom til Jeremias om alle de jøder som bodde i Egyptens land, dem som bodde i Migdol og i Tahpanhes og i Memfis og i landet Patros: 
24:44:2: Så sier Herren, hærskarenes Gud, Israels Gud: I har sett all den ulykke jeg har latt komme over Jerusalem og over alle Judas byer, og se, nu er de en ørken, og det er ingen som bor i dem, 
24:44:3: for det onde som de gjorde, så de vakte min harme, da de gikk avsted og brente røkelse og dyrket andre guder, som de ikke kjente, hverken de eller I eller eders fedre. 
24:44:4: Og jeg sendte alle mine tjenere, profetene, til eder tidlig og sent og sa: Gjør ikke denne vederstyggelige ting som jeg hater! 
24:44:5: Men de hørte ikke og la ikke øret til, så de vendte om fra sin ondskap og holdt op med å brenne røkelse for andre guder. 
24:44:6: Da blev min harme og vrede utøst, og den brente i Judas byer og på Jerusalems gater, og de blev til en ørken, til en ødemark, som det sees på denne dag. 
24:44:7: Og nu sier Herren, hærskarenes Gud, Israels Gud, så: Hvorfor gjør I så stor en ondskap mot eder selv, så mann og kvinne, barn og diebarn utryddes for eder av Judas midte, så det ikke levnes eder nogen rest, 
24:44:8: og vekker min harme ved eders henders gjerninger, ved å brenne røkelse for andre guder i Egyptens land, dit I har draget for å bo der - så de* skal bli utryddet for eder, og I bli til en forbannelse og til spott blandt alle jordens folkeslag? # <* mann og kvinne o.s.v.; JER 44, 7.> 
24:44:9: Har I glemt eders fedres onde gjerninger og Judas kongers onde gjerninger og dets kvinners onde gjerninger og eders egne onde gjerninger og eders kvinners onde gjerninger, det de gjorde i Juda land og på Jerusalems gater? 
24:44:10: Til denne dag er de ikke blitt ydmyket, og de frykter ikke og lever ikke efter min lov og mine bud, som jeg har forelagt eder og eders fedre. 
24:44:11: Derfor sier Herren, hærskarenes Gud, Israels Gud, så: Se, jeg vender mitt åsyn imot eder til det onde, og jeg vil utrydde hele Juda. 
24:44:12: Og jeg vil ta dem som er blitt igjen av Juda, dem som satte sig fore å dra til Egyptens land for å bo der, og de skal alle omkomme, i Egyptens land skal de falle; ved sverd og ved hunger skal de omkomme; både små og store skal dø ved sverd og ved hunger, og de skal bli til en ed, til en forferdelse og en forbannelse og til spott. 
24:44:13: Og jeg vil hjemsøke dem som bor i Egyptens land, likesom jeg hjemsøkte Jerusalem, med sverd, med hunger og med pest. 
24:44:14: Og av dem som er blitt tilbake av Juda, av dem som er kommet for å bo der i Egyptens land, skal ingen slippe unda og bli igjen, så han kommer tilbake til Juda land, som de stunder efter å komme tilbake til for å bo der; for ingen skal komme tilbake igjen uten nogen få undslopne. 
24:44:15: Da svarte de Jeremias alle de menn som visste at deres kvinner brente røkelse for andre guder, og alle kvinnene som stod der i en stor flokk, og alt folket som bodde i Egyptens land, i Patros, og de sa: 
24:44:16: Vi vil ikke høre på dig i det du har talt til oss i Herrens navn, 
24:44:17: men vi vil gjøre alt det som vi har lovt med vår munn; vi vil brenne røkelse for himmelens dronning og utøse drikkoffer for henne, likesom vi har gjort, vi og våre fedre, våre konger og våre høvdinger, i Judas byer og på Jerusalems gater. Da blev vi mettet med brød, og det gikk oss vel, og ingen ulykke rammet oss. 
24:44:18: Men fra den tid vi holdt op med å brenne røkelse for himmelens dronning og utøse drikkoffer for henne, har vi manglet alt, og ved sverd og hunger er vi omkommet. 
24:44:19: Og når vi brente røkelse for himmelens dronning og utøste drikkoffer for henne, mon det da var uten våre menns vitende og vilje at vi laget kaker for henne og dyrket henne og utøste drikkoffer for henne? 
24:44:20: Da sa Jeremias til alt folket, til mennene og til kvinnene og til alt folket som hadde svart ham: 
24:44:21: Det at I har brent røkelse i Judas byer og på Jerusalems gater, I og eders fedre, eders konger og eders høvdinger og landets folk, mon Herren ikke har kommet det i hu og tenkt på det? 
24:44:22: Og Herren kunde ikke mere tåle det for eders onde gjerningers skyld, for de vederstyggeligheters skyld som I gjorde, og så blev eders land til en ørken og en ødemark og til en forbannelse, så ingen bor der, som det sees på denne dag. 
24:44:23: Fordi I brente røkelse og syndet mot Herren og ikke hørte på Herrens røst og ikke levde efter hans lov og bud og vidnesbyrd, derfor rammet denne ulykke eder, som det sees på denne dag. 
24:44:24: Og Jeremias sa til alt folket og til alle kvinnene: Hør Herrens ord, alle I av Juda som er i Egyptens land! 
24:44:25: Så sier Herren, hærskarenes Gud, Israels Gud: I og eders kvinner, I talte med eders munn og fullbyrdet det med eders hender, og I sa: Vi vil holde de løfter som vi har gjort: å brenne røkelse for himmelens dronning og utøse drikkoffer for henne. Hold bare eders løfter og opfyll dem! 
24:44:26: Hør derfor Herrens ord, alle I av Juda som bor i Egyptens land: Se, jeg har svoret ved mitt store navn, sier Herren: Sannelig, mitt navn skal ikke mere nevnes i nogen jødisk manns munn i hele Egyptens land, så han sier: Så sant Herren, Israels Gud, lever! 
24:44:27: Se, jeg våker over dem til det onde og ikke til det gode, og hver mann av Juda som er i Egyptens land, skal omkomme ved sverd og ved hunger, inntil de er tilintetgjort. 
24:44:28: Og de som undkommer fra sverdet, skal vende tilbake fra Egyptens land til Juda land, en liten flokk, og alle de som er blitt igjen av Juda, som er kommet til Egyptens land for å bo der, skal få merke hvis ord skal stå ved makt, mitt eller deres. 
24:44:29: Og dette, sier Herren, skal være tegnet for eder på at jeg vil hjemsøke eder på dette sted, så I skal vite at mine ord om eder skal stå ved makt til ulykke for eder: 
24:44:30: Så sier Herren: Se, jeg gir Egyptens konge Farao Hofra i hans fienders hånd og i deres hånd som står ham efter livet, likesom jeg har gitt Judas konge Sedekias i Babels konge Nebukadnesars hånd, som var hans fiende og stod ham efter livet. 
24:45:1: Dette er det ord profeten Jeremias talte til Baruk, Nerijas sønn, da han efter Jeremias' munn skrev disse ord i en bok, i Judas konge Jojakims, Josias' sønns fjerde år: 
24:45:2: Så sier Herren, Israels Gud, om dig, Baruk: 
24:45:3: Du sa: Ve mig! For Herren har lagt sorg til min smerte; jeg er trett av mine sukk og finner ikke ro. 
24:45:4: Dette skal du si til ham: Så sier Herren: Se, det jeg har bygget, det bryter jeg ned, og det jeg har plantet, det rykker jeg op, og så går det med hele jorden. 
24:45:5: Og du, du attrår store ting? Attrå dem ikke! For se, jeg lar ulykke komme over alt kjød, sier Herren. Men jeg vil gi dig ditt liv til krigsbytte på alle de steder hvor du går hen. 
24:46:1: Dette er Herrens ord som kom til profeten Jeremias om hedningefolkene. 
24:46:2: Om Egypten. Om egypterkongen Farao Nekos hær, som stod ved elven Eufrat, ved Karkemis, og som Babels konge Nebukadnesar slo i Judas konge Jojakims, Josias' sønns fjerde år: 
24:46:3: Sett skjoldene i stand, de små og de store, og rykk frem til striden! 
24:46:4: Spenn hestene for og sitt op, I ryttere, og still eder op med hjelmer på! Gjør spydene blanke, ta brynene på! 
24:46:5: Hvorfor ser jeg dem forferdet vike tilbake? Og deres kjemper blir slått og tar flukten uten å vende sig om; redsel er det rundt omkring, sier Herren. 
24:46:6: Den lette skal ikke undfly, og kjempen ikke undslippe; mot nord, ved bredden av elven Eufrat, snubler de og faller. 
24:46:7: Hvem er det som stiger op lik Nilen, hvis vann bølger som strømmene? 
24:46:8: Egypten stiger op som Nilen, og dets vann bølger som strømmene; det sier: Jeg vil stige op og dekke landene, jeg vil ødelegge byer og dem som bor i dem. 
24:46:9: Far frem, I hester, og ras avsted, I vogner! Kjempene skal rykke ut, etiopere og puteere, som fører skjold, og menn fra Lud, som fører og spenner bue. 
24:46:10: Men den dag er for Herren, Israels Gud, hærskarenes Gud, en hevnens dag, til hevn over hans fiender, og sverdet skal fortære og mettes og bli drukkent av deres blod; for en offerslakting holder Herren, Israels Gud, hærskarenes Gud, i landet i nord ved elven Eufrat. 
24:46:11: Dra op til Gilead og hent balsam, du jomfru, Egyptens datter! Forgjeves bruker du mange slags lægedom; det finnes ikke plaster for dig. 
24:46:12: Folkeslag hører om din skam, og jorden er full av ditt skrik; for den ene stridsmann snubler over den andre, de faller begge to. 
24:46:13: Dette er det ord Herren talte til profeten Jeremias om at Babels konge Nebukadnesar skulde komme og slå Egyptens land: 
24:46:14: Kunngjør det i Egypten, la det høres i Migdol og la det høres i Memfis og Tahpanhes! Si: Still dig frem og gjør dig ferdig! For sverdet har fortært alt rundt omkring dig. 
24:46:15: Hvorfor er din sterke* styrtet til jorden? Han holdt ikke stand; for Herren støtte ham ned. # <* d.e. rimeligvis den egyptiske avgud oksen Apis.> 
24:46:16: Han får mange til å snuble, ja, den ene faller ovenpå den andre, og de sier: Op, la oss vende tilbake til vårt folk og vårt fødeland for det herjende sverd! 
24:46:17: Da roper de: Farao, Egyptens konge, er ødelagt! Han lot den rette tid gå forbi. 
24:46:18: Så sant jeg lever, sier kongen, han hvis navn er Herren, hærskarenes Gud: Som Tabor iblandt fjellene og som Karmel ved havet kommer han*. # <* Nebukadnesar.> 
24:46:19: Rust dig ut til å gå i landflyktighet, du Egyptens datter som bor i landet! For Memfis skal bli til en ørken og bli ødelagt, så ingen bor der. 
24:46:20: En herlig kvige er Egypten - kleggen fra nord kommer, den kommer. 
24:46:21: Også de leide krigsmenn som det har i sin midte, de som er lik gjøkalver, ja, også de vender om, flyr alle sammen, de holder ikke stand; for deres ulykkesdag er kommet over dem, deres hjemsøkelses tid. 
24:46:22: Dets røst skal lyde som ormens; for med en hær drar de* frem, og med økser kommer de mot det som tømmerhuggere. # <* fiendene.> 
24:46:23: De hugger ned dets skog, sier Herren; for den er ugjennemtrengelig; de er flere enn gresshoppene, det er ikke tall på dem. 
24:46:24: Egyptens datter blir til skamme; hun blir gitt i hendene på et folk fra nord. 
24:46:25: Herren, hærskarenes Gud, Israels Gud, sier: Se, jeg hjemsøker Amon fra No og Farao og Egypten og dets guder og dets konger, både Farao og dem som setter sin lit til ham, 
24:46:26: og jeg gir dem i deres hånd som står dem efter livet, i Babels konge Nebukadnesars hånd og i hans tjeneres hånd; men derefter skal det få leve i ro som i fordums dager, sier Herren. 
24:46:27: Men frykt ikke, du min tjener Jakob, og reddes ikke, Israel! For se, jeg frelser dig fra det fjerne land og din ætt fra deres fangenskaps land, og Jakob skal vende tilbake og ha ro og være trygg, og ingen skal forferde ham. 
24:46:28: Frykt ikke, min tjener Jakob, sier Herren, for jeg er med dig; jeg vil gjøre ende på alle de folk som jeg har drevet dig bort til, men dig vil jeg ikke gjøre ende på; jeg vil tukte dig med måte; men helt ustraffet vil jeg ikke la dig være. 
24:47:1: Dette er Herrens ord som kom til profeten Jeremias om filistrene, før Farao slo Gasa. 
24:47:2: Så sier Herren: Se, vann stiger op fra nord, og det blir til en flommende elv, og det flommer utover landene og alt som i dem er, byene og dem som bor i dem. Menneskene skriker, og alle som bor på jorden, jamrer sig; 
24:47:3: når hans veldige hesters hovslag drønner, når hans vogner ramler, når hans hjul dundrer, da ser ikke fedrene sig om efter barna, så motfalne er de 
24:47:4: for den dags skyld som kommer for å ødelegge alle filistrene, for å utrydde for Tyrus og Sidon alle som er undsloppet og kunde hjelpe; for Herren ødelegger filistrene, dem som er blitt igjen fra øen Kaftor. 
24:47:5: Gasa er blitt skallet, Askalon er blitt målløst, det som er blitt igjen av deres lavland; hvor lenge vil du skjære i ditt kjøtt? 
24:47:6: Ve! Du Herrens sverd, hvor lenge vil du være urolig? Far i din skjede, hvil dig og vær stille! 
24:47:7: Hvorledes skulde du holde dig rolig, når Herren har gitt dig befaling? Til Askalon og til havets strand, dit har han stevnet det. 
24:48:1: Om Moab. Så sier Herren, hærskarenes Gud, Israels Gud: Ve over Nebo, for det er ødelagt; Kirjata'im er blitt til skamme, det er inntatt; festningen er blitt til skamme og slått med redsel. 
24:48:2: Med Moabs ry er det forbi; i Hesbon legger de op onde råd mot det: Kom, la oss utrydde det, så det ikke mere er et folk! Også du Madmen skal bli målløs, efter dig skal sverdet fare. 
24:48:3: Det lyder skrik fra Horona'im: herjing og stor ødeleggelse. 
24:48:4: Moab er ødelagt; dets barn opløfter skrik. 
24:48:5: For gråtende går de opover bakken til Luhut; på veien ned til Horona'im høres angstfulle klagerop over ødeleggelsen. 
24:48:6: Fly, redd eders liv! Bli som hjelpeløse mennesker i ørkenen! 
24:48:7: Fordi du satte din lit til dit gods og dine skatter, skal også du bli hærtatt, og Kamos skal gå i fangenskap, hans prester og hans høvdinger alle sammen. 
24:48:8: Og en ødelegger skal komme over hver by, og ingen by skal gå fri; dalen skal gå til grunne og sletten bli ødelagt, som Herren har sagt. 
24:48:9: Gi Moab vinger! For i flyvende fart skal det dra ut, og dets byer skal bli til en ørken, så ingen bor i dem. 
24:48:10: Forbannet være den som gjør Herrens gjerning med lathet, og forbannet være den som holder sitt sverd fra blod! 
24:48:11: Moab har levd i ro fra sin ungdom av; det har ligget stille på sin berme og er ikke blitt tappet om fra kar til kar og er ikke gått i fangenskap; derfor har det beholdt sin smak, og dets duft er ikke forandret. 
24:48:12: Se, derfor skal dager komme, sier Herren, da jeg sender det vintappere, og de skal legge det på hell og tømme dets kar og knuse dets krukker. 
24:48:13: Da skal Moab ha skam av Kamos, likesom Israels hus hadde skam av Betel, som de satte sin lit til. 
24:48:14: Hvorledes kan I si: Vi er helter og djerve krigsmenn? 
24:48:15: Moab blir ødelagt, og de stiger op i dets byer, og dets utvalgte unge menn stiger ned for å slaktes, sier kongen, hvis navn er Herren, hærskarenes Gud. 
24:48:16: Moabs nød er nær, og dets ulykke kommer hastig. 
24:48:17: Ha medynk med det, alle I som bor rundt omkring det, og alle I som kjenner dets navn! Si: Hvor det er blitt knekket, det sterke spir, den herlige stav! 
24:48:18: Stig ned fra din herlighet og sett dig i et tørt land, du Dibons datter som bor i byen! For Moabs ødelegger stiger op imot dig, han ødelegger dine festninger. 
24:48:19: Still dig ved veien og se ut, du som bor i Aroer! Spør de flyktende og undkomne, si: Hvad har hendt? 
24:48:20: Moab er blitt til skamme, for det er knust. Hyl og skrik! Kunngjør ved Arnon at Moab er ødelagt! 
24:48:21: Dommen er kommet over slettelandet, over Holon og over Jahsa og over Mofa'at 
24:48:22: og over Dibon og over Nebo og over Bet-Diblata'im 
24:48:23: og over Kirjata'im og over Bet-Gamul og over Bet-Meon 
24:48:24: og over Kerijot og over Bosra og over alle byene i Moabs land, fjernt og nær. 
24:48:25: Moabs horn er avhugget, og dets arm er brutt, sier Herren. 
24:48:26: Gjør det drukkent, fordi det har ophøiet sig mot Herren! Moab skal velte sig i sitt spy og bli til latter, det også. 
24:48:27: Eller var ikke Israel til latter for dig? Eller er det grepet blandt tyver, siden du ryster på hodet så ofte du taler om det? 
24:48:28: Forlat byene og bo på fjellet, I Moabs innbyggere, og bli lik duen som bygger rede på den andre siden av kløftens gap! 
24:48:29: Vi har hørt om Moabs veldige overmot, om dets storaktighet, dets overmot, dets opblåsthet og dets stolte hjerte. 
24:48:30: Jeg kjenner dets overmot, sier Herren, og dets tomme skryt; tomhet er det de har gjort. 
24:48:31: Derfor jamrer jeg over Moab, over hele Moab klager jeg; over mennene i Kir-Heres må en sukke. 
24:48:32: Som Jaser gråter, gråter jeg over dig, du Sibmas vintre! Dine kvister gikk ut over havet, de nådde like til Jasers hav; over din frukthøst og din vinhøst faller en ødelegger. 
24:48:33: Og glede og fryd blir borte fra den fruktbare mark og fra Moabs land, og på vinen i persekarene gjør jeg ende; ingen skal trede persen med frydeskrik; det lyder frydeskrik som ikke er frydeskrik* # <* d.e. det lyder frydeskrik fra de seierrike fiender, ikke fra høstfolkene; JER 51, 14.> 
24:48:34: Det lyder skrik fra Hesbon like til El'ale, ja like til Jahas, fra Soar til Horona'im, den treårige kvige; for også Nimrims vann blir til ørkener. 
24:48:35: Og jeg utrydder av Moab, sier Herren, hver den som stiger op på en haug og brenner røkelse for sine guder. 
24:48:36: Derfor klager mitt hjerte likesom fløiter over Moab, mitt hjerte klager likesom fløiter over mennene i Kir-Heres; derfor går det til grunne det som Moab har samlet sammen, dets opsparte gods. 
24:48:37: For hvert hode er skallet og hvert skjegg avraket; på alle hender er det flenger og om lendene sekk. 
24:48:38: På alle Moabs tak og på dets gater er hele folket bare veklage; for jeg har knust Moab lik et kar som ingen bryr sig om, sier Herren. 
24:48:39: Hvor forferdet det er, hvor de hyler! Se hvorledes Moab vender ryggen til! Hvor det er blitt til skamme! Moab blir til latter og til en forferdelse for alle dem som bor rundt omkring det. 
24:48:40: For så sier Herren: Se, han* flyver som en ørn og breder sine vinger ut over Moab. # <* Nebukadnesar. JER 49, 22.> 
24:48:41: Byene blir inntatt, borgene blir stormet, og Moabs kjemper blir på den dag til mote som en kvinne i barnsnød. 
24:48:42: Og Moab ødelegges, så det ikke mere er et folk, fordi det har ophøiet sig mot Herren. 
24:48:43: Gru og grav og garn over dig, du Moabs innbygger, sier Herren. 
24:48:44: Den som flyr for gruen, skal falle i graven, og den som kommer op av graven, skal fanges i garnet; for jeg lar hjemsøkelsens år komme over Moab, sier Herren. 
24:48:45: I Hesbons skygge står flyktninger uten kraft; for det farer ild ut fra Hesbon og en lue fra Sihon, og den fortærer Moabs kinn og ufreds-ættens isse. 
24:48:46: Ve dig, Moab! Fortapt er Kamos' folk; for dine sønner føres bort i fengsel og dine døtre i fangenskap. 
24:48:47: Men i de siste dager vil jeg gjøre ende på Moabs fangenskap, sier Herren. Her ender dommen over Moab. 
24:49:1: Om Ammons barn. Så sier Herren: Har Israel ingen barn, eller har han ingen arving? Hvorfor har deres konge* tatt Gad til eie, og hans folk bosatt sig i dets byer? # <* d.e. deres (ammonittenes) avgud Molok; 1KG 11, 5. 7. AMO 1, 15.> 
24:49:2: Se, derfor skal dager komme, sier Herren, da jeg lar krigsskrik høre mot Rabba i Ammons land, og det skal bli til en øde grusdynge, og dets døtre* skal brennes op med ild, og Israel skal ta sine eiere til eie, sier Herren. # <* d.e. dets omliggende byer.> 
24:49:3: Hyl, Hesbon! For Ai er ødelagt. Skrik, I Rabbas døtre, omgjord eder med sekk, jamre eder og løp omkring ved kveene! For deres konge* går i fangenskap, hans prester og høvdinger alle sammen. # <* JER 49, 1.> 
24:49:4: Hvorfor roser du dig av dine daler, av din dals overflod, du frafalne datter, som setter din lit til dine skatter og sier: Hvem kan gjøre mig noget? 
24:49:5: Se, jeg lar frykt komme over dig, sier Herren, Israels Gud, hærskarenes Gud, fra alle dem som bor rundt omkring dig, og I skal bli drevet bort, hver til sin kant, uten at nogen samler de flyktende. 
24:49:6: Men derefter vil jeg gjøre ende på Ammons barns fangenskap, sier Herren. 
24:49:7: Om Edom. Så sier Herren, hærskarenes Gud: Er det ikke mere nogen visdom i Teman? Finnes ikke lenger råd hos de forstandige? Er deres visdom uttømt? 
24:49:8: Fly, vend eder, skjul eder dypt nede, I Dedans innbyggere! For jeg lar Esaus ulykke komme over ham, den tid da jeg hjemsøker ham. 
24:49:9: Om vinhøstere kommer over dig, så vil de ikke levne nogen efterhøst, om tyver kommer om natten, så vil de ødelegge til de har nok. 
24:49:10: For jeg klær Esau naken, jeg avdekker hans skjulesteder, og vil han skjule sig, så kan han det ikke; hans barn og hans brødre og hans naboer blir ødelagt, og han er ikke mere. 
24:49:11: Forlat dine farløse! Jeg vil holde dem i live, og dine enker kan stole på mig. 
24:49:12: For så sier Herren: Se, de som det ikke tilkom å drikke begeret*, de skal drikke, og så skulde du bli ustraffet? Nei, du skal ikke bli ustraffet, drikke skal du. # <* d.e. Herrens folk. JER 25, 29. KLA 4, 21.> 
24:49:13: For jeg har svoret ved mig selv, sier Herren, at Bosra skal bli til en forferdelse, til spott, til en ørken og til en forbannelse, og alle dets byer skal bli til evige grusdynger. 
24:49:14: En tidende har jeg hørt fra Herren, og et bud er sendt ut iblandt folkene: Samle eder og dra imot det, stå op til krig! 
24:49:15: For se, jeg gjør dig liten blandt folkene, foraktet blandt menneskene. 
24:49:16: Redsel over dig! Ditt hjertes overmot har dåret dig, du som bor i fjellkløfter, du som holder til oppe på høidene. Om du bygger ditt rede høit som ørnen, så vil jeg styrte dig ned derfra, sier Herren. 
24:49:17: Og Edom skal bli til en forferdelse; hver den som går forbi det, skal forferdes og spotte over alle dets plager. 
24:49:18: Som da Sodoma og Gomorra og dets nabobyer blev lagt i grus, sier Herren, skal ingen mann bo der, og intet menneskebarn opholde sig der. 
24:49:19: Se, han* stiger op som en løve fra Jordans prakt** til de alltid grønne enger; for i et øieblikk vil jeg jage Edoms folk bort derfra, og den som er utvalgt, ham vil jeg sette over det; for hvem er som jeg, og hvem vil stevne mig, og hvem er den hyrde som kan stå for mitt åsyn? # <* Nebukadnesar.> # <** JER 12, 5.> 
24:49:20: Hør derfor det råd som Herren har lagt mot Edom, og de tanker som han har tenkt mot Temans innbyggere: Visselig, de skal bli slept bort de små lam; visselig, deres beitemark skal forferdes over dem. 
24:49:21: Ved braket av deres fall bever jorden; det lyder skrik som høres like til det Røde Hav. 
24:49:22: Se, som en ørn farer han op og flyver og breder sine vinger ut over Bosra, og Edoms kjemper blir på den dag til mote som en kvinne i barnsnød. 
24:49:23: Om Damaskus. Hamat og Arpad er blitt til skamme, for de har hørt en ond tidende, de forgår av angst; i havet er det uro, det kan ikke være stille. 
24:49:24: Damaskus er motløst, det vender sig til flukt, forferdelse har grepet det; angst og veer har grepet det som den fødende kvinne. 
24:49:25: Hvor den er forlatt, den lovpriste stad, min gledes by*! # <* således klager Damaskus' innbyggere.> 
24:49:26: Derfor skal dens unge menn falle på dens gater, og alle krigsmennene skal tilintetgjøres på den dag, sier Herren, hærskarenes Gud. 
24:49:27: Jeg vil sette ild på Damaskus' mur, og den skal fortære Benhadads palasser. 
24:49:28: Om Kedar og om Hasors riker*, som Babels konge Nebukadnesar slo. Så sier Herren: Stå op, dra op mot Kedar og ødelegg Østens barn! # <* d.e. Arabia. JES 21, 13 fg. JER 9, 25. 26; 25, 23. 24.> 
24:49:29: Deres telt og deres småfe skal de ta; deres telttepper og alle deres redskaper og deres kameler skal de føre bort med sig, og de skal rope til dem: Redsel rundt omkring! 
24:49:30: Fly, flykt alt det I kan, skjul eder dypt nede, I Hasors innbyggere, sier Herren; for Babels konge Nebukadnesar har lagt råd imot eder og tenkt ut ondt imot eder. 
24:49:31: Stå op, dra op mot et rolig folk, som bor trygt, sier Herren; de har hverken port eller bom, de bor for sig selv. 
24:49:32: Deres kameler skal bli til rov, og deres store buskap skal bli til hærfang, og jeg vil sprede dem for alle vinder, dem med rundklippet hår, og fra alle kanter vil jeg la ulykke komme over dem, sier Herren. 
24:49:33: Og Hasor skal bli til bolig for sjakaler, en ørken til evig tid; ingen mann skal bo der, og intet menneskebarn opholde sig der. 
24:49:34: Dette er Herrens ord som kom til profeten Jeremias om Elam* i begynnelsen av Judas konge Sedekias' regjering: # <* d.e. Persia. JER 25, 25. JES 21, 2.> 
24:49:35: Så sier Herren, hærskarenes Gud: Se, jeg knekker Elams bue, deres beste våben. 
24:49:36: Og jeg vil la fire vinder fra himmelens fire hjørner komme over Elam og sprede dem for alle disse vinder, og det skal ikke være noget folk som de bortdrevne fra Elam ikke kommer til. 
24:49:37: Og jeg vil gjøre Elam redd for sine fiender og for dem som står dem efter livet, og jeg vil la ulykke komme over dem, min brennende vrede, sier Herren, og jeg vil sende sverd efter dem, inntil jeg får gjort ende på dem. 
24:49:38: Og jeg vil sette min trone i Elam, og jeg vil utrydde konge og høvdinger der, sier Herren. 
24:49:39: Men det skal skje i de siste dager, da vil jeg gjøre ende på Elams fangenskap, sier Herren. 
24:50:1: Dette er det ord Herren talte om Babel, om kaldeernes land, ved profeten Jeremias. 
24:50:2: Forkynn det iblandt folkene og kunngjør det og løft op banner, kunngjør det, dølg det ikke, si: Babel er inntatt, Bel er blitt til skamme, Merodak er blitt forferdet, dets gudebilleder er blitt til skamme, dets vederstyggelige avguder er forferdet! 
24:50:3: For et folk drar op imot det fra nord, det gjør dets land til en ørken, og det er ingen som bor i det; både mennesker og dyr er flyktet bort. 
24:50:4: I de dager og på den tid, sier Herren, skal Israels barn komme, de og Judas barn sammen; de skal gå og gråte, og Herren sin Gud skal de søke. 
24:50:5: Efter Sion skal de spørre, hit er deres åsyn vendt: Kom og gi eder til Herren ved en evig pakt, som ikke glemmes! 
24:50:6: Fortapte får var mitt folk, deres hyrder hadde ført dem vill, til fjellene hadde de drevet dem bort*; fra fjell til haug gikk de, de glemte sitt hvilested. # <* neml. for der å dyrke avguder; JER 3, 6. 23.> 
24:50:7: Alle som traff dem, åt dem, og deres fiender sa: Vi skal ikke bøte for det - fordi de hadde syndet mot Herren, rettferdighetens bolig, og mot sine fedres håp, Herren. 
24:50:8: Fly ut av Babel og dra bort fra kaldeernes land, og gå som bukker foran hjorden! 
24:50:9: For se, jeg vekker og fører op imot Babel en skare av store folkeslag fra landet i nord, og de skal stille sig op imot det; av dem skal det bli inntatt; deres piler er som en prøvet kjempes, ingen vender tilbake med uforrettet sak. 
24:50:10: Og Kaldea skal bli til rov; alle som røver det, skal mettes, sier Herren. 
24:50:11: Ja, gled eder bare, ja, juble bare, I som plyndrer min arv! Ja, hopp bare som en treskende kvige* og vrinsk som de sterke hester! # <* 5MO 25, 4. HSE 10, 11.> 
24:50:12: Eders mor blir storlig til skamme, hun som fødte eder, blues; se, hun blir det siste blandt folkene, en ørken, et tørt land og en øde mark. 
24:50:13: For Herrens vredes skyld skal det aldri bo folk der igjen, det hele skal bli til en ørken; hver den som går forbi Babel, skal forferdes og spotte over alle dets plager. 
24:50:14: Still eder op mot Babel rundt omkring, alle I som spenner bue! Skyt på det, spar ikke på pilene! For mot Herren har det syndet. 
24:50:15: Opløft hærskrik mot det rundt omkring! Det har overgitt sig, dets grunnvoller er falt, dets murer er brutt ned; for det er Herrens hevn. Hevn eder på det! Gjør mot det således som det har gjort! 
24:50:16: Utrydd av Babel både såmann og den som fører sigden i høstens tid! For det herjende sverd skal de vende sig hver til sitt folk og fly hver til sitt land. 
24:50:17: Et bortdrevet lam er Israel, løver har jaget det bort; først åt Assurs konge det, og nu sist har Babels konge Nebukadnesar knust dets ben. 
24:50:18: Derfor sier Herren, hærskarenes Gud, Israels Gud, så: Se, jeg hjemsøker Babels konge og hans land, likesom jeg har hjemsøkt Assurs konge. 
24:50:19: Og jeg vil føre Israel tilbake til dets beitemarker, og det skal beite på Karmel og i Basan, og på Efra'ims fjell og i Gilead skal det ete sig mett. 
24:50:20: I de dager og på den tid, sier Herren, skal de søke efter Israels misgjerning, men den skal ikke være til, og efter Judas synder, men de skal ikke finnes; for jeg vil tilgi dem som jeg lar bli igjen. 
24:50:21: Dra op mot Merata'ims* land og mot Pekods* innbyggere! Forfølg dem, ødelegg dem og slå dem med bann, sier Herren, og gjør i alle deler som jeg har befalt dig! # <* Merata'im (dobbelt gjenstridighet) og Pekod (hjemsøkelse) er billedlige navn på Babel; sml. ESK 23, 23.> 
24:50:22: Det er larm av krig i landet og stor ødeleggelse. 
24:50:23: Hvor den er blitt knekket og knust den hammer som slo hele jorden! Hvor Babel er blitt til en forferdelse blandt folkene! 
24:50:24: Jeg stilte snare for dig, og du blev fanget, Babel, uten at du visste av det; du blev funnet og grepet; for mot Herren hadde du gitt dig i strid. 
24:50:25: Herren har åpnet sitt rustkammer og tatt frem sin vredes våben; for Herren, Israels Gud, hærskarenes Gud, har et arbeid fore i kaldeernes land. 
24:50:26: Kom imot det fra alle kanter! Åpne dets forrådshus, dyng op som korndynger det som finnes der, og slå det med bann! La det ikke ha noget igjen! 
24:50:27: Drep alle dets okser*, la dem stige ned for å slaktes! Ve over dem! For deres dag er kommet, deres hjemsøkelses tid. # <* d.e. dets veldige menn.> 
24:50:28: Hør! De er flyktet og undkommet fra Babels land for å kunngjøre i Sion Herrens, vår Guds hevn, hevnen for hans tempel! 
24:50:29: Kall skytterne sammen mot Babel, alle dem som spenner bue! Slå leir mot det rundt omkring, la ingen slippe unda! Betal det efter dets gjerninger, gjør mot det i alle deler som det har gjort! For mot Herren har det ophøiet sig, mot Israels Hellige. 
24:50:30: Derfor skal dets unge menn falle på dets gater, og alle dets krigsmenn skal tilintetgjøres på den dag, sier Herren. 
24:50:31: Se, jeg kommer over dig, Sadon*, sier Herren, Israels Gud, hærskarenes Gud; for din dag er kommet, den tid da jeg hjemsøker dig. # <* Sadon (overmot) er et billedlig navn på Babel; sml. ESK 7, 10.> 
24:50:32: Og Sadon skal snuble og falle uten å ha nogen som reiser det op, og jeg vil sette ild på dets byer, og den skal fortære alt rundt omkring det. 
24:50:33: Så sier Herren, hærskarenes Gud: Undertrykt er både Israels barn og Judas barn, og alle de som har ført dem i fangenskap, holder dem fast; de nekter å la dem fare. 
24:50:34: Deres gjenløser er sterk, Herren, hærskarenes Gud, er hans navn; han skal visselig føre deres sak og la jorden få ro, men volde Babels innbyggere uro. 
24:50:35: Sverd over kaldeerne, sier Herren, over Babels innbyggere, over dets høvdinger og over dets vismenn! 
24:50:36: Sverd over snakkerne*! De skal stå der som dårer. Sverd over dets kjemper! De skal bli forferdet. # <* d.e. de falske spåmenn, stjernetyderne og lign.; JES 44, 25; 47, 12. 13.> 
24:50:37: Sverd over dets hester og over dets vogner og over alle de fremmede krigsfolk blandt dem! De skal bli til kvinner. Sverd over dets skatter! De skal bli røvet. 
24:50:38: Tørke over dets vann! De skal tørkes ut. For det er et land med avgudsbilleder, og med sine gruelige avguder ter de sig som rasende. 
24:50:39: Derfor skal ørkenens dyr bo der sammen med ville hunder, og strutser skal bo i det; og det skal aldri mere reise sig igjen, og slekt efter slekt skal ingen bo der. 
24:50:40: Som da Gud la Sodoma og Gomorra og dets nabobyer i grus, sier Herren, skal ingen mann bo der, og intet menneskebarn opholde sig der. 
24:50:41: Se, et folk kommer fra nord, et stort folk, og mange konger skal stå frem fra jordens ytterste ende. 
24:50:42: Bue og spyd holder de i hånden, de er grusomme og skåner ingen; deres røst bruser som havet, og på hester kommer de ridende, rustet som en krigsmann, mot dig, Babels datter! 
24:50:43: Babels konge har hørt ryktet om dem, og hans hender er blitt kraftløse; angst har grepet ham, smerter som den fødende kvinnes. 
24:50:44: Se, han* stiger op som en løve fra Jordans prakt til de alltid grønne enger; for i et øieblikk vil jeg jage dem** bort derfra, og den som er utvalgt, ham vil jeg sette over det; for hvem er som jeg, og hvem vil stevne mig, og hvem er den hyrde som kan bli stående for mitt åsyn? # <* Babels fiende.> # <** babylonierne.> 
24:50:45: Hør derfor det råd som Herren har lagt mot Babel, og de tanker som han har tenkt mot kaldeernes land: Visselig, de skal bli slept bort de små lam; visselig, deres beitemark skal forferdes over dem. 
24:50:46: Ved det rop: Babel er inntatt, bever jorden, og det høres skrik blandt folkene. 
24:51:1: Så sier Herren: Se, jeg vekker et ødeleggende vær over Babel og over innbyggerne i Leb-Kamai*. # <* Leb-Kamai, d.e. mine motstanderes hjerte eller midtpunkt, et navn på Babel.> 
24:51:2: Og jeg sender fremmede mot Babel, og de skal kaste det som korn og tømme dets land; for de skal falle over det fra alle kanter på ulykkens dag. 
24:51:3: Mot den som spenner bue, skal bueskytteren spenne sin bue, og mot den som kneiser i sin brynje; spar ikke dets unge menn, slå hele dets hær med bann! 
24:51:4: Og drepte menn skal falle i kaldeernes land, og gjennemborede menn på dets gater. 
24:51:5: For Israel og Juda er ikke enker, forlatt av sin Gud, Herren, hærskarenes Gud, skjønt deres land er fullt av skyld mot Israels Hellige. 
24:51:6: Fly ut av Babel, hver mann må redde sitt liv! Se til at I ikke omkommer ved Babels misgjerning! En hevnens tid er det for Herren; han gjengjelder Babel det som det har gjort. 
24:51:7: Babel var et gullbeger i Herrens hånd, som gjorde hele jorden drukken; av dets vin drakk folkene, derfor tedde folkene sig som rasende. 
24:51:8: Med ett er Babel falt og blitt knust; jamre over det, hent balsam for dets smerte! Kanskje det kunde læges. 
24:51:9: Vi har søkt å læge Babel, men det lot sig ikke læge. Forlat det, så vi kan gå hver til sitt land! For dommen over det når til himmelen og hever sig til skyene. 
24:51:10: Herren har latt vår rettferdige sak komme frem i dagen; kom, la oss fortelle i Sion Herrens, vår Guds gjerning! 
24:51:11: Gjør pilene blanke, grip skjoldene! Herren har vakt mederkongenes ånd; for mot Babel er hans tanke rettet, han vil ødelegge det; for det er Herrens hevn, hevnen for hans tempel. 
24:51:12: Løft banner mot Babels murer, hold sterk vakt, sett ut vaktposter, legg bakhold! For Herren har både tenkt ut og setter i verk det som han har talt imot Babels innbyggere. 
24:51:13: Du som bor ved store vann, du som er rik på skatter! Din ende er kommet, målet for din vinning er fullt. 
24:51:14: Herren, hærskarenes Gud, har svoret ved sig selv: Har jeg enn fylt dig med mennesker som med gresshopper, skal det allikevel opløftes et frydeskrik over dig. 
24:51:15: Han er den som skapte jorden ved sin kraft, som grunnfestet jorderike ved sin visdom og utspente himmelen ved sin forstand. 
24:51:16: Ved sin torden lar han vannene i himmelen bruse; han lar dunster stige op fra jordens ende, sender lyn med regn og fører vind ut av sine forrådskammer. 
24:51:17: Hvert menneske blir ufornuftig, uten forstand; hver gullsmed har skam av sine utskårne billeder, hans støpte billeder er løgn, og det er ingen ånd i dem. 
24:51:18: De er tomhet, et verk som vekker spott; på sin hjemsøkelses tid skal de gå til grunne. 
24:51:19: Ikke er han som er Jakobs del, lik dem; for han er den som har skapt alle ting og den ætt som er hans arv; Herren, hærskarenes Gud, er hans navn. 
24:51:20: Du* er min hammer, mitt krigsvåben, og med dig knuser jeg folkeslag, og med dig ødelegger jeg kongeriker; # <* Babel. JER 50, 23.> 
24:51:21: med dig knuser jeg hest og rytter, og med dig knuser jeg vogn og kjører; 
24:51:22: med dig knuser jeg mann og kvinne, og med dig knuser jeg gammel og ung, yngling og jomfru; 
24:51:23: med dig knuser jeg hyrde og hjord, og med dig knuser jeg pløieren og hans okser, og med dig knuser jeg stattholdere og landshøvdinger. 
24:51:24: Men jeg gjengjelder Babel og alle Kaldeas innbyggere for eders øine all den ondskap som de har gjort mot Sion, sier Herren. 
24:51:25: Se, jeg kommer over dig, du fordervelsens berg, sier Herren, du som forderver all jorden, og jeg vil rekke ut min hånd mot dig og velte dig ned fra fjellknausene og gjøre dig til et utbrent berg. 
24:51:26: Og fra dig skal ingen ta sten til hjørne eller sten til grunnvoll; men til evige ørkener skal du bli, sier Herren. 
24:51:27: Løft banner i landet, støt i basun blandt folkene, innvi folkeslag til kamp mot det, kall sammen mot det Ararats, Minnis og Askenas' riker, innsett høvedsmenn imot det, før hester frem som strihårede gresshopper! 
24:51:28: Innvi folkeslag til kamp mot det, kongene i Media, dets stattholdere og alle dets landshøvdinger og hele det land han* råder over! # <* d.e. mederkongen.> 
24:51:29: Da skjelver jorden og bever; for Herrens tanker mot Babel blir fullbyrdet: å gjøre Babels land til en ørken, så ingen bor der. 
24:51:30: Babels kjemper har holdt op å stride, de sitter stille i borgene, deres kraft er borte, de er blitt til kvinner; det er satt ild på dets boliger, dets bommer er sprengt. 
24:51:31: Løper løper mot løper og bud mot bud for å melde Babels konge at hans stad er inntatt på alle kanter, 
24:51:32: vadestedene er i fiendens vold, vanndammene uttørket med ild, og krigsmennene forferdet. 
24:51:33: For så sier Herren, hærskarenes Gud, Israels Gud: Babels datter er som en treskeplass når den blir stampet hård; ennu en liten stund, så kommer høstens tid for henne. 
24:51:34: Babels konge Nebukadnesar har ett oss, har ødelagt oss, har satt oss bort som et tomt kar, har opslukt oss som et sjøuhyre, har fylt sin buk med min kostelige mat; han har drevet oss bort. 
24:51:35: Den vold jeg har lidt i mitt kjøtt, måtte den komme over Babel, så må Sions innbyggere si, og mitt blod komme over Kaldeas innbyggere, så må Jerusalem si. 
24:51:36: Derfor sier Herren så: Se, jeg fører din sak og utfører din hevn, og jeg vil tørke ut dets hav og gjøre dets kilde tørr. 
24:51:37: Og Babel skal bli til grusdynger, en bolig for sjakaler, til en forferdelse og til spott, så ingen bor der. 
24:51:38: De brøler alle sammen som unge løver, de brummer som løveunger. 
24:51:39: Når de er blitt hete*, vil jeg gjøre et drikkelag for dem og gjøre dem drukne, forat de skal juble og så falle i en evig søvn og ikke våkne**, sier Herren. # <* av vin; HSE 7, 5.> # <** V. 57; 25, 26. Es. 21, 5. HAB 2, 16. DNL 5, 1 fg.> 
24:51:40: Jeg vil føre dem ned som lam til å slaktes, som værer og gjetebukker. 
24:51:41: Se, Sesak* er blitt hærtatt, all jordens pris er blitt inntatt! Hvor Babel er blitt til en forferdelse blandt folkene! # <* JER 25, 26.> 
24:51:42: Havet er steget op over Babel, det er skjult av dets brusende bølger. 
24:51:43: Dets byer er blitt til en ørken, et tørt land og en øde mark, et land som ingen mann bor i, og som intet menneskebarn drar igjennem. 
24:51:44: Og jeg hjemsøker Bel i Babel og drar det han har slukt, ut av hans munn, og folkeslag skal ikke mere strømme til ham; også Babels mur er falt. 
24:51:45: Dra ut av det, mitt folk, hver mann må redde sitt liv - for Herrens brennende vrede! 
24:51:46: Og vær ikke motfalne og redde for de rykter som høres i landet, om det i et år kommer et rykte, og derefter i et annet år et annet rykte, og om det råder vold i landet, og hersker reiser sig mot hersker. 
24:51:47: Se, derfor skal dager komme da jeg hjemsøker Babels utskårne billeder, og hele dets land skal bli til skamme, og alle dets menn skal bli drept og falle der. 
24:51:48: Og himmel og jord og alt som i dem er, skal juble over Babel; for fra nord skal ødeleggerne komme over det, sier Herren. 
24:51:49: Også Babel skal falle, I av Israel som er slått ihjel! Også Babels innbyggere skal falle, I som er slått ihjel over den hele jord! 
24:51:50: I som er undkommet fra sverdet, dra bort, stans ikke, kom Herren i hu fra det fjerne land og minnes Jerusalem! 
24:51:51: Vi var blitt til skamme; for vi måtte høre hånsord; skam dekket vårt åsyn, for fremmede var kommet over helligdommene i Herrens hus. 
24:51:52: Se, derfor skal dager komme, sier Herren, da jeg hjemsøker dets utskårne billeder, og i hele dets land skal sårede menn stønne. 
24:51:53: Om Babel enn vil stige op til himmelen, og om det enn vil gjøre sin høie festning utilgjengelig, så skal allikevel ødeleggere fra mig komme over det, sier Herren. 
24:51:54: Skrik lyder fra Babel, det er stor ødeleggelse i kaldeernes land. 
24:51:55: For Herren ødelegger Babel og gjør ende på den sterke larm i byen; og deres* bølger skal bruse som store vann, deres larmende røst skal la sig høre. # <* fiendenes.> 
24:51:56: For over det, over Babel, kommer en ødelegger, og dets kjemper blir fanget, deres buer knekket; for Herren er en gjengjeldelsens Gud, han lønner, han gjengjelder. 
24:51:57: Og jeg vil gjøre dets høvdinger og dets vismenn, dets stattholdere og dets landshøvdinger og dets kjemper drukne, og de skal falle i en evig søvn og ikke våkne, sier kongen, han hvis navn er Herren, hærskarenes Gud. 
24:51:58: Så sier Herren, hærskarenes Gud: Babels mur, den brede, skal rives til grunnen, og dets porter, de høie, skal brennes op med ild, så folkeslag skal ha gjort sig møie for intet, og folkeferd arbeidet sig trette for ilden. 
24:51:59: Dette er det bud som profeten Jeremias gav Seraja, sønn av Nerija, Mahsejas sønn, da han drog til Babel sammen med Judas konge Sedekias i det fjerde år av hans regjering - Seraja var den som sørget for herbergene. 
24:51:60: Jeremias skrev op i en bok all den ulykke som skulde komme over Babel, alle disse ord som er skrevet om Babel. 
24:51:61: Og Jeremias sa til Seraja: Når du kommer til Babel, da se til at du leser op alle disse ord! 
24:51:62: Og du skal si: Herre! Du har talt mot dette sted og sagt at du vil ødelegge det, så det ikke skal være nogen som bor der, hverken menneske eller dyr, men at det skal bli til evige ørkener. 
24:51:63: Og når du er ferdig med å lese op denne bok, da skal du binde en sten til den og kaste den ut i Eufrat, 
24:51:64: og du skal si: Således skal Babel synke og ikke komme op igjen, for den ulykke som jeg lar komme over det, og avmektige skal de ligge der. Her ender Jeremias' ord. 
24:52:1: Sedekias var en og tyve år gammel da han blev konge, og han regjerte elleve år i Jerusalem; hans mor hette Hamital, datter av Jirmeja, og var fra Libna. 
24:52:2: Han gjorde hvad ondt var i Herrens øine, aldeles som Jojakim hadde gjort. 
24:52:3: For på grunn av Herrens vrede gikk det så med Jerusalem og Juda, inntil han kastet dem bort fra sitt åsyn. Og Sedekias falt fra kongen i Babel. 
24:52:4: I hans niende regjeringsår, i den tiende måned, på den tiende dag i måneden, drog kongen i Babel Nebukadnesar med hele sin hær mot Jerusalem og leiret sig imot det, og de bygget skanser mot det rundt omkring. 
24:52:5: Og de holdt byen kringsatt like til kong Sedekias' ellevte år. 
24:52:6: I den fjerde måned, på den niende dag i måneden, var hungersnøden så stor i byen at landets folk ikke hadde noget å ete. 
24:52:7: Og byens mur blev gjennembrutt, og alle krigsmennene flyktet og drog ut av byen om natten gjennem porten mellem begge murene ved kongens have, mens kaldeerne lå leiret mot byen rundt omkring; og de tok veien til ødemarken. 
24:52:8: Men kaldeernes hær satte efter kongen, og den nådde Sedekias igjen på Jerikos ødemarker; og hele hans hær spredte sig og forlot ham. 
24:52:9: Og de grep kongen og førte ham op til Babels konge i Ribla i Hamatlandet, og han avsa dom over ham. 
24:52:10: Og Babels konge drepte Sedekias' sønner for hans øine; også alle Judas høvdinger drepte han i Ribla. 
24:52:11: Og Babels konge lot Sedekias blinde, og han lot ham binde med to kobberlenker og førte ham til Babel og holdt ham i fengsel til hans dødsdag. 
24:52:12: I den femte måned, på den tiende dag i måneden - det var Babels konge Nebukadnesars nittende år - kom Nebusaradan, høvdingen over livvakten, en av de høieste menn hos kongen i Babel, til Jerusalem. 
24:52:13: Han brente op Herrens hus og kongens hus, og alle Jerusalems hus - alle stormennenes hus - brente han op med ild. 
24:52:14: Og hele den hær av kaldeere som høvdingen over livvakten hadde med sig, rev ned alle murene omkring Jerusalem. 
24:52:15: Nebusaradan, høvdingen over livvakten, bortførte nogen av de ringeste av folket og resten av folket, dem som var blitt tilbake i byen, og overløperne som var gått over til kongen i Babel, og resten av hopen. 
24:52:16: Bare nogen av de ringeste i landet lot Nebusaradan, høvdingen over livvakten, bli tilbake som vingårdsmenn og jordbrukere. 
24:52:17: Kaldeerne slo i stykker kobbersøilene i Herrens hus og fotstykkene og kobberhavet i Herrens hus og førte alt kobberet av dem til Babel. 
24:52:18: De tok også askebøttene og ildskuffene og knivene og bollene til å sprenge blod med og røkelseskålene og alle kobberkarene som hadde vært brukt til tjenesten. 
24:52:19: Også skålene og fyrfatene og bollene til å sprenge blod med og askebøttene og lysestakene og røkelseskålene og begerne, alt som var av gull, og alt som var av sølv, det tok høvdingen over livvakten. 
24:52:20: De to søiler, det ene hav og de tolv kobberokser som var under fotstykkene som kong Salomo hadde latt gjøre for Herrens hus - i alle disse ting var kobberet ikke til å veie. 
24:52:21: Og om søilene er å si at den ene søile var atten alen høi, og en tråd på tolv alen nådde omkring den; den var fire fingrer tykk, og hul. 
24:52:22: Og det var et søilehode på den av kobber, og det ene søilehode var fem alen høit, og det var nettverk og granatepler på søilehodet rundt omkring, alt sammen av kobber, og likedan var det med den andre søile, som også hadde granatepler. 
24:52:23: Og granateplene var seks og nitti, som vendte utad; alle granateplene var hundre ved nettverket rundt omkring. 
24:52:24: Høvdingen over livvakten tok ypperstepresten Seraja og Sefanja, den næstøverste prest, og de tre voktere ved dørtreskelen, 
24:52:25: og fra byen tok han en hoffmann som var høvedsmann over krigsfolkene, og syv menn som ennu fantes i byen av dem som stadig hadde adgang til kongen, og skriveren hos hærføreren, han som utskrev landets folk til krigstjeneste, og seksti menn av landets folk som fantes i byen - 
24:52:26: dem tok Nebusaradan, høvdingen over livvakten, og førte dem til Babels konge i Ribla, 
24:52:27: og Babels konge lot dem slå ihjel i Ribla i Hamat-landet. Således blev Juda bortført fra sitt land. 
24:52:28: Dette er tallet på dem som Nebukadnesar bortførte: I det syvende år tre tusen og tre og tyve jøder; 
24:52:29: i Nebukadnesars attende år åtte hundre og to og tretti sjeler fra Jerusalem; 
24:52:30: i Nebukadnesars tre og tyvende år bortførte Nebusaradan, høvdingen over livvakten, syv hundre og fem og firti sjeler av jødene; det var i alt fire tusen og seks hundre sjeler. 
24:52:31: I det syv og trettiende år efterat Judas konge Jojakin var bortført, i den tolvte måned, på den fem og tyvende dag i måneden, tok kongen i Babel Evilmerodak - i det år han blev konge - Judas konge Jojakin til nåde og førte ham ut av fengslet. 
24:52:32: Og han talte vennlig med ham og satte hans stol ovenfor stolene til de andre konger som var hos ham i Babel. 
24:52:33: Han la sin fangedrakt av og åt stadig ved hans bord, så lenge han levde. 
24:52:34: Og han fikk sitt stadige underhold fra Babels konge, hver dag det han den dag trengte, så lenge han levde, like til sin dødsdag. 
25:1:1: Hvor ensom hun sitter, den folkerike stad! Hun er blitt som en enke; den store blandt folkene, fyrstinnen i landene er blitt til træl! 
25:1:2: Sårt gråter hun om natten, og hennes tårer rinner på hennes kinn; hun har ingen trøster blandt alle sine elskere; alle hennes venner har vært troløse mot henne, de er blitt hennes fiender. 
25:1:3: I landflyktighet er Juda vandret, efter trengsel og megen møie; det bor iblandt folkene, har ikke funnet hvile; alle dets forfølgere har innhentet det på trange steder. 
25:1:4: Veiene til Sion sørger fordi ingen kommer til festene; alle dets porter er øde, dets prester sukker, dets jomfruer er sorgfulle, og det selv er bitterlig bedrøvet. 
25:1:5: Dets motstandere er blitt dets herrer, dets fiender er trygge, for Herren har lagt sorg på det for dets mange overtredelsers skyld; dets små barn har fienden ført i fangenskap. 
25:1:6: Fra Sions datter svant all hennes prakt; hennes fyrster blev lik hjorter som ikke finner beite, og de gikk der uten kraft for forfølgerens åsyn. 
25:1:7: I sin elendighets og landflyktighets tid kommer Jerusalem i hu alle de herligheter som hun hadde fra fordums dager; da hennes folk falt for fiendens hånd, og hun ingen hjelper hadde, da så fiendene henne, de spottet over det hun hadde tapt. 
25:1:8: Storlig har Jerusalem syndet, derfor er hun blitt til en vederstyggelighet; alle de som æret henne, forakter henne, for de så hennes blusel; hun selv sukket og vendte sig bort. 
25:1:9: Hennes urenhet hang ved hennes kjortelfliker; hun tenkte ikke på hvad ende det vilde ta med henne; da sank hun på underlig vis, ingen trøstet henne. Herre, se min elendighet! Fienden gjør sig stor. 
25:1:10: Fienden rakte sin hånd ut efter alle hennes kostelige ting; for hun så hedninger komme inn i hennes helligdom, de som du bød ikke skulde komme inn i din menighet. 
25:1:11: Alt hennes folk sukker og søker efter brød; de gir sine kostelige ting bort for mat, for å opholde livet. Se, Herre, se hvor foraktet jeg er blitt! 
25:1:12: Går det eder ikke til hjerte, alle I som går forbi på veien? Sku og se om det finnes en smerte lik den smerte som er voldt mig, den som Herren har bedrøvet mig med på sin brennende vredes dag! 
25:1:13: Fra det høie sendte han ild i mine ben og lot den råde; han spente ut garn for mine føtter, han støtte mig tilbake, han gjorde mig elendig, syk hele dagen. 
25:1:14: Mine overtredelser er knyttet sammen ved hans hånd til et åk; sammenslynget er de lagt på min nakke; han har brutt min kraft. Herren har gitt mig i hendene på dem som jeg ikke kan stå imot. 
25:1:15: Herren forkastet alle de kjemper som fantes hos mig, han kalte sammen en folkeskare mot mig for å knuse mine unge menn; Herren trådte vinpersen for jomfruen, Judas datter. 
25:1:16: Over dette gråter jeg, mitt øie, mitt øie flyter bort i vann; for langt fra mig er trøsteren som kunde husvale min sjel; mine barn er ødelagt, for fienden fikk overhånd. 
25:1:17: Sion rekker ut sine hender, det har ingen trøster; Herren har kalt sammen mot Jakob hans fiender rundt omkring; Jerusalem er blitt til en vederstyggelighet blandt dem. 
25:1:18: Herren er rettferdig, for jeg var gjenstridig mot hans bud. Hør, alle folk, og se min smerte! Mine jomfruer og mine unge menn er gått i fangenskap. 
25:1:19: Jeg ropte på mine elskere; de svek mig. Mine prester og mine eldste opgav ånden i byen da de søkte efter mat for å opholde livet. 
25:1:20: Se, Herre, jeg er i trengsel! Det gjærer i mitt indre, mitt hjerte vender sig i mitt bryst; for jeg har vært gjenstridig; utenfor har sverdet gjort mig barnløs, innenfor er det som døden. 
25:1:21: De hørte at jeg sukket, det var ingen som trøstet mig; alle mine fiender hørte om min ulykke, de gledet sig over at du har gjort det. Men du lar komme en dag som du har forkynt, og da skal de bli som jeg. 
25:1:22: La all deres ondskap komme for ditt åsyn, og gjør mot dem som du har gjort mot mig for alle mine overtredelsers skyld! For mine sukk er mange, og mitt hjerte er sykt. 
25:2:1: Hvor Herren i sin vrede har innhyllet Sions datter i mørke skyer! Han har kastet Israels herlighet fra himmelen til jorden, og han har ikke kommet i hu sine føtters skammel* på sin vredes dag. # <* 1KR 28, 2. SLM 132, 7.> 
25:2:2: Herren har uten skånsel tilintetgjort alle Jakobs boliger, han har i sin vrede brutt ned Judas datters festninger, kastet dem til jorden; han har vanhelliget riket og dets fyrster. 
25:2:3: Han har i brennende vrede avhugget hvert horn i Israel, dradd sin høire hånd tilbake for fiendens åsyn* og satt Jakob i brand lik en ildslue som fortærer rundt omkring. # <* d.e. latt fienden uhindret trenge frem.> 
25:2:4: Han har spent sin bue som en fiende, stilt sig med sin høire hånd som en motstander og drept alt som var en lyst for vårt øie; i Sions datters telt har han utøst sin vrede som ild. 
25:2:5: Herren er blitt som en fiende, han har tilintetgjort Israel, tilintetgjort alle dets palasser, ødelagt dets festninger og hopet sorg på sorg over Judas datter. 
25:2:6: Han har med vold revet ned sitt gjerde som gjerdet om en have, han har ødelagt sitt forsamlingssted; Herren har latt høitid og sabbat bli glemt i Sion, og i sin vrede og harme forskutt konge og prest. 
25:2:7: Herren har forkastet sitt alter, forsmådd sin helligdom; han har overgitt dets* palassers murer i fiendens vold; de** lot sin røst høre i Herrens hus som på en høitidsdag. # <* Jerusalems.> # <** fiendene. SLM 74, 4.> 
25:2:8: Herren tenkte på å ødelegge Sions datters mur, han strakte ut målesnoren, han drog ikke sin hånd tilbake fra herjing, og han lot voller og murer sørge; forfalne ligger de der alle. 
25:2:9: Dets porter er sunket i jorden, han har ødelagt og sprengt dets bommer; dets konge og dets høvdinger bor blandt hedningene, så der ikke er nogen lov; heller ikke har dets profeter fått noget syn fra Herren. 
25:2:10: Sions datters eldste sitter tause på jorden; de har strødd støv på sitt hode, omgjordet sig med sekk; Jerusalems jomfruer har senket sitt hode til jorden. 
25:2:11: Mine øine er borttæret av tårer, det gjærer i mitt indre, min lever er utøst til jorden, fordi mitt folks datter er ødelagt, fordi de små barn og de diende vansmektet på byens gater; 
25:2:12: de ropte til sine mødre: Hvor er korn og vin? - da de vansmektet på byens gater lik sårede, da de opgav ånden ved sine mødres barm. 
25:2:13: Hvad skal jeg vidne for dig, hvad skal jeg ligne dig med, du Jerusalems datter? Hvad skal jeg stille ved siden av dig, så jeg kunde trøste dig, du jomfru, Sions datter? For stor som havet er din skade; hvem kan læge dig? 
25:2:14: Dine profeter har skuet for dig tomme og dårlige ting; de åpenbarte ikke din misgjerning for å avvende ditt fangenskap, men de forkynte dig tomme og villedende spådommer. 
25:2:15: De slår hendene sammen over dig alle de som går forbi på veien; de spotter og ryster på hodet over Jerusalems datter: Er dette den stad de kalte skjønnhetens krone, all jordens glede? 
25:2:16: Alle dine fiender spiler op sin munn mot dig, de spotter og skjærer tenner, de sier: Vi har ødelagt den; ja, dette er den dag vi har ventet på; vi har oplevd den, vi har sett den. 
25:2:17: Herren har gjort det han hadde tenkt, han har fullbyrdet sitt ord som han hadde forkynt alt i fordums dager, han har brutt ned uten skånsel; og han lot fienden glede sig over dig, han lot dine motstandere bære sine horn høit. 
25:2:18: Deres hjerte roper til Herren. - Du Sions datters mur! La tårer rinne som bekker dag og natt, unn dig ikke nogen hvile, la ikke ditt øie ha ro! 
25:2:19: Stå op, rop høit om natten, når nattevaktene begynner! Utøs ditt hjerte som vann for Herrens åsyn! Løft dine hender til ham for dine barns liv, de som vansmekter av hunger på alle gatehjørner! 
25:2:20: Se, Herre, se! Hvem har du gjort således med? Skal kvinner ete sin livsfrukt, de spede barn som bæres på armen? Skal prest og profet slås ihjel i Herrens helligdom? 
25:2:21: Ung og gammel ligger på jorden i gatene, mine jomfruer og mine unge menn er falt for sverdet; du slo ihjel på din vredes dag, du slaktet uten skånsel. 
25:2:22: Som på en høitidsdag kalte du redsler over mig fra alle kanter, og det var ikke på Herrens vredes dag nogen som slapp unda eller blev reddet; dem som jeg hadde båret på armen og opfostret, dem ødela min fiende. 
25:3:1: Jeg er den mann som har sett elendighet under hans vredes ris. 
25:3:2: Mig har han ledet og ført i mørke og ikke i lys. 
25:3:3: Bare mot mig vender han atter og atter sin hånd den hele dag. 
25:3:4: Han lot mitt kjøtt og min hud fortæres; han knuste mine ben. 
25:3:5: Han bygget en mur mot mig og omringet mig med bitterhet og møie. 
25:3:6: På mørke steder lot han mig bo som de for lenge siden døde. 
25:3:7: Han murte igjen for mig, så jeg ikke kan komme ut; han gjorde mine lenker tunge. 
25:3:8: Om jeg enn ropte og skrek, lukket han sitt øre for min bønn. 
25:3:9: Han tilmurte mine veier med hugne stener, mine stier gjorde han krokete. 
25:3:10: En lurende bjørn var han mot mig, en løve i skjul. 
25:3:11: Mine veier gjorde han til avveier, han sønderrev mig og ødela mig. 
25:3:12: Han spente sin bue og stilte mig op til mål for sin pil. 
25:3:13: Han lot sitt koggers sønner fare inn i mine nyrer. 
25:3:14: Jeg er blitt til latter for alt mitt folk, til en spottesang for dem hele dagen. 
25:3:15: Han mettet mig med bitre urter, han gav mig rikelig malurt å drikke. 
25:3:16: Han knuste mine tenner, han gav mig småsten å ete, han trykte mig ned i asken. 
25:3:17: Du forkastet mig og tok bort min fred; jeg glemte det som godt er, 
25:3:18: og jeg sa: Det er forbi med min kraft og mitt håp til Herren. 
25:3:19: Kom i hu min elendighet og min landflyktighet - malurt og galle! 
25:3:20: Min sjel kommer det i hu og er nedbøiet i mig. 
25:3:21: Dette vil jeg ta mig til hjerte, derfor vil jeg håpe: 
25:3:22: Herrens miskunnhet er det at det ikke er forbi med oss; for hans barmhjertighet har ennu ikke ende. 
25:3:23: Den er ny hver morgen, din trofasthet er stor. 
25:3:24: Herren er min del, sier min sjel; derfor håper jeg på ham. 
25:3:25: Herren er god mot dem som bier efter ham, mot den sjel som søker ham. 
25:3:26: Det er godt at en bier i stillhet efter Herrens frelse. 
25:3:27: Det er godt for en mann at han bærer åk i sin ungdom, 
25:3:28: at han sitter ene og tier, når han* legger byrder på ham, # <* Herren.> 
25:3:29: at han trykker sin munn i støvet og sier: Kanskje det ennu er håp - 
25:3:30: at han vender sitt kinn til den som slår ham, lar sig mette med hån. 
25:3:31: For Herren forkaster ikke til evig tid, 
25:3:32: men om han bedrøver, så forbarmer han sig igjen efter sin rike miskunnhet; 
25:3:33: for det er ikke av hjertet han plager eller bedrøver menneskenes barn. 
25:3:34: Når nogen knuser alle jordens fanger under sine føtter, 
25:3:35: bøier mannens rett for den Høiestes åsyn 
25:3:36: eller gjør en mann urett i hans sak - mon Herren ikke ser det? 
25:3:37: Hvem talte så det skjedde, uten at Herren bød det? 
25:3:38: Er det ikke fra den Høiestes munn både de onde og de gode ting utgår? 
25:3:39: Hvorfor klager et menneske som lever? Enhver klage over sin egen synd! 
25:3:40: La oss ransake våre veier og granske dem, og la oss vende om til Herren! 
25:3:41: La oss løfte vårt hjerte og våre hender til Gud i himmelen! 
25:3:42: Vi har syndet og vært gjenstridige; du har ikke tilgitt. 
25:3:43: Du innhyllet dig i vrede og forfulgte oss; du slo ihjel, du sparte ikke. 
25:3:44: Du innhyllet dig i skyer, så ingen bønn trengte igjennem. 
25:3:45: Til skarn og utskudd gjorde du oss midt iblandt folkene. 
25:3:46: De spilte op sin munn mot oss alle våre fiender. 
25:3:47: Gru og grav er blitt oss til del, ødeleggelse og undergang. 
25:3:48: Bekker av tårer rinner fra mitt øie fordi mitt folks datter er gått under. 
25:3:49: Mitt øie rinner og har ikke ro, det får ingen hvile, 
25:3:50: før Herrens øie ser ned fra himmelen. 
25:3:51: Mitt øie volder min sjel smerte for alle min stads døtres skyld. 
25:3:52: Hårdt jaget de mig som en fugl de som var mine fiender uten årsak. 
25:3:53: De vilde gjøre ende på mitt liv, de vilde kaste mig i brønnen, og de kastet sten på mig. 
25:3:54: Vannene strømmet over mitt hode; jeg sa: Jeg er fortapt. 
25:3:55: Jeg påkalte ditt navn, Herre, fra den dypeste brønn. 
25:3:56: Du hørte min røst; lukk ikke ditt øre for mitt rop, men la mig få lindring! 
25:3:57: Du var nær den dag jeg kalte på dig; du sa: Frykt ikke! 
25:3:58: Herre, du har ført min sjels sak, du har frelst mitt liv. 
25:3:59: Herre, du har sett den urett jeg har lidt; døm i min sak! 
25:3:60: Du har sett all deres hevn, alle deres onde råd mot mig. 
25:3:61: Du har hørt deres hån, Herre, alle deres onde råd mot mig, 
25:3:62: mine motstanderes tale og deres tanker mot mig den hele dag. 
25:3:63: Akt på dem når de sitter, og når de står op! De synger spottesanger om mig. 
25:3:64: Du vil gjøre gjengjeld mot dem, Herre, efter deres henders gjerning. 
25:3:65: Du vil legge et dekke over deres hjerte, din forbannelse vil bli dem til del. 
25:3:66: Du vil forfølge dem i vrede og ødelegge dem, så de ikke mere finnes under Herrens himmel. 
25:4:1: Hvor gullet blir mørkt, det edleste gull forandret, de hellige stener strødd omkring ved alle gatehjørner! 
25:4:2: Sions barn, de dyrebare, like i verd med det fineste gull, hvor de er blitt aktet som lerkrukker, et verk av en pottemakers hender! 
25:4:3: Endog sjakaler rekker bryst, gir sine unger die; mitt folks datter er blitt grusom som strutsen i ørkenen. 
25:4:4: Diebarnets tunge henger fast ved ganen av tørst; små barn ber om brød, det er ingen som deler ut til dem, 
25:4:5: De som åt fine retter, ligger elendige på gatene; de som blev båret på skarlagen, favner møkkdynger. 
25:4:6: Så blev straffen over mitt folks datter større enn straffen over Sodoma, som blev lagt i grus i et øieblikk, uten at hender blev løftet imot det. 
25:4:7: Hennes fyrster var renere enn sne, hvitere enn melk; de var rødere på legemet enn koraller; som safir var deres utseende. 
25:4:8: Mørkere enn sort er nu deres utseende, de blir ikke kjent på gatene; deres hud henger ved deres ben, den er blitt tørr som tre. 
25:4:9: Lykkeligere var de som blev drept ved sverd, enn de som blev drept ved hunger, de som tærtes bort og gikk til grunne av mangel på brød. 
25:4:10: Ømhjertede kvinner kokte selv sine egne barn, de tjente dem til føde da mitt folks datter gikk under. 
25:4:11: Herren uttømte sin harme, han utøste sin brennende vrede og tendte en ild i Sion, og den fortærte dets grunnvoller. 
25:4:12: Jordens konger og alle som bodde på jorderike, trodde ikke at nogen motstander og fiende skulde komme inn gjennem Jerusalems porter. 
25:4:13: For dets profeters synder, dets presters misgjerninger, de som utøste rettferdiges blod i byen, er det skjedd. 
25:4:14: De vanket omkring på gatene som blinde, tilsølt med blod, så ingen kunde røre ved deres klær. 
25:4:15: Vik bort! Uren! ropte folk til dem - vik bort, vik bort, rør ikke ved oss! For de har flyktet og vanker omkring; det sies blandt folkene: De skal ikke bli her lenger! 
25:4:16: Herrens åsyn har spredt dem, han ser ikke mere til dem; prester akter de* ikke, over de gamle forbarmer de sig ikke. # <* kaldeerne; JES 47, 6.> 
25:4:17: Da det* ennu stod, stirret våre matte øine forgjeves efter hjelp; på vårt vakttårn speidet vi efter et folk som ikke kunde frelse oss**. # <* d.e. Jerusalem.> # <** d.e. egypterne. JES 30, 5. 7. JER 37, 5-8.> 
25:4:18: De lurte på våre skritt, så vi ikke kunde gå på våre gater; vår ende var kommet nær, vår tid var omme, ja, vår ende var kommet. 
25:4:19: Våre forfølgere var raskere enn himmelens ørner; på fjellene forfulgte de oss, i ørkenen lurte de på oss. 
25:4:20: Vår livsånde, Herrens salvede, blev fanget i deres graver, han om hvem vi sa: I hans skygge vil vi leve blandt folkene. 
25:4:21: Fryd dig og gled dig bare, Edoms datter, du som bor i landet Us! Også til dig skal begeret komme; du skal bli drukken og klæ dig naken. 
25:4:22: Din straff er til ende, Sions datter! Han vil ikke mere bortføre dig. Han vil hjemsøke dig for din misgjerning, Edoms datter, åpenbare dine synder. 
25:5:1: Kom i hu, Herre, det som har hendt oss, sku og se hvor vi blir hånet! 
25:5:2: Vår arv er gått over til fremmede, våre hus til utlendinger. 
25:5:3: Vi er blitt farløse, har ingen far; våre mødre er som enker. 
25:5:4: Vi må kjøpe det vann vi drikker, vår ved må vi betale. 
25:5:5: Våre forfølgere er på nakken av oss; vi er trette, vi får ingen hvile. 
25:5:6: Til Egypten har vi overgitt oss, og til Assyria, for å bli mettet med brød. 
25:5:7: Våre fedre har syndet, de er ikke mere; vi bærer deres misgjerninger. 
25:5:8: Træler hersker over oss; ingen river oss ut av deres hånd. 
25:5:9: Med fare for vårt liv henter vi vårt brød, truet av ørkenens sverd. 
25:5:10: Vår hud brenner som en ovn av hungerens luer. 
25:5:11: Kvinner har de krenket i Sion, jomfruer i Judas byer. 
25:5:12: Fyrster har de hengt, de gamles åsyn har de ikke hedret. 
25:5:13: Unge menn bar kvernen, og gutter segnet under vedbøren. 
25:5:14: De gamle sitter ikke mere i porten, de unge menn ikke mere ved sin strengelek. 
25:5:15: Med vårt hjertes glede er det forbi, vår dans er omskiftet til sorg. 
25:5:16: Kronen er falt av vårt hode; ve oss, vi har syndet. 
25:5:17: Derfor er vårt hjerte sykt, derfor er våre øine blitt dimme, 
25:5:18: for Sions bergs skyld, som er øde; rever løper om på det. 
25:5:19: Du, Herre, troner til evig tid, din trone blir fra slekt til slekt. 
25:5:20: Hvorfor skulde du glemme oss evig, forlate oss for så lang en tid? 
25:5:21: Herre, før oss atter til dig, så vi kan komme tilbake! Forny våre dager, så de blir som i fordums tid! 
25:5:22: For skulde du rent ha forkastet oss? Skulde du være så storlig vred på oss? 
26:1:1: Det skjedde i det trettiende år, i den fjerde måned, på den femte dag i måneden, mens jeg var blandt de bortførte ved elven Kebar, at himmelen åpnedes, og jeg så syner fra Gud. 
26:1:2: På den femte dag i måneden - det var det femte år efterat kong Jojakin var blitt bortført - 
26:1:3: da kom Herrens ord til presten Esekiel, Busis sønn, i kaldeernes land ved elven Kebar, og Herrens hånd kom der over ham. 
26:1:4: Og jeg så, og se, en stormvind kom fra nord, en stor sky med stadig luende ild; en strålende glans omgav den, og midt i den, midt i ilden, viste det sig noget som så ut som blankt metall. 
26:1:5: Og midt i den viste det sig noget som lignet fire livsvesener, som så således ut: De hadde et menneskes skikkelse, 
26:1:6: og hvert av dem hadde fire ansikter og hvert av dem fire vinger. 
26:1:7: Deres ben var rette, og deres fotblad var som kloven på en kalv, og de blinket likesom skinnende kobber. 
26:1:8: Og de hadde menneskehender under vingene på alle fire sider, og alle fire hadde ansikter og vinger. 
26:1:9: Deres vinger rørte ved hverandre; de vendte sig ikke når de gikk; hvert av dem gikk rett frem. 
26:1:10: Og deres ansikt lignet et menneskeansikt, og løveansikt hadde de alle fire på høire side, og okseansikt hadde de alle fire på venstre side, og ørneansikt hadde de også alle fire. 
26:1:11: Således var deres ansikter. Og deres vinger stod ut fra hverandre oventil; hvert av dem hadde to vinger som rørte ved det andre, og to som dekket deres legemer. 
26:1:12: Og hvert av dem gikk rett frem; dit hvor ånden vilde gå, gikk de; de vendte sig ikke når de gikk. 
26:1:13: Og livsvesenenes utseende var likt glør, som brente likesom bluss; ilden fór om imellem livsvesenene, og den hadde en strålende glans, og det gikk lyn ut av ilden. 
26:1:14: Og livsvesenene fór frem og tilbake likesom lynglimt. 
26:1:15: Og jeg så livsvesenene, og se, det var et hjul på jorden ved siden av hvert av livsvesenene, og de vendte ut mot fire sider. 
26:1:16: Hjulene så ut som om de var gjort av noget som lignet krysolitt, og alle fire var lik hverandre, og det så ut som om de var gjort således at det ene hjul var inne i det andre. 
26:1:17: Til alle fire sider gikk de når de gikk; de vendte sig ikke når de gikk. 
26:1:18: Hjulringene var høie og fryktelige, og de var fulle av øine rundt omkring på alle de fire hjul. 
26:1:19: Og når livsvesenene gikk, så gikk hjulene ved siden av dem, og når livsvesenene hevet sig op fra jorden, så hevet også hjulene sig. 
26:1:20: Hvor ånden vilde gå, gikk de, nettop dit hvor ånden vilde gå; og hjulene hevet sig ved siden av dem, for livsvesenenes ånd var i hjulene. 
26:1:21: Når livsvesenene gikk, så gikk også de, og når de stod, så stod også de, og når de hevet sig op fra jorden, så hevet hjulene sig ved siden av dem, for livsvesenenes ånd var i hjulene. 
26:1:22: Og over livsvesenenes hoder var det noget som lignet en hvelving, som blendende krystall å se til, utspent ovenover deres hoder. 
26:1:23: Og under hvelvingen stod deres vinger rett ut, så de rørte ved hverandre; hvert av dem hadde på begge sider to vinger som dekket deres legemer. 
26:1:24: Og når de gikk, hørte jeg lyden av deres vinger; det var som lyden av store vann, som den Allmektiges røst, et bulder som bulderet av en leir; når de stod, senket de sine vinger. 
26:1:25: Og det lød en røst ovenover hvelvingen som var over deres hode; når de stod, senket de sine vinger. 
26:1:26: Og ovenover hvelvingen som var over deres hode, var det noget som så ut til å være av safirsten, og som lignet en trone, og ovenpå det som lignet en trone, var det en skikkelse, som et menneske å se til. 
26:1:27: Og jeg så noget som lignet blankt metall, å se til som ild, med en ring av schmelk omkring fra der hvor hans lender syntes å være, og opover. Og fra der hvor hans lender syntes å være, og nedover så jeg noget som så ut som ild, og den var omgitt av en strålende glans; 
26:1:28: likesom synet av buen i skyen på en regndag, således var synet av glansen rundt omkring. Således så Herrens herlighet ut, slik som den viste sig for mig. Og da jeg så det, falt jeg på mitt ansikt og hørte røsten av en som talte. 
26:2:1: Og han sa til mig: Menneskesønn! Stå op på dine føtter, så jeg kan tale med dig! 
26:2:2: Og det kom ånd i mig da han talte til mig, og den fikk mig til å stå på mine føtter, og jeg hørte på ham som talte til mig. 
26:2:3: Og han sa til mig: Menneskesønn! Jeg sender dig til Israels barn, til hedninger, oprørerne, som har gjort oprør mot mig; de og deres fedre har vært troløse mot mig helt til den dag idag. 
26:2:4: Og til barna med de frekke ansikter og hårde hjerter sender jeg dig, og du skal si til dem: Så sier Herren, Israels Gud. 
26:2:5: Og enten de hører eller lar det være - for en gjenstridig ætt er de - så skal de vite at en profet har vært midt iblandt dem. 
26:2:6: Men du menneskesønn, frykt ikke for dem, og frykt ikke for deres ord! For nesler og torner er du omgitt av, og mellem skorpioner bor du. Frykt ikke for deres ord og bli ikke forferdet for deres åsyn! For en gjenstridig ætt er de. 
26:2:7: Og du skal tale mine ord til dem enten de hører eller lar det være; for gjenstridige er de. 
26:2:8: Men du menneskesønn, hør på det som jeg taler til dig! Vær ikke gjenstridig som den gjenstridige ætt! Lat op din munn og et det som jeg gir dig! 
26:2:9: Og jeg så, og se, en hånd var rakt ut til mig, og i den var det en bokrull. 
26:2:10: Og han bredte den ut foran mig, og der var skrevet på den både på fremsiden og på baksiden; det var klagesanger og sukk og ve-rop som var skrevet på den. 
26:3:1: Og han sa til mig: Menneskesønn! Et det du her finner for dig! Et denne bokrull, og gå avsted og tal til Israels hus! 
26:3:2: Da lot jeg op min munn, og han gav mig denne rull å ete 
26:3:3: og sa til mig: Menneskesønn! Mett din buk og fyll dine innvoller med denne rull som jeg gir dig! Og jeg åt, og den var i min munn søt som honning. 
26:3:4: Og han sa til mig: Menneskesønn! Gå til Israels hus og tal til dem med mine ord! 
26:3:5: For ikke til et folk med vanskelig sprog og tungt mæle sendes du, men til Israels hus; 
26:3:6: ikke til mange folk med vanskelig sprog og tungt mæle, hvis ord du ikke forstår; sannelig, om jeg sendte dig til dem, skulde de høre på dig. 
26:3:7: Men Israels hus skal ikke ville høre på dig, for det vil ikke høre på mig; for hele Israels hus har hårde panner og forherdede hjerter. 
26:3:8: Se, jeg gjør ditt åsyn hårdt mot deres åsyn og din panne hård mot deres panne. 
26:3:9: Lik en diamant, hårdere enn sten, gjør jeg din panne; du skal ikke frykte for dem og ikke forferdes for deres åsyn; for en gjenstridig ætt er de. 
26:3:10: Og han sa til mig: Menneskesønn! Alle de ord som jeg taler til dig, skal du ta inn i ditt hjerte og høre med dine ører. 
26:3:11: Og gå til de bortførte, til ditt folks barn, og tal til dem og si: Så sier Herren, Israels Gud - enten de hører eller lar det være! 
26:3:12: Da løftet et vær mig op, og jeg hørte bak mig lyden av et stort bulder: Lovet være Herrens herlighet fra det sted hvor han er, 
26:3:13: og lyden av livsvesenenes vinger, som rørte ved hverandre, og lyden av hjulene ved siden av dem og lyden av et stort bulder. 
26:3:14: Og et vær løftet mig op og tok mig, og jeg fór avsted, gram og harmfull i min ånd, og Herrens hånd var sterk over mig. 
26:3:15: Og jeg kom til de bortførte i Tel-Abib, de som bodde ved elven Kebar, og hvor de ellers bodde, og jeg satt der i syv dager iblandt dem og stirret frem for mig. 
26:3:16: Men da syv dager var gått, da kom Herrens ord til mig, og det lød således: 
26:3:17: Menneskesønn! Jeg har satt dig til vekter for Israels hus, og når du hører et ord av min munn, skal du advare dem fra mig. 
26:3:18: Når jeg sier til den ugudelige: Du skal visselig dø, og du ikke advarer ham og ikke taler og advarer den ugudelige for hans ugudelige ferd for å holde ham i live, da skal han, den ugudelige, dø for sin misgjernings skyld, men hans blod vil jeg kreve av din hånd. 
26:3:19: Men når du har advart den ugudelige, og han ikke vender om fra sin ugudelighet og fra sin ugudelige ferd, da skal han dø for sin misgjernings skyld, men du har reddet din sjel. 
26:3:20: Og når en rettferdig vender sig bort fra sin rettferdighet og gjør urett, så legger jeg en anstøtssten i hans vei - han skal dø. Når du ikke har advart ham, skal han dø i sin synd, og de rettferdige gjerninger som han har gjort, skal ikke kommes i hu, men hans blod vil jeg kreve av din hånd. 
26:3:21: Men når du har advart den rettferdige om at han, den rettferdige, ikke skal synde, og han da ikke synder, da skal han visselig leve, fordi han lot sig advare, og du har reddet din sjel. 
26:3:22: Og Herrens hånd kom over mig der, og han sa til mig: Stå op og gå ut i dalen! Der vil jeg tale med dig. 
26:3:23: Da stod jeg op og gikk ut i dalen, og se, der stod Herrens herlighet, lik den herlighet jeg hadde sett ved elven Kebar; og jeg falt på mitt ansikt. 
26:3:24: Men ånden kom i mig og fikk mig til å stå på mine føtter; og han talte med mig og sa til mig: Gå og steng dig inne i ditt hus! 
26:3:25: Og du menneskesønn! Se, de skal legge rep om dig og binde dig med dem, så du ikke kommer ut iblandt dem. 
26:3:26: Og din tunge vil jeg la henge fast ved din gane, så du blir målløs og ikke blir i stand til å refse dem; for en gjenstridig ætt er de. 
26:3:27: Men når jeg taler med dig, vil jeg åpne din munn, og du skal si til dem: Så sier Herren, Israels Gud. Den som vil høre, han høre, og den som vil la det være, han la det være! For en gjenstridig ætt er de. 
26:4:1: Og du menneskesønn, ta dig en mursten og legg den foran dig og riss på den en by, Jerusalem, 
26:4:2: og reis bolverk mot den og bygg skanser mot den og kast op en voll mot den og still op leire mot den og sett murbrekkere mot den rundt omkring! 
26:4:3: Og ta dig så en jernpanne og sett den som en jernvegg mellem dig og byen og vend ditt ansikt mot den, så den blir kringsatt, og du kringsetter den. Dette skal være et tegn for Israels hus. 
26:4:4: Og så skal du legge dig på din venstre side og legge Israel-folkets misgjerning på den! I så mange dager som du ligger på den side, skal du bære deres misgjerning; 
26:4:5: og jeg vil la deres misgjernings år for dig bli like så mange dager - tre hundre og nitti dager - så lenge skal du bære Israel-folkets misgjerning. 
26:4:6: Og når du er kommet til ende med dem, så skal du annen gang legge dig på din høire side og bære Juda-folkets misgjerning; i firti dager, en dag for hvert år, vil jeg la dig bære den. 
26:4:7: Og du skal vende ditt åsyn og din nakne arm mot det kringsatte Jerusalem og profetere mot det. 
26:4:8: Og se, jeg legger rep om dig, så du ikke kan vende dig fra den ene side til den andre, før du er kommet til ende med de dager du holder det kringsatt. 
26:4:9: Og ta dig så hvete og bygg og bønner og linser og hirse og spelt og legg dem i ett kar og bak dig brød av dem! I så mange dager som du ligger på din ene side, i tre hundre og nitti dager, skal du ete det. 
26:4:10: Og den mat som du eter, skal være efter vekt, tyve sekel til hver dag; fra tid til tid skal du ete den. 
26:4:11: Det vann du drikker, skal også være efter mål, sjettedelen av en hin; fra tid til tid skal du drikke det. 
26:4:12: Som en byggkake skal du ete det, med menneskeskarn skal du steke det for deres øine. 
26:4:13: Og Herren sa: Således skal Israels barn ete sitt brød urent blandt de folk som jeg vil drive dem bort til. 
26:4:14: Da sa jeg: Akk, Herre, Herre! Se, min sjel er ikke blitt uren, og noget selvdødt eller sønderrevet har jeg ikke ett fra min ungdom like til nu, og det er ikke kommet urent kjøtt i min munn. 
26:4:15: Så sa han til mig: Vel, jeg vil la dig ta kumøkk i stedet for menneskeskarn, så du kan steke ditt brød over den. 
26:4:16: Og han sa til mig: Menneskesønn! Se, jeg sønderbryter brødets stav i Jerusalem, og de skal ete brød efter vekt og med bekymring og drikke vann efter mål og med forferdelse, 
26:4:17: så de skal lide mangel på brød og vann og forferdes, den ene med den andre, og visne bort i sin misgjerning. 
26:5:1: Og du menneskesønn, ta dig et skarpt sverd! Bruk det som en rakekniv og la det gå over ditt hode og skjegg, og ta dig så en skålvekt og del både hår og skjegg. 
26:5:2: En tredjedel skal du brenne op midt i byen, når kringsetningsdagene er til ende, og en tredjedel skal du ta og hugge i med sverdet rundt omkring byen, og en tredjedel skal du sprede for vinden, og med draget sverd vil jeg forfølge dem. 
26:5:3: Men nogen få hår skal du ta unda, og dem skal du knytte inn i dine kjortelfliker, 
26:5:4: og av dem skal du igjen ta nogen og kaste dem midt i ilden og brenne dem op; derfra skal det gå ut ild til alt Israels hus. 
26:5:5: Så sier Herren, Israels Gud: Dette Jerusalem satte jeg midt iblandt hedningefolkene og la land rundt omkring det; 
26:5:6: men det var gjenstridig mot mine lover, så det var mere ugudelig enn hedningefolkene, og mot mine bud, så det var mere ugudelig enn landene rundt omkring det; for de forkastet mine lover og fulgte ikke mine bud. 
26:5:7: Derfor sier Herren, Israels Gud, så: Fordi I har rast verre enn folkene som bor rundt omkring eder, fordi I ikke har fulgt mine bud og ikke gjort efter mine lover og ikke engang gjort efter de folks lover som bor rundt omkring eder, 
26:5:8: derfor sier Herren, Israels Gud, så: Se, jeg kommer over dig, jeg og, og jeg vil holde dom hos dig for folkenes øine, 
26:5:9: og jeg vil gjøre med dig hvad jeg ikke før har gjort og aldri mere vil gjøre maken til - for alle dine vederstyggeligheters skyld. 
26:5:10: Derfor skal foreldre ete sine barn hos dig, og barn ete sine foreldre, og jeg vil holde dom hos dig og sprede alt som blir igjen av dig, for alle vinder. 
26:5:11: Derfor, så sant jeg lever, sier Herren, Israels Gud, sannelig, fordi du har gjort min helligdom uren med alle dine avskyeligheter og alle dine vederstyggeligheter, så vil også jeg vende mitt øie bort fra dig uten skånsel og ikke spare dig. 
26:5:12: Tredjedelen av dig skal dø av pest og fortæres av hunger midt i dig, og tredjedelen skal falle for sverd rundt omkring dig, og tredjedelen vil jeg sprede for alle vinder og forfølge med draget sverd. 
26:5:13: Og min vrede skal uttømmes, og jeg vil stille min harme på dem og ta hevn, og de skal kjenne at jeg, Herren, har talt i min nidkjærhet, når jeg uttømmer min harme på dem. 
26:5:14: Og jeg vil gjøre dig* til en ørken og til en spott blandt folkene som bor rundt omkring dig, for øinene på hver den som går forbi. # <* Jerusalem.> 
26:5:15: Du skal bli til spott og hån, til en advarsel og en forferdelse for folkene som bor rundt omkring dig, når jeg holder dom over dig med vrede og harme og med harmfulle tuktelser - jeg, Herren, har talt - 
26:5:16: når jeg sender inn blandt dem hungerens onde piler, som er til ødeleggelse, dem som jeg sender for å ødelegge eder, når jeg hoper op hunger over eder og sønderbryter brødets stav for eder. 
26:5:17: Jeg vil sende hunger over eder og ville dyr, og de skal gjøre dig barnløs, og pest og blod skal gå over dig, og sverd vil jeg la komme over dig; jeg, Herren, har talt. 
26:6:1: Og Herrens ord kom til mig, og det lød så: 
26:6:2: Menneskesønn! Vend ditt åsyn mot Israels fjell og spå mot dem 
26:6:3: og si: I Israels fjell! Hør Herrens, Israels Guds ord! Så sier Herren, Israels Gud, til fjellene og haugene, til bekkene og dalene: Se, jeg lar sverd komme over eder og gjør eders offerhauger til intet. 
26:6:4: Eders altere skal ødelegges, og eders solstøtter knuses, og jeg vil la eders drepte falle for eders motbydelige avguders åsyn. 
26:6:5: Og jeg vil legge Israels barns døde kropper for eders motbydelige avguders åsyn og sprede eders ben rundt omkring eders altere. 
26:6:6: Hvor I så bor, skal byene ødelegges og offerhaugene bli forlatt, forat eders altere må bli ødelagt og forlatt, og eders motbydelige avguder knust og gjort til intet, og eders solstøtter nedhugget, og eders henders verk utslettet; 
26:6:7: drepte menn skal falle midt iblandt eder, og I skal kjenne at jeg er Herren. 
26:6:8: Men jeg vil la en levning bli igjen, så nogen av eder undgår sverdet ute blandt folkene, når I blir spredt omkring i landene. 
26:6:9: Og de av eder som slipper unda, skal komme mig i hu blandt folkene, hvor de er i fangenskap, når jeg har knust deres utro hjerter som var veket fra mig, og deres øine som i utroskap fulgte deres motbydelige avguder; og de skal vemmes ved sig selv for de onde gjerningers skyld som de har gjort - for alle sine vederstyggeligheter, 
26:6:10: og de skal kjenne at jeg er Herren. Det er ikke for ingen ting jeg har talt om å føre denne ulykke over dem. 
26:6:11: Så sier Herren, Israels Gud: Slå dine hender sammen og stamp med din fot og rop akk og ve over alle de onde vederstyggeligheter i Israels hus; for de skal falle for sverdet, for hunger og for pest. 
26:6:12: Den som er langt borte, skal dø av pest, og den som er nær, skal falle for sverdet, og den som er igjen og er berget, skal dø av hunger, og jeg vil uttømme min harme på dem. 
26:6:13: Og I skal kjenne at jeg er Herren, når deres drepte menn ligger midt iblandt deres motbydelige avguder, rundt omkring deres altere, på hver høi bakke, på alle fjelltoppene og under hvert grønt tre og hver løvrik terebinte, på alle de steder hvor de ofret alle sine motbydelige avguder en velbehagelig duft. 
26:6:14: Og jeg vil rekke ut min hånd mot dem og gjøre landet til en ørk og et øde verre enn Dibla-ørkenen, hvor de så bor, og de skal kjenne at jeg er Herren. 
26:7:1: Og Herrens ord kom til mig, og det lød så: 
26:7:2: Og du menneskesønn! Så sier Herren, Israels Gud, til Israels land: Ende! - Enden kommer over landets fire hjørner. 
26:7:3: Nu kommer enden over dig, og jeg vil sende min vrede mot dig og dømme dig efter din ferd, og jeg vil la dig bøte for alle dine vederstyggeligheter. 
26:7:4: Jeg vil ikke vise skånsel mot dig og ikke spare dig; men jeg vil la dig bøte for din ferd, og dine vederstyggeligheter skal komme over dig selv, og I skal kjenne at jeg er Herren. 
26:7:5: Så sier Herren, Israels Gud: Ulykke, en ulykke uten like, se, den kommer. 
26:7:6: Det kommer en ende, enden kommer, den våkner op og kommer til dig; se, det kommer. 
26:7:7: Nu kommer turen til dig, du som bor i landet! Tiden kommer, dagen er nær, krigslarm er der og ikke fryderop på fjellene. 
26:7:8: Nu, om en liten stund, vil jeg utøse min harme over dig og uttømme min vrede på dig og dømme dig efter din ferd, og jeg vil la dig bøte for alle dine vederstyggeligheter. 
26:7:9: Jeg vil ikke vise skånsel og ikke spare dig; efter din ferd vil jeg gjengjelde dig, og dine vederstyggeligheter skal komme over dig selv, og I skal kjenne at jeg, Herren, slår. 
26:7:10: Se, dagen er der! Se, det kommer! Nu begynner det, riset blomstrer, overmotet* grønnes; # <* d.e. de overmodige kaldeere; JER 50, 31 fg.> 
26:7:11: voldsomheten* reiser sig til et ris over ugudeligheten. Intet av dem, intet av deres larmende hop, intet av deres buldrende mengde, intet herlig blir tilbake iblandt dem. # <* d.e. de voldsomme kaldeere; HAB 1, 6 fg.> 
26:7:12: Tiden kommer, dagen er nær. Kjøperen glede sig ikke, og selgeren gremme sig ikke! For det kommer brennende vrede over hele deres hop. 
26:7:13: Selgeren skal ikke få igjen det han har solgt, om han enn er i live; for spådommen mot hele deres hop skal ikke tas tilbake, og for sin misgjernings skyld skal ingen kunne trygge sig i sitt liv. 
26:7:14: De støter i basun og gjør alt ferdig, men ingen drar ut til strid; for min harme er vendt mot hele deres hop. 
26:7:15: Sverdet utenfor og pesten og hungeren innenfor; den som er ute på marken, skal dø ved sverdet, og den som er inne i byen, ham skal hunger og pest fortære. 
26:7:16: Og om nogen av dem undkommer, skal de alle være på fjellene som kurrende duer i dalene, hver for sin misgjernings skyld. 
26:7:17: Alle hender skal synke, og alle knær skal bli som vann. 
26:7:18: Og de skal binde sekk om sig, og redsel skal legge sig over dem, og alle ansikter skal dekkes av skam, og alle deres hoder skal være skallet. 
26:7:19: Sitt sølv skal de kaste på gatene, og sitt gull skal de akte for urent; deres sølv og deres gull skal ikke kunne berge dem på Herrens vredes dag; de skal ikke kunne mette sig eller fylle sin buk med det. For det har vært et anstøt til misgjerning for dem, 
26:7:20: og sine prektige smykker som er laget av det, har de brukt til storaktighet, og sine vederstyggelige billeder, sine avskyelige avguder har de gjort av det; derfor gjør jeg det til urenhet for dem. 
26:7:21: Og jeg vil gi det i de fremmedes hånd som rov og til jordens ugudelige som hærfang, og de skal vanhellige det; 
26:7:22: Og jeg vil vende mitt åsyn bort fra dem*, og de** skal vanhellige min skatt***; røvere skal komme inn over den og vanhellige den. # <* jødene.> # <** fiendene.> # <*** tempelskatten.> 
26:7:23: Gjør lenken ferdig*! For landet er fullt av bloddommer, og byen er full av vold. # <* til tegn på folkets nær forestående fangenskap.> 
26:7:24: Og jeg vil la de verste av hedningene komme, og de skal ta deres hus i eie, og jeg vil gjøre ende på de frekkes stolthet, og deres helligdommer skal vanhelliges. 
26:7:25: Det skal komme redsel, og de skal søke frelse, men der er ingen. 
26:7:26: Ulykke på ulykke skal komme, og rykte på rykte skal opstå, og de skal søke efter syn hos profeten, og lov skal ikke være å finne hos presten eller råd hos de gamle; 
26:7:27: kongen skal sørge, og høvdingen skal klæ sig i forferdelse, og hendene på landets folk skal skjelve. Efter deres ferd vil jeg gjøre med dem, og med deres dommer vil jeg dømme dem, og de skal kjenne at jeg er Herren. 
26:8:1: Og det skjedde i det sjette år, i den sjette måned, på den femte dag i måneden, mens jeg satt i mitt hus, og Judas eldste satt foran mig, at Herrens, Israels Guds hånd falt på mig der. 
26:8:2: Og jeg så, og se, det var noget som var som ild å se til; fra det som var å se av hans lender og nedover, var alt som ild, og fra hans lender og opover var det som en glans å se til, som skinnet av det blanke metall. 
26:8:3: Og han rakte ut noget som lignet en hånd, og tok mig ved håret på mitt hode, og et vær løftet mig op mellem jorden og himmelen og førte mig i syner fra Gud til Jerusalem, til inngangen av den indre forgårds port, som vender mot nord, der hvor nidkjærhets-billedet stod, det som vakte Guds nidkjærhet. 
26:8:4: Og der så jeg Israels Guds herlighet, likesom i det syn jeg hadde sett i dalen. 
26:8:5: Og han sa til mig: Menneskesønn, løft dine øine mot nord! Og jeg løftet mine øine mot nord og fikk se nidkjærhets-billedet nordenfor alterporten ved inngangen. 
26:8:6: Og han sa til mig: Menneskesønn! Ser du hvad disse gjør? Store vederstyggeligheter er det Israels hus her gjør, så jeg må dra langt bort fra min helligdom; men du skal ennu få se flere store vederstyggeligheter. 
26:8:7: Og han førte mig til inngangen av forgården, og jeg så, og se, der var et hull i veggen. 
26:8:8: Og han sa til mig: Menneskesønn, bryt igjennom veggen! Og jeg brøt igjennem veggen og fikk se en dør. 
26:8:9: Og han sa til mig: Gå inn og se de fæle vederstyggeligheter som de gjør der! 
26:8:10: Og jeg gikk inn og så, og se, der var alle slags billeder av vederstyggelige kryp og firføtte dyr og alle Israels folks motbydelige avguder, inngravd på veggene rundt omkring. 
26:8:11: Og foran dem stod sytti menn av de eldste i Israels folk, og Ja'asanja, Safans sønn, stod midt iblandt dem, og hver av dem hadde et røkelsekar i hånden, og det steg op duft av røkelseskyen. 
26:8:12: Og han sa til mig: Har du sett, menneskesønn, hvad de eldste i Israels folk gjør i mørket, hver i sine billedkammer? For de sier: Herren ser oss ikke, Herren har forlatt landet. 
26:8:13: Og han sa til mig: Ennu skal du få se flere store vederstyggeligheter som de gjør. 
26:8:14: Og han førte mig til inngangen av porten til Herrens hus, den port som vender mot nord, og se, der satt kvinnene og gråt over Tammus*. # <* en babylonisk avgud som svarer til grekernes Adonis.> 
26:8:15: Og han sa til mig: Har du sett det, menneskesønn? Ennu skal du få se flere vederstyggeligheter, større enn disse. 
26:8:16: Og han førte mig inn i den indre forgård til Herrens hus, og se, ved inngangen til Herrens tempel, mellem forhallen og alteret, var det omkring fem og tyve menn som vendte ryggen mot Herrens tempel og ansiktet mot øst, og de bøide sig mot øst for solen. 
26:8:17: Og han sa til mig: Har du sett det, menneskesønn? Var det ikke nok for Judas hus å gjøre de vederstyggeligheter de her har gjort, siden de også har opfylt landet med vold og atter vakt min harme? Se hvorledes de nu holder kvisten op til sin nese*. # <* så gjorde perserne når de bad til solen.> 
26:8:18: Derfor vil da også jeg la min harme råde; jeg vil ikke vise skånsel og ikke spare dem; og om de roper for mine ører med høi røst, vil jeg ikke høre dem. 
26:9:1: Og jeg hørte ham rope med høi røst og si: Kom hit med hjemsøkelsene over staden, hver med sitt ødeleggelsesredskap i sin hånd! 
26:9:2: Og se, det kom seks menn på veien gjennem den øvre port, som vender mot nord, hver med sitt ødeleggelsesredskap i sin hånd, og midt iblandt dem var det en mann som var klædd i linklær og hadde et skrivetøi ved sin lend, og de kom inn og stilte sig ved siden av kobberalteret. 
26:9:3: Og Israels Guds herlighet hevet sig op fra kjerubene, som den hvilte på, og flyttet sig til husets dørtreskel, og han ropte til den mann som var klædd i linklærne og hadde skrivetøiet ved sin lend. 
26:9:4: Og Herren sa til ham: Gå midt igjennem staden, midt igjennem Jerusalem, og sett et tegn i pannen på de menn som sukker og jamrer over alle de vederstyggelige ting som skjer der. 
26:9:5: Og til de andre hørte jeg ham si: Gå gjennem staden efter ham og slå ned! Vis ikke skånsel og spar ingen! 
26:9:6: Gamle folk, unge menn og jomfruer, spebarn og kvinner skal I hugge ned for fote; men I må ikke røre nogen av dem som tegnet er på. I skal begynne fra min helligdom. Og de begynte med de gamle menn som stod foran huset. 
26:9:7: Og han sa til dem: Gjør huset urent og fyll forgårdene med drepte! Dra ut! Og de drog ut og slo ned folket i staden. 
26:9:8: Men mens de slo ned, og jeg blev igjen, falt jeg på mitt ansikt og ropte: Akk, Herre, Herre! Vil du ødelegge alt som er igjen av Israel, idet du utøser din harme over Jerusalem? 
26:9:9: Og han sa til mig: Israels og Judas folks misgjerning er over all måte stor; landet er fullt av blodskyld, og staden er full av urettferdige dommer; for de sier: Herren har forlatt landet, Herren ser det ikke. 
26:9:10: Derfor vil heller ikke jeg vise skånsel og ikke spare dem; jeg vil la deres gjerninger komme over deres eget hode. 
26:9:11: Og se, den mann som var klædd i linklærne og hadde skrivetøiet ved sin lend, kom tilbake og meldte: Jeg har gjort som du bød mig. 
26:10:1: Og jeg så, og se, på den hvelving som var over kjerubenes hode, var det noget som syntes å være en safirsten, noget som lignet en trone; han* såes over dem. # <* Herren; ESK 10, 18. 19.> 
26:10:2: Og han sa til den mann som var klædd i linklærne: Gå inn mellem hjulene, inn under kjerubene, og fyll dine hender med glør av dem som er mellem kjerubene, og strø dem ut over staden! Og han gikk inn mens jeg så på det. 
26:10:3: Og kjerubene stod på høire side av huset da mannen gikk inn, og skyen fylte den indre forgård. 
26:10:4: Og Herrens herlighet hevet sig op fra kjerubene og flyttet sig til husets dørtreskel, og huset fyltes av skyen, og forgården blev full av glansen fra Herrens herlighet. 
26:10:5: Og lyden av kjerubenes vinger hørtes helt til den ytre forgård, likesom den allmektige Guds røst når han taler. 
26:10:6: Da han nu bød mannen som var klædd i linklærne: Ta av den ild som er mellem hjulene, mellem kjerubene! - da gikk han inn og stilte sig ved siden av hjulet. 
26:10:7: Og den ene kjerub rakte ut sin hånd fra kjerubene til ilden som var mellem kjerubene, og tok av den og la i hendene på ham som var klædd i linklærne; og han tok den og gikk ut. 
26:10:8: Og det såes på kjerubene noget som lignet en menneskehånd, under deres vinger. 
26:10:9: Og jeg så, og se, det var fire hjul ved siden av kjerubene, ett hjul ved hver kjerub, og hjulene var å se til som krysolitt. 
26:10:10: I utseende var de alle fire aldeles lik hverandre; det var som om det ene hjul var inne i det andre. 
26:10:11: Når de gikk, gikk de til alle fire sider; de vendte sig ikke når de gikk, men til den kant som den forreste var vendt imot, dit gikk de; de vendte sig ikke når de gikk. 
26:10:12: Og hele deres legeme og deres rygger og deres hender og deres vinger og hjulene var fulle av øine rundt omkring - alle deres fire hjul. 
26:10:13: Jeg hørte hjulene blev kalt hvirvel. 
26:10:14: Hver av dem hadde fire ansikter; den førstes ansikt var et kjerubansikt*, den annens et menneskeansikt, den tredjes et løveansikt og den fjerdes et ørneansikt. # <* d.e. okseansikt.> 
26:10:15: Og kjerubene hevet sig; det var de livsvesener jeg hadde sett ved elven Kebar. 
26:10:16: Og når kjerubene gikk, gikk hjulene ved siden av dem, og når kjerubene løftet sine vinger for å heve sig op fra jorden, skilte hjulene sig ikke fra dem. 
26:10:17: Når kjerubene stod, stod også de, og når kjerubene hevet sig, hevet de sig med dem; for livsvesenenes ånd var i dem. 
26:10:18: Og Herrens herlighet flyttet sig bort fra husets dørtreskel og blev stående over kjerubene. 
26:10:19: Og jeg så hvorledes kjerubene løftet sine vinger og hevet sig fra jorden da de gikk ut, og hjulene ved siden av dem; og de blev stående ved inngangen til den østre port i Herrens hus, og Israels Guds herlighet var ovenover dem. 
26:10:20: Det var de livsvesener jeg hadde sett under Israels Gud ved elven Kebar, og jeg skjønte at det var kjeruber. 
26:10:21: Hvert av dem hadde fire ansikter og fire vinger, og noget som lignet menneskehender, var der under deres vinger. 
26:10:22: Og deres ansikter var lik de ansikter jeg hadde sett ved elven Kebar; således så de ut, og således var de; hvert av dem gikk rett frem. 
26:11:1: Og et vær løftet mig op og førte mig til den østre port i Herrens hus, den som vender mot øst, og ved inngangen til porten så jeg fem og tyve menn og midt iblandt dem Ja'asanja, Assurs sønn, og Pelatja, Benajas sønn, som var høvdinger blandt folket. 
26:11:2: Og Herren sa til mig: Menneskesønn! Dette er de menn som tenker ut urett og gir onde råd i denne stad, 
26:11:3: de som sier: Det blir ikke så snart tale om å bygge hus*; den** er gryten, og vi er kjøttet. # <* JER 29, 5.> # <** staden.> 
26:11:4: Derfor, spå mot dem, spå du menneskesønn! 
26:11:5: Og Herrens Ånd falt på mig, og han sa til mig: Si: Så sier Herren: Således har I sagt, I av Israels hus, og de tanker som stiger op i eders ånd, dem kjenner jeg. 
26:11:6: Mange er de som I har drept i denne stad; I har fylt dens gater med drepte. 
26:11:7: Derfor sier Herren, Israels Gud, så: De som I har drept og latt ligge midt i staden, de er kjøttet, og den er gryten; men I skal føres ut av den. 
26:11:8: I frykter for sverdet, og sverdet vil jeg la komme over eder, sier Herren, Israels Gud. 
26:11:9: Jeg vil føre eder ut av den og gi eder i fremmedes hånd, og jeg vil holde dom over eder. 
26:11:10: For sverdet skal I falle; ved Israels grense vil jeg dømme eder, og I skal kjenne at jeg er Herren. 
26:11:11: Den skal ikke være en gryte for eder, og I skal ikke være kjøttet i den; ved Israels grense vil jeg dømme eder. 
26:11:12: Og I skal kjenne at jeg er Herren, hvis bud I ikke har fulgt, og hvis lover I ikke har holdt; men I har holdt eder efter de hedningefolks lover som bor rundt omkring eder. 
26:11:13: Mens jeg holdt på å spå, døde Pelatja, Benajas sønn, og jeg falt på mitt ansikt og ropte med høi røst og sa: Akk, Herre, Herre! Vil du aldeles gjøre ende på det som er igjen av Israel? 
26:11:14: Og Herrens ord kom til mig, og det lød så: 
26:11:15: Menneskesønn! Dine brødre, dine brødre, dine nære frender og hele Israels hus, hele folket*, er det til hvem Jerusalems innbyggere sier: Hold eder langt borte fra Herren! Oss er landet gitt til eiendom. # <* Talen er om dem som med Jekonja var bortført til Babel; 2KG 24, 12 fg.> 
26:11:16: Derfor skal du si: Så sier Herren, Israels Gud: Da jeg lot dem fare langt bort iblandt hedningefolkene, da jeg spredte dem i landene, blev jeg en kort tid en helligdom* for dem i de land de var kommet til. # <* JES 8, 14.> 
26:11:17: Derfor skal du si: Så sier Herren, Israels Gud: Jeg vil samle eder fra folkene og sanke eder fra de land som I er spredt i, og jeg vil gi eder Israels land. 
26:11:18: Og når de kommer dit, da skal de få bort derfra alle de avskyeligheter og vederstyggeligheter som finnes der. 
26:11:19: Og jeg vil gi dem ett hjerte, og en ny ånd vil jeg gi i deres indre; jeg vil ta bort stenhjertet av deres kjød og gi dem et kjødhjerte, 
26:11:20: så de skal følge mine bud og holde mine lover og gjøre efter dem; og de skal være mitt folk, og jeg vil være deres Gud. 
26:11:21: Men de hvis hjerte følger deres avskyeligheter og vederstyggeligheter*, deres gjerninger vil jeg la komme over deres eget hode, sier Herren, Israels Gud. # <* deres avguder.> 
26:11:22: Da løftet kjerubene sine vinger, og hjulene fulgte med, og Israels Guds herlighet var ovenover dem. 
26:11:23: Og Herrens herlighet steg op fra staden, og den blev stående på det berg som er østenfor staden. 
26:11:24: Og et vær løftet mig op og førte mig i synet ved Guds Ånd til Kaldea, til de bortførte; og synet som jeg hadde sett, steg op fra mig. 
26:11:25: Og jeg talte til de bortførte alle de ord som Herren hadde latt mig se. 
26:12:1: Og Herrens ord kom til mig, og det lød så: 
26:12:2: Menneskesønn! Du bor midt iblandt den gjenstridige ætt, som har øine å se med, men ikke ser, og ører å høre med, men ikke hører; for en gjenstridig ætt er de. 
26:12:3: Og du menneskesønn, rust dig ut til å gå i landflyktighet og dra ut om dagen midt for deres øine! Dra fra det sted hvor du bor, til et annet sted midt for deres øine! Kanskje de da vil komme til å se; for en gjenstridig ætt er de. 
26:12:4: De ting du kan få med dig, skal du bære ut om dagen midt for deres øine, som om du skulde gå i landflyktighet. og selv skal du ta ut om aftenen midt for deres øine, likesom de som går i landflyktighet. 
26:12:5: Bryt dig et hull i veggen midt for deres øine og bær dine ting ut gjennem den! 
26:12:6: Midt for deres øine skal du ta det på skulderen; i mørket skal du bære det ut; ditt ansikt skal du tildekke, så du ikke ser landet; for jeg har satt dig til et varselstegn for Israels hus. 
26:12:7: Og jeg gjorde således som det var sagt mig: Mine ting bar jeg ut om dagen. som om jeg skulde gå i landflyktighet, og om aftenen brøt jeg mig med hånden et hull i veggen; i mørket bar jeg mine ting ut; på skulderen tok jeg dem midt for deres øine. 
26:12:8: Og Herrens ord kom til mig morgenen efter, og det lød så: 
26:12:9: Menneskesønn! Har ikke Israels hus, den gjenstridige ætt, sagt til dig: Hvad gjør du? 
26:12:10: Si til dem: Så sier Herren, Israels Gud: Denne flytning gjelder fyrsten* i Jerusalem og alt Israels hus som er der inne. # <* Sedekias.> 
26:12:11: Si: Jeg er et varselstegn for eder; likesom jeg har gjort, så skal det gjøres med dem; i landflyktighet, i fangenskap skal de gå. 
26:12:12: Og fyrsten som er iblandt dem, skal ta sine ting på skulderen i mørket og dra ut; de skal bryte et hull i muren og føre ham ut gjennem den; sitt ansikt skal han tildekke, forat han ikke skal se landet med sine øine. 
26:12:13: Og jeg vil utspenne mitt garn over ham, og han skal fanges i mitt nett, og jeg vil føre ham til Babel i kaldeernes land; men det skal han ikke se*, og der skal han dø. # <* 2KG 25, 7.> 
26:12:14: Alle som er omkring ham, hans hjelpere, og alle hans krigsskarer vil jeg sprede for alle vinder, og jeg vil forfølge dem med draget sverd. 
26:12:15: Og de skal kjenne at jeg er Herren, når jeg spreder dem blandt folkene og strør dem ut i landene. 
26:12:16: Bare nogen få av dem vil jeg la bli igjen efter sverdet og hungeren og pesten, forat de skal fortelle om alle deres vederstyggeligheter iblandt de folk som de kommer til, og de skal kjenne at jeg er Herren. 
26:12:17: Og Herrens ord kom til mig, og det lød så: 
26:12:18: Menneskesønn! Ditt brød skal du ete skjelvende, og ditt vann skal du drikke bevende og med frykt. 
26:12:19: Og du skal si til landets folk: Så sier Herren, Israels Gud, om Jerusalems innbyggere i Israels land: Sitt brød skal de ete med frykt, og sitt vann skal de drikke med forferdelse, fordi deres land skal bli øde og miste sin fylde for de voldsgjerningers skyld som alle dets innbyggere har gjort. 
26:12:20: Og de byer som det nu bor folk i, skal legges øde, og landet bli en ørken, og I skal kjenne at jeg er Herren. 
26:12:21: Og Herrens ord kom til mig, og det lød så: 
26:12:22: Menneskesønn! Hvad er dette for et ord I fører i munnen i Israels land, når I sier: Tiden blir lang, og hvert syn blir til intet? 
26:12:23: Derfor skal du si til dem: Så sier Herren, Israels Gud: Jeg vil gjøre ende på dette ord som de fører i munnen, så de ikke mere skal bruke det i Israel; men si til dem: Nu er tiden nær da ordene i hvert syn skal bli opfylt! 
26:12:24: For det skal ikke mere være noget tomt syn eller nogen dårende spådom i Israels hus; 
26:12:25: for jeg, Herren, taler det ord jeg taler, og det skal skje, det skal ikke lenger utsettes; for i eders dager, du gjenstridige ætt, taler jeg et ord og setter det i verk, sier Herren, Israels Gud. 
26:12:26: Og Herrens ord kom til mig, og det lød så: 
26:12:27: Menneskesønn! Se, Israels hus sier: Det syn han skuer, opfylles først efter mange dager, og det er om fjerne tider han spår. 
26:12:28: Derfor skal du si til dem: Så sier Herren, Israels Gud: Intet av alt jeg har sagt, skal utsettes lenger; det ord jeg taler, det skal skje, sier Herren, Israels Gud. 
26:13:1: Og Herrens ord kom til mig, og det lød så: 
26:13:2: Menneskesønn! Spå mot Israels profeter, som gir sig av med å spå, og si til dem som spår efter sitt eget hjerte: Hør Herrens ord! 
26:13:3: Så sier Herren, Israels Gud: Ve over de dårlige profeter, som følger sin egen ånd og syner som de ikke har sett! 
26:13:4: Som rever blandt ruiner er dine profeter, Israel! 
26:13:5: I er ikke steget op i murbruddene og har ikke bygget nogen mur om Israels hus, så de kunde holde stand i striden på Herrens dag. 
26:13:6: Deres syner var tomhet, og deres spådommer løgn, de som sier: Så sier Herren, enda Herren ikke har sendt dem, og som håper på at deres ord skal bli stadfestet. 
26:13:7: Er det ikke tomme syner I har skuet, og løgnaktige spådommer I har uttalt? I sier: Så sier Herren, enda jeg ikke har talt. 
26:13:8: Derfor sier Herren, Israels Gud, så: Fordi I taler tomhet og skuer løgn, se, derfor vil jeg komme over eder, sier Herren, Israels Gud. 
26:13:9: Og min hånd skal være imot de profeter som skuer tomhet og spår løgn; i mitt folks råd skal de ikke være, og i Israels folks bok skal de ikke skrives, og til Israels land skal de ikke komme, og I skal kjenne at jeg er Herren, Israels Gud, 
26:13:10: fordi, ja fordi de har ført mitt folk vill og sagt: Fred! - enda det ikke er nogen fred. Når folket bygger en vegg, se, da stryker de den over med kalk. 
26:13:11: Si til kalkstrykerne at den vil falle; det kommer et skyllregn, og I haglstener skal falle, og en stormvind skal bryte inn. 
26:13:12: Når så veggen er falt, vil det da ikke bli sagt til eder: Hvor er kalken som I strøk den over med? 
26:13:13: Derfor sier Herren, Israels Gud, så: Ja, jeg vil i min harme la en stormvind bryte inn, og i min vrede la det komme et skyllregn og i min harme haglstener, så alt blir ødelagt. 
26:13:14: Og jeg vil rive ned veggen som I har strøket over med kalk, og la den styrte til jorden, så dens grunnvoll blir lagt bar; når staden* faller. skal I omkomme midt i den, og I skal kjenne at jeg er Herren. # <* d.e. Jerusalem.> 
26:13:15: Og jeg vil uttømme min harme på veggen og på dem som strøk den over med kalk, og jeg vil si til eder: Veggen er ikke mere til, heller ikke de som strøk den over, 
26:13:16: Israels profeter, som spådde om Jerusalem og skuet fredssyner for det, enda det ikke er nogen fred, sier Herren, Israels Gud. 
26:13:17: Og du menneskesønn! Vend ditt ansikt mot ditt folks døtre, som spår efter sitt eget hjerte, og spå mot dem 
26:13:18: og si: Så sier Herren, Israels Gud: Ve dem som syr dekker over alle ledd på mine hender og gjør hetter til hodet på folk efter enhvers høide for å fange sjeler! Mitt folks sjeler fanger I, og eders egne sjeler holder I i live. 
26:13:19: Og I vanhelliger mig hos mitt folk for nogen håndfull bygg og for nogen stykker brød, for å drepe sjeler som ikke skal dø, og for å holde sjeler i live som ikke skulde leve, idet I lyver for mitt folk, som gjerne hører på løgn. 
26:13:20: Derfor sier Herren, Israels Gud, så: Se, jeg vil ta fatt i eders dekker som I fanger sjelene med likesom fugler, og jeg vil rive dem bort fra eders armer, og de sjeler som I fanger, dem vil jeg la slippe fri som fugler. 
26:13:21: Og jeg vil sønderrive eders hetter og utfri mitt folk av eders hånd, og de skal ikke mere være et bytte i eders hånd, og I skal kjenne at jeg er Herren. 
26:13:22: Fordi I ved løgn gjør den rettferdiges hjerte motløst, uten at jeg har bedrøvet ham, og styrker den ugudeliges hender, så han ikke vender om fra sin onde vei, og jeg kan la ham leve, 
26:13:23: derfor skal I ikke mere skue tomhet eller spå falske spådommer, og jeg vil utfri mitt folk av eders hånd, og I skal kjenne at jeg er Herren. 
26:14:1: Og det kom til mig nogen av Israels eldste, og de satte sig foran mig. 
26:14:2: Da kom Herrens ord til mig, og det lød så: 
26:14:3: Menneskesønn! Disse menn har gitt sine motbydelige avguder rum i sitt hjerte, og det som er dem et anstøt til misgjerning, har de satt for sine øine; skulde jeg la mig spørre av dem? 
26:14:4: Tal derfor med dem og si til dem: Så sier Herren, Israels Gud: Hver den av Israels hus som gir sine motbydelige avguder rum i sitt hjerte og setter det som er ham et anstøt til misgjerning, for sine øine og så kommer til profeten, ham vil jeg, Herren, selv svare* for hans mange motbydelige avguders skyld, # <* nemlig i gjerning ved å straffe ham; ESK 14, 8.> 
26:14:5: for å gripe Israels hus i deres hjerte, fordi de alle har veket bort fra mig ved sine motbydelige avguder. 
26:14:6: Si derfor til Israels hus: Så sier Herren, Israels Gud: Vend om og vend eder bort fra alle eders motbydelige avguder og vend eders åsyn bort fra alle eders vederstyggeligheter! 
26:14:7: For hver den av Israels hus og av de fremmede som bor i Israel, som skiller sig fra mig og gir sine motbydelige avguder rum i sitt hjerte og setter det som er ham et anstøt til misgjerning, for sine øine og så kommer til profeten for å spørre mig for sig, ham vil jeg, Herren, selv svare på min vis. 
26:14:8: Jeg vil sette mitt åsyn mot den mann og ødelegge ham, så han blir til et tegn og til et ordsprog, og jeg vil utrydde ham av mitt folk, og I skal kjenne at jeg er Herren. 
26:14:9: Og om profeten lar sig forlokke og taler et ord, da er det jeg, Herren, som har forlokket denne profet, og jeg vil rekke ut min hånd mot ham og utslette ham av mitt folk Israel. 
26:14:10: Og de skal lide for sin misgjerning; som det er med spørgerens misgjerning, så skal det være med profetens misgjerning, 
26:14:11: forat Israels hus ikke mere skal forville sig bort fra mig og ikke mere gjøre sig uren med alle sine overtredelser, men være mitt folk, og jeg deres Gud, sier Herren, Israels Gud. 
26:14:12: Og Herrens ord kom til mig, og det lød så: 
26:14:13: Menneskesønn! Når et land synder mot mig og gjør sig skyldig i troløshet, og jeg rekker ut min hånd mot det og sønderbryter brødets stav for det og sender hungersnød over det og utrydder både mennesker og fe av det, 
26:14:14: og om da disse tre menn, Noah, Daniel og Job, var der i landet, da skulde de ved sin rettferdighet bare kunne berge sitt eget liv, sier Herren, Israels Gud. 
26:14:15: Om jeg lot ville dyr fare gjennem landet, og de gjorde det folketomt, og det blev til en ørken, så ingen kunde dra gjennem det for de ville dyrs skyld, 
26:14:16: da skulde, så sant jeg lever, sier Herren, Israels Gud, disse tre menn, om de var der, hverken kunne berge sønner eller døtre; bare de selv skulde bli berget, men landet skulde bli en ørken. 
26:14:17: Eller om jeg lot sverd komme over det land og sa: Sverd skal fare gjennem landet, og jeg utryddet både mennesker og fe av det, 
26:14:18: og disse tre menn var der i landet, da skulde de, så sant jeg lever, sier Herren, Israels Gud, ikke kunne berge sønner eller døtre, bare de selv skulde bli berget. 
26:14:19: Eller om jeg sendte pest i det land og utøste min harme over det med blod for å utrydde både mennesker og fe av det, 
26:14:20: og Noah, Daniel og Job var der i landet, da skulde de, så sant jeg lever, sier Herren, Israels Gud, hverken kunne berge sønn eller datter; bare sitt eget liv skulde de berge ved sin rettferdighet. 
26:14:21: For så sier Herren, Israels Gud: Enn når jeg sender mine fire onde straffedommer, sverd, hungersnød og ville dyr og pest, over Jerusalem for å utrydde både mennesker og fe av det! 
26:14:22: Allikevel skal nogen undkomme og bli igjen der, nogen sønner og døtre, som blir bortført, og de skal da komme ut til eder, og I skal se deres ferd og deres gjerninger, og I skal trøste eder over den ulykke jeg lot komme over Jerusalem, over alt det jeg lot komme over det. 
26:14:23: Og de skal trøste eder når I ser deres ferd og deres gjerninger, og I skal kjenne at jeg ikke uten årsak har gjort noget av alt det jeg har gjort mot det, sier Herren, Israels Gud. 
26:15:1: Og Herrens ord kom til mig, og det lød så: 
26:15:2: Menneskesønn! Hvad har veden av vintreet forut for annen ved, det vintreskudd som er vokset op blandt skogens trær? 
26:15:3: Tar en vel ved av det for å bruke den til noget arbeid? Eller gjør nogen en nagle av den til å henge noget redskap på? 
26:15:4: Nei, en gir ilden den til føde; ilden fortærer begge endene, og midten blir forbrent; duer den vel da til å gjøre noget arbeid med? 
26:15:5: Mens den ennu var hel, bruktes den ikke til noget arbeid; hvor meget mindre når ilden har fortært den, og den er forbrent! Kan den enda brukes til å gjøre noget arbeid med? 
26:15:6: Derfor sier Herren, Israels Gud, så: Som det går med veden av vintreet blandt skogens trær, den som jeg gir ilden til føde, således gjør jeg med Jerusalems innbyggere. 
26:15:7: Jeg vil sette mitt åsyn imot dem; de har sloppet ut av ilden, men ilden skal fortære dem, og I skal kjenne at jeg er Herren, når jeg setter mitt åsyn imot dem. 
26:15:8: Og jeg vil gjøre landet til en ørken, fordi de har gjort sig skyldige i troløshet, sier Herren, Israels Gud. 
26:16:1: Og Herrens ord kom til mig, og det lød så: 
26:16:2: Menneskesønn! Forehold Jerusalem dets vederstyggeligheter 
26:16:3: og si: Så sier Herren, Israels Gud, til Jerusalem: Ditt ophav og din avstamning er fra kana'anittenes land; en amoritt var din far, og en hetittkvinne din mor. 
26:16:4: Og med din fødsel gikk det således til: Den dag du blev født, blev din navlestreng ikke avskåret, og du blev ikke tvettet ren med vann og ikke inngnidd med salt og ikke svøpt i svøp. 
26:16:5: Intet øie ynkedes over dig, så de gjorde noget sådant med dig og forbarmet sig over dig; men du blev kastet ut på marken den dag du blev født, fordi de ikke ønsket at du skulde leve. 
26:16:6: Da gikk jeg forbi dig og så dig sprelle i ditt blod, og jeg sa til dig: Du som ligger der i ditt blod, lev! Ja, jeg sa til dig: Du som ligger der i ditt blod, lev! 
26:16:7: Jeg gjorde dig til mange tusen som vekstene på marken, og du vokste og blev stor og nådde den høieste skjønnhet; brystene blev faste, og ditt hår vokste. Men du var naken og bar. 
26:16:8: Da gikk jeg forbi dig og så dig, og se, din tid var kommet, elskovens tid, og jeg bredte min kappe over dig og skjulte din blusel, og jeg tilsvor dig troskap og gjorde pakt med dig, sier Herren, Israels Gud, og du blev min. 
26:16:9: Og jeg tvettet dig med vann og skylte blodet av dig og salvet dig med olje. 
26:16:10: Jeg klædde dig med utsydde klær og hadde på dig sko av takas-skinn, og jeg bandt fint lin om dig og hyllet dig i silke. 
26:16:11: Jeg prydet dig med smykker, og jeg la armbånd om dine hender og en kjede om din hals. 
26:16:12: Jeg satte en ring i din nese og ørenringer i dine ører og en prektig krone på ditt hode. 
26:16:13: Så smykket du dig med gull og sølv, og din klædning var av fint lin og silke og utsydd tøi; fint mel og honning og olje åt du, og du blev overmåte fager og vel skikket til kongedømme. 
26:16:14: Og ditt navn kom ut blandt folkene for din skjønnhets skyld; for den var fullkommen på grunn av de herlige prydelser som jeg hadde klædd dig i, sier Herren, Israels Gud. 
26:16:15: Men du stolte på din skjønnhet og drev hor i tillit til ditt navn, og du utøste ditt hor over hver den som gikk forbi; han fikk nyte din skjønnhet. 
26:16:16: Du tok dine klær og gjorde dig brokete telt på offerhaugene og drev hor der; sådant må ikke hende og ikke skje. 
26:16:17: Du tok dine prektige smykker, mitt gull og mitt sølv, som jeg hadde gitt dig, og gjorde dig mannfolkebilleder og drev hor med dem. 
26:16:18: Du tok dine utsydde klær og dekket dem med, og min olje og min røkelse satte du frem for dem. 
26:16:19: Mitt brød, som jeg hadde gitt dig, det fine mel og oljen og honningen som jeg gav dig å ete, det satte du frem for dem til en velbehagelig duft; så vidt gikk det, sier Herren, Israels Gud. 
26:16:20: Du tok dine sønner og dine døtre, som du hadde født mig, og ofret dem til mat for dem. Var det ikke nok at du drev hor, 
26:16:21: siden du slaktet mine barn og gav dem bort - lot dem gå igjennem ilden for dem? 
26:16:22: Og ved alle dine vederstyggeligheter og ditt hor kom du ikke din ungdoms dager i hu, da du var naken og bar og lå og sprelte i ditt blod. 
26:16:23: Og efter all denne din ondskap - ve, ve dig! sier Herren, Israels Gud 
26:16:24: bygget du dig en hvelving* og gjorde dig en offerhaug i hver gate**. # <* til utukt.> # <** 2KR 28, 24.> 
26:16:25: Ved hvert veiskjell bygget du din offerhaug og vanæret din skjønnhet og bredte dine føtter ut for hver den som gikk forbi, og du drev stadig hor. 
26:16:26: Du drev hor med Egyptens sønner, dine kjøttfulle naboer, og du drev stadig hor, så du vakte min harme. 
26:16:27: Og se, jeg rakte ut min hånd mot dig og avknappet det som var tiltenkt dig, og jeg lot dem som hatet dig, få gjøre med dig som de lystet - filistrenes døtre, som bluedes ved din skammelige ferd. 
26:16:28: Og du drev hor med Assurs sønner, fordi du ikke var mett; du drev hor med dem og blev enda ikke mett. 
26:16:29: Og du drev stadig hor, helt bort til kremmerlandet Kaldea; men heller ikke da blev du mett. 
26:16:30: Hvor ditt hjerte var vissent, sier Herren, Israels Gud, da du gjorde alt dette, slikt som bare en skamløs skjøge gjør, 
26:16:31: da du bygget din hvelving ved hvert veiskjell og din offerhaug i hver gate. Men du var ikke som andre skjøger, for du foraktet skjøgelønn. 
26:16:32: Du horkvinne, som i stedet for din mann tar imot fremmede! 
26:16:33: Alle skjøger gir de lønn, men du gav alle dine elskere dine gaver og kjøpte dem til å komme til dig fra alle kanter og drive hor med dig. 
26:16:34: Og med dig skjedde det omvendte av det som skjer med andre kvinner: Du drev hor uten at nogen løp efter dig, og du gav horelønn uten selv å få det; således blev du det omvendte av andre. 
26:16:35: Hør derfor Herrens ord, du horkvinne! 
26:16:36: Så sier Herren, Israels Gud: Fordi du har ødslet med ditt kobber* og avdekket din blusel, når du drev hor med dine elskere, og for alle dine vederstyggelige avguders skyld og for dine barns blods skyld, som du gav dem**, # <* kanskje din uedle, urene kjærlighet; sml. ESK 22, 18; 24, 11. JER 6, 28.> # <** ESK 16, 20.> 
26:16:37: se, derfor samler jeg alle dine elskere, som likte dig så godt, og alle dem du elsket, og likeså alle dem du hatet - jeg vil samle dem mot dig fra alle kanter og avdekke din blusel for dem, så de får se hele din blusel. 
26:16:38: Jeg vil dømme dig likesom de kvinner dømmes som driver hor og utøser blod, og jeg vil gjøre dig til bare blod ved min harme og nidkjærhet. 
26:16:39: Jeg vil gi dig i deres hånd, og de skal rive din hvelving og bryte ned dine offerhauger og dra dine klær av dig og ta dine prektige smykker og la dig ligge der naken og bar. 
26:16:40: Og de skal føre en folkeskare frem mot dig og stene dig og hugge dig i stykker med sine sverd. 
26:16:41: De skal brenne op dine hus med ild og holde dom over dig for mange kvinners øine; og jeg vil gjøre ende på ditt horeliv, og du skal ikke mere kunne gi horelønn. 
26:16:42: Således vil jeg stille min harme på dig, og så skal min nidkjærhet vike fra dig, og jeg vil holde mig rolig og ikke vredes mere. 
26:16:43: Fordi du ikke kom din ungdoms dager i hu, men krenket mig ved alt dette, se, derfor vil også jeg la dine gjerninger komme over ditt eget hode, sier Herren, Israels Gud; for har du ikke lagt skjensel til alle dine vederstyggeligheter? 
26:16:44: Se, alle som lager ordsprog, skal bruke dette ordsprog om dig: Som moren, så datteren. 
26:16:45: Du er din mors datter, hun som foraktet sin mann og sine barn, og du er dine søstres søster, de som foraktet sine menn og sine barn; en hetittkvinne er eders mor, og en amoritt eders far. 
26:16:46: Din større søster er Samaria med sine døtre, hun som bor ved din venstre side, og din mindre søster, som bor ved din høire side, er Sodoma og hennes døtre. 
26:16:47: Du gikk ikke på deres veier og gjorde ikke efter deres vederstyggeligheter; men bare en liten stund - så gjorde du det verre enn de, på alle dine veier. 
26:16:48: Så sant jeg lever, sier Herren, Israels Gud, Sodoma, din søster, med sine døtre har ikke gjort som du og dine døtre har gjort. 
26:16:49: Se, dette var Sodomas, din søsters misgjerning: overmot; overflod av brød og trygg ro hadde hun og hennes døtre; men den elendige og fattige hjalp hun ikke; 
26:16:50: de ophøiet sig og gjorde det som var vederstyggelig for mitt åsyn, og jeg ryddet dem bort da jeg så det. 
26:16:51: Heller ikke Samaria har syndet halvt så meget som du; du gjorde mange flere vederstyggeligheter enn de, og du rettferdiggjorde dine søstre ved alle de vederstyggeligheter som du gjorde. 
26:16:52: Bær da også du din skam, du som har dømt til beste for dine søstre! For dine synders skyld, hvormed du har båret dig mere vederstyggelig at enn de, er de rettferdigere enn du; så skam dig da, også du, og bær din skjensel, idet du rettferdiggjør dine søstre! 
26:16:53: Men jeg vil gjøre ende på deres fangenskap, Sodomas og hennes døtres fangenskap og Samarias og hennes døtres fangenskap og dine fangnes fangenskap midt iblandt dem, 
26:16:54: forat du skal bære din skam og bli skamfull over alt det du har gjort, idet du trøster dem. 
26:16:55: Og dine søstre, Sodoma og hennes døtre, skal komme tilbake til sin tidligere tilstand, og Samaria og hennes døtre skal komme tilbake til sin tidligere tilstand, og du og dine døtre, I skal komme tilbake til eders tidligere tilstand. 
26:16:56: Og var ikke Sodoma, din søster, et omkvede i din munn på ditt overmots dag, 
26:16:57: før din ondskap kom for dagen, likesom på den tid du blev hånet av Syrias døtre og alle dem som bodde rundt omkring det, og av filistrenes døtre, som foraktet dig rundt omkring? 
26:16:58: Din utukt og dine vederstyggeligheter skal du bære straffen for, sier Herren. 
26:16:59: For så sier Herren, Israels Gud: Jeg vil gjøre mot dig efter det du har gjort, du som foraktet eden og brøt pakten. 
26:16:60: Og så vil jeg komme i hu min pakt med dig i din ungdoms dager, og jeg vil oprette en evig pakt med dig. 
26:16:61: Og du skal komme din ferd i hu og skamme dig, når du tar imot dine søstre, både dem som er større enn du, og dem som er mindre enn du, og jeg gir dig dem til døtre, enda de ikke hører med til din pakt. 
26:16:62: Jeg vil oprette min pakt med dig, og du skal kjenne at jeg er Herren, 
26:16:63: forat du skal komme din ferd i hu og blues og ikke mere oplate din munn for din skams skyld, når jeg forlater dig alt det du har gjort, sier Herren, Israels Gud. 
26:17:1: Og Herrens ord kom til mig, og det lød så: 
26:17:2: Menneskesønn! Fremsett en gåte for Israels hus og tal til dem i en lignelse 
26:17:3: og si: Så sier Herren, Israels Gud: Den store ørn med de store vinger og de lange svingfjær, med full fjærham og brokete farver, kom til Libanon og tok bort toppen av sederen. 
26:17:4: Den brøt av den øverste kvist og førte den til kjøbmannslandet og satte den i kremmerstaden*. # <* d.e. Babel; ESK 17, 12; 16, 27.> 
26:17:5: Så tok den et skudd av landets vekster og satte det i en dyrket mark; den plantet det på et sted hvor det var meget vann; den satte det likesom et piletre. 
26:17:6: Og det vokste op og blev til et vintre som bredte sig vidt ut, men var lavt av vekst, forat dets ranker skulde vende sig til den*, og dets røtter være under den. Og det blev til et vintre som satte grener og skjøt løvrike kvister. # <* d.e. til ørnen; ESK 17, 3.> 
26:17:7: Men det var en annen stor ørn med store vinger og mange fjær, og se, vintreet bøide sine røtter bort imot den, og fra den seng hvori det var plantet, strakte det sine ranker bort til den, forat den skulde vanne det. 
26:17:8: På en god mark, på et sted hvor det var meget vann, var det plantet, så det kunde skyte grener og bære frukt og bli et herlig vintre. 
26:17:9: Si: Så sier Herren, Israels Gud: Skal det trives? Skal ikke dets røtter rykkes op og dets frukt rives av, så det tørker bort? Alle dets spirende blad skal tørke bort, og ikke med stor styrke og meget folk vil nogen kunne få det til å skyte op av sine røtter. 
26:17:10: Se, det er plantet; skal det trives? Skal det ikke tørke bort, tørke aldeles bort, så snart østenvinden rører ved det? I den seng hvor det vokser, skal det tørke bort. 
26:17:11: Og Herrens ord kom til mig, og det lød så: 
26:17:12: Si til den gjenstridige ætt: Vet I ikke hvad dette er? Si: Se, Babels konge kom til Jerusalem og tok dets konge* og dets høvdinger og førte dem til sig i Babel. # <* Jojakin; 2KG 24, 10. 15. 2KR 36, 9. 10.> 
26:17:13: Og han tok en av kongeætten* og gjorde en pakt med ham og tok ham i ed, og de mektige i landet tok han med sig, # <* Sedekias; 2KG 24, 17. 2KR 36, 10 fg.> 
26:17:14: forat det skulde være et ringe kongerike og ikke ophøie sig, men holde pakten med ham og bli stående. 
26:17:15: Men han falt fra ham og sendte sine bud til Egypten, forat de skulde gi ham hester og meget folk. Skal han ha fremgang? Skal den som gjør slikt, slippe unda? Skal han kunne bryte en pakt og slippe unda? 
26:17:16: Så sant jeg lever, sier Herren, Israels Gud, på det sted hvor han bor den konge som gjorde ham til konge, men hvis ed han foraktet, og hvis pakt han brøt, hos ham, midt i Babel skal han visselig dø. 
26:17:17: Og ikke skal Farao med en stor hær og meget folk komme ham til hjelp i krigen, når det kastes op en voll og bygges skanser for å utrydde mange liv. 
26:17:18: Han foraktet eden og brøt pakten, enda han hadde gitt hånden på det. Alt dette har han gjort; han skal ikke slippe unda. 
26:17:19: Derfor sier Herren, Israels Gud, så: Så sant jeg lever, eden som han svor ved mig, men allikevel foraktet, og pakten som han gjorde med mig, men allikevel brøt, den vil jeg visselig la komme over hans hode. 
26:17:20: Jeg vil utspenne mitt garn over ham, og han skal fanges i mitt nett, og jeg vil føre ham til Babel og gå i rette med ham der, for den troløshet som han har vist mot mig. 
26:17:21: Og alle flyktninger fra alle hans krigsskarer skal falle for sverdet, og de som blir igjen, skal spredes for alle vinder, og I skal kjenne at jeg, Herren, har talt. 
26:17:22: Så sier Herren, Israels Gud: Da vil jeg ta en kvist av den høie seders topp og sette; av dens øverste kvister vil jeg bryte av et spett skudd, og jeg vil sette det på et høit, høit fjell. 
26:17:23: På Israels høie fjell vil jeg plante det, og det skal skyte grener og bære frukt og bli til en herlig seder, og alle slags fugler, alt som har vinger, skal bo under det; i skyggen av dets grener skal de bo. 
26:17:24: Og alle markens trær skal kjenne at jeg, Herren, har gjort et høit tre lavt og et lavt tre høit, et friskt tre tørt og et tørt tre grønt; jeg, Herren, har sagt det, og jeg skal gjøre det. 
26:18:1: Og Herrens ord kom til mig, og det lød så: 
26:18:2: Hvorledes kan I bruke dette ordsprog i Israels land: Fedrene eter sure druer, og barnas tenner blir såre? 
26:18:3: Så sant jeg lever, sier Herren, Israels Gud, I skal ikke mere bruke dette ordsprog i Israel. 
26:18:4: Se, alle sjeler hører mig til, både farens sjel og sønnens sjel; mig hører de til; den som synder, han skal dø. 
26:18:5: Og når en mann er rettferdig og gjør rett og rettferdighet, 
26:18:6: ikke eter [avgudsoffer] på fjellene og ikke løfter sine øine til Israels folks motbydelige avguder og ikke krenker sin næstes hustru og ikke nærmer sig en kvinne når hun er uren, 
26:18:7: og ikke undertrykker nogen, men lar skyldneren få sitt pant igjen, ikke raner og røver, men gir den hungrige sitt brød og dekker den nakne med klær, 
26:18:8: ikke låner ut mot rente og ikke tar overmål, holder sin hånd tilbake fra urett, dømmer rett dom mann og mann imellem, 
26:18:9: følger mine bud og holder mine lover, så han gjør det som rett og godt er - han er rettferdig, han skal visselig leve, sier Herren, Israels Gud. 
26:18:10: Men får han en sønn som blir en voldsmann, som utøser blod og gjør noget som helst av dette*, # <* det som faren ikke har gjort.> 
26:18:11: som ikke i noget av dette bærer sig at [som faren har gjort], men endog eter [avgudsoffer] på fjellene og krenker sin næstes hustru, 
26:18:12: undertrykker den elendige og fattige, raner og røver, ikke gir pantet tilbake, løfter sine øine til de motbydelige avguder, gjør det som vederstyggelig er, 
26:18:13: låner ut mot rente og tar overmål, skulde så han få leve? Nei, han skal ikke få leve! Alle disse vederstyggeligheter har han gjort; han skal visselig late livet, hans blod skal komme over ham. 
26:18:14: Men får så han igjen en sønn som ser alle de synder som hans far gjør - ser dem og ikke gjør efter dem, 
26:18:15: som ikke eter [avgudsoffer] på fjellene og ikke løfter sine øine til Israels folks avguder, ikke krenker sin næstes hustru, 
26:18:16: ikke undertrykker nogen, ikke tar pant, ikke raner og røver, men gir den hungrige sitt brød og dekker den nakne med klær, 
26:18:17: ikke forgriper sig på den elendige, ikke tar rente eller overmål, men gjør efter mine lover og følger mine bud, så skal han ikke dø for sin fars misgjernings skyld, han skal visselig få leve. 
26:18:18: Men hans far, som har gjort voldsgjerninger, ranet og røvet fra sin bror og gjort det som ikke er godt, iblandt sitt folk, se, han skal dø for sin misgjernings skyld. 
26:18:19: Men I sier: Hvorfor skal ikke sønnen bære farens misgjerning? - Sønnen har jo gjort rett og rettferdighet, alle mine bud har han holdt og gjort efter dem; han skal visselig leve. 
26:18:20: Den som synder, han skal dø; en sønn skal ikke bære sin fars misgjerning, og en far skal ikke bære sin sønns misgjerning; den rettferdiges rettferdighet skal hvile over ham, og den ugudeliges ugudelighet skal hvile over ham. 
26:18:21: Men når den ugudelige vender om fra alle de synder han har gjort, og holder alle mine bud og gjør rett og rettferdighet, da skal han visselig leve - han skal ikke dø. 
26:18:22: Ingen av de overtredelser han har gjort, skal tilregnes ham; for den rettferdighets skyld som han har gjort, skal han leve. 
26:18:23: Skulde jeg ha behag i den ugudeliges død? sier Herren, Israels Gud - mon ikke heller i at han vender om fra sin vei og lever? 
26:18:24: Men når en rettferdig vender om fra sin rettferdighet og gjør urett, gjør efter alle de vederstyggeligheter som den ugudelige gjør, skulde han da få leve? Ingen av de rettferdige gjerninger som han har gjort, skal tilregnes ham; for den troløshet han har vist, og for den synd han har gjort, skal han dø. 
26:18:25: Og I sier: Herrens vei er ikke rett. Hør, du Israels hus! Er ikke min vei rett? Er det ikke eders veier som ikke er rette? 
26:18:26: Når en rettferdig vender om fra sin rettferdighet og gjør urett, så skal han dø til straff for det; for den urett som han gjør, skal han dø. 
26:18:27: Og når en ugudelig vender om fra sin ugudelige ferd og gjør rett og rettferdighet, da skal han berge sitt liv. 
26:18:28: Han så og vendte om fra alle de overtredelser han hadde gjort; han skal visselig leve - han skal ikke dø. 
26:18:29: Men Israels hus sier: Herrens vei er ikke rett. Er ikke mine veier rette, Israels hus? Er det ikke eders veier som ikke er rette? 
26:18:30: Derfor vil jeg dømme eder, Israels hus, hver efter hans veier, sier Herren, Israels Gud. Vend om og vend eder bort fra alle eders overtredelser, forat ikke nogen misgjerning skal bli eder til fall! 
26:18:31: Kast fra eder alle eders overtredelser, som I har forsyndet eder med, og få eder et nytt hjerte og en ny ånd! Hvorfor vil I dø, Israels hus? 
26:18:32: For jeg har ikke behag i nogens død, sier Herren, Israels Gud; så omvend eder da, og I skal leve! 
26:19:1: Og du, stem i en klagesang over Israels fyrster 
26:19:2: og si: Hvad er din mor? En løvinne. Mellem løver hvilte hun; blandt unge løver opfødde hun sine unger. 
26:19:3: Og hun opfostret en av sine unger*; han blev en ung løve, og han lærte å rane og røve, han åt mennesker. # <* Joakas.> 
26:19:4: Men hedningefolk fikk høre om ham; i deres grav blev han fanget, og de førte ham med neseringer til Egyptens land. 
26:19:5: Da hun så at hun hadde ventet forgjeves, at hennes håp var gått til grunne, tok hun en annen av sine unger* og gjorde ham til en ung løve. # <* Jojakin.> 
26:19:6: Han gikk omkring blandt løver; han blev en ung løve, og han lærte å rane og røve, han åt mennesker. 
26:19:7: Han voldtok deres enker og ødela deres byer, og landet og alt det der var, blev forferdet ved lyden av hans brøl. 
26:19:8: Da satte folkene fra landskapene rundt omkring sitt garn op imot ham og spente det ut over ham; han blev fanget i den grav de hadde gravd. 
26:19:9: Så satte de ham med krok i nesen i et bur og førte ham til kongen i Babel; der satte de ham i en fast borg, forat hans røst ikke mere skulde høres på Israels fjell. 
26:19:10: Mens du levde i ro, var din mor som et vintre plantet ved vann; fruktbart og fullt av grener var det, fordi det hadde meget vann. 
26:19:11: Og det fikk sterke grener, tjenlige til herskerspir, og hevet sig høit op mellem skyene, og det falt i øinene ved sin høide og sine mange ranker. 
26:19:12: Da blev det rykket op i harme og kastet til jorden, og østenvinden tørket bort dets frukt; dets sterke grener blev revet av og tørket bort; ild fortærte dem. 
26:19:13: Og nu er det plantet i ørkenen, i et tørt og tørstende land. 
26:19:14: Og det gikk ut ild fra dets kvistede gren*; den fortærte dets frukt, og det er ikke mere nogen sterk gren på det til herskerspir. Dette er en klagesang, og til en klagesang skal det bli. # <* d.e. fra Sedekias; ESK 17, 13 fg.> 
26:20:1: I det syvende år, i den femte måned, på den tiende dag i måneden, kom nogen av Israels eldste for å spørre Herren, og de satte sig foran mig. 
26:20:2: Da kom Herrens ord til mig, og det lød så: 
26:20:3: Menneskesønn! Tal med Israels eldste og si til dem: Så sier Herren, Israels Gud: Er I kommet for å spørre mig? Så sant jeg lever, lar jeg mig ikke spørre av eder, sier Herren, Israels Gud. 
26:20:4: Vil du dømme dem, vil du dømme, menneskesønn? Tal til dem om deres fedres vederstyggeligheter 
26:20:5: og si til dem: Så sier Herren, Israels Gud: På den dag da jeg utvalgte Israel og løftet min hånd [til ed] for ætlingene av Jakobs hus og gav mig til kjenne for dem i Egyptens land, da jeg løftet min hånd [til ed] for dem og sa: Jeg er Herren eders Gud - 
26:20:6: på den dag løftet jeg min hånd og tilsvor dem at jeg vilde føre dem ut av Egyptens land til et land som jeg hadde utsøkt for dem, et land som flyter med melk og honning, det fagreste av alle land. 
26:20:7: Og jeg sa til dem: Enhver av eder kaste bort de vederstyggeligheter hans øine henger ved, og gjør eder ikke urene med Egyptens motbydelige avguder! Jeg er Herren eders Gud. 
26:20:8: Men de var gjenstridige mot mig og vilde ikke høre på mig; de kastet ikke bort de vederstyggeligheter som deres øine hang ved, og forlot ikke Egyptens motbydelige avguder. Da sa jeg at jeg vilde utøse min harme over dem, fullbyrde min vrede på dem midt i Egyptens land. 
26:20:9: Men det jeg gjorde, det gjorde jeg for mitt navns skyld, forat det ikke skulde bli vanhelliget for det folks øine som de bodde blandt, og for hvis øine jeg gav mig til kjenne for dem for å føre dem ut av Egyptens land. 
26:20:10: Og jeg førte dem ut av Egyptens land og lot dem dra inn i ørkenen. 
26:20:11: Og jeg gav dem mine bud og kunngjorde dem mine lover; for det menneske som holder dem, skal leve ved dem. 
26:20:12: Også mine sabbater gav jeg dem, så de skulde være til et tegn mellem mig og dem, forat de skulde vite at jeg er Herren, som helliger dem. 
26:20:13: Men Israels hus var gjenstridig mot mig i ørkenen; de fulgte ikke mine bud og forkastet mine lover, enda det menneske som holder dem, får leve ved dem, og mine sabbater vanhelliget de grovelig. Da sa jeg at jeg vilde utøse min harme over dem i ørkenen og gjøre ende på dem. 
26:20:14: Men det jeg gjorde, det gjorde jeg for mitt navns skyld, forat det ikke skulde bli vanhelliget for de folks øine for hvis øine jeg hadde ført dem ut. 
26:20:15: Men jeg løftet også min hånd for dem i ørkenen og svor at jeg ikke vilde la dem komme inn i det land som jeg hadde gitt dem, det land som flyter med melk og honning, det fagreste av alle land, 
26:20:16: fordi de forkastet mine lover og ikke fulgte mine bud og vanhelliget mine sabbater; for deres hjerte fulgte deres motbydelige avguder. 
26:20:17: Men jeg viste dem skånsel og ødela dem ikke; jeg gjorde ikke aldeles ende på dem i ørkenen. 
26:20:18: Og jeg sa til deres barn i ørkenen: Følg ikke eders fedres bud og hold ikke deres lover og gjør eder ikke urene med deres motbydelige avguder! 
26:20:19: Jeg er Herren eders Gud; følg mine bud og hold mine lover og gjør efter dem! 
26:20:20: Og hellighold mine sabbater! De skal være til et tegn mellem mig og eder, forat I skal vite at jeg, Herren, er eders Gud. 
26:20:21: Men barna var gjenstridige mot mig; de fulgte ikke mine bud og holdt ikke mine lover, så de gjorde efter dem, enda det menneske som holder dem, får leve ved dem; mine sabbater vanhelliget de. Da sa jeg at jeg vilde utøse min harme over dem, fullbyrde min vrede på dem i ørkenen. 
26:20:22: Men jeg drog min hånd tilbake og gjorde det jeg gjorde, for mitt navns skyld, forat det ikke skulde bli vanhelliget for de folks øine for hvis øine jeg hadde ført dem ut. 
26:20:23: Men jeg løftet også min hånd for dem i ørkenen og svor at jeg vilde sprede dem blandt hedningefolkene og strø dem ut i landene, 
26:20:24: fordi de ikke holdt mine lover, men forkastet mine bud og vanhelliget mine sabbater, og deres øine fulgte deres fedres motbydelige avguder. 
26:20:25: Derfor gav jeg dem også bud som ikke var gode, og lover som de ikke kunde leve ved. 
26:20:26: Jeg lot dem bli urene ved sine offergaver, idet de lot alt som åpner mors liv, gå igjennem ilden, så jeg kunde ødelegge dem, forat de skulde kjenne at jeg er Herren. 
26:20:27: Tal derfor til Israels hus, menneskesønn, og si til dem: Så sier Herren, Israels Gud: Også dermed hånte eders fedre mig at de viste troløshet mot mig; 
26:20:28: jeg førte dem inn i det land som jeg med løftet hånd hadde svoret å ville gi dem; men så snart de så en høi bakke eller et løvrikt tre, ofret de der sine slaktoffer og bar der frem sine harmelige offergaver; dit kom de med sin søte offerduft, og der utøste de sine drikkoffer. 
26:20:29: Da sa jeg til dem: Hvad er disse offerhauger, siden I søker til dem? - Og allikevel kalles de offerhauger den dag idag. 
26:20:30: Si derfor til Israels hus: Så sier Herren, Israels Gud: Hvad? I gjør eder urene på samme vis som eders fedre og driver hor med deres vederstyggeligheter; 
26:20:31: når I bærer frem eders gaver og lar eders barn gå gjennem ilden, gjør I eder ennu den dag idag urene ved alle eders motbydelige avguder - og jeg skulde la mig spørre av eder, Israels hus? Så sant jeg lever, sier Herren, Israels Gud, jeg lar mig ikke spørre av eder. 
26:20:32: Og aldri skal det skje det som kommer eder i sinne, når I sier: Vi vil være som hedningene, som folkene rundt om i landene; vi vil dyrke tre og sten. 
26:20:33: Så sant jeg lever, sier Herren, Israels Gud, jeg vil regjere over eder med sterk hånd og med utrakt arm og med utøst harme. 
26:20:34: Og jeg vil føre eder ut fra de folk og samle eder fra de land som I nu er spredt i, med sterk hånd og med utrakt arm og med utøst harme. 
26:20:35: Og jeg vil føre eder til folkenes ørken, og der vil jeg gå i rette med eder, åsyn til åsyn; 
26:20:36: likesom jeg gikk i rette med eders fedre i ørkenen ved Egyptens land, således vil jeg gå i rette med eder, sier Herren, Israels Gud. 
26:20:37: Og jeg vil la eder gå forbi under hyrdestaven, og jeg vil føre eder inn i paktens bånd, 
26:20:38: og jeg vil skille ut fra eder de gjenstridige og dem som er falt fra mig; fra deres utlendighets land vil jeg føre dem ut, men til Israels land skal ingen av dem komme, og I skal kjenne at jeg er Herren. 
26:20:39: Og I, Israels hus! Så sier Herren, Israels Gud: Gå avsted, dyrk hver sine motbydelige avguder! Men siden skal I sannelig høre på mig og ikke mere vanhellige mitt hellige navn ved eders gaver og ved eders motbydelige avguder. 
26:20:40: For på mitt hellige berg, på Israels høie berg, sier Herren, Israels Gud, der skal hele Israels hus, alle som er i landet, tjene mig; der vil jeg ha behag i dem, og der vil jeg spørre efter eders offergaver og efter den førstegrøde som I bærer frem av alt det I helliger. 
26:20:41: For den søte dufts skyld vil jeg ha velbehag i eder, når jeg fører eder ut fra de folk og samler eder fra de land som I nu er spredt i, og åpenbarer min hellighet på eder for hedningefolkenes øine. 
26:20:42: Og I skal kjenne at jeg er Herren, når jeg fører eder til Israels land, til det land som jeg med løftet hånd har svoret å ville gi eders fedre. 
26:20:43: Og der skal I komme i hu eders ferd og alle de gjerninger som I har gjort eder urene med, og I skal vemmes ved eder selv for alle de onde gjerninger som I har gjort. 
26:20:44: Og I skal kjenne at jeg er Herren, når jeg gjør således med eder, for mitt navns skyld og ikke efter eders onde ferd og eders skamløse gjerninger, Israels hus, sier Herren, Israels Gud. 
26:20:45: Og Herrens ord kom til mig, og det lød så: 
26:20:46: Menneskesønn! Vend ditt åsyn mot syd og prek mot Syden og spå mot skoglandet i syd*! # <* d.e. mot Jerusalem og Juda (ESK 21, 2), som for profeten i Kaldea lå mot syd.> 
26:20:47: Og si til Sydens skog: Hør Herrens ord! Så sier Herren, Israels Gud: Se, jeg tender en ild i dig, og den skal fortære hvert friskt tre og hvert tørt tre i dig; den luende ild skal ikke utslukkes, men alle ansikter fra syd til nord skal bli forbrent ved den. 
26:20:48: Og alt kjød skal se at jeg, Herren, har tendt den; den skal ikke utslukkes. 
26:20:49: Da sa jeg: Akk, Herre, Herre! De sier om mig: Han taler jo bare i lignelser! 
26:21:1: Da kom Herrens ord til mig, og det lød så: 
26:21:2: Menneskesønn! Vend ditt åsyn mot Jerusalem og prek mot helligdommene og spå mot Israels land! 
26:21:3: Og si til Israels land: Så sier Herren: Se, jeg kommer over dig og drar mitt sverd av skjeden, og jeg vil utrydde både rettferdige og ugudelige i dig. 
26:21:4: Fordi jeg vil utrydde både rettferdige og ugudelige i dig, derfor skal mitt sverd fare ut av skjeden mot alt kjød, fra syd til nord. 
26:21:5: Og alt kjød skal kjenne at jeg, Herren, har draget mitt sverd av skjeden; det skal ikke mere vende tilbake. 
26:21:6: Men du, menneskesønn, skal sukke så det bryter i dine lender; i sår verk skal du sukke for deres øine. 
26:21:7: Og når de da sier til dig: Hvorfor sukker du? - da skal du svare: Over en tidende; for den kommer, og da skal hvert hjerte smelte og alle hender synke og hver ånd bli sløv og alle knær bli som vann; se, det kommer, og det skal skje, sier Herren, Israels Gud. 
26:21:8: Og Herrens ord kom til mig, og det lød så: 
26:21:9: Menneskesønn! Spå og si: Så sier Herren: Si: Et sverd, et sverd er hvesset, og slipt er det og. 
26:21:10: Til å slakte er det hvesset, til å lyne er det slipt. Eller skulde vi glede oss, du min sønns stamme* som forakter alt tre**? # <* d.e. Israels folk.> # <** d.e. all tukt; ESK 21, 13.> 
26:21:11: Det skulde slipes for å tas i hånd; det er hvesset dette sverd, og det er slipt for å gis i en drapsmanns hånd. 
26:21:12: Skrik og hyl, menneskesønn! For det kommer over mitt folk, det kommer over alle Israels fyrster; de blir overgitt til sverdet sammen med mitt folk. Derfor slå dig på lenden! 
26:21:13: For en prøvelse er for hånden, og hvad skulde den ramme, om ikke den stamme som forakter tukt? sier Herren, Israels Gud. 
26:21:14: Og du menneskesønn, spå og slå hendene sammen! Dobbelt, ja tredobbelt kommer sverdet, det sverd som slår mange ihjel, dette sverd som slår den store* ihjel, det som truer dem på alle kanter. # <* d.e. kongen; ESK 21, 25.> 
26:21:15: Forat hjertene skal smelte og anstøtene bli mange, setter jeg et blinkende sverd mot alle dets* porter; akk, det er gjort til å lyne, skjerpet til å slakte. # <* Jerusalems.> 
26:21:16: Ta dig sammen og hugg til høire! Vend dig og hugg til venstre! Dit hvor din egg er bestemt! 
26:21:17: Også jeg vil slå hendene sammen og stille min harme; jeg, Herren, har talt. 
26:21:18: Og Herrens ord kom til mig, og det lød så: 
26:21:19: Og du menneskesønn, gjør dig to veier, som kongen av Babels sverd kan fare frem på! Fra ett land skal de begge gå ut, og du skal risse en hånd* - risse den ved begynnelsen av veien til hver by! # <* som veiviser.> 
26:21:20: En vei skal du gjøre som sverdet kan fare frem på til Rabba i ammonittenes land, og en til Juda, inn i Jerusalem, den faste by. 
26:21:21: For Babels konge står på veiskjellet, ved begynnelsen av de to veier, for å la sig spå; han ryster pilene, han spør husgudene, han ser på leveren. 
26:21:22: I sin høire hånd får han loddet: Jerusalem; der skal han stille op murbrekkere, åpne munnen til krigsskrik, lufte røsten med hærrop - stille op murbrekkere mot portene, kaste op en voll og bygge skanser. 
26:21:23: Men dette er i deres* øine bare en tom spådom; de har jo de helligste eder**. Men han*** minner om deres misgjerning, forat de skal gripes. # <* jødenes.> # <** de mener sig på grunn av Herrens eder sikre; MIK 3, 11. JER 7, 4.> # <*** Herren.> 
26:21:24: Derfor sier Herren, Israels Gud, så: Fordi I selv minner om eders misgjerning, idet eders overtredelser åpenbares, så eders synder viser sig i alle eders gjerninger - fordi jeg således blir minnet om eder, skal I gripes med hånden. 
26:21:25: Og du dødsdømte, du ugudelige, du Israels fyrste, hvis dag er kommet når den misgjerning er skjedd som fører til undergang! 
26:21:26: Så sier Herren, Israels Gud: Ta huen bort og løft kronen av! Det som nu er, skal ikke være mere; det lave skal ophøies, og det høie skal fornedres. 
26:21:27: Jeg vil vende op ned, op ned, op ned på det som er; heller ikke ved dette skal det bli, inntil han kommer hvem retten tilhører*, og jeg gir ham den. # <* d.e. Messias; 1MO 49, 10.> 
26:21:28: Og du menneskesønn, spå og si: Så sier Herren, Israels Gud, om Ammons barn og om deres hånsord*: Du skal si: Et sverd, et sverd er draget, slipt til å slakte, til å fortære, til å lyne, # <* ESK 25, 3.> 
26:21:29: mens de skuer falske syner for dig og spår dig løgn, for å legge dig ved siden av de ihjelslåtte ugudeliges halser*, hvis dag er kommet når den misgjerning er skjedd som fører til undergang. # <* d.e. for å slå eder ihjel sammen med de ugudelige i Juda.> 
26:21:30: Stikk sverdet i skjeden igjen! På det sted hvor du blev skapt, i det land hvorfra du er oprunnet, der vil jeg dømme dig. 
26:21:31: Og jeg vil utøse min harme over dig, min vredes ild vil jeg la lue mot dig, og jeg vil gi dig i ville menneskers hånd, som er mestere i å ødelegge. 
26:21:32: Du skal bli til føde for ilden, ditt blod skal bli utøst i ditt land, ingen skal mere komme dig i hu; for jeg, Herren, har sagt det. 
26:22:1: Og Herrens ord kom til mig, og det lød så: 
26:22:2: Og du menneskesønn! Vil du dømme, vil du dømme blodstaden? Forehold den alle dens vederstyggeligheter! 
26:22:3: Og du skal si: Så sier Herren, Israels Gud: Du by som har utøst dine innbyggeres blod, forat din tid skal komme, du som har gjort dig motbydelige avguder og således er blitt uren! 
26:22:4: Ved det blod som du har utøst, er du blitt skyldig, og ved de motbydelige avguder som du har gjort, er du blitt uren, og du har fått dine dager til å nærme sig og har nådd dine år; derfor gjør jeg dig til hån for folkene og til spott for alle landene. 
26:22:5: De land som er nær, og de som er langt borte fra dig, skal spotte dig, du med ditt utskjemte navn og din store forvirring! 
26:22:6: Se, Israels fyrster i dig brukte alle sin arm til å utøse blod. 
26:22:7: Far og mor blev ringeaktet hos dig; mot den fremmede blev det brukt vold hos dig; den farløse og enken blev undertrykt hos dig. 
26:22:8: Mine helligdommer foraktet du, og mine sabbater vanhelliget du. 
26:22:9: Baktalere stod frem hos dig for å utøse blod, og på dine fjell blev der ett [avgudsoffer]*; skjenselsgjerninger skjedde hos dig. # <* ESK 18, 6.> 
26:22:10: Farens blusel blottedes hos dig; en kvinne som var uren i sin månedlige svakhet, krenkedes hos dig. 
26:22:11: En bar sig vederstyggelig at mot sin næstes hustru, og en annen vanæret sin sønnekone i skammelig utukt, atter en annen krenket sin søster, sin fars datter, hos dig. 
26:22:12: Gaver tok de hos dig for å utøse blod; rente og overmål tok du, og mot din næste gjorde du urett og vold, og mig glemte du, sier Herren, Israels Gud. 
26:22:13: Men se, jeg har slått mine hender sammen over den urettferdige vinning du har samlet dig, og over det blod du har utøst i din midte. 
26:22:14: Skal ditt hjerte holde stand eller dine hender ha sin styrke i de dager da jeg vil ha med dig å gjøre? Jeg, Herren, har sagt det, og jeg skal gjøre det. 
26:22:15: Jeg vil sprede dig iblandt folkene og strø dig omkring i landene, og jeg vil ta bort fra dig all din urenhet. 
26:22:16: Du skal bli vanhelliget ved din egen skyld for folkenes øine, og du skal kjenne at jeg er Herren. 
26:22:17: Og Herrens ord kom til mig, og det lød så: 
26:22:18: Menneskesønn! Israels hus er blitt mig til slagg; de er alle sammen som kobber og tinn og jern og bly i en ovn; sølvslagg er de blitt. 
26:22:19: Derfor sier Herren, Israels Gud, så: Fordi I alle er blitt til slagg, se, derfor vil jeg samle eder sammen i Jerusalem. 
26:22:20: Likesom en samler sølv og kobber og jern og bly og tinn i en ovn og blåser ild på det for å smelte det, således vil jeg samle eder i min vrede og min harme og legge eder i ovnen og smelte eder. 
26:22:21: Ja, jeg vil samle eder og blåse på eder med min vredes ild, og I skal smeltes i den; 
26:22:22: som sølv smeltes i en ovn, således skal I smeltes i den, og I skal kjenne at jeg, Herren, har utøst min harme over eder. 
26:22:23: Og Herrens ord kom til mig, og det lød så: 
26:22:24: Menneskesønn! Si til det*: Du er et land som ikke er renset, som ikke har fått skyllregn på vredens dag. # <* Juda.> 
26:22:25: De profeter det har i sin midte, er en flokk av sammensvorne; de er lik en brølende løve, som raner og røver; de fortærer menneskeliv, de tar gods og kostbarheter; de gjør mange til enker der. 
26:22:26: Dets prester gjør vold på min lov og vanhelliger mine helligdommer; mellem hellig og vanhellig gjør de ingen forskjell, og de lærer ikke å skjelne mellem urent og rent; for mine sabbater lukker de sine øine, og jeg blir vanhelliget midt iblandt dem. 
26:22:27: De fyrster det har i sin midte, er lik ulver, som raner og røver; de utøser blod, de ødelegger menneskeliv for å samle urettferdig vinning. 
26:22:28: Dets profeter stryker over med kalk for dem; de skuer tomhet og spår dem løgn og sier: Så sier Herren, Israels Gud - enda Herren ikke har talt. 
26:22:29: Folket i landet gjør voldsverk og raner og røver, og den elendige og fattige undertrykker de, og mot den fremmede gjør de voldsverk uten lov og rett. 
26:22:30: Jeg søkte blandt dem efter en mann som vilde mure op en mur og stille sig i gapet for mitt åsyn til vern for landet, så jeg ikke skulde ødelegge det; men jeg fant ingen. 
26:22:31: Så utøser jeg da min vrede over dem; ved min harmes ild gjør jeg ende på dem; deres gjerninger lar jeg komme over deres eget hode, sier Herren, Israels Gud. 
26:23:1: Og Herrens ord kom til mig, og det lød så: 
26:23:2: Menneskesønn! Der var to kvinner, døtre av en mor. 
26:23:3: De drev hor i Egypten, i sin ungdom drev de hor; der trykket de deres bryster, der kjente de på deres jomfruelige barm. 
26:23:4: Deres navn var: Ohola, den eldste, og Oholiba, hennes søster, og de blev mine og fødte sønner og døtre; og om deres navn er å si: Ohola er Samaria og Oholiba* Jerusalem. # <* Ohola d.e. hennes eget telt; så kalles Samaria fordi de ti stammer hadde sin egen selvvalgte gudstjeneste. Oholiba d.e. mitt (Herrens) telt er i det; så kalles Jerusalem fordi Herrens tempel var der.> 
26:23:5: Ohola drev hor og var utro mot mig; hun optendtes av elskov til sine elskere, til assyrerne, som hadde nærmet sig henne, 
26:23:6: klædd i blått purpur, fyrster og herrer, alle sammen fagre unge menn, ryttere på sine hester. 
26:23:7: Hun holdt sig til dem i hor, til alle de ypperste av Assurs sønner; hun gjorde sig uren med alle dem som hun optendtes av elskov til - med alle deres avguder. 
26:23:8: Men sitt horelevnet fra Egypten lot hun ikke fare; for de hadde ligget hos henne i hennes ungdom, og de hadde kjent på hennes jomfruelige barm, og de hadde utøst sitt hor over henne. 
26:23:9: Derfor gav jeg henne i hennes elskeres hånd, i Assurs sønners hånd, som hun var optendt av elskov til. 
26:23:10: De avdekket hennes blusel, tok hennes sønner og døtre og slo henne selv ihjel med sverdet; hun blev navnkundig blandt kvinnene, og de holdt dom over henne. 
26:23:11: Og hennes søster Oholiba så det og drev det verre med sin elskov enn hun, og verre med sitt hor enn hennes søster hadde gjort. 
26:23:12: Hun optendtes av elskov til Assurs sønner, fyrster og herrer, som hadde nærmet sig henne, prektig klædd, ryttere på sine hester, alle sammen fagre unge menn. 
26:23:13: Jeg så at hun var blitt uren; en og samme vei gikk de begge. 
26:23:14: Men hun drev sitt hor videre. Da hun så menn avbildet på veggen, billeder av kaldeerne malt med rødt, 
26:23:15: omgjordet med belte om sine lender, med nedhengende farvede huer på sine hoder, alle sammen å se til som vognkjempere, en avbildning av Babels sønner, hvis fødeland er Kaldea, 
26:23:16: da optendtes hun av elskov, så snart hun så dem med sine øine, og hun sendte bud til dem i Kaldea. 
26:23:17: Og Babels sønner kom til henne, til elskovs samleie, og gjorde henne uren med sitt hor; men da hun var blitt uren ved dem, vendte hennes sjel sig bort fra dem. 
26:23:18: Da hun drev sitt hor så åpenbart og avdekket sin blusel, vendte min sjel sig bort fra henne, likesom min sjel hadde vendt sig bort fra hennes søster. 
26:23:19: Men hun drev sitt hor ennu videre; hun kom sin ungdoms dager i hu, da hun drev hor i Egyptens land, 
26:23:20: og hun optendtes av elskov til horkarlene der, hvis kjøtt var som aseners kjøtt, og hvis utflod var som hesters utflod. 
26:23:21: Så søkte du igjen til din ungdoms utukt, da egypterne kjente på din barm for dine ungdommelige brysters skyld. 
26:23:22: Derfor, Oholiba, sier Herren, Israels Gud, så: Se, jeg egger dine elskere imot dig, dem som din sjel har vendt sig bort fra, og jeg lar dem komme over dig fra alle kanter. 
26:23:23: Babels sønner og alle kaldeerne, Pekod og Sjoa og Koa*, og sammen med dem alle Assurs sønner fagre unge menn, fyrster og herrer alle sammen, vognkjempere, navnkundige menn, alle sammen ridende på hester. # <* tre nabofolk til kaldeerne.> 
26:23:24: De skal komme over dig med krigsmakt, vogner og hjul, og med en skare av folkeslag; store skjold og små skjold og hjelmer skal de stille mot dig på alle kanter, og jeg vil overlate dommen til dem, og de skal dømme dig med sine dommer. 
26:23:25: Jeg vil vise min nidkjærhet mot dig, og de skal fare frem mot dig i harme; din nese og dine ører skal de skjære av, og de som blir igjen av dig, skal falle for sverdet; de skal ta dine sønner og døtre, og de som blir igjen av dig, skal fortæres av ilden. 
26:23:26: De skal dra dine klær av dig og ta dine prektige smykker. 
26:23:27: Således vil jeg gjøre ende på din utukt og ditt horelevnet fra Egyptens land, og du skal ikke mere løfte dine øine til dem og ikke mere komme Egypten i hu. 
26:23:28: For så sier Herren, Israels Gud: Se, jeg gir dig i deres hånd som du hater, i deres hånd som din sjel har vendt sig bort fra. 
26:23:29: Og de skal fare frem mot dig i hat og ta alt det du har vunnet ved ditt strev, og la dig bli tilbake naken og bar, og din utuktige blusel og din skamløshet og ditt hor skal bli avdekket. 
26:23:30: Dette skal de gjøre med dig fordi du i hor har løpet efter hedningefolk, fordi du har gjort dig uren med deres motbydelige avguder. 
26:23:31: På din søsters vei har du gått; derfor rekker jeg dig det samme beger som hun måtte tømme. 
26:23:32: Så sier Herren, Israels Gud: Din søsters beger skal du drikke, det dype og vide; du skal bli til latter og spott - det rummer meget. 
26:23:33: Av rus og sorg skal du bli full - et ødeleggelsens og herjingens beger er din søster Samarias beger. 
26:23:34: Du skal drikke det ut til siste dråpe, og du skal gnage på dets skår og sønderrive dine bryster; for jeg har talt, sier Herren, Israels Gud. 
26:23:35: Derfor sier Herren, Israels Gud, så: Fordi du glemte mig og kastet mig bak din rygg; så bær da også du straffen for din utukt og ditt hor! 
26:23:36: Og Herren sa til mig: Menneskesønn! Vil du dømme Ohola og Oholiba? Forehold dem deres vederstyggeligheter! 
26:23:37: For de har drevet hor, og der er blod på deres hender, med sine motbydelige avguder har de drevet hor; ja, endog sine barn, som de hadde født mig, har de latt gå gjennem ilden for dem, forat de skulde ete dem. 
26:23:38: Også dette gjorde de mot mig: Den samme dag gjorde de min helligdom uren og vanhelliget mine sabbater. 
26:23:39: Når de hadde slaktet sine barn for sine motbydelige avguder, kom de samme dag i min helligdom og vanhelliget den; se, således gjorde de i mitt hus. 
26:23:40: Ja, de sendte endog bud efter menn som skulde komme langveis fra; der blev skikket sendebud til dem, og se, de kom de som du hadde badet dig og sminket dine øine og smykket dig med prydelser for. 
26:23:41: Og du satt på et prektig leie, og et bord stod dekket foran det, og min røkelse og min olje hadde du satt på bordet. 
26:23:42: Lyden av en sorgløs mengde hørtes der inne*, og til de menn av folkehopen som var der, hentedes drikkebrødre fra ørkenen, og de satte armbånd på deres** hender og prektige kroner på deres hoder. # <* i Jerusalem.> # <** Oholas og Oholibas.> 
26:23:43: Da sa jeg om den utslitte skjøge: Nu driver de rett hor med henne, ja med henne. 
26:23:44: De gikk inn til henne som folk går inn til en skjøge; således gikk de inn til Ohola og Oholiba, de utuktige kvinner. 
26:23:45: Men rettferdige menn skal dømme dem, som horkvinner dømmes og kvinner som utøser blod; for horkvinner er de, og blod er der på deres hender. 
26:23:46: For så sier Herren, Israels Gud: Jeg vil føre en folkeskare frem mot dem og overgi dem til mishandling og ran. 
26:23:47: Og folkeskaren skal stene dem og hugge dem sønder med sine sverd; deres sønner og døtre skal de slå ihjel, og deres hus skal de brenne op med ild. 
26:23:48: Således vil jeg gjøre ende på utukten i landet, og alle kvinner skal la sig advare og ikke drive slik utukt som I. 
26:23:49: Eders utukt skal legges på eder selv, og I skal bære straffen for eders synder med eders motbydelige avguder, og I skal kjenne at jeg er Herren, Israels Gud. 
26:24:1: Og Herrens ord kom til mig i det niende år, i den tiende måned, på den tiende dag i måneden, og det lød så: 
26:24:2: Menneskesønn! Skriv op for dig navnet på denne dag, just denne dag! Babels konge har leiret sig mot Jerusalem just på denne dag. 
26:24:3: Og sett frem en lignelse for den gjenstridige ætt og si til dem: Så sier Herren, Israels Gud: Sett gryten på, sett den på og øs så vann i den! 
26:24:4: Legg kjøttstykkene sammen i den, alle gode stykker, lår og bog! Fyll den med de beste ben! 
26:24:5: Ta det beste av småfeet og legg så et bål under den for å koke benene! La det koke til gagns! Benene koker allerede i den. 
26:24:6: Derfor sier Herren, Israels Gud, så: Ve blodstaden, den rustne gryte, som rusten ikke er gått av! Ta stykke for stykke ut av den! Det blir ikke kastet lodd derom. 
26:24:7: For det blod den har utøst, er ennu i den; den har helt det ut på nakne berget, den har ikke utøst det på jorden, så mulden kunde dekke over det. 
26:24:8: For å la min harme ha fritt løp, for å ta hevn lot jeg dens blod komme på nakne berget, så det ikke skulde bli tildekket. 
26:24:9: Derfor sier Herren, Israels Gud, så: Ve blodstaden! Også jeg vil gjøre bålet stort. 
26:24:10: Legg meget ved på, tend op ilden! La kjøttet bli mørt og suppen koke inn, så benene blir forbrent, 
26:24:11: og la den bli stående tom på sine glør, forat den kan bli het, og dens kobber bli glødende, og dens urenhet smeltes bort og dens rust fortæres! 
26:24:12: Trettende arbeid har den voldt mig, og allikevel er dens tykke rust ikke gått av den. I ilden med dens rust! 
26:24:13: Din urenhet er en skam; fordi jeg har renset dig, men du ikke er blitt ren, skal du ikke mere bli fri for din urenhet før jeg får stilt min harme på dig. 
26:24:14: Jeg, Herren, har talt; det kommer, og jeg vil gjøre det; jeg lar det ikke være og sparer ikke og angrer ikke; efter din ferd og dine gjerninger skal du bli dømt, sier Herren, Israels Gud. 
26:24:15: Og Herrens ord kom til mig, og det lød så: 
26:24:16: Menneskesønn! Se, jeg tar dine øines lyst fra dig ved en brå død; men du skal ikke klage og ikke gråte, og dine tårer skal ikke rinne. 
26:24:17: Sukk i stillhet og hold ikke sørgefest som efter en død! Bind din hue på dig og ta dine sko på dine føtter og dekk ikke skjegget til og et ikke mat som folk sender dig! 
26:24:18: Så talte jeg til folket om morgenen, og min hustru døde om aftenen; og morgenen efter gjorde jeg som det var sagt mig. 
26:24:19: Da sa folket til mig: Vil du ikke si oss hvad det betyr at du gjør så? 
26:24:20: Jeg svarte dem: Herrens ord kom til mig, og det lød så: 
26:24:21: Si til Israels hus: Så sier Herren, Israels Gud: Se, jeg vanhelliger min helligdom, eders herlige stolthet, eders øines lyst og eders sjels lengsel, og eders sønner og døtre, som I har latt tilbake, skal falle for sverdet. 
26:24:22: Da skal I gjøre som jeg har gjort: Eders skjegg skal I ikke tildekke, og den mat som folk sender eder, skal I ikke ete; 
26:24:23: eders huer skal I ha på hodet og eders sko på føttene; I skal ikke klage og ikke gråte; men I skal visne bort i eders misgjerninger og sukke med hverandre. 
26:24:24: Esekiel skal være til et tegn for eder; aldeles som han har gjort, skal I gjøre. Når det kommer, da skal I kjenne at jeg er Herren, Israels Gud. 
26:24:25: Og du menneskesønn! På den dag da jeg tar deres vern, deres herlige fryd, deres øines lyst og deres sjels lengsel, deres sønner og døtre, fra dem, 
26:24:26: på den dag skal det komme flyktninger til dig og forkynne det for folk. 
26:24:27: Den dag skal din munn åpnes, når flyktningene er kommet, og du skal tale og ikke mere være målløs, og du skal være til et tegn for dem*, og de skal kjenne at jeg er Herren. # <* de bortførte.> 
26:25:1: Og Herrens ord kom til mig, og det lød så: 
26:25:2: Menneskesønn! Vend ditt åsyn mot Ammons barn og spå mot dem! 
26:25:3: Si til Ammons barn: Hør Herrens, Israels Guds ord! Så sier Herren, Israels Gud: Fordi du ropte: Ha, ha! over min helligdom, som er blitt vanhelliget, og over Israels land, som er blitt ødelagt, og over Judas hus, som er ført bort i fangenskap, 
26:25:4: se, derfor vil jeg la Østens sønner få dig til eie, og de skal slå op sine teltleire i dig og sette op sine boliger i dig; de skal ete din frukt og drikke din melk. 
26:25:5: Og jeg vil gjøre Rabba til beitemark for kameler og Ammons barns land til hvileplass for småfe, og I skal kjenne at jeg er Herren. 
26:25:6: For så sier Herren, Israels Gud: Fordi du klappet i hendene og stampet med føttene og gledet dig med dyp forakt av hele ditt hjerte over Israels land, 
26:25:7: se, derfor rekker jeg min hånd ut imot dig og gir dig til føde for folkene; jeg utrydder dig av folkene og utsletter dig av landene; jeg vil gjøre dig til intet, og du skal kjenne at jeg er Herren. 
26:25:8: Så sier Herren, Israels Gud: Fordi Moab og Se'ir sa: Se, det er gått med Judas hus som med alle de andre folk, 
26:25:9: se, derfor vil jeg åpne Moabs side helt fra byene*, fra dets byer på alle kanter, den fagreste del av landet, Bet-Jesimot, Ba'al-Meon og like til Kirjata'im, # <* d.e. grensebyene.> 
26:25:10: så Østens sønner kan slippe inn, likesom jeg vil gjøre med Ammons barns land*, som jeg gir dem til eie, forat Ammons barns land ikke mere skal kommes i hu blandt folkene. # <* d.e. likesom jeg og åpner Ammons barns land for dem; ESK 25, 4.> 
26:25:11: Jeg vil holde dom over Moab, og de skal kjenne at jeg er Herren. 
26:25:12: Så sier Herren, Israels Gud: Fordi Edom tok hevn over Judas hus og førte skyld over sig ved å hevne sig på dem, 
26:25:13: derfor sier Herren, Israels Gud, så: Jeg vil rekke min hånd ut imot Edom og utrydde der både folk og fe, og jeg vil gjøre det til en ørken helt fra Teman, og like til Dedan skal de falle for sverdet. 
26:25:14: Og jeg vil fullbyrde min hevn på Edom ved mitt folk Israels hånd, og de skal gjøre med Edom efter min vrede og harme, og de skal få kjenne min hevn, sier Herren, Israels Gud. 
26:25:15: Så sier Herren, Israels Gud: Fordi filistrene tok hevn, fordi de hevnet sig med forakt av hele sitt hjerte for å ødelegge på grunn av eldgammelt fiendskap, 
26:25:16: derfor sier Herren, Israels Gud, så: Se, jeg rekker min hånd ut imot filistrene og utrydder kreterne* og utsletter det som er igjen ved havets strand. # <* 1SA 30, 14. SEF 2, 5.> 
26:25:17: Jeg vil ta stor hevn over dem og tukte dem i min vrede, og de skal kjenne at jeg er Herren, når jeg fullbyrder min hevn på dem. 
26:26:1: I det ellevte år, på den første dag i måneden, kom Herrens ord til mig, og det lød så: 
26:26:2: Menneskesønn! Fordi Tyrus sier om Jerusalem: Ha, ha! Sønderbrutt er den, denne folkenes port; den er flyttet til mig, jeg blir fylt - den er ødelagt, 
26:26:3: derfor sier Herren, Israels Gud, så: Se, jeg kommer over dig, Tyrus, og fører mange folk frem mot dig, likesom havet fører sine bølger frem. 
26:26:4: Og de skal ødelegge Tyrus' murer og rive ned dets tårner, og jeg vil feie bort dets grus av det og gjøre det til nakent berg; 
26:26:5: det skal bli til en tørkeplass for fiskegarn ute i havet. For jeg har talt, sier Herren, Israels Gud, og det skal bli til hærfang for folkene, 
26:26:6: og dets døtre på fastlandet* skal slåes ihjel med sverd, og de skal kjenne at jeg er Herren. # <* d.e. de til Tyrus hørende mindre byer.> 
26:26:7: For så sier Herren, Israels Gud: Se, jeg lar Nebukadnesar, Babels konge, kongenes konge, komme mot Tyrus fra Norden med hester og vogner og ryttere og med en hær og meget folk. 
26:26:8: Dine døtre på fastlandet skal han slå ihjel med sverd, og han skal bygge skanser mot dig og kaste en voll op mot dig og reise skjoldtak mot dig. 
26:26:9: Og sin murbrekker skal han sette mot dine murer, og dine tårn skal han bryte ned med sine jern. 
26:26:10: Hans hester er så mange at støvet av dem skal dekke dig; for larmen av ryttere og hjul og vogner skal dine murer beve, når han drar inn gjennem dine porter, som en drar inn i en hærtatt by. 
26:26:11: Med sine hesters hover skal han trampe ned alle dine gater; ditt folk skal han slå ihjel med sverd, og dine sterke søiler skal synke til jorden. 
26:26:12: De skal røve ditt gods og rane dine varer og bryte ned dine murer og rive ned dine herlige hus og kaste dine stener og ditt treverk og ditt støv ut i vannet. 
26:26:13: Jeg lar dine larmende sanger høre op, og lyden av dine citarer skal ikke høres mere. 
26:26:14: Jeg vil gjøre dig til nakent berg; du skal bli til en tørkeplass for fiskegarn; du skal aldri bygges op igjen; for jeg, Herren, har talt, sier Herren, Israels Gud. 
26:26:15: Så sier Herren, Israels Gud, til Tyrus: Se, kystene skal beve ved braket av ditt fall, ved de såredes stønn og ved de mange mord midt i dig. 
26:26:16: Alle havets fyrster skal stige ned fra sine troner og legge av sine kapper og ta av sig sine utsydde klær; de skal klæ sig i redsel, sitte på jorden og reddes hvert øieblikk og forferdes over dig. 
26:26:17: De skal stemme i en klagesang over dig og si til dig: Hvorledes er du gått til grunne, du som hadde dine innbyggere fra havene, du den lovpriste stad, som var så mektig på havet, både du og dine innbyggere, som utbredte redsel for dig blandt alle som bodde der? 
26:26:18: Nu gripes øene av redsel på den dag du faller - øene i havet forferdes over den ende du fikk. 
26:26:19: For så sier Herren, Israels Gud: Når jeg gjør dig til en ødelagt by, lik de byer som det ikke lenger bor folk i, når jeg lar dypets vann stige op over dig, så vannmassene skjuler dig, 
26:26:20: da lar jeg dig fare ned likesom de som farer ned i graven, til fortidens folk, og lar dig bo i dødsrikets land, i ruiner fra eldgammel tid, likesom de som farer ned i graven, forat ingen mere skal bo i dig; men jeg vil gjøre herlige ting* i de levendes land. # <* forherlige Israel.> 
26:26:21: Til en forferdelse vil jeg gjøre dig, og du skal ikke være til mere; de skal søke efter dig, men aldri i evighet finne dig, sier Herren, Israels Gud. 
26:27:1: Og Herrens ord kom til mig, og det lød så: 
26:27:2: Og du menneskesønn! Stem i en klagesang over Tyrus! 
26:27:3: Si til Tyrus, som bor ved havets innløp, som handler med folkene på mange kyster: Så sier Herren, Israels Gud: Tyrus! du sier: Jeg er fullkommen i skjønnhet. 
26:27:4: Midt i havet er dine landemerker; dine bygningsmenn gjorde din skjønnhet fullkommen. 
26:27:5: Av cypresser fra Senir bygget de begge dine plankesider; sedrer fra Libanon hentet de for å gjøre mast på dig. 
26:27:6: Av eker fra Basan gjorde de dine årer; dine rorbenker gjorde de av elfenben innlagt i buksbom fra Kittims øer. 
26:27:7: Fint utsydd lin fra Egypten var det du foldet ut som ditt flagg; blått og purpurrødt tøi fra Elisa-øene var ditt soltelt. 
26:27:8: Folk fra Sidon og Arvad var dine rorskarer; de kloke menn som fantes hos dig, Tyrus, var dine styrmenn. 
26:27:9: Gebals eldste og dets kloke menn var hos dig og bøtte dine brøst; alle havets skib og sjøfolk var hos dig og handlet med dig. 
26:27:10: Menn fra Persia og Lud og Put gjorde krigstjeneste i din hær; skjold og hjelm hengte de op i dig, de gav dig glans. 
26:27:11: Arvads sønner stod med din egen hær på dine murer rundt omkring og djerve menn på dine tårn; sine skjold hengte de op på dine murer rundt omkring; de gjorde din skjønnhet fullkommen. 
26:27:12: Tarsis handlet med dig fordi du var rik på alle slags gods; med sølv, jern, tinn og bly betalte de dine varer. 
26:27:13: Javan, Tubal og Mesek var dine kremmere; med mennesker* og kobberkar betalte de dine varer. # <* d.e. træler.> 
26:27:14: Togarma-folket betalte dine varer med vognhester og ridehester og mulesler. 
26:27:15: Dedans sønner var dine kremmere; mange kystland mottok varer av din hånd, elfenben og ibenholt gav de dig til betaling. 
26:27:16: Syria handlet med dig fordi du var rik på alle slags kunstarbeider; med karfunkler, purpur og utsydd tøi og fint lin og koraller og rubiner betalte de dine varer. 
26:27:17: Juda og Israels land var dine kremmere; med hvete fra Minnit og søte kaker og honning og olje og balsam betalte de dine varer. 
26:27:18: Damaskus handlet med dig fordi du var rik på alle slags kunstarbeider, på allslags gods; de kom med vin fra Helbon og hvit ull. 
26:27:19: Vedan og Javan fra Usal betalte dine varer, så skinnende jern, kassia og kalmus kom i din handel. 
26:27:20: Dedan handlet med dig med dekkener til å ride på. 
26:27:21: Arabia og alle Kedars fyrster mottok varer av din hånd; med lam og værer og bukker handlet de med dig. 
26:27:22: Sjebas og Ramas kremmere var dine kremmere; med beste slag av allehånde velluktende urter og med allehånde kostbare stener og gull betalte de dine varer. 
26:27:23: Karan og Kanne og Eden, Sjebas kremmere, Assur og Kilmad handlet med dig; 
26:27:24: de handlet med dig med prektige klær, med kapper av purpurfarvede og utsydde tøier og med hele skatter av tvunnet, mangefarvet garn, med tvunne og sterke snorer på ditt marked. 
26:27:25: Tarsis-skib var dine karavaner, de drev din handel, og du blev fylt og overmåte rik, der du lå midt ute i havet. 
26:27:26: På store vann førte dine rorskarer dig ut - østenvinden knuser dig midt i havet. 
26:27:27: Ditt gods og dine varer, din handel, dine sjøfolk og dine styrmenn, de som bøter dine brøst, og de som driver din handel, alle de krigsmenn du har hos dig, og hele det mannskap du har ombord, skal falle midt ute i havet på den dag du faller. 
26:27:28: Ved lyden av dine styrmenns skrik skal dine marker beve. 
26:27:29: Og de skal stige ut av sine skib, alle de som sitter ved årene, sjøfolkene og alle styrmenn på havet; de skal gå i land. 
26:27:30: De skal bryte ut i jammerrop over dig, og de skal kaste støv på sine hoder og velte sig i asken. 
26:27:31: De skal rake sig skallet for din skyld og binde sekk om sig, og de skal gråte over dig i bitter sorg, med bitter veklage. 
26:27:32: I sin jammer skal de stemme i en klagesang over dig og si: Hvem er lik Tyrus, den stad som nu er blitt taus, der den ligger midt ute i havet? 
26:27:33: Da dine varer kom inn fra havene, mettet du mange folkeslag; med alt ditt gods og alle dine varer gjorde du jordens konger rike. 
26:27:34: Nu, da du er knust og er sunket i havets dyp, er dine varer og hele ditt mannskap gått til grunne med dig. 
26:27:35: Alle de som bor i kystlandene, skal forferdes over dig, og deres konger skal gyse med redsel i sine ansikter. 
26:27:36: Kjøbmennene rundt om blandt folkene skal spotte over dig: En redsel er du blitt, og du er blitt borte - for evig tid. 
26:28:1: Og Herrens ord kom til mig, og det lød så: 
26:28:2: Menneskesønn! Si til Tyrus' fyrste: Så sier Herren, Israels Gud: Fordi ditt hjerte ophøier sig, og du sier: Jeg er en gud, jeg sitter på et gudesete midt ute i havet, enda du er et menneske og ikke nogen gud, men allikevel i ditt hjerte tykkes dig å være en gud - 
26:28:3: ja, du er visere enn Daniel, intet lønnlig er skjult for dig; 
26:28:4: ved din visdom og forstand har du vunnet dig rikdom og samlet gull og sølv i dine skattkammer; 
26:28:5: ved din store klokskap i handel har du øket din rikdom, og ditt hjerte ophøiet sig for din rikdoms skyld - 
26:28:6: derfor sier Herren, Israels Gud, så: Fordi du i ditt hjerte tykkes dig å være en gud, 
26:28:7: se, derfor lar jeg fremmede komme over dig, de grusomste blandt folkene, og de skal dra sine sverd mot din strålende visdom og vanhellige din glans. 
26:28:8: I graven skal de støte dig ned, og du skal dø som de ihjelslåtte dør, midt ute i havet. 
26:28:9: Vil du vel da si til din banemann: Jeg er en gud, enda du er et menneske og ikke nogen gud, du som er i din drapsmanns vold? 
26:28:10: Som de uomskårne dør, skal du dø ved fremmedes hånd; for jeg har talt, sier Herren, Israels Gud. 
26:28:11: Og Herrens ord kom til mig, og det lød så: 
26:28:12: Menneskesønn! Stem i en klagesang over Tyrus' konge og si til ham: Så sier Herren, Israels Gud: Du som var seglet på den velordnede bygning, full av visdom og fullkommen i skjønnhet! 
26:28:13: I Eden, Guds have, bodde du; kostbare stener dekket dig, karneol, topas og diamant, krysolitt, onyks og jaspis, safir, karfunkel og smaragd, og gull; dine trommer og fløiter var i fullt arbeid hos dig; den dag du blev skapt, stod de rede. 
26:28:14: Du var en salvet kjerub med dekkende vinger, og jeg satte dig på det hellige gudefjell; der gikk du omkring blandt skinnende stener*. # <* funklende edelstener. 2MO 25, 20.> 
26:28:15: Ustraffelig var du i din ferd fra den dag du blev skapt, til det blev funnet urettferdighet hos dig. 
26:28:16: Ved din store handel fyltes ditt indre med urett, og du syndet; så vanhelliget jeg dig og drev dig bort fra gudefjellet, og jeg gjorde dig til intet, du salvede kjerub, så du ikke mere fikk være blandt de skinnende stener. 
26:28:17: Ditt hjerte ophøiet sig for din skjønnhets skyld, du ødela din visdom på grunn av din glans; jeg kastet dig til jorden, jeg la dig ned for kongers åsyn, forat de skulde se på dig med lyst. 
26:28:18: Ved dine mange misgjerninger, ved din urettferdige handel vanhelliget du dine helligdommer. Derfor lar jeg ild gå ut fra dig, og den skal fortære dig; jeg gjør dig til aske på jorden for alle deres øine som ser dig. 
26:28:19: Alle som kjente dig blandt folkene, er forferdet over dig; en redsel er du blitt, og du er blitt borte - for evig tid. 
26:28:20: Og Herrens ord kom til mig, og det lød så: 
26:28:21: Menneskesønn! Vend ditt åsyn mot Sidon og spå mot det 
26:28:22: og si: Så sier Herren, Israels Gud: Se, jeg kommer over dig, Sidon, og jeg vil forherlige mig i dig. Og de skal kjenne at jeg er Herren, når jeg holder dom over det og åpenbarer min hellighet på det. 
26:28:23: Og jeg vil sende pest og blod over det på dets gater, og drepte menn skal falle der for et sverd som kommer over det fra alle kanter, og de skal kjenne at jeg er Herren. 
26:28:24: Og ikke skal det for Israels hus mere være nogen stikkende torn eller brennende tistel blandt alle dem som bor rundt omkring dem, og som forakter dem, og de skal kjenne at jeg er Herren, Israels Gud. 
26:28:25: Så sier Herren, Israels Gud: Når jeg samler Israels hus fra de folk som de er spredt iblandt, da vil jeg åpenbare min hellighet på dem for folkenes øine, og de skal bo i sitt land, det som jeg gav min tjener Jakob, 
26:28:26: og de skal bo trygt der og bygge hus og plante vingårder - de skal bo trygt, mens jeg holder dom over alle dem som bor rundt omkring dem, og som forakter dem, og de skal kjenne at jeg, Herren, er deres Gud. 
26:29:1: I det tiende år, i den tiende måned, på den tolvte dag i måneden, kom Herrens ord til mig, og det lød så: 
26:29:2: Menneskesønn! Vend ditt åsyn mot Farao, Egyptens konge, og spå mot ham og mot hele Egypten! 
26:29:3: Tal og si: Så sier Herren, Israels Gud: Se, jeg kommer over dig, Farao, Egyptens konge, du det store sjøuhyre, som ligger midt i dine strømmer, som sier: Mig tilhører min strøm, jeg har selv gjort den. 
26:29:4: Jeg vil legge kroker i dine kjever og la fiskene i dine strømmer henge fast i dine skjell, og jeg vil dra dig op av dine strømmer, både dig og alle fiskene i dine strømmer, som henger fast i dine skjell. 
26:29:5: Og jeg vil kaste dig ut i ørkenen, både dig og alle fiskene i dine strømmer; på marken skal du falle; du skal ikke samles og ikke sankes; jeg gir dig til føde for jordens dyr og himmelens fugler. 
26:29:6: Og alle Egyptens innbyggere skal kjenne at jeg er Herren, fordi de har vært en rørstav for Israels hus; 
26:29:7: når de* tar dig** ved ditt håndfang, så knekkes du og kløver skulderen på dem alle, og når de støtter sig på dig, brytes du i stykker og får lendene på dem alle til å vakle. # <* Israels folk.> # <** Egypten.> 
26:29:8: Derfor sier Herren, Israels Gud, så: Se, jeg lar sverd komme over dig, og jeg vil utrydde både folk og fe hos dig. 
26:29:9: Og Egyptens land skal bli til en ørken, et øde land, og de skal kjenne at jeg er Herren, fordi han sa: Strømmen hører mig til, jeg har gjort den. 
26:29:10: Se, derfor kommer jeg over dig og dine strømmer, og jeg vil gjøre Egyptens land til en øde ørken, full av grusdynger, fra Migdol til Syene* og like til Etiopias grense. # <* Migdol var en by i det nordlige Egypten, og Syene en by ved Egyptens sydgrense; 30, 6.> 
26:29:11: Ingen menneskefot skal fare frem der, og ingen fefot skal fare frem der, og det skal ikke reise sig igjen på firti år. 
26:29:12: Og jeg vil gjøre Egyptens land til en ørken blandt ødelagte land, og dets byer skal ligge øde blandt ødelagte byer i firti år, og jeg vil sprede egypterne blandt folkene og strø dem ut i landene. 
26:29:13: For så sier Herren, Israels Gud: Når firti år er gått, vil jeg samle egypterne fra de folk som de var spredt iblandt. 
26:29:14: Jeg vil gjøre ende på Egyptens fangenskap og føre dem tilbake til landet Patros, det land som de stammer fra, og der skal de være et ringe kongerike. 
26:29:15: Det skal være ringere enn andre kongeriker og ikke mere ophøie sig over folkene, og jeg vil gjøre dem fåtallige, så de ikke skal herske over folkene. 
26:29:16: Og for Israels hus skal det ikke mere være en tilflukt, som minner mig om deres misgjerning, når de vender sig til dem, og de skal kjenne at jeg er Herren, Israels Gud. 
26:29:17: I det syv og tyvende år, i den første måned, på den første dag i måneden, kom Herrens ord til mig, og det lød så: 
26:29:18: Menneskesønn! Nebukadnesar, Babels konge, har latt sin hær utføre et svært arbeid mot Tyrus; hvert hode er skallet og hver skulder slitt. Men lønn har han og hans hær ikke fått av Tyrus for det arbeid han har utført imot det. 
26:29:19: Derfor sier Herren, Israels Gud, så: Se, jeg gir Egyptens land til Nebukadnesar, Babels konge, og han skal føre bort dets rikdom og rane og røve alt hvad der er å rane og røve, og det skal hans hær ha til lønn. 
26:29:20: Som lønn for det arbeid han har utført, gir jeg ham Egyptens land; for de har arbeidet for mig, sier Herren, Israels Gud. 
26:29:21: Den samme dag vil jeg la et horn vokse op for Israels hus, og dig vil jeg gi en oplatt munn midt iblandt dem, og de skal kjenne at jeg er Herren. 
26:30:1: Og Herrens ord kom til mig, og det lød så: 
26:30:2: Menneskesønn! Spå og si: Så sier Herren, Israels Gud: Jamre eder: Ve oss, for en dag! 
26:30:3: For nær er dagen, ja, nær er Herrens dag; en dag med skyer, en dommens tid for folkene skal den være. 
26:30:4: Det skal komme et sverd mot Egypten, og Etiopia skal vri sig i angst, når drepte menn faller i Egypten; dets rikdom skal føres bort, og dets grunnvoller skal brytes ned. 
26:30:5: Etiopere og folk fra Put og Lud og alle de fremmede krigsfolk og kubeere og forbundslandets sønner skal sammen med dem* falle for sverdet. # <* egypterne.> 
26:30:6: Så sier Herren: Egyptens støtter skal falle, og dets stolte makt skal synke sammen; fra Migdol til Syene skal de der falle for sverdet, sier Herren, Israels Gud. 
26:30:7: Og det skal ligge øde blandt ødelagte land, og dets byer skal ligge blandt ødelagte byer. 
26:30:8: Og de skal kjenne at jeg er Herren, når jeg setter ild på Egypten, og alle dets hjelpere blir knust. 
26:30:9: Den dag skal sendebud fra mig dra ut på skib for å forferde Etiopia i dets trygghet, og det skal vri sig i angst, som på Egyptens dag; for se, det kommer. 
26:30:10: Så sier Herren, Israels Gud: Jeg vil gjøre ende på Egyptens larmende hop ved Nebukadnesar, Babels konge. 
26:30:11: Han og hans folk med ham, de grusomste blandt folkene, skal føres dit for å ødelegge landet, og de skal dra sine sverd mot Egypten og fylle landet med drepte menn. 
26:30:12: Og jeg vil la strømmene tørkes ut og selge landet i onde menneskers hånd, og jeg vil ødelegge landet og alt som i det er, ved fremmedes hånd; jeg, Herren, har talt. 
26:30:13: Så sier Herren, Israels Gud: Jeg vil tilintetgjøre de motbydelige avguder og utrydde de falske guder i Memfis, og det skal aldri mere opstå nogen fyrste i Egyptens land, og jeg vil la det falle frykt over Egyptens land. 
26:30:14: Jeg vil ødelegge Patros og sette ild på Soan, og jeg vil holde dom over No. 
26:30:15: Jeg vil utøse min harme over Sin, Egyptens sterke vern, og jeg vil utrydde den larmende hop i No. 
26:30:16: Jeg vil sette ild på Egypten; Sin skal skjelve og beve, og No bli inntatt, og Memfis skal bli overfalt av fiender ved høilys dag. 
26:30:17: Avens og Pibesets unge menn skal falle for sverdet, og de selv* skal gå i fangenskap. # <* byene selv, det er resten av folket der.> 
26:30:18: I Tehafnehes skal dagen bli mørk, når jeg der sønderbryter Egyptens åk, og det er ute med dets stolte makt; selv skal det dekkes av en sky, og dets døtre skal gå i fangenskap. 
26:30:19: Jeg vil holde dom over Egypten, og de skal kjenne at jeg er Herren. 
26:30:20: I det ellevte år, i den første måned, på den syvende dag i måneden, kom Herrens ord til mig, og det lød så: 
26:30:21: Menneskesønn! Jeg har sønderbrutt egypterkongen Faraos arm, og se, den er ikke blitt forbundet; ingen har brukt lægemidler eller lagt forbinding på, så den kunde bli sterk nok til å gripe sverdet. 
26:30:22: Derfor sier Herren, Israels Gud, så: Se, jeg kommer over Farao, Egyptens konge, og jeg vil sønderbryte hans armer, både den sterke og den sønderbrutte, og jeg vil la sverdet falle av hans hånd. 
26:30:23: Jeg vil sprede egypterne blandt folkene og strø dem ut i landene. 
26:30:24: Jeg vil styrke Babels konges armer og legge mitt sverd i hans hånd, men Faraos armer vil jeg sønderbryte, og han skal ligge og stønne for hans åsyn, som en hårdt såret mann stønner. 
26:30:25: Jeg vil gjøre Babels konges armer sterke, men Faraos armer skal synke, og de skal kjenne at jeg er Herren, når jeg legger mitt sverd i Babels konges hånd, og han rekker det ut mot Egyptens land. 
26:30:26: Og jeg vil sprede egypterne blandt folkene og strø dem ut i landene, og de skal kjenne at jeg er Herren. 
26:31:1: I det ellevte år, i den tredje måned, på den første dag i måneden, kom Herrens ord til mig, og det lød så: 
26:31:2: Menneskesønn! Si til Farao, Egyptens konge, og til hans larmende hop. Hvem er du lik i din storhet? 
26:31:3: Se, Assur var en seder på Libanon med fagre grener, en skyggende skog og høi av vekst, og hans krone nådde op mellem skyene. 
26:31:4: Vannet gjorde ham stor, vanndypet gjorde ham høi; med sine strømmer gikk det rundt om det sted hvor han var plantet, og sendte sine vannløp ut til alle markens trær. 
26:31:5: Derfor blev han høiere av vekst enn alle markens trær, og han fikk mange grener og lange kvister, fordi det var så rikelig med vann der han bredte sig ut; 
26:31:6: på hans kvister bygget alle himmelens fugler rede, og under hans grener fødte alle markens dyr sine unger, og i hans skygge bodde alle de mange folkeslag. 
26:31:7: Han var fager i sin storhet, med sine lange grener; for ved hans rot var det rikelig med vann. 
26:31:8: Sedrene i Guds have stilte ham ikke i skyggen, cypresser var ikke å ligne med hans kvister, og lønnetrær var ikke som hans grener; intet tre i Guds have var å ligne med ham i skjønnhet. 
26:31:9: Fager hadde jeg gjort ham i hans rikdom på grener, og alle Edens trær i Guds have misunte ham. 
26:31:10: Derfor sa Herren, Israels Gud, så: Siden han er blitt høi av vekst og har strakt sin krone op mellem skyene, og han er blitt overmodig fordi han var blitt så høi, 
26:31:11: så gir jeg ham i hendene på folkenes hersker, så han kan gjøre med ham som han vil. For hans ugudelighets skyld drev jeg ham ut, 
26:31:12: og fremmede, de grusomste blandt folkene, hugg ham ned og lot ham ligge; på fjellene og i alle daler falt hans kvister, og hans grener blev sønderbrutt ved alle landets bekker, og alle jordens folk steg ned fra hans skygge og lot ham ligge, 
26:31:13: på hans falne stamme slo alle himmelens fugler sig ned, og ved hans grener leiret sig alle markens dyr - 
26:31:14: forat ingen trær som vokser ved vann, skal bli overmodige av sin vekst og strekke sin krone op mellem skyene, og de sterkeste av dem, alle de som suger vann til sig, ikke skal stå der i sin høide; for de er alle sammen overgitt til døden og må ned til dødsrikets land, blandt menneskenes barn, til dem som har faret ned i graven. 
26:31:15: Så sier Herren, Israels Gud: Den dag han fór ned i dødsriket, lot jeg vanndypet sørge; jeg tildekket det for hans skyld, jeg stanset dets strømmer, og mange vann blev holdt tilbake. Jeg klædde Libanon i sort for hans skyld, og alle markens trær vansmektet for hans skyld. 
26:31:16: Ved braket av hans fall fikk jeg folkeslag til å skjelve, da jeg lot ham fare ned i dødsriket med dem som farer ned i graven; da blev de trøstet i dødsrikets land alle Edens trær, de beste og fagreste på Libanon, alle de som suget vann til sig. 
26:31:17: Også de fór ned med ham i dødsriket til dem som var drept med sverdet; for som hans arm* hadde de sittet i hans skygge midt iblandt folkene. # <* d.e. hans hjelpere.> 
26:31:18: Hvem er du* således lik i herlighet og storhet blandt Edens trær? - Så skal du da nedstøtes med Edens trær til dødsrikets land; midt iblandt uomskårne skal du ligge, sammen med dem som er drept med sverdet. Således skal det gå Farao og hele hans larmende hop, sier Herren, Israels Gud. # <* Farao; ESK 31, 2.> 
26:32:1: I det tolvte år, i den tolvte måned, på den første dag i måneden, kom Herrens ord til mig, og det lød så: 
26:32:2: Menneskesønn! Stem i en klagesang over Farao, Egyptens konge, og si til ham: En ung løve blandt folkene vilde du være lik, og så var du som sjøuhyret i havet, og du fór frem i dine elver og gjorde vannet grumset med dine føtter og gjorde dets strømmer mudret. 
26:32:3: Så sier Herren, Israels Gud: Derfor vil jeg utspenne mitt garn over dig ved skarer av mange folk, og de skal dra dig op i min not. 
26:32:4: Jeg vil kaste dig på land og slenge dig bort på marken, og jeg vil la alle himmelens fugler slå ned på dig og dyrene på hele jorden mette sig med dig. 
26:32:5: Jeg vil kaste ditt kjøtt op på fjellene og fylle dalene med din store kropp. 
26:32:6: Jeg vil vanne landet med strømmer av ditt blod helt op til fjellene, og bekkeleiene skal fylles med dig. 
26:32:7: Og når jeg utslukker dig, vil jeg tildekke himmelen og klæ dens stjerner i sort; solen vil jeg tildekke med skyer, og månen skal ikke la sitt lys skinne. 
26:32:8: Alle skinnende lys på himmelen vil jeg klæ i sort for din skyld, og jeg vil legge mørke over ditt land, sier Herren, Israels Gud. 
26:32:9: Jeg vil vekke sorg i mange folkeslags hjerter, når jeg lar tidenden om din undergang komme ut iblandt folkene, i land som du ikke kjenner. 
26:32:10: Jeg vil gjøre mange folk forferdet over dig, og deres konger skal gyse av redsel over dig, når jeg svinger mitt sverd for deres åsyn, og de skal beve hvert øieblikk, hver for sitt liv, på den dag du faller. 
26:32:11: For så sier Herren, Israels Gud: Babels konges sverd skal komme over dig. 
26:32:12: For kjempers sverd vil jeg la din larmende hop falle - de* er alle sammen de grusomste blandt folkene; de skal ødelegge Egyptens stolthet, og hele dets larmende hop skal tilintetgjøres. # <* kjempene.> 
26:32:13: Jeg vil utrydde alt dets fe, som har beitet ved de mange vann, og ingen menneskefot skal mere gjøre dem grumset, og ingen klov skal mere gjøre dem grumset. 
26:32:14: Da vil jeg la deres vann synke og deres strømmer flyte bort som olje, sier Herren, Israels Gud, 
26:32:15: når jeg gjør Egyptens land til en ørken, så landet ligger øde og tomt, når jeg slår ned alle dem som bor der; og de skal kjenne at jeg er Herren. 
26:32:16: Dette er en klagesang, og den skal bli sunget, folkenes døtre skal synge den; over Egypten og hele dets larmende hop skal de synge den, sier Herren, Israels Gud. 
26:32:17: I det tolvte år, på den femtende dag i måneden, kom Herrens ord til mig, og det lød så: 
26:32:18: Menneskesønn! Syng en gravsang over Egyptens larmende hop og la den fare ned, den og herlige folkeslags døtre, til dødsrikets land, sammen med dem som farer ned i graven! 
26:32:19: Overgår du nogen i skjønnhet? Far ned og legg dig hos de uomskårne! 
26:32:20: Midt iblandt dem som er drept med sverdet, skal de* falle; sverdet er gitt**. Dra avsted med det*** og med alle dets skarer! # <* egypterne.> # <** nemlig til kaldeerne, ESK 32, 11.> # <*** Egypten.> 
26:32:21: Kjempenes høvdinger taler midt ut fra dødsriket om ham* og hans hjelpere: De har faret ned, de ligger der, de uomskårne, drept med sverdet. # <* Farao.> 
26:32:22: Der er Assur og hele hans skare, rundt om ham er hans graver; alle sammen er drept, falt for sverdet; 
26:32:23: hans graver er lagt i hulens dypeste bunn, og hans skare er rundt omkring hans grav; alle sammen er drept, falt for sverdet, de som utbredte redsel i de levendes land. 
26:32:24: Der er Elam og hele hans larmende hop rundt omkring hans grav; alle sammen er drept, falt for sverdet, de som uomskårne fór ned til dødsrikets land, de som hadde utbredt redsel for sig i de levendes land og bar sin skam sammen med dem som farer ned i graven. 
26:32:25: Midt iblandt drepte menn er det redet et leie for ham med hele hans larmende hop, rundt omkring ham er hans graver; alle sammen er uomskårne, drept med sverdet, fordi redsel for dem var utbredt i de levendes land, og de bar sin skam sammen med dem som farer ned i graven; midt iblandt drepte menn blev han lagt. 
26:32:26: Der er Mesek-Tubal og hele hans larmende hop, rundt omkring ham er hans graver; alle sammen er uomskårne, drept med sverdet, fordi de hadde utbredt redsel for sig i de levendes land. 
26:32:27: Skulde de da ikke ligge hos kjemper, hos dem som er falt blandt de uomskårne, som fór ned til dødsriket med sine krigsvåben, og hvis sverd blev lagt under deres hoder, og hvis misgjerning tynget på deres ben, fordi det hadde vært redsel for kjempene i de levendes land? 
26:32:28: Og du, midt iblandt uomskårne skal du bli knust og ligge hos dem som er drept med sverdet. 
26:32:29: Dit er Edom kommet, hans konger og alle hans fyrster, som tross sitt velde er lagt hos dem som er drept med sverdet; de skal ligge hos uomskårne og hos dem som farer ned i graven. 
26:32:30: Dit er Nordens fyrster kommet alle sammen, og alle sidonierne, som fór ned med de drepte og blev til skamme tross den redsel folk hadde hatt for dem for deres veldes skyld, og uomskårne fikk de sitt leie hos dem som var drept med sverdet, og de bar sin skam sammen med dem som farer ned i graven. 
26:32:31: Dem skal Farao se, og han skal trøste sig over hele sin larmende hop; drept med sverdet er Farao og hele hans hær, sier Herren, Israels Gud. 
26:32:32: For jeg har utbredt redsel for ham i de levendes land, og han skal legges midt iblandt de uomskårne, hos dem som er drept med sverdet, Farao selv og hele hans larmende hop, sier Herren, Israels Gud. 
26:33:1: Og Herrens ord kom til mig, og det lød så: 
26:33:2: Menneskesønn! Tal til ditt folks barn og si til dem: Når jeg lar sverdet komme over et land, og landets folk tar en mann av sin midte og setter ham til vekter for sig, 
26:33:3: og han ser sverdet komme over landet og støter i basunen og advarer folket, 
26:33:4: men den som hører basunens lyd, ikke lar sig advare, og sverdet kommer og tar ham bort, da skal hans blod komme over hans eget hode; 
26:33:5: han hørte basunens lyd, men lot sig ikke advare, hans blod skal komme over ham selv; men hadde han latt sig advare, så hadde han berget sitt liv. 
26:33:6: Men når vekteren ser sverdet komme og ikke støter i basunen, og folket ikke blir advart, og sverdet kommer og tar bort nogen av dem, da blir han tatt bort for sin misgjernings skyld, men hans blod vil jeg kreve av vekterens hånd. 
26:33:7: Og du menneskesønn! Til vekter har jeg satt dig for Israels hus, og når du hører et ord av min munn, skal du advare dem fra mig. 
26:33:8: Når jeg sier til den ugudelige: Du ugudelige, du skal visselig dø, og du ikke taler og advarer den ugudelige for hans ferd, da skal han, den ugudelige, dø for sin misgjernings skyld, men hans blod vil jeg kreve av din hånd. 
26:33:9: Men når du har advart den ugudelige for hans ferd, at han skal vende om fra den, men han ikke vender om fra sin ferd, da skal han dø for sin misgjernings skyld, men du har reddet din sjel. 
26:33:10: Og du menneskesønn! Si til Israels hus: Således sier I: Våre overtredelser og synder tynger på oss, og for deres skyld visner vi bort; hvorledes kan vi da leve? 
26:33:11: Si til dem: Så sant jeg lever, sier Herren, Israels Gud, jeg har ikke behag i den ugudeliges død, men i at den ugudelige vender om fra sin ferd og lever. Vend om, vend om fra eders onde veier! Hvorfor vil I dø, Israels hus? 
26:33:12: Og du menneskesønn! Si til ditt folks barn: Den rettferdiges rettferdighet skal ikke frelse ham på den dag han synder, og den ugudelige skal ikke omkomme for sin ugudelighets skyld på den dag han vender om fra sin ugudelighet, og den rettferdige skal ikke kunne leve ved sin rettferdighet på den dag han synder. 
26:33:13: Når jeg sier om den rettferdige: Han skal visselig leve, og han setter sin lit til sin rettferdighet og gjør urett, da skal ingen av hans rettferdige gjerninger tilregnes ham, men for den uretts skyld som han har gjort, for den skal han dø. 
26:33:14: Og når jeg sier til den ugudelige: Du skal visselig dø, og han vender om fra sin synd og gjør rett og rettferdighet, 
26:33:15: så han, den ugudelige, gir pant tilbake, godtgjør det han har røvet, følger livets bud, så han ikke gjør urett, da skal han visselig leve, han skal ikke dø. 
26:33:16: Ingen av de synder som han har gjort, skal tilregnes ham; rett og rettferdighet har han gjort, han skal visselig leve. 
26:33:17: Og ditt folks barn sier: Herrens vei er ikke rett. Men det er deres vei som ikke er rett. 
26:33:18: Når den rettferdige vender om fra sin rettferdighet og gjør urett, så skal han dø. 
26:33:19: Og når den ugudelige vender om fra sin ugudelighet og gjør rett og rettferdighet, så skal han leve. 
26:33:20: Og enda sier I: Herrens vei er ikke rett. Jeg vil dømme eder, Israels hus, hver efter hans ferd. 
26:33:21: I det tolvte år efterat vi var bortført, i den tiende måned, på den femte dag i måneden, kom det nogen flyktninger fra Jerusalem til mig og sa: Staden er inntatt. 
26:33:22: Og Herrens hånd var kommet over mig om aftenen, før flyktningene kom, og han oplot min munn, innen de kom til mig om morgenen; min munn blev oplatt, og jeg var ikke lenger målløs. 
26:33:23: Og Herrens ord kom til mig, og det lød så: 
26:33:24: Menneskesønn! De som bor iblandt grushaugene der borte i Israels land, sier så: Abraham var bare en, og han fikk landet i eie; vi er mange, vi har fått landet til eiendom. 
26:33:25: Si derfor til dem: Så sier Herren, Israels Gud: I eter kjøtt med blodet i og løfter eders øine til eders motbydelige avguder og utøser blod - og I skulde eie landet? 
26:33:26: I trosser på eders sverd, I gjør det som vederstyggelig er, og krenker hverandres hustruer - og I skulde eie landet? 
26:33:27: Så skal du si til dem: Så sier Herren, Israels Gud: Så sant jeg lever, de som bor blandt grushaugene, skal visselig falle for sverdet, og den som er på marken, ham gir jeg til føde for de ville dyr, og de som er i fjellborgene og i hulene, skal dø av pest. 
26:33:28: Jeg vil gjøre landet til en ørken, en ødemark, og det skal være ute med dets stolte makt, og fjellene i Israel skal ligge øde, så ingen ferdes der. 
26:33:29: Og de skal kjenne at jeg er Herren, når jeg gjør landet til en ørken, en ødemark, for alle de vederstyggelige tings skyld som de har gjort. 
26:33:30: Og du menneskesønn! Ditt folks barn taler sammen om dig ved veggene og i husdørene, og alle sier de til hverandre: Kom og hør hvad det er for et ord som utgår fra Herren! 
26:33:31: Så kommer de til dig i store skarer, og de setter sig foran dig som mitt folk og hører dine ord, men de gjør ikke efter dem; for det som er efter deres smak, det gjør de, og til vinning står deres hu. 
26:33:32: Og se, du er for dem som en yndig sang, som en som har en vakker røst og spiller fagert; de hører dine ord, men gjør ikke efter dem. 
26:33:33: Men når det kommer - og komme skal det - da skal de kjenne at en profet har vært iblandt dem. 
26:34:1: Og Herrens ord kom til mig, og det lød så: 
26:34:2: Menneskesønn! Spå mot Israels hyrder, spå og si til dem, til hyrdene: Sa sier Herren, Israels Gud: Ve Israels hyrder, som røkter sig selv! Er det ikke hjorden hyrdene skal røkte? 
26:34:3: Fettet eter I, og med ullen klær I eder, det fete slakter I; hjorden røkter I ikke. 
26:34:4: Det svake har I ikke styrket, og det syke har I ikke lægt, og det sønderbrutte har I ikke forbundet, og det bortdrevne har I ikke ført tilbake, og det fortapte har I ikke opsøkt, men med vold og med hårdhet har I hersket over dem. 
26:34:5: Og således blev de adspredt, fordi de ingen hyrde hadde; de blev til føde for alle markens ville dyr og blev adspredt. 
26:34:6: Min hjord farer vill på alle fjell og på hver høi bakke, og over hele landet er min hjord spredt; det er ingen som spør, og ingen som leter efter den. 
26:34:7: Hør derfor Herrens ord, I hyrder! 
26:34:8: Så sant jeg lever, sier Herren, Israels Gud, sannelig, siden min hjord er blitt til rov, og den er blitt til føde for alle markens ville dyr, fordi den var uten hyrde, og mine hyrder ikke spurte efter min hjord, men hyrdene røktet sig selv og ikke røktet min hjord - 
26:34:9: derfor, I hyrder, hør Herrens ord! 
26:34:10: Så sier Herren, Israels Gud: Se, jeg kommer over hyrdene og vil kreve min hjord av deres hånd og la dem ophøre med å røkte hjorden, så hyrdene ikke mere skal røkte sig selv, og jeg vil redde min hjord av deres munn, så den ikke skal være til føde for dem. 
26:34:11: For så sier Herren, Israels Gud: Se, jeg kommer og vil spørre efter min hjord og se til den. 
26:34:12: Likesom en hyrde ser til sin hjord på den dag han er iblandt sine adspredte får, således vil jeg se til mine får og redde dem fra alle de steder hvor de er adspredt på en dag med skyer og skodde. 
26:34:13: Og jeg vil føre dem ut fra folkene og samle dem fra landene og føre dem til deres eget land, og jeg vil røkte dem på Israels fjell, i dalene og på alle de steder i landet hvor de bor. 
26:34:14: På en god beitemark vil jeg la dem beite, på Israels høie fjell skal deres havnegang være; der skal de hvile på en god havnegang, og på en fet beitemark skal de beite på Israels fjell. 
26:34:15: Jeg vil selv fø min hjord og selv la den hvile, sier Herren, Israels Gud. 
26:34:16: Det fortapte vil jeg opsøke, og det bortdrevne vil jeg føre tilbake, og det sønderbrutte vil jeg forbinde, og det syke vil jeg styrke. Men det fete og det sterke vil jeg ødelegge; jeg vil røkte det efter hvad rett er. 
26:34:17: Og I, min hjord, så sier Herren, Israels Gud: Se, jeg dømmer mellem får og får, mellem værer og bukker. 
26:34:18: Er det ikke nok med at I får beite den beste beitemark, siden I trår ned resten av eders beitemark med eders føtter? Er det ikke nok at I får drikke det klare vann, siden I gjør resten grumset med eders føtter? 
26:34:19: Og min hjord - skal den ete det som er trådt ned av eders føtter, og drikke det som er gjort grumset av eders føtter? 
26:34:20: Derfor sier Herren, Israels Gud, så til dem: Se, jeg kommer og vil dømme mellem de fete får og de magre får. 
26:34:21: Fordi I støter alle de svake bort med side og bog og stanger dem med eders horn, til I får adspredt dem og drevet dem utenfor, 
26:34:22: så vil jeg frelse min hjord, og de skal ikke mere være til rov, og jeg vil dømme mellem får og får. 
26:34:23: Og jeg vil opreise én hyrde over dem, og han skal røkte dem - min tjener David*; han skal røkte dem, han skal være deres hyrde. # <* d.e. Kristus, hvis stamfar og forbillede David var.> 
26:34:24: Og jeg, Herren, vil være deres Gud, og min tjener David skal være fyrste blandt dem; jeg, Herren, har talt. 
26:34:25: Og jeg vil gjøre en fredspakt med dem og utrydde ville dyr av landet, og de skal bo trygt i ørkenen og sove i skogene. 
26:34:26: Og jeg vil gjøre dem og landet omkring min haug til en velsignelse; jeg vil sende regn i rette tid; velsignelses regnstrømmer skal det være. 
26:34:27: Markens trær skal gi sin frukt, og jorden skal gi sin grøde, og de skal bo trygt i sitt land, og de skal kjenne at jeg er Herren, når jeg bryter stengene på deres åk og frir dem av deres hånd som holdt dem i trældom. 
26:34:28: Og de skal ikke mere være et rov for folkene, og jordens ville dyr skal ikke fortære dem; de skal bo trygt, og ingen skal skremme dem. 
26:34:29: Og jeg vil la en plantning vokse op for dem, som skal bli dem til navnkundighet, og de skal ikke mere bli bortrevet av hunger i landet og ikke mere bære folkenes hån. 
26:34:30: Og de skal kjenne at jeg, Herren deres Gud, er med dem, og at de, Israels hus, er mitt folk, sier Herren, Israels Gud. 
26:34:31: Og I, min hjord, den hjord jeg før, I er mennesker; jeg er eders Gud, sier Herren, Israels Gud. 
26:35:1: Og Herrens ord kom til mig, og det lød så: 
26:35:2: Menneskesønn! Vend ditt åsyn mot Se'ir-fjellet og spå mot det! 
26:35:3: Og du skal si til det: Så sier Herren, Israels Gud: Se, jeg kommer over dig, du Se'ir-fjell, og jeg vil rekke ut min hånd mot dig og gjøre dig til en ørken, en ødemark. 
26:35:4: Dine byer vil jeg gjøre til grushoper, og du selv skal bli til en ørken, og du skal kjenne at jeg er Herren. 
26:35:5: Fordi du bærer på et evig fiendskap og overgav Israels barn i sverdets vold - i deres ulykkes tid, da den misgjerning skjedde som førte til undergang, 
26:35:6: derfor, så sant jeg lever, sier Herren, Israels Gud, vil jeg gjøre dig til blod, og blod skal forfølge dig; fordi du ikke har hatet blod, skal blod forfølge dig. 
26:35:7: Jeg vil gjøre Se'ir-fjellet til en ørken, en ødemark, og utrydde av det både den som drar frem, og den som drar tilbake. 
26:35:8: Jeg vil fylle dets fjell med dets drepte menn; på dine hauger og i dine daler og i alle dine bekker skal det falle menn som er drept ved sverd. 
26:35:9: Til evige ørkener vil jeg gjøre dig, og dine byer skal ikke reise sig igjen, og I skal kjenne at jeg er Herren. 
26:35:10: Fordi du sa: De to folk* og de to land skal bli mine, og vi vil ta dem i eie, enda Herren har vært der, # <* Juda og Israel.> 
26:35:11: derfor vil jeg, så sant jeg lever, sier Herren, Israels Gud, gjøre med dig efter den vrede og avind som du har lagt for dagen i ditt hat mot dem, og jeg skal bli kjent blandt dem når jeg dømmer dig. 
26:35:12: Og du skal kjenne at jeg, Herren, har hørt alle de spottord som du har talt mot Israels fjell, da du sa: De er ødelagt, oss er de gitt til føde. 
26:35:13: Og I talte overmodig mot mig med eders munn og brukte mange ord mot mig; jeg har nok hørt det. 
26:35:14: Så sier Herren, Israels Gud: Mens all jorden gleder sig, vil jeg legge dig øde. 
26:35:15: Som du gledet dig fordi Israelættens arv blev ødelagt, således vil jeg gjøre mot dig; en ørken skal Se'ir-fjellet og hele, hele Edom bli, og de skal kjenne at jeg er Herren. 
26:36:1: Og du menneskesønn! Spå om Israels fjell og si: Hør Herrens ord, I Israels fjell! 
26:36:2: Så sier Herren, Israels Gud: Fordi fienden ropte: Ha, ha! over eder og sa: De evige hauger er blitt vår eiendom, 
26:36:3: derfor skal du spå og si: Så sier Herren, Israels Gud: Fordi, ja fordi de ødelegger og higer efter å opsluke eder fra alle sider, så I kan bli de andre folks eiendom, og fordi I er kommet på tunger og leber og i ondt rykte blandt folk, 
26:36:4: derfor, I Israels fjell, hør Herrens, Israels Guds ord! Så sier Herren, Israels Gud, til fjellene og haugene, til bekkene og dalene og til de øde grushauger og de forlatte byer, som er blitt til rov og til spott for de andre folk, som bor rundt omkring, 
26:36:5: derfor sier Herren, Israels Gud, så: Sannelig, i min brennende nidkjærhet har jeg talt mot de andre folk og mot hele Edom, som har tilkjent sig mitt land til eiendom med hjertens glede og med inderlig forakt, for å drive ut dem som bor der, og utplyndre det; 
26:36:6: derfor skal du spå om Israels land og si til fjellene og haugene, til bekkene og dalene: Så sier Herren, Israels Gud: Se, jeg har talt i min nidkjærhet og i min harme: Fordi I har båret folkenes hån, 
26:36:7: derfor sier Herren, Israels Gud, så: Jeg har løftet min hånd og svoret: Sannelig, de folk som bor rundt omkring eder, de skal selv lide hån. 
26:36:8: Men I, Israels fjell, I skal utskyte eders grener og bære eders frukt for mitt folk Israel; for det skal snart komme. 
26:36:9: For se, jeg kommer til eder, jeg vil vende mig til eder, og I skal bli dyrket og tilsådd. 
26:36:10: Og jeg vil føre mange mennesker op på eder, hele Israels hus, og i byene skal det atter bo folk, og ruinene skal bygges op igjen. 
26:36:11: Jeg vil føre mennesker og fe i mengde op på eder, og de skal økes og være fruktbare; jeg vil la det bo folk på eder som i fordums tider og gjøre mere vel mot eder enn i eders første tid, og I skal kjenne at jeg er Herren. 
26:36:12: Jeg vil la mennesker, mitt folk Israel, ferdes på eder, og de skal ta dig* i eie, og du skal være deres arv, og du skal ikke mere bli ved å gjøre dem barnløse. # <* d.e. Israels land.> 
26:36:13: Så sier Herren, Israels Gud: Fordi de sier til dig: Du er en menneskeeter, og du har gjort dine folk* barnløse, # <* Israel og Juda.> 
26:36:14: derfor skal du ikke mere ete mennesker og ikke mere føre dine folk til fall, sier Herren, Israels Gud. 
26:36:15: Og jeg vil ikke mere la dig høre folkenes hån, og folkeslagenes spott skal du ikke mere bære, og dine egne folk skal du ikke mere føre til fall, sier Herren, Israels Gud. 
26:36:16: Og Herrens ord kom til mig, og det lød så: 
26:36:17: Menneskesønn! Israels hus bodde i sitt land, og de gjorde det urent ved sin ferd og sine gjerninger; som en kvinnes månedlige urenhet var deres ferd for mitt åsyn. 
26:36:18: Da utøste jeg min harme over dem for det blods skyld som de hadde utøst over landet, og fordi de hadde gjort det urent med sine motbydelige avguder. 
26:36:19: Og jeg spredte dem blandt folkene, og de blev strødd omkring i landene; efter deres ferd og deres gjerninger dømte jeg dem. 
26:36:20: Og da de kom til de folk som de kom til, vanhelliget de mitt hellige navn, og det blev sagt om dem: Dette er Herrens folk, og allikevel måtte de dra ut av hans land! 
26:36:21: Da ynkedes jeg over mitt hellige navn, som Israels hus hadde vanhelliget blandt de folk som de var kommet til. 
26:36:22: Derfor skal du si til Israels hus: Så sier Herren, Israels Gud: Ikke for eders skyld, Israels hus, gjør jeg det, men for mitt hellige navns skyld, som I har vanhelliget blandt de folk som I er kommet til. 
26:36:23: Jeg vil hellige mitt store navn, som er blitt vanhelliget blandt folkene, det som I har vanhelliget blandt dem, og folkene skal kjenne at jeg er Herren, sier Herren, Israels Gud, når jeg åpenbarer min hellighet på eder for eders øine. 
26:36:24: Jeg vil hente eder fra folkene og samle eder fra alle landene, og jeg vil la eder komme til eders eget land. 
26:36:25: Og jeg vil sprenge rent vann på eder, og I skal bli rene; fra alle eders urenheter og fra alle eders motbydelige avguder vil jeg rense eder. 
26:36:26: Jeg vil gi eder et nytt hjerte, og en ny ånd vil jeg gi inneni eder, og jeg vil ta bort stenhjertet av eders kjød og gi eder et kjødhjerte. 
26:36:27: Min Ånd vil jeg gi inneni eder, og jeg vil gjøre at I følger mine bud og holder mine lover og gjør efter dem. 
26:36:28: I skal bo i det land jeg gav eders fedre, og I skal være mitt folk, og jeg vil være eders Gud. 
26:36:29: Jeg vil fri eder fra alle eders urenheter, og jeg vil kalle på kornet og gjøre det foldrikt og ikke la hungersnød komme over eder. 
26:36:30: Jeg vil mangfoldiggjøre trærnes frukt og markens grøde, så I ikke mere for hungers skyld skal lide hån blandt hedningefolkene. 
26:36:31: Da skal I komme i hu eders onde ferd og eders gjerninger, som ikke var gode, og I skal vemmes ved eder selv for eders misgjerninger og eders vederstyggeligheter. 
26:36:32: Ikke for eders skyld gjør jeg det, sier Herren, Israels Gud, det skal I vite! Blues og skam eder over eders ferd, Israels hus! 
26:36:33: Så sier Herren, Israels Gud: Den dag jeg renser eder fra alle eders misgjerninger, vil jeg la det bo folk igjen i byene, og ruinene skal bygges op igjen, 
26:36:34: og det land som var lag øde, skal bli dyrket i stedet for at det var en ørken for hver manns øine som gikk forbi. 
26:36:35: Da skal de si: Dette land som var lagt øde, er blitt som Edens have, og de herjede og ødelagte og nedrevne byer er gjenreist og har fått murer. 
26:36:36: Og de folk som blir igjen rundt omkring eder, skal kjenne at jeg, Herren, har bygget op igjen det som var revet ned, og tilplantet det ødelagte land. Jeg, Herren, har sagt det, og jeg skal gjøre det. 
26:36:37: Så sier Herren, Israels Gud: Også i dette vil jeg bønnhøre Israels hus, så jeg gjør det for dem: Jeg vil gjøre det så rikt på folk som en fårehjord; 
26:36:38: som en hellig hjord, som Jerusalems hjord på dets høitider, således skal de ødelagte byer bli fulle av menneskehjorder, og de skal kjenne at jeg er Herren. 
26:37:1: Herrens hånd kom over mig, og Herren førte mig i ånden ut og satte mig ned midt i dalen, og den var full av ben. 
26:37:2: Og han førte mig rundt omkring dem, og jeg så at de lå der i store mengder utover dalen, og de var aldeles tørre. 
26:37:3: Og han sa til mig: Menneskesønn! Skal disse ben bli levende? Jeg svarte: Herre, Israels Gud, du vet det! 
26:37:4: Da sa han til mig: Spå om disse ben og si til dem: I tørre ben, hør Herrens ord! 
26:37:5: Så sier Herren, Israels Gud, til disse ben: Se, jeg lar det komme livsånde i eder, og I skal bli levende. 
26:37:6: Jeg vil legge sener på eder og la det komme kjøtt på eder og dekke eder med hud og gi livsånde i eder, og I skal bli levende; og I skal kjenne at jeg er Herren. 
26:37:7: Og jeg spådde således som det var befalt mig, og mens jeg spådde, hørtes det en sterk lyd; det blev et bulder, og benene nærmet sig til hverandre. 
26:37:8: Og jeg så, og se, det kom sener og kjøtt på dem, og ovenpå det drog det sig hud over dem; men livsånde var det ikke i dem. 
26:37:9: Da sa han til mig: Spå og tal til livsånden! Spå, menneskesønn, og si til livsånden: Så sier Herren, Israels Gud: Kom du livsånde fra de fire vinder og blås på disse drepte menn, så de kan bli levende! 
26:37:10: Og jeg spådde således som han hadde befalt mig, og livsånden kom i dem, og de blev levende og stod op på sine føtter, en meget, meget stor hær. 
26:37:11: Og han sa til mig: Menneskesønn! Disse ben er hele Israels hus. Se, de sier: Våre ben er fortørket, og vårt håp er gått til grunne; vi er fortapt. 
26:37:12: Spå derfor og si til dem: Så sier Herren, Israels Gud: Se, jeg åpner eders graver og lar eder, mitt folk, stige op av eders graver, og jeg fører eder til Israels land. 
26:37:13: Og I skal kjenne at jeg er Herren, når jeg åpner eders graver og lar eder, mitt folk, stige op av eders graver. 
26:37:14: Og jeg vil gi min Ånd i eder, og I skal bli levende, og jeg vil bosette eder i eders land, og I skal kjenne at jeg, Herren, har sagt det, og at jeg også vil gjøre det, sier Herren. 
26:37:15: Og Herrens ord kom til mig, og det lød så: 
26:37:16: Og du menneskesønn! Ta dig en stav og skriv på den: For Juda og for Israels barn, hans medbrødre! Og ta dig en annen stav og skriv på den: For Josef - en stav for Efra'im og hele Israels hus, hans medbrødre! 
26:37:17: Og sett dem sammen, den ene til den andre, til én stav, så de blir til ett i din hånd! 
26:37:18: Og når ditt folks barn sier til dig: Vil du ikke la oss få vite hvad du mener med dette? - 
26:37:19: da skal du si til dem: Så sier Herren, Israels Gud: Se, jeg tar Josefs stav, som er i Efra'ims hånd, og Israels stammer, hans medbrødre, og jeg legger dem til Judas stav og gjør dem til én stav, så de blir til ett i min hånd. 
26:37:20: Og de staver som du skriver på, skal du holde i din hånd for deres øine. 
26:37:21: Si så til dem: Så sier Herren, Israels Gud: Se, jeg henter Israels barn fra de folk som de drog bort til, og jeg vil samle dem fra alle kanter og føre dem til deres eget land. 
26:37:22: Og jeg vil gjøre dem til ett folk i landet, på Israels fjell, og én konge skal være konge for dem alle, og de skal ikke mere være to folk og ikke mere dele sig i to riker. 
26:37:23: Og de skal ikke mere gjøre sig urene med sine motbydelige avguder og med sine vederstyggeligheter eller med nogen av sine misgjerninger, og jeg vil utfri dem fra alle deres bosteder, hvor de har syndet, og rense dem, og de skal være mitt folk, og jeg vil være deres Gud. 
26:37:24: Og min tjener David* skal være konge over dem, og én hyrde skal det være for dem alle, og mine lover skal de følge, og mine bud skal de holde og gjøre efter dem. # <* ESK 34, 23.> 
26:37:25: Og de skal bo i det land jeg gav min tjener Jakob, det som eders fedre bodde i; de skal bo i det, de og deres barn og deres barnebarn, til evig tid, og David, min tjener, skal være deres fyrste evindelig. 
26:37:26: Og jeg vil gjøre en fredspakt med dem - en evig pakt med dem skal det være; og jeg vil bosette dem i mitt land og la dem bli tallrike, og jeg vil sette min helligdom midt iblandt dem for evig tid. 
26:37:27: Og min bolig skal være over dem, og jeg vil være deres Gud, og de skal være mitt folk. 
26:37:28: Og folkene skal kjenne at jeg er Herren, som helliger Israel, når min helligdom blir midt iblandt dem til evig tid. 
26:38:1: Og Herrens ord kom til mig, og det lød så: 
26:38:2: Menneskesønn! Vend ditt åsyn mot Gog i Magogs land, fyrsten over Ros, Mesek og Tubal, og spå imot ham! 
26:38:3: Og du skal si: Så sier Herren, Israels Gud: Se, jeg kommer over dig, Gog, fyrste over Ros, Mesek og Tubal! 
26:38:4: Og jeg vil vende dig om og legge kroker i dine kjever og føre dig ut med hele din hær, hester og ryttere, alle sammen prektig klædd, en stor skare med store og små skjold, alle sammen med sverd i hånd. 
26:38:5: Persere, etiopere og puteere er med dem, alle sammen med skjold og hjelm; 
26:38:6: Gomer og alle dets skarer, Togarma-folket i det ytterste Norden og alle dets skarer, mange folkeslag er med dig. 
26:38:7: Rust dig og gjør alt ferdig, du og alle dine skarer, som har samlet sig hos dig, og du skal være deres fører. 
26:38:8: Når lang tid er gått, kommer turen til dig; ved årenes ende skal du komme til et land som er utfridd fra sverdet, og hvis folk er samlet fra mange folkeslag, op på Israels fjell som stadig hadde ligget øde; men nu er det ført ut fra folkene, og de bor der trygt alle sammen. 
26:38:9: Dit skal du dra op; som en storm skal du komme, som en sky skal du være til å skjule landet, du og alle dine skarer og mange folkeslag med dig. 
26:38:10: Så sier Herren, Israels Gud: På den dag skal tanker stige op i ditt hjerte, og du skal tenke ut et ondt råd 
26:38:11: og si: Jeg vil dra op imot et land med åpne byer, jeg vil komme over fredelige folk, som bor trygt, som alle sammen bor uten murer og hverken har bom eller porter - 
26:38:12: for å rane og røve og ta hærfang, for å vende din hånd mot ruiner som er bygget op igjen, og mot et folk som er sanket sammen fra folkene, som har samlet sig fe og gods, som bor på jordens navle* # <* d.e. på jordens høie midte; ESK 5, 5.> 
26:38:13: Sjeba og Dedan og kjøbmennene fra Tarsis og alle deres unge løver* skal si til dig: Kommer du for å rane og røve? Har du samlet dine skarer for å vinne hærfang, for å føre bort sølv og gull, for å ta fe og gods, for å gjøre et stort bytte? # <* d.e. rovgjerrige handelsfyrster.> 
26:38:14: Derfor skal du spå, menneskesønn, og si til Gog: Så sier Herren, Israels Gud: Se, på den tid da mitt folk Israel bor trygt, får du nok vite det. 
26:38:15: Da skal du komme fra ditt land, fra det ytterste Norden, du og mange folkeslag med dig, alle sammen ridende på hester, en stor skare og en tallrik hær, 
26:38:16: og du skal dra op imot mitt folk Israel som en sky og skjule landet; i de siste dager skal det skje, da lar jeg dig komme over mitt land, forat folkene skal lære mig å kjenne, når jeg for deres øine åpenbarer min hellighet på dig, Gog! 
26:38:17: Så sier Herren, Israels Gud: Er det dig jeg har talt om i fordums dager ved mine tjenere, Israels profeter, de som spådde i de dager, år efter år, at jeg vilde la dig komme over dem? 
26:38:18: Men på den samme dag, den dag Gog kommer over Israels land, sier Herren, Israels Gud, da skal min harme stige op i mitt åsyn. 
26:38:19: Og i min nidkjærhet, i min brennende vrede sier jeg: Sannelig, på den samme dag skal det komme et stort jordskjelv over Israels land. 
26:38:20: Og havets fisker og himmelens fugler og markens dyr og alt det kryp som rører sig på jorden, og alle de mennesker som bor på jorden, skal skjelve for mitt åsyn, og fjellene skal ramle og bergveggene styrte ned, og hver en mur skal falle til jorden. 
26:38:21: Og jeg vil kalle på sverdet imot ham på alle mine fjell, sier Herren, Israels Gud; den enes sverd skal vendes mot den andre. 
26:38:22: Og jeg vil gå i rette med ham med pest og med blod, og et skyllregn og haglstener, ild og svovel vil jeg la regne ned over ham og hans skarer og over de mange folkeslag som er med ham. 
26:38:23: Og jeg vil åpenbare min storhet og min hellighet og gi mig til kjenne for mange folks øine, og de skal kjenne at jeg er Herren. 
26:39:1: Og du menneskesønn! Spå mot Gog og si: Så sier Herren, Israels Gud: Se, jeg kommer over dig, Gog, fyrste over Ros, Mesek og Tubal! 
26:39:2: Og jeg vil vende dig om og drive dig frem og føre dig op fra det ytterste Norden og la dig komme over Israels fjell. 
26:39:3: Og jeg vil slå din bue ut av din venstre hånd og la dine piler falle ut av din høire hånd. 
26:39:4: På Israels fjell skal du falle, du og alle dine skarer og de folkeslag som er med dig; jeg gir dig til føde for allehånde rovfugler og for markens ville dyr. 
26:39:5: På marken skal du falle; for jeg har talt, sier Herren, Israels Gud. 
26:39:6: Og jeg vil sende ild mot Magog og mot dem som bor trygt på øene, og de skal kjenne at jeg er Herren. 
26:39:7: Og jeg vil kunngjøre mitt hellige navn blandt mitt folk Israel og ikke mere la mitt hellige navn bli vanhelliget, og folkene skal kjenne at jeg er Herren, hellig i Israel. 
26:39:8: Se, det kommer, det skjer, sier Herren, Israels Gud; det er dagen som jeg har talt om. 
26:39:9: Da skal de som bor i Israels byer, gå ut og gjøre op ild og holde den ved like med rustninger og små og store skjold, med buer og piler og staver og spyd, og de skal holde ilden ved like dermed i syv år; 
26:39:10: de skal ikke hente ved fra marken og ikke hugge i skogene, men med rustningene skal de holde ilden ved like, og de skal røve fra dem som har røvet fra dem, og plyndre dem som har plyndret dem, sier Herren, Israels Gud. 
26:39:11: På den dag vil jeg gi Gog et gravsted i Israel, de veifarendes dal østenfor havet, og den skal stenge veien for de veifarende; der skal de begrave Gog og hele hans hop, og de skal kalle den Gog-hopens dal. 
26:39:12: I syv måneder skal Israels hus holde på med å begrave dem for å rense landet. 
26:39:13: Alt folket i landet skal være med om å begrave dem, og det skal være dem til navnkundighet på den dag jeg åpenbarer min herlighet, sier Herren, Israels Gud. 
26:39:14: De skal utkåre nogen menn som stadig skal dra gjennem landet, og andre som sammen med dem som drar gjennem landet, skal begrave alle som er blitt liggende igjen på marken rundt om i landet, for å rense det. Når syv måneder er til ende, skal de gjennemsøke det, 
26:39:15: og når nogen av dem som skal dra gjennem landet, på sin ferd ser et menneskeben, da skal de sette op et merke der, til de som skal begrave, får begravet det i Goghopens dal. 
26:39:16: Også en by der skal ha navnet Hamona*. Således skal de rense landet. # <* d.e. dens (Gogs hærs) hop; ESK 39, 11. 15.> 
26:39:17: Og du menneskesønn! Så sier Herren, Israels Gud: Si til allehånde fugler og til alle markens ville dyr: Samle eder og kom, samle eder fra alle kanter til det slaktoffer som jeg slakter for eder, et stort slaktoffer på Israels fjell, og I skal ete kjøtt og drikke blod. 
26:39:18: Kjempers kjøtt skal I ete, og jordens fyrsters blod skal I drikke, værer, lam og bukker og okser, alle sammen gjødd i Basan. 
26:39:19: Og I skal ete fett til I blir mette, og drikke blod til I blir drukne, av det slaktoffer som jeg slakter for eder. 
26:39:20: I skal mettes ved mitt bord med ridehester og vognhester, med kjemper og alle slags krigsmenn, sier Herren, Israels Gud. 
26:39:21: Og jeg vil åpenbare min herlighet iblandt folkene, og alle folkene skal se den dom som jeg har holdt, og min hånd som jeg har lagt på dem. 
26:39:22: Og Israels hus skal kjenne at jeg er Herren deres Gud, fra den dag og fremdeles. 
26:39:23: Og folkene skal skjønne at det var for sin misgjernings skyld Israels hus blev bortført, fordi de var troløse mot mig, så jeg skjulte mitt åsyn for dem og gav dem i deres fienders hånd, og de falt for sverdet alle sammen; 
26:39:24: efter deres urenhet og deres overtredelser gjorde jeg med dem, og jeg skjulte mitt åsyn for dem. 
26:39:25: Derfor sier Herren, Israels Gud, så: Nu vil jeg gjøre ende på Jakobs fangenskap og forbarme mig over hele Israels hus, og jeg vil vise mig nidkjær for mitt hellige navn. 
26:39:26: Og de skal bære* sin skam og all den troløshet de har vist mot mig, når de bor trygt i sitt land, og ingen forferder dem. # <* d.e. ydmykende erkjenne.> 
26:39:27: Når jeg fører dem tilbake fra folkene og samler dem fra deres fienders land og åpenbarer min hellighet på dem for de mange folks øine, 
26:39:28: da skal de kjenne at jeg er Herren deres Gud, fordi jeg har bortført dem til folkene, men igjen samlet dem til deres land og ikke lar nogen av dem bli igjen der. 
26:39:29: Og jeg vil ikke mere skjule mitt åsyn for dem, for jeg vil utgyde min Ånd over Israels hus, sier Herren, Israels Gud. 
26:40:1: I det fem og tyvende år efterat vi var bortført, i begynnelsen av året, på den tiende dag i måneden, i det fjortende år efterat staden var inntatt, nettop på den dag kom Herrens hånd over mig, og han førte mig dit. 
26:40:2: I syner fra Gud førte han mig til Israels land og satte mig ned på et meget høit fjell, og på det var det likesom en bygget stad i syd. 
26:40:3: Og han førte mig dit, og se, der var en mann hvis utseende var som kobber, og han hadde en linsnor og en målestang i hånden, og han stod i porten. 
26:40:4: Og mannen talte til mig og sa: Menneskesønn! Se med dine øine og hør med dine ører og akt på alt det jeg vil la dig se! For du er ført hit, forat jeg skal la dig se det. Forkynn Israels hus alt det du ser! 
26:40:5: Og se, der var en mur utenom huset rundt omkring, og målestangen som mannen hadde i hånden, var seks alen lang, hver alen regnet til en almindelig alen og en håndsbredd, og han målte murens bredde: den var én stang, og dens høide: den var én stang. 
26:40:6: Så kom han til den port hvis forside vendte mot øst, og han steg op efter dens trin, og han målte portens treskel: den var én stang i bredden - det var den første treskel, og den var én stang i bredden. 
26:40:7: Og hver vaktstue var én stang i lengden og én stang i bredden, og mellem vaktstuene var det fem alen, og portens treskel ved portens forhall på den indre side var én stang. 
26:40:8: Og han målte portens forhall på den indre side: den var én stang. 
26:40:9: Og han målte portens forhall: den var åtte alen, og dens pilarer: de var to alen, og portens forhall lå på innsiden. 
26:40:10: Og vaktstuene i porten mot øst var tre på hver side; alle tre var like store, og pilarene på begge sider var også like store. 
26:40:11: Og han målte bredden av portåpningen: den var ti alen, og portens lengde: den var tretten alen. 
26:40:12: Foran vaktstuene var det et rum på én alen, og på den andre side likeledes et rum på én alen, og hver vaktstue var seks alen på den ene og seks alen på den andre side. 
26:40:13: Og han målte porten fra den ene vaktstues tak til den andres tak; bredden var fem og tyve alen, dør midt imot dør. 
26:40:14: Og han gjorde pilarene seksti alen høie, og tett inn til pilarene lå forgården, rundt omkring porten. 
26:40:15: Og fra forsiden av inngangsporten til forsiden av den indre ports forhall var det femti alen. 
26:40:16: Og det var vinduer med fast gitter på vaktstuene og på deres pilarer innentil mot porten rundt omkring, og likeså på hallene; og således var det vinduer rundt omkring innentil, og på pilarene var det palmer. 
26:40:17: Så førte han mig til den ytre forgård, og se, der var kammere og et stenlagt gulv i forgården rundt omkring; det var tretti kammer på stengulvet. 
26:40:18: Og stengulvet var ved siden av portene, svarende til portenes lengde; det var det nedre stengulv. 
26:40:19: Og han målte bredden fra forsiden av den nedre port til den ytre forside av den indre forgård: den var hundre alen mot øst og mot nord. 
26:40:20: Så målte han lengden og bredden av den port hvis forside vendte mot nord, og som hørte til den ytre forgård. 
26:40:21: Den hadde tre vaktstuer på hver side, og dens pilarer og haller var like store som de i den første port; den var femti alen lang og fem og tyve alen bred. 
26:40:22: Og vinduene og hallene og palmene var like store som i den port hvis forside vendte mot øst, og på syv trin steg en op i den, og dens haller lå foran dem. 
26:40:23: Og porten til den indre forgård lå midt imot porten som vendte mot nord og mot øst, og han målte hundre alen fra port til port. 
26:40:24: Så førte han mig mot syd, og se, der var en port som vendte mot syd; og han målte dens pilarer og haller: de var like store som de andre. 
26:40:25: Og det var vinduer på den og på dens haller rundt omkring, likesom de andre vinduer; den var femti alen lang og fem og tyve alen bred. 
26:40:26: Dens trapper hadde syv trin, og dens haller lå foran dem, og på dens pilarer var det palmer, én på hver side. 
26:40:27: Og der var en port til den indre forgård mot syd, og fra denne port til porten mot syd målte han hundre alen. 
26:40:28: Så førte han mig til den indre forgård gjennem sydporten, og han målte sydporten: den var like stor som de andre. 
26:40:29: Og dens vaktstuer og pilarer og haller var like store som de andre, og det var vinduer på den og på dens haller rundt omkring; den var femti alen lang og fem og tyve alen bred. 
26:40:30: Og det var haller rundt omkring, fem og tyve alen lange og fem alen brede. 
26:40:31: Hallene vendte ut mot den ytre forgård, og det var palmer på pilarene, og trappene hadde åtte trin. 
26:40:32: Så førte han mig til den indre forgård mot øst, og han målte porten: den var like stor som de andre. 
26:40:33: Og dens vaktstuer og pilarer og haller var like store som de andre; og det var vinduer på den og på dens haller rundt omkring; den var femti alen lang og fem og tyve alen bred. 
26:40:34: Hallene vendte ut mot den ytre forgård, og det var palmer på pilarene på begge sider, og trappene hadde åtte trin. 
26:40:35: Så førte han mig til nordporten, og han målte den: den var like stor som de andre; 
26:40:36: likeså målte han dens vaktstuer, pilarer og haller, og det var vinduer på den rundt omkring; den var femti alen lang og fem og tyve alen bred. 
26:40:37: Dens pilarer vendte mot den ytre forgård, og det var palmer på pilarene på begge sider, og trappene hadde åtte trin. 
26:40:38: Og det var et kammer med dør i pilarene ved portene; der skulde de skylle brennofferet. 
26:40:39: Og i portens forhall stod det to bord på hver side til å slakte brennofferet og syndofferet og skyldofferet på. 
26:40:40: Og ved den ytre sidevegg, når en steg op i inngangen til porten mot nord, stod det to bord, og ved deri andre sidevegg på portens forhall stod det også to bord - 
26:40:41: fire bord ved hver sidevegg på porten, tilsammen åtte bord; på dem skulde de slakte. 
26:40:42: Og det var fire bord til brennofferet; de var av hugne stener, halvannen alen lange og halvannen alen brede og én alen høie; på dem skulde de legge de redskaper som de skulde slakte brennofferet og slaktofferet med. 
26:40:43: Og dobbelte knagger, en hånd brede, var festet på huset rundt omkring, og på bordene skulde offerkjøttet legges. 
26:40:44: Og utenfor den indre port var det kammere for sangerne i den indre forgård, ved siden av nordporten, og deres forside vendte mot syd; og en annen rad kammer lå ved siden av østporten, med forsiden mot nord. 
26:40:45: Og han talte til mig og sa: Denne rad kammer, hvis forside vender mot syd, er for de prester som tar vare på det som er å vareta ved huset; 
26:40:46: men den rad kammer hvis forside vender mot nord, er for de prester som tar vare på det som er å vareta ved alteret; det er Sadoks sønner, de av Levis sønner som treder nær til Herren for å tjene ham. 
26:40:47: Og han målte forgården: den var hundre alen lang og hundre alen bred i firkant, og alteret* stod foran huset. # <* d.e. brennofferalteret; ESK 43, 13 fg.> 
26:40:48: Så førte han mig til husets forhall og målte forhallens pilarer: de var fem alen på den ene og fem alen på den andre side, og portens bredde: den var tre alen på hver side. 
26:40:49: Forhallen var tyve alen lang og elleve alen bred, og den hadde trin som førte op til den, og ved pilarene stod der søiler, én på hver side. 
26:41:1: Så førte han mig til det Hellige, og han målte pilarene: de var seks alen brede på den ene og seks alen brede på den andre side - det var teltets bredde. 
26:41:2: Døren var ti alen bred, og sideveggene ved døren var fem alen på hver side; og han målte dets* lengde: den var firti alen, og bredden: den var tyve alen. # <* det Helliges; ESK 41, 1.> 
26:41:3: Så gikk han innenfor* og målte dørens pilarer: de holdt to alen, og døren: den holdt seks alen, og dørens bredde: den var syv alen. # <* i det Aller-helligste; ESK 41, 4.> 
26:41:4: Og han målte dets lengde: den var tyve alen, og bredden: den var tyve alen, like mot templet*, og han sa til mig: Dette er det Aller-helligste. # <* d.e. det Hellige; ESK 41, 1.> 
26:41:5: Så målte han husets vegg: den var seks alen, og sidekammernes bredde: den var fire alen, rundt omkring hele huset. 
26:41:6: Og sidekammerne lå, kammer ved kammer, i tre stokkverk, tretti i hvert stokkverk, og de nådde til den vegg som huset hadde mot sidekammerne rundt omkring, så de var festet; men de var ikke festet til husets vegg. 
26:41:7: Og bredden på sidekammerne tiltok rundt omkring, jo høiere en kom op; for bygningen om huset gikk høiere og høiere op rundt omkring huset. Derfor var bredden mot huset større oventil, og fra det nederste stokkverk steg en op i det øverste gjennem det midterste. 
26:41:8: Og jeg så at huset hadde en forhøining rundt omkring; sidekammernes grunnvoller var en hel stang, seks alen til kanten. 
26:41:9: Bredden av den vegg sidekammerne hadde utad, var fem alen, og den frie plass mellem husets sidekammere 
26:41:10: og de andre kammer hadde en bredde av tyve alen rundt omkring hele huset. 
26:41:11: Og sidekammernes dører vendte ut mot den frie plass, én dør mot nord og én dør mot syd, og bredden av den frie plass var fem alen rundt omkring. 
26:41:12: Og den bygning som lå imot den avsondrede plass på vestsiden, var sytti alen bred, og bygningens vegg var fem alen i bredde rundt omkring, og dens lengde var nitti alen. 
26:41:13: Så målte han huset: det var hundre alen langt, og den avsondrede plass og bygningen med dens vegger var hundre alen i lengde. 
26:41:14: Og bredden av husets forside og den avsondrede plass mot øst var hundre alen. 
26:41:15: Og han målte lengden av bygningen mot den avsondrede plass, den bygning som lå på dens bakside, og dens svalganger på begge sider: det var hundre alen; likeså det indre tempel og forgårdens haller. 
26:41:16: Dørtresklene og vinduene med fast gitter og svalgangene rundt omkring i deres tredobbelte stokkverk - ovenfor dørtreskelen var det et panel av tre rundt omkring, og jorden nådde op til vinduene, og vinduene var dekket - 
26:41:17: og rummet over dørene og rummet inn til det indre hus og utadtil og på hele veggen rundt omkring, innvendig og utvendig, alt var efter mål. 
26:41:18: Og det var gjort kjeruber og palmer, én palme mellem to kjeruber, og kjerubene hadde to ansikter, 
26:41:19: et menneskes ansikt mot palmen på den ene side og en ung løves ansikt mot palmen på den andre side; således var det gjort på hele huset rundt omkring. 
26:41:20: Fra jorden til ovenfor døren var det gjort kjeruber og palmer, på templets* vegg. # <* det Helliges.> 
26:41:21: Templet* hadde firkantede dørstolper, og fremsiden på helligdommen** hadde samme utseende. # <* det Hellige.> # <** det Aller-helligste.> 
26:41:22: Alteret* var av tre, tre alen høit og to alen langt, og det hadde sine hjørner, og langsiden og veggene var av tre; og han talte til mig og sa: Dette er bordet som står for Herrens åsyn. # <* d.e. røkofferalteret.> 
26:41:23: Og templet og helligdommen hadde to dører. 
26:41:24: Og dørene hadde to fløier, to bevegelige dørfløier; den ene dør hadde to fløier og likeså den andre. 
26:41:25: Og på dem, på templets dører, var det gjort kjeruber og palmer, likesom de som var gjort på veggene; og det var en tverrbjelke av tre foran forhallen utentil. 
26:41:26: Og det var vinduer med fast gitter og palmer på forhallens sidevegger, på begge sider, og i husets sidekammere og på tverrbjelkene. 
26:42:1: Så førte han mig ut til den ytre forgård på veien mot nord, og derefter førte han mig til de kammer som lå midt imot den avsondrede plass og midt imot bygningen i nord, 
26:42:2: til langsiden, som målte hundre alen, med inngang mot nord, og bredden var femti alen. 
26:42:3: Ut mot den tyve alen brede plass som hørte til den indre forgård, og ut mot stengulvet i den ytre forgård var det svalgang mot svalgang i tre stokkverk. 
26:42:4: Og foran kammerne var det en gang på ti alens bredde til det indre, en vei på én alen, og deres dører vendte mot nord. 
26:42:5: Og de øverste kammer var mindre enn de andre; for svalgangene tok mere rum fra dem enn fra de nederste og de midterste i bygningen; 
26:42:6: for de lå i tre stokkverk, men hadde ingen søiler, således som forgårdene hadde; derfor blev rummet i de øverste kammer mindre enn i de nederste og midterste, fra jorden og opefter. 
26:42:7: Og en mur som var utenfor, langsmed kammerne, mot den ytre forgård, gikk foran kammerne; den var femti alen lang. 
26:42:8: For lengden av de kammer som lå mot den ytre forgård, var femti alen, men mot templet hundre alen. 
26:42:9: Og nedenfor disse kammer var inngangen fra øst, når en gikk inn i dem fra den ytre forgård. 
26:42:10: Der hvor forgårdens mur mot øst var bredest, foran den avsondrede plass og foran bygningen, lå det også kammere, 
26:42:11: og foran dem var det en vei; de var av utseende som de kammer som lå mot nord, og av samme lengde og bredde som de, og de hadde alle sine utganger og sine innretninger som de, og som dørene på dem, 
26:42:12: så var også dørene på de kammer som lå mot syd; der var en dør ved begynnelsen av veien, veien foran den tilsvarende mur, veien mot øst, hvor en gikk inn til dem. 
26:42:13: Så sa han til mig: Kammerne mot nord og kammerne mot syd, som ligger mot den avsondrede plass, de er hellige kammer, hvor prestene, som står Herren nær, skal ete det høihellige; der skal de legge det høihellige, både matofferet og syndofferet og skyldofferet; for det er et hellig sted. 
26:42:14: Når prestene går inn, skal de ikke gå ut igjen fra helligdommen i den ytre forgård, men de skal legge av der* de klær som de gjør tjeneste i; for de er hellige; de skal ta på sig andre klær og så nærme sig til den plass som er for folket. # <* d.e. i kammerne; ESK 42, 13. 44, 19.> 
26:42:15: Og da han var ferdig med målingen i det indre hus, førte han mig ut gjennem den port hvis forside vendte mot øst; og han målte der rundt omkring. 
26:42:16: Han målte østsiden med målestangen: den var fem hundre stenger efter målestangen rundt omkring. 
26:42:17: Han målte nordsiden: den var fem hundre stenger efter målestangen rundt omkring. 
26:42:18: Også sydsiden målte han: den var fem hundre stenger efter målestangen. 
26:42:19: Så gikk han omkring vestsiden og målte fem hundre stenger efter målestangen. 
26:42:20: På alle fire sider målte han det; det hadde en mur rundt omkring, fem hundre stenger lang og fem hundre bred, til å skille mellem det hellige og det som ikke var hellig. 
26:43:1: Så førte han mig til porten, den port som vendte mot øst. 
26:43:2: Og se, Israels Guds herlighet kom fra øst, og lyden av den var som lyden av store vann, og jorden lyste av hans herlighet. 
26:43:3: Og det var å se til som det syn jeg før hadde sett, som det syn jeg så da jeg kom for å ødelegge staden*, og synene var som det syn jeg hadde sett ved elven Kebar, og jeg falt ned på mitt ansikt. # <* d.e. forkynne staden at den skulde ødelegges; ESK 9, 1 fg. 1, 1. 28.> 
26:43:4: Og Herrens herlighet drog inn i huset gjennem den port som vendte mot øst. 
26:43:5: Og Ånden løftet mig op og førte mig inn i den indre forgård, og se, Herrens herlighet fylte huset. 
26:43:6: Og jeg hørte en som talte til mig ut fra huset, mens en mann stod ved siden av mig. 
26:43:7: Og han sa til mig: Menneskesønn! Dette er stedet for min trone, det sted hvor mine føtter skal stå, og der vil jeg bo blandt Israels barn til evig tid, og Israels hus skal ikke mere gjøre mitt hellige navn urent, hverken de eller deres konger, med sitt horelevnet og med sine kongers* døde kropper og med sine offerhauger, # <* d.e. sine avguders.> 
26:43:8: således som de gjorde da de satte deres treskel ved min treskel og deres dørstolpe ved siden av min dørstolpe, så bare veggen var imellem mig og dem, og de gjorde mitt hellige navn urent med de vederstyggelige ting som de fór med, så jeg måtte gjøre ende på dem i min vrede. 
26:43:9: Nu skal de ha sitt horelevnet og sine kongers døde kropper langt bort fra mig, og da vil jeg bo iblandt dem til evig tid. 
26:43:10: Du menneskesønn! Tal til Israels hus om dette hus, så de må skamme sig over sine misgjerninger og måle den velordnede bygning. 
26:43:11: Og når de skammer sig over alt det de har gjort, da skal du kunngjøre dem husets skikkelse og dets innredning, dets utganger og innganger, hele dets skikkelse og alle forskriftene om det - hele dets skikkelse og alle lovene om det, og skriv det op for deres øine, sa de må akte på hele dets skikkelse og alle forskriftene om det og gjøre efter dem. 
26:43:12: Dette er loven om huset: På toppen av fjellet skal hele dets område rundt omkring være høihellig. Se, dette er loven om huset. 
26:43:13: Og dette er alterets mål i alen - hver alen regnet til en almindelig alen og en håndsbredd: Der skal være et fotstykke, én alen høit og én alen bredt, og kantlisten på randen av det skal være et spann rundt omkring; dette er alterets underlag. 
26:43:14: Og fra fotstykket på jorden til den nedre avsats skal det være to alen, og bredden skal være én alen, og fra den lille avsats til den store avsats skal det være fire alen, og bredden skal være én alen. 
26:43:15: Og Har'el* skal være fire alen, og fra Ariel* og opefter skal det være fire horn**. # <* Har'el (Guds berg) er hele alterets øverste del, og Ariel (Guds arne) dets ildsted.> # <** 2MO 27, 2.> 
26:43:16: Og Ariel skal være tolv alen lang og tolv alen bred, firkantet med fire like lange sider. 
26:43:17: Og avsatsen skal være fjorten alen lang og fjorten alen bred, med fire like lange sider, og kantlisten omkring den skal være en halv alen, og fotstykket dertil en alen rundt omkring, og dets trapper skal vende mot øst. 
26:43:18: Og han sa til mig: Menneskesønn! Så sier Herren, Israels Gud: Dette er forskriftene om alteret på den dag det blir gjort, så en kan ofre brennoffer og sprenge blod på det. 
26:43:19: Du skal gi de levittiske prester, de som er av Sadoks ætt, som står mig nær og tjener mig, en ung okse til syndoffer, sier Herren, Israels Gud. 
26:43:20: Og du skal ta noget av dens blod og stryke på dets fire horn og på avsatsens fire hjørner og på kantlisten rundt omkring, og du skal rense det fra synd og gjøre soning for det. 
26:43:21: Så skal du ta syndofferoksen, og den skal brennes på det foreskrevne sted i huset, utenfor helligdommen. 
26:43:22: Og den annen dag skal du ofre en gjetebukk uten lyte til syndoffer, og de skal rense alteret fra synd, likesom de renset det med oksen. 
26:43:23: Når du er ferdig med å rense det, skal du ofre en ung okse uten lyte og en vær uten lyte av småfeet. 
26:43:24: Og du skal føre dem frem for Herrens åsyn, og prestene skal strø salt på dem og ofre dem til brennoffer for Herren. 
26:43:25: I syv dager skal du ofre en bukk til syndoffer hver dag, og en ung okse og en vær av småfeet, begge uten lyte, skal ofres. 
26:43:26: I syv dager skal de gjøre soning for alteret og rense det og innvie det. 
26:43:27: Og når disse dager er til ende, da skal prestene på den åttende dag og alltid senere ofre eders brennoffer og takkoffer på alteret, og jeg vil ha behag i eder, sier Herren, Israels Gud. 
26:44:1: Så førte han mig tilbake mot helligdommens ytre port, som vendte mot øst; den var lukket. 
26:44:2: Og Herren sa til mig: Denne port skal være lukket, den skal ikke åpnes, og ingen skal gå inn gjennem den; for Herren, Israels Gud, har gått inn gjennem den; derfor skal den være lukket. 
26:44:3: Men fyrsten skal, fordi han er fyrste, ha lov til å sitte der og holde måltid for Herrens åsyn; han skal gå inn gjennem portens forhall, og samme vei skal han gå ut. 
26:44:4: Så førte han mig gjennem nordporten til plassen foran huset, og jeg så, og se, Herrens herlighet fylte Herrens hus, og jeg falt ned på mitt ansikt. 
26:44:5: Og Herren sa til mig: Menneskesønn! Akt på og se med dine øine og hør med dine ører alt det jeg sier dig om alle forskriftene og alle lovene om Herrens hus, og du skal legge nøie merke til inngangen til huset og alle utgangene fra helligdommen. 
26:44:6: Og du skal si til de gjenstridige, til Israels hus: Så sier Herren, Israels Gud: Det får nu være nok med alle eders vederstyggeligheter, Israels hus, 
26:44:7: at I har latt fremmede med uomskåret hjerte og uomskåret kjøtt komme inn i min helligdom og være der, så mitt hus blev vanhelliget, mens I bar frem min mat, fett og blod, og således brøt min pakt, for ikke å nevne alle eders andre vederstyggeligheter. 
26:44:8: Og I tok ikke vare på det som var å vareta i mine helligdommer; men I satte andre i stedet for eder til å ta vare på det jeg vilde ha varetatt i min helligdom. 
26:44:9: Så sier Herren, Israels Gud: Ingen fremmed med uomskåret hjerte og uomskåret kjøtt skal komme inn i min helligdom, ingen av alle de fremmede som bor blandt Israels barn. 
26:44:10: Ja, endog de levitter som gikk bort fra mig da Israel fór vill og forvillet sig bort fra mig efter sine motbydelige avguder, de skal bære sin misgjerning. 
26:44:11: De skal være tjenere i min helligdom, opsynsmenn ved husets porter og tjenere i huset; de skal slakte brennofferet og slaktofferet for folket, og de skal stå for deres åsyn og tjene dem. 
26:44:12: Fordi de tjente dem for deres motbydelige avguders åsyn og var et anstøt til misgjerning for Israels hus, derfor har jeg løftet min hånd imot dem, sier Herren, Israels Gud, og de skal bære sin misgjerning. 
26:44:13: De skal ikke nærme sig til mig for å tjene mig som prester eller for å nærme sig til nogen av mine hellige ting - de høihellige; men de skal bære sin skam og de vederstyggeligheter som de har gjort sig skyldige i. 
26:44:14: Jeg vil sette dem til å ta vare på det som er å vareta i huset, med alt arbeidet der og alt som der skal gjøres. 
26:44:15: Men de levittiske prester, Sadoks sønner, som tok vare på det som var å vareta i min helligdom, da Israels barn forvillet sig bort fra mig, de skal trede nær til mig for å tjene mig, og de skal stå for mitt åsyn og frembære for mig fett og blod, sier Herren, Israels Gud. 
26:44:16: De skal gå inn i min helligdom, og de skal trede nær til mitt bord for å tjene mig, og de skal ta vare på det jeg vil ha varetatt. 
26:44:17: Og når de går inn i den indre forgårds porter, da skal de klæ sig i linklær; det skal ikke komme ull på dem når de tjener i den indre forgårds porter eller i huset. 
26:44:18: De skal ha linhuer på hodet og benklær av lin om lendene; de skal ikke omgjorde sig med noget som fremkaller sved. 
26:44:19: Og når de går ut i den ytre forgård, til folket i den ytre forgård, skal de ta av sig de klær som de har forrettet tjeneste i, og legge dem ned i de hellige kammer, og de skal ta på sig andre klær og ikke gjøre folket hellig med sine klær. 
26:44:20: De skal ikke rake hodet og heller ikke la håret vokse fritt; de skal klippe sitt hodehår. 
26:44:21: Vin skal ingen av prestene drikke når de går inn i den indre forgård. 
26:44:22: En enke eller en kvinne som hennes mann har skilt sig fra, skal de ikke ta til hustru, men bare jomfruer av Israels ætt; men de kan ta en enke som er enke efter en prest. 
26:44:23: De skal lære mitt folk å skille mellem hellig og vanhellig og forklare dem forskjellen mellem urent og rent. 
26:44:24: I rettssaker skal de stå frem og dømme, efter mine forskrifter skal de dømme i dem; mine lover og bud skal de holde på alle mine høitider, og mine sabbater skal de holde hellige. 
26:44:25: Ingen av dem må gå inn til et lik, så han blir uren; bare når det gjelder far eller mor eller sønn eller datter eller bror eller en søster som ikke har tilhørt nogen mann, kan de gjøre sig urene. 
26:44:26: Når han så er blitt ren igjen, skal de telle syv dager for ham; 
26:44:27: og den dag han går inn i helligdommen, inn i den indre forgård, for å tjene i helligdommen, skal han ofre sitt syndoffer, sier Herren, Israels Gud. 
26:44:28: Og deres arvedel skal være den at jeg er deres arv. Og nogen eiendom skal I ikke gi dem i Israel; jeg er deres eiendom. 
26:44:29: Matofferet og syndofferet og skyldofferet skal de ete, og alt bannlyst i Israel skal høre dem til. 
26:44:30: Og de første frukter av all førstegrøde av alle slag og hver offergave av alle slag, av alle eders offergaver, skal tilhøre prestene, og det første av eders deig skal I gi presten, så velsignelse må komme over ditt hus. 
26:44:31: Noget selvdødt eller sønderrevet av fugler eller dyr skal prestene ikke ete. 
26:45:1: Og når I lodder ut landet til eiendom, skal I avgi en gave til Herren, et hellig stykke av landet, fem og tyve tusen stenger langt og ti tusen bredt; det skal være hellig så langt det rekker rundt omkring. 
26:45:2: Av det skal det tas til helligdommen fem hundre stenger i lengde og fem hundre i bredde, i firkant rundt omkring, og femti alen til en fri plass for den rundt omkring. 
26:45:3: Således skal du efter dette mål måle fem og tyve tusen stenger i lengde og ti tusen i bredde; og der skal helligdommen, det høihellige, være. 
26:45:4: Det er en hellig del av landet, den skal tilhøre prestene, helligdommens tjenere, som nærmer sig for å tjene Herren, og det skal være til hustomter for dem og en hellig plass for helligdommen. 
26:45:5: Og fem og tyve tusen stenger i lengde og ti tusen i bredde skal tilhøre levittene, husets tjenere; de skal ha tyve gårder til eiendom. 
26:45:6: Og som stadens eiendom skal I avgi fem tusen stenger i bredde og fem og tyve tusen i lengde, ved siden av helligdommens lodd; det skal tilhøre hele Israels hus. 
26:45:7: Og fyrsten skal ha sin lodd på begge sider av helligdommens lodd og av stadens eiendom, langsmed helligdommens lodd og stadens eiendom, dels på vestsiden, mot vest, og dels på østsiden, mot øst, og i lengde svarende til én av stammenes lodder fra vestgrensen til østgrensen. 
26:45:8: Dette skal han ha som sitt land, som sin eiendom i Israel, og mine fyrster skal ikke mere undertrykke mitt folk, men overlate landet til Israels hus efter deres stammer. 
26:45:9: Så sier Herren, Israels Gud: Nu får det være nok, I Israels fyrster! Få bort vold og ødeleggelse og gjør rett og rettferdighet, hør op med å drive mitt folk fra gård og grunn, sier Herren, Israels Gud. 
26:45:10: Rette vektskåler og rett efa og rett bat skal I ha. 
26:45:11: En efa og en bat skal ha samme mål, så en bat er tiendedelen av en homer, og en efa tiendedelen av en homer; efter homeren skal deres mål rette sig. 
26:45:12: En sekel skal være tyve gera; en mine skal hos eder være tyve sekel, fem og tyve sekel, femten sekel. 
26:45:13: Dette er den offergave I skal gi: en sjettedel efa av en homer hvete, og likeså skal I gi en sjettedel efa av en homer bygg. 
26:45:14: Og den fastsatte avgift av olje, av en bat olje, er en tiendedel bat av en kor* - det går ti bat på en homer; for en homer er ti bat - # <* en kor er så stor som en homer.> 
26:45:15: og av småfeet ett lam av to hundre fra Israels vannrike beitemark til matoffer og til brennoffer og takkoffer, til å gjøre soning for dem, sier Herren, Israels Gud. 
26:45:16: Alt folket i landet skal være skyldig til å yde denne offergave til fyrsten i Israel. 
26:45:17: Og fyrsten skal det påligge å ofre brennofferne og matofferet og drikkofferet på festene og nymånedagene og sabbatene, på alle Israels høitider; han skal ofre syndofferet og matofferet og brennofferet og takkofferne for å gjøre soning for Israels hus. 
26:45:18: Så sier Herren, Israels Gud: I den første måned, på den første dag i måneden, skal du ta en ung okse uten lyte, og du skal rense helligdommen fra synd. 
26:45:19: Og presten skal ta noget av syndofferets blod og stryke på husets dørstolper og på de fire hjørner av alterets avsats og på dørstolpene i den indre forgårds port. 
26:45:20: Og likeså skal du gjøre på den syvende dag i måneden for deres skyld som har syndet av vanvare eller uvitenhet, og således skal I gjøre soning for huset. 
26:45:21: I den første måned, på den fjortende dag i måneden, skal I holde påske; på denne fest skal I ete usyret brød i syv dager. 
26:45:22: På den dag skal fyrsten ofre en okse til syndoffer for sig og for alt folket i landet. 
26:45:23: Og på festens syv dager skal han ofre Herren et brennoffer, syv okser uten lyte og syv værer uten lyte hver dag i de syv dager, og som syndoffer en gjetebukk hver dag. 
26:45:24: Og som matoffer skal han ofre en efa til hver okse og en efa til hver vær og en hin olje til hver efa. 
26:45:25: I den syvende måned, på den femtende dag i måneden, på festen, skal han ofre lignende offer i syv dager, både syndofferet og brennofferet, både matofferet og oljen. 
26:46:1: Så sier Herren, Israels Gud: Den indre forgårds port, den som vender mot øst, skal være lukket de seks arbeidsdager; men på sabbatens dag skal den åpnes, og likeså på nymånedagen. 
26:46:2: Og fyrsten skal da gå inn gjennem portens forhall utenfra og stå ved portens dørstolpe; prestene skal ofre hans brennoffer og hans takkoffer, og han skal tilbede på portens treskel og så gå ut igjen; men porten skal ikke lukkes før om aftenen. 
26:46:3: Og folket i landet skal på sabbatene og nymånedagene tilbede for Herrens åsyn ved inngangen til denne port. 
26:46:4: Og brennofferet som fyrsten skal ofre til Herren på sabbatens dag, skal være seks lam uten lyte og en vær uten lyte, 
26:46:5: og som matoffer en efa til væren, og som matoffer til lammene så meget som han finner for godt å gi, og en hin olje til hver efa. 
26:46:6: Og på nymånedagen en ung okse uten lyte og seks lam og en vær; uten lyte skal de være. 
26:46:7: Og som matoffer skal han ofre en efa til oksen og en efa til væren, og til lammene så meget som han har råd til, og en hin olje til hver efa. 
26:46:8: Og når fyrsten går inn, skal han gå inn gjennem portens forhall, og samme vei skal han gå ut igjen. 
26:46:9: Men når folket i landet går inn for Herrens åsyn på høitidene, skal den som går inn igjennem nordporten for å tilbede, gå ut igjennem sydporten, og den som går inn igjennem sydporten, gå ut igjennem porten mot nord; han skal ikke vende tilbake gjennem den port som han gikk inn gjennem, men de skal gå ut rett frem for sig. 
26:46:10: Og fyrsten skal gå inn blandt de andre, når de går inn; og når de går ut, skal de gå ut sammen. 
26:46:11: Og på festene og høitidene skal matofferet være en efa til hver okse og en efa til hver vær, og til lammene så meget som han finner for godt å gi, og en hin olje til hver efa. 
26:46:12: Når fyrsten vil ofre et frivillig offer - et brennoffer eller takkoffere som frivillig offer til Herren, da skal de åpne for ham den port som vender mot øst, og han skal ofre sitt brennoffer og sine takkoffer således som han gjør det på sabbatens dag; og så skal han gå ut igjen, og når han er gått ut, skal porten lukkes. 
26:46:13: Som brennoffer til Herren skal du hver dag ofre et årsgammelt lam uten lyte; hver morgen skal du ofre det. 
26:46:14: Og som matoffer dertil skal du hver morgen ofre en sjettedel efa og en tredjedel hin olje til å væte melet med; det er et matoffer for Herren - forskrifter som skal gjelde evig, for all tid. 
26:46:15: Således skal de hver morgen ofre lammet og matofferet og oljen; det skal være et stadig brennoffer. 
26:46:16: Så sier Herren, Israels Gud: Når fyrsten gir nogen av sine sønner en gave, så er det hans arv; det skal tilhøre hans sønner, det er deres arv og eie. 
26:46:17: Men når han gir en gave av sin arv til en av sine tjenere, så skal det tilhøre ham inntil frihetsåret, men så komme tilbake til fyrsten; bare hans sønner skal hans arv tilhøre. 
26:46:18: Fyrsten må ikke ta noget av folkets arv, så han driver dem bort fra deres eiendom; av sin egen eiendom skal han la sine sønner arve, så mitt folk ikke skal bli spredt ved å miste sine eiendommer. 
26:46:19: Så førte han mig gjennem den inngang som var ved siden av porten, til de hellige kammer som var bestemt for prestene, og som vendte mot nord, og se, der var en plass ytterst mot vest. 
26:46:20: Og han sa til mig: Dette er den plass hvor prestene skal koke skyldofferet og syndofferet, og hvor de skal bake matofferet, så de ikke har nødig å bære det ut i den ytre forgård og således hellige folket. 
26:46:21: Så lot han mig gå ut i den ytre forgård, og førte mig omkring til forgårdens fire hjørner, og se, der var en mindre gård i hvert hjørne av forgården. 
26:46:22: I forgårdens fire hjørner var det lukkede gårder, firti alen lange og tretti alen brede; de fire gårder i hjørnene hadde samme mål. 
26:46:23: Og det gikk en muret ring rundt om i dem, rundt om i alle fire, og det var gjort kokesteder under de murede ringer rundt om. 
26:46:24: Og han sa til mig: Dette er de kokehus hvor husets tjenere* skal koke folkets slaktoffer. # <* ESK 44, 11.> 
26:47:1: Så førte han mig tilbake til husets inngang, og se, det kom vann ut under husets dørtreskel mot øst, for husets forside vendte mot øst; og vannet rant ned fra husets høire side, sønnenfor alteret. 
26:47:2: Så lot han mig gå ut gjennem nordporten og førte mig omkring utenfor, til den ytre port, til den port som vender mot øst, og se, det vellet vann frem fra den høire side. 
26:47:3: Mannen gikk nu mot øst med en målesnor i hånden og målte tusen alen; så lot han mig vade gjennem vannet, og vannet nådde mig til anklene. 
26:47:4: Atter målte han tusen alen og lot mig vade gjennem vannet; da nådde vannet mig til knærne. Atter målte han tusen alen og lot mig vade gjennem vann som nådde til lendene. 
26:47:5: Så målte han atter tusen alen; da var det en bekk som jeg ikke kunde vade igjennem; for vannet var så høit at en måtte svømme der - det var en bekk som ikke lot sig vade. 
26:47:6: Og han sa til mig: Har du sett det, menneskesønn? Og han førte mig tilbake igjen langs bekkens bredd. 
26:47:7: Da jeg vendte tilbake, se, da stod det på bekkens bredd en stor mengde trær på begge sider. 
26:47:8: Og han sa til mig: Dette vann rinner til østbygdene og videre ned til ødemarken og faller så i havet*; og når det ledes ut i havet, blir vannet der sundt. # <* det Døde Hav.> 
26:47:9: Og alle levende skapninger, som det vrimler av overalt hvor dobbeltbekken* kommer, skal leve, og fiskene skal bli meget tallrike; for når dette vann kommer dit, blir der sundhet og liv overalt hvor bekken kommer. # <* d.e. den store bekk; ESK 47, 5.> 
26:47:10: Og det skal stå fiskere ved havet* fra En-Gedi til En-Egla'im; det skal være et sted til å kaste fiskegarn ut; det skal finnes fisk av forskjellig slag i stor mengde, som i det store hav. # <* det Døde Hav.> 
26:47:11: Men myrene og pyttene der skal ikke bli sunde; de skal bare være til å utvinne salt av. 
26:47:12: Ved bekken skal det på begge dens bredder vokse op allehånde frukttrær; deres blad skal ikke visne, og deres frukt skal ikke høre op; hver måned skal de bære ny frukt; for vannet til dem går ut fra helligdommen; og deres frukt skal være til mat, og deres blad til lægedom. 
26:47:13: Så sier Herren, Israels Gud: Dette er de grenser hvorefter I skal skifte ut landet til arv for Israels tolv stammer; Josef skal ha to lodder. 
26:47:14: I skal ta det til arv, den ene som den andre, fordi jeg har løftet min hånd og svoret å ville gi eders fedre det, og således skal dette land tilfalle eder som arv. 
26:47:15: Dette skal være landets grenser: Mot nord fra det store hav på veien til Hetlon i retning mot Sedad, 
26:47:16: Hamat, Berota, Sibra'im, som ligger mellem Damaskus' landemerke og Hamats landemerke, det mellemste Haser, som ligger bortimot Havrans landemerke. 
26:47:17: Således skal grensen gå fra havet til Hasar-Enon ved Damaskus' landemerke og videre enda lenger mot nord op til Hamats landemerke. Dette er nordsiden. 
26:47:18: På østsiden: Jordan, mellem Havran og Damaskus og mellem Gilead og Israels land; fra nordgrensen til det østlige hav skal I måle. Dette er østsiden. 
26:47:19: På sydsiden - mot syd - skal grensen gå fra Tamar til Meribots vann ved Kades; arven skal nå til det store hav. Dette er sydsiden - grensen mot syd. 
26:47:20: Og på vestsiden skal det store hav være grensen og gå fra sydgrensen til midt imot Hamat. Dette er vestsiden. 
26:47:21: Dette land skal I dele mellem eder efter Israels stammer. 
26:47:22: I skal lodde det ut til arv for eder og de fremmede som bor iblandt eder, og som har fått barn iblandt eder; de skal for eder være som de innfødte blandt Israels barn; de skal få arv sammen med eder blandt Israels stammer. 
26:47:23: I den stamme hvor den fremmede bor, der skal I gi ham hans arv, sier Herren, Israels Gud. 
26:48:1: Dette er stammenes navn: Fra nordenden langs Hetlon-veien til bortimot Hamat og Hasar-Enan ved Damaskus' landemerke, mot nord, ved siden av Hamat, der skal Dan ha landet, fra østsiden til havet - Dan én lodd. 
26:48:2: Og langsmed Dans landemerke, fra østsiden til vestsiden, Aser én lodd. 
26:48:3: Og langsmed Asers landemerke, fra østsiden og til vestsiden, Naftali én lodd. 
26:48:4: Og langsmed Naftalis landemerke, fra østsiden til vestsiden, Manasse én lodd. 
26:48:5: Og langsmed Manasses landemerke, fra østsiden til vestsiden, Efra'im én lodd. 
26:48:6: Og langsmed Efra'ims landemerke, fra østsiden og til vestsiden, Ruben én lodd. 
26:48:7: Og langsmed Rubens landemerke, fra østsiden til vestsiden, Juda én lodd. 
26:48:8: Og langsmed Judas landemerke, fra østsiden til vestsiden, skal det område ligge som I skal avgi som gave til Herren; det skal være fem og tyve tusen stenger i bredde, og i lengde som én av stammeloddene, fra østsiden til vestsiden, og midt i der skal helligdommen være. 
26:48:9: Det område som I skal avgi til Herren som gave, skal være fem og tyve tusen stenger langt og ti tusen bredt. 
26:48:10: Og av denne hellige gave skal et stykke tilhøre prestene, i nord fem og tyve tusen stenger, i vest ti tusen i bredde, i øst ti tusen i bredde, og i syd fem og tyve tusen i lengde, og midt i der skal Herrens helligdom være. 
26:48:11: Prestene, dem som er helliget blandt Sadoks sønner, som tok vare på det jeg vilde ha varetatt, som ikke forvillet sig dengang Israels barn fór vill, således som levittene forvillet sig, 
26:48:12: dem skal det tilhøre som en del av det landområde som skal avgis til Herren, som et høihellig stykke langsmed levittenes landemerke. 
26:48:13: Og levittene skal ved siden av prestenes landemerke ha fem og tyve tusen stenger i lengde og ti tusen i bredde; lengden skal i alt være fem og tyve tusen stenger, og bredden ti tusen. 
26:48:14: De skal ikke selge eller bytte bort noget av det, og denne førstegrøde av landet skal ikke gå over til andre; for den er helliget til Herren. 
26:48:15: De fem tusen stenger som er tilovers av bredden, langsmed de fem og tyve tusen, skal ikke være hellig land; det skal være til bruk for staden, til boliger og til jorder, og midt i der skal staden være. 
26:48:16: Og dette er dens mål: Nordsiden skal være fire tusen og fem hundre stenger, og sydsiden fire tusen og fem hundre, og østsiden fire tusen og fem hundre, og vestsiden fire tusen og fem hundre. 
26:48:17: Og staden skal ha jorder, i nord to hundre og femti stenger og i syd to hundre og femti og i øst to hundre og femti og i vest to hundre og femti. 
26:48:18: Og det som er tilovers av lengden, langsmed den hellige gave, ti tusen stenger mot øst og ti tusen mot vest, det skal være langsmed den hellige gave, og av grøden av det skal være til føde for stadens arbeidere. 
26:48:19: Og stadens arbeidere, av alle Israels stammer, skal dyrke det*. # <* jordstykket; ESK 48, 18.> 
26:48:20: Hele gaven skal være fem og tyve tusen stenger i lengde og fem og tyve tusen i bredde; en fjerdedel i forhold til den hellige gave* skal I avgi til stadens eiendom. # <* ESK 48, 9-14.> 
26:48:21: Og fyrsten skal ha det som er tilovers på begge sider av den hellige gave og av stadens eiendom, langsmed den fem og tyve tusen stenger brede lodd til den østre grense og mot vest langsmed de fem og tyve tusen stenger til den vestre grense, ved siden av stammeloddene*; det skal tilhøre fyrsten, og den hellige gave og husets helligdom skal være midt imellem. # <* d.e. Judas og Benjamins.> 
26:48:22: Og det som ligger mellem Judas landemerke og Benjamins landemerke, med levittenes eiendom og stadens eiendom i midten, det skal tilhøre fyrsten. 
26:48:23: Så kommer de andre stammer: Fra østsiden til vestsiden skal Benjamin ha én lodd. 
26:48:24: Og langsmed Benjamins landemerke, fra østsiden til vestsiden, Simeon én lodd. 
26:48:25: Og langsmed Simeons landemerke, fra østsiden til vestsiden, Issakar én lodd. 
26:48:26: Og langsmed Issakars landemerke, fra østsiden til vestsiden, Sebulon én lodd. 
26:48:27: Og langsmed Sebulons landemerke, fra østsiden til vestsiden, Gad én lodd. 
26:48:28: Og langsmed Gads landemerke, på sydsiden, skal grensen mot syd gå fra Tamar til Meribas vann ved Kades; arven skal nå til det store hav. 
26:48:29: Dette er det land som I skal lodde ut til Israels stammer av arven, og dette er deres lodder, sier Herren, Israels Gud. 
26:48:30: Og dette er stadens yttergrenser: På nordsiden skal den måle fire tusen og fem hundre stenger; 
26:48:31: og stadens porter skal opkalles efter Israels stammer; tre av dem skal ligge mot nord: Rubens port én, Judas port én, Levis port én. 
26:48:32: Og på østsiden skal den måle fire tusen og fem hundre stenger og ha tre porter: Josefs port én, Benjamins port én, Dans port én. 
26:48:33: Og sydsiden skal måle fire tusen og fem hundre stenger og ha tre porter: Simeons port én, Issakars port én, Sebulons port én. 
26:48:34: Vestsiden skal måle fire tusen og fem hundre stenger og ha tre porter: Gads port én, Asers port én, Naftalis port én. 
26:48:35: Rundt omkring skal staden måle atten tusen stenger. Og stadens navn skal fra den dag være: Herren er der. 
27:1:1: I Judas konge Jojakims tredje regjeringsår drog Nebukadnesar, kongen i Babel, til Jerusalem og kringsatte det. 
27:1:2: Og Herren gav Judas konge Jojakim i hans hånd, og likeledes en del av karene i Guds hus, og han førte dem til Sinears land, til sin guds hus - han lot karene sette inn i sin guds skattkammer. 
27:1:3: Og kongen sa til Aspenas, sin øverste hoffmann, at han skulde ta med sig nogen av Israels barn, både av kongeætten og av de fornemste, 
27:1:4: unge gutter som var uten lyte og fagre å se til, og med evne til å tilegne sig all slags visdom og vinne kunnskap og bli kyndige i videnskap, og som var dyktige til å tjene i kongens palass, og at de skulde oplæres i kaldeernes skrift og tungemål. 
27:1:5: Og kongen fastsatte hvad de hver dag skulde ha av kongens kostelige mat og av den vin han drakk, og bød at de skulde opdras i tre år, og når disse år var omme, skulde de bli kongens tjenere. 
27:1:6: Blandt dem var av Judas barn Daniel, Hananja, Misael og Asarja. 
27:1:7: Og den øverste hoffmann gav dem nye navn: Daniel kalte han Beltsasar og Hananja Sadrak og Misael Mesak og Asarja Abed-Nego. 
27:1:8: Men Daniel satte sig fore at han ikke vilde gjøre sig uren med kongens kostelige mat og med den vin han drakk, og han bad den øverste hoffmann om at han måtte være fri for således å gjøre sig uren. 
27:1:9: Og Gud lot Daniel finne velvilje og barmhjertighet hos den øverste hoffmann. 
27:1:10: Og den øverste hoffmann sa til Daniel: Jeg er redd for at min herre kongen, som har fastsatt hvad I skal ete og drikke, vil synes at eders ansikter ser dårligere ut enn de gutters som er på eders alder, og at I således skal føre skyld over mitt hode hos kongen. 
27:1:11: Da sa Daniel til kjellermesteren, som den øverste hoffmann hadde satt over Daniel, Hananja, Misael og Asarja: 
27:1:12: Kjære, prøv det med dine tjenere i ti dager og la dem gi oss grønnsaker å ete og vann å drikke, 
27:1:13: så kan du siden ta vårt utseende i øiesyn, og likeså de gutters utseende som eter kongens kostelige mat, og gjør så med dine tjenere efter det du da ser! 
27:1:14: Og han gjorde som de ønsket, og prøvde det med dem i ti dager. 
27:1:15: Og da de ti dager var omme, viste det sig at de var fagrere å se til og i bedre hold enn alle de gutter som hadde ett av kongens kostelige mat. 
27:1:16: Da lot kjellermesteren deres kostelige mat bære bort, og likeså den vin de skulde drikke, og gav dem grønnsaker. 
27:1:17: Og Gud gav disse fire gutter kunnskap og forstand på all slags skrift og visdom, og Daniel skjønte sig på alle slags syner og drømmer. 
27:1:18: Da den tid kongen hadde fastsatt, var til ende, og de skulde fremstilles for ham, førte den øverste hoffmann dem inn for Nebukadnesar. 
27:1:19: Og kongen talte med dem, og det fantes ikke blandt alle de unge gutter nogen som kunde måle sig med Daniel, Hananja, Misael og Asarja; så blev de da kongens tjenere. 
27:1:20: Og i enhver sak som krevde visdom og forstand, og som kongen spurte dem om, fant han at de ti ganger overgikk alle de tegnsutleggere og åndemanere som fantes i hele hans rike. 
27:1:21: Og Daniel blev der like til kong Kyros' første år. 
27:2:1: I sitt annet regjeringsår hadde Nebukadnesar drømmer som gjorde hans sinn urolig, og det var forbi med hans søvn. 
27:2:2: Og kongen lot kalle tegnsutleggerne og åndemanerne og trollmennene og kaldeerne, forat de skulde fortelle kongen hans drømmer; og de kom og trådte frem for kongen. 
27:2:3: Og kongen sa til dem: Jeg har hatt en drøm, og mitt sinn er blitt urolig; jeg stunder efter å få drømmen å vite. 
27:2:4: Da talte kaldeerne til kongen på araméisk: Kongen leve evindelig! Si dine tjenere drømmen! Så skal vi kunngjøre dig dens uttydning. 
27:2:5: Kongen svarte kaldeerne: Dette mitt ord står fast: Hvis I ikke kunngjør mig drømmen og dens uttydning, så skal I bli hugget i stykker, og eders huser gjort til en møkkdynge. 
27:2:6: Men dersom I kunngjør mig drømmen og dens uttydning, så skal I få rike gaver og stor ære av mig. Derfor, si mig drømmen og dens uttydning! 
27:2:7: Da svarte de annen gang: Vil ikke kongen si sine tjenere drømmen! Så skal vi si hvad den betyr. 
27:2:8: Kongen svarte: Jeg vet for visst at I bare vil vinne tid, fordi I ser at det ord jeg sa, står fast. 
27:2:9: Såfremt I ikke sier mig drømmen, da er dommen over eder uforanderlig. Men I er blitt enige om å fortelle mig en skammelig løgn, i håp om at tidene skal forandre sig, derfor, si mig drømmen, så jeg kan vite at I også kan kunngjøre mig dens uttydning! 
27:2:10: Kaldeerne svarte kongen: Det er ikke et menneske på jorden som kan kunngjøre kongen det han vil vite; for ingen konge, hvor stor og mektig han var, har nogensinne krevd noget sådant av nogen tegnsutlegger eller åndemaner eller kaldeer. 
27:2:11: For det som kongen krever, er altfor vanskelig, og det er ingen annen som kan kunngjøre kongen hvad han vil vite, enn gudene, som ikke har sin bolig blandt menneskene. 
27:2:12: Over dette blev kongen harm og meget vred, og han bød at alle Babels vismenn skulde drepes. 
27:2:13: Da denne befaling var utstedt, og de skulde til å drepe vismennene, lette de også efter Daniel og hans medbrødre for å drepe dem. 
27:2:14: Da sa Daniel et klokt og forstandig ord til Arjok, høvdingen for kongens livvakt, som hadde draget ut for å drepe Babels vismenn. 
27:2:15: Han tok til orde og spurte Arjok, kongens høvedsmann: Hvorfor har kongen utstedt denne strenge befaling? Da fortalte Arjok Daniel hvorledes det hadde sig. 
27:2:16: Så gikk Daniel inn og bad kongen at han vilde gi ham en frist, så han kunde kunngjøre kongen uttydningen. 
27:2:17: Derefter gikk Daniel hjem og fortalte Hananja, Misael og Asarja, sine medbrødre, det som hadde hendt, 
27:2:18: forat de skulde bede himmelens Gud at han i sin barmhjertighet vilde åpenbare denne hemmelighet, så at ikke Daniel og hans medbrødre skulde bli drept sammen med Babels andre vismenn. 
27:2:19: Da blev hemmeligheten åpenbaret Daniel i et syn om natten; og Daniel priste himmelens Gud. 
27:2:20: Daniel tok til orde og sa: Lovet være Guds navn fra evighet og til evighet! For visdommen og styrken hører ham til, 
27:2:21: og han omskifter tider og stunder, avsetter konger og innsetter konger; han gir de vise visdom og de forstandige forstand; 
27:2:22: han åpenbarer det dype og skjulte; han vet hvad som er i mørket, og hos ham bor schmelken. 
27:2:23: Dig, mine fedres Gud, takker og priser jeg, fordi du har gitt mig visdom og styrke og nu har kunngjort mig hvad vi bad dig om; for det som kongen vilde vite har du kunngjort oss. 
27:2:24: Så gikk da Daniel inn til Arjok, som kongen hadde gitt pålegg om å drepe Babels vismenn; han kom og sa til ham: Drep ikke Babels vismenn! Før mig inn til kongen! Så vil jeg kunngjøre kongen uttydningen. 
27:2:25: Da førte Arjok Daniel i hast inn til kongen og sa til ham: Jeg har funnet en av de bortførte fra Juda, han vil kunngjøre kongen uttydningen. 
27:2:26: Kongen svarte Daniel, som hadde fått navnet Beltsasar: Er du i stand til å kunngjøre mig den drøm jeg har hatt, og dens uttydning? 
27:2:27: Daniel svarte kongen: Den hemmelighet kongen ønsker å få vite, makter ingen vismenn, åndemanere, tegnsutleggere eller sannsigere å kunngjøre kongen; 
27:2:28: men det er en Gud i himmelen, som åpenbarer hemmeligheter, og han har kunngjort kong Nebukadnesar hvad som skal skje i de siste dager. Dette var den drøm og de syner du hadde i ditt indre, mens du hvilte på ditt leie: 
27:2:29: Da du, konge, lå på ditt leie, steg det op hos dig tanker om hvad som skulde skje i fremtiden; og han som åpenbarer hemmeligheter, kunngjorde dig hvad som skal skje. 
27:2:30: Og for mig er denne hemmelighet blitt åpenbaret, ikke fordi jeg eier nogen visdom fremfor alle andre som lever, men forat uttydningen skulde kunngjøres kongen, og du skulde få ditt hjertes tanker å vite. 
27:2:31: Konge, du så i ditt syn et stort billede; det var et veldig billede, og dets glans var overmåte stor; det stod like foran dig, og det var forferdelig å skue. 
27:2:32: Billedets hode var av fint gull, brystet og armene av sølv, buken og lendene av kobber, 
27:2:33: benene av jern og føttene dels av jern og dels av ler. 
27:2:34: Mens du så på billedet, blev en sten revet løs, men ikke med hender, og den rammet billedet på føttene, som var av jern og ler, og knuste dem. 
27:2:35: Da knustes på én gang både jernet, leret, kobberet, sølvet og gullet, og det blev som agner fra treskeplassene om sommeren, og vinden tok det så det ikke fantes spor efter det. Men stenen som rammet billedet, blev til et stort fjell, som fylte hele jorden. 
27:2:36: Det var drømmen; og nu skal vi si kongen uttydningen. 
27:2:37: Konge, du kongenes konge, som himmelens Gud har gitt riket og makten og styrken og æren - 
27:2:38: overalt hvor menneskenes barn, markens dyr og himmelens fugler har sine bosteder, har han gitt dem i din hånd og gjort dig til herre over dem alle - du er hodet av gull. 
27:2:39: Men efter dig skal det opkomme et annet rike, ringere enn ditt, og så ennu et tredje rike, som er av kobber, og som skal herske over all jorden, 
27:2:40: og derefter et fjerde rike, som skal være sterkt som jernet; eftersom jernet knuser og sønderslår alt, skal det som det sønderknusende jern sønderslå og sønderknuse alle hine riker. 
27:2:41: Og at føttene og tærne som du så, dels var av pottemakerler og dels av jern, det betyr at det skal være et delt rike, og at det skal være noget av jernets styrke i det, eftersom du så jernet blandet med leret. 
27:2:42: Og at tærne på føttene dels var av jern og dels av ler, det betyr at riket for en del skal være sterkt og for en del skrøpelig, 
27:2:43: og at jernet som du så, var blandet med ler, det betyr at de* skal blande sig med hverandre ved giftermål, men at de allikevel ikke i lengden skal holde sammen, likesom jernet ikke blander sig med leret. # <* d.e. rikets konger.> 
27:2:44: Og i disse kongers dager vil himmelens Gud oprette et rike, som i all evighet ikke skal ødelegges, og dette rike skal ikke overlates til noget annet folk; det skal knuse og gjøre ende på alle hine riker, men selv skal det stå fast evindelig; 
27:2:45: for du så jo at en sten blev revet løs fra fjellet, men ikke med hender, og knuste jernet, kobberet, leret, sølvet og gullet. Den store Gud har kunngjort kongen hvad som skal skje i fremtiden, og fast står drømmen, og troverdig er dens uttydning. 
27:2:46: Da falt kong Nebukadnesar på sitt ansikt og tilbad Daniel, og han bød at de skulde ofre ham matoffer og røkelse. 
27:2:47: Og kongen tok til orde og sa til Daniel: I sannhet, eders Gud er gudenes Gud og kongenes herre og en som åpenbarer hemmeligheter, siden du har kunnet åpenbare denne hemmelighet. 
27:2:48: Derefter gjorde kongen Daniel til en stor mann, og han gav ham mange store gaver og satte ham til herre over hele landskapet Babel og til øverste forstander for alle Babels vismenn. 
27:2:49: Og på Daniels bønn satte kongen Sadrak, Mesak og Abed-Nego til å styre landskapet Babel; men Daniel selv blev ved kongens hoff. 
27:3:1: Kong Nebukadnesar gjorde et billede av gull, seksti alen høit og seks alen bredt, og stilte det op i Duradalen i landskapet Babel. 
27:3:2: Og kong Nebukadnesar sendte ut folk som skulde stevne sammen satrapene, stattholderne og landshøvdingene, overdommerne, skattmesterne, de lovkyndige, dommerne og alle landskapenes embedsmenn og byde dem å komme til innvielsen av det billede kong Nebukadnesar hadde stilt op. 
27:3:3: Da kom satrapene, stattholderne og landshøvdingene, overdommerne, skattmesterne, de lovkyndige, dommerne og alle landskapenes embedsmenn sammen til innvielsen av det billede kong Nebukadnesar hadde stilt op, og de trådte frem foran det billede Nebukadnesar hadde stilt op. 
27:3:4: Og herolden ropte med høi røst: Det være eder sagt, I folk, ætter og tungemål: 
27:3:5: Så snart I hører lyden av horn, fløite, citar, harpe, lutt og sekkepipe og alle andre slags strengelek, skal I falle ned og tilbede det gullbillede kong Nebukadnesar har stilt op. 
27:3:6: Og den som ikke faller ned og tilbeder, skal i samme stund kastes i den brennende ildovn. 
27:3:7: Så snart nu alle folkene hørte lyden av horn, fløite, citar, harpe, lutt og alle andre slags strengelek, falt alle folk, ætter og tungemål ned og tilbad det gullbillede kong Nebukadnesar hadde stilt op. 
27:3:8: Men straks efter stod nogen kaldeiske menn frem og klaget på jødene. 
27:3:9: De tok til orde og sa til kong Nebukadnesar: Kongen leve evindelig! 
27:3:10: Konge, du har gitt befaling om at hvert menneske som hørte lyden av horn, fløite, citar, harpe, lutt, sekkepipe og alle andre slags strengelek, skulde falle ned og tilbede gullbilledet, 
27:3:11: og den som ikke falt ned og tilbad, skulde kastes i den brennende ildovn. 
27:3:12: Men nu er her nogen jødiske menn som du har satt til å styre landskapet Babel, Sadrak, Mesak og Abed-Nego; disse menn har ikke aktet på ditt bud, konge! De dyrker ikke dine guder, og det gullbillede du har stilt op, tilbeder de ikke. 
27:3:13: Da blev Nebukadnesar vred og harm og bød at Sadrak, Mesak og Abed-Nego skulde føres frem; så blev disse menn ført frem for kongen. 
27:3:14: Nebukadnesar tok til orde og sa til dem: Er det med forsett, Sadrak, Mesak og Abed-Nego, at I ikke dyrker min gud og ikke tilbeder det gullbillede jeg har stilt op? 
27:3:15: Nuvel, hvis I, når I hører lyden av horn, fløite, citar, harpe, lutt, sekkepipe og alle andre slags strengelek, er rede til å falle ned og tilbede det billede jeg har gjort, så er det godt og vel; men hvis I ikke tilbeder det, så skal I i samme stund kastes i den brennende ildovn, og hvem er den gud som kan frelse eder av min hånd? 
27:3:16: Sadrak, Mesak og Abed-Nego svarte kongen: Nebukadnesar! Vi har ikke nødig å svare dig et ord på dette. 
27:3:17: Vår Gud, som vi dyrker, er mektig til å frelse oss; av den brennende ildovn og av din hånd, konge, vil han frelse. 
27:3:18: Men hvis ikke, da skal du vite, konge, at vi ikke vil dyrke dine guder eller tilbede det gullbillede du har stilt op. 
27:3:19: Da blev Nebukadnesar full av harme, og uttrykket i hans ansikt forandret sig, idet han så på Sadrak, Mesak og Abed-Nego. Han tok til orde og sa at ovnen skulde gjøres syv ganger hetere enn de hadde funnet for godt å ophete den. 
27:3:20: Og han bød nogen sterke menn i hans hær å binde Sadrak, Mesak og Abed-Nego og kaste dem i den brennende ildovn. 
27:3:21: Da blev disse menn med sine skjorter, kjortler og kapper og sine andre klær bundet og kastet i den brennende ildovn. 
27:3:22: Såsom nu kongens ord var så strengt, og ovnen var blitt så overmåte sterkt ophetet, drepte ildsluen de menn som hadde ført Sadrak, Mesak og Abed-Nego dit op. 
27:3:23: Men disse tre menn, Sadrak, Mesak og Abed-Nego, blev kastet bundet ned i den brennende ildovn. 
27:3:24: Da forferdedes kong Nebukadnesar og reiste sig hastig op. Han tok til orde og sa til sine rådsherrer: Kastet vi ikke tre menn bundet i ilden? De svarte kongen: Jo visselig, konge! 
27:3:25: Han tok atter til orde og sa: Men jeg ser fire menn som går løse omkring inne i ilden, og det er ingen skade å se på dem, og den fjerde ser ut som en gudesønn. 
27:3:26: Da gikk Nebukadnesar bort til døren på den brennende ildovn. Han tok til orde og sa: Sadrak, Mesak og Abed-Nego, I den høieste guds tjenere! Kom hit ut! Da gikk Sadrak, Mesak og Abed-Nego ut av ilden. 
27:3:27: Og satrapene, stattholderne, landshøvdingene og kongens rådsherrer kom sammen; de så at ilden ikke hadde hatt nogen makt over disse menns legemer, og at håret på deres hoder ikke var svidd, og at deres skjorter ikke hadde lidt nogen skade; det kunde ikke engang kjennes lukt av noget brent på dem. 
27:3:28: Da tok Nebukadnesar til orde og sa: Lovet være Sadraks, Mesaks og Abed-Negos gud, som sendte sin engel og frelste sine tjenere, som satte sin lit til ham og gjorde mot kongens ord og vågde sitt liv for ikke å dyrke eller tilbede nogen gud uten sin gud. 
27:3:29: Så gir jeg da nu det bud at den som i sine ord forser sig mot Sadraks, Mesaks og Abed-Negos gud, hvad folk eller ætt eller tungemål han så hører til, han skal hugges i stykker, og hans hus gjøres til en møkkdynge; for det er ingen annen gud som makter å frelse således. 
27:3:30: Så ophøiet kongen Sadrak, Mesak og Abed-Nego til ære og verdighet i landskapet Babel. 
27:4:1: Kong Nebukadnesar til alle folk, ætter og tungemål som bor på den hele jord: Alt godt bli eder i rikt mål til del 
27:4:2: De tegn og under som den høieste Gud har gjort mot mig, har jeg funnet for godt å kunngjøre. 
27:4:3: Hvor store er ikke hans tegn, hvor mektige hans under! Hans rike er et evig rike, og hans herredømme varer fra slekt til slekt. 
27:4:4: Jeg, Nebukadnesar, satt i god ro i mitt hus, levde lykkelig i mitt palass. 
27:4:5: Da hadde jeg en drøm; den forferdet mig, og tankene på mitt leie og synene i mitt indre skremte mig. 
27:4:6: Og det blev av mig utstedt det bud at alle Babels vismenn skulde føres inn for mig, forat de skulde kunngjøre mig drømmens uttydning. 
27:4:7: Da kom tegnsutleggerne, åndemanerne, kaldeerne og sannsigerne inn, og jeg fortalte dem drømmen; men de kunde ikke si mig hvorledes den skulde tydes. 
27:4:8: Til sist kom også Daniel inn for mig, han som hadde fått navnet Beltsasar efter navnet på min gud, og i hvem de hellige guders ånd er, og jeg fortalte ham drømmen: 
27:4:9: Beltsasar, du tegnsutleggernes mester! Efterdi jeg vet at de hellige guders ånd er i dig, og at ingen hemmelighet er dig for vanskelig, så si mig nu de syner jeg har sett i min drøm - si mig hvorledes de skal tydes! 
27:4:10: Dette var de syner jeg hadde i mitt indre mens jeg hvilte på mitt leie: Jeg så i mitt syn et tre som stod midt på jorden, og som var meget høit; 
27:4:11: det var et stort og sterkt tre, og så høit at det nådde til himmelen, og det kunde sees helt til jordens ende; 
27:4:12: dets løv var fagert, og det bar rikelig frukt, så det gav føde for alle; markens dyr fant skygge under det, og himmelens fugler bodde på dets grener, og alt levende nærte sig av det. 
27:4:13: Og i de syner jeg hadde i mitt indre mens jeg hvilte på mitt leie, så jeg en hellig vekter som steg ned fra himmelen. 
27:4:14: Han ropte med høi røst, og således lød hans ord: Fell treet, hugg dets grener av, riv løvet av det og spred fruktene omkring! Dyrene under det skal rømme, og fuglene flyve bort fra dets grener. 
27:4:15: Men la dets rotstubb stå igjen i jorden, men i lenker av jern og kobber, midt i gresset på marken! Og med himmelens dugg skal han* vætes, og med dyrene skal han ha del i jordens urter. # <* den mann som treet er billede på; V. 33.> 
27:4:16: Hans hjerte skal forandres, så det ikke lenger er et menneskes hjerte; et dyrs hjerte skal han få, og syv tider* skal skride frem over ham. # <* d.e. år.> 
27:4:17: Dette budskap har sin grunn i vekternes rådslutning, og sådan er de helliges avgjørelser i denne sak, forat alle som lever, skal sanne at den Høieste har makt over kongedømmet blandt menneskene og gir det til den han vil, og setter den ringeste av menneskene til å råde over det. 
27:4:18: Det var den drøm som jeg kong Nebukadnesar, så; kunngjør nu du Beltsasar mig dens uttydning. For ingen av mitt rikes vismenn kan si mig hvad den betyr; men du kan det, fordi de hellige guders ånd er i dig. 
27:4:19: Da stod Daniel, han som hadde fått navnet Beltsasar, en stund slått av redsel, og hans tanker forferdet ham. Så tok kongen til orde og sa: Beltsasar! La ikke drømmen og dens uttydning forferde dig! Beltsasar svarte: Min herre! Gid denne drøm måtte gjelde dem som hater dig, og dens uttydning dine fiender! 
27:4:20: Det tre du så, som var så stort og sterkt, og så høit at det nådde til himmelen, og som kunde sees over hele jorden, 
27:4:21: og som hadde så fagert løv og bar så rikelig frukt at det gav føde for alle - som markens dyr hadde tilhold under, og på hvis grener himmelens fugler bodde, 
27:4:22: det er du selv, konge, du som er så stor og mektig, og hvis velde har vokset så det når til himmelen, og hvis herredømme strekker sig like til jordens ende. 
27:4:23: Men at kongen så en hellig vekter som steg ned fra himmelen og sa: Fell treet og ødelegg det, men la dets rotstubb stå igjen i jorden, men i lenker av jern og kobber, midt i gresset på marken, og med himmelens dugg skal han vætes, og med markens dyr skal han dele lodd så lenge til syv tider har skredet frem over ham, 
27:4:24: det betyr, konge, og så er den Høiestes rådslutning, som kommer over min herre kongen: 
27:4:25: Du skal bli utstøtt fra menneskene, og din bolig skal være hos markens dyr, og urter skal du ete likesom oksene, og med himmelens dugg skal du vætes, og syv tider skal skride frem over dig, inntil du sanner at den Høieste har makt over kongedømmet blandt menneskene og gir det til den han vil. 
27:4:26: Men at det blev sagt at treets rotstubb skulde stå igjen, det betyr at ditt rike skal være ditt fra den tid du sanner at himmelen har makten. 
27:4:27: Derfor, konge, la mitt råd tekkes dig, og løs dig fra dine synder ved rettferdighet og fra dine misgjerninger ved barmhjertighet mot ulykkelige, om din lykke skal bli varig. 
27:4:28: Alt dette kom over kong Nebukadnesar. 
27:4:29: Da tolv måneder var til ende, gikk han engang omkring på taket av det kongelige palass i Babel. 
27:4:30: Da tok kongen til orde og sa: Er ikke dette det store Babel, som jeg har bygget til kongesete ved min veldige makt og til ære for min herlighet? 
27:4:31: Før kongen ennu hadde talt ut, kom det brått en røst fra himmelen: Til dig, kong Nebukadnesar, sies nu dette ord: Riket er tatt fra dig, 
27:4:32: fra menneskene blir du utstøtt, og hos markens dyr skal din bolig være, urter skal du ete likesom oksene, og syv tider skal skride frem over dig, inntil du sanner at den Høieste har makt over kongedømmet blandt menneskene og gir det til den han vil. 
27:4:33: I samme stund blev dette ord fullbyrdet på Nebukadnesar; han blev utstøtt fra menneskene og åt urter likesom oksene, og av himmelens dugg blev hans kropp vætet, til hans hår vokste og blev som ørnefjær, og hans negler som fugleklør. 
27:4:34: Men da tiden* var utløpet, løftet jeg, Nebukadnesar, mine øine til himmelen, og min forstand vendte tilbake, og jeg lovet den Høieste og priste og æret ham som lever evindelig, han hvis herredømme er et evig herredømme, og hvis rike varer fra slekt til slekt**. # <* DNL 4, 25. 32.> # <** DNL 4, 3.> 
27:4:35: Alle de som bor på jorden, er som intet å akte, og han gjør med himmelens hær og med dem som bor på jorden, hvad han vil, og det er ingen som kan hindre ham og si til ham: Hvad gjør du? 
27:4:36: Så vendte da på den tid min forstand tilbake, og jeg fikk mitt kongedømmes herlighet, min prakt og min glans igjen, og mine rådsherrer og stormenn søkte mig op, og jeg blev atter innsatt i mitt kongedømme og fikk ennu større makt enn før. 
27:4:37: Nu priser og ophøier og ærer jeg, Nebukadnesar, himmelens konge; for alle hans gjerninger er sannhet, og hans stier rettferdighet, og dem som ferdes i overmot, makter han å ydmyke. 
27:5:1: Kong Belsasar gjorde et stort gjestebud for sine tusen stormenn, og han drakk vin for deres øine. 
27:5:2: Da vinen smakte Belsasar, bød han at de gull- og sølvkar som hans far Nebukadnesar hadde tatt bort fra templet i Jerusalem, skulde hentes, så kongen og hans stormenn, hans hustruer og hans medhustruer kunde drikke av dem. 
27:5:3: Så hentet de de gullkar som var tatt bort fra templet, fra Guds hus i Jerusalem; og kongen og hans stormenn, hans hustruer og hans medhustruer drakk av dem. 
27:5:4: De drakk vin og priste sine guder av gull og sølv, av kobber, jern, tre og sten. 
27:5:5: I samme stund kom det til syne fingrer av en menneskehånd, som skrev på den kalkede vegg i kongens palass, midt imot lysestaken. Og kongen så den hånd som skrev. 
27:5:6: Da skiftet kongen farve, og hans tanker forferdet ham; hans lenders ledemot slappedes, og hans knær slo mot hverandre. 
27:5:7: Og kongen ropte med høi røst at de skulde hente åndemanerne, kaldeerne og sannsigerne. Så tok han da til orde og sa til Babels vismenn: Den mann som leser denne skrift og kunngjør mig dens uttydning, han skal klæs i purpur og få en gullkjede om sin hals, og i makt skal han være den tredje i riket. 
27:5:8: Da alle kongens vismenn var kommet til stede, var de ikke i stand til å lese skriften og kunngjøre kongen dens uttydning. 
27:5:9: Da blev kong Belsasar storlig forferdet og skiftet farve, og hans stormenn blev aldeles forvirret. 
27:5:10: Ved kongens og hans stormenns ord kom dronningen* inn i gjestebudssalen. Hun tok til orde og sa: Kongen leve evindelig! La ikke dine tanker forferde dig, og skift ikke farve! # <* d.e. enkedronningen. DNL 2, 4.> 
27:5:11: Det finnes i ditt rike en mann i hvem de hellige guders ånd er, og hos hvem det i din fars dager blev funnet oplysning og klokskap og en visdom som guders visdom; og kong Nebukadnesar, din far, gjorde ham til mester for tegnsutleggerne, åndemanerne, kaldeerne og sannsigerne. Således, konge, ophøiet din far ham, 
27:5:12: fordi det hos ham fantes en høi ånd og kunnskap og innsikt og evne til å tyde drømmer og løse gåter og utrede vanskelige spørsmål - jeg mener Daniel, han som kongen gav navnet Beltsasar. Send derfor bud efter Daniel! Så kunngjør han dig uttydningen. 
27:5:13: Så blev Daniel ført inn for kongen. Og kongen tok til orde og sa til Daniel: Er du Daniel, en av de jødiske fanger som kongen, min far, førte hit fra Juda? 
27:5:14: Jeg har hørt om dig at guders ånd er i dig, og at det hos dig er funnet oplysning og innsikt og høi visdom. 
27:5:15: Nu er vismennene og åndemanerne blitt ført inn for mig for å lese denne skrift og kunngjøre mig dens uttydning; men de er ikke i stand til å kunngjøre mig nogen uttydning av den. 
27:5:16: Men jeg har hørt om dig at du kan gi uttydninger og utrede vanskelige spørsmål; kan du nu lese denne skrift og kunngjøre mig dens uttydning, så skal du klæs i purpur og få en gullkjede om din hals, og i makt skal du være den tredje i riket. 
27:5:17: Da svarte Daniel og sa der han stod foran kongen: Dine gaver kan du selv ha, og dine foræringer kan du gi til en annen! Men skriften skal jeg lese for kongen og kunngjøre ham dens uttydning. 
27:5:18: Konge! Den høieste Gud gav Nebukadnesar, din far, riket og makten og æren og herligheten; 
27:5:19: og for den makts skyld han hadde gitt ham, bevet og fryktet alle folk, ætter og tungemål for ham; hvem han vilde, slo han ihjel, og hvem han vilde, lot han leve, og hvem han vilde, ophøiet han, og hvem han vilde, fornedret han. 
27:5:20: Men da hans hjerte ophøiet sig, og hans ånd blev stolt og overmodig, blev han nedstøtt fra sin kongetrone, og hans ære blev tatt fra ham. 
27:5:21: Han blev utstøtt fra menneskenes barn, og hans hjerte blev likt dyrenes, og hos villeslene var hans bolig; urter måtte han ete likesom oksene, og av himmelens dugg blev hans legeme vætet, inntil han sannet at den høieste Gud råder over kongedømmet blandt menneskene og setter den han vil, til å styre. 
27:5:22: Men du Belsasar, hans sønn, har ikke ydmyket ditt hjerte, enda du visste alt dette; 
27:5:23: du har ophøiet dig mot himmelens herre, og du har latt hente karene som var tatt fra hans hus, og du og dine stormenn, dine hustruer og dine medhustruer har drukket vin av dem, og du har prist dine guder av sølv og gull, av kobber, jern, tre og sten, som ikke ser og ikke hører og ikke har forstand; men den Gud i hvis hånd din livsånde er, og som råder over alle dine veier, har du ikke æret. 
27:5:24: Derfor blev nu denne hånd sendt fra ham, og denne skrift skrevet. 
27:5:25: Og dette er den skrift som er skrevet her: Mene, mene, tekel, ufarsin*. # <* d.e. tellet, tellet, veid, og de deler.> 
27:5:26: Og så er uttydningen av dette ord: Mene: Tellet har Gud ditt kongedømmes dager og gjort ende på det. 
27:5:27: Tekel: Veid er du på vektskål og funnet for lett. 
27:5:28: Peres: Delt er ditt kongedømme og gitt til mederne og perserne. 
27:5:29: Da bød Belsasar at Daniel skulde klæs i purpur og en gullkjede legges om hans hals, og at det skulde utropes om ham at han i makt skulde være den tredje i riket. 
27:5:30: Samme natt blev kaldeerkongen Belsasar drept. 
27:6:1: Og mederen Darius mottok riket; han var da to og seksti år gammel. 
27:6:2: Darius fant for godt å sette hundre og tyve satraper over riket; de skulde bo rundt omkring i hele riket. 
27:6:3: Og over dem satte han tre riksråder, og av dem var Daniel den ene; for dem skulde satrapene avlegge regnskap, så kongen ikke skulde lide noget tap. 
27:6:4: Men Daniel utmerket sig fremfor riksrådene og satrapene, fordi det var en høi ånd i ham, og kongen tenkte på å sette ham over hele riket. 
27:6:5: Da søkte riksrådene og satrapene å finne skyld hos Daniel vedkommende rikets styrelse, men de kunde ikke finne nogen skyld eller nogen urett, eftersom han var tro, og det ikke fantes nogen forseelse eller nogen urett hos ham. 
27:6:6: Så sa disse menn: Vi finner ingen skyld hos denne Daniel, det skulde da være at vi kunde finne noget å anklage ham for i hans gudsdyrkelse. 
27:6:7: Da stormet disse riksråder og satraper inn til kongen og sa til ham: Kong Darius leve evindelig! 
27:6:8: Alle riksrådene, stattholderne og satrapene, rådsherrene og landshøvdingene har rådslått om at det burde utstedes en kongelig forordning og gis et strengt forbud, at hver den som i løpet av tretti dager beder til nogen gud eller noget menneske uten til dig, konge, skal kastes i løvehulen. 
27:6:9: Så utsted nu, konge, et sådant forbud, og la det sette op skriftlig, så det efter medernes og persernes uforanderlige lov ikke kan tilbakekalles. 
27:6:10: I overensstemmelse hermed lot kong Darius sette op en skrivelse med et sådant forbud. 
27:6:11: Men så snart Daniel fikk vite at skrivelsen var satt op, gikk han inn i sitt hus; der hadde han i sin sal åpne vinduer som vendte mot Jerusalem, og tre ganger om dagen bøide han sine knær med bønn og lovprisning for sin Guds åsyn, aldeles som han hadde gjort før. 
27:6:12: Da stormet disse menn inn og fant Daniel bedende og bønnfallende for sin Gud. 
27:6:13: Så gikk de frem for kongen og spurte med tanke på det kongelige forbud: Har du ikke latt sette op et forbud, at hvert menneske som i løpet av tretti dager beder til nogen gud eller noget menneske uten til dig, konge, skal kastes i løvehulen? Kongen svarte: Det ord står fast efter medernes og persernes uforanderlige lov. 
27:6:14: Da tok de til orde og sa der de stod foran kongen: Daniel, som er en av de bortførte fra Juda, har ikke aktet på dig, konge, eller på det forbud du har latt sette op; tre ganger om dagen holder han bønn. 
27:6:15: Da kongen hørte dette, blev han meget bedrøvet og tenkte på hvorledes han skulde kunne frelse Daniel, og helt til solen gikk ned, gjorde han sig umak for å utfri ham. 
27:6:16: Da stormet disse menn inn på kongen og sa til ham: Vit, konge, at det gjelder den lov hos mederne og perserne at intet forbud og ingen forordning som kongen utsteder, kan forandres. 
27:6:17: Så bød kongen at Daniel skulde hentes og kastes i løvehulen. Og kongen tok til orde og sa til Daniel: Din Gud, som du stadig dyrker, han frelse dig! 
27:6:18: Så blev en sten ført frem og lagt over hulens åpning, og kongen forseglet den med sitt eget og sine stormenns segl, så det ikke skulde kunne skje nogen forandring i det som var gjort med Daniel. 
27:6:19: Derefter gikk kongen hjem til sitt palass og fastet hele natten, og han lot ikke nogen av sine medhustruer komme inn til sig, og søvnen flydde fra ham. 
27:6:20: Tidlig om morgenen, så snart det lysnet, stod kongen op og skyndte sig til løvehulen. 
27:6:21: Og da han kom nær til hulen, ropte han med sorgfull røst på Daniel. Han tok til orde og sa til Daniel: Daniel, du den levende Guds tjener! Har din Gud, som du stadig har dyrket, maktet å frelse dig fra løvene? 
27:6:22: Da svarte Daniel kongen: Kongen leve evindelig! 
27:6:23: Min Gud sendte sin engel og lukket løvenes gap, så de ikke har gjort mig nogen skade, fordi jeg er funnet uskyldig for ham, og heller ikke mot dig, konge, har jeg gjort noget galt. 
27:6:24: Da blev kongen meget glad og bød at Daniel skulde dras op av hulen; og da Daniel var dradd op av hulen, fantes det ingen skade på ham, fordi han hadde trodd på sin Gud. 
27:6:25: Og kongen bød at de menn som hadde klaget på Daniel, skulde hentes, og de blev med sine barn og hustruer kastet i løvehulen; og før de nådde bunnen i hulen, falt løvene over dem og knuste alle deres ben. 
27:6:26: Derefter skrev kong Darius til alle folk, ætter og tungemål som fantes på den hele jord: Alt godt bli eder i rikt mål til del! 
27:6:27: Jeg gir hermed det bud at alle folk i hele mitt kongerikes område skal skjelve og frykte for Daniels Gud; for han er den levende Gud og blir i evighet, og hans rike ødelegges ikke, og hans herredømme varer inntil enden. 
27:6:28: Han frelser og utfrir og gjør tegn og under i himmelen og på jorden - han som frelste Daniel av løvenes vold. 
27:6:29: Og Daniel levde æret og lykkelig både under Darius' og perseren Kyros' regjering. 
27:7:1: I Babels konge Belsasars første år hadde Daniel en drøm og så i sitt indre syner mens han lå på sitt leie; siden skrev han drømmen op og fortalte dens hovedinnhold. 
27:7:2: Således lød Daniels fortelling: Jeg hadde et syn om natten, og i det syn så jeg hvorledes himmelens fire vinder brøt frem over det store hav. 
27:7:3: Og fire store dyr steg op av havet, og alle var de forskjellige fra hverandre. 
27:7:4: Det første var som en løve og hadde ørnevinger; og mens jeg så på det, blev dets vinger revet av, og det blev løftet fra jorden og reist på to føtter som et menneske, og det fikk et menneskehjerte. 
27:7:5: Så fikk jeg se et dyr til, det annet i rekken; det var likt en bjørn og reiste sig på den ene side*; det hadde tre ribben i sitt gap mellem sine tenner, og det blev sagt til det: Stå op og et meget kjøtt! # <* d.e. på det ene forben.> 
27:7:6: Derefter så jeg i mitt syn et annet dyr, som lignet en leopard; det hadde fire fuglevinger på ryggen og hadde fire hoder, og det fikk stort velde. 
27:7:7: Derefter fikk jeg i mine nattlige syner se et fjerde dyr, fryktelig og forferdelig og overmåte sterkt; det hadde store tenner av jern og åt og knuste, og det som blev tilovers, trådte det ned med sine føtter; det var anderledes enn alle de første dyr og hadde ti horn. 
27:7:8: Jeg aktet nøie på hornene; da fikk jeg se et annet lite horn som skjøt op mellem dem, og tre av de første horn blev rykket op for dets skyld, og dette horn hadde øine som menneskeøine og en munn som talte store ord. 
27:7:9: Mens jeg så på dette, blev det satt stoler frem, og en gammel av dager satte sig; hans klædebon var hvitt som sne, og håret på hans hode var som ren ull; hans trone var ildsluer, og hjulene på den var brennende ild. 
27:7:10: En strøm av ild fløt frem og gikk ut fra ham; tusen ganger tusen tjente ham, og ti tusen ganger ti tusen stod foran ham; retten blev satt, og bøker blev åpnet. 
27:7:11: Mens jeg så på dette, blev dyret drept for de store ords skyld som hornet talte, og dets kropp blev tilintetgjort og kastet i ilden for å brennes. 
27:7:12: De andre dyrs herredømme blev tatt fra dem; for deres levetid var fastsatt til tid og stund. 
27:7:13: Fremdeles fikk jeg i mine nattlige syner se hvorledes en som lignet en menneskesønn, kom med himmelens skyer; han gikk bort til den gamle av dager og blev ført frem for ham. 
27:7:14: Og det blev gitt ham herredømme og ære og rike, og alle folk, ætter og tungemål skulde tjene ham; hans herredømme er et evig herredømme, som ikke forgår, og hans rike er et rike som ikke ødelegges. 
27:7:15: Da blev jeg, Daniel, grepet av uro i min ånd, og synene i mitt indre forferdet mig. 
27:7:16: Jeg gikk bort til en av dem som stod der, og bad ham om å få sikker oplysning om alt dette; og han svarte mig og kunngjorde mig uttydningen av det: 
27:7:17: Disse fire store dyr betyr at fire konger skal opstå av jorden; 
27:7:18: men den Høiestes hellige skal få riket og ha det i eie til evig tid, ja i evigheters evighet. 
27:7:19: Da ønsket jeg å få sikker oplysning om det fjerde dyr, som var anderledes enn alle de andre, det som var så fryktelig og hadde tenner av jern og klør av kobber, og som åt og knuste og trådte det som blev tilovers, ned med sine føtter, 
27:7:20: og likeså om de ti horn på dets hode, og om det nye horn, det som skjøt op, og for hvis skyld tre av de andre horn falt av - det horn som både hadde øine og en munn som talte store ord, og som var større å se til enn de andre. 
27:7:21: Jeg så hvorledes dette horn førte krig mot de hellige og fikk overhånd over dem, 
27:7:22: inntil den gamle av dager kom, og den Høiestes hellige fikk sin rett, og tiden kom da de hellige tok riket i eie. 
27:7:23: Så lød hans ord: Det fjerde dyr betyr at det på jorden skal komme et fjerde rike, som skal være anderledes enn alle de andre riker, og det skal sluke hele jorden og søndertrede og knuse den. 
27:7:24: Og de ti horn betyr at det av dette rike skal opstå ti konger, og efter dem skal det opstå en annen konge, som skal være anderledes enn de foregående, og som skal ydmyke tre konger, 
27:7:25: og han skal tale ord mot den Høieste og undertrykke den Høiestes hellige; han skal tenke på å forandre hellige tider og lov, og de skal gis i hans hånd en tid og tider og en halv tid*. # <* ett år og to år og et halvt år; DNL 12, 7.> 
27:7:26: Så blir retten satt, og herredømmet skal fratas ham, så han blir ødelagt og tilintetgjort for all tid. 
27:7:27: Og riket og herredømmet og makten over rikene under hele himmelen skal gis til det folk som er den Høiestes hellige; dets rike skal være et evig rike, og alle makter skal tjene og lyde det. 
27:7:28: Hermed er min fortelling til ende. Jeg, Daniel, forferdedes storlig av mine tanker, og mitt ansikt skiftet farve; men jeg gjemte det jeg hadde sett og hørt, i mitt hjerte. 
27:8:1: I kong Belsasars tredje regjeringsår fikk jeg, Daniel, se et syn, et som kom efter det jeg før hadde sett. 
27:8:2: Da jeg hadde dette syn, forekom det mig som jeg var i borgen Susan i landskapet Elam, og videre forekom det mig i synet som jeg var ved elven Ulai. 
27:8:3: Og da jeg så op, fikk jeg se en vær som stod foran elven; den hadde to horn, og begge hornene var høie, men det ene høiere enn det andre, og det høieste vokste sist frem. 
27:8:4: Jeg så væren stange mot vest og mot nord og mot syd, og intet dyr kunde stå sig for den, og det var ingen som kunde frelse av dens vold; den gjorde som den vilde, og tedde sig overmodig. 
27:8:5: Og da jeg videre gav akt, fikk jeg se en gjetebukk som kom fra vest og fór frem over hele jorden uten å røre ved jorden, og bukken hadde et veldig horn mellem øinene. 
27:8:6: Og den kom like bort til væren med de to horn, den som jeg så foran elven, og sprang imot den i sin voldsomme kraft. 
27:8:7: Og jeg så hvorledes den kom tett inn på væren og i sinne fór løs på den og støtte til den og sønderbrøt begge dens horn, og væren hadde ikke kraft til å stå sig for den; og den kastet den til jorden og trådte den ned, og det var ingen som kunde frelse væren av dens vold. 
27:8:8: Og gjetebukken blev overmåte mektig; men just som den hadde nådd sin fulle styrke, blev det store horn brutt av, og i stedet for det vokste det op fire veldige horn, som vendte mot himmelens fire hjørner. 
27:8:9: Og av det ene av dem skjøt det op et nytt horn, som fra først av var lite, men siden blev overmåte stort mot syd og mot øst og mot det fagre land*. # <* d.e. Israels land; DNL 11, 16. 41. JER 3, 19.> 
27:8:10: Og det vokste like op til himmelens hær og kastet nogen av hæren - av stjernene* - til jorden og trådte dem ned. # <* d.e. av Guds folk; DNL 8, 24.> 
27:8:11: Ja, like til hærens fyrste hevet det sig; det tok fra ham det stadige offer, og hans helligdoms bolig blev omstyrtet. 
27:8:12: Og sammen med det stadige offer blev hæren overgitt til ødeleggelse for frafallets skyld; og hornet kastet sannheten til jorden, og det hadde fremgang med alt det foretok sig. 
27:8:13: Så hørte jeg en av de hellige tale; og en annen hellig sa til ham som talte: For hvor lang tid gjelder synet om det stadige offer og det ødeleggende frafall - at både helligdom og hær overgis til nedtredelse? 
27:8:14: Og han sa til mig: To tusen og tre hundre aftener og morgener; så skal helligdommen komme til sin rett igjen. 
27:8:15: Da nu jeg, Daniel, så dette syn, søkte jeg å forstå det; da stod det med én gang foran mig en skikkelse som så ut som en mann. 
27:8:16: Og jeg hørte et menneskes røst mellem Ulais bredder; han ropte: Gabriel! Forklar synet for ham! 
27:8:17: Så kom han dit jeg stod, og da han kom, blev jeg forferdet og falt på mitt ansikt; og han sa til mig: Gi akt på mine ord, menneskebarn! For synet sikter til endens tid. 
27:8:18: Mens han talte med mig, sank jeg sanseløs på mitt ansikt til jorden; da rørte han ved mig og reiste mig op igjen. 
27:8:19: Og han sa: Nu vil jeg kunngjøre dig hvad som skal skje i vredens siste tid; for synet sikter til den for enden fastsatte tid. 
27:8:20: Væren du så, den med de to horn, det er kongene av Media og Persia. 
27:8:21: Og den raggete bukk er kongen av Grekenland, og det store horn mellem dens øine er den første konge*. # <* Aleksander den store.> 
27:8:22: Og at dette horn blev avbrutt, og at det kom fire andre i dets sted, det betyr at fire kongeriker* skal opstå av hans folk, men ikke med hans kraft. # <* d.e. det makedoniske, trakiske, syriske og egyptiske.> 
27:8:23: Og i den siste tid av deres herredømme, når overtrederne har gjort sine synders mål fullt, skal det opstå en konge med frekt åsyn og kyndig i onde råd*. # <* Antiokus Epifanes.> 
27:8:24: Og hans makt skal bli stor, men ikke ved hans egen kraft, og han skal gjøre utrolig stor skade og ha fremgang med alt det han foretar sig; han skal ødelegge mektige fyrster og de helliges folk. 
27:8:25: Og fordi han er klok, skal hans svikefulle ferd lykkes for ham; han skal ophøie sig i sitt hjerte, og han skal ødelegge mange i deres trygghet; ja, mot fyrstenes fyrste skal han sette sig op; men uten menneskehånd skal han knuses. 
27:8:26: Og synet om aftenene og morgenene, som det var tale om, er sannhet; men gjem du synet, for det sikter til en fjern fremtid! 
27:8:27: Men jeg, Daniel, blev rent avmektig, og jeg blev syk en tid; så stod jeg op og gjorde min tjeneste hos kongen; jeg var forferdet over synet, men det var ingen som forstod det. 
27:9:1: I Darius', Ahasverus' sønns første regjeringsår - han som var av medisk ætt og var blitt konge over kaldeerriket 
27:9:2: i det første år av hans regjering la jeg, Daniel, i bøkene merke til tallet på de år som Herren hadde talt om til profeten Jeremias - at han vilde la fulle sytti år gå til ende mens Jerusalem lå i ruiner. 
27:9:3: Da vendte jeg mitt ansikt til Gud Herren for å søke ham med bønn og ydmyke begjæringer under faste og i sekk og aske. 
27:9:4: Og jeg bad til Herren min Gud og bekjente og sa: Akk, Herre, du store og forferdelige Gud, som holder din pakt og bevarer miskunnhet mot dem som elsker dig og holder dine bud! 
27:9:5: Vi har syndet og gjort ille og vært ugudelige og satt oss op imot dig; vi har veket av fra dine bud og dine lover. 
27:9:6: Vi hørte ikke på dine tjenere, profetene, som talte i ditt navn til våre konger, våre fyrster og våre fedre og til alt folket i landet. 
27:9:7: Dig, Herre, hører rettferdigheten til, men oss vårt ansikts blygsel, som det sees på denne dag - oss, Judas menn og Jerusalems innbyggere og hele Israel, både dem som er nær, og dem som er langt borte, i alle de land som du har drevet dem bort til for den troløshets skyld som de har vist mot dig. 
27:9:8: Herre! Oss hører vårt ansikts blygsel til, våre konger, våre fyrster og våre fedre, fordi vi har syndet mot dig. 
27:9:9: Hos Herren vår Gud er barmhjertighet og forlatelse. For vi har satt oss op imot ham, 
27:9:10: og vi hørte ikke på Herrens, vår Guds røst og fulgte ikke hans lover, som han forela oss ved sine tjenere, profetene; 
27:9:11: men hele Israel overtrådte din lov og vek av fra dig og hørte ikke på din røst; derfor blev den utøst over oss den forbannelse som han hadde svoret å sende, og som står skrevet i Mose, Guds tjeners lov; for vi hadde syndet mot ham, 
27:9:12: og han opfylte de ord som han hadde talt mot oss og mot våre dommere, som dømte oss, og han lot så stor en ulykke komme over oss at det ikke under hele himmelen har hendt noget sådant som det som har hendt i Jerusalem. 
27:9:13: Efter det som skrevet står i Mose lov, kom all denne ulykke over oss; men vi bønnfalt ikke Herren vår Gud og vendte ikke om fra våre misgjerninger og aktet ikke på din sannhet. 
27:9:14: Derfor hadde Herren ulykken stadig for øie og lot den komme over oss; for Herren vår Gud er rettferdig i alt det han gjør, men vi hørte ikke på hans røst. 
27:9:15: Og nu, Herre vår Gud, du som førte ditt folk ut av Egyptens land med sterk hånd og gjorde dig et navn, som det er på denne dag! Vi har syndet, vi har vært ugudelige. 
27:9:16: Herre! La efter alle dine rettferdige gjerninger din vrede og harme vende sig bort fra din stad Jerusalem, ditt hellige berg! For på grunn av våre synder og våre fedres misgjerninger er Jerusalem og ditt folk blitt til spott for alle dem som bor omkring oss. 
27:9:17: Hør nu, vår Gud, på din tjeners bønn og hans ydmyke begjæringer og la ditt åsyn lyse over din ødelagte helligdom - for din egen skyld, Herre! 
27:9:18: Vend, min Gud, ditt øre hit og hør! Oplat dine øine og se våre ruiner og staden som er kalt med ditt navn! For ikke på våre rettferdige gjerninger grunner vi våre ydmyke begjæringer, som vi bærer frem for ditt åsyn, men på din store barmhjertighet. 
27:9:19: Herre, hør! Herre, forlat! Herre, gi akt og gjør det og dryg ikke - for din egen skyld, min Gud! For din stad og ditt folk er kalt med ditt navn. 
27:9:20: Mens jeg ennu talte og bad og bekjente min synd og mitt folk Israels synd og bar min bønn for min Guds hellige berg frem for Herrens, min Guds åsyn - 
27:9:21: mens jeg ennu talte i bønnen, da kom Gabriel, den mann som jeg før hadde sett i synet, dengang jeg blev så rent avmektig, og rørte ved mig - det var på aftenofferets tid. 
27:9:22: Og han lærte mig og talte til mig og sa: Daniel! Nu er jeg kommet hit for å lære dig å forstå. 
27:9:23: Med det samme du begynte å frembære dine ydmyke bønner, kom det et ord, og nu er jeg kommet for å kunngjøre dig det; for du er høit elsket; så merk dig nu ordet og gi akt på synet! 
27:9:24: Sytti uker* er tilmålt ditt folk og din hellige stad til å innelukke frafallet og til å forsegle synder og til å dekke over misgjerning og til å føre frem en evig rettferdighet** og til å besegle syn og profet*** og til å salve et Aller-helligste****. # <* Med uke menes her et tidsrum av syv år.> # <** Rom 3, 21 fg.> # <*** MTT 5, 17. APO 3, 18.> # <**** Kristus; MRK 1, 24. LUK 1, 35.> 
27:9:25: Og du skal vite og forstå: Fra den tid ordet utgår om å gjenreise og ombygge Jerusalem, inntil en salvet*, en fyrste, står frem, skal det gå syv uker og to og seksti uker; det skal igjen settes i stand og opbygges med gater og vollgraver, men under tidenes trengsel**. # <* Kristus.> # <** NEH 2, 7-9; 3, 1 fg. 4, 9 fg.> 
27:9:26: Og efter de to og seksti uker skal den salvede utryddes* og intet ha**, og staden og helligdommen skal en kommende fyrstes folk ødelegge, og enden på det er oversvømmelse, og inntil enden er det krig; ødeleggelse er fast besluttet. # <* JES 53, 8.> # <** intet herredømme; MTT 26, 56.> 
27:9:27: Og én uke skal gjøre pakten* fast for de mange; og i midten av uken skal slaktoffer og matoffer ophøre**, og på vederstyggelighetenes vinger skal ødeleggeren komme***, og det inntil tilintetgjørelse og fast besluttet straffedom strømmer ned over den som ødelegges. # <* HEB 7, 22; 8, 6. MTT 26, 28.> # <** HEB 10, 9.> # <*** DNL 8, 13. MTT 24, 15.> 
27:10:1: I perserkongen Kyros' tredje år kom det i en åpenbaring et ord til Daniel, som hadde fått navnet Beltsasar; dette ord er sant og varsler stor trengsel. Og han merket sig ordet og gav akt på synet. 
27:10:2: På den tid hadde jeg, Daniel, sørget i tre uker; 
27:10:3: nogen kostelig mat åt jeg ikke, og kjøtt og vin kom ikke i min munn, og heller ikke salvet jeg mig, før de tre uker var til ende. 
27:10:4: På den fire og tyvende dag i den første måned, mens jeg stod ved bredden av den store elv, det er Hiddekel*, # <* d.e. Tigris; 1MO 2, 14.> 
27:10:5: så jeg op, og da fikk jeg se en mann som stod der; han var klædd i linklær, og hans lender var omgjorde med et belte av gull fra Ufas; 
27:10:6: hans legeme var som krysolitt, hans ansikt skinte som lynet, hans øine var som ildsluer, hans armer og ben var som blankt kobber å se til, og lyden av hans ord var som et veldig drønn. 
27:10:7: Jeg, Daniel, var den eneste som så synet; de menn som var med mig, så ikke synet, men en stor redsel falt på dem, og de flyktet og skjulte sig. 
27:10:8: Så blev jeg alene tilbake. Og da jeg så dette store syn, blev jeg rent maktesløs, og mitt ansikt mistet sin friske farve, så jeg så rent ille ut, og jeg hadde ingen kraft mere. 
27:10:9: Og jeg hørte lyden av hans ord; og da jeg hørte lyden av hans ord, sank jeg sanseløs fremover med ansiktet mot jorden. 
27:10:10: Da merket jeg en hånd som rørte ved mig, den hjalp mig op, så jeg skjelvende hvilte på mine knær og hender. 
27:10:11: Og han sa til mig: Daniel, du høit elskede mann! Gi akt på de ord jeg vil tale til dig, og reis dig op igjen! For nu er jeg sendt til dig. Og da han talte således til mig, stod jeg skjelvende op. 
27:10:12: Og han sa til mig: Frykt ikke, Daniel! For fra den første dag du vendte din hu til å vinne forstand og til å ydmyke dig for din Guds åsyn, er dine ord blitt hørt, og for dine ords skyld er jeg kommet. 
27:10:13: Perserrikets fyrste* stod mig imot i en og tyve dager; men da kom Mikael, en av de fornemste fyrster**, og hjalp mig, så jeg fikk overhånd der hos kongene av Persia. # <* d.e. onde engel. DNL 10, 19.> # <** DNL 12, 1. JDA 1, 9. APE 12, 7.> 
27:10:14: Og nu er jeg kommet for å oplyse dig om hvad som skal vederfares ditt folk i de siste dager*; for dette er atter et syn som sikter til de dager. # <* 1MO 49, 1.> 
27:10:15: Og da han talte således til mig, vendte jeg mitt ansikt mot jorden og var målløs. 
27:10:16: Og se, en som lignet et menneske, rørte ved mine leber, og jeg åpnet min munn og talte og sa til ham som stod foran mig: Herre! Ved det syn jeg har hatt, er svære smerter kommet over mig, så jeg ikke lenger har nogen kraft; 
27:10:17: hvorledes skulde da min herres tjener, en slik som jeg, kunne tale med en slik som min herre er? Hos mig finnes fra nu av ingen kraft, og det er ikke ånde tilbake i mig. 
27:10:18: Og atter rørte en som så ut som et menneske, ved mig og styrket mig, og han sa: Frykt ikke, du høit elskede mann! Fred være med dig! Vær du bare frimodig! Og som han talte med mig, blev jeg styrket og sa: Tal, herre! For du har styrket mig. 
27:10:19: Da sa han: Vet du nu hvorfor jeg er kommet til dig? Nu må jeg vende tilbake for å stride mot Persias fyrste*; og når jeg drar ut, da kommer Grekenlands fyrste. # <* DNL 10, 13.> 
27:10:20: Men først vil jeg kunngjøre dig hvad som er optegnet i sannhets bok. Og det er ikke en eneste som hjelper mig mot dem, uten Mikael, eders fyrste; 
27:11:1: jeg stod også ved hans side som hjelper og som vern i mederen Darius' første år. # [DNL 11, 1 er i trykte bøker plassert på slutten av kapittel 10.] 
27:11:2: Og nu vil jeg kunngjøre dig hvad som visst og sant vil hende: Ennu skal det opstå tre konger* i Persia, og den fjerde** skal samle større rikdom enn alle de andre, og når han har vunnet stor makt ved sin rikdom, skal han opbyde alt mot Grekenlands rike. # <* Kambyses, Smerdes og Darius Hystaspis.> # <** Xerxes.> 
27:11:3: Så skal det opstå en veldig konge*, og han skal herske med stor makt og gjøre som han vil. # <* Aleksander den store.> 
27:11:4: Men aldri så snart er han stått frem, så skal hans rike brytes i stykker og deles efter de fire verdenshjørner; det skal ikke tilfalle hans efterkommere og ikke være så mektig som da han rådet; for hans rike skal omstyrtes og tilfalle andre enn dem. 
27:11:5: Og Sydens konge* skal bli mektig, og en av hans fyrster** skal bli mektigere enn han og råde over et eget rike; hans rike skal være et stort rike. # <* Egyptens konge, Ptolemeus Lagi.> # <** Syrias konge, Seleukus Nikator.> 
27:11:6: Og mange år efter skal de inngå forbund med hverandre; en datter av Sydens konge skal komme til Nordens konge* for å skape vennskap mellem dem; men hun skal ikke lenge formå å utrette noget, og hverken han** eller datteren som skulde hjelpe ham, skal holde stand, men hun og de som førte henne dit, og hennes far og han som tok imot henne***, skal miste livet på den for hver av dem bestemte tid. # <* den egyptiske konge Ptolemeus Filadelfus gav den syriske konge Antiokus Teos sin datter Berenike til ekte.> # <** Ptolemeus.> # <*** Antiokus, Berenike og hennes venner blev drept.> 
27:11:7: Men et av skuddene fra hennes røtter* skal trede op på hans plass; og han skal dra mot fiendens hær og trenge inn i Nordens konges** festninger og gjøre med dem som han vil, og ha overmakt. # <* Ptolemeus Euergetes, en bror av Berenike.> # <** Nordens konge er den syriske konge Seleukus Kallinikus.> 
27:11:8: Endog deres guder med deres støpte billeder, med deres kostbare kar av sølv og gull, skal han føre bort til Egypten; siden skal han i nogen år la Nordens konge få være i fred. 
27:11:9: Så skal Nordens konge dra mot Sydens konges rike, men måtte vende tilbake til sitt land igjen*. # <* Kallinikus' tog mot Egypten mislyktes aldeles.> 
27:11:10: Og hans sønner* skal ruste sig til strid og samle en veldig krigshær, og den skal dra inn i landet og oversvømme og overskylle det; så skal den komme igjen, og krigen skal føres helt frem til hans festning**. # <* Kallinikus' sønner, Seleukus Keraunus og Antiokus den store.> # <** den egyptiske konge Ptolemeus Filopators festning Rafia.> 
27:11:11: Da skal Sydens konge* bli harm og dra ut og stride mot Nordens konge, og han skal stille op en stor hær, men den skal gis i hans* hånd. # <* Ptolemeus Filopator.> 
27:11:12: Når denne hær er sprengt, skal han bli overmodig; han skal felle titusener, men allikevel ikke få overmakten. 
27:11:13: Og Nordens konge* skal komme igjen og stille op en hær som er ennu større enn den første; og når nogen år er gått til ende, skal han komme med en stor hær og store forråd. # <* Antiokus den store. DNL 11, 10.> 
27:11:14: Og i de tider skal mange reise sig mot Sydens konge*, og voldsmenn** av ditt eget folk skal også reise sig, så synet blir stadfestet; men de skal falle. # <* Egyptens konge Ptolemeus Epifanes.> # <** ugudelige jøder som falt fra Egypten og holdt sig til Antiokus.> 
27:11:15: Og Nordens konge skal komme og opkaste en voll og innta en sterk festning*, og Sydens makt skal ikke kunne holde stand, og enn ikke hans** utvalgte mannskap har kraft til å holde stand. # <* Sidon.> # <** Egyptens konges.> 
27:11:16: Og den som rykker mot ham, skal gjøre som han vil, og ingen skal kunne stå sig mot ham; han skal feste fot i det fagre land* og føre ødeleggelse med sig. # <* DNL 8, 9.> 
27:11:17: Og han* skal ta sig fore å komme med hele sitt rikes makt**, men er villig til å inngå forlik, og det skal han også få i stand; og en kvinne, sin egen datter***, skal han gi ham, forat hun skal volde ødeleggelse; men det skal ikke komme i stand og ikke lykkes for ham. # <* Antiokus den store.> # <** imot Egyptens konge.> # <*** Kleopatra, datter av Antiokus.> 
27:11:18: Så skal han vende sig mot øene og innta mange, men en hærfører* skal gjøre ende på hans hån og endog la hans hån falle tilbake på ham selv. # <* Lucius Scipio.> 
27:11:19: Da skal han vende sig mot festningene i sitt eget land, men han snubler og faller og er ikke mere til. 
27:11:20: Og på hans plass skal det opstå en* som skal la en skattekrever** dra gjennem rikets pryd***; og nogen dager efter skal han omkomme, men ikke ved vrede, heller ikke i krig****. # <* Seleukus Filopator, konge i Syria.> # <** Heliodorus.> # <*** Judea.> # <**** Seleukus blev drept med gift av Heliodorus.> 
27:11:21: Og på hans plass skal det opstå et foraktelig menneske*, som de ikke har overgitt kongedømmets herlighet; men han kommer uventende og tilegner sig kongedømmet ved list og svik. # <* Antiokus Epifanes, som hadde vært gissel hos romerne.> 
27:11:22: Og de oversvømmende hærer* skal oversvømmes av ham og tilintetgjøres, og likeså forbundsfyrsten**; # <* d.e. den egyptiske hær.> # <** den egyptiske konge Ptolemeus Filometor.> 
27:11:23: for efterat han har inngått forbund med ham, skal han fare frem med svik; han skal dra ut og få overmakten med få folk. 
27:11:24: Uventende skal han falle inn i landets fruktbareste bygder og gjøre ting som hans fedre og hans fedres fedre ikke har gjort; hærfang og rov og gods skal han strø ut til sine folk, og mot faste byer skal han legge op onde råd, og det vil vare en tid. 
27:11:25: Han skal opbyde sin makt og sitt mot mot Sydens konge og komme med en stor hær; men Sydens konge skal også ruste sig til krig med en overmåte stor og sterk hær; men han skal ikke kunne holde stand; for det blir lagt op onde råd mot ham. 
27:11:26: De som eter ved hans bord, skal felle ham; hans hær skal strømme frem, og det skal bli et stort mannefall. 
27:11:27: Begge konger* har ondt i sinne, og mens de sitter ved samme bord, skal de tale løgn; men det skal ikke lykkes, for ennu dryger det med enden, til den fastsatte tid kommer. # <* Ptolemeus Filometor og Antiokus.> 
27:11:28: Han* skal vende tilbake til sitt land med meget gods, og han skal legge op råd mot den hellige pakt, og efterat han har utført dem, skal han vende tilbake til sitt land. # <* Antiokus.> 
27:11:29: Til fastsatt tid skal han atter dra mot Syden; men den siste gang skal det ikke gå som første gang; 
27:11:30: for skib fra Kittim* skal komme imot ham, og han skal bli motfallen og vende om og la sin vrede gå ut over den hellige pakt; så skal han vende om og legge merke til dem som forlater den hellige pakt. # <* d.e. romerske. DNL 11, 28.> 
27:11:31: Og hærer som han sender ut, skal komme og vanhellige helligdommen, den faste borg*, og avskaffe det stadige offer og stille op den ødeleggende vederstyggelighet. # <* d.e. templet.> 
27:11:32: Og dem som synder mot pakten, skal han gjøre til hedninger ved glatte ord; men de av folket som kjenner sin Gud, skal stå fast og holde ut. 
27:11:33: Og de forstandige blandt folket skal lære mengden, og de skal falle ved sverd og bål og ved fangenskap og plyndring en tid lang. 
27:11:34: Men mens de holder på å ligge under, skal de få en liten hjelp, og mange skal slå sig i lag med dem på skrømt. 
27:11:35: Og nogen av de forstandige skal falle, sa de kan bli prøvd og renset og tvettet til endens tid; for ennu dryger det med enden, til den fastsatte tid kommer. 
27:11:36: Og kongen skal gjøre som han vil, og ophøie sig og heve sig over enhver gud, og mot gudenes Gud skal han tale forferdelige ord, og han skal ha fremgang, inntil vreden er til ende; for det som er fast besluttet, vil bli fullbyrdet. 
27:11:37: På sine fedres guder skal han ikke akte, heller ikke på kvinnenes lyst* eller på nogen annen gud skal han akte; for han skal ophøie sig over alle. # <* en især av kvinner dyrket avgud.> 
27:11:38: Men festningenes gud skal han ære i stedet; en gud som hans fedre ikke har kjent, skal han ære med gull og sølv og dyre stener og andre kostelige ting; 
27:11:39: og således skal han gjøre med de sterke festninger og med den fremmede gud: Dem som vedkjenner sig denne gud, skal han vise stor ære, og han skal sette dem til å råde over mange, og han skal dele ut jord til dem som lønn. 
27:11:40: Men i endens tid skal Sydens konge føre krig med ham, og Nordens konge skal storme frem mot ham med vogner og hestfolk og mange skib og falle inn i landene og oversvømme og overskylle dem. 
27:11:41: Han skal også falle inn i det fagre land, og store skarer skal falle; men disse skal slippe unda hans makt: Edom og Moab og de ypperste av Ammons barn. 
27:11:42: Og han skal utstrekke sin hånd mot andre land, og Egyptens land skal ikke slippe unda. 
27:11:43: Han skal tilegne sig skattene av gull og av sølv og alle Egyptens kostelige ting, og libyere og etiopere skal være i hans følge. 
27:11:44: Men tidender fra Østen og fra Norden skal forferde ham, og han skal dra ut i stor harme for å ødelegge og tilintetgjøre mange. 
27:11:45: Og han skal slå op sine palasstelt mellem havet og helligdommens fagre berg; men så bærer det til enden med ham, og det er ingen som hjelper ham. 
27:12:1: På den tid skal Mikael stå frem, den store fyrste som verner om ditt folks barn, og det skal komme en trengselstid som det ikke har vært fra den dag noget folk blev til, og like til den tid. Men på den tid skal alle de av ditt folk bli frelst som finnes opskrevet i boken. 
27:12:2: Og de mange som sover i jordens muld, skal våkne op, somme til evig liv og somme til skam og evig avsky. 
27:12:3: Men de forstandige skal skinne som himmelhvelvingen skinner, og de som har ført de mange til rettferdighet, skal skinne som stjernene, evindelig og alltid. 
27:12:4: Og du, Daniel, gjem disse ord og forsegl boken inntil endens tid! Mange skal granske den, og kunnskapen skal bli stor. 
27:12:5: Så fikk jeg, Daniel, i mitt syn se to andre som stod der, en på den ene elvebredd og en på den andre elvebredd. 
27:12:6: Og en av dem sa til den mann som var klædd i linklær, og som stod ovenover elvens vann: Hvor lenge vil det vare før disse underfulle ting er til ende? 
27:12:7: Og jeg hørte hvad den mann sa som var klædd i linklær, og som stod ovenover elvens vann; han løftet sin høire hånd og sin venstre hånd op mot himmelen og svor ved ham som lever evindelig: En tid og tider og en halv tid*, og når det hellige folks makt er fullstendig knust, skal alle disse ting fullendes. # <* DNL 7, 25.> 
27:12:8: Og jeg hørte det, men forstod det ikke, og jeg sa: Herre! Hvad er det siste av disse ting? 
27:12:9: Så sa han: Gå bort, Daniel! For disse ord skal være gjemt og forseglet inntil endens tid. 
27:12:10: Mange skal bli renset og tvettet og prøvd; men de ugudelige skal bli ved å være ugudelige, og ingen ugudelig skal forstå det; men de forstandige skal forstå det. 
27:12:11: Og fra den tid det stadige offer blir avskaffet, og den ødeleggende vederstyggelighet blir stilt op, skal det gå tusen, to hundre og nitti dager. 
27:12:12: Salig er den som bier og når frem til tusen, tre hundre og fem og tretti dager. 
27:12:13: Men gå du din ende i møte! Du skal hvile og stå op til din lodd ved dagenes ende. 
28:1:1: Herrens ord som kom til Hoseas, Be'eris sønn, i de dager da Ussias, Jotam, Akas, Esekias var konger i Juda, og da Jeroboam, Joas' sønn, var konge i Israel. 
28:1:2: Da Herren begynte å tale til Hoseas, sa Herren til ham: Gå og få dig en horkvinne og horebarn! For landet driver hor og følger ikke mere Herren. 
28:1:3: Da gikk han og ektet Gomer, Dibla'ims datter, og hun blev fruktsommelig og fødte ham en sønn. 
28:1:4: Og Herren sa til ham: Kall ham Jisre'el! For om en liten stund vil jeg hjemsøke Jisre'els blodskyld på Jehus hus og gjøre ende på kongedømmet i Israels hus; 
28:1:5: og det skal skje på den dag at jeg sønderbryter Israels bue i Jisre'els dal. 
28:1:6: Og hun blev atter fruktsommelig og fødte en datter, og han sa til ham: Kall henne Lo-Ruhama*! For jeg vil ikke mere miskunne mig over Israels hus, så jeg skulde tilgi dem; # <* den som ikke får miskunn.> 
28:1:7: men over Judas hus vil jeg miskunne mig, og jeg vil frelse dem ved Herren deres Gud; jeg vil ikke frelse dem ved bue eller sverd eller krig, ved hester eller ryttere. 
28:1:8: Da hun hadde avvent Lo-Ruhama, blev hun atter fruktsommelig og fødte en sønn. 
28:1:9: Og han sa: Kall ham Lo-Ammi*! For I er ikke mitt folk, og jeg vil ikke tilhøre eder. # <* ikke mitt folk.> 
28:1:10: Men allikevel skal tallet på Israels barn bli som havets sand, som ikke lar sig måle eller telle, og det skal skje: På det sted hvor det blev sagt til dem: I er ikke mitt folk, skal det sies til dem: Den levende Guds barn! 
28:1:11: Og Judas barn og Israels barn skal fylke sig sammen og ta sig en høvding og dra op av landet; for stor er Jisre'els dag. 
28:2:1: Si da til eders brødre: Ammi*! Og til eders søstre: Ruhama**! # <* Du mitt folk!.> # <** Du som har fått miskunn! 1PE 2, 10.> # [HSE 2, 1 er i trykte bøker plassert på slutten av kapittel 1.] 
28:2:2: Gå i rette med eders mor, gå i rette med henne! For hun er ikke min hustru, og jeg er ikke hennes mann. Få henne til å ta sine horeminer bort fra sitt ansikt og sine utuktige fakter fra sine bryster, 
28:2:3: forat jeg ikke skal klæ henne naken og stille henne frem som hun var den dag hun blev født, og gjøre henne lik en ørken, et tørt land, og la henne dø av tørst! 
28:2:4: Og hennes barn vil jeg ikke miskunne mig over; for de er horebarn. 
28:2:5: For deres mor har drevet hor, hun som fødte dem, har båret sig skammelig at - hun sa: Jeg vil gå efter mine elskere, de som gir mig mitt brød og mitt vann, min ull og mitt lin, min olje og min vin. 
28:2:6: Se, derfor stenger jeg din vei med torner; jeg reiser en mur foran henne, så hun ikke finner sine stier; 
28:2:7: når hun løper efter sine elskere, skal hun ikke nå dem, når hun søker dem, skal hun ikke finne dem. Da skal hun si: Jeg vil vende tilbake til min første mann; for dengang hadde jeg det bedre enn nu. 
28:2:8: Hun skjønte ikke at det var jeg som gav henne kornet og mosten og oljen, og som gav henne sølv og gull i mengdevis, som de brukte til Ba'al. 
28:2:9: Derfor vil jeg ta mitt korn tilbake, når dets tid kommer, og min most, når tiden er inne, og jeg vil ta bort min ull og mitt lin, som tjener til å skjule hennes blusel. 
28:2:10: Ja, nu vil jeg avdekke hennes visne kropp for hennes elskeres øine, og ingen skal rive henne ut av min hånd. 
28:2:11: Og jeg vil gjøre ende på all hennes glede, hennes fester, hennes nymåner og hennes sabbater og alle hennes høitider. 
28:2:12: Og jeg vil ødelegge hennes vintre og hennes fikentre, hvorom hun har sagt: De er min horelønn, som mine elskere har gitt mig. Og jeg vil gjøre dem til skog, og markens dyr skal ete dem. 
28:2:13: Og jeg vil hjemsøke henne for de dager da hun brente røkelse for Ba'alene og prydet sig med nesering og halssmykke og gikk efter sine elskere, men glemte mig, sier Herren. 
28:2:14: Se, derfor vil jeg lokke henne og føre henne ut i ørkenen og tale vennlig til henne, 
28:2:15: og så snart hun kommer derfra, vil jeg gi henne hennes vingårder igjen og gjøre Akors dal* til en håpets dør for henne; og hun skal takke der**, som i sin ungdoms dager, som den dag hun drog op fra Egyptens land. # <* JOS 7, 24. 26.> # <** hun skal takke Herren for de velgjerninger hvormed han i Kana'an mottar henne.> 
28:2:16: Og det skal skje på den dag, sier Herren, at du skal rope: Min mann! Og du skal ikke mere rope til mig: Min Ba'al! 
28:2:17: Og jeg vil ta Ba'alenes navn bort fra hennes munn, og de skal ikke mere nevnes ved navn. 
28:2:18: Og jeg vil den dag gjøre en pakt for dem med markens dyr og himmelens fugler og jordens kryp; og bue og sverd og krig vil jeg sønderbryte og utrydde av landet, og jeg vil la dem bo i trygghet. 
28:2:19: Og jeg vil trolove mig med dig for evig tid; jeg vil trolove mig med dig i rettferdighet og rett, i miskunhet og barmhjertighet, 
28:2:20: og jeg vil trolove mig med dig i trofasthet, og du skal kjenne Herren. 
28:2:21: Og det skal skje på den dag at jeg vil bønnhøre, sier Herren, jeg vil bønnhøre himmelen, og den skal bønnhøre jorden, 
28:2:22: og jorden skal bønnhøre kornet og mosten og oljen, og de skal bønnhøre Jisre'el. 
28:2:23: Og jeg vil plante henne* for mig i landet og miskunne mig over Lo-Ruhama, og jeg vil si til Lo-Ammi: Du er mitt folk, og det skal svare: Min Gud! # <* Israel.> 
28:3:1: Og Herren sa til mig: Gå atter bort og elsk en kvinne som er elsket av sin venn og allikevel driver hor, likesom Herren elsker Israels barn, men de vender sig til andre guder og elsker rosinkaker*! # <* d.e. den lokkende avgudsdyrkelse.> 
28:3:2: Da kjøpte jeg mig en slik kvinne for femten sekel sølv og en homer bygg og en letek* bygg. # <* en letek var en halv homer.> 
28:3:3: Og jeg sa til henne: I mange dager skal du få sitte alene, uten å drive hor og uten å ha med nogen mann å gjøre; og jeg skal bære mig likedan at mot dig. 
28:3:4: For i mange dager skal Israels barn sitte uten konge og fyrste, uten offer og billedstøtte, uten livkjortel og husguder. 
28:3:5: Derefter skal Israels barn vende om og søke Herren sin Gud og David sin konge, og bevende skal de søke Herren og hans gaver i de siste dager*. # <* 1MO 49, 1.> 
28:4:1: Hør Herrens ord, I Israels barn! For Herren har sak med dem som bor i landet, fordi det ingen sannhet og ingen kjærlighet og ingen gudskunnskap finnes i landet. 
28:4:2: De sverger og lyver, myrder og stjeler og driver hor. De farer frem med vold, og mord følger på mord. 
28:4:3: Derfor skal landet visne, og alt det som bor der, skal vansmekte, både markens dyr og himmelens fugler, endog havets fisker skal utryddes. 
28:4:4: Allikevel må ingen gå i rette med nogen annen eller refse ham; for ditt folk er lik dem som tretter med en prest. 
28:4:5: Du skal omkomme om dagen, og profeten skal omkomme med dig om natten, og jeg vil tilintetgjøre din mor. 
28:4:6: Mitt folk går til grunne fordi det ikke har kunnskap; fordi du har forkastet kunnskapen, forkaster jeg dig, så du ikke skal tjene mig som prest; du glemte din Guds lov, derfor vil også jeg glemme dine barn. 
28:4:7: Jo flere de blev, dess mere syndet de mot mig; deres ære vil jeg skifte om til skam. 
28:4:8: Av mitt folks synd lever de, og efter deres misgjerning higer de*. # <* Prestene nærer sig av syndofferne og ønsker derfor at folket skal synde rett meget.> 
28:4:9: Derfor skal det gå med presten som med folket, og jeg vil hjemsøke ham for hans ferd og gi ham like for hans gjerninger. 
28:4:10: De skal ete og ikke bli mette, de skal drive utukt og ikke utbrede sig; for de har holdt op å akte på Herren. 
28:4:11: Hor og vin og most tar forstanden bort. 
28:4:12: Mitt folk spør sitt trestykke til råds, og dets kjepp gir det svar; for utuktens ånd har forvillet dem, så de driver hor og ikke vil stå under sin Gud. 
28:4:13: På fjelltoppene ofrer de, og på haugene brenner de røkelse under eker og popler og terebinter, fordi skyggen der er god; derfor driver eders døtre hor, og eders sønnekoner gjør sig skyldige i ekteskapsbrudd. 
28:4:14: Jeg vil ikke hjemsøke eders døtre for deres hor, eller eders sønnekoner for deres ekteskapsbrudd; for mennene går selv avsides med horkvinnene og ofrer med skjøgene. Således går det uforstandige folk til grunne. 
28:4:15: Om enn du, Israel, driver hor, så må ikke Juda gjøre sig skyldig i sådant! Gå ikke til Gilgal og dra ikke op til Bet-Aven* og sverg ikke**: Så sant Herren lever! # <* d.e. Betel; HSE 10, 5. 15. AMO 5, 5. 1KG 12, 29.> # <** på samme tid; AMO 8, 14. SEF 1, 5.> 
28:4:16: For Israel er som en ustyrlig ku; men nu skal Herren la dem beite som lam på den vide mark. 
28:4:17: Efra'im er bundet til avgudsbilleder; la ham fare! 
28:4:18: Deres rus er forbi; utukt har deres skjold* drevet, høit har de elsket det som skammelig er. # <* d.e. høvdinger; SLM 47, 10.> 
28:4:19: Et stormvær griper dem med sine vinger*, og de skal bli til skamme med sine offer. # <* Israel skal bortføres av den assyriske konge.> 
28:5:1: Hør dette, I prester, og gi akt, du Israels hus, og du kongehus, vend øret til! For eder gjelder dommen; for en snare har I vært på Mispa og et utspent nett på Tabor. 
28:5:2: De er falt dypt i en mangfoldighet av forvillelser; men jeg skal tukte dem alle. 
28:5:3: Jeg kjenner Efra'im, og Israel er ikke skjult for mig; for nu har du drevet utukt, Efra'im! Israel er blitt urent. 
28:5:4: Deres gjerninger tillater dem ikke å vende om til sin Gud; for de har en utuktens ånd i sitt indre, og Herren kjenner de ikke. 
28:5:5: Og Israels stolthet skal vidne mot det like i dets åsyn, og Israel og Efra'im skal omkomme for sin misgjernings skyld; også Juda skal omkomme med dem. 
28:5:6: Med sine får og okser skal de gå for å søke Herren, men ikke finne ham; han har dradd sig bort fra dem. 
28:5:7: Mot Herren har de båret sig troløst at, for de har født uekte barn; nu skal nymånen* fortære både dem og alt det de eier. # <* som de feiret på hedensk vis.> 
28:5:8: Støt i basun i Gibea, i trompet i Rama! Blås alarm i Bet-Aven*! Fienden er efter dig, Benjamin! # <* HSE 4, 15.> 
28:5:9: Efra'im skal bli til en ørken på straffens dag; blandt Israels stammer har jeg kunngjort hvad sikkert vil skje. 
28:5:10: Judas fyrster er blitt lik dem som flytter markeskjell; over dem vil jeg utøse min harme som vann. 
28:5:11: Efra'im blir undertrykt, knust ved dom; for han fant for godt å følge menneskers bud. 
28:5:12: Og jeg er som møll for Efra'im og som råttenhet for Judas hus. 
28:5:13: Da Efra'im så sin sykdom og Juda sitt sår, da gikk Efra'im til Assur og sendte bud til kong Jareb*; men han skal ikke kunne helbrede eder, og eders sår skal ikke bli lægt. # <* den stridbare, Assyrias konge; HSE 10, 6.> 
28:5:14: For jeg er som en løve mot Efra'im og som en ungløve mot Judas hus; selv sønderriver jeg og går min vei; jeg bærer byttet bort, og det er ingen som frelser. 
28:5:15: Jeg vil gå min vei, jeg vil vende tilbake til mitt sted, inntil de erkjenner sig skyldige og søker mitt åsyn; i sin trengsel skal de lete efter mig. 
28:6:1: Kom, la oss vende om til Herren! For det er han som har sønderrevet, men han vil også læge oss; han slo, men han vil også forbinde oss. 
28:6:2: Han vil gjøre oss levende efter to dager; på den tredje dag vil han opreise oss, og vi skal leve for hans åsyn. 
28:6:3: Så la oss lære å kjenne Herren, la oss med iver søke å lære ham å kjenne! Hans opgang er så viss som morgenrøden, og han kommer til oss som regnet, som et vårregn som væter jorden. 
28:6:4: Hvad skal jeg gjøre med dig, Efra'im? Hvad skal jeg gjøre med dig, Juda? For eders kjærlighet er som en morgensky, lik duggen som tidlig går bort. 
28:6:5: Derfor har jeg hugget løs på dem ved profetene, drept dem ved min munns ord, og dommene over dig fór ut som et lyn. 
28:6:6: For jeg har lyst til kjærlighet og ikke til slaktoffer, og til gudskunnskap mere enn til brennoffer. 
28:6:7: Men de har brutt pakten, likesom Adam; der har de vært troløse mot mig. 
28:6:8: Gilead er en by med ugjerningsmenn, full av blodspor. 
28:6:9: Og lik en røverskare som lurer på folk, er hele presteflokken; de myrder på veien til Sikem; ja, skammelige ting har de gjort. 
28:6:10: I Israels hus har jeg sett grufulle ting; der driver Efra'im hor, der er Israel blitt urent. 
28:6:11: Også for dig, Juda, er det fastsatt en høst, når jeg gjør ende på mitt folks fangenskap. 
28:7:1: Når jeg vil læge Israel, da åpenbares Efra'ims misgjerning og Samarias ondskap; for de farer med svik, og tyver bryter sig inn, og røverskarer plyndrer utenfor. 
28:7:2: Og de tenker ikke på at jeg kommer all deres ondskap i hu; nu har deres gjerninger omringet dem, de er kommet for mitt åsyn. 
28:7:3: Ved sin ondskap gleder de kongen og ved sine løgner fyrstene. 
28:7:4: Alle sammen er de horkarler; de ligner en ovn som ophetes av bakeren; han holder bare op med å ilde fra deigen eltes til den er syret. 
28:7:5: På vår konges dag drikker fyrstene sig syke av den hete vin; han rekker spottere hånden. 
28:7:6: For de gjør sitt hjerte så hett som en ovn mens de lurer; hele natten sover deres baker*, om morgenen brenner det som luende ild. # <* deres lidenskap.> 
28:7:7: Alle sammen blir de hete som en ovn og fortærer sine dommere; alle deres konger er falt, det er ingen iblandt dem, som kaller på mig. 
28:7:8: Efra'im blander sig med folkene; Efra'im er blitt en kake som ikke er vendt. 
28:7:9: Fremmede har fortært hans kraft, men han vet det ikke; hans hår er alt gråsprengt, men han vet det ikke. 
28:7:10: Og Israels stolthet vidner mot ham like i hans åsyn; men de vender ikke om til Herren sin Gud og søker ham ikke til tross for alt dette. 
28:7:11: Men Efra'im er blitt som en enfoldig due, uten forstand; på Egypten kaller de, til Assur går de. 
28:7:12: Så snart de går, vil jeg spenne ut mitt nett over dem; som himmelens fugler vil jeg dra dem ned; jeg vil tukte dem, som det allerede er forkynt for deres menighet. 
28:7:13: Ve dem, at de flyktet bort fra mig! Ødeleggelse over dem, fordi de er falt fra mig! Jeg vilde forløse dem, men de talte løgn imot mig. 
28:7:14: De roper ikke til mig fra sitt hjerte, men ligger og hyler på sitt leie; de trenger sig sammen for å få korn og most, men fra mig vender de sig bort. 
28:7:15: Det er jeg som har lært dem op, og som har styrket deres armer; men mot mig har de ondt i sinne. 
28:7:16: De vender sig, men ikke mot det høie; de er blitt som en sviktende bue. Deres fyrster skal falle for sverdet for sin rasende tunges skyld; dette blir dem til spott i Egyptens land. 
28:8:1: Sett basunen for din munn*! - Som en ørn kommer fienden over Herrens hus, fordi de har brutt pakten med mig og forsyndet sig mot min lov. # <* JES 58, 1.> 
28:8:2: De skal rope til mig: Min Gud! Vi israelitter kjenner dig. 
28:8:3: Israel har støtt det gode fra sig - fienden skal forfølge ham. 
28:8:4: De har valgt sig konger, som ikke kom fra mig, de har satt inn fyrster, uten at jeg visste om det. Av sitt sølv og gull har de gjort sig avgudsbilleder, så de skulde bli utryddet. 
28:8:5: Motbydelig er din kalv, Samaria! Min vrede er optendt mot dem. Hvor lenge skal renhet synes dem utålelig? 
28:8:6: For et verk av Israel er den, en kunstner har gjort den, og den er ikke nogen gud; ja, til splinter skal den bli Samarias kalv. 
28:8:7: For vind sår de, og storm skal de høste; deres korn får ikke aks, deres grøde gir ikke føde; om den gir noget, skal fremmede opsluke det. 
28:8:8: Israel er opslukt; nu er de blandt folkene lik en ting som ingen bryr sig om; 
28:8:9: for de drog op til Assur lik et villesel, som går sine egne veier; Efra'im tinger om elskov; 
28:8:10: men om de enn tinger blandt folkene, vil jeg nu samle dem dit, og de skal begynne å bli færre under storkongens byrder. 
28:8:11: Fordi Efra'im har gjort sig så mange alter til å synde med, er de blitt ham alter til synd. 
28:8:12: Om jeg enn skriver ham mine lover i tusentall, så blir de allikevel aktet for noget fremmed. 
28:8:13: Som offergaver til mig ofrer de kjøtt som de selv eter; Herren har ikke behag i dem. Nu vil han komme deres misgjerning i hu og hjemsøke dem for deres synder - de skal vende tilbake til Egypten. 
28:8:14: Israel glemte sin skaper og bygget sig palasser, og Juda bygget mange faste byer; men jeg vil sende ild mot hans byer, og den skal fortære hans palasser. 
28:9:1: Gled dig ikke, Israel, og juble ikke, likesom folkene! For du har forlatt din Gud i hor; du har elsket horelønn på hvert sted hvor kornet treskes. 
28:9:2: Treskeplassen og vinpersen skal ikke kunne fø dem, og mosten skal slå feil for dem. 
28:9:3: De skal ikke bli boende i Herrens land; men Efra'im skal vende tilbake til Egypten, og i Assur skal de ete det som er urent. 
28:9:4: De skal ikke ofre vin til drikkoffer for Herren, og deres slaktoffer skal ikke behage ham; som sorgens brød skal de være for dem; alle som eter det, skal bli urene; for sitt brød har de for sig selv, det kommer ikke i Herrens hus. 
28:9:5: Hvad vil I gjøre på høitidsdagen, på Herrens festdag? 
28:9:6: For de drar bort for ødeleggelses skyld; Egypten skal samle dem, Memfis begrave dem; deres sølvsmykker skal nesler ta i eie, torner skal vokse i deres telt. 
28:9:7: Kommet er hjemsøkelsens dager, kommet er gjengjeldelsens dager; Israel skal få merke det: En dåre er profeten, avsindig er åndens mann, fordi din misgjerning er så stor og ditt fiendskap så sterkt. 
28:9:8: Efra'im ser sig om efter andre guder ved siden av min Gud; profeten er en fuglefangersnare på alle hans veier, bare fiendskap i hans Guds hus. 
28:9:9: De er sunket dypt i fordervelse, som i Gibeas dager; han skal komme deres misgjerning i hu, han skal hjemsøke dem for deres synder. 
28:9:10: Som druer i ørkenen fant jeg Israel, som den tidligste frukt på et fikentre i dets første tid så jeg eders fedre; men da de kom til Ba'al-Peor, vidde de sig til avgudsdyrkelsen og blev vederstyggelige likesom den de elsket. 
28:9:11: Efra'ims herlighet skal flyve bort som en fugl. Ingen fødsel, intet fruktsommelig morsliv, ingen undfangelse! 
28:9:12: Ja, selv om de opfør sine barn, vil jeg gjøre dem barnløse, så intet menneske blir tilbake; for ve dem når jeg forlater dem! 
28:9:13: Efra'im er, som om jeg så bort til Tyrus, plantet på en eng; men Efra'im må føre sine barn ut til bøddelen. 
28:9:14: Gi dem, Herre! - Ja, hvad skal du gi dem? Gi dem morsliv som føder i utide, og bryster som er uttørket! 
28:9:15: All deres ondskap er samlet i Gilgal, ja, der har jeg fattet hat til dem; for deres onde gjerningers skyld vil jeg drive dem ut av mitt hus; jeg vil ikke elske dem mere, alle deres førere er oprørere. 
28:9:16: Efra'im er ormstukket, deres rot er blitt tørr, frukt skal de ikke bære; om de enn føder, vil jeg drepe deres dyre livsfrukt. 
28:9:17: Min Gud skal forkaste dem, fordi de ikke har hørt på ham, og de skal vanke om blandt folkene som flyktninger. 
28:10:1: Israel er et frodig vintre, som bærer frukt; jo mere frukt han fikk, desto flere alter bygget han; jo bedre det gikk hans land, desto vakrere billedstøtter gjorde han sig. 
28:10:2: Deres hjerte er falskt, nu skal de bøte; han* skal bryte ned deres altere og ødelegge deres billedstøtter; # <* Herren.> 
28:10:3: ja, nu skal de si: Vi har ingen konge; for vi har ikke fryktet Herren, og kongen, hvad kan vel han gjøre for oss? 
28:10:4: De har talt tomme ord, svoret falsk, inngått forbund, og retten skyter op som giftige urter på markens furer. 
28:10:5: For Bet-Avens* kalv er Samarias innbyggere i angst; ja, dens** folk sørger over den, og dens avgudsprester skjelver for den - for dens herlighet, fordi den er ført bort fra dem. # <* d.e. Betels; HSE 10, 15.> # <** kalvens, avgudens.> 
28:10:6: Også den skal føres til Assur, som en gave til kong Jareb*; skam skal Efra'im høste, og Israel skal skamme sig over sitt råd. # <* HSE 5, 13.> 
28:10:7: Samarias konge skal bli borte som en spån på vannets flate. 
28:10:8: Og Avens offerhauger, Israels synd, skal tilintetgjøres; torner og tistler skal skyte op på deres altere, og de skal si til fjellene: Skjul oss! og til haugene: Fall over oss! 
28:10:9: Fra Gibeas dager av har du syndet, Israel! Der* er de blitt stående, uten at krigen mot nidingene har nådd dem i Gibea. # <* i gibe'ittenes synd.> 
28:10:10: Efter min lyst vil jeg tukte dem, og folkeslag skal samles imot dem, når jeg binder dem fast til begge deres misgjerninger. 
28:10:11: Efra'im er en opøvd kvige, som gjerne vil treske, men jeg legger et åk på dens fagre hals; jeg vil spenne Efra'im for, Juda skal pløie, Jakob skal harve. 
28:10:12: Så eder en sæd, som rettferdigheten krever, få eder en høst efter kjærlighetens bud, bryt eder nytt land! For nu er det tiden til å søke Herren, til han kommer og lar rettferdighet regne over eder. 
28:10:13: I har pløid ugudelighet, I har høstet urett, I har ett løgnens frukt; for du har satt din lit til din ferd og til dine mange helter. 
28:10:14: Derfor skal det reise sig et krigsbulder blandt dine stammer, og alle dine festninger skal ødelegges, likesom Salman* ødela Bet-Arbel på stridens dag, da både mor og barn blev knust. # <* kanskje Salmanassar.> 
28:10:15: Sådant skal Betel føre over eder for eders store ondskaps skyld; når det lysner til dag, er det ute, aldeles ute med Israels konge*. # <* Hosea; 2KG 17, 6 fg.> 
28:11:1: Da Israel var ung, hadde jeg ham kjær, og fra Egypten kalte jeg min sønn. 
28:11:2: Jo mere de* kalte på dem, dess mere gikk de bort fra dem; de ofrer til Ba'alene og brenner røkelse for de utskårne billeder. # <* profetene.> 
28:11:3: Og det var da jeg som lærte Efra'im å gå og tok dem på mine armer; men de skjønte ikke at jeg lægte dem. 
28:11:4: Med menneskebånd drog jeg dem, med kjærlighets rep, og jeg var for dem som de som løfter op åket over kjevene, og jeg gav ham føde. 
28:11:5: Han skal ikke vende tilbake til Egyptens land, men Assur skal være hans konge; for de vilde ikke vende om. 
28:11:6: Sverdet skal fare om i hans byer og tilintetgjøre hans bommer og ete om sig - for deres onde råds skyld; 
28:11:7: for mitt folk henger fast ved sitt frafall fra mig, og kaller nogen dem til det høie, er det ingen av dem som løfter sitt øie opad. 
28:11:8: Hvorledes skal jeg kunne gi dig op, Efra'im, gi dig til pris, Israel? Hvorledes skal jeg kunne gi dig op som Adma, gjøre med dig som med Sebo'im? Mitt hjerte vender sig i mig, all min medynk våkner. 
28:11:9: Jeg vil ikke fullbyrde min brennende vrede, jeg vil ikke atter ødelegge Efra'im; for jeg er Gud og ikke et menneske, den Hellige i din midte; jeg kommer ikke med glødende harme. 
28:11:10: De skal følge Herren; han skal brøle som en løve; ja, han skal brøle, og bevende skal hans barn komme fra havet; 
28:11:11: bevende skal de komme som en fugl fra Egypten, som en due fra Assurs land, og jeg vil la dem bo i sine hus, sier Herren. 
28:12:1: Efra'im har omringet mig med løgn, og Israels hus med svik; og Juda er ennu gjenstridig mot Gud, mot den trofaste Hellige. 
28:12:2: Efra'im higer efter vind, han jager efter østenvær; hele dagen dynger han op løgn og ødeleggelse; de inngår pakt med Assur, de fører olje til Egypten. 
28:12:3: Herren har trette med Juda, og han skal hjemsøke Jakob for hans ferds skyld, betale ham efter hans gjerninger. 
28:12:4: I mors liv holdt han sin bror i hælen, og i sin manndomskraft kjempet han med Gud. 
28:12:5: Han kjempet med engelen og vant; han gråt og bad ham om nåde; i Betel møtte han ham, og der talte han med oss. 
28:12:6: Og Herren, hærskarenes Gud - Herren er det navn han skal kalles med. 
28:12:7: Og du - til din Gud skal du vende om; hold fast ved miskunnhet og rett, og bi stadig på din Gud! 
28:12:8: I Kana'ans* hånd er det falsk vekt; å gjøre urett er hans lyst. # <* Israel lignes med det kana'anittiske handelsfolk.> 
28:12:9: Og Efra'im sier: Ja, jeg er blitt rik, har vunnet mig gods; men i alt mitt strev skal ingen kunne finne nogen urett - nogen synd. 
28:12:10: Men jeg er Herren din Gud fra Egyptens land; jeg vil ennu la dig bo i telt likesom i høitidsdagene. 
28:12:11: Jeg har talt til profetene, og jeg har latt dem se syner i mengde, og gjennem profetene har jeg talt i lignelser. 
28:12:12: Er Gilead fullt av misgjerninger, så skal de også bli rent til intet; har de ofret okser i Gilgal, så skal og deres altere bli som stenrøser langsmed markens furer. 
28:12:13: Jakob flyktet til Arams land*, Israel tjente for å vinne en hustru - for å vinne en hustru voktet han får. # <* d.e. Mesopotamia.> 
28:12:14: Men ved en profet førte Herren Israel op fra Egypten, og ved en profet voktet han det. 
28:12:15: Efra'im har vakt bitter harme; derfor skal hans Herre la hans blodskyld komme over ham, og hans hån skal han gjengjelde ham. 
28:13:1: Når Efra'im talte, blev alle redde; han raget høit op i Israel; da førte han skyld over sig ved å dyrke Ba'al og døde. 
28:13:2: Og nu blir de ved å synde og gjøre sig støpte billeder av sitt sølv, avguder efter sin egen forstand, alle sammen håndverkeres arbeid; sådanne er det de taler til - mennesker som ofrer, kysser kalver! 
28:13:3: Derfor skal de bli som en morgensky, lik duggen som tidlig svinner bort, lik agner som vinden fører bort fra treskeplassen, og som røk fra et røkfang. 
28:13:4: Men jeg er Herren din Gud fra Egyptens land, og nogen annen Gud enn mig kjenner du ikke, og nogen annen frelser finnes det ikke. 
28:13:5: Det var jeg som sørget for dig i ørkenen, i det brennhete land. 
28:13:6: Fordi deres beite var godt, blev de mette; og da de blev mette, ophøiet de sig i sitt hjerte, og så glemte de mig. 
28:13:7: Da blev jeg mot dem som en løve; som en leopard lurer jeg ved veien; 
28:13:8: jeg vil møte dem som en bjørn som ungene er tatt fra, og sønderrive deres hjertes dekke*; jeg vil fortære dem som en løvinne; markens ville dyr skal sønderslite dem. # <* d.e. deres bryst.> 
28:13:9: Det er blitt til din ødeleggelse, Israel, at du har satt dig op imot mig, jeg som er din hjelp. 
28:13:10: Hvor er da din konge, at han skulde kunne frelse dig i alle dine byer, og dine dommere, om hvem du sa: Gi mig en konge og fyrster? 
28:13:11: Jeg gir dig en konge i min vrede, og jeg tar ham bort igjen i min harme. 
28:13:12: Efra'ims misgjerning er samlet i en pung, hans synd er gjemt. 
28:13:13: Veer som hos en fødende kvinne skal komme over ham. Han er en uforstandig sønn; når tiden er der, kommer han ikke frem i modermunnen. 
28:13:14: Av dødsrikets vold vil jeg fri dem ut, fra døden vil jeg forløse dem. Død! Hvor er din pest? Dødsrike! Hvor er din sott? Anger er skjult for mine øine*. # <* jeg angrer ikke hvad jeg har lovt; 4MO 23, 19.> 
28:13:15: For han bærer frukt*, der han står blandt sine brødre; men det kommer en østenvind, et Herrens vær, som drar op fra ørkenen, og hans brønn blir tom, og hans kilde tørkes ut; den** røver den hele skatt av kostelige ting. # <* 1MO 48, 19; 49, 22.> # <** østenvinden, d.e. assyrerne; HSE 4, 19.> 
28:14:1: Samaria skal bøte fordi det har vært gjenstridig mot sin Gud; for sverdet skal de falle, deres små barn skal knuses, og deres fruktsommelige kvinner opskjæres. 
28:14:2: Vend om, Israel, til Herren din Gud! For du er falt ved din misgjerning. 
28:14:3: Kom med [ydmyke] ord og vend om til Herren! Si til ham: Forlat all misgjerning og ta imot det gode vi kan yde - la oss betale med okser, med våre leber*! # <* d.e. med vår takk og pris som offer; SLM 51, 19; 119, 108.> 
28:14:4: Assur skal ikke hjelpe oss, på hester vil vi ikke ride, og vi vil ikke mere si til våre henders verk: Vår Gud! For det er hos dig den farløse finner miskunnhet. 
28:14:5: Jeg vil læge deres frafall, jeg vil elske dem av hjertet; for min vrede har vendt sig fra dem. 
28:14:6: Jeg vil være som dugg for Israel; han skal blomstre som en lilje, og han skal slå røtter som skogen på Libanon. 
28:14:7: Hans skudd skal brede sig vidt utover, og hans løv skal skinne som oljetreet, og dufte skal han som Libanon. 
28:14:8: De som sitter i hans skygge, skal atter avle korn og blomstre som vintreet; minnet om ham skal være som Libanons vin. 
28:14:9: Efra'im! Hvad har jeg mere med avguder å gjøre? Jeg vil bønnhøre ham og se til ham; jeg er som en grønn cypress, det skal vise sig at din frukt kommer fra mig. 
28:14:10: Hvem er vis, så han skjønner dette, forstandig, så han merker sig det? For Herrens veier er rette, og de rettferdige ferdes på dem, men overtrederne snubler der. 
29:1:1: Herrens ord som kom til Joel, Petuels sønn: 
29:1:2: Hør dette, I gamle! Gi akt, alle som bor i landet! Er sådant skjedd i eders dager eller i eders fedres dager? 
29:1:3: I skal fortelle om det til eders barn, og eders barn til sine barn, og deres barn til en kommende slekt. 
29:1:4: Hvad gnageren* har levnet, har vrimleren* ett, og hvad vrimleren har levnet, har slikkeren* ett, og hvad slikkeren har levnet, har skaveren* ett. # <* forskjellige navn på gresshopper.> 
29:1:5: Våkn op, I drukne, og gråt, og jamre, alle vindrikkere, fordi mosten er revet bort fra eders munn. 
29:1:6: For et folk har draget op over mitt land sterkt og talløst; dets tenner er som en løves tenner, og det har jeksler som en løvinne. 
29:1:7: Det har rent ødelagt mine vintrær og knekket mine fikentrær; det har gjort dem aldeles bare og kastet dem bort; deres grener er blitt hvite. 
29:1:8: Klag som en jomfru som bærer sørgedrakt for sin ungdoms brudgom! 
29:1:9: Matoffer og drikkoffer er revet bort fra Herrens hus; prestene, Herrens tjenere, sørger. 
29:1:10: Marken er ødelagt, jorden sørger; for kornet er ødelagt, mosten er tørket bort, oljen er svunnet inn. 
29:1:11: Akerdyrkerne er skuffet, vingårdsmennene jamrer sig; for hveten og bygget, markens grøde, er gått tapt. 
29:1:12: Vintreet er tørket bort, og fikentreet er visnet; granatepletreet og palmen og epletreet, alle markens trær er tørket bort; ja, all fryd er svunnet bort fra menneskenes barn. 
29:1:13: Klæ eder i sørgedrakt og klag, I prester! Jamre eder, I som gjør tjeneste ved alteret! Gå inn og sitt hele natten i sørgedrakt, I min Guds tjenere! For eders Guds hus må savne matoffer og drikkoffer. 
29:1:14: Tillys en hellig faste, utrop en festforsamling, samle de eldste, ja alle som bor i landet, til Herrens, eders Guds hus og rop til Herren! 
29:1:15: Ve oss, for en dag! For Herrens dag er nær og kommer som en ødeleggelse fra den Allmektige. 
29:1:16: Er ikke maten blitt borte for våre øine, glede og fryd fra vår Guds hus? 
29:1:17: Sædekornene er tørket inn under mulden som dekker dem; forrådshusene er ødelagt, ladene nedbrutt, for kornet er fordervet. 
29:1:18: Hvor buskapen stønner! Oksehjordene farer redde omkring, for det finnes intet beite for dem; også fårehjordene må lide. 
29:1:19: Til dig, Herre, roper jeg; for ild har fortært ørkenens beitemarker, og luer har forbrent alle markens trær. 
29:1:20: Endog markens dyr skriker op til dig; for bekkene er uttørket, og ild har fortært ørkenens beitemarker. 
29:2:1: Støt i basun på Sion og blås alarm på mitt hellige berg, alle som bor i landet, beve! For Herrens dag kommer - den er nær, 
29:2:2: en dag med mørke og mulm, en dag med skyer og skodde, utbredt over fjellene som morgenrøde - et stort og sterkt folk, som det ikke har vært make til fra fordums tid og heller ikke siden kommer make til gjennem årene, fra slekt til slekt. 
29:2:3: Foran det fortærer ild, og efter det brenner lue; som Edens have er landet foran det, og efter det er det en øde ørken, og det er intet som slipper unda det. 
29:2:4: Som hester er det å se til, og som ryttere løper de avsted. 
29:2:5: Det lyder som larm av vogner når de hopper over fjelltoppene, det lyder som når ildsluen fortærer halm, de er som et sterkt folk, rustet til krig. 
29:2:6: Folkeslag skjelver for det; alle ansikter blir blussende røde*. # <* JES 13, 8.> 
29:2:7: Som helter løper de avsted, som krigsmenn stiger de op på murene; de drar frem hver sin vei og bøier ikke av fra sine stier. 
29:2:8: De trenger ikke hverandre til side; de går frem hver på sin egen vei; mellem kastespyd styrter de frem og stanser ikke i sitt løp. 
29:2:9: I byen vanker de om, på muren løper de, i husene stiger de op, gjennem vinduene går de inn som tyver. 
29:2:10: Foran dem skjelver jorden og ryster himmelen; sol og måne sortner, og stjernene holder op å lyse. 
29:2:11: Og Herren lar sin røst høre foran sin fylking, for hans hær er såre stor, og sterk er den som fullbyrder hans ord; for stor er Herrens dag og såre forferdelig - hvem kan utholde den? 
29:2:12: Men endog nu, sier Herren, vend om til mig med hele eders hjerte og med faste og gråt og klage, 
29:2:13: og sønderriv eders hjerte og ikke eders klær, og vend om til Herren eders Gud! For han er nådig og barmhjertig, langmodig og rik på miskunnhet, og han angrer det onde. 
29:2:14: Hvem vet? Han torde vende om og angre og la en velsignelse bli igjen efter sig, til matoffer og drikkoffer for Herren eders Gud. 
29:2:15: Støt i basun på Sion, tillys en hellig faste, utrop en festforsamling! 
29:2:16: Samle folket, tillys en hellig sammenkomst, kall de gamle sammen, samle de små barn, endog dem som dier morsbryst! La brudgommen gå ut av sitt rum og bruden av sitt kammer! 
29:2:17: Mellem forhallen og alteret skal prestene, Herrens tjenere, stå gråtende og si: Spar, Herre, ditt folk, og overgi ikke din arv til vanære, så hedninger får råde over den! Hvorfor skal de si blandt folkene: Hvor er deres Gud? 
29:2:18: Da blir Herren nidkjær for sitt land, og han sparer sitt folk. 
29:2:19: Og Herren svarer og sier til sitt folk: Se, jeg sender eder korn og most og olje, så I blir mette; og jeg vil ikke mere overgi eder til vanære blandt hedningene. 
29:2:20: Og fienden fra Norden vil jeg drive langt bort fra eder og jage ham avsted til et tørt og øde land, hans fortropp til havet i øst og hans baktropp til havet i vest; og det skal stige op en stank fra ham, en motbydelig lukt; for altfor store ting har han tatt sig fore. 
29:2:21: Frykt ikke, du land! Fryd dig og vær glad! For store ting har Herren gjort. 
29:2:22: Frykt ikke, I markens dyr! For ørkenens beitemarker grønnes, trærne bærer sin frukt, fikentreet og vintreet gir sin kraft. 
29:2:23: Og I, Sions barn, fryd og gled eder i Herren eders Gud! For han gir eder læreren til rettferdighet; og så sender han regn ned til eder, høstregn og vårregn, først*. # <* JOE 3, 1.> 
29:2:24: Treskeplassene blir fulle av korn, og persekarene flyter over av most og olje. 
29:2:25: Og jeg godtgjør eder de år da vrimleren åt op alt, og slikkeren og skaveren og gnageren, min store hær, som jeg sendte mot eder. 
29:2:26: Og I skal ete og bli mette og prise Herrens, eders Guds navn, han som har stelt så underfullt med eder, og mitt folk skal aldri i evighet bli til skamme. 
29:2:27: Og I skal kjenne at jeg bor midt i Israel, og at jeg er Herren eders Gud, og ingen annen; og mitt folk skal aldri i evighet bli til skamme. 
29:3:1: Og derefter skal det skje at jeg vil utgyde min Ånd over alt kjød, og eders sønner og eders døtre skal tale profetiske ord; eders oldinger skal ha drømmer, eders unge menn skal se syner; 
29:3:2: ja, endog over trælene og over trælkvinnene vil jeg i de dager utgyde min Ånd. 
29:3:3: Og jeg vil gjøre underfulle tegn på himmelen og på jorden: blod og ild og røkstøtter. 
29:3:4: Solen skal omskiftes til mørke, og månen til blod, før Herrens dag kommer, den store og forferdelige. 
29:3:5: Og det skal skje: Hver den som påkaller Herrens navn, skal bli frelst; for på Sions berg og i Jerusalem skal det være en flokk av undkomne, således som Herren har sagt, og blandt de undslopne skal de være som Herren kaller. 
29:3:6: For se, i de dager og på den tid, når jeg gjør ende på Judas og Jerusalems fangenskap, 
29:3:7: da vil jeg samle alle hedningefolk og føre dem ned i Josafats* dal, og jeg vil der gå i rette med dem for mitt folks og min arvs, Israels skyld, fordi de spredte dem blandt hedningene og delte mitt land; # <* Josafat betyr: Gud dømmer; JOE 3, 17. 19.> 
29:3:8: om mitt folk kastet de lodd, de gav en gutt for en skjøge, de solgte en pike for vin, og den drakk de op. 
29:3:9: Og I, Tyrus og Sidon og alle Filisterlands bygder! Hvad vil mig? Er det noget I vil gjengjelde mig, eller vil I gjøre mig noget? Snart, i en hast, skal jeg la eders gjerning falle tilbake på eders eget hode, 
29:3:10: I som tok mitt sølv og gull og førte mine dyreste skatter bort til eders templer, 
29:3:11: og Judas barn og Jerusalems barn solgte I til Javans barn for å få dem langt bort fra sitt land. 
29:3:12: Se, jeg kaller dem fra det sted som I har solgt dem til, og lar eders gjerning falle tilbake på eders eget hode. 
29:3:13: Og jeg vil selge eders sønner og døtre til Judas barn, og de skal selge dem til sabeerne, et folk som bor langt borte; for Herren har talt. 
29:3:14: Rop dette ut blandt hedningefolkene, rust eder til en hellig krig, kall på heltene, la alle krigsmenn stige frem og dra ut! 
29:3:15: Smi eders hakker om til sverd og eders vingårdskniver til spyd! Den veke si: Jeg er en helt! 
29:3:16: Skynd eder og kom, alle I hedningefolk fra alle kanter, og samle eder sammen! Dit la du, Herre, dine helter* stige ned! # <* SLM 103, 20.> 
29:3:17: Hedningefolkene skal våkne op og dra til Josafats dal; for der vil jeg sitte og dømme alle hedningefolk fra alle kanter. 
29:3:18: Send sigden ut, for høsten er moden! Kom og tred vinpersen, for den er full, persekarene flyter over, deres ondskap er stor! 
29:3:19: Skare på skare samler sig i avgjørelsens dal. For nær er Herrens dag i avgjørelsens dal. 
29:3:20: Sol og måne sortner, og stjernene holder op å lyse. 
29:3:21: Og Herren skal brøle* fra Sion og la sin røst høre fra Jerusalem, og himmel og jord skal skjelve; men Herren er en tilflukt for sitt folk og et vern for Israels barn. # <* som en løve; sml. HSE 11, 10. AMO 1, 2 fg. 3, 8.> 
29:3:22: Og I skal kjenne at jeg er Herren eders Gud, som bor på Sion, mitt hellige berg; og Jerusalem skal være et hellig sted, og fremmede skal ikke mere trenge inn der. 
29:3:23: Og det skal skje på den dag at fjellene skal dryppe av most, og haugene flyte over av melk, og alle bekker i Juda strømme med vann; og det skal utgå en kilde fra Herrens hus og vanne Sittims dal. 
29:3:24: Egypten skal bli til en ødemark og Edom til en øde ørken for deres vold mot Judas barn, fordi de har utøst uskyldig blod i sitt land. 
29:3:25: Men Juda skal bli til evig tid, og Jerusalem fra slekt til slekt. 
29:3:26: Og jeg vil hevne deres blod, det som jeg ikke før har hevnet. Og Herren bor på Sion. 
30:1:1: De ord som Amos, en av hyrdene fra Tekoa, mottok i sine syner om Israel i de dager da Ussias var konge i Juda og Jeroboam, Joas' sønn, konge i Israel, to år før jordskjelvet. 
30:1:2: Han sa: Herren skal brøle fra Sion og la sin røst høre fra Jerusalem, og hyrdenes beitemarker skal sørge, og Karmels topp bli tørr. 
30:1:3: Så sier Herren: For tre misgjerninger av Damaskus, ja for fire vil jeg ikke ta det* tilbake - fordi de tresket Gilead med treskesleder av jern**; # <* hvad jeg har besluttet; AMO 1, 4. 5.> # <** 2SA 12, 31. JES 28, 27. 28; 41, 15.> 
30:1:4: men jeg vil sende ild mot Hasaels hus, og den skal fortære Benhadads palasser, 
30:1:5: og jeg vil sønderbryte Damaskus' portbom og utrydde dem som bor i Avens dal, og den som bærer kongestaven, i Bet-Eden; og Syrias folk skal bortføres til Kir, sier Herren. 
30:1:6: Så sier Herren: For tre misgjerninger av Gasa, ja for fire vil jeg ikke ta det tilbake - fordi de bortførte alt folket som fanger og overgav dem til Edom; 
30:1:7: men jeg vil sende ild mot Gasas murer, og den skal fortære dets palasser, 
30:1:8: og jeg vil utrydde dem som bor i Asdod, og den som bærer kongestaven, i Askalon, og jeg vil vende min hånd mot Ekron, og det som er igjen av filistrene, skal gå til grunne, sier Herren, Israels Gud. 
30:1:9: Så sier Herren: For tre misgjerninger av Tyrus, ja for fire vil jeg ikke ta det tilbake - fordi de overgav alt folket som fanger til Edom og ikke kom brorpakten i hu; 
30:1:10: men jeg vil sende ild mot Tyrus' murer, og den skal fortære dets palasser. 
30:1:11: Så sier Herren: For tre misgjerninger av Edom, ja for fire vil jeg ikke ta det tilbake - fordi han forfulgte sin bror med sverd og kvalte sin barmhjertighet, og hans vrede stadig sønderrev, og han alltid holdt på sin harme; 
30:1:12: men jeg vil sende ild mot Teman, og den skal fortære Bosras palasser. 
30:1:13: Så sier Herren: For tre misgjerninger av Ammons barn, ja for fire vil jeg ikke ta det tilbake - fordi de skar op de fruktsommelige kvinner i Gilead for å utvide sitt landemerke; 
30:1:14: men jeg vil stikke ild på Rabbas murer, og den skal fortære dets palasser, under hærskrik på stridens dag, i storm på uværets dag, 
30:1:15: og deres konge skal føres bort som fange, både han og hans fyrster, sier Herren. 
30:2:1: Så sier Herren: For tre misgjerninger av Moab, ja for fire vil jeg ikke ta det tilbake - fordi de brente Edom-kongens ben til kalk; 
30:2:2: men jeg vil sende ild mot Moab, og den skal fortære Kerijots palasser, og Moab skal dø under krigsbulder, under hærskrik, under basunens lyd, 
30:2:3: og jeg vil utrydde dommeren av deres land, og alle dets fyrster vil jeg drepe sammen med ham, sier Herren. 
30:2:4: Så sier Herren: For tre misgjerninger av Juda, ja for fire vil jeg ikke ta det tilbake - fordi de foraktet Herrens lov og ikke holdt hans bud, og deres løgnguder, som deres fedre hadde fulgt, førte dem vill; 
30:2:5: men jeg vil sende ild mot Juda, og den skal fortære Jerusalems palasser. 
30:2:6: Så sier Herren: For tre misgjerninger av Israel, ja for fire vil jeg ikke ta det tilbake - fordi de selger den uskyldige for penger og den fattige for et par sko, 
30:2:7: de som higer efter å se støv på de ringes hode og bøier retten for de saktmodige. En mann og hans far går til samme pike, så de vanhelliger mitt hellige navn. 
30:2:8: På pantsatte klær strekker de sig ved hvert alter, og vin som de har tatt som bøter, drikker de i sin Guds hus. 
30:2:9: Og jeg utryddet da amorittene foran dem, de som var høie som sedertrær og sterke som eketrær, og jeg ødela deres frukt oventil og deres røtter nedentil, 
30:2:10: og jeg førte eder op fra Egyptens land, og jeg ledet eder i ørkenen i firti år, forat I skulde få amorittens land til eiendom, 
30:2:11: og jeg opvakte nogen av eders sønner til profeter, og nogen av eders unge menn til nasireere. Er det ikke så, I Israels barn? sier Herren. 
30:2:12: Men I fikk nasireerne til å drikke vin, og I forbød profetene å profetere. 
30:2:13: Se, jeg vil knuge eder ned, likesom en vogn full av kornbånd knuger allting ned; 
30:2:14: den raske skal intet tilfluktssted finne, og den sterke ikke kunne gjøre bruk av sin kraft, og helten ikke berge sitt liv; 
30:2:15: bueskytteren skal ikke holde stand, den som er lett på foten, skal ikke berge sitt liv, og heller ikke rytteren på sin hest, 
30:2:16: den modigste iblandt heltene skal flykte naken på den dag, sier Herren. 
30:3:1: Hør dette ord som Herren har talt mot eder, Israels barn, mot hele den ætt jeg har ført op fra Egyptens land: 
30:3:2: Eder alene har jeg villet kjennes ved blandt alle jordens ætter; derfor vil jeg hjemsøke eder for alle eders misgjerninger. 
30:3:3: Går vel to sammen uten at de har avtalt det med hverandre? 
30:3:4: Brøler vel løven i skogen uten at den har funnet noget rov? Lar vel ungløven sin røst høre fra sin hule uten at den har fanget noget? 
30:3:5: Faller vel fuglen i en snare på marken uten at det er satt nogen snare for den? Spretter vel snaren op fra marken uten at den fanger noget? 
30:3:6: Eller støtes det vel i basun i en by uten at folket forferdes? Eller skjer det vel en ulykke i en by uten at Herren har gjort det? 
30:3:7: For Herren, Israels Gud, gjør ikke noget uten at han har åpenbaret sitt lønnlige råd for sine tjenere profetene. 
30:3:8: Når løven brøler, hvem skulde ikke da reddes? Når Herren, Israels Gud, taler, hvem skulde ikke da profetere? 
30:3:9: Rop ut i palassene i Asdod og i palassene i Egyptens land og si: Samle eder på Samaria-fjellene og se hvor stor forvirring det er der inne, og hvor megen undertrykkelse der råder! 
30:3:10: De vet ikke å gjøre det som rett er, sier Herren, de som dynger op vold og ødeleggelse i sine palasser. 
30:3:11: Derfor sier Herren, Israels Gud, så: Fiender skal komme og omringe hele landet. Og de skal styrte ned din borg, så du mister ditt vern, og dine palasser skal bli plyndret. 
30:3:12: Så sier Herren: Likesom en hyrde berger to leggeben eller en ørelapp av løvens gap, således skal Israels barn berges, de som sitter i Samaria i sofahjørnet og på damaskes hynder. 
30:3:13: Hør og vidne mot Jakobs hus, sier Herren, Israels Gud, hærskarenes Gud: 
30:3:14: På den dag jeg hjemsøker Israel for dets misgjerninger, da vil jeg hjemsøke Betels altere, så alterhornene blir avhugget og faller til jorden, 
30:3:15: og jeg vil sønderslå både vinterhusene og sommerhusene, og elfenbenshusene skal gå til grunne, og mange hus rives bort, sier Herren. 
30:4:1: Hør dette ord, I Basan-kyr på Samarias fjell, I som undertrykker de ringe, som knuser de fattige, som sier til eders menn: Kom med vin, så vi får drikke! 
30:4:2: Herren, Israels Gud, har svoret ved sin hellighet: Se, dager kommer over eder da I skal slepes bort med haker, og den siste levning av eder med fiskekroker, 
30:4:3: og I skal gå ut gjennem murbruddene, hver rett frem for sig, og I skal bli slengt bort i palasset*, sier Herren. # <* seierherrens palass.> 
30:4:4: Gå til Betel og synd, til Gilgal og synd til gagns, og bær hver morgen frem eders slaktoffer, hver tredje dag eders tiender, 
30:4:5: og la røk av syret brød stige op som takkoffer og tillys frivillige offer og kunngjør det! For så vil I jo gjerne ha det, I Israels barn, sier Herren, Israels Gud. 
30:4:6: Og jeg har da latt eder gå med tom munn i alle eders byer og latt eder mangle brød i alle eders hjem; men I har ikke omvendt eder til mig, sier Herren. 
30:4:7: Jeg har forholdt eder regnet da det ennu var tre måneder igjen til høsten, og har latt det regne over en by, men ikke over en annen; et stykke land fikk regn, og et annet stykke blev fortørket, fordi det ikke kom regn på det, 
30:4:8: og to, tre byer ravet avsted til en og samme by for å drikke vann, men fikk ikke nok; men I har ikke omvendt eder til mig, sier Herren. 
30:4:9: Jeg har slått eder med kornbrand og rust; gresshoppene åt op eders mange haver og vingårder og fikentrær og oljetrær; men I har ikke omvendt eder til mig, sier Herren. 
30:4:10: Jeg har sendt pest blandt eder likesom dengang i Egypten, jeg har drept eders unge menn med sverdet og latt eders hester bli hærfang, og jeg lot stanken fra eders hær stige op i eders nese; men I har ikke omvendt eder til mig, sier Herren. 
30:4:11: Jeg har omstyrtet byer iblandt eder, således som Gud omstyrtet Sodoma og Gomorra, og I blev som en brand som er revet ut av ilden; men I har ikke omvendt eder til mig, sier Herren. 
30:4:12: Derfor vil jeg gjøre med dig, Israel, så som jeg har sagt*. Og fordi jeg vil gjøre således med dig, så gjør dig rede til å møte din Gud, Israel! # <* AMO 2, 13 fg. 3, 11 fg. 4, 2 fg.> 
30:4:13: For se, han som danner fjellene og skaper vinden og åpenbarer menneskene deres tanker, han som gjør morgenrøden til mørke og farer frem over jordens høider - Herren, hærskarenes Gud, er hans navn. 
30:5:1: Hør dette ord, en klagesang som jeg istemmer over eder, Israels hus! 
30:5:2: Hun er falt, hun skal aldri reise sig mere, jomfruen Israel; hun ligger nedkastet på sin egen grunn, det er ingen som reiser henne op. 
30:5:3: For så sier Herren, Israels Gud: Den by som tusen drar ut av, skal ha hundre igjen, og den by som hundre drar ut av, skal ha ti igjen, i Israels hus. 
30:5:4: For så sier Herren til Israels hus: Søk mig, så skal I leve! 
30:5:5: Søk ikke til Betel og kom ikke til Gilgal og dra ikke over til Be'erseba! For Gilgal skal bli bortført, og Betel bli til intet. 
30:5:6: Søk Herren, så skal I leve! Ellers skal han komme over Josefs hus som en ild, og den skal fortære uten at Betel har nogen som slukker! 
30:5:7: De som forvender retten til malurt og kaster rettferdigheten til jorden! 
30:5:8: Han som har skapt Syvstjernen og Orion og omskifter dødsskygge til morgen og gjør dagen mørk som natten, han som kaller på havets vann og øser dem ut over jorden - Herren er hans navn! 
30:5:9: Han som lar ødeleggelse lyne frem mot den sterke og fører ødeleggelse over den faste borg! 
30:5:10: På tinge hater de den som hevder retten, og de avskyr den som taler sannhet. 
30:5:11: Derfor, fordi I treder på den fattige og tar avgift i korn av ham, så skal I ikke få bo i de hus av huggen sten som I selv har bygget, og ikke få drikke vin fra de herlige vingårder som I selv har plantet. 
30:5:12: For jeg vet at eders overtredelser er mange og eders synder tallrike; I forfølger den uskyldige, tar imot løsepenger* og bøier retten for de fattige på tinge. # <* 4MO 35, 31. 2MO 23, 6.> 
30:5:13: Derfor, den som er klok, han tier i denne tid; for det er en ond tid. 
30:5:14: Søk det gode og ikke det onde, så I får leve. Da skal Herren, hærskarenes Gud, være med eder, således som I har sagt. 
30:5:15: Hat det onde og elsk det gode og la retten stå fast på tinge! Kanskje Herren, hærskarenes Gud, da vil være nådig mot Josefs levning. 
30:5:16: Derfor sier Herren, Israels Gud, hærskarenes Gud, så: På alle gater skal det høres klagerop, og i alle streder skal de rope: Ve, ve! Bonden skal kalles til sørgehøitid, og til dem som er kyndige i sørgekveder, skal de si: Syng en sørgesang! 
30:5:17: Og i alle vingårder skal det høres klagerop; for jeg vil skride frem midt iblandt eder, sier Herren. 
30:5:18: Ve dem som stunder efter Herrens dag! Hvad vil I da med Herrens dag? Den er mørke og ikke lys. 
30:5:19: Det er som når en flykter for en løve, men støter på en bjørn, og når han kommer hjem og støtter sig til veggen med hånden, blir han bitt av en orm. 
30:5:20: Ja, Herrens dag er mørke og ikke lys, belgmørk og uten lysskjær. 
30:5:21: Jeg hater og forakter eders høitider, og jeg har ikke behag i eders festforsamlinger; 
30:5:22: for om I ofrer mig brennoffer og matoffer, finner jeg ikke behag i dem, og eders takkoffer av gjøkalver ser jeg ikke på. 
30:5:23: La mig slippe for dine larmende sanger! Jeg vil ikke høre på ditt harpespill. 
30:5:24: Men dommen skal komme veltende som vann, og rettferdigheten som en alltid strømmende bekk. 
30:5:25: Bar I frem for mig slaktoffer og matoffer i ørkenen i de firti år, Israels hus? 
30:5:26: Nei, I bar eders konges* telt og eders billeders fotstykke, eders guds stjerne, som I hadde gjort eder. # <* d.e. Moloks.> 
30:5:27: Jeg vil føre eder bort i fangenskap, langt bortenfor Damaskus, sier han hvis navn er Herren, hærskarenes Gud. 
30:6:1: Ve de trygge på Sion og de sorgløse på Samarias fjell, de fornemme blandt det ypperste av folkene, dem som Israels hus pleier å vende sig til! 
30:6:2: Dra over til Kalne og se, og gå videre til det store Hamat, og stig ned til Gat i Filisterland! Er de bedre enn disse riker, eller er deres land større enn eders land? 
30:6:3: I som jager den onde dag langt bort og flytter urettens sete nær til eder*, # <* I vil intet høre om straffens dag, og allikevel lar I vold og urett råde blandt eder.> 
30:6:4: I som ligger på elfenbensbenker og dovner eder på eders leier, og som eter lam av hjorden og kalver fra fjøset, 
30:6:5: som synger allslags tull til harpens toner og har uttenkt eder strengeinstrumenter, likesom David, 
30:6:6: som drikker vin av skåler og salver eder med den ypperste olje, men ikke sørger over Josefs skade. 
30:6:7: Derfor skal de nu vandre ut av landet fremst blandt dem som føres bort, og med skrålet av dem som dovner sig, skal det være slutt. 
30:6:8: Herren, Israels Gud, har svoret ved sig selv, sier Herren, hærskarenes Gud: Jeg avskyr Jakobs stolthet og hater hans palasser, og jeg vil overgi byen og alt det som er i den. 
30:6:9: Og om det er ti menn tilbake i ett hus, da skal de dø. 
30:6:10: Og en manns nærmeste frende, som skal brenne ham*, skal ta ham og bære benene ut av huset, og han skal si til den som er i husets innerste krok: Er det ennu nogen hos dig? Og han skal svare: Nei, ingen! Da skal han si: Hysj! Herrens navn må ikke nevnes! # <* fordi mengden av de døde (AMO 8, 3) gjør det umulig å begrave dem.> 
30:6:11: For se, Herren byder, og de slår det store hus så det synker i grus, og det lille hus så det revner. 
30:6:12: Springer vel hestene opefter fjellet, eller pløies det der med okser? siden I har forvendt retten til gift og rettferdighetens frukter til malurt. 
30:6:13: I som gleder eder over det som intet er, og som sier: Er det ikke ved vår egen styrke vi har vunnet oss velde? 
30:6:14: For se, jeg opvekker et folk mot eder, Israels hus, sier Herren, hærskarenes Gud, og de skal undertrykke eder fra der hvor veien går til Hamat, og like til bekken i ødemarken. 
30:7:1: Dette syn lot Herren, Israels Gud, mig se: Det var en som skapte gresshopper, da håen begynte å skyte op; det var håen efter kongens slått. 
30:7:2: Og da de vel hadde ett op alle urter i landet, da sa jeg: Herre, Israels Gud, tilgi! Hvorledes skal Jakob kunne bli i live, han som er så liten? 
30:7:3: Da angret Herren det. Det skal ikke skje, sa Herren. 
30:7:4: Så lot Herren, Israels Gud, mig se dette syn: Herren, Israels Gud, kalte på ilden til straff, og den fortærte det store vanndyp, og den holdt på å fortære hans arvelodd. 
30:7:5: Da sa jeg: Herre, Israels Gud, hold op! Hvorledes skal Jakob kunne bli i live, han som er så liten? 
30:7:6: Da angret Herren det. Heller ikke det skal skje, sa Herren, Israels Gud. 
30:7:7: Så lot han mig se dette syn: Herren stod på en loddrett mur, og i hånden hadde han et blylodd. 
30:7:8: Og Herren sa til mig: Hvad ser du, Amos? Jeg svarte: Et blylodd. Da sa Herren: Se, jeg vil bruke et blylodd iblandt mitt folk Israel; jeg vil ikke lenger bære over med dets synd. 
30:7:9: Isaks offerhauger skal ødelegges, og Israels helligdommer synke i grus, og jeg vil reise mig med sverdet mot Jeroboams hus. 
30:7:10: Da sendte Amasja, presten i Betel, bud til Jeroboam, Israels konge, og lot si: Amos har fått i stand en sammensvergelse mot dig midt i Israels hus; landet kan ikke tåle alt det han sier. 
30:7:11: For så har Amos sagt: Jeroboam skal dø for sverdet, og Israel skal bli bortført fra sitt land. 
30:7:12: Og Amasja sa til Amos: Gå din vei, du seer, fly til Juda land og et ditt brød der; der kan du være profet! 
30:7:13: Men i Betel skal du ikke mere holde på å profetere; for det er en kongelig helligdom og et rikstempel. 
30:7:14: Da svarte Amos Amasja således: Jeg er ikke nogen profet, heller ikke disippel av nogen profet; jeg er bare en fehyrde, som sanker frukten av morbærtreet. 
30:7:15: Men Herren tok mig fra hjorden, og Herren sa til mig: Gå og vær profet for mitt folk Israel! 
30:7:16: Så hør nu Herrens ord! Du sier: Du skal ikke profetere mot Israel og ikke tale mot Isaks hus. 
30:7:17: Derfor sier Herren så: Din hustru skal drive hor i byen, og dine sønner og døtre skal falle for sverdet, og din jord skal deles med målesnor, og du selv skal dø i et urent land, og Israel skal bli bortført fra sitt land. 
30:8:1: Så lot Herren, Israels Gud, mig se dette syn: Der stod en kurv med moden frukt. 
30:8:2: Og han sa: Hvad ser du, Amos? Jeg svarte: En kurv med moden frukt. Da sa Herren til mig: Enden er kommet for mitt folk Israel; jeg vil ikke lenger bære over med dets synd. 
30:8:3: Palassets sanger skal bli til klageskrik på den dag, sier Herren, Israels Gud; der ligger lik i mengde, overalt kastes de til side i stillhet. 
30:8:4: Hør dette, I som higer efter å opsluke den fattige og gjøre ende på de saktmodige i landet, 
30:8:5: I som sier: Når er nymånedagen slutt, så vi kan selge korn, og sabbaten, så vi kan åpne kornbodene og gjøre efaen liten og sekelen stor og forfalske vekten 
30:8:6: og kjøpe de ringe for penger og den fattige for et par sko og selge avfall av korn? 
30:8:7: Herren har svoret ved Jakobs stolthet: Jeg skal aldri glemme alt det de har gjort. 
30:8:8: Må ikke derfor jorden skjelve, og alle som bor på den, sørge? Må ikke hele jorden heve sig som Nilen, stige og falle som Egyptens elv? 
30:8:9: Og det skal skje på den dag, sier Herren, Israels Gud, at jeg vil la solen gå ned om middagen og gjøre det mørkt på jorden ved høilys dag. 
30:8:10: Og jeg vil omskifte eders høitider til sorg og alle eders sanger til klage, jeg vil legge sekk om alle lender og la hvert hode bli skallet; jeg vil gjøre det så som når en sørger over sin eneste sønn, og la enden bli som en ulykkens dag. 
30:8:11: Se, dager kommer, sier Herren, Israels Gud, da jeg sender hunger i landet, ikke hunger efter brød og ikke tørst efter vann, men efter å høre Herrens ord. 
30:8:12: Da skal de vanke om fra hav til hav og fra nord til øst; de skal flakke omkring og søke efter Herrens ord, men de skal ikke finne det. 
30:8:13: På den dag skal de fagre jomfruer og de unge menn vansmekte av tørst, 
30:8:14: de som sverger ved Samarias synd* og sier: Så sant din gud lever, Dan, og så sant han lever han de dyrker i Be'erseba! Og de skal falle og ikke reise sig mere. # <* avgud; 5MO 9, 21.> 
30:9:1: Jeg så Herren stå ved alteret, og han sa: Slå til søilehodene, så dørtersklene skjelver, og knus dem, så de faller ned over hodet på dem alle! Den siste levning av dem vil jeg drepe med sverdet; ingen av dem skal kunne undfly, ingen av dem komme unda. 
30:9:2: Om de bryter sig inn i dødsriket, så skal min hånd hente dem derfra, og om de farer op til himmelen, skal jeg styrte dem ned derfra; 
30:9:3: om de skjuler sig på Karmels topp, skal jeg lete dem op og hente dem derfra, og om de gjemmer sig for mine øine på havets bunn, skal jeg kalle ormen frem, og den skal bite dem, 
30:9:4: og om de føres i fangenskap av sine fiender, skal jeg der byde sverdet å slå dem ihjel; jeg skal la mitt øie hvile på dem, til det onde og ikke til det gode. 
30:9:5: Og Herren, Israels Gud, hærskarenes Gud, han rører ved jorden, da smelter den, og alle som bor på den, sørger, og hele jorden hever sig som Nilen og faller som Egyptens elv, 
30:9:6: Han har bygget sine høie saler i himmelen og grunnfestet sin hvelving over jorden, han kaller på havets vann og øser dem ut over jorden; Herren er hans navn. 
30:9:7: Er I mere verd for mig enn etiopernes barn, I Israels barn? sier Herren; har jeg ikke ført Israel op fra Egyptens land og filistrene fra Kaftor og syrerne fra Kir? 
30:9:8: Se, Herrens, Israels Guds øine er vendt mot dette syndige rike, og jeg vil utslette det av jorden; men jeg vil ikke aldeles utslette Jakobs hus, sier Herren. 
30:9:9: For se, jeg byder at Israels ætt skal rystes blandt alle folkeslag, likesom en ryster med et sold, og ikke et korn faller til jorden. 
30:9:10: For mitt sverd skal de dø alle syndere blandt mitt folk, de som sier: Ulykken skal ikke nå oss eller kommer over oss. 
30:9:11: På den dag vil jeg reise op igjen Davids falne hytte, og jeg vil mure igjen dens revner og reise op det som er nedbrutt av den, og jeg vil bygge den op igjen som i fordums dager, 
30:9:12: så at de får ta i eie det som er igjen av Edom, og alle de hedningefolk som kalles med mitt navn, sier Herren, han som gjør dette. 
30:9:13: Se, dager kommer, sier Herren, da den som pløier, skal nå den som høster, og den som trår vindruer, skal nå den som kaster ut sæden, og fjellene skal dryppe av most, og alle haugene skal flyte over. 
30:9:14: Og jeg vil gjøre ende på mitt folk Israels fangenskap, og de skal bygge op igjen de ødelagte byer og bo der og plante vingårder og drikke deres vin og dyrke op haver og ete deres frukt. 
30:9:15: Og jeg vil plante dem i deres land, og de skal aldri mere rykkes op av sitt land, det som jeg har gitt dem, sier Herren din Gud. 
31:1:1: Obadias' syn. Så sier Herren, Israels Gud, om Edom: En tidende har vi hørt fra Herren, og et bud er sendt ut blandt hedningefolkene: Stå op og la oss reise oss til strid mot det! 
31:1:2: Se, liten vil jeg gjøre dig blandt hedningefolkene; du skal bli dypt foraktet. 
31:1:3: Ditt hjertes overmot har dåret dig, du som bor i fjellkløfter, i din høie bolig, du som sier i ditt hjerte: Hvem vil styrte mig ned til jorden? 
31:1:4: Om du bygger høit som ørnen og setter ditt rede blandt stjerner, vil jeg styrte dig ned derfra, sier Herren. 
31:1:5: Om tyver kom til dig eller røvere om natten - å, hvor du blir tilintetgjort! - mon de da vilde stjele mere enn det de trengte? Om det kom til dig folk som vilde høste din vin, vilde de da ikke levne en efterhøst? 
31:1:6: Men hvor blir ikke Esau ransaket, og hans skjulte skatter opsøkt! 
31:1:7: Like til grensen følger alle dine forbundsfeller dig; dine gode venner sviker dig og får overhånd over dig; de menn som eter ditt brød, legger en snare for dig. Det er ingen forstand hos ham! 
31:1:8: Skal jeg ikke på den dag, sier Herren, gjøre ende på alle vismenn i Edom og all forstand på Esaus berg? 
31:1:9: Dine kjemper, Teman, skal bli motløse, så hver mann blir drept og utryddet på Esaus berg. 
31:1:10: For den vold du har gjort mot din bror Jakob, skal skam dekke dig, og du skal bli utryddet til evig tid. 
31:1:11: Den dag da du holdt dig unda, den dag da fremmede bortførte hans gods, og utlendinger gikk inn i hans porter og kastet lodd om Jerusalem, da var også du som en av dem. 
31:1:12: Se ikke med skadefryd på din brors dag, på hans ulykkes dag, og gled dig ikke over Judas barn på deres undergangs dag, og lukk ikke din munn så vidt op på trengselens dag! 
31:1:13: Dra ikke inn gjennem mitt folks port den dag de er i nød, se ikke også du med skadefryd på deres ulykke den dag de er i nød, og legg ikke hånd på deres gods den dag de er i nød, 
31:1:14: og stå ikke på veiskjellet for å utrydde dem av mitt folk som har sloppet unda, og overgi ikke dets flyktninger til fienden på trengselens dag! 
31:1:15: For nær er Herrens dag over alle folkene; som du har gjort, skal det gjøres med dig, dine gjerninger skal falle tilbake på ditt eget hode. 
31:1:16: For likesom I har drukket på mitt hellige berg, skal alle folkene drikke uten å holde op; de skal drikke i fulle drag og bli som om de aldri hadde vært til. 
31:1:17: Men på Sions berg skal det være en flokk av undslopne, og det skal være hellig; og Jakobs hus skal ta sine eiendommer i eie. 
31:1:18: Og Jakobs hus skal bli en ild, og Josefs hus en lue, og Esaus hus skal bli til halm, og de skal sette ild på det og fortære det, og det skal ikke bli nogen tilbake av Esaus hus, for Herren har talt. 
31:1:19: Og de som bor i sydlandet, skal ta Esaus berg i eie, og de som bor i lavlandet, skal ta filistrenes land, og de skal ta Efra'ims land og Samarias land i eie, og Benjamin skal ta Gilead, 
31:1:20: og de av denne Israels hær som er bortført, skal ta det som finnes av kana'anitter like til Sarepta, og de bortførte fra Jerusalem som er i Sefarad, skal ta sydlandets byer i eie. 
31:1:21: Og frelsere skal dra op på Sions berg og dømme Esaus berg, og riket skal høre Herren til. 
32:1:1: Herrens ord kom til Jonas, Amittais sønn, og det lød så: 
32:1:2: Stå op, gå til Ninive, den store stad, og tal for den! For deres ondskap er steget op og er kommet for mitt åsyn. 
32:1:3: Men Jonas stod op og vilde fly til Tarsis, bort fra Herrens åsyn; og han drog ned til Joppe og fant der et skib som skulde gå til Tarsis, og han betalte frakten og gikk ombord for å fare med dem til Tarsis, bort fra Herrens åsyn. 
32:1:4: Men Herren sendte en sterk vind ut over havet, og det blev en stor storm på havet, og skibet var i ferd med å knuses. 
32:1:5: Da blev skibsfolkene redde og ropte hver til sin gud, og de kastet de ting som var i skibet, ut i havet for å lette skibet. Men Jonas var steget ned i skibets nederste rum og lå i fast søvn. 
32:1:6: Skibsføreren gikk da til ham og sa: Hvorledes kan du sove så fast? Stå op og rop til din gud! Kanskje den gud vil tenke på råd for oss, så vi ikke går under. 
32:1:7: Og de sa til hverandre: Kom, la oss kaste lodd, så vi kan få vite hvem som er skyld i at denne ulykke har hendt oss! Så kastet de lodd, og loddet falt på Jonas. 
32:1:8: Da sa de til ham: Si oss hvem som er skyld i at denne ulykke har hendt oss! Hvad er ditt ærend, og hvor kommer du fra? Hvad land er du fra, og hvad folk hører du til? 
32:1:9: Han svarte: Jeg er en hebreer, og jeg frykter Herren, himmelens Gud, ham som har gjort havet og det tørre land. 
32:1:10: Da blev mennene grepet av en stor frykt, og de sa til ham: Hvorfor har du gjort dette? For de visste at han flydde fra Herrens åsyn; det hadde han fortalt dem. 
32:1:11: Og de sa til ham: Hvad skal vi gjøre med dig, forat havet kan legge sig for oss? For havet blev mere og mere oprørt. 
32:1:12: Han svarte: Ta mig og kast mig i havet! Så vil havet legge sig for eder; for jeg vet at det er for min skyld denne store storm er kommet over eder. 
32:1:13: Mennene prøvde nu å ro tilbake til land, men de maktet det ikke; for sjøen slo sterkere og sterkere mot dem. 
32:1:14: Da ropte de til Herren og sa: Å Herre, la oss ikke gå under fordi denne mann skal dø, og la ikke uskyldig blod komme over oss! For du, Herre, har gjort som du vilde. 
32:1:15: Så tok de Jonas og kastet ham i havet; da holdt havet op å rase. 
32:1:16: Og mennene blev grepet av stor frykt for Herren, og de ofret takkoffer til Herren og gjorde løfter. 
32:2:1: Men Herren lot en stor fisk komme og sluke Jonas, og Jonas var i fiskens buk tre dager og tre netter. 
32:2:2: Og Jonas bad til Herren sin Gud fra fiskens buk 
32:2:3: og sa: Jeg kalte på Herren i min nød, og han svarte mig; fra dødsrikets skjød ropte jeg, du hørte min røst. 
32:2:4: Du kastet mig i dypet, midt i havet, og vannstrømmer omgav mig; alle dine brenninger og dine bølger gikk over mig. 
32:2:5: Jeg tenkte: Jeg er støtt bort fra dine øine. Men jeg skal atter skue op til ditt hellige tempel. 
32:2:6: Vannene omringet mig like til sjelen, dypet omgav mig, tang innhyllet mitt hode, 
32:2:7: til fjellenes grunnvoller sank jeg ned, jordens bommer var lukket efter mig for evig. Men du førte mitt liv op av graven, Herre min Gud! 
32:2:8: Da min sjel vansmektet i mig, kom jeg Herren i hu, og min bønn kom til dig i ditt hellige tempel. 
32:2:9: De som holder sig til de tomme avguder, de forlater sin miskunnhet*. # <* sin miskunnelige Gud; SLM 144, 2.> 
32:2:10: Men jeg vil ofre til dig med takksigelses røst; det jeg har lovt, vil jeg holde; frelsen hører Herren til. 
32:2:11: Så spydde fisken på Herrens bud Jonas ut på det tørre land. 
32:3:1: Og Herrens ord kom annen gang til Jonas, og det lød så: 
32:3:2: Stå op, gå til Ninive, den store stad, og rop ut i den de ord som jeg vil tale til dig! 
32:3:3: Og Jonas stod op og gikk til Ninive efter Herrens ord. Men Ninive var en stor stad for Gud, tre dagsreiser lang. 
32:3:4: Og Jonas gikk en dagsreise inn i byen og ropte: Om firti dager skal Ninive bli omstyrtet. 
32:3:5: Da trodde mennene i Ninive på Gud, og de ropte ut en faste og klædde sig i sekk, både store og små. 
32:3:6: Da saken kom for Ninives konge, stod han op fra sin trone og la sin kappe av sig og svøpte sekk om sig og satte sig i asken. 
32:3:7: Og han lot rope ut i Ninive: Efter kongens og hans stormenns påbud må hverken mennesker eller dyr, storfe eller småfe, smake nogen ting, ikke nyte føde og ikke drikke vann! 
32:3:8: Men de skal svøpe sig i sekk, både mennesker og dyr, og de skal rope til Gud med kraft og vende om, hver fra sin onde vei og fra den urett som henger ved deres hender. 
32:3:9: Hvem vet? Gud kunde da vende om og angre det, vende om fra sin brennende vrede, så vi ikke forgår. 
32:3:10: Da nu Gud så det de gjorde, at de vendte om fra sin onde vei, angret han det onde han hadde sagt han vilde gjøre mot dem, og han gjorde det ikke. 
32:4:1: Men det syntes Jonas meget ille om, og hans vrede optendtes. 
32:4:2: Og han bad til Herren og sa: Å Herre! Var det ikke det jeg sa da jeg ennu var i mitt land? Derfor flydde jeg dengang til Tarsis; for jeg visste at du er en nådig og barmhjertig Gud, langmodig og rik på miskunnhet og angrer det onde. 
32:4:3: Så ta nu, Herre, mitt liv! For jeg vil heller dø enn leve. 
32:4:4: Men Herren sa: Er det med rette din vrede er optendt? 
32:4:5: Jonas var gått ut av byen; han hadde satt sig østenfor byen, og der hadde han gjort sig en løvhytte og satt under den i skyggen for å se hvorledes det gikk med byen. 
32:4:6: Da lot Gud Herren et kikajontre vokse op over Jonas til å skygge over hans hode, så han kunde bli fri sitt mismot; og Jonas gledet sig høilig over kikajontreet. 
32:4:7: Men dagen efter, da morgenen brøt frem, lot Gud en orm komme, som stakk kikajontreet så det visnet. 
32:4:8: Og da solen stod op, sendte Gud en lummer østenvind, og solen stakk Jonas' hode, så han vansmektet; da ønsket han sig døden og sa: Jeg vil heller dø enn leve. 
32:4:9: Men Gud sa til Jonas: Er det med rette din vrede er optendt for kikajontreets skyld? Han svarte: Ja, med rette er min vrede optendt inntil døden. 
32:4:10: Da sa Herren: Du ynkes over kikajontreet, som du ikke har hatt møie med og ikke opelsket, som blev til på en natt og forgikk på en natt; 
32:4:11: og jeg skulde ikke ynkes over Ninive, den store stad, hvor det er mere enn tolv ganger ti tusen mennesker som ikke kjenner forskjell mellem høire og venstre, og en mengde dyr! 
33:1:1: Dette er Herrens ord som kom til Mika fra Moreset i de dager da Jotam, Akas og Esekias var konger i Juda, det som han skuet om Samaria og Jerusalem. 
33:1:2: Hør, I folk, alle sammen! Gi akt, du jord med alt som på dig er! Og Herren, Israels Gud, være vidne mot eder, Herren fra sitt hellige tempel! 
33:1:3: For se, Herren går ut fra sin bolig; han stiger ned og farer frem over jordens høider. 
33:1:4: Fjellene smelter under ham, og dalene revner - som voks for ilden, som vann når det øses ut over et stup. 
33:1:5: For Jakobs frafall skjer alt dette, for de synder Israels hus har gjort. Hvem er ophavet til Jakobs frafall? Mon ikke Samaria? Og hvem til Judas offerhauger? Mon ikke Jerusalem? 
33:1:6: Så vil jeg da gjøre Samaria til en grusdynge på marken, til et sted for vindyrkning, og jeg vil velte dets stener ned i dalen og avdekke dets grunnvoller. 
33:1:7: Og alle dets utskårne billeder skal sønderslås, og alle dets horegaver brennes op med ild, og alle dets avgudsbilleder vil jeg ødelegge; for av horelønn har det samlet dem, og til horelønn skal de atter bli. 
33:1:8: Over dette vil jeg klage og hyle, jeg vil gå barbent og naken; jeg vil opløfte klageskrik som sjakalene og jamre mig som strutsene. 
33:1:9: For dets sår er ulægelige; for ulykken er kommet like til Juda, den har nådd like til mitt folks port, til Jerusalem. 
33:1:10: Forkynn det ikke i Gat, gråt ikke der! I Bet-Leafra velter jeg mig i støvet. 
33:1:11: Dra bort*, I Sjafirs innbyggere, i nakenhet og vanære! Sa'anans innbyggere våger sig ikke ut**. Bet-Haesels klageskrik gjør det umulig for eder å bli der. # <* i landflyktighet.> # <** JER 6, 25.> 
33:1:12: For Marots innbyggere vrir sig i smerte over sin tapte lykke; for ulykke er faret ned fra Herren, like til Jerusalems port. 
33:1:13: Spenn traveren for vognen, I innbyggere av Lakis! Syndens ophav var du* for Sions datter, for hos dig blev Israels overtredelser funnet. # <* Lakis. JOS 15, 39.> 
33:1:14: Derfor skal du gi avkall på Moreset-Gat; husene i Aksib blir som en sviktende bekk for Israels konger. 
33:1:15: Ennu en gang skal jeg la eder få en eiermann, I Maresas innbyggere! Til Adullam skal Israels stormenn komme. 
33:1:16: Gjør dig skallet, klipp håret vekk for dine barns skyld, de som var din lyst! Gjør ditt hode så skallet som gribbens, for de er ført bort fra dig. 
33:2:1: Ve dem som tenker ut urett og emner på ondt mens de ligger på sitt leie! Så snart morgenen gryr, setter de det i verk, fordi det står i deres makt. 
33:2:2: De attrår marker og røver dem, de attrår hus og tar dem, de gjør vold mot mannen og hans hus, mot bonden og hans arvelodd. 
33:2:3: Derfor sier Herren så: Se, jeg tenker ut ondt over denne slekt, ulykker som I ikke skal kunne ryste av eders hals, og I skal ikke kunne gå med høireist hode; for det blir en ond tid. 
33:2:4: På den dag skal folk istemme en spottevise over eder og synge en klagesang. De skal si: Det er forbi, vi er rent ødelagt; mitt folks arvelodd skifter han ut til andre, se, hvorledes han tar den fra mig! Til frafalne* deler han ut våre jorder. # <* d.e. hedninger.> 
33:2:5: Derfor skal du ingen ha som drar målesnor over nogen jordlodd* i Herrens menighet. # <* tilmåler dig en jordlodd.> 
33:2:6: Prek ikke! Så preker de. De må ikke preke om slikt, det blir ingen ende på skjellsord*. # <* de sanne profeter, sier de mektige undertrykkere, må ikke preke om ulykke; deres tale er bare en endeløs rekke av skjellsord.> 
33:2:7: Hvilket ord, du Jakobs hus! Mon Herren er snar til vrede? Eller er det således han pleier å gå frem? Er ikke mine ord gode mot den som vandrer ærlig? 
33:2:8: Men for lenge siden har mitt folk reist sig som min fiende; I drar kappen av folk som går trygge forbi og er fredelig sinnede - så de bare har kjortelen igjen. 
33:2:9: Mitt folks kvinner driver I ut av de hjem som var deres lyst; fra deres små barn tar I for alltid bort den pryd jeg har gitt dem. 
33:2:10: Stå op og dra bort! For her er ikke eders hvilested, for eders urenhets skyld, som volder fordervelse, svær fordervelse. 
33:2:11: Om nogen kom med munnsvær og svik og løi og sa: Jeg vil preke for dig om vin og om sterk drikk - han skulde være en predikant for dette folk. 
33:2:12: Jeg vil samle dig, Jakob, samle eder så mange som I er; sanke sammen vil jeg det som er igjen av Israel, jeg vil føre dem sammen som får i en kve, som en hjord til sitt beite, så det larmer av mennesker. 
33:2:13: Veibryteren går foran dem, de bryter igjennem og drar frem gjennem porten og går ut av den; deres konge drar frem foran dem, og Herren i spissen for dem. 
33:3:1: Og jeg sa: Hør, I Jakobs høvdinger og I dommere for Israels hus! Er det ikke eders sak å vite hvad rett er? 
33:3:2: I som hater det gode og elsker det onde, I som flår huden av dem og kjøttet av deres ben, 
33:3:3: I som eter mitt folks kjøtt og river huden av dem og knuser deres ben og breder dem ut likesom i en gryte og som kjøtt i en panne. 
33:3:4: Da* skal de rope til Herren, men han skal ikke svare dem; han skal skjule sitt åsyn for dem på den tid for deres onde gjerningers skyld! # <* MIK 2, 3.> 
33:3:5: Så sier Herren om de profeter som fører mitt folk vill, som roper når de har noget å tygge med sine tenner: Fred! Men mot den som intet gir dem i deres munn, roper de ut en hellig krig. 
33:3:6: Derfor skal det bli natt for eder uten syner, og mørke uten spådom; solen skal gå ned over profetene, og dagen bli sort over dem. 
33:3:7: Seerne skal skamme sig, og spåmennene blues; de skal tilhylle skjegget* alle sammen; for det kommer intet svar fra Gud. # <* 3MO 13, 45.> 
33:3:8: Men jeg, jeg er full av kraft ved Herrens Ånd og full av rett og styrke til å vidne for Jakob om hans overtredelse og for Israel om hans synd. 
33:3:9: Hør dette, I høvdinger over Jakobs hus og I dommere for Israels hus, I som avskyr rett og gjør det kroket alt det som er bent, 
33:3:10: I som bygger Sion med blod og Jerusalem med urett! 
33:3:11: Dets høvdinger dømmer for gave, dets prester lærer for betaling, og dets profeter spår for penger, og enda stoler de på Herren og sier: Er ikke Herren midt iblandt oss? Det kommer ingen ulykke over oss. 
33:3:12: Derfor skal for eders skyld Sion pløies som en aker, og Jerusalem skal bli til grusdynger, og tempelberget til skogbakker. 
33:4:1: Men det skal skje i de siste dager, da skal fjellet der Herrens hus står, være grunnfestet på toppen av fjellene, og høit hevet skal det være over alle høider; og folkeslag skal strømme op på det. 
33:4:2: Og mange hedningefolk skal gå avsted og si: Kom, la oss gå op til Herrens berg og til Jakobs Guds hus, så han kan lære oss sine veier, og vi ferdes på hans stier! For fra Sion skal lov utgå, og Herrens ord fra Jerusalem. 
33:4:3: Og han skal dømme mellem mange folkeslag og skifte rett for veldige hedningefolk helt til de fjerneste land, og de skal smi sine sverd om til hakker og sine spyd til vingårdskniver; folkene skal ikke lenger løfte sverd mot hverandre og ikke mere lære å føre krig, 
33:4:4: men de skal sitte hver under sitt vintre og sitt fikentre, og ingen skal skremme dem; for Herrens, hærskarenes Guds munn har talt. 
33:4:5: For alle folkene vandrer hvert i sin guds navn; men vi vandrer i Herrens, vår Guds navn evindelig og alltid. 
33:4:6: På den dag, sier Herren, vil jeg samle de haltende og sanke sammen de bortdrevne og dem jeg har faret ille med, 
33:4:7: og jeg vil la de haltende bli i live som en rest og gjøre de langt bortdrevne til et tallrikt folk. Og Herren skal være konge over dem på Sions berg fra nu og til evig tid. 
33:4:8: Og du hjordens tårn*, du Sions datters haug! Til dig skal det nå, ja, det skal komme det forrige herredømme, kongedømmet over Jerusalems datter. # <* kongeborgens tårn.> 
33:4:9: Hvorfor skriker du nu så høit? Er det da ingen konge i dig, eller er din rådgiver omkommet, siden veer har grepet dig som den fødende kvinnes? 
33:4:10: Vri dig og jamre dig, Sions datter, som en fødende kvinne! For nu må du dra ut av byen og bo ute på marken, og du skal komme like til Babel. Der skal du bli utfridd, der skal Herren løse dig ut av dine fienders hånd. 
33:4:11: Nu har mange folkeslag samlet sig mot dig, og de sier: Måtte det bli vanhelliget, så våre øine kan se med lyst på Sion! 
33:4:12: Men de kjenner ikke Herrens tanker og forstår ikke hans råd, at han har samlet dem som kornbånd til treskeplassen. 
33:4:13: Stå op og tresk, du Sions datter! For ditt horn vil jeg gjøre til jern, og dine klover vil jeg gjøre til kobber, og du skal knuse mange folk, og jeg vil lyse deres rov i bann til Herren og deres gods til all jordens herre. 
33:4:14: Nu samler du dig i skare, du skarenes datter*! Voller har de kastet op imot oss; med staven slår de Israels dommer på kinnbenet. # <* det samme som Sions datter.> 
33:5:1: Men du Betlehem, Efrata, som er liten til å være med blandt Judas tusener*! Av dig skal det utgå for mig en som skal være hersker over Israel, og hans utgang er fra fordum, fra evighets dager. # <* større avdelinger av stammene.> 
33:5:2: Derfor skal han overgi dem inntil den tid da hun som skal føde, har født; og de som blir igjen av hans brødre*, skal vende tilbake sammen med Israels barn. # <* d.e. Judas barn; 2SA 19, 12.> 
33:5:3: Og han skal stå og vokte sin hjord i Herrens kraft, i Herrens, sin Guds navns høihet; og de skal sitte i ro, for nu skal han være stor like til jordens ender. 
33:5:4: Og han skal være fred; når Assur kommer inn i vårt land, og når han treder inn i våre palasser, så reiser vi mot ham syv hyrder, ja åtte menneskefyrster, 
33:5:5: og de skal herje Assurs land med sverd og Nimrods land* i dets porter; og han skal frelse fra Assur når han kommer i vårt land, og når han treder inn på våre enemerker. # <* 1MO 10, 8 fg.> 
33:5:6: Og Jakobs rest skal være midt iblandt mange folk som dugg fra Herren, som en regnskur på urter, som ikke bier på nogen og ikke venter på menneskenes barn. 
33:5:7: Og Jakobs rest skal være blandt hedningefolkene, midt iblandt mange folk, som en løve blandt skogens dyr, som en ungløve blandt fårehjorder, som trår ned og sønderriver hvor den farer frem, og det er ingen som redder. 
33:5:8: Høi være din hånd over dine motstandere! Måtte alle dine fiender bli utryddet! 
33:5:9: Og det skal skje på den dag, sier Herren, at jeg vil utrydde alle de hester du har, og tilintetgjøre dine vogner. 
33:5:10: Og jeg vil utrydde byene i ditt land og bryte ned alle dine festninger. 
33:5:11: Og jeg vil utrydde all trolldom hos dig, og folk som spår av skyene, skal ikke lenger finnes hos dig. 
33:5:12: Og jeg vil utrydde dine utskårne billeder og dine billedstøtter av ditt land, og du skal ikke mere tilbede dine henders verk. 
33:5:13: Og jeg vil rive op Astartebilledene i ditt land og ødelegge dine byer. 
33:5:14: Og jeg vil i vrede og harme ta hevn over de hedningefolk som ikke vilde høre. 
33:6:1: Hør nu hvad Herren sier: Reis dig, før din sak for fjellene, og la haugene høre din røst! 
33:6:2: Hør, I fjell, Herrens sak, og I jordens evige grunnvoller! For Herren har sak med sitt folk, og med Israel går han i rette. 
33:6:3: Mitt folk, hvad har jeg gjort dig, og hvormed har jeg trettet dig ut? Avlegg vidnesbyrd mot mig! 
33:6:4: For jeg førte dig op fra Egyptens land og løste dig ut av trælehuset, og jeg sendte Moses, Aron og Mirjam til førere for dig. 
33:6:5: Mitt folk! Kom i hu det råd som Balak, Moabs konge, hadde lagt, og det svar som Bileam, Beors sønn, gav ham, det som hendte på veien fra Sittim til Gilgal, så du kan forstå Herrens rettferdige gjerninger! 
33:6:6: Hvormed skal jeg komme frem for Herren, bøie mig ned for Gud i det høie? Skal jeg komme frem for ham med brennoffer, med årsgamle kalver? 
33:6:7: Vil Herren ha behag i tusener av værer, i titusener av oljebekker? Skal jeg gi min førstefødte for min overtredelse, mitt livs frukt som syndoffer for min sjel? 
33:6:8: Han har åpenbaret dig, menneske, hvad godt er; og hvad krever Herren av dig uten at du skal gjøre rett og gjerne vise kjærlighet og vandre ydmykt med din Gud? 
33:6:9: Herrens røst roper til staden, og efter visdom ser ditt navn*; hør straffen og hvem som har fastsatt den! # <* Guds navn d.e. Gud selv.> 
33:6:10: Er det ennu i den ugudeliges hus skatter som han har vunnet med ugudelighet, og en for knapp efa, en forbannet? 
33:6:11: Kan jeg være ren med ugudelighets vektskåler og med falske vektstener i pungen? 
33:6:12: Du stad hvis rikmenn er fulle av urett, og hvis innbyggere taler løgn og har en svikefull tunge i sin munn! 
33:6:13: Så vil da også jeg slå dig med farlige sår, ødelegge dig for dine synders skyld. 
33:6:14: Du skal ete, men ikke bli mett, og du skal være tom i ditt indre; du kan flytte dine ting, men du berger dem ikke, og det du berger, vil jeg overgi til sverdet. 
33:6:15: Du skal så, men ikke høste; du skal presse oljebær, men ikke salve dig med olje; du skal presse druer, men ikke drikke vin. 
33:6:16: For de akter nøie på Omris forskrifter og alt det som Akabs hus har gjort, og I følger deres vedtekter, så jeg må gjøre dig til en forferdelse og dine innbyggere til spott, og mitt folks vanære skal I bære. 
33:7:1: Ve mig! For det er gått mig som når sommerfrukten er innsamlet, som når eftersankingen efter vinhøsten er til ende: det er ingen drue å ete, ingen tidlig fiken som jeg har lyst til. 
33:7:2: Den fromme er blitt borte fra jorden, og det finnes ikke en ærlig mann blandt menneskene; alle sammen lurer de efter blod, hver mann vil fange sin bror i sitt garn. 
33:7:3: Med begge hender arbeider de på å få det onde til å synes godt; fyrsten krever, og dommeren er villig mot betaling, og stormannen sier hvad han ønsker, og således forvender de retten. 
33:7:4: Den beste av dem er som en tornebusk, den ærligste verre enn en tornehekk; dine vekteres dag*, din hjemsøkelse kommer, da blir de rådville. # <* den dag profetene har forut forkynt.> 
33:7:5: Tro ikke på nogen som står dig nær, sett ikke lit til nogen venn! For henne som ligger ved din barm, må du vokte din munns dører! 
33:7:6: For en sønn forakter sin far, en datter setter sig op imot sin mor, en svigerdatter mot sin svigermor; en manns husfolk er hans fiender. 
33:7:7: Men jeg vil skue ut efter Herren, bie på min frelses Gud; min Gud vil høre mig. 
33:7:8: Gled eder ikke over mig, I mine fiender! Når jeg er falt, står jeg op igjen; når jeg sitter i mørket, er Herren lys for mig. 
33:7:9: Herrens vrede vil jeg bære, for jeg har syndet mot ham - inntil han fører min sak og hjelper mig til min rett; han skal føre mig ut i schmelken, jeg skal se med fryd på hans rettferdighet. 
33:7:10: Og mine fiender skal se det, og skam skal dekke dem som sier til mig: Hvor er han, Herren din Gud? Mine øine skal se med fryd på dem, for da skal de bli trådt ned som skarn på gatene. 
33:7:11: Det kommer en dag da dine murer skal bygges op igjen; den dag skal dine grenser flyttes langt ut. 
33:7:12: På den dag skal de komme til dig like fra Assur og Egyptens byer og fra Egypten like til elven og fra hav til hav og fra fjell til fjell*. # <* d.e. fra alle land, enten de ligger mellem hav eller fjell.> 
33:7:13: Men landet skal [først] bli øde for sine innbyggeres skyld - for deres gjerningers skyld. 
33:7:14: Vokt ditt folk med din stav, den hjord som er din arv, som bor for sig selv i en skog på Karmel! La dem beite i Basan og Gilead, som i gamle dager! 
33:7:15: Som i de dager da du drog ut av Egyptens land, vil jeg la dig få se underfulle ting. 
33:7:16: Hedningefolk skal se det og skamme sig ved alt sitt velde; de skal legge hånden på sin munn, deres ører skal bli døve. 
33:7:17: De skal slikke støv som ormen; som jordens kryp skal de gå bevende frem av sine borger; til Herren vår Gud skal de komme skjelvende og frykte for dig. 
33:7:18: Hvem er en Gud som du, en Gud som tar bort misgjerning og går overtredelse forbi for dem som er tilbake av hans arv? Han holder ikke til evig tid fast ved sin vrede, for han har lyst til miskunnhet. 
33:7:19: Han skal igjen forbarme sig over oss, han skal trede våre misgjerninger under føtter. Du skal kaste alle deres synder i havets dyp. 
33:7:20: Du skal vise Jakob trofasthet, Abraham miskunnhet, som du har svoret våre fedre fra fordums dager. 
34:1:1: Dette er et utsagn om Ninive - en bok om elkositten Nahums syn. 
34:1:2: En nidkjær og hevnende Gud er Herren, en hevner er Herren og full av harme; en hevner er Herren mot sine motstandere og en som gjemmer på vrede mot sine fiender. 
34:1:3: Herren er langmodig, men han er stor i kraft, og ustraffet lar han aldri den skyldige være. I hvirvelvind og storm har Herren sin vei, og skyer er støvet under hans føtter. 
34:1:4: Han truer havet og tørker det ut, og alle elvene gjør han tørre; Basan visner og Karmel, og Libanons blomst visner bort. 
34:1:5: Fjellene skjelver for ham, og haugene smelter, og jorden hever sig for hans åsyn, jorderike og alle som bor der. 
34:1:6: Hvem holder stand for hans harme, og hvem utholder hans brennende vrede? Hans harme strømmer frem som ild, og fjellene styrter sammen for ham. 
34:1:7: Herren er god, et vern på trengselens dag, og han kjenner dem som tar sin tilflukt til ham. 
34:1:8: Men med en overskyllende flom skal han tilintetgjøre grunnen det* står på, og mørket skal forfølge hans fiender. # <* Ninive.> 
34:1:9: Hvad er det I tenker ut mot Herren? Han skal gjøre ende på eder; trengselen skal ikke komme to ganger. 
34:1:10: For er de enn så sammenflettet som torner og så fulle som deres fullskap kan gjøre dem, så blir de like fullt aldeles fortært som tørr halm. 
34:1:11: Fra dig* gikk det ut en** som tenkte ut ondt mot Herren, som la op ugudelige råd. # <* Ninive.> # <** Sankerib; 2KG 18, 13 fg.> 
34:1:12: Så sier Herren: Er de enn i full kraft og mange i tall, skal de allikevel bli nedmeiet som de er, og borte er de. Har jeg plaget dig*, så vil jeg ikke plage dig mere; # <* Jerusalem.> 
34:1:13: men nu vil jeg bryte det åk han la på dig, og sønderrive dine bånd. 
34:1:14: Men om dig* byder Herren så: Det skal ikke mere fødes nogen som bærer ditt navn; av din guds hus vil jeg utrydde både utskårne og støpte billeder; jeg vil gjøre i stand en grav for dig, for du er funnet for lett**. # <* Assyrias konge.> # <* DNL 5, 27.> 
34:2:1: Se der på fjellene dens føtter som kommer med godt budskap - som forkynner fred! Hold dine høitider, Juda, opfyll dine løfter! For de ugudelige skal ikke mere trenge inn i ditt land, de er alle utryddet. 
34:2:2: Det drar op mot dig en som vil sprede dig til alle kanter; vokt festningen, sku ut på veien, styrk dine lender, samle all din kraft! 
34:2:3: For Herren fører Jakobs høihet tilbake likesom Israels høihet, for ransmenn har plyndret dem og ødelagt deres vintrær. 
34:2:4: Hans kjempers skjold er rødfarvede, stridsmennene er klædd i skarlagen, vognene i luende stål på den dag han stiller dem op, og spydene svinges. 
34:2:5: På gatene raser vognene avsted, de styrter frem over torvene; de er som bluss å se til, som lyn farer de frem. 
34:2:6: Han kommer i hu sine gjæve menn; de snubler under sin gang, de haster avsted til bymuren, men skjoldtaket er reist. 
34:2:7: Portene ut mot elvene blir åpnet, og palasset forgår av angst. 
34:2:8: Og det står fast: Hun* blir avklædd, ført bort, og hennes piker kurrer som duer** og slår sig for sitt bryst. # <* Ninive fremstilt som en kvinne.> # <** ESK 7, 16.> 
34:2:9: Og Ninive er som en dam full av vann* like fra de dager det blev til. Men nu flyr de. Stans, stans! Men ingen vender sig om. # <* en folkerik stad.> 
34:2:10: Røv sølv, røv gull! For det er ingen ende på skattene, en overflod av allehånde kostelige ting! 
34:2:11: Tomt, tømt, uttømt! - forferdede hjerter og vaklende knær og verk i alle lender, og alles ansikter er blussende røde. 
34:2:12: Hvor er nu løvenes bolig, det sted hvor de unge løver fortærte sitt rov, hvor løven og løvinnen ferdedes og løveungen, og det var ingen som skremte dem? 
34:2:13: Hvor er løven, som røvet til dens unger hadde nok, og myrdet for sine løvinner og fylte sine huler med rov og sine boliger med det den hadde sønderrevet? 
34:2:14: Se, jeg kommer over dig, sier Herren, hærskarenes Gud, og jeg vil brenne dine vogner så de går op i røk, og dine unge løver skal sverdet fortære; og jeg vil utrydde ditt rov av jorden, og dine sendebuds røst skal ikke mere høres. 
34:3:1: Ve blodstaden, helt igjennem full av løgn og vold! Aldri holder den op å røve. 
34:3:2: Smellende svepe og durende hjul og jagende hest og hoppende vogn, 
34:3:3: fremstormende rytter og luende sverd og lynende spyd og drepte i mengde og dynger av lik! Det er ingen ende på døde kropper, en snubler over deres døde kropper, 
34:3:4: til gjengjeld for den mangfoldige utukt som hun drev, hun den fagre og trollkyndige skjøge, som solgte folkeslag ved sin utukt og hele slekter ved sine trolldomskunster. 
34:3:5: Se, jeg kommer over dig, sier Herren, hærskarenes Gud, og jeg vil dra din kjortelfald op over ditt ansikt, og jeg vil la folkeslag se din blusel og riker din skam, 
34:3:6: og jeg vil kaste skarn på dig og føre vanære over dig og gjøre dig til et skuespill. 
34:3:7: Og det skal skje at hver den som ser dig, skal fly bort fra dig og si: Ødelagt er Ninive! Hvem vil ha medynk med det? Hvor skal jeg søke efter trøstere for dig? 
34:3:8: Mon du er bedre enn No-Amon*, som tronte mellem Nilens strømmer med vann rundt omkring sig, som hadde hav til vern og hav til mur? # <* Teben i Egypten.> 
34:3:9: Etiopere i mengde og egyptere uten tall, puteere og libyere var dets hjelp. 
34:3:10: Også det måtte gå i landflyktighet som fange, også dets små barn blev knust på alle gatehjørner, og om dets ærede menn blev det kastet lodd, og alle dets stormenn blev bundet med lenker. 
34:3:11: Også du skal bli drukken og sanseløs; også du skal søke et vern imot fienden. 
34:3:12: Alle dine festninger er som fikentrær med tidlig moden frukt; rystes de, så faller fikenene i munnen på den som vil ete dem. 
34:3:13: Se, det krigsfolk du har hos dig, er som kvinnfolk; ditt lands porter står vidt åpne for dine fiender; ilden har fortært dine bommer. 
34:3:14: Øs vann til å ha mens du er kringsatt, gjør dine festninger sterke, gå ut i dyndet og stamp i leret, sett teglovnene i stand! 
34:3:15: Der skal ilden fortære dig, sverdet utrydde dig, fortære dig som slikkerne*; du kan gjerne være så tallrik som slikkerne, så tallrik som gresshoppene! # <* JOE 1, 4.> 
34:3:16: Dine kremmere er flere enn himmelens stjerner; slikkerne bredte ut sine vinger og fløi bort. 
34:3:17: Dine fyrster er som gresshopper, og dine høvdinger ligner en gresshoppesverm som slår sig ned på murene på en kold dag; solen går op, og de flyver bort, og ingen vet hvor det blir av dem. 
34:3:18: Dine hyrder slumrer, Assurs konge! Dine gjæve menn ligger i ro; ditt folk er spredt på fjellene, og det er ingen som samler det. 
34:3:19: Det er ingen lindring for din skade, ulægelig er ditt sår. Alle som hører tidenden om dig, klapper i hendene over dig; for hvem gikk ikke din ondskap uavlatelig ut over? 
35:1:1: Dette er det utsagn som profeten Habakuk skuet. 
35:1:2: Hvor lenge, Herre, skal jeg ennu rope uten at du hører! Jeg klager for dig over vold - og du frelser ikke. 
35:1:3: Hvorfor lar du mig skue urett, og hvorledes kan du selv se på slik elendighet? Ødeleggelse og vold har jeg for mine øine; det yppes kiv og opstår tretter. 
35:1:4: Derfor er loven maktesløs, og retten kommer aldri frem; for ugudelige omringer den rettferdige, derfor kommer retten frem forvendt. 
35:1:5: Se eder omkring blandt folkene, se og bli forferdet, ja forferdet! For en gjerning gjør jeg i eders dager - I skulde ikke tro den når den blev fortalt. 
35:1:6: For se, jeg lar kaldeerne reise sig, det ville og voldsomme folk, som farer frem så vide som jorden når, for å ta i eie boliger som ikke hører det til. 
35:1:7: Fryktelig og forferdelig er det; fra sig selv henter det sin rett og sin høihet. 
35:1:8: Dets hester er raskere enn leoparder og skarpere til å springe enn ulver om aftenen; dets ryttere sprenger frem, dets ryttere kommer langt borte fra, de flyver som en ørn når den styrter sig over sitt rov. 
35:1:9: Alle kommer de for å gjøre voldsverk, de stirrer stridslystne fremad, og de samler fanger som sand. 
35:1:10: De spotter konger, og fyrster er til latter for dem; de ler av hver festning, de dynger jord op mot den og tar den. 
35:1:11: Så stryker de avsted som en vind og farer frem og drar skyld over sig; deres kraft er deres gud. 
35:1:12: Er du ikke fra fordums tid Herren min Gud, min Hellige? Vi skal ikke dø. Herre! Til å fullbyrde dom har du satt dem. Du vår klippe! Til å straffe har du gitt dem fullmakt. 
35:1:13: Du som er ren av øine, så du ikke kan se på ondt* og ikke er i stand til å skue på elendighet**! Hvorfor ser du på troløse, hvorfor tier du når den ugudelige tilintetgjør den som er rettferdigere enn han? # <* uten å straffe.> # <** uten å hjelpe.> 
35:1:14: Du har jo gjort med menneskene som med havets fisker, som med krypet, som ingen herre har! 
35:1:15: Dem alle drar de* op med krok, samler dem i sin not og sanker dem i sitt garn; derfor gleder de sig og jubler. # <* kaldeerne.> 
35:1:16: Derfor ofrer de til sin not og brenner røkelse for sitt garn; for de gir dem deres fete lodd og deres kraftige mat. 
35:1:17: Men skal de derfor fremdeles få tømme sin not og uavlatelig slå folkeslag ihjel uten skånsel? 
35:2:1: På min vaktpost vil jeg stå og stille mig på varden; og jeg vil skue ut for å se hvad han vil tale til mig, og hvad jeg skal få til svar på mitt klagemål. 
35:2:2: Og Herren svarte mig og sa: Skriv synet op og skriv det tydelig på tavlene, så det kan leses med letthet! 
35:2:3: For ennu må synet vente på sin tid; men det haster mot enden og lyver ikke. Om det dryger, så bi efter det! For komme skal det, det skal ikke utebli. 
35:2:4: Se, opblåst og uærlig er hans sjel i ham*; men den rettferdige skal leve ved sin tro. # <* kaldeeren.> 
35:2:5: Så er og vinen troløs; en skrytende mann - han skal ikke bli boende i ro, han som har opspilt sitt grådige svelg likesom dødsriket; han er som døden og blir ikke mett, han har sanket til sig alle folk og samlet til sig alle folkeslag. 
35:2:6: Skal ikke alle disse synge nidviser og spottesanger om ham, lage gåter om ham og si: Ve den som dynger op ting som ikke hører ham til - hvor lenge? - og som lesser på sig pantegods! 
35:2:7: Skal de ikke brått reise sig de som skal pine dig, og våkne op de som skal jage dig op, så du blir et rov for dem? 
35:2:8: For du har plyndret mange folkeslag; således skal alle som blir igjen av folkene, plyndre dig for din blodskyld mot menneskene og for dine voldsverk mot jorden, mot staden og alle dem som bor i den. 
35:2:9: Ve den som jager efter skammelig vinning for sitt hus, for å bygge sitt rede i høiden, for å redde sig fra ulykkens hånd! 
35:2:10: Du har lagt op råd som blir til skam for ditt hus, lagt op råd om å gjøre ende på mange folk og dermed syndet mot dig selv; 
35:2:11: for stenen i veggen skal skrike, og bjelken i treverket skal svare den. 
35:2:12: Ve den som bygger en by med blod og grunnlegger en stad med urett! 
35:2:13: Se, kommer det ikke fra Herren, hærskarenes Gud, at folkeslag skal arbeide sig trette for ilden, og folkeferd gjøre sig møie for intet? 
35:2:14: For jorden skal fylles med kunnskap om Herrens herlighet, likesom vannet som dekker havets bunn. 
35:2:15: Ve den som gir sin næste å drikke av sin brennende vredes skål, ja drikker dem drukne, for å se på deres blusel! 
35:2:16: Du blir mettet med skam og ikke med ære; drikk også du og vis din forhud frem! Begeret i Herrens høire hånd skal i sin tid komme til dig, og dyp skam skal skjule din ære. 
35:2:17: For ditt voldsverk mot Libanon og ødeleggelsen av dyrene, som skremte dem, skal komme over dig for din blodskyld mot menneskene og for dine voldsverk mot jorden, mot staden og alle dem som bor i den. 
35:2:18: Hvad gagn gjør et utskåret billede, om enn en mester har skåret det? Eller hvad gagn gjør et støpt billede, en lærer i løgn, om enn dets mester satte sin lit til det da han gjorde målløse avguder? 
35:2:19: Ve den som sier til en stokk: Våkn op! - til en målløs sten: Stå op! Skulde den være lærer? Den er jo overtrukket med gull og sølv, og det finnes ikke ånd i den. 
35:2:20: Men Herren er i sitt hellige tempel; vær stille for hans åsyn, all jorden! 
35:3:1: En bønn av profeten Habakuk; efter Sjigjonot*. # <* SLM 7, 1.> 
35:3:2: Herre, jeg har hørt budskapet om dig, jeg er forferdet. Herre, din gjerning* - kall den til live før mange år er lidd, ja, kunngjør den før mange år er lidd! I din vrede komme du i hu å forbarme dig! # <* HAB 1, 5; 2, 2 fg.> 
35:3:3: Gud kommer fra Teman, den Hellige fra Paran-fjellet. Sela. Hans prakt dekker himmelen, og jorden er full av hans herlighet. 
35:3:4: En glans som solens schmelk bryter frem, stråler omgir ham, og i dem er hans makt skjult. 
35:3:5: Pest går frem for hans åsyn, og sott følger hans fottrin. 
35:3:6: Han stiger frem og ryster jorden; han ser op og får folkene til å skjelve; de evige fjell sprenges i stykker, de eldgamle hauger synker sammen; hans gang er som i eldgammel tid. 
35:3:7: Jeg ser Kusans telter i sorg, teltteppene i Midians land bever. 
35:3:8: Harmes du, Herre, på elvene, er din vrede optendt mot dem, eller din harme mot havet, siden du farer frem på dine hester, på dine seierrike vogner? 
35:3:9: Bar og naken er din bue - dine eder til stammene, ditt ord*! - Sela. Til elver kløver du jorden. # <* blir nu synlig fullbyrdet.> 
35:3:10: Fjellene ser dig og bever, vannstrømmer styrter frem; avgrunnen lar sin røst høre, den løfter sine hender mot det høie. 
35:3:11: Sol og måne treder inn i sin bolig for schmelken av dine piler, som farer frem, for glansen av ditt lynende spyd. 
35:3:12: I harme skrider du frem over jorden, i vrede treder du folkene ned. 
35:3:13: Du drar ut til frelse for ditt folk, til frelse for din salvede; du knuser taket på den ugudeliges* hus, du avdekker grunnvollen like til halsen. Sela. # <* kaldeerkongens.> 
35:3:14: Du gjennemborer med hans* eget spyd hodene på hans skarer, som stormer frem for å sprede mig og gleder sig likesom de skulde til å opete en arming i lønndom. # <* den ugudeliges; HAB 3, 13.> 
35:3:15: Du farer gjennem havet med dine hester, gjennem en haug av store vann. 
35:3:16: Jeg hørte det*; da bevet mitt indre, ved lyden dirret mine leber; det kommer råttenhet i mine ben, og jeg bever hvor jeg står, fordi jeg rolig må bie på nødens dag, bie på at han drar op mot folket, han som skal trenge det. # <* HAB 3, 2.> 
35:3:17: For fikentreet springer ikke ut, og vintrærne bærer ikke, oljetreets frukt slår feil, og markene gir ingen føde; han* har utryddet fårene av kveen, og det finnes ikke fe i fjøsene. # <* kaldeeren.> 
35:3:18: Men jeg vil fryde mig i Herren, jeg vil juble i min frelses Gud. 
35:3:19: Herren, Israels Gud, er min kraft, han gjør mine føtter som hindenes og lar mig skride frem over mine høider. Til sangmesteren, med min strengelek. 
36:1:1: Dette er Herrens ord som kom til Sefanias, sønn av Kusi, sønn av Gedalja, sønn av Amarja, sønn av Esekias, i de dager da Josias, Amons sønn, var konge i Juda. 
36:1:2: Bort, bort vil jeg ta alt av jorden, sier Herren. 
36:1:3: Jeg vil ta bort mennesker og dyr, ta bort himmelens fugler og havets fisker og alt det som volder anstøt, sammen med de ugudelige, og jeg vil utrydde menneskene av jorden, sier Herren. 
36:1:4: Jeg vil rekke min hånd ut mot Juda og mot alle Jerusalems innbyggere, og jeg vil utrydde av dette sted alt som er igjen av Ba'al, avgudsprestenes navn sammen med prestene 
36:1:5: og dem som på takene tilbeder himmelens hær, og dem som tilbeder Herren og sverger ham troskap, men på samme tid sverger ved sin konge*, # <* JER 49, 1.> 
36:1:6: og dem som har vendt sig bort fra Herren, og som ikke har søkt Herren og ikke spurt efter ham. 
36:1:7: Vær stille for Herren, Israels Gud! For Herrens dag er nær; Herren har stelt til en offerslaktning, han har innvidd sine gjester. 
36:1:8: Og på Herrens offerslaktnings dag vil jeg hjemsøke høvdingene og kongens sønner og alle som klær sig i utenlandske klær. 
36:1:9: Og jeg vil på den dag hjemsøke hver den som hopper over dørtreskelen, dem som fyller sin Herres hus med vold og svik. 
36:1:10: På den dag, sier Herren, skal det lyde skrik fra Fiskeporten og hyl fra stadens andre del og stort brak fra haugene. 
36:1:11: Hyl, I som bor i Morteren*! For hele kana'anitterfolket** er utslettet; alle de som slepte på masser av sølv, er utryddet. # <* en dyptliggende del av Jerusalem.> # <** kremmerfolket; HSE 12, 8.> 
36:1:12: Og på den samme tid vil jeg ransake Jerusalem med lykter, og jeg vil hjemsøke de folk som ligger på sin berme og sier i sitt hjerte: Herren gjør hverken godt eller ondt. 
36:1:13: Og deres gods skal bli til rov, og deres hus ødelegges; de skal bygge hus, men ikke bo i dem, og plante vingårder, men ikke drikke vin fra dem. 
36:1:14: Nær er Herrens dag, den store; den er nær og kommer med stor hast. Hør! Det er Herrens dag! Full av angst skriker da kjempen. 
36:1:15: En vredes dag er den dag, en dag med nød og trengsel, en dag med omstyrtelse og ødeleggelse, en dag med mørke og mulm, en dag med skyer og skodde, 
36:1:16: en dag med basunklang og hærskrik mot de faste byer og de høie murtinder. 
36:1:17: Da vil jeg sette slik angst i menneskene at de går der som blinde, fordi de har syndet mot Herren; og deres blod skal utøses som støv, og deres kjøtt som møkk. 
36:1:18: Hverken deres sølv eller deres gull skal kunne berge dem på Herrens vredes dag; ved hans nidkjærhets ild skal hele jorden bli fortært; for han vil gjøre ende, ja brått gjøre ende på alle dem som bor på jorden. 
36:2:1: Tenk efter og gå i dig selv, du folk som ikke blues, 
36:2:2: før Guds råd føder - som agner farer dagen frem - før Herrens brennende vrede kommer over eder, før Herrens vredes dag kommer over eder! 
36:2:3: Søk Herren, alle I saktmodige i landet, som holder hans lov! Søk rettferdighet, søk saktmodighet! Kanskje I blir skjult på Herrens vredes dag. 
36:2:4: For Gasa skal bli forlatt, og Askalon en ørken; Asdods folk skal jages ut midt på dagen, og Ekron skal rykkes op med rot. 
36:2:5: Ve dem som bor i bygdene ute ved havet, kreterfolket! Herrens ord kommer over dig, Kana'an, du filistrenes land, jeg vil ødelegge dig så ingen skal bo i dig. 
36:2:6: Og bygdene ute ved havet skal bli til beitemarker med gjemmesteder for hyrdene og med fehegn. 
36:2:7: Og det skal bli en bygd for dem som blir igjen av Judas hus; der skal de ha sitt beite, i Askalons huser skal de hvile om aftenen. For Herren deres Gud skal se til dem og gjøre ende på deres fangenskap. 
36:2:8: Jeg har hørt Moabs spott og Ammons barns hånsord, da de spottet mitt folk og bar sig overmodig at mot dets land. 
36:2:9: Derfor, så sant jeg lever, sier Herren, hærskarenes Gud, Israels Gud, skal det gå Moab som Sodoma og Ammons barn som Gomorra: De skal bli neslers eie og en saltgrube og en ørken til evig tid; resten av mitt folk skal plyndre dem; de som blir igjen av mitt folk, skal ta dem i eie. 
36:2:10: Således skal det gå dem for deres overmots skyld, fordi de hånte og bar sig overmodig at mot Herrens, hærskarenes Guds folk. 
36:2:11: Forferdelig skal Herren vise sig mot dem; for han skal tilintetgjøre alle jordens guder, og alle hedningenes kyster skal tilbede ham, hvert folk på sitt sted. 
36:2:12: Også I etiopere blir gjennemboret av mitt sverd. 
36:2:13: Han rekker sin hånd ut mot Norden og ødelegger Assur og gjør Ninive til et øde, tørt som en ørken. 
36:2:14: Der skal hjorder hvile; alle slags villdyrflokker, både pelikaner og pinnsvin, skal overnatte på søilehodene der; fuglesang høres i vinduene, grus ligger på dørtreskelen, for sederpanelet er revet av. 
36:2:15: Dette er den jublende stad, som bodde så trygt, som sa i sitt hjerte: Jeg og ingen annen! Hvor den er blitt til en ørken, et leie for ville dyr! Hver den som går forbi, blåser av den og ryster hånlig sin hånd. 
36:3:1: Ve den gjenstridige og urene, staden som er full av vold! 
36:3:2: Den hører ikke på nogens røst, tar ikke mot tukt; til Herren setter den ikke sin lit, til sin Gud holder den sig ikke nær. 
36:3:3: Høvdingene i den er brølende løver, dens dommere er som ulver om aftenen; de gjemmer ikke noget til om morgenen; 
36:3:4: dens profeter er storskrytere, troløse menn; dens prester vanhelliger det som er hellig, de gjør vold på loven. 
36:3:5: Herren råder der med rettferd, han gjør ikke urett; hver morgen lar han sin dom komme for schmelken, det slår ikke feil. Men den urettferdige kjenner ikke til skam. 
36:3:6: Jeg har utryddet hedningefolk, deres murtinder er ødelagt; jeg har gjort deres gater øde, så ingen går gjennem dem; deres byer er herjet, så det ikke finnes folk, ikke bor nogen der. 
36:3:7: Jeg sa: Bare du* vil frykte mig, ta mot tukt; da skal din bolig ikke bli utryddet, ikke noget skje av det jeg har besluttet mot dig. Men de har tvert imot lagt vinn på å forderve alle sine gjerninger. # <* Jerusalem; SEF 3, 1 fg.> 
36:3:8: Derfor, bi på mig, sier Herren, på den dag jeg reiser mig for å ta hærfang! For min dom er at jeg samler hedningefolk, sanker riker sammen, utøser min harme over dem, all min brennende vrede; for ved min nidkjærhets ild skal hele jorden bli fortært. 
36:3:9: For da vil jeg gi folkene nye, rene leber, så de alle påkaller Herrens navn og tjener ham med ett sinn. 
36:3:10: Fra landet bortenfor Etiopias elver skal de føre mine tilbedere, mitt spredte folk, som offergave til mig. 
36:3:11: På den dag skal du ikke mere skamme dig over alle de gjerninger hvormed du forbrøt dig mot mig; for da vil jeg rydde bort hos dig dem som jubler så stolt i din midte, og du skal ikke mere ophøie dig selv på mitt hellige berg. 
36:3:12: Men jeg vil levne hos dig et bøiet og ringe folk*, og de skal ta sin tilflukt til Herrens navn. # <* JES 57, 15.> 
36:3:13: Israels rest skal ikke gjøre urett; de skal ikke tale løgn, og det skal ikke finnes en svikefull tunge i deres munn; for de skal finne føde og leve i ro, og ingen skal forferde dem. 
36:3:14: Fryd dig storlig, Sions datter! Rop høit, Israel! Gled dig og fryd dig av fullt hjerte, Jerusalems datter! 
36:3:15: Herren har tatt bort dine straffedommer, han har ryddet bort din fiende; Israels konge, Herren, er i din midte, du skal ikke mere se noget ondt. 
36:3:16: På den dag skal det sies til Jerusalem: Frykt ikke! Sion, la ikke dine hender synke! 
36:3:17: Herren din Gud er i din midte, en kjempe som frelser; han fryder sig over dig med glede, han tier i sin kjærlighet, han jubler over dig med fryderop. 
36:3:18: Dem som sørger for høitidens skyld*, samler jeg - de er fra dig, vanære tynger på dem. # <* fordi de ikke kan holde nogen høitid.> 
36:3:19: Se, jeg skal på den tid ha med alle dem å gjøre som har plaget dig, og jeg vil frelse det haltende og sanke det bortdrevne, og jeg vil gjøre dem til pris og til ry over hele jorden, hvor de er blitt så vanæret. 
36:3:20: På den tid vil jeg føre eder hit, på den tid vil jeg sanke eder; for jeg vil gjøre eder til ry og til pris blandt alle jordens folk, når jeg gjør ende på eders fangenskap for eders øine, sier Herren. 
37:1:1: I kong Darius' annet år, i den sjette måned, på den første dag i måneden, kom Herrens ord ved profeten Haggai til Serubabel, Sealtiels sønn, stattholder over Juda, og til Josva, Jehosadaks sønn, ypperstepresten, og det lød så: 
37:1:2: Så sier Herren, hærskarenes Gud: Dette folk sier: Det er ennu ikke tid til å komme, tid til å bygge Herrens hus. 
37:1:3: Så kom da Herrens ord ved profeten Haggai: 
37:1:4: Er det tid for eder til å bo i eders bordklædde hus, mens dette hus ligger øde? 
37:1:5: Og nu sier Herren, hærskarenes Gud, så: Legg merke til hvorledes det går eder! 
37:1:6: I sår meget, men høster lite i hus; I eter, men blir ikke mette; I drikker, men blir ikke utørste; I klær eder, men ingen blir varm, og den som tjener for lønn, får sin lønn i en hullet pung. 
37:1:7: Så sier Herren, hærskarenes Gud: Legg merke til hvorledes det går eder! 
37:1:8: Gå op i fjellene og hent tømmer og bygg huset! Så vil jeg ha velbehag i det og herliggjøre mig, sier Herren. 
37:1:9: I venter meget, og se, det blir til lite, og når I bærer det i hus, så blåser jeg det bort. Hvorfor? sier Herren, hærskarenes Gud. Fordi mitt hus ligger øde, mens I har det travelt hver med sitt hus. 
37:1:10: Derfor har himmelen lukket sig over eder, så den ikke gir dugg, og jorden har holdt sin grøde tilbake. 
37:1:11: Og jeg har kalt tørke hit over landet og fjellene, over kornet og mosten og oljen og alt det som jorden bærer, og over folk og fe og over alt eders henders arbeid. 
37:1:12: Og Serubabel, Sealtiels sønn, og Josva, Jehosadaks sønn, ypperstepresten, og alle som var igjen av folket, hørte på Herrens, sin Guds røst og på profeten Haggais ord, fordi Herren deres Gud hadde sendt ham, og folket fryktet for Herren. 
37:1:13: Da sa Haggai, Herrens sendebud, i Herrens ærend til folket: Jeg er med eder, sier Herren. 
37:1:14: Og Herren vakte slik iver hos Serubabel, Sealtiels sønn, stattholderen over Juda, og hos Josva, Jehosadaks sønn, ypperstepresten, og hos hele det folk som var blitt igjen, at de kom og arbeidet på Herrens, sin Guds, hærskarenes Guds hus; 
37:1:15: det var på den fire og tyvende dag i måneden, i den sjette måned i kong Darius' annet år. 
37:2:1: I den syvende måned, på den en og tyvende dag i måneden, kom Herrens ord ved profeten Haggai, og det lød så: 
37:2:2: Si til Serubabel, Sealtiels sønn, stattholderen over Juda, og til Josva, Jehosadaks sønn, ypperstepresten, og til dem som er igjen av folket: 
37:2:3: Har nogen blandt eder som er blitt igjen, sett dette hus i dets første herlighet? Og hvad synes I om det nu? Er det ikke som ingenting i eders øine? 
37:2:4: Men vær nu frimodig, Serubabel, sier Herren, vær frimodig, Josva, Jehosadaks sønn, du yppersteprest, vær frimodig, alt landets folk, sier Herren, og arbeid! For jeg er med eder, sier Herren, hærskarenes Gud. 
37:2:5: Den pakt jeg gjorde med eder da I drog ut av Egypten, skal stå fast, og min Ånd skal bo blandt eder; frykt ikke! 
37:2:6: For så sier Herren, hærskarenes Gud: Ennu én gang, om en liten stund, vil jeg ryste himmelen og jorden og havet og det tørre land. 
37:2:7: Og jeg vil ryste alle folkene, og alle folkenes kostelige skatter skal komme hit, og jeg vil fylle dette hus med herlighet, sier Herren, hærskarenes Gud. 
37:2:8: Mig hører sølvet, og mig hører gullet til, sier Herren, hærskarenes Gud. 
37:2:9: Dette siste hus skal bli herligere enn det første, sier Herren, hærskarenes Gud; og på dette sted vil jeg gi fred, sier Herren, hærskarenes Gud. 
37:2:10: På den fire og tyvende dag i den niende måned i Darius' annet år kom Herrens ord ved profeten Haggai, og det lød så: 
37:2:11: Så sier Herren, hærskarenes Gud: Spør prestene om loven og si: 
37:2:12: Om nogen bærer hellig kjøtt i folden av sin kappe og med den kommer nær noget brød eller noget kokt eller vin eller olje eller noget slags mat, blir det da hellig? Prestene svarte: Nei. 
37:2:13: Så sa Haggai: Om en som er blitt uren ved lik, kommer nær noget av dette, blir det da urent? Prestene svarte: Ja, det blir urent. 
37:2:14: Da tok Haggai til orde og sa: Således er det med dette folk, og således er det med disse mennesker i mine øine, sier Herren, og således er det med all deres henders gjerning; og det de ofrer der*, det er urent. # <* på alteret; ESR 3, 3.> 
37:2:15: Og gi nu akt på tiden fra denne dag og tilbake, før det blev lagt sten på sten i Herrens tempel! 
37:2:16: Kom nogen i den tid til en haug med kornbånd som han tenkte skulde gi tyve mål, så blev det bare ti; kom nogen til persekaret for å øse op femti spann, så blev det bare tyve. 
37:2:17: Jeg slo eder med kornbrand og rust og hagl i all eders henders gjerning; men I vendte eder ikke til mig, sier Herren. 
37:2:18: Gi da akt på tiden fra denne dag og tilbake, fra den fire og tyvende dag i den niende måned like til den dag da Herrens tempel blev grunnlagt! Gi akt! 
37:2:19: Er det ennu sæd i kornboden? Ennu har hverken vintreet eller fikentreet eller granatepletreet eller oljetreet båret noget. Men fra denne dag vil jeg velsigne. 
37:2:20: Og Herrens ord kom annen gang til Haggai på den fire og tyvende dag i måneden, og det lød så: 
37:2:21: Si til Serubabel, stattholderen over Juda: Jeg vil ryste himmelen og jorden, 
37:2:22: og jeg vil omstyrte kongerikers troner og tilintetgjøre hedningerikenes makt; jeg vil velte vognene og dem som kjører på dem, og hestene skal styrte, og de som rider på dem; den ene skal falle for den andres sverd. 
37:2:23: På den dag, sier Herren, hærskarenes Gud, vil jeg ta dig, Serubabel, Sealtiels sønn, min tjener, sier Herren, og gjøre med dig som med en signetring; for dig har jeg utvalgt, sier Herren, hærskarenes Gud. 
38:1:1: I den åttende måned i Darius' annet år kom Herrens ord til profeten Sakarias, sønn av Berekias, sønn av Iddo, og det lød så: 
38:1:2: Stor var Herrens vrede mot eders fedre. 
38:1:3: Og du skal si til dem: Så sier Herren, hærskarenes Gud: Vend om til mig, sier Herren, hærskarenes Gud, så vil jeg vende om til eder, sier Herren, hærskarenes Gud. 
38:1:4: Vær ikke lik eders fedre, som de forrige profeter formante med disse ord: Så sier Herren, hærskarenes Gud: Vend om fra eders onde veier og eders onde gjerninger! Men de hørte ikke og aktet ikke på mig, sier Herren. 
38:1:5: Eders fedre, hvor er de? Og profetene, kan de leve til evig tid? 
38:1:6: Men mine ord og mine råd, som jeg bød mine tjenere profetene å forkynne, har ikke de nådd eders fedre, så de vendte om og sa: Som Herren, hærskarenes Gud, hadde i sinne å gjøre med oss efter våre veier og våre gjerninger, således har han gjort med oss? 
38:1:7: På den fire og tyvende dag i den ellevte måned, det er måneden sebat, i Darius' annet år, kom Herrens ord til profeten Sakarias, sønn av Berekias, sønn av Iddo, og det lød så: 
38:1:8: Jeg hadde et syn om natten: Jeg så en mann som red på en rød hest, og han holdt stille blandt myrtetrærne i dalbunnen; og bak ham var det røde, blakke og hvite hester. 
38:1:9: Da sa jeg: Hvad er dette, herre? Og engelen som talte med mig, sa til mig: Jeg vil la dig se hvad dette er. 
38:1:10: Da tok mannen som holdt stille blandt myrtetrærne, til orde og sa: Dette er de som Herren har sendt ut for å fare omkring på jorden. 
38:1:11: Og de svarte Herrens engel, som holdt stille blandt myrtetrærne, og sa: Vi har faret omkring på jorden og sett at hele jorden er rolig og stille. 
38:1:12: Da tok Herrens engel til orde og sa: Herre, hærskarenes Gud! Hvor lenge skal det vare før du forbarmer dig over Jerusalem og Judas byer, som du har vært vred på nu i sytti år? 
38:1:13: Og Herren svarte engelen som talte med mig, gode og trøstefulle ord. 
38:1:14: Og engelen som talte med mig, sa til mig: Rop ut disse ord: Så sier Herren, hærskarenes Gud: Jeg er såre nidkjær for Jerusalem og Sion, 
38:1:15: og såre vred er jeg på de trygge hedningefolk; for jeg var bare litt vred, men de hjalp med til ulykken. 
38:1:16: Derfor sier Herren så: Jeg vender mig atter til Jerusalem med miskunnhet; mitt hus skal bygges der, sier Herren, hærskarenes Gud, og målesnoren skal strekkes ut over Jerusalem. 
38:1:17: Rop atter og si: Så sier Herren, hærskarenes Gud: Ennu en gang skal mine byer flyte over av det som godt er, og Herren skal ennu en gang trøste Sion og ennu en gang utvelge Jerusalem. 
38:2:1: Og jeg løftet mine øine op og fikk se fire horn. 
38:2:2: Da spurte jeg engelen som talte med mig: Hvad er dette? Og han sa til mig: Dette er de horn som har adspredt Juda, Israel og Jerusalem. 
38:2:3: Så lot Herren mig se fire smeder. 
38:2:4: Da spurte jeg: Hvad kommer disse her for? Han svarte: Hornene har adspredt Juda, således at ingen vågde å løfte sitt hode, og nu er disse kommet for å forferde dem og slå av hornene på de hedningefolk som har løftet horn mot Juda land for å adsprede det. 
38:2:5: Så løftet jeg mine øine op og fikk se en mann som hadde en målesnor i sin hånd. 
38:2:6: Jeg spurte ham: Hvor skal du hen? Han svarte: Jeg skal til Jerusalem for å utmåle det og se hvor bredt og hvor langt det skal være. 
38:2:7: Da kom engelen som talte med mig, frem, og en annen engel kom imot ham. 
38:2:8: Og han sa til ham: Spring avsted og si til den unge mann der: Jerusalem skal ligge fritt og åpent på grunn av den mengde mennesker og fe som skal finnes der. 
38:2:9: Og jeg, sier Herren, vil være en ildmur rundt omkring det, og jeg vil åpenbare min herlighet der. 
38:2:10: Hør! Hør! Fly bort fra Nordens land, sier Herren; for jeg har spredt eder for himmelens fire vinder, sier Herren. 
38:2:11: Hør! Sion, berg dig unda, du som bor hos Babels datter! 
38:2:12: For så sier Herren, hærskarenes Gud: For sin æres skyld har han sendt mig til hedningefolkene som plyndret eder; for den som rører ved eder, rører ved hans øiesten; 
38:2:13: for se, jeg løfter min hånd mot dem, og de skal bli et rov for dem som nu træler for dem; og I skal kjenne at Herren, hærskarenes Gud, har sendt mig. 
38:2:14: Fryd dig storlig og gled dig, du Sions datter! For se, jeg kommer og vil bo hos dig, sier Herren. 
38:2:15: Og mange hedningefolk skal gi sig til Herren på den dag og bli mitt folk; og jeg vil bo hos dig, og du skal kjenne at Herren, hærskarenes Gud, har sendt mig til dig. 
38:2:16: Og Herren skal ta Juda til eie som sin del på den hellige jordbunn; og han skal ennu en gang utvelge Jerusalem. 
38:2:17: Vær stille, alt kjød, for Herrens åsyn! For han har reist sig og er gått ut av sin hellige bolig. 
38:3:1: Så lot han mig se Josva, ypperstepresten, som stod for Herrens engels åsyn, og Satan som stod ved hans høire side for å anklage ham. 
38:3:2: Men Herren sa til Satan: Herren refse dig, Satan! Herren refse dig, han som har utvalg Jerusalem! Er ikke denne mann her en rykende brand, revet ut av ilden? 
38:3:3: Men Josva var klædd i skitne klær der han stod for engelens åsyn. 
38:3:4: Og engelen tok til orde og sa til dem som stod foran ham: Ta de skitne klær av ham! Og til ham selv sa han: Se, jeg tar din misgjerning bort fra dig og klær dig i høitidsklær. 
38:3:5: Da sa jeg: Sett en ren hue på hans hode! Og de satte den rene hue på hans hode og hadde på ham andre klær mens Herrens engel stod der. 
38:3:6: Da vidnet Herrens engel for Josva og sa: 
38:3:7: Så sier Herren, hærskarenes Gud: Hvis du går på mine veier og holder mine bud, da skal du også få styre mitt hus og vokte mine forgårder; og jeg vil gi dig førere blandt disse som står her*. # <* blandt englene.> 
38:3:8: Hør nu, Josva, du yppersteprest! Du og dine venner, som sitter her foran dig, I er varselsmenn*. For se, jeg lar min tjener Spire** komme; # <* forbilleder; JES 8, 18.> # <** Messias, spiren av Davids ætt.> 
38:3:9: for se, den sten jeg har lagt foran Josva - på denne ene sten er syv øine* rettet! Se, jeg skjærer ut på den de tegn den skal ha, sier Herren, hærskarenes Gud, og jeg tar dette lands misgjerning bort på én dag. # <* SKR 4, 10.> 
38:3:10: På den dag, sier Herren, hærskarenes Gud, skal I innby hverandre til gjestebud under vintreet og fikentreet. 
38:4:1: Så kom engelen som talte med mig, igjen og vakte mig likesom når en mann vekkes av sin søvn. 
38:4:2: Og han sa til mig: Hvad ser du? Jeg svarte: Jeg ser en lysestake som er helt igjennem av gull, med sitt oljekar på toppen og sine syv lamper, og med syv rør for hver lampe som er på den, 
38:4:3: og to oljetrær står ved siden av den, ett på oljekarets høire side og ett på dets venstre side. 
38:4:4: Og jeg tok til orde og sa til engelen som talte med mig: Hvad er dette, herre? 
38:4:5: og engelen som talte med mig, svarte: Vet du ikke hvad dette er? Jeg sa: Nei, herre! 
38:4:6: Da tok han til orde og sa til mig: Dette er Herrens ord til Serubabel: Ikke ved makt og ikke ved kraft, men ved min Ånd, sier Herren, hærskarenes Gud. 
38:4:7: Hvem er du, du store fjell som reiser dig foran Serubabel? Bli til en slette! Han skal føre toppstenen* frem under høie rop: Nåde, nåde være med den! # <* den øverste sten.> 
38:4:8: Og Herrens ord kom til mig, og det lød så: 
38:4:9: Serubabels hender har grunnlagt dette hus, og hans hender skal fullføre det; og du skal kjenne at Herren, hærskarenes Gud, har sendt mig til eder. 
38:4:10: For hvem vil forakte den ringe begynnelses dag? Med glede ser de syv øine* blyloddet i Serubabels hånd, disse Herrens øine som farer omkring over hele jorden. # <* SKR 3, 9.> 
38:4:11: Da tok jeg til orde og sa til ham: Hvad er disse to oljetrær ved lysestakens høire og venstre side? 
38:4:12: Og jeg tok annen gang til orde og sa til ham: Hvad er de to oljegrener tett ved de to gullrenner som gullet* strømmer ut av? # <* d.e. den glinsende olje.> 
38:4:13: Da sa han til mig: Vet du ikke hvad disse er? Jeg svarte: Nei, herre! 
38:4:14: Da sa han: Det er de to oljesalvede* som står hos all jordens herre. # <* d.e. de to Herrens salvede, folkets prestelige og fyrstelige overhoder, (Josva og Serubabel).> 
38:5:1: Så løftet jeg atter mine øine op og fikk se en flyvende bokrull. 
38:5:2: Og han sa til mig: Hvad ser du? Jeg svarte: Jeg ser en flyvende bokrull, som er tyve alen lang og ti alen bred. 
38:5:3: Da sa han til mig: Dette er den forbannelse som går ut over det hele land; for hver den som stjeler, skal efter det som står på den ene side av den, bli utryddet, og likeså skal hver den som sverger falsk, efter det som står på dens andre side, bli utryddet. 
38:5:4: Jeg har latt den* gå ut, sier Herren, hærskarenes Gud, og den skal gå inn i tyvens hus og i dens hus som sverger falsk ved mitt navn, og den skal bli i hans hus og fortære det, både treverket og stenene. # <* forbannelsen.> 
38:5:5: Så kom engelen som talte med mig, frem; og han sa til mig: Løft dine øine op og se hvad det er dette som kommer frem der! 
38:5:6: Jeg spurte: Hvad er det? Han svarte: Det er en efa som kommer frem der. Og han sa: Således skal det se ut med dem* i hele landet. # <* folket.> 
38:5:7: Da blev et blylokk løftet op, og det viste sig en kvinne som satt i efaen. 
38:5:8: Og han sa: Dette er ugudeligheten. Så støtte han henne ned i efaen og slo blylokket til over åpningen. 
38:5:9: Derefter løftet jeg mine øine op og fikk se to kvinner som kom frem, og vinden fylte deres vinger, for de hadde vinger som storkens vinger; og de løftet efaen op, så den svevde mellem jorden og himmelen. 
38:5:10: Da sa jeg til engelen som talte med mig: Hvor skal de hen med efaen? 
38:5:11: Han svarte: De skal til Sinears land for å bygge et hus for henne der; og når det er ferdig, skal hun settes ned der på sitt sted. 
38:6:1: Så løftet jeg atter mine øine op og fikk se fire vogner som kom frem mellem to fjell, og fjellene var kobberfjell. 
38:6:2: For den første vogn var det røde hester, og for den annen vogn var det sorte hester, 
38:6:3: og for den tredje vogn var det hvite hester, og for den fjerde vogn flekkete, sterke hester. 
38:6:4: Og jeg tok til orde og sa til engelen som talte med mig: Hvad er dette, herre? 
38:6:5: Engelen svarte: Dette er himmelens fire vinder, som nu farer ut efterat de har fremstilt sig for all jordens herre. 
38:6:6: Vognen med de sorte hester for drog ut mot Nordens land, og de hvite drog ut efter dem, og de flekkete drog ut mot Sydens land. 
38:6:7: Og de sterkeste* drog ut, og da de ønsket å få dra omkring på jorden**, sa han: Gå, dra omkring på jorden! Og de drog omkring på jorden. # <* de røde; SKR 6, 2.> # <** SKR 1, 10.> 
38:6:8: Så ropte han høit på mig og sa til mig: Se, de som drar ut mot Nordens land, de stiller min vrede på Nordens land. 
38:6:9: Og Herrens ord kom til mig, og det lød så: 
38:6:10: Ta imot gavene fra de bortførte, fra Heldai, Tobia og Jedaja - gå selv på denne dag inn i Josias', Sefanias' sønns hus - for dit er de kommet fra Babel - 
38:6:11: og ta imot sølv og gull av dem og gjør kroner og sett dem på Josvas, Jehosadaks sønns, yppersteprestens hode! 
38:6:12: Og si til ham: Så sier Herren, hærskarenes Gud: Se, det skal komme en mann som heter Spire*; han skal spire frem av sin rot, og han skal bygge Herrens tempel. # <* SKR 3, 8.> 
38:6:13: Ja, han skal bygge Herrens tempel, og han skal vinne herlighet* og sitte og herske på sin kongetrone, og han skal være prest der han sitter på sin trone, og freds råd skal det være mellem dem begge. # <* JES 9, 6.> 
38:6:14: Og kronene skal være til et minne i Herrens tempel om Helem og Tobia og Jedaja og Hen, Sefanias sønn. 
38:6:15: Og langt borte fra skal de komme og bygge på Herrens tempel, og I skal kjenne at Herren, hærskarenes Gud, har sendt mig til eder; og dette skal skje dersom I hører på Herrens, eders Guds røst. 
38:7:1: Så var det i kong Darius' fjerde år, da kom Herrens ord til Sakarias på den fjerde dag i den niende måned, i måneden kislev. 
38:7:2: For Betel hadde sendt Sareser og Regem-Melek og hans menn for å bønnfalle Herren 
38:7:3: og spørre prestene i Herrens, hærskarenes Guds hus og profetene: Skal vi gråte og faste i den femte måned, som vi nu har gjort i så mange år? 
38:7:4: Da kom Herrens, hærskarenes Guds ord til mig, og det lød så: 
38:7:5: Si til alt folket i landet og til prestene: Når I har fastet og klaget i den femte* og i den syvende** måned, og det nu i sytti år***, er det da for min skyld I har fastet? # <* da blev templet ødelagt; 2KG 25, 8 fg.> # <** da blev Gedalja myrdet; 2KG 25, 25. JER 41, 2.> # <*** SKR 1, 12. JER 25, 11.> 
38:7:6: Og når I eter og drikker, er det da ikke I selv som eter, og I selv som drikker? 
38:7:7: Minnes I ikke de ord Herren lot utrope ved de forrige profeter, da Jerusalem lå der i ro og fred med sine byer rundt om, og likeså sydlandet og lavlandet? 
38:7:8: Og Herrens ord kom til Sakarias, og det lød så: 
38:7:9: Så sa Herren, hærskarenes Gud: Døm rettferdige dommer og vis miskunnhet og barmhjertighet mot hverandre, 
38:7:10: og undertrykk ikke enker og farløse, fremmede og arminger, og tenk ikke ut ondt mot hverandre i eders hjerte! 
38:7:11: Men de vilde ikke akte på det, men satte i sin gjenstridighet skulderen imot, og sine ører gjorde de døve, så de ikke hørte, 
38:7:12: og sitt hjerte gjorde de hårdt som en diamant, så de ikke hørte på loven og de ord Herren, hærskarenes Gud, sendte ved sin Ånd gjennem de forrige profeter; derfor kom det en stor vrede fra Herren, hærskarenes Gud. 
38:7:13: Og likesom han ropte, og de ikke hørte, således, sa Herren, hærskarenes Gud, skal de rope, og jeg vil ikke høre, 
38:7:14: men jeg vil sprede dem som i en stormvind blandt alle hedningefolkene, som de ikke kjenner, og landet skal ligge øde efter dem, så ingen drar frem eller tilbake der. Og således gjorde de det herlige land til en ørken. 
38:8:1: Og Herrens, hærskarenes Guds ord kom, og det lød så: 
38:8:2: Så sier Herren, hærskarenes Gud: Jeg er såre nidkjær for Sion, ja, med stor harme er jeg nidkjær for det. 
38:8:3: Så sier Herren: Jeg vender tilbake til Sion og vil bo i Jerusalem, og Jerusalem skal kalles den trofaste stad, og Herrens, hærskarenes Guds berg det hellige berg. 
38:8:4: Så sier Herren, hærskarenes Gud: Ennu en gang skal gamle menn og kvinner sitte på torvene i Jerusalem, hver med sin stav i hånden for sin høie alders skyld, 
38:8:5: og torvene i byen skal være fulle av gutter og piker, som leker på torvene der. 
38:8:6: Så sier Herren, hærskarenes Gud: Om dette kan være umulig i deres øine som er blitt igjen av dette folk i de dager, mon det da også skulde være umulig i mine øine? sier Herren, hærskarenes Gud. 
38:8:7: Så sier Herren, hærskarenes Gud: Se, jeg frelser mitt folk fra de land der solen går op, og fra de land der den går ned, 
38:8:8: og jeg lar dem komme hit, og de skal bo i Jerusalem, og de skal være mitt folk, og jeg vil være deres Gud i sannhet og rettferdighet. 
38:8:9: Så sier Herren, hærskarenes Gud: La eders hender være sterke, I som i denne tid hører disse ord av de profeters munn som talte på den dag da grunnen blev lagt til Herrens, hærskarenes Guds hus, templet som skulde bygges! 
38:8:10: For før den tid kom det intet ut hverken av menneskers eller dyrs arbeid; og ingen hadde fred for fienden, enten han drog ut eller han drog inn; for jeg slapp alle mennesker løs mot hverandre. 
38:8:11: Men nu vil jeg ikke være som i tidligere dager mot dem som er blitt igjen av dette folk, sier Herren, hærskarenes Gud; 
38:8:12: nu skal sæden være fredet: Vintreet skal gi sin frukt, jorden skal gi sin grøde, og himmelen skal gi sin dugg, og jeg vil la dem som er blitt igjen av dette folk, få alt dette til eie. 
38:8:13: Og det skal skje at likesom I, Judas hus og Israels hus, har vært en forbannelse blandt hedningene, således vil jeg nu frelse eder, og I skal bli en velsignelse; frykt ikke! La eders hender være sterke! 
38:8:14: For så sier Herren, hærskarenes Gud: Likesom jeg satte mig fore å gjøre eder ondt da eders fedre vakte min vrede, sier Herren, hærskarenes Gud, og jeg ikke angret det, 
38:8:15: således har jeg i disse dager satt mig fore å gjøre vel mot Jerusalem og Judas hus; frykt ikke! 
38:8:16: Dette er det I skal gjøre: Tal sannhet med hverandre, døm på tinge rettferdige dommer og dommer som skaper fred, 
38:8:17: tenk ikke ut ondt mot hverandre i eders hjerte, og elsk ikke falsk ed! For alt dette hater jeg, sier Herren. 
38:8:18: Og Herrens, hærskarenes Guds ord kom til mig, og det lød så: 
38:8:19: Så sier Herren, hærskarenes Gud: Fasten i den fjerde måned* og i den femte** og i den syvende** og i den tiende*** måned** skal bli Judas hus til fryd og glede og til glade høitider. Men elsk sannhet og fred! # <* da blev Jerusalem inntatt; JER 39, 2; 52, 6. 7.> # <** SKR 7, 5.> # <*** da begynte Jerusalems beleiring; 2KG 25, 1. ESK 24. 1. 2.> 
38:8:20: Så sier Herren, hærskarenes Gud: Ennu en gang skal det skje at hele folkeslag og mange byers innbyggere skal komme hit. 
38:8:21: Og innbyggerne i den ene by skal gå til den andre og si: La oss gå avsted for å bønnfalle Herren og for å søke ham, Herren, hærskarenes Gud! Jeg vil gå, jeg og. 
38:8:22: Og mange folkeslag og tallrike hedningefolk skal komme for å søke Herren, hærskarenes Gud, i Jerusalem og for å bønnfalle Herren. 
38:8:23: Så sier Herren, hærskarenes Gud: I de dager skal det skje at ti menn av alle hedningefolkenes tungemål skal gripe fatt i en jødisk manns kappefald og si: Vi vil gå med eder; for vi har hørt at Gud er med eder. 
38:9:1: Dette utsagn er Herrens ord mot Hadraks land*; også i Damaskus skal det slå sig ned - for Herren har øie med menneskene og med alle Israels stammer - # <* visstnok et lite rike i Syria.> 
38:9:2: likeså i Hamat, som grenser til det, også i Tyrus og Sidon, fordi de der er så kloke. 
38:9:3: Tyrus bygget sig en festning, og det dynget op sølv som støv og fint gull som skarn på gatene. 
38:9:4: Se, Herren skal la fremmede få det til eie og styrte dets murer i havet, og selv skal det fortæres av ild. 
38:9:5: Askalon ser det og frykter, og Gasa, som vrir sig i angst, likeså Ekron, fordi dets håp* er blitt til skamme; Gasa skal miste sin konge, og i Askalon skal ingen bo. # <* d.e. Tyrus.> 
38:9:6: I Asdod skal det bare bo fremmed pakk, og jeg vil utrydde filistrenes stolthet, 
38:9:7: jeg vil ta offerblodet* ut av deres munn og det vederstyggelige offerkjøtt bort fra deres tenner; og da skal det også av dem bli igjen en rest for vår Gud, og de skal være som fyrster i Juda, og Ekron skal være som en jebusitt**. # <* SLM 16, 4.> # <** d.e. innlemmes blandt Guds folk, likesom jebusittene av David blev innlemmet i det; 2SA 24, 18 fg. 1KR 21, 18 fg.> 
38:9:8: Og jeg vil slå leir til vern for mitt hus mot krigshærer som kommer og går, og ingen voldsherre skal mere komme over dem; for nu ser jeg med egne øine hvorledes det er. 
38:9:9: Fryd dig storlig, Sions datter! Rop høit, Jerusalems datter! Se, din konge kommer til dig, rettferdig er han og full av frelse*, fattig og ridende på et asen, på aseninnens unge fole. # <* HEB 5, 7. 9.> 
38:9:10: Og jeg vil utrydde vognene i Efra'im og hestene i Jerusalem, og alle krigsbuer skal utryddes, Og han skal tale fred til hedningene, og hans herredømme skal nå fra hav til hav og fra elven til jordens ender. 
38:9:11: For ditt paktsblods skyld vil jeg også fri dine fanger ut av brønnen som det ikke er vann i. 
38:9:12: Vend tilbake til festningen, I fanger som har håp! Også idag forkynner jeg at jeg vil gi eder dobbelt igjen. 
38:9:13: For jeg spenner Juda som min bue og legger Efra'im på buen, og jeg egger dine sønner, Sion, mot dine sønner, Javan*, og jeg gjør dig lik en kjempes sverd. # <* d.e. Grekenland.> 
38:9:14: Og Herren skal åpenbare sig over dem, og hans pil skal fare ut som lynet; Herren, Israels Gud, skal støte i basunen og fare frem i sønnenstormene. 
38:9:15: Herren, hærskarenes Gud, skal verne dem, og de skal opsluke sine fiender og trå dem under føtter som slyngestener*; de skal drikke deres blod og larme som av vin, og de skal bli fulle av blodet som offerskålen, som alterets hjørner**. # <* Fienden lignes med ringe stener, motsatt kronestenene; SKR 9, 16.> # <** 2MO 29, 12.> 
38:9:16: Og Herren deres Gud skal frelse dem på den dag, han skal frelse sitt folk som en hjord; for de er som edelstener i en krone og stråler over hans land*; # <* 2SA 12, 30.> 
38:9:17: for hvor herlig er det ikke, og hvor fagert! Av dets korn skal unge menn og av dets vin jomfruer blomstre op. 
38:10:1: Bed Herren om regn når tiden for vårregnet er inne! Herren sender lynstråler, og regnskyll skal han gi dem, så hver mann får grøde på sin mark. 
38:10:2: For husgudene* talte usant, og spåmennene skuet løgn; tomme drømmer forkynte de, og den trøst de gav, var intet verd; derfor måtte folket dra avsted som får og li ondt, fordi der ingen hyrde var. # <* 1MO 31, 19.> 
38:10:3: Mot hyrdene* er min vrede optendt, og bukkene* vil jeg hjemsøke; for Herren, hærskarenes Gud, ser til sin hjord, Judas hus, og gjør den lik sin stolte stridshest. # <* d.e. folkets fremmede herskere.> 
38:10:4: Fra det* skal hjørnestenene komme, fra det naglene** , fra det krigsbuene - fra det skal alle herskere utgå. # <* Judas hus.> # <** JES 22, 23. 25.> 
38:10:5: Og de skal være lik kjemper som trår sine fiender ned i krigen likesom skarnet på gatene; ja, stride skal de, for Herren er med dem, og de som rider på hester, skal bli til skamme. 
38:10:6: Jeg vil styrke Judas hus og frelse Josefs hus og gi dem et bosted; for jeg forbarmer mig over dem, og de skal være som om jeg aldri hadde forkastet dem; for jeg er Herren deres Gud og vil bønnhøre dem. 
38:10:7: Og Efra'ims menn skal være lik kjemper, og deres hjerter skal bli glade som av vin; deres barn skal se det og glede sig, deres hjerter skal fryde sig i Herren. 
38:10:8: Jeg vil pipe til dem og samle dem, for jeg har utfridd dem; og de skal bli tallrike, som de har vært. 
38:10:9: Og jeg vil så dem ut blandt folkene, men i de fjerne land skal de komme mig i hu; og de skal leve med sine barn og komme tilbake. 
38:10:10: Og jeg vil føre dem tilbake fra Egyptens land og samle dem fra Assur og føre dem til Gileads land og til Libanon, og der skal det ikke bli rum nok for dem. 
38:10:11: Han* skal dra frem gjennem havet, gjennem farer, og slå ned bølgene i havet, og alle Nilens dyp skal tørkes ut, og Assurs stolthet skal støtes ned, og kongespiret skal vike fra Egypten. # <* Herren.> 
38:10:12: Jeg vil gjøre dem sterke i Herren, og i hans navn skal de gå frem, sier Herren. 
38:11:1: Lat op dine porter, Libanon, så ilden kan fortære dine sedrer! 
38:11:2: Jamre dig, du cypress, fordi sederen er falt, fordi de herlige trær er ødelagt! Jamre eder, I Basans eker, fordi den utilgjengelige skog er felt! 
38:11:3: Hør hvor hyrdene jamrer fordi deres herlighet* er ødelagt! Hør hvor de unge løver brøler fordi Jordans prakt** er ødelagt. # <* herlige beitemark; JER 25, 36.> # <** JER 12, 5.> 
38:11:4: Så sa Herren min Gud: Røkt slaktefårene! 
38:11:5: De som kjøper dem, slakter dem uten å bøte for det, og de som selger dem, sier: Lovet være Herren! nu blir jeg rik; og deres hyrder sparer dem ikke. 
38:11:6: For jeg vil ikke mere spare landets innbyggere, sier Herren; men jeg vil overgi menneskene i hverandres hender og i hendene på deres konge, og de skal herje landet, og jeg vil ikke redde nogen av deres hånd. 
38:11:7: Så røktet jeg slaktefårene, endog de usleste av fårene; og jeg tok mig to staver, den ene kalte jeg liflighet, og den andre kalte jeg bånd*, og jeg røktet fårene, # <* SKR 11, 14.> 
38:11:8: og jeg gjorde de tre hyrder til intet i én måned. Jeg blev lei av dem, og de likte heller ikke mig, 
38:11:9: og jeg sa: Jeg vil ikke lenger røkte eder; det som er nær ved å dø, får dø, og det som er nær ved å bli tilintetgjort, får bli tilintetgjort, og de som blir igjen, får fortære hverandres kjøtt. 
38:11:10: Så tok jeg min stav liflighet og brøt den i stykker for å gjøre den pakt til intet som jeg hadde gjort med alle folkene. 
38:11:11: Og den blev gjort til intet på den dag; og således skjønte de usleste av fårene, de som aktet på mig, at det var Herrens ord. 
38:11:12: Derefter sa jeg til dem: Om I så synes, så gi mig min lønn, men hvis ikke, så la det være! Så veide de op til mig min lønn, tretti sølvpenninger. 
38:11:13: Da sa Herren til mig: Kast den bort til pottemakeren, den herlige pris som jeg er aktet verd av dem! Og jeg tok de tretti sølvpenninger og kastet dem i Herrens hus til pottemakeren. 
38:11:14: Så brøt jeg i stykker min andre stav bånd for å gjøre brorskapet mellem Juda og Israel til intet. 
38:11:15: Og Herren sa til mig: Ta dig atter en stav - en dårlig hyrdes stav! 
38:11:16: For se, jeg lar en hyrde stå frem i landet, en som ikke ser til de får som er nær ved å omkomme, ikke søker efter det som er bortkommet, og ikke læger det som har fått skade, og ikke sørger for det som holder sig oppe, men eter kjøttet av de fete og endog bryter deres klover i stykker. 
38:11:17: Ve den dårlige hyrde, som forlater fårene! Sverd over hans arm og over hans høire øie! Hans arm skal visne bort, og hans høire øie skal utslukkes. 
38:12:1: Dette utsagn er Herrens ord om Israel. Så sier Herren, som utspente himmelen og grunnfestet jorden og dannet menneskets ånd i hans indre: 
38:12:2: Se, jeg gjør Jerusalem til en tumleskål* for alle folkene rundt omkring; også over Juda skal det gå ut når Jerusalem blir kringsatt. # <* d.e. en skål med berusende vin; SLM 60, 5. JES 51, 17 fg.> 
38:12:3: Og det skal skje på den dag at jeg vil gjøre Jerusalem til en sten å løfte på for alle folkene; alle som løfter på den, skal såre sig selv. Ja, alle jordens hedningefolk skal samle sig mot det. 
38:12:4: På den dag, sier Herren, vil jeg slå hver hest med skyhet og dens rytter med vanvidd; men over Judas hus vil jeg oplate mine øine, og alle folkenes hester vil jeg slå med blindhet. 
38:12:5: Og Judas stammehøvdinger skal si i sitt hjerte: Vår styrke er Jerusalems innbyggere ved Herren, hærskarenes Gud, deres Gud. 
38:12:6: På den dag vil jeg gjøre Judas stammehøvdinger lik et fyrfat mellem vedtrær og et ildbluss blandt kornbånd, og de skal fortære alle folkene rundt omkring, til høire og til venstre; og Jerusalem-folket skal fremdeles bli på sitt sted, i Jerusalem. 
38:12:7: Og Herren skal frelse Judas telter først, forat ikke Davids hus og Jerusalems innbyggere skal ha større herlighet enn Juda. 
38:12:8: På den dag skal Herren verne Jerusalems innbyggere, og den iblandt dem som snubler, skal på den dag være som David, og Davids hus skal være som Gud, som Herrens engel foran dem*. # <* 2MO 14, 19.> 
38:12:9: Og på den dag vil jeg søke å ødelegge alle de hedningefolk som drar mot Jerusalem. 
38:12:10: Men over Davids hus og over Jerusalems innbyggere vil jeg utgyde nådens og bønnens ånd, og de skal skue op til mig som de har gjennemstunget; og de skal sørge over ham som en sørger over sin enbårne sønn, og klage sårt over ham som en klager over sin førstefødte. 
38:12:11: På den dag skal sorgen bli stor i Jerusalem, som sorgen over ulykken i Hadadrimmon i Megiddons dal*. # <* det sted hvor den fromme konge Josias blev dødelig såret; 2KR 35, 22 fg.> 
38:12:12: Og landet skal sørge, hver slekt for sig, Davids huses slekt for sig og deres kvinner for sig, Natans huses* slekt for sig og deres kvinner for sig, # <* 2SA 5, 14. LUK 3, 31.> 
38:12:13: Levis huses slekt for sig og deres kvinner for sig, sime'ittenes slekt for sig og deres kvinner for sig, 
38:12:14: og likeså alle de andre slekter, hver slekt for sig og deres kvinner for sig. 
38:13:1: På den dag skal det være en åpnet kilde for Davids hus og for Jerusalems innbyggere mot synd og urenhet. 
38:13:2: Og det skal skje på den dag, sier Herren, hærskarenes Gud, at jeg vil utrydde avgudenes navn av landet, og de skal ikke mere kommes i hu; også profetene og den urene ånd vil jeg få bort av landet. 
38:13:3: Og om nogen herefter står frem som profet, da skal hans far og mor, hans egne foreldre, si til ham: Du skal ikke leve, for du har talt løgn i Herrens navn. Og hans far og mor, hans egne foreldre, skal gjennembore ham mens han profeterer. 
38:13:4: På den dag skal alle profeter skamme sig over sine syner når de vil profetere, og de skal ikke klæ sig i en lodden kappe for å lyve. 
38:13:5: Men hver av dem skal si: Jeg er ikke nogen profet; jeg er jordbruker, for det var en som kjøpte mig til træl da jeg var ung gutt. 
38:13:6: Og om nogen spør ham: Hvad er det for sår du har i dine hender? da skal han svare: Det er sår jeg har fått i mine elskeres* hus. # <* d.e. avgudenes.> 
38:13:7: Sverd! Våkn op mot min hyrde, mot den mann som er min næste! sier Herren, hærskarenes Gud; slå hyrden, og fårene skal adspredes, og jeg vil igjen ta mig av de små*! # <* MTT 18, 3 fg.> 
38:13:8: Og i hele landet, sier Herren, skal to tredjedeler utryddes og omkomme, bare en tredjedel skal levnes der. 
38:13:9: Og denne tredjedel vil jeg la gå gjennem ilden og rense den, som en renser sølv, og prøve den, som en prøver gull. De skal påkalle mitt navn, og jeg vil bønnhøre dem; jeg vil si: De er mitt folk, og de skal si: Herren er min Gud. 
38:14:1: Se, det kommer en Herrens dag, da det hærfang som er tatt fra dig, skal skiftes ut i din midte; 
38:14:2: for jeg vil samle alle hedningefolkene til krig mot Jerusalem, og staden skal bli tatt og husene bli plyndret, og kvinnene bli skjendet, og halvdelen av staden skal måtte gå i landflyktighet, men resten av folket skal ikke utryddes av staden. 
38:14:3: For Herren skal dra ut og stride mot disse hedningefolk, som han før har stridt på kampens dag. 
38:14:4: På den dag skal hans føtter stå på Oljeberget, som ligger midt imot Jerusalem i øst, og Oljeberget skal revne tvert over mot øst og vest, så der blir en stor dal, idet den ene halvdel av fjellet viker mot nord, og den andre halvdel mot syd. 
38:14:5: Og I skal fly til dalen mellem mine fjell, for dalen mellem fjellene skal nå like til Asel*; I skal fly som I flydde for jordskjelvet i Judas konge Ussias' dager. Da skal Herren min Gud komme, og alle hellige med dig, min Gud. # <* MIK 1, 11.> 
38:14:6: På den dag skal schmelken bli borte; de herlige himmellys skal formørkes. 
38:14:7: Og det skal komme én dag - Herren kjenner den - det skal hverken være dag eller natt; men mot aftens tid, da skal det bli schmelk. 
38:14:8: Og på den dag skal levende vann strømme ut fra Jerusalem, den ene halvdel av dem til havet i øst og den andre halvdel av dem til havet i vest; både sommer og vinter skal det være så. 
38:14:9: Da skal Herren bli konge over hele landet; på den dag skal Herren være én, og hans navn ett. 
38:14:10: Og hele landet, fra Geba til Rimmon sønnenfor Jerusalem, skal bli som den øde mark*, og det** skal heve sig høit og trone på sitt sted, like fra Benjamin-porten til det sted hvor den forrige port var, til Hjørneporten, og fra Hananels tårn til kongens vinperser. # <* d.e. Jordan-dalen.> # <** Jerusalem.> 
38:14:11: Og folket skal bo der, og der skal aldri mere lyses bann over det; Jerusalem skal ligge der trygt. 
38:14:12: Men denne plage skal Herren la ramme alle de folk som har stridt imot Jerusalem: Han skal la kjøttet råtne på dem alle mens de står på sine føtter, og deres øine skal råtne i sine huler, og tungen skal råtne i deres munn. 
38:14:13: På den dag skal Herren sende en stor redsel over dem, så de tar fatt på hverandre og løfter hånd mot hverandre. 
38:14:14: Også Juda skal stride i Jerusalem, og fra alle kanter skal hedningefolkenes gods sankes sammen, gull og sølv og klær i stor mengde. 
38:14:15: Den samme plage* skal også ramme hestene, muleslene, kamelene, asenene og alle de andre dyr i leirene. # < V. 12.> 
38:14:16: Og alle de som blir igjen av alle de hedningefolk som angrep Jerusalem, skal år efter år dra op for å tilbede Kongen, Herren, hærskarenes Gud, og for å delta i løvsalenes fest. 
38:14:17: Men om noget av jordens folk ikke drar op til Jerusalem for å tilbede Kongen, Herren, hærskarenes Gud, så skal det ikke komme regn hos dem; 
38:14:18: om Egyptens folk ikke drar op og ikke møter frem, da skal det ikke komme regn hos dem heller; den samme plage skal ramme dem som Herren lar ramme de hedningefolk som ikke drar op for å delta i løvsalenes fest. 
38:14:19: Sådan skal den straff være som rammer Egypten og alle de hedningefolk som ikke drar op for å delta i løvsalenes fest. 
38:14:20: På den dag skal det stå på hestenes bjeller: Helliget til Herren, og grytene i Herrens hus skal være som offerskålene foran alteret, 
38:14:21: og hver gryte i Jerusalem og i Juda skal være helliget til Herren, hærskarenes Gud, og alle de som ofrer, skal komme og ta av dem til å koke i; og det skal ikke mere være nogen kana'anitt* i Herrens, hærskarenes Guds hus på den dag. # <* JES 35, 8; 52, 1. JOE 3, 22.> 
39:1:1: Dette utsagn er Herrens ord til Israel ved Malakias. 
39:1:2: Jeg har elsket eder, sier Herren. Og I sier: Hvormed har du vist oss din kjærlighet? Er ikke Esau bror til Jakob? sier Herren, og enda har jeg elsket Jakob, 
39:1:3: men hatet Esau og gjort hans fjell til en ørken og hans arv til en bolig for ørkenens sjakaler. 
39:1:4: Når Edom sier: Vi er knust, men vi skal bygge ruinene op igjen, da sier Herren, hærskarenes Gud, så: De skal bygge, men jeg skal rive ned, og de skal kalles ugudelighetens land og det folk som Herren til evig tid er vred på. 
39:1:5: I skal se det med egne øine, og I skal si: Herren er stor ut over Israels landemerke. 
39:1:6: En sønn ærer sin far, og en tjener sin herre; er nu jeg far, hvor er da min ære, og er jeg herre, hvor er da frykten for mig? sier Herren, hærskarenes Gud, til eder, I prester som forakter mitt navn. Og I sier: Hvormed har vi vist forakt for ditt navn? 
39:1:7: Ved å bære frem uren mat på mitt alter. Og I sier: Hvormed har vi krenket din renhet? Ved å si: Herrens bord er ingen ære verdt. 
39:1:8: Når I kommer med et blindt dyr for å ofre det, er det da intet ondt deri? Og når I kommer med et halt eller et sykt dyr, er det da intet ondt deri? Kom med slike gaver til din stattholder! Mon han vil finne behag i dig, eller mon han vil ta nådig imot dig? sier Herren, hærskarenes Gud. 
39:1:9: Så bønnfall nu Gud at han må bli oss nådig! Med egne hender har I gjort dette - kan han da for eders skyld være nådig? sier Herren, hærskarenes Gud. 
39:1:10: Gid det fantes nogen blandt eder som vilde lukke tempeldørene, så I ikke skal gjøre op ild på mitt alter til ingen nytte! Jeg finner ikke behag i eder, sier Herren, hærskarenes Gud, og til offergaver fra eders hånd har jeg ingen lyst. 
39:1:11: For fra solens opgang til dens nedgang skal mitt navn bli stort blandt hedningefolkene, og på hvert sted skal det brennes røkelse og bæres frem offergaver for mitt navn, rene offergaver; for mitt navn skal bli stort blandt folkene, sier Herren, hærskarenes Gud. 
39:1:12: Men I vanhelliger det ved å si: Herrens bord er urent, og maten som gis til det, er lite verd. 
39:1:13: Og I sier: Å, for en møie! Og I blåser av det, sier Herren, hærskarenes Gud, og I bærer frem slikt som er røvet, og det som er halt og sykt - sådant bærer I frem som offergave! Skulde slike gaver fra eder behage mig? sier Herren. 
39:1:14: Forbannet være den som farer med svik*, enda der er handyr i hans hjord, og den som gjør et løfte og enda ofrer til Herren et hundyr som har lyte; for jeg er en stor konge, sier Herren, hærskarenes Gud, og mitt navn er forferdelig blandt folkene. # <* ved å foregi at han bare har hundyr å ofre.> 
39:2:1: Og nu kommer dette bud til eder, I prester! 
39:2:2: Dersom I ikke vil høre og ikke legge eder det* på hjerte, så I gir mitt navn ære, sier Herren, hærskarenes Gud, så vil jeg sende forbannelsen mot eder og forbanne eders velsignelser; ja, jeg har alt forbannet dem, fordi I ikke legger eder det på hjerte. # <* MLK 1, 6 fg.> 
39:2:3: Se, jeg forbanner eders ætt og kaster møkk i ansiktet på eder, møkk av eders høitidsoffere*; og I skal selv bli kastet op i den. # <* 2MO 29, 14.> 
39:2:4: Da skal I kjenne at jeg har sendt eder dette bud, forat det skal være min pakt med Levi, sier Herren, hærskarenes Gud. 
39:2:5: Den pakt jeg hadde med ham, lovte ham liv og lykke; det gav jeg ham forat han skulde frykte mig, og han fryktet mig og var forferdet for mitt navn. 
39:2:6: Sannhets lov var i hans munn, og urett blev ikke funnet på hans leber; i fred og ærlighet vandret han med mig, og mange omvendte han fra misgjerning. 
39:2:7: For en prests leber skal holde fast ved kunnskap, og lov skal hentes fra hans munn; for han er Herrens, hærskarenes Guds sendebud. 
39:2:8: Men I har veket av fra veien og har fått mange til å støte an mot loven; I har fordervet Levi-pakten, sier Herren, hærskarenes Gud. 
39:2:9: Derfor har også jeg gjort eder foraktet og ringe i alt folkets øine, fordi I ikke akter på mine veier, men gjør forskjell på folk for loven*. # <* d.e. når I skal dømme efter loven.> 
39:2:10: Har vi ikke alle sammen én far? Har ikke en Gud skapt oss? Hvorfor er vi da troløse mot hverandre, så vi vanhelliger våre fedres pakt? 
39:2:11: Juda har vært troløs, og vederstyggelige ting er gjort i Israel og i Jerusalem; for Juda har vanhelliget Herrens helligdom, som han elsker, og har tatt en fremmed guds døtre til ekte. 
39:2:12: Hos den mann som det gjør, skal Herren utrydde av Jakobs telter hver levende sjel, og den som bærer offergaver frem for Herren, hærskarenes Gud! 
39:2:13: For det annet gjør I også dette: I dekker Herrens alter med tårer, med gråt og sukk*, så han ikke mere ser på offergaven eller med velbehag tar imot noget av eders hånd**. # <* nemlig: av eders forskutte hustruer.> # <** MLK 1, 10.> 
39:2:14: Og I sier: Hvorfor? Fordi Herren har vært vidne mellem dig og din ungdoms hustru, som du har vært troløs mot, enda hun er din ektemake og din hustru som du har inngått pakt med. 
39:2:15: Men har ikke en* gjort det og er enda blitt i live? Men hvad gjorde denne ene? Han vilde opnå den ætt Gud hadde lovt ham**; men I skal ta vare på eders liv, og mot din ungdoms hustru må du ikke være troløs! # <* Abraham; 1MO 16, 4 fg. JES 51, 2. ESK 33, 24.> # <** 1MO 15, 4 fg. 16, 2.> 
39:2:16: For jeg hater skilsmisse, sier Herren, Israels Gud; en dekker derved sitt klædebon med vold, sier Herren, hærskarenes Gud. Så ta da vare på eders liv og vær ikke troløse! 
39:2:17: I har trettet Herren med eders ord, og I sier: Hvorledes har vi trettet ham? Ved å si: Hver den som gjør ondt, er god i Herrens øine, og i sådanne har han velbehag, eller: Hvor er Gud, han som dømmer? 
39:3:1: Se, jeg sender mitt bud, og han skal rydde vei for mitt åsyn; og brått skal han komme til sitt tempel, Herren som I søker, paktens engel som I stunder efter; se, han kommer, sier Herren, hærskarenes Gud. 
39:3:2: Men hvem kan utholde den dag han kommer, og hvem kan bli stående når han lar sig se? For han er som en smelters ild og som tvetteres lut. 
39:3:3: Og han skal sitte og smelte og rense sølvet, og han skal rense Levis barn og gjøre dem rene som gull og sølv; og de skal bære frem for Herren offergaver i rettferdighet, 
39:3:4: og Judas og Jerusalems offergaver skal behage Herren, som i gamle dager, som i fordums år. 
39:3:5: Og jeg vil komme til eder og holde dom og være et hastig vidne mot trollkarene og horkarlene og dem som sverger falsk, og mot dem som forholder dagarbeideren hans lønn og gjør vold mot enken og den farløse, og som bøier retten for den fremmede og ikke frykter mig, sier Herren, hærskarenes Gud; 
39:3:6: for jeg, Herren, har ikke forandret mig, og I, Jakobs barn, er ikke tilintetgjort. 
39:3:7: Like fra eders fedres dager har I veket av fra mine lover og ikke holdt dem; vend om til mig, så vil jeg vende om til eder, sier Herren, hærskarenes Gud. Og I sier: Hvad skal vi vende om fra? 
39:3:8: Skal et menneske rane fra Gud, siden I raner fra mig? Og I sier: Hvad har vi rant fra dig? Tienden og de hellige gaver. 
39:3:9: Forbannelsen har rammet eder, og fra mig raner I, ja hele folket. 
39:3:10: Bær hele tienden inn i forrådshuset, så det kan finnes mat i mitt hus, og prøv mig på denne måte, sier Herren, hærskarenes Gud, om jeg ikke vil åpne himmelens sluser for eder og utøse velsignelser over eder i rikelig mål! 
39:3:11: Og jeg vil true eteren* for eders skyld, så den ikke ødelegger jordens avgrøde for eder; og vintreet på marken skal ikke slå feil for eder, sier Herren, hærskarenes Gud. # <* gresshoppen.> 
39:3:12: Og alle folkene skal prise eder lykkelige; for da skal eders land være et herlig land, sier Herren, hærskarenes Gud. 
39:3:13: Eders ord har vært sterke mot mig, sier Herren. Og I sier: Hvad har vi sagt oss imellem mot dig? 
39:3:14: I har sagt: Fåfengt er det å tjene Gud, og hvad vinning har det vært for oss at vi har aktet på hans bud, og at vi har gått i sørgeklær for Herrens, hærskarenes Guds skyld? 
39:3:15: Og nu priser vi de overmodige lykkelige; ikke alene trives de vel de som lever ugudelig, men de har satt Gud på prøve og har allikevel sloppet fri. 
39:3:16: Da talte de med hverandre de som frykter Herren, og Herren lyttet til og hørte det, og det blev for hans åsyn skrevet en minnebok for dem som frykter Herren og tenker på hans navn. 
39:3:17: Og på den dag som jeg skaper, sier Herren, hærskarenes Gud, skal de være min eiendom, og jeg vil spare dem, likesom en mann sparer sin sønn som tjener ham. 
39:3:18: Da skal I atter se forskjell mellem den rettferdige og den ugudelige, mellem den som tjener Gud, og den som ikke tjener ham. 
39:4:1: For se, dagen kommer, brennende som en ovn; da skal alle overmodige og hver den som lever ugudelig, være som halm, og dagen som kommer, skal sette dem i brand, sier Herren, hærskarenes Gud, så den ikke levner dem rot eller gren. 
39:4:2: Men for eder som frykter mitt navn, skal rettferdighetens sol gå op med lægedom under sine vinger; og I skal gå ut og hoppe som gjøkalver, 
39:4:3: og I skal trå ned de ugudelige, for de skal være som aske under eders fotsåler, på den dag jeg skaper, sier Herren, hærskarenes Gud. 
39:4:4: Kom i hu Mose, min tjeners lov, som jeg gav ham på Horeb for hele Israel, både lover og bud! 
39:4:5: Se, jeg sender eder Elias, profeten, før Herrens dag kommer, den store og forferdelige; 
39:4:6: og han skal vende fedrenes hjerte til barna, og barnas hjerte til deres fedre, så jeg ikke skal komme og slå landet med bann. 
40:1:1: Jesu Kristi, Davids sønns, Abrahams sønns ættetavle: 
40:1:2: Abraham fikk sønnen Isak; Isak fikk sønnen Jakob; Jakob fikk Juda og hans brødre; 
40:1:3: Juda fikk sønnene Peres og Serah med Tamar; Peres fikk sønnen Hesron; Hesron fikk sønnen Ram; 
40:1:4: Ram fikk sønnen Aminadab; Aminadab fikk sønnen Nahson; Nahson fikk sønnen Salmon; 
40:1:5: Salmon fikk sønnen Boas med Rahab; Boas fikk sønnen Obed med Rut; Obed fikk sønnen Isai; 
40:1:6: Isai var far til kong David. David fikk sønnen Salomo med Urias hustru; 
40:1:7: Salomo fikk sønnen Rehabeam; Rehabeam fikk sønnen Abia; Abia fikk sønnen Asa; 
40:1:8: Asa fikk sønnen Josafat; Josafat fikk sønnen Joram; Joram fikk sønnen Ussias; 
40:1:9: Ussias fikk sønnen Jotam; Jotam fikk sønnen Akas; Akas fikk sønnen Esekias; 
40:1:10: Esekias fikk sønnen Manasse; Manasse fikk sønnen Amon; Amon fikk sønnen Josias; 
40:1:11: Josias fikk Jekonja og hans brødre ved den tid da folket blev bortført til Babylon. 
40:1:12: Efter bortførelsen til Babylon fikk Jekonja sønnen Sealtiel; Sealtiel fikk sønnen Serubabel; 
40:1:13: Serubabel fikk sønnen Abiud; Abiud fikk sønnen Eljakim; Eljakim fikk sønnen Asor; 
40:1:14: Asor fikk sønnen Sadok; Sadok fikk sønnen Akim; Akim fikk sønnen Eliud; 
40:1:15: Eliud fikk sønnen Eleasar; Eleasar fikk sønnen Mattan; Mattan fikk sønnen Jakob; 
40:1:16: Jakob var far til Josef, Marias mann, og av henne blev Jesus født, han som kalles Kristus. 
40:1:17: Således er alle ætt-leddene fra Abraham inntil David fjorten ledd, og fra David inntil bortførelsen til Babylon fjorten ledd, og fra bortførelsen til Babylon inntil Kristus fjorten ledd. 
40:1:18: Men med Jesu Kristi fødsel gikk det således til: Da hans mor Maria var trolovet med Josef, viste det sig, før de var kommet sammen, at hun var fruktsommelig ved den Hellige Ånd. 
40:1:19: Men Josef, hennes mann, som var rettferdig og ikke vilde føre skam over henne, vilde skille sig fra henne i stillhet. 
40:1:20: Mens han nu grundet på dette, se, da åpenbarte Herrens engel sig for ham i en drøm og sa: Josef, Davids sønn! frykt ikke for å ta din hustru Maria til dig! for det som er avlet i henne, er av den Hellige Ånd; 
40:1:21: og hun skal føde en sønn, og du skal kalle ham Jesus; for han skal frelse sitt folk fra deres synder. 
40:1:22: Men alt dette skjedde forat det skulde opfylles som er talt av Herren ved profeten, som sier: 
40:1:23: Se, en jomfru skal bli fruktsommelig og føde en sønn, og han skal kalles Immanuel, det er utlagt: Gud med oss. 
40:1:24: Da nu Josef var våknet op av søvnen, gjorde han som Herrens engel hadde pålagt ham, og tok sin hustru til sig. 
40:1:25: Og han holdt sig ikke til henne før hun hadde født sin sønn, og han kalte ham Jesus. 
40:2:1: Da Jesus var født i Betlehem i Judea, i kong Herodes' dager, se, da kom nogen vismenn fra Østerland til Jerusalem og sa: 
40:2:2: Hvor er den jødenes konge som nu er født? Vi har sett hans stjerne i Østen og er kommet for å tilbede ham. 
40:2:3: Men da kong Herodes hørte det, blev han forferdet, og hele Jerusalem med ham. 
40:2:4: Og han sammenkalte alle yppersteprestene og de skriftlærde blandt folket og spurte dem hvor Messias skulde fødes. 
40:2:5: De sa til ham: I Betlehem i Judea; for så er skrevet ved profeten: 
40:2:6: Og du Betlehem i Juda land, du er ingenlunde den ringeste blandt høvdingene i Juda; for fra dig skal utgå en høvding som skal være hyrde for mitt folk Israel. 
40:2:7: Da kalte Herodes hemmelig vismennene til sig og spurte dem nøie om tiden da stjernen hadde vist sig; 
40:2:8: og han sendte dem til Betlehem og sa: Gå og spør nøie om barnet; og når I har funnet det, da si mig til, forat også jeg kan komme og tilbede det! 
40:2:9: Da de hadde hørt kongens ord, drog de avsted; og se, stjernen som de hadde sett i Østen, gikk foran dem, inntil den kom og blev stående over det sted hvor barnet var. 
40:2:10: Og da de så stjernen, blev de over all måte glade. 
40:2:11: Og de gikk inn i huset og så barnet med Maria, dets mor, og de falt ned og tilbad det, og oplot sine gjemmer og bar frem gaver til det: gull og røkelse og myrra. 
40:2:12: Og da de var blitt varslet av Gud i en drøm at de ikke skulde vende tilbake til Herodes, drog de en annen vei bort til sitt land. 
40:2:13: Men da de hadde draget bort, se, da åpenbarer Herrens engel sig i en drøm for Josef og sier: Stå op, ta barnet og dets mor og fly til Egypten, og bli der inntil jeg sier dig til! for Herodes vil søke efter barnet for å drepe det. 
40:2:14: Da stod han op og tok barnet og dets mor om natten og drog bort til Egypten, 
40:2:15: og han blev der til Herodes' død, forat det skulde opfylles som er talt av Herren ved profeten, som sier: Fra Egypten kalte jeg min sønn. 
40:2:16: Da Herodes nu så at han var blitt narret av vismennene, blev han meget vred, og han sendte bud og lot drepe alle de guttebarn som var i Betlehem og alt landet deromkring, fra to år og derunder, efter den tid han nøie hadde utspurt av vismennene. 
40:2:17: Da blev det opfylt som er talt ved profeten Jeremias, som sier: 
40:2:18: En røst blev hørt i Rama, gråt og stor klage; Rakel gråt over sine barn og vilde ikke la sig trøste, for de er ikke mere til. 
40:2:19: Men da Herodes var død, se, da åpenbarer Herrens engel sig i en drøm for Josef i Egypten og sier: 
40:2:20: Stå op, ta barnet og dets mor og dra til Israels land! for de er døde som stod barnet efter livet. 
40:2:21: Og han stod op og tok barnet og dets mor og kom til Israels land. 
40:2:22: Men da han hørte at Arkelaus var konge i Judea efter sin far Herodes, fryktet han for å dra dit; men han blev varslet av Gud i en drøm og drog bort til Galilea. 
40:2:23: Og han kom og tok bolig i en by som heter Nasaret, forat det skulde opfylles som er talt ved profetene, at han skal kalles en nasareer. 
40:3:1: I de dager stod døperen Johannes frem og forkynte i Judeas ørken 
40:3:2: og sa: Omvend eder; for himlenes rike er kommet nær! 
40:3:3: For det er ham profeten Esaias taler om når han sier: Det er en røst av en som roper i ørkenen: Rydd Herrens vei, gjør hans stier jevne! 
40:3:4: Men Johannes hadde klædebon av kamelhår og lærbelte om sin lend, og hans mat var gresshopper og vill honning. 
40:3:5: Da drog Jerusalem og hele Judea og hele landet om Jordan ut til ham, 
40:3:6: og de blev døpt av ham i elven Jordan, idet de bekjente sine synder. 
40:3:7: Men da han så mange av fariseerne og sadduseerne komme til hans dåp, sa han til dem: Ormeyngel! hvem lærte eder å fly for den kommende vrede? 
40:3:8: Bær derfor frukt som er omvendelsen verdig, 
40:3:9: og tro ikke at I kan si ved eder selv: Vi har Abraham til far! for jeg sier eder at Gud kan opvekke Abraham barn av disse stener. 
40:3:10: Øksen ligger allerede ved roten av trærne; derfor blir hvert tre som ikke bærer god frukt, hugget ned og kastet på ilden. 
40:3:11: Jeg døper eder med vann til omvendelse; men han som kommer efter mig, er sterkere enn jeg, han hvis sko jeg ikke er verdig til å bære; han skal døpe eder med den Hellige Ånd og ild; 
40:3:12: han har sin kasteskovl i sin hånd, og han skal rense sin låve og samle sin hvete i laden, men agnene skal han brenne op med uslukkelig ild. 
40:3:13: Da kom Jesus fra Galilea til Jordan til Johannes for å bli døpt av ham. 
40:3:14: Men han nektet ham det og sa: Jeg trenger til å bli døpt av dig, og du kommer til mig? 
40:3:15: Men Jesus svarte og sa til ham: La det nu skje! for således sømmer det sig for oss å fullbyrde all rettferdighet. Da lot han det skje. 
40:3:16: Men da Jesus var døpt, steg han straks op av vannet, og se, himmelen åpnet sig for ham, og han så Guds Ånd fare ned som en due og komme over ham. 
40:3:17: Og se, det kom en røst fra himmelen, som sa: Dette er min Sønn, den elskede, i hvem jeg har velbehag. 
40:4:1: Da blev Jesus av Ånden ført ut i ørkenen for å fristes av djevelen. 
40:4:2: Og da han hadde fastet firti dager og firti netter, blev han til sist hungrig. 
40:4:3: Og fristeren kom til ham og sa: Er du Guds Sønn, da si at disse stener skal bli til brød! 
40:4:4: Men han svarte og sa: Det er skrevet: Mennesket lever ikke av brød alene, men av hvert ord som går ut av Guds munn. 
40:4:5: Da tok djevelen ham med sig til den hellige stad og stilte ham på templets tinde, og sa til ham: 
40:4:6: Er du Guds Sønn, da kast dig ned! for det er skrevet: Han skal gi sine engler befaling om dig, og de skal bære dig på hendene, forat du ikke skal støte din fot på nogen sten. 
40:4:7: Jesus sa til ham: Det er atter skrevet: Du skal ikke friste Herren din Gud. 
40:4:8: Atter tok djevelen ham med op på et meget høit fjell og viste ham alle verdens riker og deres herlighet og sa til ham: 
40:4:9: Alt dette vil jeg gi dig hvis du vil falle ned og tilbede mig. 
40:4:10: Da sa Jesus til ham: Bort fra mig, Satan! for det er skrevet: Herren din Gud skal du tilbede, og ham alene skal du tjene. 
40:4:11: Da forlot djevelen ham, og se, engler kom til ham og tjente ham. 
40:4:12: Men da han fikk høre at Johannes var kastet i fengsel, drog han bort til Galilea. 
40:4:13: Og han forlot Nasaret og kom og tok bolig i Kapernaum ved sjøen, i Sebulons og Naftalis landemerker, 
40:4:14: forat det skulde opfylles som er talt ved profeten Esaias, som sier: 
40:4:15: Sebulons land og Naftalis land ved sjøen, landet på hin side Jordan, hedningenes Galilea, 
40:4:16: det folk som satt i mørke, har sett et stort lys, og for dem som satt i dødens land og skygge, for dem er et lys oprunnet. 
40:4:17: Fra den tid begynte Jesus å forkynne og si: Omvend eder; for himlenes rike er kommet nær! 
40:4:18: Men da han vandret ved den Galileiske Sjø, så han to brødre, Simon, som kalles Peter, og hans bror Andreas, i ferd med å kaste garn i sjøen; for de var fiskere; 
40:4:19: og han sa til dem: Følg mig, så vil jeg gjøre eder til menneskefiskere! 
40:4:20: Og de forlot straks sine garn og fulgte ham. 
40:4:21: Og da han gikk videre frem, så han to andre brødre, Jakob, Sebedeus' sønn, og hans bror Johannes, sitte i båten med sin far Sebedeus, i ferd med å bøte sine garn, og han kalte dem. 
40:4:22: Og de forlot straks båten og sin far og fulgte ham. 
40:4:23: Og Jesus gikk omkring i hele Galilea og lærte folket i deres synagoger og forkynte evangeliet om riket og helbredet all sykdom og all skrøpelighet blandt folket, 
40:4:24: og ryktet om ham kom ut over hele Syria, og de førte til ham alle dem som hadde ondt og led av alle slags sykdommer og plager, både besatte og månesyke og verkbrudne, og han helbredet dem. 
40:4:25: Og meget folk fulgte ham fra Galilea og Dekapolis og Jerusalem og Judea og landet hinsides Jordan. 
40:5:1: Og da han så folket, gikk han op fjellet, og da han hadde satt sig, kom hans disipler til ham. 
40:5:2: Og han oplot sin munn, lærte dem og sa: 
40:5:3: Salige er de fattige i ånden; for himlenes rike er deres. 
40:5:4: Salige er de som sørger; for de skal trøstes. 
40:5:5: Salige er de saktmodige; for de skal arve jorden. 
40:5:6: Salige er de som hungrer og tørster efter rettferdighet; for de skal mettes. 
40:5:7: Salige er de barmhjertige; for de skal finne barmhjertighet. 
40:5:8: Salige er de rene av hjertet; for de skal se Gud. 
40:5:9: Salige er de fredsommelige; for de skal kalles Guds barn. 
40:5:10: Salige er de som er forfulgt for rettferdighets skyld; for himlenes rike er deres. 
40:5:11: Salige er I når de spotter og forfølger eder og lyver eder allehånde ondt på for min skyld. 
40:5:12: Gled og fryd eder! for eders lønn er stor i himmelen; for således forfulgte de profetene før eder. 
40:5:13: I er jordens salt; men når saltet mister sin kraft, hvad skal det så saltes med? Det duer ikke lenger til noget, uten til å kastes ut og tredes ned av menneskene. 
40:5:14: I er verdens schmelk; en by som ligger på et fjell, kan ikke skjules; 
40:5:15: en tender heller ikke et lys og setter det under en skjeppe, men i staken; så skinner det for alle i huset. 
40:5:16: La således eders schmelk skinne for menneskene, forat de kan se eders gode gjerninger og prise eders Fader i himmelen! 
40:5:17: I må ikke tro at jeg er kommet for å opheve loven eller profetene; jeg er ikke kommet for å opheve, men for å opfylle. 
40:5:18: For sannelig sier jeg eder: Før himmel og jord forgår, skal ikke den minste bokstav eller en eneste tøddel forgå av loven, før det er skjedd alt sammen. 
40:5:19: Derfor, den som bryter et eneste av disse minste bud og lærer menneskene således, han skal kalles den minste i himlenes rike; men den som holder dem og lærer andre dem, han skal kalles stor i himlenes rike. 
40:5:20: For jeg sier eder: Dersom eders rettferdighet ikke overgår de skriftlærdes og fariseernes, kommer I ingenlunde inn i himlenes rike. 
40:5:21: I har hørt at det er sagt til de gamle: Du skal ikke slå ihjel, men den som slår ihjel, skal være skyldig for dommen. 
40:5:22: Men jeg sier eder at hver den som blir vred på sin bror uten grunn, skal være skyldig for dommen; men den som sier til sin bror: Raka! skal være skyldig for rådet; men den som sier: Du dåre! skal være skyldig til helvedes ild. 
40:5:23: Derfor, når du bærer ditt offer frem til alteret, og der kommer i hu at din bror har noget imot dig, 
40:5:24: så la ditt offer ligge der foran alteret, og gå først bort og forlik dig med din bror, og kom så og bær ditt offer frem! 
40:5:25: Skynd dig å være føielig mot din motstander så lenge du er med ham på veien, forat ikke motstanderen skal overgi dig til dommeren, og dommeren overgi dig til tjeneren, og du bli kastet i fengsel. 
40:5:26: Sannelig sier jeg dig: Du skal ingenlunde komme ut derfra før du har betalt den siste øre. 
40:5:27: I har hørt at det er sagt: Du skal ikke drive hor. 
40:5:28: Men jeg sier eder at hver den som ser på en kvinne for å begjære henne, har alt drevet hor med henne i sitt hjerte. 
40:5:29: Om ditt høire øie frister dig, da riv det ut og kast det fra dig! for det er bedre for dig at ett av dine lemmer går tapt enn at hele ditt legeme blir kastet i helvede. 
40:5:30: Og dersom din høire hånd frister dig, da hugg den av og kast den fra dig! for det er bedre for dig at ett av dine lemmer går tapt enn at hele ditt legeme kommer i helvede. 
40:5:31: Det er sagt: Den som skiller sig fra sin hustru, skal gi henne skilsmissebrev. 
40:5:32: Men jeg sier eder at hver den som skiller sig fra sin hustru uten for hors skyld, han volder at hun driver hor, og den som gifter sig med en fraskilt kvinne, han driver hor. 
40:5:33: Atter har I hørt at det er sagt til de gamle: Du skal ikke sverge falsk ed, men du skal holde dine eder for Herren. 
40:5:34: Men jeg sier eder at I aldeles ikke skal sverge, hverken ved himmelen, for den er Guds trone, 
40:5:35: eller ved jorden, for den er hans fotskammel, eller ved Jerusalem, for det er den store konges stad. 
40:5:36: Heller ikke skal du sverge ved ditt hode; for du kan ikke gjøre ett hår hvitt eller sort. 
40:5:37: Men eders tale skal være ja, ja, nei, nei; det som er mere enn dette, er av det onde. 
40:5:38: I har hørt at det er sagt: Øie for øie, og tann for tann! 
40:5:39: Men jeg sier eder at I ikke skal sette eder imot den som er ond mot eder; men om nogen slår dig på ditt høire kinn, da vend også det andre til ham, 
40:5:40: og om nogen vil føre sak mot dig og ta din kjortel, da la ham også få kappen, 
40:5:41: og om nogen tvinger dig til å gå en mil, da gå to med ham. 
40:5:42: Gi den som ber dig, og vend dig ikke bort fra den som vil låne av dig. 
40:5:43: I har hørt at det er sagt: Du skal elske din næste og hate din fiende. 
40:5:44: Men jeg sier eder: Elsk eders fiender, velsign dem som forbanner eder, gjør vel imot dem som hater eder, og bed for dem som forfølger eder, 
40:5:45: forat I kan bli eders himmelske Faders barn; for han lar sin sol gå op over onde og gode, og lar det regne over rettferdige og urettferdige. 
40:5:46: For om I elsker dem som elsker eder, hvad lønn har I da? Gjør ikke også tolderne det samme? 
40:5:47: Og om I hilser bare på eders brødre, hvad stort gjør I da? Gjør ikke også hedningene det samme? 
40:5:48: Derfor skal I være fullkomne, likesom eders himmelske Fader er fullkommen. 
40:6:1: Ta eder i vare at I ikke utøver eders rettferdighet for menneskenes øine, for å sees av dem; ellers har I ingen lønn hos eders Fader i himmelen. 
40:6:2: Derfor, når du gir almisse, da skal du ikke la blåse i basun for dig, som hyklerne gjør i synagogene og på gatene, for å æres av menneskene; sannelig sier jeg eder: De har allerede fått sin lønn. 
40:6:3: Men når du gir almisse, da la ikke din venstre hånd vite hvad din høire gjør, 
40:6:4: forat din almisse kan være i lønndom, og din Fader, som ser i lønndom, han skal lønne dig i det åpenbare. 
40:6:5: Og når I beder, skal I ikke være som hyklerne; for de vil gjerne stå og bede i synagogene og på gatehjørnene, for å vise sig for menneskene; sannelig sier jeg eder: De har allerede fått sin lønn. 
40:6:6: Men du, når du beder, da gå inn i ditt lønnkammer og lukk din dør og bed til din Fader, som er i lønndom, og din Fader, som ser i lønndom, han skal lønne dig i det åpenbare. 
40:6:7: Og når I beder, skal I ikke ramse op mange ord som hedningene; for de tror at de blir bønnhørt når de bruker mange ord. 
40:6:8: Gjør derfor ikke som de! for eders Fader vet hvad I trenger til, før I beder ham. 
40:6:9: Derfor skal I bede således: Fader vår, du som er i himmelen! Helliget vorde ditt navn; 
40:6:10: komme ditt rike; skje din vilje, som i himmelen, så og på jorden; 
40:6:11: gi oss idag vårt daglige brød; 
40:6:12: og forlat oss vår skyld, som vi og forlater våre skyldnere; 
40:6:13: og led oss ikke inn i fristelse; men fri oss fra det onde. For riket er ditt, og makten og æren i evighet. Amen. 
40:6:14: For dersom I forlater menneskene deres overtredelser, da skal eders himmelske Fader også forlate eder; 
40:6:15: men dersom I ikke forlater menneskene deres overtredelser, da skal heller ikke eders Fader forlate eders overtredelser. 
40:6:16: Når I faster, da skal I ikke gå med mørkt åsyn som hyklerne; for de gjør sitt ansikt ukjennelig, forat menneskene skal se at de faster; sannelig sier jeg eder: De har allerede fått sin lønn. 
40:6:17: Men du, når du faster, da salv ditt hode og vask ditt ansikt, 
40:6:18: forat ikke menneskene skal se at du faster, men din Fader, som er i lønndom; og din Fader, som ser i lønndom, skal lønne dig. 
40:6:19: Samle eder ikke skatter på jorden, hvor møll og rust tærer, og hvor tyver bryter inn og stjeler; 
40:6:20: men samle eder skatter i himmelen, hvor hverken møll eller rust tærer, og hvor tyver ikke bryter inn og stjeler! 
40:6:21: For hvor din skatt er, der vil også ditt hjerte være. 
40:6:22: Øiet er legemets schmelk; er ditt øie friskt, da blir hele ditt legeme lyst; 
40:6:23: men er ditt øie sykt, da blir hele ditt legeme mørkt. Er altså schmelken i dig mørke, hvor stort blir da mørket! 
40:6:24: Ingen kan tjene to herrer; for han vil enten hate den ene og elske den andre, eller holde sig til den ene og forakte den andre; I kan ikke tjene Gud og mammon. 
40:6:25: Derfor sier jeg eder: Vær ikke bekymret for eders liv, hvad I skal ete og hvad I skal drikke, eller for eders legeme, hvad I skal klæ eder med! Er ikke livet mere enn maten, og legemet mere enn klærne? 
40:6:26: Se på himmelens fugler: De sår ikke, de høster ikke, de samler ikke i lader, og eders himmelske Fader før dem allikevel. Er ikke I meget mere enn de? 
40:6:27: Og hvem av eder kan med all sin bekymring legge en alen til sin livslengde? 
40:6:28: Og hvorfor er I bekymret for klærne? Akt på liljene på marken, hvorledes de vokser: de arbeider ikke, de spinner ikke; 
40:6:29: men jeg sier eder: Enn ikke Salomo i all sin herlighet var klædd som en av dem. 
40:6:30: Men klær Gud således gresset på marken, som står idag og imorgen kastes i ovnen, skal han da ikke meget mere klæ eder, I lite troende? 
40:6:31: Derfor skal I ikke være bekymret og si: Hvad skal vi ete, eller hvad skal vi drikke, eller hvad skal vi klæ oss med? 
40:6:32: For alt slikt søker hedningene efter, og eders himmelske Fader vet at I trenger til alt dette. 
40:6:33: Men søk først Guds rike og hans rettferdighet, så skal I få alt dette i tilgift! 
40:6:34: Vær altså ikke bekymret for den dag imorgen! for den dag imorgen skal bekymre sig for sig selv; hver dag har nok med sin egen plage. 
40:7:1: Døm ikke, forat I ikke skal dømmes! for med den samme dom som I dømmer med, skal I dømmes, 
40:7:2: og med det samme mål som I måler med, skal eder måles igjen. 
40:7:3: Hvorfor ser du splinten i din brors øie, men bjelken i ditt eget øie blir du ikke var? 
40:7:4: Eller hvorledes kan du si til din bror: La mig dra splinten ut av ditt øie? og se, det er en bjelke i ditt eget øie! 
40:7:5: Du hykler! dra først bjelken ut av ditt eget øie, så kan du se å dra splinten ut av din brors øie! 
40:7:6: Gi ikke hundene det hellige, og kast ikke eders perler for svinene, forat de ikke skal trå dem ned med sine føtter og vende sig om og sønderrive eder. 
40:7:7: Bed, så skal eder gis, let, så skal I finne, bank på, så skal det lukkes op for eder! 
40:7:8: For hver den som beder, han får, og den som leter, han finner, og den som banker på, for ham skal det lukkes op. 
40:7:9: Eller hvilket menneske iblandt eder er det vel som vil gi sin sønn en sten når han ber ham om brød, 
40:7:10: eller vil gi ham en orm når han ber om en fisk? 
40:7:11: Dersom da I, som er onde, vet å gi eders barn gode gaver, hvor meget mere skal da eders Fader i himmelen gi dem gode gaver som beder ham! 
40:7:12: Derfor, alt det I vil at menneskene skal gjøre imot eder, det skal også I gjøre imot dem; for dette er loven og profetene. 
40:7:13: Gå inn gjennem den trange port! for den port er vid, og den vei er bred som fører til fortapelsen, og mange er de som går inn gjennem den; 
40:7:14: for den port er trang, og den vei er smal som fører til livet, og få er de som finner den. 
40:7:15: Men vokt eder for de falske profeter, som kommer til eder i fåreklær, men innvortes er glupende ulver! 
40:7:16: Av deres frukter skal I kjenne dem; kan en vel sanke vindruer av tornebusker eller fiken av tistler? 
40:7:17: Således bærer hvert godt tre gode frukter, men det dårlige tre bærer onde frukter. 
40:7:18: Et godt tre kan ikke bære onde frukter, og et dårlig tre kan ikke bære gode frukter. 
40:7:19: Hvert tre som ikke bærer god frukt, blir hugget ned og kastet på ilden. 
40:7:20: Derfor skal I kjenne dem av deres frukter. 
40:7:21: Ikke enhver som sier til mig: Herre! Herre! skal komme inn i himlenes rike, men den som gjør min himmelske Faders vilje. 
40:7:22: Mange skal si til mig på hin dag: Herre! Herre! har vi ikke talt profetisk ved ditt navn, og utdrevet onde ånder ved ditt navn, og gjort mange kraftige gjerninger ved ditt navn? 
40:7:23: Og da skal jeg vidne for dem: Jeg har aldri kjent eder; vik bort fra mig, I som gjorde urett! 
40:7:24: Derfor, hver den som hører disse mine ord og gjør efter dem, han blir lik en forstandig mann, som bygget sitt hus på fjell; 
40:7:25: og skyllregnet falt, og flommen kom, og vindene blåste og slo imot dette hus, men det falt ikke; for det var grunnlagt på fjell. 
40:7:26: Og hver den som hører disse mine ord og ikke gjør efter dem, han blir lik en uforstandig mann, som bygget sitt hus på sand; 
40:7:27: og skyllregnet falt, og flommen kom, og vindene blåste og slo imot dette hus, og det falt, og dets fall var stort! 
40:7:28: Og da Jesus hadde endt denne tale, da var folket slått av forundring over hans lære; 
40:7:29: for han lærte dem som en som hadde myndighet, og ikke som deres skriftlærde. 
40:8:1: Da han gikk ned av fjellet, fulgte meget folk ham. 
40:8:2: Og se, en spedalsk kom og falt ned for ham og sa: Herre! om du vil, så kan du rense mig. 
40:8:3: Og han rakte hånden ut, rørte ved ham og sa: Jeg vil; bli ren! Og straks blev han renset for sin spedalskhet. 
40:8:4: Og Jesus sa til ham: Se til at du ikke sier det til nogen; men gå og te dig for presten, og bær frem det offer Moses har påbudt, til et vidnesbyrd for dem! 
40:8:5: Men da han gikk inn i Kapernaum, kom en høvedsmann til ham og bad ham og sa: 
40:8:6: Herre! min dreng ligger verkbrudden hjemme og pines forferdelig. 
40:8:7: Jesus sa til ham: Jeg vil komme og helbrede ham. 
40:8:8: Men høvedsmannen svarte og sa: Herre! Jeg er for ringe til at du skal gå inn under mitt tak; men si bare et ord, så blir min dreng helbredet! 
40:8:9: For jeg er også en mann som står under overordnede, men har stridsmenn under mig igjen; og sier jeg til den ene: Gå! så går han, og til en annen: Kom! så kommer han, og til min tjener: Gjør dette! så gjør han det. 
40:8:10: Men da Jesus hørte dette, undret han sig, og sa til dem som fulgte ham: Sannelig sier jeg eder: Ikke engang i Israel har jeg funnet så stor en tro. 
40:8:11: Men jeg sier eder at mange skal komme fra øst og vest og sitte til bords med Abraham og Isak og Jakob i himlenes rike; 
40:8:12: men rikets barn skal kastes ut i mørket utenfor; der skal være gråt og tenners gnidsel. 
40:8:13: Og Jesus sa til høvedsmannen: Gå bort; dig skje som du har trodd! Og hans dreng blev helbredet i den samme stund. 
40:8:14: Og da Jesus kom inn i Peters hus, så han at hans svigermor lå til sengs og hadde feber; 
40:8:15: og han rørte ved hennes hånd, og feberen forlot henne; og hun stod op og tjente ham. 
40:8:16: Men da det var blitt aften, førte de til ham mange besatte, og han drev åndene ut med et ord, og alle dem som hadde ondt, helbredet han, 
40:8:17: forat det skulde opfylles som er talt ved profeten Esaias, som sier: Han tok våre skrøpeligheter på sig og bar våre sykdommer. 
40:8:18: Men da Jesus så meget folk omkring sig, bød han disiplene å fare med ham over til hin side. 
40:8:19: Og en skriftlærd kom og sa til ham: Mester! Jeg vil følge dig hvorhen du går. 
40:8:20: Og Jesus sa til ham: Revene har huler, og himmelens fugler reder; men Menneskesønnen har ikke det han kan helle sitt hode til. 
40:8:21: Men en annen, en av hans disipler, sa til ham: Herre! gi mig først lov til å gå bort og begrave min far! 
40:8:22: Men Jesus sa til ham: Følg mig, og la de døde begrave sine døde! 
40:8:23: Han gikk da i båten, og hans disipler fulgte ham. 
40:8:24: Og se, det blev en stor storm på sjøen, så at båten skjultes av bølgene; men han sov. 
40:8:25: Da gikk disiplene til ham og vekket ham og sa: Herre, frels! vi går under. 
40:8:26: Og han sa til dem: Hvorfor er I redde, I lite troende? Så stod han op og truet vindene og sjøen, og det blev blikkstille. 
40:8:27: Men mennene undret sig og sa: Hvad er dette for en, som både vindene og sjøen er lydige? 
40:8:28: Og da han kom over på hin side, til gadarenernes bygd, møtte der ham to besatte, som kom ut av gravene, meget ville, så ingen var i stand til å komme frem den vei. 
40:8:29: Og se, de ropte: Hvad har vi med dig å gjøre, du Guds Sønn? Er du kommet hit for å pine oss før tiden? 
40:8:30: Men et langt stykke fra dem gikk en stor svinehjord og beitet; 
40:8:31: og de onde ånder bad ham: Dersom du driver oss ut, da gi oss lov til å fare inn i svinehjorden! 
40:8:32: Og han sa til dem: Far avsted! Og de fór ut og fór i svinene. Og se, hele hjorden styrtet sig ut over stupet ned i sjøen og døde i vannet. 
40:8:33: Men gjæterne tok flukten og kom inn i byen og fortalte alt sammen, og sa hvorledes det var gått med de besatte. 
40:8:34: Og se, hele byen kom ut og møtte Jesus, og da de så ham, bad de ham gå bort fra deres landemerker. 
40:9:1: Og han gikk i båten og fór over og kom til sin egen by. 
40:9:2: Og se, de førte til ham en mann som var verkbrudden, og som lå på en seng; og da Jesus så deres tro, sa han til den verkbrudne: Vær frimodig, sønn! dine synder er dig forlatt. 
40:9:3: Og se, nogen av de skriftlærde sa ved sig selv: Denne spotter Gud. 
40:9:4: Da Jesus så deres tanker, sa han: Hvorfor tenker I så ondt i eders hjerter? 
40:9:5: For hvad er lettest, enten å si: Dine synder er dig forlatt, eller å si: Stå op og gå? 
40:9:6: Men forat I skal vite at Menneskesønnen har makt på jorden til å forlate synder - da sier han til den verkbrudne: Stå op og ta din seng og gå hjem til ditt hus! 
40:9:7: Og han stod op og gikk hjem til sitt hus. 
40:9:8: Men da folket så det, blev de forferdet og priste Gud, som hadde gitt mennesker en sådan makt. 
40:9:9: Og da Jesus gikk videre derfra, så han en mann ved navn Matteus sitte på tollboden; og han sa til ham: Følg mig! Og han stod op og fulgte ham. 
40:9:10: Og det skjedde da han satt til bords i hans hus, se, da kom mange toldere og syndere og satt til bords med Jesus og hans disipler. 
40:9:11: Og da fariseerne så det, sa de til hans disipler: Hvorfor eter eders mester med toldere og syndere? 
40:9:12: Men da Jesus hørte det, sa han: De friske trenger ikke til læge, men de som har ondt. 
40:9:13: Men gå bort og lær hvad det er: Jeg har lyst til barmhjertighet og ikke til offer. For jeg er ikke kommet for å kalle rettferdige, men for å kalle syndere. 
40:9:14: Da kom Johannes' disipler til ham og sa: Hvorfor faster vi og fariseerne så meget, men dine disipler faster ikke? 
40:9:15: Og Jesus sa til dem: Kan vel brudesvennene sørge så lenge brudgommen er hos dem? Men de dager skal komme da brudgommen blir tatt fra dem, og da skal de faste. 
40:9:16: Ingen setter en lapp av ukrympet tøi på et gammelt klædebon; for lappen river med sig et stykke av klædebonet, og riften blir verre. 
40:9:17: Heller ikke fyller de ny vin i gamle skinnsekker; ellers revner sekkene, og vinen spilles, og sekkene ødelegges; men de fyller ny vin i nye skinnsekker, så blir begge deler berget. 
40:9:18: Mens han talte dette til dem, se, da kom en synagoge-forstander og falt ned for ham og sa: Min datter er nettop død; men kom og legg din hånd på henne, så vil hun leve. 
40:9:19: Og Jesus stod op og fulgte ham, og hans disipler gikk med. 
40:9:20: Og se, en kvinne som hadde hatt blodsott i tolv år, trådte til bakfra og rørte ved det ytterste av hans klædebon; 
40:9:21: for hun sa ved sig selv: Kan jeg bare få røre ved hans klædebon, så blir jeg helbredet. 
40:9:22: Men han vendte sig om, og da han så henne, sa han: Vær frimodig, datter! din tro har frelst dig. Og kvinnen blev helbredet fra samme stund. 
40:9:23: Og da Jesus kom inn i synagoge-forstanderens hus og så fløitespillerne og hopen som larmet, sa han til dem: 
40:9:24: Gå bort! Piken er ikke død; hun sover. Og de lo ham ut. 
40:9:25: Men da han hadde drevet hopen ut, gikk han inn og tok henne ved hånden; og piken stod op. 
40:9:26: Og ryktet om dette kom ut i hele landet deromkring. 
40:9:27: Og da Jesus gikk derfra, fulgte der ham to blinde, som ropte: Miskunn dig over oss, du Davids sønn! 
40:9:28: Og da han var kommet inn i huset, gikk de blinde til ham og Jesus sa til dem: Tror I at jeg kan gjøre dette? De sa til ham: Ja, Herre! 
40:9:29: Da rørte han ved deres øine og sa: Det skje eder efter eders tro! 
40:9:30: Og deres øine blev oplatt. Og Jesus talte strengt til dem og sa: Se til at ingen får vite det! 
40:9:31: Men de gikk ut og utbredte ryktet om ham i hele landet deromkring. 
40:9:32: Da nu disse gikk bort, se, da førte de til ham et stumt menneske, som var besatt. 
40:9:33: Og da den onde ånd var drevet ut, talte den stumme. Og folket undret sig og sa: Aldri har slikt vært sett i Israel. 
40:9:34: Men fariseerne sa: Det er ved de onde ånders fyrste han driver de onde ånder ut. 
40:9:35: Og Jesus gikk omkring i alle byene og landsbyene og lærte folket i deres synagoger og forkynte evangeliet om riket og helbredet all sykdom og all skrøpelighet. 
40:9:36: Og da han så folket, ynkedes han inderlig over dem; for de var ille medfarne og forkomne, lik får som ikke har hyrde. 
40:9:37: Da sa han til sine disipler: Høsten er stor, men arbeiderne få; 
40:9:38: bed derfor høstens herre at han vil drive arbeidere ut til sin høst! 
40:10:1: Og han kalte sine tolv disipler til sig og gav dem makt over urene ånder, til å drive dem ut, og til å helbrede all sykdom og all skrøpelighet. 
40:10:2: Men dette er de tolv apostlers navn: Først Simon, som kalles Peter, og Andreas, hans bror; Jakob, Sebedeus' sønn, og Johannes, hans bror; 
40:10:3: Filip og Bartolomeus; Tomas og Matteus, tolderen; Jakob, Alfeus' sønn, og Lebbeus med tilnavnet Taddeus; 
40:10:4: Simon Kananeus og Judas Iskariot, han som forrådte ham. 
40:10:5: Disse tolv sendte Jesus ut og bød dem: Gå ikke ut på veien til hedningene, og gå ikke inn i nogen av samaritanenes byer, 
40:10:6: men gå heller til de fortapte får av Israels hus! 
40:10:7: Og når I går avsted, da forkynn dette budskap: Himlenes rike er kommet nær! 
40:10:8: Helbred syke, opvekk døde, rens spedalske, driv ut onde ånder! For intet har I fått det, for intet skal I gi det. 
40:10:9: I skal ikke ta gull eller sølv eller kobber med i eders belter, 
40:10:10: ikke skreppe til reisen, ikke to kjortler, ikke sko, ikke stav; for arbeideren er sin føde verd. 
40:10:11: Og hvor I kommer inn i en by eller landsby, der skal I spørre efter hvem som er det verd i den by; og bli hos ham til I drar bort derfra! 
40:10:12: Og når I kommer inn i et hus, da skal I hilse det; 
40:10:13: og dersom huset er det verd, da komme eders fred over det; men dersom det ikke er det verd, da vende eders fred tilbake til eder! 
40:10:14: Og om nogen ikke tar imot eder og ikke hører på eders ord, da gå ut av det hus eller den by, og ryst støvet av eders føtter! 
40:10:15: Sannelig sier jeg eder: Det skal gå Sodomas og Gomorras land tåleligere på dommens dag enn den by. 
40:10:16: Se, jeg sender eder som får midt iblandt ulver; vær derfor kloke som slanger og enfoldige som duer! 
40:10:17: Men vokt eder for menneskene! for de skal overgi eder til domstolene og hudstryke eder i sine synagoger; 
40:10:18: og I skal føres frem for landshøvdinger og konger for min skyld, til vidnesbyrd for dem og for hedningene. 
40:10:19: Men når de overgir eder, da vær ikke bekymret for hvorledes eller hvad I skal tale; for det skal gis eder i samme stund hvad I skal tale. 
40:10:20: For det er ikke I som taler, men det er eders Faders Ånd som taler i eder. 
40:10:21: Og bror skal overgi bror til døden, og en far sitt barn, og barn skal reise sig mot foreldre og volde deres død; 
40:10:22: og I skal hates av alle for mitt navns skyld; men den som holder ut til enden, han skal bli frelst. 
40:10:23: Men når de forfølger eder i den ene by, da fly til den andre! for sannelig sier jeg eder: I skal ikke komme til ende med Israels byer før Menneskesønnen kommer. 
40:10:24: En disippel er ikke over sin mester, heller ikke en tjener over sin herre; 
40:10:25: det er nok for disippelen at han blir som sin mester, og tjeneren som sin herre; har de kalt husbonden Be'elsebul, hvor meget mere da hans husfolk! 
40:10:26: Frykt derfor ikke for dem! for intet er skjult som ikke skal bli åpenbaret, og intet er dulgt som ikke skal bli kjent; 
40:10:27: det jeg sier eder i mørket, det skal I si i schmelken, og det som hviskes eder i øret, det skal I forkynne på takene. 
40:10:28: Og frykt ikke for dem som slår legemet ihjel, men ikke kan slå sjelen ihjel; men frykt heller for ham som kan ødelegge både sjel og legeme i helvede! 
40:10:29: Selges ikke to spurver for en øre? Og ikke en av dem faller til jorden uten at eders Fader vil. 
40:10:30: Men endog hårene på eders hode er tellet alle sammen. 
40:10:31: Frykt derfor ikke! I er mere enn mange spurver. 
40:10:32: Derfor, hver den som kjennes ved mig for menneskene, ham skal også jeg kjennes ved for min Fader i himmelen; 
40:10:33: men den som fornekter mig for menneskene, ham skal også jeg fornekte for min Fader i himmelen. 
40:10:34: I må ikke tro at jeg er kommet for å sende fred på jorden; jeg er ikke kommet for å sende fred, men sverd. 
40:10:35: For jeg er kommet for å sette splid mellem en mann og hans far, og mellem en datter og hennes mor, og mellem en svigerdatter og hennes svigermor, 
40:10:36: og en manns husfolk skal bli hans fiender. 
40:10:37: Den som elsker far eller mor mere enn mig, er mig ikke verd, og den som elsker sønn eller datter mere enn mig, er mig ikke verd, 
40:10:38: og den som ikke tar sitt kors og følger efter mig, er mig ikke verd. 
40:10:39: Den som finner sitt liv, skal miste det, og den som mister sitt liv for min skyld, skal finne det. 
40:10:40: Den som tar imot eder, tar imot mig, og den som tar imot mig, tar imot ham som sendte mig. 
40:10:41: Den som tar imot en profet fordi han er en profet, skal få en profets lønn, og den som tar imot en rettferdig fordi han er rettferdig, skal få en rettferdigs lønn. 
40:10:42: Og den som gir én av disse små endog bare et beger koldt vann å drikke fordi han er disippel, sannelig sier jeg eder: Han skal ingenlunde miste sin lønn. 
40:11:1: Og det skjedde da Jesus var ferdig med å gi sine tolv disipler disse forskrifter, da drog han derfra for å lære og forkynne i deres byer. 
40:11:2: Men da Johannes i fengslet hørte om Kristi gjerninger, sendte han bud med sine disipler og lot si til ham: 
40:11:3: Er du den som skal komme, eller skal vi vente en annen? 
40:11:4: Og Jesus svarte og sa til dem: Gå bort og fortell Johannes det som I hører og ser: 
40:11:5: blinde ser og halte går, spedalske renses og døve hører og døde står op, og evangeliet forkynnes for fattige; 
40:11:6: og salig er den som ikke tar anstøt av mig. 
40:11:7: Da nu disse gikk bort, begynte Jesus å tale til folket om Johannes: Hvorfor var det I gikk ut i ørkenen? for å se et rør som svaier for vinden? 
40:11:8: Eller hvorfor var det I gikk der ut? for å se et menneske klædd i fine klær? Se, de som går i fine klær, er i kongenes hus. 
40:11:9: Eller hvorfor var det I gikk der ut? for å se en profet? Ja, jeg sier eder, endog mere enn en profet. 
40:11:10: Det er ham det er skrevet om: Se, jeg sender mitt bud for ditt åsyn; han skal rydde din vei for dig. 
40:11:11: Sannelig sier jeg eder: Nogen større enn døperen Johannes er ikke opreist blandt dem som er født av kvinner; men den minste i himlenes rike er større enn han. 
40:11:12: Men fra døperen Johannes' dager inntil nu trenger de sig med makt inn i himlenes rike, og de som trenger sig inn, river det til sig. 
40:11:13: For alle profetene og loven har spådd inntil Johannes, 
40:11:14: og om I vil ta imot det: Han er den Elias som skal komme. 
40:11:15: Den som har ører, han høre! 
40:11:16: Men hvem skal jeg ligne denne slekt med? Den ligner små barn som sitter på torvene og roper til sine lekebrødre: 
40:11:17: Vi blåste på fløite for eder, og I vilde ikke danse; vi sang sørgesanger, og I vilde ikke gråte. 
40:11:18: For Johannes kom; han hverken åt eller drakk, og de sier: Han er besatt. 
40:11:19: Menneskesønnen kom; han eter og drikker, og de sier: Se, for en storeter og vindrikker, tolderes og synderes venn! Men visdommen er rettferdiggjort av sine barn. 
40:11:20: Da begynte han å refse de byer hvor hans fleste kraftige gjerninger var gjort, fordi de ikke hadde omvendt sig: 
40:11:21: Ve dig, Korasin! ve dig, Betsaida! Dersom de kraftige gjerninger som er gjort i eder, var gjort i Tyrus og Sidon, da hadde de for lenge siden omvendt sig i sekk og aske. 
40:11:22: Dog, jeg sier eder: Det skal gå Tyrus og Sidon tåleligere på dommens dag enn eder. 
40:11:23: Og du, Kapernaum, som er blitt ophøiet like til himmelen! like til dødsriket skal du bli nedstøtt; for dersom de kraftige gjerninger som er gjort i dig, var gjort i Sodoma, da var det blitt stående til denne dag. 
40:11:24: Dog, jeg sier eder: Det skal gå Sodomas land tåleligere på dommens dag enn eder. 
40:11:25: På den tid tok Jesus til orde og sa: Jeg priser dig, Fader, himmelens og jordens herre, fordi du har skjult dette for de vise og forstandige, og åpenbaret det for de umyndige; 
40:11:26: ja, Fader, fordi således skjedde det som var velbehagelig for dig. 
40:11:27: Alle ting er mig overgitt av min Fader, og ingen kjenner Sønnen, uten Faderen, heller ikke kjenner nogen Faderen, uten Sønnen og den som Sønnen vil åpenbare det for. 
40:11:28: Kom til mig, alle I som strever og har tungt å bære, og jeg vil gi eder hvile! 
40:11:29: Ta mitt åk på eder og lær av mig! for jeg er saktmodig og ydmyk av hjertet; så skal I finne hvile for eders sjeler. 
40:11:30: For mitt åk er gagnlig, og min byrde er lett. 
40:12:1: På den tid gikk Jesus gjennem en aker på sabbaten, og hans disipler var sultne og begynte å plukke aks og ete. 
40:12:2: Men da fariseerne så det, sa de til ham: Se, dine disipler gjør det som det ikke er tillatt å gjøre på sabbaten. 
40:12:3: Men han sa til dem: Har I ikke lest hvad David gjorde da han var sulten, han og de som var med ham, 
40:12:4: hvorledes han gikk inn i Guds hus og åt skuebrødene, som hverken han eller de som var med ham hadde lov til å ete, men alene prestene? 
40:12:5: Eller har I ikke lest i loven at prestene på sabbaten vanhelliger sabbaten i templet og er dog uten skyld? 
40:12:6: Men jeg sier eder at her er det som er større enn templet. 
40:12:7: Og dersom I hadde visst hvad det er: Jeg har lyst til barmhjertighet og ikke til offer, da hadde I ikke fordømt de uskyldige. 
40:12:8: For Menneskesønnen er herre over sabbaten. 
40:12:9: Og han gikk bort derfra og kom i deres synagoge. 
40:12:10: Og se, der var en mann som hadde en vissen hånd; og de spurte ham: Er det tillatt å helbrede på sabbaten? forat de kunde føre klagemål imot ham. 
40:12:11: Men han sa til dem: Hvem er det vel iblandt eder som har ett får, og om det på sabbaten faller i en grøft, da ikke tar fatt i det og drar det op? 
40:12:12: Hvor meget mere er da ikke et menneske enn et får! Derfor er det tillatt å gjøre godt på sabbaten. 
40:12:13: Derefter sier han til mannen: Rekk din hånd ut! Og han rakte den ut, og den blev frisk igjen som den andre. 
40:12:14: Men fariseerne gikk ut og holdt råd imot ham, hvorledes de skulde få ryddet ham av veien. 
40:12:15: Men da Jesus fikk vite det, drog han bort derfra, og mange fulgte ham, og han helbredet dem alle. 
40:12:16: Og han bød dem strengt at de ikke skulde gjøre ham kjent, 
40:12:17: forat det skulde opfylles som er talt ved profeten Esaias, som sier: 
40:12:18: Se, min tjener, som jeg har utvalgt, min elskede, som min sjel har velbehag i; jeg vil legge min Ånd på ham, og han skal forkynne rett for hedningene. 
40:12:19: Han skal ikke trette eller rope, og ingen skal høre hans røst på gatene; 
40:12:20: han skal ikke knuse et knekket rør og ikke slukke en rykende tande, før han har ført retten frem til seier. 
40:12:21: Og til hans navn skal hedningene sette sitt håp. 
40:12:22: Derefter førte de til ham en besatt som var blind og stum; og han helbredet ham, så at den stumme talte og så. 
40:12:23: Og alt folket blev ute av sig selv av forundring og sa: Mon dette skulde være Davids sønn? 
40:12:24: Men da fariseerne hørte det, sa de: Det er bare ved Be'elsebul, de onde ånders fyrste, han driver de onde ånder ut. 
40:12:25: Men da Jesus visste deres tanker, sa han til dem: Hvert rike som kommer i strid med sig selv, legges øde, og ingen by og intet hus som kommer i strid med sig selv, kan bli stående. 
40:12:26: Og dersom Satan driver Satan ut, da er han kommet i strid med sig selv; hvorledes kan da hans rike bli stående? 
40:12:27: Og driver jeg de onde ånder ut ved Be'elsebul, ved hvem er det da eders barn driver dem ut? Derfor skal de være eders dommere. 
40:12:28: Men er det ved Guds Ånd jeg driver de onde ånder ut, da er jo Guds rike kommet til eder. 
40:12:29: Eller hvorledes kan nogen gå inn i den sterkes hus og røve hans gods, uten han først har bundet den sterke? så kan han plyndre hans hus. 
40:12:30: Den som ikke er med mig, han er imot mig, og den som ikke samler med mig, han spreder. 
40:12:31: Derfor sier jeg eder: Hver synd og bespottelse skal bli menneskene forlatt; men bespottelse mot Ånden skal ikke bli forlatt. 
40:12:32: Og om nogen taler et ord mot Menneskesønnen, det skal bli ham forlatt; men om nogen taler mot den Hellige Ånd, det skal ikke bli ham forlatt, hverken i denne verden eller i den kommende. 
40:12:33: La enten treet være godt og dets frukt god, eller la treet være dårlig og dets frukt dårlig; for på frukten skal treet kjennes. 
40:12:34: Orme-yngel! hvorledes kan I tale godt, I som er onde? for hvad hjertet flyter over av, det taler munnen. 
40:12:35: Et godt menneske bærer frem gode ting av sitt gode forråd, og et ondt menneske bærer frem onde ting av sitt onde forråd. 
40:12:36: Men jeg sier eder at for hvert unyttig ord som menneskene taler, skal de gjøre regnskap på dommens dag; 
40:12:37: for efter dine ord skal du kjennes rettferdig, og efter dine ord skal du fordømmes. 
40:12:38: Da svarte nogen av de skriftlærde og fariseerne ham og sa: Mester! vi vil gjerne se et tegn av dig. 
40:12:39: Men han svarte og sa til dem: En ond og utro slekt krever tegn, og tegn skal ikke gis den, uten profeten Jonas tegn. 
40:12:40: For likesom Jonas var tre dager og tre netter i fiskens buk, således skal Menneskesønnen være tre dager og tre netter i jordens skjød. 
40:12:41: Ninives menn skal stå op på dommens dag sammen med denne slekt og fordømme den; for de omvendte sig ved Jonas' forkynnelse, og se, her er mere enn Jonas. 
40:12:42: Dronningen fra Syden skal stå op på dommens dag sammen med denne slekt og fordømme den; for hun kom fra jordens ytterste ende for å høre Salomos visdom, og se, her er mere enn Salomo. 
40:12:43: Når den urene ånd er faret ut av et menneske, går den gjennem tørre steder og søker hvile, men finner den ikke. 
40:12:44: Da sier den: Jeg vil vende tilbake til mitt hus, som jeg fór ut av. Og når den kommer dit, finner den det ledig og feid og pyntet. 
40:12:45: Så går den bort og tar med sig syv andre ånder, verre enn den selv, og de går inn og bor der, og det siste blir verre med det menneske enn det første. Således skal det også gå denne onde slekt. 
40:12:46: Mens han ennu talte til folket, se, da stod hans mor og brødre utenfor og søkte å få ham i tale. 
40:12:47: Da sa en til ham: Se, din mor og dine brødre står utenfor og søker å få dig i tale. 
40:12:48: Men han svarte den som sa det til ham: Hvem er min mor, og hvem er mine brødre? 
40:12:49: Og han rakte sin hånd ut mot sine disipler og sa: Se, det er min mor og mine brødre! 
40:12:50: For den som gjør min himmelske Faders vilje, han er min bror og søster og mor. 
40:13:1: Samme dag gikk Jesus ut av huset og satte sig ved sjøen, 
40:13:2: og meget folk samlet sig om ham, så at han gikk ut i en båt og satte sig der; og alt folket stod på stranden. 
40:13:3: Og han talte meget til dem i lignelser og sa: Se, en såmann gikk ut for å så, 
40:13:4: og da han sådde, falt noget ved veien; og fuglene kom og åt det op. 
40:13:5: Og noget falt på stengrunn, hvor det ikke hadde meget jord; og det kom snart op, fordi det ikke hadde dyp jord; 
40:13:6: men da solen gikk op, blev det avsvidd, og da det ikke hadde rot, visnet det. 
40:13:7: Og noget falt blandt torner, og tornene skjøt op og kvalte det. 
40:13:8: Og noget falt i god jord; og det bar frukt, noget hundre fold, og noget seksti fold, og noget tretti fold. 
40:13:9: Den som har ører, han høre! 
40:13:10: Og disiplene gikk til ham og sa: hvorfor taler du til dem i lignelser? 
40:13:11: Han svarte og sa til dem: Fordi eder er det gitt å få vite himlenes rikes hemmeligheter; men dem er det ikke gitt. 
40:13:12: For den som har, ham skal gis, og han skal ha overflod; men den som ikke har, fra ham skal endog tas det han har. 
40:13:13: Derfor taler jeg til dem i lignelser, fordi de ser og dog ikke ser, og fordi de hører og dog ikke hører og ikke forstår. 
40:13:14: Og på dem opfylles Esaias' spådom, som sier: I skal høre og høre og ikke forstå, og se og se og ikke skjelne; 
40:13:15: for dette folks hjerte er sløvet, og med ørene hører de tungt, og sine øine lukker de, forat de ikke skal se med øinene og høre med ørene og forstå med hjertet og omvende sig, så jeg kunde få læge dem. 
40:13:16: Men salige er eders øine fordi de ser, og eders ører fordi de hører. 
40:13:17: For sannelig sier jeg eder: Mange profeter og rettferdige har attrådd å se det I ser, og har ikke fått se det, og å høre det I hører, og har ikke fått høre det. 
40:13:18: Så hør da I lignelsen om såmannen: 
40:13:19: Hver gang nogen hører ordet om riket og ikke forstår det, kommer den onde og røver det som er sådd i hans hjerte; dette er den som blev sådd ved veien. 
40:13:20: Men den som blev sådd på stengrunn, det er den som hører ordet og straks tar imot det med glede; 
40:13:21: men han har ikke rot i sig, og holder bare ut til en tid; blir det trengsel eller forfølgelse for ordets skyld, da tar han straks anstøt. 
40:13:22: Men den som blev sådd blandt torner, det er den som hører ordet, og verdens bekymring og rikdommens forførelse kveler ordet, og det blir uten frukt. 
40:13:23: Men den som blev sådd i den gode jord, det er den som hører ordet og forstår det; han bærer frukt, og en gir hundre fold, en seksti fold, en tretti fold. 
40:13:24: En annen lignelse fremsatte han for dem og sa: Himlenes rike er å ligne med en mann som hadde sådd god sæd i sin aker; 
40:13:25: men mens folkene sov, kom hans fiende og sådde ugress blandt hveten, og gikk så bort. 
40:13:26: Men da strået skjøt op og satte aks, da kom også ugresset til syne. 
40:13:27: Da gikk husbondens tjenere til ham og sa: Herre! sådde du ikke god sæd i din aker? hvorfra kommer det da ugress i den? 
40:13:28: Han sa til dem: Det har en fiende gjort. Da sa tjenerne til ham: Vil du da vi skal gå og sanke det sammen? 
40:13:29: Men han sa: Nei, forat I ikke også skal rykke op hveten når I sanker ugresset sammen. 
40:13:30: La dem begge vokse sammen inntil høsten, og når høsttiden kommer, vil jeg si til høstfolkene: Sank først ugresset sammen og bind det i bunter for å brenne det op; men samle hveten i min lade! 
40:13:31: En annen lignelse fremsatte han for dem og sa: Himlenes rike er likt et sennepskorn som en mann tok og sådde i sin aker; 
40:13:32: det er mindre enn alt annet frø; men når det vokser til, er det større enn alle maturter og blir til et tre, så himmelens fugler kommer og bygger rede i dets grener. 
40:13:33: En annen lignelse sa han dem: Himlenes rike er likt en surdeig som en kvinne tok og skjulte i tre skjepper mel, til det blev syret alt sammen. 
40:13:34: Alt dette talte Jesus i lignelser til folket, og uten lignelser talte han ikke noget til dem, 
40:13:35: forat det skulde opfylles som er talt ved profeten, som sier: Jeg vil oplate min munn i lignelser, jeg vil utsi det som har vært skjult fra verdens grunnvoll blev lagt. 
40:13:36: Derefter lot han folket fare og gikk inn i huset. Og hans disipler gikk til ham og sa: Tyd oss lignelsen om ugresset i akeren! 
40:13:37: Han svarte og sa: Den som sår den gode sæd, er Menneskesønnen. 
40:13:38: Akeren er verden; den gode sæd, det er rikets barn; men ugresset er den ondes barn; 
40:13:39: fienden som sådde det, er djevelen; høsten er verdens ende; høstfolkene er englene. 
40:13:40: Likesom da ugresset sankes og brennes op med ild, således skal det gå til ved verdens ende: 
40:13:41: Menneskesønnen skal utsende sine engler, og de skal sanke ut av hans rike alt det som volder anstøt, og dem som gjør urett, 
40:13:42: og de skal kaste dem i ildovnen; der skal være gråt og tenners gnidsel. 
40:13:43: Da skal de rettferdige skinne som solen i sin Faders rike. Den som har ører, han høre! 
40:13:44: Himlenes rike er likt en skatt som var gjemt i en aker, og som en mann fant og skjulte, og i sin glede gikk han bort og solgte alt det han hadde, og kjøpte akeren. 
40:13:45: Atter er himlenes rike likt en kjøpmann som søkte efter gode perler, 
40:13:46: og da han fant en kostelig perle, gikk han bort og solgte alt det han hadde, og kjøpte den. 
40:13:47: Atter er himlenes rike likt en not som kastes i havet og samler fisk av alle slags; 
40:13:48: når den er blitt full, drar de den på land og setter sig ned og samler de gode sammen i kar, men de råtne kaster de bort. 
40:13:49: Således skal det gå til ved verdens ende: Englene skal gå ut og skille de onde fra de rettferdige 
40:13:50: og kaste dem i ildovnen; der skal være gråt og tenners gnidsel. 
40:13:51: Har I forstått alt dette? De sier til ham: Ja. 
40:13:52: Da sa han til dem: Derfor er enhver skriftlærd som er oplært for himlenes rike, lik en husbond som bærer frem nytt og gammelt av det han har. 
40:13:53: Og det skjedde da Jesus hadde endt disse lignelser, da drog han bort derfra. 
40:13:54: Og han kom til sitt hjemsted og lærte dem i deres synagoge, så de blev slått av forundring og sa: Hvorfra har denne mann slik visdom og slike kraftige gjerninger? 
40:13:55: Er ikke dette tømmermannens sønn? heter ikke hans mor Maria, og hans brødre Jakob og Josef og Simon og Judas? 
40:13:56: Og hans søstre, er de ikke alle her hos oss? Hvorfra har han da alt dette? 
40:13:57: Og de tok anstøt av ham. Men Jesus sa til dem: En profet blir ikke foraktet annensteds enn på sitt hjemsted og i sitt hus. 
40:13:58: Og han gjorde ikke mange kraftige gjerninger der, for deres vantros skyld. 
40:14:1: På den tid fikk fjerdingsfyrsten Herodes høre ryktet om Jesus, 
40:14:2: og han sa til sine tjenere: Dette er døperen Johannes; han er opstanden fra de døde, og derfor er det disse krefter er virksomme i ham. 
40:14:3: Herodes hadde grepet Johannes og bundet ham og kastet ham i fengsel for Herodias' skyld, som var hans bror Filips hustru. 
40:14:4: For Johannes hadde sagt til ham: Det er dig ikke tillatt å ha henne. 
40:14:5: Og han vilde gjerne slå ham ihjel, men fryktet for folket; for de holdt ham for en profet. 
40:14:6: Men da det var Herodes' fødselsdag, danset Herodias' datter for dem, og Herodes syntes om henne; 
40:14:7: derfor lovte han med ed å gi henne hvad hun vilde be om. 
40:14:8: Men hun sa efter sin mors råd: Gi mig hit døperen Johannes' hode på et fat! 
40:14:9: Og kongen blev ille til mote, men for sine eders og for gjestenes skyld bød han at det skulde gis henne, 
40:14:10: og han sendte sine folk avsted og lot Johannes halshugge i fengslet. 
40:14:11: Og de kom med hans hode på et fat og gav det til piken, og hun bar det til sin mor. 
40:14:12: Og hans disipler kom til og tok hans legeme og begravde det; og de gikk og fortalte det til Jesus. 
40:14:13: Da Jesus hørte det, drog han derfra i en båt avsides til et øde sted, og da folket fikk høre det, fulgte de efter ham til fots fra byene. 
40:14:14: Og da han gikk i land, så han meget folk, og han ynkedes inderlig over dem og helbredet deres syke. 
40:14:15: Men da det var blitt aften, gikk hans disipler til ham og sa: Stedet er øde, og det er alt sent på dagen; la derfor folket fare, så det kan gå bort i byene og kjøpe sig mat! 
40:14:16: Men Jesus sa til dem: De har ikke nødig å gå bort; gi I dem å ete! 
40:14:17: De sa til ham: Vi har ikke mere her enn fem brød og to fisker. 
40:14:18: Men han sa: Hent dem hit til mig! 
40:14:19: Og han bød at folket skulde sette sig ned i gresset, tok de fem brød og de to fisker, så op mot himmelen og velsignet dem; og han brøt brødene og gav dem til disiplene, og disiplene gav dem til folket. 
40:14:20: Og de åt alle og blev mette; og de tok op det som blev tilovers av stykkene, tolv kurver fulle. 
40:14:21: Men de som hadde ett, var omkring fem tusen menn foruten kvinner og barn. 
40:14:22: Og straks nødde han sine disipler til å gå i båten og sette over til hin side før ham, inntil han hadde latt folket fare. 
40:14:23: Og da han hadde latt folket fare, gikk han avsides op i fjellet for å bede; og da det var blitt aften, var han der alene. 
40:14:24: Men båten var alt midt ute på sjøen og arbeidet hårdt mot bølgene, for vinden var imot. 
40:14:25: Men i den fjerde nattevakt kom han til dem, vandrende på sjøen. 
40:14:26: Og da disiplene så ham vandre på sjøen, blev de forferdet og sa: Det er et spøkelse, og de skrek av redsel. 
40:14:27: Men Jesus talte straks til dem og sa: Vær frimodige; det er mig, frykt ikke! 
40:14:28: Da svarte Peter ham og sa: Herre! er det dig, da byd mig komme til dig på vannet! 
40:14:29: Han sa: Kom! Og Peter steg ut av båten og gikk bortover vannet for å komme til Jesus. 
40:14:30: Men da han så det hårde vær, blev han redd og begynte å synke; da ropte han: Herre, frels mig! 
40:14:31: Og Jesus rakte straks hånden ut og tok fatt i ham og sa til ham: Du lite troende! hvorfor tvilte du? 
40:14:32: Og da de steg i båten, la vinden sig. 
40:14:33: Men de som var i båten, kom og falt ned for ham og sa: Sannelig, du er Guds Sønn! 
40:14:34: Og da de var faret over, kom de til Gennesarets land. 
40:14:35: Og da folket på dette sted kjente ham igjen, sendte de bud i hele landet deromkring, og de førte til ham alle dem som hadde ondt, 
40:14:36: og bad ham at de bare måtte få røre ved det ytterste av hans klædebon; og alle de som rørte ved det, blev helbredet. 
40:15:1: Da kom fariseere og skriftlærde fra Jerusalem til Jesus og sa: Mark. 
40:15:2: Hvorfor bryter dine disipler de gamles vedtekt? de vasker jo ikke sine hender når de holder måltid. 
40:15:3: Men han svarte og sa til dem: Og I, hvorfor bryter I Guds bud for eders vedtekts skyld? 
40:15:4: For Gud har gitt det bud: Hedre din far og din mor; og: Den som banner far eller mor, skal visselig dø; 
40:15:5: men I sier: Den som sier til far eller mor: Det du skulde hatt til hjelp av mig, det gir jeg til templet - han skylder ikke å hedre sin far eller sin mor. 
40:15:6: Og I har gjort Guds lov til intet for eders vedtekts skyld. 
40:15:7: I hyklere! rett spådde Esaias om eder da han sa: 
40:15:8: Dette folk ærer mig med lebene; men deres hjerte er langt borte fra mig; 
40:15:9: men de dyrker mig forgjeves, idet de lærer lærdommer som er menneskebud. 
40:15:10: Og han kalte folket til sig og sa til dem: Hør dette og forstå det: 
40:15:11: Ikke det som kommer inn i munnen, gjør mennesket urent; men det som går ut av munnen, det gjør mennesket urent. 
40:15:12: Da gikk hans disipler til ham og sa: Vet du at fariseerne tok anstøt ved å høre dette ord? 
40:15:13: Men han svarte og sa: Enhver plante som min himmelske Fader ikke har plantet, skal rykkes op med rot. 
40:15:14: La dem fare! de er blinde veiledere for blinde; men når en blind leder en blind, faller de begge i grøften. 
40:15:15: Da svarte Peter og sa til ham: Tyd denne lignelse for oss! 
40:15:16: Men han sa: Er også I ennu uforstandige? 
40:15:17: Skjønner I ikke at alt det som kommer inn i munnen, går i buken og kastes ut den naturlige vei? 
40:15:18: Men det som går ut av munnen, det kommer fra hjertet, og dette er det som gjør mennesket urent. 
40:15:19: For fra hjertet kommer onde tanker: mord, hor, utukt, tyveri, falskt vidnesbyrd, bespottelse. 
40:15:20: Dette er det som gjør mennesket urent; men å ete med uvaskede hender gjør ikke mennesket urent. 
40:15:21: Og Jesus gikk bort derfra, og trakk sig tilbake til landet ved Tyrus og Sidon. 
40:15:22: Og se, en kananeisk kvinne kom fra de landemerker og ropte: Herre, du Davids sønn! miskunn dig over mig! min datter plages ille av en ond ånd. 
40:15:23: Men han svarte henne ikke et ord. Da gikk hans disipler til ham og bad ham og sa: Skill dig av med henne! for hun roper efter oss. 
40:15:24: Men han svarte og sa: Jeg er ikke utsendt til andre enn de fortapte får av Israels hus. 
40:15:25: Men hun kom og falt ned for ham og sa: Herre, hjelp mig! 
40:15:26: Men han svarte og sa: Det er ikke rett å ta brødet fra barna og kaste det for de små hunder. 
40:15:27: Men hun sa: Det er sant, Herre! for de små hunder eter jo av de smuler som faller fra deres herrers bord. 
40:15:28: Da svarte Jesus og sa til henne: Kvinne! din tro er stor; dig skje som du vil! Og hennes datter blev helbredet fra samme stund. 
40:15:29: Og Jesus gikk bort derfra og kom til den Galileiske Sjø, og han gikk op i fjellet og satte sig der. 
40:15:30: Og meget folk kom til ham, og de hadde med sig halte, blinde, stumme, vanføre og mange andre; og de la dem for hans føtter, og han helbredet dem, 
40:15:31: så at folket undret sig da de så stumme tale, vanføre helbredes, halte gå og blinde se; og de priste Israels Gud. 
40:15:32: Men Jesus kalte sine disipler til sig og sa: Jeg ynkes inderlig over folket; for de har alt vært hos mig i tre dager, og de har ikke noget å ete; og la dem fare fastende fra mig vil jeg ikke, forat de ikke skal vansmekte på veien. 
40:15:33: Og hans disipler sa til ham: Hvorfra skal vi her i ørkenen få brød nok til å mette så meget folk? 
40:15:34: Og Jesus sa til dem: Hvor mange brød har I? De sa: Syv, og nogen få småfisker. 
40:15:35: Da bød han folket sette sig ned på jorden, 
40:15:36: og han tok de syv brød og fiskene, takket og brøt dem og gav dem til disiplene, og disiplene til folket. 
40:15:37: Og de åt alle sammen og blev mette; og de tok op det som blev tilovers av stykkene, syv kurver fulle. 
40:15:38: Men de som hadde ett, var fire tusen menn foruten kvinner og barn. 
40:15:39: Og da han hadde latt folket fare, gikk han i båten og kom til landet ved Magadan. 
40:16:1: Og fariseerne og sadduseerne gikk til ham og fristet ham, og bad at han vilde la dem få se et tegn fra himmelen. 
40:16:2: Men han svarte og sa til dem: Når det er blitt aften, sier I: Det blir godt vær, for himmelen er rød; 
40:16:3: og om morgenen: Idag blir det uvær, for himmelen er rød og mørk. Himmelens utseende vet I å tyde, men tidenes tegn kan I ikke tyde. 
40:16:4: En ond og utro slekt krever tegn, og tegn skal ikke gis den, uten Jonas' tegn. Og han forlot dem og gikk bort. 
40:16:5: Og da disiplene kom over til hin side, hadde de glemt å ta brød med. 
40:16:6: Da sa Jesus til dem: Se eder for og ta eder i vare for fariseernes og sadduseernes surdeig! 
40:16:7: Da tenkte de ved sig selv og sa: Det er fordi vi ikke har tatt brød med. 
40:16:8: Men da Jesus merket det, sa han til dem: I lite troende! hvorfor tenker I ved eder selv at det er fordi I ikke har tatt brød med? 
40:16:9: Skjønner I ennu ikke, og kommer I ikke i hu de fem brød til de fem tusen, og hvor mange kurver I da fikk, 
40:16:10: eller de syv brød til de fire tusen, og hvor mange kurver I da fikk? 
40:16:11: Kan I da ikke skjønne at det ikke var om brød jeg talte til eder? Men ta eder i vare for fariseernes og sadduseernes surdeig! 
40:16:12: Da forstod de at han ikke hadde talt om at de skulde ta sig i vare for surdeigen i brød, men for fariseernes og sadduseernes lære. 
40:16:13: Men da Jesus var kommet til landet ved Cesarea Filippi, spurte han sine disipler og sa: Hvem sier folk at Menneskesønnen er? 
40:16:14: De sa: Nogen sier døperen Johannes, andre Elias, andre igjen Jeremias eller en av profetene. 
40:16:15: Han sa til dem: Men I, hvem sier I at jeg er? 
40:16:16: Da svarte Simon Peter og sa: Du er Messias, den levende Guds Sønn. 
40:16:17: Og Jesus svarte og sa til ham: Salig er du, Simon, Jonas' sønn! for kjød og blod har ikke åpenbaret dig det, men min Fader i himmelen. 
40:16:18: Og jeg sier dig at du er Peter; og på denne klippe vil jeg bygge min menighet, og dødsrikets porter skal ikke få makt over den. 
40:16:19: Og jeg vil gi dig nøklene til himlenes rike, og det du binder på jorden, skal være bundet i himmelen, og det du løser på jorden, skal være løst i himmelen. 
40:16:20: Da bød han sine disipler at de ikke skulde si til nogen at han var Messias. 
40:16:21: Fra den tid begynte Jesus å gi sine disipler til kjenne at han skulde gå til Jerusalem og lide meget av de eldste og yppersteprestene og de skriftlærde, og slåes ihjel, og opstå på den tredje dag. 
40:16:22: Da tok Peter ham til side og begynte å irettesette ham og sa: Gud fri dig, Herre! dette må ingenlunde vederfares dig! 
40:16:23: Men han vendte sig og sa til Peter: Vik bak mig, Satan! du er mig til anstøt; for du har ikke sans for det som hører Gud til, men bare for det som hører menneskene til. 
40:16:24: Da sa Jesus til sine disipler: Vil nogen komme efter mig, da må han fornekte sig selv og ta sitt kors op og følge mig. 
40:16:25: For den som vil berge sitt liv, skal miste det; men den som mister sitt liv for min skyld, skal finne det. 
40:16:26: For hvad gagner det et menneske om han vinner den hele verden, men tar skade på sin sjel? eller hvad vil et menneske gi til vederlag for sin sjel? 
40:16:27: For Menneskesønnen skal komme i sin Faders herlighet med sine engler, og da skal han betale enhver efter hans gjerning. 
40:16:28: Sannelig sier jeg eder: Nogen av dem som her står, skal ikke smake døden før de ser Menneskesønnen komme i sitt rike. 
40:17:1: Og seks dager derefter tok Jesus med sig Peter, Jakob og hans bror Johannes og førte dem avsides op på et høit fjell. 
40:17:2: Og han blev forklaret for deres øine, og hans åsyn skinte som solen, og hans klær blev hvite som schmelken. 
40:17:3: Og se, Moses og Elias viste sig for dem og talte med ham. 
40:17:4: Da tok Peter til orde og sa til Jesus: Herre! det er godt at vi er her; vil du, så skal jeg gjøre tre boliger her, en til dig og en til Moses og en til Elias. 
40:17:5: Mens han ennu talte, se, da kom det en lysende sky og overskygget dem, og se, det kom en røst ut av skyen, som sa: Dette er min Sønn, den elskede, i hvem jeg har velbehag; hør ham! 
40:17:6: Og da disiplene hørte det, falt de ned på sitt ansikt og blev meget forferdet. 
40:17:7: Og Jesus gikk bort og rørte ved dem og sa: Stå op, og frykt ikke! 
40:17:8: Men da de så op, så de ingen uten Jesus alene. 
40:17:9: Og da de gikk ned av fjellet, bød Jesus dem: I skal ikke fortelle nogen om dette syn før Menneskesønnen er opstanden fra de døde. 
40:17:10: Og disiplene spurte ham og sa: Hvorfor sier da de skriftlærde at Elias først må komme? 
40:17:11: Han svarte og sa: Elias kommer visstnok og skal sette alt i rette skikk; 
40:17:12: men jeg sier eder at Elias er alt kommet, og de kjente ham ikke, men gjorde med ham hvad de vilde. Det samme skal også Menneskesønnen lide av dem. 
40:17:13: Da forstod disiplene at det var om døperen Johannes han talte til dem. 
40:17:14: Og da de kom til folket, kom en mann til ham og falt på kne for ham og sa: 
40:17:15: Herre! miskunn dig over min sønn! Han er månesyk og Iider storlig; ofte faller han i ilden og ofte i vannet. 
40:17:16: Og jeg førte ham til dine disipler; men de kunde ikke helbrede ham. 
40:17:17: Da svarte Jesus og sa: Du vantro og vrange slekt! hvor lenge skal jeg være hos eder? hvor lenge skal jeg tåle eder? Før ham hit til mig! 
40:17:18: Og Jesus truet ham, og den onde ånd fór ut av ham, og gutten blev helbredet fra samme stund. 
40:17:19: Da gikk disiplene til Jesus i enrum og sa: Hvorfor kunde ikke vi drive den ut? 
40:17:20: Jesus sa til dem: For eders vantros skyld; for sannelig sier jeg eder: Har I tro som et sennepskorn, da kan I si til dette fjell: Flytt dig derfra og dit! og det skal flytte sig, og intet skal være umulig for eder. 
40:17:21: Men dette slag farer ikke ut uten ved bønn og faste. 
40:17:22: Men mens de ferdedes i Galilea, sa Jesus til dem: Menneskesønnen skal overgis i menneskers hender, 
40:17:23: og de skal slå ham ihjel, og på den tredje dag skal han opstå. Og de blev meget bedrøvet. 
40:17:24: Men da de kom til Kapernaum, gikk de som krevde inn tempelskatten, til Peter og sa: Betaler ikke eders mester tempelskatt? 
40:17:25: Han sa: Jo. Og da han kom inn i huset, kom Jesus ham i forkjøpet og sa til ham: Hvad tykkes dig, Simon? av hvem tar kongene på jorden toll eller skatt? av sine barn eller av de fremmede? 
40:17:26: Han sa: Av de fremmede. Da sa Jesus til ham: Så er jo barna fri. 
40:17:27: Men forat vi ikke skal gi dem anstøt, så gå ned til sjøen, kast ut en krok, og ta den første fisk som kommer op; og når du åpner munnen på den, skal du finne en stater; ta den og gi dem for mig og dig! 
40:18:1: I samme stund kom disiplene til Jesus og sa: Hvem er den største i himlenes rike? 
40:18:2: Og han kalte et lite barn til sig og stilte det midt iblandt dem 
40:18:3: og sa: Sannelig sier jeg eder: Uten at I omvender eder og blir som barn, kommer I ingenlunde inn i himlenes rike. 
40:18:4: Derfor, den som gjør sig liten som dette barn, han er den største i himlenes rike; 
40:18:5: og den som tar imot ett sådant barn for mitt navns skyld, tar imot mig; 
40:18:6: men den som forfører en av disse små som tror på mig, for ham var det bedre at det var hengt en kvernsten om hans hals og han var nedsenket i havets dyp. 
40:18:7: Ve verden for forførelser! for forførelser må komme; men ve det menneske som forførelsen kommer fra! 
40:18:8: Men om din hånd eller din fot frister dig, da hugg den av og kast den fra dig! det er bedre for dig å gå halt eller vanfør inn til livet enn å ha to hender eller to føtter og bli kastet i den evige ild. 
40:18:9: Og om ditt øie frister dig, da riv det ut og kast det fra dig! det er bedre for dig å gå enøiet inn til livet enn å ha to øine og bli kastet i helvedes ild. 
40:18:10: Se til at I ikke forakter en av disse små! for jeg sier eder at deres engler i himmelen ser alltid min himmelske Faders åsyn. 
40:18:11: For Menneskesønnen er kommet for å frelse det som var fortapt. 
40:18:12: Hvad tykkes eder? om et menneske har hundre får, og ett av dem forviller sig, forlater han da ikke de ni og nitti i fjellet og går bort og leter efter det som har forvillet sig? 
40:18:13: Og hender det at han finner det, sannelig sier jeg eder: Han gleder sig mere over det enn over de ni og nitti som ikke har forvillet sig. 
40:18:14: Således er det ikke eders himmelske Faders vilje at en eneste av disse små skal fortapes. 
40:18:15: Men om din bror synder mot dig, da gå bort og irettesett ham i enrum! hører han på dig, da har du vunnet din bror; 
40:18:16: men vil han ikke høre, da ta ennu en eller to med dig, forat enhver sak skal stå fast ved to eller tre vidners ord. 
40:18:17: Men hører han ikke på dem, da si det til menigheten! men hører han heller ikke på menigheten, da skal han være for dig som en hedning og en tolder. 
40:18:18: Sannelig sier jeg eder: Alt det I binder på jorden, skal være bundet i himmelen, og alt det I løser på jorden, skal være løst i himmelen. 
40:18:19: Atter sier jeg eder: Alt det to av eder på jorden blir enige om å bede om, det skal gis dem av min Fader i himmelen. 
40:18:20: For hvor to eller tre er samlet i mitt navn, der er jeg midt iblandt dem. 
40:18:21: Da gikk Peter til ham og sa: Herre! hvor ofte skal min bror synde mot mig og jeg tilgi ham det? så meget som syv ganger? 
40:18:22: Jesus sa til ham: Jeg sier dig: Ikke syv ganger, men sytti ganger syv ganger. 
40:18:23: Derfor er himlenes rike å ligne med en konge som vilde holde regnskap med sine tjenere. 
40:18:24: Men da han begynte på opgjøret, blev en ført frem for ham, som skyldte ti tusen talenter. 
40:18:25: Men da han ikke hadde noget å betale med, bød hans herre at han skulde selges, han og hans hustru og barn og alt det han hadde, og at der skulde betales. 
40:18:26: Da falt denne tjener ned for ham og sa: Vær langmodig med mig, så skal jeg betale dig alt sammen! 
40:18:27: Da ynkedes denne tjeners herre inderlig over ham, og lot ham løs og eftergav ham gjelden. 
40:18:28: Men da denne tjener gikk ut, traff han en av sine medtjenere som skyldte ham hundre penninger, og han tok fatt på ham og holdt på å kvele ham og sa: Betal det du skylder! 
40:18:29: Da falt hans medtjener ned og bad ham og sa: Vær langmodig med mig, så skal jeg betale dig! 
40:18:30: Men han vilde ikke; han gikk bort og kastet ham i fengsel, til han betalte det han var skyldig. 
40:18:31: Men da hans medtjenere så hvad som skjedde, blev de meget bedrøvet, og de kom og fortalte sin herre alt det som var skjedd. 
40:18:32: Da kalte hans herre ham for sig og sa til ham: Du onde tjener! all din gjeld eftergav jeg dig, fordi du bad mig; 
40:18:33: burde ikke også du forbarme dig over din medtjener, likesom jeg forbarmet mig over dig? 
40:18:34: Og hans herre blev vred, og overgav ham til dem som piner, inntil han betalte alt det han var ham skyldig. 
40:18:35: Således skal også min himmelske Fader gjøre med eder om ikke enhver av eder av hjertet tilgir sin bror. 
40:19:1: Og det skjedde da Jesus hadde endt denne tale, da drog han bort fra Galilea og kom til Judeas landemerker på hin side Jordan. 
40:19:2: Og meget folk fulgte ham, og han helbredet dem der. 
40:19:3: Og fariseerne kom til ham, fristet ham og sa: Har en mann lov til å skille sig fra sin hustru for enhver saks skyld? 
40:19:4: Han svarte og sa: Har I ikke lest at han som skapte dem, fra begynnelsen av skapte dem til mann og kvinne 
40:19:5: og sa: Derfor skal mannen forlate far og mor og holde sig til sin hustru, og de to skal være ett kjød? 
40:19:6: Så er de da ikke lenger to, men ett kjød. Derfor, det som Gud har sammenføiet, det skal et menneske ikke adskille. 
40:19:7: De sa til ham: Hvorfor bød da Moses å gi hustruen skilsmissebrev og skille sig fra henne? 
40:19:8: Han sa til dem: For eders hårde hjertes skyld gav Moses eder lov til å skilles fra eders hustruer; men fra begynnelsen av har det ikke vært således. 
40:19:9: Men jeg sier eder at den som skiller sig fra sin hustru, uten for hors skyld, og gifter sig med en annen, han driver hor, og den som gifter sig med en fraskilt kvinne, han driver hor. 
40:19:10: Hans disipler sa til ham: Står saken så mellem mann og hustru, da er det ikke godt å gifte sig. 
40:19:11: Men han sa til dem: Ikke alle fatter dette ord, men bare de som det er gitt. 
40:19:12: For der er gildinger som er født slik av mors liv, og der er gildinger som er gildet av menneskene, og der er gildinger som har gildet sig selv for himlenes rikes skyld. Den som kan fatte dette ord, han fatte det! 
40:19:13: Da bar de små barn til ham, forat han skulde legge sine hender på dem og bede; men disiplene truet dem. 
40:19:14: Men Jesus sa: La de små barn være og hindre dem ikke fra å komme til mig! for himlenes rike hører sådanne til. 
40:19:15: Og han la sine hender på dem og drog derfra. 
40:19:16: Og se, det kom en til ham og sa: Mester! hvad godt skal jeg gjøre for å få evig liv? 
40:19:17: Men han sa til ham: Hvorfor spør du mig om det gode? Det er bare en som er god. Men vil du gå inn til livet, da hold budene! 
40:19:18: Han sa til ham: Hvilke? Jesus sa: Du skal ikke slå ihjel, du skal ikke drive hor, du skal ikke stjele, du skal ikke si falskt vidnesbyrd, 
40:19:19: hedre din far og din mor, og: du skal elske din næste som dig selv. 
40:19:20: Den unge mann sa til ham: Alt dette har jeg holdt; hvad fattes mig ennu? 
40:19:21: Jesus sa til ham: Vil du være fullkommen, da gå bort og selg det du eier, og gi det til de fattige, så skal du få en skatt i himmelen; kom så og følg mig! 
40:19:22: Men da den unge mann hørte det, gikk han bedrøvet bort; for han var meget rik. 
40:19:23: Og Jesus sa til sine disipler: Sannelig sier jeg eder: Det vil være vanskelig for en rik å komme inn i himlenes rike. 
40:19:24: Atter sier jeg eder: Det er lettere for en kamel å gå gjennem et nåleøie enn for en rik å gå inn i Guds rike. 
40:19:25: Men da disiplene hørte det, blev de meget forferdet og sa: Hvem kan da bli frelst? 
40:19:26: Men Jesus så på dem og sa til dem: For mennesker er dette umulig, men for Gud er alt mulig. 
40:19:27: Da svarte Peter ham: Se, vi har forlatt alt og fulgt dig; hvad skal da vi få? 
40:19:28: Da sa Jesus til dem: Sannelig sier jeg eder: I som har fulgt mig, I skal i gjenfødelsen, når Menneskesønnen sitter på sin herlighets trone, også sitte på tolv troner og dømme Israels tolv stammer. 
40:19:29: Og hver den som har forlatt hus eller brødre eller søstre eller far eller mor eller barn eller akrer for mitt navns skyld? skal få mangefold igjen og arve evig liv. 
40:19:30: Men mange som er de første, skal bli de siste, og de siste de første. 
40:20:1: For himlenes rike er likt en husbond som gikk ut tidlig om morgenen for å leie arbeidere til sin vingård. 
40:20:2: Og da han var blitt enig med arbeiderne om en penning om dagen, sendte han dem bort til sin vingård. 
40:20:3: Og han gikk ut ved den tredje time og så andre stå ledige på torvet, 
40:20:4: og til dem sa han: Gå også I bort til vingården, og hvad rett er, vil jeg gi eder. Og de gikk avsted. 
40:20:5: Atter gikk han ut ved den sjette og den niende time og gjorde likeså. 
40:20:6: Og han gikk ut ved den ellevte time og fant andre stående der, og han sa til dem: Hvorfor står I her ledige hele dagen? 
40:20:7: De sa til ham: Fordi ingen har leid oss. Han sa til dem: Gå også I bort til vingården! 
40:20:8: Men da det var blitt aften, sa vingårdens herre til sin forvalter: Kall arbeiderne frem og gi dem deres lønn; begynn med de siste og end med de første! 
40:20:9: Så kom de som var leid ved den ellevte time, og de fikk hver sin penning. 
40:20:10: Men da de første kom, tenkte de at de skulde få mere; men de fikk hver sin penning de også. 
40:20:11: Men da de fikk den, knurret de mot husbonden og sa: 
40:20:12: Disse siste har bare vært her en time, og du har gjort dem like med oss som har båret dagens byrde og hete. 
40:20:13: Men han svarte en av dem og sa: Min venn! jeg gjør dig ikke urett, blev du ikke enig med mig om en penning? 
40:20:14: Ta ditt og gå! Men jeg vil gi denne siste like så meget som dig. 
40:20:15: Eller har jeg ikke lov til å gjøre med mitt hvad jeg vil? eller er ditt øie ondt fordi jeg er god? 
40:20:16: Således skal de siste bli de første, og de første de siste; for mange er kalt, men få utvalgt. 
40:20:17: Og da Jesus drog op til Jerusalem, tok han de tolv disipler til side og sa til dem på veien: 
40:20:18: Se, vi går op til Jerusalem, og Menneskesønnen skal overgis til yppersteprestene og de skriftlærde, og de skal dømme ham til døden 
40:20:19: og overgi ham til hedningene til å spottes og hudstrykes og korsfestes; og på den tredje dag skal han opstå. 
40:20:20: Da gikk Sebedeus-sønnenes mor til ham med sine sønner, falt ned for ham og bad ham om noget. 
40:20:21: Han sa til henne: Hvad vil du? Hun sa til ham: Si at disse mine to sønner skal sitte, den ene ved din høire og den andre ved din venstre side i ditt rike! 
40:20:22: Men Jesus svarte og sa: I vet ikke hvad det er I ber om. Kan I drikke den kalk jeg skal drikke? De sa til ham: Det kan vi. 
40:20:23: Han sa til dem: Min kalk skal I nok drikke; men å sitte ved min høire og ved min venstre side, det tilkommer det ikke mig å gi nogen, men det gis dem som det er beredt av min Fader. 
40:20:24: Da de ti hørte dette, blev de harme på de to brødre. 
40:20:25: Men Jesus kalte dem til sig og sa: I vet at fyrstene hersker over sine folk, og deres stormenn bruker makt over dem. 
40:20:26: Så skal det ikke være blandt eder; men den som vil bli stor iblandt eder, han skal være eders tjener, 
40:20:27: og den som vil være den første blandt eder, han skal være eders træl, 
40:20:28: likesom Menneskesønnen ikke er kommet for å la sig tjene, men for selv å tjene og gi sitt liv til en løsepenge for mange. 
40:20:29: Og da de gikk ut fra Jeriko, fulgte meget folk ham. 
40:20:30: Og se, to blinde satt ved veien, og da de hørte at det var Jesus som gikk forbi, ropte de: Miskunn dig over oss, Herre, du Davids sønn! 
40:20:31: Folket truet dem at de skulde tie; men de ropte enda mere og sa: Herre, miskunn dig over oss, du Davids sønn! 
40:20:32: Og Jesus stod stille og kalte på dem og sa: Hvad vil I jeg skal gjøre for eder? 
40:20:33: De sa: Herre! at våre øine må bli oplatt! 
40:20:34: Da ynkedes Jesus inderlig og rørte ved deres øine; og straks fikk de sitt syn igjen, og de fulgte ham. 
40:21:1: Og da de kom nær til Jerusalem og var kommet til Betfage ved Oljeberget, da sendte Jesus to disipler avsted og sa til dem: 
40:21:2: Gå inn i den by som ligger rett for eder, og straks skal I finne en aseninne bundet og en fole hos den; løs dem, og før dem til mig! 
40:21:3: Og dersom nogen sier noget til eder, da skal I si: Herren har bruk for dem; så skal han straks sende dem. 
40:21:4: Men dette skjedde forat det skulde opfylles som er talt ved profeten, som sier: 
40:21:5: Si til Sions datter: Se, din konge kommer til dig, saktmodig og ridende på et asen, på trældyrets fole. 
40:21:6: Disiplene gikk da avsted og gjorde som Jesus bød dem; 
40:21:7: de hentet aseninnen og folen, og la sine klær på dem; og han satte sig oppå. 
40:21:8: Og størstedelen av folket bredte sine klær på veien, andre hugg grener av trærne og strødde dem på veien, 
40:21:9: og folket som gikk foran og fulgte efter, ropte: Hosianna Davids sønn! Velsignet være han som kommer i Herrens navn! Hosianna i det høieste! 
40:21:10: Og da han drog inn i Jerusalem, kom hele byen i bevegelse og sa: Hvem er dette? 
40:21:11: Men folket sa: Dette er profeten Jesus fra Nasaret i Galilea. 
40:21:12: Og Jesus gikk inn i Guds tempel og drev ut alle dem som solgte og kjøpte i templet, og pengevekslernes bord og duekremmernes stoler veltet han, 
40:21:13: og han sa til dem: Det er skrevet: Mitt hus skal kalles et bedehus. Men I gjør det til en røverhule. 
40:21:14: Og det kom blinde og halte til ham i templet, og han helbredet dem. 
40:21:15: Men da yppersteprestene og de skriftlærde så de undergjerninger han gjorde, og barna som ropte i templet: Hosianna Davids sønn! da blev de vrede og sa til ham: 
40:21:16: Hører du hvad disse sier? Men Jesus sa til dem: Ja! Har I aldri lest: Av umyndiges og diendes munn har du beredt dig lovprisning? 
40:21:17: Og han forlot dem og gikk utenfor byen til Betania, og var der natten over. 
40:21:18: Om morgenen, da han gikk til byen igjen, blev han hungrig, 
40:21:19: og da han så et fikentre ved veien, gikk han bort til det, men fant ikke noget på det uten bare blad. Da sa han til det: Aldri i evighet skal det mere vokse frukt på dig. Og straks visnet fikentreet. 
40:21:20: Og da disiplene så det, undret de sig og sa: Hvorledes gikk det til at fikentreet straks visnet? 
40:21:21: Men Jesus svarte og sa til dem: Sannelig sier jeg eder: Dersom I har tro og ikke tviler, da skal I ikke alene kunne gjøre dette med fikentreet, men endog om I sier til dette fjell: Løft dig op og kast dig i havet, så skal det skje; 
40:21:22: og alt det I beder om med tro i eders bønn, det skal I få. 
40:21:23: Og da han var kommet inn i templet og lærte der, gikk yppersteprestene og folkets eldste til ham og sa: Med hvad myndighet gjør du dette, og hvem har gitt dig denne myndighet? 
40:21:24: Jesus svarte og sa til dem: Også jeg vil spørre eder om en ting; sier I mig det, da skal også jeg si eder med hvad myndighet jeg gjør dette. 
40:21:25: Johannes' dåp, hvorfra var den? fra himmelen eller fra mennesker? Da tenkte de ved sig selv og sa: Sier vi: Fra himmelen, da sier han til oss: Hvorfor trodde I ham da ikke? 
40:21:26: Men sier vi: Fra mennesker, da frykter vi for folket; for alle holder Johannes for en profet. 
40:21:27: De svarte da Jesus og sa: Vi vet det ikke. Da sa også han til dem: Så sier heller ikke jeg eder med hvad myndighet jeg gjør dette. 
40:21:28: Men hvad tykkes eder? En mann hadde to sønner, og han gikk til den ene og sa: Sønn, gå idag og arbeid i min vingård! 
40:21:29: Men han svarte: Jeg vil ikke. Men siden angret han det og gikk. 
40:21:30: Og han gikk til den andre og sa det samme til ham. Han svarte: Ja, herre! men gikk ikke. 
40:21:31: Hvem av de to gjorde nu farens vilje? De sier: Den første. Jesus sier til dem: Sannelig sier jeg eder at toldere og skjøger kommer før inn i Guds rike enn I. 
40:21:32: For Johannes kom til eder på rettferdighets vei, og I trodde ham ikke, men tolderne og skjøgene trodde ham; men I, enda I så det, angret I det ikke siden, så I trodde ham. 
40:21:33: Hør en annen lignelse: Det var en husbond som plantet en vingård, og han satte et gjerde omkring den og gravde en vinperse i den og bygget et tårn, og så leide han den ut til vingårdsmenn og drog utenlands. 
40:21:34: Da det nu led mot frukttiden, sendte han sine tjenere til vingårdsmennene for å ta imot den frukt som han skulde ha; 
40:21:35: og vingårdsmennene tok hans tjenere: en slo de, en drepte de, en stenet de. 
40:21:36: Atter sendte han andre tjenere, flere enn de første, og de gjorde likeså med dem. 
40:21:37: Men til sist sendte han sin sønn til dem og sa: De vil undse sig for min sønn. 
40:21:38: Men da vingårdsmennene så sønnen, sa de sig imellem: Dette er arvingen; kom, la oss slå ham ihjel, så vi kan få hans arv! 
40:21:39: Og de tok ham og kastet ham ut av vingården og slo ham ihjel. 
40:21:40: Når nu vingårdens herre kommer, hvad skal han da gjøre med disse vingårdsmenn? 
40:21:41: De sier til ham: Ille skal han ødelegge disse illgjerningsmenn, og vingården skal han leie ut til andre vingårdsmenn, som gir ham frukten i rette tid. 
40:21:42: Jesus sier til dem: Har I aldri lest i skriftene: Den sten som bygningsmennene forkastet, den er blitt hjørnesten; av Herren er dette gjort, og det er underfullt i våre øine? 
40:21:43: Derfor sier jeg eder: Guds rike skal tas fra eder og gis til et folk som bærer dets frukter. 
40:21:44: Og den som faller på denne sten, han skal knuses, men den som den faller på, ham skal den smuldre til støv. 
40:21:45: Og da yppersteprestene og fariseerne hørte hans lignelser, skjønte de at han talte om dem. 
40:21:46: Og de søkte å gripe ham, men fryktet for folket; for det holdt ham for en profet. 
40:22:1: Og Jesus tok atter til orde og talte til dem i lignelser og sa: 
40:22:2: Himlenes rike er å ligne med en konge som gjorde bryllup for sin sønn. 
40:22:3: Og han sendte sine tjenere ut for å be de innbudne komme til bryllupet; men de vilde ikke komme. 
40:22:4: Atter sendte han andre tjenere ut og sa: Si til de innbudne: Se, jeg har gjort i stand mitt måltid: mine okser og mitt gjø-fe er slaktet, og alt er ferdig; kom til bryllupet! 
40:22:5: Men de brydde sig ikke om det og gikk sin vei, den ene til sin aker, den annen til sitt kjøbmannskap; 
40:22:6: og de andre tok fatt på hans tjenere, hånte dem og slo dem ihjel. 
40:22:7: Men kongen blev harm, og sendte sine krigshærer ut og drepte disse manndrapere og satte ild på deres by. 
40:22:8: Derefter sier han til sine tjenere: Bryllupet er vel ferdig, men de innbudne var det ikke verd; 
40:22:9: gå derfor ut på veiskjellene og be til bryllups så mange I finner! 
40:22:10: Så gikk da disse tjenere ut på veiene og fikk sammen alle dem de fant, både onde og gode, og bryllupshuset blev fullt av gjester. 
40:22:11: Da nu kongen gikk inn for å se på dem som satt til bords, så han der en mann som ikke hadde bryllupsklædning på. 
40:22:12: Og han sa til ham: Min venn! hvorledes er du kommet inn her og har ikke bryllupsklædning på! Men han tidde. 
40:22:13: Da sa kongen til tjenerne: Bind hender og føtter på ham og kast ham ut i mørket utenfor! Der skal være gråt og tenners gnidsel. 
40:22:14: For mange er kalt, men få er utvalgt. 
40:22:15: Da gikk fariseerne bort og holdt råd om hvorledes de kunde fange ham i ord. 
40:22:16: Og de sendte sine disipler avsted til ham sammen med herodianerne og lot dem si: Mester! vi vet at du er sanndru og lærer Guds vei i sannhet, og ikke bryr dig om nogen, for du gjør ikke forskjell på folk; 
40:22:17: Si oss da: Hvad tykkes dig? er det tillatt å gi keiseren skatt, eller ikke? 
40:22:18: Men Jesus merket deres ondskap og sa: Hvorfor frister I mig, hyklere? 
40:22:19: Vis mig skattens mynt! De rakte ham da en penning. 
40:22:20: Og han sier til dem: Hvis billede og påskrift er dette? 
40:22:21: De sier til ham: Keiserens. Da sier han til dem: Gi da keiseren hvad keiserens er, og Gud hvad Guds er! 
40:22:22: Og da de hørte det, undret de sig, og forlot ham og gikk bort. 
40:22:23: Samme dag kom nogen sadduseere til ham, de som sier at det ikke er nogen opstandelse, og de spurte ham og sa: 
40:22:24: Mester! Moses har sagt: Når en mann dør og ikke har barn, da skal hans bror gifte sig med hans hustru og opreise sin bror avkom. 
40:22:25: Nu var det hos oss syv brødre; og den første giftet sig og døde, og da han ikke hadde avkom, efterlot han sin hustru til broren. 
40:22:26: Likeså den annen og den tredje, like til den syvende. 
40:22:27: Men sist av alle døde kvinnen. 
40:22:28: Men i opstandelsen, hvem av de syv skal da få henne til hustru? for de har jo hatt henne alle sammen. 
40:22:29: Men Jesus svarte og sa til dem: I farer vill fordi I ikke kjenner skriftene og heller ikke Guds kraft. 
40:22:30: For i opstandelsen hverken tar de til ekte eller gis de til ekte, men de er som Guds engler i himmelen. 
40:22:31: Men om de dødes opstandelse, har I da ikke lest hvad som er sagt eder om den av Gud, som sier: 
40:22:32: Jeg er Abrahams Gud og Isaks Gud og Jakobs Gud? Han er ikke de dødes Gud, men de levendes. 
40:22:33: Og da folket hørte det, var de slått av forundring over hans lære. 
40:22:34: Men da fariseerne hørte at han hadde stoppet munnen på sadduseerne, kom de sammen; 
40:22:35: og en av dem, en lovkyndig, spurte for å friste ham: 
40:22:36: Mester! hvilket bud er det største i loven? 
40:22:37: Han sa til ham: Du skal elske Herren din Gud av alt ditt hjerte og av all din sjel og av all din hu. 
40:22:38: Dette er det største og første bud. 
40:22:39: Men det er et annet som er like så stort: Du skal elske din næste som dig selv. 
40:22:40: På disse to bud hviler hele loven og profetene. 
40:22:41: Men mens fariseerne var samlet, spurte Jesus dem: 
40:22:42: Hvad tykkes eder om Messias? hvis sønn er han? De sier til ham: Davids. 
40:22:43: Han sier til dem: Hvorledes kan da David i Ånden kalle ham herre, når han sier: 
40:22:44: Herren sa til min herre: Sett dig ved min høire hånd, til jeg får lagt dine fiender under dine føtter! 
40:22:45: Kaller nu David ham herre, hvorledes kan han da være hans sønn? 
40:22:46: Og ingen kunde svare ham et ord, og heller ikke vågde nogen å spørre ham mere fra den dag. 
40:23:1: Da talte Jesus til folket og til sine disipler og sa: 
40:23:2: På Mose stol sitter de skriftlærde og fariseerne. 
40:23:3: Derfor skal I gjøre og holde alt det de byder eder; men efter deres gjerninger skal I ikke gjøre. For de sier det, men de gjør det ikke; 
40:23:4: de binder svære byrder, som er vanskelige å bære, og legger dem på menneskenes skuldrer, men selv vil de ikke røre dem med sin finger. 
40:23:5: Men de gjør alle sine gjerninger for å sees av menneskene; for de gjør sine minnesedler brede og sine dusker store, 
40:23:6: og de vil gjerne sitte øverst ved gjestebudene og ha de øverste seter i synagogene 
40:23:7: og få hilsninger på torvene og kalles rabbi av menneskene. 
40:23:8: Men I skal ikke la eder kalle rabbi; for en er eders mester, men I er alle brødre. 
40:23:9: Og I skal ikke kalle nogen på jorden eders far; for en er eders far, han som er i himmelen. 
40:23:10: Heller ikke skal I la eder kalle lærere; for en er eders lærer, Kristus. 
40:23:11: Men den største blandt eder skal være eders tjener. 
40:23:12: Den sig selv ophøier, skal fornedres, og den sig selv fornedrer, skal ophøies. 
40:23:13: Men ve eder, I skriftlærde og fariseere, I hyklere, I som lukker himlenes rike for menneskene! for selv går I ikke der inn, og dem som er i ferd med å gå der inn, tillater I ikke å gå inn. 
40:23:14: Ve eder, I skriftlærde og fariseere, I hyklere, I som opeter enkers hus og for et syns skyld holder lange bønner! Derfor skal I få dess hårdere dom. 
40:23:15: Ve eder, I skriftlærde og fariseere, I hyklere, I som farer over hav og land for å vinne en eneste tilhenger, og når han er blitt det, gjør I ham til et helvedes barn, to ganger verre enn I selv er! 
40:23:16: Ve eder, I blinde veiledere, som sier: Om nogen sverger ved templet, det er intet; men den som sverger ved gullet i templet, han er bundet! 
40:23:17: I dårer og blinde! hvad er størst, gullet, eller templet som helliger gullet? 
40:23:18: Og: Om nogen sverger ved alteret, det er intet; men den som sverger ved den offergave som ligger på det, han er bundet. 
40:23:19: I blinde! hvad er størst, offergaven, eller alteret som helliger gaven? 
40:23:20: Derfor, den som sverger ved alteret, han sverger ved det og ved alt det som ligger på det, 
40:23:21: og den som sverger ved templet, han sverger ved det og ved ham som bor i det, 
40:23:22: og den som sverger ved himmelen, han sverger ved Guds trone og ved ham som sitter på den. 
40:23:23: Ve eder, I skriftlærde og fariseere, I hyklere, I som gir tiende av mynte og anis og karve, og ikke enser det som veier tyngre i loven: rett og barmhjertighet og trofasthet! Dette burde gjøres, og det andre ikke lates ugjort. 
40:23:24: I blinde veiledere, som avsiler myggen, men sluker kamelen! 
40:23:25: Ve eder, I skriftlærde og fariseere, I hyklere, I som renser beger og fat utvendig, men innvendig er de fulle av rov og griskhet! 
40:23:26: Du blinde fariseer! rens først begeret og fatet innvendig, forat det også kan bli rent utvendig! 
40:23:27: Ve eder, I skriftlærde og fariseere, I hyklere, I som ligner kalkede graver, som utvendig er fagre å se til, men innvendig er fulle av dødningeben og all urenhet! 
40:23:28: Således synes også I utvendig rettferdige for menneskene, men innvendig er I fulle av hykleri og urettferdighet. 
40:23:29: Ve eder, I skriftlærde og fariseere, I hyklere, I som bygger profetenes graver og pryder de rettferdiges gravsteder og sier: 
40:23:30: Hadde vi levd i våre fedres dager, da hadde vi ikke vært medskyldige med dem i profetenes blod! 
40:23:31: Så gir I da eder selv det vidnesbyrd at I er deres barn som slo profetene ihjel; 
40:23:32: fyll da også I eders fedres mål! 
40:23:33: I slanger! I ormeyngel! hvorledes kan I undfly helvedes dom? 
40:23:34: Derfor, se, jeg sender til eder profeter og vismenn og skriftlærde; nogen av dem skal I slå ihjel og korsfeste, og nogen av dem skal I hudstryke i eders synagoger og forfølge fra by til by, 
40:23:35: forat det skal komme over eder alt det rettferdige blod som er utøst på jorden, fra den rettferdige Abels blod inntil Sakarias', Barakias' sønns blod, han som I slo ihjel mellem templet og alteret; 
40:23:36: sannelig sier jeg eder: Alt dette skal komme over denne slekt. 
40:23:37: Jerusalem! Jerusalem! du som slår ihjel profetene, og stener dem som er sendt til dig! hvor ofte jeg vilde samle dine barn, likesom en høne samler sine kyllinger under sine vinger! Og I vilde ikke. 
40:23:38: Se, eders hus skal lates eder øde. 
40:23:39: For jeg sier eder: Fra nu av skal I ikke se mig før I sier: Velsignet være han som kommer i Herrens navn! 
40:24:1: Og Jesus gikk ut av templet og drog derfra, og hans disipler kom til ham for å vise ham templets bygninger. 
40:24:2: Men han svarte og sa til dem: Ser I ikke alt dette? Sannelig sier jeg eder: Her skal ikke levnes sten på sten som ikke skal brytes ned. 
40:24:3: Men da han satt på Oljeberget, gikk hans disipler til ham i enrum og sa: Si oss: Når skal dette skje? og hvad skal tegnet være på ditt komme og på verdens ende? 
40:24:4: Og Jesus svarte og sa til dem: Se til at ikke nogen fører eder vill! 
40:24:5: For mange skal komme i mitt navn og si: Jeg er Messias; og de skal føre mange vill. 
40:24:6: Og I skal høre krig og rykter om krig; se til at I ikke lar eder skremme! for det må så skje, men enden er ikke enda. 
40:24:7: For folk skal reise sig mot folk, og rike mot rike, og det skal være hunger og jordskjelv både her og der. 
40:24:8: Men alt dette er begynnelsen til veene. 
40:24:9: Da skal de overgi eder til trengsel og slå eder ihjel, og I skal hates av alle folkeslag for mitt navns skyld. 
40:24:10: Og da skal mange ta anstøt, og de skal forråde hverandre og hate hverandre; 
40:24:11: og mange falske profeter skal opstå og føre mange vill. 
40:24:12: Og fordi urettferdigheten tar overhånd, skal kjærligheten bli kold hos de fleste. 
40:24:13: Men den som holder ut inntil enden, han skal bli frelst. 
40:24:14: Og dette evangelium om riket skal forkynnes over hele jorderike til et vidnesbyrd for alle folkeslag, og da skal enden komme. 
40:24:15: Når I da ser ødeleggelsens vederstyggelighet, som profeten Daniel har talt om, stå på hellig grunn - den som leser det, han se til å skjønne det! - 
40:24:16: da må de som er i Judea, fly til fjells, 
40:24:17: og den som er på taket, ikke stige ned for å hente noget fra sitt hus, 
40:24:18: og den som er ute på marken, ikke vende tilbake for å hente sin kappe. 
40:24:19: Ve de fruktsommelige og dem som gir die, i de dager! 
40:24:20: Men bed at eders flukt ikke må skje om vinteren eller på sabbaten! 
40:24:21: for da skal det være så stor en trengsel som ikke har vært fra verdens begynnelse inntil nu, og heller ikke skal bli. 
40:24:22: Og blev ikke de dager forkortet, da blev intet kjød frelst; men for de utvalgtes skyld skal de dager bli forkortet. 
40:24:23: Om nogen da sier til eder: Se, her er Messias, eller der, da skal I ikke tro det. 
40:24:24: For falske messiaser og falske profeter skal opstå og gjøre store tegn og under, så at endog de utvalgte skulde føres vill, om det var mulig. 
40:24:25: Se, jeg har sagt eder det forut. 
40:24:26: Om de da sier til eder: Se, han er ute i ørkenen, da gå ikke der ut; se, han er inne i kammerne, da tro det ikke! 
40:24:27: For som lynet går ut fra øst og skinner like til vest, således skal Menneskesønnens komme være. 
40:24:28: Hvor åtselet er, der skal ørnene samles. 
40:24:29: Men straks efter de dagers trengsel skal solen bli formørket, og månen ikke gi sitt skinn, og stjernene skal falle ned fra himmelen, og himmelens krefter skal rokkes. 
40:24:30: Og da skal Menneskesønnens tegn vise sig på himmelen, og da skal alle jordens slekter jamre sig, og de skal se Menneskesønnen komme i himmelens skyer med kraft og megen herlighet. 
40:24:31: Og han skal sende ut sine engler med basunens veldige røst, og de skal samle hans utvalgte fra de fire verdenshjørner, fra himmelbryn til himmelbryn. 
40:24:32: Lær en lignelse av fikentreet: Så snart det kommer saft i dets grener, og dets blader springer ut, da vet I at sommeren er nær; 
40:24:33: således skal også I, når I ser alt dette, vite at han er nær for døren. 
40:24:34: Sannelig sier jeg eder: Denne slekt skal ingenlunde forgå før alt dette skjer. 
40:24:35: Himmel og jord skal forgå, men mine ord skal ingenlunde forgå. 
40:24:36: Men hin dag og time vet ingen, ikke engang himmelens engler, men alene min Fader. 
40:24:37: Og som Noahs dager var, således skal Menneskesønnens komme være; 
40:24:38: for likesom de i dagene før vannflommen åt og drakk, tok til ekte og gav til ekte, like til den dag da Noah gikk inn i arken, 
40:24:39: og de visste ikke av før vannflommen kom og tok dem alle, således skal også Menneskesønnens komme være. 
40:24:40: Da skal to være ute på marken; en blir tatt med, og en blir latt tilbake. 
40:24:41: To kvinner skal male på kvernen; en blir tatt med, og en blir latt tilbake. 
40:24:42: Våk derfor! for I vet ikke hvad dag eders Herre kommer. 
40:24:43: Men det skal I vite at dersom husbonden visste i hvilken nattevakt tyven kom, da vilde han våke, og ikke la nogen bryte inn i sitt hus. 
40:24:44: Derfor vær også I rede! for Menneskesønnen kommer i den time I ikke tenker. 
40:24:45: Hvem er da den tro og kloke tjener, som hans husbond har satt over sine tjenestefolk for å gi dem deres mat i rette tid? 
40:24:46: Salig er den tjener som hans husbond finner å gjøre så når han kommer. 
40:24:47: Sannelig sier jeg eder: Han skal sette ham over alt det han eier. 
40:24:48: Men dersom den onde tjener sier i sitt hjerte: Min herre gir sig tid, 
40:24:49: og så gir sig til å slå sine medtjenere og eter og drikker med drankerne, 
40:24:50: da skal denne tjeners herre komme en dag han ikke venter, og en time han ikke vet, 
40:24:51: og hugge ham sønder og gi ham Iodd og del med hyklerne; der skal være gråt og tenners gnidsel. 
40:25:1: Da skal himlenes rike være å ligne med ti jomfruer som tok sine lamper og gikk ut for å møte brudgommen. 
40:25:2: Men fem av dem var dårlige og fem kloke; 
40:25:3: de dårlige tok sine lamper, men tok ikke olje med sig; 
40:25:4: men de kloke tok olje i sine kanner sammen med lampene. 
40:25:5: Men da brudgommen gav sig tid, slumret de alle inn og sov. 
40:25:6: Men midt på natten lød der et rop: Se, brudgommen kommer! gå ham i møte! 
40:25:7: Da våknet alle jomfruene og gjorde sine lamper i stand. 
40:25:8: Men de dårlige sa til de kloke: Gi oss av eders olje! for våre lamper slukner. 
40:25:9: Men de kloke svarte: Nei; det vilde ikke bli nok både til oss og til eder; gå heller til kjøbmennene og kjøp til eder selv! 
40:25:10: Men mens de gikk bort for å kjøpe, kom brudgommen, og de som var rede, gikk inn med ham til bryllupet; og døren blev lukket. 
40:25:11: Til sist kom da også de andre jomfruer og sa: Herre! herre! lukk op for oss! 
40:25:12: Men han svarte og sa: Sannelig sier jeg eder: Jeg kjenner eder ikke. 
40:25:13: Våk derfor! for I vet ikke dagen eller timen. 
40:25:14: For det er likesom en mann som vilde drage utenlands og kalte sine tjenere for sig og overgav dem sin eiendom, 
40:25:15: og en gav han fem talenter, en annen to, og atter en annen en, hver efter som han var duelig til, og så drog han utenlands. 
40:25:16: Den som hadde fått de fem talenter, gikk da straks bort og kjøpslo med dem, og tjente fem talenter til. 
40:25:17: Likeså den som hadde fått de to; han tjente to til. 
40:25:18: Men den som hadde fått den ene, gikk bort og gravde i jorden og gjemte sin herres penger. 
40:25:19: Lang tid derefter kom da disse tjeneres herre og holdt regnskap med dem. 
40:25:20: Da kom han frem som hadde fått de fem talenter, og hadde med sig fem talenter til og sa: Herre! du gav mig fem talenter; se, jeg har tjent fem talenter til. 
40:25:21: Hans herre sa til ham: Vel, du gode og tro tjener! du har vært tro over lite, jeg vil sette dig over meget; gå inn til din herres glede! 
40:25:22: Da kom også han frem som hadde fått de to talenter, og sa: Herre! du gav mig to talenter; se, jeg har tjent to talenter til. 
40:25:23: Hans herre sa til ham: Vel, du gode og tro tjener! du har vært tro over lite, jeg vil sette dig over meget; gå inn til din herres glede! 
40:25:24: Men også han kom frem som hadde fått den ene talent, og sa: Herre! jeg visste at du er en hård mann, som høster hvor du ikke sådde, og sanker hvor du ikke strødde; 
40:25:25: derfor blev jeg redd, og gikk bort og gjemte din talent i jorden; se, her har du ditt. 
40:25:26: Da svarte hans herre og sa til ham: Du dårlige og late tjener! du visste at jeg høster hvor jeg ikke sådde, og sanker hvor jeg ikke strødde! 
40:25:27: derfor burde du ha satt mine penger ut hos pengevekslerne, så hadde jeg fått mitt igjen med renter når jeg kom. 
40:25:28: Ta derfor talenten fra ham og gi den til ham som har de ti talenter! 
40:25:29: For hver den som har, ham skal gis, og han skal ha overflod; men den som ikke har, fra ham skal tas endog det han har. 
40:25:30: Og kast den unyttige tjener ut i mørket utenfor! Der skal være gråt og tenners gnidsel. 
40:25:31: Men når Menneskesønnen kommer i sin herlighet, og alle englene med ham, da skal han sitte på sin herlighets trone. 
40:25:32: Og alle folkeslag skal samles for hans åsyn, og han skal skille dem fra hverandre, likesom hyrden skiller fårene fra gjetene, 
40:25:33: og han skal stille fårene ved sin høire side, men gjetene ved den venstre. 
40:25:34: Da skal kongen si til dem ved sin høire side: Kom hit, I min Faders velsignede! arv det rike som er beredt eder fra verdens grunnvoll blev lagt! 
40:25:35: For jeg var hungrig, og I gav mig å ete; jeg var tørst, og I gav mig å drikke; jeg var fremmed, og I tok imot mig; 
40:25:36: jeg var naken, og I klædde mig; jeg var syk, og I så til mig; jeg var i fengsel, og I kom til mig. 
40:25:37: Da skal de rettferdige svare ham og si: Herre! når så vi dig hungrig og gav dig mat, eller tørst og gav dig drikke? 
40:25:38: Når så vi dig fremmed og tok imot dig, eller naken og klædde dig? 
40:25:39: Når så vi dig syk eller i fengsel og kom til dig? 
40:25:40: Og kongen skal svare og si til dem: Sannelig sier jeg eder: Hvad I har gjort imot en av disse mine minste brødre, det har I gjort imot mig. 
40:25:41: Da skal han også si til dem ved den venstre side: Gå bort fra mig, I forbannede, i den evige ild, som er beredt djevelen og hans engler! 
40:25:42: For jeg var hungrig, og I gav gav mig ikke å ete; jeg var tørst, og I gav mig ikke å drikke; 
40:25:43: jeg var fremmed, og I tok ikke imot mig; jeg var naken, og I klædde mig ikke; jeg var syk og i fengsel, og I så ikke til mig. 
40:25:44: Da skal også de svare ham og si: Herre! når så vi dig hungrig eller tørst eller fremmed eller naken eller syk eller i fengsel og tjente dig ikke? 
40:25:45: Da skal han svare dem og si: Sannelig sier jeg eder: Hvad I ikke har gjort imot en av disse minste, det har I heller ikke gjort imot mig. 
40:25:46: Og disse skal gå bort til evig pine, men de rettferdige til evig liv. 
40:26:1: Og det skjedde da Jesus hadde endt hele denne tale, da sa han til sine disipler: 
40:26:2: I vet at om to dager er det påske, og da skal Menneskesønnen overgis til å korsfestes. 
40:26:3: Da kom yppersteprestene og folkets eldste sammen hos ypperstepresten, som hette Kaifas, i hans gård, 
40:26:4: og de rådslo om å gripe Jesus med list og slå ham ihjel. 
40:26:5: Men de sa: Ikke på høitiden, forat det ikke skal bli opstyr blandt folket. 
40:26:6: Men da Jesus var kommet til Betania og var i Simon den spedalskes hus, 
40:26:7: da kom en kvinne til ham, som hadde en alabaster-krukke med kostelig salve, og hun helte den ut over hans hode, mens han satt til bords. 
40:26:8: Men da disiplene så det, blev de vrede og sa: Hvad skal denne spille være til? 
40:26:9: Dette kunde jo være solgt for mange penger og gitt til de fattige. 
40:26:10: Men da Jesus merket det, sa han til dem: Hvorfor gjør I kvinnen fortred? hun har jo gjort en god gjerning mot mig. 
40:26:11: For de fattige har I alltid hos eder, men mig har I ikke alltid. 
40:26:12: For da hun helte denne salve ut over mitt legeme, gjorde hun mig i stand til min jordeferd. 
40:26:13: Sannelig sier jeg eder: Hvor som helst dette evangelium forkynnes i all verden, skal også det hun gjorde, omtales til minne om henne. 
40:26:14: Da gikk en av de tolv, som hette Judas Iskariot, til yppersteprestene 
40:26:15: og sa: Hvad vil I gi mig, så skal jeg gi ham i eders vold? De gav ham da tretti sølvpenninger. 
40:26:16: Og fra den tid av søkte han leilighet til å forråde ham. 
40:26:17: Men på den første dag av de usyrede brøds høitid gikk disiplene til Jesus og sa til ham: Hvor vil du vi skal gjøre i stand for dig til å ete påskelammet? 
40:26:18: Han sa: Gå inn i byen til en mann der, og si til ham: Mesteren sier: Min tid er nær; hos dig vil jeg holde påske med mine disipler. 
40:26:19: Og disiplene gjorde som Jesus bød dem, og gjorde i stand påskelammet. 
40:26:20: Men da det var blitt aften, satte han sig til bords med de tolv. 
40:26:21: Og mens de åt, sa han: Sannelig sier jeg eder: En av eder skal forråde mig. 
40:26:22: Og de blev meget bedrøvet, og begynte å si til ham hver for sig: Det er da vel ikke mig, Herre? 
40:26:23: Han svarte og sa: Den som dypper hånden i fatet sammen med mig, han skal forråde mig. 
40:26:24: Menneskesønnen går bort, som skrevet er om ham; men ve det menneske ved hvem Menneskesønnen blir forrådt! Det hadde vært godt for det menneske om han aldri var født. 
40:26:25: Da svarte Judas, som forrådte ham: Det er da vel ikke mig, rabbi? Han sa til ham: Du har selv sagt det. 
40:26:26: Men mens de åt, tok Jesus et brød, velsignet og brøt det, gav disiplene og sa: Ta, et! Dette er mitt legeme. 
40:26:27: Og han tok en kalk og takket, gav dem og sa: Drikk alle derav! 
40:26:28: For dette er mitt blod, den nye pakts blod, som utgydes for mange til syndenes forlatelse. 
40:26:29: Men jeg sier eder: Fra nu av skal jeg ikke drikke av denne vintreets frukt, før den dag da jeg skal drikke den ny med eder i min Faders rike. 
40:26:30: Og da de hadde sunget lovsangen, gikk de ut til Oljeberget. 
40:26:31: Da sier Jesus til dem: I denne natt skal I alle ta anstøt av mig; for det er skrevet: Jeg vil slå hyrden, og hjordens får skal adspredes. 
40:26:32: Men når jeg er opstanden, vil jeg gå i forveien for eder til Galilea. 
40:26:33: Da svarte Peter og sa til ham: Om alle tar anstøt av dig, så vil jeg aldri ta anstøt. 
40:26:34: Jesus sa til ham: Sannelig sier jeg dig: I denne natt, før hanen galer, skal du fornekte mig tre ganger. 
40:26:35: Peter sa til ham: Om jeg så skal dø med dig, vil jeg ikke fornekte dig. Det samme sa alle disiplene. 
40:26:36: Da kom Jesus med dem til et sted som heter Getsemane, og han sa til disiplene: Sett eder her, mens jeg går der bort og beder! 
40:26:37: Og han tok Peter og de to Sebedeus' sønner med sig og begynte å bedrøves og engstes. 
40:26:38: Da sier han til dem: Min sjel er bedrøvet inntil døden; bli her og våk med mig! 
40:26:39: Og han gikk et lite stykke frem, falt på sitt ansikt og bad og sa: Min Fader! er det mulig, da la denne kalk gå mig forbi! Dog, ikke som jeg vil, men som du vil! 
40:26:40: Og han kommer til disiplene og finner dem sovende og sier til Peter: Så var I da ikke i stand til å våke en time med mig! 
40:26:41: Våk og bed, forat I ikke skal komme i fristelse! Ånden er villig, men kjødet er skrøpelig. 
40:26:42: Atter gikk han annen gang bort, bad og sa: Min Fader! kan ikke dette gå mig forbi, uten at jeg må drikke det, da skje din vilje! 
40:26:43: Og han kom og fant dem atter sovende; for deres øine var tunge. 
40:26:44: Og han lot dem være, og gikk atter bort og bad tredje gang og talte de samme ord. 
40:26:45: Da kom han til disiplene og sa til dem: I sover altså og hviler eder! Se, timen er nær da Menneskesønnen skal overgis i synderes hender; 
40:26:46: stå op, la oss gå! Se, han er nær som forråder mig. 
40:26:47: Og mens han ennu talte, se, da kom Judas, en av de tolv, og med ham fulgte en stor hop med sverd og stokker; de kom fra yppersteprestene og folkets eldste. 
40:26:48: Men han som forrådte ham, hadde gitt dem et tegn og sagt: Den jeg kysser, ham er det; grip ham! 
40:26:49: Og straks gikk han bort til Jesus og sa: Vær hilset, rabbi! og kysset ham. 
40:26:50: Men Jesus sa til ham: Venn, hvorfor er du her? Da trådte de til og la hånd på Jesus og grep ham. 
40:26:51: Og se, en av dem som var med Jesus, grep til med hånden og drog sitt sverd, og han slo til yppersteprestens tjener og hugg øret av ham. 
40:26:52: Da sa Jesus til ham: Stikk ditt sverd i skjeden! for alle som griper til sverd, skal falle for sverd. 
40:26:53: Eller tror du ikke at jeg i denne stund kan bede min Fader, og han vilde sende mig mere enn tolv legioner engler? 
40:26:54: Hvorledes skulde da skriftene opfylles, at så må skje? 
40:26:55: I samme stund sa Jesus til hopen: I er gått ut som mot en røver med sverd og stokker for å gripe mig; daglig satt jeg i templet og lærte, og I grep mig ikke. 
40:26:56: Men alt dette er skjedd forat profetenes skrifter skal opfylles. Da forlot alle disiplene ham og flydde. 
40:26:57: Men de som hadde grepet Jesus, førte ham til ypperstepresten Kaifas; der var de skriftlærde og de eldste samlet. 
40:26:58: Og Peter fulgte ham langt bakefter like til yppersteprestens gård, og han gikk inn og satte sig hos tjenerne for å se hvad enden vilde bli. 
40:26:59: Men yppersteprestene og hele rådet søkte falskt vidnesbyrd mot Jesus, forat de kunde drepe ham; 
40:26:60: men de fant ikke noget, enda mange falske vidner kom frem. Men til sist kom to frem og sa: 
40:26:61: Denne mann har sagt: Jeg kan bryte ned Guds tempel og bygge det op igjen på tre dager. 
40:26:62: Da stod ypperstepresten op og sa til ham: Svarer du ikke på det som disse vidner mot dig? 
40:26:63: Men Jesus tidde. Og ypperstepresten tok til orde og sa til ham: Jeg tar dig i ed ved den levende Gud at du sier oss om du er Messias, Guds Sønn. 
40:26:64: Jesus sa til ham: Du har sagt det. Dog, jeg sier eder: Fra nu av skal I se Menneskesønnen sitte ved kraftens høire hånd og komme i himmelens skyer. 
40:26:65: Da sønderrev ypperstepresten sine klær og sa: Han har spottet Gud; hvad skal vi mere med vidner? Se, nu har I hørt gudsbespottelsen! 
40:26:66: Hvad tykkes eder? De svarte og sa: Han er skyldig til døden. 
40:26:67: Da spyttet de ham i ansiktet og slo ham med knyttet neve; andre slo ham med stokker 
40:26:68: og sa: Spå oss, Messias: Hvem var det som slo dig? 
40:26:69: Men Peter satt utenfor i gårdsrummet. Og en tjenestepike gikk bort til ham og sa: Også du var med Jesus fra Galilea. 
40:26:70: Men han nektet for dem alle og sa: Jeg forstår ikke hvad du mener. 
40:26:71: Men da han gikk ut i portgangen, så en annen pike ham, og sa til dem som var der: Også denne var med Jesus fra Nasaret. 
40:26:72: Og atter nektet han det med en ed: Jeg kjenner ikke det menneske. 
40:26:73: Men litt efter gikk de frem som stod der, og sa til Peter: Sannelig, også du er en av dem; ditt mål røber dig. 
40:26:74: Da gav han sig til å forbanne sig og sverge: Jeg kjenner ikke det menneske. Og straks gol hanen. 
40:26:75: Da kom Peter Jesu ord i hu, at han hadde sagt til ham: Før hanen galer, skal du fornekte mig tre ganger; og han gikk ut og gråt bitterlig. 
40:27:1: Da det nu var blitt morgen, holdt alle yppersteprestene og folkets eldste råd imot Jesus, at de kunde drepe ham, 
40:27:2: og de bandt ham og førte ham bort og overgav ham til landshøvdingen Pilatus. 
40:27:3: Da nu Judas, som forrådte ham, så at han var blitt domfelt, angret han det, og han kom tilbake til yppersteprestene og de eldste med de tretti sølvpenninger og sa: 
40:27:4: Jeg har syndet da jeg forrådte uskyldig blod. Men de sa: Hvad kommer det oss ved? Se du dertil! 
40:27:5: Da kastet han sølvpengene inn i templet, og gikk bort og hengte sig. 
40:27:6: Men yppersteprestene tok sølvpengene og sa: Det er ikke tillatt å legge dem i tempelkisten; for det er blodpenger. 
40:27:7: Og de holdt råd med hverandre, og kjøpte for pengene pottemakerens aker til gravsted for fremmede. 
40:27:8: Derfor heter denne aker Blodakeren den dag idag. 
40:27:9: Da blev det opfylt som er talt ved profeten Jeremias, som sier: Og de tok de tretti sølvpenninger, den verdsattes verdi, han som Israels barn lot verdsette, 
40:27:10: og de gav dem for pottemakerens aker, således som Herren bød mig. 
40:27:11: Men Jesus blev stilt frem for landshøvdingen. Og landshøvdingen spurte ham: Er du jødenes konge? Jesus sa til ham: Du sier det. 
40:27:12: Og på alle yppersteprestenes og de eldstes klagemål svarte han intet. 
40:27:13: Da sa Pilatus til ham: Hører du ikke hvor meget de vidner imot dig? 
40:27:14: Og han svarte ham ikke på et eneste ord, så landshøvdingen undret sig storlig. 
40:27:15: Men på høitiden pleide landshøvdingen å gi folket en fange fri, hvem de vilde. 
40:27:16: Nu hadde de dengang en vel kjent fange, som hette Barabbas. 
40:27:17: Da de nu var samlet, sa Pilatus til dem: Hvem vil I jeg skal gi eder fri, Barabbas eller Jesus, som de kaller Messias? 
40:27:18: For han visste at det var av avind de hadde overgitt ham til ham. 
40:27:19: Men mens han satt på dommersetet, sendte hans hustru bud til ham og lot si: Ha ikke noget med denne rettferdige å gjøre! for jeg har lidt meget i drømme idag for hans skyld. 
40:27:20: Men yppersteprestene og de eldste overtalte folket til å be om å få Barabbas fri, og Jesus avlivet. 
40:27:21: Landshøvdingen tok nu til orde og sa til dem: Hvem av de to vil I jeg skal gi eder fri? De sa: Barabbas! 
40:27:22: Pilatus sier til dem: Hvad skal jeg da gjøre med Jesus, som de kaller Messias? De sier alle: La ham korsfeste! 
40:27:23: Han sa da: Hvad ondt har han da gjort? Men de ropte enda sterkere: La ham korsfeste! 
40:27:24: Da Pilatus så at han intet utrettet, men at det bare blev større opstyr, tok han vann og vasket sine hender for folkets øine og sa: Jeg er uskyldig i denne rettferdiges blod; se I dertil! 
40:27:25: Og alt folket svarte og sa: Hans blod komme over oss og over våre barn! 
40:27:26: Da gav han dem Barabbas fri; men Jesus lot han hudstryke og overgav ham til å korsfestes. 
40:27:27: Da tok landshøvdingens stridsmenn Jesus med sig inn i borgen og samlet hele vakten omkring ham. 
40:27:28: Og de klædde ham av og hengte en skarlagens kappe om ham, 
40:27:29: og de flettet en krone av torner og satte på hans hode, og gav ham et rør i hans høire hånd, og de falt på kne for ham og hånte ham og sa: Vær hilset, du jødenes konge! 
40:27:30: Og de spyttet på ham og tok røret og slo ham i hodet. 
40:27:31: Og da de hadde hånet ham, tok de kappen av ham og klædde ham i hans egne klær, og førte ham bort for å korsfeste ham. 
40:27:32: Men mens de var på veien, traff de en mann fra Kyrene ved navn Simon; ham tvang de til å bære hans kors. 
40:27:33: Og da de kom til et sted som kalles Golgata, det er Hodeskallestedet, 
40:27:34: gav de ham vin å drikke, blandet med galle; men da han smakte det, vilde han ikke drikke. 
40:27:35: De korsfestet ham da, og delte hans klær imellem sig ved loddkasting; 
40:27:36: og de satt der og holdt vakt over ham. 
40:27:37: Og over hans hode satte de klagemålet imot ham, således skrevet: Dette er Jesus, jødenes konge. 
40:27:38: Da blev to røvere korsfestet sammen med ham, en på den høire og en på den venstre side. 
40:27:39: Og de som gikk forbi, spottet ham og rystet på hodet og sa: 
40:27:40: Du som bryter ned templet og bygger det op igjen på tre dager, frels dig selv! Er du Guds Sønn, da stig ned av korset! 
40:27:41: Likeså spottet også yppersteprestene tillikemed de skriftlærde og de eldste ham og sa: 
40:27:42: Andre har han frelst, sig selv kan han ikke frelse! Han er jo Israels konge; la ham nu stige ned av korset, så skal vi tro på ham! 
40:27:43: Han har satt sin lit til Gud; han fri ham nu om han har behag i ham! Han har jo sagt: Jeg er Guds Sønn. 
40:27:44: På samme måte hånte også røverne ham, de som var korsfestet med ham. 
40:27:45: Men fra den sjette time blev det mørke over hele landet like til den niende time. 
40:27:46: Og ved den niende time ropte Jesus med høi røst og sa: Eli! Eli! lama sabaktani? det er: Min Gud! Min Gud! hvorfor har du forlatt mig? 
40:27:47: Men da nogen av dem som stod der, hørte det, sa de: Han roper på Elias! 
40:27:48: Og straks løp en av dem frem og tok en svamp og fylte den med eddik og stakk den på et rør og gav ham å drikke. 
40:27:49: Men de andre sa: Vent, la oss se om Elias kommer for å frelse ham! 
40:27:50: Men Jesus ropte atter med høi røst og opgav ånden. 
40:27:51: Og se, forhenget i templet revnet i to stykker fra øverst til nederst, og jorden skalv, og klippene revnet, 
40:27:52: og gravene åpnedes, og mange av de hensovede helliges legemer stod op, 
40:27:53: og de gikk ut av gravene efter hans opstandelse, og kom inn i den hellige stad og viste sig for mange. 
40:27:54: Men da høvedsmannen og de som holdt vakt med ham over Jesus, så jordskjelvet og det som skjedde, blev de såre forferdet og sa: Sannelig, denne var Guds Sønn! 
40:27:55: Men mange kvinner som hadde fulgt Jesus fra Galilea og tjent ham, stod der og så på i frastand; 
40:27:56: blandt dem var Maria Magdalena, og Maria, Jakobs og Joses' mor, og Sebedeus-sønnenes mor. 
40:27:57: Men da det var blitt aften, kom en rik mann fra Arimatea ved navn Josef, som også var blitt en Jesu disippel; 
40:27:58: han gikk til Pilatus og bad om Jesu legeme. Da bød Pilatus at det skulde gis ham. 
40:27:59: Og Josef tok legemet og svøpte det i et rent, fint linklæde 
40:27:60: og la det i sin nye grav, som han hadde latt hugge i klippen, og han veltet en stor sten for døren til graven, og gikk bort. 
40:27:61: Men Maria Magdalena og den andre Maria var der og satt like imot graven. 
40:27:62: Men den næste dag, som var dagen efter beredelses-dagen, kom yppersteprestene og fariseerne sammen hos Pilatus 
40:27:63: og sa: Herre! vi kommer i hu at mens denne forfører ennu var i live, sa han: Tre dager efter står jeg op. 
40:27:64: Byd derfor at de vokter graven vel til den tredje dag, forat ikke hans disipler skal komme og stjele ham og så si til folket: Han er opstanden fra de døde, og den siste forførelse bli verre enn den første. 
40:27:65: Pilatus sa til dem: Der har I vakt; gå bort og vokt graven som best I kan! 
40:27:66: De gikk bort og voktet graven sammen med vakten, efterat de hadde satt segl på stenen. 
40:28:1: Men ved enden av sabbaten, da det lysnet mot den første dag i uken, kom Maria Magdalena og den andre Maria for å se til graven. 
40:28:2: Og se, det kom et stort jordskjelv; for en Herrens engel fór ned fra himmelen og trådte til og veltet stenen fra og satte sig på den. 
40:28:3: Og han var som et lyn å se til, og hans klædning var hvit som sne; 
40:28:4: og av frykt for ham skalv de som holdt vakt, og de blev som døde. 
40:28:5: Men engelen tok til orde og sa til kvinnene: Frykt ikke! jeg vet at I søker efter Jesus, den korsfestede; 
40:28:6: han er ikke her; han er opstanden, som han sa; kom og se stedet hvor han lå! 
40:28:7: Og gå avsted i hast og si til hans disipler at han er opstanden fra de døde. Og se, han går i forveien for eder til Galilea; der skal I se ham. Se, jeg har sagt eder det! 
40:28:8: Og de gikk i hast bort fra graven med frykt og stor glede, og løp avsted for å fortelle hans disipler det. 
40:28:9: Og se, Jesus møtte dem og sa: Fred være med eder! Men de gikk frem og omfavnet hans føtter og tilbad ham. 
40:28:10: Da sa Jesus til dem: Frykt ikke! Gå og si til mine brødre at de skal dra til Galilea; der skal de få se mig. 
40:28:11: Men mens de var på veien, se, da kom nogen av vakten inn i byen og fortalte yppersteprestene alt det som var skjedd. 
40:28:12: Da kom disse sammen med de eldste og rådslo, og de gav stridsmennene mange penger 
40:28:13: og sa: I skal si: Hans disipler kom om natten og stjal ham bort mens vi sov. 
40:28:14: Og om det skulde komme landshøvdingen for øre, da skal vi tale vel med ham, og lage det så at I kan være trygge. 
40:28:15: De tok da pengene, og gjorde som de blev lært; og dette rykte kom ut blandt jødene, og har holdt sig til denne dag. 
40:28:16: Men de elleve disipler drog til Galilea til det fjell hvor Jesus hadde satt dem stevne. 
40:28:17: Og da de så ham, tilbad de ham; men nogen tvilte. 
40:28:18: Og Jesus trådte frem, talte til dem og sa: Mig er gitt all makt i himmel og på jord; 
40:28:19: gå derfor ut og gjør alle folkeslag til disipler, idet I døper dem til Faderens og Sønnens og den Hellige Ånds navn, 
40:28:20: og lærer dem å holde alt det jeg har befalt eder. Og se, jeg er med eder alle dager inntil verdens ende! 
41:1:1: Begynnelsen til Jesu Kristi, Guds Sønns evangelium. 
41:1:2: Som skrevet står hos profeten Esaias: Se, jeg sender mitt bud for ditt åsyn; han skal rydde din vei; 
41:1:3: det er en røst av en som roper i ørkenen: Rydd Herrens vei, gjør hans stier jevne! 
41:1:4: - således stod døperen Johannes frem i ørkenen og forkynte omvendelses dåp til syndenes forlatelse, 
41:1:5: og hele Judea og alle de fra Jerusalem gikk ut til ham, og de blev døpt av ham i elven Jordan, idet de bekjente sine synder. 
41:1:6: Og Johannes hadde klædning av kamelhår, og lærbelte om sin lend, og hans mat var gresshopper og vill honning. 
41:1:7: Og han forkynte og sa: Efter mig kommer den som er sterkere enn jeg, han hvis skorem jeg ikke er verdig til å bukke mig ned og løse. 
41:1:8: Jeg har døpt eder med vann, men han skal døpe eder med den Hellige Ånd. 
41:1:9: Og det skjedde i de dager at Jesus kom fra Nasaret i Galilea og blev døpt av Johannes i Jordan; 
41:1:10: og straks da han steg op av vannet, så han himmelen åpne sig og Ånden komme ned over ham som en due. 
41:1:11: Og det kom en røst fra himmelen: Du er min Sønn, den elskede; i dig har jeg velbehag. 
41:1:12: Og straks drev Ånden ham ut i ørkenen, 
41:1:13: og han var i ørkenen og blev fristet av Satan i firti dager, og han var hos de ville dyr; og englene tjente ham. 
41:1:14: Men efterat Johannes var kastet i fengsel, kom Jesus til Galilea og forkynte Guds evangelium, 
41:1:15: og sa: Tiden er fullkommet, og Guds rike er kommet nær; omvend eder, og tro på evangeliet! 
41:1:16: Og da han gikk forbi ved den Galileiske Sjø, så han Simon og Andreas, Simons bror, i ferd med å kaste garn i sjøen; for de var fiskere; 
41:1:17: og Jesus sa til dem: Følg mig, så vil jeg gjøre eder til menneskefiskere! 
41:1:18: Og de forlot straks sine garn og fulgte ham. 
41:1:19: Og da han var gått litt lenger frem, så han Jakob, Sebedeus' sønn, og hans bror Johannes i ferd med å bøte sine garn i båten, 
41:1:20: og straks kalte han dem, og de forlot sin far Sebedeus i båten med leiefolkene og fulgte ham. 
41:1:21: Og de gikk inn i Kapernaum, og straks på sabbaten gikk han inn i synagogen og lærte. 
41:1:22: Og de var slått av forundring over hans lære; for han lærte dem som en som hadde myndighet, og ikke som de skriftlærde. 
41:1:23: Og det var i deres synagoge en mann med en uren ånd, og han ropte: 
41:1:24: Hvad har vi med dig å gjøre, Jesus fra Nasaret? Du er kommet for å ødelegge oss; jeg vet hvem du er, du Guds hellige! 
41:1:25: Og Jesus truet den og sa: Ti og far ut av ham! 
41:1:26: Og den urene ånd slet i ham og skrek med høi røst og fór ut av ham. 
41:1:27: Og de blev alle forferdet, så de spurte hverandre: Hvad er dette? En ny lære! Med myndighet byder han endog de urene ånder, og de er ham lydige! 
41:1:28: Og ryktet om ham kom straks ut allesteds i hele landet deromkring i Galilea. 
41:1:29: Og de gikk straks ut av synagogen og gikk inn i Simons og Andreas hus sammen med Jakob og Johannes. 
41:1:30: Men Simons svigermor lå til sengs og hadde feber, og straks talte de til ham om henne. 
41:1:31: Og han trådte til og tok henne ved hånden og reiste henne op; og feberen forlot henne, og hun tjente dem. 
41:1:32: Men da det var blitt aften og solen gikk ned, førte de til ham alle dem som hadde ondt, og de besatte; 
41:1:33: og hele byen var samlet for døren. 
41:1:34: Og han helbredet mange som hadde ondt av forskjellige sykdommer, og drev ut mange onde ånder, og han tillot ikke de onde ånder å tale, fordi de kjente ham. 
41:1:35: Og tidlig om morgenen, mens det ennu var aldeles mørkt, stod han op og gikk ut og drog bort til et øde sted og bad der. 
41:1:36: Og Simon og de som var med ham, skyndte sig efter ham. 
41:1:37: Og de fant ham og sa til ham: Alle leter efter dig. 
41:1:38: Og han sa til dem: La oss gå annensteds, til småbyene heromkring, forat jeg kan forkynne ordet også der! for derfor er jeg gått ut. 
41:1:39: Og han kom og forkynte ordet i deres synagoger over hele Galilea, og drev ut de onde ånder. 
41:1:40: Og en spedalsk kom til ham og bad ham og falt på kne for ham og sa: Om du vil, så kan du rense mig. 
41:1:41: Og han ynkedes inderlig over ham, Og rakte sin hånd ut og rørte ved ham og sa: Jeg vil; bli ren! 
41:1:42: Og straks forlot spedalskheten ham, og han blev renset. 
41:1:43: Og han talte strengt til ham og drev ham straks ut, 
41:1:44: og sa til ham: Se til at du ikke sier det til nogen; men gå og te dig for presten, og bær frem det offer for din renselse som Moses har påbudt, til et vidnesbyrd for dem! 
41:1:45: Men han gikk ut og begynte å tale vidt og bredt om det og å utbrede ryktet, så at Jesus ikke mere kunde gå åpenlyst inn i nogen by; men han var utenfor, på øde steder, og de kom til ham allestedsfra. 
41:2:1: Og nogen dager derefter gikk han atter inn i Kapernaum, og det spurtes at han var hjemme. 
41:2:2: Og mange samlet sig, så de ikke lenger fikk rum, ikke engang ved døren, og han talte ordet til dem. 
41:2:3: Og de kom til ham med en verkbrudden, som blev båret av fire mann; 
41:2:4: og da de ikke kunde komme frem til ham for folketrengselen, tok de taket av der hvor han var, og brøt hull og firte ned sengen som den verkbrudne lå på. 
41:2:5: Og da Jesus så deres tro, sa han til den verkbrudne: Sønn! dine synder er dig forlatt. 
41:2:6: Men nogen skriftlærde satt der og tenkte i sine hjerter: 
41:2:7: Hvorfor taler denne mann så? Han spotter Gud; hvem kan forlate synder uten en, det er Gud? 
41:2:8: Og Jesus kjente straks i sin ånd at de tenkte så ved sig selv, og han sa til dem: Hvorfor tenker I slikt i eders hjerter? 
41:2:9: Hvad er lettest, enten å si til den verkbrudne: Dine synder er dig forlatt, eller å si: Stå op og ta din seng og gå? 
41:2:10: Men forat I skal vite at Menneskesønnen har makt på jorden til å forlate synder - så sier han til den verkbrudne: 
41:2:11: Jeg sier dig: Stå op og ta din seng og gå hjem til ditt hus! 
41:2:12: Og han stod op og tok straks sin seng og gikk ut for alles øine, så alle blev ute av sig selv av forundring og priste Gud og sa: Slikt har vi aldri sett. 
41:2:13: Og han gikk atter ut til sjøen, og alt folket kom til ham, og han lærte dem. 
41:2:14: Og da han gikk videre, så han Levi, Alfeus' sønn, sitte på tollboden, og han sa til ham: Følg mig! Og han stod op og fulgte ham. 
41:2:15: Og det skjedde at han satt til bords i hans hus; og mange toldere og syndere satt til bords med Jesus og hans disipler; for det var mange av dem, og de fulgte ham. 
41:2:16: Og da de skriftlærde og fariseerne så at han åt sammen med toldere og syndere, sa de til hans disipler: Han eter og drikker med toldere og syndere! 
41:2:17: Og da Jesus hørte det, sa han til dem: De friske trenger ikke til læge, men de som har ondt; jeg er ikke kommet for å kalle rettferdige, men for å kalle syndere. 
41:2:18: Og Johannes' og fariseernes disipler holdt faste, og de kom og sa til ham: Hvorfor faster Johannes' disipler og fariseernes disipler, men dine disipler faster ikke? 
41:2:19: Og Jesus sa til dem: Kan vel brudesvennene faste så lenge brudgommen er hos dem? Så lenge de har brudgommen hos sig, kan de ikke faste. 
41:2:20: Men de dager skal komme da brudgommen blir tatt fra dem, og da skal de faste, på den dag. 
41:2:21: Ingen syr en lapp av ukrympet tøi på et gammelt klædebon, ellers river den nye lapp et stykke med sig av det gamle, og riften blir verre. 
41:2:22: Og ingen fyller ny vin i gamle skinnsekker; ellers vil vinen sprenge sekkene og vinen spilles og sekkene ødelegges; men ny vin i nye skinnsekker! 
41:2:23: Og det skjedde at han gikk igjennem en aker på sabbaten, og hans disipler begynte å plukke aks mens de gikk der. 
41:2:24: Og fariseerne sa til ham: Se, hvorfor gjør de på sabbaten det som ikke er tillatt? 
41:2:25: Og han sa til dem: Har I aldri lest hvad David gjorde da han var i nød og sultet, han selv og de som var med ham, 
41:2:26: hvorledes han gikk inn i Guds hus, da Abjatar var yppersteprest, og åt skuebrødene, som ingen har lov til å ete uten prestene, og gav også dem som var med ham? 
41:2:27: Og han sa til dem: Sabbaten blev til for menneskets skyld, og ikke mennesket for sabbatens skyld. 
41:2:28: Så er da Menneskesønnen herre også over sabbaten. 
41:3:1: Og han gikk atter inn i en synagoge, og der var en mann som hadde en vissen hånd. 
41:3:2: Og de lurte på ham, om han vilde helbrede ham på sabbaten, forat de kunde føre klagemål imot ham. 
41:3:3: Og han sa til mannen som hadde den visne hånd: Stå op og kom frem! 
41:3:4: Og han sa til dem: Er det tillatt på sabbaten å gjøre godt eller å gjøre ondt, å berge liv eller å slå ihjel? Men de tidde. 
41:3:5: Og han så omkring på dem med harme, full av sorg over deres hjertes forherdelse, og sa til mannen: Rekk din hånd ut! Og han rakte den ut, og hans hånd blev frisk igjen. 
41:3:6: Og fariseerne gikk ut og holdt straks råd imot ham sammen med herodianerne, hvorledes de skulde få ryddet ham av veien. 
41:3:7: Og Jesus drog bort med sine disipler til sjøen, og en stor mengde fra Galilea og fra Judea fulgte ham, 
41:3:8: og fra Jerusalem og fra Idumea og fra landet på hin side Jordan og omkring Tyrus og Sidon kom de til ham, en stor mengde, da de hørte hvor store gjerninger han gjorde. 
41:3:9: Og han bød sine disipler at en båt skulde ligge ferdig til ham for folkets skyld, forat de ikke skulde trenge ham; 
41:3:10: for han helbredet mange, så at alle de som hadde plager, trengte sig inn på ham for å få røre ved ham. 
41:3:11: Og når de urene ånder så ham, falt de ned for ham og ropte: Du er Guds Sønn! 
41:3:12: Og han bød dem med mange strenge ord at de ikke skulde gjøre ham kjent. 
41:3:13: Og han gikk op i fjellet og kalte til sig dem han selv vilde, og de gikk til ham. 
41:3:14: Og han utvalgte tolv, som skulde være med ham, og som han kunde sende ut for å forkynne ordet 
41:3:15: og ha makt til å drive ut de onde ånder, 
41:3:16: og han gav Simon navnet Peter, 
41:3:17: og Jakob, Sebedeus' sønn, og Johannes, Jakobs bror, og han gav dem navnet Boanerges, det er tordensønner, 
41:3:18: og Andreas og Filip og Bartolomeus og Matteus og Tomas og Jakob, Alfeus' sønn, og Taddeus og Simon Kananeus 
41:3:19: og Judas Iskariot, han som forrådte ham. 
41:3:20: Og de kom hjem, og folket kom atter sammen, så de ikke engang kunde få sig mat. 
41:3:21: Og da hans nærmeste fikk høre om det, gikk de hjemmefra for å få fatt på ham; for de sa: Han er fra sig selv. 
41:3:22: Og de skriftlærde, som var kommet ned fra Jerusalem, sa: Han er besatt av Be'elsebul, og: Det er ved de onde ånders fyrste han driver de onde ånder ut. 
41:3:23: Og han kalte dem til sig og sa til dem i lignelser: Hvorledes kan Satan drive Satan ut? 
41:3:24: Om et rike kommer i strid med sig selv, kan dette rike ikke bli stående, 
41:3:25: og om et hus kommer i strid med sig selv, kan dette hus ikke bli stående. 
41:3:26: Og dersom Satan setter sig op mot sig selv og kommer i strid med sig selv, kan han ikke bli stående, men det er ute med ham. 
41:3:27: Men ingen kan gå inn i den sterkes hus og røve hans gods uten at han først har bundet den sterke; da kan han plyndre hans hus. 
41:3:28: Sannelig sier jeg eder: Alle synder skal bli menneskenes barn forlatt, og alle bespottelser som de taler; 
41:3:29: men den som taler bespottelig mot den Hellige Ånd, han får i all evighet ikke forlatelse, men er skyldig i en evig synd - 
41:3:30: det var fordi de sa: Han er besatt av en uren ånd. 
41:3:31: Så kom hans mor og hans brødre, og de stod utenfor og sendte bud til ham og bad ham komme ut. 
41:3:32: Og folket satt omkring ham, og de sa til ham: Se, din mor og dine brødre er utenfor og spør efter dig. 
41:3:33: Og han svarte dem og sa: Hvem er min mor og mine brødre? 
41:3:34: Og han så på dem som satt omkring ham, og sa: Se, det er min mor og mine brødre! 
41:3:35: Den som gjør Guds vilje, han er min bror og søster og mor. 
41:4:1: Og han begynte atter å lære ved sjøen, og meget folk samlet sig om ham, så han gikk ut i en båt og satt i den ute på sjøen, og alt folket var på land ved sjøen. 
41:4:2: Og han lærte dem meget i lignelser, og sa til dem idet han lærte: 
41:4:3: Hør! Se, en såmann gikk ut for å så, 
41:4:4: og det skjedde da han sådde, da falt noget ved veien; og fuglene kom og åt det op. 
41:4:5: Og noget falt på stengrunn, hvor det ikke hadde meget jord; og det kom snart op, fordi det ikke hadde dyp jord, 
41:4:6: og da solen gikk op, blev det avsvidd, og da det ikke hadde rot, visnet det. 
41:4:7: Og noget falt blandt torner; og tornene skjøt op og kvalte det, og det bar ikke frukt. 
41:4:8: Og noget falt i god jord; og det bar frukt som vokste og blev stor, og det bar inntil tretti fold og seksti fold og hundre fold. 
41:4:9: Og han sa til dem: Den som har ører å høre med, han høre! 
41:4:10: Og da han var blitt alene, spurte de tolv og de andre som var med ham, om lignelsene. 
41:4:11: Og han sa til dem: Eder er Guds rikes hemmelighet gitt, men til hine som er utenfor, sies det alt sammen i lignelser, 
41:4:12: forat de skal se og se og ikke skjelne, og høre og høre og ikke forstå, forat de ikke skal omvende sig og få forlatelse. 
41:4:13: Og han sa til dem: Forstår I ikke denne lignelse? Hvorledes skal I da skjønne alle lignelsene? 
41:4:14: Såmannen sår ordet. 
41:4:15: Men de ved veien er de som ordet blir sådd i, og når de har hørt det, kommer straks Satan og tar bort ordet som er sådd i dem. 
41:4:16: Og på samme måte de som såes på stengrunn; det er de som straks tar imot ordet med glede når de får høre det, 
41:4:17: og de har ikke rot i sig, men holder bare ut til en tid; blir det så trengsel eller forfølgelse for ordets skyld, da tar de straks anstøt. 
41:4:18: Og andre igjen er de som såes blandt torner; det er de som hører ordet, 
41:4:19: og verdens bekymringer og rikdommens forførelse og attrå efter de andre ting kommer inn og kveler ordet, og det blir uten frukt. 
41:4:20: Og dette er de som er sådd i den gode jord: de som hører ordet og tar imot det og bærer frukt, tretti fold og seksti fold og hundre fold. 
41:4:21: Og han sa til dem: Kommer vel schmelken inn for å settes under en skjeppe eller under en seng? Kommer det ikke for å settes i staken? 
41:4:22: For intet er skjult uten at det skal åpenbares, heller ikke blir noget dulgt uten for å komme for dagen. 
41:4:23: Om nogen har ører å høre med, han høre! 
41:4:24: Og han sa til dem: Akt på hvad I hører! Med det samme mål som I måler med, skal eder måles igjen, og enn mere skal gis eder. 
41:4:25: For den som har, ham skal gis, og den som ikke har, fra ham skal endog tas det han har. 
41:4:26: Og han sa: Med Guds rike er det således som når et menneske kaster sæden i jorden 
41:4:27: og sover og står op, natt og dag, og sæden spirer frem og blir høi, uten at han selv vet av det. 
41:4:28: Av sig selv bærer jorden grøde: først strå, så aks, så fullt korn i akset. 
41:4:29: Men når grøden er moden, sender han straks sigden ut, fordi høsten er forhånden. 
41:4:30: Og han sa: Hvad skal vi ligne Guds rike med, eller hvad lignelse skal vi bruke om det? 
41:4:31: Det er likesom sennepskornet, som er mindre enn alt annet frø på jorden når det såes i jorden; 
41:4:32: og når det er sådd, vokser det op og blir større enn alle maturter og skyter store grener, så at himmelens fugler kan bygge rede under dets skygge. 
41:4:33: Og i mange sådanne lignelser talte han ordet til dem, så meget som de kunde høre, 
41:4:34: og uten lignelse talte han ikke til dem; men i enrum utla han alt sammen for sine disipler. 
41:4:35: Og samme dag, da det var blitt aften, sa han til dem: La oss fare over til hin side! 
41:4:36: Og de lot folket fare, og tok ham med sig i båten som han var; men også andre båter var i følge med ham. 
41:4:37: Og det kom en sterk stormvind, og bølgene slo inn i båten, så at den holdt på å fylles. 
41:4:38: Og han lå og sov bak i båten på en hodepute; og de vekket ham og sa til ham: Mester! bryr du dig ikke om at vi går under? 
41:4:39: Og han stod op og truet vinden, og til sjøen sa han: Ti, vær stille! Og vinden la sig, og det blev blikkstille. 
41:4:40: Og han sa til dem: Hvorfor er I så redde? Hvorledes kan I være så vantro? 
41:4:41: Og de blev storlig forferdet, og sa til hverandre: Hvad er dette for en, som både vinden og sjøen er lydige? 
41:5:1: Og de kom over på hin side av sjøen, til gerasenernes bygd. 
41:5:2: Og da han var gått ut av båten, kom det straks mot ham ut av gravene en mann som var besatt av en uren ånd. 
41:5:3: Han hadde sitt tilhold der i gravene, og de kunde ikke lenger binde ham, ikke engang med lenker; 
41:5:4: for han hadde ofte vært bundet med fot-jern og lenker, og lenkene hadde han revet av sig, og fot-jernene hadde han sønderslitt, og ingen kunde rå med ham, 
41:5:5: og han var alltid, natt og dag, i gravene og på fjellene og skrek og slo sig selv med stener. 
41:5:6: Og da han så Jesus langt borte, løp han til og falt ned for ham, 
41:5:7: og ropte med høi røst: Hvad har jeg med dig å gjøre, Jesus, du den høieste Guds Sønn? Jeg besverger dig ved Gud at du ikke må pine mig! 
41:5:8: For han sa til ham: Far ut av mannen, du urene ånd! 
41:5:9: Og han spurte ham: Hvad er ditt navn? Og han sa til ham: Legion er mitt navn; for vi er mange. 
41:5:10: Og han bad ham meget at han ikke måtte drive dem ut av bygden. 
41:5:11: Men det gikk en stor svinehjord og beitet der ved fjellet, 
41:5:12: Og de bad ham: Send oss inn i svinene, så vi kan fare i dem! 
41:5:13: Og han gav dem lov til det. Og de urene ånder fór ut og fór i svinene; og hjorden styrtet sig ut over stupet ned i sjøen, omkring to tusen i tallet, og druknet i sjøen. 
41:5:14: Og de som gjætte dem, tok flukten, og fortalte det i byen og i bygden. Og folk kom ut for å se hvad som hadde hendt. 
41:5:15: Og de kom til Jesus og så den besatte sitte påklædd og ved sans og samling, han som hadde vært besatt av legionen, og de blev forferdet. 
41:5:16: Og de som hadde sett det, fortalte dem hvorledes det var gått med den besatte, og om svinene. 
41:5:17: Og de begynte å be ham at han vilde dra bort fra deres landemerker. 
41:5:18: Og da han gikk i båten, bad den besatte om å få være med ham. 
41:5:19: Og han gav ham ikke lov, men sa til ham: Gå hjem til dine og fortell dem hvor store ting Herren har gjort imot dig, og at han har miskunnet sig over dig! 
41:5:20: Og han gikk bort og begynte å kunngjøre i Dekapolis hvor store ting Jesus hadde gjort imot ham; og alle undret sig. 
41:5:21: Og da Jesus var faret over med båten til hin side igjen, samlet meget folk sig om ham; og han var ved sjøen. 
41:5:22: Og det kom en av synagoge-forstanderne ved navn Jairus; og da han så ham, falt han ned for hans føtter, 
41:5:23: og han bad ham meget og sa: Min datter ligger på det siste; kom og legg dine hender på henne, så hun må bli frelst og leve! 
41:5:24: Og han gikk bort med ham, og meget folk fulgte ham, og de trengte ham. 
41:5:25: Og der var en kvinne som hadde hatt blodsott i tolv år; 
41:5:26: og hun hadde lidt meget av mange læger og satt til alt det hun eide, og hadde ikke hatt nogen hjelp av det, men var heller blitt verre; 
41:5:27: da hun hadde hørt ryktet om Jesus, kom hun midt iblandt folket og rørte bakfra ved hans klædebon. 
41:5:28: For hun sa: Kan jeg få røre, om det så bare er ved hans klær, så blir jeg helbredet. 
41:5:29: Og straks uttørkedes hennes blods kilde, og hun kjente i sitt legeme at hun var helbredet for sin plage. 
41:5:30: Og Jesus kjente straks hos sig selv den kraft som gikk ut fra ham, og han vendte sig om i hopen og sa: Hvem var det som rørte ved mine klær? 
41:5:31: Og hans disipler sa til ham: Du ser at folket trenger dig på alle kanter, og du sier: Hvem var det som rørte ved mig? 
41:5:32: Og han så sig om for å få øie på henne som hadde gjort dette. 
41:5:33: Men kvinnen kom redd og skjelvende, for hun visste hvad som var skjedd med henne, og hun falt ned for ham og sa ham hele sannheten. 
41:5:34: Da sa han til henne: Datter! din tro har frelst dig; gå bort i fred, og vær helbredet for din plage! 
41:5:35: Mens han ennu talte, kom det folk fra synagoge-forstanderen og sa: Din datter er død; hvorfor umaker du mesteren lenger? 
41:5:36: Men Jesus hørte det ord som blev sagt, og sa til synagoge-forstanderen: Frykt ikke, bare tro! 
41:5:37: Og han lot ingen følge med sig uten Peter og Jakob og Johannes, Jakobs bror. 
41:5:38: Og de kom til synagoge-forstanderens hus, og han så en larmende hop og folk i stor gråt og jammer, 
41:5:39: og han gikk inn og sa til dem: Hvorfor larmer og gråter I? Barnet er ikke død; hun sover. 
41:5:40: Og de lo ham ut. Men han driver alle ut, og tar med sig barnets far og mor og dem som var med ham, og går inn der hvor barnet var. 
41:5:41: Og han tar barnet ved hånden og sier til henne: Talita kumi; det er utlagt: Pike! jeg sier dig: Stå op! 
41:5:42: Og straks stod piken op og gikk omkring; for hun var tolv år gammel. Og de blev storlig forferdet. 
41:5:43: Og han bød dem strengt at ikke nogen skulde få dette å vite; og han sa at de skulde gi henne noget å ete. 
41:6:1: Og han gikk ut derfra og kom til sitt hjemsted, og hans disipler fulgte ham. 
41:6:2: Og da sabbaten kom, begynte han å lære i synagogen. Og mange som hørte ham, var slått av forundring og sa: Hvorfra har han dette, og hvad er det for en visdom som er ham gitt? Og slike kraftige gjerninger som skjer ved hans hender! 
41:6:3: Er ikke dette tømmermannen, Marias sønn og bror til Jakob og Joses og Judas og Simon, og er ikke hans søstre her hos oss? Og de tok anstøt av ham. 
41:6:4: Og Jesus sa til dem: En profet blir ikke foraktet annensteds enn på sitt hjemsted og blandt sine slektninger og i sitt hus. 
41:6:5: Og han kunde ikke gjøre nogen kraftig gjerning der, undtagen at han la sine hender på nogen få syke og helbredet dem; 
41:6:6: og han undret sig over deres vantro. Og han gikk omkring i byene og lærte. 
41:6:7: Og han kalte de tolv til sig og begynte å sende dem ut, to og to, og gav dem makt over de urene ånder. 
41:6:8: Og han bød dem at de ikke skulde ta noget med på veien, uten bare en stav, ikke brød, ikke skreppe, ikke kobber i beltet, 
41:6:9: men ha sko på, og ikke to kjortler. 
41:6:10: Og han sa til dem: Hvor I kommer inn i et hus, der skal I bli til I drar videre fra det sted. 
41:6:11: Og hvor de ikke tar imot eder og ikke hører på eder, der skal I gå ut fra det sted og ryste av støvet under eders føtter til et vidnesbyrd mot dem. 
41:6:12: Og de gikk ut og forkynte for folket at de skulde omvende sig, 
41:6:13: og de drev ut mange onde ånder og salvet mange syke med olje og helbredet dem. 
41:6:14: Og kong Herodes fikk høre om dette - for Jesu navn blev kjent vidt og bredt - og han sa: Døperen Johannes er opstanden fra de døde, og derfor er det disse krefter er virksomme i ham. 
41:6:15: Andre sa: Det er Elias; andre igjen sa: Det er en profet, som en av profetene. 
41:6:16: Men da Herodes hørte det, sa han: Johannes, som jeg lot halshugge, han er opstanden fra de døde. 
41:6:17: For Herodes hadde selv sendt folk avsted og grepet Johannes og lagt ham i bånd og kastet ham i fengsel for Herodias' skyld, som var hans bror Filips hustru; for han hadde giftet sig med henne. 
41:6:18: Men Johannes hadde sagt til Herodes: Det er dig ikke tillatt å ha din brors hustru. 
41:6:19: Og Herodias bar hat til ham og vilde gjerne slå ham ihjel, men kunde ikke utvirke det. 
41:6:20: For Herodes fryktet Johannes, fordi han kjente ham som en rettferdig og hellig mann, og han holdt sin hånd over ham, og når han hørte ham, var han i tvil om mangt og meget, og han hørte ham gjerne. 
41:6:21: Så kom det en beleilig dag, da Herodes gjorde et gjestebud på sin fødselsdag for sine stormenn og krigshøvdingene og de fornemste i Galilea, 
41:6:22: og Herodias' datter kom inn og danset, og Herodes og de som satt til bords med ham, syntes om henne. Og kongen sa til piken: Be mig om hvad du vil, og jeg vil gi dig det! 
41:6:23: Og han svor henne til: Hvad du ber mig om, det vil jeg gi dig, om det så var halvdelen av mitt rike. 
41:6:24: Hun gikk da ut og sa til sin mor: Hvad skal jeg be om? Hun sa: Om døperen Johannes' hode. 
41:6:25: Og straks skyndte hun sig inn til kongen og bad ham og sa: Jeg vil at du straks skal gi mig døperen Johannes' hode på et fat. 
41:6:26: Og kongen blev meget bedrøvet; men for sine eders skyld og for deres skyld som satt til bords, vilde han ikke si nei til henne. 
41:6:27: Og straks sendte kongen en av sin livvakt avsted og bød ham hente hans hode. 
41:6:28: Han gikk da avsted og halshugget ham i fengslet, og kom med hans hode på et fat og gav det til piken, og piken gav det til sin mor. 
41:6:29: Og da hans disipler hørte det, kom de og tok hans legeme og la det i en grav. 
41:6:30: Og apostlene samlet sig igjen hos Jesus, og fortalte ham alt det de hadde gjort og lært. 
41:6:31: Og han sa til dem: Kom nu I med mig avsides til et øde sted og hvil eder litt ut! For det var mange som gikk til og fra, og det blev ikke engang tid for dem til å få sig mat. 
41:6:32: Så drog de avsted i båten til et øde sted for sig selv. 
41:6:33: Og folk så dem dra bort, og mange kjente dem; og fra alle byene løp de til fots dit og kom før dem. 
41:6:34: Og da han gikk i land, så han meget folk, og han ynkedes inderlig over dem; for de var lik får som ikke har hyrde; og han begynte å lære dem meget. 
41:6:35: Og da det alt var sent på dagen, gikk hans disipler til ham og sa: Stedet er øde, og det er alt sent på dagen; 
41:6:36: la dem fare, så de kan gå bort i bygdene og byene heromkring og kjøpe sig noget å ete! 
41:6:37: Men han svarte og sa til dem: Gi I dem å ete! Og de sa til ham: Skal vi gå bort og kjøpe brød for to hundre penninger og gi dem å ete? 
41:6:38: Men han sa til dem: Hvor mange brød har I? Gå bort og se efter! Og da de hadde sett efter, sa de: Fem, og to fisker. 
41:6:39: Og han bød dem å la alle sette sig ned i det grønne gress, lag ved lag. 
41:6:40: Og de satte sig ned, hop ved hop, somme på hundre og somme på femti. 
41:6:41: Og han tok de fem brød og de to fisker, så op mot himmelen og velsignet dem; og han brøt brødene og gav dem til disiplene, forat de skulde dele ut til folket, og de to fisker delte han imellem dem alle. 
41:6:42: Og de åt alle og blev mette; 
41:6:43: og de tok op tolv kurver fulle av stykker, og likeså av fiskene. 
41:6:44: Og de som hadde ett brødene, var fem tusen menn. 
41:6:45: Og straks nødde han sine disipler til å gå i båten og fare i forveien over til hin side, til Betsaida, mens han selv sendte folket avsted. 
41:6:46: Og da han skiltes fra dem, gikk han op i fjellet for å bede. 
41:6:47: Og da det var blitt aften, var båten midt på sjøen, og han var alene på land. 
41:6:48: Og da han så at de var i nød mens de rodde - for vinden var imot - kom han til dem ved den fjerde nattevakt, vandrende på sjøen, og han vilde gå forbi dem. 
41:6:49: Men da de så ham vandre på sjøen, trodde de at det var et spøkelse, og de skrek; 
41:6:50: for de så ham alle sammen og blev forferdet. Men han talte straks til dem og sa: Vær frimodige; det er mig, frykt ikke! 
41:6:51: Og han steg inn i båten til dem, og vinden la sig; og de blev ute av sig selv av forundring. 
41:6:52: For de hadde ikke fått forstand av det som var skjedd med brødene; men deres hjerte var forherdet. 
41:6:53: Og da de hadde faret over, kom de til Gennesarets land og la til der. 
41:6:54: Og da de gikk ut av båten, kjente folket ham straks igjen, 
41:6:55: og de løp omkring i alt landet der, og de begynte å føre de syke omkring i sine senger dit hvor de hørte at han var. 
41:6:56: Og hvor han gikk inn i landsbyer eller byer eller gårder, der la de sine syke på torvene og bad ham at de måtte få røre, om det så bare var ved det ytterste av hans klædebon; og alle de som rørte ved ham, blev helbredet. 
41:7:1: Og fariseerne og nogen av de skriftlærde, som var kommet fra Jerusalem, samlet sig om ham. 
41:7:2: Og de fikk se at nogen av hans disipler åt med vanhellige, det er uvaskede, hender; 
41:7:3: fariseerne og alle jøder eter ikke uten at de først omhyggelig har vasket hendene, for de holder fast ved de gamles vedtekt, 
41:7:4: og når de kommer fra torvet, eter de ikke før de har vasket sig, og det er meget annet som de har vedtatt å holde: vaskninger av beger og krus og kobberkar og benker. 
41:7:5: Og fariseerne og de skriftlærde spurte ham: Hvorfor følger ikke dine disipler de gamles vedtekt, men eter med vanhellige hender? 
41:7:6: Men han sa til dem: Rett spådde Esaias om eder, I hyklere, således som skrevet er: Dette folk ærer mig med lebene, men deres hjerte er langt borte fra mig; 
41:7:7: men de dyrker mig forgjeves, idet de lærer lærdommer som er menneskebud. 
41:7:8: I forlater Guds bud og holder fast ved menneskers vedtekt. 
41:7:9: Og han sa til dem: Det er riktig vakkert at I gjør Guds bud til intet for å holde eders vedtekt. 
41:7:10: For Moses har sagt: Hedre din far og din mor, og: Den som banner far eller mor, skal visselig dø; 
41:7:11: men I sier: Om et menneske sier til far eller mor: Det du skulde ha hatt til hjelp av mig, det skal være en korban, det er en gave til templet, 
41:7:12: så lar I ham ikke lenger få lov til å gjøre noget for far eller mor, 
41:7:13: og således gjør I Guds ord til intet ved eders vedtekt, som I har pålagt menneskene. Og meget av samme slag gjør I. 
41:7:14: Og han kalte atter folket til sig og sa til dem: Hør på mig alle, og forstå hvad jeg sier! 
41:7:15: Det er intet utenfor mennesket som kan gjøre ham uren når det kommer inn i ham; men det som går ut av mennesket, det er det som gjør mennesket urent. 
41:7:16: Om nogen har ører å høre med, han høre! 
41:7:17: Og da han var kommet inn i et hus, bort fra folket, spurte hans disipler ham om denne lignelse. 
41:7:18: Og han sa til dem: Er da også I så uforstandige? Skjønner I ikke at intet som kommer inn i mennesket utenfra, kan gjøre ham uren? 
41:7:19: Det kommer jo ikke inn i hans hjerte, mere bare i hans buk, og går ut den naturlige vei, hvorved all mat blir renset. 
41:7:20: Men han sa: Det som går ut av mennesket, det er det som gjør mennesket urent. 
41:7:21: For innenfra, fra menneskenes hjerte, kommer de onde tanker: utukt, tyveri, mord, 
41:7:22: hor, havesyke, ondskap, svik, skamløshet, ondt øie, bespottelse, overmot, uforstand. 
41:7:23: Alle disse onde ting kommer ut innenfra og gjør mennesket urent. 
41:7:24: Og han stod op og gikk bort derfra til Tyrus' og Sidons landemerker. Og han gikk inn i et hus, og vilde ikke at nogen skulde få vite det, og det kunde dog ikke holdes skjult; 
41:7:25: men en kvinne hvis datter hadde en uren ånd, hadde fått høre om ham, og kom straks og falt ned for hans føtter. 
41:7:26: Men kvinnen var en hedensk kvinne, syrofønikisk av ætt; og hun bad ham at han vilde drive den onde ånd ut av hennes datter. 
41:7:27: Og han sa til henne: La først barna bli mette! for det er ikke vakkert å ta brødet fra barna og kaste det for de små hunder. 
41:7:28: Men hun svarte ham: Det er sant, Herre! de små hunder eter jo under bordet av barnas smuler. 
41:7:29: Og han sa til henne: For dette ords skyld sier jeg dig: Gå bort! Den onde ånd er faret ut av din datter. 
41:7:30: Og hun gikk bort til sitt hus og fant at barnet lå på sengen, og at den onde ånd var faret ut. 
41:7:31: Og da han gikk ut igjen fra Tyrus' landemerker, kom han gjennem Sidon til den Galileiske Sjø, midt igjennem Dekapolis-landet. 
41:7:32: Og de førte til ham en mann som var døv og hadde ondt for å tale, og de bad ham legge sin hånd på ham. 
41:7:33: Og han tok ham avsides fra folket, og stakk sine fingrer i hans ører og spyttet og rørte ved hans tunge, 
41:7:34: og så op mot himmelen, sukket og sa til ham: Effata! det er: lat dig op! 
41:7:35: Og straks blev hans ører oplatt, og hans tunges bånd blev løst, og han talte rent. 
41:7:36: Og han forbød dem å si det til nogen; men jo mere han forbød dem det, dess mere kunngjorde de det. 
41:7:37: Og de var overvettes forundret og sa: Han har gjort alle ting vel; både gjør han at de døve hører, og at de målløse taler. 
41:8:1: I de samme dager, da det atter var meget folk, og de ikke hadde noget å ete, kalte han sine disipler til sig og sa til dem: 
41:8:2: Jeg ynkes inderlig over folket; for de har alt vært hos mig i tre dager, og de har ikke noget å ete; 
41:8:3: og lar jeg dem fare fastende hjem, vil de vansmekte på veien; nogen av dem er jo kommet langveisfra. 
41:8:4: Og hans disipler svarte ham: Hvorfra kan nogen få brød nok til å mette disse her i ørkenen? 
41:8:5: Og han spurte dem: Hvor mange brød har I? De sa: Syv. 
41:8:6: Da bød han folket sette sig ned på jorden, og han tok de syv brød, takket og brøt dem og gav dem til sine disipler, forat de skulde dele dem ut; og de delte ut til folket. 
41:8:7: Og de hadde nogen få småfisker, og han velsignet dem og bød at også de skulde deles ut. 
41:8:8: Og de åt og blev mette; og de tok op det som blev tilovers av stykkene, syv kurver. 
41:8:9: Men de var omkring fire tusen. Og han lot dem fare. 
41:8:10: Og straks gikk han ut i båten med sine disipler, og kom til landet ved Dalmanuta. 
41:8:11: Og fariseerne kom der ut og begynte å tviste med ham, idet de krevde et tegn fra himmelen av ham for å friste ham. 
41:8:12: Og han sukket i sin ånd og sa: Hvorfor krever denne slekt tegn? Sannelig sier jeg eder: Ikke skal det gis denne slekt noget tegn. 
41:8:13: Og han forlot dem og gikk atter i båten, og fór over til hin side. 
41:8:14: Og de hadde glemt å ta brød med, og hadde ikke mere enn et eneste brød med sig i båten. 
41:8:15: Og han bød dem: Se eder for, ta eder i vare for fariseernes surdeig og for Herodes' surdeig! 
41:8:16: Og de talte sig imellem: Det er fordi vi ikke har brød med. 
41:8:17: Og da han merket det, sa han til dem: Hvorfor taler I med hverandre om at I ikke har brød med? Skjønner og forstår I ennu ikke? Er eders hjerte forherdet? 
41:8:18: Har I øine og ser ikke, har I ører og hører ikke? Kommer I ikke i hu 
41:8:19: da jeg brøt de fem brød til de fem tusen, hvor mange kurver fulle av stykker I da tok op? De sa til ham: Tolv. 
41:8:20: Og da jeg brøt de syv til de fire tusen, hvor mange kurver fulle av stykker tok I da op? De sa: Syv. 
41:8:21: Og han sa til dem: Hvorledes går det da til at I ennu ikke forstår? 
41:8:22: Og de kom til Betsaida. Og de førte en blind til ham og bad ham røre ved ham. 
41:8:23: Og han tok den blinde ved hånden og førte ham utenfor byen, og han spyttet i hans øine og la sine hender på ham, og spurte ham om han så noget. 
41:8:24: Og han så op og sa: Jeg kan se mennesker; for jeg ser folk gå omkring likesom trær. 
41:8:25: Så la han atter sine hender på hans øine, og han så klart, og han blev helbredet og kunde se alt tydelig på frastand.
41:8:26: Og han sendte ham hjem til hans hus og sa: Du skal ikke gå inn i byen eller si det til nogen i byen. 
41:8:27: Og Jesus og hans disipler gikk ut til byene omkring Cesarea Filippi; og på veien spurte han sine disipler og sa til dem: Hvem sier folk at jeg er? 
41:8:28: De svarte ham: Nogen sier døperen Johannes, og andre Elias, andre igjen en av profetene. 
41:8:29: Og han spurte dem: Men I, hvem sier I at jeg er? Peter svarte og sa til ham: Du er Messias. 
41:8:30: Og han bød dem strengt at de ikke skulde si dette om ham til nogen. 
41:8:31: Og han begynte å lære dem at Menneskesønnen skulde lide meget og forkastes av de eldste og yppersteprestene og de skriftlærde og ihjelslåes og opstå tre dager efter. 
41:8:32: Og han talte rent ut om det. Da tok Peter ham til side og begynte å irettesette ham. 
41:8:33: Men han vendte sig om og så på sine disipler og irettesatte Peter og sa: Vik bak mig, Satan! for du har ikke sans for det som hører Gud til, men bare for det som hører menneskene til. 
41:8:34: Og han kalte folket til sig tillikemed sine disipler og sa til dem: Den som vil følge efter mig, han må fornekte sig selv og ta sitt kors op og følge mig. 
41:8:35: For den som vil berge sitt liv, skal miste det; men den som mister sitt liv for min skyld og for evangeliets skyld, han skal berge det. 
41:8:36: For hvad gagner det et menneske om han vinner den hele verden og tar skade på sin sjel? 
41:8:37: For hvad kan vel et menneske gi til vederlag for sin sjel? 
41:8:38: For den som skammer sig ved mig og mine ord i denne utro og syndige slekt, ham skal også Menneskesønnen skamme sig ved når han kommer i sin Faders herlighet med de hellige engler. 
41:9:1: Og han sa til dem: Sannelig sier jeg eder: Nogen av dem som her står, skal ikke smake døden før de ser at Guds rike er kommet med kraft. 
41:9:2: Og seks dager derefter tok Jesus med sig Peter og Jakob og Johannes og førte dem avsides op på et høit fjell for sig selv alene. Og han blev forklaret for deres øine, 
41:9:3: og hans klær blev skinnende, aldeles hvite, så ingen bleker på jorden kan gjøre klær så hvite. 
41:9:4: Og Elias viste sig for dem sammen med Moses, og de talte med Jesus. 
41:9:5: Og Peter tok til orde og sa til Jesus: Rabbi! det er godt at vi er her; la oss gjøre tre boliger, en til dig, og en til Moses, og en til Elias! 
41:9:6: Han visste ikke hvad han skulde si; for de blev meget forferdet. 
41:9:7: Og en sky kom og overskygget dem, og det kom en røst ut av skyen: Dette er min Sønn, den elskede; hør ham! 
41:9:8: Og med ett, da de så sig om, så de ikke lenger nogen hos sig uten Jesus alene. 
41:9:9: Og da de gikk ned av fjellet, bød han dem at de ikke skulde fortelle nogen hvad de hadde sett, før Menneskesønnen var opstanden fra de døde. 
41:9:10: Og de holdt fast ved det ord, og de talte sig imellem om hvad det er å opstå fra de døde. 
41:9:11: Og de spurte ham og sa: De skriftlærde sier jo at Elias først må komme? 
41:9:12: Han sa til dem: Elias kommer først og setter alt i rette skikk, og hvad står det skrevet om Menneskesønnen? At han skal lide meget og bli foraktet. 
41:9:13: Men jeg sier eder at Elias er kommet, og de gjorde med ham alt det de vilde, som skrevet er om ham. 
41:9:14: Og da de kom til disiplene, så de meget folk om dem, og nogen skriftlærde som trettet med dem. 
41:9:15: Og straks alt folket så ham, blev de forferdet, og løp til og hilste ham. 
41:9:16: Og han spurte dem: Hvad er det I tretter med dem om? 
41:9:17: Og en blandt folket svarte: Mester! jeg har ført til dig min sønn, som er besatt av en målløs ånd; 
41:9:18: og når den griper ham, sliter den i ham, og han fråder og skjærer tenner og visner bort; og jeg bad dine disipler drive den ut, og de var ikke i stand til det. 
41:9:19: Men han svarte dem og sa: Du vantro slekt! hvor lenge skal jeg være hos eder? hvor lenge skal jeg tåle eder? 
41:9:20: Før ham hit til mig! Og de førte gutten til ham, og da han så ham, rev og slet ånden straks i ham, og han falt på jorden, veltet sig og frådet. 
41:9:21: Og Jesus spurte hans far: Hvor lang tid har han hatt det slik? Han sa: Fra barndommen av; 
41:9:22: og ofte har den kastet ham både i ild og i vann for å gjøre ende på ham; men om du formår noget, så ha medynk med oss og hjelp oss! 
41:9:23: Men Jesus sa til ham: Om jeg formår? - Alt er mulig for den som tror. 
41:9:24: Straks ropte barnets far: Jeg tror; hjelp min vantro! 
41:9:25: Men da Jesus så at folket løp til, truet han den urene ånd og sa til den: Du målløse og døve ånd! jeg byder dig: Far ut av ham, og far aldri mere inn i ham! 
41:9:26: Da skrek den og slet hårdt i ham, og fór ut av ham. Og han blev som død, så de fleste sa: Han er død. 
41:9:27: Men Jesus tok ham ved hånden og reiste ham op; og han stod op. 
41:9:28: Og da han var kommet inn i et hus, spurte hans disipler ham i enrum: Hvorfor kunde ikke vi drive den ut? 
41:9:29: Og han sa til dem: Dette slag kan ikke drives ut uten ved bønn og faste. 
41:9:30: Og da de gikk derfra, drog de gjennem Galilea, og han vilde ikke at nogen skulde få vite om det; 
41:9:31: for han lærte sine disipler og sa til dem: Menneskesønnen skal overgis i menneskenes hender, og de skal slå ham ihjel, og når han er ihjelslått, skal han opstå tre dager efter. 
41:9:32: Men de skjønte ikke dette ord, og de fryktet for å spørre ham. 
41:9:33: Og de kom til Kapernaum, og da han var kommet inn i huset, spurte han dem: Hvad var det I talte om på veien? 
41:9:34: Men de tidde; for de hadde talt med hverandre på veien om hvem som var den største. 
41:9:35: Og han satte sig og kalte på de tolv og sa til dem: Om nogen vil være den første, han skal være den siste av alle, og alles tjener. 
41:9:36: Og han tok et lite barn og stilte det midt iblandt dem, og tok det i favn og sa til dem: 
41:9:37: Den som tar imot et sådant lite barn for mitt navns skyld, han tar imot mig, og den som tar imot mig, han tar ikke imot mig, men imot ham som sendte mig. 
41:9:38: Johannes sa til ham: Mester! vi så en som ikke er i følge med oss, drive ut onde ånder i ditt navn, og vi forbød ham det, fordi han ikke var i følge med oss. 
41:9:39: Men Jesus sa: Forbyd ham det ikke! for ingen som gjør en kraftig gjerning i mitt navn, vil snart efter kunne tale ille om mig. 
41:9:40: For den som ikke er imot oss, er med oss. 
41:9:41: For den som gir eder et beger vann å drikke i mitt navn, fordi I hører Kristus til, sannelig sier jeg eder: han skal ikke miste sin lønn. 
41:9:42: Og den som forfører én av disse små som tror på mig, for ham var det bedre om det var hengt en kvernsten om hans hals, og han var kastet i havet. 
41:9:43: Og om din hånd frister dig, da hugg den av! det er bedre at du går vanfør inn til livet enn at du har dine to hender og kommer i helvede i den uslukkelige ild, 
41:9:44: hvor deres orm ikke dør, og ilden ikke slukkes. 
41:9:45: Og om din fot frister dig, da hugg den av! det er bedre at du går halt inn til livet enn at du har dine to føtter og kastes i helvede, 
41:9:46: hvor deres orm ikke dør, og ilden ikke slukkes. 
41:9:47: Og om ditt øie frister dig, da riv det ut! det er bedre at du går énøiet inn i Guds rike enn at du har to øine og kastes i helvede, 
41:9:48: hvor deres orm ikke dør, og ilden ikke slukkes. 
41:9:49: For enhver skal saltes med ild, og ethvert offer skal saltes med salt. 
41:9:50: Salt er en god ting; men når saltet mister sin kraft, hvad vil I da salte det med? Ha salt i eder selv, og hold fred med hverandre! 
41:10:1: Og han stod op derfra, og kom til Judeas landemerker og landet på hin side Jordan, og folket kom igjen sammen til ham, og han lærte dem atter, som han pleide. 
41:10:2: Og fariseerne kom til ham og spurte for å friste ham: Har en mann lov til å skille sig fra sin hustru? 
41:10:3: Han svarte og sa til dem: Hvad har Moses foreskrevet eder? 
41:10:4: De sa: Moses har gitt lov til å skrive et skilsmissebrev og skille sig fra henne. 
41:10:5: Men Jesus sa til dem: For eders hårde hjertes skyld har han skrevet eder dette bud. 
41:10:6: Men fra skapningens begynnelse skapte Gud dem til mann og kvinne. 
41:10:7: Derfor skal mannen forlate sin far og mor og holde sig til sin hustru, 
41:10:8: og de to skal være ett kjød. Så er de da ikke lenger to, men ett kjød. 
41:10:9: Derfor, det som Gud har sammenføiet, det skal et menneske ikke adskille. 
41:10:10: Og da de var kommet inn i huset, spurte disiplene ham atter om dette. 
41:10:11: Og han sa til dem: Den som skiller sig fra sin hustru og gifter sig med en annen, han gjør sig skyldig i hor mot henne, 
41:10:12: og dersom hustruen skiller sig fra sin mann og gifter sig med en annen, driver hun hor. 
41:10:13: Og de bar små barn til ham, forat han skulde røre ved dem; men disiplene truet dem som bar dem. 
41:10:14: Men da Jesus så det, blev han vred og sa til dem: La de små barn komme til mig, hindre dem ikke! for Guds rike hører sådanne til. 
41:10:15: Sannelig sier jeg eder: Den som ikke tar imot Guds rike som et lite barn, han skal ingenlunde komme inn i det. 
41:10:16: Og han tok dem i favn og la sine hender på dem og velsignet dem. 
41:10:17: Og da han gikk ut på veien, kom en løpende og falt på kne for ham og spurte ham: Gode mester! hvad skal jeg gjøre for å arve evig liv? 
41:10:18: Men Jesus sa til ham: Hvorfor kaller du mig god? Ingen er god uten én, det er Gud. 
41:10:19: Budene kjenner du: Du skal ikke drive hor, du skal ikke slå ihjel, du skal ikke stjele, du skal ikke si falskt vidnesbyrd, du skal ikke frata nogen hvad hans er, hedre din far og din mor. 
41:10:20: Men han sa til ham: Mester! alt dette har jeg holdt fra min ungdom av. 
41:10:21: Da så Jesus på ham og fikk ham kjær og sa til ham: Ett fattes dig; gå bort, selg alt det du har, og gi det til de fattige, så skal du få en skatt i himmelen; kom så og følg mig! 
41:10:22: Men han blev ille til mote over den tale og gikk bedrøvet bort; for han var meget rik. 
41:10:23: Og Jesus så sig om og sa til sine disipler: Hvor vanskelig det vil være for de rike å komme inn i Guds rike! 
41:10:24: Disiplene blev forferdet over hans ord. Da tok Jesus atter til orde og sa til dem: Barn! hvor vanskelig det er for dem som setter sin lit til sin rikdom, å komme inn i Guds rike! 
41:10:25: Det er lettere for en kamel å gå gjennem et nåleøie enn for en rik å gå inn i Guds rike. 
41:10:26: Da blev de ytterlig forferdet og sa til hverandre: Hvem kan da bli frelst? 
41:10:27: Jesus så på dem og sa: For mennesker er det umulig, men ikke for Gud; for alt er mulig for Gud. 
41:10:28: Peter tok til orde og sa til ham: Se, vi har forlatt alt og fulgt dig. 
41:10:29: Jesus svarte og sa: Sannelig sier jeg eder: Det er ingen som har forlatt hus eller brødre eller søstre eller mor eller far eller barn eller akrer for min skyld og for evangeliets skyld, 
41:10:30: uten at han skal få hundrefold igjen, nu her i tiden hus og brødre og søstre og mødre og barn og akrer under forfølgelser, og i den kommende verden evig liv. 
41:10:31: Men mange som er de første, skal bli de siste, og de siste de første. 
41:10:32: Men de var på veien op til Jerusalem, og Jesus gikk foran dem, og de var forferdet, og de som fulgte med, fryktet. Og han tok atter de tolv til sig og begynte å si dem hvorledes det skulde gå ham: 
41:10:33: Se, vi går op til Jerusalem, og Menneskesønnen skal overgis til yppersteprestene og de skriftlærde, og de skal dømme ham til døden og overgi ham til hedningene, 
41:10:34: og de skal spotte ham og spytte på ham og hudstryke ham og slå ham ihjel, og tre dager efter skal han opstå. 
41:10:35: Da gikk Jakob og Johannes, Sebedeus' sønner, til ham og sa: Mester, vi vil gjerne at du skal gjøre for oss det vi ber dig om. 
41:10:36: Han sa til dem: Hvad vil I da at jeg skal gjøre for eder? 
41:10:37: De sa til ham: Gi oss at den ene av oss må sitte ved din høire og den andre ved din venstre side i din herlighet! 
41:10:38: Men Jesus sa til dem: I vet ikke hvad det er I ber om. Kan I drikke den kalk jeg drikker, eller døpes med den dåp jeg døpes med? 
41:10:39: De sa til ham: Det kan vi. Men Jesus sa til dem: Den kalk jeg drikker, skal I drikke, og den dåp jeg døpes med, skal I døpes med; 
41:10:40: men å sitte ved min høire eller ved min venstre side, det tilkommer det ikke mig å gi nogen, men det gis dem som det er beredt. 
41:10:41: Og da de ti hørte dette, begynte de å harmes på Jakob og Johannes. 
41:10:42: Da kalte Jesus dem til sig og sa: I vet at de som gjelder for å være fyrster, hersker over sine folk, og deres stormenn bruker makt over dem. 
41:10:43: Men så er det ikke blandt eder; den som vil bli stor blandt eder, han skal være eders tjener, 
41:10:44: og den som vil bli den første blandt eder, han skal være alles træl; 
41:10:45: for Menneskesønnen er heller ikke kommet for å la sig tjene, men for selv å tjene og gi sitt liv til en løsepenge for mange. 
41:10:46: Og de kom til Jeriko; og da han gikk ut fra Jeriko med sine disipler og meget folk, satt Timeus' sønn, Bartimeus, en blind tigger, ved veien, 
41:10:47: og da han hørte at det var Jesus fra Nasaret, begynte han å rope: Jesus, du Davids sønn! miskunn dig over mig! 
41:10:48: Og mange truet ham at han skulde tie; men han ropte enda meget mere: Du Davids sønn! miskunn dig over mig! 
41:10:49: Og Jesus stod stille og sa: Kall ham hit! Og de kalte på den blinde og sa til ham: Vær frimodig, stå op! han kaller på dig. 
41:10:50: Og han kastet sin kappe av sig og sprang op og kom til Jesus. 
41:10:51: Og Jesus tok til orde og sa til ham: Hvad vil du jeg skal gjøre for dig? Den blinde sa til ham: Rabbuni! at jeg må få mitt syn igjen! 
41:10:52: Da sa Jesus til ham: Gå bort! din tro har frelst dig. Og straks fikk han sitt syn igjen og fulgte ham på veien. 
41:11:1: Og da de kom nær til Jerusalem, til Betfage og Betania ved Oljeberget, sendte han to av sine disipler avsted og sa til dem: 
41:11:2: Gå bort til den by som ligger rett for eder, og straks I kommer inn i den, skal I finne en fole bundet, som ennu aldri noget menneske har sittet på; løs den, og før den hit! 
41:11:3: Og dersom nogen sier til eder: Hvad er det I gjør? da skal I si: Herren har bruk for den, og han sender den straks tilbake igjen. 
41:11:4: Og de gikk avsted, og fant folen bundet ved døren utenfor på gaten og løste den. 
41:11:5: Og nogen av dem som stod der, sa til dem: Hvad er det I gjør? løser I folen? 
41:11:6: Men de sa til dem så som Jesus hadde sagt; og de lot dem få den. 
41:11:7: Og de førte folen til Jesus, og la sine klær på den; og han satte sig på den. 
41:11:8: Og mange bredte sine klær på veien, andre løvkvister, som de hadde hugget på markene. 
41:11:9: Og de som gikk foran, og de som fulgte efter, ropte: Hosianna! Velsignet være han som kommer i Herrens navn! 
41:11:10: Velsignet være vår far Davids rike som kommer! Hosianna i det høieste! 
41:11:11: Og han gikk inn i Jerusalem i templet og så sig om overalt, og da det alt var sent på dagen, gikk han ut til Betania med de tolv. 
41:11:12: Og den næste dag, da de gikk ut fra Betania, blev han hungrig. 
41:11:13: Og da han så et fikentre langt borte, som hadde blad, gikk han dit, om han kanskje kunde finne noget på det, og da han kom bort til det, fant han ikke noget uten blad; for det var ikke fikentid. 
41:11:14: Og han tok til orde og sa til det: Aldri i evighet skal nogen mere ete frukt av dig! Og hans disipler hørte det. 
41:11:15: Og de kom til Jerusalem; og han gikk inn i templet og begynte å drive ut dem som solgte og kjøpte i templet, og pengevekslernes bord og duekremmernes stoler veltet han, 
41:11:16: og han tillot ikke at nogen bar noget kar gjennem templet. 
41:11:17: Og han lærte og sa til dem: Er det ikke skrevet: Mitt hus skal kalles et bedehus for alle folk? Men I har gjort det til en røverhule. 
41:11:18: Og yppersteprestene og de skriftlærde hørte det, og de søkte råd til å rydde ham av veien; for de fryktet for ham, fordi alt folket var slått av forundring over hans lære. 
41:11:19: Og når det blev aften, gikk han ut av byen. 
41:11:20: Og da de gikk forbi tidlig om morgenen, så de at fikentreet var visnet fra roten av. 
41:11:21: Og Peter kom det i hu og sa til ham: Rabbi! se, fikentreet som du forbannet, er visnet. 
41:11:22: Og Jesus svarte og sa til dem: Ha tro til Gud! 
41:11:23: Sannelig sier jeg eder at den som sier til dette fjell: Løft dig op og kast dig i havet! og ikke tviler i sitt hjerte, men tror at det han sier skal skje, ham skal det vederfares. 
41:11:24: Derfor sier jeg eder: Alt det I beder om og begjærer, tro bare at I har fått det, så skal det vederfares eder. 
41:11:25: Og når I står og beder, og I har noget imot nogen, da forlat ham det, forat også eders Fader i himmelen skal forlate eder eders overtredelser! 
41:11:26: Men dersom I ikke forlater, da skal heller ikke eders Fader i himmelen forlate eders overtredelser. 
41:11:27: Og de kom atter til Jerusalem. Og da han gikk omkring i templet, kom yppersteprestene og de skriftlærde og de eldste 
41:11:28: og sa til ham: Med hvad myndighet gjør du dette, og hvem har gitt dig denne myndighet til å gjøre det? 
41:11:29: Men Jesus sa til dem: Jeg vil spørre eder om én ting; svar mig, så skal jeg si eder med hvad myndighet jeg gjør dette. 
41:11:30: Johannes' dåp, var den fra himmelen eller fra mennesker? Svar mig! 
41:11:31: De tenkte da ved sig selv og sa: Sier vi: Fra himmelen, da sier han: Hvorfor trodde I ham da ikke? 
41:11:32: Eller skal vi si: Fra mennesker? De fryktet for folket; for alle mente om Johannes at han i sannhet var en profet. 
41:11:33: De svarte da Jesus: Vi vet det ikke. Da svarte Jesus og sa til dem: Så sier heller ikke jeg eder med hvad myndighet jeg gjør dette. 
41:12:1: Og han begynte å tale til dem i lignelser: En mann plantet en vingård, og satte et gjerde omkring den og gravde en vinperse og bygget et tårn, og så leide han den ut til vingårdsmenn og drog utenlands. 
41:12:2: Og da tiden kom, sendte han en tjener til vingårdsmennene for å ta imot hans del av vingårdens frukt hos vingårdsmennene; 
41:12:3: og de tok og slo ham, og lot ham gå bort med tomme hender. 
41:12:4: Og atter sendte han en annen tjener til dem, og ham slo de i hodet og hånte ham. 
41:12:5: Og han sendte en annen, og ham slo de ihjel, og så gjorde de med mange andre: somme slo de, og somme drepte de. 
41:12:6: Nu hadde han bare sin eneste sønn igjen, som han elsket; ham sendte han til sist til dem, idet han sa: De vil undse sig for min sønn. 
41:12:7: Men disse vingårdsmenn sa til hverandre: Dette er arvingen; kom, la oss slå ham ihjel, så blir arven vår! 
41:12:8: Og de tok og slo ham ihjel, og kastet ham ut av vingården. 
41:12:9: Hvad skal da vingårdens herre gjøre? Han skal komme og drepe vingårdsmennene, og overgi vingården til andre. 
41:12:10: Og har I ikke lest dette sted i Skriften: Den sten som bygningsmennene forkastet, den er blitt hjørnesten; 
41:12:11: av Herren er dette gjort, og det er underfullt i våre øine? 
41:12:12: Og de søkte å gripe ham, men fryktet for folket; for de skjønte at det var om dem han hadde talt lignelsen. Og de forlot ham og gikk bort. 
41:12:13: Og de sendte nogen av fariseerne og herodianerne til ham for å fange ham med ord. 
41:12:14: Og de kom og sa til ham: Mester! vi vet at du er sanndru og ikke bryr dig om nogen; for du gjør ikke forskjell på folk, men lærer Guds vei i sannhet: Er det tillatt å gi keiseren skatt, eller ikke? skal vi gi, eller skal vi ikke gi? 
41:12:15: Men da han så deres hykleri, sa han til dem: Hvorfor frister I mig? Kom hit med en penning og la mig få se den! 
41:12:16: De gav ham en. Og han sier til dem: Hvis billede og påskrift er dette? De sa til ham: Keiserens. 
41:12:17: Og Jesus sa til dem: Gi keiseren hvad keiserens er, og Gud hvad Guds er! Og de undret sig storlig over ham. 
41:12:18: Og det kom nogen sadduseere til ham, de som sier at det ikke er nogen opstandelse, og de spurte ham og sa: 
41:12:19: Mester! Moses har foreskrevet oss at når en manns bror dør og efterlater hustru, men ikke barn, da skal hans bror ta hans hustru til ekte og opreise sin bror avkom. 
41:12:20: Nu var det syv brødre; og den første tok sig en hustru, og efterlot ikke avkom da han døde. 
41:12:21: Og den annen tok henne, og døde uten å efterlate avkom, og den tredje likeså; 
41:12:22: og ingen av de syv efterlot avkom. Sist av alle døde også kvinnen. 
41:12:23: Men i opstandelsen, når de står op, hvem av dem skal da få henne til hustru? for alle syv har jo hatt henne til hustru. 
41:12:24: Jesus sa til dem: Er det ikke derfor I farer vill, fordi I ikke kjenner skriftene og heller ikke Guds kraft? 
41:12:25: For når de står op fra de døde, da hverken tar de til ekte eller gis de til ekte, men de er som englene i himmelen. 
41:12:26: Men om de døde, at de står op, har I da ikke lest i Mose bok, der hvor det tales om tornebusken, hvorledes Gud talte til ham og sa: Jeg er Abrahams Gud og Isaks Gud og Jakobs Gud? 
41:12:27: Gud er ikke de dødes Gud, men de levendes. I farer storlig vill. 
41:12:28: Og en av de skriftlærde, som hadde hørt deres ordskifte, gikk til ham, da han forstod at han hadde svart dem godt, og han spurte ham: Hvilket bud er det første av alle? 
41:12:29: Jesus svarte ham: Det første er dette: Hør, Israel! Herren vår Gud, Herren er én, 
41:12:30: og du skal elske Herren din Gud av alt ditt hjerte og av all din sjel og av all din hu og av all din makt; dette er det første bud. 
41:12:31: Det annet, som er like så stort, er dette: Du skal elske din næste som dig selv. Større enn disse er intet annet bud. 
41:12:32: Og den skriftlærde sa til ham: I sannhet, mester! med rette har du sagt at han er én, og at det ikke er nogen annen foruten ham. 
41:12:33: Og å elske ham av alt sitt hjerte og av all sin forstand og av all sin sjel og av all sin makt, og å elske sin næste som sig selv, det er mere enn alle brennoffer og slaktoffer. 
41:12:34: Og da Jesus så at han svarte forstandig, sa han til ham: Du er ikke langt borte fra Guds rike. Og ingen vågde mere å spørre ham. 
41:12:35: Og mens Jesus lærte i templet, tok han til orde og sa: Hvorledes kan de skriftlærde si at Messias er Davids sønn? 
41:12:36: David selv har jo sagt i den Hellige Ånd: Herren sa til min herre: Sett dig ved min høire hånd, til jeg får lagt dine fiender til skammel for dine føtter! 
41:12:37: David selv kaller ham herre; hvorledes kan han da være hans sønn? Og den store mengde hørte ham gjerne. 
41:12:38: Og han sa mens han lærte dem: Ta eder i vare for de skriftlærde, som gjerne vil gå i side klær og la sig hilse på torvene, 
41:12:39: og ha de øverste seter i synagogene og sitte øverst ved gjestebudene; 
41:12:40: de som opeter enkers hus og for et syns skyld holder lange bønner! Disse skal få dess hårdere dom. 
41:12:41: Og han satte sig rett imot tempelkisten og så på hvorledes folket la penger i kisten; og mange rike la meget. 
41:12:42: Og en fattig enke kom og la to skjerver, som er én øre. 
41:12:43: Da kalte han sine disipler til sig og sa til dem: Sannelig sier jeg eder: Denne fattige enke har lagt mere enn alle de som la i kisten. 
41:12:44: For de la alle av sin overflod, men hun la av sin fattigdom alt det hun eide, hele sitt livsophold. 
41:13:1: Og da han gikk ut av templet, sa en av hans disipler til ham: Mester! se, hvilke stener og hvilke bygninger! 
41:13:2: Og Jesus sa til ham: Ser du disse store bygninger? Det skal ikke levnes sten på sten som ikke skal brytes ned. 
41:13:3: Og da han satt på Oljeberget rett imot templet, spurte Peter og Jakob og Johannes og Andreas ham i enrum: 
41:13:4: Si oss: Når skal dette skje? og hvad er tegnet når alt dette skal fullbyrdes? 
41:13:5: Jesus tok til orde og sa til dem: Se til at ikke nogen fører eder vill! 
41:13:6: For mange skal komme i mitt navn og si: Det er mig; og de skal føre mange vill. 
41:13:7: Men når I hører krig og rykter om krig, da la eder ikke skremme! for det må så skje, men enden er ikke enda. 
41:13:8: For folk skal reise sig mot folk, og rike mot rike; det skal være jordskjelv både her og der; det skal være hunger og oprør. Dette er begynnelsen til veene. 
41:13:9: Men ta eder i vare! De skal overgi eder til domstolene, og I skal hudstrykes i synagoger og stilles for landshøvdinger og konger for min skyld, til vidnesbyrd for dem. 
41:13:10: Og først må evangeliet forkynnes for alle folkeslag. 
41:13:11: Og når de fører eder frem og overgir eder, da vær ikke forut bekymret for hvad I skal tale! men det som gis eder i samme stund, det skal I tale; for det er ikke I som taler, men den Hellige Ånd. 
41:13:12: Og bror skal overgi bror til døden, og en far sitt barn, og barn skal reise sig mot foreldre og volde deres død. 
41:13:13: Og I skal hates av alle for mitt navns skyld; men den som holder ut inntil enden, han skal bli frelst. 
41:13:14: Men når I ser ødeleggelsens vederstyggelighet stå der hvor den ikke bør - den som leser det, han se til å skjønne det! - da må de som er i Judea, fly til fjells, 
41:13:15: og den som er på taket, ikke stige ned i huset og ikke gå inn for å hente noget fra sitt hus, 
41:13:16: og den som er ute på marken, ikke vende tilbake for å hente sin kappe. 
41:13:17: Ve de fruktsommelige og dem som gir die, i de dager! 
41:13:18: Men bed at det ikke må skje om vinteren! 
41:13:19: for i de dager skal det være så stor en trengsel som ikke har vært inntil nu fra skapningens begynnelse, fra den tid da Gud skapte verden, og som heller ikke skal bli. 
41:13:20: Og dersom ikke Herren forkortet de dager, da blev intet kjød frelst; men for de utvalgtes skyld, for deres skyld som han har utvalgt, har han forkortet de dager. 
41:13:21: Og om nogen da sier til eder: Se, her er Messias, eller: Se der - da skal I ikke tro det. 
41:13:22: For falske messiaser og falske profeter skal opstå og gjøre tegn og under for å føre de utvalgte vill, om det var mulig. 
41:13:23: Men ta I eder i vare! Jeg har sagt eder alt forut. 
41:13:24: Men i de dager, efter den trengsel, skal solen bli formørket og månen ikke gi sitt skinn, 
41:13:25: og stjernene skal falle ned fra himmelen, og himmelens krefter skal rokkes. 
41:13:26: Og da skal de se Menneskesønnen komme i skyene med megen kraft og herlighet. 
41:13:27: Og da skal han sende ut englene og samle sine utvalgte fra de fire verdenshjørner, fra jordens ende til himmelens ende. 
41:13:28: Lær en lignelse av fikentreet: Så snart det kommer saft i dets grener, og dets blader springer ut, da vet I at sommeren er nær; 
41:13:29: således skal også I, når I ser dette skje, vite at han er nær for døren. 
41:13:30: Sannelig sier jeg eder: Denne slekt skal ingenlunde forgå før alt dette skjer. 
41:13:31: Himmel og jord skal forgå, men mine ord skal ingenlunde forgå. 
41:13:32: Men hin dag eller time vet ingen, ikke engang englene i himmelen, ikke engang Sønnen, men alene min Fader. 
41:13:33: Ta eder i vare, våk! For I vet ikke når tiden er. 
41:13:34: Likesom en mann som drog utenlands og forlot sitt hus og overgav sine tjenere styret, enhver sin gjerning, og bød dørvokteren at han skulde våke, 
41:13:35: således skal I våke - for I vet ikke når husets herre kommer, enten det blir om aftenen eller ved midnatt eller ved hanegal eller om morgenen - 
41:13:36: forat han ikke skal finne eder sovende, når han kommer uforvarende. 
41:13:37: Men det jeg sier til eder, det sier jeg til alle: Våk! 
41:14:1: Men to dager derefter var det påske og de usyrede brøds høitid. Og yppersteprestene og de skriftlærde søkte råd til å gripe ham med list og slå ham ihjel; 
41:14:2: for de sa: Ikke på høitiden, forat det ikke skal bli opstyr blandt folket. 
41:14:3: Og mens han var i Betania, i Simon den spedalskes hus, og satt til bords, da kom en kvinne som hadde en alabaster-krukke med ekte kostelig nardussalve, og hun brøt alabaster-krukken i sønder og helte den ut over hans hode. 
41:14:4: Men der var nogen som blev vrede ved sig selv og sa: Hvad skal denne spille av salven være til? 
41:14:5: Denne salve kunde jo være solgt for mere enn tre hundre penninger og gitt til de fattige. Og de talte strengt til henne. 
41:14:6: Men Jesus sa: La henne være i fred! Hvorfor gjør I henne fortred? hun har gjort en god gjerning mot mig. 
41:14:7: For de fattige har I alltid hos eder, og når I vil, kan I gjøre vel mot dem; men mig har I ikke alltid. 
41:14:8: Hun gjorde det hun kunde; hun salvet forut mitt legeme til min jordeferd. 
41:14:9: Sannelig sier jeg eder: Hvor som helst evangeliet forkynnes i all verden, skal også det hun gjorde omtales til minne om henne. 
41:14:10: Og Judas Iskariot, en av de tolv, gikk til yppersteprestene for å forråde ham til dem. 
41:14:11: Men da de hørte det, blev de glade og lovte å gi ham penger; og han søkte leilighet til å forråde ham. 
41:14:12: Og på den første dag av de usyrede brøds høitid, da de slaktet påskelammet, sa hans disipler til ham: Hvor vil du vi skal gå og gjøre i stand, så du kan ete påskelammet? 
41:14:13: Da sendte han to av sine disipler avsted og sa til dem: Gå inn i byen, og der skal møte eder en mann som bærer en krukke vann; følg ham, 
41:14:14: og der hvor han går inn, skal I si til husbonden: Mesteren sier: Hvor er mitt herberge, der jeg kan ete påskelammet med mine disipler? 
41:14:15: Og han skal vise eder en stor sal, som står ferdig med benker og hynder, og der skal I gjøre det i stand for oss. 
41:14:16: Og disiplene gikk avsted og kom inn i byen og fant det således som han hadde sagt dem; og de gjorde i stand påskelammet. 
41:14:17: Og da det var blitt aften, kom han med de tolv. 
41:14:18: Og mens de satt til bords og åt, sa Jesus: Sannelig sier jeg eder: En av eder skal forråde mig, den som eter med mig. 
41:14:19: Da begynte de å bedrøves og en for en å si til ham: Det er da vel ikke mig? 
41:14:20: Han sa til dem: Det er en av de tolv, den som dypper sammen med mig i fatet. 
41:14:21: Menneskesønnen går bort, som skrevet er om ham; men ve det menneske ved hvem Menneskesønnen blir forrådt! Det hadde vært godt for det menneske om han aldri var født. 
41:14:22: Og mens de åt, tok han et brød, velsignet og brøt det, gav dem og sa: Ta det! Dette er mitt legeme. 
41:14:23: Og han tok en kalk, takket og gav dem; og de drakk alle av den. 
41:14:24: Og han sa til dem: Dette er mitt blod, paktens blod, som utgydes for mange. 
41:14:25: Sannelig sier jeg eder: Jeg skal ikke mere drikke av vintreets frukt før den dag jeg skal drikke den ny i Guds rike. 
41:14:26: Og da de hadde sunget lovsangen, gikk de ut til Oljeberget. 
41:14:27: Og Jesus sa til dem: I skal alle ta anstøt; for det er skrevet: Jeg vil slå hyrden, og fårene skal adspredes. 
41:14:28: Men når jeg er opstanden, vil jeg gå i forveien for eder til Galilea. 
41:14:29: Da sa Peter til ham: Om enn alle tar anstøt, vil dog ikke jeg gjøre det. 
41:14:30: Og Jesus sa til ham: Sannelig sier jeg dig: Idag, i denne natt, før hanen har galt to ganger, skal du fornekte mig tre ganger. 
41:14:31: Men han blev ved sitt og sa: Om jeg så skal dø med dig, vil jeg ikke fornekte dig. Det samme sa de alle. 
41:14:32: Og de kom til et sted som heter Getsemane; og han sa til sine disipler: Sett eder her mens jeg beder! 
41:14:33: Og han tok Peter og Jakob og Johannes med sig, og begynte å forferdes og engstes, 
41:14:34: og han sier til dem: Min sjel er bedrøvet inntil døden; bli her og våk! 
41:14:35: Og han gikk et lite stykke frem, falt ned på jorden og bad at denne stund måtte gå ham forbi, om det var mulig, 
41:14:36: og han sa: Abba, Fader! alt er mulig for dig; ta denne kalk fra mig! Dog, ikke hvad jeg vil, men hvad du vil! 
41:14:37: Og han kom og fant dem sovende, og sa til Peter: Simon, sover du? Var du ikke i stand til å våke én time? 
41:14:38: Våk og bed, forat I ikke skal komme i fristelse! Ånden er villig, men kjødet er skrøpelig. 
41:14:39: Og atter gikk han bort og bad, og sa de samme ord. 
41:14:40: Og da han kom igjen, fant han dem atter sovende; for deres øine var tunge, og de visste ikke hvad de skulde svare ham. 
41:14:41: Og han kom tredje gang og sa til dem: I sover altså og hviler eder! Det er nok; timen er kommet; se, Menneskesønnen overgis i synderes hender; 
41:14:42: stå op, la oss gå! Se, han er nær som forråder mig. 
41:14:43: Og straks, mens han ennu talte, kom Judas, en av de tolv, og med ham fulgte en hop med sverd og stokker; de kom fra yppersteprestene og de skriftlærde og de eldste. 
41:14:44: Men han som forrådte ham, hadde gitt dem et tegn og sagt: Den jeg kysser, ham er det; grip ham og før ham sikkert bort! 
41:14:45: Og da han kom, gikk han straks bort til ham og sa: Rabbi! og kysset ham. 
41:14:46: Men de la hånd på ham og grep ham. 
41:14:47: Men en av dem som stod der, drog sitt sverd, og slo til yppersteprestens tjener og hugg øret av ham. 
41:14:48: Og Jesus tok til orde og sa til dem: I er gått ut som mot en røver med sverd og stokker for å gripe mig; 
41:14:49: daglig var jeg hos eder og lærte i templet, og I grep mig ikke; men dette er skjedd forat skriftene skal opfylles. 
41:14:50: Da forlot de ham alle sammen og flydde. 
41:14:51: Og en ung mann fulgte ham med et linklæde kastet om sitt bare legeme, og de grep ham; 
41:14:52: men han slapp linklædet og flydde naken bort. 
41:14:53: Og de førte Jesus til ypperstepresten, og der kom alle yppersteprestene og de eldste og de skriftlærde sammen. 
41:14:54: Og Peter fulgte ham langt bakefter like inn i yppersteprestens gård, og han satt der sammen med tjenerne og varmet sig ved ilden. 
41:14:55: Men yppersteprestene og hele rådet søkte vidnesbyrd mot Jesus, forat de kunde drepe ham, men de fant ikke noget. 
41:14:56: For mange vidnet falsk mot ham, men deres vidnesbyrd stemte ikke overens. 
41:14:57: Da stod nogen op og vidnet falsk mot ham og sa: 
41:14:58: Vi har hørt ham si: Jeg vil bryte ned dette tempel som er gjort med hender, og på tre dager bygge et annet, som ikke er gjort med hender. 
41:14:59: Og enda stemte ikke deres vidnesbyrd overens. 
41:14:60: Da stod ypperstepresten op midt iblandt dem og spurte Jesus: Svarer du intet på det som disse vidner mot dig? 
41:14:61: Men han tidde og svarte intet. Atter spurte ypperstepresten ham og sa til ham: Er du Messias, den Velsignedes Sønn? 
41:14:62: Jesus sa: Jeg er det, og I skal se Menneskesønnen sitte ved kraftens høire hånd og komme med himmelens skyer. 
41:14:63: Da sønderrev ypperstepresten sine klær og sa: Hvad skal vi mere med vidner? 
41:14:64: I har hørt gudsbespottelsen; hvad tykkes eder? De dømte ham alle å være skyldig til døden. 
41:14:65: Og nogen gav sig til å spytte på ham og tildekke hans ansikt og slå ham med knyttet neve og si til ham: Spå! Og tjenerne tok imot ham med stokkeslag. 
41:14:66: Og mens Peter var nede i gårdsrummet, kom en av yppersteprestens tjenestepiker, 
41:14:67: og da hun så Peter varme sig, så hun på ham og sa: Også du var med denne nasareer, Jesus. 
41:14:68: Men han nektet det og sa: Jeg hverken vet eller forstår hvad du mener. Og han gikk ut i forgården, og hanen gol. 
41:14:69: Og piken fikk se ham, og begynte igjen å si til dem som stod der: Dette er en av dem. 
41:14:70: Men han nektet det atter. Og litt efter sa atter de som stod der, til Peter: Du er sannelig en av dem; du er jo en galileer. 
41:14:71: Men han gav sig til å forbanne sig og sverge: Jeg kjenner ikke det menneske I taler om. 
41:14:72: Og straks gol hanen annen gang. Da kom Peter det ord i hu som Jesus hadde sagt til ham: Før hanen har galt to ganger, skal du fornekte mig tre ganger. Og han tok det til hjerte og gråt. 
41:15:1: Og straks om morgenen holdt ypperstepresten samråd med de eldste og de skriftlærde, hele rådet, og de bandt Jesus, og førte ham bort og overgav ham til Pilatus. 
41:15:2: Og Pilatus spurte ham: Er du jødenes konge? Han svarte ham og sa: Du sier det. 
41:15:3: Og yppersteprestene førte mange klagemål imot ham. 
41:15:4: Da spurte Pilatus ham atter: Svarer du ikke et ord? Se hvor svære klagemål de fører mot dig! 
41:15:5: Men Jesus svarte ikke mere, så Pilatus undret sig. 
41:15:6: Men på høitiden pleide han å gi dem en fange fri, hvem de bad om. 
41:15:7: Nu var det en som hette Barabbas; han var kastet i fengsel sammen med nogen oprørere som hadde gjort et mord under oprøret; 
41:15:8: og folket kom op og begynte å be Pilatus om det som han alltid pleide å gjøre. 
41:15:9: Da svarte han dem og sa: Vil I jeg skal gi eder jødenes konge fri? 
41:15:10: For han visste at det var av avind yppersteprestene hadde overgitt ham til ham. 
41:15:11: Men yppersteprestene egget folket op til å be om at han heller skulde gi dem Barabbas fri. 
41:15:12: Da svarte Pilatus atter og sa til dem: Hvad vil I da jeg skal gjøre med ham som I kaller jødenes konge? 
41:15:13: De ropte igjen: Korsfest ham! 
41:15:14: Pilatus sa til dem: Hvad ondt har han da gjort? Men de ropte enda sterkere: Korsfest ham! 
41:15:15: Da nu Pilatus vilde gjøre folket til lags, gav han dem Barabbas fri og lot Jesus hudstryke og overgav ham til å korsfestes. 
41:15:16: Og stridsmennene førte ham bort, inn i gården, det er borgen, og kalte hele vakten sammen, 
41:15:17: og de klædde ham i en purpurkappe, og flettet en tornekrone og satte på ham, 
41:15:18: og begynte å hilse ham: Vær hilset, du jødenes konge! 
41:15:19: Og de slo ham i hodet med et rør og spyttet på ham og falt på kne og hyldet ham. 
41:15:20: Og da de hadde spottet ham, tok de purpurkappen av ham og klædde ham i hans egne klær. Så førte de ham ut for å korsfeste ham. 
41:15:21: Og det møtte dem en mann som kom ute fra landet, Simon fra Kyrene, far til Aleksander og Rufus; ham tvang de til å bære hans kors. 
41:15:22: Og de førte ham til stedet Golgata, det er utlagt: Hodeskallestedet, 
41:15:23: og de gav ham vin med myrra i; men han tok den ikke. 
41:15:24: Og de korsfestet ham og delte hans klær imellem sig og kastet lodd om hvad hver skulde få. 
41:15:25: Det var den tredje time da de korsfestet ham. 
41:15:26: Og innskriften med klagemålet imot ham lød: Jødenes konge. 
41:15:27: Og sammen med ham korsfestet de to røvere, en på hans høire og en på hans venstre side; 
41:15:28: og Skriften blev opfylt, som sier: Og han blev regnet blandt ugjerningsmenn. 
41:15:29: Og de som gikk forbi, spottet ham, og rystet på hodet og sa: Tvi dig, du som bryter ned templet og bygger det op igjen på tre dager! 
41:15:30: frels dig selv og stig ned av korset! 
41:15:31: Likeså spottet også yppersteprestene ham sig imellem sammen med de skriftlærde og sa: Andre har han frelst, sig selv kan han ikke frelse! 
41:15:32: La nu Messias, Israels konge, stige ned av korset, så vi kan se det og tro! Også de som var korsfestet sammen med ham, hånte ham. 
41:15:33: Og da den sjette time var kommet, blev det mørke over hele landet like til den niende time. 
41:15:34: Og ved den niende time ropte Jesus med høi røst: Elo'i! Elo'i! lama sabaktani? det er utlagt: Min Gud! Min Gud! hvorfor har du forlatt mig? 
41:15:35: Og da nogen av dem som stod der, hørte det, sa de: Se, han roper på Elias! 
41:15:36: Men en løp frem og fylte en svamp med eddik og stakk den på et rør og gav ham å drikke og sa: Vent, la oss se om Elias kommer for å ta ham ned! 
41:15:37: Men Jesus ropte med høi røst og utåndet. 
41:15:38: Og forhenget i templet revnet i to stykker fra øverst til nederst. 
41:15:39: Men da høvedsmannen, som stod like imot ham, så at han utåndet med et sådant rop, sa han: Sannelig, denne mann var Guds Sønn! 
41:15:40: Men der var også nogen kvinner som så på i frastand; blandt dem var også Maria Magdalena og Maria, mor til Jakob den yngre og Joses, og Salome, 
41:15:41: som hadde fulgt ham og tjent ham da han var i Galilea, og mange andre kvinner som hadde draget op med ham til Jerusalem. 
41:15:42: Og da det alt var blitt aften - det var beredelses-dagen, det er dagen før sabbaten - 
41:15:43: kom Josef av Arimatea, en høit aktet rådsherre, som også ventet på Guds rike, og han vågde sig til å gå inn til Pilatus og be om Jesu legeme. 
41:15:44: Men Pilatus undret sig om han alt skulde være død, 
41:15:45: og han kalte høvedsmannen for sig og spurte ham om det var lenge siden han døde; og da han hadde fått det å vite av høvedsmannen, gav han liket til Josef. 
41:15:46: Og Josef kjøpte fint linklæde og tok ham ned og svøpte ham i linklædet og la ham i en grav som var uthugget i klippen, og veltet en sten for døren til graven. 
41:15:47: Men Maria Magdalena og Maria, mor til Joses, så hvor han blev lagt. 
41:16:1: Og da sabbaten var til ende, kjøpte Maria Magdalena og Maria, Jakobs mor, og Salome velluktende urter for å gå og salve ham. 
41:16:2: Og meget tidlig på den første dag i uken kom de til graven, da solen gikk op. 
41:16:3: Og de sa til hverandre: Hvem skal velte bort stenen fra døren til graven for oss? 
41:16:4: Og da de så op, blev de var at stenen var veltet bort; for den var meget stor. 
41:16:5: Og da de kom inn i graven, så de en ung mann sitte på høire side, klædd i en hvit, sid kjortel; og de blev forferdet. 
41:16:6: Men han sier til dem: Forferdes ikke! I søker Jesus fra Nasaret, den korsfestede; han er opstanden, han er ikke her; se, der er stedet hvor de la ham. 
41:16:7: Men gå bort og si til hans disipler og til Peter at han går i forveien for eder til Galilea; der skal I se ham, som han har sagt eder. 
41:16:8: Og de gikk ut og flydde bort fra graven; for beven og forferdelse var kommet over dem; og de talte ikke et ord til nogen; for de fryktet. 
41:16:9: Men efterat han var opstanden tidlig den første dag i uken, åpenbarte han sig først for Maria Magdalena, som han hadde utdrevet syv onde ånder av. 
41:16:10: Hun gikk bort og fortalte det til dem som hadde vært med ham og nu sørget og gråt; 
41:16:11: og da disse hørte at han levde og var blitt sett av henne, trodde de det ikke. 
41:16:12: Derefter åpenbarte han sig i en annen skikkelse for to av dem, mens de gikk ut på landet. 
41:16:13: Også disse gikk bort og fortalte det til de andre; heller ikke dem trodde de. 
41:16:14: Men til sist åpenbarte han sig for de elleve selv, mens de satt til bords, og han refset dem for deres vantro og hårde hjerte, fordi de ikke hadde trodd dem som hadde sett ham opstanden. 
41:16:15: Og han sa til dem: Gå ut i all verden og forkynn evangeliet for all skapningen! 
41:16:16: Den som tror og blir døpt, skal bli frelst; men den som ikke tror, skal bli fordømt. 
41:16:17: Og disse tegn skal følge dem som tror: I mitt navn skal de drive ut onde ånder, de skal tale med tunger, 
41:16:18: de skal ta slanger i hendene, og om de drikker noget giftig, skal det ikke skade dem; på syke skal de legge sine hender, og de skal bli helbredet. 
41:16:19: Så blev den Herre Jesus, efterat han hadde talt til dem, optatt til himmelen, og satte sig ved Guds høire hånd. 
41:16:20: Men de gikk ut og forkynte ordet allesteds, og Herren virket med og stadfestet ordet ved de tegn som fulgte med. 
42:1:1: Eftersom mange har tatt sig fore å sette op en fortelling om de ting som er fullbyrdet iblandt oss, 
42:1:2: således som de som fra først av var øienvidner og blev ordets tjenere, har overgitt oss det, 
42:1:3: så har også jeg foresatt mig, efterat jeg nøie har gransket alt sammen fra først av, å nedskrive det i sammenheng for dig, gjæveste Teofilus, 
42:1:4: forat du kan lære å kjenne hvor pålitelige de lærdommer er som du er oplært i. 
42:1:5: I de dager da Herodes var konge i Jødeland, var det en prest ved navn Sakarias, av Abias skifte, og han hadde en hustru av Arons døtre, og hennes navn var Elisabet. 
42:1:6: De var begge rettferdige for Gud, og vandret ulastelig i alle Herrens bud og forskrifter. 
42:1:7: Og de hadde ikke barn; for Elisabet var ufruktbar, og de var begge kommet langt ut i årene. 
42:1:8: Men det skjedde mens han gjorde prestetjeneste for Gud, da raden var kommet til hans skifte, 
42:1:9: at det efter preste-tjenestens sedvane tilfalt ham å gå inn i Herrens tempel og ofre røkelse; 
42:1:10: og hele folkemengden stod utenfor og bad i røkofferets stund. 
42:1:11: Da åpenbarte en Herrens engel sig for ham og stod på høire side av røkoffer-alteret. 
42:1:12: Og da Sakarias så ham, blev han forferdet, og frykt falt på ham. 
42:1:13: Men engelen sa til ham: Frykt ikke, Sakarias! din bønn er hørt, og din hustru Elisabet skal føde dig en sønn, og du skal kalle ham Johannes; 
42:1:14: og han skal bli dig til glede og fryd, og mange skal glede sig over hans fødsel. 
42:1:15: For han skal være stor for Herren, og han skal ikke drikke vin og sterk drikk, og han skal fylles med den Hellige Ånd like fra mors liv; 
42:1:16: og han skal omvende mange av Israels barn til Herren deres Gud, 
42:1:17: og han skal gå i forveien for ham i Elias' ånd og kraft, for å vende fedres hjerter til barn og ulydige til rettferdiges sinnelag, for å berede Herren et velskikket folk. 
42:1:18: Og Sakarias sa til engelen: Hvorav skal jeg vite dette? Jeg er jo en gammel mann, og min hustru er langt ute i årene. 
42:1:19: Og engelen svarte ham: Jeg er Gabriel, som står for Guds åsyn, og jeg er utsendt for å tale til dig og forkynne dig dette glade budskap; 
42:1:20: og se, du skal bli målløs, og ikke kunne tale før den dag da dette skjer, fordi du ikke trodde mine ord, som skal fullbyrdes i sin tid. 
42:1:21: Og folket stod og ventet på Sakarias, og de undredes over at han blev så lenge i templet. 
42:1:22: Men da han kom ut, kunde han ikke tale til dem, og de skjønte at han hadde sett et syn i templet, og han nikket til dem, og var og blev stum. 
42:1:23: Og det skjedde da hans tjenestedager var til ende, da drog han hjem til sitt hus. 
42:1:24: Men efter disse dager blev hans hustru Elisabet fruktsommelig, og hun trakk sig tilbake i ensomhet i fem måneder, og sa: 
42:1:25: Så har Herren gjort med mig i de dager da han så til mig for å bortta min vanære iblandt menneskene. 
42:1:26: Men i den sjette måned blev engelen Gabriel sendt fra Gud til en by i Galilea som heter Nasaret, 
42:1:27: til en jomfru som var trolovet med en mann ved navn Josef, av Davids hus, og jomfruens navn var Maria. 
42:1:28: Og engelen kom inn til henne og sa: Vær hilset, du benådede! Herren er med dig; velsignet er du blandt kvinner! 
42:1:29: Men hun blev forferdet over hans ord, og grundet på hvad dette skulde være for en hilsen. 
42:1:30: Og engelen sa til henne: Frykt ikke, Maria! for du har funnet nåde hos Gud; 
42:1:31: og se, du skal bli fruktsommelig og føde en sønn, og du skal kalle ham Jesus. 
42:1:32: Han skal være stor og kalles den Høiestes Sønn, og Gud Herren skal gi ham hans far Davids trone, 
42:1:33: og han skal være konge over Jakobs hus evindelig, og det skal ikke være ende på hans kongedømme. 
42:1:34: Men Maria sa til engelen: Hvorledes skal dette gå til, da jeg ikke vet av mann? 
42:1:35: Og engelen svarte henne: Den Hellige Ånd skal komme over dig, og den Høiestes kraft skal overskygge dig; derfor skal også det hellige som fødes, kalles Guds Sønn. 
42:1:36: Og se, Elisabet, din slektning, har også undfanget en sønn i sin alderdom, og hun, som kaltes ufruktbar, er nu i sin sjette måned. 
42:1:37: For ingen ting er umulig for Gud. 
42:1:38: Da sa Maria: Se, jeg er Herrens tjenerinne; mig skje efter ditt ord! Og engelen skiltes fra henne. 
42:1:39: Men Maria stod op i de dager og skyndte sig til fjellbygdene, til en by i Juda, 
42:1:40: og hun kom inn i Sakarias' hus og hilste på Elisabet. 
42:1:41: Og det skjedde da Elisabet hørte Marias hilsen, da sprang fosteret i hennes liv, og Elisabet blev fylt med den Hellige Ånd 
42:1:42: og ropte med høi røst og sa: Velsignet er du blandt kvinner, og velsignet er ditt livs frukt! 
42:1:43: Og hvorledes times mig dette, at min Herres mor kommer til mig? 
42:1:44: For se, da lyden av din hilsen nådde mitt øre, sprang fosteret i mitt liv med fryd. 
42:1:45: Og salig er hun som trodde; for fullbyrdes skal det som er sagt henne av Herren. 
42:1:46: Da sa Maria: Min sjel ophøier Herren, 
42:1:47: og min ånd fryder sig i Gud, min frelser, 
42:1:48: han som har sett til sin tjenerinnes ringhet. For se, fra nu av skal alle slekter prise mig salig, 
42:1:49: fordi han har gjort store ting imot mig, han, den mektige, og hellig er hans navn, 
42:1:50: og hans miskunn er fra slekt til slekt over dem som frykter ham. 
42:1:51: Han gjorde veldig verk med sin arm, han adspredte dem som var overmodige i sitt hjertes tanke; 
42:1:52: han støtte stormenn fra deres høiseter og ophøiet de små; 
42:1:53: hungrige mettet han med gode gaver, og rikmenn lot han gå bort med tomme hender. 
42:1:54: Han tok sig av Israel, sin tjener, for å komme miskunn i hu 
42:1:55: - således som han talte til våre fedre - mot Abraham og hans ætt til evig tid. 
42:1:56: Og Maria blev hos henne omkring tre måneder, og vendte så hjem igjen til sitt hus. 
42:1:57: Men for Elisabet kom tiden da hun skulde føde, og hun fødte en sønn, 
42:1:58: og hennes granner og frender fikk høre at Herren hadde gjort sin miskunn stor mot henne, og de gledet sig med henne. 
42:1:59: Og det skjedde på den åttende dag, da kom de for å omskjære barnet, og de vilde kalle ham Sakarias efter hans far. 
42:1:60: Da tok hans mor til orde og sa: Nei, han skal hete Johannes. 
42:1:61: Og de sa til henne: Men det er jo ingen i din ætt som har dette navn. 
42:1:62: De gjorde da tegn til hans far for å få vite hvad han vilde han skulde hete. 
42:1:63: Og han bad om en tavle og skrev disse ord: Johannes er hans navn. Og de undret sig alle. 
42:1:64: Men straks blev hans munn oplatt og hans tunge løst, og han talte og lovet Gud. 
42:1:65: Og det kom frykt på alle dem som bodde deromkring, og i alle Judeas fjellbygder talte de med hverandre om alle disse ting, 
42:1:66: og alle som hørte det, la det på hjerte og sa: Hvad skal det da bli av dette barn? For Herrens hånd var med ham. 
42:1:67: Og hans far Sakarias blev fylt med den Hellige Ånd og talte profetiske ord og sa: 
42:1:68: Lovet være Herren, Israels Gud, han som så til sitt folk og forløste det! 
42:1:69: Og han opreiste oss et frelsens horn i sin tjener Davids hus, 
42:1:70: således som han talte gjennem sine hellige profeters munn fra fordums tid av, 
42:1:71: en frelse fra våre fiender og fra alle deres hånd som hater oss, 
42:1:72: for å gjøre miskunn mot våre fedre og komme sin hellige pakt i hu, 
42:1:73: den ed han svor Abraham, vår far, 
42:1:74: for å fri oss av våre fienders hånd og gi oss å tjene ham uten frykt 
42:1:75: i hellighet og rettferdighet for hans åsyn alle våre dager. 
42:1:76: Men også du, barn, skal kalles den Høiestes profet; for du skal gå frem for Herrens åsyn for å rydde hans veier, 
42:1:77: for å lære hans folk frelse å kjenne ved deres synders forlatelse 
42:1:78: for vår Guds miskunnelige hjertelags skyld, som lot solopgang fra det høie gjeste oss, 
42:1:79: for å lyse for dem som sitter i mørke og dødsskygge, for å styre våre føtter inn på fredens vei. 
42:1:80: Men barnet vokste og blev sterkt i ånden, og han var i ødemarkene til den dag da han blev ført frem for Israel. 
42:2:1: Og det skjedde i de dager at det utgikk et bud fra keiser Augustus at all verden skulde innskrives i manntall. 
42:2:2: Dette var den første innskrivning, i den tid Kvirinius var landshøvding i Syria. 
42:2:3: Og alle gikk for å la sig innskrive, hver til sin by. 
42:2:4: Men også Josef drog op fra Galilea, fra byen Nasaret, til Judea, til Davids stad, som heter Betlehem, fordi han var av Davids hus og ætt, 
42:2:5: for å la sig innskrive sammen med Maria, sin trolovede, som var fruktsommelig. 
42:2:6: Men det skjedde mens de var der, da kom tiden da hun skulde føde. 
42:2:7: Og hun fødte sin sønn, den førstefødte, og svøpte ham og la ham i en krybbe, fordi det ikke var rum for dem i herberget. 
42:2:8: Og det var nogen hyrder der på stedet, som var ute på marken og holdt nattevakt over sin hjord. 
42:2:9: Og se, en Herrens engel stod for dem, og Herrens herlighet lyste om dem, og de blev meget forferdet. 
42:2:10: Og engelen sa til dem: Forferdes ikke! for se, jeg forkynner eder en stor glede, som skal vederfares alt folket! 
42:2:11: Eder er idag en frelser født, som er Kristus, Herren, i Davids stad. 
42:2:12: Og dette skal I ha til tegn: I skal finne et barn svøpt, liggende i en krybbe. 
42:2:13: Og straks var det hos engelen en himmelsk hærskare, som lovet Gud og sa: 
42:2:14: Ære være Gud i det høieste, og fred på jorden, i mennesker hans velbehag! 
42:2:15: Og det skjedde da englene var faret fra dem op til himmelen, da sa hyrdene til hverandre: La oss nu gå like til Betlehem og se dette som har hendt, og som Herren har kunngjort oss! 
42:2:16: Og de skyndte sig og kom og fant både Maria og Josef, og barnet som lå i krybben; 
42:2:17: og da de hadde sett det, fortalte de dem det ord som var sagt dem om dette barn. 
42:2:18: Og alle som hørte det, undret sig over det som blev dem sagt av hyrdene; 
42:2:19: men Maria gjemte alle disse ord og grundet på dem i sitt hjerte. 
42:2:20: Og hyrdene vendte tilbake, og priste og lovet Gud for alt det de hadde hørt og sett, således som det var blitt sagt dem. 
42:2:21: Og da åtte dager var til ende, og han skulde omskjæres, blev han kalt Jesus, det navn som engelen hadde nevnt før han blev undfanget i mors liv. 
42:2:22: Og da deres renselses-dager efter Mose lov var til ende, førte de ham op til Jerusalem for å stille ham frem for Herren - 
42:2:23: som det er skrevet i Herrens lov: Alt mannkjønn som åpner mors liv, skal kalles hellig for Herren - 
42:2:24: og for å gi offer, efter det som er sagt i Herrens lov, et par turtelduer eller to due-unger. 
42:2:25: Og se, det var en mann i Jerusalem ved navn Simeon, og denne mann var rettferdig og gudfryktig og ventet på Israels trøst; og den Hellige Ånd var over ham, 
42:2:26: og det var åpenbaret ham av den Hellige Ånd at han ikke skulde se døden før han hadde sett Herrens salvede. 
42:2:27: Han kom, drevet av Ånden, inn i templet, og da foreldrene førte barnet Jesus inn for å gjøre med ham som skikk var efter loven, 
42:2:28: da tok han ham på sine armer og lovet Gud og sa: 
42:2:29: Herre! nu lar du din tjener fare herfra i fred, efter ditt ord; 
42:2:30: for mine øine har sett din frelse, 
42:2:31: som du har beredt for alle folks åsyn, 
42:2:32: et lys til åpenbarelse for hedningene, og en herlighet for ditt folk Israel. 
42:2:33: Og hans far og hans mor undret sig over det som blev talt om ham. 
42:2:34: Og Simeon velsignet dem, og sa til hans mor Maria: Se, denne er satt til fall og opreisning for mange i Israel, og til et tegn som blir motsagt; 
42:2:35: men også din sjel skal et sverd gjennemstinge, forat mange hjerters tanker skal bli åpenbaret. 
42:2:36: Og der var en profetinne, Anna, Fanuels datter, av Asers stamme; hun var kommet langt ut i årene, hadde levd med sin mann i syv år efter sin jomfrustand, 
42:2:37: og nu for sig selv som enke inntil en alder av fire og åtti år; hun vek aldri fra templet, men tjente Gud med faste og bønn natt og dag. 
42:2:38: Og hun trådte til i samme stund og priste Gud, og hun talte om ham til alle dem som ventet på forløsning for Jerusalem. 
42:2:39: Og da de hadde fullført alt efter Herrens lov, vendte de tilbake til Galilea, til sin by Nasaret. 
42:2:40: Men barnet vokste og blev sterkt og fullt av visdom, og Guds velbehag var over ham. 
42:2:41: Og hans foreldre drog hvert år til Jerusalem til påskefesten. 
42:2:42: Og da han var tolv år gammel, drog de der op, som det var skikk på høitiden; 
42:2:43: og da de hadde vært der de dager til ende, og de vendte hjem igjen, blev barnet Jesus tilbake i Jerusalem, og hans foreldre visste ikke om det. 
42:2:44: Men da de trodde at han var i reisefølget, kom de en dags reise frem, og lette efter ham blandt slektninger og kjenninger; 
42:2:45: og da de ikke fant ham, vendte de tilbake til Jerusalem og lette efter ham. 
42:2:46: Og det skjedde tre dager derefter, da fant de ham i templet; der satt han midt iblandt lærerne og hørte på dem og spurte dem, 
42:2:47: og alle som hørte ham, var ute av sig selv av forundring over hans forstand og svar. 
42:2:48: Og da de så ham, blev de forferdet, og hans mor sa til ham: Barn! hvorfor gjorde du oss dette? Se, din far og jeg har lett efter dig med smerte. 
42:2:49: Og han sa til dem: Hvorfor lette I efter mig? Visste I ikke at jeg må være i min Faders hus? 
42:2:50: Men de forstod ikke det ord han talte til dem. 
42:2:51: Og han gikk ned med dem og kom til Nasaret og var dem lydig. Og hans mor gjemte alle disse ord i sitt hjerte. 
42:2:52: Og Jesus gikk frem i visdom og alder og yndest hos Gud og mennesker. 
42:3:1: I det femtende år av keiser Tiberius' regjering, mens Pontius Pilatus var landshøvding i Judea, og Herodes fjerdingsfyrste i Galilea, og hans bror Filip fjerdingsfyrste i det itureiske og trakonittiske land, og Lysanias fjerdingsfyrste i Abilene, 
42:3:2: mens Annas og Kaifas var yppersteprester, da kom Guds ord til Johannes, Sakarias' sønn, i ørkenen; 
42:3:3: og han kom rundt i hele landet om Jordan og forkynte omvendelses dåp til syndenes forlatelse, 
42:3:4: således som det er skrevet i profeten Esaias' talers bok: Det er en røst av en som roper i ørkenen: Rydd Herrens vei, gjør hans stier jevne! 
42:3:5: Hver dal skal fylles, og hvert fjell og hver haug skal senkes, og det krokete skal rettes, og de knudrete veier jevnes, 
42:3:6: og alt kjød skal se Guds frelse. 
42:3:7: Han sa da til folket som drog ut for å døpes av ham: Ormeyngel! hvem lærte eder å fly for den kommende vrede? 
42:3:8: Bær derfor frukter som er omvendelsen verdige, og gi eder ikke til å si ved eder selv: Vi har Abraham til far! for jeg sier eder at Gud kan opvekke Abraham barn av disse stener. 
42:3:9: Øksen ligger også allerede ved roten av trærne; derfor blir hvert tre som ikke bærer god frukt, hugget ned og kastet på ilden. 
42:3:10: Og folket spurte ham: Hvad skal vi da gjøre? 
42:3:11: Han svarte dem: Den som har to kjortler, skal dele med den som ingen har, og den som har mat, skal gjøre likeså! 
42:3:12: Men også toldere kom for å døpes, og de sa til ham: Mester! hvad skal vi gjøre? 
42:3:13: Han sa til dem: Krev ikke mere enn hvad eder er foreskrevet! 
42:3:14: Men også krigsfolk spurte ham og sa: Og vi, hvad skal vi gjøre? Og han sa til dem: Press ikke penger ut av nogen ved vold eller ved svik, og la eder nøie med eders lønn! 
42:3:15: Men da folket gikk i forventning, og alle tenkte i sitt hjerte på Johannes, om ikke han var Messias, 
42:3:16: da svarte Johannes og sa til alle: Jeg døper eder med vann; men den kommer som er sterkere enn jeg, han hvis skorem jeg ikke er verdig til å løse; han skal døpe eder med den Hellige Ånd og ild; 
42:3:17: han har sin kasteskovl i sin hånd, og han skal rense sin låve og samle hveten i sin lade, men agnene skal han brenne op med uslukkelig ild. 
42:3:18: Også mange andre formaninger gav han folket, og forkynte dem evangeliet. 
42:3:19: Men fjerdingsfyrsten Herodes, som blev refset av ham for sin brorkone Herodias' skyld, og for alt det onde Herodes hadde gjort, 
42:3:20: han la også dette til alt det andre, at han kastet Johannes i fengsel. 
42:3:21: Men det skjedde da alt folket lot sig døpe, og Jesus var blitt døpt og bad, da åpnet himmelen sig, 
42:3:22: og den Hellige Ånd kom ned over ham i legemlig skikkelse, som en due, og en røst kom fra himmelen: Du er min Sønn, den elskede; i dig har jeg velbehag. 
42:3:23: Og Jesus selv var omkring tretti år da han begynte sin gjerning, og han var, efter det folk holdt ham for, sønn av Josef, sønn av Eli, 
42:3:24: sønn av Mattat, sønn av Levi, sønn av Melki, sønn av Jannai, sønn av Josef, 
42:3:25: sønn av Mattatias, sønn av Amos, sønn av Nahum, sønn av Esli, sønn av Naggai, 
42:3:26: sønn av Ma'at, sønn av Mattatias, sønn av Sime'i, sønn av Josek, sønn av Joda, 
42:3:27: sønn av Johanan, sønn av Resa, sønn av Serubabel, sønn av Sealtiel, sønn av Neri, 
42:3:28: sønn av Melki, sønn av Addi, sønn av Kosam, sønn av Elmadam, sønn av Er, 
42:3:29: sønn av Josva, sønn av Elieser, sønn av Jorim, sønn av Mattat, sønn av Levi, 
42:3:30: sønn av Simeon, sønn av Juda, sønn av Josef, sønn av Jonam, sønn av Eljakim, 
42:3:31: sønn av Melea, sønn av Mana, sønn av Mattata, sønn av Natan, sønn av David, 
42:3:32: sønn av Isai, sønn av Obed, sønn av Boas, sønn av Salmon, sønn av Nahson, 
42:3:33: sønn av Aminadab, sønn av Ram, sønn av Hesron, sønn av Peres, sønn av Juda, 
42:3:34: sønn av Jakob, sønn av Isak, sønn av Abraham, sønn av Tarah, sønn av Nakor, 
42:3:35: sønn av Serug, sønn av Re'u, sønn av Peleg, sønn av Eber, sønn av Salah, 
42:3:36: sønn av Kenan, sønn av Arpaksad, sønn av Sem, sønn av Noah, sønn av Lamek, 
42:3:37: sønn av Metusalah, sønn av Enok, sønn av Jared, sønn av Malalael, sønn av Kenan, 
42:3:38: sønn av Enos, sønn av Set, sønn av Adam, Guds sønn. 
42:4:1: Men Jesus vendte tilbake fra Jordan, full av den Hellige Ånd, og han blev av Ånden ført om i ørkenen 
42:4:2: og i firti dager fristet av djevelen. Og han åt intet i de dager, og da de var til ende, blev han hungrig. 
42:4:3: Da sa djevelen til ham: Er du Guds Sønn, da si til denne sten at den skal bli til brød! 
42:4:4: Og Jesus svarte ham: Det er skrevet: Mennesket lever ikke av brød alene, men av hvert Guds ord. 
42:4:5: Og djevelen førte ham op på et høit fjell og viste ham alle verdens riker i et øieblikk. 
42:4:6: Og djevelen sa til ham: Dig vil jeg gi makten over alt dette og disse rikers herlighet; for mig er det overgitt, og jeg gir det til hvem jeg vil; 
42:4:7: vil nu du falle ned og tilbede mig, da skal det alt sammen være ditt. 
42:4:8: Og Jesus svarte ham og sa: Det er skrevet: Du skal tilbede Herren din Gud, og ham alene skal du tjene. 
42:4:9: Og han førte ham til Jerusalem og stilte ham på templets tinde og sa til ham: Er du Guds Sønn, da kast dig ned herfra! 
42:4:10: for det er skrevet: Han skal gi sine engler befaling om dig at de skal bevare dig, 
42:4:11: og de skal bære dig på hendene, forat du ikke skal støte din fot på nogen sten. 
42:4:12: Og Jesus svarte og sa til ham: Det er sagt: Du skal ikke friste Herren din Gud. 
42:4:13: Og da djevelen hadde endt all fristelse, vek han fra ham for en tid. 
42:4:14: Og Jesus vendte i Åndens kraft tilbake til Galilea, og rykte om ham kom ut over hele landet deromkring. 
42:4:15: Og han lærte i deres synagoger, og blev prist av alle. 
42:4:16: Og han kom til Nasaret, hvor han var opfostret, og gikk efter sin sedvane på sabbatsdagen inn i synagogen og stod op for å lese for dem. 
42:4:17: Og de gav ham profeten Esaias' bok, og da han slo boken op, fant han det sted hvor det var skrevet: 
42:4:18: Herrens Ånd er over mig, fordi han salvet mig til å forkynne evangeliet for fattige; han har utsendt mig for å forkynne fanger at de skal få frihet, og blinde at de skal få syn, for å sette undertrykte i frihet, 
42:4:19: for å forkynne et velbehagelig år fra Herren. 
42:4:20: Og han lukket boken og gav den til tjeneren og satte sig, og alle som var i synagogen, hadde sine øine festet på ham. 
42:4:21: Han begynte da med å si til dem: Idag er dette Skriftens ord opfylt for eders ører. 
42:4:22: Og alle gav ham vidnesbyrd og undret sig over de livsalige ord som gikk ut av hans munn, og de sa: Er ikke dette Josefs sønn? 
42:4:23: Og han sa til dem: I vil visst si til mig dette ordsprog: Læge, læg dig selv! Hvad vi har hørt du gjorde i Kapernaum, gjør det også her på ditt hjemsted! 
42:4:24: Men han sa: Sannelig sier jeg eder: Ingen profet blir vel mottatt på sitt hjemsted. 
42:4:25: Og i sannhet sier jeg eder: Det var mange enker i Israel i Elias' dager, dengang da himmelen blev lukket for tre år og seks måneder, da det blev en stor hunger over hele landet, 
42:4:26: og til ingen av dem blev Elias sendt, men bare til en enke i Sarepta i Sidons land. 
42:4:27: Og det var mange spedalske i Israel på profeten Elisas tid, og ingen av dem blev renset, men bare syreren Na'aman. 
42:4:28: Og alle i synagogen blev fulle av vrede da de hørte dette, 
42:4:29: og de stod op og drev ham ut av byen og førte ham ut på brynet av det fjell som deres by var bygget på, for å styrte ham ned. 
42:4:30: Men han gikk midt gjennem flokken og drog bort. 
42:4:31: Og han kom ned til Kapernaum, en by i Galilea, og lærte dem på sabbaten, 
42:4:32: og de var slått av forundring over hans lære; for hans tale var med myndighet. 
42:4:33: Og i synagogen var det en mann som var besatt av en uren ånd, og han ropte med høi røst: 
42:4:34: Å, hvad har vi med dig å gjøre, Jesus fra Nasaret? Du er kommet for å ødelegge oss; jeg vet hvem du er, du Guds hellige! 
42:4:35: Og Jesus truet den og sa: Ti, og far ut av ham! Og den onde ånd kastet ham ned midt iblandt dem og fór ut av ham uten å skade ham. 
42:4:36: Og redsel kom over alle, og de talte med hverandre og sa: Hvad er dette for et ord? for med myndighet og makt byder han de urene ånder, og de farer ut! 
42:4:37: Og rykte om ham kom ut til hvert sted i landet deromkring. 
42:4:38: Og han stod op og forlot synagogen, og gikk inn i Simons hus. Men Simons svigermor lå i sterk feber, og de bad ham hjelpe henne. 
42:4:39: Og han stod over henne og truet feberen, og den forlot henne; og straks stod hun op og tjente dem. 
42:4:40: Men da solen gikk ned, kom alle som hadde syke som led av forskjellige sykdommer, og førte dem til ham; og han la sine hender på hver især av dem og helbredet dem. 
42:4:41: Også onde ånder fór ut av mange, og de ropte: Du er Guds Sønn! Og han truet dem og tillot dem ikke å tale, fordi de visste at han var Messias. 
42:4:42: Men da det var blitt dag, gikk han ut og drog til et øde sted, og folket lette efter ham, og de kom like til ham og holdt på ham, forat han ikke skulde gå fra dem. 
42:4:43: Men han sa til dem: Også i de andre byer må jeg forkynne evangeliet om Guds rike; for dertil er jeg utsendt. 
42:4:44: Og han forkynte ordet i synagogene i Galilea. 
42:5:1: Men det skjedde da folket trengte sig inn på ham og hørte Guds ord, og han stod ved Gennesaret-sjøen, 
42:5:2: da så han to båter ligge ved sjøen; men fiskerne var gått ut av dem og skyllet garnene. 
42:5:3: Han gikk da ut i en av båtene, som tilhørte Simon, og bad ham legge litt ut fra land; og han satte sig i båten og lærte folket. 
42:5:4: Men da han holdt op å tale, sa han til Simon: Legg ut på dypet, og kast eders garn ut til en drett! 
42:5:5: Og Simon svarte ham: Mester! vi har strevet hele natten og ikke fått noget; men på ditt ord vil jeg kaste ut garnene. 
42:5:6: Og de gjorde så, og fanget en stor mengde fisk; og deres garn revnet. 
42:5:7: Og de vinket til sine lagsbrødre i den andre båt, at de skulde komme og ta i med dem; og de kom, og de fylte begge båtene, så de holdt på å synke. 
42:5:8: Men da Simon Peter så det, falt han ned for Jesu knær og sa: Herre, gå fra mig! for jeg er en syndig mann. 
42:5:9: For redsel kom over ham og alle dem som var med ham, for den fiskedrett som de hadde fått; 
42:5:10: likeså Jakob og Johannes, Sebedeus' sønner, som var i lag med Simon. Og Jesus sa til Simon: Frykt ikke! fra nu av skal du fange mennesker. 
42:5:11: Da rodde de båtene i land og forlot alt og fulgte ham. 
42:5:12: Og det skjedde da han var i en av byene, se, da var det en mann som var full av spedalskhet; og da han så Jesus, falt han ned på sitt ansikt, bad ham og sa: Herre! om du vil, kan du rense mig. 
42:5:13: Og Jesus rakte hånden ut, rørte ved ham og sa: Jeg vil; bli ren! Og straks forlot spedalskheten ham. 
42:5:14: Og han bød ham at han ikke skulde si det til nogen; men gå og te dig for presten og ofre for din renselse, således som Moses har påbudt, til et vidnesbyrd for dem! 
42:5:15: Men ordet om ham kom ennu mere ut, og meget folk kom sammen for å høre og for å bli helbredet for sine sykdommer; 
42:5:16: men han drog sig tilbake til øde steder og var der i bønn. 
42:5:17: Og det skjedde en av dagene mens han lærte, og der satt fariseere og lovlærere som var kommet fra hver by i Galilea og Judea og fra Jerusalem, og Herrens kraft var hos ham til å helbrede: 
42:5:18: se, da kom nogen menn som bar en mann på en seng, han var verkbrudden; og de søkte å få ham inn og legge ham ned foran ham. 
42:5:19: Og da de for folketrengselen ikke fant nogen vei til å få ham inn, steg de op på taket og firte ham med sengen ned mellem takstenene midt foran Jesus. 
42:5:20: Og da han så deres tro, sa han: Menneske! dine synder er dig forlatt. 
42:5:21: Da begynte de skriftlærde og fariseerne å tenke så: Hvem er denne mann, som taler bespottelser? Hvem kan forlate synder uten Gud alene? 
42:5:22: Men da Jesus merket deres tanker, svarte han og sa til dem: Hvad er det I tenker i eders hjerter? 
42:5:23: Hvad er lettest, enten å si: Dine synder er dig forlatt, eller å si: Stå op og gå? 
42:5:24: Men forat I skal vite at Menneskesønnen har makt på jorden til å forlate synder - så sa han til den verkbrudne: Jeg sier dig: Stå op og ta din seng og gå hjem til ditt hus! 
42:5:25: Og straks stod han op for deres øine og tok det han lå på, og gikk hjem til sitt hus og priste Gud. 
42:5:26: Da kom det forferdelse over dem alle, og de priste Gud, og de blev fulle av frykt og sa: Idag har vi sett utrolige ting! 
42:5:27: Derefter gikk han ut, og han så en tolder ved navn Levi sitte på tollboden; og han sa til ham: Følg mig! 
42:5:28: Og han forlot alt og stod op og fulgte ham. 
42:5:29: Og Levi gjorde et stort gjestebud for ham i sitt hus, og der var en stor mengde toldere og andre som satt til bords med dem. 
42:5:30: Og fariseerne og deres skriftlærde knurret mot hans disipler og sa: Hvorfor eter og drikker I med toldere og syndere? 
42:5:31: Og Jesus svarte og sa til dem: De friske trenger ikke til læge, men de som har ondt; 
42:5:32: jeg er ikke kommet for å kalle rettferdige, men syndere til omvendelse. 
42:5:33: De sa til ham: Johannes' disipler holder jevnlig faste og bønn, og fariseernes disipler likeså; men dine eter og drikker. 
42:5:34: Men Jesus sa til dem: Kan I vel få brudesvennene til å faste så lenge brudgommen er hos dem? 
42:5:35: Men de dager skal komme da brudgommen blir tatt fra dem; da skal de faste, i de dager. 
42:5:36: Han sa også en lignelse til dem: Ingen river en lapp av et nytt klædebon og setter den på et gammelt; ellers river han det nye i sønder, og lappen av det nye passer ikke til det gamle. 
42:5:37: Og ingen fyller ny vin i gamle skinnsekker; ellers vil den nye vin sprenge sekkene, og den selv spilles og sekkene ødelegges; 
42:5:38: men ny vin skal fylles i nye skinnsekker. 
42:5:39: Og ingen som har drukket gammel vin, har lyst på ny; han sier: den gamle er god. 
42:6:1: Det skjedde på den næst-første sabbat at han gikk gjennem en aker, og hans disipler plukket aks og gned dem ut med hendene og åt. 
42:6:2: Da sa nogen av fariseerne: Hvorfor gjør I det som det ikke er tillatt å gjøre på sabbaten? 
42:6:3: Og Jesus svarte og sa til dem: Har I da ikke lest hvad David gjorde da han var sulten, han selv og de som var med ham, 
42:6:4: hvorledes han gikk inn i Guds hus og tok skuebrødene og åt, og gav også dem som var med ham? Og dem har ingen lov til å ete uten prestene alene. 
42:6:5: Og han sa til dem: Menneskesønnen er herre også over sabbaten. 
42:6:6: Og det skjedde på en annen sabbat at han gikk inn i synagogen og lærte; og der var en mann hvis høire hånd var vissen. 
42:6:7: Men de skriftlærde og fariseerne lurte på ham, om han vilde helbrede på sabbaten, forat de kunde finne klagemål imot ham. 
42:6:8: Men han visste deres tanker, og han sa til mannen som hadde den visne hånd: Stå op og kom frem! Og han stod op og trådte frem. 
42:6:9: Da sa Jesus til dem: Jeg spør eder om det er tillatt på sabbaten å gjøre godt eller å gjøre ondt, å berge liv eller å spille det? 
42:6:10: Og han så omkring på dem alle og sa til ham: Rekk din hånd ut! Og han gjorde så, og hans hånd blev frisk igjen. 
42:6:11: Men de blev rent rasende, og talte med hverandre om hvad de skulde gjøre med Jesus. 
42:6:12: Og det skjedde i de dager at han gikk op i fjellet for å bede, og han var der hele natten i bønn til Gud. 
42:6:13: Og da det blev dag, kalte han sine disipler til sig, og han valgte sig ut tolv av dem og gav dem navnet apostler: 
42:6:14: Simon, som han også gav navnet Peter, og hans bror Andreas, og Jakob og Johannes og Filip og Bartolomeus 
42:6:15: og Matteus og Tomas og Jakob, Alfeus' sønn, og Simon, som kaltes ivreren, 
42:6:16: og Judas, Jakobs sønn, og Judas Iskariot, som blev forræder. 
42:6:17: Og han gikk ned med dem, og han blev stående på en slette, og med ham en stor flokk av hans disipler og en stor mengde folk fra hele Judea og Jerusalem og fra havkanten ved Tyrus og Sidon, 
42:6:18: som var kommet for å høre ham og for å bli lægt for sine sykdommer; og de som var plaget av urene ånder, blev helbredet. 
42:6:19: Og alt folket søkte å få røre ved ham; for en kraft gikk ut fra ham og helbredet alle. 
42:6:20: Og han løftet sine øine på sine disipler og sa: Salige er I fattige; for Guds rike er eders. 
42:6:21: Salige er I som nu hungrer; for I skal mettes. Salige er I som nu gråter; for I skal le. 
42:6:22: Salige er I når menneskene hater eder, og når de ikke vil vite av eder, og spotter eder og kaster eders navn fra sig som noget ondt, for Menneskesønnens skyld. 
42:6:23: Gled eder på den dag og spring av fryd! for se, eders lønn er stor i himmelen; for på samme vis gjorde deres fedre med profetene. 
42:6:24: Men ve eder, I rike! for I har allerede fått eders trøst. 
42:6:25: Ve eder, I som nu er mette! for I skal hungre. Ve eder, I som nu ler! for I skal sørge og gråte. 
42:6:26: Ve eder når alle mennesker taler vel om eder! for på samme vis gjorde deres fedre med de falske profeter. 
42:6:27: Men til eder som hører, sier jeg: Elsk eders fiender, gjør vel imot dem som hater eder, 
42:6:28: velsign dem som forbanner eder, bed for dem som taler ille om eder! 
42:6:29: Når en slår dig på det ene kinn, så by og det andre frem, og når en tar din kappe fra dig, da nekt ham heller ikke kjortelen! 
42:6:30: Gi hver den som ber dig, og om en tar fra dig det som ditt er, da krev det ikke igjen! 
42:6:31: Og som I vil at menneskene skal gjøre imot eder, så skal og I gjøre imot dem. 
42:6:32: Og om I elsker dem som elsker eder, hvad er det å takke eder for? Også syndere elsker jo dem som dem elsker. 
42:6:33: Og om I gjør vel imot dem som gjør vel imot eder, hvad er det å takke eder for? Også syndere gjør det samme. 
42:6:34: Og om I låner til dem som I håper å få igjen av, hvad er det å takke eder for? Også syndere låner til syndere for å få like igjen. 
42:6:35: Men elsk eders fiender, og gjør vel og lån uten å vente noget igjen! så skal eders lønn være stor, og I skal være den Høiestes barn; for han er god mot de utakknemlige og onde. 
42:6:36: Vær barmhjertige, likesom eders Fader er barmhjertig, 
42:6:37: og døm ikke, så skal I ikke dømmes, og fordøm ikke, så skal I ikke fordømmes; forlat, så skal eder forlates; 
42:6:38: gi, så skal eder gis! et godt, stoppet, rystet, overfylt mål skal gis eder i fanget; for med det samme mål som I måler med, skal eder måles igjen. 
42:6:39: Han sa også en lignelse til dem: Kan en blind lede en blind? kommer de ikke begge til å falle i grøften? 
42:6:40: En disippel er ikke over sin mester, men enhver som er full-lært, blir som sin mester. 
42:6:41: Og hvorfor ser du splinten i din brors øie, men bjelken i ditt eget øie blir du ikke var? 
42:6:42: Hvorledes kan du si til din bror: Bror! la mig dra ut splinten som er i ditt øie, du som ikke ser bjelken i ditt eget øie? Du hykler! dra først bjelken ut av ditt eget øie, så kan du se å dra ut splinten som er i din brors øie! 
42:6:43: For det er ikke noget godt tre som bærer dårlig frukt, og heller ikke noget dårlig tre som bærer god frukt. 
42:6:44: For hvert tre kjennes på sin frukt; en sanker jo ikke fiken av tornebusker, og en plukker ikke vindruer av tornekratt. 
42:6:45: Et godt menneske bærer det gode frem av sitt hjertes gode forråd, og et ondt menneske bærer det onde frem av sitt onde forråd; for hvad hjertet flyter over av, det taler hans munn. 
42:6:46: Men hvorfor kaller I mig Herre, Herre, og gjør ikke det jeg sier? 
42:6:47: Hver den som kommer til mig og hører mine ord og gjør efter dem - hvem han er lik, vil jeg vise eder. 
42:6:48: Han er lik et menneske som skulde bygge et hus, og som gravde dypt ned og la grunnvollen på fjell; og da det blev flom, brøt strømmen imot det hus, og den var ikke i stand til å rokke det, fordi det var godt bygget. 
42:6:49: Men den som hører og ikke gjør derefter, han er lik et menneske som bygget sitt hus på bare jorden uten grunnvoll; og strømmen brøt imot det, og det falt straks, og det blev et stort fall da det hus falt. 
42:7:1: Da han hadde fullendt sin tale for folkets ører, gikk han inn i Kapernaum. 
42:7:2: Og en høvedsmann hadde en tjener som var syk og nær ved å dø, og han var ham meget kjær. 
42:7:3: Da han nu hørte om Jesus, sendte han nogen av jødenes eldste til ham og bad ham at han vilde komme og helbrede hans tjener. 
42:7:4: De kom da til Jesus, og bad ham inderlig og sa: Han er vel verd at du gjør dette for ham; 
42:7:5: for han elsker vårt folk, og det er han som har bygget synagogen for oss. 
42:7:6: Jesus gikk da med dem. Men da han ikke hadde langt igjen til huset, sendte høvedsmannen nogen venner til ham og lot si: Herre, umak dig ikke! for jeg er for ringe til at du skal gå inn under mitt tak; 
42:7:7: derfor aktet jeg mig heller ikke verdig til å gå til dig; men si et ord, så blir min dreng helbredet! 
42:7:8: For jeg er også en mann som står under overordnede, men har stridsmenn under mig igjen; og sier jeg til den ene: Gå! så går han, og til en annen: Kom! så kommer han, og til min tjener: Gjør dette! så gjør han det. 
42:7:9: Men da Jesus hørte dette, undret han sig over ham, og han vendte sig om og sa til folket som fulgte ham: Jeg sier eder: Ikke engang i Israel har jeg funnet så stor en tro. 
42:7:10: Og da de som var utsendt, kom hjem igjen, fant de den syke tjener aldeles frisk. 
42:7:11: Dagen derefter skjedde det at han drog til en by som heter Nain, og mange av hans disipler gikk med ham, og meget folk. 
42:7:12: Da han nu kom nær til byens port, se, da blev en død båret ut, som var sin mors eneste sønn, og hun var enke, og meget folk fra byen var med henne. 
42:7:13: Og da Herren så henne, ynkedes han inderlig over henne og sa til henne: Gråt ikke! 
42:7:14: Og han trådte til og rørte ved båren, og de som bar den, stod stille, og han sa: Du unge mann! jeg sier dig: Stå op! 
42:7:15: Og den døde reiste sig op og begynte å tale; og han gav ham til hans mor. 
42:7:16: Da kom det frykt over dem alle, og de priste Gud og sa: En stor profet er opreist blandt oss, og Gud har gjestet sitt folk. 
42:7:17: Og dette ord kom ut om ham i hele Judea og i hele landet deromkring. 
42:7:18: Og Johannes' disipler fortalte ham om alt dette. Da kalte Johannes to av sine disipler til sig, 
42:7:19: og sendte dem til Herren og lot si: Er du den som skal komme, eller skal vi vente en annen? 
42:7:20: Mennene kom da til ham og sa: Døperen Johannes har sendt oss til dig og lar si: Er du den som skal komme, eller skal vi vente en annen? 
42:7:21: I den samme stund helbredet han mange for sykdommer og plager og onde ånder, og mange blinde gav han synet. 
42:7:22: Og Jesus svarte og sa til dem: Gå bort og fortell Johannes det som I har sett og hørt: blinde ser, halte går, spedalske renses, døve hører, døde står op, og evangeliet forkynnes for fattige; 
42:7:23: og salig er den som ikke tar anstøt av mig. 
42:7:24: Da nu sendebudene fra Johannes var gått bort, begynte han å tale til folket om Johannes: Hvorfor var det I gikk ut i ørkenen? for å se et rør som svaier for vinden? 
42:7:25: Eller hvorfor var det I gikk der ut? for å se et menneske klædd i fine klær? Se, de som går prektig klædd og lever i vellevnet, er i kongenes saler. 
42:7:26: Eller hvorfor var det I gikk der ut? for å se en profet? Ja, jeg sier eder, endog mere enn en profet. 
42:7:27: Det er ham det er skrevet om: Se, jeg sender mitt bud for ditt åsyn; han skal rydde din vei for dig. 
42:7:28: Jeg sier eder: Større profet enn Johannes er ingen blandt dem som er født av kvinner; men den minste i Guds rike er større enn han. 
42:7:29: Og alt folket som hørte ham, og tolderne, de gav Gud rett og lot sig døpe med Johannes' dåp; 
42:7:30: men fariseerne og de lovkyndige gjorde Guds råd til intet for sig og lot sig ikke døpe av ham. 
42:7:31: Hvem skal jeg da ligne denne slekts mennesker med, og hvem er de like? 
42:7:32: De ligner små barn som sitter på torvet og roper til hverandre og sier: Vi blåste på fløite for eder, og I vilde ikke danse, vi sang sørgesanger, og I vilde ikke gråte. 
42:7:33: For døperen Johannes er kommet; han hverken åt brød eller drakk vin, og I sier: Han er besatt! 
42:7:34: Menneskesønnen er kommet; han eter og drikker, og I sier: Se, for en storeter og vindrikker, tolderes og synderes venn! 
42:7:35: Og visdommen er rettferdiggjort av alle sine barn. 
42:7:36: Men en av fariseerne bad ham at han vilde ete hos ham; og han gikk inn i fariseerens hus og satte sig til bords. 
42:7:37: Og se, det var en kvinne der i byen, som var en synderinne, og da hun fikk vite at han satt til bords i fariseerens hus, kom hun med en alabaster-krukke med salve 
42:7:38: og stod bak ved hans føtter og gråt, og begynte å væte hans føtter med sine tårer og tørket dem med sitt hår og kysset hans føtter og salvet dem med salven. 
42:7:39: Men da fariseeren som hadde innbudt ham, så det, sa han ved sig selv: Var denne mann en profet, da visste han hvem og hvordan denne kvinne er som rører ved ham, at hun er en synderinne. 
42:7:40: Og Jesus svarte og sa til ham: Simon! jeg har noget å si dig. Han sier: Mester, si frem! 
42:7:41: En som lånte ut penger, hadde to skyldnere; den ene skyldte ham fem hundre penninger, og den andre femti; 
42:7:42: da de ikke hadde noget å betale med, eftergav han dem begge gjelden. Hvem av dem vil da elske ham mest? 
42:7:43: Simon svarte: Jeg tenker: Den han eftergav mest. Da sa han til ham: Du dømte rett. 
42:7:44: Og han vendte sig mot kvinnen og sa til Simon: Ser du denne kvinne? Jeg kom inn i ditt hus, du gav mig ikke vann til mine føtter, men hun vætte mine føtter med sine tårer og tørket dem med sitt hår; 
42:7:45: du gav mig ikke noget kyss, men hun holdt ikke op å kysse mine føtter fra den stund jeg kom inn; 
42:7:46: du salvet ikke mitt hode med olje, men hun salvet mine føtter med salve. 
42:7:47: Derfor sier jeg dig: Hennes mange synder er henne forlatt, for hun elsket meget; men den som lite forlates, elsker lite. 
42:7:48: Og han sa til henne: Dine synder er dig forlatt. 
42:7:49: Da begynte de som satt til bords med ham, å si ved sig selv: Hvem er denne mann, som endog forlater synder? 
42:7:50: Men han sa til kvinnen: Din tro har frelst dig; gå bort i fred! 
42:8:1: Og det skjedde derefter at han drog omkring fra by til by og fra landsby til landsby og forkynte evangeliet om Guds rike, og de tolv var med ham, 
42:8:2: og likeså nogen kvinner som var helbredet for onde ånder og sykdommer: Maria med tilnavnet Magdalena, som syv onde ånder var faret ut av, 
42:8:3: og Johanna, som var gift med Kuzas, Herodes' foged, og Susanna og mange andre, som tjente dem med det de eide. 
42:8:4: Da nu meget folk strømmet sammen, og de som bodde omkring i byene, drog ut til ham, sa han i en lignelse: 
42:8:5: En såmann gikk ut for å så sin sæd, og da han sådde, falt noget ved veien; og det blev trådt ned, og himmelens fugler åt det op. 
42:8:6: Og noget falt på stengrunn; og da det var vokset op, visnet det, fordi det ikke hadde væte. 
42:8:7: Og noget falt midt iblandt torner; og tornene vokste op med og kvalte det. 
42:8:8: Og noget falt i god jord; og det vokste op og bar frukt i hundre fold. Da han sa dette, ropte han: Den som har ører å høre med, han høre! 
42:8:9: Men hans disipler spurte ham hvad denne lignelse skulde bety. 
42:8:10: Han sa da: Eder er det gitt å få vite Guds rikes hemmeligheter; men de andre gis det i lignelser, forat de skal se og dog ikke se, og høre og dog ikke forstå. 
42:8:11: Men dette er lignelsen: Sæden er Guds ord. 
42:8:12: De ved veien er de som hører det; så kommer djevelen og tar ordet bort fra deres hjerte, forat de ikke skal tro og bli frelst. 
42:8:13: De på stengrunn er de som tar imot ordet med glede når de hører det; men de har ikke rot; de tror til en tid, og i prøvelsens stund faller de fra. 
42:8:14: Det som falt iblandt torner, det er de som hører, og mens de vandrer under bekymringer og rikdom og livets lyst, kveles de, og bærer ikke fullmoden frukt. 
42:8:15: Men det i den gode jord, det er de som hører ordet og holder det fast i et vakkert og godt hjerte og bærer frukt i tålmodighet. 
42:8:16: Men ingen som tender et lys, skjuler det med et kar eller setter det under en seng; han setter det i en stake, forat de som kommer inn, kan se schmelken. 
42:8:17: For det er ingen ting skjult som ikke skal bli åpenbar, og ingen ting dulgt som ikke skal bli kjent og komme for dagen. 
42:8:18: Se derfor til hvorledes I hører! for den som har, ham skal gis, og den som ikke har, fra ham skal endog tas det han tykkes sig å ha. 
42:8:19: Men hans mor og hans brødre kom til ham, og de kunde ikke nå frem til ham for folket. 
42:8:20: Det blev da meldt ham: Din mor og dine brødre står utenfor og vil gjerne få se dig. 
42:8:21: Men han svarte og sa til dem: Min mor og mine brødre er disse som hører Guds ord og gjør efter det. 
42:8:22: Og det skjedde en av dagene at han gikk ut i en båt, og hans disipler med ham, og han sa til dem: La oss fare over til hin side av sjøen! Og de la fra land. 
42:8:23: Men mens de seilte, sovnet han; og det fór en stormvind ned over sjøen, og båten fyltes, og de var i fare. 
42:8:24: Da gikk de til ham og vekket ham op og sa: Mester! mester! vi går under! Men han stod op og truet vinden og bølgene; og de la sig, og det blev blikkstille. 
42:8:25: Og han sa til dem: Hvor er eders tro? Men de blev forferdet og undret sig og sa til hverandre: Hvad er da dette for en, som endog byder vindene og vannet, og de er ham lydige? 
42:8:26: Og de seilte frem til gergesenernes bygd, som er rett imot Galilea. 
42:8:27: Da han var gått i land, møtte det ham en mann fra byen, som var besatt av onde ånder; han hadde ikke hatt klær på sig i lang tid, og han holdt sig ikke i hus, men i gravene. 
42:8:28: Da han så Jesus, satte han i et skrik og falt ned for ham og sa med høi røst: Hvad har jeg med dig å gjøre, Jesus, du den høieste Guds Sønn? Jeg ber dig, pin mig ikke! 
42:8:29: For han bød den urene ånd fare ut av mannen; i lange tider hadde den revet ham med sig, og han blev bundet med lenker og fotjern og holdt under vakt; og han sønderrev båndene og blev drevet av den onde ånd ut i ørkenene. 
42:8:30: Men Jesus spurte ham: Hvad er ditt navn? Han svarte: Legion! For mange onde ånder var faret i ham. 
42:8:31: Og de bad ham at han ikke vilde byde dem fare ned i avgrunnen. 
42:8:32: Men det var på stedet en stor svinehjord, som gikk og beitet i fellet, og de bad ham at han vilde gi dem lov til å fare inn i dem; og han gav dem lov. 
42:8:33: Så fór da de onde ånder ut av mannen og inn i svinene; og hjorden styrtet sig ut over stupet ned i sjøen og druknet. 
42:8:34: Men da gjæterne så det som hadde hendt, tok de flukten, og fortalte det i byen og i bygden. 
42:8:35: Folk kom da ut for å se hvad som hadde hendt, og de kom til Jesus, og fant mannen som de onde ånder var faret ut av, sittende påklædd og ved sans og samling ved Jesu føtter, og de blev forferdet. 
42:8:36: Men de som hadde sett det, fortalte dem hvorledes den besatte var blitt frelst. 
42:8:37: Og hele folkemengden i gergesenernes bygd bad ham dra bort fra dem; for det var en stor frykt over dem. Og han gikk i båten og vendte tilbake igjen. 
42:8:38: Mannen som de onde ånder var faret ut av, bad om å få være med ham. Men han sendte ham fra sig og sa: 
42:8:39: Gå hjem til ditt hus og fortell hvor store ting Gud har gjort imot dig! Og han gikk bort og kunngjorde over hele byen hvor store ting Jesus hadde gjort imot ham. 
42:8:40: Men da Jesus kom tilbake, tok folket imot ham; for alle ventet på ham. 
42:8:41: Og se, det kom en mann ved navn Jairus, som var forstander for synagogen, og han falt ned for Jesu føtter og bad ham komme inn i hans hus; 
42:8:42: for han hadde en eneste datter, omkring tolv år gammel, og hun lå for døden. Da nu Jesus gikk avsted, trengte folket sig inn på ham; 
42:8:43: og en kvinne som hadde hatt blodsott i tolv år og hadde kostet på læger alt det hun skulde leve av, og ikke kunnet bli helbredet av nogen, 
42:8:44: hun trådte til bakfra og rørte ved det ytterste av hans klædebon, og straks stanset hennes blodsott. 
42:8:45: Og Jesus sa: Hvem var det som rørte ved mig? Men da alle nektet, sa Peter og de som var med ham: Mester! Folket trykker og trenger dig jo! 
42:8:46: Men Jesus sa: Det var nogen som rørte ved mig; for jeg kjente at en kraft gikk ut fra mig. 
42:8:47: Da nu kvinnen så at det ikke var skjult det hun hadde gjort, kom hun skjelvende og falt ned for ham og fortalte ham, så hele folket hørte det, av hvad grunn hun rørte ved ham, og hvorledes hun straks blev helbredet. 
42:8:48: Da sa han til henne: Datter! din tro har frelst dig; gå bort i fred! 
42:8:49: Mens han ennu taler, kommer en fra synagoge-forstanderen og sier til ham: Din datter er død; umak ikke mesteren! 
42:8:50: Men da Jesus hørte det, svarte han ham: Frykt ikke, bare tro, så skal hun bli frelst! 
42:8:51: Da han nu kom inn i huset, lot han ingen gå inn med sig uten Peter og Johannes og Jakob og pikens far og mor. 
42:8:52: Og alle gråt og jamret sig over henne; men han sa: Gråt ikke! hun er ikke død, hun sover. 
42:8:53: Og de lo ham ut; for de visste at hun var død. 
42:8:54: Men han tok henne ved hånden og ropte: Pike, stå op! 
42:8:55: Da vendte hennes ånd tilbake, og hun stod straks op; og han bød at de skulde gi henne mat. 
42:8:56: Og hennes foreldre blev forferdet; men han bød dem at de ikke skulde tale til nogen om det som var skjedd. 
42:9:1: Og han kalte de tolv sammen og gav dem makt og myndighet over alle de onde ånder og til å helbrede sykdommer, 
42:9:2: og han sendte dem ut for å forkynne Guds rike og helbrede syke. 
42:9:3: Og han sa til dem: Ta ikke noget med på veien, hverken stav eller skreppe eller brød eller penger! heller ikke skal I ha to kjortler hver. 
42:9:4: Og i det hus I kommer inn i, der skal I bli, og fra det skal I dra videre. 
42:9:5: Og hvert sted hvor de ikke tar imot eder, gå ut av den by og ryst støvet av eders føtter til et vidnesbyrd mot dem! 
42:9:6: Så gikk de ut og drog omkring fra by til by og forkynte evangeliet og helbredet folk allesteds. 
42:9:7: Men fjerdingsfyrsten Herodes fikk høre om alt det som skjedde, og han var i stor tvil, fordi det blev sagt av nogen at Johannes var opstanden fra de døde, 
42:9:8: men av andre at Elias hadde vist sig, men av andre igjen at en profet, en av de gamle, var opstanden. 
42:9:9: Og Herodes sa: Johannes lot jeg halshugge; men hvem er denne, som jeg hører sådant om? Og han søkte å få se ham. 
42:9:10: Og apostlene kom tilbake og fortalte ham alt det de hadde gjort; og han tok dem med sig og drog avsides til en by som heter Betsaida. 
42:9:11: Men da folket fikk det å vite, fulgte de efter ham; og han tok imot dem og talte til dem om Guds rike, og helbredet dem som trengte til lægedom. 
42:9:12: Men dagen begynte å helle; da gikk de tolv til ham og sa: La folket fare, så de kan gå bort i byene og bygdene heromkring og få herberge og finne føde! for her er vi på et øde sted. 
42:9:13: Han sa da til dem: Gi I dem å ete! Men de sa: Vi har ikke mere enn fem brød og to fisker, medmindre vi skulde gå bort og kjøpe mat til alt dette folk. 
42:9:14: For det var omkring fem tusen menn. Da sa han til sine disipler: La dem sette sig ned i lag på femti mann! 
42:9:15: Og de gjorde så og lot alle sette sig ned. 
42:9:16: Da tok han de fem brød og de to fisker, så op mot himmelen og velsignet dem, og han brøt dem og gav dem til disiplene, forat de skulde dele ut til folket. 
42:9:17: Og de åt og blev mette alle sammen; og da de tok op de stykker som var blitt tilovers efter dem, blev det tolv kurver. 
42:9:18: Og det skjedde engang mens han bad, og disiplene var alene med ham, da spurte han dem og sa: Hvem sier folket at jeg er? 
42:9:19: De svarte: Døperen Johannes; andre at du er Elias; andre igjen at en profet, en av de gamle, er opstanden. 
42:9:20: Han sa til dem: Men I, hvem sier I at jeg er? Da svarte Peter: Guds Messias. 
42:9:21: Men han bød dem strengt at de ikke skulde si dette til nogen, 
42:9:22: og han sa: Menneskesønnen skal lide meget og forkastes av de eldste og yppersteprestene og de skriftlærde og slåes ihjel, og opstå på den tredje dag. 
42:9:23: Og han sa til alle: Vil nogen komme efter mig, da må han fornekte sig selv og hver dag ta sitt kors op og følge mig. 
42:9:24: For den som vil berge sitt liv, skal miste det, men den som mister sitt liv for min skyld, han skal berge det. 
42:9:25: For hvad gagner det et menneske om han vinner den hele verden, men mister sig selv eller tar skade på sig selv? 
42:9:26: For den som skammer sig ved mig og mine ord, ham skal også Menneskesønnen skamme sig ved når han kommer i sin og sin Faders og de hellige englers herlighet. 
42:9:27: Jeg sier eder for sant: Nogen av dem som her står, skal ikke smake døden før de ser Guds rike. 
42:9:28: Og det skjedde omkring åtte dager efterat han hadde talt dette, at han tok med sig Peter og Johannes og Jakob og gikk op i fjellet for å bede. 
42:9:29: Og mens han bad, blev hans åsyn anderledes å se til, og hans klædning blev hvit og skinte som lynet. 
42:9:30: Og se, to menn talte med ham, og det var Moses og Elias; 
42:9:31: de viste sig i herlighet og talte om hans bortgang, som han skulde fullbyrde i Jerusalem. 
42:9:32: Peter og de som var med ham, var tunge av søvn; men da de blev fullt våkne, så de hans herlighet og de to menn som stod hos ham. 
42:9:33: Og det skjedde da de skiltes fra ham, da sa Peter til Jesus: Mester! det er godt at vi er her; la oss gjøre tre boliger, en til dig, og en til Moses, og en til Elias! - han visste ikke hvad han sa. 
42:9:34: Som han sa dette, kom en sky og overskygget dem; og de blev forferdet da de kom inn i skyen. 
42:9:35: Og det kom en røst ut av skyen: Dette er min Sønn, den utvalgte; ham skal I høre! 
42:9:36: Og da røsten kom, var ingen å se, uten Jesus alene. Og de tidde stille og fortalte ingen i de dager noget av det som de hadde sett. 
42:9:37: Og det skjedde dagen derefter, da de kom ned fra fjellet, at meget folk kom ham i møte. 
42:9:38: Og se, en mann av folket ropte: Mester! jeg ber dig: Se til min sønn! for han er min eneste, 
42:9:39: og se, en ånd griper ham, og straks setter han i et skrik, og den sliter i ham så han fråder; og det er så vidt den slipper ham, og ille farer den da med ham; 
42:9:40: og jeg bad dine disipler at de skulde drive den ut, men de kunde ikke. 
42:9:41: Jesus svarte og sa: Du vantro og vrange slekt! hvor lenge skal jeg være hos eder og tåle eder? Før din sønn hit! 
42:9:42: Ennu mens han var på veien til ham, rev og slet den onde ånd i ham; men Jesus truet den urene ånd og helbredet gutten og gav ham tilbake til hans far. 
42:9:43: Og alle var slått av forundring over Guds storhet. 
42:9:44: Men mens alle undret sig over alt det han gjorde, sa han til sine disipler: Gjem disse ord i eders ører: Menneskesønnen skal overgis i menneskers hender! 
42:9:45: Men de skjønte ikke dette ord, og det var skjult for dem, så de ikke fattet det, og de fryktet for å spørre ham om dette ord. 
42:9:46: Men det kom en tanke op i dem om hvem som vel var den største av dem. 
42:9:47: Men da Jesus så deres hjertes tanke, tok han et lite barn og stilte det ved siden av sig 
42:9:48: og sa til dem: Den som tar imot dette lille barn for mitt navns skyld, tar imot mig, og den som tar imot mig, tar imot ham som sendte mig; for den som er den minste iblandt eder alle, han er stor. 
42:9:49: Da svarte Johannes og sa: Mester! vi så en som drev ut onde ånder i ditt navn, og vi forbød ham det, fordi han ikke er i følge med oss. 
42:9:50: Og Jesus sa til ham: Forbyd det ikke! for den som ikke er imot eder, er med eder. 
42:9:51: Og det skjedde da det led mot tiden at han skulde optas, da vendte han sitt åsyn mot Jerusalem for å dra der op, 
42:9:52: og han sendte bud foran sig. De gikk da avsted, og kom inn i en samaritansk by for å gjøre i stand for ham; 
42:9:53: og de tok ikke imot ham, fordi hans åsyn var vendt mot Jerusalem. 
42:9:54: Da hans disipler Jakob og Johannes så det, sa de: Herre! vil du vi skal byde ild fare ned fra himmelen og fortære dem, likesom Elias gjorde? 
42:9:55: Men han vendte sig om og talte strengt til dem og sa: I vet ikke av hvad ånd I er. 
42:9:56: For Menneskesønnen er ikke kommet for å ødelegge menneskeliv, men for å frelse. Og de gikk til en annen by. 
42:9:57: Og det skjedde mens de vandret på veien, da sa en til ham: Jeg vil følge dig hvorhen du går. 
42:9:58: Og Jesus sa til ham: Revene har huler, og himmelens fugler reder; men Menneskesønnen har ikke det han kan helle sitt hode til. 
42:9:59: Han sa til en annen: Følg mig! Men han sa: Herre! gi mig først lov til å gå bort og begrave min far! 
42:9:60: Men han sa til ham: La de døde begrave sine døde; men gå du avsted og forkynn Guds rike! 
42:9:61: Også en annen sa: Jeg vil følge dig, Herre! men gi mig først lov til å si farvel til dem der hjemme! 
42:9:62: Men Jesus sa til ham: Ingen som legger sin hånd på plogen og ser sig tilbake, er skikket for Guds rike. 
42:10:1: Siden utvalgte Herren også sytti andre og sendte dem ut, to og to, i forveien for sig til hver by og hvert sted hvor han selv skulde komme. 
42:10:2: Og han sa til dem: Høsten er stor, men arbeiderne få; bed derfor høstens herre at han vil drive arbeidere ut til sin høst! 
42:10:3: Gå avsted! Se, jeg sender eder som lam midt iblandt ulver. 
42:10:4: Bær ikke pung, ikke skreppe, ikke sko; gi eder ikke i tale med nogen på veien! 
42:10:5: Men hvor I kommer inn i et hus, der skal I først si: Fred være med dette hus! 
42:10:6: Og er det et fredens barn der, da skal eders fred hvile over ham, men hvis ikke, da skal den vende tilbake over eder. 
42:10:7: Men bli der i huset, og et og drikk hvad de byr eder! for arbeideren er sin lønn verd. I skal ikke flytte fra hus til hus. 
42:10:8: Og hvor I kommer inn i en by og de tar imot eder, der skal I ete hvad de setter frem for eder, 
42:10:9: og helbred de syke som er der, og si til dem: Guds rike er kommet nær til eder! 
42:10:10: Men hvor I kommer inn i en by og de ikke tar imot eder, der skal I gå ut på dens gater og si: 
42:10:11: Endog støvet som er blitt hengende ved våre føtter av eders by, stryker vi av til eder; men dette skal I vite at Guds rike er kommet nær! 
42:10:12: Jeg sier eder at det skal gå Sodoma tåleligere på hin dag enn den by. 
42:10:13: Ve dig, Korasin! ve dig, Betsaida! for dersom de kraftige gjerninger som er gjort i eder, var gjort i Tyrus og Sidon, da hadde de for lenge siden omvendt sig og sittet i sekk og aske. 
42:10:14: Dog, det skal gå Tyrus og Sidon tåleligere i dommen enn eder. 
42:10:15: Og du, Kapernaum, som er blitt ophøiet like til himmelen! like til dødsriket skal du bli nedstøtt. 
42:10:16: Den som hører eder, hører mig, og den som forkaster eder, forkaster mig, og den som forkaster mig, forkaster ham som sendte mig. 
42:10:17: Og de sytti kom glade tilbake og sa: Herre! endog de onde ånder er oss lydige i ditt navn! 
42:10:18: Da sa han til dem: Jeg så Satan falle ned fra himmelen som et lyn. 
42:10:19: Se, jeg har gitt eder makt til å trede på slanger og skorpioner og over alt fiendens velde, og ingen ting skal skade eder; 
42:10:20: dog, gled eder ikke over dette at åndene er eder lydige, men gled eder over at eders navn er opskrevet i himmelen! 
42:10:21: I den samme stund frydet han sig i den Hellige Ånd og sa: Jeg priser dig, Fader, himmelens og jordens herre, fordi du har skjult dette for de vise og forstandige og åpenbaret det for de umyndige; ja, Fader, fordi således skjedde det som var velbehagelig for dig. 
42:10:22: Alle ting er mig overgitt av min Fader, og ingen kjenner til hvem Sønnen er, uten Faderen, og hvem Faderen er, uten Sønnen og den som Sønnen vil åpenbare det for. 
42:10:23: Og han vendte sig særlig til sine disipler og sa: Salige er de øine som ser det I ser; 
42:10:24: for jeg sier eder: Mange profeter og konger har villet se det I ser, og har ikke fått se det, og høre det I hører, og har ikke fått høre det. 
42:10:25: Og se, en lovkyndig stod op og fristet ham og sa: Mester! hvad skal jeg gjøre forat jeg kan arve evig liv? 
42:10:26: Han sa til ham: Hvad er skrevet i loven? hvorledes leser du? 
42:10:27: Han svarte og sa: Du skal elske Herren din Gud av alt ditt hjerte og av all din sjel og av all din makt og av all din hu, og din næste som dig selv. 
42:10:28: Da sa han til ham: Du svarte rett; gjør dette, så skal du leve! 
42:10:29: Men da han vilde gjøre sig selv rettferdig, sa han til Jesus: Hvem er da min næste? 
42:10:30: Jesus svarte og sa: En mann gikk ned fra Jerusalem til Jeriko, og han falt iblandt røvere, som både klædde ham av og slo ham og gikk bort og lot ham ligge halvdød. 
42:10:31: Men det traff sig så at en prest drog samme vei ned, og han så ham, og gikk like forbi. 
42:10:32: Likeså en levitt; han kom til stedet, gikk frem og så ham, og gikk like forbi. 
42:10:33: Men en samaritan som var på reise, kom dit hvor han var, og da han så ham, ynkedes han inderlig, 
42:10:34: og han gikk bort til ham og forbandt hans sår og helte olje og vin i dem, og han løftet ham op på sitt eget dyr og førte ham til et herberge og pleide ham. 
42:10:35: Og da det led mot næste dag, tok han to penninger frem og gav verten og sa til ham: Plei ham! og hvad mere du måtte koste på ham, det skal jeg betale dig igjen når jeg kommer tilbake. 
42:10:36: Hvem av disse tre synes du nu viste sig som den manns næste som var falt iblandt røverne? 
42:10:37: Han sa: Den som gjorde barmhjertighet imot ham. Da sa Jesus til ham: Gå du bort og gjør likeså! 
42:10:38: Og det skjedde mens de var på vandring, at han kom til en by, og en kvinne ved navn Marta tok imot ham i sitt hus. 
42:10:39: Og hun hadde en søster som hette Maria; hun satte sig ved Jesu føtter og hørte på hans ord. 
42:10:40: Men Marta hadde det meget travelt med å tjene ham; hun gikk da frem og sa: Herre! bryr du dig ikke om at min søster har latt mig bli alene om å tjene dig? Si da til henne at hun skal hjelpe mig! 
42:10:41: Men Jesus svarte og sa til henne: Marta! Marta! du gjør dig strev og uro med mange ting; 
42:10:42: men ett er nødvendig. Maria har valgt den gode del, som ikke skal tas fra henne. 
42:11:1: Og det skjedde at han var ensteds og bad; og da han holdt op, sa en av hans disipler til ham: Herre! lær oss å bede, likesom Johannes lærte sine disipler! 
42:11:2: Da sa han til dem: Når I beder, skal I si: Fader vår, du som er i himmelen! Helliget vorde ditt navn; komme ditt rike; skje din vilje, som i himmelen, så og på jorden; 
42:11:3: gi oss hver dag vårt daglige brød; 
42:11:4: og forlat oss våre synder, for også vi forlater hver den som er oss skyldig; og led oss ikke inn i fristelse; men fri oss fra det onde. 
42:11:5: Og han sa til dem: Om nogen av eder har en venn og kommer til ham midt på natten og sier til ham: Kjære, lån mig tre brød! 
42:11:6: for en venn er kommet til mig fra reisen, og jeg har ikke noget å sette frem for ham 
42:11:7: - skulde da han der inne svare: Gjør mig ikke uleilighet! Døren er alt lukket, og mine småbarn er i seng med mig; jeg kan ikke stå op og gi dig det? 
42:11:8: Jeg sier eder: Om han ikke står op og gir ham det fordi han er hans venn, så vil han stå op for hans ubluhets skyld og gi ham alt det han trenger til. 
42:11:9: Og jeg sier eder: Bed, så skal eder gis; let, så skal I finne; bank på, så skal det lukkes op for eder! 
42:11:10: For hver den som beder, han får, og den som leter, han finner, og den som banker på, for ham skal det lukkes op. 
42:11:11: Men hvem av eder som er far, vil gi sin sønn en sten når han ber om brød, eller når han ber om en fisk, gi ham en orm i stedet for fisken, 
42:11:12: eller når han ber om et egg, gi ham en skorpion? 
42:11:13: Dersom da I, som er onde, vet å gi eders barn gode gaver, hvor meget mere skal da Faderen, som er i himmelen, gi dem den Hellige Ånd som beder ham! 
42:11:14: Og han drev ut en ond ånd, og den var stum; men det skjedde da den onde ånd var faret ut, da talte den stumme. Og folket undret sig. 
42:11:15: Men nogen av dem sa: Det er ved Be'elsebul, de onde ånders fyrste, han driver de onde ånder ut. 
42:11:16: Andre igjen fristet ham og krevde et tegn fra himmelen av ham. 
42:11:17: Men da han visste deres tanker, sa han til dem: Hvert rike som kommer i strid med sig selv, legges øde, og hus faller på hus. 
42:11:18: Men er nu også Satan kommet i strid med sig selv, hvorledes kan da hans rike bli stående? I sier jo at jeg driver de onde ånder ut ved Be'elsebul. 
42:11:19: Men driver jeg de onde ånder ut ved Be'elsebul, ved hvem er det da eders barn driver dem ut? Derfor skal de være eders dommere. 
42:11:20: Men er det ved Guds finger jeg driver de onde ånder ut, da er jo Guds rike kommet til eder. 
42:11:21: Når den sterke med våben vokter sin egen gård, da får hans eiendom være i fred; 
42:11:22: men når en som er sterkere enn han, kommer over ham og overvinner ham, da tar han hans fulle rustning, som han hadde satt sin lit til, og utdeler det rov han har tatt fra ham. 
42:11:23: Den som ikke er med mig, han er imot mig, og den som ikke samler med mig, han spreder. 
42:11:24: Når den urene ånd er faret ut av et menneske, går den gjennem tørre steder og søker hvile, og når den ikke finner det, sier den: Jeg vil vende tilbake til mitt hus som jeg fór ut av. 
42:11:25: Og når den kommer dit, finner den det feiet og pyntet. 
42:11:26: Så går den bort og tar med sig syv andre ånder, verre enn den selv, og de går inn og bor der, og det siste blir verre med det menneske enn det første. 
42:11:27: Og det skjedde da han sa dette, at en kvinne blandt folket løftet sin røst og sa til ham: Salig er det liv som bar dig, og det bryst som du diet. 
42:11:28: Men han sa: Ja, salige er de som hører Guds ord og bevarer det. 
42:11:29: Da nu folket strømmet til, tok han til orde: Denne slekt er en ond slekt; den krever tegn, og tegn skal ikke gis den, uten Jonas' tegn. 
42:11:30: For likesom Jonas blev et tegn for folket i Ninive, således skal også Menneskesønnen bli det for denne slekt. 
42:11:31: Dronningen fra Syden skal stå op på dommens dag sammen med mennene av denne slekt og fordømme dem; for hun kom fra jordens ende for å høre Salomos visdom, og se, her er mere enn Salomo. 
42:11:32: Ninives menn skal stå op på dommens dag sammen med denne slekt og fordømme den; for de omvendte sig ved Jonas' forkynnelse, og se, her er mere enn Jonas. 
42:11:33: Ingen som tender et lys, setter det i kjelleren eller under en skjeppe, men i staken, forat de som kommer inn, skal se skinnet av det. 
42:11:34: Ditt øie er legemets schmelk; når ditt øie er friskt, da er også hele ditt legeme lyst; men er det sykt, da er også ditt legeme mørkt. 
42:11:35: Se derfor til at schmelken i dig ikke er mørke! 
42:11:36: Dersom altså hele ditt legeme er lyst og ikke har nogen del som er mørk, da først blir det rett lyst helt igjennem, som når schmelken lyser på dig med strålende skinn. 
42:11:37: Men da han hadde talt, bad en fariseer ham at han vilde ete hos ham; han gikk da inn og satte sig til bords. 
42:11:38: Men da fariseeren så at han ikke først vasket sig før måltidet, undret han sig. 
42:11:39: Da sa Herren til ham: I fariseere renser nu beger og fat utvendig; men eders indre er fullt av rov og ondskap. 
42:11:40: I dårer! han som gjorde det utvendige, har ikke han også gjort det innvendige? 
42:11:41: Men gi det som er inneni, til almisse, og se, da er alt rent for eder. 
42:11:42: Men ve eder, I fariseere, I som gir tiende av mynte og rute og alle slags maturter, og ikke spør efter rett og kjærlighet til Gud! Dette burde gjøres, og det andre ikke lates ugjort. 
42:11:43: Ve eder, I fariseere, I som så gjerne vil ha de øverste seter i synagogene og hilsninger på torvene! 
42:11:44: Ve eder, I som ligner de ukjennelige graver, som menneskene går omkring på uten å vite av det! 
42:11:45: Da svarte en av de lovkyndige og sa til ham: Mester! ved å si dette krenker du også oss. 
42:11:46: Men han sa: Ve også eder, I lovkyndige, I som lesser byrder på menneskene, som de vanskelig kan bære, og selv rører I ikke byrdene med én av eders fingrer! 
42:11:47: Ve eder, I som bygger gravsteder for profetene, og eders fedre slo dem ihjel! 
42:11:48: Så gir I da eders fedres gjerninger vidnesbyrd og samtykke; for de slo dem ihjel, og I bygger. 
42:11:49: Derfor sa også Guds visdom: Jeg vil sende profeter og apostler til dem, og nogen av dem skal de slå ihjel, og nogen skal de forfølge, 
42:11:50: forat alle profeters blod, som er utøst fra verdens grunnvoll blev lagt, skal bli krevd av denne slekt, 
42:11:51: fra Abels blod til Sakarias' blod, han som blev drept mellem alteret og templet. Ja, sier jeg eder, det skal bli krevd av denne slekt. 
42:11:52: Ve eder, I lovkyndige, I som har tatt kunnskapens nøkkel til eder! Selv er I ikke gått inn, og dem som var i ferd med å gå inn, har I hindret. 
42:11:53: Og da han gikk ut derfra, begynte de skriftlærde og fariseerne å trenge hårdt inn på ham og å spørre ham ut om mangt og meget, 
42:11:54: for de lurte på ham for å lokke noget ut av hans munn. 
42:12:1: Da folket imens hadde samlet sig i tusentall, så de trådte på hverandre, tok han til orde og sa til sine disipler: Ta eder først og fremst i vare for fariseernes surdeig, som er hykleri! 
42:12:2: Men intet er skjult som ikke skal bli åpenbaret, og intet er dulgt som ikke skal bli kjent; 
42:12:3: derfor skal alt det som I sier i mørket, bli hørt i schmelken, og det som I hvisker i øret inne i kammerne, det skal bli forkynt på takene. 
42:12:4: Men jeg sier til eder, mine venner: Frykt ikke for dem som slår legemet ihjel og derefter ikke kan gjøre mere; 
42:12:5: men jeg vil vise eder hvem I skal frykte for: Frykt for ham som har makt både til å slå ihjel og til derefter å kaste i helvede! Ja, sier jeg eder, for ham skal I frykte. 
42:12:6: Selges ikke fem spurver for to øre? Og ikke én av dem er glemt hos Gud. 
42:12:7: Men endog hårene på eders hode er tellet alle sammen; frykt ikke! I er mere enn mange spurver. 
42:12:8: Men jeg sier eder: Hver den som kjennes ved mig for menneskene, ham skal også Menneskesønnen kjennes ved for Guds engler; 
42:12:9: men den som fornekter mig for menneskene, han skal fornektes for Guds engler. 
42:12:10: Og hver den som taler et ord mot Menneskesønnen, ham skal det bli forlatt; men den som taler bespottelig mot den Hellige Ånd, ham skal det ikke bli forlatt. 
42:12:11: Men når de fører eder frem for synagogene og øvrighetene og myndighetene, da vær ikke bekymret for hvorledes eller hvad I skal svare for eder, eller hvad I skal si; 
42:12:12: for den Hellige Ånd skal lære eder i samme stund hvad I skal si. 
42:12:13: Og en av folket sa til ham: Mester! si til min bror at han skal skifte arven med mig! 
42:12:14: Men han sa til ham: Menneske! hvem har satt mig til dommer eller skifter over eder? 
42:12:15: Og han sa til dem: Se til og ta eder i vare for all havesyke! for ingen har sitt liv av sitt gods, om han er nokså rik. 
42:12:16: Og han fortalte dem en lignelse og sa: Der var en rik mann hvis jord bar godt; 
42:12:17: og han tenkte ved sig selv: Hvad skal jeg gjøre? jeg har ikke rum til å samle min grøde i. 
42:12:18: Og han sa: Jo, dette vil jeg gjøre: Jeg vil rive mine lader ned og bygge dem større, og der vil jeg samle hele min avling og mitt gods; 
42:12:19: og så vil jeg si til min sjel: Sjel! du har meget godt liggende for mange år; slå dig til ro, et, drikk, vær glad! 
42:12:20: Men Gud sa til ham: Du dåre! i denne natt kreves din sjel av dig; hvem skal så ha det du har samlet? 
42:12:21: Således er det med den som samler sig skatter og ikke er rik i Gud. 
42:12:22: Og han sa til sine disipler: Derfor sier jeg eder: Vær ikke bekymret for eders liv, hvad I skal ete, heller ikke for eders legeme, hvad I skal klæ eder med! 
42:12:23: Livet er mere enn maten, og legemet mere enn klærne. 
42:12:24: Gi akt på ravnene: de hverken sår eller høster, de har ikke matbod eller lade, og Gud før dem allikevel. Hvor meget mere er ikke I enn fuglene! 
42:12:25: Og hvem av eder kan med all sin bekymring legge en alen til sin livslengde? 
42:12:26: Formår I da ikke engang det minste, hvorfor er I da bekymret for det andre? 
42:12:27: Gi akt på liljene, hvorledes de vokser: de arbeider ikke, de spinner ikke; men jeg sier eder: Enn ikke Salomo i all sin herlighet var klædd som én av dem. 
42:12:28: Men klær Gud således gresset på marken, som står idag og imorgen kastes i ovnen, hvor meget mere skal han da klæ eder, I lite troende! 
42:12:29: Så skal da I heller ikke søke efter hvad I skal ete, eller hvad I skal drikke, og ikke la eders tanker fare hit og dit. 
42:12:30: For alt slikt søker hedningene i verden efter; men eders Fader vet at I trenger til det. 
42:12:31: Men søk hans rike, så skal I få dette i tilgift! 
42:12:32: Frykt ikke, du lille hjord! for det har behaget eders Fader å gi eder riket. 
42:12:33: Selg det I eier, og gi almisse! Gjør eder punger som ikke eldes, en skatt som ikke forgår, i himmelen, der hvor tyve-hånd ikke når, og møll ikke tærer! 
42:12:34: For hvor eders skatt er, der vil også eders hjerte være. 
42:12:35: La eders lender være ombundet og eders schmelk brennende, 
42:12:36: og vær I likesom folk som venter på sin herre når han vil fare hjem fra bryllupet, forat de kan lukke op for ham straks han kommer og banker på! 
42:12:37: Salige er de tjenere som herren finner våkne når han kommer. Sannelig sier jeg eder: Han skal binde op om sig og la dem sette sig til bords og gå frem og tjene dem. 
42:12:38: Og om han kommer i den annen vakt, og om han kommer i den tredje og finner det så, salige er de. 
42:12:39: Men dette skal I vite at dersom husbonden visste i hvilken time tyven kom, da vilde han våke og ikke la nogen bryte inn i sitt hus. 
42:12:40: Vær da også I rede! for Menneskesønnen kommer i den time I ikke tenker. 
42:12:41: Da sa Peter til ham: Herre! er det oss du taler om i denne lignelse, eller alle? 
42:12:42: Og Herren sa: Hvem er da den tro og kloke husholder, som hans husbond vil sette over sine tjenestefolk for å gi dem deres mat i rette tid? 
42:12:43: Salig er den tjener som hans husbond finner å gjøre så når han kommer. 
42:12:44: Sannelig sier jeg eder: Han skal sette ham over alt det han eier. 
42:12:45: Men dersom denne tjener sier i sitt hjerte: Min herre dryger med å komme, og så gir sig til å slå drengene og pikene og å ete og drikke og fylle sig, 
42:12:46: da skal denne tjeners herre komme en dag han ikke venter, og en time han ikke vet, og hugge ham sønder og gi ham lodd og del med de utro. 
42:12:47: Men den tjener som kjente sin herres vilje, og ikke stelte til eller satte i verk det han vilde, han skal få mange slag; 
42:12:48: men den som ikke kjente den, men gjorde det som er slag verd, han skal få færre. Hver den som meget er gitt, av ham skal meget kreves, og den som meget er overgitt, av ham skal dess mere fordres. 
42:12:49: Ild er jeg kommet for å kaste på jorden; og hvor gjerne jeg vilde den alt var tendt! 
42:12:50: Men en dåp har jeg å døpes med; og hvor jeg gruer til den er fullført! 
42:12:51: Tror I at jeg er kommet for å gi fred på jorden? Nei, sier jeg eder, men strid. 
42:12:52: For fra nu av skal fem være i strid i ett hus, tre mot to og to mot tre, 
42:12:53: far mot sønn og sønn mot far, mor mot datter og datter mot mor, svigermor mot svigerdatter og svigerdatter mot svigermor. 
42:12:54: Men han sa også til folket: Når I ser det stiger en sky op i vest, sier I straks: Det kommer regn, og det skjer så; 
42:12:55: og når I ser det blåser sønnenvind, sier I: Det blir hete, og det blir så. 
42:12:56: I hyklere! Jordens og himmelens utseende vet I å tyde; hvorfor kan I da ikke tyde denne tid? 
42:12:57: Hvorfor dømmer I da ikke også av eder selv hvad rett er? 
42:12:58: For når du går avsted til øvrigheten med din motstander, da gjør dig umak for å bli forlikt med ham mens du er på veien, forat han ikke skal dra dig frem for dommeren, og dommeren overgi dig til fangevokteren, og fangevokteren kaste dig i fengsel. 
42:12:59: Jeg sier dig: Du skal ingenlunde komme ut derfra før du har betalt til siste øre. 
42:13:1: På samme tid kom nogen og fortalte ham om de galileere hvis blod Pilatus hadde blandet med deres offer. 
42:13:2: Han svarte da og sa til dem: Tenker I at disse galileere var syndere fremfor alle andre galileere, fordi de har lidt dette? 
42:13:3: Nei, sier jeg eder; men dersom I ikke omvender eder, skal I alle omkomme likedan. 
42:13:4: Eller hine atten som tårnet ved Siloa falt over og slo ihjel, tenker I at de var skyldige fremfor alle mennesker som bor i Jerusalem? 
42:13:5: Nei, sier jeg eder; men dersom I ikke omvender eder, skal I alle omkomme på samme vis. 
42:13:6: Men han sa denne lignelse: En mann hadde et fikentre som var plantet i hans vingård, og han kom og lette efter frukt på det, og fant ingen. 
42:13:7: Da sa han til vingårdsmannen: Se, i tre år er jeg nu kommet og har lett efter frukt på dette fikentre og har ikke funnet nogen; hugg det ned! Hvorfor skal det også opta jorden til ingen nytte? 
42:13:8: Men han svarte ham: Herre! la det ennu stå dette år, til jeg får gravd omkring det og lagt gjødning på, 
42:13:9: om det kanskje kunde bære til næste år! hvis ikke, da kan du hugge det ned. 
42:13:10: Og han holdt på å lære i en av synagogene på sabbaten; 
42:13:11: og se, der var en kvinne som hadde hatt en vanmakts-ånd i atten år, og hun var krumbøid og kunde ikke rette sig helt op. 
42:13:12: Da Jesus så henne, kalte han henne til sig og sa til henne: Kvinne! du er løst fra din vanmakt. 
42:13:13: Og han la sine hender på henne, og straks rettet hun sig op og priste Gud. 
42:13:14: Da tok synagoge-forstanderen til orde - han var vred over at Jesus helbredet på sabbaten - og han sa til folket: Det er seks dager til å arbeide i; kom derfor på dem og la eder helbrede, og ikke på sabbatsdagen! 
42:13:15: Men Herren svarte ham og sa: I hyklere! vil ikke enhver av eder på sabbaten løse sin okse eller sitt asen fra krybben og gå bort og vanne dem? 
42:13:16: Men denne, en Abrahams datter, som Satan har bundet, tenk, i atten år, skulde ikke hun bli løst av dette bånd på sabbatsdagen? 
42:13:17: Da han sa dette, blev de til skamme alle som stod ham imot, og alt folket gledet sig over alle de herlige gjerninger han gjorde. 
42:13:18: Derfor sa han: Hvad er Guds rike likt, og hvad skal jeg ligne det med? 
42:13:19: Det er likt et sennepskorn som en mann tok og la i sin have; og det vokste og blev til et tre, og himmelens fugler bygget rede i dets grener. 
42:13:20: Og atter sa han: Hvad skal jeg ligne Guds rike med? 
42:13:21: Det er likt en surdeig som en kvinne tok og skjulte i tre skjepper mel, til det blev syret alt sammen. 
42:13:22: Og han gikk omkring og lærte rundt om I byer og landsbyer, og tok veien til Jerusalem. 
42:13:23: En sa da til ham: Herre! er det få som blir frelst? Da sa han til dem: 
42:13:24: Strid for å komme inn igjennem den trange dør! for mange, sier jeg eder, skal søke å komme inn, og ikke være i stand til det. 
42:13:25: Fra den stund av da husbonden har reist sig og lukket døren, og I begynner å stå utenfor og banke på døren og si: Herre, lukk op for oss! da skal han svare og si til eder: Jeg vet ikke hvor I er fra. 
42:13:26: Da skal I begynne å si: Vi åt og drakk for dine øine, og du lærte på våre gater! 
42:13:27: Og han skal si: Jeg sier eder: Jeg vet ikke hvor I er fra; vik bort fra mig alle I som gjorde urett! 
42:13:28: Der skal være gråt og tenners gnidsel når I får se Abraham og Isak og Jakob og alle profetene i Guds rike, men eder selv kastet utenfor. 
42:13:29: Og det skal komme folk fra øst og vest og fra nord og syd, og de skal sitte til bords i Guds rike. 
42:13:30: Og se, der er de som er mellem de siste og skal bli de første, og der er de som er mellem de første og skal bli de siste. 
42:13:31: I samme stund kom nogen fariseere og sa til ham: Gå bort og dra herfra! for Herodes har i sinne å slå dig ihjel. 
42:13:32: Og han sa til dem: Gå og si til den rev: Se, jeg driver ut onde ånder og fullfører helbredelser idag og imorgen, og på den tredje dag er jeg ved enden; 
42:13:33: men jeg må vandre idag og imorgen og dagen derefter; for det går ikke an at en profet mister livet utenfor Jerusalem. 
42:13:34: Jerusalem! Jerusalem! du som slår ihjel profetene og stener dem som er sendt til dig! hvor ofte jeg vilde samle dine barn, likesom en høne samler sine kyllinger under sine vinger! Og I vilde ikke. 
42:13:35: Se, eders hus skal overlates eder selv. Jeg sier eder at I ikke skal se mig før den stund kommer da I sier: Velsignet være han som kommer i Herrens navn! 
42:14:1: Og det skjedde da han kom inn i en av de øverste fariseeres hus på en sabbat for å holde måltid, og de lurte på ham, 
42:14:2: se, da var der en vattersottig mann like for ham. 
42:14:3: Og Jesus tok til orde og talte til de lovkyndige og fariseerne og sa: Er det tillatt å helbrede på sabbaten, eller ikke? 
42:14:4: Men de tidde. Og han tok på ham og helbredet ham, og lot ham gå. 
42:14:5: Og han tok atter til orde og sa til dem: Hvem av eder har en sønn eller en okse som faller i en brønn, og drar dem ikke straks op igjen på sabbatsdagen? 
42:14:6: Men de var ikke i stand til å svare ham på dette. 
42:14:7: Og han sa en lignelse til gjestene, da han la merke til hvorledes de valgte sig ut de øverste seter: 
42:14:8: Når du blir buden av nogen til bryllups, da sett dig ikke øverst ved bordet, forat ikke en gjævere enn du skal være buden, 
42:14:9: og den som innbød dig og ham, skal komme og si til dig: Gi denne mann rum! og du så med skam skal komme til å sitte nederst. 
42:14:10: Men når du er buden, da gå og sett dig nederst, forat han som innbød dig, kan si til dig når han kommer: Venn, kom hit, sett dig høiere op! Da får du ære i alle deres øine som sitter til bords med dig; 
42:14:11: for hver den sig selv ophøier, skal fornedres, og den sig selv fornedrer, skal ophøies. 
42:14:12: Han sa også til den som hadde innbudt ham: Når du gjør gjestebud middag eller aften, da innbyd ikke dine venner eller dine brødre eller dine frender eller rike granner, forat ikke de skal be dig igjen, så du får gjengjeld! 
42:14:13: Men når du gjør gjestebud, da be fattige, vanføre, halte, blinde! 
42:14:14: så er du salig; for de har ikke noget å gi dig igjen, men du skal få det igjen i de rettferdiges opstandelse. 
42:14:15: Da en av dem som satt med til bords, hørte dette, sa han til ham: Salig er den som får sitte til bords i Guds rike. 
42:14:16: Da sa han til ham: Det var en mann som gjorde en stor nattverd og innbød mange; 
42:14:17: og han sendte sin tjener ut ved den tid nattverden skulde holdes, for å si til de innbudne: Kom! for nu er det ferdig. 
42:14:18: Men de begynte alle som én å undskylde sig. Den første sa til ham: Jeg har kjøpt en aker og må nødvendig gå ut og se på den; jeg ber dig, ha mig undskyldt! 
42:14:19: Og en annen sa: Jeg har kjøpt fem par okser og går ut for å prøve dem; jeg ber dig, ha mig undskyldt! 
42:14:20: Og atter en annen sa: Jeg har tatt mig en hustru, og derfor kan jeg ikke komme. 
42:14:21: Og tjeneren kom og fortalte sin herre dette. Da blev husbonden harm og sa til tjeneren: Gå i hast ut på byens gater og streder, og før herinn de fattige og vanføre og blinde og halte! 
42:14:22: Og tjeneren sa: Herre! det er gjort som du bød, og der er ennu rum. 
42:14:23: Da sa herren til tjeneren: Gå ut på veiene og ved gjerdene og nød dem til å komme inn, forat mitt hus kan bli fullt! 
42:14:24: For jeg sier eder at ingen av de menn som var innbudt, skal smake min nattverd. 
42:14:25: Og meget folk vandret sammen med ham. Og han vendte sig og sa til dem: 
42:14:26: Om nogen kommer til mig og ikke hater sin far og mor og hustru og barn og brødre og søstre, ja endog sitt eget liv, han kan ikke være min disippel. 
42:14:27: Og den som ikke bærer sitt kors og følger efter mig, han kan ikke være min disippel. 
42:14:28: For hvem av eder som vil bygge et tårn, setter sig ikke først ned og regner efter hvad det vil koste, om han har nok til å fullføre det med, 
42:14:29: forat ikke, når han har lagt grunnen og ikke er i stand til å fullføre det, alle de som ser det, skal begynne å spotte ham og si: 
42:14:30: Denne mann begynte å bygge, og var ikke i stand til å fullføre det? 
42:14:31: Eller hvilken konge som drar ut for å møte en annen konge i strid, setter sig ikke først ned og rådslår om han med ti tusen er i stand til å møte den som kommer imot ham med tyve tusen? 
42:14:32: Men kan han ikke det, da skikker han sendemenn til ham, mens han ennu er langt borte, og tinger om fred. 
42:14:33: Således kan da ingen av eder være min disippel uten at han opgir alt det han eier. 
42:14:34: Salt er en god ting, men når også saltet mister sin kraft, hvad skal det da saltes med? 
42:14:35: Det duer hverken i jord eller i gjødsel; det blir kastet ut. Den som har ører å høre med, han høre! 
42:15:1: Og alle toldere og syndere holdt sig nær til ham for å høre ham. 
42:15:2: Og både fariseerne og de skriftlærde knurret sig imellem og sa: Denne mann tar imot syndere og eter sammen med dem! 
42:15:3: Da talte han denne lignelse til dem: 
42:15:4: Hvilket menneske iblandt eder som har hundre får og mister ett av dem, forlater ikke de ni og nitti i ørkenen og går efter det han har mistet, til han finner det? 
42:15:5: Og når han har funnet det, legger han det på sine skuldrer med glede, 
42:15:6: og når han kommer hjem, kaller han sine venner og granner sammen og sier til dem: Gled eder med mig, for jeg har funnet mitt får som jeg hadde mistet! 
42:15:7: Jeg sier eder: Således skal det være glede i himmelen over én synder som omvender sig, mere enn over ni og nitti rettferdige som ikke trenger til omvendelse. 
42:15:8: Eller hvilken kvinne som har ti sølvpenninger og mister én av dem, tender ikke lys og feier sitt hus og leter med flid til hun finner den? 
42:15:9: Og når hun har funnet den, kaller hun sine venninner og grannekvinner sammen og sier: Gled eder med mig, for jeg har funnet sølvpenningen som jeg hadde mistet! 
42:15:10: Således, sier jeg eder, blir det glede for Guds engler over én synder som omvender sig. 
42:15:11: Og han sa: En mann hadde to sønner, 
42:15:12: og den yngste av dem sa til sin far: Far! gi mig den del av boet som faller på mig! Og han skiftet sin eiendom imellem dem. 
42:15:13: Og ikke mange dager derefter samlet den yngste sønn alt sitt og drog til et land langt borte, og der ødte han sin eiendom i et ryggesløst levnet. 
42:15:14: Men da han hadde satt alt over styr, blev det en svær hunger i det land, og han begynte å lide nød. 
42:15:15: Da gikk han bort og holdt sig til en av borgerne der i landet, og han sendte ham ut på sine marker for å gjæte svin; 
42:15:16: og hans attrå var å fylle sin buk med de skolmer som svinene åt, og ingen gav ham noget. 
42:15:17: Men da kom han til sig selv og sa: Hvor mange leiefolk hos min far har fullt op av brød, men jeg setter livet til her av sult! 
42:15:18: Jeg vil stå op og gå til min far og si til ham: Far! jeg har syndet mot himmelen og for dig; 
42:15:19: jeg er ikke verdig lenger til å kalles din sønn; la mig få være som en av dine leiefolk! 
42:15:20: Og han stod op og kom til sin far. Men da han ennu var langt borte, så hans far ham, og han ynkedes inderlig, og løp til og falt ham om halsen og kysset ham. 
42:15:21: Men sønnen sa til ham: Far! jeg har syndet mot himmelen og for dig, og jeg er ikke verdig lenger til å kalles din sønn. 
42:15:22: Men faren sa til sine tjenere: Ta frem den beste klædning og ha den på ham, og gi ham en ring på hans hånd og sko på hans føtter, 
42:15:23: og hent gjøkalven og slakt den, og la oss ete og være glade! 
42:15:24: For denne min sønn var død og er blitt levende igjen, var tapt og er funnet. Og de begynte å være glade. 
42:15:25: Men hans eldste sønn var ute på marken, og da han gikk hjemover og kom nær til huset, hørte han spill og dans; 
42:15:26: og han kalte en av tjenerne til sig og spurte hvad dette var. 
42:15:27: Han sa til ham: Din bror er kommet, og din far lot slakte gjøkalven, fordi han fikk ham frisk tilbake. 
42:15:28: Da blev han vred og vilde ikke gå inn. Hans far gikk da ut og talte vennlig til ham; 
42:15:29: men han svarte og sa til sin far: Se, i så mange år har jeg tjent dig, og aldri har jeg gjort imot ditt bud, og mig har du aldri gitt et kje forat jeg kunde være glad med mine venner; 
42:15:30: men da denne din sønn kom, han som har ett op din eiendom sammen med skjøger, da slaktet du gjøkalven for ham! 
42:15:31: Men han sa til ham: Barn! du er alltid hos mig, og alt mitt er ditt; 
42:15:32: men vi burde fryde og glede oss fordi denne din bror var død og er blitt levende, var tapt og er funnet. 
42:16:1: Men han sa også til sine disipler: Der var en rik mann som hadde en husholder, og han blev angitt for ham som en som ødte hans eiendom. 
42:16:2: Og han kalte ham for sig og sa til ham: Hvad er dette jeg hører om dig? Gjør regnskap for din husholdning! for du kan ikke lenger forestå mitt hus. 
42:16:3: Da sa husholderen ved sig selv: Hvad skal jeg gjøre, nu da min herre tar husholdningen fra mig? Jeg er ikke i stand til å grave, jeg skammer mig ved å tigge. 
42:16:4: Nu vet jeg hvad jeg vil gjøre, forat de skal ta imot mig i sine hus når jeg blir avsatt fra husholdningen. 
42:16:5: Og han kalte til sig hver især av sin herres skyldnere, og sa til den første: Hvor meget er du min herre skyldig? 
42:16:6: Han sa: Hundre anker olje. Da sa han til ham: Her har du ditt gjeldsbrev; sett dig ned, skynd dig og skriv femti! 
42:16:7: Derefter sa han til en annen: Og du, hvor meget er du skyldig? Han sa: Hundre tønner hvete. Han sier til ham: Her har du ditt gjeldsbrev; skriv åtti! 
42:16:8: Og Herren roste den urettferdige husholder for at han hadde båret sig klokt ad; for denne verdens barn er klokere mot sin egen slekt enn lysets barn. 
42:16:9: Og jeg sier eder: Gjør eder venner ved den urettferdige mammon, forat de, når den svikter, må ta imot eder i de evige boliger! 
42:16:10: Den som er tro i smått, er også tro i stort, og den som er urettferdig i smått, er også urettferdig i stort. 
42:16:11: Dersom I da ikke har vært tro i den urettferdige mammon, hvem vil da betro eder de sanne skatter? 
42:16:12: Og dersom I ikke har vært tro i det som hører andre til, hvem vil da gi eder noget til eget eie? 
42:16:13: Ingen tjener kan tjene to herrer; for han vil enten hate den ene og elske den andre, eller holde sig til den ene og forakte den andre; I kan ikke tjene Gud og mammon. 
42:16:14: Fariseerne, som var pengekjære, hørte på alt dette, og de spottet ham. 
42:16:15: Da sa han til dem: I er de som gjør eder selv rettferdige for menneskene; men Gud kjenner eders hjerter; for det som er høit i menneskers øine, er en vederstyggelighet for Gud. 
42:16:16: Loven og profetene hadde sin tid inntil Johannes; fra den tid forkynnes evangeliet om Guds rike, og enhver trenger sig inn i det med makt; 
42:16:17: men før skal himmel og jord forgå før en eneste tøddel av loven skal falle bort. 
42:16:18: Hver den som skiller sig fra sin hustru og gifter sig med en annen kvinne, han driver hor, og hver den som gifter sig med en kvinne som er skilt fra sin mann, han driver hor. 
42:16:19: Der var en rik mann, og han klædde sig i purpur og kostelig linklæde og levde hver dag i herlighet og glede. 
42:16:20: Men der var en fattig mann ved navn Lasarus, som var kastet for hans port, full av sår, 
42:16:21: og hans attrå var å få mette sig med det som falt fra den rikes bord; men endog hundene kom og slikket hans sår. 
42:16:22: Men det skjedde at den fattige døde, og at han blev båret bort av engler i Abrahams skjød; men også den rike døde og blev begravet. 
42:16:23: Og da han slo sine øine op i dødsriket, der han var i pine, da ser han Abraham langt borte og Lasarus i hans skjød. 
42:16:24: Da ropte han: Fader Abraham! forbarm dig over mig og send Lasarus, forat han kan dyppe det ytterste av sin finger i vann og svale min tunge! for jeg pines storlig i denne lue. 
42:16:25: Men Abraham sa: Sønn! kom i hu at du fikk ditt gode i din levetid, og Lasarus likeså det onde! men nu trøstes han her, og du pines. 
42:16:26: Og dessuten er et stort svelg festet mellem oss og eder, forat de som vil gå herfra og over til eder, ikke skal kunne det, og forat heller ikke de på den andre side skal fare derfra og over til oss. 
42:16:27: Da sa han: Så ber jeg dig, fader, at du sender ham til min fars hus 
42:16:28: - for jeg har fem brødre - forat han kan vidne for dem, så ikke også de skal komme til dette pinens sted. 
42:16:29: Men Abraham sier til ham: De har Moses og profetene; la dem høre dem! 
42:16:30: Men han sa: Nei, fader Abraham! men om nogen fra de døde kommer til dem, da omvender de sig. 
42:16:31: Men han sa til ham: Hører de ikke Moses og profetene, da vil de heller ikke tro om nogen står op fra de døde. 
42:17:1: Og han sa til sine disipler: Det er umulig annet enn at forførelser må komme; men ve den som de kommer fra! 
42:17:2: Det var bedre for ham om det var hengt en kvernsten om hans hals, og han var kastet i havet, enn at han skulde forføre én av disse små. 
42:17:3: Ta eder i vare! Om din bror synder, da irettesett ham; og om han angrer det, da tilgi ham! 
42:17:4: Og om han syv ganger om dagen synder imot dig og syv ganger kommer tilbake til dig og sier: Jeg angrer det, da skal du tilgi ham. 
42:17:5: Og apostlene sa til Herren: Øk vår tro! 
42:17:6: Men Herren sa: Dersom I hadde tro som et sennepskorn, da skulde I si til dette morbærtre: Rykk dig op med rot og plant dig i havet! og det skulde lyde eder. 
42:17:7: Men hvem av eder som har en tjener som pløier eller gjæter, vil si til ham når han kommer inn fra marken: Kom straks hit og sett dig til bords? 
42:17:8: Vil han ikke heller si til ham: Gjør i stand det jeg skal ha til aftensmat, og bind op om dig og gå mig til hånde til jeg får ett og drukket, så skal du få ete og drikke? 
42:17:9: Takker han vel sin tjener fordi han gjorde det som var ham pålagt? Jeg tror det ikke. 
42:17:10: Således skal også I, når I har gjort alt det som er eder pålagt, si: Vi er unyttige tjenere; vi har bare gjort det vi var skyldige å gjøre. 
42:17:11: Og det skjedde på vandringen til Jerusalem at han drog midt imellem Samaria og Galilea; 
42:17:12: og da han gikk inn i en by, møtte det ham ti spedalske menn, som stod langt borte, 
42:17:13: og de ropte høit: Jesus, mester! miskunn dig over oss! 
42:17:14: Og da han så dem, sa han til dem: Gå bort og te eder for prestene! Og det skjedde mens de var på veien, at de blev renset. 
42:17:15: Men en av dem vendte tilbake, da han så at han var helbredet, og han priste Gud med høi røst, 
42:17:16: og falt ned på sitt ansikt for hans føtter og takket ham; og han var en samaritan. 
42:17:17: Da svarte Jesus og sa: Blev ikke de ti renset? hvor er da de ni? 
42:17:18: Fantes det ingen som vendte tilbake for å gi Gud ære uten denne fremmede? 
42:17:19: Og han sa til ham: Stå op og gå bort! din tro har frelst dig. 
42:17:20: Men da han blev spurt av fariseerne når Guds rike skulde komme, svarte han dem og sa: Guds rike kommer ikke på den måte at en kan se det med sine øine; 
42:17:21: heller ikke skal de si: Se her eller se der er det! For se, Guds rike er inneni eder. 
42:17:22: Men han sa til sine disipler: De dager skal komme da I skal attrå å få se en av Menneskesønnens dager, og I skal ikke få se den. 
42:17:23: Og de skal si til eder: Se her, se der er han! Gå ikke der bort, og følg ikke efter! 
42:17:24: For likesom lynet, når det lyner, skinner fra himmelbryn til himmelbryn, således skal Menneskesønnen være på sin dag. 
42:17:25: Men først skal han lide meget og forkastes av denne slekt. 
42:17:26: Og likesom det gikk i Noahs dager, så skal det også gå i Menneskesønnens dager: 
42:17:27: de åt og drakk, de tok til ekte og blev gitt til ekte, like til den dag da Noah gikk inn i arken; så kom vannflommen og ødela dem alle sammen. 
42:17:28: På samme vis - likesom det gikk i Lots dager: de åt og drakk, de kjøpte og solgte, de plantet og bygget; 
42:17:29: men den dag da Lot gikk ut av Sodoma, da lot Gud ild og svovel regne fra himmelen og ødela dem alle sammen - 
42:17:30: således skal det også gå på den dag da Menneskesønnen åpenbares. 
42:17:31: På den dag må den som er på taket og har sine ting i huset, ikke stige ned for å hente dem, og heller ikke den som er ute på marken, vende tilbake til sitt hjem. 
42:17:32: Kom Lots hustru i hu! 
42:17:33: Den som søker å frelse sitt liv, skal miste det, og den som mister det, skal berge det. 
42:17:34: Jeg sier eder: I den natt skal det være to i én seng; den ene skal tas med, og den andre skal lates tilbake. 
42:17:35: To kvinner skal male på samme kvern; den ene skal tas med, og den andre skal lates tilbake. 
42:17:36: Det skal være to ute på marken; den ene blir tatt med, den andre blir latt tilbake. 
42:17:37: Da svarte de og sa til ham: Hvor, Herre? Han sa til dem: Hvor åtselet er, der skal ørnene samles. 
42:18:1: Og han sa en lignelse til dem om at de alltid skulde bede og ikke bli trette. 
42:18:2: Der var en dommer i en by, som ikke fryktet Gud og ikke undså sig for noget menneske. 
42:18:3: Og det var en enke der i byen, og hun kom til ham og sa: Hjelp mig til å få rett over min motstander! 
42:18:4: Og lenge vilde han ikke; men til sist sa han ved sig selv: Om jeg enn ikke frykter Gud og ikke undser mig for noget menneske, 
42:18:5: vil jeg dog hjelpe denne enke til å få rett fordi hun gjør mig uleilighet, så hun ikke til slutt skal komme og legge hånd på mig. 
42:18:6: Og Herren sa: Hør hvad den urettferdige dommer sier! 
42:18:7: Men skulde da ikke Gud hjelpe sine utvalgte til deres rett, dem som roper til ham dag og natt, og er han sen når det gjelder dem? 
42:18:8: Jeg sier eder at han skal skynde sig å hjelpe dem til deres rett. Men når Menneskesønnen kommer, mon han da vil finne troen på jorden? 
42:18:9: Han sa også denne lignelse til nogen som stolte på sig selv at de var rettferdige, og foraktet de andre: 
42:18:10: To menn gikk op til templet for å bede; den ene var en fariseer og den andre en tolder. 
42:18:11: Fariseeren stod for sig selv og bad således: Gud! jeg takker dig fordi jeg ikke er som andre mennesker: røvere, urettferdige, horkarler, eller og som denne tolder. 
42:18:12: Jeg faster to ganger om uken, jeg gir tiende av all min inntekt. 
42:18:13: Og tolderen stod langt borte og vilde ikke engang løfte sine øine mot himmelen, men slo sig for sitt bryst og sa: Gud! vær mig synder nådig! 
42:18:14: Jeg sier eder: Denne gikk rettferdiggjort ned til sitt hus fremfor den andre; for hver den sig selv ophøier, skal fornedres, og den sig selv fornedrer, skal ophøies. 
42:18:15: De bar også sine små barn til ham, forat han skulde røre ved dem; men da disiplene så det, truet de dem. 
42:18:16: Men Jesus kalte dem til sig og sa: La de små barn komme til mig, og hindre dem ikke! for Guds rike hører sådanne til. 
42:18:17: Sannelig sier jeg eder: Den som ikke tar imot Guds rike som et lite barn, han skal ingenlunde komme inn i det. 
42:18:18: Og en rådsherre spurte ham: Gode mester! hvad skal jeg gjøre for å arve evig liv? 
42:18:19: Jesus sa til ham: Hvorfor kaller du mig god? Ingen er god uten én, det er Gud. 
42:18:20: Budene kjenner du: Du skal ikke drive hor, du skal ikke slå ihjel, du skal ikke stjele, du skal ikke si falskt vidnesbyrd, hedre din far og din mor. 
42:18:21: Men han sa: Alt dette har jeg holdt fra min ungdom av. 
42:18:22: Da Jesus hørte det, sa han til ham: Ett fattes dig ennu: selg alt det du har, og del det ut til fattige, så skal du få en skatt i himmelen; kom så og følg mig! 
42:18:23: Men da han hørte det, blev han meget bedrøvet; for han var meget rik. 
42:18:24: Og da Jesus så det, sa han: Hvor vanskelig det er for de rike å komme inn i Guds rike! 
42:18:25: For det er lettere for en kamel å gå gjennem et nåleøie enn for en rik å gå inn i Guds rike. 
42:18:26: Da sa de som hørte det: Hvem kan da bli frelst? 
42:18:27: Men han sa: Det som er umulig for mennesker, er mulig for Gud. 
42:18:28: Og Peter sa: Se, vi har forlatt alt vårt og fulgt dig. 
42:18:29: Da sa han til dem: Sannelig sier jeg eder: Det er ingen som har forlatt hus eller hustru eller brødre eller foreldre eller barn for Guds rikes skyld 
42:18:30: uten at han skal få mangefold igjen her i tiden, og i den kommende verden evig liv. 
42:18:31: Men han tok de tolv til sig og sa til dem: Se, vi går op til Jerusalem, og alt det som er skrevet av profetene om Menneskesønnen, skal fullbyrdes. 
42:18:32: For han skal overgis til hedningene og bli spottet og hånet og spyttet på, 
42:18:33: og de skal hudstryke ham og slå ham ihjel, og på den tredje dag skal han opstå. 
42:18:34: Og de forstod ikke noget av dette, og dette ord var skjult for dem, og de skjønte ikke det han sa. 
42:18:35: Og det skjedde da han kom nær til Jeriko, at en blind mann satt ved veien og tigget. 
42:18:36: Da han hørte folk gå forbi, spurte han hvad dette var. 
42:18:37: De fortalte ham da at Jesus fra Nasaret gikk forbi. 
42:18:38: Og han ropte: Jesus, du Davids sønn! miskunn dig over mig! 
42:18:39: Og de som gikk foran, truet ham at han skulde tie. Men han ropte enda meget mere: Du Davids sønn! miskunn dig over mig! 
42:18:40: Da stod Jesus stille, og bød at han skulde føres til ham; og da han kom frem, spurte han ham: 
42:18:41: Hvad vil du jeg skal gjøre for dig? Han sa: Herre! at jeg må bli seende! 
42:18:42: Og Jesus sa til ham: Bli seende! din tro har frelst dig. 
42:18:43: Og straks fikk han sitt syn igjen, og fulgte ham og lovet Gud; og alt folket som så det, priste Gud. 
42:19:1: Og han kom inn i Jeriko og drog igjennem byen. 
42:19:2: Og se, der var en mann som hette Sakkeus; han var overtolder og en rik mann. 
42:19:3: Og han søkte å få se hvem som var Jesus, og han kunde ikke komme til for folket, for han var liten av vekst. 
42:19:4: Da sprang han i forveien og steg op i et morbærtre for å få se ham; for hans vei gikk der forbi. 
42:19:5: Og da Jesus kom til stedet, så han op og sa til ham: Sakkeus! skynd dig og stig ned! for idag skal jeg bli i ditt hus. 
42:19:6: Og han skyndte sig og steg ned, og tok imot ham med glede. 
42:19:7: Og da de så det, knurret de alle og sa: Han gikk inn for å ta herberge hos en syndig mann! 
42:19:8: Men Sakkeus stod frem og sa til Herren: Se, Herre! Halvdelen av mitt gods gir jeg de fattige, og har jeg presset penger ut av nogen, gir jeg det firdobbelt igjen. 
42:19:9: Og Jesus sa om ham: Idag er frelse blitt dette hus til del, eftersom og han er en Abrahams sønn; 
42:19:10: for Menneskesønnen er kommet for å søke og frelse det som var fortapt. 
42:19:11: Mens de hørte på dette, la han også en lignelse til, fordi han var nær ved Jerusalem, og de tenkte at Guds rike straks skulde komme til syne. 
42:19:12: Han sa da: En mann av høi byrd drog til et land langt borte for å få kongemakt og så komme tilbake igjen. 
42:19:13: Han kalte da ti av sine tjenere for sig, og gav dem ti pund og sa: Kjøpslå med dem til jeg kommer igjen. 
42:19:14: Men hans landsmenn hatet ham, og skikket sendemenn avsted efter ham og lot si: Vi vil ikke at denne mann skal være konge over oss. 
42:19:15: Og det skjedde da han hadde fått kongemakten og kom tilbake, da bød han at de tjenere han hadde gitt pengene, skulde kalles for ham, forat han kunde få vite hvad hver av dem hadde vunnet. 
42:19:16: Da kom den første frem og sa: Herre! ditt pund har kastet av sig ti pund. 
42:19:17: Og han sa til ham: Vel, du gode tjener! fordi du har vært tro i det små, skal du råde over ti byer. 
42:19:18: Og den annen kom og sa: Herre! ditt pund har gitt fem pund. 
42:19:19: Også til denne sa han: Vær du herre over fem byer! 
42:19:20: Og en annen kom og sa: Herre! se her er ditt pund, som jeg har hatt liggende i et tørklæ; 
42:19:21: for jeg fryktet for dig, fordi du er en streng mann; du tar op det du ikke la ned, og høster det du ikke sådde. 
42:19:22: Han sier til ham: Efter din egen munn dømmer jeg dig, du dårlige tjener! Du visste at jeg er en streng mann, som tar op det jeg ikke la ned, og høster det jeg ikke sådde; 
42:19:23: hvorfor satte du da ikke mine penger ut hos pengevekslerne? Så hadde jeg, når jeg kom, fått dem tilbake med renter. 
42:19:24: Og han sa til dem som stod ved hans side: Ta pundet fra ham og gi det til ham som har de ti pund! 
42:19:25: - De sa til ham: Herre! han har jo ti pund! - 
42:19:26: Jeg sier eder at hver den som har, ham skal gis; men den som ikke har, fra ham skal endog tas det han har. 
42:19:27: Men disse mine fiender som ikke vilde at jeg skulde være konge over dem, før dem hit og hugg dem ned for mine øine! 
42:19:28: Og da han hadde sagt dette, drog han videre foran dem på sin vandring op til Jerusalem. 
42:19:29: Og det skjedde da han kom nær til Betfage og Betania, til det berg som kalles Oljeberget, da sendte han to av sine disipler avsted og sa: 
42:19:30: Gå bort til den by som ligger rett for oss! Når I kommer inn i den, skal I finne en fole bundet, som intet menneske har sittet på; løs den og før den hit! 
42:19:31: Og dersom nogen spør eder: Hvorfor løser I den? da skal I si så: Herren har bruk for den. 
42:19:32: Så gikk de utsendte avsted, og de fant det så som han hadde sagt dem. 
42:19:33: Og da de løste folen, sa dens eiermenn til dem: Hvorfor løser I folen? 
42:19:34: De sa: Herren har bruk for den. 
42:19:35: Og de førte den til Jesus, og de la sine klær på folen og lot Jesus sette sig på den. 
42:19:36: Da han nu drog frem, bredte de sine klær under ham på veien. 
42:19:37: Men da han allerede var nær ved nedgangen fra Oljeberget, begynte hele disippel-flokken glad å love Gud med høi røst for alle de kraftige gjerninger de hadde sett, og sa: 
42:19:38: Velsignet være kongen som kommer i Herrens navn! Fred i himmelen, og ære i det høieste! 
42:19:39: Og nogen av fariseerne blandt mengden sa til ham: Mester! irettesett dine disipler! 
42:19:40: Men han svarte og sa til dem: Jeg sier eder: Om disse tier, skal stenene rope. 
42:19:41: Og da han kom nær og så byen, gråt han over den og sa: 
42:19:42: Visste også du, om enn først på denne din dag, hvad som tjener til din fred! Men nu er det skjult for dine øine. 
42:19:43: For de dager skal komme over dig da dine fiender skal kaste en voll op om dig og kringsette dig og trenge dig fra alle sider, 
42:19:44: og slå dig til jorden og dine barn i dig, og ikke levne sten på sten i dig, fordi du ikke kjente din besøkelses tid. 
42:19:45: Og han gikk inn i templet og begynte å drive ut dem som drev handel der, 
42:19:46: og han sa til dem: Det er skrevet: Mitt hus skal være et bedehus. Men I har gjort det til en røverhule. 
42:19:47: Og han lærte daglig i templet. Men yppersteprestene og de skriftlærde og de første blandt folket søkte å få ryddet ham av veien. 
42:19:48: Og de fant ikke ut hvad de skulde gjøre; for hele folket hang ved ham og hørte på ham. 
42:20:1: Og det skjedde en av dagene mens han lærte folket i templet og forkynte evangeliet, da stod yppersteprestene og de skriftlærde frem sammen med de eldste 
42:20:2: og sa til ham: Si oss: Med hvad myndighet gjør du dette, eller hvem er det som har gitt dig denne myndighet? 
42:20:3: Men han svarte og sa til dem: Også jeg vil spørre eder om en ting; si mig: 
42:20:4: Johannes' dåp, var den fra himmelen eller fra mennesker? 
42:20:5: Men de samrådde sig med hverandre og sa: Sier vi: Fra himmelen, da sier han: Hvorfor trodde I ham da ikke? 
42:20:6: Men sier vi: Fra mennesker, da stener hele folket oss; for de tror fullt og fast at Johannes var en profet. 
42:20:7: Og de svarte at de ikke visste hvor den var fra. 
42:20:8: Da sa Jesus til dem: Så sier heller ikke jeg eder med hvad myndighet jeg gjør dette. 
42:20:9: Han begynte da å si denne lignelse til folket: En mann plantet en vingård og leide den ut til vingårdsmenn og drog utenlands for lange tider. 
42:20:10: Og da tiden kom, sendte han en tjener til vingårdsmennene, forat de skulde gi ham av vingårdens frukt; men vingårdsmennene slo ham, og lot ham gå bort med tomme hender. 
42:20:11: Og han blev ved og sendte en annen tjener; men de slo også ham og hånte ham og lot ham gå bort med tomme hender. 
42:20:12: Og han blev ved og sendte en tredje; men de slo også ham til blods og kastet ham ut. 
42:20:13: Da sa vingårdens herre: Hvad skal jeg gjøre? Jeg vil sende min sønn, den elskede; de vil da vel undse sig for ham. 
42:20:14: Men da vingårdsmennene fikk se ham, la de op råd med hverandre og sa: Dette er arvingen; la oss slå ham ihjel, så arven kan bli vår! 
42:20:15: Og de kastet ham ut av vingården og slo ham ihjel. Hvad skal nu vingårdens herre gjøre med dem? 
42:20:16: Han skal komme og drepe disse vingårdsmenn og overgi vingården til andre. Da de hørte det, sa de: Det må aldri skje! 
42:20:17: Men han så på dem og sa: Hvad er da dette som er skrevet: Den sten som bygningsmennene forkastet, den er blitt hjørnesten? 
42:20:18: Hver den som faller på denne sten, han skal knuses; men den som den faller på, ham skal den smuldre til støv. 
42:20:19: Og de skriftlærde og yppersteprestene søkte å få lagt hånd på ham i samme stund; men de fryktet for folket; for de skjønte at det var om dem han hadde sagt denne lignelse. 
42:20:20: Og efterat de nogen tid hadde voktet på ham, sendte de lurere, som lot som de var rettferdige, for å fange ham i ord, så de kunde overgi ham til øvrigheten og til landshøvdingens makt. 
42:20:21: Og de spurte ham og sa: Mester! vi vet at du taler og lærer rett og gjør ikke forskjell på folk, men lærer Guds vei i sannhet; 
42:20:22: er det oss tillatt å gi keiseren skatt, eller ikke? 
42:20:23: Men han merket deres list og sa til dem: 
42:20:24: Vis mig en penning! Hvis billede og påskrift har den? De svarte: Keiserens. 
42:20:25: Da sa han til dem: Så gi da keiseren hvad keiserens er, og Gud hvad Guds er! 
42:20:26: Og de var ikke i stand til å fange ham i ord i folkets påhør, og de undret sig over hans svar, og tidde. 
42:20:27: Men det kom nogen av sadduseerne til ham, de som nekter at det er nogen opstandelse, og de spurte ham og sa: 
42:20:28: Mester! Moses har foreskrevet oss at når en manns gifte bror dør og ikke har barn, da skal hans bror ta hans hustru til ekte og opreise sin bror avkom. 
42:20:29: Nu var det syv brødre; og den første tok sig en hustru og døde barnløs. 
42:20:30: Og den annen 
42:20:31: og den tredje tok henne, og likeså alle syv; de efterlot ikke barn, og døde. 
42:20:32: Til sist døde også kvinnen. 
42:20:33: Hvem iblandt dem skal nu få kvinnen til hustru i opstandelsen? for alle syv har jo hatt henne til hustru. 
42:20:34: Og Jesus sa til dem: Denne verdens barn tar til ekte og gis til ekte; 
42:20:35: men de som aktes verdige til å få del i hin verden og i opstandelsen fra de døde, de hverken tar til ekte eller gis til ekte; 
42:20:36: for de kan ikke mere dø, for de er englene like og er Guds barn, idet de er opstandelsens barn. 
42:20:37: Men at de døde står op, det har også Moses gitt til kjenne, der hvor det tales om tornebusken, når han kaller Herren Abrahams Gud og Isaks Gud og Jakobs Gud; 
42:20:38: men han er ikke de dødes Gud, men de levendes; for de lever alle for ham. 
42:20:39: Da svarte nogen av de skriftlærde og sa: Mester! du taler vel. 
42:20:40: For de vågde ikke mere å spørre ham om noget. 
42:20:41: Men han sa til dem: Hvorledes kan det sies at Messias er Davids sønn? 
42:20:42: David selv sier jo i Salmenes bok: Herren sa til min herre: Sett dig ved min høire hånd, 
42:20:43: til jeg får lagt dine fiender til skammel for dine føtter! 
42:20:44: David kaller ham altså herre; hvorledes kan han da være hans sønn? 
42:20:45: Men i hele folkets påhør sa han til sine disipler: 
42:20:46: Vokt eder for de skriftlærde, som gjerne vil gå i side klær og gjerne vil la sig hilse på torvene og ha de øverste seter i synagogene og sitte øverst ved gjestebudene; 
42:20:47: de som opeter enkers hus og for et syns skyld holder lange bønner! Disse skal få dess hårdere dom. 
42:21:1: Og da han så op, fikk han se de rike legge sine gaver i tempelkisten. 
42:21:2: Og han så en fattig enke legge to skjerver i den. 
42:21:3: Da sa han: Sannelig sier jeg eder: Denne fattige enke har lagt mere enn alle. 
42:21:4: For alle disse la sine gaver av sin overflod; men hun la av sin fattigdom alt det hun hadde å leve av. 
42:21:5: Og da nogen sa om templet at det var prydet med fagre stener og tempelgaver, sa han: 
42:21:6: Dette som I ser - de dager skal komme da det ikke skal levnes sten på sten som ikke skal brytes ned. 
42:21:7: Da spurte de ham og sa: Mester! når skal da dette skje? og hvad skal tegnet være når dette skal skje? 
42:21:8: Og han sa: Se til at I ikke føres vill! for mange skal komme i mitt navn og si: Det er mig, og: Tiden er nær. Gå ikke efter dem! 
42:21:9: Og når I får høre om krig og oprør, da la eder ikke skremme! for dette må først skje, men enden kommer ikke med det samme. 
42:21:10: Da sa han til dem: Folk skal reise sig mot folk og rike mot rike, 
42:21:11: og store jordskjelv skal det være og hunger og sott både her og der, og det skal skje forferdelige ting og store tegn fra himmelen. 
42:21:12: Men før alt dette skjer, skal de legge hånd på eder og forfølge eder og overgi eder til synagoger og fengsler, og I skal føres frem for konger og landshøvdinger for mitt navns skyld; 
42:21:13: det skal falle ut til godt vidnesbyrd for eder. 
42:21:14: Legg eder derfor på hjerte at I ikke forut skal grunde på hvorledes I skal forsvare eder! 
42:21:15: for jeg skal gi eder munn og visdom som alle eders motstandere ikke kan motstå eller motsi. 
42:21:16: Men I skal forrådes endog av foreldre og brødre og frender og venner, og de skal volde nogen av eder døden, 
42:21:17: og I skal hates av alle for mitt navns skyld. 
42:21:18: Og ikke et hår på eders hode skal gå tapt. 
42:21:19: Vær tålmodige, så skal I vinne eders sjeler! 
42:21:20: Men når I ser Jerusalem bli kringsatt av krigshærer, da skal I vite at dets ødeleggelse er nær. 
42:21:21: Da må de som er i Judea, fly til fjells, og de som er inne i byen, må gå ut, og de som er ute på landet, ikke gå inn i den; 
42:21:22: for dette er gjengjeldelsens dager, forat alt det som skrevet er, skal bli opfylt. 
42:21:23: Men ve de fruktsommelige, og dem som gir die, i de dager! for stor nød skal være på jorden, og vrede over dette folk, 
42:21:24: og de skal falle for sverds egg og føres fangne til alle folkeslag, og Jerusalem skal ligge nedtrådt av hedninger, inntil hedningenes tid er til ende. 
42:21:25: Og det skal skje tegn i sol og måne og stjerner, og på jorden skal folkene engstes i fortvilelse når hav og brenninger bruser, 
42:21:26: mens mennesker faller i avmakt av redsel og gru for det som kommer over jorderike; for himmelens krefter skal rokkes. 
42:21:27: Og da skal de se Menneskesønnen komme i skyen med kraft og megen herlighet. 
42:21:28: Men når dette begynner å skje, da rett eder op og løft eders hoder! for eders forløsning stunder til. 
42:21:29: Og han sa en lignelse til dem: Se på fikentreet og alle trær: 
42:21:30: Så snart de springer ut og I ser det, da vet I av eder selv at nu er sommeren nær. 
42:21:31: Således skal også I, når I ser dette skje, vite at Guds rike er nær. 
42:21:32: Sannelig sier jeg eder: Denne slekt skal ingenlunde forgå før det skjer alt sammen. 
42:21:33: Himmel og jord skal forgå, men mine ord skal ingenlunde forgå. 
42:21:34: Men vokt eder at ikke eders hjerte nogen tid tynges av rus og svir og timelige bekymringer, så hin dag kommer uventet over eder som en snare! 
42:21:35: for den skal komme over alle dem som bor over den hele jord. 
42:21:36: Men våk hver tid og stund, og bed, så I kan være i stand til å undfly alt dette som skal komme, og til å bli stående for Menneskesønnen! 
42:21:37: Om dagene lærte han i templet, men om nettene gikk han ut av byen og overnattet på det berg som kalles Oljeberget. 
42:21:38: Og alt folket kom tidlig om morgenen til ham i templet for å høre ham. 
42:22:1: Men de usyrede brøds høitid, som kalles påske, var nær; 
42:22:2: og yppersteprestene og de skriftlærde søkte råd til å få ryddet ham av veien; for de fryktet for folket. 
42:22:3: Men Satan fór inn i Judas med tilnavnet Iskariot, som var en av de tolv, 
42:22:4: og han gikk bort og talte med yppersteprestene og høvedsmennene om hvorledes han skulde forråde ham til dem. 
42:22:5: Og de blev glade, og lovte å gi ham penger, 
42:22:6: og han gav sitt tilsagn og søkte leilighet til å forråde ham til dem uten opstyr. 
42:22:7: Så kom de usyrede brøds dag, da påskelammet skulde slaktes. 
42:22:8: Og han sendte Peter og Johannes avsted og sa: Gå bort og gjør i stand påskelammet for oss, så vi kan ete det! 
42:22:9: De sa til ham: Hvor vil du vi skal gjøre det i stand? 
42:22:10: Han sa til dem: Se, når I kommer inn i byen, skal det møte eder en mann som bærer en krukke vann; følg ham til det hus hvor han går inn, 
42:22:11: og si til husbonden: Mesteren sier til dig: Hvor er det herberge der jeg kan ete påskelammet med mine disipler? 
42:22:12: Så skal han vise eder en stor sal med benker og hynder; der skal I gjøre det i stand. 
42:22:13: De gikk da avsted, og fant det så som han hadde sagt dem; og de gjorde i stand påskelammet. 
42:22:14: Og da timen kom, satte han sig til bords, og apostlene med ham. 
42:22:15: Og han sa til dem: Jeg har hjertelig lengtet efter å ete dette påskelam med eder før jeg lider; 
42:22:16: for jeg sier eder: Jeg skal aldri mere ete det før det er blitt fullkommet i Guds rike. 
42:22:17: Og han tok en kalk, takket og sa: Ta dette og del det mellem eder! 
42:22:18: For jeg sier eder: Fra nu av skal jeg aldri mere drikke av vintreets frukt før Guds rike er kommet. 
42:22:19: Og han tok et brød, takket og brøt det, gav dem og sa: Dette er mitt legeme, som gis for eder; gjør dette til minne om mig! 
42:22:20: Likeså kalken, efterat de hadde ett, og sa: Denne kalk er den nye pakt i mitt blod, som utgydes for eder. 
42:22:21: Men se, hans hånd som forråder mig, er med mig over bordet. 
42:22:22: For Menneskesønnen går vel bort, som bestemt er; men ve det menneske ved hvem han blir forrådt! 
42:22:23: De begynte da å spørre hverandre om hvem av dem det vel kunde være som skulde gjøre denne gjerning. 
42:22:24: Det blev også en trette mellem dem om hvem av dem skulde gjelde for å være størst. 
42:22:25: Da sa han til dem: Kongene hersker over sine folk, og de som bruker makt over dem, kalles deres velgjørere. 
42:22:26: Så er det ikke med eder; men den største blandt eder skal være som den yngste, og den øverste som den som tjener. 
42:22:27: For hvem er størst, den som sitter til bords, eller den som tjener? Er det ikke den som sitter til bords? Men jeg er som en tjener iblandt eder. 
42:22:28: Men I er de som har holdt ut hos mig i mine prøvelser, 
42:22:29: og jeg tilsier eder riket, likesom min Fader har tilsagt mig det, 
42:22:30: så I skal ete og drikke ved mitt bord i mitt rike, og sitte på troner og dømme Israels tolv stammer. 
42:22:31: Simon! Simon! se, Satan krevde å få eder i sin vold for å sikte eder som hvete; 
42:22:32: men jeg bad for dig at din tro ikke måtte svikte, og når du engang omvender dig, da styrk dine brødre! 
42:22:33: Men han sa til ham: Herre! med dig er jeg rede til å gå både i fengsel og i død. 
42:22:34: Da sa han: Jeg sier dig, Peter: Hanen skal ikke gale idag før du tre ganger har nektet at du kjenner mig. 
42:22:35: Og han sa til dem: Da jeg sendte eder ut uten pung og skreppe og sko, fattedes eder da noget? De sa: Nei, intet. 
42:22:36: Han sa da til dem: Men nu skal den som har pung, ta den med, likeså skreppe, og den som ikke har sverd, han selge sin kappe og kjøpe sig et! 
42:22:37: For jeg sier eder at dette som er skrevet, må opfylles på mig, dette ord: Og han blev regnet blandt ugjerningsmenn; for det som er sagt om mig, er til ende. 
42:22:38: Da sa de: Herre! se, her er to sverd. Men han sa til dem: Det er nok. 
42:22:39: Og han gikk ut og vandret efter sedvane til Oljeberget; men hans disipler fulgte og med ham. 
42:22:40: Og da han kom til stedet, sa han til dem: Bed at I ikke må komme i fristelse! 
42:22:41: Og han slet sig fra dem så lang som et stenkast, og falt på kne, bad og sa: 
42:22:42: Fader! om du vil, da la denne kalk gå mig forbi! Dog, skje ikke min vilje, men din! 
42:22:43: Og en engel fra himmelen åpenbarte sig for ham og styrket ham. 
42:22:44: Og han kom i dødsangst og bad enda heftigere, og hans sved blev som blodsdråper, som falt ned på jorden. 
42:22:45: Så stod han op fra bønnen og kom til sine disipler og fant dem sovende av bedrøvelse, 
42:22:46: og han sa til dem: Hvorfor sover I? Stå op og bed at I ikke må komme i fristelse! 
42:22:47: Mens han ennu talte, se, da kom en flokk, og han som hette Judas, en av de tolv, gikk foran dem og trådte nær til Jesus for å kysse ham. 
42:22:48: Men Jesus sa til ham: Judas! forråder du Menneskesønnen med et kyss? 
42:22:49: Da nu de som var om ham, så hvad som vilde skje, sa de: Herre! skal vi slå til med sverd? 
42:22:50: Og en av dem slo til yppersteprestens tjener og hugg det høire øre av ham. 
42:22:51: Men Jesus svarte og sa: La dem bare gå så vidt! Og han rørte ved hans øre og lægte ham. 
42:22:52: Og Jesus sa til yppersteprestene og høvedsmennene over tempel-vakten og de eldste som var kommet imot ham: I er gått ut som mot en røver med sverd og stokker; 
42:22:53: da jeg daglig var hos eder i templet, rakte I ikke eders hender ut mot mig. Men dette er eders time og mørkets makt. 
42:22:54: Da de nu hadde grepet ham, drog de avsted med ham og førte ham inn i yppersteprestens hus; og Peter fulgte langt bakefter. 
42:22:55: De hadde tendt en ild midt i gårdsrummet og satt der sammen, og Peter satt midt iblandt dem. 
42:22:56: Men en tjenestepike fikk se ham sitte mot schmelken, og stirret på ham og sa: Også denne var med ham. 
42:22:57: Men han fornektet ham og sa: Jeg kjenner ham ikke, kvinne! 
42:22:58: Litt efter fikk en annen se ham og sa: Du er også en av dem. Men Peter sa: Menneske! jeg er ikke det. 
42:22:59: Og omkring én time efter stadfestet en annen det og sa: Sannelig, også denne var med ham; han er jo en galileer. 
42:22:60: Men Peter sa: Menneske! jeg forstår ikke hvad det er du mener! Og straks, mens han ennu talte, gol hanen. 
42:22:61: Og Herren vendte sig og så på Peter; og Peter kom Herrens ord i hu, hvorledes han hadde sagt til ham: Før hanen galer idag, skal du fornekte mig tre ganger. 
42:22:62: Og han gikk ut og gråt bitterlig. 
42:22:63: Og mennene som holdt Jesus, spottet ham og slo ham, 
42:22:64: og de kastet et klæde over ham og spurte ham og sa: Spå nu: Hvem var det som slo dig? 
42:22:65: Og mange andre spottord talte de til ham. 
42:22:66: Da det nu blev dag, samledes folkets eldste og yppersteprestene og de skriftlærde, og de førte ham frem i sitt rådsmøte 
42:22:67: og sa: Er du Messias, da si oss det! Men han sa til dem: Om jeg sier eder det, tror I det ikke; 
42:22:68: og om jeg spør, svarer I ikke. 
42:22:69: Men fra nu av skal Menneskesønnen sitte ved Guds krafts høire hånd. 
42:22:70: Da sa de alle: Er du da Guds Sønn? Han sa til dem: I sier det; jeg er det. 
42:22:71: Da sa de: Hvad skal vi mere med vidnesbyrd? Vi har jo selv hørt det av hans munn. 
42:23:1: Og hele hopen stod op og førte ham for Pilatus; 
42:23:2: og de begynte å føre klagemål imot ham og sa: Denne mann har vi funnet vill-leder vårt folk og forbyder å gi keiseren skatt, og sier om sig selv at han er Messias, en konge. 
42:23:3: Da spurte Pilatus ham: Er du jødenes konge? Han svarte ham: Du sier det. 
42:23:4: Da sa Pilatus til yppersteprestene og folket: Jeg finner ingen skyld hos denne mann. 
42:23:5: Men de tok sterkere i og sa: Han opvigler folket, han lærer over hele Jødeland, fra Galilea av, hvor han begynte, og like hit. 
42:23:6: Da Pilatus hørte det, spurte han om mannen var fra Galilea, 
42:23:7: og da han fikk vite at han hørte under Herodes, sendte han ham til Herodes, som også var i Jerusalem i de dager. 
42:23:8: Og da Herodes så Jesus, blev han meget glad; for han hadde i lang tid ønsket å få se ham, fordi han hadde hørt om ham, og han håpet å få se et tegn av ham. 
42:23:9: Han spurte ham da med mange ord; men Jesus svarte ham intet. 
42:23:10: Og yppersteprestene og de skriftlærde stod og klaget hårdt på ham. 
42:23:11: Men Herodes med sine krigsfolk hånte og spottet ham; derefter kastet han et skinnende klædebon om ham og sendte ham således tilbake til Pilatus. 
42:23:12: Den dag blev Pilatus og Herodes venner; før hadde de ligget i fiendskap med hverandre. 
42:23:13: Da kalte Pilatus yppersteprestene og rådsherrene og folket sammen 
42:23:14: og sa til dem: I har ført denne mann frem for mig som en som forfører folket til frafall; og se, jeg har tatt ham i forhør for eders øine, men jeg har ikke funnet denne mann skyldig i noget av det I klager på ham for; 
42:23:15: Herodes heller ikke; for jeg sendte eder til ham; og se, han har ikke gjort noget som fortjener døden. 
42:23:16: Derfor vil jeg refse ham og så gi ham fri. 
42:23:17: Men på høitiden måtte han gi dem én fri. 
42:23:18: De ropte da alle som én: Bort med denne, men gi oss Barabbas fri! 
42:23:19: Dette var en som var kastet i fengsel for et oprør som hadde vært i byen, og for et mord. 
42:23:20: Pilatus talte da atter til dem, fordi han gjerne vilde gi Jesus fri. 
42:23:21: Men de ropte til ham og sa: Korsfest, korsfest ham! 
42:23:22: Da sa han for tredje gang til dem: Hvad ondt har da denne mann gjort? Jeg har ikke funnet nogen dødsskyld hos ham; derfor vil jeg refse ham og så gi ham fri. 
42:23:23: Men de trengte på med stort skrik og krevde at han skulde korsfestes; og deres skrik fikk overhånd. 
42:23:24: Så felte da Pilatus den dom at det skulde skje som de krevde; 
42:23:25: og han gav den fri som var kastet i fengsel for oprør og mord, ham som de bad om; men Jesus overgav han til deres vilje. 
42:23:26: Og da de førte ham bort, tok de fatt på en mann ved navn Simon, fra Kyrene, som kom fra landet, og de la korset på ham, forat han skulde bære det efter Jesus. 
42:23:27: Og en stor mengde av folket fulgte ham og mange kvinner, som jamret sig og gråt over ham. 
42:23:28: Men Jesus vendte sig om til dem og sa: I Jerusalems døtre! gråt ikke over mig, men gråt over eder selv og over eders barn! 
42:23:29: For se, de dager skal komme da de skal si: Salige er de ufruktbare og det liv som ikke fødte, og det bryst som ikke gav die. 
42:23:30: Da skal de begynne å si til fjellene: Fall over oss! og til haugene: Skjul oss! 
42:23:31: For gjør de så med det grønne tre, hvorledes skal det da gå det tørre? 
42:23:32: Også to andre, to ugjerningsmenn, blev ført bort med ham for å avlives. 
42:23:33: Og da de var kommet til det sted som kalles Hodeskallen, korsfestet de der både ham og ugjerningsmennene, den ene på hans høire og den andre på hans venstre side. 
42:23:34: Men Jesus sa: Fader, forlat dem! for de vet ikke hvad de gjør. Og de delte hans klær mellem sig og kastet lodd om dem. 
42:23:35: Og folket stod og så på; men rådsherrene spottet ham og sa: Andre har han frelst, la ham nu frelse sig selv dersom han er Guds Messias, den utvalgte! 
42:23:36: Også stridsmennene hånte ham, de gikk bort til ham og rakte ham eddik og sa: 
42:23:37: Er du jødenes konge, da frels dig selv! 
42:23:38: Men det var også satt en innskrift over ham: Dette er jødenes konge. 
42:23:39: En av ugjerningsmennene som hang der, spottet ham og sa: Er ikke du Messias? Frels dig selv og oss! 
42:23:40: Men den andre svarte og irettesatte ham og sa: Frykter du ikke engang for Gud, du som dog er under samme dom? 
42:23:41: Og vi med rette; for vi får igjen hvad våre gjerninger har forskyldt; men denne har ikke gjort noget galt. 
42:23:42: Og han sa: Jesus! kom mig i hu når du kommer i ditt rike! 
42:23:43: Og han sa til ham: Sannelig sier jeg dig: Idag skal du være med mig i Paradis. 
42:23:44: Og det var omkring den sjette time, da blev det mørke over hele landet like til den niende time, 
42:23:45: og solen blev formørket, og forhenget i templet revnet midtefter. 
42:23:46: Og Jesus ropte med høi røst og sa: Fader! i dine hender overgir jeg min ånd! Og da han hadde sagt dette, utåndet han. 
42:23:47: Men da høvedsmannen så det som skjedde, gav han Gud æren og sa: Sannelig, denne mann var rettferdig! 
42:23:48: Og alt folket som var kommet sammen for å se dette syn, slo sig for sitt bryst og vendte tilbake da de så hvad som skjedde. 
42:23:49: Men alle hans kjenninger og de kvinner som hadde fulgt ham fra Galilea, stod langt borte og så dette. 
42:23:50: Og se, det var en mann ved navn Josef, som var rådsherre, og en god og rettferdig mann - 
42:23:51: han hadde ikke samtykket i deres råd og gjerning - fra den jødiske by Arimatea, og han ventet på Guds rike; 
42:23:52: han gikk til Pilatus og bad om Jesu legeme, 
42:23:53: og han tok det ned og svøpte det i fint linklæde, og la det i en grav som var hugget i klippen, og som aldri nogen hadde ligget i. 
42:23:54: Det var beredelses-dagen, og sabbaten stundet til. 
42:23:55: Men nogen kvinner som var kommet med ham fra Galilea, fulgte med, og de så graven, og hvorledes hans legeme blev lagt. 
42:23:56: Så vendte de tilbake og tilberedte velluktende urter og salver, og sabbaten over holdt de sig stille efter lovens bud. 
42:24:1: Men på den første dag i uken, tidlig i dagningen, kom de til graven, og hadde med sig de velluktende urter som de hadde tilberedt. 
42:24:2: Men de fant stenen veltet fra graven, 
42:24:3: og da de gikk inn i den, fant de ikke den Herre Jesu legeme. 
42:24:4: Og det skjedde mens de stod rådville ved dette, se, da stod to menn hos dem i skinnende klædebon; 
42:24:5: og da de blev forferdet og bøide sitt ansikt mot jorden, sa de til dem: Hvorfor søker I den levende blandt de døde? 
42:24:6: Han er ikke her, han er opstanden; kom i hu hvorledes han talte til eder mens han ennu var i Galilea, da han sa 
42:24:7: at Menneskesønnen skulde overgis i syndige menneskers hender og korsfestes og opstå på den tredje dag! 
42:24:8: Da kom de hans ord i hu. 
42:24:9: Og de vendte tilbake fra graven, og fortalte alt dette til de elleve og til alle de andre. 
42:24:10: Det var Maria Magdalena og Johanna og Maria, Jakobs mor, og de andre kvinner med dem. De sa dette til apostlene, 
42:24:11: og deres ord syntes dem å være løs tale, og de trodde dem ikke. 
42:24:12: Men Peter stod op og løp til graven, og da han bøide sig ned, så han bare liksvøpet; og han gikk hjem og undret sig over det som var skjedd. 
42:24:13: Og se, to av dem gikk samme dag til en by som ligger seksti stadier fra Jerusalem, og som heter Emmaus, 
42:24:14: og de talte med hverandre om alt dette som hadde hendt. 
42:24:15: Og det skjedde mens de talte sammen og spurte hverandre, da kom Jesus selv nær til dem og vandret sammen med dem; 
42:24:16: men deres øine blev holdt igjen, så de ikke kjente ham. 
42:24:17: Han sa til dem: Hvad er dette for tale som I fører med hverandre på veien? Og de stod stille med sorgfullt åsyn. 
42:24:18: Men en av dem, som hette Kleopas, tok til orde og sa til ham: Er du alene fremmed i Jerusalem og vet ikke det som er skjedd der i disse dager? 
42:24:19: Han sa til dem: Hvad da? Og de sa til ham: Det med Jesus fra Nasaret, som var en profet, mektig i gjerning og ord for Gud og alt folket, 
42:24:20: og hvorledes våre yppersteprester og rådsherrer har overgitt ham til dødsdom og korsfestet ham. 
42:24:21: Men vi håpet at han var den som skulde forløse Israel. Og dog - med alt dette er det idag den tredje dag siden dette skjedde. 
42:24:22: Men så har og nogen av våre kvinner forferdet oss; de kom tidlig imorges til graven, 
42:24:23: og da de ikke fant hans legeme, kom de og sa at de hadde sett et syn av engler, som sa at han lever; 
42:24:24: og nogen av dem som var med oss, gikk bort til graven og fant det så som kvinnene hadde sagt; men ham så de ikke. 
42:24:25: Da sa han til dem: I dårer og senhjertede til å tro alt det profetene har talt! 
42:24:26: Måtte ikke Messias lide dette og så gå inn til sin herlighet? 
42:24:27: Og han begynte fra Moses og fra alle profetene og utla for dem i alle skriftene det som er skrevet om ham. 
42:24:28: Og de var nær ved byen som de gikk til, og han lot som han vilde gå videre. 
42:24:29: Da nødde de ham og sa: Bli hos oss; for det stunder til aften, og dagen heller! Og han gikk inn og blev hos dem. 
42:24:30: Og det skjedde da han satt til bords med dem, da tok han brødet og velsignet det, og brøt det og gav dem; 
42:24:31: da blev deres øine åpnet, og de kjente ham; og han blev usynlig for dem. 
42:24:32: Og de sa til hverandre: Brente ikke vårt hjerte i oss da han talte til oss på veien og oplot skriftene for oss? 
42:24:33: Og de stod op i samme stund og vendte tilbake til Jerusalem, og de fant de elleve samlet, og dem som var med dem, og disse sa: 
42:24:34: Herren er sannelig opstanden, og er sett av Simon! 
42:24:35: Og de fortalte hvad som var skjedd på veien, og hvorledes han blev kjent av dem da han brøt brødet. 
42:24:36: Mens de talte om dette, stod han selv midt iblandt dem og sa til dem: Fred være med eder! 
42:24:37: Men de blev forferdet og fulle av frykt, og trodde at de så en ånd. 
42:24:38: Og han sa til dem: Hvorfor er I forferdet, og hvorfor opstiger tvilende tanker i eders hjerte? 
42:24:39: Se mine hender og mine føtter, og se at det er mig selv! Kjenn på mig og se! En ånd har jo ikke kjøtt og ben, som I ser at jeg har. 
42:24:40: Og da han hadde sagt dette, viste han dem sine hender og sine føtter. 
42:24:41: Men da de ennu ikke trodde for glede, og undret sig, sa han til dem: Har I her noget å ete? 
42:24:42: Da gav de ham et stykke av en stekt fisk og noget av en honningkake, 
42:24:43: og han tok det og åt for deres øine. 
42:24:44: Og han sa til dem: Dette er mine ord som jeg talte til eder mens jeg ennu var hos eder, at alt det måtte opfylles som er skrevet i Mose lov og profetene og salmene om mig. 
42:24:45: Da oplot han deres forstand, så de kunde forstå skriftene. 
42:24:46: Og han sa til dem: Så står skrevet, at Messias skal lide og opstå fra de døde på den tredje dag, 
42:24:47: og at i hans navn skal omvendelse og syndenes forlatelse forkynnes for alle folkeslag, fra Jerusalem av. 
42:24:48: I er vidner om dette. 
42:24:49: Og jeg sender over eder det som min Fader har lovt; men I skal bli i byen inntil I blir iklædd kraft fra det høie. 
42:24:50: Og han førte dem ut imot Betania, og han løftet op sine hender og velsignet dem; 
42:24:51: og det skjedde da han velsignet dem, at han skiltes fra dem og blev optatt til himmelen. 
42:24:52: Og de tilbad ham og vendte tilbake til Jerusalem med stor glede, 
42:24:53: Og de var alltid i templet og lovet og priste Gud. 
43:1:1: I begynnelsen var Ordet, og Ordet var hos Gud, og Ordet var Gud. 
43:1:2: Han var i begynnelsen hos Gud. 
43:1:3: Alt er blitt til ved ham, og uten ham er ikke noget blitt til av alt som er blitt til. 
43:1:4: I ham var liv, og livet var menneskenes schmelk. 
43:1:5: Og schmelken skinner i mørket, og mørket tok ikke imot det. 
43:1:6: Det fremstod en mann, utsendt fra Gud; hans navn var Johannes; 
43:1:7: han kom til vidnesbyrd, for å vidne om schmelken, forat alle skulde komme til troen ved ham. 
43:1:8: Han var ikke schmelken, men han skulde vidne om schmelken. 
43:1:9: Det sanne lys, som oplyser hvert menneske, var i ferd med å komme til verden. 
43:1:10: Han var i verden, og verden er blitt til ved ham, og verden kjente ham ikke. 
43:1:11: Han kom til sitt eget, og hans egne tok ikke imot ham. 
43:1:12: Men alle dem som tok imot ham, dem gav han rett til å bli Guds barn, dem som tror på hans navn; 
43:1:13: og de er født ikke av blod, heller ikke av kjøds vilje, heller ikke av manns vilje, men av Gud. 
43:1:14: Og Ordet blev kjød og tok bolig iblandt oss, og vi så hans herlighet - en herlighet som den en enbåren sønn har fra sin far - full av nåde og sannhet. 
43:1:15: Johannes vidner om ham og roper: Det var denne om hvem jeg sa: Han som kommer efter mig, er kommet foran mig, fordi han var før mig. 
43:1:16: For av hans fylde har vi alle fått, og det nåde over nåde; 
43:1:17: for loven blev gitt ved Moses, nåden og sannheten kom ved Jesus Kristus. 
43:1:18: Ingen har nogensinne sett Gud; den enbårne Sønn, som er i Faderens skjød, han har forklaret ham. 
43:1:19: Og dette er Johannes' vidnesbyrd, da jødene sendte prester og levitter fra Jerusalem for å spørre ham: Hvem er du? 
43:1:20: Da bekjente han og nektet ikke; han bekjente: Jeg er ikke Messias. 
43:1:21: Og de spurte ham: Hvad da? Er du Elias? Han sa: Det er jeg ikke. Er du profeten? Og han svarte: Nei. 
43:1:22: De sa da til ham: Hvem er du? så vi kan gi dem svar som har sendt oss; hvad sier du om dig selv? 
43:1:23: Han sa: Jeg er en røst av en som roper i ørkenen: Gjør Herrens vei jevn, som profeten Esaias har sagt. 
43:1:24: Og de som var utsendt, var fariseere, 
43:1:25: og de spurte ham: Hvorfor døper du da, når du ikke er Messias, og heller ikke Elias, og heller ikke profeten? 
43:1:26: Johannes svarte dem: Jeg døper med vann; midt iblandt eder står den I ikke kjenner, 
43:1:27: han som kommer efter mig, han hvis skorem jeg ikke er verdig til å løse. 
43:1:28: Dette skjedde i Betania på hin side Jordan, hvor Johannes døpte. 
43:1:29: Dagen efter ser han Jesus komme til sig og sier: Se der Guds lam, som bærer verdens synd! 
43:1:30: Det er ham om hvem jeg sa: Efter mig kommer en mann som er kommet foran mig, fordi han var før mig. 
43:1:31: Og jeg kjente ham ikke; men forat han skulde åpenbares for Israel, derfor er jeg kommet og døper med vann. 
43:1:32: Og Johannes vidnet og sa: Jeg har sett Ånden komme ned som en due fra himmelen, og han blev over ham. 
43:1:33: Og jeg kjente ham ikke; men han som sendte mig for å døpe med vann, han sa til mig: Den du ser Ånden komme ned og bli over, han er den som døper med den Hellige Ånd; 
43:1:34: og jeg har sett det, og jeg har vidnet at han er Guds Sønn. 
43:1:35: Dagen efter stod atter Johannes og to av hans disipler der, 
43:1:36: og han så Jesus som kom gående, og sa: Se der Guds lam! 
43:1:37: Og de to disipler hørte hans ord, og de fulgte efter Jesus. 
43:1:38: Da vendte Jesus sig om, og da han så de fulgte efter ham, sa han til dem:\
43:1:38: Hvad søker I? De sa til ham: Rabbi! det er utlagt: mester! hvor har du ditt herberge? 
43:1:39: Han sa til dem: Kom og se! Da kom de og så hvor han hadde sitt herberge, og de blev hos ham den dag; det var omkring den tiende time. 
43:1:40: En av de to som hørte hint ord av Johannes og fulgte efter ham, var Andreas, Simon Peters bror; 
43:1:41: han finner først sin bror Simon, og sier til ham: Vi har funnet Messias, det er utlagt: Kristus. 
43:1:42: Og han førte ham til Jesus. Jesus så på ham og sa: Du er Simon, Johannes' sønn; du skal hete Kefas, det er utlagt: Peter. 
43:1:43: Dagen efter vilde han dra derfra til Galilea, og han traff Filip. Og Jesus sa til ham: Følg mig! 
43:1:44: Men Filip var fra Betsaida, Andreas' og Peters by. 
43:1:45: Filip traff Natanael, og sa til ham: Ham som Moses har skrevet om i loven, og likeså profetene, ham har vi funnet: Jesus, Josefs sønn, fra Nasaret! 
43:1:46: Og Natanael sa til ham: Kan det komme noget godt fra Nasaret? Filip sa til ham: Kom og se! 
43:1:47: Jesus så Natanael komme bortimot sig, og sa om ham: Se, det er en ekte israelitt, som det ikke er svik i. 
43:1:48: Natanael sa til ham: Hvor kjenner du mig fra? Jesus svarte og sa til ham: Før Filip kalte på dig, mens du var under fikentreet, så jeg dig. 
43:1:49: Natanael svarte ham: Rabbi! du er Guds Sønn, du er Israels konge! 
43:1:50: Jesus svarte og sa til ham: Fordi jeg sa dig at jeg så dig under fikentreet, derfor tror du? Du skal få se det som er større enn dette. 
43:1:51: Og han sa til ham: Sannelig, sannelig sier jeg eder: I skal se himmelen åpnet og Guds engler stige op og stige ned over Menneskesønnen. 
43:2:1: Og på den tredje dag var det et bryllup i Kana i Galilea, og Jesu mor var der; 
43:2:2: men også Jesus og hans disipler var innbudt til bryllupet. 
43:2:3: Og da det blev mangel på vin, sa Jesu mor til ham: De har ikke vin. 
43:2:4: Jesus sa til henne: Hvad har jeg med dig å gjøre, kvinne? Min time er ennu ikke kommet. 
43:2:5: Hans mor sa til tjenerne: Hvad han sier eder, det skal I gjøre. 
43:2:6: Nu stod der, efter jødenes renselsesskikk, seks vannkar av sten, hvert på to eller tre anker. 
43:2:7: Jesus sa til dem: Fyll karene med vann! Og de fylte dem til randen. 
43:2:8: Så sa han til dem: Øs nu op og bær det til kjøkemesteren! Og de bar det til ham. 
43:2:9: Men da kjøkemesteren smakte vannet som var blitt til vin, og ikke visste hvor den kom fra - men tjenerne som hadde øst vannet, de visste det - da kalte kjøkemesteren på brudgommen og sa til ham: 
43:2:10: Hver mann setter først den gode vin frem, og når de er blitt drukne, da den ringere; du har gjemt den gode vin til nu. 
43:2:11: Dette sitt første tegn gjorde Jesus i Kana i Galilea og åpenbarte sin herlighet; og hans disipler trodde på ham. 
43:2:12: Derefter drog han ned til Kapernaum, han selv og hans mor og hans brødre og hans disipler, og der blev de nogen få dager. 
43:2:13: Og jødenes påske var nær, og Jesus drog op til Jerusalem. 
43:2:14: Og i templet fant han dem som solgte okser og får og duer, og pengevekslerne som satt der, 
43:2:15: og han gjorde sig en svepe av rep og drev alle ut av templet, både fårene og oksene, og pengevekslernes penger spilte han, og deres bord veltet han, 
43:2:16: og til due-kremmerne sa han: Ta dette bort herfra! gjør ikke min Faders hus til en handelsbod! 
43:2:17: Men hans disipler kom i hu at det er skrevet: Nidkjærhet for ditt hus skal fortære mig. 
43:2:18: Da tok jødene til orde og sa til ham: Hvad for tegn viser du oss, siden du gjør dette? 
43:2:19: Jesus svarte og sa til dem: Bryt dette tempel ned, og på tre dager skal jeg gjenreise det. 
43:2:20: Da sa jødene: I seks og firti år har det vært bygget på dette tempel, og du vil gjenreise det på tre dager? 
43:2:21: Men han talte om sitt legemes tempel. 
43:2:22: Da han nu var opstanden fra de døde, kom hans disipler i hu at han hadde sagt dette, og de trodde Skriften og det ord som Jesus hadde sagt. 
43:2:23: Mens han nu var i Jerusalem i påsken, på høitiden, trodde mange på hans navn da de så de tegn han gjorde; 
43:2:24: men Jesus selv betrodde sig ikke til dem, fordi han kjente alle, 
43:2:25: og fordi han ikke trengte til at nogen skulde vidne om et menneske; for han visste selv hvad som bodde i mennesket. 
43:3:1: Og det var en mann av fariseerne som hette Nikodemus, en av jødenes rådsherrer; 
43:3:2: han kom til ham om natten og sa til ham: Rabbi! vi vet at du er en lærer kommet fra Gud; for ingen kan gjøre disse tegn som du gjør, uten at Gud er med ham. 
43:3:3: Jesus svarte og sa til ham: Sannelig, sannelig sier jeg dig: Uten at nogen blir født på ny, kan han ikke se Guds rike. 
43:3:4: Nikodemus sier til ham: Hvorledes kan et menneske fødes når han er gammel? kan han vel annen gang komme inn i sin mors liv og fødes? 
43:3:5: Jesus svarte: Sannelig, sannelig sier jeg dig: Uten at nogen blir født av vann og Ånd, kan han ikke komme inn i Guds rike. 
43:3:6: Det som er født av kjødet, er kjød, og det som er født av Ånden, er ånd. 
43:3:7: Undre dig ikke over at jeg sa til dig: I må fødes på ny! 
43:3:8: Vinden blåser dit den vil, og du hører den suser; men du vet ikke hvor den kommer fra, og hvor den farer hen; således er det med hver den som er født av Ånden. 
43:3:9: Nikodemus svarte og sa til ham: Hvorledes kan dette skje? 
43:3:10: Jesus svarte og sa til ham: Du er Israels lærer, og vet ikke dette? 
43:3:11: Sannelig, sannelig sier jeg dig: Vi taler det vi vet, og vi vidner det vi har sett, og I tar ikke imot vårt vidnesbyrd. 
43:3:12: Når jeg har sagt eder de jordiske ting, og I ikke tror, hvorledes skal I da tro om jeg sier eder de himmelske? 
43:3:13: Og dog er ingen steget op til himmelen uten han som er steget ned fra himmelen, Menneskesønnen, som er i himmelen. 
43:3:14: Og likesom Moses ophøiet slangen i ørkenen, således skal Menneskesønnen ophøies, 
43:3:15: forat hver den som tror på ham, skal ha evig liv. 
43:3:16: For så har Gud elsket verden at han gav sin Sønn, den enbårne, forat hver den som tror på ham, ikke skal fortapes, men ha evig liv; 
43:3:17: for Gud sendte ikke sin Sønn til verden for å dømme verden, men forat verden skulde bli frelst ved ham. 
43:3:18: Den som tror på ham, blir ikke dømt; den som ikke tror, er allerede dømt, fordi han ikke har trodd på Guds enbårne Sønns navn. 
43:3:19: Og dette er dommen, at schmelken er kommet til verden, og menneskene elsket mørket fremfor schmelken; for deres gjerninger var onde. 
43:3:20: For hver den som gjør ondt, hater schmelken og kommer ikke til schmelken, forat hans gjerninger ikke skal bli refset; 
43:3:21: men den som gjør sannheten, han kommer til schmelken, forat hans gjerninger må bli åpenbaret; for de er gjort i Gud. 
43:3:22: Derefter kom Jesus og hans disipler til Judea, og han blev der sammen med dem og døpte. 
43:3:23: Men også Johannes døpte, i Ænon, nær ved Salim, fordi det var meget vann der, og folk kom dit og lot sig døpe; 
43:3:24: for Johannes var enda ikke kastet i fengsel. 
43:3:25: Det blev nu en trette mellem Johannes' disipler og en jøde om renselsen, 
43:3:26: og de kom til Johannes og sa til ham: Rabbi! han som var hos dig på hin side Jordan, han som du har gitt vidnesbyrd, se, han døper, og alle kommer til ham! 
43:3:27: Johannes svarte og sa: Et menneske kan ikke få noget uten at det er gitt ham fra himmelen. 
43:3:28: I er selv mine vidner at jeg sa: Jeg er ikke Messias, men: Jeg er utsendt foran ham. 
43:3:29: Den som har bruden, han er brudgom; men brudgommens venn, som står og hører på ham, gleder sig storlig over brudgommens røst. Denne min glede er nu blitt fullkommen. 
43:3:30: Han skal vokse, jeg skal avta. 
43:3:31: Han som kommer ovenfra, er over alle; den som er av jorden, er av jorden og taler av jorden. Han som kommer fra himmelen, er over alle; 
43:3:32: det han har sett og hørt, det vidner han, og ingen tar imot hans vidnesbyrd; 
43:3:33: den som har tatt imot hans vidnesbyrd, han har stadfestet at Gud er sanndru. 
43:3:34: For han som Gud har utsendt, taler Guds ord; for Gud gir ikke Ånden efter mål. 
43:3:35: Faderen elsker Sønnen, og alt har han gitt i hans hånd. 
43:3:36: Den som tror på Sønnen, har evig liv; men den som ikke vil tro på Sønnen, skal ikke se livet, men Guds vrede blir over ham. 
43:4:1: Da nu Herren fikk vite at fariseerne hadde hørt at Jesus vant flere disipler og døpte flere enn Johannes 
43:4:2: - dog var det ikke Jesus selv som døpte, men hans disipler - 
43:4:3: da forlot han Judea og drog atter bort til Galilea. 
43:4:4: Han måtte da reise gjennem Samaria. 
43:4:5: Så kom han til en by i Samaria, som heter Sykar, nær ved det stykke land som Jakob gav sin sønn Josef; 
43:4:6: og der var Jakobs brønn. Jesus, som var trett av reisen, satt nu der ved brønnen; det var omkring den sjette time. 
43:4:7: En kvinne fra Samaria kommer for å dra op vann. Jesus sier til henne: Gi mig å drikke! 
43:4:8: Hans disipler var gått bort til byen for å kjøpe mat. 
43:4:9: Den samaritanske kvinne sier da til ham: Hvorledes kan du som er jøde, be mig, en samaritansk kvinne, om å få drikke? - for jøder har ikke samkvem med samaritaner. 
43:4:10: Jesus svarte og sa til henne: Kjente du Guds gave, og visste du hvem det er som sier til dig: Gi mig å drikke! da hadde du bedt ham, og han hadde gitt dig levende vann. 
43:4:11: Kvinnen sier til ham: Herre! du har jo ikke noget å dra op vann med, og brønnen er dyp; hvor har du da det levende vann fra? 
43:4:12: Du er da vel ikke større enn vår far Jakob, som gav oss brønnen og selv drakk av den, han og hans sønner og hans fe? 
43:4:13: Jesus svarte og sa til henne: Enhver som drikker av dette vann, skal tørste igjen; 
43:4:14: men den som drikker av det vann jeg vil gi ham, skal aldri i evighet tørste, men det vann jeg vil gi ham, blir i ham en kilde med vann som veller frem til evig liv. 
43:4:15: Kvinnen sier til ham: Herre! gi mig dette vann, så jeg kan slippe å tørste og å gå hit for å dra op vann! 
43:4:16: Han sier til henne: Gå avsted, kall på din mann, og kom så hit! 
43:4:17: Kvinnen svarte: Jeg har ingen mann. Jesus sa til henne: Med rette sa du: Jeg har ingen mann; 
43:4:18: for du har hatt fem menn, og den du nu har, er ikke din mann. Der talte du sant. 
43:4:19: Kvinnen sier til ham: Herre! jeg ser at du er en profet. 
43:4:20: Våre fedre tilbad på dette fjell, og I sier at i Jerusalem er det sted hvor en skal tilbede. 
43:4:21: Jesus sier til henne: Tro mig, kvinne! den time kommer da I hverken skal tilbede Faderen på dette fjell eller i Jerusalem. 
43:4:22: I tilbeder det I ikke kjenner, vi tilbeder det vi kjenner; for frelsen kommer fra jødene; 
43:4:23: men den time kommer, og er nu, da de sanne tilbedere skal tilbede Faderen i ånd og sannhet; for det er sådanne tilbedere Faderen vil ha. 
43:4:24: Gud er ånd, og de som tilbeder ham, bør tilbede i ånd og sannhet. 
43:4:25: Kvinnen sier til ham: Jeg vet at Messias kommer, det er utlagt: Kristus; når han kommer, skal han forkynne oss alt. 
43:4:26: Jesus sier til henne: Det er mig, jeg som taler med dig! 
43:4:27: Og i det samme kom hans disipler, og de undret sig over at han talte med en kvinne; dog sa ingen: Hvad vil du henne, eller: hvorfor taler du med henne? 
43:4:28: Kvinnen lot da sin vannkrukke stå og gikk bort til byen og sa til folket der: 
43:4:29: Kom og se en mann som har sagt mig alt jeg har gjort! Han skulde vel ikke være Messias? 
43:4:30: De gikk ut av byen og var på veien til ham. 
43:4:31: Imens bad disiplene ham og sa: Rabbi, et! 
43:4:32: Men han sa til dem: Jeg har mat å ete som I ikke vet om. 
43:4:33: Disiplene sa da til hverandre: Skulde nogen ha båret mat til ham? 
43:4:34: Jesus sier til dem: Min mat er å gjøre hans vilje som har sendt mig, og å fullføre hans gjerning. 
43:4:35: Sier ikke I at det ennu er fire måneder, så kommer høsten? Se, jeg sier eder: Løft eders øine og se markene, de er alt hvite til høsten! 
43:4:36: Den som høster, får lønn og samler frukt til evig liv, forat både den som sår og den som høster, kan glede sig sammen; 
43:4:37: for her er det et sant ord at en sår og en annen høster. 
43:4:38: Jeg har utsendt eder for å høste det som ikke I har arbeidet med; andre har arbeidet, og I er kommet inn i deres arbeid. 
43:4:39: Men mange av samaritanene fra den by trodde på ham for kvinnens ords skyld, da hun vidnet: Han har sagt mig alt jeg har gjort. 
43:4:40: Da nu samaritanene kom til ham, bad de ham bli hos dem; og han blev der to dager. 
43:4:41: Og mange flere trodde for hans ords skyld, 
43:4:42: og de sa til kvinnen: Nu tror vi ikke lenger for din tales skyld; for vi har selv hørt, og vi vet nu at han sannelig er verdens frelser. 
43:4:43: Efter de to dager drog han derfra til Galilea; 
43:4:44: for Jesus vidnet selv at en profet blir ikke aktet på sitt eget hjemsted. 
43:4:45: Da han nu kom til Galilea, tok galileerne imot ham, fordi de hadde sett alt det han hadde gjort i Jerusalem på høitiden; for også de var kommet til høitiden. 
43:4:46: Han kom da atter til Kana i Galilea, der hvor han hadde gjort vann til vin. Og det var en kongens mann i Kapernaum, som hadde en syk sønn; 
43:4:47: da han fikk høre at Jesus var kommet fra Judea til Galilea, drog han til ham og bad at han vilde komme ned og helbrede hans sønn; for han var nær ved å dø. 
43:4:48: Jesus sa da til ham: Uten at I ser tegn og under, tror I ikke. 
43:4:49: Kongens mann sier til ham: Herre! kom ned før mitt barn dør! 
43:4:50: Jesus sier til ham: Gå hjem, din sønn lever! Mannen trodde det ord Jesus sa til ham, og gikk. 
43:4:51: Da han nu alt var på hjemveien, møtte hans tjenere ham og fortalte at hans barn levde. 
43:4:52: Han spurte dem da om den time da det var blitt bedre med ham; de sa til ham: Igår ved den syvende time forlot feberen ham. 
43:4:53: Faren skjønte da at det var den time da Jesus sa til ham: Din sønn lever; og han trodde selv, og hele hans hus. 
43:4:54: Dette var det annet tegn som Jesus gjorde da han var kommet fra Judea til Galilea. 
43:5:1: Derefter var det en av jødenes høitider, og Jesus drog op til Jerusalem. 
43:5:2: Men det er ved Fåreporten i Jerusalem en dam som på hebraisk heter Betesda og har fem bueganger; 
43:5:3: i dem lå en mengde syke: blinde, halte, visne. 
43:5:4: For en engel steg til visse tider ned i dammen og oprørte vannet. Den som da først steg ned efterat vannet var blitt oprørt, han blev helbredet, hvad sykdom han så led av. 
43:5:5: Nu var der en mann som hadde vært syk i åtte og tretti år; 
43:5:6: da Jesus så ham ligge der, og visste at han allerede hadde vært syk i lang tid, sa han til ham: Vil du bli frisk? 
43:5:7: Den syke svarte ham: Herre! jeg har ingen til å kaste mig ned i dammen når vannet blir oprørt; og i det samme jeg kommer, stiger en annen ned før mig. 
43:5:8: Jesus sier til ham: Stå op, ta din seng og gå! 
43:5:9: Og straks blev mannen frisk og han tok sin seng og gikk. Men det var sabbat den dag. 
43:5:10: Jødene sa da til ham som var blitt helbredet: Det er sabbat, og det er dig ikke tillatt å bære sengen. 
43:5:11: Han svarte dem: Han som gjorde mig frisk, han sa til mig: Ta din seng og gå! 
43:5:12: De spurte ham: Hvem er det menneske som sa til dig: Ta den og gå? 
43:5:13: Men han som var blitt helbredet, visste ikke hvem det var; for Jesus hadde trukket sig tilbake, da det var meget folk på stedet. 
43:5:14: Siden traff Jesus ham i templet og sa til ham: Se, du er blitt frisk; synd ikke mere, forat ikke noget verre skal vederfares dig! 
43:5:15: Mannen gikk bort, og fortalte jødene at det var Jesus som hadde gjort ham frisk. 
43:5:16: Og derfor forfulgte jødene Jesus, fordi han gjorde dette på en sabbat. 
43:5:17: Men Jesus svarte dem: Min Fader arbeider inntil nu; også jeg arbeider. 
43:5:18: Derfor stod da jødene ham enn mere efter livet, fordi han ikke bare brøt sabbaten, men også kalte Gud sin Fader og gjorde sig selv Gud lik. 
43:5:19: Jesus svarte da og sa til dem: Sannelig, sannelig sier jeg eder: Sønnen kan ikke gjøre noget av sig selv, men bare det han ser Faderen gjør; for det han gjør, det gjør Sønnen likeså; 
43:5:20: for Faderen elsker Sønnen, og viser ham alt det han selv gjør; og han skal vise ham større gjerninger enn disse, forat I skal undre eder. 
43:5:21: For likesom Faderen opvekker de døde og gjør levende, således gjør også Sønnen levende hvem han vil. 
43:5:22: For Faderen dømmer heller ikke nogen, men har gitt Sønnen hele dommen, 
43:5:23: forat alle skal ære Sønnen, likesom de ærer Faderen. Den som ikke ærer Sønnen, ærer ikke Faderen, som har sendt ham. 
43:5:24: Sannelig, sannelig sier jeg eder: Den som hører mitt ord og tror ham som har sendt mig, han har evig liv og kommer ikke til dom, men er gått over fra døden til livet. 
43:5:25: Sannelig, sannelig sier jeg eder: Den time kommer, og er nu, da de døde skal høre Guds Sønns røst, og de som hører, skal leve. 
43:5:26: For likesom Faderen har liv i sig selv, således har han også gitt Sønnen å ha liv i sig selv, 
43:5:27: og han har gitt ham makt til å holde dom, fordi han er en menneskesønn. 
43:5:28: Undre eder ikke over dette! For den time kommer da alle de som er i gravene, skal høre hans røst, 
43:5:29: og de skal gå ut, de som har gjort godt, til livets opstandelse, de som har gjort ondt, til dommens opstandelse. 
43:5:30: Jeg kan ikke gjøre noget av mig selv; som jeg hører, så dømmer jeg, og min dom er rettferdig; for jeg søker ikke min vilje, men hans vilje som har sendt mig. 
43:5:31: Vidner jeg om mig selv, da er mitt vidnesbyrd ikke sant; 
43:5:32: det er en annen som vidner om mig, og jeg vet at det vidnesbyrd han vidner om mig, er sant. 
43:5:33: I har sendt bud til Johannes, og han har vidnet for sannheten; 
43:5:34: jeg tar ikke imot vidnesbyrd av et menneske, men jeg sier dette forat I skal bli frelst. 
43:5:35: Han var det brennende og skinnende lys, men I vilde bare en tid fryde eder i hans schmelk. 
43:5:36: Men jeg har det vidnesbyrd som er større enn det Johannes har vidnet; for de gjerninger som Faderen har gitt mig å fullbyrde, selve disse gjerninger som jeg gjør, de vidner om mig at Faderen har utsendt mig. 
43:5:37: Og Faderen, som har sendt mig, han har vidnet om mig; hverken har I nogensinne hørt hans røst eller sett hans skikkelse, 
43:5:38: og hans ord har I ikke blivende i eder; for den han har sendt, ham tror I ikke. 
43:5:39: I ransaker skriftene, fordi I tenker at i dem har I evig liv, og det er de som vidner om mig; 
43:5:40: og I vil ikke komme til mig for å få liv. 
43:5:41: Jeg tar ikke ære av mennesker; 
43:5:42: men jeg kjenner eder og vet at I ikke har kjærligheten til Gud i eder. 
43:5:43: Jeg er kommet i min Faders navn, og I tar ikke imot mig; kommer en annen i sitt eget navn, ham tar I imot. 
43:5:44: Hvorledes kan I tro, I som tar ære av hverandre, og den ære som er av den eneste Gud, den søker I ikke? 
43:5:45: I må ikke tenke at jeg vil anklage eder hos Faderen; det er en som anklager eder, Moses, han som I har satt eders håp til. 
43:5:46: For hadde I trodd Moses, da hadde I trodd mig; for det er om mig han har skrevet; 
43:5:47: men tror I ikke hans skrifter, hvorledes kan I da tro mine ord? 
43:6:1: Derefter drog Jesus bort til hin side av den Galileiske Sjø, Tiberias-sjøen; 
43:6:2: og meget folk fulgte ham, fordi de så de tegn han gjorde på de syke. 
43:6:3: Men Jesus gikk op i fjellet, og han satte sig der med sine disipler. 
43:6:4: Og påsken, jødenes høitid, var nær. 
43:6:5: Da nu Jesus løftet sine øine og så at meget folk kom til ham, sa han til Filip: Hvor skal vi kjøpe brød, så disse kan få mat? 
43:6:6: Men dette sa han for å prøve ham; for han visste selv hvad han vilde gjøre. 
43:6:7: Filip svarte ham: Brød for to hundre penninger er ikke nok for dem så hver av dem kan få et lite stykke. 
43:6:8: En av hans disipler, Andreas, Simon Peters bror, sier til ham: 
43:6:9: Her er en liten gutt som har fem byggbrød og to småfisker; men hvad er det til så mange? 
43:6:10: Jesus sa: La folket sette sig ned! Det var meget gress på stedet, og mennene satte sig da ned, omkring fem tusen i tallet. 
43:6:11: Da tok Jesus brødene og takket, og delte dem ut til dem som satt der, likeledes av småfiskene, så meget de vilde ha. 
43:6:12: Men da de var blitt mette, sier han til sine disipler: Sank sammen stykkene som er blitt tilovers, forat ikke noget skal spilles! 
43:6:13: Da sanket de sammen, og de fylte tolv kurver med stykker av de fem byggbrød, som var blitt tilovers efter dem som hadde ett. 
43:6:14: Da nu folket så det tegn han gjorde, sa de: Dette er i sannhet profeten som skal komme til verden. 
43:6:15: Da nu Jesus skjønte at de vilde komme og ta ham med makt for å gjøre ham til konge, gikk han fra dem og op i fjellet igjen, han selv alene. 
43:6:16: Men da det blev aften, gikk hans disipler ned til sjøen, 
43:6:17: og de gikk i en båt og fór over til hin side av sjøen, til Kapernaum. Og det var allerede blitt mørkt, og Jesus var enda ikke kommet til dem. 
43:6:18: Og sjøen gikk høit, for det blåste en sterk vind. 
43:6:19: Da de nu hadde rodd en fem og tyve eller tretti stadier, ser de Jesus gå på sjøen og komme nær til båten, og de blev redde. 
43:6:20: Men han sa til dem: Det er mig, frykt ikke! 
43:6:21: Nu vilde de ta ham op i båten, og straks kom båten til landet som de fór til. 
43:6:22: Den næste dag så folket som stod på hin side av sjøen, at det ikke var nogen annen båt der, og visste at det bare hadde vært én, og at Jesus ikke var gått i båten sammen med sine disipler, men at hans disipler hadde faret bort alene. 
43:6:23: Imens kom det andre båter fra Tiberias nær til det sted hvor de hadde fått mat efter Herrens takkebønn. 
43:6:24: Da nu folket så at Jesus ikke var der, og heller ikke hans disipler, gikk de i båtene, og kom til Kapernaum og søkte efter Jesus. 
43:6:25: Og da de fant ham på hin side av sjøen, sa de til ham: Rabbi! når er du kommet hit? 
43:6:26: Jesus svarte dem og sa: Sannelig, sannelig sier jeg eder: I søker mig, ikke fordi I så tegn, men fordi I åt av brødene og blev mette. 
43:6:27: Arbeid ikke for den mat som forgår, men for den mat som varer ved til evig liv, den som Menneskesønnen skal gi eder! for på ham har hans Fader, Gud, satt sitt innsegl. 
43:6:28: De sa da til ham: Hvad skal vi gjøre for å gjøre Guds gjerninger? 
43:6:29: Jesus svarte og sa til dem: Dette er Guds gjerning at I skal tro på den han har utsendt. 
43:6:30: De sa da til ham: Hvad tegn gjør da du, så vi kan se det og tro dig? hvad gjerning gjør du? 
43:6:31: Våre fedre åt manna i ørkenen, som skrevet står: Han gav dem brød fra himmelen å ete. 
43:6:32: Jesus sa til dem: Sannelig, sannelig sier jeg eder: Moses har ikke gitt eder brødet fra himmelen, men min Fader gir eder det sanne brød fra himmelen; 
43:6:33: for Guds brød er det som kommer ned fra himmelen og gir verden liv. 
43:6:34: De sa da til ham: Herre, gi oss alltid dette brød! 
43:6:35: Jesus sa til dem: Jeg er livsens brød; den som kommer til mig, skal ikke hungre, og den som tror på mig, skal aldri nogensinne tørste. 
43:6:36: Men jeg har sagt eder at I har sett mig og tror dog ikke. 
43:6:37: Alle de som Faderen gir mig, kommer til mig, og den som kommer til mig, vil jeg ingenlunde støte ut; 
43:6:38: for jeg er kommet ned fra himmelen, ikke for å gjøre min vilje, men for å gjøre hans vilje som har sendt mig, 
43:6:39: og dette er hans vilje som har sendt mig, at jeg ikke skal miste noget av alt det han har gitt mig, men opreise det på den ytterste dag. 
43:6:40: For dette er min Faders vilje at hver den som ser Sønnen og tror på ham, skal ha evig liv, og at jeg skal opreise ham på den ytterste dag. 
43:6:41: Da knurret jødene over ham fordi han sa: Jeg er det brød som er kommet ned fra himmelen, 
43:6:42: og de sa: Er ikke dette Jesus, Josefs sønn, hvis far og mor vi kjenner? Hvorledes kan han nu si: Jeg er kommet ned fra himmelen? 
43:6:43: Jesus svarte og sa til dem: Knurr ikke eder imellem! 
43:6:44: Ingen kan komme til mig uten at Faderen, som har sendt mig, drager ham, og jeg skal opreise ham på den ytterste dag. 
43:6:45: Det står skrevet i profetene: Og de skal alle være lært av Gud. Hver den som hører av Faderen og lærer, han kommer til mig. 
43:6:46: Ikke så at nogen har sett Faderen; bare han som er fra Gud, han har sett Faderen. 
43:6:47: Sannelig, sannelig sier jeg eder: Den som tror, har evig liv. 
43:6:48: Jeg er livsens brød. 
43:6:49: Eders fedre åt manna i ørkenen og døde; 
43:6:50: dette er det brød som kommer ned fra himmelen, forat en skal ete av det og ikke dø. 
43:6:51: Jeg er det levende brød, som er kommet ned fra himmelen; om nogen eter av dette brød, skal han leve evindelig; og det brød jeg vil gi, er mitt kjød, som jeg vil gi for verdens liv. 
43:6:52: Jødene trettet da med hverandre og sa: Hvorledes kan han gi oss sitt kjød å ete? 
43:6:53: Jesus sa da til dem: Sannelig, sannelig sier jeg eder: Dersom I ikke eter Menneskesønnens kjød og drikker hans blod, har I ikke liv i eder. 
43:6:54: Den som eter mitt kjød og drikker mitt blod, har evig liv, og jeg skal opreise ham på den ytterste dag; 
43:6:55: for mitt kjød er i sannhet mat, og mitt blod er i sannhet drikke. 
43:6:56: Den som eter mitt kjød og drikker mitt blod, han blir i mig og jeg i ham. 
43:6:57: Likesom den levende Fader har utsendt mig, og jeg lever ved Faderen, således skal også den som eter mig, leve ved mig. 
43:6:58: Dette er det brød som er kommet ned fra himmelen; ikke således som fedrene åt og døde; den som eter dette brød, skal leve evindelig. 
43:6:59: Dette sa han mens han lærte i en synagoge i Kapernaum. 
43:6:60: Mange av hans disipler sa nu, da de hørte det: Dette er en hård tale; hvem kan høre den? 
43:6:61: Men da Jesus visste med sig selv at hans disipler knurret over dette, sa han til dem: Volder dette eder anstøt? 
43:6:62: Enn når I får se Menneskesønnen fare op dit hvor han var før? 
43:6:63: Det er Ånden som gjør levende, kjødet gagner intet; de ord som jeg har talt til eder, er ånd og er liv. 
43:6:64: Men det er nogen av eder som ikke tror. For Jesus visste fra først av hvem det var som ikke trodde, og hvem det var som skulde forråde ham. 
43:6:65: Og han sa: Det var derfor jeg sa eder at ingen kan komme til mig uten at det er gitt ham av Faderen. 
43:6:66: Derfor drog mange av hans disipler sig tilbake og gikk ikke lenger omkring med ham. 
43:6:67: Jesus sa da til de tolv: Vil også I gå bort? 
43:6:68: Simon Peter svarte ham: Herre! hvem skal vi gå til? Du har det evige livs ord, 
43:6:69: og vi tror og vet at du er Guds hellige. 
43:6:70: Jesus svarte dem: Har ikke jeg utvalgt eder tolv? Og én av eder er en djevel. 
43:6:71: Men han talte om Judas, Simon Iskariots sønn; for det var han som skulde forråde ham, enda han var en av de tolv. 
43:7:1: Og derefter gikk Jesus omkring i Galilea; for han vilde ikke gå omkring i Judea, fordi jødene stod ham efter livet. 
43:7:2: Og jødenes høitid, løvsalenes fest, var nær. 
43:7:3: Hans brødre sa da til ham: Dra bort herfra og gå til Judea, forat også dine disipler kan få se de gjerninger som du gjør! 
43:7:4: for ingen gjør noget i lønndom og attrår dog selv å være almindelig kjent; gjør du sådanne ting, da åpenbar dig for verden! 
43:7:5: For heller ikke hans brødre trodde på ham. 
43:7:6: Jesus sier til dem: Min tid er ennu ikke kommet; men eders tid er alltid forhånden. 
43:7:7: Verden kan ikke hate eder; men mig hater den fordi jeg vidner om den at dens gjerninger er onde. 
43:7:8: Dra I op til høitiden! jeg drar ikke op til denne høitid; for min tid er ennu ikke fullkommet. 
43:7:9: Da han hadde sagt dette til dem, blev han i Galilea. 
43:7:10: Men da hans brødre hadde draget op til høiden, da drog også han der op, dog ikke åpenbart, men som i lønndom. 
43:7:11: Jødene lette da efter ham på høitiden og sa: Hvor er han? 
43:7:12: Og det blev mumlet meget om ham blandt folket; nogen sa: Han er en god mann, men andre sa: Nei, han fører folket vill. 
43:7:13: Dog talte ingen fritt ut om ham, av frykt for jødene. 
43:7:14: Men da det allerede var midt i høitiden, gikk Jesus op i templet og lærte. 
43:7:15: Jødene undret sig da og sa: Hvor har han sin lærdom fra, han som ikke er oplært? 
43:7:16: Jesus svarte dem og sa: Min lære er ikke min, men hans som har sendt mig; 
43:7:17: vil nogen gjøre hans vilje, han skal kjenne om læren er av Gud, eller om jeg taler av mig selv. 
43:7:18: Den som taler av sig selv, søker sin egen ære; men den som søker hans ære som har sendt ham, han er sanndru, og det er ikke urettferdighet i ham. 
43:7:19: Har ikke Moses gitt eder loven? Og ingen av eder holder loven. Hvorfor står I mig efter livet? 
43:7:20: Folket svarte: Du er besatt; hvem står dig efter livet? 
43:7:21: Jesus svarte og sa til dem: En gjerning gjorde jeg, og I undrer eder alle over den. 
43:7:22: Moses har gitt eder omskjærelsen - ikke så at den er fra Moses, men fra fedrene - og I omskjærer et menneske på sabbaten; 
43:7:23: når nu et menneske blir omskåret på sabbaten, forat Mose lov ikke skal brytes, harmes I da på mig fordi jeg har gjort et helt menneske friskt på sabbaten? 
43:7:24: Døm ikke efter synet, men døm en rettferdig dom! 
43:7:25: Nogen av dem som hørte hjemme i Jerusalem, sa da: Er det ikke ham de står efter livet? 
43:7:26: Og se, han taler fritt ut, og de sier ikke et ord til ham; skulde virkelig våre rådsherrer være blitt overtydet om at han er Messias? 
43:7:27: Men om denne mann vet vi hvor han er fra; men når Messias kommer, vet ingen hvor han er fra. 
43:7:28: Mens nu Jesus lærte i templet, ropte han ut: Både kjenner I mig, og I vet hvor jeg er fra; og av mig selv er jeg dog ikke kommet; men det er i sannhet en som har sendt mig, han som I ikke kjenner. 
43:7:29: Jeg kjenner ham; for fra ham er jeg, og han har utsendt mig. 
43:7:30: De søkte da å få grepet ham; men ingen la hånd på ham, for hans time var ennu ikke kommet. 
43:7:31: Men av folket var det mange som trodde på ham, og de sa: Når Messias kommer, mon han da vil gjøre flere tegn enn denne har gjort? 
43:7:32: Fariseerne hørte folket mumle dette om ham, og yppersteprestene og fariseerne sendte tjenere avsted for å gripe ham. 
43:7:33: Jesus sa da: Ennu en kort tid er jeg hos eder, så går jeg bort til ham som har sendt mig. 
43:7:34: I skal lete efter mig og ikke finne mig, og der hvor jeg er, kan I ikke komme. 
43:7:35: Jødene sa da til hverandre: Hvor vil han gå bort, siden vi ikke skal finne ham? Mon han vil gå til dem som er spredt omkring blandt grekerne, og lære grekerne? 
43:7:36: Hvad er dette for et ord han sa: I skal lete efter mig og ikke finne mig, og der hvor jeg er, kan I ikke komme? 
43:7:37: Men på den siste, den store dag i høitiden stod Jesus og ropte ut: Om nogen tørster, han komme til mig og drikke! 
43:7:38: Den som tror på mig, av hans liv skal det, som Skriften har sagt, rinne strømmer av levende vann. 
43:7:39: Dette sa han om den Ånd som de skulde få som trodde på ham; for Ånden var ennu ikke kommet, fordi Jesus ennu ikke var herliggjort. 
43:7:40: Nogen av folket sa nu, da de hørte disse ord: Dette er i sannhet profeten. 
43:7:41: Andre sa: Dette er Messias. Andre igjen sa: Messias kommer da vel ikke fra Galilea? 
43:7:42: Har ikke Skriften sag at Messias kommer av Davids ætt og fra Betlehem, den by hvor David var? 
43:7:43: Det blev da splid iblandt folket for hans skyld. 
43:7:44: Og nogen av dem vilde gripe ham; men ingen la hånd på ham. 
43:7:45: Tjenerne kom da til yppersteprestene og fariseerne, og disse sa til dem: Hvorfor har I ikke ført ham hit? 
43:7:46: Tjenerne svarte: Aldri har noget menneske talt således som denne mann. 
43:7:47: Fariseerne svarte dem da: Har også I latt eder dåre? 
43:7:48: Har vel nogen av rådsherrene trodd på ham, eller nogen av fariseerne? 
43:7:49: Men denne hop som ikke kjenner loven, er forbannet. 
43:7:50: Nikodemus, han som før engang var kommet til ham, og som var en av dem, sier til dem: 
43:7:51: Vår lov dømmer da vel ikke nogen uten at de først har hørt ham og fått vite hvad han har gjort? 
43:7:52: De svarte ham: Kanskje du også er fra Galilea? Ransak, så skal du se at ingen profet kommer fra Galilea! 
43:7:53: Og de gikk hver til sitt. 
43:8:1: Men Jesus gikk til Oljeberget. 
43:8:2: Og tidlig om morgenen kom han atter til templet, og alt folket kom til ham, og han satte sig og lærte dem. 
43:8:3: Da kom de skriftlærde og fariseerne med en kvinne som var grepet i hor, og de stilte henne frem for ham 
43:8:4: og sa til ham: Mester! denne kvinne er grepet på fersk gjerning i hor. 
43:8:5: I loven har Moses foreskrevet oss at slike kvinner skal stenes; hvad sier nu du? 
43:8:6: Dette sa de for å friste ham, så de kunde få klagemål imot ham. Men Jesus bukket sig ned og skrev med fingeren på jorden. 
43:8:7: Og da de blev ved å spørre, rettet han sig op og sa: Den av eder som er uten synd, han kaste den første sten på henne! 
43:8:8: Og han bukket sig atter ned og skrev på jorden. 
43:8:9: Men da de hørte det, gikk de ut, den ene efter den annen, de eldste først; og Jesus blev alene tilbake med kvinnen som stod der. 
43:8:10: Da rettet Jesus sig op og sa til henne: Kvinne! hvor er de? Har ingen fordømt dig? 
43:8:11: Hun sa: Nei, Herre! ingen. Da sa Jesus: Heller ikke jeg fordømmer dig; gå bort, og synd ikke mere! 
43:8:12: Atter talte da Jesus til dem og sa: Jeg er verdens schmelk; den som følger mig, skal ikke vandre i mørket, men ha livsens schmelk. 
43:8:13: Fariseerne sa da til ham: Du vidner om dig selv; ditt vidnesbyrd er ikke sant. 
43:8:14: Jesus svarte og sa til dem: Om jeg også vidner om mig selv, er mitt vidnesbyrd sant; for jeg vet hvorfra jeg er kommet, og hvor jeg går hen; men I vet ikke hvorfra jeg kommer, eller hvor jeg går hen. 
43:8:15: I dømmer efter kjødet; jeg dømmer ingen. 
43:8:16: Men om jeg også dømmer, da er min dom rett; for jeg er ikke alene, men jeg og Faderen, som har sendt mig; 
43:8:17: men også i eders lov står det skrevet at to menneskers vidnesbyrd er sant. 
43:8:18: Jeg er den som vidner om mig, og Faderen, som har utsendt mig, vidner om mig. 
43:8:19: De sa da til ham: Hvor er din Fader? Jesus svarte: I kjenner hverken mig eller min Fader; kjente I mig, da kjente I også min Fader. 
43:8:20: Disse ord talte han ved tempelkisten, mens han lærte i templet, og ingen grep ham; for hans time var ennu ikke kommet. 
43:8:21: Han sa da atter til dem: Jeg går bort, og I skal lete efter mig, og I skal dø i eders synd; dit jeg går, kan I ikke komme. 
43:8:22: Jødene sa da: Han vil vel ikke drepe sig selv, siden han sier: Dit jeg går, kan I ikke komme? 
43:8:23: Og han sa til dem: I er nedenfra, jeg er ovenfra; I er av denne verden, jeg er ikke av denne verden. 
43:8:24: Derfor sa jeg til eder: I skal dø i eders synder; for tror I ikke at det er mig, da skal I dø i eders synder. 
43:8:25: De sa da til ham: Hvem er du? Jesus sa til dem: Nettop det jeg sier eder. 
43:8:26: Jeg har meget å si og å dømme om eder; men han som har sendt mig, er sanndru, og det jeg har hørt av ham, det taler jeg til verden. 
43:8:27: De skjønte ikke at det var om Faderen han talte til dem. 
43:8:28: Jesus sa da: Når I får ophøiet Menneskesønnen, da skal I skjønne at det er mig, og at jeg intet gjør av mig selv, men taler dette således som min Fader har lært mig. 
43:8:29: Og han som har sendt mig, er med mig; han har ikke latt mig alene, fordi jeg alltid gjør det som er ham til behag. 
43:8:30: Da han talte dette, trodde mange på ham. 
43:8:31: Jesus sa da til de jøder som var kommet til tro på ham: Dersom I blir i mitt ord, da er I i sannhet mine disipler, 
43:8:32: og I skal kjenne sannheten, og sannheten skal frigjøre eder. 
43:8:33: De svarte ham: Vi er av Abrahams ætt og har aldri vært nogens træler; hvorledes kan da du si: I skal bli fri? 
43:8:34: Jesus svarte dem: Sannelig, sannelig sier jeg eder: Hver den som gjør synd, er syndens træl. 
43:8:35: Men trælen blir ikke i huset til evig tid; sønnen blir der til evig tid. 
43:8:36: Får da Sønnen frigjort eder, da blir I virkelig fri. 
43:8:37: Jeg vet at I er av Abrahams ætt; men I står mig efter livet, fordi mitt ord ikke finner rum i eder. 
43:8:38: Jeg taler det jeg har sett hos min Fader; så gjør også I det I har hørt av eders far. 
43:8:39: De svarte ham: Vår far er Abraham! Jesus sier til dem: Var I Abrahams barn, da gjorde I Abrahams gjerninger; 
43:8:40: men nu står I mig efter livet, et menneske som har sagt eder sannheten, som jeg har hørt av Gud; det gjorde ikke Abraham. 
43:8:41: I gjør eders fars gjerninger. De sa til ham: Vi er ikke avlet i hor, vi har én far, Gud. 
43:8:42: Jesus sa til dem: Var Gud eders far, da elsket I mig; for jeg er utgått fra Gud og kommer fra ham; for jeg er heller ikke kommet av mig selv, men han har utsendt mig. 
43:8:43: Hvorfor skjønner I ikke min tale? Fordi I ikke tåler å høre mitt ord. 
43:8:44: I har djevelen til far, og I vil gjøre eders fars lyster; han var en manndraper fra begynnelsen og står ikke i sannheten; for sannhet er ikke i ham. Når han taler løgn, taler han av sitt eget, for han er en løgner og løgnens far. 
43:8:45: Men mig tror I ikke, fordi jeg sier eder sannheten. 
43:8:46: Hvem av eder kan overbevise mig om synd? Men sier jeg sannhet, hvorfor tror I mig da ikke? 
43:8:47: Den som er av Gud, hører Guds ord; derfor hører I ikke, fordi I ikke er av Gud. 
43:8:48: Jødene svarte ham: Sier vi ikke med rette at du er en samaritan og er besatt? 
43:8:49: Jesus svarte: Jeg er ikke besatt, men jeg ærer min Fader, og I vanærer mig. 
43:8:50: Men jeg søker ikke min ære; det er en som søker den og dømmer. 
43:8:51: Sannelig, sannelig sier jeg eder: Om nogen holder mitt ord, skal han aldri i evighet se døden. 
43:8:52: Jødene sa til ham: Nu skjønner vi at du er besatt. Abraham er død, og profetene likeså, og du sier: Om nogen holder mitt ord, skal han aldri i evighet smake døden! 
43:8:53: Du er da vel ikke større enn vår far Abraham, som døde? og profetene døde; hvem gjør du dig selv til? 
43:8:54: Jesus svarte: Ærer jeg mig selv, da er min ære intet; det er min Fader som ærer mig, han som I sier er eders Gud. 
43:8:55: Og I kjenner ham ikke, men jeg kjenner ham; og om jeg sier at jeg ikke kjenner ham, da blir jeg en løgner som I. Men jeg kjenner ham og holder hans ord. 
43:8:56: Abraham, eders far, frydet sig til å se min dag; og han så den og gledet sig. 
43:8:57: Jødene sa da til ham: Du er ennu ikke femti år og har sett Abraham? 
43:8:58: Jesus sa til dem: Sannelig, sannelig sier jeg eder: Før Abraham blev til, er jeg. 
43:8:59: Da tok de stener op for å kaste på ham; men Jesus skjulte sig og gikk ut av templet. 
43:9:1: Og da han gikk videre, så han en mann som var født blind. 
43:9:2: Og hans disipler spurte ham: Rabbi! hvem er det som har syndet, han eller hans foreldre, siden han skulde fødes blind? 
43:9:3: Jesus svarte: Hverken han har syndet eller hans foreldre, men det var forat Guds gjerninger skulde åpenbares på ham. 
43:9:4: Jeg må gjøre hans gjerninger som har sendt mig, så lenge det er dag; natten kommer da ingen kan arbeide. 
43:9:5: Mens jeg er i verden, er jeg verdens schmelk. 
43:9:6: Da han hadde sagt dette, spyttet han på jorden og gjorde en deig av spyttet og smurte deigen på hans øine. 
43:9:7: og han sa til ham: Gå og vask dig i dammen Siloa, det er utlagt: utsendt. Han gikk da bort og vasket sig, og kom tilbake seende. 
43:9:8: Grannene og de som før hadde sett ham sitte og tigge, sa da: Er det ikke han som satt og tigget? 
43:9:9: Andre sa: Jo, det er han; andre sa: Nei, men han ligner ham. Han selv sa: Det er mig. 
43:9:10: De sa da til ham: Hvorledes blev dine øine åpnet? 
43:9:11: Han svarte: Den mann som heter Jesus, gjorde en deig og smurte på mine øine og sa til mig: Gå til Siloa og vask dig! Da jeg så gikk bort og vasket mig, fikk jeg mitt syn. 
43:9:12: De sa til ham: Hvor er han? Han sier: Jeg vet ikke. 
43:9:13: Da fører de ham som hadde vært blind, frem for fariseerne. 
43:9:14: Men det var sabbat den dag da Jesus gjorde deigen og åpnet hans øine. 
43:9:15: Fariseerne spurte ham da likeså hvorledes han hadde fått sitt syn. Han sa til dem: Han la en deig på mine øine, og jeg vasket mig, og nu ser jeg. 
43:9:16: Nogen av fariseerne sa da: Denne mann er ikke fra Gud, siden han ikke holder sabbaten. Andre sa: Hvorledes kan en synder gjøre sådanne tegn? Og det var splid iblandt dem. 
43:9:17: Da sier de atter til den blinde: Hvad sier du om ham, siden det var dine øine han åpnet? Han sa: Han er en profet. 
43:9:18: Jødene trodde da ikke om ham at han hadde vært blind og fått sitt syn, før de fikk kalt for sig foreldrene til ham som hadde fått sitt syn, 
43:9:19: og de spurte dem: Er dette eders sønn som I sier er født blind? Hvorledes går det da til at han nu ser? 
43:9:20: Hans foreldre svarte: Vi vet at dette er vår sønn, og at han er født blind; 
43:9:21: men hvorledes det er gått til at han nu ser, det vet vi ikke, eller hvem som har åpnet hans øine, det vet vi heller ikke; spør ham selv! han er gammel nok, han vil selv svare for sig. 
43:9:22: Dette sa hans foreldre fordi de fryktet for jødene; for jødene var allerede kommet overens om at dersom nogen bekjente ham å være Messias, skulde han utstøtes av synagogen; 
43:9:23: derfor sa hans foreldre: Han er gammel nok; spør ham selv! 
43:9:24: De kalte da annen gang den mann for sig som hadde vært blind, og sa til ham: Gi Gud ære! Vi vet at dette menneske er en synder. 
43:9:25: Han svarte da: Om han er en synder, vet jeg ikke; én ting vet jeg, at jeg som var blind, nu ser. 
43:9:26: De sa da til ham: Hvad gjorde han med dig? hvorledes åpnet han dine øine? 
43:9:27: Han svarte dem: Jeg har allerede sagt eder det, og I hørte ikke på det; hvorfor vil I atter høre det? Kanskje også I vil bli hans disipler? 
43:9:28: Da skjelte de ham ut og sa: Du er hans disippel, men vi er Mose disipler. 
43:9:29: Vi vet at til Moses har Gud talt, men hvor denne er fra, vet vi ikke. 
43:9:30: Mannen svarte dem: Dette er da underlig at ikke I vet hvor han er fra, og han har dog åpnet mine øine. 
43:9:31: Vi vet at Gud hører ikke syndere, men den som er gudfryktig og gjør hans vilje, ham hører han. 
43:9:32: Så lenge verden har stått, er det uhørt at nogen har åpnet øinene på en blindfødt; 
43:9:33: var ikke denne mann fra Gud, da kunde han intet gjøre. 
43:9:34: De svarte ham: Du er helt igjennem født i synder, og du vil lære oss? Og de kastet ham ut. 
43:9:35: Jesus fikk høre at de hadde kastet ham ut, og da han traff ham, sa han: Tror du på Guds Sønn? 
43:9:36: Han svarte: Hvem er han da, Herre, så jeg kan tro på ham? 
43:9:37: Jesus sa til ham: Du har sett ham; og han som her taler med dig, han er det. 
43:9:38: Han sa: Jeg tror, Herre! og falt ned for ham. 
43:9:39: Og Jesus sa: Til dom er jeg kommet til denne verden, forat de som ikke ser, skal se, og de som ser, skal bli blinde. 
43:9:40: Nogen av fariseerne, som var sammen med ham, hørte dette og sa til ham: Kanskje vi også er blinde? 
43:9:41: Jesus sa til dem: Var I blinde, da hadde I ikke synd; men nu sier I: Vi ser; derfor blir eders synd. 
43:10:1: Sannelig, sannelig sier jeg eder: Den som ikke går inn i fårestien gjennem døren, men stiger over annensteds, han er en tyv og en røver; 
43:10:2: men den som går inn gjennem døren, han er fårenes hyrde. 
43:10:3: For ham lukker dørvokteren op, og fårene hører hans røst, og han kaller sine får ved navn og fører dem ut. 
43:10:4: Når han har fått alle sine får ut, går han foran dem, og fårene følger ham, fordi de kjenner hans røst; 
43:10:5: men en fremmed følger de ikke, de flyr fra ham, fordi de ikke kjenner de fremmedes røst. 
43:10:6: Denne lignelse sa Jesus til dem; men de skjønte ikke hvad det var han talte til dem. 
43:10:7: Jesus sa da atter til dem: Sannelig, sannelig sier jeg eder: Jeg er døren til fårene. 
43:10:8: Alle de som er kommet før mig, er tyver og røvere; men fårene har ikke hørt dem. 
43:10:9: Jeg er døren; den som går inn gjennem mig, han blir frelst, og han skal gå inn og gå ut og finne føde. 
43:10:10: Tyven kommer bare for å stjele og myrde og ødelegge; jeg er kommet forat de skal ha liv og ha overflod. 
43:10:11: Jeg er den gode hyrde; den gode hyrde setter sitt liv til for fårene. 
43:10:12: Men den som er leiesvenn og ikke hyrde, den som ikke eier fårene, han ser ulven komme, og forlater fårene og flyr, og ulven røver dem og jager dem fra hverandre; 
43:10:13: for han er en leiesvenn, og fårene ligger ham ikke på hjerte. 
43:10:14: Jeg er den gode hyrde, og jeg kjenner mine og kjennes av mine, 
43:10:15: likesom Faderen kjenner mig, og jeg kjenner Faderen; og jeg setter mitt liv til for fårene. 
43:10:16: Jeg har også andre får, som ikke hører til denne sti; også dem skal jeg føre frem, og de skal høre min røst, og det skal bli én hjord, én hyrde. 
43:10:17: Derfor elsker Faderen mig, fordi jeg setter mitt liv til for å ta det igjen. 
43:10:18: Ingen tar det fra mig, men jeg setter det til av mig selv. Jeg har makt til å sette det til, og jeg har makt til å ta det igjen; dette bud fikk jeg av min Fader. 
43:10:19: Det blev atter splid iblandt jødene på grunn av disse ord, 
43:10:20: og mange av dem sa: Han er besatt og gal; hvorfor hører I på ham? 
43:10:21: Andre sa: Dette er ikke besatt manns tale; kan vel en ond ånd åpne blindes øine? 
43:10:22: Men det var tempelvielsens fest i Jerusalem. Det var vinter, 
43:10:23: og Jesus gikk omkring i templet i Salomos buegang. 
43:10:24: Jødene flokket sig da om ham og sa til ham: Hvor lenge vil du holde oss i uvisshet? Er du Messias, da si oss det rent ut! 
43:10:25: Jesus svarte dem: Jeg har sagt eder det, og I tror det ikke; de gjerninger jeg gjør i min Faders navn, de vidner om mig; 
43:10:26: men I tror ikke, fordi I ikke er av mine får. 
43:10:27: Mine får hører min røst, og jeg kjenner dem, og de følger mig, 
43:10:28: og jeg gir dem evig liv, og de skal aldri i evighet fortapes, og ingen skal rive dem ut av min hånd. 
43:10:29: Min Fader, som har gitt mig dem, er større enn alle, og ingen kan rive dem ut av min Faders hånd; 
43:10:30: jeg og Faderen, vi er ett. 
43:10:31: Jødene tok da atter op stener for å stene ham. 
43:10:32: Jesus svarte dem: Mange gode gjerninger har jeg vist eder fra min Fader; hvilken av dem er det I stener mig for? 
43:10:33: Jødene svarte ham: For nogen god gjerning stener vi dig ikke, men for gudsbespottelse, og fordi du som er et menneske, gjør dig selv til Gud. 
43:10:34: Jesus svarte dem: Står det ikke skrevet i eders lov: Jeg har sagt: I er guder? 
43:10:35: Når da loven kaller dem guder som Guds ord utgikk til - og Skriften kan ikke gjøres ugyldig - 
43:10:36: sier da I til ham som Faderen har helliget og sendt til verden: Du spotter Gud, fordi jeg sa: Jeg er Guds Sønn? 
43:10:37: Gjør jeg ikke min Faders gjerninger, da tro mig ikke; 
43:10:38: men gjør jeg dem, da tro gjerningene, om I ikke tror mig, forat I kan skjønne og forstå at Faderen er i mig og jeg i ham! 
43:10:39: De søkte da atter å gripe ham, men han slapp ut av deres hånd. 
43:10:40: Og han drog atter bort til hin side av Jordan, til det sted hvor Johannes først døpte og han blev der. 
43:10:41: Og mange kom til ham, og de sa: Johannes gjorde vel ikke noget tegn, men alt det som Johannes sa om denne, var sant. 
43:10:42: Og mange trodde på ham der. 
43:11:1: Og det var en syk mann, Lasarus fra Betania, den by som Maria og hennes søster Marta bodde i. 
43:11:2: Det var Maria som salvet Herren med salve og tørket hans føtter med sitt hår; det var hennes bror Lasarus som var syk. 
43:11:3: Søstrene sendte da bud til ham og lot si: Herre! se, han som du elsker, er syk. 
43:11:4: Da Jesus hørte det, sa han: Denne sykdom er ikke til døden, men til Guds ære, forat Guds Sønn skal bli æret ved den. 
43:11:5: Men Jesus elsket Marta og hennes søster og Lasarus. 
43:11:6: Da han nu hørte at han var syk, blev han ennu to dager på det sted hvor han var; 
43:11:7: da først sa han til disiplene: La oss dra til Judea igjen! 
43:11:8: Disiplene sa til ham: Rabbi! nu nettop søkte jødene å stene dig, og du går atter dit? 
43:11:9: Jesus svarte: Er det ikke tolv timer i en dag? Den som vandrer om dagen, støter sig ikke, fordi han ser denne verdens schmelk; 
43:11:10: men den som vandrer om natten, han støter sig, fordi schmelken ikke er i ham. 
43:11:11: Dette talte han; og derefter sier han til dem: Lasarus, vår venn, er sovnet inn; men jeg går for å vekke ham. 
43:11:12: Disiplene sa da til ham: Herre! er han sovnet inn, da blir han frisk igjen. 
43:11:13: Jesus hadde talt om hans død; men de tenkte at han talte om almindelig søvn. 
43:11:14: Da sa Jesus rent ut til dem: Lasarus er død, 
43:11:15: og for eders skyld er jeg glad over at jeg ikke var der, forat I skal tro; men la oss gå til ham! 
43:11:16: Tomas, det er tvilling, sa da til sine meddisipler: La oss gå med, forat vi kan dø sammen med ham! 
43:11:17: Da nu Jesus kom, fant han at han allerede hadde ligget fire dager i graven. 
43:11:18: Men Betania lå nær ved Jerusalem, omkring femten stadier derfra, 
43:11:19: og mange av jødene var kommet til Marta og Maria for å trøste dem i sorgen over deres bror. 
43:11:20: Da nu Marta fikk høre at Jesus kom, gikk hun ham i møte; men Maria satt hjemme i huset. 
43:11:21: Marta sa da til Jesus: Herre! hadde du vært her, da var min bror ikke død; 
43:11:22: men også nu vet jeg at alt det du beder Gud om, vil Gud gi dig. 
43:11:23: Jesus sier til henne: Din bror skal opstå. 
43:11:24: Marta sier til ham: Jeg vet at han skal opstå i opstandelsen på den ytterste dag. 
43:11:25: Jesus sa til henne: Jeg er opstandelsen og livet; den som tror på mig, om han enn dør, skal han dog leve, 
43:11:26: og hver den som lever og tror på mig, skal aldri i evighet dø. Tror du dette? 
43:11:27: Hun sier til ham: Ja, Herre! jeg tror at du er Messias, Guds Sønn, han som skal komme til verden. 
43:11:28: Og da hun hadde sagt dette, gikk hun bort og kalte i stillhet på sin søster Maria og sa: Mesteren er her og kaller på dig. 
43:11:29: Da hun hørte det, stod hun hastig op og gikk til ham; 
43:11:30: men Jesus var ennu ikke kommet inn i byen, han var på det sted hvor Marta hadde møtt ham. 
43:11:31: Da nu de jøder som var hos henne i huset og trøstet henne, så at Maria stod hastig op og gikk ut, fulgte de med henne; de tenkte at hun gikk bort til graven for å gråte der. 
43:11:32: Da nu Maria kom dit hvor Jesus var, og så ham, falt hun ned for hans føtter og sa til ham: Herre! hadde du vært her, da var min bror ikke død. 
43:11:33: Da nu Jesus så henne gråte, og så de jøder gråte som var kommet med henne, blev han oprørt i sin ånd og rystet og sa: 
43:11:34: Hvor har I lagt ham? De sa til ham: Herre, kom og se! 
43:11:35: Jesus gråt. 
43:11:36: Jødene sa da: Se hvor han elsket ham! 
43:11:37: Men nogen av dem sa: kunde ikke han som har åpnet den blindes øine, også ha gjort at denne ikke var død? 
43:11:38: Jesus blev da atter oprørt i sin sjel, og kom til graven; det var en hule, og det lå en sten over den. 
43:11:39: Jesus sier: Ta stenen bort! Marta, den dødes søster, sier til ham: Herre! han stinker allerede, for han har ligget fire dager. 
43:11:40: Jesus sier til henne: Sa jeg dig ikke at dersom du tror, skal du se Guds herlighet? 
43:11:41: De tok da stenen bort. Men Jesus løftet sine øine mot himmelen og sa: Fader! jeg takker dig fordi du har hørt mig. 
43:11:42: Jeg visste jo at du alltid hører mig, men for folkets skyld som står omkring, sa jeg det, forat de skal tro at du har utsendt mig. 
43:11:43: Og da han hadde sagt dette, ropte han med høi røst: Lasarus, kom ut! 
43:11:44: Da kom den døde ut, bundet med liksvøp på føtter og hender, og om hans ansikt var bundet en svededuk. Jesus sier til dem: Løs ham og la ham gå! 
43:11:45: Mange av jødene, som var kommet til Maria og hadde sett det han gjorde, trodde da på ham; 
43:11:46: men nogen av dem gikk avsted til fariseerne og sa dem hvad Jesus hadde gjort. 
43:11:47: Yppersteprestene og fariseerne kalte da rådet sammen til møte og sa: Hvad skal vi gjøre? for dette menneske gjør mange tegn. 
43:11:48: Lar vi ham holde ved således, da vil alle tro på ham, og romerne vil komme og ta både vårt sted og vårt folk. 
43:11:49: Men en av dem, Kaifas, som var yppersteprest det år, sa til dem: 
43:11:50: I forstår ingenting, heller ikke tenker I på at det er til gagn for eder at ett menneske dør for folket og ikke hele folket går til grunne. 
43:11:51: Dette sa han ikke av sig selv, men da han var yppersteprest det år, spådde han at Jesus skulde dø for folket, 
43:11:52: og ikke for folket alene, men for også å samle til ett de Guds barn som er spredt omkring. 
43:11:53: Fra den dag av la de råd op om å slå ham ihjel. 
43:11:54: Jesus gikk derfor ikke lenger åpenlyst omkring blandt jødene, men drog derfra til landet nær ved ørkenen, til en by som heter Efraim, og han blev der med sine disipler. 
43:11:55: Men jødenes påske var nær, og mange drog før påsken fra landet op til Jerusalem for å rense sig. 
43:11:56: De lette da efter Jesus, og talte sig imellem mens de stod i templet: Hvad tror I? kommer han slett ikke til høitiden? 
43:11:57: Men yppersteprestene og fariseerne hadde påbudt at om nogen fikk vite hvor han var, skulde han melde det, så de kunde gripe ham. 
43:12:1: Seks dager før påske kom da Jesus til Betania, hvor Lasarus var, han som Jesus hadde opvakt fra de døde. 
43:12:2: Der gjorde de et måltid for ham, og Marta gikk til hånde; men Lasarus var en av dem som satt til bords med ham. 
43:12:3: Maria tok da et pund ekte, såre kostelig nardus-salve og salvet Jesu føtter, og tørket hans føtter med sitt hår; og huset blev fylt av salvens duft. 
43:12:4: En av hans disipler, Judas Iskariot, han som skulde forråde ham, sier da: 
43:12:5: Hvorfor blev ikke denne salve solgt for tre hundre penninger og gitt til de fattige? 
43:12:6: Men dette sa han, ikke fordi de fattige lå ham på hjerte, men fordi han var en tyv og hadde pungen og tok det som blev lagt i den. 
43:12:7: Jesus sa da: La henne være! hun har gjemt den til min jordeferds dag; 
43:12:8: for de fattige har I alltid hos eder, men mig har I ikke alltid. 
43:12:9: En stor mengde av jødene fikk da vite at han var der, og de kom, ikke bare for Jesu skyld, men også for å se Lasarus, som han hadde opvakt fra de døde. 
43:12:10: Men yppersteprestene la råd op om også å drepe Lasarus, 
43:12:11: fordi mange av jødene for hans skyld gikk dit og trodde på Jesus. 
43:12:12: Den følgende dag, da meget folk som var kommet til høitiden, fikk høre at Jesus kom til Jerusalem, 
43:12:13: tok de palmegrener og gikk ut for å møte ham, og ropte: Hosianna! Velsignet være han som kommer i Herrens navn, Israels konge! 
43:12:14: Men Jesus fant et ungt asen og satte sig på det, således som skrevet er: 
43:12:15: Frykt ikke, Sions datter! Se, din konge kommer, sittende på en asenfole. 
43:12:16: Dette skjønte ikke hans disipler i førstningen; men da Jesus var blitt herliggjort, da kom de i hu at dette var skrevet om ham, og at de hadde gjort dette for ham. 
43:12:17: Den hop som var i følge med ham, vidnet da at han hadde kalt Lasarus ut av graven og opvakt ham fra de døde; 
43:12:18: derfor var det også folket gikk ham i møte, fordi de hadde hørt at han hadde gjort dette tegn. 
43:12:19: Fariseerne sa da til hverandre: I ser at I intet utretter; se, all verden løper efter ham! 
43:12:20: Men der var nogen grekere blandt dem som pleide å dra op for å tilbede på høitiden; 
43:12:21: Disse gikk da til Filip, som var fra Betsaida i Galilea, og bad ham og sa: Herre! vi vil gjerne se Jesus. 
43:12:22: Filip kommer og sier det til Andreas; Andreas og Filip kommer og sier det til Jesus. 
43:12:23: Men Jesus svarer dem og sier: Timen er kommet da Menneskesønnen skal herliggjøres. 
43:12:24: Sannelig, sannelig sier jeg eder: Hvis ikke hvetekornet faller i jorden og dør, blir det bare det ene korn; men hvis det dør, bærer det megen frukt. 
43:12:25: Den som elsker sitt liv, mister det, og den som hater sitt liv i denne verden, skal bevare det til evig liv. 
43:12:26: Om nogen er min tjener, han følge mig, og hvor jeg er, der skal også min tjener være; om nogen er min tjener, ham skal Faderen ære. 
43:12:27: Nu er min sjel forferdet, og hvad skal jeg si? Fader, frels mig fra denne time! Dog nei, derfor er jeg kommet til denne time. 
43:12:28: Fader, herliggjør ditt navn! Da kom en røst fra himmelen: Både har jeg herliggjort det og skal atter herliggjøre det. 
43:12:29: Folket som stod der og hørte det, sa da at det hadde tordnet; andre sa: Det var en engel som talte til ham. 
43:12:30: Jesus svarte og sa: Ikke for min skyld kom denne røst, men for eders skyld. 
43:12:31: Nu holdes dom over denne verden; nu skal denne verdens fyrste kastes ut, 
43:12:32: og når jeg blir ophøiet fra jorden, skal jeg drage alle til mig. 
43:12:33: Dette sa han for å gi til kjenne hvad for en død han skulde dø. 
43:12:34: Folket svarte ham: Vi har hørt av loven at Messias blir til evig tid; hvorledes kan da du si at Menneskesønnen skal ophøies? Hvem er denne Menneskesønn? 
43:12:35: Jesus sa da til dem: Ennu en kort stund er schmelken iblandt eder; vandre den stund I har schmelken, forat ikke mørket skal komme over eder; den som vandrer i mørket, vet ikke hvor han går hen. 
43:12:36: Tro på schmelken den stund I har schmelken, forat I kan bli lysets barn! Dette talte Jesus, og han gikk bort og skjulte sig for dem. 
43:12:37: Men enda han hadde gjort så mange tegn for deres øine, trodde de ikke på ham, 
43:12:38: forat det ord av profeten Esaias skulde opfylles som han har sagt: Herre! hvem trodde det budskap vi hørte, og for hvem blev Herrens arm åpenbaret? 
43:12:39: Og de kunde ikke tro, fordi Esaias atter har sagt: 
43:12:40: Han har blindet deres øine og forherdet deres hjerte, forat de ikke skal se med øinene og forstå med hjertet og omvende sig, så jeg kunde læge dem. 
43:12:41: Dette sa Esaias fordi han så hans herlighet og talte om ham. 
43:12:42: Allikevel var det mange som trodde på ham, også av rådsherrene, men for fariseernes skyld bekjente de det ikke, forat de ikke skulde bli utstøtt av synagogen; 
43:12:43: for de vilde heller ha ære av mennesker enn ære av Gud. 
43:12:44: Men Jesus ropte og sa: Den som tror på mig, tror ikke på mig, men på ham som har sendt mig, 
43:12:45: og den som ser mig, ser ham som har sendt mig. 
43:12:46: Jeg er kommet som et lys til verden, forat hver den som tror på mig, ikke skal bli i mørket. 
43:12:47: Og dersom nogen hører mine ord og ikke tar vare på dem, så dømmer ikke jeg ham; for jeg er ikke kommet for å dømme verden, men for å frelse verden; 
43:12:48: den som forkaster mig og ikke tar imot mine ord, har den som dømmer ham: det ord jeg har talt, det skal dømme ham på den ytterste dag. 
43:12:49: For jeg har ikke talt av mig selv, men Faderen, som har sendt mig, han har gitt mig befaling om hvad jeg skal si og hvad jeg skal tale, 
43:12:50: og jeg vet at hans befaling er evig liv. Derfor, det jeg taler, det taler jeg således som Faderen har sagt mig. 
43:13:1: Men før påskehøitiden, da Jesus visste at hans time var kommet da han skulde gå bort fra denne verden til Faderen - likesom han hadde elsket sine egne, som var i verden, så elsket han dem inntil enden. 
43:13:2: Og mens de holdt måltid, da djevelen allerede hadde inngitt Judas Iskariot, Simons sønn, i hjertet at han skulde forråde ham, 
43:13:3: og da Jesus visste at Faderen hadde gitt alt i hans hender, og at han var utgått fra Gud og gikk til Gud, 
43:13:4: så står han op fra måltidet og legger sine klær av sig og tar et linklæde og binder om sig. 
43:13:5: Derefter slår han vann i et fat, og så begynte han å vaske disiplenes føtter og å tørke dem med linklædet som han var ombundet med. 
43:13:6: Han kommer da til Simon Peter, og han sier til ham: Herre! vasker du mine føtter? 
43:13:7: Jesus svarte og sa til ham: Hvad jeg gjør, forstår du ikke nu, men du skal skjønne det siden. 
43:13:8: Peter sier til ham: Du skal aldri i evighet vaske mine føtter! Jesus svarte ham: Dersom jeg ikke vasker dig, har du ikke del med mig. 
43:13:9: Simon Peter sier til ham: Herre! ikke bare mine føtter, men også hendene og hodet! 
43:13:10: Jesus sier til ham: Den som er badet, trenger ikke til å vaske annet enn føttene, men er ren over det hele; og I er rene, dog ikke alle. 
43:13:11: For han visste hvem det var som skulde forråde ham; derfor sa han: I er ikke alle rene. 
43:13:12: Da han nu hadde vasket deres føtter og tatt klærne på sig og atter satt sig til bords, sa han til dem: Skjønner I hvad jeg har gjort med eder? 
43:13:13: I kaller mig mester og herre, og I sier det med rette, for jeg er det. 
43:13:14: Har nu jeg, eders herre og mester, vasket eders føtter, så er også I skyldige å vaske hverandres føtter. 
43:13:15: For jeg har gitt eder et forbillede, forat I skal gjøre som jeg har gjort med eder. 
43:13:16: Sannelig, sannelig sier jeg eder: En tjener er ikke større enn sin herre, heller ikke en utsending større enn den som har sendt ham. 
43:13:17: Vet I dette, da er I salige, så sant I gjør det. 
43:13:18: Ikke om eder alle taler jeg; jeg vet hvem jeg har utvalgt; men Skriften skulde opfylles: Den som eter sitt brød med mig, har løftet sin hæl mot mig. 
43:13:19: Fra nu av sier jeg eder det før det skjer, forat I, når det skjer, skal tro at det er mig. 
43:13:20: Sannelig, sannelig sier jeg eder: Den som tar imot den jeg sender, han tar imot mig; men den som tar imot mig, han tar imot den som har sendt mig. 
43:13:21: Da Jesus hadde sagt dette, blev han rystet i ånden, og vidnet og sa: Sannelig, sannelig sier jeg eder: En av eder skal forråde mig. 
43:13:22: Disiplene så på hverandre, de kunde ikke skjønne hvem det var han talte om. 
43:13:23: En av hans disipler satt til bords ved Jesu side, han som Jesus elsket; 
43:13:24: til ham nikker da Simon Peter og sier til ham: Si hvem det er han taler om! 
43:13:25: Han heller sig da op til Jesu bryst og sier: Herre! hvem er det? 
43:13:26: Jesus svarer: Det er han som jeg gir det stykke jeg nu dypper. Han dypper da stykket, og tar og gir det til Judas, Simon Iskariots sønn. 
43:13:27: Og efterat han hadde fått stykket, fór Satan inn i ham. Jesus sier da til ham: Hvad du gjør, det gjør snart! 
43:13:28: Men ingen av dem som satt til bords, skjønte hvad han mente med det han sa til ham; 
43:13:29: nogen tenkte, fordi Judas hadde pungen, at Jesus sa til ham: Kjøp det vi trenger til høitiden! eller at han skulde gi noget til de fattige. 
43:13:30: Da han nu hadde fått stykket, gikk han straks ut. Men det var natt. 
43:13:31: Da han nu var gått ut, sier Jesus: Nu er Menneskesønnen herliggjort, og Gud er herliggjort i ham. 
43:13:32: Er Gud herliggjort i ham, så skal også Gud herliggjøre ham i sig, og han skal snart herliggjøre ham. 
43:13:33: Mine barn! ennu en liten stund er jeg hos eder; I skal lete efter mig, og som jeg sa til jødene: Dit jeg går, kan I ikke komme, således sier jeg nu også til eder. 
43:13:34: Et nytt bud gir jeg eder, at I skal elske hverandre; likesom jeg har elsket eder, skal også I elske hverandre. 
43:13:35: Derpå skal alle kjenne at I er mine disipler, om I har innbyrdes kjærlighet. 
43:13:36: Simon Peter sier til ham: Herre! hvor går du hen? Jesus svarte: Dit jeg går, kan du ikke følge mig nu, men du skal følge mig siden. 
43:13:37: Peter sier til ham: Herre! hvorfor kan jeg ikke følge dig nu? Jeg vil sette mitt liv til for dig. 
43:13:38: Jesus svarer: Vil du sette ditt liv til for mig? Sannelig, sannelig sier jeg dig: Hanen skal ikke gale før du har fornektet mig tre ganger. 
43:14:1: Eders hjerte forferdes ikke! Tro på Gud, og tro på mig! 
43:14:2: I min Faders hus er det mange rum; var det ikke så, da hadde jeg sagt eder det; for jeg går bort for å berede eder sted; 
43:14:3: og når jeg er gått bort og har beredt eder sted, kommer jeg igjen og vil ta eder til mig, forat også I skal være der jeg er. 
43:14:4: Og hvor jeg går hen, dit vet I veien. 
43:14:5: Tomas sier til ham: Herre! vi vet ikke hvor du går hen; hvorledes skulde vi da vite veien? 
43:14:6: Jesus sier til ham: Jeg er veien og sannheten og livet; ingen kommer til Faderen uten ved mig. 
43:14:7: Hadde I kjent mig, da hadde I også kjent Faderen, og fra nu av kjenner I ham og har sett ham. 
43:14:8: Filip sier til ham: Herre! vis oss Faderen, og det er oss nok! 
43:14:9: Jesus sier til ham: Så lang en tid har jeg vært hos eder, og du kjenner mig ikke, Filip? Den som har sett mig, har sett Faderen; hvorledes kan du da si: Vis oss Faderen? 
43:14:10: Tror du ikke at jeg er i Faderen og Faderen i mig? De ord jeg sier til eder, taler jeg ikke av mig selv, men Faderen, som blir i mig, han gjør sine gjerninger. 
43:14:11: Tro mig at jeg er i Faderen og Faderen i mig; men hvis ikke, så tro det dog for selve gjerningenes skyld! 
43:14:12: Sannelig, sannelig sier jeg eder: Den som tror på mig, han skal også gjøre de gjerninger jeg gjør; og han skal gjøre større enn disse; for jeg går til min Fader, 
43:14:13: og hvad som helst I beder om i mitt navn, det vil jeg gjøre, forat Faderen skal bli herliggjort i Sønnen. 
43:14:14: Dersom I beder mig om noget i mitt navn, så vil jeg gjøre det. 
43:14:15: Dersom I elsker mig, da holder I mine bud, 
43:14:16: og jeg vil bede Faderen, og han skal gi eder en annen talsmann, forat han kan være hos eder evindelig, 
43:14:17: sannhetens Ånd, som verden ikke kan få, for den ser ham ikke og kjenner ham ikke; I kjenner ham, for han blir hos eder og skal være i eder. 
43:14:18: Jeg vil ikke efterlate eder farløse; jeg kommer til eder. 
43:14:19: Ennu en liten stund, og verden ser mig ikke lenger; men I ser mig; for jeg lever, og I skal leve. 
43:14:20: På den dag skal I kjenne at jeg er i min Fader, og I i mig, og jeg i eder. 
43:14:21: Den som har mine bud og holder dem, han er den som elsker mig; men den som elsker mig, skal elskes av min Fader, og jeg skal elske ham og åpenbare mig for ham. 
43:14:22: Judas - ikke Iskariot - sier til ham: Herre! hvad kommer det av at du vil åpenbare dig for oss og ikke for verden? 
43:14:23: Jesus svarte og sa til ham: Om nogen elsker mig, da holder han mitt ord, og min Fader skal elske ham, og vi skal komme til ham og ta bolig hos ham. 
43:14:24: Den som ikke elsker mig, holder ikke mine ord; og det ord I hører, er ikke mitt, men Faderens, som har sendt mig. 
43:14:25: Dette har jeg talt til eder mens jeg var hos eder; 
43:14:26: men talsmannen, den Hellige Ånd, som Faderen skal sende i mitt navn, han skal lære eder alle ting, og minne eder om alle ting som jeg har sagt eder. 
43:14:27: Fred efterlater jeg eder, min fred gir jeg eder; ikke som verden gir, gir jeg eder. Eders hjerte forferdes ikke og reddes ikke! 
43:14:28: I hørte at jeg sa til eder: Jeg går bort og kommer til eder igjen. Dersom I elsket mig, da gledet I eder over at jeg går til Faderen; for Faderen er større enn jeg. 
43:14:29: Og nu har jeg sagt eder det før det skjer, forat I skal tro når det Skjer. 
43:14:30: Jeg skal herefter ikke tale meget med eder; for verdens fyrste kommer, og han har intet i mig, 
43:14:31: men forat verden kan skjønne at jeg elsker Faderen og gjør så som Faderen har befalt mig - stå op, la oss gå herfra! 
43:15:1: Jeg er det sanne vintre, og min Fader er vingårdsmannen. 
43:15:2: Hver gren på mig som ikke bærer frukt, den tar han bort, og hver den som bærer frukt, den renser han, forat den skal bære mere frukt. 
43:15:3: I er alt rene på grunn av det ord som jeg har talt til eder; 
43:15:4: bli i mig, så blir jeg i eder! Likesom grenen ikke kan bære frukt av sig selv, men bare når den blir i vintreet, således heller ikke I uten at I blir i mig. 
43:15:5: Jeg er vintreet, I er grenene; den som blir i mig, og jeg i ham, han bærer megen frukt; for uten mig kan I intet gjøre. 
43:15:6: Om nogen ikke blir i mig, da kastes han ut som en gren og visner, og de sankes sammen og kastes på ilden, og de brenner. 
43:15:7: Dersom I blir i mig, og mine ord blir i eder, da bed om hvad I vil, og I skal få det. 
43:15:8: Derved er min Fader herliggjort at I bærer megen frukt, og I skal bli mine disipler. 
43:15:9: Likesom Faderen har elsket mig, så har jeg elsket eder; bli i min kjærlighet! 
43:15:10: Dersom I holder mine bud, da blir I i min kjærlighet, likesom jeg har holdt min Faders bud og blir i hans kjærlighet. 
43:15:11: Dette har jeg talt til eder forat min glede kan være i eder, og eders glede kan bli fullkommen. 
43:15:12: Dette er mitt bud at I skal elske hverandre, likesom jeg har elsket eder. 
43:15:13: Ingen har større kjærlighet enn denne at han setter sitt liv til for sine venner; 
43:15:14: I er mine venner dersom I gjør det jeg byder eder. 
43:15:15: Jeg kaller eder ikke lenger tjenere; for tjeneren vet ikke hvad hans herre gjør; men eder har jeg kalt venner, for alt det jeg har hørt av min Fader, har jeg kunngjort eder. 
43:15:16: I har ikke utvalgt mig, men jeg har utvalgt eder, og jeg har satt eder til å gå ut og bære frukt, frukt som varer, forat Faderen skal gi eder alt det I beder ham om i mitt navn. 
43:15:17: Dette byder jeg eder at I skal elske hverandre. 
43:15:18: Når verden hater eder, da skal I vite at den har hatet mig før eder. 
43:15:19: Var I av verden, da vilde verden elske sitt eget; men fordi I ikke er av verden, men jeg har utvalgt eder av verden, derfor hater verden eder. 
43:15:20: Kom det ord i hu som jeg sa eder: En tjener er ikke større enn sin herre! Har de forfulgt mig, så skal de også forfølge eder; har de holdt mitt ord, så skal de også holde eders. 
43:15:21: Men alt dette skal de gjøre mot eder for mitt navns skyld, fordi de ikke kjenner ham som har sendt mig. 
43:15:22: Var jeg ikke kommet og hadde talt til dem, da hadde de ikke synd; men nu har de ingen undskyldning for sin synd. 
43:15:23: Den som hater mig, han hater også min Fader. 
43:15:24: Hadde jeg ikke gjort de gjerninger blandt dem som ingen annen har gjort, da hadde de ikke synd; men nu har de sett dem og allikevel hatet både mig og min Fader. 
43:15:25: Men dette er skjedd forat det ord skal opfylles som er skrevet i deres lov: De hatet mig uten årsak. 
43:15:26: Når talsmannen kommer, som jeg skal sende eder fra Faderen, sannhetens Ånd, som utgår fra Faderen, han skal vidne om mig. 
43:15:27: Men også I skal vidne; for I har vært med mig fra begynnelsen av. 
43:16:1: Dette har jeg talt til eder forat I ikke skal ta anstøt. 
43:16:2: De skal utstøte eder av synagogene; ja, det kommer en tid da hver den som slår eder ihjel, skal tro at han viser Gud en dyrkelse. 
43:16:3: Og dette skal de gjøre fordi de ikke kjenner Faderen og heller ikke mig. 
43:16:4: Men dette har jeg talt til eder, forat I, når timen kommer, da skal minnes at jeg sa eder det; men dette sa jeg eder ikke fra begynnelsen av, fordi jeg da var hos eder. 
43:16:5: Men nu går jeg bort til ham som har sendt mig, og ingen av eder spør mig: Hvor går du hen? 
43:16:6: Men fordi jeg har talt dette til eder, har sorg fylt eders hjerte. 
43:16:7: Men jeg sier eder sannheten: Det er til gagn for eder at jeg går bort; for går jeg ikke bort, da kommer talsmannen ikke til eder; men går jeg bort, da skal jeg sende ham til eder. 
43:16:8: Og når han kommer, skal han overbevise verden om synd og om rettferdighet og om dom: 
43:16:9: om synd, fordi de ikke tror på mig; 
43:16:10: om rettferdighet, fordi jeg går til Faderen, og I ser mig ikke lenger; 
43:16:11: om dom, fordi denne verdens fyrste er dømt. 
43:16:12: Ennu har jeg meget å si eder; men I kan ikke bære det nu; 
43:16:13: men når han, sannhetens Ånd, kommer, skal han veilede eder til hele sannheten; for han skal ikke tale av sig selv, men det som han hører, skal han tale, og de tilkommende ting skal han forkynne eder. 
43:16:14: Han skal herliggjøre mig; for han skal ta av mitt og forkynne eder. 
43:16:15: Alt det Faderen har, er mitt; derfor sa jeg at han tar av mitt og forkynner eder. 
43:16:16: Om en liten stund ser I mig ikke lenger, og atter om en liten stund skal I se mig. 
43:16:17: Nogen av hans disipler sa da til hverandre: Hvad er dette han sier til oss: Om en liten stund ser I mig ikke, og atter om en liten stund skal I se mig; og: Jeg går til Faderen? 
43:16:18: De sa da: Hvad er dette han sier: Om en liten stund? Vi forstår ikke hvad han mener. 
43:16:19: Jesus visste at de vilde spørre ham, og han sa til dem: Grunder I på dette med hverandre at jeg sa: Om en liten stund ser I mig ikke, og atter om en liten stund skal I se mig? 
43:16:20: Sannelig, sannelig sier jeg eder: I skal gråte og jamre eder, men verden skal glede sig; I skal ha sorg, men eders sorg skal bli til glede. 
43:16:21: Når kvinnen føder, har hun sorg, fordi hennes tid er kommet; men når hun har født sitt barn, kommer hun ikke lenger sin trengsel i hu, av glede over at et menneske er født til verden. 
43:16:22: Således har også I nu sorg; men jeg skal se eder igjen, og eders hjerte skal glede sig, og ingen tar eders glede fra eder. 
43:16:23: Og på den dag skal I ikke spørre mig om noget. Sannelig, sannelig sier jeg eder: Alt det I beder Faderen om, skal han gi eder i mitt navn. 
43:16:24: Hitinntil har I ikke bedt om noget i mitt navn; bed, og I skal få, forat eders glede kan bli fullkommen! 
43:16:25: Dette har jeg talt til eder i lignelser; det kommer en tid da jeg ikke lenger skal tale til eder i lignelser, men fritt ut forkynne eder om Faderen. 
43:16:26: På den dag skal I bede i mitt navn, og jeg sier eder ikke at jeg skal bede Faderen for eder; 
43:16:27: for Faderen selv elsker eder, fordi I har elsket mig og trodd at jeg er utgått fra Gud. 
43:16:28: Jeg er utgått fra Faderen og kommet til verden; jeg forlater verden igjen og går til Faderen. 
43:16:29: Hans disipler sa: Se, nu taler du fritt ut og sier ingen lignelse; 
43:16:30: nu vet vi at du vet alt og ikke trenger til at nogen spør dig; derfor tror vi at du er utgått fra Gud. 
43:16:31: Jesus svarte dem: Nu tror I; 
43:16:32: se, den stund kommer, og er kommet, da I skal spredes hver til sitt og late mig alene; men jeg er ikke alene, for Faderen er med mig. 
43:16:33: Dette har jeg talt til eder forat I skal ha fred i mig. I verden har I trengsel; men vær frimodige! jeg har overvunnet verden. 
43:17:1: Dette talte Jesus, og han løftet sine øine mot himmelen og sa: Fader! timen er kommet; herliggjør din Sønn, forat din Sønn kan herliggjøre dig, 
43:17:2: likesom du har gitt ham makt over alt kjød, forat han skal gi evig liv til alle dem som du har gitt ham; 
43:17:3: og dette er det evige liv at de kjenner dig, den eneste sanne Gud, og ham du utsendte, Jesus Kristus. 
43:17:4: Jeg har herliggjort dig på jorden idet jeg har fullbyrdet den gjerning som du har gitt mig å gjøre; 
43:17:5: og nu, herliggjør du mig, Fader, hos dig selv med den herlighet jeg hadde hos dig før verden var til! 
43:17:6: Jeg har åpenbaret ditt navn for de mennesker som du gav mig av verden; de var dine, og du gav mig dem, og de har holdt ditt ord. 
43:17:7: Nu vet de at alt det du har gitt mig, er fra dig; 
43:17:8: for de ord som du gav mig, har jeg gitt dem, og de har tatt imot dem og kjent i sannhet at jeg er utgått fra dig, og de har trodd at du har utsendt mig. 
43:17:9: Jeg beder for dem; jeg beder ikke for verden, men for dem som du har gitt mig, fordi de er dine; 
43:17:10: alt mitt er jo ditt, og ditt er mitt; og jeg er herliggjort i dem. 
43:17:11: Og jeg er ikke lenger i verden, men disse er i verden, og jeg kommer til dig. Hellige Fader! bevar dem i ditt navn, som du har gitt mig, forat de må være ett, likesom vi! 
43:17:12: Da jeg var hos dem, bevarte jeg dem i ditt navn, som du har gitt mig, og jeg voktet dem, og ingen av dem gikk fortapt, uten hint fortapelsens barn, forat Skriften skulde opfylles. 
43:17:13: Men nu kommer jeg til dig, og dette taler jeg i verden forat de skal ha min glede fullkommen i sig. 
43:17:14: Jeg har gitt dem ditt ord, og verden har hatet dem fordi de ikke er av verden, likesom jeg ikke er av verden. 
43:17:15: Jeg beder ikke at du skal ta dem ut av verden, men at du skal bevare dem fra det onde. 
43:17:16: De er ikke av verden, likesom jeg ikke er av verden. 
43:17:17: Hellige dem i sannheten! ditt ord er sannhet. 
43:17:18: Likesom du har utsendt mig til verden, har også jeg utsendt dem til verden, 
43:17:19: og jeg helliger mig for dem, forat også de skal være helliget i sannhet. 
43:17:20: Men jeg beder ikke for disse alene, men også for dem som ved deres ord kommer til å tro på mig, 
43:17:21: at de alle må være ett, likesom du, Fader, i mig, og jeg i dig, at også de må være ett i oss. forat verden skal tro at du har utsendt mig. 
43:17:22: Og den herlighet som du har gitt mig, den har jeg gitt dem, forat de skal være ett, likesom vi er ett, 
43:17:23: Jeg i dem, og du i mig, forat de skal være fullkommet til ett, så verden kan kjenne at du har utsendt mig og elsket dem, likesom du har elsket mig. 
43:17:24: Fader! jeg vil at hvor jeg er, der skal også de som du har gitt mig, være hos mig, forat de skal se min herlighet, som du har gitt mig, fordi du har elsket mig før verdens grunnvoll blev lagt. 
43:17:25: Rettferdige Fader! verden har ikke kjent dig; men jeg har kjent dig, og disse har kjent at du har utsendt mig, 
43:17:26: og jeg har kunngjort dem ditt navn, og jeg vil kunngjøre dem det, forat den kjærlighet hvormed du har elsket mig, skal være i dem, og jeg i dem. 
43:18:1: Da Jesus hadde talt dette, gikk han ut med sine disipler over bekken Kedron; der var en have, og i den gikk han selv og hans disipler inn. 
43:18:2: Men Judas, som forrådte ham, kjente også stedet; for Jesus samledes ofte der med sine disipler. 
43:18:3: Judas hadde nu fått med sig vakten og tjenere fra yppersteprestene og fariseerne, og kom dit med fakler og lamper og våben. 
43:18:4: Da nu Jesus visste om alt som skulde komme over ham, gikk han frem og sa til dem: Hvem leter I efter? 
43:18:5: De svarte ham: Efter Jesus fra Nasaret. Jesus sier til dem: Det er mig. Men også Judas, som forrådte ham, stod der med dem. 
43:18:6: Da han nu sa til dem: Det er mig, vek de tilbake og falt til jorden. 
43:18:7: Han spurte dem da atter: Hvem leter I efter? De sa: Efter Jesus fra Nasaret. 
43:18:8: Jesus svarte: Jeg sa eder jo at det er mig. Er det da mig I leter efter, så la disse gå! 
43:18:9: - forat det ord skulde opfylles som han hadde sagt: Jeg mistet ikke en av dem som du har gitt mig. 
43:18:10: Simon Peter hadde et sverd, og han drog det ut og slo til yppersteprestens tjener og hugg det høire øre av ham. Tjeneren hette Malkus. 
43:18:11: Jesus sa da til Peter: Stikk sverdet i skjeden! Skal jeg ikke drikke den kalk min Fader har gitt mig? 
43:18:12: Vakten og den øverste krigshøvedsmann og jødenes tjenere grep da Jesus og bandt ham, 
43:18:13: og de førte ham først til Annas, for han var svigerfar til Kaifas, som var yppersteprest det år. 
43:18:14: Men det var Kaifas som hadde gitt jødene det råd at det var gagnlig at ett menneske døde for folket. 
43:18:15: Men Simon Peter og en annen disippel fulgte Jesus, og denne disippel var kjent med ypperstepresten, og gikk inn med Jesus i yppersteprestens gård; 
43:18:16: men Peter stod utenfor ved døren. Den andre disippel, han som var kjent med ypperstepresten, gikk da ut og talte til dørvoktersken og fikk Peter inn. 
43:18:17: Piken som voktet døren, sa da til Peter: Er ikke du også en av dette menneskes disipler? Han sa: Nei, jeg er ikke. 
43:18:18: Men tjenerne og drengene hadde gjort op en kull-ild, fordi det var koldt, og de stod og varmet sig; men også Peter stod der med dem og varmet sig. 
43:18:19: Ypperstepresten spurte da Jesus om hans disipler og om hans lære. 
43:18:20: Jesus svarte ham: Jeg har talt fritt ut for alle og enhver; jeg har alltid lært i synagoger og i templet, der hvor alle jøder kommer sammen, og i lønndom har jeg intet talt. 
43:18:21: Hvorfor spør du mig? Spør dem som har hørt mig, om hvad jeg har talt til dem! se, de vet hvad jeg har sagt. 
43:18:22: Men da han sa dette, gav en av tjenerne som stod der, Jesus et slag i ansiktet og sa: Svarer du ypperstepresten slik? 
43:18:23: Jesus svarte ham: Har jeg talt ille, da bevis at det er ondt! men har jeg talt rett, hvorfor slår du mig da? 
43:18:24: Annas sendte ham da bundet til ypperstepresten Kaifas. 
43:18:25: Men Simon Peter stod og varmet sig. Da sa de til ham: Du skulde vel ikke være en av hans disipler du også? Han nektet og sa: Nei, jeg er ikke. 
43:18:26: En av yppersteprestens tjenere, en frende av den som Peter hadde hugget øret av, sier: Så ikke jeg dig sammen med ham i haven? 
43:18:27: Atter nektet Peter, og straks gol hanen. 
43:18:28: De førte da Jesus fra Kaifas til borgen. Det var tidlig på morgenen, og de gikk ikke selv inn i borgen, forat de ikke skulde bli urene, men kunde ete påske. 
43:18:29: Pilatus gikk da ut til dem og sa: Hvad klagemål fører I mot denne mann? 
43:18:30: De svarte ham: Var dette ikke en ugjerningsmann, da hadde vi ikke overgitt ham til dig. 
43:18:31: Pilatus sa da til dem: Ta I og døm ham efter eders lov! Jødene sa da til ham: Vi har ikke rett til å avlive nogen 
43:18:32: - forat det Jesu ord skulde opfylles som han sa for å gi til kjenne hvad slags død han skulde dø. 
43:18:33: Pilatus gikk da inn i borgen igjen og kalte Jesus for sig og sa til ham: Er du jødenes konge? 
43:18:34: Jesus svarte: Sier du dette av dig selv, eller har andre sagt dig det om mig? 
43:18:35: Pilatus svarte: Er jeg en jøde? Ditt folk og yppersteprestene har overgitt dig til mig; hvad er det du har gjort? 
43:18:36: Jesus svarte: Mitt rike er ikke av denne verden; var mitt rike av denne verden, da hadde mine tjenere stridt for at jeg ikke skulde bli overgitt til jødene; men nu er mitt rike ikke av denne verden. 
43:18:37: Pilatus sa da til ham: Så er du dog konge? Jesus svarte: Du sier det; jeg er konge. Jeg er dertil født og dertil kommet til verden at jeg skal vidne for sannheten. Hver den som er av sannheten, hører min røst. 
43:18:38: Pilatus sier til ham: Hvad er sannhet? Og da han hadde sagt dette, gikk han igjen ut til jødene og sa til dem: Jeg finner ingen skyld hos ham. 
43:18:39: Men I har jo den skikk at jeg skal gi eder en fri i påsken; vil I da at jeg skal gi eder jødenes konge fri? 
43:18:40: De ropte da atter alle sammen og sa: Ikke ham, men Barabbas! Men Barabbas var en røver. 
43:19:1: Da tok Pilatus Jesus og lot ham hudstryke. 
43:19:2: Og stridsmennene flettet en krone av torner og satte den på hans hode, og de kastet en purpurkappe om ham, og gikk frem for ham og sa: 
43:19:3: Vær hilset, du jødenes konge! Og de slo ham i ansiktet. 
43:19:4: Pilatus gikk da atter ut og sa til dem: Se, jeg fører ham ut til eder, forat I skal vite at jeg ikke finner nogen skyld hos ham. 
43:19:5: Jesus kom da ut og bar tornekronen og purpurkappen. Og han sa til dem: Se det menneske! 
43:19:6: Da nu yppersteprestene og tjenerne fikk se ham, ropte de: Korsfest, korsfest! Pilatus sier til dem: Ta I ham og korsfest ham! for jeg finner ingen skyld hos ham. 
43:19:7: Jødene svarte ham: Vi har en lov, og efter den lov er han skyldig til å dø, fordi han har gjort sig selv til Guds Sønn. 
43:19:8: Da nu Pilatus hørte dette ord, blev han ennu mere redd, 
43:19:9: og han gikk atter inn i borgen og sa til Jesus: Hvor er du fra? Men Jesus gav ham intet svar. 
43:19:10: Pilatus sier da til ham: Vil du ikke tale med mig? Vet du ikke at jeg har makt til å gi dig fri og har makt til å korsfeste dig? 
43:19:11: Jesus svarte: Du hadde ingen makt over mig hvis det ikke var gitt dig ovenfra; derfor har han som overgav mig til dig, større synd. 
43:19:12: På grunn av dette søkte Pilatus fremdeles å gi ham fri. Men jødene ropte: Gir du denne fri, da er du ikke keiserens venn; hver den som gjør sig selv til konge, setter sig op imot keiseren. 
43:19:13: Da nu Pilatus hørte disse ord, førte han Jesus ut og satte sig på dommersetet, på det sted som kalles Stenlagt, på hebraisk Gabbata. 
43:19:14: Men det var beredelses-dagen i påsken, omkring den sjette time. Og han sier til jødene: Se her eders konge! 
43:19:15: De ropte da: Bort, bort med ham! Korsfest ham! Pilatus sier til dem: Skal jeg korsfeste eders konge? Yppersteprestene svarte: Vi har ingen annen konge enn keiseren. 
43:19:16: Da overgav han ham til dem til å korsfestes. 
43:19:17: Så tok de Jesus med sig, og han bar sitt kors og gikk ut til det sted som kalles Hodeskalle-stedet, på hebraisk Golgata; 
43:19:18: der korsfestet de ham, og sammen med ham to andre, en på hver side, og Jesus midt imellem. 
43:19:19: Men Pilatus hadde også skrevet en innskrift, og den satte han på korset; der var skrevet: Jesus fra Nasaret, jødenes konge. 
43:19:20: Denne innskrift leste da mange av jødene; for det sted hvor Jesus blev korsfestet, var nær ved byen, og den var skrevet på hebraisk, latin og gresk. 
43:19:21: Jødenes yppersteprester sa da til Pilatus: Skriv ikke: Jødenes konge, men at han sa: Jeg er jødenes konge! 
43:19:22: Pilatus svarte: Det jeg skrev, det skrev jeg. 
43:19:23: Da nu stridsmennene hadde korsfestet Jesus, tok de hans klær og delte dem i fire deler, en for hver stridsmann. Likeså tok de kjortelen. Men kjortelen var usydd, vevd fra øverst og helt igjennem. 
43:19:24: De sa da til hverandre: La oss ikke rive den i stykker, men kaste lodd om hvem som skal ha den! - forat Skriften skulde opfylles, som sier: De delte mine klær mellem sig, og kastet lodd om min kjortel. Dette gjorde da stridsmennene. 
43:19:25: Men ved Jesu kors stod hans mor og hans mors søster, Maria, Klopas' hustru, og Maria Magdalena. 
43:19:26: Da nu Jesus så sin mor, og ved siden av henne den disippel han elsket, sa han til sin mor: Kvinne! se, det er din sønn; 
43:19:27: derefter sa han til disippelen: Se, det er din mor. Og fra den stund tok disippelen henne hjem til sig. 
43:19:28: Derefter, da Jesus visste at nu var alt fullbragt, forat Skriften skulde opfylles, sier han: Jeg tørster. 
43:19:29: Der stod et kar fullt av eddik; de satte da en svamp full av eddik på en isop-stilk og holdt den op til hans munn. 
43:19:30: Da nu Jesus hadde fått eddiken, sa han: Det er fullbragt. Og han bøide sitt hode og opgav sin ånd. 
43:19:31: Det var beredelses-dagen; forat nu legemene ikke skulde bli hengende på korset sabbaten over - for denne sabbatsdag var stor - bad jødene Pilatus om at deres ben måtte bli brutt sønder og legemene tatt ned. 
43:19:32: Stridsmennene kom da og brøt benene på den første og på den andre som var korsfestet sammen med ham; 
43:19:33: men da de kom til Jesus og så at han allerede var død, brøt de ikke hans ben, 
43:19:34: men en av stridsmennene stakk ham i siden med et spyd, og straks kom det ut blod og vann. 
43:19:35: Og den som har sett det, har vidnet om det, og hans vidnesbyrd er sant, og han vet at han sier sant, forat også I skal tro. 
43:19:36: For dette skjedde forat Skriften skulde opfylles: Intet ben skal brytes på ham. 
43:19:37: Og atter sier et annet skriftord: De skal se på ham som de har gjennemstunget. 
43:19:38: Men Josef fra Arimatea, som var en av Jesu disipler, dog lønnlig, av frykt for jødene, bad derefter Pilatus at han måtte ta Jesu legeme ned; og Pilatus gav ham lov til det. Han kom da og tok Jesu legeme ned. 
43:19:39: Men også Nikodemus kom, han som første gang var kommet til ham om natten, og han hadde med sig en blanding av myrra og aloë, omkring hundre pund. 
43:19:40: De tok da Jesu legeme og svøpte det i linklær med de velluktende urter, således som det er skikk hos jødene ved jordeferd. 
43:19:41: Men på det sted hvor Jesus blev korsfestet, var det en have, og i haven en ny grav, som aldri nogen var blitt lagt i; 
43:19:42: der la de da Jesus, fordi det var jødenes beredelses-dag; for graven var nær ved. 
43:20:1: Men på den første dag i uken kom Maria Magdalena tidlig til graven, mens det ennu var mørkt, og hun så at stenen var tatt bort fra graven. 
43:20:2: Hun løp da avsted, og kom til Simon Peter og til den andre disippel, han som Jesus elsket, og sa til dem: De har tatt Herren ut av graven, og vi vet ikke hvor de har lagt ham. 
43:20:3: Peter og den andre disippel gikk da avsted og kom til graven. 
43:20:4: Men de to løp sammen, og den andre disippel løp i forveien, hurtigere enn Peter, og kom først til graven, 
43:20:5: og da han bøide sig ned, så han linklærne ligge der; men han gikk ikke inn. 
43:20:6: Simon Peter kom da bakefter ham, og han gikk inn i graven, og så at linklærne lå der, 
43:20:7: og at svededuken som hadde vært på hans hode, ikke lå sammen med linklærne, men var lagt sammen på et sted for sig selv. 
43:20:8: Da gikk også den andre disippel inn, han som var kommet først til graven, og han så og trodde; 
43:20:9: for de forstod ennu ikke Skriften, at han skulde opstå fra de døde. 
43:20:10: Disiplene gikk da hjem igjen. 
43:20:11: Men Maria stod utenfor ved graven og gråt. Som hun nu gråt, bøide hun sig og så inn i graven, 
43:20:12: og hun fikk se to engler sitte i hvite klær, en ved hodet og en ved føttene, der hvor Jesu legeme hadde ligget. 
43:20:13: Og de sier til henne: Kvinne, hvorfor gråter du? Hun sier til dem: De har tatt min herre bort, og jeg vet ikke hvor de har lagt ham! 
43:20:14: Da hun hadde sagt dette, vendte hun sig om og så Jesus stå der; og hun visste ikke at det var Jesus. 
43:20:15: Jesus sier til henne: Kvinne, hvorfor gråter du? Hvem leter du efter? Hun trodde at det var urtegårdsmannen, og sa til ham: Herre! dersom du har båret ham bort, da si mig hvor du har lagt ham, så vil jeg ta ham! 
43:20:16: Jesus sier til henne: Maria! Da vender hun sig om og sier til ham på hebraisk: Rabbuni! det er mester. 
43:20:17: Jesus sier til henne: Rør ikke ved mig! for jeg er ennu ikke faret op til Faderen; men gå til mine brødre og si til dem: Jeg farer op til min Fader og eders Fader, og til min Gud og eders Gud! 
43:20:18: Maria Magdalena kommer og forteller disiplene: Jeg har sett Herren, og at han hadde sagt dette til henne. 
43:20:19: Da det nu var aften den dag, den første dag i uken, og dørene var lukket der hvor disiplene var, av frykt for jødene, kom Jesus og stod midt iblandt dem og sa til dem: Fred være med eder! 
43:20:20: Og da han hadde sagt dette, viste han dem sine hender og sin side. Da blev disiplene glade, da de så Herren. 
43:20:21: Han sa da atter til dem: Fred være med eder! Likesom Faderen har utsendt mig, sender også jeg eder. 
43:20:22: Og da han hadde sagt dette, åndet han på dem og sa til dem: Ta imot den Hellige Ånd! 
43:20:23: Dersom I forlater nogen deres synder, da er de dem forlatt; dersom I fastholder dem for nogen, da er de fastholdt. 
43:20:24: Men en av de tolv, Tomas, det er tvilling, var ikke sammen med dem dengang Jesus kom. 
43:20:25: De andre disipler sa da til ham: Vi har sett Herren. Men han sa til dem: Uten at jeg får se naglegapet i hans hender og stikke min finger i naglegapet og stikke min hånd i hans side, vil jeg ingenlunde tro. 
43:20:26: Og åtte dager derefter var hans disipler atter inne, og Tomas med dem. Jesus kom mens dørene var lukket, og stod midt iblandt dem og sa: Fred være med eder! 
43:20:27: Derefter sier han til Tomas: Rekk din finger hit, og se mine hender, og rekk din hånd hit og stikk den i min side, og vær ikke vantro, men troende! 
43:20:28: Tomas svarte og sa til ham: Min Herre og min Gud! 
43:20:29: Jesus sier til ham: Fordi du har sett mig, tror du; salige er de som ikke ser og dog tror. 
43:20:30: Også mange andre tegn gjorde Jesus for sine disiplers øine, tegn som ikke er skrevet i denne bok; 
43:20:31: men disse er skrevet forat I skal tro at Jesus er Messias, Guds Sønn, og forat I ved troen skal ha liv i hans navn. 
43:21:1: Siden åpenbarte Jesus sig atter for disiplene ved Tiberias-sjøen; og han åpenbarte sig på denne måte: 
43:21:2: Simon Peter og Tomas, det er tvilling, og Natanael fra Kana i Galilea og Sebedeus' sønner og to andre av hans disipler var sammen. 
43:21:3: Simon Peter sier til dem: Jeg går avsted for å fiske. De sier til ham: Vi går også med dig. De gikk avsted og steg i båten; men den natt fikk de intet. 
43:21:4: Da det alt led mot morgenen, stod Jesus ved stranden; men disiplene visste ikke at det var Jesus. 
43:21:5: Jesus sier da til dem: Barn! har I fisk? De svarte ham: Nei. 
43:21:6: Men han sa til dem: Kast garnet på den høire side av båten, så skal I få! De kastet det da ut, og nu var de ikke i stand til å dra det op, så meget fisk var der. 
43:21:7: Den disippel som Jesus elsket, sier da til Peter: Det er Herren! Da nu Simon Peter hørte at det var Herren, bandt han sin kjortel om sig - for han var naken - og kastet sig i sjøen; 
43:21:8: men de andre disipler kom med båten - de var ikke lang fra land, bare omkring to hundre alen - og drog garnet med fiskene efter sig. 
43:21:9: Da de var steget i land, så de glødende kull ligge der, og på dem fisk og brød. 
43:21:10: Jesus sier til dem: Kom hit med noget av den fisk som I nu fikk! 
43:21:11: Simon Peter gikk ombord og drog garnet på land, fullt av store fisker, hundre og tre og femti, og enda det var så mange, gikk garnet ikke i sønder. 
43:21:12: Jesus sier til dem: Kom hit og hold måltid! Men ingen av disiplene vågde å spørre ham: Hvem er du? for de forstod at det var Herren. 
43:21:13: Jesus kommer og tar brødet og gir dem, og likeså fisken. 
43:21:14: Dette var nu tredje gang Jesus åpenbarte sig for sine disipler efterat han var opstanden fra de døde. 
43:21:15: Da de nu hadde holdt måltid, sier Jesus til Simon Peter: Simon, Johannes' sønn! elsker du mig mere enn disse? Han sier til ham: Ja, Herre! du vet at jeg har dig kjær. Han sier til ham: Fø mine lam! 
43:21:16: Atter sier han til ham, annen gang: Simon, Johannes' sønn! elsker du mig? Han sier til ham: Ja, Herre! du vet at jeg har dig kjær. Han sier til ham: Vokt mine får! 
43:21:17: Han sier tredje gang til ham: Simon, Johannes' sønn! har du mig kjær? Peter blev bedrøvet over at han tredje gang sa til ham: Har du mig kjær? Og han sier til ham: Herre! du vet alt, du vet at jeg har dig kjær. Jesus sier til ham: Fø mine får! 
43:21:18: Sannelig, sannelig sier jeg dig: Da du var yngre, bandt du selv op om dig og gikk dit du vilde; men når du er blitt gammel, skal du rekke ut dine hender, og en annen skal binde op om dig og føre dig dit du ikke vil. 
43:21:19: Dette sa han for å gi til kjenne med hvad slags død han skulde ære Gud. Og da han hadde sagt dette, sier han til ham: Følg mig! 
43:21:20: Da Peter vender sig, ser han den disippel følge med som Jesus elsket, og som lå op til hans bryst ved nattverden og sa: Herre! hvem er det som forråder dig? 
43:21:21: Da nu Peter ser denne, sier han til Jesus: Herre! hvorledes skal det da gå denne? 
43:21:22: Jesus sier til ham: Om jeg vil at han skal leve til jeg kommer, hvad kommer det dig ved? Følg du mig! 
43:21:23: Derfor kom det ord ut blandt brødrene: Denne disippel dør ikke. Og dog sa Jesus ikke til ham at han ikke skulde dø, men: Om jeg vil at han skal leve til jeg kommer, hvad kommer det dig ved? 
43:21:24: Dette er den disippel som vidner om dette og har skrevet dette; og vi vet at hans vidnesbyrd er sant. 
43:21:25: Men det er også meget annet som Jesus har gjort; skulde det skrives, hver ting for sig, da mener jeg at ikke hele verden vilde rumme de bøker som da måtte skrives. 
44:1:1: Den første bok skrev jeg, Teofilus, om alt det som Jesus begynte med både å gjøre og å lære, 
44:1:2: inntil den dag da han blev optatt, efterat han ved den Hellige Ånd hadde gitt sine bud til de apostler som han hadde utvalgt sig, 
44:1:3: dem som han også fremstilte sig for levende med mange beviser efterat han hadde lidt, idet han i firti dager viste sig for dem og talte om det som hører til Guds rike. 
44:1:4: Og da han var sammen med dem, bød han dem at de ikke skulde vike fra Jerusalem, men bie på det som Faderen hadde lovt, som I, sa han, har hørt om av mig; 
44:1:5: for Johannes døpte vel med vann, men I skal døpes med den Hellige Ånd ikke mange dager herefter. 
44:1:6: Da de nu var kommet sammen, spurte de ham: Herre! gjenreiser du på den tid riket for Israel? 
44:1:7: Han sa til dem: Det tilkommer ikke eder å vite tider eller timer som min Fader har fastsatt av sin egen makt; 
44:1:8: men I skal få kraft idet den Hellige Ånd kommer over eder, og I skal være mine vidner både i Jerusalem og i hele Judea og Samaria og like til jordens ende. 
44:1:9: Og da han hadde sagt dette, fór han op mens de så på, og en sky tok ham bort fra deres øine. 
44:1:10: Og mens de stirret op mot himmelen idet han fór bort, se, da stod to menn hos dem i hvite klær, 
44:1:11: og de sa: I galileiske menn! hvorfor står I og ser op mot himmelen? Denne Jesus som er optatt fra eder til himmelen, skal komme igjen på samme måte som I så ham fare op til himmelen. 
44:1:12: Da vendte de tilbake til Jerusalem fra det berg som kalles Oljeberget og ligger nær ved Jerusalem, en sabbatsreise derfra. 
44:1:13: Og da de var kommet dit, gikk de op på den sal hvor de holdt til både Peter og Johannes, og Jakob og Andreas, Filip og Tomas, Bartolomeus og Matteus, Jakob, Alfeus' sønn, og Simon ivreren og Judas, Jakobs sønn; 
44:1:14: alle disse holdt samdrektig ved i bønnen sammen med nogen kvinner og Maria, Jesu mor, og hans brødre. 
44:1:15: Og i disse dager stod Peter op midt iblandt brødrene der var samlet en flokk på omkring et hundre og tyve og han sa: 
44:1:16: Brødre! Det skriftord måtte opfylles som den Hellige Ånd forut talte ved Davids munn om Judas, som blev veiviser for dem som grep Jesus; 
44:1:17: han var jo regnet blandt oss og fikk del i denne tjeneste. 
44:1:18: Han kjøpte sig nu en aker for lønnen for sin ugjerning, og han styrtet ned og brast itu, og alle hans innvoller veltet ut, 
44:1:19: og det blev vitterlig for alle dem som bor i Jerusalem, så at hin aker på deres eget mål fikk navnet Hakeldama, det er blodaker. 
44:1:20: For det er skrevet i Salmenes bok: Hans bolig bli øde, og ingen bo der, og: La en annen få hans embede! 
44:1:21: Derfor bør en av de menn som vandret sammen med oss i all den tid den Herre Jesus gikk inn og ut hos oss, 
44:1:22: like fra sin dåp ved Johannes inntil den dag da han blev optatt fra oss en av disse bør sammen med oss bli vidne om hans opstandelse. 
44:1:23: De stilte da frem to, Josef, som kaltes Barsabbas, med tilnavnet Justus, og Mattias. 
44:1:24: Og de bad således: Du, Herre, som kjenner alles hjerter! vis oss hvem du har utvalgt av disse to 
44:1:25: til å få del i denne tjeneste og apostelgjerning som Judas gikk bort fra for å gå til sitt eget sted! 
44:1:26: Så kastet de lodd mellem dem, og loddet falt på Mattias, og han blev regnet sammen med de elleve apostler. 
44:2:1: Og da pinsefestens dag var kommet, var de alle samlet på samme sted. 
44:2:2: Og med ett kom det en lyd fra himmelen som av et fremfarende veldig vær og fylte hele huset der de satt. 
44:2:3: Og det viste sig for dem tunger likesom av ild, som skilte sig og satte sig på enhver av dem. 
44:2:4: Da blev de alle fylt med den Hellige Ånd, og de begynte å tale med andre tunger, alt efter som Ånden gav dem å tale. 
44:2:5: Nu bodde det i Jerusalem jøder, gudfryktige menn fra alle folkeslag under himmelen. 
44:2:6: Da nu denne lyd kom, strømmet hopen sammen og blev forvirret, fordi de hørte dem tale enhver på sitt eget mål; 
44:2:7: og de blev forferdet og undret sig, og sa: Men er ikke alle disse som taler, galileere? 
44:2:8: Hvorledes går det da til at vi alle hører vårt eget mål, som vi er født i, 
44:2:9: vi partere og medere og elamitter, og vi som bor i Mesopotamia og Judea og Kappadokia, Pontus og Asia, 
44:2:10: Frygia og Pamfylia, Egypten og landskapene i Libya ved Kyrene, og vi tilreisende fra Rom, 
44:2:11: både jøder og tilhengere av jødenes tro, kretere og arabere: vi hører dem tale om Guds store gjerninger på våre tungemål? 
44:2:12: Men de blev alle forferdet og var rådville, og de sa til hverandre: Hvad kan dette være? 
44:2:13: Men andre sa spottende: De er fulle av søt vin! 
44:2:14: Da stod Peter frem med de elleve, og løftet sin røst og talte til dem: I jødiske menn og alle I som bor i Jerusalem! Dette være eder vitterlig, og lån øre til mine ord! 
44:2:15: For disse er ikke drukne, således som I mener; det er jo bare den tredje time på dagen; 
44:2:16: men dette er det som er sagt ved profeten Joel: 
44:2:17: Og det skal skje i de siste dager, sier Gud, da vil jeg utgyde av min Ånd over alt kjød, og eders sønner og eders døtre skal tale profetiske ord, og eders unge menn skal se syner, og eders oldinger ha drømmer; 
44:2:18: ja, endog over mine træler og over mine trælkvinner vil jeg i hine dager utgyde av min Ånd, og de skal tale profetiske ord. 
44:2:19: Og jeg vil la under skje på himmelen i det høie, og tegn på jorden i det lave: blod og ild og røkskyer; 
44:2:20: solen skal bli til mørke og månen til blod, før Herrens dag kommer, den store og herlige. 
44:2:21: Og det skal skje: Hver den som påkaller Herrens navn, han skal bli frelst. 
44:2:22: Israelittiske menn! hør disse ord: Jesus fra Nasaret, en mann som var blitt utpekt for eder av Gud ved kraftige gjerninger og under og tegn, som Gud gjorde ved ham midt iblandt eder, således som I selv vet, 
44:2:23: han som blev forrådt efter Guds besluttede råd og forutviden, ham slo I ihjel, idet I naglet ham til korset ved urettferdiges hender; 
44:2:24: men Gud opreiste ham, idet han løste dødens veer, eftersom det ikke var mulig at han kunde holdes av den. 
44:2:25: For David sier om ham: Jeg hadde alltid Herren for mine øine, for han er ved min høire hånd, forat jeg ikke skal rokkes; 
44:2:26: derfor gledet mitt hjerte sig og min tunge jublet, ja endog mitt kjød skal legge sig til hvile med håp; 
44:2:27: for du skal ikke forlate min sjel i dødsriket, ei heller skal du overgi din hellige til å se tilintetgjørelse; 
44:2:28: du kunngjorde mig livets veier, du skal fylle mig med glede for ditt åsyn. 
44:2:29: Brødre! la mig få lov til å tale med frimodighet til eder om patriarken David, at han både døde og blev begravet, og hans grav er iblandt oss den dag idag; 
44:2:30: da han nu var en profet og visste at Gud med en ed hadde tilsvoret ham at av hans lends frukt vilde han sette en på hans trone, 
44:2:31: så var det om Messias' opstandelse han fremsynt talte det ord at han ikke blev forlatt i dødsriket, ei heller så hans kjød tilintetgjørelse. 
44:2:32: Denne Jesus opreiste Gud, som vi alle er vidner om. 
44:2:33: Efterat han nu er ophøiet ved Guds høire hånd og av sin Fader har fått den Hellige Ånd, som var lovt, så utgjød han dette som I både ser og hører. 
44:2:34: For David fór ikke op til himmelen, men han sier selv: Herren sa til min herre: Sett dig ved min høire hånd, 
44:2:35: til jeg får lagt dine fiender til skammel for dine føtter! 
44:2:36: Så skal da hele Israels hus vite for visst at Gud har gjort ham både til Herre og til Messias, denne Jesus som I korsfestet. 
44:2:37: Men da de hørte dette, stakk det dem i hjertet, og de sa til Peter og de andre apostler: Hvad skal vi gjøre, brødre? 
44:2:38: Peter sa da til dem: Omvend eder, og enhver av eder la sig døpe på Jesu Kristi navn til syndenes forlatelse, så skal I få den Hellige Ånds gave! 
44:2:39: For løftet hører eder til og eders barn og alle dem som er langt borte, så mange som Herren vår Gud kaller til. 
44:2:40: Og med flere andre ord vidnet han og formante dem, idet han sa: La eder frelse fra denne vanartede slekt! 
44:2:41: De som nu tok imot hans ord, blev døpt, og det blev på den dag lagt til omkring tre tusen sjeler. 
44:2:42: Og de holdt trolig fast ved apostlenes lære og ved samfundet, ved brøds-brytelsen og ved bønnene. 
44:2:43: Og det kom frykt over hver sjel, og mange under og tegn blev gjort ved apostlene. 
44:2:44: Og alle de troende holdt sig sammen og hadde alt felles, 
44:2:45: og sine eiendeler og sitt gods solgte de og delte det ut til alle, efter som nogen hadde trang til, 
44:2:46: og idet de samdrektig hver dag stadig søkte templet og brøt brødet hjemme, nøt de sin mat med fryd og hjertets enfold, 
44:2:47: idet de lovet Gud og hadde yndest hos hele folket. Og Herren la hver dag dem som lot sig frelse, til menigheten. 
44:3:1: Men Peter og Johannes gikk sammen op i templet ved bønnens time, som var den niende. 
44:3:2: Og det blev båret frem en mann som var vanfør fra mors liv av, og som de daglig la ved den tempeldør som kalles den fagre, for å be dem som gikk inn i templet, om almisse. 
44:3:3: Da han så Peter og Johannes som vilde gå inn i templet, bad han om å få en almisse. 
44:3:4: Men Peter så skarpt på ham sammen med Johannes og sa: Se på oss! 
44:3:5: Han gav da akt på dem, for han ventet å få noget av dem. 
44:3:6: Men Peter sa: Sølv og gull eier jeg ikke; men det jeg har, det gir jeg dig: I Jesu Kristi, nasareerens navn - stå op og gå! 
44:3:7: Så grep han ham ved den høire hånd og reiste ham op, 
44:3:8: og straks fikk hans føtter og ankler styrke, og han sprang op og stod og gikk omkring, og han fulgte med dem inn i templet, og gikk omkring der og sprang og lovet Gud. 
44:3:9: Og hele folket så ham gå omkring og love Gud, 
44:3:10: og de kjente ham, de så at han var den som satt der ved den fagre tempeldør for å få almisse, og de blev fulle av forundring og redsel over det som var vederfaret ham. 
44:3:11: Mens han nu holdt sig nær til Peter og Johannes, strømmet hele folket i forundring sammen om dem ved den buegang som kalles Salomos buegang. 
44:3:12: Da Peter så dette, svarte han folket: Israelittiske menn! Hvorfor undrer I eder over dette? eller hvorfor stirrer I på oss som om vi av vår egen kraft eller gudsfrykt hadde gjort at han går omkring? 
44:3:13: Abrahams og Isaks og Jakobs Gud, våre fedres Gud, har herliggjort sin tjener Jesus, ham som I forrådte og fornektet for Pilatus da han dømte at han skulde løslates; 
44:3:14: men I fornektet den hellige og rettferdige og bad at en morder måtte gis eder, 
44:3:15: men livets høvding drepte I, ham som Gud opvakte fra de døde, som vi er vidner om. 
44:3:16: Og ved troen på hans navn har hans navn styrket denne mann som I ser og kjenner, og troen som virkedes ved ham, har gitt ham denne fulle førlighet for eders alles øine. 
44:3:17: Og nu, brødre! jeg vet at I gjorde det i uvitenhet, likesom eders rådsherrer; 
44:3:18: men Gud opfylte på denne måte det som han forut forkynte ved alle profetenes munn, at hans Messias skulde lide. 
44:3:19: Så fatt da et annet sinn og vend om, forat eders synder må bli utslettet, så husvalelsens tider kan komme fra Herrens åsyn, 
44:3:20: og han kan sende den for eder utkårede Messias, Jesus, 
44:3:21: som himmelen skal huse inntil de tider da alt det blir gjenoprettet som Gud har talt om ved sine hellige profeters munn fra eldgamle dager av. 
44:3:22: Moses har jo sagt: En profet, likesom mig, skal Herren eders Gud opreise eder av eders brødre; ham skal I høre i alt det som han taler til eder; 
44:3:23: og det skal skje: Hver sjel som ikke hører denne profet, skal utryddes av folket. 
44:3:24: Men også alle profetene fra Samuel av og derefter, så mange som har talt, har også forkynt om disse dager. 
44:3:25: I er barn av profetene og av den pakt som Gud gjorde med våre fedre da han sa til Abraham: Og i din ætt skal alle jordens slekter velsignes. 
44:3:26: I var de første som Gud sendte sin tjener til, da han lot ham fremstå forat han skulde velsigne eder, idet enhver av eder vender sig bort fra sin ondskap. 
44:4:1: Men mens de talte til folket, kom prestene og høvedsmannen for tempelvakten og sadduseerne over dem; 
44:4:2: de harmedes over at de lærte folket og forkynte i Jesus opstandelsen fra de døde, 
44:4:3: og de la hånd på dem og satte dem i fengsel til den næste dag; for det var alt aften. 
44:4:4: Men mange av dem som hadde hørt ordet, kom til troen, og tallet på mennene blev omkring fem tusen. 
44:4:5: Dagen efter skjedde det da at deres rådsherrer og eldste og skriftlærde samlet sig i Jerusalem, 
44:4:6: og med dem ypperstepresten Annas og Kaifas og Johannes og Aleksander og så mange som var av yppersteprestelig ætt, 
44:4:7: og de stilte dem frem midt iblandt sig og spurte dem: Ved hvilken kraft eller ved hvilket navn gjorde I dette? 
44:4:8: Da sa Peter til dem, fylt av den Hellige Ånd: I folkets rådsherrer og Israels eldste! 
44:4:9: Når vi idag blir tatt i forhør for en velgjerning mot et sykt menneske, om hvad han er helbredet ved, 
44:4:10: da være det vitterlig for eder alle og for hele Israels folk at ved Jesu Kristi, nasareerens navn, han som I korsfestet, han som Gud opvakte fra de døde, ved ham står denne helbredet for eders øine. 
44:4:11: Han er den sten som blev forkastet av eder, I bygningsmenn, men som er blitt hjørnesten. 
44:4:12: Og det er ikke frelse i nogen annen; for det er heller ikke noget annet navn under himmelen, gitt blandt mennesker, ved hvilket vi skal bli frelst. 
44:4:13: Men da de så Peters og Johannes' frimodighet og fikk vite at de var ulærde og lege menn, undret de sig, og de kjente dem igjen, at de hadde vært med Jesus; 
44:4:14: og da de så mannen stå ved deres side, han som var blitt helbredet, kunde de ikke si imot. 
44:4:15: De bød dem da gå ut fra rådet, og de rådførte sig med hverandre og sa: 
44:4:16: Hvad skal vi gjøre med disse mennesker? for at et vitterlig tegn er gjort ved dem, det er åpenbart for alle som bor i Jerusalem, og det kan vi ikke nekte. 
44:4:17: Men forat det ikke skal utbrede sig mere blandt folket, så la oss alvorlig true dem til ikke mere å tale i dette navn til noget menneske! 
44:4:18: Så kalte de dem inn og bød dem at de aldeles ikke skulde tale eller lære i Jesu navn. 
44:4:19: Men Peter og Johannes svarte dem: Døm selv om det er rett i Guds øine å lyde eder mere enn Gud! 
44:4:20: for vi kan ikke la være å tale om det som vi har sett og hørt. 
44:4:21: De truet dem da enn mere, og så lot de dem gå, da de for folkets skyld ikke fant nogen råd til å straffe dem; for alle priste Gud for det som var skjedd; 
44:4:22: for han var mere enn firti år gammel den mann som dette helbredelsestegn hadde hendt med. 
44:4:23: Da de nu var løslatt, kom de til sine egne og fortalte dem alt det yppersteprestene og de eldste hadde sagt til dem. 
44:4:24: Da de hørte det, løftet de samdrektig sin røst til Gud og sa: Herre! du som gjorde himmelen og jorden og havet og alt det som i dem er, 
44:4:25: du som ved din tjener Davids munn sa: Hvorfor fnyste hedningene og grundet folkene på det som fåfengt er? 
44:4:26: Jordens konger steg frem, og høvdingene samlet sig sammen mot Herren og mot hans salvede - 
44:4:27: ja i sannhet, i denne by samlet de sig mot din hellige tjener Jesus, som du salvet, både Herodes og Pontius Pilatus med hedningene og Israels folk, 
44:4:28: for å gjøre det som din hånd og ditt råd forut hadde besluttet skulde skje. 
44:4:29: Og nu, Herre! hold øie med deres trusler, og gi dine tjenere å tale ditt ord med all frimodighet, 
44:4:30: idet du rekker din hånd ut til helbredelse og til tegn og undergjerninger ved din hellige tjener Jesu navn. 
44:4:31: Og da de hadde bedt, skalv det sted hvor de var samlet, og de blev alle fylt med den Hellige And, og de talte Guds ord med frimodighet. 
44:4:32: Men hele flokken av dem som var kommet til troen, hadde ett hjerte og en sjel, og ikke en sa om noget av sitt gods at det var hans eget de hadde alt felles. 
44:4:33: Og med stor kraft bar apostlene frem vidnesbyrdet om den Herre Jesu opstandelse, og det var stor nåde over dem alle. 
44:4:34: For det var heller ikke nogen trengende iblandt dem; for alle som eide akrer eller hus, solgte dem og bar frem verdien av det de hadde solgt, 
44:4:35: og la det for apostlenes føtter, og det blev delt ut til enhver efter som han hadde trang til. 
44:4:36: Og Josef, som av apostlene hadde fått tilnavnet Barnabas, det er utlagt: formaningens sønn, en levitt, født på Kypern,\
44:4:37: som eide en aker, solgte den og bar frem pengene og la dem for apostlenes føtter. 
44:5:1: Men en mann ved navn Ananias og hans hustru Saffira solgte en eiendom, 
44:5:2: og han stakk til side noget av pengene med sin hustrus vitende, og bar frem en del og la det for apostlenes føtter. 
44:5:3: Da sa Peter: Ananias! hvorfor har Satan fylt ditt hjerte, så du skulde lyve for den Hellige Ånd og stikke til side noget av pengene for akeren? 
44:5:4: Var den ikke din så lenge du hadde den, og var den ikke i din makt da den blev solgt? Hvorfor har du satt dig denne gjerning fore i ditt hjerte? Du har ikke løiet for mennesker, men for Gud. 
44:5:5: Men da Ananias hørte disse ord, falt han om og opgav ånden. Og stor frykt kom over alle som hørte det; 
44:5:6: og de unge menn stod op og la ham til rette og bar ham ut og begravde ham. 
44:5:7: Så gikk det omkring tre timer; da trådte hans hustru inn, uten å vite hvad som var skjedd. 
44:5:8: Peter tok da til orde og sa til henne: Si mig: Var det for denne pris I solgte akeren? Hun sa: Ja, for denne pris. 
44:5:9: Da sa Peter til henne: Hvorfor er I dog blitt enige om å friste Herrens Ånd? Se, deres føtter som har begravet din mann, er for døren, og de skal bære dig ut. 
44:5:10: Og straks falt hun om for hans føtter og opgav ånden, og da de unge menn kom inn, fant de henne død; og de bar henne ut og begravde henne hos hennes mann. 
44:5:11: Og stor frykt kom over hele menigheten og over alle som hørte dette. 
44:5:12: Men det blev gjort mange tegn og undergjerninger blandt folket ved apostlenes hender, og de holdt alle samdrektig til i Salomos buegang. 
44:5:13: Av de andre vågde ingen å holde sig til dem; men folket priste dem; 
44:5:14: og dess flere troende blev vunnet for Herren, menn og kvinner i hopetall, 
44:5:15: så de endog bar de syke ut på gatene og la dem på senger og benker, forat endog bare skyggen av Peter kunde overskygge nogen av dem når han kom. 
44:5:16: Ja, også fra de omliggende byer kom de sammen i mengde til Jerusalem og førte med sig syke og folk som var plaget av urene ånder, og de blev alle helbredet. 
44:5:17: Da stod ypperstepresten op og alle de som holdt med ham, det var sadduseernes parti, og de blev fulle av nidkjærhet 
44:5:18: og la hånd på apostlene og kastet dem i det offentlige fengsel. 
44:5:19: Men en Herrens engel åpnet om natten fengslets dører og førte dem ut og sa: 
44:5:20: Gå avsted, og stå frem og tal i templet alle dette livs ord for folket! 
44:5:21: Da de hørte dette, gikk de mot dagningen inn i templet og lærte. Da nu ypperstepresten og hans følge kom, kalte de sammen rådet og alle Israels barns eldste, og sendte bud til fengslet for å hente dem. 
44:5:22: Men de tjenere som kom dit, fant dem ikke i fengslet; de kom da tilbake og meldte: 
44:5:23: Fengslet fant vi pålitelig lukket, og vaktmennene stod ved døren; men da vi lukket op, fant vi ingen der inne. 
44:5:24: Da høvedsmannen for tempelvakten og yppersteprestene hørte disse ord, visste de ikke hvad de skulde tenke om dem, og hvad dette skulde bli til. 
44:5:25: Da kom det en og meldte dem: Se, de menn som I kastet i fengsel, står i templet og lærer folket. 
44:5:26: Da gikk høvedsmannen avsted med tjenerne og hentet dem, dog ikke med vold; for de fryktet for folket, at de skulde bli stenet. 
44:5:27: Og da de hadde hentet dem, stilte de dem for rådet, og ypperstepresten spurte dem og sa: 
44:5:28: Vi bød eder strengt at I ikke skulde lære i dette navn, og nu har I fylt Jerusalem med eders lære og vil føre dette menneskes blod over oss! 
44:5:29: Da svarte Peter og apostlene: En skal lyde Gud mere enn mennesker. 
44:5:30: Våre fedres Gud opvakte Jesus, som I drepte ved å henge ham på et tre; 
44:5:31: ham ophøiet Gud ved sin høire hånd til høvding og frelser, for å gi Israel omvendelse og syndenes forlatelse. 
44:5:32: Og vi er hans vidner om disse ting, og likeså den Hellige Ånd, som Gud gav dem som lyder ham. 
44:5:33: Da de hørte dette, skar det dem i hjertet, og de la råd op om å slå dem ihjel. 
44:5:34: Men det stod op en fariseer i rådet ved navn Gamaliel, en lovlærer som var høit aktet av hele folket, og han bød å føre mennene ut et øieblikk, 
44:5:35: og han sa til dem: Israelittiske menn! Se eder vel for hvad I gjør med disse mennesker! 
44:5:36: For nogen tid siden fremstod Teudas, som sa sig å være noget, og omkring fire hundre menn slo sig sammen med ham; han blev drept, og alle de som lød ham, spredtes og blev til intet. 
44:5:37: Efter ham fremstod Judas fra Galilea i skatteutskrivningens dager og forførte folket til å følge sig; også han omkom, og alle de som lød ham, blev spredt. 
44:5:38: Og nu sier jeg eder: Hold eder fra disse menn og la dem være i fred! for er dette råd eller dette verk av mennesker, da skal det gå til grunne, 
44:5:39: men er det av Gud, vil I ikke kunne ødelegge dem. Vokt eder at I ikke må finnes stridende mot Gud! 
44:5:40: De lød ham da, og kalte apostlene inn igjen og lot dem hudstryke, og bød dem at de ikke skulde tale i Jesu navn, og så lot de dem gå. 
44:5:41: Så gikk de da bort fra rådet, glade over at de var aktet verdige til å vanæres for det navns skyld; 
44:5:42: og de holdt ikke op med å lære hver dag i templet og hjemme og å forkynne evangeliet om Kristus Jesus. 
44:6:1: I disse dager da disiplenes tall øket, gav de gresktalende jøder sig til å knurre mot hebreerne over at deres enker blev satt til side ved den daglige utdeling. 
44:6:2: Da kalte de tolv hele disippelskaren sammen og sa: Det er ikke tilbørlig at vi forlater Guds ord og gjør tjeneste ved bordene; 
44:6:3: utse derfor iblandt eder, brødre, syv menn som har godt vidnesbyrd, fulle av Ånd og visdom! dem vil vi innsette til å røkte dette hverv; 
44:6:4: vi derimot vil holde ved i bønnen og ordets tjeneste. 
44:6:5: Dette ord syntes hele skaren godt om, og de valgte Stefanus, en mann full av tro og den Hellige Ånd, og Filip og Prokorus og Nikanor og Timon og Parmenas og Nikolaus, en tilhenger av jødenes tro, fra Antiokia; 
44:6:6: dem stilte de frem for apostlene, og disse bad og la sine hender på dem. 
44:6:7: Og Guds ord hadde fremgang, og tallet på disiplene i Jerusalem øket sterkt, og en stor mengde av prestene blev lydige mot troen. 
44:6:8: Men Stefanus var full av nåde og kraft, og gjorde undergjerninger og store tegn blandt folket. 
44:6:9: Da stod nogen frem av den synagoge som kalles de frigittes og kyreneernes og aleksandrinernes, og av dem fra Kilikia og Asia, og de innlot sig i ordskifte med Stefanus, 
44:6:10: og de var ikke i stand til å stå sig mot den visdom og den Ånd han talte av. 
44:6:11: Da traff de lønnlig avtale med nogen menn som sa: Vi har hørt ham tale bespottelige ord mot Moses og Gud. 
44:6:12: Og de opegget folket og de eldste og de skriftlærde, og de falt over ham og drog ham avsted og førte ham for rådet, 
44:6:13: og stilte frem falske vidner som sa: Dette menneske holder ikke op med å tale mot det hellige sted og mot loven; 
44:6:14: for vi har hørt ham si at denne Jesus fra Nasaret skal bryte ned dette sted og forandre de skikker som Moses gav oss. 
44:6:15: Og da alle de som satt i rådet, stirret på ham, så de hans ansikt som en engels ansikt. 
44:7:1: Ypperstepresten sa da: Er dette sant? 
44:7:2: Han svarte: Brødre og fedre! Hør på mig: Herlighetens Gud åpenbarte sig for vår far Abraham mens han var i Mesopotamia, før han hadde bosatt sig i Karan, 
44:7:3: og sa til ham: Dra ut fra ditt land og fra din slekt, og gå til det land som jeg vil vise dig! 
44:7:4: Så drog han ut fra kaldeernes land og bosatte sig i Karan. Og derfra lot Gud ham efter farens død flytte til dette land som I nu bor i; 
44:7:5: men han gav ham ikke arvelodd der, enn ikke en fotsbredd; og han lovte å gi ham det til eie, og hans ætt efter ham, enda han ikke hadde barn. 
44:7:6: Og Gud talte således: Hans ætt skal bo i utlendighet i et fremmed land, og de skal holde den i trældom og mishandle den i fire hundre år; 
44:7:7: og det folk som de skal træle under, vil jeg dømme, sa Gud, og derefter skal de dra ut derfra og tjene mig på dette sted. 
44:7:8: Og han gav ham omskjærelsens pakt; og så fikk han sønnen Isak, og omskar ham på den åttende dag, og Isak fikk sønnen Jakob, og Jakob blev far til de tolv patriarker. 
44:7:9: Og patriarkene bar avind mot Josef og solgte ham til Egypten; og Gud var med ham 
44:7:10: og fridde ham ut av alle hans trengsler, og gav ham nåde og visdom for Faraos, egypterkongens øine; og han satte ham til høvding over Egypten og hele sitt hus. 
44:7:11: Så kom det hungersnød over hele Egyptens land og Kana'an, og stor trengsel, og våre fedre fant ikke føde. 
44:7:12: Men da Jakob fikk høre at det var korn i Egypten, sendte han våre fedre dit første gang; 
44:7:13: og annen gang blev Josef gjenkjent av sine brødre, og Josefs byrd kom til Faraos kunnskap. 
44:7:14: Da sendte Josef bud og kalte til sig sin far Jakob og hele sin slekt, fem og sytti sjeler. 
44:7:15: Så drog Jakob ned til Egypten, og han døde, han og våre fedre, 
44:7:16: og de blev ført til Sikem og lagt i den grav som Abraham kjøpte for en kjøpesum i sølv av Hemors barn i Sikem. 
44:7:17: Efter som nu tiden nærmet sig da det løfte skulde opfylles som Gud hadde gitt Abraham, tok folket til og blev mannsterkt i Egypten, 
44:7:18: inntil det fremstod en annen konge over Egypten, som ikke kjente Josef; 
44:7:19: han fór med svik mot vårt folk, og ved mishandling tvang han våre fedre til å sette sine nyfødte barn ut, så de ikke skulde holdes i live. 
44:7:20: På den tid blev Moses født, og han var fager for Gud; han blev i tre måneder fostret i sin fars hus, 
44:7:21: og da han var blitt utsatt, tok Faraos datter ham op, og fostret ham og tok ham i sønns sted. 
44:7:22: Og Moses blev oplært i all egypternes visdom, og han var mektig i ord og gjerninger. 
44:7:23: Men da han var blitt firti år gammel, fikk han i sinne å se til sine brødre, Israels barn, 
44:7:24: og da han så en lide urett, kom han ham til hjelp og hevnet den som blev mishandlet, og slo egypteren ihjel. 
44:7:25: Han tenkte da at hans brødre skulde forstå at Gud gav dem frelse ved hans hånd; men de forstod det ikke. 
44:7:26: Den næste dag kom han til dem mens de trettet, og han formante dem til å holde fred, han sa: I menn! I er brødre; hvorfor gjør I hverandre urett? 
44:7:27: Men han som gjorde sin næste urett, støtte ham fra sig og sa: Hvem har satt dig til høvding og dommer over oss? 
44:7:28: Vil du kanskje slå mig ihjel, som du igår slo ihjel egypteren? 
44:7:29: Men Moses flyktet for dette ords skyld, og blev en utlending i Midians land og fikk der to sønner. 
44:7:30: Og da firti år var til ende, åpenbarte en engel sig for ham i berget Sinais ørken i en brennende tornebusk. 
44:7:31: Da Moses så det, undret han sig over synet; men da han gikk bort for å se på det, kom en røst fra Herren: 
44:7:32: Jeg er dine fedres Gud, Abrahams og Isaks og Jakobs Gud. Men Moses skalv av redsel, og vågde ikke å se på det. 
44:7:33: Og Herren sa til ham: Løs skoene av dine føtter! for det sted du står på, er hellig jord. 
44:7:34: Jeg har grant sett hvor mitt folk blir mishandlet i Egypten, og deres sukk har jeg hørt, og jeg er steget ned for å utfri dem. Og nu, kom, la mig sende dig til Egypten! 
44:7:35: Denne Moses som de fornektet, idet de sa: Hvem har satt dig til høvding og dommer? ham sendte Gud til å være både høvding og redningsmann ved den engels hånd som åpenbarte sig for ham i tornebusken. 
44:7:36: Han var den som førte dem ut, idet han gjorde undergjerninger og tegn i Egyptens land og i det Røde Hav og i ørkenen i firti år. 
44:7:37: Han er den Moses som sa til Israels barn: En profet, likesom mig, skal Gud opreise eder av eders brødre. 
44:7:38: Han er den som i menigheten i ørkenen var med den engel som talte til ham på Sinai berg, og med våre fedre, han som mottok levende ord for å gi oss dem. 
44:7:39: Ham vilde våre fedre ikke være lydige, de støtte ham fra sig, og vendte sig i sitt hjerte til Egypten, og de sa til Aron: 
44:7:40: Gjør oss guder som kan dra foran oss! for denne Moses som førte oss ut av Egyptens land vi vet ikke hvad det er blitt av ham. 
44:7:41: Og de gjorde en kalv i hine dager og bar frem offer til avgudsbilledet, og de gledet sig over sine henders verk. 
44:7:42: Men Gud vendte sig bort, og overgav dem til å dyrke himmelens hær, som skrevet er i profetenes bok: Israels hus! gav I mig vel slaktoffer og andre offer i firti år i ørkenen? 
44:7:43: Nei, I bar med eder Moloks telt og guden Remfans stjerne, de billeder som I gjorde for å tilbede dem, og jeg vil flytte eder bort hinsides Babylon. 
44:7:44: Våre fedre hadde i ørkenen vidnesbyrdets telt, således som den som talte til Moses, bød ham å gjøre det efter det forbillede han hadde sett; 
44:7:45: dette tok våre fedre i arv, og førte det med Josva inn i de hedningers eiendomsland som Gud drev bort for våre fedre, inntil Davids dager. 
44:7:46: Han fant nåde hos Gud, og bad om at han måtte finne et bosted for Jakobs Gud. 
44:7:47: Men Salomo bygget ham et hus. 
44:7:48: Men den Høieste bor ikke i hus som er gjort med hender, således som profeten sier: 
44:7:49: Himmelen er min trone, og jorden en skammel for mine føtter; hvad hus vil I bygge mig, sier Herren, eller hvor er det sted jeg skal hvile på? 
44:7:50: Har ikke min hånd gjort alt dette? 
44:7:51: I hårde halser og uomskårne på hjerte og ører! I står alltid den Hellige Ånd imot, som eders fedre, således også I. 
44:7:52: Hvem av profetene forfulgte ikke eders fedre? De drepte dem som forut forkynte at den rettferdige skulde komme, han som I nu har forrådt og myrdet, 
44:7:53: I som mottok loven gitt ved engler og ikke har holdt den! 
44:7:54: Men da de hørte dette, stakk det dem i deres hjerter, og de skar tenner mot ham. 
44:7:55: Men han var full av den Hellige Ånd og skuet ufravendt op mot himmelen, og han så Guds herlighet, og Jesus stå ved Guds høire hånd, 
44:7:56: og han sa: Nu ser jeg himlene åpne, og Menneskesønnen stå ved Guds høire hånd! 
44:7:57: Da skrek de med høi røst og holdt for sine ører, og stormet alle som en inn på ham 
44:7:58: og drev ham ut av byen og stenet ham; og vidnene la sine klær av sig ved en ung manns føtter som hette Saulus. 
44:7:59: Og de stenet Stefanus, mens han bad og sa: Herre Jesus, ta imot min ånd! 
44:7:60: Og han falt på kne og ropte med høi røst: Herre, tilregn dem ikke denne synd! Og som han hadde sagt dette, sov han inn. 
44:8:1: Og Saulus samtykte i mordet på ham. Men på den dag blev det en stor forfølgelse mot menigheten i Jerusalem, og de blev alle adspredt over Judeas og Samarias land, undtagen apostlene. 
44:8:2: Men nogen gudfryktige menn begravde Stefanus og holdt en stor veklage over ham. 
44:8:3: Men Saulus herjet menigheten, og gikk inn i hus efter hus og drog ut både menn og kvinner og lot dem kaste i fengsel. 
44:8:4: De som nu var adspredt, drog omkring og forkynte evangeliets ord. 
44:8:5: Filip kom da ned til en by i Samaria og forkynte Kristus for dem. 
44:8:6: Og folket gav samdrektig akt på det som blev sagt av Filip, idet de hørte og så de tegn som han gjorde. 
44:8:7: For det var mange som hadde urene ånder, og de fór ut av dem med høie skrik, og mange verkbrudne og vanføre blev helbredet. 
44:8:8: Og det blev stor glede der i byen. 
44:8:9: Men der var en mann ved navn Simon, som før hadde gitt sig av med trolldom i byen og satt folket i Samaria i den største forundring, for han sa sig selv å være stor; 
44:8:10: ham gav de akt på, både små og store, de sa: Han er Guds kraft som kalles den store. 
44:8:11: Men de gav akt på ham fordi han i lang tid hadde satt dem i forundring ved sine trolldomskunster. 
44:8:12: Men da de nu trodde Filip, som forkynte dem evangeliet om Guds rike og Jesu Kristi navn, så lot de sig døpe, både menn og kvinner. 
44:8:13: Og Simon tok ved troen han også, og da han var blitt døpt, holdt han sig nær til Filip, og da han så de kraftige gjerninger og tegn som blev gjort, blev han ute av sig selv av forundring. 
44:8:14: Da nu apostlene i Jerusalem fikk høre at Samaria hadde tatt imot Guds ord, sendte de Peter og Johannes til dem; 
44:8:15: disse kom ned og bad for dem, forat de skulde få den Hellige Ånd; 
44:8:16: for Ånden var ennu ikke falt på nogen av dem, de var bare døpt til den Herre Jesu navn. 
44:8:17: De la da sine hender på dem, og de fikk den Hellige Ånd. 
44:8:18: Men da Simon så at Ånden blev gitt ved apostlenes håndspåleggelse, kom han til dem med penger og sa: 
44:8:19: Gi også mig denne makt at den som jeg legger mine hender på, må få den Hellige Ånd! 
44:8:20: Men Peter sa til ham: Ditt sølv være forbannet, både det og du selv, fordi du tenkte å kjøpe Guds gave for penger! 
44:8:21: Du har ikke del eller lodd i dette ord; for ditt hjerte er ikke rett for Gud. 
44:8:22: Omvend dig derfor fra denne din ondskap, og bed Herren om måskje ditt hjertes tanke måtte forlates dig! 
44:8:23: For jeg ser at du ligger i bitterhets galle og urettferdighets bånd. 
44:8:24: Da svarte Simon: Bed I for mig til Herren, forat ikke noget av det I har sagt, skal komme over mig! 
44:8:25: Efterat de nu hadde vidnet og talt Herrens ord, vendte de tilbake til Jerusalem, og de forkynte evangeliet i mange av samaritanenes byer. 
44:8:26: Men en Herrens engel talte til Filip og sa: Stå op og gå mot syd, på den vei som går ned fra Jerusalem til Gasa! Denne vei er øde. 
44:8:27: Han stod da op og gikk dit; og se, der var en etioper, en hoffmann, en høi embedsmann hos Kandake, etiopernes dronning, en som var satt over hele hennes skatt; han var kommet til Jerusalem for å tilbede, 
44:8:28: og han var nu på hjemveien, og satt på sin vogn og leste profeten Esaias. 
44:8:29: Og Ånden sa til Filip: Gå bort til denne vogn og hold dig nær ved den! 
44:8:30: Filip løp da til, og hørte at han leste profeten Esaias, og sa: Skjønner du det du leser? 
44:8:31: Han svarte: Hvorledes skulde jeg vel kunne det uten at nogen veileder mig? Og han bad Filip stige op og sette sig hos ham. 
44:8:32: Men det stykke av Skriften som han leste, var dette: Som et får blev han ført bort for å slaktes, og lik et lam som er stumt for den som klipper det, således åpner han ikke sin munn; 
44:8:33: i hans fornedrelse blev dommen over ham borttatt, og hvem kan fortelle om hans ætt? for hans liv blir tatt bort fra jorden. 
44:8:34: Hoffmannen tok da til orde og sa til Filip: Jeg ber dig: om hvem sier profeten dette? om sig selv eller om nogen annen? 
44:8:35: Da oplot Filip sin munn, og idet han gikk ut fra dette skriftsted, forkynte han ham evangeliet om Jesus. 
44:8:36: Og som de drog frem på veien, kom de til et sted hvor det var vann, og hoffmannen sa: Se, her er vann; hvad er til hinder for at jeg blir døpt? 
44:8:37: Og Filip sa: Tror du av hele ditt hjerte, så kan det skje. Men han svarte og sa: Jeg tror at Jesus Kristus er Guds Sønn. 
44:8:38: Og han bød vognen holde, og de steg ned i vannet, både Filip og hoffmannen, og han døpte ham. 
44:8:39: Men da de steg op av vannet, rykte Herrens Ånd Filip bort, og hoffmannen så ham ikke lenger; for han drog sin vei med glede. 
44:8:40: Men Filip blev funnet i Asdod, og han drog omkring og forkynte evangeliet i alle byene, inntil han kom til Cesarea. 
44:9:1: Men Saulus fnyste fremdeles av trusel og mord mot Herrens disipler, og han gikk til ypperstepresten 
44:9:2: og bad ham om brev til Damaskus, til synagogene der, forat om han fant nogen som hørte Guds vei til, både menn og kvinner, han da kunde føre dem bundne til Jerusalem. 
44:9:3: Men på reisen skjedde det at han kom nær til Damaskus, og med ett strålte et lys fra himmelen om ham, 
44:9:4: og han falt til jorden og hørte en røst som sa til ham: Saul! Saul! hvorfor forfølger du mig? 
44:9:5: Han sa: Hvem er du, Herre? Og han svarte: Jeg er Jesus, han som du forfølger. 
44:9:6: Men stå op og gå inn i byen, så skal det bli dig sagt hvad du har å gjøre! 
44:9:7: Men mennene som reiste sammen med ham, stod forferdet, for de hørte vel røsten, men så ikke nogen. 
44:9:8: Saulus reiste sig da op fra jorden; men da han åpnet sine øine, så han intet; de ledet ham da ved hånden og førte ham inn i Damaskus. 
44:9:9: Og i tre dager var han uten syn og hverken åt eller drakk. 
44:9:10: Men det var i Damaskus en disippel ved navn Ananias, og Herren sa til ham i et syn: Ananias! Han svarte: Her er jeg, Herre! 
44:9:11: Og Herren sa til ham: Stå op og gå bort i den gate som kalles den rette, og spør i Judas' hus efter en som heter Saulus, fra Tarsus! for se, han beder, 
44:9:12: og han har i et syn sett en mann ved navn Ananias, som kom inn til ham og la hånden på ham forat han skulde få sitt syn igjen. 
44:9:13: Men Ananias svarte: Herre! jeg har hørt av mange om denne mann hvor meget ondt han har gjort mot dine hellige i Jerusalem, 
44:9:14: og her har han makt fra yppersteprestene til å binde alle dem som påkaller ditt navn. 
44:9:15: Men Herren sa til ham: Gå avsted! for han er mig et utvalgt redskap til å bære mitt navn frem både for hedninger og konger og for Israels barn; 
44:9:16: for jeg vil vise ham hvor meget han skal lide for mitt navns skyld. 
44:9:17: Så gikk Ananias avsted og kom inn i huset og la hendene på ham og sa: Saul, bror! Herren har sendt mig, Jesus, han som åpenbarte sig for dig på veien hvor du kom, forat du skal få ditt syn igjen og bli fylt med den Hellige Ånd. 
44:9:18: Og straks falt det likesom skjell fra hans øine, og han fikk sitt syn igjen, og han stod op og blev døpt, 
44:9:19: og han tok føde til sig og blev styrket. Han blev da nogen dager hos disiplene i Damaskus. 
44:9:20: Og straks forkynte han Jesus i synagogene, at han er Guds Sønn. 
44:9:21: Og alle som hørte det, blev ute av sig selv av forundring og sa: Er ikke dette han som i Jerusalem utryddet dem som påkaller dette navn? og han var kommet hit for å føre dem bundne til yppersteprestene. 
44:9:22: Men Saulus blev enn mere styrket, og han målbandt jødene som bodde i Damaskus, idet han beviste at Jesus er Messias. 
44:9:23: Da nu mange dager var gått, la jødene råd op om å slå ham ihjel; 
44:9:24: men Saulus fikk vite at de efterstrebte ham. De voktet også portene dag og natt for å slå ham ihjel; 
44:9:25: men hans disipler tok ham om natten og slapp ham ut gjennem muren, idet de firte ham ned i en kurv. 
44:9:26: Da han nu kom til Jerusalem, søkte han å holde sig nær til disiplene; og de fryktet alle for ham, for de trodde ikke at han var nogen disippel. 
44:9:27: Men Barnabas tok sig av ham og førte ham til apostlene, og han fortalte dem hvorledes han hadde sett Herren på veien, og at han hadde talt til ham, og hvorledes han i Damaskus hadde lært frimodig i Jesu navn. 
44:9:28: Så gikk han da inn og ut med dem i Jerusalem 
44:9:29: og lærte frimodig i Herrens navn, og han talte med de gresktalende jøder og innlot sig i ordskifte med dem. De søkte da å slå ham ihjel; 
44:9:30: men da brødrene fikk det å vite, førte de ham ned til Cesarea, og sendte ham derfra til Tarsus. 
44:9:31: Menigheten hadde nu fred over hele Judea og Galilea og Samaria; den opbyggedes og vandret i Herrens frykt, og vokste ved den Hellige Ånds hjelp. 
44:9:32: Og det hendte sig da Peter drog allesteds omkring, at han også kom ned til de hellige som bodde i Lydda. 
44:9:33: Der fant han en mann ved navn Æneas, som hadde ligget åtte år til sengs, fordi han var verkbrudden. 
44:9:34: Og Peter sa til ham: Æneas! Jesus Kristus helbreder dig; stå op og red selv din seng! Og straks stod han op, 
44:9:35: og alle som bodde i Lydda og Saron, så ham, og de omvendte sig til Herren. 
44:9:36: I Joppe var det en disippelinne ved navn Tabita, det er utlagt: Dorkas*; hun var rik på gode gjerninger og gav mange almisser. # <* en hind.> 
44:9:37: Men det skjedde i de dager at hun blev syk og døde; de vasket henne da og la henne på en sal. 
44:9:38: Og da Lydda ligger nær ved Joppe, og disiplene hadde hørt at Peter var der, sendte de to menn til ham og bad: Dryg ikke med å komme hit til oss! 
44:9:39: Peter stod da op og gikk med dem, og da han kom dit, førte de ham op på salen, og alle enkene stod ved hans side og gråt, og viste ham de kjortler og klær som Tabita hadde gjort den tid hun var hos dem. 
44:9:40: Men Peter bød alle gå ut, falt på kne og bad, og han vendte sig til liket og sa: Tabita, stå op! Hun slo øinene op, og da hun så Peter, satte hun sig op. 
44:9:41: Og han rakte henne hånden og reiste henne op; og han kalte de hellige og enkene inn og stilte henne levende frem for dem. 
44:9:42: Dette blev vitterlig over hele Joppe, og mange kom til troen på Herren. 
44:9:43: Derefter blev han en lengere tid i Joppe hos en mann ved navn Simon, en garver. 
44:10:1: Det var en mann i Cesarea ved navn Kornelius, høvedsmann ved den hærdeling som kaltes den italiske; 
44:10:2: han var en from mann og fryktet Gud med hele sitt hus og gav mange almisser til folket og bad alltid til Gud. 
44:10:3: Og han så grandgivelig i et syn, omkring den niende time på dagen, en Guds engel som kom inn til ham og sa til ham: Kornelius! 
44:10:4: Men han stirret på ham og blev forferdet og sa: Hvad er det, Herre? Han svarte ham: Dine bønner og dine almisser er steget op til ihukommelse for Gud. 
44:10:5: Og nu, send nogen menn til Joppe og hent til dig en mann ved navn Simon, som kalles med tilnavn Peter; 
44:10:6: han bor hos en garver som heter Simon, og som har et hus ved havet. 
44:10:7: Da nu engelen som talte til ham, var faret fra ham, kalte han til sig to av sine tjenere og en from stridsmann av dem som alltid var om ham, 
44:10:8: og han fortalte dem det alt sammen, og sendte dem avsted til Joppe. 
44:10:9: Den næste dag, mens disse var på veien og nærmet sig til byen, gikk Peter ved den sjette time op på taket for å bede. 
44:10:10: Han blev da sulten og vilde ha noget å ete. Mens de nu laget det til, kom det en henrykkelse over ham, 
44:10:11: og han ser himmelen åpnet og noget dale ned, likesom en stor duk, som blev senket ned på jorden efter de fire hjørner; 
44:10:12: i den var det alle slags firføtte og krypende dyr som lever på jorden, og alle slags himmelens fugler. 
44:10:13: Og det kom en røst til ham: Stå op, Peter! slakt og et! 
44:10:14: Men Peter sa: Ingenlunde, Herre! aldri har jeg ett noget vanhellig eller urent. 
44:10:15: Og det kom atter en røst, for annen gang, til ham: Det som Gud har renset, det må ikke du gjøre til urent. 
44:10:16: Dette skjedde tre ganger, og straks blev det tatt op igjen til himmelen. 
44:10:17: Mens nu Peter var tvilrådig med sig selv om hvad det syn vel skulde bety som han hadde sett, se, da stod de menn som var utsendt av Kornelius, for porten, efterat de hadde spurt sig frem til Simons hus, 
44:10:18: og de ropte inn og spurte om Simon som kaltes med tilnavn Peter, bodde der. 
44:10:19: Mens Peter nu grundet på synet, sa Ånden til ham: Se, tre menn leter efter dig; 
44:10:20: stå op og gå ned, og dra med dem uten å tvile! for det er jeg som har sendt dem. 
44:10:21: Da gikk Peter ned til mennene og sa: Se, jeg er den som I leter efter; hvad er årsaken til at I er kommet hit? 
44:10:22: De sa: Kornelius, en høvedsmann, en rettferdig og gudfryktig mann, som har godt vidnesbyrd av hele jødenes folk, fikk det bud av en hellig engel at han skulde hente dig til sitt hus og høre hvad du har å si. 
44:10:23: Han kalte dem da inn og gav dem herberge. Og den næste dag stod han op og drog avsted med dem, og nogen av brødrene fra Joppe gikk med ham. 
44:10:24: Dagen efter kom de til Cesarea, og Kornelius hadde kalt sammen sine frender og nærmeste venner og ventet på dem. 
44:10:25: Da nu Peter trådte inn, gikk Kornelius ham i møte og falt ned for hans føtter og tilbad ham. 
44:10:26: Men Peter reiste ham op og sa: Stå op! Også jeg er et menneske. 
44:10:27: Og idet han samtalte med ham, gikk han inn, og der fant han mange samlet, 
44:10:28: og han sa til dem: I vet hvor utillatelig det er for en jøde å omgåes med nogen av et annet folk eller å gå inn til ham; men Gud viste mig at jeg ikke skulde kalle noget menneske vanhellig eller urent; 
44:10:29: derfor kom jeg også uten innvending da jeg blev budsendt. Jeg spør altså: Av hvad årsak sendte I bud efter mig? 
44:10:30: Da sa Kornelius: For fire dager siden - det var da som nu ved den niende time - bad jeg i mitt hus, og se, en mann stod for mig i skinnende klædebon, 
44:10:31: og han sier: Kornelius! din bønn er hørt, og dine almisser er ihukommet for Guds åsyn; 
44:10:32: send derfor bud til Joppe og kall til dig Simon som kalles med tilnavnet Peter! han har herberge hos Simon, en garver, ved havet; og han skal tale til dig når han kommer hit. 
44:10:33: Derfor sendte jeg straks bud til dig, og du gjorde vel at du kom. Nu er vi da alle til stede for Guds åsyn for å høre alt som er dig pålagt av Herren. 
44:10:34: Peter oplot da sin munn og sa: Jeg skjønner i sannhet at Gud ikke gjør forskjell på folk; 
44:10:35: men blandt ethvert folkeslag tar han imot den som frykter ham og gjør rettferdighet. 
44:10:36: Det ord som han sendte ut til Israels barn, idet han i evangeliet forkynte fred ved Jesus Kristus han er alles Herre 
44:10:37: det ord kjenner I, det som utgikk over hele Judea efter å ha tatt sin begynnelse fra Galilea efter den dåp som Johannes forkynte - 
44:10:38: hvorledes Gud salvet Jesus fra Nasaret med den Hellige Ånd og kraft, han som gikk omkring og gjorde vel og helbredet alle som var overveldet av djevelen, fordi Gud var med ham. 
44:10:39: Og vi er vidner om alt det han gjorde både i jødenes land og i Jerusalem, han som de slo ihjel, idet de hengte ham på et tre. 
44:10:40: Ham opvakte Gud på den tredje dag, og gav ham å åpenbare sig, 
44:10:41: ikke for hele folket, men for de vidner som var forut valgt av Gud, for oss, vi som åt og drakk sammen med ham efterat han var opstanden fra de døde. 
44:10:42: Og han bød oss å forkynne for folket og vidne at han er den som av Gud er bestemt til å være dommer over levende og døde. 
44:10:43: Ham gir alle profetene det vidnesbyrd at hver den som tror på ham, får syndenes forlatelse ved hans navn. 
44:10:44: Mens Peter ennu talte disse ord, falt den Hellige Ånd på alle dem som hørte ordet. 
44:10:45: Og alle de troende av omskjærelsen som var kommet med Peter, blev forferdet over at den Hellige Ånds gave var blitt utgytt også over hedningene; 
44:10:46: for de hørte dem tale med tunger og lovprise Gud. 
44:10:47: Da svarte Peter: Mon nogen kan nekte dem vannet, så de ikke skulde bli døpt, de som har fått den Hellige Ånd likesom vi? 
44:10:48: Og han bød at de skulde døpes i Jesu Kristi navn. De bad ham da bli der nogen dager. 
44:11:1: Apostlene og de brødre som var omkring i Judea, fikk da høre at også hedningene hadde tatt imot Guds ord. 
44:11:2: Da nu Peter kom op til Jerusalem, gikk de av omskjærelsen i rette med ham og sa: 
44:11:3: Du gikk inn til uomskårne menn og åt med dem. 
44:11:4: Men Peter begynte da fra først av og la det ut i sammenheng og sa: 
44:11:5: Jeg var i byen Joppe og bad; da så jeg i en henrykkelse et syn: Det dalte ned noget, likesom en stor duk, som blev senket ned fra himmelen efter de fire hjørner, og det kom like bort til mig; 
44:11:6: da jeg stirret på den og så nøie efter, blev jeg var jordens firføtte dyr og ville og krypende dyr og himmelens fugler. 
44:11:7: Jeg hørte også en røst som sa til mig: Stå op, Peter! slakt og et! 
44:11:8: Men jeg sa: Ingenlunde, Herre! aldri er noget vanhellig eller urent kommet i min munn. 
44:11:9: En røst fra himmelen svarte mig da annen gang: Det som Gud har renset, det må ikke du gjøre til urent. 
44:11:10: Dette skjedde tre ganger; og det blev alt sammen dradd op igjen til himmelen. 
44:11:11: Og se, i det samme stod tre menn utenfor huset der jeg var; de var utsendt til mig fra Cesarea, 
44:11:12: Og Ånden sa til mig at jeg skulde gå med dem uten å tvile; disse seks brødre drog også med mig; og vi kom inn i mannens hus. 
44:11:13: Og han fortalte oss hvorledes han hadde sett engelen som stod i hans hus og sa til ham: Send bud til Joppe og hent til dig Simon som kalles med tilnavn Peter! 
44:11:14: han skal tale ord til dig som du skal bli frelst ved, du og hele ditt hus. 
44:11:15: Men da jeg begynte å tale, falt den Hellige Ånd på dem, likesom på oss i begynnelsen. 
44:11:16: Da kom jeg Herrens ord i hu, at han sa: Johannes døpte med vann, men I skal døpes med den Hellige Ånd. 
44:11:17: Gav nu altså Gud dem den samme gave som han gav oss, da de var kommet til troen på den Herre Jesus Kristus, hvem var da vel jeg, at jeg skulde være i stand til å hindre Gud? 
44:11:18: Da de hørte dette, slo de sig til ro, og de priste Gud og sa: Så har da Gud også gitt hedningene omvendelsen til livet. 
44:11:19: De som nu var adspredt på grunn av den trengsel som opstod for Stefanus' skyld, drog omkring like til Fønikia og Kypern og Antiokia, men talte ikke ordet til andre enn jøder. 
44:11:20: Men iblandt dem var det nogen menn fra Kypern og Kyrene, som kom til Antiokia, og der talte de også til grekerne og forkynte dem evangeliet om den Herre Jesus. 
44:11:21: Og Herrens hånd var med dem, og et stort tall kom til troen og omvendte sig til Herren. 
44:11:22: Denne tidende kom da menigheten i Jerusalem for øre, og de sendte Barnabas ut for å dra til Antiokia; 
44:11:23: da han kom dit og så Guds nåde, gledet han sig, og han formante alle til at de med hjertets forsett skulde holde fast ved Herren; 
44:11:24: for han var en god mann og full av den Hellige Ånd og tro. Og en stor skare blev vunnet for Herren. 
44:11:25: Han drog da ut til Tarsus for å opsøke Saulus, og da han hadde funnet ham, førte han ham til Antiokia. 
44:11:26: Og et helt år var de sammen der i menigheten og lærte en stor skare, og i Antiokia fikk disiplene først navnet kristne. 
44:11:27: I disse dager kom det nogen profeter ned fra Jerusalem til Antiokia, 
44:11:28: og en av dem, ved navn Agabus, stod op og varslet ved Ånden at det skulde komme en stor hungersnød over hele jorderike; den kom også under Klaudius. 
44:11:29: Disiplene vedtok da, alt efter som enhver av dem hadde råd til, å sende noget til hjelp for de brødre som bodde i Judea. 
44:11:30: Dette gjorde de også, og de sendte det med Barnabas og Saulus til de eldste. 
44:12:1: På denne tid la kong Herodes hånd på nogen av menigheten og mishandlet dem. 
44:12:2: Jakob, Johannes' bror, avlivet han med sverd, 
44:12:3: og da han så at det var jødene til behag, grep han dessuten også Peter - det var de usyrede brøds dager - 
44:12:4: og da han hadde grepet ham, kastet han ham i fengsel, og overlot til fire vaktskifter av stridsmenn, hvert på fire mann, å passe på ham, da han efter påsken vilde føre ham frem for folket. 
44:12:5: Så holdt de da vakt over Peter i fengslet; men det blev gjort inderlig bønn til Gud for ham av menigheten. 
44:12:6: Da nu Herodes skulde til å føre ham frem, sov Peter den natt mellem to stridsmenn, bundet med to lenker, og vaktmenn utenfor døren passet på fengslet. 
44:12:7: Og se, en Herrens engel stod der, og et lys skinte i fangerummet, og han støtte Peter i siden og vekket ham op og sa: Skynd dig og stå op! Og lenkene falt av hans hender. 
44:12:8: Og engelen sa til ham: Bind op om dig og knyt dine sko på dig! Han gjorde så. Og han sier til ham: Kast din kappe om dig, og følg mig! 
44:12:9: Han gikk da ut og fulgte ham, og han forstod ikke at det som engelen gjorde, var virkelig, han trodde han så et syn. 
44:12:10: De gikk da gjennem den første vakt og den annen, og kom til den jernport som førte ut til byen; den åpnet sig for dem av sig selv, og de trådte ut og gikk en gate frem, og straks skiltes engelen fra ham. 
44:12:11: Da kom Peter til sig selv og sa: Nu vet jeg for visst at Herren har utsendt sin engel og fridd mig ut av Herodes' hånd og av alt det som jødefolket hadde ventet på. 
44:12:12: Da han nu hadde sanset sig, gikk han bort til det hus som eides av Maria, mor til Johannes som kaltes med tilnavn Markus; der var mange samlet og holdt bønn. 
44:12:13: Og da han banket på porten, kom det en tjenestepike ved navn Rode ut for å høre efter, 
44:12:14: og da hun kjente Peters røst, blev hun så glad at hun ikke åpnet porten, men løp inn og fortalte at Peter stod utenfor 
44:12:15: De sa til henne: Du er fra sans og samling. Men hun forsikret at det var således. Da sa de: Det er hans engel. 
44:12:16: Men Peter blev ved å banke på, de lukket da op og så ham, og de blev forferdet. 
44:12:17: Han vinket da til dem med hånden at de skulde tie stille, og han fortalte dem hvorledes Herren hadde ført ham ut av fengslet, og sa: Fortell dette til Jakob og brødrene! Så gikk han bort og drog til et annet sted. 
44:12:18: Da det nu blev dag, var det ikke lite opstyr blandt stridsmennene; de undret sig over hvad det vel var blitt av Peter. 
44:12:19: Herodes lot lete efter ham, men fant ham ikke; han tok da vaktmennene i forhør og bød at de skulde føres bort; og han drog ned fra Judea til Cesarea og opholdt sig der. 
44:12:20: Han var meget forbitret på tyrierne og sidonierne; men de enedes om å trede frem for ham, og efterat de hadde fått Blastus, kongens kammerherre, på sin side, bad de om fred, fordi deres land hadde sin næring fra kongens. 
44:12:21: På en fastsatt dag klædde da Herodes sig i kongelig skrud og satte sig på sin trone og holdt en tale til dem, 
44:12:22: og folket ropte til ham: Dette er Guds røst, og ikke et menneskes. 
44:12:23: Straks slo en Herrens engel ham, fordi han ikke gav Gud æren, og han blev fortært av ormer og opgav ånden. 
44:12:24: Men Guds ord hadde fremgang og utbredte sig. 
44:12:25: Og Barnabas og Saulus vendte tilbake fra Jerusalem efterat de hadde fullført sin tjeneste, og de tok med sig derfra Johannes som kaltes med tilnavn Markus. 
44:13:1: I Antiokia, i menigheten der, var det profeter og lærere: Barnabas og Simeon, som kaltes Niger, og LUKius fra Kyrene og Manaen, fosterbror til fjerdingsfyrsten Herodes, og Saulus. 
44:13:2: Mens de holdt gudstjeneste og fastet, sa den Hellige Ånd: Ta ut for mig Barnabas og Saulus til den gjerning som jeg har kalt dem til! 
44:13:3: Da lot de dem dra ut efterat de hadde fastet og bedt og lagt hendene på dem. 
44:13:4: Da de nu således var utsendt av den Hellige Ånd, drog de ned til Seleukia og seilte derfra til Kypern, 
44:13:5: og da de var kommet til Salamis, forkynte de Guds ord i jødenes synagoger; de hadde også Johannes med, som skulde gå dem til hånde. 
44:13:6: Efterat de nu hadde vandret gjennem hele øen like til Pafus, traff de på en trollmann, en falsk profet, en jøde ved navn Barjesus, 
44:13:7: som var hos landshøvdingen Sergius Paulus, en forstandig mann. Denne kalte Barnabas og Saulus til sig og bad om å få høre Guds ord. 
44:13:8: Men Elymas, trollmannen for således uttydes hans navn stod dem imot og søkte å vende landshøvdingen bort fra troen. 
44:13:9: Da blev Saulus, som også kaltes Paulus, fylt av den Hellige Ånd, og han så skarpt på ham og sa: 
44:13:10: Du som er full av all svik og all ondskap, du djevelens barn, all rettferdighets fiende! vil du ikke holde op med å forvende Herrens rette veier? 
44:13:11: Og nu, se, Herrens hånd er over dig, og du skal bli blind og til en tid ikke se solen. Og straks falt det skodde og mørke over ham, og han gikk omkring og søkte efter nogen som kunde lede ham ved hånden. 
44:13:12: Da kom landshøvdingen til troen, da han så det som var skjedd, og han undret sig storlig over Herrens lære. 
44:13:13: Paulus og de som var med ham, seilte da ut fra Pafus og kom til Perge i Pamfylia, men Johannes skilte sig fra dem og vendte tilbake til Jerusalem. 
44:13:14: Men de drog videre fra Perge og kom til Antiokia i Pisidia, og gikk inn i synagogen på sabbatsdagen og satte sig der. 
44:13:15: Efter oplesningen av loven og profetene sendte da synagoge-forstanderne bud til dem og sa: Brødre! har I noget formaningsord til folket, så tal! 
44:13:16: Da stod Paulus op og slo til lyd med hånden og sa: Israelittiske menn og I som frykter Gud! Hør: 
44:13:17: Dette folks, Israels, Gud utvalgte våre fedre, og han lot folket vokse sig stort under utlendigheten i Egypten, og med løftet arm førte han dem ut derfra, 
44:13:18: og omkring firti år bar han dem på faderarm i ørkenen, 
44:13:19: og han utryddet syv folkeslag i Kana'ans land og skiftet deres land ut til arv for dem, i omkring fire hundre og femti år. 
44:13:20: Siden gav han dem dommere inntil profeten Samuel. 
44:13:21: Og derefter krevde de en konge, og Gud gav dem Saul, Kis' sønn, en mann av Benjamins stamme, i firti år, 
44:13:22: og efterat han hadde avsatt ham, opreiste han dem David til konge, som han også gav dette vidnesbyrd: Jeg fant David, Isais sønn, en mann efter mitt hjerte; han skal gjøre all min vilje. 
44:13:23: Av hans ætt førte han efter sitt løfte en frelser frem for Israel, Jesus, 
44:13:24: efterat Johannes forut for hans fremtreden hadde forkynt omvendelses dåp for hele Israels folk. 
44:13:25: Men da Johannes fullendte sitt løp, sa han: Den I holder mig for å være, er jeg ikke; men se, det kommer en efter mig, hvis sko jeg ikke er verdig til å løse av hans føtter. 
44:13:26: Brødre, sønner av Abrahams ætt og de iblandt eder som frykter Gud! til eder blev ordet om denne frelse utsendt. 
44:13:27: For de som bor i Jerusalem, og deres rådsherrer kjente ham ikke, og ved å dømme ham opfylte de profetenes ord som blir lest hver sabbat, 
44:13:28: og enda de ikke fant nogen dødsskyld hos ham, bad de Pilatus at han måtte bli slått ihjel. 
44:13:29: Og da de hadde fullbyrdet alt som er skrevet om ham, tok de ham ned av treet og la ham i en grav. 
44:13:30: Men Gud opvakte ham fra de døde, 
44:13:31: og han åpenbarte sig i flere dager for dem som hadde draget op med ham fra Galilea til Jerusalem, de som nu er hans vidner for folket. 
44:13:32: Og vi forkynner eder evangeliet om det løfte som blev gitt til fedrene, at dette har Gud opfylt for oss, deres barn, idet han opreiste Jesus, 
44:13:33: således som det også er skrevet i den annen salme: Du er min Sønn; jeg har født dig idag. 
44:13:34: Men at han har opreist ham fra de døde, så han ikke mere skal vende tilbake til tilintetgjørelse, det har han sagt således: Jeg vil gi eder de hellige løfter til David, de trofaste. 
44:13:35: Derfor sier han også på et annet sted: Du skal ikke overgi din hellige til å se tilintetgjørelse. 
44:13:36: For David sov inn, efterat han i sin levetid hadde tjent Guds råd, og han blev samlet med sine fedre og så tilintetgjørelse; 
44:13:37: men den som Gud opvakte, han så ikke tilintetgjørelse. 
44:13:38: Så være det eder da vitterlig, brødre, at ved ham forkynnes eder syndenes forlatelse, 
44:13:39: og fra alt det som I ikke kunde rettferdiggjøres fra ved Mose lov, rettferdiggjøres i ham enhver som tror. 
44:13:40: Se da til at ikke det kommer over eder som er sagt hos profetene: 
44:13:41: Se, I foraktere, og undre eder og bli til intet! for en gjerning gjør jeg i eders dager, en gjerning som I ikke vil tro om nogen forteller eder det. 
44:13:42: Da de gikk ut, bad folk dem om at disse ord måtte bli talt til dem den følgende sabbat. 
44:13:43: Og da synagoge-tjenesten var til ende, fulgte mange av jødene og av de gudsdyrkere som hadde tatt ved jødenes tro, med Paulus og Barnabas; disse talte da med dem og formante dem til å holde fast ved Guds nåde. 
44:13:44: På den næste sabbat samlet da næsten hele byen sig for å høre Herrens ord. 
44:13:45: Men da jødene så folkehopen, blev de fulle av nidkjærhet og motsa det som blev sagt av Paulus, ja motsa og spottet. 
44:13:46: Da tok Paulus og Barnabas til orde og sa dem rent ut: Det var nødvendig at Guds ord blev talt først til eder; men siden I støter det fra eder og ikke akter eder verdige til det evige liv, så vender vi oss nu til hedningene. 
44:13:47: For så er Herrens bud til oss: Jeg har satt dig til et lys for hedninger, forat du skal være til frelse inntil jordens ende. 
44:13:48: Da hedningene hørte det, blev de glade og priste Herrens ord, og de tok ved troen så mange som var utsett til evig liv. 
44:13:49: Og Herrens ord utbredte sig over hele landet. 
44:13:50: Men jødene satte op de fornemme kvinner som var tilhengere av jødenes tro, og de første menn i byen, og de reiste en forfølgelse mot Paulus og Barnabas, og drev dem bort fra sine landemerker. 
44:13:51: De rystet da støvet av sine føtter mot dem, og kom til Ikonium. 
44:13:52: Men disiplene blev fylt av glede og den Hellige Ånd. 
44:14:1: I Ikonium skjedde det da at de sammen gikk inn i jødenes synagoge og talte således at en stor mengde både av jøder og av grekere kom til troen. 
44:14:2: Men de jøder som ikke vilde tro, opegget hedningenes sinn og satte ondt i dem mot brødrene. 
44:14:3: De blev nu en lang tid der og talte frimodig i Herren, som gav sitt nådesord vidnesbyrd, idet han lot tegn og under skje ved deres hender. 
44:14:4: Og mengden i byen delte sig; nogen holdt med jødene, andre med apostlene. 
44:14:5: Men da nu både hedningene og jødene med deres styresmenn stormet frem og vilde mishandle dem og stene dem, 
44:14:6: og de merket dette, flydde de til byene i Lykaonia, Lystra og Derbe, og landet deromkring, 
44:14:7: og forkynte evangeliet der. 
44:14:8: Og i Lystra satt det en mann som ikke hadde makt i føttene, da han var vanfør fra mors liv av, og som aldri hadde kunnet gå. 
44:14:9: Han hørte Paulus tale; denne så skarpt på ham, og da han så at han hadde tro til å bli helbredet, sa han med høi røst: 
44:14:10: Reis dig og stå oprett på dine føtter! Og han sprang op og gikk omkring. 
44:14:11: Men da folket så det som Paulus hadde gjort, ropte de med høi røst på lykaonisk og sa: Gudene er blitt mennesker lik og er kommet ned til oss. 
44:14:12: Og de kalte Barnabas Jupiter og Paulus Merkur, fordi han var den som førte ordet. 
44:14:13: Og presten ved det Jupitertempel som var utenfor byen, kom til porten med okser og kranser og vilde ofre sammen med folket. 
44:14:14: Men da apostlene Barnabas og Paulus hørte dette, sønderrev de sine klær og sprang ut til hopen 
44:14:15: og ropte: I menn! hvad er det I gjør? Også vi er mennesker under samme vilkår som I, og vi forkynner eder evangeliet at I skal vende eder bort fra disse falske guder til den levende Gud, han som gjorde himmelen og jorden og havet og alt som i dem er, 
44:14:16: han som i de fremfarne tider lot alle hedningefolkene vandre sine egne veier, 
44:14:17: enda han ikke lot sig uten vidnesbyrd, idet han gjorde godt, gav eder regn og fruktbare tider fra himmelen, og mettet eders hjerter med føde og glede. 
44:14:18: Og ved å si dette fikk de med nød og neppe hindret folket fra å ofre til dem. 
44:14:19: Men det kom jøder til fra Antiokia og Ikonium og overtalte folket, og de stenet Paulus og slepte ham utenfor byen, da de trodde at han var død; 
44:14:20: men mens disiplene flokket sig omkring ham, stod han op og gikk inn i byen. Den næste dag drog han med Barnabas avsted til Derbe. 
44:14:21: Og efterat de hadde forkynt evangeliet der i byen og gjort mange disipler, vendte de tilbake til Lystra og Ikonium og Antiokia, 
44:14:22: idet de styrket disiplenes sjeler og formante dem til å holde fast ved troen og sa: Vi må gå inn i Guds rike gjennem mange trengsler. 
44:14:23: Og efterat de hadde valgt eldste for dem i hver menighet, overgav de dem med bønn og faste til den Herre som de hadde satt sin tro til. 
44:14:24: Efterat de så hadde draget igjennem Pisidia, kom de til Pamfylia, 
44:14:25: og da de hadde talt ordet i Perge, drog de ned til Attalia, 
44:14:26: og seilte derfra til Antiokia, det sted hvor de var blitt overgitt til Guds nåde for å gjøre den gjerning som de nu hadde fullført. 
44:14:27: Da de nu kom dit, samlet de menigheten og fortalte hvor store ting Gud hadde gjort ved dem, og at han hadde åpnet troens dør for hedningene. 
44:14:28: Og så blev de en ikke så kort tid hos disiplene. 
44:15:1: Og det kom nogen ned fra Judea og lærte brødrene: Dersom I ikke blir omskåret efter Mose skikk, kan I ikke bli frelst. 
44:15:2: Da det nu opstod strid, og Paulus og Barnabas fikk et ikke lite ordskifte med dem, vedtok de at Paulus og Barnabas og nogen andre av dem skulde dra op til apostlene og de eldste i Jerusalem og legge dette spørsmål frem for dem. 
44:15:3: Menigheten fulgte dem da et stykke på veien, og så drog de gjennem Fønikia og Samaria, idet de fortalte om hedningenes omvendelse, og de vakte stor glede hos alle brødrene. 
44:15:4: Og da de kom frem til Jerusalem, blev de mottatt av menigheten og apostlene og de eldste, og de fortalte hvor store ting Gud hadde gjort ved dem. 
44:15:5: Men det reiste sig nogen av fariseernes parti som hadde tatt ved troen, og sa: De må omskjæres og bydes å holde Mose lov. 
44:15:6: Apostlene og de eldste kom da sammen for å overveie denne sak. 
44:15:7: Da det nu blev et skarpt ordskifte, stod Peter op og sa til dem: Brødre! I vet at allerede for lenge siden gjorde Gud det valg iblandt eder at ved min munn skulde hedningene få høre evangeliets ord og komme til troen. 
44:15:8: Og Gud, som kjenner hjertene, gav dem vidnesbyrd, idet han gav dem likesom oss den Hellige Ånd, 
44:15:9: og han gjorde ingen forskjell mellem oss og dem, idet han ved troen renset deres hjerter. 
44:15:10: Hvorfor frister I da nu Gud ved å legge et åk på disiplenes nakke som hverken våre fedre eller vi var i stand til å bære? 
44:15:11: Men ved den Herre Jesu nåde tror vi at vi blir frelst, på samme måte som de. 
44:15:12: Da tidde hele mengden, og de hørte på Barnabas og Paulus, som fortalte hvor store tegn og undergjerninger Gud hadde gjort blandt hedningene ved dem. 
44:15:13: Og da de hadde holdt op å tale, tok Jakob til orde og sa: Brødre! Hør på mig: 
44:15:14: Simeon har fortalt hvorledes Gud fra først av drog omsorg for å få et folk av hedninger for sitt navn. 
44:15:15: Og dermed stemmer profetenes ord overens, således som det er skrevet: 
44:15:16: Derefter vil jeg vende tilbake og igjen opbygge Davids falne hytte, og det nedbrutte av den vil jeg atter opbygge, og jeg vil igjen opreise den, 
44:15:17: forat alle andre mennesker skal søke Herren, ja alle hedningefolkene, som mitt navn er blitt nevnt over, sier Herren, som gjør dette 
44:15:18: som er kjent av ham fra evighet av. 
44:15:19: Derfor mener jeg at vi ikke skal gjøre det tungt for dem av hedningene som omvender sig til Gud, 
44:15:20: men skrive til dem at de skal avholde sig fra avgudenes urenhet og fra hor og fra det som er kvalt, og fra blod. 
44:15:21: For Moses har fra gammel tid av i hver by dem som forkynner ham, idet han hver sabbat opleses i synagogene. 
44:15:22: Da vedtok apostlene og de eldste sammen med hele menigheten å velge nogen menn iblandt sig og sende dem til Antiokia sammen med Paulus og Barnabas; det var Judas med tilnavnet Barsabbas og Silas, som var høit aktede menn blandt brødrene, 
44:15:23: og de skrev således med dem: Apostlene og eldste-brødrene hilser brødrene av hedningene i Antiokia og Syria og Kilikia. 
44:15:24: Eftersom vi har fått høre at nogen som er kommet fra oss, har forvirret eder ved sin tale og vakt uro i eders sjeler vi har ikke gitt dem noget pålegg 
44:15:25: så har vi, efterat vi var kommet til enighet i denne sak, vedtatt å velge nogen menn og sende dem til eder sammen med våre elskede brødre Barnabas og Paulus, 
44:15:26: menn som har våget sitt liv for vår Herre Jesu Kristi navns skyld. 
44:15:27: Vi har da sendt Judas og Silas, som også skal forkynne eder det samme muntlig. 
44:15:28: For den Hellige Ånd og vi har besluttet ikke å legge nogen annen tyngsel på eder enn disse nødvendige ting: 
44:15:29: at I avholder eder fra avgudsoffer og blod og det som er kvalt, og hor; dersom I vokter eder for disse ting, vil det gå eder vel. Lev vel! 
44:15:30: Da nu disse var sendt avsted, kom de til Antiokia, og de samlet hele menigheten og gav dem brevet. 
44:15:31: Da de hadde lest det, blev de glade over trøsten de fikk. 
44:15:32: Judas og Silas, som selv var profeter, trøstet brødrene med mange ord og styrket dem, 
44:15:33: og efterat de hadde vært en tid der, lot brødrene dem fare med fred til dem som hadde utsendt dem. 
44:15:34: Silas fant det best å bli der. 
44:15:35: Men Paulus og Barnabas blev i Antiokia, og de lærte og forkynte Herrens ord ved evangeliet sammen med mange andre. 
44:15:36: Men nogen tid derefter sa Paulus til Barnabas: La oss nu vende tilbake og se til brødrene i enhver by hvor vi forkynte Herrens ord, hvorledes de har det! 
44:15:37: Barnabas vilde også ta med Johannes som kaltes Markus; 
44:15:38: men Paulus syntes ikke at de skulde ta ham med som hadde skilt sig fra dem i Pamfylia og ikke var gått med dem til deres gjerning. 
44:15:39: Det kom da til en skarp strid, så de skiltes fra hverandre, og Barnabas tok Markus med sig og seilte avsted til Kypern; 
44:15:40: men Paulus valgte Silas til sin følgesvenn og drog ut, efterat brødrene hadde overgitt ham til Herrens nåde. 
44:15:41: Og han drog igjennem Syria og Kilikia og styrket menighetene. 
44:16:1: Han kom da til Derbe og Lystra, og se, der var en disippel ved navn Timoteus, sønn av en jødisk kvinne som hadde tatt ved troen, og av en gresk far; 
44:16:2: han hadde godt lov av brødrene i Lystra og Ikonium. 
44:16:3: Ham vilde Paulus ha med sig på reisen, og han tok og omskar ham for de jøders skyld som var på de steder; for de visste alle at hans far var greker. 
44:16:4: Efter som de nu drog fra by til by, påla de brødrene å holde de bud som var vedtatt av apostlene og de eldste i Jerusalem. 
44:16:5: Så blev da menighetene styrket i troen, og vokste i tall for hver dag. 
44:16:6: De drog nu igjennem Frygia og det galatiske land, fordi de blev hindret av den Hellige Ånd fra å tale ordet i Asia; 
44:16:7: og da de var kommet bortimot Mysia, prøvde de på å fare til Bitynia, men Jesu Ånd gav dem ikke lov; 
44:16:8: så drog de da utenom Mysia og drog ned til Troas. 
44:16:9: Og et syn viste sig om natten for Paulus: Det stod en mann fra Makedonia og bad ham og sa: Kom over til Makedonia og hjelp oss! 
44:16:10: Da han hadde sett synet, søkte vi straks å dra over til Makedonia; vi kunde skjønne at Herren hadde kalt oss til å forkynne evangeliet for dem. 
44:16:11: Vi seilte da ut fra Troas og styrte rett frem til Samotrake, og den næste dag til Neapolis, 
44:16:12: og derfra til Filippi, som er den første by i den del av Makedonia og en romersk koloni; der i byen blev vi da nogen tid. 
44:16:13: På sabbatsdagen gikk vi utenfor porten, til en elv hvor de pleide å samles til bønn, og vi satte oss der og talte til de kvinner som var kommet sammen. 
44:16:14: Og der var en kvinne ved navn Lydia, en purpur-kremmerske fra byen Tyatira, som dyrket Gud efter jødenes tro; hun hørte på, og Herren oplot hennes hjerte, så hun gav akt på det som blev talt av Paulus. 
44:16:15: Da nu hun og hennes hus var blitt døpt, bad hun oss og sa: Så sant I holder mig for å være en som tror på Herren, så kom inn i mitt hus og ta ophold der! Og hun nødde oss. 
44:16:16: Men det skjedde engang vi drog ut til bedestedet, at vi møtte en trælkvinne som hadde en spådomsånd, og som hjalp sine herrer til stor inntekt ved å spå. 
44:16:17: Hun fulgte efter Paulus og oss og ropte: Disse mennesker er den høieste Guds tjenere, som forkynner eder frelsens vei. 
44:16:18: Dette gjorde hun i mange dager. Da harmedes Paulus og vendte sig om og sa til ånden: Jeg byder dig i Jesu Kristi navn å fare ut av henne! Og den fór ut i samme stund. 
44:16:19: Men da hennes herrer så at deres håp om inntekt var ute, tok de fatt på Paulus og Silas og slepte dem avsted til torvet for å stille dem for retten, 
44:16:20: og de førte dem frem for styresmennene og sa: Disse mennesker forstyrrer rent vår by; de er jøder, 
44:16:21: og forkynner skikker som vi ikke har lov til å ta imot eller leve efter, vi som er romere. 
44:16:22: Folket reiste sig også imot dem; styresmennene rev da klærne av dem og lot dem hudstryke, 
44:16:23: og da de hadde gitt dem mange slag, kastet de dem i fengsel, og bød fangevokteren å passe godt på dem. 
44:16:24: Da han hadde fått dette bud, kastet han dem i det innerste fangerum og satte deres føtter fast i stokken. 
44:16:25: Men ved midnattstid holdt Paulus og Silas bønn og sang lovsanger til Gud, og fangene hørte på dem. 
44:16:26: Da kom det med ett et sterkt jordskjelv, så fengslets grunnvoller rystet, og straks sprang alle dører op, og alles lenker løstes. 
44:16:27: Fangevokteren fór da op av søvne, og da han så fengslets dører åpne, drog han et sverd og vilde drepe sig, fordi han trodde at fangene var rømt. 
44:16:28: Men Paulus ropte med høi røst: Gjør dig ikke noget ondt! vi er her alle. 
44:16:29: Han bad da om schmelk og sprang inn, og han falt skjelvende ned for Paulus og Silas. 
44:16:30: Og han førte dem utenfor og sa: Herrer! hvad skal jeg gjøre for å bli frelst? 
44:16:31: De sa da: Tro på den Herre Jesus, sa skal du bli frelst, du og ditt hus! 
44:16:32: Og de talte Herrens ord til ham og til alle som var i hans hus 
44:16:33: Så tok han dem til sig i samme time på natten og vasket dem efter slagene, og straks blev han døpt med alle sine, 
44:16:34: og han førte dem op i sitt hus og satte et bord for dem, og frydet sig, efterat han med hele sitt hus var kommet til troen på Gud. 
44:16:35: Da det nu var blitt dag, sendte styresmennene bud med sine tjenere: Gi disse mennesker fri! 
44:16:36: Fangevokteren fortalte da disse ord til Paulus: Styresmennene har sendt bud at I skal gis fri; så gå nu ut og dra bort i fred! 
44:16:37: Men Paulus sa til dem: De har hudstrøket oss for alles øine, uten lov og dom, vi som er romerske borgere; så har de kastet oss i fengsel, og nu jager de oss lønnlig bort? Nei, la dem selv komme og føre oss ut! 
44:16:38: Tjenerne meldte disse ord til styresmennene; da blev de redde, da de fikk høre at de var romerske borgere, 
44:16:39: og de kom og gav dem gode ord, og førte dem ut og bad dem fare fra byen. 
44:16:40: Så gikk de da ut av fengslet og gikk inn til Lydia, og efterat de hadde sett brødrene og formant dem, drog de avsted. 
44:17:1: Efterat de hadde reist igjennem Amfipolis og Apollonia, kom de til Tessalonika, hvor jødene hadde en synagoge. 
44:17:2: Efter sin sedvane gikk Paulus inn til dem, og på tre sabbatsdager holdt han samtale med dem ut av skriftene, 
44:17:3: og la ut og forklarte at Messias skulde lide og opstå fra de døde, og sa: Dette er Messias, den Jesus som jeg forkynner eder. 
44:17:4: Og nogen av dem lot sig overbevise og holdt sig til Paulus og Silas, likeså en stor mengde av de grekere som dyrket Gud, og ikke få av de fornemste kvinner. 
44:17:5: Men jødene blev nidkjære og tok med sig nogen onde mennesker, nogen dagdrivere, og gjorde et opløp og satte byen i røre, og de trengte frem imot Jasons hus og søkte å få dem ut til folket; 
44:17:6: men da de ikke fant dem, slepte de Jason og nogen brødre frem for by-dommerne og ropte: Disse som opvigler hele verden, er også kommet hit, 
44:17:7: og Jason har tatt imot dem; og alle disse gjør stikk imot keiserens bud og sier at en annen, en som heter Jesus, er konge. 
44:17:8: Da folket og by-dommerne hørte dette, blev de forferdet, 
44:17:9: og efterat de hadde latt Jason og de andre innestå for dem, lot de dem fare. 
44:17:10: Men straks samme natt sendte brødrene både Paulus og Silas avsted til Berøa. Da de kom dit, gikk de bort i jødenes synagoge. 
44:17:11: Men disse var av et edlere sinn enn de i Tessalonika; de tok imot ordet med all godvilje, og gransket daglig i skriftene om det var således som det blev sagt dem. 
44:17:12: Mange av disse kom da til troen, og ikke få av de fornemme greske kvinner og menn. 
44:17:13: Men da jødene i Tessalonika fikk vite at Guds ord blev forkynt av Paulus også i Berøa, kom de dit og opegget folket der også. 
44:17:14: Straks sendte da brødrene Paulus avsted på veien til havet; men både Silas og Timoteus blev tilbake der. 
44:17:15: De som fulgte med Paulus, førte ham da til Aten, og drog så derfra med det bud til Silas og Timoteus at de skulde komme til ham så snart som mulig. 
44:17:16: Mens nu Paulus ventet på dem i Aten, harmedes hans ånd i ham, da han så at byen var full av avgudsbilleder. 
44:17:17: Han holdt da samtaler i synagogen med jødene og dem som dyrket Gud, og på torvet hver dag med dem han traff på. 
44:17:18: Nogen av de epikureiske og stoiske visdomslærere innlot sig også i ordskifte med ham, og nogen sa: Hvad mener vel denne ordgyder? Andre igjen sa: Han synes å være en som forkynner utenlandske guddommer det var fordi han forkynte evangeliet om Jesus og opstandelsen. 
44:17:19: Så tok de ham ved hånden og førte ham op på Areopagus, og sa: Kan vi få vite hvad dette er for en ny lære som du forkynner? 
44:17:20: for underlige ting fører du frem for våre ører; vi ønsker derfor å få vite hvad dette skal bety. 
44:17:21: Men alle atenere og de fremmede som opholdt sig der, gav sig ikke stunder til annet enn å fortelle eller høre nytt. 
44:17:22: Så stod da Paulus frem midt på Areopagus og sa: Atenske menn! I alle måter ser jeg at I er ivrige i eders gudsfrykt. 
44:17:23: For da jeg gikk omkring og så på eders helligdommer, fant jeg også et alter som det var satt den innskrift på: For en ukjent Gud. Det som I altså dyrker uten å kjenne det, dette forkynner jeg eder. 
44:17:24: Gud, han som gjorde verden og alt som i den er, han som er herre over himmel og jord, han bor ikke i templer gjort med hender; 
44:17:25: heller ikke tjenes han av menneskelige hender som om han trengte til noget, han som jo selv gir alle liv og ånde og alle ting; 
44:17:26: og han lot alle folkeslag av ett blod bo over hele jorderike, og satte dem faste tider og grenseskjell mellem deres bosteder, 
44:17:27: forat de skulde lete efter Gud, om de dog kunde føle og finne ham, enda han ikke er langt borte fra nogen eneste av oss. 
44:17:28: For i ham er det vi lever og rører oss og er til, som også nogen av eders skalder har sagt: For vi er også hans ætt. 
44:17:29: Da vi nu altså er Guds ætt, bør vi ikke tro at guddommen er lik gull eller sølv eller sten, et billedverk av menneskelig kunst og tanke. 
44:17:30: Efterat Gud da har båret over med vankundighetens tider, byder han nu menneskene at de alle allesteds skal omvende sig, 
44:17:31: eftersom han har fastsatt en dag da han skal dømme verden med rettferdighet ved en mann som han har bestemt til det, efterat han har gitt fullgodt bevis for alle ved å opreise ham fra de døde. 
44:17:32: Da de hørte om dødes opstandelse, spottet nogen, men andre sa: Vi vil høre dig ennu en gang om dette. 
44:17:33: Dermed gikk da Paulus bort fra dem. 
44:17:34: Men nogen menn holdt sig til ham og kom til troen, iblandt dem også Dionysius, en av Areopagus-dommerne, og en kvinne ved navn Damaris, og nogen andre med dem. 
44:18:1: Derefter drog han fra Aten og kom til Korint. 
44:18:2: Der fant han en jøde ved navn Akvilas, født i Pontus, som nylig var kommet fra Italia med sin hustru Priskilla, fordi Klaudius hadde påbudt at alle jøder skulde forlate Rom; dem gav han sig i lag med, 
44:18:3: og da han drev samme håndverk, bodde han hos dem og arbeidet der; for de var teltmakere av håndverk. 
44:18:4: Men hver sabbat holdt han samtaler i synagogen, og han overbeviste jøder og grekere. 
44:18:5: Da nu Silas og Timoteus kom ned fra Makedonia, var Paulus helt optatt av å lære, idet han vidnet for jødene at Jesus er Messias. 
44:18:6: Men da de stod imot og spottet, rystet han støvet av sine klær og sa til dem: Eders blod komme over eders eget hode! Jeg er ren; fra nu av går jeg til hedningene. 
44:18:7: Så gikk han bort derfra, og tok inn i et hus som tilhørte en mann ved navn Justus, som dyrket Gud; hans hus lå like ved synagogen. 
44:18:8: Men synagoge-forstanderen Krispus kom til troen på Herren med hele sitt hus, og mange av korintierne som hørte ham, kom til troen og lot sig døpe. 
44:18:9: Og Herren sa til Paulus i et syn om natten: Frykt ikke, men tal, og ti ikke! 
44:18:10: Jeg er med dig, og ingen skal røre dig for å gjøre dig ondt; for jeg har meget folk i denne by. 
44:18:11: Så tok han ophold der i et år og seks måneder, og lærte Guds ord iblandt dem. 
44:18:12: Men da Gallio var landshøvding i Akaia, reiste jødene sig alle som en mot Paulus og førte ham for domstolen og sa: 
44:18:13: Denne opvigler folk til å dyrke Gud på annen vis enn loven byder. 
44:18:14: Da nu Paulus skulde til å oplate munnen, sa Gallio til jødene: Var det nogen misgjerning eller slem udåd, I jøder, da hadde jeg hatt god grunn til å høre tålmodig på eder; 
44:18:15: men er det spørsmål om en lære og om navn og om eders lov, da får det bli eders sak; dommer i disse ting vil jeg ikke være. 
44:18:16: Og han drev dem bort fra domstolen. 
44:18:17: Men da tok de alle fatt på synagoge-forstanderen Sostenes og slo ham midt for domstolen; og Gallio brydde sig ikke noget om alt dette. 
44:18:18: Paulus blev der da ennu en lang tid; så sa han farvel til brødrene og seilte avsted til Syria og i følge med ham Priskilla og Akvilas efterat han i Kenkreæ hadde klippet sitt hår; for han hadde et løfte på sig. 
44:18:19: Så kom de til Efesus; der lot han de andre bli tilbake, og selv gikk han inn i synagogen og gav sig i samtale med jødene. 
44:18:20: Da de bad ham bli der lenger, samtykte han ikke, 
44:18:21: men sa farvel til dem og sa: Jeg vil komme til eder en annen gang, om Gud så vil. Så seilte han fra Efesus 
44:18:22: og kom til Cesarea; derefter drog han op og hilste på menigheten, og drog så ned til Antiokia. 
44:18:23: Da han hadde vært der nogen tid, drog han ut, og vandret gjennem det galatiske land og Frygia fra ende til annen og styrket alle disiplene. 
44:18:24: Men det var en jøde ved navn Apollos, født i Aleksandria, en vel talende mann, som kom til Efesus; han var sterk i skriftene. 
44:18:25: Han var oplært i Herrens vei, og da han var brennende i ånden, talte og lærte han grundig om Jesus, enda han bare kjente Johannes' dåp. 
44:18:26: Og han begynte å tale frimodig i synagogen. Da Priskilla og Akvilas hadde hørt ham, tok de ham til sig og la Guds vei nøiere ut for ham. 
44:18:27: Da han nu vilde dra videre til Akaia, tilskyndte brødrene ham, og skrev til disiplene der om å ta imot ham. Og da han kom dit, blev han ved Guds nåde til stort gagn for de troende; 
44:18:28: for med kraft målbandt han jødene offentlig, idet han viste av skriftene at Jesus er Messias. 
44:19:1: Mens Apollos var i Korint, skjedde det at Paulus kom til Efesus, efterat han hadde vandret igjennem de øvre landskaper. 
44:19:2: Der fant han nogen disipler og sa til dem: Fikk I den Hellige Ånd da I kom til troen? De svarte ham: Vi har ikke engang hørt om den Hellige Ånd er kommet. 
44:19:3: Han sa da: Hvad dåp blev I da døpt med? De sa: Med Johannes' dåp. 
44:19:4: Da sa Paulus: Johannes, han døpte med omvendelses dåp, idet han sa til folket at de skulde tro på den som kom efter ham, det er på Jesus. 
44:19:5: Da de hørte dette, lot de sig døpe til den Herre Jesu navn, 
44:19:6: og da Paulus la hendene på dem, kom den Hellige Ånd over dem, og de talte med tunger og profetiske ord. 
44:19:7: Disse menn var i alt omkring tolv. 
44:19:8: Han gikk da inn i synagogen og talte frimodig i tre måneder, idet han holdt samtaler med dem og overtydet dem om det som hører Guds rike til. 
44:19:9: Men da nogen forherdet sig og ikke vilde tro, og talte ille om Guds vei så mengden hørte på det, da brøt han lag med dem og skilte disiplene fra dem, og holdt daglige samtaler i Tyrannus' skole. 
44:19:10: Dette varte i to år, så alle som bodde i Asia, fikk høre Herrens ord, både jøder og grekere. 
44:19:11: Og usedvanlige kraftgjerninger gjorde Gud ved Paulus' hender, 
44:19:12: så at de endog tok svededuker eller forklær som han hadde hatt på sig, og bar til de syke, og sykdommene vek fra dem, og de onde ånder fór ut av dem. 
44:19:13: Men også nogen av de jødiske ånde-manere som fór omkring, tok sig fore å nevne den Herre Jesu navn over dem som hadde onde ånder, og sa: Jeg maner eder ved den Jesus som Paulus forkynner. 
44:19:14: Det var nogen sønner av Skevas, en jødisk yppersteprest, syv i tallet, som gjorde dette. 
44:19:15: Men den onde ånd svarte dem: Jesus kjenner jeg, og Paulus vet jeg om; men I, hvem er I? 
44:19:16: Og mannen som den onde ånd var i, sprang inn på dem og vant på dem begge to og overmannet dem, så at de måtte fly nakne og sårede ut av huset. 
44:19:17: Dette blev da vitterlig for alle dem som bodde i Efesus, både jøder og grekere, og det falt frykt over dem alle, og den Herre Jesu navn blev prist, 
44:19:18: og mange av dem som var kommet til troen, kom og bekjente og fortalte om sine gjerninger. 
44:19:19: Og en stor hop av dem som hadde drevet med utillatelige kunster, bar sine bøker sammen og brente dem op for alles øine; og de regnet ut deres verdi og fant at den var femti tusen sølvpenninger. 
44:19:20: Således hadde Herrens ord sterk fremgang og fikk makt. 
44:19:21: Da nu dette var fullført, satte Paulus sig fore å reise gjennem Makedonia og Akaia og dra til Jerusalem, idet han sa: Når jeg har vært der, må jeg også se Rom. 
44:19:22: Han sendte da to av dem som gikk ham til hånde, Timoteus og Erastus, til Makedonia; selv blev han ennu nogen tid i Asia. 
44:19:23: Men ved denne tid blev det et ikke lite opstyr om Guds vei. 
44:19:24: For en mann ved navn Demetrius, en sølvsmed, som arbeidet Diana-templer av sølv, og som hjalp kunstnerne til en ikke liten inntekt, 
44:19:25: sammenkalte disse og de arbeidere som syslet med dette, og sa til dem: I menn! I vet at av dette arbeid har vi vår velstand, 
44:19:26: og I ser og hører at ikke bare i Efesus, men i næsten hele Asia har denne Paulus ved sine overtalelser forført en stor hop, idet han sier at de ikke er guder de som arbeides med hendene. 
44:19:27: Nu er det ikke bare den fare for oss at denne håndtering skal komme i vanrykte, men også at den store gudinne Dianas tempel skal bli regnet for intet, og at det skal bli gjort ende på hennes storhet, som hele Asia, ja hele jorderike dyrker. 
44:19:28: Da de hørte dette, blev de fulle av vrede og ropte: Stor er efesernes Diana! 
44:19:29: Og hele byen kom i oprør, og de stormet alle som en avsted til teatret og rev med sig Gajus og Aristarkus, to makedoniere, Paulus' reisefeller. 
44:19:30: Paulus vilde nu gå der inn til folket, men disiplene tillot ham det ikke; 
44:19:31: også nogen av asiarkene, som var hans venner, sendte bud til ham og bad ham at han ikke måtte gå inn i teatret. 
44:19:32: Nogen skrek nu ett, andre et annet; for forsamlingen var i et røre, og størstedelen visste ikke hvad de var kommet sammen for. 
44:19:33: Men nogen av hopen satte Aleksander inn i saken, da jødene skjøv ham frem; Aleksander slo da til lyd med hånden og vilde holde en forsvarstale til folket. 
44:19:34: Men da de fikk vite at han var jøde, blev det ett rop fra dem alle, og de skrek i omkring to timer: Stor er efesernes Diana! 
44:19:35: Men by-skriveren fikk hopen til å være rolig, og sa derefter: I menn fra Efesus! Hvilket menneske er det vel som ikke vet at efesernes by er tempelvokter for den store Diana og hennes himmelfalne billede? 
44:19:36: Da nu ingen motsier dette, må I holde eder rolige og ikke foreta eder noget forhastet. 
44:19:37: For I har ført hit disse mennesker, som hverken er tempelranere eller spotter eders gudinne. 
44:19:38: Har nu altså Demetrius og kunstnerne med ham nogen klage mot nogen, da holdes det jo rettergangsdager, og her er jo landshøvdinger; la dem kalle hverandre inn for retten! 
44:19:39: Men har I noget å kreve i andre saker, da skal det bli avgjort i lovlig tingmøte. 
44:19:40: For vi står endog i fare for å komme under tiltale som oprørere for denne dags skyld, da det ikke er nogen grunn som vi kan svare med for dette opløp.\
44:19:40: Ved å tale så fikk han forsamlingen til å gå sin vei. 
44:20:1: Da nu opstyret hadde lagt sig, kalte Paulus disiplene til sig og for mante dem og bød dem farvel; så drog han avsted for å fare til Makedonia. 
44:20:2: Efterat han nu hadde reist gjennem disse landskaper og hadde formant dem med mange ord, kom han til Grekenland; 
44:20:3: der blev han i tre måneder, og fordi jødene lurte på ham da han var i ferd med å seile til Syria, satte han sig fore å vende tilbake gjennem Makedonia. 
44:20:4: Han hadde da følge til Asia av Sopater, Pyrrus' sønn, fra Berøa, og blandt tessalonikerne av Aristarkus og Sekundus, og av Gajus fra Derbe og Timoteus, og av Tykikus og Trofimus fra Asia. 
44:20:5: Disse drog i forveien og bidde på oss i Troas; 
44:20:6: men vi seilte ut fra Filippi efter de usyrede brøds dager, og kom fem dager efter til dem i Troas; der blev vi i syv dager. 
44:20:7: På den første dag i uken var vi samlet for å bryte brødet; Paulus holdt da samtaler med dem, for han skulde fare derfra den næste dag, og han drog sin tale ut like til midt på natten. 
44:20:8: Det var mange lamper i salen hvor vi var samlet. 
44:20:9: Men en ung mann ved navn Eutykus satt i vinduet og var falt i dyp søvn, da Paulus talte med dem i lang tid, og i søvne styrtet han ut og falt ned fra tredje stokkverk, Og blev tatt død op. 
44:20:10: Men Paulus gikk ned og kastet sig over ham og favnet ham og sa: Vær ikke urolige! hans sjel er i ham. 
44:20:11: Så gikk han op igjen og brøt brødet og åt av det; og efterat han hadde talt lenge med dem, like til daggry, drog han avsted. 
44:20:12: De førte da gutten levende bort og blev høilig trøstet. 
44:20:13: Vi hadde imens gått ombord i skibet og seilte til Assus, hvor vi skulde ta Paulus ombord; for det hadde han pålagt oss; selv vilde han gå til fots. 
44:20:14: Da han nu møtte oss i Assus, tok vi ham ombord, og kom til Mitylene, 
44:20:15: Og derfra seilte vi videre og kom den næste dag bent ut for Kios; annen dag gikk vi innom Samos og lå over i Trogillium, og kom dagen efter til Milet. 
44:20:16: For Paulus hadde satt sig fore å seile Efesus forbi, så han ikke skulde bli heftet i Asia; for han hastet for å nå frem til Jerusalem til pinsedag, om det var ham mulig. 
44:20:17: Fra Milet sendte han da bud til Efesus og kalte menighetens eldste til sig. 
44:20:18: Da de var kommet til ham, sa han til dem: I vet hvordan min ferd iblandt eder var hele tiden fra den første dag jeg kom til Asia, 
44:20:19: at jeg tjente Herren med all ydmykhet og under tårer og prøvelser som jeg måtte utstå ved jødenes efterstrebelser; 
44:20:20: hvorledes jeg ikke holdt tilbake noget av det som kunde være eder til gagn, men forkynte eder det og lærte eder det offentlig og i husene, 
44:20:21: idet jeg vidnet både for jøder og for grekere om omvendelsen til Gud og troen på vår Herre Jesus Kristus. 
44:20:22: Og nu se, bundet av Ånden drar jeg til Jerusalem, og vet ikke hvad som skal møte mig der, 
44:20:23: men bare at den Hellige Ånd i by efter by vidner for mig og sier at bånd og trengsler venter mig. 
44:20:24: Men for mig selv akter jeg ikke mitt liv et ord verdt, når jeg bare kan fullende mitt løp og den tjeneste som jeg fikk av den Herre Jesus: å vidne om Guds nådes evangelium. 
44:20:25: Og nu, se, jeg vet at I aldri mere skal se mitt åsyn, alle I som jeg gikk omkring iblandt og forkynte riket. 
44:20:26: Derfor vidner jeg for eder på denne dag at jeg er ren for alles blod; 
44:20:27: for jeg holdt ikke noget tilbake, men forkynte eder hele Guds råd. 
44:20:28: Så gi da akt på eder selv, og på hele den hjord som den Hellige Ånd satte eder til tilsynsmenn i, for å vokte Guds menighet, som han vant sig ved sitt eget blod! 
44:20:29: Jeg vet at efter min bortgang skal det komme glupende ulver inn blandt eder, som ikke skåner hjorden; 
44:20:30: ja, blandt eder selv skal det fremstå menn som fører forvendt tale for å lokke disiplene efter sig. 
44:20:31: Våk derfor, og kom i hu at jeg i tre år ikke holdt op, hverken natt eller dag, å formane hver eneste en med tårer! 
44:20:32: Og nu overgir jeg eder til Gud og hans nådes ord, han som er mektig til å opbygge eder og gi eder arvelodd blandt alle dem som er blitt helliget. 
44:20:33: Jeg attrådde ikke sølv eller gull eller klær av nogen; 
44:20:34: I vet selv at det jeg selv trengte, og de som var med mig, det har disse hender arbeidet for. 
44:20:35: I alle deler viste jeg eder at således bør vi ved strevsomt arbeid ta oss av de skrøpelige og komme den Herre Jesu ord i hu, som han selv har sagt: Det er saligere å gi enn å ta. 
44:20:36: Da han hadde sagt dette, falt han på kne og bad sammen med dem alle. 
44:20:37: De brast da alle i sterk gråt, og de falt Paulus om halsen og kysset ham; 
44:20:38: men mest sørget de over det ord han hadde sagt, at de aldri mere skulde se hans åsyn. Derefter fulgte de ham til skibet. 
44:21:1: Da vi nu hadde revet oss løs fra dem og hadde seilt avsted, kom vi rett frem til Kos, næste dag til Rodus, og derfra til Patara. 
44:21:2: Der fant vi et skib som skulde fare rett over til Fønikia; vi gikk da ombord på det og seilte avsted. 
44:21:3: Efterat vi hadde fått Kypern i sikte og latt den efter oss på venstre hånd, seilte vi til Syria og løp inn til Tyrus; for der skulde skibet losse sin ladning. 
44:21:4: Vi opsøkte da disiplene og blev der i syv dager; de sa til Paulus ved Ånden at han ikke måtte dra til Jerusalem. 
44:21:5: Da nu disse dager var til ende, drog vi derfra og fór videre, og de fulgte oss alle med kvinner og barn helt utenfor byen; vi falt da på kne på stranden og bad; 
44:21:6: så bød vi hverandre farvel, og vi gikk ombord på skibet; men de vendte tilbake til sitt. 
44:21:7: Vi endte nu sjøferden og kom fra Tyrus til Ptolemais; der hilste vi på brødrene og blev en dag hos dem. 
44:21:8: Næste dag drog vi derfra og kom til Cesarea; der tok vi inn hos Filip, evangelisten, som var en av de syv, og blev hos ham. 
44:21:9: Han hadde fire ugifte døtre som hadde profetisk gave. 
44:21:10: Mens vi nu blev der i flere dager, kom en profet ved navn Agabus ned fra Judea; 
44:21:11: han kom til oss og tok Paulus' belte og bandt sig selv på hender og føtter og sa: Så sier den Hellige Ånd: Den mann som eier dette belte, ham skal jødene binde således i Jerusalem og overgi i hedningenes hender. 
44:21:12: Da vi hørte dette, bad vi ham, både vi og de der på stedet, at han ikke måtte dra op til Jerusalem. 
44:21:13: Men Paulus svarte: Hvorfor gråter I og sønderriver mitt hjerte? Jeg er rede ikke bare til å bindes, men også til å dø i Jerusalem for den Herre Jesu navn. 
44:21:14: Da han nu ikke lot sig overtale, slo vi oss til ro og sa: Skje Herrens vilje! 
44:21:15: Da nu disse dager var til ende, gjorde vi oss i stand og drog op til Jerusalem, 
44:21:16: og sammen med oss reiste også nogen av disiplene fra Cesarea; de førte oss til en som hette Mnason, fra Kypern, en gammel disippel, som vi skulde bo hos. 
44:21:17: Da vi nu kom til Jerusalem, tok brødrene imot oss med glede. 
44:21:18: Den næste dag gikk Paulus med oss til Jakob, og alle de eldste kom dit. 
44:21:19: Og da han hadde hilst på dem, fortalte han det som Gud hadde gjort blandt hedningene ved hans tjeneste, det ene efter det annet. 
44:21:20: Da de hørte det, priste de Gud; og så sa de til ham: Du ser, bror, hvor mangfoldige tusener det er blandt jødene som har tatt ved troen, og alle er de nidkjære for loven; 
44:21:21: men nu har de hørt si om dig at ute blandt hedningene lærer du alle jøder frafall fra Moses og sier at de ikke skal omskjære sine barn eller vandre efter de gamle skikker. 
44:21:22: Hvad er da å gjøre? I ethvert fall vil en hel mengde komme sammen; for de vil få høre at du er kommet. 
44:21:23: Gjør nu derfor som vi sier til dig! Vi har her fire menn som har et løfte på sig; 
44:21:24: slå dig sammen med dem og la dig rense med dem, og ta på dig omkostningene for dem, så de kan få rake sitt hode! så vil alle skjønne at det ikke er noget i det som de har hørt si om dig, men at du også selv vandrer så at du holder loven. 
44:21:25: Men om de hedninger som har tatt ved troen, har vi sendt brev og vedtatt at de ikke skal holde noget sådant, men bare vokte sig for avgudsoffer og blod og det som er kvalt, og hor. 
44:21:26: Da slo Paulus sig sammen med mennene, og den næste dag lot han sig rense med dem og gikk inn i templet for å melde utløpet av renselsesdagene, da offeret skulde frembæres for enhver av dem. 
44:21:27: Da nu de syv dager led til ende, fikk jødene fra Asia se ham i templet, og de satte op hele hopen og la hånd på ham 
44:21:28: og skrek: Israelittiske menn! kom til hjelp! Dette er det menneske som allesteds lærer alle imot folket og loven og dette sted, og han har endog ført grekere inn i templet og vanhelliget dette hellige sted. 
44:21:29: For de hadde før sett Trofimus fra Efesus ute i byen i følge med ham, og nu tenkte de at Paulus hadde ført ham inn i templet. 
44:21:30: Det blev da et opstyr i hele byen, og folket flokket sig sammen, og de grep Paulus og slepte ham utenfor templet, og straks blev dets dører lukket. 
44:21:31: Mens de nu holdt på og vilde slå ham ihjel, gikk det melding op til den øverste høvedsmann for vakten om at hele Jerusalem var i et røre. 
44:21:32: Han tok da straks krigsfolk og høvedsmenn og skyndte sig ned til dem; men da de så den øverste høvedsmann og krigsfolket, holdt de op med å slå Paulus. 
44:21:33: Da kom den øverste høvedsmann bort til ham og lot ham gripe, og bød at han skulde bindes med to lenker, og spurte hvem han var, og hvad han hadde gjort. 
44:21:34: Nogen i hopen ropte da ett, andre et annet; og da han på grunn av larmen ikke kunde få noget sikkert å vite, bød han at han skulde føres inn i festningen. 
44:21:35: Da han nu kom til trappene, blev det slik at han måtte bæres av krigsfolket, så voldsom var hopen; 
44:21:36: for hele mengden av folket fulgte efter og skrek: Drep ham! 
44:21:37: Da nu Paulus skulde føres inn i festningen, sier han til den øverste høvedsmann: Får jeg lov å si et ord til dig? Han svarte: Kan du gresk? 
44:21:38: Er du da ikke den egypter som for nogen tid siden gjorde oprør og førte de fire tusen mordere ut i ørkenen? 
44:21:39: Da sa Paulus: Jeg er en jøde, fra Tarsus, borger av en ikke ukjent by i Kilikia; men jeg ber dig: Gi mig lov å tale til folket! 
44:21:40: Da han gav ham lov til det, stilte Paulus sig på trappen og slo til lyd for folket med hånden; da det nu blev aldeles stille, talte han til dem på det hebraiske mål og sa: 
44:22:1: Brødre og fedre! Hør på det jeg nu vil si eder til mitt forsvar! 
44:22:2: Da de hørte at han talte til dem på det hebraiske mål, holdt de sig ennu mere stille. Han sier da: 
44:22:3: Jeg er en jøde, født i Tarsus i Kilikia, men opfostret i denne by, oplært ved Gamaliels føtter efter vår fedrene lovs strenghet, og jeg var nidkjær for Gud, som I alle er idag; 
44:22:4: jeg forfulgte Guds vei til døden, bandt og kastet i fengsel både menn og kvinner, 
44:22:5: som også ypperstepresten og hele eldste-rådet kan vidne; av dem fikk jeg endog brev med til brødrene i Damaskus, og drog dit for å føre også dem som var der, bundne til Jerusalem, forat de kunde få straff. 
44:22:6: Men det skjedde da jeg var på veien og kom nær til Damaskus, da strålte ved middags-tider et sterkt lys fra himmelen med ett omkring mig, 
44:22:7: og jeg falt til jorden og hørte en røst som sa til mig: Saul! Saul! hvorfor forfølger du mig? 
44:22:8: Jeg svarte: Hvem er du, Herre? Og han sa til mig: Jeg er Jesus fra Nasaret, han som du forfølger. 
44:22:9: De som var med mig, så schmelken, men røsten av ham som talte til mig, hørte de ikke. 
44:22:10: Jeg sa da: Hvad skal jeg gjøre, Herre? Og Herren svarte mig: Stå op og gå inn i Damaskus! der skal bli talt til dig om alt det som du er bestemt til å gjøre. 
44:22:11: Da jeg nu ikke kunde se for glansen av hint lys, blev jeg ledet ved hånden av dem som var med mig, og kom inn i Damaskus. 
44:22:12: Og en som hette Ananias, en gudfryktig mann efter loven, med godt vidnesbyrd av alle jøder som bodde der, 
44:22:13: kom til mig og stod for mig og sa: Saul, bror, se op! Og samme stund så jeg op på ham. 
44:22:14: Og han sa: Våre fedres Gud har utkåret dig til å kjenne hans vilje og se den rettferdige og høre røsten av hans munn; 
44:22:15: for du skal være ham et vidne for alle mennesker om det du har sett og hørt. 
44:22:16: Og nu, hvad bier du efter? stå op og la dig døpe og få avtvettet dine synder, idet du påkaller hans navn. 
44:22:17: Da jeg så var vendt tilbake til Jerusalem og bad i templet, hendte det mig at jeg kom i en henrykkelse, 
44:22:18: og jeg så ham, og jeg hørte ham si til mig: Skynd dig og gå i hast ut av Jerusalem! for de kommer ikke til å ta imot ditt vidnesbyrd om mig. 
44:22:19: Da sa jeg: Herre! de vet selv at jeg kastet i fengsel og hudstrøk rundt om i synagogene dem som trodde på dig, 
44:22:20: og da blodet av Stefanus, ditt vidne, blev utgytt, stod jeg også hos og samtykte i det og tok vare på klærne til dem som slo ham ihjel. 
44:22:21: Og han sa til mig: Dra ut! for jeg vil sende dig ut til hedningefolk langt borte. 
44:22:22: Inntil dette ord hørte de på ham; men da løftet de sin røst og sa: Ta ham bort fra jorden! han burde ikke få leve! 
44:22:23: Da de nu skrek og rev klærne av sig og kastet støv op i luften, 
44:22:24: bød den øverste høvedsmann at han skulde føres inn i festningen, og sa at han skulde forhøres under hudstrykning, forat han kunde få vite av hvad årsak de ropte så mot ham. 
44:22:25: Men da de nu hadde bundet ham for å hudstryke ham, sa Paulus til høvedsmannen, som stod hos: Har I lov til å hudstryke en romersk borger og det uten dom? 
44:22:26: Da høvedsmannen hørte dette, gikk han til den øverste høvedsmann og meldte det, og sa: Hvad er det du er i ferd med å gjøre? dette menneske er jo romersk borger. 
44:22:27: Den øverste høvedsmann gikk da bort til ham og sa: Si mig: Er du romersk borger? Han svarte: Ja. 
44:22:28: Den øverste høvedsmann sa: Jeg har kjøpt denne borgerrett for mange penger. Men Paulus svarte: Men jeg er endog født til den. 
44:22:29: Da gikk de straks fra ham de som skulde ha forhørt ham. Men da den øverste høvedsmann fikk vite at han var romersk borger, blev også han redd, fordi han hadde bundet ham. 
44:22:30: Men da han næste dag vilde ha visshet om hvad jødene hadde å klage på ham, løste han ham, og bød at yppersteprestene og hele rådet skulde komme sammen. Og han førte Paulus ned og stilte ham frem for dem. 
44:23:1: Da så Paulus fast på rådet og sa: Brødre! med all god samvittighet har jeg ført mitt levnet for Gud inntil denne dag. 
44:23:2: Ypperstepresten Ananias bød da dem som stod der, å slå ham på munnen. 
44:23:3: Da sa Paulus til ham: Gud skal slå dig, du kalkede vegg! Og du sitter her for å dømme mig efter loven, og du bryter loven ved å byde at de skal slå mig? 
44:23:4: De som stod der, sa da: Skjeller du ut Guds yppersteprest? 
44:23:5: Og Paulus sa: Jeg visste ikke, brødre, at det var ypperstepresten; det står jo skrevet: Mot en høvding blandt ditt folk skal du ikke bruke ukvemsord. 
44:23:6: Da nu Paulus visste at den ene del av dem var sadduseere og den andre del fariseere, ropte han i rådet: Brødre! jeg er en fariseer, sønn av fariseere; det er for håp og for de dødes opstandelse at jeg står her for retten. 
44:23:7: Da han sa dette, blev det strid mellem fariseerne og sadduseerne, og hopen blev innbyrdes uenig. 
44:23:8: For sadduseerne sier at det ikke er nogen opstandelse, heller ikke nogen engel eller ånd; men fariseerne lærer begge deler. 
44:23:9: Nu blev det da et stort skrik, og nogen skriftlærde av fariseernes flokk stod op og stred skarpt og sa: Vi finner intet ondt hos dette menneske; om det nu var en ånd som talte til ham, eller en engel? 
44:23:10: Da det nu opstod stor strid, fryktet den øverste høvedsmann for at Paulus skulde bli slitt i stykker av dem, og han bød krigsfolket å gå ned og rive ham ut fra dem og føre ham inn i festningen. 
44:23:11: Men natten efter stod Herren for ham og sa: Vær frimodig! likesom du vidnet om mig i Jerusalem, således skal du også vidne i Rom. 
44:23:12: Men da det var blitt dag, la jødene sig sammen mot ham, og forbannet sig på at de hverken vilde ete eller drikke før de hadde drept Paulus. 
44:23:13: Det var mere enn firti som således sammensvor sig, 
44:23:14: og de gikk til yppersteprestene og de eldste og sa: Vi har forbannet oss på at vi ikke vil smake mat før vi har drept Paulus; 
44:23:15: nu må da I sammen med rådet la den øverste høvedsmann vite at han skal føre ham ned til eder, som om I nøiere vilde prøve hans sak; så holder vi oss ferdige til å drepe ham før han når frem. 
44:23:16: Men Paulus' søstersønn fikk høre om dette hemmelige råd, og han kom og gikk inn i festningen og fortalte Paulus det. 
44:23:17: Paulus kalte da en av høvedsmennene til sig og sa: Før denne unge mann til den øverste høvedsmann! for han har noget å melde ham. 
44:23:18: Han tok ham da med sig og førte ham til den øverste høvedsmann og sier: Fangen Paulus kalte mig til sig og bad mig føre denne unge mann til dig, da han hadde noget å si dig. 
44:23:19: Den øverste høvedsmann tok ham da ved hånden og gikk til side og spurte: Hvad er det du har å melde mig? 
44:23:20: Han sa da: Jødene er kommet overens om å be dig at du imorgen vil føre Paulus for rådet, som om det vilde få nøiere rede på hans sak; 
44:23:21: du må da ikke la dig overtale av dem; for mere enn firti menn av dem ligger på lur efter ham, og de har forbannet sig på at de hverken vil ete eller drikke før de har drept ham, og nu holder de sig ferdige og venter på at du skal love det. 
44:23:22: Den øverste høvedsmann lot da den unge mann gå og bød ham: Du må ikke si til nogen at du har latt mig få vite dette. 
44:23:23: Og han kalte til sig to av høvedsmennene og sa: La to hundre stridsmenn holde sig ferdige til å dra til Cesarea, og sytti hestfolk og to hundre skyttere, fra den tredje time på natten! 
44:23:24: Og han bød at de skulde gjøre hester i stand, forat de kunde la Paulus ride på dem og føre ham sikkert frem til landshøvdingen Feliks. 
44:23:25: Og han skrev et brev med dette innhold: 
44:23:26: Klaudius Lysias hilser den mektige landshøvding Feliks. 
44:23:27: Denne mann, som var grepet av jødene og nær ved å bli drept av dem, ham fridde jeg ut; jeg kom til med krigsfolket, efterat jeg hadde fått vite at han var romersk borger; 
44:23:28: og da jeg vilde vite hvad det var de hadde å klage på ham, førte jeg ham ned for deres råd. 
44:23:29: Jeg fant da at de førte klage over ham for nogen spørsmål i deres lov, men at det ikke var noget klagemål imot ham som gjorde ham skyldig til død eller fengsel. 
44:23:30: Men da det nu er blitt meldt mig at de vil gjøre et overfall på mannen, sender jeg ham uten ophold til dig, efterat jeg har pålagt hans anklagere at også de skal fremføre for dig det de har å si. 
44:23:31: Krigsfolket tok nu Paulus, som det var blitt dem pålagt, og førte ham om natten til Antipatris, 
44:23:32: og dagen efter lot de hestfolket dra videre med ham og vendte selv tilbake til festningen. 
44:23:33: De kom da til Cesarea og lot landshøvdingen få brevet, og førte også Paulus frem for ham. 
44:23:34: Da han hadde lest det og spurt hvad land han var fra, og fått vite at han var fra Kilikia, sa han: 
44:23:35: Jeg skal ta dig i forhør når dine anklagere kommer. Og han bød at han skulde holdes i varetekt i Herodes' borg. 
44:24:1: Fem dager efter kom ypperstepresten Ananias der ned med nogen av de eldste og en taler, Tertullus; disse fremførte sin klage imot Paulus for landshøvdingen. 
44:24:2: Han blev da kalt frem, og Tertullus begynte på klagen imot ham og sa: 
44:24:3: Da vi har dig å takke for megen fred, og da ved din omsorg forbedringer i alle måter og på alle steder blir dette folk til del, så skjønner vi på dette, mektigste Feliks, med all takksigelse. 
44:24:4: Men forat jeg ikke skal hefte dig for lenge, ber jeg dig at du efter din mildhet vil høre på nogen få ord av oss. 
44:24:5: For vi har funnet at denne mann er en pest og en opvigler blandt alle jøder rundt om i verden og en leder for nasareernes sekt; 
44:24:6: han har endog søkt å vanhellige templet. Vi grep ham derfor og vilde dømme ham efter vår lov; 
44:24:7: men Lysias, den øverste høvedsmann, kom til og tok ham med makt og vold ut av våre hender, 
44:24:8: og han bød hans anklagere å komme til dig; og av ham kan du selv, om du gransker saken, få kunnskap om alt det som vi fører klagemål imot ham for. 
44:24:9: Men også jødene holdt med ham og sa at så var det. 
44:24:10: Men Paulus svarte, da landshøvdingen gav ham et tegn at han kunde tale: Da jeg vet at du i mange år har vært dommer for dette folk, fører jeg frimodig mitt forsvar, 
44:24:11: eftersom du kan få visshet for at det ikke er mere enn tolv dager siden jeg drog op til Jerusalem for å tilbede. 
44:24:12: Og hverken i templet eller i synagogene eller omkring i byen har de funnet at jeg talte til nogen eller opviglet folket; 
44:24:13: heller ikke kan de godtgjøre for dig disse sine klager imot mig. 
44:24:14: Men det vedgår jeg for dig at efter den Guds vei som de kaller en sekt-lære, tjener jeg så mine fedres Gud at jeg tror alt det som er skrevet i loven og i profetene, 
44:24:15: og har det håp til Gud, som også disse selv venter på, at en opstandelse forestår både av rettferdige og av urettferdige. 
44:24:16: Derfor legger jeg selv vinn på alltid å ha en uskadd samvittighet for Gud og mennesker. 
44:24:17: Da nu flere år var gått, kom jeg for å føre milde gaver til mitt folk og for å ofre, 
44:24:18: og under dette fant de mig i templet, efterat jeg hadde renset mig, uten opløp og uten opstyr; men det var nogen jøder fra Asia, 
44:24:19: og disse burde trede frem for dig og komme med sin klage om de hadde noget å si på mig. 
44:24:20: Eller la disse selv si hvad skyld de fant hos mig dengang jeg stod for rådet, 
44:24:21: uten det skulde være for dette ene ord som jeg ropte da jeg stod iblandt dem: For de dødes opstandelse står jeg idag for retten iblandt eder. 
44:24:22: Men Feliks utsatte saken, da han hadde nøiere kjennskap til Guds vei, og han sa: Når høvedsmannen Lysias kommer her ned, skal jeg prøve eders sak. 
44:24:23: Og han bød høvedsmannen at han skulde holdes i varetekt og ha ro, og at ingen av hans egne skulde hindres fra å være ham til tjeneste. 
44:24:24: Da nogen dager var gått, kom Feliks med sin hustru Drusilla, som var en jødinne, og han sendte bud efter Paulus og hørte ham om troen på Kristus. 
44:24:25: Men da han talte om rettferd og avhold og den kommende dom, blev Feliks forferdet og sa: Gå bort for denne gang! når jeg får god tid, vil jeg kalle dig til mig igjen. 
44:24:26: Han håpet også på at han skulde få penger av Paulus; derfor sendte han også oftere bud efter ham og talte med ham. 
44:24:27: Da to år var omme, fikk Feliks til eftermann Porcius Festus, og da Feliks gjerne vilde vinne takk av jødene, lot han Paulus bundet efter sig. 
44:25:1: Da nu Festus hadde tiltrådt landshøvding-embedet, drog han tre dager derefter op til Jerusalem fra Cesarea. 
44:25:2: Yppersteprestene og jødenes første menn førte da klage hos ham mot Paulus og kom frem med en bønn til ham; 
44:25:3: med ondt i sinne mot Paulus utbad de sig den nåde at han vilde la ham hente til Jerusalem; for de lå på lur for å slå ham ihjel på veien. 
44:25:4: Men Festus svarte da at Paulus blev holdt i varetekt i Cesarea, og at han selv snart vilde dra dit; 
44:25:5: de altså av eder, sa han, som har myndighet til det, kan jo dra ned med mig, om det er noget i veien med denne mann, og fremføre sin klage imot ham. 
44:25:6: Efterat han nu hadde vært bare åtte eller ti dager hos dem, drog han ned til Cesarea; dagen efter satte han sig på sitt dommersete og bød at Paulus skulde føres frem. 
44:25:7: Da han var kommet, stilte de jøder som var kommet fra Jerusalem, sig rundt omkring ham og førte mange svære klager imot ham; men de var ikke i stand til å bevise dem, 
44:25:8: da Paulus forsvarte sig og sa: Hverken mot jødenes lov eller mot templet eller mot keiseren har jeg syndet i noget stykke. 
44:25:9: Men da Festus gjerne vilde vinne takk av jødene, svarte han Paulus og sa: Er du villig til å dra op til Jerusalem og der få dom av mig i denne sak? 
44:25:10: Da sa Paulus: Jeg står for keiserens domstol, og der er det min rett å dømmes. Mot jødene har jeg ingen urett gjort, som også du godt vet. 
44:25:11: Har jeg urett, og har jeg gjort noget som fortjener døden, da ber jeg mig ikke fri for å dø; men er det ikke noget i det som disse fører klagemål imot mig for, da kan ingen overgi mig til dem bare for å gjøre dem til lags; jeg innanker min sak for keiseren. 
44:25:12: Festus talte da med sitt råd, og sa så: For keiseren har du innanket din sak; til keiseren skal du fare. 
44:25:13: Men da nogen dager var til ende, kom kong Agrippa og Berenike til Cesarea for å hilse på Festus. 
44:25:14: Da de nu hadde vært der i flere dager, fortalte Festus kongen om Paulus og sa: Her er en mann, efterlatt av Feliks som fange; 
44:25:15: da jeg kom til Jerusalem, førte yppersteprestene og jødenes eldste klage imot ham og bad om straff over ham. 
44:25:16: Jeg svarte dem da at det ikke er sedvane hos romerne å utlevere et menneske for å gjøre nogen til lags; men den som klagen gjelder, må først stilles frem for sine anklagere og få adgang til å forsvare sig mot klagemålet. 
44:25:17: Da de så var kommet sammen her, utsatte jeg ikke saken, men satte mig næste dag på dommersetet og bød at mannen skulde føres frem. 
44:25:18: Men da anklagerne stod omkring ham, fremførte de ikke nogen klage for slikt som jeg hadde tenkt; 
44:25:19: men de hadde nogen stridsspørsmål med ham om sin egen gudsdyrkelse og om en ved navn Jesus, som var død, men som Paulus sa var i live. 
44:25:20: Da jeg nu var rådvill og ikke visste hvorledes denne sak burde undersøkes, spurte jeg om han vilde reise til Jerusalem og der få dom i saken. 
44:25:21: Men da Paulus innanket sin sak for å bli holdt i varetekt til keiserens dom, bød jeg at han skulde holdes i varetekt inntil jeg kan sende ham til keiseren. 
44:25:22: Agrippa sa da til Festus: Jeg vilde også gjerne høre denne mann. Han svarte: Imorgen skal du få høre ham. 
44:25:23: Dagen efter kom da Agrippa og Berenike med stor prakt og gikk inn i salen sammen med de øverste høvedsmenn og de gjæveste menn i byen, og på Festus' bud blev Paulus ført frem. 
44:25:24: Festus sier da: Kong Agrippa og alle I menn som her er samlet med oss! Her ser I den om hvem hele jødenes hop søkte mig både i Jerusalem og her, og ropte på at han ikke burde få leve lenger. 
44:25:25: Men da jeg kom efter at han ikke har gjort noget som fortjener døden, og da han også selv innanket sin sak for keiseren, satte jeg mig fore å sende ham dit. 
44:25:26: Noget pålitelig å skrive til min herre om ham har jeg ikke; derfor førte jeg ham frem for eder, og mest for dig, kong Agrippa, forat jeg kan ha noget å skrive når han er blitt forhørt. 
44:25:27: For det tykkes mig meningsløst, når jeg sender en fange, da ikke å gi oplysning om klagene mot ham. 
44:26:1: Agrippa sa da til Paulus: Du har lov til å tale om dig selv. Da strakte Paulus sin hånd ut og sa til sitt forsvar: 
44:26:2: Mot alt det som jøder anklager mig for, kong Agrippa, akter jeg mig lykkelig at jeg idag skal forsvare mig for dig, 
44:26:3: for du kjenner best alle skikker og spørsmål iblandt jødene; derfor ber jeg dig at du vil høre tålmodig på mig. 
44:26:4: Mitt liv fra ungdommen av, som jeg fra første stund har levd iblandt mitt folk og i Jerusalem, kjenner alle jøder, 
44:26:5: da de forut vet om mig, like fra først av, om de bare vil komme frem med sitt vidnesbyrd, at jeg levde efter det strengeste parti av vår gudsdyrkelse, som fariseer. 
44:26:6: Og nu står jeg her og skal dømmes for håp på det løfte som av Gud er gitt fedrene, 
44:26:7: det som vårt tolvstamme-folk under uavlatelig gudstjeneste natt og dag håper å vinne frem til; for dette håp tiltales jeg av jøder, konge! 
44:26:8: Hvorfor aktes det for utrolig iblandt eder at Gud opvekker døde? 
44:26:9: Jeg for min del trodde nu at jeg burde gjøre meget imot Jesu, nasareerens navn; 
44:26:10: det gjorde jeg også i Jerusalem, og både kastet jeg mange av de hellige i fengsel, efterat jeg hadde fått fullmakt av yppersteprestene, og når de skulde slåes ihjel, gav jeg min stemme til det, 
44:26:11: og omkring i alle synagogene tvang jeg dem ofte ved straff til å spotte, og jeg raste enn mere mot dem og forfulgte dem like til de utenlandske byer. 
44:26:12: Under dette drog jeg da også til Damaskus med makt og myndighet fra yppersteprestene; 
44:26:13: og midt på dagen så jeg på veien, konge, et lys fra himmelen, klarere enn solen, stråle omkring mig og dem som reiste med mig, 
44:26:14: og da vi alle falt til jorden, hørte jeg en røst si til mig på det hebraiske mål: Saul! Saul! hvorfor forfølger du mig? det blir hårdt for dig å stampe mot brodden. 
44:26:15: Jeg sa da: Hvem er du, Herre? Og Herren sa: Jeg er Jesus, han som du forfølger. 
44:26:16: Men reis dig op og stå på dine føtter! for derfor åpenbarte jeg mig for dig: for å utkåre dig til tjener og vidne både om det som du har sett, og om det for hvis skyld jeg vil åpenbare mig for dig, 
44:26:17: idet jeg frir dig ut fra folket og hedningene, som jeg sender dig til 
44:26:18: for å oplate deres øine, så de kan vende sig fra mørke til lys og fra Satans makt til Gud, forat de kan få syndenes forlatelse og arvelodd blandt dem som er helliget ved troen på mig. 
44:26:19: Derfor, kong Agrippa, blev jeg ikke ulydig mot det himmelske syn; 
44:26:20: men både for dem i Damaskus først og for Jerusalem og i hele Judeas land og for hedningene forkynte jeg at de skulde omvende sig og komme tilbake til Gud og gjøre gjerninger som er omvendelsen verdige. 
44:26:21: For disse tings skyld grep nogen jøder mig i templet og søkte å slå mig ihjel. 
44:26:22: Så har jeg da fått hjelp fra Gud og står til denne dag og vidner både for liten og for stor, idet jeg ikke sier noget annet enn hvad profetene og Moses har sagt skulde skje: 
44:26:23: at Messias skulde lide, og at han som den første av de dødes opstandelse skulde forkynne lys for folket og for hedningene. 
44:26:24: Men da han forsvarte sig således, sier Festus med høi røst: Du er vanvittig, Paulus! din megen lærdom driver dig til vanvidd. 
44:26:25: Men han sier: Jeg er ikke vanvittig, mektigste Festus! men jeg taler sanne og sindige ord. 
44:26:26: For kongen kjenner til disse ting, og til ham taler jeg også frimodig; for jeg kan ikke tro at noget av dette er ukjent for ham; dette er jo ikke skjedd i en avkrok. 
44:26:27: Tror du, kong Agrippa, profetene? Jeg vet at du tror. 
44:26:28: Men Agrippa sa til Paulus: Det mangler lite i at du overtaler mig til å bli en kristen. 
44:26:29: Paulus sa da: Jeg vilde ønske til Gud, enten det mangler lite eller meget, at ikke alene du, men også alle som hører mig idag, måtte bli slik som jeg er, bare uten disse lenker. 
44:26:30: Da stod kongen op, og landshøvdingen og Berenike og de som satt der med dem, 
44:26:31: og de gikk til side og talte med hverandre og sa: Denne mann gjør ikke noget som fortjener død eller lenker. 
44:26:32: Og Agrippa sa til Festus: Denne mann kunde være løslatt, om han ikke hadde innanket sin sak for keiseren. 
44:27:1: Da det nu var avgjort at vi skulde seile avsted til Italia, overgav de både Paulus og nogen andre fanger til en høvedsmann ved navn Julius ved den keiserlige hærdeling. 
44:27:2: Vi gikk da ombord på et skib fra Adramyttium som skulde seile til stedene langs Asia-landet, og så fór vi ut; Aristarkus, en makedonier fra Tessalonika, var med oss. 
44:27:3: Den annen dag løp vi inn til Sidon, og Julius, som var menneskekjærlig mot Paulus, gav ham lov til å gå til sine venner og nyte godt av deres omsorg. 
44:27:4: Derfra fór vi videre og seilte inn under Kypern, fordi vinden var imot, 
44:27:5: og efterat vi hadde seilt over havet ved Kilikia og Pamfylia, kom vi til Myra i Lykia. 
44:27:6: Der fant høvedsmannen et skib fra Aleksandria som skulde til Italia, og han førte oss ombord på det. 
44:27:7: I mange dager gikk det nu smått med seilingen, og vi vant med nød og neppe frem imot Knidus; da vinden var imot, holdt vi ned under Kreta ved Salmone, 
44:27:8: og det var så vidt vi kom der forbi og nådde frem til et sted som kalles Godhavn, nær ved en by Lasea. 
44:27:9: Da nu en lang tid var gått, og det allerede var farlig å ferdes på sjøen, fordi det alt var over fasten, advarte Paulus dem og sa: 
44:27:10: I menn! jeg ser at sjøferden vil være et vågestykke og medføre stor skade, ikke bare for ladning og skib, men også for vårt liv. 
44:27:11: Men høvedsmannen satte mere lit til styrmannen og skipperen enn til det som Paulus sa. 
44:27:12: Og da havnen var uhøvelig til vinterleie, blev de fleste enige om at de skulde fare ut også derfra, om de måskje kunde vinne frem og ta vinterhavn i Føniks, en havn på Kreta, som vender mot sydvest og nordvest. 
44:27:13: Da det nu blåste en svak sønnenvind, tenkte de at de kunde fullføre sitt forsett; de lettet da, og seilte nær land langsmed Kreta. 
44:27:14: Men ikke lenge efter kom en hvirvelvind som kalles eurakylon, og kastet sig mot øen; 
44:27:15: da skibet blev grepet av den og ikke kunde holde sig op mot vinden, gav vi det op og lot oss drive. 
44:27:16: Vi løp da under en liten ø som kalles Klauda, og det var med nød at vi fikk berget båten; 
44:27:17: da de hadde fått den ombord, grep de til nødhjelp og slo taug om skibet. Og da de fryktet for å drive ned på Syrten, firte de seilet ned, og drev således. 
44:27:18: Da vi nu led meget ondt av været, kastet de næste dag ladningen overbord, 
44:27:19: og den tredje dag kastet vi med egne hender skibets redskap i sjøen. 
44:27:20: Da nu hverken sol eller stjerner lot sig se på flere dager, og et svært uvær var over oss, var det fra nu av forbi med alt håp om redning. 
44:27:21: Og da de ikke hadde fått mat på lenge, stod Paulus frem midt iblandt dem og sa: I menn! I burde ha lydt mitt råd og ikke faret ut fra Kreta, så I hadde spart eder for dette vågestykke og denne skade. 
44:27:22: Og nu ber jeg eder være ved godt mot; for ingen sjel iblandt eder skal forgå, men bare skibet. 
44:27:23: For i denne natt stod for mig en engel fra den Gud som jeg tilhører, som jeg også tjener, og sa: 
44:27:24: Frykt ikke, Paulus! du skal stå frem for keiseren, og se, Gud har gitt dig alle dem som seiler med dig, til gave. 
44:27:25: Derfor vær ved godt mot, I menn! for jeg setter min lit til Gud at det skal bli så som det er sagt mig. 
44:27:26: Men vi skal strande på en eller annen ø. 
44:27:27: Da nu den fjortende natt kom, mens vi drev omkring i Adriaterhavet, skjønte sjøfolkene midt på natten at det bar nær mot land. 
44:27:28: Og da de loddet, fant de tyve favner; men da de var kommet et lite stykke derfra og loddet igjen, fant de femten favner; 
44:27:29: og da de fryktet for at de kanskje kunde støte på skjær, kastet de fire anker ut fra bakstavnen, og ønsket at det vilde bli dag. 
44:27:30: Men sjøfolkene søkte å rømme bort fra skibet og firte båten ned i havet, idet de lot som om de vilde legge ankere ut fra forstavnen; 
44:27:31: da sa Paulus til høvedsmannen og til krigsfolket: Dersom ikke disse blir ombord i skibet, kan I ikke bli berget. 
44:27:32: Da kappet krigsfolket taugene på båten og lot den falle. 
44:27:33: Da det nu led mot dag, bad Paulus alle ta føde til sig, og han sa: Dette er nu den fjortende dag at I venter og lar være å ete og ikke tar noget til eder. 
44:27:34: Derfor ber jeg eder ta føde til eder; dette hører med til eders redning; for det skal ikke falle et hår av hodet på nogen iblandt eder. 
44:27:35: Da han hadde sagt dette, tok han et brød, takket Gud for alles øine og brøt det og begynte å ete; 
44:27:36: da blev de alle frimodige og tok føde til sig de også. 
44:27:37: Vi var i alt to hundre og seks og sytti sjeler på skibet. 
44:27:38: Og da de var blitt mette, lettet de skibet ved å kaste levnetsmidlene i havet. 
44:27:39: Da det nu blev dag, kjente de ikke landet, men de blev var en vik som hadde en strand; der bestemte de sig til å sette skibet på land om det var mulig. 
44:27:40: De kappet da ankerne og lot dem falle i havet, og løste tillike de taug som de hadde surret rorene med; så heiste de seilet for vinden og holdt ned på stranden. 
44:27:41: Men de drev inn på en grunn som hadde dypt hav på begge sider; her støtte de på med skibet, og forskibet løp sig fast og stod urørlig, men akterskibet blev sønderslått av brenningene. 
44:27:42: Krigsfolket vilde nu drepe fangene, forat ikke nogen av dem skulde svømme bort og rømme; 
44:27:43: men høvedsmannen, som vilde frelse Paulus, hindret dem i deres råd, og bød at de som kunde svømme, skulde først kaste sig ut og komme i land, 
44:27:44: og så de andre, dels på planker, dels på stykker av skibet. Og på denne vis gikk det så at alle berget sig i land. 
44:28:1: Da vi nu hadde berget oss, fikk vi vite at øen hette Malta. 
44:28:2: De innfødte viste ikke liten menneskekjærlighet mot oss; de tendte op en ild og tok sig av oss alle sammen, da det hadde begynt å regne og var koldt. 
44:28:3: Men da Paulus sanket sammen en hop tørre kvister og kastet dem på ilden, krøp en orm ut på grunn av heten og bet sig fast i hans hånd. 
44:28:4: Da nu de innfødte så dyret henge ved hans hånd, sa de til hverandre: Dette menneske er visselig en morder, som den hevnende rettferdighet ikke gir lov til å leve, skjønt han er berget fra havet. 
44:28:5: Han rystet da dyret av sig inn i ilden, og hadde intet mén av det; 
44:28:6: men de ventet på at han skulde hovne eller falle død om med det samme. Da de nu ventet lenge, og så at han ikke hadde noget ondt av det, kom de på andre tanker, og sa at han var en gud. 
44:28:7: Men nær ved dette sted hadde den første mann på øen, ved navn Publius, en gård; han tok imot oss og viste oss vennlig gjestfrihet i tre dager. 
44:28:8: Og det traff sig så at Publius' far lå meget syk av feber og blodgang; Paulus gikk da inn til ham og bad og la hendene på ham og helbredet ham. 
44:28:9: Men da dette hadde hendt, kom også de andre der på øen som hadde sykdommer, og blev helbredet. 
44:28:10: De viste oss også stor ære, og da vi fór derfra, førte de ombord i skibet det vi kunde ha bruk for. 
44:28:11: Da nu tre måneder var gått, fór vi derfra på et skib fra Aleksandria som hadde hatt vinterleie ved øen og hadde tvillingene til merke; 
44:28:12: og vi løp inn til Syrakus og blev der i tre dager; 
44:28:13: og efterat vi derfra hadde seilt rundt kysten, kom vi til Regium. Dagen efter blev det sønnenvind, så vi efter to dagers fart kom til Puteoli. 
44:28:14: Der fant vi brødre, og de bad oss bli syv dager hos dem; og så kom vi til Rom. 
44:28:15: Og da brødrene der fikk høre om oss, gikk de oss i møte derfra til Forum Appii og Tres Tabernæ; da Paulus så dem, takket han Gud og fattet mot. 
44:28:16: Da vi nu var kommet til Rom, overgav høvedsmannen fangene til høvdingen for livvakten; men Paulus fikk lov til å bo for sig selv sammen med den stridsmann som holdt vakt over ham. 
44:28:17: Og da tre dager var gått, skjedde det at han kalte sammen de første menn blandt jødene der i byen, og da de var kommet sammen, sa han til dem: Mine brødre! jeg som ikke har gjort noget imot vårt folk eller våre fedres skikker, blev fra Jerusalem av overgitt som fange i romernes hender; 
44:28:18: da de hadde forhørt mig, vilde de late mig løs, fordi det ikke var nogen døds-sak mot mig. 
44:28:19: Men da jødene satte sig imot det, blev jeg nødt til å innanke min sak for keiseren, men ikke som om jeg hadde noget klagemål imot mitt folk. 
44:28:20: Av denne årsak har jeg da bedt eder hit for å få se eder og tale med eder; for det er for Israels håps skyld jeg bærer denne lenke. 
44:28:21: De sa da til ham: Hverken har vi fått noget brev om dig fra Judea, heller ikke er nogen av brødrene kommet og har meldt eller sagt noget ondt om dig. 
44:28:22: Men vi vil gjerne få høre av dig hvad du mener; for om denne sekt er det oss vitterlig at den allesteds finner motsigelse. 
44:28:23: Efterat de da hadde avtalt en dag med ham, kom de i større mengde til ham i hans herberge; han la da ut for dem, idet han vidnet om Guds rike og søkte å overbevise dem om Jesus ut av Mose lov og profetene, fra årle morgen til aften silde. 
44:28:24: Og nogen blev overbevist ved det han sa, men andre var vantro. 
44:28:25: De gikk da fra hverandre i uenighet, idet Paulus sa det ene ord: Rettelig talte den Hellige Ånd ved profeten Esaias til eders fedre da han sa: 
44:28:26: Gå til dette folk og si: I skal høre og høre og ikke forstå, og se og se og ikke skjelne; 
44:28:27: for dette folks hjerte er sløvet, og med ørene hører de tungt, og sine øine lukker de, forat de ikke skal se med øinene og høre med ørene og forstå med hjertet og omvende sig, så jeg kunde få læge dem. 
44:28:28: Så være det eder da vitterlig at denne Guds frelse er blitt utsendt til hedningene; hos dem skal den også finne øre. 
44:28:29: Og da han hadde sagt dette, gikk jødene bort og hadde et skarpt ordskifte innbyrdes. 
44:28:30: Han blev da to fulle år i sitt leide herberge, og tok imot alle som kom til ham, 
44:28:31: og han forkynte Guds rike og lærte om den Herre Jesus med all frimodighet, uten hindring. 
45:1:1: Paulus, Jesu Kristi tjener, kalt til apostel, utkåret til å forkynne Guds evangelium, 
45:1:2: som han forut lovte ved sine profeter i hellige skrifter, 
45:1:3: om hans Sønn, som efter kjødet er kommet av Davids ætt, 
45:1:4: som efter hellighets ånd er godtgjort å være Guds veldige Sønn ved opstandelsen fra de døde, Jesus Kristus, vår Herre, 
45:1:5: ved hvem vi fikk nåde og apostelembede for å virke troens lydighet blandt alle hedningefolkene til hans navns ære, 
45:1:6: blandt hvilke også I er kalt til Jesus Kristus 
45:1:7: - til alle Guds elskede, kalte, hellige som er i Rom: Nåde være med eder og fred fra Gud vår Fader og den Herre Jesus Kristus! 
45:1:8: Først takker jeg min Gud ved Jesus Kristus for eder alle, fordi eders tro er navnkundig i hele verden. 
45:1:9: For Gud, som jeg tjener i min ånd i hans Sønns evangelium, er mitt vidne hvor uavlatelig jeg kommer eder i hu, 
45:1:10: idet jeg alltid i mine bønner beder om at jeg dog endelig engang ved Guds vilje må få lykke til å komme til eder. 
45:1:11: For jeg lenges efter å se eder, så jeg kunde la eder få del med mig i nogen åndelig nådegave, forat I kunde styrkes, 
45:1:12: det vil si at jeg hos eder kunde vederkveges sammen med eder ved den felles tro, eders og min. 
45:1:13: Og jeg vil ikke, brødre, at I skal være uvitende om at jeg ofte har satt mig fore å komme til eder - men jeg er blitt hindret inntil nu - forat jeg kunde ha nogen frukt også blandt eder likesom blandt de andre hedningefolk. 
45:1:14: Jeg står i gjeld både til grekere og til barbarer, både til vise og uvise; 
45:1:15: således er jeg for min del villig til å forkynne evangeliet også for eder i Rom. 
45:1:16: For jeg skammer mig ikke ved evangeliet; for det er en Guds kraft til frelse for hver den som tror, både for jøde først og så for greker; 
45:1:17: for i det åpenbares Guds rettferdighet av tro til tro, som skrevet er: Den rettferdige, ved tro skal han leve. 
45:1:18: For Guds vrede åpenbares fra himmelen over all ugudelighet og urettferdighet hos mennesker som holder sannheten nede i urettferdighet; 
45:1:19: for det som en kan vite om Gud, ligger åpent for dem, for Gud har åpenbaret dem det. 
45:1:20: For hans usynlige vesen, både hans evige kraft og hans guddommelighet, er synlig fra verdens skapelse av, idet det kjennes av hans gjerninger, forat de skal være uten undskyldning, 
45:1:21: fordi de, enda de kjente Gud, dog ikke æret eller takket ham som Gud, men blev dårlige i sine tanker, og deres uforstandige hjerte blev formørket. 
45:1:22: Mens de gjorde sig til av å være vise, blev de dårer, 
45:1:23: og byttet den uforgjengelige Guds herlighet bort mot et billede, en avbildning av et forgjengelig menneske og av fugler og av firføtte dyr og av krypdyr. 
45:1:24: Derfor overgav også Gud dem i deres hjerters lyster til urenhet, til å vanære sine legemer sig imellem, 
45:1:25: de som byttet Guds sannhet bort mot løgn og æret og dyrket skapningen fremfor skaperen, han som er velsignet i evighet. Amen. 
45:1:26: Derfor overgav Gud dem til skammelige lyster; for både deres kvinner forvendte den naturlige bruk til den unaturlige, 
45:1:27: og på samme vis forlot også mennene den naturlige bruk av kvinnen og brente i sin lyst efter hverandre, så at menn drev skjenselsverk med menn, og fikk på sig selv det vvederlag for sin forvillelse som rett var. 
45:1:28: Og likesom de ikke brydde sig om å eie Gud i kunnskap, så overgav Gud dem til et sinn som intet duer, så de gjorde det usømmelige: 
45:1:29: fulle av all urettferdighet, vanart, havesyke, ondskap; fulle av avind, mord, trette, svik, list; 
45:1:30: øretutere, baktalere, gudsforhatte, voldsmenn, overmodige, storskrytere, opfinnsomme til ondt; ulydige mot foreldre, 
45:1:31: uforstandige, upålitelige, ukjærlige, ubarmhjertige; 
45:1:32: slike som godt kjenner Guds dom at de som gjør sådant, fortjener døden, og dog ikke bare gjør det, men endog holder med dem som gjør det. 
45:2:1: Derfor er du uten undskyldning, menneske, hvem du enn er som dømmer. For idet du dømmer din næste, fordømmer du dig selv; for du gjør det samme, du som dog dømmer; 
45:2:2: men vi vet at Guds dom, som sannhet byder, er over dem som gjør slikt. 
45:2:3: Men mener du det, du menneske, du som dømmer dem som gjør slikt, og selv gjør det, at du skal undfly Guds dom? 
45:2:4: Eller forakter du hans godhets og tålmods og langmods rikdom, og vet ikke at Guds godhet driver dig til omvendelse? 
45:2:5: Ved din hårdhet og ditt ubotferdige hjerte ophoper du dig vrede på vredens dag, den dag da Guds rettferdige dom åpenbares, 
45:2:6: han som skal betale enhver efter hans gjerninger: 
45:2:7: dem som med utholdenhet i god gjerning søker herlighet og ære og uforgjengelighet, skal han gi evig liv, 
45:2:8: men dem som er gjenstridige og ulydige mot sannheten, men lydige mot urettferdigheten, dem skal times vrede og harme. 
45:2:9: Trengsel og angst skal komme over hver menneskesjel som gjør det onde, både jøde først og så greker; 
45:2:10: men herlighet og ære og fred skal times hver den som gjør det gode, både jøde først og så greker. 
45:2:11: For Gud gjør ikke forskjell på folk: 
45:2:12: alle som syndet uten loven, skal også gå fortapt uten loven, og alle som syndet under loven, skal dømmes ved loven; 
45:2:13: for ikke de som hører loven, er rettferdige for Gud, men de som gjør efter loven, skal bli rettferdiggjort. 
45:2:14: For når hedningene, som ikke har loven, av naturen gjør det loven byder, da er disse, som dog ikke har loven, sig selv en lov; 
45:2:15: de viser at lovens gjerning er skrevet i deres hjerter, idet også deres samvittighet gir sitt vidnesbyrd, og deres tanker innbyrdes anklager eller også forsvarer dem - 
45:2:16: på den dag da Gud skal dømme det skjulte hos menneskene efter mitt evangelium ved Jesus Kristus. 
45:2:17: Men om du kalles jøde og setter din lit til loven og roser dig av Gud 
45:2:18: og kjenner hans vilje og dømmer om de forskjellige ting, idet du lærer av loven, 
45:2:19: og trøster dig til å være en veiviser for blinde, et lys for dem som er i mørke, 
45:2:20: en opdrager for dårer, en lærer for umyndige, da du har den rette form for kunnskap og sannhet i loven: 
45:2:21: du som altså lærer en annen, lærer du ikke dig selv? du som preker at en ikke skal stjele, stjeler du? 
45:2:22: du som sier at en ikke skal drive hor, driver du hor? du som har motbydelighet for avgudene, raner du avgudenes templer? 
45:2:23: Du som roser dig av loven, du vanærer Gud ved å bryte loven! 
45:2:24: For for eders skyld spottes Guds navn blandt hedningene, som skrevet er. 
45:2:25: For omskjærelsen har vel sin nytte om du holder loven; men er du en lov-bryter, da er din omskjærelse blitt til forhud. 
45:2:26: Om altså den uomskårne holder lovens bud, skal da ikke hans forhud bli regnet som omskjærelse? 
45:2:27: Og den av naturen uomskårne som opfyller loven, skal dømme dig, du som med bokstav og omskjærelse er en lov-bryter. 
45:2:28: For ikke den er jøde som er det i det åpenbare; heller ikke er det omskjærelse som skjer i det åpenbare, på kjøttet; 
45:2:29: men den som er jøde i det skjulte, han er jøde, og omskjærelsen er hjertets omskjærelse i Ånden, ikke i bokstaven; en sådan har sin ros, ikke av mennesker, men av Gud. 
45:3:1: Hvad fortrin har da jøden? eller hvad gagn er det i omskjærelsen? 
45:3:2: Meget i alle måter; først og fremst det at Guds ord blev dem betrodd. 
45:3:3: For hvorledes er det? om somme var utro, skulde da deres utroskap gjøre Guds troskap til intet? 
45:3:4: Langt derifra! la det stå fast at Gud er sanndru, men hvert menneske en løgner, som skrevet er: At du må kjennes rettferdig i dine ord og vinne når du fører din sak. 
45:3:5: Men dersom vår urettferdighet viser Guds rettferdighet, hvad skal vi da si? Er vel Gud urettferdig når han fører sin vrede over oss? Jeg taler på menneskelig vis. 
45:3:6: Langt derifra! hvorledes kunde Gud da dømme verden? 
45:3:7: Ja, men dersom Guds sanndruhet ved min løgn åpenbarte sig rikelig til hans ære, hvorfor blir da jeg enda dømt som en synder? 
45:3:8: Og skal vi da ikke - som vi spottes for, og som nogen sier at vi lærer - gjøre det onde forat det gode kan komme derav? Rettferdig er den dom som treffer slike. 
45:3:9: Hvad da? har vi nogen fordel? Nei, aldeles ikke; vi har jo før anklaget både jøder og grekere for at de alle sammen er under synd, 
45:3:10: som skrevet er: Det finnes ikke en rettferdig, enn ikke en; 
45:3:11: det finnes ikke en som er forstandig; det finnes ikke en som søker Gud; 
45:3:12: alle er avveket; alle til hope er de blitt uduelige; det finnes ikke nogen som gjør godt, det finnes ikke en eneste. 
45:3:13: Deres strupe er en åpnet grav; med sine tunger gjorde de svik, ormegift er under deres leber. 
45:3:14: Deres munn er full av forbannelse og bitterhet. 
45:3:15: Deres føtter er snare til å utøse blod; 
45:3:16: ødeleggelse og usælhet er det på deres veier, 
45:3:17: og freds vei kjenner de ikke. 
45:3:18: Det er ikke gudsfrykt for deres øine. 
45:3:19: Men vi vet at alt det som loven sier, det taler den til dem som har loven, forat hver munn skal lukkes og hele verden bli skyldig for Gud, 
45:3:20: siden intet kjød blir rettferdiggjort for ham ved lov-gjerninger; for ved loven kommer syndens erkjennelse. 
45:3:21: Men nu er Guds rettferdighet, som loven og profetene vidner om, åpenbaret uten loven, 
45:3:22: det vil si Guds rettferdighet ved troen på Jesus Kristus for alle og over alle som tror. For det er ingen forskjell; 
45:3:23: alle har syndet og fattes Guds ære, 
45:3:24: og de blir rettferdiggjort uforskyldt av hans nåde ved forløsningen i Kristus Jesus, 
45:3:25: som Gud stilte til skue i hans blod, som en nådestol ved troen, for å vise sin rettferdighet, fordi han i sin langmodighet hadde båret over med de synder som før var gjort - 
45:3:26: for å vise sin rettferdighet i den tid som nu er, så han kunde være rettferdig og gjøre den rettferdig som har troen på Jesus. 
45:3:27: Hvor er så vår ros? Den er utelukket. Ved hvilken lov? Gjerningenes? Nei, ved troens lov. 
45:3:28: For vi holder for at mennesket blir rettferdiggjort ved troen, uten lov-gjerninger. 
45:3:29: Eller er Gud bare jøders Gud? er han ikke også hedningers? Jo, han er også hedningers, 
45:3:30: så sant Gud er én, og han rettferdiggjør de omskårne av troen og de uomskårne ved troen. 
45:3:31: Ophever vi da loven ved troen? Langt derifra! vi stadfester loven. 
45:4:1: Hvad skal vi da si at vår far Abraham har opnådd efter kjødet? 
45:4:2: For blev Abraham rettferdiggjort av gjerninger, da har han noget å rose sig av. Men det har han ikke for Gud; 
45:4:3: for hvad sier Skriften? Abraham trodde Gud, og det blev regnet ham til rettferdighet; 
45:4:4: men den som har gjerninger, ham tilregnes lønnen ikke av nåde, men som skyldighet; 
45:4:5: den derimot som ikke har gjerninger, men tror på ham som rettferdiggjør den ugudelige, ham regnes hans tro til rettferdighet. 
45:4:6: Således priser også David det menneske salig som Gud tilregner rettferdighet uten gjerninger: 
45:4:7: Salige er de hvis overtredelser er forlatt, og hvis synder er skjult, 
45:4:8: salig er den mann som Herren ikke tilregner synd. 
45:4:9: Gjelder nu denne saligprisning de omskårne, eller også de uomskårne? Vi sier jo at troen blev regnet Abraham til rettferdighet; 
45:4:10: hvorledes blev den da tilregnet ham? da han var omskåret, eller da han hadde forhud? Ikke da han var omskåret, men da han hadde forhud, 
45:4:11: og han fikk omskjærelsens tegn som et segl på rettferdigheten ved den tro som han hadde da han var uomskåret, forat han skulde være far til alle de uomskårne som tror, så rettferdigheten kunde tilregnes også dem, 
45:4:12: og far til de omskårne som ikke bare har omskjærelsen, men også vandrer i fotsporene av den tro som vår far Abraham hadde da han var uomskåret. 
45:4:13: For ikke ved loven fikk Abraham eller hans ætt det løfte at han skulde være arving til verden, men ved troens rettferdighet. 
45:4:14: For dersom de som holder sig til loven, er arvinger, da er troen blitt unyttig, og løftet blitt til intet; 
45:4:15: for loven virker vrede; men hvor det ikke er nogen lov, er det heller ingen overtredelse. 
45:4:16: Derfor fikk han løftet ved troen, forat det kunde være som en nåde, så løftet kunde stå fast for hele ætten, ikke bare for den som har loven, men også for den som har Abrahams tro, han som er far til oss alle 
45:4:17: - som skrevet er: Til mange folks far har jeg satt dig - for Guds åsyn, hvem han trodde, han som gjør de døde levende og nevner det som ikke er til, som om det var til. 
45:4:18: Mot håp trodde han med håp, forat han skulde bli mange folks far efter det som sagt var: Så skal din ætt bli; 
45:4:19: og uten å bli svak i troen så han på sitt eget legeme, som var utlevd, han var næsten hundre år gammel, og på Saras utdødde morsliv; 
45:4:20: på Guds løfte tvilte han ikke i vantro, men blev sterk i sin tro, idet han gav Gud æren 
45:4:21: og var fullt viss på at det han hadde lovt, det var han også mektig til å gjøre. 
45:4:22: Derfor blev det også regnet ham til rettferdighet. 
45:4:23: Men ikke bare for hans skyld er det skrevet at det blev tilregnet ham, 
45:4:24: men også for vår skyld, som det vil bli tilregnet, vi som tror på ham som opvakte Jesus, vår Herre, fra de døde, 
45:4:25: han som blev gitt for våre overtredelser og opreist til vår rettferdiggjørelse. 
45:5:1: Da vi nu altså er rettferdiggjort av troen, har vi fred med Gud ved vår Herre Jesus Kristus, 
45:5:2: ved hvem vi også har fått adgang ved troen til denne nåde i hvilken vi står, og vi roser oss av håp om Guds herlighet; 
45:5:3: ja, ikke bare det, men vi roser oss også av våre trengsler, for vi vet at trengselen virker tålmodighet, 
45:5:4: og tålmodigheten et prøvet sinn, og det prøvede sinn håp, 
45:5:5: og håpet gjør ikke til skamme, fordi Guds kjærlighet er utøst i våre hjerter ved den Hellige Ånd, som er oss gitt. 
45:5:6: For mens vi ennu var skrøpelige, døde Kristus til fastsatt tid for ugudelige. 
45:5:7: For neppe vil nogen gå i døden for en rettferdig - for en som er god, kunde kanskje nogen ta sig på å dø - 
45:5:8: men Gud viser sin kjærlighet mot oss derved at Kristus døde for oss mens vi ennu var syndere. 
45:5:9: Så meget mere skal vi da, efterat vi nu er rettferdiggjort ved hans blod, ved ham bli frelst fra vreden. 
45:5:10: For så sant vi blev forlikt med Gud ved hans Sønns død da vi var fiender, så skal vi så meget mere bli frelst ved hans liv efterat vi er blitt forlikt; 
45:5:11: ja, ikke bare det, men vi roser oss også av Gud ved vår Herre Jesus Kristus, ved hvem vi nu har fått forlikelsen. 
45:5:12: Derfor, likesom synden kom inn i verden ved ett menneske, og døden ved synden, og døden således trengte igjennem til alle mennesker, fordi de syndet alle - 
45:5:13: for vel var det synd i verden før loven, men synden tilregnes ikke hvor det ingen lov er; 
45:5:14: men allikevel hersket døden fra Adam til Moses også over dem som ikke hadde syndet i likhet med Adams overtredelse, han som er et forbillede på den som skulde komme. 
45:5:15: Men ikke er det med nådegaven således som med fallet; for er de mange død ved den enes fall, da er meget mere Guds nåde og gaven i det ene menneske Jesu Kristi nåde blitt overvettes rik for de mange. 
45:5:16: Og ikke er det med gaven således som det blev da én syndet; for dommen blev til fordømmelse for éns skyld, men nådegaven til frifinnelsesdom for mange falls skyld. 
45:5:17: For kom døden til å herske ved den ene på grunn av den enes fall, så skal meget mere de som får nådens og rettferdighets-gavens overvettes rikdom, leve og herske ved den ene, Jesus Kristus. 
45:5:18: Altså: likesom éns fall blev til fordømmelse for alle mennesker, således blev også éns rettferdige gjerning til livsens rettferdiggjørelse for alle mennesker; 
45:5:19: for likesom de mange er blitt syndere ved det ene menneskes ulydighet, så skal også de mange bli rettferdige ved den enes lydighet. 
45:5:20: Men loven kom til forat fallet skulde bli stort; men hvor synden blev stor, blev nåden enda større, 
45:5:21: forat likesom synden hersket ved døden, så skulde også nåden herske ved rettferdighet til et evig liv ved Jesus Kristus, vår Herre. 
45:6:1: Hvad skal vi da si? skal vi holde ved i synden, forat nåden kan bli dess større? 
45:6:2: Langt derifra! vi som er avdød fra synden, hvorledes skulde vi ennu leve i den? 
45:6:3: Eller vet I ikke at alle vi som blev døpt til Kristus Jesus, blev døpt til hans død? 
45:6:4: Vi blev altså begravet med ham ved dåpen til døden, forat likesom Kristus blev opreist fra de døde ved Faderens herlighet, så skal også vi vandre i et nytt levnet. 
45:6:5: For er vi blitt forenet med ham ved likheten med hans død, så skal vi også bli det ved likheten med hans opstandelse, 
45:6:6: da vi jo vet dette at vårt gamle menneske blev korsfestet med ham forat synde-legemet skulde bli til intet, så vi ikke mere skal tjene synden; 
45:6:7: for den som er død, er rettferdiggjort fra synden. 
45:6:8: Men er vi død med Kristus, da tror vi at vi også skal leve med ham, 
45:6:9: fordi vi vet at efterat Kristus er opstanden fra de døde, dør han ikke mere; døden har ikke mere nogen makt over ham; 
45:6:10: for sin død, den døde han én gang for synden, men sitt liv, det lever han for Gud. 
45:6:11: Således skal også I akte eder som døde for synden, men levende for Gud i Kristus Jesus. 
45:6:12: La derfor ikke synden herske i eders dødelige legeme, så I lyder dets lyster; 
45:6:13: by heller ikke eders lemmer frem for synden som urettferdighets våben, men by eder frem for Gud som de som av døde er blitt levende, og eders lemmer som rettferdighets våben for Gud! 
45:6:14: For synden skal ikke herske over eder; I er jo ikke under loven, men under nåden. 
45:6:15: Hvad da? skal vi synde, siden vi ikke er under loven, men under nåden? Langt derifra! 
45:6:16: vet I ikke at når I byr eder frem for nogen som tjenere til lydighet, da er I også tjenere under den som I så lyder, enten det er under synden til død eller under lydigheten til rettferdighet? 
45:6:17: Men Gud være takk at I vel har vært syndens tjenere, men nu av hjertet er blitt lydige mot den lærdomsform som I er blitt overgitt til! 
45:6:18: Men idet I er blitt frigjort fra synden, er I trådt i rettferdighetens tjeneste. 
45:6:19: Jeg taler på menneskelig vis for eders kjøds skrøpelighets skyld. For likesom I bød eders lemmer frem som tjenere for urenheten og urettferdigheten til urettferdighet, således by nu eders lemmer frem som tjenere for rettferdigheten til helliggjørelse! 
45:6:20: For dengang da I var syndens tjenere, var I fri fra rettferdigheten. 
45:6:21: Hvad frukt hadde I da dengang? Slikt som I nu skammer eder over; for utgangen på det er døden. 
45:6:22: Men nu, da I er frigjort fra synden og er trådt i Guds tjeneste, har I eders frukt til helliggjørelse, og til utgang et evig liv. 
45:6:23: For den lønn som synden gir, er døden, men Guds nådegave er evig liv i Kristus Jesus, vår Herre. 
45:7:1: Eller vet I ikke, brødre - jeg taler jo til slike som kjenner loven - at loven hersker over mennesket så lenge det lever? 
45:7:2: Den gifte kvinne er jo ved loven bundet til sin mann så lenge han lever; men dersom mannen dør, er hun løst fra loven som bandt henne til mannen. 
45:7:3: Derfor skal hun kalles en horkvinne om hun, mens mannen lever, ekter en annen mann; men dersom mannen dør, er hun fri fra loven, så hun ikke blir en horkvinne om hun ekter en annen mann. 
45:7:4: Derfor, mine brødre, døde også I fra loven ved Kristi legeme, forat I skulde høre en annen til, ham som er opstanden fra de døde, så vi kan bære frukt for Gud. 
45:7:5: For da vi var i kjødet, virket de syndige lyster, som vaktes ved loven, således i våre lemmer at vi bar frukt for døden; 
45:7:6: men nu er vi løst fra loven, idet vi er død fra det som vi var fanget under, så vi tjener i Åndens nye vesen, og ikke i bokstavens gamle vesen. 
45:7:7: Hvad skal vi da si? er loven synd? Langt derifra! men jeg kjente ikke synden uten ved loven; for begjærligheten kjente jeg ikke dersom ikke loven hadde sagt: Du skal ikke begjære. 
45:7:8: Men synden tok anledning av budet og virket alskens begjærlighet i mig; for uten lov er synden død. 
45:7:9: Jeg levde en tid uten lov; men da budet kom, blev synden levende igjen, 
45:7:10: jeg derimot døde; og budet, som var til liv, det blev funnet å være mig til død; 
45:7:11: for synden tok anledning av budet og dåret og drepte mig ved det. 
45:7:12: Så er da loven hellig, og budet hellig og rettferdig og godt. 
45:7:13: Er da det som er godt, blitt mig til død? Langt derifra! men det var synden, forat den skulde vise sig som synd, idet den voldte mig døden ved det som er godt - forat synden skulde bli overvettes syndig ved budet. 
45:7:14: For vi vet at loven er åndelig; jeg derimot er kjødelig, solgt under synden; 
45:7:15: for hvad jeg gjør, vet jeg ikke; for jeg gjør ikke det som jeg vil; men det som jeg hater, det gjør jeg. 
45:7:16: Men gjør jeg det som jeg ikke vil, da vidner jeg jo med loven at den er god; 
45:7:17: men nu er det ikke mere jeg som gjør det, men synden, som bor i mig. 
45:7:18: For jeg vet at i mig, det er i mitt kjød, bor intet godt; for viljen har jeg, men å gjøre det gode makter jeg ikke; 
45:7:19: for jeg gjør ikke det gode som jeg vil, men det onde som jeg ikke vil, det gjør jeg. 
45:7:20: Men gjør jeg det som jeg ikke vil, da er det ikke mere jeg som gjør det, men synden, som bor i mig. 
45:7:21: Så finner jeg da den lov for mig, jeg som vil gjøre det gode, at det onde ligger mig for hånden; 
45:7:22: for jeg har lyst til Guds lov efter mitt innvortes menneske, 
45:7:23: men jeg ser en annen lov i mine lemmer, som strider mot loven i mitt sinn og tar mig til fange under syndens lov, den som er i mine lemmer. 
45:7:24: Jeg elendige menneske! hvem skal fri mig fra dette dødens legeme? 
45:7:25: Gud være takk ved Jesus Kristus, vår Herre! - Så tjener da jeg Guds lov med mitt sinn, men syndens lov med mitt kjød. 
45:8:1: Så er det da ingen fordømmelse for dem som er i Kristus Jesus; 
45:8:2: for livets Ånds lov har i Kristus Jesus frigjort mig fra syndens og dødens lov. 
45:8:3: For det som var umulig for loven, idet den var maktesløs ved kjødet, det gjorde Gud, idet han sendte sin Sønn i syndig kjøds lignelse og for syndens skyld og fordømte synden i kjødet, 
45:8:4: forat lovens krav skulde bli opfylt i oss, vi som ikke vandrer efter kjødet, men efter Ånden. 
45:8:5: For de som er efter kjødet, attrår det som hører kjødet til, men de som er efter Ånden, attrår det som hører Ånden til. 
45:8:6: For kjødets attrå er død, men Åndens attrå er liv og fred, 
45:8:7: fordi kjødets attrå er fiendskap mot Gud - for det er ikke Guds lov lydig, kan heller ikke være det - 
45:8:8: og de som er i kjødet, kan ikke tekkes Gud. 
45:8:9: Men I er ikke i kjødet, men i Ånden, såfremt Guds Ånd bor i eder; men har nogen ikke Kristi Ånd, da hører han ikke ham til. 
45:8:10: Men er Kristus i eder, da er vel legemet dødt på grunn av synd, men ånden er liv på grunn av rettferdighet. 
45:8:11: Men dersom hans Ånd som opvakte Jesus fra de døde, bor i eder, da skal han som opvakte Kristus fra de døde, også levendegjøre eders dødelige legemer ved sin Ånd, som bor i eder. 
45:8:12: Derfor, brødre, står vi ikke i gjeld til kjødet, så vi skulde leve efter kjødet; 
45:8:13: for dersom I lever efter kjødet, da skal I dø; men dersom I døder legemets gjerninger ved Ånden, da skal I leve. 
45:8:14: For så mange som drives av Guds Ånd, de er Guds barn. 
45:8:15: I fikk jo ikke trældommens ånd, så I atter skulde frykte, men I fikk barnekårets Ånd, ved hvilken vi roper: Abba, Fader! 
45:8:16: Ånden selv vidner med vår ånd at vi er Guds barn; 
45:8:17: men er vi barn, da er vi også arvinger, Guds arvinger og Kristi medarvinger, såfremt vi lider med ham, forat vi også skal herliggjøres med ham. 
45:8:18: For jeg holder for at den nærværende tids lidelser ikke er å akte mot den herlighet som skal åpenbares på oss. 
45:8:19: For skapningen lenges og stunder efter Guds barns åpenbarelse; 
45:8:20: skapningen blev jo lagt under forgjengelighet - ikke godvillig, men efter hans vilje som la den derunder - 
45:8:21: i håp om at også skapningen skal bli frigjort fra forgjengelighetens trældom til Guds barns herlighets frihet. 
45:8:22: For vi vet at hele skapningen tilsammen sukker og er tilsammen i smerte inntil nu; 
45:8:23: ja, ikke bare det, men også vi som dog har Åndens førstegrøde, også vi sukker med oss selv, idet vi stunder efter vårt barnekår, vårt legemes forløsning. 
45:8:24: For i håpet er vi frelst; men et håp som sees, er ikke noget håp; hvorfor skulde en håpe det som han ser? 
45:8:25: Men dersom vi håper det vi ikke ser, da stunder vi efter det med tålmod. 
45:8:26: Men i like måte kommer også Ånden vår skrøpelighet til hjelp; for vi vet ikke hvad vi skal bede om, slik som vi trenger det; men Ånden selv går i forbønn for oss med usigelige sukk, 
45:8:27: og han som ransaker hjertene, vet hvad Åndens attrå er; for efter Guds vilje går han i forbønn for de hellige. 
45:8:28: Og vi vet at alle ting tjener dem til gode som elsker Gud, dem som efter hans råd er kalt. 
45:8:29: For dem som han forut kjente, dem har han også forut bestemt til å bli likedannet med hans Sønns billede, forat han skulde være den førstefødte blandt mange brødre; 
45:8:30: og dem som han forut bestemte, dem har han også kalt; og dem som han kalte, dem har han også rettferdiggjort; og dem som han rettferdiggjorde, dem har han også herliggjort. 
45:8:31: Hvad skal vi da si til dette? Er Gud for oss, hvem er da imot oss? 
45:8:32: Han som ikke sparte sin egen Sønn, men gav ham for oss alle, hvorledes skal han kunne annet enn gi oss alle ting med ham? 
45:8:33: Hvem vil anklage Guds utvalgte? Gud er den som rettferdiggjør; 
45:8:34: hvem er den som fordømmer? Kristus er den som er død, ja, hvad mere er, som også er opstanden, som også er ved Guds høire hånd, som også går i forbønn for oss; 
45:8:35: hvem vil skille oss fra Kristi kjærlighet? Trengsel eller angst eller forfølgelse eller hunger eller nakenhet eller fare eller sverd? 
45:8:36: som skrevet er: For din skyld drepes vi hele dagen; vi er regnet som slaktefår. 
45:8:37: Men i alt dette vinner vi mere enn seier ved ham som elsket oss. 
45:8:38: For jeg er viss på at hverken død eller liv, hverken engler eller krefter, hverken det som nu er eller det som komme skal, eller nogen makt, 
45:8:39: hverken høide eller dybde eller nogen annen skapning skal kunne skille oss fra Guds kjærlighet i Kristus Jesus, vår Herre. 
45:9:1: Jeg sier sannhet i Kristus, jeg lyver ikke, min samvittighet vidner med mig i den Hellige Ånd, 
45:9:2: at jeg har en stor sorg og en uavlatelig gremmelse i mitt hjerte. 
45:9:3: For jeg vilde ønske at jeg selv var forbannet bort fra Kristus for mine brødre, mine frender efter kjødet, 
45:9:4: de som er israelitter, de som barnekåret og herligheten og paktene og lovgivningen og gudstjenesten og løftene tilhører, 
45:9:5: de som fedrene tilhører, og som Kristus er kommet fra efter kjødet, han som er Gud over alle ting, velsignet i evighet. Amen. 
45:9:6: Dog ikke som om Guds ord har slått feil. For ikke alle som er av Israels ætt, er derfor Israel; 
45:9:7: heller ikke er alle, fordi de er Abrahams ætt, derfor hans barn; men: I Isak skal det nevnes dig en ætt, 
45:9:8: det er: ikke kjødets barn er Guds barn, men løftets barn regnes til ætten; 
45:9:9: for et løftes ord er dette: Ved denne tid vil jeg komme, og da skal Sara ha en sønn. 
45:9:10: Og ikke bare dette; men så var det også med Rebekka, hun som var fruktsommelig ved én, Isak, vår far. 
45:9:11: For da de ennu var ufødte og ennu ikke hadde gjort hverken godt eller ondt - forat Guds råd efter hans utvelgelse skulde stå ved makt, ikke ved gjerninger, men ved ham som kaller - 
45:9:12: da blev det sagt til henne: Den eldste skal tjene den yngste; 
45:9:13: som skrevet er: Jakob elsket jeg, men Esau hatet jeg. 
45:9:14: Hvad skal vi da si? er der vel urettferdighet hos Gud? Langt derifra! 
45:9:15: for til Moses sier han: Jeg vil miskunne mig over den som jeg miskunner mig over, og ynkes over den som Jeg ynkes over. 
45:9:16: Så står det da ikke til den som vil, heller ikke til den som løper, men til Gud, som gjør miskunnhet. 
45:9:17: For Skriften sier til Farao: Just til dette opreiste jeg dig at jeg kunde vise min makt på dig, og at mitt navn kunde bli kunngjort over all jorden. 
45:9:18: Altså: hvem han vil, den miskunner han sig over; og hvem han vil, den forherder han. 
45:9:19: Du vil da si til mig: Hvad har han da ennu å klage over? for hvem står vel hans vilje imot? 
45:9:20: Men hvem er da du, menneske, som tar til gjenmæle mot Gud? Vil da verket si til virkeren: Hvorfor gjorde du mig slik? 
45:9:21: Eller har ikke pottemakeren makt over leret, så han av samme deig kan arbeide det ene kar til ære, det andre til vanære? 
45:9:22: Men om nu Gud, skjønt han vilde vise sin vrede og kunngjøre sin makt, dog i stort langmod tålte vredens kar, som var dannet til undergang, 
45:9:23: så han også kunde kunngjøre sin herlighets rikdom over miskunnhetens kar, som han forut hadde beredt til herlighet? 
45:9:24: Og til å bli slike kalte han også oss, ikke bare av jøder, men også av hedninger, 
45:9:25: som han også sier hos Hoseas: Det som ikke er mitt folk, vil jeg kalle mitt folk, og henne som ikke er elsket, vil jeg kalle min elskede, 
45:9:26: og det skal skje: På det sted hvor det blev sagt til dem: I er ikke mitt folk, der skal de kalles den levende Guds barn. 
45:9:27: Og Esaias roper ut over Israel: Om tallet på Israels barn er som havets sand, så skal bare levningen bli frelst; 
45:9:28: for sitt ord skal Herren utføre og hastig fullbyrde på jorden. 
45:9:29: Og som Esaias forut har sagt: Hadde ikke den Herre Sebaot levnet oss en sæd, så var vi blitt som Sodoma og gjort like med Gomorra. 
45:9:30: Hvad skal vi da si? At hedninger som ikke søkte rettferdighet, de vant rettferdighet, men det var rettferdigheten av tro; 
45:9:31: Israel derimot, som søkte rettferdighetens lov, de vant ikke frem til denne lov. 
45:9:32: Hvorfor det? Fordi de ikke søkte den ved tro, men ved gjerninger; for de støtte an mot snublestenen, 
45:9:33: som skrevet er: Se, jeg legger i Sion en snublesten og en anstøtsklippe; den som tror på ham, skal ikke bli til skamme. 
45:10:1: Brødre! mitt hjertes ønske og min bønn til Gud for dem er at de må bli frelst. 
45:10:2: For jeg gir dem det vidnesbyrd at de har nidkjærhet for Gud, men ikke med skjønnsomhet; 
45:10:3: for da de ikke kjente Guds rettferdighet og strevde efter å grunne sin egen rettferdighet, gav de sig ikke inn under Guds rettferdighet. 
45:10:4: For Kristus er lovens ende, til rettferdighet for hver den som tror. 
45:10:5: For Moses skriver om rettferdigheten av loven: Det menneske som gjør disse ting, skal leve ved dem; 
45:10:6: men rettferdigheten av troen sier så: Si ikke i ditt hjerte: Hvem skal fare op til himmelen - det vil si: for å hente Kristus ned - ? 
45:10:7: eller: Hvem skal fare ned i avgrunnen - det vil si: for å hente Kristus op fra de døde - ? 
45:10:8: Men hvad sier den? Ordet er dig nær, i din munn og i ditt hjerte; det er troens ord, det som vi forkynner, 
45:10:9: for dersom du med din munn bekjenner at Jesus er Herre, og i ditt hjerte tror at Gud opvakte ham fra de døde, da skal du bli frelst; 
45:10:10: for med hjertet tror en til rettferdighet, og med munnen bekjenner en til frelse. 
45:10:11: For Skriften sier: Hver den som tror på ham, skal ikke bli til skamme. 
45:10:12: Det er jo ingen forskjell på jøde og greker; de har alle den samme Herre, som er rik nok for alle som påkaller ham; 
45:10:13: for hver den som påkaller Herrens navn, skal bli frelst. 
45:10:14: Hvorledes kan de da påkalle den som de ikke tror på? og hvorledes kan de tro der de ikke har hørt? og hvorledes kan de høre uten at det er nogen som forkynner? 
45:10:15: og hvorledes kan de forkynne uten at de blir utsendt? som skrevet er: Hvor fagre deres føtter er som forkynner fred, som bærer godt budskap! 
45:10:16: Men ikke alle var lydige mot evangeliet. For Esaias sier: Herre! hvem trodde vel det han hørte av oss? 
45:10:17: Så kommer da troen av forkynnelsen, og forkynnelsen ved Kristi ord; 
45:10:18: men jeg sier: Har de da ikke fått høre? Jo til visse; deres røst gikk ut til all jorden, og deres ord til jorderikes ende. 
45:10:19: Men jeg sier: Kjente da Israel ikke til det? Først sier Moses: Jeg vil gjøre eder nidkjære på det som ikke er et folk; på et uforstandig folk vil jeg gjøre eder harme; 
45:10:20: og Esaias våger sig til å si: Jeg blev funnet av dem som ikke søkte mig; jeg åpenbarte mig for dem som ikke spurte efter mig; 
45:10:21: men om Israel sier han: Hele dagen rakte jeg mine hender ut til et ulydig og gjenstridig folk. 
45:11:1: Jeg sier altså: Har da Gud forkastet sitt folk? Langt derifra! også jeg er jo en israelitt, av Abrahams ætt, av Benjamins stamme; 
45:11:2: Gud har ikke forkastet sitt folk, som han forut kjente. Eller vet I ikke hvad Skriften sier på det sted om Elias, hvorledes han treder frem for Gud mot Israel: 
45:11:3: Herre! dine profeter drepte de, dine alter rev de ned, og jeg blev alene tilbake, og de står mig efter livet. 
45:11:4: Men hvad sier Guds svar til ham? Jeg har levnet mig syv tusen menn som ikke har bøiet kne for Ba'al. 
45:11:5: Således er det da også i denne tid blitt en levning tilbake efter nådens utvelgelse; 
45:11:6: men er det av nåde, da er det ikke mere av gjerninger, ellers blir nåden ikke mere nåde. 
45:11:7: Hvorledes altså? Det som Israel attrår, det har det ikke nådd; men de utvalgte har nådd det, de andre er blitt forherdet, 
45:11:8: som skrevet er: Gud gav dem en treghets ånd, øine til ikke å se med og ører til ikke å høre med, inntil den dag idag. 
45:11:9: Og David sier: La deres bord bli dem til en strikke og en snare og en felle og en gjengjeldelse for dem! 
45:11:10: la deres øine bli formørket, så de ikke ser, og bøi alltid deres rygg! 
45:11:11: Jeg sier altså: Har de da snublet for å falle? Langt derifra! men ved deres fall er frelsen kommet til hedningene, forat dette skal vekke dem til nidkjærhet; 
45:11:12: men er deres fall en rikdom for verden, og er tapet av dem en rikdom for hedninger, hvor meget mere da deres fylde! 
45:11:13: For til eder taler jeg, I hedninger! Så sant som jeg er hedningenes apostel, priser jeg mitt embede, 
45:11:14: om jeg bare kunde vekke mine kjødelige frender til nidkjærhet og få frelst nogen av dem. 
45:11:15: For er verden blitt forlikt med Gud ved deres forkastelse, hvad annet vil da deres antagelse bli enn liv av døde? 
45:11:16: Men er førstegrøden hellig, da er deigen det også, og er roten hellig, da er grenene det også. 
45:11:17: Om nu allikevel nogen av grenene blev avbrutt, og du som var en vill oljekvist, blev innpodet iblandt dem og fikk del med dem i oljetreets rot og fedme, 
45:11:18: da ros dig ikke mot grenene! men hvis du roser dig, så er det dog ikke du som bærer roten, men roten som bærer dig. 
45:11:19: Du vil da si: Grenene blev avbrutt forat jeg skulde bli innpodet. 
45:11:20: Vel! ved sin vantro blev de avbrutt, men du står ved din tro; vær ikke overmodig, men frykt! 
45:11:21: for sparte Gud ikke de naturlige grener, da vil han heller ikke spare dig. 
45:11:22: Så se da Guds godhet og strenghet: Strenghet er over dem som er falt, men over dig er Guds godhet, såfremt du holder dig til hans godhet, ellers skal også du bli avhugget. 
45:11:23: Men hine skal også bli innpodet, såfremt de ikke holder ved i sin vantro; for Gud er mektig til å innpode dem igjen. 
45:11:24: For blev du avhugget av det oljetre som er vilt av naturen, og mot naturen innpodet i et godt oljetre, hvor meget mere skal de da bli innpodet i sitt eget oljetre, disse som av naturen hører det til. 
45:11:25: For jeg vil ikke, brødre, at I skal være uvitende om denne hemmelighet - forat I ikke skal tykkes eder selv kloke - at forherdelse delvis er kommet over Israel, inntil fylden av hedningene er kommet inn, 
45:11:26: og således skal hele Israel bli frelst, som skrevet er: Fra Sion skal redningsmannen komme, han skal bortrydde ugudelighet fra Jakob, 
45:11:27: og når jeg borttar deres synder, da er dette min pakt med dem. 
45:11:28: Efter evangeliet er de fiender for eders skyld, men efter utvelgelsen er de elsket for fedrenes skyld; 
45:11:29: for sine nådegaver og sitt kall angrer Gud ikke på. 
45:11:30: For likesom I før var ulydige mot Gud, men nu har fått miskunn ved disses ulydighet, 
45:11:31: så har også disse nu vært ulydige, forat de også skal få miskunn ved den miskunn som er blitt eder til del; 
45:11:32: for Gud har overgitt dem alle til ulydighet forat han kunde miskunne sig over dem alle. 
45:11:33: O dyp av rikdom og visdom og kunnskap hos Gud! Hvor uransakelige hans dommer er, og hvor usporlige hans veier! 
45:11:34: For hvem kjente Herrens sinn? eller hvem var hans rådgiver? 
45:11:35: Eller hvem gav ham noget først, så han skulde få vederlag igjen? 
45:11:36: For av ham og ved ham og til ham er alle ting; ham være æren i evighet! Amen. 
45:12:1: Jeg formaner eder altså, brødre, ved Guds miskunn at I fremstiller eders legemer som et levende, hellig, Gud velbehagelig offer - dette er eders åndelige gudstjeneste - 
45:12:2: og skikk eder ikke like med denne verden, men bli forvandlet ved fornyelsen av eders sinn, så I kan prøve hvad som er Guds vilje: det gode og velbehagelige og fullkomne! 
45:12:3: For ved den nåde som er mig gitt, sier jeg til enhver iblandt eder at han ikke skal tenke høiere enn han bør tenke, men tenke så at han tenker sindig, alt efter som Gud har tilmålt enhver hans mål av tro. 
45:12:4: For likesom vi har mange lemmer på ett legeme, men ikke alle lemmene har samme gjerning, 
45:12:5: således er vi mange ett legeme i Kristus, men hver for sig er vi hverandres lemmer. 
45:12:6: Og da vi har ulike nådegaver, alt efter den nåde som er oss gitt, så la oss, om vi har profetisk gave, bruke den efter som vi har tro til, 
45:12:7: eller om vi har en tjeneste, ta vare på tjenesten, eller om en er lærer, på lærdommen, 
45:12:8: eller om en skal formane, på formaningen; den som utdeler, gjøre det med ærlig hu; den som er forstander, være det med iver; den som gjør barmhjertighet, gjøre det med glede! 
45:12:9: Kjærligheten være uten skrømt; avsky det onde, hold fast ved det gode! 
45:12:10: Vær ømhjertede mot hverandre i broderkjærlighet; kappes om å hedre hverandre! 
45:12:11: Vær ikke lunkne i eders iver; vær brennende i ånden; tjen Herren! 
45:12:12: Vær glade i håpet, tålmodige i trengselen, vedholdende i bønnen. 
45:12:13: Ta eder av de hellige i deres trang; legg vinn på gjestfrihet! 
45:12:14: Velsign dem som forfølger eder; velsign, og forbann ikke! 
45:12:15: Gled eder med de glade, og gråt med de gråtende! 
45:12:16: Ha ett sinnelag mot hverandre; attrå ikke det høie, men hold eder gjerne til det lave; vær ikke selvkloke! 
45:12:17: Gjengjeld ikke nogen ondt med ondt; legg vinn på det som godt er, for alle menneskers åsyn! 
45:12:18: Såfremt det er mulig, da hold I på eders side fred med alle mennesker! 
45:12:19: Hevn eder ikke selv, mine elskede, men gi vreden rum! for det er skrevet: Mig hører hevnen til, jeg vil gjengjelde, sier Herren. 
45:12:20: Om da din fiende hungrer, så gi ham å ete; om han tørster, gi ham å drikke! for når du gjør dette, sanker du gloende kull på hans hode. 
45:12:21: La dig ikke overvinne av det onde, men overvinn det onde med det gode! 
45:13:1: Hver sjel være lydig mot de foresatte øvrigheter! for det er ikke øvrighet uten av Gud, men de som er, de er innsatt av Gud, 
45:13:2: så at den som setter sig imot øvrigheten, står Guds ordning imot; men de som står imot, skal få sin dom. 
45:13:3: For de styrende er ikke til redsel for den gode gjerning, men for den onde. Men vil du slippe å frykte for øvrigheten? Gjør det som godt er, så skal du ha ros av den; 
45:13:4: for den er Guds tjener, dig til gode. Men gjør du det som ondt er, da frykt! for den bærer ikke sverdet for intet; for den er Guds tjener, en hevner til straff over den som gjør det som ondt er. 
45:13:5: Derfor er det nødvendig å være lydig mot den, ikke bare for straffens skyld, men også for samvittighetens. 
45:13:6: Derfor betaler I jo også skatt; for de er Guds tjenere, som nettop tar vare på dette. 
45:13:7: Gi alle det I er dem skyldige: den skatt som skatt tilkommer, den toll som toll tilkommer, den frykt som frykt tilkommer, den ære som ære tilkommer! 
45:13:8: Bli ingen noget skyldige, uten det å elske hverandre! for den som elsker den annen, har opfylt loven. 
45:13:9: For det ord: Du skal ikke drive hor, du skal ikke slå ihjel, du skal ikke stjele, du skal ikke begjære, og hvad andre bud det kan være, det samles til ett i dette ord: Du skal elske din næste som dig selv. 
45:13:10: Kjærligheten gjør ikke næsten noget ondt; derfor er kjærligheten lovens opfyllelse. 
45:13:11: Og dette må vi gjøre, da vi kjenner tiden, at timen er kommet da vi skal våkne op av søvne; for frelsen er oss nærmere nu enn dengang vi kom til troen. 
45:13:12: Det lider med natten, og det stunder til dag; la oss derfor avlegge mørkets gjerninger, men iklæ oss lysets våben! 
45:13:13: La oss vandre sømmelig, som om dagen, ikke i svir og drikk, ikke i løsaktighet og skamløshet, ikke i kiv og avind, 
45:13:14: men iklæ eder den Herre Jesus Kristus, og bær ikke således omsorg for kjødet at det vekkes begjærligheter! 
45:14:1: Ta eder av den som er svak i troen, uten å sette eder til doms over hans tanker! 
45:14:2: Den ene har tro til å ete alt, men den som er svak, eter bare urter; 
45:14:3: den som eter, ringeakte ikke den som ikke eter, og den som ikke eter, dømme ikke den som eter! for Gud har jo tatt sig av ham. 
45:14:4: Hvem er du som dømmer en fremmed svenn? Han står eller faller for sin egen herre; dog, han skal bli stående; for Herren er mektig til å holde ham oppe. 
45:14:5: Den ene akter en dag fremfor en annen, den andre akter alle dager like; enhver være fullt sikker i sitt eget sinn! 
45:14:6: Den som akter på dagen, gjør det for Herren, og den som ikke akter på dagen, gjør det for Herren. Og den som eter, gjør det for Herren, for han takker Gud; og den som ikke eter, gjør det for Herren og takker Gud. 
45:14:7: For ingen av oss lever for sig selv, og ingen dør for sig selv; 
45:14:8: for om vi lever, så lever vi for Herren, og om vi dør, så dør vi for Herren; enten vi da lever eller vi dør, hører vi Herren til. 
45:14:9: For derfor døde Kristus og blev levende igjen at han skulde være herre både over levende og over døde. 
45:14:10: Men du, hvorfor dømmer du din bror? eller du, hvorfor ringeakter du din bror? vi skal jo alle stilles frem for Guds domstol. 
45:14:11: For det er skrevet: Så sant jeg lever, sier Herren, for mig skal hvert kne bøie sig, og hver tunge skal prise Gud. 
45:14:12: Så skal da hver av oss gjøre Gud regnskap for sig selv. 
45:14:13: Derfor, la oss ikke mere dømme hverandre, men fell heller den dom at I ikke skal legge anstøt eller felle for eders bror! 
45:14:14: Jeg vet og er viss på det i den Herre Jesus at ingen ting er uren i sig selv; men for den som holder noget for urent, for ham er det urent. 
45:14:15: For dersom det voldes din bror sorg ved din mat, da vandrer du ikke lenger i kjærlighet; før ikke ved din mat den i fortapelse som Kristus er død for! 
45:14:16: La da ikke eders gode bli spottet! 
45:14:17: Guds rike består jo ikke i å ete og drikke, men i rettferdighet og fred og glede i den Hellige Ånd; 
45:14:18: for den som heri tjener Kristus, han er velbehagelig for Gud og tekkelig for mennesker. 
45:14:19: La oss derfor strebe efter det som tjener til fred og til innbyrdes opbyggelse! 
45:14:20: Nedbryt ikke Guds verk for mats skyld! Alt er vel rent, men det er ondt for det menneske som eter med samvittighets-anstøt; 
45:14:21: det er godt ikke å ete kjøtt eller drikke vin eller gjøre noget som din bror støter sig ved. 
45:14:22: Du har tro? Ha den hos dig selv, for Gud! Salig er den som ikke dømmer sig selv i det han velger; 
45:14:23: men den som tviler - dersom han eter, så er han dømt, fordi det ikke er gjort av tro; alt som ikke er av tro, er synd. 
45:15:1: Vi som er sterke, er skyldige til å bære de svakes skrøpeligheter og ikke være oss selv til behag; 
45:15:2: enhver av oss være sin næste til behag, til hans gagn, til opbyggelse! 
45:15:3: For Kristus levde heller ikke sig selv til behag, men, som skrevet er: Deres hån som hånte dig, falt på mig. 
45:15:4: For alt som før er skrevet, det er skrevet oss til lærdom, forat vi skal ha håp ved det tålmod og den trøst som skriftene gir. 
45:15:5: Men tålmodets og trøstens Gud gi eder å ha ett sinn innbyrdes efter Kristi Jesu forbillede, 
45:15:6: så at I enige, med én munn, kan prise Gud og vår Herre Jesu Kristi Fader! 
45:15:7: Derfor ta eder av hverandre, likesom og Kristus tok sig av eder til Guds ære! 
45:15:8: Jeg mener: Kristus er blitt en tjener for de omskårne for Guds sanndruhets skyld, for å stadfeste løftene til fedrene, 
45:15:9: men hedningene skal prise Gud for miskunnhet, som skrevet er: Derfor vil jeg prise dig iblandt hedninger og lovsynge ditt navn. 
45:15:10: Og atter sier Skriften: Gled eder, I hedninger, sammen med hans folk! 
45:15:11: Og atter: Lov Herren, alle hedninger, og pris ham, alle folk! 
45:15:12: Og atter sier Esaias: Det skal komme det Isais rotskudd, han som reiser sig for å herske over hedninger; på ham skal hedningene håpe. 
45:15:13: Men håpets Gud fylle eder med all glede og fred i eders tro, så I kan være rike på håp ved den Hellige Ånds kraft! 
45:15:14: Men også jeg, mine brødre, har den visse tro om eder at I av eder selv er fulle av godhet, fylt med all kunnskap, i stand til også å formane hverandre; 
45:15:15: men allikevel har jeg til dels skrevet eder noget djervt til for atter å påminne eder, efter den nåde som er mig gitt av Gud, 
45:15:16: at jeg skal være Kristi Jesu offerprest for hedningene, idet jeg prestelig forvalter Guds evangelium, forat hedningene kan bli et velbehagelig offer, helliget ved den Hellige Ånd. 
45:15:17: Derfor har jeg min ros i Kristus Jesus, i min tjeneste for Gud; 
45:15:18: for jeg vil ikke driste mig til å tale om annet enn det som Kristus har virket ved mig for å føre hedningene til lydighet, ved ord og gjerning, 
45:15:19: ved tegns og underes kraft, ved Åndens kraft, så at jeg fra Jerusalem og rundt omkring like til Illyria har fullt kunngjort Kristi evangelium, 
45:15:20: dog så at jeg satte min ære i å forkynne evangeliet, ikke der hvor Kristus allerede var nevnt, forat jeg ikke skulde bygge på fremmed grunnvoll, 
45:15:21: men, som skrevet er: De som ikke har fått budskap om ham, skal se, og de som ikke har hørt, skal forstå. 
45:15:22: Derved især er jeg blitt hindret fra å komme til eder; 
45:15:23: men nu, da jeg ikke lenger har rum i disse land, men i mange år har hatt lengsel efter å komme til eder, 
45:15:24: håper jeg å få se eder på gjennemreisen når jeg drar til Spania, og få følge av eder dit når jeg først i nogen mon har hatt godt av eder. 
45:15:25: Men nu drar jeg til Jerusalem i de helliges tjeneste. 
45:15:26: For Makedonia og Akaia har villet gjøre et sammenskudd for de fattige blandt de hellige i Jerusalem. 
45:15:27: De har så villet, og de står også i gjeld til dem; for har hedningene fått del i deres åndelige goder, da er de også skyldige til å tjene dem med de timelige. 
45:15:28: Når jeg da har fullført dette og lagt denne frukt i deres hender, vil jeg dra derfra gjennem eders by til Spania, 
45:15:29: og jeg vet at når jeg kommer til eder, skal jeg komme med en fylde av Kristi velsignelse. 
45:15:30: Men jeg formaner eder, brødre, ved vår Herre Jesus Kristus og ved Åndens kjærlighet at I strider sammen med mig i eders bønner for mig til Gud, 
45:15:31: forat jeg må utfries fra de vantro i Judea, og mitt ærend til Jerusalem må tekkes de hellige, 
45:15:32: så jeg kan komme til eder med glede, om Gud så vil, og få vederkvege mig sammen med eder. 
45:15:33: Fredens Gud være med eder alle! Amen. 
45:16:1: Jeg anbefaler eder Føbe, vår søster, som er menighets-tjenerinne i Kenkreæ, 
45:16:2: at I tar imot henne i Herren, som det sømmer sig for de hellige, og går henne til hånde i alt som hun måtte trenge eders hjelp i; for hun har også gått mange til hånde, ja mig selv. 
45:16:3: Hils Priska og Akvilas, mine medarbeidere i Kristus Jesus, 
45:16:4: de som har våget sitt eget liv for mitt, og som ikke alene jeg takker, men også alle menigheter av hedningene, 
45:16:5: og hils menigheten i deres hus. Hils Epenetus, min elskede, som er Asias førstegrøde for Kristus. 
45:16:6: Hils Maria, som har arbeidet meget for eder. 
45:16:7: Hils Andronikus og Junias, mine frender og mine medfanger, de som har et godt navn blandt apostlene, de som også før mig er kommet til Kristus. 
45:16:8: Hils Amplias, min elskede i Herren. 
45:16:9: Hils Urbanus, vår medarbeider i Kristus, og Stakys, min elskede. 
45:16:10: Hils Apelles, den prøvede i Kristus. Hils dem av Aristobulus' hus. 
45:16:11: Hils Herodion, min frende. Hils dem av Narkissus' hus som er i Herren. 
45:16:12: Hils Tryfena og Tryfosa, som har arbeidet i Herren. Hils Persis, den elskede, som har arbeidet meget i Herren. 
45:16:13: Hils Rufus, den utvalgte i Herren, og hans og min mor. 
45:16:14: Hils Asynkritus, Flegon, Hermes, Patrobas, Hermas, og brødrene hos dem. 
45:16:15: Hils Filologus og Julia, Nereus og hans søster og Olympas, og alle de hellige hos dem. 
45:16:16: Hils hverandre med et hellig kyss! Alle Kristi menigheter hilser eder. 
45:16:17: Men jeg formaner eder, brødre, til å holde øie med dem som volder tvedrakten og anstøtene imot den lære som I har lært, og gå av veien for dem; 
45:16:18: for disse tjener ikke vår Herre Jesus Kristus, men sin egen buk, og ved sin søte tale og sine fagre ord dårer de de enfoldiges hjerter. 
45:16:19: For ordet om eders lydighet er kommet ut til alle; derfor gleder jeg mig over eder, men jeg ønsker at I skal være vise til det gode og rene for det onde. 
45:16:20: Men fredens Gud skal i hast knuse Satan under eders føtter. Vår Herre Jesu Kristi nåde være med eder! 
45:16:21: Timoteus, min medarbeider, og Lukius og Jason og Sosipater, mine frender, hilser eder. 
45:16:22: Jeg Tertius, som nedskriver brevet, hilser eder i Herren. 
45:16:23: Gajus, min og hele menighetens vert, hilser eder. Erastus, byens regnskapsfører, og broderen Kvartus hilser eder. 
45:16:24: Vår Herre Jesu Kristi nåde være med eder alle! Amen. 
45:16:25: Men ham som er mektig til å styrke eder efter mitt evangelium og Jesu Kristi forkynnelse, efter åpenbaringen av den hemmelighet som har vært fortidd i evige tider, 
45:16:26: men nu er kommet for schmelken og ved profetiske skrifter efter den evige Guds befaling kunngjort for alle folk for å virke troens lydighet, 
45:16:27: ham, den ene vise Gud ved Jesus Kristus, være æren i all evighet! Amen. 
46:1:1: Paulus, ved Guds vilje kalt til Kristi Jesu apostel, og broderen Sostenes 
46:1:2: - til den Guds menighet som er i Korint, helligede i Kristus Jesus, kalte, hellige, tillikemed alle dem som på ethvert sted påkaller vår Herre Jesu Kristi navn, deres og vår: 
46:1:3: Nåde være med eder og fred fra Gud vår Fader og den Herre Jesus Kristus! 
46:1:4: Jeg takker alltid min Gud for eder, for den Guds nåde som er eder gitt i Kristus Jesus, 
46:1:5: at I i ham er gjort rike på alt, på all lære og all kunnskap, 
46:1:6: likesom Kristi vidnesbyrd er blitt rotfestet i eder, 
46:1:7: så at det ikke fattes eder på nogen nådegave mens I venter på vår Herre Jesu Kristi åpenbarelse, 
46:1:8: han som også skal styrke eder inntil enden, så I må være ulastelige på vår Herre Jesu Kristi dag. 
46:1:9: Gud er trofast, han ved hvem I blev kalt til samfund med hans Sønn, Jesus Kristus, vår Herre. 
46:1:10: Men jeg formaner eder, brødre, ved vår Herre Jesu Kristi navn at I alle må føre den samme tale, og at det ikke må være splid iblandt eder, men at I må være fast forenet i samme sinn og i samme mening. 
46:1:11: For det er av Kloes folk blitt mig fortalt om eder, mine brødre, at det er tretter iblandt eder. 
46:1:12: Jeg mener dette at enhver av eder sier: Jeg holder mig til Paulus; jeg til Apollos; jeg til Kefas; jeg til Kristus. 
46:1:13: Er Kristus blitt delt? var det Paulus som blev korsfestet for eder, eller var det til Paulus' navn I blev døpt? 
46:1:14: Jeg takker Gud at jeg ikke har døpt nogen av eder uten Krispus og Gajus, 
46:1:15: for at ikke nogen skal si at I blev døpt til mitt navn. 
46:1:16: Dog har jeg også døpt Stefanas' hus; ellers vet jeg ikke av at jeg har døpt nogen annen. 
46:1:17: For Kristus har ikke utsendt mig for å døpe, men for å forkynne evangeliet, ikke med vise ord, for at Kristi kors ikke skulde tape sin kraft. 
46:1:18: For ordet om korset er vel en dårskap for dem som går fortapt, men for oss som blir frelst, er det en Guds kraft; 
46:1:19: for det er skrevet: Jeg vil ødelegge de vises visdom, og de forstandiges forstand vil jeg gjøre til intet. 
46:1:20: Hvor er en vismann, hvor er en skriftlærd, hvor er en gransker i denne verden? har ikke Gud gjort verdens visdom til dårskap? 
46:1:21: For eftersom verden ikke ved sin visdom kjente Gud i Guds visdom, var det Guds vilje ved forkynnelsens dårskap å frelse dem som tror, 
46:1:22: eftersom både jøder krever tegn og grekere søker visdom, 
46:1:23: men vi forkynner Kristus korsfestet, for jøder et anstøt og for hedninger en dårskap, 
46:1:24: men for dem som er kalt, både jøder og grekere, forkynner vi Kristus, Guds kraft og Guds visdom. 
46:1:25: For Guds dårskap er visere enn menneskene, og Guds svakhet er sterkere enn menneskene. 
46:1:26: For legg merke til eders kall, brødre, at ikke mange vise efter kjødet blev kalt, ikke mange mektige, ikke mange høibårne; 
46:1:27: men det som er dårlig i verden, det utvalgte Gud sig for å gjøre de vise til skamme, og det som er svakt i verden, det utvalgte Gud sig for å gjøre det sterke til skamme, 
46:1:28: og det som er lavt i verden, og det som er ringeaktet, det utvalgte Gud sig, det som ingenting er, for å gjøre til intet det som er noget, 
46:1:29: forat intet kjød skal rose sig for Gud. 
46:1:30: Men av ham er I i Kristus Jesus, som er blitt oss visdom fra Gud og rettferdighet og helliggjørelse og forløsning, 
46:1:31: forat, som skrevet er: Den som roser sig, han rose sig i Herren! 
46:2:1: Og jeg, da jeg kom til eder, brødre, kom jeg ikke med mesterskap i tale eller i visdom og forkynte eder Guds vidnesbyrd; 
46:2:2: for jeg vilde ikke vite noget iblandt eder uten Jesus Kristus og ham korsfestet. 
46:2:3: Og jeg var hos eder i skrøpelighet og i frykt og i megen beven, 
46:2:4: og min tale og min forkynnelse var ikke med visdoms overtalende ord, men med Ånds og krafts bevis, 
46:2:5: forat eders tro ikke skulde være grunnet på menneskers visdom, men på Guds kraft. 
46:2:6: Dog, visdom taler vi blandt de fullkomne, men en visdom som ikke tilhører denne verden eller denne verdens herrer, de som forgår; 
46:2:7: men som en hemmelighet taler vi Guds visdom, den skjulte, som Gud fra evighet av har forut bestemt til vår herlighet, 
46:2:8: den som ingen av denne verdens herrer kjente; for hadde de kjent den, da hadde de ikke korsfestet herlighetens herre; 
46:2:9: men, som skrevet er: Hvad øie ikke så og øre ikke hørte, og hvad ikke opkom i noget menneskes hjerte, hvad Gud har beredt for dem som elsker ham. 
46:2:10: Men oss har Gud åpenbaret det ved sin Ånd. For Ånden ransaker alle ting, også dybdene i Gud; 
46:2:11: for hvem iblandt mennesker vet hvad som bor i mennesket, uten menneskets ånd, som er i ham? Således vet heller ingen hvad som bor i Gud, uten Guds Ånd; 
46:2:12: men vi har ikke fått verdens ånd, vi har fått den Ånd som er av Gud, forat vi skal kjenne det som er gitt oss av Gud, 
46:2:13: det som vi også taler om, ikke med ord som menneskelig visdom lærer, men med ord som Ånden lærer, idet vi tolker åndelige ting med åndelige ord. 
46:2:14: Men et naturlig menneske tar ikke imot det som hører Guds Ånd til; for det er ham en dårskap, og han kan ikke kjenne det, for det dømmes åndelig; 
46:2:15: men den åndelige dømmer alt, men selv dømmes han av ingen; 
46:2:16: for hvem har kjent Herrens sinn, så at han skulde lære ham? men vi har Kristi sinn. 
46:3:1: Og jeg, brødre, kunde ikke tale til eder som til åndelige, men bare som til kjødelige, som til småbarn i Kristus. 
46:3:2: Jeg gav eder melk å drikke og ikke fast føde; for I tålte den enda ikke. Ja, I tåler den ikke ennu, 
46:3:3: I er jo ennu kjødelige. For når det er avind og trette iblandt eder, er I da ikke kjødelige og vandrer på menneskelig vis? 
46:3:4: For når en sier: Jeg holder mig til Paulus, og en annen: Jeg til Apollos, er I da ikke mennesker? 
46:3:5: Hvad er da Apollos? eller hvad er Paulus? Tjenere ved hvem I kom til troen, og det efter som Herren gav enhver. 
46:3:6: Jeg plantet, Apollos vannet, men Gud gav vekst; 
46:3:7: derfor er hverken den noget som planter, eller den som vanner, men Gud som gir vekst. 
46:3:8: Men den som planter, og den som vanner, er ett; dog skal enhver av dem få sin egen lønn efter sitt eget arbeid. 
46:3:9: For vi er Guds medarbeidere; I er Guds akerland, Guds bygning. 
46:3:10: Efter den Guds nåde som er mig gitt, har jeg lagt grunnvoll som en vis byggmester, og en annen bygger videre; men enhver se til hvorledes han bygger videre! 
46:3:11: For ingen kan legge en annen grunnvoll enn den som er lagt, det er Jesus Kristus. 
46:3:12: Men om nogen på denne grunnvoll bygger med gull, sølv, kostelige stener, tre, høi, strå, 
46:3:13: da skal enhvers verk bli åpenbart; for dagen skal vise det, for den åpenbares med ild, og hvordan enhvers verk er, det skal ilden prøve. 
46:3:14: Om det verk som en har bygget, står sig, da skal han få lønn; 
46:3:15: om ens verk brenner op, da skal han tape lønnen, men selv skal han bli frelst, dog således som gjennem ild. 
46:3:16: Vet I ikke at I er Guds tempel, og at Guds Ånd bor i eder? 
46:3:17: Om nogen ødelegger Guds tempel, ham skal Gud ødelegge; for Guds tempel er hellig, og det er I. 
46:3:18: Ingen dåre sig selv! Om nogen iblandt eder tykkes sig å være vis i denne verden, han bli en dåre, forat han kan bli vis; 
46:3:19: for denne verdens visdom er dårskap for Gud. For det er skrevet: Han fanger de vise i deres kløkt, 
46:3:20: og atter: Herren kjenner de vises tanker, at de er tomme. 
46:3:21: Derfor rose ingen sig av mennesker! for alt hører eder til, 
46:3:22: enten det er Paulus eller Apollos eller Kefas, enten det er verden eller liv eller død, enten det er det som nu er, eller det som komme skal - alt hører eder til, 
46:3:23: men I hører Kristus til, og Kristus hører Gud til. 
46:4:1: Således akte I oss som Kristi tjenere og husholdere over Guds hemmeligheter! 
46:4:2: For øvrig kreves det av husholdere at de må finnes tro. 
46:4:3: Men for mig har det lite å si å dømmes av eder eller av en menneskelig domstol; ja, jeg dømmer mig ikke engang selv; 
46:4:4: for vel vet jeg intet med mig selv, men dermed er jeg ikke rettferdiggjort; men den som dømmer mig, er Herren. 
46:4:5: Døm derfor ikke noget før tiden, før Herren kommer, han som også skal føre frem for schmelken det som har vært skjult i mørket, og åpenbare hjertenes råd; og da skal enhver få sin ros av Gud. 
46:4:6: Dette, brødre, har jeg overført på mig selv og Apollos for eders skyld, forat I ved oss må lære ikke å gå ut over det som skrevet er, forat ingen av eder skal bli opblåst, for den ene mot den andre. 
46:4:7: For hvem gir vel dig fortrin? Og hvad har du, som du ikke har fått? Men om du nu har fått noget, hvorfor roser du dig da som om du ikke hadde fått det? 
46:4:8: I er alt blitt mette; I er alt blitt rike; uten oss er I blitt herrer! Ja, gid I var blitt herrer, så vi kunde herske sammen med eder! 
46:4:9: For mig tykkes at Gud har vist oss apostler frem som de ringeste, som dødsdømte; for et skuespill er vi blitt for verden, både for engler og for mennesker. 
46:4:10: Vi er dårer for Kristi skyld, men I er kloke i Kristus; vi er skrøpelige, men I er sterke; I er hedret, men vi er æreløse. 
46:4:11: Like til denne stund er vi både hungrige og tørste og nakne og mishandlet og hjemløse og mødige, 
46:4:12: idet vi arbeider med våre egne hender; vi blir utskjelt - og vi velsigner; vi blir forfulgt - og vi tåler det; 
46:4:13: Vi blir spottet - og vi formaner; vi er blitt som utskudd i verden, en vemmelse for alle, inntil nu. 
46:4:14: Jeg skriver ikke dette for å vanære eder, men for å påminne eder som mine elskede barn. 
46:4:15: For om I og har ti tusen læremestere i Kristus, så har I dog ikke mange fedre; for jeg har avlet eder i Kristus Jesus ved evangeliet. 
46:4:16: Jeg formaner eder derfor: Bli mine efterfølgere! 
46:4:17: Derfor har jeg sendt Timoteus til eder, han som er mitt elskede og trofaste barn i Herren, forat han skal minne eder om mine veier i Kristus, således som jeg lærer overalt i hver menighet. 
46:4:18: Men somme er blitt opblåst, i den tanke at jeg ikke skulde komme til eder; 
46:4:19: men jeg kommer snart til eder, om Herren vil, og får da lære å kjenne ikke ordene, men kraften hos dem som er opblåst; 
46:4:20: for Guds rike består ikke i ord, men i kraft. 
46:4:21: Hvad vil I? skal jeg komme til eder med ris, eller med kjærlighet og saktmodighets ånd? 
46:5:1: I det hele høres det om hor iblandt eder, og det slikt hor som ikke engang nevnes blandt hedningene: at en holder sig til sin fars hustru. 
46:5:2: Og I er opblåst, mens I langt heller skulde sørge, så den som har gjort denne gjerning, kunde bli støtt ut fra eder. 
46:5:3: For jeg for min del, som vel er fraværende med legemet, men nærværende med ånden, har allerede, som om jeg var nærværende, felt den dom over den som har gjort dette, 
46:5:4: at han skal I vår Herre Jesu navn, idet I og min ånd samles i vår Herre Jesu kraft, 
46:5:5: overgis til Satan til kjødets ødeleggelse, forat hans ånd kan bli frelst på den Herre Jesu dag. 
46:5:6: Det er ikke smukt det som I roser eder av. Vet I ikke at en liten surdeig syrer hele deigen? 
46:5:7: Rens derfor ut den gamle surdeig, så I kan være ny deig, likesom I er usyrede! for vårt påskelam er jo slaktet: Kristus. 
46:5:8: La oss derfor holde høitid, ikke med gammel surdeig eller med ondskaps og ugudelighets surdeig, men med renhets og sannhets usyrede brød! 
46:5:9: Jeg skrev til eder i mitt brev at I ikke skulde ha omgang med horkarler - 
46:5:10: jeg mente ikke i almindelighet horkarlene i denne verden eller de havesyke og røverne eller avgudsdyrkerne, ellers måtte I jo gå ut av verden; 
46:5:11: men det jeg skrev til eder, var at I ikke skulde ha omgang med nogen som kalles en bror og er en horkarl eller havesyk eller avgudsdyrker eller baktaler eller dranker eller røver, så I ikke engang eter sammen med ham. 
46:5:12: For hvad har vel jeg med å dømme dem som er utenfor? dømmer ikke også I bare dem som er innenfor? 
46:5:13: men dem som er utenfor, skal Gud dømme. Støt da den onde ut fra eder! 
46:6:1: Våger nogen av eder, når han har sak mot sin næste, da å søke dom hos de urettferdige og ikke hos de hellige? 
46:6:2: Eller vet I ikke at de hellige skal dømme verden? og dersom verden blir dømt ved eder, er I da uverdige til å dømme i de ringeste saker? 
46:6:3: Vet I ikke at vi skal dømme engler? hvor meget mere da i timelige ting! 
46:6:4: Når I da har saker om timelige ting, så setter I dem til dommere som ikke aktes for noget i menigheten! 
46:6:5: Til skam for eder sier jeg det. Så finnes det da ikke nogen vis mann iblandt eder, ikke en eneste én, som kan skille tretten for sin bror? 
46:6:6: men bror fører sak mot bror, og det for vantros domstol! 
46:6:7: Det er jo i det hele allerede et tap for eder at I har saker mot hverandre. Hvorfor lider I ikke heller urett? hvorfor tåler I ikke heller skade? 
46:6:8: Men I gjør urett og skade, og det mot brødre! 
46:6:9: Eller vet I ikke at de som gjør urett, ikke skal arve Guds rike? Far ikke vill! Hverken horkarler eller avgudsdyrkere eller ekteskapsbrytere eller bløtaktige eller de som synder mot naturen, 
46:6:10: eller tyver eller havesyke eller drankere eller baktalere eller røvere skal arve Guds rike. 
46:6:11: Og således var det med somme av eder; men I har latt eder avtvette, I er blitt helliget, I er blitt rettferdiggjort i den Herre Jesu navn og i vår Guds Ånd. 
46:6:12: Jeg har lov til alt - men ikke alt gagner; jeg har lov til alt - men jeg skal ikke la noget få makten over mig. 
46:6:13: Maten er for buken, og buken for maten; og Gud skal gjøre ende på dem begge. Men legemet er ikke for hor, men for Herren, og Herren for legemet; 
46:6:14: og Gud har opvakt Herren og skal også opvekke oss ved sin kraft. 
46:6:15: Vet I ikke at eders legemer er Kristi lemmer? skal jeg da ta Kristi lemmer og gjøre dem til en skjøges lemmer? Langt derifra! 
46:6:16: Eller vet I ikke at den som holder sig til skjøgen, er ett legeme med henne? For det er sagt: De to skal være ett kjød. 
46:6:17: Men den som holder sig til Herren, er én ånd med ham. 
46:6:18: Fly hor! Enhver synd som et menneske kan gjøre, er utenfor legemet; men den som driver hor, synder mot sitt eget legeme. 
46:6:19: Eller vet I ikke at eders legeme er et tempel for den Hellige Ånd, som bor i eder, og som I har fra Gud, og at I ikke hører eder selv til? 
46:6:20: for I er dyrt kjøpt. Ær da Gud i eders legeme! 
46:7:1: Men vedkommende det som I skrev om, da er det godt for et menneske ikke å røre en kvinne; 
46:7:2: men for hors skyld skal hver mann ha sin egen hustru, og hver kvinne sin egen mann. 
46:7:3: Mannen gjøre sin skyldighet mot hustruen, og likeså hustruen mot mannen; 
46:7:4: hustruen råder ikke over sitt eget legeme, men mannen; likeså råder heller ikke mannen over sitt eget legeme, men hustruen. 
46:7:5: Hold eder ikke fra hverandre uten efter samråd, for en tid, for å leve i bønn, og kom så sammen igjen, forat ikke Satan skal friste eder, fordi I ikke makter å være avholdende! 
46:7:6: Dette sier jeg som tillatelse, ikke som påbud; 
46:7:7: jeg ønsker at alle mennesker var som jeg; men hver har sin egen nådegave av Gud, den ene så, den andre så. 
46:7:8: Til de ugifte og til enkene sier jeg: Det er godt for dem om de vedblir å være som jeg; 
46:7:9: men kan de ikke være avholdende, da la dem gifte sig! for det er bedre å gifte sig enn å lide brynde. 
46:7:10: De gifte byder jeg, dog ikke jeg, men Herren, at en hustru ikke skal skille sig fra sin mann; 
46:7:11: men er hun skilt fra ham, da vedbli hun å være ugift eller forlike sig med sin mann - og at en mann ikke skal skille sig fra sin hustru. 
46:7:12: Til de andre sier jeg, ikke Herren: Dersom en bror har en vantro hustru, og hun samtykker i å bo hos ham, da skille han sig ikke fra henne! 
46:7:13: og om en hustru har en vantro mann, og denne samtykker i å bo hos henne, da skille hun sig ikke fra sin mann! 
46:7:14: For den vantro mann er helliget ved sin hustru, og den vantro hustru er helliget ved broren; ellers var jo eders barn urene, men nu er de hellige. 
46:7:15: Men dersom den vantro skiller sig, da får han så gjøre; broren eller søsteren er ikke trælbundet i slike ting, men Gud har kalt oss til fred. 
46:7:16: For hvad vet du, hustru, om du kan frelse din mann? eller hvad vet du, mann, om du kan frelse din hustru? 
46:7:17: Dog vandre hver således som Herren har gitt ham, som Gud har kalt ham! Og således foreskriver jeg i alle menigheter. 
46:7:18: En blev kalt som omskåret, han dra ikke forhud over; en er blitt kalt som uomskåret, han la sig ikke omskjære! 
46:7:19: Det kommer ikke an på omskjærelse, og det kommer ikke an på forhud, men på å holde Guds bud. 
46:7:20: Hver bli i det kall han blev kalt i! 
46:7:21: Blev du kalt som træl, da gjør dig ingen sorg av det; men kan du også bli fri, så gjør heller bruk derav! 
46:7:22: For den træl som er kalt i Herren, er Herrens frigitte; likeså er også den frie som er kalt, Kristi træl. 
46:7:23: I er dyrt kjøpt; bli ikke menneskers træler! 
46:7:24: I den stand enhver blev kalt i, brødre, i den bli han hos Gud! 
46:7:25: Om jomfruene har jeg ikke noget bud av Herren, men jeg sier min mening som en som har fått miskunn av Herren til å være troverdig. 
46:7:26: Jeg mener da dette at det for den nærværende nøds skyld er godt for et menneske å leve således. 
46:7:27: Er du bundet til en kvinne, da søk ikke å bli løst fra henne; er du ikke bundet til en kvinne, da søk ikke en kvinne! 
46:7:28: Men om du også gifter dig, synder du ikke, og om jomfruen gifter sig, synder hun ikke; men de som det gjør, vil få trengsel for sitt kjød, jeg derimot vil spare eder. 
46:7:29: Men dette sier jeg, brødre: Tiden er kort, så at herefter de som har hustruer, skal være som de som ingen har, 
46:7:30: og de som gråter, som de som ikke gråter, og de som gleder sig, som de som ikke gleder sig, og de som kjøper, som de som ikke eier noget, 
46:7:31: og de som bruker verden, som de som ikke bruker den; for denne verdens skikkelse forgår. 
46:7:32: Jeg vil gjerne at I skal være fri for omsorg. Den ugifte har omsorg for det som hører Herren til, hvorledes han kan tekkes Herren; 
46:7:33: men den gifte har omsorg for det som hører verden til, hvorledes han kan tekkes sin hustru. 
46:7:34: Og det er forskjell på hustruen og jomfruen; den ugifte kvinne har omsorg for det som hører Herren til, at hun kan være hellig både på legeme og ånd; men den gifte kvinne har omsorg for det som hører verden til, hvorledes hun kan tekkes sin mann. 
46:7:35: Dette sier jeg til eders eget gagn, ikke for å sette en snare for eder, men for å fremme det som sømmer sig: å henge fast ved Herren. 
46:7:36: Men dersom nogen mener at han gjør urett mot sin ugifte datter om hun er over ungdomsalderen, og det må så være, han gjøre det han vil; han synder ikke; la dem gifte sig! 
46:7:37: Men den som står fast i sitt hjerte og ikke har noget som tvinger ham, men har frihet til å følge sin egen vilje og har satt sig dette fore i sitt hjerte at han vil holde sin datter ugift, han gjør vel. 
46:7:38: Så gjør da den vel som bortgifter, og den gjør bedre som ikke bortgifter. 
46:7:39: En hustru er bundet så lenge hennes mann lever; men når hennes mann er hensovet, da har hun frihet til å gifte sig med hvem hun vil, bare det skjer i Herren. 
46:7:40: Men lykkeligere er hun om hun blir som hun er, efter min mening; men jeg tror også å ha Guds Ånd. 
46:8:1: Men vedkommende avgudsofferne, da vet vi at vi alle har kunnskap. Kunnskapen opblåser, men kjærligheten opbygger; 
46:8:2: om nogen tykkes sig å kjenne noget, han har aldri kjent noget således som en bør kjenne det; 
46:8:3: men om nogen elsker Gud, han er kjent av ham. 
46:8:4: Hvad nu det vedkommer å ete av avguds-offerne, da vet vi at ingen avgud i verden er til, og at det er ingen Gud uten én. 
46:8:5: For om det også er såkalte guder, enten i himmelen eller på jorden - som det jo er mange guder og mange herrer - 
46:8:6: så er det dog for oss bare én Gud, Faderen, av hvem alt er, og vi til ham, og én Herre, Jesus Kristus, ved hvem alt er, og vi ved ham. 
46:8:7: Dog, den kunnskap er ikke hos alle; men somme gjør sig ennu samvittighet for avgudens skyld og eter det derfor som avguds-offer, og deres samvittighet, som er skrøpelig, blir uren. 
46:8:8: Men mat gir oss jo ikke verd for Gud; hverken vinner vi noget om vi eter, eller taper noget om vi ikke eter. 
46:8:9: Men se til at ikke denne eders frihet blir til anstøt for de skrøpelige! 
46:8:10: For dersom nogen ser dig som har kunnskap, sitte til bords i avgudshuset, vil da ikke samvittigheten hos ham som er skrøpelig, få dristighet til å ete avgudsofferet? 
46:8:11: da går jo den skrøpelige fortapt for din kunnskaps skyld, den bror for hvem Kristus er død! 
46:8:12: Men når I således synder mot eders brødre og sårer deres skrøpelige samvittighet, da synder I mot Kristus. 
46:8:13: Derfor, om mat volder min bror anstøt, da vil jeg aldri i evighet ete kjøtt, for ikke å volde min bror anstøt. 
46:9:1: Er jeg ikke fri? er jeg ikke apostel? har jeg ikke sett Jesus, vår Herre? er ikke I mitt verk i Herren? 
46:9:2: Er jeg ikke apostel for andre, så er jeg det i det minste for eder; for I er innseglet på mitt apostel-embede i Herren. 
46:9:3: Dette er mitt forsvar mot dem som dømmer mig. 
46:9:4: Har vi ikke rett til å ete og drikke? 
46:9:5: Har vi ikke rett til å føre en søster med oss som hustru, likesom de andre apostler og Herrens brødre og Kefas? 
46:9:6: Eller er det bare jeg og Barnabas som ikke har rett til å slippe å arbeide? 
46:9:7: Hvem gjør vel nogensinne krigstjeneste på egen lønning? hvem planter en vingård og eter ikke av dens frukt? eller hvem før en hjord og eter ikke av hjordens melk? 
46:9:8: Taler jeg dette bare på menneskelig vis, eller sier ikke også loven dette? 
46:9:9: I Mose lov er det jo skrevet: Du skal ikke binde munnen til på en okse som tresker. Er det oksene som ligger Gud på hjerte, 
46:9:10: eller sier han ikke dette bare for vår skyld? For vår skyld er det jo skrevet at den som pløier, skal pløie med håp, og den som tresker, skal gjøre det med håp om å få sin del. 
46:9:11: Har vi sådd for eder de åndelige goder, er det da noget stort om vi høster eders timelige goder? 
46:9:12: Har andre rett over eder, skulde da ikke vi meget mere ha det? Men denne rett har vi ikke brukt; vi tåler alt, for ikke å legge nogen hindring for Kristi evangelium. 
46:9:13: Vet I ikke at de som gjør tjeneste i templet, får sin føde av templet, og de som tjener ved alteret, deler med alteret? 
46:9:14: Så har også Herren fastsatt for dem som forkynner evangeliet, at de skal leve av evangeliet. 
46:9:15: Men jeg har ikke gjort bruk av noget av dette. Jeg skriver ikke dette forat det skal bli så med mig; for heller vil jeg dø enn at nogen skulde gjøre det til intet som jeg roser mig av. 
46:9:16: For om jeg forkynner evangeliet, er det ikke noget å rose mig av; det er en nødvendighet som påligger mig; for ve mig om jeg ikke forkynner evangeliet! 
46:9:17: For gjør jeg dette frivillig, da har jeg lønn; men gjør jeg det nødtvunget, da er det en husholdning som er mig betrodd. 
46:9:18: Hvad er da min lønn? At jeg, når jeg forkynner evangeliet, gjør det for intet, så jeg ikke gjør bruk av min rett i evangeliet. 
46:9:19: For om jeg enn er fri fra alle, har jeg dog selv gjort mig til tjener for alle, for å vinne de fleste, 
46:9:20: og jeg er blitt som en jøde for jødene, for å vinne jøder, for dem som er under loven, som en som er under loven - om jeg enn ikke selv er under loven - for å vinne dem som er under loven; 
46:9:21: for dem som er uten lov, er jeg blitt som en som er uten lov - om jeg enn ikke er lovløs for Gud, men lovbundet for Kristus - for å vinne dem som er uten lov; 
46:9:22: for de skrøpelige er jeg blitt skrøpelig, for å vinne de skrøpelige; for dem alle er jeg blitt alt, for i alle tilfelle å frelse nogen. 
46:9:23: Men alt gjør jeg for evangeliets skyld, forat også jeg kan få del i det. 
46:9:24: Vet I ikke at de som løper på rennebanen, de løper vel alle, men bare én får prisen? Løp da således, forat I kan vinne den! 
46:9:25: Hver som er med i veddekamp, er avholdende i alt, hine for å få en forgjengelig krans, men vi en uforgjengelig. 
46:9:26: Jeg løper da ikke som på det uvisse; jeg fekter ikke som en som slår i været; 
46:9:27: men jeg undertvinger mitt legeme og holder det i trældom, forat ikke jeg som preker for andre, selv skal finnes uverdig. 
46:10:1: For jeg vil ikke, brødre, at I skal være uvitende om at våre fedre var alle under skyen og gikk alle gjennem havet 
46:10:2: og blev alle døpt til Moses i skyen og i havet, 
46:10:3: og de åt alle den samme åndelige mat 
46:10:4: og drakk alle den samme åndelige drikk; for de drakk av den åndelige klippe som fulgte dem, og klippen var Kristus; 
46:10:5: allikevel hadde Gud ikke behag i de fleste av dem; for de blev slått ned i ørkenen. 
46:10:6: Men disse ting skjedde som forbilleder for oss, forat ikke vi skal ha lyst til det onde, likesom de hadde lyst til det. 
46:10:7: Bli heller ikke avgudsdyrkere, likesom nogen av dem, som skrevet er: Folket satte sig ned for å ete og drikke og stod op for å leke! 
46:10:8: La oss heller ikke drive hor, likesom nogen av dem drev hor og falt på én dag tre og tyve tusen! 
46:10:9: La oss heller ikke friste Kristus, likesom nogen av dem fristet ham og blev ødelagt av slanger! 
46:10:10: Knurr heller ikke, likesom nogen av dem knurret og blev ødelagt av ødeleggeren! 
46:10:11: Dette hendte dem som forbilleder, men det er skrevet til formaning for oss, til hvem de siste tider er kommet. 
46:10:12: Derfor, den som tykkes sig å stå, han se til at han ikke faller! 
46:10:13: Eder har ingen fristelse møtt som mennesker ikke kan bære; og Gud er trofast, som ikke skal la eder fristes over evne, men gjøre både fristelsen og dens utgang, så I kan tåle den. 
46:10:14: Derfor, mine elskede, fly fra avgudsdyrkelsen! 
46:10:15: Jeg taler til eder som til forstandige; døm selv det jeg sier! 
46:10:16: Velsignelsens kalk som vi velsigner, er den ikke samfund med Kristi blod? Brødet som vi bryter, er det ikke samfund med Kristi legeme? 
46:10:17: Fordi der er ett brød, er vi ett legeme, enda vi er mange; for vi har alle del i det ene brød. 
46:10:18: Se til Israel efter kjødet: Har ikke de som eter offerne, samfund med alteret? 
46:10:19: Hvad mener jeg altså? at det er noget avguds-offer til? eller at det er nogen avgud til? 
46:10:20: Nei, men at det de ofrer, det ofrer de til onde ånder og ikke til Gud; men jeg vil ikke at I skal komme i samfund med de onde ånder. 
46:10:21: I kan ikke drikke Herrens kalk og onde ånders kalk; I kan ikke ha del I Herrens bord og i onde ånders bord. 
46:10:22: Eller tør vi egge Herren til nidkjærhet? vi er vel ikke sterkere enn han? 
46:10:23: Jeg har lov til alt, men ikke alt gagner; jeg har lov til alt, men ikke alt opbygger. 
46:10:24: Ingen søke sitt eget, men enhver søke den annens beste! 
46:10:25: Alt det som selges i slakterboden, kan I ete uten at I for samvittighetens skyld spør mere efter det; 
46:10:26: for jorden og alt det som fyller den, hører Herren til. 
46:10:27: Og dersom nogen av de vantro ber eder til gjest, og I vil gå dit, da kan I ete alt som settes frem for eder, uten at I for samvittighetens skyld spør mere efter det. 
46:10:28: Men dersom nogen sier til eder: Dette er avguds-offer, da la være å ete det, for hans skyld som sa eder det, og for samvittighetens skyld! 
46:10:29: Jeg mener ikke ens egen samvittighet, men næstens. For hvorfor skal min frihet dømmes av en annens samvittighet? 
46:10:30: Dersom jeg nyter det med takk, hvorfor skal jeg da spottes for det som jeg takker for? 
46:10:31: Enten I altså eter eller drikker, eller hvad I gjør, så gjør alt til Guds ære! 
46:10:32: Vær uten anstøt både for jøder og for grekere og for Guds menighet, 
46:10:33: likesom også jeg i alt streber å tekkes alle og ikke tenker på mitt eget gagn, men på de manges, at de må bli frelst. 
46:11:1: Bli mine efterfølgere, likesom jeg efterfølger Kristus! # [1KO 11, 1 er i trykte bøker plassert på slutten av kapittel 10.] 
46:11:2: Jeg roser eder for at I kommer mig i hu i alle ting og holder fast ved mine forskrifter, således som jeg gav eder dem. 
46:11:3: Men jeg vil at I skal vite at Kristus er enhver manns hoved, og mannen er kvinnens hoved, og Gud er Kristi hoved. 
46:11:4: Hver mann som beder eller taler profetisk med noget på hodet, vanærer sitt hode; 
46:11:5: men hver kvinne som beder eller taler profetisk med utildekket hode, vanærer sitt hode; for det er aldeles det samme som om hun var raket. 
46:11:6: For hvis en kvinne ikke tildekker sig, da la henne også klippe håret av; men er det usømmelig for en kvinne å klippe eller rake av sig håret, da la henne tildekke sig. 
46:11:7: For en mann skal ikke tildekke sitt hode, eftersom han er Guds billede og ære; men kvinnen er mannens ære. 
46:11:8: For mannen er ikke av kvinnen, men kvinnen er av mannen; 
46:11:9: mannen blev jo heller ikke skapt for kvinnens skyld, men kvinnen for mannens skyld. 
46:11:10: Derfor bør kvinnen ha et undergivenhets-tegn på sitt hode for englenes skyld. 
46:11:11: Dog, i Herren er hverken kvinnen noget fremfor mannen eller mannen noget fremfor kvinnen; 
46:11:12: for likesom kvinnen er av mannen, så er også mannen ved kvinnen, og alt er av Gud. 
46:11:13: Døm hos eder selv: passer det sig at en kvinne beder til Gud med utildekket hode? 
46:11:14: Lærer ikke endog selve naturen eder at dersom en mann lar håret vokse langt, er det ham til vanære, 
46:11:15: men dersom en kvinne lar håret vokse langt, er det henne til ære? for det lange hår er gitt henne til slør. 
46:11:16: Men dersom nogen vil være trettekjær, da har ikke vi en sådan skikk, heller ikke Guds menigheter. 
46:11:17: Men idet jeg påbyder dette, roser jeg ikke at I kommer sammen, ikke til det bedre, men til det verre. 
46:11:18: For for det første, når I kommer sammen i menighets-samling, hører jeg at det er splid iblandt eder, og for en del tror jeg det; 
46:11:19: for det må være partier iblandt eder, forat de ekte kan bli åpenbare iblandt eder. 
46:11:20: Når I altså kommer sammen, da blir det ikke Herrens nattverd I eter; 
46:11:21: for idet I eter, tar hver på forhånd sin egen mat, og den ene er hungrig, og den annen er drukken. 
46:11:22: Har I da ikke hus til å ete og drikke i? eller forakter I Guds menighet og vanærer dem som intet har? Hvad skal jeg si til eder? skal jeg rose eder? I dette roser jeg eder ikke. 
46:11:23: For jeg har mottatt fra Herren dette som jeg også har overgitt eder, at den Herre Jesus i den natt da han blev forrådt, tok et brød, 
46:11:24: takket og brøt det og sa: Dette er mitt legeme, som er for eder; gjør dette til minne om mig! 
46:11:25: Likeså også kalken efter aftensmåltidet, idet han sa: Denne kalk er den nye pakt i mitt blod; gjør dette, så ofte som I drikker den, til minne om mig! 
46:11:26: For så ofte som I eter dette brød og drikker denne kalk, forkynner I Herrens død, inntil han kommer. 
46:11:27: Derfor, hver som eter brødet eller drikker Herrens kalk uverdig, han blir skyldig i Herrens legeme og blod. 
46:11:28: Men hvert menneske prøve sig selv, og så ete han av brødet og drikke av kalken! 
46:11:29: for den som eter og drikker, han eter og drikker sig selv til dom dersom han ikke gjør forskjell på Herrens legeme. 
46:11:30: Derfor er det mange skrøpelige og syke iblandt eder, og mange sovner inn. 
46:11:31: Men dersom vi dømte oss selv, blev vi ikke dømt; 
46:11:32: men når vi dømmes, da refses vi av Herren, forat vi ikke skal fordømmes sammen med verden. 
46:11:33: Derfor, mine brødre, når I kommer sammen for å ete, da del med hverandre; 
46:11:34: og hvis nogen hungrer, da ete han hjemme, forat I ikke skal komme sammen til dom! Det annet skal jeg foreskrive når jeg kommer. 
46:12:1: Men om de åndelige gaver, brødre, vil jeg ikke at I skal være uvitende. 
46:12:2: I vet at dengang da I var hedninger, lot I eder drage til de stumme avguder, alt efter som I blev draget. 
46:12:3: Derfor kunngjør jeg eder at ingen som taler i Guds Ånd, sier: Forbannet er Jesus! og ingen kan si: Jesus er Herre! uten i den Hellige Ånd. 
46:12:4: Det er forskjell på nådegaver, men Ånden er den samme; 
46:12:5: og det er forskjell på tjenester, men Herren er den samme; 
46:12:6: og det er forskjell på kraftige virkninger, men Gud er den samme, som virker alt i alle. 
46:12:7: Men Åndens åpenbarelse gis enhver til det som er gagnlig. 
46:12:8: For en gis visdoms tale ved Ånden, en annen kunnskaps tale ved den samme Ånd; 
46:12:9: en annen tro ved den samme Ånd, en annen nådegaver til å helbrede ved den samme Ånd, 
46:12:10: en annen kraft til å gjøre undergjerninger; en annen profetisk gave, en annen evne til å prøve ånder, en annen forskjellige slags tunger, en annen tydning av tunger. 
46:12:11: Alt dette virker den ene og samme Ånd, idet han utdeler til hver især efter som han vil. 
46:12:12: For likesom legemet er ett og har mange lemmer, men alle legemets lemmer, om de enn er mange, dog er ett legeme, således er det også med Kristus; 
46:12:13: for vi er jo alle døpt med én Ånd til å være ett legeme, enten vi er jøder eller grekere, enten vi er træler eller frie; og vi har alle fått én Ånd å drikke. 
46:12:14: For legemet er jo heller ikke ett lem, men mange. 
46:12:15: Om foten sier: Fordi jeg ikke er hånd, hører jeg ikke med til legemet, så hører den like fullt med til legemet; 
46:12:16: og om øret sier: Fordi jeg ikke er øie, hører jeg ikke med til legemet, så hører det like fullt med til legemet. 
46:12:17: Dersom hele legemet var øie, hvor blev det da av hørselen? dersom det hele var hørsel, hvor blev det da av lukten? 
46:12:18: Men nu satte Gud lemmene, hvert især av dem, på legemet, således som han vilde. 
46:12:19: Var de derimot alle ett lem, hvor blev det da av legemet? 
46:12:20: Men nu er det mange lemmer, men ett legeme. 
46:12:21: Øiet kan ikke si til hånden: Jeg trenger ikke til dig, eller hodet til føttene: Jeg trenger ikke til eder; 
46:12:22: men tvert imot: de lemmer på legemet som synes å være de skrøpeligste, de er nødvendige. 
46:12:23: Og de lemmer på legemet som vi synes er mindre ære verd, dem klær vi med større ære, og de lemmer som vi blues ved, dem klær vi med større bluferdighet, 
46:12:24: men våre edlere lemmer trenger ikke til det. Men Gud satte legemet sammen således at han gav det ringeste størst ære, 
46:12:25: forat det ikke skal være splid i legemet, men lemmene ha samme omsorg for hverandre. 
46:12:26: Og om ett lem lider, da lider alle lemmene med, og om ett lem hedres, da gleder alle lemmene sig med. 
46:12:27: Men nu er I Kristi legeme og hans lemmer, hver efter sin del. 
46:12:28: Og Gud satte i menigheten først nogen til apostler, for det annet profeter, for det tredje lærere, så kraftige gjerninger, så nådegaver til å helbrede, til å hjelpe, til å styre, forskjellige slags tunger. 
46:12:29: Er vel alle apostler? er vel alle profeter? er vel alle lærere? gjør vel alle kraftige gjerninger? 
46:12:30: har vel alle nådegaver til å helbrede? taler vel alle med tunger? kan vel alle tyde dem? 
46:12:31: Men streb efter de største nådegaver! Og jeg vil vise eder en ennu bedre vei. 
46:13:1: Om jeg taler med menneskers og englers tunger, men ikke har kjærlighet, da er jeg en lydende malm eller en klingende bjelle. 
46:13:2: Og om jeg eier profetisk gave og kjenner alle hemmeligheter og all kunnskap, og om jeg har all tro, så jeg kan flytte fjell, men ikke har kjærlighet, da er jeg intet. 
46:13:3: Og om jeg gir til føde for fattige alt det jeg eier, og om jeg gir mitt legeme til å brennes, men ikke har kjærlighet, da gagner det mig intet. 
46:13:4: Kjærligheten er langmodig, er velvillig; kjærligheten bærer ikke avind, kjærligheten brammer ikke, opblåses ikke, 
46:13:5: den gjør intet usømmelig, søker ikke sitt eget, blir ikke bitter, gjemmer ikke på det onde; 
46:13:6: den gleder sig ikke over urettferdighet, men gleder sig ved sannhet; 
46:13:7: den utholder alt, tror alt, håper alt, tåler alt. 
46:13:8: Kjærligheten faller aldri bort; men hvad enten det er profetiske gaver, da skal de få ende, eller det er tunger, da skal de ophøre, eller det er kunnskap, da skal den få ende. 
46:13:9: For vi skjønner stykkevis og taler profetisk stykkevis; 
46:13:10: men når det fullkomne kommer, da skal det som er stykkevis, få ende. 
46:13:11: Da jeg var barn, talte jeg som et barn, tenkte jeg som et barn, dømte jeg som et barn; men da jeg blev mann, la jeg av det barnslige. 
46:13:12: For nu ser vi i et speil, i en gåte; men da skal vi se åsyn til åsyn; nu kjenner jeg stykkevis, men da skal jeg kjenne fullt ut, likesom jeg også fullt ut er kjent. 
46:13:13: Men nu blir de stående disse tre, tro, håp, kjærlighet, og størst blandt dem er kjærligheten. 
46:14:1: Jag efter kjærligheten! Streb efter de åndelige gaver, men mest efter å tale profetisk! 
46:14:2: For den som taler med tunge, taler ikke for mennesker, men for Gud; for ingen skjønner det, men han taler hemmeligheter i Ånden; 
46:14:3: den derimot som taler profetisk, taler for mennesker til opbyggelse og formaning og trøst. 
46:14:4: Den som taler med tunge, opbygger sig selv; men den som taler profetisk, opbygger menigheten. 
46:14:5: Allikevel ønsker jeg at I alle talte med tunger, men heller at I talte profetisk; den som taler profetisk, er større enn den som taler med tunger, medmindre han tyder det, så at menigheten kan få opbyggelse av det. 
46:14:6: Og nu, brødre, om jeg kommer til eder og taler med tunger, hvad vil det da gagne eder, medmindre jeg taler til eder enten med åpenbaring eller med kunnskap, enten med profetiske ord eller med lære? 
46:14:7: Dersom de livløse ting som gir lyd, enten det er en fløite eller en harpe, ikke gir forskjellige toner, hvorledes kan en da skjønne det som spilles på fløiten eller på harpen? 
46:14:8: Og om en basun gir en utydelig lyd, hvem vil da gjøre sig rede til strid? 
46:14:9: Således også med eder: Dersom I ikke med eders tunge fremfører tydelig tale, hvorledes kan en da skjønne det som blir sagt? I vil jo da tale bort i været. 
46:14:10: Så mange slags sprog er det nu visst i verden, og det er intet av dem som ikke har sin betydning; 
46:14:11: dersom jeg altså ikke kjenner sprogets betydning, blir jeg en utlending for den som taler, og den som taler, blir en utlending for mig. 
46:14:12: Således også med eder: Når I streber efter de åndelige gaver, så søk å få dem i rikelig mål til menighetens opbyggelse. 
46:14:13: Derfor, den som taler med tunge, han bede om at han må kunne tyde det! 
46:14:14: For dersom jeg beder med tunge, da beder min ånd, men min forstand er uten frukt. 
46:14:15: Hvorledes er det altså? Jeg vil bede med ånden, men jeg vil også bede med forstanden; jeg vil lovsynge med ånden, men jeg vil også lovsynge med forstanden. 
46:14:16: For dersom du lover Gud med din ånd, hvorledes skal da nogen blandt de ukyndige kunne si amen til din takkebønn? han vet jo ikke hvad du sier; 
46:14:17: for du holder vel en smukk takkebønn, men den andre får ingen opbyggelse av det. 
46:14:18: Jeg takker Gud: jeg taler mere med tunge enn I alle; 
46:14:19: men i en menighets-samling vil jeg heller tale fem ord med min forstand, for derved å lære andre, enn ti tusen ord med tunge. 
46:14:20: Brødre! vær ikke barn i forstand, men vær barn i ondskap; i forstand derimot skal I være fullvoksne! 
46:14:21: Det er skrevet i loven: Ved folk med fremmed tungemål og ved fremmedes leber vil jeg tale til dette folk, og enda skal de ikke høre på mig, sier Herren. 
46:14:22: Så er da tungene til et tegn, ikke for de troende, men for de vantro; den profetiske tale derimot er ikke for de vantro, men for de troende. 
46:14:23: Om da hele menigheten kommer sammen, og alle taler med tunger, og det så kommer ukyndige eller vantro inn, vil de da ikke si at I er fra eder selv? 
46:14:24: Men om alle taler profetisk, og det så kommer inn en vantro eller en ukyndig, så refses han av alle og dømmes av alle, 
46:14:25: hans hjertes skjulte tanker åpenbares, og så vil han falle på sitt ansikt og tilbede Gud, og vidne at Gud sannelig er iblandt eder. 
46:14:26: Hvorledes er det altså, brødre? Når I kommer sammen, så har hver av eder en salme eller en lære eller en åpenbaring eller en tunge eller en tydning: la alt skje til opbyggelse! 
46:14:27: Taler nogen med tunge, da la det være to eller i det høieste tre hver gang, og den ene efter den annen, og la én tyde det! 
46:14:28: Men er det ingen til stede som kan tyde, da skal han tie i menighets-samlingen, men tale for sig selv og for Gud. 
46:14:29: Men av profeter tale to eller tre, og de andre prøve det! 
46:14:30: og får en annen en åpenbaring mens han sitter der, da skal den første tie. 
46:14:31: For I kan alle tale profetisk, én ad gangen, så alle kan lære og alle formanes; 
46:14:32: og profeters ånder er profeter lydige; 
46:14:33: for Gud er ikke uordens Gud, men freds Gud. 
46:14:34: Likesom i alle de helliges menigheter, skal eders kvinner tie i menighets-samlingene; for det tillates dem ikke å tale, men de skal underordne sig, som også loven sier. 
46:14:35: Men vil de få rede på noget, da skal de spørre sine egne menn hjemme; for det sømmer sig ikke for en kvinne å tale i menighets-samling. 
46:14:36: Eller var det fra eder Guds ord gikk ut? eller er I de eneste det er nådd til? 
46:14:37: Tror nogen at han er en profet eller en åndelig, da skal han skjønne at det jeg skriver til eder, er Herrens bud. 
46:14:38: Men om nogen ikke skjønner det, så får han la det være. 
46:14:39: Derfor, brødre, streb efter å tale profetisk, og hindre ikke nogen i å tale med tunger; 
46:14:40: men la alt skje sømmelig og med orden! 
46:15:1: Jeg kunngjør eder, brødre, det evangelium som jeg forkynte eder, som I og tok imot, som I og står fast i, 
46:15:2: som I og blir frelst ved dersom I holder fast ved det ord hvormed jeg forkynte eder det, såfremt I ikke forgjeves er kommet til troen. 
46:15:3: For jeg overgav eder blandt de første ting det som jeg selv mottok, at Kristus døde for våre synder efter skriftene, 
46:15:4: og at han blev begravet, 
46:15:5: og at han opstod på den tredje dag efter skriftene, 
46:15:6: og at han blev sett av Kefas, derefter av de tolv. 
46:15:7: Derefter blev han sett av mere enn fem hundre brødre på én gang - av dem er de fleste ennu i live, men nogen er hensovet. 
46:15:8: Derefter blev han sett av Jakob, derefter av alle apostlene. 
46:15:9: Men sist av alle blev han og sett av mig som det ufullbårne foster; for jeg er den ringeste av apostlene og er ikke verd å kalles apostel, fordi jeg har forfulgt Guds menighet; 
46:15:10: men av Guds nåde er jeg det jeg er, og hans nåde mot mig har ikke vært forgjeves, men jeg har arbeidet mere enn de alle, dog ikke jeg, men Guds nåde som er med mig. 
46:15:11: Hvad enten det da er jeg eller de andre, så forkynner vi således, og således kom I til troen. 
46:15:12: Men forkynnes det om Kristus at han er opstanden fra de døde, hvorledes kan da nogen iblandt eder si at det ikke er nogen opstandelse av døde? 
46:15:13: Men er det ikke nogen opstandelse av døde, da er heller ikke Kristus opstanden; 
46:15:14: men er Kristus ikke opstanden, da er vår forkynnelse intet, da er også eders tro intet; 
46:15:15: da finnes vi og å være falske vidner om Gud, fordi vi har vidnet mot Gud at han har opvakt Kristus, som han dog ikke har opvakt såfremt altså de døde ikke opstår. 
46:15:16: For dersom de døde ikke opstår, da er heller ikke Kristus opstanden; 
46:15:17: men er Kristus ikke opstanden, da er eders tro unyttig, da er I ennu i eders synder, 
46:15:18: da er altså også de fortapt som er hensovet i Kristus. 
46:15:19: Har vi bare i dette liv satt vårt håp til Kristus, da er vi de ynkverdigste av alle mennesker. 
46:15:20: Men nu er Kristus opstanden fra de døde og er blitt førstegrøden av de hensovede. 
46:15:21: For eftersom døden er kommet ved et menneske, så er og de dødes opstandelse kommet ved et menneske; 
46:15:22: for likesom alle dør i Adam, så skal og alle levendegjøres i Kristus. 
46:15:23: Men hver i sin egen avdeling: Kristus er førstegrøden; derefter skal de som hører Kristus til, levendegjøres ved hans komme; 
46:15:24: derefter kommer enden, når han overgir riket til Gud og Faderen, efterat han har tilintetgjort all makt og all myndighet og velde. 
46:15:25: For han skal være konge inntil han får lagt alle sine fiender under sine føtter. 
46:15:26: Den siste fiende som tilintetgjøres, er døden; 
46:15:27: for han har lagt alt under hans føtter. Men når han sier at alt er ham underlagt, så er det klart at han er undtatt som har underlagt ham alt; 
46:15:28: men når alt er ham underlagt, da skal og Sønnen selv underlegge sig ham som la alt under ham, forat Gud skal være alt i alle. 
46:15:29: Hvad gjør da de som lar sig døpe for de døde? Dersom de døde i det hele tatt ikke opstår, hvorfor lar de sig da døpe for dem? 
46:15:30: Hvorfor setter også vi oss hver time i fare? 
46:15:31: Jeg dør hver dag, så sant som jeg kan rose mig av eder, brødre, i Kristus Jesus, vår Herre. 
46:15:32: Var det på menneskelig vis jeg stred med ville dyr i Efesus, hvad vinning har jeg da av det? Dersom de døde ikke opstår, da la oss ete og drikke, for imorgen dør vi! 
46:15:33: Far ikke vill! Dårlig omgang forderver gode seder. 
46:15:34: Våkn op for alvor og synd ikke! for somme har ikke kjennskap til Gud; til skam for eder sier jeg det. 
46:15:35: Men en kunde si: Hvorledes opstår de døde? og med hvad slags legeme kommer de frem? 
46:15:36: Du dåre! det du sår, blir ikke levendegjort uten det dør. 
46:15:37: Og når du sår, sår du ikke det legeme som skal bli, men et nakent korn, kan hende av hvete eller av noget annet slag; 
46:15:38: men Gud gir det et legeme efter sin vilje, og hvert slags sæd sitt eget legeme. 
46:15:39: Ikke alt kjøtt er det samme kjøtt, men ett er kjøtt i mennesker, et annet i fe, et annet i fugler, et annet i fisker. 
46:15:40: Og der er himmelske legemer, og der er jordiske legemer; men én herlighet har de himmelske legemer, en annen de jordiske. 
46:15:41: Én glans har solen, og en annen månen, og en annen stjernene; for den ene stjerne skiller sig fra den andre i glans. 
46:15:42: Så er det og med de dødes opstandelse. Det såes i forgjengelighet; det opstår i uforgjengelighet; 
46:15:43: det såes i vanære, det opstår i herlighet; det såes i skrøpelighet, det opstår i kraft; 
46:15:44: der såes et naturlig legeme, der opstår et åndelig legeme. Så visst som det gis et naturlig legeme, gis det og et åndelig legeme. 
46:15:45: Således er det og skrevet: Det første menneske, Adam, blev til en levende sjel; den siste Adam er blitt til en levendegjørende ånd. 
46:15:46: Men det åndelige er ikke det første, men det naturlige, derefter det åndelige. 
46:15:47: Det første menneske var av jorden, jordisk; det annet menneske er av himmelen. 
46:15:48: Sådan som den jordiske var, så er og de jordiske, og sådan som den himmelske er, så skal og de himmelske være, 
46:15:49: og likesom vi har båret den jordiskes billede, så skal vi og bære den himmelskes billede. 
46:15:50: Men dette sier jeg, brødre, at kjød og blod kan ikke arve Guds rike, heller ikke arver forgjengelighet uforgjengelighet. 
46:15:51: Se, jeg sier eder en hemmelighet: Vi skal ikke alle hensove, men vi skal alle forvandles, 
46:15:52: i et nu, i et øieblikk, ved den siste basun. For basunen skal lyde, og de døde skal opstå uforgjengelige, og vi skal forvandles. 
46:15:53: For dette forgjengelige skal bli iklædd uforgjengelighet, og dette dødelige bli iklædd udødelighet. 
46:15:54: Og når dette forgjengelige er iklædd uforgjengelighet, og dette dødelige er iklædd udødelighet, da opfylles det ord som er skrevet: Døden er opslukt til seier. 
46:15:55: Død, hvor er din brodd? Død, hvor er din seier? 
46:15:56: Men dødens brodd er synden, og syndens kraft er loven; 
46:15:57: men Gud være takk, som gir oss seier ved vår Herre Jesus Kristus! 
46:15:58: Derfor, mine elskede brødre, vær faste, urokkelige, alltid rike i Herrens gjerning, da I vet at eders arbeide ikke er unyttig i Herren! 
46:16:1: Men vedkommende innsamlingen til de hellige, da skal I gjøre således som jeg har ordnet det for menighetene i Galatia. 
46:16:2: På hver første dag i uken legge enhver av eder hjemme hos sig selv til side det han får lykke til, forat innsamlingen ikke skal skje først da når jeg kommer. 
46:16:3: Når jeg så er kommet, skal jeg sende dem som I selv velger, med brev, for å føre eders kjærlighetsgave til Jerusalem; 
46:16:4: men er det verdt at jeg selv reiser, skal de reise i følge med mig. 
46:16:5: Jeg kommer til eder når jeg har reist igjennem Makedonia; for Makedonia reiser jeg igjennem; 
46:16:6: men hos eder blir jeg kan hende nogen tid, eller endog vinteren over, forat jeg kan få følge av eder dit jeg siden skal reise. 
46:16:7: For jeg tenker ikke å se eder nu på gjennemreisen; for jeg håper å bli nogen tid hos eder, om Herren tillater det. 
46:16:8: I Efesus blir jeg inntil pinsen; 
46:16:9: for en stor og virksom dør er oplatt for mig, og det er mange motstandere. 
46:16:10: Når Timoteus kommer, da se til at han kan være hos eder uten frykt! for han gjør Herrens gjerning, likesom jeg; 
46:16:11: ingen må derfor ringeakte ham. Og følg ham på vei i fred, forat han kan komme til mig! for jeg venter på ham med brødrene. 
46:16:12: Vedkommende broderen Apollos, da bad jeg ham meget at han skulde fare til eder sammen med brødrene; han var aldeles ikke villig til å komme nu, men han vil komme når han får tid. 
46:16:13: Vær årvåkne, stå fast i troen, vær mandige, vær sterke! 
46:16:14: La alt hos eder skje i kjærlighet! 
46:16:15: Jeg formaner eder, brødre: I kjenner Stefanas' hus, at det er førstegrøden av Akaia, og at de har stilt sig til tjeneste for de hellige; 
46:16:16: vis og I ærbødighet mot dem og mot enhver som hjelper til og gjør sig møie! 
46:16:17: Jeg gleder mig over at Stefanas og Fortunatus og Akaikus er her; for de har utfylt savnet av eder; 
46:16:18: for de har vederkveget min og eders ånd. Skjønn derfor på slike! 
46:16:19: Menighetene i Asia hilser eder. Akvilas og Priska tillikemed menigheten i deres hus hilser eder meget i Herren. 
46:16:20: Alle brødrene hilser eder. Hils hverandre med et hellig kyss! 
46:16:21: Hilsen med min, Paulus' hånd: 
46:16:22: Om nogen ikke elsker Herren, han være forbannet! Maran ata. 
46:16:23: Den Herre Jesu nåde være med eder! 
46:16:24: Min kjærlighet er med eder alle i Kristus Jesus. 
47:1:1: Paulus, ved Guds vilje Kristi Jesu apostel, og broderen Timoteus - til den Guds menighet som er i Korint, tillikemed alle de hellige som er i hele Akaia: 
47:1:2: Nåde være med eder og fred fra Gud vår Fader og den Herre Jesus Kristus! 
47:1:3: Lovet være Gud og vår Herre Jesu Kristi Fader, miskunns Fader og all trøsts Gud, 
47:1:4: han som trøster oss i all vår trengsel, forat vi skal kunne trøste dem som er i all slags trengsel, med den trøst hvormed vi selv blir trøstet av Gud! 
47:1:5: For likesom Kristi lidelser kommer rikelig over oss, så er og vår trøst rikelig ved Kristus. 
47:1:6: Men lider vi trengsel, da er det eder til trøst og frelse; trøstes vi, da er det eder til en trøst som viser sig kraftig i tålmod under de samme lidelser som vi lider; 
47:1:7: og vårt håp om eder er fast, da vi vet at likesom I har del i lidelsene, så skal I og ha del i trøsten. 
47:1:8: For vi vil ikke, brødre, at I skal være uvitende om at den trengsel som kom over oss i Asia, var overmåte tung, mere enn vi kunde bære, så vi endog mistvilte om livet; 
47:1:9: ja, vi hadde opgjort med oss selv at vi måtte dø, forat vi ikke skulde sette vår lit til oss selv, men til Gud, som opvekker de døde, 
47:1:10: han som fridde oss og frir oss fra slik en død, han som vi har det håp til at han og herefter vil fri oss derfra, 
47:1:11: idet også I kommer oss til hjelp med bønn, forat det fra manges munn må lyde rikelig takksigelse for oss, for den nåde som er oss gitt. 
47:1:12: For dette er vår ros: vår samvittighets vidnesbyrd om at vi har vandret i verden, og særlig hos eder, i Guds hellighet og renhet, ikke i kjødelig visdom, men i Guds nåde. 
47:1:13: For vi skriver ikke annet til eder enn det I leser eller skjønner, og jeg håper at I og skal skjønne det inntil enden 
47:1:14: - likesom I jo for en del har skjønt oss - at vi er eders ros, likesom og I er vår på den Herre Jesu dag. 
47:1:15: Og i tillit til dette vilde jeg komme til eder først, forat I skulde få ennu en nåde, 
47:1:16: og fra eder dra til Makedonia, og så fra Makedonia igjen komme til eder og få følge av eder til Judea. 
47:1:17: Da jeg nu altså vilde dette, gikk jeg da kan hende lettsindig frem? eller hvad jeg foresetter mig, foresetter jeg mig det på kjødelig vis, så at der hos mig skulde være både ja, ja og nei, nei? 
47:1:18: Så sant Gud er trofast: mitt ord til eder er ikke ja og nei! 
47:1:19: For Guds Sønn, Kristus Jesus, han som blev forkynt iblandt eder ved oss, ved mig og Silvanus og Timoteus, han var ikke ja og nei, men ja er der blitt i ham; 
47:1:20: for så mange som Guds løfter er, i ham har de sitt ja, derfor får de og ved ham sitt amen, Gud til ære ved oss. 
47:1:21: Men den som binder oss tillikemed eder fast til Kristus, og som salvet oss, det er Gud, 
47:1:22: han som og satte sitt innsegl på oss og gav Ånden til pant i våre hjerter. 
47:1:23: Men jeg kaller Gud til vidne for min sjel at det var for å skåne eder jeg ennu ikke er kommet til Korint; 
47:1:24: ikke at vi er herrer over eders tro, men vi arbeider med på eders glede; for I står i troen. 
47:2:1: Men også for min egen skyld foresatte jeg mig at jeg ikke atter vilde komme til eder med sorg. 
47:2:2: For hvis jeg gjør eder sorg, hvem er det da som gjør mig glad, uten den som jeg gjør sorg? 
47:2:3: Og just i det øiemed skrev jeg, forat jeg ikke, når jeg kom, skulde få sorg av dem som jeg burde ha glede av, idet jeg har den tillit til eder alle at min glede er eders alles glede. 
47:2:4: For med megen trengsel og angst i hjertet skrev jeg til eder under mange tårer, ikke for å gjøre eder sorg, men forat I skulde kjenne den kjærlighet som jeg særlig har til eder. 
47:2:5: Men er det nogen som har voldt sorg, da er det ikke mig han har voldt sorg, men delvis - at jeg ikke skal være for hård - eder alle. 
47:2:6: Det er nok for ham med den straff han har fått av de fleste, 
47:2:7: så at I derimot heller skal tilgi og trøste ham, forat han ikke skal gå til grunne i ennu større sorg. 
47:2:8: Derfor ber jeg eder å la kjærlighet råde mot ham. 
47:2:9: For derfor var det også jeg skrev, for å få vite om I holder prøve, om I er lydige i alt. 
47:2:10: Men den som I tilgir noget, ham tilgir og jeg; for det jeg har tilgitt - om jeg har hatt noget å tilgi - det har jeg gjort for eders skyld, for Kristi åsyn, forat vi ikke skal dåres av Satan; 
47:2:11: for ikke er vi uvitende om hans tanker. 
47:2:12: Da jeg kom til Troas for Kristi evangeliums skyld, og en dør var mig oplatt i Herren, hadde jeg ingen ro i min ånd, fordi jeg ikke fant Titus, min bror, 
47:2:13: men jeg sa farvel til dem og drog videre til Makedonia. 
47:2:14: Men Gud være takk, som alltid lar oss vinne seier i Kristus og ved oss åpenbarer sin kunnskaps duft på hvert sted! 
47:2:15: For vi er en Kristi vellukt for Gud, blandt dem som blir frelst, og blandt dem som går fortapt, 
47:2:16: for disse en duft av død til død, for hine en duft av liv til liv. Og hvem er vel duelig til dette? 
47:2:17: For vi er ikke, som de mange, slike at vi forfalsker Guds ord til egen vinning; men som i renhet, ja som av Gud, taler vi for Guds åsyn i Kristus. 
47:3:1: Begynner vi atter å gi oss selv skussmål, eller mon vi, således som visse folk, trenger til skussmålsbrev til eder eller fra eder? 
47:3:2: I er vårt brev, innskrevet i våre hjerter, kjent og lest av alle mennesker, 
47:3:3: idet det blir vitterlig at I er Kristi brev, tilblitt ved vår tjeneste, innskrevet ikke med blekk, men med den levende Guds Ånd, ikke på stentavler, men på hjertets kjødtavler. 
47:3:4: Men en sådan tillit har vi til Gud ved Kristus, 
47:3:5: ikke at vi av oss selv duer til å uttenke noget som av oss selv, men vår duelighet er av Gud, 
47:3:6: som og gjorde oss duelige til å være tjenere for en ny pakt, ikke for bokstav, men for Ånd; for bokstaven slår ihjel, men Ånden gjør levende. 
47:3:7: Dersom da dødens tjeneste, som med bokstaver var innhugget i stener, fremtrådte i herlighet, så at Israels barn ikke tålte å se på Mose åsyn for hans åsyns herlighets skyld, som dog svant, 
47:3:8: hvor skal da ikke Åndens tjeneste enn mere være i herlighet! 
47:3:9: For dersom fordømmelsens tjeneste er herlighet, da er rettferdighetens tjeneste ennu langt mere rik på herlighet; 
47:3:10: for det herlige har i dette stykke ingen herlighet mot hin overvettes rike herlighet. 
47:3:11: For når det som svinner, var i herlighet, da skal meget mere det som blir, være i herlighet. 
47:3:12: Eftersom vi da har et sådant håp, går vi frem med stor frimodighet, 
47:3:13: og gjør ikke som Moses, som la et dekke over sitt åsyn, forat Israels barn ikke skulde skue enden av det som svant. 
47:3:14: Men deres sinn er blitt forherdet; for like til denne dag blir det samme dekke liggende når de leser den gamle pakt, uten at det blir åpenbaret at den opheves i Kristus. 
47:3:15: Men til denne dag ligger et dekke over deres hjerte når Moses leses; 
47:3:16: men når det omvender sig til Herren, blir dekket tatt bort. 
47:3:17: Men Herren er Ånden, og hvor Herrens Ånd er, der er frihet. 
47:3:18: Men vi som med utildekket åsyn skuer Herrens herlighet som i et speil; vi blir alle forvandlet til det samme billede fra herlighet til herlighet, som av Herrens Ånd. 
47:4:1: Derfor, da vi har denne tjeneste, eftersom vi har fått miskunn, så taper vi ikke motet; 
47:4:2: men vi har sagt oss løs fra alle skammelige snikveier og farer ikke frem med list, heller ikke forfalsker vi Guds ord, men ved å kunngjøre sannheten anbefaler vi oss til alle menneskers samvittighet for Guds åsyn. 
47:4:3: Er da enn vårt evangelium skjult, så er det skjult blandt dem som går fortapt, 
47:4:4: i hvem denne verdens gud har forblindet de vantros sinn, forat schmelken fra evangeliet om Kristi herlighet, han som er Guds billede, ikke skal skinne for dem. 
47:4:5: For vi forkynner ikke oss selv, vi forkynner Kristus Jesus som Herre, oss derimot som eders tjenere for Jesu skyld. 
47:4:6: For Gud, som bød at lys skulde skinne frem av mørke, han er den som også har latt det skinne i våre hjerter, forat kunnskapen om Guds herlighet i Jesu Kristi åsyn skulde stråle frem fra oss. 
47:4:7: Men vi har denne skatt i lerkar, forat den rike kraft skal være av Gud og ikke av oss, 
47:4:8: idet vi alltid er i trengsel, men ikke kuet, tvilende, men ikke mistvilende, 
47:4:9: forfulgt, men ikke opgitt, nedslått, men ikke tilintetgjort - 
47:4:10: alltid bærende Jesu død med oss i legemet, forat også Jesu liv skal åpenbares i vårt legeme. 
47:4:11: For ennu mens vi lever, overgis vi stadig til døden for Jesu skyld, forat også Jesu liv skal åpenbares i vårt dødelige kjød. 
47:4:12: Således er da døden virksom i oss, men livet i eder. 
47:4:13: Men eftersom vi har den samme troens Ånd - efter det som er skrevet: Jeg trodde, derfor talte jeg - så tror vi og, derfor taler vi og, 
47:4:14: for vi vet at han som opvakte den Herre Jesus, skal også opvekke oss med Jesus og stille oss frem sammen med eder. 
47:4:15: For det skjer alt sammen for eders skyld, forat nåden kan utbrede sig til dess flere og virke rikelig takksigelse til Guds ære. 
47:4:16: Derfor taper vi ikke motet, men om og vårt utvortes menneske går til grunne, så fornyes dog det innvortes dag for dag. 
47:4:17: For vår trengsel, som er kortvarig og lett, virker for oss en evig fylde av herlighet i overmål på overmål, 
47:4:18: såsom vi ikke har det synlige for øie, men det usynlige; for det synlige er timelig, men det usynlige evig 
47:5:1: For vi vet at om vårt legemes jordiske hus nedbrytes, så har vi en bygning av Gud, et hus som ikke er gjort med hender, evig i himlene. 
47:5:2: For også mens vi er her til huse, sukker vi, fordi vi lenges efter å overklædes med vår bolig fra himmelen, 
47:5:3: så sant vi skal bli funnet iklædd, ikke nakne. 
47:5:4: For vi som er i denne hytte, sukker under byrden, fordi vi ikke vil avklædes, men overklædes, forat det dødelige kan bli opslukt av livet. 
47:5:5: Men den som har satt oss i stand just til dette, er Gud, han som og har gitt oss Ånden til pant. 
47:5:6: Derfor er vi alltid frimodige og vet at så lenge vi er hjemme i legemet, er vi borte fra Herren; 
47:5:7: for vi vandrer i tro, ikke i beskuelse; 
47:5:8: vi er altså frimodige, og vil heller være borte fra legemet og være hjemme hos Herren. 
47:5:9: Derfor setter vi og vår ære i, enten vi er hjemme eller borte, å tekkes ham. 
47:5:10: For vi skal alle åpenbares for Kristi domstol, forat enhver kan få igjen det som er skjedd ved legemet, efter det som han har gjort, enten godt eller ondt. 
47:5:11: Da vi altså kjenner frykten for Herren, søker vi å vinne mennesker, men for Gud er vi åpenbare; jeg håper og å være åpenbar for eders samvittigheter. 
47:5:12: Vi gir oss nu ikke atter skussmål for eder; men vi gir eder leilighet til å rose eder av oss, forat I kan ha noget å sette imot dem som roser sig av det de er i det utvortes og ikke i hjertet. 
47:5:13: For om vi er fra oss selv, da er det for Gud, og om vi er ved sans og samling, da er det for eder. 
47:5:14: For Kristi kjærlighet tvinger oss, 
47:5:15: idet vi har opgjort dette med oss selv at én er død for alle, derfor er de alle død; og han døde for alle, forat de som lever, ikke lenger skal leve for sig selv, men for ham som er død og opstanden for dem. 
47:5:16: Derfor kjenner vi fra nu av ikke nogen efter kjødet; har vi og kjent Kristus efter kjødet, så kjenner vi ham dog nu ikke lenger således. 
47:5:17: Derfor, dersom nogen er i Kristus, da er han en ny skapning; det gamle er forganget, se, alt er blitt nytt! 
47:5:18: Men alt dette er av Gud, som forlikte oss med sig selv ved Kristus og gav oss forlikelsens tjeneste, 
47:5:19: fordi Gud i Kristus forlikte verden med sig selv, så han ikke tilregner dem deres overtredelser og har nedlagt i oss ordet om forlikelsen. 
47:5:20: Så er vi da sendebud i Kristi sted, som om Gud selv formante ved oss; vi ber i Kristi sted: La eder forlike med Gud! 
47:5:21: Ham som ikke visste av synd, har han gjort til synd for oss, forat vi i ham skal bli rettferdige for Gud. 
47:6:1: Men som medarbeidere formaner vi eder også at I ikke forgjeves må ta imot Guds nåde. 
47:6:2: Han sier jo: På den tid som behaget mig, bønnhørte jeg dig, og på frelsens dag kom jeg dig til hjelp. Se, nu er en velbehagelig tid, se, nu er frelsens dag! 
47:6:3: Og vi gir ikke i noget stykke noget anstøt, forat ikke tjenesten skal bli lastet, 
47:6:4: men viser oss i alt som Guds tjenere: ved stort tålmod i trengsler, i nød, i angst, 
47:6:5: under slag, i fengsler, i oprør, i strengt arbeid, i nattevåk, i faste; 
47:6:6: ved renhet, ved skjønnsomhet, ved langmodighet, ved godhet, ved den Hellige Ånd, ved uskrømtet kjærlighet, 
47:6:7: ved sannhets ord, ved Guds kraft, ved rettferds våben på høire og venstre side; 
47:6:8: i ære og vanære, med ondt rykte og godt rykte, som forførere og dog sanndrue, 
47:6:9: som ukjente og dog velkjente; som de som dør, og se, vi lever, som de som refses og dog ikke ihjelslåes, 
47:6:10: som bedrøvede, men alltid glade, som fattige, som dog gjør mange rike, som de som intet har og dog eier alt. 
47:6:11: Vår munn er oplatt mot eder, I korintiere, vårt hjerte har utvidet sig. 
47:6:12: I har ikke trangt rum hos oss, men det er trangt i eders hjerte. 
47:6:13: Men like for like - jeg taler som til barn - utvid også I eders hjerter! 
47:6:14: Dra ikke i fremmed åk med vantro! for hvad samlag har rettferd med urett, eller hvad samfund har schmelk med mørke? 
47:6:15: Og hvad samklang er det mellem Kristus og Belial, eller hvad lodd og del har en troende med en vantro? 
47:6:16: Og hvad enighet er det mellem Guds tempel og avguder? Vi er jo den levende Guds tempel, som Gud har sagt: Jeg vil bo iblandt dem og ferdes iblandt dem, og jeg vil være deres Gud, og de skal være mitt folk. 
47:6:17: Derfor, gå ut fra dem og skill eder fra dem, sier Herren, og rør ikke ved urent, så skal jeg ta imot eder, 
47:6:18: og jeg vil være eder en far, og I skal være mig sønner og døtre, sier Herren, den Allmektige. 
47:7:1: Eftersom vi da har disse løfter, mine elskede, så la oss rense oss fra all urenhet på kjød og ånd og fullende vår helliggjørelse i Guds frykt! # [2KO 7, 1 er i trykte bøker plassert på slutten av kapittel 6.] 
47:7:2: Gi oss rum! Ingen har vi gjort urett, ingen har vi ødelagt, ingen har vi gått for nær. 
47:7:3: Dette sier jeg ikke for å fordømme eder; jeg har jo før sagt at I er i våre hjerter for å dø med oss og leve med oss. 
47:7:4: Jeg har stor tiltro til eder; jeg roser mig storlig av eder; jeg er full av trøst, jeg er overvettes rik på glede under all vår trengsel. 
47:7:5: For heller ikke da vi kom til Makedonia, hadde vårt kjød nogen ro, men vi trengtes på alle måter: utenfor oss var strid, inneni oss frykt; 
47:7:6: men Gud, som trøster de nedbøiede, han trøstet oss ved Titus' komme, 
47:7:7: dog ikke bare ved hans komme, men og ved den trøst hvormed han var blitt trøstet over eder, idet han meldte oss eders lengsel, eders klage, eders nidkjærhet for mig, så at jeg blev ennu gladere. 
47:7:8: For om jeg enn bedrøvet eder ved mitt brev, så angrer jeg det ikke; om jeg enn før angret det - jeg ser jo at hint brev bedrøvet eder, om enn bare for en stund - 
47:7:9: så gleder jeg mig nu, ikke over at I blev bedrøvet, men over at I blev bedrøvet til omvendelse; for I blev bedrøvet efter Guds sinn, forat I ikke skulde lide nogen skade ved oss. 
47:7:10: For bedrøvelsen efter Guds sinn virker omvendelse til frelse, som ingen angrer; men verdens bedrøvelse virker død. 
47:7:11: For se, just dette at I blev bedrøvet efter Guds sinn, hvilken iver det virket hos eder, ja forsvar, ja harme, ja frykt, ja lengsel, ja nidkjærhet, ja straff! I alt viste I eder å være rene i den sak. 
47:7:12: Derfor, om jeg enn skrev til eder, så var det ikke for hans skyld som gjorde uretten, heller ikke for hans skyld som uretten blev gjort imot, men forat eders iver for oss kunde bli åpenbaret hos eder for Guds åsyn. 
47:7:13: Derfor er vi blitt trøstet. Og til vår trøst kom ennu den langt større glede over Titus' glede; for hans ånd er blitt vederkveget av eder alle. 
47:7:14: For om jeg til ham har uttalt nogen ros over eder, så er jeg ikke blitt til skamme; men likesom vi i alt har talt sannhet til eder, så er og vår ros for Titus blitt sannhet, 
47:7:15: og hans kjærlighet til eder er enn sterkere når han kommer eders alles lydighet i hu, hvorledes I tok imot ham med frykt og beven. 
47:7:16: Jeg gleder mig over at jeg i alt kan lite på eder. 
47:8:1: Vi kunngjør eder, brødre, den nåde som Gud har gitt i Makedonias menigheter, 
47:8:2: at enda de var hårdt prøvet med trengsel, så har dog deres overvettes glede og deres dype fattigdom i overstrømmende fylde virket hos dem en rikdom på opriktig kjærlighet. 
47:8:3: For efter evne gav de, det vidner jeg, ja over evne, av egen drift; 
47:8:4: de bad oss inntrengende om å få lov til å være med i hjelpen til de hellige; 
47:8:5: og de gav ikke bare således som vi håpet, men de gav sig selv først til Herren og til oss ved Guds vilje, 
47:8:6: så vi bad Titus at likesom han før hadde begynt, således skulde han fullende hos eder også dette kjærlighetsverk. 
47:8:7: Men likesom I er rike i alle måter, på tro og på tale og på kunnskap og på all iver og på kjærlighet som I har vakt hos oss, så bli nu og rike i dette kjærlighetsverk! 
47:8:8: Dette sier jeg ikke som et bud, men ved andres iver vil jeg også prøve ektheten av eders kjærlighet. 
47:8:9: For I kjenner vår Herre Jesu Kristi nåde, at han for eders skyld blev fattig da han var rik, forat I ved hans fattigdom skulde bli rike. 
47:8:10: Og jeg sier min mening i denne sak; for dette er til gagn for eder, I som alt før, fra ifjor av, begynte ikke bare å virke, men og å ville. 
47:8:11: Men fullfør nu også verket, forat fullførelsen må svare til viljens redebonhet, efter eders evne! 
47:8:12: For er redebonheten forhånden, da er den velbehagelig efter det den har, og ikke efter det den ikke har. 
47:8:13: For dette sier jeg, ikke forat andre skal ha lettelse og I trengsel, 
47:8:14: men forat det skal være likelighet; i den nuværende tid kommer eders overflod deres trang til hjelp, forat også deres overflod må komme eders trang til hjelp, forat det må være likelighet, 
47:8:15: som skrevet står: Den som sanket meget, hadde ikke overflod, og den som sanket lite, hadde ikke trang. 
47:8:16: Men Gud være takk, som har inngitt den samme iver for eder i Titus' hjerte! 
47:8:17: for han tok gjerne imot min tilskyndelse, ja, hans iver er så stor at han av egen drift drar ut til eder. 
47:8:18: Og med ham sender vi og den bror som ved sin virksomhet for evangeliet har vunnet ros i alle menigheter, 
47:8:19: Og ikke bare det, men som også er valgt av menighetene til å reise sammen med oss med denne kjærlighetsgave som kommer i stand ved vår tjeneste, for å fremme Herrens egen ære og vår redebonhet; 
47:8:20: for derved undgår vi at nogen skal kunne laste oss for noget som vedkommer denne rike gave som kommer i stand ved vår tjeneste, 
47:8:21: da vi legger vinn på det som er godt, ikke alene i Herrens, men og i menneskers øine. 
47:8:22: Og med dem sender vi vår bror, som vi ofte på mange måter har funnet ivrig, men nu meget ivrigere på grunn av den store tillit han har til eder. 
47:8:23: Hvad enten det da gjelder Titus, så er han min medbroder og medarbeider hos eder, eller det gjelder våre brødre, så er de menighetsutsendinger, Kristi ære. 
47:8:24: Så vis dem altså sannheten av eders kjærlighet og av den ros vi har gitt eder, for menighetenes åsyn! 
47:9:1: For om hjelpen til de hellige har jeg ikke nødig å skrive til eder; 
47:9:2: jeg kjenner jo eders redebonhet, som jeg roser eder for hos makedonierne, idet jeg sier: Akaia har vært rede like fra ifjor; og eders nidkjærhet tilskyndte så mange. 
47:9:3: Men jeg sender brødrene, forat ikke den ros vi har gitt eder, skal bli til intet i dette stykke, forat I, som jeg har sagt, må være rede, 
47:9:4: så vi, for ikke å si I selv, ikke skal bli til skamme i denne tillit når det kommer makedoniere med mig og finner eder uferdige. 
47:9:5: Derfor aktet jeg det nødvendig å tilskynde brødrene til å dra i forveien til eder og forut få i stand den gave I før har lovt, at den må være ferdig som en velsignelse, og ikke som en karrig gave. 
47:9:6: Men dette sier jeg: Den som karrig sår, skal karrig høste, og den som sår med velsignelser, skal høste med velsignelser. 
47:9:7: Hver gi så som han setter sig fore i sitt hjerte, ikke med sorg eller av tvang! for Gud elsker en glad giver. 
47:9:8: Og Gud er mektig til å gi eder all nåde i rikelig mål, forat I alltid i alle ting kan ha alt det I trenger til, og således rikelig kan gjøre all god gjerning, 
47:9:9: som skrevet er: Han strødde ut, han gav de fattige; hans rettferdighet blir til evig tid. 
47:9:10: Og han som gir såmannen såkorn og brød til å ete, han skal og gi eder utsæd og øke den og gi vekst til fruktene av eders rettferdighet, 
47:9:11: idet I blir rike i alle ting til all opriktig kjærlighet, som ved oss virker takksigelse til Gud. 
47:9:12: For den hjelp som ydes ved denne tjeneste, råder ikke bare bot på de helliges trang, men bærer også rik frukt ved manges takksigelser til Gud, 
47:9:13: idet de ved det hjertelag som denne hjelp viser, kommer til å prise Gud for eders lydighet til å bekjenne Kristi evangelium og for opriktigheten i eders samfund med dem og med alle, 
47:9:14: idet også de med bønn for eder lenges efter eder på grunn av den Guds nåde som er så overvettes rik over eder. 
47:9:15: Gud være takk for sin usigelige gave! 
47:10:1: Men jeg, Paulus, formaner eder ved Kristi saktmodighet og mildhet, jeg som for eders øine er ydmyk iblandt eder, men fraværende er djerv mot eder; 
47:10:2: men jeg ber om at når jeg nu kommer, må jeg slippe for å være djerv med den frimodighet hvormed jeg akter å gå dristig frem mot somme folk som akter oss for slike som vandrer på kjødelig vis. 
47:10:3: For om vi enn vandrer i kjødet, så strider vi dog ikke på kjødelig vis; 
47:10:4: for våre stridsvåben er ikke kjødelige, men mektige for Gud til å omstyrte festnings-verker, 
47:10:5: idet vi omstyrter tankebygninger og enhver høide som reiser sig mot kunnskapen om Gud, og tar enhver tanke til fange under lydigheten mot Kristus, 
47:10:6: og er rede til å straffe all ulydighet, når først eders lydighet er blitt fullkommen. 
47:10:7: I ser bare på det utvortes! Om nogen er viss med sig selv at han hører Kristus til, han dømme atter hos sig selv at likesom han hører Kristus til, således og vi. 
47:10:8: For om jeg enn vil rose mig noget mere av vår makt, som Herren har gitt oss til å opbygge eder og ikke til å nedbryte, så vil jeg ikke bli til skamme, 
47:10:9: forat jeg ikke skal synes likesom å ville skremme eder med mine brev. 
47:10:10: For brevene, sier de, er myndige og djerve; men det legemlige nærvær er skrøpelig, og hans tale aktes for intet. 
47:10:11: Den som taler så, han må tenke sig det at slik som vi fraværende er i ord i våre brev, slik er vi og nærværende i gjerning. 
47:10:12: For vi tør ikke regne oss iblandt eller ligne oss med visse folk som gir sig selv skussmål; men når disse måler sig med sig selv og ligner sig med sig selv, så mangler de forstand; 
47:10:13: vi derimot vil ikke rose oss over all måte, men efter målet av det område Gud har tildelt oss som mål: at vi skulde nå frem like til eder. 
47:10:14: For vi strekker oss ikke ut over vårt mål, som om vi ikke var nådd frem til eder - vi er jo kommet like til eder med Kristi evangelium - 
47:10:15: idet vi ikke roser oss over all måte, av andres arbeide, men har det håp at eders tro vil vokse, og vi således bli overvettes store iblandt eder efter vårt mål, 
47:10:16: så vi kan føre evangeliet frem til de land som ligger på hin side av eder, og ikke innenfor andres område rose oss av det som alt er virket. 
47:10:17: Men den som roser sig, rose sig i Herren! 
47:10:18: for ikke den som gir sig selv skussmål, holder prøve, men den som Herren gir skussmål. 
47:11:1: Gid I vilde tåle litt dårskap av mig! Dog, I skal tåle mig. 
47:11:2: For jeg er nidkjær for eder med Guds nidkjærhet; jeg trolovet eder jo med én mann, for å fremstille en ren jomfru for Kristus; 
47:11:3: men jeg frykter for at likesom slangen dåret Eva med sin list, således skal også eders tanker forderves og vendes bort fra den enfoldige troskap mot Kristus. 
47:11:4: For om det kommer en til eder og forkynner en annen Jesus, som vi ikke har forkynt, eller I får en annen ånd, som I ikke før har fått, eller et annet evangelium, som I ikke før har mottatt, da vil I gjerne tåle det! 
47:11:5: For jeg mener at jeg ikke i noget stykke står tilbake for de såre store apostler; 
47:11:6: men er jeg enn ulærd i tale, så er jeg det dog ikke i kunnskap; tvert imot har vi alltid lagt den for dagen for eder i alle stykker. 
47:11:7: Eller gjorde jeg synd da jeg ydmyket mig selv forat I skulde ophøies, idet jeg uten vederlag forkynte eder Guds evangelium? 
47:11:8: Andre menigheter skattla jeg, idet jeg tok lønn av dem for å tjene eder, og da jeg var hos eder og kom i trang, falt jeg ikke nogen til byrde; 
47:11:9: for det jeg manglet, det utfylte brødrene som kom fra Makedonia, og i alle måter har jeg holdt mig og vil holde mig fra å falle eder til byrde. 
47:11:10: Så sant Kristi sannhet er i mig: Denne ros skal ikke frarøves mig i Akaias bygder! 
47:11:11: Hvorfor? fordi jeg ikke elsker eder? Det vet Gud! 
47:11:12: men det jeg gjør, vil jeg og herefter gjøre for å avskjære dem leiligheten som søker en leilighet, forat de skal finnes like med oss i det som de roser sig av. 
47:11:13: For disse er falske apostler, svikefulle arbeidere, som skaper sig om til apostler for Kristus. 
47:11:14: Og det er intet under; for Satan selv skaper sig om til en lysets engel; 
47:11:15: derfor er det ikke noget stort om og hans tjenere skaper sig om til rettferdighets tjenere; men deres ende skal være efter deres gjerninger. 
47:11:16: Atter sier jeg: Ingen akte mig for en dåre! men selv om så er, da ta imot mig, om enn som en dåre, forat også jeg kan få rose mig litt! 
47:11:17: Det jeg her taler, det taler jeg ikke efter Herrens vilje, men som i dårskap, idet jeg roser mig så tillitsfullt. 
47:11:18: Eftersom mange roser sig på kjødelig vis, vil jeg og rose mig. 
47:11:19: For I tåler jo gjerne dårene, I som er så kloke; 
47:11:20: I tåler det jo om nogen gjør eder til træler, om nogen opeter eder, om nogen fanger eder, om nogen ophøier sig over eder, om nogen slår eder i ansiktet! 
47:11:21: Med skam sier jeg det, fordi vi har vært svake; men det som nogen gjør sig stor av - jeg taler i dårskap - det gjør også jeg mig stor av. 
47:11:22: Er de hebreere? Jeg og. Er de israelitter? Jeg og. Er de Abrahams ætt? Jeg og. 
47:11:23: Er de Kristi tjenere? Jeg taler i vanvidd: Jeg er det mere; jeg har arbeidet mere, fått flere slag, vært flere ganger i fengsel, ofte i dødsfare. 
47:11:24: Av jødene har jeg fem ganger fått firti slag på ett nær; 
47:11:25: tre ganger blev jeg hudstrøket, én gang stenet, tre ganger led jeg skibbrudd, et døgn har jeg vært i dypet. 
47:11:26: Ofte har jeg vært på reiser, i farer i elver, i farer blandt røvere, i farer fra mitt folk, i farer fra hedninger, i farer i by, i farer i ørken, i farer på hav, i farer blandt falske brødre, 
47:11:27: i strev og møie, ofte i nattevåk, i hunger og tørst, ofte i faste, i kulde og nakenhet. 
47:11:28: Foruten alt annet har jeg ennu det daglige overløp, omsorgen for alle menighetene. 
47:11:29: Hvem er skrøpelig uten at og jeg blir skrøpelig? hvem voldes anstøt uten at det brenner i mig? 
47:11:30: Må jeg endelig rose mig, da vil jeg rose mig av det som hører min skrøpelighet til. 
47:11:31: Gud og vår Herre Jesu Kristi Fader, han som er velsignet i evighet, vet at jeg ikke lyver! 
47:11:32: I Damaskus holdt kong Aretas' landshøvding vakt om damaskenernes by for å gripe mig, 
47:11:33: og gjennem en glugge på muren blev jeg firt ned i en kurv og slapp bort av hans hender. 
47:12:1: Jeg må rose mig skjønt det ikke er gagnlig; men jeg kommer nu til syner og åpenbarelser av Herren. 
47:12:2: Jeg kjenner et menneske i Kristus - om han var i legemet, vet jeg ikke, eller utenfor legemet, vet jeg ikke; Gud vet det - en som for fjorten år siden blev rykket like inn i den tredje himmel. 
47:12:3: Og jeg kjenner dette menneske - om han var i legemet eller utenfor legemet, vet jeg ikke; Gud vet det - 
47:12:4: han blev rykket inn i Paradis og hørte usigelige ord, som det ikke er et menneske tillatt å tale. 
47:12:5: Av dette vil jeg rose mig; men av mig selv vil jeg ikke rose mig, uten av min skrøpelighet. 
47:12:6: For om jeg vil rose mig, blir jeg ikke en dåre; for det er sannhet jeg vil tale; men jeg lar det være, forat ingen skal tenke større om mig enn som han ser mig eller hører av mig. 
47:12:7: Og forat jeg ikke skal ophøie mig av de høie åpenbarelser, er det gitt mig en torn i kjødet, en Satans engel, forat han skal slå mig, så jeg ikke skal ophøie mig. 
47:12:8: Om denne bad jeg Herren tre ganger at han måtte vike fra mig; 
47:12:9: og han sa til mig: Min nåde er dig nok; for min kraft fullendes i skrøpelighet. Derfor vil jeg helst rose mig av min skrøpelighet, forat Kristi kraft kan bo i mig. 
47:12:10: Derfor er jeg vel til mote i skrøpelighet, i mishandling, i nød, i forfølgelser, i trengsler for Kristi skyld; for når jeg er skrøpelig, da er jeg sterk. 
47:12:11: Jeg er blitt en dåre; I har tvunget mig til det. For jeg burde få ros av eder; jeg stod jo ikke i noget tilbake for de såre store apostler, om jeg enn er intet. 
47:12:12: En apostels tegn blev iallfall gjort blandt eder med all utholdenhet, både med tegn og under og kraftige gjerninger. 
47:12:13: For hvad var det I blev tilsidesatt i for de andre menigheter uten i det at jeg ikke falt eder til byrde? Tilgi mig denne urett! 
47:12:14: Se, for tredje gang er jeg nu rede til å komme til eder, og jeg vil ikke falle eder til byrde; for jeg søker ikke det som eders er, men eder selv. Barna er jo ikke skyldige å samle til foreldrene, men foreldrene til barna. 
47:12:15: Men jeg vil med glede ofre, ja bli ofret for eders sjeler, om jeg enn elskes dess mindre av eder jo mere jeg elsker eder. 
47:12:16: Men la så være at jeg ikke var eder til byrde, men jeg var listig og fanget eder med svik! 
47:12:17: Søkte jeg vel vinning av eder ved nogen av dem som jeg sendte til eder? 
47:12:18: Jeg tilskyndte Titus og sendte vår bror med ham; mon vel Titus søkte vinning av eder? vandret vi ikke i den samme ånd? ikke i de samme spor? 
47:12:19: Alt lenge har I tenkt at det er eder vi forsvarer oss for. Nei, det er for Guds åsyn vi taler i Kristus; men alt er til eders opbyggelse, mine elskede! 
47:12:20: For jeg frykter for at når jeg kommer, skal jeg ikke finne eder slik som jeg ønsker, og at jeg skal finnes av eder slik som I ikke ønsker - at det skal være kiv, avind, vrede, stridigheter, baktalelse, sladder, opblåsthet, uorden - 
47:12:21: at når jeg kommer, skal min Gud atter ydmyke mig hos eder, og jeg skal komme til å sørge over mange av dem som før har syndet og ikke har omvendt sig fra den urenhet og utukt og skamløshet som de har drevet. 
47:13:1: Det er nu tredje gang jeg kommer til eder. Ved to og tre vidners ord skal hver sak stå fast. 
47:13:2: Jeg har sagt det forut og sier forut, likesom ved mitt annet nærvær, så og nu i mitt fravær, til dem som før har syndet, og til alle de andre at når jeg nu atter kommer, vil jeg ikke vise skånsel - 
47:13:3: siden I krever et bevis på at Kristus taler i mig, han som ikke er skrøpelig hos eder, men er sterk iblandt eder; 
47:13:4: for blev han enn korsfestet i skrøpelighet, så lever han dog i Guds kraft; for også vi er skrøpelige i ham; men vi skal leve med ham i Guds kraft hos eder. 
47:13:5: Ransak eder selv om I er i troen; prøv eder selv! Eller kjenner I ikke eder selv at Kristus Jesus er i eder? det måtte da være at I ikke holder prøve. 
47:13:6: Men jeg håper at I skal få kjenne at vi ikke er de som ikke holder prøve. 
47:13:7: Dog beder vi til Gud at I ikke må gjøre noget ondt, ikke forat vi skal vise oss å holde prøve, men forat I må gjøre det gode, vi derimot være som de som ikke holder prøve; 
47:13:8: for vi makter ikke noget mot sannheten, men bare for sannheten. 
47:13:9: For vi gleder oss når vi er skrøpelige, I derimot sterke; dette beder vi og om, at I må bli fullkomne. 
47:13:10: Derfor skriver jeg dette i mitt fravær, forat jeg ikke under mitt nærvær skal fare strengt frem efter den makt som Herren har gitt mig til å opbygge og ikke til å nedbryte. 
47:13:11: For øvrig, brødre, gled eder, bli fullkomne, la eder formane, ha ett sinn, hold fred med hverandre, så skal kjærlighetens og fredens Gud være med eder. 
47:13:12: Hils hverandre med et hellig kyss! Alle de hellige hilser eder. 
47:13:13: Den Herre Jesu Kristi nåde og Guds kjærlighet og den Hellige Ånds samfund være med eder alle! 
48:1:1: Paulus, apostel, ikke av mennesker eller ved noget menneske, men ved Jesus Kristus og Gud Fader, som opvakte ham fra de døde, 
48:1:2: og alle de brødre som er hos mig - til menighetene i Galatia: 
48:1:3: Nåde være med eder og fred fra Gud Fader og vår Herre Jesus Kristus, 
48:1:4: han som gav sig selv for våre synder for å fri oss ut av den nærværende onde verden efter vår Guds og Faders vilje; 
48:1:5: ham være æren i all evighet! Amen. 
48:1:6: Jeg undrer mig over at I så snart vender eder bort fra ham som kalte eder ved Kristi nåde, til et annet evangelium, 
48:1:7: skjønt der ikke er noget annet; det er bare nogen som forvirrer eder og vil forvrenge Kristi evangelium. 
48:1:8: Men selv om vi eller en engel fra himmelen forkynner eder et annet evangelium enn det som vi har forkynt eder, han være forbannet! 
48:1:9: Som vi før har sagt, så sier jeg og nu igjen: Om nogen forkynner eder et annet evangelium enn det som I har mottatt, han være forbannet! 
48:1:10: Taler jeg nu mennesker til vilje, eller Gud? eller søker jeg å tekkes mennesker? Søkte jeg ennu å tekkes mennesker, da var jeg ikke Kristi tjener. 
48:1:11: Jeg kunngjør eder, brødre, at det evangelium som er blitt forkynt av mig, ikke er menneske-verk; 
48:1:12: for heller ikke jeg har mottatt det eller lært det av noget menneske, men ved Jesu Kristi åpenbaring. 
48:1:13: I har jo hørt hvorledes jeg fordum levde som jøde, at jeg over all måte forfulgte Guds menighet og ødela den, 
48:1:14: og jeg gikk videre i jødedommen enn mange jevnaldrende i mitt folk, jeg var enda mere nidkjær for mine fedrene lærdommer. 
48:1:15: Men da han som utvalgte mig fra mors liv og kalte mig ved sin nåde, 
48:1:16: efter sin vilje åpenbarte sin Sønn i mig, forat jeg skulde forkynne evangeliet om ham blandt hedningene, da samrådde jeg mig ikke med kjød og blod, 
48:1:17: heller ikke drog jeg op til Jerusalem til dem som var apostler før mig; men jeg drog straks bort til Arabia og vendte tilbake til Damaskus. 
48:1:18: Siden, tre år efter, drog jeg op til Jerusalem for å bli kjent med Kefas, og jeg blev femten dager hos ham; 
48:1:19: men nogen annen av apostlene så jeg ikke, uten Jakob, Herrens bror. 
48:1:20: Det jeg her skriver til eder - se, det vet Gud at jeg ikke lyver! 
48:1:21: Derefter kom jeg til Syrias og Kilikias bygder. 
48:1:22: Jeg var av utseende ukjent for de kristne menigheter i Judea; 
48:1:23: de hadde bare hørt si: Han som før forfulgte oss, han forkynner nu den tro som han før vilde utrydde; 
48:1:24: og de priste Gud for mig. 
48:2:1: Fjorten år efter gikk jeg atter op til Jerusalem med Barnabas og tok også Titus med; 
48:2:2: men jeg gikk der op efter en åpenbaring. Og jeg forela dem, og særskilt dem som gjaldt mest, det evangelium som jeg forkynner iblandt hedningene, om jeg vel løp eller hadde løpet forgjeves. 
48:2:3: Men ikke engang Titus, som var med mig, han som var en greker, blev tvunget til å la sig omskjære - 
48:2:4: og det for de falske brødres skyld som hadde sneket sig inn og var kommet for å lure på vår frihet, den som vi har i Kristus Jesus, så de kunde gjøre oss til træler. 
48:2:5: For disse vek vi ikke et øieblikk i eftergivenhet, forat evangeliets sannhet kunde stå fast iblandt eder. 
48:2:6: Men de som gjaldt for å være noget - hvor store de var, er mig det samme; Gud gjør ikke forskjell på folk - de som gjaldt mest, påla mig intet videre, 
48:2:7: tvert imot: da de så at det var mig betrodd å forkynne evangeliet for de uomskårne, likesom Peter for de omskårne - 
48:2:8: for han som gav Peter kraft til apostel-tjeneste blandt de omskårne, han gav og mig kraft til det blandt hedningene - 
48:2:9: og da de fikk vite om den nåde som var mig gitt, da gav Jakob og Kefas og Johannes, de som gjaldt for å være støttene, mig og Barnabas samfunds-hånd, at vi skulde gå til hedningene, men de til de omskårne, 
48:2:10: bare at vi skulde komme de fattige i hu, og det har jeg nettop lagt vinn på å gjøre. 
48:2:11: Men da Kefas kom til Antiokia, sa jeg ham imot like op i øinene, fordi det var ført klagemål imot ham. 
48:2:12: For før det kom nogen fra Jakob, åt han sammen med hedningene; men da de kom, drog han sig tilbake og skilte sig ut, for han var redd for dem som var av omskjærelsen, 
48:2:13: og sammen med ham hyklet og de andre jøder, så at endog Barnabas blev revet med av deres hykleri. 
48:2:14: Men da jeg så at de ikke gikk rett frem efter evangeliets sannhet, sa jeg til Kefas så alle hørte på det: Når du som er jøde, lever som hedning og ikke som jøde, hvorledes kan du da tvinge hedningene til å leve som jøder? 
48:2:15: Vi er jøder av fødsel og ikke syndere av hedensk ætt; 
48:2:16: men da vi innså at et menneske ikke blir rettferdiggjort av lov-gjerninger, men ved tro på Kristus Jesus, så trodde også vi på Kristus Jesus, for å bli rettferdiggjort av tro på Kristus og ikke av lov-gjerninger, eftersom intet kjød blir rettferdiggjort av lov-gjerninger. 
48:2:17: Men om vi, da vi søkte å bli rettferdiggjort i Kristus, fantes også selv å være syndere, er derfor Kristus en syndens tjener? Langt derifra! 
48:2:18: for hvis jeg igjen bygger op det jeg brøt ned, da viser jeg mig selv som en lovbryter. 
48:2:19: For jeg er ved loven død for loven for å leve for Gud; 
48:2:20: jeg er korsfestet med Kristus, jeg lever ikke lenger selv, men Kristus lever i mig, og det liv jeg nu lever i kjødet, det lever jeg i troen på Guds Sønn, som elsket mig og gav sig selv for mig. 
48:2:21: Jeg akter ikke Guds nåde for intet; for er rettferdighet å få ved loven, da er altså Kristus død forgjeves. 
48:3:1: I uforstandige galatere! hvem har forgjort eder, I som har fått Jesus Kristus malt for øinene som korsfestet? 
48:3:2: Bare dette vil jeg få vite av eder: Var det ved lov-gjerninger I fikk Ånden, eller ved troens forkynnelse? 
48:3:3: Er I så uforstandige? I begynte i Ånd; vil I nu fullende i kjød? 
48:3:4: Så meget har I oplevd forgjeves! - om det da virkelig er forgjeves. 
48:3:5: Han altså som gir eder Ånden og virker kraftige gjerninger iblandt eder, gjør han det ved lov-gjerninger eller ved troens forkynnelse? 
48:3:6: Likesom Abraham trodde Gud, og det blev regnet ham til rettferdighet. 
48:3:7: Derfor skal I vite at de som har tro, de er Abrahams barn. 
48:3:8: Og da Skriften forutså at det er ved tro Gud rettferdiggjør hedningene, forkynte den Abraham forut det evangelium: I dig skal alle folk velsignes. 
48:3:9: Så blir da de som har tro, velsignet med den troende Abraham. 
48:3:10: For så mange som holder sig til lov-gjerninger, er under forbannelse; for det er skrevet: Forbannet er hver den som ikke blir ved i alt som er skrevet i lovens bok, så han gjør det! 
48:3:11: Og at ingen blir rettferdiggjort for Gud ved loven, er åpenbart; for: Den rettferdige, ved tro skal han leve; 
48:3:12: men loven har ikke noget med troen å gjøre, men: Den som gjør det, skal leve derved. 
48:3:13: Kristus kjøpte oss fri fra lovens forbannelse, idet han blev en forbannelse for oss - for det er skrevet: Forbannet er hver den som henger på et tre - 
48:3:14: forat Abrahams velsignelse kunde komme over hedningene i Kristus Jesus, så vi ved troen kunde få Ånden, som var oss lovt. 
48:3:15: Brødre! jeg taler på menneskelig vis. Selv en menneskelig pakt vil ingen gjøre til intet eller legge noget til efterat den er stadfestet. 
48:3:16: Nu blev løftene gitt Abraham og hans ætt; han sier ikke: Og dine ætlinger, som om mange, men som om én: Og din ætt, og dette er Kristus. 
48:3:17: Dette er da min mening: En pakt som forut er stadfestet av Gud, gjør ikke loven, som er gitt fire hundre og tretti år efter, ugyldig, så den skulde gjøre løftet til intet. 
48:3:18: For får en arven ved lov, da får en den ikke lenger ved løfte; men Gud har gitt Abraham den ved løfte. 
48:3:19: Hvad skulde da loven til? Den blev lagt til for overtredelsenes skyld, inntil den ætt kom som løftet gjaldt - gitt ved engler, ved en mellemmanns hånd; 
48:3:20: men en mellemmann er ikke bare for én; men Gud er én. 
48:3:21: Er da loven imot Guds løfter? Langt derifra! for var det gitt nogen lov som kunde gjøre levende, da kom rettferdigheten virkelig av loven; 
48:3:22: men Skriften har lagt alt inn under synd, forat det som var lovt, skulde ved tro på Jesus Kristus bli gitt dem som tror. 
48:3:23: Men før troen kom, blev vi holdt innestengt i varetekt under loven til den tro som skulde åpenbares. 
48:3:24: Så er da loven blitt vår tuktemester til Kristus, forat vi skulde bli rettferdiggjort av tro; 
48:3:25: men efterat troen er kommet, er vi ikke lenger under tuktemesteren; 
48:3:26: alle er I jo Guds barn ved troen på Kristus Jesus; 
48:3:27: for I, så mange som er døpt til Kristus, har iklædd eder Kristus. 
48:3:28: Her er ikke jøde eller greker, her er ikke træl eller fri, her er ikke mann og kvinne; for I er alle én i Kristus Jesus. 
48:3:29: Men hører I Kristus til, da er I jo Abrahams ætt, arvinger efter løfte. 
48:4:1: Men jeg sier: Så lenge arvingen er barn, er det ingen forskjell mellem ham og en træl, enda han er herre over alt sammen; 
48:4:2: men han er under formyndere og husholdere inntil den tid som hans far forut har fastsatt. 
48:4:3: Således var også vi, dengang vi var barn, trælbundet under verdens barnelærdom; 
48:4:4: men da tidens fylde kom, utsendte Gud sin Sønn, født av en kvinne, født under loven, 
48:4:5: forat han skulde kjøpe dem fri som var under loven, forat vi skulde få barnekår. 
48:4:6: Og fordi I er sønner, har Gud sendt sin Sønns Ånd i våre hjerter, som roper: Abba, Fader! 
48:4:7: Så er du da ikke lenger træl, men sønn; men er du sønn, da er du og arving ved Gud. 
48:4:8: Men dengang da I ikke kjente Gud, trælet I under de guder som i virkeligheten ikke er guder; 
48:4:9: men nu, da I kjenner Gud, ja det som mere er, er kjent av Gud, hvorledes kan I da vende om igjen til den skrøpelige og fattige barnelærdom? vil I da på ny igjen træle under den? 
48:4:10: I tar vare på dager og måneder og tider og år; 
48:4:11: jeg frykter for eder at jeg kanskje forgjeves har gjort mig møie med eder. 
48:4:12: Vær som jeg! for jeg er jo som I. Brødre! jeg ber eder. I har ingen urett gjort mig; 
48:4:13: men I vet at det var på grunn av skrøpelighet i mitt kjød at jeg første gang kom til å forkynne evangeliet for eder; 
48:4:14: og til tross for den fristelse som voldtes eder ved mitt kjød, ringeaktet og avskydde I mig ikke. I tok imot mig som en Guds engel, ja, som Kristus Jesus. 
48:4:15: Hvor I dengang priste eder salige! for jeg gir eder det vidnesbyrd at hadde det vært mulig, så hadde I revet eders øine ut og gitt mig dem. 
48:4:16: Så er jeg da blitt eders fiende ved å si eder sannheten! 
48:4:17: De legger sig efter eder med en iver som ikke er god; men de vil stenge eder ute, forat I skal være ivrige for dem. 
48:4:18: Men det er godt å vise iver i det gode alltid, og ikke bare når jeg er til stede hos eder. 
48:4:19: Mine barn, som jeg atter føder med smerte, inntil Kristus vinner skikkelse i eder! 
48:4:20: Jeg skulde ønske å være til stede hos eder nu og omskifte min røst; for jeg er rådvill med eder. 
48:4:21: Si mig, I som vil være under loven: Hører I ikke loven? 
48:4:22: Det er jo skrevet at Abraham hadde to sønner, én med trælkvinnen og én med den frie kvinne; 
48:4:23: men trælkvinnens sønn er født efter kjødet, den frie kvinnes derimot ifølge løftet. 
48:4:24: I dette ligger en dypere mening under; for disse kvinner er to pakter, den ene fra berget Sinai, og den føder barn til trældom; dette er Hagar. 
48:4:25: For Hagar er berget Sinai i Arabia, og svarer til det Jerusalem som nu er; for det er i trældom med sine barn. 
48:4:26: Men det Jerusalem som er der oppe, er fritt, og det er vår mor; 
48:4:27: for det er skrevet: Vær glad, du ufruktbare, du som ikke føder! bryt ut og rop, du som ikke har veer! for den enslige kvinnes barn er mange flere enn hennes som har mannen. 
48:4:28: Men vi, brødre, er løftets barn, likesom Isak. 
48:4:29: Men likesom dengang han som var født efter kjødet, forfulgte ham som var født efter Ånden, således og nu. 
48:4:30: Men hvad sier Skriften? Driv ut trælkvinnen og hennes sønn! for trælkvinnens sønn skal ingenlunde arve med den frie kvinnes sønn. 
48:4:31: Derfor, brødre, er vi ikke trælkvinnens barn, men den frie kvinnes. 
48:5:1: Til frihet har Kristus frigjort oss; stå derfor fast, og la eder ikke atter legge under trældoms åk! 
48:5:2: Se, jeg, Paulus, sier eder at dersom I lar eder omskjære, så vil Kristus intet gagne eder. 
48:5:3: Atter vidner jeg for hvert menneske som lar sig omskjære, at han er skyldig å holde hele loven. 
48:5:4: I er skilt fra Kristus, I som vil rettferdiggjøres ved loven; I er falt ut av nåden. 
48:5:5: For ved troen venter vi i Ånden på det som rettferdigheten gir oss håp om; 
48:5:6: for i Kristus Jesus gjelder hverken omskjærelse eller forhud noget, men bare tro, virksom ved kjærlighet. 
48:5:7: I løp godt; hvem hindret eder fra å lyde sannheten? 
48:5:8: Denne overtalelse kommer ikke fra ham som kalte eder. 
48:5:9: En liten surdeig syrer hele deigen. 
48:5:10: Jeg har den tillit til eder i Herren at I ikke vil mene noget annet; men den som forvirrer eder, skal bære sin dom, hvem han så er. 
48:5:11: Men jeg, brødre - hvis jeg ennu forkynner omskjærelse, hvorfor forfølges jeg da ennu? da er jo korsets anstøt gjort til intet. 
48:5:12: Gid de endog må skjære sig selv i sønder, de som opvigler eder! 
48:5:13: For I blev kalt til frihet, brødre; bruk bare ikke friheten til en leilighet for kjødet, men tjen hverandre i kjærlighet! 
48:5:14: For hele loven er opfylt i ett bud, i dette: Du skal elske din næste som dig selv. 
48:5:15: Men dersom I biter og eter hverandre, da se til at I ikke blir fortært av hverandre! 
48:5:16: Men jeg sier: Vandre i Ånden, så skal I ikke fullbyrde kjødets begjæring. 
48:5:17: For kjødet begjærer imot Ånden, og Ånden imot kjødet; de står hverandre imot, så I ikke skal gjøre det I vil. 
48:5:18: Men dersom I drives av Ånden, da er I ikke under loven. 
48:5:19: Men kjødets gjerninger er åpenbare, såsom: utukt, urenhet, skamløshet, 
48:5:20: avgudsdyrkelse, trolldom, fiendskap, kiv, avind, vrede, stridigheter, tvedrakt, partier, 
48:5:21: misunnelse, mord, drikk, svir og annet slikt; om dette sier jeg eder forut, likesom jeg og forut har sagt, at de som gjør sådant, skal ikke arve Guds rike. 
48:5:22: Men Åndens frukt er kjærlighet, glede, fred, langmodighet, mildhet, godhet, trofasthet, saktmodighet, avholdenhet; 
48:5:23: mot slike er loven ikke. 
48:5:24: Men de som hører Kristus Jesus til, har korsfestet kjødet med dets lyster og begjæringer. 
48:5:25: Dersom vi lever i Ånden, da la oss og vandre i Ånden! 
48:5:26: La oss ikke ha lyst til tom ære, så vi egger hverandre og bærer avind imot hverandre! 
48:6:1: Brødre! om også et menneske blir overlistet av nogen synd, da hjelp ham til rette, I åndelige, med saktmodighets ånd; men se til dig selv at ikke du og blir fristet! 
48:6:2: Bær hverandres byrder, og opfyll på den måte Kristi lov! 
48:6:3: For dersom nogen tykkes sig å være noget og er dog intet, da dårer han sig selv. 
48:6:4: Men enhver prøve sin egen gjerning, og da skal han ha sin ros bare efter det han selv er, og ikke efter det som næsten er; 
48:6:5: for hver skal bære sin egen byrde. 
48:6:6: Den som oplæres i ordet, skal dele alt godt med den som lærer ham. 
48:6:7: Far ikke vill! Gud lar sig ikke spotte; for det som et menneske sår, det skal han og høste. 
48:6:8: For den som sår i sitt kjød, skal høste fordervelse av kjødet; men den som sår i Ånden, skal høste evig liv av Ånden. 
48:6:9: Men la oss gjøre det gode og ikke bli trette! for vi skal høste i sin tid, såfremt vi ikke går trett. 
48:6:10: La oss derfor, mens vi har leilighet til det, gjøre det gode mot alle, men mest mot troens egne folk! 
48:6:11: Se, med hvor store bokstaver jeg skriver til eder med min egen hånd! 
48:6:12: Så mange som vil ta sig godt ut i kjødet, disse er det som tvinger eder til å la eder omskjære, bare for ikke å bli forfulgt for Kristi korses skyld. 
48:6:13: For ikke engang de som lar sig omskjære, holder selv loven; men de vil at I skal la eder omskjære, forat de kan rose sig av eders kjød. 
48:6:14: Men det være langt fra mig å rose mig uten av vår Herre Jesu Kristi kors; for ved det er verden blitt korsfestet for mig og jeg for verden. 
48:6:15: For hverken omskjærelse eller forhud er noget, men bare en ny skapning. 
48:6:16: Og så mange som går frem efter denne rettesnor, fred og miskunn være over dem og over Guds Israel! 
48:6:17: Herefter volde ingen mig uleilighet! for jeg bærer Jesu merketegn på mitt legeme. 
48:6:18: Vår Herre Jesu Kristi nåde være med eders ånd, brødre! Amen. 
49:1:1: aulus, ved Guds vilje Kristi Jesu apostel - til de hellige i Efesus, som tror på Kristus Jesus: 
49:1:2: Nåde være med eder og fred fra Gud vår Fader og den Herre Jesus Kristus! 
49:1:3: Lovet være Gud og vår Herre Jesu Kristi Fader, han som har velsignet oss med all åndelig velsignelse i himmelen i Kristus, 
49:1:4: likesom han utvalgte oss i ham før verdens grunnvoll blev lagt, forat vi skulde være hellige og ulastelige for hans åsyn, 
49:1:5: idet han i kjærlighet forut bestemte oss til å få barnekår hos sig ved Jesus Kristus efter sin viljes frie råd, 
49:1:6: til pris for sin nådes herlighet, som han gav oss i den elskede, 
49:1:7: i hvem vi har forløsningen ved hans blod, syndenes forlatelse, efter hans nådes rikdom, 
49:1:8: som han rikelig gav oss i og med all visdom og forstand, 
49:1:9: idet han kunngjorde oss sin viljes hemmelighet efter sitt frie råd, som han fattet hos sig selv 
49:1:10: om en husholdning i tidenes fylde: at han atter vilde samle alt til ett i Kristus, både det som er i himlene, og det som er på jorden; 
49:1:11: han i hvem vi også har fått arvelodd, efterat vi forut var bestemt til det efter hans forsett som virker alt efter sin viljes råd, 
49:1:12: forat vi skulde være hans hellighet til pris, vi som forut hadde håpet på Kristus; 
49:1:13: i ham har også I, da I hadde hørt sannhetens ord, evangeliet om eders frelse - i ham har I og, da I var kommet til troen, fått til innsegl den Hellige Ånd, som var oss lovt, 
49:1:14: han som er pantet på vår arv til eiendomsfolkets forløsning, hans herlighet til pris. 
49:1:15: Derfor, efterat jeg har hørt om eders tro på den Herre Jesus og om eders kjærlighet til alle de hellige, 
49:1:16: holder jeg ikke op med å takke for eder når jeg kommer eder i hu i mine bønner, 
49:1:17: at vår Herre Jesu Kristi Gud, herlighetens Fader, måtte gi eder visdoms og åpenbarings Ånd til kunnskap om sig, 
49:1:18: og gi eders hjerte oplyste øine, så I kan forstå hvilket håp det er han har kalt eder til, og hvor rik på herlighet hans arv er iblandt de hellige, 
49:1:19: og hvor overvettes stor hans makt er for oss som tror, efter virksomheten av hans veldige kraft, 
49:1:20: som han viste på Kristus da han opvakte ham fra de døde og satte ham ved sin høire hånd i himmelen, 
49:1:21: over enhver makt og myndighet og velde og herredom og ethvert navn som nevnes, ikke bare i denne verden, men også i den kommende, 
49:1:22: og han la alt under hans føtter og gav ham som hoved over alle ting til menigheten, 
49:1:23: som er hans legeme, fylt av ham som fyller alt i alle. 
49:2:1: Også eder har han gjort levende, I som var døde ved eders overtredelser og synder, 
49:2:2: som I fordum vandret i efter denne verdens løp, efter høvdingen over luftens makter, den ånd som nu er virksom i vantroens barn, 
49:2:3: blandt hvilke også vi alle fordum vandret i vårt kjøds lyster, idet vi gjorde kjødets og tankenes vilje, og vi var av naturen vredens barn likesom de andre. 
49:2:4: Men Gud, som er rik på miskunn, har for sin store kjærlighets skyld som han elsket oss med, 
49:2:5: gjort oss levende med Kristus, enda vi var døde ved våre overtredelser - av nåde er I frelst - 
49:2:6: og opvakt oss med ham og satt oss med ham i himmelen, i Kristus Jesus, 
49:2:7: forat han i de kommende tider kunde vise sin nådes overvettes rikdom i godhet mot oss i Kristus Jesus. 
49:2:8: For av nåde er I frelst, ved tro, og det ikke av eder selv, det er Guds gave, 
49:2:9: ikke av gjerninger, forat ikke nogen skal rose sig. 
49:2:10: For vi er hans verk, skapt i Kristus Jesus til gode gjerninger, som Gud forut har lagt ferdige, at vi skulde vandre i dem. 
49:2:11: Kom derfor i hu at I som fordum var hedninger i kjøttet og blev kalt uomskårne av den såkalte omskjærelse på kjøttet, den som gjøres med hånden, 
49:2:12: at I på den tid stod utenfor Kristus, utelukket fra Israels borgerrett og fremmede for paktene med deres løfte, uten håp og uten Gud i verden; 
49:2:13: men nu, i Kristus Jesus, er I som fordum var langt borte, kommet nær til ved Kristi blod. 
49:2:14: For han er vår fred, han som gjorde de to til ett og nedrev gjerdets skillevegg, fiendskapet, 
49:2:15: idet han ved sitt kjød avskaffet den lov som kom med bud og forskrifter, forat han ved sig selv kunde skape de to til ett nytt menneske, idet han gjorde fred, 
49:2:16: og forlike dem begge i ett legeme med Gud ved korset, idet han på dette drepte fiendskapet. 
49:2:17: Og han kom og forkynte fred for eder som var langt borte, og fred for dem som var nær ved; 
49:2:18: for ved ham har vi begge adgang til Faderen i en Ånd. 
49:2:19: Så er I da ikke lenger fremmede og utlendinger, men I er de helliges medborgere og Guds husfolk, 
49:2:20: I som er bygget op på apostlenes og profetenes grunnvoll, mens hjørnestenen er Kristus Jesus selv, 
49:2:21: i hvem hver bygning føies sammen og vokser til et hellig tempel i Herren, 
49:2:22: i hvem også I bygges op med de andre til en Guds bolig i Ånden. 
49:3:1: Derfor bøier jeg mine knær, jeg, Paulus, Kristi Jesu fange for eders skyld, I hedninger - 
49:3:2: om I ellers har hørt om husholdningen med den Guds nåde som er mig gitt for eder, 
49:3:3: at han ved åpenbaring har kunngjort mig hemmeligheten, således som jeg ovenfor har skrevet med få ord, 
49:3:4: hvorav I, når I leser det, kan kjenne min innsikt i Kristi hemmelighet, 
49:3:5: som i de forrige tidsaldre ikke er blitt kunngjort for menneskenes barn således som den nu er åpenbaret for hans hellige apostler og profeter i Ånden: 
49:3:6: at hedningene er medarvinger og hører med til legemet og har del med i løftet i Kristus Jesus ved evangeliet, 
49:3:7: hvis tjener jeg er blitt efter den Guds nådes gave som er mig gitt ved virksomheten av hans makt. 
49:3:8: Mig, den aller ringeste av alle hellige, blev denne nåde gitt å forkynne hedningene evangeliet om Kristi uransakelige rikdom, 
49:3:9: og å oplyse alle om hvorledes husholdningen er med den hemmelighet som har vært skjult fra evige tider i Gud, som har skapt alt, 
49:3:10: forat Guds mangfoldige visdom nu ved menigheten skulde bli kunngjort for maktene og myndighetene i himmelen 
49:3:11: efter det forsett fra evige tider som han fullførte i Kristus Jesus, vår Herre, 
49:3:12: i hvem vi har vår frimodighet og adgang med tillit ved troen på ham. 
49:3:13: Derfor beder jeg at I ikke må tape motet over mine trengsler for eder, som er eders ære. - 
49:3:14: Derfor altså bøier jeg mine knær for Faderen, 
49:3:15: som er den rette far for alt som kalles barn i himmelen og på jorden, 
49:3:16: at han efter sin herlighets rikdom må gi eder å styrkes med kraft ved hans Ånd i eders innvortes menneske, 
49:3:17: at Kristus må bo ved troen i eders hjerter, 
49:3:18: så I, rotfestet og grunnfestet i kjærlighet, må være i stand til å fatte med alle de hellige hvad bredde og lengde og dybde og høide der er, 
49:3:19: og kjenne Kristi kjærlighet, som overgår all kunnskap, forat I kan fylles til all Guds fylde. 
49:3:20: Men ham som kan gjøre mere enn alt, langt ut over det som vi beder eller forstår, efter den kraft som ter sig virksom i oss, 
49:3:21: ham være æren i menigheten og i Kristus Jesus, gjennem alle slekter i alle evigheter! Amen. 
49:4:1: Jeg formaner eder altså, jeg, den fangne i Herren, at I vandrer så som verdig er for det kall som I er kalt med, 
49:4:2: med all ydmykhet og saktmodighet, med langmodighet, så I tåler hverandre i kjærlighet, 
49:4:3: idet I legger vinn på å bevare Åndens enhet i fredens sambånd. 
49:4:4: Ett legeme og én Ånd, likesom I og er kalt med ett håp i eders kall; 
49:4:5: én Herre, én tro, én dåp, 
49:4:6: én Gud og alles Fader, han som er over alle og gjennem alle og i alle. 
49:4:7: Men hver og en av oss er nåden gitt efter det mål som Kristi gave tilmåles med. 
49:4:8: Derfor sier Skriften: Han fór op i det høie og bortførte fanger, han gav menneskene gaver. 
49:4:9: Men dette: Han fór op, hvad er det uten at han først fór ned til jordens lavere deler? 
49:4:10: Han som fór ned, er den samme som fór op over alle himler for å fylle alt. 
49:4:11: Og det er han som gav oss nogen til apostler, nogen til profeter, nogen til evangelister, nogen til hyrder og lærere, 
49:4:12: forat de hellige kunde bli fullkommengjort til tjenestegjerning, til Kristi legemes opbyggelse, 
49:4:13: inntil vi alle når frem til enhet i tro på Guds Sønn og i kjennskap til ham, til manns modenhet, til aldersmålet for Kristi fylde, 
49:4:14: forat vi ikke lenger skal være umyndige og la oss kaste og drive om av ethvert lærdoms vær ved menneskenes spill, ved kløkt i villfarelsens kunster, 
49:4:15: men at vi, sannheten tro i kjærlighet, i alle måter skal vokse op til ham som er hovedet, Kristus, 
49:4:16: av hvem hele legemet sammenføies og sammenknyttes ved hvert bånd som han gir, og vokser sin vekst som legeme til sin opbyggelse i kjærlighet, alt efter den virksomhet som er tilmålt hver del især. 
49:4:17: Dette sier jeg da og vidner i Herren at I ikke lenger skal vandre som hedningene vandrer i sitt sinns tomhet, 
49:4:18: formørket i sin tanke, fremmedgjort for Guds liv ved den vankundighet som er i dem på grunn av deres hjertes forherdelse, 
49:4:19: de som følelsesløse har overgitt sig til skamløshet, så de driver all urenhet tillikemed havesyke. 
49:4:20: Men I har ikke lært Kristus således å kjenne, 
49:4:21: om I ellers har hørt om ham og er blitt oplært i ham, således som sannhet er i Jesus, 
49:4:22: at I efter eders forrige ferd skal avlegge det gamle menneske, som forderves ved de dårende lyster, 
49:4:23: men fornyes i eders sinns ånd 
49:4:24: og iklæ eder det nye menneske, som er skapt efter Gud i sannhetens rettferdighet og hellighet. 
49:4:25: Derfor, avlegg løgnen og tal sannhet, enhver med sin næste, fordi vi er hverandres lemmer! 
49:4:26: Om I vredes, da synd ikke; la ikke solen gå ned over eders vrede, 
49:4:27: og gi ikke djevelen rum! 
49:4:28: Den som stjal, stjele ikke lenger, men arbeide heller, idet han gjør noget godt med sine hender, forat han kan ha noget å gi til den som trenger. 
49:4:29: Ingen råtten tale gå ut av eders munn, men sådan tale som er god til nødvendig opbyggelse, så den kan være til gagn for dem som hører på; 
49:4:30: og gjør ikke Guds Hellige Ånd sorg, han som I har fått til innsegl til forløsningens dag! 
49:4:31: Allslags bitterhet og hissighet og vrede og skrik og spott være langt borte fra eder, likesom all ondskap; 
49:4:32: men vær gode mot hverandre, barmhjertige, så I tilgir hverandre, likesom Gud har tilgitt eder i Kristus! 
49:5:1: Bli derfor Guds efterfølgere som hans elskede barn, 
49:5:2: og vandre i kjærlighet, likesom Kristus elsket eder og gav sig selv for oss som en gave og et offer, Gud til en velbehagelig duft. 
49:5:3: Men utukt og all urenhet og havesyke må ikke engang nevnes iblandt eder, således som det sømmer sig for hellige, 
49:5:4: heller ikke skamløs ferd og dårlig snakk eller lettferdig tale, som alt sammen er utilbørlig, men heller takksigelse. 
49:5:5: For dette vet og skjønner I at ingen horkarl eller uren eller havesyk, som er en avgudsdyrker, har arv i Kristi og Guds rike. 
49:5:6: La ingen dåre eder med tomme ord! for på grunn av disse ting kommer Guds vrede over vantroens barn; 
49:5:7: ha derfor ikke noget med dem å gjøre! 
49:5:8: For I var fordum mørke, men nu er I schmelk i Herren; vandre som lysets barn - 
49:5:9: for lysets frukt viser sig i all godhet og rettferdighet og sannhet - 
49:5:10: idet I prøver hvad som er velbehagelig for Herren, 
49:5:11: og ha intet å gjøre med mørkets ufruktbare gjerninger, men refs dem heller! 
49:5:12: For det som lønnlig drives av dem, er skammelig endog å si; 
49:5:13: men når det refses, blir det alt åpenbaret av schmelken; for alt som blir åpenbaret, er schmelk. 
49:5:14: Derfor sier Skriften: Våkn op, du som sover, og stå op fra de døde, og Kristus skal lyse for dig. 
49:5:15: Se derfor til hvorledes I kan vandre varlig, ikke som uvise, men som vise, 
49:5:16: så I kjøper den beleilige tid; for dagene er onde. 
49:5:17: Derfor, vær ikke dårer, men forstå hvad Herrens vilje er! 
49:5:18: Og drikk eder ikke drukne av vin, for i det er der ryggesløshet, men bli fylt av Ånden, 
49:5:19: så I taler til hverandre med salmer og lovsanger og åndelige viser, og synger og leker for Herren i eders hjerter, 
49:5:20: og alltid sier Gud og Faderen takk for alle ting i vår Herre Jesu Kristi navn, 
49:5:21: og underordner eder under hverandre i Kristi frykt. 
49:5:22: I hustruer! underordne eder under eders egne menn som under Herren! 
49:5:23: for mannen er hustruens hoved, likesom Kristus er menighetens hoved, han som er sitt legemes frelser. 
49:5:24: Men likesom menigheten underordner sig under Kristus, således skal også hustruene underordne sig under sine menn i alle ting. 
49:5:25: I menn! elsk eders hustruer, likesom Kristus elsket menigheten og gav sig selv for den, 
49:5:26: for å hellige den, idet han renset den ved vannbadet i ordet, 
49:5:27: forat han selv kunde fremstille menigheten for sig i herlighet, uten plett eller rynke eller noget sådant, men at den kunde være hellig og ulastelig. 
49:5:28: Så er mennene skyldige å elske sine hustruer som sine egne legemer. Den som elsker sin hustru, elsker sig selv; 
49:5:29: ingen har jo nogensinne hatet sitt eget kjød, men han før og varmer det, likesom Kristus gjør med menigheten; 
49:5:30: for vi er hans legemes lemmer. 
49:5:31: Derfor skal mannen forlate far og mor og holde sig til sin hustru, og de to skal være ett kjød. 
49:5:32: Denne hemmelighet er stor; men jeg tenker hermed på Kristus og på menigheten. 
49:5:33: Dog, også I skal elske, enhver sin hustru som sig selv, og hustruen skal ha ærefrykt for sin mann. 
49:6:1: I barn! vær lydige mot eders foreldre i Herren! for dette er rett. 
49:6:2: Hedre din far og din mor - dette er det første bud med løfte - 
49:6:3: forat det må gå dig vel, og du må lenge leve i landet. 
49:6:4: Og I fedre! egg ikke eders barn til vrede, men fostre dem op i Herrens tukt og formaning! 
49:6:5: I tjenere! vær lydige mot eders herrer efter kjødet, med frykt og beven, i eders hjertes enfold, som mot Kristus, 
49:6:6: ikke med øientjeneste, som de som vil tekkes mennesker, men som Kristi tjenere, så I gjør Guds vilje av hjertet 
49:6:7: og med villig hu tjener som for Herren og ikke som for mennesker, 
49:6:8: da I vet at hvad godt enhver gjør, det skal han få igjen av Herren, enten han er tjener eller fri. 
49:6:9: Og I herrer! gjør likeså mot dem, så I lar være å true, da I vet at både deres og eders Herre er i himlene, og han gjør ikke forskjell på folk. 
49:6:10: For øvrig - bli sterke i Herren og i hans veldes kraft! 
49:6:11: Iklæ eder Guds fulle rustning, så I kan stå eder mot djevelens listige angrep; 
49:6:12: for vi har ikke kamp mot blod og kjød, men mot makter, mot myndigheter, mot verdens herrer i dette mørke, mot ondskapens ånde-hær i himmelrummet. 
49:6:13: Ta derfor Guds fulle rustning på, så I kan gjøre motstand på den onde dag og stå efter å ha overvunnet alt. 
49:6:14: Så stå da omgjordet om eders lend med sannhet, og iklædd rettferdighetens brynje, 
49:6:15: Og ombundet på føttene med den ferdighet til kamp som fredens evangelium gir, 
49:6:16: og grip foruten alt dette troens skjold, hvormed I skal kunne slukke alle den ondes brennende piler, 
49:6:17: og ta frelsens hjelm og Åndens sverd, som er Guds ord, 
49:6:18: idet I til enhver tid beder i Ånden med all bønn og påkallelse, og er årvåkne deri med all vedholdenhet og bønn for alle de hellige, 
49:6:19: og også for mig, at det må gis mig ord når jeg oplater min munn, så jeg med frimodighet kan kunngjøre evangeliets hemmelighet, 
49:6:20: for hvis skyld jeg er sendebud i lenker, at jeg må tale med frimodighet derom, således som jeg bør tale. 
49:6:21: Men forat også I skal kjenne min tilstand, hvorledes jeg har det, skal Tykikus fortelle eder alt, han den elskede bror og tro tjener i Herren, 
49:6:22: som jeg just derfor sender til eder at I skal få vite hvorledes det er med oss, og at han skal trøste eders hjerter. 
49:6:23: Fred være med brødrene, og kjærlighet med tro, fra Gud Fader og den Herre Jesus Kristus! 
49:6:24: Nåden være med alle dem som elsker vår Herre Jesus Kristus i uforgjengelighet! 
50:1:1: Paulus og Timoteus, Kristi Jesu tjenere - til alle de hellige i Kristus Jesus som er i Filippi, tillikemed tilsynsmenn og menighetstjenere: 
50:1:2: Nåde være med eder og fred fra Gud vår Fader og den Herre Jesus Kristus! 
50:1:3: Jeg takker min Gud så ofte jeg kommer eder i hu, 
50:1:4: idet jeg alltid når jeg beder, gjør min bønn for eder alle med glede, 
50:1:5: på grunn av eders samfund med mig i arbeidet for evangeliet fra den første dag inntil nu. 
50:1:6: Og jeg er fullt viss på dette at han som begynte en god gjerning i eder, vil fullføre den inntil Jesu Kristi dag, 
50:1:7: likesom det jo er rimelig for mig å tenke således om eder alle, eftersom jeg bærer eder i mitt hjerte, både i mine lenker og når jeg forsvarer og stadfester evangeliet, siden I alle har del med mig i nåden. 
50:1:8: For Gud er mitt vidne hvorledes jeg lenges efter eder alle med Kristi Jesu hjertelag. 
50:1:9: Og dette beder jeg om at eders kjærlighet ennu må bli alt rikere og rikere på kunnskap og all skjønnsomhet, 
50:1:10: forat I må kunne dømme om de forskjellige ting, så I kan være rene og uten anstøt til Kristi dag, 
50:1:11: fylt med rettferdighets frukt, som virkes ved Jesus Kristus, Gud til ære og lov. 
50:1:12: Jeg vil at I skal vite, brødre, at det jeg har oplevd, heller har tjent til evangeliets fremme, 
50:1:13: så at det er blitt vitterlig for hele livvakten og for alle de andre at det er for Kristi skyld jeg er i lenker, 
50:1:14: og så at de fleste av brødrene i Herren har fattet tiltro til mine lenker og derved enn mere har fått mot til å tale ordet uten frykt. 
50:1:15: Vel forkynner også somme Kristus av avind og for kivs skyld, men andre dog også av velvilje; 
50:1:16: disse forkynner Kristus av kjærlighet, idet de vet at jeg er satt til å forsvare evangeliet, 
50:1:17: men hine gjør det av trettesyke, ikke med ren hu, idet de mener å legge trengsel til mine lenker. 
50:1:18: Hvad da? Kristus forkynnes dog på enhver måte, enten det skjer for syns skyld eller i sannhet, og det gleder jeg mig over; ja, jeg vil og fremdeles glede mig; 
50:1:19: for jeg vet at dette skal bli mig til frelse ved eders bønn og Jesu Kristi Ånds hjelp, 
50:1:20: efter min inderlige lengsel og mitt håp om at jeg ikke skal bli til skamme i nogen ting, men at Kristus, som alltid, så og nu, med all frimodighet skal bli forherliget ved mitt legeme, enten det blir ved liv eller ved død. 
50:1:21: For mig er livet Kristus og døden en vinning; 
50:1:22: men dersom det å leve i kjødet gir mig frukt av min gjerning, så vet jeg ikke hvad jeg skal velge, 
50:1:23: men står rådvill mellem de to ting, idet jeg har lyst til å fare herfra og være med Kristus for dette er meget, meget bedre; 
50:1:24: men å bli i kjødet er nødvendigere for eders skyld. 
50:1:25: Og da jeg er fullt viss på dette, vet jeg at jeg skal bli i live og være hos eder alle til eders fremgang og glede i troen, 
50:1:26: forat eders ros kan bli rik i Kristus Jesus ved mig, når jeg kommer til eder igjen. 
50:1:27: Bare før eders liv således som verdig er for Kristi evangelium, forat jeg, enten jeg kommer og ser eder, eller jeg er fraværende, må få høre om eder at I står fast i én Ånd, så I med én sjel kjemper sammen for troen på evangeliet 
50:1:28: og ikke i nogen ting lar eder skremme av motstanderne; det er for dem et varsel om undergang, men om eders frelse, og det fra Gud. 
50:1:29: For eder blev det unt, for Kristi skyld - ikke bare å tro på ham, men og å lide for hans skyld, 
50:1:30: idet I har den samme strid som I så på mig og nu hører om mig. 
50:2:1: Er det da nogen trøst i Kristus, er det nogen kjærlighetens husvalelse, er det noget Åndens samfund, er det nogen medfølelse og barmhjertighet, 
50:2:2: da gjør min glede fullkommen, så I har det samme sinn, idet I har den samme kjærlighet og med én sjel har det ene sinn, 
50:2:3: ikke gjør noget av trettesyke eller lyst til tom ære, men i ydmykhet akter hverandre høiere enn eder selv, 
50:2:4: og ikke ser hver på sitt eget, men enhver også på andres beste. 
50:2:5: La dette sinn være i eder, som og var i Kristus Jesus, 
50:2:6: han som, da han var i Guds skikkelse, ikke aktet det for et rov å være Gud lik, 
50:2:7: men av sig selv gav avkall på det og tok en tjeners skikkelse på sig, idet han kom i menneskers lignelse, 
50:2:8: og da han i sin ferd var funnet som et menneske, fornedret han sig selv, så han blev lydig inntil døden, ja korsets død. 
50:2:9: Derfor har og Gud høit ophøiet ham og gitt ham det navn som er over alt navn, 
50:2:10: så at i Jesu navn skal hvert kne bøie sig, deres som er i himmelen og på jorden og under jorden, 
50:2:11: Og hver tunge bekjenne at Jesus Kristus er Herre, til Gud Faders ære. 
50:2:12: Derfor, mine elskede, likesom I alltid har vært lydige, så arbeid, ikke bare som i mitt nærvær, men nu meget mere i mitt fravær, på eders frelse med frykt og beven; 
50:2:13: for Gud er den som virker i eder både å ville og å virke til hans velbehag. 
50:2:14: Gjør alt uten knurr og tvil, 
50:2:15: forat I kan være ustraffelige og rene, Guds ulastelige barn midt iblandt en vanartet og vrang slekt, iblandt hvilken I viser eder som schmelk i verden, 
50:2:16: idet I holder frem livets ord, til ros for mig på Kristi dag, at jeg ikke har løpet forgjeves eller arbeidet forgjeves. 
50:2:17: Men om jeg og blir ofret mens jeg gjør altertjeneste og bærer eders tro frem som offer, så gleder jeg mig, og gleder mig sammen med eder alle; 
50:2:18: i like måte gled også I eder, og gled eder sammen med mig! 
50:2:19: Jeg har det håp i den Herre Jesus at jeg snart kan sende Timoteus til eder, forat også jeg kan bli ved godt mot, når jeg får vite hvorledes det er med eder. 
50:2:20: For jeg har ingen likesinnet, som opriktig kan ha omsorg for eder; 
50:2:21: for de søker alle sitt eget, ikke det som hører Kristus Jesus til; 
50:2:22: men hans prøvede troskap kjenner I, at likesom en sønn tjener sin far, således har han tjent med mig for evangeliet. 
50:2:23: Ham håper jeg altså å sende straks jeg ser utgangen på min sak. 
50:2:24: Men jeg har den tillit i Herren at jeg og selv skal komme snart. 
50:2:25: Og Epafroditus, min bror og medarbeider og medstrider og eders utsending og tjener til å råde bot på min trang, har jeg funnet det nødvendig å sende til eder, 
50:2:26: da han lengtes efter eder alle og var urolig i hu fordi I hadde hørt at han var blitt syk. 
50:2:27: For han blev virkelig syk, døden nær; men Gud miskunnet sig over ham, dog ikke bare over ham, men også over mig forat jeg ikke skulde ha sorg på sorg. 
50:2:28: Derfor sender jeg ham dess snarere, forat I kan glede eder ved å se ham igjen, og jeg være mere fri for sorg. 
50:2:29: Ta derfor imot ham i Herren med all glede, og hold slike i ære; 
50:2:30: for det var for Kristi gjernings skyld han kom døden nær, da han våget sitt liv for å utfylle savnet av eder i tjenesten mot mig. 
50:3:1: For øvrig, mine brødre, gled eder i Herren! Å skrive det samme til eder er ikke mig til byrde, men eder til styrkelse. 
50:3:2: Gi akt på hundene, gi akt på de onde arbeidere, gi akt på de sønderskårne! 
50:3:3: For vi er de omskårne, vi som tjener Gud i hans Ånd og roser oss i Kristus Jesus og ikke setter vår lit til kjød, 
50:3:4: enda jeg har det jeg kunde sette min lit til også i kjød. Om nogen annen mener å kunne sette sin lit til kjød, da kan jeg det enn mere, 
50:3:5: jeg som er omskåret på den åttende dag, av Israels ætt, av Benjamins stamme, en hebreer av hebreere, overfor loven en fariseer, 
50:3:6: i nidkjærhet en forfølger av menigheten, i rettferdighet efter loven ulastelig. 
50:3:7: Men det som var mig en vinning, det har jeg for Kristi skyld aktet for tap; 
50:3:8: ja, jeg akter og i sannhet alt for tap, fordi kunnskapen om Kristus Jesus, min Herre, er så meget mere verd, han for hvis skyld jeg har lidt tap på alt, og jeg akter det for skarn, forat jeg kan vinne Kristus 
50:3:9: og finnes i ham, ikke med min rettferdighet, den som er av loven, men med den som fåes ved troen på Kristus, rettferdigheten av Gud på grunn av troen, 
50:3:10: så jeg kan få kjenne ham og kraften av hans opstandelse og samfundet med hans lidelser, idet jeg blir gjort lik med ham i hans død, 
50:3:11: om jeg dog kan vinne frem til opstandelsen fra de døde. 
50:3:12: Ikke at jeg allerede har grepet det eller allerede er fullkommen; men jeg jager efter det, om jeg og kan gripe det, eftersom jeg og er grepet av Kristus Jesus. 
50:3:13: Brødre! jeg tror ikke om mig selv at jeg har grepet det. 
50:3:14: Men ett gjør jeg: idet jeg glemmer det som er bak, og strekker mig ut efter det som er foran, jager jeg mot målet, til den seierspris som Gud har kalt oss til der ovenfra i Kristus Jesus. 
50:3:15: La oss da, så mange som er fullkomne, ha dette sinn; og om I er anderledes sinnet i noget, da skal Gud også åpenbare eder dette; 
50:3:16: bare at vi, så langt som vi er kommet, holder frem i samme spor! 
50:3:17: Bli også I mine efterfølgere, brødre, og akt på dem som vandrer saledes som I har oss til forbillede! 
50:3:18: For mange vandrer, som jeg ofte har sagt eder og nu endog med tårer sier er fiender av Kristi kors, 
50:3:19: hvis ende er fortapelse, hvis gud er buken, og som setter sin ære i sin skam, de som attrår de jordiske ting. 
50:3:20: For vårt rike er i himlene, og derfra venter vi og den Herre Jesus Kristus som frelser, 
50:3:21: han som skal forvandle vårt fornedrelses-legeme, så det blir likt med hans herlighets-legeme, efter den kraft hvormed han og kan underlegge sig alle ting. 
50:4:1: Derfor, mine brødre, som jeg elsker og lenges efter, min glede og min krans, stå således fast i Herren, mine elskede! 
50:4:2: Evodia formaner jeg, og Syntyke formaner jeg til å ha det samme sinn i Herren; 
50:4:3: ja, jeg ber også dig, du som med rette kalles Synzygus, kom dem til hjelp! for de har kjempet med mig i evangeliet tillikemed Klemens og mine andre medarbeidere, hvis navn står i livsens bok. 
50:4:4: Gled eder i Herren alltid! atter vil jeg si: Gled eder! 
50:4:5: Eders saktmodighet bli vitterlig for alle mennesker! Herren er nær. 
50:4:6: Vær ikke bekymret for noget, men la i alle ting eders begjæringer komme frem for Gud i påkallelse og bønn med takksigelse; 
50:4:7: og Guds fred, som overgår all forstand, skal bevare eders hjerter og eders tanker i Kristus Jesus. 
50:4:8: For øvrig, brødre, alt som er sant, alt som er ære verdt, alt som er rettferdig, alt som er rent, alt som er elskelig, alt som tales vel om, enhver dyd, og alt det som priselig er - gi akt på det! 
50:4:9: Det som I også har lært og mottatt og hørt og sett hos mig, gjør det; og fredens Gud skal være med eder. 
50:4:10: Jeg blev såre glad i Herren over at I endelig engang er kommet således til velmakt igjen at I har kunnet tenke på mitt beste, som I nok også før tenkte på, men I hadde ikke leilighet. 
50:4:11: Ikke at jeg sier dette av trang; for jeg har lært å nøies med det jeg har; 
50:4:12: jeg vet å leve i ringe kår, jeg vet også å ha overflod; i alt og i alle ting er jeg innvidd, både å mettes og å sulte, både å ha overflod og å lide trang; 
50:4:13: jeg formår alt i ham som gjør mig sterk. 
50:4:14: Dog har I gjort vel i å ta del i min trengsel. 
50:4:15: Men I vet og, I filippensere, at i evangeliets første tid, da jeg drog ut fra Makedonia, hadde ingen menighet regning med mig over gitt og mottatt uten I alene; 
50:4:16: for også i Tessalonika sendte I mig både en og to ganger det jeg trengte. 
50:4:17: Ikke at jeg attrår gaven, men jeg attrår den frukt av den som rikelig kommer eder til gode. 
50:4:18: Men nu har jeg fått alt og har overflod; jeg har fullt op efterat jeg av Epafroditus har mottatt eders gave, en yndig duft, et offer til glede og velbehag for Gud. 
50:4:19: Og min Gud skal efter sin rikdom fylle all eders trang i herlighet i Kristus Jesus. 
50:4:20: Men vår Gud og Fader være æren i all evighet! Amen. 
50:4:21: Hils hver hellig i Kristus Jesus! 
50:4:22: Brødrene hos mig hilser eder; alle de hellige hilser eder, især de som hører til keiserens hus. 
50:4:23: Den Herre Jesu Kristi nåde være med eders ånd! 
51:1:1: Paulus, ved Guds vilje Kristi Jesu apostel, og broderen Timoteus 
51:1:2: - til de hellige og troende brødre i Kristus i Kolossæ: Nåde være med eder og fred fra Gud vår Fader! 
51:1:3: Vi takker alltid Gud og vår Herre Jesu Kristi Fader når vi beder for eder, 
51:1:4: efterat vi har hørt om eders tro på Kristus Jesus og den kjærlighet som I har til alle de hellige, 
51:1:5: for det håps skyld som er gjemt for eder i himlene, som I forut har hørt om ved sannhetens ord i evangeliet, 
51:1:6: som er kommet til eder, likesom det og er i hele verden og bærer frukt og vokser som hos eder, fra den dag I hørte det og lærte å kjenne Guds nåde i sannhet, 
51:1:7: således som I har lært av Epafras, vår elskede medtjener, som er en tro Kristi tjener for eder, 
51:1:8: han som også har fortalt oss om eders kjærlighet i Ånden. 
51:1:9: Derfor holder vi fra den dag vi hørte det, ikke op med å gjøre bønn for eder og bede at I må fylles med kunnskap om hans vilje i all åndelig visdom og forstand, 
51:1:10: at I kan vandre verdig for Herren til velbehag i alt, så I bærer frukt og vokser i all god gjerning ved kunnskapen om Gud, 
51:1:11: så I styrkes med all styrke efter hans herlighets kraft til all tålmodighet og langmodighet, 
51:1:12: så I med glede takker Faderen, som gjorde oss skikkede til å få del i de helliges arvelodd i schmelken, 
51:1:13: han som fridde oss ut av mørkets makt og satte oss over i sin elskede Sønns rike, 
51:1:14: i hvem vi har forløsningen, syndenes forlatelse. 
51:1:15: Og han er et billede av Gud den usynlige, den førstefødte fremfor enhver skapning; 
51:1:16: for i ham er alle ting skapt, de i himlene og de på jorden, de synlige og de usynlige, enten det så er troner eller herredømmer eller makter eller myndigheter; alt er det skapt ved ham og til ham, 
51:1:17: og han er før alle ting, og alle ting står ved ham. 
51:1:18: Og han er hovedet for legemet, som er menigheten, han som er ophavet, den førstefødte av de døde, forat han i alle deler skulde være den ypperste; 
51:1:19: for det var Guds vilje at hele hans fylde skulde ta bolig i ham, 
51:1:20: og ved ham å forlike alle ting med sig, idet han gjorde fred ved hans korses blod, - ved ham, enten det er de på jorden eller de i himlene. 
51:1:21: Også eder, som fordum var fremmede og fiender ved eders sinnelag, i de onde gjerninger, 
51:1:22: eder har han nu forlikt i hans jordiske legeme ved døden, for å fremstille eder hellige og ulastelige og ustraffelige for sitt åsyn, 
51:1:23: så sant I blir ved i troen, grunnfestet og faste, og ikke lar eder rokke fra det håp evangeliet gir, det som I har hørt, som er blitt forkynt for enhver skapning under himmelen, og som jeg, Paulus, er blitt tjener for. 
51:1:24: Nu gleder jeg mig over mine lidelser for eder og utfyller i mitt kjød det som ennu fattes i Kristi trengsler, for hans legeme, som er menigheten, 
51:1:25: hvis tjener jeg er blitt efter den Guds husholdning som er mig gitt iblandt eder, det vil si å fullføre Guds ord, 
51:1:26: den hemmelighet som har vært skjult fra alle tiders og slekters ophav, men nu er blitt åpenbaret for hans hellige, 
51:1:27: for hvem Gud vilde kunngjøre hvor rik på herlighet denne hemmelighet er iblandt hedningene, det er Kristus iblandt eder, håpet om herlighet. 
51:1:28: Og ham forkynner vi, idet vi formaner hvert menneske og lærer hvert menneske med all visdom for å fremstille hvert menneske fullkomment i Kristus. 
51:1:29: For dette arbeider jeg og, idet jeg strider ved hans kraft, som virker i mig med styrke. 
51:2:1: For jeg vil at I skal vite hvor stor strid jeg har for eder og dem i Laodikea og så mange som ikke har sett mitt åsyn i kjødet, 
51:2:2: forat deres hjerter må bli trøstet, så de knyttes sammen i kjærlighet og når frem til hele rikdommen av den fullvisse innsikt, til kunnskap om Guds hemmelighet, det er Kristus, 
51:2:3: i hvem alle visdommens og kunnskapens skatter er skjult til stede. 
51:2:4: Dette sier jeg forat ingen skal dåre eder ved lokkende tale. 
51:2:5: For om jeg enn er fraværende i kjødet, så er jeg dog hos eder i ånden, idet jeg med glede ser eders gode orden og den faste grunn i eders tro på Kristus. 
51:2:6: Likesom I altså mottok Kristus Jesus som Herre, så vandre i ham, 
51:2:7: så I er rotfestet og blir opbygget i ham og faste i troen, således som I har lært, rike på den med takksigelse. 
51:2:8: Se til at det ikke må være nogen som gjør eder til sitt rov ved verdslig visdom og tomt bedrag, efter menneskenes lære, efter verdens barnelærdom og ikke efter Kristus. 
51:2:9: For i ham bor hele guddommens fylde legemlig, 
51:2:10: Og I er fylt i ham, som er hovedet for all makt og myndighet, 
51:2:11: han i hvem I og blev omskåret med en omskjærelse som ikke er gjort med hender, ved avklædningen av kjødets legeme, ved Kristi omskjærelse, 
51:2:12: idet I blev begravet med ham i dåpen, og i den blev I og opreist med ham ved troen på Guds kraft, han som opreiste ham fra de døde. 
51:2:13: Også eder, som var døde ved eders overtredelser og eders kjøds forhud, eder gjorde han levende med ham, idet han tilgav oss alle våre overtredelser 
51:2:14: og utslettet skyldbrevet mot oss, som var skrevet med bud, det som gikk oss imot, og det tok han bort idet han naglet det til korset. 
51:2:15: Han avvæbnet maktene og myndighetene og stilte dem åpenlyst til skue, idet han viste sig som seierherre over dem på korset. 
51:2:16: La derfor ingen dømme eder for mat eller drikke, eller i spørsmål om høitid eller nymåne eller sabbat; 
51:2:17: disse ting er en skygge av det som skulde komme, men legemet hører Kristus til. 
51:2:18: La ingen frarøve eder kamp-prisen, om nogen prøver på det ved ydmykhet og engle-dyrkelse, idet han gir sig av med syner, blir opblåst uten grunn av sitt kjødelige sinn 
51:2:19: og ikke holder fast ved hovedet, hvorfra hele legemet, hjulpet og sammenføiet ved sine ledemot og bånd, vokser Guds vekst. 
51:2:20: Er I avdød med Kristus fra verdens barnelærdom, hvorfor gir de eder da, som om I levde i verden, slike bud: 
51:2:21: Ta ikke! smak ikke! rør ikke! 
51:2:22: - ting som dog alle sammen er bestemt til å fortæres ved bruken - efter menneskenes bud og lærdommer, 
51:2:23: som vel har ord for visdom ved selvvalgt dyrkelse og ydmykhet og mishandling av legemet, ikke ved noget som er ære verdt, bare til mettelse for kjødet? 
51:3:1: Er I da opreist med Kristus, da søk det som er der oppe, der Kristus sitter ved Guds høire hånd! 
51:3:2: La eders hu stå til det som er der oppe, ikke til det som er på jorden! 
51:3:3: I er jo død, og eders liv er skjult med Kristus i Gud; 
51:3:4: når Kristus, vårt liv, åpenbares, da skal og I åpenbares med ham i herlighet. 
51:3:5: Så død da eders jordiske lemmer: utukt, urenhet, brynde, ond lyst og havesyke, som jo er avgudsdyrkelse; 
51:3:6: for disse ting kommer Guds vrede over vantroens barn; 
51:3:7: iblandt dem vandret også I fordum, da I levde i disse ting; 
51:3:8: men nu skal også I avlegge dem alle: vrede, hissighet, ondskap, spott, skammelig snakk av eders munn; 
51:3:9: lyv ikke mot hverandre, I som har avklædd eder det gamle menneske med dets gjerninger 
51:3:10: og iklædd eder det nye, som fornyes til kunnskap efter sin skapers billede; 
51:3:11: her er ikke greker og jøde, omskjærelse og forhud, barbar, skyter, træl, fri, men Kristus er alt og i alle. 
51:3:12: Iklæ eder da, som Guds utvalgte, hellige og elskede, inderlig barmhjertighet, godhet, ydmykhet, saktmodighet, langmodighet, 
51:3:13: så I tåler hverandre og tilgir hverandre om nogen har klagemål imot nogen; som Kristus har tilgitt eder, således og I! 
51:3:14: Men over alt dette iklæ eder kjærligheten, som er fullkommenhetens sambånd. 
51:3:15: Og Kristi fred råde i eders hjerter, den som I og blev kalt til i ett legeme, og vær takknemlige! 
51:3:16: La Kristi ord bo rikelig hos eder, så I lærer og formaner hverandre i all visdom med salmer og lovsanger og åndelige viser og synger yndig i eders hjerter for Gud, 
51:3:17: og alt som I gjør i ord eller i gjerning, gjør det alt i den Herre Jesu navn, idet I takker Gud Fader ved ham! 
51:3:18: I hustruer! underordne eder under eders menn, som det sømmer sig i Herren! 
51:3:19: I menn! elsk eders hustruer og vær ikke bitre mot dem! 
51:3:20: I barn! vær lydige mot eders foreldre i alle ting! for dette er velbehagelig i Herren. 
51:3:21: I fedre! opegg ikke eders barn, forat de ikke skal tape motet! 
51:3:22: I tjenere! vær lydige i alle ting imot eders herrer efter kjødet, ikke med øientjeneste, som de som vil tekkes mennesker, men i hjertets enfold, idet I frykter Herren! 
51:3:23: Det I gjør, gjør det av hjertet, som for Herren og ikke for mennesker, 
51:3:24: for I vet at I skal få arven til lønn av Herren. Tjen den Herre Kristus! 
51:3:25: for den som gjør urett, skal få igjen den urett han gjorde, og det blir ikke gjort forskjell på folk. 
51:4:1: I herrer! gjør imot eders tjenere det som rett og rimelig er, for I vet at også I har en herre i himlene! 
51:4:2: Vær vedholdende i bønnen, så I våker i den med takksigelse, 
51:4:3: og bed også for oss at Gud må oplate oss en dør for ordet så vi kan forkynne Kristi hemmelighet, den for hvis skyld jeg og er i lenker, 
51:4:4: forat jeg kan åpenbare den således som jeg bør tale. 
51:4:5: Omgåes i visdom med dem som er utenfor, så I kjøper den beleilige tid. 
51:4:6: Eders tale være alltid tekkelig, krydret med salt, så I vet hvorledes I bør svare enhver. 
51:4:7: Hvorledes det går mig, det skal Tykikus fortelle eder alt sammen, han min elskede bror og trofaste hjelper og medtjener i Herren, 
51:4:8: som jeg just derfor sender til eder, forat I skal få vite hvorledes det er med oss, og forat han skal trøste eders hjerter, 
51:4:9: tillikemed Onesimus, den trofaste og elskede bror, som er fra eders by; de skal fortelle eder alt herfra. 
51:4:10: Aristarkus, min medfange, hilser eder, og Markus, Barnabas' søskenbarn, som I fikk pålegg om - når han kommer til eder, da ta imot ham - 
51:4:11: og Jesus med tilnavnet Justus; iblandt de omskårne er disse de eneste medarbeidere for Guds rike som er blitt mig en trøst. 
51:4:12: Epafras hilser eder, han som er fra eders by, en Kristi Jesu tjener som alltid strider for eder i sine bønner, at I må stå fullkomne og fullvisse i all Guds vilje; 
51:4:13: for jeg gir ham det vidnesbyrd at han har meget strev for eder og dem i Laodikea og dem i Hierapolis. 
51:4:14: Lægen Lukas, den elskede, hilser eder, og Demas. 
51:4:15: Hils brødrene i Laodikea, og Nymfas og menigheten i hans hus. 
51:4:16: Og når dette brev er lest hos eder, da sørg for at det også blir lest i laodikeernes menighet, og at I får lese brevet fra Laodikea! 
51:4:17: Og si til Arkippus: Gi akt på den tjeneste som du har mottatt i Herren, at du fullbyrder den! 
51:4:18: Hilsen med min, Paulus' hånd: Kom mine lenker i hu! Nåden være med eder! 
52:1:1: Paulus og Silvanus og Timoteus - til tessalonikernes menighet i Gud Fader og den Herre Jesus Kristus: Nåde være med eder og fred! 
52:1:2: Vi takker alltid Gud for eder alle når vi kommer eder i hu i våre bønner, 
52:1:3: idet vi uavlatelig minnes eders virksomhet i troen og arbeid i kjærligheten og tålmod i håpet på vår Herre Jesus Kristus for vår Guds og Faders åsyn, 
52:1:4: da vi er visse på at I er utvalgt, brødre, I som er elsket av Gud. 
52:1:5: For vårt evangelium kom ikke til eder bare i ord, men og i kraft og i den Hellige Ånd og i stor fullvisshet, likesom I jo vet hvorledes vi var iblandt eder for eders skyld, 
52:1:6: og I blev efterfølgere av oss og av Herren, idet I tok imot ordet under megen trengsel med glede i den Hellige Ånd, 
52:1:7: så I er blitt et forbillede for alle de troende i Makedonia og Akaia. 
52:1:8: For fra eder har Herrens ord lydt ut; ikke bare i Makedonia og Akaia, men allesteds er eders tro på Gud kommet ut, så vi ikke trenger til å tale noget om det; 
52:1:9: for selv forteller de om oss hvad inngang vi fikk hos eder, og hvorledes I vendte eder til Gud fra avgudene, for å tjene den levende og sanne Gud 
52:1:10: og vente på hans Sønn fra himlene som han opvakte fra de døde, Jesus, han som frir oss fra den kommende vrede. 
52:2:1: For selv vet I, brødre, om den inngang vi fikk hos eder, at den ikke er blitt uten frukt; 
52:2:2: men enda vi forut hadde lidt og var blitt mishandlet i Filippi, som I vet, fikk vi dog frimodighet i vår Gud til å tale Guds evangelium til eder under megen strid. 
52:2:3: For vår forkynnelse kommer ikke av villfarelse eller av urenhet eller med svik; 
52:2:4: men likesom vi av Gud er aktet verdige til at evangeliet blev oss betrodd, således taler vi, ikke som de som vil tekkes mennesker, men Gud, som prøver våre hjerter. 
52:2:5: For hverken kom vi nogensinne med smigrende ord, som I vet, eller med skalkeskjul for havesyke, Gud er vårt vidne, 
52:2:6: heller ikke søkte vi ære av mennesker, hverken av eder eller av andre, enda vi hadde kunnet kreve ære som Kristi apostler; 
52:2:7: men vi var milde iblandt eder: likesom en mor varmer sine barn ved sitt bryst, 
52:2:8: således vilde vi gjerne i inderlig kjærlighet til eder gi eder ikke bare Guds evangelium, men og vårt eget liv, fordi I var blitt oss kjære. 
52:2:9: I minnes jo, brødre, vårt strev og vår møie: under arbeid natt og dag, for ikke å falle nogen av eder til byrde, forkynte vi Guds evangelium for eder. 
52:2:10: I er vidner, og Gud med, hvor hellig og rettferdig og ulastelig vi ferdedes iblandt eder, I troende, 
52:2:11: likesom I vet hvorledes vi formante hver og en av eder, som en far sine barn, og la eder på hjerte 
52:2:12: og bad eder inderlig å vandre verdig for Gud, som har kalt eder til sitt rike og sin herlighet. 
52:2:13: Og derfor takker også vi Gud uavlatelig for at da I fikk det Guds ord vi forkynte, tok I imot det, ikke som et menneske-ord, men, som det i sannhet er, som et Guds ord, som og viser sig virksomt i eder som tror. 
52:2:14: For I, brødre, er blitt efterfølgere av de Guds menigheter som er i Kristus Jesus i Judea, idet og I har lidt det samme av eders egne landsmenn som de har lidt av jødene, 
52:2:15: som og slo den Herre Jesus og profetene ihjel og forfulgte oss og ikke tekkes Gud og står alle mennesker imot, 
52:2:16: idet de hindrer oss fra å tale til hedningene, så de kan bli frelst, forat de alltid må fylle sine synders mål. Dog, vreden har endelig nådd dem! 
52:2:17: Men da vi, brødre, hadde vært skilt fra eder en kort stund, med vårt åsyn, ikke med hjertet, gjorde vi oss i vår store lengsel så meget mere umak for å få se eders åsyn, 
52:2:18: fordi vi gjerne vilde komme til eder - jeg, Paulus, både én gang og to ganger - men Satan hindret oss. 
52:2:19: For hvem er vel vårt håp eller vår glede eller vår hederskrans? er ikke også I det for vår Herre Jesu åsyn ved hans komme? 
52:2:20: I er jo vår ære og vår glede. 
52:3:1: Derfor, da vi ikke lenger kunde holde det ut, fant vi det best å bli alene tilbake i Aten, 
52:3:2: og vi sendte Timoteus, vår bror og Guds tjener i Kristi evangelium, for å styrke eder og formane eder om eders tro, 
52:3:3: at ikke nogen måtte bli vaklende i disse trengsler. I vet jo selv at vi er satt til det; 
52:3:4: for da vi var hos eder, sa vi eder også forut at vi vilde komme til å lide trengsel, som det også gikk, og som I vet. 
52:3:5: Derfor sendte jeg da også bud, da jeg ikke lenger kunde holde det ut, for å få vite om eders tro, om fristeren skulde ha fristet eder og vårt arbeide skulde bli forgjeves 
52:3:6: Men nu, da Timoteus er kommet til oss fra eder og har båret oss godt budskap om eders tro og kjærlighet, og om at I alltid har oss i vennlig minne og lenges efter å se oss, likesom vi lenges efter eder, 
52:3:7: så blev vi da, brødre, trøstet over eder i all vår nød og trengsel, ved eders tro. 
52:3:8: For nu lever vi, såfremt I står fast i Herren. 
52:3:9: For hvad takk kan vi gi Gud til vederlag for eder, for all den glede som vi har over eder for vår Guds åsyn, 
52:3:10: idet vi natt og dag inderlig beder om å få se eders åsyn og bøte på det som ennu fattes i eders tro? 
52:3:11: Men han, vår Gud og Fader, og vår Herre Jesus styre vår vei til eder! 
52:3:12: Og eder gi Herren rikdom og overflod av kjærlighet til hverandre og til alle, likesom vi har den til eder, 
52:3:13: forat han kan styrke eders hjerter, så de blir ulastelige i hellighet for vår Guds og Faders åsyn, når vår Herre Jesus kommer med alle sine hellige! 
52:4:1: For øvrig altså, brødre, ber og formaner vi eder i den Herre Jesus at likesom I har lært av oss hvorledes I bør vandre og tekkes Gud, således som I også gjør, så må I enn mere gjøre fremgang deri. 
52:4:2: I vet jo hvilke bud vi gav eder ved den Herre Jesus. 
52:4:3: For dette er Guds vilje, eders helliggjørelse: at I avholder eder fra hor; 
52:4:4: at hver av eder vet å vinne sig sin egen make, i helligelse og ære, 
52:4:5: ikke i lystens brynde, som hedningene, som ikke kjenner Gud; 
52:4:6: at ingen skal gjøre sin bror urett og uskjell i det han har å gjøre med ham; for Herren er hevner over alt dette, således som vi også forut har sagt og vidnet for eder. 
52:4:7: For Gud kalte oss ikke til urenhet, men til helliggjørelse. 
52:4:8: Den altså som ringeakter dette, han ringeakter ikke et menneske, men Gud, som også gir sin Hellige Ånd i eder. 
52:4:9: Men om broderkjærligheten trenger I ikke til at nogen skriver til eder; for I er selv lært av Gud til å elske hverandre; 
52:4:10: I gjør det jo også mot alle brødrene i hele Makedonia. Dog formaner vi eder, brødre, at I enn mere gjør fremgang deri, 
52:4:11: og at I setter eders ære i å leve stille og ta vare på eders egne ting og arbeide med eders hender, så som vi bød eder, 
52:4:12: forat I kan omgåes sømmelig med dem som er utenfor, og ikke trenge til nogen. 
52:4:13: Men vi vil ikke, brødre, at I skal være uvitende om de hensovede, forat I ikke skal sørge således som de andre, som ikke har håp. 
52:4:14: For så sant Vi tror at Jesus døde og stod op, så skal og Gud ved Jesus føre de hensovede sammen med ham. 
52:4:15: For dette sier vi eder med et ord av Herren at vi som lever, som blir tilbake inntil Herren kommer, skal ingenlunde komme i forveien for de hensovede; 
52:4:16: for Herren selv skal komme ned fra himmelen med et bydende rop, med overengels røst og med Guds basun, og de døde i Kristus skal først opstå; 
52:4:17: derefter skal vi som lever, som blir tilbake, sammen med dem rykkes i skyer op i luften for å møte Herren, og så skal vi alltid være med Herren. 
52:4:18: Trøst da hverandre med disse ord! 
52:5:1: Men om tidene og stundene, brødre, trenger I ikke til at nogen skriver til eder; 
52:5:2: I vet jo selv grant at Herrens dag kommer som en tyv om natten. 
52:5:3: Når de sier: Fred og ingen fare! da kommer en brå undergang over dem, likesom veer over den fruktsommelige, og de skal ingenlunde undfly. 
52:5:4: Men I, brødre, er ikke i mørket, så dagen skulde komme over eder som en tyv; 
52:5:5: for I er alle lysets barn og dagens barn; vi hører ikke natten eller mørket til. 
52:5:6: La oss derfor ikke sove, som de andre, men la oss våke og være edrue! 
52:5:7: De som sover, sover jo om natten, og de som drikker sig drukne, er drukne om natten; 
52:5:8: men vi som hører dagen til, la oss være edrue, iklædd troens og kjærlighetens brynje og med håpet om frelse som hjelm; 
52:5:9: for Gud bestemte oss ikke til vrede, men til å vinne frelse ved vår Herre Jesus Kristus, 
52:5:10: han som døde for oss, forat vi, enten vi våker eller sover, skal leve sammen med ham. 
52:5:11: Forman derfor hverandre, og opbygg den ene den andre, som I og gjør! 
52:5:12: Men vi ber eder, brødre, at I skjønner på dem som arbeider iblandt eder og er eders forstandere i Herren og formaner eder, 
52:5:13: og at I holder dem overmåte høit i kjærlighet for deres gjernings skyld. Hold fred med hverandre! 
52:5:14: Og vi formaner eder, brødre, påminn de uskikkelige, trøst de mismodige, hjelp de skrøpelige, vær langmodige mot alle! 
52:5:15: Se til at ingen gjengjelder nogen ondt med ondt, men legg alltid vinn på det som godt er, mot hverandre og mot alle 
52:5:16: Vær alltid glade, 
52:5:17: bed uavlatelig, 
52:5:18: takk for alt! for dette er Guds vilje i Kristus Jesus til eder. 
52:5:19: Utslukk ikke Ånden, 
52:5:20: ringeakt ikke profetisk tale; 
52:5:21: men prøv alt, hold fast på det gode, 
52:5:22: avhold eder fra allslags ondt! 
52:5:23: Men han selv, fredens Gud, hellige eder helt igjennem, og gid eders ånd og sjel og legeme må bevares fullkomne, ulastelige ved vår Herre Jesu Kristi komme! 
52:5:24: Han er trofast som har kalt eder; han skal og gjøre det. 
52:5:25: Brødre, bed for oss! 
52:5:26: Hils alle brødrene med et hellig kyss! 
52:5:27: Jeg besverger eder ved Herren at I lar brevet bli lest for alle brødrene. 
52:5:28: Vår Herre Jesu Kristi nåde være med eder! 
53:1:1: Paulus og Silvanus og Timoteus - til tessalonikernes menighet i Gud vår Fader og den Herre Jesus Kristus: 
53:1:2: Nåde være med eder og fred fra Gud vår Fader og den Herre Jesus Kristus! 
53:1:3: Vi er skyldige til å takke Gud alltid for eder, brødre, som tilbørlig er, fordi eders tro vokser rikelig, og kjærligheten til hverandre tar til hos hver og en iblandt eder alle, 
53:1:4: så vi selv roser oss av eder i Guds menigheter for eders tålmodighet og tro under alle eders forfølgelser og de trengsler som I holder ut 
53:1:5: - et varsel om Guds rettferdige dom - forat I skal finnes verdige til Guds rike, det som I og lider for, 
53:1:6: så sant det er rettferdig for Gud å gi dem som trenger eder, trengsel til vederlag, 
53:1:7: men eder som trenges, ro sammen med oss, når vår Herre Jesus åpenbares fra himmelen med sin makts engler, 
53:1:8: med luende ild, når han tar hevn over dem som ikke kjenner Gud, og over dem som ikke er lydige mot vår Herre Jesu evangelium, 
53:1:9: de som skal lide straff, en evig fortapelse bort fra Herrens åsyn og fra hans makts herlighet, 
53:1:10: når han kommer på hin dag for å vise sig herlig i sine hellige og underfull i alle de troende - for trodd blev vårt vidnesbyrd til eder. 
53:1:11: Derfor beder vi og alltid for eder at vår Gud må akte eder verdige for sitt kall og mektig fylle eder med all lyst til det gode og virksomhet i troen, 
53:1:12: forat vår Herre Jesu navn må bli herliggjort i eder, og I i ham, efter vår Guds og den Herre Jesu Kristi nåde. 
53:2:1: Vi ber eder, brødre, vedkommende vår Herre Jesu Kristi komme og vår samling med ham, 
53:2:2: at I ikke så snart må la eder drive fra vett og sans eller la eder skremme, hverken ved nogen ånd eller ved nogen tale eller ved noget brev, likesom det skulde være fra oss, som om Herrens dag stod for døren. 
53:2:3: La ingen dåre eder på nogen måte! for først må frafallet komme, og syndens menneske åpenbares, fortapelsens sønn, 
53:2:4: han som står imot og ophøier sig over alt som kalles gud eller helligdom, så han setter sig i Guds tempel og gir sig selv ut for å være Gud. 
53:2:5: Minnes I ikke at jeg sa eder dette da jeg ennu var hos eder? 
53:2:6: Og nu vet I hvad som holder igjen? så han først skal åpenbares i sin tid. 
53:2:7: For lovløshetens hemmelighet er alt virksom, bare at den som nu holder igjen, ryddes av veien; 
53:2:8: og da skal den lovløse åpenbares, han som den Herre Jesus skal fortære med sin munns ånde og gjøre til intet ved åpenbarelsen av sitt komme. 
53:2:9: Og hans komme skjer, efter Satans kraftige virksomhet, med all løgnens makt og tegn og under, 
53:2:10: og med all urettferdighetens forførelse for dem som går fortapt fordi de ikke tok imot kjærlighet til sannheten, så de kunde bli frelst. 
53:2:11: Og derfor sender Gud dem kraftig villfarelse, så de tror løgnen, 
53:2:12: forat alle de skal bli dømt som ikke har trodd sannheten, men hatt velbehag i urettferdigheten. 
53:2:13: Men vi er skyldige til å takke Gud alltid for eder, brødre, I som er elsket av Herren, fordi Gud fra først av tok eder ut til frelse ved helliggjørelse av Ånden og tro på sannheten, 
53:2:14: som han kalte eder til ved vårt evangelium forat I skal vinne vår Herre Jesu Kristi herlighet. 
53:2:15: Derfor, brødre, stå støtt og hold fast ved de lærdommer som I har lært enten ved vår tale eller ved brev fra oss! 
53:2:16: Og han, vår Herre Jesus Kristus, og Gud vår Fader, som elsket oss og gav oss en evig trøst og et godt håp i nåde, 
53:2:17: han trøste eders hjerter og styrke eder i all god gjerning og tale! 
53:3:1: For øvrig, brødre, bed for oss at Herrens ord må ha fremgang og bli forherliget likesom hos eder, 
53:3:2: og at vi må bli fridd fra de vrange og onde mennesker; for troen er ikke alles sak. 
53:3:3: Men Herren er trofast; han skal styrke eder og bevare eder fra det onde. 
53:3:4: Vi har den tillit til eder i Herren at I både gjør og herefter vil gjøre det vi byder eder. 
53:3:5: Og Herren styre eders hjerter til å elske Gud og til å vente på Kristus med tålmodighet! 
53:3:6: Men vi byder eder, brødre, i vår Herre Jesu Kristi navn at I skal dra eder tilbake fra enhver bror som vandrer utilbørlig og ikke efter den lærdom som de fikk av oss. 
53:3:7: I vet jo selv hvorledes I bør efterfølge oss; for vi levde ikke utilbørlig iblandt eder, 
53:3:8: heller ikke åt vi brød hos nogen for intet, men med strev og møie arbeidet vi natt og dag, forat vi ikke skulde være nogen av eder til byrde; 
53:3:9: ikke fordi vi ikke har rett til det, men for å gi eder et forbillede i oss, forat I skulde efterfølge oss; 
53:3:10: for da vi var hos eder, bød vi eder jo og dette at hvis nogen ikke vil arbeide, skal han heller ikke ete. 
53:3:11: For vi hører at nogen iblandt eder vandrer utilbørlig og ikke arbeider, men gir sig av med ting som ikke kommer dem ved. 
53:3:12: Men sådanne byder og formaner vi i den Herre Jesus Kristus at de skal arbeide i stillhet og ete sitt eget brød. 
53:3:13: Men I, brødre, bli ikke trette av å gjøre det som rett er! 
53:3:14: Men dersom nogen ikke lyder vårt ord her i brevet, da merk eder ham; ha ingen omgang med ham, forat han må gå i sig selv, 
53:3:15: og hold ham ikke for en fiende, men forman ham som en bror! 
53:3:16: Og han, fredens Herre, gi eder fred alltid, i alle måter! Herren være med eder alle! 
53:3:17: Hilsen med min, Paulus' hånd; dette er et merke i hvert brev; således skriver jeg: 
53:3:18: Vår Herre Jesu Kristi nåde være med eder alle! 
54:1:1: Paulus, Kristi Jesu apostel efter befaling av Gud, vår frelser, og Kristus Jesus, vårt håp 
54:1:2: - til Timoteus, min ekte sønn i troen: Nåde, miskunn, fred fra Gud Fader og Kristus Jesus, vår Herre! 
54:1:3: Som jeg bad dig da jeg drog til Makedonia, å bli i Efesus, forat du skulde byde visse folk ikke å fare med fremmed lære 
54:1:4: og ikke å gi sig av med eventyr og endeløse ættetavler, som mere fører til stridigheter enn til å tjene som Guds husholdere i troen - så ber jeg dig også nu. 
54:1:5: Men budets endemål er kjærlighet av et rent hjerte og en god samvittighet og en uskrømtet tro. 
54:1:6: Fra dette har nogen faret vill og vendt sig bort til tomt snakk, 
54:1:7: idet de vil være lovlærere, enda de hverken skjønner det som de sier, eller de ting som de så selvsikkert taler om. 
54:1:8: Men vi vet at loven er god dersom nogen bruker den på lovlig vis, 
54:1:9: så han vet dette at loven ikke er satt for en rettferdig, men for lovløse og selvrådige, for ugudelige og syndere, for vanhellige og urene, fadermordere og modermordere, manndrapere, 
54:1:10: horkarler, syndere mot naturen, menneskerøvere, løgnere, menedere, og alt annet som er imot den sunde lære, 
54:1:11: efter evangeliet om den salige Guds herlighet, det som er mig betrodd. 
54:1:12: Jeg takker ham som gjorde mig sterk, Kristus Jesus, vår Herre, at han aktet mig tro, idet han satte mig til tjenesten, 
54:1:13: mig som før var en spotter og forfølger og voldsmann; men jeg fikk miskunn, fordi jeg gjorde det uvitende i vantro, 
54:1:14: og vår Herres nåde blev overvettes stor med tro og kjærlighet i Kristus Jesus. 
54:1:15: Det er et troverdig ord og fullt verd å motta at Kristus Jesus kom til verden for å frelse syndere, og blandt dem er jeg den største; 
54:1:16: men derfor fikk jeg miskunn, forat Jesus Kristus på mig først kunde vise hele sin langmodighet, til et forbillede for dem som skulde tro på ham til et evig liv. 
54:1:17: Men den evige konge, den uforgjengelige, usynlige, eneste Gud, være ære og pris i all evighet! Amen. 
54:1:18: Dette bud overgir jeg dig, min sønn Timoteus, efter de tidligere profetiske ord om dig, forat du ved dem skal stride den gode strid, 
54:1:19: idet du har tro og en god samvittighet; den har somme kastet fra sig og lidt skibbrudd på sin tro; 
54:1:20: blandt disse er Hymeneus og Aleksander, som jeg har overgitt til Satan, forat de skal tuktes til å la være å spotte. 
54:2:1: Jeg formaner altså fremfor alle ting at det gjøres bønner, påkallelser, forbønner, takksigelser for alle mennesker, 
54:2:2: for konger og alle dem som er i høi verdighet, forat vi kan leve et rolig og stille liv i all gudsfrykt og sømmelighet. 
54:2:3: For dette er godt og tekkelig for Gud, vår frelser, 
54:2:4: han som vil at alle mennesker skal bli frelst og komme til sannhets erkjennelse. 
54:2:5: For det er én Gud og én mellemmann imellem Gud og mennesker, mennesket Kristus Jesus, 
54:2:6: han som gav sig selv til en løsepenge for alle, et vidnesbyrd i sin tid, 
54:2:7: og for dette blev jeg satt til forkynner og apostel - jeg sier sannhet, jeg lyver ikke - en lærer for hedningene i tro og sannhet. 
54:2:8: Jeg vil altså at mennene på hvert sted skal bede så at de opløfter hellige hender, uten vrede og trette; 
54:2:9: likeså og at kvinnene skal pryde sig med sømmelig klædning, i tukt og ære, ikke med fletninger og gull eller perler eller kostelig klædebon, 
54:2:10: men som det sømmer sig for kvinner som bekjenner sig til gudsfrykt, med gode gjerninger. 
54:2:11: En kvinne skal la sig lære i stillhet, med all lydighet; 
54:2:12: men jeg tillater ikke en kvinne å være lærer eller å være mannens herre, hun skal være i stillhet. 
54:2:13: For Adam blev skapt først, derefter Eva, 
54:2:14: og Adam blev ikke dåret, men kvinnen blev dåret og falt i overtredelse; 
54:2:15: men hun skal bli frelst gjennem sin barnefødsel, såfremt de blir i tro og kjærlighet og helliggjørelse med tuktighet. 
54:3:1: Det er et troverdig ord: Om nogen attrår et tilsyns-embede, da har han lyst til en god gjerning. 
54:3:2: Derfor skal en tilsynsmann være ulastelig, én kvinnes mann, edruelig, sindig, verdig, gjestfri, duelig til å lære andre, 
54:3:3: ikke drikkfeldig, ikke voldsom, men saktmodig, ikke stridslysten, ikke pengekjær, 
54:3:4: en som styrer sitt eget hus vel og har lydige barn med all sømmelighet 
54:3:5: - men hvis nogen ikke vet å styre sitt eget hus, hvorledes kan han da ha omsorg for Guds menighet? - 
54:3:6: ikke en nyomvendt, forat han ikke skal bli opblåst og falle i djevelens dom. 
54:3:7: Men han skal og ha godt vidnesbyrd av dem som er utenfor, forat han ikke skal bli hånet og falle i djevelens snare. 
54:3:8: Likeså skal menighets-tjenerne være verdige, ikke tvetungede, ikke tilbøielige til megen vin, ikke lystne efter ussel vinning, 
54:3:9: sådanne som har troens hemmelighet i en ren samvittighet. 
54:3:10: Også disse skal først prøves; derefter skal de tjene i menigheten, om de er ulastelige. 
54:3:11: Likeså skal kvinnene være verdige, ikke baktalende, edruelige, tro i alle ting. 
54:3:12: Menighets-tjenerne skal være hver én kvinnes mann og styre vel sine barn og sine egne hus. 
54:3:13: For de som har vært gode menighets-tjenere, vinner sig et godt stade og megen frimodighet i troen på Kristus Jesus. 
54:3:14: Dette skriver jeg til dig skjønt jeg håper snart å komme til dig, 
54:3:15: men om jeg venter med å komme, at du da kan vite hvorledes en bør ferdes i Guds hus, som er den levende Guds menighet, sannhetens støtte og grunnvoll. 
54:3:16: Og som enhver må bekjenne, stor er den gudsfryktens hemmelighet: Han som blev åpenbaret i kjød, rettferdiggjort i ånd, sett av engler, forkynt iblandt folkeslag, trodd i verden, optatt i herlighet. 
54:4:1: Men Ånden sier med tydelige ord at i de kommende tider skal nogen falle fra troen, idet de holder sig til forførende ånder og djevlers lærdommer 
54:4:2: ved hykleri av falske lærere, som er brennemerket i sin egen samvittighet, 
54:4:3: som forbyder å gifte sig og byder å avholde sig fra mat, den Gud har skapt til å nytes med takk av dem som tror og har lært sannheten å kjenne. 
54:4:4: For all Guds skapning er god, og intet er å forkaste når det mottas med takk; 
54:4:5: for det helliges ved Guds ord og bønn. 
54:4:6: Når du lærer brødrene dette, da er du en god Kristi Jesu tjener, idet du nærer dig med troens og den gode lærdoms ord som du har fulgt. 
54:4:7: Men vis fra dig de vanhellige og kjerringaktige eventyr; øv dig derimot i gudsfrykt! 
54:4:8: For den legemlige øvelse er nyttig til lite, men gudsfrykten er nyttig til alt; den har løfte for det liv som nu er, og for det som kommer. 
54:4:9: Det er et troverdig ord og fullt verd å motta. 
54:4:10: For derfor arbeider vi og lider hån, fordi vi har satt vårt håp til den levende Gud, som er alle menneskers frelser, mest deres som tror. 
54:4:11: Dette skal du byde og lære. 
54:4:12: La ingen forakte dig for din ungdoms skyld, men vær et forbillede for de troende, i tale, i ferd, i kjærlighet, i tro, i renhet! 
54:4:13: Legg vinn på oplesningen av Skriften, på formaningen, på læren, inntil jeg kommer! 
54:4:14: Vanskjøtt ikke den nådegave som er i dig, som blev dig gitt ved profetiske ord med håndspåleggelse av de eldste. 
54:4:15: Tenk på dette, lev i dette, forat din fremgang kan bli åpenbar for alle! 
54:4:16: Gi akt på dig selv og på læren, hold ved med det! for når du det gjør, da skal du frelse både dig selv og dem som hører dig. 
54:5:1: Tal ikke hårdt til en gammel mann, men forman ham som en far, unge menn som brødre, 
54:5:2: gamle kvinner som mødre, unge som søstre, i all renhet! 
54:5:3: Hedre enker som virkelig er enker! 
54:5:4: Men har en enke barn eller barnebarn, da skal disse først lære å vise sin gudsfrykt mot sin egen slekt og gi sine foreldre vederlag; for dette er tekkelig i Guds øine. 
54:5:5: Men den som virkelig er enke og står alene, hun har satt sitt håp til Gud og blir ved i bønn og påkallelse natt og dag; 
54:5:6: men den som lever efter sine lyster, er levende død. 
54:5:7: Og dette skal du byde, forat de kan være ulastelige. 
54:5:8: Men dersom nogen ikke har omsorg for sine egne, og mest for sine husfolk, han har fornektet troen og er verre enn en vantro. 
54:5:9: En enke kan velges dersom hun ikke er yngre enn seksti år, dersom hun har vært én manns hustru, 
54:5:10: har vidnesbyrd om gode gjerninger, har opfostret barn, har vært gjestfri, har vasket de helliges føtter, er kommet de nødlidende til hjelp, har lagt vinn på all god gjerning. 
54:5:11: Men yngre enker skal du avvise; for når de i kjødelig lyst sviker Kristus, vil de gifte sig, 
54:5:12: og dermed har de den dom at de har brutt sin første tro. 
54:5:13: Tilmed lærer de og å gå ørkesløse, idet de farer omkring i husene, og ikke alene ørkesløse, men også med sladder og uvedkommende ting, så de taler det som utilbørlig er. 
54:5:14: Derfor vil jeg at unge enker skal gifte sig, føde barn, styre sitt hus, ikke gi motstanderen nogen leilighet til baktalelse. 
54:5:15: For allerede har somme vendt sig bort efter Satan. 
54:5:16: Dersom nogen troende mann eller kvinne har enker, da skal de sørge for dem og ikke la dem falle menigheten til byrde, forat den kan sørge for de virkelige enker. 
54:5:17: De eldste som er gode forstandere, skal aktes dobbelt ære verd, mest de som arbeider i tale og lære. 
54:5:18: For Skriften sier: Du skal ikke binde munnen på en okse som tresker. Og en arbeider er sin lønn verd. 
54:5:19: Ta ikke imot nogen klage mot en eldste uten efter to eller tre vidner. 
54:5:20: Dem som synder, skal du refse så alle hører på det, forat også de andre må ha frykt. 
54:5:21: Jeg vidner for Gud og Kristus Jesus og de utvalgte engler at du skal ta vare på dette uten fordom, så du ikke gjør noget av tilbøielighet. 
54:5:22: Vær ikke snar til å legge hendene på nogen; gjør dig ikke delaktig i fremmede synder; hold dig selv ren! 
54:5:23: Drikk ikke lenger bare vann, men nyt litt vin for din mave og dine jevnlige sykdommer. 
54:5:24: Nogen menneskers synder er åpenbare og går forut for dem til dom; men hos andre følger de efter. 
54:5:25: Likeså er og de gode gjerninger åpenbare, og de som det ikke er således med, kan dog ikke skjules. 
54:6:1: Så mange som er træler under åket, skal akte sine herrer all ære verd, forat Guds navn og læren ikke skal bli spottet. 
54:6:2: Men de som har troende herrer, skal ikke forakte dem, fordi de er brødre, men så meget heller gjøre sin tjeneste, fordi de som nyter godt av deres velgjerning, er troende og elsket. Dette skal du lære og formane til. 
54:6:3: Dersom nogen farer med fremmed lære og ikke holder sig til vår Herre Jesu Kristi sunde ord og den lære som hører til gudsfrykt, 
54:6:4: han er opblåst skjønt han intet forstår, men er syk for stridsspørsmål og ordkrig, som volder avind, kiv, spottord, ond mistanke, 
54:6:5: stadig krangel iblandt mennesker som er fordervet i sitt sinn og har tapt sannheten, idet de akter gudsfrykten for en vei til vinning. 
54:6:6: Ja, gudsfrykt med nøisomhet er en stor vinning; 
54:6:7: for vi har ikke hatt noget med oss til verden; det er åpenbart at vi heller ikke kan ta noget med oss derfra; 
54:6:8: men når vi har føde og klær, skal vi dermed la oss nøie; 
54:6:9: men de som vil bli rike, faller i fristelse og snare og mange dårlige og skadelige lyster, som senker menneskene ned i undergang og fortapelse. 
54:6:10: For pengekjærhet er en rot til alt ondt; av lyst dertil har somme faret vill fra troen og har gjennemstunget sig selv med mange piner. 
54:6:11: Men du, Guds menneske, fly disse ting, jag efter rettferdighet, gudsfrykt, tro, kjærlighet, tålmodighet, saktmodighet! 
54:6:12: Strid troens gode strid, grip det evige liv, som du blev kalt til, du som og har avlagt den gode bekjennelse for mange vidner! 
54:6:13: Jeg byder dig for Gud, som gir alle ting liv, og for Kristus Jesus, som vidnet for Pontius Pilatus den gode bekjennelse, 
54:6:14: at du skal holde budet rent og ulastelig inntil vår Herre Jesu Kristi åpenbarelse, 
54:6:15: som den Salige og alene Mektige skal vise oss i sin tid, han som er kongenes konge og herrenes herre, 
54:6:16: han som alene har udødelighet, som bor i et lys som ingen kan komme til, han som intet menneske har sett eller kan se; ham tilhører ære og evig makt. Amen. 
54:6:17: Byd dem som er rike i den nuværende verden, at de ikke skal være overmodige eller sette sitt håp til den uvisse rikdom, men til Gud, som gir oss rikelig alle ting å nyte, 
54:6:18: at de skal gjøre godt, være rike på gode gjerninger, gavmilde, godgjørende, 
54:6:19: så de legger sig op en god grunnvoll for den kommende tid, at de kan gripe det sanne liv. 
54:6:20: Timoteus! ta vare på det som er dig overgitt, så du vender dig bort fra det vanhellige tomme snakk og motsigelsene fra den kunnskap som falskelig kalles så, 
54:6:21: som nogen bekjenner sig til, så de har faret vill i troen. Nåden være med dig! 
55:1:1: Paulus, ved Guds vilje Kristi Jesu apostel til å kunngjøre løftet om livet i Kristus Jesus 
55:1:2: - til Timoteus, min elskede sønn: Nåde, miskunn, fred fra Gud Fader og Kristus Jesus, vår Herre! 
55:1:3: Jeg takker Gud, som jeg fra mine forfedre av tjener med en ren samvittighet, likesom jeg uavlatelig kommer dig i hu i mine bønner natt og dag, 
55:1:4: full av lengsel efter å se dig - idet jeg minnes dine tårer - forat jeg kan bli fylt med glede, 
55:1:5: da jeg er blitt minnet om den uskrømtede tro som er i dig, den som først bodde i din mormor Lois og din mor Eunike, og som jeg er viss på også bor i dig. 
55:1:6: Derfor minner jeg dig om at du igjen optender den Guds nådegave som er i dig ved min håndspåleggelse. 
55:1:7: For Gud gav oss ikke motløshets ånd, men krafts og kjærlighets og sindighets ånd. 
55:1:8: Skam dig derfor ikke ved vår Herres vidnesbyrd eller ved mig, hans fange, men lid ondt med mig for evangeliet i Guds kraft, 
55:1:9: han som frelste oss og kalte oss med et hellig kall, ikke efter våre gjerninger, men efter sitt eget forsett og den nåde som er oss gitt i Kristus Jesus fra evige tider, 
55:1:10: men nu er blitt åpenbaret ved vår frelser Jesu Kristi åpenbarelse, han som tilintetgjorde døden og førte liv og uforgjengelighet frem for schmelken ved evangeliet, 
55:1:11: og for det er jeg satt til forkynner og apostel og lærer for hedninger. 
55:1:12: Derfor lider jeg også dette, men jeg skammer mig ikke ved det; for jeg vet på hvem jeg tror, og jeg er viss på at han er mektig til å ta vare på det som er mig overgitt, inntil hin dag. 
55:1:13: Ha til forbillede de sunde ord som du har hørt av mig, i tro og kjærlighet i Kristus Jesus; 
55:1:14: ta vare på den fagre skatt som er dig overgitt, ved den Hellige Ånd, som bor i oss! 
55:1:15: Du vet dette at alle de i Asia vendte sig fra mig; blandt disse er Fygelus og Hermogenes. 
55:1:16: Herren vise miskunn mot Onesiforus' hus! for han har ofte vederkveget mig og ikke skammet sig ved mine lenker; 
55:1:17: men da han var kommet til Rom, søkte han med stor iver efter mig og fant mig. 
55:1:18: Herren gi han må finne miskunn hos Herren på hin dag! Og til hvor stor tjeneste han var i Efesus, det vet du selv best. 
55:2:1: Så bli da du, min sønn, sterk ved nåden i Kristus Jesus, 
55:2:2: og det som du har hørt av mig i mange vidners nærvær, overgi det til trofaste mennesker som er duelige til også å lære andre! 
55:2:3: Lid ondt med mig som en god Kristi Jesu stridsmann! 
55:2:4: Ingen som gjør krigstjeneste, blander sig inn i livets sysler, forat han kan tekkes sin hærfører. 
55:2:5: Men om nogen også strider i veddekamp, får han dog ikke kransen hvis han ikke strider på den rette måte. 
55:2:6: Den bonde som arbeider, bør først nyte fruktene. 
55:2:7: Forstå det jeg sier! for Herren skal gi dig forstand på alt. 
55:2:8: Kom Jesus Kristus i hu, som er opstanden fra de døde, av Davids ætt, efter mitt evangelium, 
55:2:9: for hvis skyld jeg lider ondt like til dette å være bundet som en ugjerningsmann; men Guds ord er ikke bundet. 
55:2:10: Derfor tåler jeg alt for de utvalgtes skyld, forat også de skal vinne frelsen i Kristus Jesus med evig herlighet. 
55:2:11: Det er et troverdig ord; for er vi død med ham, skal vi og leve med ham; 
55:2:12: holder vi ut, skal vi og herske med ham; fornekter vi, skal han og fornekte oss; 
55:2:13: er vi troløse, så er han trofast; for han kan ikke fornekte sig selv. 
55:2:14: Minn om dette, idet du vidner for Herrens åsyn at de ikke skal ligge i ordkrig, til ingen nytte, men til undergang for dem som hører på. 
55:2:15: Legg vinn på å fremstille dig for Gud som en som holder prøve, som en arbeider som ikke har noget å skamme sig over, idet du rettelig lærer sannhetens ord. 
55:2:16: Men hold dig fra det vanhellige tomme snakk! for de går alltid videre i ugudelighet, 
55:2:17: og deres ord vil ete om sig som dødt kjøtt. Blandt disse er Hymeneus og Filetus, 
55:2:18: som har faret vill fra sannheten, idet de sier at opstandelsen allerede har vært, og de nedbryter troen hos somme. 
55:2:19: Men Guds faste grunnvoll står og har dette segl: Herren kjenner sine, og: Hver den som nevner Herrens navn, skal avstå fra urettferdighet! 
55:2:20: Men i et stort hus er det ikke bare kar av gull og sølv, men også kar av tre og ler, og nogen til ære, andre til vanære. 
55:2:21: Holder da nogen sig ren fra disse, da vil han være et kar til ære, helliget, nyttig for husbonden, rede til all god gjerning. 
55:2:22: Men fly ungdommens lyster, og jag efter rettferdighet, tro, kjærlighet, fred med dem som påkaller Herren av et rent hjerte! 
55:2:23: Og vis fra dig de dårlige og uforstandige stridsspørsmål, for du vet at de føder strid! 
55:2:24: Men en Herrens tjener må ikke stride, han må være mild imot alle, duelig til å lære andre, i stand til å tåle ondt, 
55:2:25: så han med saktmodighet viser dem til rette som sier imot, om Gud dog engang vilde gi dem omvendelse, så de kunde kjenne sannheten 
55:2:26: og våkne igjen av sitt rus i djevelens snare, han som de er fanget av, så de må gjøre hans vilje. 
55:3:1: Men dette skal du vite at i de siste dager skal det komme vanskelige tider. 
55:3:2: For menneskene skal da være egenkjærlige, pengekjære, stortalende, overmodige, spottende, ulydige mot foreldre, utakknemlige, vanhellige, 
55:3:3: ukjærlige, upålitelige, baktalende, umåtelige, umilde, uten kjærlighet til det gode, 
55:3:4: svikefulle, fremfusende, opblåste, slike som elsker sine lyster høiere enn Gud, 
55:3:5: som har gudfryktighets skinn, men fornekter dens kraft - og disse skal du vende dig fra. 
55:3:6: For til dem hører de som lurer sig inn i husene og fanger kvinnfolk som er tynget av synder og drives av mangehånde lyster 
55:3:7: og alltid lærer og aldri kan komme til sannhets erkjennelse. 
55:3:8: Som Jannes og Jambres stod Moses imot, således står også disse sannheten imot, mennesker som er fordervet i sitt sinn og uduelige I troen. 
55:3:9: Dog, de skal ikke få mere fremgang; for deres uforstand skal bli åpenbar for alle, likesom og hines blev. 
55:3:10: Men du har efterfulgt min lære, min ferd, mitt forsett, min tro, min langmodighet, min kjærlighet, min tålmodighet, 
55:3:11: mine forfølgelser, mine lidelser, sådanne som møtte mig i Antiokia, i Ikonium, i Lystra, sådanne forfølgelser som jeg har utholdt, og Herren har fridd mig ut av dem alle sammen. 
55:3:12: Og alle som vil leve gudfryktig i Kristus Jesus, skal bli forfulgt. 
55:3:13: Men onde mennesker og slike som kverver synet på folk, går frem til det verre; de fører vill og farer vill. 
55:3:14: Men bli du i det som du har lært, og som du er blitt overbevist om, da du vet hvem du har lært det av, 
55:3:15: og da du fra barndommen av kjenner de hellige skrifter, som kan gjøre dig vis til frelse ved troen på Kristus Jesus. 
55:3:16: Den hele Skrift er innblest av Gud av Gud og nyttig til lærdom, til overbevisning, til rettledning, til optuktelse i rettferdighet, 
55:3:17: forat det Guds menneske kan være fullkommen, duelig til all god gjerning. 
55:4:1: Jeg vidner for Gud og Kristus Jesus, som skal dømme levende og døde, og ved hans åpenbarelse og hans rike; 
55:4:2: Forkynn ordet, vær rede i tide og utide, overbevis, irettesett, forman med all langmodighet og lære! 
55:4:3: For det skal komme en tid da de ikke skal tåle den sunde lære, men efter sine egne lyster ta sig selv lærere i hopetall, fordi det klør dem i øret, 
55:4:4: og de skal vende øret bort fra sannheten og vende sig til eventyr. 
55:4:5: Men vær du edru i alle ting, lid ondt, gjør en evangelists gjerning, fullfør din tjeneste! 
55:4:6: For jeg ofres allerede, og tiden for min bortgang er forhånden. 
55:4:7: Jeg har stridt den gode strid, fullendt løpet, bevart troen. 
55:4:8: Så ligger da rettferdighetens krans rede for mig, den som Herren, den rettferdige dommer, skal gi mig på hin dag, dog ikke mig alene, men alle som har elsket hans åpenbarelse. 
55:4:9: Gjør dig umak for å komme snart til mig! 
55:4:10: For Demas forlot mig, fordi han fikk kjærlighet til den nuværende verden, og drog til Tessalonika, Kreskens til Galatia, Titus til Dalmatia; 
55:4:11: Lukas er alene hos mig. Ta Markus og ha ham med dig! for han er mig nyttig til tjeneste. 
55:4:12: Tykikus har jeg sendt til Efesus. 
55:4:13: Når du kommer, da ha med dig den kappe som jeg lot bli i Troas hos Karpus, og bøkene, især skinnbøkene! 
55:4:14: Kobbersmeden Aleksander gjorde mig meget ondt; Herren vil lønne ham efter hans gjerninger. 
55:4:15: Vokt dig for ham du også! for han stod hårdt imot våre ord. 
55:4:16: Ved mitt første forsvar møtte ingen med mig, men alle forlot mig; gid det ikke må bli dem tilregnet! 
55:4:17: Men Herren stod hos mig og styrket mig, forat forkynnelsen skulde fullbyrdes ved mig og alle folk få høre den, og jeg blev fridd ut av løvens gap. 
55:4:18: Herren skal fri mig fra all ond gjerning og frelse mig inn i sitt himmelske rike; ham være æren i all evighet! Amen. 
55:4:19: Hils Priska og Akvilas og Onesiforus' hus! 
55:4:20: Erastus blev i Korint; Trofimus lot jeg syk efter mig i Milet. 
55:4:21: Gjør dig umak for å komme før vinteren! Eubulus og Pudens og Linus og Klaudia og alle brødrene hilser dig. 
55:4:22: Den Herre Jesus være med din ånd! Nåden være med eder! 
56:1:1: Paulus, Guds tjener og Jesu Kristi apostel til å føre Guds utvalgte til tro og til å kjenne den sannhet som hører til gudsfrykt, 
56:1:2: i håp om evig liv, som Gud, han som ikke lyver, har lovt fra evige tider, 
56:1:3: men nu i sin tid har han åpenbaret sitt ord i den forkynnelse som blev mig betrodd efter Guds, vår frelsers befaling 
56:1:4: - til Titus, min ekte sønn i den felles tro; Nåde og fred fra Gud Fader og Kristus Jesus, vår frelser! 
56:1:5: Derfor lot jeg dig bli efter på Kreta at du skulde sette det i rette skikk som ennu stod tilbake, og innsette eldste i hver by, således som jeg foreskrev dig, 
56:1:6: om nogen er ulastelig, én kvinnes mann, og har troende barn som ikke har ondt ord på sig for ryggesløshet eller er gjenstridige. 
56:1:7: For en tilsynsmann skal være ulastelig som en Guds husholder, ikke selvgod, ikke vredladen, ikke drikkfeldig, ikke voldsom, ikke lysten efter ussel vinning, 
56:1:8: men gjestfri, glad i det gode, sindig, rettferdig, hellig, avholdende, 
56:1:9: en som holder fast ved det troverdige ord efter læren, forat han kan være i stand til både å formane ved den sunde lære og å tale til rette dem som sier imot. 
56:1:10: For der er mange gjenstridige, som farer med tomt snakk og dårer folks hu, helst de av omskjærelsen. 
56:1:11: Disse skal en målbinde; for de nedriver hele hus ved å føre utilbørlig lære for ussel vinnings skyld. 
56:1:12: En av dem, deres egen profet, har sagt: Kreterne er alltid løgnere, onde dyr, late buker. 
56:1:13: Dette vidnesbyrd er sant. Derfor skal du tale dem strengt til rette, forat de må bli sunde i troen, 
56:1:14: så de ikke gir sig av med jødiske eventyr og bud av mennesker som vender sig bort fra sannheten. 
56:1:15: Alt er rent for de rene; men for de urene og vantro er intet rent; både deres sinn og deres samvittighet er urene. 
56:1:16: De sier at de kjenner Gud; men de fornekter ham med sine gjerninger, for de er vederstyggelige og ulydige og uduelige til all god gjerning. 
56:2:1: Men tal du det som sømmer sig for den sunde lære, 
56:2:2: at gamle menn skal være edrue, verdige, sindige, sunde i troen, i kjærligheten, i tålmodet; 
56:2:3: likeså at gamle kvinner i sin ferd skal te sig som det sømmer sig for hellige, ikke fare med baktalelse, ikke være træler av drikk, men veiledere i det gode, 
56:2:4: forat de kan lære de unge kvinner å elske sine menn og sine barn, 
56:2:5: å være sindige, rene, huslige, gode, lydige mot sine egne menn, forat Guds ord ikke skal bli spottet! 
56:2:6: De unge menn skal du likeledes formane til å være sindige, 
56:2:7: idet du i alle måter ter dig selv som et forbillede i gode gjerninger, og i din lære viser renhet, verdighet, 
56:2:8: en sund, ulastelig tale, forat motstanderen må gå i sig selv, idet han ikke har noget ondt å si om oss. 
56:2:9: Tjenere skal du formane til å være lydige mot sine egne herrer, i alle ting å tekkes dem, ikke å si imot, 
56:2:10: ikke å være utro, men vise all god troskap, forat de i alt kan være en pryd for Guds, vår frelsers lære. 
56:2:11: For Guds nåde er åpenbaret til frelse for alle mennesker, 
56:2:12: idet den optukter oss til å fornekte ugudelighet og de verdslige lyster og leve tuktig og rettferdig og gudfryktig i den nuværende verden, 
56:2:13: mens vi venter på det salige håp og åpenbarelsen av den store Guds og vår frelser Jesu Kristi herlighet, 
56:2:14: han som gav sig selv for oss for å forløse oss fra all urettferdighet og rense sig selv et eiendomsfolk, nidkjært til gode gjerninger. 
56:2:15: Tal dette og forman og irettesett med all myndighet! La ingen ringeakte dig! 
56:3:1: Minn dem om å underordne sig under myndigheter og øvrigheter, å være lydige, rede til all god gjerning, 
56:3:2: ikke å spotte nogen, ikke å være stridslystne, men milde, og vise all saktmodighet mot alle mennesker. 
56:3:3: For også vi var engang uforstandige, ulydige, villfarende, træler av mangehånde begjæringer og lyster, vi levde i ondskap og avind, vi var forhatt og hatet hverandre; 
56:3:4: men da Guds, vår frelsers godhet og kjærlighet til menneskene blev åpenbaret, 
56:3:5: frelste han oss, ikke for rettferdige gjerningers skyld som vi hadde gjort, men efter sin miskunn, ved badet til gjenfødelse og fornyelse ved den Hellige Ånd, 
56:3:6: som han rikelig har utøst over oss ved Jesus Kristus, vår frelser. 
56:3:7: forat vi, rettferdiggjort ved hans nåde, efter håpet skulde bli arvinger til det evige liv. 
56:3:8: Det er et troverdig ord, og dette vil jeg at du skal innprente, forat de som tror på Gud, må legge vinn på å gjøre gode gjerninger. Dette er godt og nyttig for menneskene; 
56:3:9: men dårlige stridsspørsmål og ættetavler og kiv og trette om loven skal du holde dig fra; for de er unyttige og gagnløse. 
56:3:10: Et menneske som gir sig av med vranglære, skal du vise fra dig, efterat du har formant ham én gang og én gang til, 
56:3:11: for du vet at han er forvendt og synder, dømt av sig selv. 
56:3:12: Når jeg sender Artemas eller Tykikus til dig, da gjør dig umak for å komme til mig i Nikopolis; for der har jeg tenkt å bli vinteren over. 
56:3:13: Zenas, den lovkyndige, og Apollos skal du med omhu hjelpe på vei, forat intet skal fattes dem. 
56:3:14: Også våre må lære å gjøre gode gjerninger, alt efter som det er trang til, forat de ikke skal være uten frukt. 
56:3:15: Alle som er hos mig, hilser dig. Hils dem som elsker oss i troen! Nåden være med eder alle! 
57:1:1: Paulus, Kristi Jesu fange, og broderen Timoteus - til Filemon, vår kjære venn og medarbeider, 
57:1:2: og til Appia, vår søster, og til Arkippus, vår medstrider, og til menigheten i ditt hus: 
57:1:3: Nåde være med eder og fred fra Gud vår Fader og den Herre Jesus Kristus! 
57:1:4: Jeg takker alltid min Gud når jeg kommer dig i hu i mine bønner, 
57:1:5: da jeg hører om din kjærlighet og den tro som du har til den Herre Jesus og til alle de hellige, 
57:1:6: forat deres samfund med dig i troen må bli virksomt for Kristus i kjennskapet til alt det gode som er i eder. 
57:1:7: For stor glede og trøst fikk jeg ved din kjærlighet, fordi de helliges hjerter er blitt vederkveget ved dig, bror! 
57:1:8: Derfor, om jeg enn har megen frimodighet i Kristus til å påbyde dig det som tilbørlig er, 
57:1:9: så ber jeg dog heller, for kjærlighetens skyld. Slik som jeg er, den gamle Paulus, men nu også Kristi Jesu fange, 
57:1:10: ber jeg dig for min sønn, som jeg har avlet i mine lenker, Onesimus, 
57:1:11: som fordum var unyttig for dig, men nu er nyttig for dig og for mig, han som jeg sender tilbake til dig. 
57:1:12: Ta du imot ham, det er mitt eget hjerte! 
57:1:13: Jeg hadde lyst til å la ham bli her hos mig, forat han i ditt sted kunde tjene mig i mine lenker for evangeliet, 
57:1:14: men uten ditt samtykke vilde jeg intet gjøre, forat din godhet ikke skulde være som av tvang, men av fri vilje. 
57:1:15: For kan hende han just derfor blev skilt fra dig en stund forat du kunde få ham til evig eie, 
57:1:16: ikke lenger som træl, men mere enn en træl, som en elsket bror, især for mig, men hvor meget mere for dig, både i kjødet og i Herren! 
57:1:17: Så sant du derfor akter mig for din medbroder, så ta imot ham som mig selv; 
57:1:18: og har han gjort dig nogen urett, eller er han dig noget skyldig, da skriv det på min regning. 
57:1:19: Jeg, Paulus, skriver med egen hånd: jeg skal betale det - forat jeg ikke skal si dig at du skylder mig endog dig selv. 
57:1:20: Ja, bror, la mig ha nytte av dig i Herren! vederkveg mitt hjerte i Kristus! 
57:1:21: I tillit til din lydighet skriver jeg til dig, viss på at du vil gjøre endog mere enn jeg sier. 
57:1:22: Og gjør dessuten herberge i stand for mig; for jeg håper at jeg ved eders bønner skal bli gitt eder. 
57:1:23: Epafras, min medfange i Kristus Jesus, hilser dig; 
57:1:24: likeledes Markus, Aristarkus, Demas, Lukas, mine medarbeidere. 
57:1:25: Vår Herre Jesu Kristi nåde være med eders ånd! 
58:1:1: Efterat Gud fordum hadde talt mange ganger og på mange måter til fedrene ved profetene, så har han i disse siste dager talt til oss ved Sønnen, 
58:1:2: som han har satt til arving over alle ting, ved hvem han og har gjort verden, 
58:1:3: han som er avglansen av hans herlighet og avbilledet av hans vesen og bærer alle ting ved sin krafts ord, og som derfor, da han hadde gjort renselse for våre synder, satte sig ved Majestetens høire hånd i det høie, 
58:1:4: og er blitt så meget større enn englene som han har arvet et herligere navn fremfor dem. 
58:1:5: For til hvem av englene har han nogen tid sagt: Du er min sønn, jeg har født dig idag, og atter: Jeg vil være ham en far, og han skal være mig en sønn? 
58:1:6: Men når han atter fører den førstefødte inn i verden, sier han: Og alle Guds engler skal tilbede ham. 
58:1:7: Og om englene sier han: Han som gjør sine engler til vinder og sine tjenere til ildslue, 
58:1:8: men om Sønnen: Din trone, Gud, står i all evighet, og rettvishets kongestav er ditt rikes kongestav; 
58:1:9: du elsket rettferd og hatet urett; derfor har, Gud, din Gud salvet dig med gledens olje fremfor dine medbrødre. 
58:1:10: Og: Du, Herre, grunnfestet i begynnelsen jorden, og himlene er dine henders verk; 
58:1:11: de skal forgå, men du blir, og de skal alle eldes som et klædebon, 
58:1:12: og som en kåpe skal du rulle dem sammen, og de skal omskiftes; men du er den samme, og dine år skal ikke få ende. 
58:1:13: Men til hvem av englene har han nogen tid sagt: Sett dig ved min høire hånd, til jeg får lagt dine fiender til skammel for dine føtter? 
58:1:14: Er de ikke alle tjenende ånder, som sendes ut til tjeneste for deres skyld som skal arve frelse? 
58:2:1: Derfor må vi så meget mere gi akt på det vi har hørt, forat vi ikke skal drive bort derfra. 
58:2:2: For dersom det ord som var talt ved engler, stod fast, og hver overtredelse og ulydighet fikk sin fortjente lønn, 
58:2:3: hvorledes skal da vi undfly om vi ikke akter så stor en frelse? - den som først blev forkynt ved Herren og derefter stadfestet for oss av dem som hadde hørt ham, 
58:2:4: idet Gud vidnet med, både ved tegn og under og mangehånde kraftige gjerninger og utdeling av den Hellige Ånd efter sin vilje. 
58:2:5: For det var ikke under engler han la den kommende verden, som vi taler om. 
58:2:6: Men en har på et sted vidnet så: Hvad er et menneske, at du kommer ham i hu, eller et menneskes sønn, at du akter på ham? 
58:2:7: Du gjorde ham lite ringere enn englene; med herlighet og ære kronte du ham, og satte ham over dine henders gjerninger; 
58:2:8: alle ting la du under hans føtter. For idet han underla ham alle ting, undtok han intet som ikke er ham underlagt; men nu ser vi ennu ikke at alle ting er ham underlagt; 
58:2:9: men den som var gjort lite ringere enn englene, Jesus, ham ser vi, fordi han led døden, kronet med herlighet og ære, forat han ved Guds nåde skulde smake døden for alle. 
58:2:10: For det sømmet sig for ham for hvis skyld alle ting er til, og ved hvem alle ting er til, da han førte mange barn til herlighet, gjennem lidelser å fullende deres frelses høvding. 
58:2:11: For både den som helliggjør, og de som helliggjøres, er alle av en; derfor skammer han sig ikke ved å kalle dem brødre, 
58:2:12: når han sier: Jeg vil kunngjøre ditt navn for mine brødre, midt i menigheten vil jeg lovsynge dig. 
58:2:13: Og atter: Jeg vil sette min lit til ham. Og atter: Se, her er jeg og de barn som Gud har gitt mig. 
58:2:14: Eftersom da barnene har del i blod og kjød, fikk også han i like måte del deri, forat han ved døden kunde gjøre til intet den som hadde dødens velde, det er djevelen, 
58:2:15: og utfri alle dem som av frykt for døden var i trældom all sin livstid. 
58:2:16: For engler tar han sig jo ikke av, men Abrahams ætt tar han sig av; 
58:2:17: derfor måtte han i alle ting bli sine brødre lik, forat han kunde bli en miskunnelig og trofast yppersteprest for Gud til å gjøre soning for folkets synder. 
58:2:18: For derved at han selv har lidt og har vært fristet, kan han komme dem til hjelp som blir fristet. 
58:3:1: Derfor, hellige brødre, I som har fått del i et himmelsk kall, gi akt på den apostel og yppersteprest som vi bekjenner, Jesus, 
58:3:2: han som var tro mot den som gjorde ham dertil, likesom og Moses var i hele hans hus. 
58:3:3: For denne er aktet så meget større ære verd enn Moses, som den som har gjort huset, har større ære enn huset selv. 
58:3:4: Hvert hus blir jo gjort av nogen; men den som har gjort alt, er Gud; 
58:3:5: og Moses var vel tro i hele hans hus som tjener til å vidne om det som skulde tales, 
58:3:6: men Kristus som Sønn over hans hus, og hans hus er vi, såfremt vi holder vår frimodighet og det håp som vi roser oss av, fast inntil enden. 
58:3:7: Derfor, som den Hellige Ånd sier: Idag, om I hører hans røst, 
58:3:8: da forherd ikke eders hjerter som ved forbitrelsen, på fristelsesdagen i ørkenen, 
58:3:9: hvor eders fedre fristet mig ved å sette mig på prøve, enda de så mine gjerninger i firti år; 
58:3:10: derfor harmedes jeg på denne slekt og sa: De farer alltid vill i hjertet; men de kjente ikke mine veier, 
58:3:11: så jeg svor i min vrede: Sannelig, de skal ikke komme inn til min hvile! 
58:3:12: Se til, brødre, at det ikke i nogen av eder er et ondt, vantro hjerte, så han faller fra den levende Gud; 
58:3:13: men forman hverandre hver dag, så lenge det heter idag, forat ikke nogen av eder skal forherdes ved syndens svik; 
58:3:14: for vi har fått del med Kristus, såfremt vi holder vår første fulle visshet fast inntil enden. 
58:3:15: Når det sies: Idag, om I hører hans røst, da forherd ikke eders hjerter som ved forbitrelsen, 
58:3:16: hvem var det da vel som hørte den og dog forbitret ham? var det ikke alle de som gikk ut av Egypten ved Moses? 
58:3:17: Og hvem var det han harmedes på i firti år? var det ikke på dem som hadde syndet, så deres kropper falt i ørkenen? 
58:3:18: Og om hvem var det han svor at de ikke skulde komme inn til hans hvile, uten om dem som ikke hadde villet tro? 
58:3:19: Så ser vi da at det var for vantros skyld de ikke kunde komme inn. 
58:4:1: La oss derfor ta oss i vare for at nogen av eder skal synes å være blitt liggende efter, da et løfte om å komme inn til hans hvile ennu er forhånden. 
58:4:2: For det glade budskap er og forkynt oss, likesom for hine; men ordet som de hørte, blev dem til ingen nytte, fordi det ikke ved troen var smeltet sammen med dem som hørte det. 
58:4:3: For vi går inn til hvilen, vi som er kommet til troen, således som han har sagt: Så jeg svor i min vrede: Sannelig, de skal ikke komme inn til min hvile - enda gjerningene var fullført fra verdens grunnvoll blev lagt. 
58:4:4: For så har han på et sted sagt om den syvende dag: Og Gud hvilte på den syvende dag fra alle sine gjerninger; 
58:4:5: og på dette sted igjen: Sannelig, de skal ikke komme inn til min hvile. 
58:4:6: Eftersom det altså står tilbake at nogen skal komme inn til den, og de som først fikk det glade budskap, ikke kom inn for vantros skyld, 
58:4:7: så fastsetter han atter en dag: idag, idet han sier ved David så lang tid efter, således som før er sagt: Idag, om I hører hans røst, da forherd ikke eders hjerter. 
58:4:8: For hadde Josva ført dem til hvile, da hadde han ikke derefter talt om en annen dag. 
58:4:9: Altså står det en sabbatshelg tilbake for Guds folk. 
58:4:10: For den som er kommet inn til hans hvile, han har og fått hvile fra sine gjerninger, likesom Gud fra sine. 
58:4:11: La oss derfor gjøre oss umak for å komme inn til den hvile, forat ikke nogen skal falle efter samme eksempel på vantro. 
58:4:12: For Guds ord er levende og kraftig og skarpere enn noget tveegget sverd og trenger igjennem, inntil det kløver sjel og ånd, ledemot og marg, og dømmer hjertets tanker og råd, 
58:4:13: Og ingen skapning er usynlig for hans åsyn, men alt er nakent og bart for hans øine som vi har å gjøre med. 
58:4:14: Eftersom vi da har en stor yppersteprest, som er gått gjennem himlene, Jesus, Guds Sønn, så la oss holde fast ved bekjennelsen. 
58:4:15: For vi har ikke en yppersteprest som ikke kan ha medynk med våre skrøpeligheter, men en sådan som er blitt prøvd i alt i likhet med oss, dog uten synd. 
58:4:16: La oss derfor trede frem med frimodighet for nådens trone, forat vi kan få miskunn og finne nåde til hjelp i rette tid. 
58:5:1: For hver yppersteprest tas iblandt mennesker og innsettes for mennesker til tjeneste for Gud, for å frembære både gaver og slaktoffer for synder, 
58:5:2: som en som kan bære over med de vankundige og villfarende, fordi han også selv er skrøpelighet underlagt, 
58:5:3: og for dens skyld må frembære syndoffer, som for folket, så og for sig selv. 
58:5:4: Og ingen tiltar sig selv den ære, men den som kalles av Gud, likesom Aron. 
58:5:5: Således tilla da heller ikke Kristus sig den ære å bli yppersteprest, men han som sa til ham: Du er min Sønn, jeg har født dig idag; 
58:5:6: likesom han og på et annet sted sier: Du er prest til evig tid efter Melkisedeks vis. 
58:5:7: Og han har i sitt kjøds dager med sterkt skrik og tårer frembåret bønner og nødrop til ham som kunde frelse ham fra døden, og han blev bønnhørt for sin gudsfrykt, 
58:5:8: og således lærte han, skjønt han var Sønn, lydighet av det han led, 
58:5:9: og da han var fullendt, blev han ophav til evig frelse for alle dem som lyder ham, 
58:5:10: og blev av Gud kalt yppersteprest efter Melkisedeks vis. 
58:5:11: Om dette har vi meget å si, som også er vanskelig å utlegge, eftersom I er blitt trege til å høre. 
58:5:12: For skjønt I efter tiden burde være lærere, trenger I atter til at en lærer eder hvad som er de første grunner i Guds ord, og I er blitt sådanne som trenger til melk, ikke fast føde. 
58:5:13: For hver den som får melk, er ukyndig i rettferds ord, for han er jo et barn; 
58:5:14: men fast føde er for voksne, for dem som ved bruken har sine sanser opøvd til å skille mellem godt og ondt. 
58:6:1: La oss derfor gå forbi barnelærdommen om Kristus og skride frem mot det fullkomne, så vi ikke atter legger grunnvollen med omvendelse fra døde gjerninger og tro på Gud, 
58:6:2: med lære om dåp og håndspåleggelse, om dødes opstandelse og evig dom 
58:6:3: Og dette vil vi gjøre, om Gud gir lov til det. 
58:6:4: For det er umulig at de som engang er blitt oplyst og har smakt den himmelske gave og fått del i den Hellige Ånd 
58:6:5: og har smakt Guds gode ord og den kommende verdens krefter, og så faller fra, atter kan fornyes til omvendelse, 
58:6:6: da de på ny korsfester Guds Sønn for sig og gjør ham til spott. 
58:6:7: For den jord som drikker regnet som ofte faller på den, og som bærer gagnlig grøde for dem den dyrkes for, den får velsignelse fra Gud; 
58:6:8: men bærer den torner og tistler, da er den uduelig og forbannelse nær, og enden med den er å brennes. 
58:6:9: Men om eder, I elskede, er vi visse på det som bedre er, og som hører til frelse, enda vi taler således. 
58:6:10: For Gud er ikke urettferdig, så han skulde glemme eders verk og den kjærlighet I har vist mot hans navn, idet I har tjent og ennu tjener de hellige. 
58:6:11: Men vi ønsker at enhver av eder må vise den samme iver for den fulle visshet i håpet inntil enden, 
58:6:12: forat I ikke skal bli trege, men efterfølge dem som ved tro og tålmod arver løftene. 
58:6:13: For da Gud gav Abraham løftet, svor han ved sig selv, eftersom han ingen større hadde å sverge ved, og sa: 
58:6:14: Sannelig, jeg vil rikelig velsigne dig og storlig mangfoldiggjøre dig; 
58:6:15: og da han således hadde ventet tålmodig, opnådde han det som var lovt. 
58:6:16: For mennesker sverger jo ved den større, og eden er dem en ende på all motsigelse, til stadfestelse. 
58:6:17: Derfor, da Gud vilde enn mere vise løftets arvinger hvor uryggelig hans vilje var, gikk han imellem med en ed, 
58:6:18: forat vi ved to uryggelige ting, hvori Gud umulig kunde lyve, skulde ha en sterk trøst, vi som har tatt vår tilflukt til å gripe det håp som venter oss, 
58:6:19: det vi har som et anker for sjelen, et som er trygt og fast og når innenfor forhenget, 
58:6:20: hvor Jesus gikk inn som forløper for oss, idet han blev yppersteprest til evig tid efter Melkisedeks vis. 
58:7:1: For denne Melkisedek, konge i Salem, den høieste Guds prest, han som gikk Abraham i møte da han vendte tilbake fra sin seier over kongene, og som velsignet ham, 
58:7:2: han som Abraham også gav tiende av alt, og som først, når hans navn utlegges, er rettferdighets konge, men dernæst også Salems konge, det er freds konge, 
58:7:3: som er uten far, uten mor, uten ættetavle, som hverken har dagers begynnelse eller livs ende, men er gjort lik med Guds Sønn - han blir prest for alltid. 
58:7:4: Og se, hvor stor han er, denne som Abraham, patriarken, endog gav tiende av byttet! 
58:7:5: Og mens de av Levis barn som får prestedømmet, har et bud om å ta tiende efter loven av folket, det er av sine brødre, skjønt disse er kommet av Abrahams lend, 
58:7:6: så har denne, som ikke regner sin ætt fra dem, tatt tiende av Abraham og velsignet ham som hadde løftene. 
58:7:7: Men uten all motsigelse er det den ringere som velsignes av den høiere. 
58:7:8: Og her er det dødelige mennesker som tar tiende, men der en som har det vidnesbyrd at han lever. 
58:7:9: Og om jeg så må si: Ved Abraham er endog Levi, som tar tiende, blitt lagt i tiende; 
58:7:10: for han var ennu i sin fars lend dengang da Melkisedek møtte ham. 
58:7:11: Var det da fullkommenhet å vinne ved det levittiske prestedømme - for dette var folket bundet til ved loven - hvad trang hadde det da vært til at en annen prest skulde opstå efter Melkisedeks vis og ikke nevnes efter Arons vis? 
58:7:12: Omskiftes prestedømmet, da går jo nødvendigvis også en omskiftelse av loven for sig; 
58:7:13: for han som dette sies om, hørte til en annen stamme, hvorav ingen har tatt vare på alteret; 
58:7:14: det er jo kjent nok at vår Herre er oprunnet av Juda, og til denne stamme har Moses ikke talt noget om prester. 
58:7:15: Og enn mere klart er det når det opstår en annen prest efter Melkisedeks vis, 
58:7:16: som ikke er blitt det ifølge et kjødelig buds lov, men ifølge et uforgjengelig livs kraft; 
58:7:17: for han får det vidnesbyrd: Du er prest til evig tid efter Melkisedeks vis. 
58:7:18: For et eldre bud settes ut av kraft, fordi det var svakt og unyttig - 
58:7:19: loven førte jo ikke noget frem til fullkommenhet - og et bedre håp føres inn, så vi kan nærme oss til Gud. 
58:7:20: Og så visst som det ikke gikk for sig uten ed - 
58:7:21: for hine er blitt prester uten ed, men han med ed ved den som sier til ham: Herren svor, og han skal ikke angre det: Du er prest til evig tid efter Melkisedeks vis - 
58:7:22: så visst er det en bedre pakt som Jesus er blitt borgsmann for. 
58:7:23: Og av hine prester har det vært flere, fordi de ved døden blev hindret fra å holde ved; 
58:7:24: men denne har et uforgjengelig prestedømme, fordi han blir til evig tid, 
58:7:25: og derfor kan han også fullkommen frelse dem som kommer til Gud ved ham, da han alltid lever til å gå i forbønn for dem. 
58:7:26: For en sådan yppersteprest måtte vi og ha, hellig, uskyldig, ren, skilt fra syndere og ophøiet over himlene 
58:7:27: en som ikke hver dag trenger til, likesom yppersteprestene, først å frembære offer for sine egne synder, derefter for folkets; for dette gjorde han en gang for alle da han ofret sig selv. 
58:7:28: For loven innsetter til yppersteprester mennesker som har skrøpelighet, men edens ord, som kom efter loven, innsetter Sønnen, han som er blitt fullendt for all evighet. 
58:8:1: Men en hovedsak ved det som her sies, er dette: Vi har en sådan yppersteprest som satte sig ved høire side av Majestetens trone i himlene, 
58:8:2: med prestelig tjeneste i helligdommen, det sanne tabernakel, som Herren har reist, og ikke et menneske. 
58:8:3: For hver yppersteprest innsettes jo til å frembære både gaver og slaktoffer, hvorfor det er nødvendig at også denne har noget å frembære. 
58:8:4: Var han nu altså på jorden, da var han ikke engang prest, da der er prester som efter loven bærer frem gavene, 
58:8:5: de som tjener ved et avbillede og en skygge av det himmelske, efter den forskrift som Moses fikk da han skulde gjøre tabernaklet; for han sier; Se til at du gjør alt efter det billede som blev vist dig på fjellet. 
58:8:6: Men nu har han fått en så meget bedre prestetjeneste som det og er en bedre pakt han er mellemmann for, da den er grunnlagt på bedre løfter. 
58:8:7: For hadde hin første vært ulastelig, da var det ikke søkt rum for en annen; 
58:8:8: det er jo lastende ord han taler til dem når han sier: Se, de dager kommer, sier Herren, da jeg vil oprette en ny pakt med Israels hus og med Judas hus, 
58:8:9: ikke efter den pakt som jeg gjorde med deres fedre på den dag da jeg tok dem ved hånden for å føre dem ut av Egyptens land; for de blev ikke i min pakt, og jeg brydde mig ikke om dem, sier Herren. 
58:8:10: For dette er den pakt som jeg vil oprette med Israels hus efter hine dager, sier Herren: Jeg vil gi mine lover i deres sinn, og jeg vil skrive dem i deres hjerte, og jeg vil være deres Gud, og de skal være mitt folk, 
58:8:11: og de skal ikke lære hver sin landsmann og hver sin bror og si: Kjenn Herren! for de skal alle kjenne mig, fra den minste til den største iblandt dem; 
58:8:12: for jeg vil være nådig mot deres urettferdigheter og ikke mere komme deres synder i hu. 
58:8:13: Idet han sier: en ny, har han dømt den første å være gammel; men det som blir gammelt og foreldes, er nær ved å bli borte. 
58:9:1: Nu hadde vel og den første pakt sine forskrifter for gudstjenesten og sin jordiske helligdom 
58:9:2: For der blev laget et telt, det forreste, og i dette var både lysestaken og bordet og skuebrødene: dette kalles det Hellige. 
58:9:3: Og bak det annet forheng var det telt som kalles det Aller-helligste, 
58:9:4: som hadde et røkoffer-alter av gull og paktens ark, som var klædd rundt om med gull, og i denne var en gullkrukke med manna og Arons stav, som hadde blomstret, og paktens tavler, 
58:9:5: og over den herlighetens kjeruber, som skygget over nådestolen; men om disse ting skal vi ikke nu tale stykke for stykke. 
58:9:6: Men da nu dette er laget således, går prestene alltid inn i det forreste telt når de utfører sin tjeneste; 
58:9:7: men i det annet telt går bare ypperstepresten inn en gang om året, ikke uten blod, som han bærer frem for sig selv og for folkets forseelser, 
58:9:8: idet den Hellige Ånd herved gir dette til kjenne at veien til helligdommen ennu ikke er åpenbaret så lenge det forreste telt ennu står, 
58:9:9: for dette er et billede inntil den nuværende tid, og svarende til dette bæres det da frem både gaver og slaktoffer, 
58:9:10: som dog ikke makter å gjøre den fullkommen efter samvittigheten som tjener Gud, men som bare, sammen med mat og drikke og alle slags tvetninger, er kjødelige forskrifter, pålagt inntil tiden kom til å sette alt i rette skikk. 
58:9:11: Men da Kristus kom som yppersteprest for de kommende goder, gikk han gjennem det større og fullkomnere telt, som ikke er gjort med hender, det er: som ikke er av denne skapning, 
58:9:12: og ikke med blod av bukker og kalver, men med sitt eget blod, en gang inn i helligdommen og fant en evig forløsning. 
58:9:13: For så sant blodet av bukker og okser og asken av en kvige, når den sprenges på de urene, helliger til kjødets renhet, 
58:9:14: hvor meget mere skal da Kristi blod, han som ved en evig ånd bar sig selv frem som et ulastelig offer for Gud, rense eders samvittighet fra døde gjerninger til å tjene den levende Gud! 
58:9:15: Og derfor er han mellemmann for en ny pakt, forat de kalte skal få den evige arv som var lovt, efterat en død har funnet sted til forløsning fra overtredelsene under den første pakt. 
58:9:16: For hvor det er et testament, der er det nødvendig at dens død som har oprettet det, blir godtgjort; 
58:9:17: for et testament kommer først til å gjelde efter døden, da det aldri har kraft så lenge den lever som oprettet det. 
58:9:18: Derfor er heller ikke den første pakt blitt innvidd uten blod; 
58:9:19: for da ethvert bud efter loven var blitt forkynt av Moses for alt folket, tok han blodet av kalvene og bukkene tillikemed vann og skarlagenrød ull og isop og sprengte det både på boken selv og på alt folket, idet han sa: 
58:9:20: Dette er den pakts blod som Gud har foreskrevet for eder. 
58:9:21: Men også teltet og alle gudstjenestens redskaper oversprengte han i like måte med blod. 
58:9:22: Og næsten alt blir efter loven renset med blod, og uten blod blir utgydt, skjer ikke forlatelse. 
58:9:23: Det er altså nødvendig at avbilledene av de himmelske ting blir renset ved dette, men de himmelske ting selv ved bedre offer enn disse. 
58:9:24: For Kristus gikk ikke inn i en helligdom som var gjort med hender og bare var et billede av den sanne, men inn i selve himmelen, for nu å åpenbares for Guds åsyn for vår skyld, 
58:9:25: og heller ikke forat han flere ganger skulde ofre sig selv, således som ypperstepresten hvert år går inn i helligdommen med fremmed blod; 
58:9:26: ellers måtte han jo ha lidt flere ganger fra verden blev grunnlagt; men nu er han åpenbaret en gang ved tidenes ende for å bortta synden ved sitt offer. 
58:9:27: Og likesom det er menneskenes lodd en gang å dø, og derefter dom, 
58:9:28: således skal og Kristus, efter å være ofret en gang for å bortta manges synder, annen gang åpenbare sig, uten synd, til frelse for dem som venter på ham. 
58:10:1: For da loven bare har en skygge av de kommende goder, men ikke selve billedet av tingene, så kan den aldri ved de offer som de hvert år alltid på ny bærer frem, gjøre dem fullkomne som kommer frem med dem. 
58:10:2: Ellers vilde de jo ha ophørt med å frembære dem, da de ofrende ikke lenger vilde ha synder på samvittigheten når de en gang var renset. 
58:10:3: Men ved dem kommer hvert år en minnelse om synder; 
58:10:4: for det er umulig at blod av okser og bukker kan bortta synder. 
58:10:5: Derfor sier han idet han treder inn i verden: Offer og gave vilde du ikke ha, men et legeme laget du for mig; 
58:10:6: brennoffer og syndoffer hadde du ikke lyst til. 
58:10:7: Da sa jeg: Se, jeg kommer - i bokrullen er det skrevet om mig - for å gjøre, Gud, din vilje. 
58:10:8: Idet han først sier: Offer og gaver og brennoffer og syndoffer vilde du ikke ha og hadde du ikke lyst til - og de bæres dog frem efter loven - 
58:10:9: så har han derefter sagt: Se, jeg kommer for å gjøre din vilje. Han tar det første bort for å innsette det annet, 
58:10:10: og ved denne vilje er vi helliget ved ofringen av Jesu Kristi legeme en gang for alle. 
58:10:11: Og hver prest står daglig og gjør tjeneste og bærer mange ganger frem de samme offer, som dog aldri kan bortta synder; 
58:10:12: men han har frembåret ett offer for synder og har derefter for alltid satt sig ved Guds høire hånd, 
58:10:13: og nu venter han bare på at hans fiender skal legges til skammel for hans føtter; 
58:10:14: for med ett offer har han for alltid gjort dem fullkomne som blir helliget. 
58:10:15: Men det vidner og den Hellige Ånd for oss; for efterat han har sagt: 
58:10:16: Dette er den pakt som jeg vil oprette med dem efter hine dager, så sier Herren: Jeg vil gi mine lover i deres hjerter, og jeg vil skrive dem i deres sinn, 
58:10:17: og deres synder og deres overtredelser vil jeg ikke mere komme i hu. 
58:10:18: Men hvor det er forlatelse for dem, der trenges ikke mere noget offer for synd. 
58:10:19: Da vi altså, brødre, i Jesu blod har frimodighet til å gå inn i helligdommen, 
58:10:20: som han har innvidd oss en ny og levende vei til gjennem forhenget, det er hans kjød, 
58:10:21: og da vi har en stor prest over Guds hus, 
58:10:22: så la oss trede frem med sanndru hjerte i troens fulle visshet, renset på hjertene fra en ond samvittighet og tvettet på legemet med rent vann; 
58:10:23: la oss holde uryggelig fast ved bekjennelsen av vårt håp - for han er trofast som gav løftet - 
58:10:24: og la oss gi akt på hverandre, så vi opgløder hverandre til kjærlighet og gode gjerninger, 
58:10:25: og ikke forlater vår egen forsamling, som nogen har for skikk, men formaner hverandre, og det så meget mere som I ser dagen nærme sig. 
58:10:26: For synder vi med vilje efter å ha lært sannheten å kjenne, da er det ikke mere tilbake noget offer for synder, 
58:10:27: men bare en forferdelig gru for dom og en nidkjærhetens brand som skal fortære de gjenstridige. 
58:10:28: Har nogen brutt Mose lov, da dør han uten barmhjertighet på to eller tre vidners ord; 
58:10:29: hvor meget verre straff tror I da den skal aktes verd som har trådt Guds Sønn under føtter og ringeaktet paktens blod, som han blev helliget ved, og har hånet nådens Ånd? 
58:10:30: Vi kjenner jo ham som har sagt: Mig hører hevnen til, jeg vil gjengjelde, og atter: Herren skal dømme sitt folk. 
58:10:31: Det er forferdelig å falle i den levende Guds hender. 
58:10:32: Men kom i hu de gamle dager, da I, efter å være blitt oplyst, utholdt en stor strid i lidelser, 
58:10:33: idet I dels blev til et skuespill ved hån og trengsler, dels led med dem som hadde det således. 
58:10:34: For også fangene hadde I medynk med, og fant eder med glede i at eders gods blev røvet, da I visste at I selv hadde en bedre og blivende eiendom. 
58:10:35: Kast derfor ikke bort eders frimodighet, som har stor lønn! 
58:10:36: For I trenger til tålmod, forat I, når I har gjort Guds vilje, kan opnå det som er lovt. 
58:10:37: For ennu er det bare så kort en stund, så kommer han som komme skal, og han skal ikke dryge; 
58:10:38: men den rettferdige, ved tro skal han leve, og dersom han unddrager sig, har min sjel ikke lyst til ham. 
58:10:39: Men vi er ikke av dem som unddrager sig til fortapelse, vi er av dem som tror til sjelens frelse. 
58:11:1: Men tro er full visshet om det som håpes, overbevisning om ting som ikke sees. 
58:11:2: For på grunn av den fikk de gamle godt vidnesbyrd. 
58:11:3: Ved tro skjønner vi at verden er kommet i stand ved Guds ord, så det som sees, ikke blev til av det synlige. 
58:11:4: Ved tro bar Abel frem for Gud et bedre offer enn Kain; ved den fikk han det vidnesbyrd at han var rettferdig, idet Gud vidnet om hans gaver, og ved den taler han ennu efter sin død. 
58:11:5: Ved tro blev Enok bortrykket, så han ikke skulde se døden, og han blev ikke funnet, fordi Gud hadde bortrykket ham. For før han blev bortrykket, fikk han det vidnesbyrd at han tektes Gud; 
58:11:6: men uten tro er det umulig å tekkes Gud; for den som treder frem for Gud, må tro at han er til, og at han lønner dem som søker ham. 
58:11:7: Ved tro bygget Noah, varslet av Gud om det som ennu ikke var sett, i hellig frykt en ark til frelse for sitt hus; ved den fordømte han verden og blev arving til rettferdigheten av tro. 
58:11:8: Ved tro var Abraham lydig da han blev kalt, så han drog ut til det sted han skulde få til arv, og han drog ut uten å vite hvorhen han skulde komme; 
58:11:9: ved tro opholdt han sig som utlending i det lovede land som i et fremmed land, idet han bodde i telt sammen med Isak og Jakob, medarvingene til det samme løfte; 
58:11:10: for han ventet på den stad som har de faste grunnvoller, og som Gud er byggmester og skaper til. 
58:11:11: Ved tro fikk også Sara kraft til å grunnlegge en ætt, og det til tross for sin alder, da hun aktet ham trofast som gav løftet; 
58:11:12: derfor blev det også av en, og det en utlevd, avlet så mange som himmelens stjerner og som sanden ved havets strand, som ingen kan telle. 
58:11:13: I tro døde alle disse uten at de hadde opnådd det som var lovt; men de så det langt borte og hilste det og bekjente at de var fremmede og utlendinger på jorden. 
58:11:14: For de som sier slikt, gir derved til kjenne at de søker et fedreland; 
58:11:15: og dersom de hadde tenkt på det som de var kommet fra, da hadde de jo hatt tid til å vende tilbake; 
58:11:16: men nu stunder de efter et bedre, det er et himmelsk; derfor skammer Gud sig ikke ved dem, ved å kalles deres Gud; for han har gjort en stad ferdig til dem. 
58:11:17: Ved tro ofret Abraham Isak dengang han blev fristet; ja, sin enbårne ofret han som hadde fått løftene, 
58:11:18: han som det blev sagt til: I Isak skal det nevnes dig en ætt; 
58:11:19: for han tenkte at Gud er mektig endog til å opvekke fra de døde, og derfra fikk han ham og likesom tilbake. 
58:11:20: Ved tro velsignet Isak Jakob og Esau, med syn på det som skulde komme. 
58:11:21: Ved tro velsignet Jakob døende hver og en av Josefs sønner og tilbad, bøiet over knappen på sin stav. 
58:11:22: Ved tro talte Josef på sitt siste om Israels barns utgang og gav påbud om sine ben. 
58:11:23: Ved tro blev Moses efter sin fødsel skjult i tre måneder av sine foreldre, fordi de så at gutten var fager, og de fryktet ikke for kongens bud. 
58:11:24: Ved tro nektet Moses, da han var blitt stor, å kalles Faraos datters sønn, 
58:11:25: idet han heller valgte å lide ondt sammen med Guds folk enn å ha en kortvarig nydelse av synden, 
58:11:26: og aktet Kristi vanære for en større rikdom enn Egyptens skatter; for han så frem til lønnen. 
58:11:27: Ved tro forlot han Egypten, uten å frykte for kongens vrede; for han holdt ut, som om han så den usynlige. 
58:11:28: Ved tro holdt han påsken med blodsprengningen, forat ikke ødeleggeren skulde røre deres førstefødte. 
58:11:29: Ved tro gikk de gjennem det Røde Hav som over tørt land; men da egypterne prøvde på det, druknet de. 
58:11:30: Ved tro falt Jerikos murer, da de hadde gått omkring dem i syv dager. 
58:11:31: Ved tro undgikk skjøgen Rahab å gå til grunne med de vantro, da hun hadde tatt imot speiderne med fred. 
58:11:32: Og hvorfor taler jeg lenger? Tiden vilde bli mig for kort om jeg skulde fortelle om Gideon og Barak og Samson og Jefta, om David og Samuel og profetene, 
58:11:33: som ved tro seiret over kongeriker, håndhevde rettferdighet, fikk løfter opfylt, stoppet gapet på løver, 
58:11:34: slukket ilds kraft, slapp fra sverds egg, fikk styrke igjen efter sykdom, blev veldige i krig, fikk fienders hærer til å vike; 
58:11:35: kvinner fikk sine døde igjen ved opstandelse; andre blev utspent til pinsel og vilde ikke ta imot utløsning, forat de kunde få del i en bedre opstandelse; 
58:11:36: andre fikk lide spott og hudstrykning, ja bånd og fengsel; 
58:11:37: de blev stenet, gjennemsaget, fristet; de døde for sverd; de flakket omkring i fåreskinn, i gjeteskinn, de led mangel, trengsel, hård medferd 
58:11:38: - verden var dem ikke verd - de vanket om i utørkener og fjell og huler og jordkløfter. 
58:11:39: Og enda alle disse fikk vidnesbyrd for sin tro, opnådde de ikke det som var lovt, 
58:11:40: fordi Gud forut hadde utsett noget bedre for oss, så de ikke skulde nå fullendelsen uten oss. 
58:12:1: Derfor, la og oss, da vi har så stor en sky av vidner omkring oss, avlegge alt som tynger, og synden som henger så fast ved oss, og med tålmodighet løpe i den kamp som er oss foresatt, 
58:12:2: idet vi ser på troens ophavsmann og fullender, Jesus, han som for den glede som ventet ham, led tålmodig korset, uten å akte vanæren, og nu sitter på høire side av Guds trone. 
58:12:3: Ja, gi akt på ham som tålmodig har lidt en slik motsigelse av syndere, så I ikke skal gå trett og bli motløse i eders sjeler! 
58:12:4: Ennu har I ikke gjort motstand like til blodet i eders kamp mot synden, 
58:12:5: og I har glemt den formaning som taler til eder som til barn: Min sønn! akt ikke Herrens tukt ringe, og bli ikke motløs når du refses av ham; 
58:12:6: for den Herren elsker, den tukter han, og han hudstryker hver sønn som han tar sig av. 
58:12:7: Det er for tuktens skyld at I tåler lidelser; Gud gjør med eder som med sønner. For hvem er den sønn som hans far ikke tukter? 
58:12:8: Men er I uten tukt, som alle har fått sin del av, da er I uekte barn, og ikke sønner. 
58:12:9: Dessuten: våre kjødelige fedre hadde vi til optuktere, og vi hadde ærefrykt for dem; skal vi da ikke meget mere være lydige mot åndenes Fader, så vi får leve? 
58:12:10: For hine tuktet oss for nogen få dager efter sitt tykke, men han tukter til vårt gagn, forat vi skal få del i hans hellighet. 
58:12:11: All tukt synes vel, mens den står på, ikke å være til glede, men til sorg; men siden gir den dem som derved er blitt opøvd, rettferdighets salige frukt. 
58:12:12: Derfor, rett de hengende hender og de maktløse knær, 
58:12:13: og gjør rette stier for eders føtter, forat ikke det halte skal komme rent i ulag, men heller må bli helbredet! 
58:12:14: Jag efter fred med alle og efter helliggjørelse; for uten helliggjørelse skal ingen se Herren. 
58:12:15: Og gi akt på at ikke nogen viker tilbake fra Guds nåde, at ikke nogen bitter rot skal vokse op og volde men, og mange bli smittet ved den, 
58:12:16: at ikke nogen er en horkarl eller en vanhellig som Esau, som for en eneste rett mat solgte sin førstefødselsrett. 
58:12:17: I vet jo at han og senere, da han vilde arve velsignelsen, blev avvist - for han fant ikke rum for bot - enda han søkte den med tårer. 
58:12:18: For I er ikke kommet til et fjell som en kan ta på med hender, og til brennende ild og til skodde og mørke og uvær, 
58:12:19: og til basunlyd og røst av ord, slik at de som hørte den, bad at der ikke måtte tales mere til dem; 
58:12:20: for de kunde ikke bære dette bud: Om det så bare er et dyr som rører ved fjellet, skal det stenes, 
58:12:21: og så fryktelig var synet at Moses sa: Jeg er forferdet og skjelver. 
58:12:22: Men I er kommet til Sions berg og den levende Guds stad, det himmelske Jerusalem, og til englenes mange tusener, 
58:12:23: til høitidsskaren og menigheten av de førstefødte, som er opskrevet i himlene, og til dommeren, som er alles Gud, og til de fullendte rettferdiges ånder, 
58:12:24: og til Jesus, mellemmann for en ny pakt, og til oversprengningens blod, som taler bedre enn Abels. 
58:12:25: Se til at I ikke avviser ham som taler! For slapp ikke hine fri, de som avviste ham som talte på jorden, hvor meget mindre skal da vi slippe om vi vender oss bort fra ham som taler fra himmelen! 
58:12:26: Hans røst rystet dengang jorden; men nu har han lovt og sagt: Ennu en gang vil jeg ryste ikke bare jorden, men også himmelen. 
58:12:27: Men det ord: Ennu en gang, gir til kjenne en omskiftelse av de ting som rystes, fordi de er skapt, så de ting som ikke rystes, skal bli ved. 
58:12:28: Derfor, da vi får et rike som ikke kan rystes, så la oss være takknemlige og derved tjene Gud til hans velbehag, med blygsel og frykt! 
58:12:29: For vår Gud er en fortærende ild. 
58:13:1: La broderkjærligheten bli ved! 
58:13:2: Glem ikke gjestfrihet! for ved den har nogen uten å vite det hatt engler til gjester. 
58:13:3: Kom fangene i hu som deres medfanger, dem som lider ondt, siden I og selv er i legemet! 
58:13:4: Ekteskapet være i akt og ære hos alle, og ektesengen usmittet! for horkarler og ekteskapsbrytere skal Gud dømme. 
58:13:5: Eders ferd være uten pengekjærhet, så I nøies med det I har; for han har sagt: Jeg vil ingenlunde slippe dig og ingenlunde forlate dig, 
58:13:6: så vi kan si med fritt mot: Herren er min hjelper, jeg vil ikke frykte; hvad kan et menneske gjøre mig? 
58:13:7: Kom i hu eders veiledere, som har talt Guds ord til eder! gi akt på utgangen av deres ferd, og efterfølg så deres tro! 
58:13:8: Jesus Kristus er igår og idag den samme, ja til evig tid. 
58:13:9: La eder ikke føre på avveie ved mange forskjellige og fremmede lærdommer! for det er godt at hjertet blir styrket ved nåden, ikke ved mat, som ikke har gagnet dem som gav sig av dermed. 
58:13:10: Vi har et alter som de ikke har rett til å ete av de som tjener ved teltet; 
58:13:11: for de dyr hvis blod bæres inn i helligdommen ved ypperstepresten til å sone for synd, deres kropper brennes op utenfor leiren; 
58:13:12: derfor led også Jesus utenfor porten, forat han ved sitt eget blod kunde hellige folket. 
58:13:13: La oss da gå ut til ham utenfor leiren og bære hans vanære! 
58:13:14: for vi har ikke her en blivende stad, men søker den kommende. 
58:13:15: La oss da ved ham alltid frembære lovoffer for Gud, det er: frukt av leber som lover hans navn! 
58:13:16: Men glem ikke å gjøre godt og å dele med andre! for slike offer tekkes Gud. 
58:13:17: Lyd eders veiledere og rett eder efter dem! for de våker over eders sjeler som de som skal gjøre regnskap, så de kan gjøre det med glede og ikke sukkende; for det er eder ikke til gagn. 
58:13:18: Bed for oss! for vi trøster oss til at vi har en god samvittighet, og vil gjerne fare rett frem i alle stykker. 
58:13:19: Og jeg ber eder dess mere å gjøre dette forat jeg dess snarere må bli gitt eder tilbake. 
58:13:20: Men fredens Gud, som i kraft av en evig pakts blod førte fårenes store hyrde, vår Herre Jesus, op fra de døde, 
58:13:21: han gjøre eder fullt dyktige i all god gjerning, så I kan gjøre hans vilje, idet han virker i eder det som tekkes ham, ved Jesus Kristus; ham være æren i all evighet! Amen. 
58:13:22: Jeg ber eder, brødre, ta dette formaningens ord vel op; for jeg har skrevet til eder i korthet. 
58:13:23: Vit at vår bror Timoteus er gitt fri; sammen med ham vil jeg se eder, om han kommer snart. 
58:13:24: Hils alle eders veiledere og alle de hellige! De fra Italia hilser eder. 
58:13:25: Nåden være med eder alle! 
59:1:1: Jakob, Guds og den Herre Jesu Kristi tjener, hilser de tolv stammer som er spredt omkring i landene. 
59:1:2: Akt det for bare glede, mine brødre, når I kommer i allehånde fristelser, 
59:1:3: da I vet at prøvelsen av eders tro virker tålmodighet; 
59:1:4: men tålmodigheten må føre til fullkommen gjerning, forat I kan være fullkomne og hele og ikke mangle noget. 
59:1:5: Men dersom nogen av eder mangler visdom, da bede han Gud, han som gir alle villig og uten onde ord, og den skal gis ham. 
59:1:6: Men han bede i tro, uten å tvile; for den som tviler, ligner havsbølgen, som drives og kastes av vinden. 
59:1:7: For ikke må det menneske tro at han skal få noget av Herren, 
59:1:8: slik en tvesinnet mann, ustø på alle sine veier. 
59:1:9: Men den ringe bror rose sig av sin høihet, 
59:1:10: og den rike av sin ringhet; for han skal forgå som blomst på gress; 
59:1:11: solen gikk op med sin brand og tørket gresset bort, og blomsten på det falt av, og dets fagre skikkelse blev ødelagt; således skal og den rike visne bort på sine veier. 
59:1:12: Salig er den mann som holder ut i fristelse; for når han har stått sin prøve, skal han få livsens krone, som Gud har lovt dem som elsker ham. 
59:1:13: Ingen si når han fristes: Jeg fristes av Gud. For Gud fristes ikke av det onde, og selv frister han ingen. 
59:1:14: Men hver fristes idet han drages og lokkes av sin egen lyst; 
59:1:15: derefter, når lysten har undfanget, føder den synd; men når synden er blitt fullmoden, føder den død. 
59:1:16: Far ikke vill, mine elskede brødre! 
59:1:17: All god gave og all fullkommen gave kommer ovenfra, fra lysenes Fader, hos hvem det ikke er forandring eller skiftende skygge. 
59:1:18: Efter sin vilje har han født oss ved sannhets ord, forat vi skal være en førstegrøde av hans skapninger. 
59:1:19: I vet det jo, mine elskede brødre! Men hvert menneske være snar til å høre, sen til å tale, sen til vrede; 
59:1:20: for manns vrede virker ikke det som er rett for Gud. 
59:1:21: Avlegg derfor all urenhet og all levning av ondskap, og ta med saktmodighet imot det ord som er innplantet i eder, og som er mektig til å frelse eders sjeler! 
59:1:22: Men vær ordets gjørere, og ikke bare dets hørere, idet I dårer eder selv. 
59:1:23: For dersom en er ordets hører og ikke dets gjører, da ligner han en mann som ser på sitt naturlige åsyn i et speil: 
59:1:24: han så på sig selv og gikk bort, og glemte straks hvordan han så ut. 
59:1:25: Men den som skuer inn i frihetens fullkomne lov og holder ved med det, så han ikke blir en glemsom hører, men gjerningens gjører, han skal være salig i sin gjerning. 
59:1:26: Dersom en mener at han er en gudsdyrker, og ikke holder sin tunge i tømme, men dårer sitt eget hjerte, hans gudsdyrkelse er forgjeves. 
59:1:27: En ren og usmittet gudsdyrkelse for Gud og Faderen er dette: å se til farløse og enker i deres trengsel, å holde sig selv uplettet av verden. 
59:2:1: Mine brødre! la ikke eders tro på vår Herre Jesus Kristus, den herliggjorte, være forenet med at I gjør forskjell på folk! 
59:2:2: For om det kommer en mann inn i eders forsamling med gullring på fingeren, i skinnende klædning, og det også kommer en fattigmann inn i skitten klædning, 
59:2:3: og I ser på ham som bærer den skinnende klædning, og sier: Sett du dig her på en god plass! og sier til den fattige: Stå du der, eller sett dig her nede ved min fotskammel! - 
59:2:4: gjør I da ikke forskjell hos eder selv og er blitt dommere med onde tanker? 
59:2:5: Hør dog, mine elskede brødre: Har ikke Gud utvalgt dem som er fattige på verdens gods, til å være rike i troen og arvinger til det rike han har lovt dem som elsker ham? 
59:2:6: Men I har vanæret den fattige. Er det ikke de rike som underkuer eder, og som drar eder for domstolene? 
59:2:7: Er det ikke de som spotter det gode navn I er nevnt med? 
59:2:8: Visselig, dersom I opfyller den kongelige lov efter Skriften: Du skal elske din næste som dig selv, da gjør I vel; 
59:2:9: men gjør I forskjell på folk, da gjør I synd, og loven refser eder som lovbrytere. 
59:2:10: For enhver som holder hele loven, men snubler i én ting, han er blitt skyldig i alle. 
59:2:11: For han som sa: Du skal ikke drive hor, han sa også: Du skal ikke slå ihjel. Om du da ikke driver hor, men slår ihjel, da er du blitt lovbryter. 
59:2:12: Tal så og gjør så som de som skal dømmes efter frihetens lov! 
59:2:13: For dommen skal være ubarmhjertig mot den som ikke har gjort barmhjertighet; men barmhjertighet roser sig mot dommen. 
59:2:14: Hvad nytter det, mine brødre, om en sier at han har tro, når han ikke har gjerninger? Kan vel troen frelse ham? 
59:2:15: Om en bror eller søster er naken og fattes føde for dagen, 
59:2:16: og nogen av eder sier til dem: Gå bort i fred, varm eder og mett eder! men I ikke gir dem det som legemet trenger, hvad nytter det? 
59:2:17: Således og med troen: har den ikke gjerninger, er den død i sig selv. 
59:2:18: Men en kan si: Du har tro, og jeg har gjerninger. Vis mig din tro uten gjerninger, og jeg vil vise dig min tro av mine gjerninger! 
59:2:19: Du tror at Gud er én; du gjør vel; djevlene tror det også og skjelver. 
59:2:20: Og vil du vite det, du dårlige menneske, at troen uten gjerninger er unyttig? 
59:2:21: Abraham, vår far, blev ikke han rettferdiggjort ved gjerninger, da han ofret sin sønn Isak på alteret? 
59:2:22: Du ser at troen virket sammen med hans gjerninger, og at troen blev fullkommen ved gjerningene, 
59:2:23: og Skriften blev opfylt, som sier: Abraham trodde Gud, og det blev regnet ham til rettferdighet, og han blev kalt Guds venn. 
59:2:24: I ser at mennesket blir rettferdiggjort ved gjerninger, og ikke ved tro alene. 
59:2:25: I like måte Rahab, skjøgen; blev hun ikke rettferdiggjort ved gjerninger, da hun tok imot utsendingene og slapp dem ut en annen vei? 
59:2:26: For likesom legemet er dødt uten ånd, så er og troen død uten gjerninger. 
59:3:1: Mine brødre! ikke mange av eder bli lærere, eftersom I vet at vi skal få dess tungere dom! 
59:3:2: For vi snubler alle sammen i mange ting; den som ikke snubler i tale, han er en fullkommen mann, i stand til også å holde hele legemet i tømme. 
59:3:3: Når vi legger bissel i munnen på hestene, forat de skal lyde oss, så styrer vi og hele deres legeme. 
59:3:4: Se, også skibene, enda de er så store og drives av sterke vinder, styres dog av et lite ror dit styrmannen vil ha dem. 
59:3:5: Så er og tungen et lite lem, og taler dog store ord. Se, en liten ild, hvor stor en skog den setter i brand! 
59:3:6: Også tungen er en ild; som en verden av urettferdighet står tungen blandt våre lemmer; den smitter hele legemet og setter livets hjul i brand, og settes selv i brand av helvede. 
59:3:7: For all natur, både i dyr og i fugler, både i ormer og i sjødyr, temmes og er blitt temmet av den menneskelige natur; 
59:3:8: men tungen kan intet menneske temme, det ustyrlige onde, full av dødelig gift. 
59:3:9: Med den velsigner vi Herren og Faderen, og med den forbanner vi menneskene, som er skapt efter Guds billede. 
59:3:10: Av samme munn utgår velsignelse og forbannelse. Mine brødre! dette må ikke være så. 
59:3:11: Gir vel kilden av samme opkomme søtt og beskt vann? 
59:3:12: Kan vel et fikentre, mine brødre, bære oljebær, eller et vintre fiken? Heller ikke kan en salt kilde gi søtt vann. 
59:3:13: Hvem er vis og forstandig blandt eder? Han vise ved god ferd sine gjerninger i visdoms saktmodighet! 
59:3:14: Men har I besk avind og trettesyke i eders hjerter, da ros eder ikke mot sannheten og lyv ikke mot den! 
59:3:15: Denne visdom kommer ikke ovenfra, men er jordisk, sanselig, djevelsk; 
59:3:16: for hvor der er avind og trettesyke, der er urede og alt det som ondt er. 
59:3:17: Men den visdom som er ovenfra, er først og fremst ren, dernæst fredsommelig, rimelig, eftergivende, full av barmhjertighet og gode frukter, uten tvil, uten skrømt. 
59:3:18: Men rettferdighets frukt såes i fred for dem som holder fred. 
59:4:1: Hvorfra kommer all ufreden, og hvorfra kommer all striden iblandt eder? Er det ikke av eders lyster, som fører krig i eders lemmer? 
59:4:2: I attrår, og har ikke; I slår ihjel og bærer avind, og kan ikke få; I ligger i strid og ufred. I har ikke, fordi I ikke beder; 
59:4:3: I beder og får ikke, fordi I beder ille, for å øde det i eders lyster. 
59:4:4: I utro! vet I ikke at vennskap med verden er fiendskap mot Gud? Den altså som vil være verdens venn, han blir Guds fiende. 
59:4:5: Eller mener I at Skriften taler fåfengt? Med nidkjærhet attrår han den ånd han lot bo i oss, men dess større er den nåde han gir. 
59:4:6: Derfor heter det: Gud står de stolte imot, men de ydmyke gir han nåde. 
59:4:7: Vær derfor Gud undergitt! Men stå djevelen imot, og han skal fly fra eder; 
59:4:8: hold eder nær til Gud, og han skal holde sig nær til eder! Tvett hendene, I syndere, og rens hjertene, I tvesinnede! 
59:4:9: Kjenn eders nød og sørg og gråt! Eders latter vende sig til sorg, og gleden til bedrøvelse! 
59:4:10: Ydmyk eder for Herren, og han skal ophøie eder! 
59:4:11: Baktal ikke hverandre, brødre! Den som baktaler en bror eller dømmer sin bror, han baktaler loven og dømmer loven; men dømmer du loven, da er du ikke lovens gjører, men dens dommer. 
59:4:12: Én er lovgiveren og dommeren, han som er mektig til å frelse og til å ødelegge; men du, hvem er du som dømmer din neste? 
59:4:13: Og nu, I som sier: Idag eller imorgen vil vi dra til den by og bli der et år og kjøpslå og ha vinning, 
59:4:14: I som ikke vet hvad som skal hende imorgen! For hvad er eders liv? I er jo en røk som viser sig en liten stund og så blir borte! 
59:4:15: Istedenfor at I skulde si: Om Herren vil, så blir vi i live, og vi skal gjøre dette eller hint. 
59:4:16: Men nu roser I eder i eders overmot. All sådan ros er ond. 
59:4:17: Den som altså vet å gjøre godt og ikke gjør det, han har synd av det. 
59:5:1: Og nu, I rike: Gråt og jamre over eders ulykker, som kommer over eder! 
59:5:2: Eders rikdom er råtnet, og eders klær er blitt møllett; 
59:5:3: eders gull og sølv er rustet bort, og rusten på det skal være til vidnesbyrd mot eder og ete eders kjød som en ild; I har samlet skatter i de siste dager! 
59:5:4: Se, den lønn I har forholdt arbeiderne som har skåret eders akrer, den skriker, og høstfolkenes rop er kommet inn for den Herre Sebaots ører. 
59:5:5: I har levd i vellevnet på jorden og efter eders lyster; I har gjødd eders hjerter på slaktedagen! 
59:5:6: I har domfelt og drept den rettferdige; ingen gjør motstand mot eder. 
59:5:7: Vær da tålmodige, brødre, til Herren kommer! Se, bonden venter på jordens kostelige grøde og bier tålmodig på den, til den får høstregn og vårregn; 
59:5:8: vær og I tålmodige, styrk eders hjerter! for Herrens komme er nær. 
59:5:9: Sukk ikke mot hverandre, brødre, forat I ikke skal dømmes! Se, dommeren står for døren. 
59:5:10: Mine brødre! ta profetene, som talte i Herrens navn, til eders forbillede i å lide ondt og være tålmodig! 
59:5:11: Se, vi priser dem salige som lider tålmodig. I har hørt om Jobs tålmod og sett den utgang som Herren gjorde; for Herren er overmåte miskunnelig og barmhjertig. 
59:5:12: Men fremfor alt, mine brødre, sverg ikke, hverken ved himmelen eller ved jorden eller nogen annen ed! men eders ja være ja, og eders nei være nei, forat I ikke skal falle under dommen! 
59:5:13: Lider nogen iblandt eder ondt, han bede; er nogen vel til mote, han synge lovsanger. 
59:5:14: Er nogen iblandt eder syk, han kalle til sig menighetens eldste, og de skal bede over ham og salve ham med olje i Herrens navn, 
59:5:15: og troens bønn skal hjelpe den syke, og Herren skal reise ham op, og har han gjort synder, skal de bli ham forlatt. 
59:5:16: Bekjenn derfor eders synder for hverandre og bed for hverandre, forat I kan bli lægt! En rettferdig manns bønn har stor kraft i sin virkning. 
59:5:17: Elias var et menneske under samme vilkår som vi, og han bad at det ikke skulde regne, og det regnet ikke på jorden i tre år og seks måneder; 
59:5:18: og han bad atter, og himmelen gav regn, og jorden bar sin grøde. 
59:5:19: Mine brødre! dersom nogen iblandt eder har faret vill fra sannheten, og en omvender ham, 
59:5:20: han skal vite at den som omvender en synder fra hans villfarende vei, han frelser en sjel fra døden og skjuler en mangfoldighet av synder. 
60:1:1: Peter, Jesu Kristi apostel - til de utlendinger som er spredt omkring i Pontus, Galatia, Kappadokia, Asia og Bitynia, utvalgt 
60:1:2: efter Gud Faders forutviten, i Åndens helliggjørelse, til lydighet og oversprengning med Jesu Kristi blod: Nåde og fred bli eder mangfoldig til del! 
60:1:3: Lovet være Gud og vår Herre Jesu Kristi Fader, han som efter sin store miskunn har gjenfødt oss til et levende håp ved Jesu Kristi opstandelse fra de døde, 
60:1:4: til en uforgjengelig og usmittet og uvisnelig arv, som er gjemt i himlene for eder, 
60:1:5: I som ved Guds makt holdes oppe ved troen til den frelse som er ferdig til å bli åpenbaret i den siste tid. 
60:1:6: Derover fryder I eder, om I enn nu - når så skal være - har sorg en liten stund ved allehånde fristelser, 
60:1:7: forat eders prøvede tro, som er meget kosteligere enn det forgjengelige gull, som dog prøves ved ild, må finnes til lov og pris og ære i Jesu Kristi åpenbarelse, 
60:1:8: han som I ikke har kjent og dog elsker, han som I nu ikke ser og dog tror på, og derfor fryder I eder med en usigelig og herliggjort glede, 
60:1:9: når I vinner frem til endemålet for eders tro, sjelenes frelse. 
60:1:10: Om denne frelse var det profetene gransket og ransaket, de som spådde om den nåde som I skulde få, 
60:1:11: idet de ransaket hvilken eller hvad slags tid Kristi Ånd, som var i dem, viste frem til når han forut vidnet om Kristi lidelser og om herligheten derefter; 
60:1:12: for det blev dem åpenbaret at de ikke tjente sig selv, men oss, med dette som nu er blitt kunngjort ved dem som har forkynt eder evangeliet ved den Hellige Ånd, som blev sendt fra himmelen - dette som englene attrår å skue inn i. 
60:1:13: Derfor, omgjord eders sinns lender, vær edrue, og sett eders håp fullt og fast til den nåde som blir eder til del i Jesu Kristi åpenbarelse! 
60:1:14: Som lydige barn skal I ikke skikke eder efter de forrige lyster i eders vankundighet, 
60:1:15: men vær, efter den Hellige som kalte eder, også I hellige i all eders ferd! 
60:1:16: for det er skrevet: I skal være hellige; for jeg er hellig. 
60:1:17: Og når I påkaller som Fader ham som dømmer uten å gjøre forskjell, efter enhvers gjerning, da ferdes i frykt i eders utlendighets tid, 
60:1:18: for I vet at I ikke med forgjengelige ting, sølv eller gull, blev løskjøpt fra eders dårlige ferd, som var arvet fra fedrene, 
60:1:19: men med Kristi dyre blod som blodet av et ulastelig og lyteløst lam, 
60:1:20: han som forut var kjent, før verdens grunnvoll blev lagt, men blev åpenbaret ved tidenes ende for eders skyld, 
60:1:21: I som ved ham tror på Gud, som opvakte ham fra de døde og gav ham herlighet, så at eders tro også er håp til Gud. 
60:1:22: Rens eders sjeler i lydighet mot sannheten til uskrømtet broderkjærlighet, og elsk hverandre inderlig av hjertet, 
60:1:23: I som er gjenfødt, ikke av forgjengelig, men uforgjengelig sæd, ved Guds ord, som lever og blir! 
60:1:24: For alt kjød er som gress, og all dets herlighet som blomst på gress: gresset visnet, og blomsten på det falt av, 
60:1:25: men Herrens ord blir evindelig; og dette er det ord som er forkynt eder ved evangeliet. 
60:2:1: Avlegg derfor all ondskap og all svik og også hykleri og avind og all baktalelse, 
60:2:2: og lenges som nyfødte barn efter den åndelige uforfalskede melk, forat I kan vokse ved den til frelse, 
60:2:3: så sant I har smakt at Herren er god! 
60:2:4: Kom til ham, den levende sten, som vel blev forkastet av mennesker, men er utvalgt og kostelig for Gud, 
60:2:5: og bli også I opbygget som levende stener til et åndelig hus, et hellig presteskap til å frembære åndelige offer, som tekkes Gud ved Jesus Kristus. 
60:2:6: For det heter i Skriften: Se, jeg legger i Sion en hjørnesten, utvalgt, kostelig, og den som tror på ham, skal ingenlunde bli til skamme. 
60:2:7: Eder altså som tror, hører æren til; men for de vantro er den sten som bygningsmennene forkastet, blitt til hjørnesten og snublesten og anstøtsklippe, 
60:2:8: disse som snubler ved sin vantro mot ordet; dertil er de og satt. 
60:2:9: Men I er en utvalgt ætt, et kongelig presteskap, et hellig folk, et folk til eiendom, forat I skal forkynne hans dyder som kalte eder fra mørke til sitt underfulle lys, 
60:2:10: I som fordum ikke var et folk, men nu er Guds folk, I som ikke hadde funnet miskunn, men nu har fått miskunn. 
60:2:11: I elskede! jeg formaner eder som fremmede og utlendinger at I avholder eder fra de kjødelige lyster, som strider mot sjelen, 
60:2:12: så I lar eders ferd iblandt hedningene være god, forat de mens de baktaler eder som ugjerningsmenn, kan se eders gode gjerninger og for deres skyld prise Gud på besøkelsens dag. 
60:2:13: Vær derfor all menneskelig ordning undergitt for Herrens skyld, enten det nu er kongen som den høieste, 
60:2:14: eller landshøvdinger som utsendinger fra ham til straff for ugjerningsmenn, men til ros for dem som gjør godt. 
60:2:15: For således er det Guds vilje at I ved å gjøre det gode skal målbinde de uvettige menneskers vankundighet, 
60:2:16: som frie, og ikke som de som har friheten til ondskaps skjul, men som Guds tjenere. 
60:2:17: Ær alle, elsk broderskapet, frykt Gud, ær kongen! 
60:2:18: I tjenere! vær eders herrer undergitt i all frykt, ikke bare de gode og rimelige, men også de vrange! 
60:2:19: For dette finner nåde, om nogen av samvittighet for Gud finner sig i sorger når han lider urettferdig. 
60:2:20: For hvad ros er det om I er tålmodige når I synder og får straff for det? Men om I er tålmodige når I gjør det gode og allikevel må lide, da finner dette nåde hos Gud. 
60:2:21: For dertil blev I og kalt, fordi også Kristus led for eder og efterlot eder et eksempel, forat I skal følge efter i hans fotspor, 
60:2:22: han som ikke gjorde synd, og i hvis munn det ikke blev funnet svik, 
60:2:23: han som ikke skjelte igjen når han blev utskjelt, ikke truet når han led, men overlot det til ham som dømmer rettferdig; 
60:2:24: han som bar våre synder på sitt legeme op på treet, forat vi skal avdø fra våre synder og leve for rettferdigheten, han ved hvis sår I er lægt. 
60:2:25: For I var villfarende som får, men har nu omvendt eder til eders sjelers hyrde og tilsynsmann. 
60:3:1: Likeså I hustruer: Underordne eder under eders egne menn, så endog de som er vantro mot ordet, kan bli vunnet uten ord ved sine hustruers ferd, 
60:3:2: når de ser for sine øine eders rene ferd i frykt. 
60:3:3: Deres pryd skal ikke være den utvortes med hårfletning og påhengte gullsmykker eller klædebon, 
60:3:4: men hjertets skjulte menneske i den uforgjengelige prydelse med den saktmodige og stille ånd, som er kostelig for Gud. 
60:3:5: For således prydet og fordum de hellige kvinner sig, de som satte sitt håp til Gud, og de underordnet sig under sine egne menn, 
60:3:6: likesom Sara var Abraham lydig og kalte ham herre; og hennes barn er I blitt når I gjør det gode og ikke lar eder skremme av noget. 
60:3:7: Likeså I ektemenn: Lev med forstand sammen med eders hustruer som det svakere kar, og vis dem ære, da de og er medarvinger til livets nåde, forat eders bønner ikke skal hindres. 
60:3:8: Og til slutning: Vær alle enige, medlidende, kjærlige mot brødrene, barmhjertige, ydmyke, 
60:3:9: så I ikke gjengjelder ondt med ondt eller skjellsord med skjellsord, men tvert imot velsigner; for dertil blev I kalt, at I skal arve velsignelse. 
60:3:10: For den som vil elske livet og se gode dager, han holde sin tunge fra ondt og sine leber fra å tale svik; 
60:3:11: han gå av veien for ondt og gjøre godt, han søke fred og jage efter den! 
60:3:12: For Herrens øine er over de rettferdige, og hans ører vendt til deres bønn; men Herrens åsyn er over dem som gjør ondt. 
60:3:13: Og hvem er det som kan gjøre eder ondt såfremt I legger vinn på det gode? 
60:3:14: Men om I enn skulde lide for rettferdighets skyld, er I salige. Men frykt ikke for dem og forferdes ikke; men hellige Kristus som Herre i eders hjerter, 
60:3:15: og vær alltid rede til å forsvare eder for enhver som krever eder til regnskap for det håp som bor i eder, dog med saktmodighet og frykt, 
60:3:16: idet I har en god samvittighet, forat de som laster eders gode ferd i Kristus, må bli til skamme i det som de baktaler eder for som ugjerningsmenn. 
60:3:17: For det er bedre, om så er Guds vilje, å lide når en gjør godt, enn når en gjør ondt. 
60:3:18: For også Kristus led en gang for synder, en rettferdig for urettferdige, for å føre oss frem til Gud, han som led døden i kjødet, men blev levendegjort i ånden, 
60:3:19: og i denne gikk han og bort og preket for åndene som var i varetekt, 
60:3:20: de som fordum hadde vært gjenstridige, dengang da Guds langmodighet ventet i Noahs dager, mens arken blev bygget, i hvilken nogen få - åtte sjeler - blev frelst ved vann, 
60:3:21: det som også nu frelser oss i sitt motbillede, dåpen, som ikke er avleggelse av kjødets urenhet, men en god samvittighets pakt med Gud ved Jesu Kristi opstandelse, 
60:3:22: han som er faret op til himmelen og er ved Guds høire hånd, hvor engler og makter og krefter er ham underlagt. 
60:4:1: Da nu Kristus har lidt i kjødet, så væbne og I eder med den samme tanke, at den som har lidt i kjødet, er ferdig med synden, 
60:4:2: så I ikke lenger skal leve efter menneskers lyster, men efter Guds vilje, den tid I ennu skal være i kjødet. 
60:4:3: For det er nok at I i den fremfarne livstid har gjort hedningenes vilje, idet I ferdedes i skamløshet, lyster, fyll, svir, drikk og skammelig avgudsdyrkelse; 
60:4:4: derfor undrer de sig når I ikke renner med dem ut i den samme strøm av ryggesløshet, og spotter eder; 
60:4:5: men de skal gjøre regnskap for ham som står ferdig til å dømme levende og døde. 
60:4:6: For derfor blev evangeliet forkynt også for døde at de visstnok skulde dømmes som mennesker i kjødet, men leve således som Gud i ånden. 
60:4:7: Men alle tings ende er kommet nær; vær derfor sindige og edrue så I kan bede, 
60:4:8: og ha fremfor alle ting inderlig kjærlighet til hverandre! for kjærligheten skjuler en mangfoldighet av synder. 
60:4:9: Vær gjestfrie mot hverandre uten knurr! 
60:4:10: Efter som enhver har fått en nådegave, så tjen hverandre med den som gode husholdere over Guds mangehånde nåde: 
60:4:11: om nogen taler, han tale som Guds ord; om nogen tjener i menigheten, han tjene som av den kraft Gud gir, forat Gud må æres i alle ting ved Jesus Kristus, han som æren og makten tilhører i all evighet. Amen. 
60:4:12: I elskede! undre eder ikke over den ild som kommer over eder til prøvelse, som om det hendte eder noget underlig; 
60:4:13: men i samme mon som I har del i Kristi lidelser, skal I glede eder, forat I også i hans herlighets åpenbarelse kan glede eder med jubel. 
60:4:14: Om I hånes for Kristi navns skyld, er I salige, fordi herlighetens og Guds Ånd hviler over eder. 
60:4:15: For ingen av eder må lide som morder eller tyv eller ugjerningsmann eller som en som blander sig i andres saker; 
60:4:16: men lider han som kristen, da skal han ikke skamme sig, men prise Gud for dette navn. 
60:4:17: For det er nu tiden da dommen skal begynne med Guds hus; men begynner det med oss, hvad blir da enden for dem som ikke vil tro Guds evangelium? 
60:4:18: Og blir den rettferdige vanskelig frelst, hvor skal det da bli av den ugudelige og synderen? 
60:4:19: Derfor skal og de som efter Guds vilje må lide, overgi sine sjeler til den trofaste skaper, idet de gjør det gode. 
60:5:1: De eldste blandt eder formaner jeg som medeldste og vidne om Kristi lidelser, som den som og har del i den herlighet som skal åpenbares: 
60:5:2: Vokt den Guds hjord som er hos eder, og ha tilsyn med den, ikke av tvang, men frivillig, ikke for ussel vinnings skyld, men av villig hjerte, 
60:5:3: heller ikke som de som vil herske over sine menigheter, men således at I blir mønster for hjorden; 
60:5:4: og når overhyrden åpenbares, skal I få ærens uvisnelige krans. 
60:5:5: Likeså skal I yngre underordne eder under de eldre, og I alle skal iklæ eder ydmykhet mot hverandre; for Gud står de stolte imot, men de ydmyke gir han nåde. 
60:5:6: Ydmyk eder derfor under Guds veldige hånd, forat han kan ophøie eder i sin tid, 
60:5:7: og kast all eders sorg på ham! for han har omsorg for eder. 
60:5:8: Vær edrue, våk! Eders motstander djevelen går omkring som en brølende løve og søker hvem han kan opsluke; 
60:5:9: stå ham imot, faste i troen, for I vet at de samme lidelser er lagt på eders brødre i verden. 
60:5:10: Men all nådes Gud, som kalte eder til sin evige herlighet i Kristus Jesus, efter en kort tids lidelse, han skal dyktiggjøre, stadfeste, styrke, grunnfeste eder; 
60:5:11: ham tilhører makten i all evighet. Amen. 
60:5:12: Med Silvanus, den trofaste bror - det holder jeg ham for - skriver jeg kortelig til eder for å formane og vidne at dette er Guds sanne nåde som I står i. 
60:5:13: Den medutvalgte menighet i Babylon hilser eder, likeså Markus, min sønn. 
60:5:14: Hils hverandre med kjærlighets kyss! Fred være med alle eder som er i Kristus! 
61:1:1: Simeon Peter, Jesu Kristi tjener og apostel - til dem som har fått samme dyre tro som vi ved vår Gud og frelser Jesu Kristi rettferdighet: 
61:1:2: Nåde og fred bli eder mangfoldig til del, idet I kjenner Gud og Jesus, vår Herre! 
61:1:3: Eftersom hans guddommelige makt har gitt oss alt som tjener til liv og gudsfrykt, ved kunnskapen om ham som kalte oss ved sin egen herlighet og kraft 
61:1:4: og derved har gitt oss de største og dyreste løfter, forat I ved dem skulde få del i guddommelig natur, idet I flyr bort fra fordervelsen i verden, som kommer av lysten, 
61:1:5: så legg og just derfor all vinn på i eders tro å vise dyd, og i dyden skjønnsomhet, 
61:1:6: og i skjønnsomheten avhold, og i avholdet tålmod, og i tålmodet gudsfrykt, 
61:1:7: og i gudsfrykten broderkjærlighet, og i broderkjærligheten kjærlighet til alle. 
61:1:8: For når disse ting finnes hos eder og får vokse, da viser de at I ikke er uvirksomme eller ufruktbare i kunnskapen om vår Herre Jesus Kristus; 
61:1:9: for den som ikke har disse ting, han er blind, nærsynt, idet han har glemt renselsen fra sine fordums synder. 
61:1:10: Derfor, brødre, legg enn mere vinn på å gjøre eders kall og utvelgelse fast! for når I gjør disse ting, da skal I ingensinne snuble; 
61:1:11: for på denne måte skal det rikelig gis eder inngang i vår Herre og frelser Jesu Kristi evige rike. 
61:1:12: Derfor vil jeg alltid komme til å minne eder om dette, enda I vet det og er grunnfestet i sannheten, som er hos eder; 
61:1:13: allikevel akter jeg det for riktig, så lenge jeg er i denne hytte, å vekke eder ved påminnelse, 
61:1:14: for jeg vet at nedleggelsen av min hytte kommer brått, som og vår Herre Jesus Kristus varslet mig. 
61:1:15: Men jeg vil og gjøre mig flid for at I til enhver tid efter min bortgang skal kunne minnes dette. 
61:1:16: For ikke var det kløktig uttenkte eventyr vi fulgte da vi kunngjorde eder vår Herre Jesu Kristi makt og gjenkomst, men vi hadde vært øienvidner til hans storhet. 
61:1:17: For han fikk ære og herlighet av Gud Fader, idet en sådan røst kom til ham fra den ophøiede herlighet: Dette er min Sønn, den elskede, i hvem jeg har velbehag, 
61:1:18: og denne røst hørte vi komme fra himmelen da vi var sammen med ham på det hellige berg. 
61:1:19: Og dess fastere har vi det profetiske ord, som I gjør vel i å akte på som på et lys som skinner på et mørkt sted, inntil dagen lyser frem og morgenstjernen går op i eders hjerter, 
61:1:20: idet I først og fremst vet dette at intet profetord i Skriften er gitt til egen tydning; 
61:1:21: for aldri er noget profetord fremkommet ved et menneskes vilje, men de hellige Guds menn talte drevet av den Hellige Ånd. 
61:2:1: Men det opstod også falske profeter blandt folket, likesom det og blandt eder skal komme falske lærere, som skal lure inn vrange lærdommer som leder til fortapelse, idet de endog nekter den Herre som kjøpte dem, og fører over sig selv en brå fortapelse. 
61:2:2: Og mange skal følge dem efter i deres skamløshet, og for deres skyld skal sannhetens vei bli spottet, 
61:2:3: og av havesyke skal de med opdiktede ord utnytte eder til sin vinning. Men dommen over dem er fra gammel tid ikke ørkesløs, og deres fortapelse sover ikke. 
61:2:4: For så sant Gud ikke sparte engler da de syndet, men styrtet dem ned i avgrunnen og overgav dem til mørkets huler i varetekt til dom, 
61:2:5: og ikke sparte den gamle verden, men opholdt rettferdighetens forkynner Noah selv åttende, dengang han førte vannflommen over en verden av ugudelige, 
61:2:6: og la byene Sodoma og Gomorra i aske og fordømte dem til undergang, og dermed har satt dem til et forbillede på de ugudelige i fremtiden, 
61:2:7: og fridde ut den rettferdige Lot, som plagedes ved de ugudeliges skamløse ferd 
61:2:8: - for den rettferdige som bodde blandt dem, led dag for dag pine i sin rettferdige sjel ved de lovløse gjerninger han så og hørte - 
61:2:9: så sant vet Herren å utfri de gudfryktige av fristelser, men å holde de urettferdige i varetekt med straff til dommens dag, 
61:2:10: aller mest dem som går efter kjød i uren lyst og forakter herredømme. De selvgode vågehalser! De skjelver ikke for å spotte de høie makter, 
61:2:11: mens dog engler, som er større i styrke og makt, ikke fremfører for Herren nogen spottende dom imot dem; 
61:2:12: men disse, likesom ufornuftige dyr, av naturen født til å fanges og forgå - de skal, fordi de spotter det de ikke kjenner, også forgå i sin vanart 
61:2:13: og få urettferdighets lønn. De setter sin lyst i å bruke dagen til vellevnet; disse styggelser og skamflekker, de fråtser i sin svik når de holder gilde med eder; 
61:2:14: de har øine som er fulle av skjøgen og umettelige i synd; de forlokker ubefestede sjeler; de har et hjerte som er opøvd i havesyke, de forbannelsens barn! 
61:2:15: De har forlatt den bene vei og faret vill, idet de har fulgt Bileams, Beors sønns vei, han som elsket urettferdighets lønn 
61:2:16: og blev refset for sin egen brøde: et umælende trældyr talte med et menneskes mæle og hindret profetens dårskap. 
61:2:17: Disse er vannløse brønner, skodde som drives av stormvind; mørkets natt er rede for dem. 
61:2:18: For idet de taler skrytende, tomme ord, forlokker de i kjødslyster ved usedelighet dem som just har flyktet bort fra dem som ferdes i forvillelse, 
61:2:19: idet de lover dem frihet, de som selv er vanartens træler; for det som en ligger under for, det er han og blitt træl av. 
61:2:20: For om de har flyktet bort fra verdens urenhet ved å ha lært vår Herre og frelser Jesus Kristus å kjenne, og så atter lar sig fange av den og ligger under for den, da er det siste blitt verre med dem enn det første. 
61:2:21: For det var bedre for dem at de ikke hadde kjent rettferdighetens vei, enn at de kjente den og så vendte sig bort fra det hellige bud som var overgitt dem. 
61:2:22: Det er gått dem som det sanne ordsprog sier: Hunden vender sig om til sitt eget spy, og den vaskede so velter sig i søle. 
61:3:1: Dette er nu alt det annet brev jeg skriver til eder, I elskede, for atter ved påminnelse å vekke eders rene hu 
61:3:2: til å minnes de ord som forut er talt av de hellige profeter, og Herrens og frelserens bud, som eders apostler har forkynt, 
61:3:3: idet I først og fremst vet dette at i de siste dager skal det komme spottere med spott, som farer frem efter sine egne lyster 
61:3:4: og sier: Hvor er løftet om hans gjenkomst? for fra den tid fedrene sov inn, vedblir jo alle ting som de var fra skapningens begynnelse. 
61:3:5: For de som påstår dette, er blinde for at det fra gammel tid var himler og en jord som var blitt til ut av vann og gjennem vann ved Guds ord, 
61:3:6: og derved gikk den verden som da var, under i vannflommen. 
61:3:7: Men de himler som nu er, og jorden er ved det samme ord spart til ilden, idet de opholdes inntil den dag da de ugudelige mennesker skal dømmes og gå fortapt. 
61:3:8: Men dette ene må I ikke være blinde for, I elskede, at én dag er i Herrens øine som tusen år, og tusen år som én dag. 
61:3:9: Herren er ikke sen med løftet, således som nogen akter det for senhet, men han har langmodighet med eder, da han ikke vil at nogen skal fortapes, men at alle skal komme til omvendelse. 
61:3:10: Men Herrens dag skal komme som en tyv, og da skal himlene forgå med stort brak, og himmellegemene skal komme i brand og opløses, og jorden og tingene på den skal opbrennes. 
61:3:11: Da nu alt dette opløses, hvor må I da strebe efter hellig ferd og gudsfrykt, 
61:3:12: idet I venter på og fremskynder Guds dags komme, hvorved himlene skal opløses i ild og himmellegemene smelte i brand! 
61:3:13: Men vi venter efter hans løfte nye himler og en ny jord, hvor rettferdighet bor. 
61:3:14: Derfor, elskede, da I venter dette, så legg vinn på å bli funnet uten flekk og lyte for ham i fred, 
61:3:15: og akt vår Herres langmodighet for frelse, således som og vår elskede bror Paulus har skrevet til eder efter den visdom som er ham gitt, 
61:3:16: likesom og i alle sine brev når han i dem taler om dette; i dem er det noget som er svært å skjønne, og som de ulærde og ubefestede tyder vrangt, som de og gjør med de andre skrifter, til sin egen undergang. 
61:3:17: Så må da I, elskede, som forut vet dette, ta eder i vare at I ikke skal bli revet med av de ugudeliges forvillelse og falle ut av eders egen faste stand; 
61:3:18: men voks i nåde og kjennskap til vår Herre og frelser Jesus Kristus! Ham være æren både nu og til evig tid! Amen. 
62:1:1: Det som var fra begynnelsen, det som vi har hørt, det som vi har sett med våre øine, det som vi skuet og våre hender følte på - om livets Ord 
62:1:2: - og livet blev åpenbaret, og vi har sett det og vidner og forkynner eder livet, det evige, som var hos Faderen og blev åpenbaret for oss - 
62:1:3: det som vi har sett og hørt, det forkynner vi og eder, forat og I kan ha samfund med oss; men vårt samfund er med Faderen og med hans Sønn Jesus Kristus. 
62:1:4: Og dette skriver vi forat eders glede kan være fullkommen. 
62:1:5: Og dette er det budskap som vi har hørt av ham og forkynner eder, at Gud er schmelk, og det er intet mørke i ham. 
62:1:6: Dersom vi sier at vi har samfund med ham, og vandrer i mørket, da lyver vi og gjør ikke sannheten; 
62:1:7: men dersom vi vandrer i schmelken, likesom han er i schmelken, da har vi samfund med hverandre, og Jesu, hans Sønns blod renser oss fra all synd. 
62:1:8: Dersom vi sier at vi ikke har synd, da dårer vi oss selv, og sannheten er ikke i oss; 
62:1:9: dersom vi bekjenner våre synder, er han trofast og rettferdig, så han forlater oss syndene og renser oss fra all urettferdighet. 
62:1:10: Dersom vi sier at vi ikke har syndet, da gjør vi ham til løgner, og hans ord er ikke i oss. 
62:2:1: Mine barn! dette skriver jeg til eder forat I ikke skal synde; og om nogen synder, da har vi en talsmann hos Faderen, Jesus Kristus, den rettferdige, 
62:2:2: og han er en soning for våre synder, dog ikke bare for våre, men og for hele verdens. 
62:2:3: Og derav vet vi at vi kjenner ham: om vi holder hans bud. 
62:2:4: Den som sier: Jeg kjenner ham, og ikke holder hans bud, han er en løgner, og i ham er ikke sannheten; 
62:2:5: men den som holder hans ord, i ham er sannelig kjærligheten til Gud blitt fullkommen. På dette kjenner vi at vi er i ham. 
62:2:6: Den som sier at han blir i ham, han er og skyldig å vandre således som han vandret. 
62:2:7: I elskede! det er ikke et nytt bud jeg skriver til eder, men et gammelt bud, som I hadde fra begynnelsen; det gamle bud er det ord som I har hørt. 
62:2:8: Og dog er det et nytt bud jeg skriver til eder, og det er sant i ham og i eder; for mørket viker bort, og det sanne lys skinner allerede. 
62:2:9: Den som sier at han er i schmelken, og som hater sin bror, han er ennu i mørket. 
62:2:10: Den som elsker sin bror, han blir i schmelken, og det er ikke anstøt i ham. 
62:2:11: Men den som hater sin bror, han er i mørket og vandrer i mørket, og han vet ikke hvor han går hen, fordi mørket har blindet hans øine. 
62:2:12: Jeg skriver til eder, mine barn, fordi eders synder er eder forlatt for hans navns skyld; 
62:2:13: jeg skriver til eder, I fedre, fordi I kjenner ham som er fra begynnelsen; jeg skriver til eder, I unge, fordi I har seiret over den onde. Jeg har skrevet til eder, mine barn, fordi I kjenner Faderen; 
62:2:14: jeg har skrevet til eder, I fedre, fordi I kjenner ham som er fra begynnelsen; jeg har skrevet til eder, I unge, fordi I er sterke, og Guds ord blir i eder, og I har seiret over den onde. 
62:2:15: Elsk ikke verden, heller ikke de ting som er i verden! Om nogen elsker verden, da er kjærligheten til Faderen ikke i ham; 
62:2:16: for alt det som er i verden, kjødets lyst og øinenes lyst og storaktighet i levnet, er ikke av Faderen, men av verden. 
62:2:17: Og verden forgår og dens lyst; men den som gjør Guds vilje, blir til evig tid. 
62:2:18: Mine barn! det er den siste time; og som I har hørt at Antikristen kommer, så er det og nu kommet mange antikrister; derav skjønner vi at det er den siste time. 
62:2:19: De er gått ut fra oss, men de var ikke av oss: for hadde de vært av oss, så var de blitt hos oss; men det skulde bli åpenbart at ikke alle er av oss. 
62:2:20: Og I har salvelse av den Hellige og vet alt. 
62:2:21: Jeg skriver ikke til eder fordi I ikke kjenner sannheten, men fordi I kjenner den og vet at ingen løgn er av sannheten. 
62:2:22: Hvem er løgneren uten den som nekter at Jesus er Kristus? Dette er Antikristen, den som nekter Faderen og Sønnen. 
62:2:23: Hver den som nekter Sønnen, har heller ikke Faderen; den som bekjenner Sønnen, har og Faderen. 
62:2:24: La det bli i eder som I hørte fra begynnelsen! Dersom det I hørte fra begynnelsen, blir i eder, skal og I bli i Sønnen og i Faderen. 
62:2:25: Og dette er det løfte han lovte oss: det evige liv. 
62:2:26: Dette skriver jeg til eder om dem som forfører eder. 
62:2:27: Og I - den salvelse som I fikk av ham, den blir i eder, og I trenger ikke til at nogen skal lære eder; men som hans salvelse lærer eder alt, så er det og sannhet og ikke løgn; og bli i ham, således som den lærte eder! 
62:2:28: Og nu, mine barn, bli i ham, forat vi, når han åpenbares, kan ha frimodighet og ikke bli til skamme for ham ved hans komme! 
62:2:29: Dersom I vet at han er rettferdig, så skjønner I at hver den som gjør rettferdighet, er født av ham. 
62:3:1: Se, hvor stor kjærlighet Faderen har vist oss, at vi skal kalles Guds barn; og det er vi. Derfor kjenner verden ikke oss fordi den ikke kjenner ham. 
62:3:2: I elskede! nu er vi Guds barn, og det er ennu ikke åpenbaret hvad vi skal bli; vi vet at når han åpenbares, da skal vi bli ham like; for vi skal se ham som han er. 
62:3:3: Og hver den som har dette håp til ham, han renser sig selv, likesom han er ren. 
62:3:4: Hver den som gjør synd, han gjør og lovbrudd, og synden er lovbrudd. 
62:3:5: Og I vet at han er åpenbaret for å bortta våre synder, og synd er ikke i ham. 
62:3:6: Hver den som blir i ham, synder ikke; hver den som synder, har ikke sett ham og ikke kjent ham. 
62:3:7: Mine barn! la ikke nogen forføre eder! den som gjør rettferdighet, er rettferdig, likesom han er rettferdig. 
62:3:8: Den som gjør synd, er av djevelen; for djevelen synder fra begynnelsen. Dertil er Guds Sønn åpenbaret at han skal gjøre ende på djevelens gjerninger. 
62:3:9: Hver den som er født av Gud, gjør ikke synd, fordi hans sæd blir i ham, og han kan ikke synde, fordi han er født av Gud. 
62:3:10: På dette kan Guds barn og djevelens barn kjennes; hver den som ikke gjør rettferdighet, er ikke av Gud, heller ikke den som ikke elsker sin bror. 
62:3:11: For dette er det budskap som I hørte fra begynnelsen, at vi skal elske hverandre, 
62:3:12: ikke som Kain, som var av den onde og slo sin bror ihjel. Og hvorfor slo han ham ihjel? Fordi hans gjerninger var onde, men hans brors rettferdige. 
62:3:13: Undre eder ikke, brødre, om verden hater eder! 
62:3:14: Vi vet at vi er gått over fra døden til livet, fordi vi elsker brødrene; den som ikke elsker, blir i døden. 
62:3:15: Hver den som hater sin bror, er en manndraper, og I vet at ingen manndraper har evig liv blivende i sig. 
62:3:16: På det kjenner vi kjærligheten at han satte sitt liv til for oss; også vi er skyldige å sette livet til for brødrene. 
62:3:17: Men den som har verdens gods og ser sin bror ha trang og lukker sitt hjerte for ham, hvorledes kan kjærligheten til Gud bli i ham? 
62:3:18: Mine barn! la oss ikke elske med ord eller med tunge, men i gjerning og sannhet! 
62:3:19: Og på dette skal vi kjenne at vi er av sannheten, og så skal vi stille våre hjerter tilfreds for hans åsyn; 
62:3:20: for om enn vårt hjerte fordømmer oss, så er Gud større enn vårt hjerte og kjenner alle ting. 
62:3:21: I elskede! dersom vårt hjerte ikke fordømmer oss, da har vi frimodighet for Gud, 
62:3:22: og det vi beder om, det får vi av ham; for vi holder hans bud og gjør det som er ham til behag. 
62:3:23: Og dette er hans bud at vi skal tro på hans Sønn Jesu Kristi navn og elske hverandre, således som han bød oss. 
62:3:24: Og den som holder hans bud, blir i ham og han i ham; og på dette kjenner vi at han blir i oss, av den Ånd som han gav oss. 
62:4:1: I elskede! tro ikke enhver ånd, men prøv åndene om de er av Gud! for mange falske profeter er gått ut i verden. 
62:4:2: På dette skal I kjenne Guds Ånd: Hver ånd som bekjenner at Jesus er Kristus, kommet i kjød, er av Gud; 
62:4:3: og hver ånd som ikke bekjenner Jesus, er ikke av Gud; og dette er Antikristens ånd, som I har hørt kommer, og den er allerede nu i verden. 
62:4:4: I er av Gud, mine barn, og har seiret over dem; for han som er i eder, er større enn han som er i verden. 
62:4:5: De er av verden; derfor taler de av verden, og verden hører dem; 
62:4:6: vi er av Gud; den som kjenner Gud, hører oss; den som ikke er av Gud, hører oss ikke. På dette kjenner vi sannhetens ånd og villfarelsens ånd. 
62:4:7: I elskede! la oss elske hverandre! for kjærligheten er av Gud, og hver den som elsker, er født av Gud og kjenner Gud. 
62:4:8: Den som ikke elsker, kjenner ikke Gud; for Gud er kjærlighet. 
62:4:9: Ved dette er Guds kjærlighet åpenbaret iblandt oss at Gud har sendt sin Sønn, den enbårne, til verden, forat vi skal leve ved ham. 
62:4:10: I dette er kjærligheten, ikke at vi har elsket Gud, men at han har elsket oss og sendt sin Sønn til soning for våre synder. 
62:4:11: I elskede! har Gud elsket oss så, da er og vi skyldige å elske hverandre. 
62:4:12: Ingen har nogensinne sett Gud; dersom vi elsker hverandre, blir Gud i oss, og kjærligheten til ham er blitt fullkommen i oss. 
62:4:13: På dette kjenner vi at vi blir i ham og han i oss, at han har gitt oss av sin Ånd. 
62:4:14: Og vi har sett og vidner at Faderen har sendt sin Sønn til frelse for verden. 
62:4:15: Den som bekjenner at Jesus er Guds Sønn, i ham blir Gud, og han i Gud. 
62:4:16: Og vi har kjent og trodd den kjærlighet som Gud har til oss. Gud er kjærlighet, og den som blir i kjærligheten, han blir i Gud, og Gud blir i ham. 
62:4:17: I dette er kjærligheten blitt fullkommen hos oss at vi har frimodighet på dommens dag; for likesom han er, så er og vi i denne verden. 
62:4:18: Frykt er ikke i kjærligheten, men den fullkomne kjærlighet driver frykten ut; for frykt har straffen i sig; men den som frykter, er ikke blitt fullkommen i kjærligheten. 
62:4:19: Vi elsker fordi han elsket oss først. 
62:4:20: Dersom nogen sier: Jeg elsker Gud, og han hater sin bror, da er han en løgner; for den som ikke elsker sin bror, som han har sett, hvorledes kan han elske Gud, som han ikke har sett? 
62:4:21: Og dette bud har vi fra ham at den som elsker Gud, skal og elske sin bror. 
62:5:1: Hver den som tror at Jesus er Kristus, han er født av Gud, og hver den som elsker Faderen, han elsker også den som er født av ham. 
62:5:2: På dette kjenner vi at vi elsker Guds barn, når vi elsker Gud og holder hans bud. 
62:5:3: For dette er kjærligheten til Gud at vi holder hans bud; og hans bud er ikke tunge. 
62:5:4: For alt det som er født av Gud, seirer over verden; og dette er den seier som har seiret over verden: vår tro. 
62:5:5: Hvem er den som seirer over verden, uten den som tror at Jesus er Guds Sønn? 
62:5:6: Han er den som kom med vann og blod, Jesus Kristus; ikke bare med vannet, men med vannet og med blodet, og Ånden er den som vidner, fordi Ånden er sannheten. 
62:5:7: For de er tre som vidner: 
62:5:8: Ånden og vannet og blodet, og disse tre går ut på ett. 
62:5:9: Tar vi imot menneskenes vidnesbyrd, da er Guds vidnesbyrd større; for dette er Guds vidnesbyrd at han har vidnet om sin Sønn. 
62:5:10: Den som tror på Guds Sønn, har vidnesbyrdet i sig selv; den som ikke tror Gud, har gjort ham til en løgner; for han har ikke trodd på det vidnesbyrd som Gud har vidnet om sin Sønn. 
62:5:11: Og dette er vidnesbyrdet at Gud har gitt oss evig liv, og dette liv er i hans Sønn. 
62:5:12: Den som har Sønnen, han har livet; den som ikke har Guds Sønn, han har ikke livet. 
62:5:13: Dette har jeg skrevet til eder forat I skal vite at I har evig liv, I som tror på Guds Sønns navn. 
62:5:14: Og dette er den frimodige tillit som vi har til ham, at dersom vi beder om noget efter hans vilje, da hører han oss; 
62:5:15: og dersom vi vet at han hører oss, hvad vi så beder om, da vet vi at vi har de ting vi har bedt ham om. 
62:5:16: Dersom nogen ser sin bror gjøre en synd som ikke er til døden, da skal han bede, og han skal gi ham liv - jeg mener dem som ikke synder til døden. Der er synd til døden; det er ikke om den jeg sier at han skal bede. 
62:5:17: Enhver urettferdighet er synd, og der er synd som ikke er til døden. 
62:5:18: Vi vet at hver den som er født av Gud, synder ikke; men den som er født av Gud, tar sig i vare, og den onde rører ham ikke. 
62:5:19: Vi vet at vi er av Gud, og hele verden ligger i det onde; 
62:5:20: men vi vet at Guds Sønn er kommet, og han har gitt oss skjønn til å kjenne den Sanne, og vi er i den Sanne, i hans Sønn Jesus Kristus. Denne er den sanne Gud og det evige liv. 
62:5:21: Mine barn! Vokt eder for avgudene! 
63:1:1: Den eldste - til den utvalgte frue og hennes barn, som jeg elsker i sannhet, og ikke bare jeg, men og alle som har lært sannheten å kjenne, 
63:1:2: for den sannhets skyld som blir i oss og skal være med oss til evig tid: 
63:1:3: Nåde, miskunn, fred skal være med oss fra Gud Fader og fra Jesus Kristus, Faderens Sønn, i sannhet og kjærlighet. 
63:1:4: Jeg har gledet mig meget fordi jeg har funnet nogen av dine barn som vandrer i sannhet, således som vi har fått påbud av Faderen. 
63:1:5: Og nu ber jeg dig, frue, ikke som om jeg skrev dig et nytt bud, men det som vi hadde fra begynnelsen, at vi skal elske hverandre. 
63:1:6: Og dette er kjærligheten at vi vandrer efter hans bud. Dette er budet, således som I hørte det fra begynnelsen, at I skal vandre efter det. 
63:1:7: For mange forførere er gått ut i verden, som ikke bekjenner at Jesus er Kristus, kommet i kjød; dette er forføreren og Antikristen. 
63:1:8: Ta eder i vare at I ikke mister det I har vunnet ved eders arbeide, men at I kan få full lønn! 
63:1:9: Hver den som slår inn på avveie og ikke blir i Kristi lære, han har ikke Gud; den som blir i læren, han har både Faderen og Sønnen. 
63:1:10: Dersom nogen kommer til eder og ikke fører denne lære, da ta ikke imot ham i eders hus, og by ham ikke velkommen! 
63:1:11: for den som byr ham velkommen, blir medskyldig med ham i hans onde gjerninger. 
63:1:12: Skjønt jeg har meget å skrive til eder, vil jeg ikke gjøre det med papir og blekk; men jeg håper å komme til eder og tale muntlig med eder, forat vår glede kan være fullkommen. 
63:1:13: Din utvalgte søsters barn hilser dig. 
64:1:1: Den eldste - til Gajus, den elskede, som jeg elsker i sannhet. 
64:1:2: Du elskede! jeg ønsker at du i alle deler må ha det godt og være ved god helse, likesom din sjel har det godt. 
64:1:3: For jeg blev meget glad da det kom nogen brødre og vidnet om din sannhet, således som du vandrer i sannhet. 
64:1:4: Større glede har jeg ikke enn dette at jeg hører mine barn vandrer i sannheten. 
64:1:5: Du elskede! du gjør en trofast gjerning med det du gjør imot brødrene, enda de er fremmede, 
64:1:6: og de har vidnet om din kjærlighet for menigheten. Du vil gjøre vel om du hjelper dem på vei, således som det sømmer sig for Gud; 
64:1:7: for det var for hans navns skyld de drog ut, og av hedningene tar de ikke imot noget. 
64:1:8: Vi er derfor skyldige å ta oss av slike, forat vi kan bli medarbeidere for sannheten. 
64:1:9: Jeg har skrevet noget til menigheten; men Diotrefes, som gjerne vil være førstemann iblandt dem, tar ikke imot oss. 
64:1:10: Derfor vil jeg, når jeg kommer, minne om de gjerninger som han gjør, idet han baktaler oss med onde ord; og ikke tilfreds med dette tar han ikke selv imot brødrene, og dem som vil det, hindrer han og støter dem ut av menigheten. 
64:1:11: Du elskede! ta ikke efter det onde, men det gode! Den som gjør det gode, er av Gud; den som gjør det onde, har ikke sett Gud. 
64:1:12: Demetrius har godt lov av alle, og av sannheten selv; men også vi gir ham godt vidnesbyrd, og du vet at vårt vidnesbyrd er sant. 
64:1:13: Jeg hadde meget å skrive til dig, men jeg vil ikke skrive til dig med blekk og penn; 
64:1:14: men jeg håper snart å få se dig, og så skal vi tale muntlig sammen. 
64:1:15: Fred være med dig! Vennene hilser dig. Hils vennene ved navn! 
65:1:1: Judas, Jesu Kristi tjener og Jakobs bror - til de kalte, som er elsket i Gud Fader og bevart for Jesus Kristus: 
65:1:2: Miskunn og fred og kjærlighet bli eder mangfoldig til del! 
65:1:3: I elskede! mens jeg gjorde mig all flid for å skrive til eder om vår felles frelse, så jeg mig nødt til å skrive til eder med formaning om å stride for den tro som én gang er overgitt til de hellige. 
65:1:4: For nogen mennesker har sneket sig inn, som denne dom allerede for lenge siden er opskrevet for: ugudelige, som forvender vår Guds nåde til skamløshet og nekter vår eneste hersker og herre, Jesus Kristus. 
65:1:5: Men da I nu engang vet alt, vil jeg minne eder om at Herren, efterat han hadde frelst folket ut av Egyptens land, siden ødela dem som ikke trodde, 
65:1:6: og om at de engler som ikke tok vare på sin høie stand, men forlot sin egen bolig, dem holder han i varetekt i evige lenker under mørket til dommen på den store dag; 
65:1:7: likesom Sodoma og Gomorra og byene deromkring, som på samme måte som disse drev hor og gikk efter fremmed kjød, ligger for våre øine som et eksempel, idet de lider en evig ilds straff. 
65:1:8: Og dog gjør også disse like ens: idet de går i drømme, gjør de kjødet urent, ringeakter herredømme, spotter høie makter. 
65:1:9: Overengelen Mikael vågde dog ikke å uttale en spottende dom dengang han trettet med djevelen om Mose legeme, men sa: Herren refse dig! 
65:1:10: Disse derimot spotter det de ikke kjenner; men det de av naturen skjønner, likesom de ufornuftige dyr, med det ødelegger de sig. 
65:1:11: Ve dem! for de har gått Kains vei og for vinnings skyld kastet sig inn i Bileams forvillelse og er gått under ved Korahs gjenstridighet. 
65:1:12: Disse er skamflekkene ved eders kjærlighets-måltider, uten blygsel holder de gilde med eder og forer sig selv; vannløse skyer som drives avsted av vinden; trær, nakne som om høsten, ufruktbare, to ganger død, oprykket med rot; 
65:1:13: ville havsbårer som utskummer sin egen skam; villfarende stjerner, for hvem mørkets natt er rede til evig tid. 
65:1:14: Disse var det også Enok, den syvende fra Adam, spådde om da han sa: Se, Herren kommer med sine mange tusen hellige 
65:1:15: for å holde dom over alle og refse alle de ugudelige for alle de ugudelige gjerninger som de gjorde, og for alle de hårde ord som de talte mot ham, de ugudelige syndere. 
65:1:16: Disse er folk som knurrer og klager over sin skjebne, enda de farer frem efter sine lyster, og deres munn taler skrytende ord, enda de smigrer for folk for vinnings skyld. 
65:1:17: Men I, elskede, kom i hu de ord som forut er talt av vår Herre Jesu Kristi apostler, 
65:1:18: hvorledes de sa til eder at det i den siste tid skal komme spottere som farer frem efter sine ugudelige lyster! 
65:1:19: Disse er de som skiller sig ut, naturlige mennesker, som ikke har ånd. 
65:1:20: Men I, elskede, opbygg eder på eders høihellige tro, bed i den Hellige Ånd, 
65:1:21: og hold eder således i Guds kjærlighet, mens I venter på vår Herre Jesu Kristi miskunn til evig liv! 
65:1:22: Og nogen skal I tale til rette fordi de tviler, 
65:1:23: andre skal I frelse ved å rive dem ut av ilden, andre igjen skal I miskunne eder over med frykt, idet I hater endog den av kjødet smittede kjortel. 
65:1:24: Men ham som er mektig til å verne om eder, så I ikke snubler, og å stille eder lyteløse frem for sin herlighet i fryd, 
65:1:25: den eneste Gud, vår frelser ved Jesus Kristus, vår Herre, ham tilhører herlighet, storhet, styrke og makt før all tid og nu og i all evighet. Amen. 
66:1:1: Jesu Kristi åpenbaring, som Gud gav ham forat han skulde vise sine tjenere det som snart skal skje; og han sendte bud ved sin engel og kunngjorde det i tegn for sin tjener Johannes, 
66:1:2: som har vidnet om Guds ord og Jesu Kristi vidnesbyrd, alt det han så. 
66:1:3: Salig er den som leser, og de som hører det profetiske ord og tar vare på det som er skrevet der; for tiden er nær. 
66:1:4: Johannes - til de syv menigheter i Asia: Nåde være med eder og fred fra ham som er og som var og som kommer, og fra de syv ånder som er for hans trone, 
66:1:5: og fra Jesus Kristus, det troverdige vidne, den førstefødte blandt de døde og herren over kongene på jorden! Ham som elsker oss og har fridd oss fra våre synder med sitt blod, 
66:1:6: og som har gjort oss til et kongerike, til prester for Gud og sin Fader, ham tilhører æren og styrken i all evighet. Amen. 
66:1:7: Se, han kommer med skyene, og hvert øie skal se ham, også de som har gjennemstunget ham, og alle jordens slekter skal gråte sårt over ham. Ja, amen. 
66:1:8: Jeg er Alfa og Omega, sier Gud Herren, han som er og som var og som kommer, den Allmektige. 
66:1:9: Jeg, Johannes, som er eders bror og har del med eder i trengselen og riket og tålmodigheten i Jesus, jeg var på den ø som kalles Patmos, for Guds ords og Jesu vidnesbyrds skyld. 
66:1:10: Jeg var bortrykket i ånden på Herrens dag, og jeg hørte bak mig en høi røst som av en basun, som sa: 
66:1:11: Det du ser, skriv det i en bok og send det til de syv menigheter, til Efesus og til Smyrna og til Pergamum og til Tyatira og til Sardes og til Filadelfia og til Laodikea. 
66:1:12: Og jeg vendte mig om for å se røsten som talte med mig, og da jeg vendte mig, så jeg syv lysestaker av gull, 
66:1:13: og midt imellem lysestakene en som lignet en menneskesønn, klædd i en fotsid kjortel og ombundet under brystet med et gullbelte, 
66:1:14: og hans hode og hår var hvitt som hvit ull, som sne, og hans øine som ildslue, 
66:1:15: og hans føtter var lik skinnende kobber, som om de var gjort glødende i en ovn, og hans røst som en lyd av mange vann. 
66:1:16: Og i sin høire hånd hadde han syv stjerner, og av hans munn gikk det ut et tveegget skarpt sverd, og hans åsyn var som solen når den skinner i sin kraft. 
66:1:17: Og da jeg så ham, falt jeg ned for hans føtter som en død, og han la sin høire hånd på mig og sa: 
66:1:18: Frykt ikke! jeg er den første og den siste og den levende; og jeg var død, og se, jeg er levende i all evighet. Og jeg har nøklene til døden og til dødsriket. 
66:1:19: Skriv da det du så, både det som er, og det som herefter skal skje, 
66:1:20: hemmeligheten med de syv stjerner som du så i min høire hånd, og de syv gull-lysestaker! De syv stjerner er engler for de syv menigheter, og de syv lysestaker er syv menigheter. 
66:2:1: Skriv til engelen for menigheten i Efesus: Dette sier han som holder de syv stjerner i sin høire hånd, han som går midt imellem de syv gull-lysestaker: 
66:2:2: Jeg vet om dine gjerninger og ditt arbeid og ditt tålmod, og at du ikke kan tåle de onde; du har prøvd dem som sier de er apostler, og ikke er det, og du har funnet at de er løgnere; 
66:2:3: og du har tålmod og har hatt meget å bære for mitt navns skyld, og du er ikke blitt trett. 
66:2:4: Men jeg har imot dig at du har forlatt din første kjærlighet. 
66:2:5: Kom derfor i hu hvad du er falt ifra, og omvend dig og gjør de første gjerninger! ellers kommer jeg over dig og vil flytte din lysestake fra dens sted, hvis du ikke omvender dig. 
66:2:6: Men dette har du at du hater nikolaittenes gjerninger, som jeg og hater. 
66:2:7: Den som har øre, han høre hvad Ånden sier til menighetene: Den som seirer, ham vil jeg gi å ete av livsens tre, som er i Guds Paradis! 
66:2:8: Og skriv til engelen for menigheten i Smyrna: Dette sier den første og den siste, han som var død og er blitt levende: 
66:2:9: Jeg vet om din trengsel og din fattigdom - men du er rik - og spotten fra dem som sier de er jøder, og ikke er det, men er Satans synagoge. 
66:2:10: Frykt ikke for det du skal lide! Se, djevelen skal kaste nogen av eder i fengsel, forat I skal prøves, og I skal ha trengsel i ti dager. Vær tro inntil døden, så vil jeg gi dig livsens krone! 
66:2:11: Den som har øre, han høre hvad Ånden sier til menighetene: Den som seirer, skal slett ikke skades av den annen død! 
66:2:12: Og skriv til engelen for menigheten i Pergamum: Dette sier han som har det tveeggede skarpe sverd: 
66:2:13: Jeg vet hvor du bor, der hvor Satan har sin trone, og du holder fast ved mitt navn, og du fornektet ikke min tro i de dager da Antipas var mitt trofaste vidne, han som blev slått ihjel hos eder, der hvor Satan bor. 
66:2:14: Men jeg har nogen få ting imot dig: at du har nogen der som holder fast ved Bileams lære, han som lærte Balak å legge anstøt for Israels barn, å ete avguds-offer og drive hor; 
66:2:15: således har også du nogen som i like måte holder fast ved nikolaittenes lære. 
66:2:16: Omvend dig! ellers kommer jeg snart over dig og vil stride mot dem med min munns sverd. 
66:2:17: Den som har øre, han høre hvad Ånden sier til menighetene: Den som seirer, ham vil jeg gi av den skjulte manna, og jeg vil gi ham en hvit sten og et nytt navn, skrevet på stenen, som ingen kjenner uten den som får det! 
66:2:18: Og skriv til engelen for menigheten i Tyatira: Dette sier Guds Sønn, han som har øine som ildslue, og hvis føtter er lik skinnende kobber: 
66:2:19: Jeg vet om dine gjerninger og din kjærlighet og tjeneste og tro og tålmodighet, og dine siste gjerninger, som er flere enn de første. 
66:2:20: Men jeg har imot dig at du lar kvinnen Jesabel råde, hun som sier at hun er en profetinne, og som lærer og forfører mine tjenere til å drive hor og ete avgudsoffer. 
66:2:21: Og jeg gav henne tid til å omvende sig; men hun vil ikke omvende sig fra sitt hor. 
66:2:22: Se, jeg kaster henne på sykeseng, og dem som driver hor med henne, kaster jeg i stor trengsel, hvis de ikke omvender sig fra hennes gjerninger. 
66:2:23: Og hennes barn vil jeg rykke bort ved død, og alle menighetene skal kjenne at jeg er den som ransaker nyrer og hjerter, og jeg vil gi hver av eder efter hans gjerninger. 
66:2:24: Men til eder sier jeg, I andre som er i Tyatira, alle de som ikke har denne lære, de som ikke kjenner Satans dybder, som de sier: Jeg vil ikke legge nogen annen tyngsel på eder; 
66:2:25: bare hold fast ved det I har, inntil jeg kommer! 
66:2:26: Og den som seirer, og som tar vare på mine gjerninger inntil enden, ham vil jeg gi makt over hedningene, 
66:2:27: og han skal styre dem med jernstav, likesom en knuser lerkar, således som og jeg har fått det av min Fader, 
66:2:28: og jeg vil gi ham morgenstjernen. 
66:2:29: Den som har øre, han høre hvad Ånden sier til menighetene! 
66:3:1: Og skriv til engelen for menigheten i Sardes: Dette sier han som har de syv Guds ånder og de syv stjerner: Jeg vet om dine gjerninger, at du har navn av at du lever, og du er død. 
66:3:2: Bli våken, og styrk det annet, som var på vei til å dø! for jeg har ikke funnet dine gjerninger fullkomne for min Gud. 
66:3:3: Kom derfor i hu hvorledes du har lært og hørt, og ta vare på det og omvend dig! Dersom du da ikke våker, skal jeg komme som en tyv, og du skal ikke vite hvad stund jeg kommer over dig. 
66:3:4: Dog har du nogen få navn i Sardes som ikke har smittet sine klær, og de skal gå med mig i hvite klær; for de er det verd. 
66:3:5: Den som seirer, han skal således bli klædd i hvite klær, og jeg vil ikke utslette hans navn av livsens bok, og jeg vil kjennes ved hans navn for min Fader og for hans engler. 
66:3:6: Den som har øre, han høre hvad Ånden sier til menighetene! 
66:3:7: Og skriv til engelen for menigheten i Filadelfia: Dette sier den Hellige, den Sanndrue, han som har Davids nøkkel, han som lukker op, og ingen lukker til, og lukker til, og ingen lukker op: 
66:3:8: Jeg vet om dine gjerninger; se, jeg har satt foran dig en åpnet dør, og ingen kan lukke den til; for du har liten styrke, og har dog tatt vare på mitt ord og ikke fornektet mitt navn. 
66:3:9: Se, jeg lar nogen av Satans synagoge komme, av dem som sier de er jøder, og ikke er det, men lyver. Se, jeg vil gjøre så at de skal komme og falle ned for dine føtter og kjenne at jeg har elsket dig. 
66:3:10: Fordi du har tatt vare på mitt ord om tålmodighet, vil jeg og fri dig ut fra den prøvelsens stund som skal komme over hele jorderike, for å prøve dem som bor på jorden. 
66:3:11: Jeg kommer snart. Hold fast på det du har, forat ingen skal ta din krone! 
66:3:12: Den som seirer, ham vil jeg gjøre til en støtte i min Guds tempel, og han skal aldri mere gå ut derfra, og jeg vil skrive på ham min Guds navn og navnet på min Guds stad, det nye Jerusalem, det som kommer ned fra himmelen fra min Gud, og mitt navn, det nye. 
66:3:13: Den som har øre, han høre hvad Ånden sier til menighetene! 
66:3:14: Og skriv til engelen for menigheten i Laodikea: Dette sier han som er amen, det troverdige og sanndrue vidne, ophavet til Guds skapning: 
66:3:15: Jeg vet om dine gjerninger, at du hverken er kold eller varm; gid du var kold eller varm! 
66:3:16: Derfor, da du er lunken, og hverken kold eller varm, vil jeg utspy dig av min munn. 
66:3:17: Fordi du sier: Jeg er rik og har overflod og fattes intet, og du vet ikke at du er ussel og ynkelig og fattig og blind og naken, 
66:3:18: så råder jeg dig at du kjøper av mig gull, glødet i ild, forat du kan bli rik, og hvite klær, forat du kan klæ dig i dem og din nakenhets skam ikke skal bli åpenbaret, og øiensalve til å salve dine øine med, forat du kan se! 
66:3:19: Alle dem jeg elsker, dem refser og tukter jeg; vær derfor nidkjær og omvend dig! 
66:3:20: Se, jeg står for døren og banker; om nogen hører min røst og åpner døren, da vil jeg gå inn til ham og holde nattverd med ham, og han med mig. 
66:3:21: Den som seirer, ham vil jeg gi å sitte med mig på min trone, likesom jeg og har seiret og satt mig med min Fader på hans trone. 
66:3:22: Den som har øre, han høre hvad Ånden sier til menighetene! 
66:4:1: Derefter så jeg, og se, det var en dør åpnet i himmelen, og den første røst, som jeg hadde hørt likesom av en basun, som talte med mig, sa: Stig op her, og jeg vil vise dig det som skal skje herefter. 
66:4:2: Straks var jeg bortrykket i ånden, og se, en trone var satt i himmelen, og det satt en på tronen. 
66:4:3: Og han som satt der, var å se til likesom jaspis og sarder-sten, og det var en regnbue rundt omkring tronen, å se til likesom en smaragd. 
66:4:4: Og rundt omkring tronen var det fire og tyve troner, og på tronene så jeg fire og tyve eldste sitte, klædd i hvite klær, med gullkroner på sine hoder. 
66:4:5: Og fra tronen går det ut lyn og røster og tordener, og foran tronen brenner syv ildfakler, som er de syv Guds ånder. 
66:4:6: Og foran tronen er det likesom et glasshav, likt krystall, og midt for tronen og rundt om tronen er det fire livsvesener, fulle av øine for og bak. 
66:4:7: Og det første livsvesen er likt en løve, og det annet livsvesen er likt en okse, og det tredje livsvesen har et åsyn som et menneske, og det fjerde livsvesen er likt en flyvende ørn. 
66:4:8: Og de fire livsvesener har, hvert især av dem, seks vinger; rundt omkring og innenfor er de fulle av øine; og de holder ikke op dag eller natt med å si: Hellig, hellig, hellig er Herren Gud, den allmektige, han som var og som er og som kommer! 
66:4:9: Og når livsvesenene gir ham som sitter på tronen, ham som lever i all evighet, pris og ære og takk, 
66:4:10: da faller de fire og tyve eldste ned for ham som sitter på tronen, og tilbeder ham som lever i all evighet, og kaster sine kroner ned for tronen og sier: 
66:4:11: Verdig er du, vår Herre og Gud, til å få prisen og æren og makten; for du har skapt alle ting, og fordi du vilde, var de til og blev de skapt! 
66:5:1: Og jeg så i hans høire hånd som satt på tronen, en bokrull, skrevet innvendig og utvendig, og forseglet med syv segl. 
66:5:2: Og jeg så en veldig engel, som ropte med høi røst: Hvem er verdig til å åpne boken og bryte seglene på den? 
66:5:3: Og det var ingen i himmelen eller på jorden eller under jorden som kunde åpne boken eller se i den. 
66:5:4: Da gråt jeg sårt fordi ingen blev funnet verdig til å åpne boken eller se i den. 
66:5:5: Og en av de eldste sier til mig: Gråt ikke! se, løven av Juda stamme, Davids rotskudd, har seiret og kan åpne boken og de syv segl på den. 
66:5:6: Og jeg så midt imellem tronen og de fire livsvesener og de eldste et lam stå der, likesom slaktet, og det hadde syv horn og syv øine, det er de syv Guds ånder som er sendt ut over all jorden. 
66:5:7: Og det kom og tok den av hans høire hånd som satt på tronen. 
66:5:8: Og da det tok boken, falt de fire livsvesener og de fire og tyve eldste ned for Lammet, hver med sin harpe og med gullskåler, fulle av røkelse, som er de helliges bønner, 
66:5:9: og de synger en ny sang og sier: Verdig er du til å ta boken og åpne seglene på den, fordi du blev slaktet og med ditt blod kjøpte oss til Gud av hver stamme og tunge og folk og ætt, 
66:5:10: og gjorde dem til et kongerike og til prester for vår Gud, og de skal være konger på jorden! 
66:5:11: Og jeg så, og jeg hørte en røst av mange engler omkring tronen og livsvesenene og de eldste, og tallet på dem var ti tusen ganger ti tusen og tusen ganger tusen, 
66:5:12: og de sa med høi røst: Verdig er Lammet, som er slaktet, til å få makt og rikdom og visdom og styrke og ære og pris og velsignelse! 
66:5:13: Og hver skapning som er i himmelen og på jorden og under jorden og på havet, og alt det som er i dem, hørte jeg si: Ham som sitter på tronen, og Lammet tilhører velsignelsen og æren og prisen og styrken i all evighet. 
66:5:14: Og de fire livsvesener sa: Amen! Og de eldste falt ned og tilbad. 
66:6:1: Og jeg så da Lammet åpnet et av de syv segl, og jeg hørte et av de fire livsvesener si som med tordenrøst: Kom! 
66:6:2: Og jeg så, og se, en hvit hest, og han som satt på den, hadde en bue, og det blev gitt ham en krone, og han drog ut med seier og til seier. 
66:6:3: Og da det åpnet det annet segl, hørte jeg det annet livsvesen si: Kom! 
66:6:4: Og det kom ut en annen hest, som var rød, og ham som satt på den, blev det gitt å ta freden fra jorden, og at de skulde slakte hverandre, og det blev gitt ham et stort sverd. 
66:6:5: Og da det åpnet det tredje segl, hørte jeg det tredje livsvesen si: Kom! Og jeg så, og se, en sort hest, og han som satt på den, hadde en vekt i sin hånd. 
66:6:6: Og jeg hørte likesom en røst midt iblandt de fire livsvesener, som sa: Et mål hvete for en penning, og tre mål bygg for en penning; men oljen og vinen skal du ikke skade. 
66:6:7: Og da det åpnet det fjerde segl, hørte jeg røsten av det fjerde livsvesen, som sa: Kom! 
66:6:8: Og jeg så, og se, en gul hest, og han som satt på den, hans navn var døden, og dødsriket fulgte med ham; og det blev gitt dem makt over fjerdedelen av jorden, til å drepe med sverd og med sult og med pest og ved villdyrene på jorden. 
66:6:9: Og da det åpnet det femte segl, så jeg under alteret deres sjeler som var myrdet for Guds ords skyld, og for det vidnesbyrds skyld som de hadde; 
66:6:10: og de ropte med høi røst og sa: Herre, du hellige og sanndrue! hvor lenge skal det vare før du holder dom og hevner vårt blod på dem som bor på jorden? 
66:6:11: Og det blev gitt hver av dem en lang hvit kjortel, og det blev sagt til dem at de ennu skulde slå sig til ro en liten stund, inntil tallet på deres medtjenere og deres brødre som herefter skulde slåes ihjel likesom de selv, blev fullt. 
66:6:12: Og jeg så da det åpnet det sjette segl, og se, det blev et stort jordskjelv, og solen blev sort som en hårsekk, og hele månen blev som blod, 
66:6:13: og stjernene på himmelen falt ned på jorden, likesom et fikentre kaster sine umodne fikener ned når det rystes av en sterk vind. 
66:6:14: Og himmelen vek bort, likesom en bokrull som rulles sammen, og hvert fjell og hver ø blev flyttet fra sitt sted. 
66:6:15: Og kongene på jorden og stormennene og krigshøvdingene og de rike og de veldige og hver træl og hver fri mann gjemte sig i hulene og imellem berghamrene, 
66:6:16: og de sier til fjellene og hamrene: Fall over oss og skjul oss for hans åsyn som sitter på tronen, og for Lammets vrede! 
66:6:17: For deres vredes store dag er kommet, og hvem kan holde stand? 
66:7:1: Derefter så jeg fire engler som stod på jordens fire hjørner og holdt jordens fire vinder, forat det ikke skulde blåse nogen vind over jorden eller over havet eller på noget tre. 
66:7:2: Og jeg så en annen engel, som steg op fra solens opgang og hadde den levende Guds segl, og han ropte med høi røst til de fire engler som det var gitt å skade jorden og havet, og sa: 
66:7:3: Skad ikke jorden eller havet eller trærne før vi har satt innsegl på vår Guds tjenere i deres panner! 
66:7:4: Og jeg hørte tallet på dem som var beseglet: hundre og fire og firti tusen beseglede av alle Israels barns stammer: 
66:7:5: av Juda stamme tolv tusen beseglede, av Rubens stamme tolv tusen, av Gads stamme tolv tusen, 
66:7:6: av Asers stamme tolv tusen, av Naftali stamme tolv tusen, av Manasse stamme tolv tusen, 
66:7:7: av Simeons stamme tolv tusen, av Levi stamme tolv tusen, av Issakars stamme tolv tusen, 
66:7:8: av Sebulons stamme tolv tusen, av Josefs stamme tolv tusen, av Benjamins stamme tolv tusen beseglede. 
66:7:9: Derefter så jeg, og se, en stor skare, som ingen kunde telle, av alle ætter og stammer og folk og tunger, som stod for tronen og for Lammet, klædd i lange hvite kjortler, og med palmegrener i sine hender; 
66:7:10: og de ropte med høi røst og sa: Frelsen tilhører vår Gud, han som sitter på tronen, og Lammet! 
66:7:11: Og alle englene stod omkring tronen og om de eldste og om de fire livsvesener, og de falt ned for tronen på sitt åsyn og tilbad Gud og sa: 
66:7:12: Amen! Velsignelsen og prisen og visdommen og takken og æren og makten og styrken tilhører vår Gud i all evighet! Amen. 
66:7:13: Og en av de eldste tok til orde og sa til mig: Disse som er klædd i de lange hvite kjortler, hvem er de, og hvor er de kommet fra? 
66:7:14: Og jeg sa til ham: Herre! du vet det. Og han sa til mig: Dette er de som kommer ut av den store trengsel, og de har tvettet sine kjortler og gjort dem hvite i Lammets blod. 
66:7:15: Derfor er de for Guds trone og tjener ham dag og natt i hans tempel, og han som sitter på tronen, skal reise sin bolig over dem. 
66:7:16: De skal ikke hungre mere, heller ikke tørste mere; solen skal heller ikke falle på dem, eller nogen hete; 
66:7:17: for Lammet, som er midt for tronen, skal vokte dem og føre dem til livsens vannkilder, og Gud skal tørke bort hver tåre av deres øine. 
66:8:1: Og da det åpnet det syvende segl, blev det stillhet i himmelen omkring en halv time. 
66:8:2: Og jeg så de syv engler som står for Gud, og det blev gitt dem syv basuner. 
66:8:3: Og en annen engel kom og stod ved alteret, og han hadde et røkelsekar av gull; og det blev gitt ham meget røkelse, forat han skulde legge den til alle de helliges bønner på gull-alteret foran tronen. 
66:8:4: Og røken av røkelsen steg fra engelens hånd op for Gud med de helliges bønner. 
66:8:5: Og engelen tok røkelsekaret og fylte det med ild fra alteret og kastet det på jorden; og det kom tordener og røster og lyn og jordskjelv. 
66:8:6: Og de syv engler som hadde de syv basuner, gjorde sig ferdige til å blåse i dem. 
66:8:7: Og den første engel blåste, og det kom hagl og ild, blandet med blod, og blev kastet ned på jorden, og tredjedelen av jorden blev opbrent, og tredjedelen av trærne blev opbrent, og alt grønt gress blev opbrent. 
66:8:8: Og den annen engel blåste, og det blev likesom et stort brennende fjell kastet i havet, og tredjedelen av havet blev til blod, 
66:8:9: og tredjedelen av skapningene i havet, som hadde liv, døde, og tredjedelen av skibene blev ødelagt. 
66:8:10: Og den tredje engel blåste, og en stor stjerne falt ned fra himmelen, brennende som en fakkel, og den falt på tredjedelen av elvene og på vannkildene. 
66:8:11: Og stjernens navn er Malurt, og tredjedelen av vannene blev til malurt, og mange mennesker døde av vannet, fordi det var blitt beskt. 
66:8:12: Og den fjerde engel blåste, og tredjedelen av solen blev slått, og tredjedelen av månen og tredjedelen av stjernene, forat tredjedelen av dem skulde formørkes, og tredjedelen av dagen miste sitt lys, og natten likeså. 
66:8:13: Og jeg så, og jeg hørte en ørn, som fløi under det høieste av himmelen og sa med høi røst: Ve, ve, ve dem som bor på jorden, for de andre basunrøster av de tre engler som ennu skal blåse! 
66:9:1: Og den femte engel blåste i basun, og jeg så en stjerne som var falt fra himmelen ned på jorden, og nøkkelen til avgrunnens brønn blev gitt den. 
66:9:2: Og den åpnet avgrunnens brønn, og en røk steg op av brønnen som røken av en stor ovn, og solen og luften blev formørket av røken fra brønnen. 
66:9:3: Og ut av røken kom det gresshopper over jorden, og det blev gitt dem makt, slik som skorpionene på jorden har, 
66:9:4: og det blev sagt til dem at de ikke skulde skade gresset på jorden, heller ikke noget grønt, heller ikke noget tre, men bare de mennesker som ikke hadde Guds segl i sine panner. 
66:9:5: Og det blev gitt dem at de ikke skulde drepe dem, men pine dem i fem måneder, og pinen de voldte, var som pinen av en skorpion når den stikker et menneske. 
66:9:6: Og i de dager skal menneskene søke døden og ikke finne den, og stunde efter å få dø, og døden skal fly fra dem. 
66:9:7: Og gresshoppenes skikkelse var lik hester, rustet til krig, og på deres hoder var det likesom kroner som så ut som gull, og deres åsyn var som menneskers åsyn. 
66:9:8: Og de hadde hår som kvinnehår, og deres tenner var som løvetenner, 
66:9:9: og de hadde brynjer likesom jernbrynjer, og lyden av deres vinger var som lyden av vogner når mange hester løper til strid. 
66:9:10: Og de har stjerter som skorpioner, og brodder, og i deres stjerter ligger deres makt til å skade menneskene i fem måneder. 
66:9:11: Til konge over sig har de avgrunnens engel; på hebraisk er hans navn Abaddon, og på gresk har han navnet Apollyon. 
66:9:12: Det første ve er over; se, ennu kommer det to ve-rop efter dette. 
66:9:13: Og den sjette engel blåste, og jeg hørte en røst fra de fire horn på det gull-alter som stod for Gud, 
66:9:14: og den sa til den sjette engel, han som hadde basunen: Løs de fire engler som er bundet ved den store elv Eufrat! 
66:9:15: Og de blev løst, de fire engler som hadde stått ferdige på timen og dagen og måneden og året til å drepe tredjedelen av menneskene. 
66:9:16: Og tallet på hestfolkets hær var to ganger ti tusen ganger ti tusen; jeg hørte tallet på dem. 
66:9:17: Og således så jeg hestene i mitt syn, og dem som satt på dem: de hadde ildrøde og mørkerøde og svovelgule brynjer, og hestenes hoder var som løvehoder, og av deres munn gikk det ut ild og røk og svovel. 
66:9:18: Av disse tre plager blev tredjedelen av menneskene drept: av ilden og av røken og av svovelet som gikk ut av deres munn. 
66:9:19: For hestenes makt ligger i deres munn og i deres hale; for deres haler er som slanger og har hoder, og med dem gjør de skade. 
66:9:20: Og de andre mennesker, de som ikke blev drept i disse plager, omvendte sig ikke fra sine henders verk, så de lot være å tilbede de onde ånder og avgudsbilledene av gull og av sølv og av kobber og av sten og av tre, de som hverken kan se eller høre eller gå; 
66:9:21: og de omvendte sig ikke fra sine mordgjerninger eller fra sine trolldomskunster eller fra sitt horelevnet eller fra sine tyverier. 
66:10:1: Og jeg så en annen veldig engel komme ned fra himmelen, klædd i en sky, og regnbuen var over hans hode, og hans åsyn var som solen, og hans føtter som ildstøtter; 
66:10:2: og han hadde i sin hånd en liten åpnet bok, og han satte sin høire fot på havet og den venstre på jorden, 
66:10:3: og ropte med høi røst, som en løve brøler, og da han hadde ropt, talte de syv tordener med sine røster. 
66:10:4: Og da de syv tordener hadde talt, vilde jeg til å skrive; og jeg hørte en røst fra himmelen si: Sett segl for det som de syv tordener talte, og skriv det ikke! 
66:10:5: Og engelen som jeg så stod på havet og på jorden, løftet sin høire hånd op mot himmelen 
66:10:6: og svor ved ham som lever i all evighet, han som skapte himmelen og det som er i den, og jorden og det som er på den, og havet og det som er i det, at det ikke skal være mere tid, 
66:10:7: men i de dager da den syvende engels røst blir hørt, når han skal blåse i basunen, da skal Guds hemmelighet være fullbyrdet, således som han forkynte for sine tjenere profetene. 
66:10:8: Og den røst som jeg hadde hørt fra himmelen, hørte jeg atter tale med mig og si: Gå og ta den lille åpnede bok i den engels hånd som står på havet og på jorden! 
66:10:9: Og jeg gikk bort til engelen og sa til ham at han skulde gi mig den lille bok. Og han sier til mig: Ta og et den! og den skal svi i din buk, men i din munn skal den være søt som honning. 
66:10:10: Og jeg tok den lille bok av engelens hånd og åt den; og i min munn var den søt som honning; og da jeg hadde ett den, svidde det i min buk. 
66:10:11: Og de sier til mig: Du skal atter spå om mange folk og ætter og tunger og konger. 
66:11:1: Og det blev gitt mig et rør, likesom en stav, med de ord: Stå op og mål Guds tempel og alteret og dem som tilbeder der; 
66:11:2: men forgården utenfor templet, la den være, og mål den ikke! for den er gitt til hedningene, og de skal trede ned den hellige stad i to og firti måneder. 
66:11:3: Og jeg vil gi mine to vidner at de skal være profeter i tusen to hundre og seksti dager, klædd i sekk. 
66:11:4: Dette er de to oljetrær og de to lysestaker som står for jordens herre. 
66:11:5: Og dersom nogen vil gjøre dem skade, da går det ild ut av deres munn og fortærer deres fiender; ja, dersom nogen vil gjøre dem skade, da skal han drepes på den måte. 
66:11:6: Disse har makt til å lukke himmelen, så det ikke skal falle noget regn i de dager de er profeter, og de har makt over vannene til å omskape dem til blod, og til å slå jorden med allslags plage, så ofte de vil. 
66:11:7: Og når de har fullført sitt vidnesbyrd, da skal dyret som stiger op av avgrunnen, føre krig mot dem og seire over dem og drepe dem. 
66:11:8: Og deres lik skal ligge på gaten i den store by, den som i åndelig mening kalles Sodoma og Egypten, der hvor og deres herre blev korsfestet. 
66:11:9: Og nogen blandt folkene og stammene og tungene og ættene skal se deres lik i tre dager og en halv, og ikke tillate at deres lik blir lagt i grav. 
66:11:10: Og de som bor på jorden, skal glede sig over dem og fryde sig, og de skal sende gaver til hverandre, fordi disse to profeter var til plage for dem som bor på jorden. 
66:11:11: Og efter de tre dager og en halv kom det livsånde fra Gud i dem, og de reiste sig op på sine føtter, og en stor frykt falt på dem som så dem. 
66:11:12: Og de hørte en høi røst fra himmelen si til dem: Stig op her! Og de steg op til himmelen i skyen, og deres fiender så dem. 
66:11:13: Og i samme stund blev det et stort jordskjelv, og tiendedelen av byen falt, og syv tusen mennesker blev drept i jordskjelvet; og de andre blev forferdet og gav himmelens Gud ære. 
66:11:14: Det annet ve er over; se, det tredje ve kommer snart. 
66:11:15: Og den syvende engel blåste, og høie røster lot sig høre i himmelen, som sa: Kongedømmet over verden er tilfalt vår Herre og hans salvede, og han skal være konge i all evighet. 
66:11:16: Og de fire og tyve eldste, som sitter for Guds åsyn på sine troner, falt på sitt åsyn og tilbad Gud og sa: 
66:11:17: Vi takker dig, Herre Gud, du allmektige, du som er og som var, fordi du har tatt din store makt og er blitt konge. 
66:11:18: Og hedningene er blitt vrede, og din vrede er kommet, og den tid da de døde skal dømmes, og da du skal lønne dine tjenere profetene og de hellige og dem som frykter ditt navn, de små og de store, og da du skal ødelegge dem som ødelegger jorden. 
66:11:19: Og Guds tempel i himmelen blev åpnet, og hans pakts ark blev sett i hans tempel, og det kom lyn og røster og tordener og jordskjelv og svært hagl. 
66:12:1: Og et stort tegn blev sett i himmelen: en kvinne, klædd med solen, og månen under hennes føtter, og på hennes hode en krone av tolv stjerner, 
66:12:2: og hun var fruktsommelig og skrek i barnsnød og fødsels-veer. 
66:12:3: Og et annet tegn blev sett i himmelen, og se, en stor ildrød drage, som hadde syv hoder og ti horn, og på sine hoder syv kroner, 
66:12:4: og dens stjert drog tredjedelen av himmelens stjerner med sig og kastet dem på jorden. Og dragen stod foran kvinnen som skulde føde, for å sluke hennes barn når hun hadde født. 
66:12:5: Og hun fødte et guttebarn, som skal styre alle hedningene med jernstav; og hennes barn blev bortrykket til Gud og til hans trone. 
66:12:6: Og kvinnen flydde ut i ørkenen, hvor hun har et sted som er gjort i stand til henne av Gud, forat de der skulde gi henne hennes føde i tusen to hundre og seksti dager. 
66:12:7: Og det blev en strid i himmelen: Mikael og hans engler tok til å stride mot dragen, og dragen stred, og dens engler. 
66:12:8: Men de maktet det ikke, heller ikke blev deres sted mere funnet i himmelen. 
66:12:9: Og den store drage blev kastet ned, den gamle slange, han som kalles djevelen og Satan, han som forfører hele jorderike; han blev kastet ned på jorden, og hans engler blev kastet ned med ham. 
66:12:10: Og jeg hørte en høi røst i himmelen si: Fra nu av tilhører frelsen og styrken og riket vår Gud, og makten hans salvede; for våre brødres anklager er kastet ned, han som anklaget dem for vår Gud dag og natt. 
66:12:11: Og de har seiret over ham i kraft av Lammets blod og det ord de vidnet; og de hadde ikke sitt liv kjært, like til døden. 
66:12:12: Derfor fryd eder, I himler, og I som bor i dem! Ve jorden og havet! for djevelen er faret ned til eder i stor vrede, fordi han vet at han bare har en liten tid. 
66:12:13: Og da dragen så at den var kastet ned på jorden, forfulgte den kvinnen som hadde født gutten. 
66:12:14: Og den store ørns to vinger blev gitt til kvinnen forat hun skulde flyve ut i ørkenen til sitt sted, hvor hun får sin føde en tid og tider og en halv tid, borte fra slangens åsyn. 
66:12:15: Og slangen sprutet av sin munn vann som en elv efter kvinnen, for å rive henne bort med elven; 
66:12:16: men jorden kom kvinnen til hjelp, og jorden åpnet sin munn og slukte elven som slangen sprutet av sin munn. 
66:12:17: Og dragen blev vred på kvinnen og drog avsted for å føre krig mot de andre av hennes ætt, dem som holder Guds bud og har Jesu vidnesbyrd. 
66:12:18: Og jeg stod på sanden ved havet. 
66:13:1: Og jeg så et dyr stige op av havet, som hadde ti horn og syv hoder og på sine horn ti kroner, og på sine hoder bespottelses-navn. 
66:13:2: Og dyret som jeg så, var likt en leopard, og dets føtter som på en bjørn, og dets munn som en løvemunn; og dragen gav det sin kraft og sin trone og stor makt. 
66:13:3: Og jeg så et av dets hoder likesom såret til døden, og dets dødssår blev lægt, og all jorden undret sig og fulgte efter dyret, 
66:13:4: og de tilbad dragen, fordi den hadde gitt dyret makt, og de tilbad dyret og sa: Hvem er lik dyret? og hvem kan stride mot det? 
66:13:5: Og der blev gitt det en munn som talte store og spottende ord, og der blev gitt det makt til å holde på i to og firti måneder. 
66:13:6: Og det åpnet sin munn til spott mot Gud, til å spotte hans navn og hans bolig, dem som bor i himmelen. 
66:13:7: Og der blev gitt det å føre krig mot de hellige og seire over dem, og der blev gitt det makt over hver stamme og folk og tunge og ætt. 
66:13:8: Og alle som bor på jorden, skal tilbede det, hver den som ikke, fra verdens grunnvoll blev lagt, har fått sitt navn skrevet i livsens bok hos Lammet som er slaktet. 
66:13:9: Dersom nogen har øre, han høre! 
66:13:10: Om nogen fører i fangenskap, han skal i fangenskap; om nogen dreper med sverd, han skal drepes med sverd. Her er de helliges tålmodighet og tro. 
66:13:11: Og jeg så et annet dyr stige op av jorden, og det hadde to horn likesom et lam, og talte som en drage. 
66:13:12: Og det bruker det første dyrs hele makt for dets øine, og gjør at jorden og de som bor på den, tilbeder det første dyr, hvis dødssår blev lægt, 
66:13:13: og det gjør store tegn, så at det endog får ild til å falle ned fra himmelen på jorden for menneskenes øine. 
66:13:14: Og det forfører dem som bor på jorden, for de tegns skyld som er det gitt å gjøre for dyrets øine, idet det sier til dem som bor på jorden, at de skal gjøre et billede til det dyr som fikk såret av sverdet og blev i live. 
66:13:15: Og det fikk makt til å gi dyrets billede livsånde, så at dyrets billede endog kunde tale, og gjøre så at alle de som ikke vilde tilbede dyrets billede, skulde drepes. 
66:13:16: Og det gjør at der blir gitt alle, små og store, rike og fattige, frie og træler, et merke i deres høire hånd eller på deres panne, 
66:13:17: og at ingen kan kjøpe eller selge uten den som har merket, dyrets navn eller tallet for dets navn. 
66:13:18: Her er visdommen. Den som har forstand, han regne ut dyrets tall! for det er et menneskes tall, og dets tall er seks hundre og seks og seksti. 
66:14:1: Og jeg så, og se, Lammet stod på Sions berg, og med det hundre og fire og firti tusen, som hadde dets navn og dets Faders navn skrevet på sine panner. 
66:14:2: Og jeg hørte en røst fra himmelen som lyden av mange vann og som lyden av en sterk torden, og røsten jeg hørte, var som av harpespillere som spilte på sine harper. 
66:14:3: Og de sang en ny sang for tronen og for de fire livsvesener og de eldste, og ingen kunde lære sangen uten de hundre og fire og firti tusen, de som er kjøpt fra jorden. 
66:14:4: Disse er de som ikke har gjort sig urene med kvinner; for de er som jomfruer. Disse er de som følger Lammet hvor det går. Disse er kjøpt fra menneskene til en førstegrøde for Gud og Lammet, 
66:14:5: og i deres munn er ikke funnet løgn; for de er uten lyte. 
66:14:6: Og jeg så en annen engel flyve under det høieste av himmelen, som hadde et evig evangelium å forkynne for dem som bor på jorden, og for hver ætt og stamme og tunge og folk, 
66:14:7: og han sa med høi røst: Frykt Gud og gi ham ære! for timen for hans dom er kommet; og tilbed ham som gjorde himmelen og jorden og havet og vannkildene! 
66:14:8: Og atter en annen engel fulgte efter og sa: Falt, falt er Babylon, den store, som har gitt alle folk å drikke av sitt horelevnets vredesvin. 
66:14:9: Og atter en tredje engel fulgte efter dem og sa med høi røst: Dersom nogen tilbeder dyret og dets billede og tar merket på sin panne eller i sin hånd, 
66:14:10: da skal også han drikke av Guds vredes-vin, som er skjenket ublandet i hans harmes beger, og han skal pines med ild og svovel for de hellige englers og for Lammets øine, 
66:14:11: og røken av deres pine stiger op i all evighet, og de har ikke hvile dag eller natt de som tilbeder dyret og dets billede, og hver den som tar dets navns merke. 
66:14:12: Her er de helliges tålmodighet, de som holder Guds bud og Jesu tro. 
66:14:13: Og jeg hørte en røst fra himmelen si: Skriv: Salige er de døde som dør i Herren herefter! Ja, sier Ånden, de skal hvile fra sitt arbeid; for deres gjerninger følger med dem. 
66:14:14: Og jeg så, og se, en hvit sky, og på skyen satt en som var lik en menneskesønn, og på sitt hode hadde han en gullkrone og i sin hånd en skarp sigd. 
66:14:15: Og en annen engel kom ut av templet og ropte med høi røst til ham som satt på skyen: Send ut din sigd og høst! Timen til å høste er kommet; for høsten på jorden er overmoden. 
66:14:16: Og han som satt på skyen, lot sin sigd gå over jorden, og jorden blev høstet. 
66:14:17: Og en annen engel kom ut av templet i himmelen og hadde en skarp sigd han også. 
66:14:18: Og ennu en annen engel kom ut fra alteret, og han hadde makt over ilden, og ropte med høi røst til ham som hadde den skarpe sigd: Send ut din skarpe sigd og høst druene av vintreet på jorden! for dets bær er modne. 
66:14:19: Og engelen lot sin sigd gå over jorden og avhøstet vintreet på jorden og kastet frukten i Guds vredes store vinperse. 
66:14:20: Og vinpersen blev trådt utenfor byen, og det gikk blod ut av vinpersen like til bislene på hestene, så langt som tusen og seks hundre stadier. 
66:15:1: Og jeg så et annet tegn i himmelen, stort og underfullt: syv engler som hadde de syv siste plager; for med dem er Guds vrede fullendt. 
66:15:2: Og jeg så likesom et glasshav blandet med ild; og dem som hadde seiret over dyret og dets billede og dets navns tall, så jeg stå ved glasshavet med Guds harper i hånd; 
66:15:3: og de sang Moses', Guds tjeners sang og Lammets sang og sa: Store og underfulle er dine gjerninger, Herre Gud, du allmektige! rettferdige og sanne er dine veier, du folkenes konge! 
66:15:4: Hvem skulde ikke frykte, Herre, og ære ditt navn? Du alene er hellig, og alle folkene skal komme og tilbede for ditt åsyn, fordi dine rettferdige dommer er blitt åpenbaret. 
66:15:5: Og derefter så jeg, og templet med vidnesbyrdets telt i himmelen blev åpnet, 
66:15:6: og de syv engler som hadde de syv plager, kom ut av templet, klædd i rent og skinnende lin og ombundet om brystet med gullbelter. 
66:15:7: Og et av de fire livsvesener gav de syv engler syv gullskåler fylt med Guds vrede, hans som lever i all evighet. 
66:15:8: Og templet blev fylt med røk av Guds herlighet og av hans makt; og ingen kunde gå inn i templet før de syv englers syv plager var fullendt. 
66:16:1: Og jeg hørte en høi røst fra templet si til de syv engler: Gå avsted og tøm Guds vredes syv skåler ut på jorden! 
66:16:2: Og den første gikk avsted og tømte sin skål ut på jorden; og det kom en ond og farlig byld på de mennesker som hadde dyrets merke, og som tilbad dets billede. 
66:16:3: Og den annen engel tømte sin skål ut i havet; og det blev til blod som av en død mann, og hver levende sjel i havet døde. 
66:16:4: Og den tredje engel tømte sin skål ut i elvene og i vannkildene; og det blev til blod. 
66:16:5: Og jeg hørte engelen over vannene si: Rettferdig er du som er og som var, du hellige, at du har dømt således; 
66:16:6: for blod av hellige og av profeter har de utøst, og blod gav du dem å drikke; de er det verd. 
66:16:7: Og jeg hørte alteret si: Ja, Herre Gud, du allmektige, sanne og rettferdige er dine dommer. 
66:16:8: Og den fjerde engel tømte sin skål ut på solen; og den fikk makt til å brenne menneskene med ild; 
66:16:9: og menneskene brente i svær hete, og de spottet Guds navn, hans som har makt over disse plager, og de omvendte sig ikke til å gi ham ære. 
66:16:10: Og den femte engel tømte sin skål ut over dyrets trone; og dets rike blev formørket, og de tygget sine tunger av pine, 
66:16:11: Og de spottet himmelens Gud for sine piner og for sine bylder, og omvendte sig ikke fra sine gjerninger. 
66:16:12: Og den sjette engel tømte sin skål ut i den store elv Eufrat; og vannet i den tørket bort, forat der skulde ryddes vei for kongene fra Østen. 
66:16:13: Og jeg så at det av dragens munn og av dyrets munn og av den falske profets munn kom ut tre urene ånder som lignet padder; 
66:16:14: for de er djevle-ånder som gjør tegn, og de går ut til kongene over hele jorderike for å samle dem til krigen på Guds, den allmektiges, store dag. 
66:16:15: Se, jeg kommer som en tyv; salig er den som våker og tar vare på sine klær, så han ikke skal gå naken og de skal se hans skam. 
66:16:16: Og han samlet dem på det sted som på hebraisk heter Harmageddon. 
66:16:17: Og den syvende engel tømte sin skål ut i luften; og en høi røst kom fra templet i himmelen, fra tronen, og sa: Det er skjedd! 
66:16:18: Og det kom lyn og røster og tordener, og det kom et stort jordskjelv, et slikt som ikke har vært fra den tid menneskene blev til på jorden, et sådant jordskjelv, så stort. 
66:16:19: Og den store by blev delt i tre deler, og folkenes byer falt, og Babylon, den store, blev ihukommet for Gud, at den skulde få begeret med hans strenge vredes vin. 
66:16:20: Og hver ø vek bort, og fjell blev ikke funnet. 
66:16:21: Og et svært hagl, som en hundre pund, falt ned fra himmelen på menneskene, og menneskene spottet Gud for haglets plage; for plagen av det var meget stor. 
66:17:1: Og en av de syv engler som hadde de syv skåler, kom og talte med mig og sa: Kom, jeg vil vise dig dommen over den store skjøge, som sitter over de mange vann, 
66:17:2: som kongene på jorden drev hor med, og de som bor på jorden, blev drukne av hennes horelevnets vin. 
66:17:3: Og han førte mig i ånden ut i ørkenen, og jeg så en kvinne sitte på et skarlagenrødt dyr, som var fullt av bespottelses-navn og hadde syv hoder og ti horn. 
66:17:4: Og kvinnen var klædd i purpur og skarlagen og lyste av gull og edelstener og perler; hun hadde et gullbeger i sin hånd, fullt av styggheter og av hennes horelevnets urenheter, 
66:17:5: og på hennes panne var skrevet et navn, en hemmelighet: Babylon, den store, mor til skjøgene og til stygghetene på jorden. 
66:17:6: Og jeg så kvinnen drukken av de helliges blod og av Jesu vidners blod, og jeg undret mig storlig da jeg så henne. 
66:17:7: Og engelen sa til mig: Hvorfor undret du dig? Jeg vil si dig hemmeligheten med kvinnen og med dyret som bærer henne, og som har de syv hoder og de ti horn. 
66:17:8: Det dyr du så, var og er ikke, og det skal stige op av avgrunnen og fare bort til undergang; og de som bor på jorden, de hvis navn ikke, fra verdens grunnvoll blev lagt, er skrevet i livsens bok, skal undre sig når de ser at dyret var og ikke er og skal komme igjen. 
66:17:9: Her gjelder det vett som har visdom. De syv hoder er syv fjell, som kvinnen sitter på, 
66:17:10: og de er syv konger; de fem er falt, den ene er til, den annen er ennu ikke kommet; og når han kommer, skal han bare holde sig en kort tid. 
66:17:11: Og dyret som var og ikke er, er selv den åttende, og er tillike en av de syv, og farer bort til undergang. 
66:17:12: Og de ti horn som du så, er ti konger som ennu ikke har fått rike, men de får makt som konger én time sammen med dyret. 
66:17:13: Disse har én tanke, og sin kraft og sin makt gir de til dyret. 
66:17:14: Disse skal stride mot Lammet, og Lammet skal seire over dem, fordi det er herrers herre og kongers konge, og de som er med det, de kalte og utvalgte og trofaste. 
66:17:15: Og han sier til mig: De vann som du så, hvor skjøgen sitter, er folk og skarer og ætter og tunger. 
66:17:16: Og de ti horn som du så, og dyret, disse skal hate skjøgen og gjøre henne øde og naken, og hennes kjøtt skal de ete, og henne selv skal de brenne op med ild. 
66:17:17: For Gud gav dem i hjertet å fullføre hans tanke, og å fullføre én og samme tanke, og å gi dyret sitt rike, inntil Guds ord er blitt fullbyrdet. 
66:17:18: Og kvinnen som du så, er den store by som har kongedømme over kongene på jorden. 
66:18:1: Derefter så jeg en annen engel stige ned fra himmelen; han hadde stor makt, og jorden blev oplyst av hans herlighet, 
66:18:2: og han ropte med sterk røst og sa: Falt, falt er Babylon, den store, og den er blitt et bosted for onde ånder, og et fengsel for hver uren ånd, og et fengsel for hver uren og hatet fugl; 
66:18:3: for av hennes horelevnets vredes-vin har alle folk drukket, og kongene på jorden har drevet hor med henne, og kjøbmennene på jorden er blitt rike av hennes vellevnets fylde. 
66:18:4: Og jeg hørte en annen røst fra himmelen si: Gå ut fra henne, mitt folk, forat I ikke skal ha del i hennes synder, og forat I ikke skal få noget av hennes plager! 
66:18:5: For hennes synder når like til himmelen, og Gud har kommet hennes urettferdige gjerninger i hu. 
66:18:6: Gi henne igjen som hun har gitt, og gjengjeld henne dobbelt efter hennes gjerninger! Skjenk henne dobbelt i det beger hun har iskjenket! 
66:18:7: Så meget som hun har ophøiet sig selv og levd i vellevnet, så meget skal I gi henne av pine og sorg! Fordi hun sier i sitt hjerte: Jeg sitter som dronning og er ikke enke, og sorg skal jeg aldri se, 
66:18:8: derfor skal hennes plager komme på én dag: død og sorg og sult; og hun skal bli opbrent med ild; for sterk er Gud Herren, som dømte henne. 
66:18:9: Og kongene på jorden, som har drevet hor og levd i vellevnet med henne, skal gråte og jamre sig over henne når de ser røken av hennes brand, 
66:18:10: mens de står langt borte av frykt for hennes pine, og de skal si: Ve, ve, du store by Babylon, du sterke by, at din dom er kommet i en time! 
66:18:11: Og kjøbmennene på jorden skal gråte og sørge over henne, fordi ingen mere kjøper deres skibsladninger, 
66:18:12: skibsladninger av gull og sølv og edelstener og perler og fint lin og purpur og silke og skarlagen, og allslags velluktende tre og allslags kar av elfenben og allslags kar av kostelig tre og av kobber og jern og marmor, 
66:18:13: og kanel og hårsalve og røkelse og myrra og virak og vin og olje og fint mel og hvete og storfe og får og hester og vogner og træler og menneskesjeler. 
66:18:14: Og den frukt som din sjel hadde lyst til, er blitt borte for dig, og alt det fete og glimrende er blitt borte for dig, og aldri mere skal nogen finne det igjen. 
66:18:15: De som handler med slikt, de som er blitt rike ved henne, skal stå langt borte av frykt for hennes pine og gråte og sørge og si: 
66:18:16: Ve, ve den store by, som var klædd i fint lin og purpur og skarlagen og lyste av gull og edelstener og perler, at så stor en rikdom er ødelagt i én time! 
66:18:17: Og hver styrmann og hver skibsfører og alle sjøfolk og alle de som ferdes på havet, stod langt borte 
66:18:18: og ropte da de så røken av hennes brand, og sa: Hvem er lik den store by? 
66:18:19: Og de kastet støv på sine hoder og ropte med gråt og sorg og sa: Ve, ve den store by, hvor alle som har skib i sjøen, er blitt rike av dens kostbarheter, at den er lagt øde i en time! 
66:18:20: Fryd dig over den, du himmel, og I hellige, og I apostler og profeter, fordi Gud har holdt dom over den for eder! 
66:18:21: Og en veldig engel løftet en sten som en stor kvernsten og kastet den i havet og sa: Så skal Babylon, den store by, kastes ned med hast og ikke finnes mere. 
66:18:22: Og lyd av harpespillere og sangere og fløitespillere og basunblåsere skal ikke mere høres i dig, og ingen som driver nogen kunst, skal mere finnes i dig, og lyd av kvern skal ikke mere høres i dig, 
66:18:23: og schmelk av lampe skal ikke mere skinne i dig, og røst av brudgom og brud skal ikke mere høres i dig; for dine kjøbmenn var stormennene på jorden, fordi alle folk blev ført vill ved din trolldom. 
66:18:24: Og i den blev funnet blod av profeter og hellige og av alle dem som er myrdet på jorden. 
66:19:1: Derefter hørte jeg likesom en sterk lyd av en stor skare i himmelen, som sa: Halleluja! Frelsen og æren og makten tilhører vår Gud! 
66:19:2: for sanne og rettferdige er hans dommer; han har dømt den store skjøge, hun som ødela jorden med sitt horelevnet, og han har krevd sine tjeneres blod av hennes hånd. 
66:19:3: Og de sa annen gang: Halleluja! Og røken av henne stiger op i all evighet. 
66:19:4: Og de fire og tyve eldste og de fire livsvesener falt ned og tilbad Gud, som satt på tronen, og sa: Amen! Halleluja! 
66:19:5: Og en røst gikk ut fra tronen, som sa: Lov vår Gud, alle I hans tjenere, og I som frykter ham, både små og store! 
66:19:6: Og jeg hørte likesom en lyd av en stor skare og som en lyd av mange vann og som en lyd av sterke tordener, som sa: Halleluja! for Gud Herren, den allmektige, er blitt konge! 
66:19:7: La oss glede og fryde oss og gi ham æren! for Lammets bryllup er kommet, og hans brud har gjort sig rede, 
66:19:8: og det er henne gitt å klæ sig i rent og skinnende fint lin. For det fine lin er de helliges rettferdige gjerninger. 
66:19:9: Og han sier til mig: Skriv: Salige er de som er innbudt til Lammets bryllups-nattverd! Og han sier til mig: Dette er Guds sanne ord. 
66:19:10: Og jeg falt ned for hans føtter for å tilbede ham; og han sier til mig: Var dig for det! jeg er din og dine brødres medtjener, de som har Jesu vidnesbyrd. Gud skal du tilbede! For Jesu vidnesbyrd er profet-ordets ånd. 
66:19:11: Og jeg så himmelen åpnet, og se, en hvit hest, og han som satt på den, heter Trofast og Sanndru, og han dømmer og strider med rettferdighet. 
66:19:12: Hans øine er som ildslue, og på hans hode er det mange kroner; han har et navn skrevet som ingen kjenner uten han selv, 
66:19:13: og han er klædd i et klædebon som er dyppet i blod, og han er kalt Guds ord. 
66:19:14: Og hærene i himmelen fulgte ham på hvite hester, klædd i hvitt og rent fint lin. 
66:19:15: Og av hans munn går det ut et skarpt sverd, forat han med det skal slå hedningene; og han skal styre dem med jernstav, og han treder vinpersen med Guds, den allmektiges, strenge vredes vin. 
66:19:16: Og på sitt klædebon og på sin lend har han et navn skrevet: Kongers konge og herrers herre. 
66:19:17: Og jeg så en engel som stod i solen, og han ropte med høi røst og sa til alle fuglene som flyver under det høieste av himmelen: Kom hit og samle eder til Guds store måltid, 
66:19:18: for å ete kjøtt av konger og kjøtt av krigshøvdinger og kjøtt av veldige og kjøtt av hester og av dem som satt på dem, og kjøtt av alle, frie menn og træler, små og store! 
66:19:19: Og jeg så dyret og kongene på jorden og deres hærer samlet for å føre krig mot ham som satt på hesten, og mot hans hær. 
66:19:20: Og dyret blev grepet, og sammen med det den falske profet, han som for dets øine hadde gjort de tegn hvormed han hadde forført dem som tok dyrets merke og tilbad dets billede; disse to blev kastet levende i ildsjøen som brenner med svovel. 
66:19:21: Og de andre blev drept med hans sverd som satt på hesten, det sverd som gikk ut av hans munn; og alle fuglene blev mettet av deres kjøtt. 
66:20:1: Og jeg så en engel stige ned fra himmelen, som hadde nøkkelen til avgrunnen og en stor lenke i sin hånd. 
66:20:2: Og han grep dragen, den gamle slange, som er djevelen og Satan, og bandt ham for tusen år 
66:20:3: og kastet ham i avgrunnen og lukket til og satte segl over ham, forat han ikke lenger skulde forføre folkene, inntil de tusen år var til ende; og efter den tid skal han løses en kort stund. 
66:20:4: Og jeg så troner, og de satte sig på dem, og det blev gitt dem makt til å holde dom; og jeg så deres sjeler som var blitt halshugget for Jesu vidnesbyrds og for Guds ords skyld, og dem som ikke hadde tilbedt dyret eller dets billede, og som ikke hadde tatt merket på sin panne og på sin hånd; og de blev levende og regjerte med Kristus i tusen år. 
66:20:5: Men de andre døde blev ikke levende igjen før de tusen år var til ende. Dette er den første opstandelse. 
66:20:6: Salig og hellig er den som har del i den første opstandelse; over dem har den annen død ikke makt, men de skal være Guds og Kristi prester og regjere med ham i tusen år. 
66:20:7: Og når de tusen år er til ende, skal Satan løses av sitt fengsel. 
66:20:8: Og han skal gå ut for å forføre de folk som bor ved jordens fire hjørner, Gog og Magog, for å samle dem til strid, og deres tall er som havets sand. 
66:20:9: Og de drog op over den vide jord og kringsatte de helliges leir og den elskede stad. Og ild falt ned fra himmelen og fortærte dem. 
66:20:10: Og djevelen, som hadde forført dem, blev kastet i sjøen med ild og svovel, hvor dyret og den falske profet var, og de skal pines dag og natt i all evighet. 
66:20:11: Og jeg så en stor hvit trone, og ham som satt på den; og for hans åsyn vek jorden og himmelen bort, og det blev ikke funnet sted for dem. 
66:20:12: Og jeg så de døde, små og store, stå for Gud, og bøker blev åpnet; og en annen bok blev åpnet, som er livsens bok; og de døde blev dømt efter det som var skrevet i bøkene, efter sine gjerninger. 
66:20:13: Og havet gav tilbake de døde som var i det, og døden og dødsriket gav tilbake de døde som var i dem; og de blev dømt, enhver efter sine gjerninger. 
66:20:14: Og døden og dødsriket blev kastet i ildsjøen. Dette er den annen død: ildsjøen. 
66:20:15: Og hvis nogen ikke fantes opskrevet i livsens bok, da blev han kastet i ildsjøen. 
66:21:1: Og jeg så en ny himmel og en ny jord; for den første himmel og den første jord var veket bort, og havet er ikke mere. 
66:21:2: Og jeg så den hellige stad, det nye Jerusalem, stige ned av himmelen fra Gud, gjort i stand som en brud som er prydet for sin brudgom. 
66:21:3: Og jeg hørte en høi røst fra tronen si: Se, Guds bolig er hos menneskene, og han skal bo hos dem; og de skal være hans folk, og Gud selv skal være hos dem og være deres Gud; 
66:21:4: og han skal tørke bort hver tåre av deres øine, og døden skal ikke være mere, og ikke sorg og ikke skrik og ikke pine skal være mere; for de første ting er veket bort. 
66:21:5: Og han som satt på tronen, sa: Se, jeg gjør alle ting nye. Og han sier til mig: Skriv! for disse ord er troverdige og sanne. 
66:21:6: Og han sa til mig: Det er skjedd. Jeg er Alfa og Omega, begynnelsen og enden. Jeg vil gi den tørste av livsens vannkilde uforskyldt. 
66:21:7: Den som seirer, skal arve alle ting, og jeg vil være hans Gud, og han skal være min sønn. 
66:21:8: Men de redde og vantro og vederstyggelige og manndraperne og horkarlene og trollmennene og avgudsdyrkerne og alle løgnerne, deres del skal være i sjøen som brenner med ild og svovel; det er den annen død. 
66:21:9: Og en av de syv engler som hadde de syv skåler, fulle av de syv siste plager, kom til mig og talte med mig og sa: Kom, jeg vil vise dig bruden, Lammets hustru. 
66:21:10: Og han førte mig i ånden bort på et stort og høit fjell og viste mig den hellige stad Jerusalem, som steg ned av himmelen fra Gud; 
66:21:11: den hadde Guds herlighet, og dens schmelk var som den kosteligste sten, som krystallklar jaspis. 
66:21:12: Og den hadde en stor og høi mur; den hadde tolv porter, og på portene tolv engler og innskrevne navn, navnene på Israels barns tolv stammer; 
66:21:13: mot øst var tre porter, mot nord tre porter, mot syd tre porter, mot vest tre porter. 
66:21:14: Og stadens mur hadde tolv grunnstener, og på dem navnene på Lammets tolv apostler. 
66:21:15: Og han som talte med mig, hadde et gullrør forat han skulde måle staden og dens porter og dens mur. 
66:21:16: Og staden ligger i en firkant, og dens lengde er så stor som bredden. Og han målte staden med røret: tolv tusen stadier; lengden og bredden og høiden på den er like. 
66:21:17: Og han målte dens mur: hundre og fire og firti alen, efter menneskemål, som og er engle-mål. 
66:21:18: Og dens mur var bygget av jaspis, og staden var av rent gull, lik rent glass. 
66:21:19: Og grunnstenene i stadens mur var prydet med allslags kostelig sten; den første grunnsten var jaspis, den annen safir, den tredje kalkedon, den fjerde smaragd, 
66:21:20: den femte sardonyks, den sjette sarder, den syvende krysolitt, den åttende beryll, den niende topas, den tiende krysopras, den ellevte hyasint, den tolvte ametyst. 
66:21:21: Og de tolv porter var tolv perler; hver av portene var av én perle; og stadens gate var rent gull, som klart glass. 
66:21:22: Og noget tempel så jeg ikke i den; for dens tempel er Gud Herren, den allmektige, og Lammet. 
66:21:23: Og staden trenger ikke solen eller månen til å lyse for sig; for Guds herlighet oplyser den, og Lammet er dens schmelk. 
66:21:24: Og folkeslagene skal vandre i dens schmelk, og kongene på jorden bærer sin herlighet inn i den. 
66:21:25: Og dens porter skal aldri lukkes om dagen; for natt skal ikke være der; 
66:21:26: og de skal bære folkeslagenes herlighet og ære inn i den. 
66:21:27: Og intet urent skal komme inn i den, og ingen som farer med stygghet og løgn, men bare de som er innskrevet i livsens bok hos Lammet. 
66:22:1: Og han viste mig en elv med livsens vann, som rant, klar som krystall, ut fra Guds og Lammets trone. 
66:22:2: Mellem stadens gate og elven, på begge sider, stod livsens tre, som bar frukt tolv ganger og gav sin frukt hver måned; og bladene på treet var til lægedom for folkene. 
66:22:3: Og ingen forbannelse skal være mere, og Guds og Lammets trone skal være i den, og hans tjenere skal tjene ham, 
66:22:4: og de skal se hans åsyn, og hans navn skal være på deres panner. 
66:22:5: Og natt skal ikke være mere, og de trenger ikke lys av lampe og schmelk av sol, for Gud Herren skal lyse over dem; og de skal regjere i all evighet. 
66:22:6: Og han sa til mig: Disse ord er troverdige og sanne, og Herren, den Gud som utdeler sin Ånd til profetene, har sendt ut sin engel for å vise sine tjenere det som snart skal skje. 
66:22:7: Og se, jeg kommer snart. Salig er den som tar vare på de profetiske ord i denne bok. 
66:22:8: Og jeg, Johannes, er den som så og hørte dette; og da jeg hadde hørt og sett, falt jeg ned for å tilbede for den engels føtter som viste mig dette. 
66:22:9: Og han sier til mig: Var dig for det! jeg er din og dine brødre profetenes medtjener og deres som tar vare på ordene i denne bok. Gud skal du tilbede! 
66:22:10: Og han sier til mig: Du skal ikke sette segl for de profetiske ord i denne bok; for tiden er nær. 
66:22:11: La den som gjør urett, fremdeles gjøre urett, og den urene fremdeles bli uren, og den rettferdige fremdeles gjøre rettferdighet, og den hellige fremdeles bli helliggjort! 
66:22:12: Se, jeg kommer snart, og min lønn er med mig, til å gi enhver igjen efter som hans gjerning er. 
66:22:13: Jeg er Alfa og Omega, begynnelsen og enden, den første og den siste. 
66:22:14: Salige er de som tvetter sine kjortler, så de må få rett til livsens tre og gjennem portene komme inn i staden. 
66:22:15: Men utenfor er hundene og trollmennene og horkarlene og manndraperne og avgudsdyrkerne og enhver som elsker og taler løgn. 
66:22:16: Jeg, Jesus, har sendt min engel for å vidne dette for eder om menighetene; jeg er Davids rotskudd og ætt, den klare morgenstjerne. 
66:22:17: Og Ånden og bruden sier: Kom! og den som hører det, si: Kom! og den som tørster, han komme, og den som vil, han ta livsens vann uforskyldt! 
66:22:18: Jeg vidner for enhver som hører de profetiske ord i denne bok: Dersom nogen legger noget til dette, da skal Gud legge på ham de plager som er skrevet i denne bok; 
66:22:19: og dersom nogen tar noget bort fra ordene i denne profetiske bok, da skal Gud ta bort hans del fra livsens tre og fra den hellige stad, som det er skrevet om i denne bok. 
66:22:20: Han som vidner dette, sier: Ja, jeg kommer snart. Amen, ja kom, Herre Jesus! 
66:22:21: Vår Herre Jesu Kristi nåde være med eder alle! Amen.`;
export default norskBibel;
